import { EnvUtils } from '@premagic/utils';

enum LOCAL_SERVERS {
  PREMAGIC_SERVER,
  ACCOUNT_MANAGEMENT,
  ALBUM_MANAGEMENT,
  IMAGE_STORE,
}
const LOCAL_SERVER_PORTS: Record<LOCAL_SERVERS, number> = {
  [LOCAL_SERVERS.PREMAGIC_SERVER]: 5000,
  [LOCAL_SERVERS.ACCOUNT_MANAGEMENT]: 8000,
  [LOCAL_SERVERS.ALBUM_MANAGEMENT]: 8001,
  [LOCAL_SERVERS.IMAGE_STORE]: 1378,
};

function getLocalServerUrl(server: LOCAL_SERVERS): string {
  const { hostname, protocol } = window.location;
  return `${protocol}//${hostname}:${LOCAL_SERVER_PORTS[server]}`;
}

function getUrl(server: LOCAL_SERVERS) {
  const url = EnvUtils.getEnvValues().NX_PUBLIC_API_ROOT_URL;
  if (url === 'http://localhost') {
    return getLocalServerUrl(server);
  }
  return url;
}

export function isLocalApiURL() {
  const url = EnvUtils.getEnvValues().NX_PUBLIC_API_ROOT_URL;
  return url === 'http://localhost';
}

export function getAPIUrl(): string {
  // return 'https://api.premagic.com'; //@TODO change
  return getUrl(LOCAL_SERVERS.PREMAGIC_SERVER);
}

export function getAccountMgmntUrl(): string {
  return getUrl(LOCAL_SERVERS.ACCOUNT_MANAGEMENT);
}

export function getAlbumAPIUrl(): string {
  return getUrl(LOCAL_SERVERS.ALBUM_MANAGEMENT);
}

export function getImageStoreUrl(): string {
  if (isLocalApiURL()) {
    return `${getUrl(LOCAL_SERVERS.IMAGE_STORE)}/asia-compressed-image-store/`;
  }
  return EnvUtils.getEnvValues().NX_PUBLIC_IMAGE_STORE;
}
