import { connect } from 'react-redux';
import { EventPosterService } from '@premagic/core';
import { PosterWidgetTextType } from '@premagic/poster-creator';
import EventPosterTextColorSelector from './EventPosterTextColorSelector';
import { eventPostersSelector, updateEventPosterWidget } from '../../../crm/event-posters/EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    posterId: string;
    widgetId: EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS;
    name: 'fill' | 'font_color';
  },
) {
  const { projectId, posterId, widgetId, name } = ownProps;
  const poster = eventPostersSelector(state)[posterId];
  const selectedWidget = poster?.widgets?.[widgetId] as PosterWidgetTextType;
  return { projectId, poster, posterId, widget: selectedWidget, name };
}

function mapDispatchToProps(dispatch) {
  return {
    editTextColor: ({ projectId, poster, widgetToUpdate }) => {
      dispatch(
        updateEventPosterWidget(dispatch, projectId, {
          widgetToUpdate,
          poster,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterTextColorSelector);
