import { createContext, useContext } from 'react';

type PhotoSliderWidgetContextType = {
  widgetCode?: string;
  customizations?: {
    background_color: string;
    height?: number;
    max_height?: number;
    num_of_images: number;
  };
  setCustomizations?: (data) => void;
};

const PhotoSliderWidgetContext = createContext<PhotoSliderWidgetContextType>({
  widgetCode: undefined,
  customizations: undefined,
  setCustomizations: () => {},
});

export function usePhotoSliderWidgetData(): PhotoSliderWidgetContextType {
  return useContext(PhotoSliderWidgetContext);
}

export default PhotoSliderWidgetContext;
