import React, { useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  Button,
  BUTTON_STYLES,
  Col,
  InnerPage,
  InnerPageContent,
  Row,
  InnerPageHeader,
  Space,
  Text,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { EventsService, EventTrackerService } from '@premagic/core';
import APP_URLS from '../../../services/AppRouteURLService';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';
import CRMDashboardWorkflowStagesContainer from './wokflow-stages/CRMDashboardWorkflowStagesContainer';
import CRMDashboardEventsListPageContainer from './event-list/CRMDashboardEventsListPageContainer';

type Props = {
  view: 'list' | 'calendar';
  filters: string;
  hasCRMFeature: boolean;
};
const ROUTES = APP_URLS.CRM_DASHBOARD;

export default function CRMDashboardPage(props: Props): JSX.Element {
  const { view, filters, hasCRMFeature } = props;
  const createNewUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.CREATE__CLIENT, {
    filters: BrowserUrlUtils.getQueryStringFromObject({
      event_status: EventsService.EVENT_STATUS.NEW,
    }),
  });

  useEffect(() => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.VIEW, { view });
  }, [view]);

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="trello"
        title="Dashboard"
        rightActions={
          <Button className={JS_SELECTORS.ADD_EVENTS_BTN} style={BUTTON_STYLES.PRIMARY} link={createNewUrl}>
            Add lead
          </Button>
        }
      >
        Leads
      </InnerPageHeader>
      <InnerPageContent>
        <CRMDashboardWorkflowStagesContainer filters={filters} view={view} />
        <Space vertical size={4} />
        <Switch>
          <Route path={ROUTES.EVENTS_LIST} component={CRMDashboardEventsListPageContainer} />
        </Switch>
        <Space vertical />
        {!hasCRMFeature && (
          <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
            CRM is available only on higher plan
          </Alert>
        )}
        <Row>
          <Col rightAlighed size={null}>
            <Text muted>
              Looking for older leads UI? <Link to={APP_URLS.OLD_LEADS.INDEX}>Click here</Link>
            </Text>
          </Col>
        </Row>
      </InnerPageContent>
    </InnerPage>
  );
}
