import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  FormFooter,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Space,
  Text,
} from '@premagic/myne';
import { ClientSettingsService, CompanyService } from '@premagic/core';
import CompanyTypeInput from './CompanyTypeInput';

function CompanyTypeConfirmationModalContent(props: { companyType: ClientSettingsService.CLIENT_COMPANY_TYPES }) {
  const { companyType } = props;
  const toEnableFeatures = CompanyService.COMPANY_TYPE_FEATURES.filter((feature) =>
    feature.enableForCompanyType.includes(companyType),
  );
  const toDisableFeatures = CompanyService.COMPANY_TYPE_FEATURES.filter((feature) =>
    feature.disableForCompanyType.includes(companyType),
  );

  return (
    <div>
      {toEnableFeatures.length > 0 && (
        <div>
          <Text block color={COLOR_SHADES.DARKER}>
            This will enable
          </Text>
          <Space size={1} vertical />
          <ol>
            {toEnableFeatures.map((feature) => (
              <li key={feature.name}>
                <Text color={COLOR_SHADES.DARKER}>{feature.name}</Text>
              </li>
            ))}
          </ol>
          <Space vertical />
        </div>
      )}
      {toDisableFeatures.length > 0 && (
        <div>
          <Text block color={COLOR_SHADES.DARKER}>
            This will disable
          </Text>
          <Space size={1} vertical />
          <ol>
            {toDisableFeatures.map((feature) => (
              <li key={feature.name}>
                <Text color={COLOR_SHADES.DARKER}>{feature.name}</Text>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}

type Props = {
  isSaving: boolean;
  savePreferences: (data) => void;
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
  toggleConfirmationModal: (show: boolean) => void;
  showConfirmationModal: boolean;
};

export default function CompanyTypeSelector(props: Props) {
  const { isSaving, savePreferences, companyType, toggleConfirmationModal, showConfirmationModal } = props;
  const [newSelectedType, setNewSelectedType] = useState<ClientSettingsService.CLIENT_COMPANY_TYPES>(
    ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY,
  );
  const newSelectedTypeDetail =
    ClientSettingsService.CLIENT_COMPANY_TYPE_DETAILS[
      newSelectedType || ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY
    ];

  function onConfirm() {
    savePreferences({ data: CompanyService.getClientSettingsToSaveForCompanyType(newSelectedType) });
    toggleConfirmationModal(false);
  }
  function handleChange(newType: ClientSettingsService.CLIENT_COMPANY_TYPES) {
    setNewSelectedType(newType);
    toggleConfirmationModal(true);
  }
  return (
    <div>
      <CompanyTypeInput onChange={(value) => handleChange(value)} defaultValue={companyType} />
      {showConfirmationModal && (
        <Modal size={MODAL_SIZES.SM} show={showConfirmationModal} close={() => toggleConfirmationModal(false)}>
          <ModalTitle>Change the company type to {newSelectedTypeDetail.title}?</ModalTitle>
          <CompanyTypeConfirmationModalContent companyType={newSelectedType} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleConfirmationModal(false)}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} onClick={onConfirm} isLoading={isSaving}>
              Change to {newSelectedTypeDetail.title}
            </Button>
          </FormFooter>
        </Modal>
      )}
    </div>
  );
}
