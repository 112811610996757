import { connect } from 'react-redux';

import EventDetailsDeliverablesLayout from './EventDetailsDeliverablesLayout';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { projectsSelector } from '../../projects/AccountProjectsDataDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId, focusId } = ownProps;
  const project = projectsSelector(state)[projectId] || {};

  return {
    eventId: project.event_id,
    focusId,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    project,
    showClientWebsite: true,
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showCreateProject: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT.CONVERT_TO_PROJECT, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsDeliverablesLayout);
