import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import HighlightDesignGalleryThumbnailSizeSelector from './HighlightDesignGalleryThumbnailSizeSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (value) => void;
    galleryThumbnailSize: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
  },
) {
  const { onChange, galleryThumbnailSize } = ownProps;
  return {
    onChange,
    galleryThumbnailSize,
    isThumbnailSizeSelectorEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignGalleryThumbnailSizeSelector);
