import { CompanyLeadService, EventsService, IntegrationService } from '@premagic/core';
import { SimpleDateUtils } from '@premagic/utils';
import { EventWithClientFormData } from '../crm/events/details/CreateEventPageDuck';
import { UserType } from '../crm/users/UsersService';

export enum INTEGRATION_CHECKBOX_TYPES {
  ATTENDEES = 'attendees',
  EVENT_DETAILS = 'event_details',
}

export function getIntegrationCheckboxConfig(
  platform: IntegrationService.INTEGRATION_PLATFORMS,
  subFieldsMap: Record<INTEGRATION_CHECKBOX_TYPES, Array<{ id: string; name: string }>>,
): Record<
  INTEGRATION_CHECKBOX_TYPES,
  {
    name: INTEGRATION_CHECKBOX_TYPES;
    title: string;
    subFields: Array<{ id: string; name: string }>;
    readOnly: boolean;
    subTitle?: string;
  }
> {
  return {
    [INTEGRATION_CHECKBOX_TYPES.ATTENDEES]: {
      name: INTEGRATION_CHECKBOX_TYPES.ATTENDEES,
      title: 'Attendees',
      subFields: subFieldsMap[INTEGRATION_CHECKBOX_TYPES.ATTENDEES],
      readOnly: false,
      subTitle: IntegrationService.doesPlatformHasApprovedAttendeesFeature(platform)
        ? 'NOTE: Only guests who are approved and invited will be imported. Declined guests will not be imported.'
        : '',
    },
    [INTEGRATION_CHECKBOX_TYPES.EVENT_DETAILS]: {
      name: INTEGRATION_CHECKBOX_TYPES.EVENT_DETAILS,
      title: 'Event details',
      subFields: subFieldsMap[INTEGRATION_CHECKBOX_TYPES.EVENT_DETAILS],
      readOnly: true,
    },
  };
}

// a helper function for actions to get premagic formated event data for event creation
export function getDataToCreatePremagicEvent(
  integrationEventData: IntegrationService.IntegrationPlatformEventType,
  hostUser?: UserType,
) {
  const {
    id: integrationEventId,
    title: eventName,
    starts_at: startDate,
    ends_at: endDate,
    timezone,
    description: aboutTheEvent,
    address,
  } = integrationEventData;

  const { email: hostEmail, name: hostName, phone_number: hostPhoneNumber } = hostUser || {};

  const eventStartDate =
    typeof startDate === 'number' ? SimpleDateUtils.getISODateStringFromTimestamp(startDate) : startDate;
  const eventEndDate = typeof endDate === 'number' ? SimpleDateUtils.getISODateStringFromTimestamp(endDate) : endDate;

  const dataToCreatePMEvent: EventWithClientFormData = {
    data: {
      email: hostEmail || '',
      name: hostName || '',
      phone_number: hostPhoneNumber || '',
      channel: CompanyLeadService.LEAD_CHANNELS.CRM,
      source: CompanyLeadService.LEAD_SOURCE.OTHER,
      address,
      event: {
        name: eventName,
        about_the_event: `<p style="">${aboutTheEvent}</p>`,
        date_timezone: timezone,
        start_date: eventStartDate,
        end_date: eventEndDate,
        location: address,
        status: EventsService.EVENT_STATUS.CONFIRMED,
      },
      project: {
        name: eventName,
      },
    },
  };

  return dataToCreatePMEvent;
}
