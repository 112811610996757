import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import ClientWebsiteBasicInfoFormContainer from './ClientWebsiteBasicInfoFormContainer';
import APP_URLS from '../../../services/AppRouteURLService';
import ClientWebsitePeopleDetailsFormContainer from './ClientWebsitePeopleDetailsFormContainer';

type Props = {
  id: string;
  projectId: string;
  saveClientWebsite: (id: string, projectId: string, data: FormResponseType, navigateToUrl: string) => void;
  isLoading: boolean;
  errors?: Record<string, string>;
  showPanel: boolean;
  closePanel: () => void;
};

export default function EditClientWebsitePeopleDetailsPanel(props: Props): JSX.Element {
  const { id, projectId, saveClientWebsite, isLoading, errors, showPanel, closePanel } = props;
  const clientWebsiteDetailsPageUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, {
    id,
    projectId,
  });
  return (
    <WindowPanel show={showPanel}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Details</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          onSubmit={(formResponse) => saveClientWebsite(id, projectId, formResponse, clientWebsiteDetailsPageUrl)}
          formSpec={{
            'data[client_website_hide_title]': {
              type: 'boolean',
            },
          }}
          errors={errors}
        >
          <ClientWebsitePeopleDetailsFormContainer id={id} />

          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
