import { PricingPlanFeaturesService, QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';

import QRCodePageRightSidebar from './QRCodePageRightSidebar';
import { qrCodeDesignsBackgroundPosterDataSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    projectId: string;
  },
) {
  const { folderId, projectId } = ownProps;
  return {
    folderId,
    projectId,
    backgroundPoster: qrCodeDesignsBackgroundPosterDataSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePageRightSidebar);
