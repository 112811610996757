import { FormGroup, RealCheckbox } from '@premagic/myne';
import React from 'react';

export default function WatermarkRemoveOnDownloadsOption(props: { removeOnDownloads?: boolean; name: string }) {
  const { removeOnDownloads = false, name } = props;

  return (
    <FormGroup>
      <RealCheckbox name={name} defaultValue={removeOnDownloads}>
        Remove this watermark when attendee downloads the photos
      </RealCheckbox>
    </FormGroup>
  );
}
