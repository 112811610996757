import { FolderService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import { projectEventDataSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { clientIdForEventSelector, crmEventItemsSelector, updateEventName } from '../EventsDataDuck';
import EditEventNamePanel from './EditEventNamePanel';

const PANEL_KEY = LOADINGS.EVENT.SHOW_EDIT_NAME_PANEL;
const COMMON_KEY = LOADINGS.EVENT.UPDATE_NAME;

function mapStateToProps(
  state,
  ownProps: {
    projectId?: string;
    eventId: string;
  },
) {
  const { projectId, eventId } = ownProps;
  const clientId = clientIdForEventSelector(eventId)(state);

  const clientWebsiteIds = projectId
    ? folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.CLIENT_WEBSITE, {
        is_shared: true,
      })(state)
    : [];
  const websiteId = clientWebsiteIds[0];

  return {
    clientId,
    eventId,
    websiteId,
    event: crmEventItemsSelector(state)[eventId],
    projectId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    eventData: projectId ? projectEventDataSelector(projectId)(state) : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },

    updateEventName: (
      options: { eventId: string; clientId: string; projectId?: string; websiteId?: string },
      newName: string,
    ) => {
      dispatch(updateEventName(dispatch, options, newName));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEventNamePanel);
