import React from 'react';
import { InnerPageContentMainCol, InnerPageContentSidebar } from '@premagic/myne';
import ClientWebsiteClientPhotosContainer from './ClientWebsiteClientPhotosContainer';
import ClientWebsitePageSidebarContainer from '../details/ClientWebsitePageSidebarContainer';

type Props = {
  folderId: string;
  projectId: string;
};

export default function ClientWebsiteClientPhotosLayout(props: Props): React.ReactElement {
  const { projectId, folderId } = props;

  return (
    <>
      <InnerPageContentMainCol>
        <ClientWebsiteClientPhotosContainer id={folderId} projectId={projectId} />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <ClientWebsitePageSidebarContainer projectId={projectId} id={folderId} />
      </InnerPageContentSidebar>
    </>
  );
}
