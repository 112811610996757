import { connect } from 'react-redux';
import { CalendarEvent } from '@premagic/myne';
import { OccasionsCalendarItemContent } from './OccasionsCalendarItemContent';
import { calendarOccasionItemsSelector } from './OccasionsCalendarDataDuck';

function mapStateToProps(state, ownProps: { event: CalendarEvent }) {
  const { event: occasionInfo } = ownProps;
  const { id } = occasionInfo;
  const occasion = calendarOccasionItemsSelector(state)[id];

  return {
    occasion,
  };
}

export default connect(mapStateToProps)(OccasionsCalendarItemContent);
