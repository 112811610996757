import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';

import { pagesSelector } from '../../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_EXHIBITOR', true);

export const setEditOfExhibitor = createAction(
  getActionType('ITEM', 'EDIT'),
  (dispatch: Dispatch, exhibitorId: string | null) => exhibitorId,
);

export const setSearchTermForExhibitor = createAction(
  getActionType('SEARCH', 'SET'),
  (dispatch: Dispatch, searchTerm: string | null) => searchTerm,
);

export const updateExhibitorListStyle = createAction(
  getActionType('LIST', 'DETAILED'),
  (dispatch: Dispatch, show: 'list' | 'detailed') => show,
);

type EventExhibitorsPageType = {
  editExhibitorId: string | null;
  searchTerm?: string;
  listStyle: 'list' | 'detailed';
};

const initialState = {
  editExhibitorId: undefined,
  searchTerm: undefined,
  listStyle: 'list',
};

export default handleActions(
  {
    [setEditOfExhibitor.toString()]: (state, action: { payload }) => ({
      ...state,
      editExhibitorId: action.payload,
    }),
    [setSearchTermForExhibitor.toString()]: (state, action: { payload }) => ({
      ...state,
      searchTerm: action.payload,
    }),
    [updateExhibitorListStyle.toString()]: (state, action: { payload }) => ({
      ...state,
      listStyle: action.payload,
    }),
  },
  initialState,
);

export const eventExhibitorPageSelector = createSelector(
  pagesSelector,
  (state) => state.eventExhibitor.eventExhibitor as EventExhibitorsPageType,
);

export const exhibitorIdForEditSelector = createSelector(eventExhibitorPageSelector, (page) => page.editExhibitorId);
export const exhibitorSearchTermSelector = createSelector(eventExhibitorPageSelector, (page) => page.searchTerm);
export const exhibitorListStyleSelector = createSelector(eventExhibitorPageSelector, (page) => page.listStyle);
