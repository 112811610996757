import { EventAttendeesCheckinZoneService } from '@premagic/core';
import { connect } from 'react-redux';
import EventAttendeeFaceChekinZoneForm from './EventAttendeeFaceChekinZoneForm';

function mapStateToProps(
  state,
  ownProps: { projectId: string; zoneData?: EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneType },
) {
  const { zoneData } = ownProps;
  return { zoneData };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceChekinZoneForm);
