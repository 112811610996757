import React from 'react';
import {
  Col,
  Color,
  COLOR_SHADES,
  FormErrorType,
  FormResponseType,
  Icon,
  ICON_SIZES,
  RequestFeatureEnabling,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ClientSettingsService, PricingPlanFeaturesService, ProjectMetaService, ProjectService } from '@premagic/core';
import ProjectMetaFeatureLabel from './ProjectMetaFeatureLabel';
import ProjectMetaFeatureToggleButton from './ProjectMetaFeatureToggleButton';
import ProjectMetaDownloadQualitySettingsContainer from './ProjectMetaDownloadQualitySettingsContainer';
import PhotoDownloadPaymentSettingsContainer from './PhotoDownloadPaymentSettingsContainer';

type Props = {
  projectId: string;
  project: ProjectService.ProjectType;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateProjectMeta: (projectId: string, data: FormResponseType) => void;
  onChange?: (value: boolean) => void;
  errors?: FormErrorType;
  isLoading: boolean;
  itemKey: ProjectMetaService.PROJECT_META_TOGGLE_ITEMS;
  isStaffUser: boolean;
  clientSettings: ClientSettingsService.ClientSettingsType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.GALLERY_PASSWORD_PROTECTION]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FACES]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES]: boolean;
  };
};

const FEATURE_WHICH_REQUIRE_EVENT = [ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_ALBUM];

export default function ProjectMetaToggle(props: Props) {
  const {
    projectId,
    project,
    projectMeta,
    updateProjectMeta,
    errors,
    isLoading,
    itemKey,
    hasFeatures,
    isStaffUser,
    clientSettings,
    onChange,
  } = props;
  const { key, label, feature, isStaffToggleable, handleUndefinedAsTrue, invertValue } =
    ProjectMetaService.PROJECT_META_TOGGLE_ITEMS_DETAILS[itemKey];
  const projectHasEvents = project.event_id;
  const disableEnabling = FEATURE_WHICH_REQUIRE_EVENT.includes(itemKey) && !projectHasEvents;

  // conditionally selecting the toggleValue because we will not get 'enable_download' keys in the project meta for older projects
  let toggleValue;
  if (key === ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD) {
    toggleValue =
      projectMeta && 'enable_highlight_download' in projectMeta
        ? projectMeta?.enable_highlight_download
        : clientSettings.enable_highlight_download;
  } else if (key === ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_SELECTION_DOWNLOAD) {
    toggleValue =
      projectMeta && 'enable_selection_download' in projectMeta
        ? projectMeta?.enable_selection_download
        : clientSettings.enable_selection_download;
  } else if (handleUndefinedAsTrue) {
    toggleValue = projectMeta && key in projectMeta ? projectMeta[key] : true;
  } else {
    toggleValue = (projectMeta && projectMeta[key]) || false;
  }
  if (invertValue) {
    toggleValue = !toggleValue;
  }

  const hasFeature = hasFeatures[feature];
  function onUpdate(id: string, data: FormResponseType) {
    updateProjectMeta(id, data);
    if (onChange) onChange(data.data[key] as boolean);
  }

  if (isStaffToggleable && !hasFeature) {
    return (
      <Row>
        <div>
          <ProjectMetaFeatureLabel itemKey={itemKey} primaryStyle showPremiumFeatureButton={false} />
          <Space size={5} />
          <RequestFeatureEnabling featureName={label} projectName={project.project_name || 'no-set'} />
          <Space vertical size={1} />
          {isStaffUser && (
            <Text color={COLOR_SHADES.PRIMARY} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5}>
              <Color shade={COLOR_SHADES.DANGER} inline>
                <Icon name="sun" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} />
              You! The staff user can only enable this!
            </Text>
          )}
        </div>
        <Col size={2} rightAlighed>
          <ProjectMetaFeatureToggleButton
            invertValue={invertValue}
            hasFeature={isStaffUser}
            isLoading={isLoading}
            name={key}
            checked={toggleValue}
            updateProjectMeta={onUpdate}
            projectId={projectId}
          />
        </Col>
      </Row>
    );
  }

  if (disableEnabling && hasFeature) {
    return (
      <Row>
        <div>
          <ProjectMetaFeatureLabel itemKey={itemKey} />
          <Space size={1} vertical />
          <Row>
            <Color shade={COLOR_SHADES.WARNING} inline>
              <Icon name="info" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={COLOR_SHADES.WARNING} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5}>
              Can be enabled only for projects created with an Event
            </Text>
          </Row>
        </div>
        <Col size={2} rightAlighed>
          <ProjectMetaFeatureToggleButton
            hasFeature={false}
            isLoading={isLoading}
            name={key}
            checked={toggleValue}
            updateProjectMeta={onUpdate}
            projectId={projectId}
            invertValue={invertValue}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <div>
        <ProjectMetaFeatureLabel itemKey={itemKey} showPremiumFeatureButton={!hasFeature} />
        {itemKey === ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD && toggleValue && (
          <>
            <Row>
              <Space />
              <ProjectMetaDownloadQualitySettingsContainer projectId={projectId} />
            </Row>
            <Space vertical size={1} />
            <Row>
              <Space />
              <PhotoDownloadPaymentSettingsContainer projectId={projectId} />
            </Row>
          </>
        )}
        <Space vertical size={1} />
      </div>
      <Col size={2} rightAlighed>
        <ProjectMetaFeatureToggleButton
          hasFeature={hasFeature}
          isLoading={isLoading}
          name={key}
          checked={toggleValue}
          updateProjectMeta={onUpdate}
          projectId={projectId}
          invertValue={invertValue}
        />
      </Col>
    </Row>
  );
}
