import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
} from '@premagic/myne';
import EventPosterFormContainer from './MemoryTemplateFormContainer';

type Props = {
  id?: string;
  editTemplate: (projectId: string, templateId: string, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
};

export default function EditMemoryTemplatePanel(props: Props) {
  const { show, close, projectId, editTemplate, isLoading, error, id } = props;
  if (!id) return null;
  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Edit Template</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          formSpec={{
            name: { validator: 'required' },
            age: { validator: 'required' },
            target: { validator: 'required' },
          }}
          onSubmit={(formResponse) => editTemplate(projectId, id, formResponse)}
        >
          <EventPosterFormContainer projectId={projectId} id={id} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
