import { connect } from 'react-redux';
import EditClientPaymentPanel from './EditClientPaymentPanel';
import { LOADINGS } from '../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { crmPaymentsSelector, updatePayment } from '../CRMPaymentDataDuck';
import { paymentIdForEditSelector, setEditOfPayment } from '../../events/details/EventDetailsPageDuck';

const PANEL_KEY = LOADINGS.CRM_PAYMENTS.SHOW_EDIT_CLIENT_PAYMENT_PANEL;
const COMMON_KEY = LOADINGS.CRM_PAYMENTS.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;
  const paymentId = paymentIdForEditSelector(state);
  return {
    eventId,
    paymentId,
    payment: paymentId ? crmPaymentsSelector(state)[paymentId] : undefined,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePayment: (paymentId: string, eventId: string, data) => {
      dispatch(updatePayment(dispatch, { paymentId, eventId }, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(setEditOfPayment(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditClientPaymentPanel);
