import { connect } from 'react-redux';
import { LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';

import ProposalThumbnailPanel from './ProposalThumbnailPanel';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL.SHOW_THUMBNAIL;
const KEY = ActionConstants.KEYS.PROPOSAL_DECK.FETCH_ALL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  const proposal = ProposalDataDuck.proposalsItemsSelectors(state)[folderId];

  return {
    projectId,
    folderId,
    proposal,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalThumbnailPanel);
