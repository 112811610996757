import { LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { EventPosterService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';
import { activeSidebarViewSelector, setActiveSidebarView } from '../EventPosterCreatorDuck';
import EventPosterCreatorSidebar from './EventPosterCreatorSidebar';

function mapStateToProps(
  state,
  ownProps: { projectId: string; posterId?: string; type: EventPosterService.EVENT_POSTER_TYPE },
) {
  const { projectId, posterId, type } = ownProps;
  const poster = posterId ? eventPostersSelector(state)[posterId] : undefined;
  return {
    projectId,
    posterId,
    poster,
    type,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.SINGLE_FETCH)(state),
    view: activeSidebarViewSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditEventPoster: (posterId) => {
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.EVENT_POSTERS.SHOW_EDIT_PANEL, posterId));
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_EDIT_PANEL, true));
    },
    setActiveSidebarView: (view) => {
      dispatch(setActiveSidebarView(dispatch, view));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterCreatorSidebar);
