import { connect } from 'react-redux';

import ProposalCreatorProposalFolderDataUpdater from './ProposalCreatorProposalFolderDataUpdater';
import { folderMetaDataForFolderSelector, saveFolderMetaData } from '../../../projects/folder-meta/FoldersMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    folderMeta: folderMetaDataForFolderSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveFolderMeta: (projectId, folderId, data) =>
      dispatch(
        saveFolderMetaData(dispatch, projectId, folderId, {
          ...data,
          loadFonts: true,
        }),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorProposalFolderDataUpdater);
