import { FileService } from '@premagic/core';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { pagesSelector } from '../../../reducers/selectors';
import { DAM_LIBRARY_FILTER_KEYS } from './filters/DAMLibraryFilterTypes';
import { navigateToDAMLibraryPageWithFilter } from './filters/DAMLibraryFilterDuck';
import { setFilesData } from '../../images/FilesDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('DAM_LIBRARY_DATA', true);

const setFilterLabels = createAction(getActionType('FILTER_LABELS', 'SET'), (dispatch, data) => data);

export const resetDAMLibraryData = createAction(getActionType('DATA', 'RESET'), (dispatch) => {
  dispatch(setFilterLabels(dispatch, {}));
  dispatch(navigateToDAMLibraryPageWithFilter(dispatch, {}));
  dispatch(setFilesData(dispatch, {}));
});

export const getDAMFilterLabels = createAction(
  getActionType('FILTER_LABELS', 'FETCH'),
  (dispatch, projectId: Array<string> | string) => {
    const loadingKey = LOADINGS.DAM_LIBRARY.FETCH_FILTER_LABELS;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FileService.fetchFilterLabelsForDAMLibrary(projectId)
      .then((response) => {
        // THis will have key_people and tags
        dispatch(setFilterLabels(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('DAM_FILTER_LABELS_FETCH_ERROR', e);
      });
  },
);

type DAMFilterLabelsType = IMap<DAM_LIBRARY_FILTER_KEYS, Array<string>>;

type StateType = {
  filter_labels: DAMFilterLabelsType;
};

const initialState = {
  filter_labels: IMap({}),
};

export default handleActions(
  {
    [setFilterLabels.toString()]: (state, action) => ({
      ...state,
      filter_labels: IMap(action.payload),
    }),
  },
  initialState,
);

const damLibraryDataSelector = createSelector(pagesSelector, (pages) => pages.DAMLibrary.data.DAMData as StateType);
const damLibraryFilterLabelsSelector = createSelector(damLibraryDataSelector, (data) => data.filter_labels);

export const damLibraryFilterLabelsDataSelector = createSelector(damLibraryFilterLabelsSelector, (data) =>
  data.toJSON(),
);

export const damLibraryFilterLabelsByFilterKeySelector = (filterKey: DAM_LIBRARY_FILTER_KEYS) =>
  createSelector(damLibraryFilterLabelsSelector, (filterLabelsData) => filterLabelsData.get(filterKey) || []);
