import { ClientSettingsService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  TEXT_BUTTON_STYLES,
  BUTTON_SIZE,
  TextButton,
  Form,
  FormGroup,
  Text,
  TEXT_BOLDNESS,
  INPUT_SIZES,
  FormFooter,
  Dialog,
  Select,
} from '@premagic/myne';
import { MediaUtils } from '@premagic/utils';
import React, { useMemo, useRef, useState } from 'react';

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateProjectMeta: (projectId: string, data) => void;
  hasSinglePhotoDownloadQualityPermissions: boolean;
};

export default function ProjectMetaDownloadQualitySettings(props: Props) {
  const { projectMeta = {}, updateProjectMeta, projectId, hasSinglePhotoDownloadQualityPermissions } = props;

  const $button = useRef(null);
  const [isDialogShown, toggleDialog] = useState(false);
  const downloadQualityOption = useMemo(
    () =>
      Object.entries(ProjectMetaService.SINGLE_FILE_DOWNLOAD_QUALITY_DETAILS).map(([key, value]) => ({
        label: value.title,
        value: key,
      })),
    [],
  );
  const selectedSingleFileDownloadValue =
    projectMeta[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SINGLE_FILE_DOWNLOAD_QUALITY] ||
    MediaUtils.DOWNLOAD_QUALITY_TYPES.RESOLUTION_4K;
  const selectionOptions =
    downloadQualityOption.find((option) => option.value === selectedSingleFileDownloadValue) ||
    downloadQualityOption[0];

  function onSubmit(data) {
    updateProjectMeta(projectId, data);
    toggleDialog(false);
  }

  if (!hasSinglePhotoDownloadQualityPermissions) return null;
  return (
    <div>
      <TextButton
        ref={$button}
        style={TEXT_BUTTON_STYLES.SECONDARY}
        onClick={() => toggleDialog(!isDialogShown)}
        size={BUTTON_SIZE.MD}
      >
        {selectionOptions.label}
      </TextButton>
      <Dialog target={$button.current} show={isDialogShown} onClose={() => toggleDialog(false)}>
        <Form onSubmit={(formData) => onSubmit(formData)}>
          <FormGroup>
            <Row vcenter>
              <Color shade={COLOR_SHADES.DARKER} inline>
                <Icon name="download" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} />
              <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                Single file download quality
              </Text>
            </Row>
            <Space size={2} vertical />
            <Select
              size={INPUT_SIZES.SM}
              isMulti={false}
              name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SINGLE_FILE_DOWNLOAD_QUALITY}
              value={selectionOptions}
              options={downloadQualityOption}
            />
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY}>Save</Button>
          </FormFooter>
        </Form>
      </Dialog>
    </div>
  );
}
