import { QRCodeBackgroundPosterService } from '@premagic/core';
import { BUTTON_SIZE, BUTTON_STYLES, Button, COLOR_SHADES, Color, ICON_SIZES, Icon, Row, Space } from '@premagic/myne';
import QRCodeCustomLEDBackgroundPosterContainer from '../../../background-poster/QRCodeCustomLEDBackgroundPosterContainer';
import QRCodePosterCardImage from '../../common-ui/QRCodePosterCardImage';

type Props = {
  projectId: string;
  selectedPoster?: string;
  openCustomPosterSelectModal: () => void;
  removeCustomLEDPoster: (
    projectId: string,
    folderId: string,
    qrCodeDesigns: QRCodeBackgroundPosterService.QRCodeDesignType,
  ) => void;
  folderId: string;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodeCustomLEDPoster(props: Props) {
  const { projectId, folderId, qrCodeDesigns, openCustomPosterSelectModal, selectedPoster, removeCustomLEDPoster } =
    props;

  function handleSelectCustomPoster() {
    openCustomPosterSelectModal();
  }

  function handleRemoveCustomPoster() {
    removeCustomLEDPoster(projectId, folderId, qrCodeDesigns || {});
  }

  if (!selectedPoster) {
    return (
      <Row columnDirection vcenter>
        <QRCodePosterCardImage>
          <Color inline shade={COLOR_SHADES.LIGHT}>
            <Icon name="image" size={ICON_SIZES.MD} />
          </Color>
        </QRCodePosterCardImage>
        <Space size={2} vertical />
        <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleSelectCustomPoster}>
          Select
        </Button>
      </Row>
    );
  }

  return (
    <Row columnDirection vcenter>
      <QRCodePosterCardImage>
        <QRCodeCustomLEDBackgroundPosterContainer fileId={selectedPoster} />
      </QRCodePosterCardImage>
      <Space size={2} vertical />
      <Row spaceBetween>
        <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleSelectCustomPoster}>
          Select
        </Button>
        <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleRemoveCustomPoster}>
          Remove
        </Button>
      </Row>
    </Row>
  );
}
