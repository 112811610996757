import React, { useEffect } from 'react';
import { List, Text, Card, InnerPage, InnerPageContent, Space, Col } from '@premagic/myne';
import ProposalTemplateItemContainer from './ProposalTemplateItemContainer';
import ProposalVariablesListContainer from '../proposal-variables/ProposalVariablesListContainer';
import ProposalSettingsHeader from '../../settings/proposal/ProposalSettingsHeader';

type Props = {
  initData: () => void;
  proposalIds: Array<string>;
  isLoading: boolean;
  currentUrlPathname: string;
};

export default function ProposalTemplatesPage(props: Props): JSX.Element {
  const { initData, currentUrlPathname } = props;
  useEffect(() => {
    initData();
  }, [initData]);

  const { proposalIds, isLoading } = props;

  return (
    <InnerPage>
      <ProposalSettingsHeader currentUrlPathname={currentUrlPathname} />
      <InnerPageContent isLoading={isLoading}>
        <div>
          {proposalIds.length === 0 ? (
            <div>
              <Text muted>You haven&apos;t added any Proposal templates</Text>
            </div>
          ) : (
            <Card>
              <List>
                {proposalIds.map((id) => (
                  <ProposalTemplateItemContainer key={id} id={id} />
                ))}
              </List>
            </Card>
          )}
          <Space vertical size={12} />
          <Col>
            <ProposalVariablesListContainer />
          </Col>
        </div>
      </InnerPageContent>
    </InnerPage>
  );
}
