import { connect } from 'react-redux';
import { duplicateOccasion, occasionItemsSelector } from '../OccasionsDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import EventOccasionOptionsMenu from './EventOccasionOptionsMenu';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { hasPermission } from '../../users/UsersDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';

function mapStateToProps(state, ownProps: { occasionId: string; eventId: string }) {
  const { occasionId, eventId } = ownProps;

  return {
    eventId,
    occasionId,
    occasion: occasionItemsSelector(state)[occasionId],
    hasPermissionsToManageEvent: hasPermission(PERMISSIONS.EVENT_OCCASION_MANAGE)(state),
    isLoading: isLoadingSelector(LOADINGS.OCCASIONS.DUPLICATE(occasionId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    duplicateOccasion: (eventId: string, occasionId: string, data) => {
      dispatch(duplicateOccasion(dispatch, eventId, occasionId, data));
    },
    showDeleteDialog: (occasionId: string) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_DELETE_DIALOG(occasionId), true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventOccasionOptionsMenu);
