import { connect } from 'react-redux';
import { ClientSettingsService } from '@premagic/core';
import { clientSettingsDataSelector } from '../../../../settings/preferences/ClientSettingsDataDuck';
import EventFeedbackButton from './EventFeedbackButton';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps;
  return {
    eventId,
    hasEnabled:
      clientSettingsDataSelector(state)[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SHOW_EVENT_FEEDBACK_FORM],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFeedbackButton);
