import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { ProjectMetaService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
} from '../../projects/project-meta/ProjectsMetaDataDuck';
import AIPhotoDeliveryNotificationInfo from './AIPhotoDeliveryNotificationInfo';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_STATUS;
const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (projectId, formResponse) => {
      dispatch(
        saveProjectMetaData(dispatch, projectId, formResponse, {
          itemId: META_ITEM,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AIPhotoDeliveryNotificationInfo);
