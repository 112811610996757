import { EventPosterService } from '@premagic/core';
import { FormGroup, FormLabel, GroupedButton, GroupedButtons, Icon, ICON_SIZES, Row, Space } from '@premagic/myne';
import { PosterWidgetTextType } from '@premagic/poster-creator';
import React from 'react';

const OPTIONS = {
  HORIZONTAL: [
    { value: 'left', label: 'Left', icon: 'align_left' },
    { value: 'center', label: 'Center', icon: 'align_center' },
    { value: 'right', label: 'Right', icon: 'align_right' },
  ],
  VERTICAL: [
    { value: 'top', label: 'Top', icon: 'align_top_simple' },
    { value: 'middle', label: 'Middle', icon: 'align_center_horizontal_simple' },
    { value: 'bottom', label: 'Bottom', icon: 'align_bottom_simple' },
  ],
};

type Props = {
  poster: EventPosterService.EventPosterType;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetTextType;
  }) => void;
  projectId: string;
  widget: PosterWidgetTextType;
};
const INPUT_NAME = 'font_align';
export default function EventPosterFontAlignment(props: Props) {
  const { poster, editWidget, projectId, widget } = props;
  function onChange(key: 'font_align' | 'font_vertical_align', value: string) {
    const updatedWidget = Object.assign({}, widget, {
      [key]: value,
    });
    editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
  }
  const horizontalValue = widget.font_align;
  const verticalValue = widget.font_vertical_align;
  return (
    <Row>
      <FormGroup>
        <Row>
          <GroupedButtons>
            {OPTIONS.HORIZONTAL.map((item) => (
              <GroupedButton
                title={item.label}
                active={item.value === horizontalValue}
                onClick={() => onChange('font_align', item.value)}
              >
                <Icon name={item.icon} size={ICON_SIZES.SM} />
              </GroupedButton>
            ))}
          </GroupedButtons>
          <Space />
          <GroupedButtons>
            {OPTIONS.VERTICAL.map((item) => (
              <GroupedButton
                title={item.label}
                active={item.value === verticalValue}
                onClick={() => onChange('font_vertical_align', item.value)}
              >
                <Icon name={item.icon} size={ICON_SIZES.SM} />
              </GroupedButton>
            ))}
          </GroupedButtons>
        </Row>
      </FormGroup>
      <Space />
    </Row>
  );
}
