import axios, { AxiosInstance } from 'axios';
import { ErrorTracker, PromiseUtils } from '@premagic/utils';
import { getPromiseBucket } from './APIURLService';

const { CancelToken } = axios;

export function addAxiosAuthorizationToken(AppAxios: AxiosInstance) {
  AppAxios.interceptors.request.use(
    (requestObj) => {
      const { url, method = '' } = requestObj;
      const bucket = getPromiseBucket(url as string);
      if (['GET', 'get'].includes(method) && bucket) {
        const source = CancelToken.source();
        PromiseUtils.addPendingPromise(source, url as string, bucket);
        // eslint-disable-next-line no-param-reassign
        requestObj.cancelToken = source.token;
      }
      return requestObj;
    },

    (error) => Promise.reject(error),
  );

  AppAxios.interceptors.response.use(
    (response) => {
      PromiseUtils.removeFromPendingPromise(response.config.cancelToken);
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (axios.isCancel(error)) {
        ErrorTracker.logAction('Request canceled By User', error as unknown as any);
        return Promise.reject(new Error('Request canceled by user'));
      }
      return Promise.reject(error);
    },
  );
}
