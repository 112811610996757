import { connect } from 'react-redux';

import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import PendingPhotographerActions from './PendingPhotographerActions';
import { fetchAllPhotographerActions, pendingPhotographerActionsSelector } from './PhotographerActionsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  return {
    actions: pendingPhotographerActionsSelector(projectId)(state),
    isLoading: isLoadingSelector(LOADINGS.PHOTOGRAPHER_ACTIONS.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingActions: () => dispatch(fetchAllPhotographerActions(dispatch, 'new')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingPhotographerActions);
