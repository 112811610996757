import React from 'react';
import { ClientWebsiteService } from '@premagic/core';

import { Row, Space, ErrorBoundary, VIDEO_TYPES, VideoJsPlayer } from '@premagic/myne';

type Props = {
  file?: ClientWebsiteService.ClientWebsiteFileType;
};

export default function ClientWishFile(props: Props): JSX.Element | null {
  const { file } = props;

  if (!file) return null;
  return (
    <div>
      <Row center>
        <ErrorBoundary>
          <div
            style={{
              minWidth: '200px',
              width: '100%',
              height: '400px',
            }}
          >
            <VideoJsPlayer src={file.public_url} autoplay={false} type={VIDEO_TYPES.STREAM} poster={file.thumbnail} />
          </div>
        </ErrorBoundary>
      </Row>
      <Space vertical />
    </div>
  );
}
