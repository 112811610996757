import React from 'react';
import { SCROLLABLE_CONTAINER_SIZE, ScrollableContainer } from '@premagic/myne';
import AccountFolderPhotoDropZoneContainer from '../../folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderUploadProgressContainer from '../../folders/folder-files-upload/FolderUploadProgressContainer';
import FolderFilesUploadErrorContainer from '../../folders/folder-files-upload/FolderFilesUploadErrorContainer';
import FolderGalleryWithFileSelectContainer from '../../folders/gallery/FolderGalleryWithFileSelectContainer';

type Props = {
  projectId: string;
  folderId?: string;
  onSelect: (fileId: string) => void;
  selectedFileIds?: string[];
};

export default function SponsorFiles(props: Props): JSX.Element | null {
  const { projectId, folderId, onSelect, selectedFileIds } = props;
  if (!folderId) return null;
  return (
    <div>
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
      <FolderUploadProgressContainer folderId={folderId} />
      <FolderFilesUploadErrorContainer folderId={folderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <FolderGalleryWithFileSelectContainer
          projectId={projectId}
          folderId={folderId}
          onSelect={onSelect}
          groupBy={selectedFileIds ? { label: 'Selected', items: selectedFileIds } : undefined}
        />
      </ScrollableContainer>
    </div>
  );
}
