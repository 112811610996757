import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { EventCustomFromFieldsService, EventTrackerService } from '@premagic/core';
import { LoadingDuck, ErrorDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { entitiesDataSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_FORM_FIELDS');

const setCustomEventFromFieldsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addCustomEventFromFieldData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const removeCustomEventFromFieldData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const fetchCustomEventFromFields = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.FETCH;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    EventCustomFromFieldsService.fetchFormFields(projectId)
      .then((response) => {
        dispatch(setCustomEventFromFieldsData(dispatch, response));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('CustomEventFromField: fetch failed', e);
      });
  },
);

export const addNewCustomEventFromField = createAction(
  getActionType('DATA', 'CREATE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: EventCustomFromFieldsService.NewFormFieldType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.ADD;

    if (formResponse.errors) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    EventCustomFromFieldsService.createFormField(projectId, formResponse.data)
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.USER_REGISTRATION_FORM.CREATE, {
          name: formResponse.data.label,
          type: formResponse.data.type,
        });
        dispatch(addCustomEventFromFieldData(dispatch, response));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(
            dispatch,
            LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_ADD_PANEL,
            false,
          ),
        );
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('CustomEventFromField: add new failed', e);
      });
  },
);

export const addSystemCustomFormField = createAction(
  getActionType('DATA', 'CREATE_SYSTEM_FORM_FIELD'),
  async (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.ADD;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    Promise.all(
      Object.entries(EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS_DETAILS)
        .filter(([, item]) => item.createByDefault)
        .map(([, item]) => EventCustomFromFieldsService.createFormField(projectId, item)),
    )
      .then((responses) => {
        dispatch(setCustomEventFromFieldsData(dispatch, responses));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return responses;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e as Record<string, unknown>));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('SystemCustomEventFromField: add new failed', e);
      });
  },
);

export const saveCustomEventFromField = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: {
      projectId: string;
      formFieldId: string;
    },
    formResponse: FormResponseType,
  ) => {
    const loadingKey = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.UPDATE;
    if (formResponse.errors) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    EventCustomFromFieldsService.updateFormField(options, formResponse.data)
      .then((response) => {
        dispatch(setCustomEventFromFieldsData(dispatch, response));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(
            dispatch,
            LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_EDIT_PANEL,
            false,
          ),
        );
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('CustomEventFromField: save failed', e);
      });
  },
);

export const removeCustomEventFromField = createAction(
  getActionType('ACTION', 'DELETE'),
  (
    dispatch,
    options: {
      projectId: string;
      formFieldId: string;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.DELETE;
    const { formFieldId: id } = options;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    dispatch(removeCustomEventFromFieldData(dispatch, id));

    EventCustomFromFieldsService.deleteFormField(options)
      .then((response) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('CustomEventFromField: delete failed', e);
      });
  },
);

type StateType = {
  items: IMap<string, EventCustomFromFieldsService.FormFieldType>;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setCustomEventFromFieldsData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addCustomEventFromFieldData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeCustomEventFromFieldData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.remove(action.payload),
    }),
  },
  initialState,
);

const eventCustomFormFieldsDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.customEventFormFields as StateType,
);

export const eventCustomFormFieldsItemsSelector = createSelector(
  eventCustomFormFieldsDataSelector,
  (state) => state.items,
);

export const eventCustomFormFieldIdsSelectors = (scope: EventCustomFromFieldsService.FORM_FIELD_SCOPE) =>
  createSelector(eventCustomFormFieldsDataSelector, (state) =>
    state.items
      .filter((item) => item.scope === scope)
      .sort((a, b) => a.position - b.position)
      .keySeq()
      .toArray(),
  );
export const eventCustomFormFieldsSelectors = (scope: EventCustomFromFieldsService.FORM_FIELD_SCOPE) =>
  createSelector(eventCustomFormFieldsDataSelector, (state) =>
    state.items
      .filter((item) => item.scope === scope)
      .valueSeq()
      .toArray(),
  );

export const eventCustomFormFieldsItemsSelectors = createSelector(eventCustomFormFieldsItemsSelector, (items) =>
  items.toJSON(),
);

export const hasSystemCustomFormFieldsSelector = createSelector(
  eventCustomFormFieldsItemsSelector,
  (items) => !!items.find((item) => item.name === EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE),
);
