import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import AddSelectionAlbumButton from './AddSelectionAlbumButton';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { hasActiveSubscriptionSelector } from '../../../settings/subscriptions/SubscriptionsDataDuck';
import { addFolderToProject } from '../../../projects/AccountProjectsDataDuck';

const FOLDER_TYPE = FolderService.FOLDER_TYPES.SELECTION;
function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    isLoading: isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.FOLDER.ADD)(state),
    hasActiveSubscription: hasActiveSubscriptionSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createFolder: (projectId, formResponse) => {
      dispatch(
        addFolderToProject(dispatch, formResponse, { projectId, folderType: FOLDER_TYPE, navigateToFolder: true }),
      );
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSelectionAlbumButton);
