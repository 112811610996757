import { Card, Row, Space } from '@premagic/myne';
import React from 'react';

type Props = {
  children: React.ReactElement;
};

export default function QRCodePosterCardImage(props: Props) {
  const { children } = props;

  return (
    <Card center cssStyle={{ margin: 0, width: 80, height: 80 }}>
      <Space size={2}>
        <Row center vcenter fullHeight>
          {children}
        </Row>
      </Space>
    </Card>
  );
}
