import React from 'react';
import {
  Card,
  CARD_SIZES,
  COLOR_SHADES,
  DECK_SLIDE_DATA_TYPES,
  DECK_SLIDE_LAYOUTS_DETAILS,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { ProposalSlideService } from '@premagic/proposals';
import { StringUtils } from '@premagic/utils';

type Props = {
  slide: ProposalSlideService.ProposalSlideType;
};

export default function ProposalSlidePreview(props: Props): JSX.Element {
  const { slide } = props;
  const { structure = {}, template_id: layoutId } = slide || {};

  const layoutDetails = DECK_SLIDE_LAYOUTS_DETAILS[layoutId];
  const stringTitle = StringUtils.getTextFromHTMLString(structure[DECK_SLIDE_DATA_TYPES.TITLE]?.value);
  const stringSubTitle = StringUtils.getTextFromHTMLString(structure[DECK_SLIDE_DATA_TYPES.SUB_TITLE]?.value);
  const stringContent = StringUtils.getTextFromHTMLString(structure[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?.value);

  return (
    <Card size={CARD_SIZES.SM} overflowHidden>
      <Space size={2}>
        <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
          {stringTitle || stringSubTitle || stringContent}
        </Text>
      </Space>
    </Card>
  );
}
