import { connect } from 'react-redux';
import HighlightDesignTextCard from './HighlightDesignTextCard';
import { toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  const { galleryWelcomeText, galleryEndingText } = ownProps;
  return { galleryWelcomeText, galleryEndingText };
}

function mapDispatchToProps(dispatch) {
  return {
    showPanel: () =>
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.HIGHLIGHT_DESIGN.SHOW_EDIT_TEXT_PANEL, true)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignTextCard);
