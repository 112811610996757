import { EventPosterService } from '@premagic/core';
import { Row, Space } from '@premagic/myne';
import { POSTER_SIZE, POSTER_SIZE_DETAIL } from '@premagic/poster-creator';

import AttendeeEventPosterContainer from './AttendeeEventPosterContainer';
import { PRINT_BADGE_CLASS } from '../../crm/events/event-attendees/event-attendee-badges/service/EventAttendeeBadgeDesignService';

type Props = {
  posterId: string;
  poster?: EventPosterService.EventPosterType;
  eventAttendeesIds: Array<string>;
};

export default function EventPosterPreviewLayout(props: Props) {
  const { poster, posterId, eventAttendeesIds } = props;
  const widgets = poster?.widgets || {};
  const zoomOut = (Number(poster?.size.width) || POSTER_SIZE_DETAIL[POSTER_SIZE.LANDSCAPE].width) > 600 ? 0.5 : 1;
  const width = (Number(poster?.size.width) || POSTER_SIZE_DETAIL[POSTER_SIZE.LANDSCAPE].width) * zoomOut;

  return (
    <div>
      <Space vertical size={8} />
      <Row columnDirection center>
        {eventAttendeesIds.map((id) => (
          <div
            className={PRINT_BADGE_CLASS}
            key={id}
            style={{
              width,
            }}
          >
            <AttendeeEventPosterContainer attendeeId={id} posterId={posterId} />
            <Space vertical size={8} />
          </div>
        ))}
      </Row>
    </div>
  );
}
