import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteFontSelector from './ClientWebsiteFontSelector';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps: { id: string; isTrialPackage?: boolean }) {
  const { id } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    hasPremiumDigitalInviteFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE_PREMIUM,
    )(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteFontSelector);
