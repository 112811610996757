import { createAction, handleActions } from 'redux-actions';
import { FormResponseType } from '@premagic/myne';
import { Dispatch } from 'redux';
import { List, Map as IMap } from 'immutable';
import { WindowPanelDuck } from '@premagic/common-ducks';
import { EventTrackerService, FolderService } from '@premagic/core';
import { ActionTypeUtils } from '@premagic/utils';
import { createSelector } from 'reselect';
import { pagesSelector, PagesStateType } from '../../reducers/selectors';
import { LOADINGS } from '../../../../common/Constants';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { getClientWebsite } from '../client-website/ClientWebsiteDataDuck';

import { updateFolderData } from '../projects/folders/AccountFoldersDataDuck';
import { toastMessage } from '../../reducers/ToastStore';
import { fetchProject } from '../projects/AccountProjectsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('FOLDER_SHARE', true);

export const setFolderIdsToShare = createAction(
  getActionType('FOLDER_SHARE_DATA', 'SET'),
  (dispatch: Dispatch, data) => data,
);

export const shareFolderWithClient = createAction(
  getActionType('FOLDER', 'SHARE'),
  (
    dispatch,
    folderId: string,
    projectId: string,
    formResponse: {
      data: {
        clients: Array<{ name: string; phone_number: string; email: string }>;
        expiry_date?: string;
        email_content?: string;
        skip_notifications?: boolean;
      };
    },
  ) => {
    const loadingKey = LOADINGS.FOLDER_SHARE.SHARE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;

    return FolderService.shareFolderWithClientService(projectId, [folderId], data)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        const tempFolderStatus = response.folder_status[folderId];
        dispatch(
          updateFolderData(dispatch, folderId, {
            share_url: tempFolderStatus.share_url,
            status: tempFolderStatus.status,
            is_shared: true,
          }),
        );
        dispatch(fetchProject(dispatch, projectId));
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.FOLDER_SHARE.SHARE, false));
        // const backToUrlProject = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.DETAILS, {
        //   projectId,
        // });
        // const backToUrlEvent =
        //   eventId &&
        //   BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
        //     eventId,
        //     focusId: folderType,
        //   });

        // navigateTo(dispatch, backToUrlEvent || backToUrlProject);
        toastMessage('info', 'Shared with your host 🚀');
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const shareMutipleFoldersWithClient = createAction(
  getActionType('FOLDER', 'SHARE'),
  (
    dispatch,
    folderIds: Array<string>,
    projectId: string,
    formResponse: {
      data: {
        clients: Array<{ name: string; phone_number: string; email: string }>;
        expiry_date?: string;
        email_content?: string;
        skip_notifications?: boolean;
      };
    },
  ) => {
    const loadingKey = LOADINGS.FOLDER_SHARE.SHARE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;

    FolderService.shareFolderWithClientService(projectId, folderIds, data)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        const tempFolderStatus = response.folder_status;
        folderIds.map((folderId) =>
          dispatch(
            updateFolderData(dispatch, folderId, {
              share_url: tempFolderStatus[folderId].share_url,
              status: tempFolderStatus[folderId].status,
              is_shared: true,
            }),
          ),
        );
        dispatch(fetchProject(dispatch, projectId));
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.FOLDER_SHARE.SHARE, false));
        toastMessage('info', 'Shared with your host 🚀');
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const shareClientWebsiteWithClient = createAction(
  getActionType('FOLDER_CLIENT_WEBSITE', 'SHARE'),
  async (
    dispatch,
    id: string,
    projectId: string,
    formResponse: FormResponseType & {
      data: {
        clients: Array<{ name: string; phone_number: string; email: string }>;
        expiry_date?: string;
        email_content?: string;
      };
    },
  ) => {
    await shareFolderWithClient(dispatch, id, projectId, formResponse).payload;

    // Refresh client website to update the published state information
    dispatch(getClientWebsite(dispatch, id, projectId));
  },
);

export const shareFoldersWithClientBasedOnFolderType = createAction(
  getActionType('FOLDER_TYPE', 'SHARE'),
  (
    dispatch,
    options: {
      folderIds: Array<string>;
      projectId: string;
      folderType: FolderService.FOLDER_TYPES;
    },
    formResponse: FormResponseType & {
      data: {
        clients: Array<{ name: string; phone_number: string; email: string }>;
        expiry_date?: string;
        email_content?: string;
        skip_notifications?: boolean;
      };
    },
  ) => {
    const { folderIds, projectId, folderType } = options;
    switch (folderType) {
      case FolderService.FOLDER_TYPES.CLIENT_WEBSITE:
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CLIENT_WEBSITE.SHARE, {
          folderType,
          totalFoldersShared: folderIds.length,
          totalClients: formResponse.data.clients.length,
        });
        return dispatch(shareClientWebsiteWithClient(dispatch, folderIds[0], projectId, formResponse));
      default:
        if (folderIds.length > 1) {
          return dispatch(shareMutipleFoldersWithClient(dispatch, folderIds, projectId, formResponse));
        }
        return dispatch(shareFolderWithClient(dispatch, folderIds[0], projectId, formResponse));
    }
  },
);

type StateType = {
  items: List<string>;
};

const initialState = {
  items: List(),
};

export default handleActions(
  {
    [setFolderIdsToShare.toString()]: (state, action: any) => ({
      ...state,
      items: List(action.payload),
    }),
  },
  initialState,
);

export const folderIdsToShareSelector = createSelector(pagesSelector, (state: PagesStateType) =>
  (state.folderShare as StateType).items.toArray(),
);
