import { EventPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  Row,
  Space,
} from '@premagic/myne';
import { PosterWidgetRectType } from '@premagic/poster-creator';
import React, { useEffect, useState } from 'react';

type Props = {
  poster: EventPosterService.EventPosterType;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetRectType;
  }) => void;
  projectId: string;
  posterId: string;
  widget: PosterWidgetRectType;
};
export default function EventPosterPositionSelector(props: Props) {
  const { projectId, editWidget, posterId, widget, poster } = props;
  // We need to handle the case when the position and size change from outside
  const [spec, setSpec] = useState({
    position: widget.position,
    size: widget.size,
    opacity: widget.opacity,
    corner_radius: widget.corner_radius,
  });
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    setSpec({
      position: widget.position,
      size: widget.size,
      opacity: widget.opacity,
      corner_radius: widget.corner_radius,
    });
  }, [widget.corner_radius, widget.opacity, widget.position, widget.size]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const inputName = event.target.name;
    let updateSpec = {};
    if (inputName.includes('.')) {
      const item = inputName.split('.');
      updateSpec = {
        [item[0]]: Object.assign({}, widget[item[0]], { [item[1]]: Number(value) }),
      };
    } else {
      // handle opacity & corner_radius
      updateSpec = { [inputName]: Number(value) };
    }
    setSpec((prev) => ({ ...prev, ...updateSpec }));

    const updatedWidget = Object.assign({}, widget, updateSpec);
    editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
  }
  if (!showOptions) {
    return (
      <Button onClick={() => setShowOptions(true)} size={BUTTON_SIZE.SM} style={BUTTON_STYLES.LINK}>
        Show Box options <Icon name="chevron_down" size={ICON_SIZES.SM} />
      </Button>
    );
  }

  return (
    <FormGroup>
      <Row>
        <Col size={4}>
          <FormLabel>Width</FormLabel>
          <Input
            name="size.width"
            type="number"
            value={Math.floor(Number(spec.size.width))}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </Col>
        <Space />
        <Col size={4}>
          <FormLabel>Height</FormLabel>
          <Input
            name="size.height"
            type="number"
            value={Math.floor(Number(spec.size.height))}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </Col>
      </Row>
      <Space vertical />
      <Row>
        <Col size={4}>
          <FormLabel>Corner radius</FormLabel>
          <Input
            name="corner_radius"
            type="number"
            value={Number(spec.corner_radius) || 0}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </Col>
        <Space />
        <Col size={4}>
          <FormLabel>Opacity</FormLabel>
          <Input
            name="opacity"
            type="number"
            min={0}
            max={1}
            step={0.1}
            value={Number(spec.opacity)}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </Col>
      </Row>
      <Space vertical />
      <Row>
        <Col size={4}>
          <FormLabel>X</FormLabel>
          <Input
            name="position.x"
            type="number"
            value={Math.floor(Number(spec.position.x))}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </Col>
        <Space />
        <Col size={4}>
          <FormLabel>Y</FormLabel>
          <Input
            name="position.y"
            type="number"
            value={Math.floor(Number(spec.position.y))}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </Col>
        <Space />
        {/* <Col size={4}> */}
        {/*  <FormLabel>Z</FormLabel> */}
        {/*  <Input */}
        {/*    name="position.z" */}
        {/*    type="number" */}
        {/*    value={Math.floor(Number(spec.position.z))} */}
        {/*    onChange={onChange} */}
        {/*    size={INPUT_SIZES.XS} */}
        {/*  /> */}
        {/* </Col> */}
      </Row>
    </FormGroup>
  );
}
