function getActionKey(scope: string) {
  return (action: string) => `@pl-${scope}/${action}`;
}

export const KEYS = {
  PROPOSALS_TEMPLATES: {
    FETCH: getActionKey('template')('fetch'),
    CREATE: getActionKey('template')('create'),
    UPDATE: getActionKey('template')('update'),
    DUPLICATE: getActionKey('template')('duplicate'),
  },
  PROPOSALS: {
    INIT: getActionKey('proposal')('init'),
    FETCH: getActionKey('proposal')('fetch-all'),
    DETAILS_FETCH: getActionKey('proposal')('fetch'),
    CREATE: getActionKey('proposal')('create'),
    UPDATE: getActionKey('proposal')('update'),
    SHOW_ADD_PANEL: getActionKey('proposal')('show-add-panel'),
  },
  PROPOSAL: {
    SHARE: getActionKey('proposal')('share'),
    DELETE: (id) => getActionKey('proposal')(`delete-${id}`),
    UPDATE: (id) => getActionKey('proposal')(`update-${id}`),
    SHOW_EDIT_NAME: getActionKey('proposal')('edit-name-dialog'),
    SHOW_EDIT_PROPOSAL_CONFIRMATION: getActionKey('proposal')('edit-proposal-confirm'),
    SHOW_DELETE_DIALOG: getActionKey('proposal-modal')(`delete`),
    SHOW_FILES_MODAL: getActionKey('proposal-modal')('files'),
    SELECT_FILE_MODAL: getActionKey('proposal-modal')('select-file'),
    SHOW_HISTORY: getActionKey('proposal-modal')('history'),
    SHOW_THUMBNAIL: getActionKey('proposal-modal')('thumbnail'),
  },

  PROPOSAL_DECK: {
    FETCH: (id: string) => getActionKey('deck')(`fetch-${id}`),
    FETCH_ALL: getActionKey('deck')('fetch-all'),
    ADD_FILES: getActionKey('deck')('add-files'),
    CREATE: getActionKey('deck')('create'),
    CREATE_VERSION: getActionKey('deck-version')('create'),
    UPDATE: getActionKey('deck')('update'),
    UPDATE_SLIDE_ORDER: getActionKey('deck')('update-slide-order'),
    DELETE: getActionKey('deck')('delete'),
    SHOW_UPDATE_VARIABLES_PANEL: getActionKey('proposal-variable-modal')('update'),
  },
  PROPOSAL_SLIDE: {
    CREATE: getActionKey('slide')('create'),
    FETCH: getActionKey('slide')('fetch'),
    UPDATE: (id: number) => getActionKey('slide')(`update-${id}`),
    DELETE: getActionKey('slide')('delete'),
    SHOW_ADD_DIALOG: getActionKey('slide')('show-add'),
  },
  PROPOSAL_FILE: {
    FETCH: getActionKey('deck-file')('fetch'),
    DELETE: getActionKey('deck-file')('delete'),
  },
  PROPOSAL_VARIABLES: {
    FETCH: getActionKey('variable')('fetch'),
    ADD: getActionKey('variable')('add'),
    UPDATE: getActionKey('variable')('update'),
    DELETE: getActionKey('variable')('delete'),
    SHOW_ADD_PANEL: getActionKey('variable')('show-add'),
    SHOW_EDIT_PANEL: getActionKey('variable')('show-edit'),
    SHOW_EDIT_PANEL_WITH_ID: (id) => getActionKey('variable')(`show-edit-${id}`),
  },
  SLIDE_DATA: {},
};
