import { EventAttendeesService, EventPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Grid,
  Icon,
  ICON_SIZES,
  Modal,
  MODAL_SIZES,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { POSTER_WIDGET_TYPE, PosterWidgetType } from '@premagic/poster-creator';
import { useMemo, useRef, useState } from 'react';
import { getDefaultDataForWidget, getWidgetToAddOptions } from './WidgetCreatorService';

function WidgetSelectorModal(props: {
  hideModal: () => void;
  onSelect: (widget: { name: string; icon: string; type: POSTER_WIDGET_TYPE; id?: string }) => void;
  widgets: Array<PosterWidgetType>;
  type: EventPosterService.EVENT_POSTER_TYPE;
  scope: Array<EventAttendeesService.EVENT_PEOPLE_TYPE>;
}) {
  const { hideModal, onSelect, widgets, type, scope } = props;

  const WIDGET_OPTIONS: Array<{
    name: string;
    title: string;
    icon: string;
    type: POSTER_WIDGET_TYPE;
    value?: string;
  }> = useMemo(() => getWidgetToAddOptions({ widgets, type, scope }), [widgets]);

  return (
    <Modal show showCloseButton close={hideModal} size={MODAL_SIZES.MD}>
      <Grid colSize={160}>
        {WIDGET_OPTIONS.map((widget) => (
          <div key={widget.name}>
            <Button size={BUTTON_SIZE.MD} onClick={() => onSelect(widget)} style={BUTTON_STYLES.TERTIARY} block>
              {widget.title}
            </Button>
          </div>
        ))}
      </Grid>
    </Modal>
  );
}

type Props = {
  projectId: string;
  poster: EventPosterService.EventPosterType;
  widgets: Array<PosterWidgetType>;
  addWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: Partial<PosterWidgetType>;
  }) => void;
  showSelectPosterModal: (widgetId: string) => void;
  type: EventPosterService.EVENT_POSTER_TYPE;
};

export default function AddEventPosterWidgetButton(props: Props) {
  const { poster, widgets, addWidget, projectId, showSelectPosterModal, type } = props;
  const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);

  const $target = useRef(null);

  function handleAddWidget(widget: { name: string; icon: string; type: POSTER_WIDGET_TYPE; id?: string }) {
    // Create new widget with default values
    const newWidget = getDefaultDataForWidget(widget, poster);
    addWidget({ projectId, widgetToUpdate: newWidget, poster });
    if (newWidget.type === POSTER_WIDGET_TYPE.IMAGE) {
      showSelectPosterModal(newWidget.id);
    }
    setShowAddWidgetModal(false);
  }
  return (
    <div>
      <Button
        style={BUTTON_STYLES.LINK}
        onClick={() => setShowAddWidgetModal(true)}
        ref={$target}
        size={BUTTON_SIZE.SM}
      >
        <Color shade={COLOR_SHADES.BLUE_DARK} inline fillStrokeIcon>
          <Icon name="plus" size={ICON_SIZES.SM} />
        </Color>
        <Space size={1} />
        Widget
      </Button>
      {showAddWidgetModal && (
        <WidgetSelectorModal
          hideModal={() => setShowAddWidgetModal(false)}
          widgets={widgets}
          onSelect={handleAddWidget}
          type={type}
          scope={poster.scope}
        />
      )}
      <Space vertical size={2} />
    </div>
  );
}
