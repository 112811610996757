import React, { useRef } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  DIALOG_SIZES,
  Form,
  FormErrorType,
  FormFooter,
  FormResponseType,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { CRMPaymentType } from '../CRMPaymentService';
import QuotePaymentFormContainer from './QuotePaymentFormContainer';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';

type Props = {
  eventId: string;
  quotePayment?: CRMPaymentType;
  addPayment: (eventId: string, data: FormResponseType) => void;
  updatePayment: (id: string, eventId: string, data: FormResponseType) => void;
  isLoading: boolean;
  error?: FormErrorType;
  isUpdating: boolean;
  updateError?: FormErrorType;
  toggleDialog: (show: boolean) => void;
  isDialogShown: boolean;
};

export default function QuotePaymentItem(props: Props): JSX.Element {
  const {
    eventId,
    quotePayment,
    addPayment,
    updatePayment,
    isLoading,
    error,
    isUpdating,
    updateError,
    toggleDialog,
    isDialogShown,
  } = props;
  function handleOnSave(formResponse) {
    if (quotePayment) {
      updatePayment(quotePayment.id, eventId, formResponse);
      return;
    }
    addPayment(eventId, formResponse);
  }
  const target = useRef(null);
  return (
    <Row>
      <Col size={4} screenSMSize={5}>
        <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
          Quote
        </Text>
      </Col>
      <Col size={6}>
        <div ref={target}>
          {quotePayment ? (
            <div>
              <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3}>
                <AccountCurrencyContainer amount={quotePayment.amount} />
              </Text>
              <Space size={2} />
              <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={() => toggleDialog(true)}>
                edit
              </Button>
            </div>
          ) : (
            <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.SM} onClick={() => toggleDialog(true)}>
              Set quote
            </Button>
          )}
        </div>
      </Col>
      {isDialogShown && (
        <Dialog
          size={DIALOG_SIZES.MD}
          target={target.current}
          show={isDialogShown}
          placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
          onClose={() => toggleDialog(false)}
        >
          <Space size={2}>
            <Form onSubmit={handleOnSave} errors={error || updateError}>
              <QuotePaymentFormContainer id={quotePayment?.id} />
              <FormFooter>
                <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading || isUpdating}>
                  Save
                </Button>
              </FormFooter>
            </Form>
          </Space>
        </Dialog>
      )}
    </Row>
  );
}
