import { ClientWebsiteService } from '@premagic/core';
import { Col, Row, Space, SubTitle } from '@premagic/myne';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type ClientWebsiteFontSelectorProps = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  hasPremiumDigitalInviteFeature?: boolean;
};

export default function ClientWebsiteFontSelector(props: ClientWebsiteFontSelectorProps) {
  const { clientWebsite, hasPremiumDigitalInviteFeature } = props;

  return (
    <Row>
      <Col>
        <ClientWebsiteFormItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_FONT_PRIMARY}
          clientWebsite={clientWebsite}
          disabled={!hasPremiumDigitalInviteFeature}
        />
      </Col>
      <Space />
      <Col>
        <ClientWebsiteFormItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_FONT_SECONDARY}
          clientWebsite={clientWebsite}
          disabled={!hasPremiumDigitalInviteFeature}
        />
      </Col>
    </Row>
  );
}
