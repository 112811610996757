import { connect } from 'react-redux';
import StorePageContent from './StorePageContent';
import { storeOffersItemIdsSelector } from './StoreOffersDataDuck';

function mapStateToProps(state) {
  return {
    storeOfferIds: storeOffersItemIdsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePageContent);
