import { connect } from 'react-redux';
import { ProposalDeckDataDuck } from '@premagic/proposals';

import ProposalSlideList from './ProposalSlideList';
import { proposalCreatorSelectedSlideSelector } from '../ProposalCreatorDuck';
import { appHeightSelector } from '../../../AppDuck';

function mapStateToProps(state, ownProps: { projectId: string; deckId: string; folderId: string }) {
  const { projectId, deckId, folderId } = ownProps;

  return {
    projectId,
    folderId,
    deckId,
    slideIds: ProposalDeckDataDuck.proposalsSlideIdsForDeckSelectors(deckId)(state),
    activeSlideId: proposalCreatorSelectedSlideSelector(state),
    appHeight: appHeightSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProposalDeckSidesOrder: (options) => {
      dispatch(ProposalDeckDataDuck.updateProposalDeckSidesOrder(dispatch, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSlideList);
