import { connect } from 'react-redux';

import { projectMetaDataForProjectSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';

import EventEmbedSettings from './EventEmbedSettings';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import {
  clientWebsiteForProjectSelector,
  eventProjectSelectorSelector,
} from '../../../projects/AccountProjectsDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    eventId,
    projectId,
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
    project,
    projectMeta,
    hasPermissions: {
      [PERMISSIONS.SETTINGS]: hasPermission(PERMISSIONS.SETTINGS)(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventEmbedSettings);
