import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { ActionTypeUtils } from '@premagic/utils';
import { LoadingDuck, ErrorDuck } from '@premagic/common-ducks';
import { FileService, ProjectService, Schemas } from '@premagic/core';

import { LOADINGS } from '../../common/Constants';
import { albumCreatorPageSelector } from '../../common/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM_IMAGES', false);

export const setAlbumImagesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchImageForAlbumCreator = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId) => {
  const loadingKey = LOADINGS.ALBUM.FETCH_IMAGES;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

  ProjectService.fetchAllSelectedPhotosForProject(projectId)
    .then((response) => {
      const normalizedData = normalize(response.data, Schemas.FoldersSchema);

      dispatch(setAlbumImagesData(dispatch, normalizedData.entities.images));
      // dispatch(
      //   setFolderAndImagesData(dispatch, {
      //     folders: normalizedData.entities.folders,
      //     images: normalizedData.entities.images,
      //   }),
      // );
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const resetImagesToFolder = createAction(getActionType('DATA', 'RESET'));

type StateType = {
  items: IMap<string, FileService.FolderFileType>;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [resetImagesToFolder.toString()]: (state, action) => ({
      ...state,
      items: IMap({}),
    }),
    [setAlbumImagesData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const albumCreatorImagesDataSelector = createSelector(
  albumCreatorPageSelector,
  (albumCreator) => albumCreator.data.images as StateType,
);

export const albumCreatorImageEntitiesDataSelector = createSelector(
  albumCreatorImagesDataSelector,
  (state: { items }) => state.items,
);

export const albumCreatorImageEntitiesSelector = createSelector(albumCreatorImagesDataSelector, (state: { items }) =>
  state.items.toJSON(),
);

export const imageItemsForAlbumSelector = createSelector(albumCreatorImageEntitiesDataSelector, (items) =>
  items
    .sort((a, b) => a.created_at - b.created_at)
    .toArray()
    .map((obj) => ({ ...obj[1], id: obj[0] })),
);

export const albumCreatorTotalPhotosCountSelector = createSelector(
  albumCreatorImageEntitiesDataSelector,
  (items) => items.size,
);
