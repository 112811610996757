import { connect } from 'react-redux';
import { DeviceDuck, ModalDuck } from '@premagic/common-ducks';

import { MODALS } from '../../../common/Constants';
import AlbumPreviewFooter from './AlbumPreviewFooter';
import { albumEntitySelector } from './AlbumDataDuck';

function mapStateToProps(state, ownProps) {
  const { albumId } = ownProps;

  return {
    albumId,
    album: albumEntitySelector(albumId)(state),
    isMobileUp: DeviceDuck.isMobileUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showConfirmAlbum: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.ALBUM.UPDATE_STATE, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumPreviewFooter);
