import { ClientSettingsService, StorePricingService } from '@premagic/core';
import { ButtonTile, BUTTON_TILE_SIZE, Card, CARD_SIZES, Row, Space } from '@premagic/myne';
import React, { useState } from 'react';
import StorePricingModalContainer from '../../store-pricing-selector/StorePricingModalContainer';

type Props = {
  storePricingData?: StorePricingService.StorePricingType;
  savePreferences: (data) => void;
};

export default function StorePricingDefaultSettings(props: Props) {
  const { storePricingData, savePreferences } = props;

  const [selectedProduct, setSelectedProduct]: [StorePricingService.ProductItemType | null, any] = useState(null);

  function handleFormSubmit(formData) {
    const { data } = formData;
    const dataToSave = {
      data: {
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.STORE_PRICING]: { ...storePricingData, ...data },
      },
    };
    savePreferences(dataToSave);
    setSelectedProduct(null);
  }

  return (
    <>
      <Card size={CARD_SIZES.FULL_WIDTH}>
        <Space>
          <Row wrap>
            {Object.values(StorePricingService.STORE_PRODUCTS_DETAILS).map((item) => (
              <div key={item.id}>
                <Space size={3}>
                  <ButtonTile size={BUTTON_TILE_SIZE.SM} onClick={() => setSelectedProduct(item)} title={item.label}>
                    {item.label}
                  </ButtonTile>
                </Space>
              </div>
            ))}
          </Row>
        </Space>
        <Space vertical size={35} />
        <Space vertical size={35} />
      </Card>
      <StorePricingModalContainer
        show={Boolean(selectedProduct)}
        selectedProduct={selectedProduct}
        onSave={handleFormSubmit}
        onClose={() => setSelectedProduct(null)}
      />
    </>
  );
}
