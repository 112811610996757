import { GlobalVariableService, HelpDeskService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  ButtonIcon,
  Color,
  COLOR_SHADES,
  Dialog,
  DIALOG_SIZES,
  DialogFooter,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useRef, useState } from 'react';
import APP_URLS from '../../services/AppRouteURLService';

type PremiumFeatureIconProps = {
  module: string;
};

export default function PremiumFeatureIcon(props: PremiumFeatureIconProps) {
  const { module } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const $button = useRef(null);

  function getDemo() {
    HelpDeskService.raiseTicket({
      subject: `REQUEST FOR FEATURE - ${module}`,
      email: GlobalVariableService.getGlobalRequestingUser().email || 'text@example.com',
      message: 'message will be empty',
      tags: ['sales', 'sales--upsell'],
    });
    setShowSuccess(true);
  }

  return (
    <>
      <ButtonIcon title="Premium feature" onClick={() => setShowDialog(!showDialog)} ref={$button}>
        <Color shade={COLOR_SHADES.DARKER} inline>
          <Icon name="premium" size={ICON_SIZES.SM} accentStyle={ICON_ACCENT_STYLES.FILL} />
        </Color>
      </ButtonIcon>
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Row vcenter>
          <Color shade={COLOR_SHADES.DARKER} inline>
            <Icon name="premium" size={ICON_SIZES.SM} accentStyle={ICON_ACCENT_STYLES.FILL} />
          </Color>
          <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            Premium Feature
          </Text>
        </Row>
        <Space vertical />
        {showSuccess ? (
          <div>
            <Text
              block
              size={TEXT_SIZE.SIZE_3}
              center
              boldness={TEXT_BOLDNESS.BOLD}
              color={COLOR_SHADES.DARKER}
              primaryStyle
            >
              Thank you
            </Text>
            <Space vertical />
            <Text>Our Product specialist will connect with you shortly</Text>
            <DialogFooter>
              <Button
                style={BUTTON_STYLES.CANCEL}
                onClick={() => {
                  setShowDialog(false);
                  setShowSuccess(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <div>
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {module}
            </Text>{' '}
            <Text>is a premium feature which is available only on the premium plans</Text>
            <Space vertical />
            <Text>Upgrade your plan to enable the feature</Text>
            <DialogFooter>
              <Button style={BUTTON_STYLES.TERTIARY} onClick={getDemo}>
                Get demo
              </Button>
              <Button style={BUTTON_STYLES.PRIMARY} link={APP_URLS.SETTINGS.SUBSCRIPTIONS}>
                Upgrade
              </Button>
            </DialogFooter>
          </div>
        )}
      </Dialog>
    </>
  );
}
