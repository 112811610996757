import { connect } from 'react-redux';
import { EventCustomFromFieldsService } from '@premagic/core';

import { UserRegistrationUserFacingFormFields } from './UserRegistrationUserFacingFormFields';
import {
  eventCustomFormFieldIdsSelectors,
  eventCustomFormFieldsItemsSelectors,
} from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    defaultValue?: Record<string, string>;
  },
) {
  const { defaultValue } = ownProps;
  return {
    formFieldIds: eventCustomFormFieldIdsSelectors(EventCustomFromFieldsService.FORM_FIELD_SCOPE.REGISTRATION)(state),
    formFields: eventCustomFormFieldsItemsSelectors(state),
    defaultValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationUserFacingFormFields);
