import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { navigateTo } from '../../../../../../../../services/RouterService';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../../../projects/AccountProjectsDataDuck';
import { createBrandingFolderForBadges } from '../../../../../projects/sponsor/SponsorAndBrandingDuck';
import EventAttendeeBadgeButton from './EventAttendeeBadgeButton';
import { hasFeatureSelector } from '../../../../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; eventId: string }) {
  const { eventId, projectId } = ownProps;

  const brandingFolderIds =
    folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.BRANDING)(state) || [];
  const brandingFolderId = brandingFolderIds[0];

  return {
    eventId,
    projectId,
    isFeatureEnabled: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES)(
      state,
    ),
    brandingFolderId,
    isCreatingFolder: isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createBrandingFolderForBadges: (projectId, eventId) => {
      dispatch(createBrandingFolderForBadges(dispatch, projectId, eventId));
    },
    navigateTo: (url) => {
      navigateTo(dispatch, url, {});
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeButton);
