import { HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';

const APIS = APIURLService.API_URLS.CRM_WORKFLOW;

export type WorkflowType = {
  id: number;
  task_groups: Array<number>;
  name: string;
  created_at: string;
  updated_at?: string;
  added_by: string;
  account: string;
};

export function fetchWorkflows(): Promise<{ results: Array<WorkflowType>; count: number }> {
  return HttpUtils.get(APIS.ROOT, {
    params: { limit: 100 },
  }).then((response) => response.data);
}

export function fetchWorkflowDetails(workflowId: number): Promise<WorkflowType> {
  return HttpUtils.get(APIS.DETAILS(workflowId))
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function createWorkflow(data: { name: string }): Promise<WorkflowType> {
  return HttpUtils.post(APIS.ROOT, data, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function deleteWorkflow(workflowId: number): Promise<void> {
  return HttpUtils.httpDelete(APIS.DETAILS(workflowId)).then((response) => response.data);
}

export function editWorkflow(workflowId: number, data: Partial<WorkflowType>): Promise<any> {
  return HttpUtils.patch(APIS.DETAILS(workflowId), data, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}
