import React, { useContext } from 'react';
import { BUTTON_SIZE, BUTTON_STYLES } from '../Button/Buttons';
import { TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { COLOR_SHADES } from '../Color/Color';

import {
  DECK_SLIDE_SECTION_ANIMATION_TYPE,
  DECK_SLIDE_SECTION_AREAS,
  DeckSlideAcceptTermsAndCondition,
  DeckSlideBackground,
  DeckSlideButtons,
  DeckSlideEquipmentsItems,
  DeckSlideExpiryInfo,
  DeckSlideInvoiceItems,
  DeckSlideInvoiceItemsTextOnly,
  DeckSlideLargeText,
  DeckSlideLargeTextSection,
  DeckSlideSection,
  DeckSlideSimpleTextForTextOnly,
  DeckSlideSubTitleSection,
  DeckSlideTitle,
  DeckSlideTitleSection,
} from './DeckSlideComponents';
import {
  DECK_SLIDE_DATA_TYPE_ALIGNMENT,
  DECK_SLIDE_DATA_TYPE_STYLES,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideDataType,
} from './DeckSlideLayoutDataService';
import DeckContext from './DeckContext';
import { Space } from '../Grid/Grid';
import { Icon, ICON_SIZES } from '../Icon/Icons';

type DeckSlideLayoutBaseProps = {
  id: number;
  data: DeckSlideDataType;
};

export function DeckSlideSplash(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <>
      <DeckSlideBackground slideData={data} />
      <DeckSlideTitleSection data={data[DECK_SLIDE_DATA_TYPES.TITLE]} />
      <DeckSlideLargeTextSection data={data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]} id={id} />
    </>
  );
}

export function DeckSlideTextAndContent(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <>
      <DeckSlideBackground slideData={data} />
      <DeckSlideTitleSection data={data[DECK_SLIDE_DATA_TYPES.TITLE]} />
      <DeckSlideSubTitleSection data={data[DECK_SLIDE_DATA_TYPES.SUB_TITLE]} />
      <DeckSlideLargeTextSection data={data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]} id={id} />
    </>
  );
}

export function DeckSlidePackage(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;

  return (
    <>
      <DeckSlideBackground slideData={data} />
      <DeckSlideTitleSection data={data[DECK_SLIDE_DATA_TYPES.TITLE]} />
      <DeckSlideSection
        animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.SECOND}
        align={DECK_SLIDE_DATA_TYPE_ALIGNMENT.TOP}
        style={DECK_SLIDE_DATA_TYPE_STYLES.NONE}
        area={DECK_SLIDE_SECTION_AREAS.CONTENT}
        value="packages"
      >
        <DeckSlideInvoiceItems data={data[DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS]} id={id} />
      </DeckSlideSection>
    </>
  );
}

export function DeckSlideEquipments(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <>
      <DeckSlideBackground slideData={data} />
      <DeckSlideTitleSection data={data[DECK_SLIDE_DATA_TYPES.TITLE]} />
      <DeckSlideSection
        animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.SECOND}
        align={DECK_SLIDE_DATA_TYPE_ALIGNMENT.TOP}
        style={DECK_SLIDE_DATA_TYPE_STYLES.NONE}
        area={DECK_SLIDE_SECTION_AREAS.CONTENT}
        value="equipments"
      >
        <DeckSlideEquipmentsItems data={data[DECK_SLIDE_DATA_TYPES.EQUIPMENTS_ITEMS]} id={id} />
      </DeckSlideSection>
    </>
  );
}

export function DeckSlideTestimonial(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <>
      <DeckSlideBackground slideData={data} />
      <DeckSlideTitleSection data={data[DECK_SLIDE_DATA_TYPES.TITLE]} />
      <DeckSlideLargeTextSection data={data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]} id={id} />
    </>
  );
}

export function DeckSlideAccept(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  const { deckAdditionalData } = useContext(DeckContext);
  const { reviewStatus } = deckAdditionalData || {};
  return (
    <>
      <DeckSlideBackground slideData={data} />
      {reviewStatus ? (
        <DeckSlideSection
          animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.FIRST}
          align={DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM}
          style={DECK_SLIDE_DATA_TYPE_STYLES.GRADIENT_TO_TOP}
          area={DECK_SLIDE_SECTION_AREAS.CONTENT}
          value="show this"
        >
          <DeckSlideLargeText
            data={{
              value: `<p>You have <strong>${reviewStatus.toLowerCase().replaceAll('_', ' ')}</strong> the proposal</p>`,
              align: DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM,
              style: DECK_SLIDE_DATA_TYPE_STYLES.GRADIENT_TO_TOP,
            }}
            id={id}
          />
          {reviewStatus === 'APPROVED' && (
            <DeckSlideAcceptTermsAndCondition data={data[DECK_SLIDE_DATA_TYPES.TERMS_AND_CONDITIONS]} hasAgreed />
          )}
        </DeckSlideSection>
      ) : (
        <DeckSlideSection
          animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.FIRST}
          align={DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM}
          style={DECK_SLIDE_DATA_TYPE_STYLES.GRADIENT_TO_TOP}
          area={DECK_SLIDE_SECTION_AREAS.CONTENT}
          value="show this"
        >
          <DeckSlideTitle data={data[DECK_SLIDE_DATA_TYPES.TITLE]} />
          <Space vertical />
          <DeckSlideButtons
            buttons={[
              {
                title: 'Request changes?',
                key: 'deck-action--request-changes',
                style: BUTTON_STYLES.TERTIARY,
              },

              {
                title: (
                  <>
                    <Icon name="check_circle" size={ICON_SIZES.SM} />
                    <Space size={1} />
                    Accept
                  </>
                ),
                key: 'deck-action--accept',
                style: BUTTON_STYLES.SECONDARY,
              },
            ]}
          />
          <DeckSlideAcceptTermsAndCondition data={data[DECK_SLIDE_DATA_TYPES.TERMS_AND_CONDITIONS]} />
          <Space vertical size={16} />
          <DeckSlideButtons
            buttons={[
              {
                title: (
                  <>
                    <Icon name="x_circle" size={ICON_SIZES.SM} />
                    <Space size={1} /> Reject
                  </>
                ),
                key: 'deck-action--reject',
                style: BUTTON_STYLES.DANGER,
                size: BUTTON_SIZE.MD,
              },
            ]}
          />
          <Space vertical />
          <DeckSlideExpiryInfo />
        </DeckSlideSection>
      )}
    </>
  );
}

export function DeckSlideTextAndContentTextOnly(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;

  return (
    <>
      <DeckSlideSimpleTextForTextOnly
        data={data[DECK_SLIDE_DATA_TYPES.TITLE]}
        size={TEXT_SIZE.SIZE_1}
        boldness={TEXT_BOLDNESS.BOLD}
        color={COLOR_SHADES.DARKER}
        id={id}
      />
      <DeckSlideSimpleTextForTextOnly data={data[DECK_SLIDE_DATA_TYPES.SUB_TITLE]} size={TEXT_SIZE.SIZE_3} id={id} />
      <Space vertical size={4} />
      <DeckSlideSimpleTextForTextOnly data={data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]} size={TEXT_SIZE.SIZE_4} id={id} />
    </>
  );
}

export function DeckSlidePackageTextOnly(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <div>
      <DeckSlideSimpleTextForTextOnly
        data={data[DECK_SLIDE_DATA_TYPES.TITLE]}
        size={TEXT_SIZE.SIZE_1}
        boldness={TEXT_BOLDNESS.BOLD}
        color={COLOR_SHADES.DARKER}
        id={id}
      />
      <Space vertical size={4} />
      <DeckSlideInvoiceItemsTextOnly data={data[DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS]} id={id} />
    </div>
  );
}
export function DeckSlideEquipmentsTextOnly(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <div>
      <DeckSlideSimpleTextForTextOnly
        data={data[DECK_SLIDE_DATA_TYPES.TITLE]}
        size={TEXT_SIZE.SIZE_1}
        boldness={TEXT_BOLDNESS.BOLD}
        color={COLOR_SHADES.DARKER}
        id={id}
      />
      <Space vertical size={4} />
      <DeckSlideInvoiceItemsTextOnly data={data[DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS]} id={id} />
    </div>
  );
}
export function DeckSlideAcceptTextOnly(props: DeckSlideLayoutBaseProps) {
  const { data, id } = props;
  return (
    <div>
      <Space vertical size={12} />
      <DeckSlideSimpleTextForTextOnly
        data={{
          value: 'Terms & Condition',
        }}
        size={TEXT_SIZE.SIZE_1}
        boldness={TEXT_BOLDNESS.BOLD}
        color={COLOR_SHADES.DARKER}
        id={id}
      />
      <Space vertical />
      <DeckSlideSimpleTextForTextOnly
        data={data[DECK_SLIDE_DATA_TYPES.TERMS_AND_CONDITIONS]}
        size={TEXT_SIZE.SIZE_5}
        id={id}
      />
    </div>
  );
}
