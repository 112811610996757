import React, { useEffect, useMemo, useState } from 'react';
import { Col, FormGroup, FormLabel, Input, Row, Select, Space } from '@premagic/myne';
import { ClientSettingsService, EventsCategoryService, EventsService } from '@premagic/core';

type EventCategoryOptionType = {
  value: EventsService.EVENT_CATEGORY;
  label: string;
  icon: string;
};

function getDefaultSelectedId(
  defaultValue,
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES,
): EventsService.EVENT_CATEGORY {
  if (defaultValue && defaultValue in EventsService.EVENT_CATEGORY) return defaultValue;
  if (defaultValue) return EventsService.EVENT_CATEGORY.CUSTOM;
  if (companyType === ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY)
    return EventsService.EVENT_CATEGORY.WEDDING;
  return EventsService.EVENT_CATEGORY.CORPORATE;
}

type EventCategoryPickerProps = {
  defaultValue?: EventsService.EVENT_CATEGORY | string;
  name?: string;
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
  onChange?: (value: EventsService.EVENT_CATEGORY | string) => void;
};

export default function EventCategoryPicker(props: EventCategoryPickerProps): JSX.Element {
  const { defaultValue, name = 'event[name]', companyType, onChange } = props;
  const eventCategoryOptions = useMemo(
    () =>
      Object.values(EventsCategoryService.EVENT_CATEGORY_DETAILS)
        .filter((item) => item.forCompanyType.includes(companyType))
        .map((type) => ({
          value: type.id,
          label: type.title,
          icon: type.icon,
        })) as Array<EventCategoryOptionType>,
    [companyType],
  );
  const defaultValueId = getDefaultSelectedId(defaultValue, companyType);

  const defaultSelectedOption = eventCategoryOptions.find((option) => option.value === defaultValueId);
  const [selectedOptions, setSelectionOption] = useState(defaultSelectedOption);

  useEffect(() => {
    if (onChange && selectedOptions?.value) onChange(selectedOptions?.value);
  }, [selectedOptions, onChange]);

  return (
    <Row>
      <Col>
        <FormGroup>
          <FormLabel isRequired>Event Category</FormLabel>
          <Select
            options={eventCategoryOptions}
            value={defaultSelectedOption}
            onChange={(option) => setSelectionOption(option as EventCategoryOptionType)}
            name={selectedOptions?.value === EventsService.EVENT_CATEGORY.CUSTOM ? '' : name}
            isMulti={false}
          />
        </FormGroup>
      </Col>
      <Space />
      <Col>
        {selectedOptions?.value === EventsService.EVENT_CATEGORY.CUSTOM && (
          <FormGroup>
            <FormLabel isRequired>Other category name</FormLabel>
            <Input
              required
              name={name}
              autoFocus
              defaultValue={defaultValueId === EventsService.EVENT_CATEGORY.CUSTOM ? defaultValue : undefined}
            />
          </FormGroup>
        )}
      </Col>
    </Row>
  );
}
