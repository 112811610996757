import ClassNames from 'classnames';
import styles from './loading.module.css';
import { Page } from '../Page';
import { Row } from '../Grid';
import { LoadingDots } from './Loading';

export function LoadingHeart(): React.ReactElement {
  return (
    <div className={ClassNames(styles['loader-heart'])}>
      <ul style={{ ['--line-count' as string]: 9 }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
          <li
            key={i}
            className={ClassNames(styles[`loader-header__line-${i}`])}
            style={{ ['--line-index' as string]: i }}
          />
        ))}
      </ul>
    </div>
  );
}

export function AppLoadingHearts(): JSX.Element {
  return (
    <Page center screenHeight>
      <Row fullHeight center>
        <LoadingHeart />
      </Row>
    </Page>
  );
}

export function AppLoadingDots(): JSX.Element {
  return (
    <Page center screenHeight>
      <Row fullHeight center>
        <LoadingDots />
      </Row>
    </Page>
  );
}
