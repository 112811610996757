import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import Amplify from 'aws-amplify';
import { ErrorTracker, AppUtils, HttpUtils, AuthUtils, FontUtils } from '@premagic/utils';
import { EventTrackerService, PromiseCancellingAxiosHook } from '@premagic/core';

import { configureStore, history } from './store/configureStore';
import Root from './app/Root';
import awsExports from '../aws-exports';

const store = configureStore();
ErrorTracker.initErrorTracker(AppUtils.APPS.ACCOUNT_APP);
EventTrackerService.initAnalytics(AppUtils.APPS.ACCOUNT_APP);

// Auth
Amplify.configure(awsExports);
// Axios hooks
AuthUtils.addAxiosAuthorizationToken(HttpUtils.getHttpInstance());
PromiseCancellingAxiosHook.addAxiosAuthorizationToken(HttpUtils.getHttpInstance());

const root = ReactDOMClient.createRoot(document.getElementById('root')!);
root.render(<Root store={store} history={history} />);

FontUtils.loadFont({
  google: {
    families: ['Playfair Display:700', 'Source Sans Pro:400,600,700'],
  },
});
