import React, { useEffect, useState } from 'react';

import { FolderService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  DialogTitle,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type Props = {
  isSubscriptionPlan: boolean;
  isDeleting: boolean;
  folder: FolderService.FolderType;
  projectId: string;
  folderId: string;
  toggleShowDeleteFolder: (show: boolean) => void;
  isDeleteFolderPopoverShown: boolean;
  deleteFolderData: (projectId: string, folderId: string, folderType: FolderService.FOLDER_TYPES) => void;
};

export default function FolderDeleteDialog(props: Props): JSX.Element | null {
  const {
    isSubscriptionPlan,
    folder,
    isDeleting,
    projectId,
    folderId,
    toggleShowDeleteFolder,
    isDeleteFolderPopoverShown,
    deleteFolderData,
  } = props;

  const { folder_type: folderType, status: folderStatus } = folder;
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);

  useEffect(() => () => setShowConfirmationPrompt(false), []);

  function handleDelete() {
    const folderHasCompletedSelection =
      folderType === FolderService.FOLDER_TYPES.SELECTION &&
      [FolderService.FOLDER_STATUS.DONE, FolderService.FOLDER_STATUS.REVIEW_COMPLETED].includes(folderStatus);
    if (folderHasCompletedSelection) {
      return setShowConfirmationPrompt(true);
    }
    deleteFolderData(projectId, folderId, folderType);
  }

  if (!isDeleteFolderPopoverShown) return null;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.EVENTS_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleShowDeleteFolder(false)}
    >
      <DialogTitle>Delete this folder?</DialogTitle>

      {showConfirmationPrompt ? (
        <div>
          <Text color={COLOR_SHADES.WARNING} boldness={TEXT_BOLDNESS.BOLD}>
            Your client has completed selection on this folder!
          </Text>
          <Space vertical size={2} />
          <Text>Are you sure?</Text>
          <DialogFooter>
            <Button style={BUTTON_STYLES.LINK} onClick={() => toggleShowDeleteFolder(false)} type="button">
              Cancel
            </Button>
            <Button
              disabled={!isSubscriptionPlan}
              disabledMessage="You can only delete folder with subscription plans!, Upgrade your plan to continue."
              isLoading={isDeleting}
              style={BUTTON_STYLES.DANGER}
              onClick={() => deleteFolderData(projectId, folderId, folderType)}
            >
              Delete folder
            </Button>
          </DialogFooter>
        </div>
      ) : (
        <div>
          <Text>This will erase this folder data and any pending process</Text>
          <Space vertical size={2} />
          <Text>Are you sure you want to delete the folder?</Text>
          <Space vertical />
          <Text color={COLOR_SHADES.DANGER}>You can’t undo this action.</Text>
          <DialogFooter>
            <Button style={BUTTON_STYLES.LINK} onClick={() => toggleShowDeleteFolder(false)} type="button">
              Cancel
            </Button>
            <Button isLoading={isDeleting} style={BUTTON_STYLES.DANGER} onClick={handleDelete}>
              Delete folder
            </Button>
          </DialogFooter>
        </div>
      )}
    </Dialog>
  );
}
