import React, { useState } from 'react';
import { Col, FormGroup, FormLabel, Input, INPUT_SIZES, Row, Select, Space } from '@premagic/myne';
import { OccasionsService } from '@premagic/core';

type OccasionNameOptionType = {
  value: OccasionsService.OCCASIONS;
  label: string;
};
const occasionOptions = Object.values(OccasionsService.OCCASIONS_DETAILS).map((type) => ({
  value: type.id,
  label: type.id,
})) as Array<OccasionNameOptionType>;

function getDefaultSelectedId(defaultValue) {
  if (defaultValue && defaultValue in OccasionsService.OCCASIONS) return defaultValue;
  if (defaultValue) return OccasionsService.OCCASIONS.OTHERS;
  return OccasionsService.OCCASIONS.WEDDING;
}

type OccasionNamePickerProps = {
  defaultValue?: OccasionsService.OCCASIONS | string;
  name?: string;
};

export default function OccasionNamePicker(props: OccasionNamePickerProps): JSX.Element {
  const { defaultValue, name = 'event[name]' } = props;
  const defaultValueId = getDefaultSelectedId(defaultValue);

  const defaultSelectedOption = occasionOptions.find((option) => option.value === defaultValueId);
  const [selectedOptions, setSelectionOption] = useState(defaultSelectedOption);

  return (
    <Row>
      <Col size={5}>
        <FormGroup>
          <FormLabel isRequired>Name of the Occasion</FormLabel>
          {selectedOptions?.value === OccasionsService.OCCASIONS.OTHERS ? (
            <Input
              required
              name={name}
              autoFocus
              placeholder="Enter the name of the occasion"
              defaultValue={defaultValueId === OccasionsService.OCCASIONS.OTHERS ? defaultValue : undefined}
            />
          ) : (
            <Select
              options={occasionOptions}
              value={defaultSelectedOption}
              onChange={(option) => setSelectionOption(option as OccasionNameOptionType)}
              name={name}
              isMulti={false}
              autoFocus
            />
          )}
        </FormGroup>
      </Col>
    </Row>
  );
}
