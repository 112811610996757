import { FileService } from '@premagic/core';
import { FadeIn, Gallery, GALLERY_IMAGE_STYLE, Space, SubTitle } from '@premagic/myne';
import AccountFolderPhotoDropZoneContainer from '../folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderFilesUploadStatusContainer from '../folder-files-upload/FolderFilesUploadStatusContainer';
import { FolderGalleryImage } from './FolderGallery';

type ProposalFolderGalleryProps = {
  files: Record<string, FileService.FolderFileType>;
  projectId: string;
  folderId: string;
  fileIds: Array<string>;
  onSelect: (fileId: string) => void;
  onSelectExpand?: (fileId: string) => void;
  groupBy?: {
    label: string;
    items: Array<string>;
  };
};

export default function FolderGalleryWithFileSelect(props: ProposalFolderGalleryProps) {
  const { files, projectId, folderId, fileIds = [], onSelect, groupBy, onSelectExpand } = props;
  const totalFiles = fileIds.length;

  if (totalFiles === 0) return null;
  let fileIdsToShow = fileIds;
  if (groupBy) {
    fileIdsToShow = fileIds.filter((id) => !groupBy.items.includes(id));
  }

  return (
    <FadeIn>
      <FolderFilesUploadStatusContainer folderId={folderId} projectId={projectId} />
      <Space vertical size={2} />
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId}>
        <div>
          <Gallery scaleSizeWithScreen={false}>
            {fileIdsToShow.map((id) => (
              <FolderGalleryImage
                key={id}
                fileId={id}
                file={files[id]}
                onClick={() => onSelect(id)}
                onSelectExpand={() => onSelectExpand?.(id)}
                isAlbumViewWithFileName
                style={GALLERY_IMAGE_STYLE.CONTAIN}
              />
            ))}
          </Gallery>
          {groupBy?.items && groupBy?.items.length > 0 && (
            <div>
              <Space vertical />
              <SubTitle>{groupBy?.label}</SubTitle>
              <Space vertical size={1} />
              <Gallery scaleSizeWithScreen={false}>
                {groupBy?.items.map((id) => (
                  <FolderGalleryImage
                    key={id}
                    fileId={id}
                    file={files[id]}
                    onClick={() => onSelect(id)}
                    onSelectExpand={() => onSelectExpand?.(id)}
                    isAlbumViewWithFileName
                    style={GALLERY_IMAGE_STYLE.CONTAIN}
                  />
                ))}
              </Gallery>
            </div>
          )}
        </div>
      </AccountFolderPhotoDropZoneContainer>
    </FadeIn>
  );
}
