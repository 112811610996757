import React, { useEffect } from 'react';
import { Card, InnerPage, InnerPageContent, InnerPageContentMainCol } from '@premagic/myne';
import { Route, Switch } from 'react-router-dom';
import BrandingDetailsPageHeaderContainer from './BrandingDetailsPageHeaderContainer';
import APP_URLS from '../../../../services/AppRouteURLService';
import BrandingWatermarkDetailsContainer from '../branding-watermark/BrandingWatermarkDetailsContainer';

type Props = {
  folderId: string;
  isLoading: boolean;
  currentUrlPathname: string;
  hasWatermarkFeature: boolean;
  initData: (folderId: string) => void;
};

export default function BrandingDetailsPage(props: Props) {
  const { isLoading, hasWatermarkFeature, currentUrlPathname, initData, folderId } = props;

  useEffect(() => {
    initData(folderId);
  }, [folderId, initData]);

  return (
    <InnerPage>
      <BrandingDetailsPageHeaderContainer currentUrlPathname={currentUrlPathname} folderId={folderId} />
      <InnerPageContent hasInnerCols isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Switch>
            <Route path={APP_URLS.SETTINGS.BRANDING.DETAILS__WATERMARK} component={BrandingWatermarkDetailsContainer} />
          </Switch>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
