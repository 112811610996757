import { connect } from 'react-redux';
import { hasPermission } from '../../../users/UsersDataDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import SponsorCreativeOptionsMenu from './SponsorCreativeOptionsMenu';
import { sponsorCreativesSelector } from '../SponsorCreativeDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; creativeId: string }) {
  const { projectId, creativeId } = ownProps;

  return {
    projectId,
    creativeId,
    creative: sponsorCreativesSelector(state)[creativeId],
    hasPermissionsToManageCreatives: hasPermission(PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: (sponsorId: string, id: string) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_DELETE_DIALOG(sponsorId, id), true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCreativeOptionsMenu);
