import {
  BUTTON_ICONS_SIZES,
  BUTTON_ICON_STYLES,
  ButtonIcon,
  ICON_SIZES,
  Icon,
  MODAL_SIZES,
  Modal,
  ModalTitle,
  Position,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Text,
} from '@premagic/myne';
import React, { useState } from 'react';
import { EventPosterService } from '@premagic/core';
import { PosterWidgetType } from '@premagic/poster-creator';
import AccountFolderPhotoDropZoneContainer from '../../../projects/folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderUploadProgressContainer from '../../../projects/folders/folder-files-upload/FolderUploadProgressContainer';
import FolderFilesUploadErrorContainer from '../../../projects/folders/folder-files-upload/FolderFilesUploadErrorContainer';
import FolderGalleryWithFileSelectContainer from '../../../projects/folders/gallery/FolderGalleryWithFileSelectContainer';
import FolderImagePopupSimpleContainer from '../../../projects/folders/gallery/FolderImagePopupSimpleContainer';

type Props = {
  close: () => void;
  show: boolean;
  projectId: string;
  posterId: string;
  onSelect: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetType;
  }) => void;
  poster: EventPosterService.EventPosterType;
  brandingFolderId?: string;
  widget: PosterWidgetType;
};

export default function EventPosterFilePickerModal(props: Props) {
  const { show, close, projectId, onSelect, poster, brandingFolderId, posterId, widget } = props;
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  function handleOnSelect(imageId: string) {
    const isBackgroundPoster = widget.name === EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER;
    const updatedWidget = Object.assign({}, widget, {
      value: imageId,
      size: isBackgroundPoster ? poster.size : widget.size,
    });
    onSelect({ projectId, widgetToUpdate: updatedWidget, poster });
    close();
  }
  if (!brandingFolderId) {
    return (
      <Modal show={show} close={close} size={MODAL_SIZES.MD}>
        <Text>No Branding folder</Text>
      </Modal>
    );
  }

  if (selectedFile) {
    return (
      <Modal show={show} close={close} size={MODAL_SIZES.MD}>
        <Position align="top-right">
          <Space>
            <ButtonIcon
              onClick={() => setSelectedFile(null)}
              title="Close"
              size={BUTTON_ICONS_SIZES.SM}
              style={BUTTON_ICON_STYLES.SECONDARY}
            >
              <Icon name="x" size={ICON_SIZES.LG} />
            </ButtonIcon>
          </Space>
        </Position>

        <FolderImagePopupSimpleContainer
          projectId={projectId}
          folderId={brandingFolderId}
          id={selectedFile}
          onDelete={() => setSelectedFile(null)}
          onNavigate={setSelectedFile}
          onSelect={handleOnSelect}
        />
      </Modal>
    );
  }
  return (
    <Modal show={show} close={close} size={MODAL_SIZES.MD}>
      <Row vcenter spaceBetween>
        <ModalTitle>Choose the file</ModalTitle>
        <ButtonIcon onClick={close} title="Close" size={BUTTON_ICONS_SIZES.SM} style={BUTTON_ICON_STYLES.SECONDARY}>
          <Icon name="x" size={ICON_SIZES.LG} />
        </ButtonIcon>
      </Row>
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={brandingFolderId} />
      <FolderUploadProgressContainer folderId={brandingFolderId} />
      <FolderFilesUploadErrorContainer folderId={brandingFolderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <FolderGalleryWithFileSelectContainer
          projectId={projectId}
          folderId={brandingFolderId}
          onSelect={handleOnSelect}
          onSelectExpand={setSelectedFile}
        />
      </ScrollableContainer>
    </Modal>
  );
}
