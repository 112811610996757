import React from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormLabel,
  Input,
  FormGroup,
  SubTitle,
  Textarea,
  FormFooter,
  FormErrorType,
} from '@premagic/myne';

type Props = {
  addWish: (options: { projectId: string; folderId: string }, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  folderId: string;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
};

export default function AddClientWishPanel(props: Props) {
  const { show, close, folderId, projectId, addWish, isLoading, error } = props;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add Wish</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          onSubmit={(response) => addWish({ projectId, folderId }, response)}
          formSpec={{
            name: {
              validator: 'required',
            },
            email: {
              validator: 'required',
            },
            message: {
              validator: 'required',
            },
          }}
        >
          <FormGroup>
            <FormLabel isRequired>Message</FormLabel>
            <Textarea name="message" autoFocus required />
          </FormGroup>
          <SubTitle>Message by</SubTitle>
          <FormGroup>
            <FormLabel isRequired>Name</FormLabel>
            <Input name="name" placeholder="eg. John Doe" required />
          </FormGroup>
          <FormGroup>
            <FormLabel isRequired>Email</FormLabel>
            <Input name="email" placeholder="eg. john@gmail.com" required />
          </FormGroup>
          <FormGroup>
            <FormLabel>Phone</FormLabel>
            <Input name="phone" type="tel" placeholder="+91 00000000" />
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
