import { FolderService } from '@premagic/core';
import { Button, BUTTON_SIZE, BUTTON_STYLES, Icon, ICON_SIZES, Row, Space, Text } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { NotificationSettingsType } from '../../../settings/notifications/NotificationService';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  notifications: NotificationSettingsType;
  showSettingsLink?: boolean;
  action?: string;
};

export default function FolderShareChannelInfo(props: Props) {
  const { notifications, showSettingsLink, action = 'Share' } = props;
  const { share_on_whatsapp: foldersWhatsapp } = notifications;
  const notificationUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.NOTIFICATIONS, {});
  const hasWhatsapp = foldersWhatsapp;

  return (
    <div>
      <Row vcenter>
        <Icon name="gmail_solid_color" size={ICON_SIZES.SM} />
        <Space size={1} />
        {hasWhatsapp && (
          <>
            <Icon name="whatsapp_solid_color" size={ICON_SIZES.SM} />
            <Space size={1} />
          </>
        )}
        <Text>
          {action} via {hasWhatsapp && 'Whatsapp and'} Email
        </Text>
      </Row>
      {showSettingsLink && (
        <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} link={notificationUrl}>
          Notification settings
        </Button>
      )}
    </div>
  );
}
