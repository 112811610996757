import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { QRCodeBackgroundPosterService } from '@premagic/core';
import {
  editQRCodeDesigns,
  qrCodeDesignsBackgroundPosterDataSelector,
  qrCodeDesignsSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import QRCodePosterIllustrationSelector from './QRCodePosterIllustrationSelector';
import { filesSelector } from '../../../../images/FilesDataDuck';

function mapStateToProps(state, ownProps) {
  const { folderId, projectId } = ownProps;

  let illustrationFile;
  const filesData = filesSelector(state);
  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);
  const illustrationId = qrCodeDesigns?.custom_illustration;
  if (illustrationId) {
    illustrationFile = filesData[illustrationId];
  }

  return {
    folderId,
    projectId,
    qrCodeDesigns,
    illustrationImageSrc: illustrationFile?.dynamic_image_url,
    backgroundCover: qrCodeDesignsBackgroundPosterDataSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSelectFileModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_ILLUSTRATION_MODAL, true));
    },
    removeIllustration: (projectId, folderId, qrCodeDesigns) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION]: undefined,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterIllustrationSelector);
