import { ColorUtils } from '@premagic/utils';
import { loadGoogleFonts } from './FontService';

export type ThemeTokensType = {
  color: {
    background?: string;
    font_color?: string;
    accent_color?: string;
  };
};

export function updateThemeVariable(themeTokens: ThemeTokensType) {
  const { color } = themeTokens;

  const { background, font_color: fontColor, accent_color: accentColor } = color;

  const themeData = {
    ...(fontColor && { color: fontColor }),
    ...(background && {
      'background-color': background,
      'background-color-contrast': ColorUtils.getContrastColor({
        bgColor: background,
        threshold: 200,
      }),
      'background-color-contrast-lighter': ColorUtils.getContrastColor({
        bgColor: background,
        threshold: 30,
      }),
      'background-color-contrast-lightest': ColorUtils.getContrastColor({
        bgColor: background,
        threshold: 10,
      }),
      'tertiary-color': background,
      'tab-background-color': background,
      'tab-color': ColorUtils.getContrastColor({
        bgColor: background,
        threshold: 200,
      }),
      'tab-background-color-transparent': ColorUtils.changeOpactiy(background, 0.3),
      'nav-background-color': ColorUtils.changeOpactiy(background, 0.3),
      'nav-color': ColorUtils.getContrastColor({
        bgColor: background,
        threshold: 200,
      }),
      'nav-color-contrast': ColorUtils.getContrastColor({
        bgColor: background,
        threshold: 120,
      }),
    }),
    ...(accentColor && { 'accent-color': accentColor }),
  };

  Object.entries(themeData).forEach(([variable, value]) =>
    document.documentElement.style.setProperty(`--js-theme-${variable}`, value),
  );
}

export function updateFontFamily(font: { primary_font: string; secondary_font?: string }) {
  const { primary_font: primaryFont, secondary_font: secondaryFont } = font;

  const themeData = {
    'font-primary': primaryFont,
    ...(secondaryFont && { 'font-secondary': secondaryFont }),
  };

  Object.entries(themeData).forEach(([variable, value]) =>
    document.documentElement.style.setProperty(`--js-theme-${variable}`, value),
  );
}

export function loadAndUpdateGoogleFonts(
  font: {
    primary_font: string;
    secondary_font?: string;
  },
  name?: string,
  onActive?: () => void,
  fontActive?: (fontFamily: string, fvd: string) => void,
  inactive?: () => void,
  fontInactive?: (fontFamily: string, fvd: string) => void,
): Promise<void> {
  const { primary_font: primaryFont, secondary_font: secondaryFont } = font;

  updateFontFamily(font);

  const fontsToLoad = [primaryFont];

  if (secondaryFont) {
    fontsToLoad.push(secondaryFont);
  }

  // Providing a text parameter will optimize the font loading
  if (name) {
    return loadGoogleFonts(fontsToLoad, { text: name }, onActive, fontActive, inactive, fontInactive);
  }

  return loadGoogleFonts(fontsToLoad, {}, onActive, fontActive, inactive, fontInactive);
}
