import React from 'react';
import { Col, FormGroup, FormLabel, Input, RichTextArea, Row, Space } from '@premagic/myne';
import { ProposalDeckService, ProposalService } from '@premagic/proposals';
import ProposalTemplatePickerContainer from './ProposalTemplatePickerContainer';

type Props = {
  proposal?: ProposalService.ProposalType;
  deck?: ProposalDeckService.ProposalDeckType;
};

export default function EventProposalForm(props: Props): JSX.Element {
  const { proposal, deck } = props;

  return (
    <div>
      <FormGroup>
        <FormLabel>Name</FormLabel>
        <Input
          name="folder_name"
          required
          autoFocus
          placeholder="eg. Wedding Proposal"
          defaultValue={proposal?.folder_name}
        />
      </FormGroup>
      <div>
        <ProposalTemplatePickerContainer />
      </div>
    </div>
  );
}
