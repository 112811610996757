/*
We are using ProseMirror for the rich text editor.
We are Remirror which work on top of ProseMirror.

All the extensions are here.
https://remirror.io/docs/extensions/link-extension
 */

import { debounce, noop } from 'lodash';
import React, { useMemo, useState } from 'react';
import { prosemirrorNodeToHtml } from 'remirror';
import ClassNames from 'classnames';
import {
  BoldExtension,
  BulletListExtension,
  HeadingExtension,
  FontSizeExtension,
  ItalicExtension,
  LinkExtension,
  OrderedListExtension,
  PlaceholderExtension,
  UnderlineExtension,
  TextColorExtension,
  NodeFormattingExtension,
} from 'remirror/extensions';
import {
  EditorComponent,
  Remirror,
  ThemeProvider,
  useActive,
  useChainedCommands,
  useCurrentSelection,
  useRemirror,
} from '@remirror/react';

import 'remirror/styles/extension-mention-atom.css';

import './richtext.css';
import './richtext-value.css';
import './lib-richtext-porsemirror.css';

import { VariableAtomExtension } from './extentions/variable-atom-extention';
import { ListMarkerColorExtension } from './extentions/list-marker-color-extention';
import RichTextVariableSelector, { RichTextVariableType } from './RichTextVariableSelector';
import { RichTextToolbar, RichTextFloatingToolbarMini, RichTextFloatingToolbarBasic } from './RichTextToolbar';

// import { ProsemirrorDevTools } from '@remirror/dev';

type FooterProps = {
  variables?: Array<RichTextVariableType>;
  onNewVariable?: () => void;
};

function Footer(props: FooterProps) {
  const { variables, onNewVariable } = props;
  const chain = useChainedCommands();
  const active = useActive();
  const currentSelection = useCurrentSelection();

  function handleOnSelectForVariable(variable: RichTextVariableType) {
    // chain.insertText(variable.id).focus().run();

    chain
      .createVariableAtom(
        {
          name: variable.label,
          range: currentSelection,
        },
        variable,
      )
      .insertText(` ${variable.label}`)
      .focus()
      .run();
  }

  if (!variables) return null;

  return (
    <div className="rich-editor__footer">
      <RichTextVariableSelector
        options={variables}
        onSelect={handleOnSelectForVariable}
        onNewVariable={onNewVariable}
      />
    </div>
  );
}

// const ALL_USERS = [
//   { id: 'joe', label: 'Joe' },
//   { id: 'sue', label: 'Sue' },
//   { id: 'pat', label: 'Pat' },
//   { id: 'tom', label: 'Tom' },
//   { id: 'jim', label: 'Jim' },
// ];

// function UserSuggestor() {
//   const [users, setUsers] = useState<VariableAtomNodeAttributes[]>([]);
//   const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useVariableAtom({
//     items: users,
//   });
//
//   useEffect(() => {
//     if (!state) {
//       return;
//     }
//
//     const searchTerm = state.query.full.toLowerCase();
//     const filteredUsers = ALL_USERS.filter((user) => user.label.toLowerCase().includes(searchTerm))
//       .sort()
//       .slice(0, 5);
//     setUsers(filteredUsers);
//   }, [state]);
//
//   const enabled = !!state;
//
//   return (
//     <FloatingWrapper positioner="cursor" enabled={enabled} placement="bottom-start">
//       <div {...getMenuProps()} className="suggestions">
//         {enabled &&
//           users.map((user, index) => {
//             const isHighlighted = indexIsSelected(index);
//             const isHovered = indexIsHovered(index);
//
//             return (
//               <div
//                 key={user.id}
//                 className={cx('suggestion', isHighlighted && 'highlighted', isHovered && 'hovered')}
//                 {...getItemProps({
//                   item: user,
//                   index,
//                 })}
//               >
//                 {user.label}
//               </div>
//             );
//           })}
//       </div>
//     </FloatingWrapper>
//   );
// }

enum RICH_TEXTAREA_STYLES {
  DEFAULT = 'default',
  INLINE_SIMPLE = 'inline-simple',
}
function getExtensions(style: RICH_TEXTAREA_STYLES, options: { placeholder: string }) {
  const { placeholder } = options;
  switch (style) {
    case RICH_TEXTAREA_STYLES.INLINE_SIMPLE:
      return [
        new BoldExtension({}),
        new ItalicExtension(),
        new UnderlineExtension(),
        new PlaceholderExtension({
          placeholder,
        }),
        new LinkExtension({
          autoLink: true,
          extraAttributes: {
            style: {
              default: '--link-color: #4a90e2',
              parseDOM: (domNode) => domNode.getAttribute('style'),
            },
          },
        }),
        new BulletListExtension({
          extraAttributes: {
            style: {
              default: '--marker-color: inherit',
              parseDOM: (domNode) => domNode.getAttribute('style'),
            },
          },
        }),
        new OrderedListExtension({
          extraAttributes: {
            style: {
              default: '--marker-color: inherit',
              parseDOM: (domNode) => domNode.getAttribute('style'),
            },
          },
        }),
        new TextColorExtension({}),
        new ListMarkerColorExtension(),
        new NodeFormattingExtension({}),
      ];

    case RICH_TEXTAREA_STYLES.DEFAULT:
    default:
      return [
        new BoldExtension({}),
        new ItalicExtension(),
        new UnderlineExtension(),
        new HeadingExtension({}),
        new FontSizeExtension({ defaultSize: '16', unit: 'px' }),
        new LinkExtension({
          autoLink: true,
          extraAttributes: {
            style: {
              default: '--link-color: #4a90e2',
              parseDOM: (domNode) => domNode.getAttribute('style'),
            },
          },
        }),
        new BulletListExtension({
          extraAttributes: {
            style: {
              default: '--marker-color: inherit',
              parseDOM: (domNode) => domNode.getAttribute('style'),
            },
          },
        }),
        new OrderedListExtension({
          extraAttributes: {
            style: {
              default: '--marker-color: inherit',
              parseDOM: (domNode) => domNode.getAttribute('style'),
            },
          },
        }),
        new PlaceholderExtension({
          placeholder,
        }),
        new VariableAtomExtension({
          extraAttributes: { type: 'variable' },
        }),
        new TextColorExtension({}),
        new ListMarkerColorExtension(),
        new NodeFormattingExtension({}),
      ];
  }
}

function hasDiff(newString = '', stateString, defaultValue): boolean {
  if (newString === defaultValue) return false;

  if (newString === '<p></p>' && stateString?.length === 0) return false;
  // If the newString is emptry and previous state is emapy
  if (newString && newString.length === 0 && stateString === '<p></p>') return false;

  // Update the state only if there is a diff between the state and new html string
  if (newString === stateString) return false;

  return true;
}

type Props = {
  defaultValue?: string;
  placeholder?: string;
  name: string;
  onChange?: (value: string) => void;
  variables?: Array<RichTextVariableType>;
  onNewVariable?: () => void;
  disabled?: boolean;
  disableColorChange?: boolean;
  debounceTimer?: number;
  showTextAlignment?: boolean;
};

export function LibRichTextArea(props: Props): JSX.Element {
  const {
    defaultValue = '',
    placeholder = '',
    name,
    onChange = noop,
    variables,
    onNewVariable,
    disabled,
    disableColorChange,
    debounceTimer = 1000,
    showTextAlignment,
  } = props;
  const [htmlString, setHtmlString] = useState<string>(defaultValue);
  const { manager, state } = useRemirror({
    extensions: () => getExtensions(RICH_TEXTAREA_STYLES.DEFAULT, { placeholder }),
    content: defaultValue,
    selection: 'start',
    stringHandler: 'html',
  });

  const debouncedOnChange = useMemo(
    () =>
      debounce((doc) => {
        const str = prosemirrorNodeToHtml(doc);
        if (hasDiff(str, htmlString, defaultValue)) {
          setHtmlString(str);
          onChange(str);
        }
      }, debounceTimer),
    [htmlString, defaultValue, onChange, debounceTimer],
  );

  return (
    <div className="rich-editor">
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={state}
          onChange={(variable) => {
            debouncedOnChange(variable.state.doc);
          }}
        >
          <RichTextToolbar showTextAlignment={showTextAlignment} showFontSize />
          <div
            className={ClassNames(
              'rich-editor__content',
              `rich-editor__content--style-${RICH_TEXTAREA_STYLES.DEFAULT}`,
            )}
          >
            <EditorComponent />
            <RichTextFloatingToolbarMini />
          </div>
          <Footer variables={variables} onNewVariable={onNewVariable} />
        </Remirror>
      </ThemeProvider>
      <input type="hidden" name={name} value={htmlString} readOnly />
    </div>
  );
}

export function LibRichTextAreaInlineSimple(props: Props): JSX.Element {
  const {
    defaultValue = '',
    placeholder = '',
    name,
    onChange = noop,
    variables,
    onNewVariable,
    disabled,
    disableColorChange,
    debounceTimer = 1000,
    showTextAlignment,
  } = props;
  const [htmlString, setHtmlString] = useState<string>(defaultValue);
  const { manager, state } = useRemirror({
    extensions: () => getExtensions(RICH_TEXTAREA_STYLES.INLINE_SIMPLE, { placeholder }),
    content: defaultValue,
    selection: 'start',
    stringHandler: 'html',
  });

  const debouncedOnChange = useMemo(
    () =>
      debounce((doc) => {
        const str = prosemirrorNodeToHtml(doc);
        if (hasDiff(str, htmlString, defaultValue)) {
          setHtmlString(str);
          onChange(str);
        }
      }, debounceTimer),
    [htmlString, defaultValue, onChange, debounceTimer],
  );

  return (
    <div className="rich-editor">
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={state}
          onChange={(variable) => {
            debouncedOnChange(variable.state.doc);
          }}
          editable={!disabled}
        >
          <div
            className={ClassNames(
              'rich-editor__content',
              `rich-editor__content--style-${RICH_TEXTAREA_STYLES.INLINE_SIMPLE}`,
            )}
          >
            <EditorComponent />
            <RichTextFloatingToolbarBasic disableColorChange={disableColorChange} />
          </div>
        </Remirror>
      </ThemeProvider>
      <input type="hidden" name={name} value={htmlString} readOnly />
    </div>
  );
}
