import { Dropdown, DropdownIcon, Icon, LoadingDots, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';
import IntegrationSyncOrImportMenuItemsContainer from '../../../integration-third-party-platforms/integration-button/IntegrationSyncOrImportMenuItemsContainer';
import UploadEditBulkEventAttendeeMenuItemContainer from './bulk-event-attendee/upload-edit-button/UploadEditBulkEventAttendeeMenuItemContainer';
import EventAttendeeDownloadCSVContainer from './download-csv/EventAttendeeDownloadCSVContainer';

const LIST_DETAILS: {
  [key: string]: {
    title: string;
    icon: string;
  };
} = {
  list: {
    title: 'Show compact list',
    icon: 'list',
  },
  detailed: {
    title: 'Show detailed list',
    icon: 'list',
  },
};

type Props = {
  updateListStyle: (style: string) => void;
  listStyle: 'list' | 'detailed';
  isLoading: boolean;
  projectId: string;
};

export default function EventAttendeeListMenu(props: Props) {
  const { isLoading, updateListStyle, listStyle, projectId } = props;
  const listStyleToSwitch = listStyle === 'list' ? 'detailed' : 'list';
  return (
    <Dropdown>
      <DropdownIcon title="More" tooltipPlacement="left">
        {isLoading ? <LoadingDots size="xs" /> : <Icon name="more_vertical" />}
      </DropdownIcon>
      <Menu>
        <MenuItem onClick={() => updateListStyle(listStyleToSwitch)}>
          <MenuItemIcon name={LIST_DETAILS[listStyleToSwitch]?.icon} />
          {LIST_DETAILS[listStyleToSwitch]?.title}
        </MenuItem>
        <EventAttendeeDownloadCSVContainer projectId={projectId} />
        <UploadEditBulkEventAttendeeMenuItemContainer />
        <IntegrationSyncOrImportMenuItemsContainer projectId={projectId} />
      </Menu>
    </Dropdown>
  );
}
