import { connect } from 'react-redux';
import { EventTrackerService, ProjectMetaService } from '@premagic/core';
import ProjectHighlightCoverPhoto from './ProjectHighlightCoverPhoto';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
  uploadProjectCoverPhoto,
} from '../project-meta/ProjectsMetaDataDuck';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { companyLogoSelector, photographerNameSelector } from '../../settings/company/CompanyDataDuck';
import { projectsSelector } from '../AccountProjectsDataDuck';
import { clientSettingsDataSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { loadHighlightFont } from '../highlight-folders/highlight-gallery-design/HighlightDesignCustomizationPageDuck';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.COVER_IMAGE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  },
) {
  const { projectId, coverLayout } = ownProps;
  const projectMetaData = projectMetaDataForProjectSelector(projectId)(state);
  const clientSettings = clientSettingsDataSelector(state);

  return {
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.COVER_UPDATE)(state),
    coverPhoto: projectMetaData?.cover_image,
    focalPoint: projectMetaData?.cover_image_focal_point,
    primaryFontFamily:
      projectMetaData?.highlight_design__primary_font_family || clientSettings?.highlight_design__primary_font_family,
    secondaryFontFamily: projectMetaData?.highlight_design__secondary_font_family,
    projectId,
    companyLogo: companyLogoSelector(state),
    companyName: photographerNameSelector(state),
    project: projectsSelector(state)[projectId],
    isFontLoading: isLoadingSelector(LOADINGS.FONTS.UPDATE)(state),
    coverLayout,
    isCoverTitleHidden: projectMetaData?.highlight_design__cover_hide_title,
    isCompanyLogoHidden: projectMetaData?.highlight_design__cover_hide_logo,
    isSaving: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    albumCoverTitle: projectMetaData?.album_cover_title,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadCoverPhoto: (projectId: string, coverPhoto: File, cordinates) => {
      dispatch(uploadProjectCoverPhoto(dispatch, projectId, coverPhoto, false));
      dispatch(
        saveProjectMetaData(
          dispatch,
          projectId,
          { data: { cover_image_focal_point: cordinates } },
          {
            itemId: META_ITEM,
          },
        ),
      );
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.SETTING_SET_PROJECT_COVER);
    },
    showSetFocalPointModal: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.PROJECT_COVER_FOCAL_POINT_MODAL, true));
    },
    initFontData: (primaryFontFamily: string, secondaryFontFamily?: string) => {
      dispatch(
        loadHighlightFont(dispatch, {
          primary_font: primaryFontFamily,
          secondary_font: secondaryFontFamily,
        }),
      );
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHighlightCoverPhoto);
