import { DatePicker, FormLabel, LoadingDots, Row } from '@premagic/myne';

type Props = {
  value?: string;
  onChange: (newFilterValue: string) => void;
  isLoading: boolean;
};

export default function DAMLibraryEndDateFilterSelector(props: Props): JSX.Element {
  const { value, onChange, isLoading } = props;

  function handleStartDateOnChange(date) {
    onChange(date);
  }

  return (
    <div>
      <FormLabel>End Date: </FormLabel>
      {isLoading ? (
        <Row center>
          <LoadingDots size="sm" />
        </Row>
      ) : (
        <DatePicker name="date_end" defaultValue={value} onChange={handleStartDateOnChange} />
      )}
    </div>
  );
}
