export enum IMAGE_CLIP_POSITION {
  LEFT_TOP = 'left-top',
  LEFT_MIDDLE = 'left-middle',
  LEFT_BOTTOM = 'left-bottom',
  CENTER_TOP = 'center-top',
  CENTER_MIDDLE = 'center-middle',
  CENTER_BOTTOM = 'center-bottom',
  RIGHT_TOP = 'right-top',
  RIGHT_MIDDLE = 'right-middle',
  RIGHT_BOTTOM = 'right-bottom',
  SCALE = 'scale',
}
export function getImageCrop(
  image: {
    width: number;
    height: number;
  },
  container: {
    width: number;
    height: number;
  },
  clipPosition = IMAGE_CLIP_POSITION.CENTER_MIDDLE,
):
  | {
      x: number;
      y: number;
      width: number;
      height: number;
    }
  | undefined {
  const { width, height } = container;
  const aspectRatio = width / height;

  let newWidth;
  let newHeight;

  const imageRatio = image.width / image.height;

  if (aspectRatio >= imageRatio) {
    newWidth = image.width;
    newHeight = image.width / aspectRatio;
  } else {
    newWidth = image.height * aspectRatio;
    newHeight = image.height;
  }

  const positionCalculations: Record<
    IMAGE_CLIP_POSITION,
    {
      x: number;
      y: number;
      newWidth?: number;
      newHeight?: number;
    }
  > = {
    [IMAGE_CLIP_POSITION.LEFT_TOP]: { x: 0, y: 0 },
    [IMAGE_CLIP_POSITION.LEFT_MIDDLE]: { x: 0, y: (image.height - newHeight) / 2 },
    [IMAGE_CLIP_POSITION.LEFT_BOTTOM]: { x: 0, y: image.height - newHeight },
    [IMAGE_CLIP_POSITION.CENTER_TOP]: { x: (image.width - newWidth) / 2, y: 0 },
    [IMAGE_CLIP_POSITION.CENTER_MIDDLE]: { x: (image.width - newWidth) / 2, y: (image.height - newHeight) / 2 },
    [IMAGE_CLIP_POSITION.CENTER_BOTTOM]: { x: (image.width - newWidth) / 2, y: image.height - newHeight },
    [IMAGE_CLIP_POSITION.RIGHT_TOP]: { x: image.width - newWidth, y: 0 },
    [IMAGE_CLIP_POSITION.RIGHT_MIDDLE]: { x: image.width - newWidth, y: (image.height - newHeight) / 2 },
    [IMAGE_CLIP_POSITION.RIGHT_BOTTOM]: { x: image.width - newWidth, y: image.height - newHeight },
    [IMAGE_CLIP_POSITION.SCALE]: { x: 0, y: 0, newWidth: width, newHeight: height },
  };
  const { newWidth: nw, newHeight: nh, x, y } = positionCalculations[clipPosition] || {};

  return {
    x,
    y,
    width: nw || newWidth,
    height: nh || newHeight,
  };
}

export function getCropForCenterAlign(
  imageAspectRadio: number,
  container: {
    width: number;
    height: number;
  },
): {
  x: number;
  y: number;
  width: number;
  height: number;
} {
  const containerAspectRadio = container.width / container.height;
  let x = 0;
  let y = 0;
  let { height, width } = container;

  if (imageAspectRadio > containerAspectRadio) {
    height = container.width / imageAspectRadio;
    y = (container.height - height) / 2;
  } else {
    width = container.height * imageAspectRadio;
    x = (container.width - width) / 2;
  }

  return {
    x,
    y,
    width,
    height,
  };
}
