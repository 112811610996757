import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';
import { isMobileUp } from '../../AppDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import UserDetails from './UserDetails';
import { requestingUserIdSelector, usersItemsSelector } from './UsersDataDuck';
import APP_URLS from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';

function mapStateToProps(state, ownProps) {
  const user = usersItemsSelector(state)[ownProps.id];

  return {
    user,
    isMobileUp: isMobileUp(state),
    requestingUserId: requestingUserIdSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteUser: (userId) => {
      const deleteUserPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.DELETE, { userId });
      navigateTo(dispatch, deleteUserPage);
    },
    showActivateUser: (userId) => {
      const activateUserPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.ACTIVATE, { userId });
      navigateTo(dispatch, activateUserPage);
    },
    showEditUser: (userId) => {
      const editUserPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.EDIT, { userId });
      navigateTo(dispatch, editUserPage);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
