import React, { useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { BrowserUrlUtils } from '@premagic/utils';

import { BUTTON_ICON_STYLES, ButtonIcon, Col, Divider, Icon, Row, Space } from '@premagic/myne';
import AlbumCreatorHeaderContainer from './AlbumCreatorLeftPaneContainer';
import AlbumCreatorImagesTrayContainer from './AlbumCreatorImagesTrayContainer';
import AlbumCreatorSinglePageContainer from './page/AlbumCreatorSinglePageContainer';
import { AlbumPageType, NewAlbumPageType } from './services/AlbumServices';
import AlbumCreatorPageNavigatorButtonContainer from './AlbumCreatorPageNavigatorButtonContainer';
import { ALBUM_CREATOR_URLS } from './services/AlbumCreatorRouteURLService';
import AlbumStateInfoContainer from './album/album-share/AlbumStateInfoContainer';

type Props = {
  pageNo: number;
  albumId: string;
  eventId?: string;
  pageData: NewAlbumPageType | AlbumPageType;
  setActivePage: (pageNo: number) => void;
  addImageToAlbumPage: (
    albumId: string,
    pageData: NewAlbumPageType | AlbumPageType,
    newImagePosition: string,
    newImageId: string,
  ) => void;
};

export default function AlbumCreatorLayout(props: Props) {
  const { pageNo, setActivePage, addImageToAlbumPage, albumId, pageData, eventId } = props;

  useEffect(() => {
    setActivePage(pageNo);
  }, [pageNo]);

  function onDrop(options: DropResult) {
    const { reason, draggableId, destination } = options;
    if (reason === 'DROP' && destination && draggableId !== 'IMAGE_TRAY') {
      const position = destination.droppableId;
      const imageId = draggableId;
      addImageToAlbumPage(albumId, pageData, position, imageId);
    }
  }

  const eventDetailsURL = eventId
    ? BrowserUrlUtils.getRouteUrlFor(ALBUM_CREATOR_URLS.CRM.EVENT__DELIVERABLE, {
        eventId,
        focusId: albumId,
      })
    : '';

  const paneStyle = {
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 10px 3px',
    height: '100%',
    overflowY: 'auto' as const,
  };

  return (
    <div
      style={{
        backgroundImage:
          'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=")',
        height: 'var(--app-height)',
      }}
    >
      <DragDropContext onDragEnd={onDrop}>
        <Row fullHeight>
          <Col size={null}>
            <div style={paneStyle}>
              <ButtonIcon
                style={BUTTON_ICON_STYLES.SECONDARY}
                link={eventDetailsURL}
                title="Back to Event"
                tooltipPlacement="bottom"
              >
                <Icon name="arrow_left" />
              </ButtonIcon>
              <Divider />
              <AlbumCreatorHeaderContainer />
            </div>
          </Col>
          <Space size={1} />
          <Col size={9}>
            <Row center fullHeight>
              <AlbumCreatorPageNavigatorButtonContainer buttonDirection="previous" />
              <AlbumCreatorSinglePageContainer />
              <AlbumCreatorPageNavigatorButtonContainer buttonDirection="next" />
            </Row>
          </Col>
          <Space size={1} />
          <Col size={3} rightAlighed>
            <div style={paneStyle}>
              <AlbumStateInfoContainer />
              <Divider />
              <AlbumCreatorImagesTrayContainer />
            </div>
          </Col>
        </Row>
      </DragDropContext>
    </div>
  );
}
