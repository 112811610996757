import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';
import { ClientWebsiteService } from '@premagic/core';
import { entitiesDataSelector, EntitiesStateType } from '../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_WEBSITE_FILES');

export const setClientWebsiteFiles = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addClientWebsiteFile = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

type StateClientWebsiteFilesItemsType = IMap<string, ClientWebsiteService.ClientWebsiteFileType>;
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setClientWebsiteFiles.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addClientWebsiteFile.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState,
);

const clientWebsiteFilesDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => entities.clientWebsiteFiles as { items: StateClientWebsiteFilesItemsType },
);

const clientWebsiteFilesEntitesSelector = createSelector(clientWebsiteFilesDataSelector, (state) => state.items);

export const clientWebsiteFilesSelector = createSelector(
  clientWebsiteFilesEntitesSelector,
  (items: StateClientWebsiteFilesItemsType) => items.toJS(),
);

export const clientWebsiteFileSelector = (id: string) =>
  createSelector(clientWebsiteFilesEntitesSelector, (items: StateClientWebsiteFilesItemsType) => items.get(id));
