import { FileService } from '@premagic/core';
import { SimpleImage } from '@premagic/myne';
import React from 'react';

type Props = { file?: FileService.FolderFileType };

export default function QRCodeCustomLEDBackgroundPoster(props: Props) {
  const { file } = props;
  const { dynamic_image_url: imageUrl } = file || {};

  if (!imageUrl) return <div style={{ height: '100%', width: '100%', background: 'white' }} />;

  return <SimpleImage src={imageUrl} alt="poster" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />;
}
