export enum SOCIAL_MEDIAS {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  PINTEREST = 'pinterest',
  VIMEO = 'vimeo',
  WHATSAPP = 'whatsapp',
  YOUTUBE = 'youtube',
}

export const SOCIAL_MEDIAS_DETAILS: Record<
  SOCIAL_MEDIAS,
  {
    icon: string;
    url: string;
  }
> = {
  [SOCIAL_MEDIAS.FACEBOOK]: {
    icon: 'facebook_solid_color',
    url: 'https://www.facebook.com/',
  },
  [SOCIAL_MEDIAS.TWITTER]: {
    icon: 'twitter_solid_color',
    url: 'https://www.twitter.com/',
  },
  [SOCIAL_MEDIAS.INSTAGRAM]: {
    icon: 'instagram_solid_color',
    url: 'https://www.instagram.com/',
  },
  [SOCIAL_MEDIAS.LINKEDIN]: {
    icon: 'linkedin_solid_color',
    url: 'https://www.linkedin.com/',
  },
  [SOCIAL_MEDIAS.PINTEREST]: {
    icon: 'pinterest_solid_color',
    url: 'https://www.pinterest.com/',
  },
  [SOCIAL_MEDIAS.VIMEO]: {
    icon: 'vimeo_solid_color',
    url: 'https://www.vimeo.com/',
  },
  [SOCIAL_MEDIAS.WHATSAPP]: {
    icon: 'whatsapp_solid_color',
    url: 'https://wa.me/',
  },
  [SOCIAL_MEDIAS.YOUTUBE]: {
    icon: 'youtube_solid_color',
    url: 'https://www.youtube.com/',
  },
};
