import ClassNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Portal } from 'react-overlays';
import styles from '../Alert/alert.module.css';

import { BUTTON_ICONS_SIZES, ButtonIcon } from '../Button/Buttons';
import { Icon, ICON_SIZES } from '../Icon/Icons';

function MessageInHTML(props: { children: React.ReactNode }) {
  const { children } = props;
  // @ts-ignore
  return <div dangerouslySetInnerHTML={{ __html: children }} />;
}

const TOAST_ICONS = {
  success: 'check_circle',
  info: 'info',
  error: 'slash',
  warning: 'clock',
};

type Props = {
  store: any;
};

export function ToastContainer(props: Props) {
  const { store } = props;
  const [toasts, setToasts] = useState<Array<{ id: number; timer: number; status: string; message: string }>>([]);

  function removeToastMessage(toastId: any) {
    setToasts(toasts.filter((t) => t.id !== toastId));
  }

  useEffect(() => {
    const storeSubscription = store.watch((data) => {
      const toast = { ...data, id: Math.random() };

      setToasts([toast].concat(toasts));

      if (['success', 'info'].includes(toast.status)) {
        setTimeout(() => {
          removeToastMessage(toast.id);
        }, data.timer || 3000);
      }
    });
    return () => {
      store.unwatch(storeSubscription);
    };
  }, []);

  return (
    <Portal container={document.querySelector('body')}>
      <div className={styles.toasts}>
        {toasts.map((toast) => (
          <div key={toast.id} className={ClassNames(styles.toast, styles[`toast--${toast.status}`])}>
            <div className={ClassNames(styles.toast__icon, styles[`toast__icon--${toast.status}`])}>
              <Icon name={TOAST_ICONS[toast.status]} size={ICON_SIZES.SM} />
            </div>
            <div className={ClassNames(styles.toast__close)}>
              <ButtonIcon
                title="Close"
                tooltipPlacement="left"
                onKeyPress={() => removeToastMessage(toast.id)}
                onClick={() => removeToastMessage(toast.id)}
                size={BUTTON_ICONS_SIZES.SM}
              >
                <Icon name="x" size={ICON_SIZES.SM} />
              </ButtonIcon>
            </div>
            <div className={styles.toast__content}>
              <MessageInHTML>{toast.message}</MessageInHTML>
            </div>
          </div>
        ))}
      </div>
    </Portal>
  );
}
