import { ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import ContactInfoForNotification from './ContactInfoForNotification';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const projectMetaData = projectMetaDataForProjectSelector(projectId)(state);
  return {
    projectId,
    registrationType:
      projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_TYPE] ||
      ProjectMetaService.EVENT_REGISTRATION_TYPES.PUBLIC,
    eventRegistrationSendNotification:
      projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION],
    eventRegistrationSendNotificationRequired:
      projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED],
    allowedEmailDomains:
      projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_ALLOWED_EMAIL_DOMAINS],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoForNotification);
