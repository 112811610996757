import { connect } from 'react-redux';
import { LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';

import { EventPosterService } from '@premagic/core';
import EventPosterList from './EventPosterList';
import { LOADINGS } from '../../../../../common/Constants';
import { eventPosterIdsForTypeSelector, getEventPostersForProject } from './EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    focusId?: string;
    type: EventPosterService.EVENT_POSTER_TYPE;
    listStyle?: 'list' | 'mini-item';
  },
) {
  const { eventId, projectId, focusId, type, listStyle } = ownProps;

  return {
    eventId,
    projectId,
    focusId,
    type,
    listStyle,
    posterIds: eventPosterIdsForTypeSelector(type)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      dispatch(getEventPostersForProject(dispatch, projectId));
    },
    showAddNewEventPoster: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterList);
