import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { toggleModalVisibility } from '../../../../../../../../common/ModalDuck';
import { editQRCodeDesigns, qrCodeDesignsSelector } from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeCustomLEDPoster from './QRCodeCustomLEDPoster';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { folderId, projectId } = ownProps;
  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);
  return {
    selectedPoster: qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_LED_POSTER],
    folderId,
    projectId,
    qrCodeDesigns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openCustomPosterSelectModal: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_LED_CUSTOM_POSTER_MODAL, true));
    },
    removeCustomLEDPoster: (projectId, folderId, qrCodeDesigns) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_LED_POSTER]: '',
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeCustomLEDPoster);
