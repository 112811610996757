import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { fetchAppData, isMobileUp } from '../AppDuck';
import AppLayoutPage from './AppLayoutPage';
import { hasPermission } from '../crm/users/UsersDataDuck';
import { PERMISSIONS } from '../crm/users/UserPermissionService';
import { companyLogoSelector } from '../settings/company/CompanyDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureLeadEnabledSelector,
} from '../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.APP_LOADING)(state),
    isMobileUp: isMobileUp(state),
    hasPermissions: {
      [PERMISSIONS.SETTINGS]: hasPermission(PERMISSIONS.SETTINGS)(state),
      [PERMISSIONS.EVENT_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_MANAGEMENT)(state),
      [PERMISSIONS.LEAD_MANAGEMENT]: hasPermission(PERMISSIONS.LEAD_MANAGEMENT)(state),
      [PERMISSIONS.REPORTS]: hasPermission(PERMISSIONS.REPORTS)(state),
      [PERMISSIONS.FINANCIALS]: hasPermission(PERMISSIONS.FINANCIALS)(state),
      [PERMISSIONS.DAM_LIBRARY]: hasPermission(PERMISSIONS.DAM_LIBRARY)(state),
    },
    activeAccountLogo: companyLogoSelector(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchAppData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayoutPage);
