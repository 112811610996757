import React from 'react';
import { FormGroup, FormLabel } from '@premagic/myne';
import { NumberUtils } from '@premagic/utils';

import { CRMPaymentCategoryType, CRMPaymentType } from '../CRMPaymentService';
import InputCurrencyContainer from '../../../acccount/InputCurrencyContainer';

type Props = {
  payment?: CRMPaymentType;
  quoteCategory?: CRMPaymentCategoryType;
};

export default function QuotePaymentForm(props: Props): JSX.Element {
  const { payment, quoteCategory } = props;
  const isNew = !payment;
  if (!quoteCategory) return <div>This is an error, add the quote category on the BE</div>;
  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Amount</FormLabel>
        <InputCurrencyContainer autoFocus name="amount" required defaultValue={payment?.amount} />
      </FormGroup>
      {isNew && <input type="hidden" name="payment_category" value={quoteCategory.id} />}
    </div>
  );
}
