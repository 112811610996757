import { QRCodeBackgroundPosterService, SponsorCreativesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import {
  isModalOpenSelector,
  modalOptionsSelector,
  setModalOptions,
  toggleModalVisibility,
} from '../../../../../../../common/ModalDuck';
import { sponsorCreativesIdsByPlacementSelector } from '../../../../crm/events/event-sponsor-creatives/SponsorCreativeDataDuck';
import {
  editQRCodeDesigns,
  qrCodeDesignsSelector,
  qrCodeDesignsSponsorSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodePosterSponsorLogoSelectorModal from './QRCodePosterSponsorLogoSelectorModal';

const KEY = LOADINGS.QR_CODE.SELECT_SPONSOR_LOGO_MODAL;

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  const modalOptions = modalOptionsSelector(KEY)(state);
  const { logoId }: { logoId?: QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS } = modalOptions || {};

  const sponsorLogoForPosterData = qrCodeDesignsSponsorSelector(folderId)(state);

  return {
    projectId,
    folderId,
    show: isModalOpenSelector(KEY)(state),
    logoId,
    sponsorCreativesIds: sponsorCreativesIdsByPlacementSelector(
      SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.FOOTER,
    )(state),
    selectedCreativeId: logoId ? sponsorLogoForPosterData[logoId] : undefined,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
    sponsorLogoForPosterData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_SPONSOR_LOGO_MODAL, false));
      dispatch(setModalOptions(dispatch, LOADINGS.QR_CODE.SELECT_SPONSOR_LOGO_MODAL, {}));
    },
    updateSponsorLogo: (projectId, folderId, qrCodeDesigns, sponsorLogoDesigns) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.SPONSOR_LOGO]: sponsorLogoDesigns,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterSponsorLogoSelectorModal);
