import { List, SCROLLABLE_CONTAINER_SIZE, ScrollableContainer, Space, Text } from '@premagic/myne';
import SponsorCategoryListItemContainer from './SponsorCategoryListItemContainer';

type Props = {
  sponsorsCategoryListItemsIds?: Array<string>;
  handleSelectCategory: (sponsorCategoryId: string) => void;
  selectedId?: string;
};

export default function SponsorCategoryList(props: Props) {
  const { sponsorsCategoryListItemsIds, handleSelectCategory, selectedId } = props;

  if (!sponsorsCategoryListItemsIds || sponsorsCategoryListItemsIds?.length === 0) {
    return (
      <>
        <Space vertical size={2} />
        <Text muted>No sponsor category is added.</Text>
        <Space vertical size={2} />
      </>
    );
  }

  return (
    <ScrollableContainer direction="vertical" size={SCROLLABLE_CONTAINER_SIZE.MD}>
      <List>
        {sponsorsCategoryListItemsIds.map((id) => (
          <SponsorCategoryListItemContainer
            key={id}
            active={selectedId === id}
            sponsorCategoryId={id}
            handleSelectCategory={handleSelectCategory}
          />
        ))}
      </List>
    </ScrollableContainer>
  );
}
