import { StorePricingService } from '@premagic/core';
import { connect } from 'react-redux';
import { storePricingDataSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import PriceSetterTable from './PriceSetterTable';

function mapStateToProps(
  state,
  ownProps: {
    product: StorePricingService.ProductItemType;
    defaultSubProductPrices?: StorePricingService.StorePricingType;
  },
) {
  const { product, defaultSubProductPrices } = ownProps;
  const storePricingData = storePricingDataSelector(state);
  const defaultCurrencyCode =
    defaultSubProductPrices?.[StorePricingService.STORE_CURRENCY] || storePricingData?.store_currency;

  return { product, currencyCode: defaultCurrencyCode, defaultSubProductPrices };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceSetterTable);
