import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import { AccountFinancialsFiltersType } from './AccountFinancialsFilterTypes';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';

export function getAccountFinancialsUrlWithFilters(
  type: ACCOUNT_FINANCIALS_TYPES,
  filters: AccountFinancialsFiltersType,
): string {
  return BrowserUrlUtils.getRouteUrlFor(APP_URLS.FINANCIALS.DETAILS, {
    type,
    filters: BrowserUrlUtils.getQueryStringFromObject(filters),
  });
}

export function getDefaultAccountFinancialsUrlWithFilters(view: ACCOUNT_FINANCIALS_TYPES): string {
  const today = SimpleDateUtils.now();
  const startDate = SimpleDateUtils.getDateStringFromDate(
    SimpleDateUtils.getStartDateOfMonth(today),
    SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO,
  );
  const defaultFilter: Record<ACCOUNT_FINANCIALS_TYPES, AccountFinancialsFiltersType> = {
    [ACCOUNT_FINANCIALS_TYPES.PENDING]: {
      start_date_gte: startDate,
    },
    [ACCOUNT_FINANCIALS_TYPES.UPCOMING]: {
      start_date_gte: startDate,
    },
    [ACCOUNT_FINANCIALS_TYPES.EXPENSE]: {
      start_date_gte: startDate,
    },
    [ACCOUNT_FINANCIALS_TYPES.UNSETTLED_EXPENSE]: {
      start_date_gte: startDate,
    },
  };

  return getAccountFinancialsUrlWithFilters(view, defaultFilter[view]);
}
