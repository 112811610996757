import React from 'react';
import { isNull } from 'lodash';
import { Label, LABEL_STYLES } from '@premagic/myne';

type EventPaymentStatusLabelProps = {
  isSettled: boolean | null;
};

export default function EventPaymentStatusLabel(props: EventPaymentStatusLabelProps): JSX.Element {
  const { isSettled } = props;
  if (isNull(isSettled)) return <Label style={LABEL_STYLES.DEFAULT}>All</Label>;
  return (
    <Label style={isSettled ? LABEL_STYLES.SUCCESS : LABEL_STYLES.WARNING}>{isSettled ? 'SETTLED' : 'PENDING'}</Label>
  );
}
