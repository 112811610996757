import React from 'react';
import { FolderService, ProjectMetaService, ProjectService } from '@premagic/core';
import { InnerPageContentMainCol, SubTitle, Space, InnerPageContentSidebar, Row } from '@premagic/myne';
import ProjectPinNumber from '../../ProjectPinNumber';
import SelectionFoldersPageContainer from './SelectionFoldersContainer';
import SelectionFolderContainer from './SelectionFolderDetailsContainer';
import SelectionBudgetContainer from '../SelectionBudgetContainer';
import ProjectPinNumberResetContainer from '../../ProjectPinNumberResetContainer';
import ProjectPermissionsSettingsContainer from '../../folder-permissions/ProjectPermissionsSettingsContainer';

type Props = {
  activeFolderId?: string;
  projectId: string;
  project: ProjectService.ProjectType;
};

export default function SelectionFoldersPageFoldersLayout(props: Props) {
  const { projectId, project, activeFolderId } = props;

  return (
    <>
      <InnerPageContentMainCol>
        <SelectionFoldersPageContainer projectId={projectId} activeFolderId={activeFolderId} />
        <Space vertical />
        {activeFolderId && <SelectionFolderContainer projectId={projectId} folderId={activeFolderId} />}
      </InnerPageContentMainCol>

      <InnerPageContentSidebar>
        <SubTitle>Project Details</SubTitle>
        <Space vertical size={4} />
        <Row>
          <ProjectPinNumber pin={project?.mpin} />
          <Space />
          {project?.mpin && <ProjectPinNumberResetContainer projectId={projectId} />}
        </Row>
        <Space vertical size={4} />
        <SelectionBudgetContainer projectId={projectId} />
        <Space size={7} vertical />
        <ProjectPermissionsSettingsContainer projectId={projectId} folderType={FolderService.FOLDER_TYPES.SELECTION} />
      </InnerPageContentSidebar>
    </>
  );
}
