import React from 'react';
import { PosterBox } from './BaseWidgets';
import { PosterWidgetRectType } from '../services/PosterWidgetService';

type PosterQRCodeType = {
  data: PosterWidgetRectType;
};

const PosterQRCode = React.forwardRef((props: PosterQRCodeType, ref: React.Ref<any>) => {
  const { data, ...rest } = props;
  const { position, size, value, id } = data;
  return <PosterBox data={data} />;
});

export default PosterQRCode;
