import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  InnerPageHeader,
  Text,
  InnerPage,
  InnerPageContent,
  Dialog,
  DIALOG_PLACEMENT,
  Space,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  Menu,
  MenuItem,
  MenuItemIcon,
  DialogTitle,
  COLOR_SHADES,
  DialogFooter,
  BUTTON_SIZE,
  Row,
  ListItemAction,
  LIST_ITEM_ACTION_STYLES,
  Tooltip,
  Color,
  Col,
  ScrollableContainer,
  Input,
  Form,
  INPUT_SIZES,
  FormGroup,
  FormLabel,
  DIALOG_SIZES,
  FormFooter,
} from '@premagic/myne';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import APP_URLS from '../../../../services/AppRouteURLService';
import WorkflowTaskGroupContainer from './WorkflowTaskGroupContainer';
import MESSAGES from '../../../../../../common/Messages';
import { WorkflowType } from '../service/WorkflowService';

type OptionsMenuProps = {
  isLoading: boolean;
  workflowId: number;
  deleteWorkflow: (workflowId: number) => void;
  workflowName: string;
  changeWorkflowName: (workflowId: number, workflowName: string) => void;
  isWorkflowSaving: boolean;
  isDeletingWorkflow: boolean;
};

// Later we can move this to a separate component - WorkflowHeaderOptionsMenu
function OptionsMenu(props: OptionsMenuProps) {
  const {
    isLoading,
    deleteWorkflow,
    workflowId,
    workflowName,
    changeWorkflowName,
    isWorkflowSaving,
    isDeletingWorkflow,
  } = props;
  const target = useRef(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  function handleWorkflowNameEdit(data) {
    changeWorkflowName(workflowId, data.data.workflow_name);
    setIsEditDialogOpen(false);
  }

  return (
    <>
      <Dropdown>
        <DropdownIcon
          title="More"
          tooltipPlacement="top"
          isLoading={isLoading || isWorkflowSaving || isDeletingWorkflow}
        >
          <Icon name="more_vertical" size={ICON_SIZES.MD} />
        </DropdownIcon>
        <Menu>
          {/* Will implement duplicate workflow later */}
          {/* <MenuItem onClick={() => console.log('Duplicate')}>
            <MenuItemIcon name="copy" />
            Duplicate Workflow
          </MenuItem> */}

          <MenuItem onClick={() => setIsEditDialogOpen(!isEditDialogOpen)}>
            <MenuItemIcon name="edit" />
            Edit name
          </MenuItem>
          <MenuItem onClick={() => setIsDeleteDialogOpen(!isDeleteDialogOpen)}>
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      <span ref={target}>
        {(() => {
          if (isEditDialogOpen) {
            return (
              <Dialog
                target={target.current}
                show={isEditDialogOpen}
                placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
                onClose={() => setIsEditDialogOpen(false)}
                size={DIALOG_SIZES.MD}
              >
                <Space size={2}>
                  <Form onSubmit={handleWorkflowNameEdit}>
                    <FormGroup>
                      <FormLabel>Workflow Name</FormLabel>
                      <Input
                        name="workflow_name"
                        placeholder="eg. Wedding workflow"
                        autoFocus
                        required
                        size={INPUT_SIZES.AUTO}
                        defaultValue={workflowName}
                      />
                    </FormGroup>
                    <FormFooter>
                      <Button style={BUTTON_STYLES.PRIMARY} block isLoading={isWorkflowSaving}>
                        Save
                      </Button>
                    </FormFooter>
                  </Form>
                </Space>
              </Dialog>
            );
          }

          return (
            <Dialog
              target={target.current}
              show={isDeleteDialogOpen}
              placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
              onClose={() => setIsDeleteDialogOpen(false)}
            >
              <DialogTitle>Delete this Workflow?</DialogTitle>
              <Text>Are you sure you want to delete this Workflow?</Text>
              <Space vertical size={6} />
              <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
              <DialogFooter>
                <Button style={BUTTON_STYLES.CANCEL} onClick={() => setIsDeleteDialogOpen(false)}>
                  Cancel
                </Button>
                <Button
                  style={BUTTON_STYLES.DANGER}
                  onClick={() => deleteWorkflow(workflowId)}
                  isLoading={isDeletingWorkflow}
                >
                  Delete
                </Button>
              </DialogFooter>
            </Dialog>
          );
        })()}
      </span>
    </>
  );
}

type Props = {
  id: string;
  workflow: WorkflowType;
  taskgroupIds: Array<number>;
  initData: (workflowId: number) => void;
  isLoading: boolean;
  isCreatingTaskgroup: boolean;
  createNewTaskgroup: (workflowId: any, taskgroupName: any) => void;
  deleteWorkflow: (workflowId: number) => void;
  changeTaskgroupOrder: (workflowId: number, taskgroupIds: Array<number>, data: { from: number; to: number }) => void;
  changeWorkflowName: (workflowId: number, workflowName: string) => void;
  isWorkflowSaving: boolean;
  isDeletingWorkflow: boolean;
};

export default function WorkflowDetailsPage(props: Props): JSX.Element {
  const {
    id,
    initData,
    isLoading,
    workflow,
    createNewTaskgroup,
    deleteWorkflow,
    isCreatingTaskgroup,
    changeTaskgroupOrder,
    taskgroupIds,
    changeWorkflowName,
    isWorkflowSaving,
    isDeletingWorkflow,
  } = props;

  const { id: workflowId, name: workflowName } = workflow;

  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;

    if (reason === 'DROP' && destination) {
      changeTaskgroupOrder(workflowId, taskgroupIds, {
        from: source.index,
        to: destination.index,
      });
    }
  }

  useEffect(() => {
    initData(Number(id));
  }, [initData, id]);

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="template"
        title="Workflow"
        backTo={APP_URLS.SETTINGS_WORKFLOW.WORKFLOW}
        rightActions={
          <OptionsMenu
            isLoading={isLoading}
            workflowId={workflowId}
            deleteWorkflow={deleteWorkflow}
            workflowName={workflowName}
            changeWorkflowName={changeWorkflowName}
            isWorkflowSaving={isWorkflowSaving}
            isDeletingWorkflow={isDeletingWorkflow}
          />
        }
      >
        {workflowName}
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        {taskgroupIds?.length === 0 && (
          <div>
            <Text muted>You haven&apos;t added any Task group templates yet</Text>
          </div>
        )}
        <DragDropContext onDragEnd={onDrop}>
          <Droppable droppableId="TASKGROUP-LIST" direction="vertical">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {taskgroupIds?.map((taskgroupId, idx) => (
                  <Draggable key={taskgroupId.toString()} draggableId={taskgroupId.toString()} index={idx}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <WorkflowTaskGroupContainer
                          key={taskgroupId.toString()}
                          taskgroupId={taskgroupId}
                          workflowId={workflowId}
                        />
                        <Space vertical />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Space vertical size={6} />
          <Button
            style={BUTTON_STYLES.PRIMARY}
            size={BUTTON_SIZE.MD}
            onClick={() => createNewTaskgroup(workflowId, `Task group ${taskgroupIds ? taskgroupIds.length + 1 : ''}`)}
            isLoading={isCreatingTaskgroup}
          >
            Add Task Group
          </Button>
        </DragDropContext>
      </InnerPageContent>
    </InnerPage>
  );
}
