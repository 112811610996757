import React from 'react';
import ClassNames from 'classnames';

import './lib-video-js-overrides.css';
import styles from './media.module.css';
import { Icon, ICON_SIZES } from '../Icon/Icons';

export enum VIDEO_TYPES {
  STREAM = 'application/x-mpegURL',
  DEFAULT = 'video/mp4',
}

type VideoPlayButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

export function VideoPlayButton(props: VideoPlayButtonProps): JSX.Element {
  const { children, onClick } = props;
  if (onClick) {
    return (
      <button className={ClassNames(styles['video-play-button'])} onClick={onClick} type="button">
        <div className={ClassNames(styles['video-play-button__overlay'])}>
          <Icon name="play_circle" size={ICON_SIZES.XLG} className={ClassNames(styles['video-play-button__icon'])} />
        </div>
        {children}
      </button>
    );
  }

  return (
    <div className={ClassNames(styles['video-play-button'])}>
      <div className={ClassNames(styles['video-play-button__overlay'])}>
        <Icon name="play_circle" size={ICON_SIZES.XLG} className={ClassNames(styles['video-play-button__icon'])} />
      </div>
      {children}
    </div>
  );
}
