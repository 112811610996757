import { FileService, SponsorCreativesService } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ErrorTracker, PhotoWatermarkUtils } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { omit } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { pagesSelector } from '../../../../reducers/selectors';
import { filesEntitiesDataSelector } from '../../../images/FilesDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('SPONSOR_CREATIVE');

export const setSponsorCreativeData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addSponsorCreativeData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

const updateSponsorCreativeData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const removeSponsorCreativeData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const updateSponsorCreative = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; creativeId: string },
    formResponse: FormResponseType & {
      data: SponsorCreativesService.SponsorCreativeType;
    },
  ) => {
    const loadingKey = LOADINGS.SPONSOR_CREATIVES.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    SponsorCreativesService.updateSponsorCreative(options, data)
      .then((response) => {
        dispatch(updateSponsorCreativeData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_SPONSOR', e);
      });
  },
);

export const updateSponsorCreativeWatermark = createAction(
  getActionType('ACTION', 'UPDATE_WATERMARK'),
  (
    dispatch,
    options: { projectId: string; creativeId: string },
    formResponse: FormResponseType & {
      data: SponsorCreativesService.NewSponsorCreativeType & {
        watermark_folder_ids: Array<string>;
        watermark_opacity: number;
        watermark_size: number;
        watermark_position: string;
        watermark_position_x: number | string;
        watermark_position_y: number | string;
      };
    },
  ) => {
    const loadingKey = LOADINGS.SPONSOR_CREATIVES.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    if (!data.watermark_folder_ids || !data.sponsor_id) return;

    const watermarkFolderIds = Array.isArray(data.watermark_folder_ids)
      ? data.watermark_folder_ids
      : [data.watermark_folder_ids];

    const dataForSponsorCreative = omit(data, [
      'watermark_folder_ids',
      'watermark_opacity',
      'watermark_size',
      'watermark_position',
      'watermark_position_x',
      'watermark_position_y',
    ]) as unknown as SponsorCreativesService.SponsorCreativeType;

    const dataToPost = {
      ...dataForSponsorCreative,
      meta: {
        watermark_folder_ids: watermarkFolderIds,
        watermark_opacity: data.watermark_opacity,
        watermark_size: data.watermark_size,
        watermark_position: data.watermark_position,
        watermark_position_x: data.watermark_position_x,
        watermark_position_y: data.watermark_position_y,
      },
    };

    SponsorCreativesService.updateSponsorCreative(options, dataToPost)
      .then((response) => {
        dispatch(updateSponsorCreativeData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_SPONSOR_WATERMARK_CREATIVE', e);
      });
  },
);

type SponsorCreativeItemType = IMap<string, SponsorCreativesService.SponsorCreativeType>;
type StateType = {
  items: SponsorCreativeItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setSponsorCreativeData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addSponsorCreativeData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateSponsorCreativeData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeSponsorCreativeData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

const sponsorCreativeDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.eventSponsor.data.creatives as StateType,
);

export const sponsorCreativeEntitiesSelector = createSelector(
  sponsorCreativeDataSelector,
  (creatives) => creatives.items,
);

export const sponsorCreativesSelector = createSelector(sponsorCreativeEntitiesSelector, (creatives) =>
  creatives.toJSON(),
);

export const sponsorCreativesIdsByPlacementSelector = (
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT,
) =>
  createSelector(sponsorCreativeEntitiesSelector, (creatives) =>
    creatives
      .filter((item) => item.placement === placement)
      .keySeq()
      .toArray(),
  );

export const getSponsorCreativeDataById = (id: string) =>
  createSelector(sponsorCreativeEntitiesSelector, (data) => data.get(id));

const sponsorCreativeWatermarkSelector = createSelector(sponsorCreativeEntitiesSelector, (items) =>
  items.filter((item) => item.placement === SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.WATERMARK).toJSON(),
);

export const sponsorCreativeWatermarksGroupedByFolderIdSelector = createSelector(
  sponsorCreativeEntitiesSelector,
  (sponsorCreative) => {
    if (!sponsorCreative) return undefined;

    return sponsorCreative
      .filter(
        (item) =>
          item.placement === SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.WATERMARK &&
          item?.meta?.watermark_folder_ids &&
          item?.meta?.watermark_folder_ids?.length > 0,
      )
      .reduce((result, item) => {
        const { watermark_folder_ids: watermarkFolderIds } = item.meta || {};
        const data = { ...result };
        watermarkFolderIds?.forEach((folderId) => {
          if (!data[folderId]) {
            data[folderId] = [item.id];
          } else {
            data[folderId].push(item.id);
          }
        });
        return data;
      }, {}) as Record<string, Array<string>>;
  },
);

export const sponsorCreativeWatermarksByFolderIdSelector = (folderId) =>
  createSelector(sponsorCreativeWatermarksGroupedByFolderIdSelector, (folderWatermark) => folderWatermark?.[folderId]);

export const photoWatermarkForSponsorForPreviewSelector = (folderId: string) =>
  createSelector(sponsorCreativeWatermarkSelector, filesEntitiesDataSelector, (state, files) => {
    const sponsorCreativeWatermarks = Object.values(state || {});
    if (sponsorCreativeWatermarks.length > 0) {
      return sponsorCreativeWatermarks
        .filter((watermark) => {
          const watermarkFile = watermark?.asset_ids?.[0];
          const file = watermarkFile ? files.get(watermarkFile) : undefined;
          if (!file) return false;
          return (
            file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.DONE &&
            watermark?.meta?.watermark_folder_ids?.includes(folderId)
          );
        })
        .map((watermark) => {
          const file = watermark?.asset_ids?.[0] ? files.get(watermark?.asset_ids?.[0])?.dynamic_image_url : undefined;
          return {
            opacity: watermark?.meta?.watermark_opacity,
            size: watermark?.meta?.watermark_size,
            position: watermark?.meta?.watermark_position as PhotoWatermarkUtils.WATERMARK_POSITIONS,
            x: watermark.meta?.watermark_position_x,
            y: watermark.meta?.watermark_position_y,
            objKey: file ? file.replace('https://asts-company.premagic.com/', '') : '',
          };
        });
    }
    return undefined;
  });

export const isWatermarkForSponsorProcessingSelector = (folderId: string) =>
  createSelector(
    sponsorCreativeWatermarkSelector,

    filesEntitiesDataSelector,
    (state, files): boolean => {
      const watermarks = Object.values(state || {});
      if (watermarks.length > 0) {
        return watermarks.some((watermark) => {
          const watermarkFile = watermark?.asset_ids?.[0];
          const file = watermarkFile ? files.get(watermarkFile) : undefined;
          if (!file) return false;
          return (
            watermark?.meta?.watermark_folder_ids?.includes(folderId) &&
            (file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.PROCESSING ||
              file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.QUEUED ||
              file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.UPLOADING)
          );
        });
      }
      return false;
    },
  );
