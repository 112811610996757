import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { filesSelector } from '../../../../images/FilesDataDuck';
import {
  editQRCodeDesigns,
  qrCodeDesignsBackgroundPosterDataSelector,
  qrCodeDesignsLogoSelector,
  qrCodeDesignsSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { companyLogoSelector } from '../../../../settings/company/CompanyDataDuck';
import { clientWebsitesSelector } from '../../../ClientWebsiteDataDuck';
import QRCodePosterColorSelector from './QRCodePosterColorSelector';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { folderId, projectId } = ownProps;
  const clientWebsite = clientWebsitesSelector(state)[folderId];
  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);
  const logoOptionType = qrCodeDesigns?.logo_option_type;

  let logoFileData;
  if (logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO) {
    const filesData = filesSelector(state);
    const logos = qrCodeDesignsLogoSelector(folderId)(state);
    const indexForLogo =
      QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_DETAILS[
        QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_1
      ].positionIndex;

    if (logos) {
      logoFileData = filesData[logos[indexForLogo] as string];
    }
  }

  return {
    projectId,
    folderId,
    backgroundCover: qrCodeDesignsBackgroundPosterDataSelector(folderId)(state),
    qrCodeDesigns,
    clientWebsiteColors: clientWebsite?.theme?.color,
    logoFileData,
    logoOptionType,
    companyLogo: companyLogoSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateColors: (
      projectId,
      folderId,
      qrCodeDesigns,
      value?: { colorName: QRCodeBackgroundPosterService.POSTER_COLOR_ID; colorValue: string },
    ) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS]: {
              ...qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS],
              ...(value && { [value.colorName]: value.colorValue }),
            },
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLOR_EXTRACTION_FROM_LOGO]: false,
          },
        }),
      );
    },
    updateMultipleColors: (
      projectId,
      folderId,
      qrCodeDesigns,
      value?: { primaryColorValue1: string; primaryColorValue2: string },
    ) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS]: {
              ...(value && {
                [QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_1]: value.primaryColorValue1,
                [QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_2]: value.primaryColorValue2,
              }),
            },
          },
        }),
      );
    },
    updateSecondaryColors: (
      projectId,
      folderId,
      qrCodeDesigns,
      value?: QRCodeBackgroundPosterService.PosterSecondaryColorType,
    ) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS]: {
              ...qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS],
              [QRCodeBackgroundPosterService.POSTER_COLOR_ID.SECONDARY_COLORS]: value,
            },
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterColorSelector);
