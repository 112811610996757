import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';
import { projectMetaDataForProjectSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import EventAttendeeListHeader from './EventAttendeeListHeader';
import { eventAttendeesInsightSelector } from './EventAttendeesDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
  },
) {
  const { projectId, eventId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);
  return {
    eventId,
    eventAttendeesInsight: eventAttendeesInsightSelector(state),
    hasEnabledApprovalWorkflow:
      !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE],
    hasFaceCheckinEnabled: !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_FACE_CHECKIN_ENABLED],
    hasPhotoDistributionEnabled: true,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeListHeader);
