import { connect } from 'react-redux';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import EditOccasionPanel from './EditOccasionPanel';
import { occasionItemsSelector, updateOccasion } from '../OccasionsDataDuck';
import { occasionIdForEditSelector, setEditOfOccasion } from '../../events/details/EventDetailsPageDuck';

const PANEL_KEY = LOADINGS.OCCASIONS.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.OCCASIONS.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;
  const occasionId = occasionIdForEditSelector(state);

  return {
    eventId,
    occasionId,
    occasion: occasionId ? occasionItemsSelector(state)[occasionId] : null,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOccasion: (occasionId: string, eventId: string, data) => {
      dispatch(updateOccasion(dispatch, occasionId, eventId, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(setEditOfOccasion(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditOccasionPanel);
