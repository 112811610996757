import React from 'react';

import {
  Button,
  BUTTON_CIRCLE_SIZES,
  BUTTON_CIRCLE_STYLES,
  BUTTON_STYLES,
  ButtonCircle,
  Card,
  CARD_STYLES,
  COLOR_SHADES,
  Divider,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProposalDeckService } from '@premagic/proposals';

import ProposalCreatorSlideDataUpdaterContainer from './slide-updater/ProposalCreatorSlideDataUpdaterContainer';
import ProposalCreatorProposalFolderDataUpdaterContainer from './proposal-updater/ProposalCreatorProposalFolderDataUpdaterContainer';
import ProposalCreatorSlideBackgroundDataUpdaterContainer from './slide-updater/ProposalCreatorSlideBackgroundDataUpdaterContainer';

function ClientComment({ comment }: { comment?: string }) {
  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.BOLD} block>
        Client's comment
      </Text>
      <Space size={1} vertical />
      <Card style={CARD_STYLES.SECONDARY}>
        <Space size={3}>
          <Text color={COLOR_SHADES.DARKER}>{comment || '(blank)'}</Text>
        </Space>
      </Card>
    </div>
  );
}

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  deck?: ProposalDeckService.ProposalDeckType;
  isLoading: boolean;
  createNewVersion: (
    projectId: string,
    deckId: string,
    options: {
      folderId: string;
    },
  ) => void;
};

export default function ProposalCreatorRightSidebar(props: Props) {
  const { isLoading, projectId, folderId, deckId, deck, createNewVersion } = props;
  const isDeckPublished = deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;
  if (deck?.review_status) {
    switch (deck?.review_status) {
      case ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.APPROVED:
        return (
          <Row center columnDirection fullHeight>
            <ButtonCircle style={BUTTON_CIRCLE_STYLES.SUCCESS} size={BUTTON_CIRCLE_SIZES.LG}>
              <Icon name="check" size={ICON_SIZES.LG} />
            </ButtonCircle>
            <Space vertical size={8} />
            <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} primaryStyle>
              Proposal is approved
            </Text>
          </Row>
        );
      case ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.REJECTED:
        return (
          <Row center columnDirection fullHeight>
            <ButtonCircle style={BUTTON_CIRCLE_STYLES.DEFAULT} size={BUTTON_CIRCLE_SIZES.LG}>
              <Icon name="x" size={ICON_SIZES.LG} />
            </ButtonCircle>
            <Space vertical size={8} />
            <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} primaryStyle>
              Proposal is rejected
            </Text>
            <Space vertical size={12} />
            <Space size={4}>
              <ClientComment comment={deck.review_comment} />
            </Space>
          </Row>
        );
      case ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.REQUEST_FOR_CHANGE:
        return (
          <Row center columnDirection fullHeight>
            <ButtonCircle style={BUTTON_CIRCLE_STYLES.DEFAULT} size={BUTTON_CIRCLE_SIZES.LG}>
              <Icon name="edit" size={ICON_SIZES.LG} />
            </ButtonCircle>
            <Space vertical size={8} />
            <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} primaryStyle block>
              Client has requested changes.
            </Text>
            <Space vertical size={12} />
            <Space size={4}>
              <ClientComment comment={deck.review_comment} />
              <Space vertical />
              <Button
                style={BUTTON_STYLES.PRIMARY}
                onClick={() =>
                  createNewVersion(projectId, deckId, {
                    folderId,
                  })
                }
                isLoading={isLoading}
              >
                Edit Proposal
              </Button>
            </Space>
          </Row>
        );
      default:
        return null;
    }
  }
  if (isDeckPublished) {
    return (
      <Row center fullHeight>
        <div>
          <Text block size={TEXT_SIZE.SIZE_3} center color={COLOR_SHADES.DARKER}>
            You have shared the proposal with your client.
          </Text>
          <Space vertical size={8} />
          <Text block>Would you like to edit this proposal?</Text>
          <Button
            style={BUTTON_STYLES.LINK}
            onClick={() =>
              createNewVersion(projectId, deckId, {
                folderId,
              })
            }
            isLoading={isLoading}
          >
            Edit Proposal
          </Button>
        </div>
      </Row>
    );
  }

  return (
    <div>
      <ProposalCreatorProposalFolderDataUpdaterContainer projectId={projectId} folderId={folderId} />
      <Divider />
      <ProposalCreatorSlideDataUpdaterContainer projectId={projectId} folderId={folderId} deckId={deckId} />
      <Divider />
      <ProposalCreatorSlideBackgroundDataUpdaterContainer projectId={projectId} folderId={folderId} deckId={deckId} />
    </div>
  );
}
