import { EventsService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  Input,
  INPUT_SIZES,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';

type Props = {
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  projectId?: string;
  eventId?: string;
  clientId?: string;
  updateEventName: (
    options: { eventId: string; clientId: string; projectId?: string; websiteId?: string },
    newName: string,
  ) => void;
  eventData?: EventsService.EventDataType;
  event?: EventsService.EventType;
  websiteId?: string;
};

export default function EditEventNamePanel(props: Props): JSX.Element {
  const {
    clientId,
    show,
    isLoading,
    errors,
    closePanel,
    projectId,
    eventData,
    updateEventName,
    event,
    eventId,
    websiteId,
  } = props;

  function handleOnSubmit(formResponse) {
    if (!clientId || !eventId) return;
    updateEventName({ eventId, clientId, projectId, websiteId }, formResponse.data.name);
    closePanel();
  }

  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Name</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={errors} onSubmit={handleOnSubmit}>
          <FormGroup>
            <FormLabel isRequired>Name</FormLabel>
            <Input
              type="text"
              name="name"
              defaultValue={event?.name}
              placeholder="eg. Tomorrowland"
              size={INPUT_SIZES.MD}
            />
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
