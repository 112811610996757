import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../../../../settings/company/CompanyDataDuck';
import HighlightDesignWelcomeMessageEditor from './HighlightDesignWelcomeMessageEditor';

function mapStateToProps(
  state,
  ownProps: {
    onChange?: (value: string) => void;
    galleryWelcomeText?: string;
    name: string;
  },
) {
  const { onChange, galleryWelcomeText, name } = ownProps;
  return {
    onChange,
    name,
    galleryWelcomeText,
    isWelcomeTextEditorEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignWelcomeMessageEditor);
