import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
} from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import EventPosterFormContainer from './EventPosterFormContainer';

type Props = {
  posterId?: string;
  editEventPoster: (projectId: string, posterId: string, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
  type: EventPosterService.EVENT_POSTER_TYPE;
};

export default function EditEventPosterPanel(props: Props) {
  const { show, close, projectId, editEventPoster, isLoading, error, posterId, type } = props;
  if (!posterId) return null;
  const { title } = EventPosterService.EVENT_POSTER_TYPE_DETAILS[type];
  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Edit {title}</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={error} onSubmit={(formResponse) => editEventPoster(projectId, posterId, formResponse)}>
          <EventPosterFormContainer projectId={projectId} posterId={posterId} type={type} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
