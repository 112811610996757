import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  LogoText,
  Page,
  PAGE_STYLES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { AWSServiceUtils } from '@premagic/utils';
import AuthResetPasswordContainer from './AuthResetPasswordContainer';

enum AUTH_MESSAGES {
  ACTIVATION_LINK_EXPIRED = 'activation-link-expired',
  VERIFIED_ACCOUNT_LOGIN_AGAIN = 'verified-account-login-again',
  SIGNED_UP_LOGIN = 'signed-up-login',
}
const AUTH_MESSAGES_DETAILS: Record<
  AUTH_MESSAGES,
  {
    title: string;
    message: string;
  }
> = {
  [AUTH_MESSAGES.ACTIVATION_LINK_EXPIRED]: {
    title: 'Expired',
    message: 'The invitation link has expired! You can request the account owner to reset it.',
  },
  [AUTH_MESSAGES.VERIFIED_ACCOUNT_LOGIN_AGAIN]: {
    title: 'Verified',
    message: 'Your account is verified, Sign-in to continue',
  },
  [AUTH_MESSAGES.SIGNED_UP_LOGIN]: {
    title: 'Welcome',
    message: 'Login to your Premagic account',
  },
};

type AuthPageHeaderProps = {
  showResetPasswordForm: boolean;
  filterObject?: {
    message?: AUTH_MESSAGES;
  };
};
function AuthPageHeader(props: AuthPageHeaderProps) {
  const { showResetPasswordForm, filterObject } = props;
  if (showResetPasswordForm)
    return (
      <Text block size={TEXT_SIZE.SIZE_2} primaryStyle center>
        Reset Password
      </Text>
    );
  if (filterObject?.message) {
    const message = AUTH_MESSAGES_DETAILS[filterObject.message];
    if (!message)
      return (
        <Text block size={TEXT_SIZE.SIZE_2} primaryStyle center>
          {filterObject.message}
        </Text>
      );

    return (
      <Row center>
        <div>
          <Text size={TEXT_SIZE.SIZE_1} primaryStyle center color={COLOR_SHADES.DARKER} block>
            {message.title}
          </Text>
          <Space vertical size={4} />
          <Text size={TEXT_SIZE.SIZE_3}>{message.message}</Text>
        </div>
      </Row>
    );
  }

  return (
    <Text block size={TEXT_SIZE.SIZE_2} primaryStyle center>
      Welcome,
      <Space vertical size={1} />
      Login to your account
    </Text>
  );
}

type Props = {
  loginUser: (data) => void;
  authLoading: boolean;
  errors?: {
    message: string;
    code: string;
    name: string;
  };
  filterObject?: {
    message?: AUTH_MESSAGES;
  };
  authState: 'signedIn' | 'signUp' | 'NEW_PASSWORD_REQUIRED';
  setNewPassword: (data) => void;
};
export default function AuthPage(props: Props) {
  const { loginUser, authLoading, errors, filterObject, authState, setNewPassword } = props;
  const [showResetPasswordForm, setShowResetForm] = useState(false);

  function handleSubmit(formData) {
    if (authState === 'NEW_PASSWORD_REQUIRED') return setNewPassword(formData);
    loginUser(formData);
  }

  return (
    <Page screenHeight style={PAGE_STYLES.DEFAULT}>
      <Space vertical size={10} />
      <Row center>
        <LogoText />
      </Row>
      <Space vertical size={10} />
      <AuthPageHeader showResetPasswordForm={showResetPasswordForm} filterObject={filterObject} />
      <Space vertical size={12} />
      <Row center columnDirection>
        {errors && (
          <>
            <Row vcenter>
              <Color shade={COLOR_SHADES.DANGER} inline>
                <Icon name="alert_triangle" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} />
              <Text color={COLOR_SHADES.DANGER} boldness={TEXT_BOLDNESS.BOLD}>
                {AWSServiceUtils.getErrorMessage(errors.message)}
              </Text>
            </Row>
            <Space vertical />
          </>
        )}
        {(() => {
          if (showResetPasswordForm) return <AuthResetPasswordContainer />;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <Input name="email" placeholder="john@premagic.com" autoFocus required size={INPUT_SIZES.MD} />
              </FormGroup>

              <FormGroup>
                <Row>
                  <FormLabel>Password</FormLabel>
                  <Col rightAlighed size={null}>
                    <Button style={BUTTON_STYLES.LINK} onClick={() => setShowResetForm(true)} tabIndex={-1}>
                      Forgot?
                    </Button>
                  </Col>
                </Row>
                <Input name="password" type="password" required size={INPUT_SIZES.MD} />
              </FormGroup>
              {authState === 'NEW_PASSWORD_REQUIRED' && (
                <div>
                  <Space vertical />
                  <Text boldness={TEXT_BOLDNESS.BOLD}>Set your new password</Text>
                  <Space vertical />
                  <FormGroup>
                    <FormLabel>New Password</FormLabel>
                    <Input name="newPassword" type="password" required size={INPUT_SIZES.MD} autoFocus />
                  </FormGroup>
                </div>
              )}
              <FormFooter>
                <Button style={BUTTON_STYLES.PRIMARY} block isLoading={authLoading}>
                  Sign in
                </Button>
              </FormFooter>
            </Form>
          );
        })()}
      </Row>
    </Page>
  );
}
// @TODO, Clicking on forgot will ask the user for email
// After user click on confirm ask the user for code and new password and also show a button to resend code
