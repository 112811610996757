import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { LOADINGS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { TaskSchema } from '../../../../../schema/Schemas';
import { entitiesDataSelector } from '../../../../reducers/selectors';
import { addTaskData } from './TaskDataDuck';
import { createTask } from '../service/TaskService';
import { TaskGroupType } from '../service/TaskGroupService';

const getActionType = ActionTypeUtils.getActionTypeFunction('TASK_GROUP');

export const setTaskgroupData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addTaskgroupData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const addTaskToTaskgroup = createAction(getActionType('DATA', 'ADD_TASK'), (dispatch, taskgroupId, taskId) => ({
  taskgroupId,
  taskId,
}));

export const updateTaskgroupData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, taskgroupId, data) => ({
  taskgroupId,
  data,
}));

export const addNewTaskToTaskgroup = createAction(
  getActionType('DATA', 'ADD_NEW_TASK'),
  (dispatch, workflowId: number, taskgroupId, data: { name: string }) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.CREATE_TASK;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    createTask(workflowId, taskgroupId, data)
      .then((response) => {
        const normalizedData = normalize(response, TaskSchema);
        dispatch(addTaskData(dispatch, normalizedData.entities.task));
        dispatch(addTaskToTaskgroup(dispatch, taskgroupId, response.id));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Workflow: create new task failed', e);
      });
  },
);

// might use later
// export const fetchTaskgroupItemData = createAction(
//   getActionType('DATA', 'FETCH_SINGLE_DATA'),
//   (dispatch, workflowId: number, taskgroupId: number) => {
//     const loadingKey = LOADINGS.CRM_WORKFLOW.FETCH_TASKGROUP;
//     dispatch(toggleLoadingState(dispatch, loadingKey, true));
//     dispatch(clearErrorState(dispatch, loadingKey));

//     fetchTaskGroupDetails(workflowId, taskgroupId)
//       .then((response) => {
//         const normalizedData = normalize(response, TaskGroupSchema);
//         dispatch(addTaskgroupData(dispatch, normalizedData.entities.taskgroup));
//         dispatch(addTaskData(dispatch, normalizedData.entities.task));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         return response;
//       })
//       .catch((e) => {
//         dispatch(setErrorState(dispatch, loadingKey, e));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         ErrorTracker.logError('Taskgroup: details fetch failed', e);
//       });
//   },
// );

// might use later
// export const fetchTaskgroupItemsData = createAction(
//   getActionType('DATA', 'FETCH_MULTIPLE_DATA'),
//   (dispatch, workflowId: number) => {
//     const loadingKey = LOADINGS.CRM_WORKFLOW.FETCH_TASKGROUP;
//     dispatch(toggleLoadingState(dispatch, loadingKey, true));
//     dispatch(clearErrorState(dispatch, loadingKey));

//     fetchTaskGroups(workflowId)
//       .then((response) => {
//         const normalizedData = normalize(response.results, TaskGroupsSchema);
//         dispatch(setTaskgroupData(dispatch, normalizedData.entities.taskgroup));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         return response;
//       })
//       .catch((e) => {
//         dispatch(setErrorState(dispatch, loadingKey, e));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         ErrorTracker.logError('Taskgroup: details fetch failed', e);
//       });
//   },
// );

type TaskgroupStateType = {
  items: IMap<string, TaskGroupType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setTaskgroupData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),

    [addTaskgroupData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [addTaskToTaskgroup.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(String(action.payload.taskgroupId), (taskgroup: any) => ({
        ...taskgroup,
        tasks: taskgroup?.tasks?.concat([action.payload.taskId]),
      })),
    }),
    [updateTaskgroupData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(String(action.payload.taskgroupId), (taskgroup: any) => ({
        ...taskgroup,
        ...action.payload.data,
      })),
    }),
  },
  initialState,
);

const taskgroupDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.crm.workflows.taskgroup as TaskgroupStateType,
);

export const taskgroupEntitiesSelector = createSelector(taskgroupDataSelector, (state) => state.items);

export const taskgroupIdsByOrderSelector = createSelector(taskgroupEntitiesSelector, (items) =>
  items
    .sort((taskgroup1, taskgroup2) => taskgroup1.order - taskgroup2.order)
    .keySeq()
    .toArray()
    .map((item) => Number(item)),
);

export const taskgroupItemSelector = (taskgroupId: number) =>
  createSelector(taskgroupEntitiesSelector, (items) => items.get(String(taskgroupId)) || ({} as TaskGroupType));
