import React, { useLayoutEffect } from 'react';
import ClassNames from 'classnames';
import { BrowserUtils } from '@premagic/utils';

import styles from './general-editor.module.css';
import { ErrorBoundary } from '../Error/Error';

function useBodyScrollHide() {
  useLayoutEffect(() => {
    BrowserUtils.disableBodyScroll();
    return () => BrowserUtils.enableBodyScroll();
  }, []);
}

interface GeneralEditorProps {
  children: React.ReactNode;
  hasLeftPanel?: boolean;
  rightPanelSize?: 'sm' | 'md' | 'lg';
}

export function GeneralEditor(props: GeneralEditorProps) {
  const { children, hasLeftPanel = true, rightPanelSize = 'lg' } = props;
  useBodyScrollHide();

  return (
    <div
      className={ClassNames(styles['general-editor'], styles[`general-editor--right-panel-size-${rightPanelSize}`], {
        [styles['general-editor--has-left-panel']]: hasLeftPanel,
      })}
    >
      {children}
    </div>
  );
}

interface GeneralEditorHeaderProps {
  children: React.ReactNode;
}

export function GeneralEditorHeader(props: GeneralEditorHeaderProps) {
  const { children } = props;

  return (
    <div className={styles['general-editor__header']}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}

interface GeneralEditorContentProps {
  children: React.ReactNode;
}

export function GeneralEditorContent(props: GeneralEditorContentProps) {
  const { children } = props;

  return (
    <div className={styles['general-editor__content']}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}

interface GeneralEditorSidebarProps {
  children: React.ReactNode;
  isRight?: boolean;
}

export function GeneralEditorSidebar(props: GeneralEditorSidebarProps) {
  const { children, isRight } = props;

  return (
    <div
      className={ClassNames(styles['general-editor__sidebar'], {
        [styles['general-editor__sidebar--right']]: isRight,
        'js-general-editor-sidebar-right': isRight,
      })}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}
