import React from 'react';

import { Button, BUTTON_STYLES } from '@premagic/myne';
import { StringUtils } from '@premagic/utils';

type Props = {
  variablesSetOnDeck: Array<string>;
  showVariablesPanel: () => void;
};

export default function ProposalCreatorDeckVariablesInfo(props: Props) {
  const { variablesSetOnDeck, showVariablesPanel } = props;
  const totalVariablesSetOnDeck = variablesSetOnDeck.length;

  return (
    <Button style={BUTTON_STYLES.LINK} onClick={showVariablesPanel}>
      {totalVariablesSetOnDeck} {StringUtils.pluralize('Variable', totalVariablesSetOnDeck)}
    </Button>
  );
}
