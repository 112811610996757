import React, { useEffect, useLayoutEffect } from 'react';

import { Row, LoadingSpin } from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';

import AlbumCreatorLayoutContainer from './AlbumCreatorLayoutContainer';

function useBodyScrollHide() {
  useLayoutEffect(() => {
    BrowserUtils.disableBodyScroll();
    return () => BrowserUtils.enableBodyScroll();
  }, []);
}

type Props = {
  isLoading: boolean;
  view: 'preview' | 'edit';
  initData: (albumId: string, view: 'preview' | 'edit') => void;
  albumId: string;
};

export default function AlbumCreator(props: Props) {
  const { initData, albumId, isLoading, view } = props;
  useBodyScrollHide();
  useEffect(() => {
    initData(albumId, view);
  }, [albumId, initData, view]);

  if (isLoading) {
    return (
      <Row center fullHeight>
        <LoadingSpin />
      </Row>
    );
  }

  return <AlbumCreatorLayoutContainer {...props} />;
}
