import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';

import { AuthDuck } from '@premagic/common-ducks';
import AuthPageSigninWithCode from './AuthPageSigninWithCode';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../common/ErrorDuck';

function mapStateToProps(state) {
  const filterObject = BrowserUrlUtils.getQueryObjectFromUrl() as { code: string };
  return {
    filterObject,
    authLoading: isLoadingSelector(LOADINGS.AUTH.LOGIN)(state),
    errors:
      errorObjectForTypeSelector(LOADINGS.AUTH.LOGIN)(state) ||
      errorObjectForTypeSelector(LOADINGS.AUTH.RESET_PASSWORD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (data) => {
      dispatch(AuthDuck.loginUser(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthPageSigninWithCode);
