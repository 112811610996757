import React from 'react';
import {
  Col,
  Color,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  HTML_ICON,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  ListItem,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

import {
  USER_ROLE_DETAILS,
  USER_ROLE_FOR_PHOTOGRAPHER_DETAILS,
  USER_SERVICE_TYPE_DETAILS,
  UserType,
} from './UsersService';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';
import UserNamesContainer from './list/UserNamesContainer';
import MESSAGES from '../../../../../common/Messages';

type Props = {
  user: UserType;
  requestingUserId: string;
  showDeleteUser: (userid: string) => void;
  showEditUser: (userid: string) => void;
  showActivateUser: (userid: string) => void;
  isCompanyTypeNotPhotographer: boolean;
};

export default function UserDetails(props: Props): JSX.Element {
  const { user, requestingUserId, showDeleteUser, showEditUser, showActivateUser, isCompanyTypeNotPhotographer } =
    props;
  const { id, name, role, phone_number: phone, email, service_role: serviceRole, active } = user;
  const userRoleTitle = isCompanyTypeNotPhotographer
    ? USER_ROLE_DETAILS?.[role]?.title
    : USER_ROLE_FOR_PHOTOGRAPHER_DETAILS?.[role]?.title;
  const isRequestingUser = id === requestingUserId;

  return (
    <ListItem>
      <UserNamesContainer userIds={[id]} />
      <Space />
      <Col size={4}>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
          {name}
          {isRequestingUser && (
            <Text size={TEXT_SIZE.SIZE_5} muted>
              <Space size={1} />
              (You)
              <Space size={1} />
            </Text>
          )}
          {!active && (
            <Text muted size={TEXT_SIZE.SIZE_5}>
              <Space size={1} />
              {HTML_ICON.DOT}
              <Space size={1} />
              Deactivated
            </Text>
          )}
        </Text>
        <Text block>{userRoleTitle}</Text>
      </Col>

      <Col size={4}>
        <SimpleLink href={`tel:${phone}`} isExternalLink={false}>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER}>
              <Icon name="phone" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={COLOR_SHADES.DARKER}>{phone}</Text>
          </Row>
        </SimpleLink>
        <Space vertical size={1} />
        <SimpleLink href={`mailto:${email}`} isExternalLink={false}>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER}>
              <Icon name="mail" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={email ? COLOR_SHADES.DARKER : COLOR_SHADES.LIGHT}>{email || '(blank)'}</Text>
          </Row>
        </SimpleLink>
      </Col>
      <Col size={null} rightAlighed>
        {[serviceRole].map((skill) => (
          <Icon key={skill} name={USER_SERVICE_TYPE_DETAILS[skill].icon} accentStyle={ICON_ACCENT_STYLES.FILL} />
        ))}
      </Col>
      <Space />
      <Col rightAlighed size={null}>
        <span className={`${JS_SELECTORS.USER_MORE_OPTIONS}${id}`}>
          <Dropdown>
            <DropdownIcon title="More" tooltipPlacement="left">
              <Icon name="more_vertical" />
            </DropdownIcon>
            <Menu>
              <MenuItem onClick={() => showEditUser(id)}>
                <MenuItemIcon name="edit_2" />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => showActivateUser(id)}
                disabled={isRequestingUser}
                disabledMessage={MESSAGES.USER.ACTIVATE_BLOCK}
              >
                <MenuItemIcon name={active ? 'user_x' : 'user_check'} />
                {active ? 'De-activate' : 'Activate'}
              </MenuItem>
              <MenuItem
                onClick={() => showDeleteUser(id)}
                disabled={isRequestingUser}
                disabledMessage={MESSAGES.USER.DELETE_BLOCK}
              >
                <MenuItemIcon name="trash" />
                Delete permanently
              </MenuItem>
            </Menu>
          </Dropdown>
        </span>
      </Col>
    </ListItem>
  );
}
