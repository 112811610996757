import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  COLOR_SHADES,
  getComponentForTextGroupType,
  Icon,
  ICON_SIZES,
  LoadingDots,
  MiniCardCount,
  MiniCardCounts,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import MESSAGES from '../../../../../../../common/Messages';
import APP_URLS from '../../../../../services/AppRouteURLService';
import ReportStatsItem from './ReportStatsItem';

type Props = {
  eventId: string;
  sponsorStats: {
    views: {
      primary: number;
    };
    clicks: {
      primary: number;
    };
    ctaClicks: {
      primary: number;
    };
    roi: {
      primary: number;
    };
  };
  hasPermissions: {
    [PERMISSIONS.EVENT_SPONSOR_REPORT]: boolean;
  };
  isLoading: boolean;
  type: 'default' | 'compact';
  focusId?: string;
};

export default function SponsorReportCard(props: Props): JSX.Element {
  const { eventId, sponsorStats, hasPermissions, isLoading, type, focusId } = props;

  const urlForSponsorInsight = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__REPORT, {
    eventId,
    focusId: 'sponsor',
  });
  const { roi, clicks, views, ctaClicks } = sponsorStats;
  if (type === 'compact') {
    return (
      <Card>
        <Space>
          <SubTitle>Insights</SubTitle>
          <Space vertical />
          {isLoading ? (
            <div>
              <Space vertical size={5} />
              <LoadingDots size="sm" />
              <Space vertical size={4} />
            </div>
          ) : (
            <MiniCardCounts>
              <ReportStatsItem title="Impression" count={views.primary} type={TEXT_GROUP_TYPES.NUMBER} />
              <ReportStatsItem title="ROI" count={roi.primary} type={TEXT_GROUP_TYPES.CURRENCY} />
            </MiniCardCounts>
          )}
          <Space vertical />
          <Button
            style={BUTTON_STYLES.LINK}
            disabled={!hasPermissions[PERMISSIONS.EVENT_SPONSOR_REPORT]}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_SPONSOR_REPORT]}
            link={urlForSponsorInsight}
          >
            View detailed insights
            <Space size={1} />
            <Icon name="arrow_right" size={ICON_SIZES.SM} />
          </Button>
        </Space>
      </Card>
    );
  }

  return (
    <Card highlight={focusId === 'sponsor'}>
      <Space>
        {isLoading ? (
          <div>
            <Space vertical size={5} />
            <LoadingDots size="sm" />
            <Space vertical size={4} />
          </div>
        ) : (
          <MiniCardCounts>
            <ReportStatsItem title="Impression" count={views.primary} type={TEXT_GROUP_TYPES.NUMBER} />
            <ReportStatsItem title="Clicks" count={clicks.primary} type={TEXT_GROUP_TYPES.NUMBER} />
            <ReportStatsItem title="CTA Clicks" count={ctaClicks.primary} type={TEXT_GROUP_TYPES.NUMBER} />
            <ReportStatsItem title="ROI" count={roi.primary} type={TEXT_GROUP_TYPES.CURRENCY} />
          </MiniCardCounts>
        )}
        <Space vertical />
      </Space>
    </Card>
  );
}
