import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { ActionTypeUtils, TimezoneUtils } from '@premagic/utils';
import { Dispatch } from 'redux';
import { normalize } from 'normalizr';

import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { toastMessage } from '../../reducers/ToastStore';
import { setActiveAccountIdCache, AccountType, saveAccount, ACCOUNT_COMMUNICATION_TEMPLATES } from './AccountService';
import { entitiesDataSelector } from '../../reducers/selectors';
import { AccountsSchema, AccountSchema } from '../../../schema/Schemas';

const getActionType = ActionTypeUtils.getActionTypeFunction('ACCOUNT');

export const setAccountsData = createAction(getActionType('DATA', 'SET'), (dispatch, data: Array<AccountType>) => {
  const normalizedData = normalize(data, AccountsSchema);
  return normalizedData.entities.accounts;
});

export const updateAccountsData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
export const setActiveAccount = createAction(getActionType('ACTIVE', 'SET'), (dispatch, accountId) => {
  setActiveAccountIdCache(accountId);
  return accountId;
});

export const saveAccountData = createAction(getActionType('DATA', 'SAVE'), (dispatch: Dispatch, id, data) => {
  const loadingKey = LOADINGS.ACCOUNTS.UPDATE;
  const { data: formData } = data;

  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  saveAccount(id, formData)
    .then((response) => {
      TimezoneUtils.setCurrentTimezone(response.timezone);
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      const normalizedData = normalize(response, AccountSchema);
      dispatch(updateAccountsData(dispatch, normalizedData.entities.accounts));
      toastMessage('success', 'Account information saved!');
      return response;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setErrorState(dispatch, loadingKey, e));
    });
});

type AccountStateType = {
  items: IMap<string, AccountType>;
  activeAccount?: string;
};

const initialState = {
  items: IMap(),
  activeAccount: undefined,
};

export default handleActions(
  {
    [setAccountsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [updateAccountsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [setActiveAccount.toString()]: (state, action: { payload }) => ({
      ...state,
      activeAccount: action.payload,
    }),
  },
  initialState,
);

const accountsDataSelector = createSelector(entitiesDataSelector, (entities) => entities.account as AccountStateType);

const accountsEntitiesSelector = createSelector(accountsDataSelector, (state) => state.items);

export const accountsArraySelector = createSelector(accountsEntitiesSelector, (accounts) =>
  accounts.valueSeq().toArray(),
);
export const activeAccountIdSelector = createSelector(accountsDataSelector, (state) => state.activeAccount);

export const accountSelector = createSelector(
  accountsEntitiesSelector,
  activeAccountIdSelector,
  (accounts, accountId) => (accountId ? accounts.get(accountId) : undefined),
);
export const currencyCodeSelector = createSelector(accountSelector, (account) => account?.currency || 'INR');

export const accountCountryCodeSelector = createSelector(accountSelector, (account) => account?.country || 'IND');

export const accountTaxNameSelector = createSelector(accountSelector, (account) => account?.tax_name || 'GST');

export const accountTaxPercentageSelector = createSelector(accountSelector, (account) => account?.tax_percentage || 18);

export const accountEmailTemplatesSelector = createSelector(accountSelector, (account) =>
  Object.assign({}, account?.email_templates || {}, {
    [ACCOUNT_COMMUNICATION_TEMPLATES.HIGHLIGHT]: {
      subject: '',
      body: '',
    },
    [ACCOUNT_COMMUNICATION_TEMPLATES.SELECTION]: {
      subject: '',
      body: '',
    },
    [ACCOUNT_COMMUNICATION_TEMPLATES.PROPOSAL]: {
      subject: '{studio} | Proposal for {event_name}',
      body: `<p>
        <strong>Thanks for reaching out, we're so excited to get started on your event!</strong>
       </p>
       <p></p>
        <p>We're looking forward to helping you make your event an unforgettable success. Below is our proposal. If you have any questions, don't hesitate to reach out.</p>`,
    },
    [ACCOUNT_COMMUNICATION_TEMPLATES.CLIENT_WEBSITE]: {
      subject: '',
      body: '',
    },
  }),
);

export const accountInvoiceNoteSelector = createSelector(accountSelector, (account) => account?.default_invoice_note);
