import { FileService } from '@premagic/core';
import { DAM_LIBRARY_FILTER_KEYS, DAMLibraryFiltersType } from './filters/DAMLibraryFilterTypes';

export type DAMLibraryFilterLabelsType = Partial<Record<DAM_LIBRARY_FILTER_KEYS, Array<string>>>;

export function fetchFilesWithFilter(
  filters: DAMLibraryFiltersType,
): Promise<Record<string, FileService.FolderFileType>> {
  const projectIds = filters?.[DAM_LIBRARY_FILTER_KEYS.PROJECT];
  const faceIds = filters?.[DAM_LIBRARY_FILTER_KEYS.FACE_ID];

  if (!projectIds) {
    return Promise.reject(new Error('Project id is missing'));
  }

  return FileService.fetchFilesForDAMLibrary(projectIds, faceIds, filters as Record<string, string>);
}
