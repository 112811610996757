import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { ProjectNerdStatsService } from '@premagic/core';
import { ActionTypeUtils } from '@premagic/utils';
import { entitiesDataSelector, EntitiesStateType } from '../../../reducers/selectors';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_NERD_STATS');

const setProjectNerdStatsData = createAction(getActionType('DATA', 'SET'), (dispatch, projectId, data) => ({
  [projectId]: data,
}));

export const getProjectNerdStatsData = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.PROJECT_NERD_STATS.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));

  ProjectNerdStatsService.fetchProjectNerdStats(projectId)
    .then((response) => {
      dispatch(setProjectNerdStatsData(dispatch, projectId, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

type StateType = {
  items: IMap<string, ProjectNerdStatsService.ProjectNerdStatsType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setProjectNerdStatsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const projectNerdStatsDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.projectsNerdStats as StateType).items,
);

export const projectNerdStatsForProjectSelector = (projectId: string) =>
  createSelector(projectNerdStatsDataSelector, (items) => {
    const item = items.get(projectId);
    if (!item) return undefined;

    return Object.entries(item).reduce((result, [key, metric]) => {
      if (!isEmpty(metric))
        return {
          ...result,
          [key]: metric,
        };
      return result;
    }, {}) as Partial<ProjectNerdStatsService.ProjectNerdStatsType>;
  });
