import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { clientWebsiteItineraryItemsSelector, removeClientWebsiteItinerary } from './ClientWebsiteItineraryDataDuck';
import ClientWebsiteItineraryOptionsMenu from './ClientWebsiteItineraryOptionsMenu';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    websiteId: string;
    itineraryId: number;
  },
) {
  const { projectId, websiteId, itineraryId } = ownProps;

  return {
    websiteId,
    projectId,
    itineraryId,
    isLoading:
      isLoadingSelector(LOADINGS.CLIENT_WEBSITE_ITINERARY.ADD)(state) ||
      isLoadingSelector(LOADINGS.CLIENT_WEBSITE_ITINERARY.REMOVE)(state),
    itineraryItems: clientWebsiteItineraryItemsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeItinerary: (options) => {
      dispatch(removeClientWebsiteItinerary(dispatch, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteItineraryOptionsMenu);
