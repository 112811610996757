import { Card, List, Space, Text } from '@premagic/myne';
import React from 'react';
import { ProductFeedbackService } from '@premagic/core';
import ReportIssueListByGroupTypeContainer from './ReportIssueListByGroupTypeContainer';
import { REPORT_ISSUE_GROUP_DETAILS } from '../service/ReportIssueService';
import ClosedReportIssueListContainer from './ClosedReportIssueListContainer';

type Props = {
  projectId: string;
  reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
};

export default function ReportIssueListLayout(props: Props) {
  const { projectId, reportType } = props;

  return (
    <>
      {Object.values(REPORT_ISSUE_GROUP_DETAILS).map((groupType) => (
        <div key={groupType.id}>
          <ReportIssueListByGroupTypeContainer
            projectId={projectId}
            reportType={reportType}
            name={groupType.name}
            groupType={groupType.id}
          />
          <Space vertical />
        </div>
      ))}
      <ClosedReportIssueListContainer projectId={projectId} reportType={reportType} name="Resolved issues" />
    </>
  );
}
