import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { eventAttendeesSelector } from '../../../../crm/events/event-attendees/EventAttendeesDataDuck';
import DAMLibraryPeopleFilterByNameSelector from './DAMLibraryPeopleFilterByNameSelector';

function mapStateToProps(
  state,
  ownProps: { projectId: string; value: Array<string>; onChange: (newFilterValue: Array<string>) => void },
) {
  const { projectId, value, onChange } = ownProps;
  return {
    value,
    onChange,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state),
    attendees: eventAttendeesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryPeopleFilterByNameSelector);
