import React from 'react';

import { Button, BUTTON_STYLES, Icon, ICON_SIZES, Row, Space, Tooltip } from '@premagic/myne';
import { ArrayUtils } from '@premagic/utils';

import SharePanelContainer from '../../folder-share/SharePanelContainer';
import { FOLDER_STATUS } from '../../../../../common/Constants';

type Props = {
  projectId: string;
  id: string;
  showSharePanel: (id: string) => void;
  status: FOLDER_STATUS;
  shareUrl?: string;
  clients?: Array<{
    name: string;
    phone_number: string;
    email: string;
  }>;
};

export default function ClientWebsiteShareButton(props: Props): React.ReactElement {
  const { id, projectId, showSharePanel, status, shareUrl, clients } = props;
  const previewUrl = shareUrl;
  const clientShareText = clients?.map(
    (clientData) => `${clientData.name} via ${clientData.email} & ${clientData.phone_number}`,
  );

  if (status === FOLDER_STATUS.DONE && shareUrl) {
    return (
      <Row vcenter>
        {!clients && (
          <Button style={BUTTON_STYLES.LINK} onClick={() => showSharePanel(id)}>
            <Icon name="share" size={ICON_SIZES.SM} />
            <Space size={2} />
            Share
          </Button>
        )}
        <Space size={8} />
        <SharePanelContainer projectId={projectId} />
        <Tooltip
          message={clientShareText ? `Shared with ${ArrayUtils.listify(clientShareText)}` : 'Shared with your client'}
          placement="left"
        >
          <Button style={BUTTON_STYLES.TERTIARY} link={shareUrl} isExternalLink>
            Open
            <Space size={2} />
            <Icon name="external_link" size={ICON_SIZES.SM} />
          </Button>
        </Tooltip>
      </Row>
    );
  }

  return (
    <>
      {shareUrl && (
        <Button style={BUTTON_STYLES.LINK} link={previewUrl} isExternalLink>
          Preview
          <Space size={2} />
          <Icon name="external_link" size={ICON_SIZES.SM} />
        </Button>
      )}
      <Space />
      <Button style={BUTTON_STYLES.PRIMARY} onClick={() => showSharePanel(id)}>
        Share <Space size={2} /> <Icon name="send" size={ICON_SIZES.SM} />
      </Button>
      <SharePanelContainer projectId={projectId} />
    </>
  );
}
