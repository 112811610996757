import { connect } from 'react-redux';
import { ActionConstants, ProposalDeckDataDuck, ProposalSlideDataDuck } from '@premagic/proposals';
import { LoadingDuck, WindowPanelDuck, ErrorDuck } from '@premagic/common-ducks';
import DeckVariablesPanel from './DeckVariablesPanel';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL;
const COMMON_KEY = ActionConstants.KEYS.PROPOSAL_DECK.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    deckId: string;
    folderId: string;
  },
) {
  const { projectId, deckId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    deckId,
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state),
    variablesSetOnDeck: ProposalSlideDataDuck.proposalsVariablesInAllSlidesSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveDeck: (projectId, deckId, data) => {
      dispatch(ProposalDeckDataDuck.saveProposalDeck(dispatch, projectId, deckId, data));
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(
          dispatch,
          ActionConstants.KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL,
          false,
        ),
      );
    },
    closePanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DeckVariablesPanel);
