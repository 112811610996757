import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
  DialogTitle,
  COLOR_SHADES,
} from '@premagic/myne';
import MESSAGES from '../../../../../../../common/Messages';
import { JS_SELECTORS, getElementFromSelector } from '../../../../../../../common/JSClassSelectors';

type Props = {
  eventId: string;
  projectId?: string;
  isLoading: boolean;
  isDialogShown: boolean;
  toggleDialog: (show: boolean) => void;
  removeEvent: (options: { projectId?: string; eventId: string }) => void;
};

export default function EventDeleteDialog(props: Props): React.ReactElement {
  const { eventId, projectId, isLoading, removeEvent, toggleDialog, isDialogShown } = props;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.EVENTS_MORE_OPTIONS)}
      show={isDialogShown}
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleDialog(false)}
    >
      <DialogTitle>Delete this event?</DialogTitle>
      <Text block>Deleting this event will</Text>
      <ul>
        <li>
          <Text>Delete all the occasions in the event</Text>
        </li>
        <li>
          <Text>Archive the project/gallery in the event</Text>
        </li>
      </ul>
      <Space vertical size={4} />
      <Text block>Are you sure you want to delete this event?</Text>
      <Space vertical size={6} />
      <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.DANGER} onClick={() => removeEvent({ eventId, projectId })} isLoading={isLoading}>
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
