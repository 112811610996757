import { connect } from 'react-redux';
import { Toolbar } from '@premagic/myne';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.CRM.CALENDAR_OCCASION_FETCH)(state),
  };
}

export default connect(mapStateToProps)(Toolbar);
