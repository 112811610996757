import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';

type Props = {
  eventNumber?: string;
};

export default function EventTagNumber(props: Props): JSX.Element | null {
  const [showCopyToClipboard, setShowCopyToClipboard] = useState(false);
  const [showCopied, setCopiedInfo] = useState(false);
  const { eventNumber } = props;
  if (!eventNumber) return null;

  return (
    <Tooltip
      message={
        showCopied ? (
          <Row vcenter>
            <Color shade={COLOR_SHADES.SUCCESS} inline>
              <Icon name="check" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            Copied!
          </Row>
        ) : (
          <div>Event ID {HTML_ICON.DOT} Click to copy</div>
        )
      }
    >
      <Row vcenter inline>
        <Icon name="hash" size={ICON_SIZES.SM} />
        <Space size={1} />
        <span
          onMouseEnter={() => setShowCopyToClipboard(true)}
          onMouseLeave={() => {
            setShowCopyToClipboard(false);
            setCopiedInfo(false);
          }}
        >
          <Button
            onClick={() => {
              BrowserUtils.copyToClipboard(`#${eventNumber}`);
              setCopiedInfo(true);
              setTimeout(() => {
                setCopiedInfo(false);
              }, 3000);
            }}
            style={BUTTON_STYLES.RESET}
          >
            <Text
              size={TEXT_SIZE.SIZE_4}
              block
              color={showCopyToClipboard ? COLOR_SHADES.BLUE_DARK : COLOR_SHADES.DARKER}
              boldness={showCopyToClipboard ? TEXT_BOLDNESS.BOLD : TEXT_BOLDNESS.NORMAL}
            >
              {eventNumber}
            </Text>
          </Button>
          <Space size={1} />
          {showCopyToClipboard && (
            <Color shade={COLOR_SHADES.BLUE} inline>
              <Icon name="copy" size={ICON_SIZES.XS} />
            </Color>
          )}
        </span>
      </Row>
    </Tooltip>
  );
}
