import { ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { clientWebsiteForProjectSelector } from '../../../../projects/AccountProjectsDataDuck';
import { projectMetaDataForProjectSelector } from '../../../../projects/project-meta/ProjectsMetaDataDuck';
import EventAttendeeFaceCheckinCard from './EventAttendeeFaceCheckinCard';
import { getEventAttendeesCheckinZones } from './EventAttendeesCheckinZonesDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; eventId: string }) {
  const { projectId, eventId } = ownProps;

  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    eventId,
    isSelfCheckinFeatureEnabled:
      projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_FACE_CHECKIN_ENABLED],
    isQRCodeCheckinFeatureEnabled:
      projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED],
    clientWebsiteFolder: clientWebsiteForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEventAttendeesCheckinZones: (projectId: string) => {
      dispatch(getEventAttendeesCheckinZones(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceCheckinCard);
