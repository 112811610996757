import { connect } from 'react-redux';

import { projectDefaultLanguageSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import SwitchLanguageForm from './SwitchLanguageForm';

function mapStateToProps(state, ownProps: { eventId?: string; projectId?: string }) {
  const { eventId, projectId } = ownProps;

  return {
    defaultLangCode: projectDefaultLanguageSelector(projectId)(state),
  };
}

export default connect(mapStateToProps)(SwitchLanguageForm);
