import { AssetsService, QRCodeBackgroundPosterService } from '@premagic/core';
import {
  Card,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Position,
  PremagicBlackLogo,
  PremagicWhiteLogo,
  PreventMarginCollapse,
  Row,
  SimpleImage,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import classNames from 'classnames';
import QRCodeBackgroundPosterContainer from './background-poster/QRCodeBackgroundPosterContainer';
import LogoBasedOnPosterTypeContainer from './displayed-logo/LogoBasedOnPosterTypeContainer';
import DisplayedPosterIllustrationContainer from './poster-illustration/DisplayedPosterIllustrationContainer';
import useQRCode from './custom-hooks/useQrCode';
import useBackgroundPoster from './custom-hooks/useBackgroundPoster';

export default function PocketCardQRCode(props: {
  url: string;
  printClass: string;
  fileNameClass: string;
  backgroundCover: QRCodeBackgroundPosterService.QRCodePoster;
  appWidth: number;
  folderId: string;
  logos?: Array<string | undefined>;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  showPoweredByPremagic?: boolean;
}) {
  const {
    url,
    printClass,
    fileNameClass,
    backgroundCover,
    appWidth,
    folderId,
    logos,
    qrCodeDesigns,
    showPoweredByPremagic,
  } = props;

  const { qrCode, isLoading: isLoadingQr } = useQRCode(url, 150);
  const { qrSvgImage } = qrCode || {};

  const layoutChangeBreakpointWidth = 1360;
  const layoutChangeForSmallerScreen = appWidth <= layoutChangeBreakpointWidth;

  const customLogo1 = logos && logos[0];
  const customLogo2 = logos && logos[1];

  const { customStyle, headerTextColor, footerTextColor, premagicLogo, backTextColor, smallerLogoSpace } =
    useBackgroundPoster('pocket', backgroundCover, qrCodeDesigns);

  return (
    <>
      <Row vcenter center>
        <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARK}>
          Pocket Card (2" x 3")
        </Text>
      </Row>
      <Space vertical size={layoutChangeForSmallerScreen ? 6 : 3} />
      <Row vcenter center columnDirection={layoutChangeForSmallerScreen}>
        <Card center overflowHidden cssStyle={{ borderRadius: 0, border: 0 }}>
          <div
            className={classNames(printClass, fileNameClass)}
            style={{
              width: '324px',
              height: '486px',
              overflow: 'hidden',
              position: 'relative',
              flexShrink: 0,
            }}
          >
            <Position align="cover">
              <QRCodeBackgroundPosterContainer
                posterId={backgroundCover.id}
                posterType="pocket-card-front"
                folderId={folderId}
              />
            </Position>
            {backgroundCover?.customIllustration && (
              <Position align="bottom-left">
                <DisplayedPosterIllustrationContainer folderId={folderId} imageHeight={180} imageWidth={100} />
              </Position>
            )}
            <div
              style={{
                zIndex: 1,
                position: 'relative',
              }}
            >
              <Row columnDirection center>
                <Space vertical size={7} />
                <LogoBasedOnPosterTypeContainer
                  folderId={folderId}
                  posterType="pocket-card"
                  customLogo1={customLogo1}
                  customLogo2={customLogo2}
                  customStyle={customStyle}
                  smallerLogoSpace={smallerLogoSpace}
                />
                <Space vertical size={6} />
                <div
                  style={
                    customStyle
                      ? {
                          width: '193px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          position: 'absolute',
                          top: '24px',
                          right: '18px',
                        }
                      : { display: 'flex', flexDirection: 'column', alignItems: 'center' }
                  }
                >
                  <div
                    style={
                      customStyle ? { width: '143px', textAlign: 'center' } : { width: '166px', textAlign: 'center' }
                    }
                  >
                    <Text
                      primaryStyle
                      color={headerTextColor}
                      boldness={TEXT_BOLDNESS.BOLD}
                      block
                      cssStyle={
                        customStyle
                          ? { fontSize: '28px', lineHeight: '28px' }
                          : { fontSize: '33px', lineHeight: '32px' }
                      }
                    >
                      GET YOUR
                    </Text>
                    <Text
                      primaryStyle
                      color={headerTextColor}
                      boldness={TEXT_BOLDNESS.BOLD}
                      block
                      italic
                      cssStyle={
                        customStyle
                          ? { fontSize: '32px', lineHeight: '35px' }
                          : { fontSize: '36px', lineHeight: '35px' }
                      }
                    >
                      PHOTOS
                    </Text>
                    <Text
                      primaryStyle
                      color={headerTextColor}
                      boldness={TEXT_BOLDNESS.BOLD}
                      block
                      cssStyle={
                        customStyle
                          ? { fontSize: '15px', lineHeight: '18px' }
                          : { fontSize: '18px', lineHeight: '24px' }
                      }
                    >
                      FROM THIS EVENT
                    </Text>
                    <Text
                      primaryStyle
                      color={headerTextColor}
                      boldness={TEXT_BOLDNESS.BOLD}
                      block
                      italic
                      cssStyle={
                        customStyle
                          ? { fontSize: '16px', lineHeight: '18px' }
                          : { fontSize: '20px', lineHeight: '24px', whiteSpace: 'nowrap' }
                      }
                    >
                      ON YOUR PHONE
                    </Text>
                  </div>
                  <div style={customStyle ? { marginBottom: '74px' } : { marginBottom: '16px' }} />
                  <Row center>
                    <div
                      style={
                        customStyle
                          ? {
                              border: '1.5px solid #191a19',
                              borderRadius: '7%',
                              padding: '3px',
                              background: 'white',
                            }
                          : {
                              border: '1.5px solid #191a19',
                              borderRadius: '7%',
                              padding: '7px',
                              background: 'white',
                            }
                      }
                    >
                      {isLoadingQr ? (
                        <LoadingDots size="sm" />
                      ) : (
                        qrSvgImage && <SimpleImage src={qrSvgImage} alt="qr-code" />
                      )}
                    </div>
                  </Row>
                  <div style={customStyle ? { marginBottom: '38px' } : { marginBottom: '27px' }} />
                  <Text
                    block
                    center
                    boldness={TEXT_BOLDNESS.SEMI_BOLD}
                    color={footerTextColor}
                    cssStyle={{ fontSize: '17px' }}
                  >
                    Scan this code & register your face
                  </Text>
                </div>
              </Row>
            </div>
          </div>
        </Card>
        <Space size={16} vertical={layoutChangeForSmallerScreen} />
        <Card center overflowHidden cssStyle={{ borderRadius: 0, border: 0 }}>
          <div
            className={classNames(printClass, fileNameClass)}
            style={{
              width: '324px',
              height: '486px',
              overflow: 'hidden',
              flexShrink: 0,
            }}
          >
            <Position align="cover">
              <QRCodeBackgroundPosterContainer
                posterId={backgroundCover.id}
                posterType="pocket-card-back"
                folderId={folderId}
              />
            </Position>
            <div
              style={{
                zIndex: 1,
                position: 'relative',
              }}
            >
              <PreventMarginCollapse>
                <Row vcenter columnDirection>
                  <Space vertical size={customStyle ? 25 : 35} />
                  {showPoweredByPremagic ? (
                    <>
                      <Text
                        color={premagicLogo === 'white' ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
                        size={TEXT_SIZE.SIZE_4}
                        boldness={TEXT_BOLDNESS.SEMI_BOLD}
                      >
                        Powered by
                      </Text>
                      {(() => {
                        if (premagicLogo === 'white') {
                          return <PremagicWhiteLogo width={155} height={50} />;
                        }

                        if (premagicLogo === 'black') {
                          return <PremagicBlackLogo width={155} height={50} />;
                        }

                        return (
                          <SimpleImage
                            src={AssetsService.getLogoImages(AssetsService.ASSETS_URL.LOGO_ICON_WITH_TEXT_HIGH_QUALITY)}
                            alt="Logo"
                            style={{ width: '170px' }}
                          />
                        );
                      })()}
                      {customStyle && (
                        <>
                          <Space vertical size={16} />
                          <Row vcenter>
                            <Color inline shade={backTextColor}>
                              <Icon name="mail" size={ICON_SIZES.MD_SM} align="bottom" alignSize={2} />
                            </Color>
                            <Space size={2} />
                            <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} size={TEXT_SIZE.SIZE_3} color={backTextColor}>
                              events@premagic.com
                            </Text>
                          </Row>
                          <Space vertical size={10} />
                        </>
                      )}
                    </>
                  ) : (
                    <div style={{ height: '54px' }} />
                  )}
                  <Space vertical size={4} />
                  <div style={{ width: '240px', textAlign: 'center' }}>
                    <Text
                      color={backTextColor}
                      block
                      center
                      cssStyle={{ fontSize: '18px', lineHeight: '24px', fontWeight: '500' }}
                    >
                      We use{' '}
                      <Text
                        color={backTextColor}
                        boldness={TEXT_BOLDNESS.BOLD}
                        cssStyle={{ fontSize: '18px', lineHeight: '20px' }}
                      >
                        AI & Face Recognition
                      </Text>{' '}
                    </Text>
                    <Text
                      color={backTextColor}
                      block
                      cssStyle={{ fontSize: '18px', lineHeight: '20px', fontWeight: '500' }}
                    >
                      to identify your face from
                    </Text>
                    <Text
                      color={backTextColor}
                      block
                      cssStyle={{ fontSize: '18px', lineHeight: '20px', fontWeight: '500' }}
                    >
                      the event photos & deliver
                    </Text>
                    <Text
                      color={backTextColor}
                      block
                      cssStyle={{ fontSize: '18px', lineHeight: '20px', fontWeight: '500' }}
                    >
                      them to you via{' '}
                      <Text
                        color={backTextColor}
                        boldness={TEXT_BOLDNESS.BOLD}
                        cssStyle={{ fontSize: '18px', lineHeight: '20px' }}
                      >
                        WhatsApp.
                      </Text>{' '}
                    </Text>
                  </div>
                </Row>
              </PreventMarginCollapse>
            </div>
          </div>
        </Card>
      </Row>
    </>
  );
}
