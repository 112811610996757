import React from 'react';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';

type Props = {
  totalRemainingClientPayment: number;
  showAddPanel: () => void;
};

export default function RemainingClientPaymentsInfo(props: Props): JSX.Element | null {
  const { totalRemainingClientPayment, showAddPanel } = props;

  if (totalRemainingClientPayment > 0)
    return (
      <div>
        <Space vertical size={1} />
        <Row>
          <Space />
          <Alert style={ALERT_STYLES.WARNING} size={ALERT_SIZES.SM} inline>
            <Space size={2} />
            <Text>
              Add the remaining{' '}
              <Text boldness={TEXT_BOLDNESS.BOLD}>
                <AccountCurrencyContainer amount={totalRemainingClientPayment} />
              </Text>{' '}
              client payment?
            </Text>
            <Space size={6} />
            <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.SM} onClick={showAddPanel}>
              Add
            </Button>
          </Alert>
        </Row>
      </div>
    );

  if (totalRemainingClientPayment < 0) return <Text>(You have add more client payment)</Text>;

  return null;
}
