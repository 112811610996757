import { AttendeeMemoriesService } from '@premagic/core';
import {
  BUTTON_ICON_STYLES,
  BUTTON_ICONS_SIZES,
  Divider,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Menu,
  MENU_ITEM_STYLES,
  MenuItem,
  MenuItemIcon,
} from '@premagic/myne';
import React from 'react';

type Props = {
  eventId: string;
  id: string;
  projectId: string;
  removeTemplate: (projectId: string, posterId: string) => void;
  isDeleting: boolean;
  template: AttendeeMemoriesService.MemoryTemplateType;
  showEditTemplate: (templateId: string) => void;
};

export default function MemoryTemplateItemOptions(props: Props) {
  const { eventId, projectId, removeTemplate, isDeleting, template, showEditTemplate, id } = props;

  if (isDeleting) return <LoadingDots size="sm" />;
  return (
    <Dropdown>
      <DropdownIcon
        title="Poster options"
        tooltipPlacement="left"
        size={BUTTON_ICONS_SIZES.MD}
        style={BUTTON_ICON_STYLES.TERTIARY}
      >
        <Icon name="more_vertical" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
      </DropdownIcon>
      <Menu>
        <MenuItem onClick={() => showEditTemplate(id)}>
          <MenuItemIcon name="edit_2" />
          Edit
        </MenuItem>
        <Divider />
        <MenuItem style={MENU_ITEM_STYLES.DANGER} onClick={() => removeTemplate(projectId, id)}>
          <MenuItemIcon name="trash" />
          Delete
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
