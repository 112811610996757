import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { fetchPricingPlansData, pricingPlanItemsSelector } from '../pricing-plan/PricingPlansDataDuck';
import { fetchInvoicesData, invoiceItemsSelector } from './InvoiceDataDuck';
import InvoicePage from './InvoicePage';

function mapStateToProps(state) {
  return {
    pricingPlans: pricingPlanItemsSelector(state),
    invoices: invoiceItemsSelector(state),
    isLoading: isLoadingSelector(LOADINGS.INVOICE.FETCH_ALL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchInvoicesData(dispatch));
      dispatch(fetchPricingPlansData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
