import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import AddInvoiceNotePanel from './AddInvoiceNotePanel';
import { crmInvoiceNoteSelector, crmQuoteSelector, updateInvoiceNotes } from '../../CRMPaymentDataDuck';
import { hasActiveSubscriptionSelector } from '../../../../settings/subscriptions/SubscriptionsDataDuck';
import { accountInvoiceNoteSelector, accountSelector, saveAccountData } from '../../../../acccount/AccountDataDuck';

const PANEL_KEY = LOADINGS.CRM_PAYMENTS.SHOW_ADD_INVOICE_NOTE_PANEL;
const COMMON_KEY = LOADINGS.CRM_PAYMENTS.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;
  const account = accountSelector(state);

  return {
    eventId,
    quotePayment: crmQuoteSelector(state),
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    isDefaultNoteButtonLoading: isLoadingSelector(LOADINGS.ACCOUNTS.UPDATE)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    hasActiveSubscription: hasActiveSubscriptionSelector(state),
    invoiceNote: crmInvoiceNoteSelector(state),
    defaultInvoiceNote: accountInvoiceNoteSelector(state),
    accountId: account?.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoiceNotes: (paymentId: string, eventId: string, data) => {
      dispatch(updateInvoiceNotes(dispatch, paymentId, eventId, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    saveDefaultNote: (id, data) => {
      dispatch(saveAccountData(dispatch, id, data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddInvoiceNotePanel);
