import React, { useRef, useState } from 'react';
import { SimpleDateUtils } from '@premagic/utils';
import {
  Color,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  Icon,
  ICON_SIZES,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroupValue,
  TimeAgo,
  Col,
  BUTTON_STYLES,
  Button,
} from '@premagic/myne';
import { CRMPaymentCategoryType, CRMPaymentType, PAYMENT_TYPES } from '../CRMPaymentService';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';
import UserNamesContainer from '../../users/list/UserNamesContainer';

type PaymentItemDetailsDialogContentProps = {
  payment: CRMPaymentType;
  category: CRMPaymentCategoryType;
};

export function PaymentItemDetailsDialogContent(props: PaymentItemDetailsDialogContentProps): JSX.Element {
  const { payment, category } = props;
  const {
    is_settled: isSettled,
    human_readable_id: humanId,
    amount,
    notes,
    due_date: dueDate,
    payment_date: paymentDate,
    added_by: addedBy,
    created_at: createdAt,
  } = payment;
  const paymentType = category.payment_type;
  const dueDateString = dueDate && SimpleDateUtils.humanizeDate(dueDate, false, false, true);
  const paymentDateString = paymentDate && SimpleDateUtils.humanizeDate(paymentDate, false, false, true);
  return (
    <div>
      <Row vcenter>
        <Color shade={COLOR_SHADES.DARKER} inline>
          <Icon name="file_text" size={ICON_SIZES.SM} />
        </Color>
        <Space size={1} />
        <Text block size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
          {humanId}
        </Text>
        <Col rightAlighed size={null}>
          {isSettled ? <Text block>{paymentDateString}</Text> : <Text block>Due {dueDateString}</Text>}
        </Col>
      </Row>

      <Space vertical size={2} />
      <Row>
        <Col>
          <Text block size={TEXT_SIZE.SIZE_3}>
            {category.name}{' '}
            {paymentType === PAYMENT_TYPES.OUTFLOW && !isSettled && (
              <Text size={TEXT_SIZE.SIZE_4} muted>
                (Unsettled)
              </Text>
            )}
          </Text>
        </Col>
        <Col rightAlighed size={null}>
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
            <AccountCurrencyContainer amount={amount} />
          </Text>
        </Col>
      </Row>

      {notes && (
        <div>
          <Space vertical />
          <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.SM}>
            <TextGroupValue value={notes} type={TEXT_GROUP_TYPES.RICH_TEXTAREA} />
          </ScrollableContainer>
        </div>
      )}

      <Space vertical />
      <Text muted>
        Added By <UserNamesContainer userIds={[addedBy]} avatarStyle={false} />, <TimeAgo date={createdAt} />
      </Text>
    </div>
  );
}

type Props = {
  children: React.ReactNode;
  payment: CRMPaymentType;
  categories: Record<number, CRMPaymentCategoryType>;
};

export default function PaymentItemDetailsDialog(props: Props): JSX.Element {
  const { payment, categories, children } = props;
  const { payment_category: categoryId } = payment;
  const category = categories[categoryId];
  const [showDialog, setShowDialog] = useState(false);
  const target = useRef(null);
  return (
    <>
      <Button
        ref={target}
        style={BUTTON_STYLES.RESET}
        onClick={() => setShowDialog(!showDialog)}
        onMouseEnter={() => {
          setTimeout(() => {
            setShowDialog(true);
          }, 150);
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setShowDialog(false);
          }, 300);
        }}
      >
        {children}
      </Button>
      <Dialog
        target={target.current}
        show={showDialog}
        onClose={() => setShowDialog(false)}
        placement={DIALOG_PLACEMENT.LEFT}
      >
        <PaymentItemDetailsDialogContent payment={payment} category={category} />
      </Dialog>
    </>
  );
}
