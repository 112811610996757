import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  Tooltip,
} from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';

type Props = {
  pin?: number;
};

export default function ProjectPinNumber(props: Props): JSX.Element | null {
  const [showCopied, setCopiedInfo] = useState(false);
  const { pin } = props;
  if (!pin) return null;

  return (
    <Tooltip
      placement="right"
      message={
        showCopied ? (
          <Row vcenter>
            <Color shade={COLOR_SHADES.SUCCESS} inline>
              <Icon name="check" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            Copied!
          </Row>
        ) : (
          'Click to copy'
        )
      }
    >
      <Button
        onClick={() => {
          BrowserUtils.copyToClipboard(pin);
          setCopiedInfo(true);
          setTimeout(() => {
            setCopiedInfo(false);
          }, 3000);
        }}
        style={BUTTON_STYLES.RESET}
      >
        <Text color={COLOR_SHADES.DARKER}>
          PIN
          <Space size={1} />
        </Text>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          {pin}
        </Text>
      </Button>
    </Tooltip>
  );
}
