import React from 'react';
import { List as IList } from 'immutable';
import {
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Tooltip,
} from '@premagic/myne';
import { EventTrackerService } from '@premagic/core';

type Props = {
  projectId: string;
  isLoading: boolean;
  projectsShownInPortfolio: IList<string>;
  showProjectInPortfolio: (projectId: string) => void;
  hideProjectInPortfolio: (projectId: string) => void;
  portfolioProjectLimit: number;
};

export default function ProjectPortfolioToggle(props: Props) {
  const {
    projectId,
    isLoading,
    projectsShownInPortfolio,
    showProjectInPortfolio,
    hideProjectInPortfolio,
    portfolioProjectLimit,
  } = props;

  const toggleValue = projectsShownInPortfolio?.includes(projectId) || false;

  const totalProjectsShownInPortfolio = projectsShownInPortfolio?.size;
  const isProjectAvailableToShowInPortfolio = totalProjectsShownInPortfolio < portfolioProjectLimit;

  return (
    <Row>
      <div>
        <Row vcenter>
          <Color shade={COLOR_SHADES.LIGHT} inline>
            <Icon name="glob" size={ICON_SIZES.SM} /> <Space size={1} />
          </Color>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Show album in Portfolio</Text>
          <Space size={1} />
        </Row>
        <Space vertical size={1} />
      </div>
      <Col size={2} rightAlighed>
        <Row vcenter columnDirection>
          <Tooltip
            message={
              !isProjectAvailableToShowInPortfolio && !toggleValue
                ? `Limit reached, Only ${portfolioProjectLimit} ${
                    portfolioProjectLimit > 1 ? 'albums' : 'album'
                  } can be shown in portfolio`
                : ''
            }
            placement="bottom"
          >
            <ToggleSwitch
              disabled={!isProjectAvailableToShowInPortfolio && !toggleValue}
              isLoading={isLoading}
              name="mark_as_portfolio"
              checked={toggleValue}
              onChange={(value) => {
                EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.SHOW_IN_PORTFOLIO, {
                  enabled: value,
                });
                if (value) {
                  showProjectInPortfolio(projectId);
                } else {
                  hideProjectInPortfolio(projectId);
                }
              }}
            />
          </Tooltip>
          <Space size={1} vertical />
          {toggleValue ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
              Yes
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
              No
            </Text>
          )}
        </Row>
      </Col>
    </Row>
  );
}
