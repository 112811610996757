import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import EventExhibitorListMenu from './EventExhibitorListMenu';
import { exhibitorListStyleSelector, updateExhibitorListStyle } from './EventExhibitorPageDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  return {
    projectId: ownProps.projectId,
    listStyle: exhibitorListStyleSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_EXHIBITOR.FETCH)(state) || isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateListStyle: (style) => {
      dispatch(updateExhibitorListStyle(dispatch, style));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventExhibitorListMenu);
