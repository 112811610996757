import React from 'react';

import { ClientWebsiteService } from '@premagic/core';
import { Row, Col } from '@premagic/myne';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
};

export default function ClientWebsiteMetricsInfoForm(props: Props): JSX.Element {
  const { clientWebsite } = props;
  return <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.METRICS} clientWebsite={clientWebsite} />;
}
