import lockr from 'lockr';

export function setCache(key: string, value: unknown): void {
  lockr.set(key, value);
}

export function getCache<T>(key: string): T {
  return lockr.get(key);
}

export function setSimpleCache(key: string, value: string): void {
  localStorage.setItem(key, value);
}
export function getSimpleCache(key: string): unknown {
  return localStorage.getItem(key);
}

export function removeCache(key: string): void {
  lockr.rm(key);
}

export function setSessionCache(key: string, value: string): void {
  window.sessionStorage.setItem(key, value);
}

export function getSessionCache(key: string): string | null {
  return window.sessionStorage.getItem(key);
}

export function removeSessionCache(key: string): void {
  window.sessionStorage.removeItem(key);
}
