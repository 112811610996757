import { connect } from 'react-redux';
import StoreOfferItem from './StoreOfferItem';
import { storeOffersItemSelector } from '../StoreOffersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
  },
) {
  const { id } = ownProps;
  const storeOffers = storeOffersItemSelector(state);
  return {
    storeOffer: storeOffers[id],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreOfferItem);
