import { connect } from 'react-redux';
import ExhibitorLogoPicture from './ExhibitorLogoPicture';
import { filesSelector } from '../../../../images/FilesDataDuck';
import { eventExhibitorsSelector } from '../EventExhibitorDataDuck';

function mapStateToProps(state, ownProps: { exhibitorId: string }) {
  const { exhibitorId } = ownProps;

  const exhibitor = exhibitorId ? eventExhibitorsSelector(state)[exhibitorId] : undefined;

  return { fileId: exhibitor?.logo_asset_id, files: filesSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorLogoPicture);
