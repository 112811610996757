import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { SimpleDateUtils, ArrayUtils, ActionTypeUtils } from '@premagic/utils';
import { pagesSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('REPORTS_PROJECTS');

export const setReportProjectsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setReportProjectsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const reportsProjectsDataSelector = createSelector(pagesSelector, (pages: any) =>
  pages.reports.data.projects.items
    .map((item) => ({
      ...item,
      id: item.project_id,
      created_at: SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(item.created_at),
    }))
    .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.created_at, b.created_at, true)),
);

export const reportsProjectsDataValuesSelector = createSelector(reportsProjectsDataSelector, (projects: any) =>
  projects.valueSeq().toJS(),
);

export const reportsProjectsSummarySelector = createSelector(reportsProjectsDataSelector, (projects: any) =>
  projects.reduce(
    (result, project) => {
      const projectStats = project.stats || {};
      return {
        projects: result.projects + 1,
        views: result.views + projectStats.views,
        shares: result.shares + projectStats.shares,
        referrals: result.referrals + projectStats.referrals,
        downloads: result.downloads + projectStats.downloads,
      };
    },
    {
      projects: 0,
      views: 0,
      shares: 0,
      referrals: 0,
      downloads: 0,
    },
  ),
);
