import { connect } from 'react-redux';
import { ClientsService } from '@premagic/core';
import ClientWebsiteForm from './CRMClientForm';
import { clientItemsSelector } from './ClientsDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureLeadEnabledSelector,
} from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id?: string;
    showNotes?: boolean;
    showLeadSourcePicker?: boolean;
    showAddress?: boolean;
    client?: Partial<ClientsService.ClientType>;
  },
) {
  const { id, showNotes, showLeadSourcePicker, showAddress, client } = ownProps;

  return {
    client: id ? clientItemsSelector(state)[id] : client,
    showNotes,
    showAddress,
    showLeadSourcePicker,
    hasLeadsModule: isFeatureLeadEnabledSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

export default connect(mapStateToProps)(ClientWebsiteForm);
