import { AssetsService } from '@premagic/core';
import {
  ALERT_STYLES,
  Alert,
  BUTTON_ICONS_SIZES,
  BUTTON_ICON_STYLES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  ButtonIcon,
  COLOR_SHADES,
  Color,
  FileUploader,
  ICON_SIZES,
  Icon,
  MODAL_SIZES,
  Modal,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';
import { useState } from 'react';
import { toastMessage } from '../../../../../../reducers/ToastStore';

const CSV_UPLOAD_GUIDELINES = {
  GUIDELINES_1:
    "1. You can change a user's selfie by including the 'selfie_url' in the CSV file. Just make sure the 'selfie_url' field is not empty for the attendees you want to update.",
  GUIDELINES_2:
    "2. If you want to add new custom details, like 'company_id,' put them in the CSV file with a 'custom_' prefix. For example, add 'custom_company_id' as a new column.",
  GUIDELINES_3:
    '3. Check that the custom fields are already in the event registration form before you start. If not, the system will ignore any new custom fields you add.',
  GUIDELINES_4: '4. To facilitate a successful update, ensure that the CSV file includes the "registration_id" field.',
};

type UploadAndUpdateGuidelinesInfoProps = {
  type: 'update' | 'upload';
};

function UploadAndUpdateGuidelinesInfo(props: UploadAndUpdateGuidelinesInfoProps) {
  const { type } = props;
  return (
    <Row>
      <Alert style={ALERT_STYLES.INFO}>
        <Text block>{CSV_UPLOAD_GUIDELINES.GUIDELINES_1}</Text>
        <Text block>{CSV_UPLOAD_GUIDELINES.GUIDELINES_2}</Text>
        <Text block>{CSV_UPLOAD_GUIDELINES.GUIDELINES_3}</Text>
        {type === 'update' && <Text block>{CSV_UPLOAD_GUIDELINES.GUIDELINES_4}</Text>}
      </Alert>
    </Row>
  );
}

type BulkUploadNewEventAttendeesProps = {
  close: () => void;
  isLoading: boolean;
  onDownload: () => void;
  onUpload: (e, files) => void;
  handleBack: () => void;
  errors: Record<string, string>;
};

function BulkUploadNewEventAttendees(props: BulkUploadNewEventAttendeesProps) {
  const { close, isLoading, onDownload, onUpload, handleBack, errors } = props;
  return (
    <>
      <Row spaceBetween>
        <Row vcenter>
          <ButtonIcon title="Back to option selection" onClick={handleBack}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
          </ButtonIcon>
          <Space size={2} />
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="upload" size={ICON_SIZES.MD_SM} />
            </Color>
            <Space size={2} />
            <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
              Bulk Upload
            </Text>
          </Row>
        </Row>
        <ButtonIcon onClick={close} title="Close" size={BUTTON_ICONS_SIZES.SM} style={BUTTON_ICON_STYLES.SECONDARY}>
          <Icon name="x" size={ICON_SIZES.MD} />
        </ButtonIcon>
      </Row>
      <Space vertical />
      {errors && (
        <>
          <Alert style={ALERT_STYLES.DANGER}>{errors.message}</Alert>
          <Space vertical />
        </>
      )}
      <UploadAndUpdateGuidelinesInfo type="upload" />
      <Space vertical size={8} />
      <Text muted>Step 1: Download this sample CSV file and add new attendees based on this format.</Text>
      <Space vertical size={2} />
      <Button isLoading={isLoading} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} onClick={onDownload}>
        Download Sample CSV File
      </Button>
      <Space vertical size={6} />
      <Text muted>Step 2: Upload the event attendee list as a CSV file.</Text>
      <Space vertical size={2} />
      <FileUploader
        maxSize={35}
        accept="text/csv , .csv"
        onError={(message) => toastMessage('error', message)}
        onChange={onUpload}
      >
        <Button isLoading={isLoading} style={BUTTON_STYLES.PRIMARY}>
          Upload CSV File
        </Button>
      </FileUploader>
    </>
  );
}

type BulkUpdateEventAttendeesProps = {
  close: () => void;
  isLoading: boolean;
  onDownload: () => void;
  onUpload: (e, files) => void;
  handleBack: () => void;
  errors: Record<string, string>;
};

function BulkUpdateEventAttendees(props: BulkUpdateEventAttendeesProps) {
  const { close, isLoading, onDownload, onUpload, handleBack, errors } = props;

  return (
    <>
      <Row spaceBetween>
        <Row vcenter>
          <ButtonIcon title="Back to option selection" onClick={handleBack}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
          </ButtonIcon>
          <Space size={2} />
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="edit" size={ICON_SIZES.MD_SM} />
            </Color>
            <Space size={2} />
            <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
              Bulk Update
            </Text>
          </Row>
          <Space size={2} />
        </Row>
        <ButtonIcon onClick={close} title="Close" size={BUTTON_ICONS_SIZES.SM} style={BUTTON_ICON_STYLES.SECONDARY}>
          <Icon name="x" size={ICON_SIZES.MD} />
        </ButtonIcon>
      </Row>
      <Space vertical />
      {errors && (
        <>
          <Alert style={ALERT_STYLES.DANGER}>{errors.message}</Alert>
          <Space vertical />
        </>
      )}
      <UploadAndUpdateGuidelinesInfo type="update" />
      <Space vertical size={8} />
      <Text muted>Step 1: Download and update this CSV file.</Text>
      <Space vertical size={2} />
      <Button isLoading={isLoading} style={BUTTON_STYLES.LINK} onClick={onDownload}>
        Download CSV File
      </Button>
      <Space vertical size={6} />
      <Text muted>Step 2: Upload the updated event attendee list CSV file.</Text>
      <Space vertical size={2} />
      <FileUploader
        maxSize={35}
        accept="text/csv , .csv"
        onError={(message) => toastMessage('error', message)}
        onChange={onUpload}
      >
        <Button isLoading={isLoading} style={BUTTON_STYLES.PRIMARY}>
          Upload CSV File
        </Button>
      </FileUploader>
    </>
  );
}

enum MODAL_STATE {
  OPTIONS_MENU = 'OPTIONS_MENU',
  UPLOAD_NEW_CSV = 'UPLOAD_NEW_CSV',
  UPDATE_CSV = 'UPDATE_CSV',
}

type Props = {
  show: boolean;
  close: () => void;
  downloadEventAttendeeData: (projectId: string, projectName: string) => void;
  projectName: string;
  projectId: string;
  isLoading: boolean;
  updateBulkRegistrationEventAttendees: (projectId: string, csvFile: File) => void;
  uploadNewBulkRegistrationEventAttendeesCSV: (projectId: string, csvFile: File) => void;
  errors: Record<string, string>;
  clearErrors: () => void;
};

export default function UploadAndEditBulkAttendeeListModal(props: Props) {
  const {
    projectName,
    projectId,
    isLoading,
    show,
    close,
    downloadEventAttendeeData,
    updateBulkRegistrationEventAttendees,
    uploadNewBulkRegistrationEventAttendeesCSV,
    errors,
    clearErrors,
  } = props;

  const [modalState, setModalState] = useState(MODAL_STATE.OPTIONS_MENU);

  function closeModal() {
    setModalState(MODAL_STATE.OPTIONS_MENU);
    close();
    clearErrors();
  }

  function handleSampleEventAttendeeCsvDownload() {
    const sampleCsvLink = AssetsService.getCSVFiles(AssetsService.ASSETS_URL.CSV_FILES.SAMPLE_EVENT_ATTENDEE_LIST_CSV);
    BrowserUtils.downloadAsFile({ file: sampleCsvLink, fileName: 'sample_people_list.csv' });
  }

  function handleCurrentEventAttendeeCsvDownload() {
    downloadEventAttendeeData(projectId, projectName);
  }

  if (!show) return null;

  return (
    <Modal show={show} close={closeModal} size={MODAL_SIZES.MD}>
      {(() => {
        if (modalState === MODAL_STATE.OPTIONS_MENU) {
          return (
            <>
              <Row spaceBetween>
                <Row vcenter>
                  <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
                    Select an option
                  </Text>
                  <Space size={2} />
                </Row>
                <ButtonIcon
                  onClick={closeModal}
                  title="Close"
                  size={BUTTON_ICONS_SIZES.SM}
                  style={BUTTON_ICON_STYLES.SECONDARY}
                >
                  <Icon name="x" size={ICON_SIZES.MD} />
                </ButtonIcon>
              </Row>
              <Space vertical size={8} />
              <Row center spaceAround>
                <Button onClick={() => setModalState(MODAL_STATE.UPLOAD_NEW_CSV)}>
                  <Icon name="upload" size={ICON_SIZES.SM} />
                  <Space size={2} /> Bulk Upload
                </Button>
                <Button onClick={() => setModalState(MODAL_STATE.UPDATE_CSV)}>
                  <Icon name="edit" size={ICON_SIZES.SM} />
                  <Space size={2} />
                  Bulk Update
                </Button>
              </Row>
            </>
          );
        }
        if (modalState === MODAL_STATE.UPLOAD_NEW_CSV) {
          return (
            <BulkUploadNewEventAttendees
              close={closeModal}
              isLoading={isLoading}
              onDownload={handleSampleEventAttendeeCsvDownload}
              onUpload={(e, files) => {
                if (files?.length) {
                  uploadNewBulkRegistrationEventAttendeesCSV(projectId, files[0]);
                }
              }}
              handleBack={() => setModalState(MODAL_STATE.OPTIONS_MENU)}
              errors={errors}
            />
          );
        }
        if (modalState === MODAL_STATE.UPDATE_CSV) {
          return (
            <BulkUpdateEventAttendees
              close={closeModal}
              isLoading={isLoading}
              onDownload={handleCurrentEventAttendeeCsvDownload}
              onUpload={(e, files) => {
                if (files?.length) {
                  updateBulkRegistrationEventAttendees(projectId, files[0]);
                }
              }}
              handleBack={() => setModalState(MODAL_STATE.OPTIONS_MENU)}
              errors={errors}
            />
          );
        }
        return null;
      })()}
    </Modal>
  );
}
