import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { BrowserUrlUtils, ErrorTracker, ActionTypeUtils, ArrayUtils } from '@premagic/utils';
import { LOADINGS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { WorkflowSchema, WorkflowsSchema, TaskGroupSchema } from '../../../../../schema/Schemas';
import { entitiesDataSelector } from '../../../../reducers/selectors';
import APP_URLS from '../../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../../services/RouterService';
import { addNewTaskToTaskgroup, addTaskgroupData, setTaskgroupData, updateTaskgroupData } from './TaskGroupDataDuck';
import { setTaskData } from './TaskDataDuck';
import {
  createWorkflow,
  deleteWorkflow,
  editWorkflow,
  fetchWorkflowDetails,
  fetchWorkflows,
  WorkflowType,
} from '../service/WorkflowService';
import { changeOrderOfTaskGroup, createTaskGroup } from '../service/TaskGroupService';

const getActionType = ActionTypeUtils.getActionTypeFunction('WORKFLOW');

const setWorkflowData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addWorkflowData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const removeWorkflowData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, workflowId) => workflowId);
export const updateWorkflowData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, workflowId, data) => ({
  workflowId,
  data,
}));

export const addNewTaskgroupToWorkflow = createAction(
  getActionType('DATA', 'ADD_TASKGROUP'),
  (dispatch, workflowId, taskgroupId) => ({
    workflowId,
    taskgroupId,
  }),
);

export const fetchWorkflowList = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.CRM_WORKFLOW.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchWorkflows()
    .then((response) => {
      const normalizedData = normalize(response.results, WorkflowsSchema);
      dispatch(setWorkflowData(dispatch, normalizedData.entities.workflow));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Workflow: List fetch failed', e);
    });
});

export const fetchWorkflowItemData = createAction(
  getActionType('DATA', 'FETCH_SINGLE_DATA'),
  async (dispatch, workflowId: number) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.FETCH_ITEM;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    fetchWorkflowDetails(workflowId)
      .then((response) => {
        const normalizedData = normalize(response, WorkflowSchema);
        dispatch(addWorkflowData(dispatch, normalizedData.entities.workflow));
        dispatch(setTaskgroupData(dispatch, normalizedData.entities.taskgroup));
        dispatch(setTaskData(dispatch, normalizedData.entities.task));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Workflow: Details fetch failed', e);
        return e;
      });
  },
);

export const addTaskgroupToWorkflow = createAction(
  getActionType('DATA', 'CREATE_ADD_TASKGROUP'),
  (dispatch, workflowId: number, data: { name: string }) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.CREATE_TASKGROUP;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    createTaskGroup(workflowId, data)
      .then((response) => {
        const normalizedData = normalize(response, TaskGroupSchema);
        dispatch(addTaskgroupData(dispatch, normalizedData.entities.taskgroup));
        dispatch(addNewTaskgroupToWorkflow(dispatch, workflowId, response.id));
        dispatch(addNewTaskToTaskgroup(dispatch, workflowId, response.id, { name: 'Task 1' }));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Taskgroup: Create new taskgroup failed', e);
      });
  },
);

export const createNewWorkflow = createAction(
  getActionType('DATA', 'CREATE'),
  async (dispatch, data: { name: string }) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.CREATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    try {
      const response = await createWorkflow(data);
      const normalizedData = normalize(response, WorkflowSchema);
      dispatch(addWorkflowData(dispatch, normalizedData.entities.workflow));

      await dispatch(addTaskgroupToWorkflow(dispatch, response.id, { name: 'Task group 1' }));
      await dispatch(addTaskgroupToWorkflow(dispatch, response.id, { name: 'Task group 2' }));
      await dispatch(addTaskgroupToWorkflow(dispatch, response.id, { name: 'Task group 3' }));
      navigateTo(
        dispatch,
        BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_WORKFLOW.EDIT, {
          workflowId: response.id,
        }),
      );
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Workflow: create new workflow failed', e);
      return e;
    }
  },
);

export const deleteWorkflowItem = createAction(getActionType('DATA', 'DELETE'), (dispatch, workflowId: number) => {
  const loadingKey = LOADINGS.CRM_WORKFLOW.DELETE;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  deleteWorkflow(workflowId)
    .then((response) => {
      dispatch(removeWorkflowData(dispatch, workflowId));
      dispatch(setTaskgroupData(dispatch, {}));
      dispatch(setTaskData(dispatch, {}));
      navigateTo(dispatch, BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_WORKFLOW.WORKFLOW, {}));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Workflow: delete workflow failed', e);
    });
});

export const changeTaskgroupOrder = createAction(
  getActionType('DATA', 'CHANGE_TASKGROUP_ORDER'),
  (dispatch, workflowId: number, taskgroupIds: Array<number>, data: { from: number; to: number }) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.CHANGE_TASKGROUP_ORDER;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const newTaskgroupPositions = ArrayUtils.arrayMove(taskgroupIds, data.from, data.to);

    newTaskgroupPositions.forEach((taskgroupId, idx) => {
      dispatch(updateTaskgroupData(dispatch, taskgroupId, { order: idx }));
    });

    changeOrderOfTaskGroup(workflowId, newTaskgroupPositions)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Workflow: change taskgroup order failed', e);
      });
  },
);

export const changeWorkflowName = createAction(
  getActionType('DATA', 'CHANGE_WORKFLOW_NAME'),
  (dispatch, workflowId: number, workflowName: string) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const data = {
      name: workflowName,
    };

    dispatch(updateWorkflowData(dispatch, workflowId, data));
    editWorkflow(workflowId, data)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Workflow: change workflow name failed', e);
      });
  },
);

type WorkflowStateType = {
  items: IMap<string, WorkflowType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setWorkflowData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),

    [addWorkflowData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeWorkflowData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.delete(String(action.payload.workflowId)),
    }),
    [updateWorkflowData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(String(action.payload.workflowId), (workflow: any) => ({
        ...workflow,
        ...action.payload.data,
      })),
    }),
    [addNewTaskgroupToWorkflow.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(String(action.payload.workflowId), (workflow: any) => ({
        ...workflow,
        task_groups: workflow?.task_groups?.concat([action.payload.taskgroupId]),
      })),
    }),
  },
  initialState,
);
export const workflowsEntitiesSelector = createSelector(entitiesDataSelector, (entities) => entities.crm.workflows);

export const workflowEntitiesSelector = createSelector(workflowsEntitiesSelector, (state: any) => state.workflow.items);

export const workflowItemsSelector = createSelector(workflowEntitiesSelector, (items) => items.valueSeq().toArray());

export const workflowDetailsItemSelector = (workflowId: number) =>
  createSelector(workflowEntitiesSelector, (items) => items.get(String(workflowId)) || {});
