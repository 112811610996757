import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import EventStatusSelectorButton from './EventStatusSelectorButton';
import { clientIdForEventSelector, crmEventItemsSelector, updateEventStage } from '../../EventsDataDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { hasPermission } from '../../../users/UsersDataDuck';

const DIALOG_KEY = LOADINGS.EVENT.SHOW_EDIT_STATUS;

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;

  const event = crmEventItemsSelector(state)[eventId] || {};
  const clientId = clientIdForEventSelector(eventId)(state);
  return {
    eventId,
    clientId,
    status: event.status,
    isSaving: isLoadingSelector(LOADINGS.EVENT.UPDATE_STAGE)(state),
    showEdit: isModalOpenSelector(DIALOG_KEY)(state),
    hasEventManagementPermission: hasPermission(PERMISSIONS.EVENT_DELIVERABLE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveStatus: (id, clientId, status) => {
      dispatch(updateEventStage(dispatch, id, clientId, status));
    },
    toggleShowEdit: (show) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventStatusSelectorButton);
