import { UploaderDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import MegaUploaderItems from './MegaUploaderItems';

function mapStateToProps(state) {
  return {
    projectProgress: UploaderDuck.projectsWithProgressSelector(state),
    errors: UploaderDuck.filesUploadErrorSelector(state),
  };
}

export default connect(mapStateToProps)(MegaUploaderItems);
