import { MediaUtils, useImage } from '@premagic/utils';
import React, { useContext } from 'react';
import { Image as CanvasImage, Text as CanvasText, Group } from 'react-konva';
import PosterContext from '../poster/PosterContext';
import { getImageCrop, IMAGE_CLIP_POSITION } from '../services/PosterImageService';
import { PosterFileType } from '../services/PosterService';
import { PosterWidgetImageType } from '../services/PosterWidgetService';

function getSizeScaleAndPosition(options: {
  containerSize: { width: number; height?: number };
  containerPosition: { x: number; y: number; z: number; rotation?: number };
  mediaSize: { width: number; height: number };
  objectFitContain?: boolean;
}): {
  size: { width: number; height: number };
  position: { x: number; y: number; z: number; rotation?: number };
  scale: { scaleX: number; scaleY: number };
} {
  const { containerSize, mediaSize, containerPosition, objectFitContain } = options;

  if (objectFitContain) {
    // scale down the image such that it fits inside the container
    const scaleX = containerSize.width / mediaSize.width;
    const scaleY = (containerSize.height || 0) / mediaSize.height;
    const scale = Math.min(scaleX, scaleY);

    return {
      size: {
        width: mediaSize.width,
        height: mediaSize.height,
      },
      position: {
        x: containerPosition.x + containerSize.width / 2 - (mediaSize.width * scale) / 2,
        y: containerPosition.y + (containerSize.height || 0) / 2 - (mediaSize.height * scale) / 2,
        z: containerPosition.z,
        rotation: containerPosition.rotation,
      },
      scale: {
        scaleX: scale,
        scaleY: scale,
      },
    };
  }

  return {
    size: {
      width: containerSize.width,
      height: containerSize.height || 0,
    },
    position: {
      x: containerPosition.x,
      y: containerPosition.y,
      z: containerPosition.z,
      rotation: containerPosition.rotation,
    },
    scale: {
      scaleX: 1,
      scaleY: 1,
    },
  };
}

type PosterImageType = {
  data: PosterWidgetImageType;
  files: Record<string, PosterFileType>;
};

function getFileUrl(options: {
  file?: PosterFileType;
  width: number;
  options?: Record<string, string | Array<string>>;
}) {
  const PULL_IMAGE_FROM_CDN = !window.location.href.includes('MAGAZINE');
  const { file, width } = options;
  if (!file) return '';
  if (PULL_IMAGE_FROM_CDN) {
    return MediaUtils.getNewDynamicImageURL(
      file.dynamic_image_url,
      MediaUtils.IMAGE_QUALITY_TYPES.FULL_VIEW_HIGHLIGHT,
      {
        width,
      },
    );
  }
  return (
    MediaUtils.getNewDynamicImageURL(file.dynamic_image_url, MediaUtils.IMAGE_QUALITY_TYPES.RESOLUTION_FULL, {
      width: width * (Number(options.options?.imageX) || 1),
    }) || file.location
  );
}

const PosterImage = React.forwardRef((props: PosterImageType, ref: React.Ref<any>) => {
  const { data, files, ...rest } = props;
  const { position: containerPosition, size: containerSize, value, id, corner_radius: radius, name } = data;
  const file = value ? files[value] : undefined;

  const { size, position, scale } = getSizeScaleAndPosition({
    containerSize,
    containerPosition,
    mediaSize: { width: file?.meta?.width || 0, height: file?.meta?.height || 0 },
    // have to add a better way to add objectFitContain by adding this toggle on the account app for each image
    // temporarily using the name like this
    objectFitContain: name === 'EXHIBITOR_LOGO',
  });
  const { width, height } = size;
  const { x, y, z, rotation } = position;
  const { scaleX, scaleY } = scale;

  const { options } = useContext(PosterContext);
  const imageUrl = getFileUrl({ file, width: size.width, options });
  const {
    image: imageObj,
    isLoading,
    hasError,
  } = useImage(imageUrl, {
    getImageObject: true,
    fromCanvas: true,
  });
  const textProps = {
    id: 'image-error-text',
    x: position?.x,
    y: position?.y,
    fontSize: (size?.height || 0) * 0.05,
    fill: '#888',
    offset: {
      // x: -size.width / 2,
      // y: -(size.height || 100) / 2,
      x: 0,
      y: 0,
    },
  };

  const cropDetails = file
    ? getImageCrop(
        {
          width: file?.meta?.width || 100,
          height: file?.meta?.height || 100,
        },
        {
          width: Number(size.width),
          height: Number(size.height) || 100,
        },
        IMAGE_CLIP_POSITION.CENTER_MIDDLE,
      )
    : undefined;

  return (
    <Group>
      <CanvasImage
        ref={ref}
        id={id}
        image={imageObj}
        width={Number(width)}
        height={Number(height)}
        x={x}
        y={y}
        scaleX={scaleX}
        scaleY={scaleY}
        rotation={rotation}
        cornerRadius={radius}
        // crop={size.width <= 1000 ? cropDetails : undefined}
        // crop={cropDetails}
        {...rest}
      />
      {isLoading && <CanvasText {...textProps} text="Loading..." />}
      {!imageUrl && <CanvasText {...textProps} fill="#F00" text="Image is not set" />}
      {hasError && <CanvasText {...textProps} fill="#F00" text="Can't load image" />}
    </Group>
  );
});

export default PosterImage;
