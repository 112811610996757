import { connect } from 'react-redux';
import { posterAdvocatesSelector } from '../PosterAdvocatesDataDuck';
import PosterAdvocateListItem from './PosterAdvocateListItem';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
  },
) {
  const { id } = ownProps;
  const advocate = posterAdvocatesSelector(state)[id];
  return {
    id,
    advocate,
  };
}

export default connect(mapStateToProps)(PosterAdvocateListItem);
