import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { ProductFeedbackService } from '@premagic/core';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ArrayUtils, ErrorTracker } from '@premagic/utils';
import { toggleLoadingState } from '../../../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../../../common/ErrorDuck';
import { pagesSelector } from '../../../../../reducers/selectors';
import { LOADINGS } from '../../../../../../../common/Constants';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_FEEDBACK');

const setEventFeedbackData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const getEventFeedbackForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string, type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE) => {
    const loadingKey = LOADINGS.PRODUCT_FEEDBACK.EVENT_FEEDBACK.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProductFeedbackService.getProductFeedbackList(projectId, type)
      .then((response) => {
        dispatch(setEventFeedbackData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_FEEDBACK_FETCH_ERROR', e);
      });
  },
);

type EventFeedbackItemType = IMap<string, ProductFeedbackService.ProductFeedbackType>;
type StateType = {
  items: EventFeedbackItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setEventFeedbackData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const eventFeedbackDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.productReview.data.eventFeedback as StateType,
);

const eventFeedbackEntitiesSelector = createSelector(eventFeedbackDataSelector, (data) => data.items);

export const eventFeedbackByIdSelector = (eventFeedbackId) =>
  createSelector(eventFeedbackEntitiesSelector, (items) => items.get(eventFeedbackId));

export const eventFeedbackIdsByProjectIdSelector = (
  projectId: string,
  type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE,
) =>
  createSelector(
    eventFeedbackEntitiesSelector,
    (items) =>
      items
        .filter((item) => item?.project_id === projectId && item?.feedback_type === type)
        .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
        .keySeq()
        .toArray() || [],
  );
