import { connect } from 'react-redux';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteMetricsInfoForm from './ClientWebsiteMetricsInfoForm';

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
  };
}

export default connect(mapStateToProps)(ClientWebsiteMetricsInfoForm);
