import { EventAttendeesService, EventCustomFromFieldsService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  Form,
  FormGroup,
  FormLabel,
  FormResponseType,
  Input,
  INPUT_SIZES,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { useRef, useState } from 'react';

type Props = {
  projectId: string;
  posterId: string;
  attendeeIds: Array<string>;
  isLoading: boolean;
  bulkUpdateAttendee: (
    projectId: string,
    data: Record<string, Partial<EventAttendeesService.EventAttendeeType>>,
  ) => void;
};

export default function UpdateEventAttendeeBadgesIds(props: Props) {
  const { projectId, posterId, attendeeIds, isLoading, bulkUpdateAttendee } = props;
  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef<HTMLButtonElement>(null);

  function handleSubmit(form: FormResponseType) {
    const data = attendeeIds.reduce((acc, attendeeId, index) => {
      const id = `${form.data.prefix || ''}${index + 1}`;
      acc[attendeeId] = {
        custom_fields: {
          [EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID]: id,
        },
      };
      return acc;
    }, {});
    bulkUpdateAttendee(projectId, data);
    setShowDialog(false);
  }

  return (
    <div>
      <Button
        ref={$button}
        style={BUTTON_STYLES.PRIMARY}
        onClick={() => setShowDialog(!showDialog)}
        isLoading={isLoading}
      >
        Update Badge ids
      </Button>
      <Dialog show={showDialog} target={$button?.current} onClose={() => setShowDialog(false)}>
        <Text color={COLOR_SHADES.DARK} boldness={TEXT_BOLDNESS.BOLD}>
          Update Badge Ids?
        </Text>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Please enter the prefix for the badge:</FormLabel>
            <Input size={INPUT_SIZES.SM} name="prefix" placeholder="eg. G-" autoFocus />
          </FormGroup>
          <Button style={BUTTON_STYLES.PRIMARY}>Update all</Button>
        </Form>
      </Dialog>
    </div>
  );
}
