import React from 'react';
import { Col, Row, Space, SubTitle } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
};

export default function ClientWebsiteThemeColorsEdit(props: Props): JSX.Element {
  const { clientWebsite } = props;

  return (
    <Row>
      <Col>
        <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_PRIMARY} clientWebsite={clientWebsite} />
        <ClientWebsiteFormItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_BACKGROUND}
          clientWebsite={clientWebsite}
        />
      </Col>
      <Space />
      <Col>
        <ClientWebsiteFormItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_SECONDARY}
          clientWebsite={clientWebsite}
        />
        <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_TERTIARY} clientWebsite={clientWebsite} />
      </Col>
    </Row>
  );
}
