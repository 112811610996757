import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { LOADINGS } from '../../../../common/Constants';
import { projectWatermarkForProjectSelector, saveProjectMetaData } from '../projects/project-meta/ProjectsMetaDataDuck';
import EditWatermarkPanel from './EditWatermarkPanel';

const PANEL_KEY = LOADINGS.PROJECT_META.SHOW_EDIT_WATERMARK;
const LOADING_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    id: ModalDuck.modalOptionsSelector(PANEL_KEY)(state) as string,
    isLoading: LoadingDuck.isLoadingSelector(LOADING_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADING_KEY)(state),
    showPanel: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    watermarks: projectWatermarkForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateWatermark: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, { type: 'watermark' }));
    },
    closePanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
      dispatch(ModalDuck.setModalOptions(dispatch, PANEL_KEY, null));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWatermarkPanel);
