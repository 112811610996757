import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import SponsorReportSummaryCard from './SponsorReportSummaryCard';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { hasPermission } from '../../../users/UsersDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { eventSponsorReportSelector } from '../../event-report/EventReportDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
    type: 'default' | 'compact';
    focusId?: string;
  },
) {
  const { projectId, eventId, type, focusId } = ownProps;

  return {
    eventId,
    focusId,
    type,
    sponsorStats: eventSponsorReportSelector(projectId)(state),
    hasPermissions: {
      [PERMISSIONS.EVENT_SPONSOR_REPORT]: hasPermission(PERMISSIONS.EVENT_SPONSOR_REPORT)(state),
    },
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_STATS.SPONSOR_FETCH)(state),
  };
}

export default connect(mapStateToProps)(SponsorReportSummaryCard);
