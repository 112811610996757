import React, { useEffect } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  Illustration,
  ILLUSTRATION_SIZES,
  InnerPage,
  InnerPageContent,
  Link,
  Row,
  Section,
  SimpleImage,
  Space,
  SubTitle,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils, StringUtils } from '@premagic/utils';
import { AssetsService, CompanyService, EventTrackerService, PremagicResources } from '@premagic/core';

import PendingPhotographerActionsSummaryContainer from '../photographer-actions/PendingPhotographerActionsSummaryContainer';
import HaveAQuestion from '../help/HaveAQuestion';
import APP_URLS from '../../services/AppRouteURLService';

import CRMTourWelcomeModal from '../../tour/CRMTourWelcomeModal';

type IntroCardPhotographerProps = {
  company: CompanyService.CompanyType;
  isMobileUp: boolean;
  toggleTour: (status: boolean) => void;
};
const STEPS_PHOTOGRAPHER = [
  {
    title: 'Setup your company Profile',
    actionLink: BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.PROFILE.DETAILS, {}),
    actionName: 'Setup',
  },
  {
    title: 'Manage all your work',
    actionLink: BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.CREATE__CLIENT, {}),
    actionName: 'Add Event',
  },
  {
    title: 'Share Signature album with your client using Premagic desktop app',
    actionLink: PremagicResources.RESOURCES.download('account-app'),
    actionName: 'Download',
    isExternalLink: true,
  },
  {
    title: 'Book a demo with real human',
    actionLink: PremagicResources.RESOURCES.bookDemo('account-app'),
    actionName: 'Book now',
    isExternalLink: true,
  },
];

export function IntroCardPhotographer(props: IntroCardPhotographerProps): JSX.Element {
  const { company, isMobileUp, toggleTour } = props;
  const { name } = company;

  if (isMobileUp)
    return (
      <Card overflowHidden>
        <Space>
          <Row vcenter>
            <Col size={7}>
              <Row columnDirection>
                <Space vertical size={4} />
                <Text primaryStyle size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER}>
                  Welcome, {StringUtils.toTitleCase(name)}{' '}
                  <span role="img" aria-label="wave">
                    👋
                  </span>
                </Text>
                <Space vertical />
                <Text size={TEXT_SIZE.SIZE_2}>Thank you for choosing Premagic.</Text>
              </Row>
              <Space vertical size={10} />
              <SubTitle>
                Getting started{' '}
                <span role="img" aria-label="wave">
                  🚀
                </span>
              </SubTitle>
              <ul>
                {STEPS_PHOTOGRAPHER.map(({ title, actionName, actionLink, isExternalLink }) => (
                  <li key={title}>
                    <Text color={COLOR_SHADES.DARKER}>{title}</Text> <Space size={1} />
                    <Button
                      style={BUTTON_STYLES.LINK}
                      link={actionLink}
                      size={BUTTON_SIZE.SM}
                      isExternalLink={isExternalLink}
                    >
                      {actionName}
                    </Button>
                    <Space vertical size={2} />
                  </li>
                ))}
                <li>
                  <Button onClick={() => toggleTour(true)}>Show tutorial</Button>
                </li>
              </ul>
            </Col>
            <Col size={null} rightAlighed>
              <Illustration name="stay_clam_photographer" size={ILLUSTRATION_SIZES.LG} />
            </Col>
            <Space />
          </Row>
        </Space>
        <Section>
          <HaveAQuestion />
        </Section>
      </Card>
    );
  return (
    <Card overflowHidden>
      <Space>
        <Text primaryStyle size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER} center>
          Welcome, {StringUtils.toTitleCase(name)}{' '}
          <span role="img" aria-label="wave">
            👋
          </span>
        </Text>
        <Space vertical size={2} />
        <Text size={TEXT_SIZE.SIZE_4}>Thank you for choosing Premagic.</Text>
        <Space vertical size={4} />
        <Row center>
          <Illustration name="stay_clam_photographer" size={ILLUSTRATION_SIZES.MD} />
        </Row>
        <Space vertical size={10} />
        <SubTitle>
          Getting started{' '}
          <span role="img" aria-label="wave">
            🚀
          </span>
        </SubTitle>
        <ul>
          <li>
            <Text color={COLOR_SHADES.DARKER}>
              Premagic is build for larger screen(desktop/laptop), open Premagic.com in your desktop to experience the
              full potential of Premagic
            </Text>
          </li>
          <Space vertical />
          <li>
            <Button onClick={() => toggleTour(true)}>Show tutorial</Button>
          </li>
          <Space vertical />
          <li>
            <Text color={COLOR_SHADES.DARKER}>Book a demo with real human</Text> <Space size={1} />
            <Button
              onClick={() => {
                EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.DEMO.SCHEDULE);
              }}
              style={BUTTON_STYLES.LINK}
              link={PremagicResources.RESOURCES.bookDemo('account-app')}
              size={BUTTON_SIZE.SM}
              isExternalLink
            >
              Book now
            </Button>
          </li>
        </ul>

        <Space vertical size={2} />
      </Space>
      <Section>
        <HaveAQuestion />
      </Section>
    </Card>
  );
}

type IntroCardEventOrganizerProps = {
  company: CompanyService.CompanyType;
  isMobileUp: boolean;
  toggleTour: (status: boolean) => void;
};
const STEPS_EVENT = [
  {
    title: 'Setup your company Profile',
    actionLink: BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.PROFILE.DETAILS, {}),
    actionName: 'Setup',
  },
  {
    title: 'Create an event and enable AI photo distribution',
    actionLink: BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.CREATE__CLIENT, {}),
    actionName: 'Create',
    isExternalLink: true,
  },
  {
    title: 'Upload photo using Premagic desktop app',
    actionLink: PremagicResources.RESOURCES.download('account-app'),
    actionName: 'Download',
    isExternalLink: true,
  },
  {
    title: 'Book a demo with real human',
    actionLink: PremagicResources.RESOURCES.bookDemo('account-app'),
    actionName: 'Book now',
    isExternalLink: true,
  },
];

export function IntroCardEventOrganizer(props: IntroCardEventOrganizerProps): JSX.Element {
  const { company, isMobileUp, toggleTour } = props;
  const { name } = company;

  if (isMobileUp)
    return (
      <Card overflowHidden>
        <Row vcenter>
          <Col size={7}>
            <Space>
              <Row columnDirection>
                <Space vertical size={4} />
                <Text primaryStyle size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER}>
                  Welcome, {StringUtils.toTitleCase(name)}{' '}
                  <span role="img" aria-label="wave">
                    👋
                  </span>
                </Text>
                <Space vertical />
                <Text size={TEXT_SIZE.SIZE_2}>Let the magic begin</Text>
              </Row>
              <Space vertical size={10} />
              <SubTitle>
                Getting started{' '}
                <span role="img" aria-label="wave">
                  🚀
                </span>
              </SubTitle>
              <Space vertical size={1} />
              <ul>
                {STEPS_EVENT.map(({ title, actionName, actionLink, isExternalLink }) => (
                  <li key={title}>
                    <Text color={COLOR_SHADES.DARKER}>{title}</Text> <Space size={1} />
                    <Button
                      style={BUTTON_STYLES.LINK}
                      link={actionLink}
                      size={BUTTON_SIZE.SM}
                      isExternalLink={isExternalLink}
                    >
                      {actionName}
                    </Button>
                    <Space vertical size={2} />
                  </li>
                ))}
              </ul>
            </Space>
          </Col>
          <Col size={null} rightAlighed>
            <SimpleImage
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
              src={AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.ORGANIZER_LOOKING)}
            />
          </Col>
          <Space />
        </Row>
        <Section>
          <HaveAQuestion />
        </Section>
      </Card>
    );

  return (
    <Card overflowHidden>
      <Space>
        <Text primaryStyle size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER} center>
          Welcome, {StringUtils.toTitleCase(name)}{' '}
          <span role="img" aria-label="wave">
            👋
          </span>
        </Text>
        <Space vertical size={4} />
        <Row center>
          <SimpleImage
            style={{
              width: '100%',
              maxWidth: '164px',
            }}
            src={AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.ORGANIZER_LOOKING)}
          />
        </Row>
        <Space vertical size={10} />
        <SubTitle>
          Getting started{' '}
          <span role="img" aria-label="wave">
            🚀
          </span>
        </SubTitle>
        <Space vertical size={1} />
        <ul>
          <li>
            <Text color={COLOR_SHADES.DARKER}>
              Premagic is build for larger screen(desktop/laptop), open Premagic.com in your desktop to experience the
              full potential of Premagic
            </Text>
          </li>
          <Space vertical />
          <li>
            <Text color={COLOR_SHADES.DARKER}>Book a demo with real human</Text> <Space size={1} />
            <Button
              onClick={() => {
                EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.DEMO.SCHEDULE);
              }}
              style={BUTTON_STYLES.LINK}
              link={PremagicResources.RESOURCES.bookDemo('account-app')}
              size={BUTTON_SIZE.SM}
              isExternalLink
            >
              Book now
            </Button>
          </li>
        </ul>

        <Space vertical size={2} />
      </Space>
      <Section>
        <HaveAQuestion />
      </Section>
    </Card>
  );
}

type Props = {
  company: CompanyService.CompanyType;
  showIntro: boolean;
  isMobileUp: boolean;
  toggleTour: (status: boolean) => void;
  toggleTourWelcomeModal: (status: boolean) => void;
  isSubscriptionPlan: boolean;
  isTourWelcomeModalOpen: boolean;
  isCompanyTypeNotPhotographer: boolean;
};

export default function DashboardPage(props: Props): JSX.Element {
  const {
    company,
    showIntro,
    isMobileUp,
    toggleTour,
    isSubscriptionPlan,
    isTourWelcomeModalOpen,
    toggleTourWelcomeModal,
    isCompanyTypeNotPhotographer,
  } = props;
  const { usage, name } = company;
  const { availableProjects = 0, totalProjects = 0 } = usage || {
    availableProjects: 0,
    totalProjects: 0,
  };
  const remainingProjects = availableProjects - totalProjects;

  useEffect(() => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.HOME.VISIT, { showIntro });
  }, [showIntro]);

  if (isCompanyTypeNotPhotographer) {
    return (
      <InnerPage>
        <InnerPageContent>
          {showIntro ? (
            <IntroCardEventOrganizer toggleTour={toggleTour} company={company} isMobileUp={isMobileUp} />
          ) : (
            <Card>
              <Row>
                <Space>
                  <Text primaryStyle size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER}>
                    Hi, {StringUtils.toTitleCase(name)}{' '}
                    <span role="img" aria-label="wave">
                      👋
                    </span>
                  </Text>
                </Space>
                <Col size={null} rightAlighed>
                  <SimpleImage
                    style={{
                      width: '100%',
                      maxWidth: '300px',
                    }}
                    src={AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.ORGANIZER_LOOKING)}
                  />
                </Col>
              </Row>
            </Card>
          )}
        </InnerPageContent>
      </InnerPage>
    );
  }

  return (
    <InnerPage>
      <InnerPageContent>
        <CRMTourWelcomeModal
          show={isTourWelcomeModalOpen}
          close={() => toggleTourWelcomeModal(false)}
          toggleTour={toggleTour}
        />
        {showIntro ? (
          <IntroCardPhotographer toggleTour={toggleTour} company={company} isMobileUp={isMobileUp} />
        ) : (
          <Card>
            <Space>
              <div>
                <Text primaryStyle size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER}>
                  Hi, {StringUtils.toTitleCase(name)}{' '}
                  <span role="img" aria-label="wave">
                    👋
                  </span>
                </Text>
              </div>
              <Space vertical size={10} />
              <PendingPhotographerActionsSummaryContainer />
            </Space>
          </Card>
        )}
      </InnerPageContent>
    </InnerPage>
  );
}
