import { connect } from 'react-redux';

import ClientWebsitePhotoItem from './ClientWebsitePhotoItem';
import { deletePhotos } from '../ClientWebsiteDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

function mapStateToProps(state, ownProps) {
  const { name } = ownProps;

  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.PHOTO_UPDATE(name))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deletePhotos: (id: string, projectId: string, data) => {
      dispatch(deletePhotos(dispatch, id, projectId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsitePhotoItem);
