import { FolderService, PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';
import { foldersMetaSelector } from '../../folder-meta/FoldersMetaDataDuck';
import { foldersEntitiesSelector } from '../../folders/AccountFoldersDataDuck';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';
import HighlightGalleryDesignLayout from './HighlightGalleryDesignLayout';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  const folders = foldersEntitiesSelector(state);
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.HIGHLIGHT,
  )(state);

  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    hasHighlightCustomizationFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
    galleryGridSpace: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE],
    galleryThumbnailSize: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE],
    galleryEndingText: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT],
    galleryWelcomeText: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT],
    folderLayout: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW],
    isFolderThumbnailViewEnabled: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED],
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    folders: signatureFolderIds.map((folderId) => folders[folderId]),
    folderMeta: foldersMetaSelector(state),
    isEventSponsorshipFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightGalleryDesignLayout);
