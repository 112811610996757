import React, { useEffect } from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  Color,
  Divider,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  LoadingDots,
  Position,
  Row,
  Space,
} from '@premagic/myne';
import { BrowserUrlUtils, BrowserUtils } from '@premagic/utils';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import ClientPaymentsInvoiceContentContainer from './ClientPaymentsInvoiceContentContainer';
import ClientPaymentsInvoicePageHeaderContainer from './ClientPaymentsInvoicePageHeaderContainer';
import APP_URLS from '../../../../../services/AppRouteURLService';
import ClientPaymentsInvoiceOccasionsContainer from './ClientPaymentsInvoiceOccasionsContainer';
import CompanyLogoContainer from '../../../../settings/company/CompanyLogoContainer';
import AddInvoiceNotePanelContainer from './AddInvoiceNotePanelContainer';

type Props = {
  eventId: string;
  initData: (eventId: string) => void;
  isLoading: boolean;
  hasPermissions: Partial<Record<PERMISSIONS, boolean>>;
  isWatermarkOnClientInvoiceShown: boolean;
};

export default function ClientPaymentsInvoicePage(props: Props): React.ReactElement {
  const { eventId, initData, isLoading, hasPermissions, isWatermarkOnClientInvoiceShown } = props;

  useEffect(() => {
    initData(eventId);
  }, []);

  if (isLoading) {
    return (
      <Row fullHeight center>
        <LoadingDots />
      </Row>
    );
  }

  if (!hasPermissions[PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS])
    return <div>You don't have permission to view this page</div>;

  const eventDetailsFinancialPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__FINANCIAL, {
    eventId,
  });

  return (
    <InnerPage>
      <div className="hidden-print">
        <Space size={2}>
          <Row>
            <Button link={eventDetailsFinancialPage} style={BUTTON_STYLES.LINK}>
              <Icon name="arrow_left" size={ICON_SIZES.SM} /> Back to event
            </Button>
            <Col size={null} rightAlighed>
              <Button style={BUTTON_STYLES.PRIMARY} onClick={BrowserUtils.print}>
                Print
                <Space size={1} />
                <Icon name="printer" size={ICON_SIZES.SM} />
              </Button>
            </Col>
          </Row>
        </Space>
        <Divider />
        <Space vertical />
      </div>
      <InnerPageContent>
        <Card>
          <Space>
            <ClientPaymentsInvoicePageHeaderContainer eventId={eventId} />
            <Space vertical size={8} />
            <ClientPaymentsInvoiceOccasionsContainer eventId={eventId} />
            <Space vertical size={8} />
            <ClientPaymentsInvoiceContentContainer />
          </Space>
        </Card>
        <AddInvoiceNotePanelContainer eventId={eventId} />
      </InnerPageContent>
      {isWatermarkOnClientInvoiceShown && (
        <Color disabledLighter inline>
          <Position align="center">
            <CompanyLogoContainer size="xlg" />
          </Position>
        </Color>
      )}
    </InnerPage>
  );
}
