import { connect } from 'react-redux';
import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';
import SocialShareMessages from './SocialShareMessages';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../project-meta/ProjectsMetaDataDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    scope: ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE;
  },
) {
  const { projectId, scope } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    socialShareMessages: projectMeta?.social_share_messages,
    scope,
    hasCustomMessageFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CUSTOM_SOCIAL_SHARE_MESSAGES,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
    showAddSocialMessageModal: (scope) =>
      dispatch(
        dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_ADD_SOCIAL_MESSAGE_MODAL(scope), true)),
      ),
    showEditSocialMessageModal: (messageId) =>
      dispatch(
        dispatch(
          toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_EDIT_SOCIAL_MESSAGE_MODAL(messageId), true),
        ),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialShareMessages);
