import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { FolderService, PixelService, ProjectNerdStatsService } from '@premagic/core';
import { ActionTypeUtils } from '@premagic/utils';
import { pagesSelector, PagesStateType } from '../../../../reducers/selectors';
import { posterAdvocatesEntitiesSelector } from '../../../poster-advocates/PosterAdvocatesDataDuck';
import { folderFilesStatsWithFolderTypeForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import {
  EVENT_REPORT_WIDGETS,
  getPixelMetricKeyForWidget,
  getROIForAdvocates,
  getROIForEvent,
  getROIForSponsor,
} from './EventReportService';
import {
  getProjectSponsorsStatsData,
  statsOnProjectSelector,
} from '../../../projects/project-stats/ProjectsStatsDataDuck';
import {
  getProjectNerdStatsData,
  projectNerdStatsForProjectSelector,
} from '../../../projects/project-stats-nerd/ProjectNerdStatsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_REPORT', true);

export const getEventReportDataForDashboard = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    dispatch(getProjectSponsorsStatsData(dispatch, projectId));
    dispatch(getProjectNerdStatsData(dispatch, projectId));
  },
);

type StateType = {
  user?: {
    name: string;
    title?: string | null;
  };
};
const initialState: StateType = {
  user: undefined,
};

export default handleActions({}, initialState);

const eventReportData = createSelector(
  pagesSelector,
  (pages: PagesStateType) => pages.eventReport.eventReport as StateType,
);

export const eventPosterAdvocateReportSelector = (projectId: string) =>
  createSelector(posterAdvocatesEntitiesSelector, (advocates) => {
    const totalAdvocates = advocates.size;
    const totalReferrals = advocates.reduce((acc, advocate) => acc + (Number(advocate.advocacy_count) || 0), 0);
    return {
      advocates: {
        primary: totalAdvocates,
      },
      referrals: {
        primary: totalReferrals,
      },
      roi: {
        primary: getROIForAdvocates({
          advocates: totalAdvocates,
          referrals: totalReferrals,
        }),
      },
    };
  });

export const eventReportForDashboardItemsSelector = (projectId: string) =>
  createSelector(
    statsOnProjectSelector(projectId),
    folderFilesStatsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.HIGHLIGHT),
    projectNerdStatsForProjectSelector(projectId),
    eventPosterAdvocateReportSelector(projectId),
    (pixelMetric, signatureFiles, projectStats, advocateMetrics) => {
      const downloads = pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.DOWNLOADS)];
      const bulkDownloads = pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.BULK_DOWNLOADS)];
      const registrationData = projectStats?.[ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE.EVENT_REGISTRATION];
      const usersPhotoDelivered = registrationData?.users_photo_delivered || 0;
      const registeredUser = registrationData?.event_registration_count || 0; // I think the same info is in PixelMetric
      const deliveryPercentage = usersPhotoDelivered / (registeredUser || 1);
      const views = pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.VIEWS)];
      const shares = pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.SHARES)];

      const metrics: Record<
        EVENT_REPORT_WIDGETS,
        {
          primary: number;
          secondary?: number;
          tertiary?: number;
        }
      > = {
        [EVENT_REPORT_WIDGETS.VIEWS]: {
          primary: views,
        },
        [EVENT_REPORT_WIDGETS.SHARES]: {
          primary: shares,
        },
        [EVENT_REPORT_WIDGETS.DOWNLOADS]: { primary: downloads },
        [EVENT_REPORT_WIDGETS.BULK_DOWNLOADS]: { primary: bulkDownloads },
        [EVENT_REPORT_WIDGETS.FACE_SEARCH]: {
          primary: pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.FACE_SEARCH)],
        },
        [EVENT_REPORT_WIDGETS.ENGAGEMENT]: {
          primary: pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.ENGAGEMENT)],
        },
        [EVENT_REPORT_WIDGETS.LEADS]: {
          primary: pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.LEADS)],
        },
        [EVENT_REPORT_WIDGETS.REGISTRATIONS]: {
          primary: pixelMetric[getPixelMetricKeyForWidget(EVENT_REPORT_WIDGETS.REGISTRATIONS)],
        },

        [EVENT_REPORT_WIDGETS.ATTENDEE_FEEDBACK]: { primary: 0 },
        [EVENT_REPORT_WIDGETS.FILES]: { primary: signatureFiles.total_image },
        [EVENT_REPORT_WIDGETS.PHOTO_DISTRIBUTION]: {
          primary: deliveryPercentage,
          secondary: usersPhotoDelivered,
          tertiary: registeredUser,
        },
        [EVENT_REPORT_WIDGETS.ROI]: {
          primary: getROIForEvent({
            downloads,
            bulkDownloads,
            views,
            shares,
            posterAdvocates: advocateMetrics.advocates.primary,
            posterReferrals: advocateMetrics.referrals.primary,
          }),
        },
        [EVENT_REPORT_WIDGETS.CHECK_IN]: { primary: 0 },
        [EVENT_REPORT_WIDGETS.PAID_DOWNLOADS]: { primary: 0 },
        [EVENT_REPORT_WIDGETS.YOUR_COMMISION]: { primary: 0 },
      };
      return metrics;
    },
  );

export const eventSponsorReportSelector = (projectId: string) =>
  createSelector(statsOnProjectSelector(projectId), (pixelMetric) => {
    const sponsorViews = pixelMetric[PixelService.TRACK_EVENTS.SPONSOR_VIEWS];
    const sponsorClicks = pixelMetric[PixelService.TRACK_EVENTS.SPONSOR_CLICKS];
    const sponsorCTAClicks = pixelMetric[PixelService.TRACK_EVENTS.SPONSOR_CTA_CLICKS];

    return {
      views: {
        primary: sponsorViews,
      },
      clicks: {
        primary: sponsorClicks,
      },
      ctaClicks: {
        primary: sponsorCTAClicks,
      },
      roi: {
        primary: getROIForSponsor({ sponsorViews, sponsorClicks, sponsorCTAClicks }),
      },
    };
  });
