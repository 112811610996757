import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { MODALS } from '../../../../../../../../common/Constants';
import { toggleModalVisibility } from '../../../../../../../../common/ModalDuck';
import { hasFeatureSelector } from '../../../../../settings/company/CompanyDataDuck';
import UploadEditBulkEventAttendeeMenuItem from './UploadEditBulkEventAttendeeMenuItem';

function mapStateToProps(state, ownProps) {
  return {
    isFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_PEOPLE_BULK_REGISTRATION,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showUploadModal: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.PEOPLE.BULK_UPDATE_EVENT_ATTENDEE_LIST, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadEditBulkEventAttendeeMenuItem);
