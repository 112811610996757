import { connect } from 'react-redux';
import FolderCoverPhoto from './FolderCoverPhoto';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { uploadFolderCoverPhoto, folderMetaDataForFolderSelector } from '../../folder-meta/FoldersMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: { folderId: string; projectId: string; smallStyle?: boolean; type?: 'cover' | 'thumbnail' },
) {
  const { folderId, projectId, smallStyle, type } = ownProps;

  const folderMeta = folderMetaDataForFolderSelector(folderId)(state);

  return {
    type,
    isLoading: isLoadingSelector(LOADINGS.FOLDER.UPDATE_COVER_PHOTO(folderId))(state),
    id: folderId,
    projectId,
    smallStyle,
    folderMeta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadCoverPhoto: (projectId: string, folderId: string, coverPhoto: File) => {
      dispatch(uploadFolderCoverPhoto(dispatch, projectId, folderId, coverPhoto));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderCoverPhoto);
