import React, { useEffect } from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  LoadingHeart,
  Page,
  PreventMarginCollapse,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';
import { AlbumType } from '../services/AlbumServices';
import AlbumSinglePageContainer from './AlbumSinglePageContainer';
import AlbumPreviewFooterContainer from './AlbumPreviewFooterContainer';

type Props = {
  isLoading: boolean;
  initData: (albumId: string, view: 'preview' | 'edit') => void;
  albumId: string;
  album?: AlbumType;
  noOfPages: number;
};

export default function AlbumPreview(props: Props) {
  const { initData, albumId, isLoading, noOfPages, album } = props;
  const { name } = album || {};

  useEffect(() => {
    initData(albumId, 'preview');
  }, []);

  if (isLoading) {
    return (
      <Row center fullHeight>
        <LoadingHeart />
      </Row>
    );
  }

  return (
    <div>
      <Page>
        <Space vertical size={16} />
        <Text
          size={TEXT_SIZE.SIZE_1}
          primaryStyle
          center
          block
          boldness={TEXT_BOLDNESS.BOLD}
          color={COLOR_SHADES.DARKER}
        >
          {name}
        </Text>
        <Space size={8} vertical />
        <Row center>
          <Col screenSMSize={12} size={4}>
            <Card style={CARD_STYLES.SECONDARY}>
              <Space>
                <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} center block>
                  Here is a{' '}
                  <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
                    draft{' '}
                  </Text>{' '}
                  of your album!
                </Text>
                <Space vertical size={2} />
                <Text>This draft will give you an idea about</Text>
                <ul>
                  <li>
                    <Text>
                      Overall <Text boldness={TEXT_BOLDNESS.BOLD}>layout</Text> of the album.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      The <Text boldness={TEXT_BOLDNESS.BOLD}>position & order</Text> of the photos.
                    </Text>
                  </li>
                </ul>
                <Space vertical />
                <Text>Note</Text>
                <ul>
                  <li>
                    <Text>Photos might not be colour graded!</Text>
                  </li>
                </ul>
              </Space>
            </Card>
          </Col>
        </Row>
        <Space size={16} vertical />
        {Array.from(Array(noOfPages).keys()).map((item, pageNo) => (
          <AlbumSinglePageContainer pageNo={pageNo + 1} key={item} />
        ))}
        <Space size={16} vertical />
        <PreventMarginCollapse>
          <Space vertical size={8} />
          <Row center>
            <Button style={BUTTON_STYLES.DEFAULT} link="/home">
              <Icon name="arrow_left" size={ICON_SIZES.SM} /> <Space size={1} /> Back home
            </Button>
            <Button style={BUTTON_STYLES.DEFAULT} onClick={() => BrowserUtils.scrollToTop()}>
              <Icon name="arrow_top" size={ICON_SIZES.SM} /> <Space size={1} /> Back to top
            </Button>
          </Row>
          <Space vertical size={8} />
        </PreventMarginCollapse>
        <AlbumPreviewFooterContainer albumId={albumId} />
      </Page>
    </div>
  );
}
