import { connect } from 'react-redux';
import { LOADINGS, MODALS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../../common/ModalDuck';
import { projectsSelector } from '../../../../../projects/AccountProjectsDataDuck';
import {
  downloadBulkRegistrationEventAttendeesCSV,
  updateBulkRegistrationEventAttendeesCSV,
  uploadNewBulkRegistrationEventAttendeesCSV,
} from '../../EventAttendeesDataDuck';
import UploadAndEditBulkAttendeeListModal from './UploadAndEditBulkAttendeeListModal';
import { clearErrorState, errorObjectForTypeSelector } from '../../../../../../../../common/ErrorDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const project = projectsSelector(state)[projectId];
  return {
    projectId,
    projectName: project?.project_name,
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CSV_BULK_DOWNLOAD)(state) ||
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPDATE)(state),
    show: isModalOpenSelector(MODALS.PEOPLE.BULK_UPDATE_EVENT_ATTENDEE_LIST)(state),
    errors:
      errorObjectForTypeSelector(LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPLOAD)(state) ||
      errorObjectForTypeSelector(LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.PEOPLE.BULK_UPDATE_EVENT_ATTENDEE_LIST, false));
    },
    downloadEventAttendeeData: (projectId, projectName) => {
      dispatch(downloadBulkRegistrationEventAttendeesCSV(dispatch, projectId, projectName));
    },
    uploadNewBulkRegistrationEventAttendeesCSV: (projectId, csvFile) => {
      dispatch(uploadNewBulkRegistrationEventAttendeesCSV(dispatch, projectId, csvFile));
    },
    updateBulkRegistrationEventAttendees: (projectId, csvFile) => {
      dispatch(updateBulkRegistrationEventAttendeesCSV(dispatch, projectId, csvFile));
    },
    clearErrors: () => {
      dispatch(clearErrorState(dispatch, LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPLOAD));
      dispatch(clearErrorState(dispatch, LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPDATE));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadAndEditBulkAttendeeListModal);
