import React, { useRef } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Dialog,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_BUTTON_STYLES,
  TextButton,
} from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import {
  BackgroundMusicSelectInput,
  BackgroundMusicTextGroupValue,
} from '../../../background-music/BackgroungMusicSelector';

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateMusic: (projectId: string, data: FormResponseType) => void;
  isDialogShown: boolean;
  toggleDialog: (show: boolean) => void;
  errors?: FormErrorType;
  isLoading: boolean;
};

export default function SignatureAlbumMusicSelector(props: Props) {
  const { projectId, isDialogShown, toggleDialog, projectMeta, updateMusic, errors, isLoading } = props;
  const { music_id: id } = projectMeta || {};
  const $button = useRef(null);
  return (
    <>
      <Color shade={COLOR_SHADES.DARKER} inline>
        <Icon name="music" size={ICON_SIZES.SM} alignSize={2} align="bottom" />
      </Color>
      <Space size={1} />
      {id ? (
        <TextButton
          ref={$button}
          style={TEXT_BUTTON_STYLES.SECONDARY}
          onClick={() => toggleDialog(!isDialogShown)}
          size={BUTTON_SIZE.LG}
        >
          <BackgroundMusicTextGroupValue value={id} />
        </TextButton>
      ) : (
        <Button
          ref={$button}
          style={BUTTON_STYLES.LINK}
          size={BUTTON_SIZE.SM}
          onClick={() => toggleDialog(!isDialogShown)}
        >
          Set music
        </Button>
      )}
      <Dialog target={$button.current} show={isDialogShown} onClose={() => toggleDialog(false)}>
        <Form onSubmit={(response) => updateMusic(projectId, response)} errors={errors}>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="music" size={ICON_SIZES.SM} alignSize={2} align="bottom" />
            </Color>
            <Space size={1} />
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              Music for slideshow
            </Text>
          </Row>
          <Space size={2} vertical />
          <Text block>Music will auto play on signature album slideshow</Text>
          <Space vertical />
          <BackgroundMusicSelectInput name="music_id" autoFocus defaultValue={id} />
          <FormFooter>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Set
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
