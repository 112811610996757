import { connect } from 'react-redux';
import EventPosterPreviewSidebar from './EventPosterPreviewSidebar';

function mapStateToProps(state, ownProps: { projectId: string; posterId?: string }) {
  const { projectId, posterId } = ownProps;
  return { projectId, posterId };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterPreviewSidebar);
