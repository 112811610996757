import React, { useEffect } from 'react';
import { Text, InnerPage, InnerPageContent, InnerPageHeader } from '@premagic/myne';
import { AssetsService, EventTrackerService } from '@premagic/core';
import APP_URLS from '../../services/AppRouteURLService';
import StorePageContentContainer from './StorePageContentContainer';

type Props = {
  view: 'list';
  filters: string;
  isMobileUp: boolean;
  hasStoreFeature: boolean;
  isLoading: boolean;
  initData: () => void;
};
const ROUTES = APP_URLS.STORE;

export default function StorePage(props: Props): JSX.Element {
  const { view, filters, hasStoreFeature, isMobileUp, isLoading, initData } = props;

  useEffect(() => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.STORE.VIEW, { view });
    initData();
  }, [view, initData]);

  const imageId = isMobileUp
    ? AssetsService.ASSETS_URL.STORE.COMMON_SOON_LG
    : AssetsService.ASSETS_URL.STORE.COMMON_SOON;

  return (
    <InnerPage>
      <InnerPageHeader iconName="shopping_cart" title="Offers">
        Offers
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        {hasStoreFeature ? (
          <StorePageContentContainer />
        ) : (
          <div>
            <Text>Upgrade your plan to view exclusive offer</Text>
          </div>
        )}
      </InnerPageContent>
    </InnerPage>
  );
}
