import React, { useEffect } from 'react';

import {
  BUTTON_ICON_STYLES,
  ButtonIcon,
  Color,
  COLOR_SHADES,
  GeneralEditor,
  GeneralEditorContent,
  GeneralEditorHeader,
  GeneralEditorSidebar,
  Icon,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

import { ProposalDeckService, ProposalVariableService } from '@premagic/proposals';
import ProposalCreatorSlideContainer from './proposal-slide/ProposalCreatorSlideContainer';
import ProposalCreatorHeaderContainer from './header/ProposalCreatorHeaderContainer';
import ProposalSlideListContainer from './proposal-slide-list/ProposalSlideListContainer';
import ProposalCreatorRightSidebarContainer from './ProposalCreatorRightSidebarContainer';
import ProposalCreatorFilesModalContainer from './proposal-files/ProposalCreatorFilesModalContainer';
import ProposalCreatorSelectFileForSlideModalContainer from './proposal-files/ProposalCreatorSelectFileForSlideModalContainer';
import DeckVariablesPanelContainer from './deck-variables/DeckVariablesPanelContainer';
import ProposalHistoryPanelContainer from './history/ProposalHistoryPanelContainer';
import ProposalThumbnailPanelContainer from './proposal-thumbnail/ProposalThumbnailPanelContainer';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  slideId?: number;
  slideIds: number[];
  isLoading: boolean;
  initData: (options: { projectId: string; folderId: string; deckId: string }) => void;
  setActiveSlide: (slideId: number) => void;
  deck?: ProposalDeckService.ProposalDeckType;
  updateProposal: (projectId: string, deckId: string, data) => void;
  proposalVariableDetails: Array<ProposalVariableService.ProposalVariableType>;
};

export default function ProposalCreatorLayout(props: Props) {
  const {
    initData,
    isLoading,
    projectId,
    folderId,
    deckId,
    slideId,
    slideIds,
    setActiveSlide,
    deck,
    updateProposal,
    proposalVariableDetails,
  } = props;

  useEffect(() => {
    initData({ projectId, folderId, deckId });
  }, [projectId, folderId, deckId]);

  useEffect(() => {
    if (isLoading) return;
    if (slideId) {
      setActiveSlide(slideId);
    } else {
      setActiveSlide(slideIds[0]);
    }
  }, [isLoading, slideId]);

  useEffect(() => {
    if (deck && !('proposal_variable_details' in deck.variables)) {
      const dataToSave = {
        data: { variables: { ...deck?.variables, proposal_variable_details: proposalVariableDetails } },
      };
      updateProposal(projectId, deckId, dataToSave);
    }
  }, [deckId, deck, projectId, updateProposal, proposalVariableDetails]);

  if (isLoading) {
    return (
      <GeneralEditor>
        <GeneralEditorHeader>
          <Row vcenter>
            <ButtonIcon style={BUTTON_ICON_STYLES.SECONDARY} title="Back" tooltipPlacement="bottom">
              <Icon name="arrow_left" />
            </ButtonIcon>
            <Color fillSolidIcon shade={COLOR_SHADES.DARKER} inline>
              <Icon name="film" />
            </Color>
            <Space size={2}>
              <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                Proposal
              </Text>
            </Space>
          </Row>
        </GeneralEditorHeader>
        <GeneralEditorSidebar>
          <div />
        </GeneralEditorSidebar>
        <GeneralEditorContent>
          <Row center fullHeight>
            <LoadingDots />
          </Row>
        </GeneralEditorContent>
        <GeneralEditorSidebar isRight>
          <div />
        </GeneralEditorSidebar>
      </GeneralEditor>
    );
  }

  return (
    <>
      <GeneralEditor>
        <GeneralEditorHeader>
          <ProposalCreatorHeaderContainer projectId={projectId} folderId={folderId} deckId={deckId} />
        </GeneralEditorHeader>
        <GeneralEditorSidebar>
          <ProposalSlideListContainer projectId={projectId} folderId={folderId} deckId={deckId} />
        </GeneralEditorSidebar>
        <GeneralEditorContent>
          <ProposalCreatorSlideContainer folderId={folderId} deckId={deckId} />
        </GeneralEditorContent>
        <GeneralEditorSidebar isRight>
          <ProposalCreatorRightSidebarContainer projectId={projectId} folderId={folderId} deckId={deckId} />
        </GeneralEditorSidebar>
      </GeneralEditor>
      <ProposalCreatorFilesModalContainer projectId={projectId} folderId={folderId} />
      <ProposalCreatorSelectFileForSlideModalContainer projectId={projectId} folderId={folderId} deckId={deckId} />
      <DeckVariablesPanelContainer projectId={projectId} folderId={folderId} deckId={deckId} />
      <ProposalHistoryPanelContainer projectId={projectId} folderId={folderId} />
      <ProposalThumbnailPanelContainer projectId={projectId} folderId={folderId} />
    </>
  );
}
