import { useLayoutEffect, useRef, useState } from 'react';
import ClassNames from 'classnames';
import { HotKeys } from 'react-hotkeys';
import { Modal as ModalLib } from 'react-overlays';
import { COLOR_SHADES } from '../Color/Color';
import { ErrorBoundary } from '../Error/Error';
import { Col, Row, Space } from '../Grid/Grid';
import { Backdrop } from './Backdrop';

import styles from './modal.module.css';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { ButtonCircle, BUTTON_CIRCLE_SIZES, BUTTON_CIRCLE_STYLES, Button, BUTTON_SIZE, BUTTON_STYLES } from '../Button';
import { Icon, ICON_SIZES } from '../Icon/Icons';
import { FadeIn } from '../Animation/Animation';
import { Card, CARD_SIZES, CARD_STYLES } from '../Card/Cards';

export enum MODAL_SIZES {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum MODAL_ALIGNMENT {
  DEFAULT = 'default',
  TOP = 'top',
}

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  close: () => void;
  size: MODAL_SIZES;
  alignModalContent?: MODAL_ALIGNMENT;
  showCloseButton?: boolean;
  showFooterCloseButton?: boolean;
}

const modalStyle = {
  position: 'fixed' as const,
  zIndex: 40,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const CLOSE_BUTTON_SIZE = 40;
export function Modal(props: ModalProps) {
  const {
    show,
    close,
    children,
    size,
    alignModalContent = MODAL_ALIGNMENT.DEFAULT,
    showCloseButton = false,
    showFooterCloseButton = false,
  } = props;
  const $content = useRef<HTMLDivElement>(null);
  const [closeButtonPosition, setCloseButtonPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  useLayoutEffect(() => {
    if ($content.current) {
      const { top, right } = $content.current.getBoundingClientRect();
      const left = right >= window.innerWidth ? window.innerWidth - CLOSE_BUTTON_SIZE : right - CLOSE_BUTTON_SIZE / 2; // is cutting off the screen | on mobile
      setCloseButtonPosition({
        top: top - CLOSE_BUTTON_SIZE / 2,
        left,
      });
    }
  }, [$content.current && $content.current?.clientHeight]);

  return (
    <ModalLib
      enforceFocus={false} // Inside the CompanyContactFormModal, when the user click on the country code selector input, the focus was getting reset which will prevent the user to type anything on selector
      renderBackdrop={(backdropProps) => <Backdrop props={backdropProps} />}
      containerClassName={styles['modal--open']}
      show={show}
      style={modalStyle}
      onHide={close}
      onClick={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
    >
      <ErrorBoundary>
        <div
          ref={$content}
          className={ClassNames(
            styles.modal__content,
            styles[`modal__content--size-${size}`],
            styles[`modal__content--align-${alignModalContent}`],
            {
              [styles['modal__content--with-close-button-icon']]: showCloseButton,
            },
          )}
        >
          {children}
          {showFooterCloseButton && (
            <>
              <Space vertical />
              <Row>
                <Col rightAlighed size={null}>
                  <Button onClick={close} size={BUTTON_SIZE.MD} style={BUTTON_STYLES.TERTIARY}>
                    Close
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
        {showCloseButton && (
          <div className={ClassNames(styles['modal__close-button'])} style={closeButtonPosition}>
            <ButtonCircle onClick={close} style={BUTTON_CIRCLE_STYLES.WHITE} layer={1} size={BUTTON_CIRCLE_SIZES.N}>
              <Icon name="x" size={ICON_SIZES.MD} />
            </ButtonCircle>
          </div>
        )}
      </ErrorBoundary>
    </ModalLib>
  );
}

interface ModalTitleProps {
  children: React.ReactNode;
}

export function ModalTitle(props: ModalTitleProps) {
  const { children } = props;
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
        {children}
      </Text>
      <Space vertical />
    </div>
  );
}

interface ModalMediaContentProps {
  children: React.ReactNode;
}

export function ModalMediaContent(props: ModalMediaContentProps) {
  const { children } = props;
  const classes = ClassNames(styles['modal__media-content']);

  return <div className={classes}>{children}</div>;
}

export enum MEDIA_MODAL_STYLES {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
}

interface MediaModalProps {
  children: React.ReactNode;
  show: boolean;
  close: () => void;
  style?: MEDIA_MODAL_STYLES;
}

export function MediaModal(props: MediaModalProps) {
  const { children, show, close, style = MEDIA_MODAL_STYLES.DEFAULT } = props;

  const keyMap = {
    close: 'esc',
  };

  const handlers = {
    close: () => close(),
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <div
        tabIndex={0}
        role="button"
        ref={(n) =>
          n &&
          show &&
          setTimeout(() => {
            n.focus();
          }, 0)
        }
        className={ClassNames(styles['media-modal'], styles[`media-modal--style-${style}`], {
          [styles['media-modal--show']]: show,
        })}
      >
        {show && children}
      </div>
    </HotKeys>
  );
}

interface MediaModalContentProps {
  children: React.ReactNode;
}

export function MediaModalContent(props: MediaModalContentProps) {
  const { children } = props;
  const classes = ClassNames(styles['media-modal__content']);

  return <div className={classes}>{children}</div>;
}

interface MediaModalContentLeftColProps {
  children: React.ReactNode;
}

export function MediaModalContentLeftCol(props: MediaModalContentLeftColProps) {
  const { children } = props;
  const classes = ClassNames(styles['media-modal__content__left']);

  return (
    <Col className={classes} size={9}>
      {children}
    </Col>
  );
}
interface MediaModalContentRightColProps {
  children: React.ReactNode;
}

export function MediaModalContentRightCol(props: MediaModalContentRightColProps) {
  const { children } = props;
  const classes = ClassNames(styles['media-modal__content__right']);

  return (
    <Col className={classes} size={3}>
      {children}
    </Col>
  );
}

interface MediaModalHeaderProps {
  children: React.ReactNode;
}

export function MediaModalHeader(props: MediaModalHeaderProps) {
  const { children } = props;
  const classes = ClassNames(styles['media-modal__header']);

  return <div className={classes}>{children}</div>;
}

interface MediaModalHeaderActionsProps {
  children: React.ReactNode;
}

export function MediaModalHeaderActions(props: MediaModalHeaderActionsProps) {
  const { children } = props;
  const classes = ClassNames(styles['media-modal__header__actions']);

  return <div className={classes}>{children}</div>;
}

interface MediaModalNavigatorProps {
  children: React.ReactNode;
  isRight?: boolean;
}

export function MediaModalNavigator(props: MediaModalNavigatorProps) {
  const { children, isRight = false } = props;
  const classes = ClassNames(styles['media-modal__naviator'], {
    [styles['media-modal__naviator--right']]: isRight,
  });

  return <div className={classes}>{children}</div>;
}

type InfoBoxModalProps = {
  children: React.ReactNode;
};

export function InfoBoxModal(props: InfoBoxModalProps) {
  const { children } = props;

  const classes = ClassNames(styles['info-box-modal']);

  return (
    <div className={classes}>
      <FadeIn>
        <Card
          center
          size={CARD_SIZES.SM}
          style={CARD_STYLES.TRANSPARENT}
          cssStyle={{ backgroundColor: 'rgba(0,0,0,0.9)' }}
        >
          {children}
        </Card>
      </FadeIn>
    </div>
  );
}
