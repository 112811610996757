import React, { useState } from 'react';
import { Col, DatePicker, FormGroup, FormLabel, RealCheckbox, RichTextArea, Row, Space, Text } from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';

import { CRMPaymentType, PAYMENT_TYPES } from '../CRMPaymentService';
import PaymentCategoryPickerContainer from '../PaymentCategoryPickerContainer';
import InputCurrencyContainer from '../../../acccount/InputCurrencyContainer';

type Props = {
  payment?: CRMPaymentType;
};

export default function ExpensePaymentForm(props: Props): JSX.Element {
  const { payment } = props;
  const isNew = !payment;
  const [isSettled, setIsSettled] = useState(isNew ? true : payment?.is_settled);

  return (
    <div>
      <Row>
        <Col>
          <PaymentCategoryPickerContainer
            paymentType={PAYMENT_TYPES.OUTFLOW}
            selectedCategoryId={payment?.payment_category}
          />
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel isRequired>Amount</FormLabel>
            <InputCurrencyContainer name="amount" required autoFocus defaultValue={payment?.amount} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <RealCheckbox name="is_settled" onChange={() => setIsSettled(!isSettled)} checked={isSettled}>
          Settled payment
        </RealCheckbox>
        <Text muted block>
          Payment is settled with employee
        </Text>
      </FormGroup>
      {isSettled ? (
        <FormGroup>
          <FormLabel isRequired>Payment date</FormLabel>
          <DatePicker
            name="payment_date"
            showTimePicker
            defaultValue={payment?.payment_date}
            maxDate={SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO)}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <FormLabel isRequired>Settlement due date</FormLabel>
          <DatePicker
            name="due_date"
            defaultValue={payment?.due_date}
            minDate={SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO)}
          />
        </FormGroup>
      )}
      <FormGroup>
        <FormLabel>Notes</FormLabel>
        <RichTextArea name="notes" defaultValue={payment?.notes} />
      </FormGroup>
    </div>
  );
}
