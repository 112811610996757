import { connect } from 'react-redux';
import { History } from 'history';
import { BrowserUrlUtils } from '@premagic/utils';
import CRMTour from './CRMTour';
import { MODALS } from '../../../common/Constants';
import { isModalOpenSelector, toggleModalVisibility } from '../../../common/ModalDuck';
import { createSampleProjectEvent } from './CRMTourDuck';

function mapStateToProps(state, ownProps: { history: History }) {
  return {
    history: ownProps.history,
    isTourOpen: isModalOpenSelector(MODALS.TOUR_MODAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleTour: (status: boolean) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.TOUR_MODAL, status));
    },
    openTour: (filters: BrowserUrlUtils.QueryObject) => {
      dispatch(createSampleProjectEvent(dispatch, filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMTour);
