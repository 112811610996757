import { ArrayUtils, HttpUtils } from '@premagic/utils';
import { LabelsType } from './LabelService';
import { API_URLS } from './APIURLService';

export enum EVENT_STATUS {
  NEW = 'NEW',
  QUALIFIED = 'QUALIFIED',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}

export const EVENT_STATUS_TYPE_DETAILS: Record<
  EVENT_STATUS,
  {
    id: EVENT_STATUS;
    title: string;
  }
> = {
  [EVENT_STATUS.NEW]: {
    id: EVENT_STATUS.NEW,
    title: 'New',
  },
  [EVENT_STATUS.QUALIFIED]: {
    id: EVENT_STATUS.QUALIFIED,
    title: 'Qualified',
  },
  [EVENT_STATUS.CONFIRMED]: {
    id: EVENT_STATUS.CONFIRMED,
    title: 'Confirmed',
  },
  [EVENT_STATUS.REJECTED]: {
    id: EVENT_STATUS.REJECTED,
    title: 'Rejected',
  },
};

export enum EVENT_PRIORITY {
  CRITICAL = 10,
  HIGH = 20,
  MEDIUM = 30,
  LOW = 40,
  ON_HOLD = 99,
}

export const EVENT_PRIORITY_TYPE_DETAILS: Record<
  EVENT_PRIORITY,
  {
    id: EVENT_PRIORITY;
    title: string;
    icon: string;
  }
> = {
  [EVENT_PRIORITY.CRITICAL]: {
    id: EVENT_PRIORITY.CRITICAL,
    title: 'Urgent',
    icon: 'alert_square',
  },
  [EVENT_PRIORITY.HIGH]: {
    id: EVENT_PRIORITY.HIGH,
    title: 'High',
    icon: 'range_high',
  },
  [EVENT_PRIORITY.MEDIUM]: {
    id: EVENT_PRIORITY.MEDIUM,
    title: 'Medium',
    icon: 'range_medium',
  },
  [EVENT_PRIORITY.LOW]: {
    id: EVENT_PRIORITY.LOW,
    title: 'Low',
    icon: 'range_low',
  },
  [EVENT_PRIORITY.ON_HOLD]: {
    id: EVENT_PRIORITY.ON_HOLD,
    title: 'On Hold',
    icon: 'pause',
  },
};

export enum EVENT_SORT_BY {
  CREATED_AT = 'created_at',
  PRIORITY_VALUE = 'priority_value',
}

export const EVENT_SORT_BY_TYPE_DETAILS: Record<
  EVENT_SORT_BY,
  {
    id: EVENT_SORT_BY;
    title: string;
  }
> = {
  [EVENT_SORT_BY.CREATED_AT]: {
    id: EVENT_SORT_BY.CREATED_AT,
    title: 'Added date',
  },
  [EVENT_SORT_BY.PRIORITY_VALUE]: {
    id: EVENT_SORT_BY.PRIORITY_VALUE,
    title: 'Priority',
  },
};

export enum EVENT_CATEGORY {
  BIRTHDAY = 'BIRTHDAY',
  CORPORATE = 'CORPORATE',
  COUPLE_SHOOT = 'COUPLE_SHOOT',
  FAMILY_PHOTO_SHOOT = 'FAMILY_PHOTO_SHOOT',
  MATERNITY_PHOTO_SHOOT = 'MATERNITY_PHOTO_SHOOT',
  MODEL_PHOTO_SHOOT = 'MODEL_PHOTO_SHOOT',
  NEWBORN_BABY = 'NEWBORN_BABY',
  PRODUCT_PHOTO_SHOOT = 'PRODUCT_PHOTO_SHOOT',
  REAL_ESTATE_PHOTO_SHOOT = 'REAL_ESTATE_PHOTO_SHOOT',
  RESTAURANT_PHOTO_SHOOT = 'RESTAURANT_PHOTO_SHOOT',
  THEME_PHOTO_SHOOT = 'THEME_PHOTO_SHOOT',
  WEDDING = 'WEDDING',
  ENGAGEMENT = 'ENGAGEMENT',
  CUSTOM = 'CUSTOM',
}

// This will be saved in project meta
export type EventDataType = {
  about_the_event?: string;
  date_timezone: string;
  start_date: string;
  end_date?: string;
  event_website?: string;
  location?: string;
  location_map_link?: string;
  number_of_attendees?: string;
  notes?: string;
};

export type EventType = EventDataType & {
  id: string;
  name: EVENT_CATEGORY | string;
  client: string;
  created_at: string;
  number?: string;
  service_providers?: Array<string>;
  occasions: Array<string>;
  total_amount: number;
  paid_amount: number;
  postal_code: string;
  labels: Array<LabelsType>;
  priority_value: EVENT_PRIORITY;
  status: EVENT_STATUS;
};

export function fetchEvent(eventId: string): Promise<EventType> {
  return (
    HttpUtils.get(API_URLS.CRM_EVENTS.DETAILS(eventId))
      .then((response) => response.data)
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch((response) => Promise.reject({ ...response.data, status: response.status }))
  );
}

export function fetchEventsForClient(clientId: string): Promise<{ results: Array<EventType>; count: number }> {
  return HttpUtils.get(API_URLS.CRM_EVENTS.ROOT(clientId)).then((response) => response.data);
}

function getDataToSave(data: Partial<EventType>): Partial<EventType> {
  // Convert service provider to array, in-case when user select only one user
  const { service_providers: serviceProviders } = data;
  if ('service_providers' in data) {
    return { ...data, service_providers: ArrayUtils.convertToArray(serviceProviders as Array<string>) };
  }
  return data;
}

export function createEvent(clientId: string, data: Partial<EventType>): Promise<EventType> {
  return HttpUtils.post(API_URLS.CRM_EVENTS.ROOT(clientId), getDataToSave(data), true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function editEvent(id: string, clientId: string, data: Partial<EventType>): Promise<EventType> {
  return HttpUtils.patch(API_URLS.CRM_EVENTS.EDIT(clientId, id), getDataToSave(data), true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function deleteEvent(eventId: string): Promise<EventType> {
  return HttpUtils.httpDelete(API_URLS.CRM_EVENTS.DETAILS(eventId)).then((response) => response.data);
}

export function createSampleProject(): Promise<EventType> {
  return HttpUtils.get(API_URLS.CRM_EVENTS.CREATE_SAMPLE).then((response) => response.data);
}
