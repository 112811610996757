import {
  ButtonIcon,
  COLOR_SHADES,
  Col,
  Icon,
  LoadingDots,
  MediaModal,
  MediaModalContent,
  MediaModalContentLeftCol,
  MediaModalContentRightCol,
  MediaModalHeader,
  MediaModalHeaderActions,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import React, { useEffect, useState } from 'react';
import APP_URLS from '../../../services/AppRouteURLService';
import WatermarkPhotoSelector from './WatermarkPhotoSelector';
import WatermarkPreviewFullViewImageContainer from './WatermarkPreviewFullViewImageContainer';

type Props = {
  init: (projectId: string, folderId: string) => void;
  folderId?: string;
  projectId: string;
  isLoadingProject?: boolean;
  isLoading?: boolean;
  navigateBack: (defaultUrl: string) => void;
  eventId?: string;
};

export default function WatermarkPreviewFullView(props: Props) {
  const { projectId, init, isLoadingProject, folderId, isLoading, navigateBack, eventId } = props;

  function close() {
    // if eventId is present then its a sponsor page
    const backUrl = eventId
      ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__SPONSORS, {
          eventId,
        })
      : BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT__COVER_SETTINGS, {
          projectId,
        });
    navigateBack(backUrl);
  }

  useEffect(() => {
    if (!projectId || !folderId || isLoadingProject) return;
    init(projectId, folderId);
  }, [projectId, folderId, init, isLoadingProject]);
  const [selectedPhoto, setSelectedPhoto] = useState<{ image; name }>();

  return (
    <MediaModal show close={close}>
      <MediaModalHeader>
        <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
          Watermark preview
        </Text>
        <Space size={12} />
        <WatermarkPhotoSelector onChange={setSelectedPhoto} value={selectedPhoto} />
        <MediaModalHeaderActions>
          <ButtonIcon onClick={close} title="Close">
            <Icon name="x" />
          </ButtonIcon>
        </MediaModalHeaderActions>
      </MediaModalHeader>
      <MediaModalContent>
        {isLoading || isLoadingProject ? (
          <LoadingDots />
        ) : (
          <>
            <MediaModalContentLeftCol>
              <WatermarkPreviewFullViewImageContainer projectId={projectId} photo={selectedPhoto} />
            </MediaModalContentLeftCol>
            <MediaModalContentRightCol>
              <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                Mobile view
              </Text>
              <Space vertical />

              <div style={{ maxHeight: 400 }}>
                <WatermarkPreviewFullViewImageContainer projectId={projectId} photo={selectedPhoto} isMobileView />
              </div>
            </MediaModalContentRightCol>
          </>
        )}
      </MediaModalContent>
    </MediaModal>
  );
}
