import { connect } from 'react-redux';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import EditClientPanel from './EditClientPanel';
import { updateCRMClients } from '../ClientsDataDuck';
import { clientIdForEventSelector } from '../../events/EventsDataDuck';

const PANEL_KEY = LOADINGS.CRM_CLIENTS.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.CRM_CLIENTS.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;
  const clientId = clientIdForEventSelector(eventId)(state);

  return {
    clientId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editClient: (clientId: string, data) => {
      dispatch(updateCRMClients(dispatch, clientId, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditClientPanel);
