import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { removeEventExhibitor } from '../EventExhibitorDataDuck';
import ExhibitorDeleteDialog from './ExhibitorDeleteDialog';

function mapStateToProps(state, ownProps: { exhibitorId: string; projectId: string }) {
  const { exhibitorId, projectId } = ownProps;

  return {
    exhibitorId,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.EVENT_EXHIBITOR.DELETE)(state),
    isDialogShown: isModalOpenSelector(LOADINGS.EVENT_EXHIBITOR.SHOW_DELETE_DIALOG(exhibitorId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (id: string, show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_DELETE_DIALOG(id), show));
    },
    deleteExhibitor: (projectId, exhibitorId) => {
      dispatch(removeEventExhibitor(dispatch, projectId, exhibitorId));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_DELETE_DIALOG(exhibitorId), false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorDeleteDialog);
