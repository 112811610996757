import { ModalDuck } from '@premagic/common-ducks';
import { FolderService, QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { qrCodeDesignsLogoSelector } from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { getFolderImagesAndOrder } from '../../../../projects/folders/AccountFoldersDataDuck';
import QRCodeLogoSelectorModal from './QRCodeLogoSelectorModal';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  const { logoId } =
    (ModalDuck.modalOptionsSelector(LOADINGS.QR_CODE.SELECT_LOGO_MODAL)(state) as {
      logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
    }) || {};
  return {
    projectId,
    folderId,
    logoId,
    show: ModalDuck.isModalOpenSelector(LOADINGS.QR_CODE.SELECT_LOGO_MODAL)(state),
    logos: qrCodeDesignsLogoSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_LOGO_MODAL, false));
      dispatch(
        ModalDuck.setModalOptions(dispatch, LOADINGS.QR_CODE.SELECT_LOGO_MODAL, {
          logoId: '',
        }),
      );
    },

    fetchLogoImages: (projectId, folderId) => {
      dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, FolderService.FOLDER_TYPES.CLIENT_WEBSITE));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeLogoSelectorModal);
