import { ProjectMetaService, StorePricingService } from '@premagic/core';
import React from 'react';
import StorePricingModalContainer from '../../store-pricing-selector/StorePricingModalContainer';

type Props = {
  isStorePriceSettingModalOpen?: boolean;
  closeStorePriceModal: () => void;
  projectId: string;
  updateProjectMeta: (projectId: string, formResponse: any) => void;
  photoDownloadPrice?: number;
  currencyCode?: string;
};

export default function StorePricingModalPhotoDownload(props: Props) {
  const {
    closeStorePriceModal,
    isStorePriceSettingModalOpen,
    photoDownloadPrice,
    currencyCode,
    projectId,
    updateProjectMeta,
  } = props;

  function handleStorePriceModalSave(formData) {
    const dataToPost = {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.STORE_PRICE_CURRENCY_CODE]: formData.data.store_currency,
        [ProjectMetaService.PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE]: formData.data.high_res_photo,
      },
    };
    updateProjectMeta(projectId, dataToPost);
    closeStorePriceModal();
  }
  return (
    <StorePricingModalContainer
      selectedProduct={StorePricingService.STORE_PRODUCTS_DETAILS[StorePricingService.STORE_PRODUCT_IDS.PHOTO_DOWNLOAD]}
      onSave={handleStorePriceModalSave}
      onClose={() => closeStorePriceModal()}
      show={isStorePriceSettingModalOpen}
      defaultSubProductPrices={{
        [StorePricingService.SUBPRODUCTS_IDS.HIGH_RES_PHOTO]: photoDownloadPrice,
        [StorePricingService.STORE_CURRENCY]: currencyCode,
      }}
    />
  );
}
