import { connect } from 'react-redux';
import SelectionFoldersPageFoldersLayout from './SelectionFoldersPageFoldersLayout';
import { projectsSelector } from '../../AccountProjectsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    location: { pathname: string };
    match: {
      params: {
        projectId: string;
        folderId?: string;
      };
    };
  },
) {
  const { projectId, folderId } = ownProps.match.params;

  return {
    projectId,
    project: projectsSelector(state)[projectId],
    activeFolderId: folderId,
  };
}

export default connect(mapStateToProps)(SelectionFoldersPageFoldersLayout);
