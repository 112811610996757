import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';
import { projectsSelector } from '../../../projects/AccountProjectsDataDuck';
import SignatureAlbumMusicSelector from './SignatureAlbumMusicSelector';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.MUSIC;
const DIALOG_KEY = LOADINGS.PROJECT_META.SHOW_UPDATE_PANEL;
const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    project: projectsSelector(state)[projectId],
    projectId,
    isDialogShown: isModalOpenSelector(DIALOG_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateMusic: (projectId, formResponse) => {
      dispatch(
        saveProjectMetaData(dispatch, projectId, formResponse, {
          itemId: META_ITEM,
        }),
      );
    },
    toggleDialog: (show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignatureAlbumMusicSelector);
