import { ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../common/Constants';
import { filesSelector } from '../images/FilesDataDuck';
import { folderIdsWithFolderTypeForProjectSelector } from '../projects/AccountProjectsDataDuck';
import { fetchFolder } from '../projects/folders/AccountFoldersDataDuck';
import { projectMetaDataForProjectSelector } from '../projects/project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../settings/company/CompanyDataDuck';
import {
  isSelectionAlbumEnabledSelector,
  isWatermarkBetaEnabledSelector,
} from '../settings/preferences/ClientSettingsDataDuck';
import ProjectWatermarkSettingCard from './ProjectWatermarkSettingCard';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId?: string;
  },
) {
  const isWatermarkBetaEnabled = isWatermarkBetaEnabledSelector(state);
  const { projectId, eventId } = ownProps;
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);
  return {
    projectId,
    hasWatermarkFeature:
      isWatermarkBetaEnabled || hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    files: filesSelector(state),
    brandingFolderId: brandingFolderIds[0],
    isSelectionAlbumEnabled: isSelectionAlbumEnabledSelector(state),
    eventId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditOfWatermark: (id) => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_EDIT_WATERMARK, true));
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.PROJECT_META.SHOW_EDIT_WATERMARK, id));
    },
    showAddOfWatermark: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_ADD_WATERMARK, true));
    },
    fetchFolderFiles: (projectId, folderId) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
    showAllBrandingFiles: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.BRANDING_FOLDER.FILES_MODAL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectWatermarkSettingCard);
