import './amplify-ui.css';

const Container = {
  fontFamily: `'Source Sans Pro', sans-serif`,
  fontWeight: '400',
};

const FormContainer = {
  textAlign: 'center',
  marginTop: '20px',
  margin: '5% auto 50px',
};

const FormSection = {
  position: 'relative',
  marginBottom: '20px',
  backgroundColor: '#fff',
  padding: '32px 40px',
  textAlign: 'left',
  display: 'inline-block',
  width: '100%',
  maxWidth: '500px',
  borderRadius: '6px',
  boxShadow: '1px 1px 4px 0 rgba(0,0,0,0.15)',
};

const FormField = {
  marginBottom: '24px',
};

const SectionHeader = {
  color: '#152939',
  marginBottom: '30px',
  fontSize: '18px',
  fontWeight: '500',
};

const SectionBody = {
  marginBottom: '30px',
};

const SectionFooter = {
  fontSize: '14px',
  color: '#828282',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'flex-start',
};

const SectionFooterPrimaryContent = {
  marginLeft: 'auto',
};

const SectionFooterSecondaryContent = {
  marginRight: 'auto',
  alignSelf: 'center',
};

const Input = {
  display: 'block',
  width: '100%',
  padding: '12px',
  fontSize: '14px',
  fontFamily: `'Source Sans Pro', sans-serif`,
  color: '#203a56',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  border: '1px solid #e9ebed',
  borderRadius: '2px',
  boxSizing: 'border-box',
  marginBottom: '12px',
};

const Button = {
  marginBottom: '0',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  cursor: 'pointer',
  color: '#fff',
  backgroundColor: '#047af0',
  textTransform: 'uppercase',
  padding: '12px 32px',
  letterSpacing: '1.1px',
  border: 'none',
  borderRadius: '2px',
  disabled: {
    opacity: '.5',
  },
};

const SignInButton = {
  position: 'relative',
  width: '100%',
  borderRadius: '4px',
  marginBottom: '10px',
  cursor: 'pointer',
  padding: 0,
  fontFamily: `'Source Sans Pro', sans-serif`,
  color: '#fff',
  fontSize: '14px',
  '#google_signin_btn': {
    backgroundColor: '#4285F4',
    fontFamily: 'Roboto',
    border: '1px solid #4285F4',
  },
  '#facebook_signin_btn': {
    backgroundColor: '#4267B2',
    borderColor: '#4267B2',
  },
  '#amazon_signin_btn': {
    backgroundColor: '#FF9900',
    border: 'none',
  },
  hover: {
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.2)',
  },
};

const SignInButtonIcon = {
  position: 'absolute',
  left: 0,
  '#google_signin_btn_icon': {
    backgroundColor: '#fff',
    borderRadius: '4px 0 0 4px',
    height: '30px',
    width: '30px',
    padding: '11px',
  },
  '#facebook_signin_btn_icon': {
    height: '33px',
    width: '18px',
    padding: '10px 14px',
  },
  '#amazon_signin_btn_icon': {
    padding: '10px',
    height: '32px',
    width: '32px',
  },
};

const SignInButtonContent = {
  display: 'block',
  padding: '18px 0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
};

const Strike = {
  width: '100%',
  textAlign: 'center',
  borderBottom: '1px solid #bbb',
  lineHeight: '0.1em',
  margin: '32px 0',
  color: '#828282',
};

const StrikeContent = {
  background: '#fff',
  padding: '0 25px',
  fontSize: '14px',
  fontWeight: '500',
};

const ActionRow = {
  marginBottom: '15px',
};

const FormRow = {
  marginBottom: '12px',
};

const A = {
  color: '#4a90e2',
  cursor: 'pointer',
};

const Hint = {
  color: '#828282',
  fontSize: '12px',
};

const Radio = {
  marginRight: '18px',
  verticalAlign: 'bottom',
};

const InputLabel = {
  color: '#203a56',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '8px',
};

const Toast = {
  background: 'rgba(0, 0, 0, 0.8)',
  padding: '16px',
  position: 'fixed',
  top: '76px',
  right: '12px',
  color: '#fff',
  borderRadius: '4px',
  boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.4)',
  zIndex: 1,
};

const NavBar = {};
const NavRight = {};
const Nav = {};
const NavItem = {
  display: 'inline',
  marginRight: '8px',
};
const NavButton = {
  border: 'none',
  background: '#eee',
  padding: '4px 12px',
  fontSize: '13px',
  lineHeight: '16px',
  color: '#888',
  borderRadius: '4px',
  cursor: 'pointer',
  hover: {
    background: '#555',
  },
};

export const AuthPageTheme = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  inputLabel: InputLabel,
  toast: Toast,

  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton,
};
