import {
  BUTTON_STYLES,
  Button,
  COLOR_SHADES,
  Col,
  Color,
  ICON_SIZES,
  Icon,
  Row,
  Space,
  SubTitle,
  Tooltip,
} from '@premagic/myne';
import React, { useState } from 'react';

type Props = { children: React.ReactNode; title: string; info?: string; hide?: boolean };

export default function QRCodePageSidebarAccordion(props: Props) {
  const { children, title, info, hide } = props;

  const [expand, setExpand] = useState<boolean>(true);

  if (hide) return null;

  return (
    <Space size={2}>
      <Button style={BUTTON_STYLES.RESET} onClick={() => setExpand((prev) => !prev)} block>
        <Row vcenter>
          <SubTitle>{title}</SubTitle>
          {info && (
            <>
              <Space size={2} />
              <Tooltip placement="bottom" message={info}>
                <Color inline shade={COLOR_SHADES.DARK}>
                  <Icon name="info" size={ICON_SIZES.SM} align="bottom" alignSize={4} />
                </Color>
              </Tooltip>
            </>
          )}
          <Col size={null} rightAlighed>
            <Icon name={expand ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
          </Col>
        </Row>
      </Button>

      {expand && (
        <>
          <Space size={2} vertical />
          {children}
        </>
      )}
    </Space>
  );
}
