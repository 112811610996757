import React from 'react';
import { BUTTON_SIZE, BUTTON_STYLES, Button, Icon, ICON_SIZES, Space } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { ProductFeedbackService } from '@premagic/core';
import APP_URLS from '../../../../../services/AppRouteURLService';

type Props = {
  eventId: string;
  isReportIssueEnabled?: boolean;
};

export default function ReportIssueButton(props: Props) {
  const { eventId, isReportIssueEnabled } = props;

  const reportIssuesUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__REPORT_ISSUES, {
    eventId,
    reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.NEED_MORE_PHOTOS,
  });

  return (
    <Button
      style={BUTTON_STYLES.LINK}
      size={BUTTON_SIZE.MD}
      link={reportIssuesUrl}
      disabled={!isReportIssueEnabled}
      disabledMessage="Enable this feature from Event Settings"
    >
      <Icon name="alert_triangle" size={ICON_SIZES.SM} /> <Space size={1} />
      Reported issues
    </Button>
  );
}
