import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { commonUISelector } from '../selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('MODAL', true);

export const toggleModalVisibility = createAction(
  getActionType('TYPE', 'SHOW'),
  (dispatch: Dispatch, type: string, show: boolean) => ({
    [type]: show,
  }),
);

export const setModalOptions = createAction(
  getActionType('OPTIONS', 'SET'),
  (dispatch: Dispatch, type: string, options: any) => ({
    [type]: options,
  }),
);

const initialState = {
  items: IMap({}),
  itemsOptions: IMap({}),
};

export const ModalReducer = handleActions(
  {
    [toggleModalVisibility.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [setModalOptions.toString()]: (state, action) => ({
      ...state,
      itemsOptions: state.itemsOptions.merge(action.payload),
    }),
  },
  initialState,
);

export const modalsDataSelector = createSelector(
  commonUISelector,
  (state) => state.modal as { items: IMap<string, boolean>; itemsOptions: IMap<string, unknown> },
);

const modalsSelector = createSelector(modalsDataSelector, (state) => state.items);
const modalsOptionsSelector = createSelector(modalsDataSelector, (state) => state.itemsOptions);

export const isModalOpenSelector = (type: string) => createSelector(modalsSelector, (modals) => !!modals.get(type));

export const isPopoverOpenSelector = (type: string) => createSelector(modalsSelector, (popover) => !!popover.get(type));

export const modalOptionsSelector = <T>(type: string) =>
  createSelector(modalsOptionsSelector, (modals) => modals.get(type) as T);
