import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../common/ModalDuck';
import PaymentDoneModal from './PaymentDoneModal';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.SUBSCRIPTION.PAYMENT)(state),
    show: isModalOpenSelector(MODALS.SUBSCRIPTION.PAYMENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.SUBSCRIPTION.PAYMENT, false));
      dispatch(push('/home'));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDoneModal);
