import { connect } from 'react-redux';
import { ProposalDeckDataDuck, ProposalService, ActionConstants } from '@premagic/proposals';
import { LoadingDuck } from '@premagic/common-ducks';
import { PixelService } from '@premagic/core';

import EventProposalItem from './EventProposalItem';
import { foldersEntitiesSelector } from '../../projects/folders/AccountFoldersDataDuck';
import { statsOnFolderForTypeSelector } from '../../projects/project-stats/ProjectsStatsDataDuck';
import { LOADINGS } from '../../../../../common/Constants';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    highlight?: boolean;
  },
) {
  const { id, highlight } = ownProps;
  const proposal = foldersEntitiesSelector(state)[id] as ProposalService.ProposalType;
  const { project_id: projectId } = proposal || {};
  const deckId = proposal.deck_versions[0]; // current_deck_version is the latest published deck
  return {
    proposal,
    deckId,
    highlight,
    deck: deckId ? ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId] : undefined,
    isLoading: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_DECK.FETCH(deckId))(state),
    isLoadingCounts: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_STATS.FETCH)(state),
    numberOfVisits: statsOnFolderForTypeSelector(projectId, id, PixelService.TRACK_EVENTS.VIEW)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId, deckId) => {
      dispatch(ProposalDeckDataDuck.getProposalDeck(dispatch, projectId, deckId));
    },
    sendToClient: () => {
      dispatch();
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventProposalItem);
