import React from 'react';
import { History } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import AccountProjectPageContainer from '../components/projects/AccountProjectPageContainer';
import AccountProjectsPageContainer from '../components/projects/AccountProjectsPageContainer';
import APP_URLS from '../services/AppRouteURLService';
import HighlightFoldersPageContainer from '../components/projects/highlight-folders/HighlightFoldersPageContainer';
import SelectionFoldersPageContainer from '../components/projects/selection-folders/SelectionFoldersPageContainer';

type Props = {
  history: History;
};

export default function ProjectPagesRoutes(props: Props): JSX.Element {
  const { history } = props;
  return (
    <Router history={history}>
      <Switch>
        <Route path={APP_URLS.PROJECT.HIGHLIGHT} component={HighlightFoldersPageContainer} />
        <Route path={APP_URLS.PROJECT.SELECTION} component={SelectionFoldersPageContainer} />

        <Route exact path={APP_URLS.PROJECT.DETAILS} component={AccountProjectPageContainer} />
        <Route exact path={APP_URLS.PROJECTS.LIST} component={AccountProjectsPageContainer} />
      </Switch>
    </Router>
  );
}
