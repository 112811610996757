import {
  FloatingWrapper,
  Icon as RemirrorIcon,
  useActive,
  useChainedCommands,
  useCommands,
  useCurrentSelection,
  useHelpers,
} from '@remirror/react';
import React from 'react';
import { SketchPicker } from 'react-color';
import {
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  GROUPED_BUTTON_STYLES,
  GroupedButton,
  GroupedButtons,
} from '../../Button/Buttons';
import { Row, Space } from '../../Grid';
import { ICON_SIZES, Icon } from '../../Icon/Icons';
import { SubTitle } from '../../Text/Text';
import { ColorPicker } from '../Form';

import useLinkState from './hooks/useLinkState';
import useTextColorState from './hooks/useTextColorState';
import { Dropdown, DropdownButton, Menu, MenuItem } from '../../Dropdown';
import { ScrollableContainer, SCROLLABLE_CONTAINER_SIZE } from '../../Page';

function ToolbarButtonsTextDecoration() {
  const chain = useChainedCommands();
  const active = useActive();
  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton
        active={active.bold()}
        onClick={() => {
          chain.toggleBold().focus().run();
        }}
      >
        <RemirrorIcon name="bold" />
      </GroupedButton>
      <GroupedButton
        active={active.italic()}
        onClick={() => {
          chain.toggleItalic().focus().run();
        }}
      >
        <RemirrorIcon name="italic" />
      </GroupedButton>
      <GroupedButton
        active={active.underline()}
        onClick={() => {
          chain.toggleUnderline().focus().run();
        }}
      >
        <RemirrorIcon name="underline" />
      </GroupedButton>
    </GroupedButtons>
  );
}

function ToolbarFontSizeOptions() {
  const FONT_SIZES = ['8', '10', '12', '14', '16', '18', '24', '30'];
  const { setFontSize } = useCommands();
  const { fontSize } = useActive();
  return (
    <Dropdown>
      <DropdownButton size={BUTTON_SIZE.XS} style={BUTTON_STYLES.DEFAULT}>
        <RemirrorIcon name="fontSize" />
      </DropdownButton>
      <Menu>
        <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} showShadow direction="vertical">
          {FONT_SIZES.map((size) => (
            <MenuItem key={size} onClick={() => setFontSize(size)} isActive={fontSize({ size })}>
              {size}
            </MenuItem>
          ))}
        </ScrollableContainer>
      </Menu>
    </Dropdown>
  );
}

function ToolbarButtonsHeadings() {
  const chain = useChainedCommands();
  const active = useActive();
  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton
        active={active.heading({ level: 1 })}
        onClick={() => {
          chain.toggleHeading({ level: 1 }).focus().run();
        }}
      >
        <RemirrorIcon name="h1" />
      </GroupedButton>
      <GroupedButton
        active={active.heading({ level: 2 })}
        onClick={() => {
          chain.toggleHeading({ level: 2 }).focus().run();
        }}
      >
        <RemirrorIcon name="h2" />
      </GroupedButton>
      <GroupedButton
        active={active.heading({ level: 3 })}
        onClick={() => {
          chain.toggleHeading({ level: 3 }).focus().run();
        }}
      >
        <RemirrorIcon name="h3" />
      </GroupedButton>
    </GroupedButtons>
  );
}

function ToolbarButtonsLists() {
  const chain = useChainedCommands();
  const active = useActive();
  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton
        active={active.bulletList()}
        onClick={() => {
          chain.toggleBulletList().focus().run();
        }}
      >
        <RemirrorIcon name="listUnordered" />
      </GroupedButton>
      <GroupedButton
        active={active.orderedList()}
        onClick={() => {
          chain.toggleOrderedList().focus().run();
        }}
      >
        <RemirrorIcon name="listUnordered" />
      </GroupedButton>
    </GroupedButtons>
  );
}

function ToolbarButtonsLink(props: { clickEdit; removeLink }) {
  const { clickEdit, removeLink } = props;
  const active = useActive();
  const activeLink = active.link();
  if (activeLink)
    return (
      <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
        <GroupedButton onClick={clickEdit}>
          <RemirrorIcon name="pencilLine" />
        </GroupedButton>
        <GroupedButton onClick={removeLink}>
          <RemirrorIcon name="linkUnlink" />
        </GroupedButton>
      </GroupedButtons>
    );

  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton onClick={clickEdit}>
        <RemirrorIcon name="link" />
      </GroupedButton>
    </GroupedButtons>
  );
}

function ToolbarButtonsTextColor(props: { removeColor; setShowColorPicker; showColorPicker }) {
  const { removeColor, setShowColorPicker, showColorPicker } = props;
  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton onClick={() => setShowColorPicker(!showColorPicker)}>
        <Icon name="paint_brush" size={ICON_SIZES.SM} />
      </GroupedButton>
    </GroupedButtons>
  );
}

function ToolbarTextAlignment() {
  const chain = useChainedCommands();
  const active = useActive();

  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton
        active={active.text({ textAlign: 'left' })}
        onClick={() => {
          chain.leftAlign().focus().run();
        }}
      >
        <RemirrorIcon name="alignLeft" />
      </GroupedButton>
      <GroupedButton
        active={active.text({ textAlign: 'center' })}
        onClick={() => {
          chain.centerAlign().focus().run();
        }}
      >
        <RemirrorIcon name="alignCenter" />
      </GroupedButton>
      <GroupedButton
        active={active.text({ textAlign: 'right' })}
        onClick={() => {
          chain.rightAlign().focus().run();
        }}
      >
        <RemirrorIcon name="alignRight" />
      </GroupedButton>
    </GroupedButtons>
  );
}

type RichTextToolbarProps = {
  showTextAlignment?: boolean;
  showFontSize?: boolean;
};

export function RichTextToolbar(props: RichTextToolbarProps) {
  const { showTextAlignment, showFontSize } = props;
  return (
    <div className="rich-editor__header">
      <ToolbarButtonsTextDecoration />
      <ToolbarButtonsHeadings />
      <ToolbarButtonsLists />
      {showTextAlignment && <ToolbarTextAlignment />}
      {showFontSize && <ToolbarFontSizeOptions />}
    </div>
  );
}

/* RichTextFloatingToolbarMini
 * - This has link and color button
 * */

export function RichTextFloatingToolbarMini() {
  const {
    isEditing: isEditingLink,
    linkPositioner,
    clickEdit,
    onRemove,
    submitHref,
    href,
    setHref,
    cancelHref,
  } = useLinkState();
  const { setColor, setShowColorPicker, showColorPicker, removeColor, selectedColor } = useTextColorState();

  const selection = useCurrentSelection();
  const commands = useCommands();
  const helpers = useHelpers();
  const active = useActive();

  return (
    <>
      <FloatingWrapper positioner="selection" placement="bottom" enabled={!isEditingLink} renderOutsideEditor>
        <ToolbarButtonsLink removeLink={onRemove} clickEdit={clickEdit} />
        <ToolbarButtonsTextColor
          removeColor={removeColor}
          setShowColorPicker={setShowColorPicker}
          showColorPicker={showColorPicker}
        />
      </FloatingWrapper>
      <FloatingWrapper
        positioner={linkPositioner}
        placement="bottom"
        enabled={!isEditingLink && selection.empty}
        renderOutsideEditor
      >
        <ToolbarButtonsLink removeLink={onRemove} clickEdit={clickEdit} />
      </FloatingWrapper>

      <FloatingWrapper positioner="always" placement="bottom" enabled={isEditingLink} renderOutsideEditor>
        <Space size={1}>
          <Row vcenter>
            <input
              name="link"
              style={{ zIndex: 20 }}
              autoFocus
              placeholder="Enter link..."
              onChange={(event) => setHref(event.target.value)}
              value={href}
              onKeyPress={(event) => {
                const { code } = event;

                if (code === 'Enter') {
                  submitHref();
                }

                if (code === 'Escape') {
                  cancelHref();
                }
              }}
            />
            <Space size={1} />
            <ButtonIcon
              size={BUTTON_ICONS_SIZES.SM}
              title="save"
              onClick={() => submitHref()}
              disabled={!href}
              disabledTitle="Please enter a link"
            >
              <Icon name="check" size={ICON_SIZES.SM} />
            </ButtonIcon>
          </Row>
        </Space>
      </FloatingWrapper>
      <FloatingWrapper positioner="selection" placement="bottom" enabled={showColorPicker} renderOutsideEditor>
        <Space size={2}>
          <SubTitle>Text Color</SubTitle>
          <ColorPicker name="" defaultValue={selectedColor} onChange={setColor} />
          {(active.orderedList() || active.bulletList()) && (
            <div>
              <Space vertical />
              <SubTitle>List Marker Color</SubTitle>
              <ColorPicker
                name=""
                defaultValue={helpers.getListMarkerColorForSelection(selection)}
                onChange={(color) => commands.setListMarkerColor(color)}
              />
            </div>
          )}
          {active.link() && (
            <div>
              <Space vertical />
              <SubTitle>Link Color</SubTitle>
              <ColorPicker
                name=""
                defaultValue={helpers.getLinkColorForSelection(selection)}
                onChange={(color) => commands.setLinkColor(color)}
              />
            </div>
          )}
        </Space>
      </FloatingWrapper>
    </>
  );
}
/*
 * RichTextFloatingToolbar
 *
 * */

type RichTextFloatingToolbarBasicProps = {
  disableColorChange?: boolean;
};

export function RichTextFloatingToolbarBasic(props: RichTextFloatingToolbarBasicProps) {
  const { disableColorChange } = props;
  const { setColor, selectedColor, showColorPicker, setShowColorPicker, removeColor } = useTextColorState();
  const {
    isEditing: isEditingLink,
    linkPositioner,
    clickEdit,
    onRemove,
    submitHref,
    href,
    setHref,
    cancelHref,
  } = useLinkState();

  const selection = useCurrentSelection();
  const { empty } = useCurrentSelection();

  return (
    <>
      <FloatingWrapper
        positioner="selection"
        placement="top"
        enabled={!(empty || isEditingLink || showColorPicker)}
        renderOutsideEditor
      >
        <ToolbarButtonsTextDecoration />
        <ToolbarButtonsLink removeLink={onRemove} clickEdit={clickEdit} />
        {!disableColorChange && (
          <ToolbarButtonsTextColor
            removeColor={removeColor}
            setShowColorPicker={setShowColorPicker}
            showColorPicker={showColorPicker}
          />
        )}
      </FloatingWrapper>
      {/* LINK EDIT */}
      <FloatingWrapper
        positioner={linkPositioner}
        placement="bottom"
        enabled={!isEditingLink && selection.empty}
        renderOutsideEditor
      >
        <ToolbarButtonsLink removeLink={onRemove} clickEdit={clickEdit} />
      </FloatingWrapper>

      <FloatingWrapper positioner="always" placement="bottom" enabled={isEditingLink} renderOutsideEditor>
        <Space size={1}>
          <Row vcenter>
            <input
              name="link"
              style={{ zIndex: 20 }}
              autoFocus
              placeholder="Enter link..."
              onChange={(event) => setHref(event.target.value)}
              value={href}
              onKeyPress={(event) => {
                const { code } = event;

                if (code === 'Enter') {
                  submitHref();
                }

                if (code === 'Escape') {
                  cancelHref();
                }
              }}
            />
            <Space size={1} />
            <ButtonIcon
              size={BUTTON_ICONS_SIZES.SM}
              title="save"
              onClick={() => submitHref()}
              disabled={!href}
              disabledTitle="Please enter a link"
            >
              <Icon name="check" size={ICON_SIZES.SM} />
            </ButtonIcon>
          </Row>
        </Space>
      </FloatingWrapper>
      <FloatingWrapper positioner="selection" placement="bottom" enabled={showColorPicker} renderOutsideEditor>
        <SketchPicker
          color={selectedColor}
          onChangeComplete={(c) => {
            setColor(c.hex);
          }}
        />
      </FloatingWrapper>
    </>
  );
}
