import { connect } from 'react-redux';
import { PricingPlanService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { requestingUserSelector } from '../../crm/users/UsersDataDuck';
import { companyDataSelector } from '../company/CompanyDataDuck';
import BuyPlanButton from './BuyPlanButton';

import { savePaymentInformation } from './SubscriptionPageDuck';
import { purchaseOrderItemSelector, setPurchaseOrder } from '../pricing-plan/PricingPlansDataDuck';
import { createSubscription, currentSubscriptionSelector } from './SubscriptionsDataDuck';
import { clientSettingsDataSelector } from '../preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    variant: PricingPlanService.PricingPlanVariant;
    planId: string;
    variantId: PricingPlanService.PricingPlanVariantId;
  },
) {
  const { variant, planId, variantId } = ownProps;
  const KEY = `${LOADINGS.PRICING_PLAN_ORDER}__${planId}`;
  return {
    variant,
    planId,
    variantId,
    purchaseOrder: purchaseOrderItemSelector(state),
    company: companyDataSelector(state),
    isLoading: isLoadingSelector(KEY)(state),
    subscription: currentSubscriptionSelector(state),
    clientSettings: clientSettingsDataSelector(state),
    requestingUser: requestingUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSubscription: (planId: string, variantId) => {
      dispatch(createSubscription(dispatch, planId, variantId));
    },
    savePaymentInformation: (options) => {
      dispatch(savePaymentInformation(dispatch, options));
    },
    resetOrder: () => {
      dispatch(setPurchaseOrder(dispatch, null));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyPlanButton);
