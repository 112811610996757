import { connect } from 'react-redux';
import { AuthDuck } from '@premagic/common-ducks';
import { requestingUserSelector } from '../crm/users/UsersDataDuck';
import ChangePasswordPage from './ChangePasswordPage';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../common/ErrorDuck';
import APP_URLS, { getRouteUrlFor } from '../../services/AppRouteURLService';
import { toastMessage } from '../../reducers/ToastStore';
import { navigateTo } from '../../../../services/RouterService';

const COMMON_KEY = LOADINGS.USERS.UPDATE;
function mapStateToProps(state) {
  return {
    user: requestingUserSelector(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (data) => {
      dispatch(
        AuthDuck.changePassword(dispatch, data, () => {
          const profilePage = getRouteUrlFor(APP_URLS.USER_PROFILE.INDEX, {});
          toastMessage('success', 'Password changed successfully');
          navigateTo(dispatch, profilePage);
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
