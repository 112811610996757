import { useEffect, useState } from 'react';

import { ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Col,
  Form,
  Row,
  Space,
  TEXT_BUTTON_STYLES,
  TextButton,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';

import { omit } from 'lodash';
import WatermarkFormContainer from './WatermarkFormContainer';

type Props = {
  id: string;
  projectId: string;
  updateWatermark: (projectId: string, formResponse) => void;
  isLoading: boolean;
  showPanel: boolean;
  closePanel: () => void;
  watermarks?: ProjectMetaService.ProjectMetaWatermarkType;
};

export default function EditWatermarkPanel(props: Props) {
  const { projectId, updateWatermark, isLoading, watermarks = {}, showPanel, closePanel, id } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!showPanel) setIsDeleting(false);
  }, [showPanel]);

  function handleAdd(formResponse) {
    updateWatermark(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.WATERMARK]: {
          ...watermarks,
          [id]: {
            ...watermarks[id],
            ...formResponse.data,
          },
        },
      },
    });
  }

  function handleDelete(deleteId) {
    setIsDeleting(true);
    updateWatermark(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.WATERMARK]: omit(watermarks, deleteId),
      },
    });
  }

  if (!showPanel) return null;

  return (
    <WindowPanel show={showPanel} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Watermark</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          formSpec={{
            opacity: {
              type: 'number',
            },
            size: {
              type: 'number',
            },
            remove_on_download: {
              type: 'boolean',
            },
          }}
          onSubmit={handleAdd}
        >
          <WatermarkFormContainer projectId={projectId} id={id} />
          <Space vertical />
          <Row>
            <TextButton onClick={() => handleDelete(id)} isLoading={isDeleting} style={TEXT_BUTTON_STYLES.LINK}>
              Delete watermark?
            </TextButton>
            <Col rightAlighed>
              <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
                Cancel
              </Button>
              <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
