import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../../common/Constants';
import { editQRCodeDesigns, qrCodeDesignsSelector } from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeIllustrationImageEditor from './QRCodeIllustrationImageEditor';
import { filesSelector } from '../../../../images/FilesDataDuck';

function mapStateToProps(state, ownProps) {
  const { folderId, projectId } = ownProps;

  let illustrationFile;
  const filesData = filesSelector(state);
  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);
  const illustrationId = qrCodeDesigns?.custom_illustration;
  if (illustrationId) {
    illustrationFile = filesData[illustrationId];
  }

  return {
    folderId,
    projectId,
    qrCodeDesigns,
    illustrationFile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateIllustrationMeta: (projectId, folderId, qrCodeDesigns, newDesignData) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData,
        }),
      );
    },
    closeModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_ILLUSTRATION_MODAL, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeIllustrationImageEditor);
