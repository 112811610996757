import React from 'react';
import {
  COLOR_SHADES,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  IconNames,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemContent,
  ListItemIcon,
  Nav,
  NAV_STYLES,
  NavItem,
  NavItemIcon,
  Row,
  Sidebar,
  SidebarFooter,
  Space,
  Text,
  TEXT_BOLDNESS,
  Tooltip,
} from '@premagic/myne';

import UserProfileDropdownContainer from '../user-profile/UserProfileDropdownContainer';
import { PERMISSIONS } from '../crm/users/UserPermissionService';
import { getDefaultAccountFinancialsUrlWithFilters } from '../account-financials/filters/AccountFinancialsFilterService';
import { ACCOUNT_FINANCIALS_TYPES } from '../account-financials/AccountFinancialsService';
import { JS_SELECTORS } from '../../../../common/JSClassSelectors';
import APP_URLS from '../../services/AppRouteURLService';
import HelpNavButtonContainer from '../help/HelpNavButtonContainer';
import SubscriptionStatusCardContainer from '../settings/subscriptions/subscriptions-status/SubscriptionStatusCardContainer';

type LinkType = {
  name: string;
  icon: IconNames;
  link: string;
  enabled?: boolean;
  className?: string;
  isActive?: () => boolean;
};

type Props = {
  isMobileUp: boolean;
  hasPermissions: Partial<Record<PERMISSIONS, boolean>>;
  hasLeadsModule: boolean;
  isFeatureFinancialsEnabled: boolean;
  isCompanyTypeNotPhotographer: boolean;
  isFeatureProjectsTabEnabled: boolean;
  isFeatureLibraryEnabled: boolean;
};

export default function AppLayoutMainNavbar(props: Props): React.ReactElement {
  const {
    hasPermissions,
    isMobileUp,
    hasLeadsModule,
    isFeatureFinancialsEnabled,
    isCompanyTypeNotPhotographer,
    isFeatureProjectsTabEnabled,
    isFeatureLibraryEnabled,
  } = props;
  const online = true;
  const links: Array<LinkType> = [
    {
      name: 'Home',
      icon: 'home',
      link: '/',
      enabled: !isCompanyTypeNotPhotographer && isMobileUp && hasPermissions[PERMISSIONS.DASHBOARD],
    },
    {
      name: 'Leads',
      icon: 'trello',
      link: APP_URLS.CRM_DASHBOARD.INDEX,
      enabled: hasLeadsModule && hasPermissions[PERMISSIONS.LEAD_MANAGEMENT],
    },
    {
      name: 'Events',
      icon: 'event',
      link: '/crm',
      enabled: hasPermissions[PERMISSIONS.EVENT_LIST],
      className: JS_SELECTORS.EVENTS_NAV_ITEM,
    },
    {
      name: 'Library',
      icon: 'book_open',
      link: '/library',
      enabled: isFeatureLibraryEnabled && hasPermissions[PERMISSIONS.DAM_LIBRARY],
      className: JS_SELECTORS.LIBRARY_NAV_ITEM,
    },
    {
      name: 'Projects',
      icon: 'server',
      link: '/projects',
      enabled: isFeatureProjectsTabEnabled && hasPermissions[PERMISSIONS.EVENT_DELIVERABLE_MANAGEMENT],
    },
    {
      name: 'Financials',
      icon: 'briefcase',
      link: getDefaultAccountFinancialsUrlWithFilters(ACCOUNT_FINANCIALS_TYPES.PENDING),
      enabled: hasPermissions[PERMISSIONS.FINANCIALS] && isFeatureFinancialsEnabled,
    },
    {
      name: 'Insights',
      icon: 'activity',
      link: '/reports/insight',
      enabled: isFeatureProjectsTabEnabled && isMobileUp && hasPermissions[PERMISSIONS.REPORTS],
    },
    {
      name: 'Offers',
      icon: 'shopping_cart',
      link: APP_URLS.STORE.INDEX,
      enabled: !isMobileUp && !isCompanyTypeNotPhotographer,
    },
    {
      name: 'Settings',
      icon: 'settings',
      link: '/settings',
      enabled: hasPermissions[PERMISSIONS.SETTINGS],
    },
  ];
  if (!isMobileUp) {
    return (
      <div>
        <Sidebar>
          <UserProfileDropdownContainer />
        </Sidebar>
        <Nav style={NAV_STYLES.BOTTOM}>
          {/* <BottomNavItem to="/home"> */}
          {/*  <Logo /> */}
          {/* </BottomNavItem> */}
          {links
            .filter((link) => link.enabled)
            .map((link) => (
              <NavItem to={link.link} key={link.name} className={link.className}>
                <NavItemIcon>
                  <Icon name={link.icon} size={ICON_SIZES.MD_SM} accentStyle={ICON_ACCENT_STYLES.FILL} />
                </NavItemIcon>
                {link.name}
              </NavItem>
            ))}
        </Nav>
      </div>
    );
  }
  return (
    <Sidebar>
      {!online && (
        <Tooltip
          message="Internet connection is slow. Please try using a different internet connection or try later."
          placement="right"
        >
          <Text color={COLOR_SHADES.DANGER} boldness={TEXT_BOLDNESS.BOLD}>
            <Row center inline>
              <Icon name="alert_triangle" size={ICON_SIZES.SM} />
              <Space size={1} />
              Poor network
            </Row>
          </Text>
        </Tooltip>
      )}
      <Space size={2} vertical />
      <List>
        <UserProfileDropdownContainer />
        <SubscriptionStatusCardContainer />
        {links
          .filter((link) => link.enabled)
          .map((link) => (
            <ListItem to={link.link} style={LIST_ITEM_STYLES.NAV} key={link.name} className={link.className}>
              <ListItemIcon>
                <Icon name={link.icon} size={ICON_SIZES.SM} accentStyle={ICON_ACCENT_STYLES.FILL} />
              </ListItemIcon>
              <ListItemContent>{link.name}</ListItemContent>
            </ListItem>
          ))}
      </List>
      <SidebarFooter>
        <List>
          {!isCompanyTypeNotPhotographer && (
            <ListItem to={APP_URLS.STORE.INDEX} style={LIST_ITEM_STYLES.NAV}>
              <ListItemIcon>
                <Icon name="shopping_cart" size={ICON_SIZES.SM} />
              </ListItemIcon>
              Offers
            </ListItem>
          )}
          <HelpNavButtonContainer />
        </List>
      </SidebarFooter>
    </Sidebar>
  );
}
