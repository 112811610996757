import React from 'react';

import {
  Icon,
  ICON_SIZES,
  NOTIFICATION_DOT_STYLES,
  NotificationDot,
  Row,
  Space,
  TEXT_BUTTON_STYLES,
  TextButton,
} from '@premagic/myne';
import { StringUtils } from '@premagic/utils';

type Props = {
  variables: Array<string>;
  unsetVariables: Array<string>;
  showVariablesPanel: () => void;
};

export default function ProposalCreatorDeckSetVariablesValues(props: Props) {
  const { variables, unsetVariables, showVariablesPanel } = props;
  const totalVariables = variables.length;
  const totalUnSetVariables = unsetVariables.length;

  if (totalUnSetVariables > 0) {
    return (
      <Row>
        <NotificationDot style={NOTIFICATION_DOT_STYLES.DEFAULT} />
        <Space size={1} />
        <TextButton onClick={showVariablesPanel} style={TEXT_BUTTON_STYLES.LINK}>
          <Icon size={ICON_SIZES.SM} name="tool" />
          <Space size={1} />
          Set {StringUtils.pluralize('Value', totalUnSetVariables)} for
          <Space size={1} />
          <span className="rich-text-variable-atom">
            {totalUnSetVariables} {StringUtils.pluralize('Variable', totalUnSetVariables)}
          </span>
        </TextButton>
      </Row>
    );
  }
  if (totalVariables > 0)
    return (
      <TextButton onClick={showVariablesPanel} style={TEXT_BUTTON_STYLES.LINK}>
        <Icon size={ICON_SIZES.SM} name="tool" />
        <Space size={1} />
        Update {StringUtils.pluralize('Value', totalVariables)} for
        <Space size={1} />
        <span className="rich-text-variable-atom">
          {totalVariables} {StringUtils.pluralize('Variable', totalVariables)}
        </span>
      </TextButton>
    );
  return null;
}
