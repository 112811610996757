import { EventPosterService } from '@premagic/core';
import { FormGroup, FormLabel } from '@premagic/myne';
import { PosterWidgetTextType } from '@premagic/poster-creator';
import FontSelectorContainer from '../../../font-selector/FontSelectorContainer';

type Props = {
  poster: EventPosterService.EventPosterType;
  editPoster: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetTextType;
  }) => void;
  projectId: string;
  loadFonts: (fonts: Array<string>) => void;
  widget: PosterWidgetTextType;
};

export default function EventPosterFontFamilySelect(props: Props) {
  const { poster, editPoster, projectId, loadFonts, widget } = props;

  function handleFontSelect(data) {
    const { fontFamily, fontSource } = data;
    const updatedWidget = Object.assign({}, widget, { font_family: fontFamily });
    editPoster({ projectId, widgetToUpdate: updatedWidget, poster });
    loadFonts([fontFamily]);
  }

  return (
    <FormGroup>
      <FormLabel>Font</FormLabel>
      <FontSelectorContainer
        defaultValue={widget && widget.font_family}
        onChange={handleFontSelect}
        name="font"
        nameSource=""
      />
    </FormGroup>
  );
}
