import { EventAttendeesService, EventCustomFromFieldsService } from '@premagic/core';
import {
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Color,
  COLOR_SHADES,
  FormGroup,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  Row,
  Space,
  SubTitle,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { useEffect, useState } from 'react';

function SocialLinkInput(props: {
  inputName: string;
  icon: string;
  prefix?: string;
  placeholder: string;
  defaultValue?: string;
}) {
  const { inputName, icon, prefix = '', placeholder, defaultValue } = props;

  const [value, setValue] = useState(BrowserUrlUtils.getLinkWithoutThePrefix(prefix, defaultValue));
  function handleChange(e) {
    setValue(BrowserUrlUtils.getLinkWithoutThePrefix(prefix, e.target.value));
  }

  useEffect(() => {
    setValue(BrowserUrlUtils.getLinkWithoutThePrefix(prefix, defaultValue));
  }, [defaultValue, prefix]);
  const absoluteLink = value && BrowserUrlUtils.getURLWithProtocol(`${prefix}${value}`);
  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.DARKER} inline>
        <Icon name={icon} size={ICON_SIZES.MD} />
      </Color>
      <Space size={3} />
      <Input
        prefix={prefix}
        name=""
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        size={INPUT_SIZES.MD}
      />
      <Space size={2} />
      {absoluteLink && (
        <ButtonIcon link={absoluteLink} isExternalLink title="Open" size={BUTTON_ICONS_SIZES.SM}>
          <Icon name="external_link" size={ICON_SIZES.SM} />
        </ButtonIcon>
      )}
      <input type="hidden" name={inputName} value={absoluteLink} />
    </Row>
  );
}
type Props = {
  attendee?: EventAttendeesService.EventAttendeeType;
};
export default function UserSocialForm(props: Props) {
  const { attendee } = props;
  const { custom_fields: customFields } = attendee || {};
  const fields: Array<{
    label: string;
    inputName: string;
    icon: string;
    prefix?: string;
    placeholder: string;
    defaultValue?: string;
  }> = [
    {
      label: 'Linkedin',
      inputName: EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_LINKEDIN,
      icon: 'linkedin_solid_color',
      prefix: 'linkedin.com/',
      placeholder: 'in/handle',
      defaultValue: customFields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_LINKEDIN],
    },
    {
      label: 'Instagram',
      inputName: EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_INSTAGRAM,
      icon: 'instagram_solid_color',
      prefix: 'instagram.com/',
      placeholder: 'username',
      defaultValue: customFields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_INSTAGRAM],
    },
    {
      label: 'X',
      inputName: EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_TWITTER,
      icon: 'twitter_solid_color',
      prefix: 'x.com/',
      placeholder: 'username',
      defaultValue: customFields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_TWITTER],
    },
    {
      label: 'Website',
      inputName: EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_WEBSITE,
      icon: 'glob',
      placeholder: 'eg. www.johnblogg.com',
      defaultValue: customFields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_WEBSITE],
    },
  ];

  return (
    <div>
      <SubTitle>Social Links</SubTitle>
      <Space vertical size={2} />
      {fields.map((field) => (
        <FormGroup key={field.label}>
          <SocialLinkInput {...field} />
        </FormGroup>
      ))}
      <Space vertical size={2} />
    </div>
  );
}
