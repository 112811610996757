import React from 'react';
import { List as IList } from 'immutable';
import {
  Row,
  Space,
  Icon,
  ICON_SIZES,
  Dropdown,
  DropdownIcon,
  MenuItem,
  MenuItemIcon,
  Menu,
  ButtonIcon,
  HTML_ICON,
  Text,
} from '@premagic/myne';
import { ProjectService } from '@premagic/core';
import ProjectPinNumber from './ProjectPinNumber';

type Props = {
  projectId: string;
  project?: ProjectService.ProjectType;
  projectsShownInPortfolio: IList<string>;
  showProjectInPortfolio: (projectId: string) => void;
  hideProjectInPortfolio: (projectId: string) => void;
  portfolioProjectLimit: number;
};

export default function AccountProjectItemOptionMenu(props: Props) {
  const {
    projectId,
    project,
    projectsShownInPortfolio,
    showProjectInPortfolio,
    hideProjectInPortfolio,
    portfolioProjectLimit,
  } = props;

  const isProjectShownInPortfolio = projectsShownInPortfolio.includes(projectId) || false;

  const totalProjectsShownInPortfolio = projectsShownInPortfolio?.size;
  const isProjectAvailableToShowInPortfolio = totalProjectsShownInPortfolio < portfolioProjectLimit;
  const { mpin, is_archive: isArchived } = project || {};
  return (
    <Row vcenter>
      {isProjectShownInPortfolio && (
        <ButtonIcon title="Shown on Portfolio">
          <Icon name="glob" size={ICON_SIZES.SM} />
        </ButtonIcon>
      )}
      {!isArchived && mpin && <ProjectPinNumber pin={mpin} />}
      {isArchived && <Text muted>Archived</Text>}
      <Space size={2} />
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="bottom">
          <Icon name="more_horizontal" />
        </DropdownIcon>
        <Menu>
          {isProjectShownInPortfolio ? (
            <MenuItem onClick={() => hideProjectInPortfolio(projectId)}>
              <MenuItemIcon name="glob" />
              Hide from your Portfolio
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => showProjectInPortfolio(projectId)}
              disabled={!isProjectAvailableToShowInPortfolio}
              disabledMessage={`Limit reached, Only ${portfolioProjectLimit} ${
                portfolioProjectLimit > 1 ? 'albums' : 'album'
              } can be shown in portfolio`}
            >
              <MenuItemIcon name="glob" />
              Show in your Portfolio
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    </Row>
  );
}
