import { IntegrationService } from '@premagic/core';
import { useEffect } from 'react';
import IntegrationSyncOrImportMenuItemContainer from './IntegrationSyncOrImportMenuItemContainer';

type Props = {
  projectId: string;
  fetchProjectMeta: (projectId: string) => void;
  toFetchProjectMeta?: boolean;
  isFetchingProjectMeta?: boolean;
  integrationSettings: IntegrationService.IntegrationClientSettingsType;
};

export default function IntegrationSyncOrImportMenuItems(props: Props) {
  const { projectId, fetchProjectMeta, toFetchProjectMeta, isFetchingProjectMeta, integrationSettings } = props;

  useEffect(() => {
    if (toFetchProjectMeta && !isFetchingProjectMeta) {
      fetchProjectMeta(projectId);
    }
  }, [fetchProjectMeta, projectId, toFetchProjectMeta, isFetchingProjectMeta]);

  return (
    <>
      {Object.values(IntegrationService.INTEGRATION_PLATFORMS)
        .filter((platform) => integrationSettings[platform]?.enabled)
        .map((platform) => (
          <IntegrationSyncOrImportMenuItemContainer key={platform} projectId={projectId} platform={platform} />
        ))}
    </>
  );
}
