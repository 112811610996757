import { connect } from 'react-redux';
import { clientWebsitesSelector, saveClientWebsite } from '../ClientWebsiteDataDuck';
import ClientWebsiteFontAndColorCustomization from './ClientWebsiteFontAndColorCustomization';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';

function mapStateToProps(
  state,
  ownProps: { id: string; projectId: string; isTrialPackage?: boolean; isInsideCard?: boolean },
) {
  const { id, projectId, isTrialPackage, isInsideCard = true } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    id,
    projectId,
    isTrialPackage,
    isInsideCard,
    isSaving: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveClientWebsiteData: (id: string, projectId: string, data) => {
      dispatch(saveClientWebsite(dispatch, { id, projectId }, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteFontAndColorCustomization);
