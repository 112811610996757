import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';

import {
  navigateToAccountFinancialsPageWithFilter,
  removeFilterAccountFinancialsPage,
} from './AccountFinancialsFilterDuck';
import { AccountFinancialsFilters } from './AccountFinancialsFilters';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';

function mapStateToProps(state, ownProps: { filters: string; type: ACCOUNT_FINANCIALS_TYPES }) {
  const { filters, type } = ownProps;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
  return {
    filters: filterObject,
    type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (view, filters) => {
      dispatch(navigateToAccountFinancialsPageWithFilter(dispatch, view, filters));
    },
    removeFilter: (view, filterKey, filters) => {
      dispatch(removeFilterAccountFinancialsPage(dispatch, view, filterKey, filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinancialsFilters);
