import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  InnerPageSubHeader,
  Row,
  Space,
  TabItem,
  Tabs,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { EventTrackerService } from '@premagic/core';

import OccasionsCalendarPageContainer from './calendar-and-event-page/occasion-calendar/OccasionsCalendarPageContainer';
import EventsListPageContainer from './calendar-and-event-page/events-list/EventsListPageContainer';
import APP_URLS from '../../services/AppRouteURLService';
import { getDefaultCRMCalendarUrlWithFilters, getDefaultCRMEventListUrlWithFilters } from './filters/CRMFilterService';
import CRMFiltersContainer from './filters/CRMFiltersContainer';
import { JS_SELECTORS } from '../../../../common/JSClassSelectors';

type Props = {
  view: 'list' | 'calendar';
  filters: string;
  hasCRMFeature: boolean;
  showCRMClientSearchModal: () => void;
  fetchAllEventLabels: () => void;
  isCompanyTypeNotPhotographer: boolean;
  isFeatureEventsOccasionsEnabled: boolean;
  isFeatureLeadEnabled: boolean;
  isMobileUp: boolean;
};

const ROUTES = APP_URLS.CRM;

export default function CRMPage(props: Props): JSX.Element {
  const {
    view,
    filters,
    hasCRMFeature,
    showCRMClientSearchModal,
    fetchAllEventLabels,
    isCompanyTypeNotPhotographer,
    isFeatureEventsOccasionsEnabled,
    isFeatureLeadEnabled,
    isMobileUp,
  } = props;
  const createNewUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.CREATE__CLIENT, {});

  const urlForCalendar = getDefaultCRMCalendarUrlWithFilters();
  const urlForEvents = getDefaultCRMEventListUrlWithFilters({ isFeatureLeadEnabled });

  useEffect(() => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.VIEW, { view });
    fetchAllEventLabels();
  }, [view, fetchAllEventLabels]);

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="event"
        title="Events"
        rightActions={
          <Button className={JS_SELECTORS.ADD_EVENTS_BTN} style={BUTTON_STYLES.PRIMARY} link={createNewUrl}>
            Add Event
          </Button>
        }
      >
        Events
      </InnerPageHeader>
      {isFeatureEventsOccasionsEnabled && (
        <InnerPageSubHeader>
          <Tabs>
            <TabItem to={urlForEvents} active={view == 'list'}>
              List
            </TabItem>
            <TabItem to={urlForCalendar} active={view == 'calendar'}>
              Calendar
            </TabItem>
          </Tabs>
        </InnerPageSubHeader>
      )}
      <InnerPageContent>
        <Row vcenter={isMobileUp}>
          <CRMFiltersContainer filters={filters} view={view} />
          <Space size={1} />
          {!isCompanyTypeNotPhotographer && (
            <Col size={null} rightAlighed>
              <Button onClick={() => showCRMClientSearchModal()} size={BUTTON_SIZE.SM}>
                <Icon name="search" size={ICON_SIZES.MD_SM} />
                <Space size={1} />
                Search
              </Button>
            </Col>
          )}
        </Row>
        <Space vertical size={2} />
        <Switch>
          <Route path={ROUTES.EVENTS_LIST} component={EventsListPageContainer} />
          <Route path={ROUTES.CALENDAR} component={OccasionsCalendarPageContainer} />
        </Switch>
        <Space vertical />
        {!hasCRMFeature && (
          <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
            CRM is available only on higher plan
          </Alert>
        )}
      </InnerPageContent>
    </InnerPage>
  );
}
