import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';

import AlbumConfirmationToPhotographerModal from './AlbumConfirmationToPhotographerModal';
import { LOADINGS, MODALS } from '../../../common/Constants';
import { updateAlbum } from './AlbumDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    albumId: string;
  },
) {
  return {
    albumId: ownProps.albumId,
    show: ModalDuck.isModalOpenSelector(MODALS.ALBUM.UPDATE_STATE)(state),
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.ALBUM.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.ALBUM.UPDATE_STATE, false));
    },
    updateAlbumState: (albumId, state) =>
      dispatch(
        updateAlbum(
          dispatch,
          albumId,
          {
            state,
          },
          {},
        ),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumConfirmationToPhotographerModal);
