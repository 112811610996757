import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';

import ProposalCreatorFiles from './ProposalCreatorFiles';
import { filesSelector } from '../../../images/FilesDataDuck';
import { hasFeatureSelector, proposalPhotosLimitSelector } from '../../../settings/company/CompanyDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    onSelect: (fileId: string) => void;
  },
) {
  const { projectId, folderId, onSelect } = ownProps;
  const hasAdvancedProposalFeatureEnabled = hasFeatureSelector(
    PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED,
  )(state);

  return {
    projectId,
    folderId,
    files: filesSelector(state),
    onSelect,
    hasAdvancedProposalFeatureEnabled,
    proposalPhotosLimit: proposalPhotosLimitSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorFiles);
