import { LoadingDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { filesSelector, updateFileMeta } from '../../images/FilesDataDuck';
import ImagesMetaForm from './ImagesMetaForm';

type OwnPropsType = {
  folderId?: string;
  projectId: string;
  id: string;
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  const { projectId, folderId, id } = ownProps;

  const file = filesSelector(state)[id];

  return {
    id,
    file,
    projectId,
    folderId: folderId || file?.folder_id,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FILE.UPDATE_META(id))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSaveMeta: (options, data) => {
      dispatch(updateFileMeta(dispatch, options, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesMetaForm);
