import { Text } from '@premagic/myne';
import FolderWatermarkListItemContainer from './FolderWatermarkListItemContainer';

type Props = { allHighlightFolderIds?: Array<string>; sponsorFolderId: string; eventId: string };

export default function FolderWatermarkList(props: Props) {
  const { allHighlightFolderIds, sponsorFolderId, eventId } = props;

  if (!allHighlightFolderIds || allHighlightFolderIds.length === 0)
    return (
      <Text muted block>
        No Folder added
      </Text>
    );

  return (
    <div>
      {allHighlightFolderIds?.map((folderId) => (
        <FolderWatermarkListItemContainer
          key={folderId}
          folderId={folderId}
          eventId={eventId}
          sponsorFolderId={sponsorFolderId}
        />
      ))}
    </div>
  );
}
