import React from 'react';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemAction,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Space,
  Text,
  TEXT_BOLDNESS,
  TimeAgo,
} from '@premagic/myne';
import { FolderService } from '@premagic/core';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';

type Props = {
  folder: FolderService.FolderType;
  isUpdating: boolean;
  removeFolder: (folderId: string) => void;
};

export default function CompanyBrandingListItem(props: Props): JSX.Element {
  const { folder, isUpdating, removeFolder } = props;

  const { folder_name: name, folder_id: id, created_at: createdAt } = folder;

  const brandingDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.BRANDING.DETAILS, {
    folderId: id,
    view: 'watermark',
  });

  return (
    <ListItem>
      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
        {name}
      </Text>
      <Col rightAlighed size={null}>
        <Text muted>
          <TimeAgo date={SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt)} />
        </Text>
      </Col>
      <Space size={8} />
      <Button style={BUTTON_STYLES.LINK} link={brandingDetails} size={BUTTON_SIZE.SM}>
        Edit
      </Button>
      <Space size={8} />
      <ListItemAction className={JS_SELECTORS.SYSTEM_FOLDER_MORE_OPTIONS(id)}>
        {isUpdating ? (
          <LoadingDots size="sm" />
        ) : (
          <Dropdown>
            <DropdownIcon title="Branding options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
              <Icon name="more_horizontal" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
            </DropdownIcon>
            <Menu>
              <MenuItem onClick={() => removeFolder(id)}>
                <MenuItemIcon name="trash" />
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </ListItemAction>
    </ListItem>
  );
}
