import React, { useRef, useState } from 'react';
import { Dialog, DIALOG_PLACEMENT, ErrorBoundary, Icon, Label, Row, Space } from '@premagic/myne';
import { EventsService, LabelService } from '@premagic/core';

import { CRM_FILTER_KEYS, CRMFiltersType } from './CRMFilterTypes';
import EventStatusLabel from '../events/details/EventStatusLabel';
import { CRM_FILTERS_DETAILS } from './CRMFilterConstants';
import CRMFilterItemsDialogContent from './CRMFilterItemsDialogContent';
import EventPaymentStatusLabel from '../events/details/EventPaymentStatusLabel';
import EventLabels from '../events/details/EventLabels';
import EventSortByLabel from '../events/details/EventSortByLabel';

type CRMFiltersItemContentProps = {
  filterKey: CRM_FILTER_KEYS;
  value?: string | EventsService.EVENT_STATUS | Array<string>;
};
const EMPTY_VALUE = '(not set)';

function CRMFiltersItemContent(props: CRMFiltersItemContentProps): JSX.Element {
  const { filterKey, value = EMPTY_VALUE } = props;
  const { label } = CRM_FILTERS_DETAILS[filterKey];
  switch (filterKey) {
    case CRM_FILTER_KEYS.STATUS:
    case CRM_FILTER_KEYS.EVENT_STATUS: {
      return (
        <>
          {label} <Space size={2} />{' '}
          <EventStatusLabel status={value === EMPTY_VALUE ? null : (value as EventsService.EVENT_STATUS)} />
        </>
      );
    }
    case CRM_FILTER_KEYS.EVENT_SORT_BY: {
      return (
        <>
          {label} <Space size={2} />{' '}
          <EventSortByLabel sortBy={value === EMPTY_VALUE ? undefined : (value as EventsService.EVENT_SORT_BY)} />
        </>
      );
    }
    case CRM_FILTER_KEYS.EVENT_LABELS: {
      return (
        <>
          {label} <Space size={2} />{' '}
          <EventLabels labels={value === EMPTY_VALUE ? undefined : (value as Array<string>)} />
        </>
      );
    }
    case CRM_FILTER_KEYS.PAYMENT_STATUS: {
      return (
        <>
          {label} <Space size={2} />{' '}
          <EventPaymentStatusLabel isSettled={value === EMPTY_VALUE ? null : value === 'true'} />
        </>
      );
    }

    default:
      return (
        <>
          {label}: {value}
        </>
      );
  }
}

type CRMFiltersItemProps = {
  filterKey: CRM_FILTER_KEYS;
  value?: string | Array<string>;
  onRemove: (filterKey: CRM_FILTER_KEYS) => void;
  onChange: (newFilterValue: string) => void;
  labelOptions: Array<LabelService.LabelsType>;
  isMobileUp: boolean;
};

function CRMFiltersItem(props: CRMFiltersItemProps): JSX.Element {
  const { filterKey, value, onRemove, onChange, labelOptions, isMobileUp } = props;
  const [showDialog, setShowDialog] = useState(false);
  const DialogContentForFilter = CRMFilterItemsDialogContent[filterKey];

  const target = useRef(null);
  return (
    <span ref={target}>
      <ErrorBoundary>
        <Label onRemove={() => onRemove(filterKey)} onClick={() => setShowDialog(true)}>
          <CRMFiltersItemContent filterKey={filterKey} value={value} />
        </Label>
        <Space vertical={!isMobileUp} size={isMobileUp ? 4 : 1} />
        {showDialog && (
          <Dialog
            target={target.current}
            show={showDialog}
            placement={DIALOG_PLACEMENT.AUTO}
            onClose={() => setShowDialog(false)}
          >
            <Space size={2}>
              <DialogContentForFilter
                value={value}
                onChange={(newFilterValue) => {
                  onChange(newFilterValue);
                  setShowDialog(false);
                }}
                labelOptions={labelOptions}
              />
            </Space>
          </Dialog>
        )}
      </ErrorBoundary>
    </span>
  );
}

type Props = {
  filters: CRMFiltersType;
  view: 'calendar' | 'list';
  navigate: (view: 'calendar' | 'list', filters: CRMFiltersType) => void;
  removeFilter: (view: 'calendar' | 'list', filterToRemove: CRM_FILTER_KEYS, filters: CRMFiltersType) => void;
  labelOptions: Array<LabelService.LabelsType>;
  isFeatureLeadEnabled: boolean;
  isMobileUp: boolean;
};

export function CRMFilters(props: Props): JSX.Element {
  const { filters = {}, navigate, view, removeFilter, labelOptions, isFeatureLeadEnabled, isMobileUp } = props;

  function navigateWithNewFilter(newFilter: CRMFiltersType) {
    navigate(view, {
      ...filters,
      ...newFilter,
    });
  }

  const isCalendarView = view === 'calendar';
  function getFiltersToShow() {
    if (isCalendarView) return [CRM_FILTER_KEYS.EVENT_STATUS];
    if (isFeatureLeadEnabled)
      return [CRM_FILTER_KEYS.STATUS, CRM_FILTER_KEYS.EVENT_SORT_BY, CRM_FILTER_KEYS.EVENT_LABELS];

    return [CRM_FILTER_KEYS.EVENT_SORT_BY, CRM_FILTER_KEYS.EVENT_LABELS];
  }
  // CRM_FILTER_KEYS.EVENT_STATUS - For the occasion api, event status key is event_status and for events its status
  const FILTERS_TO_SHOW = getFiltersToShow();

  return (
    <Row vcenter={isMobileUp} columnDirection={!isMobileUp}>
      {isMobileUp && (
        <>
          <Space size={1} />
          <Icon name="filter" /> <Space />
        </>
      )}
      {FILTERS_TO_SHOW.map((filterKey) => (
        <CRMFiltersItem
          key={filterKey}
          filterKey={filterKey}
          value={filters[filterKey]}
          onRemove={(filterKeyToRemove) => removeFilter(view, filterKeyToRemove, filters)}
          onChange={(newFilter) => navigateWithNewFilter({ [filterKey]: newFilter })}
          labelOptions={labelOptions}
          isMobileUp={isMobileUp}
        />
      ))}
    </Row>
  );
}
