import { connect } from 'react-redux';
import { FolderService, PricingPlanFeaturesService } from '@premagic/core';

import {
  projectsSelector,
  totalSharedFolderWithFolderTypeForProjectSelector,
} from '../../projects/AccountProjectsDataDuck';
import { projectMetaDataForProjectSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';
import AIPhotoDeliveryConfirmationModal from './AIPhotoDeliveryConfirmationModal';
import { hasFeatureSelector, remainingAiDeliveryPhotosSelector } from '../../settings/company/CompanyDataDuck';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    project: projectsSelector(state)[projectId],
    projectId,
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    isStaffUser: isStaffUserSelector(state),
    hasAiDeliveryFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY)(state),
    hasExpressAiDeliveryFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_EXPRESS_DELIVERY,
    )(state),
    totalHighlightFolderShared: totalSharedFolderWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.HIGHLIGHT,
    )(state),
    remainingAiDeliveryPhotos: remainingAiDeliveryPhotosSelector(state),
  };
}

export default connect(mapStateToProps)(AIPhotoDeliveryConfirmationModal);
