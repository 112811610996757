/*
We use react-table for our table component
https://react-table.js.org/api/useTable
 */

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ClassNames from 'classnames';
import {
  ColumnInstance,
  useBlockLayout,
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { InView } from 'react-intersection-observer';
import { CsvUtils, CurrencyUtils, SimpleDateUtils } from '@premagic/utils';

import { Button, BUTTON_SIZE, BUTTON_STYLES, ButtonIcon } from '../Button/Buttons';
import { Col, Row, Space } from '../Grid/Grid';
import { HTML_ICON, Icon, ICON_SIZES } from '../Icon/Icons';
import { Tooltip } from '../Tooltip/Tooltip';
import { Dialog, DIALOG_SIZES } from '../Dialog/Dialog';
import { SubTitle, Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';

import {
  getInitialStateFilters,
  getOrderingString,
  getSelectedEntitiesFromRowIndexes,
  handleOnClickRow,
  ROW_SELECTION_COLUMN_ID,
  TABLE_CELL_TYPES,
  TableCellProps,
  TableColumnType,
  TableDataType,
  TableFiltersProps,
  TableStateType,
} from './tableUtils';
import styles from './table.module.css';
import { ErrorBoundary } from '../Error/Error';
import { LoadingSpin } from '../Loading/Loading';
import { Color, COLOR_SHADES } from '../Color/Color';

const TABLE_SIZE = 25;

interface IndeterminateCheckboxProps {
  indeterminate: any;
}

const IndeterminateCheckbox = forwardRef((props: IndeterminateCheckboxProps, ref: any) => {
  const { indeterminate, ...rest } = props;
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return <input type="checkbox" ref={resolvedRef} {...rest} />;
  // return <Checkbox ref={resolvedRef} {...rest} />;
});

interface LoadMoreComponentProps {
  isLoading: boolean;
  onFetch: () => void;
}

function LoadMoreComponent(props: LoadMoreComponentProps) {
  const { isLoading, onFetch } = props;
  if (isLoading)
    return (
      <div>
        <Space vertical />
        <Row center>
          <LoadingSpin />
        </Row>
        <Space vertical size={60} />
      </div>
    );
  return (
    <InView
      as="div"
      onChange={(inView) => {
        if (inView) onFetch();
      }}
    >
      <Space vertical />
      <Row center>
        <Button size={BUTTON_SIZE.SM} style={BUTTON_STYLES.LINK} onClick={onFetch}>
          Load more...
        </Button>
      </Row>
      <Space vertical size={60} />
    </InView>
  );
}

export function TableCell(props: TableCellProps) {
  const {
    cell: { value },
    column: { type = TABLE_CELL_TYPES.TEXT, CellComponent },
  } = props;
  let displayValue = value;
  if (value === null || value === undefined || value.length === 0) {
    return (
      <Text muted boldness={TEXT_BOLDNESS.BOLD}>
        {HTML_ICON.DASH_SMALL}
      </Text>
    );
  }
  switch (type) {
    case TABLE_CELL_TYPES.CUSTOM:
      if (!CellComponent) return <div>Specify `CellComponent` for the column</div>;

      return (
        <ErrorBoundary>
          <CellComponent value={value} />
        </ErrorBoundary>
      );

    case TABLE_CELL_TYPES.DATE:
      return (
        <Tooltip message={SimpleDateUtils.fromNowForTooltip(value)}>
          <Text ellipsis>{SimpleDateUtils.humanizeDate(value, true)}</Text>
        </Tooltip>
      );
    case TABLE_CELL_TYPES.PERCENTAGE:
      return (
        <Tooltip message={`${value}%`}>
          <Text ellipsis>
            {value}
            <Text size={TEXT_SIZE.SIZE_5} muted>
              %
            </Text>
          </Text>
        </Tooltip>
      );

    case TABLE_CELL_TYPES.NUMBER:
      return (
        <Tooltip message={value}>
          <Text>{value}</Text>
        </Tooltip>
      );
    case TABLE_CELL_TYPES.CURRENCY:
      displayValue = CurrencyUtils.getCurrencyInFormat(Number(value), 'USD');
      return (
        <Tooltip message={displayValue}>
          <Text ellipsis>{displayValue}</Text>
        </Tooltip>
      );
    case TABLE_CELL_TYPES.BOOLEAN:
      return (
        <div>
          <Tooltip message={value ? 'Active' : 'Inactive'} placement="left">
            <Color shade={value ? COLOR_SHADES.BLUE : COLOR_SHADES.LIGHTER}>
              <Icon name={value ? 'check_circle' : 'x_circle'} size={ICON_SIZES.SM} />
            </Color>
          </Tooltip>
        </div>
      );

    case TABLE_CELL_TYPES.TEXT:
    default:
      return (
        <Text ellipsis muted={!value}>
          {value}
        </Text>
      );
  }
}

interface TableHeaderCellProps {
  column: {
    title?: string;
    info?: string;
    getHeaderProps: () => any;
    getSortByToggleProps: () => any;
    getResizerProps: () => any;
    isResizing: boolean;
    isSorted: boolean;
    isSortedDesc: boolean;
    render: (component: string) => any;
  };
}

export function TableHeaderCell(props: TableHeaderCellProps) {
  const {
    column: {
      title,
      info,
      getHeaderProps,
      getSortByToggleProps,
      isSorted,
      isSortedDesc,
      render,
      getResizerProps,
      isResizing,
    },
  } = props;
  if (!title) {
    return (
      <div {...getHeaderProps()} className={styles['table__cell-header']}>
        {render('Header')}
      </div>
    );
  }

  return (
    <div {...getHeaderProps()} className={styles['table__cell-header']}>
      <Row {...getSortByToggleProps()} fullHeight vcenter title={title} positionRelative={false}>
        <Text ellipsis boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          {title}
        </Text>
        <Space size={1} />
        {info && (
          <Tooltip message={info} placement="bottom">
            <Row>
              <Icon name="info" size={ICON_SIZES.SM} />
            </Row>
          </Tooltip>
        )}
        {isSorted && (
          <div className={styles['table__cell-header__sort-icon']}>
            <Icon size={ICON_SIZES.SM} name={isSortedDesc ? 'arrow_down' : 'arrow_up'} />
          </div>
        )}
      </Row>
      <div
        {...getResizerProps()}
        className={ClassNames(styles['table__cell-header__resizer'], {
          [styles['table__cell-header__resizer--active']]: isResizing,
        })}
      />
    </div>
  );
}

interface TableHeaderProps {
  headerGroups: any;
}

function TableHeader(props: TableHeaderProps) {
  const { headerGroups } = props;
  return (
    <div>
      {headerGroups.map((headerGroup) => (
        <div
          {...headerGroup.getHeaderGroupProps()}
          className={ClassNames(styles.table__row, styles['table__row--header'])}
        >
          {headerGroup.headers.map((column) => (
            <TableHeaderCell {...column.getHeaderProps()} column={column} />
          ))}
        </div>
      ))}
    </div>
  );
}

interface TableRowProps {
  row: any;
  onRowClick?: (rowId: number) => void;
}

function TableRow(props: TableRowProps) {
  const { row, onRowClick } = props;
  return (
    <div
      {...row.getRowProps()}
      className={ClassNames(styles.table__row, {
        [styles['table__row--clickable']]: onRowClick,
      })}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => handleOnClickRow(e, row, onRowClick)}
      onClick={(e) => handleOnClickRow(e, row, onRowClick)}
    >
      {row.cells.map((cell) => (
        <div {...cell.getCellProps()} className={styles.table__cell}>
          {cell.render('Cell')}
        </div>
      ))}
    </div>
  );
}

interface TableBodyType {
  getTableBodyProps: () => any;
  page: any;
  prepareRow: (row: any) => any;
  onRowClick?: (rowId: number) => void;
}

function TableBody(props: TableBodyType) {
  const { getTableBodyProps, page, prepareRow, onRowClick } = props;
  return (
    <div {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        return <TableRow {...row.getRowProps()} row={row} onRowClick={onRowClick} />;
      })}
    </div>
  );
}

interface ManageColumnsType {
  allColumns: Array<ColumnInstance<TableColumnType>>;
}

function ManageColumns(props: ManageColumnsType) {
  const { allColumns } = props;
  const target = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  return (
    <div onMouseLeave={() => setShowDialog(false)}>
      <span ref={target} onMouseEnter={() => setShowDialog(true)}>
        <Button style={BUTTON_STYLES.SECONDARY} size={BUTTON_SIZE.SM}>
          <Icon name="columns" size={ICON_SIZES.SM} /> <Icon name="chevron_down" size={ICON_SIZES.SM} />
        </Button>
      </span>
      <Dialog target={target.current} show={showDialog} size={DIALOG_SIZES.SM} onClose={() => setShowDialog(false)}>
        <SubTitle>Displayed columns</SubTitle>
        <ErrorBoundary>
          {allColumns
            .filter((column) => column.id !== ROW_SELECTION_COLUMN_ID)
            .map((column) => {
              // @ts-ignore
              const title = 'title' in column ? column.title : column.id;
              return (
                <div key={column.id}>
                  <IndeterminateCheckbox {...column.getToggleHiddenProps()}>{title}</IndeterminateCheckbox>
                </div>
              );
            })}
        </ErrorBoundary>
      </Dialog>
    </div>
  );
}

interface TableActionsProps extends ManageColumnsType {
  children?: React.ReactNode;
  allColumns: Array<ColumnInstance<TableColumnType>>;
}

function TableActions(props: TableActionsProps) {
  const { allColumns, children } = props;
  return (
    <>
      <Row vcenter>
        <Col>{children}</Col>
        <Col rightAlighed size={null}>
          <ManageColumns allColumns={allColumns} />
        </Col>
      </Row>
      <Space vertical size={2} />
    </>
  );
}

interface TablePaginationProps {
  tableInstance: any;
  tableState: TableStateType;
}

function TablePagination(props: TablePaginationProps) {
  const { tableInstance, tableState } = props;
  const { previousPage, canPreviousPage, nextPage, canNextPage, pageOptions } = tableInstance;
  const { pageIndex } = tableState;

  return (
    <div className={ClassNames(styles.table__pagination)}>
      <Row vcenter>
        <ButtonIcon onClick={previousPage} disabled={!canPreviousPage} title="Previous page">
          <Icon name="chevron_left" size={ICON_SIZES.SM} />
        </ButtonIcon>
        <Space size={2} />
        <Text>
          {pageIndex + 1} of {pageOptions.length}
        </Text>
        <Space size={2} />
        <ButtonIcon onClick={nextPage} disabled={!canNextPage} title="Next page">
          <Icon name="chevron_right" size={ICON_SIZES.SM} />
        </ButtonIcon>
      </Row>
    </div>
  );
}

/**
 * requestDataWithFilter - is called when sorting on the table is changed, we can navigate the with new filters(sort filter)
 * tableFilters - Used to prefil the sorting state of the table
 */
interface TableProps {
  name: string;
  columns: Array<TableColumnType>;
  data: TableDataType;
  // Table sorting
  tableFilters: Partial<TableFiltersProps>;
  requestDataWithFilter: (SortValues?: TableFiltersProps) => void;
  // Others
  showSelectCheckboxOnRows?: boolean;
  onRowClick?: (rowId: number) => void;
  onRowCheckboxSelection?: (dataIds: Array<string>) => void;
  tableActionsLeftContent?: React.ReactNode;
  onTablePreferenceChange?: (data: { width: Record<string, number>; hiddenColumns: Array<string> }) => void;
  selectedIds?: Array<string>;
  allowDownloadCSV?: boolean;
}

export function Table(props: TableProps) {
  const {
    name,
    columns,
    data,
    showSelectCheckboxOnRows,
    onRowClick,
    onRowCheckboxSelection,
    onTablePreferenceChange = () => {},
    tableFilters,
    requestDataWithFilter,
    tableActionsLeftContent,
    selectedIds,
    allowDownloadCSV,
  } = props;

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 40,
      width: 160,
      maxWidth: 500,
      Cell: TableCell,
      Header: TableCell,
    }),
    [],
  );
  const tableOptions: any = {
    columns,
    data,
    defaultColumn,
    manualSortBy: false,
    initialState: getInitialStateFilters(data, { tableFilters, columns, selectedIds, pageSize: TABLE_SIZE }),
  };

  const TableInstance = useTable<any>(
    tableOptions,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,
    // (hooks) => {
    // if (!showSelectCheckboxOnRows) return;
    // hooks.visibleColumns.push((visibleColumns) => [
    //   {
    //     id: ROW_SELECTION_COLUMN_ID,
    //     // Make this column a groupByBoundary. This ensures that groupBy columns
    //     // are placed after it
    //     groupByBoundary: true,
    //     // The header can use the table's getToggleAllRowsSelectedProps method
    //     // to render a checkbox
    //     Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => (
    //       <div>
    //         <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //       </div>
    //     ),
    //     // The cell can use the individual row's getToggleRowSelectedProps method
    //     // to the render a checkbox
    //     Cell: ({ row }: { row: any }) => (
    //       <div>
    //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //       </div>
    //     ),
    //     width: 10,
    //   },
    //   ...visibleColumns,
    // ]);
    // },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    allColumns,
    state: tableState,
  } = TableInstance as any;
  // @ts-ignore
  const { selectedRowIds, sortBy, columnResizing, hiddenColumns } = tableState as Partial<TableStateType<any>>;

  // const handleUpdateOfTablePreference = useCallback(
  //   debounce(() => {
  //     // Existing columns widths is required as columnResizing.columnWidths will contain only the new widths which are changed
  //     const existingColumnWidths = columns.reduce(
  //       (result, col) => ({ ...result, [col.accessor as string]: col.width }),
  //       {},
  //     );
  //
  //     onTablePreferenceChange?.({
  //       hiddenColumns,
  //       width: {
  //         ...existingColumnWidths,
  //         ...columnResizing.columnWidths,
  //       },
  //     });
  //   }, 1000),
  //   [hiddenColumns, columnResizing],
  // );

  // Handle callback on change of hidden columns
  // Handle callback on width change of columns
  // useEffect(() => {
  //   handleUpdateOfTablePreference();
  // }, [columnResizing, hiddenColumns]);

  useEffect(() => {
    const selectedId = getSelectedEntitiesFromRowIndexes(data, selectedRowIds);
    onRowCheckboxSelection?.(selectedId);
  }, [selectedRowIds]);

  useDeepCompareEffect(() => {
    if (sortBy.length > 0) {
      // @ts-ignore
      const ordering = getOrderingString({ sortBy });
      // @ts-ignore
      requestDataWithFilter({ ordering });
    }
  }, [sortBy]);

  if (page.length === 0) {
    return (
      <>
        {/* <TableActions allColumns={allColumns}>{tableActionsLeftContent}</TableActions> */}
        {/* <Space vertical /> */}
        <Space>
          <Text muted block center>
            There is nothing here
          </Text>
        </Space>
      </>
    );
  }

  return (
    <>
      {/* <TableActions allColumns={allColumns}>{tableActionsLeftContent}</TableActions> */}
      <div {...getTableProps()} className={styles.table}>
        <TableHeader headerGroups={headerGroups} />
        <TableBody getTableBodyProps={getTableBodyProps} page={page} prepareRow={prepareRow} onRowClick={onRowClick} />
      </div>
      <Space vertical />
      <Row>
        <Space />
        {allowDownloadCSV && (
          <Button style={BUTTON_STYLES.LINK} onClick={() => CsvUtils.createCSVFile(data, name, columns)}>
            <Icon name="download" size={ICON_SIZES.SM} />
            <Space size={1} />
            Download as CSV
          </Button>
        )}
        <Col size={null} rightAlighed>
          <TablePagination tableInstance={TableInstance} tableState={tableState} />
        </Col>
      </Row>
      <Space vertical />
    </>
  );
}
