import { Map as IMap } from 'immutable';
import { isArray } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { ErrorDuck, LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { ClientWebsiteService, EventTrackerService, Schemas } from '@premagic/core';
import { ActionTypeUtils, ArrayUtils, SimpleDateUtils } from '@premagic/utils';

import { pagesSelector } from '../../../reducers/selectors';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWebsitePageSelector, setEditOfItinerary, setLastItineraryEndDate } from '../ClientWebsitePageDuck';
import { toastMessage } from '../../../reducers/ToastStore';

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_WEBSITE_ITINERARY');

type ClientWebsiteItineraryType = IMap<string, ClientWebsiteService.ClientWebsiteItinerary>;

export const setClientWebsiteItineraryData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addClientWebsiteItinerary = createAction(
  getActionType('DATA', 'ADD'),
  (
    dispatch,
    options: {
      websiteId: string;
      projectId: string;
      itineraries: ClientWebsiteItineraryType;
    },
    formResponse: FormResponseType & {
      data: ClientWebsiteService.ClientWebsiteItinerary;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE_ITINERARY.ADD;
    const { websiteId, projectId, itineraries } = options;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    const { speakers = [] } = data;
    dispatch(
      setLastItineraryEndDate(dispatch, {
        date: data.end_date_time || data.start_date_time,
        timezone: data.date_timezone as string,
      }),
    );
    // get the max id from the existing itineraries and add 1 to it
    const newItineraryId = itineraries.reduce((maxId, itinerary) => Math.max(maxId, itinerary.id), 0) + 1;
    const allItineraries = itineraries
      .merge({
        [newItineraryId]: {
          ...data,
          speakers: isArray(speakers) ? speakers : [speakers],
          id: newItineraryId,
        },
      })
      .valueSeq()
      .toArray();

    ClientWebsiteService.updateClientWebsiteItineraries(websiteId, projectId, allItineraries)
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CLIENT_WEBSITE.ADD_ITINERARY);
        const normalizedData = normalize(response, Schemas.ClientWebsiteItinerariesSchema);
        dispatch(setClientWebsiteItineraryData(dispatch, normalizedData.entities.itineraries));

        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));

        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(
            dispatch,
            LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_ADD_ITINERARY_PANEL,
            false,
          ),
        );
        toastMessage('success', 'Session added');
        setTimeout(() => {
          dispatch(
            WindowPanelDuck.toggleWindowPanelVisibility(
              dispatch,
              LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_ADD_ITINERARY_PANEL,
              true,
            ),
          );
        }, 1);
        return response;
      })
      .catch((response) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, response.data));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const updateClientWebsiteItinerary = createAction(
  getActionType('DATA', 'UPDATE'),
  (
    dispatch,
    options: {
      websiteId: string;
      projectId: string;
      itineraries: ClientWebsiteItineraryType;
      itineraryId: string;
    },
    formResponse: FormResponseType & {
      data: ClientWebsiteService.ClientWebsiteItinerary;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE_ITINERARY.UPDATE;
    const { websiteId, projectId, itineraries, itineraryId } = options;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    const { speakers = [] } = data;
    const allItineraries = itineraries
      .update(String(itineraryId), (itinerary) => ({
        ...itinerary,
        ...data,
        speakers: isArray(speakers) ? speakers : [speakers],
      }))
      .valueSeq()
      .toArray();

    ClientWebsiteService.updateClientWebsiteItineraries(websiteId, projectId, allItineraries)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ClientWebsiteItinerariesSchema);
        dispatch(setClientWebsiteItineraryData(dispatch, normalizedData.entities.itineraries));

        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));

        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(
            dispatch,
            LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_EDIT_ITINERARY_PANEL,
            false,
          ),
        );
        setEditOfItinerary(dispatch, null);
        return response;
      })
      .catch((response) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, response.data));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const removeClientWebsiteItinerary = createAction(
  getActionType('DATA', 'REMOVE'),
  (
    dispatch,
    options: {
      websiteId: string;
      projectId: string;
      itineraries: ClientWebsiteItineraryType;
      itineraryId: string;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE_ITINERARY.REMOVE;
    const { websiteId, projectId, itineraries, itineraryId } = options;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    const allItineraries = itineraries.remove(String(itineraryId)).valueSeq().toArray();

    ClientWebsiteService.updateClientWebsiteItineraries(websiteId, projectId, allItineraries)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ClientWebsiteItinerariesSchema);
        dispatch(setClientWebsiteItineraryData(dispatch, normalizedData.entities.itineraries));

        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));

        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(
            dispatch,
            LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_EDIT_ITINERARY_PANEL,
            false,
          ),
        );
        return response;
      })
      .catch((response) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, response.data));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

type StateType = {
  items: ClientWebsiteItineraryType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setClientWebsiteItineraryData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const clientWebsiteItineraryDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.clientWebsite.data.itinerary as StateType,
);

export const clientWebsiteItineraryItemsSelector = createSelector(clientWebsiteItineraryDataSelector, (state) =>
  state.items.sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.start_date_time, b.start_date_time, false)),
);

export const clientWebsiteItinerarySelector = createSelector(clientWebsiteItineraryItemsSelector, (items) =>
  items.toJSON(),
);

export const itineraryForSpeakerSelector = (attendeeId) =>
  createSelector(clientWebsiteItineraryItemsSelector, (items) =>
    items
      .filter((item) => item.speakers?.includes(attendeeId))
      .valueSeq()
      .toArray(),
  );

export const clientWebsiteTotalItinerarySelector = createSelector(
  clientWebsiteItineraryItemsSelector,
  (items) => items.size,
);
export const clientWebsiteItineraryLastItemSelector = createSelector(clientWebsiteItineraryDataSelector, (state) =>
  state.items.last(),
);
export const clientWebsiteItineraryGroupedSelector = createSelector(
  clientWebsiteItineraryItemsSelector,
  (items) =>
    items
      .valueSeq()
      .groupBy((item) => SimpleDateUtils.humanizeDateSimple(item.start_date_time))
      .toJS() as Record<string, Array<ClientWebsiteService.ClientWebsiteItinerary>>,
);

export const lastItineraryEndDateSelector = createSelector(
  clientWebsitePageSelector,
  clientWebsiteItineraryLastItemSelector,
  (page, lastItem) => {
    if (page.lastItineraryEndDate) {
      return page.lastItineraryEndDate;
    }
    if (lastItem) {
      return {
        date: lastItem.end_date_time || lastItem.start_date_time,
        timezone: lastItem.date_timezone,
      };
    }
    return undefined;
  },
);
