import { connect } from 'react-redux';
import { EventAttendeesService } from '@premagic/core';
import { addOnSelector, eventAttendeesSelector } from '../EventAttendeesDataDuck';
import EventAttendeeForm from './EventAttendeeForm';
import { attendeeRegistrationSendNotificationRequiredSelector } from '../../../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    attendeeId?: string;
    projectId?: string;
    onChangeOfFile: (file?: File | null) => void;
    attendee?: EventAttendeesService.EventAttendeeType;
  },
) {
  const { attendeeId, attendee, projectId } = ownProps;
  return {
    attendeeId,
    projectId,
    attendee: attendeeId ? eventAttendeesSelector(state)[attendeeId] : attendee,
    attendeeAddOns: addOnSelector(state),
    attendeeRegistrationSendNotificationRequired: projectId
      ? attendeeRegistrationSendNotificationRequiredSelector(projectId)(state)
      : undefined,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeForm);
