import React from 'react';
import {
  Card,
  Col,
  Color,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  Link,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { isString, isUndefined } from 'lodash';
import { ClientSettingsService, FeatureToggleService, PremagicResources } from '@premagic/core';
import APP_URLS from '../../services/AppRouteURLService';

type SettingItemProps = {
  title: string;
  description: string;
  link: string;
  iconName: string;
  isExternalLink?: boolean;
  enabled?: boolean;
  featureStatus?: boolean | string;
};

function getFeatureStatus(featureStatus?: boolean | string) {
  if (isUndefined(featureStatus)) return null;
  if (isString(featureStatus)) return featureStatus;
  if (featureStatus) return null;
  return 'Disabled';
}
function SettingItem(props: SettingItemProps) {
  const { iconName, title, description, link, isExternalLink, enabled, featureStatus } = props;
  return (
    <div>
      <Link to={link} isExternalLink={isExternalLink}>
        <Row>
          <Col size={null}>
            <Space vertical size={1} />
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name={iconName} />
            </Color>
          </Col>
          <Space />
          <Col size={10}>
            <Row alignItems="baseline">
              <Text size={TEXT_SIZE.SIZE_3} block color={COLOR_SHADES.BLUE_LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
                {title}
              </Text>
              {getFeatureStatus(featureStatus) && (
                <>
                  <Space size={1} />
                  <Text muted size={TEXT_SIZE.SIZE_5}>
                    {HTML_ICON.DOT}
                  </Text>
                  <Space size={1} />
                  <Text color={COLOR_SHADES.DARK} size={TEXT_SIZE.SIZE_5}>
                    {getFeatureStatus(featureStatus)}
                  </Text>
                </>
              )}
            </Row>

            <Text>{description}</Text>
          </Col>
        </Row>
      </Link>
      <Space vertical size={8} />
    </div>
  );
}

function getSettingsLinks(options: {
  hasProposal: boolean;
  clientSettings: ClientSettingsService.ClientSettingsType;
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
}): Array<SettingItemProps> {
  const { clientSettings, hasProposal, companyType } = options;
  const companyTypeDetails = ClientSettingsService.CLIENT_COMPANY_TYPE_DETAILS[companyType];
  const isLeadEnabled = !clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_LEADS_DISABLE];

  function hasFeature(featureKey: ClientSettingsService.CLIENT_SETTINGS_META_TYPES) {
    return featureKey in clientSettings ? (clientSettings[featureKey] as boolean) : true;
  }

  let SETTINGS: Array<SettingItemProps> = [
    {
      title: 'Company profile',
      description: 'Manage your company information, Set up your company logo and social profiles',
      iconName: 'star',
      link: APP_URLS.SETTINGS.PROFILE.DETAILS,
      enabled: true,
    },
    {
      title: 'Branding',
      featureStatus: companyTypeDetails.title,
      description: 'Manage your brand settings',
      iconName: 'bold',
      link: APP_URLS.SETTINGS.BRANDING.HOME,
      enabled: true,
    },
    {
      title: 'Account Settings',
      description: 'Manage account setting, Set up account currency',
      iconName: 'archive',
      link: APP_URLS.SETTINGS.ACCOUNT.BASIC,
      enabled: true,
    },
    {
      title: 'Users',
      description: 'Manage all the users in Premagic',
      iconName: 'users',
      link: APP_URLS.SETTINGS_USERS.USERS,
      enabled: true,
    },
    {
      title: 'Notifications',
      description: 'Manage email & whatsapp notifications send from your account',
      iconName: 'bell',
      link: APP_URLS.SETTINGS.NOTIFICATIONS,
      enabled: true,
    },
    {
      title: 'Event Settings',
      description: 'Manage events settings',
      iconName: 'event',
      link: APP_URLS.SETTINGS.EVENTS.ROOT,
      enabled: true,
    },
  ];
  if (
    [ClientSettingsService.CLIENT_COMPANY_TYPES.EVENT, ClientSettingsService.CLIENT_COMPANY_TYPES.ORGANIZER].includes(
      companyType,
    )
  ) {
    SETTINGS = SETTINGS.concat([
      {
        title: 'Event Marketing',
        description: 'Manage your event marketing settings',
        iconName: 'magaphone',
        link: APP_URLS.SETTINGS.EVENT_MARKETING.ROOT,
        featureStatus: hasFeature(
          ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_EVENT_MARKETING_POSTERS_ENABLE,
        ),
        enabled: true,
      },
      {
        title: 'Library Settings',
        description: 'Manage all your digital assets in one place',
        iconName: 'book_open',
        link: APP_URLS.SETTINGS.DAM_LIBRARY.ROOT,
        enabled: true,
      },
      {
        title: 'Gallery',
        description: 'Manage your preference on Gallery',
        iconName: 'folder_simple',
        link: APP_URLS.SETTINGS.SIGNATURE,
        enabled: true,
      },
      {
        title: 'Gallery Design',
        description: 'Set default design settings for your Gallery',
        iconName: 'signature_album',
        link: APP_URLS.SETTINGS.SIGNATURE_ALBUM_DESIGN,
        enabled: true,
      },
      {
        title: 'Photo Distribution',
        description: 'Manage your preference on photo distribution',
        iconName: 'glob',
        link: APP_URLS.SETTINGS.PHOTO_DISTRIBUTION,
        enabled: true,
      },
    ]);
  }
  if ([ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY].includes(companyType)) {
    SETTINGS = SETTINGS.concat([
      {
        title: 'Lead Management',
        featureStatus: isLeadEnabled,
        description: 'Generate Leads and Manage them',
        iconName: 'trello',
        link: APP_URLS.SETTINGS.LEADS.ROOT,
        enabled: true,
      },
      {
        title: 'Event Financials',
        featureStatus: hasFeature(ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE),
        description: 'Manage your event financials',
        iconName: 'dollar_sign',
        link: APP_URLS.SETTINGS.FINANCIALS.ROOT,
        enabled: true,
      },
      {
        title: 'Proposal Management',
        featureStatus: hasFeature(ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROPOSAL_ENABLE),
        description: 'Manage proposal template and variables',
        iconName: 'film',
        link: APP_URLS.PROPOSALS_TEMPLATES.LIST,
        enabled: hasProposal,
      },
      {
        title: 'Signature album',
        description: 'Manage your preference on signature album',
        iconName: 'folder_simple',
        link: APP_URLS.SETTINGS.SIGNATURE,
        enabled: true,
      },
      {
        title: 'Selection album',
        description: 'Manage your preference on selection album',
        iconName: 'folder_simple',
        link: APP_URLS.SETTINGS.SELECTION,
        enabled: true,
        featureStatus: hasFeature(ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_SELECTION_ALBUM),
      },
      {
        title: 'Signature album Design',
        description: 'Set default design settings for your Signature albums',
        iconName: 'signature_album',
        link: APP_URLS.SETTINGS.SIGNATURE_ALBUM_DESIGN,
        enabled: true,
      },
      {
        title: 'Store Pricing',
        description: 'Manage product pricing',
        iconName: 'shopping_cart',
        link: APP_URLS.SETTINGS.STORE_PRICING.DEFAULT,
        enabled: FeatureToggleService.isToggleableFeatureEnabledForClient(
          FeatureToggleService.FEATURES_TOGGLES.STORE_PRICING,
          true,
        ),
      },
    ]);
    //
    // {
    //   title: 'Workflow Template',
    //   description: 'Manage workflow template',
    //   iconName: 'template',
    //   link: APP_URLS.SETTINGS_WORKFLOW.WORKFLOW,
    //   enabled: FeatureToggleService.isToggleableFeatureEnabledForClient(
    //     FeatureToggleService.FEATURES_TOGGLES.WORKFLOW,
    //     true,
    //   ),
    // },
  }
  return SETTINGS.concat([
    {
      title: 'Premagic Desktop App',
      description: 'Upload photos with Desktop app',
      iconName: 'download',
      link: PremagicResources.RESOURCES.download('account-app'),
      enabled: true,
      isExternalLink: true,
    },
    {
      title: 'Subscriptions',
      description: 'Manage your premagic subscription',
      iconName: 'box',
      link: APP_URLS.SETTINGS.SUBSCRIPTIONS,
      enabled: true,
    },
  ]);
}

type Props = {
  isMobileUp: boolean;
  hasProposal: boolean;
  clientSettings: ClientSettingsService.ClientSettingsType;
};

export default function SettingsPage(props: Props): JSX.Element {
  const { isMobileUp, hasProposal, clientSettings } = props;
  const companyType =
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.COMPANY_TYPE] ||
    ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY;
  const SETTINGS = getSettingsLinks({ companyType, clientSettings, hasProposal });
  return (
    <InnerPage>
      <InnerPageHeader iconName="settings" title="Settings">
        Settings
      </InnerPageHeader>
      <InnerPageContent>
        <Card>
          <Space size={8}>
            <Col size={12}>
              {SETTINGS.filter((setting) => setting.enabled).map((setting) => (
                <SettingItem
                  key={setting.title}
                  title={setting.title}
                  description={setting.description}
                  iconName={setting.iconName}
                  link={setting.link}
                  isExternalLink={setting.isExternalLink}
                  featureStatus={setting.featureStatus}
                />
              ))}
            </Col>
          </Space>
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
