export enum REPORT_ISSUE_GROUP_TYPES {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  OLDER = 'older',
}

export const REPORT_ISSUE_GROUP_DETAILS: Record<string, { id: REPORT_ISSUE_GROUP_TYPES; name: string }> = {
  [REPORT_ISSUE_GROUP_TYPES.TODAY]: { id: REPORT_ISSUE_GROUP_TYPES.TODAY, name: 'Today' },
  [REPORT_ISSUE_GROUP_TYPES.YESTERDAY]: { id: REPORT_ISSUE_GROUP_TYPES.YESTERDAY, name: 'Yesterday' },
  [REPORT_ISSUE_GROUP_TYPES.OLDER]: { id: REPORT_ISSUE_GROUP_TYPES.OLDER, name: 'Older' },
};
