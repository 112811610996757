import { HttpUtils } from '@premagic/utils';
import { APIURLService, EventsService, LabelService } from '@premagic/core';
import qs from 'qs';
import { PAGINATION_SIZE } from '../../../services/PaginationService';

export type EventsListEventType = {
  client: {
    id: string;
    name: string;
  };
  id: string;
  number?: string;
  name: EventsService.EVENT_CATEGORY | string;
  start_date: string;
  end_date: string;
  created_at: string;
  service_providers: Array<string>;
  occasions: Array<string>;
  total_amount: number;
  paid_amount: number;
  notes: string;
  location: string;
  postal_code: string;
  status: EventsService.EVENT_STATUS;
  labels: Array<LabelService.LabelsType>;
  priority_value: EventsService.EVENT_PRIORITY;
};

export type EventFiltersType = {
  start_date_gte: string;
  start_date_lte: string;
  end_date_gte: string;
  end_date_lte: string;
  status: EventsService.EVENT_STATUS;
  labels: string;
};

export function fetchEventsForList(
  filters?: EventFiltersType,
): Promise<{ results: Array<EventsListEventType>; count: number }> {
  const options = {
    params: {
      ...filters,
      limit: PAGINATION_SIZE,
    },
  };

  return HttpUtils.get(APIURLService.API_URLS.CRM.EVENTS, options).then((response) => response.data);
}
export function fetchEventCountByStatus(): Promise<Record<EventsService.EVENT_STATUS, number>> {
  return HttpUtils.get(APIURLService.API_URLS.CRM.EVENTS_COUNTS_BY_STATUS).then((response) => response.data);
}
