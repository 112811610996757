import {
  ClientAppServices,
  ClientWebsiteService,
  EventAttendeesService,
  EventCustomFromFieldsService,
  FolderService,
  PeopleFacesService,
} from '@premagic/core';

import {
  Avatar,
  AVATAR_SIZES,
  AVATAR_STYLES,
  Button,
  BUTTON_ICON_STYLES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  Color,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  LIST_ITEM_ACTION_STYLES,
  ListItem,
  ListItemAction,
  Row,
  Section,
  SECTION_STYLES,
  SimpleLink,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { ArrayUtils, BrowserUtils, SimpleDateUtils, StringUtils } from '@premagic/utils';
import React, { useEffect, useMemo } from 'react';
import MESSAGES from '../../../../../../common/Messages';
import CustomRegistrationFormFieldsContainer from '../../../event-custom-form-fields/CustomRegistrationFormFieldsContainer';
import { PERMISSIONS } from '../../users/UserPermissionService';
import AttendeeRegistrationApprovalButtonsContainer from './attendee-registration-approval/AttendeeRegistrationApprovalButtonsContainer';
import EventAttendeeOptionsMenuContainer from './dropdown-menu/EventAttendeeOptionsMenuContainer';
import EventAttendeeLinkToMagazineButtonContainer from './EventAttendeeLinkToMagazineButtonContainer';
import EventAttendeeLinkToPosterButtonContainer from './EventAttendeeLinkToPosterButtonContainer';
import EventAttendeeLinkToBadgeButtonContainer from './EventAttendeeLinkToBadgeButtonContainer';

const USER_CONTACTS_META_DETAILS = {
  phone: {
    icon: 'phone',
    id: 'phone',
    getUrl: (phone: string) => `tel:${phone}`,
  },
  email: {
    icon: 'mail',
    id: 'email',
    getUrl: (email: string) => `mailto:${email}`,
  },
};

type UserInfoProps = {
  value?: string;
  item: 'phone' | 'email';
};

function UserInfo(props: UserInfoProps) {
  const { value, item } = props;
  const itemDetails = USER_CONTACTS_META_DETAILS[item];
  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.BLUE} inline>
        <Icon name={itemDetails.icon} size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />
      {value ? (
        <a href={itemDetails.getUrl(value)} target="_blank" rel="noopener noreferrer" data-channel={itemDetails.id}>
          <Text block size={TEXT_SIZE.SIZE_5}>
            {value}
          </Text>
        </a>
      ) : (
        <Text block size={TEXT_SIZE.SIZE_5} muted>
          (blank)
        </Text>
      )}
    </Row>
  );
}
interface AttendeeDetailsProps {
  attendee: EventAttendeesService.EventAttendeeType;
  pIIDataDisplayOption: EventAttendeesService.PII_DATA_OPTIONS;
}

function AttendeeDetails(props: AttendeeDetailsProps) {
  const { attendee, pIIDataDisplayOption } = props;
  const {
    name,
    phone,
    email,
    people_type: type = EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE,
    face_id: faceId,
    best_face_id: bestFaceId,
    custom_fields: customFields,
  } = attendee;

  const rowsToShow = [
    {
      icon: 'mail',
      title: email,
      link: `mailto:${email}`,
    },
    {
      icon: 'phone',
      title: phone,
      link: `tel:${phone}`,
    },
  ];
  const typeDetails = EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS[type];
  const faceUrlToShow =
    customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]?.dynamic_image_url ||
    PeopleFacesService.getPeopleFaceUrl(bestFaceId || faceId);

  const role = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE];
  const company = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY];
  const displayId = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID];

  return (
    <ErrorBoundary>
      <Section style={SECTION_STYLES.BLACK_WITH_WHITE}>
        <Row>
          <Col size={3}>
            <Space vertical size={4} />
            <Avatar
              size={AVATAR_SIZES.LG}
              user={{
                fullName: name,
                profilePic: faceUrlToShow,
              }}
            />
          </Col>
          <Space />
          <Col size={9}>
            <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.WHITE} block ellipsis>
              {name}
            </Text>
            {role && company ? (
              <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.WHITE} block ellipsis>
                {role}, {company}
              </Text>
            ) : (
              <Space vertical size={10} />
            )}
            <Space vertical size={6} />
            <Text block>{typeDetails?.name}</Text>
            <Space vertical size={2} />
            {(() => {
              if (pIIDataDisplayOption === EventAttendeesService.PII_DATA_OPTIONS.DONT_SHOW) return null;
              if (pIIDataDisplayOption === EventAttendeesService.PII_DATA_OPTIONS.NO_CONSENT)
                return (
                  <Text size={TEXT_SIZE.SIZE_5} muted>
                    The user has not given consent to share personal details.
                  </Text>
                );

              // EventAttendeesService.PII_DATA_OPTIONS.SHOW
              return rowsToShow.map((row) => (
                <div key={row.title}>
                  <Row>
                    <Icon name={row.icon} size={ICON_SIZES.SM} />
                    <Space size={2} />
                    {row.link ? (
                      <SimpleLink href={row.link}>
                        <Text size={TEXT_SIZE.SIZE_5} color={row.title ? COLOR_SHADES.BLUE_DARK : COLOR_SHADES.LIGHT}>
                          {row.title || '(blank)'}
                        </Text>
                      </SimpleLink>
                    ) : (
                      <Text size={TEXT_SIZE.SIZE_5}>{row.title}</Text>
                    )}
                  </Row>
                  <Space vertical size={2} />
                </div>
              ));
            })()}
          </Col>
        </Row>
      </Section>
    </ErrorBoundary>
  );
}

const STATUS_ICON_DETAILS: Record<
  EventAttendeesService.EVENT_REGISTRATION_PROCESS,
  {
    info: string;
    icon?: string;
    color?: COLOR_SHADES;
  }
> = {
  [EventAttendeesService.EVENT_REGISTRATION_PROCESS.DONE]: {
    info: 'Attendee received the photos',
    icon: 'check_circle',
    color: COLOR_SHADES.SUCCESS,
  },
  [EventAttendeesService.EVENT_REGISTRATION_PROCESS.ERROR]: {
    info: 'Premagic was unable to share the photos via email or WhatsApp due to incorrect information or some other issue',
    icon: 'slash',
    color: COLOR_SHADES.DANGER,
  },
  [EventAttendeesService.EVENT_REGISTRATION_PROCESS.IN_PROGRESS]: {
    info: '',
  },
  [EventAttendeesService.EVENT_REGISTRATION_PROCESS.PENDING]: {
    info: 'Attendees yet to receive the photos/No Photos of attendee',
  },
};

function PhotoDeliveryStatusIcon(props: {
  status: EventAttendeesService.EVENT_REGISTRATION_PROCESS;
  notificationSuccessPlatform: string;
  showDetails: boolean;
}) {
  const { status, notificationSuccessPlatform = '', showDetails } = props;
  const details = STATUS_ICON_DETAILS[status];
  const notificationSuccessPlatformText = notificationSuccessPlatform
    ? ArrayUtils.listify(
        notificationSuccessPlatform.split(',').map((platform) => StringUtils.toTitleCase(platform, true)),
      )
    : '';
  if (showDetails) {
    if (status === EventAttendeesService.EVENT_REGISTRATION_PROCESS.DONE) {
      return (
        <Text size={TEXT_SIZE.SIZE_5} muted>
          {details.info} {notificationSuccessPlatformText && `via ${notificationSuccessPlatformText}`}
        </Text>
      );
    }
    return (
      <Text size={TEXT_SIZE.SIZE_5} muted>
        {details.info || 'No information available'}
      </Text>
    );
  }

  if (!details.icon) return null;
  if (status === EventAttendeesService.EVENT_REGISTRATION_PROCESS.DONE) {
    return (
      <ButtonIcon
        title={
          status === EventAttendeesService.EVENT_REGISTRATION_PROCESS.DONE
            ? `${details.info} via ${notificationSuccessPlatformText}`
            : details.info
        }
      >
        <Color shade={details.color} inline>
          <Icon name={details.icon} size={ICON_SIZES.SM} />
        </Color>
      </ButtonIcon>
    );
  }

  return (
    <ButtonIcon title={details.info}>
      <Color shade={details.color} inline>
        <Icon name={details.icon} size={ICON_SIZES.SM} />
      </Color>
    </ButtonIcon>
  );
}

function PhotoDeliveryStatusDetails(props: { attendee: EventAttendeesService.EventAttendeeType }) {
  const { attendee } = props;
  const {
    process_status: status,
    notification_success_platform: notificationSuccessPlatform,
    first_notification_send_time: firstNotificationSendTime,
    last_notification_send_time: lastNotificationSendTime,
  } = attendee;
  const firstNotificationSendDate =
    firstNotificationSendTime && SimpleDateUtils.getDateStringISO(new Date(firstNotificationSendTime * 1000));
  const lastNotificationSendDate =
    lastNotificationSendTime && SimpleDateUtils.getDateStringISO(new Date(lastNotificationSendTime * 1000));

  const details = STATUS_ICON_DETAILS[status];

  return (
    <div>
      <Row>
        {details.icon && (
          <>
            <Color shade={details.color} inline>
              <Icon name={details.icon} size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
          </>
        )}
        <SubTitle>Photo Delivery</SubTitle>
      </Row>
      <Space vertical size={1} />
      <PhotoDeliveryStatusIcon status={status} notificationSuccessPlatform={notificationSuccessPlatform} showDetails />
      <Space vertical size={2} />
      <div>
        {firstNotificationSendDate && (
          <Text size={TEXT_SIZE.SIZE_5} muted>
            First notification sent{' '}
            <Tooltip message={SimpleDateUtils.fromNowForTooltip(firstNotificationSendDate)}>
              {SimpleDateUtils.fromNow(firstNotificationSendDate, true)}
            </Tooltip>
          </Text>
        )}
        <Space vertical size={2} />
        {lastNotificationSendDate && (
          <Text size={TEXT_SIZE.SIZE_5} muted>
            Last notification sent{' '}
            <Tooltip message={SimpleDateUtils.fromNowForTooltip(lastNotificationSendDate)}>
              {SimpleDateUtils.fromNow(lastNotificationSendDate, true)}
            </Tooltip>
          </Text>
        )}
      </div>
    </div>
  );
}

type AttendeePIIDataProps = {
  email: string;
  phone: string;
  pIIDataDisplayOption: EventAttendeesService.PII_DATA_OPTIONS;
};

function AttendeePIIData(props: AttendeePIIDataProps) {
  const { email, phone, pIIDataDisplayOption } = props;

  if (pIIDataDisplayOption === EventAttendeesService.PII_DATA_OPTIONS.DONT_SHOW) return null;

  if (pIIDataDisplayOption === EventAttendeesService.PII_DATA_OPTIONS.NO_CONSENT) {
    return (
      <Text size={TEXT_SIZE.SIZE_5} muted>
        The user has not given consent to share personal details.
      </Text>
    );
  }

  // EventAttendeesService.PII_DATA_OPTIONS.SHOW
  return (
    <div>
      <UserInfo item="email" value={email} />
      <Space size={2} vertical />
      <UserInfo item="phone" value={phone} />
    </div>
  );
}

export function getCompleteRegistrationLink(options: {
  clientWebsite?: FolderService.FolderType;
  registrationId: string;
}) {
  const { clientWebsite, registrationId } = options;
  if (!clientWebsite) return null;
  return `${clientWebsite.share_url}${ClientWebsiteService.CLIENT_WEBSITE_LINK.REGISTRATION.COMPLETE_REGISTRATION(registrationId)}`;
}

type Props = {
  attendee: EventAttendeesService.EventAttendeeType;
  projectId: string;
  id: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_ATTENDEES]: boolean;
    [PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]: boolean;
  };
  focus?: boolean;
  guestGalleryPreviewLink?: string;
  showPIIDataFeature: boolean;
  requestForUserDataForMarketing?: boolean;
  listStyle: 'list' | 'detailed';
  hasEnabledNetworkingFeature: boolean;
  hasAttendeePoster: boolean;
  isFeatureMagazineEnabled: boolean;
  isFeatureBadgeEnabled: boolean;
  clientWebsite?: FolderService.FolderType;
};

export default function EventAttendeeItem(props: Props): JSX.Element {
  const {
    attendee,
    id,
    projectId,
    hasPermissions,
    focus,
    guestGalleryPreviewLink,
    showPIIDataFeature,
    requestForUserDataForMarketing,
    listStyle,
    hasEnabledNetworkingFeature,
    hasAttendeePoster,
    isFeatureMagazineEnabled,
    isFeatureBadgeEnabled,
    clientWebsite,
  } = props;
  const {
    registration_id: registrationId,
    name,
    phone,
    email,
    people_type: type = EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE,
    face_id: faceId,
    best_face_id: bestFaceId,
    custom_fields: customFields,
    process_status: status,
    have_consent_to_use_data_for_marketing: hasConsentToUseDataForMarketing,
    have_consent_for_networking: hasConsentForNetworking,
    created_at: createdAt,
    notification_success_platform: notificationSuccessPlatform,
  } = attendee;

  const hasPermissionsToManageAttendees = hasPermissions[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT];
  const typeDetails = EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS[type];
  const faceUrlToShow =
    customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]?.dynamic_image_url ||
    PeopleFacesService.getPeopleFaceUrl(bestFaceId || faceId);

  const registeredDate = SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt);
  const role = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE];
  const company = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY];
  const displayId = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID];

  const [listExpanded, setListExpanded] = React.useState(listStyle === 'detailed');
  useEffect(() => {
    setListExpanded(listStyle === 'detailed');
  }, [listStyle]);

  const galleryLink = useMemo(() => {
    if (guestGalleryPreviewLink && (bestFaceId || faceId))
      return ClientAppServices.getPeopleGalleryUrl(guestGalleryPreviewLink, bestFaceId || faceId);
    return null;
  }, [bestFaceId, faceId, guestGalleryPreviewLink]);

  return (
    <ListItem highlight={focus}>
      <Col size={12}>
        <Row>
          {faceId ? (
            <Avatar
              onClick={() =>
                galleryLink && hasPermissionsToManageAttendees && BrowserUtils.openInNewWindow(galleryLink, '_blank')
              }
              style={AVATAR_STYLES.SECONDARY}
              size={AVATAR_SIZES.SM}
              user={{
                fullName: name,
                profilePic: faceUrlToShow,
              }}
              // dialogContent={
              //   <AttendeeDetails
              //     attendee={attendee}
              //     pIIDataDisplayOption={EventAttendeesService.getPIIDataDisplayOption(
              //       showPIIDataFeature,
              //       requestForUserDataForMarketing,
              //       hasConsentToUseDataForMarketing,
              //     )}
              //   />
              // }
            />
          ) : (
            <Row vcenter columnDirection>
              <Color shade={COLOR_SHADES.DANGER} inline>
                <Icon name="alert_triangle" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} vertical />
              <Text color={COLOR_SHADES.DANGER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_6}>
                Not Set
              </Text>
            </Row>
          )}
          <Space size={4} />
          <Col size={8}>
            <Button onClick={() => setListExpanded(!listExpanded)} style={BUTTON_STYLES.RESET} block>
              <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
                {name}
              </Text>
              {role && <Text block>{role}</Text>}
              {company && (
                <Text color={COLOR_SHADES.DARKER} block>
                  {company}
                </Text>
              )}
            </Button>
            {!faceId && (
              <div>
                <Button
                  style={BUTTON_STYLES.LINK}
                  size={BUTTON_SIZE.XS}
                  link={getCompleteRegistrationLink({ clientWebsite, registrationId })}
                  isExternalLink
                >
                  Complete Registration
                  <Space size={1} />
                  <Icon name="external_link" size={ICON_SIZES.SM} />
                </Button>
              </div>
            )}
          </Col>
        </Row>
        {listExpanded && (
          <div>
            <Space vertical size={2} />
            <Row>
              <Space size={12} />
              <Col size={8}>
                <Text size={TEXT_SIZE.SIZE_5} muted block>
                  {typeDetails?.name || ''} registered
                  <Space size={1} />
                  <Tooltip message={SimpleDateUtils.fromNowForTooltip(registeredDate)}>
                    {SimpleDateUtils.fromNow(registeredDate, true)}
                  </Tooltip>
                </Text>
                <Space vertical size={2} />
                <AttendeePIIData
                  email={email}
                  phone={phone}
                  pIIDataDisplayOption={EventAttendeesService.getPIIDataDisplayOption(
                    showPIIDataFeature,
                    requestForUserDataForMarketing,
                    hasConsentToUseDataForMarketing,
                  )}
                />
              </Col>
              <Space />
              <Col size={4}>
                <PhotoDeliveryStatusDetails attendee={attendee} />
              </Col>
            </Row>
          </div>
        )}
        {listExpanded && <CustomRegistrationFormFieldsContainer data={customFields} />}
        {listExpanded && (
          <div>
            <Space vertical size={4} />
            <Row>
              <Space size={12} />
              {galleryLink && (
                <Button
                  disabled={!hasPermissionsToManageAttendees}
                  disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
                  style={BUTTON_STYLES.LINK}
                  link={galleryLink}
                  isExternalLink
                >
                  <Icon name="external_link" size={ICON_SIZES.SM} />
                  <Space size={1} />
                  Gallery
                </Button>
              )}
              {hasAttendeePoster && <EventAttendeeLinkToPosterButtonContainer id={id} projectId={projectId} />}
              {isFeatureMagazineEnabled && <EventAttendeeLinkToMagazineButtonContainer id={id} projectId={projectId} />}
              {isFeatureBadgeEnabled && <EventAttendeeLinkToBadgeButtonContainer id={id} projectId={projectId} />}
              <Space />
              {hasEnabledNetworkingFeature && (
                <ButtonIcon
                  title={hasConsentForNetworking ? 'Networking consent given' : 'Networking consent not given'}
                  style={BUTTON_ICON_STYLES.SECONDARY}
                >
                  <Color shade={hasConsentForNetworking ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
                    <Icon name={hasConsentForNetworking ? 'users' : 'user_x'} size={ICON_SIZES.SM} />
                  </Color>
                </ButtonIcon>
              )}
            </Row>
          </div>
        )}
      </Col>
      <ListItemAction style={LIST_ITEM_ACTION_STYLES.PRIMARY_TOP_CORNER}>
        <Space size={1} />
        <AttendeeRegistrationApprovalButtonsContainer id={id} projectId={projectId} />
        <Space size={8} />
        <PhotoDeliveryStatusIcon
          status={status}
          notificationSuccessPlatform={notificationSuccessPlatform}
          showDetails={false}
        />
        <ButtonIcon
          title={listExpanded ? 'Collapse' : 'Expand'}
          style={BUTTON_ICON_STYLES.SECONDARY}
          onClick={() => setListExpanded(!listExpanded)}
        >
          <Icon name={listExpanded ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
        </ButtonIcon>

        <EventAttendeeOptionsMenuContainer attendeeId={id} projectId={projectId} />
      </ListItemAction>
    </ListItem>
  );
}
