import { EventTrackerService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
  uploadProjectCoverPhoto,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import ProjectCoverPhoto from './ProjectCoverPhoto';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.COVER_IMAGE;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const projectMetaData = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    coverPhoto: projectMetaData?.cover_image,
    isSaving:
      isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state) ||
      isLoadingSelector(LOADINGS.PROJECT_META.COVER_UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadCoverPhoto: (projectId: string, coverPhoto: File, cordinates) => {
      dispatch(uploadProjectCoverPhoto(dispatch, projectId, coverPhoto, true));
      dispatch(
        saveProjectMetaData(
          dispatch,
          projectId,
          { data: { cover_image_focal_point: cordinates } },
          {
            itemId: META_ITEM,
          },
        ),
      );
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.SETTING_SET_PROJECT_COVER);
    },
    removeCoverPhoto: (projectId) => {
      dispatch(
        saveProjectMetaData(
          dispatch,
          projectId,
          {
            // @ts-ignore
            data: {
              [ProjectMetaService.PROJECT_META_TYPES.COVER_IMAGE]: null,
            },
          },
          {},
        ),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCoverPhoto);
