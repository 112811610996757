import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import {
  eventAttendeesArraySelector,
  getEventAttendeesForProject,
} from '../../crm/events/event-attendees/EventAttendeesDataDuck';
import { damLibraryFilterLabelsByFilterKeySelector } from '../library-dam/DAMLibraryDataDuck';
import { DAM_LIBRARY_FILTER_KEYS } from '../library-dam/filters/DAMLibraryFilterTypes';
import { ImageKeyPeopleSelect } from './ImageKeyPeopleSelect';

type OwnProps = {
  values?: string[];
  projectId: string;
};

function mapStateToProps(state, ownProps: OwnProps) {
  const { values = [], projectId } = ownProps;
  return {
    values,
    projectId,
    attendees: eventAttendeesArraySelector(state),
    allKeyPeople: damLibraryFilterLabelsByFilterKeySelector(DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAttendees: (projectId: string) => {
      dispatch(getEventAttendeesForProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageKeyPeopleSelect);
