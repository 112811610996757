import React, { useState } from 'react';
import {
  ButtonIcon,
  Card,
  Icon,
  ICON_SIZES,
  Input,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils, BrowserUtils } from '@premagic/utils';

type Props = {
  companyUrls: {
    contactCard: string;
    form: string;
    portfolio: string;
    premagicDomainUrl: string;
  };
};

export default function CompanyEnquiryFormPage(props: Props) {
  const [isCopied, showCopiedInfo] = useState(false);

  const { companyUrls } = props;
  const enquireFormUrl = BrowserUrlUtils.getURLWithProtocol(companyUrls.form);
  const codeToEmbed = `<iframe id="lead-from-premagic" src="${enquireFormUrl}" style="border:0px #ffffff none;"name="Lead form Premagic" scrolling="no" frameBorder="0" marginHeight="0px" marginWidth="0px" width="100%"allowFullScreen></iframe><script type="text/javascript"src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({log: false, checkOrigin: false}, '#lead-from-premagic')</script>`;

  return (
    <Card>
      <Space>
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
          Business Card{' '}
        </Text>
        <Text size={TEXT_SIZE.SIZE_3} block>
          <SimpleLink
            href={BrowserUrlUtils.getURLWithProtocol(companyUrls.contactCard)}
            isExternalLink
            showExternalLinkIcon
          >
            {companyUrls.contactCard}
          </SimpleLink>
        </Text>
        <Space vertical size={8} />
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
          Lead Form
        </Text>
        <Text size={TEXT_SIZE.SIZE_3} muted block>
          <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(enquireFormUrl)} isExternalLink showExternalLinkIcon>
            {companyUrls.form}
          </SimpleLink>
        </Text>
        <Space vertical size={8} />
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
          Embed Lead form on your website
        </Text>
        <Text block>Copy paste the below snippet on your website</Text>
        <Space vertical size={1} />
        <Input
          readOnly
          name="code"
          iconPlacement="right"
          buttonIcon={
            <ButtonIcon
              title={isCopied ? 'Copied' : 'Copy'}
              onClick={() => {
                showCopiedInfo(true);
                setTimeout(() => showCopiedInfo(false), 2000);
                BrowserUtils.copyToClipboard(codeToEmbed);
              }}
            >
              <Icon size={ICON_SIZES.SM} name={isCopied ? 'check' : 'copy'} />
            </ButtonIcon>
          }
          defaultValue={codeToEmbed}
        />
        <Space vertical />
        <Text muted>
          Here is an example of a website with form embed <Space vertical size={1} />
          <SimpleLink href="https://premagic-lead-form-.glitch.me/#book" isExternalLink showExternalLinkIcon>
            Open example
          </SimpleLink>{' '}
          <Space vertical size={1} />
          <SimpleLink
            href="https://glitch.com/edit/#!/premagic-lead-form-?path=index.html%3A267%3A3"
            isExternalLink
            showExternalLinkIcon
          >
            Open Source code for the example
          </SimpleLink>
        </Text>
      </Space>
    </Card>
  );
}
