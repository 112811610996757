import { EventsService, IntegrationService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { setModalOptions, toggleModalVisibility } from '../../../../../common/ModalDuck';
import {
  getProjectMetaData,
  integrationEventConfigForProjectSelector,
  isProjectConnectedToIntegrationPlatformSelector,
  projectEventDataSelector,
} from '../../projects/project-meta/ProjectsMetaDataDuck';
import { integrationsEventClientSettingsSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { INTEGRATION_CONNECT_OPTION } from '../integration-modal/IntegrationStepService';
import { syncIntegrationEvent } from '../IntegrationDataDuck';
import IntegrationSyncOrImportMenuItem from './IntegrationSyncOrImportMenuItem';

function mapStateToProps(state, ownProps: { projectId: string; platform: IntegrationService.INTEGRATION_PLATFORMS }) {
  const { projectId, platform } = ownProps;
  return {
    projectId,
    platform,
    isProjectConnectedToIntegrationPlatform: isProjectConnectedToIntegrationPlatformSelector(projectId)(state) || false,
    pmEventData: projectEventDataSelector(projectId)(state),
    integrationEventConfig: integrationEventConfigForProjectSelector(projectId)(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEventsList: (platform: IntegrationService.INTEGRATION_PLATFORMS, projectId: string) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, true));
      dispatch(
        setModalOptions(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, {
          platform,
          connectOption: INTEGRATION_CONNECT_OPTION.CONNECT_AND_SYNC,
          projectId,
        }),
      );
    },
    syncIntegrationEvent: (options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      premagicProjectId: string;
      integrationEventId: string;
      integrationEventData?: IntegrationService.IntegrationPlatformEventType;
      premagicEventData?: EventsService.EventDataType;
    }) => {
      dispatch(syncIntegrationEvent(dispatch, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSyncOrImportMenuItem);
