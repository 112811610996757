import React from 'react';
import { connect } from 'react-redux';
import { ProductFeedbackService } from '@premagic/core';
import { openReportedIssuesIdsByTypeGroupedByDateSelector } from '../data-duck/ReportIssueDataDuck';
import { REPORT_ISSUE_GROUP_TYPES } from '../service/ReportIssueService';
import ReportIssueListByGroupType from './ReportIssueListByGroupType';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
    name: string;
    groupType: REPORT_ISSUE_GROUP_TYPES;
  },
) {
  const { projectId, reportType, name, groupType } = ownProps;

  const reportIssuesIdsByTypeGroupedByDate: Record<
    REPORT_ISSUE_GROUP_TYPES,
    Array<string>
  > = openReportedIssuesIdsByTypeGroupedByDateSelector(projectId, reportType)(state);

  return {
    openReportIssuesIds: reportIssuesIdsByTypeGroupedByDate?.[groupType] || [],
    projectId,
    name,
    groupType,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssueListByGroupType);
