import React from 'react';
import { connect } from 'react-redux';
import EventFeedbackListItem from './EventFeedbackListItem';
import { eventFeedbackByIdSelector } from '../data-duck/EventFeedbackDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; feedbackId: string }) {
  const { feedbackId, projectId } = ownProps;
  return {
    projectId,
    eventFeedbackItem: eventFeedbackByIdSelector(feedbackId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFeedbackListItem);
