import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { editQRCodeDesigns, qrCodeDesignsSelector } from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeLEDScreenCustomPosterFiles from './QRCodeLEDScreenCustomPosterFiles';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  return { projectId, folderId, qrCodeDesigns: qrCodeDesignsSelector(folderId)(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLEDCustomPoster: (projectId, folderId, qrCodeDesigns, fileId) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_LED_POSTER]: fileId,
          },
        }),
      );
    },
    close: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_LED_CUSTOM_POSTER_MODAL, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeLEDScreenCustomPosterFiles);
