import { FolderService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { folderFilesStatsWithFolderTypeForProjectSelector, projectsSelector } from '../AccountProjectsDataDuck';
import HighlightFoldersPageHeader from './HighlightFoldersPageHeader';
import {
  clientSettingsDataSelector,
  isCompanyTypeNotPhotographerSelector,
} from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    currentUrlPathname: string;
  },
) {
  const { projectId, currentUrlPathname } = ownProps;

  const clientData = clientSettingsDataSelector(state);

  return {
    projectId,
    currentUrlPathname,
    project: projectsSelector(state)[projectId],
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    folderFilesStats: folderFilesStatsWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.HIGHLIGHT,
    )(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    isFavoriteFeatureEnabled: clientData?.enable_highlight_favorite,
  };
}

export default connect(mapStateToProps)(HighlightFoldersPageHeader);
