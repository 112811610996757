import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import HighlightDesignTitleAndLogoSettings from './HighlightDesignTitleAndLogoSettings';

function mapStateToProps(
  state,
  ownProps: {
    isCoverTitleHidden?: boolean;
    isCoverLogoHidden?: boolean;
    coverTitle?: string;
    handleFormSubmit: (data: any) => void;
    isLoading?: boolean;
    hideTitleChangeInput?: boolean;
  },
) {
  const { isCoverTitleHidden, isCoverLogoHidden, coverTitle, handleFormSubmit, isLoading, hideTitleChangeInput } =
    ownProps;

  return {
    isFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
    isCoverTitleHidden,
    isCoverLogoHidden,
    coverTitle,
    handleFormSubmit,
    isLoading,
    hideTitleChangeInput,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignTitleAndLogoSettings);
