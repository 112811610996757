import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import { ModalDuck, UploaderDuck } from '@premagic/common-ducks';
import WatermarkForm from './WatermarkForm';
import {
  projectMetaDataForProjectSelector,
  projectWatermarkForProjectSelector,
} from '../projects/project-meta/ProjectsMetaDataDuck';
import { folderIdsWithFolderTypeForProjectSelector, projectsSelector } from '../projects/AccountProjectsDataDuck';
import { fetchFolder } from '../projects/folders/AccountFoldersDataDuck';
import { filesSelector } from '../images/FilesDataDuck';
import { LOADINGS } from '../../../../common/Constants';

const BRANDING_FOLDER_FILES = LOADINGS.BRANDING_FOLDER.SELECT_FILE_MODAL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    id: string;
  },
) {
  const { projectId, id } = ownProps;
  const project = projectsSelector(state)[projectId] || {};
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);
  return {
    id,
    projectId,
    title: project.project_name,
    files: filesSelector(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    watermarks: projectWatermarkForProjectSelector(projectId)(state),
    brandingFolderId: brandingFolderIds[0],
    isUploading: UploaderDuck.isUploadingFilesSelector(state),
    modalOptions: ModalDuck.modalOptionsSelector<
      Record<
        string,
        {
          name: string;
          fileId?: string;
        }
      >
    >(BRANDING_FOLDER_FILES)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFolderFiles: (projectId, folderId) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
    openFilePicker: (name) => {
      dispatch(
        ModalDuck.setModalOptions(dispatch, BRANDING_FOLDER_FILES, {
          [name]: {
            name,
          },
        }),
      );
      dispatch(ModalDuck.toggleModalVisibility(dispatch, BRANDING_FOLDER_FILES, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermarkForm);
