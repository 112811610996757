import { GeneralImageEditorService } from '@premagic/core';
import { Space } from '@premagic/myne';
import React, { useState, useRef, useEffect } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import ImageEditorBasicButtons from './image-editor-inputs/ImageEditorBasicButtons';
import ImageEditorCropInputs from './image-editor-inputs/ImageEditorCropInputs';

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}
type Props = {
  imageSrc: string;
  onChange: (val: GeneralImageEditorService.ImageEditorImageMetaDataType) => void;
  handleRemovePhoto?: () => void;
  handleChangePhoto?: () => void;
  defaultCrop?: PixelCrop;
  defaultScale?: number;
  defaultRotate?: number;
  imageContainerBoxDimensions?: { width: string; height: string };
  defaultAspectRatio?: number;
};

export default function GeneralImageEditor(props: Props) {
  const {
    onChange,
    imageSrc,
    handleRemovePhoto,
    handleChangePhoto,
    defaultCrop,
    defaultScale = 1,
    defaultRotate = 0,
    imageContainerBoxDimensions = {
      width: '860px',
      height: '450px',
    },
    defaultAspectRatio,
  } = props;

  const editContainerBoxDimensions = imageContainerBoxDimensions;

  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>(
    defaultCrop || {
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 50,
      height: 50,
    },
  );
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [aspect, setAspect] = useState<number | undefined>(defaultAspectRatio);

  function handleSave() {
    const img = document.getElementById('edit-image');
    if (img) {
      const width = img.clientWidth;
      const height = img.clientHeight;
      onChange({ pixelCrop: completedCrop, scale, rotate, imageProperties: { width, height } });
    }
  }

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  return (
    <>
      {!!imageSrc && (
        <div
          style={{
            width: editContainerBoxDimensions.width,
            height: editContainerBoxDimensions.height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            overflow: 'hidden',
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            keepSelection
            disabled={!imageSrc}
          >
            <img
              id="edit-image"
              ref={imgRef}
              alt="Edit container"
              src={imageSrc}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                maxWidth: editContainerBoxDimensions.width,
                maxHeight: editContainerBoxDimensions.height,
                objectFit: 'contain',
              }}
              crossOrigin="anonymous"
            />
          </ReactCrop>
        </div>
      )}
      <ImageEditorCropInputs rotate={rotate} scale={scale} setRotate={setRotate} setScale={setScale} />
      <Space vertical size={6} />
      <div style={{ borderBottom: '0.5px solid #e9ebed' }} />
      <Space vertical size={1} />
      <ImageEditorBasicButtons
        handleSave={handleSave}
        handleRemovePhoto={handleRemovePhoto}
        handleChangePhoto={handleChangePhoto}
      />
    </>
  );
}
