import { connect } from 'react-redux';
import { ClientWebsiteBackgroundMusicPlayer } from './ClientWebsiteBackgroundMusicPlayer';

function mapStateToProps(state, ownProps) {
  const { musicId } = ownProps;
  return {
    musicId,
  };
}

export default connect(mapStateToProps)(ClientWebsiteBackgroundMusicPlayer);
