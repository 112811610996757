import React, { useState } from 'react';
import { History } from 'history';
import { BrowserUrlUtils, BrowserUtils, SimpleDateUtils } from '@premagic/utils';
import Tour from 'reactour';
import { Button, BUTTON_STYLES, TEXT_BUTTON_STYLES, TextButton } from '@premagic/myne';
import { EventTrackerService } from '@premagic/core';
import steps from './steps';
import APP_URLS from '../services/AppRouteURLService';

function gotoPage(currentStep, history, setNextStep) {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.CHANGE, { step: currentStep + 2 });
  const selector = steps[currentStep].selector.replaceAll(`.`, ``);
  const element: HTMLElement = document.getElementsByClassName(selector)[0] as HTMLElement;

  if (steps[currentStep].url) {
    history.push(steps[currentStep].url);
    setTimeout(() => {
      setNextStep(currentStep + 1);
    }, 1000);
    return;
  }

  if (steps[currentStep].isClickRequired) {
    element?.click();
    setTimeout(() => {
      setNextStep(currentStep + 1);
    }, 1000);
    return;
  }
  if (currentStep == 2) {
    const eventId = element?.getAttribute('data-event-id');
    const urlForDetails =
      eventId &&
      BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
        eventId,
      });
    history.push(urlForDetails);
    setTimeout(() => {
      setNextStep(currentStep + 1);
    }, 1000);
    return;
  }
  setNextStep(currentStep + 1);
}

type Props = {
  history: History;
  isTourOpen: boolean;
  toggleTour: (status: boolean) => void;
  openTour: (filters: BrowserUrlUtils.QueryObject) => void;
};

export default function AccountAppRoutes(props: Props) {
  const { history, isTourOpen, toggleTour, openTour } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState(0);

  const today = SimpleDateUtils.now();
  const filters = {
    start_date_gte: SimpleDateUtils.getDateStringFromDate(
      SimpleDateUtils.getStartDateOfMonth(today),
      SimpleDateUtils.STANDARD_DATE_FORMATS.FULL_DATE,
    ),
    end_date_lte: SimpleDateUtils.getDateStringFromDate(
      SimpleDateUtils.getEndDateOfMonth(today),
      SimpleDateUtils.STANDARD_DATE_FORMATS.FULL_DATE,
    ),
  };

  return (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      rounded={5}
      showNavigation={false}
      onRequestClose={() => toggleTour(false)}
      showNumber={false}
      startAt={0}
      goToStep={nextStep}
      showCloseButton={false}
      scrollSmooth
      disableInteraction
      closeWithMask={false}
      inViewThreshold={400}
      onAfterOpen={() => {
        BrowserUtils.disableBodyScroll();
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.OPEN, { view: 'Tour' });
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.CHANGE, { step: 1 });
        openTour(filters);
      }}
      onBeforeClose={() => BrowserUtils.enableBodyScroll()}
      disableDotsNavigation
      getCurrentStep={(step) => setCurrentStep(step)}
      className="tourbg"
      nextButton={
        <Button style={BUTTON_STYLES.PRIMARY_DARK} onClick={() => gotoPage(currentStep, history, setNextStep)}>
          {steps[currentStep].buttonText}
        </Button>
      }
      prevButton={
        <TextButton
          style={TEXT_BUTTON_STYLES.SECONDARY}
          onClick={() => {
            toggleTour(false);
            EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.SKIP, { skipedStep: currentStep + 1 });
          }}
        >
          Skip tour
        </TextButton>
      }
      lastStepNextButton={
        <Button
          style={BUTTON_STYLES.PRIMARY_DARK}
          onClick={() => {
            toggleTour(false);
            EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.FINISHED);
          }}
        >
          Done
        </Button>
      }
    />
  );
}
