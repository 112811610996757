import React, { useMemo } from 'react';

import { ProjectService, SponsorCreativesService } from '@premagic/core';
import { Card, Table, TABLE_CELL_TYPES } from '@premagic/myne';

type ReportTableProps = {
  isLoading: boolean;
  data: Array<SponsorCreativesService.SponsorCreativeReportType>;
  project?: ProjectService.ProjectType;
};

export default function SponsorReportTable(props: ReportTableProps) {
  const { data, isLoading, project } = props;
  const { project_name: projectName } = project || {};
  const columns = useMemo(
    () => [
      {
        title: 'Sponsor',
        accessor: 'sponsor_name',
        width: 150,
      },
      {
        title: 'Creative',
        accessor: 'creative_name',
        width: 100,
      },
      {
        title: 'Placement',
        accessor: 'creative_placement',
        width: 120,
      },
      {
        title: 'Impressions',
        accessor: 'stats_views',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 120,
      },
      {
        title: 'Clicks',
        accessor: 'stats_clicks',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 80,
      },
      {
        title: 'CTR',
        accessor: 'stats_ctr',
        type: TABLE_CELL_TYPES.PERCENTAGE,
        width: 75,
      },
      {
        title: 'CTA Clicks',
        accessor: 'stats_cta_clicks',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 80,
      },
      {
        title: 'ROI',
        accessor: 'stats_roi',
        type: TABLE_CELL_TYPES.CURRENCY,
        width: 80,
      },
      {
        title: 'Sponsor ID',
        accessor: 'sponsor_id',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 130,
      },
      {
        title: 'Creative ID',
        accessor: 'creative_id',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 130,
      },
    ],
    [],
  );

  if (isLoading) return <div>Loading...</div>;
  return (
    <Card>
      <Table
        showSelectCheckboxOnRows
        columns={columns}
        data={data}
        tableFilters={{}}
        requestDataWithFilter={(filters) => {}}
        name={`Sponsor Report - ${projectName}`}
        allowDownloadCSV
      />
    </Card>
  );
}
