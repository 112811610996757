import { IntegrationService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Col,
  FormFooter,
  List,
  ListItem,
  ListItemHeader,
  LoadingDots,
  ModalTitle,
  Row,
  ScrollableContainer,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { useEffect } from 'react';
import { INTEGRATION_STEP } from './IntegrationStepService';

type Props = {
  platform: IntegrationService.INTEGRATION_PLATFORMS;
  getAllEventsForPlatform: (platform: IntegrationService.INTEGRATION_PLATFORMS) => void;
  setIntegrationStep: (step: INTEGRATION_STEP) => void;
  events: Record<string, IntegrationService.IntegrationPlatformEventType>;
  setIntegrationSelectedEvent: (eventId: string) => void;
  isLoading?: boolean;
  closeModal: () => void;
  errors?: {
    message: string;
    code: string;
    name: string;
  };
};

export default function IntegrationEventSelectorList(props: Props) {
  const {
    getAllEventsForPlatform,
    platform,
    setIntegrationStep,
    events,
    setIntegrationSelectedEvent,
    isLoading,
    closeModal,
    errors,
  } = props;

  function handleSelectEvent(eventId) {
    setIntegrationSelectedEvent(eventId);
    setIntegrationStep(INTEGRATION_STEP.SELECT_CUSTOM_FIELD);
  }

  useEffect(() => {
    getAllEventsForPlatform(platform);
  }, [getAllEventsForPlatform, platform]);

  if (isLoading) {
    return <LoadingDots />;
  }

  if (errors) {
    return (
      <>
        <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          {errors.message}
        </Text>
        <FormFooter>
          <Button onClick={closeModal} style={BUTTON_STYLES.TERTIARY}>
            Close
          </Button>
        </FormFooter>
      </>
    );
  }

  return (
    <>
      <ModalTitle>Select an event</ModalTitle>
      <List>
        <ListItemHeader>
          <Row>
            <Col size={3}>
              <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Event name
              </Text>
            </Col>

            <Col rightAlighed size={null}>
              <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Created at
              </Text>
            </Col>
          </Row>
        </ListItemHeader>
        <Space>
          <ScrollableContainer>
            {Object.values(events).map((event) => (
              <ListItem key={event.id} onClick={() => handleSelectEvent(event.id)}>
                {event.title}
                <Col rightAlighed size={null}>
                  {SimpleDateUtils.humanizeDate(
                    SimpleDateUtils.getISODateStringFromTimestamp(event.created_at),
                    false,
                    false,
                    true,
                    event.timezone,
                  )}
                </Col>
              </ListItem>
            ))}
          </ScrollableContainer>
        </Space>
      </List>
      <FormFooter>
        <Button onClick={closeModal} style={BUTTON_STYLES.TERTIARY}>
          Close
        </Button>
      </FormFooter>
    </>
  );
}
