import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import { exhibitorIdForEditSelector, setEditOfExhibitor } from '../EventExhibitorPageDuck';
import { updateEventExhibitor } from '../EventExhibitorDataDuck';
import EditEventExhibitorPanel from './EditEventExhibitorPanel';

const PANEL_KEY = LOADINGS.EVENT_EXHIBITOR.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.EVENT_EXHIBITOR.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const exhibitorId = exhibitorIdForEditSelector(state);

  return {
    projectId,
    exhibitorId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEventExhibitor: (options, data) => {
      dispatch(updateEventExhibitor(dispatch, options, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
      dispatch(setEditOfExhibitor(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEventExhibitorPanel);
