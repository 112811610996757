import {
  ClientAppServices,
  ClientWebsiteService,
  EventTrackerService,
  FolderService,
  ProjectMetaService,
  ProjectService,
  Schemas,
} from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ArrayUtils, BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { size, uniqBy } from 'lodash';
import { normalize } from 'normalizr';
import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { setClientWebsiteItineraryData } from '../client-website/itinerary/ClientWebsiteItineraryDataDuck';
import { getEventAttendeesForProject } from '../crm/events/event-attendees/EventAttendeesDataDuck';
import {
  addFolderData,
  foldersArraySelector,
  foldersEntitiesSelector,
  foldersSelector,
  setFoldersData,
  updateFolderData,
} from './folders/AccountFoldersDataDuck';

import { toggleModalVisibility } from '../../../../common/ModalDuck';
import { toggleWindowPanelVisibility } from '../../../../common/WindowPanelDuck';
import { navigateTo } from '../../../../services/RouterService';
import APP_URLS from '../../services/AppRouteURLService';
import { setClientWebsiteData } from '../client-website/ClientWebsiteDataDuck';
import { eventEntitiesSelector, fetchEventDataWithDependencyData } from '../crm/events/EventsDataDuck';
import { isSelectionAlbumEnabledSelector } from '../settings/preferences/ClientSettingsDataDuck';
import { projectSearchTermSelector } from './AccountprojectsListDataDuck';
import { setFoldersMetaData } from './folder-meta/FoldersMetaDataDuck';
import {
  getProjectMetaData,
  hasAiSignatureAlbumSelector,
  projectMetaDataForProjectSelector,
  projectsMetaDataSelector,
} from './project-meta/ProjectsMetaDataDuck';
import { getProjectStatsData } from './project-stats/ProjectsStatsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECTS');

export const setProjectsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const updateProjectsData = createAction(
  getActionType('DATA', 'UPDATE'),
  (dispatch, projectId: string, data) => ({
    id: projectId,
    data,
  }),
);

export const removeFolderFromProjectData = createAction(
  getActionType('DATA', 'REMOVE_FOLDER'),
  (dispatch, projectId: string, folderId: string) => ({
    id: projectId,
    folderId,
  }),
);
export const addNewFolderToProjectsData = createAction(
  getActionType('DATA', 'ADD_FOLDER'),
  (dispatch, projectId, folderId) => ({
    id: projectId,
    folderId,
  }),
);

export const setProjectSearchTerm = createAction(
  getActionType('PROJECT_SEARCH', 'SET'),
  (dispatch, searchTerm: string) => searchTerm,
);

export const fetchProjectsData = createAction(getActionType('DATA', 'FETCH'), (dispatch: Dispatch) => {
  const loadingKey = LOADINGS.PROJECTS.FETCH_ALL;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  ProjectService.fetchProjects()
    .then((response) => {
      const normalizedData = normalize(response, Schemas.ProjectsSchema);
      dispatch(setProjectsData(dispatch, normalizedData.entities.projects));
      dispatch(setFoldersData(dispatch, normalizedData.entities.folders));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const fetchProject = createAction(
  getActionType('ACTION', 'SINGLE_FETCH'),
  (dispatch: Dispatch, projectId: string) => {
    const loadingKey = LOADINGS.PROJECT.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    ProjectService.fetchProject(projectId)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProjectSchema);
        if (response.event_id) dispatch(fetchEventDataWithDependencyData(dispatch, response.event_id));
        dispatch(setProjectsData(dispatch, normalizedData.entities.projects));
        dispatch(setFoldersData(dispatch, normalizedData.entities.folders));
        const folderData = normalizedData.entities?.folders || {};
        const folderMetaData = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(folderData)) {
          folderMetaData[key] = value.meta;
        }
        dispatch(setFoldersMetaData(dispatch, folderMetaData));
        dispatch(setClientWebsiteData(dispatch, normalizedData.entities.clientWebsite));
        dispatch(setClientWebsiteItineraryData(dispatch, normalizedData.entities.itineraries));

        dispatch(getProjectMetaData(dispatch, projectId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const fetchProjectForEvent = createAction(
  getActionType('ACTION', 'SINGLE_FETCH_FOR_EVENT'),
  (dispatch: Dispatch, eventId: string) => {
    const loadingKey = LOADINGS.PROJECT.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProjectService.fetchProjectForEvent(eventId)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProjectSchema);
        const { projects, folders, clientWebsite } = normalizedData.entities;
        dispatch(setProjectsData(dispatch, projects));
        dispatch(setFoldersData(dispatch, folders));
        dispatch(setClientWebsiteData(dispatch, clientWebsite));
        dispatch(setClientWebsiteItineraryData(dispatch, normalizedData.entities.itineraries));

        // Fetch this to show the speakers in the session, in the micro-site section
        dispatch(getEventAttendeesForProject(dispatch, response.project_id));

        dispatch(getProjectMetaData(dispatch, response.project_id));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const addProject = createAction(
  getActionType('ACTION', 'ADD'),
  (dispatch: Dispatch, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.PROJECT.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.CREATE, { ...formResponse.data });

    const { errors, data } = formResponse;
    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, errors));
      return;
    }
    ProjectService.createProject(data as ProjectService.NewProjectType)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProjectSchema);
        dispatch(setProjectsData(dispatch, normalizedData.entities.projects));
        dispatch(setFoldersData(dispatch, normalizedData.entities.folders));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.PROJECT.SHOW_ADD_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const updateProject = createAction(
  getActionType('ACTION', 'UPDATE'),
  (dispatch: Dispatch, projectId: string, action: 'RENAME' | 'ARCHIVE', formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.PROJECT.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { errors, data } = formResponse;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.UPDATE, { action, ...data });
    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, errors));
      return;
    }

    const serviceFunction =
      action === 'RENAME'
        ? ProjectService.renameProject(projectId, data as { project_name: string })
        : ProjectService.archiveProject(projectId);

    serviceFunction
      .then((response) => {
        dispatch(updateProjectsData(dispatch, projectId, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT.SHOW_ARCHIVE_DIALOG, false));
        dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT.SHOW_RENAME_DIALOG, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const addFolderToProject = createAction(
  getActionType('ACTION', 'ADD'),
  (
    dispatch,
    formResponse: FormResponseType,
    options: {
      folderType: FolderService.FOLDER_TYPES;
      projectId: string;
      navigateToFolder: boolean;
      autoPublish?: boolean;
    },
  ) => {
    const { folderType, projectId, navigateToFolder, autoPublish } = options;

    const loadingKey = LOADINGS.FOLDER.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const { folder_name: folderName } = formResponse.data;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.CREATE, {
      projectId,
      folderName,
      folderType,
    });

    FolderService.createFolder(projectId, {
      folder_name: folderName as string,
      folder_type: folderType,
    })
      .then((response) => {
        const folderId = response.folder_id;
        const normalizedData = normalize(response, Schemas.FolderSchema);
        const { folders } = normalizedData.entities || {};
        dispatch(addFolderData(dispatch, folders));
        dispatch(addNewFolderToProjectsData(dispatch, projectId, folderId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        if (navigateToFolder) {
          const folderEditURL = BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.DETAILS, {
            folderType,
            projectId,
            folderId,
          });

          navigateTo(dispatch, folderEditURL);
        }
        if (autoPublish) {
          FolderService.shareFolderWithClientService(projectId, [folderId], {
            clients: [
              {
                name: 'dummy',
                email: `dummy-${projectId}@premagic.com`,
                phone_number: '',
              },
            ],
          }).then((shareInfo) => {
            const tempFolderStatus = shareInfo.folder_status;
            dispatch(
              updateFolderData(dispatch, folderId, {
                share_url: tempFolderStatus[folderId].share_url,
                status: tempFolderStatus[folderId].status,
                is_shared: true,
              }),
            );
          });
        }
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const cloneHighlightFolderToSelection = createAction(
  getActionType('ACTION', 'CLONE_HIGHLIGHT'),
  (dispatch, projectId) => {
    const loadingKey = LOADINGS.PROJECT.COPY_FROM_SIGNATURE_ALBUM;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.COPY_HIGHLIGHT_TO_SELECTION, {
      projectId,
    });

    ProjectService.cloneHighlightFoldersToSelectionFolder(projectId)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        const folderEditURL = BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.DETAILS, {
          folderType: FolderService.FOLDER_TYPES.SELECTION,
          projectId,
          folderId: undefined,
        });

        navigateTo(dispatch, folderEditURL);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const resetProjectPinNumber = createAction(
  getActionType('ACTION', 'RESET_PIN'),
  (dispatch: Dispatch, projectId: string) => {
    const loadingKey = LOADINGS.PROJECT.RESET_PIN;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProjectService.resetProjectMPIN(projectId)
      .then((response) => {
        dispatch(updateProjectsData(dispatch, projectId, { mpin: response }));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

type ItemsType = IMap<string, ProjectService.ProjectType>;
type StateType = {
  items: ItemsType;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setProjectsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [updateProjectsData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (project: any) => ({
        ...project,
        ...action.payload.data,
      })),
    }),
    [addNewFolderToProjectsData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (project: any) => ({
        ...project,
        folders: project.folders?.concat(action.payload.folderId),
      })),
    }),
    [removeFolderFromProjectData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (project: any) => ({
        ...project,
        folders: project.folders.filter((id) => id !== action.payload.folderId),
      })),
    }),
    [setProjectSearchTerm.toString()]: (state, action: { payload }) => ({
      ...state,
      projectSearchTerm: action.payload,
    }),
  },
  initialState,
);

export const projectsDataSelector = (state) => state.entities.projects;

export const projectsEntitiesSelector = createSelector(projectsDataSelector, (state: StateType) => state.items);

export const projectsDataWithSearchTermSelector = createSelector(
  projectsEntitiesSelector,
  projectSearchTermSelector,
  (projects, searchTerm) => {
    let projectItems = projects;
    if (searchTerm.length >= 2) {
      const filteredProjects = ProjectService.searchProjects(projects.valueSeq().toArray(), searchTerm);
      const normalizedData = normalize(filteredProjects, Schemas.ProjectsSchema);
      projectItems = normalizedData.entities.projects ? IMap(normalizedData.entities.projects) : IMap({});
    }
    return projectItems.sort((a, b) => {
      if (a.is_archive && !b.is_archive) {
        return 1;
      }
      if (!a.is_archive && b.is_archive) {
        return -1;
      }
      return (
        SimpleDateUtils.getTimestampFromUglyBackendDateFormat(b.created_at) -
        SimpleDateUtils.getTimestampFromUglyBackendDateFormat(a.created_at)
      );
    });
  },
);

export const eventProjectSelectorSelector = (eventId: string) =>
  createSelector(projectsEntitiesSelector, (items) => items.find((item) => item.event_id === eventId));

export const projectsSelector = createSelector(projectsEntitiesSelector, (items) => items.toJSON());
export const projectNameSelector = (projectId: string) =>
  createSelector(projectsEntitiesSelector, (items) => items.get(projectId)?.project_name);
export const activeProjectsArraySelector = createSelector(projectsEntitiesSelector, (items) =>
  items
    .filter((item) => !item.is_archive)
    .sort(
      (a, b) =>
        SimpleDateUtils.getTimestampFromUglyBackendDateFormat(b.created_at) -
        SimpleDateUtils.getTimestampFromUglyBackendDateFormat(a.created_at),
    )
    .valueSeq()
    .toJSON(),
);

export const clientIdForProjectSelector = (projectId: string) =>
  createSelector(projectsEntitiesSelector, eventEntitiesSelector, (projects, events) => {
    const project = projects.get(projectId);
    if (!project?.event_id) return undefined;
    const event = events.get(project.event_id);
    return event?.client;
  });

export const clientForProjectFolderSelector = (projectId: string, folderIds?: Array<string>) =>
  createSelector(projectsEntitiesSelector, foldersSelector, (projects, folders) => {
    let { clients } = projects.get(projectId, { clients: undefined });
    if (folderIds) {
      const clientsObj = folderIds.reduce(
        (results, folderId) => ({
          ...results,
          ...folders.get(folderId)?.clients,
        }),
        {},
      );
      const clientsArray = Object.entries(clientsObj).map(([, value]) => value);
      // These clients have received the notification
      clients = clientsArray.length ? (clientsArray as Array<FolderService.FolderClientType>) : clients;
    }
    // This is when the requested folders are not shared with the client, in this case we need to pull the clients from other folders
    // so that user can share the new folders with all the clients which are already added
    if (size(clients) <= 0) {
      const clientsObj = folders
        .filter((folder) => folder.clients)
        .reduce(
          (results, folder) => ({
            ...results,
            ...folder.clients,
          }),
          {},
        );

      const clientsArray = Object.entries(clientsObj).map(([, value]) => Object.assign({}, value, { isNew: true }));
      clients = clientsArray.length ? (clientsArray as Array<FolderService.FolderClientType>) : clients;
    }

    if (size(clients) <= 0) return undefined;
    return uniqBy(clients, 'email')
      .filter((client) => client.name !== 'dummy')
      .map((client) => ({
        name: client.name,
        phone_number: client.phone_number,
        email: client.email,
        isNew: client.skip_notifications ? true : client.isNew,
      }));
  });

export const projectStatusSelector = (projectId: string) =>
  createSelector(projectsDataSelector, foldersSelector, (state: StateType, folders) => {
    const project = state.items.get(projectId);
    const projectFolders = project?.folders.map((folderId) => folders.get(folderId)) as Array<FolderService.FolderType>;
    if (projectFolders) return FolderService.getProjectStatusFromFolders(projectFolders);
    return FolderService.FOLDER_STATUS.DRAFT;
  });

export const allProjectsKeySelector = createSelector(projectsDataSelector, (state: StateType) =>
  state.items.keySeq().toJS(),
);

export const groupProjectsWithDateSelector = createSelector(
  projectsDataWithSearchTermSelector,
  (projects) =>
    projects
      .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
      .groupBy((project) =>
        SimpleDateUtils.getDateStringFromDate(
          SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(project.created_at),
          SimpleDateUtils.STANDARD_DATE_FORMATS.MONTH_YEAR,
        ),
      )
      .toJS() as {
      [date: string]: Record<string, ProjectService.ProjectType>;
    },
);

export const groupProjectsWithDateBasedOnArchivedSelector = (archived: boolean) =>
  createSelector(
    projectsDataWithSearchTermSelector,
    (projects) =>
      projects
        .filter((project) => project.is_archive === archived)
        .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
        .groupBy((project) =>
          SimpleDateUtils.getDateStringFromDate(
            SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(project.created_at),
            SimpleDateUtils.STANDARD_DATE_FORMATS.MONTH_YEAR,
          ),
        )
        .toJS() as {
        [date: string]: Record<string, ProjectService.ProjectType>;
      },
  );

export const foldersArrayForProjectSelector = (projectId: string) =>
  createSelector(
    projectsEntitiesSelector,
    foldersArraySelector,
    (projectEntities, folders: Array<FolderService.FolderType>) => {
      const project = projectEntities.get(projectId);
      return folders.filter((folder) => project?.folders.includes(folder.folder_id));
    },
  );

export const folderIdsWithFolderTypeForProjectSelector = (
  projectId: string,
  folderType: FolderService.FOLDER_TYPES,
  options?: {
    is_shared?: boolean;
  },
) =>
  createSelector(
    projectsEntitiesSelector,
    foldersSelector,
    projectsMetaDataSelector,
    (projectEntities, allFolders, projectMetas) => {
      const project = projectEntities.get(projectId, { folders: [] });
      const projectMeta = projectMetas.get(projectId);
      const folderOrderFromMeta = projectMeta?.highlight_folders_position_order || [];
      let folderIds = project.folders?.filter((folderId) => allFolders.get(folderId)?.folder_type === folderType) || [];

      if ([FolderService.FOLDER_TYPES.SPONSOR, FolderService.FOLDER_TYPES.BRANDING].includes(folderType)) {
        return folderIds.sort((aFolderId, bFolderId) =>
          ArrayUtils.stringSortFunction(allFolders.get(aFolderId)?.folder_name, allFolders.get(bFolderId)?.folder_name),
        );
      }

      if (options && 'is_shared' in options) {
        const checkFunction = options.is_shared
          ? (status) => status !== FolderService.FOLDER_STATUS.DRAFT
          : (stats) => stats === FolderService.FOLDER_STATUS.DRAFT;

        folderIds = folderIds.filter((folderId) => checkFunction(allFolders.get(folderId)?.status));
      }

      if (folderType === FolderService.FOLDER_TYPES.HIGHLIGHT) {
        return (
          folderIds
            .sort((aFolderId, bFolderId) => FolderService.sortFoldersBytDate(aFolderId, bFolderId, allFolders.toJSON()))
            .sort((aFolderId, bFolderId) => {
              // If the folder_id in not in the orders then push it last
              if (folderOrderFromMeta.includes(aFolderId))
                return folderOrderFromMeta.indexOf(aFolderId) - folderOrderFromMeta.indexOf(bFolderId);
              return 1;
            }) || []
        );
      }

      return folderIds.sort((aFolderId, bFolderId) =>
        ArrayUtils.stringSortFunction(allFolders.get(aFolderId)?.folder_name, allFolders.get(bFolderId)?.folder_name),
      );
    },
  );

export function clientWebsiteIdForProjectSelector(projectId?: string) {
  if (!projectId) return () => undefined;
  return createSelector(
    folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.CLIENT_WEBSITE),
    (clientWebsites) => clientWebsites[0],
  );
}
export function clientWebsiteForProjectSelector(projectId?: string) {
  if (!projectId) return () => undefined;
  return createSelector(foldersSelector, clientWebsiteIdForProjectSelector(projectId), (folders, clientWebsiteId) => {
    if (clientWebsiteId) return folders.get(clientWebsiteId);
    return undefined;
  });
}
export function registrationLinkForProjectSelector(projectId: string) {
  return createSelector(
    clientWebsiteForProjectSelector(projectId),
    projectMetaDataForProjectSelector(projectId),
    (clientWebsite, projectMeta) => {
      if (!clientWebsite) return '';
      const photoRegistrationLink = `${clientWebsite.share_url}${ClientWebsiteService.CLIENT_WEBSITE_LINK.PHOTO_REGISTER}`;
      const registrationLink = `${clientWebsite.share_url}${ClientWebsiteService.CLIENT_WEBSITE_LINK.BOOK}`;

      return projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED]
        ? registrationLink
        : photoRegistrationLink;
    },
  );
}

export const totalSharedFolderWithFolderTypeForProjectSelector = (
  projectId: string,
  folderType: FolderService.FOLDER_TYPES,
) =>
  createSelector(projectsEntitiesSelector, foldersSelector, (projectEntities, allFolders) => {
    const project = projectEntities.get(projectId, { folders: [] });
    return project.folders?.filter((folderId) => {
      const folder = allFolders.get(folderId);
      if (!folder) return false;
      return folder.folder_type === folderType && folder.is_shared;
    }).length;
  });

const initialFolderStats = {
  total_image: 0,
  accepted: 0,
  rejected: 0,
  unreviewed: 0,
};
export const folderFilesStatsWithFolderTypeForProjectSelector = (
  projectId: string,
  folderType: FolderService.FOLDER_TYPES,
) =>
  createSelector(projectsEntitiesSelector, foldersSelector, (projectEntities, folders) => {
    const project = projectEntities.get(projectId, { folders: [] });
    return (
      project?.folders
        ?.filter((folderId) => folders.get(folderId)?.folder_type === folderType)
        .reduce((result, folderId) => {
          const folder = folders.get(folderId) || { stats: initialFolderStats };
          return {
            total_image: result.total_image + Number(folder.stats?.total_image || 0),
            accepted: result.accepted + Number(folder.stats?.accepted || 0),
            rejected: result.rejected + Number(folder.stats?.rejected || 0),
            unreviewed: result.unreviewed + Number(folder.stats?.unreviewed || 0),
          };
        }, initialFolderStats) || {}
    );
  });

function getFolderShareUrl(
  allFolders: IMap<string, FolderService.FolderType>,
  folderIds: Array<string>,
  folderType: FolderService.FOLDER_TYPES,
): string | undefined {
  const folderWithShareUrl = folderIds
    .filter((id) => allFolders.get(id)?.folder_type === folderType)
    .find((id) => allFolders.get(id)?.share_url);
  return folderWithShareUrl ? allFolders.get(folderWithShareUrl)?.share_url : undefined;
}

export const projectFolderTypesLinksSelector = (projectId: string) =>
  createSelector(
    projectsEntitiesSelector,
    foldersSelector,
    isSelectionAlbumEnabledSelector,
    (allProjects, allFolders, isSelectionAlbumEnabled) => {
      const project = allProjects.get(projectId);
      const folderIds = project?.folders?.filter((folderId) => allFolders.get(folderId)?.share_url) || [];
      const selectionLink = isSelectionAlbumEnabled
        ? {
            [FolderService.FOLDER_TYPES.SELECTION]: getFolderShareUrl(
              allFolders,
              folderIds,
              FolderService.FOLDER_TYPES.SELECTION,
            ),
          }
        : {};
      return {
        [FolderService.FOLDER_TYPES.HIGHLIGHT]: getFolderShareUrl(
          allFolders,
          folderIds,
          FolderService.FOLDER_TYPES.HIGHLIGHT,
        ),

        [FolderService.FOLDER_TYPES.CLIENT_WEBSITE]: getFolderShareUrl(
          allFolders,
          folderIds,
          FolderService.FOLDER_TYPES.CLIENT_WEBSITE,
        ),
        ...selectionLink,
      };
    },
  );

export const guestGalleryLinkSelector = (projectId: string) =>
  createSelector(
    foldersEntitiesSelector,
    folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.HIGHLIGHT),
    hasAiSignatureAlbumSelector(projectId),
    (allFolders, folderIds, hasPhotoDelivery) => {
      if (!hasPhotoDelivery) return undefined;
      const folderWithShareUrl = folderIds.find(
        (id) =>
          allFolders[id]?.status !== FolderService.FOLDER_STATUS.DRAFT &&
          (allFolders[id]?.guest_share_url || allFolders[id]?.share_url),
      );
      const shareUrl = folderWithShareUrl
        ? allFolders[folderWithShareUrl].guest_share_url || allFolders[folderWithShareUrl].share_url
        : undefined;
      return shareUrl?.replace('/share/', '/guest/');
    },
  );

export const guestGalleryLinkFromProjectDataSelector = (projectId: string, faceId: string) =>
  createSelector(projectsEntitiesSelector, (projects) => {
    const project = projects.get(projectId);
    const shareUrl = project ? project.share_url : undefined;
    const link = shareUrl?.replace('/share/', '/guest/');
    return link ? `${link}#/people/${faceId}` : undefined;
  });

export const projectShareUrlForHighlightSelector = createSelector(foldersSelector, (allFolders) => {
  const folderIdWithShareUrl = allFolders
    .keySeq()
    .toArray()
    .find(
      (id) => allFolders.get(id)?.folder_type === FolderService.FOLDER_TYPES.HIGHLIGHT && allFolders.get(id)?.share_url,
    );
  return folderIdWithShareUrl ? allFolders.get(folderIdWithShareUrl)?.share_url : undefined;
});

export const avatarStudioLinkSelector = (projectId: string) =>
  createSelector(guestGalleryLinkSelector(projectId), (link) =>
    link ? ClientAppServices.getAvatarStudioUrl(link) : undefined,
  );

export const avatarStudioPrintLinkSelector = (projectId: string) =>
  createSelector(guestGalleryLinkSelector(projectId), (link) =>
    link ? ClientAppServices.getAvatarStudioPrintLink(link) : undefined,
  );
