import React, { useEffect } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  InnerPageHeaderFloatingTag,
} from '@premagic/myne';
import { ProjectService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';

import APP_URLS from '../../services/AppRouteURLService';
import ProjectDetailsDeliverablesLayoutContainer from '../crm/event-deliveries/ProjectDetailsDeliverablesLayoutContainer';
import SharePanelContainer from '../folder-share/SharePanelContainer';
import EditSocialMessageModalContainer from './social-media-settings/social-message-modal/EditSocialMessageModalContainer';
import AddClientWebsiteItineraryPanelContainer from '../client-website/itinerary/AddClientWebsiteItineraryPanelContainer';
import EditClientWebsiteItineraryPanelContainer from '../client-website/itinerary/EditClientWebsiteItineraryPanelContainer';

type Props = {
  initData: (projectId: string) => void;
  isLoading: boolean;
  project: ProjectService.ProjectType;
  projectId: string;
  clientWebsiteId?: string;
};

export default function AccountProjectPage(props: Props): JSX.Element {
  const { initData, project, isLoading, projectId, clientWebsiteId } = props;
  const { event_id: eventId } = project;
  useEffect(() => {
    initData(projectId);
  }, []);

  const eventDetailsURL = eventId
    ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
        eventId,
      })
    : '';

  return (
    <InnerPage>
      {eventId && (
        <InnerPageHeaderFloatingTag>
          <Button style={BUTTON_STYLES.LINK} link={eventDetailsURL} size={BUTTON_SIZE.SM}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} /> Back to Event
          </Button>
        </InnerPageHeaderFloatingTag>
      )}
      <InnerPageHeader iconName="server" title="Projects" backTo="/projects" backToTitle="Back to Projects list">
        Projects
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading} hasInnerCols>
        <ProjectDetailsDeliverablesLayoutContainer projectId={projectId} />
      </InnerPageContent>
      {projectId && <SharePanelContainer projectId={projectId} eventId={eventId} />}
      {projectId && <EditSocialMessageModalContainer projectId={projectId} />}
      {projectId && clientWebsiteId && (
        <>
          <AddClientWebsiteItineraryPanelContainer websiteId={clientWebsiteId} projectId={projectId} />
          <EditClientWebsiteItineraryPanelContainer websiteId={clientWebsiteId} projectId={projectId} />
        </>
      )}
    </InnerPage>
  );
}
