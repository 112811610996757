import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../common/Constants';
import { EVENT_REPORT_WIDGETS } from './EventReportService';
import EventReportWidget from './EventReportWidget';
import { eventReportForDashboardItemsSelector } from './EventReportDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: EVENT_REPORT_WIDGETS;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;

  return {
    id,
    isLoading:
      LoadingDuck.isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state) ||
      LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_STATS.FETCH)(state) ||
      LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_NERD_STATS.FETCH)(state),
    value: eventReportForDashboardItemsSelector(projectId)(state)[id],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventReportWidget);
