import React from 'react';
import {
  PreventMarginCollapse,
  PricingCard,
  PricingCardContent,
  PricingCardHeader,
  PricingCardPrice,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Text,
  TEXT_BOLDNESS,
  ErrorBoundary,
} from '@premagic/myne';
import { CompanyService, PricingPlanService } from '@premagic/core';

import BuyPlanButtonContainer from '../subscriptions/BuyPlanButtonContainer';
import PricingPlanList from './PricingPlanList';

type PricingPlanProps = {
  plan: PricingPlanService.PricingPlanType;
  previousPlan?: PricingPlanService.PricingPlanType;
  highlight: boolean;
  company: CompanyService.CompanyType;
  view: PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY;
  last: boolean;
};
const showPrice = false;
export function PricingPlan(props: PricingPlanProps) {
  const { plan, previousPlan, highlight, company, view, last } = props;
  const userLocal = company.customer_country_code;

  const { variants } = plan;
  const variantId = `${view}_${userLocal}` as PricingPlanService.PricingPlanVariantId;
  const variant = variants[variantId] || { amount: 0 };
  const amount = variant.amount / 100;

  const name = PricingPlanService.getPricingPlanTitle(plan);

  return (
    <PricingCard highlight={highlight} last={last}>
      <PricingCardHeader>{name}</PricingCardHeader>
      <PricingCardContent>
        {showPrice && <PricingCardPrice amount={amount} view={view} userLocal={userLocal} />}
        <Space vertical />
        {previousPlan ? (
          <div>
            <Text block>
              Everything in{' '}
              <Text boldness={TEXT_BOLDNESS.BOLD}>{PricingPlanService.getPricingPlanTitle(previousPlan)} + </Text>
            </Text>
            <Space vertical size={1} />
          </div>
        ) : (
          <PreventMarginCollapse>
            <Space vertical size={6} />
          </PreventMarginCollapse>
        )}
        <ErrorBoundary>
          <ScrollableContainer showShadow direction="vertical" size={SCROLLABLE_CONTAINER_SIZE.SM}>
            <PricingPlanList plan={plan} userLocal={userLocal} previousPlan={previousPlan} />
          </ScrollableContainer>
        </ErrorBoundary>
        <Space vertical size={8} />
        <Row center>
          <BuyPlanButtonContainer variant={variant} variantId={variantId} planId={plan.id} />
        </Row>
      </PricingCardContent>
    </PricingCard>
  );
}
