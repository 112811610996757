import React, { useRef, useState } from 'react';
import { ClientsService, EventsService, ProjectService } from '@premagic/core';

import {
  Alert,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Form,
  FormGroup,
  FormLabel,
  Input,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';
import MESSAGES from '../../../../../common/Messages';
import UpdateButton from '../../settings/UpgradeButton';

type Props = {
  eventId: string;
  event: EventsService.EventType;
  client?: ClientsService.ClientType;
  project?: ProjectService.ProjectType;
  isCreating: boolean;
  isLoading: boolean;
  isTourOpen: boolean;
  convertEventToProject: (data: { eventId: string; eventName: string; clientId: string; projectName: string }) => void;
  hasExceedProjectLimit: boolean;
};

export default function ConvertToProjectButton(props: Props): React.ReactElement | null {
  const {
    eventId,
    project,
    isLoading,
    isCreating,
    isTourOpen,
    convertEventToProject,
    event,
    hasExceedProjectLimit,
    client,
  } = props;
  const [isDialogShown, setDialog] = useState(false);
  const $button = useRef(null);
  if (project && !isTourOpen) return null;
  if (isLoading) return null;
  const { name: clientName } = client || {};

  return (
    <>
      <Button
        style={BUTTON_STYLES.PRIMARY}
        ref={$button}
        onClick={() => setDialog(true)}
        size={BUTTON_SIZE.SM}
        className={JS_SELECTORS.CONVERT_TO_PROJECT}
      >
        Convert to project
      </Button>
      <Dialog
        target={$button.current}
        show={isDialogShown}
        placement={DIALOG_PLACEMENT.TOP_START}
        onClose={() => setDialog(false)}
      >
        <Form
          onSubmit={(formResponse) => {
            convertEventToProject({
              eventId,
              eventName: event.name,
              clientId: event.client,
              projectName: formResponse.data.name as string,
            });
          }}
        >
          {hasExceedProjectLimit && (
            <div>
              <Alert style={ALERT_STYLES.INFO}>
                {MESSAGES.PROJECT.UPGRADE}
                <Space vertical size={2} />
                <UpdateButton />
              </Alert>
              <Space vertical />
            </div>
          )}
          <FormGroup>
            <FormLabel>Project Name</FormLabel>
            <Input name="name" required placeholder="eg. John & Angelina" autoFocus defaultValue={clientName} />
            <Text muted size={TEXT_SIZE.SIZE_5}>
              Project Name shown to your client.
            </Text>
          </FormGroup>
          <DialogFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => setDialog(false)}>
              Cancel
            </Button>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isCreating}
              disabled={hasExceedProjectLimit}
              disabledMessage={MESSAGES.PROJECT.UPGRADE}
            >
              Convert
            </Button>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
}
