import { connect } from 'react-redux';
import { ProposalDataDuck, ProposalDeckDataDuck } from '@premagic/proposals';

import ProposalHistoryVersionItem from './ProposalHistoryVersionItem';

function mapStateToProps(
  state,
  ownProps: {
    deckId: string;
    folderId: string;
    versionNumber: string;
  },
) {
  const { deckId, folderId, versionNumber } = ownProps;

  return {
    deckId,
    folderId,
    versionNumber,
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    proposal: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalHistoryVersionItem);
