import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { projectsSelector } from '../../../../projects/AccountProjectsDataDuck';
import { downloadEventAttendeesForProject } from '../EventAttendeesDataDuck';
import EventAttendeeDownloadCSV from './EventAttendeeDownloadCSV';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const project = projectsSelector(state)[projectId];

  return {
    projectId,
    projectName: project?.project_name,
    isLoading: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.DOWNLOAD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadEventAttendeeData: (projectId, projectName) => {
      dispatch(downloadEventAttendeesForProject(dispatch, projectId, projectName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeDownloadCSV);
