import { connect } from 'react-redux';
import { getFolderMetaData } from '../../projects/folder-meta/FoldersMetaDataDuck';
import { clientWebsitesSelector, saveClientWebsite } from '../ClientWebsiteDataDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import ClientWebsiteDesignPresets from './ClientWebsiteDesignPresets';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;
  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    isSaving: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFolderMetaData: (projectId: string, folderId: string, options) => {
      dispatch(getFolderMetaData(dispatch, projectId, folderId, { ...options }));
    },
    saveClientWebsiteData: (id: string, projectId: string, data) => {
      dispatch(saveClientWebsite(dispatch, { id, projectId }, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteDesignPresets);
