import { EventSponsorsService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Dialog,
  DIALOG_SIZES,
  Icon,
  ICON_SIZES,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { useRef, useState } from 'react';
import AddSponsorCategoryButtonContainer from './add-edit-sponsor-category/AddSponsorCategoryButtonContainer';
import SponsorCategoryListContainer from './sponsor-list/SponsorCategoryListContainer';

type Props = {
  isLoading?: boolean;
  sponsorCategoryData: Record<string, EventSponsorsService.EventSponsorCategoryType>;
  defaultSponsorCategoryId?: string;
};

export default function SelectSponsorCategory(props: Props) {
  const { isLoading, sponsorCategoryData, defaultSponsorCategoryId } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedSponsorCategoryId, setSelectedSponsorCategoryId] = useState(defaultSponsorCategoryId || '');

  function handleSelectCategory(sponsorCategoryId) {
    setSelectedSponsorCategoryId(sponsorCategoryId);
    setShowDialog(false);
  }

  const sponsorCategory = sponsorCategoryData[selectedSponsorCategoryId];

  const $button = useRef(null);
  return (
    <>
      <Button
        size={BUTTON_SIZE.MD}
        style={BUTTON_STYLES.LINK}
        onClick={() => setShowDialog(!showDialog)}
        ref={$button}
        isLoading={isLoading}
      >
        {sponsorCategory?.name ? <Text boldness={TEXT_BOLDNESS.BOLD}>{sponsorCategory?.name}</Text> : 'Select category'}
        <Space size={1} />
        <Icon name={!showDialog ? 'chevron_down' : 'chevron_up'} size={ICON_SIZES.SM} />
      </Button>

      {showDialog && (
        <Dialog
          size={DIALOG_SIZES.AUTO}
          target={$button.current}
          show={showDialog}
          onClose={() => setShowDialog(false)}
        >
          <div style={{ minWidth: 424 }}>
            <SponsorCategoryListContainer
              handleSelectCategory={handleSelectCategory}
              selectedId={selectedSponsorCategoryId}
            />
            <Space size={6} />
            <AddSponsorCategoryButtonContainer />
          </div>
        </Dialog>
      )}
      <input required type="hidden" name="category" value={selectedSponsorCategoryId} />
    </>
  );
}
