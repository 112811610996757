import { LoadingDuck, ModalDuck, ErrorDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { hasPermission } from '../../../users/UsersDataDuck';
import { eventAttendeesSelector, updateEventAttendeeApprovalStatus } from '../EventAttendeesDataDuck';
import { attendeeIdForEditStatusSelector, setEditStatusOfAttendee } from '../EventAttendeesPageDuck';
import EditAttendeeApprovalStatusModal from './EditAttendeeApprovalStatusModal';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const id = attendeeIdForEditStatusSelector(state);

  return {
    projectId,
    id,
    attendee: id ? eventAttendeesSelector(state)[id] : undefined,
    show: ModalDuck.isModalOpenSelector(LOADINGS.EVENT_ATTENDEES.SHOW_EDIT_STATUS)(state),
    error: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADINGS.EVENT_ATTENDEES.UPDATE_STATUS(id))(state),
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_ATTENDEES.UPDATE_STATUS(id))(state),
    hasPermissionsToManageAttendees: hasPermission(PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEdit: () => {
      dispatch(setEditStatusOfAttendee(dispatch, null));
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_EDIT_STATUS, false));
    },
    updateStatus: (options, status) => {
      dispatch(updateEventAttendeeApprovalStatus(dispatch, options, status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAttendeeApprovalStatusModal);
