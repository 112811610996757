import React, { useEffect } from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
  Divider,
  Space,
} from '@premagic/myne';
import AddSystemRegistrationFormFieldsContainer from './AddSystemRegistrationFormFieldsContainer';
import EventFormFieldFormContainer from './EventFormFieldFormContainer';

type Props = {
  addFormField: (projectId: string, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
};

export default function AddEventFormFieldPanel(props: Props) {
  const { show, close, projectId, addFormField, isLoading, error } = props;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add form field</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          onSubmit={(response) => addFormField(projectId, response)}
          formSpec={{
            is_required: {
              type: 'boolean',
            },
            visibility: {
              type: 'boolean',
            },
            position: {
              type: 'number',
            },
          }}
        >
          <EventFormFieldFormContainer projectId={projectId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
        <Space vertical />
        <Divider />
        <Space vertical />
        <AddSystemRegistrationFormFieldsContainer projectId={projectId} />
      </WindowPanelContent>
    </WindowPanel>
  );
}
