import { connect } from 'react-redux';
import CRMDashboardEventsListPagePagination from './CRMDashboardEventsListPagePagination';
import { eventsListEventCountSelector } from '../../calendar-and-event-page/events-list/EventsListEventsDataDuck';
import { navigateToCRMDashboardPageWithFilter } from '../wokflow-stages/CRMDashboardWorkflowStagesDuck';

function mapStateToProps(state, ownProps: { filters: string }) {
  const { filters } = ownProps;

  return {
    totalItems: eventsListEventCountSelector(state),
    filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (filters) => {
      dispatch(navigateToCRMDashboardPageWithFilter(dispatch, 'list', filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMDashboardEventsListPagePagination);
