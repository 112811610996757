import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { PosterAdvocateService, Schemas } from '@premagic/core';
import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { pagesSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('POSTER_ADVOCATES');

const setPosterAdvocates = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchPosterAdvocatesForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch: Dispatch, projectId: string) => {
    const loadingKey = LOADINGS.POSTER_ADVOCATES.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    PosterAdvocateService.fetchPosterAdvocatesForProject(projectId)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.PosterAdvocate);
        dispatch(setPosterAdvocates(dispatch, normalizedData.entities.advocates));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Poster Advocates: fetch failed', e);
        return e;
      });
  },
);

type PosterAdvocatesStateType = {
  items: IMap<string, PosterAdvocateService.PosterAdvocateType>;
};

const initialState = {
  items: IMap([]),
};

export default handleActions(
  {
    [setPosterAdvocates.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const posterAdvocatesDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.eventReport.data.posterAdvocates as PosterAdvocatesStateType,
);

export const posterAdvocatesEntitiesSelector = createSelector(posterAdvocatesDataSelector, (state) =>
  // state.items.sortBy((advocate) => advocate.advocacy_count),
  state.items.sort((a, b) => (b?.advocacy_count || 0) - (a?.advocacy_count || 0)),
);

export const posterAdvocatesSelector = createSelector(posterAdvocatesEntitiesSelector, (advocates) =>
  advocates.toJSON(),
);
export const posterAdvocatesIdsSelector = createSelector(posterAdvocatesEntitiesSelector, (advocates) =>
  advocates.keySeq().toArray(),
);
