import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../common/Constants';
import { addFilesToFolderWithUrl, addUrlToFiles, foldersEntitiesSelector } from '../AccountFoldersDataDuck';
import { projectsSelector } from '../../AccountProjectsDataDuck';
import VideoLinkUploader from './VideoLinkUploader';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    projectId: string;
  },
) {
  const { projectId, folderId } = ownProps;

  const folder = foldersEntitiesSelector(state)[folderId];
  const project = projectsSelector(state)[projectId] || {};

  return {
    projectId,
    folderId,
    projectName: project.project_name,
    folder,
    show: ModalDuck.isModalOpenSelector(LOADINGS.FOLDER.ADD_EXTERNAL_VIDEO_LINK)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.ADD_FILES)(state),
    uploadedFileId: ModalDuck.modalOptionsSelector(LOADINGS.FOLDER.FILE_ID_FOR_EXTERNAL_VIDEO_LINK)(state) as string,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideExternalVideoLinkUploader: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.FOLDER.ADD_EXTERNAL_VIDEO_LINK, false));
    },
    uploadFiles: (files, options) => dispatch(addFilesToFolderWithUrl(dispatch, files, options)),
    addUrlToFiles: (options) => dispatch(addUrlToFiles(dispatch, options)),
    removeUploadedFileId: () =>
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.FOLDER.FILE_ID_FOR_EXTERNAL_VIDEO_LINK, '')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoLinkUploader);
