import React from 'react';
import { SCROLLABLE_CONTAINER_SIZE, ScrollableContainer } from '@premagic/myne';
import { QRCodeBackgroundPosterService } from '@premagic/core';
import AccountFolderPhotoDropZoneContainer from '../../../../projects/folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderFilesUploadErrorContainer from '../../../../projects/folders/folder-files-upload/FolderFilesUploadErrorContainer';
import FolderUploadProgressContainer from '../../../../projects/folders/folder-files-upload/FolderUploadProgressContainer';
import FolderGalleryWithFileSelectContainer from '../../../../projects/folders/gallery/FolderGalleryWithFileSelectContainer';

type Props = {
  projectId: string;
  folderId: string;
  onSelect: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    customIllustrationId?: string,
  ) => void;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodeIllustrationFiles(props: Props): JSX.Element | null {
  const { projectId, folderId, onSelect, qrCodeDesigns } = props;

  function handleOnSelect(selectedFileId) {
    onSelect(projectId, folderId, qrCodeDesigns, selectedFileId);
  }

  return (
    <div>
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
      <FolderUploadProgressContainer folderId={folderId} />
      <FolderFilesUploadErrorContainer folderId={folderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <FolderGalleryWithFileSelectContainer projectId={projectId} folderId={folderId} onSelect={handleOnSelect} />
      </ScrollableContainer>
    </div>
  );
}
