import {
  COLOR_SHADES,
  ErrorBoundary,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_GROUP_SIZES,
  TEXT_SIZE,
  TextGroup,
  TextGroupLabel,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { NumberUtils } from '@premagic/utils';
import { CompanyStatsService } from '@premagic/core';

function StatsContent(props: {
  itemId: CompanyStatsService.COMPANY_STATS_TYPE;
  stats: CompanyStatsService.CompanyStatsType;
  remaining: number;
}) {
  const { itemId, stats, remaining } = props;
  switch (itemId) {
    case CompanyStatsService.COMPANY_STATS_TYPE.AI_DELIVERY:
      return (
        <ErrorBoundary>
          <Text muted>
            <Text color={COLOR_SHADES.DARKER}>{stats[itemId]?.assets_scanned}</Text> Files
            <Space vertical size={1} />
            Remaining: {NumberUtils.getNumberInFormat(remaining)}
          </Text>
        </ErrorBoundary>
      );
    default:
      return <Text muted>Requires dev</Text>;
  }
}

type Props = {
  isLoading: boolean;
  stats?: CompanyStatsService.CompanyStatsType;
  initData: () => void;
  remainingAiDeliveryPhotos: number;
};

export default function CompanyStatsInfo(props: Props) {
  const { isLoading, stats, initData, remainingAiDeliveryPhotos } = props;

  useEffect(() => {
    initData();
  }, [initData]);

  if (isLoading) {
    return (
      <Row center>
        <LoadingDots size="sm" />
      </Row>
    );
  }

  if (!stats || isEmpty(stats)) {
    return (
      <Text muted center block size={TEXT_SIZE.SIZE_5}>
        No stats yet.
      </Text>
    );
  }

  return (
    <div>
      {Object.entries(stats).map(([key, value]) => {
        const details = CompanyStatsService.COMPANY_STATS_TYPE_DETAIL[key];
        return (
          <TextGroup key={key} size="sm">
            <TextGroupLabel size={TEXT_GROUP_SIZES.SM}>{details?.title || key}</TextGroupLabel>
            <StatsContent
              stats={stats}
              itemId={key as CompanyStatsService.COMPANY_STATS_TYPE}
              remaining={remainingAiDeliveryPhotos}
            />
          </TextGroup>
        );
      })}
    </div>
  );
}
