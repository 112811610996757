import React from 'react';
import { ErrorTracker, BrowserUrlUtils } from '@premagic/utils';
import { COLOR_SHADES } from '../Color/Color';
import { Text } from '../Text/Text';
import { Alert, ALERT_STYLES } from '../Alert/Alert';
import { Space } from '../Grid/Grid';
import { Button, BUTTON_STYLES } from '../Button/Buttons';

interface Props {
  children: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  onError?: (error) => void;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const { onError } = this.props;
    this.setState({ hasError: true });
    ErrorTracker.logError(error.name || 'ERROR_BOUNDARY', info);
    if (onError) onError(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Alert style={ALERT_STYLES.DANGER}>
          <Text color={COLOR_SHADES.DANGER}>Something went wrong, Please try</Text> <Space size={1} />
          <Button onClick={BrowserUrlUtils.reload} style={BUTTON_STYLES.LINK}>
            reloading
          </Button>
        </Alert>
      );
    }
    return children;
  }
}
