// https://github.com/premagic/premagic-server/blob/master/premagic/errors.py

export enum ERROR_CODES {
  C_401 = 401,
  C_18103 = 18103,
  C_18001 = 18001,
  USER_NOT_FOUND = 10006,
}
export const ERROR_CODE_DETAILS: Record<
  ERROR_CODES,
  {
    title: string;
    message: string;
  }
> = {
  [ERROR_CODES.C_401]: {
    title: 'Expired',
    message: 'Your Gallery has expired.',
  },
  [ERROR_CODES.C_18103]: {
    title: 'Opted out',
    message: 'Person has opted out of networking',
  },
  [ERROR_CODES.C_18001]: {
    title: 'Not Registered',
    message: 'Person is not registered ',
  },
  [ERROR_CODES.USER_NOT_FOUND]: {
    title: 'User not found',
    message: 'User not found ',
  },
};
