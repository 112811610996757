import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { SimpleFileType } from './FileService';
import { SOCIAL_NETWORKS } from './SocialAuthService';

export type PosterAdvocateType = {
  id: string;
  poster_id: string;
  name: string;
  email: string;
  profile_picture: string;
  created_at: string;
  posted_at: string;
  updated_at: string;
  poster_image?: SimpleFileType;
  post_url?: string;
  has_shared_poster?: boolean;
  advocacy_count?: number;
  advocacy_url?: string;
  platform: SOCIAL_NETWORKS;
};

export function fetchPosterAdvocatesForProject(projectId: string): Promise<Array<PosterAdvocateType>> {
  return HttpUtils.get(API_URLS.POSTER_ADVOCATES.FETCH(projectId)).then((response) => response.data);
}
