import { connect } from 'react-redux';
import { ProductFeedbackService } from '@premagic/core';
import EventFeedbackList from './EventFeedbackList';
import { eventFeedbackIdsByProjectIdSelector } from '../data-duck/EventFeedbackDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
  },
) {
  const { projectId, type } = ownProps;

  return {
    eventFeedbackIds: eventFeedbackIdsByProjectIdSelector(projectId, type)(state),
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFeedbackList);
