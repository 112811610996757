import { connect } from 'react-redux';
import PhotoSliderWidgetRightSidebar from './PhotoSliderWidgetRightSidebar';

function mapStateToProps(state, ownProps: { shareId?: string }) {
  const { shareId } = ownProps;
  return { shareId };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSliderWidgetRightSidebar);
