import { EventAttendeesCheckinZoneService } from '@premagic/core';
import { ColorPicker, FormGroup, FormLabel, Input, Select } from '@premagic/myne';
import React, { useMemo } from 'react';

const COMMON_ZONE_NAMES = [
  'Registration',
  'VIP',
  'General',
  'Media',
  'Press',
  'Speaker',
  'Sponsor',
  'Exhibitor',
  'Staff',
  'Crew',
  'Volunteer',
  'Security',
  'Medical',
  'Lost & Found',
  'Information',
  'Emergency',
  'Exit',
  'Entrance',
  'Stage',
  'Backstage',
  'Green Room',
  'Dressing Room',
  'Restroom',
  'Food & Beverage',
  'Bar',
  'Merchandise',
  'Swags',
  'Parking',
  'Transportation',
  'Shuttle',
  'Taxi',
  'Uber',
  'Lyft',
  'Bus',
  'Train',
  'Subway',
  'Airport',
  'Hotel',
  'Lounge',
  'Meeting Room',
  'Ballroom',
  'Concert Hall',
  'Theater',
  'Auditorium',
  'Conference Room',
  'Expo Hall',
  'Exhibit Hall',
  'Trade Show',
  'Convention',
  'Festival',
  'Fair',
  'Carnival',
];

type Props = {
  zoneData?: EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneType;
};

export default function EventAttendeeFaceChekinZoneForm(props: Props) {
  const { zoneData } = props;

  const { name, color } = zoneData || {};
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  const selectOptions = useMemo(
    () =>
      COMMON_ZONE_NAMES.map((item) => ({ label: item, value: item })).concat(
        name ? { label: name, value: name } : { label: 'Other', value: 'other' },
      ),
    [name],
  );
  const selectedOption = selectOptions.find((item) => item.value === name);

  return (
    <>
      <FormGroup>
        <FormLabel isRequired>Name of the Zone</FormLabel>
        <Select options={selectOptions} name="name" isMulti={false} value={selectedOption} isCreatable />
      </FormGroup>
      <FormGroup>
        <FormLabel isRequired>Color of the zone</FormLabel>
        <ColorPicker name="color" defaultValue={color || randomColor} />
      </FormGroup>
    </>
  );
}
