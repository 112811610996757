import { FolderService } from '@premagic/core';
import { connect } from 'react-redux';
import { navigateTo } from '../../../../../services/RouterService';
import { folderIdsWithFolderTypeForProjectSelector } from '../../projects/AccountProjectsDataDuck';
import WatermarkPreviewButton from './WatermarkPreviewButton';

function mapStateToProps(state, ownProps: { projectId: string; eventId?: string }) {
  const { projectId, eventId } = ownProps;

  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);

  return { projectId, folderId: brandingFolderIds[0], eventId };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      navigateTo(dispatch, url, {});
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermarkPreviewButton);
