import { GlobalVariableService, HelpDeskService } from '@premagic/core';
import React, { useRef, useState } from 'react';
import { Button, BUTTON_SIZE, BUTTON_STYLES } from '../Button/Buttons';
import { Color, COLOR_SHADES } from '../Color/Color';
import { Dialog, DialogFooter } from '../Dialog';
import { Form } from '../Form';
import { Row, Space } from '../Grid';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { Icon, ICON_ACCENT_STYLES, ICON_SIZES } from '../Icon/Icons';

type RequestFeatureEnablingProps = {
  featureName: string;
  projectName: string;
  onClose?: () => void;
};

export function RequestFeatureEnabling(props: RequestFeatureEnablingProps) {
  const { featureName, projectName, onClose } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const $button = useRef(null);

  function onSubmit() {
    HelpDeskService.raiseTicket({
      subject: `ENABLE ${featureName} for project ${projectName} `,
      email: GlobalVariableService.getGlobalRequestingUser().email || 'text@example.com',
      message: 'message will be empty',
      tags: ['sales', 'sales--enable-feature'],
    });
    setShowSuccess(true);
  }

  return (
    <>
      <Button size={BUTTON_SIZE.SM} style={BUTTON_STYLES.LINK} onClick={() => setShowDialog(!showDialog)} ref={$button}>
        Enable
        <Space size={1} />
        <Icon name="key" size={ICON_SIZES.SM} accentStyle={ICON_ACCENT_STYLES.FILL} />
      </Button>
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Row vcenter>
          <Color shade={COLOR_SHADES.DARKER} inline>
            <Icon name="key" size={ICON_SIZES.SM} accentStyle={ICON_ACCENT_STYLES.FILL} />
          </Color>
          <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            Enable for this project?
          </Text>
        </Row>
        <Space vertical />
        {showSuccess ? (
          <div>
            <Text
              block
              size={TEXT_SIZE.SIZE_3}
              center
              boldness={TEXT_BOLDNESS.BOLD}
              color={COLOR_SHADES.DARKER}
              primaryStyle
            >
              Great
            </Text>
            <Space vertical />
            <Text>Our Product specialist will enable the feature and contact you</Text>
            <DialogFooter>
              <Button
                style={BUTTON_STYLES.CANCEL}
                onClick={() => {
                  setShowDialog(false);
                  setShowSuccess(false);
                  if (onClose) onClose();
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <div>
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {featureName}
            </Text>{' '}
            <Text>feature can be enabled only by customer support</Text>
            <Space vertical />
            <Text>Would you like to raise a request to enable this feature only for this project?</Text>
            <Form onSubmit={onSubmit}>
              <DialogFooter>
                <Button
                  style={BUTTON_STYLES.CANCEL}
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  Cancel
                </Button>
                <Button style={BUTTON_STYLES.PRIMARY}>Raise Request</Button>
              </DialogFooter>
            </Form>
          </div>
        )}
      </Dialog>
    </>
  );
}
