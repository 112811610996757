import {
  BUTTON_STYLES,
  Button,
  ButtonIcon,
  Card,
  Col,
  Dialog,
  DialogFooter,
  DialogTitle,
  Dropdown,
  DropdownIcon,
  GeneralEditor,
  GeneralEditorContent,
  GeneralEditorHeader,
  GeneralEditorSidebar,
  ICON_SIZES,
  Icon,
  Link,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Page,
  Row,
  Space,
  TEXT_SIZE,
  TabItem,
  Tabs,
  Text,
} from '@premagic/myne';
import { BrowserUrlUtils, BrowserUtils, CanvasUtils } from '@premagic/utils';
import { useEffect, useRef, useState } from 'react';

import {
  BackgroundMusicService,
  ClientWebsiteService,
  EventTrackerService,
  QRCodeBackgroundPosterService,
} from '@premagic/core';
import MESSAGES from '../../../../../common/Messages';
import APP_URLS from '../../../services/AppRouteURLService';
import LEDScreenQRCodeContainer from './LEDScreenQRCodeContainer';
import PocketCardQRCodeContainer from './PocketCardQRCodeContainer';
import StandeeQRCodeContainer from './StandeeQRCodeContainer';
import TentQRCodeContainer from './TentQRCodeContainer';
import QRCodePosterAllModalsContainer from './modal/QRCodePosterAllModalsContainer';
import QRCodePageRightSidebarContainer from './side-bar/QRCodePageRightSidebarContainer';
import useQRCode from './custom-hooks/useQrCode';

// Classes for print and files names
const PRINT_CONTENT_CLASS = 'js-print-content';
const STANDEE_A3_FILE_NAME_CLASS = 'Standee';
const POCKET_CARD_FILE_NAME_CLASS = 'Pocket-Card';
const TENT_CARD_FILE_NAME_CLASS = 'Tent-Card';
const LED_SCREEN_CARD_FILE_NAME_CLASS = 'LED-Screen-Card';

function QRCodeDownloadables(props: { folderId: string; projectName?: string; url: string }) {
  const { folderId, projectName, url } = props;
  return (
    <>
      <StandeeQRCodeContainer
        printClass={PRINT_CONTENT_CLASS}
        fileNameClass={STANDEE_A3_FILE_NAME_CLASS}
        folderId={folderId}
        url={url}
      />
      <Space vertical size={12} />
      <PocketCardQRCodeContainer
        printClass={PRINT_CONTENT_CLASS}
        fileNameClass={POCKET_CARD_FILE_NAME_CLASS}
        folderId={folderId}
        url={url}
      />
      <Space vertical size={12} />
      <TentQRCodeContainer
        printClass={PRINT_CONTENT_CLASS}
        fileNameClass={TENT_CARD_FILE_NAME_CLASS}
        folderId={folderId}
        url={url}
      />
      <Space vertical size={12} />
      <LEDScreenQRCodeContainer
        printClass={PRINT_CONTENT_CLASS}
        fileNameClass={LED_SCREEN_CARD_FILE_NAME_CLASS}
        folderId={folderId}
        projectName={projectName}
        url={url}
      />
      <Row>
        <Space vertical size={16} />
      </Row>
    </>
  );
}

function QRCode(props: { folderId: string; url: string }) {
  const { folderId, url } = props;

  const { qrCode, isLoading } = useQRCode(url, 250);
  const { rawQrSvg } = qrCode || {};

  return (
    <Row center vcenter fullHeight>
      <Card>
        <div
          className={`${PRINT_CONTENT_CLASS} qr-code`}
          style={{
            width: 250 + 16 * 2,
            height: 250 + 16 * 2,
          }}
        >
          <Space>
            {isLoading ? <LoadingDots size="sm" /> : <div dangerouslySetInnerHTML={{ __html: rawQrSvg || '' }} />}
          </Space>
        </div>
      </Card>
    </Row>
  );
}

type DownloadButtonProps = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  view: 'downloadables' | 'qrcode' | 'svg';
  projectName: string;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  setShowDownloadInfoModal: any;
};
function DownloadButton(props: DownloadButtonProps) {
  const { clientWebsite, view, projectName, qrCodeDesigns, setShowDownloadInfoModal } = props;
  const [isLoading, setIsLoading] = useState(false);

  const $button = useRef(null);

  // This is the lever we use to change the image download quality (300 ppi is used to do the calculation)
  function getRenderScale(fileName): number {
    const SCALE_MAP = {
      [STANDEE_A3_FILE_NAME_CLASS]: 14,
      [TENT_CARD_FILE_NAME_CLASS]: 5,
      [POCKET_CARD_FILE_NAME_CLASS]: 2,
      [LED_SCREEN_CARD_FILE_NAME_CLASS]: 16,
    };

    return SCALE_MAP[fileName] || 14;
  }

  enum ELEMENT_DOWNLOAD_TYPE {
    PDF = 'pdf',
    IMAGE = 'image',
  }
  function onDownload(downloadType: ELEMENT_DOWNLOAD_TYPE) {
    const elements = BrowserUtils.getElements(`.${PRINT_CONTENT_CLASS}`);

    function onDownloadComplete() {
      setIsLoading(false);
      setShowDownloadInfoModal(false);
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CLIENT_WEBSITE.QR_CODE_DESIGN, {
        qrCodeWithPoster: true,
        qrCodeDesigns,
        downloadType,
      });
    }

    if (view === 'svg') {
      const svg = BrowserUtils.getElement(`.${PRINT_CONTENT_CLASS} svg`);
      BrowserUtils.downloadAsFile({ file: BrowserUtils.createSVGFile(svg), fileName: `${projectName}-qr-code` });
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CLIENT_WEBSITE.QR_CODE_DESIGN, {
        qrCodeWithPoster: false,
        qrCodeDesigns: null,
      });
      setIsLoading(false);
      setShowDownloadInfoModal(false);
      return;
    }

    if (elements) {
      const scaleToUse: Array<number> = [];
      const fileNames: Array<string> = [];
      elements.forEach((el) => {
        const classNames = Array.from(el.classList);
        const fileName = classNames[1];
        fileNames.push(`${projectName}-${fileName}.jpg`);
        scaleToUse.push(getRenderScale(fileName));
      });

      if (downloadType === ELEMENT_DOWNLOAD_TYPE.PDF) {
        elements.forEach((el, idx) => {
          CanvasUtils.downloadElementsAsPdf([el], fileNames[idx], scaleToUse[idx], false, () => {
            if (idx === elements.length - 1) {
              onDownloadComplete();
            }
          });
        });
      } else if (downloadType === ELEMENT_DOWNLOAD_TYPE.IMAGE) {
        CanvasUtils.downloadElementsAsImages(elements, fileNames, scaleToUse, onDownloadComplete);
      }

      // Download the LED Screen QR in jpeg format as well when type is pdf
      if (downloadType === ELEMENT_DOWNLOAD_TYPE.PDF) {
        elements.forEach((el) => {
          const classNames = Array.from(el.classList);
          const fileName = classNames[1];
          if (fileName === LED_SCREEN_CARD_FILE_NAME_CLASS) {
            CanvasUtils.downloadElementsAsImages(
              [el],
              [`${projectName}-${fileName}.jpg`],
              scaleToUse,
              onDownloadComplete,
            );
          }
        });
      }

      // Download the QR Code Scanning intro male voice
      const qrCodeIntroMaleVoiceMp3 = BackgroundMusicService.getServerPath('qr-code-intro-male-voice.mp3');
      BrowserUtils.downloadAsFile({
        file: qrCodeIntroMaleVoiceMp3,
        fileName: 'QR-Code-intro-male-voice.mp3',
      });
    }
  }

  function handleDownload(downloadType: ELEMENT_DOWNLOAD_TYPE) {
    setIsLoading(true);
    setShowDownloadInfoModal(true);
    setTimeout(() => {
      onDownload(downloadType);
    }, 100);
  }

  return (
    <>
      <Row inline vcenter>
        <Button
          style={BUTTON_STYLES.PRIMARY}
          isLoading={isLoading}
          onClick={() => handleDownload(ELEMENT_DOWNLOAD_TYPE.PDF)}
          ref={$button}
        >
          <Icon name="download" size={ICON_SIZES.SM} />
          <Space size={1} />
          Download
        </Button>
        <Space size={2} />
        <Dropdown>
          <DropdownIcon title="More" tooltipPlacement="left">
            {isLoading ? <LoadingDots size="xs" /> : <Icon name="more_vertical" />}
          </DropdownIcon>
          <Menu>
            <MenuItem onClick={() => handleDownload(ELEMENT_DOWNLOAD_TYPE.IMAGE)}>
              <MenuItemIcon name="download" />
              <Space size={1} />
              Download as Image
            </MenuItem>
          </Menu>
        </Dropdown>
      </Row>
      {/* Keeping it incase we need it later (!BrowserUtils.isChromeBrowser()) */}
      {/* {showDialog && (
        <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>Download QR Code</DialogTitle>
          <Text size={TEXT_SIZE.SIZE_3}>
            To ensure optimal display quality, we highly recommend using the Google chrome browser when downloading the
            QR code posters
          </Text>
          <Space vertical size={4} />
          <Row vcenter>
            <Icon size={ICON_SIZES.MD_SM} name="chrome" />
            <Space size={1} />
            <Link showExternalLinkIcon isExternalLink to="https://www.google.com/chrome/">
              Download Google Chrome
            </Link>
          </Row>
          <Space vertical size={6} />
          <DialogFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              onClick={() => handleDownloadClick(ELEMENT_DOWNLOAD_TYPE.PDF)}
              isLoading={isLoading}
              disabled={BrowserUtils.isSafariBrowser()}
            >
              Ok, Understand
            </Button>
          </DialogFooter>
        </Dialog>
      )} */}
    </>
  );
}

const VIEW_MAP = {
  downloadables: QRCodeDownloadables,
  qrcode: QRCode,
  svg: QRCode,
};

type Props = {
  projectId: string;
  id: string;
  url: string;
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  fetchClientWebsite: (clientWebsiteId: string, projectId: string) => void;
  isLoading: boolean;
  view: 'downloadables' | 'qrcode';
  currentUrlPathname: string;
  isMobileUp: boolean;
  browserBack: (defaultUrl: string) => void;
  eventId?: string;
  navigateTo: (url: string) => void;
  fetchFolderMeta: (projectId: string, id: string) => void;
  projectName: string;
  fetchProjects: () => void;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  fetchSponsorsData: (projectId: string, sponsorFolderId: string) => void;
  sponsorFolderId?: string;
  hasEventSponsorshipFeatureEnabled?: boolean;
  resetSponsors: () => void;
};

export default function ClientWebsitePrintQRCodePage(props: Props) {
  const {
    projectId,
    url,
    clientWebsite,
    id,
    fetchClientWebsite,
    isLoading,
    view,
    currentUrlPathname,
    isMobileUp,
    browserBack,
    eventId,
    navigateTo,
    fetchFolderMeta,
    fetchProjects,
    projectName,
    qrCodeDesigns,
    fetchSponsorsData,
    sponsorFolderId,
    hasEventSponsorshipFeatureEnabled,
    resetSponsors,
  } = props;

  const [showDownloadInfoModal, setShowDownloadInfoModal] = useState(false);

  useEffect(() => {
    if (id && !clientWebsite?.share_url) fetchClientWebsite(id, projectId);
    if (id) fetchFolderMeta(projectId, id);
    fetchProjects();
  }, [id, clientWebsite?.share_url, fetchClientWebsite, fetchFolderMeta, projectId]);

  useEffect(() => {
    resetSponsors();
    if (!hasEventSponsorshipFeatureEnabled) return;
    if (projectId && sponsorFolderId) fetchSponsorsData(projectId, sponsorFolderId);
  }, [hasEventSponsorshipFeatureEnabled, projectId, sponsorFolderId, fetchSponsorsData, resetSponsors]);

  if (isLoading) {
    return (
      <Page center>
        <LoadingDots size="md" />
      </Page>
    );
  }

  if (!clientWebsite || !clientWebsite.share_url) {
    return (
      <Page>
        <Text>{MESSAGES.CLIENT_WEBSITE.NAME} is not shared</Text>
      </Page>
    );
  }

  function getRoute(v: 'downloadables' | 'qrcode' | 'svg') {
    return BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.PRINT_INVITE, {
      projectId,
      id,
      view: v,
    });
  }

  function handleBack() {
    if (eventId) {
      const urlForDeliverable = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
        eventId,
      });
      navigateTo(urlForDeliverable);
    } else {
      browserBack(APP_URLS.CRM.INDEX);
    }
  }

  const Component = VIEW_MAP[view];

  return (
    <>
      <GeneralEditor hasLeftPanel={false} rightPanelSize="md">
        <GeneralEditorHeader>
          <div className="hidden-print">
            <Tabs>
              <ButtonIcon title="Back" tooltipPlacement="bottom" onClick={handleBack}>
                <Icon name="arrow_left" size={ICON_SIZES.SM} />
              </ButtonIcon>
              <Space size={4} />
              <TabItem to={getRoute('downloadables')} active={currentUrlPathname.includes(getRoute('downloadables'))}>
                Downloadables
              </TabItem>
              <TabItem to={getRoute('qrcode')} active={currentUrlPathname.includes(getRoute('qrcode'))}>
                QR Code
              </TabItem>
              <TabItem to={getRoute('svg')} active={currentUrlPathname.includes(getRoute('svg'))}>
                SVG
              </TabItem>
              <Col size={null} rightAlighed>
                <DownloadButton
                  clientWebsite={clientWebsite}
                  view={view}
                  projectName={projectName}
                  qrCodeDesigns={qrCodeDesigns}
                  setShowDownloadInfoModal={setShowDownloadInfoModal}
                />
                <Space />
              </Col>
            </Tabs>
          </div>
        </GeneralEditorHeader>
        <GeneralEditorContent>
          <Component folderId={id} projectName={projectName} url={url} />
        </GeneralEditorContent>
        <GeneralEditorSidebar isRight>
          <QRCodePageRightSidebarContainer folderId={id} projectId={projectId} />
        </GeneralEditorSidebar>
      </GeneralEditor>

      <QRCodePosterAllModalsContainer
        projectId={projectId}
        folderId={id}
        showDownloadInfoModal={showDownloadInfoModal}
      />
    </>
  );
}
