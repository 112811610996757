import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { attendeeListStyleSelector, updatePeopleListStyle } from './EventAttendeesPageDuck';
import EventAttendeeListMenu from './EventAttendeeListMenu';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  return {
    projectId: ownProps.projectId,
    listStyle: attendeeListStyleSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state) ||
      isLoadingSelector(LOADINGS.PROJECT.FETCH)(state) ||
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.DOWNLOAD)(state) ||
      isLoadingSelector(LOADINGS.INTEGRATION.CONNECT_AND_SYNC_EVENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateListStyle: (style) => {
      dispatch(updatePeopleListStyle(dispatch, style));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeListMenu);
