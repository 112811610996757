import { AssetsService, EventTrackerService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  IMAGE_V2_STYLES,
  ImageV2,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../services/AppRouteURLService';
import { NO_PUBLIC_FOLDER_ALERT_INFO } from '../service/PhotoSliderWidgetService';

type Props = {
  eventId?: string;
  projectId: string;
  navigateTo: (url: string) => void;
  shareId?: string;
};

export default function PhotoSliderWidgetNavigateButton(props: Props) {
  const { eventId, projectId, navigateTo, shareId } = props;

  function handleNavigateToWidgetPage() {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.WIDGET.PHOTO_GALLERY.CLICK_PREVIEW, {});
    const url = BrowserUrlUtils.getRouteUrlFor(APP_URLS.WIDGET.PHOTO_GALLERY, {
      eventId,
      projectId,
      shareId,
    });
    navigateTo(url);
  }

  return (
    <>
      <Text block color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
        Embed photos on your website
      </Text>
      <Text muted size={TEXT_SIZE.SIZE_5}>
        {NO_PUBLIC_FOLDER_ALERT_INFO}
      </Text>
      <Space vertical />

      <Button style={BUTTON_STYLES.LINK} onClick={handleNavigateToWidgetPage} block>
        <ImageV2
          src={AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.PHOTO_SLIDER_WIDGET_PREVIEW)}
          style={IMAGE_V2_STYLES.THUMBNAIL}
          alt="photo-slider-widget-preview-illustration"
        />
        <Space vertical size={1} />
        Show Embed Code
      </Button>
    </>
  );
}
