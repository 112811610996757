import { Alert, ALERT_STYLES, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import { FileService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';

type Props = {
  filesUploadStats: Record<FileService.FILE_UPLOAD_STAGES, number>;
  totalUploaderFiles: number;
  hasFilePassedTimeout: boolean;
};

export default function FolderFilesUploadError(props: Props): JSX.Element | null {
  const { filesUploadStats, totalUploaderFiles, hasFilePassedTimeout } = props;
  if (!hasFilePassedTimeout) return null;
  const filesPending = filesUploadStats.QUEUED + filesUploadStats.ERROR;
  const isThereMorePhotosInTheQueueThanInUploader = totalUploaderFiles < filesPending; // If there are less files in uploader than the queued and error files, then show error
  const showError = isThereMorePhotosInTheQueueThanInUploader;

  if (!showError) return null;

  return (
    <>
      <Alert style={ALERT_STYLES.DANGER}>
        <Text block boldness={TEXT_BOLDNESS.BOLD}>
          {filesPending} {StringUtils.pluralize('file', filesPending)} were not uploaded.
        </Text>
        <Text>Please drop the files again to upload.</Text>
      </Alert>
      <Space vertical />
    </>
  );
}
