import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum PROJECT_NERD_STATS_TYPE {
  AI_DELIVERY = 'ai_delivery',
  EVENT_REGISTRATION = 'event_registration',
  UPLOAD_TIMES = 'last_upload_time',
  QUALITY = 'quality',
}

export const PROJECT_NERD_STATS_TYPE_DETAIL: Record<PROJECT_NERD_STATS_TYPE, { title: string }> = {
  [PROJECT_NERD_STATS_TYPE.AI_DELIVERY]: {
    title: 'AI delivery',
  },
  [PROJECT_NERD_STATS_TYPE.EVENT_REGISTRATION]: {
    title: 'Event Registration',
  },
  [PROJECT_NERD_STATS_TYPE.UPLOAD_TIMES]: {
    title: 'Uploads',
  },
  [PROJECT_NERD_STATS_TYPE.QUALITY]: {
    title: 'Upload Quality',
  },
};

export type ProjectNerdStatsType = {
  [PROJECT_NERD_STATS_TYPE.AI_DELIVERY]: {
    assets_scanned: number;
    faces_scanned: number;
  };
  [PROJECT_NERD_STATS_TYPE.EVENT_REGISTRATION]: {
    users_followup_notification_sent: number;
    users_photo_delivered: number;
    event_registration_count: number;
  };
  [PROJECT_NERD_STATS_TYPE.UPLOAD_TIMES]: Record<string, string>;
  [PROJECT_NERD_STATS_TYPE.QUALITY]: Record<string, string>;
};

export function fetchProjectNerdStats(projectId: string): Promise<ProjectNerdStatsType> {
  const URL = API_URLS.PROJECT.PROJECT_STATUS(projectId);

  return HttpUtils.get(URL).then((response) => response.data);
}
