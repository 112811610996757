import { Route, Switch } from 'react-router-dom';

import { EventsService } from '@premagic/core';
import {
  COLOR_SHADES,
  Emoji,
  EMOJIE_SIZE,
  EMOJIS,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  InnerPageHeader,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';

import MESSAGES from '../../../../../common/Messages';
import APP_URLS from '../../../services/AppRouteURLService';
import CreateClientPageContainer from '../clients/CreateEventWithClientPageContainer';
import EventDetailsPageContainer from '../events/details/EventDetailsPageContainer';
import { PERMISSIONS } from '../users/UserPermissionService';

export default function AddEventWithClientPage(props: {
  companyLeadForms: {
    contactCard: string;
    form: string;
  };
  hasEventManagementPermission: boolean;
  hasLeadsModule: boolean;
  filterObject?: {
    event_status: EventsService.EVENT_STATUS;
  };
}): JSX.Element {
  const backToUrl = APP_URLS.CRM.INDEX;
  const { companyLeadForms, hasEventManagementPermission, hasLeadsModule, filterObject } = props;
  const title = filterObject?.event_status === EventsService.EVENT_STATUS.NEW ? 'Add Lead' : 'Add Event';
  const { contactCard, form } = companyLeadForms;

  return (
    <InnerPage>
      <InnerPageHeader iconName="trello" title={title} backTo={backToUrl}>
        {title}
      </InnerPageHeader>
      {hasEventManagementPermission ? (
        <InnerPageContent hasInnerCols>
          <InnerPageContentMainCol>
            <Switch>
              <Route path={APP_URLS.CRM.CREATE__CLIENT} exact component={CreateClientPageContainer} />
              <Route path={APP_URLS.CRM.EVENT} exact component={EventDetailsPageContainer} />
            </Switch>
          </InnerPageContentMainCol>
          {hasLeadsModule && (
            <InnerPageContentSidebar>
              <Text block color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
                Let the client fill out the enquiry form?
              </Text>
              <Space vertical size={1} />
              <Text muted block>
                Portfolio Contact Card{' '}
                <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(contactCard)} isExternalLink showExternalLinkIcon>
                  {contactCard}
                </SimpleLink>
              </Text>
              <Space vertical />
              <Text muted block>
                Embed Company Lead form{' '}
                <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(form)} isExternalLink showExternalLinkIcon>
                  {form}
                </SimpleLink>
              </Text>
            </InnerPageContentSidebar>
          )}
        </InnerPageContent>
      ) : (
        <InnerPageContent>
          <Space vertical size={16} />
          <Row columnDirection center>
            <Emoji name={EMOJIS.PROHIBITED} size={EMOJIE_SIZE.LG} />
            <Space vertical size={4} />
            <Text boldness={TEXT_BOLDNESS.BOLD} muted>
              {MESSAGES.PERMISSION[PERMISSIONS.EVENT_MANAGEMENT]}
            </Text>
          </Row>
        </InnerPageContent>
      )}
    </InnerPage>
  );
}
