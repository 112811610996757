import {
  BUTTON_STYLES,
  Button,
  CARD_STYLES,
  Card,
  Divider,
  EscapedParagraphHTMLText,
  Row,
  Space,
  SubTitle,
  Text,
} from '@premagic/myne';
import React from 'react';

type Props = { galleryWelcomeText: string; galleryEndingText: string; showPanel: () => void };

export default function HighlightDesignTextCard(props: Props) {
  const { galleryWelcomeText, galleryEndingText, showPanel } = props;
  return (
    <Card style={CARD_STYLES.PRIMARY}>
      <Space>
        <Row spaceBetween vcenter>
          <SubTitle>Welcome Text</SubTitle>
          <Button style={BUTTON_STYLES.LINK} onClick={() => showPanel()}>
            Edit
          </Button>
        </Row>
        <Space vertical size={2} />
        <EscapedParagraphHTMLText value={galleryWelcomeText} />
        <Space vertical />
        <Divider />
        <Space vertical />
        <SubTitle>Ending Text</SubTitle>
        <Space vertical size={2} />
        <EscapedParagraphHTMLText value={galleryEndingText} />
      </Space>
    </Card>
  );
}
