import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Section,
  SECTION_STYLES,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { FolderService } from '@premagic/core';
import ClientWebsiteCardContainer from './ClientWebsiteCardContainer';
import MESSAGES from '../../../../../../common/Messages';
import APP_URLS, { getRouteUrlFor } from '../../../../services/AppRouteURLService';
import CreateBasicClientWebsitePanelContainer from './CreateBasicClientWebsitePanelContainer';
import QuickEditClientWebsitePanelContainer from '../../../client-website/edit/QuickEditClientWebsitePanelContainer';

type Props = {
  eventId?: string;
  clientWebsiteIds: Array<string>;
  focusId?: string;
  projectId: string;
  hasClientWebsiteFeature: boolean;
  showCreateBasicInvite: () => void;
};

export default function ClientWebsitesSection(props: Props) {
  const { clientWebsiteIds, focusId, projectId, hasClientWebsiteFeature, showCreateBasicInvite, eventId } = props;
  const linkToCreate = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.CREATE, { projectId });

  if (clientWebsiteIds.length === 0) {
    return (
      <div>
        <Card highlight={focusId === FolderService.FOLDER_TYPES.CLIENT_WEBSITE}>
          <Section style={SECTION_STYLES.YELLOW_LIGHT} hasPadding={false}>
            <Space>
              <Text primaryStyle size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} block>
                {MESSAGES.CLIENT_WEBSITE.NAME}
              </Text>
              <Text block>{MESSAGES.CLIENT_WEBSITE.INFO}</Text>
            </Space>
          </Section>
          <Space>
            <Button
              style={BUTTON_STYLES.RESET}
              onClick={() => {
                // We are doing this coz the action to create basic dwi require eventId
                if (eventId) showCreateBasicInvite();
              }}
              link={eventId ? undefined : linkToCreate}
              disabled={!hasClientWebsiteFeature}
              disabledMessage={MESSAGES.CLIENT_WEBSITE.NOT_AVAILABLE}
            >
              <Card style={CARD_STYLES.TRANSPARENT}>
                <Space>
                  <Row columnDirection center>
                    <Color shade={COLOR_SHADES.BLUE}>
                      <Icon name="plus" size={ICON_SIZES.MD} />
                    </Color>
                    <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.BLUE}>
                      {MESSAGES.CLIENT_WEBSITE.NAME}
                    </Text>
                  </Row>
                </Space>
              </Card>
            </Button>
          </Space>
          {eventId && <CreateBasicClientWebsitePanelContainer eventId={eventId} projectId={projectId} />}
        </Card>
        <Space vertical size={6} />
      </div>
    );
  }

  return (
    <div>
      {clientWebsiteIds.map((clientWebsiteId) => (
        <ClientWebsiteCardContainer
          eventId={eventId}
          id={clientWebsiteId}
          projectId={projectId}
          key={clientWebsiteId}
          focus={focusId === clientWebsiteId}
        />
      ))}
      {eventId && <CreateBasicClientWebsitePanelContainer eventId={eventId} projectId={projectId} />}
      {projectId && <QuickEditClientWebsitePanelContainer eventId={eventId} projectId={projectId} />}
      <Space vertical size={6} />
    </div>
  );
}
