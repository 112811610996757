import { ModalDuck, UploaderDuck } from '@premagic/common-ducks';
import { FolderService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../../common/Constants';
import { filesSelector } from '../../../../../../images/FilesDataDuck';
import { foldersArrayForProjectSelector } from '../../../../../../projects/AccountProjectsDataDuck';
import { fetchFolder } from '../../../../../../projects/folders/AccountFoldersDataDuck';
import { eventSponsorsArraySelector } from '../../../../event-sponsors/EventSponsorDataDuck';
import { getSponsorCreativeDataById } from '../../../SponsorCreativeDataDuck';
import SponsorWatermarkForm from './SponsorWatermarkForm';

const BRANDING_FOLDER_FILES = LOADINGS.BRANDING_FOLDER.SELECT_FILE_MODAL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    sponsorCreativeWatermarkId?: string;
  },
) {
  const { projectId, sponsorCreativeWatermarkId } = ownProps;

  const allFoldersForPoject = foldersArrayForProjectSelector(projectId)(state);
  const highlightFolders = allFoldersForPoject?.filter(
    (folder) => folder.folder_type === FolderService.FOLDER_TYPES.HIGHLIGHT,
  );

  return {
    projectId,
    files: filesSelector(state),
    sponsorCreativeWatermark: sponsorCreativeWatermarkId
      ? getSponsorCreativeDataById(sponsorCreativeWatermarkId)(state)
      : undefined,
    isUploading: UploaderDuck.isUploadingFilesSelector(state),
    sponsors: eventSponsorsArraySelector(state),
    folders: highlightFolders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFolderFiles: (projectId, folderId) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
    openFilePicker: (name) => {
      dispatch(
        ModalDuck.setModalOptions(dispatch, BRANDING_FOLDER_FILES, {
          [name]: {
            name,
          },
        }),
      );
      dispatch(ModalDuck.toggleModalVisibility(dispatch, BRANDING_FOLDER_FILES, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorWatermarkForm);
