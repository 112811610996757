import { GeneralImageEditorService } from '@premagic/core';
import { PixelCrop } from 'react-image-crop';
import { connect } from 'react-redux';
import GeneralImageEditor from './GeneralImageEditor';

function mapStateToProps(
  state,
  ownProps: {
    imageSrc: string;
    onChange: (val: GeneralImageEditorService.ImageEditorImageMetaDataType) => void;
    handleRemovePhoto?: () => void;
    handleChangePhoto?: () => void;
    defaultCrop?: PixelCrop;
    defaultScale?: number;
    defaultRotate?: number;
    imageContainerBoxDimensions?: { width: string; height: string };
    defaultAspectRatio?: number;
  },
) {
  const {
    imageSrc,
    onChange,
    handleRemovePhoto,
    handleChangePhoto,
    defaultCrop,
    defaultScale,
    defaultRotate,
    imageContainerBoxDimensions,
    defaultAspectRatio,
  } = ownProps;

  return {
    imageSrc,
    onChange,
    handleRemovePhoto,
    handleChangePhoto,
    defaultCrop,
    defaultScale,
    defaultRotate,
    imageContainerBoxDimensions,
    defaultAspectRatio,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralImageEditor);
