import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';

import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { pagesSelector } from '../../reducers/selectors';
import { setReportProjectsData } from './ReportProjectsDataDuck';
import { fetchProjectsInsight } from './ReportService';

const getActionType = ActionTypeUtils.getActionTypeFunction('REPORT_INSIGHT', true);

export const fetchProjectInsightData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.REPORTS.FETCH_ALL;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchProjectsInsight()
    .then((response) => {
      dispatch(setReportProjectsData(dispatch, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

const initialState = {};
export default handleActions({}, initialState);

export const reportsPageSelector = createSelector(pagesSelector, (state: any) => state.reports.page);
