import { MediaUtils } from '@premagic/utils';
import React from 'react';
import { Group as CanvasGroup, Rect as CanvasRect } from 'react-konva';
import {
  POSTER_WIDGET_TYPE,
  PosterGuestImagePortraitType,
  PosterWidgetTextType,
} from '../services/PosterWidgetService';
import { PosterFileType } from '../services/PosterService';
import { PosterText } from './BaseWidgets';
import PosterImage from './PosterImage';

function getSizeForTheImage(options: { file?: PosterFileType; size: { width: number; height?: number } }) {
  const { file, size } = options;
  const { width, height = 0 } = size;
  const ratio = file?.meta?.ratio || 1;
  const heightForWidth = MediaUtils.getImageHeightForRatio(width, ratio);
  const widthForHeight = MediaUtils.getImageWidthForRatio(height, ratio);
  return ratio > 1 ? { width, height: heightForWidth } : { width: widthForHeight, height };
}

type PosterGuestImagePortraitProps = {
  data: PosterGuestImagePortraitType;
  files: Record<string, PosterFileType>;
};

const PosterGuestImagePortrait = React.forwardRef((props: PosterGuestImagePortraitProps, ref: React.Ref<any>) => {
  const { data, files, ...rest } = props;
  const { position, size, value, id } = data;
  const { width, height = 0 } = size;
  const file = value ? files[value] : undefined;
  const resizeCard = true;
  const sizeForImage = resizeCard
    ? getSizeForTheImage({
        file,
        size,
      })
    : size;

  const color = '#fff';
  const placeHolderColor = '#4a90e2';
  const fontsize = width * 0.1; // scale based on the width
  const photoFrameProps = {
    fill: placeHolderColor,
    cornerRadius: width * 0.1,
    opacity: 0.8,
  };
  const textProps: PosterWidgetTextType = {
    type: POSTER_WIDGET_TYPE.TEXT,
    id: 'text',
    name: 'text',
    font_color: 'rgba(255,255,255,0.8)',
    position: {
      x: position.x + fontsize,
      y: position.y + fontsize,
      z: 3,
      rotation: position.rotation,
    },
    size: {
      width: Math.min(fontsize * 10, width),
      height: fontsize,
    },
    font_size: fontsize,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  if (!value)
    return (
      <CanvasGroup>
        <CanvasRect
          id="photo-frame"
          ref={ref}
          x={position.x}
          y={position.y}
          z={position.z}
          rotation={position.rotation}
          width={width}
          height={height}
          {...photoFrameProps}
          {...rest}
        />
        <PosterText data={{ ...textProps, value: 'Portrait' }} />
      </CanvasGroup>
    );

  return (
    <CanvasGroup>
      <PosterImage
        ref={ref}
        data={{
          ...data,
          type: POSTER_WIDGET_TYPE.IMAGE,
          size: sizeForImage,
        }}
        files={files}
        {...rest}
      />
    </CanvasGroup>
  );
});

export default PosterGuestImagePortrait;
