import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import ExpensePaymentFormContainer from './ExpensePaymentFormContainer';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  eventId: string;
  addPayment: (eventId: string, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  hasActiveSubscription: boolean;
};

export default function AddExpensePaymentPanel(props: Props): JSX.Element {
  const { show, addPayment, isLoading, errors, closePanel, eventId, hasActiveSubscription } = props;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Add Expense payment</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={(data) => addPayment(eventId, data)}
          formSpec={{
            is_settled: {
              type: 'boolean',
            },
            payment_category: {
              type: 'number',
            },
          }}
        >
          <ExpensePaymentFormContainer />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button
              size={BUTTON_SIZE.LG}
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!hasActiveSubscription}
              disabledMessage={MESSAGES.SUBSCRIPTIONS.INACTIVE}
            >
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
