import React, { useEffect } from 'react';

import { Button, BUTTON_STYLES, COLOR_SHADES, InnerPageContentMainCol, Space, Text, TEXT_SIZE } from '@premagic/myne';

import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';

import EventExhibitorListContainer from './EventExhibitorListContainer';
import AddEventExhibitorPanelContainer from './create/AddEventExhibitorPanelContainer';
import EditEventExhibitorPanelContainer from './edit/EditEventExhibitorPanelContainer';
import SponsorFilesSelectFileForCreativeModalContainer from '../../../projects/sponsor/sponsor-files/SponsorFilesSelectFileForCreativeModalContainer';

type Props = {
  eventId: string;
  projectId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_EXHIBITOR]: boolean;
  };
  hasEventExhibitorFeatureEnabled: boolean;
  isReleased: boolean;
  sponsorFolderId?: string;
  fetchFolderFiles: (projectId: string, folderId: string) => void;
  createFolder: (projectId: string) => void;
  isCreatingBrandingFolder: boolean;
};

export default function EventExhibitorLayout(props: Props): React.ReactElement {
  const {
    eventId,
    projectId,
    hasPermissions,
    hasEventExhibitorFeatureEnabled,
    isReleased,
    sponsorFolderId,
    fetchFolderFiles,
    createFolder,
    isCreatingBrandingFolder,
  } = props;

  useEffect(() => {
    if (!projectId || !sponsorFolderId) return;
    fetchFolderFiles(projectId, sponsorFolderId);
  }, [projectId, sponsorFolderId, fetchFolderFiles]);

  if (!projectId)
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text>Convert to Project for adding event {MESSAGES.EVENT_EXHIBITOR.NAME}</Text>
        </Space>
      </InnerPageContentMainCol>
    );

  if (!hasEventExhibitorFeatureEnabled)
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text>{MESSAGES.EVENT_EXHIBITOR.UPGRADE}</Text>
        </Space>
      </InnerPageContentMainCol>
    );

  if (!sponsorFolderId) {
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARK} block>
            {MESSAGES.EVENT_EXHIBITOR.ENABLE}
          </Text>
          <Space vertical />
          <Button
            onClick={() => createFolder(projectId)}
            isLoading={isCreatingBrandingFolder}
            style={BUTTON_STYLES.PRIMARY}
          >
            Enable Event Exhibitor
          </Button>
        </Space>
      </InnerPageContentMainCol>
    );
  }
  return (
    <InnerPageContentMainCol>
      {hasPermissions[PERMISSIONS.EVENT_EXHIBITOR] && (
        <div>
          <EventExhibitorListContainer eventId={eventId} projectId={projectId} />
        </div>
      )}
      <AddEventExhibitorPanelContainer projectId={projectId} />
      <EditEventExhibitorPanelContainer projectId={projectId} />
      {/* using sponsor folder to store exhibitor logo */}
      <SponsorFilesSelectFileForCreativeModalContainer projectId={projectId} />
    </InnerPageContentMainCol>
  );
}
