import { InputPhoneNumber } from '@premagic/myne';
import { connect } from 'react-redux';
import { accountCountryCodeSelector } from './AccountDataDuck';

function InputPhoneNumberContainer(props: {
  name: string;
  defaultValue?: string;
  defaultCountryCode?: string;
  required?: boolean;
}) {
  const { name, defaultValue, defaultCountryCode, required } = props;
  return (
    <InputPhoneNumber
      name={name}
      defaultValue={defaultValue}
      defaultCountryCode={defaultCountryCode}
      required={required}
    />
  );
}

function mapStateToProps(
  state,
  ownProps: {
    name: string;
    defaultValue?: string;
    required?: boolean;
  },
) {
  return {
    ...ownProps,
    defaultCountryCode: accountCountryCodeSelector(state),
  };
}

export default connect(mapStateToProps)(InputPhoneNumberContainer);
