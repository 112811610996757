import {
  Button,
  BUTTON_STYLES,
  Card,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';

import { FolderService } from '@premagic/core';
import MESSAGES from '../../../../../common/Messages';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  projectId: string;
  folder?: FolderService.FolderType;
  registrationLink?: string;
};

export default function EventUserRegistrationDetails(props: Props) {
  const { projectId, folder, registrationLink } = props;

  if (!folder || !folder.share_url || /[?&]preview=/.test(folder.share_url)) {
    return (
      <Card>
        <Space>
          <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.WARNING} block>
            AI delivery works along with {MESSAGES.CLIENT_WEBSITE.NAME}
          </Text>
          <Space vertical />
          {!folder?.is_shared ? (
            <Text>
              <Text boldness={TEXT_BOLDNESS.BOLD}>Share the {MESSAGES.CLIENT_WEBSITE.NAME}</Text> to get the
              Registration link & QR code
            </Text>
          ) : (
            <Text>
              <Text boldness={TEXT_BOLDNESS.BOLD}>Create a {MESSAGES.CLIENT_WEBSITE.NAME}</Text> to get Registration
              Link & QR code
            </Text>
          )}
        </Space>
      </Card>
    );
  }
  const { folder_id: id } = folder;
  const qrCodePrintUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.PRINT_INVITE, {
    projectId,
    id,
    view: 'downloadables',
  });

  return (
    <div>
      <Button link={registrationLink} style={BUTTON_STYLES.LINK} isExternalLink>
        <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} />
        Registration Link
      </Button>
      <Space size={6} />
      <Button link={qrCodePrintUrl} style={BUTTON_STYLES.LINK}>
        <Icon name="printer" size={ICON_SIZES.SM} /> <Space size={1} /> Print QR Code
      </Button>
    </div>
  );
}
