import { get } from 'lodash';
import { EnvUtils } from '@premagic/utils';

export enum TRACK_EVENTS {
  REFER = 'REFER', // PEOPLE SHARING YOUR COMPANY PROFILE/VIEW YOUR COMPANY PROFILE
  VIEW = 'VIEW',
  SHARE = 'SHARE',
  DOWNLOAD = 'DOWNLOAD',
  BULK_DOWNLOAD = 'BULK_DOWNLOAD',
  LEAD = 'LEAD', // LEADS GETTING CREATED TO THE PREMAGIC
  FACE_SEARCH = 'FACE_SEARCH',
  EVENT_REGISTRATION = 'EVENT_REGISTRATION',
  WISH = 'WISH',
  IMAGE_LIKE = 'image_like',
  IMAGE_DISLIKE = 'image_dislike',

  SPONSOR_VIEWS = 'SPONSOR_VIEW',
  SPONSOR_CLICKS = 'SPONSOR_CLICK',
  SPONSOR_CTA_CLICKS = 'SPONSOR_CTA_CLICK',
}

export const TRACK_EVENT_DETAILS: Record<TRACK_EVENTS, { title: string; info?: string }> = {
  [TRACK_EVENTS.VIEW]: {
    title: 'Views',
  },
  [TRACK_EVENTS.REFER]: {
    title: 'Engagement',
  },
  [TRACK_EVENTS.SHARE]: {
    title: 'Shares',
  },
  [TRACK_EVENTS.DOWNLOAD]: {
    title: 'Downloads',
  },
  [TRACK_EVENTS.BULK_DOWNLOAD]: {
    title: 'Bulk Downloads',
  },
  [TRACK_EVENTS.LEAD]: {
    title: 'Leads',
  },
  [TRACK_EVENTS.EVENT_REGISTRATION]: {
    title: 'Event registrations',
    info: 'People who have interacted with your brand. This would include visiting your company website, visiting your portfolio, visiting your social media profiles, Reaching out on your phone number',
  },
  [TRACK_EVENTS.FACE_SEARCH]: {
    title: 'Face Searches',
  },
  [TRACK_EVENTS.WISH]: {
    title: 'Wishes on Invite',
  },
  [TRACK_EVENTS.IMAGE_LIKE]: {
    title: 'Photos liked',
  },
  [TRACK_EVENTS.IMAGE_DISLIKE]: {
    title: 'Photos disliked',
  },
  [TRACK_EVENTS.SPONSOR_CLICKS]: {
    title: 'Sponsor clicks',
  },
  [TRACK_EVENTS.SPONSOR_VIEWS]: {
    title: 'Sponsor views',
  },
  [TRACK_EVENTS.SPONSOR_CTA_CLICKS]: {
    title: 'Sponsor CTA clicks',
  },
};

const baseURL = EnvUtils.isProd() ? 'https://p.premagic.com' : 'http://localhost:3009';

export function recordEventForFolder(
  event: TRACK_EVENTS,
  data: {
    projectId: string;
    folderId: string;
    imageId?: string;
    creativeId?: string;
    sponsorId?: string;
  },
): void {
  const { folderId, projectId, imageId, sponsorId, creativeId } = data;

  if (!folderId || !projectId) throw Error('Required values are not passed');

  let recordUrl = `${baseURL}/px/c/?project_id=${projectId}&folder_id=${folderId}&event=${event}`;

  if (imageId) {
    recordUrl = `${recordUrl}&image_id=${imageId}`;
  }
  if (creativeId && sponsorId) {
    recordUrl = `${recordUrl}&creative_id=${creativeId}&sponsor_id=${sponsorId}`;
  }

  new Image().src = recordUrl;
}

const EVENT_MAP_FROM_MIXPANEL_EVENTS = {
  'page.view': TRACK_EVENTS.VIEW,
  'guest-gallery.page.view': TRACK_EVENTS.VIEW,
  'proposal.page-view': TRACK_EVENTS.VIEW,
  'company.send.enquiry': TRACK_EVENTS.LEAD,
  'company.reach-out': TRACK_EVENTS.REFER,
  'photo.share': TRACK_EVENTS.SHARE,
  'folder.share': TRACK_EVENTS.SHARE,
  'folder.refer': TRACK_EVENTS.REFER,
  'photo.download': TRACK_EVENTS.DOWNLOAD,
  'people-faces.scan-face': TRACK_EVENTS.FACE_SEARCH,
  'sponsor.creative.view': TRACK_EVENTS.SPONSOR_VIEWS,
  'sponsor.creative.click': TRACK_EVENTS.SPONSOR_CLICKS,
  'sponsor.creative.cta-click': TRACK_EVENTS.SPONSOR_CTA_CLICKS,
  'photos-bulk-download.download-success': TRACK_EVENTS.BULK_DOWNLOAD,
};

export function handleTrackingPixelEvents(
  event: string,
  data: { projectId: string; folderId: string; creativeId?: string; sponsorId?: string },
): void {
  const eventName = get(EVENT_MAP_FROM_MIXPANEL_EVENTS, event, null);
  if (!eventName) return;
  recordEventForFolder(eventName, data);
}
