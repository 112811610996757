import { connect } from 'react-redux';
import { filesSelector } from '../../../images/FilesDataDuck';
import { appWidthSelector } from '../../../AppDuck';
import DAMLibraryImageFullView from './DAMLibraryImageFullView';

type OwnPropsType = {
  id: string;
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  const { id } = ownProps;
  const file = filesSelector(state)[id];
  return {
    file,
    appWidth: appWidthSelector(state),
  };
}

export default connect(mapStateToProps)(DAMLibraryImageFullView);
