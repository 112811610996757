import React from 'react';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Row,
  Text,
} from '@premagic/myne';

import FolderUploadProgressContainer from '../../../projects/folders/folder-files-upload/FolderUploadProgressContainer';
import FolderFilesUploadErrorContainer from '../../../projects/folders/folder-files-upload/FolderFilesUploadErrorContainer';
import AccountFolderPhotoDropZoneContainer from '../../../projects/folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import ProposalFolderGalleryContainer from '../../../projects/folders/gallery/FolderGalleryWithFileSelectContainer';
import PremiumFeatureIcon from '../../../settings/PremiumFeatureIcon';

type Props = {
  projectId: string;
  folderId: string;
  onSelect: (fileId: string) => void;
  hasAdvancedProposalFeatureEnabled: boolean;
  proposalPhotosLimit: number;
};

export default function ProposalCreatorFiles(props: Props): JSX.Element | null {
  const { projectId, folderId, onSelect, hasAdvancedProposalFeatureEnabled, proposalPhotosLimit } = props;

  return (
    <div>
      {!hasAdvancedProposalFeatureEnabled && (
        <>
          <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
            <Row>
              <PremiumFeatureIcon module="Proposal Pro" />
              <Space size={1} />
              <Text>You can only upload {proposalPhotosLimit} photos/video.</Text>
            </Row>
            <Space vertical size={2} />
            Please upgrade your plan to upload more.
          </Alert>
          <Space vertical />
        </>
      )}
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
      <FolderUploadProgressContainer folderId={folderId} />
      <FolderFilesUploadErrorContainer folderId={folderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <ProposalFolderGalleryContainer projectId={projectId} folderId={folderId} onSelect={onSelect} />
      </ScrollableContainer>
    </div>
  );
}
