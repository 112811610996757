import { RefObject, useEffect } from 'react';
import { throttle } from 'lodash';

function hasResizeObserver() {
  return typeof window.ResizeObserver !== 'undefined';
}

type UseResizeObserverOptionsType<T> = {
  ref: RefObject<T>;
  onResize: (options: { width: number; height: number }) => void;
};

export function useResizeObserver<T extends HTMLElement>(options: UseResizeObserverOptionsType<T>): void {
  const { ref, onResize } = options;
  const callOnResize = throttle(onResize, 100);

  useEffect(() => {
    const callOnResizeOld = throttle(() => {
      const { width = 0, height = 0 } = ref?.current?.getBoundingClientRect() || {};
      onResize({
        width,
        height,
      });
    }, 100);

    if (!ref?.current || hasResizeObserver()) return;
    callOnResizeOld();
    window.addEventListener('resize', callOnResizeOld, false);
    return () => {
      window.removeEventListener('resize', callOnResizeOld, false);
    };
  });

  useEffect(() => {
    if (!ref?.current || !hasResizeObserver()) {
      return;
    }

    const resizeObserverInstance = new window.ResizeObserver((entries) => {
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        callOnResize({ width, height });
      });
    });
    resizeObserverInstance.observe(ref.current);

    return () => {
      if (ref.current) resizeObserverInstance.unobserve(ref.current);
    };
  }, [onResize, ref]);
}
