import React from 'react';
import { History } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import ClientsPageContainer from './clients/ClientsPageContainer';
import APP_URLS from '../../services/AppRouteURLService';
import CreateQuotationPageContainer from './create/AddEventWithClientPageContainer';
import EventDetailsPageContainer from './events/details/EventDetailsPageContainer';
import CRMPageContainer from './CRMPageContainer';
import { getDefaultCRMEventListUrlWithFilters } from './filters/CRMFilterService';
import ClientPaymentsInvoicePageContainer from './payments/client-payments/invoice/ClientPaymentsInvoicePageContainer';
import CRMClientSearchContainer from './clients/CRMClientSearchContainer';
import IntegrationEventsModalContainer from '../integration-third-party-platforms/integration-modal/IntegrationEventsModalContainer';

type Props = {
  history: History;
  isFeatureLeadEnabled: boolean;
};
const ROUTES = APP_URLS.CRM;

export default function CRMRoutes(props: Props): JSX.Element {
  const { history, isFeatureLeadEnabled } = props;
  return (
    <Router history={history}>
      <Route path={ROUTES.INDEX}>
        <Switch>
          <Route path={ROUTES.CREATE} component={CreateQuotationPageContainer} />
          <Route path={ROUTES.CLIENTS} component={ClientsPageContainer} />
          <Route path={ROUTES.EVENT_INVOICE} component={ClientPaymentsInvoicePageContainer} />
          <Route path={ROUTES.EVENT} component={EventDetailsPageContainer} />
          <Route path={ROUTES.DETAILS} component={CRMPageContainer} />
          <Route>
            <Redirect
              to={getDefaultCRMEventListUrlWithFilters({
                isFeatureLeadEnabled,
              })}
            />
          </Route>
        </Switch>
        <CRMClientSearchContainer />
        <IntegrationEventsModalContainer />
      </Route>
    </Router>
  );
}
