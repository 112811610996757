// Flicked from https://gist.github.com/artemkrynkin/e6bf05d0f61ca3b2ed7e51291ad3a0bf

const removeModules = ({ matrix, removeFinderPattern = false, removeCenterModules = false }) => {
  const { size } = matrix;

  const finderPatternModules = removeFinderPattern ? size - 7 : 0;
  const centerModules = removeCenterModules ? (size - 7 * 3) / 2 + 7 : 0;

  const pos = [
    // top-left
    [0, 0],
    // top-right
    [finderPatternModules, 0],
    // center
    [centerModules, centerModules],
    // bottom-left
    [0, finderPatternModules],
  ];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pos.length; i++) {
    const row = pos[i][0];
    const col = pos[i][1];

    // eslint-disable-next-line no-plusplus
    for (let r = -1; r <= 7; r++) {
      // eslint-disable-next-line no-continue
      if (row + r <= -1 || size <= row + r) continue;

      // eslint-disable-next-line no-plusplus
      for (let c = -1; c <= 7; c++) {
        // eslint-disable-next-line no-continue
        if (col + c <= -1 || size <= col + c) continue;

        matrix.set(row + r, col + c, false, true);
      }
    }
  }
};

const qrModulesDataRender = (data, size, moduleSize) => {
  let svg = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    const col = Math.floor(i % size);
    const prevCol = col - 1;
    const nextCol = col + 1;
    const row = Math.floor(i / size);

    const currentValue = data[i];
    const prevValue = col && Boolean(data[i - 1]);
    const nextValue = nextCol !== size && Boolean(data[i + 1]);
    const prevValueTRow = Boolean(data[i - size]);
    const nextValueBRow = Boolean(data[i + size]);
    const prevValueTRowPrevCol = Boolean(data[i - 1 - size]);
    const nextValueBRowPrevCol = Boolean(data[i - 1 + size]);
    const prevValueTRowNextCol = Boolean(data[i + 1 - size]);
    const nextValueBRowNextCol = Boolean(data[i + 1 + size]);

    let moduleStyle = '';

    if (currentValue) {
      if (col && !prevValue && nextValueBRow && nextValueBRowPrevCol) {
        moduleStyle = 'n_rb';

        svg += `<g transform="translate(${prevCol * moduleSize}, ${
          row * moduleSize
        })"><use xlink:href="#${moduleStyle}"/></g>`;
      }

      if (col && !prevValue && prevValueTRow && prevValueTRowPrevCol) {
        moduleStyle = 'n_rt';

        svg += `<g transform="translate(${prevCol * moduleSize}, ${
          row * moduleSize
        })"><use xlink:href="#${moduleStyle}"/></g>`;
      }

      if (nextCol !== size && !nextValue && nextValueBRow && nextValueBRowNextCol) {
        moduleStyle = 'n_lb';

        svg += `<g transform="translate(${nextCol * moduleSize}, ${
          row * moduleSize
        })"><use xlink:href="#${moduleStyle}"/></g>`;
      }

      if (nextCol !== size && !nextValue && prevValueTRow && prevValueTRowNextCol) {
        moduleStyle = 'n_lt';

        svg += `<g transform="translate(${nextCol * moduleSize}, ${
          row * moduleSize
        })"><use xlink:href="#${moduleStyle}"/></g>`;
      }

      if (!prevValue && nextValue && prevValueTRow && !nextValueBRow) {
        moduleStyle = 'rt';
      } else if (!prevValue && nextValue && !prevValueTRow && nextValueBRow) {
        moduleStyle = 'rb';
      } else if (prevValue && !nextValue && !prevValueTRow && nextValueBRow) {
        moduleStyle = 'lb';
      } else if (prevValue && !nextValue && prevValueTRow && !nextValueBRow) {
        moduleStyle = 'lt';
      } else if (!prevValue && !nextValue && prevValueTRow && !nextValueBRow) {
        moduleStyle = 't';
      } else if (prevValue && !nextValue && !prevValueTRow && !nextValueBRow) {
        moduleStyle = 'l';
      } else if (!prevValue && nextValue && !prevValueTRow && !nextValueBRow) {
        moduleStyle = 'r';
      } else if (!prevValue && !nextValue && !prevValueTRow && nextValueBRow) {
        moduleStyle = 'b';
      } else if (!prevValue && !nextValue && !prevValueTRow && !nextValueBRow) {
        moduleStyle = 'empty';
      } else {
        moduleStyle = 'rect';
      }

      svg += `<g transform="translate(${col * moduleSize}, ${
        row * moduleSize
      })"><use xlink:href="#${moduleStyle}"/></g>`;
    }
  }

  return svg;
};

export const qrRender = (qrData, customOptions, cb) => {
  const options = {
    color: 'colored',
    logo: 'fillstuff',
    ...customOptions,
  };

  const { width } = options;
  const height = width;

  removeModules({
    matrix: qrData.modules,
    removeFinderPattern: true,
    removeCenterModules: options.logo !== 'none',
  });

  const { data } = qrData.modules;
  const { size } = qrData.modules;
  const moduleSize = 97;
  const moduleColor = options.color === 'colored' ? '#2B3544' : '#000000';
  const logoModule1Color = options.color === 'colored' ? '#00BFA5' : '#ffffff';
  const logoModule2Color = options.color === 'colored' ? '#FFC74A' : '#ffffff';
  const logoModule3Color = options.color === 'colored' ? '#DD5B5B' : '#ffffff';

  const logoSvg = `
    <g id="fillstuff_logo" stroke="none" stroke-width="1" fill="none">
  <g transform="translate(68.923 68.923)" fill="none" fill-rule="evenodd">
    <g fill="#e9ebed">
      <path d="m228.495 382.45 81.901-22.657c21.812-46.85 67.84-79.964 122.24-81.864 31.087-1.086 60.171 8.209 84.065 24.806l79.333-21.952c18.73-5.166 38.544 8.048 44.292 29.549l62.187 232.4c5.766 21.507-4.749 43.12-23.453 48.303l-367.526 101.66c-18.717 5.183-38.548-8.048-44.3-29.548L205.03 430.736c-5.751-21.484 4.758-43.102 23.466-48.287Zm234.788 201.983c55.159-1.927 98.298-49.057 96.335-105.278-1.963-56.204-48.285-100.21-103.444-98.284-55.181 1.927-98.315 49.057-96.352 105.261 1.963 56.221 48.28 100.228 103.46 98.3Zm-5.828-166.874c35.282-1.232 64.895 26.914 66.15 62.863 1.255 35.94-26.323 66.076-61.604 67.308-35.281 1.232-64.894-26.907-66.15-62.847-1.254-35.949 26.323-66.092 61.604-67.324Z" fill-rule="nonzero"/>
      <path d="M386.99 233.662c8.716-2.335 14.028-11.563 11.686-20.302l-13.138-49.033c-2.342-8.739-11.556-14.075-20.272-11.74-8.716 2.336-14.028 11.564-11.687 20.303l13.139 49.033c2.341 8.739 11.556 14.075 20.272 11.74ZM460.063 225.53c2.197.452 4.264.418 6.685-.23 6.295-1.687 11.065-7.128 12.409-13.732l6.657-50.695c1.178-9.161-5.168-17.347-14.31-18.54-9.142-1.193-17.302 5.156-18.48 14.318l-6.656 50.694c-1.793 8.806 4.553 16.992 13.695 18.185ZM296.477 259.997c4.112 5.662 11.318 7.374 17.613 5.687 1.937-.519 4.228-1.653 5.904-3.143 7.321-5.604 8.723-15.866 3.123-23.211l-31.113-40.575c-5.955-6.73-16.68-8.019-24-2.415-7.322 5.605-8.724 15.867-3.123 23.211l31.596 40.446Z"/>
    </g>
  </g>
    </g>
	`;
  const offset = 160;
  const logoSvgMask = `
	  <use fill="none" fill-rule="evenodd"
	  transform="translate(${((size - 7 * 3) / 2 + 7) * moduleSize - offset}, ${
      ((size - 7 * 3) / 2 + 7) * moduleSize - offset
    })" xlink:href="#fillstuff_logo"/>
	`;

  const imageSvg = `
    <g id="image_center">
      <defs>
        <pattern id="image" x="0" y="0" height="100%" width="100%" viewBox="0 0 300 300">
          <image x="0" y="0" width="300" height="300" xlink:href="${options.image}"/>
        </pattern>
      </defs>
      <rect fill="url(#image)" x="0" y="0" width="${moduleSize * 7}" height="${
        moduleSize * 7
      }" rx="50%" stroke="rgba(0, 0, 0, 0.1)" stroke-width="0.2%"/>
    </g>
	`;
  const imageSvgMask = `
	  <use fill="none" fill-rule="evenodd" transform="translate(${((size - 7 * 3) / 2 + 7) * moduleSize}, ${
      ((size - 7 * 3) / 2 + 7) * moduleSize
    })" xlink:href="#image_center"/>
	`;

  const qrSvg = `
<svg viewBox="0 0 ${moduleSize * size} ${
    moduleSize * size
  }" width="${width}px" height="${height}px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <rect id="rect" width="100" height="100" fill="currentColor"/>
    <path id="empty" d="M0,28.6v42.9C0,87.3,12.8,100,28.6,100h42.9c15.9,0,28.6-12.8,28.6-28.6V28.6C100,12.7,87.2,0,71.4,0H28.6 C12.8,0,0,12.8,0,28.6z" fill="currentColor"/>
    <path id="b" d="M0,0 L66,0 C84.7776815,-3.44940413e-15 100,15.2223185 100,34 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" transform="rotate(-90 50 50)" fill="currentColor"/>
    <path id="r" d="M0,0 L66,0 C84.7776815,-3.44940413e-15 100,15.2223185 100,34 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" transform="rotate(-180 50 50)" fill="currentColor"/>
    <path id="l" d="M0,0 L66,0 C84.7776815,-3.44940413e-15 100,15.2223185 100,34 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" fill="currentColor"/>
    <path id="t" d="M0,0 L66,0 C84.7776815,-3.44940413e-15 100,15.2223185 100,34 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" transform="rotate(90 50 50)" fill="currentColor"/>
    <path id="lt" d="M0,0 L100,0 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" fill="currentColor"/>
    <path id="lb" d="M0,0 L100,0 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" transform="rotate(-90 50 50)" fill="currentColor"/>
    <path id="rb" d="M0,0 L100,0 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" transform="rotate(-180 50 50)" fill="currentColor"/>
    <path id="rt" d="M0,0 L100,0 L100,66 C100,84.7776815 84.7776815,100 66,100 L0,100 L0,0 Z" transform="rotate(90 50 50)" fill="currentColor"/>
    <path id="n_lt" d="M30.5,2V0H0v30.5h2C2,14.7,14.8,2,30.5,2z" fill="currentColor"/>
    <path id="n_lb" d="M2,69.5H0V100h30.5v-2C14.7,98,2,85.2,2,69.5z" fill="currentColor"/>
    <path id="n_rt" d="M98,30.5h2V0H69.5v2C85.3,2,98,14.8,98,30.5z" fill="currentColor"/>
    <path id="n_rb" d="M69.5,98v2H100V69.5h-2C98,85.3,85.2,98,69.5,98z" fill="currentColor"/>
    <path id="point" d="M600.001786,457.329333 L600.001786,242.658167 C600.001786,147.372368 587.039517,124.122784 581.464617,118.535383 C575.877216,112.960483 552.627632,99.9982143 457.329333,99.9982143 L242.670667,99.9982143 C147.372368,99.9982143 124.122784,112.960483 118.547883,118.535383 C112.972983,124.122784 99.9982143,147.372368 99.9982143,242.658167 L99.9982143,457.329333 C99.9982143,552.627632 112.972983,575.877216 118.547883,581.464617 C124.122784,587.027017 147.372368,600.001786 242.670667,600.001786 L457.329333,600.001786 C552.627632,600.001786 575.877216,587.027017 581.464617,581.464617 C587.039517,575.877216 600.001786,552.627632 600.001786,457.329333 Z M457.329333,0 C653.338333,0 700,46.6616668 700,242.658167 C700,438.667167 700,261.332833 700,457.329333 C700,653.338333 653.338333,700 457.329333,700 C261.332833,700 438.667167,700 242.670667,700 C46.6616668,700 0,653.338333 0,457.329333 C0,261.332833 0,352.118712 0,242.658167 C0,46.6616668 46.6616668,0 242.670667,0 C438.667167,0 261.332833,0 457.329333,0 Z M395.996667,200 C480.004166,200 500,220.008332 500,303.990835 C500,387.998334 500,312.001666 500,395.996667 C500,479.991668 480.004166,500 395.996667,500 C312.001666,500 387.998334,500 304.003333,500 C220.008332,500 200,479.991668 200,395.996667 C200,312.001666 200,350.906061 200,303.990835 C200,220.008332 220.008332,200 304.003333,200 C387.998334,200 312.001666,200 395.996667,200 Z" fill="currentColor"/>
    ${options.logo === 'fillstuff' ? logoSvg : ''}
    ${options.logo === 'image' && options.image ? imageSvg : ''}
  </defs>


  <g transform="translate(0,0)">
    ${qrModulesDataRender(data, size, moduleSize)}

    <use fill-rule="evenodd" transform="translate(0,0)" xlink:href="#point"/>
    <use fill-rule="evenodd" transform="translate(${size * moduleSize - 700},0)" xlink:href="#point"/>
    <use fill-rule="evenodd" transform="translate(0,${size * moduleSize - 700})" xlink:href="#point"/>

    ${options.logo === 'fillstuff' ? logoSvgMask : ''}
    ${options.logo === 'image' && options.image ? imageSvgMask : ''}
  </g>
</svg>
`;
  // add a space between the qr code
  const svg = `
<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="color: ${moduleColor}">
    ${qrSvg}
</svg>
`;

  if (typeof cb === 'function') {
    cb(null, svg);
  }

  return svg;
};
