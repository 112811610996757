import React, { useEffect } from 'react';
import { NumberUtils } from '@premagic/utils';
import { ProjectOrdersService } from '@premagic/core';

import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

import ClientWishItemContainer from './ClientWishItemContainer';

function WishesStats(props: { totalWishes: number }) {
  const { totalWishes } = props;
  if (totalWishes === 0) return null;
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_3}>
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
          {NumberUtils.getNumberInFormat(totalWishes)}
        </Text>
        <Space size={2} />
        greetings from friends & family
      </Text>
    </div>
  );
}

type Props = {
  isLoading: boolean;
  folderId: string;
  projectId: string;
  clientWishesIds: Array<string>;
  showAddWish: () => void;
  initData: (options: { projectId: string; folderId: string }) => void;
  order?: ProjectOrdersService.ProjectOrdersType;
  isStaffUser: boolean;
};

export default function ClientWishesList(props: Props): JSX.Element {
  const { folderId, projectId, isLoading, clientWishesIds, showAddWish, initData, order, isStaffUser } = props;

  useEffect(() => {
    initData({ projectId, folderId });
  }, [initData, projectId, folderId]);

  if (isLoading) return <LoadingDots />;

  return (
    <div>
      <Row>
        <Col>
          <WishesStats totalWishes={clientWishesIds.length} />
        </Col>
        <Col rightAlighed size={null}>
          <Button style={BUTTON_STYLES.LINK} onClick={showAddWish}>
            Add new Wish
          </Button>
        </Col>
      </Row>
      <Space vertical />
      {clientWishesIds.length === 0 && (
        <Card>
          <Space>
            <Text muted block>
              No wishes yet.
            </Text>
          </Space>
        </Card>
      )}
      {clientWishesIds.map((id) => (
        <ClientWishItemContainer id={id} projectId={projectId} folderId={folderId} key={id} />
      ))}
      <Space vertical />
    </div>
  );
}
