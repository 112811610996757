import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { activeAccountIdSelector } from '../../../../acccount/AccountDataDuck';
import { damLibraryFilterLabelsByFilterKeySelector } from '../../DAMLibraryDataDuck';
import { DAM_LIBRARY_FILTER_KEYS } from '../DAMLibraryFilterTypes';
import DAMLibraryTagsFilterSelector from './DAMLibraryTagsFilterSelector';

function mapStateToProps(state, ownProps: { value: Array<string> }) {
  const { value } = ownProps;

  return {
    value,
    activeAccountId: activeAccountIdSelector(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state),
    allTags: damLibraryFilterLabelsByFilterKeySelector(DAM_LIBRARY_FILTER_KEYS.TAGS)(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryTagsFilterSelector);
