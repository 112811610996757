import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';

import { pagesSelector } from '../../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_SPONSOR', true);

export const setEditOfSponsor = createAction(
  getActionType('SPONSOR', 'EDIT'),
  (dispatch: Dispatch, occasionId: string | null) => occasionId,
);

export const setEditOfCreative = createAction(
  getActionType('CREATIVE', 'EDIT'),
  (dispatch: Dispatch, paymentId: string | null) => paymentId,
);

type EventSponsorsPageType = {
  editSponsorsId: string | null;
  editCreativeId: string | null;
};
const initialState = {
  editSponsorsId: undefined,
  editCreativeId: undefined,
};

export default handleActions(
  {
    [setEditOfSponsor.toString()]: (state, action: { payload }) => ({
      ...state,
      editSponsorsId: action.payload,
    }),
    [setEditOfCreative.toString()]: (state, action: { payload }) => ({
      ...state,
      editCreativeId: action.payload,
    }),
  },
  initialState,
);

export const eventSponsorPageSelector = createSelector(
  pagesSelector,
  (state) => state.eventSponsor.eventSponsor as EventSponsorsPageType,
);
export const sponsorIdForEditSelector = createSelector(eventSponsorPageSelector, (page) => page.editSponsorsId);
export const creativeIdForEditSelector = createSelector(eventSponsorPageSelector, (page) => page.editCreativeId);
