import React from 'react';
import { ProjectService } from '@premagic/core';
import {
  Col,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  HTML_ICON,
  Icon,
  Label,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  TimeAgo,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import ProjectRenameDialogContainer from './ProjectRenameDialogContainer';
import ProjectArchiveDialogContainer from './ProjectArchiveDialogContainer';
import MESSAGES from '../../../../../../common/Messages';
import APP_URLS, { getRouteUrlFor } from '../../../../services/AppRouteURLService';

type Props = {
  projectId: string;
  project?: ProjectService.ProjectType;
  showArchive: () => void;
  showRename: () => void;
  hasClientWebsiteFeature: boolean;
  navigateTo: (url: string) => void;
};

export default function DeliverablesHeader(props: Props) {
  const { project, showRename, showArchive, projectId, hasClientWebsiteFeature, navigateTo } = props;
  const linkToCreate = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.CREATE, { projectId });
  const {
    project_name: projectName,
    is_archive: isArchived,
    is_clone: isCopyOfAProject,
    created_at: createdAt,
    event_id: eventId,
  } = project || {};

  return (
    <div>
      <Row vcenter>
        <Icon name="server" />
        <Space size={2} />
        <div>
          <Row vcenter>
            <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
              {projectName}
            </Text>
            {isArchived && (
              <>
                <Space size={2} />
                <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT}>
                  {HTML_ICON.DOT}
                  <Space size={2} />
                  Archived
                </Text>
              </>
            )}
          </Row>
          <Text size={TEXT_SIZE.SIZE_5} block>
            <TimeAgo date={SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt)} />
            <Space />
            {isCopyOfAProject && <Label>Copy of a project</Label>}
          </Text>
        </div>

        <Col size={null} rightAlighed>
          <span className={JS_SELECTORS.PROJECT_MORE_OPTIONS}>
            <Dropdown>
              <DropdownIcon title="More" tooltipPlacement="left">
                <Icon name="more_vertical" />
              </DropdownIcon>
              <Menu>
                <MenuItem onClick={showRename}>
                  <MenuItemIcon name="edit_2" />
                  Edit name
                </MenuItem>
                <MenuItem
                  onClick={() => navigateTo(linkToCreate)}
                  disabled={!eventId && !hasClientWebsiteFeature}
                  disabledMessage={
                    eventId
                      ? MESSAGES.CLIENT_WEBSITE.NOT_AVAILABLE
                      : 'Project does not have an event associated with it'
                  }
                >
                  <MenuItemIcon name="gift" />
                  Add {MESSAGES.CLIENT_WEBSITE.NAME}
                </MenuItem>
                <MenuItem
                  onClick={showArchive}
                  disabled={isArchived}
                  disabledMessage={MESSAGES.PROJECT.ALREADY_ARCHIVED}
                >
                  <MenuItemIcon name="archive" />
                  Archive
                </MenuItem>
              </Menu>
            </Dropdown>
          </span>
        </Col>
      </Row>
      <ProjectRenameDialogContainer projectId={projectId} />
      <ProjectArchiveDialogContainer projectId={projectId} />
      <Space vertical />
    </div>
  );
}
