import React, { useEffect, useState } from 'react';
import { PresetDesignService, ClientWebsiteService } from '@premagic/core';
import ClientWebsiteFontAndColorCustomizationContainer from './ClientWebsiteFontAndColorCustomizationContainer';
import PresetDesignSelectorContainer from '../../preset-design-selector/PresetDesignSelectorContainer';

type Props = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  projectId: string;
  id: string;
  getFolderMetaData: (projectId: string, folderId: string, options) => void;
  saveClientWebsiteData: (id: string, projectId: string, data) => void;
  isSaving: boolean;
  isInsideCard: boolean;
};

export default function ClientWebsiteDesignPresets(props: Props): React.ReactElement {
  const { projectId, id, getFolderMetaData, saveClientWebsiteData, clientWebsite, isSaving, isInsideCard } = props;

  const [selectedPresetDesign, setSelectedPresetDesign] = useState('');

  function handlePresetDesignChange(data: PresetDesignService.PresetDesignOption) {
    saveClientWebsiteData(id, projectId, {
      data: {
        theme: {
          color: {
            background: data.backgroundColor,
            primary_color: data.colors?.primaryColor,
            secondary_color: data.colors?.secondaryColor,
            tertiary_color: data.colors?.tertiaryColor,
          },
          font: {
            primary_font: data.primaryFontFamily,
            secondary_font: data.secondaryFontFamily,
          },
        },
      },
    });
  }

  useEffect(() => {
    getFolderMetaData(projectId, id, {
      loadFonts: false,
    });
  }, [getFolderMetaData, id, projectId]);

  useEffect(() => {
    const selectedPreset = Object.values(PresetDesignService.PRESET_DESIGN).find((item) => {
      if (
        item.backgroundColor === clientWebsite?.theme?.color?.background &&
        item.primaryFontFamily === clientWebsite?.theme?.font?.primary_font &&
        item.colors?.primaryColor === clientWebsite?.theme?.color?.primary_color &&
        item.colors?.secondaryColor === clientWebsite?.theme?.color?.secondary_color &&
        item.colors?.tertiaryColor === clientWebsite?.theme?.color?.tertiary_color
      ) {
        return item;
      }

      return null;
    });

    if (selectedPreset) {
      setSelectedPresetDesign(selectedPreset.value);
    }
  }, [clientWebsite]);

  return (
    <div>
      <PresetDesignSelectorContainer
        onChange={handlePresetDesignChange}
        selectedPresetDesign={selectedPresetDesign}
        headingTitle="Design Presets"
        isLoading={isSaving}
        isPresetDesignSelectorEnabled
        variant={PresetDesignService.PRESET_DESIGN_VARIANT.SECONDARY}
        isInsideCard={isInsideCard}
      />
      <ClientWebsiteFontAndColorCustomizationContainer id={id} projectId={projectId} isInsideCard={isInsideCard} />
    </div>
  );
}
