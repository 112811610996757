import { QRCodeBackgroundPosterService } from '@premagic/core';
import { BUTTON_SIZE, BUTTON_STYLES, Button, COLOR_SHADES, Color, ICON_SIZES, Icon, Row, Space } from '@premagic/myne';
import QRCodeSponsorCreativeItemContainer from '../../../sponsor/QRCodeSponsorCreativeItemContainer';
import QRCodePosterCardImage from '../../common-ui/QRCodePosterCardImage';

type SponsorLogoSelectProps = {
  logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS;
  logoIdToCreativeIdMapData: QRCodeBackgroundPosterService.QRCodeSponsorLogoDataType;
  projectId: string;
  openSelectSponsorLogoModal: (logoId) => void;
};
function SponsorLogoSelect(props: SponsorLogoSelectProps) {
  const { logoId, logoIdToCreativeIdMapData, projectId, openSelectSponsorLogoModal } = props;

  function handleSelectSponsorLogo() {
    openSelectSponsorLogoModal(logoId);
  }

  const creativeId = logoIdToCreativeIdMapData[logoId];

  if (!creativeId) {
    return (
      <Row columnDirection vcenter>
        <QRCodePosterCardImage>
          <Color inline shade={COLOR_SHADES.LIGHT}>
            <Icon name="image" size={ICON_SIZES.MD} />
          </Color>
        </QRCodePosterCardImage>
        <Space size={2} vertical />
        <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleSelectSponsorLogo}>
          Select
        </Button>
      </Row>
    );
  }

  return (
    <Row columnDirection vcenter>
      <QRCodePosterCardImage>
        <QRCodeSponsorCreativeItemContainer id={creativeId} showCreativeStatus />
      </QRCodePosterCardImage>
      <Space size={2} vertical />
      <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleSelectSponsorLogo}>
        Select
      </Button>
    </Row>
  );
}

type Props = {
  sponsorLogo: QRCodeBackgroundPosterService.QRCodeSponsorLogoDataType;
  projectId: string;
  openSelectSponsorLogoModal: (logoId) => void;
};

export default function QRCodePosterSponsorSelector(props: Props) {
  const { sponsorLogo, projectId, openSelectSponsorLogoModal } = props;

  return (
    <Row spaceAround vcenter wrap>
      {Object.keys(QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS).map((logoId) => (
        <SponsorLogoSelect
          key={logoId}
          logoId={logoId as unknown as QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS}
          logoIdToCreativeIdMapData={sponsorLogo}
          projectId={projectId}
          openSelectSponsorLogoModal={openSelectSponsorLogoModal}
        />
      ))}
    </Row>
  );
}
