import { useEffect, useState } from 'react';

function isClient() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

// Once we ended up on the client, the first render must look the same as on
// the server so hydration happens without problems. _Then_ we immediately
// schedule a subsequent update and return the height measured on the client.
// It's not needed for CSR-only apps, but is critical for SSR.
function useWasRenderedOnClientAtLeastOnce() {
  const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = useState(false);

  useEffect(() => {
    if (isClient()) {
      setWasRenderedOnClientAtLeastOnce(true);
    }
  }, []);
  return wasRenderedOnClientAtLeastOnce;
}

export function measureHeight(): number | null {
  if (!isClient()) return null;
  return window.innerHeight;
}

export function use100vh(): number | null {
  const [height, setHeight] = useState<number | null>(measureHeight);

  function setMeasuredHeight() {
    setHeight(measureHeight());
  }

  const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

  useEffect(() => {
    if (!wasRenderedOnClientAtLeastOnce) return;

    setMeasuredHeight();

    // window.addEventListener('resize', setMeasuredHeight)
    // return () => window.removeEventListener('resize', setMeasuredHeight)
  }, [wasRenderedOnClientAtLeastOnce]);

  return wasRenderedOnClientAtLeastOnce ? height : null;
}
