import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { clientSettingsDataSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';
import SelectionFolderPreferencePage from './SelectionFolderPreferencePage';
import { hasFeatureSelector } from '../company/CompanyDataDuck';

function mapStateToProps(state) {
  return {
    clientSettings: clientSettingsDataSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.APP_LOADING)(state) || isLoadingSelector(LOADINGS.CLIENT_SETTINGS.FETCH_ALL)(state),
    isSaving: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    hasFeatures: {
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION,
      )(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionFolderPreferencePage);
