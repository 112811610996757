import { EventsService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import { CRM_FILTER_KEYS, CRMFiltersType } from './CRMFilterTypes';

export function getCRMUrlWithFilters(view: 'calendar' | 'list', filters: CRMFiltersType): string {
  return BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.DETAILS, {
    view,
    filters: BrowserUrlUtils.getQueryStringFromObject(filters),
  });
}

export function getDefaultCRMCalendarUrlWithFilters(): string {
  const defaultFilter = {
    // [CRM_FILTER_KEYS.EVENT_STATUS]: EVENT_STATUS.CONFIRMED,
  };

  return getCRMUrlWithFilters('calendar', defaultFilter);
}

export function getDefaultCRMEventListUrlWithFilters(options: { isFeatureLeadEnabled: boolean }): string {
  const defaultFilter = {
    [CRM_FILTER_KEYS.STATUS]: EventsService.EVENT_STATUS.CONFIRMED,
  };

  return getCRMUrlWithFilters('list', defaultFilter);
}

// CRM dashboard workflow stages
export function getCRMDashboardUrlWithFilters(view: 'calendar' | 'list', filters: CRMFiltersType): string {
  return BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM_DASHBOARD.DETAILS, {
    view,
    filters: BrowserUrlUtils.getQueryStringFromObject(filters),
  });
}
export function getDefaultCRMDashboardCalendarUrlWithFilters(): string {
  const defaultFilter = {
    // [CRM_FILTER_KEYS.EVENT_STATUS]: EVENT_STATUS.CONFIRMED,
  };

  return getCRMDashboardUrlWithFilters('calendar', defaultFilter);
}

export function getDefaultCRMDashboardEventListUrlWithFilters(): string {
  const defaultFilter = {
    [CRM_FILTER_KEYS.STATUS]: EventsService.EVENT_STATUS.NEW,
  };

  return getCRMDashboardUrlWithFilters('list', defaultFilter);
}
