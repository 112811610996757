import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';
import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';

import ProposalTemplatePicker from './ProposalTemplatePicker';

function mapStateToProps(
  state,
  ownProps: {
    selectedId?: string;
  },
) {
  const { selectedId } = ownProps;
  const COMMON_KEY = ActionConstants.KEYS.PROPOSALS_TEMPLATES.FETCH;

  return {
    selectedId,
    templates: ProposalDataDuck.proposalsTemplateOptionsSelectors(state),
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTemplates: () => {
      dispatch(ProposalDataDuck.fetchProposalsTemplates(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalTemplatePicker);
