import { connect } from 'react-redux';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { usersItemsArraySelector, usersItemsOptionsForSelectInputSelector } from '../UsersDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { UserSelect } from './UserSelect';

function mapStateToProps(
  state,
  ownProps: {
    defaultValue?: string | Array<string>;
    name: string;
  },
) {
  const { defaultValue, name } = ownProps;
  return {
    defaultValue,
    name,
    isLoading: isLoadingSelector(LOADINGS.CLIENTS.FETCH_ALL)(state),
    users: usersItemsArraySelector(state),
    usersSelectOptions: usersItemsOptionsForSelectInputSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddNewUserPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.USERS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
