import { connect } from 'react-redux';
import { filesSelector } from '../../../images/FilesDataDuck';
import { filesOrderForFolderSelector } from '../AccountFoldersDataDuck';
import FolderGalleryWithFileSelect from './FolderGalleryWithFileSelect';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    onSelect: (fileId: string) => void;
    onSelectExpand?: (fileId: string) => void;
    groupBy?: {
      label: string;
      items: Array<string>;
    };
  },
) {
  const { projectId, folderId, onSelect, groupBy } = ownProps;
  return {
    projectId,
    folderId,
    files: filesSelector(state),
    fileIds: filesOrderForFolderSelector(folderId)(state),
    onSelect,
    groupBy,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderGalleryWithFileSelect);
