import { useEffect } from 'react';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Position,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { ArrayUtils, BrowserUrlUtils } from '@premagic/utils';
import UserNamesContainer from '../../users/list/UserNamesContainer';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  accountId?: string;
  isLoading: boolean;
  resetUserOccasionsData: () => void;
  occasionForUsers: Record<
    string,
    Array<{
      account_id: string;
      event__number: string;
      event_id: string;
    }>
  >;
};

export default function CRMOccasionUsersBusyAlert(props: Props) {
  const { isLoading, resetUserOccasionsData, occasionForUsers, accountId } = props;
  const users = Object.keys(occasionForUsers);

  useEffect(() => resetUserOccasionsData, [resetUserOccasionsData]);

  return (
    <Alert style={users.length > 0 ? ALERT_STYLES.WARNING : ALERT_STYLES.SUCCESS} size={ALERT_SIZES.SM}>
      <Row vcenter>
        <Color shade={COLOR_SHADES.BLUE} fillSolidIcon inline>
          <Icon name="info_solid" size={ICON_SIZES.SM} />
        </Color>
        <Space size={2} />
        <div>
          <ErrorBoundary>
            {users.length === 0 ? (
              <div>All Good, all selected team members are available</div>
            ) : (
              <div>
                {users.map((user, index) => {
                  const eventDetailsList = occasionForUsers[user].map((occasion) => {
                    const { event_id: eventId, event__number: eventNumber, account_id: occasionAccountId } = occasion;
                    const urlForDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__OCCASIONS, {
                      eventId,
                    });
                    if (occasionAccountId === accountId) {
                      return (
                        <SimpleLink href={urlForDetails} isExternalLink showExternalLinkIcon key={occasion.event_id}>
                          {eventNumber}
                        </SimpleLink>
                      );
                    }
                    return <Text>{eventNumber} (Outside Event)</Text>;
                  });
                  return (
                    <Text block key={user}>
                      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                        <UserNamesContainer userIds={[user]} avatarStyle={false} />
                      </Text>
                      <Space size={1} />
                      seems to be booked for{' '}
                      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                        {ArrayUtils.intersperse(eventDetailsList, ', ')}
                      </Text>
                      <Space />
                      for the selected dates.
                    </Text>
                  );
                })}
              </div>
            )}
          </ErrorBoundary>
        </div>
        <Position align="top-right">{isLoading && <LoadingDots size="sm" />}</Position>
      </Row>
    </Alert>
  );
}
