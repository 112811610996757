import { EventExhibitorService } from '@premagic/core';
import { FormGroup, FormLabel, Input, InputPhoneNumber } from '@premagic/myne';
import ExhibitorLogoFilesSelectorContainer from '../exhibitor-logo-file/ExhibitorLogoFilesSelectorContainer';

type Props = {
  projectId: string;
  exhibitorId?: string;
  exhibitor?: EventExhibitorService.EventExhibitorType;
};

export default function EventExhibitorForm(props: Props) {
  const { exhibitor, exhibitorId, projectId } = props;

  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input name="name" autoFocus required placeholder="eg. Google" defaultValue={exhibitor?.name} />
      </FormGroup>
      <ExhibitorLogoFilesSelectorContainer name="logo_asset_id" defaultValue={exhibitor?.logo_asset_id} />
      <FormGroup>
        <FormLabel>Email</FormLabel>
        <Input name="email" placeholder="eg. google@gmail.com" defaultValue={exhibitor?.email} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Phone</FormLabel>
        <InputPhoneNumber name="phone" defaultValue={exhibitor?.phone} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Booth Number</FormLabel>
        <Input name="booth_number" placeholder="eg. A10" defaultValue={exhibitor?.booth_number} />
      </FormGroup>
    </div>
  );
}
