import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { createAction } from 'redux-actions';
import { EventsService } from '@premagic/core';

import { LOADINGS } from '../../../common/Constants';
import { toggleLoadingState } from '../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../common/ErrorDuck';
import { fetchOccasionsForCalendar } from '../components/crm/calendar-and-event-page/OccasionsCalendarService';

const getActionType = ActionTypeUtils.getActionTypeFunction('SAMPLE_PROJECT');
export const createSampleProjectEvent = createAction(getActionType('DATA', 'CREATE'), async (dispatch, filters) => {
  const loadingKey = LOADINGS.SAMPLE_PROJECT.CREATE;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  try {
    const occasions = await fetchOccasionsForCalendar(filters);
    const sampleProjects = occasions.results.filter((item) => item.client.name.includes('Sample |'));
    if (sampleProjects.length === 0) {
      await EventsService.createSampleProject();
    }
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    return occasions;
  } catch (e) {
    dispatch(setErrorState(dispatch, loadingKey, e));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    ErrorTracker.logError('Calendar Occasions: fetch failed', e);
    return e;
  }
});
