import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  FormFooter,
  Icon,
  ICON_SIZES,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Space,
  Textarea,
  Text,
} from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';
import { EventPosterService, FolderService } from '@premagic/core';

type Props = {
  show: boolean;
  onHide: () => void;
  clientWebsite?: FolderService.FolderType;
  poster?: EventPosterService.EventPosterType;
};

export default function EmbedPosterModal(props: Props) {
  const { onHide, clientWebsite, poster, show } = props;
  const [isCopied, showCopiedInfo] = useState(false);
  if (!show) {
    return null;
  }

  function getEmbedCode(): string {
    const linkToPoster = EventPosterService.getPosterPreviewUrl({
      poster,
      clientWebsite,
      isEmbed: true,
    });

    if (!linkToPoster) return 'Not found';

    return BrowserUtils.getIframeEmbedCode({
      url: linkToPoster,
      name: 'Poster',
      id: `premagic-poster-${poster?.id}`,
    });
  }

  return (
    <Modal size={MODAL_SIZES.MD} show close={onHide}>
      <ModalTitle>Embed on your website</ModalTitle>
      <Text>Embed {poster?.name} poster in your website by pasting the following HTML code snippet to your page</Text>
      <Space vertical />
      <Textarea name="code" readOnly>
        {getEmbedCode()}
      </Textarea>
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
          Close
        </Button>
        <Button
          style={BUTTON_STYLES.PRIMARY}
          disabled={isCopied}
          onClick={() => {
            showCopiedInfo(true);
            setTimeout(() => showCopiedInfo(false), 5000);
            BrowserUtils.copyToClipboard(getEmbedCode());
          }}
        >
          <Icon size={ICON_SIZES.SM} name={isCopied ? 'check' : 'copy'} />
          <Space size={1} />
          {isCopied ? 'Copied' : 'Copy to clipboard'}
        </Button>
      </FormFooter>
    </Modal>
  );
}
