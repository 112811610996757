import React from 'react';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  COLOR_SHADES,
  FadeIn,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { EventTrackerService, SubscriptionService } from '@premagic/core';
import { SimpleDateUtils, StringUtils } from '@premagic/utils';

type Props = {
  subscription: SubscriptionService.SubscriptionType;
  isLoading: boolean;
};

export default function SubscriptionStatusCard(props: Props) {
  const { subscription, isLoading } = props;

  if (isLoading || !subscription) return null;
  const { ended_at: ending } = subscription || {};
  const endDate = SimpleDateUtils.getISODateStringFromTimestamp(ending);
  const now = SimpleDateUtils.now();
  const daysRemaining = SimpleDateUtils.getDaysRemaining(now, endDate);

  if (daysRemaining > 30) {
    return null;
  }
  const cardStyle = daysRemaining < 7 ? ALERT_STYLES.DANGER : ALERT_STYLES.WARNING;

  return (
    <FadeIn>
      <Alert style={cardStyle} size={ALERT_SIZES.AUTO}>
        <Row>
          {daysRemaining > 0 ? (
            <Text size={TEXT_SIZE.SIZE_5}>
              Subscription expiring in{' '}
              <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                {daysRemaining} {StringUtils.pluralize('day', daysRemaining)}
              </Text>
            </Text>
          ) : (
            <Text size={TEXT_SIZE.SIZE_5}>
              Subscription expired{' '}
              <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                {SimpleDateUtils.fromNow(endDate)}
              </Text>
            </Text>
          )}
          <Space size={2} />
          <Button
            style={BUTTON_STYLES.PRIMARY}
            link={subscription.authorization_url}
            size={BUTTON_SIZE.SM}
            isExternalLink
            onClick={() => {
              EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SUBSCRIPTIONS.EXPIRED_PAYMENT_CLICK);
            }}
          >
            Extend
          </Button>
        </Row>
      </Alert>
      <Space vertical size={2} />
    </FadeIn>
  );
}
