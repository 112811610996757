import { ClientWebsiteService, EventExhibitorService, EventPosterService, FolderService } from '@premagic/core';

import { Button, BUTTON_STYLES, Icon, ICON_SIZES, Space } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';

function getPosterUrlForExhibitor(
  poster?: EventPosterService.EventPosterType,
  clientWebsite?: FolderService.FolderType,
  exhibitor?: EventExhibitorService.EventExhibitorType,
): string | null {
  if (!poster || !clientWebsite || !exhibitor) return null;

  const posterUrl = EventPosterService.getPosterPreviewUrl({
    poster,
    clientWebsite,
  });

  const params = BrowserUrlUtils.getQueryStringFromObject({
    exhibitorId: exhibitor.id,
  });

  return `${posterUrl}?${params}`;
}

type PosterLinkProps = {
  exhibitor: EventExhibitorService.EventExhibitorType;
  clientWebsite: FolderService.FolderType;
  session?: ClientWebsiteService.ClientWebsiteItinerary;
  name?: string;
  poster?: EventPosterService.EventPosterType;
};

function PosterLink(props: PosterLinkProps): JSX.Element | null {
  const { exhibitor, clientWebsite, session, name, poster } = props;

  const exhibitorPosterUrl = getPosterUrlForExhibitor(poster, clientWebsite, exhibitor);

  if (!exhibitorPosterUrl) return null;
  return (
    <Button style={BUTTON_STYLES.LINK} link={exhibitorPosterUrl} isExternalLink>
      <Icon name="external_link" size={ICON_SIZES.SM} />
      <Space size={1} />
      {name || 'Poster'}
    </Button>
  );
}

type Props = {
  exhibitorId: string;
  exhibitor: EventExhibitorService.EventExhibitorType;
  poster?: EventPosterService.EventPosterType;
  clientWebsite?: FolderService.FolderType;
};

export default function EventExhibitorLinkToPosterButton(props: Props): JSX.Element | null {
  const { exhibitor, exhibitorId, poster, clientWebsite } = props;

  if (!clientWebsite) return null;

  return <PosterLink exhibitor={exhibitor} clientWebsite={clientWebsite} poster={poster} />;
}
