import * as React from 'react';
import { CSSProperties } from 'react';
import ClassNames from 'classnames';
import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom';
import { BrowserUrlUtils } from '@premagic/utils';
import { Icon, ICON_SIZES } from '../Icon/Icons';
import styles from './link.module.css';

interface SimpleLinkProps {
  children: React.ReactNode;
  href: string;
  isExternalLink?: boolean;
  showExternalLinkIcon?: boolean;
  className?: string;
  onClick?: (e: any) => void;
}

export const SimpleLink = React.forwardRef((props: SimpleLinkProps, ref: any) => {
  const { children, href, isExternalLink = false, showExternalLinkIcon = false, className, ...elementProps } = props;

  const classes = ClassNames(styles['simple-link'], className, {
    [styles['simple-link--external']]: isExternalLink,
  });

  return (
    <a
      className={classes}
      href={href}
      target={isExternalLink ? '_blank' : '_self'}
      {...elementProps}
      rel="noopener noreferrer"
      ref={ref}
    >
      {children}
      {isExternalLink && showExternalLinkIcon && (
        <Icon className={styles['simple-link__external-icon']} size={ICON_SIZES.XS} name="external_link" />
      )}
    </a>
  );
});

interface LinkProps {
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  to: string;
  isExternalLink?: boolean;
  showExternalLinkIcon?: boolean;
  cssStyle?: CSSProperties;
  onClick?: (e: any) => void;
}

export const Link = React.forwardRef((props: LinkProps, ref: any) => {
  const {
    to = '',
    children,
    className = '',
    isExternalLink = false,
    disabled = false,
    cssStyle,
    showExternalLinkIcon = false,
    ...elementProps
  } = props;

  const classes = className || ClassNames(styles.link);
  if (disabled) {
    return (
      <button ref={ref} type="button" disabled className={classes} style={cssStyle} {...elementProps}>
        {children}
      </button>
    );
  }
  if (BrowserUrlUtils.isExternalUrl(to))
    return (
      // Someplace we have links with isExternal is false like signature tabs
      <SimpleLink
        ref={ref}
        className={className}
        href={to}
        isExternalLink={isExternalLink}
        showExternalLinkIcon={showExternalLinkIcon}
        {...elementProps}
      >
        {children}
      </SimpleLink>
    );

  return (
    <RRLink ref={ref} to={to} className={classes} {...elementProps}>
      {children}
    </RRLink>
  );
});

interface NavLinkProps extends LinkProps {
  disabled?: boolean;
  className?: string;
  activeClassName: string;
  to: string;
}

export const NavLink = React.forwardRef((props: NavLinkProps, ref: any) => {
  const {
    className = '',
    disabled = false,
    activeClassName,
    to,
    isExternalLink = false,
    cssStyle,
    showExternalLinkIcon = false,
    ...elementProps
  } = props;

  const classes = ClassNames(styles.link, className);

  if (disabled) {
    return <span className={classes} {...elementProps} />;
  }

  return (
    <RRNavLink
      className={classes}
      to={to}
      exact={to === '/'}
      activeClassName={activeClassName || styles['link--active']}
      {...elementProps}
    />
  );
});
