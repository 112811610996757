import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import {
  crmPaymentsSelector,
  isUserOwnerOfCrmPayment,
  sentReminderToClient,
  updatePayment,
} from '../CRMPaymentDataDuck';
import PaymentListItem from './PaymentListItem';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { paymentCategoriesSelector } from '../CRMPaymentCategoryDataDuck';
import { LOADINGS, MODALS } from '../../../../../../common/Constants';
import { setDeleteOfPayment, setEditOfPayment } from '../../events/details/EventDetailsPageDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { PAYMENT_TYPES } from '../CRMPaymentService';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { isMobileUp } from '../../../AppDuck';

function mapStateToProps(
  state,
  ownProps: {
    paymentId: string;
  },
) {
  const { paymentId } = ownProps;

  return {
    payment: crmPaymentsSelector(state)[paymentId],
    isOwnerOfPayment: isUserOwnerOfCrmPayment(paymentId)(state),
    categories: paymentCategoriesSelector(state),
    isUpdating: isLoadingSelector(LOADINGS.CRM_PAYMENTS.UPDATE)(state),
    hasPermissionToManage: hasPermission(PERMISSIONS.EVENT_FINANCIAL_MANAGE)(state),
    isMobileUp: isMobileUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: (paymentId: string) => {
      dispatch(setDeleteOfPayment(dispatch, paymentId));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_DELETE_DIALOG, true));
    },
    markAsPaid: (paymentId: string, eventId: string, data) => {
      dispatch(ModalDuck.setModalOptions(dispatch, MODALS.MARK_AS_PAID_CONFIRM_MODAL, { paymentId, eventId, data }));
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.MARK_AS_PAID_CONFIRM_MODAL, true));
    },
    sentReminderToClient: (paymentId: string, eventId: string) => {
      dispatch(sentReminderToClient(dispatch, paymentId, eventId));
    },
    markAsSettled: (paymentId: string, eventId: string, data) => {
      dispatch(updatePayment(dispatch, { paymentId, eventId }, data));
    },
    showEditPayment: (paymentId: string, paymentType) => {
      dispatch(setEditOfPayment(dispatch, paymentId));
      const paymentTypePanel = {
        [PAYMENT_TYPES.INFLOW]: LOADINGS.CRM_PAYMENTS.SHOW_EDIT_CLIENT_PAYMENT_PANEL,
        [PAYMENT_TYPES.OUTFLOW]: LOADINGS.CRM_PAYMENTS.SHOW_EDIT_EXPENSE_PAYMENT_PANEL,
      };
      dispatch(toggleWindowPanelVisibility(dispatch, paymentTypePanel[paymentType], true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentListItem);
