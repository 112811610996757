import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';

import { normalize } from 'normalizr';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { pagesSelector } from '../../../reducers/selectors';
import { fetchInvoices, InvoiceType } from './InvoiceService';
import { InvoicesSchema } from '../../../../schema/Schemas';

const getActionType = ActionTypeUtils.getActionTypeFunction('INVOICE');

export const setInvoicesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const fetchInvoicesData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.INVOICE.FETCH_ALL;

  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchInvoices()
    .then((response) => {
      const normalizedData = normalize(response.items, InvoicesSchema);
      dispatch(setInvoicesData(dispatch, normalizedData.entities.invoices));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('INVOICE: fetch failed', e);
    });
});

type StateType = {
  items: IMap<string, InvoiceType>;
};

const initialState = {
  items: IMap(),
};

export default handleActions(
  {
    [setInvoicesData.toString()]: (state, action: any) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const invoiceDataSelector = createSelector(pagesSelector, (state) => state.invoice.data.invoice as StateType);
export const invoiceItemsSelector = createSelector(invoiceDataSelector, (invoice) =>
  invoice.items
    .sortBy(
      (item) => item.created_at,
      (a, b) => b - a,
    )
    .valueSeq()
    .toJSON(),
);
