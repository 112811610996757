import { connect } from 'react-redux';
import { appWidthSelector } from '../../AppDuck';
import {
  isWatermarkProcessingSelector,
  photoWatermarkSelectorForPreview,
} from '../../projects/project-meta/ProjectsMetaDataDuck';
import WatermarkPreviewFullViewImage from './WatermarkPreviewFullViewImage';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    isMobileView?: boolean;
    photo?: {
      name: string;
      image: string;
    };
  },
) {
  const { projectId, isMobileView, photo } = ownProps;

  return {
    watermarks: photoWatermarkSelectorForPreview(projectId)(state),
    appWidth: appWidthSelector(state),
    isWatermarkProcessing: isWatermarkProcessingSelector(projectId)(state),
    isMobileView,
    photo,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermarkPreviewFullViewImage);
