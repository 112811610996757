import React from 'react';
import {
  BUTTON_ICON_STYLES,
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroupValue,
  Tooltip,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { OccasionsService } from '@premagic/core';
import UserNamesContainer from '../users/list/UserNamesContainer';
import { USER_SERVICE_TYPE, USER_SERVICE_TYPE_DETAILS } from '../users/UsersService';
import MESSAGES from '../../../../../common/Messages';
import { PERMISSIONS } from '../users/UserPermissionService';
import EventOccasionOptionsMenuContainer from './dropdown-menu/EventOccasionOptionsMenuContainer';

type EventOccasionUsersProps = {
  usersGroupedByRole: Record<USER_SERVICE_TYPE, Array<string>>;
};

function EventOccasionUsers(props: EventOccasionUsersProps) {
  const { usersGroupedByRole } = props;
  return (
    <Row wrap>
      {Object.entries(USER_SERVICE_TYPE_DETAILS).map(([id, serviceDetails]) => (
        <div key={id}>
          <Row vcenter>
            <Tooltip message={serviceDetails.title}>
              <Color shade={COLOR_SHADES.LIGHT}>
                <Icon size={ICON_SIZES.MD} name={serviceDetails.icon} accentStyle={ICON_ACCENT_STYLES.FILL} />
              </Color>
            </Tooltip>
            <Space size={1} />
            <UserNamesContainer userIds={usersGroupedByRole[id]} />
            <Space size={8} />
          </Row>
          <Space vertical size={2} />
        </div>
      ))}
    </Row>
  );
}

type Props = {
  eventId: string;
  occasionId: string;
  occasion: OccasionsService.OccasionType;
  focus: boolean;
  showEditOccasion: (id: string) => void;
  usersGroupedByRole: Record<USER_SERVICE_TYPE, Array<string>>;
  hasPermissionsToManageEvent: boolean;
};

export default function EventOccasionItem(props: Props): React.ReactElement {
  const { occasion, occasionId, eventId, focus, showEditOccasion, usersGroupedByRole, hasPermissionsToManageEvent } =
    props;
  const {
    name,
    start_date_time: startDate,
    end_date_time: endDate,
    location,
    notes,
    location_link: locationLink,
    number_of_guests: numberOfGuests,
  } = occasion;

  return (
    <Card highlight={focus}>
      <Space>
        <CardHeader>
          <CardHeaderTitle>
            <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
              {name}
            </Text>
          </CardHeaderTitle>
          <CardHeaderActions>
            <ButtonIcon
              disabled={!hasPermissionsToManageEvent}
              disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_OCCASION_MANAGE]}
              style={BUTTON_ICON_STYLES.SECONDARY}
              size={BUTTON_ICONS_SIZES.SM}
              title="Edit"
              onClick={() => showEditOccasion(occasionId)}
            >
              <Icon name="edit_2" size={ICON_SIZES.SM} />
            </ButtonIcon>

            <Space size={2} />

            <EventOccasionOptionsMenuContainer occasionId={occasionId} eventId={eventId} />
          </CardHeaderActions>
        </CardHeader>

        <Tooltip message="People attending">
          <Row vcenter inline>
            <Icon name="users" size={ICON_SIZES.SM} />
            <Space size={2} />
            <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
              {numberOfGuests || (
                <Text size={TEXT_SIZE.SIZE_3} muted>
                  (not set)
                </Text>
              )}
            </Text>
          </Row>
        </Tooltip>
        <Space vertical size={2} />
        <Row vcenter>
          <Icon name="map_pin" size={ICON_SIZES.SM} />
          <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
            {locationLink ? (
              <SimpleLink href={locationLink} isExternalLink>
                {location}
              </SimpleLink>
            ) : (
              location || (
                <Text size={TEXT_SIZE.SIZE_3} muted>
                  (not set)
                </Text>
              )
            )}
          </Text>
        </Row>
        <Space vertical size={2} />
        <Row vcenter>
          <Icon name="clock" size={ICON_SIZES.SM} />
          <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
            {SimpleDateUtils.humanizeDateRage(startDate, endDate)}
          </Text>
        </Row>
        <Space vertical />
        <EventOccasionUsers usersGroupedByRole={usersGroupedByRole} />
        <Space vertical />
        <Text boldness={TEXT_BOLDNESS.BOLD}>Notes</Text>
        <TextGroupValue type={TEXT_GROUP_TYPES.RICH_TEXTAREA} value={notes} />
      </Space>
    </Card>
  );
}
