import React, { useState } from 'react';

import {
  Col,
  DatePickerWithTimezoneSelector,
  FormGroup,
  FormLabel,
  Input,
  RealCheckbox,
  RichTextArea,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { ClientWebsiteService, EventAttendeesService } from '@premagic/core';

const DURATIONS = [
  0,
  5,
  10,
  15,
  30,
  45,
  50,
  60,
  60 * 2,
  60 * 3,
  60 * 4,
  60 * 5,
  60 * 6,
  60 * 7,
  60 * 8,
  60 * 9,
  60 * 10,
  60 * 11,
  60 * 12,
  60 * 24,
];
const durationOptions = DURATIONS.map((item) => ({
  label: item ? SimpleDateUtils.humanizeDuration(SimpleDateUtils.getTimePartsFromMinutes(item)) : 'Not decided',
  value: item,
}));

type ItineraryDatesProps = {
  start?: string;
  end?: string;
  timezone?: string;
  timeNotDecided?: boolean;
};

function ItineraryDates(props: ItineraryDatesProps) {
  const {
    start = SimpleDateUtils.getDateStringFromDate(
      SimpleDateUtils.getStartDateTime(),
      SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
    ),
    end,
    timezone,
    timeNotDecided,
  } = props;
  const [fromDateString, setFromDateString] = useState(start);
  const [duration, setDuration] = useState(end ? SimpleDateUtils.getMinutesRemaining(start, end) : 60 * 1);

  const selectedDurationOptions = durationOptions.find((item) => item.value === duration);

  const endDateWithDuration = SimpleDateUtils.addToDate(fromDateString, 'minutes', duration);
  const endDateWithDurationString = SimpleDateUtils.getDateStringFromDate(
    endDateWithDuration,
    SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
  );

  return (
    <Row>
      <Col size={4}>
        <FormGroup>
          <Row>
            <FormLabel isRequired>Date</FormLabel>
            <Col rightAlighed size={null}>
              <RealCheckbox name="time_not_decided" defaultChecked={timeNotDecided}>
                Time not decided
              </RealCheckbox>
            </Col>
          </Row>
          <DatePickerWithTimezoneSelector
            name="start_date_time"
            defaultValue={start}
            nameForTimezoneInput="date_timezone"
            timezone={timezone}
            showTimePicker
            onChange={(date) => {
              setFromDateString(date);
            }}
          />
          <input type="hidden" name="end_date_time" value={endDateWithDurationString} />
        </FormGroup>
      </Col>
      <Space size={8} />
      <Col size={3}>
        <FormGroup>
          <FormLabel isRequired>Duration</FormLabel>
          <Select
            options={durationOptions}
            name=""
            isMulti={false}
            value={selectedDurationOptions}
            onChange={(option) => setDuration(option?.value as number)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

type Props = {
  itinerary?: ClientWebsiteService.ClientWebsiteItinerary;
  speakers: Array<EventAttendeesService.EventAttendeeType>;
  lastItineraryEndDate?: {
    date: string;
    timezone: string;
  };
};

export default function ClientWebsiteItineraryForm(props: Props) {
  const { itinerary, speakers, lastItineraryEndDate } = props;
  const defaultSpeakersValueArray = itinerary?.speakers || [];
  const speakerOptions = speakers.map((item) => ({ label: item.name, value: item.registration_id }));
  const defaultSpeakersValue = speakerOptions.filter((item) => defaultSpeakersValueArray.indexOf(item.value) !== -1);
  const [defaultSpeakers, setSpeakers] = useState(defaultSpeakersValue);

  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Title</FormLabel>
        <Input name="name" placeholder="eg. Keynote" defaultValue={itinerary?.name} required />
      </FormGroup>
      <ItineraryDates
        timeNotDecided={itinerary?.time_not_decided}
        start={itinerary?.start_date_time || lastItineraryEndDate?.date}
        end={itinerary?.end_date_time}
        timezone={itinerary?.date_timezone || lastItineraryEndDate?.timezone}
      />
      <FormGroup>
        <FormLabel>What is the session about?</FormLabel>
        <RichTextArea name="details" defaultValue={itinerary?.details} />
      </FormGroup>
      <FormGroup>
        <FormLabel info="All the speaker attendees will be listed here">Speakers</FormLabel>
        <Col>
          <Select
            options={speakerOptions}
            name="speakers"
            isMulti
            value={defaultSpeakers}
            onChange={(option) => setSpeakers(option as Array<{ label: string; value: string }>)}
          />
        </Col>
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel>Location</FormLabel>
            <Input type="text" name="venue[location]" defaultValue={itinerary?.venue.location} />
          </FormGroup>
        </Col>
        <Space size={8} />
        <Col>
          <FormGroup>
            <FormLabel>Location Google maps link</FormLabel>
            <Input
              type="url"
              name="venue[location_link]"
              pattern="https://.*"
              defaultValue={itinerary?.venue.location_link}
              placeholder="eg. https://goo.gl/maps/GKFDag2gdtwZH9Qu9"
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
