import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { WindowPanelDuck } from '@premagic/common-ducks';

import EventBadgesLayout from './EventBadgesLayout';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { eventProjectSelectorSelector } from '../../../projects/AccountProjectsDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    projectId,
    focusId,
    hasPermissions: {
      [PERMISSIONS.EVENT_BADGES]: hasPermission(PERMISSIONS.EVENT_BADGES)(state),
    },
    hasFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddNewBadge: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBadgesLayout);
