import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
  DialogTitle,
  COLOR_SHADES,
} from '@premagic/myne';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import MESSAGES from '../../../../../../common/Messages';
import { UserType } from '../UsersService';

type Props = {
  userId: string;
  isLoading: boolean;
  user: UserType;
  hideDialog: () => void;
  removeUser: (userId: string) => void;
};

export default function UserDeleteDialog(props: Props): React.ReactElement {
  const { isLoading, user, hideDialog, removeUser, userId } = props;
  return (
    <Dialog
      target={getElementFromSelector(`${JS_SELECTORS.USER_MORE_OPTIONS}${userId}`)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={hideDialog}
    >
      <DialogTitle>Remove user {user.name}</DialogTitle>
      <Text>Are you sure you want to remove this user? </Text>
      <Space vertical size={6} />
      <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={hideDialog}>
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.DANGER} onClick={() => removeUser(userId)} isLoading={isLoading}>
          Remove User
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
