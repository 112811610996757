import md5 from 'crypto-js/md5';
import hmacSha1 from 'crypto-js/hmac-sha1';
import Base64 from 'crypto-js/enc-base64';

export const getCrypto = () => <Crypto>(window.crypto || (<any>window).msCrypto); // ie 11.x uses msCrypto

export function createUUID(length = 8): string {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-~';
  let uuid = '';
  const randomValues = Array.from(getCrypto().getRandomValues(new Uint8Array(length)));
  randomValues.forEach((v) => {
    uuid += charset[v % charset.length];
  });
  return uuid;
}

export function isUUID(uuid: string): boolean {
  return /^[0-9A-Za-z\-_~]{8}$/.test(uuid);
}

export function createRandomString(): string {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(getCrypto().getRandomValues(new Uint8Array(43)));
  randomValues.forEach((v) => {
    random += charset[v % charset.length];
  });
  return random;
}

export function getMD5Hash(text: string): string {
  return md5(text);
}

export function createSignForPath(path) {
  const s = 'Pr3m@g1c';
  return Base64.stringify(hmacSha1(path, s)).replace(/\+/g, '-').replace(/\//g, '_');
  // return hmacSha1(s).update(path).digest('base64').replace(/\+/g, '-').replace(/\//g, '_');
}

export function getRandomNumberFunctionBasedOnHash(hash: string): (min: number, max: number) => number {
  const hashArray = Array.from(hash);
  const hashArrayLength = hashArray.length;
  let index = 0;
  return (min: number, max: number) => {
    const value = hashArray[index % hashArrayLength].charCodeAt(0);
    index += 1;
    return Math.floor((value * (max - min)) / 255) + min;
  };
}
