/**
 * This uses the date fns, I think for long term we need to use date-fns as we moment is deprecated. But I am not sure how to handle timezone in datefns
 */
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  endOfMonth,
  format,
  formatDistanceToNow,
  formatDuration,
  formatISO,
  getDay,
  getUnixTime,
  isEqual,
  isPast,
  isSameDay,
  isSameMonth as isSameMonthDateFns,
  isSameYear,
  isTomorrow,
  parse,
  set,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import localEN_US from 'date-fns/locale/en-US';
import { isNumber } from 'lodash';

import { pluralize } from './StringUtils';
import { getCurrentTimezone, getTzDateFromUTCDate, getUTCDateFromTzDate, isSameTimezone } from './TimezoneUtils';

const locales = {
  'en-US': localEN_US,
};

export function getDateObject(dateString: string, tz?: string, toUTC = true): Date {
  // console.log('getDateObject', {
  //   dateString,
  //   tz: getCurrentTimezone(),
  //   toDate: getTzDateFromUTCDate(dateString, getCurrentTimezone()),
  // });

  if (tz && toUTC) return getUTCDateFromTzDate(dateString, tz);
  if (tz) return getTzDateFromUTCDate(dateString, tz);

  return getTzDateFromUTCDate(dateString, getCurrentTimezone());
  // return new Date(dateString);
}

export function now(): string {
  return formatISO(new Date());
}

export function nowInEpoc(): number {
  return new Date().getTime();
}

type DateFormatsType = {
  month: string;
  date: string;
  day: string;
  dateSimple: string;
  year: string;
};

export enum DATE_FORMATS {
  DEFAULT = 'default',
  FULL_LENGTH = 'full-length',
  IN_BETWEEN = 'in-between',
}

const DATE_FORMATS_DETAILS = {
  [DATE_FORMATS.DEFAULT]: {
    month: 'MMM',
    date: 'do',
    day: 'EEE',
    dateSimple: 'd',
    year: 'yyyy',
  },
  [DATE_FORMATS.IN_BETWEEN]: {
    month: 'MMM',
    date: 'dd',
    day: 'EEE',
    dateSimple: 'd',
    year: 'yyyy',
  },
  [DATE_FORMATS.FULL_LENGTH]: {
    month: 'MMMM',
    date: 'dd',
    day: 'EEEE',
    dateSimple: 'd',
    year: 'yyyy',
  },
};

export function getDateDetails(dateString: string, formats = DATE_FORMATS.DEFAULT): DateFormatsType {
  const dateObj = getDateObject(dateString === 'no-date' ? now() : dateString);

  const formatData = DATE_FORMATS_DETAILS[formats];
  const month = format(dateObj, formatData.month);
  const date = format(dateObj, formatData.date);
  const day = format(dateObj, formatData.day);
  const dateSimple = format(dateObj, formatData.dateSimple);
  const year = format(dateObj, formatData.year);

  return {
    month,
    date,
    dateSimple,
    day,
    year,
  };
}

export function getDaysRemaining(fromDateString: string, dateString: string): number {
  return differenceInDays(getDateObject(dateString), getDateObject(fromDateString));
}

export function getHoursRemaining(fromDateString: string, dateString: string): number {
  return differenceInHours(getDateObject(dateString), getDateObject(fromDateString));
}

export function getMinutesRemaining(fromDateString: string, dateString: string): number {
  return differenceInMinutes(getDateObject(dateString), getDateObject(fromDateString));
}

export function getTimeSting(dateString: string, tz?: string, toUTC?: boolean): string {
  return format(getDateObject(dateString, tz, toUTC), 'h:mm bbb');
}

export enum STANDARD_DATE_FORMATS {
  DATE_ISO = 'DATE-ISO',
  ISO = 'ISO',
  DATE_AND_TIME = 'do(E) MMM, h:mm aaa',
  TIME = 'h:mm aaa',
  DATE = 'dd MMM',
  DATE_WITH_DAY = 'EEEE, MMM dd',
  DATE_SIMPLE = 'd MMM',
  DATE_YEAR = 'd MMM, yyyy',
  MONTH_YEAR = 'MMM yyyy',
  DATE_YEAR_SIMPLE = 'd MMM yy',
  DATE_YEAR_TIME = 'd MMM, yyyy, h:mm aaa',
  DAY = 'd',
  DAY_WITH_POSTFIX = 'do',
  MONTH = 'MMMM',
  YEAR = 'yyyy',
  FULL_DATE = 'yyyy-MM-dd',
  DATE_WITH_TIME_INPUT = 'dd/MM/yyyy h:mm aa',
  DATE_INPUT = 'dd/MM/yyyy',
}

export function getDateStringFromDate(
  date: string | Date,
  dateFormat: STANDARD_DATE_FORMATS,
  options?: { tz?: string; toUTC: boolean },
): string {
  if (!date || date === 'no-date') return 'no-date';
  try {
    const { tz, toUTC } = options || {};
    if (tz) {
      // If there is timezone then call the function with update Date with tz information
      switch (typeof date) {
        case 'string':
          return getDateStringFromDate(getDateObject(date, tz, toUTC), dateFormat);
        default:
          return getDateStringFromDate(getDateObject(date.toISOString(), tz, toUTC), dateFormat);
      }
    }

    if (typeof date === 'string') {
      // If date is passed as string, then convert to date object
      return getDateStringFromDate(getDateObject(date), dateFormat);
    }

    switch (dateFormat) {
      case STANDARD_DATE_FORMATS.DATE_ISO:
        return formatISO(date, { representation: 'date' });
      case STANDARD_DATE_FORMATS.ISO:
        // https://github.com/date-fns/date-fns/discussions/2366
        return date.toISOString();
      default:
        return format(date, dateFormat);
    }
  } catch {
    return 'no-date';
  }
}

export function getDateStringISO(date: string | Date): string {
  if (!date) return getDateStringFromDate(new Date(), STANDARD_DATE_FORMATS.ISO);
  return getDateStringFromDate(date, STANDARD_DATE_FORMATS.ISO);
}

// eg. 1690828200
export function getUnixTimestamp(dateString: string): number {
  return getUnixTime(new Date(dateString));
}

export function getISODateStringFromTimestamp(timestamp: number): string {
  let date = new Date(timestamp);
  // check if timestamp is in seconds
  if (String(Math.floor(timestamp)).length === 10) {
    date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  }
  return getDateStringISO(date);
}

export function getDateStringISOFromUglyBackendDateFormat(date?: string): string {
  if (!date) return 'no-date';
  let dateObject = parse(date, 'yyyy-MM-dd', new Date());

  if (date.length > 10) {
    // adding +0000 to the end of the date string to make it ISO compliant
    dateObject = parse(`${date}-+00`, 'yyyy-MM-dd-HH-mm-ss-x', new Date());
  }
  return getDateStringISO(dateObject);
}

// eg. 1690828200 | 10 digit
export function getTimestampFromUglyBackendDateFormat(date?: string): number {
  if (isNumber(date)) return date;
  if (!date) return nowInEpoc() / 1000;
  const dateString = getDateStringISOFromUglyBackendDateFormat(date);
  return getUnixTimestamp(dateString);
}

export function getDateStringISOFromUglyBackendDateEPOCFormat(date?: string): string {
  if (!date) return 'no-date';
  const dateObject = new Date(Number(date) * 1000);

  return getDateStringISO(dateObject);
}

export function getTimePartsFromSeconds(totalSeconds: number): {
  hours: number;
  minutes: number;
  days: number;
  seconds: number;
} {
  enum DIVIDERS {
    MINUTES = 60,
    HOURS = 60, // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
    DAYS = 24,
  }
  enum DIVIDERS_ACC {
    SECONDS = 0,
    MINUTES = 60,
    HOURS = 3600,
    DAYS = 86400,
  }
  const days = Math.floor(totalSeconds / DIVIDERS_ACC.DAYS);
  const hours = Math.floor(totalSeconds / DIVIDERS_ACC.HOURS) % DIVIDERS.DAYS;
  const minutes = Math.floor(totalSeconds / DIVIDERS_ACC.MINUTES) % DIVIDERS.HOURS;
  const seconds = Math.floor(totalSeconds % DIVIDERS_ACC.MINUTES) % DIVIDERS.MINUTES;

  return {
    days: days > 0 ? days : 0,
    hours: hours > 0 ? hours : 0,
    minutes: minutes > 0 ? minutes : 0,
    seconds: seconds > 0 ? seconds : 0,
  };
}

export function getTimePartsFromMinutes(totalMinutes: number): {
  hours: number;
  minutes: number;
  days: number;
} {
  enum DIVIDERS {
    MINUTES = 60,
    HOURS = 60, // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
    DAYS = 24,
  }
  enum DIVIDERS_ACC {
    MINUTES = 60,
    HOURS = 60, // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
    DAYS = 60 * 24,
  }
  const days = Math.floor(totalMinutes / DIVIDERS_ACC.DAYS);
  const hours = Math.floor(totalMinutes / DIVIDERS_ACC.HOURS) % DIVIDERS.DAYS;
  const minutes = Math.floor(totalMinutes % DIVIDERS.HOURS);

  return {
    days: days > 0 ? days : 0,
    hours: hours > 0 ? hours : 0,
    minutes: minutes > 0 ? minutes : 0,
  };
}

export function getTimeParts(
  fromDateString: string,
  toDateString: string,
): { days: number; hours: number; minutes: number; seconds: number } {
  const secondsRemaining = differenceInSeconds(getDateObject(toDateString), getDateObject(fromDateString));
  return getTimePartsFromSeconds(secondsRemaining);
}

export function getRemainingInfoForADate(
  fromDateString: string,
  toDateString: string,
): { value: number | string; unit: 'Days' | 'Day' | 'Hours' | 'Hour'; postFix: 'to go' | 'ago' } {
  const daysRemaining = getDaysRemaining(fromDateString, toDateString);
  const hoursRemaining = getHoursRemaining(fromDateString, toDateString);
  const isOnSameDay = isSameDay(getDateObject(fromDateString), getDateObject(toDateString));

  if (isOnSameDay) {
    return {
      value: Math.abs(hoursRemaining),
      unit: pluralize('Hour', hoursRemaining) as 'Hour' | 'Hours',
      postFix: hoursRemaining > -1 ? 'to go' : 'ago',
    };
  }

  if (daysRemaining === 0 || daysRemaining === -1) {
    // Mostly day before the event || after the event
    return {
      value: 1,
      unit: 'Day',
      postFix: hoursRemaining > 0 ? 'to go' : 'ago',
    };
  }

  return {
    value: Math.abs(daysRemaining),
    unit: pluralize('Day', daysRemaining) as 'Day' | 'Days',
    postFix: daysRemaining > -1 ? 'to go' : 'ago',
  };
}

export const dateFnsLocalizerFunctions = {
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
};

export function humanizeDateSimple(dateString: string): string {
  if (!dateString || dateString === 'no-date') return 'no-date';
  const nowDate = getDateObject(now());
  const date = getDateObject(dateString);

  if (isSameYear(nowDate, date)) {
    return format(date, STANDARD_DATE_FORMATS.DATE_SIMPLE);
  }

  return format(date, STANDARD_DATE_FORMATS.DATE_YEAR_SIMPLE);
}

export function humanizeDate(
  dateString: string,
  compact: boolean,
  isInASentence?: boolean,
  hideTime?: boolean,
  tz?: string,
): string {
  if (!dateString || dateString === 'no-date') return 'no-date';
  const nowDate = getDateObject(now(), tz, false);
  const date = getDateObject(dateString, tz, false);

  if (hideTime) {
    if (isSameDay(nowDate, date)) {
      if (isInASentence) {
        return 'today';
      }
      return 'Today';
    }

    if (isTomorrow(date)) {
      if (isInASentence) {
        return 'tomorrow';
      }

      return 'Tomorrow';
    }

    if (isSameYear(nowDate, date)) {
      return format(date, STANDARD_DATE_FORMATS.DATE_SIMPLE);
    }

    return format(date, STANDARD_DATE_FORMATS.DATE_YEAR_SIMPLE);
  }

  if (isSameDay(nowDate, date)) {
    if (isInASentence) {
      return format(date, STANDARD_DATE_FORMATS.TIME) + (compact ? '' : ', today');
    }

    return (compact ? '' : 'Today, ') + format(date, STANDARD_DATE_FORMATS.TIME);
  }

  if (isTomorrow(date)) {
    if (isInASentence) {
      return format(date, STANDARD_DATE_FORMATS.TIME) + (compact ? '' : ', tomorrow');
    }

    return (compact ? '' : 'Tomorrow, ') + format(date, STANDARD_DATE_FORMATS.TIME);
  }

  if (isSameYear(nowDate, date)) {
    if (isInASentence) {
      return compact
        ? format(date, STANDARD_DATE_FORMATS.DATE_SIMPLE)
        : `${format(date, STANDARD_DATE_FORMATS.TIME)} on ${format(date, STANDARD_DATE_FORMATS.DATE_SIMPLE)}`;
    }

    return format(date, compact ? STANDARD_DATE_FORMATS.DATE_SIMPLE : STANDARD_DATE_FORMATS.DATE_AND_TIME);
  }

  if (isInASentence) {
    return compact
      ? format(date, STANDARD_DATE_FORMATS.DATE_YEAR_SIMPLE)
      : `${format(date, STANDARD_DATE_FORMATS.TIME)} on ${format(date, STANDARD_DATE_FORMATS.DATE_YEAR)}`;
  }

  return format(date, compact ? STANDARD_DATE_FORMATS.DATE_YEAR_SIMPLE : STANDARD_DATE_FORMATS.DATE_YEAR_TIME);
}

function timezoneDetails(timezone?: string): string {
  if (!timezone) return '';

  if (isSameTimezone(timezone)) {
    return '';
  }
  return ` (${timezone})`;
}

export function humanizeDateRage(
  start: string,
  end?: string,
  options?: {
    showOnlyTime?: boolean;
    dateTimezone?: string;
  },
): string {
  if (!start || start === 'no-date' || !end || end === 'no-date') return 'no-date';
  const { showOnlyTime, dateTimezone } = options || {};
  const startDate = getDateObject(start, dateTimezone, false);

  if (!end) return `${humanizeDate(start, false, false, false, dateTimezone)}${timezoneDetails(dateTimezone)}`;

  const endDate = getDateObject(end, dateTimezone, false);
  if (showOnlyTime) {
    if (isEqual(startDate, endDate)) {
      return `${format(startDate, STANDARD_DATE_FORMATS.TIME)}${timezoneDetails(dateTimezone)}`;
    }
    if (isSameDay(startDate, endDate))
      return `${format(startDate, STANDARD_DATE_FORMATS.TIME)} - ${format(
        endDate,
        STANDARD_DATE_FORMATS.TIME,
      )}${timezoneDetails(dateTimezone)}`;
    return `${format(startDate, STANDARD_DATE_FORMATS.TIME)} - ${humanizeDate(
      end,
      false,
      false,
      false,
      dateTimezone,
    )}${timezoneDetails(dateTimezone)}`;
  }

  if (isEqual(startDate, endDate))
    return `${humanizeDate(start, false, false, false, dateTimezone)}${timezoneDetails(dateTimezone)}`;

  if (isSameDay(startDate, endDate))
    return `${humanizeDate(start, false, false, false, dateTimezone)} - ${format(
      endDate,
      STANDARD_DATE_FORMATS.TIME,
    )}${timezoneDetails(dateTimezone)}`;

  return `${humanizeDate(start, false, false, false, dateTimezone)} - ${humanizeDate(
    end,
    false,
    false,
    false,
    dateTimezone,
  )}${timezoneDetails(dateTimezone)}`;
}

export function humanizeDateRageSimple(start: string, end?: string): string {
  const startDate = getDateObject(start);
  if (!end) {
    return humanizeDateSimple(start);
  }
  const endDate = getDateObject(end);
  if (isSameDay(startDate, endDate)) {
    return humanizeDateSimple(start);
  }

  return `${humanizeDateSimple(start)} - ${humanizeDateSimple(end)}`;
}

export function humanizeDuration(duration: {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}): string {
  return formatDuration(duration);
}

export function fromNowDate(dateString: string, isInASentence?: boolean): string {
  const nowDate = getDateObject(now());
  const eventTime = getDateObject(dateString);

  const days = differenceInDays(nowDate, eventTime);
  const distanceString = formatDistanceToNow(eventTime, {
    addSuffix: isInASentence,
  });

  if (isSameDay(eventTime, nowDate)) {
    return isInASentence ? 'today' : 'Today';
  }

  if (days > 0 && Math.round(days) < 2) {
    return isInASentence ? 'yesterday' : 'Yesterday';
  }

  if (days < 0 && Math.round(days) > -2) {
    return isInASentence ? 'tomorrow' : 'Tomorrow';
  }

  return distanceString;
}

export function fromNow(dateString: string, isInASentence?: boolean, nowDateString?: string): string {
  const nowDate = getDateObject(nowDateString || now());
  const eventTime = getDateObject(dateString);

  const days = differenceInDays(nowDate, eventTime);
  const months = differenceInMonths(nowDate, eventTime);

  if (isSameDay(eventTime, nowDate)) {
    const timeAgo = formatDistanceToNow(eventTime, {
      addSuffix: true,
    });
    return isInASentence ? `at ${timeAgo}` : timeAgo;
  }
  if (days >= 0 && Math.round(days) < 2) {
    return 'Yesterday';
  }
  if (days < 0 && Math.round(days) > -2) {
    return 'Tomorrow';
  }
  if (months < 0 && months > -1) {
    return `in ${Math.abs(Number(days.toFixed()))} days`;
  }
  if (months >= 0 && months < 1) {
    return `${days.toFixed()} days ago`;
  }

  return humanizeDate(getDateStringISO(eventTime), true, isInASentence);
}

export function fromNowForTooltip(dateString: string): string {
  const nowDate = getDateObject(now());
  const eventTime = getDateObject(dateString);
  const days = differenceInDays(nowDate, eventTime);

  if (isSameDay(eventTime, nowDate)) {
    return `Today, ${getDateStringFromDate(eventTime, STANDARD_DATE_FORMATS.TIME)}`;
  }
  if (days > 0 && Math.round(days) < 2) {
    return `Yesterday, ${getDateStringFromDate(eventTime, STANDARD_DATE_FORMATS.TIME)}`;
  }
  if (days < 0 && Math.round(days) > -2) {
    return `Tomorrow, ${getDateStringFromDate(eventTime, STANDARD_DATE_FORMATS.TIME)}`;
  }

  return humanizeDate(getDateStringISO(eventTime), false);
}

export function addToDate(
  date: string,
  duration: 'years' | 'months' | 'days' | 'hours' | 'minutes',
  unit: number,
): Date {
  return add(getDateObject(date), {
    [duration]: unit,
  });
}

export function subtractToDate(
  date: string,
  duration: 'years' | 'months' | 'days' | 'hours' | 'minutes',
  unit: number,
): Date {
  return sub(getDateObject(date), {
    [duration]: unit,
  });
}

export function getStartDateTime(): Date {
  return set(new Date(), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}
// return 9:00 AM of the date
export function getStartDateTimeForDate(dateString: string): Date {
  const dateObject = getDateObject(dateString);
  return set(dateObject, {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}

export function isPastDate(dateString: string): boolean {
  return isPast(getDateObject(dateString));
}

export function isToday(dateString: string): boolean {
  const nowDate = getDateObject(now());
  return isSameDay(getDateObject(dateString), nowDate);
}

export function isSameMonth(dateString: string, dateString2: string): boolean {
  return isSameMonthDateFns(getDateObject(dateString), getDateObject(dateString2));
}

export function getStartDateOfMonth(dateString: string): Date {
  const dateObject = getDateObject(dateString);
  return startOfMonth(dateObject);
}

export function getEndDateOfMonth(dateString: string): Date {
  const dateObject = getDateObject(dateString);
  return endOfMonth(dateObject);
}

export function getYearDifference(dateString: string): number {
  const dateObject = getDateObject(dateString);
  const nowDate = getDateObject(now());
  return differenceInYears(nowDate, dateObject);
}
