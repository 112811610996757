import { BrowserUtils, CanvasUtils, HttpUtils } from '@premagic/utils';
import { PosterWidgetType } from './PosterWidgetService';

export enum POSTER_MEDIA_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  GIF = 'GIF',
}

export enum POSTER_POST_FORMAT {
  DEPRECATED_LINKEDIN_ARTICLE = 'DEPRECATED_LINKEDIN_ARTICLE',
  // ARTICLE = 'ARTICLE',
  CONTENT_WITH_IMAGE_LINK = 'CONTENT_WITH_IMAGE_LINK',
}

export const POSTER_POST_FORMAT_DETAILS: Record<
  POSTER_POST_FORMAT,
  {
    label: string;
    value: POSTER_POST_FORMAT;
    info: string;
  }
> = {
  [POSTER_POST_FORMAT.DEPRECATED_LINKEDIN_ARTICLE]: {
    label: 'Post with Article format',
    value: POSTER_POST_FORMAT.DEPRECATED_LINKEDIN_ARTICLE,
    info: 'The poster will be shown as a small preview with link',
  },
  [POSTER_POST_FORMAT.CONTENT_WITH_IMAGE_LINK]: {
    label: 'Post with Image',
    value: POSTER_POST_FORMAT.CONTENT_WITH_IMAGE_LINK,
    info: 'The poster will be show and the link will be in the message',
  },
};

export type PosterType = {
  id: string;
  name: string;
  size: {
    width: number;
    height: number;
  };
  media: POSTER_MEDIA_TYPE;
  created_at?: string;
  updated_at?: string;
  widgets: Record<string, PosterWidgetType>;
  poster_format?: POSTER_POST_FORMAT;
};

// We cannot import (FolderFileType) core services inside out poster-creator library, so we need to define some types here:
export type PosterFileType = {
  id: string; // I am not sure if we have this. | this wont come from api, but on selection-signature we add it on selector
  asset_type: 'image' | 'video';
  dynamic_image_url: string;
  location: string;
  meta?: {
    width?: number;
    height?: number;
    ratio?: number;
  };
};

//  Name  width Height
//  CR80    54mm  85.6mm
//  CR100   67    99
//  A7      74    105
//  A6      105   148
//  A4      210   297
// Landscape 317.46 166.14

// 1200 in mm = 1200 / 3.78 = 317.46
// 628 in mm = 628 / 3.78 = 166.14
// 949 x 1503 what is this size?
export enum POSTER_SIZE {
  LABEL_75X55 = 'LABEL_75x55',
  CR80 = 'CR80',
  CR100 = 'CR100',
  A7 = 'A7',
  A6 = 'A6',
  A4 = 'A4',
  A3 = 'A3',
  A3_LANDSCAPE = 'A3_LANDSCAPE',
  A2 = 'A2',
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
  CUSTOM = 'CUSTOM',
}
export enum POSTER_SIZE_TYPES {
  BADGE = 'BADGE',
  SOCIAL = 'SOCIAL',
  MAGAZINE = 'MAGAZINE',
}

const DPI = {
  D96: 96,
  D150: 150,
  D300: 300,
};
const BASE_DPI = DPI.D96;
export type PosterSizeDetail = {
  width: number;
  height: number;
  name: string;
  type: Array<POSTER_SIZE_TYPES>;
};
export const POSTER_SIZE_DETAIL: Record<POSTER_SIZE, PosterSizeDetail> = {
  [POSTER_SIZE.LANDSCAPE]: {
    name: 'Landscape',
    width: 1200,
    height: 627,
    type: [POSTER_SIZE_TYPES.SOCIAL],
  },
  [POSTER_SIZE.PORTRAIT]: {
    name: 'Portrait',
    width: 1280,
    height: 1600,
    type: [POSTER_SIZE_TYPES.SOCIAL],
  },
  [POSTER_SIZE.SQUARE]: {
    name: 'Square',
    width: 1080,
    height: 1080,
    type: [POSTER_SIZE_TYPES.SOCIAL],
  },
  // [POSTER_SIZE.PORTRAIT]: {
  //   name: 'Portrait',
  //   width: 382,
  //   height: 605,
  //   type: [POSTER_SIZE_TYPES.BADGE],
  // },
  [POSTER_SIZE.LABEL_75X55]: {
    name: 'Label 75mm x 55mm',
    width: Math.round((75 / 25.4) * BASE_DPI),
    height: Math.round((55 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.BADGE],
  },
  [POSTER_SIZE.CR80]: {
    width: Math.round(2.125 * BASE_DPI),
    height: Math.round(3.375 * BASE_DPI),
    name: 'CR80',
    type: [POSTER_SIZE_TYPES.BADGE],
  },
  [POSTER_SIZE.CR100]: {
    name: 'CR100',
    width: Math.round(2.625 * BASE_DPI),
    height: Math.round(3.875 * BASE_DPI),
    type: [POSTER_SIZE_TYPES.BADGE],
  },
  [POSTER_SIZE.A7]: {
    name: 'A7',
    width: Math.round((74 / 25.4) * BASE_DPI),
    height: Math.round((105 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.BADGE],
  },
  [POSTER_SIZE.A6]: {
    name: 'A6',
    width: Math.round((105 / 25.4) * BASE_DPI),
    height: Math.round((148 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.BADGE],
  },
  [POSTER_SIZE.A4]: {
    name: 'A4',
    width: Math.round((210 / 25.4) * BASE_DPI),
    height: Math.round((297 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.BADGE, POSTER_SIZE_TYPES.MAGAZINE],
  },
  [POSTER_SIZE.A3]: {
    name: 'A3',
    width: Math.round((297 / 25.4) * BASE_DPI),
    height: Math.round((420 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.MAGAZINE],
  },
  [POSTER_SIZE.A3_LANDSCAPE]: {
    name: 'A3 Landscape',
    width: Math.round((420 / 25.4) * BASE_DPI),
    height: Math.round((297 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.MAGAZINE],
  },
  [POSTER_SIZE.A2]: {
    name: 'A2',
    width: Math.round((420 / 25.4) * BASE_DPI),
    height: Math.round((594 / 25.4) * BASE_DPI),
    type: [POSTER_SIZE_TYPES.MAGAZINE],
  },
  [POSTER_SIZE.CUSTOM]: {
    name: 'Custom',
    width: 500,
    height: 500,
    type: [POSTER_SIZE_TYPES.BADGE, POSTER_SIZE_TYPES.SOCIAL, POSTER_SIZE_TYPES.MAGAZINE],
  },
};

export function convertToDPI(posterSize: POSTER_SIZE, targetDPI: number): PosterSizeDetail {
  // Get the original size detail from the POSTER_SIZE_DETAIL at BASE_DPI
  const originalSizeDetail = POSTER_SIZE_DETAIL[posterSize];

  // Calculate the conversion factor from BASE_DPI to targetDPI
  const conversionFactor = targetDPI / BASE_DPI;

  // Convert dimensions using the conversion factor
  return {
    width: Math.round(originalSizeDetail.width * conversionFactor),
    height: Math.round(originalSizeDetail.height * conversionFactor),
    name: originalSizeDetail.name,
    type: originalSizeDetail.type,
  };
}

export function getPosterSizeDetailsFromSpec(width: number, height: number): PosterSizeDetail {
  return (
    Object.entries(POSTER_SIZE_DETAIL).find(([, value]) => value.width == width && value.height == height)?.[1] ||
    POSTER_SIZE_DETAIL[POSTER_SIZE.CUSTOM]
  );
}

// This function can download file, You can use URI string also, use downloadAsFile
export function downloadFile(uriString: string, name: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uriString;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function getCanvasForPoster(): HTMLCanvasElement {
  return BrowserUtils.getElement<HTMLCanvasElement>('.js-poster canvas') as HTMLCanvasElement;
}

export function getDataURLForPoster(): string {
  return getCanvasForPoster()?.toDataURL('image/jpeg', 1) as string;
}

// export function downloadPosterAsImage(name: string): void {
//   downloadFile(getDataURLForPoster(), name);
// }

export function getImageBlobForPoster(): Blob {
  return CanvasUtils.dataURItoBlob(getDataURLForPoster());
}

// https://docs.picsart.io/reference/programmable-image-post_removebg
export function removeBackground(image: Blob | string): Promise<{
  status: 'success' | 'error';
  data: {
    id: string;
    url: string;
  };
}> {
  const form = new FormData();
  form.append('output_type', 'cutout');
  form.append('bg_blur', '0');
  form.append('scale', 'fit');
  form.append('format', 'PNG');

  if (typeof image === 'string') {
    form.append('image_url', image);
  } else {
    form.append('image', image);
  }

  return HttpUtils.post('https://api.picsart.io/tools/1.0/removebg', form, true, true, {
    'X-Picsart-API-Key': 'L3C4AxbHG2AxoIfrqZZPpBh5gG5zJt47',
  }).then((response) => response.data);
}
