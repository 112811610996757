import React from 'react';

import { ProductFeedbackService, ProjectMetaService, ProjectService } from '@premagic/core';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Card,
  CardHeader,
  CardHeaderTitle,
  Col,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  List,
  ListItem,
  Row,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils, i18n } from '@premagic/utils';
import APP_URLS from '../../../../services/AppRouteURLService';
import ProjectMetaToggleContainer from '../../../projects/highlight-settings/project-meta-toggle/ProjectMetaToggleContainer';

type ProjectLanguageProps = {
  defaultLanguageCode?: i18n.LANGUAGE_CODE;
  showSelectLanguagePanel: () => void;
};

function ProjectLanguageSelector(props: ProjectLanguageProps) {
  const { defaultLanguageCode, showSelectLanguagePanel } = props;

  const selectedLanguage = Object.values(i18n.LANGUAGES_DATA).find(
    (item) => item.langCode === defaultLanguageCode,
  )?.label;

  return (
    <Row>
      <FormLabel>Language for Public (Gallery, Registration pages)</FormLabel>
      <Col rightAlighed size={null}>
        <Row>
          <Text boldness={TEXT_BOLDNESS.BOLD}>{selectedLanguage}</Text>
          <Space vertical size={1} />
          <ButtonIcon title="Edit" onClick={showSelectLanguagePanel} size={BUTTON_ICONS_SIZES.SM}>
            <Icon name="edit_2" size={ICON_SIZES.SM} />
          </ButtonIcon>
        </Row>
      </Col>
    </Row>
  );
}

type Props = {
  projectId?: string;
  eventId: string;
  clientWebsiteId?: string;
  defaultLanguageCode?: i18n.LANGUAGE_CODE;
  showSelectLanguagePanel: () => void;
  isStaffUser: boolean;
  project?: ProjectService.ProjectType;
  updateClientWebsite: (websiteId: string, projectId: string, data: FormResponseType) => void;
};

export default function EventSettingsLayout(props: Props): React.ReactElement {
  const {
    projectId,
    defaultLanguageCode,
    eventId,
    showSelectLanguagePanel,
    isStaffUser,
    project,
    updateClientWebsite,
    clientWebsiteId,
  } = props;

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;
  const urlToEvent = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
    eventId,
  });

  return (
    <>
      <InnerPageContentMainCol hasSidebar>
        <Row alignItems="baseline">
          <ButtonIcon title="Back" link={urlToEvent}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
          </ButtonIcon>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            Event Settings
          </Text>
        </Row>
        <Space vertical size={4} />
        <Card>
          <Space>
            <ProjectLanguageSelector
              defaultLanguageCode={defaultLanguageCode}
              showSelectLanguagePanel={showSelectLanguagePanel}
            />
          </Space>
        </Card>
        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Event Marketing</CardHeaderTitle>
            </CardHeader>
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED}
            />
            <Space vertical />
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION}
            />
          </Space>
        </Card>
        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Gallery</CardHeaderTitle>
            </CardHeader>
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_GROUP_SIMILAR_IMAGES}
            />
            <Space vertical />
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_LOGO_HIDE}
            />
          </Space>
        </Card>
        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Registrations, Check-ins & Badges</CardHeaderTitle>
            </CardHeader>
            <List>
              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED}
                  />
                </Col>
              </ListItem>
              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE}
                  />
                </Col>
              </ListItem>

              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={
                      ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION
                    }
                  />
                </Col>
              </ListItem>
              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.PEOPLE_NETWORKING}
                  />
                </Col>
              </ListItem>
              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_FACE_CHECKIN_ENABLED}
                  />
                </Col>
              </ListItem>
              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED}
                  />
                </Col>
              </ListItem>
              <ListItem showHoverStyle>
                <Col size={12}>
                  <ProjectMetaToggleContainer
                    projectId={projectId}
                    itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_BADGES_ENABLED}
                  />
                </Col>
              </ListItem>
            </List>
          </Space>
        </Card>
        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Content generation</CardHeaderTitle>
            </CardHeader>
            <ListItem showHoverStyle>
              <Col size={12}>
                <ProjectMetaToggleContainer
                  projectId={projectId}
                  itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.AI_CONTENT_GENERATOR_FOR_ATTENDEE}
                />
              </Col>
            </ListItem>
            <ListItem showHoverStyle>
              <Col size={12}>
                <ProjectMetaToggleContainer
                  projectId={projectId}
                  itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.AVATAR_STUDIO_ENABLE}
                />
              </Col>
            </ListItem>
            <ListItem showHoverStyle>
              <Col size={12}>
                <ProjectMetaToggleContainer
                  projectId={projectId}
                  itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.MAGAZINE_CREATOR_ENABLE}
                />
              </Col>
            </ListItem>
          </Space>
        </Card>
        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Sponsors & Exhibitors</CardHeaderTitle>
            </CardHeader>
            <Space vertical />
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_EXHIBITOR}
            />
          </Space>
        </Card>
        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Asset management</CardHeaderTitle>
            </CardHeader>
            <Space vertical />
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.MAP_FILES_TO_ATTENDEE_GALLERY}
            />
          </Space>
        </Card>

        <Space vertical size={12} />
        {isStaffUser && (
          <div>
            <SubTitle>ONLY FOR STAFF</SubTitle>
            <Space vertical size={1} />
            <Card>
              <Space>
                <ProjectMetaToggleContainer
                  projectId={projectId}
                  itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.MARKETING_GENERATE_LEADS}
                />
                {project?.event_id && (
                  <Button
                    size={BUTTON_SIZE.XS}
                    style={BUTTON_STYLES.LINK}
                    link={BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__EVENT_FEEDBACK, {
                      eventId: project?.event_id,
                      type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.INTERESTED_LEAD,
                    })}
                  >
                    View leads
                  </Button>
                )}
                <Space vertical size={12} />
                <ProjectMetaToggleContainer
                  projectId={projectId}
                  itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.IS_GUEST_GALLERY_DOWNLOAD_PAID}
                />
              </Space>
            </Card>
          </div>
        )}
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <Space />
      </InnerPageContentSidebar>
    </>
  );
}
