import { connect } from 'react-redux';
import { LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../common/Constants';
import {
  clearFileSelectionForBulkAction,
  foldersEntitiesSelector,
  setBulkAction,
  totalFilesInFolderSelector,
  toggleAlbumView,
  albumViewWithFileNameSelector,
} from '../AccountFoldersDataDuck';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { clientForProjectFolderSelector } from '../../AccountProjectsDataDuck';
import FolderOptionsMenuButton from './FolderOptionsMenuButton';
import { setFolderIdsToShare } from '../../../folder-share/FolderShareDuck';
import { isFolderBlockedSelector, isFolderPublicSelector } from '../../folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  const clients = clientForProjectFolderSelector(projectId, [folderId])(state);

  return {
    folder: foldersEntitiesSelector(state)[folderId] || {},
    projectId,
    folderId,
    clients,
    totalFiles: totalFilesInFolderSelector(folderId)(state),
    isAlbumViewWithFileName: albumViewWithFileNameSelector(state),
    isFolderBlocked: isFolderBlockedSelector(folderId)(state),
    isFolderPublic: isFolderPublicSelector(folderId)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSharePanel: (folderId) => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.FOLDER_SHARE.SHARE, true));
      dispatch(setFolderIdsToShare(dispatch, [folderId]));
    },
    showDeleteFolder: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.DELETE, true));
    },
    showRenameFolder: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.RENAME, true));
    },
    setBulkAction: () => {
      dispatch(setBulkAction(dispatch, 'delete'));
    },
    toggleAlbumView: (status) => {
      dispatch(toggleAlbumView(dispatch, status));
    },
    clearFileSelectionForBulkAction: () => {
      dispatch(clearFileSelectionForBulkAction());
    },
    cancelBulkAction: () => {
      dispatch(setBulkAction(dispatch, null));
    },
    showFolderBlockDialog: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.BLOCK, true));
    },
    showExternalVideoLinkUploader: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.ADD_EXTERNAL_VIDEO_LINK, true));
    },
    showMarkAsPublicFolder: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.MARK_AS_PUBLIC, true));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FolderOptionsMenuButton);
