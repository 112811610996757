import React from 'react';
import { InnerPageHeader, InnerPageSubHeader, TabItem, Tabs } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { FolderService } from '@premagic/core';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  currentUrlPathname: string;
  folderId: string;
  folder?: FolderService.FolderType;
  isLoading: boolean;
};

export default function BrandingDetailsPageHeader(props: Props) {
  const {
    currentUrlPathname,
    folderId,
    folder = {
      folder_name: 'Loading',
    },
    isLoading,
  } = props;
  const watermarkDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.BRANDING.DETAILS, {
    folderId,
    view: 'watermark',
  });
  return (
    <div>
      <InnerPageHeader iconName="bold" title={folder.folder_name} backTo={APP_URLS.SETTINGS.BRANDING.HOME}>
        {folder.folder_name}
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem to={watermarkDetails} active={currentUrlPathname.includes(watermarkDetails)}>
            Watermark
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
    </div>
  );
}
