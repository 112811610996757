import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Col,
  COLOR_SHADES,
  Emoji,
  EMOJIE_SIZE,
  EMOJIS,
  LoadingDots,
  Page,
  Position,
  Row,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import {
  ClientSettingsService,
  CompanyService,
  CompanyStatsService,
  EventTrackerService,
  SubscriptionService,
} from '@premagic/core';
import { SimpleDateUtils } from '@premagic/utils';

import CompanyLogoContainer from '../../company/CompanyLogoContainer';
import APP_URLS from '../../../../services/AppRouteURLService';
import HaveAQuestion from '../../../help/HaveAQuestion';
import UserProfileDropdownContainer from '../../../user-profile/UserProfileDropdownContainer';
import SubscriptionsPlansContainer from '../SubscriptionsPlansContainer';

export const SUBSCRIPTION_STATUS_EMOJIES: Record<
  SubscriptionService.SUBSCRIPTION_STATUS,
  {
    emoji: EMOJIS;
  }
> = {
  [SubscriptionService.SUBSCRIPTION_STATUS.CANCELLED]: {
    emoji: EMOJIS.CRY,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.PAUSED]: {
    emoji: EMOJIS.SAD,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.EXPIRED]: {
    emoji: EMOJIS.SKULL,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.HALTED]: {
    emoji: EMOJIS.SAD,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.ACTIVE]: {
    emoji: EMOJIS.PARTYING_FACE,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.PENDING]: {
    emoji: EMOJIS.SMILING_FACE_NEUTRAL_FACE,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.AUTHENTICATED]: {
    emoji: EMOJIS.SMILING_FACE_NEUTRAL_FACE,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.CREATED]: {
    emoji: EMOJIS.SMILING_FACE_NEUTRAL_FACE,
  },
  [SubscriptionService.SUBSCRIPTION_STATUS.COMPLETED]: {
    emoji: EMOJIS.SMILING_FACE_NEUTRAL_FACE,
  },
};

type Props = {
  subscription: SubscriptionService.SubscriptionType;
  company: CompanyService.CompanyType;
  companyStats: CompanyStatsService.CompanyStatsType;
  isStaffUser: boolean;
  isLoading: boolean;
  clientSettings: ClientSettingsService.ClientSettingsType;
};

export default function SubscriptionStatusPage(props: Props) {
  const { subscription, company, isStaffUser, companyStats, isLoading, clientSettings } = props;
  if (isLoading) {
    return (
      <Page center screenHeight>
        <LoadingDots />
      </Page>
    );
  }

  const { ended_at: ending } = subscription;
  const endDate = SimpleDateUtils.getISODateStringFromTimestamp(ending);
  const now = SimpleDateUtils.now();
  const daysRemaining = SimpleDateUtils.getDaysRemaining(now, endDate);

  const { authorization_url: url, status } = subscription;
  const reason = SubscriptionService.getSubscriptionHaltedReason({
    company,
    companyStats,
    clientSettings,
  });
  const statusMessage = SubscriptionService.SUBSCRIPTION_STATUS_MESSAGES[status];
  const { emoji } = SUBSCRIPTION_STATUS_EMOJIES[status];
  return (
    <Page center screenHeight>
      <Position align="top-left">
        <Space>
          <UserProfileDropdownContainer />
        </Space>
      </Position>
      <Row center columnDirection>
        <Col size={6} screenSMSize={12}>
          <CompanyLogoContainer />
          <Space vertical />
          <Row>
            <Text size={TEXT_SIZE.SIZE_1} primaryStyle color={COLOR_SHADES.DARKER}>
              {statusMessage.title}
            </Text>
            <Space />
            <Emoji name={emoji} size={EMOJIE_SIZE.LG} />
          </Row>
          <Space vertical size={2} />
          {reason && (
            <div>
              <Text color={COLOR_SHADES.BLUE_DARK} block>
                Due to {reason}
              </Text>
            </div>
          )}
          <Space vertical size={12} />

          <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} block>
            {statusMessage.subText(daysRemaining)}
          </Text>
        </Col>
        <Space vertical />
        {[
          SubscriptionService.SUBSCRIPTION_STATUS.EXPIRED,
          SubscriptionService.SUBSCRIPTION_STATUS.HALTED,
          SubscriptionService.SUBSCRIPTION_STATUS.CANCELLED,
        ].includes(status) ? (
          <Col size={6} screenSMSize={12}>
            <SubscriptionsPlansContainer />
          </Col>
        ) : (
          <Col size={6} screenSMSize={12}>
            <Button style={BUTTON_STYLES.PRIMARY} link={APP_URLS.HOME.ROOT}>
              Continue
            </Button>
          </Col>
        )}

        <Space vertical size={12} />
        <Col size={6} screenSMSize={12}>
          <HaveAQuestion displayPhoneNumbers={false} subject={`${company.name} | ${statusMessage.title}`} />
        </Col>
      </Row>
      {isStaffUser && (
        <Position align="top-right">
          <Space size={2}>
            <Card style={CARD_STYLES.SECONDARY}>
              <Space>
                Only for staff
                <Space vertical />
                <Button link={APP_URLS.SETTINGS.INDEX} style={BUTTON_STYLES.DANGER}>
                  Delete account 🤪
                </Button>
              </Space>
            </Card>
          </Space>
        </Position>
      )}
    </Page>
  );
}
