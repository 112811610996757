import { connect } from 'react-redux';
import { ActionConstants, ProposalSlideDataDuck } from '@premagic/proposals';
import { WindowPanelDuck } from '@premagic/common-ducks';

import ProposalCreatorDeckSetVariablesValues from './ProposalCreatorDeckSetVariablesValues';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
    slideId: number;
  },
) {
  const { deckId, slideId } = ownProps;
  return {
    variables: ProposalSlideDataDuck.proposalsVariablesInSlideSelectors(slideId)(state),
    unsetVariables: ProposalSlideDataDuck.unsetProposalVariablesInSlideSelector(deckId, slideId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showVariablesPanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorDeckSetVariablesValues);
