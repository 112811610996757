import React, { useEffect } from 'react';
import { Text, InnerPageHeader, Space, TabItem, Tabs, InnerPageContent, InnerPage } from '@premagic/myne';
import { EventTrackerService } from '@premagic/core';
import { BrowserUrlUtils, BrowserUtils } from '@premagic/utils';

import { getClassNameFromSelector, JS_SELECTORS } from '../../../../common/JSClassSelectors';
import { getAccountFinancialsUrlWithFilters } from './filters/AccountFinancialsFilterService';
import AccountFinancialsMetricsContainer from './metrics/AccountFinancialsMetricsContainer';
import AccountFinancialsFiltersContainer from './filters/AccountFinancialsFiltersContainer';
import { ACCOUNT_FINANCIALS_TYPES } from './AccountFinancialsService';
import AccountFinancialsPaymentsTableContainer from './table/AccountFinancialsPaymentsTableContainer';

type Props = {
  type: ACCOUNT_FINANCIALS_TYPES;
  filters: string;
  hasAccountFinancials: boolean;
};

export default function AccountFinancialsPage(props: Props): JSX.Element {
  const { type, filters, hasAccountFinancials } = props;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
  const financialsTabsSelector = getClassNameFromSelector(JS_SELECTORS.FINANCIALS_TABS);

  const tabs = [
    {
      title: 'Overdue',
      id: ACCOUNT_FINANCIALS_TYPES.PENDING,
      url: getAccountFinancialsUrlWithFilters(ACCOUNT_FINANCIALS_TYPES.PENDING, filterObject),
    },
    {
      title: 'Upcoming',
      id: ACCOUNT_FINANCIALS_TYPES.UPCOMING,
      url: getAccountFinancialsUrlWithFilters(ACCOUNT_FINANCIALS_TYPES.UPCOMING, filterObject),
    },
    {
      title: 'Expenses',
      id: ACCOUNT_FINANCIALS_TYPES.EXPENSE,
      url: getAccountFinancialsUrlWithFilters(ACCOUNT_FINANCIALS_TYPES.EXPENSE, filterObject),
    },
  ];

  useEffect(() => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.ACCOUNT_FINANCIALS.VISIT, {
      view: type,
    });
  }, [type]);

  return (
    <InnerPage>
      <InnerPageHeader iconName="briefcase" title="Financials">
        Financials
      </InnerPageHeader>
      <InnerPageContent>
        <AccountFinancialsFiltersContainer filters={filters} type={type} />
        <Space vertical />
        <AccountFinancialsMetricsContainer filters={filters} type={type} />
        <Space vertical />
        <div className="js-financials-tabs">
          <Tabs>
            {tabs.map((tab) => (
              <TabItem
                key={tab.url}
                to={tab.url}
                active={type === tab.id}
                onClick={() => {
                  BrowserUtils.scrollTo(financialsTabsSelector, true, 0);
                }}
              >
                {tab.title}
              </TabItem>
            ))}
          </Tabs>
        </div>
        <Space vertical size={2} />
        <AccountFinancialsPaymentsTableContainer filters={filters} type={type} />
        <Space vertical />
        {!hasAccountFinancials && (
          <>
            <Space vertical size={60} />
            <Text muted>NOTE: Company Financials is available only on higher plan</Text>
          </>
        )}
      </InnerPageContent>
    </InnerPage>
  );
}
