import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWebsiteShareURLSelector, clientWebsiteStatusSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteShareButton from './ClientWebsiteShareButton';
import { setFolderIdsToShare } from '../../folder-share/FolderShareDuck';
import { clientForProjectFolderSelector } from '../../projects/AccountProjectsDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;
  const clients = clientForProjectFolderSelector(projectId, [id])(state);

  return {
    id,
    projectId,
    status: clientWebsiteStatusSelector(id)(state),
    shareUrl: clientWebsiteShareURLSelector(id)(state),
    clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSharePanel: (folderId) => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.FOLDER_SHARE.SHARE, true));
      dispatch(setFolderIdsToShare(dispatch, [folderId]));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteShareButton);
