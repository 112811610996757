import { Text } from '@premagic/myne';
import { Poster, PosterFileType, PosterType } from '@premagic/poster-creator';

type Props = {
  poster?: PosterType;
  files: Record<string, PosterFileType>;
};

export default function AttendeeEventPoster(props: Props) {
  const { poster, files } = props;
  if (!poster) return <Text>No poster data</Text>;

  return <Poster data={poster} files={files} />;
}
