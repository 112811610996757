import { PresetDesignService } from '@premagic/core';
import { connect } from 'react-redux';
import PresetDesignSelector from './PresetDesignSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (value) => void;
    selectedPresetDesign: string;
    headingTitle?: string;
    isLoading?: boolean;
    isPresetDesignSelectorEnabled: boolean;
    variant?: PresetDesignService.PRESET_DESIGN_VARIANT;
    isInsideCard?: boolean;
  },
) {
  const {
    onChange,
    selectedPresetDesign,
    headingTitle,
    isLoading,
    isPresetDesignSelectorEnabled,
    variant,
    isInsideCard = true,
  } = ownProps;
  return {
    onChange,
    selectedPresetDesign,
    headingTitle,
    isLoading,
    isPresetDesignSelectorEnabled,
    variant,
    isInsideCard,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PresetDesignSelector);
