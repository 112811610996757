import { WindowPanelDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { eventAttendeeCheckinZonesIdsSortedByCreatedTimeSelector } from '../EventAttendeesCheckinZonesDataDuck';
import EventAttendeeFaceCheckinZonesList from './EventAttendeeFaceCheckinZonesList';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  return {
    projectId,
    zoneIds: eventAttendeeCheckinZonesIdsSortedByCreatedTimeSelector(state),
    isLoading: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.FETCH)(state),
    isAddingZone: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openAddZonePanel: () =>
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_ATTENDEE_CHECKIN_ZONE.CREATE, true),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceCheckinZonesList);
