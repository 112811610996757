// later we can add steps to select integration platform  list and api key input for that platform
export enum INTEGRATION_STEP {
  SELECT_EVENT,
  SELECT_CUSTOM_FIELD,
}

export enum INTEGRATION_CONNECT_OPTION {
  CREATE_EVENT_AND_CONNECT,
  CONNECT_AND_SYNC,
}
