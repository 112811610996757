import React from 'react';
import { ProductFeedbackService } from '@premagic/core';
import { connect } from 'react-redux';
import ReportIssueListLayout from './ReportIssueListLayout';

function mapStateToProps(
  state,
  ownProps: { projectId: string; reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE },
) {
  const { projectId, reportType } = ownProps;
  return {
    projectId,
    reportType,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssueListLayout);
