import React from 'react';
import { FormGroup, FormLabel, INPUT_SIZES, Select, Textarea } from '@premagic/myne';
import { EventAttendeesService, ProjectMetaService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';

const EVENT_POSTER_SCOPE_OPTIONS = Object.entries(EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS).map(
  ([key, value]) => ({
    value: key,
    label: value.name,
  }),
) as Array<{
  value: EventAttendeesService.EVENT_PEOPLE_TYPE;
  label: string;
}>;

type Props = {
  socialMessage?: ProjectMetaService.SocialShareMessageType;
  companyName?: string;
  projectName?: string;
};

export default function SocialMessageForm(props: Props): JSX.Element | null {
  const { socialMessage, companyName, projectName } = props;

  const defaultHashtags = [
    StringUtils.getHashtagFromString(companyName || 'Premagic'),
    StringUtils.getHashtagFromString(projectName || 'Project'),
  ];

  const hashtagOptions = defaultHashtags.map((defaultHashtag) => ({ value: defaultHashtag, label: defaultHashtag }));

  const selectedHashtags = socialMessage?.hashtags.map((item) => ({ value: item, label: item }));

  const defaultPeopleType = [EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE];
  const selectedPeopleType = EVENT_POSTER_SCOPE_OPTIONS.filter((option) =>
    (socialMessage?.people_type || defaultPeopleType).includes(option.value),
  );

  return (
    <div>
      <FormGroup>
        <FormLabel>Message</FormLabel>
        <Textarea name="message" autoFocus required>
          {socialMessage?.message}
        </Textarea>
      </FormGroup>
      <FormGroup>
        <FormLabel>Hashtags</FormLabel>
        <Select isMulti isCreatable isSearchable name="hashtags" options={hashtagOptions} value={selectedHashtags} />
      </FormGroup>
      <FormGroup>
        <FormLabel isRequired>Applicable for</FormLabel>
        <Select
          name="people_type"
          size={INPUT_SIZES.SM}
          options={EVENT_POSTER_SCOPE_OPTIONS}
          isMulti
          value={selectedPeopleType}
          menuPlacement="top"
        />
      </FormGroup>
    </div>
  );
}
