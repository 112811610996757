import React from 'react';
import { Col, FormGroup, FormLabel, Input, RadioButton, Row, Select, Space, Text } from '@premagic/myne';

import {
  USER_ROLE,
  USER_ROLE_DETAILS,
  USER_ROLE_FOR_PHOTOGRAPHER_DETAILS,
  USER_SERVICE_TYPE,
  USER_SERVICE_TYPE_DETAILS,
  UserType,
} from '../UsersService';
import InputPhoneNumberContainer from '../../../acccount/InputPhoneNumberContainer';

const serviceRoleOptions = Object.values(USER_SERVICE_TYPE_DETAILS).map((type) => ({
  value: type.id,
  label: type.title,
  icon: type.icon,
}));

type Props = {
  user?: UserType | null;
  isCompanyTypeNotPhotographer: boolean;
};

export default function UserForm(props: Props): JSX.Element {
  const { user, isCompanyTypeNotPhotographer } = props;
  const { service_role: serviceRole, role } = user || {};
  const defaultSelectedRole = role || USER_ROLE.EMPLOYEE;
  const defaultSelectedServiceRole = serviceRole
    ? serviceRoleOptions.find((option) => option.value === serviceRole)
    : serviceRoleOptions[0];
  const userRoleOptions = isCompanyTypeNotPhotographer
    ? Object.values(USER_ROLE_DETAILS)
    : Object.values(USER_ROLE_FOR_PHOTOGRAPHER_DETAILS);
  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input name="name" placeholder="eg. John Doe" autoFocus required defaultValue={user?.name} />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired>Phone Number</FormLabel>
            <InputPhoneNumberContainer name="phone_number" required defaultValue={user?.phone_number} />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel info="Allow user to login with email">Email</FormLabel>
            <Input name="email" placeholder="eg. john@premagic.com" type="email" defaultValue={user?.email} required />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <FormLabel isRequired>Role</FormLabel>
        {userRoleOptions.map((option) => (
          <RadioButton name="role" key={option.id} value={option.id} defaultChecked={option.id === defaultSelectedRole}>
            {option.title}
            <Text muted block>
              {option.info}
            </Text>
          </RadioButton>
        ))}
      </FormGroup>
      {isCompanyTypeNotPhotographer ? (
        <input type="hidden" name="service_role" value={USER_SERVICE_TYPE.DEFAULT} />
      ) : (
        <FormGroup>
          <FormLabel isRequired>Skills</FormLabel>
          <Col>
            <Select
              options={serviceRoleOptions}
              value={defaultSelectedServiceRole}
              name="service_role"
              isMulti={false}
            />
          </Col>
        </FormGroup>
      )}
    </div>
  );
}
