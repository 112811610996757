import { connect } from 'react-redux';
import { EventPosterService } from '@premagic/core';
import EventPosterForm from './EventPosterForm';
import { eventPostersSelector } from '../EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    posterId?: string;
    type: EventPosterService.EVENT_POSTER_TYPE;
  },
) {
  const { projectId, posterId, type } = ownProps;

  return {
    projectId,
    posterId,
    type,
    poster: posterId ? eventPostersSelector(state)[posterId] : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterForm);
