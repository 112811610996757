import React from 'react';

import {
  Card,
  COLOR_SHADES,
  MiniCardCounts,
  PreventMarginCollapse,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
} from '@premagic/myne';
import { EventSponsorsService } from '@premagic/core';
import ReportStatsItem from './ReportStatsItem';

type Props = {
  sponsor: EventSponsorsService.EventSponsorType;
  stats: {
    views: number;
    clicks: number;
    ctaClicks: number;
    roi: number;
  };
  sponsorsCategoryData?: Record<string, EventSponsorsService.EventSponsorCategoryType>;
  isLoading: boolean;
};

export default function SponsorReportCard(props: Props): JSX.Element {
  const { sponsor, stats, sponsorsCategoryData, isLoading } = props;
  const { name, category: sponsorCategoryId } = sponsor;
  const { views, clicks, roi, ctaClicks } = stats || {};

  const tierName = sponsorsCategoryData?.[sponsorCategoryId]?.name;

  return (
    <div>
      <Card>
        <Space>
          <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block size={TEXT_SIZE.SIZE_3}>
            {name}
          </Text>
          <PreventMarginCollapse>
            {tierName ? (
              <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
                {tierName}
              </Text>
            ) : (
              <Space vertical />
            )}
          </PreventMarginCollapse>
          <Space vertical size={8} />
          <MiniCardCounts>
            <ReportStatsItem title="Impression" count={views} type={TEXT_GROUP_TYPES.NUMBER} isLoading={isLoading} />
            <ReportStatsItem title="Clicks" count={clicks} type={TEXT_GROUP_TYPES.NUMBER} isLoading={isLoading} />
            <ReportStatsItem
              title="CTA Clicks"
              count={ctaClicks}
              type={TEXT_GROUP_TYPES.NUMBER}
              isLoading={isLoading}
            />
            <ReportStatsItem title="ROI" count={roi} type={TEXT_GROUP_TYPES.CURRENCY} isLoading={isLoading} />
          </MiniCardCounts>
        </Space>
      </Card>
    </div>
  );
}
