import { EventPosterService } from '@premagic/core';
import { FormGroup, FormLabel, Input } from '@premagic/myne';
import { PosterWidgetTextType } from '@premagic/poster-creator';
import React from 'react';

type Props = {
  poster: EventPosterService.EventPosterType;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetTextType;
  }) => void;
  projectId: string;
  widget: PosterWidgetTextType;
};

export default function EventPosterTextValueSelector(props: Props) {
  const { poster, editWidget, projectId, widget } = props;
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    const updatedWidget = Object.assign({}, widget, {
      [name]: value,
    });
    editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
  }

  return (
    <FormGroup>
      <FormLabel>Text</FormLabel>
      <Input name="value" defaultValue={widget.value} onChange={onChange} />
    </FormGroup>
  );
}
