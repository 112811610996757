import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector, isPoweredByPremagicShownSelector } from '../company/CompanyDataDuck';
import { saveClientSettingsData, isWatermarkBetaEnabledSelector } from '../preferences/ClientSettingsDataDuck';
import CompanyBrandingPage from './CompanyBrandingPage';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  const isWatermarkBetaEnabled = isWatermarkBetaEnabledSelector(state);
  const features = {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ADD_LOGO_TO_EMAILS]: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ADD_LOGO_TO_EMAILS,
    )(state),
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.REMOVE_PREMAGIC_BRANDING]: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.REMOVE_PREMAGIC_BRANDING,
    )(state),
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_GET_LEADS]: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_GET_LEADS,
    )(state),
  };

  return {
    currentUrlPathname: pathname,
    brandingSettings: {
      show_company_logo_in_email: features[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ADD_LOGO_TO_EMAILS],
      show_powered_by_premagic: features[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.REMOVE_PREMAGIC_BRANDING],
      show_enquire_form_in_highlight: features[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_GET_LEADS],
    },
    isLoading: false,
    isPoweredByPremagicShown: isPoweredByPremagicShownSelector(state),
    showBranding:
      isWatermarkBetaEnabled || hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBrandingPage);
