import { FileService, PeoplePosterService } from '@premagic/core';
import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  CARD_SIZES,
  CARD_STYLES,
  COLOR_SHADES,
  Card,
  Col,
  Color,
  ErrorBoundary,
  ICON_SIZES,
  Icon,
  Row,
  SimpleImage,
  Space,
  SubTitle,
} from '@premagic/myne';
import React from 'react';
import { EventAttendeeBadgeDesignType } from '../../../service/EventAttendeeBadgeDesignService';

type Props = {
  projectId: string;
  folderId: string;
  showSelectPosterModal: () => void;
  selectedBackgroundPoster?: FileService.FolderFileType;
  badgeDesign?: EventAttendeeBadgeDesignType;
  onRemove: (projectId: string, folderId: string, badgeDesign?: EventAttendeeBadgeDesignType) => void;
};

export default function EventAttendeeBadgePosterSelect(props: Props) {
  const { showSelectPosterModal, selectedBackgroundPoster, projectId, folderId, badgeDesign, onRemove } = props;
  const posterImageSrc = selectedBackgroundPoster?.dynamic_image_url;

  function handleRemove() {
    onRemove(projectId, folderId, badgeDesign);
  }

  return (
    <ErrorBoundary>
      <SubTitle>Background image</SubTitle>
      <Space vertical />
      <Space vertical size={2} />
      <Card style={CARD_STYLES.SECONDARY}>
        <Space>
          <Row center vcenter>
            <Card style={CARD_STYLES.PRIMARY} size={CARD_SIZES.DEFAULT} center>
              <Space size={4}>
                <div style={{ width: '60px', height: '62px' }}>
                  {posterImageSrc ? (
                    <SimpleImage
                      src={posterImageSrc}
                      alt="event-attendee-badge"
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  ) : (
                    <Button size={BUTTON_SIZE.SM} onClick={showSelectPosterModal} style={BUTTON_STYLES.RESET}>
                      <Space>
                        <Color inline shade={COLOR_SHADES.LIGHT}>
                          <Icon name="image" size={ICON_SIZES.MD} />
                        </Color>
                      </Space>
                    </Button>
                  )}
                </div>
              </Space>
            </Card>
          </Row>
          <Space vertical size={2} />
          <Row center>
            <Col size={3}>
              <Row vcenter center={!posterImageSrc} spaceBetween={!!posterImageSrc}>
                <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={showSelectPosterModal}>
                  {!posterImageSrc ? `Select Poster` : 'Edit'}
                </Button>
                {posterImageSrc && (
                  <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleRemove}>
                    Remove
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </Space>
      </Card>
    </ErrorBoundary>
  );
}
