import { connect } from 'react-redux';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { addNewUser } from '../UsersDataDuck';
import NewUserPanel from './NewUserPanel';

const PANEL_KEY = LOADINGS.USERS.SHOW_ADD_PANEL;
const COMMON_KEY = LOADINGS.USERS.ADD;

function mapStateToProps(state) {
  return {
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createNewUser: (data) => {
      dispatch(addNewUser(dispatch, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewUserPanel);
