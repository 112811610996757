import styled from 'styled-components';

export const Button = styled.button`
  background-color: #fff;
  color: #4d6178;
  padding: 4px;
  border: 1px solid #4d6178;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
`;

export const ButtonIcon = styled.button`
  padding: 4px;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d6178;
  background-color: #fff;

  &:hover,
  &:active {
    background-color: #e6e6e6;
  }
`;
