import numeral from 'numeral';

export function getCurrencyInFormat(number: number): string {
  return numeral(number).format('0,0');
}

export function getNumberFromFormat(number: number | string): number {
  return numeral(number).value();
}

export function getNumberInFormat(number: number): string {
  return numeral(number).format('0,0');
}

export function getNumberInAbrFormat(number: number): string {
  return numeral(number).format('0.0a');
}

export function getNumberInPercentageFormat(number: number): string {
  return numeral(number).format('0.0%');
}

export function getNumberInBytesFormat(number: number): string {
  return numeral(number || 0).format('0b');
}

export function getNumberInDecimal(number: number): number {
  return numeral(number).format('0.00');
}

export function getmmFromPx(px: number): number {
  return px / 3.78;
}
