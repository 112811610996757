import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  Col,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import React from 'react';
import MESSAGES from '../../../../common/Messages';
import { PERMISSIONS } from '../crm/users/UserPermissionService';
import MemoriesTemplatesListContainer from './MemoriesTemplatesListContainer';

type Props = {
  projectId?: string;
  eventId: string;
  focusId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT]: boolean;
  };
  isFeatureAvatarStudioEnabled: boolean;
  showAddNewTemplate: () => void;
  avatarStudioLink?: string;
  avatarStudioPrintLink?: string;
};

export default function AvatarStudioLayout(props: Props): React.ReactElement {
  const {
    projectId,
    hasPermissions,
    eventId,
    isFeatureAvatarStudioEnabled,
    showAddNewTemplate,
    focusId,
    avatarStudioLink,
    avatarStudioPrintLink,
  } = props;

  if (!hasPermissions[PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT]) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.PERMISSION[PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT]}</Text>
      </InnerPageContentMainCol>
    );
  }

  if (!isFeatureAvatarStudioEnabled) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.AVATAR_STUDIO.UPGRADE}</Text>
      </InnerPageContentMainCol>
    );
  }

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;

  return (
    <InnerPageContentMainCol hasSidebar={false}>
      <Row>
        <Col rightAlighed size={null}>
          <Button style={BUTTON_STYLES.PRIMARY} onClick={showAddNewTemplate} size={BUTTON_SIZE.SM}>
            <Icon name="plus" size={ICON_SIZES.SM} /> Template
          </Button>
        </Col>
      </Row>
      <Space vertical />
      <MemoriesTemplatesListContainer eventId={eventId} projectId={projectId} focusId={focusId} />
      <Space vertical size={8} />
      <Row center>
        <Card size={CARD_SIZES.BOX_MD}>
          <Space>
            <Row vcenter>
              <Icon name="info" size={ICON_SIZES.SM} />
              <Space size={1} />
              <Text boldness={TEXT_BOLDNESS.BOLD} block>
                Implementation Guide
              </Text>
            </Row>
            <Space vertical />
            <ul>
              <li>
                <Text>After registering on Premagic, users will be prompted to create their avatar.</Text>
              </li>
              <Space vertical size={8} />
              <li>
                <Text>To boost engagement, you can set up a kiosk at the event. Here’s the </Text>
                <Space size={2} />
                <Button
                  style={BUTTON_STYLES.LINK}
                  size={BUTTON_SIZE.SM}
                  disabled={!avatarStudioLink}
                  link={avatarStudioLink}
                  isExternalLink
                  disabledMessage="Create a gallery to get the link"
                >
                  <Icon name="external_link" size={ICON_SIZES.SM} />
                  <Space size={1} />
                  Kiosk Link
                </Button>
              </li>
              <Space vertical size={8} />
              <li>
                <Text block>Print photos live at the event. Here’s the </Text>
                <Button
                  style={BUTTON_STYLES.LINK}
                  size={BUTTON_SIZE.SM}
                  disabled={!avatarStudioPrintLink}
                  link={avatarStudioPrintLink}
                  isExternalLink
                  disabledMessage="Create a gallery to get the link"
                >
                  <Icon name="external_link" size={ICON_SIZES.SM} />
                  <Space size={1} />
                  Avatar Studio Print View
                </Button>
              </li>
            </ul>
          </Space>
        </Card>
      </Row>
    </InnerPageContentMainCol>
  );
}
