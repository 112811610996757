import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { ErrorTracker, ActionTypeUtils, BrowserUrlUtils } from '@premagic/utils';
import { ClientsService, EventsService } from '@premagic/core';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { CRMClientSchema, CRMClientsSchema } from '../../../../schema/Schemas';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import APP_URLS from '../../../services/AppRouteURLService';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { navigateTo } from '../../../../../services/RouterService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_CLIENTS');

const setCRMClientsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addCRMClientsData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateCRMClientsData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
const setSearchCRMClientsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const LOADING_KEYS = LOADINGS.CRM_CLIENTS;

export const fetchCRMClientsData = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, clientIds: Array<string>) => {
    const loadingKey = LOADING_KEYS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    return ClientsService.fetchCustomerClients(clientIds)
      .then((response) => {
        const normalizedData = normalize(response.results, CRMClientsSchema);
        dispatch(setCRMClientsData(dispatch, normalizedData.entities.clients));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('CRMClients: fetch failed', e);
      });
  },
);

export const fetchCRMClientData = createAction(getActionType('DATA', 'FETCH_SINGLE'), (dispatch, clientId: string) => {
  const loadingKey = LOADING_KEYS.FETCH_SINGLE;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  return ClientsService.fetchCustomerClient(clientId)
    .then((response) => {
      const normalizedData = normalize(response, CRMClientSchema);
      dispatch(addCRMClientsData(dispatch, normalizedData.entities.clients));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('CRMClient: fetch failed', e);
    });
});

// export const addCRMClientsForClient = createAction(
//   getActionType('DATA', 'CREATE'),
//   (dispatch, formResponse: FormResponseType) => {
//     const loadingKey = LOADING_KEYS.ADD;
//     dispatch(toggleLoadingState(dispatch, loadingKey, true));
//     dispatch(clearErrorState(dispatch, loadingKey));
//
//     createClient(formResponse.data)
//       .then((response) => {
//         const normalizedData = normalize(response, CRMClientSchema);
//         dispatch(addCRMClientsData(dispatch, normalizedData.entities.clients));
//         const createEventPageForClientUrl = getRouteUrlFor(APP_URLS.CRM.CREATE__EVENT, {
//           clientId: response.id,
//         });
//         navigateTo(dispatch, createEventPageForClientUrl);
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         return response;
//       })
//       .catch((e) => {
//         dispatch(setErrorState(dispatch, loadingKey, e));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         ErrorTracker.logError('CRMClients: update failed', e);
//
//       });
//   },
// );

export const updateCRMClients = createAction(
  getActionType('DATA', 'EDIT'),
  (dispatch, clientId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADING_KEYS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ClientsService.editClient(clientId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, CRMClientSchema);
        dispatch(updateCRMClientsData(dispatch, normalizedData.entities.clients));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADING_KEYS.SHOW_EDIT_PANEL, false));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('CRMClient: update failed', e);
      });
  },
);

export const searchCRMClients = createAction(getActionType('DATA', 'SEARCH'), (dispatch, searchString: string) => {
  const loadingKey = LOADING_KEYS.SEARCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  return ClientsService.searchClient(searchString)
    .then((response) => {
      const normalizedData = normalize(response, CRMClientsSchema);
      dispatch(setSearchCRMClientsData(dispatch, normalizedData.entities.clients));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('CRMClients: search failed', e);
    });
});

export const routeUserToFirstEvent = createAction(getActionType('USER', 'ROUTE'), (dispatch, clientId: string) => {
  const loadingKey = LOADING_KEYS.SEARCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  return EventsService.fetchEventsForClient(clientId)
    .then((response) => {
      if (response.results.length === 0) {
        dispatch(setErrorState(dispatch, loadingKey, { code: 'no-events' }));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      } else {
        const { id: eventId } = response.results[0];
        const eventUrlForDetails =
          eventId &&
          BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
            eventId,
          });
        navigateTo(dispatch, eventUrlForDetails);
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, MODALS.CLIENT.SEARCH, false));
      }
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('CRMClients: search failed', e);
    });
});

type CRMClientsStateType = {
  items: IMap<string, ClientsService.ClientType>;
};
const initialState = {
  items: IMap([]),
};

export default handleActions(
  {
    [setCRMClientsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addCRMClientsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateCRMClientsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [setSearchCRMClientsData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const crmClientsDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.crm.clients as CRMClientsStateType,
);

export const clientEntitiesSelector = createSelector(crmClientsDataSelector, (crmCRMClients) => crmCRMClients.items);

export const clientItemsSelector = createSelector(clientEntitiesSelector, (crmCRMClients) => crmCRMClients.toJSON());

export const clientKeysSelector = createSelector(clientEntitiesSelector, (crmCRMClients) =>
  crmCRMClients.keySeq().toArray(),
);

export const clientItemsArraySelector = createSelector(clientEntitiesSelector, (crmCRMClients) =>
  crmCRMClients.valueSeq().toArray(),
);
