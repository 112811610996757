import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';

import { pagesSelector } from '../../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_ATTENDEES', true);

export const setEditOfAttendee = createAction(
  getActionType('ITEM', 'EDIT'),
  (dispatch: Dispatch, attendeeId: string | null) => attendeeId,
);
export const setEditStatusOfAttendee = createAction(
  getActionType('ITEM', 'EDIT_STATUS'),
  (dispatch: Dispatch, attendeeId: string | null) => attendeeId,
);
export const setSearchTermForAttendee = createAction(
  getActionType('SEARCH', 'SET'),
  (dispatch: Dispatch, searchTerm: string | null) => searchTerm,
);

export const updatePeopleListStyle = createAction(
  getActionType('LIST', 'DETAILED'),
  (dispatch: Dispatch, show: 'list' | 'detailed') => show,
);

type EventAttendeesPageType = {
  editAttendeesId: string | null;
  editStatusAttendeesId: string | null;
  searchTerm?: string;
  listStyle: 'list' | 'detailed';
};
const initialState = {
  editAttendeesId: undefined,
  editStatusAttendeesId: undefined,
  searchTerm: undefined,
  listStyle: 'list',
};

export default handleActions(
  {
    [setEditOfAttendee.toString()]: (state, action: { payload }) => ({
      ...state,
      editAttendeesId: action.payload,
    }),
    [setEditStatusOfAttendee.toString()]: (state, action: { payload }) => ({
      ...state,
      editStatusAttendeesId: action.payload,
    }),
    [setSearchTermForAttendee.toString()]: (state, action: { payload }) => ({
      ...state,
      searchTerm: action.payload,
    }),
    [updatePeopleListStyle.toString()]: (state, action: { payload }) => ({
      ...state,
      listStyle: action.payload,
    }),
  },
  initialState,
);

export const eventAttendeesPageSelector = createSelector(
  pagesSelector,
  (state) => state.eventAttendees.eventAttendees as EventAttendeesPageType,
);
export const attendeeIdForEditSelector = createSelector(eventAttendeesPageSelector, (page) => page.editAttendeesId);
export const attendeeIdForEditStatusSelector = createSelector(
  eventAttendeesPageSelector,
  (page) => page.editStatusAttendeesId,
);
export const attendeeSearchTermSelector = createSelector(eventAttendeesPageSelector, (page) => page.searchTerm);
export const attendeeListStyleSelector = createSelector(eventAttendeesPageSelector, (page) => page.listStyle);
