import React, { RefObject } from 'react';
// import { usePopper } from 'react-popper';
import { Overlay as OverlayLib } from 'react-overlays';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { COLOR_SHADES } from '../Color/Color';
import { Space } from '../Grid/Grid';

type OverlayProps = {
  children: React.ReactNode;
  placement?: 'left' | 'right' | 'top' | 'bottom' | 'auto';
  target: any;
  show: boolean;
  onClose: () => void;
  container?: RefObject<HTMLElement>;
};

export function Overlay(popoverProps: OverlayProps): React.ReactElement {
  const { show, placement, target, children, onClose, container } = popoverProps;

  function getContainer(): HTMLElement {
    if (container && container.current) {
      return container.current;
    }

    return document.querySelector('body') as HTMLElement;
  }

  return (
    <OverlayLib
      show={show}
      placement={placement}
      target={target}
      rootClose
      onHide={onClose}
      flip
      container={getContainer}
    >
      {({ props }) => (
        <div
          {...props}
          style={{
            // eslint-disable-next-line react/prop-types
            ...props.style,
            zIndex: 999,
          }}
        >
          {children}
        </div>
      )}
    </OverlayLib>
  );
}

const PopoverStyle = {
  padding: '0 4px',
  minWidth: '300px',
};

const PopoverInnerStyle = {
  padding: '16px',
  borderRadius: 4,
  backgroundColor: '#fff',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
};

const TooltipArrowStyle = {
  position: 'absolute' as const,
  width: 0,
  height: 0,
  borderRightColor: 'transparent',
  borderLeftColor: 'transparent',
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
  borderStyle: 'solid',
};

const PlacementStyles = {
  left: {
    tooltip: { marginLeft: -3, padding: '0 5px' },
    arrow: {
      left: 'auto',
      top: 'auto',
      right: 0,
      marginTop: -5,
      borderWidth: '5px 0 5px 5px',
      borderLeftColor: '#fff',
    },
  },
  right: {
    tooltip: { marginRight: 3, padding: '0 5px' },
    arrow: {
      top: 'auto',
      left: 0,
      marginTop: -5,
      borderWidth: '5px 5px 5px 0',
      borderRightColor: '#fff',
    },
  },
  top: {
    tooltip: { marginTop: -3, padding: '5px 0' },
    arrow: {
      top: 'auto',
      left: 'auto',
      bottom: 0,
      marginLeft: -5,
      borderWidth: '5px 5px 0',
      borderTopColor: '#fff',
    },
  },
  bottom: {
    tooltip: { marginBottom: 3, padding: '5px 0' },
    arrow: {
      left: 'auto',
      top: 0,
      marginLeft: -5,
      borderWidth: '0 5px 5px',
      borderBottomColor: '#fff',
    },
  },
};

type PopoverBoxProps = {
  children: React.ReactNode;
  placement?: 'left' | 'right' | 'top' | 'bottom';
};

function PopoverBox(props: PopoverBoxProps) {
  const { children, placement = 'bottom' } = props;
  const placementStyle = PlacementStyles[placement];
  const { arrow, tooltip } = placementStyle;

  const { left, top } = arrow;

  return (
    <div style={{ ...PopoverStyle, ...tooltip }}>
      <div style={{ ...TooltipArrowStyle, ...arrow, left, top }} />
      <div style={PopoverInnerStyle}>{children}</div>
    </div>
  );
}

type Props = {
  children: React.ReactNode;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  target: any;
  show: boolean;
  onClose: () => void;
};

export function Popover(popoverProps: Props): React.ReactElement {
  const { show, placement = 'top', target, children, onClose } = popoverProps;
  return (
    <OverlayLib show={show} placement={placement} target={target} rootClose onHide={onClose}>
      {({ props }) => (
        <div
          {...props}
          style={{
            // eslint-disable-next-line
            ...props.style,
          }}
        >
          <PopoverBox>{children}</PopoverBox>
        </div>
      )}
    </OverlayLib>
  );
}

//
// export function NewPopover(props: Props) {
//     const { show, placement, target, children } = props;
//
//     const [referenceElement, setReferenceElement] = useState(null);
//     const [popperElement, setPopperElement] = useState(null);
//     const [arrowElement, setArrowElement] = useState(null);
//     const { styles, attributes } = usePopper(referenceElement, popperElement, {
//         placement: 'bottom',
//         modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
//     });
//
//     return (
//         <>
//             <button type="button" ref={setReferenceElement}>
//                 Reference element
//             </button>
//
//             <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
//                 <PopoverBox>Popper element</PopoverBox>
//                 <div ref={setArrowElement} style={styles.arrow} />
//             </div>
//         </>
//     );
// }

export function PopoverTitle(props: { children: React.ReactNode }): React.ReactElement {
  const { children } = props;
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARKER}>
        {children}
      </Text>
      <Space vertical size={2} />
    </div>
  );
}
