import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  Form,
  FormFooter,
  FormResponseType,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  Space,
} from '@premagic/myne';
import MESSAGES from '../../../../../common/Messages';
import ClientWebsiteBasicInfoFormContainer from '../edit/ClientWebsiteBasicInfoFormContainer';
import ClientWebsiteMainOccasionFormContainer from '../occasions/ClientWebsiteMainOccasionFormContainer';

type Props = {
  addClientWebsite: (projectId: string, data: FormResponseType) => void;
  isLoading: boolean;
  projectId: string;
  errors?: Record<string, string>;
};

export default function CreateClientWebsitePage(props: Props) {
  const { projectId, addClientWebsite, isLoading, errors } = props;
  const backToUrl = `/projects/${projectId}`;
  const title = `Add ${MESSAGES.CLIENT_WEBSITE.NAME}`;
  return (
    <InnerPage>
      <InnerPageHeader iconName="gift" title={title} backTo={backToUrl}>
        {title}
      </InnerPageHeader>
      <InnerPageContent>
        <Col size={8}>
          <Form onSubmit={(formResponse) => addClientWebsite(projectId, formResponse)} errors={errors}>
            <Card>
              <Space>
                <ClientWebsiteBasicInfoFormContainer projectId={projectId} />
              </Space>
            </Card>
            <Card>
              <Space>
                <ClientWebsiteMainOccasionFormContainer />
              </Space>
            </Card>

            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL} link={backToUrl}>
                Cancel
              </Button>
              <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
                Add
              </Button>
            </FormFooter>
          </Form>
        </Col>
      </InnerPageContent>
    </InnerPage>
  );
}
