import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';

import EventAttendeesLayout from './EventAttendeesLayout';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { eventProjectSelectorSelector } from '../../../projects/AccountProjectsDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    projectId,
    hasPermissions: {
      [PERMISSIONS.EVENT_ATTENDEES]: hasPermission(PERMISSIONS.EVENT_ATTENDEES)(state),
      [PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT)(state),
    },
    hasEventAttendeesFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeesLayout);
