import { connect } from 'react-redux';
import {
  eventCustomFormFieldsItemsSelectors,
  saveCustomEventFromField,
} from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';
import ShowOrHideOnRegistrationMenuItem from './ShowOrHideOnRegistrationMenuItem';

function mapStateToProps(state, ownProps: { disabled: boolean; projectId: string; formFieldId: string }) {
  const { projectId, formFieldId, disabled } = ownProps;
  const allFormFieldData = eventCustomFormFieldsItemsSelectors(state);

  return {
    disabled,
    projectId,
    formFieldData: allFormFieldData[formFieldId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCustomEventFromField: (projectId, formFieldId, formResponse) => {
      dispatch(
        saveCustomEventFromField(
          dispatch,
          {
            projectId,
            formFieldId,
          },
          formResponse,
        ),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowOrHideOnRegistrationMenuItem);
