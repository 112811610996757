import React, { useEffect } from 'react';

import {
  Alert,
  ALERT_STYLES,
  Button,
  BUTTON_STYLES,
  Col,
  Form,
  FormFooter,
  FormResponseType,
  LoadingBox,
  InnerPageHeader,
  Space,
  InnerPage,
  InnerPageContent,
} from '@premagic/myne';
import { PremagicResources, ProjectOrdersService } from '@premagic/core';

import APP_URLS, { getRouteUrlFor } from '../../../services/AppRouteURLService';
import ClientWebsiteThemeInfoFormContainer from './ClientWebsiteThemeInfoFormContainer';
import MESSAGES from '../../../../../common/Messages';
import { CLIENT_WEBSITE_FORM_ITEMS, CLIENT_WEBSITE_FORM_ITEMS_DETAILS } from '../form-items/ClientWebsiteFormService';

type Props = {
  initData: (id: string, projectId: string) => void;
  isLoading: boolean;
  isSaving: boolean;
  saveClientWebsite: (id: string, projectId: string, data: FormResponseType, navigateToUrl: string) => void;
  id: string;
  projectId: string;
  errors?: Record<string, string>;
  order?: ProjectOrdersService.ProjectOrdersType;
  hasPremiumDigitalInviteFeature?: boolean;
};

export default function ClientWebsiteThemeEditPage(props: Props): JSX.Element {
  const {
    initData,
    id,
    projectId,
    isLoading,
    saveClientWebsite,
    errors,
    isSaving,
    order,
    hasPremiumDigitalInviteFeature,
  } = props;
  useEffect(() => {
    initData(id, projectId);
  }, []);

  const backToUrl = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, {
    id,
    projectId,
  });
  const title = MESSAGES.CLIENT_WEBSITE.NAME;
  const isTrialPackage = order?.variant === ProjectOrdersService.DIGITAL_INVITE_ORDER_VARIENTS.TRIAL;
  const clientWebsiteDetailsPageUrl = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, {
    id,
    projectId,
  });
  return (
    <InnerPage>
      <InnerPageHeader iconName="gift" title={title} backTo={backToUrl}>
        {title}
      </InnerPageHeader>
      <InnerPageContent>
        <LoadingBox isLoading={isLoading}>
          <Col size={8}>
            {!hasPremiumDigitalInviteFeature && isTrialPackage && (
              <>
                <Alert style={ALERT_STYLES.INFO}>
                  {MESSAGES.CLIENT_WEBSITE.UPGRADE_TO_CUSTOMIZE_BELOW}{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${PremagicResources.RESOURCES.supportEmail}?subject=Upgrade Micro-site`}
                  >
                    {PremagicResources.RESOURCES.supportEmail}
                  </a>
                  <Space vertical />
                  {MESSAGES.CLIENT_WEBSITE.LEARN_ABOUT_PLANS}{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={PremagicResources.DIGITAL_INVITE.marketingPage('account-app')}
                  >
                    here.
                  </a>
                </Alert>
                <Space vertical />
              </>
            )}
            <Form
              onSubmit={(formResponse) => saveClientWebsite(id, projectId, formResponse, clientWebsiteDetailsPageUrl)}
              errors={errors}
            >
              <ClientWebsiteThemeInfoFormContainer id={id} projectId={projectId} isTrialPackage={isTrialPackage} />

              <FormFooter>
                <Button style={BUTTON_STYLES.CANCEL} link={backToUrl}>
                  Cancel
                </Button>
                <Button
                  style={BUTTON_STYLES.PRIMARY}
                  isLoading={isSaving}
                  disabledMessage={MESSAGES.CLIENT_WEBSITE.UPGRADE_TO_CUSTOMIZE}
                >
                  Save
                </Button>
              </FormFooter>
            </Form>
          </Col>
        </LoadingBox>
      </InnerPageContent>
    </InnerPage>
  );
}
