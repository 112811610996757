import { HightlightAlbumThemeService } from '@premagic/core';
import { connect } from 'react-redux';
import HighlightDesignBackgroundColorSelector from './HighlightDesignBackgroundColorSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (color: string) => void;
    disabled: boolean;
    aiPickedDominantColors?: Array<Array<number>>;
    defaultBackgroundColor?: string;
  },
) {
  const { onChange, disabled, aiPickedDominantColors, defaultBackgroundColor } = ownProps;
  return {
    onChange,
    disabled,
    aiPickedDominantColors,
    defaultBackgroundColor,
  };
}

function mapDispatchToProps() {
  return {
    initBackgroundColor: (backgroundColor: string) => {
      HightlightAlbumThemeService.updateThemeVariable({
        color: {
          background: backgroundColor,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignBackgroundColorSelector);
