import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Form,
  FormErrorType,
  FormResponseType,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Row,
  Space,
} from '@premagic/myne';
import AddClientPaymentsFromPresetContainer from './AddClientPaymentsFromPresetContainer';

type Props = {
  eventId: string;
  addPaymentInBulk: (eventId: string, data: FormResponseType) => void;
  isLoading: boolean;
  error?: FormErrorType;
  toggleModal: (show: boolean) => void;
  isShown: boolean;
  showAddSingleClientPayment: () => void;
};

export default function ClientPaymentPresetModal(props: Props): JSX.Element | null {
  const { eventId, addPaymentInBulk, isLoading, error, toggleModal, isShown, showAddSingleClientPayment } = props;

  function handleOnSave(formResponse) {
    addPaymentInBulk(eventId, formResponse);
  }

  if (!isShown) return null;
  return (
    <Modal size={MODAL_SIZES.MD} show={isShown} close={() => toggleModal(false)}>
      <ModalTitle>Add client payments</ModalTitle>
      <Form onSubmit={handleOnSave} errors={error}>
        <AddClientPaymentsFromPresetContainer />
        <Space vertical size={8} />
        <Row vcenter>
          <Col size={null} screenSMSize={4}>
            <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={showAddSingleClientPayment}>
              Instead, add single payment?
            </Button>
          </Col>
          <Space />
          <Col size={null} rightAlighed>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleModal(false)}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
