import { connect } from 'react-redux';
import { EventPosterService, RouterService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { eventPostersSelector } from '../crm/event-posters/EventPostersDataDuck';
import { clientWebsiteForProjectSelector } from '../projects/AccountProjectsDataDuck';
import EventPosterCreatorHeader from './EventPosterCreatorHeader';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    posterId?: string;
    type: EventPosterService.EVENT_POSTER_TYPE;
  },
) {
  const { eventId, projectId, posterId, type } = ownProps;

  return {
    eventId,
    projectId,
    posterId,
    type,
    poster: posterId ? eventPostersSelector(state)[posterId] : undefined,
    isLoading:
      LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.SINGLE_FETCH)(state) ||
      LoadingDuck.isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.UPDATE_WIDGET)(state),
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterCreatorHeader);
