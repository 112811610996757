import { HelpArticlesService, PricingPlanFeaturesService } from '@premagic/core';
import { Label, LABEL_STYLES } from '@premagic/myne';
import { HelpWidgetUtils } from '@premagic/utils';

type FeatureInfoProps = {
  feature: PricingPlanFeaturesService.PRICING_PLAN_FEATURES;
};

export default function FeatureInfo(props: FeatureInfoProps): JSX.Element {
  const { feature } = props;
  const details = PricingPlanFeaturesService.getFeatureDetails(feature);

  if (!details.helpArticle) return <div>There no helpArticle for this feature</div>;
  const articleId = HelpArticlesService.getArticleId(details.helpArticle);

  return (
    <Label style={LABEL_STYLES.PILL_INFO} onClick={() => HelpWidgetUtils.showArticle(articleId)}>
      Learn more
    </Label>
  );
}
