import { createAction } from 'redux-actions';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { EventTrackerService } from '@premagic/core';

import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';

import { CapturePaymentDataType, fetchCompanyInfo, savePaymentInfo } from '../company/CompanyService';
import { setPurchaseOrder } from '../pricing-plan/PricingPlansDataDuck';
import { getCurrentSubscription } from './SubscriptionsDataDuck';
import { setCompanyData } from '../company/CompanyDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('SUBSCRIPTION_PAGE', true);

export const savePaymentInformation = createAction(
  getActionType('PAYMENT', 'DONE'),
  (dispatch, options: CapturePaymentDataType) => {
    const { amount, paymentId, subscriptionId, razorpaySignature, clientSettings, requestingUser } = options;
    dispatch(toggleLoadingState(dispatch, LOADINGS.SUBSCRIPTION.PAYMENT, true));
    dispatch(clearErrorState(dispatch, LOADINGS.SUBSCRIPTION.PAYMENT));
    dispatch(toggleModalVisibility(dispatch, MODALS.SUBSCRIPTION.PAYMENT, true));

    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PAYMENT.DONE, {
      amount,
      paymentId,
    });

    savePaymentInfo({
      razorpaySignature,
      paymentId,
      subscriptionId,
      clientSettings,
      requestingUser,
    })
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, LOADINGS.SUBSCRIPTION.PAYMENT, false));
        fetchCompanyInfo().then((company) => {
          dispatch(setCompanyData(dispatch, company));
          dispatch(getCurrentSubscription(dispatch, { company, clientSettings, userId: requestingUser.id }));
          // Fetch subscription again after 30sec
          setTimeout(() => {
            dispatch(
              getCurrentSubscription(dispatch, {
                company,
                clientSettings,
                userId: requestingUser.id,
              }),
            );
          }, 30000);
        });
        dispatch(setPurchaseOrder(dispatch, null));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, LOADINGS.SUBSCRIPTION.PAYMENT, e));
        dispatch(toggleLoadingState(dispatch, LOADINGS.SUBSCRIPTION.PAYMENT, false));
        ErrorTracker.logError('SUBSCRIPTION_PAYMENT: failed to save the payment', e);
      });
  },
);
