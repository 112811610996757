import { connect } from 'react-redux';
import { DECK_SLIDE_DATA_TYPES } from '@premagic/myne';
import { ProposalSlideDataDuck } from '@premagic/proposals';

import { activeProposalSlideSelector } from '../ProposalCreatorDuck';
import SlideDataSettingButton from './SlideDataSettingButton';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    deckId: string;
    dataType: DECK_SLIDE_DATA_TYPES;
  },
) {
  const { projectId, deckId, dataType } = ownProps;
  const slideDetails = activeProposalSlideSelector(state);
  return {
    projectId,
    deckId,
    slideDetails,
    dataType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProposalSlide: (options, data) => dispatch(ProposalSlideDataDuck.saveProposalSlide(dispatch, options, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideDataSettingButton);
