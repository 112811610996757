import { FileService, GeneralImageEditorService, QRCodeBackgroundPosterService } from '@premagic/core';
import { Alert, ALERT_STYLES, ALERT_SIZES, Space } from '@premagic/myne';
import React, { useState } from 'react';
import GeneralImageEditorContainer from '../../../../general-image-editor/GeneralImageEditorContainer';

type Props = {
  logoFileData: FileService.FolderFileType;
  logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
  projectId: string;
  folderId: string;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  updateLogoData: (
    projectId: string,
    folderId: string,
    logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    newDesignData?: QRCodeBackgroundPosterService.QRCodeDesignType,
  ) => void;
  logos?: Array<string | undefined>;
  closeModal: () => void;
};

export default function QRCodeLogoImageEditor(props: Props) {
  const { logoFileData, closeModal, logoId, folderId, projectId, qrCodeDesigns, updateLogoData, logos } = props;
  const imgSrc = logoFileData?.dynamic_image_url;

  const [cropUseAlert, setCropUseAlert] = useState(false);

  function handleLogoMetaChange(val: GeneralImageEditorService.ImageEditorImageMetaDataType) {
    if (!val || !val.pixelCrop) {
      setCropUseAlert(true);
      return;
    }
    const data = {
      ...qrCodeDesigns?.logo_meta_data,
      [logoId]: val,
    };
    const newDesignData = {
      [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_META_DATA]: data,
    };
    updateLogoData(projectId, folderId, logoId, qrCodeDesigns, newDesignData);
    closeModal();
  }

  function handleLogoUpdate() {
    let newDesignData;
    if (logoId === QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_1) {
      newDesignData = {
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO]: [undefined, logos && logos[1]],
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_META_DATA]: {
          ...qrCodeDesigns?.logo_meta_data,
          [logoId]: null,
        },
      };
    } else {
      newDesignData = {
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO]: [logos && logos[0], undefined],
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_META_DATA]: {
          ...qrCodeDesigns?.logo_meta_data,
          [logoId]: null,
        },
      };
    }
    updateLogoData(projectId, folderId, logoId, qrCodeDesigns, newDesignData);
  }

  function handleLogoRemove() {
    handleLogoUpdate();
    closeModal();
  }

  function handleLogoChange() {
    handleLogoUpdate();
  }

  const logoMetaData = qrCodeDesigns?.logo_meta_data && qrCodeDesigns?.logo_meta_data[logoId];

  return (
    <>
      {cropUseAlert && (
        <>
          <Space vertical size={1} />
          <Alert style={ALERT_STYLES.WARNING} size={ALERT_SIZES.SM}>
            Select a crop area
          </Alert>
          <Space vertical size={1} />
        </>
      )}
      <GeneralImageEditorContainer
        imageSrc={imgSrc}
        onChange={handleLogoMetaChange}
        handleRemovePhoto={handleLogoRemove}
        handleChangePhoto={handleLogoChange}
        defaultCrop={logoMetaData?.pixelCrop}
        defaultScale={logoMetaData?.scale}
        defaultRotate={logoMetaData?.rotate}
      />
    </>
  );
}
