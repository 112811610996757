import { createSelector } from 'reselect';

const pagesSelector = (state) =>
  state.pages as {
    proposal: {
      data: {
        proposals: unknown;
        variables: unknown;
        slides: unknown;
        decks: unknown;
        files: unknown;
      };
      creator: unknown;
    };
  };

export const proposalPageSelector = createSelector(pagesSelector, (pages) => pages.proposal);
