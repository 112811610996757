import React from 'react';

import { Col, RealCheckbox, Row, Space, TEXT_BOLDNESS, Text } from '@premagic/myne';
import { get } from 'lodash';
import { ClientWebsiteService, EventsCategoryService, ThemeService } from '@premagic/core';
import { CLIENT_WEBSITE_FORM_ITEMS, CLIENT_WEBSITE_FORM_ITEMS_DETAILS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  eventName?: string;
  isEventNameOrGroomNameHidden?: boolean;
};

export default function ClientWebsitePeopleDetailsForm(props: Props): JSX.Element {
  const { clientWebsite, eventName, isEventNameOrGroomNameHidden } = props;
  const isWeddingEvent = EventsCategoryService.isWeddingEvent(eventName || clientWebsite?.event?.type);
  const showBrideAndGroomInfo = clientWebsite
    ? [ThemeService.THEMES_IDS.BLED].includes(Number(clientWebsite?.theme?.id))
    : false;
  const hasBrideName =
    get(clientWebsite, CLIENT_WEBSITE_FORM_ITEMS_DETAILS[CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE].name, '').length > 0;
  if (isWeddingEvent) {
    return (
      <div>
        <Row>
          <Col>
            <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM} clientWebsite={clientWebsite} />
          </Col>
          <Space />
          <Col>
            <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE} clientWebsite={clientWebsite} />
          </Col>
        </Row>
        {showBrideAndGroomInfo && (
          <div>
            <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.GROOM_INFO} clientWebsite={clientWebsite} />
            <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.BRIDE_INFO} clientWebsite={clientWebsite} />
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <ClientWebsiteFormItem
        itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM}
        clientWebsite={clientWebsite}
        hideLabel={!hasBrideName}
      />
      <RealCheckbox name="data[client_website_hide_title]" defaultChecked={isEventNameOrGroomNameHidden}>
        <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>Hide event name on registration page</Text>
      </RealCheckbox>
      <Space vertical />
      {hasBrideName && (
        <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE} clientWebsite={clientWebsite} />
      )}
    </div>
  );
}
