import { EventTrackerService, FolderService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

import AlbumImagesBasedOnStatusLayout from './AlbumImagesBasedOnStatusLayout';
import { fetchProjectPhotos, projectOverviewPageSelector } from './ProjectOverviewPageDuck';
import { projectOverviewFoldersSelector, projectOverviewImagesSelector } from './ProjectsOverviewDataDuck';
import { enableSelectionFavoriteSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    match: {
      params: {
        projectId: string;
        view: 'approved' | 'all' | 'rejected' | 'favorite';
        folderType: FolderService.FOLDER_TYPES;
      };
    };
  },
) {
  const { projectId, view, folderType } = ownProps.match.params;
  const { projectName } = projectOverviewPageSelector(state);

  return {
    isLoading: isLoadingSelector(LOADINGS.PROJECTS.OVERVIEW_FETCH_ALL)(state),
    view,
    folderType,
    projectId,
    projectName,
    folders: projectOverviewFoldersSelector(state),
    images: projectOverviewImagesSelector(state),
    enableSelectionFavorite: enableSelectionFavoriteSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId, folderType, imageStatus) => {
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.VIEW_ALL_SELECTED_PHOTOS, {
        folderType,
        imageStatus,
      });
      dispatch(fetchProjectPhotos(dispatch, projectId, folderType, imageStatus));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumImagesBasedOnStatusLayout);
