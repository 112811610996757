import React, { useMemo } from 'react';
import {
  Col,
  ColorPicker,
  DeckSlideBackgroundColorDataType,
  FormGroup,
  FormLabel,
  INPUT_SIZES,
  Row,
  Select,
} from '@premagic/myne';
import { debounce } from 'lodash';
import { ColorUtils } from '@premagic/utils';

enum COLOR_STYLES {
  SOLID = 'solid',
  RADIAL_GRADIENT = 'radial-gradient',
  LINEAR_GRADIENT = 'linear-gradient',
  MULTI_GRADIENT = 'multi-gradient',
}

const styleOptions: Array<{
  label: string;
  value: COLOR_STYLES;
}> = [
  {
    label: 'Solid',
    value: COLOR_STYLES.SOLID,
  },
  {
    label: 'Linear Gradient',
    value: COLOR_STYLES.LINEAR_GRADIENT,
  },
  {
    label: 'Radial Gradient',
    value: COLOR_STYLES.RADIAL_GRADIENT,
  },
  {
    label: 'Multi Gradient',
    value: COLOR_STYLES.MULTI_GRADIENT,
  },
];

type StylesButtonsProps = {
  activeStyle: COLOR_STYLES;
  onChange: (style: COLOR_STYLES) => void;
};

function StylesSelector(props: StylesButtonsProps) {
  const { onChange, activeStyle } = props;
  return (
    <FormGroup>
      <FormLabel>Style</FormLabel>
      <Select
        menuPlacement="top"
        size={INPUT_SIZES.SM}
        options={styleOptions}
        name=""
        onChange={(option) => onChange(option?.value as COLOR_STYLES)}
        value={styleOptions.find((item) => item.value === activeStyle)}
        isMulti={false}
      />
    </FormGroup>
  );
}

function generateBackgroundColor(style: COLOR_STYLES, colors: Array<string>): string {
  let gradientSize = 1200;
  const colorStop = 0.2;

  switch (style) {
    case COLOR_STYLES.LINEAR_GRADIENT:
      return `linear-gradient(${colors.join(', ')})`;
    case COLOR_STYLES.RADIAL_GRADIENT:
      return ColorUtils.generateRadialGradient({
        sizeX: gradientSize,
        sizeY: gradientSize,
        colorStops: [
          {
            color: colors[1],
            stop: 0.2,
          },
          {
            color: colors[0],
            stop: 0.35,
          },
        ],
        x: 'center',
        y: '80%',
      });
    case COLOR_STYLES.MULTI_GRADIENT:
      gradientSize = 450;
      return ColorUtils.generateMultipleRadialGradient([
        {
          sizeX: gradientSize,
          sizeY: gradientSize,
          colorStops: [
            {
              color: colors[0],
              stop: colorStop,
            },
            {
              color: 'transparent',
              stop: 1,
            },
          ],
          x: '-10%',
          y: '10%',
        },
        {
          sizeX: gradientSize,
          sizeY: gradientSize,
          colorStops: [
            {
              color: colors[1],
              stop: colorStop,
            },
            {
              color: 'transparent',
              stop: 1,
            },
          ],
          x: '110%',
          y: '-10%',
        },
        {
          sizeX: gradientSize,
          sizeY: gradientSize,
          colorStops: [
            {
              color: colors[3],
              stop: colorStop,
            },
            {
              color: 'transparent',
              stop: 1,
            },
          ],
          x: '0%',
          y: '70%',
        },
        {
          sizeX: gradientSize,
          sizeY: gradientSize,
          colorStops: [
            {
              color: colors[2],
              stop: colorStop,
            },
            {
              color: 'transparent',
              stop: 1,
            },
          ],
          x: '90%',
          y: '80%',
        },
      ]);
    case COLOR_STYLES.SOLID:
    default:
      return colors[0];
  }
}

function getColorStyleFromBGColorValue(bgColor?: string): COLOR_STYLES {
  if (!bgColor) {
    return COLOR_STYLES.SOLID;
  }
  if (bgColor.startsWith('linear-gradient')) {
    return COLOR_STYLES.LINEAR_GRADIENT;
  }

  if ((bgColor.match(/radial-gradient/g) || []).length > 1) {
    return COLOR_STYLES.MULTI_GRADIENT;
  }

  if (bgColor.startsWith('radial-gradient')) {
    return COLOR_STYLES.RADIAL_GRADIENT;
  }

  return COLOR_STYLES.SOLID;
}

function getColorsFromBGColorValue(bgColor?: string): Array<string> {
  if (!bgColor) {
    return ['#22194d', '#22194d', '#22194d', '#22194d'];
  }

  const colors = bgColor.match(/#(?:[0-9a-fA-F]{3}){1,2}/g) || [];

  const style = getColorStyleFromBGColorValue(bgColor);
  switch (style) {
    case COLOR_STYLES.RADIAL_GRADIENT:
      return colors.reverse();
    case COLOR_STYLES.MULTI_GRADIENT:
      return [colors[0] as string, colors[1], colors[3], colors[2]];
    case COLOR_STYLES.LINEAR_GRADIENT:
    case COLOR_STYLES.SOLID:
    default:
      return colors || ['#22194d', '#22194d', '#22194d', '#22194d'];
  }
}

type Props = {
  onChange: (color: string) => void;
  value: DeckSlideBackgroundColorDataType;
};

export default function SlideBackgroundColorUpdater(props: Props) {
  const { onChange, value } = props;
  const [activeStyle, setActiveStyle] = React.useState(getColorStyleFromBGColorValue(value?.value));
  const [colors, setColors] = React.useState<Array<string>>(getColorsFromBGColorValue(value?.value));

  const debouncedOnChange = useMemo(() => debounce(onChange, 1000), [onChange, value]);
  function handleOnChange(index, color) {
    colors[index] = color;
    setColors(colors);
    const colorString = generateBackgroundColor(activeStyle, colors);
    debouncedOnChange(colorString);
  }

  return (
    <Row vcenter>
      <Col>
        <StylesSelector onChange={setActiveStyle} activeStyle={activeStyle} />
      </Col>
      <Col>
        {(() => {
          switch (activeStyle) {
            case COLOR_STYLES.SOLID:
              return (
                <ColorPicker
                  name="color"
                  onChange={(newColor) => handleOnChange(0, newColor)}
                  defaultValue={colors[0]}
                />
              );
            case COLOR_STYLES.RADIAL_GRADIENT:
            case COLOR_STYLES.LINEAR_GRADIENT:
              return [0, 1].map((index) => (
                <ColorPicker
                  key={index}
                  name={`color[${index}]`}
                  onChange={(newColor) => handleOnChange(index, newColor)}
                  defaultValue={colors[index]}
                />
              ));
            case COLOR_STYLES.MULTI_GRADIENT:
              return [0, 1, 2, 3].map((index) => (
                <ColorPicker
                  key={index}
                  name={`color[${index}]`}
                  onChange={(newColor) => handleOnChange(index, newColor)}
                  defaultValue={colors[index]}
                />
              ));
            default:
              return null;
          }
        })()}
      </Col>
    </Row>
  );
}
