import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { EventAttendeeType, getSelfieBlob } from './EventAttendeesService';

export enum CHECKIN_ZONE_ACTION_TYPE {
  CHECKIN = 'checkin',
  CHECKOUT = 'checkout',
}

export type NewEventAttendeeCheckinZoneType = {
  name: string;
};

export type EventAttendeeCheckinZoneType = { id: string; name: string; color?: string; created_at?: string };

export type EventAttendeeCheckinZoneStatsItemType = {
  type: CHECKIN_ZONE_ACTION_TYPE;
  timestamp: string;
  zone: string;
};

export type EventAttendeeCheckinZoneStatsItemAccountAppType = {
  zone_id: string;
  checkin_count: string;
  checkout_count: string;
  active_count: string;
};

export function fetchEventAttendeeCheckinZones(projectId: string) {
  return HttpUtils.get(API_URLS.EVENT_CHECKIN_ZONES.ROOT(projectId))
    .then(({ data }) => data)
    .catch(({ data }) => Promise.reject(data));
}

export function createEventAttendeeCheckinZone(projectId: string, zoneData: NewEventAttendeeCheckinZoneType) {
  return HttpUtils.post(API_URLS.EVENT_CHECKIN_ZONES.ROOT(projectId), zoneData)
    .then(({ data }) => data)
    .catch(({ data }) => Promise.reject(data));
}

export function deleteEventAttendeeCheckinZone(projectId: string, zoneId: string) {
  return HttpUtils.httpDelete(API_URLS.EVENT_CHECKIN_ZONES.DETAILS(projectId, zoneId))
    .then(({ data }) => data)
    .catch(({ data }) => Promise.reject(data));
}

export async function attendeeSelfCheckinFromClientWebsiteBasedOnZone(
  domain: string,
  websiteUrlId: string,
  data: { registrationId: string; zoneId: string; action: CHECKIN_ZONE_ACTION_TYPE },
): Promise<{
  data: EventAttendeeType;
  face_id?: string;
  registration_id?: string;
  success: boolean;
}> {
  const formData = new FormData();
  // const selfieBlob = await getSelfieBlob(data.selfie);
  // formData.append('selfie', selfieBlob || data.selfie);
  formData.append('registration_id', data.registrationId);
  formData.append('zone_id', data.zoneId);
  formData.append('action', data.action);

  return HttpUtils.post(API_URLS.EVENT_CHECKIN_ZONES.CHECKIN_CHECKOUT(domain, websiteUrlId), formData, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.data?.error || { message: 'Oops... Something went wrong, try again' }));
}

export function fetchEventAttendeeCheckinZonesForClientWebsite(
  domain: string,
  websiteId: string,
): Promise<Array<EventAttendeeCheckinZoneType>> {
  return HttpUtils.get(API_URLS.EVENT_CHECKIN_ZONES.LIST_PUBLIC(domain, websiteId))
    .then(({ data }) => data)
    .catch(({ data }) => Promise.reject(data));
}

export async function attendeeCheckinCheckoutInfoForClientWebsite(
  domain: string,
  websiteId: string,
  registrationId: string,
) {
  return HttpUtils.get(API_URLS.EVENT_CHECKIN_ZONES.USER_STATUS_INFO(domain, websiteId, registrationId))
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.data));
}

export function fetchEventAttendeeCheckinZonesStats(
  projectId: string,
): Promise<Array<EventAttendeeCheckinZoneStatsItemAccountAppType>> {
  return HttpUtils.get(API_URLS.EVENT_CHECKIN_ZONES.STATS(projectId))
    .then(({ data }) => data)
    .catch(({ data }) => Promise.reject(data));
}
