import React from 'react';
import { History } from 'history';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import APP_URLS from '../../services/AppRouteURLService';
import AccountFinancialsDetailsPageContainer from './AccountFinancialsPageContainer';

import { getDefaultAccountFinancialsUrlWithFilters } from './filters/AccountFinancialsFilterService';
import { ACCOUNT_FINANCIALS_TYPES } from './AccountFinancialsService';

type Props = {
  history: History;
};
const ROUTES = APP_URLS.FINANCIALS;

export default function AccountFinancialsRoutes(props: Props): JSX.Element {
  const { history } = props;
  return (
    <Router history={history}>
      <Route path={ROUTES.INDEX}>
        <Switch>
          <Route path={ROUTES.DETAILS} component={AccountFinancialsDetailsPageContainer} />
          <Route>
            <Redirect to={getDefaultAccountFinancialsUrlWithFilters(ACCOUNT_FINANCIALS_TYPES.PENDING)} />
          </Route>
        </Switch>
      </Route>
    </Router>
  );
}
