import { EventAttendeesService, EventCustomFromFieldsService, PeopleFacesService } from '@premagic/core';
import { Avatar, AVATAR_SIZES, AVATAR_STYLES, Row, Select, Space } from '@premagic/myne';
import { useMemo } from 'react';

type AttendeeAvatarProps = {
  attendee: EventAttendeesService.EventAttendeeType;
};

function AttendeeAvatar(props: AttendeeAvatarProps) {
  const { attendee } = props;

  const { name, face_id: faceId, best_face_id: bestFaceId, custom_fields: customFields } = attendee;

  const faceUrlToShow =
    customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]?.dynamic_image_url ||
    PeopleFacesService.getPeopleFaceUrl(bestFaceId || faceId);

  return (
    <Avatar
      style={AVATAR_STYLES.SECONDARY}
      size={AVATAR_SIZES.SM}
      user={{
        fullName: name,
        profilePic: faceUrlToShow,
      }}
    />
  );
}

type SelectedAttendeeAvatarsProps = {
  selectedAttendees: Array<EventAttendeesService.EventAttendeeType>;
};

function SelectedAttendeeAvatars(props: SelectedAttendeeAvatarsProps) {
  const { selectedAttendees } = props;

  if (!selectedAttendees?.length) return null;

  return (
    <>
      <Row vcenter>
        {selectedAttendees.map((attendee) => (
          <Space size={1} key={attendee.registration_id}>
            <AttendeeAvatar attendee={attendee} />
          </Space>
        ))}
      </Row>
      <Space vertical />
    </>
  );
}

type Props = {
  projectId: string;
  isLoading?: boolean;
  attendees: Record<string, EventAttendeesService.EventAttendeeType>;
  value: Array<string>;
  onChange: (newFilterValue: Array<string>) => void;
};

export default function DAMLibraryPeopleFilterByNameSelector(props: Props) {
  const { projectId, isLoading, attendees, value, onChange } = props;

  const attendeesOptions = useMemo(
    () =>
      Object.values(attendees).map((item) => ({
        label: item.name,
        value: item.best_face_id || item.face_id,
        thumbnail: PeopleFacesService.getPeopleFaceUrl(item.best_face_id || item.face_id),
      })),
    [attendees],
  );

  const selectedValues = useMemo(
    () => attendeesOptions.filter((item) => value?.includes(item.value)),
    [value, attendeesOptions],
  );

  const selectedAttendees = useMemo(
    () => Object.values(attendees).filter((item) => value?.includes(item.face_id)),
    [attendees, value],
  );

  function handleAttendeeOnSelect(data) {
    const selectedFaceIds = data.map((item) => item.value);
    onChange(selectedFaceIds);
  }

  return (
    <>
      <SelectedAttendeeAvatars selectedAttendees={selectedAttendees} />
      <Select
        name="attendee_face_id_select"
        options={attendeesOptions}
        value={selectedValues}
        isMulti
        isSearchable
        isLoading={isLoading}
        onChange={handleAttendeeOnSelect}
      />
    </>
  );
}
