import {
  Button,
  BUTTON_STYLES,
  Col,
  FormGroup,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  INPUT_SIZES,
  Row,
  Select,
  Space,
  SubTitle,
} from '@premagic/myne';
import { FolderMetaService, FontPaletteService, FontService } from '@premagic/core';
import { useState } from 'react';

const fontOptions = Object.entries(FontPaletteService.FONT_PALETTES_DETAILS).map(([id, font]) => ({
  label: font.name,
  value: id,
}));

type FontSelectorProps = {
  selected: FontPaletteService.FONT_PALETTES;
  onChange: (value: { primary: FontService.FONT_IDS; secondary: FontService.FONT_IDS }) => void;
};

function FontSelector(props: FontSelectorProps): JSX.Element {
  const { selected, onChange } = props;
  const [selectedPalette, setSelectedPalette] = useState<FontPaletteService.FONT_PALETTES>(selected);
  const selectedOptionDetails = FontPaletteService.FONT_PALETTES_DETAILS[selectedPalette];
  const defaultSelectedOption = fontOptions.find(
    (option) => (option.value as unknown as FontPaletteService.FONT_PALETTES) == selected,
  );

  return (
    <div>
      <Select
        name=""
        size={INPUT_SIZES.MD}
        value={defaultSelectedOption}
        options={fontOptions}
        isMulti={false}
        onChange={(option) => {
          const newSelectedOptions = option?.value as FontPaletteService.FONT_PALETTES;
          const newSelectedOptionDetails = FontPaletteService.FONT_PALETTES_DETAILS[newSelectedOptions];

          setSelectedPalette(newSelectedOptions);
          onChange(newSelectedOptionDetails);
        }}
      />
      <input
        type="hidden"
        value={selectedOptionDetails.primary}
        name={FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT}
      />
      <input
        type="hidden"
        value={selectedOptionDetails.secondary}
        name={FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT}
      />
    </div>
  );
}

type Props = {
  projectId: string;
  folderId: string;
  folderMeta?: FolderMetaService.FolderMetaType;
  saveFolderMeta: (
    projectId: string,
    folderId: string,
    data: FormResponseType & {
      data: FolderMetaService.FolderMetaType;
    },
  ) => void;
};

export default function ProposalCreatorProposalFolderDataUpdater(props: Props): JSX.Element {
  const { projectId, folderId, folderMeta = {}, saveFolderMeta } = props;
  const selectedFontPalette = FontPaletteService.getFontPaletteIdFromValues({
    primary: folderMeta[FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT],
    secondary: folderMeta[FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT],
  });
  const [expand, setExpand] = useState(true);

  function handleOnChange(value) {
    saveFolderMeta(projectId, folderId, {
      data: {
        [FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT]: value.primary,
        [FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT]: value.secondary,
      },
    });
  }
  return (
    <Space>
      <Button style={BUTTON_STYLES.RESET} onClick={() => setExpand(!expand)} block>
        <Row vcenter>
          <SubTitle>Proposal Details</SubTitle>
          <Col size={null} rightAlighed>
            <Icon name={expand ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
          </Col>
        </Row>
      </Button>
      {expand && (
        <div>
          <Space vertical size={2} />
          <FormGroup>
            <FormLabel>Font</FormLabel>
            <FontSelector selected={selectedFontPalette} onChange={handleOnChange} />
          </FormGroup>
        </div>
      )}
    </Space>
  );
}
