import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { flatten, uniq } from 'lodash';
import React from 'react';
import ClassNames from 'classnames';
import { NumberUtils } from '@premagic/utils';
import styles from './form.module.css';

const MAX_FILE_VIDEO_SIZE = 3000 * 1000 * 1000; // 3GB
const MAX_FILE_PHOTO_SIZE = 25 * 1000 * 1000; // 25 MB
const MAX_FILES = 20;

export enum INPUT_FILE_ACCEPT {
  IMAGES = 'image/x-png, image/jpeg, image/jpg, image/png, image/tiff',
  IMAGES_AND_VIDEO = 'image/x-png, image/jpeg, image/jpg, image/png, image/tiff, video/mp4, video/x-m4v, video/*',
  VIDEO = 'video/mp4, video/x-m4v, video/*',
}

export enum ERROR_CODES {
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
  FILE_TOO_SMALL = 'file-too-small',
  TOO_MANY_FILES = 'too-many-files',
  FILE_TOO_LARGE_VIDEO = 'file-too-large-video',
  FILE_TOO_LARGE_PHOTO = 'file-too-large-photo',
  FILE_INVALID_WIDTH_DIMENSIONS = 'file-invalid-width-dimensions',
  FILE_INVALID_HEIGHT_DIMENSIONS = 'file-invalid-height-dimensions',
}

const ERROR_MESSAGES: Record<ERROR_CODES, (option, option2?: number) => string> = {
  [ERROR_CODES.FILE_INVALID_TYPE]: () => 'Invalid file type',
  [ERROR_CODES.FILE_TOO_LARGE]: () => 'File is too large',
  [ERROR_CODES.FILE_TOO_SMALL]: () => 'File is too small',
  [ERROR_CODES.FILE_TOO_LARGE_VIDEO]: (size: number) =>
    `Video is <strong>too large</strong><br/> Make sure the file size is less than <strong>${NumberUtils.getNumberInBytesFormat(
      size,
    )}</strong>    `,
  [ERROR_CODES.FILE_TOO_LARGE_PHOTO]: (size: number) =>
    `Photo is <strong>too large</strong><br/> Make sure the file size is less than <strong>${NumberUtils.getNumberInBytesFormat(
      size,
    )}</strong>`,
  [ERROR_CODES.TOO_MANY_FILES]: (maxFiles) =>
    `You have selected <strong>too many files</strong><br/>You can only upload <strong>${maxFiles} files</strong> at once`,
  [ERROR_CODES.FILE_INVALID_WIDTH_DIMENSIONS]: (fileWidth, minWidth) =>
    `File is width is <strong>${fileWidth}px!</strong> Upload a file with min width of <strong>${minWidth}px</strong>`,
  [ERROR_CODES.FILE_INVALID_HEIGHT_DIMENSIONS]: (fileHeight, minHeight) =>
    `File is height is <strong>${fileHeight}px!</strong> Upload a file with min height of <strong>${minHeight}px</strong>`,
};

function getUniqueErrorMessages(
  fileRejections: Array<FileRejection>,
  options: {
    maxImageFileSize: number;
    maxVideoFileSize: number;
    maxFiles: number;
  },
): Array<string> {
  const { maxImageFileSize, maxVideoFileSize, maxFiles } = options;

  const errors = flatten(fileRejections.map((rejection) => rejection.errors));
  return uniq(
    errors.map((error) => {
      switch (error.code) {
        case ERROR_CODES.FILE_TOO_LARGE_VIDEO:
          return ERROR_MESSAGES[error.code](maxVideoFileSize);
        case ERROR_CODES.FILE_TOO_LARGE_PHOTO:
          return ERROR_MESSAGES[error.code](maxImageFileSize);
        case ERROR_CODES.TOO_MANY_FILES:
          return ERROR_MESSAGES[error.code](maxFiles);
        case ERROR_CODES.FILE_INVALID_TYPE:
        case ERROR_CODES.FILE_TOO_LARGE:
        case ERROR_CODES.FILE_TOO_SMALL:
        default:
          return ERROR_MESSAGES[error.code]?.() || error.message;
      }
    }),
  );
}

function validateDimensions(
  file,
  dimensions: {
    minWidth?: number;
    minHeight?: number;
  },
): Promise<null | FileError> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = (e?.target?.result as string) || '';
      image.onload = () => {
        if (image.width < (dimensions?.minWidth || 0)) {
          resolve({
            code: ERROR_CODES.FILE_INVALID_WIDTH_DIMENSIONS,
            message: ERROR_MESSAGES[ERROR_CODES.FILE_INVALID_WIDTH_DIMENSIONS](image.width, dimensions.minWidth),
          });
        }

        if (image.height < (dimensions?.minHeight || 0)) {
          resolve({
            code: ERROR_CODES.FILE_INVALID_HEIGHT_DIMENSIONS,
            message: ERROR_MESSAGES[ERROR_CODES.FILE_INVALID_HEIGHT_DIMENSIONS](image.width, dimensions.minHeight),
          });
        }
        return resolve(null);
      };
    };
  });
}

function validatorForFileSize(
  file: File,
  options: {
    maxImageFileSize: number;
    maxVideoFileSize: number;
  },
): null | FileError {
  const { maxImageFileSize, maxVideoFileSize } = options;
  if (file.type.includes('video') && file.size > maxVideoFileSize) {
    return {
      code: ERROR_CODES.FILE_TOO_LARGE_VIDEO,
      message: ERROR_MESSAGES[ERROR_CODES.FILE_TOO_LARGE_VIDEO](maxVideoFileSize),
    };
  }
  if (file.type.includes('image') && file.size > maxImageFileSize) {
    return {
      code: ERROR_CODES.FILE_TOO_LARGE_PHOTO,
      message: ERROR_MESSAGES[ERROR_CODES.FILE_TOO_LARGE_PHOTO](maxImageFileSize),
    };
  }
  return null;
}

function validator(
  file: File,
  options: {
    maxImageFileSize: number;
    maxVideoFileSize: number;
    minWidth?: number;
    minHeight?: number;
  },
) {
  // if ((options.minHeight || options.minWidth) && file.type.includes('image'))

  return validatorForFileSize(file, options);
}

interface InputFileDropzoneProps {
  children: (options: { isDragReject: boolean; isDragAccept: boolean; isDragActive: boolean }) => JSX.Element;
  large: boolean;
  style: 'standalone' | 'default';
  accept: INPUT_FILE_ACCEPT;
  onDrop: (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, errorMessages: Array<string>) => void;
  onError?: (error: { message: string }) => void;
  maxImageFileSize?: number;
  maxVideoFileSize?: number;
  maxFiles?: number;
}

export function InputFileDropzone(props: InputFileDropzoneProps): React.ReactElement {
  const {
    children,
    large,
    style,
    accept,
    onDrop,
    maxImageFileSize = MAX_FILE_PHOTO_SIZE,
    maxVideoFileSize = MAX_FILE_VIDEO_SIZE,
    maxFiles = MAX_FILES,
  } = props;

  function handleOnDrop(acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, e) {
    const errorMessages = getUniqueErrorMessages(fileRejections, {
      maxImageFileSize,
      maxVideoFileSize,
      maxFiles,
    });
    onDrop(acceptedFiles, fileRejections, errorMessages);
    // if (e.dataTransfer && e.dataTransfer.items) {
    //   const items = e.dataTransfer.items;
    //   for (let i = 0; i < items.length; i++) {
    //     const item = items[i].webkitGetAsEntry();
    //
    //     if (item) {
    //       console.log(items);
    //       // addDirectory(item);
    //     }
    //   }
    // }
  }

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
    maxFiles,
    maxSize: MAX_FILE_VIDEO_SIZE,
    accept,
    onDrop: handleOnDrop,
    noKeyboard: style === 'default',
    noClick: style === 'default',
    validator: (file) =>
      validator(file, {
        maxImageFileSize,
        maxVideoFileSize,
      }),
  });

  return (
    <div
      {...getRootProps()}
      className={ClassNames(styles['input-file-dropzone'], styles[`input-file-dropzone--style-${style}`], {
        [styles['input-file-dropzone--large']]: large,
        [styles['input-file-dropzone--active']]: isDragActive,
      })}
    >
      <input {...getInputProps()} accept={accept} />
      {children({
        isDragReject,
        isDragAccept,
        isDragActive,
      })}
    </div>
  );
}

interface InputFileDropzoneOverlayContentProps {
  children: React.ReactNode | any;
}

export function InputFileDropzoneOverlayContent(props: InputFileDropzoneOverlayContentProps): React.ReactElement {
  const { children } = props;
  return <div className={ClassNames(styles['input-file-dropzone__content'])}>{children}</div>;
}
