import React, { Component } from 'react';

import { NumberUtils, SimpleDateUtils } from '@premagic/utils';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  MiniCardCount,
  MiniCardCounts,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { PricingPlanService } from '@premagic/core';

import { InvoiceType } from './InvoiceService';
import APP_URLS from '../../../services/AppRouteURLService';

type StatsProps = {
  title: string;
  count: number | React.ReactNode;
};

function Stats(props: StatsProps) {
  const { count, title } = props;
  return (
    <MiniCardCount>
      <Text muted boldness={TEXT_BOLDNESS.SEMI_BOLD}>
        {title}
      </Text>
      <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
        {count}
      </Text>
    </MiniCardCount>
  );
}

type InvoiceItemProps = {
  item: InvoiceType;
};

function InvoiceItem(props: InvoiceItemProps) {
  const { item } = props;
  const {
    id,
    amount_paid: paidAmount,
    currency,
    currency_symbol: symbol,
    short_url: url,
    created_at: createdAt,
  } = item;
  const date = Math.floor(Number(createdAt) * 1000);

  const amountString = NumberUtils.getCurrencyInFormat(paidAmount / 100);

  return (
    <div>
      <Card>
        <Space>
          <div>
            <Text color={COLOR_SHADES.DARKER} block boldness={TEXT_BOLDNESS.BOLD}>
              # {id} <Space />
              <SimpleLink isExternalLink showExternalLinkIcon href={url}>
                Details
              </SimpleLink>
            </Text>
          </div>
          <Text block>{SimpleDateUtils.humanizeDate(SimpleDateUtils.getDateStringISO(new Date(date)), false)}</Text>
          <Space vertical />
          <Col>
            <MiniCardCounts>
              <Stats
                title="Amount"
                count={
                  <span>
                    {symbol} {amountString}
                  </span>
                }
              />
            </MiniCardCounts>
          </Col>
        </Space>
      </Card>
      <Space vertical />
    </div>
  );
}

type Props = {
  initData: () => void;
  isLoading: boolean;
  invoices: Array<InvoiceType>;
};

type State = {
  view: string;
};

export default class InvoicePage extends Component<Props, State> {
  componentDidMount() {
    const { initData } = this.props;
    initData();
  }

  render() {
    const { isLoading, invoices } = this.props;

    return (
      <InnerPage>
        <InnerPageHeader iconName="box" title="Invoices" backTo={APP_URLS.SETTINGS.SUBSCRIPTIONS}>
          Invoices
        </InnerPageHeader>
        <InnerPageContent isLoading={isLoading}>
          {invoices.length === 0 && (
            <div>
              <Text muted>You don&apos;t have any invoices right now.</Text>
            </div>
          )}
          <Col size={8}>
            {invoices.map((invoice) => (
              <InvoiceItem key={invoice.id} item={invoice} />
            ))}
          </Col>
        </InnerPageContent>
      </InnerPage>
    );
  }
}
