import { useState, useEffect, useCallback } from 'react';
import { throttle } from 'lodash';

type UseScrollStateType = {
  scrollY: number;
  scrollX: number;
  scrollDirection: 'down' | 'up';
  lastScrollTop: number;
  isOnTop: boolean;
  isOnBottom: boolean;
  isOnLeft: boolean;
  isOnRight: boolean;
};

export function useScroll(options?: { container?: HTMLElement; target?: HTMLElement }): UseScrollStateType {
  const { container = window, target = document.body } = options || {};
  const [state, setState] = useState<UseScrollStateType>({
    isOnBottom: false,
    isOnLeft: false,
    isOnRight: false,
    isOnTop: false,
    lastScrollTop: 0,
    scrollY: target.getBoundingClientRect().top || 0,
    scrollX: target.getBoundingClientRect().left || 0,
    scrollDirection: 'down', // down, up
  });

  const handleScrollEvent = useCallback(() => {
    setState((prevState) => {
      const prevLastScrollTop = prevState.lastScrollTop;
      const bodyOffset = target.getBoundingClientRect();
      const { scrollWidth = 0, scrollLeft = 0, offsetWidth = 0, scrollHeight, scrollTop, offsetHeight } = target;

      return {
        scrollY: -bodyOffset.top,
        scrollX: bodyOffset.left,
        scrollDirection: prevLastScrollTop > -bodyOffset.top ? 'up' : 'down',
        lastScrollTop: -bodyOffset.top,
        isOnTop: scrollTop === 0,
        isOnBottom: scrollTop + offsetHeight >= scrollHeight,
        isOnLeft: scrollLeft === 0,
        isOnRight: scrollLeft + offsetWidth >= scrollWidth,
      };
    });
  }, [target]);

  useEffect(() => {
    const scrollListener = throttle(handleScrollEvent, 150);
    handleScrollEvent();
    container.addEventListener('scroll', scrollListener);

    return () => {
      container.removeEventListener('scroll', scrollListener);
    };
  }, [container]);

  return state;
}
