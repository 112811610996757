import { FolderService, ProjectService } from '@premagic/core';
import {
  Card,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  Row,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import React from 'react';
import SignatureAlbumMusicSelectorContainer from '../../../crm/event-deliveries/signature-album/SignatureAlbumMusicSelectorContainer';
import ProjectPermissionsSettingsContainer from '../../folder-permissions/ProjectPermissionsSettingsContainer';
import VideoLinkUploaderContainer from '../../folders/folder-files-upload/VideoLinkUploaderContainer';
import ProjectPortfolioToggleContainer from '../../highlight-settings/ProjectPortfolioToggleContainer';
import ProjectPinNumber from '../../ProjectPinNumber';
import ProjectPinNumberResetContainer from '../../ProjectPinNumberResetContainer';
import HighlightFolderDetailsContainer from './HighlightFolderDetailsContainer';
import HighlightFoldersListContainer from './HighlightFoldersListContainer';

type Props = {
  activeFolderId: string;
  projectId: string;
  project: ProjectService.ProjectType;
  isStaffUser: boolean;
};

export default function HighlightFoldersPageFoldersLayout(props: Props) {
  const { projectId, project, activeFolderId, isStaffUser } = props;

  return (
    <>
      <InnerPageContentMainCol>
        <HighlightFoldersListContainer projectId={projectId} activeFolderId={activeFolderId} />
        <Space vertical />
        {activeFolderId && <HighlightFolderDetailsContainer projectId={projectId} folderId={activeFolderId} />}
        <VideoLinkUploaderContainer projectId={projectId} folderId={activeFolderId} />
      </InnerPageContentMainCol>

      <InnerPageContentSidebar>
        <SubTitle>Project Details</SubTitle>
        <Space vertical size={4} />
        <Row>
          <ProjectPinNumber pin={project?.mpin} />
          <Space />
          {project?.mpin && <ProjectPinNumberResetContainer projectId={projectId} />}
        </Row>
        <Space vertical size={4} />
        <ProjectPortfolioToggleContainer projectId={projectId} />
        <Space vertical size={4} />
        {/* <ProjectMetaToggleContainer */}
        {/*  projectId={projectId} */}
        {/*  itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES} */}
        {/* /> */}
        <Space vertical size={4} />

        <Row vcenter>
          <Color shade={COLOR_SHADES.YELLOW} fillSolidIcon inline>
            <Icon name="play" size={ICON_SIZES.SM} />
          </Color>
          <Space size={1} />
          <Text boldness={TEXT_BOLDNESS.BOLD}>Slideshow</Text>
          <Space />
          <SignatureAlbumMusicSelectorContainer projectId={projectId} />
        </Row>
        <Space vertical size={4} />
        <ProjectPermissionsSettingsContainer projectId={projectId} folderType={FolderService.FOLDER_TYPES.HIGHLIGHT} />
      </InnerPageContentSidebar>
    </>
  );
}
