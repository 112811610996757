import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { taskItemSelector } from '../data-duck/TaskDataDuck';
import WorkflowTask from './WorkflowTask';

function mapStateToProps(state, ownProps: { taskId: number }) {
  const { taskId } = ownProps;
  return {
    task: taskItemSelector(taskId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTask);
