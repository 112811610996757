import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';
import { EventsService } from '@premagic/core';
import AddEventWithClientPage from './AddEventWithClientPage';
import { companyLeadFormsURL } from '../../settings/company/CompanyDataDuck';
import { hasPermission } from '../users/UsersDataDuck';
import { PERMISSIONS } from '../users/UserPermissionService';
import { isFeatureLeadEnabledSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  const { filters } = ownProps.match.params;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as {
    event_status: EventsService.EVENT_STATUS;
  };
  return {
    pathname,
    companyLeadForms: companyLeadFormsURL(state),
    hasEventManagementPermission: hasPermission(PERMISSIONS.EVENT_MANAGEMENT)(state),
    hasLeadsModule: isFeatureLeadEnabledSelector(state),
    filterObject,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEventWithClientPage);
