import React, { useRef, useState } from 'react';

import ClassNames from 'classnames';
import { MathUtils, MediaUtils, StringUtils, useImage } from '@premagic/utils';
import { useInView } from 'react-intersection-observer';

import { motion } from 'framer-motion';
import { Color, COLOR_SHADES } from '../Color/Color';
import { Icon, ICON_SIZES } from '../Icon/Icons';
import styles from './avatar.module.css';
import { Tooltip } from '../Tooltip/Tooltip';
import { Button, BUTTON_STYLES } from '../Button/Buttons';
import { Dialog, DIALOG_PLACEMENT } from '../Dialog';
import { LoadingDots, LoadingSpin } from '../Loading/Loading';
import { Position, Row } from '../Grid';

export const BACKGROUND_COLORS = [
  'F7F9FC',
  'EEEDFD',
  'FFEBEE',
  'FDEFE2',
  'E7F9F3',
  'EDEEFD',
  'ECFAFE',
  'F2FFD1',
  'FFF7E0',
  'FDF1F7',
  'EAEFE6',
  'E0E6EB',
  'E4E2F3',
  'E6DFEC',
  'E2F4E8',
  'E6EBEF',
  'EBE6EF',
  'E8DEF6',
  'D8E8F3',
  'ECE1FE',
];

export const TEXT_COLORS = [
  '060A23',
  '4409B9',
  'BD0F2C',
  'C56511',
  '216E55',
  '05128A',
  '1F84A3',
  '526E0C',
  '935F10',
  '973562',
  '69785E',
  '2D3A46',
  '280F6D',
  '37364F',
  '363548',
  '4D176E',
  'AB133E',
  '420790',
  '222A54',
  '192251',
];

function AvatarZero() {
  return (
    <svg fill="none" viewBox="0 0 24 24">
      <path
        fill="#E24B6F"
        d="M24.05 12.007a11.98 11.98 0 01-3.052 7.977 12.003 12.003 0 01-15.873 1.802 11.975 11.975 0 01-3.596-15.55A11.996 11.996 0 018.04.701a12.01 12.01 0 018.546.2 11.993 11.993 0 017.465 11.106z"
      />
      <path
        fill="#BA3152"
        d="M24.05 12.007c0 2.74-.94 5.397-2.66 7.53a12.021 12.021 0 01-6.8 4.199L10.57 11.219a5.511 5.511 0 01.478-4.442 5.521 5.521 0 013.096-2.494l5.329-1.707a11.99 11.99 0 014.578 9.432h-.002z"
      />
      <path
        fill="#FD6A8C"
        d="M24.05 12.007a12.004 12.004 0 01-4.603 9.442 12.034 12.034 0 01-10.276 2.22L6.837 16.4a4.321 4.321 0 012.801-5.44l.794-.255L22.83 6.732a11.932 11.932 0 011.22 5.275z"
      />
    </svg>
  );
}
type AvatarImageProps = {
  src: string;
  alt: string;
};
function AvatarImage(props: AvatarImageProps) {
  const { src, alt } = props;
  const { src: loadedImage, hasError, isLoading } = useImage(src);
  if (hasError || isLoading) return null;
  return <img src={loadedImage} alt={alt} title={alt} className={ClassNames(styles.avatar__image)} />;
}

export enum AVATAR_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
export enum AVATAR_STYLES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

type AvatarType = {
  size?: AVATAR_SIZES;
  style?: AVATAR_STYLES;
  user?: {
    fullName: string;
    email?: string;
    profilePic?: string;
  };
  dialogContent?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  active?: boolean;
  fromTheme?: boolean;
  bordered?: boolean;
  isLoading?: boolean;
  dotColor?: COLOR_SHADES;
};

export function Avatar(props: AvatarType): JSX.Element {
  const {
    dialogContent,
    size = AVATAR_SIZES.SM,
    user,
    className,
    onClick,
    active,
    style = AVATAR_STYLES.PRIMARY,
    fromTheme,
    bordered,
    isLoading,
    dotColor,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const target = useRef(null);

  const [inViewRef, inView] = useInView({
    threshold: 0,
  });
  if (!user)
    return (
      <div className={ClassNames(className, styles.avatar, styles[`avatar--size-${size}`])}>
        <AvatarZero />
      </div>
    );

  const { profilePic, fullName, email } = user;
  const gravatarUrl = email ? MediaUtils.getGravatarImageForEmail(email, size === AVATAR_SIZES.SM ? 32 : 64) : '';
  const pictureToShow = profilePic || gravatarUrl;

  const avatarClasses = ClassNames(
    className,
    styles.avatar,
    styles['avatar--style-primary'],
    styles[`avatar--size-${size}`],
    styles[`avatar--style-${style}`],
    {
      [styles['avatar--clickable']]: onClick,
      [styles[`avatar--${style}-active`]]: active,
      [styles['from-theme']]: fromTheme,
      [styles['avatar--bordered']]: bordered,
    },
  );
  const randomKey = MathUtils.getRandomIndexFromListOfItemsWithAConst(BACKGROUND_COLORS, fullName);
  const cssStyle = {
    ['--avatar-background-color' as string]: `#${BACKGROUND_COLORS[randomKey]}`,
    ['--avatar-text-color' as string]: `#${TEXT_COLORS[randomKey]}`,
  };

  if (dialogContent) {
    return (
      <Button
        ref={target}
        style={BUTTON_STYLES.RESET}
        cssStyle={cssStyle}
        className={avatarClasses}
        onClick={onClick}
        onMouseEnter={() => {
          setTimeout(() => {
            setShowDialog(true);
          }, 150);
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setShowDialog(false);
          }, 300);
        }}
        isLoading={isLoading}
      >
        {inView && pictureToShow && <AvatarImage key={fullName} src={pictureToShow} alt={fullName} />}
        <div ref={inViewRef} className={ClassNames(styles.avatar__text)}>
          {StringUtils.getSortNameFromFullName(fullName)}
        </div>
        <Dialog
          hasPadding={false}
          target={target.current}
          show={showDialog}
          onClose={() => setShowDialog(false)}
          placement={DIALOG_PLACEMENT.AUTO}
        >
          {dialogContent}
        </Dialog>
      </Button>
    );
  }
  const ElementType = onClick ? 'button' : 'span';
  return (
    <ElementType type="button" className={avatarClasses} style={cssStyle} onClick={onClick} ref={inViewRef}>
      <Tooltip message={fullName} placement="left">
        {isLoading ? (
          <Row vcenter center>
            <LoadingSpin />
          </Row>
        ) : (
          <>
            {inView && pictureToShow && <AvatarImage src={pictureToShow} alt={fullName} />}
            <div className={ClassNames(styles.avatar__text)}>{StringUtils.getSortNameFromFullName(fullName)}</div>
          </>
        )}
      </Tooltip>
      {dotColor && (
        <Position align="bottom-right" offsetY={1}>
          <Color inline shade={COLOR_SHADES.DANGER_DARK} fillSolidIcon>
            <Icon name="circle" size={ICON_SIZES.SM_XS} />
          </Color>
        </Position>
      )}
    </ElementType>
  );
}

type AvatarGroupProps = {
  children: React.ReactNode;
  size?: AVATAR_SIZES;
};

export function AvatarGroup(props: AvatarGroupProps): JSX.Element {
  const { children, size = AVATAR_SIZES.XS } = props;
  return <div className={ClassNames(styles['avatar-group'], styles[`avatar-group--size-${size}`])}>{children}</div>;
}

const avatarAnimation = {
  enter: (active) => ({
    opacity: 0,
  }),
  active: (active) => ({
    scale: active ? 1.3 : 1,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 6,
      stiffness: 100,
    },
  }),
  // This wont apply as we dont have AnimatePresence
  exit: (active) => ({
    opacity: 0,
  }),
};
type AvatarGroupItemProps = {
  children: React.ReactNode;
  id: string;
  active: boolean;
};

export function AvatarGroupItem(props: AvatarGroupItemProps): JSX.Element {
  const { children, id, active } = props;
  return (
    <motion.div
      className={ClassNames(styles['avatar-group__item'])}
      id={id}
      layoutId={id}
      key={id}
      variants={avatarAnimation}
      custom={active}
      initial="enter"
      animate="active"
      exit="exit"
      transition={{ duration: 0.3, delay: 0 }}
    >
      {children}
    </motion.div>
  );
}
