import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  CardContent,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Space,
} from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import EditClientWebsiteMainOccasionPanelContainer from './EditClientWebsiteMainOccasionPanelContainer';
import ClientWebsiteDetailsValueItem from '../details/ClientWebsiteDetailsValueItem';

type Props = {
  projectId: string;
  id: string;
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  showEditOfMainOccasionPanel: () => void;
};

export default function ClientWebsiteOccasionsLayout(props: Props): React.ReactElement {
  const { id, projectId, clientWebsite, showEditOfMainOccasionPanel } = props;

  return (
    <Card>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Occasions</CardHeaderTitle>
          <CardHeaderActions>
            <Button style={BUTTON_STYLES.LINK} onClick={showEditOfMainOccasionPanel}>
              Edit
            </Button>
          </CardHeaderActions>
        </CardHeader>
        <CardContent>
          <ClientWebsiteDetailsValueItem
            itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_INSTRUCTIONS}
            clientWebsite={clientWebsite}
          />
          <ClientWebsiteDetailsValueItem
            itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_CONTACT_PHONE}
            clientWebsite={clientWebsite}
          />
        </CardContent>
      </Space>
      <EditClientWebsiteMainOccasionPanelContainer id={id} projectId={projectId} />
    </Card>
  );
}
