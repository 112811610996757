import { Card, HTML_ICON, List, Row, Space, SubTitle, Text, TEXT_SIZE } from '@premagic/myne';
import { EventSponsorsService } from '@premagic/core';

import { StringUtils } from '@premagic/utils';
import EventSponsorItemContainer from './EventSponsorItemContainer';

type Props = {
  sponsorIds: Array<string>;
  projectId: string;
  category: EventSponsorsService.EventSponsorCategoryType;
};

export default function EventSponsorsList(props: Props): JSX.Element | null {
  const { projectId, sponsorIds, category } = props;
  if (sponsorIds.length === 0) return null;

  return (
    <div>
      <Row vcenter>
        <SubTitle>{category.name}</SubTitle>

        {sponsorIds.length && (
          <Text size={TEXT_SIZE.SIZE_5} muted>
            <Space size={1} />
            {HTML_ICON.DOT}
            <Space size={1} />
            {sponsorIds.length}
            <Space size={1} />
            {StringUtils.pluralize('sponsor', sponsorIds.length)}
          </Text>
        )}
      </Row>
      <Space vertical size={1} />
      <Card>
        {sponsorIds.length === 0 && (
          <Space>
            <Text muted block>
              No sponsors in {category.name}
            </Text>
          </Space>
        )}

        <List>
          {sponsorIds.map((id) => (
            <EventSponsorItemContainer id={id} projectId={projectId} key={id} />
          ))}
        </List>
      </Card>
      <Space vertical />
    </div>
  );
}
