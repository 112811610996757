import { ClientSettingsService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  Form,
  FormGroup,
  FormResponseType,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  LoadingBox,
  RealCheckbox,
  Row,
  Space,
  Text,
} from '@premagic/myne';

import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: FormResponseType) => void;
  errors?: Record<string, string>;
};

export default function ExperimentsPreferencePage(props: Props): JSX.Element {
  const { savePreferences, isSaving, errors, isLoading, clientSettings } = props;
  const {
    disable_signature_album_upsell: disableSignatureAlbumUpsell,
    disable_selection_album_upsell: disableSelectionAlbumUpsell,
    disable_web_upload_photos_limit: disableWebUploadPhotosLimit,
    enable_beta_watermark: enableBetaWatermark,
    disabled_upsell_on_download_page: disabledUpsellOnDownloadPage,
    guest_gallery_day_views: guestGalleryDayViews,
    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES]: enableAIAlbumWithAllFaces,
    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_NETWORKING_PEOPLE_LIST]: enableNetworkingPeopleList,
    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_CONTEXT_MENU_IN_FULL_VIEW_GALLERY]:
      enableContextMenuInFullGalleryView,
    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.MARKETING_GENERATE_LEAD]: marketingGenerateLead,
  } = clientSettings;
  return (
    <InnerPage>
      <InnerPageHeader iconName="git_pull_request" title="Experimental features" backTo={APP_URLS.SETTINGS.INDEX}>
        Experimental features
      </InnerPageHeader>
      <InnerPageContent>
        <Card>
          <Space>
            <LoadingBox isLoading={isLoading}>
              <Col size={8}>
                <Form
                  onSubmit={savePreferences}
                  errors={errors}
                  formSpec={{
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DISABLE_SIGNATURE_ALBUM_UPSELL]: {
                      type: 'boolean',
                    },
                    disable_web_upload_photos_limit: {
                      type: 'boolean',
                    },
                    enable_web_upload_photos_compress: {
                      type: 'boolean',
                    },
                    disable_selection_album_upsell: {
                      type: 'boolean',
                    },
                    enable_beta_watermark: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SIGNATURE_IMAGE_BACKGROUND_COLOR]: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DISABLED_UPSELL_ON_DOWNLOAD_PAGE]: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_DAY_VIEWS]: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES]: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_NETWORKING_PEOPLE_LIST]: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_CONTEXT_MENU_IN_FULL_VIEW_GALLERY]: {
                      type: 'boolean',
                    },
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.MARKETING_GENERATE_LEAD]: {
                      type: 'boolean',
                    },
                  }}
                >
                  <Row>
                    <Col>
                      <Text color={COLOR_SHADES.DARKER}>Features</Text>
                      <FormGroup>
                        <RealCheckbox
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SIGNATURE_IMAGE_BACKGROUND_COLOR}
                          defaultChecked={
                            clientSettings[
                              ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SIGNATURE_IMAGE_BACKGROUND_COLOR
                            ]
                          }
                        >
                          Enable color signature background
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name="disable_signature_album_upsell"
                          defaultChecked={disableSignatureAlbumUpsell}
                        >
                          Disable Signature album upsell
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name="disable_selection_album_upsell"
                          defaultChecked={disableSelectionAlbumUpsell}
                        >
                          Disable Selection album upsell
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DISABLED_UPSELL_ON_DOWNLOAD_PAGE}
                          defaultChecked={disabledUpsellOnDownloadPage}
                        >
                          Disable album upsell on download page
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox name="enable_beta_watermark" defaultChecked={enableBetaWatermark}>
                          Enable Watermark (only for photographer)
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES}
                          defaultChecked={enableAIAlbumWithAllFaces}
                        >
                          AI Album with all the faces including unregistered faces
                        </RealCheckbox>
                      </FormGroup>
                      <Space vertical size={4} />
                    </Col>
                    <Col>
                      <Text color={COLOR_SHADES.DARKER}>Misc settings</Text>
                      <FormGroup>
                        <RealCheckbox
                          name="disable_web_upload_photos_limit"
                          defaultChecked={disableWebUploadPhotosLimit}
                        >
                          Disable web upload photos limit
                        </RealCheckbox>
                      </FormGroup>

                      <FormGroup>
                        <RealCheckbox
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_DAY_VIEWS}
                          defaultChecked={guestGalleryDayViews}
                        >
                          Enable Guest gallery day view
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_NETWORKING_PEOPLE_LIST}
                          defaultChecked={enableNetworkingPeopleList}
                        >
                          Enable Networking people list
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name={
                            ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_CONTEXT_MENU_IN_FULL_VIEW_GALLERY
                          }
                          defaultChecked={enableContextMenuInFullGalleryView}
                        >
                          Enable context menu in full view gallery
                        </RealCheckbox>
                      </FormGroup>
                      <FormGroup>
                        <RealCheckbox
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.MARKETING_GENERATE_LEAD}
                          defaultChecked={marketingGenerateLead}
                        >
                          Enable marketing generate leads
                        </RealCheckbox>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Space vertical size={16} />
                  <Button style={BUTTON_STYLES.CANCEL} link="/settings">
                    Cancel
                  </Button>
                  <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                    Save
                  </Button>
                </Form>
              </Col>
            </LoadingBox>
          </Space>
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
