import {
  Card,
  Col,
  Color,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  Label,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { CurrencyUtils, NumberUtils } from '@premagic/utils';
import { EVENT_REPORT_WIDGET_DETAILS, EVENT_REPORT_WIDGET_TYPE, EVENT_REPORT_WIDGETS } from './EventReportService';

function getValueToDisplay(type: EVENT_REPORT_WIDGET_TYPE, value: number) {
  if (!value) return HTML_ICON.DASH_SMALL;
  switch (type) {
    case EVENT_REPORT_WIDGET_TYPE.PERCENTAGE:
      return NumberUtils.getNumberInPercentageFormat(value);
    case EVENT_REPORT_WIDGET_TYPE.CURRENCY:
      return CurrencyUtils.getCurrencyInFormat(value, 'USD', false);
    default:
      return NumberUtils.getNumberInFormat(value);
  }
}

function getWidgetColor(type: EVENT_REPORT_WIDGET_TYPE, value: number): COLOR_SHADES {
  if (!value) return COLOR_SHADES.LIGHTER;

  if (type === EVENT_REPORT_WIDGET_TYPE.CURRENCY) {
    if (value >= 0) return COLOR_SHADES.GREEN_DARKER;
    return COLOR_SHADES.DANGER;
  }

  return COLOR_SHADES.DARKER;
}

function EventReportWidgetValue(props: {
  value: {
    primary: number;
    secondary?: number;
    tertiary?: number;
  };
  id: EVENT_REPORT_WIDGETS;
}) {
  const { value, id } = props;
  const { primary, secondary, tertiary } = value || {
    primary: 0,
    secondary: 0,
    tertiary: 0,
  };
  const widgetDetails = EVENT_REPORT_WIDGET_DETAILS[id];
  if (widgetDetails.comingSoon) {
    return (
      <Text
        size={TEXT_SIZE.SIZE_2}
        boldness={TEXT_BOLDNESS.BOLD}
        color={getWidgetColor(widgetDetails.type, primary)}
        block
      >
        <Label>COMING SOON</Label>
        <Space vertical size={4} />
      </Text>
    );
  }

  return (
    <div>
      <Text
        size={TEXT_SIZE.SIZE_2}
        boldness={TEXT_BOLDNESS.BOLD}
        color={getWidgetColor(widgetDetails.type, primary)}
        block
      >
        {getValueToDisplay(widgetDetails.type, primary)}
      </Text>
      {secondary && tertiary ? (
        <Text block size={TEXT_SIZE.SIZE_5}>
          {secondary} of {tertiary}
        </Text>
      ) : (
        <Space vertical size={4} />
      )}
    </div>
  );
}

type Props = {
  isLoading: boolean;
  value: {
    primary: number;
    secondary?: number;
    tertiary?: number;
  };
  id: EVENT_REPORT_WIDGETS;
};
export default function EventReportWidget(props: Props): JSX.Element {
  const { isLoading, id, value } = props;
  const widgetDetails = EVENT_REPORT_WIDGET_DETAILS[id];
  const { title, info } = widgetDetails;
  return (
    <Card>
      <Space>
        <Tooltip message={info}>
          <Row vcenter>
            <Text color={COLOR_SHADES.DARKER} block>
              {title}
            </Text>
            <Space size={1} />
            {info && (
              <Col rightAlighed size={null}>
                <Color inline shade={COLOR_SHADES.LIGHTER} fillSolidIcon>
                  <Icon name="info_solid" size={ICON_SIZES.SM} />
                </Color>
              </Col>
            )}
          </Row>
        </Tooltip>
        <Space vertical size={2} />
        {isLoading ? (
          <div>
            <Space vertical size={6} />
            <LoadingDots size="xs" align="left" />
            <Space vertical size={4} />
          </div>
        ) : (
          <EventReportWidgetValue value={value} id={id} />
        )}
      </Space>
    </Card>
  );
}
