// import { FolderFilePositionOrderService } from '@premagic/core';
import { HttpUtils, PhotoWatermarkUtils } from '@premagic/utils';
import { API_URLS } from '../APIURLService';
import { FolderFileType } from '../FileService';
import { FOLDER_FILE_ORDER_SORTED_BY_VALUES } from '../FolderFilePositionOrderService';
import { FONT_IDS } from '../FontService';
import { QRCodeDesignType } from '../QRCodeBackgroundPosterService';

export enum FOLDER_META_TYPES {
  PRIMARY_FONT = 'primary_font',
  SECONDARY_FONT = 'secondary_font',
  CURRENCY_CODE = 'currency_code',
  SELECTION_FILES_ORDER = 'selection_files_order',
  HIGHLIGHT_FILES_ORDER = 'highlight_files_order',
  DISABLE_WISHES = 'disable_wishes',
  COVER_IMAGE_FOCAL_POINT = 'cover_image_focal_point',
  COVER_IMAGE = 'cover_image',
  BLOCK_FOLDER = 'block_folder',
  DIGITAL_INVITE_WISHES_REVIEW_REQUIRED = 'digital_invite__wishes_review_required',
  QR_CODE_DESIGNS = 'qr_code_designs',
  I_AM_ATTENDING_POSTER_DESIGNS = 'i_am_attending_poster_designs',
  EVENT_ATTENDEE_BADGE_DESIGNS = 'event_attendee_badge_designs',
  IS_FOLDER_PUBLIC = 'is_folder_public',
}

export const FOLDER_META_TOGGLE_ITEMS_DETAILS = {
  [FOLDER_META_TYPES.DISABLE_WISHES]: {
    key: FOLDER_META_TYPES.DISABLE_WISHES,
    label: 'Allow guests to add wishes',
    icon: 'mail',
  },
  [FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED]: {
    key: FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED,
    label: 'Show wishes only after approval',
    icon: 'user_check',
  },
};

export type FolderMetaType = {
  [FOLDER_META_TYPES.PRIMARY_FONT]: FONT_IDS;
  [FOLDER_META_TYPES.SECONDARY_FONT]: FONT_IDS;
  [FOLDER_META_TYPES.CURRENCY_CODE]?: string;
  [FOLDER_META_TYPES.SELECTION_FILES_ORDER]?: FOLDER_FILE_ORDER_SORTED_BY_VALUES;
  [FOLDER_META_TYPES.HIGHLIGHT_FILES_ORDER]?: FOLDER_FILE_ORDER_SORTED_BY_VALUES;
  [FOLDER_META_TYPES.DISABLE_WISHES]?: boolean;
  [FOLDER_META_TYPES.COVER_IMAGE_FOCAL_POINT]?: { x: number; y: number };
  [FOLDER_META_TYPES.COVER_IMAGE]?: FolderFileType;
  [FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED]?: boolean;
  [FOLDER_META_TYPES.BLOCK_FOLDER]?: boolean;
  [FOLDER_META_TYPES.QR_CODE_DESIGNS]?: QRCodeDesignType;
  [FOLDER_META_TYPES.IS_FOLDER_PUBLIC]?: boolean;
};

export function uploadFolderCoverPhoto(projectId: string, folderId: string, coverPhoto: FormData): Promise<any> {
  const URL = API_URLS.FOLDER.UPDATE_COVER_PHOTO(projectId, folderId);

  return HttpUtils.post(URL, coverPhoto).then((response) => response.data);
}

export function fetchFolderMeta(projectId: string, folderId: string): Promise<FolderMetaType> {
  const url = API_URLS.FOLDER.META(projectId, folderId);
  return HttpUtils.get(url)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function updateFolderMeta(projectId: string, folderId: string, data): Promise<FolderMetaType> {
  const url = API_URLS.FOLDER.META(projectId, folderId);
  return HttpUtils.post(url, data)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}
