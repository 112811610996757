import { connect } from 'react-redux';
import AccountFinancialsMetrics from './AccountFinancialsMetrics';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import {
  accountFinancialsPaymentMetricSelector,
  fetchAccountFinancialMetric,
} from '../AccountFinancialsPaymentsDataDuck';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';
import { isMobileUp } from '../../AppDuck';

function mapStateToProps(state, ownProps: { filters: string; type: ACCOUNT_FINANCIALS_TYPES }) {
  const { filters, type } = ownProps;
  return {
    filters,
    type,
    data: accountFinancialsPaymentMetricSelector(state),
    isLoading: isLoadingSelector(LOADINGS.ACCOUNT_FINANCIALS.COUNT)(state),
    isMobileUp: isMobileUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (filters) => {
      dispatch(fetchAccountFinancialMetric(dispatch, filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinancialsMetrics);
