import { combineReducers } from 'redux';

import * as AlertDuck from './lib/ducks/AlertDuck';
import * as DeviceDuck from './lib/ducks/DeviceDuck';
import * as ModalDuck from './lib/ducks/ModalDuck';
import * as WindowPanelDuck from './lib/ducks/WindowPanelDuck';
import * as LoadingDuck from './lib/ducks/LoadingDuck';
import * as ErrorDuck from './lib/ducks/ErrorDuck';
import * as UploaderDuck from './lib/ducks/UploaderDuck';
import * as AuthDuck from './lib/ducks/AuthDuck';
import * as ToastDuck from './lib/ducks/ToastDuck';

const CommonReducer = combineReducers({
  alert: AlertDuck.AlertReducer,
  modal: ModalDuck.ModalReducer,
  windowPanel: WindowPanelDuck.WindowPanelReducer,
  loading: LoadingDuck.LoadingReducer,
  errors: ErrorDuck.ErrorReducer,
  fileUploader: UploaderDuck.UploaderReducer,
  device: DeviceDuck.DeviceReducer,
  auth: AuthDuck.AuthDuckReducer,
  toast: ToastDuck.ToastReducer,
});

export {
  CommonReducer,
  AlertDuck,
  ErrorDuck,
  ModalDuck,
  WindowPanelDuck,
  LoadingDuck,
  UploaderDuck,
  DeviceDuck,
  AuthDuck,
  ToastDuck,
};
