import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { PERMISSIONS } from '../crm/users/UserPermissionService';
import { hasPermission } from '../crm/users/UsersDataDuck';
import {
  avatarStudioLinkSelector,
  avatarStudioPrintLinkSelector,
  eventProjectSelectorSelector,
} from '../projects/AccountProjectsDataDuck';
import { isFeatureAvatarStudioEnabledSelector } from '../projects/project-meta/ProjectsMetaDataDuck';

import AvatarStudioLayout from './AvatarStudioLayout';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    projectId,
    focusId,
    hasPermissions: {
      [PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT)(state),
    },
    isFeatureAvatarStudioEnabled: isFeatureAvatarStudioEnabledSelector(projectId)(state),
    avatarStudioLink: projectId ? avatarStudioLinkSelector(projectId)(state) : undefined,
    avatarStudioPrintLink: projectId ? avatarStudioPrintLinkSelector(projectId)(state) : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddNewTemplate: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarStudioLayout);
