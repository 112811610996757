import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  DialogTitle,
  FormResponseType,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import { UserType } from '../UsersService';

type Props = {
  userId: string;
  isLoading: boolean;
  user: UserType;
  hideDialog: () => void;
  toggleActivate: (userId: string, formData: FormResponseType) => void;
};

export default function UserActivateDialog(props: Props): React.ReactElement {
  const { isLoading, user, hideDialog, toggleActivate, userId } = props;
  const { name, active } = user;

  function handleOnToggle() {
    toggleActivate(userId, {
      data: {
        active: !active,
      },
    });
  }

  return (
    <Dialog
      target={getElementFromSelector(`${JS_SELECTORS.USER_MORE_OPTIONS}${userId}`)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={hideDialog}
    >
      {active ? (
        <>
          <DialogTitle>De-activate {name}?</DialogTitle>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Deactivating the user will</Text>
          <ul>
            <li>
              <Text>Remove all the access to your account.</Text>
            </li>
            <li>
              <Text>User will not be able to login to your account.</Text>
            </li>
            <li>
              <Text>All the data associated with the user will remain in your account.</Text>
            </li>
          </ul>
        </>
      ) : (
        <>
          <DialogTitle>Activate {name}?</DialogTitle>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Activating the user will,</Text>
          <ul>
            <li>
              <Text>Allow user to login to your account.</Text>
            </li>
            <li>
              <Text>Allow user access the information associated with the user.</Text>
            </li>
          </ul>
        </>
      )}

      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={hideDialog}>
          Cancel
        </Button>
        <Button
          style={active ? BUTTON_STYLES.DANGER : BUTTON_STYLES.PRIMARY}
          onClick={handleOnToggle}
          isLoading={isLoading}
        >
          {active ? 'De-activated' : 'Activate'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
