import { IntegrationService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import {
  getAllEventsForPlatform,
  integrationSortedEventsDataSelector,
  setIntegrationSelectedEvent,
} from '../IntegrationDataDuck';
import IntegrationEventSelectorList from './IntegrationEventSelectorList';
import { INTEGRATION_CONNECT_OPTION, INTEGRATION_STEP } from './IntegrationStepService';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';

function mapStateToProps(
  state,
  ownProps: {
    setIntegrationStep: (step: INTEGRATION_STEP) => void;
    closeModal: () => void;
    platform: IntegrationService.INTEGRATION_PLATFORMS;
    connectOption: INTEGRATION_CONNECT_OPTION;
  },
) {
  const { setIntegrationStep, platform, closeModal } = ownProps;

  return {
    setIntegrationStep,
    platform,
    events: integrationSortedEventsDataSelector(state),
    isLoading: isLoadingSelector(LOADINGS.INTEGRATION.FETCH_EVENTS)(state),
    closeModal,
    errors: errorObjectForTypeSelector(LOADINGS.INTEGRATION.FETCH_EVENTS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllEventsForPlatform: (platform) => {
      dispatch(getAllEventsForPlatform(dispatch, platform));
    },
    setIntegrationSelectedEvent: (eventId) => {
      dispatch(setIntegrationSelectedEvent(dispatch, eventId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationEventSelectorList);
