import { connect } from 'react-redux';

import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import PendingPhotographerActionsSummary from './PendingPhotographerActionsSummary';
import { fetchAllPhotographerActions, photographerActionsSizeSelector } from './PhotographerActionsDataDuck';

function mapStateToProps(state) {
  return {
    totalActions: photographerActionsSizeSelector(state),
    isLoading: isLoadingSelector(LOADINGS.PHOTOGRAPHER_ACTIONS.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingActions: () => dispatch(fetchAllPhotographerActions(dispatch, 'new')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingPhotographerActionsSummary);
