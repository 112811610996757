import { HightlightAlbumThemeService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { loadHighlightFont } from '../../projects/highlight-folders/highlight-gallery-design/HighlightDesignCustomizationPageDuck';
import { companyLogoSelector, hasFeatureSelector, photographerNameSelector } from '../company/CompanyDataDuck';
import { clientSettingsDataSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';
import GlobalSignatureAlbumDesignPage from './GlobalSignatureAlbumDesignPage';

function mapStateToProps(state) {
  return {
    clientSettings: clientSettingsDataSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.APP_LOADING)(state) || isLoadingSelector(LOADINGS.CLIENT_SETTINGS.FETCH_ALL)(state),
    companyLogo: companyLogoSelector(state),
    companyName: photographerNameSelector(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    isFontLoading: isLoadingSelector(LOADINGS.FONTS.UPDATE)(state),
    hasHighlightCustomizationFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
    isSaving: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
    initFontData: (fontFamily, secondaryFontFamily) => {
      dispatch(loadHighlightFont(dispatch, { primary_font: fontFamily, secondary_font: secondaryFontFamily }));
    },
    updateThemeVariables: (backgroundColor: string, fontColor: string, accentColor: string) => {
      HightlightAlbumThemeService.updateThemeVariable({
        color: {
          background: backgroundColor,
          font_color: fontColor,
          accent_color: accentColor,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSignatureAlbumDesignPage);
