import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { clientSettingsDataSelector } from '../../preferences/ClientSettingsDataDuck';
import { hasFeatureSelector } from '../../company/CompanyDataDuck';
import HighlightUploadSettings from './HighlightUploadSettings';

function mapStateToProps(state) {
  return {
    clientSettings: clientSettingsDataSelector(state),
    enable4KQuality: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.QUALITY_4K)(state),
    enableOriginalResolution: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ORIGINAL_RESOLUTION)(
      state,
    ),
    enableOriginalBloatedFile: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ORIGINAL_BLOATED_FILE,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightUploadSettings);
