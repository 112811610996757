import { connect } from 'react-redux';
import FolderImagesPopup from './FolderImagesPopup';
import { filesSelector } from '../../../images/FilesDataDuck';
import { navigateTo } from '../../../../../../services/RouterService';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  deleteFileFromFolder,
  filesNavigationDataForFolderSelector,
  foldersEntitiesSelector,
} from '../AccountFoldersDataDuck';

type OwnPropsType = {
  match: {
    params: {
      folderId: string;
      projectId: string;
      id: string;
      view: 'approved' | 'all' | 'rejected';
    };
  };
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  const { projectId, folderId, id, view } = ownProps.match.params;
  const file = filesSelector(state)[id];
  const { previousFileId, nextFileId, totalFiles } = filesNavigationDataForFolderSelector(folderId, id)(state);
  return {
    id,
    file,
    projectId,
    folderId,
    folder: foldersEntitiesSelector(state)[folderId] || {},
    view,
    nextFileId,
    previousFileId,
    totalFiles,
    isDeleting: isLoadingSelector(LOADINGS.FILE.DELETE)(state),
  };
}

function mapDispatchToProps(dispatch: (...args: Array<any>) => any) {
  return {
    navigate: (url) => {
      navigateTo(dispatch, url);
    },
    deleteFile: (projectId, folderId, fileId: string, folderType, navigateToUrl) => {
      dispatch(deleteFileFromFolder(dispatch, { projectId, folderId, fileId, navigateToUrl, folderType }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderImagesPopup);
