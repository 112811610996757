import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  CARD_STYLES,
  Col,
  ColorPicker,
  DECK_SLIDE_DATA_TYPE_DETAILS,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideBackgroundColorDataType,
  DeckSlideBackgroundDataType,
  DeckSlideDataType,
  Icon,
  ICON_SIZES,
  INPUT_TYPES,
  PillItem,
  Pills,
  PILLS_STYLES,
  Row,
  Space,
  SubTitle,
  Card,
  Range,
  RANGE_SIZES,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Divider,
} from '@premagic/myne';
import { omit } from 'lodash';
import { ErrorTracker } from '@premagic/utils';
import { ProposalSlideService } from '@premagic/proposals';
import SlideBackgroundColorUpdater from './SlideBackgroundColorUpdater';

type SlideInputProps = {
  datatype: DECK_SLIDE_DATA_TYPES;
  value: DeckSlideDataType[DECK_SLIDE_DATA_TYPES];
  colorValue?: DeckSlideDataType[DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR];
  tintOpacityValue?: DeckSlideDataType[DECK_SLIDE_DATA_TYPES.COLOR_TINT_OPACITY];
  onChange: (datatype: DECK_SLIDE_DATA_TYPES, value: any) => void;
  onRemove: (datatype: DECK_SLIDE_DATA_TYPES) => void;
  showFilePicker: (options: { datatype: DECK_SLIDE_DATA_TYPES; value?: DeckSlideBackgroundDataType }) => void;
};

function SlideInput(props: SlideInputProps) {
  const { datatype, value, onChange, onRemove, showFilePicker, colorValue, tintOpacityValue } = props;
  const datatypeDetails = DECK_SLIDE_DATA_TYPE_DETAILS[datatype];

  if (datatypeDetails.inputType === INPUT_TYPES.FILE) {
    const fileType = datatype === DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO ? 'video' : 'photo';
    return (
      <>
        <Row>
          <Button
            size={BUTTON_SIZE.SM}
            onClick={() => showFilePicker({ datatype, value: value as DeckSlideBackgroundDataType })}
          >
            {value ? 'Change' : 'Select'} {fileType}
          </Button>
          <Space />
          {value && (
            <Button size={BUTTON_SIZE.SM} style={BUTTON_STYLES.LINK} onClick={() => onRemove(datatype)}>
              Remove {fileType}
            </Button>
          )}
          <Space size={2} />
        </Row>

        {value && (
          <>
            <Space vertical size={3} />
            <Divider />
            <Space vertical size={3} />
            <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5} muted>
              COLOR TINT
            </Text>
            <Space vertical size={2} />
            <Row columnDirection>
              <SlideBackgroundColorUpdater
                value={colorValue as DeckSlideBackgroundColorDataType}
                onChange={(color) => onChange(DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR, color)}
              />
              <Space vertical size={3} />
              <Row vcenter>
                <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} size={TEXT_SIZE.SIZE_5}>
                  Intensity
                </Text>
                <Space size={6} />
                <Range
                  defaultValue={tintOpacityValue?.value || 0}
                  name="proposal_color_tint_opacity"
                  onChange={(opacity) => onChange(DECK_SLIDE_DATA_TYPES.COLOR_TINT_OPACITY, opacity)}
                  min={0}
                  max={1}
                  step={0.01}
                  hideText
                  size={RANGE_SIZES.SM}
                />
              </Row>
            </Row>
          </>
        )}
      </>
    );
  }

  return (
    <SlideBackgroundColorUpdater
      value={value as DeckSlideBackgroundColorDataType}
      onChange={(color) => onChange(DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR, color)}
    />
  );
}

function getActiveBackground(data?: DeckSlideDataType): DECK_SLIDE_DATA_TYPES {
  if (!data) return DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR;

  const item = [
    DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO,
    DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE,
    DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR,
  ].find((type) => data[type]?.value);

  if (item) return item;

  return DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR;
}

type BackGroundTypeSelectorProps = {
  setSelectedDataType: (datatype: DECK_SLIDE_DATA_TYPES) => void;
  selectedDataType: DECK_SLIDE_DATA_TYPES;
};

function BackGroundTypeSelector(props: BackGroundTypeSelectorProps) {
  const { selectedDataType, setSelectedDataType } = props;
  const inputTypes = [
    DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR,
    DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE,
    DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO,
  ];

  return (
    <Pills style={PILLS_STYLES.SECONDARY}>
      {inputTypes.map((item) => (
        <PillItem key={item} active={selectedDataType === item} onClick={() => setSelectedDataType(item)}>
          {DECK_SLIDE_DATA_TYPE_DETAILS[item].title}
        </PillItem>
      ))}
    </Pills>
  );
}

type Props = {
  slideDetails?: ProposalSlideService.ProposalSlideType;
  projectId: string;
  deckId: string;
  saveProposalSlide: (options: { projectId: string; deckId: string; id: number }, ProposalSlideType) => void;
  showSelectFileModal: (options: { datatype: DECK_SLIDE_DATA_TYPES; value?: DeckSlideBackgroundDataType }) => void;
};

export default function ProposalCreatorSlideBackgroundDataUpdater(props: Props): JSX.Element | null {
  const { saveProposalSlide, projectId, deckId, showSelectFileModal, slideDetails } = props;
  const [expand, setExpand] = useState(true);

  const [selectedDataType, setSelectedDataType] = useState(getActiveBackground(slideDetails?.structure));

  const handleOnRemove = useCallback(
    (type: DECK_SLIDE_DATA_TYPES) => {
      if (!slideDetails) return;
      const newStructure = omit(slideDetails.structure, type);
      saveProposalSlide(
        { projectId, deckId, id: slideDetails.id },
        {
          structure: newStructure,
        },
      );
    },
    [slideDetails?.id],
  );

  const handleOnChange = useCallback(
    (type: DECK_SLIDE_DATA_TYPES, value: string) => {
      if (!slideDetails) return ErrorTracker.logError('Deck slide:', new Error('No slide details'));

      const newStructure = Object.assign(slideDetails.structure, {
        [type]: { value },
      });
      saveProposalSlide(
        { projectId, deckId, id: slideDetails.id },
        {
          structure: newStructure,
        },
      );
    },
    [slideDetails?.id, slideDetails?.structure],
  );

  if (!slideDetails) return null;
  const { structure } = slideDetails;

  return (
    <Space>
      <Button style={BUTTON_STYLES.RESET} onClick={() => setExpand(!expand)} block>
        <Row vcenter>
          <SubTitle>Background</SubTitle>
          <Col size={null} rightAlighed>
            <Icon name={expand ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
          </Col>
        </Row>
      </Button>
      {expand && (
        <div>
          <Space vertical size={2} />
          <BackGroundTypeSelector selectedDataType={selectedDataType} setSelectedDataType={setSelectedDataType} />
          <Space vertical size={2} />
          <Card style={CARD_STYLES.SECONDARY}>
            <Space>
              <SlideInput
                key={selectedDataType}
                value={structure[selectedDataType]}
                datatype={selectedDataType}
                showFilePicker={showSelectFileModal}
                onChange={(type, value) => handleOnChange(type, value)}
                onRemove={handleOnRemove}
                colorValue={structure[DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR]}
                tintOpacityValue={structure[DECK_SLIDE_DATA_TYPES.COLOR_TINT_OPACITY]}
              />
            </Space>
          </Card>
        </div>
      )}
    </Space>
  );
}
