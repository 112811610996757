import React, { createContext, useContext, useState } from 'react';

import ReactDOM from 'react-dom';
import ClassNames from 'classnames';

import { BUTTON_ICON_STYLES, BUTTON_ICONS_SIZES, ButtonIcon } from '../Button/Buttons';
import { COLOR_SHADES } from '../Color/Color';
import { ErrorBoundary } from '../Error/Error';
import { Icon, ICON_SIZES } from '../Icon/Icons';

import styles from './windowbox.module.css';
import { Text, TEXT_BOLDNESS } from '../Text/Text';
import { Col, Row } from '../Grid/Grid';

const WINDOW_BOX_MOUNT = 'window-box-root';

export enum WINDOW_BOX_SIZES {
  SM = 'sm',
  MD = 'md',
}

interface WindowBoxProps {
  children: React.ReactNode;
  size?: WINDOW_BOX_SIZES;
}

const WindowBoxContext = createContext({ expanded: true, toggleExpanded: () => {} });
export function WindowBox(props: WindowBoxProps): JSX.Element | null {
  const { children, size = WINDOW_BOX_SIZES.SM } = props;
  const $target = document.getElementById(WINDOW_BOX_MOUNT);
  const [expanded, setExpanded] = useState(true);

  if (!$target) return null;

  return ReactDOM.createPortal(
    <ErrorBoundary>
      <WindowBoxContext.Provider
        value={{
          expanded,
          toggleExpanded: () => setExpanded(!expanded),
        }}
      >
        <div className={ClassNames(styles['window-box'], styles[`window-box--size-${size}`])}>{children}</div>
      </WindowBoxContext.Provider>
    </ErrorBoundary>,
    $target,
  );
}

interface WindowBoxHeaderProps {
  children: React.ReactNode;
  onClose?: () => void;
}

export function WindowBoxHeader(props: WindowBoxHeaderProps) {
  const { children, onClose } = props;
  const { expanded, toggleExpanded } = useContext(WindowBoxContext);

  return (
    <div className={ClassNames(styles['window-box__header'])}>
      <Col size={10}>
        <Row vcenter>
          <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARK} block ellipsis>
            {children}
          </Text>
        </Row>
      </Col>
      <div className={ClassNames(styles['window-box__header__actions'])}>
        <ButtonIcon
          title={expanded ? 'Minimize' : 'Maximize'}
          onClick={toggleExpanded}
          tooltipPlacement="top"
          style={BUTTON_ICON_STYLES.SECONDARY}
        >
          <Icon name={expanded ? 'chevron_down' : 'chevron_up'} size={ICON_SIZES.SM} />
        </ButtonIcon>
        <ButtonIcon
          title="Close"
          onClick={onClose}
          tooltipPlacement="top"
          style={BUTTON_ICON_STYLES.SECONDARY}
          disabled={!onClose}
        >
          <Icon name="x" size={ICON_SIZES.SM} />
        </ButtonIcon>
      </div>
    </div>
  );
}

type WindowBoxContentProps = {
  children: React.ReactNode;
};

export function WindowBoxContent(props: WindowBoxContentProps) {
  const { children } = props;
  const { expanded } = useContext(WindowBoxContext);
  if (!expanded) return null;
  return <div className={ClassNames(styles['window-box__content'])}>{children}</div>;
}

type WindowBoxFooterProps = {
  children: React.ReactNode;
};

export function WindowBoxFooter(props: WindowBoxFooterProps) {
  const { children } = props;
  const { expanded } = useContext(WindowBoxContext);
  if (!expanded) return null;

  return <div className={ClassNames(styles['window-box__footer'])}>{children}</div>;
}
