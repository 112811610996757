import { PosterAdvocateService, SocialAuthService } from '@premagic/core';
import {
  Avatar,
  AVATAR_SIZES,
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  COLOR_SHADES,
  Dialog,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  ListItem,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  TimeAgo,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { useRef, useState } from 'react';

type Props = {
  advocate: PosterAdvocateService.PosterAdvocateType;
};

export default function PosterAdvocateListItem(props: Props): JSX.Element {
  const { advocate } = props;
  const [showDialog, setShowPostDialog] = useState(false);
  const $button = useRef(null);
  const {
    poster_image: posterImage,
    profile_picture: profilePic,
    name,
    email,
    id,
    post_url: postURL,
    platform,
    advocacy_count: count,
    created_at: createdAt,
    posted_at: postedAt,
  } = advocate;
  const postedDate = postedAt || createdAt;
  return (
    <ListItem key={id}>
      <Col size={4}>
        <Row>
          <Avatar
            size={AVATAR_SIZES.MD}
            user={{
              fullName: name,
              profilePic,
            }}
          />
          <Space />
          <div>
            <Text boldness={TEXT_BOLDNESS.BOLD} block ellipsis>
              {name}
            </Text>
            <ButtonIcon
              size={BUTTON_ICONS_SIZES.SM}
              link={`mailto:${email}?subject=Thank you`}
              title="Send a thank you email"
            >
              <Icon name="gmail_solid_color" size={ICON_SIZES.SM} />
            </ButtonIcon>
          </div>
        </Row>
      </Col>
      <Col size={1}>
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} block center>
          {count || 0}
        </Text>
      </Col>
      <Col size={null} rightAlighed>
        {postURL ? (
          <Row vcenter>
            <Text muted size={TEXT_SIZE.SIZE_5}>
              <TimeAgo date={SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(postedDate)} />
            </Text>
            <Space />
            <Button
              ref={$button}
              link={SocialAuthService.getPostUrlForNetwork(platform, postURL)}
              isExternalLink
              style={BUTTON_STYLES.RESET}
              onMouseEnter={() => {
                setShowPostDialog(true);
              }}
              onMouseLeave={() => {
                setShowPostDialog(false);
              }}
            >
              {posterImage?.dynamic_image_url ? (
                <ImageV2 src={posterImage?.dynamic_image_url} alt="post" style={IMAGE_V2_STYLES.THUMBNAIL_SM} />
              ) : (
                'Open post'
              )}
            </Button>
          </Row>
        ) : (
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
            Havnt posted yet
          </Text>
        )}
      </Col>
      {posterImage?.dynamic_image_url && $button.current && (
        <Dialog show={showDialog} target={$button.current} onClose={() => setShowPostDialog(false)} hasPadding={false}>
          <Row>
            <ImageV2 src={posterImage?.dynamic_image_url} alt="post" style={IMAGE_V2_STYLES.FULL_IMAGE} />
          </Row>
        </Dialog>
      )}
    </ListItem>
  );
}
