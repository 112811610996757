import { EventsService } from '@premagic/core';
import { PaginationFiltersType } from '../../../services/PaginationService';

export enum CRM_FILTER_KEYS {
  STATUS = 'status',
  EVENT_LABELS = 'labels',
  EVENT_STATUS = 'event_status',
  EVENT_SORT_BY = 'sort_by',
  START_DATE_GTE = 'start_date_gte',
  START_DATE_LTE = 'start_date_lte',
  END_DATE_GTE = 'end_date_gte',
  END_DATE_LTE = 'end_date_lte',
  PAYMENT_STATUS = 'is_settled',
}

export type CRMFiltersType = {
  [CRM_FILTER_KEYS.STATUS]?: EventsService.EVENT_STATUS;
  [CRM_FILTER_KEYS.EVENT_LABELS]?: Array<string>;
  [CRM_FILTER_KEYS.EVENT_STATUS]?: EventsService.EVENT_STATUS;
  [CRM_FILTER_KEYS.EVENT_SORT_BY]?: EventsService.EVENT_SORT_BY;
  [CRM_FILTER_KEYS.START_DATE_GTE]?: string;
  [CRM_FILTER_KEYS.START_DATE_LTE]?: string;
  [CRM_FILTER_KEYS.END_DATE_GTE]?: string;
  [CRM_FILTER_KEYS.END_DATE_LTE]?: string;
  [CRM_FILTER_KEYS.PAYMENT_STATUS]?: string;
} & PaginationFiltersType;
