import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { BrowserUrlUtils, ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { FormResponseType } from '@premagic/myne';
import { ClientWebsiteService, EventsService, EventTrackerService, LabelService, ProjectService } from '@premagic/core';
import { addLabelsData } from '../labels/LabelsDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { CRMEventSchema, LabelSchema } from '../../../../schema/Schemas';
import APP_URLS from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';
import { clientEntitiesSelector, fetchCRMClientData } from '../clients/ClientsDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { toastMessage } from '../../../reducers/ToastStore';
import { updateClientWebsiteData } from '../../client-website/ClientWebsiteDataDuck';
// import { updateProjectsData } from '../../projects/AccountProjectsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_EVENTS');

export const setEventsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addEventsData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
export const updateEventsData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
const updateLabelsForEvent = createAction(getActionType('EVENT_LABEL', 'UPDATE'), (dispatch, eventId, data) => ({
  eventId,
  labels: data,
}));

const removeLabelsForEvent = createAction(getActionType('EVENT_LABEL', 'REMOVE'), (dispatch, eventId, data) => ({
  eventId,
  labels: data,
}));

const removeEventData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, data) => data);

const LOADING_KEYS = LOADINGS.EVENT;

export const fetchEventDataWithDependencyData = createAction(
  getActionType('DATA', 'SINGLE_FETCH'),
  async (dispatch, eventId) => {
    const loadingKey = LOADING_KEYS.FETCH_SINGLE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    try {
      const event = await EventsService.fetchEvent(eventId);
      await fetchCRMClientData(dispatch, event.client);
      const normalizedData = normalize(event, CRMEventSchema);
      dispatch(addEventsData(dispatch, normalizedData.entities.events));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return event;
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Events: fetch failed', e);
      return e;
    }
  },
);

export const addEventsForClient = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch, clientId: string, formResponse: FormResponseType) => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_CREATE, { ...formResponse.data });

    const loadingKey = LOADING_KEYS.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventsService.createEvent(clientId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, CRMEventSchema);
        dispatch(addEventsData(dispatch, normalizedData.entities.events));
        // Navigate to Details and show the user to add a new occasion
        const eventDetailsOccasionPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
          eventId: response.id,
        });
        navigateTo(dispatch, eventDetailsOccasionPage);
        // Show add occasion panel
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_ADD_PANEL, true));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Events: fetch failed', e);
        return e;
      });
  },
);

export const updateEvents = createAction(
  getActionType('DATA', 'EDIT'),
  async (
    dispatch,
    options: { eventId: string; clientId: string; projectId?: string; websiteId?: string },
    formResponse: FormResponseType,
  ) => {
    const { eventId, clientId, websiteId, projectId } = options;
    const loadingKey = LOADING_KEYS.UPDATE;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_UPDATE, { ...formResponse.data });
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const newName = formResponse.data.name as string;
    let website;
    try {
      if (projectId) {
        await ProjectService.renameProject(projectId, { project_name: newName });

        if (websiteId) {
          website = await ClientWebsiteService.updateClientWebsite(websiteId, projectId, {
            data: {
              groom_name: newName,
            },
          } as ClientWebsiteService.ClientWebsiteType);
        }
      }

      const event = await EventsService.editEvent(eventId, clientId, formResponse.data);
      const normalizedData = normalize(event, CRMEventSchema);
      if (website) {
        dispatch(updateClientWebsiteData(dispatch, website));
      }

      dispatch(updateEventsData(dispatch, normalizedData.entities.events));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADING_KEYS.SHOW_EDIT_PANEL, false));
      return event;
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Events: update failed', e);
      return e;
    }
  },
);

export const updateEventName = createAction(
  getActionType('DATA', 'EDIT_NAME'),
  async (
    dispatch,
    options: { eventId: string; clientId: string; projectId?: string; websiteId?: string },
    newName: string,
  ) => {
    const { eventId, clientId, websiteId, projectId } = options;
    const loadingKey = LOADING_KEYS.UPDATE_NAME;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_UPDATE, { name: newName });
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    let website;
    try {
      if (projectId) {
        await ProjectService.renameProject(projectId, { project_name: newName });

        if (websiteId) {
          website = await ClientWebsiteService.updateClientWebsite(websiteId, projectId, {
            data: {
              groom_name: newName,
            },
          } as ClientWebsiteService.ClientWebsiteType);
        }
      }

      const event = await EventsService.editEvent(eventId, clientId, { name: newName });
      const normalizedData = normalize(event, CRMEventSchema);
      if (website) {
        dispatch(updateClientWebsiteData(dispatch, website));
      }

      dispatch(updateEventsData(dispatch, normalizedData.entities.events));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return event;
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Events: update failed', e);
      return e;
    }
  },
);

export const updateEventStage = createAction(
  getActionType('DATA', 'EDIT_STAGE'),
  (dispatch, id: string, clientId: string, status: EventsService.EVENT_STATUS) => {
    const loadingKey = LOADING_KEYS.UPDATE_STAGE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_UPDATE_STATUS, { status });

    EventsService.editEvent(id, clientId, {
      status,
    })
      .then((response) => {
        const normalizedData = normalize(response, CRMEventSchema);
        dispatch(updateEventsData(dispatch, normalizedData.entities.events));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, LOADING_KEYS.SHOW_EDIT_STATUS, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('Events: update stage failed', e);
        return e;
      });
  },
);

export const updatePriority = createAction(
  getActionType('DATA', 'EDIT_PRIORITY'),
  (dispatch, id: string, clientId: string, priority: EventsService.EVENT_PRIORITY) => {
    const loadingKey = LOADING_KEYS.UPDATE_PRIORITY;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_UPDATE_PRIORITY, { priority });
    EventsService.editEvent(id, clientId, {
      priority_value: priority,
    })
      .then((response) => {
        const normalizedData = normalize(response, CRMEventSchema);
        dispatch(updateEventsData(dispatch, normalizedData.entities.events));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('Events: update priority failed', e);
        return e;
      });
  },
);

export const removeEvent = createAction(
  getActionType('DATA', 'DELETE'),
  (
    dispatch,
    options: {
      eventId: string;
      projectId?: string;
    },
  ) => {
    const { eventId, projectId } = options;
    const loadingKey = LOADING_KEYS.DELETE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_DELETE);

    EventsService.deleteEvent(eventId)
      .then((response) => {
        dispatch(removeEventData(dispatch, eventId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, LOADING_KEYS.SHOW_DELETE_DIALOG, false));
        if (projectId) {
          const project = ProjectService.archiveProject(projectId);
          // We cannot add this due to cycling issue
          // dispatch(updateProjectsData(dispatch, projectId, project));
        }
        const eventsPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENTS_LIST, {});
        navigateTo(dispatch, eventsPage);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Events: delete failed', e);
        return e;
      });
  },
);

export const updateEventLabels = createAction(
  getActionType('DATA', 'UPDATE_LABELS'),
  (dispatch, clientId: string, eventId: string, labelsData: Array<LabelService.LabelsType>) => {
    const loadingKey = LOADING_KEYS.ADD_LABEL;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.ADD_EVENT_LABEL, { labelsData });
    const labelIds = labelsData.map((item) => item.id);
    dispatch(updateLabelsForEvent(dispatch, eventId, labelsData));

    LabelService.addLabelToEvent(clientId, eventId, labelIds)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('Event label: update failed', e);
        return e;
      });
  },
);

export const createAndAddEventLabel = createAction(
  getActionType('DATA', 'ADD_UPDATE_LABELS'),
  (
    dispatch,
    clientId: string,
    eventId: string,
    data: { newLabelData: LabelService.LabelsType; existingLabels: Array<LabelService.LabelsType> },
  ) => {
    const { newLabelData, existingLabels } = data;
    const loadingKey = LOADING_KEYS.ADD_LABEL;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.ADD_EVENT_LABEL, { newLabelData });

    LabelService.createLabel(LabelService.ENTITY_TYPE.EVENT, newLabelData)
      .then((response) => {
        const normalizedData = normalize(response, LabelSchema);
        dispatch(addLabelsData(dispatch, normalizedData.entities.label));
        dispatch(updateEventLabels(dispatch, clientId, eventId, [...existingLabels, response]));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('Labels: create and update failed', e);
        return e;
      });
  },
);

export const removeEventLabels = createAction(
  getActionType('DATA', 'REMOVE_LABEL'),
  (dispatch, clientId: string, eventId: string, labelIds: Array<string>) => {
    const loadingKey = LOADING_KEYS.REMOVE_LABEL;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.ADD_EVENT_LABEL, { labelIds });

    dispatch(removeLabelsForEvent(dispatch, eventId, labelIds));

    LabelService.removeLabelFromEvent(clientId, eventId, labelIds)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('Labels: remove failed', e);
        return e;
      });
  },
);

export const quickEditClientWebsite = createAction(
  getActionType('DATA', 'UPDATE'),
  async (
    dispatch,
    options: {
      id: string;
      clientId?: string;
      eventId?: string;
      projectId: string;
    },
    formResponse: FormResponseType & {
      data: ClientWebsiteService.ClientWebsiteType;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE.UPDATE;
    const { id, projectId, eventId, clientId } = options;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    const eventName =
      data.data.groom_name && data.data.bride_name
        ? `${data.data.groom_name} & ${data.data.bride_name}`
        : data.data.groom_name;
    try {
      if (eventId && clientId) {
        const event = await EventsService.editEvent(eventId, clientId, {
          name: eventName as string,
        });
        const normalizedData = normalize(event, CRMEventSchema);
        dispatch(updateEventsData(dispatch, normalizedData.entities.events));
      }

      const project = await ProjectService.renameProject(projectId, { project_name: eventName });
      // We cannot add this due to cycling issue
      // dispatch(updateProjectsData(dispatch, projectId, project));

      ClientWebsiteService.updateClientWebsite(id, projectId, data).then((response) => {
        dispatch(updateClientWebsiteData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_QUICK_EDIT_PANEL, false));
        return response;
      });

      return null;
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return e;
    }
  },
);

type EventsStateType = {
  items: IMap<string, EventsService.EventType>;
};

const initialState = {
  items: IMap([]),
};

export default handleActions(
  {
    [setEventsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateEventsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeEventData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(action.payload),
    }),
    [updateLabelsForEvent.toString()]: (state, action: { payload }) => {
      const { eventId, labels } = action.payload;

      return {
        ...state,
        items: state.items.update(eventId, (data) => ({
          // @ts-ignore
          ...data,
          labels,
        })),
      };
    },
    [removeLabelsForEvent.toString()]: (state, action: { payload }) => {
      const { eventId, labels: labelsToDelete } = action.payload;

      return {
        ...state,
        items: state.items.update(eventId, (data) => ({
          // @ts-ignore
          ...data,
          // @ts-ignore
          labels: data?.labels?.filter((item) => !labelsToDelete.includes(item.id)),
        })),
      };
    },
  },
  initialState,
);

const eventsDataSelector = createSelector(entitiesDataSelector, (entities) => entities.crm.events as EventsStateType);

export const eventEntitiesSelector = createSelector(eventsDataSelector, (crmEvents) => crmEvents.items);

export const crmEventItemsSelector = createSelector(eventEntitiesSelector, (crmEvents) => crmEvents.toJSON());

export const clientIdForEventSelector = (eventId: string) =>
  createSelector(eventEntitiesSelector, clientEntitiesSelector, (events) => {
    const event = events.get(eventId);
    return event?.client || 'no-client-id-on-event';
  });

export const clientItemForEventSelector = (eventId: string) =>
  createSelector(eventEntitiesSelector, clientEntitiesSelector, (events, crmCRMClients) => {
    const event = events.get(eventId);
    return event ? crmCRMClients.get(event.client) : undefined;
  });
