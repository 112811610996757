import { GeneralEditor, GeneralEditorContent, GeneralEditorSidebar, LoadingDots, Space, Text } from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import React, { useEffect } from 'react';

import EventPosterCreatorHeaderContainer from './EventPosterPreviewHeaderContainer';
import EventPosterPreviewSidebarContainer from './EventPosterPreviewSidebarContainer';
import EventPosterPreviewLayoutContainer from './EventPosterPreviewLayoutContainer';

type Props = {
  eventId: string;
  posterId?: string;
  projectId: string;
  brandingFolderId?: string;
  fetchPosterImages: (projectId: string, folderId: string) => void;
  initData: (projectId: string, posterId: string) => void;
  isLoading: boolean;
  hasProject: boolean;
  fetchProject: (projectId: string) => void;
  type: EventPosterService.EVENT_POSTER_TYPE;
};

export default function EventPosterPreviewPage(props: Props) {
  const {
    eventId,
    posterId,
    projectId,
    fetchPosterImages,
    brandingFolderId,
    initData,
    isLoading,
    hasProject,
    fetchProject,
    type,
  } = props;

  useEffect(() => {
    if (brandingFolderId) fetchPosterImages(projectId, brandingFolderId);
  }, [projectId, fetchPosterImages, brandingFolderId]);

  useEffect(() => {
    if (posterId) initData(projectId, posterId);
  }, [projectId, initData, posterId]);

  useEffect(() => {
    // fetch project if the user refreshes the page, and there are no project in the store. We need the project to get the branding folder details
    if (isLoading) return;
    if (!hasProject) fetchProject(projectId);
  }, [fetchProject, hasProject, isLoading, projectId]);

  if (!posterId) return <Text>There is no poster</Text>;
  return (
    <GeneralEditor hasLeftPanel={false} rightPanelSize="md">
      <EventPosterCreatorHeaderContainer projectId={projectId} posterId={posterId} eventId={eventId} type={type} />
      <GeneralEditorContent>
        {isLoading ? (
          <Space>
            <LoadingDots />
          </Space>
        ) : (
          <EventPosterPreviewLayoutContainer posterId={posterId} />
        )}
      </GeneralEditorContent>
      <GeneralEditorSidebar isRight>
        <EventPosterPreviewSidebarContainer projectId={projectId} posterId={posterId} />
      </GeneralEditorSidebar>
    </GeneralEditor>
  );
}
