import { EventAttendeesCheckinZoneService } from '@premagic/core';
import {
  Col,
  List,
  ListItem,
  ListItemHeader,
  LoadingDots,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { useEffect } from 'react';

type EventAttendeeFaceCheckinStatsListItemProps = {
  checkinZoneData?: Record<string, EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneType>;
  statsData?: EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneStatsItemAccountAppType;
};

function EventAttendeeFaceCheckinStatsListItem(props: EventAttendeeFaceCheckinStatsListItemProps) {
  const { statsData, checkinZoneData } = props;

  if (!statsData) return null;

  const {
    zone_id: zoneId,
    checkin_count: checkinCount,
    checkout_count: checkoutCount,
    active_count: activeCount,
  } = statsData;

  const zoneName = checkinZoneData?.[zoneId].name || 'Zone';

  return (
    <ListItem>
      <Col size={3}>
        <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.NORMAL}>
          {zoneName}
        </Text>
      </Col>
      <Col size={3}>
        <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.NORMAL}>
          {checkinCount}
        </Text>
      </Col>
      <Col size={3}>
        <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.NORMAL}>
          {checkoutCount}
        </Text>
      </Col>
      <Col rightAlighed size={null}>
        <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.NORMAL}>
          {activeCount}
        </Text>
      </Col>
    </ListItem>
  );
}

type Props = {
  projectId: string;
  checkinZoneData?: Record<string, EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneType>;
  checkinStats?: Array<EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneStatsItemAccountAppType>;
  fetchCheckinStats: (projectId: string) => void;
  isLoading: boolean;
  getEventAttendeesCheckinZones: (projectId: string) => void;
};

export default function EventAttendeeFaceCheckinStatsList(props: Props) {
  const { projectId, checkinStats, checkinZoneData, fetchCheckinStats, isLoading, getEventAttendeesCheckinZones } =
    props;

  useEffect(() => {
    if (checkinZoneData && Object.keys(checkinZoneData).length) return;

    getEventAttendeesCheckinZones(projectId);
  }, [projectId, getEventAttendeesCheckinZones, checkinZoneData]);

  useEffect(() => {
    fetchCheckinStats(projectId);
  }, [projectId, fetchCheckinStats]);

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <List>
      <ListItemHeader>
        <Row>
          <Col size={3}>
            <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
              Zone
            </Text>
          </Col>
          <Col size={3}>
            <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
              No. of checkin
            </Text>
          </Col>
          <Col size={3}>
            <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
              No. of checkout
            </Text>
          </Col>
          <Col rightAlighed size={null}>
            <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
              active
            </Text>
          </Col>
        </Row>
      </ListItemHeader>
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical" showShadow>
        {checkinStats?.map((item, idx) => {
          const key = `id-${idx}`;
          return <EventAttendeeFaceCheckinStatsListItem key={key} statsData={item} checkinZoneData={checkinZoneData} />;
        })}
      </ScrollableContainer>
    </List>
  );
}
