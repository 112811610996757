import { connect } from 'react-redux';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { searchCRMClients, clientItemsArraySelector } from './ClientsDataDuck';
import CRMClientSearch from './CRMClientSearch';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../common/ModalDuck';

function mapStateToProps(state) {
  return {
    clients: clientItemsArraySelector(state),
    isClientSearchModalOpen: isModalOpenSelector(MODALS.CLIENT.SEARCH)(state),
    isLoading: isLoadingSelector(LOADINGS.CRM_CLIENTS.SEARCH)(state),
    error: errorObjectForTypeSelector(LOADINGS.CRM_CLIENTS.SEARCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchCRMClients: (searchString) => {
      dispatch(searchCRMClients(dispatch, searchString));
    },

    closeCRMClientSearchModal: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.CLIENT.SEARCH, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMClientSearch);
