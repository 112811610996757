import React, { useRef } from 'react';
import { Button, BUTTON_STYLES } from '../Button/Buttons';
import { Icon } from '../Icon/Icons';
import styles from './focalpoint.module.css';

export type Cordiantes = {
  x: number;
  y: number;
};

type Props = {
  cordinates: Cordiantes;
  onChange: (cordinates: Cordiantes) => void;
  children: React.ReactNode;
};

function getCordinatePercentage(offset: number, maxSize: number): number {
  return Number(((offset * 100) / maxSize).toFixed());
}

export function FocalPoint(props: Props): React.ReactElement {
  const { cordinates, onChange, children } = props;
  const $wrapper = useRef<HTMLDivElement>(null);

  function onFocalPointChange(e) {
    const wrapperDimensions = $wrapper.current?.getBoundingClientRect();
    const x = getCordinatePercentage(e.nativeEvent.offsetX, wrapperDimensions?.width || 0);
    const y = getCordinatePercentage(e.nativeEvent.offsetY, wrapperDimensions?.height || 0);
    onChange({ x, y });
  }

  return (
    <Button
      style={BUTTON_STYLES.RESET}
      className={styles['focalpoint-wrapper']}
      onClick={onFocalPointChange}
      ref={$wrapper}
    >
      {children}
      <div className={styles['focalpoint-circle']} style={{ left: `${cordinates.x}%`, top: `${cordinates.y}%` }}>
        <Icon name="circle_2" />
      </div>
    </Button>
  );
}
