import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { currentSubscriptionSelector } from '../SubscriptionsDataDuck';
import SubscriptionStatusCard from './SubscriptionStatusCard';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state) {
  return {
    subscription: currentSubscriptionSelector(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.SUBSCRIPTION.FETCH, true)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionStatusCard);
