import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDataDuck, ProposalDeckDataDuck } from '@premagic/proposals';
import { EventTrackerService } from '@premagic/core';

import ProposalCreatorHeaderOptionsMenu from './ProposalCreatorHeaderOptionsMenu';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, folderId, deckId } = ownProps;
  const CREATE_PROPOSAL = ActionConstants.KEYS.PROPOSALS_TEMPLATES.CREATE;

  return {
    projectId,
    folderId,
    deckId,
    proposal: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    isCreatingProposalLoading: LoadingDuck.isLoadingSelector(CREATE_PROPOSAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_DELETE_DIALOG, true));
    },
    showConfirmEditProposalDialog: () => {
      dispatch(
        ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_EDIT_PROPOSAL_CONFIRMATION, true),
      );
    },
    showProposalHistory: () => {
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.SHOW_HISTORY);
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_HISTORY, true));
    },
    showProposalThumbnail: () => {
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_THUMBNAIL, true),
      );
    },
    createNewVersion: (projectId, deckId, options) =>
      dispatch(ProposalDeckDataDuck.addNewProposalDeckVersion(dispatch, projectId, deckId, options)),
    duplicateProposal: (name, folderId, projectId) => {
      dispatch(ProposalDataDuck.duplicateProposalTemplate(dispatch, name, folderId, projectId));
    },
    showRenameFolder: () => {
      dispatch(toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_EDIT_NAME, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorHeaderOptionsMenu);
