import React, { useMemo } from 'react';
import { FormGroup, FormLabel, INPUT_SIZES, Select } from '@premagic/myne';
import { CRMPaymentType, TAX_TYPES, TAX_TYPES_DETAILS } from './CRMPaymentService';

type Props = {
  payment?: CRMPaymentType;
};

export default function PaymentTaxOptionPicker(props: Props): JSX.Element {
  const { payment } = props;

  const taxbleOptions = useMemo(
    () => Object.values(TAX_TYPES_DETAILS).map((item) => ({ value: item.id, label: item.label })),
    [],
  );

  const selectedValue = payment?.taxable || TAX_TYPES.NO_TAX;
  const selectedOption = taxbleOptions.find((option) => option.value === selectedValue);

  return (
    <FormGroup>
      <FormLabel>Tax</FormLabel>
      <Select options={taxbleOptions} value={selectedOption} name="taxable" isMulti={false} size={INPUT_SIZES.SM} />
    </FormGroup>
  );
}
