import { IntegrationService } from '@premagic/core';
import { connect } from 'react-redux';
import { MODALS } from '../../../../../common/Constants';
import {
  isModalOpenSelector,
  modalOptionsSelector,
  setModalOptions,
  toggleModalVisibility,
} from '../../../../../common/ModalDuck';
import IntegrationEventsModal from './IntegrationEventsModal';
import { INTEGRATION_CONNECT_OPTION } from './IntegrationStepService';

function mapStateToProps(state) {
  const {
    platform,
    connectOption,
    projectId,
  }: {
    platform: IntegrationService.INTEGRATION_PLATFORMS;
    connectOption: INTEGRATION_CONNECT_OPTION;
    projectId?: string;
  } = modalOptionsSelector(MODALS.INTEGRATION.IMPORT_EVENT)(state) || {};

  return {
    show: isModalOpenSelector(MODALS.INTEGRATION.IMPORT_EVENT)(state),
    platform,
    connectOption,
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, false));
      dispatch(setModalOptions(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationEventsModal);
