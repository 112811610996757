import { EnvUtils } from '@premagic/utils';
import { EVENT_POSTER_TYPE } from './EventPosterService';

const clientHomePageUrlProd = 'https://my.premagic.com';
const clientHomePageUrlLocal = 'http://localhost:4201';

export const GALLERY_LINK = {
  PEOPLE_GALLERY: (faceId) => `#/people/${faceId}/`,
  PEOPLE_MAGAZINE: (faceId) => `#/posters/people/${faceId}/${EVENT_POSTER_TYPE.MAGAZINE}`,
  AVATAR_STUDIO: {
    ROOT: '#/kiosk/avatar-studio',
    PRINT_LINK: '#/kiosk/avatar-studio/list',
  },
};

export function getHomePageUrl(): string {
  if (EnvUtils.isProd()) {
    return clientHomePageUrlProd;
  }
  return clientHomePageUrlLocal;
}

export function getEditPageUrl(projectId: string, folderId: string): string {
  if (EnvUtils.isProd()) {
    return `${clientHomePageUrlProd}/invite/${projectId}/${folderId}/edit`;
  }
  return `${clientHomePageUrlLocal}/invite/${projectId}/${folderId}/edit`;
}

export function getWishPageUrl(projectId: string, folderId: string): string {
  if (EnvUtils.isProd()) {
    return `${clientHomePageUrlProd}/invite/${projectId}/${folderId}/wishes`;
  }
  return `${clientHomePageUrlLocal}/invite/${projectId}/${folderId}/wishes`;
}

export function getPeopleGalleryUrl(guestGalleryUrl: string, faceId: string) {
  return `${guestGalleryUrl}${GALLERY_LINK.PEOPLE_GALLERY(faceId)}`;
}

export function getPeopleMagazineURL(guestGalleryUrl: string, faceId: string): string {
  return `${guestGalleryUrl}${GALLERY_LINK.PEOPLE_MAGAZINE(faceId)}`;
}

export function getAvatarStudioUrl(guestGalleryUrl: string): string {
  return `${guestGalleryUrl}${GALLERY_LINK.AVATAR_STUDIO.ROOT}`;
}

export function getAvatarStudioPrintLink(guestGalleryUrl: string): string {
  return `${guestGalleryUrl}${GALLERY_LINK.AVATAR_STUDIO.PRINT_LINK}`;
}

export function getShareIdFromShareUrl(url: string) {
  // return window.location.href.match(/share\/(.*)\//)[1];
  return url.split('/')[4];
}
