import { BrowserUrlUtils } from '@premagic/utils';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { filesArrayIdsSortedByRankSelector, setFilesData } from '../../../images/FilesDataDuck';
import { fetchProjectsData, guestGalleryLinkFromProjectDataSelector } from '../../AccountProjectsDataDuck';
import { fetchAllFiles } from '../DAMLibraryPageDuck';
import { DAMLibraryFiltersType, DAM_GALLERY_SORT_BY, DAM_LIBRARY_FILTER_KEYS } from '../filters/DAMLibraryFilterTypes';
import DAMFilesPage from './DAMFilesPage';
import { getDAMFilterLabels } from '../DAMLibraryDataDuck';

function mapStateToProps(state, ownProps) {
  const { filters } = ownProps.match.params;

  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as DAMLibraryFiltersType;
  const projectIds = filterObject[DAM_LIBRARY_FILTER_KEYS.PROJECT];
  const faceIds = filterObject[DAM_LIBRARY_FILTER_KEYS.FACE_ID];
  const sortByData = filterObject[DAM_LIBRARY_FILTER_KEYS.SORT_BY];

  const projectId = Array.isArray(projectIds) ? projectIds[0] : projectIds;
  const faceId = Array.isArray(faceIds) ? faceIds[0] : faceIds;
  const sortBy = !Array.isArray(sortByData) ? (sortByData as DAM_GALLERY_SORT_BY) : undefined;

  function getGuestGalleryLink() {
    // no gallery link for 2 people together
    if (Array.isArray(faceIds) && faceIds.length > 1) return undefined;

    return projectId && faceId ? guestGalleryLinkFromProjectDataSelector(projectId, faceId)(state) : undefined;
  }

  return {
    projectId,
    faceId,
    isLoading: isLoadingSelector(LOADINGS.DAM_LIBRARY.FETCH_FILES)(state),
    fileIds: filesArrayIdsSortedByRankSelector(state),
    filters,
    sortBy,
    guestGalleryLink: getGuestGalleryLink(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFiles: (filters) => {
      dispatch(fetchAllFiles(dispatch, filters));
      dispatch(fetchProjectsData(dispatch));
    },
    getDAMFilterLabels: (projectId) => {
      dispatch(getDAMFilterLabels(dispatch, projectId));
    },
    clearFilesData: () => {
      dispatch(setFilesData(dispatch, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMFilesPage);
