import { COLOR_SHADES, Col, Select, Text } from '@premagic/myne';
import { i18n } from '@premagic/utils';
import { useMemo } from 'react';

type LanguageSelectorProps = {
  defaultLangCode?: i18n.LANGUAGE_CODE;
  onChange: (value) => void;
};

function LanguageSelector(props: LanguageSelectorProps): JSX.Element {
  const { defaultLangCode, onChange } = props;
  const languageOptions = useMemo(
    () =>
      Object.values(i18n.LANGUAGES_DATA).map((option) => ({
        value: option.langCode,
        label: option.label,
      })),
    [],
  );

  const defaultSelectedOption = useMemo(
    () => languageOptions.find((option) => option.value === defaultLangCode),
    [languageOptions, defaultLangCode],
  );

  return (
    <Select
      name="account_language_selector"
      options={languageOptions}
      value={defaultSelectedOption}
      isMulti={false}
      onChange={(data) => onChange(data?.value)}
    />
  );
}

type ProjectLanguageProps = {
  defaultLanguageCode?: i18n.LANGUAGE_CODE;
  onChange: (value) => void;
};

export default function AccountLanguageSelector(props: ProjectLanguageProps) {
  const { defaultLanguageCode, onChange } = props;

  return (
    <>
      <Col size={10}>
        <Text color={COLOR_SHADES.DARKER} block>
          Language for Public
        </Text>
        <Text color={COLOR_SHADES.LIGHT}>Gallery and Registration pages</Text>
      </Col>
      <Col size={3} rightAlighed>
        <LanguageSelector defaultLangCode={defaultLanguageCode} onChange={onChange} />
      </Col>
    </>
  );
}
