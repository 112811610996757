import { connect } from 'react-redux';
import { FolderMetaService } from '@premagic/core';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import FolderBlockDialog from './FolderBlockDialog';
import { isFolderBlockedSelector, saveFolderMetaData } from '../../folder-meta/FoldersMetaDataDuck';

const COMMON_KEY = LOADINGS.FOLDER.BLOCK;

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  return {
    projectId,
    folderId,
    show: isModalOpenSelector(COMMON_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER_META.UPDATE)(state),
    isFolderBlocked: isFolderBlockedSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleShowBlockFolder: (show) => {
      dispatch(toggleModalVisibility(dispatch, COMMON_KEY, show));
    },
    blockFolder: (projectId, folderId, value) => {
      dispatch(
        saveFolderMetaData(dispatch, projectId, folderId, {
          data: {
            [FolderMetaService.FOLDER_META_TYPES.BLOCK_FOLDER]: value,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderBlockDialog);
