import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { navigateBack } from '../../../../../../../../services/RouterService';
import SponsorWatermarkPreviewFullView from './SponsorWatermarkPreviewFullView';

function mapStateToProps(state, ownProps) {
  const { eventId, folderId } = ownProps.match.params;

  return {
    eventId,
    folderId,
    isLoadingProject: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateBack: (defaultUrl) => {
      navigateBack(dispatch, defaultUrl);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorWatermarkPreviewFullView);
