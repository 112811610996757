import { FolderService } from '@premagic/core';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { folderFilesStatsWithFolderTypeForProjectSelector, projectsSelector } from '../AccountProjectsDataDuck';
import SelectionFoldersPageHeader from './SelectionFoldersPageHeader';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    currentUrlPathname: string;
  },
) {
  const { projectId, currentUrlPathname } = ownProps;

  return {
    projectId,
    currentUrlPathname,
    project: projectsSelector(state)[projectId],
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    folderFilesStats: folderFilesStatsWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.SELECTION,
    )(state),
  };
}

export default connect(mapStateToProps)(SelectionFoldersPageHeader);
