import React, { useEffect } from 'react';

import {
  Card,
  Col,
  COLOR_SHADES,
  Label,
  LABEL_STYLES,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { FolderService } from '@premagic/core';
import { ErrorTracker, ImageOptimizerUtils, NumberUtils, StringUtils } from '@premagic/utils';
import AccountFolderPhotoDropZoneContainer from '../../folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderUploadProgressContainer from '../../folders/folder-files-upload/FolderUploadProgressContainer';
import FolderGalleryContainer from '../../folders/gallery/FolderGalleryContainer';
import FolderOptionsMenuButtonContainer from '../../folders/header/FolderOptionsMenuButtonContainer';

type Props = {
  initData: (projectId: string, folderId: string) => void;
  isLoading: boolean;
  folder: FolderService.FolderType;
  projectId: string;
  folderId: string;
  filesInfo: {
    images: number;
    videos: number;
  };
  isFolderBlocked?: boolean;
  setIsImageCompressorReady: (data: boolean) => void;
  isImageCompressorReady?: boolean;
  isFolderPublic?: boolean;
};

export default function HighlightFolderDetails(props: Props): JSX.Element {
  const {
    initData,
    folder,
    isLoading,
    projectId,
    folderId,
    filesInfo,
    isFolderBlocked,
    setIsImageCompressorReady,
    isImageCompressorReady,
    isFolderPublic,
  } = props;
  const { status, folder_name: name } = folder;
  const { images, videos } = filesInfo;

  useEffect(() => {
    initData(projectId, folderId);
  }, [initData, projectId, folderId]);

  useEffect(() => {
    if (!isImageCompressorReady) {
      (async function initCompressor() {
        try {
          await ImageOptimizerUtils.converter.init();
          await ImageOptimizerUtils.filesProcessor.initImageBlobReduce();
          setIsImageCompressorReady(true);
        } catch (e) {
          ErrorTracker.logError('IMAGE OPTIMIZER', e);
          setIsImageCompressorReady(false);
        }
      })();
    }
  }, []);

  return (
    <Card>
      <Space>
        <Row>
          <Col size={7}>
            <Row vcenter>
              <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD}>
                {name}
              </Text>
              {isFolderBlocked && (
                <>
                  <Space size={2} />
                  <Tooltip message="Folder is blocked and will be hidden from the siganture album">
                    <Label style={LABEL_STYLES.PILL_DANGER}>Blocked</Label>
                  </Tooltip>
                </>
              )}
            </Row>
            <div>
              {!!images && (
                <Text>
                  {NumberUtils.getNumberInFormat(images)} {StringUtils.pluralize('Photo', images)}
                </Text>
              )}
              {!!videos && (
                <>
                  <Space size={1} />
                  <Text>
                    & {NumberUtils.getNumberInFormat(videos)} {StringUtils.pluralize('Video', videos)}
                  </Text>
                </>
              )}
            </div>
          </Col>

          <Col rightAlighed size={null}>
            <Row vcenter>
              {isFolderPublic && (
                <Text color={COLOR_SHADES.BLUE} size={TEXT_SIZE.SIZE_5}>
                  Shown in guest gallery
                </Text>
              )}
              <FolderOptionsMenuButtonContainer folderId={folderId} projectId={projectId} />
            </Row>
          </Col>
        </Row>
        <Space vertical />
        <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
        <Space vertical />
        {isLoading ? (
          <div>
            <Space vertical size={10} />
            <LoadingDots size="sm" />
            <Space vertical size={10} />
          </div>
        ) : (
          <div>
            <FolderUploadProgressContainer folderId={folderId} />
            <FolderGalleryContainer projectId={projectId} folderId={folderId} />
          </div>
        )}
      </Space>
    </Card>
  );
}
