import { HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';

const APIS = APIURLService.API_URLS.CRM_WORKFLOW;

export type TaskType = {
  id: number;
  due_date_duration: number;
  name: string;
  description: string;
  order: number;
  created_at: string;
  updated_at: string;
  task_group: number;
};

export function fetchTasks(
  workflowId: number,
  taskgroupId: number,
): Promise<{
  results: Array<TaskType>;
  count: number;
}> {
  return HttpUtils.get(APIS.TASKS_ROOT(workflowId, taskgroupId), {
    params: { limit: 100 },
  }).then((response) => response.data);
}

export function fetchTaskDetails(workflowId: number, taskgroupId: number, taskId: number): Promise<TaskType> {
  return HttpUtils.get(APIS.TASKS_DETAILS(workflowId, taskgroupId, taskId))
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function createTask(
  workflowId: number,
  taskgroupId: number,
  data: {
    name: string;
    description?: string;
    due_date_duration?: number;
  },
): Promise<TaskType> {
  return HttpUtils.post(APIS.TASKS_ROOT(workflowId, taskgroupId), data, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function deleteTask(workflowId: number, taskgroupId: number, taskId: number): Promise<void> {
  return HttpUtils.httpDelete(APIS.TASKS_DETAILS(workflowId, taskgroupId, taskId)).then((response) => response.data);
}

// will implement when required
// export function editTask(
//   workflowId: number,
//   taskgroupId: number,
//   taskId: number,
//   data: Partial<TaskType>,
// ): Promise<any> {
//   return HttpUtils.patch(APIS.TASKS_DETAILS(workflowId, taskgroupId, taskId), data, true)
//     .then((response) => response.data)
//     .catch((e) => Promise.reject(e.data));
// }
