import { DAM_LIBRARY_FILTER_KEYS } from './DAMLibraryFilterTypes';

export const DAM_LIBRARY_FILTERS_DETAILS = {
  [DAM_LIBRARY_FILTER_KEYS.PROJECT]: {
    key: DAM_LIBRARY_FILTER_KEYS.PROJECT,
    label: 'Event:',
  },
  [DAM_LIBRARY_FILTER_KEYS.FACE_ID]: {
    key: DAM_LIBRARY_FILTER_KEYS.FACE_ID,
    label: 'People:',
  },
  [DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE]: {
    key: DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE,
    label: 'Key people:',
  },
  [DAM_LIBRARY_FILTER_KEYS.TAGS]: {
    key: DAM_LIBRARY_FILTER_KEYS.TAGS,
    label: 'Tags:',
  },
  [DAM_LIBRARY_FILTER_KEYS.DATE_FROM]: {
    key: DAM_LIBRARY_FILTER_KEYS.DATE_FROM,
    label: 'Start date:',
  },
  [DAM_LIBRARY_FILTER_KEYS.DATE_END]: {
    key: DAM_LIBRARY_FILTER_KEYS.DATE_END,
    label: 'End date:',
  },
  [DAM_LIBRARY_FILTER_KEYS.SORT_BY]: {
    key: DAM_LIBRARY_FILTER_KEYS.SORT_BY,
    label: 'Sort by:',
  },
};
