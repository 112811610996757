import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { projectMetaDataForProjectSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { getEventPostersForProject } from '../../event-posters/EventPostersDataDuck';

import { eventExhibitorsIdsSelector, getEventExhibitorsForProject } from './EventExhibitorDataDuck';
import EventExhibitorList from './EventExhibitorList';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, projectId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_EXHIBITOR.FETCH)(state) || isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    projectId,
    eventId,
    errors: errorObjectForTypeSelector(LOADINGS.EVENT_EXHIBITOR.FETCH)(state),
    hasPoster: !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED],
    hasEventExhibitorFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_EXHIBITOR,
    )(state),
    exhibitorIds: eventExhibitorsIdsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId: string) => {
      dispatch(getEventExhibitorsForProject(dispatch, projectId));
    },
    showAddExhibitor: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_ADD_PANEL, true));
    },

    fetchPosters: (projectId: string) => {
      dispatch(getEventPostersForProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventExhibitorList);
