import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import SignatureAlbumCardFoldersList from './SignatureAlbumCardFoldersList';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import { saveHighlightFolderPosition } from '../../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.HIGHLIGHT,
  )(state);
  const folders = foldersEntitiesSelector(state);
  return {
    folderIds: signatureFolderIds,
    folders: signatureFolderIds.map((folderId) => folders[folderId]),
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrder: (projectId, folderIds, data) => {
      dispatch(saveHighlightFolderPosition(dispatch, projectId, folderIds, data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignatureAlbumCardFoldersList);
