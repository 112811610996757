import * as React from 'react';
import ClassNames from 'classnames';

import { BrowserUtils } from '@premagic/utils';
import { useEffect } from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { Row } from '../Grid/Grid';
import { NavLink } from '../Link/Link';
import { SubTitle, Text, TEXT_BOLDNESS } from '../Text/Text';
import { Button, BUTTON_STYLES } from '../Button/Buttons';

import styles from './list.module.css';
import alertStyles from '../Alert/alert.module.css';

interface ListProps {
  children: React.ReactNode;
}

export function List(props: ListProps): React.ReactElement {
  const classes = ClassNames(styles.list);

  const { children } = props;
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
}

export enum LIST_ITEM_STYLES {
  LIST = 'list',
  COMPACT_LIST = 'compact',
  NAV = 'nav',
}

interface ListItemProps {
  children: React.ReactNode;
  to?: string | null;
  muted?: boolean;
  style?: LIST_ITEM_STYLES;
  onClick?: () => void;
  showHoverStyle?: boolean;
  className?: string;
  active?: boolean;
  highlight?: boolean;
}

export const ListItem = React.forwardRef((props: ListItemProps, ref: any) => {
  const {
    to = null,
    children,
    muted = false,
    style = LIST_ITEM_STYLES.LIST,
    active = false,
    onClick,
    showHoverStyle = false,
    className,
    highlight = false,
    ...elementProps
  } = props;

  const classes = ClassNames(className, styles['list-item'], styles[`list-item--style-${style}`], {
    [styles['list-item--muted']]: muted,
    [styles['list-item--clickable']]: showHoverStyle || onClick,
    [styles['list-item--active']]: active,
    [styles['list-item--highlight']]: highlight,
  });

  useEffect(() => {
    if (highlight) BrowserUtils.scrollTo(ref?.current, true);
  }, [highlight]);

  if (onClick) {
    return (
      <Button ref={ref} style={BUTTON_STYLES.RESET} className={classes} onClick={onClick}>
        {children}
      </Button>
    );
  }
  if (!to)
    return (
      <div ref={ref} className={classes}>
        {children}
      </div>
    );

  return (
    <NavLink to={to} ref={ref} className={classes} activeClassName={styles['list-item--active']} {...elementProps}>
      {children}
    </NavLink>
  );
});

interface ListItemContentProps {
  children: React.ReactNode;
}

export function ListItemContent(props: ListItemContentProps): React.ReactElement {
  const { children } = props;
  return (
    <Text block boldness={TEXT_BOLDNESS.SEMI_BOLD}>
      {children}
    </Text>
  );
}

interface ListItemIconProps {
  children: React.ReactNode;
}

export function ListItemIcon(props: ListItemIconProps): React.ReactElement {
  const classes = ClassNames(styles['list-item__icon']);

  const { children } = props;
  return <div className={classes}>{children}</div>;
}

interface ListItemHeaderProps {
  children: React.ReactNode;
}

export function ListItemHeader(props: ListItemHeaderProps): React.ReactElement {
  const classes = ClassNames(styles['list-item__header']);

  const { children } = props;
  return (
    <div className={classes} {...props}>
      <SubTitle>{children}</SubTitle>
    </div>
  );
}

interface ListItemFooterProps {
  children: React.ReactNode;
}

export function ListItemFooter(props: ListItemFooterProps): React.ReactElement {
  const classes = ClassNames(styles['list-item__footer']);

  const { children } = props;
  return (
    <div className={classes} {...props}>
      <Row vcenter>{children}</Row>
    </div>
  );
}

export function ListItemNotificationDot(): React.ReactElement {
  return (
    <Tooltip message="Actions pending">
      <div className={ClassNames(alertStyles['notification-dot'], styles['list-item__notification-dot'])} />
    </Tooltip>
  );
}

export enum LIST_ITEM_ACTION_STYLES {
  PRIMARY = 'primary',
  PRIMARY_TOP_CORNER = 'primary-top-corner',
  DEFAULT = 'default',
  DEFAULT_INSET = 'default-inset',
}

interface ListItemActionProps {
  children: React.ReactNode;
  className?: string;
  style?: LIST_ITEM_ACTION_STYLES;
}

export function ListItemAction(props: ListItemActionProps): React.ReactElement {
  const { children, className, style = LIST_ITEM_ACTION_STYLES.PRIMARY } = props;
  const classes = ClassNames(styles['list-item__action'], styles[`list-item__action--${style}`], className);
  // // prevent event bubbling from here
  // function stopPropagation(event: React.MouseEvent) {
  //   event.stopPropagation();
  // }
  // onClickCapture={stopPropagation}>
  return <div className={classes}>{children}</div>;
}
