import React from 'react';
import { Text, TimeAgo } from '@premagic/myne';
import { EventsService } from '@premagic/core';

type Props = {
  event: EventsService.EventType;
};

export default function EventSidebarSummary(props: Props): JSX.Element | null {
  const { event } = props;
  const { created_at: createdAt } = event;
  if (!createdAt) return null;
  return (
    <Text muted>
      Added <TimeAgo date={createdAt} />
    </Text>
  );
}
