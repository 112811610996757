import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import {
  ClientWebsiteService,
  EventTrackerService,
  Schemas,
  ThemeService,
  ClientWebsiteClipArtService,
  PresetDesignService,
} from '@premagic/core';
import { ActionTypeUtils } from '@premagic/utils';

import { FOLDER_STATUS, LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { entitiesDataSelector, EntitiesStateType } from '../../reducers/selectors';
import APP_URLS, { getRouteUrlFor } from '../../services/AppRouteURLService';
import { navigateTo } from '../../../../services/RouterService';
import { toggleWindowPanelVisibility } from '../../../../common/WindowPanelDuck';
import { setClientWebsiteFiles } from './client-files/ClientWebsiteFilesDataDuck';
import { toastMessage } from '../../reducers/ToastStore';
import { setProjectOrdersData } from '../project-orders/ProjectOrdersDataDuck';
import { getProjectMetaData } from '../projects/project-meta/ProjectsMetaDataDuck';
import { setClientWebsiteItineraryData } from './itinerary/ClientWebsiteItineraryDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_WEBSITE');

export const setClientWebsiteData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addClientWebsiteData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const updateClientWebsiteData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const getClientWebsite = createAction(
  getActionType('DATA', 'NEW'),
  (dispatch, id: string, projectId: string) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE.FETCH_SINGLE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ClientWebsiteService.fetchClientWebsite(id, projectId)
      .then((response) => {
        response.theme.color.tertiary_color = response.theme.color.tertiary_color
          ? response.theme.color.tertiary_color
          : response.theme.color.secondary_color;
        const normalizedData = normalize(response, Schemas.ClientWebsiteSchema);
        const { clientWebsite, files, projectOrders, itineraries } = normalizedData.entities;
        dispatch(setClientWebsiteItineraryData(dispatch, itineraries));

        dispatch(setClientWebsiteData(dispatch, clientWebsite));
        dispatch(setClientWebsiteFiles(dispatch, files));
        dispatch(setProjectOrdersData(dispatch, projectOrders));
        dispatch(getProjectMetaData(dispatch, projectId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const addClientWebsite = createAction(
  getActionType('DATA', 'CREATE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: ClientWebsiteService.NewClientWebsiteType;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    const presetDesignId = ClientWebsiteClipArtService.getRandomPresetDesignsIdFromClipartMapping();
    const presetDesign = PresetDesignService.PRESET_DESIGN[presetDesignId];
    ClientWebsiteService.createClientWebsite(projectId, {
      ...data,
      theme: {
        id: ThemeService.THEMES_IDS.BAIKAL,
        color: {
          background: presetDesign.backgroundColor,
          primary_color: presetDesign.colors.primaryColor,
          secondary_color: presetDesign.colors.secondaryColor,
          tertiary_color: presetDesign.colors.tertiaryColor,
        },
        font: {
          primary_font: presetDesign.primaryFontFamily,
          secondary_font: presetDesign.secondaryFontFamily,
        },
        clipart_id: ClientWebsiteClipArtService.getClipartForPresetDesign(presetDesignId),
      },
    })
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CLIENT_WEBSITE.CREATE_NEW, response);
        dispatch(addClientWebsiteData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        const editThemeUrl = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.EDIT, { id: response.id, projectId });
        navigateTo(dispatch, editThemeUrl);

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const saveClientWebsite = createAction(
  getActionType('DATA', 'UPDATE'),
  (
    dispatch,
    options: {
      id: string;
      projectId: string;
      navigateToUrl?: string;
    },
    formResponse: FormResponseType & {
      data: ClientWebsiteService.ClientWebsiteType;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE.UPDATE;
    const { id, projectId, navigateToUrl } = options;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    ClientWebsiteService.updateClientWebsite(id, projectId, data)
      .then((response) => {
        dispatch(updateClientWebsiteData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        // // When User is in the theme page and save the values :
        // // Note: we can move this to 2 different actions when the conplexicty increase
        // const clientWebsiteDetailsPageUrl = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, {
        //   id,
        //   projectId,
        // });
        if (navigateToUrl) navigateTo(dispatch, navigateToUrl);

        // When the user is editing the basic information in the panel
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_PANEL, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_MAIN_OCCASION_PANEL, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_PEOPLE_DETAILS_PANEL, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_EVENT_METRICS_PANEL, false));
        dispatch(
          toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_USER_REGISTRATION_PANEL, false),
        );
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const deletePhotos = createAction(
  getActionType('DATA', 'UPDATE'),
  (
    dispatch,
    id: string,
    projectId: string,
    formResponse: FormResponseType & {
      data: ClientWebsiteService.ClientWebsiteType;
      name: string;
    },
  ) => {
    const { data, name } = formResponse;
    const loadingKey = LOADINGS.CLIENT_WEBSITE.PHOTO_UPDATE(name);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    ClientWebsiteService.updateClientWebsite(id, projectId, data)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('success', 'Removed');
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const saveClientWebsiteColors = createAction(
  getActionType('DATA', 'UPDATE_COLORS'),
  (
    dispatch,
    id: string,
    projectId: string,
    formResponse: FormResponseType & {
      data: ClientWebsiteService.ClientWebsiteType;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WEBSITE.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    ClientWebsiteService.updateClientWebsite(id, projectId, data)
      .then((response) => {
        dispatch(updateClientWebsiteData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        toastMessage('success', 'Saved');
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

type ClientWebsiteItemType = IMap<string, ClientWebsiteService.ClientWebsiteType>;
type StateType = {
  items: ClientWebsiteItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setClientWebsiteData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addClientWebsiteData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateClientWebsiteData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState,
);

export const clientWebsiteDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => entities.clientWebsite as StateType,
);

export const clientWebsiteEntitiesSelector = createSelector(
  clientWebsiteDataSelector,
  (clientWebsites) => clientWebsites.items,
);

export const clientWebsitesSelector = createSelector(clientWebsiteEntitiesSelector, (clientWebsites) =>
  clientWebsites.toJSON(),
);

export const clientWebsiteStatusSelector = (id: string) =>
  createSelector(
    clientWebsiteEntitiesSelector,
    (clientWebsites) => clientWebsites.get(id, { status: FOLDER_STATUS.DRAFT }).status,
  );

export const clientWebsiteIsEventNameOrGroomNameHiddenSelector = (id: string) =>
  createSelector(
    clientWebsiteEntitiesSelector,
    (clientWebsites) => clientWebsites.get(id)?.data?.client_website_hide_title,
  );

export const clientWebsiteShareURLSelector = (id: string) =>
  createSelector(clientWebsiteEntitiesSelector, (clientWebsites) => clientWebsites.get(id)?.share_url);
