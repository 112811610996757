import { connect } from 'react-redux';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteMainOccasionForm from './ClientWebsiteMainOccasionForm';

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
  };
}

export default connect(mapStateToProps)(ClientWebsiteMainOccasionForm);
