import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  Form,
  FormGroup,
  FormLabel,
  FormResponseType,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  Input,
  INPUT_SIZES,
  LoadingBox,
  Row,
  Space,
} from '@premagic/myne';
import { ClientSettingsService } from '@premagic/core';

import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: FormResponseType) => void;
  errors?: Record<string, string>;
};

export default function ExperimentsPreferencePage(props: Props): JSX.Element {
  const { savePreferences, isSaving, errors, isLoading, clientSettings } = props;
  const { [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ADDON_AI_LIMIT]: addonAILimit } = clientSettings;

  return (
    <InnerPage>
      <InnerPageHeader iconName="git_pull_request" title="Experimental features" backTo={APP_URLS.SETTINGS.INDEX}>
        Experimental features TWO
      </InnerPageHeader>
      <InnerPageContent>
        <Card>
          <Space>
            <LoadingBox isLoading={isLoading}>
              <Col size={8}>
                <Form
                  onSubmit={savePreferences}
                  errors={errors}
                  formSpec={{
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ADDON_AI_LIMIT]: {
                      type: 'number',
                    },
                  }}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormLabel>Addon AI limit</FormLabel>
                        <Input
                          size={INPUT_SIZES.SM}
                          name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ADDON_AI_LIMIT}
                          type="number"
                          defaultValue={addonAILimit}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Space vertical size={16} />
                  <Button style={BUTTON_STYLES.CANCEL} link="/settings">
                    Cancel
                  </Button>
                  <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                    Save
                  </Button>
                </Form>
              </Col>
            </LoadingBox>
          </Space>
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
