import { Dropdown, DropdownIcon, Icon, LoadingDots, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';

const LIST_DETAILS: {
  [key: string]: {
    title: string;
    icon: string;
  };
} = {
  list: {
    title: 'Show compact list',
    icon: 'list',
  },
  detailed: {
    title: 'Show detailed list',
    icon: 'list',
  },
};

type Props = {
  updateListStyle: (style: string) => void;
  listStyle: 'list' | 'detailed';
  isLoading: boolean;
  projectId: string;
};

export default function EventExhibitorListMenu(props: Props) {
  const { isLoading, updateListStyle, listStyle, projectId } = props;
  const listStyleToSwitch = listStyle === 'list' ? 'detailed' : 'list';
  return (
    <Dropdown>
      <DropdownIcon title="More" tooltipPlacement="left">
        {isLoading ? <LoadingDots size="xs" /> : <Icon name="more_vertical" />}
      </DropdownIcon>
      <Menu>
        <MenuItem onClick={() => updateListStyle(listStyleToSwitch)}>
          <MenuItemIcon name={LIST_DETAILS[listStyleToSwitch]?.icon} />
          {LIST_DETAILS[listStyleToSwitch]?.title}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
