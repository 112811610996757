import { useRef, useState } from 'react';
import { GlobalVariableService, HelpDeskService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  Form,
  FormGroup,
  FormLabel,
  FormResponseType,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Textarea,
} from '@premagic/myne';

type Props = {
  selectPlan: string;
};

export function SelectPlanManual(props: Props) {
  const { selectPlan } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const $button = useRef(null);

  function onSubmit(response: FormResponseType) {
    const { message = '' } = response.data as {
      message: string;
    };

    HelpDeskService.raiseTicket({
      subject: 'SELECT SUBSCRIPTION',
      email: GlobalVariableService.getGlobalRequestingUser().email || 'text@example.com',
      message: `Select Plan <strong>${selectPlan}</strong> <br/><br/><br/> ${message}`,
      tags: ['subscription-select'],
    });
    setShowSuccess(true);
  }

  return (
    <>
      <Button block onClick={() => setShowDialog(!showDialog)} ref={$button}>
        Select
      </Button>
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        {showSuccess ? (
          <div>
            <Text
              block
              size={TEXT_SIZE.SIZE_3}
              center
              boldness={TEXT_BOLDNESS.BOLD}
              color={COLOR_SHADES.DARKER}
              primaryStyle
            >
              We have received your request!
            </Text>
            <Space vertical />
            <Text>Our support team will reach out</Text>
            <DialogFooter>
              <Button
                style={BUTTON_STYLES.CANCEL}
                onClick={() => {
                  setShowDialog(false);
                  setShowSuccess(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <div>
            <DialogTitle>Select this plan?</DialogTitle>
            <Space vertical />
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <FormLabel>Any Comments? (Optional)</FormLabel>
                <Textarea name="message" />
              </FormGroup>
              <DialogFooter>
                <Button
                  style={BUTTON_STYLES.CANCEL}
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  Close
                </Button>
                <Button style={BUTTON_STYLES.PRIMARY}>Select Plan</Button>
              </DialogFooter>
            </Form>
          </div>
        )}
      </Dialog>
    </>
  );
}
