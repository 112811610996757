import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { LEAD_CHANNELS, LEAD_SOURCE } from './CompanyLeadService';

export enum CLIENT_STATUS {
  LEAD = 'LEAD',
  ENGAGED = 'ENGAGED',
  CONFIRMED = 'CONFIRMED',
}

export const CLIENT_STATUS_TYPE_DETAILS: Record<
  CLIENT_STATUS,
  {
    id: CLIENT_STATUS;
    title: string;
  }
> = {
  [CLIENT_STATUS.LEAD]: {
    id: CLIENT_STATUS.LEAD,
    title: 'Lead',
  },
  [CLIENT_STATUS.ENGAGED]: {
    id: CLIENT_STATUS.ENGAGED,
    title: 'Engaged',
  },
  [CLIENT_STATUS.CONFIRMED]: {
    id: CLIENT_STATUS.CONFIRMED,
    title: 'Confirmed',
  },
};

export type ClientType = {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  status: CLIENT_STATUS;
  location: string; // we can converting in the store
  notes: string;
  number: string;
  channel: LEAD_CHANNELS;
  source: LEAD_SOURCE;
  address?: string;
};

export function fetchCustomerClients(clientIds: Array<string>): Promise<{ results: Array<ClientType>; count: number }> {
  return HttpUtils.get(API_URLS.CRM_CLIENT.LIST, {
    params: {
      id__in: clientIds.join(','),
    },
  }).then((response) => response.data);
}

export function fetchCustomerClient(clientId: string): Promise<ClientType> {
  return HttpUtils.get(API_URLS.CRM_CLIENT.ROOT(clientId)).then((response) => response.data);
}

export function createClient(data: Partial<ClientType>): Promise<ClientType> {
  return HttpUtils.post(API_URLS.CRM_CLIENT.LIST, data, true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function editClient(clientId: string, data: Partial<ClientType>): Promise<ClientType> {
  return HttpUtils.patch(API_URLS.CRM_CLIENT.ROOT(clientId), data, true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function searchClient(searchString: string): Promise<Array<ClientType>> {
  return HttpUtils.get(API_URLS.CRM_CLIENT.SEARCH, {
    params: {
      q: searchString,
    },
  }).then((response) => response.data);
}
