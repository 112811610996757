import {
  Button,
  BUTTON_STYLES,
  Card,
  Emoji,
  EMOJIE_SIZE,
  EMOJIS,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  Row,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import APP_URLS from '../../../../services/AppRouteURLService';
import AvatarStudioLayoutContainer from '../../../avatar-studio/AvatarStudioLayoutContainer';
import AddClientWebsiteItineraryPanelContainer from '../../../client-website/itinerary/AddClientWebsiteItineraryPanelContainer';
import EditClientWebsiteItineraryPanelContainer from '../../../client-website/itinerary/EditClientWebsiteItineraryPanelContainer';
import SharePanelContainer from '../../../folder-share/SharePanelContainer';
import WatermarkPreviewFullViewContainer from '../../../watermark/watermark-preview/WatermarkPreviewFullViewContainer';
import EditClientPanelContainer from '../../clients/edit/EditClientPanelContainer';
import EventDetailsDeliveriesLayoutContainer from '../../event-deliveries/EventDetailsDeliverablesLayoutContainer';
import { getDefaultCRMEventListUrlWithFilters } from '../../filters/CRMFilterService';
import AddOccasionPanelContainer from '../../occassions/create/AddOccasionPanelContainer';
import EditOccasionPanelContainer from '../../occassions/edit/EditOccasionPanelContainer';
import { PERMISSIONS } from '../../users/UserPermissionService';
import EditEventNotesPanelContainer from '../edit/EditEventNotesPanelContainer';
import SelectDefaultLanguagePanelContainer from '../edit/SelectDefaultLanguagePanelContainer';
import EventAttendeesLayoutContainer from '../event-attendees/EventAttendeesLayoutContainer';
import EventAttendeeFaceCheckinStatsContainer from '../event-attendees/face-checkin/face-checkin-stats/EventAttendeeFaceCheckinStatsContainer';
import EventBadgesLayoutContainer from '../event-badges/EventBadgesLayoutContainer';
import EventEmbedSettingsContainer from '../event-embed/EventEmbedSettingsContainer';
import EventFeedbackLayoutContainer from '../event-feedback/layout/EventFeedbackLayoutContainer';
import EventMarketingLayoutContainer from '../event-marketing/EventAdvocacyLayoutContainer';
import EventPostersLayoutContainer from '../event-posters/EventPostersLayoutContainer';
import EventReportLayoutContainer from '../event-report/EventReportLayoutContainer';
import EventSettingsLayoutContainer from '../event-settings/EventSettingsLayoutContainer';
import SponsorWatermarkPreviewFullViewContainer from '../event-sponsor-creatives/event-sponsor-watermark/sponsor-watermark-preview/SponsorWatermarkPreviewFullViewContainer';
import EventSponsorLayoutContainer from '../event-sponsors/EventSponsorLayoutContainer';
import EventFinancialLayoutContainer from '../financial/EventFinancialLayoutContainer';
import ReportIssuesLayoutContainer from '../report-issues/layout/ReportIssuesLayoutContainer';
import EventDetailsLayoutContainer from './EventDetailsLayoutContainer';
import EventDetailsOccasionsLayoutContainer from './event-details-occasion/EventDetailsOccasionsLayoutContainer';
import EventDetailsHeaderContainer from './header/EventDetailsHeaderContainer';
import EditEventNamePanelContainer from '../edit/EditEventNamePanelContainer';
import EventExhibitorLayoutContainer from '../event-exhibitor/EventExhibitorLayoutContainer';

type Props = {
  initData: (eventId: string) => void;
  eventId: string;
  projectId?: string;
  clientWebsiteId?: string;
  isLoading: boolean;
  currentUrlPathname: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_MANAGEMENT]: boolean;
    [PERMISSIONS.EVENT_DELIVERABLE]: boolean;
    [PERMISSIONS.EVENT_OCCASION]: boolean;
    [PERMISSIONS.EVENT_FINANCIAL]: boolean;
    [PERMISSIONS.EVENT_SPONSOR]: boolean;
    [PERMISSIONS.EVENT_SPONSOR_REPORT]: boolean;
    [PERMISSIONS.EVENT_ATTENDEES]: boolean;
  };
  errors: {
    status?: number;
    detail?: 'Not found.';
  };
  isFeatureLeadEnabled: boolean;
};

export default function EventDetailsPage(props: Props): React.ReactElement {
  const {
    initData,
    eventId,
    isLoading,
    currentUrlPathname,
    hasPermissions,
    projectId,
    clientWebsiteId,
    errors,
    isFeatureLeadEnabled,
  } = props;

  useEffect(() => {
    initData(eventId);
  }, [eventId, initData]);

  if (errors) {
    const backToLink = getDefaultCRMEventListUrlWithFilters({
      isFeatureLeadEnabled,
    });
    return (
      <InnerPage>
        <Row center fullHeight>
          <Card>
            <Space>
              <Row center columnDirection>
                <Emoji name={EMOJIS.MONKEY_EYE_CLOSED} size={EMOJIE_SIZE.LG} />
                <Space vertical />
                {errors.status === 404 ? (
                  <Text size={TEXT_SIZE.SIZE_2}>
                    Oops! It seems the event you're trying to access has been deleted!
                  </Text>
                ) : (
                  <Text size={TEXT_SIZE.SIZE_2}>{errors.detail}</Text>
                )}
                <Space vertical size={12} />
                <Button link={backToLink} style={BUTTON_STYLES.PRIMARY}>
                  <Icon name="arrow_left" size={ICON_SIZES.SM} />
                  Back to Events
                </Button>
              </Row>
            </Space>
          </Card>
        </Row>
      </InnerPage>
    );
  }

  return (
    <InnerPage>
      <EventDetailsHeaderContainer eventId={eventId} currentUrlPathname={currentUrlPathname} />
      <InnerPageContent isLoading={isLoading} hasInnerCols>
        <ErrorBoundary>
          <Switch>
            <Route path={APP_URLS.CRM.EVENT__MICRO_SITE} exact component={EventDetailsLayoutContainer} />
            {hasPermissions[PERMISSIONS.EVENT_OCCASION] && (
              <Route path={APP_URLS.CRM.EVENT__OCCASIONS} exact component={EventDetailsOccasionsLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_DELIVERABLE] && (
              <Route path={APP_URLS.CRM.EVENT__DELIVERABLE} exact component={EventDetailsDeliveriesLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_FINANCIAL] && (
              <Route path={APP_URLS.CRM.EVENT__FINANCIAL} exact component={EventFinancialLayoutContainer} />
            )}
            <Route path={APP_URLS.CRM.EVENT__REPORT} exact component={EventReportLayoutContainer} />
            <Route path={APP_URLS.CRM.EVENT__MARKETING} exact component={EventMarketingLayoutContainer} />
            <Route path={APP_URLS.CRM.EVENT__POSTERS} exact component={EventPostersLayoutContainer} />
            <Route path={APP_URLS.CRM.EVENT__AVATAR_STUDIO} exact component={AvatarStudioLayoutContainer} />
            <Route path={APP_URLS.CRM.EVENT__BADGES} exact component={EventBadgesLayoutContainer} />

            {hasPermissions[PERMISSIONS.EVENT_SPONSOR] && (
              <Route path={APP_URLS.CRM.EVENT__SPONSORS} component={EventSponsorLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_EXHIBITOR] && (
              <Route path={APP_URLS.CRM.EVENT__EXHIBITOR} component={EventExhibitorLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_ATTENDEES] && (
              <Route path={APP_URLS.CRM.EVENT__ATTENDEES} exact component={EventAttendeesLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_ATTENDEES] && (
              <Route path={APP_URLS.CRM.EVENT__REPORT_ISSUES} exact component={ReportIssuesLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_ATTENDEES] && (
              <Route path={APP_URLS.CRM.EVENT__EVENT_FEEDBACK} exact component={EventFeedbackLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_SETTINGS] && (
              <Route path={APP_URLS.CRM.EVENT__SETTINGS} exact component={EventSettingsLayoutContainer} />
            )}
            {hasPermissions[PERMISSIONS.EVENT_SETTINGS] && (
              <Route path={APP_URLS.CRM.EVENT__EMBED} exact component={EventEmbedSettingsContainer} />
            )}
            <Route path={APP_URLS.CRM.EVENT__CHECKIN_STATS} exact component={EventAttendeeFaceCheckinStatsContainer} />
          </Switch>
        </ErrorBoundary>
      </InnerPageContent>
      <EditEventNotesPanelContainer projectId={projectId} />
      <EditEventNamePanelContainer projectId={projectId} eventId={eventId} />
      <EditClientPanelContainer eventId={eventId} />
      <AddOccasionPanelContainer eventId={eventId} />
      <EditOccasionPanelContainer eventId={eventId} />
      <SelectDefaultLanguagePanelContainer eventId={eventId} />
      {projectId && <SharePanelContainer projectId={projectId} eventId={eventId} />}
      {projectId && clientWebsiteId && (
        <>
          <AddClientWebsiteItineraryPanelContainer websiteId={clientWebsiteId} projectId={projectId} />
          <EditClientWebsiteItineraryPanelContainer websiteId={clientWebsiteId} projectId={projectId} />
        </>
      )}
      <Route
        path={APP_URLS.CRM.EVENT__SPONSORS__FOLDER_WATERMARK_PREVIEW}
        component={SponsorWatermarkPreviewFullViewContainer}
      />
      <Route
        path={APP_URLS.CRM.EVENT__SPONSORS__PROJECT_WATERMARK_PREVIEW}
        component={WatermarkPreviewFullViewContainer}
      />
    </InnerPage>
  );
}
