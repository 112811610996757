import { ModalDuck } from '@premagic/common-ducks';
import {
  EventAttendeesService,
  EventCustomFromFieldsService,
  EventTrackerService,
  PeoplePosterService,
  Schemas,
} from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ArrayUtils, BrowserUtils, CountryCodeUtils, ErrorTracker } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { isEmpty, uniq } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS, MODALS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { pagesSelector } from '../../../../reducers/selectors';
import { toastMessage } from '../../../../reducers/ToastStore';
import { eventPosterEntitiesSelector } from '../../event-posters/EventPostersDataDuck';
import {
  attendeeIdForEditSelector,
  attendeeSearchTermSelector,
  setEditStatusOfAttendee,
} from './EventAttendeesPageDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_ATTENDEES');

const setEventAttendeeData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addEventAttendeeData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, id, data) => ({
  [id]: data,
}));

const updateEventAttendeeData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, id, data) => ({
  [id]: data,
}));
const bulkUpdateEventAttendeeData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);

export const removeEventAttendeeData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const getEventAttendeesForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesService.fetchEventAttendeesForProject(projectId)
      .then((response) => {
        if (!isEmpty(response)) {
          const normalizedData = normalize(response, Schemas.EventAttendees);
          dispatch(setEventAttendeeData(dispatch, normalizedData.entities.attendees));
        } else {
          dispatch(setEventAttendeeData(dispatch, {}));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_ATTENDEES_FETCH_ERROR', e);
      });
  },
);

export const downloadEventAttendeesForProject = createAction(
  getActionType('DATA', 'DOWNLOAD'),
  (dispatch, projectId: string, projectName: string) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.DOWNLOAD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesService.downloadEventAttendeesForProject(projectId)
      .then((response) => {
        const blob = new Blob([response], { type: 'application/csv' });

        const url = window.URL.createObjectURL(blob);
        BrowserUtils.downloadAsFile({ file: url, fileName: `${projectName}_people_list.csv` || 'people_list.csv' });

        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Opps... something went wrong');
        ErrorTracker.logError('EVENT_ATTENDEES_DOWNLOAD_DATA_ERROR', e);
      });
  },
);

export const downloadBulkRegistrationEventAttendeesCSV = createAction(
  getActionType('DATA', 'DOWNLOAD_ATTENDEE_CSV'),
  (dispatch, projectId: string, projectName: string) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CSV_BULK_DOWNLOAD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesService.downloadBulkRegistrationEventAttendeesCSV(projectId)
      .then((response) => {
        const blob = new Blob([response], { type: 'application/csv' });

        const url = window.URL.createObjectURL(blob);
        BrowserUtils.downloadAsFile({ file: url, fileName: `${projectName}_people_list.csv` || 'people_list.csv' });

        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Opps... something went wrong');
        ErrorTracker.logError('EVENT_ATTENDEES_DOWNLOAD_BULK_CSV_DATA_ERROR', e);
      });
  },
);

export const uploadNewBulkRegistrationEventAttendeesCSV = createAction(
  getActionType('DATA', 'UPLOAD_NEW_ATTENDEE_CSV'),
  (dispatch, projectId: string, csvFile: File) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPLOAD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesService.uploadNewEventAttendeesCSV(projectId, csvFile)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage(
          'success',
          'Upload successful. Please wait a moment while the CSV is processed. The list will be updated shortly.',
          8000,
        );
        dispatch(getEventAttendeesForProject(dispatch, projectId));
        dispatch(toggleModalVisibility(dispatch, MODALS.PEOPLE.BULK_UPDATE_EVENT_ATTENDEE_LIST, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        // toastMessage('error', 'Opps... something went wrong');
        ErrorTracker.logError('EVENT_ATTENDEES_UPLOAD_NEW_ATTENDEE_BULK_CSV_ERROR', e);
      });
  },
);

export const updateBulkRegistrationEventAttendeesCSV = createAction(
  getActionType('DATA', 'UPDATE_ATTENDEE_CSV'),
  (dispatch, projectId: string, csvFile: File) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CSV_BULK_UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesService.updateEventAttendeesCSV(projectId, csvFile)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage(
          'success',
          'Upload successful. Please wait a moment while the CSV is processed. The list will be updated shortly.',
          8000,
        );
        dispatch(getEventAttendeesForProject(dispatch, projectId));
        dispatch(toggleModalVisibility(dispatch, MODALS.PEOPLE.BULK_UPDATE_EVENT_ATTENDEE_LIST, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        // toastMessage('error', 'Opps... something went wrong');
        ErrorTracker.logError('EVENT_ATTENDEES_DOWNLOAD_BULK_CSV_DATA_ERROR', e);
      });
  },
);

export const addEventAttendee = createAction(
  getActionType('ACTION', 'CREATE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: EventAttendeesService.NewEventAttendeeType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    if (formResponse.data.email?.includes('@shell')) {
      dispatch(
        setErrorState(dispatch, loadingKey, {
          email: 'Please use your personal email address.',
        }),
      );
    }
    if (!CountryCodeUtils.isValidPhoneNumber(formResponse.data.phone)) {
      dispatch(
        setErrorState(dispatch, loadingKey, {
          phone: 'Please enter a valid phone number',
        }),
      );
    }

    const { data, errors } = formResponse;
    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, errors));
      return;
    }
    EventAttendeesService.createEventAttendee(projectId, data)
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EVENT_ATTENDEES.CREATE, {
          name: data.name,
        });
        dispatch(addEventAttendeeData(dispatch, response.registration_id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('success', 'Saved');
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_ADD_PANEL, false));
        setTimeout(() => {
          dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_ADD_PANEL, true));
        }, 1);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_ATTENDEES_ADD_ERROR', e);
      });
  },
);

export const updateEventAttendee = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; attendeeId: string },
    formResponse: FormResponseType & {
      data: EventAttendeesService.EventAttendeeType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    EventAttendeesService.updateEventAttendee(options, data)
      .then((response) => {
        dispatch(updateEventAttendeeData(dispatch, response.registration_id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('success', 'Saved');
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_ATTENDEES', e);
      });
  },
);
export const updateEventAttendeeApprovalStatus = createAction(
  getActionType('APPROVAL_STATUS', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; attendeeId: string },
    status: EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS,
  ) => {
    const { attendeeId } = options;
    const loadingKey = LOADINGS.EVENT_ATTENDEES.UPDATE_STATUS(attendeeId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesService.updateEventAttendeeApprovalStatus(options, status)
      .then((response) => {
        dispatch(updateEventAttendeeData(dispatch, response.registration_id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(setEditStatusOfAttendee(dispatch, null));
        dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_EDIT_STATUS, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_STATUS_EVENT_ATTENDEES', e);
      });
  },
);

export const removeEventAttendee = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, projectId: string, attendeeId: string) => {
    dispatch(removeEventAttendeeData(dispatch, attendeeId));
    EventAttendeesService.deleteEventAttendee(projectId, attendeeId).then((data) => data);
  },
);

export const bulkUpdateEventAttendeeDisplayId = createAction(
  getActionType('ACTION', 'UPDATE_BULK_ID'),
  (dispatch, projectId: string, data: Record<string, Partial<EventAttendeesService.EventAttendeeType>>) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.BULK_UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    dispatch(bulkUpdateEventAttendeeData(dispatch, data));
    EventAttendeesService.bulkUpdateEventAttendeeDisplayIds(projectId, data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.EventAttendees);
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_ATTENDEES', e);
      });
  },
);

type EventAttendeeItemType = IMap<string, EventAttendeesService.EventAttendeeType>;
type StateType = {
  items: EventAttendeeItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setEventAttendeeData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventAttendeeData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateEventAttendeeData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [bulkUpdateEventAttendeeData.toString()]: (state, action) => ({
      ...state,
      items: (state as StateType).items.map((item) => {
        if (action.payload[item.registration_id]) {
          return Object.assign({}, item, {
            custom_fields: Object.assign({}, item.custom_fields, action.payload[item.registration_id].custom_fields),
          });
        }
        return item;
      }),
    }),
    [removeEventAttendeeData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

const eventAttendeeDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.eventAttendees.data.attendees as StateType,
);

const eventAttendeeEntitiesSelector = createSelector(eventAttendeeDataSelector, (attendees) =>
  attendees.items.sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true)),
);

export const eventAttendeesSelector = createSelector(eventAttendeeEntitiesSelector, (attendees) => attendees.toJSON());
export const eventAttendeesArraySelector = createSelector(eventAttendeeEntitiesSelector, (attendees) =>
  attendees.valueSeq().toArray(),
);

export const eventAttendeesIdsSelector = createSelector(eventAttendeeEntitiesSelector, (attendees) =>
  attendees
    .sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name))
    .keySeq()
    .toArray(),
);

export type EventAttendeesInsightType = {
  total: number;
  totalCheckedIn: 0;
  totalNotCheckedIn: 0;
  totalApproved: 0;
  totalRejected: 0;
  totalPendingApproval: 0;
  totalWaitingList: 0;
  totalPeopleType: Partial<Record<EventAttendeesService.EVENT_PEOPLE_TYPE, number>>;
  totalPhotoDelivery: 0;
  totalPhotoDeliveryPending: 0;
};
export const eventAttendeesInsightSelector = createSelector(
  eventAttendeeEntitiesSelector,
  (attendees) =>
    attendees.reduce(
      (result, attendee) => {
        const {
          people_type: type,
          approval: approvalStatus = EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.PENDING,
          process_status: photoDeliveryStatus,
        } = attendee;
        return {
          ...result,
          total: result.total + 1,
          totalCheckedIn: result.totalCheckedIn + (attendee.last_user_check_in_time ? 1 : 0),
          totalNotCheckedIn: result.totalNotCheckedIn + (attendee.last_user_check_in_time ? 0 : 1),
          totalApproved:
            result.totalApproved +
            (approvalStatus === EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.APPROVED ? 1 : 0),
          totalRejected:
            result.totalRejected +
            (approvalStatus === EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.REJECTED ? 1 : 0),
          totalPendingApproval:
            result.totalPendingApproval +
            (approvalStatus === EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.PENDING ? 1 : 0),
          totalWaitingList:
            result.totalWaitingList +
            (approvalStatus === EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.WAITLIST ? 1 : 0),
          totalPhotoDelivery:
            result.totalPhotoDelivery +
            (photoDeliveryStatus === EventAttendeesService.EVENT_REGISTRATION_PROCESS.DONE ? 1 : 0),
          totalPhotoDeliveryPending:
            result.totalPhotoDeliveryPending +
            (photoDeliveryStatus !== EventAttendeesService.EVENT_REGISTRATION_PROCESS.DONE ? 1 : 0),
          totalPeopleType: {
            ...result.totalPeopleType,
            [type]: result.totalPeopleType?.[type] ? result.totalPeopleType[type] + 1 : 0,
          },
        };
      },
      {
        total: 0,
        totalCheckedIn: 0 as number,
        totalNotCheckedIn: 0 as number,
        totalApproved: 0 as number,
        totalRejected: 0 as number,
        totalPendingApproval: 0 as number,
        totalWaitingList: 0 as number,
        totalPhotoDelivery: 0 as number,
        totalPhotoDeliveryPending: 0 as number,
        totalPeopleType: {},
      },
    ) as EventAttendeesInsightType,
);

export const eventAttendeesIdsForTypeSelector = (type: Array<EventAttendeesService.EVENT_PEOPLE_TYPE>) =>
  createSelector(eventAttendeeEntitiesSelector, (attendees) => {
    const sortBy = 'COMPANY_NAME';
    return attendees
      .filter((item) => type.includes(item.people_type))
      .sort((a, b) => {
        switch (sortBy) {
          case 'COMPANY_NAME':
            return ArrayUtils.stringSortFunction(
              a.custom_fields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY],
              b.custom_fields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY],
            );
          default:
            return ArrayUtils.stringSortFunction(a.name, b.name);
        }
      })
      .keySeq()
      .toArray();
  });

export const eventPeopleTypeStatsSelector = createSelector(
  eventAttendeeEntitiesSelector,
  (people) =>
    // Loop through all the people item and count the number of people type
    people.reduce((acc, item) => {
      const type = item.people_type;
      if (acc[type]) {
        acc[type] += 1;
      } else {
        acc[type] = 1;
      }
      return acc;
    }, {}) as Record<EventAttendeesService.EVENT_PEOPLE_TYPE, number>,
);

export const eventAttendeeDataByIdSelector = (id: string) =>
  createSelector(eventAttendeeEntitiesSelector, (data) => data.get(id));

export const eventAttendeeWithoutPhotoSelector = (type: Array<EventAttendeesService.EVENT_PEOPLE_TYPE>) =>
  createSelector(eventAttendeesIdsForTypeSelector(type), eventAttendeeEntitiesSelector, (ids, items) =>
    ids.filter((id) => !items.get(id)?.custom_fields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]),
  );

export const eventAttendeePosterDataSelector = (posterId: string, attendeeId: string) =>
  createSelector(eventPosterEntitiesSelector, eventAttendeeEntitiesSelector, (posters, attendees) => {
    const attendee = attendees.get(attendeeId);
    const poster = posters.get(posterId);
    if (!poster) return undefined;
    const user = PeoplePosterService.getPosterUserDataFromAttendee(attendee);

    return PeoplePosterService.getPosterWithData(poster, {
      user,
    });
  });

export const eventAttendeesWithTypeSpeakerDataSelector = createSelector(eventAttendeesSelector, (items) =>
  Object.values(items).filter((item) => item.people_type === EventAttendeesService.EVENT_PEOPLE_TYPE.SPEAKER),
);

export const searchedEventAttendeesIdsSelector = createSelector(
  eventAttendeeEntitiesSelector,
  attendeeSearchTermSelector,
  (attendees, searchTerm) => {
    if (searchTerm && searchTerm.length > 2) {
      const sortedAttendeesValues = attendees.valueSeq().toArray();
      const results = EventAttendeesService.searchAttendees(sortedAttendeesValues, searchTerm);
      return results.map((item) => item.registration_id);
    }

    return attendees.keySeq().toArray();
  },
);

export const attendeeNavigationDataSelector = createSelector(
  searchedEventAttendeesIdsSelector,
  attendeeIdForEditSelector,
  (attendeeIds, currentId) => {
    const currentIndex = currentId ? attendeeIds.indexOf(currentId) : 0;
    return {
      first: attendeeIds[0],
      last: attendeeIds[attendeeIds.length],
      next: attendeeIds[currentIndex + 1],
      previous: attendeeIds[currentIndex - 1],
      current: currentIndex,
    };
  },
);

export const addOnSelector = createSelector(eventAttendeeEntitiesSelector, (attendees) => {
  const addOns = attendees
    .map((item) => item.add_ons || [])
    .filter((item) => item.length)
    .valueSeq()
    .toArray()
    .flat();
  return uniq(addOns);
});
