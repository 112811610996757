import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  addFolderToProject,
  folderIdsWithFolderTypeForProjectSelector,
} from '../../../projects/AccountProjectsDataDuck';
import { hasFeatureSelector, highlightFoldersLimitSelector } from '../../../settings/company/CompanyDataDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { hasActiveSubscriptionSelector } from '../../../settings/subscriptions/SubscriptionsDataDuck';
import AddSignatureAlbumButton from './AddSignatureAlbumButton';

const FOLDER_TYPE = FolderService.FOLDER_TYPES.HIGHLIGHT;
function mapStateToProps(state, ownProps: { projectId: string; navigateToFolder?: boolean }) {
  const { projectId, navigateToFolder } = ownProps;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(projectId, FOLDER_TYPE)(state);
  const hasSignatureAlbumFeature = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.HIGHLIGHTS)(
    state,
  );

  return {
    totalFolder: signatureFolderIds.length,
    projectId,
    hasSignatureAlbumFeature,
    folderLimit: highlightFoldersLimitSelector(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.FOLDER.ADD)(state),
    hasActiveSubscription: hasActiveSubscriptionSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    navigateToFolder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createFolder: (projectId, formResponse, navigateToFolder) => {
      dispatch(addFolderToProject(dispatch, formResponse, { projectId, folderType: FOLDER_TYPE, navigateToFolder }));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSignatureAlbumButton);
