import React from 'react';
import { History } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import APP_URLS from '../../services/AppRouteURLService';
import UserProfilePageContainer from './UserProfilePageContainer';
import EditUserProfilePageContainer from './EditUserProfilePageContainer';
import ChangePasswordPageContainer from './ChangePasswordPageContainer';

type Props = {
  history: History;
};

export default function SettingsRoutes(props: Props): JSX.Element {
  const { history } = props;
  return (
    <Router history={history}>
      <Switch>
        <Route path={APP_URLS.USER_PROFILE.INDEX} exact component={UserProfilePageContainer} />
        <Route path={APP_URLS.USER_PROFILE.EDIT} exact component={EditUserProfilePageContainer} />
        <Route path={APP_URLS.USER_PROFILE.CHANGE_PASSWORD} exact component={ChangePasswordPageContainer} />
      </Switch>
    </Router>
  );
}
