import { SimpleDateUtils } from '@premagic/utils';
import {
  CRM_PAYMENT_CATEGORY_NAME,
  CRMPaymentCategoryType,
  getCRMPaymentCategoryIdFromName,
  TAX_TYPES,
} from '../CRMPaymentService';

export enum CLIENT_PAYMENT_PRESET_OPTIONS {
  SPLIT_1,
  SPLIT_2,
  SPLIT_3,
}

export enum DUE_DATE_PRESET {
  TODAY,
  MAIN_EVENT,
  AFTER_MAIN_EVENT_AND_1_MONTH,
}

function getDateFromPreset(preset: DUE_DATE_PRESET, mainDate: string): string {
  let dateAfterAMonth;
  switch (preset) {
    case DUE_DATE_PRESET.MAIN_EVENT:
      return mainDate;
    case DUE_DATE_PRESET.AFTER_MAIN_EVENT_AND_1_MONTH:
      dateAfterAMonth = SimpleDateUtils.addToDate(mainDate, 'months', 1);
      return SimpleDateUtils.getDateStringFromDate(dateAfterAMonth, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO);

    case DUE_DATE_PRESET.TODAY:
    default:
      return SimpleDateUtils.now();
  }
}

type ClientPaymentPresetPaymentType = {
  percentFromQuote: number;
  dueDatePreset: DUE_DATE_PRESET;
  categoryName: CRM_PAYMENT_CATEGORY_NAME;
};

const CLIENT_PAYMENT_PRESET_DETAILS: Record<
  CLIENT_PAYMENT_PRESET_OPTIONS,
  {
    label: string;
    id: CLIENT_PAYMENT_PRESET_OPTIONS;
    payments: Array<ClientPaymentPresetPaymentType>;
  }
> = {
  [CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_3]: {
    label: 'Split by 3 parts',
    id: CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_3,
    payments: [
      {
        percentFromQuote: 20,
        dueDatePreset: DUE_DATE_PRESET.TODAY,
        categoryName: CRM_PAYMENT_CATEGORY_NAME.ADVANCE,
      },
      {
        percentFromQuote: 60,
        dueDatePreset: DUE_DATE_PRESET.MAIN_EVENT,
        categoryName: CRM_PAYMENT_CATEGORY_NAME.PARTIAL,
      },
      {
        percentFromQuote: 20,
        dueDatePreset: DUE_DATE_PRESET.AFTER_MAIN_EVENT_AND_1_MONTH,
        categoryName: CRM_PAYMENT_CATEGORY_NAME.FINAL,
      },
    ],
  },

  [CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_2]: {
    label: 'Split by 2 parts',
    id: CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_2,
    payments: [
      {
        percentFromQuote: 40,
        dueDatePreset: DUE_DATE_PRESET.TODAY,
        categoryName: CRM_PAYMENT_CATEGORY_NAME.ADVANCE,
      },
      {
        percentFromQuote: 60,
        dueDatePreset: DUE_DATE_PRESET.MAIN_EVENT,
        categoryName: CRM_PAYMENT_CATEGORY_NAME.FINAL,
      },
    ],
  },
  [CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_1]: {
    label: 'Single payment',
    id: CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_1,
    payments: [
      {
        percentFromQuote: 100,
        dueDatePreset: DUE_DATE_PRESET.TODAY,
        categoryName: CRM_PAYMENT_CATEGORY_NAME.FULL,
      },
    ],
  },
};

export const clientPresentOptions = Object.values(CLIENT_PAYMENT_PRESET_DETAILS).map((option) => ({
  label: option.label,
  value: option.id,
}));

export type ClientPresetPaymentDetailsWithData = {
  id;
  categoryId?: number;
  percentFromQuote: number;
  amount: number;
  dueDate: string;
  taxable: TAX_TYPES;
};

function getClientPaymentDetailData(
  paymentPreset: ClientPaymentPresetPaymentType,
  data: {
    eventDate: string;
    quoteAmount: number;
    paymentCategories: Array<CRMPaymentCategoryType>;
  },
  id,
): ClientPresetPaymentDetailsWithData {
  const { percentFromQuote, dueDatePreset, categoryName } = paymentPreset;
  const { eventDate, quoteAmount, paymentCategories } = data;
  const dueDate = getDateFromPreset(dueDatePreset, eventDate);
  const categoryId = getCRMPaymentCategoryIdFromName(categoryName, paymentCategories);

  return {
    id,
    categoryId,
    percentFromQuote,
    amount: Math.ceil(quoteAmount * (percentFromQuote / 100)),
    dueDate: SimpleDateUtils.getDateStringFromDate(dueDate, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO),
    taxable: TAX_TYPES.NO_TAX,
  };
}

export function getClientPaymentPresetsForData(
  preset: CLIENT_PAYMENT_PRESET_OPTIONS,
  data: {
    eventDate: string;
    quoteAmount: number;
    paymentCategories: Array<CRMPaymentCategoryType>;
  },
): Array<ClientPresetPaymentDetailsWithData> {
  const presetDetails = CLIENT_PAYMENT_PRESET_DETAILS[preset];
  return presetDetails.payments.map((payment, idx) => getClientPaymentDetailData(payment, data, idx));
}
