import { FileService, GeneralImageEditorService, QRCodeBackgroundPosterService } from '@premagic/core';
import { SimpleImage, Space } from '@premagic/myne';
import React, { useEffect, useRef, useState } from 'react';
import { PixelCrop } from 'react-image-crop';
import getEditedImage from '../../../general-image-editor/GeneralImageEditorService';

const LOGO_ALIGNMENT_MAP = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

function getSizeForLogo(size, customStyle = false) {
  if (customStyle) {
    const SIZE_MAP = {
      lg: { height: '80px', width: '140px' }, //  Standee
      md: { height: '63px', width: '100px' }, //  Tent card
      sm: { height: '60px', width: '65px' }, //  Pocket card
      'full-image': { height: '100%', width: '100%' }, // Preview Logo
    };

    return SIZE_MAP[size];
  }

  const SIZE_MAP = {
    lg: { height: '68px', width: '180px' }, //  Standee
    md: { height: '57px', width: '130px' }, //  Tent card
    sm: { height: '56px', width: '110px' }, //  Pocket card
    'full-image': { height: '100%', width: '100%' }, // Preview Logo
  };

  return SIZE_MAP[size];
}

type VerticalLogoSpacePlaceholderProps = {
  size: 'lg' | 'md' | 'sm' | 'full-image';
};

export function VerticalLogoSpacePlaceholder(props: VerticalLogoSpacePlaceholderProps) {
  const { size } = props;
  return <div style={{ height: getSizeForLogo(size).height }} />;
}

type DisplayedPosterLogoProps = {
  logoFileData?: FileService.FolderFileType;
  size: 'lg' | 'md' | 'sm' | 'full-image';
  logoOptionType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
  companyLogo?: string;
  align?: 'left' | 'right' | 'center';
  logoMetaData?: GeneralImageEditorService.ImageEditorImageMetaDataType;
  customStyle?: boolean;
};

export function DisplayedPosterLogo(props: DisplayedPosterLogoProps) {
  const {
    logoFileData,
    size = 'md',
    logoOptionType = QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.COMPANY_LOGO,
    companyLogo,
    align = 'center',
    logoMetaData,
    customStyle,
  } = props;

  const defaultImageSrc =
    logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.COMPANY_LOGO
      ? companyLogo
      : logoFileData?.dynamic_image_url;

  const [editedLogo, setEditedLogo]: [any, any] = useState(defaultImageSrc);

  useEffect(() => {
    if (
      defaultImageSrc &&
      logoMetaData &&
      logoMetaData?.pixelCrop &&
      logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO
    ) {
      getEditedImage(
        defaultImageSrc,
        logoMetaData?.pixelCrop,
        logoMetaData?.scale,
        logoMetaData?.rotate,
        logoMetaData?.imageProperties,
      ).then((url) => {
        setEditedLogo(url);
      });
    } else if (logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO) {
      setEditedLogo(logoFileData?.dynamic_image_url);
    } else {
      // default to company logo
      setEditedLogo(companyLogo);
    }
  }, [logoMetaData, defaultImageSrc, logoOptionType, logoFileData?.dynamic_image_url, companyLogo]);

  if (!defaultImageSrc || !editedLogo) return <VerticalLogoSpacePlaceholder size={size} />;

  return (
    <div
      style={{
        width: getSizeForLogo(size, customStyle).width,
        height: getSizeForLogo(size, customStyle).height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: LOGO_ALIGNMENT_MAP[align],
        overflow: 'hidden',
        zIndex: 10,
      }}
    >
      <SimpleImage
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          objectFit: 'contain',
        }}
        src={editedLogo}
        alt="qr-poster-logo"
      />
    </div>
  );
}
