import { get } from 'lodash';
import { ClientWebsiteService } from '@premagic/core';
import { FormResponseType, GridLayout, GridLayoutItem, ImageUploader } from '@premagic/myne';

interface GenericImageType {
  download: string;
  key: string;
  ratio: number;
  url: string;
}

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  imageUploadApiURL?: string;
  required?: boolean;
  info?: string;
  title?: string;
  defaultValue?: GenericImageType | Array<GenericImageType>;
  deletePhotos?: (id: string, projectId: string, data: FormResponseType) => void;
  isLoading?: boolean;
};

function onRemoveFile(clientWebsite: ClientWebsiteService.ClientWebsiteType, fileKey: string, deletePhotos) {
  const data = { data: { data: { images: { [fileKey]: null } } }, name: fileKey };
  deletePhotos(clientWebsite.id, clientWebsite.project_id, data);
}

export default function ClientWebsitePhotoGridInput(props: Props) {
  const { clientWebsite, deletePhotos, imageUploadApiURL, required, info, title, isLoading, ...elementProps } = props;
  const fileArray: Array<any> = [];
  Array.from(Array(8)).forEach((x, i) => {
    const key = `data[images][photo_grid_${i}]`;
    const defaultImage = get(clientWebsite, key, '') as string;
    fileArray.push(
      <GridLayoutItem width={3} height={3} key={key}>
        <ImageUploader
          {...elementProps}
          name={`photo_grid_${i}`}
          // @ts-ignore
          defaultValue={defaultImage}
          imageUploadApiURL={imageUploadApiURL}
          onRemoveFile={(name) => {
            if (clientWebsite) onRemoveFile(clientWebsite, name, deletePhotos);
          }}
          isLoading={isLoading}
        />
      </GridLayoutItem>,
    );
  });

  return <GridLayout>{fileArray}</GridLayout>;
}
