import { EventsService, LabelService } from '@premagic/core';
import { FormLabel, Select } from '@premagic/myne';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { CRM_FILTER_KEYS } from './CRMFilterTypes';

type CRMFilterItemDialogContentProps = {
  value?: string;
  onChange: (newFilterValue: string) => void;
};

const statusOptions = Object.values(EventsService.EVENT_STATUS_TYPE_DETAILS).map((type) => ({
  value: type.id,
  label: type.title,
}));

function CRMFilterEventStatusDialog(props: CRMFilterItemDialogContentProps): JSX.Element {
  const { value, onChange } = props;
  const selectedOption = statusOptions.find((option) => option.value === value) || statusOptions[0];
  return (
    <div>
      <FormLabel>Event status</FormLabel>
      <Select
        options={statusOptions}
        value={selectedOption}
        name="status"
        onChange={(e) => onChange(e?.value as string)}
        isMulti={false}
        autoFocus
      />
    </div>
  );
}

const priorityOptions = Object.values(EventsService.EVENT_SORT_BY_TYPE_DETAILS).map((type) => ({
  value: type.id,
  label: type.title,
}));

function CRMFilterEventSortByDialog(props: CRMFilterItemDialogContentProps): JSX.Element {
  const { value, onChange } = props;
  const selectedOption = priorityOptions.find((option) => option.value === value) || priorityOptions[0];
  return (
    <div>
      <FormLabel>Sort by</FormLabel>
      <Select
        options={priorityOptions}
        value={selectedOption}
        name="event_sort_by"
        onChange={(e) => onChange(e?.value as string)}
        isMulti={false}
        autoFocus
      />
    </div>
  );
}

type CRMFilterEventLabelsDialogProps = {
  value?: Array<string>;
  onChange: (newFilterValue: Array<string>) => void;
  labelOptions: Array<LabelService.LabelsType>;
};

function CRMFilterEventLabelsDialog(props: CRMFilterEventLabelsDialogProps): JSX.Element {
  const { value, onChange, labelOptions } = props;

  const selectedOptions = labelOptions
    .filter((option) => value?.includes(option.name))
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const labelOptionsForEvent = useMemo(
    () =>
      labelOptions?.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [labelOptions],
  );

  const debouncedOnChange = useMemo(() => debounce(onChange, 700), [onChange]);

  function handleOnChange(data) {
    const labels = data.map((item) => item.label);
    debouncedOnChange(labels);
  }

  return (
    <div>
      <FormLabel>Event labels</FormLabel>
      <Select
        options={labelOptionsForEvent}
        value={selectedOptions}
        name="event_labels"
        onChange={handleOnChange}
        isMulti
        autoFocus
      />
    </div>
  );
}

const paymentStatusOptions = [
  {
    value: 'false',
    label: 'Pending',
  },
  {
    value: 'true',
    label: 'Settled',
  },
];

function CRMFilterEventPaymentStatusDialog(props: CRMFilterItemDialogContentProps): JSX.Element {
  const { value, onChange } = props;
  const selectedOption = paymentStatusOptions.find((option) => option.value === value) || paymentStatusOptions[0];
  return (
    <div>
      <FormLabel>Payments status</FormLabel>
      <Select
        options={paymentStatusOptions}
        value={selectedOption}
        name="is_settled"
        onChange={(e) => onChange(e?.value as string)}
        isMulti={false}
        autoFocus
      />
    </div>
  );
}

const CRMFilterItemsDialogContent: Record<string, (props: any) => JSX.Element> = {
  [CRM_FILTER_KEYS.EVENT_STATUS]: CRMFilterEventStatusDialog,
  [CRM_FILTER_KEYS.STATUS]: CRMFilterEventStatusDialog,
  [CRM_FILTER_KEYS.EVENT_SORT_BY]: CRMFilterEventSortByDialog,
  [CRM_FILTER_KEYS.EVENT_LABELS]: CRMFilterEventLabelsDialog,
  [CRM_FILTER_KEYS.PAYMENT_STATUS]: CRMFilterEventPaymentStatusDialog,
};

export default CRMFilterItemsDialogContent;
