import React from 'react';
import { Label, LABEL_STYLES } from '@premagic/myne';

type Props = {
  labels?: Array<string>;
};
export default function EventLabels(props: Props): JSX.Element {
  const { labels } = props;
  if (!labels) return <Label style={LABEL_STYLES.PILL_DEFAULT}>All</Label>;

  return (
    <>
      {labels.map((item, idx) => {
        const key = `dummy-${idx}`;
        return (
          <Label key={key} style={LABEL_STYLES.PILL_PRIMARY}>
            {item}
          </Label>
        );
      })}
    </>
  );
}
