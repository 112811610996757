import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { fetchFilesWithFilter } from './DAMLibraryService';
import { setFilesData } from '../../images/FilesDataDuck';
import { DAM_LIBRARY_FILTER_KEYS } from './filters/DAMLibraryFilterTypes';

const getActionType = ActionTypeUtils.getActionTypeFunction('DAM', true);

export const fetchAllFiles = createAction(getActionType('FILES', 'GET'), (dispatch, filters) => {
  const loadingKey = LOADINGS.DAM_LIBRARY.FETCH_FILES;
  // make filters independent
  // const canFetch = !!filters[DAM_LIBRARY_FILTER_KEYS.FACE_ID];
  // if (!canFetch) return;

  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchFilesWithFilter(filters)
    .then((response) => {
      dispatch(setFilesData(dispatch, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    })
    .catch((error) => {
      dispatch(setFilesData(dispatch, {}));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(
        setErrorState(
          dispatch,
          loadingKey,
          error.response || {
            message: 'Something went wrong',
          },
        ),
      );
      ErrorTracker.logError('Get all files for people face', error);
    });
});

const initialState = {};

export default handleActions({}, initialState);
