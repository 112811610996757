import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  FileUploader,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_SIZE,
  HighlightPreview,
  Position,
  FormResponseType,
  SubTitle,
} from '@premagic/myne';
import { FileService, ProjectMetaService, ProjectService } from '@premagic/core';
import { toastMessage } from '../../../reducers/ToastStore';
import HighlightDesignTitleAndLogoSettingsContainer from '../highlight-folders/highlight-gallery-design/HighlightDesignTitleAndLogoSettingsContainer';

type Props = {
  uploadCoverPhoto: (projectId: string, coverPhoto: File, cordiantes: { x: number; y: number }) => void;
  showSetFocalPointModal: () => void;
  projectId: string;
  isLoading: boolean;
  coverPhoto?: FileService.FolderFileType | null;
  focalPoint?: { x: number; y: number };
  companyLogo?: string;
  companyName: string;
  project: ProjectService.ProjectType;
  initFontData: (primaryFontFamily: string, secondaryFontFamily?: string) => void;
  primaryFontFamily?: string;
  secondaryFontFamily?: string;
  isFontLoading: boolean;
  coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  isCoverTitleHidden?: boolean;
  isCompanyLogoHidden?: boolean;
  updateProjectMeta: (projectId: string, data: FormResponseType) => void;
  isSaving?: boolean;
  albumCoverTitle?: string;
};

export default function ProjectHighlightCoverPhoto(props: Props): JSX.Element {
  const {
    uploadCoverPhoto,
    projectId,
    isLoading,
    coverPhoto,
    showSetFocalPointModal,
    focalPoint,
    companyLogo,
    companyName,
    project,
    initFontData,
    primaryFontFamily,
    secondaryFontFamily,
    isFontLoading,
    coverLayout,
    isCoverTitleHidden,
    isCompanyLogoHidden,
    updateProjectMeta,
    isSaving,
    albumCoverTitle,
  } = props;
  const defaultFocalPoint = { x: 50, y: 50 };

  function handleFormSubmit(data) {
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_TITLE]: data.data.hide_cover_title,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_LOGO]: data.data.hide_cover_logo,
        [ProjectMetaService.PROJECT_META_TYPES.ALBUM_COVER_TITLE]: data.data.album_cover_title,
      },
    });
  }

  // triggers on font change
  useEffect(() => {
    if (primaryFontFamily) {
      initFontData(primaryFontFamily, secondaryFontFamily);
    }
  }, [primaryFontFamily, secondaryFontFamily, initFontData]);

  if (!coverPhoto?.dynamic_image_url?.length) {
    return (
      <Card style={CARD_STYLES.TRANSPARENT}>
        <Space size={16}>
          <Row center>
            <Color shade={COLOR_SHADES.LIGHT}>
              <Icon name="image" size={ICON_SIZES.LG} />
            </Color>
          </Row>
          <Space vertical size={5} />
          <Text size={TEXT_SIZE.SIZE_2} center block>
            Set a banner for the gallery
          </Text>
          <Space vertical size={5} />

          <Row center>
            <FileUploader
              maxSize={35}
              accept="image/jpeg, image/png"
              onError={(message) => toastMessage('error', message)}
              onChange={(e, files) => {
                if (files?.length) uploadCoverPhoto(projectId, files[0], defaultFocalPoint);
                return {};
              }}
            >
              <Button isLoading={isLoading} style={BUTTON_STYLES.PRIMARY}>
                Choose Banner
              </Button>
            </FileUploader>
          </Row>
          <Space vertical size={2} />
          <Text size={TEXT_SIZE.SIZE_5} muted block center>
            1,584 pixels × 396 pixels (1.91/1 ratio), Max Photo size: 35MB
          </Text>
        </Space>
      </Card>
    );
  }

  return (
    <div>
      <Row>
        <Col rightAlighed size={null}>
          <Row>
            <Button size={BUTTON_SIZE.SM} isLoading={isLoading} onClick={showSetFocalPointModal}>
              Set focal point
            </Button>
            <Space size={2} />
            <FileUploader
              maxSize={35}
              accept="image/jpeg, image/png"
              onError={(message) => toastMessage('error', message)}
              onChange={(e, files) => {
                if (files?.length) uploadCoverPhoto(projectId, files[0], defaultFocalPoint);
                return {};
              }}
            >
              <Button size={BUTTON_SIZE.SM} isLoading={isLoading}>
                Change Cover Photo
              </Button>
            </FileUploader>
          </Row>
        </Col>
      </Row>
      <Space vertical />
      <Col size={null}>
        <HighlightPreview
          imageSource={coverPhoto?.dynamic_image_url}
          alt={coverPhoto?.image_key}
          focalPoint={focalPoint}
          companyLogo={companyLogo}
          companyName={companyName}
          title={albumCoverTitle || project?.project_name}
          isFontLoading={isFontLoading}
          coverLayout={coverLayout}
          isCoverTitleHidden={isCoverTitleHidden}
          isCoverLogoHidden={isCompanyLogoHidden}
        />
        <Position align="bottom">
          <Space size={6}>
            <HighlightDesignTitleAndLogoSettingsContainer
              isCoverTitleHidden={isCoverTitleHidden}
              isCoverLogoHidden={isCompanyLogoHidden}
              coverTitle={albumCoverTitle || project?.project_name}
              handleFormSubmit={handleFormSubmit}
              isLoading={isSaving}
            />
          </Space>
        </Position>
      </Col>
    </div>
  );
}
