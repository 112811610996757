import { connect } from 'react-redux';
import { FolderFilePositionOrderService } from '@premagic/core';
import { filesSelector } from '../../../images/FilesDataDuck';
import FolderGallery from './FolderGallery';
import {
  fetchFilePositionOrdersForFolder,
  updateFilesPosition,
  foldersEntitiesSelector,
  filesOrderForFolderSelector,
  toggleFileSelectionForBulkAction,
  selectedFilesForBulkActionSelector,
  isInBulkActionSelector,
  albumViewWithFileNameSelector,
} from '../AccountFoldersDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { navigateTo } from '../../../../../../services/RouterService';
import { folderMetaDataForFolderSelector, saveFolderMetaData } from '../../folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId, folderId } = ownProps;
  const folder = foldersEntitiesSelector(state)[folderId];
  return {
    isLoading: isLoadingSelector(LOADINGS.FOLDER_FILES_POSITION_ORDER.FETCH)(state),
    projectId,
    folderId,
    folder,
    folderType: folder?.folder_type,
    selectedFilesForBulkAction: selectedFilesForBulkActionSelector(state),
    isInBulkAction: isInBulkActionSelector(state),
    files: filesSelector(state),
    fileIds: filesOrderForFolderSelector(folderId)(state),
    folderMetaData: folderMetaDataForFolderSelector(folderId)(state),
    isAlbumViewWithFileName: albumViewWithFileNameSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFilePositionOrdersForFolder: (projectId: string, folderId: string) => {
      dispatch(fetchFilePositionOrdersForFolder(dispatch, projectId, folderId));
    },
    setFileIdsOrder: (updateFilePositionOrderData: FolderFilePositionOrderService.UpdateFilesPositionDataType) => {
      dispatch(updateFilesPosition(dispatch, updateFilePositionOrderData));
    },
    navigateTo: (url) => {
      navigateTo(dispatch, url);
    },
    saveFolderMetaData: (projectId: string, folderId: string, data) => {
      dispatch(saveFolderMetaData(dispatch, projectId, folderId, { ...data }));
    },
    toggleFileSelectionForBulkAction: (fileId: string, selected: boolean) => {
      dispatch(toggleFileSelectionForBulkAction(dispatch, fileId, selected));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderGallery);
