import {
  DeckSlideAccept,
  DeckSlideAcceptTextOnly,
  DeckSlideEquipments,
  DeckSlideEquipmentsTextOnly,
  DeckSlidePackage,
  DeckSlidePackageTextOnly,
  DeckSlideSplash,
  DeckSlideTestimonial,
  DeckSlideTextAndContent,
  DeckSlideTextAndContentTextOnly,
} from './DeckSlideLayouts';
import { DECK_SLIDE_DATA_TYPES, DeckSlideDataType } from './DeckSlideLayoutDataService';
import { DeckSlideEquipmentsItemsTextOnly } from './DeckSlideComponents';

export enum DECK_SLIDE_LAYOUTS {
  WELCOME = 'layout-1',
  BLANK_1 = 'layout-2',
  // LAYOUT_3 = 'layout-3',
  // LAYOUT_7 = 'layout-7',
  PACKAGE = 'package',
  EQUIPMENT = 'equipment',
  TESTIMONIALS = 'testimonials',
  ACCEPT = 'accept',
  // LAYOUT_8 = 'layout-8',
  // LAYOUT_9 = 'layout-9',
}

function getSlideData(options: {
  title?: string;
  subtitle?: string;
  content?: string;
  color?: string;
}): DeckSlideDataType {
  const { title, subtitle, content, color = '#242C39' } = options;

  return {
    [DECK_SLIDE_DATA_TYPES.TITLE]: {
      value: title,
    },
    [DECK_SLIDE_DATA_TYPES.SUB_TITLE]: {
      value: subtitle,
    },
    [DECK_SLIDE_DATA_TYPES.LARGE_TEXT]: {
      value: content,
    },
    [DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR]: {
      value: color,
    },
  };
}

type DeckSlideTypesWithBackground = Exclude<
  DECK_SLIDE_DATA_TYPES,
  | DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE
  | DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR
  | DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO
>;

export const DECK_SLIDE_LAYOUTS_DETAILS: Record<
  DECK_SLIDE_LAYOUTS,
  {
    id: DECK_SLIDE_LAYOUTS;
    name: string;
    deprecated?: boolean;
    component: (props) => JSX.Element;
    componentTextOnly?: (props) => JSX.Element;
    availableDataTypes: Array<DeckSlideTypesWithBackground>;
    iconPath: string;
    defaultStructure: DeckSlideDataType;
  }
> = {
  // [DECK_SLIDE_LAYOUTS.LAYOUT_3]: {
  //   id: DECK_SLIDE_LAYOUTS.LAYOUT_3,
  //   name: 'Deprecate',
  //   deprecated: true,
  //   component: DeckSlideTextAndContent,
  //   availableDataTypes: [
  //     DECK_SLIDE_DATA_TYPES.TITLE,
  //     DECK_SLIDE_DATA_TYPES.SUB_TITLE,
  //     DECK_SLIDE_DATA_TYPES.LARGE_TEXT,
  //   ],
  //   iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
  //   defaultStructure: getSlideData({ title: '<p>Slide <strong>Title</strong></p>' }),
  // },
  // [DECK_SLIDE_LAYOUTS.LAYOUT_7]: {
  //   deprecated: true,
  //   id: DECK_SLIDE_LAYOUTS.LAYOUT_7,
  //   name: 'Deprecate',
  //   component: DeckSlideAccept,
  //   availableDataTypes: [DECK_SLIDE_DATA_TYPES.TITLE],
  //   iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
  //   defaultStructure: getSlideData({ title: '<p>Ready to <strong>Accept?</strong></p>' }),
  // },

  [DECK_SLIDE_LAYOUTS.WELCOME]: {
    id: DECK_SLIDE_LAYOUTS.WELCOME,
    name: 'Welcome Splash',
    component: DeckSlideSplash,
    componentTextOnly: DeckSlideTextAndContentTextOnly,
    availableDataTypes: [DECK_SLIDE_DATA_TYPES.TITLE],
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
    defaultStructure: getSlideData({ title: '<p>Exclusive Proposal</p>' }),
  },
  [DECK_SLIDE_LAYOUTS.BLANK_1]: {
    id: DECK_SLIDE_LAYOUTS.BLANK_1,
    name: 'Blank Slide',
    component: DeckSlideTextAndContent,
    componentTextOnly: DeckSlideTextAndContentTextOnly,
    availableDataTypes: [
      DECK_SLIDE_DATA_TYPES.TITLE,
      DECK_SLIDE_DATA_TYPES.SUB_TITLE,
      DECK_SLIDE_DATA_TYPES.LARGE_TEXT,
    ],
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
    defaultStructure: getSlideData({ title: '<p>Slide <strong>Title</strong></p>' }),
  },

  [DECK_SLIDE_LAYOUTS.PACKAGE]: {
    id: DECK_SLIDE_LAYOUTS.PACKAGE,
    name: 'Package',
    component: DeckSlidePackage,
    componentTextOnly: DeckSlidePackageTextOnly,
    availableDataTypes: [DECK_SLIDE_DATA_TYPES.TITLE],
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
    defaultStructure: getSlideData({ title: '<p>The <strong>Package</strong></p>' }),
  },
  [DECK_SLIDE_LAYOUTS.EQUIPMENT]: {
    id: DECK_SLIDE_LAYOUTS.EQUIPMENT,
    name: 'Equipments',
    component: DeckSlideEquipmentsTextOnly,
    componentTextOnly: DeckSlideEquipmentsItemsTextOnly,
    availableDataTypes: [DECK_SLIDE_DATA_TYPES.TITLE],
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
    defaultStructure: getSlideData({ title: '<p>Our <strong>Tools</strong></p>' }),
  },
  [DECK_SLIDE_LAYOUTS.TESTIMONIALS]: {
    id: DECK_SLIDE_LAYOUTS.TESTIMONIALS,
    name: 'Testimonials',
    component: DeckSlideTestimonial,
    availableDataTypes: [DECK_SLIDE_DATA_TYPES.TITLE],
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
    defaultStructure: getSlideData({ title: '<p>Testimonial</p>' }),
  },

  [DECK_SLIDE_LAYOUTS.ACCEPT]: {
    id: DECK_SLIDE_LAYOUTS.ACCEPT,
    name: 'Accept Slide',
    component: DeckSlideAccept,
    componentTextOnly: DeckSlideAcceptTextOnly,
    availableDataTypes: [DECK_SLIDE_DATA_TYPES.TITLE, DECK_SLIDE_DATA_TYPES.TERMS_AND_CONDITIONS],
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
    defaultStructure: getSlideData({ title: '<p>Ready to <strong>Accept?</strong></p>' }),
  },
};
