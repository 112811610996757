import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { pagesSelector } from '../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_FORM_FIELDS', true);

export const setEditOfFormField = createAction(
  getActionType('DATA', 'EDIT'),
  (dispatch: Dispatch, occasionId: string | null) => occasionId,
);

type RegistrationFormPageType = {
  editFormFieldId: string | null;
};
const initialState = {
  editFormFieldId: undefined,
};

export default handleActions(
  {
    [setEditOfFormField.toString()]: (state, action: { payload }) => ({
      ...state,
      editFormFieldId: action.payload,
    }),
  },
  initialState,
);

export const eventSponsorPageSelector = createSelector(
  pagesSelector,
  (state) => state.registrationForm.registrationForm as RegistrationFormPageType,
);
export const formfieldIdForEditSelector = createSelector(eventSponsorPageSelector, (page) => page.editFormFieldId);
