import {
  Row,
  Text,
  Space,
  ToggleSwitch,
  COLOR_SHADES,
  TEXT_SIZE,
  Col,
  Color,
  Icon,
  ICON_SIZES,
  TEXT_BOLDNESS,
} from '@premagic/myne';

type Props = {
  isLoading: boolean;
  projectId: string;
  toggleReviewStatus: (projectId: string, foldersIds: Array<string>, status: boolean) => void;
  foldersIds: Array<string>;
  isReviewEnabled: boolean;
};

export default function ToggleFolderStatus(props: Props): JSX.Element | null {
  const { foldersIds, toggleReviewStatus, isLoading, projectId, isReviewEnabled } = props;

  return (
    <Row>
      <div>
        <Row vcenter>
          <Color shade={COLOR_SHADES.LIGHT} inline>
            <Icon name="check_circle" size={ICON_SIZES.SM} /> <Space size={1} />
          </Color>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Allow photo Selection</Text>
        </Row>
      </div>
      <Space size={4} />
      <Col size={2} rightAlighed>
        <Row vcenter columnDirection>
          <ToggleSwitch
            isLoading={isLoading}
            name="toggle_review_status"
            checked={isReviewEnabled}
            onChange={(value) => {
              toggleReviewStatus(projectId, foldersIds, value);
            }}
          />

          <Space size={1} vertical />
          {isReviewEnabled ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
              Yes
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
              No
            </Text>
          )}
        </Row>
      </Col>
    </Row>
  );
}
