import { ArrayUtils, HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { EVENT_CATEGORY } from './EventsService';

export enum OCCASIONS {
  WEDDING = 'Wedding',
  ENGAGEMENT = 'Engagement',
  COUPLE_SHOOT = 'Couple shoot',
  PELLIKODUKU = 'Pellikoduku',
  PELLIKUTHURU = 'Pellikuthuru',
  SANGEETH = 'Sangeeth',
  VRADHAM = 'Vradham',
  COCKTAIL = 'Cocktail',
  HALDI = 'Haldi',
  RECEPTION = 'Reception',
  MEHNDI = 'Mehndi',
  TEMPLE_SHOOT = 'Temple Shoot',
  PRE_WEDDING = 'Pre Wedding',
  POST_WEDDING = 'Post Wedding',
  OTHERS = 'Others',
}

export const OCCASIONS_DETAILS: Record<
  OCCASIONS,
  {
    id: OCCASIONS;
    eventCategory: Array<EVENT_CATEGORY>;
    isInternational: boolean;
  }
> = {
  [OCCASIONS.ENGAGEMENT]: {
    id: OCCASIONS.ENGAGEMENT,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.COUPLE_SHOOT]: {
    id: OCCASIONS.COUPLE_SHOOT,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.PRE_WEDDING]: {
    id: OCCASIONS.PRE_WEDDING,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.PELLIKODUKU]: {
    id: OCCASIONS.PELLIKODUKU,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.PELLIKUTHURU]: {
    id: OCCASIONS.PELLIKUTHURU,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.SANGEETH]: {
    id: OCCASIONS.SANGEETH,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.VRADHAM]: {
    id: OCCASIONS.VRADHAM,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.HALDI]: {
    id: OCCASIONS.HALDI,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.MEHNDI]: {
    id: OCCASIONS.MEHNDI,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.TEMPLE_SHOOT]: {
    id: OCCASIONS.TEMPLE_SHOOT,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: false,
  },
  [OCCASIONS.WEDDING]: {
    id: OCCASIONS.WEDDING,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.COCKTAIL]: {
    id: OCCASIONS.COCKTAIL,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.RECEPTION]: {
    id: OCCASIONS.RECEPTION,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.POST_WEDDING]: {
    id: OCCASIONS.POST_WEDDING,
    eventCategory: [EVENT_CATEGORY.WEDDING],
    isInternational: true,
  },
  [OCCASIONS.OTHERS]: {
    id: OCCASIONS.OTHERS,
    eventCategory: [],
    isInternational: true,
  },
};

export function getOccasionsDetails(name: string | OCCASIONS): {
  id: OCCASIONS;
  eventCategory: Array<EVENT_CATEGORY>;
} {
  if (name && name in OCCASIONS) return OCCASIONS_DETAILS[name];
  return OCCASIONS_DETAILS[OCCASIONS.OTHERS];
}

export type OccasionType = {
  id: string;
  name: string;
  client: string;
  event: string;
  start_date_time: string;
  end_date_time: string;
  service_providers: Array<string>;
  notes: string;
  number_of_guests?: number;
  location?: string;
  location_link?: string;
};

export function fetchOccasionsForEvent(eventId: string): Promise<{ results: Array<OccasionType>; count: number }> {
  return HttpUtils.get(API_URLS.CRM_OCCASIONS.ROOT(eventId)).then((response) => response.data);
}

function getDataToSave(data: Partial<OccasionType>): Partial<OccasionType> {
  // Convert service provider to array, in-case when user select only one user
  const { service_providers: serviceProviders } = data;
  if ('service_providers' in data) {
    return { ...data, service_providers: ArrayUtils.convertToArray(serviceProviders as Array<string>) };
  }
  return data;
}

export function createOccasion(eventId: string, data: Partial<OccasionType>): Promise<OccasionType> {
  return HttpUtils.post(API_URLS.CRM_OCCASIONS.ROOT(eventId), getDataToSave(data), true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function editOccasion(id: string, eventId: string, data: Partial<OccasionType>): Promise<OccasionType> {
  return HttpUtils.patch(API_URLS.CRM_OCCASIONS.DETAILS(eventId, id), getDataToSave(data))
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function deleteOccasion(id: string, eventId: string): Promise<OccasionType> {
  return HttpUtils.httpDelete(API_URLS.CRM_OCCASIONS.DETAILS(eventId, id)).then((response) => response.data);
}

export function fetchOccasionsForUser(
  eventId: string | 'all',
  userId: string,
  filters: {
    start: string;
    end: string;
  },
): Promise<
  Array<{
    event__number: string;
    event_id: string;
  }>
> {
  return HttpUtils.get(API_URLS.CRM_OCCASIONS.USER_OCCASIONS(eventId), {
    params: {
      user_id: userId,
      start_date_gte: filters.start,
      end_date_lte: filters.end,
    },
  }).then((response) => response.data);
}
