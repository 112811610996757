import React, { useEffect } from 'react';

import { List, Space, SubTitle } from '@premagic/myne';
import PendingPhotographerActionItemContainer from './PendingPhotographerActionItemContainer';
import { PhotographerActionType } from './PhotographerActionService';

type Props = {
  getPendingActions: () => void;
  isLoading: boolean;
  actions: Array<PhotographerActionType>;
};

export default function PendingPhotographerActions(props: Props): JSX.Element | null {
  const { getPendingActions, actions, isLoading } = props;
  const actionArray = Object.entries(actions);
  useEffect(() => {
    getPendingActions();
  }, []);

  if (isLoading) return null;
  if (actionArray.length === 0) return null;

  return (
    <div>
      <SubTitle>Requests from Client</SubTitle>
      <List>
        {actionArray.map(([actionId]) => (
          <PendingPhotographerActionItemContainer key={actionId} id={actionId} />
        ))}
      </List>
      <Space vertical />
    </div>
  );
}
