import { EventAttendeesService, EventPosterService, FolderService } from '@premagic/core';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  Color,
  COLOR_SHADES,
  GeneralEditorHeader,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  Label,
  LABEL_STYLES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import React from 'react';
import APP_URLS from '../../services/AppRouteURLService';
import EventPosterItemOptionsContainer from './EventPosterItemOptionsContainer';
import EventPosterSetDummyUserButtonContainer from './EventPosterSetDummyUserButtonContainer';
import EventPosterSwitcherContainer from './poster-switcher/EventPosterSwitcherContainer';

function getBackToLink(type: EventPosterService.EVENT_POSTER_TYPE) {
  if (type === EventPosterService.EVENT_POSTER_TYPE.BADGE) return APP_URLS.CRM.EVENT__BADGES;
  if (type === EventPosterService.EVENT_POSTER_TYPE.MAGAZINE) return APP_URLS.CRM.EVENT__POSTERS;
  return APP_URLS.CRM.EVENT__MARKETING;
}
type Props = {
  eventId: string;
  type: EventPosterService.EVENT_POSTER_TYPE;
  posterId?: string;
  poster?: EventPosterService.EventPosterType;
  projectId: string;
  isLoading: boolean;
  isSaving: boolean;
  clientWebsite?: FolderService.FolderType;
};

export default function EventPosterCreatorHeader(props: Props) {
  const { eventId, isLoading, isSaving, posterId, poster, projectId, type, clientWebsite } = props;
  const backUrl = BrowserUrlUtils.getRouteUrlFor(getBackToLink(type), {
    eventId,
  });

  const badgesPreviewURL =
    posterId &&
    BrowserUrlUtils.getRouteUrlFor(APP_URLS.EVENT_POSTER.PREVIEW, {
      type,
      eventId,
      projectId,
      posterId,
    });
  const isBadge = type === EventPosterService.EVENT_POSTER_TYPE.BADGE;

  if (isLoading) {
    return (
      <GeneralEditorHeader>
        <Row vcenter fullHeight>
          <ButtonIcon title="Back" tooltipPlacement="bottom" link={backUrl}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
          </ButtonIcon>
          <Color fillSolidIcon shade={COLOR_SHADES.DARKER} inline>
            <Icon name="artboard" />
          </Color>
          <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT}>
            Loading...
          </Text>
        </Row>
      </GeneralEditorHeader>
    );
  }

  const { name, scope } = poster || { scope: [], name: '' };
  const posterUrl = clientWebsite
    ? EventPosterService.getPosterPreviewUrl({
        poster,
        clientWebsite,
      })
    : undefined;
  const previewUrl = isBadge ? badgesPreviewURL : posterUrl;

  return (
    <GeneralEditorHeader>
      <Row vcenter fullHeight>
        <Space size={2} />
        <ButtonIcon title="Back" tooltipPlacement="bottom" link={backUrl} size={BUTTON_ICONS_SIZES.MD}>
          <Icon name="arrow_left" size={ICON_SIZES.MD} />
        </ButtonIcon>
        <Space size={2} />
        <Color fillSolidIcon shade={COLOR_SHADES.DARKER} inline>
          <Icon name="blueprint" />
        </Color>
        <Space size={2} />
        {posterId && (
          <>
            <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {name}
            </Text>
            <EventPosterSwitcherContainer
              eventId={eventId}
              activePosterId={posterId}
              projectId={projectId}
              type={type}
            />
            <Space size={2} />
            <Text muted>{HTML_ICON.DOT}</Text>
            <Space size={2} />
            {scope.map((scopeItem) => {
              const peopleDetails = EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS[scopeItem];
              return (
                <Label style={LABEL_STYLES.DEFAULT} key={peopleDetails.name}>
                  {peopleDetails.name}
                </Label>
              );
            })}
            <Space size={4} />
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
              {isSaving ? 'Saving...' : ' Saved'}
            </Text>
            <Col rightAlighed size={null}>
              <Row vcenter>
                <Space size={2}>
                  <EventPosterSetDummyUserButtonContainer />
                  {posterId && (
                    <Button style={BUTTON_STYLES.TERTIARY} link={previewUrl} isExternalLink>
                      Preview
                    </Button>
                  )}
                  <Space size={2} />
                  <EventPosterItemOptionsContainer
                    eventId={eventId}
                    posterId={posterId}
                    projectId={projectId}
                    location="creator"
                  />
                </Space>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </GeneralEditorHeader>
  );
}
