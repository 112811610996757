import { Text, Space, SubTitle, Row } from '@premagic/myne';
import EventAttendeeBadgePosterSelectContainer from './poster-select/EventAttendeeBadgePosterSelectContainer';
import EventAttendeeBadgeFontSelectContainer from './font-select/EventAttendeeBadgeFontSelectContainer';
import { WIDGET_TYPE } from '../../service/EventAttendeeBadgeDesignService';
import EventAttendeeBadgeFontWeightSelectContainer from './font-weight-select/EventAttendeeBadgeFontWeightSelectContainer';
import EventAttendeeBadgeTextColorSelectorContainer from './text-color/EventAttendeeBadgeTextColorSelectorContainer';
import EventAttendeeBadgeFontCaseSelectContainer from './font-case/EventAttendeeBadgeFontCaseSelectContainer';
import EventAttendeeBadgeFontSizeSelectContainer from './font-size/EventAttendeeBadgeFontSizeSelectContainer';

type Props = {
  projectId: string;
  folderId: string;
  selectedWidgetForEdit?: WIDGET_TYPE;
};

export default function EventAttendeeBadgeRightSidebar(props: Props) {
  const { folderId, projectId, selectedWidgetForEdit } = props;

  const showTextEditPanel =
    selectedWidgetForEdit &&
    [WIDGET_TYPE.DISPLAY_ID, WIDGET_TYPE.USER_COMPANY, WIDGET_TYPE.USER_NAME, WIDGET_TYPE.USER_ROLE].includes(
      selectedWidgetForEdit,
    );

  return (
    <Space size={4}>
      <EventAttendeeBadgePosterSelectContainer projectId={projectId} folderId={folderId} />
      <Space vertical size={8} />
      <SubTitle>Properties</SubTitle>
      <Space vertical size={1} />
      {showTextEditPanel ? (
        <div>
          <EventAttendeeBadgeFontSelectContainer projectId={projectId} folderId={folderId} />
          <Row>
            <EventAttendeeBadgeFontSizeSelectContainer projectId={projectId} folderId={folderId} />
            <Space />
            <EventAttendeeBadgeFontWeightSelectContainer projectId={projectId} folderId={folderId} />
          </Row>
          <Row>
            <EventAttendeeBadgeFontCaseSelectContainer projectId={projectId} folderId={folderId} />
            <Space />
            <EventAttendeeBadgeTextColorSelectorContainer projectId={projectId} folderId={folderId} />
          </Row>
        </div>
      ) : (
        <Space>
          <Text muted>Select a widget to edit its properties.</Text>
        </Space>
      )}
    </Space>
  );
}
