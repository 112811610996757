import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';

import { isMobileUp } from '../AppDuck';
import SettingsPage from './SettingsPage';
import { hasFeatureSelector } from './company/CompanyDataDuck';
import { clientSettingsDataSelector } from './preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  return {
    isMobileUp: isMobileUp(state),
    hasProposal:
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED)(state) ||
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT)(state),
    clientSettings: clientSettingsDataSelector(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
