import { EventPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  List,
  LIST_ITEM_ACTION_STYLES,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { PosterWidgetType } from '@premagic/poster-creator';
import React, { useState } from 'react';
import AddEventPosterWidgetButtonContainer from '../widget-creator/AddEventPosterWidgetButtonContainer';

type Props = {
  projectId: string;
  posterId: string;
  type: EventPosterService.EVENT_POSTER_TYPE;
  poster: EventPosterService.EventPosterType;
  widgets: Array<PosterWidgetType>;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetType;
  }) => void;
  deleteWidget: (options: { projectId: string; poster: EventPosterService.EventPosterType; id: string }) => void;
  setSelectedWidget: (widgetId: string) => void;
  selectedEventPosterWidgetId?: string;
  switchToDesignTab: () => void;
};

export default function EventPosterCreatorSidebar(props: Props) {
  const {
    poster,
    posterId,
    type,
    widgets,
    editWidget,
    projectId,
    setSelectedWidget,
    selectedEventPosterWidgetId,
    deleteWidget,
    switchToDesignTab,
  } = props;

  function handleWidgetDisplay(widget: PosterWidgetType, isHidden: boolean) {
    const updatedWidget = Object.assign({}, widget, {
      is_hidden: isHidden,
    });
    editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
  }
  function handleDeleteWidget(id) {
    deleteWidget({ projectId, poster, id });
  }
  const [isExpanded, setIsExpanded] = useState(true);

  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;
    if (reason === 'DROP' && destination) {
      console.log(source, destination);
      const sourceWidget = widgets[source.index];
      const destinationWidget = widgets[destination.index];
      /* Calculate the z value based on the movement of the widget
      When I move a widget from index 0 to index 2, the z value == destinationWidget.z - 1;
      When I move a widget from index 2 to index 0, the z value == destinationWidget.z + 1;
       */
      const newZ = destinationWidget.position.z + (source.index < destination.index ? -1 : 1);
      const updatedWidget = Object.assign({}, sourceWidget, {
        position: Object.assign({}, sourceWidget.position, {
          z: newZ,
        }),
      });

      editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
    }
  }

  return (
    <div>
      <Button block style={BUTTON_STYLES.RESET} onClick={() => setIsExpanded(!isExpanded)}>
        <Row>
          <Space />
          <SubTitle>Widgets</SubTitle>
          <Col rightAlighed size={null}>
            <Icon name={isExpanded ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
          </Col>
          <Space />
        </Row>
      </Button>
      <Space vertical size={1} />
      {isExpanded && (
        <div>
          <DragDropContext onDragEnd={onDrop}>
            <Droppable droppableId="WIDGET_LIST">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.SM}>
                    <List>
                      {widgets.map((widget, idx) => {
                        const { id, name, is_hidden: isHidden } = widget;
                        const isActive = id === selectedEventPosterWidgetId;
                        const isSystem = EventPosterService.isSystemWidget(widget);
                        return (
                          <Draggable key={id} draggableId={id} index={idx}>
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                <ListItem
                                  key={id}
                                  active={isActive}
                                  style={LIST_ITEM_STYLES.COMPACT_LIST}
                                  showHoverStyle
                                >
                                  <Space />
                                  <ListItemAction style={LIST_ITEM_ACTION_STYLES.DEFAULT_INSET}>
                                    <Tooltip message="Drag and move to reorder" placement="left">
                                      <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                                        <Icon name="drag" size={ICON_SIZES.SM_XS} />
                                      </Color>
                                    </Tooltip>
                                  </ListItemAction>
                                  <Col size={9}>
                                    <Button
                                      style={BUTTON_STYLES.RESET}
                                      onClick={() => setSelectedWidget(id)}
                                      block
                                      onDoubleClick={switchToDesignTab}
                                    >
                                      <Text
                                        size={TEXT_SIZE.SIZE_5}
                                        color={isHidden ? COLOR_SHADES.LIGHT : COLOR_SHADES.DARKER}
                                        boldness={isHidden ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                                      >
                                        {EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS_DETAILS[name]?.title || name}
                                      </Text>
                                      <Space vertical size={1} />
                                    </Button>
                                  </Col>
                                  <ListItemAction>
                                    <ButtonIcon title="Delete" onClick={() => handleDeleteWidget(id)}>
                                      <Icon name="trash" size={ICON_SIZES.SM} />
                                    </ButtonIcon>
                                    <ButtonIcon
                                      title={isHidden ? 'Show' : 'Hide'}
                                      onClick={() => handleWidgetDisplay(widget, !isHidden)}
                                    >
                                      <Icon name={isHidden ? 'eye_off' : 'eye'} size={ICON_SIZES.SM} />
                                    </ButtonIcon>
                                  </ListItemAction>
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </List>
                  </ScrollableContainer>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Space vertical size={1} />
          <Row>
            <Space size={3} />
            <AddEventPosterWidgetButtonContainer posterId={posterId} projectId={projectId} type={type} />
          </Row>
        </div>
      )}
    </div>
  );
}
