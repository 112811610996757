import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import AccountProjectPage from './AccountProjectPage';
import { clientWebsiteIdForProjectSelector, fetchProject, projectsSelector } from './AccountProjectsDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId } = ownProps.match.params;
  const project = projectsSelector(state)[projectId] || {};

  return {
    project,
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    projectId,
    clientWebsiteId: clientWebsiteIdForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      dispatch(fetchProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProjectPage);
