import React from 'react';

import { FolderService, ProjectService } from '@premagic/core';
import {
  InnerPageHeader,
  Text,
  Space,
  TEXT_BOLDNESS,
  HTML_ICON,
  InnerPageHeaderFloatingTag,
  InnerPageSubHeader,
  Tabs,
  TabItem,
  COLOR_SHADES,
  Tooltip,
  TextButton,
  TEXT_BUTTON_STYLES,
  Row,
} from '@premagic/myne';
import { BrowserUrlUtils, NumberUtils, StringUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import ProjectPinNumber from '../ProjectPinNumber';
import FolderShareButtonContainer from '../folders/sidebar/FolderShareButtonContainer';
import MESSAGES from '../../../../../common/Messages';

type Props = {
  isLoading: boolean;
  projectId: string;
  project: ProjectService.ProjectType;
  currentUrlPathname: string;
  folderFilesStats: FolderService.FolderTypeStats;
  isCompanyTypeNotPhotographer: boolean;
  isFavoriteFeatureEnabled: boolean;
};

export default function HighlightFoldersPageHeader(props: Props) {
  const {
    isLoading,
    projectId,
    project,
    currentUrlPathname,
    folderFilesStats,
    isCompanyTypeNotPhotographer,
    isFavoriteFeatureEnabled,
  } = props;
  const { total_image: totalFiles, rejected: hiddenFiles } = folderFilesStats;

  const { project_name: projectName, event_id: eventId } = project || {};

  const backToUrlProject = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.DETAILS, {
    projectId,
  });
  const backToUrlEvent =
    eventId &&
    BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
      eventId,
      focusId: FolderService.FOLDER_TYPES.HIGHLIGHT,
    });
  const foldersUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT, { projectId });
  const albumCoverUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT__COVER_SETTINGS, { projectId });
  const rejectedPhotosUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT__HIDDEN_PHOTOS, {
    projectId,
  });
  const favouritePhotosUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT__FAVOURITE_PHOTOS, {
    projectId,
  });
  const activeFolderView = currentUrlPathname.includes(foldersUrl) && !currentUrlPathname.includes('/v/');

  return (
    <>
      <InnerPageHeaderFloatingTag>
        <Text boldness={TEXT_BOLDNESS.BOLD} block>
          {projectName}
          {project?.mpin && (
            <span>
              <Space size={2} />
              {HTML_ICON.DOT}
              <Space size={2} />
              <ProjectPinNumber pin={project?.mpin} />
            </span>
          )}
        </Text>
      </InnerPageHeaderFloatingTag>
      <InnerPageHeader
        iconName="signature_album"
        title={`Signature album for ${projectName}`}
        backTo={eventId ? backToUrlEvent : backToUrlProject}
        rightActions={
          <FolderShareButtonContainer projectId={projectId} folderType={FolderService.FOLDER_TYPES.HIGHLIGHT} />
        }
      >
        <Row vcenter alignItems="baseline">
          {isCompanyTypeNotPhotographer
            ? MESSAGES.SIGNATURE_FOLDER.NAME_FOR_EVENT_ORGANIZER
            : MESSAGES.SIGNATURE_FOLDER.NAME}
          <Space size={2} />
          <Text>
            <Text color={COLOR_SHADES.DARKER}>{NumberUtils.getNumberInFormat(totalFiles)}</Text>{' '}
            {StringUtils.pluralize('Photos & Video', totalFiles)}
          </Text>
          <Space size={1} />
          {!!hiddenFiles && (
            <>
              <Tooltip
                placement="bottom"
                message={`Client had decided to hide ${hiddenFiles} ${StringUtils.pluralize(
                  'Photo',
                  hiddenFiles,
                )} from the gallery`}
              >
                <Text>
                  (
                  <TextButton link={rejectedPhotosUrl} style={TEXT_BUTTON_STYLES.LINK}>
                    {NumberUtils.getNumberInFormat(hiddenFiles)} Hidden
                  </TextButton>
                  )
                </Text>
              </Tooltip>
              <Space size={4} />
            </>
          )}
        </Row>
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem to={foldersUrl} active={activeFolderView}>
            Folders
          </TabItem>
          <TabItem to={albumCoverUrl} active={currentUrlPathname.includes(albumCoverUrl)}>
            Design
          </TabItem>
          <TabItem to={rejectedPhotosUrl} active={currentUrlPathname.includes(rejectedPhotosUrl)}>
            Hidden Photos
          </TabItem>
          {isFavoriteFeatureEnabled && (
            <TabItem to={favouritePhotosUrl} active={currentUrlPathname.includes(favouritePhotosUrl)}>
              Favourite Photos
            </TabItem>
          )}
        </Tabs>
      </InnerPageSubHeader>
    </>
  );
}
