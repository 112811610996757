import { connect } from 'react-redux';
import EventBadgePunchHoleSelector from './EventBadgePunchHoleSelector';
import { selectedPunchHoleStyleSelector, setPunchHoleStyle } from '../EventPosterCreatorDuck';

function mapStateToProps(state, ownProps: { projectId: string; posterId: string }) {
  const { projectId, posterId } = ownProps;
  return { projectId, posterId, punchHoleStyle: selectedPunchHoleStyleSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    setPunchHoleStyle: (style) => {
      dispatch(setPunchHoleStyle(dispatch, style));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBadgePunchHoleSelector);
