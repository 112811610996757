import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';
import { hasFeatureSelector } from '../company/CompanyDataDuck';
import {
  accountDefaultLanguageSelector,
  companyTypeSelector,
  enableReportingIssueFromPeopleSelector,
  enableReportingTheseAreNotMyPhotosSelector,
  isEmailHiddenOnRegistrationFormSelector,
  isFeatureEventsOccasionsEnabledSelector,
  isFeatureProjectsTabEnabledSelector,
  saveClientSettingsData,
  showEventFeedbackFormSelector,
} from '../preferences/ClientSettingsDataDuck';
import EventsSettingsPage from './EventsSettingsPage';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return {
    currentUrlPathname: pathname,
    hasCustomEventFormFieldsFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    )(state),
    isEmailHiddenOnRegistrationForm: isEmailHiddenOnRegistrationFormSelector(state),
    showEventFeedbackForm: showEventFeedbackFormSelector(state),
    enableReportingIssueFromPeople: enableReportingIssueFromPeopleSelector(state),
    enableReportingTheseAreNotMyPhotos: enableReportingTheseAreNotMyPhotosSelector(state),
    isFeatureEventsOccasionsEnabled: isFeatureEventsOccasionsEnabledSelector(state),
    isFeatureProjectsTabEnabled: isFeatureProjectsTabEnabledSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.COMPANY.SAVE_PROFILE)(state) ||
      isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    isStaffUser: isStaffUserSelector(state),
    accountDefaultLanguage: accountDefaultLanguageSelector(state),
    companyType: companyTypeSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsSettingsPage);
