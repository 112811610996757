import { connect } from 'react-redux';
import PaymentTaxOptionPicker from './PaymentTaxOptionPicker';
import { CRMPaymentType } from './CRMPaymentService';

function mapStateToProps(state, ownProps: { payment?: CRMPaymentType }) {
  const { payment } = ownProps;

  return {
    payment,
  };
}

export default connect(mapStateToProps)(PaymentTaxOptionPicker);
