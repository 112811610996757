import React from 'react';

import {
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Card,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  Row,
  Space,
  SubTitle,
  TEXT_GROUP_TYPES,
  TextGroupValue,
} from '@premagic/myne';
import { EventsService } from '@premagic/core';

import CRMClientSummaryContainer from '../../clients/details/CRMClientSummaryContainer';
import EventSidebarSummaryContainer from './EventSidebarSummaryContainer';
import { PERMISSIONS } from '../../users/UserPermissionService';
import EventProposalsContainer from '../../event-proposals/EventProposalsContainer';
import MESSAGES from '../../../../../../common/Messages';
import ClientWebsitesSectionContainer from '../../event-deliveries/client-website/ClientWebsitesSectionContainer';
import ReportIssueButtonContainer from '../report-issues/report-issue-button/ReportIssueButtonContainer';

type EventInfoProps = {
  eventData?: EventsService.EventDataType;
  showEditEventDetails: () => void;
  hasEventManagementPermission: boolean;
};

function EventInfo(props: EventInfoProps) {
  const { eventData, hasEventManagementPermission, showEditEventDetails } = props;
  const { notes } = eventData || {};
  return (
    <div>
      <Row vcenter>
        <SubTitle>Event's Notes</SubTitle>
        <Space size={2} />
        <ButtonIcon
          title="Edit"
          onClick={showEditEventDetails}
          disabled={!hasEventManagementPermission}
          disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_MANAGEMENT]}
          size={BUTTON_ICONS_SIZES.SM}
        >
          <Icon name="edit_2" size={ICON_SIZES.SM} />
        </ButtonIcon>
      </Row>
      <TextGroupValue type={TEXT_GROUP_TYPES.RICH_TEXTAREA} value={notes || '<p>(Not set)</p>'} />
    </div>
  );
}

type Props = {
  event: EventsService.EventType;
  eventId: string;
  clientId: string;
  projectId: string;
  focusId?: string;
  showEditEventDetails: () => void;
  hasPermissions: Partial<Record<PERMISSIONS, boolean>>;
  hasProposalFeature: boolean;
  isFeatureProposalEnabled: boolean;
  projectName?: string;
  eventData?: EventsService.EventDataType;
};

export default function EventDetailsLayout(props: Props): React.ReactElement {
  const {
    event,
    showEditEventDetails,
    clientId,
    hasPermissions,
    hasProposalFeature,
    eventId,
    focusId,
    projectId,
    isFeatureProposalEnabled,
    projectName,
    eventData,
  } = props;

  return (
    <>
      <InnerPageContentMainCol>
        <ClientWebsitesSectionContainer projectId={projectId} eventId={eventId} focusId={focusId} />
        <CRMClientSummaryContainer clientId={clientId} />
        <Space vertical />
        {isFeatureProposalEnabled && hasProposalFeature && hasPermissions[PERMISSIONS.EVENT_QUOTE_MANAGEMENT] && (
          <EventProposalsContainer eventId={eventId} focusId={focusId} />
        )}
        <Space vertical />
        <EventSidebarSummaryContainer eventId={event.id} />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <EventInfo
          eventData={eventData}
          showEditEventDetails={showEditEventDetails}
          hasEventManagementPermission={hasPermissions[PERMISSIONS.EVENT_MANAGEMENT] || false}
        />
        <Space vertical />
        {projectName === 'yuvakalam' && (
          <>
            <Space vertical />
            <Card>
              <Space>
                <ReportIssueButtonContainer eventId={eventId} />
              </Space>
            </Card>
          </>
        )}
      </InnerPageContentSidebar>
    </>
  );
}

// <TextGroup>
//   <TextGroupLabel>Location</TextGroupLabel>
//   <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={location} />
// </TextGroup>
// <TextGroup>
//   <TextGroupLabel>Dates</TextGroupLabel>
//   <TextGroupValue type={TEXT_GROUP_TYPES.DATE} value={startDate} />
//   <Space size={2} />
//   <Text>{HTML_ICON.DASH_SMALL}</Text>
//   <Space size={2} />
//   <TextGroupValue type={TEXT_GROUP_TYPES.DATE} value={endDate} />
// </TextGroup>
// <TextGroup>
//   <TextGroupLabel>Assigned Team members</TextGroupLabel>
//   <UserNamesContainer userIds={serviceProviders} />
// </TextGroup>
