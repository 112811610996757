import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import ClassNames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';
import { AnimatePresence, PanInfo, motion } from 'framer-motion';

import { MediaUtils, useBrowserFullscreen } from '@premagic/utils';
import SlideShowContext from './SlideShowContext';
import styles from './slideshow.module.css';
import { Row, Space } from '../Grid';
import { BUTTON_CIRCLE_SIZES, BUTTON_CIRCLE_STYLES, ButtonCircle, ButtonIcon } from '../Button/Buttons';
import { Icon } from '../Icon/Icons';
import { getAllImagesFromSlides } from './slideShowService';

interface SlideShowOverlayBoxProps {
  children: React.ReactNode;
}

export function SlideShowOverlayBox(props: SlideShowOverlayBoxProps) {
  const { children } = props;
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'easeIn', duration: 0.15 }}
      className={ClassNames(styles['slideshow__overlay-box'])}
    >
      {children}
    </motion.div>
  );
}

interface SlideShowOverlayBoxContentProps {
  overlayButtons?: Array<ReactElement> | ReactElement;
  restart: () => void;
  togglePlay: () => void;
  isPlaying: boolean;
  toggleFullscreen: () => void;
  isFullscreen: boolean;
}

function SlideShowControlButtonsContent(props: SlideShowOverlayBoxContentProps) {
  const { overlayButtons, isPlaying, restart, togglePlay, toggleFullscreen, isFullscreen } = props;
  return (
    <div>
      <Row center>
        <ButtonCircle onClick={togglePlay} style={BUTTON_CIRCLE_STYLES.WHITE} size={BUTTON_CIRCLE_SIZES.LG} layer={1}>
          <Icon name={isPlaying ? 'pause' : 'play'} />
        </ButtonCircle>
        <Space />
        <ButtonCircle onClick={restart} style={BUTTON_CIRCLE_STYLES.WHITE} size={BUTTON_CIRCLE_SIZES.LG} layer={1}>
          <Icon name="rotate_ccw" />
        </ButtonCircle>
      </Row>
      <Space vertical size={8} />
      <Row>
        {overlayButtons}
        <Space size={16} />
        <ButtonCircle
          onClick={toggleFullscreen}
          style={BUTTON_CIRCLE_STYLES.WHITE}
          size={BUTTON_CIRCLE_SIZES.LG}
          layer={1}
        >
          <Icon name={isFullscreen ? 'minimize' : 'maximize'} />
        </ButtonCircle>
      </Row>
    </div>
  );
}

interface SlideShowContainerProps {
  children: ReactElement;
  showOverlayBox?: boolean;
  toggleOverlayBox: () => void;
  nextSlide: () => void;
  previousSlide: () => void;
}

function SlideShowContainer(props: SlideShowContainerProps) {
  const { children, showOverlayBox, toggleOverlayBox, nextSlide, previousSlide } = props;
  let isDragEndPoint = {
    x: 0,
    y: 0,
  };

  function onDragEnd(e, info: PanInfo) {
    if (showOverlayBox) toggleOverlayBox();
    isDragEndPoint = info.point;
    if (info.offset.x < -100) {
      nextSlide();
    }
    if (info.offset.x > 100) {
      previousSlide();
    }
  }

  function onTap(e, info) {
    const isDragAndTapSameEndPoint = isDragEndPoint.x === info.point.x;
    if (!isDragAndTapSameEndPoint) toggleOverlayBox();
  }

  return (
    <motion.div
      onTap={onTap}
      drag="x"
      dragElastic={0.5}
      dragConstraints={{ left: 0, right: 0 }}
      onDragEnd={onDragEnd}
      className={ClassNames(styles.slideshow, {
        [styles['slideshow--open-overlay']]: showOverlayBox,
      })}
    >
      <AnimatePresence>{children}</AnimatePresence>
    </motion.div>
  );
}

export enum SLIDE_SHOW_SPEED {
  SLOW = 'show',
  MEDIUM = 'medium',
  FAST = 'fast',
}

const SLIDE_SHOW_SPEED_DETAILS: Record<SLIDE_SHOW_SPEED, { time: number }> = {
  [SLIDE_SHOW_SPEED.SLOW]: {
    time: 7000,
  },
  [SLIDE_SHOW_SPEED.MEDIUM]: {
    time: 5000,
  },
  [SLIDE_SHOW_SPEED.FAST]: {
    time: 3000,
  },
};

interface SlideShowProps {
  children: Array<ReactElement>;
  defaultSlideIndex?: number;
  speed?: SLIDE_SHOW_SPEED;
  appWidth: number;
  overlayButtons?: Array<ReactElement> | ReactElement;
  onChange?: (slideIndex: number) => void;
}

export function SlideShow(props: SlideShowProps) {
  const {
    children,
    defaultSlideIndex = 0,
    speed = SLIDE_SHOW_SPEED.MEDIUM,
    appWidth,
    overlayButtons,
    onChange,
  } = props;
  const slideShowSpeed = SLIDE_SHOW_SPEED_DETAILS[speed].time;

  const images = useMemo(() => getAllImagesFromSlides(children, appWidth), [children.length]);
  const numberOfSlides = children.length;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(defaultSlideIndex);
  const [isPlaying, setPlaying] = useState(true);
  const [showOverlayBox, setShowOverlayBox] = useState(false);

  const currentSlideComponent = children[currentSlideIndex];

  const $container = useRef<HTMLDivElement>(null);
  const [isFullscreen, setFullscreen] = useBrowserFullscreen($container);

  // Preload next 3 images
  useEffect(() => {
    MediaUtils.eagerLoadImages(images.slice(currentSlideIndex, currentSlideIndex + 3));
  }, [currentSlideIndex]);

  function play(shouldPlay: boolean) {
    setPlaying(shouldPlay);
  }

  function changeSlide(slideIndex: number) {
    setCurrentSlideIndex(slideIndex);
    onChange?.(slideIndex);
  }

  function restart() {
    changeSlide(0);
    setShowOverlayBox(false);
    if (!isPlaying) play(true);
  }

  function nextSlide() {
    const nextSlideIndex = currentSlideIndex + 1;
    if (nextSlideIndex >= numberOfSlides) {
      setTimeout(() => setShowOverlayBox(true), 2000);
      return;
    }

    changeSlide(nextSlideIndex);
    if (!isPlaying) play(true);
  }

  function previousSlide() {
    const previousSlideIndex = currentSlideIndex - 1;
    if (previousSlideIndex <= -1) return;
    changeSlide(previousSlideIndex);
    if (!isPlaying) play(true);
  }

  function togglePlay() {
    play(!isPlaying);
  }

  function toggleOverlayBox() {
    const show = !showOverlayBox;
    // Pause when overlay box is shown and play when overlay is closed
    // play(!show);
    setShowOverlayBox(show);
  }

  function toggleFullscreen() {
    setShowOverlayBox(false);
    setFullscreen(!isFullscreen);
  }

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (isPlaying) nextSlide();
    }, slideShowSpeed);
    return () => window.clearInterval(timer);
  });

  const keyMap = {
    previous: 'left',
    next: 'right',
    toggle: 'space',
  };

  const handlers = {
    previous: previousSlide,
    next: nextSlide,
    toggle: togglePlay,
  };

  return (
    <div ref={$container} className={styles['slideshow-wrapper']}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <SlideShowContext.Provider
        value={{
          appWidth,
          play,
        }}
      >
        {showOverlayBox && (
          <SlideShowOverlayBox>
            <SlideShowControlButtonsContent
              isFullscreen={isFullscreen}
              overlayButtons={overlayButtons}
              isPlaying={isPlaying}
              toggleFullscreen={toggleFullscreen}
              restart={restart}
              togglePlay={togglePlay}
            />
          </SlideShowOverlayBox>
        )}

        <SlideShowContainer
          showOverlayBox={showOverlayBox}
          nextSlide={nextSlide}
          previousSlide={previousSlide}
          toggleOverlayBox={toggleOverlayBox}
        >
          {currentSlideComponent}
        </SlideShowContainer>
      </SlideShowContext.Provider>
    </div>
  );
}
