import { useCommands, useUpdateReason } from '@remirror/react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

export default function useTextColorState() {
  const commands = useCommands();

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>();

  const removeColor = useCallback(() => commands.removeTextColor(), [commands]);
  const setColor = useCallback(
    (color) => {
      setSelectedColor(color);
      commands.setTextColor(color);
    },
    [setSelectedColor, commands],
  );

  const updateReason = useUpdateReason();

  // Close the color picker when the doc is updated
  useLayoutEffect(() => {
    if (!showColorPicker) {
      return;
    }
    // The doc will be updated when the color is changed in the picker, when selection is changed then we need to close the color picker
    if (!updateReason.doc && updateReason.selection) {
      setShowColorPicker(false);
    }
  }, [updateReason.doc, updateReason.selection]);

  return useMemo(
    () => ({
      setColor,
      removeColor,
      showColorPicker,
      setShowColorPicker,
      selectedColor,
    }),
    [setColor, removeColor, showColorPicker, setShowColorPicker, selectedColor],
  );
}
