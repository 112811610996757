import React, { useEffect } from 'react';
import { Col, LoadingBox, Row, Text } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import EventsListEventItemContainer from './EventsListEventItemContainer';
import EventsListPagePaginationContainer from './EventsListPagePaginationContainer';
import { EventFiltersType } from '../EventsListService';

type Props = {
  fetchEvents: (filtersObject: BrowserUrlUtils.QueryObject) => void;
  eventIds: Array<string>;
  isLoading: boolean;
  filters: string;
};

export default function EventsListPage(props: Props): JSX.Element {
  const { eventIds, isLoading, filters, fetchEvents } = props;
  useEffect(() => {
    const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as EventFiltersType;
    fetchEvents({ ...filterObject, labels: filterObject?.labels?.split(',') });
  }, [filters, fetchEvents]);

  return (
    <LoadingBox isLoading={isLoading}>
      <div>
        {eventIds.length === 0 && (
          <div>
            <Text muted>There are no events for the selected filter</Text>
          </div>
        )}
        {eventIds.map((event) => (
          <EventsListEventItemContainer key={event} id={event} />
        ))}
        <Row>
          <Col rightAlighed size={null}>
            <EventsListPagePaginationContainer filters={filters} />
          </Col>
        </Row>
      </div>
    </LoadingBox>
  );
}
