import { ClientWebsiteFormService, EventAttendeesService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormErrorType,
  FormFooter,
  Row,
  Space,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { ObjectUtils } from '@premagic/utils';
import React, { useEffect } from 'react';
import MESSAGES from '../../../../../../../common/Messages';
import EventAttendeeFormContainer from '../create/EventAttendeeFormContainer';
import EditEventAttendeeNavButtonsContainer from './EditEventAttendeeNavButtonsContainer';

type Props = {
  attendeeId?: string | null;
  projectId: string;
  updateAttendee: (
    options: { projectId: string; attendeeId: string },
    formResponse: {
      data: Record<string, any>;
      errors?: FormErrorType;
    },
  ) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  cleanup: () => void;
  attendee?: EventAttendeesService.EventAttendeeType;
  attendeeRegistrationSendNotificationRequired?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
};

export default function EditEventAttendeePanel(props: Props): JSX.Element | null {
  const {
    show,
    updateAttendee,
    isLoading,
    errors,
    closePanel,
    attendeeId,
    cleanup,
    projectId,
    attendee,
    attendeeRegistrationSendNotificationRequired,
  } = props;
  const [selectedFile, setSelectedFile] = React.useState<File>();
  function handleSubmit(formResponse) {
    if (!attendeeId) return;

    const partiallyUpdatedData = ObjectUtils.getChangedValues(
      { ...attendee, ...attendee?.custom_fields },
      formResponse.data,
    );

    updateAttendee(
      {
        attendeeId,
        projectId,
      },
      {
        data: {
          ...partiallyUpdatedData,
          selfie: selectedFile as File,
        },
        errors: formResponse.errors,
      },
    );
  }

  useEffect(() => {
    if (!show) {
      cleanup();
    }
  }, [show, cleanup]);

  if (!attendeeId || !show) return null;

  const isEmailRequired = attendeeRegistrationSendNotificationRequired
    ? [
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL,
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      ].includes(attendeeRegistrationSendNotificationRequired)
    : true;

  const isPhoneRequired = attendeeRegistrationSendNotificationRequired
    ? [
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      ].includes(attendeeRegistrationSendNotificationRequired)
    : true;

  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <Row vcenter>
          <WindowPanelTitle>Edit {MESSAGES.EVENT_ATTENDEES.NAME}</WindowPanelTitle>
          <Space />
          {attendeeId && <EditEventAttendeeNavButtonsContainer />}
        </Row>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          key={attendeeId}
          formSpec={{
            permission_to_save_photo: {
              type: 'boolean',
            },
            should_send_welcome_notification: {
              type: 'boolean',
            },
            have_consent_for_networking: {
              type: 'boolean',
            },
            ...(isPhoneRequired ? { phone: { validator: 'required' } } : {}),
            ...(isEmailRequired ? { email: { validator: 'required' } } : {}),
          }}
          errors={errors}
          onSubmit={handleSubmit}
        >
          <EventAttendeeFormContainer
            attendeeId={attendeeId}
            projectId={projectId}
            onChangeOfFile={(file) => setSelectedFile(file as File)}
          />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
