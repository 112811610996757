import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils } from '@premagic/utils';
import { RouterService } from '@premagic/core';
import { omit } from 'lodash';
import { DAM_LIBRARY_FILTER_KEYS, DAMLibraryFiltersType } from './DAMLibraryFilterTypes';
import { getDAMLibraryUrlWithFilters } from './DAMLibraryFilterService';

const getActionType = ActionTypeUtils.getActionTypeFunction('DAM_FILTERS', true);

export const navigateToDAMLibraryPageWithFilter = createAction(
  getActionType('NAVIGATE_WITH_FILTER'),
  (dispatch, filters: DAMLibraryFiltersType) => {
    RouterService.navigateTo(dispatch, getDAMLibraryUrlWithFilters(filters));
  },
);

export const removeFilterDAMLibraryPage = createAction(
  getActionType('REMOVE_FILTER'),
  (dispatch, filterKeyToRemove: DAM_LIBRARY_FILTER_KEYS, filters: DAMLibraryFiltersType) => {
    const updateFilters = omit(filters, filterKeyToRemove);
    RouterService.navigateTo(dispatch, getDAMLibraryUrlWithFilters(updateFilters));
  },
);

const initialState = {};

export default handleActions({}, initialState);
