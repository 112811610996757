import React, { useEffect } from 'react';
import {
  Card,
  Col,
  COLOR_SHADES,
  List,
  ListItem,
  LoadingBox,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import CompanyBrandingListItemContainer from './CompanyBrandingListItemContainer';
import AddCompanyBrandingButtonContainer from './AddCompanyBrandingButtonContainer';

type Props = {
  brandingFolderIds: Array<string>;
  isLoading: boolean;
  initData: () => void;
};

export default function CompanyBrandingList(props: Props): JSX.Element {
  const { brandingFolderIds, initData, isLoading } = props;

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <div>
      <Row>
        <Col>
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            Branding Items
          </Text>
        </Col>
        <Col rightAlighed size={null}>
          <AddCompanyBrandingButtonContainer />
        </Col>
      </Row>
      <Space vertical size={2} />
      <Card>
        <LoadingBox isLoading={isLoading}>
          <List>
            {brandingFolderIds.length === 0 ? (
              <ListItem>
                <Text muted>You haven&apos;t added any branding</Text>
              </ListItem>
            ) : (
              brandingFolderIds.map((id) => <CompanyBrandingListItemContainer key={id} id={id} />)
            )}
          </List>
        </LoadingBox>
      </Card>
    </div>
  );
}
