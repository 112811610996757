import React from 'react';

import {
  BUTTON_ICON_STYLES,
  ButtonIcon,
  Col,
  Color,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { ProjectService } from '@premagic/core';
import { ProposalService } from '@premagic/proposals';

import ProposalFolderShareButtonContainer from '../share/ProposalFolderShareButtonContainer';
import APP_URLS from '../../../../services/AppRouteURLService';
import ProposalCreatorHeaderFilesInfoContainer from './ProposalCreatorHeaderFilesInfoContainer';
import ProposalCreatorDeckVariablesInfoContainer from '../deck-variables/ProposalCreatorDeckVariablesInfoContainer';
import ProposalCreatorHeaderOptionsMenuContainer from './ProposalCreatorHeaderOptionsMenuContainer';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  isSaving: boolean;
  proposal?: ProposalService.ProposalType;
};

export default function ProposalCreatorHeader(props: Props) {
  const { projectId, folderId, deckId, proposal, isSaving } = props;

  if (!proposal) {
    return <div>There is no Proposal</div>;
  }
  const isProposalTemplate = projectId === ProjectService.SystemProject.id;
  const { event_id: eventId, folder_name: name } = proposal;

  const backUrl = isProposalTemplate
    ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROPOSALS_TEMPLATES.LIST, {
        focusId: folderId,
      })
    : BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
        eventId,
        focusId: folderId,
      });

  return (
    <Row vcenter>
      <ButtonIcon style={BUTTON_ICON_STYLES.SECONDARY} link={backUrl} title="Back" tooltipPlacement="bottom">
        <Icon name="arrow_left" />
      </ButtonIcon>
      <Color fillSolidIcon shade={COLOR_SHADES.DARKER} inline>
        <Icon name="film" />
      </Color>
      <Space size={2} />
      <Col>
        {isProposalTemplate && (
          <>
            <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
              Templates
            </Text>
            <Space size={1} />
            <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.LIGHT}>
              /
            </Text>
            <Space size={1} />
          </>
        )}
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
          {name}
        </Text>
        <Space />
        <ProposalCreatorHeaderFilesInfoContainer projectId={projectId} folderId={folderId} deckId={deckId} />
        <Space size={2} />
        <Text>{HTML_ICON.DOT}</Text>
        <Space size={2} />
        <ProposalCreatorDeckVariablesInfoContainer projectId={projectId} folderId={folderId} deckId={deckId} />
        <Space />
        <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
          {isSaving ? 'Saving...' : ' Saved'}
        </Text>
      </Col>
      <Col rightAlighed size={null}>
        <Row vcenter>
          <Space size={2}>
            {!isProposalTemplate && (
              <ProposalFolderShareButtonContainer folderId={folderId} projectId={projectId} deckId={deckId} />
            )}
          </Space>
          <ProposalCreatorHeaderOptionsMenuContainer projectId={projectId} folderId={folderId} deckId={deckId} />
        </Row>
      </Col>
    </Row>
  );
}
