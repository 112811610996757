import { connect } from 'react-redux';
import GalleryDesignCustomizationSettings from './GalleryDesignCustomizationSettings';

function mapStateToProps(
  state,
  ownProps: {
    handleFontChange: (value) => void;
    fontFamilyForSignatureAlbum?: string;
    hasHighlightCustomizationFeature: boolean;
    aiPickedDominantColors?: Array<Array<number>>;
    handleBackgroundColorChange: (value) => void;
    backgroundColorForSignatureAlbum: string;
    handleFontColorChange: (value) => void;
    fontColorForSignatureAlbum?: string;
    handleAccentColorChange: (value) => void;
    accentColorForSignatureAlbum?: string;
  },
) {
  const {
    handleFontChange,
    fontFamilyForSignatureAlbum,
    hasHighlightCustomizationFeature,
    aiPickedDominantColors,
    handleBackgroundColorChange,
    backgroundColorForSignatureAlbum,
    handleFontColorChange,
    fontColorForSignatureAlbum,
    handleAccentColorChange,
    accentColorForSignatureAlbum,
  } = ownProps;

  return {
    handleFontChange,
    fontFamilyForSignatureAlbum,
    hasHighlightCustomizationFeature,
    aiPickedDominantColors,
    handleBackgroundColorChange,
    backgroundColorForSignatureAlbum,
    handleFontColorChange,
    fontColorForSignatureAlbum,
    handleAccentColorChange,
    accentColorForSignatureAlbum,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryDesignCustomizationSettings);
