import {
  ButtonIcon,
  Card,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { useEffect } from 'react';
import APP_URLS from '../../../../../../services/AppRouteURLService';
import EventAttendeeFaceCheckinStatsListContainer from './EventAttendeeFaceCheckinStatsListContainer';

type Props = {
  eventId: string;
  projectId: string;
};

export default function EventAttendeeFaceCheckinStats(props: Props) {
  const { eventId, projectId } = props;
  const urlForPeoplesPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEES, {
    eventId,
  });

  return (
    <InnerPageContentMainCol hasSidebar={false}>
      <Row vcenter>
        <ButtonIcon title="Back" link={urlForPeoplesPage}>
          <Icon name="arrow_left" size={ICON_SIZES.SM} />
        </ButtonIcon>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          Attendee Checkin Report
        </Text>
      </Row>
      <Space vertical />
      <Card>
        <Space>
          <EventAttendeeFaceCheckinStatsListContainer projectId={projectId} />
        </Space>
      </Card>
    </InnerPageContentMainCol>
  );
}
