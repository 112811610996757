import React from 'react';

import { Button, BUTTON_SIZE, BUTTON_STYLES, Space, Icon, ICON_SIZES } from '@premagic/myne';

type FolderCoverPhotoSetFocusButtonProps = {
  isFocalPointUpdating: boolean;
  folderId: string;
  openFolderCoverFocalPointModal: (folderId: string) => void;
};

export default function FolderCoverPhotoSetFocusButton(props: FolderCoverPhotoSetFocusButtonProps): React.ReactElement {
  const { isFocalPointUpdating, folderId, openFolderCoverFocalPointModal } = props;

  return (
    <Button
      style={BUTTON_STYLES.LINK}
      size={BUTTON_SIZE.SM}
      isLoading={isFocalPointUpdating}
      onClick={() => openFolderCoverFocalPointModal(folderId)}
    >
      <Icon name="loader" size={ICON_SIZES.SM} />
      <Space size={1} />
      set focal
    </Button>
  );
}
