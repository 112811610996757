import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import SelectionBudget from './SelectionBudget';
import { saveProjectMetaData, projectMetaDataForProjectSelector } from '../project-meta/ProjectsMetaDataDuck';
import { saveClientSettingsData, clientSettingsDataSelector } from '../../settings/preferences/ClientSettingsDataDuck';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.SELECTION_BUDGET;
const DIALOG_KEY = LOADINGS.PROJECT_META.SHOW_UPDATE_PANEL_ITEM(META_ITEM);

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const clientSettings = clientSettingsDataSelector(state);
  return {
    projectId,
    defaultSelectionBudget: clientSettings?.default_selection_budget,
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    isDialogShown: ModalDuck.isModalOpenSelector(DIALOG_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show: boolean) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(
        saveProjectMetaData(dispatch, projectId, formResponse, {
          itemId: META_ITEM,
          closeModal: true,
        }),
      );
    },
    saveClientSettingsData: (formResponse) => {
      dispatch(saveClientSettingsData(dispatch, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionBudget);
