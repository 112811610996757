import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';
import { CompanyStatsService } from '@premagic/core';
import { entitiesDataSelector, EntitiesStateType } from '../../../reducers/selectors';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('COMPANY_STATS');

export const setCompanyStatsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const getCompanyStatsData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.COMPANY.STATS_FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));

  CompanyStatsService.fetchCompanyStats()
    .then((response) => {
      dispatch(setCompanyStatsData(dispatch, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

type StateType = {
  item: CompanyStatsService.CompanyStatsType;
};

const initialState = {
  item: {},
};

export default handleActions(
  {
    [setCompanyStatsData.toString()]: (state, action) => ({
      ...state,
      item: action.payload,
    }),
  },
  initialState,
);

export const companyStatsSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.companyStats as StateType).item,
);
