import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import {
  clientSettingsDataSelector,
  isCompanyTypeNotPhotographerSelector,
  saveClientSettingsData,
} from '../preferences/ClientSettingsDataDuck';
import { hasFeatureSelector, photographerNameSelector } from '../company/CompanyDataDuck';
import EventMarketingSettingsPage from './EventMarketingSettingsPage';

function mapStateToProps(state) {
  return {
    clientSettings: clientSettingsDataSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.APP_LOADING)(state) || isLoadingSelector(LOADINGS.CLIENT_SETTINGS.FETCH_ALL)(state),
    isSaving: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    hasFeatures: {
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_MARKETING]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_MARKETING,
      )(state),
    },
    companyName: photographerNameSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventMarketingSettingsPage);
