import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { foldersEntitiesSelector, renameFolder } from '../AccountFoldersDataDuck';
import FolderRenameDialog from './FolderRenameDialog';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';

const COMMON_KEY = LOADINGS.FOLDER.RENAME;
function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  return {
    folder: foldersEntitiesSelector(state)[folderId] || {},
    isRenaming: isLoadingSelector(COMMON_KEY)(state),
    projectId,
    folderId,
    isRenameFolderShown: isModalOpenSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, COMMON_KEY, show));
    },
    renameFolder: (projectId: string, folderId: string, data) => {
      dispatch(renameFolder(dispatch, projectId, folderId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderRenameDialog);
