import { CompanyService } from '@premagic/core';
import {
  BUTTON_STYLES,
  Button,
  Card,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  InnerPageContent,
  InnerPageContentMainCol,
  Input,
  Space,
} from '@premagic/myne';

type Props = {
  saveCompanyData: (data: Record<string, any>) => void;
  errors: Record<string, string>;
  isSaving: boolean;
  customPolicy?: {
    terms_of_service?: string | undefined;
    privacy_policy?: string | undefined;
  };
};

export default function CompanySettingsCustomPolicy(props: Props) {
  const { errors, isSaving, saveCompanyData, customPolicy } = props;
  const { terms_of_service: customTermsOfService, privacy_policy: customPrivacyPolicy } = customPolicy || {};

  function handleSubmit(formData) {
    saveCompanyData(formData);
  }

  return (
    <InnerPageContent hasInnerCols>
      <InnerPageContentMainCol>
        <Card>
          <Space>
            <Form onSubmit={handleSubmit} errors={errors}>
              <FormGroup>
                <FormLabel>Custom "Terms of service" URL</FormLabel>
                <Input
                  name="custom_policy[terms_of_service]"
                  type="url"
                  defaultValue={customTermsOfService}
                  placeholder="https://blog.premagic.com/terms/?utm_source=inside-premagic&utm_medium=attendee-register"
                />
              </FormGroup>
              <Space vertical />
              <FormGroup>
                <FormLabel>Custom "Privacy policy" URL</FormLabel>
                <Input
                  name="custom_policy[privacy_policy]"
                  type="url"
                  defaultValue={customPrivacyPolicy}
                  placeholder="https://blog.premagic.com/privacy/?utm_source=inside-premagic&utm_medium=attendee-register"
                />
              </FormGroup>
              <Space vertical size={1} />

              <FormFooter>
                <Button type="submit" style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                  Save
                </Button>
              </FormFooter>
            </Form>
          </Space>
        </Card>
      </InnerPageContentMainCol>
    </InnerPageContent>
  );
}
