import { BACKGROUND_MUSICS_IDS } from './BackgroundMusicService';
import { CLIP_ART_IDS } from './ClientWebsiteClipArtService';
import { RELIGIOUS_CLIP_ARTS_IDS } from './ClientWebsiteReligiousClipArtService';
import { THEMES_IDS } from './ThemeService';

// Duplicated from myne as we cannot use @premagic/myne inside the core (cycle dependecy)
enum INPUT_TYPES {
  TEXT = 'text',
  DATETIME = 'datetime',
  DATETIME_WITH_TZ = 'datetime-tz',
  DATE = 'date',
  TEXTAREA = 'textarea',
  RICH_TEXTAREA = 'rich-textarea',
  RICH_TEXTAREA_INLINE_SIMPLE = 'rich-textarea-inline-simple',
  FILE = 'file',
  IMAGE = 'image',
  URL = 'url',
  COLOR = 'color',
  TEL = 'tel',
}

export enum CLIENT_WEBSITE_FORM_ITEMS {
  WEBSITE_ID,
  PHOTO_THUMBNAIL,
  PHOTO_COUPLE_1,
  PHOTO_COUPLE_1_LG,
  PHOTO_COUPLE_2,
  PHOTO_BRIDE,
  PHOTO_GROOM,
  NAME_BRIDE,
  BRIDE_INFO,
  NAME_GROOM,
  GROOM_INFO,
  EVENT_SCHEDULE,
  EVENT_TAGLINE,
  EVENT_CONTACT_PHONE,
  EVENT_VENUE,
  EVENT_INSTRUCTIONS,
  EVENT_VIDEO_LINK,
  EVENT_TRAILER_VIDEO_LINK,
  EVENT_GALLERY_LINK,
  EVENT_VENUE_MAP_LINK,
  CLIPART_ID,
  RELIGIOUS_CLIPART_ID,
  THEME_ID,
  THEME_COLOR_BACKGROUND,
  THEME_COLOR_PRIMARY,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_TERTIARY,
  THEME_FONT_PRIMARY,
  THEME_FONT_SECONDARY,
  MUSIC_ID,
  DETAILED_TAGLINE,
  PHOTO_GRID,
  SLOGAN,
}

export enum CLIENT_WEBSITE_CUSTOM_INPUT_TYPES {
  THEME_SELECT = 'THEME_SELECT',
  CLIPART_SELECT = 'CLIPART_SELECT',
  MUSIC_SELECT = 'MUSIC_SELECT',
  RELIGIOUS_CLIPART_SELECT = 'RELIGIOUS_CLIPART_SELECT',
  PHOTO_GRID = 'PHOTO_GRID',
}

type ClientWebsiteFormItemType = {
  title: string;
  name: string;
  secondaryInputName?: string;
  type: INPUT_TYPES | CLIENT_WEBSITE_CUSTOM_INPUT_TYPES;
  placeholder?: string;
  defaultValue?: string | number;
  maxLength?: number;
  accept?: string;
  readOnly?: boolean;
  info?: string;
  required?: boolean;
};

export type ClientWebsiteFormItemImageType = ClientWebsiteFormItemType & {
  nameUsedToSendToServer?: string;
  type: INPUT_TYPES.IMAGE;
};

const CLIENT_WEBSITE_IMAGES = {
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_THUMBNAIL]: {
    title: 'Social media thumbnail',
    info: 'This photo will be used on social media. Size: 1200 pixels x 627 pixels (1.91/1 ratio)',
    type: INPUT_TYPES.IMAGE,
    name: 'data[images][thumbnail]',
    nameUsedToSendToServer: 'thumbnail',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_1]: {
    title: 'Couple Photo',
    type: INPUT_TYPES.IMAGE,
    name: 'data[images][couple1]',
    nameUsedToSendToServer: 'couple1',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_1_LG]: {
    title: 'Couple Photo large',
    info: 'The photo will be used for desktop',
    type: INPUT_TYPES.IMAGE,
    name: 'data[images][couple1_large]',
    nameUsedToSendToServer: 'couple1_large',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_BRIDE]: {
    title: 'Bride Photo',
    type: INPUT_TYPES.IMAGE,
    name: 'data[images][bride]',
    nameUsedToSendToServer: 'bride',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_GROOM]: {
    title: 'Groom Photo',
    type: INPUT_TYPES.IMAGE,
    name: 'data[images][groom]',
    nameUsedToSendToServer: 'groom',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_2]: {
    title: 'Couple Photo Gallery',
    type: INPUT_TYPES.IMAGE,
    name: 'data[images][couple2]',
    nameUsedToSendToServer: 'couple2',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_GRID]: {
    title: 'Photo grid ( max: 8 )',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_GRID,
    name: 'data[images][photo_grid]',
    nameUsedToSendToServer: 'photo_grid',
    accept: '.jpg,.jpeg',
  },
};

const CLIENT_WEBSITE_EVENT_ITEMS = {
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_SCHEDULE]: {
    title: 'Event Date',
    type: INPUT_TYPES.DATETIME_WITH_TZ,
    placeholder: 'date and time of the event',
    name: 'data[schedule]',
    secondaryInputName: 'data[schedule_timezone]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_TAGLINE]: {
    title: 'Tagline',
    type: INPUT_TYPES.TEXT,
    defaultValue: "We can't wait to celebrate our special day with you",
    maxLength: 250,
    placeholder: '',
    name: 'data[tagline]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_CONTACT_PHONE]: {
    title: 'Guest Contact phone number',
    type: INPUT_TYPES.TEL,
    placeholder: '',
    name: 'data[contact][phone]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_VENUE]: {
    title: 'Location',
    type: INPUT_TYPES.TEXTAREA,
    placeholder: 'eg. Taj Mahal',
    name: 'data[venue][location]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_VENUE_MAP_LINK]: {
    title: 'Google map link',
    type: INPUT_TYPES.URL,
    placeholder: 'eg. https://goo.gl/maps/GKFDag2gdtwZH9Qu9',
    name: 'data[venue][map_link]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_INSTRUCTIONS]: {
    title: 'Instruction',
    maxLength: 250,
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: '',
    name: 'data[instruction]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_VIDEO_LINK]: {
    title: 'LiveStream video link',
    type: INPUT_TYPES.URL,
    placeholder: '',
    name: 'data[livestream_link]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_TRAILER_VIDEO_LINK]: {
    title: 'SaveTheDate Trailer video',
    info: 'Add the embed url',
    type: INPUT_TYPES.URL,
    placeholder: '',
    name: 'data[trailer_video_link]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_GALLERY_LINK]: {
    title: 'Gallery url',
    info: 'This will show up in the bottom',
    type: INPUT_TYPES.URL,
    placeholder: 'Your Signature album link',
    name: 'data[gallery_link]',
  },
};

export const CLIENT_WEBSITE_FORM_ITEMS_DETAILS: Record<
  CLIENT_WEBSITE_FORM_ITEMS,
  ClientWebsiteFormItemType | ClientWebsiteFormItemImageType
> = {
  ...CLIENT_WEBSITE_IMAGES,
  ...CLIENT_WEBSITE_EVENT_ITEMS,
  [CLIENT_WEBSITE_FORM_ITEMS.WEBSITE_ID]: {
    title: 'URL name',
    name: 'website_id',
    type: INPUT_TYPES.TEXT,
    placeholder: 'janewedsjohn',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE]: {
    title: 'Bride Name',
    name: 'data[bride_name]',
    type: INPUT_TYPES.TEXT,
    placeholder: 'jane',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM]: {
    title: 'Groom Name',
    name: 'data[groom_name]',
    type: INPUT_TYPES.TEXT,
    placeholder: 'John',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.RELIGIOUS_CLIPART_ID]: {
    title: 'Religious Clip Art',
    name: 'theme[religious_clipart_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.RELIGIOUS_CLIPART_SELECT,
    defaultValue: RELIGIOUS_CLIP_ARTS_IDS.NONE,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.CLIPART_ID]: {
    title: 'Clip Art',
    name: 'theme[clipart_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.CLIPART_SELECT,
    defaultValue: CLIP_ART_IDS.MAUNDAYS_BAY,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_ID]: {
    title: 'Theme',
    name: 'theme[id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.THEME_SELECT,
    defaultValue: THEMES_IDS.BAIKAL,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_BACKGROUND]: {
    title: 'Background',
    name: 'theme[color][background]',
    type: INPUT_TYPES.COLOR,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_PRIMARY]: {
    title: 'Primary color',
    name: 'theme[color][primary_color]',
    type: INPUT_TYPES.COLOR,
    defaultValue: '#7B90BF',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_SECONDARY]: {
    title: 'Secondary color',
    name: 'theme[color][secondary_color]',
    type: INPUT_TYPES.COLOR,
    defaultValue: '#E6EEFF',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_TERTIARY]: {
    title: 'Accent color',
    name: 'theme[color][tertiary_color]',
    type: INPUT_TYPES.COLOR,
    defaultValue: '#D4D4D4',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_FONT_PRIMARY]: {
    title: 'Primary font',
    name: 'theme[font][primary_font]',
    type: INPUT_TYPES.TEXT,
    readOnly: true,
    defaultValue: 'Snell Roundhand',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_FONT_SECONDARY]: {
    title: 'Secondary font',
    name: 'theme[font][secondary_font]',
    type: INPUT_TYPES.TEXT,
    readOnly: true,
    defaultValue: 'Poppins',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.MUSIC_ID]: {
    title: 'Music',
    name: 'theme[music_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.MUSIC_SELECT,
    defaultValue: BACKGROUND_MUSICS_IDS.NONE,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.GROOM_INFO]: {
    title: 'Groom info',
    name: 'data[groom_info]',
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: 'Son of ...',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.DETAILED_TAGLINE]: {
    title: 'Detailed tagline',
    name: 'data[detailed_tagline]',
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder:
      'eg. Request the honour of your presence & blessings on the auspicious Occasion of the wedding ceremony of their grandson',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.BRIDE_INFO]: {
    title: 'Bride info',
    name: 'data[bride_info]',
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: 'Daughter of ...',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.SLOGAN]: {
    title: 'Slogan',
    name: 'data[slogan]',
    type: INPUT_TYPES.TEXT,
    placeholder: 'eg. Shree Ganeshaya Namaha',
  },
};

export enum EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE {
  EMAIL = 'email',
  PHONE = 'phone',
  BOTH = 'both',
}

export const EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE_DETAILS: Record<
  EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE,
  {
    id: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
    value: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
    title: string;
  }
> = {
  [EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL]: {
    id: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL,
    value: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL,
    title: 'Email',
  },
  [EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE]: {
    id: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
    value: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
    title: 'Phone',
  },
  [EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH]: {
    id: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
    value: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
    title: 'Both',
  },
};
