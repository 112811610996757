import { HttpUtils, SimpleDateUtils } from '@premagic/utils';
import { CompanyType, remainingAIDeliveryPhotos, remainingProjects } from './CompanyService';
import { CompanyStatsType } from './CompanyStatsService';
import { API_URLS } from './APIURLService';
import { PRICING_PLAN_VARIANT_FREQUENCY, PricingPlanVariantId } from './PricingPlanService';
import { ClientSettingsType } from './ClientSettingsService';

// https://razorpay.com/docs/subscriptions/lifecycle/
export enum SUBSCRIPTION_STATUS {
  CREATED = 'created',
  AUTHENTICATED = 'authenticated',
  ACTIVE = 'active',
  PENDING = 'pending',
  PAUSED = 'paused',
  HALTED = 'halted',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export const SUBSCRIPTION_STATUS_MESSAGES: Record<
  SUBSCRIPTION_STATUS,
  {
    title: string;
    subText: (days?: number) => string;
  }
> = {
  [SUBSCRIPTION_STATUS.CANCELLED]: {
    title: 'Your subscription has expired',
    subText: () => 'To continue using Premagic, please extend your subscription.',
  },
  [SUBSCRIPTION_STATUS.PAUSED]: {
    title: 'Your subscription has been paused',
    subText: () => 'To continue using Premagic, please extend your subscription.',
  },
  [SUBSCRIPTION_STATUS.EXPIRED]: {
    title: 'Your subscription has expired',
    subText: () => 'To continue using Premagic, please extend your subscription.',
  },
  [SUBSCRIPTION_STATUS.HALTED]: {
    title: 'Your subscription has been paused',
    subText: () => 'To continue using Premagic, please extend your subscription.',
  },
  [SUBSCRIPTION_STATUS.ACTIVE]: {
    title: 'Your subscription is active',
    subText: (days) => `Your subscription will expire in ${days} days.`,
  },
  [SUBSCRIPTION_STATUS.PENDING]: {
    title: 'Your subscription is pending',
    subText: () => 'Your subscription is pending.',
  },
  [SUBSCRIPTION_STATUS.AUTHENTICATED]: {
    title: 'Your subscription is authenticated',
    subText: () => 'Your subscription is authenticated.',
  },
  [SUBSCRIPTION_STATUS.CREATED]: {
    title: 'Your subscription is created',
    subText: () => 'Your subscription is created.',
  },
  [SUBSCRIPTION_STATUS.COMPLETED]: {
    title: 'Your subscription has been completed',
    subText: () => 'Your subscription has been completed.',
  },
};

export type SubscriptionType = {
  subscription_id: string;
  subscription_provider: 'razorpay' | 'premagic';
  plan_id: string;
  variant_id: string;
  status: SUBSCRIPTION_STATUS;
  subscription_start_date: number;
  current_billing_cycle: {
    current_start: number;
    current_end: number;
  };
  ended_at: number;
  next_billing_date: number;
  auth_attempts: number;
  num_of_billing_cycles_paid: number;
  authorization_url: string;
  authorization_expiry: number;
};

export function hasActiveSubscription(subscriptions?: SubscriptionType): boolean {
  // If there is no subscription then we are assuming the user is in Prepaid plan
  if (!subscriptions) return true;
  const now = SimpleDateUtils.nowInEpoc() / 1000;
  const {
    next_billing_date: nextBillingDate,
    status,
    current_billing_cycle: currentBillingCycle,
    ended_at: endedAt,
  } = subscriptions;
  const { current_end: currentEnd } = currentBillingCycle || {};
  if (now > endedAt) return false;

  switch (status) {
    case SUBSCRIPTION_STATUS.HALTED:
      return false;
    case SUBSCRIPTION_STATUS.CANCELLED:
      return now < currentEnd;
    case SUBSCRIPTION_STATUS.PAUSED:
      return now < nextBillingDate;
    case SUBSCRIPTION_STATUS.COMPLETED:
      return now < currentEnd;
    case SUBSCRIPTION_STATUS.EXPIRED: // this is when the created subscription object is expired
      return false;
    default:
      return true;
  }
}

export function fetchCurrentSubscription(): Promise<SubscriptionType> {
  return HttpUtils.get(API_URLS.SUBSCRIPTION.ROOT).then(({ data }) => data);
}

export function getSubscriptionHaltedReason(data: {
  company: CompanyType;
  companyStats: CompanyStatsType;
  clientSettings: ClientSettingsType;
}) {
  const { company, companyStats, clientSettings } = data;
  const remainingPhotos = remainingAIDeliveryPhotos({
    pricingPlan: company?.currentPricingPlan,
    companyStats,
    clientSettings,
  });
  if (remainingPhotos <= 0) return 'over usage of AI photos';
  if (remainingProjects(company?.currentPricingPlan, company) <= 0) return 'over usage of projects';

  return null;
}

function getStatusFromSubscription(company: CompanyType, companyStats?: CompanyStatsType): SUBSCRIPTION_STATUS {
  const { subscription_start_date: startDate, is_blocked: isBlocked, subscription_end_date: endDate } = company;

  if (isBlocked) return SUBSCRIPTION_STATUS.CANCELLED;
  // Check for remaining AI photos
  // const remainingPhotos = companyStats ? remainingAIDeliveryPhotos(company.currentPricingPlan, companyStats) : 1;
  // if (remainingPhotos <= 0) return SUBSCRIPTION_STATUS.HALTED;
  // Check for remaining projects
  // if (remainingProjects(company.currentPricingPlan, company) < 0) return SUBSCRIPTION_STATUS.HALTED;

  const endDateInEpoc = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(endDate);
  const now = SimpleDateUtils.nowInEpoc() / 1000;
  const isOver = endDateInEpoc <= now;
  if (isOver) return SUBSCRIPTION_STATUS.CANCELLED;
  return SUBSCRIPTION_STATUS.ACTIVE;
}

export function getSubscriptionFromCompanyData(
  company: CompanyType,
  companyStats?: CompanyStatsType,
): SubscriptionType {
  const {
    subscription_start_date: startDate,
    subscription_end_date: endDate,
    currentPricingPlan,
    customer_country_code: countryCode,
    subscription_payment_link: authorizationUrl,
  } = company;
  const startDateInEpoc = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(startDate);
  const endDateInEpoc = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(endDate);

  const status = getStatusFromSubscription(company, companyStats);

  return {
    subscription_id: '',
    subscription_provider: 'premagic',
    plan_id: currentPricingPlan?.id,
    variant_id: `${PRICING_PLAN_VARIANT_FREQUENCY.YEARLY}_${countryCode}`,
    status,
    subscription_start_date: startDateInEpoc,
    current_billing_cycle: {
      current_start: startDateInEpoc,
      current_end: endDateInEpoc,
    },
    ended_at: endDateInEpoc,
    next_billing_date: endDateInEpoc,
    auth_attempts: 0,
    num_of_billing_cycles_paid: 0,
    authorization_url: authorizationUrl || '/account/subscriptions/' || 'https://razorpay.me/@premagic',
    authorization_expiry: 0,
  };
}

export function createSubscriptionForThePlan(
  planId: string,
  variantId: PricingPlanVariantId,
): Promise<{
  success: boolean;
  subscription_id: string;
}> {
  return HttpUtils.post(API_URLS.SUBSCRIPTION.ROOT, { plan_id: planId, variant_id: variantId }).then(
    ({ data }) => data,
  );
}
