import React from 'react';

import {
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  LIST_ITEM_ACTION_STYLES,
  ListItemAction,
  Tooltip,
  Row,
  Button,
  ScrollableContainer,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Space,
} from '@premagic/myne';

import { BrowserUrlUtils } from '@premagic/utils';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import APP_URLS from '../../../../services/AppRouteURLService';
import AddSignatureAlbumButtonContainer from '../../../crm/event-deliveries/signature-album/AddSignatureAlbumButtonContainer';
import AccountFolderItemContainer from '../../folders/AccountFolderItemContainer';

type Props = {
  folderIds: Array<string>;
  activeFolderId?: string;
  projectId: string;
  updateOrder: (projectId: string, folderIds: Array<string>, data: { from: number; to: number }) => void;
};

export default function HighlightFoldersList(props: Props): JSX.Element {
  const { projectId, folderIds, updateOrder, activeFolderId } = props;
  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;
    if (reason === 'DROP' && destination) {
      updateOrder(projectId, folderIds, {
        from: source.index,
        to: destination.index,
      });
    }
  }
  return (
    <DragDropContext onDragEnd={onDrop}>
      <Droppable droppableId="FOLDERS-LIST" direction="horizontal">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <ScrollableContainer direction="vertical" showShadow scrollTo={`.js-folder-${activeFolderId}`}>
              <Row>
                {folderIds.map((folderId, idx) => {
                  const folderUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT, {
                    projectId,
                    folderId,
                  });

                  return (
                    <Draggable key={folderId} draggableId={folderId} index={idx}>
                      {(draggableProvided, draggableSnapshot) => (
                        <Button
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={BUTTON_STYLES.DEFAULT}
                          size={BUTTON_SIZE.SM}
                          key={folderId}
                          link={folderUrl}
                          active={folderId === activeFolderId}
                          className={`js-folder-${folderId}`}
                        >
                          <ListItemAction style={LIST_ITEM_ACTION_STYLES.DEFAULT}>
                            <Tooltip message="Drag and move to reorder" placement="left">
                              <Row>
                                <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                                  <Icon name="drag" size={ICON_SIZES.SM} />
                                </Color>
                              </Row>
                            </Tooltip>
                          </ListItemAction>
                          <AccountFolderItemContainer projectId={projectId} folderId={folderId} />
                        </Button>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Row>
            </ScrollableContainer>
          </div>
        )}
      </Droppable>
      <Space vertical size={2} />
      <AddSignatureAlbumButtonContainer projectId={projectId} key={activeFolderId} />
    </DragDropContext>
  );
}
