import React, { useEffect } from 'react';
import {
  Dropdown,
  DropdownIcon,
  Icon,
  Menu,
  MenuItem,
  Row,
  Space,
  WorkflowStageItem,
  WorkflowStages,
} from '@premagic/myne';
import { EventsService } from '@premagic/core';
import { CRM_FILTER_KEYS, CRMFiltersType } from '../../filters/CRMFilterTypes';
import { getCRMDashboardUrlWithFilters } from '../../filters/CRMFilterService';

const WORKFLOW_STAGES: Array<{
  id: EventsService.EVENT_STATUS;
  title: string;
  filter: CRMFiltersType;
}> = [
  {
    id: EventsService.EVENT_STATUS.NEW,
    title: EventsService.EVENT_STATUS_TYPE_DETAILS.NEW.title,
    filter: {
      [CRM_FILTER_KEYS.STATUS]: EventsService.EVENT_STATUS.NEW,
    },
  },
  {
    id: EventsService.EVENT_STATUS.QUALIFIED,
    title: EventsService.EVENT_STATUS_TYPE_DETAILS.QUALIFIED.title,
    filter: {
      [CRM_FILTER_KEYS.STATUS]: EventsService.EVENT_STATUS.QUALIFIED,
    },
  },
  {
    id: EventsService.EVENT_STATUS.CONFIRMED,
    title: EventsService.EVENT_STATUS_TYPE_DETAILS.CONFIRMED.title,
    filter: {
      [CRM_FILTER_KEYS.STATUS]: EventsService.EVENT_STATUS.CONFIRMED,
    },
  },
];

const WORKFLOW_END_STAGES: Array<{
  title: string;
  filter: CRMFiltersType;
}> = [
  {
    title: EventsService.EVENT_STATUS_TYPE_DETAILS.REJECTED.title,
    filter: {
      [CRM_FILTER_KEYS.STATUS]: EventsService.EVENT_STATUS.REJECTED,
    },
  },
];
type Props = {
  filters: CRMFiltersType;
  view: 'calendar' | 'list';
  navigateTo: (url: string) => void;
  countByStatus: Record<EventsService.EVENT_STATUS, number>;
  fetchCountByEventStatus: () => void;
  isLoadingCounts: boolean;
};

export default function CRMDashboardWorkflowStages(props: Props): JSX.Element {
  const { filters = {}, view, navigateTo, isLoadingCounts, countByStatus, fetchCountByEventStatus } = props;

  useEffect(() => {
    fetchCountByEventStatus();
  }, [fetchCountByEventStatus]);

  function getUrlToItem(newFilter: CRMFiltersType): string {
    return getCRMDashboardUrlWithFilters(view, {
      ...filters,
      ...newFilter,
    });
  }

  return (
    <Row vcenter>
      <WorkflowStages>
        {WORKFLOW_STAGES.map((stage) => (
          <WorkflowStageItem
            key={stage.id}
            isLoadingCounts={isLoadingCounts}
            count={countByStatus[stage.id]}
            title={stage.title}
            to={getUrlToItem(stage.filter)}
            active={stage.filter[CRM_FILTER_KEYS.STATUS] === filters[CRM_FILTER_KEYS.STATUS]}
          />
        ))}
      </WorkflowStages>
      <Space size={2} />
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="left">
          <Icon name="more_horizontal" />
        </DropdownIcon>
        <Menu>
          {WORKFLOW_END_STAGES.map((stage) => (
            <MenuItem onClick={() => navigateTo(getUrlToItem(stage.filter))} key={stage.title}>
              {stage.title}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </Row>
  );
}
