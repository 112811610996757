import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';

import { LOADINGS } from '../../../../../../common/Constants';
import { projectsSelector } from '../../../projects/AccountProjectsDataDuck';
import DeliverablesHeader from './DeliverablesHeader';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { navigateTo } from '../../../../../../services/RouterService';

function mapStateToProps(state, ownProps) {
  const { projectId } = ownProps;
  const hasClientWebsiteFeature = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE)(
    state,
  );
  return {
    projectId,
    project: projectsSelector(state)[projectId] || {},
    hasClientWebsiteFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showArchive: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT.SHOW_ARCHIVE_DIALOG, true));
    },
    showRename: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT.SHOW_RENAME_DIALOG, true));
    },
    navigateTo: (url) => {
      navigateTo(dispatch, url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliverablesHeader);
