export const PROPOSALS_URLS = {
  PROPOSALS_TEMPLATES: {
    LIST: '/settings/proposals-templates/',
    DETAILS: '/settings/proposals-templates/:folderId/:deckId/:slideId?',
  },
  PROPOSALS: {
    SETTINGS: '/settings/proposal/',
    DETAILS: '/crm/proposals/:projectId/:folderId/:deckId/:slideId?',
    HISTORY: '/crm/proposal/history/:projectId/:folderId/:deckId/',
  },
  CRM: {
    EVENT__DETAILS: '/crm/events/:eventId/v/details/:focusId?',
  },
};
