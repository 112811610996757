import { COLOR_SHADES, Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';

type Props = {
  pageNo: number;
  totalPages: number;
};

export default function AlbumCreatorPageInfo(props: Props) {
  const { pageNo, totalPages } = props;
  return (
    <Row vcenter>
      <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
        {pageNo}
      </Text>
      <Space size={1} />
      <Text size={TEXT_SIZE.SIZE_1} muted>
        &#x2215;
      </Text>
      <Space size={1} />
      <Text boldness={TEXT_BOLDNESS.BOLD}>{totalPages}</Text>
      <Space size={2} />
    </Row>
  );
}
