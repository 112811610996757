import { MediaUtils } from '@premagic/utils';

export enum RELIGIOUS_CLIP_ART_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum RELIGIOUS_CLIP_ART_ITEMS_IDS {
  ITEM_1 = 'item-1',
}

const CLIP_ART_DEFAULT_SIZES_DETAILS: Record<RELIGIOUS_CLIP_ART_SIZES, { sizeUnit: number }> = {
  [RELIGIOUS_CLIP_ART_SIZES.XS]: {
    sizeUnit: 1,
  },
  [RELIGIOUS_CLIP_ART_SIZES.SM]: {
    sizeUnit: 2,
  },
  [RELIGIOUS_CLIP_ART_SIZES.MD]: {
    sizeUnit: 3,
  },
  [RELIGIOUS_CLIP_ART_SIZES.LG]: {
    sizeUnit: 4,
  },
};

/**
 *  sizeStartValue is the variable which determine the initial size of the item, and then higher size will be multiple of it.
 */
function getHeightForSize(size: RELIGIOUS_CLIP_ART_SIZES, sizeStartValue = 8): number {
  const sizeDetails = CLIP_ART_DEFAULT_SIZES_DETAILS[size];
  return sizeDetails.sizeUnit * sizeStartValue * 4;
}

export function getSizeForReligiousClipArt(
  size: RELIGIOUS_CLIP_ART_SIZES,
  item?: { sizeStartValue?: number; ratio?: number },
): { width: number; height: number } {
  if (item?.sizeStartValue) {
    const height = getHeightForSize(size, item.sizeStartValue);
    return {
      height,
      width: MediaUtils.getImageWidthForRatio(height, item.ratio || 1),
    };
  }

  const height = getHeightForSize(size);
  return {
    height,
    width: height,
  };
}

export enum RELIGIOUS_CLIP_ARTS_IDS {
  NONE = 0,
  GANESHA = 1,
  CROSS = 2,
  MOONSTAR = 3,
  ARABIC = 4,
  DOVE = 5,
}

export function getReligiousClipartImageUrl(itemName: string) {
  const imageUrl = `https://asts.premagic.com/s/clip-arts/01_religious-clip-arts/${itemName}.svg`;
  return imageUrl;
}

export type ReligiousClipArtAssetImageType = {
  imgSrc: string;
  sizeStartValue: number;
  ratio: number;
};

export type ReligiousClipArtAssetItemsType = {
  [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: ReligiousClipArtAssetImageType;
};

export const RELIGIOUS_CLIP_ARTS: Record<
  RELIGIOUS_CLIP_ARTS_IDS,
  {
    label: string;
    id: RELIGIOUS_CLIP_ARTS_IDS;
    folderPath: string;
  }
> = {
  [RELIGIOUS_CLIP_ARTS_IDS.NONE]: {
    label: 'No Clip Art',
    id: RELIGIOUS_CLIP_ARTS_IDS.NONE,
    folderPath: '',
  },
  [RELIGIOUS_CLIP_ARTS_IDS.GANESHA]: {
    label: 'Ganesha',
    id: RELIGIOUS_CLIP_ARTS_IDS.GANESHA,
    folderPath: 'ganesha',
  },
  [RELIGIOUS_CLIP_ARTS_IDS.CROSS]: {
    label: 'Cross',
    id: RELIGIOUS_CLIP_ARTS_IDS.CROSS,
    folderPath: 'cross',
  },
  [RELIGIOUS_CLIP_ARTS_IDS.MOONSTAR]: {
    label: 'Moon star',
    id: RELIGIOUS_CLIP_ARTS_IDS.MOONSTAR,
    folderPath: 'moonstar',
  },
  [RELIGIOUS_CLIP_ARTS_IDS.ARABIC]: {
    label: 'Arabic',
    id: RELIGIOUS_CLIP_ARTS_IDS.ARABIC,
    folderPath: 'arabic',
  },
  [RELIGIOUS_CLIP_ARTS_IDS.DOVE]: {
    label: 'Dove',
    id: RELIGIOUS_CLIP_ARTS_IDS.DOVE,
    folderPath: 'dove',
  },
};

export const religiousCliparItemDetails = {
  [RELIGIOUS_CLIP_ARTS_IDS.NONE]: {
    [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imgSrc: null,
      sizeStartValue: 15,
      ratio: 1,
    },
  },
  [RELIGIOUS_CLIP_ARTS_IDS.ARABIC]: {
    [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imgSrc: getReligiousClipartImageUrl('arabic'),
      sizeStartValue: 18,
      ratio: 1200 / 1576,
    },
  },
  [RELIGIOUS_CLIP_ARTS_IDS.CROSS]: {
    [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imgSrc: getReligiousClipartImageUrl('cross'),
      sizeStartValue: 10,
      ratio: 31.134 / 45.476,
    },
  },
  [RELIGIOUS_CLIP_ARTS_IDS.DOVE]: {
    [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imgSrc: getReligiousClipartImageUrl('dove'),
      sizeStartValue: 10,
      ratio: 54.865 / 50.855,
    },
  },
  [RELIGIOUS_CLIP_ARTS_IDS.GANESHA]: {
    [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imgSrc: getReligiousClipartImageUrl('ganesha'),
      sizeStartValue: 11,
      ratio: 38.504 / 45.476,
    },
  },
  [RELIGIOUS_CLIP_ARTS_IDS.MOONSTAR]: {
    [RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imgSrc: getReligiousClipartImageUrl('moonstar'),
      sizeStartValue: 10,
      ratio: 47.889 / 45.665,
    },
  },
};
