import { connect } from 'react-redux';
import { FolderService, SponsorCreativesService } from '@premagic/core';

import SponsorFiles from './SponsorFiles';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';
import { allFileIdsInSponsorCreativesSelector } from '../../../crm/events/event-sponsors/EventSponsorDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    onSelect: (fileId: string) => void;
    placement?: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  },
) {
  const { projectId, onSelect, placement } = ownProps;
  const sponsorFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.SPONSOR,
  )(state);

  return {
    projectId,
    folderId: sponsorFolderIds[0],
    onSelect,
    selectedFileIds: placement ? allFileIdsInSponsorCreativesSelector(placement)(state) : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorFiles);
