import { Button, BUTTON_STYLES, Emoji, EMOJIE_SIZE, EMOJIS, Row, Space, Text } from '@premagic/myne';
import React from 'react';
import EventPosterPreviewContainer from './EventPosterCreatorPreviewContainer';

type CenterComponentLayoutProps = {
  projectId: string;
  posterId?: string;
  brandingFolderId?: string;
  createBrandingFolder: (projectId: string) => void;
  isCreatingBrandingFolder: boolean;
};

export default function EventPosterPreviewLayout(props: CenterComponentLayoutProps) {
  const { projectId, posterId, createBrandingFolder, brandingFolderId, isCreatingBrandingFolder } = props;
  if (!brandingFolderId) {
    return (
      <Space size={6}>
        <Row columnDirection center>
          <Button
            style={BUTTON_STYLES.PRIMARY}
            onClick={() => createBrandingFolder(projectId)}
            isLoading={isCreatingBrandingFolder}
          >
            Enable
          </Button>
        </Row>
      </Space>
    );
  }
  if (!posterId) {
    return (
      <Space size={6}>
        <Row columnDirection center>
          <div
            style={{
              width: 400,
              height: 400,
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: '#eee',
              zIndex: 0,
            }}
          >
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
              <Row center>
                <Row center>
                  <Emoji name={EMOJIS.POINTING_LEFT} size={EMOJIE_SIZE.LG} />
                </Row>
              </Row>
              <Space vertical />
              <Row center columnDirection>
                <Text muted block>
                  Select/add a poster from the left sidebar
                </Text>
              </Row>
            </div>
          </div>
        </Row>
      </Space>
    );
  }
  return <EventPosterPreviewContainer projectId={projectId} posterId={posterId} />;
}
