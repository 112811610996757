import { HttpUtils, SimpleDateUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';
import { PAGINATION_SIZE } from '../../services/PaginationService';

export type AccountFinancialsPaymentType = {
  pk: string;
  human_readable_id: string;
  event__number: string;
  event__client__name: string;
  event_id: string;
  payment_category_id: number;
  due_date: string;
  amount: number;
};

export type AccountFinancialsMetricType = {
  total_quotes_confirmed_projects: number;
  total_pending_due_to_collect: number;
  total_overdue_payments_to_collect: number;
  total_upcoming_due: number;
  total_dues_collected: number;
  total_expenses: number;
  total_expenses_settled: number;
  total_expenses_unsettled: number;
  expected_revenue: number;
  current_revenue: number;
};

export enum ACCOUNT_FINANCIALS_TYPES {
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  EXPENSE = 'expenses',
  UNSETTLED_EXPENSE = 'unsettled-expenses',
}

const ACCOUNT_FINANCIALS_TYPES_API_URL: Record<
  ACCOUNT_FINANCIALS_TYPES,
  | 'over_due_payments_by_event'
  | 'upcoming_payments_by_event'
  | 'total_expenses_by_event'
  | 'unsettled_expenses_by_event'
> = {
  [ACCOUNT_FINANCIALS_TYPES.PENDING]: 'over_due_payments_by_event',
  [ACCOUNT_FINANCIALS_TYPES.UPCOMING]: 'upcoming_payments_by_event',
  [ACCOUNT_FINANCIALS_TYPES.EXPENSE]: 'total_expenses_by_event',
  [ACCOUNT_FINANCIALS_TYPES.UNSETTLED_EXPENSE]: 'unsettled_expenses_by_event',
};

export type AccountFinancialsPaymentFiltersType = {
  start_date_gte: string;
  // start_date_lte: string;
  // end_date_gte: string;
  // end_date_lte: string;
  slice: 'monthly' | 'weekly' | 'yearly';
};

export function fetchAccountFinancialsPayment(
  type: ACCOUNT_FINANCIALS_TYPES,
  filters?: AccountFinancialsPaymentFiltersType,
): Promise<{ results: Array<AccountFinancialsPaymentType>; count: number }> {
  const options = {
    params: {
      start_date_gte: SimpleDateUtils.getDateStringFromDate(
        SimpleDateUtils.now(),
        SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO,
      ),
      ...filters,
      limit: PAGINATION_SIZE,
    },
  };
  const url = APIURLService.API_URLS.ACCOUNT_FINANCIALS.LIST(ACCOUNT_FINANCIALS_TYPES_API_URL[type]);
  return HttpUtils.get(url, options).then((response) => response.data);
}

export function fetchAccountFinancialsMetric(
  filters?: AccountFinancialsPaymentFiltersType,
): Promise<AccountFinancialsMetricType> {
  const options = {
    params: {
      start_date_gte: SimpleDateUtils.getDateStringFromDate(
        SimpleDateUtils.now(),
        SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO,
      ),
      ...filters,
    },
  };
  return HttpUtils.get(APIURLService.API_URLS.ACCOUNT_FINANCIALS.COUNTS, options).then((response) => response.data);
}
