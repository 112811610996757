import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';
import { pagesSelector } from '../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_OVERVIEW');

export const setFolderAndImagesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const initialState = {
  folders: IMap({}),
  images: IMap({}),
};

export default handleActions(
  {
    [setFolderAndImagesData.toString()]: (state, action) => ({
      ...state,
      folders: IMap(action.payload.folders),
      images: IMap(action.payload.images),
    }),
  },
  initialState,
);

export const projectOverviewDataSelector = createSelector(
  pagesSelector,
  (state: any) => state.projectOverview.data.all,
);

export const projectOverviewFoldersSelector = createSelector(projectOverviewDataSelector, (data: any) =>
  data.folders.valueSeq().toJS(),
);

export const projectOverviewImagesSelector = createSelector(projectOverviewDataSelector, (data: any) =>
  data.images.toJS(),
);
