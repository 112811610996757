import { MediaUtils, MathUtils } from '@premagic/utils';
import { COLOUR_PALETTES_IDS } from './ColorPaletteService';
import { PRESET_DESIGN_IDS } from './PresetDesignService';
// ISLAND NAMES

export enum CLIP_ART_IDS {
  NONE = '0',
  MAUNDAYS_BAY = 1,
  KIAWAH = 2,
  TRUNK_BAY = 3,
  PARROT_CAY = 4,
  EL_NIDO = 5,
  REETHI_RAH = 6,
  HORSESHOE_BAY = 7,
  MATIRA = 8,
  HONOKALANI = 9,
  SEVEN_MILE = 10,
  GOUVERNEUR = 11,
  ELAFONISI = 12,
  ENGLISH_HARBOUR = 13,
  PSAROU = 14,
  TEMAE_PLAGE_PUBLIQUE = 15,
  HONOPU = 16,
  SIASCONSET = 17,
  // KOH_HEY = 18,
  // BATHSHEBA = 19,
  // SEAGRASS_BAY = 20,
  // LA_CHIVA = 21,
}

export const CLIP_ARTS: Record<
  CLIP_ART_IDS,
  {
    label: string;
    id: CLIP_ART_IDS;
    folderPath: string;
  }
> = {
  [CLIP_ART_IDS.NONE]: {
    label: 'No Clip Art',
    id: CLIP_ART_IDS.NONE,
    folderPath: '',
  },
  [CLIP_ART_IDS.MAUNDAYS_BAY]: {
    label: 'Maundays Bay',
    id: CLIP_ART_IDS.MAUNDAYS_BAY,
    folderPath: 'maundays-bay',
  },
  [CLIP_ART_IDS.KIAWAH]: {
    label: 'Kiawah',
    id: CLIP_ART_IDS.KIAWAH,
    folderPath: 'kiawah',
  },
  [CLIP_ART_IDS.TRUNK_BAY]: {
    label: 'Trunk Bay',
    id: CLIP_ART_IDS.TRUNK_BAY,
    folderPath: 'trunk-bay',
  },
  [CLIP_ART_IDS.PARROT_CAY]: {
    label: 'Parrot Cay',
    id: CLIP_ART_IDS.PARROT_CAY,
    folderPath: 'parrot-cay',
  },
  [CLIP_ART_IDS.EL_NIDO]: {
    label: 'El Nido',
    id: CLIP_ART_IDS.EL_NIDO,
    folderPath: 'el-nido',
  },
  [CLIP_ART_IDS.REETHI_RAH]: {
    label: 'Reethi Rah',
    id: CLIP_ART_IDS.REETHI_RAH,
    folderPath: 'reethi-rah',
  },
  [CLIP_ART_IDS.HORSESHOE_BAY]: {
    label: 'Horseshoe Bay',
    id: CLIP_ART_IDS.HORSESHOE_BAY,
    folderPath: 'horseshoe-bay',
  },
  [CLIP_ART_IDS.MATIRA]: {
    label: 'Matira',
    id: CLIP_ART_IDS.MATIRA,
    folderPath: 'matira',
  },
  [CLIP_ART_IDS.HONOKALANI]: {
    label: 'Honokalani',
    id: CLIP_ART_IDS.HONOKALANI,
    folderPath: 'honokalani',
  },
  [CLIP_ART_IDS.SEVEN_MILE]: {
    label: 'Seven Mile',
    id: CLIP_ART_IDS.SEVEN_MILE,
    folderPath: 'seven-mile',
  },
  [CLIP_ART_IDS.GOUVERNEUR]: {
    label: 'Gouverneur',
    id: CLIP_ART_IDS.GOUVERNEUR,
    folderPath: 'gouverneur',
  },
  [CLIP_ART_IDS.ELAFONISI]: {
    label: 'Elafonisi',
    id: CLIP_ART_IDS.ELAFONISI,
    folderPath: 'elafonisi',
  },
  [CLIP_ART_IDS.ENGLISH_HARBOUR]: {
    label: 'English Harbour',
    id: CLIP_ART_IDS.ENGLISH_HARBOUR,
    folderPath: 'english-harbour',
  },
  [CLIP_ART_IDS.PSAROU]: {
    label: 'Psarou',
    id: CLIP_ART_IDS.PSAROU,
    folderPath: 'psarou',
  },
  [CLIP_ART_IDS.TEMAE_PLAGE_PUBLIQUE]: {
    label: 'Temae plage publique',
    id: CLIP_ART_IDS.TEMAE_PLAGE_PUBLIQUE,
    folderPath: 'temae-plage-publique',
  },
  [CLIP_ART_IDS.HONOPU]: {
    label: 'Honopu',
    id: CLIP_ART_IDS.HONOPU,
    folderPath: 'honopu',
  },
  [CLIP_ART_IDS.SIASCONSET]: {
    label: 'Siasconset',
    id: CLIP_ART_IDS.SIASCONSET,
    folderPath: 'siasconset',
  },
};

const CLIP_ART_COLOR_PALETTE_MAP: Record<COLOUR_PALETTES_IDS, CLIP_ART_IDS> = {
  [COLOUR_PALETTES_IDS.FUJI]: CLIP_ART_IDS.TRUNK_BAY,
  [COLOUR_PALETTES_IDS.KILIMANJARO]: CLIP_ART_IDS.HORSESHOE_BAY,
  [COLOUR_PALETTES_IDS.DENALI]: CLIP_ART_IDS.EL_NIDO,
  [COLOUR_PALETTES_IDS.K2]: CLIP_ART_IDS.MAUNDAYS_BAY,
  [COLOUR_PALETTES_IDS.BLANC]: CLIP_ART_IDS.KIAWAH,
};

const CLIP_ART_PRESET_DESIGN_MAP = {
  [PRESET_DESIGN_IDS.WHITE]: CLIP_ART_IDS.HORSESHOE_BAY,
  [PRESET_DESIGN_IDS.NIGHT_GREY]: CLIP_ART_IDS.REETHI_RAH,
  [PRESET_DESIGN_IDS.SPACE_GREY]: CLIP_ART_IDS.EL_NIDO,
  [PRESET_DESIGN_IDS.HUTT_LAGOON]: CLIP_ART_IDS.ELAFONISI,
  [PRESET_DESIGN_IDS.KOUSSI]: CLIP_ART_IDS.PARROT_CAY,
  [PRESET_DESIGN_IDS.THEL_NOI]: CLIP_ART_IDS.ELAFONISI,
  [PRESET_DESIGN_IDS.TAHOE]: CLIP_ART_IDS.TRUNK_BAY,
  [PRESET_DESIGN_IDS.LAGO_PEHE]: CLIP_ART_IDS.ELAFONISI,
  [PRESET_DESIGN_IDS.RAKOTZSEE]: CLIP_ART_IDS.MAUNDAYS_BAY,
  [PRESET_DESIGN_IDS.SLEEPY_WINDOW]: CLIP_ART_IDS.PARROT_CAY,
};

export function getClipartForColorPalette(colourPaletteId: COLOUR_PALETTES_IDS): CLIP_ART_IDS {
  return CLIP_ART_COLOR_PALETTE_MAP[colourPaletteId];
}

export function getClipartForPresetDesign(presetDesignId: PRESET_DESIGN_IDS): CLIP_ART_IDS {
  return CLIP_ART_PRESET_DESIGN_MAP[presetDesignId];
}

export function getRandomPresetDesignsIdFromClipartMapping(): PRESET_DESIGN_IDS {
  const totalItems = Object.keys(CLIP_ART_PRESET_DESIGN_MAP).length;
  const randomItemIndex = MathUtils.getRandomNumber(0, totalItems - 1);
  return Number(Object.keys(CLIP_ART_PRESET_DESIGN_MAP)[randomItemIndex]);
}

export type ClipArtAssetImageType = {
  imageSrc: string;
  sizeStartValue?: number;
  ratio?: number;
};

export enum CLIP_ART_ITEMS_IDS {
  ITEM_1 = 'item-1',
  ITEM_2 = 'item-2',
  ITEM_3 = 'item-3',
  ITEM_4 = 'item-4',
  ITEM_5 = 'item-5',
  ITEM_6 = 'item-6',
  ITEM_7 = 'item-7',
  ITEM_8 = 'item-8',
  ITEM_9 = 'item-9',
  ITEM_10 = 'item-10',
}

export enum CLIP_ART_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export type ClipArtAssetItemsType = {
  [CLIP_ART_ITEMS_IDS.ITEM_1]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_2]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_3]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_4]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_5]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_6]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_7]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_8]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_9]?: ClipArtAssetImageType;
  [CLIP_ART_ITEMS_IDS.ITEM_10]?: ClipArtAssetImageType;
};

export function getClipartImageUrl(folderPath: string, itemNumber: string) {
  if (folderPath) {
    const imageUrl = `https://asts.premagic.com/s/clip-arts/${folderPath}/${itemNumber}.png`;
    return imageUrl;
  }
  return '';
}

export const CLIP_ART_DEFAULT_SIZES_DETAILS: Record<CLIP_ART_SIZES, { sizeUnit: number }> = {
  [CLIP_ART_SIZES.XS]: {
    sizeUnit: 1,
  },
  [CLIP_ART_SIZES.SM]: {
    sizeUnit: 2,
  },
  [CLIP_ART_SIZES.MD]: {
    sizeUnit: 3,
  },
  [CLIP_ART_SIZES.LG]: {
    sizeUnit: 4,
  },
};

/**
 *  sizeStartValue is the variable which determine the initial size of the item, and then higher size will be multiple of it.
 */
function getHeightForSize(size: CLIP_ART_SIZES, sizeStartValue = 8): number {
  const sizeDetails = CLIP_ART_DEFAULT_SIZES_DETAILS[size];
  return sizeDetails.sizeUnit * sizeStartValue * 4;
}

export function getSizeForClipArt(
  size: CLIP_ART_SIZES,
  item?: { sizeStartValue?: number; ratio?: number },
): { width: number; height: number } {
  if (item?.sizeStartValue) {
    const height = getHeightForSize(size, item.sizeStartValue);
    return {
      height,
      width: MediaUtils.getImageWidthForRatio(height, item.ratio || 1),
    };
  }

  const height = getHeightForSize(size);
  return {
    height,
    width: height,
  };
}

export const cliparItemDetails = {
  [CLIP_ART_IDS.EL_NIDO]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('el-nido', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 15,
    },
  },
  [CLIP_ART_IDS.ELAFONISI]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 20,
      ratio: 155 / 246,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 83 / 456,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 12,
      ratio: 98 / 112,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 117 / 189,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 234 / 377,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 25,
      ratio: 234 / 377,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 25,
      ratio: 234 / 377,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 25,
      ratio: 234 / 377,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 25,
      ratio: 234 / 377,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('elafonisi', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 25,
      ratio: 234 / 377,
    },
  },
  [CLIP_ART_IDS.ENGLISH_HARBOUR]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 8,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 9,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 17,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 21,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('english-harbour', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 15,
    },
  },
  [CLIP_ART_IDS.GOUVERNEUR]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 25,
      ratio: 279 / 294,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('gouverneur', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
  },
  [CLIP_ART_IDS.HONOKALANI]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 20,
      ratio: 393 / 339,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 303 / 423,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('honokalani', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 136 / 278,
    },
  },
  [CLIP_ART_IDS.HONOPU]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 7,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 7,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('honopu', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
    },
  },
  [CLIP_ART_IDS.HORSESHOE_BAY]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('horseshoe-bay', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 1,
    },
  },
  [CLIP_ART_IDS.KIAWAH]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_1),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_3),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_4),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_5),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_6),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_7),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_8),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_9),
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('kiawah', CLIP_ART_ITEMS_IDS.ITEM_10),
    },
  },
  [CLIP_ART_IDS.MATIRA]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 10,
      ratio: 67.168 / 51.777,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 100.516 / 127.619,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('matira', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 1,
    },
  },
  [CLIP_ART_IDS.MAUNDAYS_BAY]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 10,
      ratio: 256 / 158,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 290 / 262,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 176 / 288,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 146 / 244,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 130 / 144,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 130 / 144,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 10,
      ratio: 130 / 144,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 10,
      ratio: 130 / 144,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 10,
      ratio: 130 / 144,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('maundays-bay', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 130 / 144,
    },
  },
  [CLIP_ART_IDS.PARROT_CAY]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_1),
      ratio: 1,
      sizeStartValue: 10,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_2),
      ratio: 1,
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_3),
      ratio: 1,
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_4),
      ratio: 1,
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_5),
      ratio: 1,
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_6),
      ratio: 1,
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_7),
      ratio: 1,
      sizeStartValue: 22,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_8),
      ratio: 1,
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_9),
      ratio: 1,
      sizeStartValue: 6,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('parrot-cay', CLIP_ART_ITEMS_IDS.ITEM_10),
      ratio: 1,
      sizeStartValue: 12,
    },
  },
  [CLIP_ART_IDS.PSAROU]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 13,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 18,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('psarou', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
    },
  },
  [CLIP_ART_IDS.REETHI_RAH]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 8,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 14,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 14,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 13,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 18,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('reethi-rah', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
    },
  },
  [CLIP_ART_IDS.SEVEN_MILE]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 25,
      ratio: 279 / 396,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 192 / 264,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 14,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 13,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 18,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('seven-mile', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 1,
    },
  },
  [CLIP_ART_IDS.TEMAE_PLAGE_PUBLIQUE]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 15,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 7,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('temae-plage-publique', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
    },
  },
  [CLIP_ART_IDS.TRUNK_BAY]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 7,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('trunk-bay', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 1,
    },
  },
  [CLIP_ART_IDS.SIASCONSET]: {
    [CLIP_ART_ITEMS_IDS.ITEM_1]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_1),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_2]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_2),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_3]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_3),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_4]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_4),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_5]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_5),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_6]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_6),
      sizeStartValue: 10,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_7]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_7),
      sizeStartValue: 12,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_8]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_8),
      sizeStartValue: 7,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_9]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_9),
      sizeStartValue: 8,
      ratio: 1,
    },
    [CLIP_ART_ITEMS_IDS.ITEM_10]: {
      imageSrc: getClipartImageUrl('siasconset', CLIP_ART_ITEMS_IDS.ITEM_10),
      sizeStartValue: 10,
      ratio: 1,
    },
  },
};
