import React from 'react';
import { SCROLLABLE_CONTAINER_SIZE, ScrollableContainer } from '@premagic/myne';
import { QRCodeBackgroundPosterService } from '@premagic/core';
import AccountFolderPhotoDropZoneContainer from '../../../../projects/folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderFilesUploadErrorContainer from '../../../../projects/folders/folder-files-upload/FolderFilesUploadErrorContainer';
import FolderUploadProgressContainer from '../../../../projects/folders/folder-files-upload/FolderUploadProgressContainer';
import FolderGalleryWithFileSelectContainer from '../../../../projects/folders/gallery/FolderGalleryWithFileSelectContainer';

type Props = {
  projectId: string;
  folderId: string;
  onSelect: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    logoId1?: string,
    logoId2?: string,
  ) => void;
  logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
  logos?: Array<string | undefined>;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodeLogoFiles(props: Props): JSX.Element | null {
  const { projectId, folderId, onSelect, logoId, logos, qrCodeDesigns } = props;

  function handleOnSelect(selectedFileId) {
    if (logoId === QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_1) {
      onSelect(projectId, folderId, qrCodeDesigns, selectedFileId, logos && logos[1]);
    } else {
      onSelect(projectId, folderId, qrCodeDesigns, logos && logos[0], selectedFileId);
    }
  }

  return (
    <div>
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
      <FolderUploadProgressContainer folderId={folderId} />
      <FolderFilesUploadErrorContainer folderId={folderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <FolderGalleryWithFileSelectContainer projectId={projectId} folderId={folderId} onSelect={handleOnSelect} />
      </ScrollableContainer>
    </div>
  );
}
