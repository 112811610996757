import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';

import { BrowserUrlUtils, BrowserUtils } from '@premagic/utils';

import TemplatePickerContainer from './templates/TemplatePickerContainer';
import { ALBUM_CREATOR_URLS } from './services/AlbumCreatorRouteURLService';

type Props = {
  pageNo: number;
  albumId: string;
  changePage: (pageUrl: string) => void;
};

export default function AlbumCreatorLeftPane(props: Props): JSX.Element {
  const { pageNo, changePage, albumId } = props;

  function functionGetURL(direction: 'previous' | 'next'): string {
    if (!albumId || !pageNo) return '';
    return BrowserUrlUtils.getRouteUrlFor(ALBUM_CREATOR_URLS.ALBUM.EDIT, {
      albumId,
      page: direction === 'previous' ? pageNo - 1 : pageNo + 1,
    });
  }

  function nextPage() {
    changePage(functionGetURL('next'));
  }

  function previousPage() {
    if (pageNo === 1) return;
    changePage(functionGetURL('previous'));
  }

  function focusOnSelectedTemplate() {
    BrowserUtils.getElement('.js-template-button--active-true')?.focus();
  }

  const keyMap = {
    previous: 'left',
    next: 'right',
    FOCUS_ON_TEMPLATES: 't',
  };

  const handlers = {
    previous: previousPage,
    next: nextPage,
    FOCUS_ON_TEMPLATES: focusOnSelectedTemplate,
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <TemplatePickerContainer />
    </>
  );
}
