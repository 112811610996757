import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { eventProjectSelectorSelector } from '../../../projects/AccountProjectsDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureProposalEnabledSelector,
} from '../../../settings/preferences/ClientSettingsDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { clientIdForEventSelector, crmEventItemsSelector } from '../EventsDataDuck';
import EventDetailsLayout from './EventDetailsLayout';
import { projectEventDataSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;

  const clientId = clientIdForEventSelector(eventId)(state);
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project ? project.project_id : 'no-project-id';

  return {
    eventId,
    projectId,
    projectName: project?.project_name,
    clientId,
    focusId,
    isLoading:
      isLoadingSelector(LOADINGS.EVENT.FETCH_SINGLE)(state) || isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    event: crmEventItemsSelector(state)[eventId] || {},
    hasPermissions: {
      [PERMISSIONS.EVENT_QUOTE_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_QUOTE_MANAGEMENT)(state),
      [PERMISSIONS.EVENT_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_MANAGEMENT)(state),
    },
    hasProposalFeature:
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED)(state) ||
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    isFeatureProposalEnabled: isFeatureProposalEnabledSelector(state),
    eventData: projectEventDataSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditEventDetails: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsLayout);
