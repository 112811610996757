import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { History } from 'history';
import APP_URLS from '../services/AppRouteURLService';
import CreateClientWebsitePageContainer from '../components/client-website/create/CreateClientWebsitePageContainer';
import ClientWebsitePageContainer from '../components/client-website/details/ClientWebsitePageContainer';
import ClientWebsiteThemeEditPageContainer from '../components/client-website/edit/ClientWebsiteThemeEditPageContainer';

type Props = {
  history: History;
};

export default function ClientWebsitesRoutes(props: Props) {
  const { history } = props;
  return (
    <Router history={history}>
      <Switch>
        <Route path={APP_URLS.CLIENT_WEBSITE.CREATE} exact component={CreateClientWebsitePageContainer} />
        <Route path={APP_URLS.CLIENT_WEBSITE.EDIT} exact component={ClientWebsiteThemeEditPageContainer} />
        <Route path={APP_URLS.CLIENT_WEBSITE.DETAILS} component={ClientWebsitePageContainer} />
      </Switch>
    </Router>
  );
}
