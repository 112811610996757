import WatchableStore from 'watchable-stores';

const Store = () => {
  const store = WatchableStore({
    action: '',
    message: '',
  });

  ['success', 'info', 'warning', 'error'].forEach((status) => {
    store[status] = (message, timer, classNames) => {
      store.data = {
        status,
        message,
        timer,
        classNames,
      };
    };
  });

  return store;
};

const ToastStore = Store();
export default ToastStore;

const errorMessage = 'Oops... Something went wrong';
export function toastMessage(type: 'success' | 'info' | 'error' | 'warning', message?: string, delay?: number) {
  ToastStore[type](message || errorMessage, delay || 3000);
}
