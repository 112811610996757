import React, { useState } from 'react';

import {
  Text,
  Card,
  Space,
  Form,
  FormGroup,
  TEXT_SIZE,
  COLOR_SHADES,
  TEXT_BOLDNESS,
  ButtonCircle,
  BUTTON_CIRCLE_STYLES,
  BUTTON_CIRCLE_SIZES,
  Icon,
  ICON_SIZES,
  Row,
  TextButton,
  TEXT_BUTTON_STYLES,
  CARD_STYLES,
  CARD_SIZES,
  BUTTON_STYLES,
  Button,
  Col,
  BUTTON_SIZE,
  LoadingDots,
  Tooltip,
  Color,
} from '@premagic/myne';
import WorkflowTaskContainer from './WorkflowTaskContainer';
import { TaskGroupType } from '../service/TaskGroupService';

type Props = {
  workflowId: number;
  taskgroup: TaskGroupType;
  addTask: (workflowId: number, taskgroupId: number, taskName: string) => void;
  isLoading: boolean;
  isCreatingTask: boolean;
};

export default function WorkflowTaskGroup(props: Props): JSX.Element {
  const { taskgroup, addTask, workflowId, isLoading, isCreatingTask } = props;

  const { id: taskGroupId, name: taskGroupName, description: taskGroupDescription, tasks } = taskgroup;

  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Card size={CARD_SIZES.FULL_WIDTH}>
      <Space size={3}>
        <Form
          onSubmit={(data) => {
            setIsEditMode(false);
          }}
        >
          <Row vcenter>
            <Tooltip message="Drag and move to reorder" placement="left">
              <Row>
                <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                  <Icon name="drag" size={ICON_SIZES.SM} align="top" alignSize={1} />
                </Color>
              </Row>
            </Tooltip>
            <Space size={2} />
            <ButtonCircle style={BUTTON_CIRCLE_STYLES.DEFAULT} size={BUTTON_CIRCLE_SIZES.SM} disabled>
              <Icon name="check" size={ICON_SIZES.SM_XS} align="top" alignSize={2} />
            </ButtonCircle>
            <Space size={2} />
            <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
              {taskGroupName}
            </Text>
            {isEditMode && (
              <Col size={null} rightAlighed>
                <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.SM}>
                  Save
                </Button>
              </Col>
            )}
          </Row>
          <Space vertical size={2} />
          <FormGroup>
            <Row vcenter>
              <Space size={9} />
              {/* will add add description later */}
              {/* {isEditMode ? (
                <Col size={6}>
                  <FormGroup>
                    <FormLabel>Description</FormLabel>
                    <Textarea name="taskgroup_description" defaultValue={taskGroupDescription} />
                  </FormGroup>
                </Col>
              ) : (
                <Row vcenter>
                  <Icon name="align_left" size={ICON_SIZES.SM} align="top" alignSize={3} />
                  <TextButton style={TEXT_BUTTON_STYLES.DEFAULT}>Add Description</TextButton>
                </Row>
              )} */}
            </Row>
          </FormGroup>
          {tasks?.map((taskId) => (
            <WorkflowTaskContainer key={taskId.toString()} taskId={taskId} />
          ))}
          <Row>
            <Space size={7} />
            <Card style={CARD_STYLES.SECONDARY} size={CARD_SIZES.FULL_WIDTH}>
              <Space size={2}>
                <Row>
                  <Space size={2} />
                  <TextButton
                    style={TEXT_BUTTON_STYLES.LINK}
                    onClick={() => addTask(workflowId, taskGroupId, `Task ${tasks ? tasks.length + 1 : ''}`)}
                    isLoading={isCreatingTask}
                  >
                    <Row vcenter>
                      <Icon name="plus" size={ICON_SIZES.SM} align="top" alignSize={1} /> Task
                    </Row>
                  </TextButton>
                </Row>
              </Space>
            </Card>
          </Row>
        </Form>
      </Space>
    </Card>
  );
}
