import React from 'react';
import { BUTTON_SIZE, BUTTON_STYLES, Button, Icon, ICON_SIZES, Space } from '@premagic/myne';
import { ProductFeedbackService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../../services/AppRouteURLService';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  eventId: string;
  hasEnabled?: boolean;
};

export default function EventFeedbackButton(props: Props) {
  const { eventId, hasEnabled } = props;

  const eventFeedbackUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__EVENT_FEEDBACK, {
    eventId,
    type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.PRODUCT_USAGE,
  });

  return (
    <Button
      style={BUTTON_STYLES.LINK}
      size={BUTTON_SIZE.MD}
      link={eventFeedbackUrl}
      disabled={!hasEnabled}
      disabledMessage={MESSAGES.EVENT_FEEDBACK.DISABLED}
    >
      <Icon name="rss" size={ICON_SIZES.SM} /> <Space size={1} />
      Event feedbacks
    </Button>
  );
}
