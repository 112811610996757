import { connect } from 'react-redux';
import { hideProjectInPortfolio, portfolioDataSelector, showProjectInPortfolio } from '../portfolio/PortfolioDataDuck';
import { portfolioProjectLimitSelector } from '../settings/company/CompanyDataDuck';
import AccountProjectItemOptionMenu from './AccountProjectItemOptionMenu';
import { projectsSelector } from './AccountProjectsDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId } = ownProps;
  const project = projectsSelector(state)[projectId];

  return {
    projectId,
    project,
    projectsShownInPortfolio: portfolioDataSelector(state),
    portfolioProjectLimit: portfolioProjectLimitSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showProjectInPortfolio: (projectId) => {
      dispatch(showProjectInPortfolio(dispatch, projectId));
    },
    hideProjectInPortfolio: (projectId) => {
      dispatch(hideProjectInPortfolio(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProjectItemOptionMenu);
