import React from 'react';
import { Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Card, COLOR_SHADES, HTML_ICON, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { AccountFinancialsMetricType } from '../AccountFinancialsService';
import AccountCurrencyContainer from '../../acccount/AccountCurrencyContainer';

type AccountFinancialChartProps = {
  metrics: Array<{
    key: keyof AccountFinancialsMetricType;
    label: string;
  }>;
  data: AccountFinancialsMetricType;
};

function AccountFinancialBarChartWith2MetricTooltip(
  props: AccountFinancialChartProps & {
    active?: boolean;
  },
) {
  const { data, metrics, active } = props;
  if (!active) return null;
  const totalMetric = metrics[0];
  const filledValueMetric = metrics[1];
  const total = data[totalMetric.key];
  const filledValue = data[filledValueMetric.key];
  return (
    <Card>
      <Space>
        <Text block>
          {totalMetric.label}:{' '}
          <Text color={COLOR_SHADES.DARKER}>
            <AccountCurrencyContainer amount={total} />
          </Text>
        </Text>
        <Text>
          {filledValueMetric.label}:{' '}
          <Text color={COLOR_SHADES.DARKER}>
            <AccountCurrencyContainer amount={filledValue} />
          </Text>
        </Text>
      </Space>
    </Card>
  );
}

export function AccountFinancialBarChartWith2Metric(props: AccountFinancialChartProps) {
  const { data, metrics } = props;
  const totalMetric = metrics[0];
  const filledValueMetric = metrics[1];

  const total = data[totalMetric.key];
  const filledValue = data[filledValueMetric.key];
  const toFilledValue = total - filledValue;

  return (
    <div>
      <ResponsiveContainer height={36} width="100%">
        <BarChart
          layout="vertical"
          data={[
            {
              name: `${totalMetric.label} :  ${(<AccountCurrencyContainer amount={total} />)}`,
              toFill: toFilledValue,
              filled: filledValue,
            },
          ]}
          stackOffset="expand"
        >
          <XAxis type="number" hide />
          <YAxis type="category" hide dataKey="name" />
          <Tooltip content={<AccountFinancialBarChartWith2MetricTooltip metrics={metrics} data={data} />} />
          <Bar dataKey="filled" name={filledValueMetric.label} fill="#13cf94" stackId="a" />
          <Bar dataKey="toFill" name={totalMetric.label} fill="#8391a1" stackId="a" />
        </BarChart>
      </ResponsiveContainer>
      <Text block>
        <span role="img" aria-label="wave">
          💡
        </span>{' '}
        {totalMetric.label}:{' '}
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          <AccountCurrencyContainer amount={total} />
        </Text>
      </Text>
    </div>
  );
}
const RADIAN = Math.PI / 180;

export function AccountFinancialPieChart(props: AccountFinancialChartProps) {
  const { data, metrics } = props;
  const breakdown1Metric = metrics[1];
  const breakdown2Metric = metrics[2];

  const breakdown1 = data[breakdown1Metric.key];
  const breakdown2 = data[breakdown2Metric.key];
  const chartData = [
    {
      name: breakdown1Metric.label,
      value: breakdown1,
    },
    {
      name: breakdown2Metric.label,
      value: breakdown2,
    },
  ];
  return (
    <ResponsiveContainer height={64} width="100%">
      <PieChart width={200} height={64}>
        <Pie
          isAnimationActive={false}
          cx="50%"
          cy="50%"
          data={chartData}
          dataKey="value"
          outerRadius={32}
          innerRadius={24}
          labelLine={false}
          label={({ cx, cy, midAngle, outerRadius, value, index, fill }) => {
            const radius = outerRadius * 1.2;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
              <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {chartData[index].name} {HTML_ICON.DASH_SMALL} <AccountCurrencyContainer amount={value} compact />
              </text>
            );
          }}
        >
          <Cell fill="#047af0" />
          <Cell fill="#f5ab33" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
