import { EventAttendeesCheckinZoneService, Schemas } from '@premagic/core';
import { ActionTypeUtils, ArrayUtils, ErrorTracker, SimpleDateUtils } from '@premagic/utils';
import { List as IList, Map as IMap } from 'immutable';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../../common/LoadingDuck';
import { pagesSelector } from '../../../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_ATTENDEES_CHECKIN_ZONES');

const setEventAttendeeCheckinZoneData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const addEventAttendeeCheckinZoneData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => data);

const updateEventAttendeeCheckinZoneData = createAction(
  getActionType('DATA', 'UPDATE_SINGLE'),
  (dispatch, id, data) => ({
    [id]: data,
  }),
);

export const removeEventAttendeeCheckinZoneData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

const setEventAttendeeCheckinStatsData = createAction(getActionType('DATA_STATS', 'SET'), (dispatch, data) => data);

export const getEventAttendeesCheckinZones = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesCheckinZoneService.fetchEventAttendeeCheckinZones(projectId)
      .then((response) => {
        if (!isEmpty(response)) {
          const normalizedData = normalize(response, Schemas.EventAttendeeCheckinZones);
          dispatch(setEventAttendeeCheckinZoneData(dispatch, normalizedData.entities.checkin_zones));
        } else {
          dispatch(setEventAttendeeCheckinZoneData(dispatch, {}));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_ATTENDEES_CHECK_ZONES_FETCH_ERROR', e);
      });
  },
);

export const createNewEventAttendeesCheckinZone = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch, projectId: string, data: EventAttendeesCheckinZoneService.NewEventAttendeeCheckinZoneType) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesCheckinZoneService.createEventAttendeeCheckinZone(projectId, data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.EventAttendeeCheckinZone);
        dispatch(addEventAttendeeCheckinZoneData(dispatch, normalizedData.entities.checkin_zones));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_ATTENDEES_CHECK_ZONES_CREATE_ZONE_ERROR', e);
      });
  },
);

export const deleteEventAttendeesCheckinZone = createAction(
  getActionType('DATA', 'DELETE'),
  (dispatch, projectId: string, zoneId: string) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.DELETE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesCheckinZoneService.deleteEventAttendeeCheckinZone(projectId, zoneId)
      .then(() => {
        dispatch(removeEventAttendeeCheckinZoneData(dispatch, zoneId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_ATTENDEES_CHECKIN_ZONES_DELETE_ZONE_ERROR', e);
      });
  },
);

export const getEventAttendeesCheckinStats = createAction(
  getActionType('DATA_STATS', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.STATS_FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventAttendeesCheckinZoneService.fetchEventAttendeeCheckinZonesStats(projectId)
      .then((response) => {
        dispatch(setEventAttendeeCheckinStatsData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_ATTENDEES_CHECKIN_STATS_FETCH_ERROR', e);
      });
  },
);

type EventAttendeeCheckinZoneType = IMap<string, EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneType>;
type EventAttendeeCheckinStatsType =
  IList<EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneStatsItemAccountAppType>;

type StateType = {
  items: EventAttendeeCheckinZoneType;
  checkinStats: EventAttendeeCheckinStatsType;
};
const initialState = {
  items: IMap({}),
  checkinStats: IList(),
};

export default handleActions(
  {
    [setEventAttendeeCheckinZoneData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventAttendeeCheckinZoneData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateEventAttendeeCheckinZoneData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),

    [removeEventAttendeeCheckinZoneData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
    [setEventAttendeeCheckinStatsData.toString()]: (state, action: { payload }) => ({
      ...state,
      checkinStats: IList(action.payload),
    }),
  },
  initialState,
);

const eventAttendeeCheckinZonesSelector = createSelector(
  pagesSelector,
  (pages) => pages.eventAttendees.data.checkinZones as StateType,
);

const eventAttendeeCheckinZonesItemsSelector = createSelector(eventAttendeeCheckinZonesSelector, (data) => data.items);

const eventAttendeeCheckinStatsSelector = createSelector(
  eventAttendeeCheckinZonesSelector,
  (data) => data.checkinStats,
);

export const eventAttendeeCheckinZonesIdsSelector = createSelector(eventAttendeeCheckinZonesItemsSelector, (data) =>
  data.keySeq().toArray(),
);

export const eventAttendeeCheckinZonesIdsSortedByCreatedTimeSelector = createSelector(
  eventAttendeeCheckinZonesItemsSelector,
  (data) =>
    data
      .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
      .keySeq()
      .toArray(),
);

export const eventAttendeeCheckinZonesDataSelector = createSelector(eventAttendeeCheckinZonesItemsSelector, (data) =>
  data.toJSON(),
);

export const eventAttendeeCheckinZonesItemDataSelector = (zoneId: string) =>
  createSelector(eventAttendeeCheckinZonesItemsSelector, (data) => data.get(zoneId));

export const eventAttendeeCheckinStatsArraySelector = createSelector(eventAttendeeCheckinStatsSelector, (data) =>
  data.toArray(),
);
