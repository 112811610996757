import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  GroupedButton,
  GroupedButtons,
  Icon,
  ICON_SIZES,
  InnerPageContent,
  Input,
  INPUT_SIZES,
  Iphone,
  Loading,
  Row,
  Section,
  SimpleImage,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { CompanyService, PremagicResources } from '@premagic/core';
import { SocialNetworksUtils, StringUtils } from '@premagic/utils';

import APP_URLS from '../../../services/AppRouteURLService';
import CompanyLogoUploadContainer from './CompanyLogoUploadContainer';
import { CompanySocialLinkForm } from './social/CompanySocialLinkForm';

const COMPANY_CONTACTS_META = {
  phone: {
    icon: 'phone',
    label: 'PHONE',
  },
  email: {
    icon: 'mail',
    label: 'EMAIL',
  },
  website: {
    icon: 'glob',
    label: 'WEBSITE',
  },
};

type CompanyContactInfoProps = {
  contacts: {
    phone?: string;
    email?: string;
    website?: string;
    alternative_phone?: string;
  };
};

function CompanyContactInfo(props: CompanyContactInfoProps) {
  const { contacts } = props;
  return (
    <div>
      {Object.entries(COMPANY_CONTACTS_META).map(([key, metaData]) => {
        const contactValue = contacts[key];
        return (
          <div key={key}>
            <Row vcenter>
              <Col size={null}>
                <Color shade={COLOR_SHADES.BLUE}>
                  <Icon name={metaData.icon} size={ICON_SIZES.SM} />
                </Color>
                <Space />
              </Col>
              <Col size={9}>
                <Text block size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARK}>
                  {metaData.label}
                </Text>
                <Space vertical size={1} />
                {contactValue ? (
                  <Text block size={TEXT_SIZE.SIZE_5}>
                    {key === 'phone' && contacts.alternative_phone
                      ? `${contactValue}, ${contacts.alternative_phone}`
                      : contactValue}
                  </Text>
                ) : (
                  <Text block size={TEXT_SIZE.SIZE_5} muted>
                    (blank)
                  </Text>
                )}
              </Col>
            </Row>
            <Space vertical size={5} />
          </div>
        );
      })}
    </div>
  );
}

type CompanySocialInfoProps = {
  socialLinks: CompanyService.CompanySocialMediaType;
};

function CompanySocialInfo(props: CompanySocialInfoProps) {
  const { socialLinks } = props;
  const customLinks = Object.entries(socialLinks).filter(([name]) => {
    const META_DATA = SocialNetworksUtils.SOCIAL_MEDIAS_DETAILS[name];
    return !META_DATA;
  });
  return (
    <div>
      <Text block color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
        Follow us on
      </Text>
      <Space vertical />
      <GroupedButtons>
        {Object.entries(SocialNetworksUtils.SOCIAL_MEDIAS_DETAILS).map(([key, metaData]) => {
          const contactValue = socialLinks[key] || '';
          const hasContactValue = contactValue && contactValue.length > 0;

          return (
            <GroupedButton key={key} disabled={!hasContactValue}>
              <SimpleLink
                isExternalLink
                href={`${metaData.url}${
                  key === SocialNetworksUtils.SOCIAL_MEDIAS.YOUTUBE && contactValue[0] !== '@'
                    ? `channel/${contactValue}`
                    : contactValue
                }`}
              >
                <Icon name={metaData.icon} size={ICON_SIZES.MD} />
              </SimpleLink>
            </GroupedButton>
          );
        })}
      </GroupedButtons>
      <Space vertical />
      {customLinks.map(([name, value]) => (
        <div key={name}>
          <a href={value} target="_blank" rel="noopener noreferrer" data-channel={name}>
            {StringUtils.toTitleCase(name)}
          </a>
        </div>
      ))}
    </div>
  );
}

type CompanyPagePreviewProps = {
  company: CompanyService.CompanyType;
};
function CompanyPagePreview(props: CompanyPagePreviewProps) {
  const { company } = props;
  const { name, social = {}, contacts = {}, logo } = company;

  return (
    <Iphone>
      <Space vertical size={35} />
      <Row center>
        <div>
          <Row center>
            {logo ? <SimpleImage src={logo} alt="Logo" style={{ height: '56px' }} /> : <Text>Add company logo</Text>}
          </Row>
          <Space vertical />
          <Text center block size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            {name}
          </Text>
          {contacts.maplocation && contacts.maplocation.length ? (
            <SimpleLink href={contacts.maplocation || ''} isExternalLink>
              <Row center>
                <Icon name="map_pin" size={ICON_SIZES.SM} />
                <Space size={1} />
                {contacts.location}
              </Row>
            </SimpleLink>
          ) : (
            <Text center block size={TEXT_SIZE.SIZE_5}>
              {contacts.location}
            </Text>
          )}
        </div>
      </Row>
      <Space vertical size={6} />
      <Row center>
        <Button style={BUTTON_STYLES.DEFAULT} disabled>
          Enquire
        </Button>
      </Row>
      <Space vertical size={8} />
      <Section>
        <Space vertical size={3} />
        <CompanyContactInfo contacts={contacts} />
        <Space vertical size={10} />
        <CompanySocialInfo
          socialLinks={{
            whatsapp: contacts.phone,
            ...social,
          }}
        />
        <Space vertical size={35} />
      </Section>
    </Iphone>
  );
}

type Props = {
  company: CompanyService.CompanyType;
  isLoading: boolean;
  isSaving: boolean;
  saveCompanyData: (data: Record<string, any>) => void;
  errors: Record<string, string>;
  currentUrlPathname: string;
  isLeadsModuleEnabled: boolean;
};

export default function CompanySettingDetailsPage(props: Props) {
  const { isLoading } = props;
  if (isLoading) {
    return <Loading />;
  }

  const { company, saveCompanyData, isSaving, errors, currentUrlPathname, isLeadsModuleEnabled } = props;
  const { name, social = {}, contacts = {} } = company;

  return (
    <InnerPageContent>
      <Row>
        <Col size={isLeadsModuleEnabled ? 6 : 8}>
          <CompanyLogoUploadContainer />
          <Space vertical />
          <Form onSubmit={saveCompanyData} errors={errors}>
            <Card>
              <Space>
                <FormGroup>
                  <FormLabel>Company Name</FormLabel>
                  <Input readOnly disabled value={name} name="name" />
                  <Text muted size={TEXT_SIZE.SIZE_5}>
                    Contact{' '}
                    <SimpleLink
                      href={`mailto:${PremagicResources.RESOURCES.supportEmail}?subject=Request to change company name`}
                    >
                      {PremagicResources.RESOURCES.supportEmail}
                    </SimpleLink>{' '}
                    to update your company name
                  </Text>
                </FormGroup>
                {!isLeadsModuleEnabled && (
                  <div>
                    <FormGroup>
                      <FormLabel>Company Email</FormLabel>
                      <Input
                        name="contacts[email]"
                        type="email"
                        autoComplete="email"
                        placeholder="eg. hello@premagic.com"
                        defaultValue={contacts.email}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Company Website</FormLabel>
                      <Input
                        type="url"
                        placeholder="eg. https://google.com"
                        name="contacts[website]"
                        autoComplete="website"
                        defaultValue={contacts.website}
                      />
                    </FormGroup>
                    <FormFooter>
                      <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.SETTINGS.INDEX}>
                        Cancel
                      </Button>
                      <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                        Save
                      </Button>
                    </FormFooter>
                  </div>
                )}
                {isLeadsModuleEnabled && (
                  <Row>
                    <Col size={6}>
                      <FormGroup>
                        <FormLabel>Address</FormLabel>
                        <Input
                          placeholder="eg. Bangalore"
                          name="contacts[location]"
                          autoComplete="shipping locality"
                          defaultValue={contacts.location}
                          size={INPUT_SIZES.DEFAULT}
                        />
                      </FormGroup>
                    </Col>
                    <Col size={6}>
                      <Row>
                        <Space size={6} />
                        <Col size={11}>
                          <FormGroup>
                            <FormLabel>Google map link</FormLabel>
                            <Input
                              placeholder="eg. https://goo.gl/maps/dDK4K5VGZBYTgU4j8"
                              name="contacts[maplocation]"
                              defaultValue={contacts.maplocation}
                              size={INPUT_SIZES.DEFAULT}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Space>
            </Card>

            <Space vertical />
            {isLeadsModuleEnabled && (
              <div>
                <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
                  Contacts
                </Text>
                <Card>
                  <Space>
                    <FormGroup>
                      <FormLabel>Phone</FormLabel>
                      <Input name="contacts[phone]" autoComplete="tel" defaultValue={contacts.phone} type="tel" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Alternative Phone</FormLabel>
                      <Input
                        name="contacts[alternative_phone]"
                        autoComplete="tel"
                        type="tel"
                        defaultValue={contacts.alternative_phone}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Email</FormLabel>
                      <Input
                        name="contacts[email]"
                        type="email"
                        autoComplete="email"
                        placeholder="eg. hello@premagic.com"
                        defaultValue={contacts.email}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Website</FormLabel>
                      <Input
                        type="url"
                        placeholder="eg. https://google.com"
                        name="contacts[website]"
                        autoComplete="website"
                        defaultValue={contacts.website}
                      />
                    </FormGroup>
                  </Space>
                </Card>
                <Space vertical />
                <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
                  Social profiles
                </Text>
                <Card>
                  <Space>
                    <CompanySocialLinkForm social={social} />
                  </Space>
                </Card>
                <Space vertical />
                <FormFooter>
                  <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.SETTINGS.INDEX}>
                    Cancel
                  </Button>
                  <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                    Save
                  </Button>
                </FormFooter>
              </div>
            )}
          </Form>
        </Col>
        <Space size={10} />
        {isLeadsModuleEnabled && (
          <Col size={6}>
            <CompanyPagePreview company={company} />
          </Col>
        )}
      </Row>
    </InnerPageContent>
  );
}
