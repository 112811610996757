import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { ProjectService } from '@premagic/core';
import { ProposalDeckDataDuck, ActionConstants } from '@premagic/proposals';

import ProposalCreatorRightSidebar from './ProposalCreatorRightSidebar';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string; deckId: string }) {
  const { projectId = ProjectService.SystemProject.id, folderId, deckId } = ownProps;

  return {
    projectId,
    folderId,
    deckId,
    isLoading: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_DECK.CREATE_VERSION)(state),
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createNewVersion: (projectId, deckId, options) =>
      dispatch(ProposalDeckDataDuck.addNewProposalDeckVersion(dispatch, projectId, deckId, options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorRightSidebar);
