import React from 'react';
import ClassNames from 'classnames';
import { isArray } from 'lodash';
import { ArrayUtils, CurrencyUtils, NumberUtils, SimpleDateUtils } from '@premagic/utils';

import styles from './text.module.css';
import { EscapedParagraphHTMLText, Text, TEXT_BOLDNESS, TEXT_SIZE } from './Text';
import { HTML_ICON, Icon, ICON_SIZES } from '../Icon/Icons';
import { Color, COLOR_SHADES } from '../Color/Color';
import { Row, Space } from '../Grid';
import { BUTTON_ICON_STYLES, BUTTON_ICONS_SIZES, ButtonIcon } from '../Button';
import { SimpleLink } from '../Link/Link';
import { IMAGE_V2_STYLES, ImageV2 } from '../Media';

type TextGroupProps = {
  children: React.ReactNode;
  size?: 'sm' | 'md';
};

export function TextGroup(props: TextGroupProps): JSX.Element {
  const { children, size = 'md' } = props;
  const classes = ClassNames(styles['text-group'], styles[`text-group--size-${size}`], 'js-text-group');
  return (
    <div className={classes}>
      <Row>{children}</Row>
    </div>
  );
}

export enum TEXT_GROUP_SIZES {
  SM = 'sm',
  MD = 'md',
}
export enum TEXT_GROUP_LABEL_STYLES {
  RIGHT_ALIGNED = 'right-aligned',
  LEFT_ALIGNED = 'left-aligned',
}

type TextGroupLabelProps = {
  children: string;
  size?: TEXT_GROUP_SIZES;
  style?: TEXT_GROUP_LABEL_STYLES;
};

export function TextGroupLabel(props: TextGroupLabelProps): JSX.Element {
  const { children, size = TEXT_GROUP_SIZES.MD, style = TEXT_GROUP_LABEL_STYLES.RIGHT_ALIGNED } = props;
  const classes = ClassNames(
    styles['text-group__label'],
    styles[`text-group__label--size-${size}`],
    styles[`text-group__label--style-${style}`],
  );
  return (
    <div className={classes}>
      <Text color={COLOR_SHADES.DARK}>{children}</Text>
    </div>
  );
}

type TextGroupValueColorProps = {
  value: string;
};

export function TextGroupValueColor(props: TextGroupValueColorProps): JSX.Element {
  const { value } = props;
  return <div className={styles['text-group__value--color']} style={{ background: value }} />;
}

type TextGroupValueDateWithTzProps = {
  value: string;
  secondaryValue: string;
};

function TextGroupValueDateWithTz(props: TextGroupValueDateWithTzProps): JSX.Element {
  const { value, secondaryValue } = props;
  return (
    <div>
      {SimpleDateUtils.getDateStringFromDate(value, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_AND_TIME, {
        tz: secondaryValue,
        toUTC: false,
      })}
      <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
        <Space size={1} />
        {HTML_ICON.DOT}
        <Space size={1} />
        {secondaryValue}
      </Text>
    </div>
  );
}
type TextGroupValueLinkProps = {
  value: string;
  secondaryValue?: string;
};

function TextGroupValueLink(props: TextGroupValueLinkProps): JSX.Element {
  const { value, secondaryValue } = props;
  return (
    <SimpleLink href={value} isExternalLink>
      <Text color={COLOR_SHADES.BLUE} block ellipsis>
        {secondaryValue || value}
      </Text>
    </SimpleLink>
  );
}

// Same as INPUT_TYPES, there is no way to assign INPUT_TYPES to TEXT_GROUP_TYPES, so just duplicating it for now.
export enum TEXT_GROUP_TYPES {
  TEXT = 'text',
  NUMBER = 'number',
  DATETIME = 'datetime',
  DATETIME_WITH_TZ = 'datetime-tz',
  DATE = 'date',
  TEXTAREA = 'textarea',
  RICH_TEXTAREA = 'rich-textarea',
  RICH_TEXTAREA_INLINE_SIMPLE = 'rich-textarea-inline-simple',
  FILE = 'file',
  IMAGE = 'image',
  URL = 'url',
  COLOR = 'color',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  TEL = 'tel',
  EMAIL = 'email',
  CUSTOM = 'CUSTOM',
}

const TEXT_GROUP_TYPES_COMPONENTS: Record<TEXT_GROUP_TYPES, any> = {
  [TEXT_GROUP_TYPES.TEXT]: (props) => {
    const { value } = props;
    if (isArray(value)) {
      return ArrayUtils.listify(value);
    }
    return value;
  },
  [TEXT_GROUP_TYPES.DATE]: (props) =>
    SimpleDateUtils.getDateStringFromDate(props.value, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE),
  [TEXT_GROUP_TYPES.DATETIME]: (props) =>
    SimpleDateUtils.getDateStringFromDate(props.value, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_AND_TIME),
  [TEXT_GROUP_TYPES.DATETIME_WITH_TZ]: TextGroupValueDateWithTz,
  [TEXT_GROUP_TYPES.TEXTAREA]: (props) => props.value,
  [TEXT_GROUP_TYPES.FILE]: (props) => props.value,
  [TEXT_GROUP_TYPES.URL]: TextGroupValueLink,
  [TEXT_GROUP_TYPES.EMAIL]: (props: { value: string }) => {
    const { value } = props;
    return (
      <SimpleLink href={`mailto:${value}`} isExternalLink={false}>
        <Text color={COLOR_SHADES.DARKER} block ellipsis>
          {value || '(blank)'}
        </Text>
      </SimpleLink>
    );
  },
  [TEXT_GROUP_TYPES.TEL]: (props: { value: string }) => {
    const { value } = props;
    return (
      <Row vcenter>
        <SimpleLink href={`tel:${value}`} isExternalLink={false}>
          <Text color={COLOR_SHADES.DARKER}>{value}</Text>
        </SimpleLink>
        <Space size={2} />
        <ButtonIcon
          style={BUTTON_ICON_STYLES.SECONDARY}
          size={BUTTON_ICONS_SIZES.SM}
          title="Send Whatsapp message"
          isExternalLink
          link={`https://wa.me/${value}`}
        >
          <Icon name="whatsapp_solid_color" size={ICON_SIZES.SM} />
        </ButtonIcon>
      </Row>
    );
  },
  [TEXT_GROUP_TYPES.COLOR]: TextGroupValueColor,
  [TEXT_GROUP_TYPES.RICH_TEXTAREA]: EscapedParagraphHTMLText,
  [TEXT_GROUP_TYPES.RICH_TEXTAREA_INLINE_SIMPLE]: EscapedParagraphHTMLText,
  [TEXT_GROUP_TYPES.CURRENCY]: (props) => CurrencyUtils.getCurrencyInFormat(props.value, props.secondaryValue, false),
  [TEXT_GROUP_TYPES.PERCENTAGE]: (props) => `${props.value}%`,
  [TEXT_GROUP_TYPES.CUSTOM]: (props) => props.value, // This will never happen
  [TEXT_GROUP_TYPES.NUMBER]: (props) => NumberUtils.getNumberInFormat(props.value),
  [TEXT_GROUP_TYPES.IMAGE]: ({ value }: { value: string }) => (
    <ImageV2 alt="image" src={value} style={IMAGE_V2_STYLES.THUMBNAIL} />
  ),
};

export function getComponentForTextGroupType(type: TEXT_GROUP_TYPES) {
  if (type in TEXT_GROUP_TYPES_COMPONENTS) return TEXT_GROUP_TYPES_COMPONENTS[type];

  return TEXT_GROUP_TYPES_COMPONENTS[TEXT_GROUP_TYPES.TEXT];
}

type TextGroupValueProps = {
  value?: string | number;
  secondaryValue?: string | number;
  type: TEXT_GROUP_TYPES;
  children?: React.ReactNode;
  zeroStateText?: string;
};

export function TextGroupValue(props: TextGroupValueProps): React.ReactElement {
  const { value, secondaryValue, type, children, zeroStateText = '(blank)' } = props;
  const classes = ClassNames(styles['text-group__value']);
  const Component = getComponentForTextGroupType(type);
  const ALLOW_EMPTY_VALUE = [];
  // if (!value && !ALLOW_EMPTY_VALUE.includes(type)) {
  if (!value || value === '<p></p>') {
    return (
      <div className={classes}>
        <Text muted>{zeroStateText}</Text>
      </div>
    );
  }

  if (type === TEXT_GROUP_TYPES.CUSTOM) {
    return (
      <div className={classes}>
        <Text color={COLOR_SHADES.DARKER}>{children}</Text>
      </div>
    );
  }

  return (
    <div className={classes}>
      <Text color={COLOR_SHADES.DARKER}>
        <Component value={value as string} secondaryValue={secondaryValue as string} />
      </Text>
    </div>
  );
}
