import { connect } from 'react-redux';
import ExpensePayments from './ExpensePayments';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import {
  crmExpensePaymentIdsSelector,
  crmExpensePaymentTotalSelector,
  crmExpensePaymentUnSettledTotalSelector,
} from '../CRMPaymentDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state) {
  return {
    expensePaymentIds: crmExpensePaymentIdsSelector(state),
    unSettledTotal: crmExpensePaymentUnSettledTotalSelector(state),
    total: crmExpensePaymentTotalSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_EXPENSE_PAYMENT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpensePayments);
