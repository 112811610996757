import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import ProjectMetaDownloadQualitySettings from './ProjectMetaDownloadQualitySettings';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    projectMeta,
    hasSinglePhotoDownloadQualityPermissions: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMetaDownloadQualitySettings);
