import React from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ClientWishService, FolderMetaService } from '@premagic/core';

const WISH_STATUS_CHANGE_DETAILS: Record<
  ClientWishService.CLIENT_WISH_STATUS,
  {
    message: string;
    actionText: string;
    toggleToStatus?: ClientWishService.CLIENT_WISH_STATUS;
    style: COLOR_SHADES;
  }
> = {
  [ClientWishService.CLIENT_WISH_STATUS.APPROVED]: {
    message: 'Shown in the invitation',
    actionText: 'Dont show',
    toggleToStatus: ClientWishService.CLIENT_WISH_STATUS.REJECT,
    style: COLOR_SHADES.DARK,
  },
  [ClientWishService.CLIENT_WISH_STATUS.REJECT]: {
    message: 'Not shown in the invitation',
    actionText: 'Show',
    toggleToStatus: ClientWishService.CLIENT_WISH_STATUS.APPROVED,
    style: COLOR_SHADES.LIGHT,
  },
  [ClientWishService.CLIENT_WISH_STATUS.DRAFT]: {
    message: 'This will dummy, its not used anywhere',
    actionText: 'blah',
    style: COLOR_SHADES.LIGHT,
  },
  [ClientWishService.CLIENT_WISH_STATUS.REVIEW]: {
    actionText: 'hide-and-show',
    message: 'Show in the invitation?',
    style: COLOR_SHADES.WARNING,
  },
};

type ClientWishApproveActionProps = {
  status: ClientWishService.CLIENT_WISH_STATUS;
  onChange: (newStatus: ClientWishService.CLIENT_WISH_STATUS) => void;
  isSaving: boolean;
};

function ClientWishApproveAction(props: ClientWishApproveActionProps) {
  const { status, onChange, isSaving } = props;
  const { message, style, actionText, toggleToStatus } = WISH_STATUS_CHANGE_DETAILS[status];
  return (
    <div>
      <Text color={style} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5}>
        {message}
      </Text>
      <Space size={2} />
      <Text muted>{HTML_ICON.DOT}</Text>
      <Space size={2} />
      {actionText === 'hide-and-show' ? (
        <span>
          <Button
            isLoading={isSaving}
            onClick={() => onChange(ClientWishService.CLIENT_WISH_STATUS.APPROVED)}
            style={BUTTON_STYLES.LINK}
            size={BUTTON_SIZE.SM}
          >
            Show
          </Button>
          <Space size={2} />
          <Text boldness={TEXT_BOLDNESS.BOLD} muted>
            or
          </Text>
          <Space size={2} />
          <Button
            isLoading={isSaving}
            onClick={() => onChange(ClientWishService.CLIENT_WISH_STATUS.REJECT)}
            style={BUTTON_STYLES.LINK}
            size={BUTTON_SIZE.SM}
          >
            Hide
          </Button>
        </span>
      ) : (
        <Button
          isLoading={isSaving}
          onClick={() => {
            if (toggleToStatus) onChange(toggleToStatus);
          }}
          style={BUTTON_STYLES.LINK}
          size={BUTTON_SIZE.SM}
        >
          {actionText}
        </Button>
      )}
    </div>
  );
}

type Props = {
  clientWish: ClientWishService.ClientWishType;
  projectId: string;
  folderId: string;
  id: string;
  updateWishStatus: (
    options: {
      wishId: string;
      projectId: string;
      folderId: string;
    },
    status: ClientWishService.CLIENT_WISH_STATUS,
  ) => void;
  isSaving: boolean;
  deleteWish: (options: { wishId: string; projectId: string; folderId: string }) => void;
  wishesReviewRequired?: boolean;
};

export default function ClientWishApproval(props: Props): JSX.Element {
  const { clientWish, id, folderId, projectId, updateWishStatus, isSaving, deleteWish, wishesReviewRequired } = props;
  const { status } = clientWish;

  function handleStatusChange(newStatus: ClientWishService.CLIENT_WISH_STATUS) {
    updateWishStatus(
      {
        wishId: id,
        folderId,
        projectId,
      },
      newStatus,
    );
  }

  return (
    <Row vcenter>
      <Space />
      <Col>
        {wishesReviewRequired && (
          <ClientWishApproveAction onChange={handleStatusChange} status={status} isSaving={isSaving} />
        )}
      </Col>
      <Col rightAlighed size={null}>
        <ButtonIcon title="Delete" onClick={() => deleteWish({ wishId: id, folderId, projectId })}>
          <Icon name="trash" size={ICON_SIZES.SM} />
        </ButtonIcon>
      </Col>
    </Row>
  );
}
