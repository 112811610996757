import React, { useEffect } from 'react';

import { FolderService, SponsorCreativesService } from '@premagic/core';
import {
  BUTTON_STYLES,
  Button,
  COLOR_SHADES,
  Col,
  ICON_SIZES,
  Icon,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  Row,
  Space,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';

import MESSAGES from '../../../../../../common/Messages';
import SponsorFilesModalContainer from '../../../projects/sponsor/sponsor-files/SponsorFilesModalContainer';
import SponsorFilesSelectFileForCreativeModalContainer from '../../../projects/sponsor/sponsor-files/SponsorFilesSelectFileForCreativeModalContainer';
import ProjectWatermarkSettingCardContainer from '../../../watermark/ProjectWatermarkSettingCardContainer';
import { PERMISSIONS } from '../../users/UserPermissionService';
import SponsorCreativesListContainer from '../event-sponsor-creatives/SponsorCreativesListContainer';
import AddSponsorCreativePanelContainer from '../event-sponsor-creatives/create/AddSponsorCreativePanelContainer';
import EditSponsorCreativePanelContainer from '../event-sponsor-creatives/edit/EditSponsorCreativePanelContainer';
import AddSponsorWatermarkPanelContainer from '../event-sponsor-creatives/event-sponsor-watermark/side-panel/AddSponsorWatermarkPanelContainer';
import EditSponsorWatermarkPanelContainer from '../event-sponsor-creatives/event-sponsor-watermark/side-panel/EditSponsorWatermarkPanelContainer';
import SponsorCreativesWatermarksListContainer from '../event-sponsor-creatives/event-sponsor-watermark/watermark-accordion-list/SponsorCreativesWatermarksListContainer';
import SponsorReportSummaryCardContainer from '../sponsors-report/sponsor-report-card/SponsorReportSummaryCardContainer';
import EventSponsorsListContainer from './EventSponsorsCategoryListContainer';
import AddEventSponsorPanelContainer from './create/AddEventSponsorPanelContainer';
import EditEventSponsorPanelContainer from './edit/EditEventSponsorPanelContainer';

type Props = {
  eventId: string;
  projectId?: string;
  folderId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_SPONSOR]: boolean;
    [PERMISSIONS.SPONSOR_CREATIVE]: boolean;
    [PERMISSIONS.EVENT_SPONSOR_REPORT]: boolean;
  };
  hasEventSponsorshipFeatureEnabled: boolean;
  fetchFolderFiles: (projectId: string, folderId: string) => void;
  createFolder: (projectId: string) => void;
  isCreatingBrandingFolder: boolean;
  showAllSponsorFiles: () => void;
  projectFolderTypesLinks?: {
    [FolderService.FOLDER_TYPES.HIGHLIGHT]?: string;
    [FolderService.FOLDER_TYPES.CLIENT_WEBSITE]?: string;
    [FolderService.FOLDER_TYPES.SELECTION]?: string;
  };
  getProjectSponsorsStatsData: (projectId: string) => void;
  fetchEventSponsorCategoryData: () => void;
  allSponsorCategoryIds: Array<string>;
};

export default function EventSponsorLayout(props: Props): React.ReactElement {
  const {
    eventId,
    projectId,
    folderId,
    hasPermissions,
    hasEventSponsorshipFeatureEnabled,
    fetchFolderFiles,
    createFolder,
    isCreatingBrandingFolder,
    showAllSponsorFiles,
    projectFolderTypesLinks,
    getProjectSponsorsStatsData,
    fetchEventSponsorCategoryData,
    allSponsorCategoryIds,
  } = props;

  useEffect(() => {
    if (!hasEventSponsorshipFeatureEnabled) return;
    if (projectId && folderId) fetchFolderFiles(projectId, folderId);
    if (projectId) getProjectSponsorsStatsData(projectId);
    if (!allSponsorCategoryIds || allSponsorCategoryIds?.length === 0) {
      fetchEventSponsorCategoryData();
    }
  }, [
    fetchFolderFiles,
    projectId,
    folderId,
    hasEventSponsorshipFeatureEnabled,
    getProjectSponsorsStatsData,
    fetchEventSponsorCategoryData,
    allSponsorCategoryIds,
  ]);

  if (!projectId)
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text>Convert to Project for adding sponsors</Text>
        </Space>
      </InnerPageContentMainCol>
    );

  if (!hasEventSponsorshipFeatureEnabled)
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text>{MESSAGES.EVENT_SPONSORSHIP.UPGRADE}</Text>
        </Space>
      </InnerPageContentMainCol>
    );

  if (!folderId) {
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARK} block>
            {MESSAGES.EVENT_SPONSORSHIP.ENABLE}
          </Text>
          <Space vertical />
          <Button
            onClick={() => createFolder(projectId)}
            isLoading={isCreatingBrandingFolder}
            style={BUTTON_STYLES.PRIMARY}
          >
            Enable Sponsorship
          </Button>
        </Space>
      </InnerPageContentMainCol>
    );
  }

  return (
    <>
      <InnerPageContentMainCol>
        {hasPermissions[PERMISSIONS.EVENT_SPONSOR] && (
          <div>
            <EventSponsorsListContainer eventId={eventId} projectId={projectId} />
            <Space vertical size={12} />
            {/* <SponsorCreativesListContainer */}
            {/*  projectId={projectId} */}
            {/*  placement={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.HEADER} */}
            {/* /> */}
            <SponsorCreativesListContainer
              projectId={projectId}
              placement={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.IN_GALLERY}
            />
            {/* <SponsorCreativesListContainer */}
            {/*  projectId={projectId} */}
            {/*  placement={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.IN_STREAM} */}
            {/* /> */}
            <SponsorCreativesListContainer
              projectId={projectId}
              placement={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.FOOTER}
            />

            <SponsorCreativesWatermarksListContainer
              projectId={projectId}
              eventId={eventId}
              sponsorFolderId={folderId}
            />
            <ProjectWatermarkSettingCardContainer projectId={projectId} eventId={eventId} />
            <Space vertical size={6} />
            <Row>
              <Col size={null} rightAlighed>
                <Button style={BUTTON_STYLES.LINK} onClick={showAllSponsorFiles}>
                  Show all sponsor files
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <AddEventSponsorPanelContainer projectId={projectId} />
        <EditEventSponsorPanelContainer projectId={projectId} />
        <AddSponsorCreativePanelContainer projectId={projectId} />
        <AddSponsorWatermarkPanelContainer projectId={projectId} />
        <EditSponsorWatermarkPanelContainer projectId={projectId} />
        <EditSponsorCreativePanelContainer projectId={projectId} />
        <SponsorFilesSelectFileForCreativeModalContainer projectId={projectId} />
        <SponsorFilesModalContainer projectId={projectId} />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <Space vertical size={2} />
        <Row>
          <Col size={null} rightAlighed>
            <Button
              link={projectFolderTypesLinks?.[FolderService.FOLDER_TYPES.HIGHLIGHT]}
              style={BUTTON_STYLES.LINK}
              disabled={!projectFolderTypesLinks?.[FolderService.FOLDER_TYPES.HIGHLIGHT]}
              isExternalLink
              disabledMessage={MESSAGES.SIGNATURE_FOLDER.FOLDER_NOT_SHARED}
            >
              <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} /> Open Album
            </Button>
          </Col>
        </Row>
        <Space vertical size={5} />
        <SponsorReportSummaryCardContainer projectId={projectId} eventId={eventId} type="compact" />
      </InnerPageContentSidebar>
    </>
  );
}
