import { ClientWebsiteFormService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormErrorType,
  FormFooter,
  RealCheckbox,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import React from 'react';
import MESSAGES from '../../../../../../../common/Messages';
import EventAttendeeFormContainer from './EventAttendeeFormContainer';

type Props = {
  addEventAttendee: (
    projectId: string,
    formResponse: {
      data: Record<string, string | File>;
      errors?: FormErrorType;
    },
  ) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
  attendeeRegistrationSendNotificationRequired?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
};

export default function AddEventAttendeePanel(props: Props) {
  const { show, close, projectId, addEventAttendee, isLoading, error, attendeeRegistrationSendNotificationRequired } =
    props;
  const [selectedFile, setSelectedFile] = React.useState<File>();

  const isEmailRequired = attendeeRegistrationSendNotificationRequired
    ? [
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL,
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      ].includes(attendeeRegistrationSendNotificationRequired)
    : true;

  const isPhoneRequired = attendeeRegistrationSendNotificationRequired
    ? [
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      ].includes(attendeeRegistrationSendNotificationRequired)
    : true;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add {MESSAGES.EVENT_ATTENDEES.NAME}</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          formSpec={{
            permission_to_save_photo: {
              type: 'boolean',
            },
            should_send_welcome_notification: {
              type: 'boolean',
            },
            have_consent_for_networking: {
              type: 'boolean',
            },
            ...(isPhoneRequired ? { phone: { validator: 'required' } } : {}),
            ...(isEmailRequired ? { email: { validator: 'required' } } : {}),
          }}
          onSubmit={(response) => {
            addEventAttendee(projectId, {
              data: {
                ...response.data,
                selfie: selectedFile as File,
              },
              errors: response.errors,
            });
          }}
        >
          <EventAttendeeFormContainer onChangeOfFile={(file) => setSelectedFile(file as File)} projectId={projectId} />

          <RealCheckbox name="should_send_welcome_notification" defaultChecked={false}>
            Notify user about the registration
          </RealCheckbox>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
