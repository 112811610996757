import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { companyDataSelector, saveCompanyData } from './CompanyDataDuck';
import CompanySettingsCustomPolicy from './CompanySettingsCustomPolicy';
import { clientSettingsDataSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const clientSettings = clientSettingsDataSelector(state);
  return {
    customPolicy: clientSettings?.custom_policy,
    isSaving: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCompanyData: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettingsCustomPolicy);
