import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export function fetchProjectsShownInPortfolio(): Promise<Array<string>> {
  return HttpUtils.get(API_URLS.PORTFOLIO.ROOT)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function showProjectInPortfolio(projectId: string): Promise<{ success: boolean }> {
  return HttpUtils.put(API_URLS.PORTFOLIO.SHOW_IN_PORTFOLIO(projectId))
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function hideProjectInPortfolio(projectId: string): Promise<{ success: boolean }> {
  return HttpUtils.httpDelete(API_URLS.PORTFOLIO.SHOW_IN_PORTFOLIO(projectId))
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}
