import { StorePricingService } from '@premagic/core';
import { Modal, MODAL_SIZES } from '@premagic/myne';
import React from 'react';
import PhotoDownloadSettingsContainer from './product-settings/photo-download/PhotoDownloadSettingsContainer';

const PRODUCT_ITEM_MAP = {
  [StorePricingService.STORE_PRODUCT_IDS.PHOTO_DOWNLOAD]: PhotoDownloadSettingsContainer,
};

type StorePricingModalProps = {
  show?: boolean;
  selectedProduct?: StorePricingService.ProductItemType | null;
  onSave: (data) => void;
  onClose: () => void;
  defaultSubProductPrices?: any;
};

export default function StorePricingModal(props: StorePricingModalProps) {
  const { show, selectedProduct, onSave, onClose, defaultSubProductPrices } = props;

  if (!selectedProduct) return null;

  const Component = PRODUCT_ITEM_MAP[selectedProduct.id];

  return (
    <Modal show={Boolean(show)} close={() => onClose()} size={MODAL_SIZES.XL}>
      <Component
        product={selectedProduct}
        onSave={onSave}
        onClose={onClose}
        defaultSubProductPrices={defaultSubProductPrices}
      />
    </Modal>
  );
}
