import React, { useEffect, useMemo } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  ErrorBoundary,
  EscapedParagraphHTMLText,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  PreventMarginCollapse,
  Row,
  Slider,
  SLIDER_STYLES,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  TimeAgo,
} from '@premagic/myne';
import { ArrayUtils, useElementSize } from '@premagic/utils';
import { EventTrackerService } from '@premagic/core';

import { StoreOfferItemType } from '../StoreOffersService';
import APP_URLS from '../../../services/AppRouteURLService';

function OfferImages(props: { images: string[] }) {
  const { images } = props;
  const [$ref, size] = useElementSize();

  const items = useMemo(
    () =>
      images.map((item) => (
        <ImageV2
          key={item}
          cssStyle={{
            height: 350,
          }}
          style={IMAGE_V2_STYLES.FULL_IMAGE}
          alt="offer"
          width={size.width}
          src={item}
        />
      )),
    [images, size.width],
  );

  return (
    <PreventMarginCollapse>
      <div
        ref={$ref}
        style={{
          height: 350,
        }}
      >
        <Slider style={SLIDER_STYLES.STRIP_SLIDER}>{items}</Slider>
      </div>
    </PreventMarginCollapse>
  );
}

type Props = {
  id: string;
  storeOffer: StoreOfferItemType;
  initData: (id) => void;
  isLoading: boolean;
};

export default function StoreOfferItemDetailsPage(props: Props): JSX.Element {
  const { id, storeOffer, isLoading, initData } = props;

  useEffect(() => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.STORE.VIEW_ITEM, { id });
    initData(id);
  }, [id, initData]);

  if (isLoading) {
    return (
      <InnerPage>
        <InnerPageHeader
          iconName="shopping_cart"
          title="Offers"
          backTo={APP_URLS.STORE.INDEX}
          backToTitle="Back to Offers"
        >
          Offers
        </InnerPageHeader>
        <InnerPageContent isLoading={isLoading}>
          <div />
        </InnerPageContent>
      </InnerPage>
    );
  }

  const { title, images, expiry_date: expiryDate, tags, vendor, description, cta_url: link } = storeOffer;
  const tagsItems = tags?.map((tag) => (
    <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT} key={tag}>
      {tag}
    </Text>
  ));

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="shopping_cart"
        title="Offers"
        backTo={APP_URLS.STORE.INDEX}
        backToTitle="Back to Offers"
      >
        Offers
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <ErrorBoundary>
          <Card overflowHidden>
            <Space size={4}>
              <Row vcenter>
                <Col>
                  <Text boldness={TEXT_BOLDNESS.BOLD} block size={TEXT_SIZE.SIZE_3}>
                    {vendor}
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Button
                    link={link}
                    style={BUTTON_STYLES.PRIMARY}
                    isExternalLink
                    onClick={() => {
                      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.STORE.CTA_CLICK, {
                        title,
                        id,
                        vendor,
                      });
                    }}
                  >
                    <Icon name="external_link" size={ICON_SIZES.SM} />
                    <Space size={1} />
                    Avail Offer
                  </Button>
                </Col>
              </Row>
              <Space vertical />
              <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block size={TEXT_SIZE.SIZE_1}>
                {title}
              </Text>
              {ArrayUtils.intersperse(tagsItems, ', ')}
              <Space vertical />
            </Space>

            <OfferImages images={images} />
            <Space size={4}>
              <Space vertical />
              <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3}>
                <EscapedParagraphHTMLText value={description} />
              </Text>

              <Space vertical size={12} />
              <Text color={COLOR_SHADES.DARKER}>
                Expires on <TimeAgo date={expiryDate} />
              </Text>
            </Space>
          </Card>
        </ErrorBoundary>
      </InnerPageContent>
    </InnerPage>
  );
}
