import React from 'react';
import {
  Col,
  COLOR_SHADES,
  Row,
  Space,
  StatusLabel,
  STATUS_LABEL_STYLES,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
} from '@premagic/myne';

type Props = {
  isLoading: boolean;
  isAccountSupportAccessEnabled: boolean;
  savePreferences: (data: any) => void;
};

export default function AccountSupportAccessToggle(props: Props) {
  const { isLoading, isAccountSupportAccessEnabled, savePreferences } = props;
  return (
    <Row>
      <Col size={12}>
        <Row>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Grant Premagic support access to your account information</Text>
        </Row>
        <Text size={TEXT_SIZE.SIZE_4} muted>
          This will help engineers to debug the issue you are having and fix the issues faster for your account
        </Text>
        <Row vcenter>
          {isAccountSupportAccessEnabled ? (
            <StatusLabel style={STATUS_LABEL_STYLES.WARNING}>Permission granted</StatusLabel>
          ) : (
            <Space vertical size={3} />
          )}
        </Row>
      </Col>
      <Space size={8} />
      <Col size={null} rightAlighed>
        <Row vcenter columnDirection>
          <Space vertical size={1} />
          <ToggleSwitch
            isLoading={isLoading}
            name="support_access_to_account"
            checked={isAccountSupportAccessEnabled}
            onChange={(value) => {
              savePreferences({ data: { support_access_to_account: value } });
            }}
          />
          <Space size={1} vertical />
          {isAccountSupportAccessEnabled ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
              Yes
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
              No
            </Text>
          )}
        </Row>
      </Col>
    </Row>
  );
}
