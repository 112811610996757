import { AppServices } from '@premagic/core';
import { HttpUtils } from '@premagic/utils';

export type CustomerClientType = {
  name: string;
  email: string;
  phone_number: string;
  created_at: string;
};

export function fetchCustomerClients() {
  return HttpUtils.get(`${AppServices.getAPIUrl()}/api/customer/clients/`);
}
