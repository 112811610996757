import { connect } from 'react-redux';
import ExpensePaymentForm from './ExpensePaymentForm';
import { crmPaymentsSelector } from '../CRMPaymentDataDuck';
import { paymentIdForEditSelector } from '../../events/details/EventDetailsPageDuck';

function mapStateToProps(state) {
  const paymentId = paymentIdForEditSelector(state);

  return {
    paymentId,
    payment: paymentId ? crmPaymentsSelector(state)[paymentId] : undefined,
  };
}

export default connect(mapStateToProps)(ExpensePaymentForm);
