import { LoadingDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';

import { LOADINGS } from '../../../../../../../common/Constants';
import { projectMetaDataForProjectSelector } from '../../../../projects/project-meta/ProjectsMetaDataDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { hasPermission } from '../../../users/UsersDataDuck';
import { eventAttendeesSelector, updateEventAttendeeApprovalStatus } from '../EventAttendeesDataDuck';
import AttendeeRegistrationApprovalButtons from './AttendeeRegistrationApprovalButtons';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    id,
    attendee: eventAttendeesSelector(state)[id],
    projectId,
    hasPermissionsToManageAttendees: hasPermission(PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT)(state),
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_ATTENDEES.UPDATE_STATUS(id))(state),
    hasEnabledApprovalWorkflow:
      !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (options, status) => {
      dispatch(updateEventAttendeeApprovalStatus(dispatch, options, status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeRegistrationApprovalButtons);
