import {
  BUTTON_SIZE,
  DatePicker,
  Dialog,
  DIALOG_PLACEMENT,
  FormLabel,
  Space,
  TextButton,
  TEXT_BUTTON_STYLES,
  ErrorBoundary,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import React, { useRef, useState } from 'react';

type Props = {
  id: string;
  value: string;
  onChange: (number) => void;
};

export default function DueDateSelector(props: Props): React.ReactElement {
  const { id, value, onChange } = props;
  const [showDialog, setShowDialog] = useState(false);
  const dueDateString = value && SimpleDateUtils.fromNowDate(value, false);
  const $button = useRef(null);

  return (
    <ErrorBoundary>
      <TextButton
        style={TEXT_BUTTON_STYLES.SECONDARY}
        onClick={() => setShowDialog(!showDialog)}
        size={BUTTON_SIZE.LG}
        ref={$button}
      >
        {dueDateString}
      </TextButton>

      <Dialog
        target={$button.current}
        show={showDialog}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => setShowDialog(false)}
      >
        <Space size={2}>
          <FormLabel>Due Date</FormLabel>
          <DatePicker
            name={`payments[${id}][due_date]`}
            defaultValue={value}
            autoFocus
            onChange={(targetValue) => {
              onChange(targetValue);
              setShowDialog(false);
            }}
          />
        </Space>
      </Dialog>
    </ErrorBoundary>
  );
}
