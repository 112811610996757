import { EventCustomFromFieldsService } from '@premagic/core';
import { Button, BUTTON_SIZE, FormErrorType, FormResponseType, Space, SubTitle } from '@premagic/myne';
import React from 'react';

type Props = {
  addFormField: (projectId: string, formResponse: FormResponseType) => void;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
  formFields: Array<EventCustomFromFieldsService.FormFieldType>;
};

export default function AddEventFormFieldPanel(props: Props) {
  const { projectId, addFormField, isLoading, error, formFields } = props;
  const systemFormFieldsThatCanBeAdded = (
    Object.entries(EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS_DETAILS) as Array<
      [EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS, EventCustomFromFieldsService.FormFieldType]
    >
  )
    .filter(([key, value]) => !EventCustomFromFieldsService.INTERNAL_SYSTEM_CUSTOM_FORM_FIELDS.includes(key))
    .filter(([key, value]) => !formFields.find((field) => field.name === key));

  if (!systemFormFieldsThatCanBeAdded.length) return null;
  return (
    <div>
      <SubTitle>Quick add</SubTitle>
      <Space size={2} vertical />
      {systemFormFieldsThatCanBeAdded.map(([key, value]) => (
        <Button
          key={key}
          isLoading={isLoading}
          size={BUTTON_SIZE.SM}
          onClick={() =>
            addFormField(projectId, {
              data: {
                ...value,
                visibility: true,
                position: formFields.length + 1,
              },
            })
          }
        >
          {value.label}
        </Button>
      ))}
    </div>
  );
}
