import { ErrorDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { createNewEventAttendeesCheckinZone } from '../EventAttendeesCheckinZonesDataDuck';
import EventAttendeeFaceCheckinAddZonePanel from './EventAttendeeFaceCheckinAddZonePanel';

const PANEL_KEY = LOADINGS.EVENT_ATTENDEE_CHECKIN_ZONE.CREATE;
const COMMON_KEY = LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.ADD;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    error: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state) as Record<string, string>,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
    createNewZone: (projectId, data) => {
      dispatch(createNewEventAttendeesCheckinZone(dispatch, projectId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceCheckinAddZonePanel);
