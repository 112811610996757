import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';
import EventPosterPreviewLayout from './EventPosterPreviewLayout';
import { addSponsorOrBrandingFolder } from '../projects/sponsor/SponsorAndBrandingDuck';
import { folderIdsWithFolderTypeForProjectSelector } from '../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../common/Constants';

function mapStateToProps(state, ownProps: { projectId: string; posterId?: string }) {
  const { projectId, posterId } = ownProps;
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);
  return {
    projectId,
    posterId,
    brandingFolderId: brandingFolderIds[0],
    isCreatingBrandingFolder: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createBrandingFolder: (projectId) => {
      dispatch(addSponsorOrBrandingFolder(dispatch, projectId, FolderService.FOLDER_TYPES.BRANDING));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterPreviewLayout);
