import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import BrandingDetailsPage from './BrandingDetailsPage';
import { hasFeatureSelector } from '../../company/CompanyDataDuck';
import { fetchSystemFolder } from '../../../projects/sponsor/SponsorAndBrandingDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  const { folderId } = ownProps.match.params;

  return {
    folderId,
    currentUrlPathname: pathname,
    hasWatermarkFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.FETCH_ALL, true)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (folderId) => dispatch(fetchSystemFolder(dispatch, folderId, FolderService.FOLDER_TYPES.BRANDING)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingDetailsPage);
