import { connect } from 'react-redux';
import EventSponsorItem from './EventSponsorItem';
import { eventSponsorsSelector } from './EventSponsorDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { setEditOfSponsor } from './EventSponsorPageDuck';
import { sponsorsCategoryDataSelector } from './sponsor-category/data-duck/SponsorCategoryDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;

  return {
    id,
    sponsor: eventSponsorsSelector(state)[id],
    projectId,
    hasPermissions: {
      [PERMISSIONS.EVENT_SPONSOR]: hasPermission(PERMISSIONS.EVENT_SPONSOR)(state),
      [PERMISSIONS.EVENT_SPONSOR_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_SPONSOR_MANAGEMENT)(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditSponsor: (id: string) => {
      dispatch(setEditOfSponsor(dispatch, id));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorItem);
