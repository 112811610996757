import { connect } from 'react-redux';
import { ProposalDeckDataDuck } from '@premagic/proposals';

import EventProposalForm from './EventProposalForm';

function mapStateToProps(
  state,
  ownProps: {
    deckId?: string;
  },
) {
  const { deckId } = ownProps;

  return {
    deck: deckId ? ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId] : undefined,
  };
}

export default connect(mapStateToProps)(EventProposalForm);
