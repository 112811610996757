const URL = 'https://premagic.freshdesk.com/support/solutions/articles/';

function u(article: string) {
  return `${URL}${article}`;
}

export enum ARTICLES {
  PHOTO_SELECTION,
  FLASH_ALBUM,
  ARCHIVE_PROJECT,
  DUPLICATE_PROJECT,
  CUSTOM_DOMAIN,
  SELECTION_BUDGET,
  SELECTION_IMPORTANCE,
  DIGITAL_INVITE_EVENT_REGISTRATION,
  AI_DELIVERY,
}

const ARTICLE_DETAILS: Record<ARTICLES, { url: string; id: number }> = {
  [ARTICLES.PHOTO_SELECTION]: {
    url: u('44001262772-photo-selection-'),
    id: 44001262772,
  },
  [ARTICLES.FLASH_ALBUM]: {
    url: u('44001262820-flash-albums'),
    id: 44001262820,
  },
  [ARTICLES.ARCHIVE_PROJECT]: {
    url: u('44001602912-archiving-project'),
    id: 44001262772,
  },
  [ARTICLES.DUPLICATE_PROJECT]: {
    url: u('44001870046-duplicate-project'),
    id: 44001870046,
  },
  [ARTICLES.CUSTOM_DOMAIN]: {
    url: u('44002282511-custom-domain'),
    id: 44002282511,
  },
  [ARTICLES.SELECTION_BUDGET]: {
    url: u('44002369296-suggest-number-of-photos-to-select'),
    id: 44002369296,
  },
  [ARTICLES.SELECTION_IMPORTANCE]: {
    url: u('44002369294-photo-importance-on-selected-photos'),
    id: 44002369294,
  },
  [ARTICLES.DIGITAL_INVITE_EVENT_REGISTRATION]: {
    url: u('44002371413-event-registration-with-ai-photo-delivery'),
    id: 44002371413,
  },
  [ARTICLES.AI_DELIVERY]: {
    url: u('44002416262-ai-photo-delivery'),
    id: 44002416262,
  },
};

export function getArticleUrl(articleId: ARTICLES): string {
  return ARTICLE_DETAILS[articleId].url;
}

export function getArticleId(articleId: ARTICLES): number {
  return ARTICLE_DETAILS[articleId].id;
}
