import { connect } from 'react-redux';
import CRMOccasionForm from './CRMOccasionForm';
import { getOccasionsForUsers, occasionItemsSelector } from '../OccasionsDataDuck';

function mapStateToProps(state, ownProps: { id?: string; eventId: string }) {
  const { id, eventId } = ownProps;

  return {
    eventId,
    occasion: id ? occasionItemsSelector(state)[id] : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOccasionsForUsers: (eventId, users, dates) => {
      dispatch(getOccasionsForUsers(dispatch, eventId, users, dates));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CRMOccasionForm);
