import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';
import { connect } from 'react-redux';

import ProposalRenameDialog from './ProposalRenameDialog';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../common/ModalDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';

const MODAL_KEY = ActionConstants.KEYS.PROPOSAL.SHOW_EDIT_NAME;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  const COMMON_KEY = ActionConstants.KEYS.PROPOSAL.UPDATE(folderId);

  return {
    isRenaming: isLoadingSelector(COMMON_KEY)(state),
    projectId,
    folderId,
    folder: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
    isRenameFolderShown: isModalOpenSelector(MODAL_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, MODAL_KEY, show));
    },
    renameFolder: (projectId: string, folderId: string, data) => {
      dispatch(ProposalDataDuck.renameProposal(dispatch, projectId, folderId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalRenameDialog);
