import React, { useRef, useState } from 'react';
import { useUserMedia } from '@premagic/utils';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  COLOR_SHADES,
  Flash,
  FormGroup,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'user', width: { ideal: 300 }, height: { ideal: 300 } },
};

type Props = {
  onCapture: (file: File) => void;
};

export default function AttendeePictureCapture(props: Props): React.ReactElement {
  const { onCapture } = props;
  const $canvas = useRef<HTMLCanvasElement>(null);
  const $video = useRef<HTMLVideoElement>(null);
  const $container = useRef<HTMLDivElement>(null);

  const [container, setContainer] = useState({ width: 300, height: 350 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);

  const [mediaStream, videoDevices, error] = useUserMedia(CAPTURE_OPTIONS);

  if (mediaStream && $video.current && !$video.current.srcObject) {
    $video.current.srcObject = mediaStream;
  }

  function handleCanPlay() {
    setContainer({
      height: $video?.current?.videoHeight || 300,
      width: $video?.current?.videoWidth || 300,
    });
    setIsVideoPlaying(true);
    $video?.current?.play();
  }

  function handleCapture() {
    if (!$canvas.current || !$video.current) return;
    const context = $canvas.current.getContext('2d');
    if (!context) return;

    context.drawImage($video.current, 0, 0, container.width, container.height);

    $canvas.current.toBlob(
      (blob) => {
        if (blob) {
          const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
          setTimeout(() => onCapture(file), 500); // Creating a delay for the user to see the flash
        }
      },
      'image/jpeg',
      1,
    );

    setCaptured(true);
    setIsVideoPlaying(false);
    setIsFlashing(true);
  }

  if (error) {
    return (
      <Card style={CARD_STYLES.PRIMARY} center>
        <Space>
          <Text size={TEXT_SIZE.SIZE_4} block center>
            <span role="img" aria-label="sad">
              😟
            </span>
          </Text>
          <Space vertical size={2} />
          <Text size={TEXT_SIZE.SIZE_6} color={COLOR_SHADES.WARNING} boldness={TEXT_BOLDNESS.BOLD} block center>
            You have Denied Permission!
          </Text>
          <Space vertical />
          <Text size={TEXT_SIZE.SIZE_4} color={COLOR_SHADES.LIGHT} block center>
            Please allow camera access to take a selfie and try again.
          </Text>
          <Space vertical />
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DARKER}>
            For iOS:
          </Text>
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
            <Space vertical size={1} />
            1. Open Settings {HTML_ICON.ARROW_RIGHT} Find your browser in the list.
            <Space vertical size={1} />
            2. Scroll down to Camera {HTML_ICON.ARROW_RIGHT} Select Allow.
          </Text>
          <Space vertical size={2} />
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DARKER}>
            For Android:
          </Text>
          <Space vertical size={1} />
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
            1. In your open browser, go to Settings {HTML_ICON.ARROW_RIGHT} Scroll down to Site Settings.
            <Space vertical size={1} />
            2. Tap Camera, expand the blocked list, {HTML_ICON.ARROW_RIGHT} Select the site and choose Allow.
          </Text>
          <Space vertical size={6} />
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
            Once done, refresh the page and try again.
          </Text>
        </Space>
      </Card>
    );
  }

  return (
    <div>
      <FormGroup>
        <Space vertical />
        <Row center>
          <div
            ref={$container}
            style={{
              width: container.width,
              height: container.height,
              transform: 'scaleX(-1)',
              borderRadius: '12px',
              overflow: 'hidden',
            }}
          >
            <canvas ref={$canvas} hidden={!captured} width={container.width} height={container.height} />

            {!isVideoPlaying && !captured && (
              <Row center fullHeight>
                <LoadingDots />
              </Row>
            )}
            <video
              ref={$video}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              width={container.width}
              height={container.height}
            />
            <Flash flash={isFlashing} onAnimationEnd={() => setIsFlashing(false)} />
          </div>
        </Row>
      </FormGroup>
      <Row center>
        <Button style={BUTTON_STYLES.PRIMARY} type="button" onClick={handleCapture} disabled={!isVideoPlaying}>
          <Icon name="camera" size={ICON_SIZES.SM} />
          <Space size={2} />
          Take Photo
        </Button>
      </Row>
    </div>
  );
}
