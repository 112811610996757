import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';

import { getStoreOfferData, storeOffersItemSelector } from '../StoreOffersDataDuck';
import StoreOfferItemDetailsPage from './StoreOfferItemDetailsPage';
import { LOADINGS } from '../../../../../common/Constants';

function mapStateToProps(
  state,
  ownProps: {
    match: {
      params: {
        id: string;
      };
    };
  },
) {
  const { id } = ownProps.match.params;
  const storeOffers = storeOffersItemSelector(state);
  return {
    id,
    storeOffer: storeOffers[id],
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.STORE.FETCH, true)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => dispatch(getStoreOfferData(dispatch)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreOfferItemDetailsPage);
