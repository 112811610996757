import { connect } from 'react-redux';
import QRCodePosterAllModals from './QRCodePosterAllModals';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string; showDownloadInfoModal?: boolean }) {
  const { projectId, folderId, showDownloadInfoModal } = ownProps;
  return { projectId, folderId, showDownloadInfoModal };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterAllModals);
