import { connect } from 'react-redux';

import { clientItemForEventSelector } from '../../../events/EventsDataDuck';
import ClientPaymentsInvoicePageHeader from './ClientPaymentsInvoicePageHeader';
import { companyDataSelector } from '../../../../settings/company/CompanyDataDuck';
import { accountSelector } from '../../../../acccount/AccountDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;
  return {
    eventId,
    client: clientItemForEventSelector(eventId)(state),
    company: companyDataSelector(state),
    account: accountSelector(state),
  };
}

export default connect(mapStateToProps)(ClientPaymentsInvoicePageHeader);
