import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Dialog,
  EscapedParagraphHTMLText,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import PremiumFeatureIcon from '../../../settings/PremiumFeatureIcon';

export default function ProjectMetaFeatureLabel(props: {
  itemKey: ProjectMetaService.PROJECT_META_TOGGLE_ITEMS;
  primaryStyle?: boolean;
  showPremiumFeatureButton?: boolean;
}) {
  const { itemKey, primaryStyle, showPremiumFeatureButton } = props;
  const { label, icon, info } = ProjectMetaService.PROJECT_META_TOGGLE_ITEMS_DETAILS[itemKey];
  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef(null);
  return (
    <div>
      <Button
        size={BUTTON_SIZE.SM}
        style={BUTTON_STYLES.RESET}
        onClick={() => setShowDialog(!showDialog)}
        onMouseEnter={() => setShowDialog(true)}
        onMouseLeave={() => setShowDialog(false)}
        ref={$button}
      >
        <Row vcenter>
          {icon && (
            <Color shade={primaryStyle ? COLOR_SHADES.YELLOW : COLOR_SHADES.LIGHT} inline fillSolidIcon={primaryStyle}>
              <Icon name={icon} size={ICON_SIZES.SM} /> <Space size={1} />
            </Color>
          )}
          <Text boldness={TEXT_BOLDNESS.BOLD}>{label}</Text>
          {showPremiumFeatureButton && (
            <>
              <Space size={1} />
              <PremiumFeatureIcon module={label} />
            </>
          )}
        </Row>
        {info && (
          <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
            <Row vcenter>
              {icon && (
                <Color shade={COLOR_SHADES.LIGHT} inline fillSolidIcon={primaryStyle}>
                  <Icon name={icon} size={ICON_SIZES.SM} /> <Space size={1} />
                </Color>
              )}
              <Text boldness={TEXT_BOLDNESS.BOLD}>{label}</Text>
            </Row>
            <Space size={2} vertical />
            <Text>
              <EscapedParagraphHTMLText value={info} />
            </Text>
          </Dialog>
        )}
      </Button>
    </div>
  );
}
