import React from 'react';
import { Button, BUTTON_STYLES, Card, CardContent, Form, FormErrorType, Space } from '@premagic/myne';

import { ProjectMetaService } from '@premagic/core';
import SystemFolderBrandingWatermarkFormContainer from '../../../watermark/SystemFolderBrandingWatermarkFormContainer';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  folderId: string;
  isSaving: boolean;
  update: (folderId: string, data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasWatermarkFeature: boolean;
};

export default function BrandingWatermarkDetails(props: Props) {
  const { folderId, update, isSaving, errors, hasWatermarkFeature } = props;

  return (
    <Card>
      <Space>
        <CardContent>
          <Form
            onSubmit={(response) => update(folderId, response.data)}
            errors={errors}
            formSpec={{
              [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_ENABLE_WATERMARK]: {
                type: 'boolean',
              },
              [ProjectMetaService.PROJECT_META_TYPES.SELECTION_ENABLE_WATERMARK]: {
                type: 'boolean',
              },
              [ProjectMetaService.PROJECT_META_TYPES.WATERMARK_OPACITY]: {
                type: 'number',
              },
              [ProjectMetaService.PROJECT_META_TYPES.WATERMARK_SIZE]: {
                type: 'number',
              },
            }}
          >
            {/* <SystemFolderBrandingWatermarkFormContainer folderId={folderId} /> */}
            <Space vertical size={2} />
            <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.SETTINGS.BRANDING.HOME}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving} disabled={!hasWatermarkFeature}>
              Save
            </Button>
          </Form>
        </CardContent>
      </Space>
    </Card>
  );
}
