import {
  Template1,
  Template10,
  Template11,
  Template12,
  Template13,
  Template14,
  Template15,
  Template16,
  Template17,
  Template18,
  Template19,
  Template2,
  Template20,
  Template21,
  Template22,
  Template3,
  Template4,
  Template5,
  Template6,
  Template7,
  Template8,
  Template9,
} from './AlbumTemplates';

export enum ALBUM_TEMPLATE_IDS {
  ITEM_1 = 1,
  ITEM_2,
  ITEM_3,
  ITEM_4,
  ITEM_5,
  ITEM_6,
  ITEM_7,
  ITEM_8,
  ITEM_9,
  ITEM_10,
  ITEM_11,
  ITEM_12,
  ITEM_13,
  ITEM_14,
  ITEM_15,
  ITEM_16,
  ITEM_17,
  ITEM_18,
  ITEM_19,
  ITEM_20,
  ITEM_21,
  ITEM_22,
}

export const ALBUM_TEMPLATES: Record<
  ALBUM_TEMPLATE_IDS,
  { id: ALBUM_TEMPLATE_IDS; name: string; numberOfPhotos: number; component: (props) => JSX.Element; iconPath: string }
> = {
  [ALBUM_TEMPLATE_IDS.ITEM_1]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_1,
    name: 'Template 1',
    numberOfPhotos: 4,
    component: Template1,
    iconPath: 'M42 2h16v17H42V2zM29 2h11v36H29V2zM42 21h16v17H42V21zM2 2h25v36H2V2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_2]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_2,
    name: 'Template 2',
    numberOfPhotos: 3,
    component: Template2,
    iconPath: 'M2 2h17v36H2zM21 2h18v36H21zM41 2h17v36H41z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_3]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_3,
    name: 'Template 3',
    numberOfPhotos: 1,
    component: Template3,
    iconPath: 'M2 2h56v36H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_4]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_4,
    name: 'Template 4',
    numberOfPhotos: 2,
    component: Template4,
    iconPath: 'M2 2h27v36H2zM31 2h27v36H31z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_5]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_5,
    name: 'Template 5',
    numberOfPhotos: 4,
    component: Template5,
    iconPath: 'M2 2h27v17H2zM2 21h13v17H2zM17 21h12v17H17zM31 2h27v36H31z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_6]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_6,
    name: 'Template 6',
    numberOfPhotos: 4,
    component: Template6,
    iconPath: 'M2 2h27v17H2zM31 2h27v17H31zM31 21h27v17H31zM2 21h27v17H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_7]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_7,
    name: 'Template 7',
    numberOfPhotos: 5,
    component: Template7,
    iconPath: 'M2 16h27v22H2zM2 2h13v12H2zM17 2h12v12H17zM31 2h27v16H31zM31 20h27v18H31z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_8]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_8,
    name: 'Template 8',
    numberOfPhotos: 3,
    component: Template8,
    iconPath: 'M2 2h16v17H2zM2 21h16v17H2zM20 2h38v36H20z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_9]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_9,
    name: 'Template 9',
    numberOfPhotos: 3,
    component: Template9,
    iconPath: 'M42 2h16v17H42zM42 21h16v17H42zM2 2h38v36H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_10]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_10,
    name: 'Template 10',
    numberOfPhotos: 6,
    component: Template10,
    iconPath: 'M2 22h13v16H2zM17 22h12v16H17zM31 22h13v16H31zM46 22h12v16H46zM2 2h27v18H2zM31 2h27v18H31z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_11]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_11,
    name: 'Template 11',
    numberOfPhotos: 9,
    component: Template11,
    iconPath:
      'M2 25h13v13H2zM17 25h12v13H17zM31 25h13v13H31zM31 2h13v10H31zM46 2h12v10H46zM31 14h13v9H31zM46 14h12v9H46zM46 25h12v13H46zM2 2h27v21H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_12]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_12,
    name: 'Template 12',
    numberOfPhotos: 12,
    component: Template12,
    iconPath:
      'M2 2h13v11H2zM2 15h13v11H2zM2 28h13v10H2zM17 2h12v11H17zM17 15h12v11H17zM17 28h12v10H17zM31 2h12v11H31zM31 15h12v11H31zM31 28h12v10H31zM45 2h13v11H45zM45 15h13v11H45zM45 28h13v10H45z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_13]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_13,
    name: 'Template 13',
    numberOfPhotos: 2,
    component: Template13,
    iconPath: 'M2 2h20v36H2zM24 2h34v36H24z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_14]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_14,
    name: 'Template 14',
    numberOfPhotos: 2,
    component: Template14,
    iconPath: 'M38 2h20v36H38zM2 2h34v36H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_15]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_15,
    name: 'Template 15',
    numberOfPhotos: 5,
    component: Template15,
    iconPath: 'M23 2h15v36H23zM2 2h19v17H2zM2 21h19v17H2zM40 21h19v17H40zM40 2h19v17H40z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_16]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_16,
    name: 'Template 16',
    numberOfPhotos: 6,
    component: Template16,
    iconPath: 'M2 2h13v12H2zM17 2h14v12H17zM2 16h29v22H2zM33 28h25v10H33zM33 2h25v12H33zM33 16h25v10H33z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_17]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_17,
    name: 'Template 17',
    numberOfPhotos: 6,
    component: Template17,
    iconPath: 'M2 2h17v10H2zM21 2h18v10H21zM41 2h17v10H41zM41 14h17v11H41zM41 27h17v11H41zM2 14h37v24H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_18]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_18,
    name: 'Template 18',
    numberOfPhotos: 4,
    component: Template18,
    iconPath: 'M30 2h28v10H30zM30 14h28v11H30zM30 27h28v11H30zM2 2h26v36H2z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_19]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_19,
    name: 'Template 19',
    numberOfPhotos: 5,
    component: Template19,
    iconPath: 'M2 2h15v17H2zM2 21h15v17H2zM19 21h16v17H19zM19 2h16v17H19zM37 2h21v36H37z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_20]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_20,
    name: 'Template 20',
    numberOfPhotos: 10,
    component: Template20,
    iconPath:
      'M2 2h13v11H2zM2 15h13v11H2zM2 28h13v10H2zM17 2h12v11H17zM17 15h12v11H17zM17 28h12v10H17zM31 2h12v17H31zM31 21h12v17H31zM45 2h13v17H45zM45 21h13v17H45z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_21]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_21,
    name: 'Template 21',
    numberOfPhotos: 7,
    component: Template21,
    iconPath: 'M2 2h14v11H2zM2 15h14v11H2zM2 28h14v10H2zM18 2h14v11H18zM18 15h14v11H18zM18 28h14v10H18zM34 2h24v36H34z',
  },
  [ALBUM_TEMPLATE_IDS.ITEM_22]: {
    id: ALBUM_TEMPLATE_IDS.ITEM_22,
    name: 'Template 22',
    numberOfPhotos: 6,
    component: Template22,
    iconPath: 'M2 2h13v11H2zM2 15h13v11H2zM2 28h13v10H2zM17 2h21v17H17zM17 21h21v17H17zM40 2h18v36H40z',
  },
};

export function getNumberOfPhotosForTemplate(templateId: number): number {
  return ALBUM_TEMPLATES[templateId].numberOfPhotos;
}
