import { FormGroup, FormLabel, Select } from '@premagic/myne';
import React, { useEffect, useMemo } from 'react';

type Props = {
  projectId: string;
  values: string[];
  allTags: Array<string>;
  getDAMFilterLabels: (projectId: string) => void;
  isLoading: boolean;
};

export default function ImageTagsSelect(props: Props) {
  const { projectId, values, allTags, getDAMFilterLabels, isLoading } = props;
  useEffect(() => {
    if (projectId && allTags.length === 0) {
      getDAMFilterLabels(projectId);
    }
  }, [allTags.length, getDAMFilterLabels, projectId]);

  const tagsOptions = useMemo(
    () =>
      allTags.map((item) => ({
        value: item,
        label: item,
      })),
    [allTags],
  );

  const tagSelectedValues = useMemo(() => {
    if (!values) return [];
    return values?.map((item) => tagsOptions.find((tag) => tag.value === item)).filter(Boolean) as Array<{
      value: string;
      label: string;
    }>;
  }, [values, tagsOptions]);

  return (
    <FormGroup>
      <FormLabel>Tags</FormLabel>
      <Select
        isMulti
        isCreatable
        isSearchable
        name="tags"
        value={tagSelectedValues}
        options={tagsOptions}
        isLoading={isLoading}
      />
    </FormGroup>
  );
}
