import { connect } from 'react-redux';
import { UploaderDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  fetchFolder,
  filesSizeInfoInFolderSelector,
  foldersEntitiesSelector,
} from '../../folders/AccountFoldersDataDuck';
import HighlightFolderDetails from './HighlightFolderDetails';
import { isFolderBlockedSelector, isFolderPublicSelector } from '../../folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId, folderId } = ownProps;

  return {
    folder: foldersEntitiesSelector(state)[folderId] || {},
    filesInfo: filesSizeInfoInFolderSelector(folderId)(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER.FETCH)(state),
    projectId,
    folderId,
    isFolderBlocked: isFolderBlockedSelector(folderId)(state),
    isImageCompressorReady: UploaderDuck.isImageCompressorReady(state),
    isFolderPublic: isFolderPublicSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId: string, folderId: string) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
    setIsImageCompressorReady: (data: boolean) => {
      dispatch(UploaderDuck.setIsImageCompressorReady(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightFolderDetails);
