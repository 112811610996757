import { connect } from 'react-redux';
import ProjectStatsForNerdCard from './ProjectStatsForNerdCard';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  return {
    projectId,
    isStaffUser: isStaffUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatsForNerdCard);
