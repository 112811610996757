import { connect } from 'react-redux';
import { SponsorCreativesService } from '@premagic/core';
import SponsorCreativeForm from './SponsorCreativeForm';
import { sponsorCreativesSelector } from '../SponsorCreativeDataDuck';
import {
  eventSponsorsArraySelector,
  sponsorCreativesStatsForPlacementGroupedBySponsorSelector,
} from '../../event-sponsors/EventSponsorDataDuck';
import { sponsorsCategoryDataSelector } from '../../event-sponsors/sponsor-category/data-duck/SponsorCategoryDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    creativeId?: string;
    placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  },
) {
  const { projectId, creativeId, placement } = ownProps;

  return {
    projectId,
    placement,
    sponsors: eventSponsorsArraySelector(state),
    creative: creativeId ? sponsorCreativesSelector(state)[creativeId] : undefined,
    sponsorsCategoryData: sponsorsCategoryDataSelector(state),
    creativesStatsForPlacementGroupedBySponsor: placement
      ? sponsorCreativesStatsForPlacementGroupedBySponsorSelector(placement)(state)
      : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCreativeForm);
