import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  COLOR_SHADES,
  Form,
  FormFooter,
  FormResponseType,
  List,
  ListItem,
  LoadingDots,
  RealCheckbox,
  Row,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { ClientSettingsService, EventsCategoryService, EventsService } from '@premagic/core';
import EventCategoryIcon from '../../crm/events/category/EventCategoryIcon';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: FormResponseType) => void;
  errors: Record<string, string>;
};

export default function AccountServicesOfferedSettings(props: Props) {
  const { isLoading, clientSettings, isSaving, savePreferences, errors } = props;
  const { services_offered: servicesOffered } = clientSettings;
  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <Form onSubmit={savePreferences} errors={errors}>
      <Card>
        <CardHeader hasPadding={false}>
          <Space>
            <Text block color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_1}>
              What type of services do you offer?
            </Text>
            <Text muted>Only selected service will be shown in the enquiry form</Text>
          </Space>
        </CardHeader>
        <List>
          {Object.entries(EventsCategoryService.EVENT_CATEGORY_DETAILS)
            .filter(([id]) => id !== EventsService.EVENT_CATEGORY.CUSTOM)
            .map(([id, option]) => (
              <ListItem key={id}>
                <RealCheckbox
                  name="services_offered"
                  id={id}
                  value={id}
                  defaultChecked={
                    servicesOffered ? servicesOffered?.includes(id as EventsService.EVENT_CATEGORY) : true
                  }
                >
                  <Row vcenter>
                    <EventCategoryIcon eventName={id} />
                    <Space size={2} />
                    <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
                      {option.title}
                    </Text>
                  </Row>
                </RealCheckbox>
              </ListItem>
            ))}
        </List>
      </Card>
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.SETTINGS.INDEX}>
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
          Save
        </Button>
      </FormFooter>
    </Form>
  );
}
