import { APIURLService, FileService } from '@premagic/core';
import { HttpUtils } from '@premagic/utils';
import { DECK_SLIDE_DATA_TYPES, DECK_SLIDE_LAYOUTS, DeckSlideDataType } from '@premagic/myne';

const URL = APIURLService.API_URLS.PROPOSAL_DECK_SLIDE;

export type ProposalSlideType = {
  id: number;
  template_id: DECK_SLIDE_LAYOUTS;
  structure: DeckSlideDataType;
};

export function fetchProposalSlide(projectId: string, deckId: string): Promise<Array<ProposalSlideType>> {
  return HttpUtils.get(URL.LIST(projectId, deckId))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function createProposalSlide(
  projectId: string,
  deckId: string,
  data: Partial<ProposalSlideType>,
): Promise<ProposalSlideType> {
  return HttpUtils.post(URL.LIST(projectId, deckId), data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function updateProposalSlide(
  options: {
    projectId: string;
    deckId: string;
    id: number;
  },
  data: Partial<ProposalSlideType>,
): Promise<ProposalSlideType> {
  const { projectId, deckId, id } = options;

  return HttpUtils.put(URL.DETAILS(projectId, deckId, id), data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function deleteProposalSlide(options: { projectId: string; deckId: string; id: string }): Promise<void> {
  const { projectId, deckId, id } = options;

  return HttpUtils.httpDelete(URL.DETAILS(projectId, deckId, id))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function getDeckSlideDataTypeForFile(
  file: FileService.FolderFileType,
): DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO | DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE {
  if (file.asset_type === FileService.ASSET_TYPES.VIDEO) return DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO;
  return DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE;
}

export function getVariablesInTheSlide(slide: ProposalSlideType): Array<string> {
  const regex = /data-variable-atom-id="(\S+)"/gim;

  // Variables in large text
  const largeText = slide.structure[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?.value || '';
  const matches = largeText.matchAll(regex);
  const variablesInLargeText = [...matches].map((match) => match[1]);

  // Variables in invoice layout
  const invoiceItems = slide.structure[DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS]?.value || [];
  const variablesInInvoiceItems = invoiceItems.map((item) => item.id);

  return [...variablesInLargeText, ...variablesInInvoiceItems];
}
