import html2canvas from 'html2canvas';
import { downloadAsFile } from './BrowserUtils';

export function domToCanvas(
  $dom: HTMLElement,
  options?: {
    width?: number;
    height?: number;
    scale?: number;
  },
): Promise<HTMLCanvasElement> {
  return html2canvas($dom, {
    allowTaint: false,
    useCORS: true,
    logging: true,
    ...options,
  });
}

function calculateImageSizeForPdf(
  containerWidth: number,
  containerHeight: number,
  imageWidth: number,
  imageHeight: number,
): { width: number; height: number } {
  const widthRatio = containerWidth / imageWidth;
  const heightRatio = containerHeight / imageHeight;

  const minRatio = Math.min(widthRatio, heightRatio);

  const newWidth = imageWidth * minRatio;
  const newHeight = imageHeight * minRatio;

  return { width: newWidth, height: newHeight };
}

export function getImageDataUrlForElement(
  $element: HTMLElement,
  scale,
): Promise<{ dataUrl: string; width: number; height: number }> {
  return domToCanvas($element, {
    scale,
  }).then((canvas) => {
    const dataUrl = canvas.toDataURL('image/png');
    const { width, height } = canvas.style;

    return { dataUrl, width: parseInt(width, 10), height: parseInt(height, 10) };
  });
}

export async function downloadElementsAsPdf(
  $elements: NodeListOf<HTMLElement> | Array<HTMLElement>,
  pdfName: string,
  scale: number | Array<number> = 4, // array of scale for each corresponding element, or a single scale for all element (scale sets the quality of the image)
  pageSizeA4 = true, // if this value is false then the size of the pdf page will be the size of the first element
  onDownloadComplete?: () => void,
): Promise<void> {
  // dynamically load the jspdf module
  import('jspdf').then(({ default: JsPDF }) => {
    const allBadgeImagesPromises: Array<Promise<{ dataUrl: string; width: number; height: number }>> = [];

    $elements.forEach((item, idx) => {
      const scaleToUse = Array.isArray(scale) ? scale[idx] : scale;
      allBadgeImagesPromises.push(getImageDataUrlForElement(item, scaleToUse));
    });

    return Promise.all(allBadgeImagesPromises).then((imageSources) => {
      const doc = pageSizeA4
        ? new JsPDF('p', 'mm', 'a4')
        : new JsPDF('p', 'px', [imageSources[0].width, imageSources[0].height]);

      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();

      imageSources.forEach((item, idx) => {
        const { dataUrl, width, height } = item;

        if (idx !== 0) {
          // Add a new page for each image except the first one
          doc.addPage();
        }
        const size = calculateImageSizeForPdf(componentWidth, componentHeight, width, height);
        doc.addImage(dataUrl, 'PNG', 0, 0, size.width, size.height);
      });

      doc.save(`${pdfName}.pdf`);
      if (onDownloadComplete) {
        onDownloadComplete();
      }
    });
  });
}

export function downloadAsImage($element: HTMLElement, name: string, scale: number): Promise<void> {
  return domToCanvas($element, {
    scale,
  }).then((canvas) => {
    const dataUrl = canvas.toDataURL('image/png');
    downloadAsFile({ file: dataUrl, fileName: name });
  });
}

export function downloadElementsAsImages(
  $elements: NodeListOf<HTMLElement> | Array<HTMLElement>,
  names: Array<string>, // array of names for each corresponding element,
  scale: number | Array<number> = 4, // array of scale for each corresponding element,
  onDownloadComplete?: () => void,
): Promise<void> {
  const downloadPromises: Array<Promise<void>> = [];
  $elements.forEach((el, idx) => {
    const fileName = names[idx];
    const scaleToUse = Array.isArray(scale) ? scale[idx] : scale;
    downloadPromises.push(downloadAsImage(el, fileName, scaleToUse));
  });

  return Promise.all(downloadPromises).then(() => {
    if (onDownloadComplete) {
      onDownloadComplete();
    }
  });
}

export function dataURItoBlob(dataURI: string): Blob {
  const parts = dataURI.split(',');
  const mime = parts[0].split(':')[1].split(';')[0];
  const byteCharacters = parts[0].indexOf('base64') >= 0 ? atob(parts[1]) : decodeURIComponent(parts[1]);
  const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
}
