import { schema } from 'normalizr';

export const FileSchema = new schema.Entity(
  'images',
  {},
  {
    idAttribute: (value, parent, key) => key,
  },
);
export const FilesSchema = new schema.Values(FileSchema);
export const ProjectOrderSchema = new schema.Entity('projectOrders', {}, { idAttribute: 'order_id' });
export const ProjectOrdersSchema = [ProjectOrderSchema];

export const ClientWebsiteFileSchema = new schema.Entity(
  'files',
  {},
  {
    idAttribute: (value, parent, key) => key,
  },
);
export const ClientWebsiteFilesSchema = new schema.Values(ClientWebsiteFileSchema);

export const ClientWebsiteItinerarySchema = new schema.Entity('itineraries', {});
export const ClientWebsiteItinerariesSchema = [ClientWebsiteItinerarySchema];
export const ClientWebsiteSchema = new schema.Entity(
  'clientWebsite',
  {
    files: ClientWebsiteFilesSchema,
    orders: ProjectOrdersSchema,
    data: {
      itineraries: ClientWebsiteItinerariesSchema,
    },
  },
  {
    idAttribute: (value, parent) => parent.folder_id,
  },
);
export const ClientWebsitesSchema = [ClientWebsiteSchema];

export const ClientWishSchema = new schema.Entity('clientWish', {});
export const ClientWishesSchema = [ClientWishSchema];

export const FolderSchema = new schema.Entity(
  'folders',
  { files: FilesSchema, website: ClientWebsiteSchema },
  { idAttribute: 'folder_id' },
);

export const FoldersSchema = [FolderSchema];

export const ProjectSchema = new schema.Entity('projects', { folders: FoldersSchema }, { idAttribute: 'project_id' });
export const ProjectsSchema = [ProjectSchema];

export const PeopleFaceSchema = new schema.Entity('faces', {}, { idAttribute: 'face_id' });

export const PeopleFacesSchema = [PeopleFaceSchema];

export const PeopleNetworkingFacesSchema = new schema.Entity(
  'people_networking_file_faces',
  {
    networking_faces: PeopleFacesSchema,
  },
  { idAttribute: 'file_id' },
);

export const PeopleNetworkingSchema = new schema.Entity('people', {}, { idAttribute: 'face_id' });
export const PeopleNetworkingListSchema = [PeopleNetworkingSchema];

export const ClientSchema = new schema.Entity('clients');

export const PricingPlanSchema = new schema.Entity('pricingPlans', {});

export const PricingPlansSchema = [PricingPlanSchema];

export const AlbumPageSchema = new schema.Entity('pages', {}, { idAttribute: 'position' });

export const AlbumSchema = new schema.Entity('albums', {
  pages: [AlbumPageSchema],
});

export const AlbumsSchema = [AlbumSchema];

export const PhotographerActionSchema = new schema.Entity(
  'actions',
  {},
  {
    idAttribute: 'id',
  },
);

export const PhotographerActionsSchema = [PhotographerActionSchema];

export const ClientProjectSchema = new schema.Entity(
  'client_projects',
  { folders: FoldersSchema },
  { idAttribute: 'project_id' },
);
export const ClientProjectsSchema = [ProjectSchema];
export const ClientAppPhotographersSchema = [
  new schema.Entity(
    'photographers',
    {
      projects: ClientProjectsSchema,
    },
    { idAttribute: 'customer_id' },
  ),
];

// Proposals
export const ProposalVariableSchema = new schema.Entity('variables', {});
export const ProposalVariablesSchema = [ProposalVariableSchema];
export const ProposalSlideSchema = new schema.Entity('slides', {});
export const ProposalSlidesSchema = [ProposalSlideSchema];

export const ProposalDeckSchema = new schema.Entity('decks', {
  slides: ProposalSlidesSchema,
});
export const ProposalDecksSchema = [ProposalDeckSchema];

// Store offers
export const StoreOfferSchema = new schema.Entity('storeOffers', {});
export const StoreOfferssSchema = [StoreOfferSchema];

// Event Sponsors
export const SponsorCreative = new schema.Entity('creatives', {});

export const EventSponsor = new schema.Entity('sponsors', {
  creatives: [SponsorCreative],
});

export const EventSponsors = [EventSponsor];

//  Event Exhibitor
export const EventExhibitor = new schema.Entity('exhibitors', {});
export const EventExhibitors = [EventExhibitor];

// Event Speakers
export const EventSpeaker = new schema.Entity('customFields', {}, { idAttribute: 'id' });
export const EventSpeakers = [EventSpeaker];

// Sponsors category
export const SponsorsCategorySchema = new schema.Entity('sponsorsCategory', {});
export const SponsorsCategoryListSchema = [new schema.Entity('sponsorsCategory', {})];

// Event Attendees
export const EventAttendee = new schema.Entity(
  'attendees',
  {},
  {
    idAttribute: 'registration_id',
  },
);

export const EventAttendees = [EventAttendee];

// Event Attendee Checkin zones
export const EventAttendeeCheckinZone = new schema.Entity(
  'checkin_zones',
  {},
  {
    idAttribute: 'id',
  },
);

export const EventAttendeeCheckinZones = [EventAttendeeCheckinZone];

// Event Attendees
export const EventPoster = new schema.Entity('posters', {}, {});

export const EventPosters = [EventPoster];

// Event Poster advocates
export const PosterAdvocate = [new schema.Entity('advocates', {}, {})];

// Attendee Memory Template
export const AttendeeMemoryTemplate = new schema.Entity('templates', {}, {});

export const AttendeeMemoryTemplates = [AttendeeMemoryTemplate];

// Attendee Memory
export const AttendeeMemoryAsset = new schema.Entity(
  'files',
  {},
  {
    idAttribute: (value, parent, key) => parent.id,
    processStrategy: (value, parent, key) => ({
      ...value,
      id: parent.id,
    }),
  },
);
export const AttendeeMemory = new schema.Entity(
  'memories',
  {
    memory_asset: AttendeeMemoryAsset,
  },
  {},
);
export const AttendeeMemories = [AttendeeMemory];

// Integrations
export const IntegrationEvent = new schema.Entity('integrationEvent', {}, { idAttribute: 'id' });
export const IntegrationEvents = [IntegrationEvent];
