import { connect } from 'react-redux';
import { ModalDuck, UploaderDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalSlideDataDuck } from '@premagic/proposals';

import {
  fetchProposalFiles,
  proposalCreatorUploadingFilesSelector,
  removeSlideUploadingFile,
} from '../ProposalCreatorDuck';
import ProposalCreatorHeaderFilesInfo from './ProposalCreatorHeaderFilesInfo';
import { totalFilesInFolderSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import { filesInProcessingSelector } from '../../../images/FilesDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, folderId, deckId } = ownProps;
  return {
    projectId,
    folderId,
    deckId,
    filesInProcess: filesInProcessingSelector(state),
    creatorUploadingFiles: proposalCreatorUploadingFilesSelector(state),
    allUploadFiles: UploaderDuck.fileUploaderFilesSelector(state),
    totalFiles: totalFilesInFolderSelector(folderId)(state),
    slides: ProposalSlideDataDuck.proposalsSlideItemsSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProposalSlide: (options, data) => dispatch(ProposalSlideDataDuck.saveProposalSlide(dispatch, options, data)),
    fetchProposalFiles: (options) => dispatch(fetchProposalFiles(dispatch, options)),
    removeSlideUploadingFile: (id) => dispatch(removeSlideUploadingFile(dispatch, id)),
    showFilesModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_FILES_MODAL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorHeaderFilesInfo);
