import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import EventDetailsOccasionsLayout from './EventDetailsOccasionsLayout';
import { toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { fetchOccasionsForEventData } from '../../../occassions/OccasionsDataDuck';
import { hasPermission } from '../../../users/UsersDataDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;

  return {
    eventId,
    focusId,
    hasPermissionsToManageEvent: hasPermission(PERMISSIONS.EVENT_OCCASION_MANAGE)(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    initData: (eventId: string) => {
      dispatch(fetchOccasionsForEventData(dispatch, eventId));
    },
    showAddOccasion: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsOccasionsLayout);
