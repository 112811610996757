import { FolderService, FontService } from '@premagic/core';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { fetchProjectsData } from '../../../../projects/AccountProjectsDataDuck';
import {
  eventAttendeeBadgeDesignsSelector,
  fetchBadgeDesignData,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { getFolderImagesAndOrder } from '../../../../projects/folders/AccountFoldersDataDuck';
import { getEventAttendeesForProject } from '../EventAttendeesDataDuck';
import EventAttendeeBadgeMainLayout from './EventAttendeeBadgeMainLayout';
import { fetchFontsData, fontFetchSuccessSelector } from '../../../../font-selector/FontSelectorDuck';

function mapStateToProps(state, ownProps) {
  const { projectId, eventId, folderId } = ownProps.match.params;

  const badgeDesign = eventAttendeeBadgeDesignsSelector(folderId)(state);

  return {
    projectId,
    eventId,
    brandingFolderId: folderId,
    isLoading:
      isLoadingSelector(LOADINGS.FOLDER_META.FETCH)(state) ||
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state) ||
      isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state),
    isFontFetchExecuted: fontFetchSuccessSelector(state),
    posterWidgetDesign: badgeDesign?.widget_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId, folderId, fetchFont) => {
      dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, FolderService.FOLDER_TYPES.BRANDING));
      dispatch(fetchBadgeDesignData(dispatch, projectId, folderId));
      dispatch(getEventAttendeesForProject(dispatch, projectId));
      dispatch(fetchProjectsData(dispatch));
      if (fetchFont) {
        dispatch(fetchFontsData(dispatch));
      }
    },
    loadFonts: (fonts) => {
      FontService.loadGoogleFonts(fonts);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeMainLayout);
