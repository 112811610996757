import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { activeProjectsArraySelector, fetchProjectsData } from '../../../AccountProjectsDataDuck';
import DAMLibraryProjectsFilterSelector from './DAMLibraryProjectsFilterSelector';

function mapStateToProps(state, ownProps: { value: Array<string> }) {
  const { value } = ownProps;

  return {
    value,
    projects: activeProjectsArraySelector(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchProjectsData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryProjectsFilterSelector);
