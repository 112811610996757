import {
  ButtonIcon,
  COLOR_SHADES,
  Col,
  Icon,
  LoadingDots,
  MediaModal,
  MediaModalContent,
  MediaModalContentLeftCol,
  MediaModalContentRightCol,
  MediaModalHeader,
  MediaModalHeaderActions,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { useState } from 'react';
import APP_URLS from '../../../../../../services/AppRouteURLService';
import WatermarkPhotoSelector from '../../../../../watermark/watermark-preview/WatermarkPhotoSelector';
import SponsorWatermarkPreviewFullViewImageContainer from './SponsorWatermarkPreviewFullViewImageContainer';

type Props = {
  navigateBack: (defaultUrl: string) => void;
  folderId: string;
  isLoadingProject?: boolean;
  isLoading?: boolean;
  eventId: string;
  projectId: string;
};

export default function SponsorWatermarkPreviewFullView(props: Props) {
  const { projectId, navigateBack, isLoadingProject, folderId, isLoading, eventId } = props;

  function close() {
    const backUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__SPONSORS, {
      eventId,
    });
    navigateBack(backUrl);
  }
  const [selectedPhoto, setSelectedPhoto] = useState<{ image; name }>();

  return (
    <MediaModal show close={close}>
      <MediaModalHeader>
        <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
          Watermark preview
        </Text>
        <Space size={12} />
        <WatermarkPhotoSelector onChange={setSelectedPhoto} value={selectedPhoto} />
        <MediaModalHeaderActions>
          <ButtonIcon onClick={close} title="Close">
            <Icon name="x" />
          </ButtonIcon>
        </MediaModalHeaderActions>
      </MediaModalHeader>
      <MediaModalContent>
        {isLoading || isLoadingProject ? (
          <LoadingDots />
        ) : (
          <>
            <MediaModalContentLeftCol>
              <SponsorWatermarkPreviewFullViewImageContainer
                projectId={projectId}
                folderId={folderId}
                photo={selectedPhoto}
              />
            </MediaModalContentLeftCol>
            <MediaModalContentRightCol>
              <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                Mobile view
              </Text>
              <Space vertical />

              <div style={{ maxHeight: 400 }}>
                <SponsorWatermarkPreviewFullViewImageContainer
                  projectId={projectId}
                  isMobileView
                  folderId={folderId}
                  photo={selectedPhoto}
                />
              </div>
            </MediaModalContentRightCol>
          </>
        )}
      </MediaModalContent>
    </MediaModal>
  );
}
