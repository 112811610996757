import {
  Col,
  Row,
  SpinningGears,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
  WindowBox,
  WindowBoxContent,
  WindowBoxHeader,
} from '@premagic/myne';

type Props = {
  show?: boolean;
  close: () => void;
};

export default function ImageCompressMessageBox(props: Props) {
  const { show, close } = props;
  if (!show) return null;

  return (
    <WindowBox>
      <WindowBoxHeader onClose={close}>Uploading files</WindowBoxHeader>
      <WindowBoxContent>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            Preparing your files for upload
          </Text>
          <Col size={null} rightAlighed>
            <SpinningGears />
          </Col>
        </Row>
      </WindowBoxContent>
    </WindowBox>
  );
}
