import { BrowserUrlUtils, HttpUtils, LocalCacheUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { UserInfoDataType } from './PeoplePosterService';

/*
  LINKEDIN
  ----------------
  https://learn.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?tabs=HTTPS1#step-2-request-an-authorization-code
  https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/sign-in-with-linkedin-v2?context=linkedin%2Fconsumer%2Fcontext

  ### How does linked auth work?
  1. User click on the button/link to login with LinkedIn, we redirect the user to api.premagic.com/social-auth/linkedin?client_redirect_url=encoded_url
  where the server will redirect the user to linkedin auth page with right permissions and linkedin will return the code.
  Server will user this code and get the accesstoken and redirect the user to the client_redirect_url with the access token in the url

  2. Get the user information from the access token | getUserInformationFromLinkedIn()

  Debug token from here https://www.linkedin.com/developers/tools/oauth/token-inspector?clientId=86oks8lt4tvm4p

*/

// Set your LinkedIn API key and secret
export enum SOCIAL_NETWORKS {
  LINKEDIN = 'LINKEDIN',
}
export const SOCIAL_NETWORK_DETAILS: Record<
  SOCIAL_NETWORKS,
  {
    getPostUrl: (urn: string) => string;
  }
> = {
  [SOCIAL_NETWORKS.LINKEDIN]: {
    getPostUrl: (urn: string) => {
      const urnId = urn.replace(/\/(ugcPosts|posts)/, '');
      return `https://www.linkedin.com/feed/update${urnId.startsWith('/') ? '' : '/'}${urnId}/`;
    },
  },
};
export function getPostUrlForNetwork(network: SOCIAL_NETWORKS, urn?: string) {
  if (!urn) return '';
  if (urn.startsWith('https://')) return urn;
  if (!SOCIAL_NETWORK_DETAILS[network]) return '';
  return SOCIAL_NETWORK_DETAILS[network].getPostUrl(decodeURIComponent(urn));
}

export function getPremagicSocialAuthPageLink(network: SOCIAL_NETWORKS) {
  return API_URLS.SOCIAL_AUTH.LOGIN_PAGE(
    network,
    encodeURIComponent(
      BrowserUrlUtils.getUrl({
        removeQueryString: true,
      }),
    ),
  );
}

type SocialAuthLinkedinCustomResponse = {
  id: string;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  headline: string;
  profile_picture: string;
  advocate_id: string;
};

function getRequiredDataFromAPI(network: SOCIAL_NETWORKS, data: SocialAuthLinkedinCustomResponse): UserInfoDataType {
  const defaultData = {
    advocateId: data.advocate_id,
    id: data.id,
  };

  switch (network) {
    case SOCIAL_NETWORKS.LINKEDIN:
    default:
      return {
        ...defaultData,
        userName: data.name,
        userTitle: data.headline,
        userPhoto: data.profile_picture,
      };
  }
}

export enum SOCIAL_POST_INTENT {
  POSTER = 'poster',
}
export function getUserInformationFromSocialNetwork(
  network: SOCIAL_NETWORKS,
  data: { accessToken: string },
  options: { domain: string; websiteId: string; posterId: string; intent: SOCIAL_POST_INTENT },
): Promise<UserInfoDataType> {
  const { accessToken } = data;
  const { domain, websiteId, posterId, intent } = options;

  if (!accessToken) return Promise.reject(new Error('Access token is required'));

  return HttpUtils.get(
    API_URLS.SOCIAL_AUTH.PROFILE(network),
    {
      params: {
        domain,
        website_id: websiteId,
        poster_id: posterId,
        intent,
      },
    },
    true,
    true,
    {
      'social-auth-access-token': accessToken,
    },
  ).then((response) => getRequiredDataFromAPI(network, response.data?.data));
}

// https://www.notion.so/pmagic/How-does-LinkedIn-Auth-works-497892e38d9441178aa6a281a3830439?pvs=4
export function postPosterOnSocialNetwork(
  network: SOCIAL_NETWORKS,
  data: { accessToken: string; userId: string; images: Array<Blob | File>; video?: File; message: string },
  options?: { domain: string; websiteId: string; posterId: string; intent: SOCIAL_POST_INTENT; advocateId: string },
): Promise<{
  post_id: string;
  post_url: string;
  advocacy_url: string;
}> {
  const { accessToken } = data;

  const formData = new FormData();
  formData.append('author_id', data.userId);
  formData.append('post_content', data.message);
  if (options) {
    const { domain, websiteId, posterId, intent, advocateId } = options;
    formData.append('domain', domain);
    formData.append('website_id', websiteId);
    formData.append('poster_id', posterId);
    formData.append('intent', intent);
    formData.append('advocate_id', advocateId);
  }

  data.images.map((image, idx) => formData.append('images', image, `image_${idx}.jpg`));
  if (data.video) formData.append('files', data.video, 'video');

  return HttpUtils.post(API_URLS.SOCIAL_AUTH.POST(network), formData, true, true, {
    'social-auth-access-token': accessToken,
  }).then((response) => response.data?.data);
}

export const CACHE_KEYS = {
  REDIRECTION_URL: 'social-auth-login-redirection-page',
  TOKEN: 'social-auth-token',
};

export function cacheRedirectionUrl(url: string) {
  LocalCacheUtils.setCache(CACHE_KEYS.REDIRECTION_URL, url);
}
export function getRedirectionUrl(): string {
  return LocalCacheUtils.getCache(CACHE_KEYS.REDIRECTION_URL) as string;
}

export function cacheCode(network: SOCIAL_NETWORKS, token?: string) {
  if (token)
    return LocalCacheUtils.setCache(CACHE_KEYS.TOKEN, {
      [network]: token,
    });

  LocalCacheUtils.removeCache(CACHE_KEYS.TOKEN);
}
export function getCode(network: SOCIAL_NETWORKS): string {
  return LocalCacheUtils.getCache(CACHE_KEYS.TOKEN)?.[network] as string;
}

export function cacheToken(token: string) {
  LocalCacheUtils.setCache(CACHE_KEYS.TOKEN, token);
}
export function getToken(): string {
  return LocalCacheUtils.getCache(CACHE_KEYS.TOKEN) as string;
}
