import React from 'react';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemAction,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Space,
  Text,
  TEXT_BOLDNESS,
  TimeAgo,
} from '@premagic/myne';
import { ProposalService } from '@premagic/proposals';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';

type Props = {
  proposal: ProposalService.ProposalType;
  isDeleting: boolean;
  removeProposal: (projectId: string, folderId: string) => void;
  duplicateProposal: (name: string, template_id: string) => void;
};

export default function ProposalTemplateItem(props: Props): JSX.Element {
  const { proposal, isDeleting, removeProposal, duplicateProposal } = props;

  const {
    folder_name: name,
    folder_id: id,
    current_deck_version: deckId,
    created_at: createdAt,
    project_id: projectId,
  } = proposal;

  const proposalDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROPOSALS_TEMPLATES.DETAILS, {
    folderId: id,
    deckId,
  });

  return (
    <ListItem>
      <ErrorBoundary>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          {name}
        </Text>
      </ErrorBoundary>
      <Col rightAlighed size={null}>
        <Text muted>
          <TimeAgo date={SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt)} />
        </Text>
      </Col>
      <Space size={8} />
      <Button style={BUTTON_STYLES.LINK} link={proposalDetails} size={BUTTON_SIZE.SM}>
        Edit
      </Button>
      <Space size={8} />
      <ListItemAction className={JS_SELECTORS.PROPOSAL_TEMPLATE_MORE_OPTIONS(id)}>
        {isDeleting ? (
          <LoadingDots size="sm" />
        ) : (
          <Dropdown>
            <DropdownIcon title="Proposal options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
              <Icon name="more_horizontal" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
            </DropdownIcon>
            <Menu>
              <MenuItem onClick={() => duplicateProposal(`Copy of ${name}`, id)}>
                <MenuItemIcon name="copy" />
                Duplicate
              </MenuItem>
              <MenuItem onClick={() => removeProposal(projectId, id)}>
                <MenuItemIcon name="trash" />
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </ListItemAction>
    </ListItem>
  );
}
