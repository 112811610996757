type CurrencyType = {
  symbol: string;
  name: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  code: string;
  name_plural: string;
};
// Subset from https://gist.github.com/Fluidbyte/2973986
export const CURRENCIES = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CAD',
    name_plural: 'Canadian dollars',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
  },
  AED: {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol_native: 'د.إ.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'AED',
    name_plural: 'UAE dirhams',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'AUD',
    name_plural: 'Australian dollars',
  },
  INR: {
    symbol: '₹',
    name: 'Indian Rupee',
    symbol_native: 'টকা',
    decimal_digits: 2,
    rounding: 0,
    code: 'INR',
    name_plural: 'Indian rupees',
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbol_native: '￥',
    decimal_digits: 0,
    rounding: 0,
    code: 'JPY',
    name_plural: 'Japanese yen',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling',
  },
};

export const MICRO_AMOUNT = 1000000;
export function getCurrencyInFormat(amount: number, currencyCode: string, compact?: boolean): string {
  const number = amount / MICRO_AMOUNT;
  const local = currencyCode === 'INR' ? 'en-IN' : 'en-US';
  return new Intl.NumberFormat(local, {
    style: 'currency',
    currency: currencyCode || 'INR',
    maximumFractionDigits: compact ? 2 : 0,
    minimumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
    notation: compact ? 'compact' : 'standard',
    compactDisplay: 'short',
  }).format(number);
}

export function getCurrencyInMicroAmount(amount = 0): number {
  return amount * MICRO_AMOUNT;
}

export function getAmountFromMicroCurrency(amount = 0): number {
  return amount / MICRO_AMOUNT;
}
