import { ALBUM_CREATOR_URLS } from '@premagic/album-creator';
import { ProposalRouteURLService } from '@premagic/proposals';
import { ErrorTracker } from '@premagic/utils';
import { compile } from 'path-to-regexp';

const APP_URLS = {
  WELCOME: {
    ROOT: '/welcome/:view(home|setup|done)/:type?',
    SETUP: '/welcome/setup/',
    DONE: '/welcome/done/',
  },
  HOME: {
    ROOT: '/home/',
  },
  PROJECTS: {
    LIST: '/projects',
  },
  PROJECT: {
    DETAILS: '/projects/:projectId/',
    ALBUM_IMAGES_BASED_ON_STATUS:
      '/projects/:projectId/:folderType(HIGHLIGHT|SELECTION)/v/:view(approved|all|rejected|favorite)/',
    ALBUM_DETAILS: '/projects/:projectId/:folderType(HIGHLIGHT|SELECTION)/:folderId?',
    HIGHLIGHT: '/projects/:projectId/HIGHLIGHT/:folderId?',
    SELECTION: '/projects/:projectId/SELECTION/:folderId?',
    SELECTION__SELECTED_PHOTOS: '/projects/:projectId/SELECTION/v/approved',
    HIGHLIGHT__COVER_SETTINGS: '/projects/:projectId/HIGHLIGHT/v/cover',
    HIGHLIGHT__GALLERY_SETTINGS: '/projects/:projectId/HIGHLIGHT/v/gallery',
    HIGHLIGHT__HIDDEN_PHOTOS: '/projects/:projectId/HIGHLIGHT/v/rejected',
    HIGHLIGHT__FAVOURITE_PHOTOS: '/projects/:projectId/HIGHLIGHT/v/favorite',
    HIGHLIGHT__WATERMARK_PREVIEW: '/projects/:projectId/HIGHLIGHT/v/cover/watermark-preview/:folderId?',
  },
  FOLDER: {
    DETAILS: '/projects/:projectId/:folderType(HIGHLIGHT|SELECTION)/:folderId?',
    FILE_VIEW: '/projects/:projectId/:folderType(HIGHLIGHT|SELECTION)/:folderId/:view(approved|all|rejected)/:id',
  },
  CLIENT_WEBSITE: {
    CREATE: '/projects/:projectId/client-website/create/',
    DETAILS: '/projects/:projectId/client-website/:id/',
    DETAILS___DETAILS: '/projects/:projectId/client-website/:id/v/details',
    DETAILS___SCHEDULE: '/projects/:projectId/client-website/:id/v/schedule',
    DETAILS___WISH: '/projects/:projectId/client-website/:id/v/wish',
    DETAILS___PHOTOS: '/projects/:projectId/client-website/:id/v/photos',
    DETAILS___REGISTRATION: '/projects/:projectId/client-website/:id/v/registration',
    EDIT: '/projects/:projectId/client-website/:id/edit',
    PRINT_INVITE: '/projects/:projectId/client-website/:id/print/:view(downloadables|qrcode|svg)',
  },
  CRM: {
    INDEX: '/crm/',
    DETAILS: '/crm/:view(list|calendar)/:filters?',
    CALENDAR: '/crm/calendar/:filters?',
    EVENTS_LIST: '/crm/list/:filters?',
    CREATE: '/crm/create/',
    CREATE__CLIENT: '/crm/create/client/:filters?',
    CREATE__EVENT: '/crm/create/client/:clientId/event',
    CLIENTS: '/crm/clients',
    EVENT: '/crm/events/:eventId',
    EVENT__MICRO_SITE: '/crm/events/:eventId/v/site/:focusId?',
    EVENT__OCCASIONS: '/crm/events/:eventId/v/occasion/:focusId?',
    EVENT__DELIVERABLE: '/crm/events/:eventId/v/deliverable/:focusId?',
    EVENT__FINANCIAL: '/crm/events/:eventId/v/financial/:focusId?',
    EVENT__SPONSORS: '/crm/events/:eventId/v/sponsor/',
    EVENT__EXHIBITOR: '/crm/events/:eventId/v/exhibitor/',
    EVENT__REPORT: '/crm/events/:eventId/v/insight/:focusId?',
    EVENT__MARKETING: '/crm/events/:eventId/v/marketing/:focusId?',
    EVENT__POSTERS: '/crm/events/:eventId/v/posters/:focusId?',
    EVENT__BADGES: '/crm/events/:eventId/v/badges/:focusId?',
    EVENT__SPONSORS__FOLDER_WATERMARK_PREVIEW: '/crm/events/:eventId/v/sponsor/folder-watermark-preview/:folderId',
    EVENT__SPONSORS__PROJECT_WATERMARK_PREVIEW:
      '/crm/events/:eventId/v/sponsor/project-watermark-preview/:projectId/:folderId',
    EVENT__ATTENDEES: '/crm/events/:eventId/v/people/:focusId?',
    EVENT__REPORT_ISSUES: '/crm/events/:eventId/v/report-issues/:reportType',
    EVENT__EVENT_FEEDBACK: '/crm/events/:eventId/v/event-feedback/:type/',
    EVENT__ATTENDEE_BADGES: '/crm/events/:eventId/v/event-attendee-badges/:projectId/:folderId',
    EVENT__CHECKIN_STATS: '/crm/events/:eventId/v/event-checkin-stats/:projectId/',
    EVENT_INVOICE: '/crm/events/:eventId/invoice',
    EVENT__SETTINGS: '/crm/events/:eventId/settings',
    EVENT__EMBED: '/crm/events/:eventId/embed',
    EVENT__AVATAR_STUDIO: '/crm/events/:eventId/avatar-studio',
  },
  CRM_DASHBOARD: {
    INDEX: '/board/',
    DETAILS: '/board/:view(list|calendar)/:filters?',
    EVENTS_LIST: '/board/list/:filters?',
  },
  FINANCIALS: {
    INDEX: '/financials/',
    DETAILS: '/financials/:type(pending|upcoming|expenses)/:filters?',
  },
  ALBUM: ALBUM_CREATOR_URLS.ALBUM,
  SETTINGS_USERS: {
    USERS: '/settings/users/',
    EDIT: '/settings/users/:userId/edit',
    DELETE: '/settings/users/:userId/delete',
    ACTIVATE: '/settings/users/:userId/activate',
  },
  SETTINGS_WORKFLOW: {
    WORKFLOW: '/settings/workflow/',
    EDIT: '/settings/workflow/:workflowId/edit',
  },
  LIBRARY: {
    INDEX: '/library/:filters?',
    FILES: '/library/:filters?',
    FILE_DETAILS: '/library/:filters/:fileId/:folderId?',
  },
  SETTINGS: {
    INDEX: '/settings',
    PROFILE: {
      ROOT: '/settings/company',
      DETAILS: '/settings/company/details',
      DOMAIN: '/settings/company/domain',
      CUSTOM_POLICY: '/settings/company/custom-policy',
    },
    ENQUIRY_FORM: '/settings/enquiry-form',
    ACCOUNT: {
      ROOT: '/settings/account',
      BASIC: '/settings/account/basic',
      SERVICES: '/settings/account/services',
    },
    SUBSCRIPTIONS: '/settings/subscriptions',
    INVOICE: '/settings/invoice',
    STORE_PRICING: {
      ROOT: '/settings/store-pricing',
      DEFAULT: '/settings/store-pricing/default',
    },
    SIGNATURE: '/settings/signature',
    SELECTION: '/settings/selection',
    SIGNATURE_ALBUM_DESIGN: '/settings/signature-album-design',
    NOTIFICATIONS: '/settings/notifications',
    HELP: '/settings/help',
    EXPERIMENTAL: '/settings/ex',
    EXPERIMENTAL_2: '/settings/ex2',
    BRANDING: {
      HOME: '/settings/branding/',
      DETAILS: '/settings/branding/:folderId/v/:view(watermark|signature|signature-gallery)',
      DETAILS__WATERMARK: '/settings/branding/:folderId/v/watermark',
    },
    EVENTS: {
      ROOT: '/settings/events/occasions/',
    },
    FINANCIALS: {
      ROOT: '/settings/account-financial/',
    },
    DAM_LIBRARY: {
      ROOT: '/settings/library/',
    },
    LEADS: {
      ROOT: '/settings/leads/',
    },
    PHOTO_DISTRIBUTION: '/settings/photo_distribution',
    EVENT_MARKETING: {
      ROOT: '/settings/event-marketing/',
    },
  },
  PROPOSALS_TEMPLATES: ProposalRouteURLService.PROPOSALS_URLS.PROPOSALS_TEMPLATES,
  PROPOSALS: ProposalRouteURLService.PROPOSALS_URLS.PROPOSALS,
  USER_PROFILE: {
    INDEX: '/my-profile',
    EDIT: '/my-profile/edit',
    CHANGE_PASSWORD: '/my-profile/change-password',
  },
  OLD_LEADS: {
    INDEX: '/leads/NEW',
  },
  STORE: {
    INDEX: '/store/',
    DETAILS: '/store/:id/',
  },
  FEED: {
    LIST: '/feed',
  },
  ACCOUNT: {
    INDEX: '/account',
    SUBSCRIPTIONS: '/account/subscriptions/',
  },
  SOCIAL_AUTH: {
    LOGIN: '/social-auth/:network(linkedin|facebook|google)/:options',
    SUCCESS: '/social-auth-success/:network(linkedin|facebook|google)/:options?',
  },
  EVENT_POSTER: {
    EDIT: '/poster-editor/:type/:eventId/:projectId/:posterId?/',
    PREVIEW: '/poster-preview/:type/:eventId/:projectId/:posterId/preview/:filters?',
  },
  WIDGET: {
    PHOTO_GALLERY: '/widget/photo-gallery/:eventId/:projectId/:shareId',
  },
};

export default APP_URLS;

/**
 * getRouterUrlFor - Coverts the url pattern to url with attributes
 * @param route - route pattern from APP_URLS
 * @param data - data to be filled to the url
 */
// Use from @premagic/utils
export function getRouteUrlFor(route: string, data: Record<string, unknown>): string {
  try {
    const toPath = compile(route);
    return toPath(data);
  } catch (e) {
    ErrorTracker.logError('CONVERT TO URL FROM ROUTE PATH FAILED', e);
    return '';
  }
}
