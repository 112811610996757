import ClassNames from 'classnames';
import styles from './socialButton.module.css';
import { LoadingSpin } from '../Loading/Loading';

export enum SOCIAL_BUTTON_NETWORKS {
  GENERIC = 'GENERIC',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  WHATSAPP = 'whatsapp',
}
const SOCIAL_NETWORK_ICONS = {
  [SOCIAL_BUTTON_NETWORKS.GENERIC]: 'share',
  [SOCIAL_BUTTON_NETWORKS.LINKEDIN]: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="LinkedIn" fill="#FFFFFF">
        <g id="Fill-6-+-Fill-7" transform="translate(6.000000, 5.000000)">
          <path
            d="M3.44222222,0 C5.34,0 6.88,1.54111111 6.88,3.44 C6.88,5.34 5.34,6.88111111 3.44222222,6.88111111 C1.53666667,6.88111111 0,5.34 0,3.44 C0,1.54111111 1.53666667,0 3.44222222,0 L3.44222222,0 Z M0.471111111,9.48888889 L6.41,9.48888889 L6.41,28.5777778 L0.471111111,28.5777778 L0.471111111,9.48888889 Z"
            id="Fill-6"
          />
          <path
            d="M10,9.47333333 L15.6866667,9.47333333 L15.6866667,12.0833333 L15.7688889,12.0833333 C16.56,10.5822222 18.4955556,9 21.3811111,9 C27.3888889,9 28.4988889,12.9522222 28.4988889,18.0933333 L28.4988889,28.5622222 L22.5666667,28.5622222 L22.5666667,19.2788889 C22.5666667,17.0655556 22.5288889,14.2177778 19.4844444,14.2177778 C16.3966667,14.2177778 15.9255556,16.63 15.9255556,19.1211111 L15.9255556,28.5622222 L10,28.5622222 L10,9.47333333"
            id="Fill-7"
          />
        </g>
      </g>
    </g>
  ),
};

type SocialButtonProps = {
  href?: string;
  isLoading?: boolean;
  network: SOCIAL_BUTTON_NETWORKS;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export function SocialButton(props: SocialButtonProps) {
  const { href, isLoading, network, children, onClick, disabled } = props;
  const Element = href ? 'a' : 'button';

  return (
    <Element
      disabled={disabled}
      className={ClassNames(styles['social-button'], styles[`social-button--${network}`], {
        [styles['social-button--loading']]: isLoading,
        [styles['social-button--disabled']]: disabled,
      })}
      href={href}
      onClick={onClick}
    >
      {/* <svg className={styles['social-button__icon']} viewBox="0 0 40 40"> */}
      {/*  {SOCIAL_NETWORK_ICONS[network]} */}
      {/* </svg> */}
      {isLoading && (
        <div className={styles['social-button__loading']}>
          <LoadingSpin />
        </div>
      )}
      <div className={styles['social-button__text']}>{children}</div>
    </Element>
  );
}
