import React, { useMemo } from 'react';
import { EventCustomFromFieldsService } from '@premagic/core';
import {
  Col,
  Row,
  Space,
  TEXT_GROUP_LABEL_STYLES,
  TEXT_GROUP_SIZES,
  TEXT_GROUP_TYPES,
  TextGroupLabel,
  TextGroupValue,
} from '@premagic/myne';

function getTextGroupValueType(type: EventCustomFromFieldsService.FORM_FIELD_TYPE) {
  // content to JSON
  const mapType = {
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.TEXT]: TEXT_GROUP_TYPES.TEXT,
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.PARAGRAPH]: TEXT_GROUP_TYPES.TEXT,
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.URL]: TEXT_GROUP_TYPES.TEXT,
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.NUMBER]: TEXT_GROUP_TYPES.TEXT,
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.DATE]: TEXT_GROUP_TYPES.DATE,
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.MULTI_SELECT]: TEXT_GROUP_TYPES.TEXT,
    [EventCustomFromFieldsService.FORM_FIELD_TYPE.SINGLE_SELECT]: TEXT_GROUP_TYPES.TEXT,
  };
  return mapType[type] || TEXT_GROUP_TYPES.TEXT;
}
type Props = {
  formFields: Record<string, EventCustomFromFieldsService.FormFieldType>;
  data: Record<string, string>;
};
function getFieldsDetails(formFields = {}, key = '') {
  return formFields[key] || formFields[key.replace('.', '')];
}
const ignoreSystemFields = true;
export function CustomRegistrationFormFields(props: Props): JSX.Element | null {
  const { formFields, data } = props;
  const dataArray = useMemo(
    () =>
      Object.entries(data)
        .filter(([key]) => {
          if (ignoreSystemFields) return !EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS_DETAILS[key];
          return true;
        })
        .filter(([key]) => getFieldsDetails(formFields, key)),
    [data, formFields],
  );
  if (dataArray.length === 0) return null;

  return (
    <Row>
      <Space size={12} />
      <Col size={10}>
        <Space vertical size={2} />
        {dataArray.map(([key, value]) => {
          const { label, type } = getFieldsDetails(formFields, key);
          const textGroupType = getTextGroupValueType(type);
          return (
            <Row key={key}>
              <TextGroupLabel size={TEXT_GROUP_SIZES.SM} style={TEXT_GROUP_LABEL_STYLES.LEFT_ALIGNED}>
                {label}
              </TextGroupLabel>
              <TextGroupValue value={value} type={textGroupType} />
            </Row>
          );
        })}
      </Col>
    </Row>
  );
}
