import { normalize } from 'normalizr';
import { Dispatch } from 'redux';
import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { EventTrackerService, FolderService, ProjectMetaService, ProjectService, Schemas } from '@premagic/core';
import { ActionTypeUtils, BrowserUrlUtils, ErrorTracker } from '@premagic/utils';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { addNewFolderToProjectsData, removeFolderFromProjectData, setProjectsData } from '../AccountProjectsDataDuck';
import {
  addFolderData,
  setFoldersData,
  updateFolderData,
  uploadFilesToFolderWithProgressTrigger,
} from '../folders/AccountFoldersDataDuck';
import { updateProjectMetaData } from '../project-meta/ProjectsMetaDataDuck';
import APP_URLS from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';
import { addFilesData, setFilesData } from '../../images/FilesDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('BRAND_SPONSOR', true);

export const fetchSystemFolder = createAction(
  getActionType('FOLDER', 'FETCH'),
  (dispatch: Dispatch, folderId: string, folderType: FolderService.FOLDER_TYPES) => {
    const loadingKey = LOADINGS.FOLDER.FETCH_ALL;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderService.fetchFolder(ProjectService.SystemProject.id, folderId, folderType)
      .then((folderDetails) => {
        const normalizedData = normalize(folderDetails, Schemas.FolderSchema);
        const { folders, images } = normalizedData.entities || {};

        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(addFolderData(dispatch, folders));
        dispatch(setFilesData(dispatch, images));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const fetchSystemFolders = createAction(
  getActionType('FOLDERS', 'FETCH'),
  (dispatch: Dispatch, folderType: FolderService.FOLDER_TYPES) => {
    const loadingKey = LOADINGS.FOLDER.FETCH_ALL;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderService.fetchSystemFolders(folderType)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.FoldersSchema);
        dispatch(
          setProjectsData(dispatch, {
            [ProjectService.SystemProject.id]: {
              folders: normalizedData.result,
            },
          }),
        );
        dispatch(setFoldersData(dispatch, normalizedData.entities.folders));
        const folderData = normalizedData.entities?.folders || {};

        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const addNewSystemBrandingFolder = createAction(
  getActionType('ACTION', 'ADD'),
  (dispatch, folderName: string) => {
    const loadingKey = LOADINGS.FOLDER.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const folderType = FolderService.FOLDER_TYPES.BRANDING;
    const projectId = ProjectService.SystemProject.id;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.CREATE, {
      projectId,
      folderName,
      folderType,
    });

    FolderService.createSystemFolder({
      folder_name: folderName,
      folder_type: folderType,
    })
      .then((response) => {
        const folderId = response.folder_id;
        const normalizedData = normalize(response, Schemas.FolderSchema);
        const { folders } = normalizedData.entities || {};
        dispatch(addFolderData(dispatch, folders));
        dispatch(addNewFolderToProjectsData(dispatch, projectId, folderId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        const folderEditURL = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.BRANDING.DETAILS, {
          folderId,
          view: 'watermark',
        });

        navigateTo(dispatch, folderEditURL);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('SYSTEM_BRANDING:ADD', e);
      });
  },
);

export const addSponsorOrBrandingFolder = createAction(
  getActionType('ACTION', 'ADD_SPONSOR_OR_BRANDING'),
  (dispatch, projectId: string, folderType: FolderService.FOLDER_TYPES) => {
    const loadingKey = LOADINGS.FOLDER.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.CREATE, {
      projectId,
      folderType,
    });

    FolderService.createFolder(projectId, {
      folder_name: folderType,
      folder_type: folderType,
    })
      .then((response) => {
        const folderId = response.folder_id;
        const normalizedData = normalize(response, Schemas.FolderSchema);
        const { folders } = normalizedData.entities || {};
        dispatch(addFolderData(dispatch, folders));
        dispatch(addNewFolderToProjectsData(dispatch, projectId, folderId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('SPONSOR_OR_BRANDING:ADD', e);
      });
  },
);

export const createBrandingFolderForBadges = createAction(
  getActionType('ACTION', 'ADD_BRANDING_FOLDER_FOR_BADGES'),
  (dispatch, projectId: string, eventId: string) => {
    const loadingKey = LOADINGS.FOLDER.ADD;
    const folderType = FolderService.FOLDER_TYPES.BRANDING;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.CREATE, {
      projectId,
      folderType,
    });

    FolderService.createFolder(projectId, {
      folder_name: folderType,
      folder_type: folderType,
    })
      .then((response) => {
        const folderId = response.folder_id;
        const normalizedData = normalize(response, Schemas.FolderSchema);
        const { folders } = normalizedData.entities || {};
        dispatch(addFolderData(dispatch, folders));
        dispatch(addNewFolderToProjectsData(dispatch, projectId, folderId));
        const url = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEE_BADGES, {
          eventId,
          projectId,
          folderId: response.folder_id,
        });
        navigateTo(dispatch, url, {});

        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ADD_BRANDING_FOLDER_FOR_BADGES', e);
      });
  },
);

export const removeSystemBrandingFolder = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, folderId: string) => {
    const loadingKey = LOADINGS.FOLDER.DELETE_SINGLE(folderId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const folderType = FolderService.FOLDER_TYPES.BRANDING;
    const projectId = ProjectService.SystemProject.id;

    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.DELETE, {
      projectId,
      folderName: folderType,
      folderType,
    });
    dispatch(removeFolderFromProjectData(dispatch, projectId, folderId));

    FolderService.deleteSystemFolder(folderType, folderId)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('BRANDING:REMOVE', e);
      });
  },
);

export const updateSystemBrandingFolder = createAction(
  getActionType('ACTION', 'update'),
  (dispatch, folderId: string, data) => {
    const loadingKey = LOADINGS.FOLDER.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const folderType = FolderService.FOLDER_TYPES.BRANDING;

    FolderService.updateSystemFolderData<FolderService.BrandingFolderType>(folderType, folderId, data)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('BRANDING:UPDATE', e);
      });
  },
);

const initialState = {
  items: IMap({}),
};

export default handleActions({}, initialState);
