import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormErrorType,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { ProposalVariableService } from '@premagic/proposals';

import ProposalVariableFormContainer from './ProposalVariableFormContainer';

type Props = {
  addVariable: (data: FormResponseType) => void;
  variableIds: Array<string>;
  modalOptions?: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE;
  show: boolean;
  isLoading: boolean;
  errors?: FormErrorType | unknown;
  closePanel: () => void;
};

export default function AddNewProposalVariablePanel(props: Props): JSX.Element {
  const {
    show,
    addVariable,
    isLoading,
    errors,
    closePanel,
    modalOptions = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.DEFAULT,
    variableIds,
  } = props;
  const details = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE_DETAILS[modalOptions];

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Add {details.addNewVariableButtonText}</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors as FormErrorType}
          onSubmit={addVariable}
          formSpec={ProposalVariableService.getVariableFormSpec(variableIds)}
        >
          <ProposalVariableFormContainer scope={modalOptions} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
