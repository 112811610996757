import { connect } from 'react-redux';
import UserRegistrationFormFieldItem from './UserRegistrationFormFieldItem';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { PERMISSIONS } from '../../crm/users/UserPermissionService';
import { hasPermission } from '../../crm/users/UsersDataDuck';
import { setEditOfFormField } from './RegistrationFormPageDuck';
import { eventCustomFormFieldsItemsSelectors } from '../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;

  return {
    id,
    formField: eventCustomFormFieldsItemsSelectors(state)[id],
    projectId,
    hasPermissions: {
      [PERMISSIONS.EVENT_FORM_FIELD]: hasPermission(PERMISSIONS.EVENT_FORM_FIELD)(state),
      [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT)(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditFormField: (id: string) => {
      dispatch(setEditOfFormField(dispatch, id));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationFormFieldItem);
