import ClassNames from 'classnames';
import React, { CSSProperties } from 'react';
import * as SVGIcons from './svg-icons';

import styles from './icon.module.css';

export enum ICON_SIZES {
  XS = 'xs',
  SM_XS = 'sm-xs',
  SM = 'sm',
  MD_SM = 'md-sm',
  MD = 'md',
  LG = 'lg',
  XLG = 'xlg',
}

export enum ICON_ACCENT_STYLES {
  NONE = 'none',
  FILL = 'fill',
  STROKE = 'stroke',
}
export type IconNames = keyof typeof SVGIcons;
interface IconProps {
  name: IconNames | string; // keyof SVGIcons
  size?: ICON_SIZES;
  align?: 'default' | 'top' | 'bottom' | 'left';
  alignSize?: 0 | 1 | 2 | 3 | 4;
  className?: string;
  accentStyle?: ICON_ACCENT_STYLES;
}

export function Icon(props: IconProps): React.ReactElement {
  const {
    name,
    size = ICON_SIZES.MD,
    accentStyle = ICON_ACCENT_STYLES.NONE,
    className = '',
    align = 'default',
    alignSize = 0,
    ...elementProps
  } = props;
  const classes = ClassNames(
    className,
    styles.icon,
    styles[`icon--size-${size}`],
    styles[`icon--${name}`],
    styles[`icon--align-${align}-${alignSize}`],
    styles[`icon--accent-style-${accentStyle}`],
  );

  return (
    <span {...elementProps} className={classes}>
      {SVGIcons[name]}
    </span>
  );
}

export enum ICON_FOLDER_STYLES {
  PRIMARY_ALT = 'primary-alt',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DEFAULT = 'default',
}

interface IconFolderProps {
  style?: ICON_FOLDER_STYLES;
  size?: ICON_SIZES;
}

export function IconFolder(props: IconFolderProps): React.ReactElement {
  const { style = ICON_FOLDER_STYLES.PRIMARY, size = ICON_SIZES.MD, ...elementProps } = props;
  const classes = ClassNames(
    styles['icon-folder'],
    styles[`icon-folder--style-${style}`],
    styles[`icon-folder--size-${size}`],
  );

  return (
    <span className={classes}>
      <Icon name="folder" size={size} {...elementProps} />
      {style === ICON_FOLDER_STYLES.PRIMARY_ALT && <Icon name="plus" className={styles[`icon-folder__icon`]} />}
    </span>
  );
}

interface LogoTextProps {
  style?: CSSProperties;
  logoStyle?: 'vertical' | 'horizontal';
}

export function LogoText(props: LogoTextProps): React.ReactElement {
  const { style, logoStyle = 'vertical' } = props;

  return (
    <svg className={styles['logo-text']} style={style} viewBox="0 0 795.73 160.47" xmlns="http://www.w3.org/2000/svg">
      <g fill="#7251f4">
        <path d="m94.28 56.14c9.84 0 18.12 3.31 24.84 9.94s10.08 14.76 10.08 24.4-3.36 17.78-10.08 24.4c-6.72 6.63-15 9.94-24.84 9.94h-17.97v26.45c0 3.95-3.2 7.15-7.15 7.15h-5.86c-3.95 0-7.15-3.2-7.15-7.15v-87.97c0-3.95 3.2-7.16 7.16-7.16h30.98zm0 49.82c4.29 0 7.84-1.48 10.67-4.46 2.82-2.97 4.24-6.65 4.24-11.03s-1.41-8.18-4.24-11.1-6.38-4.38-10.67-4.38h-17.97v30.98h17.97z" />
        <path d="m200.17 154.87-18.6-31.95h-15.27v28.35c0 3.95-3.2 7.16-7.16 7.16h-5.85c-3.95 0-7.16-3.2-7.16-7.16v-87.97c0-3.95 3.2-7.16 7.16-7.16h33.76c9.45 0 17.48 3.31 24.11 9.94 6.62 6.63 9.94 14.61 9.94 23.96 0 6.33-1.8 12.2-5.41 17.61-3.6 5.41-8.38 9.47-14.32 12.2l18.46 31.45c1.85 3.15-.42 7.13-4.08 7.13h-9.39c-2.55 0-4.9-1.35-6.18-3.55zm-33.87-79.87v30.24h20.75c3.8 0 7.06-1.48 9.79-4.46 2.73-2.97 4.09-6.55 4.09-10.74s-1.37-7.74-4.09-10.67c-2.73-2.92-5.99-4.38-9.79-4.38h-20.75z" />
        <path d="m258.62 139.13h35.95c3.95 0 7.16 3.2 7.16 7.16v4.98c0 3.95-3.2 7.16-7.16 7.16h-48.95c-3.95 0-7.16-3.2-7.16-7.16v-87.97c0-3.95 3.2-7.16 7.16-7.16h48.22c3.95 0 7.16 3.2 7.16 7.16v4.98c0 3.95-3.2 7.16-7.16 7.16h-35.22v21.77h31.56c3.95 0 7.16 3.2 7.16 7.16v4.68c0 3.95-3.2 7.16-7.16 7.16h-31.56v22.94z" />
        <path d="m422.14 63.3v87.97c0 3.95-3.2 7.16-7.16 7.16h-5.71c-3.95 0-7.16-3.2-7.16-7.16v-59.18l-28.26 46.49c-.37.6-1.01.98-1.71 1-.76.03-1.47-.36-1.86-1l-28.07-46.23v58.93c0 3.95-3.2 7.16-7.16 7.16h-5.85c-3.95 0-7.16-3.2-7.16-7.16v-87.98c0-3.95 3.2-7.16 7.16-7.16h9.57c2.5 0 4.82 1.3 6.12 3.44l27.24 44.85 27.09-44.84c1.3-2.14 3.62-3.46 6.12-3.46h9.64c3.95 0 7.16 3.2 7.16 7.16z" />
        <path d="m511.05 153.65-4.82-13.64h-42.01l-4.51 13.52c-.97 2.92-3.71 4.89-6.79 4.89h-8.26c-4.08 0-6.94-4.03-5.6-7.89l31.36-89.6c1-2.87 3.71-4.79 6.75-4.79h14.84c3.03 0 5.74 1.91 6.75 4.77l31.61 89.6c1.36 3.86-1.5 7.9-5.59 7.9h-7c-3.03 0-5.74-1.91-6.75-4.77zm-40.26-32.49h28.79l-14.76-41.79z" />
        <path d="m637.07 103.48c3.95 0 7.16 3.2 7.16 7.16v.73c0 14.61-4.68 26.45-14.03 35.5-9.35 9.06-21.43 13.59-36.24 13.59-15.78 0-28.86-5.14-39.23-15.41-10.37-10.28-15.56-22.82-15.56-37.62s5.14-27.52 15.41-37.84c10.28-10.32 23.01-15.49 38.21-15.49 9.54 0 18.26 2.17 26.15 6.5 5.13 2.82 9.52 6.25 13.17 10.28 3.07 3.4 2.26 8.75-1.71 11.03l-4.84 2.79c-2.86 1.65-6.49 1.14-8.75-1.26-2.1-2.24-4.68-4.18-7.74-5.82-4.92-2.63-10.4-3.95-16.44-3.95-9.74 0-17.75 3.19-24.03 9.57s-9.42 14.44-9.42 24.18 3.19 17.61 9.57 23.89 14.83 9.42 25.35 9.42c7.79 0 14.25-1.73 19.36-5.19s8.6-8.21 10.45-14.25h-23.82c-3.95 0-7.16-3.2-7.16-7.16v-3.51c0-3.95 3.2-7.16 7.16-7.16h36.97z" />
        <path d="m670.08 56.14h5.85c3.95 0 7.16 3.2 7.16 7.16v87.97c0 3.95-3.2 7.16-7.16 7.16h-5.85c-3.95 0-7.16-3.2-7.16-7.16v-87.97c0-3.95 3.2-7.16 7.16-7.16z" />
        <path d="m754.97 160.47c-15.39 0-28.1-5.11-38.13-15.34s-15.05-22.84-15.05-37.84 5.02-27.74 15.05-37.92 22.74-15.27 38.13-15.27c9.25 0 17.8 2.17 25.64 6.5 5.24 2.9 9.71 6.49 13.41 10.76 2.91 3.36 1.96 8.57-1.89 10.8l-4.93 2.86c-2.94 1.71-6.7 1.13-8.93-1.44-2.09-2.4-4.6-4.41-7.53-6.03-4.68-2.58-9.94-3.87-15.78-3.87-9.94 0-17.95 3.12-24.03 9.35-6.09 6.23-9.13 14.32-9.13 24.25s3.04 17.88 9.13 24.11 14.1 9.35 24.03 9.35c5.84 0 11.13-1.29 15.85-3.87 2.93-1.6 5.41-3.56 7.46-5.89 2.24-2.55 5.99-3.13 8.92-1.43l4.94 2.86c3.84 2.23 4.8 7.43 1.89 10.79-3.69 4.27-8.14 7.86-13.35 10.76-7.79 4.34-16.36 6.5-25.72 6.5z" />
      </g>
      <rect fill="#ff6550" height="37.29" rx="8.85" width="17.71" x="59.86" />
      <path
        d="m26.65 14.11c-3.46-3.46-9.06-3.46-12.52 0s-3.46 9.06 0 12.52l19.85 19.85c3.46 3.46 9.06 3.46 12.52 0 3.46-3.46 3.46-9.06 0-12.52z"
        fill="#ff6550"
      />
      <rect fill="#ff6550" height="17.71" rx="8.85" width="37.29" y="59.84" />
    </svg>
  );
}

export function Logo(): React.ReactElement {
  return (
    <svg fill="none" viewBox="0 0 512 512" className={ClassNames(styles.logo, styles['logo--size-32'])}>
      <path
        d="m256 512c141.385 0 256-114.615 256-256s-114.615-256-256-256-256 114.615-256 256 114.615 256 256 256z"
        fill="#ff6550"
      />
      <g fill="#fff">
        <path d="m341.27 135.572h-.016c-14.637 0-26.504 11.866-26.504 26.503v58.64c0 14.637 11.867 26.503 26.504 26.503h.016c14.637 0 26.503-11.866 26.503-26.503v-58.64c0-14.637-11.866-26.503-26.503-26.503z" />
        <path d="m177.833 177.816-.012.011c-10.35 10.351-10.35 27.132 0 37.482l59.445 59.445c10.35 10.35 27.131 10.35 37.482 0l.011-.012c10.35-10.35 10.35-27.131 0-37.481l-59.445-59.445c-10.35-10.35-27.131-10.35-37.481 0z" />
        <path d="m135.523 341.239v.016c0 14.637 11.866 26.503 26.504 26.503h58.64c14.637 0 26.503-11.866 26.503-26.503v-.016c0-14.638-11.866-26.504-26.503-26.504h-58.64c-14.638 0-26.504 11.866-26.504 26.504z" />
      </g>
    </svg>
  );
}

export const HTML_ICON = {
  rupee: <span>&#x20b9;</span>,
  check: <span>&#10004;</span>,
  x: <span>&#10008;</span>,
  DOT: <span>&middot;</span>,
  DASH_SMALL: <span>&ndash;</span>,
  ARROW_LEFT: <span>&#x2190;</span>,
  ARROW_RIGHT: <span>&#x2192;</span>,
};

interface IconFromSVGUrlProps {
  url: string; // keyof SVGIcons
  size?: ICON_SIZES;
  className?: string;
}

export function IconFromSVGUrl(props: IconFromSVGUrlProps): JSX.Element {
  const { url, size = ICON_SIZES.MD, className, ...elementProps } = props;
  const classes = ClassNames(className, styles.icon, styles[`icon--size-${size}`]);
  return (
    <svg className={classes}>
      <image xlinkHref={url} className={styles.icon__image} />
    </svg>
  );
}

export function VideoPlayIcon(): JSX.Element {
  return <Icon name="play_circle" size={ICON_SIZES.SM} className={ClassNames(styles['icon--video'])} />;
}

export enum LINKEDIN_ICON_SIZES {
  XS = 'xs',
}

type LinkedinReactionProps = {
  positionLeft: number;
  bgColor?: string;
  animateScale?: boolean;
  iconSrc: string;
  size?: LINKEDIN_ICON_SIZES;
};

export function LinkedinReactionIcon(props: LinkedinReactionProps) {
  const { iconSrc, positionLeft, bgColor = '#fff', animateScale, size = LINKEDIN_ICON_SIZES.XS } = props;

  const classes = ClassNames(styles['linkedin-reaction-icon'], styles[`linkedin-reaction-icon--size-${size}`], {
    [styles['linkedin-reaction-icon--scale']]: animateScale,
  });

  return (
    <div
      style={{
        background: bgColor,
        left: -positionLeft,
      }}
      className={classes}
    >
      <img alt="logo" src={iconSrc} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
    </div>
  );
}
