import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Col,
  Form,
  FormErrorType,
  Row,
  TEXT_BUTTON_STYLES,
  TextButton,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { useState } from 'react';
import SponsorWatermarkFormContainer from './form/SponsorWatermarkFormContainer';

type Props = {
  sponsorCreativeWatermarkId: string;
  close: () => void;
  show: boolean;
  projectId: string;
  sponsorId?: string;
  isLoading: boolean;
  error: FormErrorType;
  updateSponsorCreativeWatermark: (projectId: string, creativeId: string, formResponse) => void;
  clearSponsorModalOptions: () => void;
  deleteSponsorCreativeWatermark: (projectId: string, sponsorId: string, creativeId: string) => void;
};

export default function EditSponsorWatermarkPanel(props: Props) {
  const {
    show,
    close,
    projectId,
    isLoading,
    error,
    sponsorId,
    updateSponsorCreativeWatermark,
    sponsorCreativeWatermarkId,
    clearSponsorModalOptions,
    deleteSponsorCreativeWatermark,
  } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  function handleEdit(formResponse) {
    if (!formResponse?.data?.watermark_folder_ids) return;
    updateSponsorCreativeWatermark(projectId, sponsorCreativeWatermarkId, formResponse);
    clearSponsorModalOptions();
  }

  function handleDelete(deleteId) {
    if (!sponsorId) return;
    setIsDeleting(true);
    deleteSponsorCreativeWatermark(projectId, sponsorId, deleteId);
    clearSponsorModalOptions();
    setIsDeleting(false);
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader
        onClose={() => {
          close();
          clearSponsorModalOptions();
        }}
      >
        <WindowPanelTitle>Edit watermark</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          onSubmit={handleEdit}
          formSpec={{
            sponsor_id: {
              validator: 'required',
            },
            watermark_folder_ids: {
              validator: 'required',
            },
            watermark_opacity: {
              type: 'number',
            },
            watermark_size: {
              type: 'number',
            },
            watermark_remove_on_download: {
              type: 'boolean',
            },
          }}
        >
          <SponsorWatermarkFormContainer
            projectId={projectId}
            sponsorCreativeWatermarkId={sponsorCreativeWatermarkId}
          />
          <Row>
            <TextButton
              onClick={() => handleDelete(sponsorCreativeWatermarkId)}
              isLoading={isDeleting}
              style={TEXT_BUTTON_STYLES.LINK}
            >
              Delete watermark?
            </TextButton>
            <Col rightAlighed size={null}>
              <Button
                style={BUTTON_STYLES.CANCEL}
                onClick={() => {
                  close();
                  clearSponsorModalOptions();
                }}
                size={BUTTON_SIZE.LG}
              >
                Cancel
              </Button>
              <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
