import { LoadingDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { eventPosterAdvocateReportSelector } from '../../crm/events/event-report/EventReportDuck';
import { fetchPosterAdvocatesForProject, posterAdvocatesIdsSelector } from '../PosterAdvocatesDataDuck';
import PosterAdvocateReportLayout from './PosterAdvocateReportLayout';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const advocateIds = posterAdvocatesIdsSelector(state);
  return {
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.POSTER_ADVOCATES.FETCH)(state),
    advocateIds,
    advocatesMetrics: eventPosterAdvocateReportSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      dispatch(fetchPosterAdvocatesForProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PosterAdvocateReportLayout);
