import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export type FolderFilePositionOrderType = {
  image_id: string | null;
  previous_image_id: string | null;
  sort_order?: string;
};

export enum FOLDER_FILE_ORDER_SORTED_BY_VALUES {
  DATE = 'DATE',
  NAME = 'NAME',
  CUSTOM = 'CUSTOM_ORDER',
}

export const HIGHLIGHT_FOLDER_FILE_ORDER_SORTED_BY_OPTIONS = [
  {
    label: 'Date',
    value: FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
  },
  {
    label: 'Name',
    value: FOLDER_FILE_ORDER_SORTED_BY_VALUES.NAME,
  },
  {
    label: 'Custom',
    value: FOLDER_FILE_ORDER_SORTED_BY_VALUES.CUSTOM,
  },
];

export const SELECTION_FOLDER_FILE_ORDER_SORTED_BY_OPTIONS = [
  {
    label: 'Date',
    value: FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
  },
  {
    label: 'Name',
    value: FOLDER_FILE_ORDER_SORTED_BY_VALUES.NAME,
  },
];

export type FolderFileOrderIndexType = {
  oldIndex: number;
  newIndex: number;
};

export type UpdateFilesPositionDataType = {
  indexPosition: FolderFileOrderIndexType;
  projectId: string;
  folderId: string;
  fileIds: Array<string>;
};

type FolderFilePostionOrdersType = {
  image_id_list: Array<string>;
  sort_order: FOLDER_FILE_ORDER_SORTED_BY_VALUES;
};

export function getNewFilesPositionOrders(array, from, to) {
  const arrayCollection = array.slice();
  arrayCollection.splice(to < 0 ? arrayCollection.length + to : to, 0, arrayCollection.splice(from, 1)[0]);

  return arrayCollection;
}

export function fetchFilePositionOrders(projectId: string, folderId: string): Promise<FolderFilePostionOrdersType> {
  const URL = API_URLS.FOLDER_GALLERY.ORDER(projectId, folderId);
  return HttpUtils.get(URL).then((response) => response?.data);
}

export function setFolderFilesPositionOrder(
  projectId: string,
  folderId: string,
  draggingImgData: FolderFilePositionOrderType,
): Promise<FolderFilePostionOrdersType> {
  const URL = API_URLS.FOLDER_GALLERY.ORDER(projectId, folderId);
  return HttpUtils.patch(URL, draggingImgData).then((response) => response?.data);
}
