import React, { useRef, useState } from 'react';
import {
  Avatar,
  AVATAR_SIZES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogTitle,
  Icon,
  ICON_SIZES,
  ListItem,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_LABEL_STYLES,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroup,
  TextGroupLabel,
  TextGroupValue,
} from '@premagic/myne';
import { ProductFeedbackService } from '@premagic/core';

type MessageButtonIconProps = {
  id: ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE;
  name: string;
  question: string;
  feedbackData: Record<string, string>;
};

function MessageButtonIcon(props: MessageButtonIconProps) {
  const { id, name, feedbackData, question } = props;

  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const $button = useRef(null);

  const handleHover = () => {
    setShowMessageDialog(true);
  };

  const handleMouseLeave = () => {
    setShowMessageDialog(false);
  };

  const message = feedbackData?.[id];

  if (!message) return null;

  return (
    <Row>
      <Text muted>{name}</Text>
      <Space />
      <Button
        disabled={!message}
        ref={$button}
        style={BUTTON_STYLES.RESET}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        <Color shade={COLOR_SHADES.DARK} inline>
          <Icon name="message_square" size={ICON_SIZES.SM} />
        </Color>
      </Button>
      {showMessageDialog && (
        <Dialog
          target={$button.current}
          placement={DIALOG_PLACEMENT.RIGHT}
          show={showMessageDialog}
          onClose={() => setShowMessageDialog(false)}
        >
          <DialogTitle>{question}</DialogTitle>
          <Space vertical size={2} />
          <Text>{message}</Text>
        </Dialog>
      )}
    </Row>
  );
}

type UserAvatarProps = {
  username: string;
  email: string;
};

function UserAvatar(props: UserAvatarProps) {
  const { email, username } = props;

  const userImageAndDetailsComponent = (
    <Space size={2}>
      <Space size={2}>
        <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
          {username}
        </Text>
        <Space vertical size={1} />
        <SimpleLink href={`mailto:${email}`} isExternalLink={false}>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER}>
              <Icon name="mail" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={COLOR_SHADES.DARKER}>{email || '(blank)'}</Text>
          </Row>
        </SimpleLink>
      </Space>
    </Space>
  );
  return (
    <Avatar
      size={AVATAR_SIZES.LG}
      user={{
        fullName: username,
      }}
      dialogContent={userImageAndDetailsComponent}
    />
  );
}

type DisplayRatingProps = {
  id: ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE;
  name: string;
  feedbackData: Record<string, string>;
};

function DisplayRating(props: DisplayRatingProps) {
  const { id, name, feedbackData } = props;

  const rating = feedbackData?.[id];

  if (rating === '0') {
    return (
      <Row>
        <Text muted>{`${name}:`}</Text>
        <Space size={2} />
        <Text>Unrated</Text>
      </Row>
    );
  }

  return (
    <Row>
      <Text muted>{`${name}:`}</Text>
      <Space size={2} />
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>{`${feedbackData?.[id]}/5`}</Text>
    </Row>
  );
}

type Props = {
  projectId: string;
  eventFeedbackItem?: ProductFeedbackService.ProductFeedbackType;
};

export default function EventFeedbackListItem(props: Props): JSX.Element | null {
  const { projectId, eventFeedbackItem } = props;

  if (!eventFeedbackItem) return null;

  const { name: username, phone, email, source: guestGalleryLink, data, feedback_type: type } = eventFeedbackItem;

  const feedbackData = (function getFeedbackData() {
    try {
      const parsedRecord = JSON.parse(data);
      return parsedRecord;
    } catch (error) {
      return null;
    }
  })();
  if (type === ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.PRODUCT_USAGE)
    return (
      <ListItem>
        <Col size={1}>
          <UserAvatar email={email} username={username} />
        </Col>
        <Space size={2} />
        <Col size={2}>
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
            {username || 'Attendee'}
          </Text>
        </Col>
        <Col size={1}>
          <Button
            link={guestGalleryLink}
            size={BUTTON_SIZE.SM}
            style={BUTTON_STYLES.LINK}
            showExternalLinkIcon
            isExternalLink
          >
            Gallery
          </Button>
        </Col>
        <Space />
        <Col size={3}>
          <Row columnDirection>
            {/* Later if we want to refactor this, we can bring the eventFeedbackService from highlight to core and use that */}
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.OVERALL_EXPERIENCE}
              name="Overall experience"
              feedbackData={feedbackData}
            />
            <Space size={1} />
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.KEYNOTE_SPEAKERS}
              name="Keynote speakers"
              feedbackData={feedbackData}
            />
            <Space size={1} />
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.BREAKOUT_SESSIONS}
              name="Breakout sessions"
              feedbackData={feedbackData}
            />
            <Space size={1} />
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.NETWORKING_OPPORTUNITIES}
              name="Networking opportunities"
              feedbackData={feedbackData}
            />
            <Space size={1} />
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.VENUE_AND_LOGISTICS}
              name="Venue and logistics"
              feedbackData={feedbackData}
            />
            <Space size={1} />
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.PHOTO_QUALITY}
              name="Photo quality"
              feedbackData={feedbackData}
            />
            <Space size={1} />
            <DisplayRating
              id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.PHOTO_ESSENCE}
              name="Photos essence"
              feedbackData={feedbackData}
            />
          </Row>
        </Col>

        <Col rightAlighed size={3}>
          <MessageButtonIcon
            id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.PHOTO_QUALITY_DESCRIPTION_1}
            name="Photo quality"
            question="Were there any specific types of photos you felt were missing or could have been better represented?"
            feedbackData={feedbackData}
          />
          <Space size={1} />
          <MessageButtonIcon
            id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.PHOTO_QUALITY_DESCRIPTION_2}
            name="Photo quality"
            question="Do you have any suggestions for improving the photography and visual content for future conferences?"
            feedbackData={feedbackData}
          />
          <Space size={1} />
          <MessageButtonIcon
            id={ProductFeedbackService.EVENT_FEEDBACK_QUESTION_TYPE.ADDITIONAL_COMMENTS}
            name="Additional comments"
            question="Please provide any additional comments, suggestions, or ideas to help us improve."
            feedbackData={feedbackData}
          />
        </Col>
      </ListItem>
    );
  if (type === ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.INTERESTED_LEAD) {
    return (
      <ListItem>
        <Col size={3}>
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
            {username || 'Attendee'} - <Text>{feedbackData?.scope}</Text>
          </Text>
          <TextGroupValue type={TEXT_GROUP_TYPES.TEL} value={phone} />
          <TextGroupValue type={TEXT_GROUP_TYPES.EMAIL} value={email} />
          <Button
            link={guestGalleryLink}
            size={BUTTON_SIZE.SM}
            style={BUTTON_STYLES.LINK}
            showExternalLinkIcon
            isExternalLink
          >
            Gallery
          </Button>
        </Col>
        <Space />
        <Col size={6}>
          <TextGroup>
            <TextGroupLabel>Company Name</TextGroupLabel>
            <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={feedbackData?.company_name} />
          </TextGroup>
          <TextGroup>
            <TextGroupLabel>Event Name</TextGroupLabel>
            <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={feedbackData?.event_name} />
          </TextGroup>
          {/* <TextGroup> */}
          {/*  <TextGroupLabel>Event date</TextGroupLabel> */}
          {/*  <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={feedbackData?.event_date} /> */}
          {/* </TextGroup> */}
          <TextGroup>
            <TextGroupLabel>Message</TextGroupLabel>
            <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={feedbackData?.message} />
          </TextGroup>
        </Col>
      </ListItem>
    );
  }
  return <Text>Type not handled</Text>;
}
