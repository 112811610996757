import { PosterWidgetType } from '@premagic/poster-creator';
import { connect } from 'react-redux';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';
import { selectedEventPosterWidgetSelector } from '../EventPosterCreatorDuck';
import EventPosterCreatorWidgetEditor from './EventPosterCreatorWidgetEditor';

function mapStateToProps(state, ownProps: { projectId: string; posterId: string }) {
  const { projectId, posterId } = ownProps;
  const poster = eventPostersSelector(state)[posterId];
  const widgetId = selectedEventPosterWidgetSelector(state);
  const selectedWidget = widgetId ? (poster?.widgets?.[widgetId] as PosterWidgetType) : undefined;

  return { projectId, posterId, widgetId, widget: selectedWidget };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterCreatorWidgetEditor);
