import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';

import {
  filesUploadStatusInFolderSelector,
  folderTypeSelector,
  getFolderImagesAndOrder,
} from '../AccountFoldersDataDuck';
import FolderFilesUploadStatus from './FolderFilesUploadStatus';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    projectId: string;
  },
) {
  const { folderId, projectId } = ownProps;

  return {
    folderId,
    projectId,
    folderType: folderTypeSelector(folderId)(state) || FolderService.FOLDER_TYPES.HIGHLIGHT,
    filesUploadStats: filesUploadStatusInFolderSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFolder: (projectId, folderId, folderType) => {
      dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, folderType));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderFilesUploadStatus);
