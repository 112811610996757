import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  DialogTitle,
  Space,
  Text,
} from '@premagic/myne';
import { EventTrackerService } from '@premagic/core';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type Props = {
  projectId: string;
  folderId: string;
  isLoading: boolean;
  show: boolean;
  isFolderBlocked: boolean;
  blockFolder: (projectId: string, folderId: string, value: boolean) => void;
  toggleShowBlockFolder: (show: boolean) => void;
};

export default function FolderBlockDialog(props: Props): JSX.Element | null {
  const { isLoading, projectId, folderId, toggleShowBlockFolder, show, isFolderBlocked, blockFolder } = props;

  function handleBlockFolder() {
    blockFolder(projectId, folderId, true);
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.BLOCK, {
      is_blocked: true,
    });
  }

  function handleUnblockFolder() {
    blockFolder(projectId, folderId, false);
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.BLOCK, {
      is_blocked: false,
    });
  }

  if (!show) return null;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.EVENTS_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleShowBlockFolder(false)}
    >
      {!isFolderBlocked ? (
        <>
          <DialogTitle>Block this folder?</DialogTitle>

          <div>
            <Text>This will hide the folder from the gallery</Text>
            <Space vertical size={2} />
            <Text>Are you sure you want to block this folder?</Text>
            <DialogFooter>
              <Button style={BUTTON_STYLES.LINK} onClick={() => toggleShowBlockFolder(false)} type="button">
                Cancel
              </Button>
              <Button isLoading={isLoading} style={BUTTON_STYLES.DANGER} onClick={handleBlockFolder}>
                Block Folder
              </Button>
            </DialogFooter>
          </div>
        </>
      ) : (
        <>
          <DialogTitle>Unblock this folder?</DialogTitle>
          <div>
            <Text>This will show the folder on the gallery</Text>
            <Space vertical size={2} />
            <Text>Are you sure you want to unblock this folder?</Text>
            <DialogFooter>
              <Button style={BUTTON_STYLES.LINK} onClick={() => toggleShowBlockFolder(false)} type="button">
                Cancel
              </Button>
              <Button isLoading={isLoading} style={BUTTON_STYLES.SECONDARY} onClick={handleUnblockFolder}>
                Unblock Folder
              </Button>
            </DialogFooter>
          </div>
        </>
      )}
    </Dialog>
  );
}
