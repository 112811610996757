import { connect } from 'react-redux';
import FolderImageFullView from './FolderImageFullView';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { filesSelector } from '../../../images/FilesDataDuck';
import { appWidthSelector } from '../../../AppDuck';

type OwnPropsType = {
  id: string;
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  const { id } = ownProps;
  const file = filesSelector(state)[id];
  return {
    file,
    appWidth: appWidthSelector(state),
    isDeleting: isLoadingSelector(LOADINGS.FILE.DELETE)(state),
  };
}

export default connect(mapStateToProps)(FolderImageFullView);
