import React from 'react';
import styled, { css } from 'styled-components';
import { Position, Space } from '@premagic/myne';
import { ALBUM_TEMPLATES } from '../templates/AlbumPageTemplateService';
import AlbumCreatorPageInfo from '../AlbumCreatorPageInfo';

type Props = {
  templateId: number;
  pageNo: number;
  isMobileUp: boolean;
  totalPages: number;
};

const AlbumCreatorPage = styled.div`
  position: relative;
  margin: 20px auto;
  width: 100%;
  max-width: 1320px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const AlbumCreatorPageShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 120px;
  transform: translate(-100%, 0);
  pointer-events: none;
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAAABCAYAAADQO/BKAAAASElEQVQYlZ2NSQrAMAwDR0n//2X3kBpMkEOoQGjBiyJCLJxUTf7bOV+VJifC+KrR5Fu6PYxHkoDxMf0s3TQcwNPoPrffyh96AcOpHU0esqcJAAAAAElFTkSuQmCC');
  ${(props) =>
    props.right &&
    css`
      width: 48px;
      transform: translate(0, 0);
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJEAAAABCAYAAADevkxgAAAAj0lEQVQokZVPQQqEMBBL2o61UgqC4KVHv+P/f9PsYVF2ZdlqIAyTBCZDADsA4Q198Lr32Dpa+6UNw3DcaSS/siRbjBEkRfL0nXMNgHLOIinn3DnNrJVS4L1XCEEhBJmZzEzLsmAcR03TpJyzSima51nruiqlxFqrAGDbtuP/A0QfdzJPce1xx3ui39X+dnkBlW9HAKd4Q+sAAAAASUVORK5CYII=');
    `}
`;

export default function AlbumSinglePage(props: Props) {
  const { templateId, pageNo, isMobileUp, totalPages } = props;

  const TemplateComponent = ALBUM_TEMPLATES[templateId].component;

  return (
    <div>
      <AlbumCreatorPage>
        <Space size={isMobileUp ? 8 : 6}>
          <TemplateComponent pageNo={pageNo} readOnly />
        </Space>
        <AlbumCreatorPageShadow />
        <AlbumCreatorPageShadow right />
        <Position align="bottom-right">
          <AlbumCreatorPageInfo pageNo={pageNo} totalPages={totalPages} />
        </Position>
      </AlbumCreatorPage>
    </div>
  );
}
