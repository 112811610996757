type EnvType = {
  NODE_ENV: 'production' | 'development';
  NX_PUBLIC_ENV: 'production' | 'staging';
  NX_PUBLIC_APP_VERSION: string;
  NX_PUBLIC_DEPLOY_URL: string;
  NX_PUBLIC_CLIENT_APP__AWS_COGNITO_ID: string;
  NX_PUBLIC_CLIENT_APP__AWS_COGNITO_SIGNOUT_URL: string;
  NX_PUBLIC_CLIENT_APP__AWS_COGNITO_CALLBACK_URL: string;
  NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_ID: string;
  NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_SIGNOUT_URL: string;
  NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_CALLBACK_URL: string;
  NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_USER_POOL_ID: string;
  NX_PUBLIC_PHOTOGRAPHER__AWS_REGION: string;
  NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_IDENTITY_POOL_ID: string;
  NX_PUBLIC_API_ROOT_URL: 'https://api.premagic.com' | 'https://app.dev-premagic.click' | 'http://localhost';
  NX_PUBLIC_IMAGE_STORE: 'https://asts.premagic.com/' | 'http://localhost/';
  NX_PUBLIC_LIVE_EVENTS_KEY: string;
  NX_PUBLIC_LIVE_EVENTS_URL: string;
};

export function getEnvValues(): EnvType {
  return process.env as EnvType;
}

export function isProd(): boolean {
  return getEnvValues().NX_PUBLIC_ENV === 'production';
}

export function getAppVersion(): string {
  return getEnvValues().NX_PUBLIC_APP_VERSION || 'not-set';
}
