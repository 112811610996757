import { connect } from 'react-redux';

import ClientWebsitePhotoGridInput from './ClientWebsitePhotoGridItem';
import { deletePhotos } from '../ClientWebsiteDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

function mapStateToProps(state, ownProps) {
  const { clientWebsite, imageUploadApiURL, required, info, title, defaultValue } = ownProps;

  return {
    clientWebsite,
    imageUploadApiURL,
    required,
    info,
    title,
    defaultValue,
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deletePhotos: (id: string, projectId: string, data) => {
      dispatch(deletePhotos(dispatch, id, projectId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsitePhotoGridInput);
