import React from 'react';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  List,
  ListItem,
  Row,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Space,
  InnerPageContentMainCol,
  InnerPageHeader,
} from '@premagic/myne';
import { ClientSettingsService } from '@premagic/core';
import APP_URLS from '../../../services/AppRouteURLService';
import PremiumFeatureIcon from '../PremiumFeatureIcon';

type Props = {
  savePreferences: (data) => void;
  isEnabled: boolean;
  hasLibraryFeature: boolean;
  isLoading: boolean;
};

export default function DAMLibrarySettingsPage(props: Props) {
  const { savePreferences, isLoading, isEnabled, hasLibraryFeature } = props;

  return (
    <InnerPage>
      <InnerPageHeader iconName="book_open" title="Library Settings" backTo={APP_URLS.SETTINGS.INDEX}>
        Library Settings
      </InnerPageHeader>
      <InnerPageContent hasInnerCols isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <List>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Enable Library
                    {!hasLibraryFeature && <PremiumFeatureIcon module="Library" />}
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>
                    Search, View and manage all your photos across events in one place.
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {isEnabled ? 'No' : 'Yes'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isLoading}
                      name=""
                      checked={isEnabled}
                      disabled={!hasLibraryFeature}
                      onChange={(value) => {
                        // if (!hasLibraryFeature) return; || uncomment when feature is rolled out
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_DAM_LIBRARY_ENABLE]: value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
            </List>
          </Card>
          <Space vertical size={8} />
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
