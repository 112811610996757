import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';
import { RouterService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';

import CRMDashboardWorkflowStages from './CRMDashboardWorkflowStages';
import {
  eventsCountByStatusSelector,
  fetchCountByEventStatus,
} from '../../calendar-and-event-page/events-list/EventsListEventsDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { filters: string; view: 'list' | 'calendar' }) {
  const { filters, view } = ownProps;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
  return {
    filters: filterObject,
    view,
    countByStatus: eventsCountByStatusSelector(state),
    isLoadingCounts: LoadingDuck.isLoadingSelector(LOADINGS.CRM.LIST_EVENTS_COUNT_FETCH, true)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url);
    },
    fetchCountByEventStatus: () => {
      dispatch(fetchCountByEventStatus(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMDashboardWorkflowStages);
