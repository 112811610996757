import React from 'react';

import { Button, BUTTON_STYLES, Icon, ICON_SIZES, Link, Space, Tooltip } from '@premagic/myne';
import { EventTrackerService, FolderService } from '@premagic/core';
import { StringUtils, ArrayUtils } from '@premagic/utils';
import SharePanelContainer from '../../../folder-share/SharePanelContainer';

function getDisabledMessage(options: {
  folderIdsPendingToShare: Array<string>;
  folderIdsPendingToShareWithPhotos: Array<string>;
}) {
  const { folderIdsPendingToShare, folderIdsPendingToShareWithPhotos } = options;
  const pendingToShare = folderIdsPendingToShare.length;
  const pendingToShareWithPhotos = folderIdsPendingToShareWithPhotos.length;

  if (pendingToShare > 0 && pendingToShareWithPhotos === 0) {
    return `There are no photos in ${pendingToShare} ${StringUtils.pluralize(
      'folder',
      pendingToShare,
    )}! Upload photos to share.`;
  }
  return 'Nothing to share, Upload photos to share';
}

type Props = {
  folderType: FolderService.FOLDER_TYPES;
  projectId: string;
  showSharePanel: (folderIds: Array<string>) => void;
  allFolders: Record<string, FolderService.FolderType>;
  folderIdsPendingToShare: Array<string>;
  folderIds: Array<string>;
  clients?: Array<{
    name: string;
    phone_number: string;
    email: string;
  }>;
};

export default function FolderShareButton(props: Props): React.ReactElement {
  const { projectId, showSharePanel, allFolders, clients, folderType, folderIdsPendingToShare, folderIds } = props;
  const folderIdsPendingToShareWithPhotos = folderIdsPendingToShare.filter(
    (id) => allFolders[id]?.stats?.total_image || allFolders[id]?.stats?.total_video,
  );

  const folderWithShareUrl = folderIds.find((id) => allFolders[id]?.share_url);
  const shareUrl = folderWithShareUrl ? allFolders[folderWithShareUrl].share_url : undefined;
  const showPreviewLink = shareUrl && folderType === FolderService.FOLDER_TYPES.HIGHLIGHT;
  const previewUrl = shareUrl;

  const clientShareText = clients?.map(
    (clientData) => `${clientData.name} via ${clientData.email} & ${clientData.phone_number}`,
  );

  if (folderIdsPendingToShare.length === 0) {
    return (
      <>
        <Tooltip
          message={clientShareText ? `Shared with ${ArrayUtils.listify(clientShareText)}` : 'Shared with your client'}
          placement="left"
        >
          <Button style={BUTTON_STYLES.TERTIARY} isExternalLink link={shareUrl}>
            <Icon name="external_link" size={ICON_SIZES.SM} />
            <Space size={2} />
            Open
          </Button>
        </Tooltip>
        <SharePanelContainer projectId={projectId} />
      </>
    );
  }

  return (
    <>
      {showPreviewLink && (
        <>
          <Button
            style={BUTTON_STYLES.CANCEL}
            link={previewUrl}
            isExternalLink
            onClick={() => EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.PREVIEW)}
          >
            Preview
            <Space size={2} />
            <Icon name="external_link" size={ICON_SIZES.SM} />
          </Button>
          <Space />
        </>
      )}

      <Button
        style={BUTTON_STYLES.PRIMARY}
        onClick={() => showSharePanel(folderIdsPendingToShareWithPhotos)}
        disabled={!folderIdsPendingToShareWithPhotos.length}
        disabledMessage={getDisabledMessage({
          folderIdsPendingToShare,
          folderIdsPendingToShareWithPhotos,
        })}
      >
        Share
        <Space size={2} />
        <Icon name="share" size={ICON_SIZES.SM} />
      </Button>
      <SharePanelContainer projectId={projectId} />
    </>
  );
}
