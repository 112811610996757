import React, { useEffect } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { EventPosterService, ProjectMetaService } from '@premagic/core';
import SocialShareMessagesContainer from '../../../projects/social-media-settings/SocialShareMessagesContainer';

import { PERMISSIONS } from '../../users/UserPermissionService';
import MESSAGES from '../../../../../../common/Messages';
import EventPosterListContainer from '../../event-posters/EventPosterListContainer';

type Props = {
  projectId?: string;
  eventId: string;
  focusId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_MARKETING_MANAGEMENT]: boolean;
  };
  initData: (projectId: string) => void;
  hasFeature: boolean;
  showAddNewEventPoster: () => void;
};

export default function EventAdvocacyLayout(props: Props): React.ReactElement {
  const { projectId, hasPermissions, initData, eventId, hasFeature, showAddNewEventPoster, focusId } = props;

  useEffect(() => {
    if (projectId) initData(projectId);
  }, [initData, projectId]);

  if (!hasPermissions[PERMISSIONS.EVENT_MARKETING_MANAGEMENT]) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.PERMISSION[PERMISSIONS.EVENT_MARKETING_MANAGEMENT]}</Text>
      </InnerPageContentMainCol>
    );
  }

  if (!hasFeature) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.EVENT_MARKETING.UPGRADE}</Text>
      </InnerPageContentMainCol>
    );
  }

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;

  return (
    <>
      <InnerPageContentMainCol hasSidebar>
        <Row>
          <Col rightAlighed size={null}>
            <Button style={BUTTON_STYLES.PRIMARY} onClick={showAddNewEventPoster} size={BUTTON_SIZE.SM}>
              <Icon name="plus" size={ICON_SIZES.SM} /> Poster
            </Button>
          </Col>
        </Row>
        <Space vertical />
        <Card>
          <EventPosterListContainer
            eventId={eventId}
            projectId={projectId}
            focusId={focusId}
            type={EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT}
          />
        </Card>
        <Space vertical size={12} />
        <SocialShareMessagesContainer
          projectId={projectId}
          scope={ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE.POSTER}
        />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <Space />
      </InnerPageContentSidebar>
    </>
  );
}
