import { DAM_LIBRARY_FILTER_KEYS } from './DAMLibraryFilterTypes';
import DAMLibraryEndDateFilterSelectorContainer from './date-filter/DAMLibraryEndDateFilterSelectorContainer';
import DAMLibraryStartDateFilterSelectorContainer from './date-filter/DAMLibraryStartDateFilterSelectorContainer';
import DAMLibraryKeyPeopleFilterSelectorContainer from './key-people-filter/DAMLibraryKeyPeopleFilterSelectorContainer';
import DAMLibraryPeopleFilterSelectorWrapperContainer from './people-face-filter/DAMLibraryPeopleFilterSelectorWrapperContainer';
import DAMLibraryProjectsFilterSelectorContainer from './project-filter/DAMLibraryProjectsFilterSelectorContainer';
import DAMLibrarySortByFilterSelectorContainer from './sort-by/DAMLibrarySortByFilterSelectorContainer';
import DAMLibraryTagsFilterSelectorContainer from './tag-filter/DAMLibraryTagsFilterSelectorContainer';

const DAMLibraryItemsDialogContent: Record<string, any> = {
  [DAM_LIBRARY_FILTER_KEYS.PROJECT]: DAMLibraryProjectsFilterSelectorContainer,
  [DAM_LIBRARY_FILTER_KEYS.FACE_ID]: DAMLibraryPeopleFilterSelectorWrapperContainer,
  [DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE]: DAMLibraryKeyPeopleFilterSelectorContainer,
  [DAM_LIBRARY_FILTER_KEYS.TAGS]: DAMLibraryTagsFilterSelectorContainer,
  [DAM_LIBRARY_FILTER_KEYS.DATE_FROM]: DAMLibraryStartDateFilterSelectorContainer,
  [DAM_LIBRARY_FILTER_KEYS.DATE_END]: DAMLibraryEndDateFilterSelectorContainer,
  [DAM_LIBRARY_FILTER_KEYS.SORT_BY]: DAMLibrarySortByFilterSelectorContainer,
};

export default DAMLibraryItemsDialogContent;
