import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  List,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Col,
  RealCheckbox,
  Form,
} from '@premagic/myne';
import { BrowserUrlUtils, LocalCacheUtils } from '@premagic/utils';
import { EventTrackerService } from '@premagic/core';
import PaymentListItemContainer from '../payment-item/PaymentListItemContainer';
import EventPaymentStatusLabel from '../../events/details/EventPaymentStatusLabel';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';
import RemainingClientPaymentsInfoContainer from './RemainingClientPaymentsInfoContainer';
import APP_URLS from '../../../../services/AppRouteURLService';
import { CRMPaymentType } from '../CRMPaymentService';
import FolderShareChannelInfoContainer from '../../../projects/folders/share/FolderShareChannelInfoContainer';

type ClientPaymentsTableProps = {
  paymentIds: Array<string>;
  zeroMessage: string;
};

export function ClientPaymentsTable(props: ClientPaymentsTableProps) {
  const { paymentIds, zeroMessage } = props;
  if (paymentIds.length === 0) {
    return (
      <div>
        <Space vertical size={2} />
        <Row>
          <Space />
          <Text muted block>
            {zeroMessage}
          </Text>
        </Row>
      </div>
    );
  }

  return (
    <List>
      {paymentIds.map((id) => (
        <PaymentListItemContainer paymentId={id} key={id} />
      ))}
    </List>
  );
}

type Props = {
  pendingPaymentIds: Array<string>;
  settledPaymentIds: Array<string>;
  totalPending: number;
  totalSettled: number;
  showAddPanel: () => void;
  markASPaidConfirm: (
    modalOptions: { paymentId: string; eventId: string; data: { data: CRMPaymentType } },
    notifyClient: boolean,
  ) => void;
  closeModal: () => void;
  eventId: string;
  showMarkAsPaidConfirmModal: boolean;
  modalOptions: { paymentId: string; eventId: string; data: { data: CRMPaymentType } };
};

export default function ClientPayments(props: Props): JSX.Element {
  const {
    pendingPaymentIds,
    settledPaymentIds,
    totalPending,
    totalSettled,
    showAddPanel,
    eventId,
    showMarkAsPaidConfirmModal,
    markASPaidConfirm,
    closeModal,
    modalOptions,
  } = props;
  const invoicePage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT_INVOICE, {
    eventId,
  });

  function onSubmit(data) {
    markASPaidConfirm(modalOptions, data.data.notify_client);
    LocalCacheUtils.setCache('enableMarkPaymentAsPaidNotification', data.data.notify_client);
  }

  const markPaymentAsPaidNotificationDefaultValue =
    LocalCacheUtils.getCache('enableMarkPaymentAsPaidNotification') || false;
  return (
    <Card>
      <Modal show={showMarkAsPaidConfirmModal} close={() => {}} size={MODAL_SIZES.SM}>
        <ModalTitle>Confirm mark payment as paid ?</ModalTitle>
        <Space size={2} vertical />
        <Form onSubmit={onSubmit} formSpec={{ notify_client: { type: 'boolean' } }}>
          <RealCheckbox name="notify_client" defaultChecked={markPaymentAsPaidNotificationDefaultValue as boolean}>
            <FolderShareChannelInfoContainer action="Notify client" />
          </RealCheckbox>
          <Space size={8} vertical />
          <Row>
            <Col size={null} rightAlighed>
              <Button style={BUTTON_STYLES.CANCEL} onClick={closeModal}>
                Cancel
              </Button>
              <Button style={BUTTON_STYLES.PRIMARY}>Mark as paid</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Space>
        <CardHeader hasPadding={false}>
          <CardHeaderTitle>
            Client Payments <Space size={2} /> <EventPaymentStatusLabel isSettled={totalPending <= 0} />
          </CardHeaderTitle>
          <CardHeaderActions>
            <Button
              style={BUTTON_STYLES.LINK}
              link={invoicePage}
              onClick={() => {
                EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PAYMENT.SIMPLE_INVOICE_CLICK, {
                  eventId,
                });
              }}
            >
              <Icon size={ICON_SIZES.SM} name="printer" /> <Space size={1} />
              Invoice
            </Button>
            <Button style={BUTTON_STYLES.LINK} onClick={showAddPanel}>
              <Icon size={ICON_SIZES.SM} name="plus" />
              Add
            </Button>
          </CardHeaderActions>
        </CardHeader>
      </Space>
      <Row>
        <Space />
        <Text color={COLOR_SHADES.DARK} boldness={TEXT_BOLDNESS.BOLD}>
          UN-PAID <Text color={COLOR_SHADES.LIGHT}>{HTML_ICON.DOT}</Text>{' '}
          <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
            <AccountCurrencyContainer amount={totalPending} />
          </Text>
        </Text>
      </Row>
      <ClientPaymentsTable paymentIds={pendingPaymentIds} zeroMessage="You have not added any un-paid payments" />
      <RemainingClientPaymentsInfoContainer />
      <Space size={8} vertical />
      <Row>
        <Space />
        <Text color={COLOR_SHADES.DARK} boldness={TEXT_BOLDNESS.BOLD}>
          PAID <Text color={COLOR_SHADES.LIGHT}>{HTML_ICON.DOT}</Text>{' '}
          <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
            <AccountCurrencyContainer amount={totalSettled} />
          </Text>
        </Text>
      </Row>
      <ClientPaymentsTable paymentIds={settledPaymentIds} zeroMessage="You have not added any payments" />
      <Space vertical />
    </Card>
  );
}
