import { ClientWebsiteService } from '@premagic/core';
import { isArray } from 'lodash';
import {
  Col,
  FormGroup,
  FormLabel,
  Input,
  Row,
  Space,
  TEXT_GROUP_TYPES,
  Text,
  TextGroupLabel,
  TextGroupValue,
} from '@premagic/myne';

interface MetricsValuesType {
  labels: Array<string>;
  values: Array<string>;
}

type Props = {
  defaultValue: MetricsValuesType;
};

type FormItemProps = {
  label?: string;
  value?: string;
};

function FormItem(props: FormItemProps) {
  const { label = '', value = '' } = props;
  return (
    <Row>
      <Col>
        <FormGroup>
          <FormLabel>Label</FormLabel>
          <Input defaultValue={label} name="labels" />
        </FormGroup>
      </Col>
      <Space size={8} />
      <Col>
        <FormGroup>
          <FormLabel>value</FormLabel>
          <Input defaultValue={value} name="values" />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default function ClientWebsiteMetricsFormItem(props: Props) {
  const { defaultValue } = props;
  console.log(props);
  return (
    <div>
      <Space vertical size={4} />
      {Array(4)
        .fill('')
        .map((item, index) => (
          <FormItem
            label={defaultValue && 'labels' in defaultValue ? defaultValue?.labels[index] : ''}
            value={defaultValue && 'values' in defaultValue ? defaultValue?.values[index] : ''}
          />
        ))}
    </div>
  );
}

type ClientWebsiteMetricsFormItemValuePorops = {
  value: any;
};

export function ClientWebsiteMetricsFormItemValue(props: ClientWebsiteMetricsFormItemValuePorops) {
  const { value } = props;
  const { labels, values } = value;
  if (!isArray(labels)) return null;
  return (
    <div>
      {labels.map((item, index) => (
        <>
          <Row>
            <TextGroupLabel>{item}</TextGroupLabel>
            <Text>{values[index]}</Text>
          </Row>
          <Space vertical size={4} />
        </>
      ))}
    </div>
  );
}
