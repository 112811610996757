import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  eventProjectSelectorSelector,
  folderIdsWithFolderTypeForProjectSelector,
} from '../../../projects/AccountProjectsDataDuck';
import { fetchFolder } from '../../../projects/folders/AccountFoldersDataDuck';
import { addSponsorOrBrandingFolder } from '../../../projects/sponsor/SponsorAndBrandingDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import EventExhibitorLayout from './EventExhibitorLayout';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  const sponsorFolderIds = projectId
    ? folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.SPONSOR)(state)
    : [];

  return {
    eventId,
    projectId,
    hasPermissions: {
      [PERMISSIONS.EVENT_EXHIBITOR]: hasPermission(PERMISSIONS.EVENT_EXHIBITOR)(state),
    },
    hasEventExhibitorFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_EXHIBITOR,
    )(state),
    sponsorFolderId: sponsorFolderIds[0],
    isCreatingBrandingFolder: isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFolderFiles: (projectId, folderId) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
    createFolder: (projectId) => {
      dispatch(addSponsorOrBrandingFolder(dispatch, projectId, FolderService.FOLDER_TYPES.SPONSOR));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventExhibitorLayout);
