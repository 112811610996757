import React, { useEffect, useState } from 'react';
import { Button, Color, COLOR_SHADES, Icon, Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import { ClientSettingsService, CompanyService, PricingPlanService, SubscriptionService } from '@premagic/core';
import { UserType } from '../../crm/users/UsersService';

import { showPaymentModal } from '../pricing-plan/PricingPlanService';
import { CapturePaymentDataType } from '../company/CompanyService';
import { toastMessage } from '../../../reducers/ToastStore';
import { ChangeSubscription } from './ChangeSubscription';
import { SelectPlanManual } from './SelectPlanManual';

const SHOW_PRICE = false;
type Props = {
  company: CompanyService.CompanyType;
  variant: PricingPlanService.PricingPlanVariant;
  variantId: PricingPlanService.PricingPlanVariantId;
  planId: string;
  createSubscription: (planId: string, variantId: PricingPlanService.PricingPlanVariantId) => void;
  isLoading: boolean;
  purchaseOrder?: { subscriptionId: string };
  savePaymentInformation: (options: CapturePaymentDataType) => void;
  resetOrder: () => void;
  subscription?: SubscriptionService.SubscriptionType;
  clientSettings: ClientSettingsService.ClientSettingsType;
  requestingUser?: UserType;
};

export default function BuyPlanButton(props: Props): JSX.Element {
  const {
    company,
    savePaymentInformation,
    planId,
    variant,
    isLoading,
    createSubscription,
    purchaseOrder,
    variantId,
    resetOrder,
    subscription,
    clientSettings,
    requestingUser,
  } = props;

  const userInActiveSubscription = subscription?.status === SubscriptionService.SUBSCRIPTION_STATUS.ACTIVE;

  const { plan_id: currentSubscriptionPlanId, variant_id: currentSubscriptionVariantId } = subscription || {};
  const isUserInCurrentPlan = currentSubscriptionPlanId === planId && variantId === currentSubscriptionVariantId;
  const [hasClickedOnBuy, setUserHasClickedOnBuy] = useState(false);

  function handleCreatingSubscription() {
    createSubscription(planId, variantId);
    setUserHasClickedOnBuy(true);
  }

  function showPaymentModalWithSubscription() {
    if (!purchaseOrder) {
      toastMessage('error', 'Subscriptions is not created');
      return;
    }

    const { subscriptionId } = purchaseOrder;

    const { name: companyName, email: companyEmail, phone: companyPhone } = company;
    const { amount } = variant;

    const description = '';
    const amountInRs = amount / 100;

    showPaymentModal(
      subscriptionId,
      amountInRs,
      description,
      {
        name: companyName,
        email: companyEmail,
        phone: companyPhone,
      },
      (response) =>
        savePaymentInformation({
          amount,
          subscriptionId: response.razorpay_subscription_id,
          paymentId: response.razorpay_payment_id,
          razorpaySignature: response.razorpay_signature,
          clientSettings,
          requestingUser: requestingUser as UserType,
        }),
      () => {
        setUserHasClickedOnBuy(false);
        resetOrder();
      },
    );
  }

  useEffect(() => {
    // Trigger the buy button after creating subscription
    if (purchaseOrder?.subscriptionId && hasClickedOnBuy) {
      showPaymentModalWithSubscription();
    }
  }, [purchaseOrder?.subscriptionId]);

  if (userInActiveSubscription && isUserInCurrentPlan) {
    return (
      <Space size={2}>
        <Row vcenter>
          <Color shade={COLOR_SHADES.SUCCESS} inline>
            <Icon name="check_circle" />
          </Color>
          <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
            Active
          </Text>
        </Row>
      </Space>
    );
  }
  if (userInActiveSubscription) {
    return (
      <ChangeSubscription
        changeToPlan={`${planId}-${variantId}`}
        currentPlan={`${currentSubscriptionPlanId}-${currentSubscriptionVariantId}`}
      />
    );
  }
  if (SHOW_PRICE) {
    return (
      <Button block onClick={handleCreatingSubscription} isLoading={isLoading}>
        Select
      </Button>
    );
  }
  return <SelectPlanManual selectPlan={`${planId}-${variantId}`} />;
}
