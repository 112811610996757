import { connect } from 'react-redux';

import { RouterService } from '@premagic/core';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { initDefaultEventAttendeeBadgeDesigns } from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import EventAttendeeBadgeCreatorHeader from './EventAttendeeBadgeCreatorHeader';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
    folderId: string;
  },
) {
  const { projectId, eventId, folderId } = ownProps;

  return {
    projectId,
    eventId,
    folderId,
    isLoading: isLoadingSelector(LOADINGS.FOLDER_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetData: (projectId, folderId) => {
      dispatch(initDefaultEventAttendeeBadgeDesigns(dispatch, projectId, folderId));
    },
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeCreatorHeader);
