import React from 'react';
import { motion } from 'framer-motion';
import { MathUtils } from '@premagic/utils';
import styled, { css, keyframes } from 'styled-components';

interface SlideUpProps {
  children: React.ReactNode;
  disable?: boolean;
}

export function SlideUp(props: SlideUpProps): any {
  const { children, disable = false } = props;

  if (disable) return children;

  return (
    <motion.div
      initial={{
        transform: 'translate(0, 150px)',
        opacity: 0,
      }}
      animate={{ transform: 'translate(0, 0)', opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

interface SlideDownProps {
  children: React.ReactNode;
  disable?: boolean;
}

export function SlideDown(props: SlideDownProps): any {
  const { children, disable = false } = props;

  if (disable) return children;

  return (
    <motion.div
      key={`slidedown-${MathUtils.getRandomNumber(0, 10)}`}
      transition={{ ease: 'easeIn', duration: 0.5 }}
      initial={{
        y: '-100%',
      }}
      animate={{ y: 0 }}
      exit={{ opacity: 0, y: '-100%' }}
    >
      {children}
    </motion.div>
  );
}

interface FadeInProps {
  children: React.ReactNode;
  duration?: 'slow' | 'normal' | 'fast';
}

const DURATION_MAP = {
  slow: 1,
  normal: 0.5,
  fast: 0.25,
};

export function FadeIn(props: FadeInProps) {
  const { children, duration = 'normal' } = props;
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      transition={{ duration: DURATION_MAP[duration] || 0.5 }}
    >
      {children}
    </motion.div>
  );
}

interface BounceProps {
  children: React.ReactNode;
}

export function Bounce(props: BounceProps) {
  const { children } = props;
  return (
    <motion.div
      animate={{
        y: ['-16px', '0px', '0px', '0px', '12px'],
        scale: [0.5, 1, 1, 1, 0.5],
        opacity: [0, 1, 1, 1, 0],
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        ease: 'linear',
      }}
    >
      {children}
    </motion.div>
  );
}

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;
