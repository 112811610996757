import { ActionTypeUtils, ErrorTracker, FontUtils } from '@premagic/utils';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { List as IList } from 'immutable';
import { LOADINGS } from '../../../../common/Constants';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { pagesSelector, PagesStateType } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('FONT_SETTINGS');

const setFontsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchFontsData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.FONTS.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  FontUtils.fetchGoogleFonts()
    .then((response) => {
      dispatch(setFontsData(dispatch, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Google-Fonts: fetch failed', e);
    });
});

type StateType = {
  items: IList<FontUtils.GoogleFontType>;
};
const initialState = {
  items: IList(),
};

export default handleActions(
  {
    [setFontsData.toString()]: (state, action: any) => ({
      ...state,
      items: IList(action.payload),
    }),
  },
  initialState,
);

export const fontDataSelector = createSelector(pagesSelector, (state: PagesStateType) =>
  (state.fonts as StateType).items.toArray(),
);

export const fontFetchSuccessSelector = createSelector(
  pagesSelector,
  (state: PagesStateType) => (state.fonts as StateType).items.toArray().length !== 0,
);
