import React, { useEffect } from 'react';
import { Calendar, Card, Color, COLOR_SHADES, Icon, ICON_SIZES, Row, Space, Text, CalendarEvent } from '@premagic/myne';
import { BrowserUrlUtils, SimpleDateUtils, StringUtils } from '@premagic/utils';
import { isArray } from 'lodash';

import OccasionsCalendarToolbarContainer from './OccasionsCalendarToolbarContainer';
import OccasionsCalendarItemWrapperContainer from './OccasionsCalendarItemWrapperContainer';
import OccasionsCalendarItemContentContainer from './OccasionsCalendarItemContentContainer';
import { CRMFiltersType } from '../../filters/CRMFilterTypes';

type Props = {
  filters: string;
  fetchData: (filters: BrowserUrlUtils.QueryObject) => void;
  occasions: Array<CalendarEvent>;
  navigate: (filters: CRMFiltersType) => void;
  isMobileUp?: boolean;
};

export default function OccasionsCalendarPage(props: Props): JSX.Element {
  const { navigate, occasions, fetchData, filters, isMobileUp } = props;

  function onRangeChange(dates: { start: Date; end: Date } | Array<Date>, view: string) {
    const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
    let start;
    let end;
    if (isArray(dates)) {
      // eslint-disable-next-line prefer-destructuring
      start = dates[0];
      end = dates[dates.length - 1];
    } else {
      start = dates.start;
      end = dates.end;
    }
    /* Just to be optimistic
      if (start === end) -> If user is view day view, then we need to add a day to the end date
      The api doesnt not include the end-date, so on the calendar when current month last date is the last date on the calendar the data wont be fetched (eg. Dec 2022 last date is 31st, but the api will only return data till 30th)
    */
    end = SimpleDateUtils.addToDate(end, 'days', 1);

    navigate({
      ...filterObject,
      start_date_gte: SimpleDateUtils.getDateStringFromDate(start, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO),
      end_date_lte: SimpleDateUtils.getDateStringFromDate(end, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO),
    });
  }

  useEffect(() => {
    // NOTE: We wont fetch the occasion if the filter are empty
    if (StringUtils.isEmptyString(filters)) return;

    const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
    fetchData(filterObject);
  }, [filters]);

  const style = isMobileUp ? { height: '1200px' } : { width: 'calc(100vw - 35px)', height: '830px' };
  return (
    <div>
      <Card>
        <Space vertical />
        <Calendar
          events={occasions || []}
          onRangeChange={onRangeChange}
          components={{
            eventWrapper: OccasionsCalendarItemWrapperContainer,
            event: OccasionsCalendarItemContentContainer,
            toolbar: OccasionsCalendarToolbarContainer,
          }}
          style={style}
        />
      </Card>
      <Space size={2} vertical />
      <Row vcenter>
        <Color shade={COLOR_SHADES.LIGHT}>
          <Icon name="info" size={ICON_SIZES.SM} />
        </Color>
        <Space size={1} />
        <Text color={COLOR_SHADES.LIGHT}>Only the occasions are visible in the Calendar</Text>
      </Row>
    </div>
  );
}
