import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../../settings/company/CompanyDataDuck';
import QRCodePosterLEDScreenSettings from './QRCodePosterLEDScreenSettings';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  return {
    projectId,
    folderId,
    hasEventSponsorshipFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterLEDScreenSettings);
