import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { hasFeatureSelector } from '../../../../settings/company/CompanyDataDuck';
import { hasPermission } from '../../../users/UsersDataDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { eventAttendeesSelector } from '../EventAttendeesDataDuck';
import { setEditOfAttendee } from '../EventAttendeesPageDuck';
import EventAttendeeOptionsMenu from './EventAttendeeOptionsMenu';

function mapStateToProps(state, ownProps: { attendeeId: string; projectId: string }) {
  const { attendeeId, projectId } = ownProps;

  return {
    projectId,
    attendeeId,
    hasPermissionsToManageAttendees: hasPermission(PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT)(state),
    attendee: eventAttendeesSelector(state)[attendeeId],
    showPIIDataFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: (id: string) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_DELETE_DIALOG(id), true));
    },
    showEditAttendee: (id: string) => {
      dispatch(setEditOfAttendee(dispatch, id));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeOptionsMenu);
