import { connect } from 'react-redux';
import EventOccasions from './EventOccasions';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { occasionIdsForEventSelector } from './OccasionsDataDuck';

function mapStateToProps(state, ownProps: { eventId: string; focusId?: string }) {
  const { eventId, focusId } = ownProps;

  return {
    eventId,
    focusId,
    isLoading: isLoadingSelector(LOADINGS.OCCASIONS.FETCH)(state),
    occasionIds: occasionIdsForEventSelector(eventId)(state) || {},
  };
}

export default connect(mapStateToProps)(EventOccasions);
