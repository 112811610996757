import React, { useState } from 'react';

import {
  Text,
  Card,
  Space,
  FormGroup,
  TEXT_SIZE,
  COLOR_SHADES,
  TEXT_BOLDNESS,
  CARD_STYLES,
  Row,
  BUTTON_CIRCLE_STYLES,
  BUTTON_CIRCLE_SIZES,
  ICON_SIZES,
  Icon,
  ButtonCircle,
  RichTextArea,
  CARD_SIZES,
  TextButton,
  TEXT_BUTTON_STYLES,
  FormLabel,
  Textarea,
} from '@premagic/myne';
import { TaskType } from '../service/TaskService';

type Props = {
  task: TaskType;
};

export default function WorkflowTask(props: Props): JSX.Element {
  const { task } = props;
  const { id: taskId, name: taskName, description: taskDescription } = task;

  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <>
      <Space vertical size={2} />
      <Row>
        <Space size={7} />
        <Card style={CARD_STYLES.SECONDARY} size={CARD_SIZES.FULL_WIDTH}>
          <Space size={2}>
            <Space vertical size={2} />
            <Row vcenter>
              <ButtonCircle style={BUTTON_CIRCLE_STYLES.DEFAULT} size={BUTTON_CIRCLE_SIZES.SM} disabled>
                <Icon name="check" size={ICON_SIZES.SM_XS} align="top" alignSize={2} />
              </ButtonCircle>
              <Space size={2} />
              <Text size={TEXT_SIZE.SIZE_4} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                {taskName}
              </Text>
            </Row>
            <Space vertical size={2} />
            {/* will add add description later */}
            {/* <FormGroup>
              <Row vcenter>
                <Space size={7} />
                <Icon name="align_left" size={ICON_SIZES.SM} align="top" alignSize={3} />
                {isEditMode ? (
                  <>
                    <FormLabel>Description</FormLabel>
                    <Textarea name="task_description" defaultValue={taskDescription} />
                  </>
                ) : (
                  <TextButton style={TEXT_BUTTON_STYLES.DEFAULT}>Add Description</TextButton>
                )}
              </Row>
            </FormGroup> */}
          </Space>
        </Card>
      </Row>
      <Space vertical size={2} />
    </>
  );
}
