import { FileService } from '@premagic/core';
import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  CARD_STYLES,
  Card,
  IMAGE_V2_STYLES,
  ImageV2,
  Row,
  Space,
} from '@premagic/myne';
import { MediaUtils } from '@premagic/utils';

type Props = {
  sponsorCreativeWatermarkFile?: FileService.FolderFileType;
  showWatermarkPanel: (watermarkId: string) => void;
  sponsorCreativeWatermarkId: string;
};

export default function WatermarkItem(props: Props) {
  const { sponsorCreativeWatermarkFile, showWatermarkPanel, sponsorCreativeWatermarkId } = props;
  const { dynamic_image_url: imageUrl } = sponsorCreativeWatermarkFile || {};
  if (!imageUrl) return null;
  return (
    <Space size={3}>
      <Row columnDirection vcenter>
        <Card overflowHidden center style={CARD_STYLES.SECONDARY} cssStyle={{ width: 70, height: 70 }}>
          <ImageV2
            src={imageUrl}
            alt="watermark-photo"
            style={IMAGE_V2_STYLES.FULL_IMAGE}
            imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
          />
        </Card>
        <Space vertical size={2} />
        <Button
          style={BUTTON_STYLES.LINK}
          size={BUTTON_SIZE.XS}
          onClick={() => showWatermarkPanel(sponsorCreativeWatermarkId)}
        >
          Edit
        </Button>
      </Row>
    </Space>
  );
}
