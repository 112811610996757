import { ColorPicker, Row, Space, SubTitle, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

type HighlightDesignAccentColorSelectorProps = {
  onChange: (color: string) => void;
  disabled: boolean;
  accentColor?: string;
  initAccentColor: (fontColor: string) => void;
};

export default function HighlightDesignAccentColorSelector(props: HighlightDesignAccentColorSelectorProps) {
  const { onChange, disabled, accentColor, initAccentColor } = props;

  const [selectedColor, setSelectedColor] = useState(accentColor || '#047af0');

  const debouncedAccentColorChange = useMemo(() => debounce(onChange, 500), [onChange]);

  //  when the selected color changes, we will change the global theme accent color in the stylesheet
  useEffect(() => {
    initAccentColor(selectedColor);
  }, [selectedColor, initAccentColor]);

  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>Accent Color</Text>
      <Space vertical size={1} />
      <Row vcenter>
        <ColorPicker
          name="accent_color"
          defaultValue={selectedColor}
          onChange={(color) => {
            setSelectedColor(color);
            debouncedAccentColorChange(color);
          }}
          overlayPlacement="right"
          disabled={disabled}
        />
      </Row>
    </div>
  );
}
