import React, { useMemo, useRef, useState } from 'react';
import {
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  DECK_SLIDE_DATA_TYPE_ALIGNMENT,
  DECK_SLIDE_DATA_TYPE_STYLES,
  DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideContentBaseType,
  Dialog,
  DIALOG_PLACEMENT,
  Icon,
  ICON_SIZES,
  PillItem,
  Pills,
  PILLS_STYLES,
  Range,
  Select,
  Space,
  SubTitle,
} from '@premagic/myne';
import { ProposalSlideService } from '@premagic/proposals';
import { debounce, update } from 'lodash';

const ALIGNMENT_BUTTONS: Array<{
  icon: string;
  label: string;
  value: DECK_SLIDE_DATA_TYPE_ALIGNMENT;
}> = [
  { icon: 'align_top_simple', label: 'Top', value: DECK_SLIDE_DATA_TYPE_ALIGNMENT.TOP },
  {
    icon: 'align_center_horizontal_simple',
    label: 'Center',
    value: DECK_SLIDE_DATA_TYPE_ALIGNMENT.CENTER,
  },
  { icon: 'align_bottom_simple', label: 'Bottom', value: DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM },
];

type AlignmentButtonsProps = {
  activeAlignment: DECK_SLIDE_DATA_TYPE_ALIGNMENT;
  onChange: (alignment: DECK_SLIDE_DATA_TYPE_ALIGNMENT) => void;
};

function AlignmentButtons(props: AlignmentButtonsProps) {
  const { onChange, activeAlignment } = props;
  return (
    <Pills style={PILLS_STYLES.SECONDARY}>
      {ALIGNMENT_BUTTONS.map(({ icon, label, value }) => (
        <PillItem key={value} onClick={() => onChange(value)} active={value === activeAlignment}>
          <Icon name={icon} size={ICON_SIZES.SM} />
        </PillItem>
      ))}
    </Pills>
  );
}

const TEXT_ALIGNMENT_BUTTONS: Array<{
  icon: string;
  label: string;
  value: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT;
}> = [
  { icon: 'align_left', label: 'Left', value: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT.LEFT },
  {
    icon: 'align_center',
    label: 'Center',
    value: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT.CENTER,
  },
  { icon: 'align_right', label: 'Right', value: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT.RIGHT },
];

type TextAlignmentButtonsProps = {
  activeTextAlignment: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT;
  onChange: (alignment: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT) => void;
};

function TextAlignmentButtons(props: TextAlignmentButtonsProps) {
  const { onChange, activeTextAlignment } = props;
  return (
    <Pills style={PILLS_STYLES.SECONDARY}>
      {TEXT_ALIGNMENT_BUTTONS.map(({ icon, label, value }) => (
        <PillItem key={value} onClick={() => onChange(value)} active={value === activeTextAlignment}>
          <Icon name={icon} size={ICON_SIZES.SM} />
        </PillItem>
      ))}
    </Pills>
  );
}

function getStyleOptions(dataType: DECK_SLIDE_DATA_TYPES) {
  const styleOptions: Array<{
    label: string;
    value: DECK_SLIDE_DATA_TYPE_STYLES;
    group: Array<DECK_SLIDE_DATA_TYPES>;
    default?: {
      align?: DECK_SLIDE_DATA_TYPE_ALIGNMENT;
      color: string;
    };
  }> = [
    {
      label: 'None',
      value: DECK_SLIDE_DATA_TYPE_STYLES.NONE,
      group: [DECK_SLIDE_DATA_TYPES.TITLE, DECK_SLIDE_DATA_TYPES.SUB_TITLE, DECK_SLIDE_DATA_TYPES.LARGE_TEXT],
      default: dataType === DECK_SLIDE_DATA_TYPES.LARGE_TEXT ? { color: '#fff' } : { color: '#fff' },
    },
    { label: 'Box', value: DECK_SLIDE_DATA_TYPE_STYLES.BOX, group: [DECK_SLIDE_DATA_TYPES.LARGE_TEXT] },
    {
      label: 'Gradient to top',
      value: DECK_SLIDE_DATA_TYPE_STYLES.GRADIENT_TO_TOP,
      group: [DECK_SLIDE_DATA_TYPES.LARGE_TEXT],
      default: {
        align: DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM,
        color: '#fff',
      },
    },
    {
      label: 'Frost',
      value: DECK_SLIDE_DATA_TYPE_STYLES.FROST,

      group: [DECK_SLIDE_DATA_TYPES.LARGE_TEXT],
    },
    { label: 'Glass Box', value: DECK_SLIDE_DATA_TYPE_STYLES.GLASS_BOX, group: [DECK_SLIDE_DATA_TYPES.LARGE_TEXT] },
    {
      label: 'Paper Card',
      value: DECK_SLIDE_DATA_TYPE_STYLES.PAPER_CARD,
      group: [DECK_SLIDE_DATA_TYPES.LARGE_TEXT],
      default: {
        color: '#000',
      },
    },
  ];
  return styleOptions.filter(({ group }) => group.includes(dataType));
}

type StylesButtonsProps = {
  dataType: DECK_SLIDE_DATA_TYPES;
  activeStyle: DECK_SLIDE_DATA_TYPE_STYLES;
  onChange: (alignment: DECK_SLIDE_DATA_TYPE_STYLES) => void;
};

function StylesButtons(props: StylesButtonsProps) {
  const { onChange, activeStyle, dataType } = props;
  const options = useMemo(() => getStyleOptions(dataType), [dataType]);
  return (
    <Select
      options={options}
      name=""
      onChange={(option) => onChange(option?.value as DECK_SLIDE_DATA_TYPE_STYLES)}
      value={options.find((item) => item.value === activeStyle)}
      isMulti={false}
    />
  );
}

type ScaleButtonsProps = {
  activeScale: number;
  onChange: (scale: number) => void;
};

function ScaleButtons(props: ScaleButtonsProps) {
  const { onChange, activeScale } = props;
  const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange]);
  return <Range name="" min={0.5} max={1.5} step={0.1} defaultValue={activeScale} onChange={debouncedOnChange} />;
}

type Props = {
  projectId: string;
  deckId: string;
  dataType: DECK_SLIDE_DATA_TYPES;
  saveProposalSlide: (options: { projectId: string; deckId: string; id: number }, ProposalSlideType) => void;
  slideDetails?: ProposalSlideService.ProposalSlideType;
};

export default function SlideDataSettingButton(props: Props): React.ReactElement | null {
  const { dataType, saveProposalSlide, slideDetails, projectId, deckId } = props;
  const [showDialog, setShowDialog] = useState(false);
  const showSettingButton = [
    DECK_SLIDE_DATA_TYPES.LARGE_TEXT,
    DECK_SLIDE_DATA_TYPES.TITLE,
    DECK_SLIDE_DATA_TYPES.SUB_TITLE,
  ].includes(dataType);
  const $button = useRef(null);

  if (!showSettingButton) {
    return null;
  }

  const dateForTheDataType = (slideDetails?.structure?.[dataType] as DeckSlideContentBaseType) || {};
  const activeAlignment = dateForTheDataType?.align || DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM;
  const activeTextAlignment = dateForTheDataType?.text_align || DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT.LEFT;
  const activeStyle = dateForTheDataType?.style || DECK_SLIDE_DATA_TYPE_STYLES.GRADIENT_TO_TOP;
  const activeScale = dateForTheDataType?.scale || 1;

  function handleOnchange(scope: 'align' | 'text_align' | 'style' | 'scale', value: string | number) {
    if (!slideDetails) {
      return;
    }
    let newStructure = update(slideDetails.structure, `[${dataType}][${scope}]`, () => value);

    if (scope == 'style') {
      const style = getStyleOptions(dataType).find((item) => item.value === value);
      if (style && style.default) {
        newStructure = style.default?.align
          ? update(newStructure, `[${dataType}][align]`, () => style.default?.align)
          : newStructure;
        newStructure = update(newStructure, `[${dataType}][color]`, () => style.default?.color);
      }
    }

    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
  }

  return (
    <>
      <ButtonIcon
        ref={$button}
        title="Style"
        active={showDialog}
        size={BUTTON_ICONS_SIZES.SM}
        onClick={() => setShowDialog(true)}
      >
        <Icon name="sliders" size={ICON_SIZES.SM} />
      </ButtonIcon>
      <Dialog
        target={$button.current}
        show={showDialog}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => setShowDialog(false)}
      >
        <SubTitle>Alignment</SubTitle>
        <Space vertical size={1} />
        <AlignmentButtons activeAlignment={activeAlignment} onChange={(value) => handleOnchange('align', value)} />
        <Space />
        <TextAlignmentButtons
          activeTextAlignment={activeTextAlignment}
          onChange={(value) => handleOnchange('text_align', value)}
        />
        <Space vertical />
        <SubTitle>Styles</SubTitle>
        <Space vertical size={1} />
        <StylesButtons
          dataType={dataType}
          activeStyle={activeStyle}
          onChange={(value) => handleOnchange('style', value)}
        />
        <Space vertical />
        <SubTitle>Scale</SubTitle>
        <Space vertical size={1} />
        <ScaleButtons activeScale={activeScale} onChange={(value) => handleOnchange('scale', value)} />
      </Dialog>
    </>
  );
}
