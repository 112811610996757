import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';

import {
  ActionTypeUtils,
  BrowserUrlUtils,
  BrowserUtils,
  ErrorTracker,
  HelpWidgetUtils,
  TimezoneUtils,
} from '@premagic/utils';
import { ClientSettingsService, EventTrackerService, ProductSurveyService, RouterService } from '@premagic/core';

import { ProposalVariablesDataDuck } from '@premagic/proposals';
import { isUndefined } from 'lodash';
import { LOADINGS, MODALS } from '../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../common/LoadingDuck';
import { setCompanyData } from './settings/company/CompanyDataDuck';
import { fetchCompanyInfo } from './settings/company/CompanyService';
import { setClientSettingsData } from './settings/preferences/ClientSettingsDataDuck';
import { fetchUsersData, setRequestingUser } from './crm/users/UsersDataDuck';
import { fetchRequestingUser } from './crm/users/UsersService';
import { fetchPaymentCategories } from './crm/payments/CRMPaymentCategoryDataDuck';
import { fetchAccounts, getAccountIdToSwitchFromURL, getActiveAccountIdInLocalCache } from './acccount/AccountService';
import { setAccountsData, setActiveAccount } from './acccount/AccountDataDuck';
import { addAppAlert } from './app-alerts/AccountAppAlertsDataDuck';
import MESSAGES from '../../../common/Messages';
import { ACCOUNT_APP_ALERT_TYPE } from './app-alerts/AccountAppAlertService';
import { getCurrentSubscription } from './settings/subscriptions/SubscriptionsDataDuck';
import { toggleModalVisibility } from '../../../common/ModalDuck';
import { fetchProjectsShownInPortfolio } from './portfolio/PortfolioDataDuck';
import APP_URLS from '../services/AppRouteURLService';

const getActionType = ActionTypeUtils.getActionTypeFunction('APP', true);

export const setDeviceData = createAction(getActionType('DEVICE', 'SET'), (dispatch: Dispatch, data) => {
  BrowserUtils.hasSupportForWebp();
  const { height } = data;
  document.documentElement.style.setProperty('--app-height', `${height}px`);
  return data;
});

export const fetchAppData = createAction(getActionType('DATA', 'FETCH'), async (dispatch: Dispatch) => {
  dispatch(toggleLoadingState(dispatch, LOADINGS.APP_LOADING, true));
  dispatch(clearErrorState(dispatch, LOADINGS.APP_LOADING));

  dispatch(
    setDeviceData(dispatch, {
      width: window.innerWidth,
      height: window.innerHeight,
    }),
  );

  try {
    // 1. Fetch all the accounts for the user
    const accountResponse = await fetchAccounts();
    dispatch(setAccountsData(dispatch, accountResponse.results));

    if (!accountResponse?.results) return;

    // 2. Set active account
    const activeAccountId =
      getAccountIdToSwitchFromURL() || getActiveAccountIdInLocalCache() || accountResponse?.results[0]?.id; // Set first accountId
    const activeAccount = accountResponse.results.find((account) => account.id == activeAccountId);

    dispatch(setActiveAccount(dispatch, activeAccount ? activeAccount.id : accountResponse?.results[0]?.id));
    // 3. Set Timezone for the account
    TimezoneUtils.setCurrentTimezone(activeAccount?.timezone || TimezoneUtils.guess());

    // 4. Fetch the company profile data
    const company = await fetchCompanyInfo();
    dispatch(setCompanyData(dispatch, company));

    // 5. Fetch client setting
    const clientSettings = await ClientSettingsService.fetchClientSettings();
    dispatch(setClientSettingsData(dispatch, clientSettings));

    if (isUndefined(clientSettings.company_type)) {
      const welcomePageUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.WELCOME.SETUP, {
        view: 'set-company-type',
      });
      RouterService.navigateTo(dispatch, welcomePageUrl);
    }
    if (company?.is_first_time_user && !ClientSettingsService.isCompanyTypeNotPhotographer(clientSettings)) {
      dispatch(toggleModalVisibility(dispatch, MODALS.TOUR_WELCOME_MODAL, true));
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.TOUR_WELCOME_SCREEN, {
        action: 'open',
      });
    }
    // 5. Fetch Base date in Async
    dispatch(fetchUsersData(dispatch));
    dispatch(fetchPaymentCategories(dispatch));

    // 6. Set requesting user profile
    const requestingUser = await fetchRequestingUser();
    dispatch(setRequestingUser(dispatch, requestingUser.id, requestingUser));

    // 6.a If the requesting user is proxied then add alert
    if (requestingUser.real_me !== requestingUser.email) {
      dispatch(
        addAppAlert(dispatch, {
          id: 'proxy-user',
          title: MESSAGES.USER.USER_PROXY_MESSAGE,
          type: ACCOUNT_APP_ALERT_TYPE.DANGER,
          readOnly: true,
        }),
      );
    }
    // 7. Fetch subscription, status and setup correct alerts
    dispatch(
      getCurrentSubscription(dispatch, {
        clientSettings,
        company,
        userId: requestingUser.id,
      }),
    );
    dispatch(ProposalVariablesDataDuck.fetchProposalsVariables(dispatch)); // For event details page

    // 8. Fetch Projects list shown in portfolio
    dispatch(fetchProjectsShownInPortfolio(dispatch));

    // 8. Set up Tracking
    EventTrackerService.setUserInfo(requestingUser.id, {
      name: requestingUser.name,
      $email: requestingUser.email,
      $phone: requestingUser.phone_number,
      $last_login: new Date().toISOString(),
      $created: requestingUser.created_at,
      Company: company.name,
      company_type: company.company_type,
      plan: company.currentPricingPlan.internal_name,
      plan_projects: company.currentPricingPlan.projects,
      plan_projects_used: company.usage.totalProjects,
    });
    EventTrackerService.setCompanyDetails({
      id: company.id,
      name: company.name,
      company_type: company.company_type,
      plan: company.currentPricingPlan.internal_name,
      plan_projects: company.currentPricingPlan.projects,
      plan_projects_used: company.usage.totalProjects,
    });

    ErrorTracker.setUserInfo({
      id: requestingUser.id,
      username: requestingUser.name,
      email: requestingUser.email,
      company: company.name,
    });

    // Don't track for staff users
    if (requestingUser.real_me === requestingUser.email) {
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PAGE_VIEW, company);
    }
    // 9. Set up Product Survey
    if (requestingUser.real_me === requestingUser.email) {
      ProductSurveyService.initUser(
        {
          name: requestingUser.name,
          email: requestingUser.email || 'not-set',
          createdAt: requestingUser.created_at,
          role: requestingUser.role,
        },
        {
          name: company.name,
          planName: company.currentPricingPlan.internal_name,
          company_type: company.company_type,
        },
      );
    }

    HelpWidgetUtils.initHelpWidget({
      name: requestingUser.name,
      email: requestingUser.email,
      phone: requestingUser.phone_number,
      company_type: company.company_type,
    });

    dispatch(toggleLoadingState(dispatch, LOADINGS.APP_LOADING, false));
  } catch (e) {
    dispatch(setErrorState(dispatch, LOADINGS.APP_LOADING, e));
    dispatch(toggleLoadingState(dispatch, LOADINGS.APP_LOADING, false));
    ErrorTracker.logError('App load fetch', e);
  }
});

export const switchAccount = createAction(getActionType('ACCOUNT', 'SWITCH'), (dispatch, accountId) => {
  BrowserUrlUtils.navigateToPage(`${BrowserUrlUtils.getUrl()}?accountId=${accountId}`);
});

type StateType = {
  device: {
    width: number;
    height: number;
  };
};

const initialState = {
  device: {},
};

export default handleActions(
  {
    [setDeviceData.toString()]: (state, action) => ({
      ...state,
      device: action.payload,
    }),
  },
  initialState,
);

export const appSelector = (state) => state.app as StateType;

export const appWidthSelector = createSelector(appSelector, (state) => state.device.width);
export const appHeightSelector = createSelector(appSelector, (state) => state.device.height);
export const isMobileUp = createSelector(appSelector, (state) => state.device.width > 800);
