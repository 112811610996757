import { AttendeeMemoriesService } from '@premagic/core';
import {
  Card,
  COLOR_SHADES,
  IMAGE_V2_STYLES,
  ImageV2,
  Position,
  Row,
  Shadow,
  SHADOW_STYLES,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import React from 'react';
import { StringUtils } from '@premagic/utils';
import MemoryTemplateItemOptionsContainer from './create/MemoryTemplateItemOptionsContainer';

type Props = {
  eventId: string;
  projectId: string;
  template: AttendeeMemoriesService.MemoryTemplateType;
  focusId?: string;
};

export default function MemoriesTemplatesListItem(props: Props): JSX.Element {
  const { eventId, projectId, focusId, template } = props;

  const { id, type, target_asset: file, gender, name = '(blank)', age } = template;

  return (
    <Card
      highlight={id === focusId}
      overflowHidden
      cssStyle={{
        minHeight: 124,
      }}
    >
      <Position align="top-right">
        <Space size={2}>
          <MemoryTemplateItemOptionsContainer projectId={projectId} eventId={eventId} id={id} />
        </Space>
      </Position>
      <Row>
        <ImageV2 useNewImageService src={file?.dynamic_image_url} style={IMAGE_V2_STYLES.COVER} alt="Template Image" />
        <Shadow style={SHADOW_STYLES.BOTTOM}>
          <Space size={1}>
            <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.WHITE}>
              {name} for{' '}
              <Text size={TEXT_SIZE.SIZE_6} color={COLOR_SHADES.WHITE}>
                {age}/{StringUtils.toTitleCase(gender)}
              </Text>
            </Text>
          </Space>
        </Shadow>
      </Row>
    </Card>
  );
}
