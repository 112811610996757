import { EventTrackerService } from '@premagic/core';
import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  BluredView,
  Button,
  CARD_SIZES,
  COLOR_SHADES,
  Card,
  Col,
  ICON_SIZES,
  Icon,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
  UnselectableText,
  Textarea,
} from '@premagic/myne';
import { BrowserUtils } from '@premagic/utils';
import { toastMessage } from '../../../../../reducers/ToastStore';
import PremiumFeatureIcon from '../../../../settings/PremiumFeatureIcon';
import { usePhotoSliderWidgetData } from '../../context/PhotoSliderWidgetContext';

const GIBBERISH_CODE =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis consectetur libero ipsam iusto? At consectetur maxime cum iste harum quas eveniet neque, voluptas facere quibusdam perspiciatis aliquid, a culpa quisquam eius? Soluta quibusdam impedit atque hic deserunt, pariatur earum? Explicabo rem doloremque qui consequatur tenetur quis voluptas quod similique quaerat?';

export function copyCode(codeToShare) {
  BrowserUtils.copyToClipboard(codeToShare);
  toastMessage('success', 'Code copied');
}

type Props = {
  shareId?: string;
  hasFeature?: boolean;
};

export default function PhotoSliderWidgetGetWidgetCode(props: Props) {
  const { shareId, hasFeature } = props;

  const { widgetCode } = usePhotoSliderWidgetData();

  function handleCopyWidgetCode() {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.WIDGET.PHOTO_GALLERY.COPY_WIDGET_CODE, {});
    copyCode(widgetCode);
  }

  return (
    <Space>
      <Text color={COLOR_SHADES.DARKER}>
        Copy and paste the code below into your website at the desired location to display the widget.
      </Text>
      {!hasFeature && (
        <>
          <Space vertical />
          <Row vcenter>
            <Text block size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
              Available only on the premium plans
            </Text>
            {!hasFeature && <PremiumFeatureIcon module="Photo widget gallery" />}
          </Row>
        </>
      )}
      <Space vertical size={2} />
      {hasFeature ? (
        <Textarea name="" readOnly>
          {widgetCode}
        </Textarea>
      ) : (
        <Card>
          <BluredView>
            <UnselectableText>{GIBBERISH_CODE}</UnselectableText>
          </BluredView>
        </Card>
      )}
      <Space vertical />
      <Row>
        <Col rightAlighed size={null}>
          <Button
            disabled={!hasFeature}
            style={BUTTON_STYLES.PRIMARY}
            size={BUTTON_SIZE.MD}
            onClick={handleCopyWidgetCode}
          >
            <Icon name="copy" size={ICON_SIZES.SM} />
            <Space size={1} />
            Copy widget code
          </Button>
        </Col>
      </Row>
    </Space>
  );
}
