import { connect } from 'react-redux';

import { appWidthSelector } from '../../../../../AppDuck';

import {
  isWatermarkForSponsorProcessingSelector,
  photoWatermarkForSponsorForPreviewSelector,
} from '../../SponsorCreativeDataDuck';
import SponsorWatermarkPreviewFullViewImage from './SponsorWatermarkPreviewFullViewImage';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    isMobileView?: boolean;
    folderId: string;
    photo?: {
      name: string;
      image: string;
    };
  },
) {
  const { projectId, isMobileView, folderId, photo } = ownProps;

  return {
    watermarks: photoWatermarkForSponsorForPreviewSelector(folderId)(state),
    appWidth: appWidthSelector(state),
    isWatermarkProcessing: isWatermarkForSponsorProcessingSelector(folderId)(state),
    isMobileView,
    photo,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorWatermarkPreviewFullViewImage);
