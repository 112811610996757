import { connect } from 'react-redux';
import EventSponsorsGroup from './EventSponsorsGroup';
import { sponsorsCategoryDataSelector } from './sponsor-category/data-duck/SponsorCategoryDataDuck';
import { eventSponsorsIdsForCategorySelector } from './EventSponsorDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    categoryId: string;
  },
) {
  const { projectId, categoryId } = ownProps;
  return {
    projectId,
    sponsorIds: eventSponsorsIdsForCategorySelector(categoryId)(state),
    category: sponsorsCategoryDataSelector(state)[categoryId],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorsGroup);
