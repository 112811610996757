import {
  Card,
  COLOR_SHADES,
  InnerPageHeader,
  Space,
  Table,
  TABLE_CELL_TYPES,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  InnerPage,
  InnerPageContent,
  TableColumnType,
} from '@premagic/myne';
import React, { useEffect, useMemo } from 'react';
import { NumberUtils } from '@premagic/utils';
import { ReportProjectType } from '../ReportService';
import MESSAGES from '../../../../../common/Messages';

function StatsCount(props: { count: number }) {
  const { count = 0 } = props;
  return (
    <Text muted={count === 0} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD}>
      {NumberUtils.getNumberInFormat(count)}
    </Text>
  );
}

type ProjectInsightSummaryProps = {
  stats: {
    projects: number;
    views: number;
    shares: number;
    referrals: number;
    downloads: number;
  };
  hasLeadsModule: boolean;
};

function ProjectInsightSummary(props: ProjectInsightSummaryProps) {
  const { stats, hasLeadsModule } = props;
  const { projects, views, shares, referrals } = stats;
  if (views < 100) return null;

  return (
    <Card>
      <Space>
        <Text block color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
          There were <StatsCount count={views} /> views from <StatsCount count={projects} /> projects.
        </Text>
        {hasLeadsModule && shares > 0 && (
          <Text block color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
            And <StatsCount count={shares} /> people have shared projects and photos with their relatives and friends,
          </Text>
        )}
        {hasLeadsModule && referrals > 0 && (
          <Text block color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
            And <StatsCount count={referrals} /> people had engagement with your brand, These could potentially be your
            new clients!
          </Text>
        )}
      </Space>
    </Card>
  );
}
type Props = {
  initData: () => void;
  projects: Array<ReportProjectType>;
  summaryStats: {
    projects: number;
    views: number;
    shares: number;
    referrals: number;
    downloads: number;
    leads: number;
  };
  isLoading: boolean;
  hasLeadsModule: boolean;
};

export default function ReportInsightPage(props: Props) {
  const { initData, hasLeadsModule } = props;
  useEffect(() => {
    initData();
  }, [initData]);

  const { projects, isLoading, summaryStats } = props;
  const columns = useMemo(() => {
    const items: Array<TableColumnType> = [
      {
        title: 'Project Name',
        accessor: 'project_name',
        width: 300,
      },
      {
        title: 'Added',
        accessor: 'created_at',
        type: TABLE_CELL_TYPES.DATE,
        width: 100,
      },
      {
        title: 'Views',
        accessor: 'stats.views',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 100,
      },
      {
        title: 'Downloads',
        accessor: 'stats.downloads',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 100,
      },
      {
        title: 'Shares',
        accessor: 'stats.shares',
        type: TABLE_CELL_TYPES.NUMBER,
        width: 100,
      },
    ];

    if (hasLeadsModule) {
      items.push(
        ...[
          {
            title: 'Engagement',
            accessor: 'stats.referrals',
            type: TABLE_CELL_TYPES.NUMBER,
            width: 150,
            info: MESSAGES.METRICS.ENGAGEMENT,
          },
          {
            title: 'Leads',
            accessor: 'stats.leads',
            type: TABLE_CELL_TYPES.NUMBER,
            width: 100,
          },
        ],
      );
    }

    return items;
  }, [hasLeadsModule]);

  return (
    <InnerPage>
      <InnerPageHeader iconName="activity" title="Projects insight">
        Insights
      </InnerPageHeader>

      <InnerPageContent isLoading={isLoading}>
        <ProjectInsightSummary stats={summaryStats} hasLeadsModule={hasLeadsModule} />
        <Card>
          <Table
            name="projects-insight"
            showSelectCheckboxOnRows
            columns={columns}
            data={projects}
            tableFilters={{}}
            requestDataWithFilter={(filters) => {
              // console.log(filters);
            }}
          />
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
