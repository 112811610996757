import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { FolderService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Col,
  GroupedButton,
  GroupedButtons,
  Icon,
  ICON_SIZES,
  ModalMediaContent,
  Row,
  Space,
} from '@premagic/myne';
import FolderImageFullViewContainer from './FolderImageFullViewContainer';

type Props = {
  id: string;
  folder: FolderService.FolderType;
  nextFileId: string;
  previousFileId: string;
  projectId: string;
  folderId: string;
  deleteFile: (projectId: string, folderId: string, fileId: string, folderType: FolderService.FOLDER_TYPES) => void;
  onNavigate: (id) => void;
  isDeleting: boolean;
  onSelect?: (id) => void;
};

export default function FolderImagesPopupSimple(props: Props) {
  const { nextFileId, previousFileId, deleteFile, projectId, folderId, folder, onNavigate, isDeleting, id, onSelect } =
    props;

  const keyMap = {
    previous: 'left',
    next: 'right',
  };

  function handleDelete() {
    deleteFile(projectId, folderId, id, folder.folder_type);
  }

  function showNextImage() {
    if (nextFileId) {
      onNavigate(nextFileId);
    }
  }

  function showPreviousImage() {
    if (previousFileId) {
      onNavigate(previousFileId);
    }
  }

  const handlers = {
    previous: () => showPreviousImage(),
    next: () => showNextImage(),
  };

  if (!id) return null;

  return (
    <div>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <ModalMediaContent>
        <FolderImageFullViewContainer id={id} />
      </ModalMediaContent>
      <Space vertical />
      <Row>
        <Col rightAlighed size={null}>
          <Row vcenter>
            <Button style={BUTTON_STYLES.LINK} onClick={handleDelete} isLoading={isDeleting}>
              <Icon name="trash" size={ICON_SIZES.SM} />
              <Space size={1} /> Delete
            </Button>
            <Space size={12} />

            <GroupedButtons>
              <GroupedButton disabled={!previousFileId} onClick={showPreviousImage}>
                <Icon name="chevron_left" size={ICON_SIZES.SM} />
              </GroupedButton>
              <GroupedButton disabled={!nextFileId} onClick={showNextImage}>
                <Icon name="chevron_right" size={ICON_SIZES.SM} />
              </GroupedButton>
            </GroupedButtons>
            {onSelect && (
              <>
                <Space size={4} />
                <Button onClick={() => onSelect(id)} style={BUTTON_STYLES.PRIMARY}>
                  Select
                </Button>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
