import React from 'react';
import {
  Alert,
  ALERT_STYLES,
  Col,
  COLOR_SHADES,
  ErrorBoundary,
  EscapedParagraphHTMLText,
  FormGroup,
  FormLabel,
  getComponentForInputType,
  hasComponentForInputType,
  Input,
  INPUT_TYPES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProposalDeckService, ProposalSlideService, ProposalVariableService } from '@premagic/proposals';
import { FolderMetaService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';

const PROPOSAL_CUSTOM_INPUT: Partial<Record<ProposalVariableService.PROPOSAL_VARIABLE_TYPE, INPUT_TYPES>> = {
  [ProposalVariableService.PROPOSAL_VARIABLE_TYPE.TAX]: INPUT_TYPES.PERCENTAGE,
  [ProposalVariableService.PROPOSAL_VARIABLE_TYPE.DISCOUNT_PERCENT]: INPUT_TYPES.PERCENTAGE,
  [ProposalVariableService.PROPOSAL_VARIABLE_TYPE.DISCOUNT_CURRENCY]: INPUT_TYPES.CURRENCY,
};

function getInputComponent(type: INPUT_TYPES | ProposalVariableService.PROPOSAL_VARIABLE_TYPE) {
  const actualType = PROPOSAL_CUSTOM_INPUT[type] || type;

  if (hasComponentForInputType(actualType)) return getComponentForInputType(actualType);

  return Input;
}

type SlideVariablesInputProps = {
  deck?: ProposalDeckService.ProposalDeckType;
  variableIds?: Array<string>;
  variables: Record<string, ProposalVariableService.ProposalVariableType>;
  isDeckPublished?: boolean;
  slideId?: number;
};

function SlideVariablesInput(props: SlideVariablesInputProps) {
  const { deck, variableIds, variables, isDeckPublished, slideId } = props;

  if (!variableIds || !slideId) return null;
  if (variableIds.length === 0) return <Text muted>No variables set on this slide</Text>;
  return (
    <div>
      {variableIds?.map((variableId) => {
        const variable = variables[variableId];
        const InputComponent = getInputComponent(variable.type);

        return (
          <FormGroup key={variableId}>
            <FormLabel>{variable.name}</FormLabel>
            <ErrorBoundary>
              {/* @ts-ignore */}
              <InputComponent
                name={`variables[${slideId}][${variableId}]`}
                disabled={isDeckPublished}
                defaultValue={deck?.variables?.[slideId]?.[variableId]}
              />
            </ErrorBoundary>
          </FormGroup>
        );
      })}
    </div>
  );
}

type VariablesGroupedBySlideProps = {
  deck?: ProposalDeckService.ProposalDeckType;
  slides?: Record<string, ProposalSlideService.ProposalSlideType>;
  variableIdsSetOnDeck?: Array<string>;
  variables: Record<string, ProposalVariableService.ProposalVariableType>;
  isDeckPublished?: boolean;
};

function VariablesGroupedBySlides(props: VariablesGroupedBySlideProps) {
  const { deck, slides, variableIdsSetOnDeck, variables, isDeckPublished } = props;
  if (!deck || !slides) return null;

  const slidesOnDeck = deck?.slides;

  function variableIdsAddedOnSlide(slideId) {
    if (!deck) return;
    const addedVariables = deck.variables[slideId] as Record<any, any>;
    if (!addedVariables) return;
    return variableIdsSetOnDeck?.filter((variableId) => variableId in addedVariables);
  }

  return (
    <div>
      {slidesOnDeck?.map((slideId) => {
        const slide = slides[slideId];
        const slideTitle = StringUtils.getTextFromHTMLString(slide?.structure?.title?.value);
        return (
          <div key={slideId} className={`slide-id-${slideId}`}>
            <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD} block ellipsis>
              {slideTitle || `Slide ${slideId}`}
            </Text>
            <Row>
              <Space />
              <Col size={11}>
                <SlideVariablesInput
                  deck={deck}
                  variableIds={variableIdsAddedOnSlide(slideId)}
                  variables={variables}
                  isDeckPublished={isDeckPublished}
                  slideId={slideId}
                />
              </Col>
            </Row>
            <Space vertical />
          </div>
        );
      })}
    </div>
  );
}

type Props = {
  deck?: ProposalDeckService.ProposalDeckType;
  groupedVariablesSetOnDeck: Record<ProposalVariableService.PROPOSAL_VARIABLE_SCOPE, Array<string>>;
  variables: Record<string, ProposalVariableService.ProposalVariableType>;
  folderMeta: FolderMetaService.FolderMetaType;
  slides?: Record<string, ProposalSlideService.ProposalSlideType>;
};

export default function DeckVariablesForm(props: Props): JSX.Element {
  const { deck, groupedVariablesSetOnDeck, variables, folderMeta, slides } = props;
  const variableValues = deck?.variables;
  const isDeckPublished = deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;
  const variablesSetOnDeck = groupedVariablesSetOnDeck[ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.DEFAULT] || [];
  const invoiceVariablesSetOnDeck =
    groupedVariablesSetOnDeck[ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.INVOICE_ITEM] || [];

  return (
    <div>
      <VariablesGroupedBySlides
        deck={deck}
        slides={slides}
        variableIdsSetOnDeck={variablesSetOnDeck}
        variables={variables}
        isDeckPublished={isDeckPublished}
      />
      {variablesSetOnDeck.length === 0 && (
        <Alert style={ALERT_STYLES.INFO}>
          There are no variables set on Proposal. <Space vertical size={1} />
          You can add it by clicking on the <Text boldness={TEXT_BOLDNESS.BOLD}>Add Variable button</Text> in the
          content area
        </Alert>
      )}
      <Space vertical size={8} />
      <Text block boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3}>
        Invoice Items
      </Text>
      <Space vertical />
      {invoiceVariablesSetOnDeck.length === 0 && (
        <Alert style={ALERT_STYLES.INFO}>
          There are no invoice items on the Proposal. <Space vertical size={1} />
          You can add it on the <Text boldness={TEXT_BOLDNESS.BOLD}>Package slide</Text>
        </Alert>
      )}
      {invoiceVariablesSetOnDeck.map((variableId) => {
        const variable = variables[variableId];
        const InputComponent = getInputComponent(variable.type);
        return (
          <FormGroup key={variableId}>
            <Row vcenter>
              <Col>
                <FormLabel>{variable.name}</FormLabel>
              </Col>
              <Col>
                <ErrorBoundary>
                  {/* @ts-ignore */}
                  <InputComponent
                    disabled={isDeckPublished}
                    name={`variables[${variableId}]`}
                    type={variable.type}
                    // @ts-ignore
                    currencyCode={folderMeta.currency_code || 'INR'}
                    // @ts-ignore
                    defaultValue={variableValues?.[variableId] || variable.default_value}
                  />
                </ErrorBoundary>
              </Col>
            </Row>
          </FormGroup>
        );
      })}
    </div>
  );
}
