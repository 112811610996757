import { PresetDesignService } from '@premagic/core';
import {
  Col,
  COLOR_SHADES,
  FormLabel,
  Grid,
  LoadingDots,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  SubTitle,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import PresetDesignButtonContainer from './PresetDesignButtonContainer';

type PresetDesignItemProps = {
  onChange: (value) => void;
  selectedPresetDesign: string;
  groupTitle: string;
  group: PresetDesignService.PRESET_DESIGN_GROUPS;
  isPresetDesignSelectorEnabled: boolean;
  variant?: PresetDesignService.PRESET_DESIGN_VARIANT;
};

function PresetDesignItem(props: PresetDesignItemProps) {
  const { onChange, selectedPresetDesign, groupTitle, group, isPresetDesignSelectorEnabled, variant } = props;
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_6} color={COLOR_SHADES.LIGHT}>
        {groupTitle}
      </Text>
      <Space vertical size={1} />
      <Grid colSize={124} gap={1}>
        {PresetDesignService.getPresetDesignsForGroup(group).map((option) => (
          <PresetDesignButtonContainer
            key={option.id}
            onChange={onChange}
            selectedPresetDesign={selectedPresetDesign}
            option={option}
            isPresetDesignSelectorEnabled={isPresetDesignSelectorEnabled}
            variant={variant}
          />
        ))}
      </Grid>
      <Space size={2} vertical />
    </div>
  );
}

type Props = {
  onChange: (value) => void;
  selectedPresetDesign: string;
  isLoading?: boolean;
  isPresetDesignSelectorEnabled: boolean;
  headingTitle?: string;
  variant?: PresetDesignService.PRESET_DESIGN_VARIANT;
  isInsideCard?: boolean;
};

export default function PresetDesignSelector(props: Props) {
  const {
    onChange,
    selectedPresetDesign,
    isLoading,
    headingTitle = 'Design Presets',
    isPresetDesignSelectorEnabled,
    variant = PresetDesignService.PRESET_DESIGN_VARIANT.PRIMARY,
    isInsideCard,
  } = props;

  return (
    <>
      {isInsideCard && <Space vertical />}
      <Row vcenter>
        {isInsideCard && <Space />}
        {isInsideCard ? <SubTitle>{headingTitle}</SubTitle> : <FormLabel>{headingTitle}</FormLabel>}
        <Col rightAlighed size={null}>
          {isLoading ? <LoadingDots size="xs" /> : <Space size={1} />}
        </Col>
        <Space />
      </Row>
      <ScrollableContainer direction="vertical" size={SCROLLABLE_CONTAINER_SIZE.SM} showShadow>
        <Space size={isInsideCard ? 4 : 0}>
          <PresetDesignItem
            onChange={onChange}
            groupTitle="Black And White"
            selectedPresetDesign={selectedPresetDesign}
            group={PresetDesignService.PRESET_DESIGN_GROUPS.BLACK_AND_WHITE}
            isPresetDesignSelectorEnabled={isPresetDesignSelectorEnabled}
            variant={variant}
          />
          <PresetDesignItem
            onChange={onChange}
            groupTitle="Pastel Light"
            selectedPresetDesign={selectedPresetDesign}
            group={PresetDesignService.PRESET_DESIGN_GROUPS.PASTEL_LIGHT}
            isPresetDesignSelectorEnabled={isPresetDesignSelectorEnabled}
            variant={variant}
          />
          <PresetDesignItem
            onChange={onChange}
            groupTitle="Pastel Dark"
            selectedPresetDesign={selectedPresetDesign}
            group={PresetDesignService.PRESET_DESIGN_GROUPS.PASTEL_DARK}
            isPresetDesignSelectorEnabled={isPresetDesignSelectorEnabled}
            variant={variant}
          />
          <PresetDesignItem
            onChange={onChange}
            groupTitle="Bright"
            selectedPresetDesign={selectedPresetDesign}
            group={PresetDesignService.PRESET_DESIGN_GROUPS.BRIGHT}
            isPresetDesignSelectorEnabled={isPresetDesignSelectorEnabled}
            variant={variant}
          />
          <PresetDesignItem
            onChange={onChange}
            groupTitle="Dark"
            selectedPresetDesign={selectedPresetDesign}
            group={PresetDesignService.PRESET_DESIGN_GROUPS.DARK}
            isPresetDesignSelectorEnabled={isPresetDesignSelectorEnabled}
            variant={variant}
          />
        </Space>
      </ScrollableContainer>
    </>
  );
}
