import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
} from '@premagic/myne';
import MemoryTemplateFormContainer from './MemoryTemplateFormContainer';

type Props = {
  addTemplate: (options: { projectId: string; eventId: string }, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  eventId: string;
  isLoading: boolean;
  error: FormErrorType;
};

export default function AddMemoryTemplatePanel(props: Props) {
  const { show, close, projectId, eventId, addTemplate, isLoading, error } = props;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add new Template</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          formSpec={{
            name: { validator: 'required' },
            age: { validator: 'required' },
            target: { validator: 'required' },
          }}
          onSubmit={(formResponse) => addTemplate({ eventId, projectId }, formResponse)}
        >
          <MemoryTemplateFormContainer projectId={projectId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
