import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import { memoryTemplatesSelector, removeMemoryTemplate } from '../MemoriesTemplatesDataDuck';
import MemoryTemplateItemOptions from './MemoryTemplateItemOptions';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    id: string;
  },
) {
  const { eventId, projectId, id } = ownProps;

  return {
    eventId,
    projectId,
    id,
    template: memoryTemplatesSelector(state)[id],
    isDeleting: LoadingDuck.isLoadingSelector(LOADINGS.MEMORY_TEMPLATES.DELETE(id))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeTemplate: (projectId, id) => {
      dispatch(removeMemoryTemplate(dispatch, projectId, id));
    },
    showEditTemplate: (id) => {
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_EDIT_PANEL, id));
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoryTemplateItemOptions);
