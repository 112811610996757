import { connect } from 'react-redux';
import { isFolderBlockedSelector, isFolderPublicSelector } from '../folder-meta/FoldersMetaDataDuck';
import AccountFolderItem from './AccountFolderItem';
import { foldersEntitiesSelector } from './AccountFoldersDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderId?: string }) {
  const { projectId, folderId } = ownProps;

  return {
    folder: folderId ? foldersEntitiesSelector(state)[folderId] : undefined,
    isFolderBlocked: isFolderBlockedSelector(folderId)(state),
    projectId,
    folderId,
    isFolderPublic: isFolderPublicSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFolderItem);
