import { $Keys } from 'utility-types';

import React, { Component } from 'react';
import { InnerPageHeader, Text, InnerPageContent, InnerPage, InnerPageSubHeader } from '@premagic/myne';
import CustomerLeadContainer from './CustomerLeadContainer';
import { CUSTOMER_LEAD_STATUS } from './CustomerLeadsService';
import CustomerLeadsTabsContainer from './CustomerLeadsTabsContainer';

type Props = {
  initData: () => void;
  leads: Array<string>;
  isLoading: boolean;
  activeStatus: $Keys<typeof CUSTOMER_LEAD_STATUS>;
};

export default class CustomerLeadsPage extends Component<Props> {
  componentDidMount() {
    const { initData } = this.props;
    initData();
  }

  render() {
    const { leads, isLoading, activeStatus } = this.props;

    return (
      <InnerPage>
        <InnerPageHeader iconName="git_commit" title="Leads">
          Leads
        </InnerPageHeader>
        <InnerPageSubHeader>
          <CustomerLeadsTabsContainer activeStatus={activeStatus} />
        </InnerPageSubHeader>
        <InnerPageContent isLoading={isLoading}>
          <div>
            {leads.length === 0 && (
              <div>
                <Text muted>No leads</Text>
              </div>
            )}
            {leads.map((lead) => (
              <CustomerLeadContainer key={lead} id={lead} />
            ))}
          </div>
        </InnerPageContent>
      </InnerPage>
    );
  }
}
