import { Space, Text, InnerPageContentMainCol, InnerPageContentSidebar, SubTitle, Row, Col } from '@premagic/myne';
import { FileService, ProjectMetaService, ProjectService } from '@premagic/core';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import ProjectHighlightCoverPhotoContainer from '../../highlight-settings/ProjectHighlightCoverPhotoContainer';
import ProjectCoverFocalPointContainer from '../../highlight-settings/ProjectCoverFocalPointContainer';
import HighlightFoldersPropertiesSettingsContainer from './HighlightFoldersPropertiesSettingsContainer';
import HighlightDesignCoverLayoutSelectorContainer from '../highlight-gallery-design/HighlightDesignCoverLayoutSelectorContainer';
import HighlightGalleryDesignLayoutContainer from '../highlight-gallery-design/HighlightGalleryDesignLayoutContainer';

type Props = {
  projectId: string;
  project: ProjectService.ProjectType;
  folderIds: Array<string>;
  coverPhoto?: FileService.FolderFileType | null;
  updateProjectMeta: (projectId: string, formResponse: any) => void;
  coverLayout?: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
};

export default function HighlightFoldersPageCoverSettingsLayout(props: Props): JSX.Element {
  const { projectId, project, folderIds, coverPhoto, updateProjectMeta, coverLayout } = props;

  const [highlightAlbumCoverLayout, setHighlightAlbumCoverLayout] = useState(
    coverLayout || ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT,
  );

  // debouncing this function to get smooth transition effect on layout change
  const debouncedLayoutChange = useMemo(() => {
    function highlightLayoutChange(value) {
      updateProjectMeta(projectId, {
        data: {
          [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT]: value as string,
        },
      });
    }
    return debounce(highlightLayoutChange, 500);
  }, [projectId, updateProjectMeta]);

  function handleLayoutChange(value) {
    setHighlightAlbumCoverLayout(value);
    debouncedLayoutChange(value);
  }

  return (
    <>
      <Col size={12}>
        <Row>
          <InnerPageContentMainCol>
            <ProjectHighlightCoverPhotoContainer projectId={projectId} coverLayout={highlightAlbumCoverLayout} />
          </InnerPageContentMainCol>
          <InnerPageContentSidebar>
            <HighlightDesignCoverLayoutSelectorContainer
              onChange={handleLayoutChange}
              coverLayout={highlightAlbumCoverLayout}
              imageSource={coverPhoto?.dynamic_image_url}
              alt={coverPhoto?.image_key}
              title={project?.project_name}
            />
            <Space vertical size={6} />
            <HighlightFoldersPropertiesSettingsContainer projectId={projectId} />
          </InnerPageContentSidebar>
        </Row>
        <Space vertical />
        <Row>
          <HighlightGalleryDesignLayoutContainer projectId={projectId} />
        </Row>
      </Col>
      <ProjectCoverFocalPointContainer projectId={projectId} />
    </>
  );
}
