import { HttpUtils, MediaUtils, i18n } from '@premagic/utils';
import {
  FOLDER_THUMBNAIL_VIEWS,
  GUEST_GALLERY_VIEWS,
  HIGHLIGHT_DESIGN_COVER_LAYOUTS,
  HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES,
} from './entity-meta/ProjectMetaService';
import { API_URLS } from './APIURLService';
import { EVENT_CATEGORY } from './EventsService';
import { StorePricingType } from './StorePricingService';
import { IntegrationClientSettingsType } from './IntegrationService';

export enum CLIENT_SETTINGS_META_TYPES {
  DISABLE_SIGNATURE_ALBUM_UPSELL = 'disable_signature_album_upsell',
  SINGLE_FILE_DOWNLOAD_QUALITY = 'single_file_download_quality',
  HIGHLIGHT_UPLOAD_QUALITY_WEB = 'highlight_upload_quality_web',
  DEFAULT_SELECTION_BUDGET = 'default_selection_budget',
  SIGNATURE_IMAGE_BACKGROUND_COLOR = 'signature_image_background_color',
  HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY = 'highlight_design__primary_font_family',
  HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY = 'highlight_design__secondary_font_family',
  HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY_SOURCE = 'highlight_design__primary_font_family_source',
  HIGHLIGHT_DESIGN_BACKGROUND_COLOR = 'highlight_design__background_color',
  HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR = 'highlight_design__primary_font_color',
  HIGHLIGHT_DESIGN_COVER_LAYOUT = 'highlight_design__cover_layout',
  HIGHLIGHT_DESIGN_ACCENT_COLOR = 'highlight_design__accent_color',
  HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE = 'highlight_design__gallery_grid_space',
  HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE = 'highlight_design__gallery_thumbnail_size',
  HIGHLIGHT_DESIGN_ENDING_TEXT = 'highlight_design__ending_text',
  HIGHLIGHT_DESIGN_WELCOME_TEXT = 'highlight_design__welcome_text',
  FOLDER_THUMBNAIL_VIEW_ENABLED = 'folder_thumbnail_view_enabled',
  FOLDER_THUMBNAIL_VIEW = 'folder_thumbnail_view',
  HIGHLIGHT_DESIGN_COVER_HIDE_TITLE = 'highlight_design__cover_hide_title',
  HIGHLIGHT_DESIGN_COVER_HIDE_LOGO = 'highlight_design__cover_hide_logo',
  SHOW_WATERMARK_ON_CLIENT_INVOICE = 'show_watermark_on_client_invoice',
  DISABLED_UPSELL_ON_DOWNLOAD_PAGE = 'disabled_upsell_on_download_page',
  STORE_PRICING = 'store_pricing',
  GUEST_GALLERY_DAY_VIEWS = 'guest_gallery_day_views',
  GUEST_GALLERY_VIEW = 'guest_gallery_view',
  ENABLE_SELECTION_ALBUM = 'enable_selection_album',
  SIGNATURE_AI_ALBUM_SHOW_ALL_FACES = 'signature_ai_show_unregistered_faces',
  FEATURE_FINANCIALS_ENABLE = 'feature__financial_enable',
  FEATURE_EVENTS_OCCASIONS_ENABLE = 'feature__events_occasion_enable',
  FEATURE_PROPOSAL_ENABLE = 'feature__proposal_enable',
  FEATURE_PROJECTS_TAB_ENABLE = 'feature__project_tab_enable',
  FEATURE_EXHIBITOR_TAB_ENABLE = 'feature__exhibitor_tab_enable',
  FEATURE_DAM_LIBRARY_ENABLE = 'feature__dam_library_enable',
  FEATURE_EVENT_MARKETING_POSTERS_ENABLE = 'feature__event_marketing__posters_enable',
  FEATURE_LEADS_DISABLE = 'hide_enquire_form',
  COMPANY_TYPE = 'company_type',
  ENABLE_SELECTION_MORE_THAN_LIMIT = 'enable_selection_more_than_limit',
  EVENT_REGISTRATION_HIDE_EMAIL_FIELD = 'event_registration_hide_email_field',
  SHOW_EVENT_FEEDBACK_FORM = 'show_event_feedback_form',
  ADDON_AI_LIMIT = 'addon__ai_limit',
  ENABLE_REPORTING_ISSUE_FROM_PEOPLE = 'enable_reporting_issue_from_people',
  ENABLE_REPORTING_THESE_ARE_NOT_MY_PHOTOS = 'enable_reporting_these_are_not_my_photos',
  REQUEST_FOR_USER_DATA_FOR_MARKETING = 'request_for_user_data_for_marketing',
  GUEST_GALLERY_PERMISSION_SHOW_PUBLIC_FOLDER_TO_ALL = 'guest_gallery_permission_show_public_folder_to_all',
  GUEST_GALLERY_SHOW_LINKEDIN_POST_SHARE = 'guest_gallery_show_linkedin_post_share',
  EVENT_REGISTRATION_DEFAULT_CONSENT_TO_USE_DATA = 'event_registration_default_consent_to_use_data', // have_consent_to_use_data
  ENABLE_NETWORKING_PEOPLE_LIST = 'enable_networking_people_list',
  ENABLE_CONTEXT_MENU_IN_FULL_VIEW_GALLERY = 'enable_context_menu_in_full_view_gallery',
  DEFAULT_LANG = 'default_lang',
  ENABLE_MANUAL_PUBLISH_FOLDER = 'enable_manual_publish_folders',
  INTEGRATIONS = 'integrations',
  MARKETING_GENERATE_LEAD = 'marketing__generate_lead',
}

export type ClientNotificationSettingsType = {
  share_on_whatsapp: boolean;
  share_on_email: boolean;
  selection_reminder_to_client: boolean;
};

export enum CLIENT_COMPANY_TYPES {
  PHOTOGRAPHY = 'photography',
  EVENT = 'event',
  ORGANIZER = 'organized',
}

export const CLIENT_COMPANY_TYPE_DETAILS: Record<
  CLIENT_COMPANY_TYPES,
  {
    id: CLIENT_COMPANY_TYPES;
    title: string;
    clientTitle: string;
  }
> = {
  [CLIENT_COMPANY_TYPES.PHOTOGRAPHY]: {
    id: CLIENT_COMPANY_TYPES.PHOTOGRAPHY,
    title: 'Photography',
    clientTitle: 'Photography by',
  },
  [CLIENT_COMPANY_TYPES.EVENT]: {
    id: CLIENT_COMPANY_TYPES.EVENT,
    title: 'Event Planner',
    clientTitle: 'Event By',
  },
  [CLIENT_COMPANY_TYPES.ORGANIZER]: {
    id: CLIENT_COMPANY_TYPES.ORGANIZER,
    title: 'Event organizer',
    clientTitle: 'Organized By',
  },
};

export type ClientSettingsType = {
  enable_selection_download: boolean;
  enable_highlight_download: boolean;
  require_pin_to_download: boolean;
  order_highlight_photos_by_date: boolean;
  notifications: ClientNotificationSettingsType;
  enable_highlight_favorite: boolean;
  enable_selection_favorite: boolean;
  [CLIENT_SETTINGS_META_TYPES.DISABLE_SIGNATURE_ALBUM_UPSELL]: boolean;
  disable_selection_album_upsell: boolean;
  [CLIENT_SETTINGS_META_TYPES.DISABLED_UPSELL_ON_DOWNLOAD_PAGE]?: boolean;
  disable_web_upload_photos_limit: boolean;
  enable_web_upload_photos_compress: boolean;
  [CLIENT_SETTINGS_META_TYPES.FEATURE_LEADS_DISABLE]: boolean;
  show_powered_by_premagic: boolean;
  support_access_to_account: boolean;
  services_offered?: Array<EVENT_CATEGORY>;
  enable_beta_watermark?: boolean;
  custom_policy?: {
    terms_of_service?: string;
    privacy_policy?: string;
  };
  [CLIENT_SETTINGS_META_TYPES.DEFAULT_SELECTION_BUDGET]: number;
  [CLIENT_SETTINGS_META_TYPES.SIGNATURE_IMAGE_BACKGROUND_COLOR]: boolean;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY_SOURCE]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT]?: HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE]?: number;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE]?: HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT]?: string;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT]?: string;
  [CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW]?: FOLDER_THUMBNAIL_VIEWS;
  [CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_TITLE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_LOGO]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.SHOW_WATERMARK_ON_CLIENT_INVOICE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.SINGLE_FILE_DOWNLOAD_QUALITY]?: MediaUtils.DOWNLOAD_QUALITY_TYPES;
  [CLIENT_SETTINGS_META_TYPES.STORE_PRICING]?: StorePricingType;
  [CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_DAY_VIEWS]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_VIEW]?: GUEST_GALLERY_VIEWS;
  [CLIENT_SETTINGS_META_TYPES.ENABLE_SELECTION_ALBUM]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.FEATURE_EVENTS_OCCASIONS_ENABLE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.FEATURE_PROPOSAL_ENABLE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_UPLOAD_QUALITY_WEB]?: MediaUtils.UPLOAD_QUALITY_TYPES;
  [CLIENT_SETTINGS_META_TYPES.COMPANY_TYPE]?: CLIENT_COMPANY_TYPES;
  [CLIENT_SETTINGS_META_TYPES.ENABLE_SELECTION_MORE_THAN_LIMIT]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.SHOW_EVENT_FEEDBACK_FORM]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.ADDON_AI_LIMIT]?: number;
  [CLIENT_SETTINGS_META_TYPES.ENABLE_REPORTING_ISSUE_FROM_PEOPLE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.ENABLE_REPORTING_THESE_ARE_NOT_MY_PHOTOS]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.REQUEST_FOR_USER_DATA_FOR_MARKETING]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_SHOW_LINKEDIN_POST_SHARE]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.ENABLE_NETWORKING_PEOPLE_LIST]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.ENABLE_CONTEXT_MENU_IN_FULL_VIEW_GALLERY]?: boolean;
  [CLIENT_SETTINGS_META_TYPES.DEFAULT_LANG]?: i18n.LANGUAGE_CODE;
  [CLIENT_SETTINGS_META_TYPES.INTEGRATIONS]?: IntegrationClientSettingsType;
  [CLIENT_SETTINGS_META_TYPES.MARKETING_GENERATE_LEAD]?: boolean;
};

export function fetchClientSettings() {
  return HttpUtils.get(API_URLS.ACCOUNT_CLIENT_SETTINGS.ROOT, {}, false).then(({ data }) => data);
}

export function saveClientSettingsService(data: Partial<ClientSettingsType>) {
  return HttpUtils.post(API_URLS.ACCOUNT_CLIENT_SETTINGS.ROOT, data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}

export function isCompanyTypeNotPhotographer(clientSettings: ClientSettingsType) {
  const type = clientSettings[CLIENT_SETTINGS_META_TYPES.COMPANY_TYPE] || CLIENT_COMPANY_TYPES.PHOTOGRAPHY;
  return type !== CLIENT_COMPANY_TYPES.PHOTOGRAPHY;
}
