import React from 'react';
import { InnerPageHeader, InnerPageSubHeader, Tabs, TabItem } from '@premagic/myne';

import APP_URLS from '../../../services/AppRouteURLService';
import AddProposalTemplateContainer from '../../proposal-management/proposal-templates/AddProposalTemplateContainer';

type Props = {
  currentUrlPathname: string;
};

export default function ProposalSettingsHeader(props: Props) {
  const { currentUrlPathname } = props;

  return (
    <>
      <InnerPageHeader
        iconName="film"
        title="Proposal management"
        backTo={APP_URLS.SETTINGS.INDEX}
        rightActions={<AddProposalTemplateContainer />}
      >
        Proposal management
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem
            to={APP_URLS.PROPOSALS_TEMPLATES.LIST}
            active={currentUrlPathname.includes(APP_URLS.PROPOSALS_TEMPLATES.LIST)}
          >
            Templates
          </TabItem>
          <TabItem to={APP_URLS.PROPOSALS.SETTINGS} active={currentUrlPathname.includes(APP_URLS.PROPOSALS.SETTINGS)}>
            Settings
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
    </>
  );
}
