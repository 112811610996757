import { connect } from 'react-redux';
import {
  ProposalVariablesDataDuck,
  ProposalSlideDataDuck,
  ProposalVariableService,
  ProposalDeckDataDuck,
} from '@premagic/proposals';

import ProposalCreatorSlideDataUpdater from './ProposalCreatorSlideDataUpdater';
import {
  activeProposalSlideSelector,
  proposalCreatorSelectedSlideSelector,
  showAddNewProposalParameter,
} from '../ProposalCreatorDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, folderId, deckId } = ownProps;
  const slideDetails = activeProposalSlideSelector(state);
  let variablesSetOnSlide;
  if (slideDetails?.id) {
    variablesSetOnSlide = ProposalSlideDataDuck.proposalsVariablesInSlideSelectors(slideDetails?.id)(state);
  }

  return {
    projectId,
    folderId,
    deckId,
    key: proposalCreatorSelectedSlideSelector(state),
    slideDetails,
    defaultVariables: ProposalVariablesDataDuck.proposalsVariableDefaultItemsSelectors(state),
    variablesSetOnSlide,
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProposalSlide: (options, data) => dispatch(ProposalSlideDataDuck.saveProposalSlide(dispatch, options, data)),
    showAddNewVariable: () => {
      dispatch(showAddNewProposalParameter(dispatch, ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.DEFAULT));
    },
    saveDeck: (projectId, deckId, data) => {
      dispatch(ProposalDeckDataDuck.saveProposalDeck(dispatch, projectId, deckId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorSlideDataUpdater);
