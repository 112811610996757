import React, { useEffect } from 'react';
import { ClientWebsiteFormService, EventCustomFromFieldsService } from '@premagic/core';
import {
  BUTTON_ICON_STYLES,
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  List,
  ListItem,
  ListItemAction,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import MESSAGES from '../../../../../common/Messages';
import { PERMISSIONS } from '../../crm/users/UserPermissionService';
import UserRegistrationFormFieldItemContainer from './UserRegistrationFormFieldItemContainer';

function isHidden(
  field: EventCustomFromFieldsService.FormFieldType,
  options: {
    isEmailHiddenOnRegistrationForm: boolean;
    eventRegistrationSendNotification?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  },
) {
  if (field.name === EventCustomFromFieldsService.SYSTEM_FORM_FIELDS.EMAIL) {
    return (
      options?.eventRegistrationSendNotification ===
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE ||
      options.isEmailHiddenOnRegistrationForm
    );
  }
  if (field.name === EventCustomFromFieldsService.SYSTEM_FORM_FIELDS.PHONE) {
    return (
      options?.eventRegistrationSendNotification ===
      ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL
    );
  }
  return false;
}
function isRequired(
  field: EventCustomFromFieldsService.FormFieldType,
  options: {
    isEventRegistrationEmailRequired: boolean;
    eventRegistrationSendNotificationRequired?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  },
) {
  if (field.name === EventCustomFromFieldsService.SYSTEM_FORM_FIELDS.EMAIL) {
    return (
      options?.eventRegistrationSendNotificationRequired ===
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL ||
      options?.eventRegistrationSendNotificationRequired ===
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH ||
      options.isEventRegistrationEmailRequired
    );
  }
  if (field.name === EventCustomFromFieldsService.SYSTEM_FORM_FIELDS.PHONE) {
    return (
      options?.eventRegistrationSendNotificationRequired ===
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE ||
      options?.eventRegistrationSendNotificationRequired ===
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH
    );
  }
  return field.is_required;
}

type Props = {
  isLoading: boolean;
  formFieldIds: Array<string>;
  projectId: string;
  initData: (projectId: string) => void;
  isEmailHiddenOnRegistrationForm: boolean;
  isEventRegistrationEmailRequired: boolean;
  eventRegistrationSendNotification?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  eventRegistrationSendNotificationRequired?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  showEditFormField: (id: string) => void;
  hasPermissions: {
    [PERMISSIONS.EVENT_FORM_FIELD]: boolean;
    [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: boolean;
  };
};
export default function UserRegistrationFormFieldList(props: Props): JSX.Element {
  const {
    projectId,
    isLoading,
    formFieldIds,
    initData,
    isEmailHiddenOnRegistrationForm,
    isEventRegistrationEmailRequired,
    eventRegistrationSendNotification,
    eventRegistrationSendNotificationRequired,
    showEditFormField,
    hasPermissions,
  } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  if (isLoading)
    return (
      <Row center columnDirection>
        <Space vertical size={8} />
        <LoadingDots />
        <Space vertical size={8} />
      </Row>
    );
  const hasPermissionsToManageFormFields = hasPermissions[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT];

  return (
    <List>
      {Object.entries(EventCustomFromFieldsService.SYSTEM_FORM_FIELDS_DETAILS).map(([id, field]) => {
        const isHiddenField = isHidden(field, {
          isEmailHiddenOnRegistrationForm,
          eventRegistrationSendNotification,
        });
        const isRequiredField = isRequired(field, {
          isEventRegistrationEmailRequired,
          eventRegistrationSendNotificationRequired,
        });
        return (
          <ListItem key={field.name}>
            <Text color={isHiddenField ? COLOR_SHADES.LIGHT : COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
              {field.label}
              {isRequiredField ? (
                <Text color={COLOR_SHADES.DANGER}>*</Text>
              ) : (
                <Text muted size={TEXT_SIZE.SIZE_5}>
                  <Space size={1} /> (Optional)
                </Text>
              )}
            </Text>
            {isHiddenField && (
              <>
                <Space size={2} />
                {HTML_ICON.DOT}
                <Space size={2} />
                <Text color={COLOR_SHADES.PINK} boldness={TEXT_BOLDNESS.BOLD}>
                  HIDDEN
                </Text>
              </>
            )}
            <ListItemAction>
              {field.isConfigurable && (
                <>
                  <ButtonIcon
                    disabled={!hasPermissionsToManageFormFields}
                    disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]}
                    style={BUTTON_ICON_STYLES.SECONDARY}
                    size={BUTTON_ICONS_SIZES.SM}
                    title="Edit"
                    onClick={() => showEditFormField(field.name)}
                  >
                    <Icon name="edit_2" size={ICON_SIZES.SM} />
                  </ButtonIcon>
                  <Space size={2} />
                </>
              )}
              <Tooltip message="Default Form fields cannot be modified">
                <Text muted>DEFAULT</Text>
              </Tooltip>
            </ListItemAction>
          </ListItem>
        );
      })}
      {formFieldIds.map((id) => (
        <UserRegistrationFormFieldItemContainer id={id} projectId={projectId} key={id} />
      ))}
    </List>
  );
}
