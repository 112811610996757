import { connect } from 'react-redux';
import { fetchEventDataWithDependencyData } from '../EventsDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import EventDetailsPage from './EventDetailsPage';
import {
  clientWebsiteIdForProjectSelector,
  eventProjectSelectorSelector,
  fetchProjectForEvent,
} from '../../../projects/AccountProjectsDataDuck';
import { hasPermission } from '../../users/UsersDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isFeatureLeadEnabledSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;
  const { pathname } = ownProps.location;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project ? project.project_id : undefined;

  return {
    eventId,
    projectId,
    clientWebsiteId: clientWebsiteIdForProjectSelector(projectId)(state),
    currentUrlPathname: pathname,
    isLoading:
      isLoadingSelector(LOADINGS.EVENT.FETCH_SINGLE)(state) || isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    hasPermissions: {
      [PERMISSIONS.EVENT_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_MANAGEMENT)(state),
      [PERMISSIONS.EVENT_DELIVERABLE]: hasPermission(PERMISSIONS.EVENT_DELIVERABLE)(state),
      [PERMISSIONS.EVENT_OCCASION]: hasPermission(PERMISSIONS.EVENT_OCCASION)(state),
      [PERMISSIONS.EVENT_FINANCIAL]: hasPermission(PERMISSIONS.EVENT_FINANCIAL)(state),
      [PERMISSIONS.EVENT_SPONSOR]: hasPermission(PERMISSIONS.EVENT_SPONSOR)(state),
      [PERMISSIONS.EVENT_SPONSOR_REPORT]: hasPermission(PERMISSIONS.EVENT_SPONSOR_REPORT)(state),
      [PERMISSIONS.EVENT_EXHIBITOR]: hasPermission(PERMISSIONS.EVENT_EXHIBITOR)(state),
      [PERMISSIONS.EVENT_ATTENDEES]: hasPermission(PERMISSIONS.EVENT_ATTENDEES)(state),
      [PERMISSIONS.EVENT_SETTINGS]: hasPermission(PERMISSIONS.EVENT_SETTINGS)(state),
    },
    errors: errorObjectForTypeSelector(LOADINGS.EVENT.FETCH_SINGLE)(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (eventId: string) => {
      dispatch(fetchEventDataWithDependencyData(dispatch, eventId));
      dispatch(fetchProjectForEvent(dispatch, eventId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsPage);
