import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ProjectBackgroundTaskService } from '@premagic/core';
import { ActionTypeUtils, ArrayUtils } from '@premagic/utils';
import { entitiesDataSelector, EntitiesStateType } from '../../../reducers/selectors';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_BACKGROUND_TASKS');

const setProjectBackgroundTasksData = createAction(getActionType('DATA', 'SET'), (dispatch, projectId, data) => ({
  [projectId]: data,
}));

export const getProjectBackgroundTasksData = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.PROJECT_BACKGROUND_TASKS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));

    ProjectBackgroundTaskService.fetchProjectBackgroundTasks(projectId)
      .then((response) => {
        dispatch(setProjectBackgroundTasksData(dispatch, projectId, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

type StateType = {
  items: IMap<string, ProjectBackgroundTaskService.ProjectBackgroundTaskType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setProjectBackgroundTasksData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const projectBackgroundTasksDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.projectsBackgroundTasks as StateType).items,
);

export const projectBackgroundTasksForProjectSelector = (projectId) =>
  createSelector(projectBackgroundTasksDataSelector, (items) => {
    const item = items.get(projectId);
    if (!item) return undefined;

    return Object.entries(item).reduce((result, [key, metric]) => {
      if (metric.status)
        return {
          ...result,
          [key]: metric,
        };
      return result;
    }, {});
  });
