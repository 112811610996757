import React from 'react';
import { Text, LoadingDots } from '@premagic/myne';
import EventOccasionListItemContainer from './EventOccasionItemContainer';

type Props = {
  eventId: string;
  focusId?: string;
  isLoading: boolean;
  occasionIds: Array<string>;
};

export default function EventOccasions(props: Props): React.ReactElement {
  const { eventId, focusId, occasionIds, isLoading } = props;
  if (isLoading) {
    return <LoadingDots />;
  }

  if (occasionIds.length === 0) {
    return <Text muted>You haven&apos;t added any occasion to this event</Text>;
  }
  return (
    <div>
      {occasionIds.map((occasionId) => (
        <EventOccasionListItemContainer
          eventId={eventId}
          occasionId={occasionId}
          key={occasionId}
          focus={occasionId === focusId}
        />
      ))}
    </div>
  );
}
