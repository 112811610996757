import { $Keys } from 'utility-types';

import React from 'react';

import { SimpleDateUtils } from '@premagic/utils';
import {
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Select,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

import { CUSTOMER_LEAD_STATUS, CustomerLeadType } from './CustomerLeadsService';

const customerLeadStatusSelectOptions = Object.values(CUSTOMER_LEAD_STATUS).map((statusInfo) => ({
  label: statusInfo.label,
  value: statusInfo.id,
}));

type Props = {
  updateStatus: (id: string, status: $Keys<typeof CUSTOMER_LEAD_STATUS>) => void;
  lead: CustomerLeadType;
  id: string;
  isMobileUp: boolean;
};

export default function CustomerLead(props: Props) {
  const { updateStatus, id, isMobileUp } = props;
  const { lead } = props;
  const { name, phone, project_name: projectName, status, created_at: createdAt } = lead;

  return (
    <Card>
      <Space>
        <Row columnDirection={!isMobileUp}>
          <Col size={null}>
            <Space vertical size={2} />
            <Icon name="user" />
          </Col>
          <Space />
          <Col size={4} screenSMSize={12}>
            <Text color={COLOR_SHADES.DARKER} block>
              {name}
            </Text>
            <SimpleLink href={`tel:${phone}`} isExternalLink={false}>
              <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                {phone}
              </Text>
            </SimpleLink>
          </Col>
          <Col size={5} screenSMSize={12}>
            <Text block>{SimpleDateUtils.humanizeDate(createdAt, true)}</Text>
            <Space size={1} vertical />
            <Row vcenter>
              <Color shade={COLOR_SHADES.DARKER}>
                <Icon name="folder_simple" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} />
              <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD} block>
                {projectName}
              </Text>
            </Row>
          </Col>
          <Col size={2} screenSMSize={12}>
            <Text muted size={TEXT_SIZE.SIZE_5}>
              STATUS
            </Text>
            <Select
              isMulti={false}
              name="lead-status"
              value={{
                label: CUSTOMER_LEAD_STATUS[status].label,
                value: status,
              }}
              onChange={(option) => {
                // @ts-ignore
                updateStatus(id, option.label);
              }}
              options={customerLeadStatusSelectOptions}
            />
          </Col>
        </Row>
      </Space>
    </Card>
  );
}
