import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';
import { Integrations } from '@sentry/tracing';

import { getEnvValues, isProd } from './EnvUtils';
import { THIRD_PARTY_APPLICATIONS } from './config';
import { APPS } from './AppUtils';

type ReactErrorInfo = {
  componentStack: string;
};

function getIgnoreError() {
  return [];
}

function hasRaven() {
  return isProd();
}

const log = console;

function initRaven(appName: APPS) {
  Sentry.init({
    dsn: THIRD_PARTY_APPLICATIONS.SENTRY[appName],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: getEnvValues().NX_PUBLIC_ENV,
    ignoreErrors: getIgnoreError(),
    attachStacktrace: true,
  });
}

export function setTag(name: string, value: string): void {
  Sentry.setTag(name, value);
}

export function setAppInformation(appName: APPS): void {
  setTag('app', appName);
}

export function setUserInfo(obj: Record<string, unknown>): void {
  Sentry.configureScope((scope) => {
    // scope.setTag('user_mode', 'admin');
    scope.setUser(obj);
  });
}

export function initErrorTracker(appName: APPS): void {
  if (!hasRaven()) return;

  initRaven(appName);
  setAppInformation(appName);
}

export function logMessage(message: string, level?: Severity): void {
  if (hasRaven()) {
    Sentry.captureMessage(message, level);
  }

  log.info(message);
}

export function logError(section: string, error: Error | ReactErrorInfo | string | unknown): void {
  const scope = new Sentry.Scope();
  scope.setTag('section', section);
  scope.setContext('Premagic Context', {
    section,
  });

  if (hasRaven()) {
    if (!error) {
      Sentry.captureException(new Error(section), scope);
      return;
    }

    if (typeof error === 'string') {
      Sentry.captureException(new Error(error), scope);
      return;
    }

    Sentry.captureException(error, scope);
  }

  log.error(section, error);
}

export const catchException = Sentry.captureException;

/**
 * This function will add the information to the Breadcrumb in sentry.
 * This will help us to understand what caused the error.
 */
export function logAction(message: string, meta: Record<string, unknown>, category = 'action'): void {
  if (hasRaven()) {
    Sentry.addBreadcrumb({
      message,
      category,
      data: meta,
    });
  }
  log.info({ message, meta });
}

export function showErrorToUser(message: string) {
  // eslint-disable-next-line
  alert(message);
}
