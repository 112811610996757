import { combineReducers } from 'redux';
import AccountAlbumDataDuck from '../components/album/AccountAlbumDataDuck';
import CustomerClientsDataDuck from '../components/clients/CustomerClientsDataDuck';
import ClientsDataDuck from '../components/crm/clients/ClientsDataDuck';
import UsersDataDuck from '../components/crm/users/UsersDataDuck';
import EventsDataDuck from '../components/crm/events/EventsDataDuck';
import CustomerLeadsDataDuck from '../components/leads/CustomerLeadsDataDuck';
import PhotographerActionsDataDuck from '../components/photographer-actions/PhotographerActionsDataDuck';
import AccountProjectsDataDuck from '../components/projects/AccountProjectsDataDuck';
import AccountFoldersDataDuck from '../components/projects/folders/AccountFoldersDataDuck';
import CompanyDataDuck from '../components/settings/company/CompanyDataDuck';
import ClientSettingsDataDuck from '../components/settings/preferences/ClientSettingsDataDuck';
import PricingPlansDataDuck from '../components/settings/pricing-plan/PricingPlansDataDuck';
import ClientWebsiteDataDuck from '../components/client-website/ClientWebsiteDataDuck';
import ClientWishDataDuck from '../components/client-website/client-wishes/ClientWishDataDuck';
import ClientWebsiteFilesDuck from '../components/client-website/client-files/ClientWebsiteFilesDataDuck';
import OccasionsDataDuck from '../components/crm/occassions/OccasionsDataDuck';
import FilesDataDuck from '../components/images/FilesDataDuck';
import FilesOrderDataDuck from '../components/images/FilesOrderDataDuck';
import ProjectOrdersDataDuck from '../components/project-orders/ProjectOrdersDataDuck';
import CRMPaymentDataDuck from '../components/crm/payments/CRMPaymentDataDuck';
import CRMPaymentCategoryDataDuck from '../components/crm/payments/CRMPaymentCategoryDataDuck';
import AccountDataDuck from '../components/acccount/AccountDataDuck';
import ProjectMetaDataDuck from '../components/projects/project-meta/ProjectsMetaDataDuck';
import SubscriptionsDataDuck from '../components/settings/subscriptions/SubscriptionsDataDuck';
import FoldersMetaDataDuck from '../components/projects/folder-meta/FoldersMetaDataDuck';
import ProjectsStatsDataDuck from '../components/projects/project-stats/ProjectsStatsDataDuck';
import PortfolioDataDuck from '../components/portfolio/PortfolioDataDuck';
import ProjectBackgroundTasksDataDuck from '../components/projects/project-background-tasks/ProjectBackgroundTasksDataDuck';
import LabelsDataDuck from '../components/crm/labels/LabelsDataDuck';
import ProjectNerdStatsDataDuck from '../components/projects/project-stats-nerd/ProjectNerdStatsDataDuck';
import CompanyStatsDataDuck from '../components/company/company-stats/CompanyStatsDataDuck';
import WorkflowDataDuck from '../components/crm/workflow/data-duck/WorkflowDataDuck';
import TaskGroupDataDuck from '../components/crm/workflow/data-duck/TaskGroupDataDuck';
import TaskDataDuck from '../components/crm/workflow/data-duck/TaskDataDuck';
import EventCustomFormFieldsDataDuck from '../components/event-custom-form-fields/EventCustomFormFieldsDataDuck';
import PeopleFacesDataDuck from '../components/images/PeopleFacesDataDuck';
import IntegrationDataDuck from '../components/integration-third-party-platforms/IntegrationDataDuck';

const workflowsReducer = combineReducers({
  workflow: WorkflowDataDuck,
  taskgroup: TaskGroupDataDuck,
  tasks: TaskDataDuck,
});

const crmReducer = combineReducers({
  clients: ClientsDataDuck,
  users: UsersDataDuck,
  events: EventsDataDuck,
  occasions: OccasionsDataDuck,
  payments: CRMPaymentDataDuck,
  categories: CRMPaymentCategoryDataDuck,
  labels: LabelsDataDuck,
  workflows: workflowsReducer,
});

export default combineReducers({
  account: AccountDataDuck,
  pricingPlans: PricingPlansDataDuck,
  subscriptions: SubscriptionsDataDuck,
  company: CompanyDataDuck,
  companyStats: CompanyStatsDataDuck,
  leads: CustomerLeadsDataDuck,
  projects: AccountProjectsDataDuck,
  projectsMetaData: ProjectMetaDataDuck,
  projectsStats: ProjectsStatsDataDuck,
  projectsBackgroundTasks: ProjectBackgroundTasksDataDuck,
  projectsNerdStats: ProjectNerdStatsDataDuck,
  foldersMetaData: FoldersMetaDataDuck,
  projectOrders: ProjectOrdersDataDuck,
  folders: AccountFoldersDataDuck,
  files: FilesDataDuck,
  filesOrder: FilesOrderDataDuck,
  albums: AccountAlbumDataDuck,
  clients: CustomerClientsDataDuck,
  crm: crmReducer,
  clientSettings: ClientSettingsDataDuck,
  photographerActions: PhotographerActionsDataDuck,
  clientWebsite: ClientWebsiteDataDuck,
  clientWebsiteFiles: ClientWebsiteFilesDuck,
  clientWish: ClientWishDataDuck,
  portfolio: PortfolioDataDuck,
  customEventFormFields: EventCustomFormFieldsDataDuck,
  peopleFaces: PeopleFacesDataDuck,
  integration: IntegrationDataDuck,
});
