import React from 'react';

import { Icon, ICON_SIZES, Space, SubTitle } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
};

export default function ClientWebsiteMainOccasionForm(props: Props) {
  const { clientWebsite } = props;
  return (
    <div>
      <SubTitle>
        <Icon name="file_text" size={ICON_SIZES.XS} /> Main Occasion
      </SubTitle>
      <Space vertical size={2} />
      <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_INSTRUCTIONS} clientWebsite={clientWebsite} />
      <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_CONTACT_PHONE} clientWebsite={clientWebsite} />
    </div>
  );
}
