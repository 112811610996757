import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { clientWebsiteForProjectSelector, guestGalleryLinkSelector } from '../../../projects/AccountProjectsDataDuck';
import {
  isFeatureBadgesEnabledSelector,
  isFeatureMagazineEnabledSelector,
  projectMetaDataForProjectSelector,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { clientSettingsDataSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import EventAttendeeItem from './EventAttendeeItem';
import { eventAttendeesSelector } from './EventAttendeesDataDuck';
import { attendeeListStyleSelector } from './EventAttendeesPageDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;

  const clientSettingsData = clientSettingsDataSelector(state);
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    id,
    attendee: eventAttendeesSelector(state)[id],
    projectId,
    hasPermissions: {
      [PERMISSIONS.EVENT_ATTENDEES]: hasPermission(PERMISSIONS.EVENT_ATTENDEES)(state),
      [PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT)(state),
    },
    guestGalleryPreviewLink: guestGalleryLinkSelector(projectId)(state),
    showPIIDataFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII,
    )(state),
    requestForUserDataForMarketing: clientSettingsData?.request_for_user_data_for_marketing,
    listStyle: attendeeListStyleSelector(state),
    hasEnabledNetworkingFeature: !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.PEOPLE_NETWORKING],
    hasAttendeePoster: !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED],
    isFeatureMagazineEnabled: isFeatureMagazineEnabledSelector(projectId)(state),
    isFeatureBadgeEnabled: isFeatureBadgesEnabledSelector(projectId)(state),
    hasEnabledApprovalWorkflow:
      !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE],
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeItem);
