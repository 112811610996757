import { APIURLService, FolderService, ProjectService } from '@premagic/core';
import { HttpUtils } from '@premagic/utils';
import { DECK_SLIDE_DATA_TYPES, DECK_SLIDE_LAYOUTS, DeckSlideInvoiceItemsDataType } from '@premagic/myne';
import { ProposalSlideType } from '../proposal-slide/ProposalSlideService';
import { PROPOSAL_VARIABLE_TYPE, flattenVariablesObject } from '../proposal-variables/ProposalVariableService';

const ProposalListURL = APIURLService.API_URLS.FOLDER.ROOT;
const PROPOSAL_SYSTEM_FOLDER_LIST_URL = APIURLService.API_URLS.FOLDER.SYSTEM_FOLDER(
  FolderService.FOLDER_TYPES.PROPOSAL,
);
const FOLDER_DETAILS = APIURLService.API_URLS.FOLDER.DETAILS;
const SYSTEM_FOLDER_DETAILS = (id: string) =>
  APIURLService.API_URLS.FOLDER.SYSTEM_FOLDER_DETAILS(FolderService.FOLDER_TYPES.PROPOSAL, id);

export type ProposalType = FolderService.FolderType & {
  proposal_template_deck_id?: string;
  folder_type: FolderService.FOLDER_TYPES.PROPOSAL;
  event_id?: string;
  project_id: string | typeof ProjectService.SystemProject.id;
  deck_versions: Array<string>;
  current_deck_version: string; // The latest published deck
};

export function fetchSystemProposals(): Promise<ProposalType[]> {
  return HttpUtils.get(PROPOSAL_SYSTEM_FOLDER_LIST_URL)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function createProposalTemplate(name: string): Promise<ProposalType> {
  return HttpUtils.post(PROPOSAL_SYSTEM_FOLDER_LIST_URL, {
    folder_name: name,
    folder_type: FolderService.FOLDER_TYPES.PROPOSAL,
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

// TODO: Use FolderService.
export function deleteProposal(projectId: string, folderId: string): Promise<void> {
  if (projectId === ProjectService.SystemProject.id)
    return HttpUtils.httpDelete(SYSTEM_FOLDER_DETAILS(folderId))
      .then(({ data }) => data)
      .catch((error) => Promise.reject(error.data));

  return FolderService.deleteFolder(projectId, folderId)
    .then(() => {})
    .catch((error) => Promise.reject(error.data));
}

export function renameProposalName(projectId: string, folderId: string, newName): Promise<ProposalType> {
  return FolderService.updateFolderName(projectId, folderId, newName)
    .then((data) => data as ProposalType)
    .catch((error) => Promise.reject(error.data));
}

export function createDuplicateProposalTemplate(data: {
  folder_name: string;
  proposal_template_id: string;
}): Promise<ProposalType> {
  return HttpUtils.post(PROPOSAL_SYSTEM_FOLDER_LIST_URL, {
    folder_name: data.folder_name,
    proposal_template_id: data.proposal_template_id,
    folder_type: FolderService.FOLDER_TYPES.PROPOSAL,
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function createProposal(
  projectId: string,
  data: {
    folder_name: string;
    proposal_template_id: string;
  },
): Promise<ProposalType> {
  return HttpUtils.post(ProposalListURL(projectId), {
    project_id: projectId,
    folder_type: FolderService.FOLDER_TYPES.PROPOSAL,
    ...data,
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function createDuplicateProposal(
  projectId: string,
  data: {
    folder_name: string;
    proposal_template_id: string;
  },
): Promise<ProposalType> {
  return HttpUtils.post(ProposalListURL(projectId), {
    project_id: projectId,
    folder_type: FolderService.FOLDER_TYPES.PROPOSAL,
    source_project_id: projectId,
    ...data,
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

function calculateSlideInvoiceItemTotal(options: {
  invoiceItems?: DeckSlideInvoiceItemsDataType;
  variablesValues: Record<string, string | number>;
  proposalVariableDetails?: Array<any>;
}) {
  const { invoiceItems, variablesValues = {}, proposalVariableDetails } = options;

  // 1. Add up all the invoice items based on the type eg. There can be multiple items with CURRENCY type.
  const totalValuesBasedOnType =
    invoiceItems?.value?.reduce(
      (result, item) =>
        Object.assign(result, {
          [item.type]: (result[item.type] || 0) + Number(variablesValues[item.id]),
        }),
      0,
    ) || {};

  // 2. Calculate the total based the type
  const total = Object.entries(totalValuesBasedOnType || {}).reduce((result, [key, value]) => {
    switch (key as PROPOSAL_VARIABLE_TYPE) {
      case PROPOSAL_VARIABLE_TYPE.CURRENCY:
        return result + Number(value);
      case PROPOSAL_VARIABLE_TYPE.DISCOUNT_CURRENCY:
        return result - Number(value);
      default:
        return result;
    }
  }, 0);

  // 3. Calculate cost of Default variables(with cost) added on slides
  const defaultVariables = proposalVariableDetails?.filter((item) => item.scope === 'DEFAULT');
  const defaultVariablesWithCost = defaultVariables?.filter((item) => Number(item.default_value) > 0);
  const flattenedVariableValues = flattenVariablesObject(variablesValues);
  const defaultVariablesWithCostAddedOnSlide = defaultVariablesWithCost?.filter((item) =>
    Object.keys(flattenedVariableValues).includes(item.id),
  );
  const totalCostOfDefaultItemsAdded =
    defaultVariablesWithCostAddedOnSlide?.reduce(
      (result, item) => result + Number(item.default_value) * Number(flattenedVariableValues?.[item.id]),
      0,
    ) || 0;

  // 4. Calculate grand total
  const grandTotal = total + totalCostOfDefaultItemsAdded;

  // 5. Calculate total reduction % be made on the total
  const totalReductionPercentage = Number(totalValuesBasedOnType[PROPOSAL_VARIABLE_TYPE.DISCOUNT_PERCENT]) || 0;

  // 6. Calculate the total after reduction
  const totalAfterReduction = grandTotal - (grandTotal * totalReductionPercentage) / 100;
  // 7. Calculate the total after tax
  return (
    totalAfterReduction +
    (totalAfterReduction * (Number(totalValuesBasedOnType[PROPOSAL_VARIABLE_TYPE.TAX]) || 0)) / 100
  );
}

// do total proposal slide calculation here
export function getAllSlidesInvoiceItemTotal(
  slides: Array<ProposalSlideType>,
  variablesValues: Record<string, string | number>,
  proposalVariableDetails?: Array<any>,
): Record<number, number> {
  const packageSlides = slides.filter((slide) => slide.template_id === DECK_SLIDE_LAYOUTS.PACKAGE);
  return packageSlides.reduce((result, slide) => {
    const invoiceItems = slide.structure[DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS];
    return Object.assign(result, {
      [slide.id]: calculateSlideInvoiceItemTotal({
        invoiceItems,
        variablesValues,
        proposalVariableDetails,
      }),
    });
  }, {});
}
