import React from 'react';
import { Button, BUTTON_STYLES, FormFooter, Modal, MODAL_SIZES, ModalTitle } from '@premagic/myne';

import BrandingFilesContainer from './BrandingFilesContainer';

type Props = {
  projectId: string;
  onSelect: (name: string, fileId: string) => void;
  show: boolean;
  hideModal: () => void;
  modalOptions?: Record<
    string,
    {
      name: string;
      fileId?: string;
    }
  >;
};

export default function BrandingFilesSelectFileForWatermarkModal(props: Props): JSX.Element | null {
  const { projectId, onSelect, show, hideModal, modalOptions = {} } = props;

  function handleOnSelect(fileId) {
    onSelect(Object.keys(modalOptions)[0], fileId);
    hideModal();
  }

  return (
    <Modal show={show} close={hideModal} size={MODAL_SIZES.MD}>
      <ModalTitle>Select a file</ModalTitle>
      <BrandingFilesContainer projectId={projectId} onSelect={handleOnSelect} />
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={hideModal}>
          Cancel
        </Button>
      </FormFooter>
    </Modal>
  );
}
