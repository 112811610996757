import { BrowserUrlUtils, HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum SPONSORS_CREATIVES_PUBLISH_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}
export enum SPONSORS_CREATIVES_CTA_NAME {
  APPLY_NOW = 'Apply Now',
  BOOK_NOW = 'Book Now',
  CALL_NOW = 'Call Now',
  CONTACT_US = 'Contact Us',
  DOWNLOAD = 'Download',
  GET_QUOTE = 'Get Quote',
  LEARN_MORE = 'Learn More',
  LISTEN_NOW = 'Listen Now',
  ORDER_NOW = 'Order Now',
  REQUEST_TIME = 'Request Time',
  SAVE = 'Save',
  SEND_MESSAGE = 'Send Message',
  SHOP_NOW = 'Shop Now',
  SIGN_UP = 'Sign Up',
  WATCH_MORE = 'Watch More',
}
export const SPONSORS_CREATIVES_CTA_NAME_DETAILS: Record<
  SPONSORS_CREATIVES_CTA_NAME,
  {
    label: string;
  }
> = {
  [SPONSORS_CREATIVES_CTA_NAME.APPLY_NOW]: { label: 'Apply Now' },
  [SPONSORS_CREATIVES_CTA_NAME.BOOK_NOW]: { label: 'Book Now' },
  [SPONSORS_CREATIVES_CTA_NAME.CALL_NOW]: { label: 'Call Now' },
  [SPONSORS_CREATIVES_CTA_NAME.CONTACT_US]: { label: 'Contact Us' },
  [SPONSORS_CREATIVES_CTA_NAME.DOWNLOAD]: { label: 'Download' },
  [SPONSORS_CREATIVES_CTA_NAME.GET_QUOTE]: { label: 'Get Quote' },
  [SPONSORS_CREATIVES_CTA_NAME.LEARN_MORE]: { label: 'Learn More' },
  [SPONSORS_CREATIVES_CTA_NAME.LISTEN_NOW]: { label: 'Listen Now' },
  [SPONSORS_CREATIVES_CTA_NAME.ORDER_NOW]: { label: 'Order Now' },
  [SPONSORS_CREATIVES_CTA_NAME.REQUEST_TIME]: { label: 'Request Time' },
  [SPONSORS_CREATIVES_CTA_NAME.SAVE]: { label: 'Save' },
  [SPONSORS_CREATIVES_CTA_NAME.SEND_MESSAGE]: { label: 'Send Message' },
  [SPONSORS_CREATIVES_CTA_NAME.SHOP_NOW]: { label: 'Shop Now' },
  [SPONSORS_CREATIVES_CTA_NAME.SIGN_UP]: { label: 'Sign Up' },
  [SPONSORS_CREATIVES_CTA_NAME.WATCH_MORE]: { label: 'Watch More' },
};

export enum SPONSORS_CREATIVES_PLACEMENT {
  IN_GALLERY = 'IN-GALLERY',
  IN_STREAM = 'IN-STREAM',
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  INVITE = 'INVITE',
  WATERMARK = 'WATERMARK',
}

export const SPONSORS_CREATIVES_PLACEMENT_DETAILS: Record<
  SPONSORS_CREATIVES_PLACEMENT,
  {
    label: string;
  }
> = {
  [SPONSORS_CREATIVES_PLACEMENT.FOOTER]: { label: 'Gallery Footer' },
  [SPONSORS_CREATIVES_PLACEMENT.HEADER]: { label: 'Gallery Header' },
  [SPONSORS_CREATIVES_PLACEMENT.IN_GALLERY]: { label: 'Gallery, In between photos' },
  [SPONSORS_CREATIVES_PLACEMENT.IN_STREAM]: { label: 'Photo Stream, In between full view of photos' },
  [SPONSORS_CREATIVES_PLACEMENT.INVITE]: { label: 'Register page' },
  [SPONSORS_CREATIVES_PLACEMENT.WATERMARK]: { label: 'Watermark' },
};

export enum SPONSORS_CREATIVES_PLACEMENT_SIZE {
  TINY = 'XS',
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
}

//  we have to remove this along with all size sorting logic as we will now keep all size consistent.
// currently removed from signature album, digital invite is left
export const SPONSORS_CREATIVES_PLACEMENT_SIZE_DETAILS: Record<
  SPONSORS_CREATIVES_PLACEMENT_SIZE,
  {
    size: number;
    label: string;
  }
> = {
  [SPONSORS_CREATIVES_PLACEMENT_SIZE.TINY]: { size: 80, label: 'Tiny' },
  [SPONSORS_CREATIVES_PLACEMENT_SIZE.SMALL]: { size: 120, label: 'Small' },
  [SPONSORS_CREATIVES_PLACEMENT_SIZE.MEDIUM]: { size: 160, label: 'Medium' },
  [SPONSORS_CREATIVES_PLACEMENT_SIZE.LARGE]: { size: 240, label: 'Large' },
};

export enum SPONSORS_CREATIVES_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export const SPONSORS_CREATIVES_TYPE_DETAILS: Record<
  SPONSORS_CREATIVES_TYPE,
  {
    label: string;
  }
> = {
  [SPONSORS_CREATIVES_TYPE.IMAGE]: { label: 'Image' },
  [SPONSORS_CREATIVES_TYPE.VIDEO]: { label: 'Video' },
};

export type NewSponsorCreativeType = {
  name: string;
  placement: SPONSORS_CREATIVES_PLACEMENT;
  size: SPONSORS_CREATIVES_PLACEMENT_SIZE;
  status: SPONSORS_CREATIVES_PUBLISH_STATUS;
  sponsor_id: string;
  type: SPONSORS_CREATIVES_TYPE;
  meta?: {
    watermark_folder_ids?: Array<string>;
    watermark_opacity?: number;
    watermark_size?: number;
    watermark_position?: string;
    watermark_position_x?: number | string;
    watermark_position_y?: number | string;
    watermark_remove_on_download?: boolean;
  };
};

export type SponsorCreativeType = NewSponsorCreativeType & {
  id: string;
  asset_ids: Array<string>;
  headline?: string;
  description?: string;
  cta_name?: SPONSORS_CREATIVES_CTA_NAME;
  cta_link?: string;
  created_at: string;
  updated_at: string;
};

export function fetchSponsorCreative(
  projectId: string,
  sponsorId: string,
): Promise<Record<string, SponsorCreativeType>> {
  return HttpUtils.get(API_URLS.SPONSOR_CREATIVES.LIST(projectId, sponsorId)).then(({ data }) => data);
}
function getCreativeDataToSend(creative: NewSponsorCreativeType | SponsorCreativeType) {
  const { sponsor_id: id, ...creativeData } = creative;
  if ('cta_link' in creativeData) {
    return {
      ...creativeData,
      cta_link: BrowserUrlUtils.getURLWithProtocol(creativeData.cta_link),
    };
  }

  return creativeData;
}

export function createSponsorCreative(
  options: {
    projectId: string;
  },
  creative: NewSponsorCreativeType,
): Promise<Record<string, SponsorCreativeType>> {
  const { projectId } = options;
  const { sponsor_id: sponsorId } = creative;
  const creativeData = getCreativeDataToSend(creative);
  return HttpUtils.post(API_URLS.SPONSOR_CREATIVES.LIST(projectId, sponsorId), creativeData).then(({ data }) => data);
}

export function updateSponsorCreative(
  options: {
    projectId: string;
    creativeId: string;
  },
  creative: SponsorCreativeType,
): Promise<Record<string, SponsorCreativeType>> {
  const { projectId, creativeId } = options;
  const { sponsor_id: sponsorId } = creative;
  const creativeData = getCreativeDataToSend(creative);
  return HttpUtils.put(
    API_URLS.SPONSOR_CREATIVES.DETAILS(projectId, sponsorId as string, creativeId),
    creativeData,
  ).then(({ data }) => data);
}

export function deleteSponsorCreative(options: {
  projectId: string;
  sponsorId: string;
  creativeId: string;
}): Promise<void> {
  const { projectId, sponsorId, creativeId } = options;
  return HttpUtils.httpDelete(API_URLS.SPONSOR_CREATIVES.DETAILS(projectId, sponsorId, creativeId)).then(
    ({ data }) => data,
  );
}

export type SponsorCreativeReportType = {
  id: string;
  sponsor_id: string;
  sponsor_name: string;
  creative_id: string;
  creative_name: string;
  creative_asset_ids: Array<string>;
  creative_placement: string;
  stats_views: number;
  stats_clicks: number;
  stats_ctr: number;
  stats_cta_clicks: number;
  stats_roi: number;
};

export enum SPONSOR_FOOTER_GROUP_BY_TYPE {
  CATEGORY = 'CATEGORY',
  TIER = 'TIER',
}
