import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../../common/Constants';
import { setModalOptions } from '../../../../../../../../../common/ModalDuck';
import { toggleWindowPanelVisibility } from '../../../../../../../../../common/WindowPanelDuck';
import { filesItemDataSelector } from '../../../../../../images/FilesDataDuck';
import { getSponsorCreativeDataById } from '../../../SponsorCreativeDataDuck';
import WatermarkItem from './WatermarkItem';

function mapStateToProps(state, ownProps: { sponsorCreativeWatermarkId: string }) {
  const { sponsorCreativeWatermarkId } = ownProps;

  const sponsorCreativeWatermarkItem = getSponsorCreativeDataById(sponsorCreativeWatermarkId)(state);
  const sponsorCreativeWatermarkFile = filesItemDataSelector(sponsorCreativeWatermarkItem?.asset_ids[0])(state);

  return { sponsorCreativeWatermarkFile, sponsorCreativeWatermarkId };
}

function mapDispatchToProps(dispatch) {
  return {
    showWatermarkPanel: (watermarkId) => {
      dispatch(
        toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_EDIT_SPONSOR_WATERMARK_PANEL, true),
      );
      dispatch(setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_EDIT_SPONSOR_WATERMARK_PANEL, watermarkId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermarkItem);
