import { connect } from 'react-redux';
import { EventCustomFromFieldsService } from '@premagic/core';
import { LOADINGS } from '../../../../../../common/Constants';
import AddSystemRegistrationFormFields from './AddSystemRegistrationFormFields';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import {
  addNewCustomEventFromField,
  eventCustomFormFieldsSelectors,
} from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

const COMMON_KEY = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.ADD;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    error: errorObjectForTypeSelector(COMMON_KEY)(state),
    formFields: eventCustomFormFieldsSelectors(EventCustomFromFieldsService.FORM_FIELD_SCOPE.REGISTRATION)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addFormField: (projectId: string, data) => dispatch(addNewCustomEventFromField(dispatch, projectId, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemRegistrationFormFields);
