import React from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  FormFooter,
  BUTTON_SIZE,
  Form,
  Space,
  PreventMarginCollapse,
  Button,
  FormResponseType,
  FormErrorType,
  SubTitle,
  Col,
  Row,
} from '@premagic/myne';
import { ErrorTracker } from '@premagic/utils';
import { ProposalService } from '@premagic/proposals';
import { FolderService } from '@premagic/core';
import SharePanelForm from './SharePanelForm';
import FolderExpirySelector from '../../../projects/folders/share/FolderExpirySelector';
import FolderCoverPhotoContainer from '../../../projects/folders/sidebar/FolderCoverPhotoContainer';
import FolderShareEmailSelectorContainer from '../../../projects/folders/share/FolderShareEmailSelectorContainer';

type Props = {
  projectId: string;
  folderId: string;
  client?: {
    name: string;
    phone_number: string;
    email?: string;
  };
  shareFoldersWithClient: (
    options: { eventId: string; projectId: string; folderId: string },
    formResponse: FormResponseType,
  ) => void;
  close: () => void;
  show: boolean;
  isLoading: boolean;
  proposal: ProposalService.ProposalType;
  errors?: FormErrorType;
};

export default function ProposalSharePanel(props: Props) {
  const { show, close, client, folderId, projectId, shareFoldersWithClient, isLoading, proposal, errors } = props;
  const { event_id: eventId } = proposal;

  function handleShareFolders(formResponse) {
    if (!eventId) {
      return ErrorTracker.logError('ProposalSharePanel', 'eventId is not defined');
    }
    const clientData = formResponse.data.name // When the client.email is not provided, then We show a form to create a new client, so if the name is on the formData we should use it
      ? { name: formResponse.data.name, email: formResponse.data.email, phone_number: formResponse.data.phone_number }
      : client;
    const formResponseWithDate = {
      ...formResponse,
      data: {
        clients: [clientData],
        email_content: formResponse.data.email_content,
        expiry_date: formResponse.data.expiry_date,
      },
    };

    shareFoldersWithClient({ projectId, folderId, eventId }, formResponseWithDate);
  }

  if (!client) return null;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Share</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          onSubmit={handleShareFolders}
          errors={errors}
          formSpec={{
            name: {
              validator: 'required',
            },
            email: {
              validator: 'required',
            },
            phone_number: {
              validator: 'required',
            },
          }}
        >
          <SharePanelForm client={client} />
          <Space vertical />
          <SubTitle>Thumbnail</SubTitle>
          <Space vertical size={2} />
          <Row>
            <Col size={5}>
              <FolderCoverPhotoContainer folderId={folderId} projectId={projectId} smallStyle type="thumbnail" />
            </Col>
          </Row>
          <Space vertical />
          <FolderExpirySelector />
          <Space vertical />
          <FolderShareEmailSelectorContainer
            folderType={FolderService.FOLDER_TYPES.PROPOSAL}
            clientName={client.name}
          />
          <PreventMarginCollapse>
            <Space vertical size={10} />
          </PreventMarginCollapse>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Skip, share later
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Share
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
