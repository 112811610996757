import React from 'react';
import { Card, CardHeader, CardHeaderTitle, Col, Divider, Icon, ICON_SIZES, Row, Space } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  hasPremiumDigitalInviteFeature?: boolean;
  isCompanyTypeNotPhotographer: boolean;
};

export default function ClientWebsiteThemeInfoForm(props: Props) {
  const { clientWebsite, hasPremiumDigitalInviteFeature, isCompanyTypeNotPhotographer } = props;

  return (
    <div>
      <Card>
        <Space>
          <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.WEBSITE_ID} clientWebsite={clientWebsite} />
        </Space>
      </Card>
      <Card>
        <Space>
          <CardHeader>
            <CardHeaderTitle>
              <Icon name="feather" size={ICON_SIZES.SM} /> Customization
            </CardHeaderTitle>
          </CardHeader>
          <Row>
            <Col size={4}>
              <ClientWebsiteFormItem
                itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_ID}
                clientWebsite={clientWebsite}
                disabled={!hasPremiumDigitalInviteFeature}
              />
            </Col>
          </Row>

          <Space size={10} />
          <Divider />
          <Space vertical />
          <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.CLIPART_ID} clientWebsite={clientWebsite} />
          {!isCompanyTypeNotPhotographer && (
            <div>
              <Divider />
              <Space vertical />
              <ClientWebsiteFormItem
                disabled={!hasPremiumDigitalInviteFeature}
                itemKey={CLIENT_WEBSITE_FORM_ITEMS.RELIGIOUS_CLIPART_ID}
                clientWebsite={clientWebsite}
              />
            </div>
          )}
          <Space size={10} />
          <Divider />
          <Space vertical />
          <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.MUSIC_ID} clientWebsite={clientWebsite} />
        </Space>
      </Card>
    </div>
  );
}
