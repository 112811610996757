import React, { useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';
import { InnerPage, InnerPageContent } from '@premagic/myne';
import APP_URLS from '../../../services/AppRouteURLService';
import FolderImagesPopupContainer from '../folders/gallery/FolderImagesPopupContainer';
import SelectionFoldersPageHeaderContainer from './SelectionFoldersPageHeaderContainer';
import AlbumImagesBasedOnStatusLayoutContainer from '../overview/AlbumImagesBasedOnStatusLayoutContainer';
import SelectionFoldersPageFoldersLayoutContainer from './folders/SelectionFoldersPageFoldersLayoutContainer';

type Props = {
  initData: (projectId: string) => void;
  isLoading: boolean;
  projectId: string;
  currentUrlPathname: string;
};

export default function SelectionFoldersPage(props: Props) {
  const { initData, isLoading, projectId, currentUrlPathname } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  return (
    <InnerPage>
      <SelectionFoldersPageHeaderContainer projectId={projectId} currentUrlPathname={currentUrlPathname} />
      <InnerPageContent isLoading={isLoading} hasInnerCols>
        <Switch>
          <Route
            path={APP_URLS.PROJECT.ALBUM_IMAGES_BASED_ON_STATUS}
            component={AlbumImagesBasedOnStatusLayoutContainer}
          />
          <Route path={APP_URLS.PROJECT.SELECTION} component={SelectionFoldersPageFoldersLayoutContainer} />
        </Switch>
      </InnerPageContent>
      <Route exact path={APP_URLS.FOLDER.FILE_VIEW} component={FolderImagesPopupContainer} />
    </InnerPage>
  );
}
