import React from 'react';

import { InnerPageContentMainCol, InnerPageContentSidebar, Space, Text } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

import EditClientWebsiteUserRegistrationFromPanelContainer from './EditClientWebsiteUserRegistrationFromPanelContainer';
import AIPhotoDeliveryCardContainer from './AIPhotoDeliveryCardContainer';
import ClientWebsiteUserRegistrationFormLayoutContainer from '../registration-form/ClientWebsiteUserRegistrationFormLayoutContainer';

type Props = {
  projectId: string;
  id: string;
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
};

export default function ClientWebsiteUserRegistrationLayout(props: Props): React.ReactElement {
  const { clientWebsite, projectId, id } = props;

  return (
    <>
      <InnerPageContentMainCol>
        <AIPhotoDeliveryCardContainer projectId={projectId} />
        <Space vertical />
        <ClientWebsiteUserRegistrationFormLayoutContainer projectId={projectId} id={id} />
        <EditClientWebsiteUserRegistrationFromPanelContainer projectId={projectId} id={id} />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <div />
      </InnerPageContentSidebar>
    </>
  );
}
