import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import SelectionFolders from './SelectionFolders';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; activeFolderId?: string }) {
  const { projectId, activeFolderId } = ownProps;
  const folderIds = folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.SELECTION)(state);

  return {
    folderIds,
    projectId,
    activeFolderId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionFolders);
