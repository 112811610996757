import React, { useEffect } from 'react';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import {
  Card,
  Col,
  COLOR_SHADES,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  LoadingBox,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ACCOUNT_FINANCIALS_TYPES, AccountFinancialsPaymentType } from '../AccountFinancialsService';
import APP_URLS from '../../../services/AppRouteURLService';
import AccountCurrencyContainer from '../../acccount/AccountCurrencyContainer';
import { CRMPaymentCategoryType } from '../../crm/payments/CRMPaymentService';
import AccountFinancialsPaymentsTablePaginationContainer from './AccountFinancialsPaymentsTablePaginationContainer';

type PaymentDateProps = {
  date?: string;
};

function PaymentDate(props: PaymentDateProps) {
  const { date } = props;
  if (!date)
    return (
      <Text size={TEXT_SIZE.SIZE_5} muted>
        (settled)
      </Text>
    );
  const dueDateString = SimpleDateUtils.fromNowDate(date, true);

  return <Text size={TEXT_SIZE.SIZE_5}>{dueDateString}</Text>;
}

type Props = {
  items: Array<AccountFinancialsPaymentType>;
  categories: Record<number, CRMPaymentCategoryType>;
  filters: string;
  type: ACCOUNT_FINANCIALS_TYPES;
  isLoading: boolean;
  initData: (type, filters) => void;
  navigateToEventPage: (url: string) => void;
};

export default function AccountFinancialsPaymentsTable(props: Props): JSX.Element {
  const { items, filters, type, isLoading, initData, navigateToEventPage, categories } = props;

  useEffect(() => {
    const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
    initData(type, filterObject);
  }, [type, filters]);

  function handleOnClick(eventId: string, paymentId) {
    const eventDetailsFinancialPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__FINANCIAL, {
      eventId,
      focusId: paymentId,
    });
    navigateToEventPage(eventDetailsFinancialPage);
  }
  const expenseView = type === ACCOUNT_FINANCIALS_TYPES.EXPENSE;

  return (
    <LoadingBox isLoading={isLoading}>
      <Card>
        <Space size={2}>
          <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
            <Col size={1}>
              <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                #
              </Text>
            </Col>
            <Col size={3}>
              <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                Category
              </Text>
            </Col>
            <Col size={1}>
              <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                Event ID
              </Text>
            </Col>
            <Col size={2}>
              <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                Client name
              </Text>
            </Col>
            <Col size={2}>
              <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                {expenseView ? 'Settlement Due' : 'Due'}
              </Text>
            </Col>
            <Col size={null} rightAlighed>
              <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                Amount
              </Text>
            </Col>
          </ListItem>
        </Space>
        <List>
          {items.length === 0 && (
            <ListItem>
              <Text size={TEXT_SIZE.SIZE_5} muted block center>
                There are no payment for the applied filters
              </Text>
            </ListItem>
          )}
          {items.map((item) => {
            const {
              event_id: eventId,
              human_readable_id: paymentNumber,
              event__number: eventNumber,
              event__client__name: clientName,
              due_date: due,
              amount,
              pk: id,
              payment_category_id: categoryId,
            } = item;
            return (
              <ListItem key={id} onClick={() => handleOnClick(eventId, id)}>
                <Col size={1}>
                  <Text size={TEXT_SIZE.SIZE_5}>{paymentNumber}</Text>
                </Col>
                <Col size={3}>
                  <Text size={TEXT_SIZE.SIZE_5}>{categories[categoryId].name}</Text>
                </Col>
                <Col size={1}>
                  <Text size={TEXT_SIZE.SIZE_5}>{eventNumber}</Text>
                </Col>
                <Col size={2}>
                  <Text size={TEXT_SIZE.SIZE_5}>{clientName}</Text>
                </Col>
                <Col size={2}>
                  <PaymentDate date={due} />
                </Col>
                <Col size={null} rightAlighed>
                  <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
                    <AccountCurrencyContainer amount={amount} />
                  </Text>
                </Col>
              </ListItem>
            );
          })}
        </List>
      </Card>
      <Row>
        <Col rightAlighed size={null}>
          <AccountFinancialsPaymentsTablePaginationContainer type={type} filters={filters} />
        </Col>
      </Row>
    </LoadingBox>
  );
}
