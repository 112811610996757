import { StorePricingService } from '@premagic/core';
import { Col, INPUT_SIZES, Row, Space, Text, TEXT_SIZE } from '@premagic/myne';
import { StorePriceUtils } from '@premagic/utils';
import React, { useEffect, useState } from 'react';
import AccountCurrencyContainer from '../../acccount/AccountCurrencyContainer';
import InputCurrencyContainer from '../../acccount/InputCurrencyContainer';

type PriceSetterListItemProps = {
  subproductId: StorePricingService.SUBPRODUCTS_IDS;
  subproductName: string;
  cost: number;
  price: number;
  currencyCode?: string;
};

export default function PriceSetterListItem(props: PriceSetterListItemProps) {
  const { subproductName, cost, price, subproductId, currencyCode = 'INR' } = props;

  const [margin, setMargin] = useState(0);

  function handlePriceChange(productPrice) {
    setMargin(StorePriceUtils.getTotalMarginForProduct(productPrice, cost, currencyCode));
  }

  useEffect(() => {
    setMargin(StorePriceUtils.getTotalMarginForProduct(price, cost, currencyCode));
  }, []);

  return (
    <Space size={4}>
      <Row vcenter>
        <Col size={6}>
          <Text block size={TEXT_SIZE.SIZE_3} muted>
            {subproductName}
          </Text>
        </Col>
        <Col size={2}>
          <Text muted>
            <AccountCurrencyContainer amount={cost} currencyCode={currencyCode} />
          </Text>
        </Col>
        <Col size={2}>
          <InputCurrencyContainer
            name={subproductId}
            size={INPUT_SIZES.XS}
            currencyCode={currencyCode}
            defaultValue={price}
            onChange={handlePriceChange}
          />
        </Col>
        <Col rightAlighed size={null}>
          <Text muted>
            <AccountCurrencyContainer amount={margin} currencyCode={currencyCode} />
          </Text>
        </Col>
      </Row>
    </Space>
  );
}
