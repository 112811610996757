import { ProposalDeckService } from '@premagic/proposals';
import {
  Card,
  CARD_STYLES,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  STATUS_LABEL_STYLES,
  StatusLabel,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import React from 'react';

type Props = {
  deck?: ProposalDeckService.ProposalDeckType;
};

const REVIEW_STATUS_DETAILS: Record<
  ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS,
  {
    style: STATUS_LABEL_STYLES;
    text: string;
  }
> = {
  [ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.APPROVED]: {
    style: STATUS_LABEL_STYLES.SUCCESS,
    text: 'Approved',
  },
  [ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.REJECTED]: {
    style: STATUS_LABEL_STYLES.DANGER,
    text: 'Rejected',
  },
  [ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.REQUEST_FOR_CHANGE]: {
    style: STATUS_LABEL_STYLES.WARNING,
    text: 'Requested changes',
  },
};

export default function EventProposalDeckStatus(props: Props): JSX.Element {
  const { deck } = props;
  const { status, review_status: reviewStatus, review_comment: comment } = deck || {};
  const statusText = status === ProposalDeckService.PROPOSAL_DECK_STATUS.DRAFT ? 'Yet to send' : null;

  if (deck)
    return (
      <div>
        {statusText && (
          <>
            <Text color={COLOR_SHADES.WARNING}>{statusText}</Text>
            <Space />
          </>
        )}
        {reviewStatus && (
          <StatusLabel style={REVIEW_STATUS_DETAILS[reviewStatus].style}>
            {REVIEW_STATUS_DETAILS[reviewStatus].text}
          </StatusLabel>
        )}
        {statusText || reviewStatus ? <Space vertical size={2} /> : null}
        {comment && (
          <Card style={CARD_STYLES.SECONDARY}>
            <Space size={2}>
              <Row>
                <Icon name="message_square" size={ICON_SIZES.SM} />
                <Space size={2} />
                <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DARKER}>
                  {comment}
                </Text>
              </Row>
            </Space>
          </Card>
        )}
      </div>
    );

  return <Text muted>NO DECK HERE</Text>;
}
