import React, { useRef, useState } from 'react';
import {
  Avatar,
  AVATAR_SIZES,
  Button,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  Divider,
  Icon,
  ICON_SIZES,
  LIST_ITEM_STYLES,
  ListItem,
  Row,
  SimpleImage,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { UserType } from '../crm/users/UsersService';
import { AccountType } from '../acccount/AccountService';
import UserAccountsContainer from './UserAccountsContainer';
import APP_URLS from '../../services/AppRouteURLService';

type Props = {
  user?: UserType;
  activeAccount?: AccountType;
  logoutUser: () => void;
  isLoadingAuth: boolean;
  logo?: string;
};

export default function UserProfileDropdown(props: Props): JSX.Element {
  const { user, activeAccount, logoutUser, isLoadingAuth, logo } = props;
  const [showDialog, setShowDialog] = useState(false);
  const target = useRef(null);

  return (
    <>
      <ListItem ref={target} onClick={() => setShowDialog(!showDialog)} style={LIST_ITEM_STYLES.COMPACT_LIST}>
        {logo ? (
          <Tooltip message={activeAccount?.name}>
            <SimpleImage src={logo} alt="Logo" style={{ height: '32px', width: '100px', objectFit: 'contain' }} />
          </Tooltip>
        ) : (
          <Col size={9}>
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} ellipsis size={TEXT_SIZE.SIZE_5}>
              {activeAccount?.name || 'not set'}
            </Text>
          </Col>
        )}
        <Col size={null} rightAlighed>
          <Avatar
            size={AVATAR_SIZES.SM}
            user={{
              fullName: user?.name || 'Loading',
              email: user?.email,
            }}
          />
        </Col>
        <Space size={1} />
        <Icon name="chevron_down" size={ICON_SIZES.SM} />
      </ListItem>
      <Dialog
        hasPadding={false}
        target={target.current}
        show={showDialog}
        onClose={() => setShowDialog(false)}
        placement={DIALOG_PLACEMENT.TOP_START}
      >
        <Space>
          <Row center>
            <Avatar
              size={AVATAR_SIZES.LG}
              user={{
                fullName: user?.name || 'Loading',
                email: user?.email,
              }}
            />
          </Row>
          <Space vertical size={2} />
          <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} center block>
            {user?.name || 'not set'}
          </Text>
          <Text center block>
            {user?.email || 'not set'}
          </Text>
          <Text boldness={TEXT_BOLDNESS.BOLD} muted center block>
            {activeAccount?.name || 'not set'}
          </Text>
        </Space>
        <Divider />
        <UserAccountsContainer />
        <Space>
          <Row>
            <Col>
              <Button link={APP_URLS.USER_PROFILE.INDEX} style={BUTTON_STYLES.CANCEL}>
                <Icon name="award" size={ICON_SIZES.SM} />
                <Space size={1} />
                Profile
              </Button>
            </Col>
            <Space />
            <Col>
              <Button isLoading={isLoadingAuth} onClick={logoutUser} style={BUTTON_STYLES.CANCEL}>
                <Icon name="log_out" size={ICON_SIZES.SM} />
                <Space size={1} />
                Sign out
              </Button>
            </Col>
          </Row>
        </Space>
      </Dialog>
    </>
  );
}
