import { connect } from 'react-redux';
import { requestingUserSelector } from '../crm/users/UsersDataDuck';
import { appAlertsSelector, removeAppAlert } from './AccountAppAlertsDataDuck';
import AppAlerts from './AppAlerts';

function mapStateToProps(state) {
  return {
    user: requestingUserSelector(state),
    items: appAlertsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeAlert: (id) => dispatch(removeAppAlert(dispatch, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAlerts);
