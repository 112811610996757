import { FileService, FolderService, ProjectMetaService } from '@premagic/core';
import {
  ALERT_SIZES,
  ALERT_STYLES,
  Alert,
  Col,
  HighlightGalleryDesignPreview,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  LoadingDots,
  MEDIA_CARDS_GALLERY_STYLES,
  Position,
  Row,
  Space,
  TEXT_BOLDNESS,
  Text,
} from '@premagic/myne';
import { useState } from 'react';
import PremiumFeatureIcon from '../../../settings/PremiumFeatureIcon';
import ProjectWatermarkSettingCardContainer from '../../../watermark/ProjectWatermarkSettingCardContainer';
import ProjectFoldersListContainer from '../../highlight-settings/ProjectFoldersListContainer';
import HighlightDesignFolderLayoutSelectorContainer from './HighlightDesignFolderLayoutSelectorContainer';
import HighlightDesignGalleryGridSpaceSelectorContainer from './HighlightDesignGalleryGridSpaceSelectorContainer';
import HighlightDesignGalleryThumbnailSizeSelectorContainer from './HighlightDesignGalleryThumbnailSizeSelectorContainer';
import HighlightDesignTextCardContainer from './highlight-design-text/HighlightDesignTextCardContainer';
import HighlightDesignTextEditorWindowPanelContainer from './highlight-design-text/text-editor-window-panel/HighlightDesignTextEditorWindowPanelContainer';

type HighlightGalleryDesignLayoutProps = {
  projectId: string;
  updateProjectMeta: (projectId: string, formResponse: any) => void;
  hasHighlightCustomizationFeature: boolean;
  galleryGridSpace?: number;
  galleryThumbnailSize?: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
  galleryEndingText?: string;
  galleryWelcomeText?: string;
  folderLayout?: ProjectMetaService.FOLDER_THUMBNAIL_VIEWS;
  isFolderThumbnailViewEnabled?: boolean;
  isLoading: boolean;
  folders: Array<FolderService.FolderType>;
  folderMeta: any;
  isEventSponsorshipFeatureEnabled?: boolean;
};

export default function HighlightGalleryDesignLayout(props: HighlightGalleryDesignLayoutProps) {
  const {
    projectId,
    updateProjectMeta,
    hasHighlightCustomizationFeature,
    galleryGridSpace = 1,
    galleryThumbnailSize = ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT,
    galleryEndingText = 'Hope you liked it.',
    galleryWelcomeText,
    folderLayout = ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD,
    isFolderThumbnailViewEnabled = false,
    isLoading,
    folders,
    folderMeta,
    isEventSponsorshipFeatureEnabled,
  } = props;

  const folderItems = folders.map((folder) => ({
    id: folder.folder_id,
    title: folder.folder_name,
    /* using the cover photo from folderMeta instead of folders to display the
    photo immediately on gallery preview after upload */
    file: folderMeta[folder.folder_id]?.cover_image as FileService.FolderFileType,
    focalPoint: folderMeta[folder.folder_id]?.cover_image_focal_point || { x: 50, y: 50 },
  }));

  const [folderThumbnailViewEnabled, setFolderThumbnailViewEnabled] = useState(isFolderThumbnailViewEnabled);
  const [folderThumbnailView, setFolderThumbnailView] = useState(folderLayout);
  const [gridSpace, setGridSpace] = useState(galleryGridSpace);
  const [thumbnailSize, setThumbnailSize] = useState(galleryThumbnailSize);

  function getFolderThumbnailView() {
    const FOLDER_THUMBNAIL_VIEW_MAP = {
      [ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD]: MEDIA_CARDS_GALLERY_STYLES.GRID_CARD,
      [ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.LANDSCAPE_CARD]: MEDIA_CARDS_GALLERY_STYLES.LANDSCAPE_CARD,
    };

    return FOLDER_THUMBNAIL_VIEW_MAP[folderThumbnailView];
  }

  function handleFolderLayoutChange(value) {
    if (value === false) {
      setFolderThumbnailViewEnabled(false);
      updateProjectMeta(projectId, {
        data: {
          [ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED]: false,
          [ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW]: 'no-thumbnail',
        },
      });
    } else {
      setFolderThumbnailViewEnabled(true);
      setFolderThumbnailView(value);
      updateProjectMeta(projectId, {
        data: {
          [ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED]: true,
          [ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW]: value as string,
        },
      });
    }
  }

  function handleGalleryGridSpaceChange(value) {
    setGridSpace(value);
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE]: value as string,
      },
    });
  }

  function handleGalleryThumbnailSizeChange(value) {
    setThumbnailSize(value);
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE]: value as string,
      },
    });
  }

  return (
    <>
      <InnerPageContentMainCol>
        <HighlightGalleryDesignPreview
          folderItems={folderItems}
          isfolderThumbnailViewEnabled={folderThumbnailViewEnabled}
          folderThumbnailView={getFolderThumbnailView()}
          gridSpace={gridSpace}
          thumbnailSize={thumbnailSize}
        />
        <Space vertical size={6} />
        <HighlightDesignTextCardContainer
          galleryWelcomeText={galleryWelcomeText}
          galleryEndingText={galleryEndingText}
        />
        {isEventSponsorshipFeatureEnabled ? (
          <Text muted>
            The configuration for watermark settings has been relocated to the 'Sponsors' tab. Kindly apply the
            watermark within the 'Sponsors' tab.
          </Text>
        ) : (
          <ProjectWatermarkSettingCardContainer projectId={projectId} />
        )}
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <Col size={null}>
          <Position align="top-right">
            {isLoading && (
              <Space size={2}>
                <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>Saving...</Text>
                <LoadingDots size="sm" />
              </Space>
            )}
          </Position>
          {!hasHighlightCustomizationFeature && (
            <>
              <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
                <Row vcenter>
                  <PremiumFeatureIcon module="Signature Album Design Customization" />
                  Signature Album Design Customization is available on Higher plans.
                </Row>
              </Alert>
              <Space vertical size={2} />
            </>
          )}
          <HighlightDesignFolderLayoutSelectorContainer
            onChange={handleFolderLayoutChange}
            folderLayout={folderLayout}
            isFolderThumbnailViewEnabled={isFolderThumbnailViewEnabled}
          />
          <Space vertical />
          <ProjectFoldersListContainer projectId={projectId} />
          <Space vertical size={6} />
          <HighlightDesignGalleryGridSpaceSelectorContainer
            onChange={handleGalleryGridSpaceChange}
            galleryGridSpace={galleryGridSpace}
          />
          <Space vertical size={6} />
          <HighlightDesignGalleryThumbnailSizeSelectorContainer
            onChange={handleGalleryThumbnailSizeChange}
            galleryThumbnailSize={galleryThumbnailSize}
          />
        </Col>
      </InnerPageContentSidebar>
      <HighlightDesignTextEditorWindowPanelContainer
        projectId={projectId}
        galleryWelcomeText={galleryWelcomeText}
        galleryEndingText={galleryEndingText}
      />
    </>
  );
}
