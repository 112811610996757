import { EventAttendeesService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CardContent,
  CardHeaderTitle,
  Col,
  Color,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  Label,
  LABEL_STYLES,
  List,
  LIST_ITEM_ACTION_STYLES,
  ListItem,
  ListItemAction,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  Text,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { ArrayUtils, CryptoUtils, StringUtils } from '@premagic/utils';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import AddSocialMessageModalContainer from './social-message-modal/AddSocialMessageModalContainer';
import EditSocialMessageModalContainer from './social-message-modal/EditSocialMessageModalContainer';

const panelContentBasedOnScope = {
  [ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE.GALLERY]: {
    title: 'Gallery photo share messages',
    subHeader: 'Messages are auto-filled when attendees share photos from the gallery',
    noMessage: "You haven't added any social messages for photo share yet",
  },
  [ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE.POSTER]: {
    title: 'Event poster share messages',
    subHeader: 'Messages are auto-filled when attendees share the event poster',
    noMessage: "You haven't added any social messages for event poster yet",
  },
};

type MessageListItemCardProps = {
  socialMessageItem: ProjectMetaService.SocialShareMessageType;
  showEditSocialMessageModal: (messageId: string) => void;
  isLoading?: boolean;
  handleDeleteMessage: (messageId: string) => void;
  handleCreateDuplicateMessage: (messageId: string) => void;
  focus?: boolean;
};

function MessageListItemCard(props: MessageListItemCardProps) {
  const {
    socialMessageItem,
    showEditSocialMessageModal,
    isLoading,
    handleDeleteMessage,
    handleCreateDuplicateMessage,
    focus,
  } = props;
  const { id, message, hashtags, people_type: peopleType } = socialMessageItem;
  return (
    <ListItem highlight={focus}>
      <ListItemAction style={LIST_ITEM_ACTION_STYLES.DEFAULT}>
        <Space size={2}>
          <Tooltip message="Drag and move to reorder" placement="left">
            <Row>
              <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                <Icon name="drag" size={ICON_SIZES.SM} align="bottom" alignSize={4} />
              </Color>
            </Row>
          </Tooltip>
        </Space>
      </ListItemAction>
      <Col size={null}>
        <Space size={2}>
          <Text size={TEXT_SIZE.SIZE_4}>{message}</Text>
          <Space vertical size={4} />
          <Text size={TEXT_SIZE.SIZE_4} muted>
            {StringUtils.getFormattedHashtags(hashtags)}
          </Text>
          <Space vertical size={1} />
          {peopleType?.map((scopeItem) => {
            const peopleDetails = EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS[scopeItem];
            return (
              <Label style={LABEL_STYLES.DEFAULT} key={peopleDetails.name}>
                {peopleDetails.name}
              </Label>
            );
          })}
        </Space>
      </Col>
      <ListItemAction>
        <Dropdown>
          <DropdownIcon title="More" tooltipPlacement="top" isLoading={isLoading}>
            <Icon name="more_vertical" size={ICON_SIZES.MD} />
          </DropdownIcon>
          <Menu>
            <MenuItem onClick={() => showEditSocialMessageModal(id)}>
              <MenuItemIcon name="edit" />
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleCreateDuplicateMessage(id)}>
              <MenuItemIcon name="copy" />
              Duplicate
            </MenuItem>
            <MenuItem onClick={() => handleDeleteMessage(id)}>
              <MenuItemIcon name="trash" />
              Delete
            </MenuItem>
          </Menu>
        </Dropdown>
      </ListItemAction>
    </ListItem>
  );
}

type Props = {
  projectId: string;
  isLoading: boolean;
  socialShareMessages?: ProjectMetaService.SocialShareMessagesType;
  updateProjectMeta: (projectId: string, data) => void;
  showAddSocialMessageModal: (scope: ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE) => void;
  showEditSocialMessageModal: (messageId: string) => void;
  scope: ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE;
  hasCustomMessageFeature: boolean;
};

export default function SocialShareMessages(props: Props) {
  const {
    projectId,
    socialShareMessages,
    updateProjectMeta,
    showAddSocialMessageModal,
    showEditSocialMessageModal,
    isLoading,
    scope,
    hasCustomMessageFeature,
  } = props;

  const [socialShareMessagesObject, setSocialShareMessagesObject] = useState(socialShareMessages);
  const [newCreatedMessageId, setNewCreatedMessageId] = useState('');

  useEffect(() => {
    setSocialShareMessagesObject(socialShareMessages);
  }, [socialShareMessages]);

  if (!hasCustomMessageFeature) return null;

  const messageList =
    socialShareMessagesObject && Object.values(socialShareMessagesObject).filter((message) => message.scope === scope);
  const messageListInOrder = messageList?.sort((item1, item2) => item1.order - item2.order);

  function updateMessagesOrder(from, to) {
    const newMessagesPositions = ArrayUtils.arrayMove(messageListInOrder, from, to);
    const messagesWithNewOrder = { ...socialShareMessagesObject };
    newMessagesPositions.forEach((item, idx) => {
      messagesWithNewOrder[item.id] = { ...item, order: idx };
    });
    setSocialShareMessagesObject(messagesWithNewOrder);

    const dataToPost = {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SOCIAL_SHARE_MESSAGES]: messagesWithNewOrder,
      },
    };

    updateProjectMeta(projectId, dataToPost);
  }

  function handleDeleteMessage(messageId) {
    const newSocialMessagesOject: ProjectMetaService.SocialShareMessagesType = { ...socialShareMessagesObject };
    delete newSocialMessagesOject[messageId];

    Object.values(newSocialMessagesOject)
      .filter((message) => message.scope === scope)
      .sort((a, b) => a.order - b.order)
      .forEach((item, idx) => {
        newSocialMessagesOject[item.id].order = idx;
      });

    setSocialShareMessagesObject(newSocialMessagesOject);
    const dataToPost = {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SOCIAL_SHARE_MESSAGES]: newSocialMessagesOject,
      },
    };

    updateProjectMeta(projectId, dataToPost);
  }

  function handleCreateDuplicateMessage(messageId) {
    const totalMessages = socialShareMessagesObject
      ? Object.values(socialShareMessagesObject).filter((message) => message.scope === scope).length
      : 0;
    const uniqueId = CryptoUtils.createUUID();

    const newDuplicateItem = {
      ...socialShareMessagesObject?.[messageId],
      id: uniqueId,
      order: totalMessages,
    };

    const newSocialMessagesOject = {
      ...socialShareMessagesObject,
      [uniqueId]: newDuplicateItem,
    };

    setSocialShareMessagesObject(newSocialMessagesOject as ProjectMetaService.SocialShareMessagesType);
    setNewCreatedMessageId(uniqueId);

    const dataToPost = {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SOCIAL_SHARE_MESSAGES]: newSocialMessagesOject,
      },
    };

    updateProjectMeta(projectId, dataToPost);
  }

  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;

    if (reason === 'DROP' && destination) {
      updateMessagesOrder(source.index, destination.index);
    }
  }

  return (
    <div>
      <Row>
        <div>
          <CardHeaderTitle>{panelContentBasedOnScope[scope].title}</CardHeaderTitle>
          <Text size={TEXT_SIZE.SIZE_4} muted block>
            {panelContentBasedOnScope[scope].subHeader}
          </Text>
        </div>
        <Col size={null} rightAlighed>
          <Button
            size={BUTTON_SIZE.SM}
            style={BUTTON_STYLES.PRIMARY}
            onClick={() => showAddSocialMessageModal(scope)}
            isLoading={isLoading}
          >
            <Icon name="plus" size={ICON_SIZES.SM} align="top" alignSize={1} /> Message
          </Button>
        </Col>
      </Row>
      <Space size={2} vertical />
      <Card>
        <CardContent>
          {(!messageList || messageList?.length === 0) && (
            <Space>
              <Text muted center block>
                {panelContentBasedOnScope[scope].noMessage}
              </Text>
            </Space>
          )}
          <List>
            <DragDropContext onDragEnd={onDrop}>
              <Droppable droppableId="TASKGROUP-LIST" direction="vertical">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {messageListInOrder?.map((message, idx) => (
                      <Draggable key={message.id} draggableId={message.id} index={idx}>
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <MessageListItemCard
                              socialMessageItem={message}
                              showEditSocialMessageModal={showEditSocialMessageModal}
                              isLoading={isLoading}
                              handleDeleteMessage={handleDeleteMessage}
                              handleCreateDuplicateMessage={handleCreateDuplicateMessage}
                              focus={message.id === newCreatedMessageId}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </List>
        </CardContent>
        <AddSocialMessageModalContainer projectId={projectId} scope={scope} />
        <EditSocialMessageModalContainer projectId={projectId} />
      </Card>
    </div>
  );
}
