import { $Keys } from 'utility-types';
import { HttpUtils } from '@premagic/utils';
import { AppServices } from '@premagic/core';

export const CUSTOMER_LEAD_STATUS = {
  NEW: { label: 'New', id: 'NEW', title: 'New' },
  OPPORTUNITY: { label: 'Opportunity', id: 'OPPORTUNITY', title: 'Opportunity' },
  REJECT: { label: 'Reject', id: 'REJECT', title: 'Rejected' },
  CLOSE: { label: 'Close', id: 'CLOSE', title: 'Closed' },
};

export type CustomerLeadType = {
  name: string;
  email: string;
  phone: string;
  status: $Keys<typeof CUSTOMER_LEAD_STATUS>;
  project_name: string;
  created_at: string;
};

export function fetchCustomerLeads() {
  return HttpUtils.get(`${AppServices.getAPIUrl()}/customer/leads/`);
}

export function updateCustomerLeadStatus(id, status: $Keys<typeof CUSTOMER_LEAD_STATUS>) {
  return HttpUtils.put(`${AppServices.getAPIUrl()}/customer/leads/${id}/`, {
    status,
  });
}
