import { connect } from 'react-redux';
import { sponsorCreativesSelector } from '../../../crm/events/event-sponsor-creatives/SponsorCreativeDataDuck';
import { eventSponsorsSelector } from '../../../crm/events/event-sponsors/EventSponsorDataDuck';
import { filesSelector } from '../../../images/FilesDataDuck';
import QRCodeSponsorCreativeItem from './QRCodeSponsorCreativeItem';

function mapStateToProps(state, ownProps: { id: string; showCreativeStatus?: boolean; logoSize?: 'sm' | 'md' }) {
  const { id, showCreativeStatus, logoSize } = ownProps;
  const creative = sponsorCreativesSelector(state)[id];

  return {
    id,
    creative,
    sponsor: eventSponsorsSelector(state)[creative?.sponsor_id],
    files: filesSelector(state),
    showCreativeStatus,
    logoSize,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeSponsorCreativeItem);
