import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { useEffect } from 'react';
import EventExhibitorFormContainer from '../create/EventExhibitorFormContainer';

type Props = {
  exhibitorId?: string | null;
  projectId: string;
  updateEventExhibitor: (options: { projectId: string; exhibitorId: string }, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  cleanup: () => void;
};

export default function EditEventExhibitorPanel(props: Props): JSX.Element | null {
  const { show, updateEventExhibitor, isLoading, errors, closePanel, exhibitorId, cleanup, projectId } = props;
  useEffect(() => {
    if (!show) {
      cleanup();
    }
  }, [show]);

  if (!exhibitorId || !show) return null;
  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Exhibitor</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={(data) =>
            updateEventExhibitor(
              {
                exhibitorId,
                projectId,
              },
              data,
            )
          }
        >
          <EventExhibitorFormContainer projectId={projectId} exhibitorId={exhibitorId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
