import { schema } from 'normalizr';
// Depreacted move to @core/schmes
export const ImageSchema = new schema.Entity(
  'images',
  {},
  {
    idAttribute: (value, parent, key) => key,
  },
);
export const ImagesSchema = new schema.Values(ImageSchema);

export const FolderSchema = new schema.Entity('folders', { files: ImagesSchema }, { idAttribute: 'folder_id' });

export const FoldersSchema = [FolderSchema];

export const PricingPlanSchema = new schema.Entity('pricingPlans', {});

export const PricingPlansSchema = [PricingPlanSchema];

export const InvoiceSchema = new schema.Entity('invoices', {});

export const InvoicesSchema = [InvoiceSchema];

// CRM
export const UserSchema = new schema.Entity('users');
export const UsersSchema = [UserSchema];

export const CRMClientSchema = new schema.Entity('clients');
export const CRMClientsSchema = [CRMClientSchema];
export const CRMEventSchema = new schema.Entity('events');
export const CRMEventsSchema = [CRMEventSchema];

export const CRMOccasionSchema = new schema.Entity('occasions', {});
export const CRMOccasionsSchema = [CRMOccasionSchema];

export const CRMPaymentSchema = new schema.Entity('payments');
export const CRMPaymentsSchema = [CRMPaymentSchema];

export const CRMPaymentCategorySchema = new schema.Entity('categories');
export const CRMPaymentCategoriesSchema = [CRMPaymentCategorySchema];

export const CalendarOccasionsSchema = [new schema.Entity('occasions', {})];
export const EventListEventsSchema = [new schema.Entity('events', {})];

export const LabelSchema = new schema.Entity('label', {});
export const LabelsSchema = [new schema.Entity('labels', {})];

// Workflow
export const TaskSchema = new schema.Entity('task');
export const TasksSchema = [TaskSchema];
export const TaskGroupSchema = new schema.Entity('taskgroup', { tasks: TasksSchema });
export const TaskGroupsSchema = [TaskGroupSchema];
export const WorkflowSchema = new schema.Entity('workflow', { task_groups: TaskGroupsSchema });
export const WorkflowsSchema = [WorkflowSchema];

export const AccountSchema = new schema.Entity('accounts', {});
export const AccountsSchema = [AccountSchema];
export const AccountFinancialsPaymentsSchema = [new schema.Entity('payments', {})];
