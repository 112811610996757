import React from 'react';
import { Card, Col, COLOR_SHADES, Divider, Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import QuotePaymentItemContainer from './quote/QuotePaymentItemContainer';
import AccountCurrencyContainer from '../../acccount/AccountCurrencyContainer';

type Props = {
  eventId: string;
  balanceAmount: number;
  totalExpense: number;
  revenue: number;
  revenuePercent: string;
  isMobileUp: boolean;
  taxName: string;
  taxPercentage: number;
  totalTaxAmount: number;
};

export default function PaymentSummary(props: Props): JSX.Element {
  const {
    eventId,
    totalExpense,
    balanceAmount,
    revenue,
    revenuePercent,
    isMobileUp,
    taxName,
    taxPercentage,
    totalTaxAmount,
  } = props;

  return (
    <Card>
      <Space>
        <Row columnDirection={!isMobileUp}>
          <Col screenSMSize={12}>
            <QuotePaymentItemContainer eventId={eventId} />
          </Col>
          <Col screenSMSize={12}>
            <Row>
              <Col size={5}>
                <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
                  Net Revenue
                </Text>
              </Col>
              <Col size={6}>
                <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3}>
                  <AccountCurrencyContainer amount={revenue} />
                </Text>
                <Space size={1} />
                {revenue !== 0 && (
                  <Text boldness={TEXT_BOLDNESS.BOLD} color={revenue > 0 ? COLOR_SHADES.SUCCESS : COLOR_SHADES.WARNING}>
                    ({revenuePercent})
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Space>
      <Divider />
      <Space>
        <Row>
          <Col>
            <Row vcenter>
              <Col size={3} screenSMSize={6}>
                <Text boldness={TEXT_BOLDNESS.BOLD}>Balance</Text>
              </Col>
              <Text color={COLOR_SHADES.DARKER}>
                <AccountCurrencyContainer amount={balanceAmount} />
              </Text>
            </Row>
            <Row vcenter>
              <Col size={3} screenSMSize={6}>
                <Text boldness={TEXT_BOLDNESS.BOLD}>Expenses</Text>
              </Col>
              <Text color={COLOR_SHADES.DARKER}>
                <AccountCurrencyContainer amount={totalExpense} />
              </Text>
            </Row>
          </Col>
          <Col rightAlighed>
            <Row vcenter>
              <Col size={3} screenSMSize={6}>
                <Text boldness={TEXT_BOLDNESS.BOLD}>{`${taxName} (${taxPercentage}%)`}</Text>
              </Col>
              <Space size={3} />
              <Text color={COLOR_SHADES.DARKER}>
                <AccountCurrencyContainer amount={totalTaxAmount} />
              </Text>
            </Row>
          </Col>
        </Row>
      </Space>
    </Card>
  );
}
