import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Divider,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  Menu,
  MenuItem,
  MenuItemIcon,
} from '@premagic/myne';
import { EventAttendeesService, EventCustomFromFieldsService } from '@premagic/core';
import { BrowserUtils } from '@premagic/utils';
import AttendeeDeleteDialogContainer from './AttendeeDeleteDialogContainer';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  projectId: string;
  attendeeId: string;
  hasPermissionsToManageAttendees: boolean;
  showDeleteDialog: (id: string) => void;
  showEditAttendee: (id: string) => void;
  showPIIDataFeature: boolean;
  attendee: EventAttendeesService.EventAttendeeType;
};

export default function EventAttendeeOptionsMenu(props: Props): React.ReactElement {
  const {
    attendeeId,
    showDeleteDialog,
    projectId,
    hasPermissionsToManageAttendees,
    showEditAttendee,
    attendee,
    showPIIDataFeature,
  } = props;
  const { face_id: faceId, custom_fields: customFields } = attendee;
  const qrCode = customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.QR_CODE]?.dynamic_image_url;

  return (
    <>
      {showPIIDataFeature && !faceId && (
        <div>
          <Button
            disabled={!hasPermissionsToManageAttendees}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
            onClick={() => showEditAttendee(attendeeId)}
            size={BUTTON_SIZE.SM}
            style={BUTTON_STYLES.LINK}
          >
            Update
          </Button>
        </div>
      )}
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="top">
          <Icon name="more_vertical" size={ICON_SIZES.SM} />
        </DropdownIcon>

        <Menu>
          {qrCode && (
            <MenuItem onClick={() => BrowserUtils.openInNewWindow(qrCode, '_blank')}>
              <MenuItemIcon name="external_link" />
              View Check-in QR Code
            </MenuItem>
          )}
          <MenuItem
            disabled={!hasPermissionsToManageAttendees}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
            onClick={() => showEditAttendee(attendeeId)}
          >
            <MenuItemIcon name="edit_2" />
            Edit
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={!hasPermissionsToManageAttendees}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
            onClick={() => showDeleteDialog(attendeeId)}
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      {hasPermissionsToManageAttendees && (
        <AttendeeDeleteDialogContainer attendeeId={attendeeId} projectId={projectId} />
      )}
    </>
  );
}
