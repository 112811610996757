import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import SponsorReportCard from './SponsorReportCard';
import { eventSponsorsSelector } from '../../event-sponsors/EventSponsorDataDuck';
import { statsOnSponsorsSelector } from '../../../../projects/project-stats/ProjectsStatsDataDuck';
import { sponsorsCategoryDataSelector } from '../../event-sponsors/sponsor-category/data-duck/SponsorCategoryDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;

  return {
    id,
    sponsor: eventSponsorsSelector(state)[id],
    stats: statsOnSponsorsSelector(projectId, id)(state),
    sponsorsCategoryData: sponsorsCategoryDataSelector(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_STATS.SPONSOR_FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorReportCard);
