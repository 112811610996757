import { connect } from 'react-redux';
import { FolderMetaService, FontPaletteService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import FolderMetaToggle from './FolderMetaToggle';
import { saveFolderMetaData, folderMetaDataForFolderSelector } from './FoldersMetaDataDuck';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { projectsSelector } from '../AccountProjectsDataDuck';

const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(
  state,
  ownProps: { projectId: string; folderId: string; itemKey: FolderMetaService.FOLDER_META_TYPES },
) {
  const { projectId, folderId, itemKey } = ownProps;

  const defaultFontPalette = FontPaletteService.FONT_PALETTES_DETAILS[FontPaletteService.FONT_PALETTES.PLAYFAIR];
  const folderMeta = folderMetaDataForFolderSelector(folderId)(state);

  return {
    projectId,
    folderId,
    project: projectsSelector(state)[projectId] || {},
    itemKey,
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    folderMeta: {
      [FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT]: defaultFontPalette.primary,
      [FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT]: defaultFontPalette.secondary,
      [FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES]:
        !folderMeta[FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES],
      [FolderMetaService.FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED]:
        folderMeta[FolderMetaService.FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED] ?? true,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFolderMeta: (projectId, folderId, formResponse) => {
      dispatch(saveFolderMetaData(dispatch, projectId, folderId, formResponse));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderMetaToggle);
