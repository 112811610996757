import React from 'react';
import { Button, BUTTON_STYLES, Form, FormErrorType, FormFooter, Modal, MODAL_SIZES, ModalTitle } from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import { CryptoUtils } from '@premagic/utils';
import SocialMessageFormContainer from './SocialMessageFormContainer';

type Props = {
  projectId: string;
  show: boolean;
  close: (scope: ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE) => void;
  isLoading?: boolean;
  socialShareMessages?: ProjectMetaService.SocialShareMessagesType;
  updateProjectMeta: (projectId: string, data) => void;
  error: FormErrorType;
  scope: ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE;
};

export default function AddSocialMessageModal(props: Props): JSX.Element {
  const { show, close, projectId, isLoading, updateProjectMeta, socialShareMessages, error, scope } = props;

  function handleFormSubmit(formData) {
    const uniqueId = CryptoUtils.createUUID();

    const totalMessages = socialShareMessages
      ? Object.values(socialShareMessages).filter((message) => message.scope === scope).length
      : 0;

    const dataToPost = {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SOCIAL_SHARE_MESSAGES]: {
          ...socialShareMessages,
          [uniqueId]: {
            id: uniqueId,
            order: totalMessages,
            message: formData.data.message,
            hashtags: typeof formData.data.hashtags === 'object' ? formData.data.hashtags : [formData.data.hashtags],
            scope,
            people_type:
              typeof formData.data.people_type === 'object' ? formData.data.people_type : [formData.data.people_type],
          },
        },
      },
    };
    updateProjectMeta(projectId, dataToPost);
    close(scope);
  }

  return (
    <Modal show={show} close={() => close(scope)} size={MODAL_SIZES.MD}>
      <ModalTitle>Add Social Share Message</ModalTitle>

      <Form onSubmit={handleFormSubmit} errors={error}>
        <SocialMessageFormContainer projectId={projectId} />
        <FormFooter>
          <Button onClick={() => close(scope)} style={BUTTON_STYLES.LINK}>
            Cancel
          </Button>
          <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
            Save
          </Button>
        </FormFooter>
      </Form>
    </Modal>
  );
}
