import {
  Col,
  COLOR_SHADES,
  InnerPageContentMainCol,
  PageBreak,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import MESSAGES from '../../../../../../common/Messages';
import PosterAdvocateReportLayoutContainer from '../../../poster-advocates/reports/PosterAdvocateReportLayoutContainer';
import ProjectStatsForNerdCardContainer from '../../../projects/project-stats-for-nerd-card/ProjectStatsForNerdCardContainer';

import { PERMISSIONS } from '../../users/UserPermissionService';
import SponsorReportCardsContainer from '../sponsors-report/sponsor-report-card/SponsorReportCardsContainer';
import SponsorReportSummaryCardContainer from '../sponsors-report/sponsor-report-card/SponsorReportSummaryCardContainer';
import SponsorReportTableContainer from '../sponsors-report/sponsor-report-card/SponsorReportTableContainer';
import EventReportTopMetricsContainer from './EventReportTopMetricsContainer';

type Props = {
  projectId?: string;
  eventId: string;
  focusId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_SPONSOR_REPORT]: boolean;
  };
  initData: (projectId: string) => void;
  sponsorIds: Array<string>;
  isFeatureAddEventPosterEnabled: boolean;
  getProjectStatsData: (projectId: string) => void;
};

export default function EventReportLayout(props: Props): React.ReactElement {
  const {
    projectId,
    hasPermissions,
    initData,
    sponsorIds,
    eventId,
    focusId,
    isFeatureAddEventPosterEnabled,
    getProjectStatsData,
  } = props;

  useEffect(() => {
    if (projectId) {
      initData(projectId);
      getProjectStatsData(projectId);
    }
  }, [initData, projectId, getProjectStatsData]);

  if (!hasPermissions[PERMISSIONS.EVENT_SPONSOR_REPORT]) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.PERMISSION[PERMISSIONS.EVENT_SPONSOR_REPORT]}</Text>
      </InnerPageContentMainCol>
    );
  }
  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;

  return (
    <InnerPageContentMainCol hasSidebar={false}>
      <EventReportTopMetricsContainer projectId={projectId} />
      <PageBreak />
      {isFeatureAddEventPosterEnabled && <PosterAdvocateReportLayoutContainer projectId={projectId} />}
      {sponsorIds.length > 0 && (
        <div>
          <Space vertical size={8} />
          <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            Sponsor Insight
          </Text>
          <Space vertical size={2} />
          <Row>
            <Col screenSMSize={12}>
              <SponsorReportSummaryCardContainer
                projectId={projectId}
                eventId={eventId}
                type="default"
                focusId={focusId}
              />
            </Col>
          </Row>
          <Space vertical size={2} />
          <SponsorReportCardsContainer projectId={projectId} />
          <Space vertical size={8} />
          <SponsorReportTableContainer projectId={projectId} />
        </div>
      )}
      <Space vertical size={8} />
      <Row>
        <Col>
          <ProjectStatsForNerdCardContainer projectId={projectId} />
        </Col>
      </Row>
    </InnerPageContentMainCol>
  );
}
