import { ClientSettingsService, FeatureToggleService, PresetDesignService, ProjectMetaService } from '@premagic/core';
import { Alert, ALERT_SIZES, ALERT_STYLES, Card, Row, Space } from '@premagic/myne';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import PresetDesignSelectorContainer from '../../../preset-design-selector/PresetDesignSelectorContainer';
import PremiumFeatureIcon from '../../../settings/PremiumFeatureIcon';
import GalleryDesignCustomizationSettingsContainer from './GalleryDesignCustomizationSettingsContainer';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateProjectMeta: (projectId: string, formResponse: any) => void;
  hasHighlightCustomizationFeature: boolean;
  updateThemeVariables: (backgroundColor: string, fontColor: string, accentColor: string) => void;
  isLoading?: boolean;
};

export default function HighlightFoldersPropertiesSettings(props: Props) {
  const {
    clientSettings,
    projectId,
    projectMeta,
    updateProjectMeta,
    hasHighlightCustomizationFeature,
    updateThemeVariables,
    isLoading,
  } = props;

  const hasHighlightFontSelectorEnabled = FeatureToggleService.isToggleableFeatureEnabledForClient(
    FeatureToggleService.FEATURES_TOGGLES.HIGHLIGHT_FONT,
    true,
  );

  const [galleryPreset, setGalleryPreset] = useState('');

  const aiPickedDominantColors = projectMeta?.cover_image?.meta?.colors;

  const [backgroundColorForSignatureAlbum, setBackgroundColorForSignatureAlbum] = useState(
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR] || '#fff',
  );

  const [fontFamilyForSignatureAlbum, setFontFamilyForSignatureAlbum] = useState(
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY] ||
      clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY],
  );

  const [fontColorForSignatureAlbum, setFontColorForSignatureAlbum] = useState(
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR] || '#000000',
  );
  const [accentColorForSignatureAlbum, setAccentColorForSignatureAlbum] = useState(
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR] || '#047af0',
  );

  function handleFontChange(value) {
    setFontFamilyForSignatureAlbum(value.fontFamily);
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY]: value.fontFamily as string,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY_SOURCE]: value.fontSource as string,
      },
    });
  }

  const debouncedBackgroundColorChange = useMemo(() => {
    function backgroundColorChange(value) {
      updateProjectMeta(projectId, {
        data: {
          [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR]: value as string,
        },
      });
    }
    return debounce(backgroundColorChange, 500);
  }, [projectId, updateProjectMeta]);

  const debouncedFontColorChange = useMemo(() => {
    function fontColorChange(value) {
      updateProjectMeta(projectId, {
        data: {
          [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR]: value as string,
        },
      });
    }
    return debounce(fontColorChange, 500);
  }, [projectId, updateProjectMeta]);

  const debouncedAccentColorChange = useMemo(() => {
    function fontColorChange(value) {
      updateProjectMeta(projectId, {
        data: {
          [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR]: value as string,
        },
      });
    }
    return debounce(fontColorChange, 500);
  }, [projectId, updateProjectMeta]);

  function handleBackgroundColorChange(color) {
    setBackgroundColorForSignatureAlbum(color);
    debouncedBackgroundColorChange(color);
  }

  function handleFontColorChange(color) {
    setFontColorForSignatureAlbum(color);
    debouncedFontColorChange(color);
  }

  function handleAccentColorChange(color) {
    setAccentColorForSignatureAlbum(color);
    debouncedAccentColorChange(color);
  }

  function handlePresetDesignChange(value: PresetDesignService.PresetDesignOption) {
    setBackgroundColorForSignatureAlbum(value.backgroundColor);
    setFontFamilyForSignatureAlbum(value.primaryFontFamily);
    setFontColorForSignatureAlbum(value.fontColor);
    handleAccentColorChange(value.accentColor);

    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY]: value.primaryFontFamily as string,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY]:
          value.secondaryFontFamily as string,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY_SOURCE]: value.fontSource as string,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR]: value.backgroundColor as string,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR]: value.fontColor as string,
        [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR]: value.accentColor as string,
      },
    });
  }

  useEffect(() => {
    updateThemeVariables(backgroundColorForSignatureAlbum, fontColorForSignatureAlbum, accentColorForSignatureAlbum);

    const selectedPreset = Object.values(PresetDesignService.PRESET_DESIGN).find((item) => {
      if (
        item.backgroundColor === backgroundColorForSignatureAlbum &&
        item.fontColor === fontColorForSignatureAlbum &&
        item.primaryFontFamily === fontFamilyForSignatureAlbum &&
        item.accentColor === accentColorForSignatureAlbum
      ) {
        return item;
      }

      return null;
    });

    if (selectedPreset) {
      setGalleryPreset(selectedPreset.value);
    }
  }, [
    accentColorForSignatureAlbum,
    backgroundColorForSignatureAlbum,
    fontColorForSignatureAlbum,
    fontFamilyForSignatureAlbum,
    updateThemeVariables,
  ]);

  if (!hasHighlightFontSelectorEnabled) return null;

  return (
    <div>
      {!hasHighlightCustomizationFeature && (
        <>
          <Space vertical size={2} />
          <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
            <Row vcenter>
              <PremiumFeatureIcon module="Signature Album Design Customization" />
              Signature Album Design Customization is available on Higher plans.
            </Row>
          </Alert>
          <Space vertical size={4} />
        </>
      )}
      <Card>
        <PresetDesignSelectorContainer
          onChange={handlePresetDesignChange}
          selectedPresetDesign={galleryPreset}
          isLoading={isLoading}
          headingTitle="Gallery Presets"
          isPresetDesignSelectorEnabled={hasHighlightCustomizationFeature}
          variant={PresetDesignService.PRESET_DESIGN_VARIANT.PRIMARY}
        />
        <GalleryDesignCustomizationSettingsContainer
          handleFontChange={handleFontChange}
          fontFamilyForSignatureAlbum={fontFamilyForSignatureAlbum}
          hasHighlightCustomizationFeature={hasHighlightCustomizationFeature}
          aiPickedDominantColors={aiPickedDominantColors}
          handleBackgroundColorChange={handleBackgroundColorChange}
          backgroundColorForSignatureAlbum={backgroundColorForSignatureAlbum}
          handleFontColorChange={handleFontColorChange}
          fontColorForSignatureAlbum={fontColorForSignatureAlbum}
          accentColorForSignatureAlbum={accentColorForSignatureAlbum}
          handleAccentColorChange={handleAccentColorChange}
        />
      </Card>
    </div>
  );
}
