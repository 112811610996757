import React, { useEffect } from 'react';
import { Card, Space, InnerPageHeader, InnerPageContent, InnerPage } from '@premagic/myne';

type Props = {
  initData: () => void;
};

export default function FeedsPage(props: Props): JSX.Element {
  const { initData } = props;
  useEffect(() => {
    initData();
  }, []);

  return (
    <InnerPage>
      <InnerPageHeader iconName="settings" title="Feed">
        Feeds
      </InnerPageHeader>
      <InnerPageContent>
        <Card>
          <Space size={8}>Feed</Space>
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
