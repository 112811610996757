import { connect } from 'react-redux';
import SelectSponsorCategory from './SelectSponsorCategory';
import { sponsorsCategoryDataSelector } from './data-duck/SponsorCategoryDataDuck';

function mapStateToProps(state, ownProps: { defaultSponsorCategoryId?: string }) {
  const { defaultSponsorCategoryId } = ownProps;
  return {
    sponsorCategoryData: sponsorsCategoryDataSelector(state),
    defaultSponsorCategoryId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSponsorCategory);
