import { EnvUtils } from '@premagic/utils';
import Pusher from 'pusher-js';
import { getCustomerId } from './GlobalVariableService';

export enum LIVE_EVENTS {
  FOLDER_UPDATE = 'folder',
  CUSTOMER_UPDATE = 'customer-update',
  DEVICE_LOGS_FETCH = 'fetch-device-logs',
  PROJECT_UPDATE = 'project',
  NEW_ATTENDEE_MEMORY = 'new-attendee-memory',
  NEW_ATTENDEE_REGISTRATION = 'new-attendee-registration',
}
let pusherInstance;

function initPusher() {
  const isLocal = EnvUtils.getEnvValues().NX_PUBLIC_LIVE_EVENTS_URL === 'localhost';
  Pusher.logToConsole = !EnvUtils.isProd();
  pusherInstance =
    pusherInstance ||
    new Pusher(EnvUtils.getEnvValues().NX_PUBLIC_LIVE_EVENTS_KEY, {
      wsHost: EnvUtils.getEnvValues().NX_PUBLIC_LIVE_EVENTS_URL,
      wsPort: isLocal ? 6008 : 443,
      forceTLS: !isLocal,
      cluster: 'mt1',
      wssPort: 443,
      disableStats: true,
      enabledTransports: ['ws', 'wss'], // Allow both ws and wss transports
    });
  return pusherInstance;
}

export function listenOnChannelForProject(projectId: string, type: LIVE_EVENTS, cb: (...args: Array<any>) => any) {
  const pusher = initPusher();
  const key = `${getCustomerId()}@${projectId}`;
  if (!key) return;
  const pusherChannel = pusher.subscribe(key);
  pusherChannel.bind(type, (data) => cb(data));
}
