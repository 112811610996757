import { Button, BUTTON_STYLES, Col, ColorPicker, Row, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import { useEffect, useState } from 'react';

type Props = {
  onChange: (color: string) => void;
  disabled: boolean;
  defaultFontColor?: string;
  initFontColor: (fontColor: string) => void;
  backgroundColor?: string;
};

export default function HighlightDesignFontColorSelector(props: Props) {
  const { onChange, disabled, defaultFontColor, initFontColor, backgroundColor = '#FFFFFF' } = props;

  // we will not show auto color option for now
  const [autoColorOptionEnable, setAutoColorOptionEnable] = useState(false);

  const [selectedColor, setSelectedColor] = useState(defaultFontColor || '#203a56');
  const [autoColorEnabled, setAutoColorEnabled] = useState(false);

  //  when the selected color changes, we will change the global theme font color in the stylesheet
  useEffect(() => {
    initFontColor(selectedColor);
  }, [selectedColor, initFontColor]);

  //  to handle gallery preset color
  useEffect(() => {
    if (defaultFontColor) {
      setSelectedColor(defaultFontColor);
    }
  }, [defaultFontColor, initFontColor]);

  // to get the instant feedback of font color change based on background color change
  useEffect(() => {
    if (autoColorEnabled && backgroundColor) {
      const contrastColor = ColorUtils.getContrastColor({
        bgColor: backgroundColor,
        defaultColor: '#000',
        threshold: 200,
      });
      setSelectedColor(contrastColor);
      onChange(contrastColor);
    }
  }, [backgroundColor, autoColorEnabled]);

  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>Font Color</Text>
      <Space vertical size={1} />
      <Row vcenter>
        {autoColorEnabled ? (
          <>
            <Row vcenter>
              <Text boldness={TEXT_BOLDNESS.BOLD}>AUTO</Text>
              <div style={{ height: '44px' }} />
            </Row>

            <Col rightAlighed size={null}>
              <Button
                style={BUTTON_STYLES.LINK}
                onClick={() => {
                  setAutoColorEnabled(false);
                }}
              >
                Pick Color Manually?
              </Button>
            </Col>
          </>
        ) : (
          <>
            <ColorPicker
              name="font_color"
              defaultValue={selectedColor}
              onChange={(color) => {
                setSelectedColor(color);
                onChange(color);
              }}
              overlayPlacement="right"
              disabled={disabled}
            />
            {autoColorOptionEnable ? (
              <Col rightAlighed size={null}>
                <Button
                  style={BUTTON_STYLES.LINK}
                  onClick={() => {
                    setAutoColorEnabled(true);
                  }}
                  disabled={disabled}
                >
                  AUTO COLOR PICK
                </Button>
              </Col>
            ) : null}
          </>
        )}
      </Row>
    </div>
  );
}
