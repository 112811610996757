import { connect } from 'react-redux';
import { LabelService, PricingPlanFeaturesService } from '@premagic/core';
import CRMPage from './CRMPage';
import { hasFeatureSelector } from '../settings/company/CompanyDataDuck';
import { MODALS } from '../../../../common/Constants';
import { toggleModalVisibility } from '../../../../common/ModalDuck';
import { fetchLabelsData } from './labels/LabelsDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureEventsOccasionsEnabledSelector,
  isFeatureLeadEnabledSelector,
} from '../settings/preferences/ClientSettingsDataDuck';
import { isMobileUp } from '../AppDuck';

function mapStateToProps(state, ownProps) {
  const { filters, view } = ownProps.match.params;

  const hasCRMFeature = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM)(state);
  return {
    filters,
    view,
    hasCRMFeature,
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    isFeatureEventsOccasionsEnabled: isFeatureEventsOccasionsEnabledSelector(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    isMobileUp: isMobileUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showCRMClientSearchModal: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.CLIENT.SEARCH, true));
    },
    fetchAllEventLabels: () => {
      dispatch(fetchLabelsData(dispatch, LabelService.ENTITY_TYPE.EVENT));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMPage);
