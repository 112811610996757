import { EventTrackerService } from '@premagic/core';
import { COLOR_SHADES, FormResponseType, Row, Space, Text, TEXT_SIZE, ToggleSwitch } from '@premagic/myne';
import React from 'react';

export default function ProjectMetaFeatureToggleButton(props: {
  hasFeature: boolean;
  isLoading: boolean;
  name: string;
  checked: boolean;
  updateProjectMeta: (projectId: string, data: FormResponseType) => void;
  projectId: string;
  invertValue?: boolean;
}) {
  const { hasFeature, isLoading, name, checked, updateProjectMeta, projectId, invertValue } = props;
  return (
    <Row vcenter columnDirection>
      <ToggleSwitch
        disabled={!hasFeature}
        isLoading={isLoading}
        name={name}
        checked={checked}
        onChange={(value) => {
          let newValue = value;
          if (invertValue) {
            newValue = !value;
          }
          EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.SETTING_TOGGLE(name), {
            enabled: newValue,
            projectId,
          });
          updateProjectMeta(projectId, {
            data: {
              [name]: newValue,
            },
          });
        }}
      />
      <Space size={1} vertical />
      {checked ? (
        <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
          Yes
        </Text>
      ) : (
        <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
          No
        </Text>
      )}
    </Row>
  );
}
