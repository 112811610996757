import { ClientSettingsService, PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageHeader,
  List,
  ListItem,
  Loading,
  Row,
  Select,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Tooltip,
} from '@premagic/myne';
import { useMemo } from 'react';
import MESSAGES from '../../../../../common/Messages';
import APP_URLS from '../../../services/AppRouteURLService';
import PremiumFeatureIcon from '../PremiumFeatureIcon';

type PeopleGalleryPreferenceProps = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
};

export function PeopleGalleryPreference(props: PeopleGalleryPreferenceProps): JSX.Element {
  const { clientSettings, savePreferences, isSaving, errors } = props;
  const guestGalleryView =
    clientSettings[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_VIEW] ||
    ProjectMetaService.GUEST_GALLERY_VIEWS.NO_GROUPING;

  const groupByOptions = useMemo(
    () =>
      Object.entries(ProjectMetaService.GUEST_GALLERY_VIEWS_DETAILS).map(([key, value]) => ({
        label: value.label,
        value: key,
      })),
    [],
  );

  return (
    <Form onSubmit={savePreferences} errors={errors}>
      <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
        People Gallery
      </Text>
      <Space vertical size={1} />
      <FormGroup>
        <Row vcenter>
          <Text>Group photo by</Text>
          <Space size={6} />
          <Col>
            <Select
              isMulti={false}
              name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_VIEW}
              value={groupByOptions.find((option) => option.value === guestGalleryView)}
              options={groupByOptions}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} link="/settings">
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
          Save
        </Button>
      </FormFooter>
    </Form>
  );
}

type RegistrationSettingsProps = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
};

function RegistrationSettings(props: RegistrationSettingsProps) {
  const { clientSettings, savePreferences, isSaving, isLoading } = props;
  const defaultConsentOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Auto', value: 'auto' },
  ];
  const selectedValue =
    clientSettings[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.EVENT_REGISTRATION_DEFAULT_CONSENT_TO_USE_DATA] ||
    'auto';
  const selectedOptions = defaultConsentOptions.find((option) => option.value === selectedValue);
  return (
    <div>
      <Space vertical />
      <Row>
        <Space />
        <SubTitle>Registration</SubTitle>
      </Row>
      <ListItem>
        <Col size={10}>
          <Tooltip
            message={
              <div>
                No: Attendees will have to agree to the terms and conditions to register for the event
                <Space vertical size={1} />
                Yes: Consent will be auto-selected by default
                <Space vertical size={1} />
                Auto: Consent will be auto-selected depending on attendee's location (EU or non-EU)
              </div>
            }
          >
            <Text color={COLOR_SHADES.DARKER} block>
              Default consent for agreeing to Terms and Conditions
              <Space vertical size={1} />
            </Text>
          </Tooltip>
        </Col>
        {!isLoading && (
          <Col size={null} rightAlighed>
            <Select
              name=""
              isMulti={false}
              options={defaultConsentOptions}
              value={selectedOptions}
              isLoading={isSaving}
              onChange={(option) => {
                savePreferences({
                  data: {
                    [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.EVENT_REGISTRATION_DEFAULT_CONSENT_TO_USE_DATA]:
                      option?.value,
                  },
                });
              }}
            />
          </Col>
        )}
      </ListItem>
    </div>
  );
}
type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_REQUEST_FOR_PII]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII]: boolean;
  };
  companyName?: string;
  isCompanyTypeNotPhotographer: boolean;
};

export default function PhotoDistributionSettingsPage(props: Props) {
  const {
    clientSettings,
    savePreferences,
    isSaving,
    errors,
    isLoading,
    hasFeatures,
    companyName,
    isCompanyTypeNotPhotographer,
  } = props;

  const isPIIDataToggleEnabled =
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.REQUEST_FOR_USER_DATA_FOR_MARKETING] ??
    hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII] === true;
  const enablePublicFolderToAll =
    ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_PERMISSION_SHOW_PUBLIC_FOLDER_TO_ALL in
    clientSettings
      ? (clientSettings[
          ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_PERMISSION_SHOW_PUBLIC_FOLDER_TO_ALL
        ] as boolean)
      : true;

  const hasRequestPIIFeature =
    hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_REQUEST_FOR_PII];

  if (isLoading) return <Loading />;

  return (
    <InnerPage>
      <InnerPageHeader iconName="glob" title="Photo distribution" backTo={APP_URLS.SETTINGS.INDEX}>
        Photo Distribution
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <List>
              {!isCompanyTypeNotPhotographer && (
                <ListItem>
                  <Col size={10}>
                    <Text color={COLOR_SHADES.DARKER} block>
                      Store user information for marketing during photo registration
                      {!hasRequestPIIFeature && <PremiumFeatureIcon module="Request for PII" />}
                    </Text>
                    <Text color={COLOR_SHADES.LIGHT}>Once enabled,</Text>
                    <ul>
                      <li>
                        <Text color={COLOR_SHADES.LIGHT}>
                          The registration page will feature a{' '}
                          <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
                            checkbox allowing users to choose
                          </Text>{' '}
                          whether their information can be used for marketing purposes by {companyName}.
                        </Text>
                      </li>
                      <li>
                        <Text color={COLOR_SHADES.LIGHT}>
                          You can{' '}
                          <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
                            view/download
                          </Text>{' '}
                          the list of users who checked the box from the {MESSAGES.EVENT_ATTENDEES.NAME} tab
                        </Text>
                      </li>
                    </ul>
                  </Col>
                  <Col size={null} rightAlighed>
                    <Row vcenter>
                      <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                        {isPIIDataToggleEnabled ? 'Yes' : 'No'}
                      </Text>
                      <Space size={2} />
                      <ToggleSwitch
                        isLoading={isSaving || isLoading}
                        name=""
                        checked={isPIIDataToggleEnabled}
                        disabled={!hasRequestPIIFeature}
                        onChange={(value) => {
                          savePreferences({
                            data: {
                              [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.REQUEST_FOR_USER_DATA_FOR_MARKETING]:
                                value,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </ListItem>
              )}
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Anyone with link can view the folder which are marked as 'shown in guest gallery'
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {enablePublicFolderToAll ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isSaving || isLoading}
                      name=""
                      checked={enablePublicFolderToAll}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES
                              .GUEST_GALLERY_PERMISSION_SHOW_PUBLIC_FOLDER_TO_ALL]: value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
              <RegistrationSettings
                clientSettings={clientSettings}
                savePreferences={savePreferences}
                isSaving={isSaving}
                isLoading={isLoading}
              />
            </List>
          </Card>
          <Space vertical size={8} />
          <Card>
            <Space>
              <PeopleGalleryPreference
                clientSettings={clientSettings}
                isSaving={isSaving}
                savePreferences={savePreferences}
                errors={errors}
              />
            </Space>
          </Card>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
