import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { useEffect } from 'react';

import { FileService, ProjectMetaService } from '@premagic/core';
import { MediaUtils, PhotoWatermarkUtils } from '@premagic/utils';

import MESSAGES from '../../../../common/Messages';
import ProjectMetaToggleContainer from '../projects/highlight-settings/project-meta-toggle/ProjectMetaToggleContainer';
import BrandingFilesModalContainer from '../projects/sponsor/branding-files/BrandingFilesModalContainer';
import BrandingFilesSelectFileForWatermarkModalContainer from '../projects/sponsor/branding-files/BrandingFilesSelectFileForWatermarkModalContainer';
import AddWatermarkPanelContainer from './AddWatermarkPanelContainer';
import EditWatermarkPanelContainer from './EditWatermarkPanelContainer';
import WatermarkPreviewButtonContainer from './watermark-preview/WatermarkPreviewButtonContainer';

type Props = {
  projectId: string;
  hasWatermarkFeature: boolean;
  files: Record<string, FileService.FolderFileType>;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  showEditOfWatermark: (id: string) => void;
  showAddOfWatermark: () => void;
  brandingFolderId?: string;
  fetchFolderFiles: (projectId: string, folderId: string) => void;
  showAllBrandingFiles: () => void;
  isSelectionAlbumEnabled: boolean;
  eventId?: string;
};

export default function ProjectWatermarkSettingCard(props: Props) {
  const {
    projectId,
    hasWatermarkFeature,
    projectMeta,
    showEditOfWatermark,
    showAddOfWatermark,
    files,
    brandingFolderId,
    fetchFolderFiles,
    showAllBrandingFiles,
    isSelectionAlbumEnabled,
    eventId,
  } = props;

  useEffect(() => {
    if (brandingFolderId) fetchFolderFiles(projectId, brandingFolderId);
  }, [projectId, brandingFolderId, fetchFolderFiles]);

  if (!hasWatermarkFeature) return null;
  const oldWatermark = projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_FILE]
    ? {
        'old-watermark': {
          size: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_SIZE],
          opacity: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_OPACITY],
          file: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_FILE],
          position: projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_POSITION],
        },
      }
    : {};
  const watermarks = Object.entries(projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK] || oldWatermark);

  return (
    <Card accordion>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Project Watermark settings</CardHeaderTitle>
        </CardHeader>
        <CardContent>
          <Col>
            {/* {isSelectionAlbumEnabled && ( */}
            {/*  <div> */}
            {/*    <ProjectMetaToggleContainer */}
            {/*      projectId={projectId} */}
            {/*      itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.SELECTION_ENABLE_WATERMARK} */}
            {/*    /> */}
            {/*    <Space vertical /> */}
            {/*  </div> */}
            {/* )} */}
            <ProjectMetaToggleContainer
              projectId={projectId}
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.HIGHLIGHT_ENABLE_WATERMARK}
            />
          </Col>
          <Space vertical size={2} />

          {watermarks.length > 0 && (
            <Row>
              <Col rightAlighed size={null}>
                <WatermarkPreviewButtonContainer projectId={projectId} eventId={eventId} />
              </Col>
            </Row>
          )}

          <Space vertical size={2} />
          <List>
            {watermarks.length > 0 && (
              <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
                <Col size={3}>
                  <Space />
                </Col>
                <Space size={8} />
                <Col size={2}>
                  <Text muted>Position</Text>
                </Col>
                <Space />
                <Col size={1}>
                  <Text muted>Size</Text>
                </Col>
                <Col size={1}>
                  <Text muted>Opacity</Text>
                </Col>
              </ListItem>
            )}
            {watermarks?.map(([id, watermark]) => {
              const { file: fileId, position, size, opacity } = watermark || {};
              const file = fileId && files[fileId];
              return (
                <ListItem key={id} style={LIST_ITEM_STYLES.COMPACT_LIST}>
                  <Col size={3}>
                    <div
                      style={{
                        display: 'inline-flex',
                        background: '#ddd',
                        borderRadius: '4px',
                        height: '48px',
                        maxWidth: '100%',
                      }}
                    >
                      {file ? (
                        <ImageV2
                          alt={id}
                          style={IMAGE_V2_STYLES.THUMBNAIL_SM}
                          imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DEFAULT}
                          src={file.dynamic_image_url}
                        />
                      ) : (
                        <Space size={2}>
                          <Text color={COLOR_SHADES.DANGER}>(Not Set)</Text>
                        </Space>
                      )}
                    </div>
                  </Col>
                  <Space size={8} />
                  <Col size={2}>
                    <Text>{position && PhotoWatermarkUtils.WATERMARK_POSITION_DETAILS[position].label}</Text>
                  </Col>
                  <Space />
                  <Col size={1}>
                    <Text>{size}</Text>
                  </Col>
                  <Col size={1}>
                    <Text>{opacity}</Text>
                  </Col>
                  <ListItemAction>
                    <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={() => showEditOfWatermark(id)}>
                      Edit
                    </Button>
                  </ListItemAction>
                </ListItem>
              );
            })}
            <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
              <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.SM} onClick={showAddOfWatermark}>
                <Icon name="plus" size={ICON_SIZES.SM} /> Watermark
              </Button>
              {watermarks.length > 0 && (
                <Col size={null} rightAlighed>
                  <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={showAllBrandingFiles}>
                    Show all Branding files
                  </Button>
                </Col>
              )}
            </ListItem>
          </List>
          <EditWatermarkPanelContainer projectId={projectId} />
          <AddWatermarkPanelContainer projectId={projectId} />
          <BrandingFilesSelectFileForWatermarkModalContainer projectId={projectId} />
          <BrandingFilesModalContainer projectId={projectId} />
          {!hasWatermarkFeature && <Text color={COLOR_SHADES.WARNING}>{MESSAGES.WATERMARK.UPGRADE}</Text>}
        </CardContent>
      </Space>
    </Card>
  );
}
