import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  CountrySelect,
  CurrencySelect,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  Input,
  INPUT_SIZES,
  Row,
  Space,
  SubTitle,
  TimezoneSelect,
} from '@premagic/myne';
import { AccountType } from '../../acccount/AccountService';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  account?: AccountType;
  saveAccount: (id: string, formData: FormResponseType) => void;
  isSaving: boolean;
  errors?: FormErrorType;
};

export default function AccountBasicSettings(props: Props) {
  const { account, errors, saveAccount, isSaving } = props;
  if (!account) return <div>No account set!</div>;

  const {
    currency,
    name,
    country,
    timezone,
    billing_address_street_1: billingAddress,
    state,
    city,
    zipcode,
    id,
    tax_id: taxId,
    tax_name: taxName,
    tax_percentage: taxPercentage,
  } = account;
  return (
    <Form onSubmit={(data) => saveAccount(id, data)} errors={errors}>
      <Card>
        <Space>
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <Input name="name" defaultValue={name} disabled size={INPUT_SIZES.MD} />
          </FormGroup>
          <FormGroup>
            <FormLabel>Timezone</FormLabel>
            <TimezoneSelect name="timezone" defaultValue={timezone} size={INPUT_SIZES.MD} />
          </FormGroup>
          <Space vertical size={8} />

          <SubTitle>Currency & Taxes</SubTitle>
          <Space vertical size={2} />
          <FormGroup>
            <FormLabel>Currency</FormLabel>
            <CurrencySelect defaultValue={currency} />
          </FormGroup>
          <FormGroup>
            <FormLabel>Tax ID (VAT/GST)</FormLabel>
            <Input name="tax_id" defaultValue={taxId} size={INPUT_SIZES.MD} />
          </FormGroup>
          <Space vertical size={2} />
          <Row vcenter>
            <FormGroup>
              <FormLabel>Tax Name</FormLabel>
              <Input name="tax_name" defaultValue={taxName || 'GST'} size={INPUT_SIZES.SM} />
            </FormGroup>
            <Space size={5} />
            <FormGroup>
              <FormLabel>Tax Rate</FormLabel>
              <Input name="tax_percentage" defaultValue={taxPercentage || 18} size={INPUT_SIZES.XS} />
              <Space size={1} /> %
            </FormGroup>
          </Row>
          <Space vertical size={8} />

          <SubTitle>Billing address</SubTitle>
          <Space vertical size={2} />
          <FormGroup>
            <FormLabel isRequired>Street Address</FormLabel>
            <Input required name="billing_address_street_1" defaultValue={billingAddress} />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <FormLabel isRequired>City</FormLabel>
                <Input required name="city" defaultValue={city} />
              </FormGroup>
            </Col>
            <Space />
            <Col>
              <FormGroup>
                <FormLabel isRequired>State</FormLabel>
                <Input required name="state" defaultValue={state} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <FormLabel isRequired>Zipcode</FormLabel>
                <Input required type="text" name="zipcode" defaultValue={zipcode} />
              </FormGroup>
            </Col>
            <Space />
            <Col>
              <FormGroup>
                <FormLabel isRequired>Country</FormLabel>
                <CountrySelect name="country" defaultValue={country} />
              </FormGroup>
            </Col>
          </Row>
        </Space>
      </Card>
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.SETTINGS.INDEX}>
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
          Save
        </Button>
      </FormFooter>
    </Form>
  );
}
