import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { customerClientsKeysSelector, fetchClientsData } from './CustomerClientsDataDuck';
import CustomerClientsPage from './CustomerClientsPage';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENTS.FETCH_ALL)(state),
    clients: customerClientsKeysSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchClientsData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerClientsPage);
