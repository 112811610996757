import { connect } from 'react-redux';
import { EventTrackerService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

import { isMobileUp } from '../../AppDuck';
import { requestingUserIdSelector } from '../../crm/users/UsersDataDuck';
import { companyDataSelector } from '../company/CompanyDataDuck';
import SubscriptionsPage from './SubscriptionsPage';
import { getCurrentSubscription } from './SubscriptionsDataDuck';
import {
  clientSettingsDataSelector,
  isCompanyTypeNotPhotographerSelector,
} from '../preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  return {
    company: companyDataSelector(state),
    isLoading: isLoadingSelector(LOADINGS.SUBSCRIPTION.FETCH)(state),
    isMobileUp: isMobileUp(state),
    clientSettings: clientSettingsDataSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    userId: requestingUserIdSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (company, clientSettings, userId) => {
      dispatch(
        getCurrentSubscription(dispatch, {
          company,
          clientSettings,
          userId,
        }),
      );
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PAYMENT.VIEW);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);
