import { ColorUtils } from '@premagic/utils';
import { BACKGROUND_MUSICS_IDS } from './BackgroundMusicService';
import { CLIP_ART_IDS } from './ClientWebsiteClipArtService';
import { RELIGIOUS_CLIP_ARTS_IDS } from './ClientWebsiteReligiousClipArtService';
import { FONTS_DETAILS, FONT_IDS, loadFonts, loadGoogleFonts } from './FontService';

// LAKE NAMES
export enum THEMES_IDS {
  LAGUNA = 1,
  BAIKAL = 2,
  BLED = 3,
  CRATER = 4,
  // HUTT_LAGOON = 5,
  // ATTABAD = 6,
  // NAKURUM,
  // ATITLAN,
  // BACALAR,
  // KAWAGUCHIKO,
  // THALE_NOI,
  // TAHOE,
  // PICHOLA,
  // MELISSANI,
  // LUCERNE,
  // COMO,
  // NATRON,
  // LAC_DE_SAINTE_CROIX,
  // MINNEWANKA,
  // TITICACA,
  // BUTTERMERE,
  // SAIMAA,
  // MALAWI,
  // JOKULSARLON,
  // POWELL,
  // LAGO_PEHE,
  // PHEWA_TAL,
  // PLITVICE,
  // RAKOTZSEE,
}

export enum THEMES_STYLE_IDS {
  DEFAULT = 1,
  BLURED = 2,
}

interface ThemeDefinitionType {
  path: string;
  font: {
    primary: FONT_IDS;
    secondary: FONT_IDS;
  };
}

export const THEMES: Record<THEMES_IDS, ThemeDefinitionType> = {
  [THEMES_IDS.LAGUNA]: {
    path: 'laguna',
    font: {
      primary: FONT_IDS.SNELL_ROUNDHAND,
      secondary: FONT_IDS.POPPINS,
    },
  },
  [THEMES_IDS.BAIKAL]: {
    path: 'baikal',
    font: {
      primary: FONT_IDS.DANCING_SCRIPT,
      secondary: FONT_IDS.COMFORTAA,
    },
  },
  [THEMES_IDS.BLED]: {
    path: 'bled',
    font: {
      primary: FONT_IDS.ALLISON,
      secondary: FONT_IDS.KAISEI_DECOL,
    },
  },
  [THEMES_IDS.CRATER]: {
    path: 'crater',
    font: {
      primary: FONT_IDS.SOURCE_SANS_PRO,
      secondary: FONT_IDS.LATO,
    },
  },
};

export function getFolderPathForTheme(id: THEMES_IDS): string {
  if (id in THEMES) {
    const theme = THEMES[id];
    return theme.path;
  }
  // empty case- it will never happen
  return getFolderPathForTheme(THEMES_IDS.LAGUNA);
}

export function getFontsForTheme(id: THEMES_IDS): { primary: FONT_IDS; secondary: FONT_IDS } {
  if (id in THEMES) {
    const theme = THEMES[id];
    return {
      ...theme.font,
    };
  }

  // empty case- it will never happen
  return getFontsForTheme(THEMES_IDS.LAGUNA);
}

export type ThemeTokensType = {
  id: THEMES_IDS;
  style_id: THEMES_STYLE_IDS;
  clipart_id: CLIP_ART_IDS;
  music_id: BACKGROUND_MUSICS_IDS;
  religious_clipart_id: RELIGIOUS_CLIP_ARTS_IDS;
  color: {
    background: string;
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
  };
  font: {
    primary_font: string;
    secondary_font: string;
  };
  client_whitelist_enabled: boolean;
};

export function updateThemeVariable(themeTokens: ThemeTokensType): void {
  const { id: themeId, color, font } = themeTokens;
  const {
    primary_color: primaryColor,
    secondary_color: secondaryColor,
    tertiary_color: accentColor,
    background,
  } = color;
  let primaryFontName;
  let secondaryFontName;

  if (font.primary_font && font.secondary_font) {
    const tertiary = FONTS_DETAILS[FONT_IDS.FUZZY_BUBBLES].nameToLoad;
    primaryFontName = font.primary_font;
    secondaryFontName = font.secondary_font;
    loadGoogleFonts([font.primary_font, font.secondary_font, tertiary]);
  } else {
    const fonts = getFontsForTheme(themeId);
    loadFonts([fonts.primary, fonts.secondary, FONT_IDS.FUZZY_BUBBLES]);
    primaryFontName = FONTS_DETAILS[fonts.primary].name;
    secondaryFontName = FONTS_DETAILS[fonts.secondary].name;
  }
  // const accentContrastForWhite = ColorUtils.createNewColorBasedOnBackgroundAndColor({
  //   bgColor: '#fff',
  //   color: background,
  //   weight: 0.7,
  // });
  const thresholdForAccentColor = accentColor && ColorUtils.getColorLuminance(accentColor) > 0.5 ? 256 / 1.5 : 256;

  const themeData = {
    'color-primary': primaryColor,
    'color-primary-light': ColorUtils.lightenDarkenColor(primaryColor, 50),
    'color-primary-contrast': ColorUtils.getContrastColor({
      bgColor: primaryColor,
      threshold: 128,
    }),
    'color-secondary': secondaryColor,
    'color-secondary-light': ColorUtils.lightenDarkenColor(secondaryColor, 50),
    'color-secondary-contrast': ColorUtils.getContrastColor({
      bgColor: secondaryColor,
      threshold: 128,
    }),

    // 'color-tertiary': tertiaryColor || primaryColor,
    // 'color-tertiary-light': ColorUtils.lightenDarkenColor(tertiaryColor || primaryColor, 50),
    // 'color-tertiary-contrast': ColorUtils.getContrastColor({
    //   bgColor: tertiaryColor,
    //   threshold: 128,
    // }),

    'color-accent': accentColor || primaryColor,
    'color-accent-light': ColorUtils.lightenDarkenColor(accentColor || primaryColor, 50),
    'color-accent-contrast': ColorUtils.getContrastColor({
      bgColor: accentColor,
      threshold: thresholdForAccentColor,
    }),
    // 'color-accent-contrast-for-white-bg': accentContrastForWhite,
    // 'color-accent-contrast-for-white-bg-contrast': ColorUtils.getContrastColor({
    //   bgColor: accentContrastForWhite,
    //   threshold: 256,
    // }),
    'body-bg': background,
    'body-bg-contrast-light': ColorUtils.getContrastColor({
      bgColor: background,
      threshold: 10,
    }),
    'body-bg-contrast': ColorUtils.getContrastColor({
      bgColor: background,
      threshold: 120,
    }),
    'body-bg-contrast-dark': ColorUtils.getContrastColor({
      bgColor: background,
      threshold: 180,
    }),
    'font-primary': primaryFontName,
    'font-secondary': secondaryFontName,
  };

  Object.entries(themeData).forEach(([variable, value]) =>
    document.documentElement.style.setProperty(`--js-theme-${variable}`, value),
  );
}
