import { ActionTypeUtils } from '@premagic/utils';
import { createAction } from 'redux-actions';
import { HightlightAlbumThemeService } from '@premagic/core';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { clearErrorState } from '../../../../../../common/ErrorDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('HIGHLIGHT_DESIGN');

export const loadHighlightFont = createAction(
  getActionType('DATA', 'UPDATE'),
  (dispatch, fontFamily: { primary_font: string; secondary_font?: string }) => {
    const { primary_font: primaryFontFamily, secondary_font: secondaryFontFamily } = fontFamily;

    const loadingKey = LOADINGS.FONTS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    function fontActive() {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    }

    HightlightAlbumThemeService.loadAndUpdateGoogleFonts(
      { primary_font: primaryFontFamily, secondary_font: secondaryFontFamily },
      '',
      undefined,
      fontActive,
    );
  },
);
