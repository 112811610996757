const FB_APP_ID = '';

const URLS = {
  WHATSAPP: {
    MOBILE: (message) => `whatsapp://send?text=${message}`,
    DESKTOP: (message) => `https://api.whatsapp.com/send?text=${message}`,
  },
  FACEBOOK: {
    MOBILE: (message, url) => `fb-messenger://share/?message=${message}&link=${url}`,
    DESKTOP: (message, fbid, url) =>
      `https://www.facebook.com/dialog/send?app_id=${fbid}&link=${url}&redirect_uri=${encodeURIComponent(
        window.location.href,
      )}&display=popup&quote=${message}`,
  },
};

export function getShareUrls(title, message, url) {
  const isDesktop = window.innerWidth > 800;

  const shareContent = `${encodeURIComponent(title)} \n ${encodeURIComponent(message)} \n ${encodeURIComponent(url)}`;
  return {
    whatsapp: isDesktop ? URLS.WHATSAPP.DESKTOP(shareContent) : URLS.WHATSAPP.MOBILE(shareContent),
    facebook: isDesktop
      ? URLS.FACEBOOK.DESKTOP(shareContent, FB_APP_ID, encodeURIComponent(url))
      : URLS.FACEBOOK.MOBILE(shareContent, encodeURIComponent(url)),
    // email:    (payload, title) => 'mailto:?subject='+title+'&body='+payload,
    // sms:      payload => 'sms:?body='+payload
    copy: `
    ${title}\n${message}\n${url}`,
  };
}

export function getWhatsappMessageLink(number: string, message?: string) {
  return `https://wa.me/${number.replace(/[+-]/g, '').replace(' ', '')}?text=${encodeURIComponent(message || 'Hi')}`;
}
