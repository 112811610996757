import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Dialog,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
} from '@premagic/myne';

type Props = {
  isLoading: boolean;
  errors?: FormErrorType;
  createFolder: (name: string) => void;
};

export default function AddCompanyBrandingButton(props: Props): JSX.Element | null {
  const { isLoading, errors, createFolder } = props;
  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);

  return (
    <>
      <Button
        active={showDialog}
        style={BUTTON_STYLES.PRIMARY}
        size={BUTTON_SIZE.SM}
        ref={target}
        onClick={() => setShowDialog(!showDialog)}
      >
        <Icon name="plus" size={ICON_SIZES.SM} /> Branding
      </Button>
      <Dialog target={target.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Form
          onSubmit={(formResponse) => createFolder((formResponse?.data?.name as string) || 'Default')}
          errors={errors}
        >
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <Input name="name" required autoFocus placeholder="eg. Classic, Premium" />
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
