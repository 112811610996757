import { connect } from 'react-redux';
import { EventTrackerService } from '@premagic/core';

import EventReportLayout from './EventReportLayout';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { eventProjectSelectorSelector } from '../../../projects/AccountProjectsDataDuck';
import { getProjectStatsData, statsOnProjectSelector } from '../../../projects/project-stats/ProjectsStatsDataDuck';
import { eventSponsorsIdsSelector, getEventSponsorsForProject } from '../event-sponsors/EventSponsorDataDuck';
import { getEventReportDataForDashboard } from './EventReportDuck';
import {
  isAdvocayPosterEnabledSelector,
  projectMetaDataForProjectSelector,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    projectId,
    focusId,
    hasPermissions: {
      [PERMISSIONS.EVENT_SPONSOR_REPORT]: hasPermission(PERMISSIONS.EVENT_SPONSOR_REPORT)(state),
    },
    stats: projectId ? statsOnProjectSelector(projectId)(state) : {},
    sponsorIds: eventSponsorsIdsSelector(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    isFeatureAddEventPosterEnabled: isAdvocayPosterEnabledSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EVENT_REPORT.VIEW, {});
      dispatch(getEventSponsorsForProject(dispatch, projectId));
      dispatch(getEventReportDataForDashboard(dispatch, projectId));
    },
    refresh: (projectId) => {
      dispatch(getEventReportDataForDashboard(dispatch, projectId));
    },
    getProjectStatsData: (projectId) => {
      dispatch(getProjectStatsData(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventReportLayout);
