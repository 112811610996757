import {
  ButtonCircle,
  ButtonIcon,
  BUTTON_CIRCLE_SIZES,
  BUTTON_CIRCLE_STYLES,
  BUTTON_ICONS_SIZES,
  BUTTON_ICON_STYLES,
  Col,
  Icon,
  ICON_SIZES,
  Range,
  RANGE_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import React from 'react';

type ImageEditorCropInputsProps = {
  rotate: number;
  scale: number;
  setRotate: React.Dispatch<React.SetStateAction<number>>;
  setScale: React.Dispatch<React.SetStateAction<number>>;
};

export default function ImageEditorCropInputs(props: ImageEditorCropInputsProps) {
  const { rotate, scale, setRotate, setScale } = props;
  return (
    <>
      <Space size={4}>
        <Row>
          <Col size={null} rightAlighed>
            <Row>
              <ButtonCircle
                style={BUTTON_CIRCLE_STYLES.DEFAULT}
                size={BUTTON_CIRCLE_SIZES.N}
                layer={1}
                onClick={() => setRotate((prevValue) => Math.min(180, Math.max(-180, prevValue + 90)))}
              >
                <Icon name="rotate_cw" size={ICON_SIZES.MD} />
              </ButtonCircle>
              <Space size={3} />
              <ButtonCircle
                style={BUTTON_CIRCLE_STYLES.DEFAULT}
                size={BUTTON_CIRCLE_SIZES.N}
                onClick={() => setRotate((prevValue) => Math.min(180, Math.max(-180, prevValue - 90)))}
              >
                <Icon name="rotate_ccw" size={ICON_SIZES.MD} />
              </ButtonCircle>
            </Row>
          </Col>
        </Row>
      </Space>
      <Row vcenter>
        <Col size={null}>
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            Zoom
          </Text>
          <Row vcenter>
            <ButtonIcon
              title="Decrease zoom"
              onClick={() => setScale((prevValue) => prevValue - 0.1)}
              style={BUTTON_ICON_STYLES.DEFAULT}
              size={BUTTON_ICONS_SIZES.SM}
            >
              <Icon name="minus" size={ICON_SIZES.MD} />
            </ButtonIcon>
            <Range
              defaultValue={scale}
              name="scale"
              min={1}
              max={50}
              step={0.1}
              onChange={(val) => setScale(val)}
              hideText
              size={RANGE_SIZES.MD}
            />
            <ButtonIcon
              title="Increase zoom"
              onClick={() => setScale((prevValue) => prevValue + 0.1)}
              style={BUTTON_ICON_STYLES.DEFAULT}
              size={BUTTON_ICONS_SIZES.SM}
            >
              <Icon name="plus" size={ICON_SIZES.MD} />
            </ButtonIcon>
          </Row>
        </Col>
        <Col size={null} rightAlighed>
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            Straighten
          </Text>
          <Row vcenter>
            <ButtonIcon
              title="Rotate counter clockwise"
              onClick={() => setRotate((prevValue) => Math.min(180, Math.max(-180, prevValue - 1)))}
              style={BUTTON_ICON_STYLES.DEFAULT}
              size={BUTTON_ICONS_SIZES.SM}
            >
              <Icon name="minus" size={ICON_SIZES.MD} />
            </ButtonIcon>
            <Range
              defaultValue={rotate}
              name="rotate"
              min={-180}
              max={180}
              onChange={(val) => setRotate(Math.min(180, Math.max(-180, val)))}
              hideText
              size={RANGE_SIZES.MD}
            />
            <ButtonIcon
              title="Rotate clockwise"
              onClick={() => setRotate((prevValue) => Math.min(180, Math.max(-180, prevValue + 1)))}
              style={BUTTON_ICON_STYLES.DEFAULT}
              size={BUTTON_ICONS_SIZES.SM}
            >
              <Icon name="plus" size={ICON_SIZES.MD} />
            </ButtonIcon>
          </Row>
        </Col>
      </Row>
    </>
  );
}
