// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { EnvUtils } from '@premagic/utils';

const ClientId = EnvUtils.getEnvValues().NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_ID;

const UserPoolId = EnvUtils.getEnvValues().NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_USER_POOL_ID;
const AwsRegion = EnvUtils.getEnvValues().NX_PUBLIC_PHOTOGRAPHER__AWS_REGION;
const CognitoIdentityPoolId = EnvUtils.getEnvValues().NX_PUBLIC_PHOTOGRAPHER__AWS_COGNITO_IDENTITY_POOL_ID;

const AWS_CONFIG = {
  aws_project_region: AwsRegion,
  aws_cognito_identity_pool_id: CognitoIdentityPoolId,
  aws_cognito_region: AwsRegion,
  aws_user_pools_id: UserPoolId,
  userPoolId: UserPoolId,
  aws_user_pools_web_client_id: ClientId,
  ClientId,
  oauth: {
    domain: 'premagic-a.auth.ap-southeast-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile'],
    redirectSignIn: 'http://localhost:4200/',
    redirectSignOut: 'https://app.premagic.com/signin',
    responseType: 'code',
  },
};

export default AWS_CONFIG;

export const S3_CONFIG = {
  accessKeyId: '',
  secretAccessKey: '',
  region: 'us-east-1',
  bucket: 'asia-assets',
  access: 'public-read',
  gzip: true,
  gzipExclude: ['.jpg', '.jpeg', '.png', '.gif'],
  headers: {
    CacheControl: 'max-age=630720000, public',
  },
  overwrite: false,
};

export const CDN_CONFIG = {
  DOMAIN: 'xxxxxxxxxxx.cloudfront.net',
};
