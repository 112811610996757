import React from 'react';
import { connect } from 'react-redux';
import { crmPaymentsSelector } from '../CRMPaymentDataDuck';
import { paymentCategoriesSelector } from '../CRMPaymentCategoryDataDuck';
import PaymentItemDetailsDialog from './PaymentItemDetailsDialog';

function mapStateToProps(
  state,
  ownProps: {
    paymentId: string;
    children: React.ReactNode;
  },
) {
  const { paymentId } = ownProps;

  return {
    payment: crmPaymentsSelector(state)[paymentId],
    categories: paymentCategoriesSelector(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItemDetailsDialog);
