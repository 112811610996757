import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { clientIdForEventSelector, crmEventItemsSelector, updatePriority } from '../../EventsDataDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { hasPermission } from '../../../users/UsersDataDuck';
import EventPrioritySelector from './EventPrioritySelector';

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;

  const event = crmEventItemsSelector(state)[eventId] || {};
  const clientId = clientIdForEventSelector(eventId)(state);
  return {
    eventId,
    clientId,
    priority: event.priority_value,
    isSaving: isLoadingSelector(LOADINGS.EVENT.UPDATE_PRIORITY)(state),
    hasEventManagementPermission: hasPermission(PERMISSIONS.EVENT_DELIVERABLE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePriority: (id, clientId, priority) => {
      dispatch(updatePriority(dispatch, id, clientId, priority));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPrioritySelector);
