import { HightlightAlbumThemeService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { clientSettingsDataSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';
import HighlightFoldersPropertiesSettings from './HighlightFoldersPropertiesSettings';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    clientSettings: clientSettingsDataSelector(state),
    hasHighlightCustomizationFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
    updateThemeVariables: (backgroundColor: string, fontColor: string, accentColor: string) => {
      HightlightAlbumThemeService.updateThemeVariable({
        color: {
          background: backgroundColor,
          font_color: fontColor,
          accent_color: accentColor,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightFoldersPropertiesSettings);
