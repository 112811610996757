import React, { useState } from 'react';
import {
  BUTTON_STYLES,
  Button,
  COLOR_SHADES,
  Card,
  Col,
  HTML_ICON,
  ICON_SIZES,
  Icon,
  List,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
  Tooltip,
} from '@premagic/myne';
import { StringUtils } from '@premagic/utils';
import ReportIssueListItemContainer from './ReportIssueListItemContainer';

type Props = {
  projectId: string;
  name: string;
  closedReportIssuesIds: Array<string>;
};

export default function ClosedReportIssueList(props: Props) {
  const { projectId, name, closedReportIssuesIds } = props;

  const totalClosedIssues = closedReportIssuesIds.length;

  const [isExpanded, setIsExpanded] = useState(false);

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div>
      <Button onClick={handleToggle} style={BUTTON_STYLES.RESET} block>
        <Row vcenter>
          <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
            {name}
          </Text>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3}>{HTML_ICON.DOT}</Text>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3}>
            {totalClosedIssues} {StringUtils.pluralize('issue', totalClosedIssues)}
          </Text>
          <Col size={null} rightAlighed>
            <Tooltip message={isExpanded ? 'Collapse' : 'Expand'}>
              <Space size={2}>
                <Icon name={isExpanded ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
              </Space>
            </Tooltip>
          </Col>
        </Row>
      </Button>

      {isExpanded && (
        <Card>
          {closedReportIssuesIds.length === 0 && (
            <Space>
              <Text muted>There are no resolved reported issues yet.</Text>
            </Space>
          )}
          <List>
            {closedReportIssuesIds.map((issueId) => (
              <ReportIssueListItemContainer key={issueId} projectId={projectId} issueId={issueId} />
            ))}
          </List>
        </Card>
      )}
      <Space vertical />
    </div>
  );
}
