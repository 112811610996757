import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { fetchCRMClientsData, clientKeysSelector } from './ClientsDataDuck';
import ClientsPage from './ClientsPage';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENTS.FETCH_ALL)(state),
    clientIds: clientKeysSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchCRMClientsData(dispatch, []));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
