import { EventSponsorsService } from '@premagic/core';
import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  FormResponseType,
  INPUT_SIZES,
  Input,
  Row,
  Select,
  Space,
} from '@premagic/myne';

const tierOptions = Object.entries(EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.name,
}));

function TierSelect(props: { defaultValue?: EventSponsorsService.EVENT_SPONSORS_TIER }) {
  const { defaultValue } = props;
  const selectedOption = tierOptions.find((option) => option.value === defaultValue) || tierOptions[3];

  return (
    <FormGroup>
      <FormLabel>Tier</FormLabel>
      <Select
        size={INPUT_SIZES.SM}
        options={tierOptions}
        value={selectedOption}
        name="tier"
        isMulti={false}
        menuPlacement="top"
      />
    </FormGroup>
  );
}

type Props = {
  sponsorCategory?: EventSponsorsService.EventSponsorCategoryType;
  onSave: (formResponse: FormResponseType) => void;
};

export default function AddEditSponsorCategoryForm(props: Props) {
  const { sponsorCategory, onSave } = props;

  return (
    <Form onSubmit={onSave}>
      <Row vcenter>
        <Col>
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <Input name="name" size={INPUT_SIZES.SM} placeholder="eg. Gold" defaultValue={sponsorCategory?.name} />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <TierSelect defaultValue={sponsorCategory?.tier} />
        </Col>
        <Space />

        <Button size={BUTTON_SIZE.SM} style={BUTTON_STYLES.PRIMARY} type="submit">
          Save
        </Button>
      </Row>
    </Form>
  );
}
