import { ClientSettingsService, IntegrationService } from '@premagic/core';
import {
  Card,
  Col,
  COLOR_SHADES,
  Icon,
  List,
  ListItem,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
} from '@premagic/myne';
import { StringUtils } from '@premagic/utils';
import PremiumFeatureIcon from '../PremiumFeatureIcon';

type Props = {
  savePreferences: (data) => void;
  isLoading: boolean;
  hasEventIntegrationFeature: boolean;
  integrationEventsSettings: IntegrationService.IntegrationClientSettingsType;
};

export default function EventIntegrationSettings(props: Props) {
  const { integrationEventsSettings, isLoading, savePreferences, hasEventIntegrationFeature } = props;
  return (
    <>
      <Row vcenter>
        <Text block size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD}>
          Integrations
        </Text>
        {!hasEventIntegrationFeature && <PremiumFeatureIcon module="Event Integration" />}
      </Row>
      <Space vertical />
      <Card>
        <List>
          {Object.values(IntegrationService.INTEGRATION_PLATFORMS_DATA).map((item) => {
            const { id: platform, title, svgIconName } = item;
            return (
              <ListItem key={platform}>
                <Col size={10}>
                  <Row vcenter>
                    <Icon name={svgIconName} />
                    <Space size={2} />
                    <Text color={COLOR_SHADES.DARKER} block>
                      {title}
                    </Text>
                  </Row>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {integrationEventsSettings[platform]?.enabled ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      disabled={!hasEventIntegrationFeature}
                      isLoading={isLoading}
                      name=""
                      checked={integrationEventsSettings[platform]?.enabled || false}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.INTEGRATIONS]: {
                              ...integrationEventsSettings,
                              [platform]: { enabled: value },
                            },
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
            );
          })}
        </List>
      </Card>
    </>
  );
}
