import { ClientSettingsService, StorePricingService } from '@premagic/core';
import { Button, BUTTON_STYLES, Form, FormFooter, Space, Text, TEXT_BOLDNESS, TEXT_SIZE, Title } from '@premagic/myne';
import React from 'react';
import PriceSetterTableContainer from '../../price-table/PriceSetterTableContainer';

type Props = {
  product: StorePricingService.ProductItemType;
  onSave: (data) => void;
  onClose: () => void;
  defaultSubProductPrices?: StorePricingService.StorePricingType;
};

export default function PhotoDownloadSettings(props: Props) {
  const { product, onSave, onClose, defaultSubProductPrices } = props;

  const formSpecData = {};

  product?.subproducts.forEach((item) => {
    Object.assign(formSpecData, {
      [item]: {
        type: 'number',
      },
    });
  });

  return (
    <Space size={4}>
      <Form onSubmit={onSave} formSpec={formSpecData}>
        <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} muted>
          Photo Download
        </Text>
        <Space vertical size={10} />
        <PriceSetterTableContainer product={product} defaultSubProductPrices={defaultSubProductPrices} />
        <Space vertical size={5} />
        <FormFooter>
          <Button style={BUTTON_STYLES.TERTIARY} onClick={() => onClose()}>
            Cancel
          </Button>
          <Space size={2} />
          <Button style={BUTTON_STYLES.PRIMARY}>Apply</Button>
        </FormFooter>
      </Form>
    </Space>
  );
}
