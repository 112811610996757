import React from 'react';
import {
  Avatar,
  AVATAR_SIZES,
  Button,
  BUTTON_STYLES,
  Card,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  InnerPageHeader,
  Row,
  SimpleLink,
  Space,
  SubTitle,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { UserType } from '../crm/users/UsersService';
import APP_URLS from '../../services/AppRouteURLService';

type Props = {
  isMobileUp: boolean;
  user?: UserType;
};

export default function UserProfilePage(props: Props): JSX.Element {
  const { user, isMobileUp } = props;
  if (!user) return <div>User not set</div>;
  return (
    <InnerPage>
      <InnerPageHeader iconName="award" title="My Profile">
        My Profile
      </InnerPageHeader>
      <InnerPageContent hasInnerCols>
        <InnerPageContentMainCol>
          <Card>
            <Space size={8}>
              <Row center>
                <Avatar
                  size={AVATAR_SIZES.LG}
                  user={{
                    fullName: user.name || 'Loading',
                    email: user.email,
                  }}
                />
              </Row>
              <Space vertical size={2} />
              <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2} center block>
                {user.name}
              </Text>
              <Text size={TEXT_SIZE.SIZE_3} center block>
                {user.email || '(Email not set)'}
              </Text>
              <Text size={TEXT_SIZE.SIZE_3} center block>
                {user.phone_number || '(Phone not set)'}
              </Text>
              <Space vertical size={8} />
              <Row center>
                <Button style={BUTTON_STYLES.LINK} link={APP_URLS.USER_PROFILE.EDIT}>
                  Edit
                </Button>
              </Row>
            </Space>
          </Card>
          <Space size={2}>
            <Text muted>
              Note: To set profile pic, set your account at{' '}
              <SimpleLink isExternalLink showExternalLinkIcon href="https://en.gravatar.com/">
                Gravatar
              </SimpleLink>
            </Text>
          </Space>
        </InnerPageContentMainCol>
        <InnerPageContentSidebar>
          <SubTitle>
            <Icon name="key" size={ICON_SIZES.SM} /> Security
          </SubTitle>
          <Space vertical />
          <Button style={BUTTON_STYLES.LINK} link={APP_URLS.USER_PROFILE.CHANGE_PASSWORD}>
            Change Password
          </Button>
        </InnerPageContentSidebar>
      </InnerPageContent>
    </InnerPage>
  );
}
