import { EventsService } from '@premagic/core';
import {
  Col,
  Color,
  COLOR_SHADES,
  DateRangePickerWithTimezoneSelector,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  RichTextArea,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { useMemo, useState } from 'react';
import EventStatusSelector from '../details/EventStatusSelector';

type IconContainerProps = {
  children: React.ReactNode;
  iconName: string;
  showIcon?: boolean;
  bottomAlignIcon?: boolean;
};

function IconContainer(props: IconContainerProps) {
  const { children, iconName, showIcon, bottomAlignIcon } = props;

  return (
    <Row>
      {showIcon && (
        <div>
          {bottomAlignIcon && <Space vertical size={2} />}
          <Color shade={COLOR_SHADES.LIGHT} inline>
            <Icon name={iconName} size={ICON_SIZES.MD_SM} />
          </Color>
          <Space />
        </div>
      )}
      <Col size={8}>{children}</Col>
    </Row>
  );
}

const NUM_OF_ATTENDEE_OPTIONS = [
  50, 100, 150, 200, 250, 300, 400, 500, 600, 700, 750, 800, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 3500, 4000, 4500,
  5000, 6000, 7000, 8000, 9000, 10000,
];

type NumberOfAttendeesInEventSelectorProps = {
  defaultValue?: string;
};

function NumberOfAttendeesInEventSelector(props: NumberOfAttendeesInEventSelectorProps): JSX.Element {
  const { defaultValue = 500 } = props;

  const options = useMemo(() => {
    const defaultOptions = NUM_OF_ATTENDEE_OPTIONS.map((item) => ({ label: String(item), value: item }));
    if (defaultValue && !NUM_OF_ATTENDEE_OPTIONS.includes(Number(defaultValue))) {
      return [{ label: String(defaultValue), value: defaultValue }, ...defaultOptions];
    }
    return defaultOptions;
  }, [defaultValue]);

  const defaultSelectedOption = useMemo(
    () => options.find((item) => item.value == defaultValue),
    [defaultValue, options],
  );

  return (
    <Row wrap>
      <Select
        options={options}
        value={defaultSelectedOption}
        name="event[number_of_attendees]"
        isMulti={false}
        size={INPUT_SIZES.SM}
        isCreatable
      />
    </Row>
  );
}

type Props = {
  isFeatureLeadEnabled: boolean;
  selectedEventStatus?: EventsService.EVENT_STATUS;
  setSelectedEventStatus?: (val: EventsService.EVENT_STATUS) => void;
  eventData?: EventsService.EventDataType;
  expandAdditionalInfoByDefault?: boolean;
  showGoogleMapLinkFormItem?: boolean;
  showIcon?: boolean;
  isCompanyTypeNotPhotographer?: boolean;
  hideEventStatus?: boolean;
};

export default function CRMEventDataForm(props: Props) {
  const {
    isFeatureLeadEnabled,
    selectedEventStatus,
    setSelectedEventStatus,
    eventData,
    expandAdditionalInfoByDefault = false,
    showGoogleMapLinkFormItem = false,
    showIcon = true,
    isCompanyTypeNotPhotographer,
    hideEventStatus,
  } = props;

  const {
    start_date: startDate,
    end_date: endDate,
    date_timezone: dateTimeZone,
    location,
    location_map_link: locationMapLink,
    number_of_attendees: numberOfAttendees,
    event_website: eventWebsite,
    about_the_event: aboutTheEvent,
    notes,
  } = eventData || {};

  return (
    <>
      <IconContainer showIcon={showIcon} iconName="clock">
        <DateRangePickerWithTimezoneSelector
          nameForStartDate="event[start_date]"
          nameForEndDate="event[end_date]"
          nameForTimezoneInput="event[date_timezone]"
          title="Event Date"
          showTimePicker
          defaultValueForStartDate={startDate}
          defaultValueForEndDate={endDate}
          timezone={dateTimeZone}
          hideEndDate={!isCompanyTypeNotPhotographer}
        />
      </IconContainer>
      <IconContainer showIcon={showIcon} iconName="map_pin">
        <FormGroup>
          <FormLabel>Venue Address</FormLabel>
          <Input name="event[location]" placeholder="eg. Le Méridien" size={INPUT_SIZES.MD} defaultValue={location} />
        </FormGroup>
        {showGoogleMapLinkFormItem && (
          <>
            <Space />
            <div>
              <FormGroup>
                <FormLabel>Google map link</FormLabel>
                <Input
                  name="event[location_map_link]"
                  placeholder="eg. https://www.google.co.in/maps/place/San Francisco/"
                  size={INPUT_SIZES.MD}
                  defaultValue={locationMapLink}
                />
              </FormGroup>
            </div>
          </>
        )}
      </IconContainer>
      {isCompanyTypeNotPhotographer && (
        <>
          <IconContainer showIcon={showIcon} iconName="users">
            <FormGroup>
              <FormLabel>Number of attendees</FormLabel>
              <NumberOfAttendeesInEventSelector defaultValue={numberOfAttendees} />
            </FormGroup>
          </IconContainer>
          <IconContainer showIcon={showIcon} iconName="link">
            <FormGroup>
              <FormLabel>Event website</FormLabel>
              <Input
                name="event[event_website]"
                placeholder="eg. https://io.google/2024/"
                type="url"
                defaultValue={eventWebsite}
              />
            </FormGroup>
          </IconContainer>
        </>
      )}
      <IconContainer showIcon={showIcon} iconName="info">
        <FormGroup>
          <FormLabel>About the event</FormLabel>
          <RichTextArea name="event[about_the_event]" defaultValue={aboutTheEvent} />
        </FormGroup>
      </IconContainer>
      {isFeatureLeadEnabled && !isCompanyTypeNotPhotographer && !hideEventStatus ? (
        <IconContainer showIcon={showIcon} iconName="check">
          <FormGroup>
            <EventStatusSelector
              status={selectedEventStatus}
              onChange={(newStatus) => (setSelectedEventStatus ? setSelectedEventStatus(newStatus) : {})}
            />
          </FormGroup>
        </IconContainer>
      ) : (
        <input type="hidden" name="event[status]" value={EventsService.EVENT_STATUS.CONFIRMED} />
      )}
    </>
  );
}
