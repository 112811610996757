import React from 'react';
import {
  Card,
  CARD_STYLES,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  Tooltip,
} from '@premagic/myne';

type ClientDetailsProps = {
  client?: {
    name: string;
    phone_number?: string;
    email?: string;
    isNew?: boolean;
  };
};

export default function SharePanelClientDetails(props: ClientDetailsProps) {
  const { client } = props;
  const { name, phone_number: phone, email, isNew } = client || {};

  return (
    <Card style={CARD_STYLES.SECONDARY}>
      <Space>
        <Row>
          <Text block boldness={TEXT_BOLDNESS.BOLD} ellipsis>
            {name}
          </Text>
          {!isNew && (
            <Tooltip message="Already shared">
              <Color shade={COLOR_SHADES.SUCCESS}>
                <Icon name="check_circle" size={ICON_SIZES.SM} />
              </Color>
            </Tooltip>
          )}
        </Row>
        <Space vertical size={2} />
        <Row vcenter>
          <Color shade={COLOR_SHADES.LIGHT} inline>
            <Icon name="mail" size={ICON_SIZES.SM} />
          </Color>
          <Space size={2} />
          <Text block ellipsis>
            {email}
          </Text>
        </Row>
        <Space vertical size={2} />
        <Row vcenter>
          <Color shade={COLOR_SHADES.LIGHT} inline>
            <Icon name="phone" size={ICON_SIZES.SM} />
          </Color>
          <Space size={2} />
          <Text block>{phone}</Text>
        </Row>
      </Space>
    </Card>
  );
}
