import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { fetchProject, projectsSelector } from '../AccountProjectsDataDuck';
import HighlightFoldersPage from './HighlightFoldersPage';

function mapStateToProps(
  state,
  ownProps: {
    location: { pathname: string };
    match: {
      params: {
        projectId: string;
        folderId?: string;
      };
    };
  },
) {
  const { projectId, folderId } = ownProps.match.params;
  const { pathname } = ownProps.location;

  return {
    projectId,
    currentUrlPathname: pathname,
    project: projectsSelector(state)[projectId],
    activeFolderId: folderId,
    isLoading:
      isLoadingSelector(LOADINGS.PROJECT.FETCH)(state) || isLoadingSelector(LOADINGS.PROJECT_META.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      dispatch(fetchProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightFoldersPage);
