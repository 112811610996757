import React from 'react';
import { connect } from 'react-redux';
import ReportIssueButton from './ReportIssueButton';
import { enableReportingIssueFromPeopleSelector } from '../../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps;
  return {
    eventId,
    isReportIssueEnabled: enableReportingIssueFromPeopleSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssueButton);
