import { History } from 'history';
import React from 'react';
import { Provider } from 'react-redux';

import AccountAppRoutesContainer from './AccountAppRoutesContainer';

type Props = {
  store: any;
  history: History;
};

export default function Root(props: Props) {
  const { store, history } = props;
  return (
    <>
      {/* Will add StrictMode after upgrading react-router to latest version. There is some incompatibility between current react-router and react 18 */}
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <AccountAppRoutesContainer history={history} />
      </Provider>
      <div id="window-panel-root" />
      <div id="window-box-root" />
      <div id="dialog-root" />
      {/* </React.StrictMode> */}
    </>
  );
}
