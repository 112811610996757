import { ProjectMetaService } from '@premagic/core';
import {
  BUTTON_TILE_SIZE,
  ButtonTile,
  COLOR_SHADES,
  Grid,
  HighlightPreviewDesktop,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  SubTitle,
  Space,
} from '@premagic/myne';
import { MediaUtils, StringUtils, useElementSize } from '@premagic/utils';
import React from 'react';

type Props = {
  onChange: (layout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS) => void;
  coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  imageSource?: string;
  alt?: string;
  title?: string;
  isLayoutSelectorEnabled?: boolean;
  companyLogo?: string;
  companyName?: string;
  isCompanyTypeNotPhotographer: boolean;
};

export default function HighlightDesignCoverLayoutSelector(props: Props) {
  const {
    onChange,
    coverLayout,
    imageSource,
    alt = 'cover-photo-layout',
    title = 'Rahul & Riya',
    isLayoutSelectorEnabled,
    companyLogo,
    companyName,
    isCompanyTypeNotPhotographer,
  } = props;

  const formatedTitle = StringUtils.toTitleCase(title);

  const [$ref, size] = useElementSize();

  if (isCompanyTypeNotPhotographer) return null;

  return (
    <div>
      <Space vertical size={2} />
      <SubTitle>Cover Layout</SubTitle>
      <Space vertical />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.SM} direction="vertical" showShadow>
        <Grid colSize={134} gap={2}>
          <ButtonTile
            title="Default"
            size={BUTTON_TILE_SIZE.SM_XS}
            onClick={() => {
              if (
                isLayoutSelectorEnabled &&
                coverLayout !== ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT
              ) {
                onChange(ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT);
              }
            }}
            active={coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT}
          >
            <div ref={$ref}>
              <HighlightPreviewDesktop
                imageSource={imageSource}
                alt={alt}
                title={formatedTitle}
                coverLayout={ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT}
                companyLogo={companyLogo}
                companyName={companyName}
                imageWidth={size.width}
                size="sm"
                fromTheme={false}
                disableIconAnimation
                imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
              />
            </div>
          </ButtonTile>
          <ButtonTile
            title="Classic"
            size={BUTTON_TILE_SIZE.SM_XS}
            onClick={() => {
              if (
                isLayoutSelectorEnabled &&
                coverLayout !== ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC
              ) {
                onChange(ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC);
              }
            }}
            active={coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC}
            disabled={!isLayoutSelectorEnabled}
            disabledMessage="Upgrade your Plan to select this Layout"
            tooltipPlacement="top"
          >
            <div ref={$ref}>
              <HighlightPreviewDesktop
                imageSource={imageSource}
                alt={alt}
                title={formatedTitle}
                coverLayout={ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC}
                companyLogo={companyLogo}
                companyName={companyName}
                imageWidth={size.width}
                size="sm"
                fromTheme={false}
                disableIconAnimation
                imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
              />
            </div>
          </ButtonTile>
          <ButtonTile
            title="Full Photo"
            size={BUTTON_TILE_SIZE.SM_XS}
            onClick={() => {
              if (
                isLayoutSelectorEnabled &&
                coverLayout !== ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.FULL_IMAGE
              ) {
                onChange(ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.FULL_IMAGE);
              }
            }}
            active={coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.FULL_IMAGE}
            disabled={!isLayoutSelectorEnabled}
            disabledMessage="Upgrade your Plan to select this Layout"
            tooltipPlacement="top"
          >
            <div ref={$ref}>
              <HighlightPreviewDesktop
                imageSource={imageSource}
                alt={alt}
                title={formatedTitle}
                coverLayout={ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.FULL_IMAGE}
                companyLogo={companyLogo}
                companyName={companyName}
                imageWidth={size.width}
                size="sm"
                fromTheme={false}
                titleColor={COLOR_SHADES.WHITE}
                disableIconAnimation
                imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
              />
            </div>
          </ButtonTile>
          <ButtonTile
            title="Background Blur"
            size={BUTTON_TILE_SIZE.SM_XS}
            onClick={() => {
              if (
                isLayoutSelectorEnabled &&
                coverLayout !== ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR
              ) {
                onChange(ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR);
              }
            }}
            active={coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR}
            disabled={!isLayoutSelectorEnabled}
            disabledMessage="Upgrade your Plan to select this Layout"
            tooltipPlacement="top"
          >
            <div ref={$ref}>
              <HighlightPreviewDesktop
                imageSource={imageSource}
                alt={alt}
                title={formatedTitle}
                coverLayout={ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR}
                companyLogo={companyLogo}
                companyName={companyName}
                imageWidth={size.width}
                size="sm"
                fromTheme={false}
                titleColor={COLOR_SHADES.WHITE}
                disableIconAnimation
                imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
              />
            </div>
          </ButtonTile>
        </Grid>
        <Space vertical size={6} />
      </ScrollableContainer>
    </div>
  );
}
