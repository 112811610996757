import { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Dialog,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
} from '@premagic/myne';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  projectId: string;
  isLoading: boolean;
  errors?: FormErrorType;
  createFolder: (projectId: string, FormResponseType) => void;
  hasActiveSubscription: boolean;
};

export default function AddSelectionAlbumButton(props: Props): JSX.Element | null {
  const { projectId, isLoading, errors, createFolder, hasActiveSubscription } = props;
  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);
  return (
    <>
      <Button
        active={showDialog}
        style={BUTTON_STYLES.LINK}
        size={BUTTON_SIZE.SM}
        ref={target}
        onClick={() => setShowDialog(!showDialog)}
      >
        <Icon name="plus" size={ICON_SIZES.SM} /> folder
      </Button>
      <Dialog target={target.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Form onSubmit={(response) => createFolder(projectId, response)} errors={errors}>
          <FormGroup>
            <FormLabel>Folder name</FormLabel>
            <Input name="folder_name" required autoFocus placeholder="eg. The wedding day" />
          </FormGroup>
          <FormFooter>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!hasActiveSubscription}
              disabledMessage={MESSAGES.SUBSCRIPTIONS.INACTIVE}
            >
              Add
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
