import { connect } from 'react-redux';
import { LOADINGS, MODALS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { toggleModalVisibility, setModalOptions } from '../../../../../../common/ModalDuck';
import FolderCoverPhotoSetFocusButton from './FolderCoverPhotoSetFocusButton';

function mapStateToProps(state, ownProps: { folderId: string }) {
  const { folderId } = ownProps;

  return {
    isFocalPointUpdating: isLoadingSelector(LOADINGS.FOLDER.UPDATE_COVER_PHOTO_FOCAL_POINT(folderId))(state),
    folderId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openFolderCoverFocalPointModal: (folderId: string) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.FOLDER_COVER_FOCAL_POINT_MODAL, true));
      dispatch(setModalOptions(dispatch, MODALS.FOLDER_COVER_FOCAL_POINT_MODAL, { folderId }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderCoverPhotoSetFocusButton);
