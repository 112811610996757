import { EventsService, FolderService, IntegrationService, ProjectMetaService, Schemas } from '@premagic/core';
import { ActionTypeUtils, BrowserUtils, ErrorTracker, i18n } from '@premagic/utils';
import { List as IList, Map as IMap } from 'immutable';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS, MODALS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { setModalOptions, toggleModalVisibility } from '../../../../common/ModalDuck';
import { entitiesDataSelector } from '../../reducers/selectors';
import { toastMessage } from '../../reducers/ToastStore';
import { addEventWithClientData } from '../crm/events/details/CreateEventPageDuck';
import { getEventAttendeesForProject } from '../crm/events/event-attendees/EventAttendeesDataDuck';
import { UserType } from '../crm/users/UsersService';
import { addNewFolderToProjectsData } from '../projects/AccountProjectsDataDuck';
import { addFolderData, updateFolderData } from '../projects/folders/AccountFoldersDataDuck';
import { saveProjectMetaData, uploadProjectCoverPhoto } from '../projects/project-meta/ProjectsMetaDataDuck';
import { getDataToCreatePremagicEvent } from './IntegrationDataService';

const getActionType = ActionTypeUtils.getActionTypeFunction('INTEGRATION');

const setEvents = createAction(getActionType('EVENTS_DATA', 'SET'), (dispatch, data) => data);

const setEventsCustomFields = createAction(getActionType('EVENTS_CUSTOM_FIELDS', 'SET'), (dispatch, data) => data);

export const setIntegrationSelectedEvent = createAction(
  getActionType('SELECTED_EVENT', 'SET'),
  (dispatch, data) => data,
);

export const getAllEventsForPlatform = createAction(
  getActionType('EVENTS_DATA', 'FETCH_EVENTS'),
  (dispatch, platform: IntegrationService.INTEGRATION_PLATFORMS) => {
    const loadingKey = LOADINGS.INTEGRATION.FETCH_EVENTS;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    IntegrationService.fetchAllEventsForPlatform(platform)
      .then((response) => {
        if (!isEmpty(response)) {
          const normalizedData = normalize(response, Schemas.IntegrationEvents);
          dispatch(setEvents(dispatch, normalizedData.entities.integrationEvent));
        } else {
          dispatch(setEvents(dispatch, {}));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e.message));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('INTEGRATION_EVENTS_FETCH_ERROR', e);
      });
  },
);

export const getCustomFieldsForEvent = createAction(
  getActionType('EVENTS_CUSTOM_FIELDS', 'FETCH'),
  (dispatch, platform: IntegrationService.INTEGRATION_PLATFORMS, eventId: string) => {
    const loadingKey = LOADINGS.INTEGRATION.FETCH_EVENT_CUSTOM_FIELDS;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    IntegrationService.fetchEventCustomFieldsForPlatform(platform, eventId)
      .then((response) => {
        dispatch(setEventsCustomFields(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('INTEGRATION_EVENTS_CUSTOM_FIELDS_FETCH_ERROR', e);
      });
  },
);

export const updateIntegrationEventFields = createAction(
  getActionType('INTEGRATION_EVENT', 'UPDATE_FIELDS'),
  async (
    dispatch,
    options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      premagicProjectId: string;
      integrationEventData: IntegrationService.IntegrationPlatformEventType;
    },
  ) => {
    const loadingKey = LOADINGS.INTEGRATION.UPDATE_FIELDS;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { platform, premagicProjectId, integrationEventData } = options;

    const { language, cover_image_url: coverImageUrl } = integrationEventData;

    try {
      // Set cover photo of the event
      if (coverImageUrl) {
        const coverPhotoBlob = await BrowserUtils.createBlobFromUrl(coverImageUrl);
        const coverPhotoJpgFile =
          coverPhotoBlob &&
          BrowserUtils.getFileFromBlob(
            coverPhotoBlob,
            BrowserUtils.getFileNameFromUrl(coverImageUrl) || `${premagicProjectId}_cover.jpg`,
            'image/jpeg',
          );

        if (coverPhotoJpgFile) {
          dispatch(uploadProjectCoverPhoto(dispatch, premagicProjectId, coverPhotoJpgFile, true));
        }
      }

      // this find logic is for swapcard integration, have to modify this incase we add more platform in future and the format is not same.
      const languageCode =
        Object.values(i18n.LANGUAGES_DATA).find(
          (data) => data.langCode.toLowerCase() === language?.split('_')[0].toLowerCase(),
        )?.langCode || i18n.LANGUAGE_CODE.EN;

      //  Set the focal point and project language
      dispatch(
        saveProjectMetaData(
          dispatch,
          premagicProjectId,
          {
            data: {
              [ProjectMetaService.PROJECT_META_TYPES.COVER_IMAGE_FOCAL_POINT]: { x: 50, y: 50 },
              [ProjectMetaService.PROJECT_META_TYPES.DEFAULT_LANG]: languageCode as i18n.LANGUAGE_CODE,
            },
          },
          {},
        ),
      );
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('INTEGRATION_EVENTS_UPDATE_FIELDS_FAILED', e);
    }
  },
);

export const syncIntegrationEvent = createAction(
  getActionType('INTEGRATION_EVENT', 'SYNC'),
  async (
    dispatch,
    options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      premagicProjectId: string;
      integrationEventId: string;
      integrationEventData?: IntegrationService.IntegrationPlatformEventType;
      premagicEventData?: EventsService.EventDataType;
    },
  ) => {
    const loadingKey = LOADINGS.INTEGRATION.SYNC_EVENT;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { platform, premagicProjectId, integrationEventData, premagicEventData, integrationEventId } = options;

    try {
      await IntegrationService.syncConnectedEventForPlatform(premagicProjectId);

      let finalIntegrationEventData;

      // if integration data is not available in redux then fetch all the events from swapcard and select the required event
      // Later on Alen will give us an API that can fetch only a specific integration event details
      if (!integrationEventData) {
        const response = await IntegrationService.fetchAllEventsForPlatform(platform);
        if (!isEmpty(response)) {
          const normalizedData = normalize(response, Schemas.IntegrationEvents);
          finalIntegrationEventData = normalizedData.entities.integrationEvent?.[integrationEventId];
        }
      } else {
        finalIntegrationEventData = integrationEventData;
      }

      if (!finalIntegrationEventData) {
        throw new Error('Event list is empty');
      }

      const { data } = getDataToCreatePremagicEvent(finalIntegrationEventData);
      const { name, status, ...eventDataToUpdate } = data.event;

      const eventDataToStoreInProjectMeta = {
        ...premagicEventData, // this will be existing PM event data
        ...eventDataToUpdate,
        location_map_link: `https://www.google.co.in/maps/place/${eventDataToUpdate.location || 'San Francisco'}/`,
      };

      // Save events data to project meta
      dispatch(
        saveProjectMetaData(
          dispatch,
          premagicProjectId,
          {
            data: {
              [ProjectMetaService.PROJECT_META_TYPES.EVENT_DATA]: eventDataToStoreInProjectMeta,
            },
          },
          {},
        ),
      );

      // Update integration fields like cover, language etc
      dispatch(
        updateIntegrationEventFields(dispatch, {
          platform,
          premagicProjectId,
          integrationEventData: finalIntegrationEventData,
        }),
      );

      // fetch the attendees (even though we are fetching, the list is not immediately available after sync)
      dispatch(getEventAttendeesForProject(dispatch, premagicProjectId));

      toastMessage('success', 'Successfully imported the data.', 2000);
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      toastMessage('error', 'Failed to import the data.', 2000);
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('INTEGRATION_EVENTS_SYNC_FAILED', e);
    }
  },
);

export const connectAndSyncEvent = createAction(
  getActionType('INTEGRATION_EVENT', 'CONNECT_AND_SYNC'),
  async (
    dispatch,
    options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      integrationEventData: IntegrationService.IntegrationPlatformEventType;
      fieldsToSync: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
      premagicProjectId: string;
      hostUser?: UserType;
      numOfSharedFolders?: number;
      syncEvent?: boolean;
      premagicEventData?: EventsService.EventDataType; // This field is required if syncEvent is enabled
    },
  ) => {
    const {
      integrationEventData,
      platform,
      fieldsToSync,
      premagicProjectId,
      premagicEventData,
      numOfSharedFolders,
      hostUser,
      syncEvent,
    } = options;

    const { id: integrationEventId } = integrationEventData;

    const { email: hostEmail, name: hostName, phone_number: hostPhoneNumber } = hostUser || {};

    const loadingKey = LOADINGS.INTEGRATION.CONNECT_AND_SYNC_EVENT;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    try {
      // Create a dummy folder and publish it when creating a new project (necessary for connect API to work)
      if (!numOfSharedFolders) {
        const newFolder = await FolderService.createFolder(premagicProjectId, {
          folder_name: 'Highlights',
          folder_type: FolderService.FOLDER_TYPES.HIGHLIGHT,
        });

        const folderId = newFolder.folder_id;
        const normalizedData = normalize(newFolder, Schemas.FolderSchema);
        const { folders } = normalizedData.entities || {};
        dispatch(addFolderData(dispatch, folders));
        dispatch(addNewFolderToProjectsData(dispatch, premagicProjectId, folderId));

        const shareInfo = await FolderService.shareFolderWithClientService(premagicProjectId, [folderId], {
          clients: [
            {
              name: hostName || '',
              email: hostEmail || '',
              phone_number: hostPhoneNumber || '',
            },
          ],
          skip_notifications: true,
        });
        const tempFolderStatus = shareInfo.folder_status;
        dispatch(
          updateFolderData(dispatch, folderId, {
            share_url: tempFolderStatus[folderId].share_url,
            status: tempFolderStatus[folderId].status,
            is_shared: true,
          }),
        );
      }

      // Connect the Premagic Event to Integration event
      await IntegrationService.connectEventForPlatform(platform, premagicProjectId, integrationEventId, fieldsToSync);

      // Sync the event (update PM event data with integration event data)
      if (syncEvent) {
        dispatch(
          syncIntegrationEvent(dispatch, {
            platform,
            premagicProjectId,
            integrationEventId,
            integrationEventData,
            premagicEventData,
          }),
        );
      }

      // close modal
      dispatch(toggleModalVisibility(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, false));
      dispatch(setModalOptions(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, {}));

      toastMessage('success', 'Successfully imported the data.', 2000);
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      toastMessage('error', 'Failed to import the data.', 2000);
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('INTEGRATION_CONNECT_EVENT_ERROR', e);
    }
  },
);

export const createPremagicEventAndConnectIntegrationEvent = createAction(
  getActionType('INTEGRATION_EVENT', 'CREATE_AND_CONNECT_EVENT'),
  (
    dispatch,
    options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      integrationEventData: IntegrationService.IntegrationPlatformEventType;
      fieldsToSync: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
      hostUser?: UserType;
      isCompanyTypeNotPhotographer?: boolean;
    },
  ) => {
    const { integrationEventData, platform, fieldsToSync, hostUser, isCompanyTypeNotPhotographer } = options;

    const dataToCreatePMEvent = getDataToCreatePremagicEvent(integrationEventData, hostUser);

    function onEventCreationSuccess(eventOptions: { eventId: string; projectId: string }) {
      const { eventId, projectId } = eventOptions;

      //  Connect the new event to integration event. (Don't sync because the event creation action itself will handle it)
      dispatch(
        connectAndSyncEvent(dispatch, {
          platform,
          integrationEventData,
          fieldsToSync,
          hostUser,
          premagicProjectId: projectId,
          syncEvent: false, // Dont sync
        }),
      );

      // Update integration fields like cover, language etc
      dispatch(
        updateIntegrationEventFields(dispatch, {
          platform,
          premagicProjectId: projectId,
          integrationEventData,
        }),
      );
    }

    dispatch(
      // since addEventWithClientData action does a lot of things, hence using a callback based approach on event creation success instead of using promise
      addEventWithClientData(dispatch, dataToCreatePMEvent, {
        onEventCreationSuccess,
        isCompanyTypeNotPhotographer,
      }),
    );
  },
);

type IntegrationPlatformEventsType = IMap<string, IntegrationService.IntegrationPlatformEventType>;
type IntegrationEventCustomFieldsType = IList<IntegrationService.IntegrationPlatformEventCustomFieldType>;

type StateType = {
  events: IntegrationPlatformEventsType;
  custom_fields: IntegrationEventCustomFieldsType;
  selected_event: string;
};

const initialState = {
  events: IMap({}),
  custom_fields: IList(),
  selected_event: undefined,
};

export default handleActions(
  {
    [setEvents.toString()]: (state, action) => ({
      ...state,
      events: IMap(action.payload),
    }),
    [setEventsCustomFields.toString()]: (state, action: { payload }) => ({
      ...state,
      custom_fields: IList(action.payload),
    }),
    [setIntegrationSelectedEvent.toString()]: (state, action: { payload }) => ({
      ...state,
      selected_event: action.payload,
    }),
  },
  initialState,
);

const integrationEntitiesSelector = createSelector(entitiesDataSelector, (state) => state.integration as StateType);

export const integrationEventsSelector = createSelector(integrationEntitiesSelector, (data) => data.events);
export const integrationEventsDataSelector = createSelector(integrationEventsSelector, (data) => data.toJSON());

export const integrationSortedEventsSelector = createSelector(integrationEventsSelector, (data) =>
  data.sort((a, b) => b.created_at - a.created_at),
);

export const integrationSortedEventsDataSelector = createSelector(integrationSortedEventsSelector, (data) =>
  data.toJSON(),
);

export const integrationEventSelectedEventIdSelector = createSelector(
  integrationEntitiesSelector,
  (data) => data.selected_event,
);

export const integrationEventCustomFieldsDataSelector = createSelector(integrationEntitiesSelector, (data) =>
  data.custom_fields.toArray(),
);

export const integrationEventItemSelector = createSelector(
  integrationEventsSelector,
  integrationEventSelectedEventIdSelector,
  (items, integrationEventId) => items.get(integrationEventId),
);
