import React, { useMemo } from 'react';
import { EventCustomFromFieldsService } from '@premagic/core';
import { FormGroup, FormLabel, INPUT_SIZES } from '@premagic/myne';
import { getRegistrationComponentForInputType } from './RegistrationInputTypes';

type FormFieldInputProps = {
  formField: EventCustomFromFieldsService.FormFieldType;
  defaultValue?: string;
};

function FormFieldInput(props: FormFieldInputProps): JSX.Element | null {
  const { formField, defaultValue } = props;
  const { name, is_required: required, type } = formField;
  const InputComponent = getRegistrationComponentForInputType(type);

  const options = useMemo(
    () =>
      formField?.select_options?.map((option) => ({
        label: option,
        value: option,
      })),
    [formField.select_options],
  );
  if (
    [
      EventCustomFromFieldsService.FORM_FIELD_TYPE.MULTI_SELECT,
      EventCustomFromFieldsService.FORM_FIELD_TYPE.SINGLE_SELECT,
    ].includes(type)
  ) {
    return (
      <InputComponent
        name={name}
        options={options}
        size={INPUT_SIZES.MD}
        required={required}
        defaultValue={defaultValue}
      />
    );
  }

  return <InputComponent name={name} type={type} required={required} defaultValue={defaultValue} />;
}

type Props = {
  formFields: Record<string, EventCustomFromFieldsService.FormFieldType>;
  formFieldIds: Array<string>;
  defaultValue?: Record<string, string>;
};

export function UserRegistrationUserFacingFormFields(props: Props): JSX.Element | null {
  const { formFields, formFieldIds, defaultValue = {} } = props;
  return (
    <div>
      {formFieldIds.map((id) => {
        const formField = formFields[id];
        const { name, label, is_required: required, type } = formField;
        const defaultValueForInput = defaultValue[id];

        return (
          <FormGroup key={name}>
            <FormLabel isRequired={required}>{label}</FormLabel>
            <FormFieldInput formField={formField} defaultValue={defaultValueForInput} />
          </FormGroup>
        );
      })}
    </div>
  );
}
