import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import {
  ClientWebsiteService,
  ProjectService,
  Schemas,
  EventTrackerService,
  EventsService,
  FolderMetaService,
  EventsCategoryService,
  ProjectMetaService,
} from '@premagic/core';
import { FormErrorType } from '@premagic/myne';
import { ActionTypeUtils, i18n } from '@premagic/utils';

import { pagesSelector } from '../../../../reducers/selectors';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';

import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { fetchProjectForEvent, setProjectsData } from '../../../projects/AccountProjectsDataDuck';
import { addClientWebsiteData } from '../../../client-website/ClientWebsiteDataDuck';
import { shareFolderWithClient } from '../../../folder-share/FolderShareDuck';
import { updateEventStage } from '../EventsDataDuck';
import APP_URLS, { getRouteUrlFor } from '../../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../../services/RouterService';
import { saveFolderMetaData } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { getDefaultDataForInvite } from '../CreateEventService';
import { saveProjectMetaData } from '../../../projects/project-meta/ProjectsMetaDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_DETAILS', true);

export const setEditOfOccasion = createAction(
  getActionType('OCCASION', 'EDIT'),
  (dispatch: Dispatch, occasionId: string | null) => occasionId,
);

export const setEditOfPayment = createAction(
  getActionType('PAYMENT', 'EDIT'),
  (dispatch: Dispatch, paymentId: string | null) => paymentId,
);

export const setDeleteOfPayment = createAction(
  getActionType('PAYMENT', 'DELETE'),
  (dispatch: Dispatch, paymentId: string) => paymentId,
);

export const setEditOfClientWebsite = createAction(
  getActionType('CLIENT_WEBSITE', 'EDIT'),
  (dispatch: Dispatch, paymentId: string) => paymentId,
);

type ConvertEventToProjectFormData = {
  eventId: string;
  eventName: string;
  clientId: string;
  projectName: string;
};

export const convertEventToProject = createAction(
  getActionType('CONVERT', 'EVENT_PROJECT'),
  async (dispatch: Dispatch, data: ConvertEventToProjectFormData) => {
    const loadingKey = LOADINGS.EVENT.CONVERT_TO_PROJECT;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.CONVERT_TO_PROJECT, data);

    const { projectName, eventId, eventName, clientId } = data;
    try {
      /* 1. Create Project (with eventId and eventName(this is used as a variant for DWI))
         2. Update the store, by fetching projects
         3. Update Event status to CONFIRMED
         4. Highlight deliverable tab
         5. Show Create basic invite
      */

      // 1. Create Project
      const project = await ProjectService.createProject({
        project_name: projectName,
        event_id: eventId,
        event_name: eventName,
      });
      const normalizedData = normalize(project, Schemas.ProjectSchema);
      dispatch(setProjectsData(dispatch, normalizedData.entities.projects));
      // 2. Update the store, by fetching projects
      dispatch(fetchProjectForEvent(dispatch, eventId));
      // 3. Update Event status to CONFIRMED
      dispatch(updateEventStage(dispatch, eventId, clientId, EventsService.EVENT_STATUS.CONFIRMED));

      // 4. Highlight deliverable tab
      const deliverableTabWithFocus = getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, { eventId });
      navigateTo(dispatch, deliverableTabWithFocus);

      // 5. Show Create basic invite
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT.CREATE_BASIC_INVITE, true));

      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(toggleWindowPanelVisibility(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    }
  },
);
type CreateBasicInviteFormData = {
  errors: FormErrorType;
  data: {
    eventName: EventsService.EVENT_CATEGORY | string;
    eventId: string;
    projectId: string;
    bride_name: string;
    groom_name: string;
    location: string;
    schedule: string;
    schedule_timezone: string;
    share_with_client: boolean;
    email?: string;
    clientPhone?: string;
    event: EventsService.EventDataType;
  };
};

export const createABasicInviteAndShare = createAction(
  getActionType('INVITE', 'CREATE_AND_SHARE'),
  async (
    dispatch: Dispatch,
    formResponse: CreateBasicInviteFormData & { data: { otherClients: any } },
    langCode: i18n.LANGUAGE_CODE,
  ) => {
    const loadingKey = LOADINGS.EVENT.CREATE_BASIC_INVITE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(
      EventTrackerService.TRACK_EVENTS.CLIENT_WEBSITE.CREATE_BASIC_INVITE,
      formResponse.data,
    );

    const { errors, data } = formResponse;
    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }
    const {
      eventId,
      projectId,
      groom_name: groomName,
      share_with_client: shareWithClient,
      email,
      clientPhone,
      otherClients,
      event: eventData,
    } = data;

    const eventDataToStoreInProjectMeta = {
      ...eventData,
      location_map_link: `https://www.google.co.in/maps/place/${eventData.location || 'San Francisco'}/`,
    };
    try {
      /*
         1. Create an Invite
          1.1. Get default data for invite depending on event type
          1.2. Disabled wish if event type is corporate
         2. Share the website with client
         3. Update the store, by fetching projects
         4. Highlight the created item
      */

      // 1. Create an Invite
      const websiteToCreate = getDefaultDataForInvite({ ...formResponse.data });
      const website = await ClientWebsiteService.createClientWebsite(projectId, websiteToCreate);
      // 1.2. Disabled wish if event type is corporate
      if (!EventsCategoryService.isWeddingEvent(data.eventName)) {
        dispatch(
          saveFolderMetaData(dispatch, projectId, website.id, {
            data: {
              [FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES]: true,
            },
          }),
        );
      }

      // 1.3 Save events data to project meta
      dispatch(
        saveProjectMetaData(
          dispatch,
          projectId,
          {
            data: {
              [ProjectMetaService.PROJECT_META_TYPES.EVENT_DATA]: eventDataToStoreInProjectMeta,
            },
          },
          {},
        ),
      );

      dispatch(addClientWebsiteData(dispatch, website));
      // 2. Share the website with client
      if (shareWithClient && email && clientPhone) {
        const clientInformation = {
          data: {
            clients: [
              {
                name: groomName,
                email,
                phone_number: clientPhone,
              },
              ...otherClients,
            ],
          },
        };
        await dispatch(shareFolderWithClient(dispatch, website.id, projectId, clientInformation));
      }
      // 4. Update the store, by fetching projects
      dispatch(fetchProjectForEvent(dispatch, eventId));

      // 5. Highlight the created item
      const deliverableTabWithFocus = getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, { eventId, focusId: website.id });
      navigateTo(dispatch, deliverableTabWithFocus);

      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(toggleWindowPanelVisibility(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    }
  },
);

type EventDetailsPageType = {
  editOccasionId: string | null;
  editPaymentId: string | null;
  deletePaymentId: string | null;
  editClientWebsiteId: string | null;
};
const initialState = {
  editOccasionId: null,
  editPaymentId: null,
  deletePaymentId: null,
  editClientWebsiteId: null,
};

export default handleActions(
  {
    [setEditOfOccasion.toString()]: (state, action) => ({
      ...state,
      editOccasionId: action.payload as any,
    }),
    [setEditOfPayment.toString()]: (state, action) => ({
      ...state,
      editPaymentId: action.payload as any,
    }),
    [setDeleteOfPayment.toString()]: (state, action) => ({
      ...state,
      deletePaymentId: action.payload as any,
    }),
    [setEditOfClientWebsite.toString()]: (state, action) => ({
      ...state,
      editClientWebsiteId: action.payload as any,
    }),
  },
  initialState,
);

export const eventDetailsPageSelector = createSelector(
  pagesSelector,
  (state) => state.eventDetails as EventDetailsPageType,
);
export const occasionIdForEditSelector = createSelector(eventDetailsPageSelector, (page) => page.editOccasionId);
export const paymentIdForEditSelector = createSelector(
  eventDetailsPageSelector,
  (page) => page.editPaymentId as string,
);
export const paymentIdForDeleteSelector = createSelector(
  eventDetailsPageSelector,
  (page) => page.deletePaymentId as string,
);
export const clientWesbiteIdForEditSelector = createSelector(
  eventDetailsPageSelector,
  (page) => page.editClientWebsiteId as string,
);
