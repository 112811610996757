import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { normalize } from 'normalizr';
import { FolderService, ProjectService } from '@premagic/core';
import { ActionTypeUtils } from '@premagic/utils';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { FoldersSchema } from '../../../../schema/Schemas';
import { pagesSelector } from '../../../reducers/selectors';
import { setFolderAndImagesData } from './ProjectsOverviewDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_OVERVIEW', true);

export const setProjectName = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchProjectPhotos = createAction(
  getActionType('DATA', 'FETCH'),
  (
    dispatch,
    projectId: string,
    folderType: FolderService.FOLDER_TYPES,
    imageStatus: 'approved' | 'all' | 'rejected' | 'favorite',
  ) => {
    const loadingKey = LOADINGS.PROJECTS.OVERVIEW_FETCH_ALL;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProjectService.fetchProjectImages(projectId, folderType, imageStatus)
      .then((response) => {
        const normalizedData = normalize(response.data, FoldersSchema);

        dispatch(setProjectName(dispatch, response.projectName));
        dispatch(
          setFolderAndImagesData(dispatch, {
            folders: normalizedData.entities.folders,
            images: normalizedData.entities.images,
          }),
        );
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

const initialState = {
  projectName: null,
};

export default handleActions(
  {
    [setProjectName.toString()]: (state, action: { payload }) => ({
      ...state,
      projectName: action.payload,
    }),
  },
  initialState,
);

export const projectOverviewPageSelector = createSelector(pagesSelector, (state: any) => state.projectOverview.page);
