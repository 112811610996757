import { connect } from 'react-redux';
import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import ClientWebsitesSection from './ClientWebsitesSection';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { projectId: string; focusId?: string; eventId?: string }) {
  const { projectId, focusId, eventId } = ownProps;

  const hasClientWebsiteFeature = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE)(
    state,
  );
  return {
    projectId,
    eventId,
    clientWebsiteIds:
      folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.CLIENT_WEBSITE)(state) || [],
    focusId,
    hasClientWebsiteFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showCreateBasicInvite: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT.CREATE_BASIC_INVITE, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsitesSection);
