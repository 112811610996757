import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { hasFeatureSelector } from '../../../../settings/company/CompanyDataDuck';
import { eventExhibitorsSelector } from '../EventExhibitorDataDuck';
import EventExhibitorOptionsMenu from './EventExhibitorOptionsMenu';
import { setEditOfExhibitor } from '../EventExhibitorPageDuck';

function mapStateToProps(state, ownProps: { exhibitorId: string; projectId: string }) {
  const { exhibitorId, projectId } = ownProps;

  return {
    projectId,
    exhibitorId,
    exhibitor: eventExhibitorsSelector(state)[exhibitorId],
    hasFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_EXHIBITOR)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: (id: string) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_DELETE_DIALOG(id), true));
    },
    showEditExhibitor: (id: string) => {
      dispatch(setEditOfExhibitor(dispatch, id));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventExhibitorOptionsMenu);
