export const ALBUM_CREATOR_URLS = {
  ALBUM: {
    VIEW: '/albums/:albumId/:view(edit|preview)/page/:pageNo?',
    EDIT: '/albums/:albumId/edit/page/:pageNo?',
    PREVIEW: '/albums/:albumId/preview/:pageNo?',
  },
  CRM: {
    EVENT__DELIVERABLE: '/crm/events/:eventId/v/deliverable/:focusId?',
  },
};
