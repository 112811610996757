import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS, MODALS } from '../../../../../../../common/Constants';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import {
  editQRCodeDesigns,
  qrCodeDesignsBackgroundPosterDataSelector,
  qrCodeDesignsSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { clientWebsitesSelector } from '../../../ClientWebsiteDataDuck';
import QRCodeBackgroundPosterSelectorModal from './QRCodeBackgroundPosterSelectorModal';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;

  const clientWebsite = clientWebsitesSelector(state)[folderId];
  const defaultPosterColorsFromInvite = {
    [QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_1]: clientWebsite?.theme?.color?.primary_color,
    [QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_2]: clientWebsite?.theme?.color?.secondary_color,
  };

  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);

  return {
    projectId,
    folderId,
    backgroundPoster: qrCodeDesignsBackgroundPosterDataSelector(folderId)(state),
    colors: qrCodeDesigns?.colors || defaultPosterColorsFromInvite,
    qrCodeDesigns,
    show: isModalOpenSelector(LOADINGS.QR_CODE.SELECT_POSTER_MODAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_POSTER_MODAL, false));
    },
    updatePoster: (projectId, folderId, qrCodeDesigns, posterItem) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.BACKGROUND_POSTER]: posterItem,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeBackgroundPosterSelectorModal);
