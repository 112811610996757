import { FileService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Dropdown,
  DropdownButton,
  FileUploader,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  Menu,
  MenuItem,
  MenuItemIcon,
  Position,
  Row,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { MediaUtils } from '@premagic/utils';
import React from 'react';
import { toastMessage } from '../../../../reducers/ToastStore';

type FileSelectorProps = {
  onError: (message) => void;
  onChange: (e, files) => void;
  children: React.ReactNode;
};

function FileSelector(props: FileSelectorProps) {
  const { onError, onChange, children } = props;
  return (
    <FileUploader maxSize={35} accept="image/jpeg, image/png" onError={onError} onChange={onChange}>
      {children}
    </FileUploader>
  );
}

type Props = {
  projectId: string;
  isLoading?: boolean;
  uploadCoverPhoto: (
    projectId: string,
    coverPhoto: File,
    cordiantes: {
      x: number;
      y: number;
    },
  ) => void;
  coverPhoto?: FileService.FolderFileType | null;
  removeCoverPhoto: (projectId: string) => void;
  isSaving?: boolean;
};

export default function ProjectCoverPhoto(props: Props) {
  const { uploadCoverPhoto, projectId, isLoading, coverPhoto, removeCoverPhoto, isSaving } = props;

  const defaultFocalPoint = {
    x: 50,
    y: 50,
  };

  if (!coverPhoto?.dynamic_image_url) {
    return (
      <Space>
        <Row center>
          <Color shade={COLOR_SHADES.LIGHT}>
            <Icon name="image" size={ICON_SIZES.LG} />
          </Color>
        </Row>
        <Space vertical size={2} />

        <Row center>
          <FileSelector
            onError={(message) => toastMessage('error', message)}
            onChange={(e, files) => {
              if (files?.length) {
                uploadCoverPhoto(projectId, files[0], defaultFocalPoint);
              }
              return {};
            }}
          >
            <Button isLoading={isLoading} style={BUTTON_STYLES.TERTIARY} size={BUTTON_SIZE.MD}>
              Choose Banner
            </Button>
          </FileSelector>
        </Row>
        <Space vertical size={2} />
        <Text size={TEXT_SIZE.SIZE_5} muted block center>
          1,584 pixels × 396 pixels
        </Text>
      </Space>
    );
  }

  return (
    <div style={{ height: 200, position: 'relative' }}>
      <ImageV2
        useNewImageService
        src={coverPhoto?.dynamic_image_url}
        style={IMAGE_V2_STYLES.FULL_IMAGE}
        alt="project-cover-photo"
        width={280}
        imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
      />
      <Position align="bottom-right">
        <Space size={2}>
          <Dropdown dropDirection="up">
            <DropdownButton style={BUTTON_STYLES.TERTIARY} size={BUTTON_SIZE.SM} isLoading={isSaving}>
              More option
            </DropdownButton>

            <Menu>
              <FileSelector
                onError={(message) => toastMessage('error', message)}
                onChange={(e, files) => {
                  if (files?.length) {
                    uploadCoverPhoto(projectId, files[0], defaultFocalPoint);
                  }
                  return {};
                }}
              >
                <MenuItem closeOnClick={false}>
                  <MenuItemIcon name="edit" />
                  Change photo
                </MenuItem>
              </FileSelector>
              <MenuItem onClick={() => removeCoverPhoto(projectId)}>
                <MenuItemIcon name="x_circle" />
                Remove photo
              </MenuItem>
            </Menu>
          </Dropdown>
        </Space>
      </Position>
    </div>
  );
}
