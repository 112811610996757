import { NotionUtils } from '@premagic/utils';
import { connect } from 'react-redux';

import FeedsPage from './FeedsPage';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps() {
  return {
    initData: () => {
      console.log(NotionUtils.getItemsInDB('63df5a242b6d49d9aef234db618ab609'));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedsPage);
