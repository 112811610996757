import { CompanyService } from '@premagic/core';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Form,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils, SocialNetworksUtils } from '@premagic/utils';
import React, { useRef, useState } from 'react';

function OtherSocialLinks(props: { social: CompanyService.CompanySocialMediaType }) {
  const { social } = props;

  const [secondarySocialLinksAdded, setSecondarySocialLinksAdded] = useState<Array<Array<string>>>(
    Object.entries(social).filter(([key]) => !(key in SocialNetworksUtils.SOCIAL_MEDIAS_DETAILS)) || [],
  );
  const [showAddMoreDialog, setShowAddMoreDialog] = useState(false);
  const $button = useRef(null);
  return (
    <div>
      {secondarySocialLinksAdded.map(([key, value]) => (
        <FormGroup key={key}>
          <Row vcenter>
            <Col size={3}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} ellipsis>
                {key}
              </Text>
            </Col>
            <Col size={8}>
              <Input placeholder="eg. https://google.com" name={`social[${key}]`} defaultValue={value} />
            </Col>
            <Col size={null} rightAlighed>
              <ButtonIcon
                title={`Remove ${key} link`}
                onClick={() => {
                  setSecondarySocialLinksAdded(secondarySocialLinksAdded.filter(([itemKey]) => itemKey !== key));
                }}
                size={BUTTON_ICONS_SIZES.SM}
              >
                <Icon name="trash" size={ICON_SIZES.SM} />
              </ButtonIcon>
            </Col>
          </Row>
        </FormGroup>
      ))}
      <Button onClick={() => setShowAddMoreDialog(!showAddMoreDialog)} style={BUTTON_STYLES.LINK} ref={$button}>
        <Icon name="plus" size={ICON_SIZES.SM} />
        Add More
      </Button>
      <Dialog
        target={$button.current}
        show={showAddMoreDialog}
        placement={DIALOG_PLACEMENT.TOP_START}
        onClose={() => setShowAddMoreDialog(false)}
      >
        <Form
          onSubmit={(formResponse) => {
            setSecondarySocialLinksAdded([
              ...secondarySocialLinksAdded,
              [formResponse.data.name as string, BrowserUrlUtils.getURLWithProtocol(formResponse.data.value as string)],
            ]);
            setShowAddMoreDialog(false);
          }}
        >
          <FormGroup>
            <FormLabel>Website</FormLabel>
            <Input name="name" placeholder="eg. Wedding Wire" autoFocus />
          </FormGroup>
          <FormGroup>
            <FormLabel>Link</FormLabel>
            <Input name="value" placeholder="eg. https://weddingwire.com/profile" />
          </FormGroup>
          <DialogFooter>
            <Button style={BUTTON_STYLES.PRIMARY}>Add</Button>
          </DialogFooter>
        </Form>
      </Dialog>
    </div>
  );
}

type Props = {
  social: CompanyService.CompanySocialMediaType;
};

export function CompanySocialLinkForm(props: Props) {
  const { social } = props;

  return (
    <div>
      <FormGroup>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD}>
            www.instagram.com/
          </Text>
          <Space size={1} />
          <Input
            size={INPUT_SIZES.SM}
            placeholder="eg. premagicdotcom"
            name="social[instagram]"
            defaultValue={social.instagram}
          />
        </Row>
      </FormGroup>
      <FormGroup>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD}>
            www.facebook.com/
          </Text>
          <Space size={1} />
          <Input
            size={INPUT_SIZES.SM}
            placeholder="eg. premagicdotcom"
            name="social[facebook]"
            defaultValue={social.facebook}
          />
        </Row>
      </FormGroup>
      <FormGroup>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD}>
            www.youtube.com/channel/
          </Text>
          <Space size={1} />
          <Input
            size={INPUT_SIZES.SM}
            placeholder="eg. UCOY1TfMBMmmZaWDIQa77fg"
            name="social[youtube]"
            defaultValue={social.youtube}
          />
        </Row>
      </FormGroup>
      <FormGroup>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD}>
            www.pinterest.com/
          </Text>
          <Space size={1} />
          <Input
            size={INPUT_SIZES.SM}
            placeholder="eg. premagicdotcom"
            name="social[pinterest]"
            defaultValue={social.pinterest}
          />
        </Row>
      </FormGroup>
      <FormGroup>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD}>
            www.vimeo.com/
          </Text>
          <Space size={1} />
          <Input
            size={INPUT_SIZES.SM}
            placeholder="eg. premagicdotcom"
            name="social[vimeo]"
            defaultValue={social.vimeo}
          />
        </Row>
      </FormGroup>
      <FormGroup>
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD}>
            www.linkedin.com/
          </Text>
          <Space size={1} />
          <Input
            size={INPUT_SIZES.SM}
            placeholder="eg. premagicdotcom"
            name="social[linkedin]"
            defaultValue={social.linkedin}
          />
        </Row>
      </FormGroup>
      <OtherSocialLinks social={social} />
    </div>
  );
}
