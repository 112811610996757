import { Button, BUTTON_SIZE, BUTTON_STYLES } from '@premagic/myne';
import APP_URLS from '../../services/AppRouteURLService';

export default function UpdateButton(): JSX.Element {
  return (
    <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.DEFAULT} link={APP_URLS.SETTINGS.SUBSCRIPTIONS}>
      Upgrade
    </Button>
  );
}
