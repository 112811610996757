/* eslint-disable @typescript-eslint/naming-convention */
/*
 * Icons are from
 * https://feathericons.com/
 * https://phosphoricons.com/
 * https://lucide.dev/icons/message-circle-code
 * https://tabler-icons.io/
 * https://remixicon.com/
 * https://simpleicons.org/
 * https://thenounproject.com/
 * */

/*
 * 1. Download the icons from above link
 * 2. Optimize the svg using https://jakearchibald.github.io/svgomg/
 *     - make sure you have selected 'Prefer viewBox to width/height'
 * 3. Copy the body of the svg and create a new variable below (order by name)
 * */

import React from 'react';
import styles from './icon.module.css';

const commonProps = {
  viewBox: '0 0 24 24',
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round' as const,
  strokeLinejoin: 'round' as const,
  strokeWidth: '1.2',
  strokeMiterlimit: '10',
};

/* eslint-disable camelcase */
export const ai_premagic = (
  <svg {...commonProps}>
    <path
      d="m6.5 13 .784 1.569c.266.53.399.796.576 1.026a3 3 0 0 0 .545.545c.23.177.495.31 1.026.575L11 17.5l-1.569.785c-.53.265-.796.398-1.026.575a3 3 0 0 0-.545.545c-.177.23-.31.495-.576 1.026L6.5 22l-.784-1.569c-.266-.53-.399-.796-.576-1.026a3 3 0 0 0-.545-.545c-.23-.177-.495-.31-1.026-.575L2 17.5l1.569-.785c.53-.265.796-.398 1.026-.575a3 3 0 0 0 .545-.545c.177-.23.31-.495.576-1.026L6.5 13Z"
      fill="url(#a)"
    />
    <path
      d="m15 2 1.179 3.064c.282.734.423 1.1.642 1.409a3 3 0 0 0 .706.706c.309.22.675.36 1.409.642L22 9l-3.064 1.179c-.734.282-1.1.423-1.409.642a3 3 0 0 0-.706.706c-.22.309-.36.675-.642 1.409L15 16l-1.179-3.064c-.282-.734-.423-1.1-.642-1.409a3 3 0 0 0-.706-.706c-.309-.22-.675-.36-1.409-.642L8 9l3.064-1.179c.734-.282 1.1-.423 1.409-.642a3 3 0 0 0 .706-.706c.22-.309.36-.675.642-1.409L15 2Z"
      fill="url(#b)"
    />
    <path
      d="m6.5 13 .784 1.569c.266.53.399.796.576 1.026a3 3 0 0 0 .545.545c.23.177.495.31 1.026.575L11 17.5l-1.569.785c-.53.265-.796.398-1.026.575a3 3 0 0 0-.545.545c-.177.23-.31.495-.576 1.026L6.5 22l-.784-1.569c-.266-.53-.399-.796-.576-1.026a3 3 0 0 0-.545-.545c-.23-.177-.495-.31-1.026-.575L2 17.5l1.569-.785c.53-.265.796-.398 1.026-.575a3 3 0 0 0 .545-.545c.177-.23.31-.495.576-1.026L6.5 13Z"
      stroke="url(#c)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15 2 1.179 3.064c.282.734.423 1.1.642 1.409a3 3 0 0 0 .706.706c.309.22.675.36 1.409.642L22 9l-3.064 1.179c-.734.282-1.1.423-1.409.642a3 3 0 0 0-.706.706c-.22.309-.36.675-.642 1.409L15 16l-1.179-3.064c-.282-.734-.423-1.1-.642-1.409a3 3 0 0 0-.706-.706c-.309-.22-.675-.36-1.409-.642L8 9l3.064-1.179c.734-.282 1.1-.423 1.409-.642a3 3 0 0 0 .706-.706c.22-.309.36-.675.642-1.409L15 2Z"
      stroke="url(#d)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="a" x1="1" y1="18" x2="22" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7952EE" />
        <stop offset="1" stopColor="#FB6F5F" />
      </linearGradient>
      <linearGradient id="b" x1="1" y1="18" x2="22" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7952EE" />
        <stop offset="1" stopColor="#FB6F5F" />
      </linearGradient>
      <linearGradient id="c" x1="1" y1="18" x2="22" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7952EE" />
        <stop offset="1" stopColor="#FB6F5F" />
      </linearGradient>
      <linearGradient id="d" x1="1" y1="18" x2="22" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7952EE" />
        <stop offset="1" stopColor="#FB6F5F" />
      </linearGradient>
    </defs>
  </svg>
);
export const arrow_up = (
  <svg {...commonProps}>
    <path d="M12 19V5M5 12l7-7 7 7" />
  </svg>
);

export const arrow_down = (
  <svg {...commonProps}>
    <path d="M12 5v14M19 12l-7 7-7-7" />
  </svg>
);
export const arrow_left = (
  <svg {...commonProps}>
    <path d="M19 12H5M12 19l-7-7 7-7" />
  </svg>
);

export const arrow_right = (
  <svg {...commonProps}>
    <path d="M5 12h14M12 5l7 7-7 7" />
  </svg>
);
export const award = (
  <svg {...commonProps}>
    <circle cx="12" cy="8" r="7" className={styles['icon-accent-path--fill']} />
    <path d="M8.21 13.89 7 23l5-3 5 3-1.21-9.12" />
  </svg>
);
export const artboard = (
  <svg {...commonProps}>
    <path d="M0 0h24v24H0z" stroke="none" />
    <path d="M8 9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zM3 8h1M3 16h1M8 3v1M16 3v1M20 8h1M20 16h1M8 20v1M16 20v1" />
  </svg>
);

export const alert_square = (
  <svg {...commonProps} stroke="none">
    <path
      fill="#F2B800"
      d="M17.9 3.5H6.1c-1.4 0-2.6 1.2-2.6 2.6v11.8c0 1.4 1.2 2.6 2.6 2.6h11.8c1.4 0 2.6-1.2 2.6-2.6V6.1c0-1.5-1.1-2.6-2.6-2.6Z"
    />
    <path stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" d="M12 8v5.5M12 15.8v.2" />
  </svg>
);

export const align_left = (
  <svg {...commonProps}>
    <path d="M17 10H3M21 6H3M21 14H3M17 18H3" />
  </svg>
);
export const align_center = (
  <svg {...commonProps}>
    <path d="M18 10H6M21 6H3M21 14H3M18 18H6" />
  </svg>
);
export const align_right = (
  <svg {...commonProps}>
    <path d="M21 10H7M21 6H3M21 14H3M21 18H7" />
  </svg>
);
export const align_top_simple = (
  <svg {...commonProps}>
    <path d="M5.25 3h13.5M15 6H9a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75V6.75A.75.75 0 0 0 15 6Z" />
  </svg>
);
export const align_center_horizontal_simple = (
  <svg {...commonProps}>
    <path d="M19.5 8.25h-15a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75ZM12 4.5v3.75M12 15.75v3.75" />
  </svg>
);
export const align_bottom_simple = (
  <svg {...commonProps}>
    <path d="M5.25 21.75h13.5M15 3H9a.75.75 0 0 0-.75.75V18c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75V3.75A.75.75 0 0 0 15 3Z" />
  </svg>
);
// b
export const baby = (
  <svg {...commonProps}>
    <circle cx="12.3" cy="6.8" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path fill="none" d="M7.7 16.3c.4.5.3 1.3-.3 1.7-.6.4-1.3.3-1.8-.3" />
    <path d="M10.3 14.7l7.7-1.5c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5h-1.5M12.3 17.2v2.2c0 2.1-1.7 3.9-3.9 3.9H4.5c-1 0-1.8-.8-1.8-1.8v-1.2" />
    <path d="M12.3 17.2v2.2c0 2.1 1.3 3.9 3 3.9h6.1v-2.4" />
    <path d="M16.6 5.1V9c0 .6-.5 1.1-1.1 1.1H9.1C8.5 10.1 8 9.6 8 9V5.1C8 4.5 8.5 4 9.1 4h1.2v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1V4h1.2c.6 0 1.1.5 1.1 1.1z" />
    <circle cx="6.1" cy="16.3" r="4.2" />
    <path d="M16.5 20.2v-6.4" />
    <circle cx="4.4" cy="16.4" r=".6" />
    <circle cx="6.6" cy="14.6" r=".6" />
  </svg>
);
export const bell = (
  <svg {...commonProps}>
    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 0 1-3.46 0" />
  </svg>
);
export const bold = (
  <svg {...commonProps}>
    <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z" />
    <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z" />
  </svg>
);
export const blueprint = (
  <svg {...commonProps}>
    <path d="M21.75 5.625H6.375V3.75A.375.375 0 0 0 6 3.375H4.5A2.625 2.625 0 0 0 1.875 6v10.5A2.625 2.625 0 0 0 4.5 19.125h17.25a.375.375 0 0 0 .375-.375V6a.375.375 0 0 0-.375-.375ZM2.625 6A1.875 1.875 0 0 1 4.5 4.125h1.125v9.75H4.5a2.621 2.621 0 0 0-1.875.79V6Zm18.75 12.375H4.5a1.875 1.875 0 1 1 0-3.75H6a.375.375 0 0 0 .375-.375V6.375h15v12ZM9.75 13.125a.375.375 0 1 0 0 .75h1.875V15a.375.375 0 0 0 .75 0v-1.125h3V15a.375.375 0 0 0 .75 0v-1.125H18a.375.375 0 0 0 0-.75h-1.875v-2.25H18a.375.375 0 0 0 0-.75h-1.875V9a.375.375 0 1 0-.75 0v1.125h-3V9a.375.375 0 1 0-.75 0v1.125H9.75a.375.375 0 1 0 0 .75h1.875v2.25H9.75Zm2.625-2.25h3v2.25h-3v-2.25Z" />
  </svg>
);
export const briefcase = (
  <svg {...commonProps}>
    <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
  </svg>
);
// c
export const calendar = (
  <svg {...commonProps}>
    <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
    <path d="M16 2v4M8 2v4M3 10h18" />
  </svg>
);
export const chevron_down = (
  <svg {...commonProps}>
    <path d="M6 9l6 6 6-6" />
  </svg>
);

export const chevron_compact_down = (
  <svg {...commonProps}>
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="m4 11 8 3 8-3" />
  </svg>
);

export const chevron_up = (
  <svg {...commonProps}>
    <path d="M18 15l-6-6-6 6" />
  </svg>
);

export const chevron_compact_up = (
  <svg {...commonProps}>
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="m4 13 8-3 8 3" />
  </svg>
);

export const chevron_up_box = (
  <svg {...commonProps}>
    <rect x="3" y="3" width="18" height="18" rx="2.4" />
    <path d="M16.8 14.4 12 9.6l-4.8 4.8" />
  </svg>
);

export const chevron_left = (
  <svg {...commonProps}>
    <polyline points="15 18 9 12 15 6" className="icon-path-fill" />
  </svg>
);

export const chevron_compact_left = (
  <svg {...commonProps} viewBox="6 4 18 18">
    <path stroke="none" d="M0 0h24v24H0z" />
    <path stroke="currentColor" strokeWidth="2" d="m13 20-3-8 3-8" />
  </svg>
);
export const chevrons_left = (
  <svg {...commonProps}>
    <path d="m11 17-5-5 5-5M18 17l-5-5 5-5" />
  </svg>
);

export const chevron_right = (
  <svg {...commonProps}>
    <polyline points="9 18 15 12 9 6" className="icon-path-fill" />
  </svg>
);

export const chevron_compact_right = (
  <svg {...commonProps} viewBox="0 4 18 18">
    <path stroke="none" d="M0 0h24v24H0z" />
    <path stroke="currentColor" strokeWidth="2" d="m11 4 3 8-3 8" />
  </svg>
);

export const corner_up_left = (
  <svg {...commonProps}>
    <path d="M9 14 4 9l5-5" />
    <path d="M20 20v-7a4 4 0 0 0-4-4H4" />
  </svg>
);

export const chrome = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <circle cx="12" cy="12" r="4" />
    <path d="M21.17 8H12M3.95 6.06 8.54 14M10.88 21.94 15.46 14" />
  </svg>
);

export const camera = (
  <svg {...commonProps}>
    <path d="M23 19a2 2 0 01-2 2H3a2 2 0 01-2-2V8a2 2 0 012-2h4l2-3h6l2 3h4a2 2 0 012 2z" />
    <circle cx="12" cy="13" r="4" />
  </svg>
);

export const command = (
  <svg {...commonProps}>
    <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z" />
  </svg>
);
export const corporate = (
  <svg {...commonProps}>
    <circle cx="17.3" cy="4.9" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path fill="none" stroke="#0081d6" d="M7.4 5h0c2.2 0 3.9 1.8 3.9 3.9v.9" />
    <path d="M3.4 15.7H.9V9.2C.9 8 1.8 6.9 3 6.8c.7 0 1.3.2 1.7.6.4.4.6 1 .6 1.6v1.3l3.4-.8c.8-.2 1.6.5 1.6 1.3v.5L2.9 13" />
    <path d="M4.8 23V12.6l6.5-1.4V23z" />
    <circle cx="3.8" cy="3.2" r="2.1" />
    <circle cx="7.7" cy="5" r="1" fill="#0081d6" />
    <path d="M2.3 23.2l.1-6" />
    <path d="M21.4 3.2V7c0 .6-.5 1.1-1.1 1.1h-6.2c-.6 0-1.1-.5-1.1-1.1V3.2c0-.6.5-1.1 1.1-1.1h1.2v-.2c0-.6.5-1.1 1.1-1.1h1.7c.6 0 1.1.5 1.1 1.1v.3h1.2c.5 0 1 .4 1 1z" />
  </svg>
);

export const cake = (
  <svg {...commonProps}>
    <circle cx="17.7" cy="5" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M7.5 11.6V7.4l3.4-1.1v5.3" />
    <path d="M18 16.4c-.1 1.7-1.4 3-3 3-.8 0-1.6-.3-2.2-.9-.1-.1-.2-.3-.3-.4-.1 1.6-1.4 2.9-3 2.9-.8 0-1.6-.3-2.2-.9-.4-.4-.7-.9-.8-1.6-.6.9-1.6 1.5-2.6 1.5-.8 0-1.6-.3-2.2-.9-.6-.5-.9-1.3-.9-2.2v-.5c.2-1.8 1.7-3.3 3.6-3.3h9.9c1.9.1 3.5 1.5 3.7 3.3z" />
    <path fill="none" d="M18 16.8v6.4H.8v-6.4-.4c.2-1.8 1.7-3.3 3.6-3.3h9.9c1.9 0 3.4 1.4 3.6 3.3v0c.1.2.1.3.1.4z" />
    <path d="M8 3.5C8 2.8 9.3.7 9.3.7s1.3 2.1 1.3 2.8-.7 1.3-1.4 1.3S8 4.2 8 3.5z" />
    <path d="M21.9 3.3v3.9c0 .6-.5 1.1-1.1 1.1h-6.3c-.6 0-1.1-.5-1.1-1.1V3.3c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1v.3h1.2c.5 0 1 .5 1 1.1z" />
  </svg>
);
export const couple_shoot = (
  <svg {...commonProps}>
    <path
      d="M14.9 7c-1.2 0-2.2.8-2.4 1.9-.2-1.1-1.2-1.9-2.4-1.9H10l1 4.4h3l.9-4.4Z"
      className={styles['icon-accent-path']}
    />
    <path d="M12.6 5.6a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4ZM19.9 4.4a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4ZM12.6 8.9C12.9 7.8 13.9 7 15 7l-1 4.4.1.4L17 22.4H8l2.9-10.5.1-.4-.9-4.5h.1c1.2 0 2.2.8 2.4 1.9ZM12.2 2.1h.7" />
    <path d="M19.6 5.6h.7" className={styles['icon-accent-path']} />
    <path d="M18.3 22.3h3.5v-9.1l.7-.6V6.8l-1.4-.4L19.9 9l-1.2-2.6-1.4.4v5.8l.7.6M11.2 11.4h2.7" />
    <path
      d="M4.426 5.894a1.468 1.468 0 1 0 0-2.936 1.468 1.468 0 0 0 0 2.936Z"
      className={styles['icon-accent-path']}
    />
    <path d="M7.933 3.04v3.18c0 .49-.408.898-.897.898H1.897A.905.905 0 0 1 1 6.22V3.04c0-.49.408-.898.897-.898h.98v-.245c0-.49.407-.897.896-.897h1.469c.489 0 .897.408.897.897v.245h.979c.407 0 .815.408.815.897Z" />
  </svg>
);

export const circle = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" className={styles['icon-accent-path']} />
  </svg>
);

// d
export const dollar_sign = (
  <svg {...commonProps}>
    <path d="M12 1v22M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
  </svg>
);
export const droplet = (
  <svg {...commonProps}>
    <path d="M12 2.69l5.66 5.66a8 8 0 11-11.31 0z" />
  </svg>
);
export const drag = (
  <svg {...commonProps}>
    <path d="M9.5 5.154c0 1.19-.895 2.154-2 2.154s-2-.965-2-2.154C5.5 3.964 6.395 3 7.5 3s2 .964 2 2.154Zm-2 9.153c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.153 2 2.153Zm0 7c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.154 2 2.154ZM18.5 5.154c0 1.19-.895 2.154-2 2.154s-2-.965-2-2.154c0-1.19.895-2.154 2-2.154s2 .964 2 2.154Zm-2 9.153c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.153 2 2.153Zm0 7c1.105 0 2-.964 2-2.153 0-1.19-.895-2.154-2-2.154s-2 .964-2 2.154.895 2.154 2 2.154Z" />
  </svg>
);

// e
export const event = (
  <svg {...commonProps}>
    <circle cx="18.1" cy="5.5" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M2.2 15.8L11 7l8.6 8.7z" />
    <path d="M11 7.1l3.3 8.5M7.6 15.6L11 7.1M8 15.8c0 1.6-1.3 2.9-2.9 2.9s-2.9-1.3-2.9-2.9M13.8 15.8c0 1.6-1.3 2.9-2.9 2.9S8 17.4 8 15.8M19.6 15.8c0 1.6-1.3 2.9-2.9 2.9s-2.9-1.3-2.9-2.9M19.6 22.7v-7M2.2 22.7v-7M11 4.3v3.4M1 22.7h19.8" />
    <path d="M22.4 3.8v3.9c0 .6-.5 1.1-1.1 1.1H15c-.6 0-1.1-.5-1.1-1.1V3.8c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1H19c.6 0 1.1.5 1.1 1.1v.3h1.2c.6 0 1.1.5 1.1 1.1z" />
  </svg>
);

export const embed = (
  <svg {...commonProps}>
    <g clipPath="url(#a)" strokeLinecap="round" strokeLinejoin="round">
      <path d="M20.25 4.5H3.75a.75.75 0 0 0-.75.75v13.5c0 .414.336.75.75.75h16.5a.75.75 0 0 0 .75-.75V5.25a.75.75 0 0 0-.75-.75Z" />
      <path d="M17.5 7h-11a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const enquire = (
  <svg {...commonProps}>
    <path d="M8.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" stroke="#6F7376" />
    <rect x="3" y="3" width="18" height="18" rx="2.4" />
  </svg>
);

// f
export const film = (
  <svg {...commonProps}>
    <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18" />
    <path d="M7 2v20M17 2v20M2 12h20M2 7h5M2 17h5M17 17h5M17 7h5" />
  </svg>
);

export const fast_forward = (
  <svg {...commonProps}>
    <path d="m13 19 9-7-9-7v14zM2 19l9-7-9-7v14z" />
  </svg>
);

export const family = (
  <svg {...commonProps}>
    <circle cx="18.9" cy="4.9" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M5.8 21.2H3.7c-.6 0-1-.5-1-1v-3.6M11.3 21.4h2c.6 0 1.1-.5 1.1-1v-2.9" />
    <path d="M16.3 17.4v3.4c0 1.4-1.1 2.5-2.5 2.5H3.3c-1.4 0-2.5-1.1-2.5-2.5V16c0-2 1.6-3.6 3.6-3.6h.7c1 0 1.9.4 2.5 1-.7.3-1.2 1.1-1.2 1.9 0 1.2 1.1 2.2 2.4 2.1.9-.1 1.7-.9 1.8-1.8.1-.5-.1-1-.4-1.4.5-.3 1.1-.4 1.7-.4h.7c2-.1 3.7 1.6 3.7 3.6z" />
    <path d="M10.6 15.3c0 1.2-.9 2.1-2.1 2.1-1.2 0-2.1-.9-2.1-2.1 0-.8.5-1.6 1.2-1.9.3-.1.6-.2.9-.2.7 0 1.3.4 1.7.9.3.4.4.8.4 1.2zM5.8 23.2v-1.4c0-1.5 1.2-2.7 2.7-2.7h.1c1.5 0 2.7 1.2 2.7 2.7v1.4H5.8zM10.6 12V9.9c0-1.1.9-2 2-2s2 .9 2 2V12c0 .1-.1.2-.2.2h-3.6c-.1 0-.2-.1-.2-.2z" />
    <circle cx="4.9" cy="8.4" r="2.4" />
    <path d="M23.1 3.3v3.9c0 .6-.5 1.1-1.1 1.1h-6.3c-.6 0-1.1-.5-1.1-1.1V3.3c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1h1.7c.6 0 1.1.5 1.1 1.1v.3H22c.6 0 1.1.5 1.1 1.1z" />
  </svg>
);

export const food = (
  <svg {...commonProps}>
    <circle cx="17.2" cy="5.4" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M21.4 3.7v3.9c0 .6-.5 1.1-1.1 1.1H14c-.6 0-1.1-.5-1.1-1.1V3.7c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1H18c.6 0 1.1.5 1.1 1.1v.3h1.2c.6 0 1.1.5 1.1 1.1z" />
    <g>
      <path d="M7.3 14.4c.8-.8 2-1.3 3.3-1.3M.9 19.1h19.4" />
      <path d="M3.8 18.1c0-3.8 3-6.8 6.8-6.8s6.8 3 6.8 6.8M10.6 11.3V9.5M9.7 9.2h1.8M4.6 23.2l-1.2-2.6h14.4l-1.2 2.6z" />
    </g>
  </svg>
);

// g

export const grid = (
  <svg {...commonProps}>
    <path d="M3 3h7v7H3zM14 3h7v7h-7zM14 14h7v7h-7zM3 14h7v7H3z" />
  </svg>
);

// h
export const hash = (
  <svg {...commonProps}>
    <path d="M4 9h16M4 15h16M10 3L8 21M16 3l-2 18" />
  </svg>
);

export const helper = (
  <svg {...commonProps}>
    <path
      fill="#ffbf00"
      d="M2.6 22.6V14c0-.6.5-1.1 1.1-1.1h1.1c.6 0 1.1.5 1.1 1.1v8.6H2.6z"
      className={styles['icon-accent-path']}
    />
    <path d="M13.8 17.6v5H5.9v-7.8c0-2.2 1.8-4 4-4s3.9 1.6 3.9 3.8" />
    <circle cx="9.9" cy="5.3" r="3.9" />
    <path d="M19.8 12.7c-1 2.8-3.5 4.8-6.5 4.8-2.7 0-5.1-1.6-6.2-4-.3-.5-.1-1.2.3-1.6.6-.6 1.6-.3 2 .5.7 1.4 2.2 2.4 3.8 2.4 1.5 0 2.9-.8 3.6-2.1h3z" />
    <path d="M15.7 6.9h5.7v5.8h-5.7zM2.6 22.6V14c0-.6.5-1.1 1.1-1.1h1.1c.6 0 1.1.5 1.1 1.1v8.6H2.6z" />
  </svg>
);

// i
export const italics = (
  <svg {...commonProps}>
    <line x1="19" y1="4" x2="10" y2="4" />
    <line x1="14" y1="20" x2="5" y2="20" />
    <line x1="15" y1="4" x2="9" y2="20" />
  </svg>
);
// j
// k
export const key = (
  <svg {...commonProps}>
    <path d="m21 2-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0 3 3L22 7l-3-3m-3.5 3.5L19 4" />
  </svg>
);
// l
export const luma = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1540 1540">
    <path
      fill="#FAF8F5"
      d="M213 0h1109l29 6 22 6 21 8 25 13 15 10 13 10 15 13 13 13 11 14 9 12 11 18 11 22 9 25 6 25 3 22 1 13v1077l-3 27-6 29-8 23-9 20-13 22-10 14-12 14-11 12-12 11-17 13-19 12-17 9-19 8-18 6-22 5-23 3-25 1H246l-27-1-24-3-25-6-20-7-20-9-25-15-16-12-12-11-8-7-12-13-12-15-11-17-6-10-12-26-8-24-6-28-2-11V212l8-36 9-27 12-25 11-18 12-16 13-15 15-15 11-9 15-11 13-8 23-12 21-8 20-6z"
    />
    <path
      fill="#464544"
      d="M767 187h1l4 44 6 42 8 39 9 32 11 33 13 32 14 30 13 24 15 25 12 18 12 17 10 13 13 16 9 11 15 16 34 34 11 9 10 9 14 11 20 15 24 16 15 9 19 11 23 12 25 12 30 12 35 12 33 9 38 8 35 5 27 3 21 1 3 2-40 2-42 6-31 6-32 8-22 7-21 7-28 11-26 12-24 12-24 14-19 12-19 13-19 14-17 14-11 9-13 12-10 9-22 22-9 11-11 12-11 14-12 15-11 16-7 10-13 21-13 22-13 25-14 31-13 34-9 28-8 30-7 34-5 31-4 46-2 2-4-43-5-35-8-38-8-30-15-44-13-31-14-29-13-23-12-20-10-15-13-19-11-14-13-16-9-11-14-15-35-35-11-9-11-10-26-20-20-14-22-14-24-14-27-14-24-11-28-11-36-12-25-7-36-8-31-5-33-4-28-1-1-2 33-2 32-4 35-6 31-7 28-8 30-10 28-11 26-12 22-11 24-14 24-15 17-12 11-8 18-14 14-12 10-9 8-7 33-33 9-11 10-11 11-14 10-13 12-17 10-15 9-15 10-17 15-29 13-28 14-37 9-28 7-26 7-33 5-32 4-40z"
    />
  </svg>
);

export const link = (
  <svg {...commonProps}>
    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
  </svg>
);
export const list = (
  <svg {...commonProps}>
    <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
  </svg>
);
export const linkedin_solid_color = (
  <svg viewBox="0 0 24 24">
    <path
      d="M19.041 19.04h-2.963v-4.641c0-1.107-.02-2.531-1.541-2.531-1.544 0-1.78 1.205-1.78 2.45v4.722H9.794V9.495h2.844V10.8c.58-.99 1.7-1.584 2.847-1.541 3.004 0 3.558 1.975 3.558 4.546l-.002 5.235ZM6.45 8.19c-.944 0-1.72-.776-1.72-1.72 0-.943.776-1.719 1.72-1.719.943 0 1.719.776 1.72 1.72 0 .943-.777 1.72-1.72 1.72ZM7.93 19.04H4.965V9.496h2.966v9.544ZM20.52 2H3.476A1.466 1.466 0 0 0 2 3.442v17.113c.01.8.675 1.452 1.476 1.443h17.043A1.47 1.47 0 0 0 22 20.555V3.44A1.468 1.468 0 0 0 20.519 2Z"
      fill="#0A66C2"
    />
  </svg>
);
export const lock = (
  <svg {...commonProps}>
    <rect width="18" height="11" x="3" y="11" rx="2" ry="2" fill="ffbf00" className={styles['icon-accent-path']} />
    <path d="M7 11V7a5 5 0 0 1 10 0v4" />
  </svg>
);

export const loader = (
  <svg {...commonProps}>
    <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" />
  </svg>
);

export const log_in = (
  <svg {...commonProps}>
    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
    <polyline points="10 17 15 12 10 7" />
    <line x1="15" x2="3" y1="12" y2="12" />
  </svg>
);

export const log_out = (
  <svg {...commonProps}>
    <path d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4M16 17l5-5-5-5M21 12H9" />
  </svg>
);

export const life_buoy = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <circle cx="12" cy="12" r="4" />
    <path d="M4.93 4.93l4.24 4.24M14.83 14.83l4.24 4.24M14.83 9.17l4.24-4.24M14.83 9.17l3.53-3.53M4.93 19.07l4.24-4.24" />
  </svg>
);

export const lightboy = (
  <svg {...commonProps}>
    <path fill="#ffbf00" d="M5 4.1h4.3v2.8H5z" className={styles['icon-accent-path']} />
    <ellipse cx="15.7" cy="9.6" rx="3.3" ry="3.2" />
    <path d="M10.8 22.6v-4.3c0-2.2 1.8-4 4-4h1.7c2.2 0 4 1.8 4 4v4.3h-9.7z" />
    <path d="M5 4.1h4.3v2.8H5zM11.1 16.6c-2.7-.1-4.9-2.4-4.9-5.1V9.3c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v2.2c0 1.6 1.3 2.9 2.9 2.9h3.1" />
    <path d="M4.2 2.9l-.7-.8M7.1 2.5V1.4M7.1 8.1V7M10 2.9l.8-.8M17.9 22.5l.1-4.4" />
  </svg>
);
// m

export const magaphone = (
  <svg {...commonProps}>
    <path d="M18.75 7.125h-3.74c-.206-.01-5.115-.317-9.786-4.235a1.125 1.125 0 0 0-1.849.86v15a1.113 1.113 0 0 0 .649 1.02 1.119 1.119 0 0 0 1.197-.16c3.98-3.34 8.134-4.055 9.401-4.2v3.402a1.125 1.125 0 0 0 .5.937l1.032.688a1.126 1.126 0 0 0 1.714-.657l1.17-4.414a4.125 4.125 0 0 0-.288-8.241Zm-4.125 7.528c-1.268.127-5.625.808-9.883 4.383a.375.375 0 0 1-.617-.286v-15a.375.375 0 0 1 .38-.375.364.364 0 0 1 .237.094C9 7.039 13.357 7.72 14.625 7.848v6.805Zm2.52 4.94a.375.375 0 0 1-.573.222l-1.031-.687a.376.376 0 0 1-.167-.312v-3.441h2.888l-1.118 4.219Zm1.605-4.968h-3.375v-6.75h3.375a3.375 3.375 0 0 1 0 6.75Z" />
  </svg>
);
export const minus = (
  <svg {...commonProps}>
    <path d="M5 12h14" />
  </svg>
);

export const maternity = (
  <svg {...commonProps}>
    <circle cx="7" cy="7.3" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M16.3 4.8V4c0-1.1.9-2.1 2.1-2.1h.1c1.1 0 2.1.9 2.1 2.1v2.6c0 .1-.1.2-.2.2h-1.9c-1.3 0-2.2-.9-2.2-2z" />
    <path d="M11.3 5.6v3.9c0 .6-.5 1.1-1.1 1.1H3.8c-.6 0-1.1-.5-1.1-1.1V5.6c0-.6.5-1.1 1.1-1.1H5v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1v.3h1.2c.6 0 1.1.5 1.1 1.1zM21.4 10.7V23h-5.2v-1.8c-1.8-.4-3.1-2-3.1-3.9 0-1.4.7-2.6 1.8-3.3.8-.5 1.3-1.5 1.3-2.5v-.8c0-1.5 1.2-2.6 2.6-2.6.7 0 1.4.3 1.9.8.4.5.7 1.1.7 1.8z" />
    <path d="M21 16.8h-5.4c-.5 0-1-.5-1-1v0c0-.5.5-1 1-1h3.6s.1-2.1.1-2.7" />
    <path d="M16.1 3.4h.8c.8 0 1.4.6 1.4 1.4v2.1" />
  </svg>
);
export const monitor = (
  <svg {...commonProps}>
    <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
    <path d="M8 21h8M12 17v4" />
  </svg>
);
export const modeling = (
  <svg {...commonProps}>
    <circle cx="17.3" cy="7.7" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M8.7 6.7c.4 1.3-.4 2.6-1.7 3s-2.7-.4-3-1.8" />
    <path d="M21.6 6v3.9c0 .6-.5 1.1-1.1 1.1h-6.3c-.6 0-1.1-.5-1.1-1.1V6c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1v.3h1.2c.5 0 1 .5 1 1.1zM1 23.2v-7.9c0-2.2 1.7-3.9 3.9-3.9h3.5c2.2 0 3.9 1.7 3.9 3.9v7.9H1zM3.7 7l-.6-2.3c-.4-1.3.4-2.7 1.7-3 1.3-.4 2.7.4 3 1.8l.6 2.3L3.7 7z" />
    <path d="M1 7.7L11.2 5" />
    <path d="M3.6 15.5v5.2l8.4.1M9.8 20.4v-5.5" />
  </svg>
);
export const map_pin = (
  <svg {...commonProps}>
    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
    <circle cx="12" cy="10" r="3" />
  </svg>
);

export const maximize = (
  <svg {...commonProps}>
    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
  </svg>
);
export const maximize2 = (
  <svg {...commonProps}>
    <polyline points="15 3 21 3 21 9" />
    <polyline points="9 21 3 21 3 15" />
    <line x1="21" x2="14" y1="3" y2="10" />
    <line x1="3" x2="10" y1="21" y2="14" />
  </svg>
);

export const message_square = (
  <svg {...commonProps}>
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
  </svg>
);

export const minimize = (
  <svg {...commonProps}>
    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
  </svg>
);
export const minimize2 = (
  <svg {...commonProps}>
    <polyline points="4 14 10 14 10 20" />
    <polyline points="20 10 14 10 14 4" />
    <line x1="14" x2="21" y1="10" y2="3" />
    <line x1="3" x2="10" y1="21" y2="14" />
  </svg>
);

export const music = (
  <svg {...commonProps}>
    <path d="M9 18V5l12-2v13" />
    <circle cx="6" cy="18" r="3" />
    <circle cx="18" cy="16" r="3" />
  </svg>
);

// n
// o
// p
export const pie_chart = (
  <svg {...commonProps}>
    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
    <path d="M22 12A10 10 0 0 0 12 2v10z" />
  </svg>
);
export const paint_brush = (
  <svg {...commonProps}>
    <path d="M2 21h6.387c.912 0 1.804-.293 2.563-.843a4.936 4.936 0 0 0 1.699-2.244c.349-.913.44-1.918.262-2.889a5.142 5.142 0 0 0-1.262-2.56 4.514 4.514 0 0 0-2.362-1.368 4.282 4.282 0 0 0-2.665.285 4.714 4.714 0 0 0-2.07 1.841A5.303 5.303 0 0 0 3.774 16C3.774 19.462 2 21 2 21ZM11 11.323C12.705 8.793 17.04 3 21 3c0 4.357-5.2 9.624-7.5 11.5" />
    <path d="M13 9a7.908 7.908 0 0 1 3 3" />
  </svg>
);
export const people_selfie = (
  <svg {...commonProps}>
    <path d="M2 9.5h2.5m1 0H8m1 0h2.5m1 0H15m1 0h2.5m1 0H22M2 19v3h3m14 0h3v-3M5 2H2v3m17-3h3v3" strokeLinecap="butt" />
    <circle cx="12" cy="9" r="5.5" />
    <path d="M20 20H4c.167-1.667 1.5-5 8-5.5 6 .5 8 4.167 8 5.5Z" />
  </svg>
);

export const pinterest_solid_color = (
  <svg viewBox="0 0 24 24">
    <path
      d="M19.406 4.05C17.654 2.728 15.231 2 12.581 2 8.533 2 6.043 3.304 4.668 4.398 2.972 5.746 2 7.536 2 9.31c0 2.226 1.185 3.935 3.169 4.57a1.3 1.3 0 0 0 .399.065c.418 0 .75-.216.865-.56.067-.199.222-.688.29-.9.144-.419.027-.62-.288-.912-.574-.534-.841-1.165-.841-1.986 0-2.44 2.311-5.032 6.595-5.032 3.398 0 5.51 1.518 5.51 3.962 0 1.542-.423 2.97-1.19 4.022-.534.73-1.472 1.601-2.912 1.601-.623 0-1.183-.2-1.535-.551a1.163 1.163 0 0 1-.31-1.206c.152-.504.358-1.03.558-1.538.365-.928.71-1.805.71-2.505 0-1.196-.936-2-2.33-2-1.77 0-3.156 1.413-3.156 3.217 0 .884.3 1.546.435 1.8l-1.8 5.99c-.146.486-1.021 4.325.428 4.63 1.629.345 3.084-3.394 3.232-3.816.12-.344.54-1.643.798-2.442.786.596 2.052.998 3.283.998 2.322 0 4.41-.821 5.88-2.312C21.215 12.959 22 10.943 22 8.73c0-1.73-.946-3.437-2.594-4.68Z"
      fill="#D7143A"
    />
  </svg>
);
export const product = (
  <svg {...commonProps}>
    <circle cx="18.7" cy="5.4" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M23 3.7v3.9c0 .6-.5 1.1-1.1 1.1h-6.3c-.6 0-1.1-.5-1.1-1.1V3.7c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1v.3H22c.5 0 1 .5 1 1.1z" />
    <g>
      <path d="M6.3 6.3H3.8M6.3 4.7H3.8M13.2 10.5h-2.4M13.2 8.8h-2.4M3.2 21.8v-8.9M1.1 23.2V12c0-2.2 1.8-4 4-4s4 1.8 4 4v11.1h-8zM9.1 23.2v-8.1c0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9v8.1H9.1z" />
    </g>
  </svg>
);

export const premium = (
  <svg {...commonProps}>
    <path
      d="M20.4419 17L23 7.4486L16.6047 11.3738L12 3L7.39535 11.3738L1 7.31776L3.43023 17H20.4419Z"
      fill="#FFBF00"
      className={styles['icon-accent-path']}
    />
    <path d="M19.8023 19L23 7.25L16.6047 11L12 3L7.39535 11L1 7.125L4.19767 19H19.8023Z" stroke="#0081D6" />
    <path d="M4 21H20" stroke="#FFBF00" className={styles['icon-accent-path--stroke']} />
  </svg>
);

export const photographer = (
  <svg {...commonProps}>
    <path
      fill="#ffbf00"
      d="M12.1 7.7V4.9c0-1.9 1.6-3.5 3.5-3.5h.5c1.9 0 3.5 1.6 3.5 3.5v2.8h-7.5z"
      className={styles['icon-accent-path']}
    />
    <path d="M4.8 13.5V9.3M16.8 14.8c2.5 0 4.6 2.5 4.6 5.5v2.3h-7.9" />
    <path d="M6.8 13.5H2.6V9.3h7.1M12.1 7.5V5.2c0-2.1 1.7-3.7 3.7-3.7 2.1 0 3.7 1.7 3.7 3.7v2.6L8.7 7.6" />
    <path d="M12.1 10V7.7h7.5V10c0 1.9-1.6 3.5-3.5 3.5h-.5c-1.9 0-3.5-1.6-3.5-3.5z" />
    <path d="M18.3 15.4c.5.8.3 1.8-.5 2.3l-7.6 4.6c-1.4.9-3.1-.2-3.1-2v-7.8c0-1.7 1.2-3.2 2.8-3.2l-.1 9.9 6.7-4.2c.6-.4 1.4-.2 1.8.4z" />
    <circle cx="13.7" cy="9.2" r=".1" />
  </svg>
);
export const phone_incoming = (
  <svg {...commonProps}>
    <path d="M16 2v6h6M23 1l-7 7M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
  </svg>
);
export const phone_outgoing = (
  <svg {...commonProps}>
    <path d="M23 7V1h-6M16 8l7-7M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
  </svg>
);
// q

// r
export const range_low = (
  <svg {...commonProps} stroke="none">
    <path
      fill="#EAEAEA"
      d="M17.9 3.5H6.1c-1.4 0-2.6 1.2-2.6 2.6v11.8c0 1.4 1.2 2.6 2.6 2.6h11.8c1.4 0 2.6-1.2 2.6-2.6V6.1c0-1.5-1.1-2.6-2.6-2.6Z"
    />
    <path stroke="#0081D6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.1 13.6v2.2" />
    <path
      stroke="#BCBCBC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M12 10.6v5.2M15.9 7.6v8.2"
    />
  </svg>
);

export const range_medium = (
  <svg {...commonProps} stroke="none">
    <path
      fill="#EAEAEA"
      d="M17.9 3.5H6.1c-1.4 0-2.6 1.2-2.6 2.6v11.8c0 1.4 1.2 2.6 2.6 2.6h11.8c1.4 0 2.6-1.2 2.6-2.6V6.1c0-1.5-1.1-2.6-2.6-2.6Z"
    />
    <path
      stroke="#0081D6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M8.1 13.6v2.2M12 10.6v5.2"
    />
    <path stroke="#BCBCBC" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M15.9 7.6v8.2" />
  </svg>
);

export const range_high = (
  <svg {...commonProps} stroke="none">
    <path
      fill="#EAEAEA"
      d="M17.9 3.5H6.1c-1.4 0-2.6 1.2-2.6 2.6v11.8c0 1.4 1.2 2.6 2.6 2.6h11.8c1.4 0 2.6-1.2 2.6-2.6V6.1c0-1.5-1.1-2.6-2.6-2.6Z"
    />
    <path
      stroke="#0081D6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M8.1 13.6v2.2M12 10.6v5.2M15.9 7.6v8.2"
    />
  </svg>
);

export const real_estate = (
  <svg {...commonProps}>
    <circle cx="18" cy="15.6" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M22.3 13.9v3.9c0 .6-.5 1.1-1.1 1.1h-6.3c-.6 0-1.1-.5-1.1-1.1v-3.9c0-.6.5-1.1 1.1-1.1H16v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1v.3h1.2c.6 0 1.1.5 1.1 1.1z" />
    <path d="M2.7 23.2V8.3l4.2-4.5 4 4.5v14.9z" />
    <path d="M.8 8.3l5.6-6 6.5.1L18 8.3h-7M5.5 14.4v-4.2c0-.7.6-1.2 1.2-1.2.8-.1 1.3.5 1.3 1.2v4.2H5.5zM5.5 17H8v4H5.5z" />
  </svg>
);
export const refresh_ccw = (
  <svg {...commonProps}>
    <path d="M1 4v6h6M23 20v-6h-6" />
    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4-4.64 4.36A9 9 0 0 1 3.51 15" />
  </svg>
);

export const rupee = (
  <svg {...commonProps}>
    <path d="M13.75 22.995l-8.004-9.958.046-1.973c3.756.257 6.676-.138 7.356-3.671l-8.004-.046L6.44 5.19l6.384.092c-.973-2.01-3.648-2.259-7.819-2.11L6.44 1.06l13.555-.02-1.342 2.085h-3.747c.685.719 1.186 1.546 1.203 2.248l3.886-.046-1.342 2.065-2.59.046c-.405 3.08-3.25 4.904-6.847 5.323l8.057 10.231-3.524.002v0h0z" />
  </svg>
);
export const rss = (
  <svg {...commonProps}>
    <path d="M4 11a9 9 0 0 1 9 9M4 4a16 16 0 0 1 16 16" />
    <circle cx="5" cy="19" r="1" />
  </svg>
);

export const save = (
  <svg {...commonProps}>
    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
    <path d="M17 21v-8H7v8M7 3v5h8" />
  </svg>
);

export const search = (
  <svg {...commonProps}>
    <circle cx="11" cy="11" r="8" />
    <path d="m21 21-4.35-4.35" />
  </svg>
);

export const swapcard = (
  <svg
    {...commonProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1200"
    id="Layer_1"
    x="0"
    y="0"
    // // style={{ enableBackground: 'new 0 0 1200 1200' }}
  >
    <style>{'.st0{fill:#fff}'}</style>
    <path
      d="M632.4 673.9c-2.5 3-1.7 4 .4 6.6 22.9 29.3 48.1 58.1 78.8 79.6 30.5 21.3 68.7 31.8 105.8 31.8 78.4 0 148.6-46.8 178.7-119.2 1-2.2.7-5-.7-6.9v-.1c-1.3-2.2-3.8-3.5-6.5-3.5h-56c-2.6 0-5.1 1.4-6.5 3.7-7 11.5-15.8 21.8-26.1 30.6-29.2 24.9-68.6 34.2-106.1 27.2-40.8-7.7-66.8-31.4-91.9-62.4-8.6-10.6-18.1-20.7-27-31.2-1.3-1.5-3.5-1.5-4.8 0-8 9.1-32.4 37-38.1 43.8"
      style={{ fill: '#0c8' }}
    />
    <path
      d="M995.9 527.4c-8.2-19.7-19.5-37.9-33.8-54.1-36.7-41.4-89.6-65.2-145.1-65.2-30.1 0-60.2 7-87 20.4-24.7 12.1-43 29.7-63 52.4-23.7 27-47.1 54.2-70.5 81.4-19.7 22.9-39.1 46-60.2 67.6-5.9 6-58.9 66.2-96.6 86-6.6 3.4-13.5 6.3-20.8 7.7-18.4 3.4-37.2 2.9-55-1.4l-2.4-.6c-1.3-.3-2.6-.8-3.8-1.2-19.2-6.3-36.8-17.2-51-31.5-4-4-7.7-8.3-11.2-12.9-11-14.5-18.8-31.4-22.6-49.2-3.7-16.9-3.8-34.5-.4-51.4 2.8-13.9 7.9-27.2 15.1-39.3 7.1-11.9 16.1-22.6 26.6-31.7 10.6-9.1 22.7-16.5 35.7-21.7 13.4-5.4 27.6-8.4 42-9 1.8-.1 3.7-.1 5.5-.1 67.2 0 103.2 51.7 142.5 97.6 1.3 1.5 3.5 1.5 4.8 0l38.9-45.6c1-1.2 1-2.9 0-4.1-24.2-28.4-47.6-60-79-81-31.5-21.1-69.5-32.4-107.4-32.4-103.2 0-187.8 80.9-192.9 182.2-.2.3-.2.6-.2.9 0 0-11.3 81.1 68.2 154.9 27.6 25.6 66.8 41.6 108.4 45 5 .5 10 .7 15.1.7h2c1.7 0 3.4-.2 5-.2 34.8-1.2 70.1-11.5 98.7-31.5 19.1-13.4 36-29.8 51.6-47.2.6.4 39.8-46.3 57.4-66.5 6.6-7.6 13.4-15.4 20.1-23.1 2.6-3.2 5.4-6.4 8.1-9.4l1-1.1.9-1.1c24.4-28.6 48.8-57.2 73.4-86 1-1.1 2.1-2.4 3.1-3.6 1-1.2 2-2.4 3-3.5 3.3-3.9 7.4-8.1 12.5-12.7 5.3-4.7 11.2-9.1 17.9-13.2 20.3-12.4 43.5-19 67.2-19 44.8 0 85.6 22.8 109.1 60.9 1.5 2.2 3.8 3.5 6.4 3.5h55.5c2.7 0 5.2-1.4 6.6-3.6 1.4-2 1.7-4.9.6-7.1"
      style={{ fill: '#262f3d' }}
    />
  </svg>
);

export const star = (
  <svg {...commonProps}>
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
  </svg>
);
export const sliders = (
  <svg {...commonProps}>
    <path d="M4 21v-7M4 10V3M12 21v-9M12 8V3M20 21v-5M20 12V3M1 14h6M9 8h6M17 16h6" />
  </svg>
);
export const shopping_cart = (
  <svg {...commonProps}>
    <circle cx="9" cy="21" r="1" />
    <circle cx="20" cy="21" r="1" />
    <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
  </svg>
);
export const selection_album = (
  <svg {...commonProps}>
    <path
      d="M19 18.3c0 1.1-.9 2.4-2 2.4H7.5c-1.8 0-3.3-1.5-3.3-3.3V6.1c0-1.1 1.2-2 2.2-2V16c0 1.4.9 2.3 2.3 2.2H19v.1Z"
      className={styles['icon-accent-path']}
    />
    <path d="M19.6 18.3H8.4c-1 0-1.8-.8-1.8-1.8v-13c0-1 .8-1.8 1.8-1.8h11.3c1 0 1.8.8 1.8 1.8v13c-.1 1-.9 1.8-1.9 1.8Z" />
    <path d="M19 18.7c0 1.1-.9 2-2 2H7.5c-1.8 0-3.3-1.5-3.3-3.3V6.1c0-1.1.9-2 2-2" />
    <path d="M15.4 22.3H7c-2.4 0-4.4-2-4.4-4.4V7.7M17.6 7.6l-4.2 4.2-2-2.1" />
  </svg>
);
export const signature_album = (
  <svg {...commonProps}>
    <path
      d="M18.4 14.7 16.7 12c-.1-.2-.4-.2-.5 0l-2.5 3.9c-.1.2-.4.2-.5 0l-1-1.5c-.1-.2-.4-.2-.5 0L10 17c-.1.2 0 .5.3.5h7.9c.2 0 .3-.1.3-.3v-2.3c-.1-.1-.1-.2-.1-.2ZM6.1 2h-.4c-1 0-1.8.8-1.8 1.8v16.8c0 1 .8 1.8 1.8 1.8h.4V2Z"
      className={styles['icon-accent-path']}
    />
    <path d="M20.3 22.4H5.7c-1 0-1.8-.8-1.8-1.8V3.8c0-1 .8-1.8 1.8-1.8h14.6c1 0 1.8.8 1.8 1.8v16.8c0 1-.8 1.8-1.8 1.8Z" />
    <path d="M17.9 17.8h-7.5c-.4 0-.7-.3-.7-.7V7.3c0-.4.3-.7.7-.7h7.5c.4 0 .7.3.7.7v9.9c-.1.3-.4.6-.7.6ZM3.4 8.4C2.6 8.4 2 7.8 2 7c0-.8.6-1.5 1.4-1.5h1.2c.8 0 1.5.7 1.5 1.5M3.4 13.7c-.8 0-1.4-.7-1.4-1.5s.7-1.5 1.5-1.5h1.2c.8 0 1.5.7 1.5 1.5M3.4 18.9c-.8 0-1.4-.7-1.4-1.5s.7-1.5 1.5-1.5h1.2c.8 0 1.5.7 1.5 1.5M6.1 2v20.4M11.3 9h3M11.3 10.6h4.1" />
  </svg>
);
// t

export const tag = (
  <svg {...commonProps} strokeWidth="2" className={styles['icon-accent-path--stroke']}>
    <path d="m20.59 13.41-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82zM7 7h.01" />
  </svg>
);

export const tax = (
  <svg {...commonProps}>
    <path
      fill="#000"
      d="M4.697 2h11.545c.951 0 1.816.389 2.443 1.016A3.449 3.449 0 0 1 19.7 5.459v10.949H4.333V2h.364Zm11.545.729H5.062v12.95h13.91V5.459a2.72 2.72 0 0 0-.803-1.928 2.721 2.721 0 0 0-1.927-.802Z"
    />
    <path fill="#000" d="M21.669 22.713H2v-7.034h20.033v7.034h-.364Zm-18.94-.73h18.575v-5.576H2.729v5.577Z" />
  </svg>
);

export const technician = (
  <svg {...commonProps}>
    <circle cx="6.6" cy="8" r="1.5" className={styles['icon-accent-path']} />
    <path
      d="M12.4 5.7V4.4c0-1.5 1.2-2.7 2.7-2.7h.8c1.5 0 2.7 1.2 2.7 2.7v1.3h-6.2z"
      className={styles['icon-accent-path']}
    />
    <path d="M12.7 5.7l8.2.1" />
    <path d="M10.5 10.5c-.4.6-.9 1.1-1.5 1.5-.3-.7-.9-1.1-1.7-1.1-.9 0-1.7.7-1.8 1.6-.7-.2-1.4-.5-1.9-1 .5-.5.8-1.2.5-1.9-.3-.9-1.2-1.5-2.1-1.3V8c0-.6.1-1.2.4-1.8.6.4 1.4.4 2-.1.8-.6 1-1.6.5-2.4.5-.2 1.1-.3 1.7-.3H7c-.2.7.1 1.5.7 1.9.8.6 1.8.4 2.4-.3.5.5.8 1.2 1 1.9-.8.1-1.4.6-1.6 1.3-.3.9.1 1.9 1 2.3z" />
    <path d="M9 22.3v-6.4c0-2.2 1.7-3.9 3.9-3.9h5.2c2.2 0 3.9 1.7 3.9 3.9v6.4H9z" />
    <path d="M19.3 22.3l-.1-6.5M11.7 22.3l-.1-6.5" />
    <path d="M12.4 6.8v-2c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1V7" />
    <path d="M12.4 8.4V5.7h6.2v2.7c0 1.5-1.2 2.7-2.7 2.7h-.8c-1.5 0-2.7-1.2-2.7-2.7z" />
  </svg>
);
export const twitter_solid_color = (
  <svg viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#a)" fill="#000">
      <path d="m6.689 6.194 8.667 11.586h1.963L8.65 6.194H6.69Z" />
      <path d="M19.191.132H4.781a4.65 4.65 0 0 0-4.65 4.65v14.41a4.65 4.65 0 0 0 4.65 4.65h14.41a4.65 4.65 0 0 0 4.65-4.65V4.781a4.65 4.65 0 0 0-4.65-4.65Zm-4.298 18.573-3.946-5.276-4.884 5.276H4.808l5.578-6.026-5.543-7.411h4.272l3.634 4.859 4.498-4.86h1.255l-5.192 5.61 5.855 7.828h-4.272Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

// u
export const underline = (
  <svg {...commonProps}>
    <path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3" />
    <line x1="4" y1="21" x2="20" y2="21" />
  </svg>
);
// v
export const vimeo_solid_color = (
  <svg viewBox="0 0 24 24">
    <path
      d="M22.99 7.396c-.099 2.138-1.594 5.065-4.487 8.782C15.51 20.06 12.98 22 10.91 22c-1.283 0-2.368-1.181-3.255-3.545l-1.775-6.502C5.222 9.59 4.517 8.407 3.76 8.407c-.165 0-.74.346-1.727 1.034L1 8.111a272.819 272.819 0 0 0 3.21-2.86C5.658 4.004 6.746 3.347 7.47 3.28c1.712-.164 2.766 1.005 3.161 3.506.428 2.698.724 4.377.89 5.034.494 2.24 1.037 3.358 1.63 3.358.46 0 1.152-.727 2.074-2.18.921-1.452 1.415-2.557 1.481-3.317.132-1.253-.361-1.88-1.48-1.88-.527 0-1.07.12-1.629.359 1.082-3.536 3.147-5.253 6.196-5.155 2.26.067 3.327 1.53 3.196 4.392Z"
      fill="#1AB7EA"
    />
  </svg>
);
export const volume_x = (
  <svg {...commonProps}>
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    <line x1="23" x2="17" y1="9" y2="15" />
    <line x1="17" x2="23" y1="9" y2="15" />
  </svg>
);
export const volume_2 = (
  <svg {...commonProps}>
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
  </svg>
);
// w
export const ward = (
  <svg {...commonProps}>
    <path d="M15 4V2" />
    <path d="M15 16v-2" />
    <path d="M8 9h2" />
    <path d="M20 9h2" />
    <path d="M17.8 11.8 19 13" />
    <path d="M15 9h.01" />
    <path d="M17.8 6.2 19 5" />
    <path d="m3 21 9-9" />
    <path d="M12.2 6.2 11 5" />
  </svg>
);
export const template = (
  <svg {...commonProps}>
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect width="16" height="4" x="4" y="4" rx="1" />
    <rect width="6" height="8" x="4" y="12" rx="1" />
    <path d="M14 12h6M14 16h6M14 20h6" />
  </svg>
);

export const wedding = (
  <svg {...commonProps}>
    <circle cx="18.8" cy="4.8" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M22.9 3.2V7c0 .6-.5 1.1-1.1 1.1h-6.2c-.6 0-1.1-.5-1.1-1.1V3.2c0-.6.5-1.1 1.1-1.1h1.1v-.3c0-.6.5-1.1 1.1-1.1h1.7c.6 0 1.1.5 1.1 1.1v.3h1.2c.6 0 1.1.5 1.1 1.1z" />
    <g>
      <path d="M5 20H3.5c-1.3 0-2.4-1-2.4-2.2v-4.2c0-1.8 1.5-3.2 3.4-3.2h.7c1.7 0 3.2 1.3 3.4 2.9-.2.4-.3.8-.3 1.3v2.3" />
      <path d="M8.2 16.7v-2.2c0-.5.1-.9.3-1.3.5-1 1.6-1.6 2.8-1.6h1c1.7 0 3.1 1.3 3.1 3V18c0 1.1-.9 2-2.1 2h-1.9" />
      <path d="M5.6 18.5H3.7c-.5 0-.9-.4-.9-.9v-3.2M11.1 18.7h1.5c.5 0 1-.4 1-.9v-2.6" />
      <path d="M10.1 10.4V8.7c0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9v1.7c0 .1-.1.2-.2.2h-3.4c-.1 0-.2-.1-.2-.2z" />
      <ellipse cx="4.8" cy="7.3" rx="2.3" ry="2.2" />
      <circle cx="8.2" cy="20" r=".8" />
      <g>
        <path d="M10.3 21.3c.2.2.3.4.3.6 0 .5-.4.9-1 .9-.4 0-.7-.2-.8-.4 0 .2-.1.4-.3.6-.4.4-1 .3-1.4 0-.2-.2-.3-.6-.3-.9-.2.2-.4.3-.6.3-.5 0-1-.4-1-.9 0-.3.2-.6.5-.8-.2 0-.5-.1-.7-.3-.4-.4-.4-.9 0-1.3.2-.2.6-.3.9-.2-.2-.2-.3-.4-.3-.6 0-.5.4-.9 1-.9.4 0 .7.2.8.4 0-.2.1-.4.3-.6.4-.4 1-.3 1.4 0 .2.2.3.6.2.9.2-.2.4-.3.7-.3.5 0 1 .4 1 .9 0 .3-.2.6-.5.8.2 0 .5.1.6.3.4.4.4.9 0 1.3-.1.1-.4.2-.8.2z" />
      </g>
    </g>
  </svg>
);
// x
export const x_circle = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="m15 9-6 6M9 9l6 6" />
  </svg>
);

// y
// z

export const plus = (
  <svg {...commonProps}>
    <path d="M12 5v14M5 12h14" />
  </svg>
);

export const pause = (
  <svg {...commonProps} stroke="none">
    <path
      fill="#EAEAEA"
      d="M17.9 3.5H6.1c-1.4 0-2.6 1.2-2.6 2.6v11.8c0 1.4 1.2 2.6 2.6 2.6h11.8c1.4 0 2.6-1.2 2.6-2.6V6.1c0-1.5-1.1-2.6-2.6-2.6Z"
    />
    <path stroke="#BCBCBC" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M10 9v5.2M14 9v5.2" />
  </svg>
);
export const pause_circle = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <line x1="10" x2="10" y1="15" y2="9" />
    <line x1="14" x2="14" y1="15" y2="9" />
  </svg>
);
export const play = (
  <svg {...commonProps}>
    <path d="M5 3l14 9-14 9V3z" />
  </svg>
);

export const play_circle = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="M10 8l6 4-6 4V8z" />
  </svg>
);

export const rotate_ccw = (
  <svg {...commonProps}>
    <path d="M1 4v6h6" />
    <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10" className="icon-path-fill" />
  </svg>
);

export const x = (
  <svg {...commonProps}>
    <path d="M18 6L6 18M6 6l12 12" />
  </svg>
);

export const check = (
  <svg {...commonProps}>
    <path d="M20 6L9 17l-5-5" />
  </svg>
);

export const folder = (
  <svg viewBox="0 0 27 24">
    <g fill="none">
      <path fill="#203A56" d="M26.06 24H.94c-.52 0-.94-.407-.94-.909V7h27v16.091c0 .502-.42.909-.94.909z" />
      <path
        fill="#F5AB33"
        className="icon-path-fill"
        d="M12.112 4.034L10.55 1.018C10.316.542 10.004 0 9.485 0H.94C.42 0 0 .456 0 1.018V7h27V5.052c0-.563-.42-1.018-.94-1.018H12.112z"
      />
    </g>
  </svg>
);

export const git_commit = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="4" />
    <path d="M1.05 12H7M17.01 12h5.95" />
  </svg>
);

export const arrow_left_circle = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" className="icon-path-fill" />
    <path d="M12 8l-4 4 4 4M16 12H8" className="icon-path-fill" />
  </svg>
);

export const phone = (
  <svg {...commonProps}>
    <path
      className="icon-path-fill"
      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
    />
  </svg>
);

export const mail = (
  <svg {...commonProps}>
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" className="icon-path-fill" />
    <path d="M22 6l-10 7L2 6" />
  </svg>
);

export const clock = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="M12 6v6l4 2" />
  </svg>
);

export const copy = (
  <svg {...commonProps}>
    <rect width="13" height="13" x="9" y="9" rx="2" ry="2" />
    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
  </svg>
);

export const cpu = (
  <svg {...commonProps}>
    <rect width="16" height="16" x="4" y="4" rx="2" ry="2" />
    <rect width="6" height="6" x="9" y="9" />
    <line x1="9" x2="9" y1="1" y2="4" />
    <line x1="15" x2="15" y1="1" y2="4" />
    <line x1="9" x2="9" y1="20" y2="23" />
    <line x1="15" x2="15" y1="20" y2="23" />
    <line x1="20" x2="23" y1="9" y2="9" />
    <line x1="20" x2="23" y1="14" y2="14" />
    <line x1="1" x2="4" y1="9" y2="9" />
    <line x1="1" x2="4" y1="14" y2="14" />
  </svg>
);

export const upload = (
  <svg {...commonProps}>
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M17 8l-5-5-5 5M12 3v12" />
  </svg>
);

export const image = (
  <svg {...commonProps}>
    <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
    <circle cx="8.5" cy="8.5" r="1.5" />
    <path d="M21 15l-5-5L5 21" />
  </svg>
);

export const slash = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="M4.93 4.93l14.14 14.14" />
  </svg>
);

export const check_circle = (
  <svg {...commonProps}>
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <path d="M22 4L12 14.01l-3-3" />
  </svg>
);

export const upload_cloud = (
  <svg {...commonProps}>
    <path d="M16 16l-4-4-4 4M12 12v9" />
    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" />
    <path d="M16 16l-4-4-4 4" />
  </svg>
);

export const send = (
  <svg {...commonProps}>
    <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
  </svg>
);

export const info = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" className="icon-path-fill" />
    <line x1="12" x2="12" y1="16" y2="12" className="icon-path-fill" />
    <line x1="12" x2="12" y1="8" y2="8" className="icon-path-fill" />
  </svg>
);

export const info_solid = (
  <svg viewBox="0 0 16 16">
    <path d="M7.875 0a7.875 7.875 0 1 0 0 15.75 7.875 7.875 0 0 0 0-15.75zm1.639 12.205c-.405.16-.728.281-.97.365a2.559 2.559 0 0 1-.841.126c-.491 0-.873-.12-1.145-.36a1.16 1.16 0 0 1-.407-.91c0-.144.01-.29.03-.44.02-.15.053-.317.098-.506l.507-1.792a9.19 9.19 0 0 0 .114-.487 2.16 2.16 0 0 0 .045-.422c0-.228-.047-.388-.141-.478-.095-.09-.275-.134-.542-.134-.13 0-.265.019-.403.06a5.73 5.73 0 0 0-.353.117l.134-.552c.332-.135.65-.251.953-.347.304-.098.59-.146.86-.146.488 0 .864.119 1.128.354.264.235.396.541.396.917 0 .078-.009.215-.027.411a2.752 2.752 0 0 1-.101.541l-.505 1.787a5.055 5.055 0 0 0-.111.49 2.595 2.595 0 0 0-.049.418c0 .237.053.399.16.485.105.086.29.13.55.13a1.6 1.6 0 0 0 .418-.065c.155-.043.267-.081.337-.114l-.135.552zm-.09-7.252a1.204 1.204 0 0 1-.85.328c-.33 0-.615-.11-.853-.328a1.047 1.047 0 0 1-.355-.796c0-.31.12-.576.355-.797.238-.221.523-.331.854-.331.331 0 .615.11.85.331.235.22.353.487.353.797 0 .312-.118.577-.353.796z" />
  </svg>
);

export const alert_triangle = (
  <svg {...commonProps}>
    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
    <line x1="12" x2="12" y1="9" y2="13" />
    <line x1="12" x2="12" y1="17" y2="17" />
  </svg>
);

export const home = (
  <svg {...commonProps}>
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
    <polyline points="9 22 9 12 15 12 15 22" />
  </svg>
);

export const rotate_cw = (
  <svg {...commonProps}>
    <path d="M23 4v6h-6" />
    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10" />
  </svg>
);

export const trash = (
  <svg {...commonProps}>
    <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6" />
  </svg>
);

export const edit = (
  <svg {...commonProps}>
    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34" />
    <path d="M18 2l4 4-10 10H8v-4L18 2z" />
  </svg>
);

export const edit_2 = (
  <svg {...commonProps}>
    <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
  </svg>
);

export const archive = (
  <svg {...commonProps}>
    <path d="M21 8v13H3V8M1 3h22v5H1zM10 12h4" />
  </svg>
);

export const arrow_top = (
  <svg {...commonProps}>
    <path d="M12 19V5M5 12l7-7 7 7" />
  </svg>
);

export const share = (
  <svg {...commonProps}>
    <circle cx="18" cy="5" r="3" />
    <circle cx="6" cy="12" r="3" />
    <circle cx="18" cy="19" r="3" />
    <path d="M8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98" />
  </svg>
);

export const share_solid = (
  <svg viewBox="0 0 16 15">
    <path d="M15.819 4.979L11.25.19a.537.537 0 0 0-.402-.178.537.537 0 0 0-.401.178.59.59 0 0 0-.17.42v2.395H8.28c-4.241 0-6.844 1.256-7.807 3.768C.158 7.609 0 8.647 0 9.888c0 1.035.378 2.44 1.133 4.217a22.618 22.618 0 0 0 .214.505c.036.08.075.15.116.205.072.106.155.16.25.16.09 0 .16-.032.21-.094a.36.36 0 0 0 .076-.234c0-.056-.008-.138-.023-.248a2.024 2.024 0 0 1-.022-.22c-.03-.423-.045-.807-.045-1.15 0-.629.052-1.193.156-1.692.104-.498.249-.93.433-1.295a3.22 3.22 0 0 1 .714-.944c.291-.265.605-.482.941-.65.336-.168.732-.3 1.187-.397.455-.097.913-.164 1.374-.202.46-.037.983-.056 1.566-.056h1.998v2.394a.59.59 0 0 0 .17.421.537.537 0 0 0 .401.178.54.54 0 0 0 .402-.178l4.568-4.788a.589.589 0 0 0 .17-.42.59.59 0 0 0-.17-.421z" />
  </svg>
);

export const download = (
  <svg {...commonProps}>
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3" />
  </svg>
);

export const box = (
  <svg {...commonProps}>
    <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z" />
    <polyline points="2.32 6.16 12 11 21.68 6.16" />
    <line x1="12" y1="22.76" x2="12" y2="11" />
    <line x1="7" y1="3.5" x2="17" y2="8.5" />
  </svg>
);

export const zap = (
  <svg {...commonProps}>
    <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" />
  </svg>
);

export const zap__animated = (
  <svg {...commonProps}>
    <defs>
      <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="#7a00ff">
          <animate attributeName="offset" values="0;0.1;0.15" dur="0.3s" repeatCount="indefinite" />
        </stop>
        <stop offset="5%" stopColor="#002bff">
          <animate attributeName="offset" values="0.2;0.25;0.3;" dur="0.3s" repeatCount="indefinite" />
        </stop>
        <stop offset="15%" stopColor="#ff00c8">
          <animate attributeName="offset" values="0.3;0.35;0.4" dur="0.3s" repeatCount="indefinite" />
        </stop>
        <stop offset="25%" stopColor="#ff0000">
          <animate attributeName="offset" values="0.55;0.6;0.65" dur="0.3s" repeatCount="indefinite" />
        </stop>
        <stop offset="40%" stopColor="#00ffd5">
          <animate attributeName="offset" values="0.7;0.8;0.85" dur="0.3s" repeatCount="indefinite" />
        </stop>
        <stop offset="45%" stopColor="#ff7300">
          <animate attributeName="offset" values="0.9;0.92;0.95" dur="0.3s" repeatCount="indefinite" />
        </stop>
        <stop offset="80%" stopColor="#fffb00">
          <animate attributeName="offset" values="0.97;1" dur="0.3s" repeatCount="indefinite" />
        </stop>
      </linearGradient>
    </defs>
    <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" stroke="url(#linear)" />
  </svg>
);

export const glob = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
  </svg>
);

export const instagram = (
  <svg {...commonProps}>
    <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37zM17.5 6.5" />
  </svg>
);

export const facebook = (
  <svg {...commonProps}>
    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
  </svg>
);

export const feather = (
  <svg {...commonProps}>
    <path d="M20.24 12.24a6 6 0 00-8.49-8.49L5 10.5V19h8.5zM16 8L2 22M17.5 15H9" />
  </svg>
);

export const file_plus = (
  <svg {...commonProps}>
    <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
    <path d="M14 2v6h6M12 18v-6M9 15h6" />
  </svg>
);

export const file_minus = (
  <svg {...commonProps}>
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
    <path d="M14 2v6h6M9 15h6" />
  </svg>
);

export const file_text = (
  <svg {...commonProps}>
    <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
    <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8" />
  </svg>
);

export const user = (
  <svg {...commonProps}>
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
    <circle cx="12" cy="7" r="4" />
  </svg>
);

export const user_check = (
  <svg {...commonProps}>
    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
    <circle cx="8.5" cy="7" r="4" />
    <path d="m17 11 2 2 4-4" />
  </svg>
);

export const user_x = (
  <svg {...commonProps}>
    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
    <circle cx="8.5" cy="7" r="4" />
    <path d="m18 8 5 5M23 8l-5 5" />
  </svg>
);

export const users = (
  <svg {...commonProps}>
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <path d="M23 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
  </svg>
);

export const help_circle = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
    <line
      x1="12"
      y1="17"
      x2="12.01"
      y2="17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const whatsapp_solid_color = (
  <svg viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#E5E5E5"
        d="M.513 24a.513.513 0 0 1-.495-.645l1.567-5.698a11.872 11.872 0 0 1-1.489-5.76C.099 5.336 5.458 0 12.043 0a11.898 11.898 0 0 1 8.45 3.49 11.803 11.803 0 0 1 3.497 8.416c-.003 6.56-5.363 11.897-11.947 11.897h-.005c-1.924 0-3.83-.468-5.528-1.352L.643 23.983a.518.518 0 0 1-.13.017z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M.513 23.49l1.622-5.9a11.332 11.332 0 0 1-1.526-5.693C.61 5.618 5.74.51 12.043.51a11.384 11.384 0 0 1 8.087 3.34 11.295 11.295 0 0 1 3.346 8.056c-.003 6.278-5.133 11.386-11.434 11.386h-.005a11.46 11.46 0 0 1-5.464-1.386l-6.06 1.583zm6.342-3.645l.347.205a9.52 9.52 0 0 0 4.837 1.32h.004c5.238 0 9.5-4.247 9.503-9.465a9.387 9.387 0 0 0-2.78-6.696 9.46 9.46 0 0 0-6.72-2.775c-5.242 0-9.505 4.245-9.507 9.463 0 1.788.502 3.53 1.453 5.037l.226.358-.96 3.492 3.597-.94z"
      />
      <path
        fill="#64B161"
        d="M.91 23.092l1.566-5.696A10.939 10.939 0 0 1 1.003 11.9C1.005 5.84 5.957.91 12.04.91a10.989 10.989 0 0 1 7.808 3.223 10.903 10.903 0 0 1 3.23 7.777c-.003 6.06-4.954 10.992-11.038 10.992h-.005a11.07 11.07 0 0 1-5.274-1.338L.91 23.092z"
      />
      <g fill="#FFF">
        <path
          fillRule="nonzero"
          d="M.513 23.49l1.622-5.9a11.332 11.332 0 0 1-1.526-5.693C.61 5.618 5.74.51 12.043.51a11.384 11.384 0 0 1 8.087 3.34 11.295 11.295 0 0 1 3.346 8.056c-.003 6.278-5.133 11.386-11.434 11.386h-.005a11.46 11.46 0 0 1-5.464-1.386l-6.06 1.583zm6.342-3.645l.347.205a9.52 9.52 0 0 0 4.837 1.32h.004c5.238 0 9.5-4.247 9.503-9.465a9.387 9.387 0 0 0-2.78-6.696 9.46 9.46 0 0 0-6.72-2.775c-5.242 0-9.505 4.245-9.507 9.463 0 1.788.502 3.53 1.453 5.037l.226.358-.96 3.492 3.597-.94z"
        />
        <path d="M9.185 7.137c-.214-.474-.44-.484-.643-.492-.166-.007-.357-.007-.547-.007-.19 0-.5.072-.762.356-.262.285-1 .973-1 2.374 0 1.4 1.024 2.753 1.166 2.943.143.19 1.977 3.155 4.88 4.296 2.414.948 2.905.76 3.43.712.523-.047 1.69-.688 1.927-1.353.239-.664.239-1.234.167-1.353-.071-.119-.262-.19-.547-.332-.286-.142-1.69-.831-1.953-.926-.262-.095-.452-.142-.643.143-.19.284-.737.925-.904 1.115-.167.19-.333.214-.62.072-.285-.143-1.205-.443-2.296-1.413-.85-.754-1.423-1.686-1.59-1.97-.167-.285-.003-.426.126-.581.23-.28.618-.784.714-.973.095-.19.047-.357-.024-.499-.072-.142-.627-1.55-.88-2.112z" />
      </g>
    </g>
  </svg>
);

export const gmail_solid_color = (
  <svg viewBox="0 0 24 24">
    <path d="M22.5 8.1 20 9.475l-2.5 2.375V20H21a1.5 1.5 0 0 0 1.5-1.5V8.1Z" fill="#4CAF50" />
    <path d="m1.5 8.1 1.807.855L6.5 11.85V20H3a1.5 1.5 0 0 1-1.5-1.5V8.1Z" fill="#1E88E5" />
    <path d="M17.5 5.6 12 9.725 6.5 5.6 6 8.5l.5 3.35 5.5 4.125 5.5-4.125.5-3.35-.5-2.9Z" fill="#E53935" />
    <path d="M1.5 6.149V8.1l5 3.75V5.6L4.938 4.43A2.149 2.149 0 0 0 1.5 6.149Z" fill="#C62828" />
    <path d="M22.5 6.149V8.1l-5 3.75V5.6l1.562-1.17A2.149 2.149 0 0 1 22.5 6.149Z" fill="#FBC02D" />
  </svg>
);

export const messenger_solid_color = (
  <svg viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#1E88E5"
        d="M12 0C5.373 0 0 4.974 0 11.11c0 3.497 1.744 6.616 4.471 8.652V24l4.086-2.242c1.091.301 2.246.465 3.443.465 6.627 0 12-4.974 12-11.111C24 4.974 18.627 0 12 0z"
      />
      <path fill="#FAFAFA" d="M13.193 14.963l-3.056-3.26-5.963 3.26L10.732 8l3.131 3.259 5.889-3.26z" />
    </g>
  </svg>
);

export const instagram_solid_color = (
  <svg viewBox="0 0 24 24">
    <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="99.709%" y2=".777%">
        <stop offset="0%" stopColor="#E09B3D" />
        <stop offset="30%" stopColor="#C74C4D" />
        <stop offset="60%" stopColor="#C21975" />
        <stop offset="100%" stopColor="#7024C4" />
      </linearGradient>
      <linearGradient id="b" x1="50%" x2="50%" y1="146.099%" y2="-45.16%">
        <stop offset="0%" stopColor="#E09B3D" />
        <stop offset="30%" stopColor="#C74C4D" />
        <stop offset="60%" stopColor="#C21975" />
        <stop offset="100%" stopColor="#7024C4" />
      </linearGradient>
      <linearGradient id="c" x1="50%" x2="50%" y1="658.141%" y2="-140.029%">
        <stop offset="0%" stopColor="#E09B3D" />
        <stop offset="30%" stopColor="#C74C4D" />
        <stop offset="60%" stopColor="#C21975" />
        <stop offset="100%" stopColor="#7024C4" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#a)"
        fillRule="nonzero"
        d="M16.82 0H7.138A7.145 7.145 0 0 0 0 7.137v9.684a7.145 7.145 0 0 0 7.137 7.137h9.684a7.145 7.145 0 0 0 7.137-7.137V7.137C23.958 3.202 20.756 0 16.821 0zm4.728 16.82a4.727 4.727 0 0 1-4.727 4.728H7.137A4.727 4.727 0 0 1 2.41 16.82V7.137A4.727 4.727 0 0 1 7.137 2.41h9.684a4.727 4.727 0 0 1 4.727 4.727v9.684z"
      />
      <path
        fill="url(#b)"
        fillRule="nonzero"
        d="M11.979 5.783a6.203 6.203 0 0 0-6.196 6.196 6.203 6.203 0 0 0 6.196 6.196 6.203 6.203 0 0 0 6.196-6.196 6.203 6.203 0 0 0-6.196-6.196zm0 9.982a3.786 3.786 0 1 1 0-7.572 3.786 3.786 0 0 1 0 7.572z"
      />
      <circle cx="18.187" cy="5.829" r="1.485" fill="url(#c)" />
    </g>
  </svg>
);

export const facebook_solid_color = (
  <svg viewBox="0 0 24 24">
    <path
      fill="#475993"
      fillRule="evenodd"
      d="M20.755 0H3.232A3.232 3.232 0 0 0 0 3.232v17.523a3.232 3.232 0 0 0 3.232 3.232h8.643l.014-8.571H9.662a.525.525 0 0 1-.525-.524l-.01-2.763a.525.525 0 0 1 .525-.527h2.223v-2.67c0-3.098 1.892-4.785 4.656-4.785h2.268c.29 0 .525.235.525.525v2.33c0 .29-.235.525-.525.525l-1.392.001c-1.503 0-1.794.714-1.794 1.762v2.312h3.303c.315 0 .559.275.522.587l-.328 2.763a.525.525 0 0 1-.522.464h-2.96l-.015 8.572h5.142a3.232 3.232 0 0 0 3.233-3.233V3.232A3.232 3.232 0 0 0 20.755 0z"
    />
  </svg>
);

export const youtube_solid_color = (
  <svg viewBox="0 0 24 16.804">
    <path
      fill="#ff0000"
      d="M23.506 2.629A3.007 3.007 0 0 0 21.39.514C19.512 0 12 0 12 0S4.488 0 2.61.494A3.069 3.069 0 0 0 .494 2.629 31.684 31.684 0 0 0 0 8.4a31.567 31.567 0 0 0 .494 5.773A3.007 3.007 0 0 0 2.61 16.29c1.9.514 9.39.514 9.39.514s7.512 0 9.39-.494a3.007 3.007 0 0 0 2.115-2.115A31.684 31.684 0 0 0 24 8.422a30.085 30.085 0 0 0-.494-5.792z"
    />
    <path fill="#fff" d="M15.855 8.402l-6.247-3.6v7.2z" />
  </svg>
);
export const youtube = (
  <svg {...commonProps}>
    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
    <path d="m9.75 15.02 5.75-3.27-5.75-3.27v6.54z" />
  </svg>
);

export const activity = (
  <svg {...commonProps}>
    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
  </svg>
);

export const folder_simple = (
  <svg {...commonProps}>
    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
  </svg>
);

export const filter = (
  <svg {...commonProps}>
    <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" />
  </svg>
);

export const external_link = (
  <svg {...commonProps}>
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14L21 3" />
  </svg>
);

export const external_link_2 = (
  <svg {...commonProps}>
    <path d="M12 6h6v6M7 17 18 6" />
  </svg>
);

export const download_cloud = (
  <svg {...commonProps}>
    <path d="M8 17l4 4 4-4M12 12v9" />
    <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" />
  </svg>
);

export const server = (
  <svg {...commonProps}>
    <rect width="20" height="8" x="2" y="2" rx="2" ry="2" />
    <rect width="20" height="8" x="2" y="14" rx="2" ry="2" />
    <line x1="6" x2="6" y1="6" y2="6" />
    <line x1="6" x2="6" y1="18" y2="18" />
  </svg>
);

export const printer = (
  <svg {...commonProps}>
    <polyline points="6 9 6 2 18 2 18 9" />
    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
    <rect width="12" height="8" x="6" y="14" />
  </svg>
);

export const book = (
  <svg {...commonProps}>
    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
  </svg>
);

export const book_open = (
  <svg {...commonProps}>
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2zM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
  </svg>
);

export const smile = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <path d="M8 14s1.5 2 4 2 4-2 4-2" />
    <line x1="9" x2="9.01" y1="9" y2="9" />
    <line x1="15" x2="15.01" y1="9" y2="9" />
  </svg>
);

export const settings = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="3" />
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
  </svg>
);

export const settings_cam = (
  <svg {...commonProps}>
    <circle cx="17.7" cy="6.3" r="1.8" fill="#ffbf00" className={styles['icon-accent-path']} />
    <path d="M22 4.6v3.9c0 .6-.5 1.1-1.1 1.1h-6.3c-.6 0-1.1-.5-1.1-1.1V4.6c0-.6.5-1.1 1.1-1.1h1.2v-.3c0-.6.5-1.1 1.1-1.1h1.8c.6 0 1.1.5 1.1 1.1v.3H21c.5 0 1 .5 1 1.1z" />
    <g>
      <path d="M13.7 20.3c-.6.9-1.4 1.7-2.3 2.3-.4-1-1.4-1.7-2.5-1.7-1.4 0-2.6 1.1-2.7 2.5-1.1-.3-2.1-.8-2.9-1.5.8-.7 1.2-1.9.8-2.9-.4-1.4-1.8-2.1-3.2-1.8v-.5c0-1 .2-1.9.5-2.7.9.6 2.1.5 3.1-.1 1.2-.8 1.5-2.4.8-3.6.7-.5 1.6-.7 2.5-.7h.6c-.2 1 .1 2.2 1.1 2.9 1.2.8 2.7.6 3.7-.4.7.8 1.2 1.8 1.5 2.9-1.1.1-2 .8-2.4 1.9-.5 1.3.2 2.8 1.4 3.4z" />
      <circle cx="7.8" cy="16.6" r="2.2" />
    </g>
  </svg>
);

export const sun = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="5" />
    <path d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
  </svg>
);

export const git_pull_request = (
  <svg {...commonProps}>
    <circle cx="18" cy="18" r="3" />
    <circle cx="6" cy="6" r="3" />
    <path d="M13 6h3a2 2 0 0 1 2 2v7" />
    <line x1="6" x2="6" y1="9" y2="21" />
  </svg>
);

export const gift = (
  <svg {...commonProps}>
    <path d="M20 12v10H4V12M2 7h20v5H2zM12 22V7M12 7H7.5a2.5 2.5 0 010-5C11 2 12 7 12 7zM12 7h4.5a2.5 2.5 0 000-5C13 2 12 7 12 7z" />
  </svg>
);

export const more_vertical = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);

export const more_horizontal = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="1" />
    <circle cx="19" cy="12" r="1" />
    <circle cx="5" cy="12" r="1" />
  </svg>
);

export const eye = (
  <svg {...commonProps}>
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
    <circle cx="12" cy="12" r="3" />
  </svg>
);

export const eye_off = (
  <svg {...commonProps}>
    <path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22" />
  </svg>
);

export const video = (
  <svg {...commonProps}>
    <path d="M23 7l-7 5 7 5V7z" />
    <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
  </svg>
);
export const video_plus = (
  <svg {...commonProps}>
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="m15 10 4.553-2.276A1 1 0 0 1 21 8.618v6.764a1 1 0 0 1-1.447.894L15 14v-4zM3 8a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zM7 12h4M9 10v4" />
  </svg>
);

export const trello = (
  <svg {...commonProps}>
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
    <path d="M7 7h3v9H7zM14 7h3v5h-3z" />
  </svg>
);

export const target = (
  <svg {...commonProps}>
    <circle cx="12" cy="12" r="10" />
    <circle cx="12" cy="12" r="6" />
    <circle cx="12" cy="12" r="2" />
  </svg>
);

export const tool = (
  <svg {...commonProps}>
    <path d="M14.7 6.3a1 1 0 000 1.4l1.6 1.6a1 1 0 001.4 0l3.77-3.77a6 6 0 01-7.94 7.94l-6.91 6.91a2.12 2.12 0 01-3-3l6.91-6.91a6 6 0 017.94-7.94l-3.76 3.76z" />
  </svg>
);

export const eye_2 = (
  <svg {...commonProps}>
    <g>
      <path
        d="M16.926 10.2a4.509 4.509 0 01-1.312 3.182 4.47 4.47 0 01-6.336 0A4.51 4.51 0 017.965 10.2"
        stroke="#FD8484"
      />
      <path
        d="M12.446 5.7c5.692 0 9.86 6.167 9.86 6.3 0 .132-4.163 6.298-9.86 6.298-5.698 0-10.73-6.197-10.754-6.298-.024-.102 5.061-6.3 10.754-6.3z"
        stroke="#5D5D5D"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(1 5)" d="M0 0h22v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const user_2 = (
  <svg {...commonProps}>
    <path d="M34.872 17.435a17.41 17.41 0 11-1.77-7.662 17.44 17.44 0 011.77 7.662z" fill="#e24b6f" />
    <path
      d="M34.872 17.435a17.44 17.44 0 01-13.727 17.036l-5.83-18.18a7.59 7.59 0 01-.2-.752A8.013 8.013 0 0120.5 6.216l7.732-2.479a17.41 17.41 0 016.643 13.7z"
      fill="#ba3152"
    />
    <path
      d="M34.872 17.435a17.452 17.452 0 01-21.588 16.939L9.897 23.813a6.282 6.282 0 014.064-7.9l1.152-.37 17.989-5.77a17.347 17.347 0 011.77 7.662z"
      fill="#fd6a8c"
    />
  </svg>
);

export const share_2 = (
  <svg {...commonProps}>
    <path d="M13.834 18.304l9.184-8.395-9.184-8.395" stroke="#FD8484" />
    <path
      d="M13.113 6.493a11.267 11.267 0 00-5.327 1.326 12.358 12.358 0 00-4.247 3.749 13.753 13.753 0 00-2.3 5.404 14.346 14.346 0 00.117 5.957c.654-2.86 2.167-5.4 4.297-7.216 2.13-1.816 4.756-2.803 7.46-2.805"
      stroke="#5D5D5D"
    />
  </svg>
);

export const open = (
  <svg {...commonProps}>
    <path
      d="M9.089 1.545H3.232a2.137 2.137 0 00-2.137 2.139v17.081a2.137 2.137 0 002.137 2.139H20.31a2.137 2.137 0 002.137-2.139V14.91"
      stroke="#5D5D5D"
    />
    <path d="M12.754 1.545h9.697v9.697M22.45 1.55L11.772 12.225" stroke="#FD8484" />
  </svg>
);

export const eye_2_check = (
  <svg {...commonProps}>
    <g>
      <path
        d="M16.922 10.202a4.505 4.505 0 01-1.312 3.18 4.469 4.469 0 01-6.333 0 4.505 4.505 0 01-1.311-3.18"
        stroke="#5D5D5D"
      />
      <path
        d="M12.445 5.706c5.69 0 9.852 6.165 9.852 6.294 0 .129-4.162 6.294-9.852 6.294S1.722 12.102 1.698 12c-.025-.102 5.057-6.294 10.747-6.294z"
        stroke="#5D5D5D"
      />
      <path d="M10.518 10.53l1.631 1.639 3.282-3.295" stroke="#FD8484" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(1 5)" d="M0 0h22v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const download_2 = (
  <svg {...commonProps}>
    <path
      d="M2.011 17.204v2.705c0 .547.213 1.072.593 1.458.379.387.893.604 1.43.604h15.938c.536 0 1.05-.217 1.43-.604.379-.386.592-.911.592-1.458v-2.705"
      stroke="#5D5D5D"
    />
    <path d="M16.5 13.48L12 18.07l-4.5-4.59M12 17.204V2.031" stroke="#FD8484" />
  </svg>
);

export const power_toggle = (
  <svg {...commonProps}>
    <path
      d="M7.513 4.394A9.854 9.854 0 003 9.02a9.7 9.7 0 00-.644 6.396 9.791 9.791 0 003.502 5.415A9.99 9.99 0 0012 22.938a9.989 9.989 0 006.142-2.107 9.791 9.791 0 003.502-5.415A9.7 9.7 0 0021 9.02a9.854 9.854 0 00-4.513-4.626"
      stroke="#5D5D5D"
    />
    <path d="M12 12.661V2.073" stroke="#FD8484" />
  </svg>
);

export const quest = (
  <svg {...commonProps}>
    <path
      d="M12.914 2.935a9.21 9.21 0 00-4.118.973A9.128 9.128 0 005.56 6.616a8.996 8.996 0 00-1.386 8.051l-2.232 5.189 5.01-.985a9.168 9.168 0 003.887 1.953c1.435.33 2.93.312 4.357-.054a9.165 9.165 0 003.836-2.047 9.06 9.06 0 002.445-3.575 8.99 8.99 0 00-1.065-8.324 9.116 9.116 0 00-3.268-2.857 9.202 9.202 0 00-4.231-1.032v0z"
      stroke="#5D5D5D"
    />
    <path
      d="M12.933 16.681v-.267M10.743 8.917a2.297 2.297 0 011.11-1.304 2.33 2.33 0 012.72.402 2.276 2.276 0 01.414 2.656c-.263.51-.709.905-1.25 1.105-.235.086-.44.241-.583.445a1.211 1.211 0 00-.223.696v.805"
      stroke="#FD8484"
    />
  </svg>
);

export const circle_2 = (
  <svg {...commonProps}>
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#000" fillOpacity=".3" />
      <circle cx="10" cy="10" r="9" stroke="#fff" strokeOpacity=".8" strokeWidth="2" />
    </g>
  </svg>
);

export const flag = (
  <svg {...commonProps}>
    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7" />
  </svg>
);

export const heart = (
  <svg {...commonProps}>
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
  </svg>
);

export const hour_glass_green = (
  <svg {...commonProps}>
    <path
      strokeWidth="0"
      d="M19.4954 20.9371h-2.3743C17.022 17.0812 15.849 13.6868 14.0434 12c1.8056-1.6866 2.9786-5.08099 3.0777-8.93684h2.3743c.2937 0 .5314-.23802.5314-.5315 0-.29348-.2379-.5315-.5314-.5315L3.5315 2C3.23787 2 3 2.23802 3 2.5315c0 .29348.23786.5315.5315.5315h2.37436c.09914 3.85574 1.27212 7.2504 3.07774 8.9368-1.80566 1.6865-2.97863 5.0814-3.07774 8.9372l-2.37436.0001c-.29363 0-.5315.2379-.5315.5314S3.23786 22 3.5315 22h15.9639c.2937 0 .5314-.238.5314-.5315s-.2379-.5314-.5314-.5314Zm-9.59675-8.5977c.10645-.0804.16895-.2061.16895-.3394 0-.133-.0627-.2587-.16895-.339-1.80105-1.3619-3.03656-4.77445-3.14214-8.59773h9.51359c-.1056 3.82324-1.3411 7.23553-3.1422 8.59773-.106.0802-.1687.2061-.1687.339 0 .1334.0627.2588.1687.3394 1.8011 1.3618 3.0366 4.7741 3.1422 8.5977H6.75651c.10542-3.8236 1.34094-7.2358 3.14214-8.5977Z"
      fill="#516076"
    />
    <path
      strokeWidth="0"
      d="M14.7045 16.048c-.5137-1.4387-1.2253-2.5551-2.0036-3.1436l-.8367-.6327c-.1284-.1518-.1883-.3973.2306-.718l.6058-.4583c.7784-.5887 1.4898-1.70495 2.0036-3.1436.0364-.1032.0718-.20785.1063-.31325-2.0856.30555-4.1712.605-6.2501.9216.48565 1.1442 1.10085 2.03215 1.7656 2.53485l.6051.4577c.4203.3212.3602.5669.2317.719l-.8365.6325c-.77845.5885-1.48985 1.7049-2.0036 3.1437-.45559 1.2768-.73854 2.7408-.83124 4.2862l8.04434-.0001c-.0931-1.5452-.3755-3.0093-.8313-4.2862v.0002Z"
      fill="#1DDF82"
    />
  </svg>
);

export const hour_glass_green_full = (
  <svg {...commonProps}>
    <path
      strokeWidth="0"
      d="M21.3759 21.8309h-2.789c-.1164-4.2415-1.4942-7.9755-3.6151-9.8309 2.1209-1.8553 3.4987-5.58908 3.6151-9.83052h2.789c.3449 0 .6241-.26182.6241-.58465 0-.32284-.2794-.58466-.6241-.58466L2.62431 1C2.2794 1 2 1.26182 2 1.58465s.2794.58465.62431.58465h2.78897c.11645 4.24131 1.49424 7.9754 3.61516 9.8305-2.12096 1.8552-3.49875 5.5895-3.61516 9.8309l-2.78897.0002c-.34491 0-.62431.2616-.62431.5844 0 .3229.2794.5847.62431.5847H21.3759c.3449 0 .6241-.2618.6241-.5847 0-.3228-.2794-.5844-.6241-.5844Zm-11.2726-9.4575c.1249-.0885.1984-.2268.1984-.3733 0-.1464-.0737-.2847-.1984-.373-2.11557-1.4981-3.56682-5.2519-3.69083-9.4575H17.5872c-.124 4.20556-1.5752 7.9591-3.6908 9.4575-.1245.0882-.1982.2268-.1982.373 0 .1467.0737.2846.1982.3733 2.1156 1.4979 3.5668 5.2514 3.6908 9.4575H6.41247c.12382-4.2061 1.57507-7.9595 3.69083-9.4575Z"
      fill="#516076"
    />
    <path
      strokeWidth="0"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.524 11.1932c-.96749-.6491-1.85205-1.88018-2.49067-3.46678C7.46676 6.31824 7.11562 4.70409 7 3.00017L17 3c-.1152 1.70423-.467 3.3186-1.0333 4.7266-.6387 1.58661-1.523 2.8177-2.4907 3.4667l-1.0398.6976c-.0311.0326-.059.0692-.0799.1095.0208.04.0486.0764.0795.1088l1.0402.6976c.9675.649 1.852 1.8802 2.4907 3.4668.5665 1.4082.9177 3.0223 1.0333 4.7262L7 21c.11524-1.7042.46698-3.3186 1.03335-4.7266.63862-1.5866 1.52297-2.8177 2.49065-3.4667l1.0398-.6976c.0311-.0326.059-.0692.0799-.1095-.0208-.04-.0486-.0764-.0795-.1088l-1.0402-.6976Z"
      fill="#1DDF82"
    />
  </svg>
);
export const hour_glass_yellow = (
  <svg {...commonProps}>
    <path
      strokeWidth="0"
      d="M21.3759 21.8309h-2.789c-.1164-4.2415-1.4942-7.9755-3.6151-9.8309 2.1209-1.8553 3.4987-5.58908 3.6151-9.83052h2.789c.3449 0 .6241-.26182.6241-.58465 0-.32284-.2794-.58466-.6241-.58466L2.62431 1C2.2794 1 2 1.26182 2 1.58465s.2794.58465.62431.58465h2.78897c.11645 4.24131 1.49424 7.9754 3.61516 9.8305-2.12096 1.8552-3.49875 5.5895-3.61516 9.8309l-2.78897.0002c-.34491 0-.62431.2616-.62431.5844 0 .3229.2794.5847.62431.5847H21.3759c.3449 0 .6241-.2618.6241-.5847 0-.3228-.2794-.5844-.6241-.5844Zm-11.2726-9.4575c.1249-.0885.1984-.2268.1984-.3733 0-.1464-.0737-.2847-.1984-.373-2.11557-1.4981-3.56682-5.2519-3.69083-9.4575H17.5872c-.124 4.20556-1.5752 7.9591-3.6908 9.4575-.1245.0882-.1982.2268-.1982.373 0 .1467.0737.2846.1982.3733 2.1156 1.4979 3.5668 5.2514 3.6908 9.4575H6.41247c.12382-4.2061 1.57507-7.9595 3.69083-9.4575Z"
      fill="#516076"
    />
    <path
      strokeWidth="0"
      d="M15.9667 16.2736c-.6387-1.5866-1.5232-2.8178-2.4907-3.4668l-1.0402-.6976c-.1596-.1674-.234-.4382.2867-.7918l.7531-.5054c.9677-.6492 1.852-1.88011 2.4906-3.46654.0453-.11382.0893-.22921.1323-.34546-2.5927.33697-5.1853.66719-7.76966 1.01628.60367 1.26178 1.36848 2.24102 2.19476 2.79542l.7523.5046c.5225.3542.4477.6251.2879.7928l-1.0398.6976c-.96768.649-1.85203 1.8801-2.49065 3.4667C7.46698 17.6814 7.11524 19.2958 7 21l10-.0002c-.1156-1.7039-.4668-3.318-1.0333-4.7262Z"
      fill="#FFBC0F"
    />
  </svg>
);
export const hour_glass_red = (
  <svg {...commonProps}>
    <path
      strokeWidth="0"
      d="M21.6 22.72h-2.84c-.12-4.64-1.56-8.68-3.72-10.72 2.16-2.04 3.56-6.08 3.68-10.72h2.88c.36 0 .64-.28.64-.64S21.96 0 21.6 0H2.44005c-.36 0-.64.28-.64.64s.28.64.64.64h2.84c.12 4.64 1.52 8.68 3.72 10.72-2.16 2.04-3.56 6.08-3.68 10.72h-2.84c-.36 0-.64.28-.64.64s.28.64.64.64H21.6c.36 0 .64-.28.64-.64s-.28-.64-.64-.64ZM10.08 12.4c.12-.08.2-.24.2-.4 0-.16-.08-.32-.2-.4C7.92005 9.96 6.44005 5.88 6.32005 1.28H17.72c-.12 4.6-1.6 8.68-3.76 10.32-.12.08-.2.24-.2.4 0 .16.08.32.2.4 2.16 1.64 3.64 5.72 3.76 10.32H6.32005c.12-4.6 1.6-8.68 3.75995-10.32Z"
      fill="#516076"
    />
    <path
      strokeWidth="0"
      d="M15.84 16.84c-.24-.64-.52-1.44-1.08-2.28-.64-1-1.56-2.36-2.68-2.4-1.96-.12-3.52005 3.76-3.88005 4.68-.88 2.12-1 4-1 5.16H16.84c-.04-.8-.12-1.68-.32-2.68-.2-.92-.44-1.76-.68-2.48Z"
      fill="#FF514D"
    />
  </svg>
);
