import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
  DialogTitle,
  COLOR_SHADES,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { CRMPaymentType } from '../CRMPaymentService';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import MESSAGES from '../../../../../../common/Messages';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';

type Props = {
  paymentId: string;
  eventId: string;
  payment: CRMPaymentType;
  isLoading: boolean;
  isDialogShown: boolean;
  toggleDialog: (show: boolean) => void;
  deletePayment: (paymentId: string, eventId: string) => void;
};

export default function CRMPaymentItemDeleteDialog(props: Props): React.ReactElement | null {
  const { eventId, paymentId, payment, isLoading, deletePayment, toggleDialog, isDialogShown } = props;
  if (!isDialogShown) return null;

  const { amount } = payment || {};

  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.CRM_PAYMENT_MORE_OPTIONS(paymentId))}
      show={isDialogShown}
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleDialog(false)}
    >
      <DialogTitle>Delete this payment?</DialogTitle>
      <Text>
        Are you sure you want to delete this payment with amount{' '}
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          <AccountCurrencyContainer amount={amount} />
        </Text>
        ?
      </Text>
      <Space vertical size={6} />
      <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.DANGER} onClick={() => deletePayment(paymentId, eventId)} isLoading={isLoading}>
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
