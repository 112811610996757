import { connect } from 'react-redux';
import { eventAttendeesSelector } from '../EventAttendeesDataDuck';
import UserSocialForm from './UserSocialForm';

function mapStateToProps(
  state,
  ownProps: {
    attendeeId?: string;
  },
) {
  const { attendeeId } = ownProps;
  return {
    attendee: attendeeId ? eventAttendeesSelector(state)[attendeeId] : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSocialForm);
