import { EventAttendeesService } from '@premagic/core';
import { FormGroup, FormLabel, Select } from '@premagic/myne';
import React, { useEffect, useMemo } from 'react';

type Props = {
  values: string[];
  projectId: string;
  getAttendees: (projectId: string) => void;
  attendees: Array<EventAttendeesService.EventAttendeeType>;
  isLoading: boolean;
  allKeyPeople: Array<string>;
};

export function ImageKeyPeopleSelect(props: Props) {
  const { values, projectId, getAttendees, attendees, isLoading, allKeyPeople } = props;
  useEffect(() => {
    if (projectId && attendees.length === 0) {
      getAttendees(projectId);
    }
  }, [attendees.length, getAttendees, projectId]);

  const keyPeopleOptions = useMemo(() => {
    const selectedOptions = values?.map((item) => ({
      value: item,
      label: item,
    }));
    const allKeyPeopleOptions = allKeyPeople.map((item) => ({
      value: item,
      label: item,
    }));
    const attendeesOptions = attendees.map((item) => ({
      value: `ATTENDEE:${item.registration_id}`,
      label: item.name,
    }));
    return [...selectedOptions, ...allKeyPeopleOptions, ...attendeesOptions];
  }, [attendees, values]);

  const keyPeopleValues = useMemo(() => {
    if (!values) return [];
    return values?.map((item) => keyPeopleOptions.find((option) => option.value === item)).filter(Boolean) as Array<{
      value: string;
      label: string;
    }>;
  }, [values, keyPeopleOptions]);

  return (
    <FormGroup>
      <FormLabel>Key People</FormLabel>
      <Select
        isMulti
        isCreatable
        isSearchable
        name="key_people"
        value={keyPeopleValues}
        options={keyPeopleOptions}
        isLoading={isLoading}
      />
    </FormGroup>
  );
}
