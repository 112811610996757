import { connect } from 'react-redux';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';
import { memoryTemplatesSelector } from '../MemoriesTemplatesDataDuck';
import MemoryTemplateForm from './MemoryTemplateForm';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    id?: string;
  },
) {
  const { projectId, id } = ownProps;

  return {
    projectId,
    id,
    template: id ? memoryTemplatesSelector(state)[id] : undefined,
    isStaffUser: isStaffUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoryTemplateForm);
