import { $Keys } from 'utility-types';

import React from 'react';
import { Row, Tabs, TabItem } from '@premagic/myne';
import { CUSTOMER_LEAD_STATUS } from './CustomerLeadsService';

type Props = {
  activeStatus: $Keys<typeof CUSTOMER_LEAD_STATUS>;
  stats: {
    [key: string]: number;
  };
};

export default function CustomerLeadsTabs(props: Props) {
  const { activeStatus, stats } = props;
  return (
    <Tabs>
      {Object.values(CUSTOMER_LEAD_STATUS).map((status) => (
        <TabItem key={status.id} active={activeStatus === status.id} to={`/leads/${status.id}`}>
          <Row vcenter>
            {status.title || status.label} ({stats[status.id] || 0})
          </Row>
        </TabItem>
      ))}
    </Tabs>
  );
}
