import { connect } from 'react-redux';
import SponsorCategoryListItem from './SponsorCategoryListItem';
import { sponsorCategoryItemDataSelector, updateSponsorCategory } from '../data-duck/SponsorCategoryDataDuck';

function mapStateToProps(
  state,
  ownProps: { sponsorCategoryId: string; handleSelectCategory: (sponsorCategoryId: string) => void; active: boolean },
) {
  const { sponsorCategoryId, handleSelectCategory, active } = ownProps;
  return { active, sponsorCategory: sponsorCategoryItemDataSelector(sponsorCategoryId)(state), handleSelectCategory };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSponsorCategory: (sponsorCategoryId, formData) => {
      dispatch(updateSponsorCategory(dispatch, sponsorCategoryId, formData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCategoryListItem);
