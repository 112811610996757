import { EventAttendeesService } from '@premagic/core';

import { Icon, ICON_SIZES, Label, LABEL_STYLES, Space } from '@premagic/myne';
import React from 'react';

const LabelStyleFromStatus = {
  [EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.APPROVED]: {
    color: LABEL_STYLES.PILL_SUCCESS,
  },
  [EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.REJECTED]: {
    color: LABEL_STYLES.PILL_DEFAULT,
  },
  [EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.PENDING]: {
    color: LABEL_STYLES.PILL_INFO,
  },
  [EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.WAITLIST]: {
    color: LABEL_STYLES.PILL_INFO,
  },
};

type Props = {
  attendee: EventAttendeesService.EventAttendeeType;
  id: string;
  showEditAttendee: (id: string) => void;
  editable: boolean;
};

export default function AttendeeRegistrationApprovalButtons(props: Props): JSX.Element | null {
  const { attendee, id, showEditAttendee, editable } = props;
  const { approval: status } = attendee;

  if (!status) return null;

  const statusDetails = EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS_DETAILS[status];

  return (
    <Label style={LabelStyleFromStatus[status].color} onClick={editable ? () => showEditAttendee(id) : undefined}>
      {statusDetails.title}{' '}
      {editable && (
        <>
          <Space size={1} /> <Icon name="edit_2" size={ICON_SIZES.XS} />
        </>
      )}
    </Label>
  );
}
