import React from 'react';

import {
  BUTTON_ICON_STYLES,
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Col,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemAction,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { EventCustomFromFieldsService } from '@premagic/core';
import MESSAGES from '../../../../../common/Messages';
import EventFormFieldOptionsMenuContainer from './dropdown-menu/EventFormFieldOptionsMenuContainer';
import { PERMISSIONS } from '../../crm/users/UserPermissionService';

type Props = {
  formField: EventCustomFromFieldsService.FormFieldType;
  projectId: string;
  id: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_FORM_FIELD]: boolean;
    [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: boolean;
  };
  focus?: boolean;
  showEditFormField: (id: string) => void;
};

export default function UserRegistrationFormFieldItem(props: Props): JSX.Element {
  const { formField, id, projectId, hasPermissions, focus, showEditFormField } = props;
  const { label, type, is_required: isRequired, name, visibility } = formField;
  const hasPermissionsToManageFormFields = hasPermissions[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT];
  const isHiddenField = visibility === false;
  return (
    <ListItem highlight={focus}>
      <Col>
        <Text color={isHiddenField ? COLOR_SHADES.LIGHT : COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
          {label}
          {isRequired && (
            <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DANGER}>
              *
            </Text>
          )}
        </Text>
        <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
          {EventCustomFromFieldsService.FORM_FIELDS_TYPE_DETAILS[type].name}
        </Text>
        {isHiddenField && (
          <>
            <Space size={2} />
            {HTML_ICON.DOT}
            <Space size={2} />
            <Text color={COLOR_SHADES.PINK} boldness={TEXT_BOLDNESS.BOLD}>
              HIDDEN
            </Text>
          </>
        )}
      </Col>

      <ListItemAction>
        <ButtonIcon
          disabled={!hasPermissionsToManageFormFields}
          disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]}
          style={BUTTON_ICON_STYLES.SECONDARY}
          size={BUTTON_ICONS_SIZES.SM}
          title="Edit"
          onClick={() => showEditFormField(id)}
        >
          <Icon name="edit_2" size={ICON_SIZES.SM} />
        </ButtonIcon>

        <Space size={2} />
        <EventFormFieldOptionsMenuContainer formFieldId={id} projectId={projectId} />
      </ListItemAction>
    </ListItem>
  );
}
