import { ColorPicker, Row, Space, TEXT_BOLDNESS, TEXT_SIZE, Text } from '@premagic/myne';

type QRCodeColorPickerProps = {
  name: string;
  title: string;
  defaultValue?: string;
  onChange: (value: string) => void;
};

// will use this for bg color as well
export default function QRCodeColorPicker(props: QRCodeColorPickerProps) {
  const { name, title, defaultValue, onChange } = props;

  return (
    <Row columnDirection vcenter center>
      <ColorPicker name={name} defaultValue={defaultValue} onChange={onChange} />
      <Space vertical size={2} />
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} size={TEXT_SIZE.SIZE_5}>
        {title}
      </Text>
    </Row>
  );
}
