import { ClientWebsiteService } from '@premagic/core';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  ErrorBoundary,
  EscapedParagraphHTMLText,
  Icon,
  ICON_SIZES,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { isArray } from 'lodash';
import React from 'react';
import EventAttendeesNamesContainer from '../../crm/events/event-attendees/EventAttendeesNamesContainer';
import ClientWebsiteItineraryOptionsMenuContainer from './ClientWebsiteItineraryOptionsMenuContainer';

type ItineraryItemActionsProps = {
  websiteId: string;
  projectId: string;
  editItinerary: (id: number) => void;
  id: number;
};

function ItineraryItemActions(props: ItineraryItemActionsProps) {
  const { websiteId, projectId, editItinerary, id } = props;
  return (
    <ListItemAction>
      <Button onClick={() => editItinerary(id)} size={BUTTON_SIZE.SM} style={BUTTON_STYLES.LINK}>
        <Icon name="edit_2" size={ICON_SIZES.SM} />
        <Space size={1} />
        Edit
      </Button>
      <Space size={1} />
      <ClientWebsiteItineraryOptionsMenuContainer itineraryId={id} websiteId={websiteId} projectId={projectId} />
    </ListItemAction>
  );
}

type Props = {
  websiteId: string;
  projectId: string;
  editItinerary: (id: number) => void;
  itinerary: ClientWebsiteService.ClientWebsiteItinerary;
};

export default function ClientWebsiteItineraryItem(props: Props): React.ReactElement {
  const { websiteId, projectId, itinerary, editItinerary } = props;

  return (
    <ErrorBoundary>
      <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
        <Col size={12}>
          <Row>
            <Col size={8}>
              <Text boldness={TEXT_BOLDNESS.BOLD} block size={TEXT_SIZE.SIZE_4}>
                {itinerary.name}
              </Text>
            </Col>
            <ItineraryItemActions
              websiteId={websiteId}
              projectId={projectId}
              id={itinerary.id}
              editItinerary={editItinerary}
            />
          </Row>
          {/* Array check is temp, Anas was saying the speaker as string when there is only item, we can delete this check in Jan 2025 */}
          {itinerary.speakers?.length && (
            <Text block size={TEXT_SIZE.SIZE_5}>
              <EventAttendeesNamesContainer
                attendeeIds={isArray(itinerary.speakers) ? itinerary.speakers : [itinerary.speakers]}
              />
            </Text>
          )}
          {itinerary.time_not_decided ? (
            <Text block muted size={TEXT_SIZE.SIZE_5}>
              Time to be announced
            </Text>
          ) : (
            <Text block size={TEXT_SIZE.SIZE_5}>
              {SimpleDateUtils.humanizeDateRage(itinerary.start_date_time, itinerary.end_date_time, {
                showOnlyTime: true,
                dateTimezone: itinerary.date_timezone,
              })}
            </Text>
          )}
          <Text size={TEXT_SIZE.SIZE_5}>
            {itinerary.venue.location_link ? (
              <SimpleLink href={itinerary.venue.location_link} isExternalLink>
                {itinerary.venue.location}
              </SimpleLink>
            ) : (
              itinerary.venue.location
            )}
          </Text>
          <Text block size={TEXT_SIZE.SIZE_6}>
            <EscapedParagraphHTMLText value={itinerary.details} />
          </Text>
        </Col>
      </ListItem>
    </ErrorBoundary>
  );
}
