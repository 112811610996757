import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { PERMISSIONS } from './UserPermissionService';
import { usersKeysSelector, fetchUsersData, hasPermission } from './UsersDataDuck';
import UsersPage from './UsersPage';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.USERS.FETCH)(state),
    users: usersKeysSelector(state),
    hasPermissionToManageUsers: hasPermission(PERMISSIONS.USER_MANAGEMENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchUsersData(dispatch));
    },
    showAddNewUserPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.USERS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
