import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { FormErrorType, FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, BrowserUrlUtils, i18n } from '@premagic/utils';
import {
  ClientSettingsService,
  ClientsService,
  ClientWebsiteFormService,
  ClientWebsiteService,
  CompanyLeadService,
  EventsCategoryService,
  EventsService,
  EventTrackerService,
  FolderMetaService,
  FolderService,
  PricingPlanFeaturesService,
  ProjectMetaService,
  ProjectService,
  Schemas,
} from '@premagic/core';

import { isArray } from 'lodash';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';

import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { CRMClientSchema, CRMEventSchema } from '../../../../../schema/Schemas';
import { addCRMClientsData } from '../../clients/ClientsDataDuck';
import APP_URLS from '../../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../../services/RouterService';
import { addEventsData } from '../EventsDataDuck';
import { setProjectsData } from '../../../projects/AccountProjectsDataDuck';
import { getDefaultDataForInvite } from '../CreateEventService';
import { saveFolderMetaData } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { addClientWebsiteData } from '../../../client-website/ClientWebsiteDataDuck';
import { saveProjectMetaData } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { fetchCompanyInfo } from '../../../settings/company/CompanyService';
import { addSystemCustomFormField } from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_CREATE', true);

export type EventWithClientFormData = {
  errors?: FormErrorType;
  data: {
    name: string;
    phone_number: string;
    email: string;
    channel: CompanyLeadService.LEAD_CHANNELS;
    source: CompanyLeadService.LEAD_SOURCE;
    address: string;
    event: EventsService.EventDataType & {
      name?: EventsService.EVENT_CATEGORY | string;
      status?: EventsService.EVENT_STATUS;
    };
    project: {
      name: string | Array<string>;
    };
  };
};

export const addEventWithClientData = createAction(
  getActionType('ADD', 'EVENT'),
  async (
    dispatch: Dispatch,
    formResponse: EventWithClientFormData,
    options: {
      onEventCreationSuccess?: ({ eventId, projectId }) => void;
      isCompanyTypeNotPhotographer?: boolean;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT.ADD;
    const { errors, data } = formResponse;
    const { onEventCreationSuccess, isCompanyTypeNotPhotographer } = options || {};
    dispatch(clearErrorState(dispatch, loadingKey));

    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    // here name and phone_number refers to the host
    const { name, phone_number: phoneNumber, email, event: eventData, source, channel, address } = data;

    const { name: eventNameOrCategory, status, ...restEventData } = eventData;
    const eventDataToStoreInProjectMeta = {
      ...restEventData,
      location_map_link: `https://www.google.co.in/maps/place/${eventData.location || 'San Francisco'}/`,
    };

    const projectMetaDataToSave: Partial<ProjectMetaService.ProjectMetaType> = {};

    try {
      /* 1. Create Client
         2. Create Event with client id from step 1
         3. Create Project with event id from step 2, Create project only if the event.status is CONFIRMED
         4. Create Client website, Create project only if the event.status is CONFIRMED
      */

      // 1. Create Client
      const client = await ClientsService.createClient({
        name,
        phone_number: phoneNumber,
        email,
        source,
        channel,
        address,
      });
      const normalizedData = normalize(client, CRMClientSchema);
      dispatch(addCRMClientsData(dispatch, normalizedData.entities.clients));

      // 2. Create Event
      const projectName = ProjectService.getProjectNameFromName(data.project.name);
      const event = await EventsService.createEvent(client.id, {
        name: eventData.name || projectName,
        status: eventData.status,
      });
      const { id: eventId, name: eventName } = event;

      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM.EVENT_CREATE, {
        name: eventName,
        eventId,
        clientId: client.id,
      });
      const normalizedEventData = normalize(event, CRMEventSchema);
      dispatch(addEventsData(dispatch, normalizedEventData.entities.events));

      if (eventData.status === EventsService.EVENT_STATUS.CONFIRMED) {
        // 3. Create Project
        const project = await ProjectService.createProject({
          project_name: projectName,
          event_id: eventId,
          event_name: eventName,
        });
        const { project_id: projectId } = project;
        const normalizedProjectData = normalize(project, Schemas.ProjectSchema);
        dispatch(setProjectsData(dispatch, normalizedProjectData.entities.projects));

        // 4. Create Client website
        const groomName = isArray(data.project.name) ? data.project.name[0] : data.project.name;
        const brideName = isArray(data.project.name) ? data.project.name[1] : undefined;
        const websiteToCreate = getDefaultDataForInvite({
          eventName,
          groom_name: groomName,
          bride_name: brideName,
        });
        const website = await ClientWebsiteService.createClientWebsite(projectId, websiteToCreate);
        // 4.1 Publish the website
        await FolderService.shareFolderWithClientService(projectId, [website.id], {
          clients: [
            {
              name,
              email,
              phone_number: phoneNumber || '',
            },
          ],
          skip_notifications: true,
        });

        // Save events data to project meta
        Object.assign(projectMetaDataToSave, {
          [ProjectMetaService.PROJECT_META_TYPES.EVENT_DATA]: eventDataToStoreInProjectMeta,
        });

        // save folder meta
        if (!EventsCategoryService.isWeddingEvent(eventName)) {
          dispatch(
            saveFolderMetaData(dispatch, projectId, website.id, {
              data: {
                // 4.2. Disabled wish
                [FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES]: true,
              },
            }),
          );
        }

        // set notification config for non wedding events
        if (!EventsCategoryService.isWeddingEvent(eventName)) {
          Object.assign(projectMetaDataToSave, {
            [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION]:
              ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
            [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED]:
              ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
            // set other configs
            [ProjectMetaService.PROJECT_META_TYPES.AI_CONTENT_GENERATOR_FOR_ATTENDEE]: true,
          });
        }

        //  set classic layout for event organizers and planers
        if (isCompanyTypeNotPhotographer) {
          Object.assign(projectMetaDataToSave, {
            [ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT]:
              ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC,
          });
        }

        dispatch(addClientWebsiteData(dispatch, website));

        // Set folder thumbnail view to grid by default
        const company = await fetchCompanyInfo();
        const clientSettings = await ClientSettingsService.fetchClientSettings();
        const isThumbnailCustomStyleFeatureEnabled = company?.currentPricingPlan?.feature_list?.includes(
          PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS,
        );
        const folderThumbnailStyle =
          clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW];

        if (!folderThumbnailStyle && isThumbnailCustomStyleFeatureEnabled) {
          Object.assign(projectMetaDataToSave, {
            [ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW]:
              ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD,
            [ProjectMetaService.PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED]: true,
          });
        }

        // save project meta
        dispatch(
          saveProjectMetaData(
            dispatch,
            projectId,
            {
              data: projectMetaDataToSave as unknown as Record<string, any> & ProjectMetaService.ProjectMetaType,
            },
            {},
          ),
        );

        // add system custom form fields
        if (!EventsCategoryService.isWeddingEvent(eventData.name)) {
          dispatch(addSystemCustomFormField(dispatch, projectId));
        }

        if (onEventCreationSuccess) {
          onEventCreationSuccess({ eventId, projectId });
        }
      }

      // Navigate to Details and show the user to add a new occasion
      const eventDetailsPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
        eventId: event.id,
      });
      navigateTo(dispatch, eventDetailsPage);

      if (EventsCategoryService.isWeddingEvent(eventData.name)) {
        // Show add occasion panel if its wedding event
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_ADD_PANEL, true));
      }

      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(toggleWindowPanelVisibility(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    }
  },
);

const initialState = {};

export default handleActions({}, initialState);
