import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';

import ProposalCreatorDeleteMenuItem from './ProposalCreatorDeleteDialog';

const DIALOG_KEY = ActionConstants.KEYS.PROPOSAL.SHOW_DELETE_DIALOG;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    isSaving: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL.DELETE(folderId))(state),
    showDialog: ModalDuck.isModalOpenSelector(DIALOG_KEY)(state),
    proposal: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeProposal: (projectId, folderId, backToUrl) => {
      dispatch(ProposalDataDuck.removeProposal(dispatch, projectId, folderId, { backToUrl }));
    },
    toggleDialog: (show) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_DELETE_DIALOG, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorDeleteMenuItem);
