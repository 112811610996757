import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum LEAD_CHANNELS {
  HIGHLIGHT = 'HIGHLIGHT',
  WEBSITE = 'WEBSITE',
  PORTFOLIO = 'PORTFOLIO',
  CRM = 'CRM',
}
export const LEAD_CHANNEL_DETAILS: Record<LEAD_CHANNELS, { title: string }> = {
  [LEAD_CHANNELS.WEBSITE]: {
    title: 'Website',
  },
  [LEAD_CHANNELS.HIGHLIGHT]: {
    title: 'Signature album',
  },
  [LEAD_CHANNELS.PORTFOLIO]: {
    title: 'Premagic Portfolio',
  },
  [LEAD_CHANNELS.CRM]: {
    title: 'CRM',
  },
};

export enum LEAD_SOURCE {
  WEBSITE = 'WEBSITE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  WED_ME_GOOD = 'WED_ME_GOOD',
  WEDDING_BAZAAR = 'WEDDING_BAZAAR',
  WEDDING_WIRE = 'WEDDING_WIRE',
  FRIENDS = 'FRIEND',
  FAMILY = 'FAMILY',
  OTHER = 'OTHER',
}

export const LEAD_SOURCE_DETAILS: Record<LEAD_SOURCE, { title: string }> = {
  [LEAD_SOURCE.WEBSITE]: {
    title: 'Website',
  },
  [LEAD_SOURCE.FACEBOOK]: {
    title: 'Facebook',
  },
  [LEAD_SOURCE.INSTAGRAM]: {
    title: 'Instagram',
  },
  [LEAD_SOURCE.TWITTER]: {
    title: 'Twitter',
  },
  [LEAD_SOURCE.WED_ME_GOOD]: {
    title: 'WedMeGood',
  },
  [LEAD_SOURCE.WEDDING_BAZAAR]: {
    title: 'WeddingBazaar',
  },
  [LEAD_SOURCE.WEDDING_WIRE]: {
    title: 'Wedding wire',
  },
  [LEAD_SOURCE.YOUTUBE]: {
    title: 'Youtube',
  },
  [LEAD_SOURCE.FRIENDS]: {
    title: 'Friends',
  },
  [LEAD_SOURCE.FAMILY]: {
    title: 'Family/Relative',
  },
  [LEAD_SOURCE.OTHER]: {
    title: 'Other',
  },
};

type OccasionType = Array<{
  name: string;
  start_date_time: string;
  end_date_time: string;
  location: string;
  number_of_guests: number;
}>;

export type NewLeadType = {
  name: string;
  email: string;
  phone: string;
  notes: string;
  source: LEAD_SOURCE;
  channel: LEAD_CHANNELS;
  event_type: string;

  occasions: OccasionType;
};

function formatLeadData(data) {
  const {
    channel,
    email,
    event_type: eventType,
    name,
    notes,
    occasions,
    other_occasions: otherOccasions,
    phone,
    source,
  } = data;
  let occasionsArray: OccasionType = [];
  const selectedOccasions = Object.keys(otherOccasions).filter((key) => otherOccasions[key]);
  if (selectedOccasions.length) {
    occasionsArray = selectedOccasions.map((occasionName) => {
      const occasionDetails = {
        end_date_time: occasions[0].end_date_time,
        location: occasions[0].location,
        name: occasionName,
        number_of_guests: occasions[0].number_of_guests,
        start_date_time: occasions[0].start_date_time,
      };
      return occasionDetails;
    });
  }
  const formData = {
    channel,
    email,
    event_type: eventType,
    name,
    notes,
    occasions: occasionsArray.length ? occasionsArray : occasions,
    phone,
    source,
  };
  return formData;
}

export function addLeadViaSignature(shareId: string, data: NewLeadType) {
  const body = formatLeadData(data);
  return HttpUtils.post(API_URLS.CUSTOMER_LEAD.CREATE_FROM_SHARE_ID(shareId), body);
}

export function addLead(data: NewLeadType) {
  const body = formatLeadData(data);
  return HttpUtils.post(API_URLS.CUSTOMER_LEAD.CREATE_PUBLIC, body);
}
