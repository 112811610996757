import React, { useRef, useState } from 'react';
import { Dialog, DIALOG_PLACEMENT, ErrorBoundary, Icon, Label, Row, Space, Text, TEXT_SIZE } from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { ACCOUNT_FINANCIALS_FILTER_KEYS, AccountFinancialsFiltersType } from './AccountFinancialsFilterTypes';
import { ACCOUNT_FINANCIALS_FILTERS_DETAILS } from './AccountFinancialsFilterConstants';
import AccountFinancialsFilterItemsDialogContent from './AccountFinancialsFilterItemsDialogContent';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';

type CRMFiltersItemContentProps = {
  filterKey: ACCOUNT_FINANCIALS_FILTER_KEYS;
  value?: string;
};
const EMPTY_VALUE = '(not set)';

function AccountFinancialsFiltersItemContent(props: CRMFiltersItemContentProps): JSX.Element {
  const { filterKey, value = EMPTY_VALUE } = props;
  const { label } = ACCOUNT_FINANCIALS_FILTERS_DETAILS[filterKey];
  switch (filterKey) {
    case ACCOUNT_FINANCIALS_FILTER_KEYS.START_DATE_GTE:
      return (
        <>
          {label}: <Space size={2} />
          <Text size={TEXT_SIZE.SIZE_4}>
            {value
              ? SimpleDateUtils.getDateStringFromDate(value, SimpleDateUtils.STANDARD_DATE_FORMATS.MONTH)
              : '(not set)'}
          </Text>
        </>
      );
    default:
      return (
        <>
          {label}: {value}
        </>
      );
  }
}

type AccountFinancialsFiltersItemProps = {
  filterKey: ACCOUNT_FINANCIALS_FILTER_KEYS;
  value?: string;
  onRemove: (filterKey: ACCOUNT_FINANCIALS_FILTER_KEYS) => void;
  onChange: (newFilterValue: string, slice: string) => void;
};

function AccountFinancialsFiltersItem(props: AccountFinancialsFiltersItemProps): JSX.Element {
  const { filterKey, value, onRemove, onChange } = props;
  const [showDialog, setShowDialog] = useState(false);
  const DialogContentForFilter = AccountFinancialsFilterItemsDialogContent[filterKey];

  const target = useRef(null);
  return (
    <span ref={target}>
      <ErrorBoundary>
        <Label onRemove={() => onRemove(filterKey)} onClick={() => setShowDialog(true)}>
          <AccountFinancialsFiltersItemContent filterKey={filterKey} value={value} />
        </Label>
        <Space />
        {showDialog && (
          <Dialog
            target={target.current}
            show={showDialog}
            placement={DIALOG_PLACEMENT.AUTO}
            onClose={() => setShowDialog(false)}
          >
            <Space size={2}>
              <DialogContentForFilter
                value={value}
                onChange={(newFilterValue, slice) => {
                  onChange(newFilterValue, slice);
                  setShowDialog(false);
                }}
              />
            </Space>
          </Dialog>
        )}
      </ErrorBoundary>
    </span>
  );
}

type Props = {
  filters: AccountFinancialsFiltersType;
  type: ACCOUNT_FINANCIALS_TYPES;
  navigate: (type: ACCOUNT_FINANCIALS_TYPES, filters: AccountFinancialsFiltersType) => void;
  removeFilter: (
    type: ACCOUNT_FINANCIALS_TYPES,
    filterToRemove: ACCOUNT_FINANCIALS_FILTER_KEYS,
    filters: AccountFinancialsFiltersType,
  ) => void;
};

export function AccountFinancialsFilters(props: Props): JSX.Element {
  const { filters = {}, navigate, type, removeFilter } = props;

  function navigateWithNewFilter(newFilter: AccountFinancialsFiltersType) {
    navigate(type, {
      ...filters,
      ...newFilter,
    });
  }

  const FILTERS_TO_SHOW = [ACCOUNT_FINANCIALS_FILTER_KEYS.START_DATE_GTE];

  return (
    <Row vcenter>
      <Space size={1} />
      <Icon name="filter" /> <Space />
      {FILTERS_TO_SHOW.map((filterKey) => (
        <AccountFinancialsFiltersItem
          key={filterKey}
          filterKey={filterKey}
          value={filters[filterKey]}
          onRemove={(filterKeyToRemove) => removeFilter(type, filterKeyToRemove, filters)}
          onChange={(date, slice) => navigateWithNewFilter({ [filterKey]: date, slice })}
        />
      ))}
    </Row>
  );
}
