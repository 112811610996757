import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Form,
  FormError,
  FormErrorType,
  FormFooter,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { useEffect, useState } from 'react';
import SponsorWatermarkFormContainer from './form/SponsorWatermarkFormContainer';

type Props = {
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
  addSponsorCreativeWatermark: (projectId: string, data) => void;
  clearSponsorModalOptions: () => void;
};

export default function AddSponsorWatermarkPanel(props: Props) {
  const { show, close, projectId, isLoading, error, addSponsorCreativeWatermark, clearSponsorModalOptions } = props;

  // @todo remove this state. this is temporary, will mark 'watermark_folder_ids' as required in the formSpec, once the formSpec function is fixed
  const [formErrorMessage, setFormErrorMessage] = useState('');

  function handleAdd(formResponse) {
    if (!formResponse.data.watermark_folder_ids) {
      setFormErrorMessage('Please select folders');
      return;
    }
    addSponsorCreativeWatermark(projectId, formResponse);
    clearSponsorModalOptions();
  }

  useEffect(() => () => setFormErrorMessage(''), []);

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader
        onClose={() => {
          close();
          clearSponsorModalOptions();
        }}
      >
        <WindowPanelTitle>Add watermark</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          onSubmit={handleAdd}
          formSpec={{
            sponsor_id: {
              validator: 'required',
            },
            // will uncomment it once the formSpec function is fixed.
            // watermark_folder_ids: {
            //   validator: 'required',
            // },
            watermark_opacity: {
              type: 'number',
            },
            watermark_size: {
              type: 'number',
            },
            watermark_remove_on_download: {
              type: 'boolean',
            },
          }}
        >
          {formErrorMessage && <FormError>{formErrorMessage}</FormError>}
          <SponsorWatermarkFormContainer projectId={projectId} />
          <FormFooter>
            <Button
              style={BUTTON_STYLES.CANCEL}
              onClick={() => {
                close();
                clearSponsorModalOptions();
              }}
              size={BUTTON_SIZE.LG}
            >
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
