import { FontService } from '@premagic/core';
import { INPUT_SIZES, LoadingDots, Select, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { FontUtils } from '@premagic/utils';
import { useEffect, useMemo, useState } from 'react';
import { createFilter } from 'react-select';

type Props = {
  fontItems: Array<FontUtils.GoogleFontType>;
  initFontData: () => void;
  onChange?: (value: { fontFamily: string; fontSource: string }) => void;
  defaultValue?: string;
  isLoading: boolean;
  isFontFetchExecuted: boolean;
  disabled?: boolean;
  name: string;
  nameSource: string;
};

export default function FontSelector(props: Props) {
  const {
    fontItems,
    initFontData,
    onChange,
    defaultValue = 'Playfair Display',
    isLoading,
    isFontFetchExecuted,
    disabled,
    name,
    nameSource,
  } = props;

  const fontOptions = useMemo(
    () =>
      fontItems.map((item) => ({
        label: item.family,
        value: item.family,
        style: { fontFamily: item.family },
        inView: () => {
          FontService.loadGoogleFonts([item.family], { text: item.family });
        },
      })),
    [fontItems],
  );

  const [selectedValue, setsSelectedValue] = useState<{ label: string; value: string }>({
    label: defaultValue as string,
    value: defaultValue as string,
  });

  useEffect(() => {
    setsSelectedValue({
      label: defaultValue as string,
      value: defaultValue as string,
    });
  }, [defaultValue]);

  return (
    <div>
      <Select
        isMulti={false}
        name={name}
        options={fontOptions}
        value={selectedValue}
        size={INPUT_SIZES.DEFAULT}
        onChange={(option) => {
          setsSelectedValue({
            label: option?.value as string,
            value: option?.value as string,
          });
          if (onChange)
            onChange({
              fontFamily: option?.value as string,
              fontSource: 'google',
            });
        }}
        isLoading={isLoading}
        onMenuOpen={() => {
          if (!isFontFetchExecuted) {
            initFontData();
          }
        }}
        disabled={disabled}
        filterOption={createFilter({ ignoreAccents: false })}
      />
      <input type="hidden" value="google" name={nameSource} />
    </div>
  );
}
