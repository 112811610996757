import { EventAttendeesService, ProjectMetaService } from '@premagic/core';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Dialog,
  Icon,
  ICON_SIZES,
  List,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import React, { useRef, useState } from 'react';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../../services/AppRouteURLService';
import EventFeedbackButtonContainer from '../../event-feedback/event-feedback-button/EventFeedbackButtonContainer';
import ReportIssueButtonContainer from '../../report-issues/report-issue-button/ReportIssueButtonContainer';
import EventAttendeeBadgeButtonContainer from '../event-attendee-badges/button/EventAttendeeBadgeButtonContainer';

type ApplicationFormButtonProps = {
  projectId: string;
  clientWebsiteId: string;
};
function ApplicationFormButton(props: ApplicationFormButtonProps) {
  const { projectId, clientWebsiteId } = props;
  const url = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___REGISTRATION, {
    projectId,
    id: clientWebsiteId,
  });

  return (
    <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} link={url}>
      <Icon name="align_left" size={ICON_SIZES.SM} /> <Space size={1} /> Registration form
    </Button>
  );
}

type PhotoRegisterLinkProps = {
  registrationLink?: string;
};

function PhotoRegisterLink(props: PhotoRegisterLinkProps) {
  const { registrationLink } = props;
  if (!registrationLink) return null;

  return (
    <Button link={registrationLink} style={BUTTON_STYLES.LINK} isExternalLink>
      <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} /> Open Photo Registration Link
    </Button>
  );
}

type RegisterLinkProps = {
  registrationLink?: string;
};

function RegisterLink(props: RegisterLinkProps) {
  const { registrationLink } = props;
  const [showList, setShowList] = useState(false);
  const $button = useRef();

  if (!registrationLink) return null;

  return (
    <div>
      <Button ref={$button} onClick={() => setShowList(!showList)} style={BUTTON_STYLES.LINK} active={showList}>
        <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} /> Open Register Link
      </Button>
      {$button.current && (
        <Dialog show={showList} target={$button.current} onClose={() => setShowList(false)}>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Registration Links for each user type</Text>
          <Space vertical size={2} />
          <List>
            {Object.entries(EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS).map(([key, item]) => (
              <div key={key}>
                <Button link={`${registrationLink}${key}`} style={BUTTON_STYLES.LINK} isExternalLink>
                  <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} />
                  {item.name}
                </Button>
                <Space vertical size={2} />
              </div>
            ))}
          </List>
        </Dialog>
      )}
    </div>
  );
}

type Props = {
  eventId: string;
  projectId: string;
  clientWebsiteId?: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  registrationLink?: string;
};

export default function EventAttendeeReportSummaryCard(props: Props): JSX.Element {
  const { eventId, projectMeta, projectId, clientWebsiteId = 'wont-happen-1', registrationLink } = props;

  return (
    <>
      <Card>
        <Space>
          {projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED] ? (
            <RegisterLink registrationLink={registrationLink} />
          ) : (
            <PhotoRegisterLink registrationLink={registrationLink} />
          )}
        </Space>
      </Card>
      <Card>
        <Space>
          <ApplicationFormButton projectId={projectId} clientWebsiteId={clientWebsiteId} />
          <Space vertical />

          <ReportIssueButtonContainer eventId={eventId} />
          <Space vertical />
          <EventAttendeeBadgeButtonContainer eventId={eventId} projectId={projectId} />
          <Space vertical />
          <EventFeedbackButtonContainer eventId={eventId} />
        </Space>
      </Card>
    </>
  );
}
