import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';
import ClientWebsiteCard from './ClientWebsiteCard';
import { clientWebsitesSelector } from '../../../client-website/ClientWebsiteDataDuck';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import { crmEventItemsSelector } from '../../events/EventsDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { setFolderIdsToShare } from '../../../folder-share/FolderShareDuck';
import { setEditOfClientWebsite } from '../../events/details/EventDetailsPageDuck';
import { projectEventDataSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { eventId?: string; id: string; projectId: string; focus: boolean }) {
  const { id, projectId, focus, eventId } = ownProps;

  return {
    event: eventId ? crmEventItemsSelector(state)[eventId] : undefined,
    folder: foldersEntitiesSelector(state)[id],
    clientWebsite: clientWebsitesSelector(state)[id],
    folderId: id,
    projectId,
    focus,
    eventData: projectEventDataSelector(projectId)(state),
    isSaving: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSharePanel: (folderId) => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.FOLDER_SHARE.SHARE, true));
      dispatch(setFolderIdsToShare(dispatch, [folderId]));
    },
    showEditOfClientWebsite: (folderId) => {
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_QUICK_EDIT_PANEL, true),
      );
      dispatch(setEditOfClientWebsite(dispatch, folderId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteCard);
