import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  Grid,
  Icon,
  ICON_SIZES,
  List,
  ListItem,
  LoadingDots,
  Space,
  Text,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import AddMemoryTemplatePanelContainer from './create/AddMemoryTemplatePanelContainer';
import EditMemoryTemplatePanelContainer from './create/EditMemoryTemplatePanelContainer';
import MemoriesTemplatesListItemContainer from './MemoriesTemplatesListItemContainer';

type Props = {
  projectId: string;
  eventId: string;
  focusId?: string;
  initData: (projectId: string) => void;
  templateIds: Array<string>;
  isLoading: boolean;
  showAddNewTemplate: () => void;
};

export default function MemoriesTemplatesList(props: Props): React.ReactElement {
  const { projectId, initData, templateIds, eventId, focusId, isLoading, showAddNewTemplate } = props;

  useEffect(() => {
    if (projectId) initData(projectId);
  }, [initData, projectId]);

  if (isLoading) {
    return (
      <List>
        <ListItem>
          <LoadingDots size="sm" />
        </ListItem>
      </List>
    );
  }

  if (templateIds.length === 0) {
    return (
      <div>
        <Card size={CARD_SIZES.BOX_MD} onClick={showAddNewTemplate}>
          <Space>
            <Space vertical size={25} />
            <Text muted block center>
              You haven&apos;t added any templates
            </Text>
            <Space vertical size={8} />
            <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} block>
              <Icon name="plus" size={ICON_SIZES.SM} />
              <Space size={1} />
              Template
            </Button>
          </Space>
        </Card>

        <AddMemoryTemplatePanelContainer projectId={projectId} eventId={eventId} />
      </div>
    );
  }
  return (
    <div>
      <Grid colSize={200}>
        {templateIds.map((id) => (
          <div key={id}>
            <MemoriesTemplatesListItemContainer id={id} eventId={eventId} projectId={projectId} focusId={focusId} />
          </div>
        ))}
      </Grid>
      <AddMemoryTemplatePanelContainer projectId={projectId} eventId={eventId} />
      <EditMemoryTemplatePanelContainer projectId={projectId} eventId={eventId} />
    </div>
  );
}
