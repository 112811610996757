import { connect } from 'react-redux';
import CRMPaymentItemDeleteDialog from './CRMPaymentItemDeleteDialog';
import { LOADINGS } from '../../../../../../common/Constants';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { crmPaymentsSelector, removePayment } from '../CRMPaymentDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { paymentIdForDeleteSelector } from '../../events/details/EventDetailsPageDuck';

const DIALOG_KEY = LOADINGS.CRM_PAYMENTS.SHOW_DELETE_DIALOG;

function mapStateToProps(state, ownProps: { eventId: string }) {
  const paymentId = paymentIdForDeleteSelector(state);
  return {
    eventId: ownProps.eventId,
    paymentId,
    payment: crmPaymentsSelector(state)[paymentId],
    isLoading: isLoadingSelector(LOADINGS.CRM_PAYMENTS.DELETE)(state),
    isDialogShown: isModalOpenSelector(DIALOG_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
    deletePayment: (paymentId: string, eventId: string) => {
      dispatch(removePayment(dispatch, paymentId, eventId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMPaymentItemDeleteDialog);
