import { BUTTON_SIZE, BUTTON_STYLES, Button, COLOR_SHADES, Color, ICON_SIZES, Icon, Row, Space } from '@premagic/myne';
import { useState } from 'react';
import AddEditSponsorCategoryFormContainer from './AddEditSponsorCategoryFormContainer';

type Props = { isLoading?: boolean; createSponsorCategory: (formResponse) => void };

export default function AddSponsorCategoryButton(props: Props) {
  const { isLoading, createSponsorCategory } = props;
  const [showAddSponsorCategoryForm, setShowAddSponsorCategoryForm] = useState(false);

  function handleSponsorCategoryAdd(formResponse) {
    createSponsorCategory(formResponse);
    setShowAddSponsorCategoryForm(false);
  }

  if (showAddSponsorCategoryForm) {
    return <AddEditSponsorCategoryFormContainer onSave={handleSponsorCategoryAdd} />;
  }

  return (
    <Row vcenter>
      <Color inline shade={COLOR_SHADES.BLUE_LIGHT}>
        <Icon name="plus" size={ICON_SIZES.SM} />
      </Color>
      <Space size={1} />
      <Button
        size={BUTTON_SIZE.SM}
        style={BUTTON_STYLES.LINK}
        isLoading={isLoading}
        onClick={() => setShowAddSponsorCategoryForm(true)}
      >
        Add category
      </Button>
    </Row>
  );
}
