import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  Input,
} from '@premagic/myne';
import { FolderMetaService, FontPaletteService } from '@premagic/core';

type Props = {
  isLoading: boolean;
  errors?: FormErrorType;
  createFolder: (name: string, folderMeta: FolderMetaService.FolderMetaType) => void;
  currencyCode: string;
};

const defaultFontPalette = FontPaletteService.FONT_PALETTES_DETAILS[FontPaletteService.FONT_PALETTES.PLAYFAIR];

export default function AddProposalTemplate(props: Props): JSX.Element | null {
  const { isLoading, errors, createFolder, currencyCode } = props;
  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);

  return (
    <>
      <Button active={showDialog} style={BUTTON_STYLES.PRIMARY} ref={target} onClick={() => setShowDialog(!showDialog)}>
        Add Proposal Template
      </Button>
      <Dialog target={target.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Form
          onSubmit={(response) =>
            createFolder(response.data.name as string, {
              [FolderMetaService.FOLDER_META_TYPES.CURRENCY_CODE]: currencyCode,
              [FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT]: defaultFontPalette.primary,
              [FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT]: defaultFontPalette.secondary,
            })
          }
          errors={errors}
        >
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <Input name="name" required autoFocus placeholder="eg. Wedding Proposal" />
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
