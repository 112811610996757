import { connect } from 'react-redux';
import { EventPosterService } from '@premagic/core';
import EventPosterSwitcher from './EventPosterSwitcher';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    activePosterId?: string;
    type: EventPosterService.EVENT_POSTER_TYPE;
  },
) {
  const { eventId, projectId, activePosterId, type } = ownProps;

  return {
    eventId,
    projectId,
    activePosterId,
    type,
  };
}

export default connect(mapStateToProps)(EventPosterSwitcher);
