import React, { useState } from 'react';
import {
  COLOR_SHADES,
  Col,
  Color,
  FormGroup,
  FormLabel,
  ICON_SIZES,
  Icon,
  Input,
  Row,
  Select,
  Space,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { ProposalVariableService } from '@premagic/proposals';
import InputCurrencyContainer from '../../acccount/InputCurrencyContainer';

type Props = {
  variable?: ProposalVariableService.ProposalVariableType | null;
  scope: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE;
  disableNameInput?: boolean;
};

export default function ProposalVariableForm(props: Props): JSX.Element {
  const { variable, scope: defaultScope, disableNameInput } = props;
  const { type, name, scope, default_value: defaultValue } = variable || {};
  const selectedScope = scope || defaultScope;

  const details = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE_DETAILS[selectedScope];
  const defaultType = type || details.types[0];
  const variableTypes = details.types.map((id) => ({
    value: id,
    label: ProposalVariableService.PROPOSAL_VARIABLE_TYPE_DETAILS[id].name,
  }));
  const defaultSelectedType = variableTypes.find((option) => option.value === defaultType);

  const [selectedType, setSelectedType]: [any, any] = useState(defaultSelectedType);

  return (
    <div>
      <input type="hidden" name="scope" value={selectedScope} />
      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input
          name="name"
          placeholder={details.addPlaceholder}
          autoFocus
          required
          defaultValue={name}
          disabled={disableNameInput}
        />
      </FormGroup>

      {details.types.length > 1 ? (
        <FormGroup>
          <FormLabel isRequired>Type</FormLabel>
          <Col>
            <Select
              options={variableTypes}
              value={selectedType}
              name="type"
              isMulti={false}
              onChange={(val) => setSelectedType(val)}
            />
          </Col>
        </FormGroup>
      ) : (
        <input type="hidden" name="type" value={defaultSelectedType?.value} />
      )}
      {selectedType?.value === 'number' && (
        <FormGroup>
          <FormLabel isRequired>Cost of 1 unit</FormLabel>
          <Row>
            <Color shade={COLOR_SHADES.LIGHT} inline>
              <Icon name="info" size={ICON_SIZES.SM_XS} align="top" alignSize={2} />
            </Color>
            <Space size={1} />
            <Text size={TEXT_SIZE.SIZE_5} muted>
              This value will be used to calculate the total cost of this item and displayed on the package slide
            </Text>
          </Row>
          <Space size={1} />
          <Col size={3}>
            <InputCurrencyContainer name="default_value" defaultValue={Number(defaultValue)} />
          </Col>
        </FormGroup>
      )}
    </div>
  );
}
