import React from 'react';
import ClassNames from 'classnames';
import { SimpleImage } from '../Media';
import styles from './emojie.module.css';

/*
get unicodes from https://raw.githubusercontent.com/ealush/emoji-picker-react/master/src/data/emojis.json
https://stackblitz.com/edit/emoji-picker-react?file=index.js

https://github.com/iamcal/emoji-data
https://www.jsdelivr.com/package/npm/emoji-datasource-apple?path=img%2Fapple
*/
const EMOJIS_URL = 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/';

export enum EMOJIS {
  SKULL,
  PARTYING_FACE,
  MONKEY_EYE_CLOSED,
  SMILING_FACE_WITH_MOUTH_OPEN,
  SMILING_FACE_NEUTRAL_FACE,
  SMILING_FACE_ANGER,
  FIRE,
  CRY,
  POINTING_RIGHT,
  POINTING_LEFT,
  PROHIBITED,
  WAVE,
  SAD,
}

// Get the codes from https://unpkg.com/emoji.json@13.0.0/emoji.json
const ITEMS: Record<EMOJIS, { icon?: string; name: string; unified: string }> = {
  [EMOJIS.SKULL]: {
    icon: '💀',
    name: 'Skull',
    unified: '1f480',
  },
  [EMOJIS.WAVE]: {
    icon: '👋',
    name: 'waving hand',
    unified: '1f44b',
  },
  [EMOJIS.PARTYING_FACE]: {
    icon: '🥳',
    name: 'Party face',
    unified: '1f973',
  },
  [EMOJIS.MONKEY_EYE_CLOSED]: {
    icon: '🙈',
    name: 'Monkey eye closed',
    unified: '1f648',
  },
  [EMOJIS.SMILING_FACE_WITH_MOUTH_OPEN]: {
    name: 'Smiling face with open mouth',
    unified: '1f603',
  },
  [EMOJIS.SMILING_FACE_NEUTRAL_FACE]: {
    name: 'Neutral face',
    unified: '1f610',
  },
  [EMOJIS.SMILING_FACE_ANGER]: {
    name: 'Angry',
    unified: '1f621',
  },
  [EMOJIS.FIRE]: {
    name: 'Fire',
    unified: '1f525',
  },
  [EMOJIS.CRY]: {
    name: 'Cry',
    unified: '1f622',
  },
  [EMOJIS.POINTING_RIGHT]: {
    name: 'Pointing finger right',
    icon: '👉',
    unified: '1f449',
  },
  [EMOJIS.POINTING_LEFT]: {
    name: 'Pointing finger left',
    icon: '👈',
    unified: '1f448',
  },
  [EMOJIS.PROHIBITED]: {
    name: 'Prohibited',
    icon: '🚫',
    unified: '1f6ab',
  },
  [EMOJIS.SAD]: {
    name: 'frowning face',
    icon: '🚫',
    unified: '2639-fe0f',
  },
};

export enum EMOJIE_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XLG = 'xlg',
}

type Props = {
  name: EMOJIS;
  size?: EMOJIE_SIZE;
};

export function Emoji(props: Props): JSX.Element | null {
  const { name, size = EMOJIE_SIZE.SM } = props;
  const item = ITEMS[name];
  if (!item) return null;
  const { name: emojiName, unified } = item;

  return (
    <SimpleImage
      className={ClassNames(styles.emoji, styles[`emoji--size-${size}`])}
      aria-label={emojiName}
      alt={emojiName}
      src={`${EMOJIS_URL}${unified}.png`}
    />
  );
}
