import { FileService, ProjectMetaService } from '@premagic/core';
import { MediaUtils, useElementSize } from '@premagic/utils';
import classNames from 'classnames';
import { Card, CARD_STYLES } from '../Card/Cards';
import { DEVICE_SIZES, Iphone } from '../Devices/Devices';
import { Col, Row } from '../Grid';
import { MediaCardsGallery, MEDIA_CARDS_GALLERY_STYLES } from '../Media';
import { Section, SECTION_STYLES } from '../Page';
import { TabSecondaryItem, TabsSecondary } from '../Tabs/Tabs';
import styles from './highlight-gallery-design-preview.module.css';

enum GALLERY_DESIGN_DEVICE_TYPE {
  DESKTOP = 'DESKTOP',
  IPHONE = 'IPHONE',
}

function getRowHeightForFolderThumbnail(deviceType, folderThumbnailView) {
  if (deviceType === GALLERY_DESIGN_DEVICE_TYPE.IPHONE) {
    if (folderThumbnailView === MEDIA_CARDS_GALLERY_STYLES.GRID_CARD) {
      return 100;
    }
    if (folderThumbnailView === MEDIA_CARDS_GALLERY_STYLES.LANDSCAPE_CARD) {
      return 40;
    }
  } else {
    if (folderThumbnailView === MEDIA_CARDS_GALLERY_STYLES.GRID_CARD) {
      return 180;
    }
    if (folderThumbnailView === MEDIA_CARDS_GALLERY_STYLES.LANDSCAPE_CARD) {
      return 200;
    }
  }

  return 180;
}

function getRowHeightForDummyImagesLayout(deviceType, thumbnailSize) {
  if (deviceType === GALLERY_DESIGN_DEVICE_TYPE.IPHONE) {
    const ROW_HEIGHT_MAP = {
      [ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT]: 70,
      [ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.SMALL]: 50,
      [ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.LARGE]: 145,
    };

    return ROW_HEIGHT_MAP[thumbnailSize];
  }

  const ROW_HEIGHT_MAP = {
    [ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT]: 180,
    [ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.SMALL]: 130,
    [ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.LARGE]: 210,
  };

  return ROW_HEIGHT_MAP[thumbnailSize];
}

function getBoxSpacingForDummyImagesLayout(deviceType, gridSpace) {
  if (deviceType === GALLERY_DESIGN_DEVICE_TYPE.IPHONE) {
    const BOX_SPACING_MAP = {
      1: 5,
      3: 15,
    };

    return BOX_SPACING_MAP[gridSpace];
  }

  const BOX_SPACING_MAP = {
    1: 10,
    3: 35,
  };

  return BOX_SPACING_MAP[gridSpace];
}

type JustifiedGalleryWithDummyImagesProps = {
  appWidth: number;
  containerPadding: number;
  gridSpace?: number;
  thumbnailSize?: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
  deviceType?: GALLERY_DESIGN_DEVICE_TYPE;
};

function JustifiedGalleryWithDummyImages(props: JustifiedGalleryWithDummyImagesProps) {
  const {
    appWidth,
    containerPadding,
    gridSpace = 1,
    thumbnailSize = ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT,
    deviceType = GALLERY_DESIGN_DEVICE_TYPE.DESKTOP,
  } = props;

  const dummyImageAspectRatios = [
    1.2, 1.5, 0.8, 1.8, 0.9, 2, 0.9, 1.2, 1.7, 2, 2.1, 1.5, 1.8, 0.4, 0.7, 1.4, 1.3, 2, 2.2, 3, 1.1, 0.6,
  ];

  const imagesGeometry = MediaUtils.getJustifiedLayoutPositionForImages(dummyImageAspectRatios, {
    containerWidth: appWidth,
    containerPadding,
    targetRowHeightTolerance: 0.25,
    targetRowHeight: getRowHeightForDummyImagesLayout(deviceType, thumbnailSize),
    boxSpacing: getBoxSpacingForDummyImagesLayout(deviceType, gridSpace),
  });

  const dummyImages = imagesGeometry.boxes.map((box, idx) => {
    const key = `dummy-${idx}`;
    return (
      <div
        key={key}
        className={classNames(styles[`gallery-design-dummy-image`])}
        style={{
          width: `${box.width}px`,
          height: `${box.height}px`,
          top: `${box.top}px`,
          left: `${box.left}px`,
        }}
      />
    );
  });

  return <div style={{ height: `${imagesGeometry.containerHeight}px` }}>{dummyImages}</div>;
}

type FolderThumbnailsPreviewProps = {
  folderItems: Array<{
    file: FileService.FolderFileType;
    title: string;
    id: string;
    focalPoint: { x: number; y: number };
  }>;
  containerWidth: number;
  folderThumbnailView: MEDIA_CARDS_GALLERY_STYLES;
  isfolderThumbnailViewEnabled: boolean;
  deviceType?: GALLERY_DESIGN_DEVICE_TYPE;
};

export default function FolderThumbnailsPreview(props: FolderThumbnailsPreviewProps) {
  const {
    folderItems,
    containerWidth,
    isfolderThumbnailViewEnabled,
    folderThumbnailView,
    deviceType = GALLERY_DESIGN_DEVICE_TYPE.DESKTOP,
  } = props;

  if (!isfolderThumbnailViewEnabled) {
    return (
      <TabsSecondary fromTheme>
        {folderItems.map((item, i) => (
          <TabSecondaryItem onClick={() => null} active={i === 0} fromTheme key={item.title}>
            {item.title}
          </TabSecondaryItem>
        ))}
      </TabsSecondary>
    );
  }
  return (
    <Section hasPadding={false} style={SECTION_STYLES.SECONDARY}>
      <Row center>
        <MediaCardsGallery
          items={folderItems}
          containerWidth={containerWidth}
          onClick={() => null}
          style={folderThumbnailView}
          targetRowHeight={getRowHeightForFolderThumbnail(deviceType, folderThumbnailView)}
        />
      </Row>
    </Section>
  );
}

type GalleryPreviewIphoneProps = {
  folderItems: Array<{
    file: FileService.FolderFileType;
    title: string;
    id: string;
    focalPoint: { x: number; y: number };
  }>;
  isfolderThumbnailViewEnabled: boolean;
  folderThumbnailView: MEDIA_CARDS_GALLERY_STYLES;
  gridSpace?: number;
  thumbnailSize?: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
};

function GalleryPreviewIphone(props: GalleryPreviewIphoneProps) {
  const { folderItems, isfolderThumbnailViewEnabled, folderThumbnailView, gridSpace, thumbnailSize } = props;
  const [$ref, size] = useElementSize();
  return (
    <div className={classNames(styles[`gallery-design-layout-iphone`])}>
      <Iphone size={DEVICE_SIZES.SM}>
        <div className={classNames(styles[`gallery-design-iphone-preview`])} ref={$ref}>
          <div className={classNames(styles[`gallery-design-iphone-preview__folder-thumbnails`])}>
            <FolderThumbnailsPreview
              folderItems={folderItems}
              containerWidth={size.width}
              isfolderThumbnailViewEnabled={isfolderThumbnailViewEnabled}
              folderThumbnailView={folderThumbnailView}
              deviceType={GALLERY_DESIGN_DEVICE_TYPE.IPHONE}
            />
          </div>
          <div className={classNames(styles[`gallery-design-iphone-preview__justified-gallery`])}>
            <JustifiedGalleryWithDummyImages
              appWidth={size.width}
              containerPadding={10}
              gridSpace={gridSpace}
              thumbnailSize={thumbnailSize}
              deviceType={GALLERY_DESIGN_DEVICE_TYPE.IPHONE}
            />
          </div>
        </div>
      </Iphone>
    </div>
  );
}

type GalleryPreviewDesktopProps = {
  folderItems: Array<{
    file: FileService.FolderFileType;
    title: string;
    id: string;
    focalPoint: { x: number; y: number };
  }>;
  isfolderThumbnailViewEnabled: boolean;
  folderThumbnailView: MEDIA_CARDS_GALLERY_STYLES;
  gridSpace?: number;
  thumbnailSize?: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
};

function GalleryPreviewDesktop(props: GalleryPreviewDesktopProps) {
  const { folderItems, isfolderThumbnailViewEnabled, folderThumbnailView, gridSpace, thumbnailSize } = props;

  const [$ref, size] = useElementSize();

  return (
    <div className={classNames(styles[`gallery-design-layout-desktop`])}>
      <Card style={CARD_STYLES.TERTIARY}>
        <div className={classNames(styles[`gallery-design-desktop-preview`])} ref={$ref}>
          <div className={classNames(styles[`gallery-design-desktop-preview__folder-thumbnails`])}>
            <FolderThumbnailsPreview
              folderItems={folderItems}
              containerWidth={size.width - 270}
              isfolderThumbnailViewEnabled={isfolderThumbnailViewEnabled}
              folderThumbnailView={folderThumbnailView}
              deviceType={GALLERY_DESIGN_DEVICE_TYPE.DESKTOP}
            />
          </div>
          <div className={classNames(styles[`gallery-design-desktop-preview__justified-gallery`])}>
            <JustifiedGalleryWithDummyImages
              appWidth={size.width}
              containerPadding={10}
              gridSpace={gridSpace}
              thumbnailSize={thumbnailSize}
              deviceType={GALLERY_DESIGN_DEVICE_TYPE.DESKTOP}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

type HighlightGalleryDesignPreviewProps = {
  folderItems: Array<{
    file: FileService.FolderFileType;
    title: string;
    id: string;
    focalPoint: { x: number; y: number };
  }>;
  isfolderThumbnailViewEnabled: boolean;
  folderThumbnailView: MEDIA_CARDS_GALLERY_STYLES;
  gridSpace?: number;
  thumbnailSize?: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
};

export function HighlightGalleryDesignPreview(props: HighlightGalleryDesignPreviewProps) {
  const { folderItems, isfolderThumbnailViewEnabled, folderThumbnailView, gridSpace, thumbnailSize } = props;

  const classes = classNames(styles[`gallery-design-preview`]);

  return (
    <div className={classes}>
      <Col size={11}>
        <GalleryPreviewDesktop
          folderItems={folderItems}
          isfolderThumbnailViewEnabled={isfolderThumbnailViewEnabled}
          folderThumbnailView={folderThumbnailView}
          gridSpace={gridSpace}
          thumbnailSize={thumbnailSize}
        />
      </Col>
      <GalleryPreviewIphone
        folderItems={folderItems}
        isfolderThumbnailViewEnabled={isfolderThumbnailViewEnabled}
        folderThumbnailView={folderThumbnailView}
        gridSpace={gridSpace}
        thumbnailSize={thumbnailSize}
      />
    </div>
  );
}
