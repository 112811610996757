import { FormResponseType } from '@premagic/myne';
import { connect } from 'react-redux';
import { sponsorCategoryItemDataSelector } from '../data-duck/SponsorCategoryDataDuck';
import AddEditSponsorCategoryForm from './AddEditSponsorCategoryForm';

function mapStateToProps(
  state,
  ownProps: { sponsorCategoryId?: string; onSave: (formResponse: FormResponseType) => void },
) {
  const { onSave, sponsorCategoryId } = ownProps;
  return {
    onSave,
    sponsorCategory: sponsorCategoryId ? sponsorCategoryItemDataSelector(sponsorCategoryId)(state) : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSponsorCategoryForm);
