import React from 'react';
import {
  InnerPageHeader,
  InnerPage,
  InnerPageContent,
  InnerPageSubHeader,
  Tabs,
  TabItem,
  InnerPageContentMainCol,
} from '@premagic/myne';
import { Route, Switch } from 'react-router-dom';
import APP_URLS from '../../../services/AppRouteURLService';
import StorePricingDefaultSettingsContainer from './StorePricingDefaultSettingsContainer';

type Props = {
  currentUrlPathname: string;
};

export default function StorePricingPage(props: Props) {
  const { currentUrlPathname } = props;
  const STORE_PRICING_URLS = APP_URLS.SETTINGS.STORE_PRICING;

  return (
    <InnerPage>
      <InnerPageHeader iconName="shopping_cart" title="Store Pricing" backTo={APP_URLS.SETTINGS.INDEX}>
        Store Pricing
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem to={STORE_PRICING_URLS.DEFAULT} active={currentUrlPathname.includes(STORE_PRICING_URLS.DEFAULT)}>
            Default
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
      <InnerPageContent hasInnerCols>
        <InnerPageContentMainCol>
          <Switch>
            <Route path={STORE_PRICING_URLS.DEFAULT} component={StorePricingDefaultSettingsContainer} />
          </Switch>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
