import { connect } from 'react-redux';
import AccountFinancialsPaymentsTable from './AccountFinancialsPaymentsTable';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import {
  accountFinancialsPaymentItemsSelector,
  fetchAccountFinancialPaymentsData,
} from '../AccountFinancialsPaymentsDataDuck';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';
import { navigateTo } from '../../../../../services/RouterService';
import { paymentCategoriesSelector } from '../../crm/payments/CRMPaymentCategoryDataDuck';

function mapStateToProps(state, ownProps: { filters: string; type: ACCOUNT_FINANCIALS_TYPES }) {
  const { filters, type } = ownProps;

  return {
    type,
    filters,
    items: accountFinancialsPaymentItemsSelector(state),
    isLoading: isLoadingSelector(LOADINGS.ACCOUNT_FINANCIALS.LIST)(state),
    categories: paymentCategoriesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (type, filters) => {
      dispatch(fetchAccountFinancialPaymentsData(dispatch, type, filters));
    },
    navigateToEventPage: (eventPageUrl) => {
      navigateTo(dispatch, eventPageUrl);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinancialsPaymentsTable);
