import { Group, Layer, Rect, Stage } from 'react-konva';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { KeyboardUtils } from '@premagic/utils';
import { SELECTOR_KEYS } from '../services/SelectorService';
import PosterContext from './PosterContext';
import Ruler from '../widgets/Ruler';
import { PosterZoomButtons } from '../components/PosterZoomButtons';

type Props = {
  children: React.ReactNode;
  config: {
    width: number;
    height: number;
  };
  containerSize: {
    width: number;
    height: number;
  };
  resetActiveWidget: () => void;
  showContextMenu: (position: { top: string; left: string }) => void;
  showRuler?: boolean;
  options?: Record<string, string | Array<string>>;
};

export default function PosterCreatorContainer(props: Props) {
  const { containerSize, children, config, resetActiveWidget, showContextMenu, showRuler, options } = props;
  const { width, height } = config;
  const [cursorStyle, setCursorStyle] = useState('default');
  const [scaleForScreen, setScale] = useState(1);
  const [keyPressed, setKeyPressed] = useState<KeyboardUtils.KEYS>();
  const [boxPosition, setBoxPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const buffer = 64;
    const widthWithBuffer = width + buffer;
    const heightWithBuffer = height + buffer;
    const shouldScale = Math.min(containerSize.width / widthWithBuffer, containerSize.height / heightWithBuffer);
    setScale(shouldScale < 1 ? shouldScale : 1);
  }, [containerSize.height, containerSize.width, height, width]);

  // Center the box based on the window dimensions
  useEffect(() => {
    const widthScale = width * scaleForScreen; // 1200 * 0.3433333333333333 = 412
    const heightScale = height * scaleForScreen; // 1200 * 0.3433333333333333 = 412
    const centerX = (containerSize.width - widthScale) / 2;
    const centerY = (containerSize.height - heightScale) / 2;
    setBoxPosition({ x: centerX, y: centerY });
  }, [scaleForScreen, containerSize.width, containerSize.height, width, height]);

  const $stage = useRef(null);
  const context = useMemo(
    () => ({
      setCursor: (cursor: string) => {
        setCursorStyle(cursor);
      },
      options,
    }),
    [options],
  );

  function checkDeselect(e) {
    const isClickedOnBackgroundImage = e.target.attrs.id === 'BACKGROUND_POSTER';
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty || isClickedOnBackgroundImage) {
      resetActiveWidget();
    }
  }

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      // ignore when the event is inside the input
      const target = e.target as HTMLElement;
      if (target.tagName === 'INPUT') return;

      const DELTA = 4;
      let x = 0;
      let y = 0;
      const key = e.key as KeyboardUtils.KEYS;
      switch (key) {
        case KeyboardUtils.KEYS.SPACE:
          setKeyPressed(KeyboardUtils.KEYS.SPACE);
          setCursorStyle('grab');
          e.preventDefault();
          break;
        case KeyboardUtils.KEYS.DOWN:
          y = DELTA;
          break;
        case KeyboardUtils.KEYS.UP:
          y = -DELTA;
          break;
        case KeyboardUtils.KEYS.LEFT:
          x = -DELTA;
          break;
        case KeyboardUtils.KEYS.RIGHT:
          x = DELTA;
          break;
        case KeyboardUtils.KEYS.ESC:
          resetActiveWidget();
          e.preventDefault();
          break;
        default:
          break;
      }
    }

    const container = window.document;

    container.addEventListener('keydown', handleKeyDown);
    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [resetActiveWidget]);

  // function onMouseWheel(e) {
  //   if (!e.evt.metaKey) return; // Only zoom when the user presses the cmd key
  //   e.evt.preventDefault();
  //   e.evt.stopPropagation();
  //   const SCALE_BY = 1.1;
  //   const pointer = ($stage?.current as any)?.getPointerPosition();

  //   const mousePointTo = {
  //     x: (pointer.x - scaleForScreen) / scaleForScreen,
  //     y: (pointer.y - scaleForScreen) / scaleForScreen,
  //   };

  //   // how to scale? Zoom in? Or zoom out?
  //   let direction = e.evt.deltaY > 0 ? 1 : -1;

  //   // when we zoom on trackpad, e.evt.ctrlKey is true
  //   // in that case lets revert direction
  //   if (e.evt.ctrlKey) {
  //     direction = -direction;
  //   }

  //   const newScale = direction > 0 ? scaleForScreen * SCALE_BY : scaleForScreen / SCALE_BY;
  //   setScale(newScale);

  //   const newPos = {
  //     x: pointer.x - mousePointTo.x * newScale,
  //     y: pointer.y - mousePointTo.y * newScale,
  //   };

  //   // Add animation to zooming
  //   ($stage?.current as any).to({
  //     scaleX: newScale,
  //     scaleY: newScale,
  //     x: newPos.x,
  //     y: newPos.y,
  //     duration: 0.2, // Adjust the duration as needed
  //   });
  // }

  return (
    <PosterContext.Provider value={context}>
      <Stage
        // scale={{ // the widget snaping fails after this, will need to fix pxiel snaping, I had given a try
        //   x: scaleForScreen,
        //   y: scaleForScreen,
        // }}
        ref={$stage}
        width={containerSize.width}
        height={containerSize.height}
        captureAllKeyEvents={false}
        className="js-poster"
        style={{
          cursor: cursorStyle,
        }}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
        onContextMenu={(e) => {
          e.evt.preventDefault();
          e.evt.stopPropagation();
          if ($stage.current && showContextMenu) {
            const target = ($stage?.current as any).getPointerPosition() as { x: number; y: number };

            const scaledTargetX = target.x;
            const scaledTargetY = target.y;

            const clientRect = ($stage?.current as any).container().getBoundingClientRect();
            const top = clientRect.top + scaledTargetY + 4;
            const left = clientRect.left + scaledTargetX + 4;

            showContextMenu({
              top: `${top}px`,
              left: `${left}px`,
            });
          }
        }}
        // onWheel={onMouseWheel}
      >
        <Layer>
          <Group
            x={boxPosition.x}
            y={boxPosition.y}
            scale={{
              // the widget snaping fails after this, will need to fix pxiel snaping, I had given a try
              x: scaleForScreen,
              y: scaleForScreen,
            }}
            draggable={keyPressed === KeyboardUtils.KEYS.SPACE}
            onDragEnd={() => {
              setKeyPressed(undefined);
              setCursorStyle('default');
            }}
          >
            <Rect
              name="page-background"
              stroke="rgba(0,0,0,0.2)"
              strokeWidth={0.5}
              shadowBlur={10}
              shadowColor="rgba(0,0,0,0.2)"
              shadowOffset={{
                x: 0,
                y: 4,
              }}
              fill="#fff"
              x={0}
              y={0}
              width={width}
              height={height}
            />
            <Group name={SELECTOR_KEYS.EXPORT_NODE} clipX={0} clipY={0} clipWidth={width} clipHeight={height}>
              {children}
            </Group>
          </Group>
        </Layer>
        {showRuler && <Ruler width={containerSize.width} height={30} direction="horizontal" scale={scaleForScreen} />}
        {showRuler && <Ruler height={containerSize.height} width={30} direction="vertical" scale={scaleForScreen} />}
      </Stage>
      <PosterZoomButtons scale={scaleForScreen} setScale={(s) => setScale(s)} containerSize={containerSize} />
    </PosterContext.Provider>
  );
}
