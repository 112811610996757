import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import CRMOccasionFormContainer from '../form/CRMOccasionFormContainer';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  addOccasion: (eventId: string, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  eventId: string;
  hasActiveSubscription: boolean;
};

export default function AddOccasionPanel(props: Props): JSX.Element {
  const { show, addOccasion, isLoading, errors, closePanel, eventId, hasActiveSubscription } = props;
  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.LG}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Add Occasion for the event</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={errors} onSubmit={(data) => addOccasion(eventId, data)}>
          <CRMOccasionFormContainer eventId={eventId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button
              size={BUTTON_SIZE.LG}
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!hasActiveSubscription}
              disabledMessage={MESSAGES.SUBSCRIPTIONS.INACTIVE}
            >
              Add Occasion
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
