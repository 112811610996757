import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import {
  companyLogoSelector,
  hasFeatureSelector,
  photographerNameSelector,
} from '../../../settings/company/CompanyDataDuck';
import HighlightDesignCoverLayoutSelector from './HighlightDesignCoverLayoutSelector';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (layout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS) => void;
    coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
    imageSource?: string;
    alt?: string;
    title?: string;
  },
) {
  const { onChange, coverLayout, imageSource, alt, title } = ownProps;
  return {
    onChange,
    coverLayout,
    imageSource,
    alt,
    title,
    isLayoutSelectorEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
    companyLogo: companyLogoSelector(state),
    companyName: photographerNameSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignCoverLayoutSelector);
