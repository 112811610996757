function getActionKey(scope: string) {
  return (action: string) => `@${scope}/${action}`;
}

export const ACTION_KEYS = {
  AUTH: {
    LOGOUT: getActionKey('auth')('logout'),
    SIGNUP: getActionKey('auth')('signup'),
    SIGNUP_CONFIRM: getActionKey('signup')('confirm'),
    RESET_PASSWORD: getActionKey('reset')('pasword'),
    SET_PASSWORD: getActionKey('set')('pasword'),
    LOGIN: getActionKey('auth')('login'),
    UPDATE: getActionKey('auth')('update'),
    CHANGE_PASSWORD: getActionKey('change')('password'),
  },
  TOAST: getActionKey('toast')('toggle'),
};
