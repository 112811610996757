import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DialogTitle,
  FormFooter,
  Icon,
  ICON_SIZES,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  projectId: string;
  isLoading: boolean;
  hasCopyFromHighlightFeature: boolean;
  cloneFromHighlight: (projectId: string) => void;
  signatureFolderIds: Array<string>;
};

export default function CloneHighlightFolderToSelectionAlbumButton(props: Props): JSX.Element | null {
  const { projectId, isLoading, hasCopyFromHighlightFeature, cloneFromHighlight, signatureFolderIds } = props;
  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);
  return (
    <>
      <Button
        active={showDialog}
        style={BUTTON_STYLES.LINK}
        size={BUTTON_SIZE.SM}
        ref={target}
        onClick={() => setShowDialog(!showDialog)}
      >
        <Icon name="copy" size={ICON_SIZES.SM} />
        <Space size={1} />
        from {MESSAGES.SIGNATURE_FOLDER.NAME}
      </Button>
      <Dialog target={target.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Copy from {MESSAGES.SIGNATURE_FOLDER.NAME}?</DialogTitle>
        <Text>{MESSAGES.SELECTION_FOLDER.COPY_FROM_SIGNATURE_ALBUM}</Text>
        {signatureFolderIds.length > 0 ? (
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              onClick={() => cloneFromHighlight(projectId)}
              disabled={!hasCopyFromHighlightFeature}
              disabledMessage={MESSAGES.SELECTION_FOLDER.COPY_FROM_SIGNATURE_ALBUM_UPGRADE}
            >
              Copy
            </Button>
          </FormFooter>
        ) : (
          <div>
            <Space vertical />
            <Text color={COLOR_SHADES.WARNING} boldness={TEXT_BOLDNESS.BOLD}>
              {MESSAGES.SELECTION_FOLDER.COPY_FROM_SIGNATURE_ALBUM_ZERO_STATE}
            </Text>
            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL}>Okay</Button>
            </FormFooter>
          </div>
        )}
      </Dialog>
    </>
  );
}
