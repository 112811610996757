import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  FormErrorType,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
} from '@premagic/myne';
import { EventTrackerService, FolderMetaService } from '@premagic/core';

function FeatureLabel(props: { itemKey: FolderMetaService.FOLDER_META_TYPES; primaryStyle?: boolean }) {
  const { itemKey, primaryStyle } = props;
  const { label, icon } = FolderMetaService.FOLDER_META_TOGGLE_ITEMS_DETAILS[itemKey];
  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef(null);
  return (
    <div>
      <Button
        size={BUTTON_SIZE.SM}
        style={BUTTON_STYLES.RESET}
        onClick={() => setShowDialog(!showDialog)}
        onMouseEnter={() => setShowDialog(true)}
        onMouseLeave={() => setShowDialog(false)}
        ref={$button}
      >
        <Row vcenter>
          <Color shade={primaryStyle ? COLOR_SHADES.YELLOW : COLOR_SHADES.LIGHT} inline fillSolidIcon={primaryStyle}>
            <Icon name={icon} size={ICON_SIZES.SM} /> <Space size={1} />
          </Color>
          <Text boldness={TEXT_BOLDNESS.BOLD}>{label}</Text>
        </Row>
      </Button>
    </div>
  );
}

function FeatureToggle(props: {
  hasFeature?: boolean;
  isLoading: boolean;
  name: string;
  checked: boolean;
  updateFolderMeta: (projectId: string, folderId: string, data: FormResponseType) => void;
  projectId: string;
  folderId: string;
}) {
  const { hasFeature = true, isLoading, name, checked, updateFolderMeta, projectId, folderId } = props;
  return (
    <Row vcenter columnDirection>
      <ToggleSwitch
        disabled={!hasFeature}
        isLoading={isLoading}
        name={name}
        checked={checked}
        onChange={(value) => {
          EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.SETTING_TOGGLE(name), {
            enabled: value,
          });
          updateFolderMeta(projectId, folderId, {
            data: {
              [name]: name === FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES ? !value : value,
            },
          });
        }}
      />
      <Space size={1} vertical />
      {checked ? (
        <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
          Yes
        </Text>
      ) : (
        <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
          No
        </Text>
      )}
    </Row>
  );
}

type Props = {
  projectId: string;
  folderId: string;
  folderMeta?: FolderMetaService.FolderMetaType;
  updateFolderMeta: (projectId: string, folderId: string, data: FormResponseType) => void;
  errors?: FormErrorType;
  isLoading: boolean;
  itemKey: FolderMetaService.FOLDER_META_TYPES;
};

export default function FolderMetaToggle(props: Props) {
  const { projectId, folderId, folderMeta, updateFolderMeta, errors, isLoading, itemKey } = props;
  const { key } = FolderMetaService.FOLDER_META_TOGGLE_ITEMS_DETAILS[itemKey];
  const toggleValue = (folderMeta && folderMeta[key]) || false;

  return (
    <Row>
      <FeatureLabel itemKey={itemKey} />
      <Space vertical size={1} />
      <Col size={2} rightAlighed>
        <FeatureToggle
          isLoading={isLoading}
          name={key}
          checked={toggleValue}
          updateFolderMeta={updateFolderMeta}
          projectId={projectId}
          folderId={folderId}
        />
      </Col>
    </Row>
  );
}
