import { ColorPicker, SubTitle } from '@premagic/myne';
import { usePhotoSliderWidgetData } from '../../../context/PhotoSliderWidgetContext';

export default function PhotoSliderWidgetBackgroundColor() {
  const { customizations } = usePhotoSliderWidgetData();

  return (
    <>
      <SubTitle>Background color</SubTitle>
      <ColorPicker name="background_color" defaultValue={customizations?.background_color} />
    </>
  );
}
