import { FormLabel, LoadingDots, Row, Select } from '@premagic/myne';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';

type Props = {
  value?: Array<string>;
  onChange: (newFilterValue: Array<string>) => void;
  isLoading: boolean;
  allTags?: Array<string>;
};

export default function DAMLibraryTagsFilterSelector(props: Props): JSX.Element {
  const { value, onChange, isLoading, allTags } = props;

  const tagsOptions = useMemo(
    () =>
      allTags?.map((item) => ({
        value: item,
        label: item,
      })),
    [allTags],
  );
  const selectedOptions = tagsOptions?.filter((item) => value?.includes(item.value));

  const debouncedOnChange = useMemo(() => debounce(onChange, 700), [onChange]);

  function handleOnChange(options) {
    if (options) debouncedOnChange(options.map((item) => item.value));
  }

  return (
    <div>
      <FormLabel>Tags</FormLabel>
      {isLoading ? (
        <Row center>
          <LoadingDots size="sm" />
        </Row>
      ) : (
        <Select
          options={tagsOptions || []}
          value={selectedOptions}
          name="tags"
          onChange={handleOnChange}
          isMulti
          autoFocus
          isCreatable
          isSearchable
        />
      )}
    </div>
  );
}
