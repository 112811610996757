import React, { useRef, useState } from 'react';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import {
  Col,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  Divider,
  ErrorBoundary,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import APP_URLS from '../../../../services/AppRouteURLService';
import UserNamesContainer from '../../users/list/UserNamesContainer';
import EventStatusLabel from '../../events/details/EventStatusLabel';
import { CalendarOccasionsType } from '../OccasionsCalendarService';
import EventTagNumber from '../../events/details/EventTagNumber';
import EventCategoryIcon from '../../events/category/EventCategoryIcon';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type EventsCalendarEventContentProps = {
  occasion: CalendarOccasionsType;
};

export function OccasionsCalendarItemContent(props: EventsCalendarEventContentProps): JSX.Element {
  const { occasion } = props;
  const { status: eventStatus, id: eventId, number: eventNumber, name: eventName } = occasion.event;
  const { name: clientName } = occasion.client;
  const urlForDetails =
    eventId &&
    BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
      eventId,
    });
  const {
    start_date_time: startDate,
    end_date_time: endDate,
    name: occasionName,
    service_providers: serviceProviders,
    location,
  } = occasion;
  const $target = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  function hideDialog() {
    setShowDialog(false);
  }
  const rowItems = [
    {
      icon: 'map_pin',
      value: location,
    },
    {
      icon: 'clock',
      value: SimpleDateUtils.humanizeDateRage(startDate, endDate),
    },
    {
      icon: 'users',
      value: <UserNamesContainer userIds={serviceProviders} />,
    },
  ];
  return (
    <div ref={$target} onMouseEnter={() => setShowDialog(true)} onMouseLeave={hideDialog}>
      <div className={JS_SELECTORS.EVENT_ITEM_CALENDAR} data-event-id={eventId}>
        {clientName} {HTML_ICON.DOT} {occasionName}
      </div>
      <Dialog
        target={$target.current}
        show={showDialog}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={hideDialog}
      >
        <ErrorBoundary>
          <Row>
            <Text color={COLOR_SHADES.DARKER} block size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD}>
              {occasionName}
            </Text>
            <Col size={null} rightAlighed>
              <EventStatusLabel status={eventStatus} />
            </Col>
          </Row>
          <EventTagNumber eventNumber={eventNumber} />
          <Row vcenter>
            <EventCategoryIcon eventName={eventName} />
            <Space size={2} />
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
              {clientName}
            </Text>
            <Space />
          </Row>
          <Space vertical size={4} />
          {rowItems.map((item) => (
            <div key={item.icon}>
              <Row vcenter>
                <Icon name={item.icon} size={ICON_SIZES.SM} />
                <Space size={2} />
                <Text color={COLOR_SHADES.DARKER} block>
                  {item.value || '(blank)'}
                </Text>
              </Row>
              <Space vertical size={2} />
            </div>
          ))}
        </ErrorBoundary>
      </Dialog>
    </div>
  );
}
// <Space vertical />
// <Link to={urlForDetails}>View details</Link>
