import React, { useEffect } from 'react';

import { Button, BUTTON_STYLES, Col, InnerPageContentMainCol, Row, Space } from '@premagic/myne';
import EventOccasionsContainer from '../../../occassions/EventOccasionsContainer';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  eventId: string;
  focusId?: string;
  hasPermissionsToManageEvent: boolean;
  initData: (eventId: string) => void;
  showAddOccasion: () => void;
};

export default function EventDetailsOccasionsLayout(props: Props): React.ReactElement {
  const { eventId, focusId, initData, showAddOccasion, hasPermissionsToManageEvent } = props;
  useEffect(() => {
    initData(eventId);
  }, []);
  return (
    <InnerPageContentMainCol>
      <Row>
        <Col size={null} rightAlighed>
          <Button
            disabled={!hasPermissionsToManageEvent}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_OCCASION_MANAGE]}
            style={BUTTON_STYLES.PRIMARY}
            onClick={showAddOccasion}
          >
            Add Occasion
          </Button>
        </Col>
      </Row>
      <Space vertical />
      <EventOccasionsContainer eventId={eventId} focusId={focusId} />
      <Space vertical />
    </InnerPageContentMainCol>
  );
}
