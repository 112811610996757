import { connect } from 'react-redux';
import EventsListPagePagination from './EventsListPagePagination';
import { navigateToCRMPageWithFilter } from '../../filters/CRMFilterDuck';
import { eventsListEventCountSelector } from './EventsListEventsDataDuck';

function mapStateToProps(state, ownProps: { filters: string }) {
  const { filters } = ownProps;

  return {
    totalItems: eventsListEventCountSelector(state),
    filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (filters) => {
      dispatch(navigateToCRMPageWithFilter(dispatch, 'list', filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsListPagePagination);
