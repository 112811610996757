import { QRCodeBackgroundPosterService } from '@premagic/core';
import { COLOR_SHADES } from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import React from 'react';

function getTextColor(toUseContrastText?: boolean, bgColor?: string): string {
  const headerTextColor =
    toUseContrastText && bgColor && ColorUtils.hasBadContrastFrombackground('#203a56', 'readable', bgColor)
      ? COLOR_SHADES.LIGHTER
      : COLOR_SHADES.DARKER;

  return headerTextColor;
}

function getPremagicLogo(hasSolidColorsBG, footerTextColor): 'white' | 'black' | 'normal' {
  if (hasSolidColorsBG && footerTextColor === COLOR_SHADES.LIGHTER) {
    return 'white';
  }
  if (hasSolidColorsBG) {
    return 'black';
  }
  return 'normal';
}

export default function useBackgroundPoster(
  type: 'standee' | 'pocket' | 'tent' | 'led',
  backgroundCover: QRCodeBackgroundPosterService.QRCodePoster,
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
) {
  const { id, designOptions } = backgroundCover;
  const { smallerLogoSpace } = designOptions;

  const primaryColor1 = qrCodeDesigns?.colors?.primary_color1;
  const primaryColor2 = qrCodeDesigns?.colors?.primary_color2;

  const {
    numberOfColors,
    standeeHeaderContrastText,
    standeeFooterContrastText,
    pocketFrontHeaderContrastText,
    pocketFrontFooterContrastText,
    pocketBackContrastText,
  } = designOptions || {};

  const customStyle = id === QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS.RIGHT_ALIGNED_QR;
  const bgColorForHeader = numberOfColors > 0 ? primaryColor1 : '#fff';
  const bgColorForFooter = numberOfColors > 0 ? primaryColor2 : '#000';

  const hasSolidColorsBG = [
    QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS.RIGHT_ALIGNED_QR,
    QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS.SOLID_BG_COLORS,
  ].includes(id);

  const POSTER_TYPE_DESIGN_MAP = {
    standee: {
      headerTextColor: getTextColor(standeeHeaderContrastText, bgColorForHeader),
      footerTextColor: getTextColor(standeeFooterContrastText, bgColorForFooter),
      premagicLogo: getPremagicLogo(
        hasSolidColorsBG,
        getTextColor(standeeFooterContrastText, customStyle ? primaryColor2 : primaryColor1),
      ),
    },
    tent: {
      headerTextColor: getTextColor(standeeHeaderContrastText, bgColorForHeader),
      footerTextColor: getTextColor(standeeFooterContrastText, bgColorForFooter),
      premagicLogo: getPremagicLogo(
        hasSolidColorsBG,
        getTextColor(standeeFooterContrastText, customStyle ? primaryColor2 : primaryColor1),
      ),
    },
    pocket: {
      headerTextColor: getTextColor(pocketFrontHeaderContrastText, bgColorForHeader),
      footerTextColor: getTextColor(pocketFrontFooterContrastText, bgColorForFooter),
      backTextColor: getTextColor(pocketBackContrastText, primaryColor2),
      premagicLogo: getPremagicLogo(hasSolidColorsBG, getTextColor(pocketBackContrastText, primaryColor2)),
    },
  };

  return { ...POSTER_TYPE_DESIGN_MAP[type], customStyle, smallerLogoSpace };
}
