import {
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  Col,
  Color,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemAction,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_BUTTON_STYLES,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextButton,
  TextGroupValue,
  Tooltip,
  Divider,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { CRMPaymentCategoryType, CRMPaymentType, PAYMENT_TYPES, TAX_TYPES } from '../CRMPaymentService';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';
import PaymentItemDetailsDialogContainer from './PaymentItemDetailsDialogContainer';

type PaymentDateProps = {
  payment: CRMPaymentType;
  paymentType: PAYMENT_TYPES;
  onMarkAsPaid: () => void;
  onMarkAsSettled: () => void;
  isUpdating: boolean;
  hasPermissionToManage: boolean;
};

function PaymentDate(props: PaymentDateProps) {
  const { payment, paymentType, onMarkAsPaid, isUpdating, onMarkAsSettled, hasPermissionToManage } = props;
  const { is_settled: isSettled, due_date: dueDate, payment_date: paymentDate } = payment;
  const showMarkAsPaid = dueDate && (SimpleDateUtils.isPastDate(dueDate) || SimpleDateUtils.isToday(dueDate));
  const showMarkAsSettled = dueDate && (SimpleDateUtils.isPastDate(dueDate) || SimpleDateUtils.isToday(dueDate));
  const dueDateString = dueDate && SimpleDateUtils.fromNowDate(dueDate, true);
  const paymentDateString = paymentDate && SimpleDateUtils.humanizeDate(paymentDate, false, true, true);

  switch (paymentType) {
    case PAYMENT_TYPES.INFLOW:
      if (isSettled) return <Text size={TEXT_SIZE.SIZE_5}>{paymentDateString}</Text>;

      return (
        <Row vcenter inline>
          {showMarkAsPaid && (
            <Color shade={COLOR_SHADES.WARNING} inline>
              <Icon name="alert_triangle" size={ICON_SIZES.SM} />
              <Space size={1} />
            </Color>
          )}
          <Text size={TEXT_SIZE.SIZE_5} color={showMarkAsPaid ? COLOR_SHADES.DARKER : COLOR_SHADES.DARK}>
            due {dueDateString}
          </Text>
          {showMarkAsPaid && (
            <>
              <Space size={1} />
              {HTML_ICON.DOT}
              <Space size={1} />
              <TextButton
                disabled={!hasPermissionToManage}
                disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FINANCIAL_MANAGE]}
                style={TEXT_BUTTON_STYLES.LINK}
                size={BUTTON_SIZE.SM}
                onClick={onMarkAsPaid}
                isLoading={isUpdating}
              >
                Mark as Paid
              </TextButton>
            </>
          )}
        </Row>
      );

    case PAYMENT_TYPES.OUTFLOW:
      if (isSettled) return <Text size={TEXT_SIZE.SIZE_5}>{paymentDateString}</Text>;
      return (
        <Row vcenter>
          {showMarkAsSettled && (
            <Color shade={COLOR_SHADES.WARNING}>
              <Icon name="alert_triangle" size={ICON_SIZES.SM} />
              <Space size={1} />
            </Color>
          )}
          <Text size={TEXT_SIZE.SIZE_5} color={showMarkAsSettled ? COLOR_SHADES.DARKER : COLOR_SHADES.DARK}>
            due {dueDateString}
          </Text>
          {showMarkAsSettled && (
            <>
              <Space size={1} />
              {HTML_ICON.DOT}
              <Space size={1} />
              <TextButton
                disabled={!hasPermissionToManage}
                disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FINANCIAL_MANAGE]}
                style={TEXT_BUTTON_STYLES.LINK}
                size={BUTTON_SIZE.SM}
                onClick={onMarkAsSettled}
                isLoading={isUpdating}
              >
                Mark as settled
              </TextButton>
            </>
          )}
        </Row>
      );

    default:
      return (
        <Text size={TEXT_SIZE.SIZE_5}>
          <TextGroupValue type={TEXT_GROUP_TYPES.DATE} value={isSettled ? paymentDate : dueDate} />
        </Text>
      );
  }
}

type PaymentMenuItemsProps = {
  payment: CRMPaymentType;
  paymentType: PAYMENT_TYPES;
  showEditPayment: (paymentId: string, paymentType: PAYMENT_TYPES) => void;
  showDeleteDialog: (id: string) => void;
  onMarkAsPaid: () => void;
  onMarkAsSettled: () => void;
  sentReminderToClient: () => void;
  hasPermissionToManage: boolean;
  isOwnerOfPayment: boolean;
};

function PaymentMenuItems(props: PaymentMenuItemsProps) {
  const {
    payment,
    paymentType,
    showDeleteDialog,
    showEditPayment,
    onMarkAsPaid,
    onMarkAsSettled,
    hasPermissionToManage,
    isOwnerOfPayment,
    sentReminderToClient,
  } = props;
  const { is_settled: isSettled, id } = payment;
  if (!hasPermissionToManage) {
    return (
      <Menu>
        <MenuItem
          onClick={() => showEditPayment(id, paymentType)}
          disabled={!isOwnerOfPayment}
          disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FINANCIAL_MANAGE]}
        >
          <MenuItemIcon name="edit_2" />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => showDeleteDialog(id)}
          disabled={!isOwnerOfPayment}
          disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FINANCIAL_MANAGE]}
        >
          <MenuItemIcon name="trash" />
          Delete
        </MenuItem>
      </Menu>
    );
  }

  switch (paymentType) {
    case PAYMENT_TYPES.INFLOW:
      return (
        <Menu>
          {!isSettled && (
            <MenuItem onClick={sentReminderToClient}>
              <MenuItemIcon name="send" />
              Send Reminder
            </MenuItem>
          )}
          {!isSettled && (
            <MenuItem onClick={onMarkAsPaid}>
              <MenuItemIcon name="check_circle" />
              Mark as Paid
            </MenuItem>
          )}
          <MenuItem onClick={() => showEditPayment(id, paymentType)}>
            <MenuItemIcon name="edit_2" />
            Edit
          </MenuItem>
          <MenuItem onClick={() => showDeleteDialog(id)}>
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      );

    case PAYMENT_TYPES.OUTFLOW:
    default:
      return (
        <Menu>
          <MenuItem onClick={() => showEditPayment(id, paymentType)}>
            <MenuItemIcon name="edit_2" />
            Edit
          </MenuItem>
          {!isSettled && (
            <MenuItem onClick={onMarkAsSettled}>
              <MenuItemIcon name="check_circle" />
              Mark as settled
            </MenuItem>
          )}
          <MenuItem onClick={() => showDeleteDialog(id)}>
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      );
  }
}

type Props = {
  payment: CRMPaymentType;
  categories: Record<number, CRMPaymentCategoryType>;
  showDeleteDialog: (paymentId: string) => void;
  markAsPaid: (
    paymentId: string,
    eventId: string,
    options: {
      data: { payment_date: string; is_settled: boolean };
    },
  ) => void;
  markAsSettled: (
    paymentId: string,
    eventId: string,
    options: {
      data: { payment_date: string; is_settled: boolean };
    },
  ) => void;
  showEditPayment: (paymentId: string, paymentType: PAYMENT_TYPES) => void;
  sentReminderToClient: (paymentId: string, eventId: string) => void;
  isUpdating: boolean;
  isOwnerOfPayment: boolean;
  hasPermissionToManage: boolean;
  isMobileUp: boolean;
};

export default function PaymentListItem(props: Props): JSX.Element {
  const {
    payment,
    showDeleteDialog,
    markAsPaid,
    showEditPayment,
    categories,
    isUpdating,
    markAsSettled,
    isOwnerOfPayment,
    hasPermissionToManage,
    sentReminderToClient,
    isMobileUp,
  } = props;
  const {
    is_settled: isSettled,
    human_readable_id: humanId,
    amount,
    id,
    event: eventId,

    payment_category: categoryId,
  } = payment;
  const category = categories[categoryId];
  const paymentType = category.payment_type;

  function onMarkAsPaid() {
    markAsPaid(id, eventId, {
      data: {
        is_settled: true,
        payment_date: SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO),
      },
    });
  }

  function onMarkAsSettled() {
    markAsSettled(id, eventId, {
      data: {
        is_settled: true,
        payment_date: SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO),
      },
    });
  }

  if (isMobileUp) {
    return (
      <ListItem>
        <Col size={1}>
          <Text size={TEXT_SIZE.SIZE_5} block>
            {humanId}
          </Text>
        </Col>
        <Col size={3}>
          <PaymentItemDetailsDialogContainer paymentId={id}>
            <Text size={TEXT_SIZE.SIZE_5} block>
              {category.name}{' '}
              {paymentType === PAYMENT_TYPES.OUTFLOW && !isSettled && (
                <Text size={TEXT_SIZE.SIZE_6} muted>
                  (Unsettled)
                </Text>
              )}
            </Text>
          </PaymentItemDetailsDialogContainer>
        </Col>
        <Col size={5}>
          <PaymentDate
            paymentType={paymentType}
            payment={payment}
            onMarkAsPaid={onMarkAsPaid}
            onMarkAsSettled={onMarkAsSettled}
            isUpdating={isUpdating}
            hasPermissionToManage={hasPermissionToManage}
          />
        </Col>
        {payment?.taxable === TAX_TYPES.INCLUDING && (
          <Col size={1}>
            <Tooltip message="Including Tax" placement="top">
              <Color shade={COLOR_SHADES.SUCCESS} inline>
                <Icon name="tax" size={ICON_SIZES.SM} />
              </Color>
            </Tooltip>
          </Col>
        )}
        <Col rightAlighed size={null}>
          <Text color={isSettled ? COLOR_SHADES.DARK : COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
            <AccountCurrencyContainer amount={amount} />
          </Text>
        </Col>
        <Space size={5} />
        <ListItemAction className={JS_SELECTORS.CRM_PAYMENT_MORE_OPTIONS(id)}>
          {isUpdating ? (
            <LoadingDots size="sm" />
          ) : (
            <Dropdown>
              <DropdownIcon title="Payment options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
                <Icon name="more_horizontal" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
              </DropdownIcon>
              <PaymentMenuItems
                payment={payment}
                paymentType={paymentType}
                showDeleteDialog={showDeleteDialog}
                showEditPayment={showEditPayment}
                onMarkAsPaid={onMarkAsPaid}
                onMarkAsSettled={onMarkAsSettled}
                isOwnerOfPayment={isOwnerOfPayment}
                hasPermissionToManage={hasPermissionToManage}
                sentReminderToClient={() => sentReminderToClient(id, eventId)}
              />
            </Dropdown>
          )}
        </ListItemAction>
      </ListItem>
    );
  }

  return (
    <Space size={4}>
      <Row vcenter>
        <Text size={TEXT_SIZE.SIZE_5}>{humanId}</Text>
        <Space size={2} />
        <PaymentItemDetailsDialogContainer paymentId={id}>
          <Text size={TEXT_SIZE.SIZE_5} block>
            {category.name}{' '}
            {paymentType === PAYMENT_TYPES.OUTFLOW && !isSettled && (
              <Text size={TEXT_SIZE.SIZE_6} muted>
                (Unsettled)
              </Text>
            )}
          </Text>
        </PaymentItemDetailsDialogContainer>
        <Col size={null} rightAlighed>
          <Row vcenter>
            {payment?.taxable === TAX_TYPES.INCLUDING && (
              <Tooltip message="Including Tax" placement="top">
                <Color shade={COLOR_SHADES.SUCCESS} inline>
                  <Icon name="tax" size={ICON_SIZES.SM_XS} />
                </Color>
              </Tooltip>
            )}
            <Space size={2} />
            {isUpdating ? (
              <LoadingDots size="sm" />
            ) : (
              <Dropdown>
                <DropdownIcon title="Payment options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
                  <Icon name="more_vertical" size={ICON_SIZES.SM} />
                </DropdownIcon>
                <PaymentMenuItems
                  payment={payment}
                  paymentType={paymentType}
                  showDeleteDialog={showDeleteDialog}
                  showEditPayment={showEditPayment}
                  onMarkAsPaid={onMarkAsPaid}
                  onMarkAsSettled={onMarkAsSettled}
                  isOwnerOfPayment={isOwnerOfPayment}
                  hasPermissionToManage={hasPermissionToManage}
                  sentReminderToClient={() => sentReminderToClient(id, eventId)}
                />
              </Dropdown>
            )}
          </Row>
        </Col>
      </Row>
      <Space vertical size={1} />
      <Row vcenter>
        <PaymentDate
          paymentType={paymentType}
          payment={payment}
          onMarkAsPaid={onMarkAsPaid}
          onMarkAsSettled={onMarkAsSettled}
          isUpdating={isUpdating}
          hasPermissionToManage={hasPermissionToManage}
        />
        <Col size={null} rightAlighed>
          <Row vcenter>
            <Text color={isSettled ? COLOR_SHADES.DARK : COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
              <AccountCurrencyContainer amount={amount} />
            </Text>
            <Space size={2} />
          </Row>
        </Col>
      </Row>
      <Space vertical size={1} />
      <Divider />
    </Space>
  );
}
