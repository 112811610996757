import { connect } from 'react-redux';
import { LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { getMemoryTemplateForProject, memoryTemplatesIdsSelector } from './MemoriesTemplatesDataDuck';

import MemoriesTemplatesList from './MemoriesTemplatesList';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    focusId?: string;
  },
) {
  const { eventId, projectId, focusId } = ownProps;

  return {
    eventId,
    projectId,
    focusId,
    templateIds: memoryTemplatesIdsSelector(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.MEMORY_TEMPLATES.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      dispatch(getMemoryTemplateForProject(dispatch, projectId));
    },
    showAddNewTemplate: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoriesTemplatesList);
