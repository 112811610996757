import React, { Component } from 'react';
import { InnerPageHeader, Text, InnerPage, InnerPageContent } from '@premagic/myne';
import CustomerClientContainer from './details/CRMClientSummaryContainer';

type Props = {
  initData: () => void;
  clientIds: Array<string>;
  isLoading: boolean;
};

export default class ClientsPage extends Component<Props> {
  componentDidMount() {
    const { initData } = this.props;
    initData();
  }

  render() {
    const { clientIds, isLoading } = this.props;

    return (
      <InnerPage>
        <InnerPageHeader iconName="smile" title="User">
          Clients
        </InnerPageHeader>
        <InnerPageContent isLoading={isLoading}>
          <div>
            {clientIds.length === 0 && (
              <div>
                <Text muted>You haven&apos;t added any client to Premagic yet</Text>
              </div>
            )}
            {clientIds.map((client) => (
              <CustomerClientContainer key={client} clientId={client} />
            ))}
          </div>
        </InnerPageContent>
      </InnerPage>
    );
  }
}
