import { ClientSettingsService, PricingPlanFeaturesService } from '@premagic/core';
import {
  Card,
  Col,
  COLOR_SHADES,
  FormErrorType,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageHeader,
  List,
  ListItem,
  Loading,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
} from '@premagic/myne';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_MARKETING]: boolean;
  };
};

export default function EventMarketingSettingsPage(props: Props) {
  const { clientSettings, savePreferences, isSaving, isLoading, hasFeatures, errors } = props;

  const showLinkedinPOstShare = clientSettings[
    ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_SHOW_LINKEDIN_POST_SHARE
  ] as boolean;
  const hasEnabledEventMarketingPosterFeature = clientSettings[
    ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_EVENT_MARKETING_POSTERS_ENABLE
  ] as boolean;
  const hasEventMarketingFeature = hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_MARKETING];

  if (isLoading) return <Loading />;

  return (
    <InnerPage>
      <InnerPageHeader iconName="magaphone" title="Event Marketing" backTo={APP_URLS.SETTINGS.INDEX}>
        Event Marketing
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <List>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Enable Advocacy Posters
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>
                    Let your guest spread the word about your event by sharing about it on social media. You can also
                    create a poster to share on your social media channels.
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {hasEnabledEventMarketingPosterFeature ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      disabled={!hasEventMarketingFeature}
                      isLoading={isSaving || isLoading}
                      name=""
                      checked={hasEnabledEventMarketingPosterFeature}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_EVENT_MARKETING_POSTERS_ENABLE]:
                              value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Show attendee social share post preview in the guest gallery.
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {showLinkedinPOstShare ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isSaving || isLoading}
                      name=""
                      checked={showLinkedinPOstShare}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_SHOW_LINKEDIN_POST_SHARE]:
                              value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
            </List>
          </Card>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
