import React, { useRef } from 'react';
import { List, ScrollableContainer } from '@premagic/myne';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import ProposalSlideAddButtonContainer from './ProposalSlideAddButtonContainer';
import ProposalSlideListItemContainer from './ProposalSlideListItemContainer';
import { getClassNameFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type Props = {
  projectId: string;
  deckId: string;
  folderId: string;
  slideIds: Array<number>;
  activeSlideId: number;
  appHeight: number;
  updateProposalDeckSidesOrder: (options: {
    from: number;
    to: number;
    projectId: string;
    deckId: string;
    slideIds: Array<number>;
  }) => void;
};

const slideButtonHeight = 132;
const headerHeight = 52;

export default function ProposalSlideList(props: Props): JSX.Element {
  const { slideIds, projectId, deckId, folderId, activeSlideId, appHeight, updateProposalDeckSidesOrder } = props;
  const activeItemSelector = getClassNameFromSelector(JS_SELECTORS.PROPOSAL_SLIDE_ITEM(activeSlideId));
  const $container = useRef<HTMLDivElement>(null);
  const height = appHeight - slideButtonHeight - headerHeight;

  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;
    if (reason === 'DROP' && destination) {
      updateProposalDeckSidesOrder({
        slideIds,
        projectId,
        deckId,
        from: source.index,
        to: destination.index,
      });
    }
  }

  return (
    <div ref={$container}>
      <DragDropContext onDragEnd={onDrop}>
        <Droppable droppableId="SLIDES-LIST">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <ScrollableContainer showShadow direction="vertical" style={{ height }}>
                {/* scrollTo={activeItemSelector} */}
                <List>
                  {slideIds.map((id, idx) => (
                    <ProposalSlideListItemContainer
                      id={id}
                      position={idx + 1}
                      key={id}
                      projectId={projectId}
                      deckId={deckId}
                      folderId={folderId}
                    />
                  ))}
                  {provided.placeholder}
                </List>
              </ScrollableContainer>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ProposalSlideAddButtonContainer projectId={projectId} folderId={folderId} deckId={deckId} />
    </div>
  );
}
