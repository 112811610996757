import React, { useState } from 'react';
import { Button, Link, LogoText, Page, PAGE_STYLES, Row, Space } from '@premagic/myne';
import { AuthUtils } from '@premagic/utils';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { Auth } from 'aws-amplify';

type Props = {
  loginUser: (data) => void;
  authLoading: boolean;
  errors?: {
    message: string;
    code: string;
    name: string;
  };
  filterObject?: {
    code: string;
  };
};
export default function AuthPageSigninWithCode(props: Props) {
  const { loginUser, authLoading, errors, filterObject } = props;
  const [showResetPasswordForm, setShowResetForm] = useState(false);
  AuthUtils.getAuthSession();
  return (
    <Page screenHeight style={PAGE_STYLES.DEFAULT}>
      <Space vertical size={10} />
      <Row center>
        <LogoText />
      </Row>
      <Space vertical size={10} />
      <Button
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          })
        }
      >
        Login with Google
      </Button>

      <Button
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Cognito,
          })
        }
      >
        Login
      </Button>

      <Link to="https://premagic-a.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?client_id=2ehso65rkm5hamj86tt4hor6u8&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapp.premagic.com%2Fsignin">
        Login
      </Link>

      <Space vertical size={35} />
      <Link to="https://premagic-a.auth.ap-southeast-1.amazoncognito.com/logout?response_type=code&client_id=2ehso65rkm5hamj86tt4hor6u8&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapp.premagic.com%2Fsignin">
        Logout
      </Link>
    </Page>
  );
}
