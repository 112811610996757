import React from 'react';
import {
  ButtonIcon,
  Color,
  COLOR_SHADES,
  GeneralEditorHeader,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { EventPosterService } from '@premagic/core';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  eventId: string;
  type: string;
  posterId: string;
  poster?: EventPosterService.EventPosterType;
  projectId: string;
};

export default function EventPosterPreviewHeader(props: Props) {
  const { type, eventId, posterId, poster, projectId } = props;

  const backUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.EVENT_POSTER.EDIT, {
    type,
    eventId,
    projectId,
    posterId,
  });

  const { name, scope } = poster || { scope: [], name: '' };
  return (
    <GeneralEditorHeader>
      <Row vcenter fullHeight>
        <ButtonIcon title="Back" tooltipPlacement="bottom" link={backUrl}>
          <Icon name="arrow_left" size={ICON_SIZES.SM} />
        </ButtonIcon>
        <Color fillSolidIcon shade={COLOR_SHADES.DARKER} inline>
          <Icon name="artboard" />
        </Color>
        <Space size={2} />
        <Text size={TEXT_SIZE.SIZE_3}>{name}</Text>
        <Space size={1} />
        <Text muted size={TEXT_SIZE.SIZE_3}>
          /
        </Text>
        <Space size={2} />
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
          Preview
        </Text>
      </Row>
    </GeneralEditorHeader>
  );
}
