import React, { useState } from 'react';
import { BrowserUrlUtils, StringUtils } from '@premagic/utils';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Card,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  IconNames,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { EventsService, LabelService } from '@premagic/core';
import APP_URLS from '../../../../services/AppRouteURLService';
import EventOccasionsSummaryContainer from '../../occassions/summary/EventOccasionsSummaryContainer';
import EventStatusLabel from '../../events/details/EventStatusLabel';
import { EventsListEventType } from '../EventsListService';
import EventCategoryIcon from '../../events/category/EventCategoryIcon';
import EventTagNumber from '../../events/details/EventTagNumber';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';

type EventLabelsProps = {
  labels?: Array<LabelService.LabelsType>;
};

function EventLabels(props: EventLabelsProps) {
  const { labels } = props;

  if (!labels || labels?.length === 0) return null;

  return (
    <Row>
      <Space size={8} />
      <Icon name="tag" size={ICON_SIZES.SM_XS} alignSize={1} align="top" />
      <Space size={1} />
      <Text size={TEXT_SIZE.SIZE_5}>{labels.map((item) => item.name).join(', ')}</Text>
    </Row>
  );
}

type EventPriorityProps = {
  priority: EventsService.EVENT_PRIORITY;
};

function EventPriority(props: EventPriorityProps) {
  const { priority } = props;

  const priorityItems = Object.values(EventsService.EVENT_PRIORITY_TYPE_DETAILS).map((type) => ({
    value: type.id,
    label: type.title,
    icon: type.icon,
  }));

  const priorityForCurrentEvent = priorityItems.find((option) => option.value === priority) || priorityItems[2];

  const { icon: priorityIconForEvent, label: priorityName } = priorityForCurrentEvent;

  return (
    <ButtonIcon onClick={() => null} title={`Priority: ${priorityName}`} size={BUTTON_ICONS_SIZES.SM}>
      <Icon name={priorityIconForEvent as IconNames} size={ICON_SIZES.MD} accentStyle={ICON_ACCENT_STYLES.NONE} />
    </ButtonIcon>
  );
}

type EventOccasionType = {
  eventId: string;
  occasions: Array<string>;
};
function EventOccasion(props: EventOccasionType) {
  const { eventId, occasions = [] } = props;
  const [showOccasions, setShowOccasion] = useState(false);
  if (occasions.length === 0)
    return (
      <Text size={TEXT_SIZE.SIZE_5} muted>
        You have not added any occasion
      </Text>
    );
  if (showOccasions) return <EventOccasionsSummaryContainer eventId={eventId} />;
  return (
    <Button onClick={() => setShowOccasion(true)} style={BUTTON_STYLES.TERTIARY} size={BUTTON_SIZE.SM}>
      Show {occasions.length} {StringUtils.pluralize('occasion', occasions.length)}{' '}
      <Icon size={ICON_SIZES.SM} name="chevron_down" />
    </Button>
  );
}

type Props = {
  eventId: string;
  event: EventsListEventType;
  isMobileUp: boolean;
  isCompanyTypeNotPhotographer: boolean;
  hasEventDetailsPermission: boolean;
  isFeatureLeadEnabled: boolean;
  isFeatureEventsOccasionsEnabled: boolean;
};

export default function EventsListEventItem(props: Props): JSX.Element {
  const {
    event,
    eventId,
    isMobileUp,
    isCompanyTypeNotPhotographer,
    hasEventDetailsPermission,
    isFeatureLeadEnabled,
    isFeatureEventsOccasionsEnabled,
  } = props;
  const { occasions, status, name, number: eventNumber, priority_value: priority } = event;
  const { name: clientName } = event.client;

  const urlForDetails = hasEventDetailsPermission
    ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
        eventId,
      })
    : undefined;
  const nameToShow = event.name in EventsService.EVENT_CATEGORY ? clientName : name;

  return (
    <Card>
      <Space>
        <Row vcenter>
          <EventTagNumber eventNumber={eventNumber} />
          <EventPriority priority={priority} />
        </Row>
        {isMobileUp ? (
          <>
            <Row vcenter>
              <Button link={urlForDetails} style={BUTTON_STYLES.RESET}>
                <EventCategoryIcon
                  eventName={isCompanyTypeNotPhotographer ? EventsService.EVENT_CATEGORY.CORPORATE : name}
                />
                <Space size={2} />
                <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                  {nameToShow}
                </Text>
              </Button>
              {isFeatureLeadEnabled && (
                <>
                  <Space size={4} />
                  <EventStatusLabel status={status} />
                </>
              )}
              <Col size={null} rightAlighed>
                <Button
                  link={urlForDetails}
                  style={BUTTON_STYLES.LINK}
                  disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_DETAILS]}
                  disabled={!hasEventDetailsPermission}
                >
                  View details
                </Button>
              </Col>
            </Row>
            <EventLabels labels={event?.labels} />
          </>
        ) : (
          <div>
            <Button link={urlForDetails} style={BUTTON_STYLES.RESET}>
              <EventCategoryIcon
                eventName={isCompanyTypeNotPhotographer ? EventsService.EVENT_CATEGORY.CORPORATE : name}
              />
              <Space size={2} />
              <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                {nameToShow}
              </Text>
            </Button>
            <Space size={1} vertical />
            <EventLabels labels={event?.labels} />
            {isFeatureLeadEnabled && (
              <>
                <Space size={1} vertical />
                <EventStatusLabel status={status} />
              </>
            )}
          </div>
        )}
        <Space vertical />
        {isFeatureEventsOccasionsEnabled && (
          <div>
            <Text boldness={TEXT_BOLDNESS.BOLD} block>
              Occasions
            </Text>
            <Space vertical size={1} />
            <EventOccasion eventId={eventId} occasions={occasions} />
          </div>
        )}
      </Space>
    </Card>
  );
}
