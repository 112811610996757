import React from 'react';
import { ButtonIcon, COLOR_SHADES, Icon, ICON_SIZES, Row, Space, Text } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { PAGINATION_KEYS, PAGINATION_SIZE } from '../../../../services/PaginationService';
import { CRMFiltersType } from '../../filters/CRMFilterTypes';

type Props = {
  totalItems: number;
  filters: string;
  navigate: (newFilter: CRMFiltersType) => void;
};

export default function CRMDashboardEventsListPagePagination(props: Props): JSX.Element | null {
  const { totalItems, filters, navigate } = props;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
  const currentOffset = Number(filterObject[PAGINATION_KEYS.OFFSET]) || 0;
  const currentPage = currentOffset / PAGINATION_SIZE + 1;
  const totalPages = Math.ceil(totalItems / PAGINATION_SIZE);
  const hasNext = currentPage !== totalPages;
  const hasPrevious = currentPage !== 1;

  function handlePageChange(direction: 'next' | 'previous') {
    const newOffset = direction === 'next' ? currentOffset + PAGINATION_SIZE : currentOffset - PAGINATION_SIZE;
    navigate({
      ...filterObject,
      [PAGINATION_KEYS.OFFSET]: newOffset,
    });
  }

  if (!totalItems) return null;
  return (
    <Row vcenter>
      <ButtonIcon
        title="Previous page"
        onClick={() => handlePageChange('previous')}
        disabled={!hasPrevious}
        disabledTitle="There are no items in the previous page"
      >
        <Icon name="chevron_left" size={ICON_SIZES.SM} />
      </ButtonIcon>
      <Space size={2} />
      <Text color={COLOR_SHADES.DARKER}>
        {currentPage} of {totalPages}
      </Text>
      <Space size={2} />
      <ButtonIcon
        title="Next page"
        onClick={() => handlePageChange('next')}
        disabled={!hasNext}
        disabledTitle="There are no items in the next page"
      >
        <Icon name="chevron_right" size={ICON_SIZES.SM} />
      </ButtonIcon>
    </Row>
  );
}
