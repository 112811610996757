import React, { useEffect } from 'react';

import {
  Divider,
  Dropdown,
  DropdownIcon,
  Icon,
  LoadingDots,
  Menu,
  MENU_ITEM_STYLES,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  Tooltip,
} from '@premagic/myne';
import { ArrayUtils, BrowserUtils } from '@premagic/utils';
import { FolderService } from '@premagic/core';
import FolderDeleteDialogContainer from './FolderDeleteDialogContainer';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import FolderRenameDialogContainer from './FolderRenameDialogContainer';
import FolderFilesBulkDeleteContainer from '../folder-files-delete/FolderFilesBulkDeleteContainer';
import FolderBlockDialogContainer from './FolderBlockDialogContainer';
import FolderMakeItPublicDialogContainer from './FolderMakeItPublicDialogContainer';

type Props = {
  projectId: string;
  folderId: string;
  folder: FolderService.FolderType;
  showDeleteFolder: () => void;
  showRenameFolder: () => void;
  showSharePanel: (folderId: string) => void;
  clients?: Array<{
    name: string;
    phone_number: string;
    email: string;
  }>;
  totalFiles: number;
  setBulkAction: () => void;
  clearFileSelectionForBulkAction: () => void;
  cancelBulkAction: () => void;
  toggleAlbumView: (status: boolean) => void;
  isAlbumViewWithFileName: boolean;
  isFolderBlocked?: boolean;
  showFolderBlockDialog: () => void;
  showExternalVideoLinkUploader: () => void;
  isFolderPublic: boolean;
  showMarkAsPublicFolder: () => void;
  isLoading: boolean;
};

export default function FolderOptionsMenuButton(props: Props): JSX.Element {
  const {
    projectId,
    folderId,
    showDeleteFolder,
    showRenameFolder,
    folder,
    showSharePanel,
    clients,
    totalFiles,
    setBulkAction,
    clearFileSelectionForBulkAction,
    cancelBulkAction,
    isAlbumViewWithFileName,
    toggleAlbumView,
    showFolderBlockDialog,
    isFolderBlocked,
    showExternalVideoLinkUploader,
    showMarkAsPublicFolder,
    isFolderPublic,
    isLoading,
  } = props;
  const { folder_name: folderName, share_url: shareUrl, folder_type: folderType } = folder;
  function openLink(): void {
    if (!shareUrl) return;
    BrowserUtils.openInNewWindow(shareUrl, folderName);
  }

  useEffect(() => {
    clearFileSelectionForBulkAction();
    cancelBulkAction();
  }, [clearFileSelectionForBulkAction, cancelBulkAction]);
  const clientShareText = clients?.length
    ? clients?.map((clientData) => `${clientData.name} via ${clientData.email} & ${clientData.phone_number}`)
    : undefined;

  return (
    <Row inline vcenter>
      <FolderFilesBulkDeleteContainer projectId={projectId} folderId={folderId} />
      <Space />
      <span className={JS_SELECTORS.EVENTS_MORE_OPTIONS}>
        <Dropdown>
          <DropdownIcon title="More" tooltipPlacement="left">
            {isLoading ? <LoadingDots size="xs" /> : <Icon name="more_vertical" />}
          </DropdownIcon>
          <Menu>
            {folder.is_shared ? (
              <Tooltip
                message={clientShareText ? `Shared with ${ArrayUtils.listify(clientShareText)}` : 'Shared'}
                placement="left"
              >
                <MenuItem onClick={openLink}>
                  <MenuItemIcon name="external_link" />
                  Open
                </MenuItem>
              </Tooltip>
            ) : (
              <MenuItem onClick={() => showSharePanel(folderId)}>
                <MenuItemIcon name="send" />
                Share
              </MenuItem>
            )}
            <MenuItem onClick={showRenameFolder}>
              <MenuItemIcon name="edit_2" />
              Edit name
            </MenuItem>
            <MenuItem onClick={showExternalVideoLinkUploader}>
              <MenuItemIcon name="youtube" />
              Add Youtube/Vimeo Link
            </MenuItem>

            {isAlbumViewWithFileName ? (
              <MenuItem onClick={() => toggleAlbumView(false)}>
                <MenuItemIcon name="eye" />
                Hide file names
              </MenuItem>
            ) : (
              <MenuItem onClick={() => toggleAlbumView(true)}>
                <MenuItemIcon name="eye" />
                Show file names
              </MenuItem>
            )}
            <Space vertical size={1} />
            <Divider />
            <Space vertical size={1} />
            <MenuItem onClick={showMarkAsPublicFolder}>
              <MenuItemIcon name={isFolderPublic ? 'box' : 'glob'} />
              {isFolderPublic ? 'Hide from guest gallery' : 'Show in guest gallery'}
            </MenuItem>
            {isFolderBlocked ? (
              <MenuItem onClick={showFolderBlockDialog}>
                <MenuItemIcon name="circle" />
                Unblock Folder
              </MenuItem>
            ) : (
              <MenuItem onClick={showFolderBlockDialog}>
                <MenuItemIcon name="x_circle" />
                Block/hide Folder
              </MenuItem>
            )}
            <Space vertical size={1} />
            <Divider />
            <Space vertical size={1} />
            <MenuItem onClick={setBulkAction}>
              <MenuItemIcon name="file_minus" />
              Bulk Delete Files
            </MenuItem>

            <MenuItem onClick={showDeleteFolder} style={MENU_ITEM_STYLES.DANGER}>
              <MenuItemIcon name="trash" />
              Delete
            </MenuItem>
          </Menu>
        </Dropdown>
      </span>

      <FolderDeleteDialogContainer projectId={projectId} folderId={folderId} />
      <FolderRenameDialogContainer projectId={projectId} folderId={folderId} />
      <FolderBlockDialogContainer projectId={projectId} folderId={folderId} />
      <FolderMakeItPublicDialogContainer projectId={projectId} folderId={folderId} />
    </Row>
  );
}
