import React, { useState } from 'react';
import { Button, BUTTON_STYLES, Row, SCROLLABLE_CONTAINER_SIZE, ScrollableContainer } from '@premagic/myne';
import AccountFolderPhotoDropZoneContainer from '../../folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderUploadProgressContainer from '../../folders/folder-files-upload/FolderUploadProgressContainer';
import FolderFilesUploadErrorContainer from '../../folders/folder-files-upload/FolderFilesUploadErrorContainer';
import FolderGalleryWithFileSelectContainer from '../../folders/gallery/FolderGalleryWithFileSelectContainer';
import FolderImagePopupSimpleContainer from '../../folders/gallery/FolderImagePopupSimpleContainer';

type Props = {
  projectId: string;
  folderId?: string;
  onSelect: (fileId: string) => void;
  createFolder: (projectId: string) => void;
  isCreatingFolder: boolean;
};

export default function BrandingFiles(props: Props): JSX.Element | null {
  const { projectId, folderId, onSelect, createFolder, isCreatingFolder } = props;
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  if (!folderId)
    return (
      <Row center>
        <Button onClick={() => createFolder(projectId)} isLoading={isCreatingFolder} style={BUTTON_STYLES.PRIMARY}>
          Enable Watermark
        </Button>
      </Row>
    );
  if (selectedFile) {
    return (
      <FolderImagePopupSimpleContainer
        projectId={projectId}
        folderId={folderId}
        id={selectedFile}
        onDelete={() => setSelectedFile(null)}
        onNavigate={setSelectedFile}
        onSelect={onSelect}
      />
    );
  }
  return (
    <div>
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
      <FolderUploadProgressContainer folderId={folderId} />
      <FolderFilesUploadErrorContainer folderId={folderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <FolderGalleryWithFileSelectContainer
          projectId={projectId}
          folderId={folderId}
          onSelect={onSelect}
          onSelectExpand={setSelectedFile}
        />
      </ScrollableContainer>
    </div>
  );
}
