import React from 'react';
import { InnerPageHeader, InnerPageSubHeader, TabItem, Tabs } from '@premagic/myne';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  currentUrlPathname: string;
};

export default function CompanyProfileHeader(props: Props) {
  const { currentUrlPathname } = props;
  return (
    <div>
      <InnerPageHeader iconName="star" title="Company profile | Branding" backTo={APP_URLS.SETTINGS.INDEX}>
        Company profile
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem
            to={APP_URLS.SETTINGS.PROFILE.DETAILS}
            active={currentUrlPathname.includes(APP_URLS.SETTINGS.PROFILE.DETAILS)}
          >
            Details
          </TabItem>
          <TabItem
            to={APP_URLS.SETTINGS.PROFILE.DOMAIN}
            active={currentUrlPathname.includes(APP_URLS.SETTINGS.PROFILE.DOMAIN)}
          >
            Domain
          </TabItem>
          <TabItem
            to={APP_URLS.SETTINGS.PROFILE.CUSTOM_POLICY}
            active={currentUrlPathname.includes(APP_URLS.SETTINGS.PROFILE.CUSTOM_POLICY)}
          >
            Custom policy
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
    </div>
  );
}
