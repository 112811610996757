import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import HighlightDesignGalleryGridSpaceSelector from './HighlightDesignGalleryGridSpaceSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (value: number) => void;
    galleryGridSpace: number;
  },
) {
  const { onChange, galleryGridSpace } = ownProps;
  return {
    onChange,
    galleryGridSpace,
    isGalleryGridSpaceSelectorEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignGalleryGridSpaceSelector);
