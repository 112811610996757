import React from 'react';
import { Dropdown, DropdownIcon, Icon, ICON_SIZES, LoadingDots, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';
import { OccasionsService } from '@premagic/core';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';
import OccasionDeleteDialogContainer from './OccasionDeleteDialogContainer';

type EventOccasionOptionsMenuProps = {
  eventId: string;
  occasionId: string;
  occasion: OccasionsService.OccasionType;
  isLoading: boolean;
  hasPermissionsToManageEvent: boolean;
  duplicateOccasion: (eventId: string, occasionId: string, data: any) => void;
  showDeleteDialog: (occasionId: string) => void;
};

export default function EventOccasionOptionsMenu(props: EventOccasionOptionsMenuProps): React.ReactElement {
  const { eventId, occasionId, occasion, isLoading, hasPermissionsToManageEvent, duplicateOccasion, showDeleteDialog } =
    props;
  return (
    <>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="top" isLoading={isLoading}>
          <Icon name="more_vertical" size={ICON_SIZES.SM} />
        </DropdownIcon>

        <Menu>
          <MenuItem
            disabled={!hasPermissionsToManageEvent}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_OCCASION_MANAGE]}
            onClick={() => duplicateOccasion(eventId, occasionId, occasion)}
          >
            <MenuItemIcon name="copy" />
            Duplicate occasion
          </MenuItem>

          <MenuItem
            disabled={!hasPermissionsToManageEvent}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_OCCASION_MANAGE]}
            onClick={() => showDeleteDialog(occasionId)}
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      {hasPermissionsToManageEvent && <OccasionDeleteDialogContainer occasionId={occasionId} eventId={eventId} />}
    </>
  );
}
