import React from 'react';

import { FolderMetaService } from '@premagic/core';
import { MediaUtils } from '@premagic/utils';
import {
  Text,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  FileUploader,
  Image,
  Row,
  Space,
  COLOR_SHADES,
  TEXT_BOLDNESS,
  Col,
  Icon,
  ICON_SIZES,
} from '@premagic/myne';

import { toastMessage } from '../../../../reducers/ToastStore';
import FolderCoverPhotoSetFocusButtonContainer from './FolderCoverPhotoSetFocusButtonContainer';

type FolderCoverPhotoProps = {
  isLoading: boolean;
  projectId: string;
  folderId: string;
  uploadCoverPhoto: (projectId: string, folderId: string, coverPhoto: File) => void;
  smallStyle?: boolean;
  type?: 'cover' | 'thumbnail';
  folderMeta?: FolderMetaService.FolderMetaType;
};

export default function FolderCoverPhoto(props: FolderCoverPhotoProps): React.ReactElement {
  const { isLoading, folderId, projectId, uploadCoverPhoto, smallStyle = false, type = 'cover', folderMeta } = props;

  const src = folderMeta?.cover_image?.dynamic_image_url;
  const coverPhoto = !src
    ? ''
    : MediaUtils.getDynamicImageURL(src, MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL, { width: 300 });

  if (coverPhoto) {
    return (
      <>
        <Image src={coverPhoto} style={{ borderRadius: '8px' }} alt="Cover photo" smallStyle={smallStyle} />
        <Space size={2} vertical />
        <Row>
          <FolderCoverPhotoSetFocusButtonContainer folderId={folderId} />
          <Col size={null} rightAlighed>
            <FileUploader
              maxSize={35}
              accept="image/jpeg, image/png"
              onError={(message) => toastMessage('error', message)}
              onChange={(e, files) => {
                if (files.length) uploadCoverPhoto(projectId, folderId, files[0]);
                return {};
              }}
            >
              <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} isLoading={isLoading}>
                <Icon name="edit" size={ICON_SIZES.SM} />
                <Space size={1} />
                Change
              </Button>
            </FileUploader>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Card style={CARD_STYLES.TRANSPARENT}>
      <Space size={2}>
        <Text block boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT} center>
          {isLoading ? 'Uploading...' : `Set ${type} photo`}
        </Text>
        <Space vertical />
        <Row center>
          <FileUploader
            maxSize={35}
            accept="image/jpeg, image/png"
            onError={(message) => toastMessage('error', message)}
            onChange={(e, files) => {
              if (files.length) uploadCoverPhoto(projectId, folderId, files[0]);
              return {};
            }}
          >
            <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.XS} isLoading={isLoading} type="button">
              Select
            </Button>
          </FileUploader>
        </Row>
      </Space>
    </Card>
  );
}
