import { ProjectMetaService } from '@premagic/core';
import { Col, FormGroup, FormLabel, RichTextAreaInline, Space, SubTitle, Tooltip } from '@premagic/myne';

type HighlightDesignEndingTextEditorProps = {
  onChange?: (value: string) => void;
  isEndingTextEditorEnabled?: boolean;
  galleryEndingText?: string;
  name: string;
};

export default function HighlightDesignEndingTextEditor(props: HighlightDesignEndingTextEditorProps) {
  const { onChange, isEndingTextEditorEnabled, galleryEndingText, name } = props;

  const disabledMessage = 'Upgrade your Plan to set custom Ending Text';

  return (
    <FormGroup>
      <FormLabel>Ending Text</FormLabel>
      <Space vertical size={2} />
      <Col size={6}>
        <Tooltip message={!isEndingTextEditorEnabled ? disabledMessage : ''} placement="top">
          <RichTextAreaInline
            name={name}
            defaultValue={galleryEndingText}
            placeholder="eg. Hope you liked it."
            onChange={onChange}
            disabled={!isEndingTextEditorEnabled}
            disableColorChange
            debounceTimer={0}
          />
        </Tooltip>
      </Col>
    </FormGroup>
  );
}
