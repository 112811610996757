export enum WATERMARK_POSITIONS {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  CENTER_LEFT = 'center-left',
  CENTER = 'center',
  CENTER_RIGHT = 'center-right',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export const WATERMARK_POSITION_DETAILS = {
  [WATERMARK_POSITIONS.TOP_LEFT]: { x: 10, y: 10, label: 'Top Left' },
  [WATERMARK_POSITIONS.TOP_CENTER]: { x: 'center', y: 10, label: 'Top Center' },
  [WATERMARK_POSITIONS.TOP_RIGHT]: { x: -10, y: 10, label: 'Top Right' },
  [WATERMARK_POSITIONS.CENTER_LEFT]: { x: 10, y: 'center', label: 'Center Left' },
  [WATERMARK_POSITIONS.CENTER]: { x: 'center', y: 'center', label: 'Center' },
  [WATERMARK_POSITIONS.CENTER_RIGHT]: { x: -10, y: 'center', label: 'Center Right' },
  [WATERMARK_POSITIONS.BOTTOM_LEFT]: { x: 10, y: -10, label: 'Bottom Left' },
  [WATERMARK_POSITIONS.BOTTOM_CENTER]: { x: 'center', y: -10, label: 'Bottom Center' },
  [WATERMARK_POSITIONS.BOTTOM_RIGHT]: { x: -10, y: -10, label: 'Bottom Right' },
};

export enum WATERMARK_SIZES {
  SMALL = 'small',
  SMALL_MEDIUM = 'small-medium',
  MEDIUM = 'medium',
  MEDIUM_LARGE = 'medium-large',
  LARGE = 'large',
  FULL_SIZE = 'watermark-size',
  // FILL = 'fill',
}

export const WATERMARK_SIZE_DETAILS = {
  [WATERMARK_SIZES.SMALL]: { size: 10, label: 'Small' },
  [WATERMARK_SIZES.SMALL_MEDIUM]: { size: 15, label: 'Small Medium' },
  [WATERMARK_SIZES.MEDIUM]: { size: 20, label: 'Medium' },
  [WATERMARK_SIZES.MEDIUM_LARGE]: { size: 30, label: 'Medium Large' },
  [WATERMARK_SIZES.LARGE]: { size: 50, label: 'Large' },
  [WATERMARK_SIZES.FULL_SIZE]: { size: 100, label: 'Watermark size' },
  // [WATERMARK_SIZES.FILL]: { size: 100, label: 'Fill' },
};
