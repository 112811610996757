import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Form,
  FormFooter,
  FormResponseType,
  Row,
  Space,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import ClientPaymentFormContainer from './ClientPaymentFormContainer';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  eventId: string;
  addPayment: (eventId: string, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  showBulkAddClientPayments: () => void;
  hasActiveSubscription: boolean;
};

export default function AddClientPaymentPanel(props: Props): JSX.Element | null {
  const { show, addPayment, isLoading, errors, closePanel, eventId, showBulkAddClientPayments, hasActiveSubscription } =
    props;
  if (!show) return null;
  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Add client payment</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={(data) => addPayment(eventId, data)}
          formSpec={{
            is_settled: {
              type: 'boolean',
            },
            payment_category: {
              type: 'number',
            },
          }}
        >
          <ClientPaymentFormContainer />
          <Space vertical size={8} />
          <Row>
            <Col size={null} screenSMSize={4}>
              <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={showBulkAddClientPayments}>
                Instead, add bulk payment?
              </Button>
            </Col>
            <Space />
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button
              size={BUTTON_SIZE.LG}
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!hasActiveSubscription}
              disabledMessage={MESSAGES.SUBSCRIPTIONS.INACTIVE}
            >
              Add
            </Button>
          </Row>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
