import { connect } from 'react-redux';
import AddExpensePaymentPanel from './AddExpensePaymentPanel';
import { LOADINGS } from '../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { addPaymentForEvent } from '../CRMPaymentDataDuck';
import { PAYMENT_TYPES } from '../CRMPaymentService';
import { hasActiveSubscriptionSelector } from '../../../settings/subscriptions/SubscriptionsDataDuck';

const PANEL_KEY = LOADINGS.CRM_PAYMENTS.SHOW_ADD_EXPENSE_PAYMENT_PANEL;
const COMMON_KEY = LOADINGS.CRM_PAYMENTS.ADD;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;

  return {
    eventId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    hasActiveSubscription: hasActiveSubscriptionSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPayment: (eventId: string, data) => {
      dispatch(addPaymentForEvent(dispatch, eventId, data, PAYMENT_TYPES.INFLOW));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddExpensePaymentPanel);
