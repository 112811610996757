import * as React from 'react';
import styles from './shadow.module.css';

export enum SHADOW_STYLES {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface ShadowProps {
  children: React.ReactNode;
  style?: SHADOW_STYLES;
}

export function Shadow(props: ShadowProps): React.ReactElement {
  const { children, style = SHADOW_STYLES.TOP } = props;
  return <div className={styles[`shadow--style-${style}`]}>{children}</div>;
}
