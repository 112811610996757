import { List } from 'immutable';
import { CancelToken, CancelTokenSource } from 'axios';
import { logAction } from './ErrorTracker';

const isDebugging = false;

function debug(...args) {
  if (!isDebugging) return;
  console.info(...args);
}

type PromiseListItemType = {
  bucket: string;
  url: string;
  source: CancelTokenSource;
};

let PROMISES: List<PromiseListItemType> = List();

export function getPromiseInQ() {
  return PROMISES.toJSON();
}

function getPendingPromiseForBucket(bucket: string) {
  if (!bucket) return null;
  return PROMISES.find((promise) => promise.bucket === bucket);
}

export function cancelPromise(source: CancelTokenSource, message = 'Operation canceled by the user.') {
  source.cancel(message);
}

function removePendingPromise(promiseToCancel: PromiseListItemType, message) {
  cancelPromise(promiseToCancel.source, message);
  PROMISES = PROMISES.filter((promise) => promise.bucket !== promiseToCancel.bucket);
}

export function addPendingPromise(pendingPromiseSourceObj, url: string, bucket: string) {
  const pendingPromiseInBucket = getPendingPromiseForBucket(bucket);

  if (pendingPromiseInBucket) {
    debug(`*Promise in bucket:${bucket}`, pendingPromiseInBucket, PROMISES);
    removePendingPromise(pendingPromiseInBucket, `Remove old promise in the same bucket: ${bucket}`);
  }

  PROMISES = PROMISES.push({
    source: pendingPromiseSourceObj,
    url,
    bucket,
  });
  debug('Promises after adding', PROMISES);
}

export function removeFromPendingPromise(completedPromiseCancelToken?: CancelToken) {
  PROMISES = PROMISES.filter((promise) => promise.source.token != completedPromiseCancelToken);
  // console.log('REMOVE:', PROMISES);
}

export function cancelAllPendingPromise() {
  const length = PROMISES.size;
  if (length === 0) return;
  logAction('Cancelling all pending promises', { no_of_promises: length });

  PROMISES.forEach(({ bucket, source, url }) => {
    if (!bucket) {
      debug('cancel', bucket, url);
      cancelPromise(source);
    } else debug(bucket);
  });
  PROMISES = List();
}
