import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { commonUISelector } from '../src/app/reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('MODAL', true);

export const toggleModalVisibility = createAction(
  getActionType('TYPE', 'SHOW'),
  (dispatch: Dispatch, type: string, show: boolean) => ({
    [type]: show,
  })
);

export const setModalOptions = createAction(
  getActionType('OPTIONS', 'SET'),
  (dispatch: Dispatch, type: string, options: any) => ({
    [type]: options,
  })
);

const initialState = {
  items: IMap({}),
  itemsOptions: IMap({}),
};

export default handleActions(
  {
    [toggleModalVisibility.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [setModalOptions.toString()]: (state, action) => ({
      ...state,
      itemsOptions: state.itemsOptions.merge(action.payload),
    }),
  },
  initialState
);

export const modalsSelector = createSelector(
  commonUISelector,
  (state: any) => state.modal.items
);
export const modalsOptionsSelector = createSelector(
  commonUISelector,
  (state: any) => state.modal.itemsOptions
);

export const isModalOpenSelector = (type: string) =>
  createSelector(modalsSelector, (modals: any) => !!modals.get(type));

export const isPopoverOpenSelector = (type: string) =>
  createSelector(modalsSelector, (popover: any) => !!popover.get(type));

export const modalOptionsSelector = (type: string) =>
  createSelector(modalsOptionsSelector, (modals: any) => modals.get(type));
