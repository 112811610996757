import React from 'react';
import { FolderService, ProjectService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import {
  Col,
  COLOR_SHADES,
  GroupedButton,
  GroupedButtons,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_FOLDER_STYLES,
  ICON_SIZES,
  IconFolder,
  Link,
  ListItemAction,
  NOTIFICATION_DOT_STYLES,
  NotificationDot,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  Tooltip,
} from '@premagic/myne';

import { FOLDER_STATUS } from '../../../../common/Constants';
import AccountProjectItemOptionMenuContainer from './AccountProjectItemOptionMenuContainer';
import APP_URLS from '../../services/AppRouteURLService';

type Props = {
  project: ProjectService.ProjectType;
  folderStatus: FOLDER_STATUS;
  hasPendingAction: boolean;
  projectFolderTypesLinks: {
    [FolderService.FOLDER_TYPES.HIGHLIGHT]?: string;
    [FolderService.FOLDER_TYPES.CLIENT_WEBSITE]?: string;
    [FolderService.FOLDER_TYPES.SELECTION]?: string;
  };
};

const FOLDER_STYLE = {
  [FOLDER_STATUS.DRAFT]: ICON_FOLDER_STYLES.PRIMARY,
  [FOLDER_STATUS.WAITING_FOR_APPROVAL]: ICON_FOLDER_STYLES.SECONDARY,
  [FOLDER_STATUS.REVIEW_COMPLETED]: ICON_FOLDER_STYLES.TERTIARY,
  [FOLDER_STATUS.DONE]: ICON_FOLDER_STYLES.TERTIARY,
};

const tooltipMessages = {
  [FOLDER_STATUS.DRAFT]: 'Waiting for you to upload photos and share with client',
  [FOLDER_STATUS.WAITING_FOR_APPROVAL]: 'Waiting for client to complete selection',
  [FOLDER_STATUS.REVIEW_COMPLETED]: 'Client has completed selection of all folder',
  [FOLDER_STATUS.DONE]: 'Client has completed selection of all folder',
};

export default function AccountProjectItem(props: Props) {
  const { folderStatus, hasPendingAction, project, projectFolderTypesLinks } = props;
  const { project_name: projectName, project_id: projectId, is_archive: isArchived, event_id: eventId } = project || {};
  const url = eventId
    ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
        eventId,
      })
    : BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.DETAILS, {
        projectId,
      });

  return (
    <Col size={12}>
      <Row vcenter>
        <Col size={8}>
          <Link to={url}>
            {hasPendingAction && <NotificationDot style={NOTIFICATION_DOT_STYLES.LIST} />}
            <Row vcenter>
              <Tooltip message={tooltipMessages[folderStatus]}>
                <IconFolder style={FOLDER_STYLE[folderStatus]} />
              </Tooltip>
              <Space size={2} />
              <Text
                boldness={TEXT_BOLDNESS.BOLD}
                color={isArchived ? COLOR_SHADES.LIGHT : COLOR_SHADES.DARKER}
                ellipsis
                block
              >
                {projectName}
              </Text>
            </Row>
          </Link>
        </Col>
        <ListItemAction>
          <AccountProjectItemOptionMenuContainer projectId={projectId} />
        </ListItemAction>
      </Row>
      {!isArchived && (
        <Row vcenter>
          <Space size={8} />
          <GroupedButtons>
            {Object.entries(projectFolderTypesLinks).map(([folderType, link]) => {
              const folderTypeDetails = FolderService.FOLDER_TYPE_DETAILS[folderType];
              return (
                <GroupedButton disabled={!link} link={link} key={folderType}>
                  <Icon name={folderTypeDetails.icon} accentStyle={ICON_ACCENT_STYLES.FILL} size={ICON_SIZES.SM} />
                </GroupedButton>
              );
            })}
          </GroupedButtons>
        </Row>
      )}
    </Col>
  );
}
