// Add Type to TEXT_GROUP_TYPES also
import React from 'react';
import {
  ColorPicker,
  DatePicker,
  DatePickerWithTimezoneSelector,
  ImageUploader,
  Input,
  Textarea,
  FileUploader,
  InputCurrency,
  INPUT_SIZES,
  RealCheckbox,
} from './Form';
import { LibRichTextArea, LibRichTextAreaInlineSimple } from './RichTextEditor/LibRichTextArea';

export const RichTextArea = LibRichTextArea;
export const RichTextAreaInline = LibRichTextAreaInlineSimple;

export enum INPUT_TYPES {
  TEXT = 'text',
  NUMBER = 'number',
  DATETIME = 'datetime',
  DATETIME_WITH_TZ = 'datetime-tz',
  DATE = 'date',
  TEXTAREA = 'textarea',
  RICH_TEXTAREA = 'rich-textarea',
  RICH_TEXTAREA_INLINE_SIMPLE = 'rich-textarea-inline-simple',
  FILE = 'file',
  IMAGE = 'image',
  URL = 'url',
  COLOR = 'color',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  TEL = 'tel',
  CHECKBOX = 'checkbox',
}

const INPUT_TYPES_COMPONENTS = {
  [INPUT_TYPES.TEXT]: Input,
  [INPUT_TYPES.NUMBER]: Input,
  [INPUT_TYPES.DATE]: DatePicker,
  [INPUT_TYPES.DATETIME]: (props) => <DatePicker showTimePicker {...props} />,
  [INPUT_TYPES.DATETIME_WITH_TZ]: (props) => <DatePickerWithTimezoneSelector showTimePicker {...props} />,
  [INPUT_TYPES.TEXTAREA]: Textarea,
  [INPUT_TYPES.RICH_TEXTAREA]: RichTextArea,
  [INPUT_TYPES.RICH_TEXTAREA_INLINE_SIMPLE]: RichTextAreaInline,
  [INPUT_TYPES.FILE]: FileUploader,
  [INPUT_TYPES.IMAGE]: ImageUploader,
  [INPUT_TYPES.URL]: Input,
  [INPUT_TYPES.TEL]: Input,
  [INPUT_TYPES.CURRENCY]: InputCurrency,
  [INPUT_TYPES.PERCENTAGE]: (props) => (
    <Input {...props} type="number" min={0} max={100} step={1} size={INPUT_SIZES.XS} />
  ),
  [INPUT_TYPES.COLOR]: ColorPicker,
  [INPUT_TYPES.CHECKBOX]: RealCheckbox,
};

export function hasComponentForInputType(type: string | INPUT_TYPES): boolean {
  return type in INPUT_TYPES_COMPONENTS;
}

export function getComponentForInputType(type: INPUT_TYPES) {
  return INPUT_TYPES_COMPONENTS[type];
}
