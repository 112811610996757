import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ArrayUtils, ErrorTracker } from '@premagic/utils';

import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { CRMPaymentCategoriesSchema } from '../../../../schema/Schemas';
import { CRMPaymentCategoryType, fetchPaymentsCategory, PAYMENT_TYPES } from './CRMPaymentService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_PAYMENT_CATEGORY');

export const setPaymentsCategoryData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const LOADING_KEYS = LOADINGS.CRM_PAYMENT_CATEGORY;

export const fetchPaymentCategories = createAction(getActionType('DATA', 'FETCH'), async (dispatch: Dispatch) => {
  const loadingKey = LOADING_KEYS.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  try {
    const data = await fetchPaymentsCategory();
    const normalizedData = normalize(data.results, CRMPaymentCategoriesSchema);
    dispatch(setPaymentsCategoryData(dispatch, normalizedData.entities.categories));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
  } catch (e) {
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    ErrorTracker.logError('Payment Category: fetch failed', e);
  }
});

type PaymentCategoryStateType = {
  items: IMap<string, CRMPaymentCategoryType>;
};

const initialState = {
  items: IMap([]),
};

export default handleActions(
  {
    [setPaymentsCategoryData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const paymentCategoriesDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.crm.categories as PaymentCategoryStateType,
);

const paymentCategoriesItemsSelector = createSelector(paymentCategoriesDataSelector, (state) =>
  state.items.sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.name, b.name)),
);

export const paymentCategoriesSelector = createSelector(paymentCategoriesItemsSelector, (items) => items.toJSON());

export const clientPaymentCategoriesSelector = createSelector(paymentCategoriesItemsSelector, (items) =>
  items
    .filter((item) => item.payment_type === PAYMENT_TYPES.INFLOW)
    .valueSeq()
    .toArray(),
);

export const clientPaymentCategoriesIdsSelector = createSelector(clientPaymentCategoriesSelector, (items) =>
  items.map((item) => item.id),
);

export const expensePaymentCategoriesSelector = createSelector(paymentCategoriesItemsSelector, (items) =>
  items
    .filter((item) => item.payment_type === PAYMENT_TYPES.OUTFLOW)
    .valueSeq()
    .toArray(),
);
export const expensePaymentCategoriesIdsSelector = createSelector(expensePaymentCategoriesSelector, (items) =>
  items.map((item) => item.id),
);

export const quoteCategorySelector = createSelector(paymentCategoriesItemsSelector, (items) =>
  items.find((item) => item.payment_type === PAYMENT_TYPES.QUOTE),
);
