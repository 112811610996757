import { HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';

const APIS = APIURLService.API_URLS.CRM_USERS;

export enum USER_ROLE {
  OWNER = 'OWNER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  MANAGER = 'MANAGER',
  MARKETING_MANAGER = 'MARKETING_MANAGER',
  ACCOUNTS = 'ACCOUNTS',
  EVENT_OPERATOR = 'EVENT_OPERATOR',
  SALES_REP = 'SALES_REP',
  EMPLOYEE = 'EMPLOYEE',
  EXTERNAL_MEMBER = 'EXTERNAL_MEMBER',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
}

export type UserTypeDetails = {
  id: USER_ROLE;
  title: string;
  info: string;
};

export const USER_ROLE_FOR_PHOTOGRAPHER_DETAILS: Partial<Record<USER_ROLE, UserTypeDetails>> = {
  [USER_ROLE.OWNER]: {
    id: USER_ROLE.OWNER,
    title: 'Owner',
    info: 'Owner of the account',
  },
  [USER_ROLE.SUPER_ADMIN]: {
    id: USER_ROLE.SUPER_ADMIN,
    title: 'Super admin',
    info: 'Access to everything in Premagic',
  },
  [USER_ROLE.ACCOUNTS]: {
    id: USER_ROLE.ACCOUNTS,
    title: 'Accountant',
    info: 'Access to all the Financials and Events',
  },
  [USER_ROLE.MANAGER]: {
    id: USER_ROLE.MANAGER,
    title: 'Manager',
    info: 'Access to Manage Events, wont have access to Financials Reports',
  },
  [USER_ROLE.EVENT_OPERATOR]: {
    id: USER_ROLE.EVENT_OPERATOR,
    title: 'Event Operator',
    info: 'Access to Manage Events, wont have access to Event Financials ',
  },
  [USER_ROLE.SALES_REP]: {
    id: USER_ROLE.SALES_REP,
    title: 'Sales Rep',
    info: 'Access to Leads, wont have access to Event Financials ',
  },
  [USER_ROLE.EMPLOYEE]: {
    id: USER_ROLE.EMPLOYEE,
    title: 'Employee',
    info: 'Access to Events assigned',
  },
  [USER_ROLE.EXTERNAL_MEMBER]: {
    id: USER_ROLE.EXTERNAL_MEMBER,
    title: 'External member',
    info: 'Will only receive notification about the events',
  },
};

export const USER_ROLE_DETAILS: Partial<Record<USER_ROLE, UserTypeDetails>> = {
  [USER_ROLE.OWNER]: {
    id: USER_ROLE.OWNER,
    title: 'Owner',
    info: 'Owner of the account',
  },
  [USER_ROLE.SUPER_ADMIN]: {
    id: USER_ROLE.SUPER_ADMIN,
    title: 'Super admin',
    info: 'Access to everything in Premagic',
  },
  [USER_ROLE.MANAGER]: {
    id: USER_ROLE.MANAGER,
    title: 'Event Manager',
    info: 'Access to Manage Events',
  },
  [USER_ROLE.MARKETING_MANAGER]: {
    id: USER_ROLE.MARKETING_MANAGER,
    title: 'Marketing Manager',
    info: 'Access to all the event and permission to manage the contents',
  },
  [USER_ROLE.SALES_REP]: {
    id: USER_ROLE.SALES_REP,
    title: 'Sales Rep',
    info: 'Access to Event',
  },
  [USER_ROLE.EMPLOYEE]: {
    id: USER_ROLE.EMPLOYEE,
    title: 'Standard User',
    info: 'Access to Events via links shared',
  },
  [USER_ROLE.PHOTOGRAPHER]: {
    id: USER_ROLE.PHOTOGRAPHER,
    title: 'Photographer',
    info: 'Will be able to view all the photos in the event and upload the photos',
  },
};

export enum USER_SERVICE_TYPE {
  DEFAULT = 'DEFAULT',
  CAMERA_MAN = 'CAMERA_MAN',
  LIGHT_MAN = 'LIGHT_MAN',
  TECHNICIAN = 'TECHNICIAN',
  HELPER = 'HELPER',
}
export type UserServiceTypeDetails = {
  id: USER_SERVICE_TYPE;
  title: string;
  icon: string;
};
export const USER_SERVICE_TYPE_DETAILS: Record<USER_SERVICE_TYPE, UserServiceTypeDetails> = {
  [USER_SERVICE_TYPE.DEFAULT]: {
    id: USER_SERVICE_TYPE.DEFAULT,
    title: 'Account Administrator',
    icon: 'corporate',
  },
  [USER_SERVICE_TYPE.CAMERA_MAN]: {
    id: USER_SERVICE_TYPE.CAMERA_MAN,
    title: 'Camera Man',
    icon: 'photographer',
  },
  [USER_SERVICE_TYPE.LIGHT_MAN]: {
    id: USER_SERVICE_TYPE.LIGHT_MAN,
    title: 'Light Man',
    icon: 'lightboy',
  },
  [USER_SERVICE_TYPE.HELPER]: {
    id: USER_SERVICE_TYPE.HELPER,
    title: 'Helper',
    icon: 'helper',
  },
  [USER_SERVICE_TYPE.TECHNICIAN]: {
    id: USER_SERVICE_TYPE.TECHNICIAN,
    title: 'Technician',
    icon: 'technician',
  },
};

export type NewUserType = {
  name: string;
  email?: string;
  phone_number: string;
  role: USER_ROLE;
  service_role: USER_SERVICE_TYPE;
  charges_per_hour: number;
};

export type UserType = NewUserType & {
  id: string;
  status: string;
  created_at: string;
  update_at: string;
  active: boolean;
  real_me: string; // real_me is used to identify the real user in-case of login as admin
  is_staff: boolean;
};

export function fetchUsers(): Promise<{
  results: Array<UserType>;
  count: number;
}> {
  return HttpUtils.get(APIS.LIST, {
    params: { limit: 100 },
  }).then((response) => response.data);
}

export function createUser(data: NewUserType): Promise<UserType> {
  return HttpUtils.post(APIS.LIST, data, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function editUser(id: string, data: Partial<UserType>): Promise<UserType> {
  return HttpUtils.patch(APIS.DETAILS(id), data, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function deleteUser(id: string): Promise<UserType> {
  return HttpUtils.httpDelete(APIS.DETAILS(id)).then((response) => response.data);
}

export function fetchRequestingUser(): Promise<UserType> {
  return HttpUtils.get(APIS.REQUESTING_USER).then((response) => response.data);
}
