import { EventPosterService } from '@premagic/core';
import { FormGroup, FormLabel, Select } from '@premagic/myne';
import { AVATAR_STYLE, AVATAR_STYLE_DETAILS, PosterWidgetAvatarType } from '@premagic/poster-creator';

type Props = {
  poster: EventPosterService.EventPosterType;
  editPoster: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetAvatarType;
  }) => void;
  projectId: string;
  widget: PosterWidgetAvatarType;
};

export default function EventPosterAvatarStyleSelect(props: Props) {
  const { poster, editPoster, projectId, widget } = props;

  const options = Object.entries(AVATAR_STYLE_DETAILS).map(([key, value]) => ({
    label: value.title,
    value: key,
  }));
  const defaultOption = options.find((option) => option.value === (widget.style || AVATAR_STYLE.CIRCLE));

  function onChange(option) {
    const { value } = option;
    const updatedWidget = Object.assign({}, widget, { style: value });
    editPoster({ projectId, widgetToUpdate: updatedWidget, poster });
  }

  return (
    <FormGroup>
      <FormLabel>Style</FormLabel>
      <Select name="" options={options} onChange={onChange} value={defaultOption} isMulti={false} />
    </FormGroup>
  );
}
