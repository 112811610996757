import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { commonUISelector } from '../selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('LOADING', true);
export const removeLoadingState = createAction(
  getActionType('STATE', 'REMOVE'),
  (dispatch: Dispatch, type: string) => type,
);

export const toggleLoadingState = createAction(
  getActionType('STATE', 'TOGGLE'),
  (dispatch: Dispatch, type: string, isLoading: boolean) => ({
    [type]: isLoading,
  }),
);

export const initProgressLoading = createAction(
  getActionType('PROGRESS', 'INIT'),
  (dispatch: Dispatch, type: string, length: number) => ({
    [type]: {
      length,
      progress: 0,
    },
  }),
);

export const setProgressTick = createAction(
  getActionType('PROGRESS', 'SET'),
  (dispatch: Dispatch, type: string, tick: number) => {
    const progress = tick;
    return {
      type,
      progress,
    };
  },
);

type StateType = {
  items: IMap<string, boolean>;
  progresses: IMap<string, unknown>;
};

const initialState = {
  items: IMap({
    'app-loading': true,
  }),
  progresses: IMap({}),
};

type PayloadType = {
  payload;
};
export const LoadingReducer = handleActions<StateType>(
  {
    [toggleLoadingState.toString()]: (state, action: PayloadType) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeLoadingState.toString()]: (state, action: PayloadType) => ({
      ...state,
      items: state.items.delete(action.payload),
    }),
    [initProgressLoading.toString()]: (state, action: PayloadType) => ({
      ...state,
      progresses: state.progresses.merge(action.payload),
    }),
    [setProgressTick.toString()]: (state, action: PayloadType) => ({
      ...state,
      progresses: state.progresses.update(action.payload.type, (item: any) => ({
        ...item,
        progress: item.progress + action.payload.progress,
      })),
    }),
  },
  initialState,
);

export const loadingsDataSelector = createSelector(commonUISelector, (state) => state.loading as StateType);

export const isLoadingSelector = (type: string, defaultValue = false) =>
  createSelector(loadingsDataSelector, (state) => {
    if (state.items.has(type)) {
      return !!state.items.get(type);
    }
    return defaultValue;
  });

export const progressSelector = (type: string) =>
  createSelector(loadingsDataSelector, (state) => state.progresses.get(type) || {});
