import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  InnerPageHeader,
  InnerPage,
  InnerPageContent,
  Dialog,
  DIALOG_PLACEMENT,
  DIALOG_SIZES,
  Space,
  FormLabel,
  Form,
  FormGroup,
  Input,
  FormFooter,
  INPUT_SIZES,
} from '@premagic/myne';
import APP_URLS from '../../../services/AppRouteURLService';
import WorkflowListItemsContainer from './list/WorkflowListItemsContainer';
import { WorkflowType } from './service/WorkflowService';

type CreateWorkflowDialogButtonProps = {
  createNewWorkflow: (workflowName: string) => void;
  isLoading: boolean;
};

function CreateWorkflowDialogButton(props: CreateWorkflowDialogButtonProps) {
  const { createNewWorkflow, isLoading } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const target = useRef(null);

  function handleOnSubmit(value) {
    const { workflow_name: workflowName } = value.data;
    createNewWorkflow(workflowName);
  }

  return (
    <>
      <Button style={BUTTON_STYLES.PRIMARY} onClick={() => setIsDialogOpen(!isDialogOpen)} ref={target}>
        New Workflow
      </Button>
      {isDialogOpen && (
        <Dialog
          target={target.current}
          show={isDialogOpen}
          placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
          onClose={() => setIsDialogOpen(false)}
          size={DIALOG_SIZES.MD}
        >
          <Space size={2}>
            <Form onSubmit={handleOnSubmit}>
              <FormGroup>
                <FormLabel>Workflow Name</FormLabel>
                <Input
                  name="workflow_name"
                  placeholder="eg. Wedding workflow"
                  autoFocus
                  required
                  size={INPUT_SIZES.AUTO}
                />
              </FormGroup>
              <FormFooter>
                <Button style={BUTTON_STYLES.PRIMARY} block isLoading={isLoading}>
                  Add
                </Button>
              </FormFooter>
            </Form>
          </Space>
        </Dialog>
      )}
    </>
  );
}

type Props = {
  initData: () => void;
  workflowList: Array<WorkflowType>;
  isLoading: boolean;
  isCreatingWorkflow: boolean;
  createNewWorkflow: (workflowName: string) => void;
};

export default function WorkflowPage(props: Props): JSX.Element {
  const { initData, workflowList, isLoading, createNewWorkflow, isCreatingWorkflow } = props;

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="template"
        title="Workflow"
        backTo={APP_URLS.SETTINGS.INDEX}
        rightActions={
          <CreateWorkflowDialogButton createNewWorkflow={createNewWorkflow} isLoading={isCreatingWorkflow} />
        }
      >
        Workflows
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <WorkflowListItemsContainer workflowList={workflowList} />
      </InnerPageContent>
    </InnerPage>
  );
}
