export type PresetDesignOption = {
  id: PRESET_DESIGN_IDS;
  value: string;
  title: string;
  primaryFontFamily: string;
  secondaryFontFamily: string;
  fontSource: string;
  backgroundColor: string;
  fontColor: string;
  accentColor: string;
  svgNamePrimary: string;
  svgNameSecondary: string;
  group: PRESET_DESIGN_GROUPS;
  colors: {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
  };
};

export enum PRESET_DESIGN_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum PRESET_DESIGN_IDS {
  WHITE = 1,
  FOGGY = 2,
  NIGHT_GREY = 3,
  SPACE_GREY = 4,
  LAGUNA = 5,
  BAIKAL = 6,
  BLED = 7,
  CRATER = 8,
  HUTT_LAGOON = 9,
  ATTABAD = 10,
  NAKURUM = 11,
  ATITLAN = 12,
  CHELIA = 13,
  CAMEROON = 14,
  CHAD = 15,
  KOUSSI = 16,
  KARISIMBI = 17,
  BAMBA = 18,
  ELGON = 19,
  LESOTHO = 20,
  KAWAGUCHIKO = 21,
  THEL_NOI = 22,
  TAHOE = 23,
  PICHOLA = 24,
  LUCERNE = 25,
  COMO = 26,
  CROIX = 27,
  MINNEWANKA = 28,
  TITICACA = 29,
  BUTTERMERE = 30,
  SAIMAA = 31,
  MALAWI = 32,
  JOKULSARLON = 33,
  POWELL = 34,
  LAGO_PEHE = 35,
  PHEWA_TAL = 36,
  PLITVICE = 37,
  RAKOTZSEE = 38,
  ALMOSTBLACK = 39,
  MIDNIGHT_ICE = 40,
  MAROON_KITTY = 41,
  SLEEPY_WINDOW = 42,
  NIGHT_POOL = 43,
}

export enum PRESET_DESIGN_GROUPS {
  BLACK_AND_WHITE = 1,
  PASTEL_LIGHT = 2,
  PASTEL_DARK = 3,
  BRIGHT = 4,
  DARK = 5,
}

export const PRESET_DESIGN: Record<PRESET_DESIGN_IDS, PresetDesignOption> = {
  [PRESET_DESIGN_IDS.WHITE]: {
    id: PRESET_DESIGN_IDS.WHITE,
    value: 'white',
    title: 'White',
    primaryFontFamily: 'Jomolhari',
    secondaryFontFamily: 'Montserrat',
    fontSource: 'google',
    backgroundColor: '#FFFFFF',
    fontColor: '#222831',
    accentColor: '#D8B384',
    svgNamePrimary: 'white_gallery_preset__primary',
    svgNameSecondary: 'white_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BLACK_AND_WHITE,
    colors: {
      primaryColor: '#222831',
      secondaryColor: '#9C9DBA',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.FOGGY]: {
    id: PRESET_DESIGN_IDS.FOGGY,
    value: 'foggy',
    title: 'Foggy',
    primaryFontFamily: 'Archivo Narrow',
    secondaryFontFamily: 'Playfair Display',
    fontSource: 'google',
    backgroundColor: '#ECECEC',
    fontColor: '#222831',
    accentColor: '#818181',
    svgNamePrimary: 'foggy_gallery_preset__primary',
    svgNameSecondary: 'foggy_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BLACK_AND_WHITE,
    colors: {
      primaryColor: '#9293B9',
      secondaryColor: '#818181',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.NIGHT_GREY]: {
    id: PRESET_DESIGN_IDS.NIGHT_GREY,
    value: 'night-grey',
    title: 'Night grey',
    primaryFontFamily: 'Abril Fatface',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#2D2D2D',
    fontColor: '#FFFFFF',
    accentColor: '#DBD5C0',
    svgNamePrimary: 'night_grey_gallery_preset__primary',
    svgNameSecondary: 'night_grey_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BLACK_AND_WHITE,
    colors: {
      primaryColor: '#ECECEC',
      secondaryColor: '#CDB196',
      tertiaryColor: '#F9F9F9',
    },
  },
  [PRESET_DESIGN_IDS.SPACE_GREY]: {
    id: PRESET_DESIGN_IDS.SPACE_GREY,
    value: 'space-grey',
    title: 'Space grey',
    primaryFontFamily: 'Arizonia',
    secondaryFontFamily: 'Quicksand',
    fontSource: 'google',
    backgroundColor: '#575757',
    fontColor: '#FFFFFF',
    accentColor: '#D8B384',
    svgNamePrimary: 'space_grey_gallery_preset__primary',
    svgNameSecondary: 'space_grey_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BLACK_AND_WHITE,
    colors: {
      primaryColor: '#D8B384',
      secondaryColor: '#F8F0D8',
      tertiaryColor: '#F9F9F9',
    },
  },
  [PRESET_DESIGN_IDS.LAGUNA]: {
    id: PRESET_DESIGN_IDS.LAGUNA,
    value: 'laguna',
    title: 'Laguna',
    primaryFontFamily: 'Jomolhari',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#CEE5D0',
    fontColor: '#222831',
    accentColor: '#D8B384',
    svgNamePrimary: 'laguna_gallery_preset__primary',
    svgNameSecondary: 'laguna_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#67876A',
      secondaryColor: '#A3917B',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.BAIKAL]: {
    id: PRESET_DESIGN_IDS.BAIKAL,
    value: 'baikal',
    title: 'Baikal',
    primaryFontFamily: 'Archivo Narrow',
    secondaryFontFamily: 'Cormorant',
    fontSource: 'google',
    backgroundColor: '#F3E9DD',
    fontColor: '#222831',
    accentColor: '#F3E9DD',
    svgNamePrimary: 'baikal_gallery_preset__primary',
    svgNameSecondary: 'baikal_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#C0A481',
      secondaryColor: '#97A1AA',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.BLED]: {
    id: PRESET_DESIGN_IDS.BLED,
    value: 'bled',
    title: 'Bled',
    primaryFontFamily: 'Abril Fatface',
    secondaryFontFamily: 'Manrope',
    fontSource: 'google',
    backgroundColor: '#FFE2E2',
    fontColor: '#222831',
    accentColor: '#AAAAAA',
    svgNamePrimary: 'bled_gallery_preset__primary',
    svgNameSecondary: 'bled_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#A86363',
      secondaryColor: '#8F7474',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.CRATER]: {
    id: PRESET_DESIGN_IDS.CRATER,
    value: 'crater',
    title: 'Crater',
    primaryFontFamily: 'Arizonia',
    secondaryFontFamily: 'Montserrat',
    fontSource: 'google',
    backgroundColor: '#E0ECE4',
    fontColor: '#222831',
    accentColor: '#F05945',
    svgNamePrimary: 'crater_gallery_preset__primary',
    svgNameSecondary: 'crater_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#8BA794',
      secondaryColor: '#937E7B',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.HUTT_LAGOON]: {
    id: PRESET_DESIGN_IDS.HUTT_LAGOON,
    value: 'hutt-lagoon',
    title: 'Hutt Lagoon',
    primaryFontFamily: 'Cinzel Decorative',
    secondaryFontFamily: 'Comfortaa',
    fontSource: 'google',
    backgroundColor: '#E4DCCF',
    fontColor: '#222831',
    accentColor: '#898AA6',
    svgNamePrimary: 'hutt_lagoon_gallery_preset__primary',
    svgNameSecondary: 'hutt_lagoon_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#AEA089',
      secondaryColor: '#6B6C78',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.ATTABAD]: {
    id: PRESET_DESIGN_IDS.ATTABAD,
    value: 'attabad',
    title: 'Attabad',
    primaryFontFamily: 'Carattere',
    secondaryFontFamily: 'Abel',
    fontSource: 'google',
    backgroundColor: '#E2E3ED',
    fontColor: '#222831',
    accentColor: '#D88484',
    svgNamePrimary: 'attabad_gallery_preset__primary',
    svgNameSecondary: 'attabad_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#A4A7C1',
      secondaryColor: '#9F8383',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.NAKURUM]: {
    id: PRESET_DESIGN_IDS.NAKURUM,
    value: 'nakurum',
    title: 'Nakurum',
    primaryFontFamily: 'Bebas Neue',
    secondaryFontFamily: 'Tajawal',
    fontSource: 'google',
    backgroundColor: '#E9F5F9',
    fontColor: '#222831',
    accentColor: '#ECDCB2',
    svgNamePrimary: 'nakurum_gallery_preset__primary',
    svgNameSecondary: 'nakurum_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#8FB6C3',
      secondaryColor: '#A8A49B',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.ATITLAN]: {
    id: PRESET_DESIGN_IDS.ATITLAN,
    value: 'atitlan',
    title: 'Atitlan',
    primaryFontFamily: 'Croissant One',
    secondaryFontFamily: 'Poiret One',
    fontSource: 'google',
    backgroundColor: '#EFEBEB',
    fontColor: '#222831',
    accentColor: '#FFA1A1',
    svgNamePrimary: 'atitlan_gallery_preset__primary',
    svgNameSecondary: 'atitlan_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#AA9494',
      secondaryColor: '#A58D8D',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.CHELIA]: {
    id: PRESET_DESIGN_IDS.CHELIA,
    value: 'chelia',
    title: 'Chelia',
    primaryFontFamily: 'Arizonia',
    secondaryFontFamily: 'Quicksand',
    fontSource: 'google',
    backgroundColor: '#FFF0F0',
    fontColor: '#222831',
    accentColor: '#6CA6D0',
    svgNamePrimary: 'chelia_gallery_preset__primary',
    svgNameSecondary: 'chelia_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#6CA6D0',
      secondaryColor: '#B87272',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.CAMEROON]: {
    id: PRESET_DESIGN_IDS.CAMEROON,
    value: 'cameroon',
    title: 'Cameroon',
    primaryFontFamily: 'Dynalight',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#FAF3EB',
    fontColor: '#222831',
    accentColor: '#72ABB8',
    svgNamePrimary: 'cameroon_gallery_preset__primary',
    svgNameSecondary: 'cameroon_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#72ABB8',
      secondaryColor: '#C97C7C',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.CHAD]: {
    id: PRESET_DESIGN_IDS.CHAD,
    value: 'chad',
    title: 'Chad',
    primaryFontFamily: 'Croissant One',
    secondaryFontFamily: 'Thasadith',
    fontSource: 'google',
    backgroundColor: '#EDF6F0',
    fontColor: '#222831',
    accentColor: '#D2A891',
    svgNamePrimary: 'chad_gallery_preset__primary',
    svgNameSecondary: 'chad_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#D2A891',
      secondaryColor: '#83B7B4',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.KOUSSI]: {
    id: PRESET_DESIGN_IDS.KOUSSI,
    value: 'koussi',
    title: 'Koussi',
    primaryFontFamily: 'Bree Serif',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#E9F5F9',
    fontColor: '#222831',
    accentColor: '#CDB694',
    svgNamePrimary: 'koussi_gallery_preset__primary',
    svgNameSecondary: 'koussi_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#CDB694',
      secondaryColor: '#8398B7',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.KARISIMBI]: {
    id: PRESET_DESIGN_IDS.KARISIMBI,
    value: 'karisimbi',
    title: 'Karisimbi',
    primaryFontFamily: 'Fasthand',
    secondaryFontFamily: 'Poppins',
    fontSource: 'google',
    backgroundColor: '#F0F5FF',
    fontColor: '#222831',
    accentColor: '#F89F9F',
    svgNamePrimary: 'karisimbi_gallery_preset__primary',
    svgNameSecondary: 'karisimbi_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#F89F9F',
      secondaryColor: '#8FA4C5',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.BAMBA]: {
    id: PRESET_DESIGN_IDS.BAMBA,
    value: 'bamba',
    title: 'Bamba',
    primaryFontFamily: 'Italiana',
    secondaryFontFamily: 'Gothic A1',
    fontSource: 'google',
    backgroundColor: '#F0F4E9',
    fontColor: '#222831',
    accentColor: '#93B196',
    svgNamePrimary: 'bamba_gallery_preset__primary',
    svgNameSecondary: 'bamba_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#93B196',
      secondaryColor: '#CDB196',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.ELGON]: {
    id: PRESET_DESIGN_IDS.ELGON,
    value: 'elgon',
    title: 'Elgon',
    primaryFontFamily: 'Judson',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#F8F0D8',
    fontColor: '#222831',
    accentColor: '#83B7B4',
    svgNamePrimary: 'elgon_gallery_preset__primary',
    svgNameSecondary: 'elgon_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#83B7B4',
      secondaryColor: '#CDB196',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.LESOTHO]: {
    id: PRESET_DESIGN_IDS.LESOTHO,
    value: 'lesotho',
    title: 'Lesotho',
    primaryFontFamily: 'Epilogue',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#E7E9EB',
    fontColor: '#222831',
    accentColor: '#CD94A2',
    svgNamePrimary: 'lesotho_gallery_preset__primary',
    svgNameSecondary: 'lesotho_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_LIGHT,
    colors: {
      primaryColor: '#CD94A2',
      secondaryColor: '#A3A5CF',
      tertiaryColor: '#2D2D2D',
    },
  },
  [PRESET_DESIGN_IDS.KAWAGUCHIKO]: {
    id: PRESET_DESIGN_IDS.KAWAGUCHIKO,
    value: 'kawaguchiko',
    title: 'Kawaguchiko',
    primaryFontFamily: 'Aboreto',
    secondaryFontFamily: 'Tajawal',
    fontSource: 'google',
    backgroundColor: '#85929B',
    fontColor: '#FFFFFF',
    accentColor: '#FF945F',
    svgNamePrimary: 'kawaguchiko_gallery_preset__primary',
    svgNameSecondary: 'kawaguchiko_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_DARK,
    colors: {
      primaryColor: '#FFE7E7',
      secondaryColor: '#F7EBCA',
      tertiaryColor: '#4F565C',
    },
  },
  [PRESET_DESIGN_IDS.THEL_NOI]: {
    id: PRESET_DESIGN_IDS.THEL_NOI,
    value: 'thel-noi',
    title: 'Thel Noi',
    primaryFontFamily: 'DM Serif Text',
    secondaryFontFamily: 'Manrope',
    fontSource: 'google',
    backgroundColor: '#9D9F94',
    fontColor: '#FFFFFF',
    accentColor: '#B7E576',
    svgNamePrimary: 'thel_noi_gallery_preset__primary',
    svgNameSecondary: 'thel_noi_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_DARK,
    colors: {
      primaryColor: '#FFF1E0',
      secondaryColor: '#656952',
      tertiaryColor: '#47493A',
    },
  },
  [PRESET_DESIGN_IDS.TAHOE]: {
    id: PRESET_DESIGN_IDS.TAHOE,
    value: 'tahoe',
    title: 'Tahoe',
    primaryFontFamily: 'Dynalight',
    secondaryFontFamily: 'Montserrat',
    fontSource: 'google',
    backgroundColor: '#898AA6',
    fontColor: '#FFFFFF',
    accentColor: '#D6EFED',
    svgNamePrimary: 'tahoe_gallery_preset__primary',
    svgNameSecondary: 'tahoe_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_DARK,
    colors: {
      primaryColor: '#DEFDE7',
      secondaryColor: '#CDB196',
      tertiaryColor: '#494959',
    },
  },
  [PRESET_DESIGN_IDS.PICHOLA]: {
    id: PRESET_DESIGN_IDS.PICHOLA,
    value: 'pichola',
    title: 'Pichola',
    primaryFontFamily: 'DM Serif Display',
    secondaryFontFamily: 'Poiret One',
    fontSource: 'google',
    backgroundColor: '#AAAAAA',
    fontColor: '#FFFFFF',
    accentColor: '#FFC7C7',
    svgNamePrimary: 'pichola_gallery_preset__primary',
    svgNameSecondary: 'pichola_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_DARK,
    colors: {
      primaryColor: '#DEF7FF',
      secondaryColor: '#EFF8E0',
      tertiaryColor: '#505050',
    },
  },
  [PRESET_DESIGN_IDS.LUCERNE]: {
    id: PRESET_DESIGN_IDS.LUCERNE,
    value: 'lucerne',
    title: 'Lucerne',
    primaryFontFamily: 'Epilogue',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#576F72',
    fontColor: '#FFFFFF',
    accentColor: '#E4DCCF',
    svgNamePrimary: 'lucerne_gallery_preset__primary',
    svgNameSecondary: 'lucerne_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_DARK,
    colors: {
      primaryColor: '#FFFFFF',
      secondaryColor: '#E4DCCF',
      tertiaryColor: '#576557',
    },
  },
  [PRESET_DESIGN_IDS.COMO]: {
    id: PRESET_DESIGN_IDS.COMO,
    value: 'como',
    title: 'Como',
    primaryFontFamily: 'Fasthand',
    secondaryFontFamily: 'Abel',
    fontSource: 'google',
    backgroundColor: '#797A7E',
    fontColor: '#FFFFFF',
    accentColor: '#E0ECE4',
    svgNamePrimary: 'como_gallery_preset__primary',
    svgNameSecondary: 'como_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.PASTEL_DARK,
    colors: {
      primaryColor: '#FFFFFF',
      secondaryColor: '#E0ECE4',
      tertiaryColor: '#414244',
    },
  },
  [PRESET_DESIGN_IDS.CROIX]: {
    id: PRESET_DESIGN_IDS.CROIX,
    value: 'croix',
    title: 'Croix',
    primaryFontFamily: 'Gowun Batang',
    secondaryFontFamily: 'Roboto',
    fontSource: 'google',
    backgroundColor: '#88304E',
    fontColor: '#FFFFFF',
    accentColor: '#F05945',
    svgNamePrimary: 'croix_gallery_preset__primary',
    svgNameSecondary: 'croix_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#F4C5A2',
      secondaryColor: '#C293A3',
      tertiaryColor: '#52182C',
    },
  },
  [PRESET_DESIGN_IDS.MINNEWANKA]: {
    id: PRESET_DESIGN_IDS.MINNEWANKA,
    value: 'minnewanka',
    title: 'Minnewanka',
    primaryFontFamily: 'Italiana',
    secondaryFontFamily: 'Open Sans',
    fontSource: 'google',
    backgroundColor: '#5A9E7C',
    fontColor: '#FFFFFF',
    accentColor: '#B7E576',
    svgNamePrimary: 'minnewanka_gallery_preset__primary',
    svgNameSecondary: 'minnewanka_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#F5E3CB',
      secondaryColor: '#C3E9CB',
      tertiaryColor: '#305544',
    },
  },
  [PRESET_DESIGN_IDS.TITICACA]: {
    id: PRESET_DESIGN_IDS.TITICACA,
    value: 'titicaca',
    title: 'Titicaca',
    primaryFontFamily: 'Bree Serif',
    secondaryFontFamily: 'Roboto Condensed',
    fontSource: 'google',
    backgroundColor: '#DAB88B',
    fontColor: '#222831',
    accentColor: '#B7CADB',
    svgNamePrimary: 'titicaca_gallery_preset__primary',
    svgNameSecondary: 'titicaca_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#E0F9FF',
      secondaryColor: '#F5E3CC',
      tertiaryColor: '#74634A',
    },
  },
  [PRESET_DESIGN_IDS.BUTTERMERE]: {
    id: PRESET_DESIGN_IDS.BUTTERMERE,
    value: 'buttermere',
    title: 'Buttermere',
    primaryFontFamily: 'Judson',
    secondaryFontFamily: 'Raleway',
    fontSource: 'google',
    backgroundColor: '#5EAAA8',
    fontColor: '#FFFFFF',
    accentColor: '#F05945',
    svgNamePrimary: 'buttermere_gallery_preset__primary',
    svgNameSecondary: 'buttermere_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#FFD0CA',
      secondaryColor: '#ACE3E2',
      tertiaryColor: '#376664',
    },
  },
  [PRESET_DESIGN_IDS.SAIMAA]: {
    id: PRESET_DESIGN_IDS.SAIMAA,
    value: 'saimaa',
    title: 'Saimaa',
    primaryFontFamily: 'Junge',
    secondaryFontFamily: 'Nunito Sans',
    fontSource: 'google',
    backgroundColor: '#6C99C3',
    fontColor: '#FFFFFF',
    accentColor: '#F0CA45',
    svgNamePrimary: 'saimaa_gallery_preset__primary',
    svgNameSecondary: 'saimaa_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#C9FFEF',
      secondaryColor: '#BFE0FF',
      tertiaryColor: '#46637E',
    },
  },
  [PRESET_DESIGN_IDS.MALAWI]: {
    id: PRESET_DESIGN_IDS.MALAWI,
    value: 'malawi',
    title: 'Malawi',
    primaryFontFamily: 'Jura',
    secondaryFontFamily: 'Nunito',
    fontSource: 'google',
    backgroundColor: '#AEAB63',
    fontColor: '#FFFFFF',
    accentColor: '#E23E57',
    svgNamePrimary: 'malawi_gallery_preset__primary',
    svgNameSecondary: 'malawi_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#FFE1E1',
      secondaryColor: '#E0DEAE',
      tertiaryColor: '#706D41',
    },
  },
  [PRESET_DESIGN_IDS.JOKULSARLON]: {
    id: PRESET_DESIGN_IDS.JOKULSARLON,
    value: 'jokulsarlon',
    title: 'Jokulsarlon',
    primaryFontFamily: 'Kaisei Decol',
    secondaryFontFamily: 'Work Sans',
    fontSource: 'google',
    backgroundColor: '#D2A77E',
    fontColor: '#FFFFFF',
    accentColor: '#5B87B0',
    svgNamePrimary: 'jokulsarlon_gallery_preset__primary',
    svgNameSecondary: 'jokulsarlon_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#D2F7FF',
      secondaryColor: '#F9DBC0',
      tertiaryColor: '#80664F',
    },
  },
  [PRESET_DESIGN_IDS.POWELL]: {
    id: PRESET_DESIGN_IDS.POWELL,
    value: 'powell',
    title: 'Powell',
    primaryFontFamily: 'Akshar',
    secondaryFontFamily: 'Lora',
    fontSource: 'google',
    backgroundColor: '#F05945',
    fontColor: '#222831',
    accentColor: '#93F9FF',
    svgNamePrimary: 'powell_gallery_preset__primary',
    svgNameSecondary: 'powell_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#A7F7F2',
      secondaryColor: '#FBC3BC',
      tertiaryColor: '#772C22',
    },
  },
  [PRESET_DESIGN_IDS.LAGO_PEHE]: {
    id: PRESET_DESIGN_IDS.LAGO_PEHE,
    value: 'lago-pehe',
    title: 'Lago Pehe',
    primaryFontFamily: 'Kaushan Script',
    secondaryFontFamily: 'Playfair Display',
    fontSource: 'google',
    backgroundColor: '#76CBFB',
    fontColor: '#FFFFFF',
    accentColor: '#DAB88B',
    svgNamePrimary: 'lago_pehe_gallery_preset__primary',
    svgNameSecondary: 'lago_pehe_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.BRIGHT,
    colors: {
      primaryColor: '#F8F0D8',
      secondaryColor: '#4087AE',
      tertiaryColor: '#28586F',
    },
  },
  [PRESET_DESIGN_IDS.PHEWA_TAL]: {
    id: PRESET_DESIGN_IDS.PHEWA_TAL,
    value: 'phewa-tal',
    title: 'Phewa Tal',
    primaryFontFamily: 'Jomolhari',
    secondaryFontFamily: 'Tajawal',
    fontSource: 'google',
    backgroundColor: '#1A1A2E',
    fontColor: '#FFFFFF',
    accentColor: '#E94560',
    svgNamePrimary: 'phewa_tal_gallery_preset__primary',
    svgNameSecondary: 'phewa_tal_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#F6788D',
      secondaryColor: '#7878A7',
      tertiaryColor: '#4D4D6F',
    },
  },
  [PRESET_DESIGN_IDS.PLITVICE]: {
    id: PRESET_DESIGN_IDS.PLITVICE,
    value: 'plitvice',
    title: 'Plitvice',
    primaryFontFamily: 'DM Serif Display',
    secondaryFontFamily: 'Abel',
    fontSource: 'google',
    backgroundColor: '#183661',
    fontColor: '#FFFFFF',
    accentColor: '#DD6B4D',
    svgNamePrimary: 'plitvice_gallery_preset__primary',
    svgNameSecondary: 'plitvice_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#F98A6D',
      secondaryColor: '#87ACE1',
      tertiaryColor: '#224E8A',
    },
  },
  [PRESET_DESIGN_IDS.RAKOTZSEE]: {
    id: PRESET_DESIGN_IDS.RAKOTZSEE,
    value: 'rakotzsee',
    title: 'Rakotzsee',
    primaryFontFamily: 'Abril Fatface',
    secondaryFontFamily: 'Roboto Condensed',
    fontSource: 'google',
    backgroundColor: '#362222',
    fontColor: '#F5F5F5',
    accentColor: '#93F8FF',
    svgNamePrimary: 'rakotzsee_gallery_preset__primary',
    svgNameSecondary: 'rakotzsee_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#93F9FF',
      secondaryColor: '#9C7272',
      tertiaryColor: '#6B4242',
    },
  },
  [PRESET_DESIGN_IDS.ALMOSTBLACK]: {
    id: PRESET_DESIGN_IDS.ALMOSTBLACK,
    value: 'almostblack',
    title: 'Almostblack',
    primaryFontFamily: 'Cinzel',
    secondaryFontFamily: 'Raleway',
    fontSource: 'google',
    backgroundColor: '#222831',
    fontColor: '#F5F5F5',
    accentColor: '#D65A31',
    svgNamePrimary: 'almostblack_gallery_preset__primary',
    svgNameSecondary: 'almostblack_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#FA8660',
      secondaryColor: '#8195B1',
      tertiaryColor: '#4A546B',
    },
  },
  [PRESET_DESIGN_IDS.MIDNIGHT_ICE]: {
    id: PRESET_DESIGN_IDS.MIDNIGHT_ICE,
    value: 'midnight-ice',
    title: 'Midnight Ice',
    primaryFontFamily: 'Baloo',
    secondaryFontFamily: 'Lato',
    fontSource: 'google',
    backgroundColor: '#303841',
    fontColor: '#FFFFFF',
    accentColor: '#D72323',
    svgNamePrimary: 'midnight_ice_gallery_preset__primary',
    svgNameSecondary: 'midnight_ice_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#F88181',
      secondaryColor: '#8CA3BC',
      tertiaryColor: '#4E5E6B',
    },
  },
  [PRESET_DESIGN_IDS.MAROON_KITTY]: {
    id: PRESET_DESIGN_IDS.MAROON_KITTY,
    value: 'maroon-kitty',
    title: 'Maroon kitty',
    primaryFontFamily: 'Julius Sans One',
    secondaryFontFamily: 'Poiret One',
    fontSource: 'google',
    backgroundColor: '#1C1124',
    fontColor: '#FFFFFF',
    accentColor: '#DE774E',
    svgNamePrimary: 'maroon_kitty_gallery_preset__primary',
    svgNameSecondary: 'maroon_kitty_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#DE774E',
      secondaryColor: '#A16C79',
      tertiaryColor: '#513066',
    },
  },
  [PRESET_DESIGN_IDS.SLEEPY_WINDOW]: {
    id: PRESET_DESIGN_IDS.SLEEPY_WINDOW,
    value: 'sleepy-window',
    title: 'Sleepy window',
    primaryFontFamily: 'Alata',
    secondaryFontFamily: 'Playfair Display',
    fontSource: 'google',
    backgroundColor: '#464159',
    fontColor: '#FFFFFF',
    accentColor: '#C7F0DB',
    svgNamePrimary: 'sleepy_window_gallery_preset__primary',
    svgNameSecondary: 'sleepy_window_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#C7F0DB',
      secondaryColor: '#9793A8',
      tertiaryColor: '#605B7C',
    },
  },
  [PRESET_DESIGN_IDS.NIGHT_POOL]: {
    id: PRESET_DESIGN_IDS.NIGHT_POOL,
    value: 'night-pool',
    title: 'Night Pool',
    primaryFontFamily: 'Bebas Neue',
    secondaryFontFamily: 'Lora',
    fontSource: 'google',
    backgroundColor: '#30475E',
    fontColor: '#FFFFFF',
    accentColor: '#F1935C',
    svgNamePrimary: 'night_pool_gallery_preset__primary',
    svgNameSecondary: 'night_pool_gallery_preset__secondary',
    group: PRESET_DESIGN_GROUPS.DARK,
    colors: {
      primaryColor: '#F1935C',
      secondaryColor: '#7E9BB8',
      tertiaryColor: '#406080',
    },
  },
};

export function getPresetDesignsForGroup(presetGroup: PRESET_DESIGN_GROUPS): Array<PresetDesignOption> {
  return Object.values(PRESET_DESIGN).filter((item) => item.group === presetGroup);
}
