import { connect } from 'react-redux';
import { size } from 'lodash';

import { pendingPhotographerActionsSelector } from '../photographer-actions/PhotographerActionsDataDuck';
import AccountProjectItem from './AccountProjectItem';
import { projectFolderTypesLinksSelector, projectsSelector, projectStatusSelector } from './AccountProjectsDataDuck';

function mapStateToProps(state, ownProps) {
  const projectId = ownProps.id;

  const project = projectsSelector(state)[projectId];

  return {
    hasPendingAction: size(pendingPhotographerActionsSelector(projectId)(state)) > 0,
    project,
    folderStatus: projectStatusSelector(projectId)(state),
    projectFolderTypesLinks: projectFolderTypesLinksSelector(projectId)(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProjectItem);
