import { EventAttendeesService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  Space,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import React from 'react';
import EventAttendeeFormContainer from '../crm/events/event-attendees/create/EventAttendeeFormContainer';

type Props = {
  user?: EventAttendeesService.EventAttendeeType;
  setUser: (user?: EventAttendeesService.EventAttendeeType) => void;
};

export default function EventPosterSetDummyUserButton(props: Props) {
  const { user, setUser } = props;
  const [show, setShow] = React.useState(false);
  function handleSetUser(formResponse) {
    const {
      name,
      email,
      phone,
      selfie,
      people_type: type = EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE,
      ...customFields
    } = formResponse.data;
    const data = {
      name,
      email,
      phone,
      selfie,
      people_type: type,
      custom_fields: customFields,
    } as EventAttendeesService.EventAttendeeType;

    setUser(data);
    setShow(false);
  }

  return (
    <>
      <Button onClick={() => setShow(!show)}>{user?.name ? `Update ${user.name}` : 'Set user'}</Button>
      {show && (
        <WindowPanel show={show}>
          <WindowPanelHeader onClose={() => setShow(false)}>
            <WindowPanelTitle>Set User Information for Testing Purposes</WindowPanelTitle>
          </WindowPanelHeader>
          <WindowPanelContent>
            {user && (
              <div>
                <Button style={BUTTON_STYLES.CANCEL} onClick={() => setUser()}>
                  Reset
                </Button>
                <Space vertical />
              </div>
            )}
            <Form onSubmit={handleSetUser}>
              <EventAttendeeFormContainer onChangeOfFile={() => {}} attendee={user} />
              <FormFooter>
                <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShow(false)} size={BUTTON_SIZE.LG}>
                  Cancel
                </Button>
                <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY}>
                  Save
                </Button>
              </FormFooter>
            </Form>
          </WindowPanelContent>
        </WindowPanel>
      )}
    </>
  );
}
