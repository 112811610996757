import React from 'react';
import {
  Avatar,
  AVATAR_SIZES,
  Col,
  Color,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  Label,
  Row,
  Section,
  SECTION_STYLES,
  SimpleLink,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { ArrayUtils } from '@premagic/utils';
import { USER_SERVICE_TYPE_DETAILS, UserType } from '../UsersService';

interface UserNameDialogProps {
  user: UserType;
}

function UserNameDialog(props: UserNameDialogProps) {
  const { user } = props;
  const { name, service_role: serviceRole, email, phone_number: phone, active } = user;
  const serviceRoleDetails = USER_SERVICE_TYPE_DETAILS[serviceRole];
  const rowsToShow = [
    {
      icon: 'phone',
      title: phone,
      link: `tel:${phone}`,
    },
    {
      icon: 'mail',
      title: email,
      link: `mailto:${email}`,
    },
  ];
  return (
    <Section style={SECTION_STYLES.BLACK_WITH_WHITE}>
      <Row>
        <Col size={3}>
          <Space vertical size={4} />
          <Avatar
            size={AVATAR_SIZES.LG}
            user={{
              fullName: name,
              email,
            }}
          />
        </Col>
        <Space />
        <Col size={9}>
          <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.WHITE} block ellipsis>
            {name}
          </Text>
          <Row vcenter>
            <Color shade={COLOR_SHADES.WHITE}>
              <Icon name={serviceRoleDetails.icon} accentStyle={ICON_ACCENT_STYLES.FILL} />
            </Color>
            <Space size={2} />
            <Text color={COLOR_SHADES.WHITE} block>
              {serviceRoleDetails.title}
            </Text>
          </Row>
          <Space vertical size={6} />
          {rowsToShow.map((row) => (
            <div key={row.title}>
              <Row>
                <Icon name={row.icon} size={ICON_SIZES.SM} />
                <Space size={2} />
                {row.link ? (
                  <SimpleLink href={row.link}>
                    <Text size={TEXT_SIZE.SIZE_5} color={row.title ? COLOR_SHADES.BLUE_DARK : COLOR_SHADES.LIGHT}>
                      {row.title || '(blank)'}
                    </Text>
                  </SimpleLink>
                ) : (
                  <Text size={TEXT_SIZE.SIZE_5}>{row.title}</Text>
                )}
              </Row>
              <Space vertical size={2} />
            </div>
          ))}
          {!active && <Label>De-activated</Label>}
        </Col>
      </Row>
    </Section>
  );
}

interface UserNameAvatarProps {
  user: UserType;
}

function UserNameAvatar(props: UserNameAvatarProps): JSX.Element {
  const { user } = props;
  const { name = 'Loading...', email } = user || {};
  return (
    <Avatar
      user={{
        fullName: name,
        email,
      }}
      dialogContent={<UserNameDialog user={user} />}
    />
  );
}

interface UserNameProps {
  user?: UserType;
  requestingUserId: string;
}

function UserName(props: UserNameProps): string {
  const { user, requestingUserId } = props;
  const { name = 'Loading...', active } = user || {};
  if (!user?.id) return 'Deleted user';
  if (user.id === requestingUserId) return 'You';
  if (!active) return `${name} (De-activated)`;
  return name;
}

interface UserNamesProps {
  users: Record<string, UserType>;
  userIds: Array<string>;
  avatarStyle?: boolean;
  requestingUserId: string;
}

export function UserNames(props: UserNamesProps): JSX.Element {
  const { users, userIds = [], avatarStyle = true, requestingUserId } = props;
  if (userIds.length === 0) return <Text muted>(blank)</Text>;
  if (avatarStyle)
    return (
      <div>
        {userIds.map((userId) => (
          <UserNameAvatar key={userId} user={users[userId]} />
        ))}
      </div>
    );
  const usersArray = userIds.map((userId) =>
    UserName({
      user: users[userId],
      requestingUserId,
    }),
  );
  return <>{ArrayUtils.listify(usersArray)}</>;
}
