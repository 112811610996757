import { useEffect, useMemo } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  FormErrorType,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  List,
  LoadingBox,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { debounce } from 'lodash';

import { EventAttendeesService, LiveEventService } from '@premagic/core';
import MESSAGES from '../../../../../../common/Messages';
import EventExhibitorItemContainer from './EventExhibitorItemContainer';
import EventExhibitorListMenuContainer from './EventExhibitorListMenuContainer';

type Props = {
  isLoading: boolean;
  projectId: string;
  showAddExhibitor: () => void;
  initData: (projectId: string) => void;
  errors?: FormErrorType;
  hasPoster: boolean;
  hasEventExhibitorFeatureEnabled: boolean;
  fetchPosters: (projectId: string) => void;
  exhibitorIds: Array<string>;
};

export default function EventExhibitorList(props: Props): JSX.Element {
  const {
    projectId,
    isLoading,
    showAddExhibitor,
    initData,
    errors,
    hasPoster,
    fetchPosters,
    hasEventExhibitorFeatureEnabled,
    exhibitorIds,
  } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  useEffect(() => {
    if (hasPoster) fetchPosters(projectId);
  }, [fetchPosters, hasPoster, projectId]);

  if (isLoading) return <LoadingBox isLoading />;

  if (errors)
    return (
      <div>
        <Text color={COLOR_SHADES.DANGER} block>
          Oops... Something went wrong
        </Text>
        <Text>{errors.message}</Text>
      </div>
    );

  return (
    <div>
      <Row vcenter>
        <Col rightAlighed size={null}>
          <Row vcenter>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              onClick={showAddExhibitor}
              size={BUTTON_SIZE.SM}
              disabled={!hasEventExhibitorFeatureEnabled}
              disabledMessage={MESSAGES.EVENT_EXHIBITOR.ADD_EXHIBITOR_UPGRADE}
            >
              <Icon name="plus" size={ICON_SIZES.SM} /> <Space size={1} />
              {MESSAGES.EVENT_EXHIBITOR.NAME}
            </Button>
            <EventExhibitorListMenuContainer projectId={projectId} />
          </Row>
        </Col>
      </Row>
      <Space vertical />
      {!exhibitorIds?.length && <Text muted>No event exhibitor added.</Text>}
      <Card>
        <List>
          {exhibitorIds.map((exhibitorId) => (
            <EventExhibitorItemContainer exhibitorId={exhibitorId} projectId={projectId} key={exhibitorId} />
          ))}
        </List>
      </Card>
      <Space vertical />
    </div>
  );
}
