import {
  COLOR_SHADES,
  Color,
  ICON_SIZES,
  Icon,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
  Tooltip,
} from '@premagic/myne';
import QRCodeCustomLEDPosterContainer from './custom-led-poster/QRCodeCustomLEDPosterContainer';
import QRCodePosterSponsorSelectorContainer from './sponsors-selector/QRCodePosterSponsorSelectorContainer';
import QRCodePosterLEDTextColorContainer from './led-text-color/QRCodePosterLEDTextColorContainer';

type SidebarSubtitleProps = {
  children: React.ReactNode;
};

function SidebarSubtitle(props: SidebarSubtitleProps) {
  const { children } = props;
  return (
    <Text transformUpper size={TEXT_SIZE.SIZE_6} muted boldness={TEXT_BOLDNESS.SEMI_BOLD}>
      {children}
    </Text>
  );
}

type Props = { projectId: string; folderId: string; hasEventSponsorshipFeatureEnabled?: boolean };

export default function QRCodePosterLEDScreenSettings(props: Props) {
  const { projectId, folderId, hasEventSponsorshipFeatureEnabled } = props;
  return (
    <>
      <SidebarSubtitle>Custom Poster</SidebarSubtitle>
      <Space vertical size={1} />
      <QRCodeCustomLEDPosterContainer projectId={projectId} folderId={folderId} />
      {hasEventSponsorshipFeatureEnabled && (
        <>
          <Space vertical size={4} />
          <Row vcenter>
            <SidebarSubtitle>Sponsors</SidebarSubtitle>
            <Space size={1} />
            <Tooltip placement="bottom" message="Sponsor creatives added to the gallery footer will appear here.">
              <Color inline shade={COLOR_SHADES.DARK}>
                <Icon name="info" size={ICON_SIZES.SM_XS} align="bottom" alignSize={4} />
              </Color>
            </Tooltip>
          </Row>
          <Space vertical size={1} />
          <QRCodePosterSponsorSelectorContainer projectId={projectId} folderId={folderId} />
        </>
      )}
      <Space vertical size={4} />
      <SidebarSubtitle>Text color</SidebarSubtitle>
      <Space vertical size={1} />
      <QRCodePosterLEDTextColorContainer projectId={projectId} folderId={folderId} />
    </>
  );
}
