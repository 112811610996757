import { ARTICLES } from './HelpArticlesService';

export enum FEATURE_GROUP {
  BRANDING,
  SIGNATURE_ALBUM,
  MONEY_BACK,
  PHOTO_SELECTION,
  CRM,
  SUPPORT,
  ADDONS,
  DIGITAL_INVITE,
  DAM,
  CONTENT_CREATION,
}
export const FEATURE_GROUP_DETAILS: Record<
  FEATURE_GROUP,
  {
    name: string;
    custom?: boolean;
  }
> = {
  [FEATURE_GROUP.BRANDING]: {
    name: 'Branding',
  },
  [FEATURE_GROUP.SIGNATURE_ALBUM]: {
    name: 'Signature Album/Digital Album',
  },
  [FEATURE_GROUP.MONEY_BACK]: {
    name: 'Money Back Plan',
  },
  [FEATURE_GROUP.PHOTO_SELECTION]: {
    name: 'Photo Selection/Proofing',
  },
  [FEATURE_GROUP.CRM]: {
    name: 'CRM',
  },
  [FEATURE_GROUP.SUPPORT]: {
    name: 'Support',
  },
  [FEATURE_GROUP.ADDONS]: {
    name: 'Add Ons',
    custom: true,
  },
  [FEATURE_GROUP.DIGITAL_INVITE]: {
    name: 'Micro-site',
  },
  [FEATURE_GROUP.DAM]: {
    name: 'Library',
  },
  [FEATURE_GROUP.CONTENT_CREATION]: {
    name: 'Content Creation',
  },
};

export enum PRICING_PLAN_FEATURES {
  SELECTION = 'SELECTION',
  HIGHLIGHTS = 'HIGHLIGHTS',
  HIGHLIGHTS_ANALYTICS = 'HIGHLIGHTS_ANALYTICS',
  PROJECT_DUPLICATES = 'PROJECT_DUPLICATES',
  DIGITAL_INVITE = 'DIGITAL_INVITE',
  // DIGITAL_INVITE_STANDARD = 'DIGITAL_INVITE_STANDARD',
  DIGITAL_INVITE_STANDARD_COMMISSION = 'DIGITAL_INVITE_STANDARD_COMMISSION',
  GALLERY_UPSELL_COMMISSION = 'GALLERY_UPSELL_COMMISSION',
  VIDEO_WISHES = 'VIDEO_WISHES',
  STANDARD_SUPPORT = 'STANDARD_SUPPORT',
  STANDARD_WITH_CHAT = 'STANDARD_WITH_CHAT',
  PRIORITY_SUPPORT = 'PRIORITY_SUPPORT',
  SUPPORT_TRAINING = 'SUPPORT_TRAINING',
  UNLIMITED_SELECTION = 'UNLIMITED_SELECTION',
  SELECTION_PHOTOS_LIMIT = 'SELECTION_PHOTOS_LIMIT',
  HIGHLIGHT_PHOTOS_LIMIT = 'HIGHLIGHT_PHOTOS_LIMIT',
  HIGHLIGHT_FOLDER_LIMIT = 'HIGHLIGHT_FOLDER_LIMIT',
  UNLIMITED_PROJECT_VALIDITY = 'UNLIMITED_PROJECT_VALIDITY',
  CUSTOM_SUBDOMAIN = 'CUSTOM_SUBDOMAIN',
  CUSTOM_DOMAIN = 'CUSTOM_DOMAIN',
  PORTFOLIO = 'PORTFOLIO',
  CRM = 'CRM',
  CRM_MANAGE_CREW = 'CRM_MANAGE_CREW',
  CRM_MANAGE_SCHEDULE = 'CRM_MANAGE_SCHEDULE',
  CRM_MANAGE_PAYMENT = 'CRM_MANAGE_PAYMENT',
  CRM_EVENTS = 'CRM_EVENTS',
  CRM_USERS = 'CRM_USERS',
  CRM_PROPOSAL_MANAGEMENT = 'CRM_PROPOSAL_MANAGEMENT',
  CRM_PROPOSAL_MANAGEMENT_ADVANCED = 'CRM_PROPOSAL_MANAGEMENT_ADVANCED',
  CLOUD_BACKUP = 'CLOUD_BACKUP',
  CHANGE_SIGNATURE_UPLOAD_QUALITY = 'CHANGE_SIGNATURE_UPLOAD_QUALITY',
  ACCOUNT_FINANCIALS = 'ACCOUNT_FINANCIALS',
  ALBUM_CREATOR = 'ALBUM_CREATOR',
  WHATSAPP_INTEGRATION = 'WHATSAPP_INTEGRATION',
  EMAIL_INTEGRATION = 'EMAIL_INTEGRATION',
  ONE_YEAR_PROJECT_VALIDITY = 'ONE_YEAR_PROJECT_VALIDITY',
  FORTY_FIVE_DAY_PROJECT_VALIDITY = 'FORTY_FIVE_DAY_PROJECT_VALIDITY',
  UNLIMITED_HIGHLIGHTS = 'UNLIMITED_HIGHLIGHTS',
  SIGNATURE_VIDEO_UPLOAD = 'SIGNATURE_VIDEO_UPLOAD',
  SIGNATURE_SLIDESHOW_WITH_MUSIC = 'SIGNATURE_SLIDESHOW_WITH_MUSIC',
  SIGNATURE_GET_LEADS = 'SIGNATURE_GET_LEADS',
  BULK_PHOTOS_DOWNLOADS_SELECTION = 'BULK_PHOTO_DOWNLOADS_SELECTION',
  SIGNATURE_DOWNLOAD_PERMISSION = 'SIGNATURE_DOWNLOAD_PERMISSION',
  ONE_CLICK_DOWNLOAD_ALL_PHOTOS = 'ONE_CLICK_DOWNLOAD_ALL_PHOTOS',
  ONE_CLICK_DOWNLOAD_ALL_PHOTOS_INSIDE_APP = 'ONE_CLICK_DOWNLOAD_ALL_PHOTOS_INSIDE_APP',
  SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION = 'SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION',
  REMOVE_PREMAGIC_BRANDING = 'REMOVE_PREMAGIC_BRANDING',
  ADD_LOGO_TO_GALLERY = 'ADD_LOGO_TO_GALLERY',
  ADD_LOGO_TO_EMAILS = 'ADD_LOGO_TO_EMAILS',
  SOCIAL_MEDIA_LINKS = 'SOCIAL_MEDIA_LINKS',
  CONTACT_US_FORM = 'CONTACT_US_FORM',
  ORIGINAL_BLOATED_FILE = 'ORIGINAL_BLOATED_FILE',
  ORIGINAL_RESOLUTION = 'ORIGINAL_RESOLUTION',
  QUALITY_4K = '4K_QUALITY',
  DATA_ENCRYPTION = 'DATA_ENCRYPTION',
  DISABLE_POWERED_BY_PREMAGIC_BRANDING_ON_INVITE = 'DISABLE_POWERED_BY_PREMAGIC_BRANDING_ON_INVITE',
  SIGNATURE_FOLDER_THUMBNAILS = 'SIGNATURE_FOLDER_THUMBNAILS',
  GALLERY_PASSWORD_PROTECTION = 'GALLERY_PASSWORD_PROTECTION',
  SIGNATURE_ALBUM_FAVORITE = 'SIGNATURE_ALBUM_FAVORITE',
  SELECTION_PHOTO_IMPORTANCE = 'SELECTION_PHOTO_IMPORTANCE',
  SELECTION_COPY_FROM_HIGHLIGHT = 'SELECTION_COPY_FROM_HIGHLIGHT',
  SELECTION_BUDGET = 'SELECTION_BUDGET',
  SIGNATURE_FACES = 'SIGNATURE_FACES',
  SELECTION_FACES = 'SELECTION_FACES',
  DIGITAL_INVITE_EVENT_REGISTRATION = 'DIGITAL_INVITE_EVENT_REGISTRATION',
  EVENT_REGISTRATION_CUSTOM_FORM_FIELDS = 'EVENT_REGISTRATION_CUSTOM_FORM_FIELDS',
  EVENT_ATTENDEES_LIST = 'EVENT_ATTENDEES_LIST',
  EVENT_ATTENDEES_LIST_WITH_PII = 'EVENT_ATTENDEES_LIST_WITH_PII',
  EVENT_ATTENDEES_BADGES = 'EVENT_ATTENDEES_BADGES',
  CUSTOM_EMAIL_TEMPLATE = 'CUSTOM_EMAIL_TEMPLATE',
  STORE_OFFERS = 'STORE_OFFERS',
  SIGNATURE_EXPRESS_DELIVERY = 'EXPRESS_AI_SIGNATURE_ALBUM',
  PROJECT_LEVEL_DOWNLOAD = 'PROJECT_LEVEL_DOWNLOAD',
  BLOCK_GALLERY = 'BLOCK_GALLERY',
  WATERMARK = 'SIGNATURE_ALBUM_WATERMARK',
  SIGNATURE_ALBUM_CUSTOMIZATION = 'SIGNATURE_ALBUM_CUSTOMIZATION',
  SIGNATURE_ALBUM_GUEST_GALLERY = 'SIGNATURE_ALBUM_GUEST_GALLERY',
  DIGITAL_INVITE_COVER_PHOTO_FILTERS = 'DIGITAL_INVITE_COVER_PHOTO_FILTERS',
  EVENT_SPONSORSHIP = 'EVENT_SPONSORSHIP',
  EVENT_EXHIBITOR = 'EVENT_EXHIBITOR',
  DIGITAL_INVITE_PREMIUM = 'DIGITAL_INVITE_PREMIUM',
  AI_DELIVERY = 'AI_SIGNATURE_ALBUM',
  ENABLE_SELECTION_MORE_THAN_LIMIT = 'ENABLE_SELECTION_MORE_THAN_LIMIT',
  SIGNATURE_ALBUM_GUEST_GALLERY_DOWNLOAD = 'SIGNATURE_ALBUM_GUEST_GALLERY_DOWNLOAD',
  SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY = 'SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY',
  LIBRARY = 'LIBRARY',
  EVENT_ATTENDEES_REQUEST_FOR_PII = 'EVENT_ATTENDEES_REQUEST_FOR_PII',
  EVENT_PEOPLE_BULK_REGISTRATION = 'EVENT_PEOPLE_BULK_REGISTRATION',
  EVENT_IMPORT_FROM_EXTERNAL_SOURCE = 'EVENT_IMPORT_FROM_EXTERNAL_SOURCE',
  EVENT_MARKETING = 'EVENT_MARKETING',
  EVENT_PEOPLE_NETWORKING = 'EVENT_PEOPLE_NETWORKING__ONLY_STAFF', // actual feature is EVENT_PEOPLE_NETWORKING
  CUSTOM_SOCIAL_SHARE_MESSAGES = 'CUSTOM_SOCIAL_SHARE_MESSAGES',
  EVENT_FACE_CHECKIN = 'EVENT_FACE_CHECKIN',
  WIDGET_PHOTO_GALLERY = 'WIDGET_PHOTO_GALLERY',
  SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES = 'SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES',
  AVATAR_STUDIO = 'AVATAR_STUDIO',
  MAGAZINE_CREATOR = 'MAGAZINE_CREATOR',
  EVENT_INTEGRATION = 'EVENT_INTEGRATION',
  AI_CONTENT_CREATION = 'AI_CONTENT_CREATION',
  CUSTOM_BRAND_FOR_EVENT = 'CUSTOM_BRAND_FOR_EVENT',
  TAG_FILES_TO_ATTENDEE_GALLERY = 'TAG_FILES_TO_ATTENDEE_GALLERY',
}

export type PricingPlanFeaturesDetailsType = {
  key: PRICING_PLAN_FEATURES;
  name: string;
  beta?: boolean;
  value?: string | Array<{ plan: string; value: number | string }>;
  showAlways?: boolean;
  group: Array<FEATURE_GROUP>;
  helpArticle?: ARTICLES;
  showInPricingPlanCustomFunction?: (plan: { name: string }) => void;
};

function isUltimatePlan(plan: { name: string }) {
  return plan.name.includes('ULTIMATE');
}

export const PRICING_PLAN_FEATURES_DETAILS: Record<PRICING_PLAN_FEATURES, PricingPlanFeaturesDetailsType> = {
  // Branding
  [PRICING_PLAN_FEATURES.CUSTOM_DOMAIN]: {
    key: PRICING_PLAN_FEATURES.CUSTOM_DOMAIN,
    name: 'Connect your domain',
    group: [FEATURE_GROUP.BRANDING, FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.CUSTOM_SUBDOMAIN]: {
    key: PRICING_PLAN_FEATURES.CUSTOM_SUBDOMAIN,
    name: 'Custom Subdomain',
    group: [FEATURE_GROUP.BRANDING],
  },
  [PRICING_PLAN_FEATURES.ADD_LOGO_TO_GALLERY]: {
    key: PRICING_PLAN_FEATURES.ADD_LOGO_TO_GALLERY,
    name: 'Add Logo To Gallery',
    group: [FEATURE_GROUP.BRANDING],
  },
  [PRICING_PLAN_FEATURES.ADD_LOGO_TO_EMAILS]: {
    key: PRICING_PLAN_FEATURES.ADD_LOGO_TO_EMAILS,
    name: 'Add Logo To Emails',
    group: [FEATURE_GROUP.BRANDING],
  },
  [PRICING_PLAN_FEATURES.SOCIAL_MEDIA_LINKS]: {
    key: PRICING_PLAN_FEATURES.SOCIAL_MEDIA_LINKS,
    name: 'Social Media Links',
    group: [FEATURE_GROUP.BRANDING],
  },
  [PRICING_PLAN_FEATURES.CONTACT_US_FORM]: {
    key: PRICING_PLAN_FEATURES.CONTACT_US_FORM,
    name: 'Contact Us Form',
    group: [FEATURE_GROUP.BRANDING],
  },

  // Digital invite
  [PRICING_PLAN_FEATURES.DIGITAL_INVITE_COVER_PHOTO_FILTERS]: {
    key: PRICING_PLAN_FEATURES.DIGITAL_INVITE_COVER_PHOTO_FILTERS,
    name: 'Micro-site cover photos filters',
    group: [FEATURE_GROUP.DIGITAL_INVITE],
  },
  [PRICING_PLAN_FEATURES.DIGITAL_INVITE_PREMIUM]: {
    key: PRICING_PLAN_FEATURES.DIGITAL_INVITE_PREMIUM,
    name: 'Premium Micro-site',
    group: [FEATURE_GROUP.DIGITAL_INVITE],
  },
  [PRICING_PLAN_FEATURES.EVENT_ATTENDEES_REQUEST_FOR_PII]: {
    key: PRICING_PLAN_FEATURES.EVENT_ATTENDEES_REQUEST_FOR_PII,
    name: 'Save user information in Micro-site',
    group: [FEATURE_GROUP.DIGITAL_INVITE],
  },

  // Signature
  [PRICING_PLAN_FEATURES.UNLIMITED_HIGHLIGHTS]: {
    key: PRICING_PLAN_FEATURES.UNLIMITED_HIGHLIGHTS,
    name: 'Unlimited Projects',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.HIGHLIGHT_PHOTOS_LIMIT]: {
    key: PRICING_PLAN_FEATURES.HIGHLIGHT_PHOTOS_LIMIT,
    name: 'Photo limit per project',
    value: 'Unlimited for fair usage',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS,
    name: 'Folder thumbnails cards',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.GALLERY_PASSWORD_PROTECTION]: {
    key: PRICING_PLAN_FEATURES.GALLERY_PASSWORD_PROTECTION,
    name: 'Password Protections',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM, FEATURE_GROUP.PHOTO_SELECTION],
  },
  [PRICING_PLAN_FEATURES.HIGHLIGHT_FOLDER_LIMIT]: {
    key: PRICING_PLAN_FEATURES.HIGHLIGHT_FOLDER_LIMIT,
    name: 'Folders per project',
    value: 'Unlimited for fair usage',
    showAlways: true,
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.WHATSAPP_INTEGRATION]: {
    key: PRICING_PLAN_FEATURES.WHATSAPP_INTEGRATION,
    name: 'Whatsapp Notification',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM, FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EMAIL_INTEGRATION]: {
    key: PRICING_PLAN_FEATURES.EMAIL_INTEGRATION,
    name: 'Email Notification',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM, FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_VIDEO_UPLOAD]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_VIDEO_UPLOAD,
    name: 'Video Upload (total 15 mins per project)',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.QUALITY_4K]: {
    key: PRICING_PLAN_FEATURES.QUALITY_4K,
    name: '12MP Resolution',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.ORIGINAL_RESOLUTION]: {
    key: PRICING_PLAN_FEATURES.ORIGINAL_RESOLUTION,
    name: 'Original Resolution',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.ORIGINAL_BLOATED_FILE]: {
    key: PRICING_PLAN_FEATURES.ORIGINAL_BLOATED_FILE,
    name: 'Original Resolution - with unwanted pixels',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.ONE_YEAR_PROJECT_VALIDITY]: {
    key: PRICING_PLAN_FEATURES.ONE_YEAR_PROJECT_VALIDITY,
    name: 'One Year Project Validity',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.FORTY_FIVE_DAY_PROJECT_VALIDITY]: {
    key: PRICING_PLAN_FEATURES.FORTY_FIVE_DAY_PROJECT_VALIDITY,
    name: '30 days Project Validity',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_SLIDESHOW_WITH_MUSIC]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_SLIDESHOW_WITH_MUSIC,
    name: 'Signature Slideshow With Music',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_GET_LEADS]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_GET_LEADS,
    name: 'Get leads / enquiries',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.DATA_ENCRYPTION]: {
    key: PRICING_PLAN_FEATURES.DATA_ENCRYPTION,
    name: 'Data Encryption with Password protection',
    showAlways: true,
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_DOWNLOAD_PERMISSION]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_DOWNLOAD_PERMISSION,
    name: 'Signature album Download Permission',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY_DOWNLOAD]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY_DOWNLOAD,
    name: 'Signature album Guest Gallery Download Permission',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.ONE_CLICK_DOWNLOAD_ALL_PHOTOS]: {
    key: PRICING_PLAN_FEATURES.ONE_CLICK_DOWNLOAD_ALL_PHOTOS,
    name: 'One Click Download All Photos',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.ONE_CLICK_DOWNLOAD_ALL_PHOTOS_INSIDE_APP]: {
    key: PRICING_PLAN_FEATURES.ONE_CLICK_DOWNLOAD_ALL_PHOTOS_INSIDE_APP,
    name: 'One Click Download All Photos inside app',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.REMOVE_PREMAGIC_BRANDING]: {
    key: PRICING_PLAN_FEATURES.REMOVE_PREMAGIC_BRANDING,
    name: 'Remove Premagic Branding (Powered by Premagic)',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FAVORITE]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FAVORITE,
    name: 'User Favorites',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_FACES]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_FACES,
    name: 'Face recognition with AI',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
    showInPricingPlanCustomFunction: isUltimatePlan,
  },
  [PRICING_PLAN_FEATURES.AI_DELIVERY]: {
    key: PRICING_PLAN_FEATURES.AI_DELIVERY,
    name: 'AI Photo Delivery',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
    showInPricingPlanCustomFunction: isUltimatePlan,
    helpArticle: ARTICLES.AI_DELIVERY,
  },
  [PRICING_PLAN_FEATURES.DIGITAL_INVITE_EVENT_REGISTRATION]: {
    key: PRICING_PLAN_FEATURES.DIGITAL_INVITE_EVENT_REGISTRATION,
    name: 'Event Registration with AI Photo Delivery',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
    showInPricingPlanCustomFunction: isUltimatePlan,
    helpArticle: ARTICLES.DIGITAL_INVITE_EVENT_REGISTRATION,
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_EXPRESS_DELIVERY]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_EXPRESS_DELIVERY,
    name: 'Express Delivery',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.WATERMARK]: {
    key: PRICING_PLAN_FEATURES.WATERMARK,
    name: 'Watermark',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    name: 'Signature album customization',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY,
    name: 'Guest gallery',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY,
    name: 'Show folder in guest gallery',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  [PRICING_PLAN_FEATURES.EVENT_IMPORT_FROM_EXTERNAL_SOURCE]: {
    key: PRICING_PLAN_FEATURES.EVENT_IMPORT_FROM_EXTERNAL_SOURCE,
    name: 'Import from external source',
    group: [FEATURE_GROUP.SIGNATURE_ALBUM],
  },
  // Money back
  [PRICING_PLAN_FEATURES.DIGITAL_INVITE]: {
    key: PRICING_PLAN_FEATURES.DIGITAL_INVITE,
    name: 'Unlimited Micro-Site - Basic',
    group: [FEATURE_GROUP.MONEY_BACK],
  },
  // [PRICING_PLAN_FEATURES.DIGITAL_INVITE_STANDARD]: {
  //   key: PRICING_PLAN_FEATURES.DIGITAL_INVITE_STANDARD,
  //   name: 'Free Digital Invite - Standard',
  //   value: [
  //     {
  //       plan: 'GROWTH',
  //       value: 0,
  //     },
  //     {
  //       plan: 'BUSINESS',
  //       value: 4,
  //     },
  //     {
  //       plan: 'ULTIMATE',
  //       value: 2,
  //     },
  //   ],
  //   group: [FEATURE_GROUP.MONEY_BACK],
  // },
  [PRICING_PLAN_FEATURES.DIGITAL_INVITE_STANDARD_COMMISSION]: {
    key: PRICING_PLAN_FEATURES.DIGITAL_INVITE_STANDARD_COMMISSION,
    name: 'commission For Micro-site(Only for Standard)',
    showAlways: true,
    value: [
      {
        plan: 'GROWTH',
        value: '10%',
      },
      {
        plan: 'BUSINESS',
        value: '50%',
      },
      {
        plan: 'ULTIMATE',
        value: '50%',
      },
    ],
    group: [FEATURE_GROUP.MONEY_BACK],
  },
  [PRICING_PLAN_FEATURES.GALLERY_UPSELL_COMMISSION]: {
    key: PRICING_PLAN_FEATURES.GALLERY_UPSELL_COMMISSION,
    name: '20% Gallery Upsell commission',
    showAlways: true,
    group: [FEATURE_GROUP.MONEY_BACK],
  },

  // selection
  [PRICING_PLAN_FEATURES.UNLIMITED_SELECTION]: {
    key: PRICING_PLAN_FEATURES.UNLIMITED_SELECTION,
    name: 'Unlimited Projects',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
  },
  [PRICING_PLAN_FEATURES.PROJECT_DUPLICATES]: {
    key: PRICING_PLAN_FEATURES.PROJECT_DUPLICATES,
    name: 'Projects Duplication',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
  },
  [PRICING_PLAN_FEATURES.SELECTION_PHOTOS_LIMIT]: {
    key: PRICING_PLAN_FEATURES.SELECTION_PHOTOS_LIMIT,
    name: 'Photo limit per project',
    value: 'Unlimited for fair usage',
    showAlways: true,
    group: [FEATURE_GROUP.PHOTO_SELECTION],
  },
  [PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: {
    key: PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE,
    name: 'Photo Importance',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
    helpArticle: ARTICLES.SELECTION_IMPORTANCE,
  },
  [PRICING_PLAN_FEATURES.SELECTION_COPY_FROM_HIGHLIGHT]: {
    key: PRICING_PLAN_FEATURES.SELECTION_COPY_FROM_HIGHLIGHT,
    name: 'Copy from Signature album',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
  },
  [PRICING_PLAN_FEATURES.SELECTION_BUDGET]: {
    key: PRICING_PLAN_FEATURES.SELECTION_BUDGET,
    name: 'Selection limit Suggestions',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
    helpArticle: ARTICLES.SELECTION_BUDGET,
  },
  [PRICING_PLAN_FEATURES.SELECTION_FACES]: {
    key: PRICING_PLAN_FEATURES.SELECTION_FACES,
    name: 'Face recognition with AI',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
  },
  [PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: {
    key: PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION,
    name: 'Bulk photo downloads',
    group: [FEATURE_GROUP.PHOTO_SELECTION],
  },
  // CRM
  [PRICING_PLAN_FEATURES.CRM_MANAGE_CREW]: {
    key: PRICING_PLAN_FEATURES.CRM_MANAGE_CREW,
    name: 'Manage Crew',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CRM_MANAGE_PAYMENT]: {
    key: PRICING_PLAN_FEATURES.CRM_MANAGE_PAYMENT,
    name: 'Manage Payments',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CRM_MANAGE_SCHEDULE]: {
    key: PRICING_PLAN_FEATURES.CRM_MANAGE_SCHEDULE,
    name: 'Manage Schedule',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CRM_EVENTS]: {
    key: PRICING_PLAN_FEATURES.CRM_EVENTS,
    name: 'Unlimited Events',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CRM_USERS]: {
    key: PRICING_PLAN_FEATURES.CRM_USERS,
    name: 'Unlimited Users',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT]: {
    key: PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT,
    name: 'Proposal Management - Lite',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED]: {
    key: PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED,
    name: 'Proposal Management',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.CUSTOM_EMAIL_TEMPLATE]: {
    key: PRICING_PLAN_FEATURES.CUSTOM_EMAIL_TEMPLATE,
    name: 'Customize Communication emails',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.STORE_OFFERS]: {
    key: PRICING_PLAN_FEATURES.STORE_OFFERS,
    name: 'Store offers',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP]: {
    key: PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP,
    name: 'Event sponsorship',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_EXHIBITOR]: {
    key: PRICING_PLAN_FEATURES.EVENT_EXHIBITOR,
    name: 'Event exhibitor',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS]: {
    key: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    name: 'Event registration form fields',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST]: {
    key: PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST,
    name: 'Event registration list',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII]: {
    key: PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII,
    name: 'Event registration list with PII',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES]: {
    key: PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES,
    name: 'Event registration badges',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_PEOPLE_BULK_REGISTRATION]: {
    key: PRICING_PLAN_FEATURES.EVENT_PEOPLE_BULK_REGISTRATION,
    name: 'People bulk registration',
    group: [FEATURE_GROUP.CRM],
  },

  // support
  [PRICING_PLAN_FEATURES.STANDARD_SUPPORT]: {
    key: PRICING_PLAN_FEATURES.STANDARD_SUPPORT,
    name: 'Email Support',
    group: [FEATURE_GROUP.SUPPORT],
  },
  [PRICING_PLAN_FEATURES.STANDARD_WITH_CHAT]: {
    key: PRICING_PLAN_FEATURES.STANDARD_WITH_CHAT,
    name: 'Email and Chat support',
    group: [FEATURE_GROUP.SUPPORT],
  },
  [PRICING_PLAN_FEATURES.PRIORITY_SUPPORT]: {
    key: PRICING_PLAN_FEATURES.PRIORITY_SUPPORT,
    name: 'Priority support',
    group: [FEATURE_GROUP.SUPPORT],
  },
  [PRICING_PLAN_FEATURES.SUPPORT_TRAINING]: {
    key: PRICING_PLAN_FEATURES.SUPPORT_TRAINING,
    name: 'Training',
    showAlways: true,
    value: [
      {
        plan: 'GROWTH',
        value: 'Demo + training videos + 1 training session',
      },
      {
        plan: 'BUSINESS',
        value: 'Demo + training videos + 4 training sessions in a year',
      },
      {
        plan: 'ULTIMATE',
        value: 'On demand training',
      },
    ],
    group: [FEATURE_GROUP.SUPPORT],
  },
  // Addons

  [PRICING_PLAN_FEATURES.VIDEO_WISHES]: {
    key: PRICING_PLAN_FEATURES.VIDEO_WISHES,
    name: 'Video Wishes',
    group: [],
  },
  [PRICING_PLAN_FEATURES.UNLIMITED_PROJECT_VALIDITY]: {
    key: PRICING_PLAN_FEATURES.UNLIMITED_PROJECT_VALIDITY,
    name: 'Unlimited Time Validity',
    group: [],
  },
  [PRICING_PLAN_FEATURES.SELECTION]: {
    key: PRICING_PLAN_FEATURES.SELECTION,
    name: 'Project',
    group: [],
  },
  [PRICING_PLAN_FEATURES.HIGHLIGHTS]: {
    key: PRICING_PLAN_FEATURES.HIGHLIGHTS,
    name: 'Signature album',
    group: [],
  },

  [PRICING_PLAN_FEATURES.HIGHLIGHTS_ANALYTICS]: {
    key: PRICING_PLAN_FEATURES.HIGHLIGHTS_ANALYTICS,
    name: 'Album analytics & Dashboard',
    group: [],
  },
  [PRICING_PLAN_FEATURES.CRM]: {
    key: PRICING_PLAN_FEATURES.CRM,
    name: 'CRM',
    group: [],
  },

  [PRICING_PLAN_FEATURES.CLOUD_BACKUP]: {
    key: PRICING_PLAN_FEATURES.CLOUD_BACKUP,
    name: 'Cloud Backup',
    group: [],
  },
  [PRICING_PLAN_FEATURES.CHANGE_SIGNATURE_UPLOAD_QUALITY]: {
    key: PRICING_PLAN_FEATURES.CHANGE_SIGNATURE_UPLOAD_QUALITY,
    name: 'Signature album upload quality control',
    group: [],
  },
  [PRICING_PLAN_FEATURES.PORTFOLIO]: {
    key: PRICING_PLAN_FEATURES.PORTFOLIO,
    name: 'Portfolio**',
    beta: true,
    group: [],
  },
  [PRICING_PLAN_FEATURES.ACCOUNT_FINANCIALS]: {
    key: PRICING_PLAN_FEATURES.ACCOUNT_FINANCIALS,
    name: 'Company Financials',
    group: [],
  },
  [PRICING_PLAN_FEATURES.ALBUM_CREATOR]: {
    key: PRICING_PLAN_FEATURES.ALBUM_CREATOR,
    name: 'Album Creator',
    beta: true,
    group: [],
  },
  [PRICING_PLAN_FEATURES.DISABLE_POWERED_BY_PREMAGIC_BRANDING_ON_INVITE]: {
    key: PRICING_PLAN_FEATURES.DISABLE_POWERED_BY_PREMAGIC_BRANDING_ON_INVITE,
    name: 'Hide Powered by Premagic',
    group: [],
  },
  [PRICING_PLAN_FEATURES.PROJECT_LEVEL_DOWNLOAD]: {
    key: PRICING_PLAN_FEATURES.PROJECT_LEVEL_DOWNLOAD,
    name: 'Project level download',
    group: [],
  },
  [PRICING_PLAN_FEATURES.ENABLE_SELECTION_MORE_THAN_LIMIT]: {
    key: PRICING_PLAN_FEATURES.ENABLE_SELECTION_MORE_THAN_LIMIT,
    name: 'Allow selection more than limit',
    group: [],
  },
  [PRICING_PLAN_FEATURES.SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION]: {
    key: PRICING_PLAN_FEATURES.SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION,
    name: 'Single photo download Quality Permission',
    group: [],
  },
  [PRICING_PLAN_FEATURES.BLOCK_GALLERY]: {
    key: PRICING_PLAN_FEATURES.BLOCK_GALLERY,
    name: 'Block selection and signature album',
    group: [],
  },
  [PRICING_PLAN_FEATURES.LIBRARY]: {
    key: PRICING_PLAN_FEATURES.LIBRARY,
    name: 'Library',
    group: [FEATURE_GROUP.DAM],
  },
  [PRICING_PLAN_FEATURES.EVENT_MARKETING]: {
    key: PRICING_PLAN_FEATURES.EVENT_MARKETING,
    name: 'Event Marketing',
    group: [FEATURE_GROUP.CRM],
  },
  [PRICING_PLAN_FEATURES.EVENT_PEOPLE_NETWORKING]: {
    key: PRICING_PLAN_FEATURES.EVENT_PEOPLE_NETWORKING,
    name: 'Event People networking',
    group: [],
  },
  [PRICING_PLAN_FEATURES.CUSTOM_SOCIAL_SHARE_MESSAGES]: {
    key: PRICING_PLAN_FEATURES.CUSTOM_SOCIAL_SHARE_MESSAGES,
    name: 'Custom Social Share Messages',
    group: [],
  },
  [PRICING_PLAN_FEATURES.EVENT_FACE_CHECKIN]: {
    key: PRICING_PLAN_FEATURES.EVENT_FACE_CHECKIN,
    name: 'Event Check-In',
    group: [],
  },
  [PRICING_PLAN_FEATURES.WIDGET_PHOTO_GALLERY]: {
    key: PRICING_PLAN_FEATURES.WIDGET_PHOTO_GALLERY,
    name: 'Widget Photo Gallery',
    group: [],
  },
  [PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES]: {
    key: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES,
    name: '',
    group: [],
  },
  [PRICING_PLAN_FEATURES.AVATAR_STUDIO]: {
    key: PRICING_PLAN_FEATURES.AVATAR_STUDIO,
    name: 'Avatar Studio',
    group: [FEATURE_GROUP.CONTENT_CREATION],
  },
  [PRICING_PLAN_FEATURES.MAGAZINE_CREATOR]: {
    key: PRICING_PLAN_FEATURES.MAGAZINE_CREATOR,
    name: 'Magazine Creator',
    group: [FEATURE_GROUP.CONTENT_CREATION],
  },
  [PRICING_PLAN_FEATURES.EVENT_INTEGRATION]: {
    key: PRICING_PLAN_FEATURES.EVENT_INTEGRATION,
    name: 'Event Integration',
    group: [],
  },
  [PRICING_PLAN_FEATURES.AI_CONTENT_CREATION]: {
    key: PRICING_PLAN_FEATURES.AI_CONTENT_CREATION,
    name: 'AI Content Creation',
    group: [],
  },
  [PRICING_PLAN_FEATURES.CUSTOM_BRAND_FOR_EVENT]: {
    key: PRICING_PLAN_FEATURES.CUSTOM_BRAND_FOR_EVENT,
    name: 'Custom Brand for Events',
    group: [],
  },
  [PRICING_PLAN_FEATURES.TAG_FILES_TO_ATTENDEE_GALLERY]: {
    key: PRICING_PLAN_FEATURES.TAG_FILES_TO_ATTENDEE_GALLERY,
    name: 'Tag files to attendee gallery',
    group: [FEATURE_GROUP.DAM],
  },
};

export function getFeatureDetails(featureKey: PRICING_PLAN_FEATURES): {
  key: PRICING_PLAN_FEATURES;
  name: string;
  beta?: boolean;
  value?: string | Array<{ plan: string; value: number | string }>;
  showAlways?: boolean;
  group: Array<FEATURE_GROUP>;
  helpArticle?: ARTICLES;
} {
  return PRICING_PLAN_FEATURES_DETAILS[featureKey];
}
