import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  BUTTON_SIZE,
  Dialog,
  DIALOG_PLACEMENT,
  DIALOG_SIZES,
  FormLabel,
  Icon,
  ICON_SIZES,
  Row,
  Select,
  Space,
  TEXT_BUTTON_STYLES,
  TextButton,
} from '@premagic/myne';
import { LabelService } from '@premagic/core';
import { debounce } from 'lodash';
import { CRMFiltersType } from '../../../filters/CRMFilterTypes';

type SelectedEventLabelsProps = {
  labels: Array<LabelService.LabelsType>;
  hasEventManagementPermission: boolean;
  navigate: (view: 'calendar' | 'list', filters: CRMFiltersType) => void;
};

function SelectedEventLabels(props: SelectedEventLabelsProps) {
  const { labels, hasEventManagementPermission, navigate } = props;

  function handleOnClick(value) {
    navigate('list', {
      labels: [value],
    });
  }

  if (!labels || labels?.length === 0) return null;

  return (
    <>
      {labels.map((item, idx) => (
        <TextButton
          key={item.id}
          onClick={() => handleOnClick(item.name)}
          style={TEXT_BUTTON_STYLES.PRIMARY}
          size={BUTTON_SIZE.SM}
          cssStyle={{ margin: 0, padding: '1px 2px' }}
          disabled={!hasEventManagementPermission}
        >
          {`${item.name}${idx === labels.length - 1 ? '' : ','}`}
        </TextButton>
      ))}
    </>
  );
}

type Props = {
  eventId: string;
  clientId: string;
  isSaving: boolean;
  isLabelsLoading: boolean;
  labels: Array<LabelService.LabelsType>;
  updateEventLabels: (clientId: string, eventId: string, labelsData: Array<LabelService.LabelsType>) => void;
  removeEventLabels: (clientId: string, eventId: string, labelIds: Array<string>) => void;
  createAndAddEventLabel: (
    clientId: string,
    eventId: string,
    labelData: { newLabelData: Partial<LabelService.LabelsType>; existingLabels: Array<LabelService.LabelsType> },
  ) => void;
  hasEventManagementPermission: boolean;
  accountId?: string;
  allLabels: Record<string, LabelService.LabelsType>;
  navigate: (view: 'calendar' | 'list', filters: CRMFiltersType) => void;
  fetchAllEventLabels: () => void;
};

export default function EventLabelSelector(props: Props): React.ReactElement {
  const {
    eventId,
    clientId,
    isSaving,
    isLabelsLoading,
    updateEventLabels,
    labels,
    hasEventManagementPermission,
    createAndAddEventLabel,
    accountId,
    allLabels,
    removeEventLabels,
    navigate,
    fetchAllEventLabels,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const target = useRef(null);

  const debouncedLabelsUpdate = useMemo(() => debounce(updateEventLabels, 500), [updateEventLabels]);
  const debouncedLabelsRemove = useMemo(() => debounce(removeEventLabels, 500), [removeEventLabels]);

  function handleNewLabel(val) {
    createAndAddEventLabel(clientId, eventId, {
      newLabelData: {
        name: val,
        // setting a default random color for now
        color: 'blue',
        entity: LabelService.ENTITY_TYPE.EVENT,
        accountId,
      },
      existingLabels: labels,
    });
  }

  function handleLabelsChange(data) {
    const selectedLabelIds = data.map((item) => item.value);
    const currentLabelIds = labels.map((item) => item.id);

    // labels are removed
    if (data.length < labels.length) {
      const removedLabels = currentLabelIds.filter((item) => !selectedLabelIds.includes(item));
      debouncedLabelsRemove(clientId, eventId, removedLabels);
    }

    // labels are added
    else {
      const labelsData = Object.values(allLabels).filter((item) => selectedLabelIds.includes(item.id));
      debouncedLabelsUpdate(clientId, eventId, labelsData);
    }
  }

  const labelOptions = useMemo(
    () =>
      Object.values(allLabels).map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [allLabels],
  );

  const labelValues =
    labels &&
    labels.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  useEffect(() => {
    if (Object.values(allLabels).length === 0) {
      fetchAllEventLabels();
    }
  }, [fetchAllEventLabels]);

  return (
    <div ref={target}>
      <Row vcenter>
        <Space size={8} />
        <Icon name="tag" size={ICON_SIZES.SM_XS} alignSize={4} align="top" />
        <Space size={1} />
        <SelectedEventLabels
          labels={labels}
          hasEventManagementPermission={hasEventManagementPermission}
          navigate={navigate}
        />
        <TextButton
          onClick={() => setIsDialogOpen(!isDialogOpen)}
          style={TEXT_BUTTON_STYLES.SECONDARY}
          size={BUTTON_SIZE.SM}
          isLoading={isSaving}
          active={isDialogOpen}
          disabled={!hasEventManagementPermission}
        >
          Add Labels
        </TextButton>
      </Row>
      {isDialogOpen && (
        <Dialog
          target={target.current}
          show={isDialogOpen}
          placement={DIALOG_PLACEMENT.AUTO}
          onClose={() => setIsDialogOpen(false)}
          size={DIALOG_SIZES.MD}
        >
          <Space size={2}>
            <FormLabel>Event Labels</FormLabel>
            <Select
              disabled={isSaving || isLabelsLoading}
              options={labelOptions}
              value={labelValues}
              name="event_labels"
              onChange={handleLabelsChange}
              isMulti
              autoFocus
              isCreatable
              onCreateOption={handleNewLabel}
            />
          </Space>
        </Dialog>
      )}
    </div>
  );
}
