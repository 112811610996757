import React from 'react';
import { connect } from 'react-redux';
import ReportIssueListItem from './ReportIssueListItem';
import { deleteReportIssue, reportIssueByIdSelector, updateReportIssue } from '../data-duck/ReportIssueDataDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { projectId: string; issueId: string }) {
  const { issueId, projectId } = ownProps;
  return {
    projectId,
    reportIssueItem: reportIssueByIdSelector(issueId)(state),
    isLoading:
      isLoadingSelector(LOADINGS.PRODUCT_FEEDBACK.REPORT_ISSUE.DELETE(issueId))(state) ||
      isLoadingSelector(LOADINGS.PRODUCT_FEEDBACK.REPORT_ISSUE.UPDATE(issueId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeReportIssue: (projectId: string, feedbackId: string) => {
      dispatch(deleteReportIssue(dispatch, projectId, feedbackId));
    },
    updateReportIssue: (projectId, feedbackId, data) => {
      dispatch(updateReportIssue(dispatch, projectId, feedbackId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssueListItem);
