import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum PRODUCT_FEEDBACK_REPORT_TYPE {
  NOT_MY_PHOTO = 'NOT_MY_PHOTO',
  PRODUCT_USAGE = 'PRODUCT_USAGE',
  INTERESTED_LEAD = 'INTERESTED_LEAD',
  NEED_MORE_PHOTOS = 'NEED_MORE_PHOTOS',
  BAD_PHOTO_QUALITY = 'BAD_PHOTO_QUALITY',
  OTHER = 'OTHER',
  DID_NOT_GET_MY_PHOTOS = 'DID_NOT_GET_MY_PHOTOS',
}

export enum PRODUCT_FEEDBACK_STATUS_TYPE {
  OPEN = 'open',
  DONE = 'done',
}

type NewProductFeedbackType = {
  project_id: string;
  project_name: string;
  source: string;
  data: any;
  name: string;
  email: string;
  phone: string;
  feedback_type?: PRODUCT_FEEDBACK_REPORT_TYPE;
  meta: Record<string, any>;
  status: PRODUCT_FEEDBACK_STATUS_TYPE;
};

export type ProductFeedbackType = NewProductFeedbackType & {
  id: string;
  created_at: string;
  updated_at: string;
};

export enum EVENT_FEEDBACK_QUESTION_TYPE {
  OVERALL_EXPERIENCE = 'overall_experience',
  KEYNOTE_SPEAKERS = 'keynote_speakers',
  BREAKOUT_SESSIONS = 'breakout_sessions',
  NETWORKING_OPPORTUNITIES = 'networking_opportunities',
  VENUE_AND_LOGISTICS = 'venue_and_logistics',
  ORGANIZATION_AND_PLANNING = 'organization_and_planning',
  PHOTO_QUALITY = 'photo_quality',
  PHOTO_ESSENCE = 'photo_essence',
  PHOTO_QUALITY_DESCRIPTION_1 = 'photo_quality_description_1',
  PHOTO_QUALITY_DESCRIPTION_2 = 'photo_quality_description_2',
  ADDITIONAL_COMMENTS = 'additional_comments',
}

export function createProductFeedbackPublic(
  shareId: string,
  data: NewProductFeedbackType,
  selfieBlob?: Blob | null,
): Promise<ProductFeedbackType> {
  const formData = new FormData();
  // append all the items in data to formData
  Object.keys(data).forEach((key) => {
    if (key === 'data') return formData.append(key, JSON.stringify(data[key]));
    formData.append(key, data[key]);
  });
  if (selfieBlob) {
    formData.append('selfie', selfieBlob);
  }
  return HttpUtils.post(API_URLS.PRODUCT_FEEDBACK.CREATE(shareId), formData, true, true).then(
    (response) => response.data,
  );
}

export function getProductFeedbackList(
  projectId: string,
  feedbackType?: PRODUCT_FEEDBACK_REPORT_TYPE,
): Promise<Array<ProductFeedbackType>> {
  return HttpUtils.get(
    API_URLS.PRODUCT_FEEDBACK.LIST(projectId),
    {
      params: {
        feedback_type: feedbackType,
      },
    },
    true,
    true,
  ).then((response) => response.data);
}

export function deleteProductFeedbackItem(projectId: string, feedbackId: string): Promise<void> {
  return HttpUtils.httpDelete(API_URLS.PRODUCT_FEEDBACK.DETAILS(projectId, feedbackId), {}, true, true).then(
    (response) => response.data,
  );
}

export function updateProductFeedbackItem(
  projectId: string,
  feedbackId: string,
  data: Partial<ProductFeedbackType>,
): Promise<void> {
  return HttpUtils.put(API_URLS.PRODUCT_FEEDBACK.DETAILS(projectId, feedbackId), data, true, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

// testing -- as the live api has issues
// export function updateProductFeedbackItem(
//   projectId: string,
//   feedbackId: string,
//   data: Partial<ProductFeedbackType>,
// ): Promise<void> {
//   return Promise.resolve();
// }
