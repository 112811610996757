import { FolderService } from '@premagic/core';
import { connect } from 'react-redux';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../../../../projects/AccountProjectsDataDuck';
import FolderWatermarkList from './FolderWatermarkList';

function mapStateToProps(state, ownProps: { projectId: string; sponsorFolderId: string; eventId: string }) {
  const { projectId, sponsorFolderId, eventId } = ownProps;
  return {
    allHighlightFolderIds: folderIdsWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.HIGHLIGHT,
      {},
    )(state),
    sponsorFolderId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderWatermarkList);
