import React, { useEffect } from 'react';
import {
  ButtonIcon,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { ProductFeedbackService } from '@premagic/core';
import APP_URLS from '../../../../../services/AppRouteURLService';
import ReportIssueListLayoutContainer from '../report-list-items/ReportIssueListLayoutContainer';
import ReportIssueFilterByTypeDropdownContainer from '../report-issue-button/ReportIssueFilterByTypeDropdownContainer';

type Props = {
  eventId: string;
  projectId?: string;
  getReportIssues: (projectId: string, feedbackType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE) => void;
  isLoading?: boolean;
  reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
  browserBack: (defaultUrl: string) => void;
};

export default function ReportIssuesLayout(props: Props): React.ReactElement {
  const { eventId, projectId, getReportIssues, isLoading, reportType, browserBack } = props;

  useEffect(() => {
    if (projectId) getReportIssues(projectId, reportType);
  }, [getReportIssues, projectId, reportType]);

  const urlForPeoplesPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEES, {
    eventId,
  });

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;
  if (isLoading) return <LoadingDots />;
  return (
    <InnerPageContentMainCol hasSidebar={false}>
      <Row alignItems="baseline">
        <ButtonIcon title="Back" onClick={() => browserBack(urlForPeoplesPage)}>
          <Icon name="arrow_left" size={ICON_SIZES.SM} />
        </ButtonIcon>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          Reported issues
        </Text>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.LIGHT}>
          /
        </Text>
        <Space size={2} />
        <ReportIssueFilterByTypeDropdownContainer projectId={projectId} eventId={eventId} reportType={reportType} />
      </Row>
      <Space vertical />
      <ReportIssueListLayoutContainer projectId={projectId} reportType={reportType} />
    </InnerPageContentMainCol>
  );
}
