import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum PROJECT_BACKGROUND_TASK_TYPE {
  SPACE_UTILIZATION_CALCULATION = 'space_utilization_calculation',
  SEND_AI_DELIVERY_NOTIFICATION = 'send_ai_delivery_notification',
  SEND_EXPRESS_AI_DELIVERY_NOTIFICATION = 'send_express_ai_delivery_notification',
  ON_DEMAND_SCANNING_AND_PROCESSING = 'on_demand_scanning_and_processing',
  SEND_AI_DELIVERY_REMINDER_NOTIFICATION = 'send_ai_delivery_reminder_notification',
  GENERATE_FACES = 'generate_faces',
}

export enum PROJECT_BACKGROUND_TASK_STATUS {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export const PROJECT_BACKGROUND_TASK_TYPE_DETAIL: Record<PROJECT_BACKGROUND_TASK_TYPE, { title: string }> = {
  [PROJECT_BACKGROUND_TASK_TYPE.SPACE_UTILIZATION_CALCULATION]: {
    title: 'Space Utilization Calculation',
  },
  [PROJECT_BACKGROUND_TASK_TYPE.SEND_AI_DELIVERY_NOTIFICATION]: {
    title: 'Send AI Delivery Notification',
  },
  [PROJECT_BACKGROUND_TASK_TYPE.SEND_EXPRESS_AI_DELIVERY_NOTIFICATION]: {
    title: 'Send Express AI Delivery Notification',
  },
  [PROJECT_BACKGROUND_TASK_TYPE.ON_DEMAND_SCANNING_AND_PROCESSING]: {
    title: 'On-Demand Scanning and Processing',
  },
  [PROJECT_BACKGROUND_TASK_TYPE.SEND_AI_DELIVERY_REMINDER_NOTIFICATION]: {
    title: 'Send AI Delivery Reminder Notification',
  },
  [PROJECT_BACKGROUND_TASK_TYPE.GENERATE_FACES]: {
    title: 'Generate Faces',
  },
};

export type ProjectBackgroundTaskType = Record<
  PROJECT_BACKGROUND_TASK_TYPE,
  {
    status?: PROJECT_BACKGROUND_TASK_STATUS;
    ttl: string;
  }
>;

export function fetchProjectBackgroundTasks(projectId: string): Promise<ProjectBackgroundTaskType> {
  const URL = API_URLS.PROJECT.BACKGROUND_STATUS(projectId);

  return HttpUtils.get(URL).then((response) => response.data);
}
