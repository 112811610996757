import { connect } from 'react-redux';
import { ActionConstants, ProposalVariablesDataDuck } from '@premagic/proposals';
import { LoadingDuck } from '@premagic/common-ducks';
import ProposalVariablesList from './ProposalVariablesList';
import { showAddNewProposalParameter } from '../creator/ProposalCreatorDuck';

function mapStateToProps(state) {
  return {
    isLoading: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_VARIABLES.FETCH)(state),
    groupedVariableIds: ProposalVariablesDataDuck.proposalsVariableGroupedIdsSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(ProposalVariablesDataDuck.fetchProposalsVariables(dispatch));
    },
    showAddNewVariable: (scope) => {
      dispatch(showAddNewProposalParameter(dispatch, scope));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalVariablesList);
