import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import {
  folderFilesStatsWithFolderTypeForProjectSelector,
  folderIdsWithFolderTypeForProjectSelector,
  projectsSelector,
} from '../../../projects/AccountProjectsDataDuck';
import SelectionAlbumCard from './SelectionAlbumCard';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import { isSelectionAlbumEnabledSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; focus: boolean }) {
  const { projectId, focus } = ownProps;
  const selectionFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.SELECTION,
  )(state);
  const folders = foldersEntitiesSelector(state);

  return {
    isSelectionAlbumEnabled: isSelectionAlbumEnabledSelector(state),
    focus,
    folderIds: selectionFolderIds,
    folders: selectionFolderIds.map((folderId) => folders[folderId]),
    hasSelectionAlbum: selectionFolderIds.length > 0,
    projectId,
    project: projectsSelector(state)[projectId],
    folderFilesStats: folderFilesStatsWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.SELECTION,
    )(state),
  };
}

export default connect(mapStateToProps)(SelectionAlbumCard);
