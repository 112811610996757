import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../../../settings/company/CompanyDataDuck';
import PhotoSliderCustomizationLayout from './PhotoSliderCustomizationLayout';

function mapStateToProps(state, ownProps) {
  // const {} = ownProps;

  return {
    hasFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WIDGET_PHOTO_GALLERY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSliderCustomizationLayout);
