import { ModalDuck } from '@premagic/common-ducks';
import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import {
  editQRCodeDesigns,
  qrCodeDesignsLogoSelector,
  qrCodeDesignsSelector,
  saveFolderMetaData,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { CustomLogoPreviewAndSelect } from './CustomLogoPreviewAndSelect';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    projectId: string;
    title: string;
    positionIndex: number;
    logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
  },
) {
  const { folderId, projectId, title, positionIndex, logoId } = ownProps;

  const logos = qrCodeDesignsLogoSelector(folderId)(state);
  const logoFileId = logos && logos[positionIndex];

  return {
    folderId,
    projectId,
    logoId,
    logos,
    title,
    logoFileId,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSelectFileModal: (logoId) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_LOGO_MODAL, true));
      dispatch(
        ModalDuck.setModalOptions(dispatch, LOADINGS.QR_CODE.SELECT_LOGO_MODAL, {
          logoId,
        }),
      );
    },
    // logo remove
    updateLogo: (projectId, folderId, logoId, qrCodeDesigns, customLogo1, customLogo2) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO]: [customLogo1, customLogo2],
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_META_DATA]: {
              ...qrCodeDesigns?.logo_meta_data,
              [logoId]: null,
            },
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomLogoPreviewAndSelect);
