import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import {
  fetchProjectsData,
  groupProjectsWithDateBasedOnArchivedSelector,
  groupProjectsWithDateSelector,
  setProjectSearchTerm,
} from './AccountProjectsDataDuck';
import { projectSearchTermSelector } from './AccountprojectsListDataDuck';
import AccountProjectsPage from './AccountProjectsPage';

function mapStateToProps(state) {
  const groupedProjects = groupProjectsWithDateSelector(state);
  const groupedArchivedProjects = groupProjectsWithDateBasedOnArchivedSelector(true)(state);
  const groupedUnArchivedProjects = groupProjectsWithDateBasedOnArchivedSelector(false)(state);

  return {
    isLoading: isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state),
    groupedProjects,
    groupedArchivedProjects,
    groupedUnArchivedProjects,
    searchTerm: projectSearchTermSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchProjectsData(dispatch));
    },
    updateSearchTerm: (searchTerm: string) => {
      dispatch(setProjectSearchTerm(dispatch, searchTerm));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProjectsPage);
