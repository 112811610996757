import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  DIALOG_PLACEMENT,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { StringUtils } from '@premagic/utils';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  projectId: string;
  folderId: string;
  deleteImages: (projectId: string, folderId: string, imageIds: Array<string>) => void;
  cancelBulkAction: () => void;
  isBulkActionDelete: boolean;
  selectedFilesForBulkAction: Array<string>;
  clearFileSelectionForBulkAction: () => void;
};

export default function FolderFilesBulkDelete(props: Props) {
  const {
    folderId,
    projectId,
    deleteImages,
    cancelBulkAction,
    selectedFilesForBulkAction,
    isBulkActionDelete,
    clearFileSelectionForBulkAction,
  } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const $button = useRef(null);
  const totalFiles = selectedFilesForBulkAction.length;

  if (!isBulkActionDelete) return null;

  return (
    <div ref={$button}>
      <Row vcenter>
        <Button style={BUTTON_STYLES.DANGER} size={BUTTON_SIZE.SM} onClick={() => setShowConfirmation(true)}>
          Delete selected({totalFiles})
        </Button>
        <Button
          style={BUTTON_STYLES.CANCEL}
          size={BUTTON_SIZE.SM}
          onClick={() => {
            clearFileSelectionForBulkAction();
            cancelBulkAction();
          }}
        >
          Cancel
        </Button>
      </Row>

      <Dialog
        target={$button.current}
        show={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        placement={DIALOG_PLACEMENT.AUTO}
      >
        {totalFiles > 0 ? (
          <div>
            <DialogTitle>
              Are you sure you want to delete {totalFiles} {StringUtils.pluralize('file', totalFiles)}
            </DialogTitle>
            <Space vertical />
            <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
            <DialogFooter>
              <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowConfirmation(false)}>
                Cancel
              </Button>
              <Button
                style={BUTTON_STYLES.DANGER}
                onClick={() => {
                  deleteImages(projectId, folderId, selectedFilesForBulkAction);
                  setShowConfirmation(false);
                  clearFileSelectionForBulkAction();
                }}
              >
                Delete files
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <div>
            <DialogTitle>You have not selected any files!</DialogTitle>
            <Space vertical />
            <Text color={COLOR_SHADES.DARKER}>
              Please select the files you want to delete and click on Delete selected.
            </Text>
            <DialogFooter>
              <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowConfirmation(false)}>
                Ok
              </Button>
            </DialogFooter>
          </div>
        )}
      </Dialog>
    </div>
  );
}
