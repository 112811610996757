import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import EditEventPosterPanel from './EditEventPosterPanel';
import { LOADINGS } from '../../../../../../common/Constants';
import { updateEventPoster } from '../EventPostersDataDuck';

const PANEL_KEY = LOADINGS.EVENT_POSTERS.SHOW_EDIT_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    type: EventPosterService.EVENT_POSTER_TYPE;
  },
) {
  const { projectId, type } = ownProps;

  return {
    projectId,
    type,
    posterId: ModalDuck.modalOptionsSelector(LOADINGS.EVENT_POSTERS.SHOW_EDIT_PANEL)(state) as string,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.UPDATE)(state),
    error: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADINGS.EVENT_POSTERS.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editEventPoster: (projectId: string, posterId: string, data) =>
      dispatch(updateEventPoster(dispatch, { projectId, posterId }, data)),
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEventPosterPanel);
