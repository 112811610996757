import { FileService } from '@premagic/core';
import {
  COLOR_SHADES,
  IMAGE_V2_STYLES,
  ImageV2,
  Row,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  VIDEO_PLAYER_STYLES,
  VIDEO_TYPES,
  VideoJsPlayer,
} from '@premagic/myne';
import { MediaUtils } from '@premagic/utils';

type Props = {
  file: FileService.FolderFileType;
  appWidth: number;
};

const UPLOAD_STATUS_MESSAGES = {
  [FileService.FILE_UPLOAD_STAGES.UPLOADING]: 'The File is being uploaded, please wait for 2-5 mins and try again',
  [FileService.FILE_UPLOAD_STAGES.PROCESSING]: 'The File is being processed, please wait for 2-5 mins and try again',
  [FileService.FILE_UPLOAD_STAGES.QUEUED]:
    'The File is waiting to get uploaded, please wait for 2-5 mins and try again',
  [FileService.FILE_UPLOAD_STAGES.ERROR]:
    'There is an error with the file, Please delete this file and try re-uploading',
};

export default function DAMLibraryImageFullView(props: Props) {
  const { file, appWidth } = props;
  const {
    id,
    dynamic_image_url: imageDynLocation,
    image_name: fileName,
    location,
    asset_type: type,
    asset_upload_status: uploadStatus,
  } = file || {};
  const isVideo = type === FileService.ASSET_TYPES.VIDEO;
  const message = uploadStatus && UPLOAD_STATUS_MESSAGES[uploadStatus];
  const imageSrc = imageDynLocation;
  const videoSrc = location;

  if (message) {
    return (
      <Row center fullHeight>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block center size={TEXT_SIZE.SIZE_3}>
          {message}
        </Text>
      </Row>
    );
  }

  if (!imageSrc) return <Text>Oops... Cannot find photo</Text>;
  const imageSrcForScreen = MediaUtils.getDynamicImageURL(imageSrc, MediaUtils.DYNAMIC_IMAGE_TYPES.DEFAULT, {
    width: appWidth,
  });

  if (isVideo)
    return (
      <VideoJsPlayer src={videoSrc} autoplay style={VIDEO_PLAYER_STYLES.DEFAULT} type={VIDEO_TYPES.STREAM} key={id} />
    );

  return <ImageV2 alt={fileName} src={imageSrcForScreen} key={id} style={IMAGE_V2_STYLES.FULL_IMAGE} />;
}
