import { connect } from 'react-redux';

import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { foldersEntitiesSelector } from '../projects/folders/AccountFoldersDataDuck';
import PendingPhotographerActionItem from './PendingPhotographerActionItem';
import { dismissAction, markActionAsDone, photographerActionsEntitiesSelector } from './PhotographerActionsDataDuck';

type Props = {
  id: string;
};

function mapStateToProps(state, ownProps: Props) {
  const { id } = ownProps;
  return {
    id,
    action: photographerActionsEntitiesSelector(state).get(id),
    isLoading: isLoadingSelector(`${LOADINGS.PHOTOGRAPHER_ACTIONS.MARK_DONE}${id}`)(state),
    folders: foldersEntitiesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    markAsDone: (id) => dispatch(markActionAsDone(dispatch, id)),
    dismissAction: (id) => dispatch(dismissAction(dispatch, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingPhotographerActionItem);
