import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import AddEventExhibitorPanel from './AddEventExhibitorPanel';
import { addEventExhibitor } from '../EventExhibitorDataDuck';

const PANEL_KEY = LOADINGS.EVENT_EXHIBITOR.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.EVENT_EXHIBITOR.ADD)(state),
    error: errorObjectForTypeSelector(LOADINGS.EVENT_EXHIBITOR.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addEventExhibitor: (projectId: string, data) => dispatch(addEventExhibitor(dispatch, projectId, data)),
    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEventExhibitorPanel);
