import { i18n } from '@premagic/utils';
import { connect } from 'react-redux';
import { projectDefaultLanguageSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteBasicInfoForm from './ClientWebsiteBasicInfoForm';

function mapStateToProps(state, ownProps: { id?: string; projectId: string }) {
  const { id, projectId } = ownProps;

  const clientWebsite = id ? clientWebsitesSelector(state)[id] : undefined;

  return {
    clientWebsite,
    langCode: projectId ? projectDefaultLanguageSelector(projectId)(state) : i18n.LANGUAGE_CODE.EN,
  };
}

export default connect(mapStateToProps)(ClientWebsiteBasicInfoForm);
