import { connect } from 'react-redux';
import { CustomRegistrationFormFields } from './CustomRegistrationFormFields';
import { eventCustomFormFieldsItemsSelectors } from './EventCustomFormFieldsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    data: Record<string, string>;
  },
) {
  const { data } = ownProps;
  return {
    formFields: eventCustomFormFieldsItemsSelectors(state),
    data,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRegistrationFormFields);
