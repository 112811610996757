import { getDateStringFromDate, now, STANDARD_DATE_FORMATS } from './SimpleDateUtils';

function downloadAsCSV(filename, csvContent) {
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function createCSVFile(
  data: any[],
  filename: string,
  columns: Array<{
    title: string;
    accessor: string;
  }>,
) {
  const header = columns.map((c) => c.title).join(',');
  const content = data.map((e) => columns.map((c) => e[c.accessor]).join(',')).join('\n');

  const csvContent = `data:text/csv;charset=utf-8,${header}\n${content}`;

  downloadAsCSV(filename, csvContent);
}
