import { connect } from 'react-redux';
import QuotePaymentForm from './QuotePaymentForm';
import { crmPaymentsSelector } from '../CRMPaymentDataDuck';
import { quoteCategorySelector } from '../CRMPaymentCategoryDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id?: string;
  },
) {
  const paymentId = ownProps.id;
  return {
    paymentId,
    quoteCategory: quoteCategorySelector(state),
    payment: paymentId ? crmPaymentsSelector(state)[paymentId] : undefined,
  };
}

export default connect(mapStateToProps)(QuotePaymentForm);
