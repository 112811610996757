import React, { useRef } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
  DialogTitle,
  COLOR_SHADES,
} from '@premagic/myne';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  eventId: string;
  isLoading: boolean;
  isDialogShown: boolean;
  occasionId: string;
  toggleDialog: (occasionId: string, show: boolean) => void;
  deleteOccasion: (occasionId: string, eventId: string) => void;
};

export default function OccasionDeleteDialog(props: Props): React.ReactElement {
  const { eventId, isLoading, toggleDialog, isDialogShown, deleteOccasion, occasionId } = props;
  const target = useRef(null);

  return (
    <span ref={target}>
      <Dialog
        target={target.current}
        show={isDialogShown}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => toggleDialog(occasionId, false)}
      >
        <DialogTitle>Delete this occasion?</DialogTitle>
        <Text>Are you sure you want to delete this occasion?</Text>
        <Space vertical size={6} />
        <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
        <DialogFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(occasionId, false)}>
            Cancel
          </Button>
          <Button
            style={BUTTON_STYLES.DANGER}
            onClick={() => deleteOccasion(occasionId, eventId)}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </DialogFooter>
      </Dialog>
     </span>
  );
}
