import { createAction } from 'redux-actions';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, BrowserUrlUtils } from '@premagic/utils';
import { EventTrackerService, ExternalUploaderService, FolderService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import APP_URLS from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';
import { toastMessage } from '../../../reducers/ToastStore';

const getActionType = ActionTypeUtils.getActionTypeFunction('EXTERNAL_UPLOADER');

export const importFromExternalApp = createAction(
  getActionType('ACTION', 'ADD'),
  (dispatch, projectId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.EXTERNAL_UPLOADER.UPLOAD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const { url } = formResponse.data;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EXTERNAL_UPLOADER.UPLOAD, {
      projectId,
      url,
    });

    ExternalUploaderService.uploadFromExternalApp(projectId, url as string)
      .then((response) => {
        const highlightGallery = BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.DETAILS, {
          folderType: FolderService.FOLDER_TYPES.HIGHLIGHT,
          projectId,
        });

        navigateTo(dispatch, highlightGallery);
        toastMessage('success', 'Photos are getting imported to your gallery, please wait a few minutes.');
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        toastMessage('error', e.message);
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);
