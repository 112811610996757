import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  COLOR_SHADES,
  ErrorBoundary,
  IMAGE_V2_STYLES,
  ImageV2,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  TimeAgo,
} from '@premagic/myne';
import { ArrayUtils, BrowserUrlUtils } from '@premagic/utils';
import { StoreOfferItemType } from '../StoreOffersService';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  storeOffer: StoreOfferItemType;
};

export default function StoreOfferItem(props: Props): JSX.Element {
  const { storeOffer } = props;
  const { id, title, images, expiry_date: expiryDate, tags, vendor } = storeOffer;
  const tagsItems = tags.map((tag) => (
    <Text size={TEXT_SIZE.SIZE_6} color={COLOR_SHADES.LIGHT} key={tag}>
      {tag}
    </Text>
  ));
  const detailsLink = BrowserUrlUtils.getRouteUrlFor(APP_URLS.STORE.DETAILS, {
    id,
  });
  return (
    <Button link={detailsLink} style={BUTTON_STYLES.RESET}>
      <Card overflowHidden size={CARD_SIZES.FULL_WIDTH}>
        <ErrorBoundary>
          <div
            style={{
              height: 250,
            }}
          >
            <ImageV2 src={images[0]} alt={title} style={IMAGE_V2_STYLES.COVER} />
          </div>
          <Space>
            <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5} block>
              {vendor}
            </Text>
            <Space vertical size={1} />
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
              {title}
            </Text>
            {ArrayUtils.intersperse(tagsItems, ', ')}
            <Space vertical size={1} />
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
              Expires on <TimeAgo date={expiryDate} />
            </Text>
          </Space>
        </ErrorBoundary>
      </Card>
    </Button>
  );
}
