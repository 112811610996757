import WebFont from 'webfontloader';
import { get } from './HttpUtils';

export type GoogleFontType = {
  category: string;
  family: string;
  files: {
    italic: string;
    regular: string;
  };
  italic: string;
  regular: string;
  lastModified: string;
  subsets: Array<string>;
  variants: Array<string>;
  version: string;
};

export function loadFont(
  config,
  onActive?: () => void,
  fontActive?: (fontFamily: string, fvd: string) => void,
  inactive?: () => void,
  fontInactive?: (fontFamily: string, fvd: string) => void,
): Promise<void> {
  return new Promise<void>((resolve) => {
    WebFont.load({
      ...config,
      active: onActive || resolve,
      fontactive: fontActive,
      inactive,
      fontinactive: fontInactive,
    });
  });
}

const API_KEY = 'AIzaSyBCv_Exguiou-Kmlgt4wr3XK1jAEk_Htwo';

const API_URLS = {
  GOOGLE_FONT: 'https://www.googleapis.com/webfonts/v1/webfonts',
};

export function fetchGoogleFonts(): Promise<Array<GoogleFontType>> {
  const options = {
    params: {
      key: API_KEY,
      sort: 'alpha',
    },
  };

  const headers = {
    authorization: API_KEY,
  };
  return get(API_URLS.GOOGLE_FONT, options, true, false, headers).then((response) => response.data.items);
}
