import { connect } from 'react-redux';
import { guestGalleryLinkSelector } from '../../../projects/AccountProjectsDataDuck';
import { eventPostersForMagazineArraySelector } from '../../event-posters/EventPostersDataDuck';

import EventAttendeeLinkToMagazineButton from './EventAttendeeLinkToMagazineButton';
import { eventAttendeesSelector } from './EventAttendeesDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;
  return {
    magazinePages: eventPostersForMagazineArraySelector(state),
    attendee: eventAttendeesSelector(state)[id],
    guestGalleryPreviewLink: guestGalleryLinkSelector(projectId)(state),
  };
}

export default connect(mapStateToProps)(EventAttendeeLinkToMagazineButton);
