import { FolderFilePositionOrderService, FolderMetaService, FolderService } from '@premagic/core';
import { INPUT_SIZES, Select } from '@premagic/myne';

type FolderGallerySortedByDropdownProps = {
  folder: FolderService.FolderType;
  projectId: string;
  folderId: string;
  sortedBy?: FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES;
  updateFolderFileSortedByType: (
    projectId: string,
    folderId: string,
    sortedByType: FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES,
  ) => void;
  saveFolderMetaData: (projectId: string, folderId: string, data) => void;
  sortedByValueFromMetaData?: FolderMetaService.FolderMetaType;
};

export default function FolderGallerySortedByDropdown(props: FolderGallerySortedByDropdownProps) {
  const {
    projectId,
    folderId,
    sortedBy = FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
    updateFolderFileSortedByType,
    folder,
    saveFolderMetaData,
    sortedByValueFromMetaData = {
      highlight_files_order: FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
      selection_files_order: FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
    },
  } = props;

  const selectedOptionForHighlight =
    FolderFilePositionOrderService.HIGHLIGHT_FOLDER_FILE_ORDER_SORTED_BY_OPTIONS.filter(
      (item) => item.value === sortedByValueFromMetaData.highlight_files_order,
    )[0];

  if (folder.folder_type === FolderService.FOLDER_TYPES.SELECTION) {
    const selectedOptionForSelection =
      FolderFilePositionOrderService.HIGHLIGHT_FOLDER_FILE_ORDER_SORTED_BY_OPTIONS.filter(
        (item) => item.value === sortedByValueFromMetaData.selection_files_order,
      )[0];

    return (
      <Select
        options={FolderFilePositionOrderService.SELECTION_FOLDER_FILE_ORDER_SORTED_BY_OPTIONS}
        value={selectedOptionForSelection}
        name="sorted by"
        onChange={(e) => {
          saveFolderMetaData(projectId, folderId, {
            data: {
              [FolderMetaService.FOLDER_META_TYPES.SELECTION_FILES_ORDER]:
                e?.value as FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES,
            },
          });
        }}
        isMulti={false}
        size={INPUT_SIZES.SM}
      />
    );
  }

  return (
    <Select
      options={FolderFilePositionOrderService.HIGHLIGHT_FOLDER_FILE_ORDER_SORTED_BY_OPTIONS}
      value={selectedOptionForHighlight}
      name="sorted by"
      onChange={(e) => {
        saveFolderMetaData(projectId, folderId, {
          data: {
            [FolderMetaService.FOLDER_META_TYPES.HIGHLIGHT_FILES_ORDER]:
              e?.value as FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES,
          },
        });

        // When the user drags and sorts, the previous custom order is retrieved from the backend.
        // Therefore, the order by type is stored in the order API (but not used to render the sorted images in the UI).
        updateFolderFileSortedByType(
          projectId,
          folderId,
          e?.value as FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES,
        );
      }}
      isMulti={false}
      size={INPUT_SIZES.SM}
    />
  );
}
