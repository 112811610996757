import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Dialog,
  DialogFooter,
  DialogTitle,
  DIALOG_PLACEMENT,
  Form,
  FormFooter,
  FormGroup,
  RichTextArea,
  Space,
  Text,
  TEXT_BOLDNESS,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';

import { CRMPaymentType } from '../../CRMPaymentService';

type DefaultNoteDialogProps = {
  onClick: () => void;
  toggleDialog: (val: boolean) => void;
  isDialogOpen: boolean;
  isLoading: boolean;
};

function DefaultNoteConfirmationDialog(props: DefaultNoteDialogProps): React.ReactElement {
  const { isLoading, toggleDialog, isDialogOpen, onClick } = props;
  const target = useRef(null);

  return (
    <span ref={target}>
      <Dialog
        target={target.current}
        show={isDialogOpen}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => toggleDialog(false)}
      >
        <DialogTitle>Set as default note</DialogTitle>
        <Text>
          Setting this as default note will <Text boldness={TEXT_BOLDNESS.BOLD}>set all the new</Text> invoices with
          this note.
        </Text>
        <Space vertical size={6} />
        <Text>Are you sure you want to set this as default note?</Text>
        <DialogFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
            Cancel
          </Button>
          <Button style={BUTTON_STYLES.PRIMARY} onClick={onClick} isLoading={isLoading}>
            Set
          </Button>
        </DialogFooter>
      </Dialog>
    </span>
  );
}

type Props = {
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  hasActiveSubscription: boolean;
  updateInvoiceNotes: (id: string, eventId: string, data) => void;
  eventId: string;
  quotePayment?: CRMPaymentType;
  invoiceNote?: string;
  defaultInvoiceNote?: string;
  saveDefaultNote: (id, data) => void;
  accountId?: string;
  isDefaultNoteButtonLoading: boolean;
};

export default function AddInvoiceNotePanel(props: Props): JSX.Element | null {
  const {
    show,
    isLoading,
    errors,
    closePanel,
    hasActiveSubscription,
    updateInvoiceNotes,
    eventId,
    quotePayment,
    invoiceNote,
    defaultInvoiceNote,
    saveDefaultNote,
    accountId,
    isDefaultNoteButtonLoading,
  } = props;

  const [note, setNote] = useState(invoiceNote || defaultInvoiceNote);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleSubmit(formResponse) {
    if (quotePayment) {
      updateInvoiceNotes(quotePayment.id, eventId, formResponse);
    }
  }

  function handleOnClick() {
    saveDefaultNote(accountId, { data: { default_invoice_note: note } });
    setIsDialogOpen(false);
  }

  if (!show) return null;
  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Invoice Notes</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={errors} onSubmit={handleSubmit}>
          <FormGroup>
            <RichTextArea name="notes" defaultValue={note} onChange={(value) => setNote(value)} />
          </FormGroup>
          <Button
            size={BUTTON_SIZE.SM}
            style={BUTTON_STYLES.TERTIARY}
            isLoading={isDefaultNoteButtonLoading}
            disabled={!hasActiveSubscription || !accountId || !note}
            onClick={() => setIsDialogOpen(true)}
          >
            Set as default Note
          </Button>
          <DefaultNoteConfirmationDialog
            onClick={handleOnClick}
            toggleDialog={setIsDialogOpen}
            isLoading={isDefaultNoteButtonLoading}
            isDialogOpen={isDialogOpen}
          />
          <Space vertical size={12} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
