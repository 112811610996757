import { connect } from 'react-redux';

import HighlightDesignTextEditorWindowPanel from './HighlightDesignTextEditorWindowPanel';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../../common/ErrorDuck';
import { saveProjectMetaData } from '../../../../project-meta/ProjectsMetaDataDuck';

const PANEL_KEY = LOADINGS.HIGHLIGHT_DESIGN.SHOW_EDIT_TEXT_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    galleryWelcomeText?: string;
    galleryEndingText?: string;
  },
) {
  const { projectId, galleryWelcomeText, galleryEndingText } = ownProps;

  return {
    projectId,
    galleryWelcomeText,
    galleryEndingText,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    error: errorObjectForTypeSelector(LOADINGS.PROJECT_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignTextEditorWindowPanel);
