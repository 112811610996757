import { EventAttendeesService, EventPosterService, FolderService } from '@premagic/core';

import { Button, BUTTON_STYLES, Icon, ICON_SIZES, Space } from '@premagic/myne';

type Props = {
  id: string;
  badgeId: string;
  attendee: EventAttendeesService.EventAttendeeType;
  clientWebsite?: FolderService.FolderType;
};

export default function EventAttendeeLinkToBadgeButton(props: Props): JSX.Element | null {
  const { attendee, id, badgeId, clientWebsite } = props;
  const { registration_id: registrationId } = attendee;
  const attendeeBadgePrintUrl = EventPosterService.getAttendeeBadgePrintUrl({ clientWebsite, badgeId, registrationId });

  if (!attendeeBadgePrintUrl) return null;

  return (
    <Button style={BUTTON_STYLES.LINK} link={attendeeBadgePrintUrl} isExternalLink>
      <Icon name="external_link" size={ICON_SIZES.SM} />
      <Space size={1} />
      Print Badge
    </Button>
  );
}
