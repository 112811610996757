import React, { useEffect } from 'react';
import { History } from 'history';
import { AuthUtils } from '@premagic/utils';
import { Route, Router, Switch } from 'react-router-dom';

import AppLayoutPageContainer from './components/app/AppLayoutPageContainer';
import AuthPageContainer from './components/auth/AuthPageContainer';
import { AppLoading } from './components/app/AppLayoutPage';
import AuthPageSigninWithCodeContainer from './components/auth/AuthPageSigninWithCodeContainer';

type Props = {
  clearAuthState: () => void;
  initAuth: () => void;
  tokenRefresh: (data) => void;
  isLoggedIn: boolean;
  isAuthLoading: boolean;
  history: History;
};

export default function AccountAppRoutes(props: Props) {
  const { initAuth, isAuthLoading, isLoggedIn, history, clearAuthState, tokenRefresh } = props;
  useEffect(() => {
    initAuth();
    AuthUtils.initListener({
      signOut: clearAuthState,
      tokenRefresh,
    });
  }, []);

  if (isAuthLoading) {
    return <AppLoading />;
  }

  if (!isLoggedIn) {
    return (
      <div className="js-login-page">
        <Router history={history}>
          <Switch>
            <Route path="/signin" component={AuthPageSigninWithCodeContainer} />
            <Route component={AuthPageContainer} />
          </Switch>
        </Router>
      </div>
    );
  }

  return <AppLayoutPageContainer history={history} />;
}
