import React from 'react';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  List,
  ListItem,
  Row,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Space,
  InnerPageContentMainCol,
} from '@premagic/myne';
import { ClientSettingsService } from '@premagic/core';
import ProposalSettingsHeader from './ProposalSettingsHeader';

type Props = {
  savePreferences: (data) => void;
  isFeatureProposalEnabled: boolean;
  isLoading: boolean;
  currentUrlPathname: string;
};

export default function ProposalSettingsPage(props: Props) {
  const { isFeatureProposalEnabled, savePreferences, isLoading, currentUrlPathname } = props;

  return (
    <InnerPage>
      <ProposalSettingsHeader currentUrlPathname={currentUrlPathname} />
      <InnerPageContent hasInnerCols isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <List>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Enable Proposal
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>Create custom proposal for your clients</Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {isFeatureProposalEnabled ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isLoading}
                      name=""
                      checked={isFeatureProposalEnabled}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROPOSAL_ENABLE]: value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
            </List>
          </Card>
          <Space vertical size={8} />
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
