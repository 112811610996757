import React from 'react';
import { InnerPageHeader, InnerPageSubHeader, TabItem, Tabs } from '@premagic/myne';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  currentUrlPathname: string;
};

export default function CompanyBrandingHeader(props: Props) {
  const { currentUrlPathname } = props;
  return (
    <div>
      <InnerPageHeader iconName="bold" title="Branding" backTo={APP_URLS.SETTINGS.INDEX}>
        Branding
      </InnerPageHeader>
    </div>
  );
}
