import { FileService, QRCodeBackgroundPosterService } from '@premagic/core';
import { ButtonIcon, CustomColorCircle, Row, Space, TEXT_BOLDNESS, TEXT_SIZE, Text } from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import QRCodeColorPicker from '../common-ui/QRCodeColorPicker';

type Props = {
  projectId: string;
  folderId: string;
  backgroundCover: QRCodeBackgroundPosterService.QRCodePoster;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  updateColors: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    value?: { colorName: QRCodeBackgroundPosterService.POSTER_COLOR_ID; colorValue: string },
  ) => void;
  updateMultipleColors: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    value?: { primaryColorValue1: string; primaryColorValue2: string },
  ) => void;
  clientWebsiteColors: {
    background: string;
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
  };
  logoFileData?: FileService.FolderFileType;
  logoOptionType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
  companyLogo?: string;
  updateSecondaryColors: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    value?: QRCodeBackgroundPosterService.PosterSecondaryColorType,
  ) => void;
};

export default function QRCodePosterColorSelector(props: Props) {
  const {
    backgroundCover,
    updateColors,
    qrCodeDesigns,
    projectId,
    folderId,
    clientWebsiteColors,
    logoFileData,
    logoOptionType,
    companyLogo,
    updateMultipleColors,
    updateSecondaryColors,
  } = props;

  const extractColorFromLogo =
    qrCodeDesigns && 'color_extraction_from_logo' in qrCodeDesigns ? qrCodeDesigns.color_extraction_from_logo : true;

  const selectedLogoImageSrc =
    logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO
      ? logoFileData?.dynamic_image_url
      : companyLogo;

  const primaryColor1 =
    qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS]?.[
      QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_1
    ] || clientWebsiteColors?.primary_color;

  const primaryColor2 =
    qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLORS]?.[
      QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_2
    ] || clientWebsiteColors?.secondary_color;

  const debouncedHandleColorsChange = useMemo(() => {
    function handleColorsChange(colorName, colorValue) {
      updateColors(projectId, folderId, qrCodeDesigns, { colorName, colorValue });
    }
    return debounce(handleColorsChange, 500);
  }, [projectId, folderId, qrCodeDesigns, updateColors]);

  useEffect(() => {
    // Logo color extraction
    if (selectedLogoImageSrc && extractColorFromLogo) {
      ColorUtils.getDominantColorsOfImage(selectedLogoImageSrc).then((colors) => {
        const extractedColor1 = colors[0]?.hex;
        const extractedColor2 = colors[1]?.hex;

        updateMultipleColors(projectId, folderId, qrCodeDesigns, {
          primaryColorValue1: extractedColor1,
          primaryColorValue2: extractedColor2,
        });
      });
    }
  }, [projectId, folderId, updateMultipleColors, selectedLogoImageSrc, extractColorFromLogo]);

  if (backgroundCover.designOptions.numberOfColors === 0) return null;
  return (
    <>
      <Row vcenter spaceAround>
        <QRCodeColorPicker
          name={QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_1}
          title="Color 1"
          defaultValue={primaryColor1}
          onChange={(val) =>
            debouncedHandleColorsChange(QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_1, val)
          }
        />

        {backgroundCover?.designOptions?.numberOfColors >= 2 && (
          <QRCodeColorPicker
            name={QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_2}
            title="Color 2"
            defaultValue={primaryColor2}
            onChange={(val) =>
              debouncedHandleColorsChange(QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_2, val)
            }
          />
        )}
      </Row>
      <Space vertical size={4} />
      {backgroundCover.designOptions.isSecondaryColorAvailable && (
        <>
          <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} size={TEXT_SIZE.SIZE_5} muted>
            SECONDARY COLORS PRESET
          </Text>
          <Space vertical size={1} />
          <Row vcenter spaceAround>
            {Object.values(QRCodeBackgroundPosterService.SECONDARY_COLOR_PRESET_DETAILS).map((item, idx) => {
              const key = `secondary-color-${idx}`;
              return (
                <ButtonIcon
                  key={key}
                  title=""
                  onClick={() => updateSecondaryColors(projectId, folderId, qrCodeDesigns, item)}
                >
                  <CustomColorCircle
                    size="md"
                    color1={item.color_1}
                    color2={item.color_2}
                    color3={item.color_3}
                    color4={item.color_4}
                  />
                </ButtonIcon>
              );
            })}
          </Row>
        </>
      )}
    </>
  );
}
