import {
  COLOR_SHADES,
  Color,
  ICON_SIZES,
  Icon,
  InfoBoxModal,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import QRCodeBackgroundPosterSelectorModalContainer from './background-poster/QRCodeBackgroundPosterSelectorModalContainer';
import QRCodeIllustrationSelectorModalContainer from './illustration/QRCodeIllustrationSelectorModalContainer';
import QRCodePosterSponsorLogoSelectorModalContainer from './sponsor-logo/QRCodePosterSponsorLogoSelectorModalContainer';
import QRCodeLEDScreenCustomPosterSelectModalContainer from './led-screen-custom-poster/QRCodeLEDScreenCustomPosterSelectModalContainer';
import QRCodeLogoSelectorModalContainer from './logo/QRCodeLogoSelectorModalContainer';

type DownloadInfoModalProps = {
  show?: boolean;
};

function DownloadInfoModal(props: DownloadInfoModalProps) {
  const { show } = props;

  if (!show) return null;

  return (
    <InfoBoxModal>
      <Space size={4}>
        <Row vcenter>
          <Color inline shade={COLOR_SHADES.SUCCESS}>
            <Icon name="download" size={ICON_SIZES.MD} />
          </Color>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.SUCCESS} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            Downloading...
          </Text>
        </Row>
      </Space>
    </InfoBoxModal>
  );
}

type Props = { projectId: string; folderId: string; showDownloadInfoModal?: boolean };

export default function QRCodePosterAllModals(props: Props) {
  const { projectId, folderId, showDownloadInfoModal } = props;
  return (
    <>
      <QRCodeIllustrationSelectorModalContainer projectId={projectId} folderId={folderId} />
      <QRCodeBackgroundPosterSelectorModalContainer projectId={projectId} folderId={folderId} />
      <QRCodePosterSponsorLogoSelectorModalContainer projectId={projectId} folderId={folderId} />
      <QRCodeLEDScreenCustomPosterSelectModalContainer projectId={projectId} folderId={folderId} />
      <QRCodeLogoSelectorModalContainer projectId={projectId} folderId={folderId} />
      <DownloadInfoModal show={showDownloadInfoModal} />
    </>
  );
}
