import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import CRMDashboardPage from './CRMDashboardPage';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { filters, view } = ownProps.match.params;

  const hasCRMFeature = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM)(state);
  return {
    filters,
    view,
    hasCRMFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMDashboardPage);
