import React from 'react';
import { FormGroup, Label, Space, SubTitle, Text } from '@premagic/myne';
import EventAttendeeBadgeDownloadAllContainer from '../poster-badge/EventAttendeeBadgeDownloadAllContainer';
import UpdateEventAttendeeBadgesIdsContainer from '../poster-badge/UpdateEventAttendeeBadgesIdsContainer';

type Props = {
  projectId: string;
  posterId?: string;
};

export default function EventPosterPreviewSidebar(props: Props) {
  const { projectId, posterId } = props;
  if (!posterId) {
    return null;
  }

  return (
    <Space size={4}>
      <FormGroup>
        <SubTitle>Sort By</SubTitle>
        <Label>Company name</Label>
      </FormGroup>
      <Space vertical />
      <SubTitle>Actions</SubTitle>
      <Space vertical size={2} />
      <FormGroup>
        <EventAttendeeBadgeDownloadAllContainer projectId={projectId} posterId={posterId} />
      </FormGroup>
      <FormGroup>
        <UpdateEventAttendeeBadgesIdsContainer projectId={projectId} posterId={posterId} />
      </FormGroup>
    </Space>
  );
}
