import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  COLOR_SHADES,
  FormFooter,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  RequestFeatureEnabling,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { PricingPlanFeaturesService, ProjectMetaService, ProjectService } from '@premagic/core';
import { NumberUtils } from '@premagic/utils';
import FeatureInfo from '../../help/feature-details/FeatureInfo';
import PremiumFeatureIcon from '../../settings/PremiumFeatureIcon';
import AIPhotoDeliveryNotificationInfoContainer from './AIPhotoDeliveryNotificationInfoContainer';
import { AI_DELIVERY_STATES } from './AIPhotoDeliveryService';
import MESSAGES from '../../../../../common/Messages';

const aiFeatureDetails = PricingPlanFeaturesService.getFeatureDetails(
  PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY,
);
type Props = {
  state: false | AI_DELIVERY_STATES;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  project: ProjectService.ProjectType;
  projectId: string;
  onHide: () => void;
  onConfirm: (state: AI_DELIVERY_STATES) => void;
  isStaffUser: boolean;
  hasAiDeliveryFeature: boolean;
  hasExpressAiDeliveryFeature: boolean;
  remainingAiDeliveryPhotos: number;
};

export default function AIPhotoDeliveryConfirmationModal(props: Props) {
  const {
    projectId,
    state,
    onHide,
    onConfirm,
    isStaffUser,
    project,
    hasAiDeliveryFeature,
    projectMeta,
    hasExpressAiDeliveryFeature,
    remainingAiDeliveryPhotos,
  } = props;
  if (!state) return null;
  const hasMinAICount = remainingAiDeliveryPhotos >= 99;

  const {
    [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM]: aiSignatureAlbumEnabled,
    [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_EXPRESS_DELIVERY]: aiSignatureAlbumExpressEnabled,
  } = projectMeta || {};
  if (state === AI_DELIVERY_STATES.OFF) {
    const isActive = !aiSignatureAlbumEnabled && !aiSignatureAlbumExpressEnabled;
    return (
      <Modal size={MODAL_SIZES.MD} show close={onHide}>
        {isActive ? (
          <ModalTitle>{aiFeatureDetails.name} is OFF</ModalTitle>
        ) : (
          <ModalTitle>Turn OFF {aiFeatureDetails.name}?</ModalTitle>
        )}
        <Text boldness={TEXT_BOLDNESS.BOLD}>Once its OFF</Text>
        <ul>
          <li>
            <Text>Premagic will stop scanning the people in the gallery</Text>
          </li>
          <li>
            <Text>Premagic will stop sending notification to people</Text>
          </li>
        </ul>
        {isActive ? (
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
              Close
            </Button>
          </FormFooter>
        ) : (
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.DANGER} onClick={() => onConfirm(state)}>
              Turn OFF
            </Button>
          </FormFooter>
        )}
      </Modal>
    );
  }

  if (state === AI_DELIVERY_STATES.NORMAL) {
    const isActive = aiSignatureAlbumEnabled && !aiSignatureAlbumExpressEnabled;
    return (
      <Modal size={MODAL_SIZES.MD} show close={onHide}>
        {isActive ? (
          <ModalTitle>{aiFeatureDetails.name} is enabled</ModalTitle>
        ) : (
          <ModalTitle>
            Enable {aiFeatureDetails.name}?
            <Space />
            <FeatureInfo feature={PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY} />
            <Space />
            {!hasAiDeliveryFeature && <PremiumFeatureIcon module={aiFeatureDetails.name} />}
          </ModalTitle>
        )}
        <Text boldness={TEXT_BOLDNESS.BOLD}>Once its enabled</Text>
        <ul>
          <li>
            <Text>Premagic will scan all the people in the gallery</Text>
          </li>
          <li>
            <Text>Premagic will create personalized gallery for registered users</Text>
          </li>
          <li>
            <AIPhotoDeliveryNotificationInfoContainer projectId={projectId} />
          </li>
        </ul>
        <Space vertical />
        <Row>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Note:</Text>
          <Space size={2} />
          <Text block>
            You have{' '}
            <Text boldness={TEXT_BOLDNESS.BOLD}>{NumberUtils.getNumberInFormat(remainingAiDeliveryPhotos)}</Text> AI
            photos left.
            <Space vertical size={1} />
            <Text>AI Processing will be switch off after period of inactivity.</Text>
          </Text>
        </Row>
        {isActive ? (
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
              Close
            </Button>
          </FormFooter>
        ) : (
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
              Cancel
            </Button>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              onClick={() => onConfirm(state)}
              disabledMessage={MESSAGES.SIGNATURE_FOLDER.AI_PHOTOS_TOO_LESS}
              disabled={!hasAiDeliveryFeature || !hasMinAICount}
            >
              Enable
            </Button>
          </FormFooter>
        )}
      </Modal>
    );
  }

  const isActive = aiSignatureAlbumExpressEnabled;
  return (
    <Modal size={MODAL_SIZES.MD} show close={onHide}>
      {isActive ? (
        <ModalTitle>
          {state} {aiFeatureDetails.name} is enabled
        </ModalTitle>
      ) : (
        <ModalTitle>
          Enable {state} {aiFeatureDetails.name}?
          <Space />
          <FeatureInfo feature={PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY} />
          <Space />
          {!hasAiDeliveryFeature && <PremiumFeatureIcon module={aiFeatureDetails.name} />}
        </ModalTitle>
      )}

      <Text boldness={TEXT_BOLDNESS.BOLD}>Once its enabled</Text>
      <ul>
        <li>
          <Text>
            People get their photos in <Text boldness={TEXT_BOLDNESS.BOLD}>under 30 mins</Text> of upload.
          </Text>
        </li>
        <li>
          <AIPhotoDeliveryNotificationInfoContainer projectId={projectId} />
        </li>
      </ul>
      <Space vertical />
      <Row>
        <Text boldness={TEXT_BOLDNESS.BOLD}>Note:</Text>
        <Space size={2} />
        <Text block>
          You have <Text boldness={TEXT_BOLDNESS.BOLD}>{NumberUtils.getNumberInFormat(remainingAiDeliveryPhotos)}</Text>{' '}
          AI photos left.
          <Space vertical size={1} />
          <Text>This will automatically switch to normal delivery after 12 hours.</Text>
        </Text>
      </Row>
      {isActive ? (
        <FormFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
            Close
          </Button>
        </FormFooter>
      ) : (
        <FormFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={onHide}>
            Cancel
          </Button>
          {hasExpressAiDeliveryFeature ? (
            <Button
              style={BUTTON_STYLES.PRIMARY}
              onClick={() => onConfirm(state)}
              disabled={!hasMinAICount}
              disabledMessage={MESSAGES.SIGNATURE_FOLDER.AI_PHOTOS_TOO_LESS}
            >
              Enable Express
            </Button>
          ) : (
            <RequestFeatureEnabling
              featureName="Express Photo Delivery"
              projectName={project.project_name || 'no-set'}
              onClose={onHide}
            />
          )}
        </FormFooter>
      )}

      {isStaffUser && (
        <div>
          <Space vertical />
          <Card style={CARD_STYLES.SECONDARY}>
            <Space size={2}>
              <Text color={COLOR_SHADES.PRIMARY} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5}>
                You! staff can only enable this!, Others will see only Enable
                <Space size={4} />
                <Button
                  style={BUTTON_STYLES.PRIMARY}
                  size={BUTTON_SIZE.SM}
                  onClick={() => onConfirm(state)}
                  disabled={!hasAiDeliveryFeature}
                >
                  Enable {state}
                </Button>
              </Text>
            </Space>
          </Card>
        </div>
      )}
    </Modal>
  );
}
