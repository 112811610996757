import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';
import AddSocialMessageModal from './AddSocialMessageModal';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    scope: ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE;
  },
) {
  const { projectId, scope } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    scope,
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    show: isModalOpenSelector(LOADINGS.PROJECT_META.SHOW_ADD_SOCIAL_MESSAGE_MODAL(scope))(state),
    socialShareMessages: projectMeta?.social_share_messages,
    error: errorObjectForTypeSelector(LOADINGS.PROJECT_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: (scope) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_ADD_SOCIAL_MESSAGE_MODAL(scope), false));
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSocialMessageModal);
