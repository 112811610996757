import { connect } from 'react-redux';
import { EventAttendeesService } from '@premagic/core';

import EventPosterPreviewLayout from './EventPosterPreviewLayout';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';
import { filesSelector } from '../../images/FilesDataDuck';
import { eventAttendeesIdsForTypeSelector } from '../../crm/events/event-attendees/EventAttendeesDataDuck';

function mapStateToProps(state, ownProps: { posterId: string }) {
  const { posterId } = ownProps;
  const poster = posterId ? eventPostersSelector(state)[posterId] : undefined;
  const { scope = [EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE] } = poster || {};

  return {
    poster,
    files: filesSelector(state),
    eventAttendeesIds: eventAttendeesIdsForTypeSelector(scope)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterPreviewLayout);
