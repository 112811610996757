import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { Dispatch } from 'redux';
import { Promise as BluebirdPromise } from 'bluebird';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ArrayUtils, BrowserUrlUtils, ErrorTracker, NumberUtils } from '@premagic/utils';
import { FormResponseType } from '@premagic/myne';

import { EventTrackerService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { CRMPaymentSchema, CRMPaymentsSchema } from '../../../../schema/Schemas';
import APP_URLS from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import {
  createPaymentForEvent,
  CRMPaymentType,
  deletePaymentForEvent,
  editPaymentForEvent,
  fetchPaymentsForEvent,
  PAYMENT_TYPES,
  sentPaymentReminderToClient,
  TAX_TYPES,
  paymentAcknowledgement,
} from './CRMPaymentService';
import {
  clientPaymentCategoriesIdsSelector,
  expensePaymentCategoriesIdsSelector,
  quoteCategorySelector,
} from './CRMPaymentCategoryDataDuck';
import { requestingUserIdSelector } from '../users/UsersDataDuck';

import { toastMessage } from '../../../reducers/ToastStore';
import { accountTaxPercentageSelector } from '../../acccount/AccountDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_PAYMENT');

const setPaymentsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addPaymentData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updatePaymentData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
const removePaymentData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, data) => data);

const LOADING_KEYS = LOADINGS.CRM_PAYMENTS;

export const fetchPaymentsForEventData = createAction(
  getActionType('DATA', 'FETCH'),
  async (dispatch: Dispatch, eventId: string) => {
    const loadingKey = LOADING_KEYS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    try {
      const data = await fetchPaymentsForEvent(eventId);
      const normalizedData = normalize(data.results, CRMPaymentsSchema);
      dispatch(setPaymentsData(dispatch, normalizedData.entities.payments));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Payments: fetch failed', e);
    }
  },
);

function focusPayment(dispatch: Dispatch, paymentId: string, eventId: string) {
  const eventDetailsFinancialPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__FINANCIAL, {
    eventId,
    focusId: paymentId,
  });
  navigateTo(dispatch, eventDetailsFinancialPage);
}

export const addPaymentForEvent = createAction(
  getActionType('PAYMENT', 'CREATE'),
  (
    dispatch: Dispatch,
    eventId: string,
    formResponse: FormResponseType & {
      data: CRMPaymentType;
    },
    paymentType: PAYMENT_TYPES,
  ) => {
    const loadingKey = LOADING_KEYS.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    createPaymentForEvent(eventId, formResponse.data, paymentType)
      .then((response) => {
        const normalizedData = normalize(response, CRMPaymentSchema);
        dispatch(addPaymentData(dispatch, normalizedData.entities.payments));
        focusPayment(dispatch, response.id, eventId);
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        if (paymentType === PAYMENT_TYPES.QUOTE) {
          dispatch(toggleModalVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_EDIT_QUOTE_DIALOG, false));
          dispatch(toggleModalVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_BULK_ADD_CLIENT_PAYMENTS, true));
        } else {
          dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_CLIENT_PAYMENT_PANEL, false));
          dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_EXPENSE_PAYMENT_PANEL, false));
        }
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Payments: create', e);
      });
  },
);

export const addPaymentInBulkForEvent = createAction(
  getActionType('PAYMENTS', 'CREATE'),
  (
    dispatch: Dispatch,
    eventId: string,
    formResponse: FormResponseType & {
      data: {
        payments: Array<CRMPaymentType>;
      };
    },
    paymentType: PAYMENT_TYPES,
  ) => {
    const loadingKey = LOADING_KEYS.BULK_ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const { payments } = formResponse.data;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_PAYMENTS.CREATE_BULK_ACTION, {
      payments,
      eventId,
      paymentType,
    });

    BluebirdPromise.mapSeries(payments, (payment) => createPaymentForEvent(eventId, payment, paymentType))
      .then((response) => {
        const normalizedData = normalize(response, CRMPaymentsSchema);
        dispatch(addPaymentData(dispatch, normalizedData.entities.payments));

        dispatch(toggleModalVisibility(dispatch, LOADING_KEYS.SHOW_BULK_ADD_CLIENT_PAYMENTS, false));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Bulk Payments: create', e);
      });
  },
);

export const sendPaymentAcknowledgement = createAction(
  getActionType('PAYMENT', 'ACKNOWLEDGEMENT'),
  (dispatch: Dispatch, id: string, eventId: string) => {
    paymentAcknowledgement(id, eventId)
      .then((response) => {
        toastMessage('success', 'Notification has been sent to client');
        return response;
      })
      .catch((e) => {
        toastMessage('error', 'Notification sent failed!');
        ErrorTracker.logError('Notification sent failed!', e);
      });
  },
);

export const updatePayment = createAction(
  getActionType('PAYMENT', 'EDIT'),
  (
    dispatch: Dispatch,
    options: {
      paymentId: string;
      eventId: string;
      notifyClient?: boolean;
    },
    formResponse: FormResponseType & {
      data: CRMPaymentType;
    },
  ) => {
    const loadingKey = LOADING_KEYS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { paymentId, eventId } = options;

    editPaymentForEvent(paymentId, eventId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, CRMPaymentSchema);
        dispatch(updatePaymentData(dispatch, normalizedData.entities.payments));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        focusPayment(dispatch, response.id, eventId);
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_EDIT_EXPENSE_PAYMENT_PANEL, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_EDIT_CLIENT_PAYMENT_PANEL, false));
        dispatch(toggleModalVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_EDIT_QUOTE_DIALOG, false));
        if (options?.notifyClient) {
          dispatch(sendPaymentAcknowledgement(dispatch, paymentId, eventId));
        }
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Payment: update failed', e);
      });
  },
);

export const removePayment = createAction(
  getActionType('PAYMENT', 'DELETE'),
  (dispatch: Dispatch, id: string, eventId: string) => {
    const loadingKey = LOADING_KEYS.DELETE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    deletePaymentForEvent(id, eventId)
      .then((response) => {
        dispatch(removePaymentData(dispatch, id));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, LOADING_KEYS.SHOW_DELETE_DIALOG, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Payment: delete failed', e);
      });
  },
);

export const sentReminderToClient = createAction(
  getActionType('PAYMENT', 'REMINDER'),
  (dispatch: Dispatch, id: string, eventId: string) => {
    const loadingKey = LOADING_KEYS.REMINDER_TO_CLIENT;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PAYMENT.SEND_REMINDER, { eventId, paymentId: id });
    toastMessage('success', 'Reminder send via whatsapp & email');

    sentPaymentReminderToClient(id, eventId)
      .then((response) => response)
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Payment: reminder sending failed', e);
      });
  },
);

export const updateInvoiceNotes = createAction(
  getActionType('PAYMENT_INVOICE_NOTES', 'EDIT'),
  (
    dispatch: Dispatch,
    id: string,
    eventId: string,
    formResponse: FormResponseType & {
      data: CRMPaymentType;
    },
  ) => {
    const loadingKey = LOADING_KEYS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    editPaymentForEvent(id, eventId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, CRMPaymentSchema);
        dispatch(updatePaymentData(dispatch, normalizedData.entities.payments));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_INVOICE_NOTE_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Payment-invoice-notes: update failed', e);
      });
  },
);

type PaymentsStateType = {
  items: IMap<string, CRMPaymentType>;
};

const initialState = {
  items: IMap([]),
};

export default handleActions(
  {
    [setPaymentsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addPaymentData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updatePaymentData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removePaymentData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(action.payload),
    }),
  },
  initialState,
);

const paymentsDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.crm.payments as PaymentsStateType,
);

const paymentEntitiesSelector = createSelector(paymentsDataSelector, (state) => state.items);

export const crmPaymentsSelector = createSelector(paymentsDataSelector, (state) => state.items.toJSON());
export const isUserOwnerOfCrmPayment = (paymentId: string) =>
  createSelector(
    paymentEntitiesSelector,
    requestingUserIdSelector,
    (payments, userId) => payments.get(paymentId)?.added_by === userId,
  );

// Client payment
const crmClientPaymentsSelector = createSelector(
  paymentEntitiesSelector,
  clientPaymentCategoriesIdsSelector,
  (payments, categoryIds) => payments.filter((payment) => categoryIds.includes(payment.payment_category)),
);

export const crmAllClientPaymentsSelector = createSelector(crmClientPaymentsSelector, (payments) =>
  payments
    .sort((a, b) =>
      ArrayUtils.dateSortForISODateFunction(
        (a.is_settled ? a.payment_date : a.due_date) as string,
        (b.is_settled ? b.payment_date : b.due_date) as string,
        false,
      ),
    )
    .valueSeq()
    .toArray(),
);

// Client payment - pending
const crmPendingClientPaymentSelector = createSelector(crmClientPaymentsSelector, (payments) =>
  payments
    .filter((payment) => !payment.is_settled)
    .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.due_date as string, b.due_date as string, false)),
);

export const crmPendingClientPaymentIdsSelector = createSelector(crmPendingClientPaymentSelector, (payments) =>
  payments.keySeq().toArray(),
);

export const crmClientPaymentTotalSelector = createSelector(crmClientPaymentsSelector, (payments) =>
  payments.reduce((result, payment) => result + payment.amount, 0),
);

export const crmPendingClientPaymentTotalSelector = createSelector(crmPendingClientPaymentSelector, (payments) =>
  payments.reduce((result, payment) => result + payment.amount, 0),
);
// Client payment - settled
const crmSettledClientPaymentSelector = createSelector(crmClientPaymentsSelector, (payments) =>
  payments
    .filter((payment) => payment.is_settled)
    .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.payment_date as string, b.payment_date as string, false)),
);

export const crmSettledClientPaymentIdsSelector = createSelector(crmSettledClientPaymentSelector, (payments) =>
  payments.keySeq().toArray(),
);

export const crmSettledClientPaymentTotalSelector = createSelector(crmSettledClientPaymentSelector, (payments) =>
  payments.reduce((result, payment) => result + payment.amount, 0),
);

// Client payment - tax

export const crmTotalTaxableAmountSelector = createSelector(
  crmClientPaymentsSelector,
  accountTaxPercentageSelector,
  (payments, taxPercentage) => {
    const totalAmountIncludingTax = payments
      .filter((payment) => payment.taxable === TAX_TYPES.INCLUDING)
      .reduce((result, item) => result + item.amount, 0);

    return totalAmountIncludingTax / (1 + taxPercentage / 100);
  },
);

export const crmTotalNonTaxableAmountSelector = createSelector(crmClientPaymentsSelector, (payments) =>
  payments.filter((payment) => payment.taxable === TAX_TYPES.NO_TAX).reduce((result, item) => result + item.amount, 0),
);

export const crmTotalTaxAmountSelector = createSelector(
  crmTotalTaxableAmountSelector,
  accountTaxPercentageSelector,
  (taxableAmount, taxPercentage) => (taxPercentage / 100) * taxableAmount,
);

// Expense
const crmExpensePaymentsSelector = createSelector(
  paymentEntitiesSelector,
  expensePaymentCategoriesIdsSelector,
  (payments, categoryIds) =>
    payments
      .filter((payment) => categoryIds.includes(payment.payment_category))
      .sort((a, b) => {
        const dateA = a.is_settled ? a.payment_date : a.due_date;
        const dateB = b.is_settled ? b.payment_date : b.due_date;
        return ArrayUtils.dateSortForISODateFunction(dateA as string, dateB as string, false);
      }),
);

export const crmExpensePaymentIdsSelector = createSelector(crmExpensePaymentsSelector, (payments) =>
  payments.keySeq().toArray(),
);

export const crmExpensePaymentTotalSelector = createSelector(crmExpensePaymentsSelector, (payments) =>
  payments.reduce((result, payment) => result + payment.amount, 0),
);

export const crmExpensePaymentUnSettledTotalSelector = createSelector(crmExpensePaymentsSelector, (payments) =>
  payments.filter((payment) => !payment.is_settled).reduce((result, payment) => result + payment.amount, 0),
);

// Quote
export const crmQuoteSelector = createSelector(
  paymentEntitiesSelector,
  quoteCategorySelector,
  (payments, quoteCategory) => payments.find((payment) => payment.payment_category === quoteCategory?.id),
);

export const crmTotalRemainingClientPaymentAmountSelector = createSelector(
  crmQuoteSelector,
  crmClientPaymentTotalSelector,
  (quotePayment, totalClientPayment) => {
    const remainingAmount = (quotePayment?.amount || 0) - totalClientPayment;
    if (remainingAmount > 0) return remainingAmount;
    return 0;
  },
);

const crmBalanceFromQuoteSelector = createSelector(
  crmQuoteSelector,
  crmSettledClientPaymentTotalSelector,
  (quotePayment, totalSettled) => (quotePayment?.amount || 0) - totalSettled,
);

export const crmBalanceAmountSelector = createSelector(
  crmBalanceFromQuoteSelector,
  crmPendingClientPaymentTotalSelector,
  (balanceFromQuote, totalUnpaid) => (balanceFromQuote > totalUnpaid ? balanceFromQuote : totalUnpaid),
);

export const crmEventRevenueSelector = createSelector(
  crmSettledClientPaymentTotalSelector,
  crmExpensePaymentTotalSelector,
  (totalSettled, expense) => totalSettled - expense,
);

export const crmEventRevenuePercentSelector = createSelector(
  crmSettledClientPaymentTotalSelector,
  crmEventRevenueSelector,
  (totalSettled, revenue) => NumberUtils.getNumberInPercentageFormat(totalSettled ? revenue / totalSettled : -1),
);

// Invoice Note

export const crmInvoiceNoteSelector = createSelector(
  paymentEntitiesSelector,
  quoteCategorySelector,
  (payments, quoteCategory) => {
    const quote = payments.find((payment) => payment.payment_category === quoteCategory?.id);
    return quote?.notes;
  },
);
