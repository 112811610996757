import React, { useEffect } from 'react';
import {
  ButtonIcon,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { ProductFeedbackService } from '@premagic/core';
import APP_URLS from '../../../../../services/AppRouteURLService';
import EventFeedbackListContainer from '../event-feedback-list/EventFeedbackListContainer';

type Props = {
  eventId: string;
  projectId?: string;
  getEventFeedback: (projectId: string, type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE) => void;
  isLoading?: boolean;
  type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
};

export default function EventFeedbackLayout(props: Props): React.ReactElement {
  const { eventId, projectId, getEventFeedback, isLoading, type } = props;

  useEffect(() => {
    if (projectId) getEventFeedback(projectId, type);
  }, [getEventFeedback, projectId, type]);

  const urlForPeoplesPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEES, {
    eventId,
  });

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;
  if (isLoading) return <LoadingDots />;
  return (
    <InnerPageContentMainCol hasSidebar={false}>
      <Row vcenter>
        <ButtonIcon title="Back" link={urlForPeoplesPage}>
          <Icon name="arrow_left" size={ICON_SIZES.SM} />
        </ButtonIcon>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          Event Feedbacks
        </Text>
      </Row>
      <Space vertical />
      <EventFeedbackListContainer projectId={projectId} type={type} />
    </InnerPageContentMainCol>
  );
}
