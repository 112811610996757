import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { CommonReducer } from '@premagic/common-ducks';

import AppDuck from '../components/AppDuck';
import entities from './EntitiesReducer';
import pages from './PagesReducer';
import AccountAppAlertsDataDuck from '../components/app-alerts/AccountAppAlertsDataDuck';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    entities,
    pages,
    app: AppDuck,
    appAlerts: AccountAppAlertsDataDuck,
    common: CommonReducer,
  });
