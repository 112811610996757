import React from 'react';
import { Col, FormGroup, FormLabel, Input, Row, Space } from '@premagic/myne';
import SharePanelClientDetails from '../../../folder-share/SharePanelClientDetails';
import InputPhoneNumberContainer from '../../../acccount/InputPhoneNumberContainer';

type CRMClientFormProps = {
  client: {
    name: string;
    email?: string;
    phone_number?: string;
  };
};

function CRMClientForm(props: CRMClientFormProps): JSX.Element {
  const { client } = props;
  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Client name</FormLabel>
        <Input type="text" name="name" defaultValue={client?.name} placeholder="eg. Aman" autoFocus />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired>Phone</FormLabel>
            <InputPhoneNumberContainer name="phone_number" defaultValue={client?.phone_number} required />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel>Email address</FormLabel>
            <Input type="email" name="email" defaultValue={client?.email} placeholder="eg. aman@premagic.com" />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

type SharePanelFormProps = {
  client: {
    name: string;
    phone_number?: string;
    email?: string;
  };
};

export default function SharePanelForm(props: SharePanelFormProps) {
  const { client } = props;
  const hasClientDetails = client && client.phone_number && client.email;

  if (hasClientDetails) {
    return <SharePanelClientDetails client={client} />;
  }

  return <CRMClientForm client={client} />;
}
