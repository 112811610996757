import React from 'react';

import { FolderService } from '@premagic/core';
import {
  BUTTON_STYLES,
  Button,
  Dialog,
  DialogFooter,
  DIALOG_PLACEMENT,
  Form,
  FormResponseType,
  FormErrorType,
  FormGroup,
  FormLabel,
  Input,
} from '@premagic/myne';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../common/JSClassSelectors';

type Props = {
  isRenaming: boolean;
  folder: FolderService.FolderType;
  projectId: string;
  folderId?: string;
  toggleDialog: (show: boolean) => void;
  isRenameFolderShown: boolean;
  renameFolder: (projectId: string, folderId: string, data: FormResponseType) => void;
  errors?: FormErrorType;
};

export default function ProposalRenameDialog(props: Props): JSX.Element | null {
  const { folder, isRenaming, projectId, folderId, toggleDialog, isRenameFolderShown, renameFolder, errors } = props;

  if (!isRenameFolderShown || !folderId) return null;
  const { folder_name: folderName } = folder;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.PROPOSAL_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleDialog(false)}
    >
      <Form onSubmit={(response) => renameFolder(projectId, folderId, response)} errors={errors}>
        <FormGroup>
          <FormLabel>Name</FormLabel>
          <Input defaultValue={folderName} name="folder_name" autoFocus required />
        </FormGroup>
        <DialogFooter>
          <Button style={BUTTON_STYLES.LINK} onClick={() => toggleDialog(false)} type="button">
            Cancel
          </Button>
          <Button isLoading={isRenaming} style={BUTTON_STYLES.PRIMARY}>
            Save
          </Button>
        </DialogFooter>
      </Form>
    </Dialog>
  );
}
