import { connect } from 'react-redux';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import AddOccasionPanel from './AddOccasionPanel';
import { addOccasion } from '../OccasionsDataDuck';
import { hasActiveSubscriptionSelector } from '../../../settings/subscriptions/SubscriptionsDataDuck';

const PANEL_KEY = LOADINGS.OCCASIONS.SHOW_ADD_PANEL;
const COMMON_KEY = LOADINGS.OCCASIONS.ADD;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;

  return {
    eventId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    hasActiveSubscription: hasActiveSubscriptionSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOccasion: (eventId: string, data) => {
      dispatch(addOccasion(dispatch, eventId, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddOccasionPanel);
