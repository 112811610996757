import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_SIZES,
  DialogFooter,
  DialogTitle,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { EventTrackerService, FolderMetaService } from '@premagic/core';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  isFolderPublic: boolean;
  isLoading: boolean;
  projectId: string;
  folderId: string;
  updateFolderMeta: (projectId: string, folderId: string, formResponse) => void;
  hasShowInGuestGalleryFeature: boolean;
  show: boolean;
  showMarkAsPublicFolder: (show) => void;
};
export default function FolderMakeItPublicDialog(props: Props): JSX.Element | null {
  const {
    projectId,
    folderId,
    isLoading,
    updateFolderMeta,
    isFolderPublic,
    hasShowInGuestGalleryFeature,
    showMarkAsPublicFolder,
    show,
  } = props;

  function markFolderPublic(value: boolean) {
    updateFolderMeta(projectId, folderId, {
      data: {
        [FolderMetaService.FOLDER_META_TYPES.IS_FOLDER_PUBLIC]: value,
      },
    });

    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.MAKE_IT_PUBLIC, {
      value,
    });

    showMarkAsPublicFolder(false);
  }

  if (!show) return null;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.EVENTS_MORE_OPTIONS)}
      show={show}
      size={DIALOG_SIZES.AUTO}
      onClose={() => showMarkAsPublicFolder(false)}
    >
      {isFolderPublic ? (
        <div>
          <DialogTitle>Hide this folder from guest gallery?</DialogTitle>
          <Space vertical size={2} />
          <Text>
            <ul>
              <li>This will hide this folder from guest gallery.</li>
              <li>Your guests/attendees wont be able to view the folder and all the photos in it.</li>
              <li>
                Note: Guest will still be able to view <Text boldness={TEXT_BOLDNESS.BOLD}>their photo</Text> from the
                folder
              </li>
            </ul>
          </Text>
          <DialogFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => showMarkAsPublicFolder(false)}>
              Cancel
            </Button>
            <Button
              disabled={!hasShowInGuestGalleryFeature}
              disabledMessage={MESSAGES.SIGNATURE_FOLDER.SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY_UPGRADE}
              isLoading={isLoading}
              style={BUTTON_STYLES.PRIMARY}
              onClick={() => markFolderPublic(false)}
            >
              Hide
            </Button>
          </DialogFooter>
        </div>
      ) : (
        <div>
          <DialogTitle>Show this folder in guest gallery?</DialogTitle>
          <Space vertical size={2} />
          <Text>
            <ul>
              <li>This folder will be visible to your guest/attendee in their guest gallery</li>
              <li>Your guest/attendee will be able to view all the photo from this folder</li>
            </ul>
          </Text>
          <Space vertical />
          <DialogFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => showMarkAsPublicFolder(false)}>
              Cancel
            </Button>
            <Button
              disabled={!hasShowInGuestGalleryFeature}
              disabledMessage={MESSAGES.SIGNATURE_FOLDER.SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY_UPGRADE}
              isLoading={isLoading}
              style={BUTTON_STYLES.PRIMARY}
              onClick={() => markFolderPublic(true)}
            >
              Show in guest gallery
            </Button>
          </DialogFooter>
        </div>
      )}
    </Dialog>
  );
}
