import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { Schemas } from '@premagic/core';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { ErrorDuck, LoadingDuck } from '@premagic/common-ducks';

import { LOADINGS } from '../../../../common/Constants';
import { fetchStoreOffers, StoreOfferItemType } from './StoreOffersService';
import { pagesSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('STORE');

const setStoreOffersData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const getStoreOfferData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.STORE.FETCH;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

  fetchStoreOffers()
    .then((response) => {
      const normalizedData = normalize(response.results, Schemas.StoreOfferssSchema);
      dispatch(setStoreOffersData(dispatch, normalizedData.entities.storeOffers));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Store Offer: fetch failed', e);
    });
});

type StoreStateType = {
  items: IMap<string, StoreOfferItemType>;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setStoreOffersData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

const storeOffersDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.store.data.storeOffers as StoreStateType,
);

export const storeOffersItemSelector = createSelector(storeOffersDataSelector, (state) => state.items.toJSON());

export const storeOffersItemIdsSelector = createSelector(storeOffersDataSelector, (state) =>
  state.items
    .sort((a, b) => a.position - b.position)
    .keySeq()
    .toArray(),
);
