import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TextGroupValueColor,
} from '@premagic/myne';
import FontSelectorContainer from '../../../font-selector/FontSelectorContainer';
import HighlightDesignBackgroundColorSelectorContainer from '../highlight-gallery-design/HighlightDesignBackgroundColorSelectorContainer';
import HighlightDesignFontColorSelectorContainer from '../highlight-gallery-design/HighlightDesignFontColorSelectorContainer';
import HighlightDesignAccentColorSelectorContainer from '../highlight-gallery-design/HighlightDesignAccentColorSelectorContainer';

type GalleryDesignCustomizationSettingsProps = {
  handleFontChange: (value) => void;
  fontFamilyForSignatureAlbum?: string;
  hasHighlightCustomizationFeature: boolean;
  aiPickedDominantColors?: Array<Array<number>>;
  handleBackgroundColorChange: (value) => void;
  backgroundColorForSignatureAlbum: string;
  handleFontColorChange: (value) => void;
  fontColorForSignatureAlbum?: string;
  handleAccentColorChange: (value) => void;
  accentColorForSignatureAlbum?: string;
};

export default function GalleryDesignCustomizationSettings(props: GalleryDesignCustomizationSettingsProps) {
  const {
    handleFontChange,
    fontFamilyForSignatureAlbum,
    hasHighlightCustomizationFeature,
    aiPickedDominantColors,
    handleBackgroundColorChange,
    backgroundColorForSignatureAlbum,
    handleFontColorChange,
    fontColorForSignatureAlbum,
    handleAccentColorChange,
    accentColorForSignatureAlbum,
  } = props;

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  if (isAccordionOpen) {
    return (
      <Space size={4}>
        <Button onClick={() => setIsAccordionOpen(false)} style={BUTTON_STYLES.RESET} block>
          <Row>
            <Text color={COLOR_SHADES.DARKER} block>
              Customize Color & Font
            </Text>
            <Col size={null} rightAlighed>
              <Icon name="chevron_up" size={ICON_SIZES.SM} />
            </Col>
          </Row>
        </Button>
        <Space vertical size={2} />
        <FontSelectorContainer
          onChange={handleFontChange}
          defaultValue={fontFamilyForSignatureAlbum}
          disabled={!hasHighlightCustomizationFeature}
          name="font_family"
          nameSource="font_source"
        />
        <Space vertical size={4} />
        <HighlightDesignBackgroundColorSelectorContainer
          aiPickedDominantColors={aiPickedDominantColors}
          onChange={handleBackgroundColorChange}
          disabled={!hasHighlightCustomizationFeature}
          defaultBackgroundColor={backgroundColorForSignatureAlbum}
        />
        <Space vertical size={4} />
        <HighlightDesignFontColorSelectorContainer
          onChange={handleFontColorChange}
          disabled={!hasHighlightCustomizationFeature}
          defaultFontColor={fontColorForSignatureAlbum}
          backgroundColor={backgroundColorForSignatureAlbum}
        />
        <Space vertical size={4} />
        <HighlightDesignAccentColorSelectorContainer
          onChange={handleAccentColorChange}
          disabled={!hasHighlightCustomizationFeature}
          accentColor={accentColorForSignatureAlbum}
        />
      </Space>
    );
  }

  return (
    <Space size={4}>
      <Button onClick={() => setIsAccordionOpen(true)} style={BUTTON_STYLES.RESET} block>
        <div>
          <Row>
            <Text color={COLOR_SHADES.DARKER} block>
              Customize Color & Font
            </Text>
            <Col size={null} rightAlighed>
              <Icon name="chevron_down" size={ICON_SIZES.SM} />
            </Col>
          </Row>
          <Space vertical size={2} />
          <Row>
            <TextGroupValueColor value={backgroundColorForSignatureAlbum} />
            <Space size={2} />
            <TextGroupValueColor value={fontColorForSignatureAlbum || '#fff'} />
            <Space size={2} />
            <TextGroupValueColor value={accentColorForSignatureAlbum || '#047af0'} />
          </Row>
        </div>
      </Button>
    </Space>
  );
}
