import { connect } from 'react-redux';
import { ActionConstants, ProposalVariablesDataDuck, ProposalVariableService } from '@premagic/proposals';
import { LoadingDuck, WindowPanelDuck, ErrorDuck, ModalDuck } from '@premagic/common-ducks';
import EditProposalVariablePanel from './EditProposalVariablePanel';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL;
const COMMON_KEY = ActionConstants.KEYS.PROPOSAL_VARIABLES.ADD;

function mapStateToProps(state) {
  const variableIds = ProposalVariablesDataDuck.proposalsVariablesIdsSelectors(state);
  let idOpenForEdit;
  variableIds.forEach((id) => {
    const isOpen = WindowPanelDuck.isWindowPanelOpenSelector(
      ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL_WITH_ID(id),
    )(state);
    if (isOpen) {
      idOpenForEdit = id;
    }
  });
  return {
    idOpenForEdit,
    show: idOpenForEdit,
    modalOptions: ModalDuck.modalOptionsSelector<ProposalVariableService.PROPOSAL_VARIABLE_SCOPE>(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state),
    variableData: ProposalVariablesDataDuck.proposalsVariableItemsSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editVariable: (id, data) => {
      dispatch(ProposalVariablesDataDuck.saveProposalVariable(dispatch, id, data));
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(
          dispatch,
          ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL_WITH_ID(id),
          false,
        ),
      );
    },
    closePanel: (id) => {
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(
          dispatch,
          ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL_WITH_ID(id),
          false,
        ),
      );
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProposalVariablePanel);
