import { connect } from 'react-redux';
import {
  companyDataSelector,
  isCompanyInSubscriptionPlan,
  showPremagicIntroSelector,
} from '../settings/company/CompanyDataDuck';
import DashboardPage from './DashboardPage';
import { isMobileUp } from '../AppDuck';
import { toggleModalVisibility, isModalOpenSelector } from '../../../../common/ModalDuck';
import { MODALS } from '../../../../common/Constants';
import { isCompanyTypeNotPhotographerSelector } from '../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  return {
    isSubscriptionPlan: isCompanyInSubscriptionPlan(state),
    company: companyDataSelector(state),
    showIntro: showPremagicIntroSelector(state),
    isMobileUp: isMobileUp(state),
    isTourWelcomeModalOpen: isModalOpenSelector(MODALS.TOUR_WELCOME_MODAL)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleTour: (status: boolean) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.TOUR_MODAL, status));
    },
    toggleTourWelcomeModal: (status: boolean) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.TOUR_WELCOME_MODAL, status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
