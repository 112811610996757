import { EventAttendeesService, EventCustomFromFieldsService } from '@premagic/core';
import { Avatar, AVATAR_STYLES, Text } from '@premagic/myne';
import { ArrayUtils } from '@premagic/utils';
import React from 'react';

interface EventAttendeesNamesProps {
  attendees: Record<string, EventAttendeesService.EventAttendeeType>;
  attendeeIds?: Array<string>;
  avatarStyle?: boolean;
}

export function EventAttendeesNames(props: EventAttendeesNamesProps): JSX.Element {
  const { attendees, attendeeIds = [], avatarStyle = false } = props;
  if (attendeeIds.length === 0) return <Text muted>(blank)</Text>;
  if (avatarStyle)
    return (
      <div>
        {attendeeIds.map((id) => {
          const attendee = attendees[id] || {
            name: 'Unknown',
          };
          const { name, custom_fields: customFields } = attendee;
          const profilePic = customFields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]
            ?.dynamic_image_url as string;
          return (
            <Avatar
              style={AVATAR_STYLES.SECONDARY}
              user={{
                fullName: name,
                profilePic,
              }}
            />
          );
        })}
      </div>
    );
  const usersArray = attendeeIds.map((id) => attendees[id]?.name || 'Unknown');
  return <>{ArrayUtils.listify(usersArray)}</>;
}
