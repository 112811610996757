import ClassNames from 'classnames';
import React, { useMemo } from 'react';
import { INPUT_SIZES, Select } from '../Form';
import styles from './print.module.css';

export enum PRINT_SIZES {
  PHOTO_2X3 = '2x3',
  PHOTO_3X5 = '3x5',
  PHOTO_4X4 = '4x4',
  PHOTO_4X5 = '4x5',
  PHOTO_4X6 = '4x6',
  A6 = 'a6',
  PHOTO_5X7 = '5x7',
  PHOTO_8X10 = '8x10',
  A4 = 'a4',
}
const PRINT_SIZE_MAP: Record<
  PRINT_SIZES,
  {
    name: string;
    width: number; // in mm
    height: number;
  }
> = {
  [PRINT_SIZES.PHOTO_2X3]: {
    name: '2x3',
    width: 50.8,
    height: 76.2,
  },
  [PRINT_SIZES.PHOTO_3X5]: {
    name: '3x5',
    width: 76.2,
    height: 127,
  },
  [PRINT_SIZES.PHOTO_4X4]: {
    name: '4x4',
    width: 101.6,
    height: 101.6,
  },
  [PRINT_SIZES.PHOTO_4X5]: {
    name: '4x5',
    width: 101.6,
    height: 127,
  },
  [PRINT_SIZES.PHOTO_4X6]: {
    name: '4x6',
    width: 101.6,
    height: 152.4,
  },
  [PRINT_SIZES.A6]: {
    name: 'A6',
    width: 105,
    height: 148,
  },
  [PRINT_SIZES.PHOTO_5X7]: {
    name: '5x7',
    width: 127,
    height: 177.8,
  },
  [PRINT_SIZES.PHOTO_8X10]: {
    name: '8x10',
    width: 203.2,
    height: 254,
  },
  [PRINT_SIZES.A4]: {
    name: 'A4',
    width: 210,
    height: 297,
  },
};

type PrintOptionsSelectorProps = {
  defaultValue: PRINT_SIZES;
  onChange: (value: PRINT_SIZES) => void;
};

export function PrintOptionsSelector(props: PrintOptionsSelectorProps) {
  const { defaultValue, onChange } = props;
  const options = useMemo(
    () =>
      Object.values(PRINT_SIZES).map((size) => ({
        label: PRINT_SIZE_MAP[size].name,
        value: size,
        info: `${PRINT_SIZE_MAP[size].width}mm x ${PRINT_SIZE_MAP[size].height}mm`,
      })),
    [],
  );
  const selectedOption = options.find((option) => option.value === defaultValue);
  return (
    <Select
      size={INPUT_SIZES.SM}
      isMulti={false}
      name="size"
      options={options}
      onChange={(option) => onChange(option?.value as PRINT_SIZES)}
      value={selectedOption}
    />
  );
}
export function PrintPage(props: { size: PRINT_SIZES; children: React.ReactNode }) {
  const { size, children } = props;
  const { width, height } = PRINT_SIZE_MAP[size];
  return (
    <div
      className={ClassNames(styles['print-page'])}
      style={{
        ['--print-page-width' as string]: `${width}mm`,
        ['--print-page-height' as string]: `${height}mm`,
      }}
    >
      {children}
    </div>
  );
}
export function PageBreak() {
  return <div className={ClassNames(styles['print-page-break--after'])} />;
}

export function NoPageBreakInside() {
  return <div className={ClassNames(styles['print-no-page-break-inside'])} />;
}
