import React from 'react';
import { get } from 'lodash';

import {
  Button,
  BUTTON_ICON_STYLES,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemNotificationDot,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { ACTION_TYPE_INFO, PhotographerActionType } from './PhotographerActionService';

type Props = {
  id: string;
  action: PhotographerActionType;
  isLoading: boolean;
  markAsDone: (id: string) => void;
  dismissAction: (id: string) => void;
  folders: {
    [id: string]: {
      folder_name: string;
    };
  };
};

export default function PendingPhotographerActionItem(props: Props): JSX.Element {
  const { id, isLoading, folders, markAsDone, dismissAction, action } = props;
  const { type, folder_id: folderId } = action;
  const { title, primaryButtonText } = ACTION_TYPE_INFO[type];
  const { folder_name: folderName } = get(folders, folderId, { folder_name: '' });

  return (
    <ListItem>
      <ListItemNotificationDot />
      <Space size={1} />
      <Col>
        <Text>
          {title} on <Text color={COLOR_SHADES.DARKER}>{folderName}</Text>
        </Text>
      </Col>
      <Col rightAlighed size={null}>
        <Row vcenter inline>
          <Button style={BUTTON_STYLES.LINK} isLoading={isLoading} onClick={() => markAsDone(id)} size={BUTTON_SIZE.SM}>
            {primaryButtonText}
          </Button>
          <Space size={2} />
          <ButtonIcon
            style={BUTTON_ICON_STYLES.SECONDARY}
            size={BUTTON_ICONS_SIZES.SM}
            title="Dismiss"
            onClick={() => dismissAction(id)}
          >
            <Icon name="x" size={ICON_SIZES.SM} />
          </ButtonIcon>
        </Row>
      </Col>
    </ListItem>
  );
}
