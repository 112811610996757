import React from 'react';
import { Label, LABEL_STYLES } from '@premagic/myne';
import { EventsService } from '@premagic/core';

function getStatusLabelStyle(status: EventsService.EVENT_STATUS): LABEL_STYLES {
  const statusMap = {
    [EventsService.EVENT_STATUS.NEW]: LABEL_STYLES.WARNING,
    [EventsService.EVENT_STATUS.CONFIRMED]: LABEL_STYLES.SUCCESS,
    [EventsService.EVENT_STATUS.QUALIFIED]: LABEL_STYLES.PRIMARY,
    [EventsService.EVENT_STATUS.REJECTED]: LABEL_STYLES.DEFAULT,
  };
  return statusMap[status];
}

type Props = {
  status: EventsService.EVENT_STATUS | null;
};
export default function EventStatusLabel(props: Props): JSX.Element {
  const { status } = props;
  if (!status) return <Label style={LABEL_STYLES.DEFAULT}>All</Label>;
  return <Label style={getStatusLabelStyle(status)}>{status}</Label>;
}
