import { MathUtils } from '@premagic/utils';

export enum BACKGROUND_MUSICS_IDS {
  NONE = 0,
  AEROBIC = 1,
  ALL_OUR_LIFE = 2,
  DREAMING_BIG = 3,
  ROMANTIC_MOJA = 4,
  WEDDING_SPRING = 5,
  AMBER = 6,
  MOONRISE = 7,
  WALKING_IN_THE_SKY = 8,
}
const assetPath = 'https://asts.premagic.com/a/';
export function getServerPath(fileName: string): string {
  return `${assetPath}${fileName}`;
}

export type BackgroundMusicType = {
  label: string;
  id: BACKGROUND_MUSICS_IDS;
  serverPath: string;
};

export const BACKGROUND_MUSICS: Record<BACKGROUND_MUSICS_IDS, BackgroundMusicType> = {
  [BACKGROUND_MUSICS_IDS.NONE]: {
    label: 'No Music',
    id: BACKGROUND_MUSICS_IDS.NONE,
    serverPath: '',
  },
  [BACKGROUND_MUSICS_IDS.AEROBIC]: {
    label: 'Aerobic',
    id: BACKGROUND_MUSICS_IDS.AEROBIC,
    serverPath: getServerPath('mixkit-aerobic-fashion-327.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.ALL_OUR_LIFE]: {
    label: 'All our life',
    id: BACKGROUND_MUSICS_IDS.ALL_OUR_LIFE,
    serverPath: getServerPath('mixkit-all-our-life-582.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.DREAMING_BIG]: {
    label: 'Dreaming big',
    id: BACKGROUND_MUSICS_IDS.DREAMING_BIG,
    serverPath: getServerPath('mixkit-dreaming-big-31.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.ROMANTIC_MOJA]: {
    label: 'Romantic Moja',
    id: BACKGROUND_MUSICS_IDS.ROMANTIC_MOJA,
    serverPath: getServerPath('mixkit-romantic-659.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.WEDDING_SPRING]: {
    label: 'Wedding Spring',
    id: BACKGROUND_MUSICS_IDS.WEDDING_SPRING,
    serverPath: getServerPath('mixkit-wedding-01-657.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.AMBER]: {
    label: 'Amber',
    id: BACKGROUND_MUSICS_IDS.AMBER,
    serverPath: getServerPath('amber-vyen.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.MOONRISE]: {
    label: 'Moonrise',
    id: BACKGROUND_MUSICS_IDS.MOONRISE,
    serverPath: getServerPath('moonrise-reed-mathis.mp3'),
  },
  [BACKGROUND_MUSICS_IDS.WALKING_IN_THE_SKY]: {
    label: 'Walking in the Sky',
    id: BACKGROUND_MUSICS_IDS.WALKING_IN_THE_SKY,
    serverPath: getServerPath('walking-in-the-sky-nico-staf.mp3'),
  },
};

export function getRandomMusicId(): BACKGROUND_MUSICS_IDS {
  const totalItems = Object.keys(BACKGROUND_MUSICS).length;
  const randomId = MathUtils.getRandomNumber(1, totalItems);
  return randomId;
}
