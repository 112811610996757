import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { albumCreatorSelectedAlbumIdSelector, albumCreatorSelectedPageSelector } from './AlbumCreatorDuck';
import AlbumCreatorLeftPane from './AlbumCreatorLeftPane';
import {
  activeCreatorPagesDataSelector,
  albumCreatorSelectedTemplateForActivePageSelector,
} from './page/AlbumPageDataDuck';

function mapStateToProps(state) {
  const pageNo = albumCreatorSelectedPageSelector(state);
  const albumId = albumCreatorSelectedAlbumIdSelector(state);
  return {
    pageNo,
    selectedTemplateName: albumCreatorSelectedTemplateForActivePageSelector(state),
    albumId,
    pageData: activeCreatorPagesDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePage: (pageUrl) => {
      dispatch(push(pageUrl));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumCreatorLeftPane);
