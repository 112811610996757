import { QRCodeBackgroundPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Color,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  COLOR_SHADES,
} from '@premagic/myne';
import React from 'react';
import DisplayedPosterLogoContainer from '../../displayed-logo/DisplayedPosterLogoContainer';

type CustomLogoPreviewAndSelectProps = {
  folderId: string;
  projectId: string;
  showSelectFileModal: (logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID) => void;
  logos?: Array<string | undefined>;
  logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
  title: string;
  updateLogo: (
    projectId: string,
    folderId: string,
    logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    customLogo1?: string,
    customLogo2?: string,
  ) => void;
  logoFileId?: string;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export function CustomLogoPreviewAndSelect(props: CustomLogoPreviewAndSelectProps) {
  const { showSelectFileModal, logoId, title, updateLogo, folderId, projectId, logos, logoFileId, qrCodeDesigns } =
    props;

  function handleRemove() {
    if (logoId === QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_1) {
      updateLogo(projectId, folderId, logoId, qrCodeDesigns, undefined, logos && logos[1]);
    } else {
      updateLogo(projectId, folderId, logoId, qrCodeDesigns, logos && logos[0], undefined);
    }
  }

  return (
    <Row columnDirection vcenter>
      <Card style={CARD_STYLES.PRIMARY} size={CARD_SIZES.DEFAULT} center>
        <Space size={2}>
          <div style={{ width: '60px', height: '60px' }}>
            {(() => {
              if (!logoFileId) {
                return (
                  <Row vcenter center fullHeight>
                    <Button
                      size={BUTTON_SIZE.SM}
                      onClick={() => showSelectFileModal(logoId)}
                      style={BUTTON_STYLES.RESET}
                    >
                      <Space>
                        <Color inline shade={COLOR_SHADES.LIGHT}>
                          <Icon name="image" size={ICON_SIZES.MD} />
                        </Color>
                      </Space>
                    </Button>
                  </Row>
                );
              }
              return (
                <DisplayedPosterLogoContainer
                  folderId={folderId}
                  logoId={logoId}
                  size="full-image"
                  logoOptionType={QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO}
                />
              );
            })()}
          </div>
        </Space>
      </Card>
      <Space vertical size={1} />
      <Row vcenter center={!!logoFileId} spaceBetween={!logoFileId}>
        <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={() => showSelectFileModal(logoId)}>
          {!logoFileId ? `Select ${title}` : 'Edit'}
        </Button>
        {logoFileId && (
          <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleRemove}>
            Remove
          </Button>
        )}
      </Row>
    </Row>
  );
}
