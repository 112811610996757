import { connect } from 'react-redux';
import EventPosterCreatorPreview from './EventPosterCreatorPreview';
import {
  deleteEventPosterWidget,
  duplicateEventPosterWidget,
  eventPostersSelector,
  updateEventPosterWidget,
} from '../crm/event-posters/EventPostersDataDuck';
import { filesSelector } from '../images/FilesDataDuck';
import {
  contextMenuPositionSelector,
  selectedDummyUserSelector,
  selectedEventPosterWidgetSelector,
  selectedPunchHoleStyleSelector,
  setSelectedPosterWidget,
  setShowContextMenu,
  viewOptionsSelector,
} from './EventPosterCreatorDuck';

function mapStateToProps(state, ownProps: { projectId: string; posterId?: string }) {
  const { posterId, projectId } = ownProps;
  const poster = posterId ? eventPostersSelector(state)[posterId] : undefined;
  // const defaultPosterForUpdatingTheValues = poster
  //   ? Object.assign({}, poster, {
  //       widgets: EventPosterService.getDefaultPosterWidgetsData({
  //         posterSize: poster.size,
  //         textColor: '#888',
  //         type: poster.type,
  //       }),
  //     })
  //   : undefined;
  return {
    poster,
    projectId,
    // poster: defaultPosterForUpdatingTheValues,
    files: filesSelector(state),
    selectedEventPosterWidgetId: selectedEventPosterWidgetSelector(state),
    punchHoleStyle: selectedPunchHoleStyleSelector(state),
    user: selectedDummyUserSelector(state),
    contextMenuPosition: contextMenuPositionSelector(state),
    viewOptions: viewOptionsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedWidget: (widgetId) => dispatch(setSelectedPosterWidget(dispatch, widgetId)),
    setShowContextMenu: (position) => dispatch(setShowContextMenu(dispatch, position)),
    onDuplicateWidget: ({ projectId, poster, widgetId }) => {
      dispatch(
        duplicateEventPosterWidget(dispatch, projectId, {
          poster,
          widgetId,
        }),
      );
    },
    onDeleteWidget: ({ projectId, poster, widgetId }) => {
      dispatch(
        deleteEventPosterWidget(dispatch, projectId, {
          poster,
          widgetId,
        }),
      );
    },
    editWidget: ({ projectId, poster, widgetToUpdate }) => {
      dispatch(
        updateEventPosterWidget(dispatch, projectId, {
          widgetToUpdate,
          poster,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterCreatorPreview);
