import { connect } from 'react-redux';
import { get } from 'lodash';
import { DeviceDuck } from '@premagic/common-ducks';

import { albumCreatorPagesDataSelector, totalNumberOfPagesInCreatorSelector } from '../page/AlbumPageDataDuck';
import AlbumSinglePage from './AlbumSinglePage';

function mapStateToProps(state, ownProps) {
  const { pageNo } = ownProps;
  const pagesData = albumCreatorPagesDataSelector(state);
  const templateId = get(pagesData, `[${pageNo}].template_name`, 1) as number;

  return {
    templateId,
    pageNo,
    isMobileUp: DeviceDuck.isMobileUp(state),
    totalPages: totalNumberOfPagesInCreatorSelector(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumSinglePage);
