import { connect } from 'react-redux';
import { AuthDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { requestingUserSelector } from '../crm/users/UsersDataDuck';
import UserProfileDropdown from './UserProfileDropdown';
import { accountSelector } from '../acccount/AccountDataDuck';
import { companyLogoSelector } from '../settings/company/CompanyDataDuck';

function mapStateToProps(state) {
  return {
    user: requestingUserSelector(state),
    logo: companyLogoSelector(state),
    activeAccount: accountSelector(state),
    isLoadingAuth: isLoadingSelector(LOADINGS.AUTH.LOGOUT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => dispatch(AuthDuck.logoutUser(dispatch)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDropdown);
