import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  ICON_SIZES,
  Icon,
  LoadingDots,
  Space,
} from '@premagic/myne';
import { useEffect } from 'react';
import FolderWatermarkListContainer from './folder-watermak/FolderWatermarkListContainer';

type Props = {
  showWatermarkPanel: () => void;
  fetchFolderMeta: (projectId: string, folderId: string) => void;
  projectId: string;
  sponsorFolderId: string;
  eventId: string;
  isUpdating: boolean;
  isLoadingSponsor: boolean;
};

export default function SponsorCreativesWatermarksList(props: Props) {
  const { showWatermarkPanel, fetchFolderMeta, projectId, sponsorFolderId, eventId, isUpdating, isLoadingSponsor } =
    props;

  useEffect(() => {
    fetchFolderMeta(projectId, sponsorFolderId);
  }, [projectId, sponsorFolderId, fetchFolderMeta]);

  if (isLoadingSponsor) return null;
  return (
    <Card accordion>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Folder Watermark settings</CardHeaderTitle>
          {isUpdating && (
            <>
              <Space size={2} />
              <LoadingDots size="sm" />
            </>
          )}
        </CardHeader>
        <CardContent>
          <FolderWatermarkListContainer projectId={projectId} eventId={eventId} sponsorFolderId={sponsorFolderId} />
          <Space vertical />
          <Button style={BUTTON_STYLES.PRIMARY} onClick={showWatermarkPanel} size={BUTTON_SIZE.SM}>
            <Icon name="plus" size={ICON_SIZES.SM} /> Add watermark
          </Button>
        </CardContent>
      </Space>
    </Card>
  );
}
