import { getCurrencyInMicroAmount } from './CurrencyUtils';

const COMMISSION = 0.5; // Premagic commision: 50% after a minimum set price

function getMinimumPriceForCommission(currencyCode: string) {
  const PRICE_CURRENCY_MAP = {
    USD: getCurrencyInMicroAmount(1),
    CAD: getCurrencyInMicroAmount(1),
    EUR: getCurrencyInMicroAmount(1),
    AED: getCurrencyInMicroAmount(1),
    AUD: getCurrencyInMicroAmount(1),
    INR: getCurrencyInMicroAmount(25),
    JPY: getCurrencyInMicroAmount(41),
  };

  return PRICE_CURRENCY_MAP[currencyCode];
}

// All in micro amounts
export function getTotalCommission(priceOfProduct: number, currencyCode = 'INR') {
  return priceOfProduct < getMinimumPriceForCommission(currencyCode)
    ? 0
    : (priceOfProduct - getMinimumPriceForCommission(currencyCode)) * COMMISSION;
}

// All in micro amounts
export function getTotalMarginForProduct(priceOfProduct: number, productCost = 0, currencyCode = 'INR') {
  return priceOfProduct - productCost - getTotalCommission(priceOfProduct, currencyCode);
}
