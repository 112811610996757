import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils, AuthUtils, BrowserUrlUtils } from '@premagic/utils';
import { ACTION_KEYS } from './Constants';
import { clearErrorState, setErrorState } from './ErrorDuck';
import { toggleLoadingState } from './LoadingDuck';
import { commonUISelector } from '../selectors';

interface FormErrorType {
  [inputName: string]: string;
}

interface FormResponseType {
  data: {
    [inputName: string]: string | boolean;
  };
  error?: FormErrorType;
}

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_APP', true);
export const updateAuthState = createAction(
  getActionType('AUTH', 'UPDATE'),
  (
    // @ts-ignore
    dispatch: Dispatch,
    authState: string | 'signedIn' | null,
    authData: {
      signInUserSession: { accessToken: { jwtToken: string } };
    } | null,
  ) => {
    if (authData?.signInUserSession)
      AuthUtils.setAuthTokenOnCacheFor3rdPartyLibs(authData.signInUserSession.accessToken?.jwtToken);

    return {
      authState,
      authData,
    };
  },
);

export const clearAuthState = createAction(getActionType('AUTH', 'CLEAR'), (dispatch) => {
  AuthUtils.clearAuthTokenOnCacheFor3rdPartyLibs();
  dispatch(updateAuthState(dispatch, null, null));
});

export const initAuth = createAction(getActionType('AUTH', 'CLEAR'), (dispatch) => {
  const ACTION_KEY = ACTION_KEYS.AUTH.LOGIN;
  dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
  AuthUtils.getAuthUser()
    .then((user) => {
      dispatch(updateAuthState(dispatch, 'signedIn', user));
      dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
    })
    .catch(() => {
      dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
    });
  dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
  AuthUtils.clearAuthTokenOnCacheFor3rdPartyLibs();
  dispatch(updateAuthState(dispatch, null, null));
});

export const loginUser = createAction(
  getActionType('AUTH', 'LOGIN'),
  (
    dispatch,
    formData: FormResponseType & {
      data: {
        email: string;
        password: string;
      };
    },
  ) => {
    const ACTION_KEY = ACTION_KEYS.AUTH.LOGIN;

    dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
    dispatch(clearErrorState(dispatch, ACTION_KEY));
    const { data } = formData;
    AuthUtils.signIn(data.email, data.password)
      .then((user) => {
        if (user.challengeName) {
          dispatch(updateAuthState(dispatch, user.challengeName, user));
        } else {
          dispatch(updateAuthState(dispatch, 'signedIn', user));
        }
        dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, ACTION_KEY, e));
        dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
        ErrorTracker.logError('AUTH: Login', e);
      });
  },
);

export const tokenRefresh = createAction(getActionType('AUTH_TOKEN', 'UPDATE'), (dispatch, data) => {
  ErrorTracker.logAction('AUTH: token updated', {});

  if (data) AuthUtils.setAuthTokenOnCacheFor3rdPartyLibs(data.signInUserSession?.accessToken?.jwtToken);
});

export const logoutUser = createAction(getActionType('AUTH', 'LOGOUT'), (dispatch) => {
  const ACTION_KEY = ACTION_KEYS.AUTH.LOGOUT;

  dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
  AuthUtils.signOut()
    .then((data) => {
      dispatch(clearAuthState(dispatch));
      dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
      BrowserUrlUtils.reload();
      return data;
    })
    .catch((error) => {
      dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
      ErrorTracker.logError('error signing out: ', error);
    });
});

export const requestForResetPassword = createAction(
  getActionType('AUTH', 'REQUEST_RESET'),
  (dispatch, response: FormResponseType) => {
    const ACTION_KEY = ACTION_KEYS.AUTH.RESET_PASSWORD;
    const { data } = response;
    dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
    dispatch(clearErrorState(dispatch, ACTION_KEY));
    AuthUtils.requestForForgotPassword(data.email as string)
      .then(() => {
        dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
      })
      .catch((error) => {
        dispatch(setErrorState(dispatch, ACTION_KEY, error));
        dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
        ErrorTracker.logError('AUTH: Request password', error);
      });
  },
);
export const setPassword = createAction(
  getActionType('AUTH', 'SET_PASSWORD'),
  (
    dispatch,
    response: FormResponseType & {
      data: {
        email: string;
        password: string;
        newPassword: string;
      };
    },
  ) => {
    const ACTION_KEY = ACTION_KEYS.AUTH.LOGIN;
    const { data } = response;
    dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
    dispatch(clearErrorState(dispatch, ACTION_KEY));

    AuthUtils.signIn(data.email, data.password).then((user) => {
      AuthUtils.changeNewPassword(user, data.newPassword)
        .then((newUser) => {
          dispatch(updateAuthState(dispatch, 'signedIn', newUser));
          dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
        })
        .catch((error) => {
          dispatch(setErrorState(dispatch, ACTION_KEY, error));
          dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
          ErrorTracker.logError('AUTH: new password set', error);
        });
    });
  },
);

export const changePassword = createAction(
  getActionType('AUTH', 'CHANGE_PASSWORD'),
  (
    dispatch,
    response: FormResponseType & {
      data: {
        oldPassword: string;
        newPassword: string;
      };
    },
    cb: () => void,
  ) => {
    const ACTION_KEY = ACTION_KEYS.AUTH.CHANGE_PASSWORD;
    const { data } = response;
    dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
    dispatch(clearErrorState(dispatch, ACTION_KEY));

    AuthUtils.getAuthUser().then((user) => {
      AuthUtils.changePassword(user, data.oldPassword, data.newPassword)
        .then((newUser) => {
          dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
          cb();
        })
        .catch((error) => {
          dispatch(setErrorState(dispatch, ACTION_KEY, error));
          dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
          ErrorTracker.logError('AUTH: change password set', error);
        });
    });
  },
);

export const resetPassword = createAction(
  getActionType('AUTH', 'RESET_PASSWORD'),
  (
    dispatch,
    response: FormResponseType & {
      data: {
        code: string;
        email: string;
        password: string;
      };
    },
  ) => {
    const ACTION_KEY = ACTION_KEYS.AUTH.RESET_PASSWORD;
    const { data } = response;
    dispatch(toggleLoadingState(dispatch, ACTION_KEY, true));
    dispatch(clearErrorState(dispatch, ACTION_KEY));

    AuthUtils.setNewPasswordFromForgotPassword(data.email, data.code, data.password)
      .then(() => {
        dispatch(initAuth(dispatch));
        dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
        BrowserUrlUtils.reload();
      })
      .catch((error) => {
        dispatch(setErrorState(dispatch, ACTION_KEY, error));
        dispatch(toggleLoadingState(dispatch, ACTION_KEY, false));
        ErrorTracker.logError('AUTH: Change password', error);
      });
  },
);

const initialState = {
  auth: {},
};

export const AuthDuckReducer = handleActions(
  {
    [updateAuthState.toString()]: (state, action) => ({
      ...state,
      auth: action.payload,
    }),
  },
  initialState,
);

export const authSelector = createSelector(commonUISelector, (state: any) => state.auth);
export const authDataSelector = createSelector(authSelector, (state: any) => state.auth);
export const authStateSelector = createSelector(authDataSelector, (auth: any) => auth.authState);
export const isUserLoggedInSelector = createSelector(authStateSelector, (state) => state === 'signedIn');
