import React from 'react';
import {
  InnerPageHeader,
  InnerPage,
  InnerPageContent,
  InnerPageSubHeader,
  Tabs,
  TabItem,
  InnerPageContentMainCol,
} from '@premagic/myne';
import { Route, Switch } from 'react-router-dom';
import APP_URLS from '../../../services/AppRouteURLService';
import AccountBasicSettingsContainer from './AccountBasicSettingsContainer';
import AccountServicesOfferedSettingsContainer from './AccountServicesOfferedSettingsContainer';

type Props = {
  currentUrlPathname: string;
};

export default function AccountSettingPage(props: Props) {
  const { currentUrlPathname } = props;
  const ACCOUNT_URLS = APP_URLS.SETTINGS.ACCOUNT;
  return (
    <InnerPage>
      <InnerPageHeader iconName="archive" title="Account setting" backTo={APP_URLS.SETTINGS.INDEX}>
        Account setting
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem to={ACCOUNT_URLS.BASIC} active={currentUrlPathname.includes(ACCOUNT_URLS.BASIC)}>
            Basic
          </TabItem>
          <TabItem to={ACCOUNT_URLS.SERVICES} active={currentUrlPathname.includes(ACCOUNT_URLS.SERVICES)}>
            Services offered
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
      <InnerPageContent hasInnerCols>
        <InnerPageContentMainCol>
          <Switch>
            <Route path={ACCOUNT_URLS.BASIC} component={AccountBasicSettingsContainer} />
            <Route path={ACCOUNT_URLS.SERVICES} component={AccountServicesOfferedSettingsContainer} />
          </Switch>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
