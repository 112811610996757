import { QRCodeBackgroundPosterService } from '@premagic/core';
import { COLOR_SHADES, Col, Row, Space, TEXT_BOLDNESS, TEXT_SIZE, Text, ToggleSwitch } from '@premagic/myne';

type Props = {
  isLoading?: boolean;
  showPoweredByPremagic: boolean;
  projectId: string;
  folderId: string;
  togglePoweredByPremagic: (projectId: string, folderId: string, qrCodeDesigns, value: boolean) => void;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodePosterPoweredByPremagicToggle(props: Props) {
  const { isLoading, showPoweredByPremagic, projectId, folderId, qrCodeDesigns, togglePoweredByPremagic } = props;

  function handleToggleChange(value) {
    togglePoweredByPremagic(projectId, folderId, qrCodeDesigns, value);
  }

  return (
    <Row>
      <div>
        <Row vcenter>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Show "Powered by Premagic"</Text>
        </Row>
      </div>
      <Space size={4} />
      <Col size={2} rightAlighed>
        <Row vcenter columnDirection>
          <ToggleSwitch
            isLoading={isLoading}
            name="show_powered_by_premagic"
            checked={showPoweredByPremagic}
            onChange={handleToggleChange}
          />

          <Space size={1} vertical />
          {showPoweredByPremagic ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
              Yes
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
              No
            </Text>
          )}
        </Row>
      </Col>
    </Row>
  );
}
