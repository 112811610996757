export const RESOURCES = {
  publicLogo: 'https://premagic.com/icons/icon-144x144.png',
  helpArticles: 'https://premagic.freshdesk.com/support/solutions/44000269331',
  faq: (src) => `https://premagic.com/faq/?utm_source=inside-premagic&utm_medium=${src}`,
  bookDemo: (src) => `https://calendly.com/premagic-demo/product-demo/?utm_source=inside-premagic&utm_medium=${src}`,
  supportEmail: 'support@premagic.com',
  supportWhatsapp: '+91 6360-491-954',
  supportPhones: [
    {
      title: '+91 8106-176-986',
      value: '+918106176986',
    },
    {
      title: '+91 9605-547-547',
      value: '+919605547547',
    },
  ],
  notificationNumber: {
    title: '+91 95676 20333',
    value: '+919567620333',
  },
  website: (src) => `https://premagic.com?utm_source=inside-premagic&utm_medium=${src}`,
  privacy: (src) => `https://blog.premagic.com/privacy/?utm_source=inside-premagic&utm_medium=${src}`,
  terms: (src) => `https://blog.premagic.com/terms/?utm_source=inside-premagic&utm_medium=${src}`,
  download: (src) => `https://premagic.com/download/?utm_source=inside-premagic&utm_medium=${src}`,
  referralLink: (code: string) =>
    `https://studio.premagic.com/signup/?utm_source=inside-premagic&referral_code=${code}`,
  signatureUpSell: (shareId: string, daysRemaining: number, source = 'signature-album') =>
    `https://studio.premagic.com/buy/signature/?utm_source=${source}&utm_medium=${shareId}&utm_content=under-${daysRemaining}`,
  selectionExtend: (shareId: string, daysRemaining: number, source = 'selection-album') =>
    `https://studio.premagic.com/buy/selection/?utm_source=${source}&utm_medium=${shareId}&utm_content=under-${daysRemaining}`,
  writeAReview: 'https://reviews.capterra.com/new/236853',

  // watermark stock image source: (Premagic acc id: insales@premagic.com, pass:wellbeing,  project-name: stock images (do not delete))
  watermarkPreviewStockImage: [
    {
      name: 'NeoConcourse',
      image: "https://asts.premagic.com/img_-npIBysbsIc-kf6fUqeaS_A-b'd2F0ZXJtYXJrLXByZXZpZXctcGhvdG8x'.jpg",
    },
    {
      name: 'MegaStage',
      image: "https://asts.premagic.com/img_-npIBysbsIc-kf6fUqeaS_A-b'cHJvZHVjdC1zY2hvb2w='.jpg",
    },
    {
      name: 'Vista',
      image: "https://asts.premagic.com/img_-npIBysbsIc-kf6fUqeaS_A-b'YW50ZW5uYS0='.jpg",
    },
    {
      name: 'Spectra',
      image: "https://asts.premagic.com/img_-npIBysbsIc-kf6fUqeaS_A-b'YmFs'.jpg",
    },
    {
      name: 'Optima',
      image: "https://asts.premagic.com/img_-npIBysbsIc-kf6fUqeaS_A-b'bWF0dGhldw=='.jpg",
    },
  ] as Array<{ name: string; image: string }>,
};

export const DIGITAL_INVITE = {
  marketingPage: (src) => `https://studio.premagic.com/digital-invite/?utm_source=inside-premagic&utm_medium=${src}`,
  faq: 'https://premagic.freshdesk.com/support/solutions/folders/44001223006',
  helpArticles: 'https://premagic.freshdesk.com/support/solutions/44000812997',
  supportEmail: 'support@premagic.com',
  supportPhones: [
    {
      title: '+91 8106-176-986',
      value: '+918106176986',
    },
    {
      title: '+91 9605-547-547',
      value: '+919605547547',
    },
  ],
  privacy: (src) => `https://blog.premagic.com/privacy/?utm_source=inside-premagic&utm_medium=${src}`,
  terms: (src) => `https://blog.premagic.com/terms/?utm_source=inside-premagic&utm_medium=${src}`,
};

export const AI_DELIVERY = {
  marketingPage: (src) => `https://events.premagic.com/?utm_source=inside-premagic&utm_medium=${src}`,
};

export const MY_PREMAGIC = {
  signupPage: (key) => `https://my.premagic.com/signup?view=${key}`,
  oneDeliveryLink: (shareId, folderType) => `https://my.premagic.com/onedelivery/share/${shareId}/${folderType}`,
};
