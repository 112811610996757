import { Card, PillItem, Pills, PILLS_STYLES, Row, Space, Text } from '@premagic/myne';
import React, { useState } from 'react';
import EventAttendeeBadgeContainer from './EventAttendeeBadgeContainer';

type Props = {
  projectId: string;
  folderId: string;
  eventAttendeeIds?: Array<string>;
};

export default function EventAttendeeBadgeLayoutCenterComponent(props: Props) {
  const { projectId, folderId, eventAttendeeIds } = props;
  const [isDesignMode, setIsDesignMode] = useState(false);

  if (!eventAttendeeIds || eventAttendeeIds.length === 0) {
    return (
      <Space>
        <Card>
          <Space>
            <Text>No attendee is added.</Text>
          </Space>
        </Card>
      </Space>
    );
  }
  return (
    <Space size={6}>
      <Row columnDirection center>
        <Pills style={PILLS_STYLES.SECONDARY}>
          <PillItem active={isDesignMode} onClick={() => setIsDesignMode(true)}>
            Design
          </PillItem>
          <PillItem active={!isDesignMode} onClick={() => setIsDesignMode(false)}>
            Preview
          </PillItem>
        </Pills>
        <Space vertical />
        {isDesignMode ? (
          <EventAttendeeBadgeContainer projectId={projectId} folderId={folderId} attendeeId={eventAttendeeIds[0]} />
        ) : (
          eventAttendeeIds.map((id) => (
            <div key={id}>
              <EventAttendeeBadgeContainer projectId={projectId} folderId={folderId} attendeeId={id} />
              <Space vertical size={8} />
            </div>
          ))
        )}
      </Row>
    </Space>
  );
}
