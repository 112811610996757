import { FileService, GeneralImageEditorService, QRCodeBackgroundPosterService } from '@premagic/core';
import { Alert, ALERT_STYLES, ALERT_SIZES, Space } from '@premagic/myne';
import React, { useState } from 'react';
import GeneralImageEditorContainer from '../../../../general-image-editor/GeneralImageEditorContainer';

type Props = {
  projectId: string;
  folderId: string;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  updateIllustrationMeta: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    newDesignData?: QRCodeBackgroundPosterService.QRCodeDesignType,
  ) => void;
  illustrationFile?: FileService.FolderFileType;
  closeModal: () => void;
};

export default function QRCodeIllustrationImageEditor(props: Props) {
  const { closeModal, folderId, projectId, qrCodeDesigns, illustrationFile, updateIllustrationMeta } = props;

  const imgSrc = illustrationFile?.dynamic_image_url;
  const metaData = qrCodeDesigns?.custom_illustration_meta_data;

  const [cropUseAlert, setCropUseAlert] = useState(false);

  function handleImageMetaChange(val: GeneralImageEditorService.ImageEditorImageMetaDataType) {
    if (!val || !val.pixelCrop) {
      setCropUseAlert(true);
      return;
    }

    const newDesignData = {
      [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION_META_DATA]: val,
    };
    updateIllustrationMeta(projectId, folderId, qrCodeDesigns, newDesignData);
    closeModal();
  }

  function handleImageUpdate() {
    const newDesignData = {
      [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION]: undefined,
      [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION_META_DATA]: undefined,
    };

    updateIllustrationMeta(projectId, folderId, qrCodeDesigns, newDesignData);
  }

  function handleImageRemove() {
    handleImageUpdate();
    closeModal();
  }

  function handleImageChange() {
    handleImageUpdate();
  }

  return (
    <>
      {cropUseAlert && (
        <>
          <Space vertical size={1} />
          <Alert style={ALERT_STYLES.WARNING} size={ALERT_SIZES.SM}>
            Select a crop area
          </Alert>
          <Space vertical size={1} />
        </>
      )}
      <GeneralImageEditorContainer
        imageSrc={imgSrc || ''}
        onChange={handleImageMetaChange}
        handleRemovePhoto={handleImageRemove}
        handleChangePhoto={handleImageChange}
        defaultCrop={metaData?.pixelCrop}
        defaultScale={metaData?.scale}
        defaultRotate={metaData?.rotate}
      />
    </>
  );
}
