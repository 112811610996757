import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { filesSelector } from '../../../images/FilesDataDuck';
import { qrCodeDesignsLogoSelector, qrCodeDesignsSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { companyLogoSelector } from '../../../settings/company/CompanyDataDuck';
import { DisplayedPosterLogo } from './DisplayedPosterLogo';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    logoId?: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
    size: 'lg' | 'md' | 'sm' | 'full-image';
    align?: 'left' | 'right' | 'center';
    logoOptionType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
    customStyle?: boolean;
  },
) {
  const { folderId, logoId, size, logoOptionType, align, customStyle } = ownProps;
  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);

  let logoFileData;
  let logoMetaData;

  if (logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO && logoId) {
    const filesData = filesSelector(state);
    const logos = qrCodeDesignsLogoSelector(folderId)(state);
    const indexForLogo = QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_DETAILS[logoId].positionIndex;

    if (logos) {
      logoFileData = filesData[logos[indexForLogo] as string];
    }
  }

  if (qrCodeDesigns?.logo_meta_data && logoId) {
    logoMetaData = qrCodeDesigns?.logo_meta_data[logoId];
  }

  return {
    logoFileData,
    size,
    logoOptionType,
    companyLogo: companyLogoSelector(state),
    align,
    logoMetaData,
    customStyle,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayedPosterLogo);
