import React, { useRef, useState } from 'react';
import {
  Avatar,
  AVATAR_SIZES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  DialogTitle,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  ListItem,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  SimpleImage,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProductFeedbackService } from '@premagic/core';
import MESSAGES from '../../../../../../../common/Messages';

type MessageButtonIconProps = {
  message: string;
};

function MessageButtonIcon(props: MessageButtonIconProps) {
  const { message } = props;

  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const $button = useRef(null);

  const handleHover = () => {
    setShowMessageDialog(true);
  };

  const handleMouseLeave = () => {
    setShowMessageDialog(false);
  };

  if (!message) return null;

  return (
    <>
      <Button
        disabled={!message}
        ref={$button}
        style={BUTTON_STYLES.RESET}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        <Color shade={COLOR_SHADES.DARK} inline>
          <Icon name="message_square" size={ICON_SIZES.SM} />
        </Color>
      </Button>
      {showMessageDialog && (
        <Dialog
          target={$button.current}
          placement={DIALOG_PLACEMENT.RIGHT}
          show={showMessageDialog}
          onClose={() => setShowMessageDialog(false)}
        >
          <DialogTitle>Message</DialogTitle>
          <Text>{message}</Text>
        </Dialog>
      )}
    </>
  );
}

type UserAvatarProps = {
  src: string;
  username: string;
  email: string;
  phone: string;
};

function UserAvatar(props: UserAvatarProps) {
  const { src, email, phone, username } = props;

  const userImageAndDetailsComponent = (
    <Space size={2}>
      <Row vcenter center>
        {/* using simpleImage as imageV2 is showing issue with the selfie url */}
        <SimpleImage src={src} style={{ width: 230, height: 230, objectFit: 'cover' }} />
      </Row>
      <Space size={2}>
        <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
          {username}
        </Text>
        <Space vertical size={1} />
        <SimpleLink href={`tel:${phone}`} isExternalLink={false}>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER}>
              <Icon name="phone" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={COLOR_SHADES.DARKER}>{phone}</Text>
          </Row>
        </SimpleLink>
        <Space vertical size={1} />
        <SimpleLink href={`mailto:${email}`} isExternalLink={false}>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER}>
              <Icon name="mail" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={COLOR_SHADES.DARKER}>{email || '(blank)'}</Text>
          </Row>
        </SimpleLink>
      </Space>
    </Space>
  );
  return (
    <Avatar
      size={AVATAR_SIZES.LG}
      user={{
        fullName: username,
        profilePic: src,
      }}
      dialogContent={userImageAndDetailsComponent}
    />
  );
}

type Props = {
  projectId: string;
  reportIssueItem?: ProductFeedbackService.ProductFeedbackType;
  removeReportIssue: (projectId: string, feedbackId: string) => void;
  isLoading: boolean;
  updateReportIssue: (
    projectId: string,
    feedbackId: string,
    data: Partial<ProductFeedbackService.ProductFeedbackType>,
  ) => void;
};

export default function ReportIssueListItem(props: Props): JSX.Element | null {
  const { projectId, reportIssueItem, removeReportIssue, isLoading, updateReportIssue } = props;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const $button = useRef(null);

  if (!reportIssueItem) return null;

  const { id, meta, name: username, phone, email, status, source: guestGalleryLink, data } = reportIssueItem;

  function getMessage() {
    try {
      const parsedRecord = JSON.parse(data);
      return parsedRecord.message;
    } catch (error) {
      return null;
    }
  }

  const isClosed = status === ProductFeedbackService.PRODUCT_FEEDBACK_STATUS_TYPE.DONE;

  const selfieUrl = meta?.face_asset?.dynamic_image_url;

  function handleStatusChange() {
    if (isClosed) {
      updateReportIssue(projectId, id, { status: ProductFeedbackService.PRODUCT_FEEDBACK_STATUS_TYPE.OPEN });
    } else {
      updateReportIssue(projectId, id, { status: ProductFeedbackService.PRODUCT_FEEDBACK_STATUS_TYPE.DONE });
    }
  }

  return (
    <ListItem>
      <Col size={1}>
        <UserAvatar src={selfieUrl} email={email} phone={phone} username={username} />
      </Col>
      <Space size={2} />
      <Col size={2}>
        <Text
          size={TEXT_SIZE.SIZE_3}
          color={isClosed ? COLOR_SHADES.LIGHT : COLOR_SHADES.DARKER}
          boldness={TEXT_BOLDNESS.BOLD}
          block
        >
          {username}
        </Text>
      </Col>
      <Col size={2}>
        <Row vcenter>
          <Button
            link={guestGalleryLink}
            size={BUTTON_SIZE.SM}
            style={BUTTON_STYLES.LINK}
            showExternalLinkIcon
            isExternalLink
          >
            Gallery
          </Button>
          <Space size={8} />
          <MessageButtonIcon message={getMessage()} />
        </Row>
      </Col>
      <Space />

      <Col rightAlighed size={null}>
        <Row vcenter>
          {isClosed ? (
            <Col rightAlighed size={null}>
              <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.SEMI_BOLD} block>
                Resolved
              </Text>
            </Col>
          ) : (
            <Col rightAlighed size={null}>
              <Button
                style={BUTTON_STYLES.PRIMARY}
                size={BUTTON_SIZE.SM}
                onClick={handleStatusChange}
                isLoading={isLoading}
              >
                Mark as Resolved
              </Button>
            </Col>
          )}
          <Space size={2} />
          {isLoading ? (
            <LoadingDots size="sm" />
          ) : (
            <Dropdown>
              <div ref={$button}>
                <DropdownIcon title="More" tooltipPlacement="bottom">
                  <Icon name="more_vertical" />
                </DropdownIcon>
              </div>
              <Menu>
                <MenuItem onClick={handleStatusChange}>
                  <MenuItemIcon name={isClosed ? 'corner_up_left' : 'check'} />
                  {isClosed ? 'Mark as Unresolved' : 'Mark as Resolved'}
                </MenuItem>
                <MenuItem onClick={() => setShowDeleteDialog(true)}>
                  <MenuItemIcon name="trash" />
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          )}
          {showDeleteDialog && (
            <Dialog target={$button.current} show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
              <DialogTitle>Delete this Issue?</DialogTitle>
              <Text>Are you sure you want to delete this Issue?</Text>
              <Space vertical size={6} />
              <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
              <DialogFooter>
                <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDeleteDialog(false)}>
                  Cancel
                </Button>
                <Button
                  style={BUTTON_STYLES.DANGER}
                  onClick={() => {
                    setShowDeleteDialog(false);
                    removeReportIssue(projectId, id);
                  }}
                  isLoading={isLoading}
                >
                  Delete
                </Button>
              </DialogFooter>
            </Dialog>
          )}
        </Row>
      </Col>
    </ListItem>
  );
}
