import { AttendeeMemoriesService, EventTrackerService, Schemas } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ArrayUtils, ErrorTracker } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../common/WindowPanelDuck';
import { pagesSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('MEMORY_TEMPLATES');

const setMemoryTemplatesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const addMemoryTemplateData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, id, data) => ({
  [id]: data,
}));

const updateMemoryTemplateData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, id, data) => ({
  id,
  data,
}));

const removeMemoryTemplateData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const getMemoryTemplateForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.MEMORY_TEMPLATES.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    AttendeeMemoriesService.fetchMemoryTemplatesForProject(projectId)
      .then((response) => {
        if (!isEmpty(response)) {
          const normalizedData = normalize(response, Schemas.AttendeeMemoryTemplates);
          dispatch(setMemoryTemplatesData(dispatch, normalizedData.entities.templates));
        } else {
          dispatch(setMemoryTemplatesData(dispatch, {}));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setMemoryTemplatesData(dispatch, {}));
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('MEMORY_TEMPLATES_FETCH', e);
      });
  },
);

export const addMemoryTemplate = createAction(
  getActionType('ACTION', 'CREATE'),
  (
    dispatch,
    options: {
      projectId: string;
      eventId: string;
    },
    formResponse: FormResponseType & {
      data: AttendeeMemoriesService.NewMemoryTemplateType;
    },
  ) => {
    const loadingKey = LOADINGS.MEMORY_TEMPLATES.CREATE;
    const { data, errors } = formResponse;
    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, errors));
      return;
    }
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { projectId, eventId } = options;

    AttendeeMemoriesService.createMemoryTemplate(projectId, data)
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.ATTENDEE_MEMORY_TEMPLATES.CREATE);
        dispatch(addMemoryTemplateData(dispatch, response.id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_ADD_PANEL, false));

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('MEMORY_TEMPLATE_ADD', e);
      });
  },
);

export const updateMemoryTemplate = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; templateId: string },
    formResponse: FormResponseType & {
      data: AttendeeMemoriesService.NewMemoryTemplateType;
    },
  ) => {
    const loadingKey = LOADINGS.MEMORY_TEMPLATES.UPDATE;
    const { data, errors } = formResponse;
    if (errors) {
      dispatch(setErrorState(dispatch, loadingKey, errors));
      return;
    }
    const { projectId, templateId } = options;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    AttendeeMemoriesService.updateMemoryTemplate(projectId, templateId, data)
      .then((response) => {
        dispatch(updateMemoryTemplateData(dispatch, response.id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_EDIT_PANEL, false));

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_MEMORY_TEMPLATES', e);
      });
  },
);

export const removeMemoryTemplate = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, projectId: string, templateId: string) => {
    const loadingKey = LOADINGS.MEMORY_TEMPLATES.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    AttendeeMemoriesService.deleteMemoryTemplate(projectId, templateId)
      .then((response) => {
        dispatch(removeMemoryTemplateData(dispatch, templateId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.MEMORY_TEMPLATES.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_MEMORY_TEMPLATES', e);
      });
  },
);

type MemoryTemplateType = IMap<string, AttendeeMemoriesService.MemoryTemplateType>;
type StateType = {
  items: MemoryTemplateType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setMemoryTemplatesData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addMemoryTemplateData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateMemoryTemplateData.toString()]: (
      state,
      action: {
        payload: any;
      },
    ) => ({
      ...state,
      items: state.items.update(action.payload.id, (poster) => Object.assign({}, poster, action.payload.data)),
    }),
    [removeMemoryTemplateData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

const memoryTemplateDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.avatarStudio.data.memoryTemplates as StateType,
);

export const memoryTemplateEntitiesSelector = createSelector(memoryTemplateDataSelector, (posters) =>
  posters.items.sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.name, b.name)),
);

export const memoryTemplatesSelector = createSelector(memoryTemplateEntitiesSelector, (posters) => posters.toJSON());

export const memoryTemplatesIdsSelector = createSelector(memoryTemplateEntitiesSelector, (posters) =>
  posters.keySeq().toArray(),
);
