import { connect } from 'react-redux';
import AddEventFormFieldPanel from './AddEventFormFieldPanel';
import { LOADINGS } from '../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { addNewCustomEventFromField } from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

const PANEL_KEY = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_ADD_PANEL;
const COMMON_KEY = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.ADD;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    error: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addFormField: (projectId: string, data) => dispatch(addNewCustomEventFromField(dispatch, projectId, data)),
    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEventFormFieldPanel);
