import { BrowserUrlUtils } from '@premagic/utils';
import { goBack, push } from 'connected-react-router';
import { Dispatch } from 'redux';

export function navigateTo(dispatch: Dispatch, url: string, options: { append?: boolean } = {}) {
  const { append } = options;

  if (append) return dispatch(push(`${BrowserUrlUtils.getUrlPathname()}/${url}`));

  return dispatch(push(url));
}

export function navigateBack(dispatch: Dispatch, defaultURL: string): void {
  if (document.referrer !== '') {
    // Has browser back
    dispatch(goBack());
  } else {
    navigateTo(dispatch, defaultURL);
  }
}
