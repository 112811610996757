import { connect } from 'react-redux';

import { filesItemDataSelector } from '../../../../../images/FilesDataDuck';
import { eventAttendeeBadgeDesignsSelector } from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import { eventAttendeeDataByIdSelector } from '../../EventAttendeesDataDuck';
import EventAttendeeBadge from './EventAttendeeBadge';
import { selectedWidgetSelector, setSelectedWidget } from '../duck/EventAttendeeBadgeDesignDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string; attendeeId: string }) {
  const { folderId, attendeeId } = ownProps;
  const badgeDesign = eventAttendeeBadgeDesignsSelector(folderId)(state);

  const imageId = badgeDesign?.widget_data?.POSTER?.value;

  return {
    selectedBackgroundPoster: filesItemDataSelector(imageId)(state),
    posterWidgetDesign: badgeDesign?.widget_data,
    attendeeData: eventAttendeeDataByIdSelector(attendeeId)(state),
    selectedWidgetForEdit: selectedWidgetSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedWidget: (widgetId) => {
      dispatch(setSelectedWidget(dispatch, widgetId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadge);
