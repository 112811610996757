import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { FolderService, PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import {
  addFolderToProject,
  folderIdsWithFolderTypeForProjectSelector,
  projectsSelector,
  totalSharedFolderWithFolderTypeForProjectSelector,
} from '../../projects/AccountProjectsDataDuck';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
} from '../../projects/project-meta/ProjectsMetaDataDuck';
import AIPhotoDeliveryCard from './AIPhotoDeliveryCard';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_STATUS;
const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  const shareFolderIds = folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.HIGHLIGHT, {
    is_shared: true,
  })(state);

  return {
    project: projectsSelector(state)[projectId],
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    isStaffUser: isStaffUserSelector(state),
    hasAiDeliveryFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY)(state),
    totalHighlightFolderShared: totalSharedFolderWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.HIGHLIGHT,
    )(state),
    shareFolderIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (projectId, formResponse) => {
      dispatch(
        saveProjectMetaData(dispatch, projectId, formResponse, {
          itemId: META_ITEM,
        }),
      );
    },
    createFolder: (projectId) => {
      dispatch(
        addFolderToProject(
          dispatch,
          {
            data: {
              folder_name: 'Live photos',
            },
          },
          {
            projectId,
            folderType: FolderService.FOLDER_TYPES.HIGHLIGHT,
            navigateToFolder: false,
            autoPublish: true,
          },
        ),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AIPhotoDeliveryCard);
