import React, { useEffect } from 'react';

import { InnerPageContentMainCol, LoadingDots } from '@premagic/myne';

import ClientPaymentsContainer from '../../payments/client-payments/ClientPaymentsContainer';
import AddClientPaymentPanelContainer from '../../payments/client-payments/AddClientPaymentPanelContainer';
import EditClientPaymentPanelContainer from '../../payments/client-payments/EditClientPaymentPanelContainer';
import ExpensePaymentsContainer from '../../payments/expense-payments/ExpensePaymentsContainer';
import AddExpensePaymentPanelContainer from '../../payments/expense-payments/AddExpensePaymentPanelContainer';
import EditExpensePaymentPanelContainer from '../../payments/expense-payments/EditExpensePaymentPanelContainer';
import CRMPaymentItemDeleteDialogContainer from '../../payments/payment-item/CRMPaymentItemDeleteDialogContainer';
import PaymentSummaryContainer from '../../payments/PaymentSummaryContainer';
import ClientPaymentPresetModalContainer from '../../payments/client-payment-preset/ClientPaymentPresetModalContainer';
import { PERMISSIONS } from '../../users/UserPermissionService';

type Props = {
  eventId: string;
  initData: (eventId: string) => void;
  isLoading: boolean;
  hasPermissions: Partial<Record<PERMISSIONS, boolean>>;
};

export default function EventFinancialLayout(props: Props): React.ReactElement {
  const { eventId, initData, isLoading, hasPermissions } = props;
  useEffect(() => {
    initData(eventId);
  }, []);

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <InnerPageContentMainCol>
      {hasPermissions[PERMISSIONS.EVENT_FINANCIAL_QUOTE] && <PaymentSummaryContainer eventId={eventId} />}
      {hasPermissions[PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS] && <ClientPaymentsContainer eventId={eventId} />}
      {hasPermissions[PERMISSIONS.EVENT_FINANCIAL_EXPENSE] && <ExpensePaymentsContainer />}
      <AddClientPaymentPanelContainer eventId={eventId} />
      <EditClientPaymentPanelContainer eventId={eventId} />
      <AddExpensePaymentPanelContainer eventId={eventId} />
      <EditExpensePaymentPanelContainer eventId={eventId} />
      <CRMPaymentItemDeleteDialogContainer eventId={eventId} />
      <ClientPaymentPresetModalContainer eventId={eventId} />
    </InnerPageContentMainCol>
  );
}
