import React from 'react';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  List,
  ListItem,
  Loading,
  Row,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Space,
  InnerPageContentMainCol,
} from '@premagic/myne';

import CompanyBrandingHeaderContainer from './CompanyBrandingHeaderContainer';
import CompanyBrandingListContainer from './CompanyBrandingListContainer';
import CompanyTypeSelectorContainer from './CompanyTypeSelectorContainer';

type Props = {
  isLoading: boolean;
  currentUrlPathname: string;
  brandingSettings: {
    show_company_logo_in_email: boolean;
    show_powered_by_premagic: boolean;
    show_enquire_form_in_highlight: boolean;
  };
  savePreferences: (data) => void;
  isPoweredByPremagicShown: boolean;
  showBranding: boolean;
};

export default function CompanyBrandingPage(props: Props) {
  const { isLoading, savePreferences, isPoweredByPremagicShown, showBranding } = props;
  if (isLoading) {
    return <Loading />;
  }

  const { currentUrlPathname, brandingSettings } = props;

  const BRANDING_OPTIONS = [
    {
      title: 'Show company logo in email send from Premagic',
      key: 'show_company_logo_in_email',
      enabled: false,
      value: true,
    },
    {
      title: 'Show "Powered By Premagic" in Signature album & Selection album',
      key: 'show_powered_by_premagic',
      enabled: brandingSettings.show_powered_by_premagic,
      value: isPoweredByPremagicShown,
    },
  ];

  return (
    <InnerPage>
      <CompanyBrandingHeaderContainer currentUrlPathname={currentUrlPathname} />
      <InnerPageContent hasInnerCols>
        <InnerPageContentMainCol>
          <Card>
            <List>
              {BRANDING_OPTIONS.map((option) => (
                <ListItem key={option.key}>
                  <Col size={10}>
                    <Text color={COLOR_SHADES.DARKER}>{option.title}</Text>
                  </Col>
                  <Col size={null} rightAlighed>
                    <Row vcenter>
                      <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                        {option.value ? 'Yes' : 'No'}
                      </Text>
                      <Space size={2} />
                      <ToggleSwitch
                        name={option.key}
                        disabled={!option.enabled}
                        checked={option.value}
                        onChange={(value) => {
                          savePreferences({ data: { [option.key]: value } });
                        }}
                      />
                    </Row>
                  </Col>
                </ListItem>
              ))}
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER}>Company type</Text>
                </Col>
                <Col size={null} rightAlighed>
                  <CompanyTypeSelectorContainer />
                </Col>
              </ListItem>
            </List>
          </Card>
          <Space vertical size={8} />
          {showBranding && <CompanyBrandingListContainer />}
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
