import React from 'react';

import { Button, BUTTON_STYLES, Icon, ICON_SIZES, Space } from '@premagic/myne';
import { EventTrackerService, FolderService, ProjectService } from '@premagic/core';
import { ProposalDeckService, ProposalService } from '@premagic/proposals';
import ProposalSharePanelContainer from './ProposalSharePanelContainer';

type Props = {
  projectId: string;
  folderId: string;
  proposal: ProposalService.ProposalType;
  showSharePanel: () => void;
  deck?: ProposalDeckService.ProposalDeckType;
};

export default function ProposalFolderShareButton(props: Props): React.ReactElement | null {
  const { projectId, folderId, showSharePanel, proposal, deck } = props;
  const { share_url: shareUrl, event_id: eventId, status, is_shared: isShared } = proposal;
  const previewUrl = shareUrl;
  const showPreviewLink = shareUrl && deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.DRAFT;

  if (projectId === ProjectService.SystemProject.id) {
    return <div>This should not happen</div>;
  }

  if (deck?.status !== ProposalDeckService.PROPOSAL_DECK_STATUS.DRAFT) {
    return (
      <Button style={BUTTON_STYLES.CANCEL} isExternalLink link={shareUrl}>
        <Icon name="external_link" size={ICON_SIZES.SM} />
        <Space size={2} />
        Open
      </Button>
    );
  }

  return (
    <>
      {showPreviewLink && (
        <>
          <Button
            style={BUTTON_STYLES.CANCEL}
            link={previewUrl}
            isExternalLink
            onClick={() => EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.PREVIEW)}
          >
            Preview
            <Space size={2} />
            <Icon name="external_link" size={ICON_SIZES.SM} />
          </Button>
          <Space />
        </>
      )}
      <Button style={BUTTON_STYLES.PRIMARY} onClick={showSharePanel}>
        Share
        <Space size={2} />
        <Icon name="send" size={ICON_SIZES.SM} />
      </Button>
      <ProposalSharePanelContainer projectId={projectId} folderId={folderId} />
    </>
  );
}
