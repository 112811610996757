import { connect } from 'react-redux';
import { isMobileUp } from '../../../AppDuck';
import EventsListEventItem from './EventsListEventItem';
import { eventsListItemsSelector } from './EventsListEventsDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureEventsOccasionsEnabledSelector,
  isFeatureLeadEnabledSelector,
} from '../../../settings/preferences/ClientSettingsDataDuck';
import { hasPermission } from '../../users/UsersDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';

function mapStateToProps(state, ownProps: { id: string }) {
  const { id } = ownProps;
  const event = eventsListItemsSelector(state)[id];

  return {
    eventId: id,
    event,
    isMobileUp: isMobileUp(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    hasEventDetailsPermission: hasPermission(PERMISSIONS.EVENT_DETAILS)(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    isFeatureEventsOccasionsEnabled: isFeatureEventsOccasionsEnabledSelector(state),
  };
}

export default connect(mapStateToProps)(EventsListEventItem);
