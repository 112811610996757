import { connect } from 'react-redux';
import { ClientsService } from '@premagic/core';
import CRMClientSearchItem from './CRMClientSearchItem';
import { routeUserToFirstEvent } from './ClientsDataDuck';

function mapStateToProps(state, ownProps: { client: ClientsService.ClientType }) {
  const { client } = ownProps;
  return {
    client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    routeUserToFirstEvent: (clientId) => {
      dispatch(routeUserToFirstEvent(dispatch, clientId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMClientSearchItem);
