import {
  FolderFilePositionOrderService,
  FolderMetaService,
  FolderService,
  FontPaletteService,
  FontService,
  QRCodeBackgroundPosterService,
  SponsorCreativesService,
} from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { EntitiesStateType, entitiesDataSelector } from '../../../reducers/selectors';
import {
  EventAttendeeBadgeDesignType,
  WIDGET_TYPE,
  WidgetDataType,
  defaultBadgeWidgetConfig,
} from '../../crm/events/event-attendees/event-attendee-badges/service/EventAttendeeBadgeDesignService';
import {
  sponsorCreativesIdsByPlacementSelector,
  sponsorCreativesSelector,
} from '../../crm/events/event-sponsor-creatives/SponsorCreativeDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('FOLDER_META');

export const setFoldersMetaData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);

export const setFolderMetaData = createAction(getActionType('DATA', 'SET'), (dispatch, folderId, data) => ({
  [folderId]: data,
}));

const updateFolderMetaData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, folderId, data) => ({
  folderId,
  data,
}));

const updateQRCodeDesigns = createAction(getActionType('QR_CODE_DESIGN', 'UPDATE'), (dispatch, folderId, data) => ({
  folderId,
  data,
}));

const updateIAmAttendingPosterDesigns = createAction(
  getActionType('I_AM_ATTENDING_DESIGN', 'UPDATE'),
  (dispatch, folderId, data) => ({
    folderId,
    data,
  }),
);

const updateEventAttendeeBadgeDesigns = createAction(
  getActionType('EVENT_ATTENDEE_BADGE_DESIGN', 'UPDATE'),
  (dispatch, folderId, data) => ({
    folderId,
    data,
  }),
);

function loadFolderMetaFonts(meta: FolderMetaService.FolderMetaType) {
  const fontsToLoad = [
    meta[FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT],
    meta[FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT],
  ];
  if (fontsToLoad.filter((font) => !!font).length) FontService.loadFonts(fontsToLoad);
}

export const getFolderMetaData = createAction(
  getActionType('DATA', 'FETCH'),
  (
    dispatch,
    projectId: string,
    folderId: string,
    options: {
      loadFonts?: boolean;
    },
  ) => {
    const loadingKey = LOADINGS.FOLDER_META.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderMetaService.fetchFolderMeta(projectId, folderId)
      .then((response) => {
        dispatch(setFolderMetaData(dispatch, folderId, response));
        if (options?.loadFonts) {
          loadFolderMetaFonts(response);
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const saveFolderMetaData = createAction(
  getActionType('DATA', 'SAVE'),
  (
    dispatch,
    projectId: string,
    folderId: string,
    formResponse: FormResponseType & {
      data: Partial<FolderMetaService.FolderMetaType>;
      loadFonts?: boolean;
    },
  ) => {
    const loadingKey = LOADINGS.FOLDER_META.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data, loadFonts } = formResponse;
    FolderMetaService.updateFolderMeta(projectId, folderId, data)
      .then((response) => {
        dispatch(updateFolderMetaData(dispatch, folderId, response));
        if (loadFonts) {
          loadFolderMetaFonts(response);
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER_META.SHOW_UPDATE_PANEL, false));
        dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.BLOCK, false));

        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const uploadFolderCoverPhotoFocalPoint = createAction(
  getActionType('FOLDER_COVER_PHOTO_FOCAL_POINT', 'UPDATE'),
  (dispatch, projectId: string, folderId: string, cordinates: { x?: number; y: number }) => {
    const loadingKey = LOADINGS.FOLDER.UPDATE_COVER_PHOTO_FOCAL_POINT(folderId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const data = { [FolderMetaService.FOLDER_META_TYPES.COVER_IMAGE_FOCAL_POINT]: cordinates };
    FolderMetaService.updateFolderMeta(projectId, folderId, data)
      .then((response) => {
        dispatch(updateFolderMetaData(dispatch, folderId, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const uploadFolderCoverPhoto = createAction(
  getActionType('FOLDER_COVER_PHOTO', 'UPDATE'),
  (dispatch, projectId: string, folderId: string, coverPhoto: File) => {
    const formData = new FormData();
    formData.append('cover_image', coverPhoto);

    const loadingKey = LOADINGS.FOLDER.UPDATE_COVER_PHOTO(folderId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderMetaService.uploadFolderCoverPhoto(projectId, folderId, formData)
      .then((response) => {
        dispatch(
          updateFolderMetaData(dispatch, folderId, {
            cover_image: response?.cover_image,
          }),
        );
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const editQRCodeDesigns = createAction(
  getActionType('QR_CODE_DESIGNS', 'EDIT'),
  (
    dispatch,
    projectId: string,
    folderId: string,
    data: {
      previousDesignData: QRCodeBackgroundPosterService.QRCodeDesignType;
      newDesignData: QRCodeBackgroundPosterService.QRCodeDesignType;
    },
  ) => {
    const loadingKey = LOADINGS.FOLDER_META.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const dataToPost = {
      [FolderMetaService.FOLDER_META_TYPES.QR_CODE_DESIGNS]: { ...data.previousDesignData, ...data.newDesignData },
    };
    dispatch(updateQRCodeDesigns(dispatch, folderId, dataToPost[FolderMetaService.FOLDER_META_TYPES.QR_CODE_DESIGNS]));

    FolderMetaService.updateFolderMeta(projectId, folderId, dataToPost)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const initDefaultEventAttendeeBadgeDesigns = createAction(
  getActionType('EVENT_ATTENDEE_BADGE_DESIGN', 'INIT'),
  (dispatch, projectId: string, folderId: string) => {
    const loadingKey = LOADINGS.FOLDER_META.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const dataToPost = {
      [FolderMetaService.FOLDER_META_TYPES.EVENT_ATTENDEE_BADGE_DESIGNS]: defaultBadgeWidgetConfig,
    };

    FolderMetaService.updateFolderMeta(projectId, folderId, dataToPost)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(
          updateEventAttendeeBadgeDesigns(
            dispatch,
            folderId,
            response[FolderMetaService.FOLDER_META_TYPES.EVENT_ATTENDEE_BADGE_DESIGNS],
          ),
        );
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const fetchBadgeDesignData = createAction(
  getActionType('DATA_BADGE_DESIGN', 'FETCH'),
  (dispatch, projectId: string, folderId: string) => {
    const loadingKey = LOADINGS.FOLDER_META.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderMetaService.fetchFolderMeta(projectId, folderId)
      .then((response) => {
        if (!(FolderMetaService.FOLDER_META_TYPES.EVENT_ATTENDEE_BADGE_DESIGNS in response)) {
          dispatch(initDefaultEventAttendeeBadgeDesigns(dispatch, projectId, folderId));
        }
        dispatch(setFolderMetaData(dispatch, folderId, response));

        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const editEventAttendeeBadgeWidgetDesigns = createAction(
  getActionType('EVENT_ATTENDEE_BADGE_DESIGNS', 'EDIT'),
  (
    dispatch,
    projectId: string,
    folderId: string,
    data: {
      badgeDesignData: EventAttendeeBadgeDesignType;
      widgetDesignData: Partial<Record<WIDGET_TYPE, WidgetDataType>>;
    },
  ) => {
    const loadingKey = LOADINGS.FOLDER_META.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    let widgetData: Record<WIDGET_TYPE, WidgetDataType> = { ...data.badgeDesignData.widget_data };
    Object.entries(data.widgetDesignData).forEach(([key, value]) => {
      widgetData = { ...widgetData, [key]: { ...data.badgeDesignData.widget_data?.[key], ...value } };
    });

    const dataToPost = {
      [FolderMetaService.FOLDER_META_TYPES.EVENT_ATTENDEE_BADGE_DESIGNS]: {
        ...data.badgeDesignData,
        widget_data: widgetData,
      },
    };

    FolderMetaService.updateFolderMeta(projectId, folderId, dataToPost)
      .then((response) => {
        dispatch(
          updateEventAttendeeBadgeDesigns(
            dispatch,
            folderId,
            response[FolderMetaService.FOLDER_META_TYPES.EVENT_ATTENDEE_BADGE_DESIGNS],
          ),
        );
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

type FolderMetaItemType = IMap<string, FolderMetaService.FolderMetaType>;
type StateType = {
  items: FolderMetaItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setFolderMetaData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [setFoldersMetaData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [updateFolderMetaData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.folderId, (data) => Object.assign({}, data, action.payload.data)),
    }),
    [updateQRCodeDesigns.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.folderId, (data: any) => ({
        ...data,
        qr_code_designs: { ...data?.qr_code_designs, ...action.payload.data },
      })),
    }),
    [updateIAmAttendingPosterDesigns.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.folderId, (data: any) => ({
        ...data,
        [FolderMetaService.FOLDER_META_TYPES.I_AM_ATTENDING_POSTER_DESIGNS]: action.payload.data,
      })),
    }),
    [updateEventAttendeeBadgeDesigns.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.folderId, (data: any) => ({
        ...data,
        event_attendee_badge_designs: action.payload.data,
      })),
    }),
  },
  initialState,
);

export const foldersMetaDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.foldersMetaData as StateType).items,
);

export const foldersMetaSelector = createSelector(foldersMetaDataSelector, (foldersMeta) => foldersMeta.toJSON());

const defaultFontPalette = FontPaletteService.FONT_PALETTES_DETAILS[FontPaletteService.FONT_PALETTES.PLAYFAIR];

export const folderMetaDataForFolderSelector = (folderId) =>
  createSelector(
    foldersMetaDataSelector,
    (items) =>
      items.get(folderId) || {
        [FolderMetaService.FOLDER_META_TYPES.CURRENCY_CODE]: 'INR',
        [FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT]: defaultFontPalette.primary,
        [FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT]: defaultFontPalette.secondary,
        [FolderMetaService.FOLDER_META_TYPES.SELECTION_FILES_ORDER]:
          FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
        [FolderMetaService.FOLDER_META_TYPES.HIGHLIGHT_FILES_ORDER]:
          FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.DATE,
        [FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES]: false,
      },
  );

export const qrCodeDesignsSelector = (folderId) =>
  createSelector(
    folderMetaDataForFolderSelector(folderId),
    (foldersMeta) => foldersMeta?.[FolderMetaService.FOLDER_META_TYPES.QR_CODE_DESIGNS],
  );

export const qrCodeDesignsLogoSelector = (folderId) =>
  createSelector(qrCodeDesignsSelector(folderId), (data) => data?.logo);

export const qrCodeDesignsSponsorSelector = (folderId) =>
  createSelector(
    qrCodeDesignsSelector(folderId),
    sponsorCreativesIdsByPlacementSelector(SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.FOOTER),
    (data, creatives) => {
      const sponsorCreativesForQRPoster = {
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS.LOGO_1]: creatives[0],
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS.LOGO_2]: creatives[1],
        [QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS.LOGO_3]: creatives[2],
      };

      return (
        data?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.SPONSOR_LOGO] || sponsorCreativesForQRPoster
      );
    },
  );

export const qrCodeDesignsNumberOfActiveLogoSelector = (folderId) =>
  createSelector(qrCodeDesignsLogoSelector(folderId), (logos) => {
    const customLogo1 = logos && logos[0];
    const customLogo2 = logos && logos[1];

    if (customLogo1 && customLogo2) return 2;
    if (customLogo1 || customLogo2) return 1;
    return 0;
  });

export const qrCodeDesignsBackgroundPosterDataSelector = (folderId: string) =>
  createSelector(qrCodeDesignsSelector(folderId), (data) =>
    // this is to handle old meta data of background poster
    data?.background_poster &&
    Object.values(QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS).includes(data.background_poster)
      ? QRCodeBackgroundPosterService.QR_CODE_POSTERS[data.background_poster]
      : QRCodeBackgroundPosterService.QR_CODE_POSTERS[QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS.SQUARE_CIRCLE],
  );

export const qrCodeDesignsShowPoweredByPremagicSelector = (folderId) =>
  createSelector(
    qrCodeDesignsSelector(folderId),
    (data) => data?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.SHOW_POWERED_BY_PREMAGIC] ?? true,
  );

export const isFolderBlockedSelector = (folderId) =>
  createSelector(
    folderMetaDataForFolderSelector(folderId),
    (foldersMeta) => foldersMeta?.[FolderMetaService.FOLDER_META_TYPES.BLOCK_FOLDER] || false,
  );

export const eventAttendeeBadgeDesignsSelector = (folderId) =>
  createSelector(
    folderMetaDataForFolderSelector(folderId),
    (foldersMeta) =>
      foldersMeta?.[FolderMetaService.FOLDER_META_TYPES.EVENT_ATTENDEE_BADGE_DESIGNS] as EventAttendeeBadgeDesignType,
  );

export const isFolderPublicSelector = (folderId) =>
  createSelector(
    folderMetaDataForFolderSelector(folderId),
    (foldersMeta) => foldersMeta?.[FolderMetaService.FOLDER_META_TYPES.IS_FOLDER_PUBLIC] || false,
  );
