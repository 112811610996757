import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { ProductFeedbackService } from '@premagic/core';
import { eventProjectSelectorSelector } from '../../../../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import EventFeedbackLayout from './EventFeedbackLayout';
import { getEventFeedbackForProject } from '../data-duck/EventFeedbackDataDuck';

function mapStateToProps(
  state,
  ownProps: { match: { params: { eventId: string; type: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE } } },
) {
  const { eventId, type } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PRODUCT_FEEDBACK.EVENT_FEEDBACK.FETCH)(state),
    type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEventFeedback: (projectId, type) => {
      dispatch(getEventFeedbackForProject(dispatch, projectId, type));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFeedbackLayout);
