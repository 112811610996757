import { LoadingDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { EventAttendeesService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';

import UpdateEventAttendeeBadgesIds from './UpdateEventAttendeeBadgesIds';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';
import {
  bulkUpdateEventAttendeeDisplayId,
  eventAttendeesIdsForTypeSelector,
} from '../../crm/events/event-attendees/EventAttendeesDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    posterId: string;
  },
) {
  const { projectId, posterId } = ownProps;

  const poster = posterId ? eventPostersSelector(state)[posterId] : undefined;
  const { scope = [EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE] } = poster || {};

  return {
    projectId,
    posterId,
    attendeeIds: eventAttendeesIdsForTypeSelector(scope)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_ATTENDEES.BULK_UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bulkUpdateAttendee: (projectId: string, data) => {
      dispatch(bulkUpdateEventAttendeeDisplayId(dispatch, projectId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEventAttendeeBadgesIds);
