import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import ClientWebsiteDetailsLayout from './ClientWebsiteDetailsLayout';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { projectDefaultLanguageSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps.match.params;

  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    langCode: projectDefaultLanguageSelector(projectId)(state),
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditBasicInfoPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_PANEL, true));
    },
    showEditOfPeopleDetailsPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_PEOPLE_DETAILS_PANEL, true));
    },
    showEditEventMetricsPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_EVENT_METRICS_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteDetailsLayout);
