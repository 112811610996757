import React from 'react';
import { Dropdown, DropdownIcon, Icon, ICON_SIZES, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';
import { SponsorCreativesService } from '@premagic/core';

import SponsorDeleteDialogContainer from './SponsorCreativeDeleteDialogContainer';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import MESSAGES from '../../../../../../../common/Messages';

type EventOccasionOptionsMenuProps = {
  projectId: string;
  creativeId: string;
  hasPermissionsToManageCreatives: boolean;
  showDeleteDialog: (sponsorId: string, id: string) => void;
  creative: SponsorCreativesService.SponsorCreativeType;
};

export default function SponsorCreativeOptionsMenu(props: EventOccasionOptionsMenuProps): React.ReactElement {
  const { showDeleteDialog, projectId, hasPermissionsToManageCreatives, creativeId, creative } = props;
  const { sponsor_id: sponsorId } = creative;
  return (
    <>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="top">
          <Icon name="more_vertical" size={ICON_SIZES.SM} />
        </DropdownIcon>

        <Menu>
          <MenuItem
            disabled={!hasPermissionsToManageCreatives}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT]}
            onClick={() => showDeleteDialog(sponsorId, creativeId)}
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      {hasPermissionsToManageCreatives && (
        <SponsorDeleteDialogContainer sponsorId={sponsorId} projectId={projectId} creativeId={creativeId} />
      )}
    </>
  );
}
