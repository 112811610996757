import React, { useMemo } from 'react';
import { COLOR_SHADES, ErrorBoundary, Grid, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import { getLayoutForDashboard } from './EventReportService';
import EventReportWidgetContainer from './EventReportWidgetContainer';

type Props = {
  hasLeadsModule: boolean;
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
};

export default function EventReportTopMetrics(props: Props): JSX.Element | null {
  const { projectId, hasLeadsModule, projectMeta } = props;
  const isPaidDownloadEnabled =
    projectMeta?.[ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.IS_GUEST_GALLERY_DOWNLOAD_PAID] || false;
  const layout = useMemo(() => getLayoutForDashboard({ hasLeadsModule, isPaidDownloadEnabled }), [hasLeadsModule]);

  return (
    <ErrorBoundary>
      {layout.map((group) => (
        <div key={group.id}>
          {group.title && (
            <div>
              <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                {group.title}
              </Text>
              <Space vertical size={1} />
            </div>
          )}
          <Grid colSize={200}>
            {group.widgets.map((widget) => (
              <div key={widget}>
                <EventReportWidgetContainer projectId={projectId} id={widget} />
              </div>
            ))}
          </Grid>
          <Space vertical />
        </div>
      ))}
    </ErrorBoundary>
  );
}
