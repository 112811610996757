import { PaginationFiltersType } from '../../../services/PaginationService';

export enum ACCOUNT_FINANCIALS_FILTER_KEYS {
  SLICE = 'slice',
  START_DATE_GTE = 'start_date_gte',
  // START_DATE_LTE = 'start_date_lte',
  // END_DATE_GTE = 'end_date_gte',
  // END_DATE_LTE = 'end_date_lte',
}

export type AccountFinancialsFiltersType = {
  [ACCOUNT_FINANCIALS_FILTER_KEYS.SLICE]?: string;
  [ACCOUNT_FINANCIALS_FILTER_KEYS.START_DATE_GTE]?: string;
} & PaginationFiltersType;
