import { connect } from 'react-redux';
import { isMobileUp } from '../AppDuck';
import UserProfilePage from './UserProfilePage';
import { requestingUserSelector } from '../crm/users/UsersDataDuck';

function mapStateToProps(state) {
  return {
    user: requestingUserSelector(state),
    isMobileUp: isMobileUp(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
