import React from 'react';
import styled, { css } from 'styled-components';

import { BrowserUtils } from '@premagic/utils';
import { Space, SubTitle, Tooltip } from '@premagic/myne';

import { AlbumPageType, NewAlbumPageType } from '../services/AlbumServices';
import { ALBUM_TEMPLATES, ALBUM_TEMPLATE_IDS } from './AlbumPageTemplateService';

const Templates = styled.div`
  padding: 4px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, 64px);
`;

const svgCommonStyle = {
  viewBox: '0 0 60 40',
  width: '60px',
  height: '40px',
  fill: 'none',
  strokeLinecap: 'round' as const,
  strokeLinejoin: 'round' as const,
  strokeWidth: '0',
};

function TemplateIcon(props: { iconPath: string; active: boolean }) {
  const { iconPath, active } = props;
  return (
    <svg {...svgCommonStyle}>
      <path fill={BrowserUtils.getCSSVariableValue('--color-white')} d="M0 0h60v40H0V0z" />
      <path fill={BrowserUtils.getCSSVariableValue(active ? '--color-blue' : '--color-gray-light')} d={iconPath} />
    </svg>
  );
}

const TemplateButtonStyle = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  border: 2px solid #fff;
  z-index: 10;
  &:hover,
  &:focus {
    border-color: ${BrowserUtils.getCSSVariableValue('--color-blue')};
  }
  ${(props) =>
    props.active &&
    css`
      border-color: ${BrowserUtils.getCSSVariableValue('--color-blue')};
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
      transform: scale(1.05);
    `}
`;

type TemplateButtonProps = {
  templateId: ALBUM_TEMPLATE_IDS;
  onChange: (templateId: ALBUM_TEMPLATE_IDS) => void;
  active: boolean;
};

function TemplateButton(props: TemplateButtonProps) {
  const { templateId, onChange, active } = props;
  const details = ALBUM_TEMPLATES[templateId];
  return (
    <TemplateButtonStyle
      className={`js-template-button--active-${active}`}
      active={active}
      onClick={() => onChange(templateId)}
      onKeyPress={() => onChange(templateId)}
    >
      <TemplateIcon iconPath={details.iconPath} active={active} />
    </TemplateButtonStyle>
  );
}

type Props = {
  albumId: string;
  selectTemplate: (albumId, pageData: AlbumPageType | NewAlbumPageType, templateId: number) => void;
  selectedTemplateName: number;
  pageData: AlbumPageType | NewAlbumPageType;
};

export default function TemplatePicker(props: Props): JSX.Element {
  const { selectTemplate, albumId, selectedTemplateName, pageData } = props;

  return (
    <Templates>
      <div>
        <Space size={2} vertical />
        <Tooltip
          placement="bottom"
          message={
            <div>
              Select template for this page <Space size={1} vertical /> Use <kbd>t</kbd> to focus of the selected
            </div>
          }
        >
          <SubTitle>Page Templates</SubTitle>
        </Tooltip>
      </div>
      {Object.entries(ALBUM_TEMPLATES)
        .sort(([, a], [, b]) => a.numberOfPhotos - b.numberOfPhotos)
        .map(([, details]) => (
          <TemplateButton
            active={selectedTemplateName == details.id}
            key={details.id}
            templateId={details.id}
            onChange={(id) => selectTemplate(albumId, pageData, id)}
          />
        ))}
    </Templates>
  );
}
