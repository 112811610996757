import React, { useState } from 'react';
import { Button, Col, COLOR_SHADES, Deck, DeckSlide, Row, Space, Text, TEXT_BOLDNESS, TimeAgo } from '@premagic/myne';
import { ProposalDeckService } from '@premagic/proposals';
import { MediaUtils, SimpleDateUtils } from '@premagic/utils';
import EventProposalDeckVariablesContainer from '../../../crm/event-proposals/variables/EventProposalDeckVariablesContainer';

type Props = {
  deckId: string;
  folderId: string;
  deck?: ProposalDeckService.ProposalDeckType;
  versionNumber: string;
};

export default function ProposalHistoryVersionItem(props: Props) {
  const { deckId, folderId, versionNumber, deck } = props;
  const [showSlides, setShowSlides] = useState(false);
  const deckHeight = 300;
  const deckWidth = MediaUtils.getImageWidthForRatio(deckHeight, 0.5);

  if (!deck) return null;

  const { created_at: createdAt, variables, slides } = deck;
  const dateString = createdAt && SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt);
  return (
    <div>
      <Row>
        <Col>
          <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
            <TimeAgo date={dateString} />
          </Text>
        </Col>
        {versionNumber === 'latest' && (
          <Col rightAlighed size={null}>
            <Text color={COLOR_SHADES.BLUE_LIGHT}>Current Version</Text>
          </Col>
        )}
      </Row>
      <EventProposalDeckVariablesContainer deckId={deckId} folderId={folderId} />
      <Space vertical />
    </div>
  );
}
/*
      <Button onClick={() => setShowSlides(!showSlides)}>Show slides</Button>
{showSlides && (
  <div
    style={{
      height: deckHeight,
    }}
  >
    <Deck
      width={deckWidth}
      height={deckHeight}
      variables={variables}
      fonts={{}}
      files={{}}
      defaultSlideIndex={0}
      onButtonActions={()=> {}}
      deckAdditionalData={{}}
      showLeftAndRightSlides
    >
      {slides.map((slide) => {
        const { structure, id, template_id: layoutId } = slide;
        return <DeckSlide layout={layoutId} data={structure} key={id} id={id} />;
      })}
    </Deck>
  </div>
)}
*/
