import { FolderService, PremagicResources } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  FadeIn,
  Icon,
  ICON_SIZES,
  INPUT_FILE_ACCEPT,
  InputFileDropzone,
  InputFileDropzoneOverlayContent,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ArrayUtils, ErrorTracker, ImageOptimizerUtils, MediaUtils, NumberUtils, StringUtils } from '@premagic/utils';
import React from 'react';
import { toastMessage } from '../../../../reducers/ToastStore';

function doPhotoCompression(isImageCompressorReady, imageFiles, highlightUploadQuality, folderType) {
  if (isImageCompressorReady && imageFiles) {
    if (folderType === FolderService.FOLDER_TYPES.SELECTION) {
      return true;
    }
    if (highlightUploadQuality !== 'ORIGINAL') {
      return true;
    }
    return false;
  }
  return false;
}

function FileDropSuccess() {
  return (
    <div>
      <Color shade={COLOR_SHADES.BLUE}>
        <Icon name="image" size={ICON_SIZES.LG} />
      </Color>

      <Space vertical size={8} />
      <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
        Drop photos here!
      </Text>
    </div>
  );
}
function FileDropDefault(props: {
  accept: INPUT_FILE_ACCEPT;
  maxImageFileSize: number;
  maxVideoFileSize: number;
  maxFiles: number;
}) {
  const { accept, maxImageFileSize, maxVideoFileSize, maxFiles } = props;
  const actionButtonText = accept === INPUT_FILE_ACCEPT.IMAGES_AND_VIDEO ? 'Choose Files' : 'Choose Photos';
  const filesNames = accept === INPUT_FILE_ACCEPT.IMAGES_AND_VIDEO ? 'Photos & Videos' : 'Photos';
  return (
    <div>
      <Color shade={COLOR_SHADES.LIGHT}>
        <Icon name="upload" size={ICON_SIZES.LG} />
        <Space vertical size={5} />
        <Text size={TEXT_SIZE.SIZE_2}>Drop {filesNames} here</Text>
        {maxFiles < 250 && (
          <Row vcenter>
            <Text size={TEXT_SIZE.SIZE_5}>Use</Text>
            <Space size={2} />
            <Button
              size={BUTTON_SIZE.XS}
              style={BUTTON_STYLES.PRIMARY}
              link={PremagicResources.RESOURCES.download('account-app')}
              isExternalLink
            >
              Desktop app
            </Button>
            <Space size={2} />
            <Text size={TEXT_SIZE.SIZE_5}>
              to upload more than{' '}
              <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                {maxFiles} files
              </Text>{' '}
              at once.
            </Text>
          </Row>
        )}
        <Space vertical size={8} />
        <Button style={BUTTON_STYLES.PRIMARY}>{actionButtonText}</Button>
        <Space vertical size={2} />
        <Text size={TEXT_SIZE.SIZE_5} muted>
          Max Photo size: {NumberUtils.getNumberInBytesFormat(maxImageFileSize)}
          {maxVideoFileSize > 0 &&
            accept === INPUT_FILE_ACCEPT.IMAGES_AND_VIDEO &&
            ` & Video size: ${NumberUtils.getNumberInBytesFormat(maxVideoFileSize)}`}
        </Text>
      </Color>
    </div>
  );
}

function FileDropReject(props: { accept: INPUT_FILE_ACCEPT }) {
  const { accept } = props;
  const filesNames = accept === INPUT_FILE_ACCEPT.IMAGES_AND_VIDEO ? 'photo or video' : 'photo';
  return (
    <div>
      <Color shade={COLOR_SHADES.DANGER}>
        <Icon name="slash" size={ICON_SIZES.LG} />
        <Space vertical size={5} />
        <Text size={TEXT_SIZE.SIZE_2}>
          Oops... this is not a {filesNames}, Try dropping {filesNames}
        </Text>
        <Space vertical size={5} />
      </Color>
    </div>
  );
}
type FilesDropProps = {
  large: boolean;
  onDrop: (files: Array<File>) => void;
  children?: React.ReactElement;
  folderType: FolderService.FOLDER_TYPES;
  disableUploadLimit: boolean;
};

function FilesDrop(props: FilesDropProps) {
  const { large, onDrop, children, folderType, disableUploadLimit } = props;
  const accept = [FolderService.FOLDER_TYPES.SELECTION, FolderService.FOLDER_TYPES.BRANDING].includes(folderType)
    ? INPUT_FILE_ACCEPT.IMAGES
    : INPUT_FILE_ACCEPT.IMAGES_AND_VIDEO;
  const maxImageFileSize = 25 * 1000 * 1000;
  const maxVideoFileSize = folderType === FolderService.FOLDER_TYPES.SELECTION ? 0 : 3000 * 1000 * 1000;
  const maxFiles =
    [
      FolderService.FOLDER_TYPES.PROPOSAL,
      FolderService.FOLDER_TYPES.SPONSOR,
      FolderService.FOLDER_TYPES.BRANDING,
      FolderService.FOLDER_TYPES.CLIENT_WEBSITE,
    ].includes(folderType) || disableUploadLimit
      ? 3000
      : 50;

  return (
    <InputFileDropzone
      maxImageFileSize={maxImageFileSize}
      maxVideoFileSize={maxVideoFileSize}
      maxFiles={maxFiles}
      accept={accept}
      style={children ? 'default' : 'standalone'}
      large={large}
      onDrop={(files: Array<File>, rejectedFiles, errorMessages) => {
        onDrop(files);

        if (rejectedFiles.length > 0) {
          const errorMessage = ArrayUtils.listify(errorMessages);
          const isAllFilesRejected = files.length === 0;
          if (isAllFilesRejected) {
            toastMessage(
              'error',
              `<strong>${rejectedFiles.length}</strong> ${StringUtils.pluralize(
                'File',
                rejectedFiles.length,
              )} ignored! <br/> ${errorMessage}`,
              10000,
            );
            return;
          }
          toastMessage(
            'warning',
            `Premagic has ignored ${rejectedFiles.length} ${StringUtils.pluralize(
              'file',
              rejectedFiles.length,
            )}, ${errorMessage}`,
          );
        }
      }}
    >
      {({ isDragAccept, isDragReject, isDragActive }) => {
        if (isDragAccept) {
          return (
            <FadeIn>
              {children}
              <InputFileDropzoneOverlayContent>
                <FileDropSuccess />
              </InputFileDropzoneOverlayContent>
            </FadeIn>
          );
        }

        // if (isDragReject) {
        //   return (
        //     <FadeIn>
        //       {children}
        //       <InputFileDropzoneOverlayContent>
        //         <FileDropReject accept={accept} />
        //       </InputFileDropzoneOverlayContent>
        //     </FadeIn>
        //   );
        // }

        return (
          children || (
            <FadeIn>
              <FileDropDefault
                accept={accept}
                maxImageFileSize={maxImageFileSize}
                maxVideoFileSize={maxVideoFileSize}
                maxFiles={maxFiles}
              />
            </FadeIn>
          )
        );
      }}
    </InputFileDropzone>
  );
}

type Props = {
  projectId: string;
  folderId: string;
  folder: FolderService.FolderType;
  projectName: string;
  uploadFiles: (
    files: [] | Array<File>,
    options: {
      projectId: string;
      folderId: string;
      projectName: string;
      folderName: string;
      folderType: FolderService.FOLDER_TYPES;
    },
  ) => void;
  children?: React.ReactElement;
  totalFiles: number;
  highlightPhotosLimit: number;
  highlightVideosLimit: number;
  totalVideosInProject: number;
  proposalPhotosLimit: number;
  disableUploadLimit: boolean;
  showCompressingInfo: (display: boolean) => void;
  isImageCompressorReady?: boolean;
  highlightUploadQuality?: MediaUtils.UPLOAD_QUALITY_TYPES;
};

export default function AccountFolderPhotoDropZone(props: Props) {
  const {
    projectId,
    folderId,
    totalFiles,
    uploadFiles,
    highlightPhotosLimit,
    highlightVideosLimit,
    totalVideosInProject,
    proposalPhotosLimit,
    children,
    projectName,
    folder,
    disableUploadLimit,
    showCompressingInfo,
    isImageCompressorReady,
    highlightUploadQuality = MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_4K,
  } = props;
  const zeroPhotos = totalFiles === 0;
  const { folder_name: folderName, folder_type: folderType } = folder || {};

  function handleOnDrop(files) {
    const totalFilesSelected = files.length;
    const highlightAvailableFiles = highlightPhotosLimit - totalFiles;
    const proposalAvailableFiles = proposalPhotosLimit - totalFiles;

    if (folderType === FolderService.FOLDER_TYPES.HIGHLIGHT && totalFilesSelected > highlightAvailableFiles) {
      if (!highlightAvailableFiles) {
        toastMessage(
          'warning',
          `You cannot upload any more photos!  <br/>Only ${highlightPhotosLimit} photos can be uploaded in the Signature album <br/${
            highlightPhotosLimit < 500 ? 'Upgrade your plan to increase the limit' : ''
          }`,
          10000,
        );
        return;
      }

      toastMessage(
        'warning',
        `You can only upload ${highlightAvailableFiles} photos in this Signature album, but you selected ${totalFilesSelected} photos`,
        10000,
      );
      return;
    }

    if (folderType === FolderService.FOLDER_TYPES.PROPOSAL && totalFilesSelected > proposalAvailableFiles) {
      if (!proposalAvailableFiles) {
        toastMessage(
          'warning',
          `You cannot upload any more photos! <br/>Only ${proposalPhotosLimit} photos can be uploaded in the Proposal <br/${
            proposalAvailableFiles < 1 ? 'Upgrade your plan to increase the limit' : ''
          }`,
          10000,
        );
        return;
      }

      toastMessage(
        'warning',
        `You can only upload ${proposalAvailableFiles} photos in Proposal, but you selected ${totalFilesSelected} photos`,
        10000,
      );
      return;
    }

    const videoFiles = files?.filter((item) => MediaUtils.isVideoFile(item));
    // the optimizer only support jpeg images

    if (videoFiles.length + totalVideosInProject > highlightVideosLimit) {
      toastMessage('warning', `You can upload only maximum of ${highlightVideosLimit} videos in a project`, 10000);
      return;
    }

    const jpegImageFiles = files?.filter((item) => MediaUtils.isJpegImageFile(item));
    const otherImageFormatFiles = files?.filter(
      (item) => MediaUtils.isImageFile(item) && !MediaUtils.isJpegImageFile(item),
    );

    // Uploading  images with compression
    if (doPhotoCompression(isImageCompressorReady, jpegImageFiles, highlightUploadQuality, folderType)) {
      const uploadQualityType =
        folder?.folder_type === FolderService.FOLDER_TYPES.SELECTION
          ? MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_1K
          : highlightUploadQuality;

      showCompressingInfo(true);
      ImageOptimizerUtils.filesProcessor
        .compressFiles(jpegImageFiles, uploadQualityType)
        .then((compressedFiles) => {
          uploadFiles(compressedFiles, { projectId, folderId, projectName, folderName, folderType });
          showCompressingInfo(false);
        })
        .catch((e) => {
          toastMessage('warning', 'Failed to optimize photos ', 10000);
          ErrorTracker.logError('Image upload: Failed to optimize images', e);
          showCompressingInfo(false);
        });
    } else {
      // Uploading  images without compression
      uploadFiles(jpegImageFiles, { projectId, folderId, projectName, folderName, folderType });
    }

    // Uploading videos
    uploadFiles(videoFiles, { projectId, folderId, projectName, folderName, folderType });
    // Uploading other image file formats (except jpeg)
    uploadFiles(otherImageFormatFiles, { projectId, folderId, projectName, folderName, folderType });
  }

  return (
    <div>
      <FilesDrop
        large={zeroPhotos}
        onDrop={handleOnDrop}
        folderType={folderType}
        disableUploadLimit={disableUploadLimit}
      >
        {children}
      </FilesDrop>
      <Space vertical />
    </div>
  );
}
