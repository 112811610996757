import { AssetsService, QRCodeBackgroundPosterService } from '@premagic/core';
import {
  COLOR_SHADES,
  LoadingDots,
  Position,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  SimpleImage,
  PremagicWhiteLogo,
  PremagicBlackLogo,
} from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import classNames from 'classnames';
import QRCodeBackgroundPosterContainer from './background-poster/QRCodeBackgroundPosterContainer';
import LogoBasedOnPosterTypeContainer from './displayed-logo/LogoBasedOnPosterTypeContainer';
import DisplayedPosterIllustrationContainer from './poster-illustration/DisplayedPosterIllustrationContainer';
import useQRCode from './custom-hooks/useQrCode';
import useBackgroundPoster from './custom-hooks/useBackgroundPoster';

export default function TentQRCode(props: {
  url: string;
  printClass: string;
  fileNameClass: string;
  backgroundCover: QRCodeBackgroundPosterService.QRCodePoster;
  folderId: string;
  logos?: Array<string | undefined>;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  showPoweredByPremagic: boolean;
}) {
  const { url, printClass, fileNameClass, backgroundCover, folderId, logos, qrCodeDesigns, showPoweredByPremagic } =
    props;

  const { qrCode, isLoading: isLoadingQr } = useQRCode(url, 150);
  const { qrSvgImage } = qrCode || {};

  const customLogo1 = logos && logos[0];
  const customLogo2 = logos && logos[1];

  const { customStyle, headerTextColor, footerTextColor, premagicLogo, smallerLogoSpace } = useBackgroundPoster(
    'tent',
    backgroundCover,
    qrCodeDesigns,
  );

  return (
    <>
      <Row vcenter center>
        <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARK}>
          Tent Card (5.9" x 7.67")
        </Text>
      </Row>
      <Space vertical size={6} />
      <Row center>
        <div
          className={classNames(printClass, fileNameClass)}
          style={{
            width: '409px',
            height: '610px',
            position: 'relative',
            overflow: 'hidden',
            flexShrink: 0,
          }}
        >
          <Position align="cover">
            <QRCodeBackgroundPosterContainer posterId={backgroundCover.id} posterType="tent-card" folderId={folderId} />
          </Position>
          {backgroundCover?.customIllustration && (
            <Position align="bottom-left">
              <DisplayedPosterIllustrationContainer folderId={folderId} imageHeight={250} imageWidth={140} />
            </Position>
          )}
          <div
            style={{
              zIndex: 1,
              position: 'relative',
            }}
          >
            <Row columnDirection center>
              <Space vertical size={7} />
              <LogoBasedOnPosterTypeContainer
                posterType="tent"
                folderId={folderId}
                customLogo1={customLogo1}
                customLogo2={customLogo2}
                customStyle={customStyle}
                smallerLogoSpace={smallerLogoSpace}
              />
              <Space vertical size={5} />
              <div
                style={
                  customStyle
                    ? {
                        width: '229px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '23px',
                        right: '22px',
                      }
                    : { display: 'flex', flexDirection: 'column', alignItems: 'center' }
                }
              >
                <div style={{ width: '166px' }}>
                  <Text
                    primaryStyle
                    color={headerTextColor}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    cssStyle={{ fontSize: '33px', lineHeight: '33px' }}
                  >
                    GET YOUR
                  </Text>
                  <Text
                    primaryStyle
                    color={headerTextColor}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    italic
                    cssStyle={{ fontSize: '38px', lineHeight: '33px' }}
                  >
                    PHOTOS
                  </Text>
                  <Text
                    primaryStyle
                    color={headerTextColor}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    cssStyle={{ fontSize: '18px', lineHeight: '20px' }}
                  >
                    FROM THIS EVENT
                  </Text>
                  <Text
                    primaryStyle
                    color={headerTextColor}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    italic
                    cssStyle={{ fontSize: '20px', lineHeight: '20px', whiteSpace: 'nowrap' }}
                  >
                    ON YOUR PHONE
                  </Text>
                </div>
                <div style={customStyle ? { marginBottom: '92px' } : { marginBottom: '20px' }} />
                <div style={{ width: '166px', textAlign: 'center' }}>
                  <Text color={headerTextColor} boldness={TEXT_BOLDNESS.SEMI_BOLD} cssStyle={{ fontSize: '11px' }}>
                    Scan this code & register your face
                  </Text>
                </div>
                <Space vertical size={1} />
                <Row center>
                  <div
                    style={{
                      border: '1.5px solid #191a19',
                      borderRadius: '7%',
                      padding: '7px',
                      background: 'white',
                    }}
                  >
                    {isLoadingQr ? (
                      <LoadingDots size="sm" />
                    ) : (
                      qrSvgImage && <SimpleImage src={qrSvgImage} alt="qr-code" />
                    )}
                  </div>
                </Row>
                <div style={customStyle ? { marginBottom: '48px' } : { marginBottom: '24px' }} />
                {showPoweredByPremagic ? (
                  <Row center columnDirection>
                    <Text
                      color={premagicLogo === 'white' ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
                      size={TEXT_SIZE.SIZE_6}
                      boldness={TEXT_BOLDNESS.SEMI_BOLD}
                    >
                      Powered by
                    </Text>
                    {(() => {
                      if (premagicLogo === 'white') {
                        return <PremagicWhiteLogo width={110} height={30} />;
                      }

                      if (premagicLogo === 'black') {
                        return <PremagicBlackLogo width={110} height={30} />;
                      }

                      return (
                        <Space size={1}>
                          <SimpleImage
                            style={{ width: '110px', objectFit: 'contain' }}
                            src={AssetsService.getLogoImages(AssetsService.ASSETS_URL.LOGO_ICON_WITH_TEXT_HIGH_QUALITY)}
                            alt="premagic-logo"
                          />
                        </Space>
                      );
                    })()}
                  </Row>
                ) : (
                  <div style={{ height: 46 }} />
                )}
                <Space vertical size={7} />
                <Text
                  color={footerTextColor}
                  block
                  boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                  center
                  primaryStyle
                  transformUpper
                  cssStyle={
                    customStyle ? { fontSize: '11px', lineHeight: '14px' } : { fontSize: '14px', lineHeight: '16px' }
                  }
                >
                  We use AI{' '}
                  <Text
                    boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                    color={footerTextColor}
                    cssStyle={
                      customStyle ? { fontSize: '12px', lineHeight: '14px' } : { fontSize: '15px', lineHeight: '16px' }
                    }
                  >
                    &
                  </Text>{' '}
                  Face Recognition
                </Text>
                <Text
                  color={footerTextColor}
                  block
                  center
                  transformUpper
                  primaryStyle
                  boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                  cssStyle={
                    customStyle ? { fontSize: '8px', lineHeight: '12px' } : { fontSize: '9px', lineHeight: '14px' }
                  }
                >
                  to identify your face from the photos taken by
                </Text>
                <Text
                  color={footerTextColor}
                  block
                  center
                  transformUpper
                  primaryStyle
                  boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                  cssStyle={
                    customStyle ? { fontSize: '8px', lineHeight: '12px' } : { fontSize: '9px', lineHeight: '14px' }
                  }
                >
                  the photographers{' '}
                  <Text
                    boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                    color={footerTextColor}
                    cssStyle={
                      customStyle ? { fontSize: '9px', lineHeight: '12px' } : { fontSize: '10px', lineHeight: '14px' }
                    }
                  >
                    &
                  </Text>{' '}
                  deliver them to you
                </Text>
                <Text
                  color={footerTextColor}
                  block
                  center
                  transformUpper
                  primaryStyle
                  boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                  cssStyle={
                    customStyle ? { fontSize: '10px', lineHeight: '10px' } : { fontSize: '12px', lineHeight: '16px' }
                  }
                >
                  via WhatsApp{' '}
                  <Text
                    boldness={customStyle ? TEXT_BOLDNESS.NORMAL : TEXT_BOLDNESS.BOLD}
                    color={footerTextColor}
                    cssStyle={{ fontSize: '13px', lineHeight: '16px' }}
                  >
                    &
                  </Text>{' '}
                  email.
                </Text>
              </div>
            </Row>
          </div>
        </div>
      </Row>
    </>
  );
}
