import { FontUtils } from '@premagic/utils';
import { uniq } from 'lodash';

export enum FONT_IDS {
  POPPINS,
  ITALIANNO,
  SNELL_ROUNDHAND,
  DAWNING_OF_A_NEW_DAY,
  COMFORTAA,
  ALLISON,
  DANCING_SCRIPT,
  KAISEI_DECOL,
  HANDO,
  OPEN_SANS,
  PLAYFAIR_DISPLAY,
  SOURCE_SANS_PRO,
  FUZZY_BUBBLES,
  ARIMA_MADURAI,
  MULISH,
  OSWALD,
  SOURCE_SERIF_PRO,
  ZILLA_SLAB,
  INTER,
  EB_GARAMOND,
  SPLINE_SANS,
  ABOUT_NUNITO,
  NUNITO_SANS,
  FUGAZ_ONE,
  WORK_SANS,
  ABRIL_FATFACE,
  SPACE_MONO,
  BITTER,
  GRAND_HOTEL,
  LATO,
  BASICALLY_A_SANS_SERIF,
}
type FontDetailsType = {
  name: string;
  nameToLoad: string;
};
type FontGoogleDetailsType = FontDetailsType & {
  type: 'google';
};
type FontCustomDetailsType = FontDetailsType & {
  type: 'custom';
  css: string;
};
export const FONTS_DETAILS: Record<FONT_IDS, FontGoogleDetailsType | FontCustomDetailsType> = {
  [FONT_IDS.SNELL_ROUNDHAND]: {
    // deprecated
    name: 'Snell Roundhand',
    nameToLoad: 'Snell Roundhand',
    type: 'custom',
    css: 'https://asts.premagic.com/s/fonts/snell-roundhand/font.css',
  },
  [FONT_IDS.HANDO]: {
    name: 'Hando',
    nameToLoad: 'Hando',
    type: 'custom',
    css: 'https://asts.premagic.com/s/fonts/hando/stylesheet.css',
  },
  [FONT_IDS.BASICALLY_A_SANS_SERIF]: {
    name: 'Basically a Sans Serif',
    nameToLoad: 'Basically a Sans Serif',
    type: 'custom',
    css: 'https://asts.premagic.com/s/fonts/basically-a-sans-serif/stylesheet.css',
  },
  [FONT_IDS.POPPINS]: {
    name: 'Poppins',
    nameToLoad: 'Poppins:300,600',
    type: 'google',
  },
  [FONT_IDS.DAWNING_OF_A_NEW_DAY]: {
    name: 'Dawning of a New Day',
    nameToLoad: 'Dawning of a New Day:400',
    type: 'google',
  },
  [FONT_IDS.ITALIANNO]: {
    name: 'Italianno',
    nameToLoad: 'Italianno:400',
    type: 'google',
  },
  [FONT_IDS.COMFORTAA]: {
    name: 'Comfortaa',
    nameToLoad: 'Comfortaa:300,600',
    type: 'google',
  },
  [FONT_IDS.ALLISON]: {
    name: 'Allison',
    nameToLoad: 'Allison',
    type: 'google',
  },
  [FONT_IDS.DANCING_SCRIPT]: {
    name: 'Dancing Script',
    nameToLoad: 'Dancing Script',
    type: 'google',
  },
  [FONT_IDS.KAISEI_DECOL]: {
    name: 'Kaisei Decol',
    nameToLoad: 'Kaisei Decol',
    type: 'google',
  },
  [FONT_IDS.OPEN_SANS]: {
    name: 'Open Sans',
    nameToLoad: 'Open Sans:300,600',
    type: 'google',
  },
  [FONT_IDS.PLAYFAIR_DISPLAY]: {
    name: 'Playfair Display',
    nameToLoad: 'Playfair Display:700',
    type: 'google',
  },
  [FONT_IDS.SOURCE_SANS_PRO]: {
    name: 'Source Sans Pro',
    nameToLoad: 'Source Sans Pro:400,600,700',
    type: 'google',
  },
  [FONT_IDS.FUZZY_BUBBLES]: {
    name: 'Fuzzy Bubbles',
    nameToLoad: 'Fuzzy Bubbles:400',
    type: 'google',
  },
  [FONT_IDS.ARIMA_MADURAI]: {
    name: 'Arima Madurai',
    nameToLoad: 'Arima Madurai:400',
    type: 'google',
  },
  [FONT_IDS.MULISH]: {
    name: 'Mulish',
    nameToLoad: 'Mulish:400,600,700',
    type: 'google',
  },
  [FONT_IDS.OSWALD]: {
    name: 'Oswald',
    nameToLoad: 'Oswald:400',
    type: 'google',
  },
  [FONT_IDS.SOURCE_SERIF_PRO]: {
    name: 'Source Serif Pro',
    nameToLoad: 'Source Serif Pro:400,600,700',
    type: 'google',
  },
  [FONT_IDS.ZILLA_SLAB]: {
    name: 'Zilla Slab',
    nameToLoad: 'Zilla Slab:400',
    type: 'google',
  },
  [FONT_IDS.INTER]: {
    name: 'Inter',
    nameToLoad: 'Inter:400,600,700',
    type: 'google',
  },
  [FONT_IDS.EB_GARAMOND]: {
    name: 'EB Garamond',
    nameToLoad: 'EB Garamond:400',
    type: 'google',
  },
  [FONT_IDS.SPLINE_SANS]: {
    name: 'Spline Sans',
    nameToLoad: 'Spline Sans:400,600,700',
    type: 'google',
  },
  [FONT_IDS.ABOUT_NUNITO]: {
    name: 'About Nunito',
    nameToLoad: 'About Nunito:400',
    type: 'google',
  },
  [FONT_IDS.NUNITO_SANS]: {
    name: 'Nunito Sans',
    nameToLoad: 'Nunito Sans:400,600,700',
    type: 'google',
  },
  [FONT_IDS.FUGAZ_ONE]: {
    name: 'Fugaz One',
    nameToLoad: 'Fugaz One:400',
    type: 'google',
  },
  [FONT_IDS.WORK_SANS]: {
    name: 'Work Sans',
    nameToLoad: 'Work Sans:400,600,700',
    type: 'google',
  },
  [FONT_IDS.ABRIL_FATFACE]: {
    name: 'Abril Fatface',
    nameToLoad: 'Abril Fatface:400',
    type: 'google',
  },
  [FONT_IDS.SPACE_MONO]: {
    name: 'Space Mono',
    nameToLoad: 'Space Mono:400',
    type: 'google',
  },
  [FONT_IDS.BITTER]: {
    name: 'Bitter',
    nameToLoad: 'Bitter:400,600,700',
    type: 'google',
  },
  [FONT_IDS.GRAND_HOTEL]: {
    name: 'Grand Hotel',
    nameToLoad: 'Grand Hotel:400',
    type: 'google',
  },
  [FONT_IDS.LATO]: {
    name: 'Lato',
    nameToLoad: 'Lato:400,700',
    type: 'google',
  },
};

export function loadFonts(fonts: Array<FONT_IDS>): Promise<void> {
  const fontsToLoad = uniq(fonts);
  const googleFont: Array<FontGoogleDetailsType> = fontsToLoad
    .filter((font) => FONTS_DETAILS[font].type == 'google')
    .map((font) => FONTS_DETAILS[font] as FontGoogleDetailsType);

  const customFonts: Array<FontCustomDetailsType> = fontsToLoad
    .filter((font) => FONTS_DETAILS[font].type == 'custom')
    .map((font) => FONTS_DETAILS[font] as FontCustomDetailsType);

  let config = {};
  if (googleFont.length > 0) {
    config = Object.assign(config, {
      google: {
        families: googleFont.map((font) => font.nameToLoad),
      },
    });
  }
  if (customFonts.length > 0) {
    config = Object.assign(config, {
      custom: {
        families: customFonts.map((font) => font.nameToLoad),
        urls: customFonts.map((font) => font.css),
      },
    });
  }

  return FontUtils.loadFont(config);
}

export function loadGoogleFonts(
  fonts: Array<string>,
  params?: any,
  onActive?: () => void,
  fontActive?: (fontFamily: string, fvd: string) => void,
  inactive?: () => void,
  fontInactive?: (fontFamily: string, fvd: string) => void,
): Promise<void> {
  let config = {};
  config = Object.assign(config, {
    google: {
      families: fonts,
      ...params,
    },
  });

  return FontUtils.loadFont(config, onActive, fontActive, inactive, fontInactive);
}
