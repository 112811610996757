import React, { useState } from 'react';
import { Select, Row, Col, Space, ImageV2 } from '@premagic/myne';
import { ClientWebsiteReligiousClipArtService, ClientWebsiteService } from '@premagic/core';

const ClipArtOptions = Object.values(ClientWebsiteReligiousClipArtService.RELIGIOUS_CLIP_ARTS).map((item) => ({
  label: item.label,
  value: item.id,
  thumbnail: item.folderPath.length
    ? ClientWebsiteReligiousClipArtService.getReligiousClipartImageUrl(item.folderPath)
    : '',
}));

export function getClientWebsiteReligiousClipArt(id?: ClientWebsiteReligiousClipArtService.RELIGIOUS_CLIP_ARTS_IDS) {
  return ClipArtOptions.find((clipart) => clipart.value == id);
}

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  name: string;
  defaultValue?: number;
};

export function ClientWebsiteReligiousClipArtSelectInput(props: Props) {
  const { clientWebsite, defaultValue, ...elementProps } = props;
  const selectedValue = getClientWebsiteReligiousClipArt(defaultValue);
  const [selectedClipArtId, setSelectedClipArtId] = useState(Number(defaultValue));
  const selectedReligiousClipArtThumbnailUrl =
    ClientWebsiteReligiousClipArtService.religiousCliparItemDetails[selectedClipArtId][
      ClientWebsiteReligiousClipArtService.RELIGIOUS_CLIP_ART_ITEMS_IDS.ITEM_1
    ].imgSrc;
  return (
    <Row>
      <Col size={4}>
        <Select
          {...elementProps}
          value={selectedValue}
          options={ClipArtOptions}
          isMulti={false}
          onChange={(selectedItem) => setSelectedClipArtId(Number(selectedItem?.value))}
        />
      </Col>
      <Space size={16} />
      <Col size={8}>
        {selectedReligiousClipArtThumbnailUrl && (
          <ImageV2 cssStyle={{ width: '120px' }} src={selectedReligiousClipArtThumbnailUrl} alt="clipart" />
        )}
      </Col>
    </Row>
  );
}

type ClientWebsiteReligiousClipArtTextGroupValueProps = {
  value: number;
};

export function ClientWebsiteReligiousClipArtTextGroupValue(props: ClientWebsiteReligiousClipArtTextGroupValueProps) {
  const { value } = props;
  const clipArtObj = getClientWebsiteReligiousClipArt(value);
  return <div>{clipArtObj?.label}</div>;
}
