import { push } from 'connected-react-router';
import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, BrowserUrlUtils } from '@premagic/utils';
import { EventTrackerService, Schemas } from '@premagic/core';
import { ALBUM_CREATOR_URLS, AlbumServices } from '@premagic/album-creator';

import { LOADINGS, MODALS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../common/ModalDuck';
import { entitiesDataSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM');

export const setAlbumData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addAlbumData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const getAllAlbum = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId) => {
  const loadingKey = LOADINGS.ALBUM.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  AlbumServices.fetchAlbumsForProject(projectId)
    .then((response) => {
      const normalizedData = normalize(response.results, Schemas.AlbumsSchema);
      dispatch(setAlbumData(dispatch, normalizedData.entities.albums));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const createAlbum = createAction(getActionType('DATA', 'NEW'), (dispatch, data) => {
  const loadingKey = LOADINGS.ALBUM.ADD_NEW_ALBUM;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.ALBUM_CREATOR.CREATE);

  AlbumServices.createNewAlbumService(data)
    .then((response) => {
      dispatch(addAlbumData(dispatch, response));
      const editUrl = BrowserUrlUtils.getRouteUrlFor(ALBUM_CREATOR_URLS.ALBUM.EDIT, {
        albumId: response.id,
      });
      dispatch(push(editUrl));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(toggleModalVisibility(dispatch, MODALS.ALBUM.ADD_NEW_ALBUM, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});
type AlbumsItemType = IMap<string, AlbumServices.AlbumType>;
type StateType = {
  items: AlbumsItemType;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setAlbumData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addAlbumData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState,
);

export const albumsDataSelector = createSelector(entitiesDataSelector, (entities) => entities.albums as StateType);

export const albumsEntitiesSelector = createSelector(albumsDataSelector, (albums) => albums.items);

export const albumsValueSelector = createSelector(albumsEntitiesSelector, (albums) => albums.valueSeq().toArray());

export const albumsForProjectSelector = (projectId: string) =>
  createSelector(albumsEntitiesSelector, (albums) =>
    albums
      .filter((album) => album.project_id === projectId)
      .valueSeq()
      .toArray(),
  );
