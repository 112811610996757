import { connect } from 'react-redux';
import { FolderMetaService } from '@premagic/core';
import { clientWishesSelector, removeClientWish, saveClientWishStatus } from './ClientWishDataDuck';
import ClientWishApproval from './ClientWishApproval';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { folderMetaDataForFolderSelector } from '../../projects/folder-meta/FoldersMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    folderId: string;
    projectId: string;
  },
) {
  const { id, folderId, projectId } = ownProps;
  const folderMeta = folderMetaDataForFolderSelector(folderId)(state);

  return {
    id,
    clientWish: clientWishesSelector(state)[id],
    projectId,
    folderId,
    isSaving: isLoadingSelector(LOADINGS.CLIENT_WISH.UPDATE)(state),
    wishesReviewRequired: folderMeta[FolderMetaService.FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED] ?? true,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateWishStatus: (
      options: {
        wishId: string;
        projectId: string;
        folderId: string;
      },
      status,
    ) => {
      dispatch(saveClientWishStatus(dispatch, options, status));
    },
    deleteWish: (options: { wishId: string; projectId: string; folderId: string }) => {
      dispatch(removeClientWish(dispatch, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWishApproval);
