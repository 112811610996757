import { IntegrationService } from '@premagic/core';
import { Button, BUTTON_SIZE, BUTTON_STYLES, Icon, ICON_SIZES, Row, Space } from '@premagic/myne';

type Props = {
  showEventsList: (platform: IntegrationService.INTEGRATION_PLATFORMS) => void;
  integrationSettings: IntegrationService.IntegrationClientSettingsType;
  isLoading: boolean;
  platform: IntegrationService.INTEGRATION_PLATFORMS;
};

export default function IntegrationImportEventButton(props: Props) {
  const { showEventsList, integrationSettings, isLoading, platform } = props;

  const { title, svgIconName } = IntegrationService.INTEGRATION_PLATFORMS_DATA[platform];

  function handleClick() {
    showEventsList(platform);
  }

  return (
    <Button
      type="button"
      style={BUTTON_STYLES.TERTIARY}
      size={BUTTON_SIZE.LG}
      onClick={handleClick}
      isLoading={isLoading}
      block
    >
      <Row vcenter center>
        Import from
        <Space size={1} />
        <Icon name={svgIconName} size={ICON_SIZES.MD} />
        <Space size={1} />
        {title}
      </Row>
    </Button>
  );
}
