import React from 'react';

import {
  BUTTON_ICON_STYLES,
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemAction,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroupValue,
} from '@premagic/myne';
import { EventSponsorsService } from '@premagic/core';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';
import EventSponsorOptionsMenuContainer from './dropdown-menu/EventSponsorOptionsMenuContainer';

type Props = {
  sponsor: EventSponsorsService.EventSponsorType;
  projectId: string;
  id: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_SPONSOR]: boolean;
    [PERMISSIONS.EVENT_SPONSOR_MANAGEMENT]: boolean;
  };
  focus?: boolean;
  showEditSponsor: (id: string) => void;
};

export default function EventSponsorItem(props: Props): JSX.Element {
  const { sponsor, id, projectId, hasPermissions, focus, showEditSponsor } = props;
  const { name, status, phone, email } = sponsor;
  const isInDraft = status === EventSponsorsService.EVENT_SPONSORS_PUBLISH_STATUS.DRAFT;
  const hasPermissionsToManageSponsors = hasPermissions[PERMISSIONS.EVENT_SPONSOR_MANAGEMENT];

  return (
    <ListItem highlight={focus}>
      <Col>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
          {name}
          <Space size={2} />
          {isInDraft && (
            <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DANGER}>
              HIDDEN
            </Text>
          )}
        </Text>
      </Col>
      {email && phone && (
        <Col>
          <SimpleLink href={`mailto:${email}`} isExternalLink={false}>
            <Row vcenter>
              <Color shade={COLOR_SHADES.LIGHT} inline>
                <Icon name="mail" size={ICON_SIZES.SM} />
              </Color>
              <Space size={2} />
              <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={email} />
            </Row>
          </SimpleLink>
          <Space vertical size={1} />
          <SimpleLink href={`tel:${phone}`} isExternalLink={false}>
            <Row vcenter>
              <Color shade={COLOR_SHADES.LIGHT} inline>
                <Icon name="phone" size={ICON_SIZES.SM} />
              </Color>
              <Space size={2} />
              <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={phone} />
            </Row>
          </SimpleLink>
        </Col>
      )}

      <ListItemAction>
        <ButtonIcon
          disabled={!hasPermissionsToManageSponsors}
          disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_SPONSOR_MANAGEMENT]}
          style={BUTTON_ICON_STYLES.SECONDARY}
          size={BUTTON_ICONS_SIZES.SM}
          title="Edit"
          onClick={() => showEditSponsor(id)}
        >
          <Icon name="edit_2" size={ICON_SIZES.SM} />
        </ButtonIcon>

        <Space size={2} />
        <EventSponsorOptionsMenuContainer sponsorId={id} projectId={projectId} />
      </ListItemAction>
    </ListItem>
  );
}
