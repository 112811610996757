import { StorePricingService } from '@premagic/core';
import { connect } from 'react-redux';
import { storePricingDataSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import PriceSetterListItem from './PriceSetterListItem';

function mapStateToProps(
  state,
  ownProps: { subproductId: StorePricingService.SUBPRODUCTS_IDS; selectedCurrencyCode?: string; defaultPrice?: number },
) {
  const { subproductId, selectedCurrencyCode, defaultPrice } = ownProps;

  const { name: subproductName, cost } = StorePricingService.SUBPRODUCTS_DETAILS[subproductId];

  const storePricingData = storePricingDataSelector(state);

  const productPriceFromClientSettings = storePricingData && storePricingData[subproductId];

  return {
    subproductId,
    subproductName,
    cost,
    price: defaultPrice || productPriceFromClientSettings || 0,
    currencyCode: selectedCurrencyCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceSetterListItem);
