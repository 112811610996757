import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { SponsorCreativesService } from '@premagic/core';

import { LOADINGS } from '../../../../../../common/Constants';
import SponsorFilesSelector from './SponsorFilesSelector';
import { filesSelector } from '../../../images/FilesDataDuck';

const COMMON_KEY = LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL;
function mapStateToProps(
  state,
  ownProps: {
    name: string;
    defaultValue?: string;
    placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  },
) {
  const { name, defaultValue, placement } = ownProps;
  return {
    name,
    defaultValue,
    modalOptions: ModalDuck.modalOptionsSelector<
      Record<
        string,
        {
          name: string;
          fileId?: string;
        }
      >
    >(COMMON_KEY)(state),
    files: filesSelector(state),
    placement,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openFilePicker: (name, placement) => {
      dispatch(
        ModalDuck.setModalOptions(dispatch, COMMON_KEY, {
          [name]: {
            name,
            placement,
          },
        }),
      );
      dispatch(ModalDuck.toggleModalVisibility(dispatch, COMMON_KEY, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorFilesSelector);
