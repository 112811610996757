import { Card, Col, List, ListItem, Text, TEXT_SIZE, TEXT_BOLDNESS, Row, ListItemHeader, Space } from '@premagic/myne';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import APP_URLS from '../../../../services/AppRouteURLService';
import UserNamesContainer from '../../users/list/UserNamesContainer';
import { WorkflowType } from '../service/WorkflowService';

type WorkflowListItemProps = {
  workflowItem: WorkflowType;
  isLoading: boolean;
};

function WorkflowListItem(props: WorkflowListItemProps) {
  const { workflowItem, isLoading } = props;
  const { name, created_at: createdAt, added_by: addedBy, id: workflowId, id: key } = workflowItem;

  const workflowUrlForDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_WORKFLOW.EDIT, {
    workflowId,
  });

  return (
    <ListItem to={workflowUrlForDetails} key={key}>
      <Col size={5}>
        <Text block size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
          {name}
        </Text>
      </Col>

      <Col size={4}>
        <Space size={3} />
        <Text muted>{SimpleDateUtils.humanizeDate(createdAt as string, true)}</Text>
      </Col>
      <Col rightAlighed size={3}>
        <Row vcenter>
          <Space size={6} />
          <UserNamesContainer userIds={[addedBy]} avatarStyle />
        </Row>
      </Col>
      {/* Might add a option menu on list item if required */}
      {/* <Space size={6} />
      <ListItemAction>
        {isLoading ? (
          <LoadingDots size="sm" />
        ) : (
          <Dropdown>
            <DropdownIcon title="Workflow options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
              <Icon name="more_horizontal" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
            </DropdownIcon>
            <Menu>
              <MenuItem onClick={() => alert('duplicate')}>
                <MenuItemIcon name="copy" />
                Duplicate
              </MenuItem>
              <MenuItem onClick={() => alert('delete')}>
                <MenuItemIcon name="trash" />
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </ListItemAction> */}
    </ListItem>
  );
}

type WorkflowListItemsProps = {
  workflowList: Array<WorkflowType>;
  isLoading: boolean;
};

export function WorkflowListItems(props: WorkflowListItemsProps) {
  const { workflowList, isLoading } = props;

  if (workflowList?.length === 0) {
    return (
      <div>
        <Text muted>You haven&apos;t added any Workflow templates yet</Text>
      </div>
    );
  }

  return (
    <Card>
      <List>
        <ListItemHeader>
          <Row>
            <Col size={5}>
              <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                Name
              </Text>
            </Col>
            <Col size={4}>
              <Row>
                <Space size={3} />
                <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                  Created on
                </Text>
              </Row>
            </Col>
            <Col rightAlighed size={3}>
              <Row>
                <Space size={6} />
                <Text block size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                  Created by
                </Text>
              </Row>
            </Col>
          </Row>
        </ListItemHeader>
        {workflowList?.map((workflowItem) => (
          <WorkflowListItem workflowItem={workflowItem} isLoading={isLoading} key={workflowItem.id.toString()} />
        ))}
      </List>
    </Card>
  );
}
