import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import EditSponsorCreativePanel from './EditSponsorCreativePanel';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { creativeIdForEditSelector, setEditOfCreative } from '../../event-sponsors/EventSponsorPageDuck';
import { sponsorCreativesSelector, updateSponsorCreative } from '../SponsorCreativeDataDuck';

const PANEL_KEY = LOADINGS.SPONSOR_CREATIVES.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.SPONSOR_CREATIVES.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const creativeId = creativeIdForEditSelector(state);

  return {
    projectId,
    creativeId,
    creative: creativeId ? sponsorCreativesSelector(state)[creativeId] : undefined,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCreative: (options, data) => {
      dispatch(updateSponsorCreative(dispatch, options, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
      dispatch(setEditOfCreative(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditSponsorCreativePanel);
