import { ClientsService } from '@premagic/core';
import {
  COLOR_SHADES,
  Form,
  Icon,
  ICON_SIZES,
  Input,
  LoadingDots,
  Modal,
  MODAL_ALIGNMENT,
  MODAL_SIZES,
  Row,
  ScrollableContainer,
  SCROLLABLE_CONTAINER_SIZE,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import CRMClientSearchItemContainer from './CRMClientSearchItemContainer';

type SearchResultsProps = {
  isLoading: boolean;
  clients: Array<ClientsService.ClientType>;
  searchString: string;
  error: {
    code: string;
  };
};

function SearchResults(props: SearchResultsProps) {
  const { isLoading, searchString, clients, error } = props;

  if (isLoading) {
    return (
      <div>
        <Space vertical size={10} />
        <LoadingDots size="sm" />
        <Space vertical size={10} />
      </div>
    );
  }
  if (error?.code === 'no-events') {
    return (
      <div>
        <Space vertical size={10} />
        <Row vcenter center>
          <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.LIGHT}>
            Oops... the client doesn't have an event
          </Text>
        </Row>
        <Space vertical size={5} />
        <Row vcenter center>
          <Text size={TEXT_SIZE.SIZE_4} color={COLOR_SHADES.LIGHT} muted>
            Try again with a another client
          </Text>
        </Row>
        <Space vertical size={7} />
      </div>
    );
  }
  if (searchString.length !== 0 && clients.length === 0) {
    return (
      <div>
        <Space vertical size={9} />
        <Row vcenter center>
          <Text size={TEXT_SIZE.SIZE_2}>
            We couldn't find any clients with{' '}
            <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD}>
              {searchString}
            </Text>
          </Text>
        </Row>
        <Space size={5} vertical />
        <Row vcenter center>
          <Text size={TEXT_SIZE.SIZE_5} muted>
            Search with your client's name, email and phone
          </Text>
        </Row>
        <Space vertical size={6} />
      </div>
    );
  }

  if (searchString.length > 0 && clients.length > 0) {
    return (
      <ScrollableContainer direction="vertical" size={SCROLLABLE_CONTAINER_SIZE.MD} showShadow>
        <Space vertical size={1} />
        {clients.map((client) => (
          <div>
            <CRMClientSearchItemContainer client={client} />
          </div>
        ))}
      </ScrollableContainer>
    );
  }

  return (
    <div>
      <Space vertical size={12} />
      <Row vcenter center>
        <Icon name="search" size={ICON_SIZES.XLG} />
      </Row>
      <Space size={3} vertical />
      <Row vcenter center>
        <Text size={TEXT_SIZE.SIZE_2}>
          Search with your client's{' '}
          <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD}>
            name, email and phone
          </Text>
        </Text>
      </Row>
      <Space vertical size={6} />
    </div>
  );
}

type Props = {
  searchCRMClients: (searchString: string) => void;
  clients: Array<ClientsService.ClientType>;
  isLoading: boolean;
  isClientSearchModalOpen: boolean;
  closeCRMClientSearchModal: () => void;
  error: {
    code: string;
  };
};

export default function CRMClientSearch(props: Props) {
  const { clients, searchCRMClients, isLoading, isClientSearchModalOpen, closeCRMClientSearchModal, error } = props;
  const [searchString, setSearchString] = useState<string>('');

  const debouncedSearchCRMClients = useMemo(() => debounce(searchCRMClients, 500), [searchCRMClients]);

  // debouncing setSearchString so that the searchString don't gets shown on the SearchResults when no network calls are made
  const debouncedSetSearchString = useMemo(() => debounce(setSearchString, 500), [setSearchString]);

  return (
    <div>
      <Modal
        show={isClientSearchModalOpen}
        close={closeCRMClientSearchModal}
        size={MODAL_SIZES.MD}
        alignModalContent={MODAL_ALIGNMENT.TOP}
        showCloseButton
      >
        <div>
          <Form onSubmit={(formData) => debouncedSearchCRMClients(formData.data.search as string)}>
            <Input
              name="search"
              buttonIcon={<Icon name="search" size={ICON_SIZES.MD} />}
              iconPlacement="left"
              placeholder="Search Clients"
              autoFocus
              onChange={(e) => {
                debouncedSetSearchString(e.target.value);
                if (e.target.value.length >= 3) {
                  debouncedSearchCRMClients(e.target.value);
                }
              }}
            />
          </Form>
          <SearchResults isLoading={isLoading} clients={clients} searchString={searchString} error={error} />
        </div>
      </Modal>
    </div>
  );
}
