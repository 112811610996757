import { FormResponseType, MenuItem, MenuItemIcon } from '@premagic/myne';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../../crm/users/UserPermissionService';

type Props = {
  disabled?: boolean;
  saveCustomEventFromField: (projectId: string, formFieldId: string, formResponse: FormResponseType) => void;
  projectId: string;
  formFieldId: string;
  formFieldData: Record<string, any>;
};

export default function ShowOrHideOnRegistrationMenuItem(props: Props) {
  const { projectId, formFieldId, formFieldData, disabled, saveCustomEventFromField } = props;

  const shownInRegistration = formFieldData.visibility === true;

  function showFormFieldInRegistration(value) {
    saveCustomEventFromField(projectId, formFieldId, { data: { ...formFieldData, visibility: value } });
  }

  if (shownInRegistration) {
    return (
      <MenuItem
        disabled={disabled}
        disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]}
        onClick={() => showFormFieldInRegistration(false)}
      >
        <MenuItemIcon name="eye_off" />
        Hide on event registration
      </MenuItem>
    );
  }
  return (
    <MenuItem
      disabled={disabled}
      disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]}
      onClick={() => showFormFieldInRegistration(true)}
    >
      <MenuItemIcon name="eye" />
      Show on event registration
    </MenuItem>
  );
}
