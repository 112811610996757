import { connect } from 'react-redux';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { removeOccasion } from '../OccasionsDataDuck';
import OccasionDeleteDialog from './OccasionDeleteDialog';

function mapStateToProps(state, ownProps: { occasionId: string; eventId: string }) {
  const { occasionId, eventId } = ownProps;

  return {
    occasionId,
    eventId,
    isLoading: isLoadingSelector(LOADINGS.OCCASIONS.DELETE)(state),
    isDialogShown: isModalOpenSelector(LOADINGS.OCCASIONS.SHOW_DELETE_DIALOG(occasionId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (occasionId: string, show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_DELETE_DIALOG(occasionId), show));
    },
    deleteOccasion: (occasionId, eventId) => {
      dispatch(removeOccasion(dispatch, occasionId, eventId));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_DELETE_DIALOG(occasionId), false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OccasionDeleteDialog);
