import { connect } from 'react-redux';
import { customerLeadsStatsSelector } from './CustomerLeadsDataDuck';
import CustomerLeadsTabs from './CustomerLeadsTabs';

function mapStateToProps(state, ownProps) {
  const { activeStatus = 'NEW' } = ownProps;

  return {
    activeStatus,
    stats: customerLeadsStatsSelector(state),
  };
}

export default connect(mapStateToProps)(CustomerLeadsTabs);
