import { QRCodeBackgroundPosterService } from '@premagic/core';
import {
  BUTTON_TILE_SIZE,
  ButtonTile,
  COLOR_SHADES,
  Color,
  ColorCircle,
  Grid,
  GridLayout,
  ICON_SIZES,
  Icon,
  MODAL_SIZES,
  Modal,
  ModalTitle,
  Position,
  Row,
  Space,
  Tooltip,
} from '@premagic/myne';
import React from 'react';
import QRCodeBackgroundPosterContainer from '../../background-poster/QRCodeBackgroundPosterContainer';

type Props = {
  show: boolean;
  close: () => void;
  projectId: string;
  folderId: string;
  backgroundPoster: QRCodeBackgroundPosterService.QRCodePoster;
  colors?: QRCodeBackgroundPosterService.PosterColorType;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  updatePoster: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    posterItemId?: QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS,
  ) => void;
};

export default function QRCodeBackgroundPosterSelectorModal(props: Props) {
  const { show, close, projectId, folderId, backgroundPoster, colors, qrCodeDesigns, updatePoster } = props;

  function handlePosterSelect(posterId) {
    updatePoster(projectId, folderId, qrCodeDesigns, posterId);
    close();
  }

  return (
    <Modal show={show} close={close} size={MODAL_SIZES.XL} showFooterCloseButton>
      <div>
        <ModalTitle>Background Poster</ModalTitle>

        <Grid colSize={134}>
          {Object.values(QRCodeBackgroundPosterService.QR_CODE_POSTERS).map((item) => (
            <ButtonTile
              key={item.id}
              onClick={() => handlePosterSelect(item.id)}
              size={BUTTON_TILE_SIZE.SM_XS}
              active={backgroundPoster.id === item.id}
            >
              {item.imageType === 'svg' && (
                <Position align="top-right">
                  <Space size={1}>
                    <Row columnDirection vcenter>
                      <Tooltip message="Background poster color is customizable">
                        <ColorCircle />
                      </Tooltip>
                      {item?.customIllustration && (
                        <>
                          <Space vertical size={1} />
                          <Tooltip message="Custom illustration">
                            <Color inline shade={COLOR_SHADES.LIGHT}>
                              <Icon name="image" size={ICON_SIZES.SM_XS} />
                            </Color>
                          </Tooltip>
                        </>
                      )}
                    </Row>
                  </Space>
                </Position>
              )}
              <QRCodeBackgroundPosterContainer posterId={item.id} colors={colors} isPreview />
            </ButtonTile>
          ))}
        </Grid>
      </div>
    </Modal>
  );
}
