import { FormGroup, FormLabel, INPUT_SIZES, Select, Text, TEXT_SIZE } from '@premagic/myne';
import { BADGE_PUNCH_HOLE_STYLE, BADGE_PUNCH_HOLE_STYLE_DETAILS } from '@premagic/poster-creator';
import { useEffect } from 'react';

type Props = {
  projectId: string;
  posterId: string;
  punchHoleStyle: BADGE_PUNCH_HOLE_STYLE;
  setPunchHoleStyle: (punchHoleStyle: BADGE_PUNCH_HOLE_STYLE) => void;
};
export default function EventPosterTextColorSelector(props: Props) {
  const { projectId, posterId, setPunchHoleStyle, punchHoleStyle = BADGE_PUNCH_HOLE_STYLE.CIRCLE } = props;
  const options = Object.entries(BADGE_PUNCH_HOLE_STYLE_DETAILS).map(([key, value]) => ({
    label: value.title,
    value: key,
  }));
  const defaultValue = options.find((option) => option.value === punchHoleStyle);

  useEffect(() => {
    setPunchHoleStyle(punchHoleStyle); // for the first load
  }, [setPunchHoleStyle]);

  return (
    <FormGroup>
      <FormLabel>Punching area</FormLabel>
      <Select
        size={INPUT_SIZES.SM}
        name=""
        options={options}
        onChange={(option) => {
          if (option?.value) setPunchHoleStyle(option.value as BADGE_PUNCH_HOLE_STYLE);
        }}
        value={defaultValue}
        isMulti={false}
      />
      <Text muted size={TEXT_SIZE.SIZE_5}>
        The punch marks serve as a guide & won't appear on the final printed badge.
      </Text>
    </FormGroup>
  );
}
