import { ModalDuck } from '@premagic/common-ducks';
import { EventExhibitorService, Schemas } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { pagesSelector } from '../../../../reducers/selectors';
import { toastMessage } from '../../../../reducers/ToastStore';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_EXHIBITORS');

export const setEventExhibitorData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const addEventExhibitorData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

const updateEventExhibitorData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const removeEventExhibitorData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const getEventExhibitorsForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_EXHIBITOR.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventExhibitorService.fetchEventExhibitorsForProject(projectId)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.EventExhibitors);
        dispatch(setEventExhibitorData(dispatch, normalizedData.entities.exhibitors));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_EXHIBITOR_FETCH_ERROR', e);
      });
  },
);

export const addEventExhibitor = createAction(
  getActionType('ACTION', 'CREATE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: EventExhibitorService.EventExhibitorType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_EXHIBITOR.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    EventExhibitorService.createEventExhibitor(projectId, data)
      .then((response) => {
        dispatch(addEventExhibitorData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_ADD_PANEL, false));
        dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
        toastMessage('success', 'Exhibitor added');
        setTimeout(() => {
          dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_ADD_PANEL, true));
        }, 1);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_EXHIBITOR_ADD_ERROR', e);
      });
  },
);

export const updateEventExhibitor = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; exhibitorId: string },
    formResponse: FormResponseType & {
      data: EventExhibitorService.EventExhibitorType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_EXHIBITOR.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    EventExhibitorService.updateEventExhibitor(options, data)
      .then((response) => {
        dispatch(updateEventExhibitorData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_EXHIBITOR.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_EXHIBITOR_FAILED', e);
      });
  },
);

export const removeEventExhibitor = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, projectId: string, exhibitorId: string) => {
    dispatch(removeEventExhibitorData(dispatch, exhibitorId));
    EventExhibitorService.deleteEventExhibitor(projectId, exhibitorId).then((data) => data);
  },
);

type EventExhibitorItemType = IMap<string, EventExhibitorService.EventExhibitorType>;
type StateType = {
  items: EventExhibitorItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setEventExhibitorData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventExhibitorData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateEventExhibitorData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeEventExhibitorData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

export const eventExhibitorDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.eventExhibitor.data.exhibitors as StateType,
);

export const eventExhibitorEntitiesSelector = createSelector(
  eventExhibitorDataSelector,
  (exhibitors) => exhibitors.items,
);

export const eventExhibitorsIdsSelector = createSelector(eventExhibitorEntitiesSelector, (items) =>
  items.keySeq().toArray(),
);

export const eventExhibitorsSelector = createSelector(eventExhibitorEntitiesSelector, (exhibitors) =>
  exhibitors.toJSON(),
);
