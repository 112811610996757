import {
  Card,
  Col,
  COLOR_SHADES,
  Grid,
  List,
  ListItem,
  LoadingDots,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import ReportStatsItem from '../../crm/events/sponsors-report/sponsor-report-card/ReportStatsItem';
import PosterAdvocateListItemContainer from './PosterAdvocateListItemContainer';

type Props = {
  isLoading: boolean;
  projectId: string;
  initData: (projectId: string) => void;
  advocateIds: Array<string>;
  advocatesMetrics: Record<'advocates' | 'referrals' | 'roi', { primary: number }>;
};

export default function PosterAdvocateReportLayout(props: Props): JSX.Element {
  const { initData, isLoading, projectId, advocateIds, advocatesMetrics } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  if (isLoading)
    return (
      <Row center>
        <LoadingDots />
      </Row>
    );

  return (
    <div>
      <Space vertical size={8} />
      <Row alignItems="baseline">
        <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
          Event Advocates
        </Text>
        <Space size={1} />
        <Text>via Event Marketing Posters</Text>
      </Row>
      <Space vertical size={2} />
      {advocateIds.length === 0 ? (
        <Space>
          <Text muted block>
            No advocates yet
          </Text>
        </Space>
      ) : (
        <div>
          <Grid colSize={200}>
            <div>
              <Card>
                <Space>
                  <ReportStatsItem
                    title="Advocates"
                    count={advocatesMetrics.advocates.primary}
                    type={TEXT_GROUP_TYPES.NUMBER}
                  />
                </Space>
              </Card>
            </div>
            <div>
              <Card>
                <Space>
                  <ReportStatsItem
                    title="Referrals"
                    count={advocatesMetrics.referrals.primary}
                    type={TEXT_GROUP_TYPES.NUMBER}
                  />
                </Space>
              </Card>
            </div>
            <div>
              <Card>
                <Space>
                  <ReportStatsItem title="ROI" count={advocatesMetrics.roi.primary} type={TEXT_GROUP_TYPES.CURRENCY} />
                </Space>
              </Card>
            </div>
          </Grid>
          <Space vertical size={4} />
          <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            Your top advocates
          </Text>
          <Space vertical size={2} />
          <Card>
            <List>
              <ListItem>
                <Col size={4}>
                  <Text color={COLOR_SHADES.LIGHT}>Advocate</Text>
                </Col>
                <Col size={1}>
                  <Text color={COLOR_SHADES.LIGHT} block center>
                    Referrals
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Text color={COLOR_SHADES.LIGHT}>Post</Text>
                </Col>
              </ListItem>
              <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} showShadow direction="vertical">
                {advocateIds.map((id) => (
                  <PosterAdvocateListItemContainer key={id} id={id} />
                ))}
              </ScrollableContainer>
            </List>
          </Card>
        </div>
      )}
    </div>
  );
}
