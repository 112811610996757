import React, { useMemo } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Icon,
  ICON_SIZES,
  LoadingSpin,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { isArray } from 'lodash';
import { USER_SERVICE_TYPE_DETAILS, UserType } from '../UsersService';
import { UsersSelectInputOptionType } from '../UsersDataDuck';

interface UserSelectProps {
  users: Array<UserType>;
  usersSelectOptions: UsersSelectInputOptionType;
  isLoading: boolean;
  name: string;
  showAddNewUserPanel: () => void;
  onChange?: (options) => void;
}

interface SingleUserSelectProps extends UserSelectProps {
  isMulti: true;
  defaultValue?: string;
}

interface MultiUserSelectProps extends UserSelectProps {
  isMulti: true;
  defaultValue?: string | Array<string>;
}

export function UserSelect(props: SingleUserSelectProps | MultiUserSelectProps): JSX.Element {
  const {
    users,
    isLoading,
    name,
    defaultValue,
    isMulti = false,
    usersSelectOptions,
    showAddNewUserPanel,
    onChange,
  } = props;
  const selectedUsersValues: Array<{ label: string; value: string }> | { label: string; value: string } | null =
    useMemo(() => {
      if (!defaultValue) return null;
      if (isArray(defaultValue)) {
        return defaultValue.map((user) => {
          const userObj = users.find((item) => item.id === user);
          if (!userObj)
            return {
              label: 'unknown',
              value: 'unknown',
            };
          return {
            label: userObj.name,
            value: userObj.id,
            icon: USER_SERVICE_TYPE_DETAILS[userObj.service_role].icon,
          };
        });
      }

      const userObj = users.find((item) => item.id === defaultValue);
      if (!userObj)
        return {
          label: 'unknown',
          value: 'unknown',
        };
      return { label: userObj.name, value: userObj.id };
    }, [defaultValue, isMulti]);

  if (isLoading) return <LoadingSpin />;

  return (
    <div>
      <Row vcenter>
        <Col size={10}>
          {isMulti ? (
            <Select
              isMulti
              name={name}
              onChange={onChange}
              options={usersSelectOptions}
              value={selectedUsersValues as Array<{ label: string; value: string }>}
            />
          ) : (
            <Select
              isMulti={false}
              name={name}
              onChange={onChange}
              options={usersSelectOptions}
              value={selectedUsersValues as { label: string; value: string }}
            />
          )}
        </Col>
        <Space />
        <Col size={2} rightAlighed>
          <Button size={BUTTON_SIZE.SM} style={BUTTON_STYLES.DEFAULT} onClick={showAddNewUserPanel}>
            <Icon name="plus" size={ICON_SIZES.SM} /> new user
          </Button>
        </Col>
      </Row>
    </div>
  );
}
