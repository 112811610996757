import { FolderService } from '@premagic/core';
import { COLOR_SHADES, Gallery, Space, TEXT_COLORS, TEXT_SIZE, Text } from '@premagic/myne';
import { DAM_GALLERY_SORT_BY } from '../filters/DAMLibraryFilterTypes';
import DAMFilesThumbnailsContainer from './DAMFilesThumbnailsContainer';

type Props = {
  fileIdsSortedByRank: Array<string>;
  filters: string;
  sortBy: DAM_GALLERY_SORT_BY;
  fileIdsGroupedByFolder: Record<string, Array<string>>;
  folderData: Record<string, FolderService.FolderType>;
};

export default function DAMFilesGallery(props: Props) {
  const { filters, fileIdsSortedByRank, sortBy, fileIdsGroupedByFolder, folderData } = props;

  if (sortBy === DAM_GALLERY_SORT_BY.FOLDER) {
    return (
      <>
        {Object.entries(fileIdsGroupedByFolder).map(([folderId, fileIds], idx) => (
          <div key={folderId}>
            <Space size={2}>
              <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
                {folderData?.[folderId]?.folder_name || `folder ${idx + 1}`}
              </Text>
            </Space>
            <Gallery scaleSizeWithScreen={false}>
              {fileIds.map((fileId) => (
                <DAMFilesThumbnailsContainer key={fileId} fileId={fileId} filters={filters} folderId={folderId} />
              ))}
            </Gallery>
            <Space vertical size={6} />
          </div>
        ))}
      </>
    );
  }

  // sort by DAM_GALLERY_SORT_BY.RELEVANCE
  return (
    <Gallery scaleSizeWithScreen={false}>
      {fileIdsSortedByRank.map((fileId) => (
        <DAMFilesThumbnailsContainer key={fileId} fileId={fileId} filters={filters} />
      ))}
    </Gallery>
  );
}
