import { HttpUtils } from '@premagic/utils';
import { FOLDER_STATUS } from './FolderService';
import { CLIP_ART_IDS } from './ClientWebsiteClipArtService';
import { RELIGIOUS_CLIP_ARTS_IDS } from './ClientWebsiteReligiousClipArtService';
import { BACKGROUND_MUSICS_IDS } from './BackgroundMusicService';
import { FolderFileType } from './FileService';
import { EVENT_CATEGORY } from './EventsService';
import { API_URLS } from './APIURLService';

export const CLIENT_WEBSITE_LINK = {
  BOOK: 'book/',
  EMBED_BOOK: 'embed/book/',
  PHOTO_REGISTER: 'register/',
  CHECKIN: 'checkin/',
  CHECKIN_ZONE_SELFIE: 'checkin/',
  POSTER: {
    CREATE: (scope, id) => `poster/create/${scope}/${id}`,
    EMBED: (scope, id) => `embed/poster/create/${scope}/${id}`,
  },
  REGISTRATION: {
    COMPLETE_REGISTRATION: (registrationId) => `complete-register/${registrationId}`,
  },
  BADGE: {
    PRINT: (badgeId: string, registrationId: string) => `badge/print/${badgeId}/${registrationId}/`,
  },
};

export type ClientWebsiteThemeType = {
  id: number;
  name: string;
  color: {
    background: string;
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
  };
  font: {
    primary_font: string;
    secondary_font: string;
  };
  clipart_id: CLIP_ART_IDS;
  religious_clipart_id: RELIGIOUS_CLIP_ARTS_IDS;
  music_id: BACKGROUND_MUSICS_IDS;
};

export type ClientWebsiteItinerary = {
  id: number;
  name: string;
  details: string;
  venue: {
    location: string;
    location_link: string;
  };
  start_date_time: string;
  end_date_time?: string;
  date_timezone: string;
  time_not_decided: boolean;
  speakers?: Array<string>;
};

export type ClientWebsiteDataType = {
  bride_name: string;
  groom_name: string;
  tagline: string;
  detailed_tagline: string;
  contact: {
    phone: string;
  };
  content: string;
  instruction: string;
  livestream_link: string;
  user_registration_foot_note?: string;
  itineraries?: Array<ClientWebsiteItinerary>;
  metrics?: any;
  client_website_hide_title?: boolean;
};

export type ClientWebsiteFileType = FolderFileType & {
  thumbnail: string;
  public_url: string; // TEMP, we will need to remove this and use location everywhere
};

export type ClientWebsiteFilesType = Record<string, ClientWebsiteFileType>;

export type BasicClientWebsiteType = {
  theme: ClientWebsiteThemeType;
  data: ClientWebsiteDataType;
  files: ClientWebsiteFilesType;
};

export type NewClientWebsiteType = Partial<{
  theme: Partial<ClientWebsiteThemeType>;
  data: Partial<ClientWebsiteDataType>;
  files: Partial<ClientWebsiteFilesType>;
}>;

export type ClientWebsiteType = BasicClientWebsiteType & {
  id: string;
  project_id: string;
  website_id: string;
  status: FOLDER_STATUS;
  share_url?: string;
  event: {
    type?: EVENT_CATEGORY;
  };
};

export function getClientWebsiteApiURL(id: string, projectId: string): string {
  return API_URLS.CLIENT_WEBSITE.DETAILS(projectId, id);
}

export function fetchClientWebsite(id: string, projectId: string): Promise<ClientWebsiteType> {
  return HttpUtils.get(getClientWebsiteApiURL(id, projectId)).then(({ data }) => data);
}

export function createClientWebsite(projectId: string, data: NewClientWebsiteType): Promise<ClientWebsiteType> {
  return HttpUtils.post(API_URLS.CLIENT_WEBSITE.ROOT(projectId), data).then((response) => response.data);
}

export function updateClientWebsite(
  id: string,
  projectId: string,
  data: Partial<ClientWebsiteType>,
): Promise<ClientWebsiteType> {
  return HttpUtils.put(getClientWebsiteApiURL(id, projectId), data, true).then((response) => response.data);
}
export function updateClientWebsiteItineraries(
  id: string,
  projectId: string,
  data: Array<ClientWebsiteItinerary>,
): Promise<Array<ClientWebsiteItinerary>> {
  return updateClientWebsite(id, projectId, { data: { itineraries: data } } as ClientWebsiteType).then(
    (response) => response.data.itineraries as Array<ClientWebsiteItinerary>,
  );
}
