import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { pagesSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_WEBSITE', true);

export const setEditOfItinerary = createAction(
  getActionType('ITINERARY', 'EDIT'),
  (dispatch: Dispatch, id: string | null) => id,
);

export const setLastItineraryEndDate = createAction(
  getActionType('ITINERARY_END', 'EDIT'),
  (
    dispatch: Dispatch,
    data: {
      date: string;
      timezone: string;
    },
  ) => data,
);

type ClientWebsitePageType = {
  editItineraryId?: string;
  lastItineraryEndDate?: {
    date: string;
    timezone: string;
  };
};
const initialState = {
  editItineraryId: undefined,
  lastItineraryEndDate: undefined,
};

export default handleActions(
  {
    [setEditOfItinerary.toString()]: (state, action: { payload }) => ({
      ...state,
      editItineraryId: action.payload,
    }),
    [setLastItineraryEndDate.toString()]: (state, action) => ({
      ...state,
      lastItineraryEndDate: action.payload as any,
    }),
  },
  initialState,
);

export const clientWebsitePageSelector = createSelector(
  pagesSelector,
  (state) => state.clientWebsite.clientWebsite as ClientWebsitePageType,
);
export const itineraryIdForEditSelector = createSelector(clientWebsitePageSelector, (page) => page.editItineraryId);
