import { FileService } from '@premagic/core';
import { BrowserUtils, MediaUtils, useImage } from '@premagic/utils';

import ClassNames from 'classnames';
import justifiedLayout from 'justified-layout';
import React, { CSSProperties, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { Color, COLOR_SHADES } from '../Color/Color';

import { Checkbox } from '../Form/Form';
import { Col, Row, Space } from '../Grid/Grid';
import { Icon, ICON_SIZES, VideoPlayIcon } from '../Icon/Icons';
import { LoadingDots, LoadingUpload } from '../Loading/Loading';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './media.module.css';
import { VIDEO_PLAYER_STYLES, VideoJsPlayer } from './VideoJsPlayer';
import { VIDEO_TYPES } from './VideoPlayer';

interface PlaceholderImageProps {
  classes: string;
  imageClasses: string;
  style?: CSSProperties;
}

export enum ASSET_TYPES {
  VIDEO = 'video',
  PHOTO = 'image',
}

function PlaceholderImage(props: PlaceholderImageProps) {
  const { classes, imageClasses, style } = props;
  return (
    <div className={classes}>
      <div className={imageClasses} style={style} />
    </div>
  );
}

interface ImageProps {
  src: string;
  alt: string;
  onClick?: () => void;
  tumpStyle?: boolean;
  smallStyle?: boolean;
  isMiniLoading?: boolean;
  className?: string;
  showErrorIcon?: boolean;
  style?: CSSProperties;
}

export function Image(props: ImageProps): React.ReactElement {
  const {
    src,
    alt,
    onClick,
    tumpStyle = true,
    smallStyle = false,
    isMiniLoading = false,
    className,
    showErrorIcon = false,
    style,
    ...elementProps
  } = props;
  const classes = ClassNames(styles['image-wrapper'], {
    [styles['image-wrapper--full-screen']]: !tumpStyle,
  });

  const imageClasses = ClassNames(styles.image, className, {
    [styles['image--style-tump']]: tumpStyle,
    [styles['image--style-small']]: smallStyle,
    [styles['image--style-full-screen']]: !tumpStyle,
  });
  const buttonClasses = ClassNames(styles['image-wrapper__button'], {
    [styles['image-wrapper__button--clickable']]: !!onClick,
  });

  const placeHolderImage = <PlaceholderImage imageClasses={imageClasses} classes={classes} style={style} />;
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const { src: loadedImage, isLoading, hasError } = useImage(src);

  if (!inView) {
    return (
      <span ref={ref}>
        <PlaceholderImage imageClasses={imageClasses} classes={classes} style={style} />
      </span>
    );
  }

  return (
    <div className={classes}>
      <button className={buttonClasses} onClick={onClick} ref={ref} type="button">
        {(() => {
          if (hasError)
            return (
              <div style={style} ref={ref}>
                <Row center fullHeight>
                  <Text muted size={TEXT_SIZE.SIZE_5} center>
                    Oops... cant find!
                  </Text>
                </Row>
              </div>
            );
          if (isLoading) {
            return placeHolderImage;
          }

          if (isMiniLoading) {
            return (
              <div className={styles.image__icon}>
                <LoadingUpload />
              </div>
            );
          }

          if (showErrorIcon) {
            return (
              <div className={ClassNames(styles.image__icon, styles['image__icon--left'])}>
                <Tooltip message="Failed to process">
                  <Color shade={COLOR_SHADES.DANGER}>
                    <Icon name="alert_triangle" size={ICON_SIZES.SM} />
                  </Color>
                </Tooltip>
              </div>
            );
          }

          return (
            <img
              {...elementProps}
              title={alt}
              ref={ref}
              className={imageClasses}
              src={loadedImage}
              alt={alt}
              style={style}
            />
          );
        })()}
      </button>
    </div>
  );
}

interface GalleryImageProps {
  src: string;
  alt: string;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
  showCheckbox?: boolean;
  onChangeValue?: (selected: boolean) => void;
  style?: CSSProperties;
  showIconFlag?: boolean;
  favorite?: boolean;
  onFavoriteToggle?: (status: boolean) => void;
}

function GalleryPlaceholderImage(props: { className: string }): React.ReactElement {
  const { className = '' } = props;
  return (
    <PlaceholderImage
      imageClasses={ClassNames(styles['gallery-image-wrapper'])}
      classes={ClassNames(styles['gallery-image'], className)}
    />
  );
}

type GalleryImageItemProps = {
  src: string;
  alt: string;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
};

function GalleryImageItem(props: GalleryImageItemProps): React.ReactElement {
  const { alt, onClick, className = '', src, style } = props;

  const imageClasses = ClassNames(styles['gallery-image'], className);
  const buttonClasses = ClassNames(styles['gallery-image__button']);
  const { src: loadedImage, hasError, isLoading } = useImage(src);

  if (isLoading) return <GalleryPlaceholderImage className={className} />;

  return (
    <button className={buttonClasses} onClick={onClick} type="button">
      {hasError ? (
        <div className={imageClasses}>
          <Space>
            <Text muted>Oops... Missing photo</Text>
          </Space>
        </div>
      ) : (
        <img loading="lazy" src={loadedImage} alt={alt} title={alt} className={imageClasses} style={style} />
      )}
    </button>
  );
}

export function GalleryImage(props: GalleryImageProps): React.ReactElement {
  const {
    alt,
    onClick,
    selected = false,
    className = '',
    showCheckbox = false,
    onChangeValue,
    src,
    style,
    showIconFlag,
    favorite,
    onFavoriteToggle,
  } = props;
  const classes = ClassNames(styles['gallery-image-wrapper']);

  const imageClasses = ClassNames(styles['gallery-image'], className);

  const [ref, inView] = useInView({
    threshold: 0.5,
    delay: 250,
  });

  const imageSrcForScreen = MediaUtils.getNewDynamicImageURL(src, MediaUtils.IMAGE_QUALITY_TYPES.THUMBNAIL_SELECTION, {
    width: 240,
    height: 240,
  });

  if (!inView) {
    return (
      <span ref={ref}>
        <PlaceholderImage imageClasses={imageClasses} classes={classes} />
      </span>
    );
  }

  function getFlagIcon() {
    if (!showIconFlag) return null;
    if (favorite) {
      return (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            if (onFavoriteToggle) onFavoriteToggle(false);
          }}
          className={ClassNames(styles['gallery-image__icon_wrapper'], styles['gallery-image__top-right'])}
        >
          <span
            className={ClassNames(
              styles['gallery-image__icon'],
              styles['gallery-image__icon--with-yellow-bg'],
              'js-fav-icon',
            )}
          >
            <Color shade={COLOR_SHADES.WHITE} fillSolidIcon fillStrokeIcon>
              <Row vcenter>
                <Icon name="flag" size={ICON_SIZES.SM} />
              </Row>
            </Color>
          </span>
        </button>
      );
    }
    return (
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          if (onFavoriteToggle) onFavoriteToggle(true);
        }}
        className={ClassNames(styles['gallery-image__icon_wrapper'], styles['gallery-image__top-right'])}
      >
        <span
          className={ClassNames(
            styles['gallery-image__icon'],
            styles['gallery-image__icon--with-white-bg'],
            'js-fav-icon',
          )}
        >
          <Color shade={COLOR_SHADES.DARKER}>
            <Icon name="flag" size={ICON_SIZES.SM} />
          </Color>
        </span>
      </button>
    );
  }

  return (
    <div className={classes} ref={ref}>
      <GalleryImageItem src={imageSrcForScreen} alt={alt} className={className} onClick={onClick} style={style} />
      {showCheckbox && (
        <button
          type="button"
          className={ClassNames(styles['gallery-image__checkbox'], styles['gallery-image__top-right'])}
          onClick={(e) => {
            e.preventDefault();
            if (onChangeValue) onChangeValue(!selected);
          }}
        >
          <Checkbox checked={selected} readOnly />
        </button>
      )}
      {getFlagIcon()}
    </div>
  );
}
export enum GALLERY_IMAGE_STYLE {
  COVER = 'cover',
  CONTAIN = 'contain',
}
type GalleryImageWithUploadStatusProps = {
  src: string;
  alt: string;
  onClick?: () => void;
  className?: string;
  cssStyle?: CSSProperties;
  style: GALLERY_IMAGE_STYLE;
  icon?: string;
  iconColor?: COLOR_SHADES;
  iconMessage?: string;
  isVideo?: boolean;
  selected?: boolean;
  showCheckbox?: boolean;
  onChangeValue?: (selected: boolean) => void;
};

function GalleryImageWithUploadStatusItem(
  props: Omit<GalleryImageWithUploadStatusProps, 'selected' | 'showCheckbox' | 'onChangeValue'>,
): React.ReactElement {
  const { alt, onClick, className = '', src, cssStyle, icon, iconMessage, iconColor, isVideo, style } = props;

  const imageClasses = ClassNames(styles['gallery-image'], className, styles[`gallery-image--style-${style}`]);
  const buttonClasses = ClassNames(styles['gallery-image__button']);
  const { src: loadedImage, hasError, isLoading } = useImage(icon ? '' : src);

  // Render if it has icon, it used to be !src but stupid backend is creating invalid src
  if (isLoading || icon)
    return (
      <button className={buttonClasses} onClick={onClick} type="button">
        <GalleryPlaceholderImage className={className} />

        {icon && (
          <div className={styles.image__icon}>
            <Tooltip message={iconMessage}>
              <Color shade={iconColor || COLOR_SHADES.DANGER}>
                <Icon name={icon} size={ICON_SIZES.SM} />
              </Color>
            </Tooltip>
          </div>
        )}
        {isVideo && (
          <div className={ClassNames(styles.image__icon, styles['image__icon--left'])}>
            <Tooltip message="Video">
              <VideoPlayIcon />
            </Tooltip>
          </div>
        )}
      </button>
    );

  return (
    <button className={buttonClasses} onClick={onClick} type="button">
      {hasError ? (
        <div className={imageClasses}>
          <Space>
            <Text muted>Oops... Missing photo</Text>
          </Space>
        </div>
      ) : (
        <img loading="lazy" src={loadedImage} alt={alt} title={alt} className={imageClasses} style={cssStyle} />
      )}
      {isVideo && (
        <div className={ClassNames(styles.image__icon, styles['image__icon--left'])}>
          <Tooltip message="Video">
            <VideoPlayIcon />
          </Tooltip>
        </div>
      )}
    </button>
  );
}

export function GalleryImageWithUploadStatus(props: GalleryImageWithUploadStatusProps): React.ReactElement {
  const {
    alt,
    onClick,
    className = '',
    src,
    cssStyle,
    style = GALLERY_IMAGE_STYLE.COVER,
    icon,
    iconMessage,
    iconColor,
    isVideo,
    showCheckbox = false,
    selected,
    onChangeValue,
  } = props;
  const classes = ClassNames(styles['gallery-image-wrapper']);

  const imageClasses = ClassNames(styles['gallery-image'], className);

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const imageSrcForScreen = MediaUtils.getDynamicImageURL(src, MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL, {
    width: 240,
  });

  if (!inView) {
    return (
      <span ref={ref}>
        <PlaceholderImage imageClasses={imageClasses} classes={classes} />
      </span>
    );
  }

  return (
    <div className={classes} ref={ref}>
      <GalleryImageWithUploadStatusItem
        src={imageSrcForScreen}
        alt={alt}
        className={className}
        onClick={onClick}
        cssStyle={cssStyle}
        icon={icon}
        iconMessage={iconMessage}
        iconColor={iconColor}
        isVideo={isVideo}
        style={style}
      />
      {showCheckbox && (
        <button
          type="button"
          className={ClassNames(styles['gallery-image__checkbox'], styles['gallery-image__top-right'])}
          onClick={(e) => {
            e.preventDefault();
            if (onChangeValue) onChangeValue(!selected);
          }}
        >
          <Checkbox checked={selected} readOnly />
        </button>
      )}
    </div>
  );
}

type GalleryImageBottomButtonProps = {
  onSelectExpand?: () => void;
  name: string;
};
export function GalleryImageBottomButton(props: GalleryImageBottomButtonProps) {
  const { onSelectExpand, name } = props;
  return (
    <button
      type="button"
      className={ClassNames(styles['gallery-image__bottom-bar-button'])}
      onClick={(e) => {
        e.preventDefault();
        if (onSelectExpand) onSelectExpand();
      }}
    >
      <Row vcenter>
        <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.WHITE} block ellipsis>
          {name}
        </Text>
        <Space />
        <Col size={null} rightAlighed>
          <Color shade={COLOR_SHADES.WHITE} inline>
            <Icon name="maximize2" size={ICON_SIZES.SM} />
          </Color>
        </Col>
      </Row>
    </button>
  );
}

interface GalleryProps {
  children: Array<React.ReactNode>;
  scaleSizeWithScreen?: boolean;
}

export function Gallery(props: GalleryProps): React.ReactElement {
  const { scaleSizeWithScreen = true, children } = props;
  const classes = ClassNames(styles.gallery, {
    [styles['gallery--scale-size-with-screen']]: scaleSizeWithScreen,
  });

  return <div className={classes}>{children}</div>;
}

interface SimpleImageProps {
  showLoadingIcon?: boolean;
  style?: CSSProperties;
  alt?: string;
  src: string;
  onClick?: () => void;
  className?: string;
}

export const SimpleImage = React.forwardRef((props: SimpleImageProps, ref: React.Ref<HTMLImageElement>) => {
  const { showLoadingIcon = false, style, alt = 'image', src, ...elementProps } = props;

  const { src: loadedImage, isLoading, hasError } = useImage(src);

  if (isLoading)
    return (
      <div style={style} ref={ref}>
        {showLoadingIcon && (
          <Row center fullHeight>
            <LoadingDots size="sm" align="center" />
          </Row>
        )}
      </div>
    );

  if (hasError)
    return (
      <div style={style} ref={ref}>
        <Row center fullHeight>
          <Text muted size={TEXT_SIZE.SIZE_5} center>
            Oops... cant find!
          </Text>
        </Row>
      </div>
    );

  return (
    <img
      ref={ref}
      className={ClassNames(styles['image--fade-in'])}
      {...elementProps}
      src={loadedImage}
      alt={alt}
      style={style}
    />
  );
});

export enum IMAGE_V2_STYLES {
  DEFAULT = 'default',
  COVER = 'cover',
  FULL_HEIGHT = 'full-height',
  FULL_IMAGE = 'full-image',
  THUMBNAIL = 'thumbnail',
  THUMBNAIL_SM = 'thumbnail-sm',
  THUMBNAIL_SM_MD = 'thumbnail-sm-md',
  THUMBNAIL_MD = 'thumbnail-md',
}
type ImageV2Props = {
  src: string;
  alt: string;
  className?: string;
  style?: IMAGE_V2_STYLES;
  cssStyle?: CSSProperties;
  width?: number;
  height?: number;
  ratio?: number;
  imageLoadType?: MediaUtils.DYNAMIC_IMAGE_TYPES;
  newImageLoadType?: MediaUtils.IMAGE_QUALITY_TYPES;
  focalPoint?: {
    x: number;
    y: number;
  };
  onContextMenu?: (event: React.MouseEvent<HTMLImageElement>) => void;
  loadingDotsSize?: 'xs' | 'sm' | 'md';
  isBackgroundDark?: boolean;
  fromTheme?: boolean;
  useNewImageService?: boolean;
};

export function ImageV2(props: ImageV2Props) {
  const {
    alt,
    className = '',
    src,
    style = IMAGE_V2_STYLES.DEFAULT,
    cssStyle,
    width = 300,
    height,
    ratio = 1,
    imageLoadType = MediaUtils.DYNAMIC_IMAGE_TYPES.DEFAULT,
    newImageLoadType = MediaUtils.IMAGE_QUALITY_TYPES.DEFAULT,
    focalPoint = {
      x: 50,
      y: 50,
    },
    loadingDotsSize = 'sm',
    isBackgroundDark = false,
    fromTheme,
    useNewImageService = false,
    ...elementProps
  } = props;

  const objectPositionStyle = { objectPosition: `${focalPoint?.x}% ${focalPoint?.y}%` };

  const [ref, inView] = useInView({
    threshold: 0,
  });

  /* When full height style & when the image is in landscape |_____|
  If we load the image with device width, and scale to the device width, the quality of the cover photo is bad. Issue: PE-1129
  So we can if its a landscape photo then lets load the image with height so that the quality is higher
  */

  let imageForWidth = useNewImageService
    ? MediaUtils.getNewDynamicImageURL(src, newImageLoadType, { width })
    : MediaUtils.getDynamicImageURL(src, imageLoadType, { width });

  if ([IMAGE_V2_STYLES.FULL_HEIGHT, IMAGE_V2_STYLES.COVER].includes(style) && ratio >= 1.1 && height) {
    imageForWidth = useNewImageService
      ? MediaUtils.getNewDynamicImageURL(src, newImageLoadType, { width: height })
      : MediaUtils.getDynamicImageURL(src, imageLoadType, { width: height });
  }

  const { src: loadedImage, hasError, isLoading } = useImage(imageForWidth);

  if (!inView) {
    return (
      <span className={ClassNames(className, styles[`image--style-${style}`])} style={cssStyle} ref={ref}>
        <div className={styles.image__placeholder} />
      </span>
    );
  }

  if (isLoading)
    return (
      <div
        className={ClassNames(styles[`image--style-${style}`], styles[`image--style-${style}--loading`])}
        style={cssStyle}
        ref={ref}
      >
        <div
          className={ClassNames(styles.image__placeholder, {
            [styles['image__placeholder--dark']]: isBackgroundDark,
            [styles['image__placeholder--from-theme']]: fromTheme,
          })}
        >
          <Row center fullHeight>
            <LoadingDots size={loadingDotsSize} align="center" />
          </Row>
        </div>
      </div>
    );

  if (hasError)
    return (
      <div className={styles[`image--style-${style}`]} style={cssStyle} ref={ref}>
        <Row center fullHeight>
          <Text muted size={TEXT_SIZE.SIZE_5} center>
            Oops... cant find!
          </Text>
        </Row>
      </div>
    );

  return (
    <img
      ref={ref}
      className={ClassNames(className, styles['image--fade-in'], styles[`image--style-${style}`])}
      {...elementProps}
      src={loadedImage}
      alt={alt}
      style={{ ...cssStyle, ...objectPositionStyle }}
    />
  );
}

export enum MEDIA_CARDS_GALLERY_STYLES {
  LANDSCAPE_CARD = 'landscape-card',
  GRID_CARD = 'grid-card',
}

function getMediaCardsGalleryLayoutOptions(
  style: MEDIA_CARDS_GALLERY_STYLES,
  options: {
    containerWidth;
    targetRowHeight;
  },
) {
  const { containerWidth, targetRowHeight } = options;
  const defaultConfig = {
    containerWidth,
    containerPadding: 16,
    fullWidthBreakoutRowCadence: false,
    targetRowHeightTolerance: 0.25,
    boxSpacing: 16,
    targetRowHeight: 320,
  };

  if (targetRowHeight) {
    if (style === MEDIA_CARDS_GALLERY_STYLES.LANDSCAPE_CARD && containerWidth <= 200) {
      return {
        ...defaultConfig,
        fullWidthBreakoutRowCadence: true,
        targetRowHeightTolerance: 0,
        targetRowHeight,
        forceAspectRatio: true,
        boxSpacing: 25,
      };
    }

    return {
      ...defaultConfig,
      targetRowHeight,
    };
  }

  if (style === MEDIA_CARDS_GALLERY_STYLES.GRID_CARD) {
    return {
      ...defaultConfig,
      targetRowHeight: 220,
    };
  }

  if (style === MEDIA_CARDS_GALLERY_STYLES.LANDSCAPE_CARD) {
    if (containerWidth >= 760) {
      return defaultConfig;
    }

    return {
      ...defaultConfig,
      fullWidthBreakoutRowCadence: true,
      targetRowHeightTolerance: 0,
      targetRowHeight: 110,
      forceAspectRatio: true,
    };
  }

  return defaultConfig;
}

interface MediaCardProps {
  file: FileService.FolderFileType;
  title: string;
  onClick: () => void;
  width: number;
  style: MEDIA_CARDS_GALLERY_STYLES;
  focalPoint?: { x: number; y: number };
}

function getTextStyle(style: MEDIA_CARDS_GALLERY_STYLES) {
  if (style === MEDIA_CARDS_GALLERY_STYLES.LANDSCAPE_CARD) {
    return {
      primaryStyle: true,
      size: TEXT_SIZE.SIZE_3,
    };
  }

  return {
    size: TEXT_SIZE.SIZE_4,
  };
}

export function MediaCard(props: MediaCardProps): React.ReactElement {
  const { title, file, onClick, width, style, focalPoint = { x: 50, y: 50 } } = props;

  if (!file) {
    return (
      <div
        className={ClassNames(styles['media-card__dummy-image'])}
        style={
          style === MEDIA_CARDS_GALLERY_STYLES.GRID_CARD
            ? { height: `${width}px`, width: `${width}px` }
            : { height: `${width / 2}px`, width: `${width}px` }
        }
      />
    );
  }

  const { asset_type: assetType, dynamic_image_url: src, location: videoSrc } = file;

  return (
    <button
      type="button"
      className={ClassNames(styles['media-card'], styles[`media-card--style-${style}`])}
      onClick={onClick}
    >
      {assetType === ASSET_TYPES.VIDEO ? (
        <VideoJsPlayer
          src={videoSrc}
          autoplay
          style={VIDEO_PLAYER_STYLES.DEFAULT_INLINE}
          type={VIDEO_TYPES.STREAM}
          poster={src}
        />
      ) : (
        <ImageV2
          onContextMenu={(e) => BrowserUtils.preventContextMenu(e)}
          style={IMAGE_V2_STYLES.COVER}
          className={styles['media-card__img']}
          useNewImageService
          newImageLoadType={MediaUtils.IMAGE_QUALITY_TYPES.THUMBNAIL_HIGHLIGHT}
          width={width * 1.7} // Load a higher quality image
          src={src}
          alt="cover"
          focalPoint={focalPoint}
        />
      )}
      {title.length && (
        <Text
          block
          ellipsis
          boldness={TEXT_BOLDNESS.SEMI_BOLD}
          className={ClassNames(styles['media-card__title'], styles[`media-card__title--style-${style}`])}
          color={COLOR_SHADES.WHITE}
          {...getTextStyle(style)}
        >
          {title}
        </Text>
      )}
    </button>
  );
}

interface MediaCardsGalleryProps {
  items: Array<{
    file: FileService.FolderFileType;
    title: string;
    id: string;
    focalPoint: { x: number; y: number };
  }>;
  containerWidth: number;
  onClick: (id: string) => void;
  style: MEDIA_CARDS_GALLERY_STYLES;
  targetRowHeight?: number;
}
export function MediaCardsGallery(props: MediaCardsGalleryProps): React.ReactElement {
  const { items, containerWidth, onClick, style, targetRowHeight } = props;
  const filesAspectRatios = items.map((item) => item?.file?.meta?.ratio || 1);
  const layoutConfig = getMediaCardsGalleryLayoutOptions(style, {
    containerWidth,
    targetRowHeight,
  });

  const layout = useMemo(
    () => justifiedLayout(filesAspectRatios, layoutConfig),
    [items.length, containerWidth, layoutConfig],
  );

  return (
    <div
      className={styles['media-cards-gallery']}
      style={{
        height: `${layout.containerHeight}px`,
        width: `${containerWidth}px`,
      }}
    >
      {items.map((item, idx) => {
        const boxGeometry = layout.boxes[idx];
        const { focalPoint } = item;
        return (
          <div className={styles['media-cards-gallery__item']} style={boxGeometry} key={item.id}>
            <MediaCard
              focalPoint={focalPoint}
              file={item.file}
              width={boxGeometry.width}
              title={item.title}
              style={style}
              onClick={() => onClick(item.id)}
            />
          </div>
        );
      })}
    </div>
  );
}

export enum FILE_CONTAINER_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

type FileContainerProps = {
  size?: FILE_CONTAINER_SIZES;
  children: React.ReactNode;
  status?: 'QUEUED' | 'UPLOADING' | 'PROCESSING' | 'DONE' | 'ERROR';
};

export const FILE_UPLOAD_STAGES_DETAILS: Record<
  'QUEUED' | 'UPLOADING' | 'PROCESSING' | 'DONE' | 'ERROR',
  {
    title: string;
    message: string;
    icon: string;
    iconColor: string;
  }
> = {
  QUEUED: {
    title: 'Queued',
    message: 'File is queued for upload',
    icon: 'clock',
    iconColor: 'light',
  },
  UPLOADING: {
    title: 'Uploading...',
    message: 'Files is getting uploaded',
    icon: 'upload_cloud',
    iconColor: 'light',
  },
  PROCESSING: {
    title: 'Processing...',
    message: 'File is getting Processed',
    icon: 'cpu',
    iconColor: 'blue',
  },
  DONE: {
    title: 'Done',
    message: 'File is uploaded and processed',
    icon: '',
    iconColor: '',
  },
  ERROR: {
    title: 'Error',
    message: 'File is not uploaded',
    icon: 'alert_triangle',
    iconColor: 'danger',
  },
};

export function FileContainer(props: FileContainerProps): React.ReactElement {
  const { size = FILE_CONTAINER_SIZES.MEDIUM, children, status } = props;
  if (status && status !== 'DONE') {
    const detailedError = FILE_UPLOAD_STAGES_DETAILS[status] || {};
    return (
      <div className={ClassNames(styles['file-container'], styles[`file-container--${size}`])}>
        <Row center fullHeight>
          <div>
            <Row center>
              <Color shade={COLOR_SHADES.LIGHT}>
                <Icon name={detailedError.icon} />
              </Color>
            </Row>

            <Text block center size={TEXT_SIZE.SIZE_5}>
              {detailedError.title}
            </Text>
          </div>
        </Row>
      </div>
    );
  }

  return <div className={ClassNames(styles['file-container'], styles[`file-container--size-${size}`])}>{children}</div>;
}

type HighlightIframeVideoPlayerProps = {
  src: string;
};

export function HighlightIframeVideoPlayer(props: HighlightIframeVideoPlayerProps): React.ReactElement {
  const { src } = props;

  return (
    <div className={ClassNames(styles['video-player-container'])}>
      <iframe
        title="Video player"
        className={styles['video-player-container__iframe']}
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}
