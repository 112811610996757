import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';

import BrandingFiles from './BrandingFiles';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { addSponsorOrBrandingFolder } from '../SponsorAndBrandingDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    onSelect: (fileId: string) => void;
  },
) {
  const { projectId, onSelect } = ownProps;
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);

  return {
    projectId,
    folderId: brandingFolderIds[0],
    onSelect,
    isCreatingFolder: isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createFolder: (projectId) => {
      dispatch(addSponsorOrBrandingFolder(dispatch, projectId, FolderService.FOLDER_TYPES.BRANDING));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingFiles);
