import { SocialNetworksUtils } from '@premagic/utils';
import { PRICING_PLAN_VARIANT_LOCAL, PricingPlanType } from './PricingPlanService';
import { PRICING_PLAN_FEATURES } from './PricingPlanFeaturesService';
import { CLIENT_COMPANY_TYPES, CLIENT_SETTINGS_META_TYPES, ClientSettingsType } from './ClientSettingsService';
import { COMPANY_STATS_TYPE, CompanyStatsType } from './CompanyStatsService';

export enum COMPANY_CONTACT_TYPE {
  PHONE = 'phone',
  EMAIL = 'email',
  WEBSITE = 'website',
  LOCATION = 'location',
  MAP_LOCATION = 'maplocation',
  ALTERNATIVE_PHONE = 'alternative_phone',
}

export type CompanySocialMediaType = Partial<Record<SocialNetworksUtils.SOCIAL_MEDIAS, string>>;
export type CompanyContactType = Partial<Record<COMPANY_CONTACT_TYPE, string>>;

export enum COMPANY_TYPES {
  PHOTOGRAPHY = 'photography',
  EVENT = 'event',
  ORGANIZER = 'organized',
}
export type CompanyType = {
  id: string;
  name: string;
  email: string;
  phone?: string;
  alternative_phone?: string;
  is_first_time_user: boolean;
  currentPricingPlan: PricingPlanType;
  customer_country_code: PRICING_PLAN_VARIANT_LOCAL;
  usage: {
    totalProjects: number;
    availableProjects: number;
  };
  subscription: string;
  social: CompanySocialMediaType;
  contacts: CompanyContactType;
  meta: Record<string, unknown>;
  logo?: string;
  logo_white?: string;
  domain: string;
  custom_domain?: string;
  referral_code: string;
  referee_code?: string;
  favicon?: string;
  public_domain?: string;
  total_num_projects?: number;
  is_blocked: boolean;
  subscription_end_date?: string;
  subscription_start_date?: string;
  subscription_payment_link?: string;
  company_type?: COMPANY_TYPES;
};

export enum COMPANY_LOGOS {
  LOGO = 'logo',
  LOGO_WHITE = 'logo_white',
  FAVICON = 'favicon',
}

export function hasFeature(company: CompanyType, feature: PRICING_PLAN_FEATURES): boolean {
  if (company.currentPricingPlan && company.currentPricingPlan.feature_list)
    return company.currentPricingPlan.feature_list.includes(feature);
  return false;
}

export const COMPANY_TYPE_FEATURES: Array<{
  name: string;
  key: CLIENT_SETTINGS_META_TYPES;
  isInvertedToggleKey?: boolean;
  disableForCompanyType: Array<CLIENT_COMPANY_TYPES>;
  enableForCompanyType: Array<CLIENT_COMPANY_TYPES>;
}> = [
  {
    name: 'Lead management',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_LEADS_DISABLE,
    isInvertedToggleKey: true,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  {
    name: 'Selection Album',
    key: CLIENT_SETTINGS_META_TYPES.ENABLE_SELECTION_ALBUM,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  {
    name: 'Event Occasions',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_EVENTS_OCCASIONS_ENABLE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  {
    name: 'Event financials',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  {
    name: 'Projects Tab',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_PROJECTS_TAB_ENABLE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  {
    name: 'Proposal management',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_PROPOSAL_ENABLE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  {
    name: 'Client Gallery Upsell',
    key: CLIENT_SETTINGS_META_TYPES.DISABLE_SIGNATURE_ALBUM_UPSELL,
    isInvertedToggleKey: true,
    disableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
    enableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  {
    name: 'Library',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_DAM_LIBRARY_ENABLE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  {
    name: 'UGC Catalyst',
    key: CLIENT_SETTINGS_META_TYPES.GUEST_GALLERY_SHOW_LINKEDIN_POST_SHARE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  {
    name: 'Event Marketing Posters',
    key: CLIENT_SETTINGS_META_TYPES.FEATURE_EVENT_MARKETING_POSTERS_ENABLE,
    enableForCompanyType: [CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
    disableForCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
];

export function getClientSettingsToSaveForCompanyType(companyType: CLIENT_COMPANY_TYPES): Partial<ClientSettingsType> {
  return COMPANY_TYPE_FEATURES.reduce(
    (result, feature) => {
      const featureStatus = (() => {
        let status = false;
        if (feature.enableForCompanyType.includes(companyType)) status = true;

        if (feature.disableForCompanyType.includes(companyType)) status = false;
        if (feature.isInvertedToggleKey) status = !status;
        return status;
      })();

      return {
        ...result,
        [feature.key]: featureStatus,
      };
    },
    {
      company_type: companyType,
    },
  );
}

export function remainingAIDeliveryPhotos(data: {
  pricingPlan: PricingPlanType;
  companyStats: CompanyStatsType;
  clientSettings: ClientSettingsType;
}): number {
  const { pricingPlan, companyStats, clientSettings } = data;
  const used = companyStats?.[COMPANY_STATS_TYPE.AI_DELIVERY]?.assets_scanned || 0;
  const { [CLIENT_SETTINGS_META_TYPES.ADDON_AI_LIMIT]: adOnLimit = 0 } = clientSettings || {};
  const { ai_photos_per_highlight: limit = 0 } = pricingPlan;
  const totalLimit = limit + adOnLimit;
  return totalLimit - used;
}

export function remainingProjects(pricingPlan: PricingPlanType, company: CompanyType): number {
  const { usage } = company;
  if (!usage) return 1;
  const { availableProjects = 0, totalProjects = 0 } = usage || {
    availableProjects: 0,
    totalProjects: 0,
  };

  return availableProjects - totalProjects;
}
