import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDeckDataDuck, ProposalSlideDataDuck } from '@premagic/proposals';

import ProposalSlideListItem from './ProposalSlideListItem';
import { proposalCreatorSelectedSlideSelector } from '../ProposalCreatorDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: number;
    projectId: string;
    folderId: string;
    deckId: string;
    position: number;
  },
) {
  const { id, projectId, folderId, deckId, position } = ownProps;
  return {
    projectId,
    folderId,
    deckId,
    position,
    id,
    slide: ProposalSlideDataDuck.proposalsSlideItemsSelectors(state)[id],
    isUpdating: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_SLIDE.UPDATE(id))(state),
    activeSlideId: proposalCreatorSelectedSlideSelector(state),
    nextSlideId: ProposalDeckDataDuck.proposalsNextSlideIdSelectors(deckId, id)(state),
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteSlide: (options) => {
      dispatch(ProposalSlideDataDuck.removeProposalSlide(dispatch, options));
    },
    addSlide: (params, data) => {
      dispatch(ProposalSlideDataDuck.addProposalSlide(dispatch, params, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSlideListItem);
