import React from 'react';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  List,
  ListItem,
  Row,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Space,
  InnerPageContentMainCol,
  InnerPageHeader,
} from '@premagic/myne';
import { ClientSettingsService } from '@premagic/core';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  currentUrlPathname: string;
  hasAccountFinancials: boolean;
  savePreferences: (data) => void;
  isFeatureFinancialsEnabled: boolean;
  isWatermarkOnClientInvoiceShown: boolean;
};

export default function EventFinancialSettingsPage(props: Props) {
  const {
    hasAccountFinancials,
    currentUrlPathname,
    savePreferences,
    isFeatureFinancialsEnabled,
    isWatermarkOnClientInvoiceShown,
  } = props;

  return (
    <InnerPage>
      <InnerPageHeader iconName="dollar_sign" title="Event Financials Settings" backTo={APP_URLS.SETTINGS.INDEX}>
        Event Financials
      </InnerPageHeader>
      <InnerPageContent hasInnerCols>
        <InnerPageContentMainCol>
          <Card>
            <List>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Enable financial module
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>Manage Event financials</Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {isFeatureFinancialsEnabled ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE}
                      checked={isFeatureFinancialsEnabled}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE]: value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
              {isFeatureFinancialsEnabled && (
                <ListItem>
                  <Col size={10}>
                    <Text color={COLOR_SHADES.DARKER}>Show company logo as watermark on client invoice</Text>
                  </Col>
                  <Col size={null} rightAlighed>
                    <ToggleSwitch
                      name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SHOW_WATERMARK_ON_CLIENT_INVOICE}
                      checked={isWatermarkOnClientInvoiceShown}
                      onChange={(value) => {
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SHOW_WATERMARK_ON_CLIENT_INVOICE]: value,
                          },
                        });
                      }}
                    />
                  </Col>
                </ListItem>
              )}
            </List>
          </Card>
          <Space vertical size={8} />
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
