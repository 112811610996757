export enum STORE_PRODUCT_IDS {
  PHOTO_DOWNLOAD = 0,
}

export const STORE_CURRENCY = 'store_currency';

export enum SUBPRODUCTS_IDS {
  HIGH_RES_PHOTO = 'high_res_photo',
}

export type StorePricingType = {
  [SUBPRODUCTS_IDS.HIGH_RES_PHOTO]?: number;
  [STORE_CURRENCY]?: string;
};

// Product

export type ProductItemType = {
  id: STORE_PRODUCT_IDS;
  label: string;
  imgSrc: string;
  subproducts: Array<SUBPRODUCTS_IDS>;
};

export const STORE_PRODUCTS_DETAILS: Record<STORE_PRODUCT_IDS, ProductItemType> = {
  [STORE_PRODUCT_IDS.PHOTO_DOWNLOAD]: {
    id: STORE_PRODUCT_IDS.PHOTO_DOWNLOAD,
    label: 'Photo Download',
    imgSrc: '',
    subproducts: [SUBPRODUCTS_IDS.HIGH_RES_PHOTO],
  },
};

// Sub products

export type SubproductDetailsType = {
  name: string;
  id: SUBPRODUCTS_IDS;
  cost: number;
};

export const SUBPRODUCTS_DETAILS: Record<SUBPRODUCTS_IDS, SubproductDetailsType> = {
  [SUBPRODUCTS_IDS.HIGH_RES_PHOTO]: {
    id: SUBPRODUCTS_IDS.HIGH_RES_PHOTO,
    name: 'High resolution photo',
    cost: 0,
  },
};
