import { EventsService, IntegrationService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  LoadingDots,
  ModalTitle,
  RealCheckbox,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { StringUtils } from '@premagic/utils';
import { useEffect, useState } from 'react';
import { UserType } from '../../crm/users/UsersService';
import { INTEGRATION_CONNECT_OPTION, INTEGRATION_STEP } from './IntegrationStepService';
import { getIntegrationCheckboxConfig, INTEGRATION_CHECKBOX_TYPES } from '../IntegrationDataService';

type FormattedCheckboxesType = {
  fieldName: string;
  title: string;
  subTitle?: string;
  subFields?: Array<{ id: string; name: string }>;
  readOnly?: boolean;
};

function FormattedCheckboxes(props: FormattedCheckboxesType) {
  const { fieldName, title, subFields, readOnly, subTitle } = props;

  const [showChildrenCheckboxes, setShowChildrenCheckboxes] = useState(readOnly);

  return (
    <>
      <RealCheckbox
        name="dummy-parent"
        onChange={(e) => setShowChildrenCheckboxes(e.target.checked)}
        defaultChecked={readOnly}
        disabled={readOnly}
      >
        <Text boldness={TEXT_BOLDNESS.NORMAL} size={TEXT_SIZE.SIZE_2}>
          {StringUtils.toTitleCase(title, true)}
        </Text>
      </RealCheckbox>
      {subTitle && (
        <Row>
          <Space size={5} /> <Text muted>{subTitle}</Text>
        </Row>
      )}
      {showChildrenCheckboxes &&
        subFields?.map((item) => (
          <Row key={item.id}>
            <Space />
            <RealCheckbox name={`${fieldName}[${item.id}]`} defaultChecked disabled={readOnly}>
              <Text boldness={TEXT_BOLDNESS.NORMAL} size={TEXT_SIZE.SIZE_2}>
                {StringUtils.toTitleCase(item.name, true)}
              </Text>
            </RealCheckbox>
          </Row>
        ))}
    </>
  );
}

type Props = {
  platform: IntegrationService.INTEGRATION_PLATFORMS;
  getCustomFieldsForEvent: (platform: IntegrationService.INTEGRATION_PLATFORMS, eventId: string) => void;
  customFields: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
  isLoading: boolean;
  isCreatingAndConnectingEvent: boolean;
  createPremagicEventAndConnectIntegrationEvent: (options: {
    platform: IntegrationService.INTEGRATION_PLATFORMS;
    integrationEventData: IntegrationService.IntegrationPlatformEventType;
    fieldsToSync: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
    hostUser?: UserType;
    isCompanyTypeNotPhotographer?: boolean;
  }) => void;
  errors?: {
    message: string;
    code: string;
    name: string;
  };
  integrationEventData?: IntegrationService.IntegrationPlatformEventType;
  requestingUser?: UserType;
  setIntegrationStep: (step: INTEGRATION_STEP) => void;
  closeModal: () => void;
  connectOption: INTEGRATION_CONNECT_OPTION;
  connectAndSyncEvent: (options: {
    platform: IntegrationService.INTEGRATION_PLATFORMS;
    integrationEventData: IntegrationService.IntegrationPlatformEventType;
    fieldsToSync: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
    premagicProjectId: string;
    hostUser?: UserType;
    numOfSharedFolders?: number;
    syncEvent?: boolean;
    premagicEventData?: EventsService.EventDataType;
  }) => void;
  projectId?: string;
  pmEventData?: EventsService.EventDataType;
  numOfSharedFolders?: number;
  isCompanyTypeNotPhotographer?: boolean;
};

export default function IntegrationEventCustomFieldsSelector(props: Props) {
  const {
    platform,
    getCustomFieldsForEvent,
    customFields,
    isLoading,
    createPremagicEventAndConnectIntegrationEvent,
    errors,
    isCreatingAndConnectingEvent,
    integrationEventData,
    requestingUser,
    setIntegrationStep,
    closeModal,
    connectOption,
    connectAndSyncEvent,
    projectId,
    pmEventData,
    numOfSharedFolders,
    isCompanyTypeNotPhotographer,
  } = props;

  const { id: integrationEventId } = integrationEventData || {};

  const eventDetailsField = Object.values(IntegrationService.INTEGRATION_EVENT_FIELDS_TYPE).map((item) => ({
    id: item,
    name: item,
  }));

  const checkboxConfig = getIntegrationCheckboxConfig(platform, {
    [INTEGRATION_CHECKBOX_TYPES.ATTENDEES]: customFields,
    [INTEGRATION_CHECKBOX_TYPES.EVENT_DETAILS]: eventDetailsField,
  });

  function takeActionBasedOnConnectOption(
    selectedAttendeeCustomFieldsData: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>,
  ) {
    if (!integrationEventData) return;

    if (connectOption === INTEGRATION_CONNECT_OPTION.CREATE_EVENT_AND_CONNECT) {
      // create and connect
      return createPremagicEventAndConnectIntegrationEvent({
        platform,
        integrationEventData,
        fieldsToSync: selectedAttendeeCustomFieldsData,
        hostUser: requestingUser,
        isCompanyTypeNotPhotographer,
      });
    }

    if (connectOption === INTEGRATION_CONNECT_OPTION.CONNECT_AND_SYNC) {
      if (!projectId) return;

      // connect and sync
      connectAndSyncEvent({
        platform,
        integrationEventData,
        fieldsToSync: selectedAttendeeCustomFieldsData,
        premagicProjectId: projectId,
        hostUser: requestingUser,
        numOfSharedFolders,
        syncEvent: true,
        premagicEventData: pmEventData,
      });
    }
  }

  function handleSubmit(formData) {
    const { data } = formData;
    // filter all selected custom fields data (these are the fields to sync when connecting an event)
    const selectedAttendeeCustomFieldsData: Array<IntegrationService.IntegrationPlatformEventCustomFieldType> =
      customFields.filter((customField) => {
        const { id: customFieldId } = customField;
        return data[INTEGRATION_CHECKBOX_TYPES.ATTENDEES][customFieldId] === 'on';
      });

    takeActionBasedOnConnectOption(selectedAttendeeCustomFieldsData);
  }

  useEffect(() => {
    if (!integrationEventId) return;

    getCustomFieldsForEvent(platform, integrationEventId);
  }, [getCustomFieldsForEvent, platform, integrationEventId]);

  return (
    <>
      <ModalTitle>Select the items to import</ModalTitle>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <Form onSubmit={handleSubmit} errors={errors}>
          <ScrollableContainer direction="vertical" size={SCROLLABLE_CONTAINER_SIZE.MD}>
            {Object.values(checkboxConfig).map((config) => (
              <div key={config.name}>
                <FormattedCheckboxes
                  fieldName={config.name}
                  title={config.title}
                  subFields={config.subFields}
                  subTitle={config.subTitle}
                  readOnly={config.readOnly}
                />
                <Space vertical />
              </div>
            ))}
          </ScrollableContainer>

          <FormFooter>
            <Button
              onClick={closeModal}
              style={BUTTON_STYLES.TERTIARY}
              isLoading={isLoading || isCreatingAndConnectingEvent}
            >
              Close
            </Button>
            <Button type="submit" style={BUTTON_STYLES.PRIMARY} isLoading={isLoading || isCreatingAndConnectingEvent}>
              Import
            </Button>
          </FormFooter>
        </Form>
      )}
    </>
  );
}
