import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { DECK_SLIDE_DATA_TYPES, DECK_SLIDE_LAYOUTS } from '@premagic/myne';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { ErrorDuck, LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FolderService, Schemas } from '@premagic/core';
import {
  ActionConstants,
  ProposalDataDuck,
  ProposalDeckDataDuck,
  ProposalDeckService,
  ProposalService,
  ProposalSlideDataDuck,
  ProposalSlideService,
  ProposalVariablesDataDuck,
  ProposalVariableService,
} from '@premagic/proposals';
import { fetchEventDataWithDependencyData } from '../../crm/events/EventsDataDuck';
import { pagesSelector } from '../../../reducers/selectors';
import { getFolderMetaData } from '../../projects/folder-meta/FoldersMetaDataDuck';
import { addFolderData } from '../../projects/folders/AccountFoldersDataDuck';
import { setFilesData } from '../../images/FilesDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROPOSAL', true);

export type ProposalCreatorUploadingFilesType = {
  uploaderUniqueIdentifierForFile: string;
  forSlideId: number;
  dataType: DECK_SLIDE_DATA_TYPES;
};

export const setActiveSlide = createAction(getActionType('SLIDE', 'SET'), (dispatch, data) => data);
export const addSlideUploadingFiles = createAction(
  getActionType('SLIDE_FILES', 'SET'),
  (dispatch, data: ProposalCreatorUploadingFilesType) => ({
    [data.uploaderUniqueIdentifierForFile]: data,
  }),
);
export const removeSlideUploadingFile = createAction(getActionType('SLIDE_FILES', 'REMOVE'), (dispatch, id) => id);

/**
 * 1. Fetch Proposal details(folder details)
 * 2. Fetch Deck details
 * 3. Fetch all the slides & set Active slide
 * 4. Fetch all the variables
 * 5. Fetch eventDetails and Client details if not a system project
 * 6. Fetch FolderMeta - for fonts preferences
 */

export const initProposalCreator = createAction(
  getActionType('PROPOSAL', 'INIT'),
  async (dispatch, options: { projectId: string; folderId: string; deckId: string }) => {
    const { projectId, folderId, deckId } = options;
    const loadingKey = ActionConstants.KEYS.PROPOSALS.INIT;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    try {
      // 1. Fetch Proposal details(folder details)
      const proposalDetails = await FolderService.fetchFolder<ProposalService.ProposalType>(
        projectId,
        folderId,
        FolderService.FOLDER_TYPES.PROPOSAL,
      );
      const proposalNormalizedData = normalize(proposalDetails, Schemas.FolderSchema);
      const { folders, images } = proposalNormalizedData.entities || {};

      dispatch(addFolderData(dispatch, folders));
      dispatch(setFilesData(dispatch, images));

      dispatch(ProposalDataDuck.setProposalData(dispatch, folders));

      // 2. Fetch Deck details
      const deckDetails = await ProposalDeckService.fetchProposalDeck(projectId, deckId);

      const deckNormalizedData = normalize(deckDetails, Schemas.ProposalDeckSchema);
      dispatch(ProposalDeckDataDuck.setProposalDecksData(dispatch, deckNormalizedData.entities.decks));

      // 3. Fetch all the slides
      const slides = await ProposalSlideService.fetchProposalSlide(projectId, deckId);
      const normalizedData = normalize(slides, Schemas.ProposalSlidesSchema);
      dispatch(ProposalSlideDataDuck.setProposalSlidesData(dispatch, normalizedData.entities.slides));

      // 4. Fetch all the variables
      dispatch(ProposalVariablesDataDuck.fetchProposalsVariables(dispatch));

      // 5. Fetch eventDetails and Client details if not a system project
      if (proposalDetails.event_id) {
        dispatch(fetchEventDataWithDependencyData(dispatch, proposalDetails.event_id));
      }

      // 6. Fetch FolderMeta - for fonts preferences
      dispatch(
        getFolderMetaData(dispatch, projectId, folderId, {
          loadFonts: true,
        }),
      );

      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e as Record<string, string>));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Init Proposal creator: fetch failed', e);
    }
  },
);

export const fetchProposalFiles = createAction(
  getActionType('PROPOSAL', 'FILES'),
  async (dispatch, options: { projectId: string; folderId: string }) => {
    const { projectId, folderId } = options;
    const loadingKey = ActionConstants.KEYS.PROPOSAL_FILE.FETCH;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));

    try {
      const proposalDetails = await FolderService.fetchFolder<ProposalService.ProposalType>(
        projectId,
        folderId,
        FolderService.FOLDER_TYPES.PROPOSAL,
      );
      const proposalNormalizedData = normalize(proposalDetails, Schemas.FolderSchema);
      const { folders, images } = proposalNormalizedData.entities || {};

      dispatch(ProposalDataDuck.setProposalData(dispatch, folders));

      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Proposal files: fetch failed', e);
    }
  },
);
export const showAddNewProposalParameter = createAction(
  getActionType('SHOW_NEW_PROPOSAL', 'UI'),
  async (dispatch, scope: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE) => {
    dispatch(ModalDuck.setModalOptions(dispatch, ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL, scope));
    dispatch(
      WindowPanelDuck.toggleWindowPanelVisibility(
        dispatch,
        ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL,
        true,
      ),
    );
  },
);

export const showEditProposalParameter = createAction(
  getActionType('SHOW_EDIT_PROPOSAL', 'UI'),
  async (dispatch, scope: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE, id) => {
    dispatch(
      ModalDuck.setModalOptions(dispatch, ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL_WITH_ID(id), scope),
    );
    dispatch(
      WindowPanelDuck.toggleWindowPanelVisibility(
        dispatch,
        ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL_WITH_ID(id),
        true,
      ),
    );
  },
);

type StateType = {
  selectedSlide: number;
  uploadingFiles: IMap<string, ProposalCreatorUploadingFilesType>;
};

const initialState: StateType = {
  selectedSlide: 1,
  uploadingFiles: IMap(),
};

export default handleActions<StateType>(
  {
    [setActiveSlide.toString()]: (state, action: { payload }) => ({
      ...state,
      selectedSlide: action.payload,
    }),
    [addSlideUploadingFiles.toString()]: (state, action: { payload }) => ({
      ...state,
      uploadingFiles: state.uploadingFiles.merge(action.payload),
    }),
    [removeSlideUploadingFile.toString()]: (state, action: { payload }) => ({
      ...state,
      uploadingFiles: state.uploadingFiles.remove(action.payload),
    }),
  },
  initialState,
);

export const proposalCreatorSelector = createSelector(pagesSelector, (state) => state.proposal.creator as StateType);

export const proposalCreatorSelectedSlideSelector = createSelector(
  proposalCreatorSelector,
  (state) => state.selectedSlide,
);
export const proposalCreatorUploadingFilesSelector = createSelector(proposalCreatorSelector, (state) =>
  state.uploadingFiles.toJSON(),
);

export const activeProposalSlideSelector = createSelector(
  ProposalSlideDataDuck.proposalSlidesSelectors,
  proposalCreatorSelectedSlideSelector,
  (slides, activeId) => slides.get(String(activeId)),
);
export const selectedLayoutForActiveSlideSelector = createSelector(
  activeProposalSlideSelector,
  (slide) => slide?.template_id || DECK_SLIDE_LAYOUTS.WELCOME,
);
