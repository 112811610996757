import React from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  Col,
  GALLERY_IMAGE_STYLE,
  Icon,
  ICON_SIZES,
  Label,
  LABEL_STYLES,
  LoadingDots,
  Position,
  Row,
  Space,
  Tooltip,
} from '@premagic/myne';
import { EventSponsorsService, FileService, SponsorCreativesService } from '@premagic/core';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';
import SponsorCreativeOptionsMenuContainer from './dropdown-menu/SponsorCreativeOptionsMenuContainer';
import { FolderGalleryImage } from '../../../projects/folders/gallery/FolderGallery';

type Props = {
  creative: SponsorCreativesService.SponsorCreativeType;
  sponsor: EventSponsorsService.EventSponsorType;
  projectId: string;
  id: string;
  hasPermissions: {
    [PERMISSIONS.SPONSOR_CREATIVE]: boolean;
    [PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT]: boolean;
  };
  focus?: boolean;
  showEditCreative: (id: string) => void;
  files: Record<string, FileService.FolderFileType>;
};

export default function SponsorCreativeItem(props: Props): JSX.Element {
  const { creative, id, projectId, hasPermissions, focus, showEditCreative, files, sponsor } = props;
  const { name, status, size, cta_link: link } = creative;
  const isSponsorInDraft = sponsor.status === EventSponsorsService.EVENT_SPONSORS_PUBLISH_STATUS.DRAFT;
  const isInDraft = status === SponsorCreativesService.SPONSORS_CREATIVES_PUBLISH_STATUS.DRAFT;
  const hasPermissionsToManageSponsors = hasPermissions[PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT];

  return (
    <div>
      <Card highlight={focus} size={CARD_SIZES.BOX_SM}>
        <Position align="top-right">
          <Row columnDirection>
            {link && (
              <Tooltip message="Clickable Link">
                <Label style={LABEL_STYLES.PILL_INFO}>
                  <Icon name="link" size={ICON_SIZES.XS} />
                </Label>
              </Tooltip>
            )}
          </Row>
        </Position>
        {isSponsorInDraft && (
          <Position align="top-left">
            <Tooltip message="The sponsor is set to hidden, none of the sponsor's creatives will be displayed.">
              <Space size={2}>
                <Label style={LABEL_STYLES.PILL_DANGER}>Sponsor HIDDEN</Label>
              </Space>
            </Tooltip>
          </Position>
        )}
        {!isSponsorInDraft && isInDraft && (
          <Position align="top-left">
            <Tooltip message="Creative is marked as hidden">
              <Space size={2}>
                <Label style={LABEL_STYLES.PILL_DANGER}>HIDDEN</Label>
              </Space>
            </Tooltip>
          </Position>
        )}
        {creative.asset_ids.length > 0 &&
          creative.asset_ids.map((fileId) => {
            const file = files[fileId];
            return (
              <div key={fileId}>
                <div
                  style={{
                    padding: '8px',
                    backgroundColor: '#f5f5f5',
                    width: '100%',
                    height: 112,
                    borderRadius: '12px 12px 0 0',
                    ['--image-thump-height' as string]: '112px',
                    opacity: isSponsorInDraft || isInDraft ? 0.3 : 1,
                  }}
                >
                  <Row center fullHeight>
                    {file ? (
                      <FolderGalleryImage fileId={fileId} file={file} style={GALLERY_IMAGE_STYLE.CONTAIN} />
                    ) : (
                      <LoadingDots size="xs" />
                    )}
                  </Row>
                </div>
              </div>
            );
          })}
        <Space size={2}>
          <Row vcenter>
            <Button
              disabled={!hasPermissionsToManageSponsors}
              disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT]}
              style={BUTTON_STYLES.LINK}
              size={BUTTON_SIZE.SM}
              onClick={() => showEditCreative(id)}
            >
              <Icon name="edit_2" size={ICON_SIZES.SM_XS} />
              <Space size={1} />
              Edit
            </Button>

            <Col size={1} rightAlighed>
              <SponsorCreativeOptionsMenuContainer projectId={projectId} creativeId={id} />
            </Col>
            <Space />
          </Row>
        </Space>
      </Card>
    </div>
  );
}
