import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';
import { clientWebsiteForProjectSelector } from '../../projects/AccountProjectsDataDuck';

import EmbedPosterModal from './EmbedPosterModal';

const COMMON_KEY = LOADINGS.EVENT_POSTERS.SHOW_EMBED_MODAL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const posterId = ModalDuck.modalOptionsSelector(COMMON_KEY)(state) as string;
  return {
    show: ModalDuck.isModalOpenSelector(COMMON_KEY)(state),
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
    poster: eventPostersSelector(state)[posterId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onHide: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, COMMON_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbedPosterModal);
