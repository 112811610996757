import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum INTEGRATION_PLATFORMS {
  SWAPCARD = 'swapcard',
  LUMA = 'luma',
}

export const INTEGRATION_PLATFORMS_DATA: Record<
  INTEGRATION_PLATFORMS,
  {
    id: INTEGRATION_PLATFORMS;
    title: string;
    svgIconName: string;
  }
> = {
  [INTEGRATION_PLATFORMS.SWAPCARD]: {
    id: INTEGRATION_PLATFORMS.SWAPCARD,
    title: 'Swapcard',
    svgIconName: 'swapcard',
  },
  [INTEGRATION_PLATFORMS.LUMA]: {
    id: INTEGRATION_PLATFORMS.LUMA,
    title: 'Luma',
    svgIconName: 'luma',
  },
};

export function doesPlatformHasApprovedAttendeesFeature(platform: INTEGRATION_PLATFORMS) {
  const platformsWithApprovedAttendees = [INTEGRATION_PLATFORMS.LUMA];
  return platformsWithApprovedAttendees.includes(platform);
}

export enum INTEGRATION_EVENT_FIELDS_TYPE {
  TITLE = 'title',
  CREATED_AT = 'created_at',
  STARTS_AT = 'starts_at',
  ENDS_AT = 'ends_at',
  TIMEZONE = 'timezone',
  LANGUAGE = 'language',
  DESCRIPTION = 'description',
  COVER_IMAGE_URL = 'cover_image_url',
  ADDRESS = 'address',
}

export type IntegrationPlatformEventType = {
  id: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.TITLE]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.CREATED_AT]: number;
  [INTEGRATION_EVENT_FIELDS_TYPE.STARTS_AT]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.ENDS_AT]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.TIMEZONE]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.LANGUAGE]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.DESCRIPTION]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.COVER_IMAGE_URL]: string;
  [INTEGRATION_EVENT_FIELDS_TYPE.ADDRESS]: string;
};

export type IntegrationPlatformEventCustomFieldType = {
  name: string;
  type: string;
  id: string;
};

export type IntegrationClientSettingsType = Partial<Record<INTEGRATION_PLATFORMS, { enabled: boolean }>>;

export type EventPlatformIntegrationConfig = {
  platform: INTEGRATION_PLATFORMS;
  platform_config: {
    event_id: string;
    fields_to_sync: Array<IntegrationPlatformEventCustomFieldType>;
    webhook: {
      webhook_id: string;
    };
  };
};

export function fetchAllEventsForPlatform(
  platform: INTEGRATION_PLATFORMS,
): Promise<Array<IntegrationPlatformEventType>> {
  return HttpUtils.get(API_URLS.INTEGRATIONS.EVENTS_LIST(platform))
    .then(({ data }) => data.events)
    .catch((error) => Promise.reject(error.data));
}

export function fetchEventCustomFieldsForPlatform(
  platform: INTEGRATION_PLATFORMS,
  eventId: string,
): Promise<Array<IntegrationPlatformEventCustomFieldType>> {
  return HttpUtils.get(API_URLS.INTEGRATIONS.EVENT_CUSTOM_FIELDS(platform, eventId))
    .then(({ data }) => data.fields)
    .catch((error) => Promise.reject(error.data));
}

export function connectEventForPlatform(
  platform: INTEGRATION_PLATFORMS,
  projectId: string, // Premagic projectId
  eventId: string, // Integration Event (ie. Swapcard) eventId
  fieldsToSync: Array<IntegrationPlatformEventCustomFieldType>,
): Promise<{ success: true }> {
  const data = { platform, project_id: projectId, event_id: eventId, fields_to_sync: fieldsToSync };

  return HttpUtils.post(API_URLS.INTEGRATIONS.CONNECT_EVENT, data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function syncConnectedEventForPlatform(projectId: string): Promise<{ success: true }> {
  const data = { project_id: projectId };

  return HttpUtils.post(API_URLS.INTEGRATIONS.SYNC_EVENT, data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}
