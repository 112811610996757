import {
  ClientWebsiteService,
  EventAttendeesService,
  FolderService,
  ProjectMetaService,
  ProjectService,
} from '@premagic/core';

import {
  Button,
  BUTTON_STYLES,
  ButtonIcon,
  Card,
  CardHeader,
  CardHeaderTitle,
  COLOR_SHADES,
  FormFooter,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Textarea,
} from '@premagic/myne';
import { BrowserUrlUtils, BrowserUtils } from '@premagic/utils';
import React, { useState } from 'react';
import MESSAGES from '../../../../../../common/Messages';
import APP_URLS from '../../../../services/AppRouteURLService';

function getEmbedCode(clientWebsiteFolder?: FolderService.FolderType): string {
  if (!clientWebsiteFolder) return `${MESSAGES.CLIENT_WEBSITE.NAME} is not found`;

  const registerUrl = `${clientWebsiteFolder.share_url}${ClientWebsiteService.CLIENT_WEBSITE_LINK.EMBED_BOOK}${EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE}`;
  return BrowserUtils.getIframeEmbedCode({
    url: registerUrl,
    name: 'Registration Form',
    id: 'registration-from-premagic',
  });
}

type Props = {
  projectId?: string;
  eventId: string;
  project?: ProjectService.ProjectType;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  clientWebsite?: FolderService.FolderType;
};
export default function EventEmbedSettings(props: Props): React.ReactElement {
  const { projectId, eventId, projectMeta, clientWebsite, project } = props;
  const [isCopied, showCopiedInfo] = useState(false);

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;
  const urlToEvent = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
    eventId,
  });
  const isEventRegistrationEnabled =
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED];

  return (
    <>
      <InnerPageContentMainCol hasSidebar>
        <Row alignItems="baseline">
          <ButtonIcon title="Back" link={urlToEvent}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
          </ButtonIcon>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            Embed on your website
          </Text>
        </Row>
        <Space vertical size={4} />

        <Card>
          <Space>
            <CardHeader>
              <CardHeaderTitle>
                Registration form
                <Text block>Show the registration form on your website</Text>
              </CardHeaderTitle>
            </CardHeader>
            <Text color={COLOR_SHADES.DARKER}>Paste the following HTML code snippet to your page</Text>
            <Space vertical size={1} />
            {isEventRegistrationEnabled ? (
              <div>
                <Textarea name="code" readOnly>
                  {getEmbedCode(clientWebsite)}
                </Textarea>
                <FormFooter>
                  <Button
                    style={BUTTON_STYLES.PRIMARY}
                    disabled={isCopied}
                    onClick={() => {
                      showCopiedInfo(true);
                      setTimeout(() => showCopiedInfo(false), 2000);
                      BrowserUtils.copyToClipboard(getEmbedCode(clientWebsite));
                    }}
                  >
                    <Icon size={ICON_SIZES.SM} name={isCopied ? 'check' : 'copy'} />
                    <Space size={1} />
                    {isCopied ? 'Copied' : 'Copy to clipboard'}
                  </Button>
                </FormFooter>
              </div>
            ) : (
              <Text>Registration form is disabled for this event, Enable it from event settings</Text>
            )}
          </Space>
        </Card>
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <Space />
      </InnerPageContentSidebar>
    </>
  );
}
