import React from 'react';
import {
  Color,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  Label,
  LABEL_STYLES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { FolderService } from '@premagic/core';
import { SimpleDateUtils } from '@premagic/utils';
import { FOLDER_STATUS } from '../../../../../common/Constants';
import MESSAGES from '../../../../../common/Messages';

type AccountFolderItemIconProps = {
  folder_type: FolderService.FOLDER_TYPES;
  status: FOLDER_STATUS;
};

function AccountFolderItemIcon(props: AccountFolderItemIconProps) {
  const { folder_type: folderType, status } = props;
  const isPublished = [FOLDER_STATUS.DONE, FOLDER_STATUS.REVIEW_COMPLETED].includes(status);

  switch (folderType) {
    case FolderService.FOLDER_TYPES.SELECTION:
      return (
        <Color inline shade={COLOR_SHADES.BLUE_LIGHT} fillSolidIcon={isPublished}>
          <Icon name="folder_simple" size={ICON_SIZES.SM} />
        </Color>
      );

    case FolderService.FOLDER_TYPES.HIGHLIGHT:
      return (
        <Color inline shade={COLOR_SHADES.WARNING} fillSolidIcon={isPublished}>
          <Icon name="folder_simple" size={ICON_SIZES.SM} />
        </Color>
      );

    case FolderService.FOLDER_TYPES.CLIENT_WEBSITE:
      return (
        <Color inline shade={isPublished ? COLOR_SHADES.BLUE : COLOR_SHADES.LIGHT}>
          <Icon name="feather" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
        </Color>
      );

    default:
      return (
        <Color inline shade={COLOR_SHADES.SECONDARY} fillSolidIcon>
          <Icon name="folder_simple" size={ICON_SIZES.SM} />
        </Color>
      );
  }
}

const TOOLTIP_TITLE: Record<FolderService.FOLDER_TYPES, string> = {
  [FolderService.FOLDER_TYPES.HIGHLIGHT]: 'Signature album',
  [FolderService.FOLDER_TYPES.CLIENT_WEBSITE]: MESSAGES.CLIENT_WEBSITE.NAME,
  [FolderService.FOLDER_TYPES.SELECTION]: 'Photo selection folder',
  [FolderService.FOLDER_TYPES.PORTFOLIO]: 'Portfolio folder',
  [FolderService.FOLDER_TYPES.PROPOSAL]: 'Proposal folder',
  [FolderService.FOLDER_TYPES.SPONSOR]: 'Sponsor',
  [FolderService.FOLDER_TYPES.BRANDING]: 'Branding',
};

type Props = {
  folder?: FolderService.FolderType;
  isFolderBlocked?: boolean;
  isFolderPublic?: boolean;
};

export default function AccountFolderItem(props: Props): React.ReactElement | null {
  const { folder, isFolderBlocked, isFolderPublic } = props;
  if (!folder) return null;
  const { folder_name: folderName, folder_type: folderType, created_at: createdAt, status, stats } = folder;
  // const isDraft = isUndefined(isShared) ? status === FOLDER_STATUS.DRAFT : !isShared;
  const isDraft = status === FOLDER_STATUS.DRAFT;
  const createdAtDate = createdAt && SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt);
  const tooltipMessage = `${TOOLTIP_TITLE[folderType]}(${stats?.total_image || 0} Files) was added ${
    createdAt && SimpleDateUtils.fromNowForTooltip(createdAtDate)
  }`;

  return (
    <Tooltip placement="top" message={tooltipMessage}>
      <ErrorBoundary>
        <Row vcenter>
          <AccountFolderItemIcon folder_type={folderType} status={status} />
          <Space size={1} />
          <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3}>
            {folderName}
          </Text>

          {isDraft && (
            <>
              <Space />
              <div>
                <Space vertical size={1} />
                <Text color={COLOR_SHADES.DANGER} size={TEXT_SIZE.SIZE_5}>
                  Draft
                </Text>
              </div>
            </>
          )}
          {isFolderBlocked && (
            <>
              <Space size={1} />
              <Label style={LABEL_STYLES.PILL_DANGER}>Blocked</Label>
            </>
          )}
          {!isDraft && isFolderPublic && (
            <>
              <Space />
              <div>
                <Space vertical size={1} />
                <Text color={COLOR_SHADES.BLUE} size={TEXT_SIZE.SIZE_5}>
                  Shown in guest gallery
                </Text>
              </div>
            </>
          )}
        </Row>
      </ErrorBoundary>
    </Tooltip>
  );
}
