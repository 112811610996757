import { Map as IMap, List as IList } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { GlobalVariableService } from '@premagic/core';
import { ArrayUtils, BrowserUrlUtils, ErrorTracker, ActionTypeUtils, EnvUtils } from '@premagic/utils';
import MESSAGES from '../../../../../common/Messages';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import {
  createUser,
  deleteUser,
  editUser,
  fetchRequestingUser,
  fetchUsers,
  USER_ROLE,
  USER_SERVICE_TYPE,
  USER_SERVICE_TYPE_DETAILS,
  UserType,
} from './UsersService';
import { UserSchema, UsersSchema } from '../../../../schema/Schemas';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import APP_URLS from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';
import { PERMISSION_FOR_ROLES, PERMISSIONS } from './UserPermissionService';
import { addAppAlert } from '../../app-alerts/AccountAppAlertsDataDuck';
import { ACCOUNT_APP_ALERT_TYPE } from '../../app-alerts/AccountAppAlertService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_USERS');

const setUsersData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addUserData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
export const setRequestingUser = createAction(
  getActionType('DATA', 'SET_REQUESTING_USER'),
  (dispatch, userId, user: UserType) => {
    GlobalVariableService.setGlobalVariableForPM({
      requestingUser: {
        email: user.email,
        id: userId,
        name: user.name,
      },
    });
    return user;
  },
);
const updateUserData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
const removeUserData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const fetchUsersData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.USERS.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchUsers()
    .then((response) => {
      const normalizedData = normalize(response.results, UsersSchema);
      dispatch(setUsersData(dispatch, normalizedData.entities.users));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Users: fetch failed', e);
    });
});

export const addNewUser = createAction(getActionType('DATA', 'CREATE'), (dispatch, formResponse: FormResponseType) => {
  const loadingKey = LOADINGS.USERS.ADD;
  if (formResponse.errors) {
    dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
    return;
  }
  dispatch(clearErrorState(dispatch, loadingKey));
  dispatch(toggleLoadingState(dispatch, loadingKey, true));

  createUser({
    name: formResponse.data.name as string,
    email: ((formResponse.data.email as string) || '').toLowerCase(),
    role: formResponse.data.role as USER_ROLE,
    phone_number: formResponse.data.phone_number as string,
    service_role: formResponse.data.service_role as USER_SERVICE_TYPE,
    charges_per_hour: Number(formResponse.data.charges_per_hour) || 0,
  })
    .then((response) => {
      const normalizedData = normalize(response, UserSchema);
      dispatch(addUserData(dispatch, normalizedData.entities.users));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.USERS.SHOW_ADD_PANEL, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('User: add new user failed', e);
    });
});

export const saveUser = createAction(
  getActionType('DATA', 'EDIT'),
  (dispatch, userId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.USERS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    if (formResponse.errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }
    editUser(userId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, UserSchema);
        dispatch(updateUserData(dispatch, normalizedData.entities.users));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        const usersPages = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.USERS, {});
        navigateTo(dispatch, usersPages);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('User: Edit user failed', e);
      });
  },
);

export const fetchRequestingUserData = createAction(
  getActionType('REQUESTING_USER_PROFILE_DATA', 'FETCH'),
  (dispatch) => {
    const loadingKey = LOADINGS.USERS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    fetchRequestingUser()
      .then((requestingUser) => {
        if (requestingUser.real_me !== requestingUser.email) {
          dispatch(
            addAppAlert(dispatch, {
              id: 'proxy-user',
              title: MESSAGES.USER.USER_PROXY_MESSAGE,
              type: ACCOUNT_APP_ALERT_TYPE.DANGER,
              readOnly: true,
            }),
          );
        }
        dispatch(setRequestingUser(dispatch, requestingUser.id, requestingUser));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return requestingUser;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Requesting User Profile : fetch user failed', e);
      });
  },
);

export const saveUserProfile = createAction(
  getActionType('DATA_PROFILE', 'EDIT'),
  (dispatch, userId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.USERS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    if (formResponse.errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return;
    }
    editUser(userId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, UserSchema);
        dispatch(updateUserData(dispatch, normalizedData.entities.users));
        dispatch(fetchRequestingUserData(dispatch));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        const profilePage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.USER_PROFILE.INDEX, {});
        navigateTo(dispatch, profilePage);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('User Profile: Edit user failed', e);
      });
  },
);

export const removeUser = createAction(getActionType('DATA', 'DELETE'), (dispatch, userId: string) => {
  const loadingKey = LOADINGS.USERS.DELETE;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  deleteUser(userId)
    .then((response) => {
      const usersPages = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.USERS, {});
      navigateTo(dispatch, usersPages);
      dispatch(removeUserData(dispatch, userId));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('User: Delete failed', e);
    });
});

type UserStateType = {
  items: IMap<string, UserType>;
  requestingUser?: UserType;
};
const initialState = {
  items: IMap({}),
  requestingUser: null,
};

export default handleActions(
  {
    [setUsersData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [setRequestingUser.toString()]: (state, action: { payload }) => ({
      ...state,
      requestingUser: action.payload,
    }),
    [addUserData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateUserData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeUserData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.remove(action.payload),
    }),
  },
  initialState,
);

const usersDataSelector = createSelector(entitiesDataSelector, (entities) => entities.crm.users as UserStateType);

export const userEntitiesSelector = createSelector(usersDataSelector, (state) => state.items);
export const requestingUserIdSelector = createSelector(
  usersDataSelector,
  (state) => state.requestingUser?.id as string,
);
export const requestingUserSelector = createSelector(usersDataSelector, (users) => users.requestingUser);
export const requestingUserNameSelector = createSelector(
  requestingUserSelector,
  (requestingUser) => requestingUser?.name,
);
export const hasPermission = (permission: PERMISSIONS) =>
  createSelector(requestingUserSelector, (user) => {
    if (!user) return false;

    return PERMISSION_FOR_ROLES[user.role].includes(permission);
  });

export const isStaffUserSelector = createSelector(
  requestingUserSelector,
  (requestingUser) => (EnvUtils.isProd() ? requestingUser?.is_staff || false : true), // We are assuming all the users on local are staff users
);

export const usersItemsSelector = createSelector(userEntitiesSelector, (users) => users.toJSON());
export const usersItemsArraySelector = createSelector(userEntitiesSelector, (users) =>
  users
    .sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name))
    .valueSeq()
    .toArray(),
);

export const usersKeysSelector = createSelector(userEntitiesSelector, (users) =>
  users
    .sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name))
    .keySeq()
    .toArray(),
);

export type UsersSelectInputOptionType = Array<{
  label: USER_SERVICE_TYPE;
  options: Array<{ label: string; value: string }>;
}>;

export const usersItemsOptionsForSelectInputSelector = createSelector(
  userEntitiesSelector,
  (users) =>
    users
      .sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name))
      .groupBy((user) => user.service_role)
      .reduce(
        (result, serviceRoleGroup, userServiceType) =>
          result.push({
            label: userServiceType,
            options: serviceRoleGroup
              .map((user) => ({
                label: user.name,
                value: user.id,
                icon: USER_SERVICE_TYPE_DETAILS[user.service_role].icon,
              }))
              .valueSeq()
              .toArray(),
          }),
        IList(),
      )
      .toJSON() as UsersSelectInputOptionType,
);
