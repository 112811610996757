import { connect } from 'react-redux';
import { MODALS } from '../../../../../common/Constants';
import FolderCoverFocalPoint from './FolderCoverFocalPoint';
import { isModalOpenSelector, toggleModalVisibility, modalOptionsSelector } from '../../../../../common/ModalDuck';
import { folderMetaDataForFolderSelector, uploadFolderCoverPhotoFocalPoint } from '../folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const options = modalOptionsSelector(MODALS.FOLDER_COVER_FOCAL_POINT_MODAL)(state);
  const folderId = options?.folderId || '';
  return {
    projectId,
    folderId,
    isFocalPointModalOpen: isModalOpenSelector(MODALS.FOLDER_COVER_FOCAL_POINT_MODAL)(state),
    folderMeta: folderMetaDataForFolderSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.FOLDER_COVER_FOCAL_POINT_MODAL, false));
    },
    setFocalPoint: (projectId, folderId, cordinates) => {
      dispatch(uploadFolderCoverPhotoFocalPoint(dispatch, projectId, folderId, cordinates));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderCoverFocalPoint);
