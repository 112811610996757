import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';
import { isFeatureMagazineEnabledSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';

import EventPostersLayout from './EventPostersLayout';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { eventProjectSelectorSelector } from '../../../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    projectId,
    focusId,
    hasPermissions: {
      [PERMISSIONS.EVENT_POSTERS_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_POSTERS_MANAGEMENT)(state),
    },
    isFeatureMagazineEnabled: isFeatureMagazineEnabledSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {},
    showAddNewEventPoster: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPostersLayout);
