import { connect } from 'react-redux';
import { ClientSettingsService, ProjectMetaService, StorePricingService } from '@premagic/core';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../project-meta/ProjectsMetaDataDuck';
import { clientSettingsDataSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import StorePricingModalPhotoDownload from './StorePricingModalPhotoDownload';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  const projectMeta = projectMetaDataForProjectSelector(projectId)(state) || {};
  const clientSettings = clientSettingsDataSelector(state) || {};

  const photoDownloadPrice =
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE] ||
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.STORE_PRICING]?.[
      StorePricingService.SUBPRODUCTS_IDS.HIGH_RES_PHOTO
    ] ||
    0;

  const currencyCode =
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.STORE_PRICE_CURRENCY_CODE] ||
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.STORE_PRICING]?.[
      StorePricingService.STORE_CURRENCY
    ] ||
    'INR';

  return {
    projectId,
    isStorePriceSettingModalOpen: isModalOpenSelector(LOADINGS.STORE_PRICE.SHOW_PRICE_SETTING_MODAL)(state),
    photoDownloadPrice,
    currencyCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeStorePriceModal: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.STORE_PRICE.SHOW_PRICE_SETTING_MODAL, false));
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePricingModalPhotoDownload);
