import { QRCodeUtils } from '@premagic/utils';
import { useEffect, useState } from 'react';

// have to give separate width for different poster types for perfect render in Safari
export default function useQRCode(url: string, width: number) {
  const [qrCode, setQrCode] = useState<{ qrSvgImage: string; rawQrSvg: string } | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    QRCodeUtils.getCodeInSVG(url, width).then((data) => {
      const blob = new Blob([data], { type: 'image/svg+xml' });
      const resUrl = URL.createObjectURL(blob);
      setQrCode((prev) => ({ ...prev, qrSvgImage: resUrl, rawQrSvg: data }));
      setIsLoading(false);
    });
  }, [url]);

  return { qrCode, isLoading };
}
