import { connect } from 'react-redux';
import PhotoSliderWidgetPreview from './PhotoSliderWidgetPreview';
import { isAnyHighlightFolderMadePublicSelector } from '../../../projects/folders/AccountFoldersDataDuck';

function mapStateToProps(state, ownProps: { shareId?: string }) {
  const { shareId } = ownProps;

  return { shareId, isAnyFolderMadePublic: isAnyHighlightFolderMadePublicSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSliderWidgetPreview);
