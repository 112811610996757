import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import ExpensePaymentFormContainer from './ExpensePaymentFormContainer';

type Props = {
  eventId: string;
  paymentId: string;
  updatePayment: (paymentId: string, eventId: string, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
};

export default function EditExpensePaymentPanel(props: Props): JSX.Element {
  const { show, updatePayment, isLoading, errors, closePanel, eventId, paymentId } = props;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit expense</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={(data) => updatePayment(paymentId, eventId, data)}
          formSpec={{
            is_settled: {
              type: 'boolean',
            },
            payment_category: {
              type: 'number',
            },
          }}
        >
          <ExpensePaymentFormContainer />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
