export enum POSTER_WIDGET_TYPE {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AVATAR = 'AVATAR',
  GUEST_IMAGE = 'GUEST_IMAGE',
  GUEST_IMAGE_GALLERY = 'GUEST_IMAGE_GALLERY',
  GUEST_IMAGE_PORTRAIT = 'GUEST_IMAGE_PORTRAIT',
  CIRCLE = 'CIRCLE',
  BOX = 'BOX',
  QR_CODE = 'QR_CODE',
}

export type PosterWidgetBaseType = {
  id: string;
  name: string;
  position: {
    x: number;
    y: number;
    z: number;
    rotation?: number;
  };
  size: {
    width: number;
    height?: number;
  };
  value?: string;
  created_at: string; // ISO string
  updated_at: string; // ISO string
  opacity?: number;
  selectable?: boolean; // only for front-end
  is_system_widget?: boolean; // only for front-end
  is_hidden?: boolean;
};

export type PosterWidgetImageType = PosterWidgetBaseType & {
  type: POSTER_WIDGET_TYPE.IMAGE;
  corner_radius?: number;
};

export enum AVATAR_STYLE {
  CIRCLE = 'circle',
  ROUNDED = 'rounded',
  SQUARE = 'square',
  WITHOUT_BACKGROUND = 'without-background',
}

export const AVATAR_STYLE_DETAILS: Record<AVATAR_STYLE, { title: string }> = {
  [AVATAR_STYLE.CIRCLE]: { title: 'Circle' },
  [AVATAR_STYLE.ROUNDED]: { title: 'Square with rounded corner' },
  [AVATAR_STYLE.SQUARE]: { title: 'Square' },
  [AVATAR_STYLE.WITHOUT_BACKGROUND]: { title: 'Remove Background' },
};

export type PosterWidgetAvatarType = PosterWidgetBaseType & {
  type: POSTER_WIDGET_TYPE.AVATAR;
  style?: AVATAR_STYLE;
  font_color?: string;
};

// https://konvajs.org/api/Konva.Text.html
export type PosterWidgetTextType = PosterWidgetBaseType & {
  type: POSTER_WIDGET_TYPE.TEXT;
  font_family?: string;
  font_weight?: 400 | 500 | 700;
  font_color?: string;
  font_size?: number;
  font_line_height?: number;
  ellipsis?: boolean;
  font_align?: 'left' | 'center' | 'right';
  font_vertical_align?: 'top' | 'middle' | 'bottom';
  font_variant?: 'normal' | 'small-caps';
};
// NOTE: CAMEL CASE Variable are not on server. They are only for front-end, will need to add to WidgetDTO if needed
// SHAPES
type PosterWidgetShapeType = PosterWidgetBaseType & {
  fill?: string;
  strokeWidth?: number;
  stroke?: string; // color
  dash?: number[];
  corner_radius?: number;
  shadowColor?: string;
  shadowBlur?: number;
  shadowOpacity?: number;
};

// https://konvajs.org/api/Konva.Rect.html
export type PosterWidgetRectType = PosterWidgetShapeType & {
  type: POSTER_WIDGET_TYPE.BOX;
};

export type PosterWidgetCircleType = PosterWidgetShapeType & {
  type: POSTER_WIDGET_TYPE.CIRCLE;
  style?: 'circle' | 'rounded' | 'square';
};
export type PosterWidgetQRCodeType = PosterWidgetShapeType & {
  type: POSTER_WIDGET_TYPE.QR_CODE;
};

export type PosterWidgetGuestImageType = PosterWidgetBaseType & {
  type: POSTER_WIDGET_TYPE.GUEST_IMAGE;
  corner_radius?: number;
  auto_populate_value_from_folder_id?: string;
};
export type PosterGuestImageGalleryType = PosterWidgetBaseType & {
  type: POSTER_WIDGET_TYPE.GUEST_IMAGE_GALLERY;
  value: Array<string>;
  auto_populate_value_from_folder_id?: string;
};

export type PosterGuestImagePortraitType = PosterWidgetBaseType & {
  type: POSTER_WIDGET_TYPE.GUEST_IMAGE_PORTRAIT;
};

export type PosterWidgetType =
  | PosterWidgetImageType
  | PosterWidgetAvatarType
  | PosterWidgetTextType
  | PosterWidgetRectType
  | PosterWidgetCircleType
  | PosterWidgetQRCodeType
  | PosterWidgetGuestImageType
  | PosterGuestImageGalleryType
  | PosterGuestImagePortraitType;

export function getCornerRadiusFromStyle(style: AVATAR_STYLE, size: number) {
  switch (style) {
    case AVATAR_STYLE.ROUNDED:
      return size / 20;
    case AVATAR_STYLE.SQUARE:
    case AVATAR_STYLE.WITHOUT_BACKGROUND:
      return 0;
    case AVATAR_STYLE.CIRCLE:
    default:
      return size / 2 || 100;
  }
}

export enum BADGE_PUNCH_HOLE_STYLE {
  NONE = 'none',
  CIRCLE = 'circle',
  RECTANGLE = 'rectangle',
  CIRCLE_BOTH_SIDES = 'circle-both-sides',
  RECTANGLE_BOTH_SIDES = 'rectangle-both-sides',
}
export const BADGE_PUNCH_HOLE_STYLE_DETAILS: Record<BADGE_PUNCH_HOLE_STYLE, { title: string }> = {
  [BADGE_PUNCH_HOLE_STYLE.NONE]: { title: 'None' },
  [BADGE_PUNCH_HOLE_STYLE.CIRCLE]: { title: 'Circle' },
  [BADGE_PUNCH_HOLE_STYLE.RECTANGLE]: { title: 'Rectangle' },
  [BADGE_PUNCH_HOLE_STYLE.CIRCLE_BOTH_SIDES]: { title: 'Circle Both Sides' },
  [BADGE_PUNCH_HOLE_STYLE.RECTANGLE_BOTH_SIDES]: { title: 'Rectangle Both Sides' },
};
