import { connect } from 'react-redux';
import AppLayoutMainNavbar from './AppLayoutMainNavbar';
import { PERMISSIONS } from '../crm/users/UserPermissionService';
import { hasPermission } from '../crm/users/UsersDataDuck';
import { isMobileUp } from '../AppDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureLeadEnabledSelector,
  isFeatureFinancialsEnabledSelector,
  isFeatureProjectsTabEnabledSelector,
  isFeatureDAMLibraryEnabledSelector,
} from '../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  return {
    hasPermissions: {
      [PERMISSIONS.DASHBOARD]: hasPermission(PERMISSIONS.DASHBOARD)(state),
      [PERMISSIONS.SETTINGS]: hasPermission(PERMISSIONS.SETTINGS)(state),
      [PERMISSIONS.EVENT_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_MANAGEMENT)(state),
      [PERMISSIONS.EVENT_LIST]: hasPermission(PERMISSIONS.EVENT_LIST)(state),
      [PERMISSIONS.EVENT_DELIVERABLE_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_DELIVERABLE_MANAGEMENT)(state),
      [PERMISSIONS.LEAD_MANAGEMENT]: hasPermission(PERMISSIONS.LEAD_MANAGEMENT)(state),
      [PERMISSIONS.REPORTS]: hasPermission(PERMISSIONS.REPORTS)(state),
      [PERMISSIONS.FINANCIALS]: hasPermission(PERMISSIONS.FINANCIALS)(state),
      [PERMISSIONS.DAM_LIBRARY]: hasPermission(PERMISSIONS.DAM_LIBRARY)(state),
    },
    isMobileUp: isMobileUp(state),
    hasLeadsModule: isFeatureLeadEnabledSelector(state),
    isFeatureFinancialsEnabled: isFeatureFinancialsEnabledSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    isFeatureProjectsTabEnabled: isFeatureProjectsTabEnabledSelector(state),
    isFeatureLibraryEnabled: isFeatureDAMLibraryEnabledSelector(state),
  };
}

export default connect(mapStateToProps)(AppLayoutMainNavbar);
