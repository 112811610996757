import { connect } from 'react-redux';
import { projectMetaDataForProjectSelector } from '../../project-meta/ProjectsMetaDataDuck';
import { photographerNameSelector } from '../../../settings/company/CompanyDataDuck';
import SocialMessageForm from './SocialMessageForm';
import { projectNameSelector } from '../../AccountProjectsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    id?: string;
  },
) {
  const { projectId, id } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    socialMessage: id ? projectMeta?.social_share_messages?.[id] : undefined,
    companyName: photographerNameSelector(state),
    projectName: projectNameSelector(projectId)(state),
  };
}

export default connect(mapStateToProps)(SocialMessageForm);
