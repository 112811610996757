import { ClientsService, EventsService, FolderService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Col,
  DateRangePickerWithTimezoneSelector,
  Dialog,
  DIALOG_SIZES,
  DialogTitle,
  FadeIn,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Input,
  RealCheckbox,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { FormUtils, i18n, SimpleDateUtils } from '@premagic/utils';
import { useRef, useState } from 'react';
import MESSAGES from '../../../../../../common/Messages';
import InputPhoneNumberContainer from '../../../acccount/InputPhoneNumberContainer';
import SharePanelClientDetails from '../../../folder-share/SharePanelClientDetails';
import AddNewClientForm from '../../clients/AddNewClientForm';
import ConvertToProjectButtonContainer from '../../event-project/ConvertToProjectButtonContainer';

type CreateBasicClientNamesProps = {
  event: EventsService.EventType;
};
function CreateBasicClientNames(props: CreateBasicClientNamesProps) {
  const { event } = props;

  if (event.name === EventsService.EVENT_CATEGORY.WEDDING) {
    return (
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired>Groom Name</FormLabel>
            <Input name="groom_name" placeholder="eg. John" required autoFocus />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel>Bride Name</FormLabel>
            <Input name="bride_name" placeholder="eg. Angelina" />
          </FormGroup>
        </Col>
      </Row>
    );
  }
  return (
    <FormGroup>
      <FormLabel isRequired>Name</FormLabel>
      <Input name="groom_name" placeholder="eg. John" required autoFocus />
    </FormGroup>
  );
}

type ClientData = {
  name: string;
  phone_number: string;
  email: string;
};

type Props = {
  eventId: string;
  projectId: string;
  event: EventsService.EventType;
  client?: ClientsService.ClientType;
  createClientWebsite: (data: FormResponseType, langCode: i18n.LANGUAGE_CODE) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  folders: Record<string, FolderService.FolderType>;
  langCode: i18n.LANGUAGE_CODE;
  isCompanyTypeNotPhotographer?: boolean;
};

export default function CreateBasicClientWebsitePanel(props: Props): JSX.Element {
  const {
    show,
    createClientWebsite,
    isLoading,
    errors,
    closePanel,
    eventId,
    projectId,
    event,
    client,
    folders,
    langCode,
    isCompanyTypeNotPhotographer,
  } = props;

  const [showClientDetails, setShowClientDetails] = useState(true);

  const sharedFolders = Object.values(folders).find((item) => item.status === 'DONE');
  const clinetsDetails = Object.values(sharedFolders?.clients || {});

  const defaultClients = clinetsDetails.filter((clientData) => client?.email !== clientData.email);

  const target = useRef(null);

  const [showAddNewClientForm, setAddNewClientFormStatus] = useState(false);
  const [newClientDetails, setNewClientDetails] = useState<Array<ClientData>>(defaultClients);

  function onNewClientDataSubmit(clientData) {
    setAddNewClientFormStatus(false);
    setNewClientDetails([...newClientDetails, clientData.data]);
  }

  function onClose() {
    setAddNewClientFormStatus(false);
    closePanel();
  }

  if (!client) return <div />;

  function onCreate(response) {
    const { data } = response;
    if (!client) return;
    createClientWebsite(
      {
        errors: response.errors,
        data: {
          ...data,
          eventName: event.name,
          projectId,
          eventId,
          clientId: client.id,
          otherClients: newClientDetails,
        },
      },
      langCode,
    );
  }
  if (!projectId || projectId === 'no-project-id')
    return (
      <WindowPanel show={show}>
        <WindowPanelHeader onClose={onClose}>
          <WindowPanelTitle>Would you like to create & share {MESSAGES.CLIENT_WEBSITE.NAME}?</WindowPanelTitle>
        </WindowPanelHeader>
        <WindowPanelContent>
          <Text>You can only create {MESSAGES.CLIENT_WEBSITE.NAME} after you convert to Project</Text>
          <Space vertical />
          <ConvertToProjectButtonContainer eventId={eventId} />
        </WindowPanelContent>
      </WindowPanel>
    );
  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={onClose}>
        <WindowPanelTitle>Would you like to create & share {MESSAGES.CLIENT_WEBSITE.NAME}?</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Dialog target={target.current} show={showAddNewClientForm} onClose={() => {}} size={DIALOG_SIZES.AUTO}>
          <DialogTitle>Add new host</DialogTitle>
          <AddNewClientForm onCancel={() => setAddNewClientFormStatus(false)} onSubmit={onNewClientDataSubmit} />
        </Dialog>
        <Form
          errors={errors}
          onSubmit={onCreate}
          formSpec={{
            share_with_client: {
              type: 'boolean',
            },
          }}
        >
          <CreateBasicClientNames event={event} />
          <FormGroup>
            <FormLabel isRequired>Venue Address</FormLabel>
            <Input name="event[location]" placeholder="eg. Le Méridien Bangalore" required />
          </FormGroup>
          <DateRangePickerWithTimezoneSelector
            nameForStartDate="event[start_date]"
            nameForEndDate="event[end_date]"
            nameForTimezoneInput="event[date_timezone]"
            title="Event Date"
            showTimePicker
            minDate={SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO)}
            hideEndDate={!isCompanyTypeNotPhotographer}
          />

          <Space vertical size={8} />
          <FormGroup>
            <RealCheckbox
              name="share_with_client"
              checked={showClientDetails}
              onChange={() => setShowClientDetails(!showClientDetails)}
            >
              <Text boldness={TEXT_BOLDNESS.BOLD}>Share</Text>
            </RealCheckbox>
          </FormGroup>
          {showClientDetails && (
            <FadeIn>
              <Card style={CARD_STYLES.SECONDARY}>
                <Space>
                  <FormGroup>
                    <FormLabel isRequired>Email address</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      patten={FormUtils.FORM_INPUT_PATTERNS.EMAIL}
                      placeholder="eg. aman@premagic.com"
                      defaultValue={client.email}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel isRequired>Phone number</FormLabel>
                    <InputPhoneNumberContainer name="clientPhone" required defaultValue={client.phone_number} />
                  </FormGroup>
                  <Row vcenter>
                    <Icon name="gmail_solid_color" size={ICON_SIZES.SM} />
                    <Space size={1} />
                    <Icon name="whatsapp_solid_color" size={ICON_SIZES.SM} />
                    <Space size={1} />
                    <Text>Share through Whatsapp and Email</Text>
                  </Row>
                </Space>
              </Card>
              {newClientDetails.map((clientData) => (
                <div key={clientData.email}>
                  <Space vertical />
                  <SharePanelClientDetails client={clientData} />
                </div>
              ))}
              <Space vertical />
              <Button style={BUTTON_STYLES.LINK} onClick={() => setAddNewClientFormStatus(true)} ref={target}>
                Add host
              </Button>
            </FadeIn>
          )}
          <Space vertical />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              {showClientDetails ? 'Share' : 'Create'} {MESSAGES.CLIENT_WEBSITE.NAME}
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
