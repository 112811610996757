import { GROUPED_BUTTON_STYLES, GroupedButton, GroupedButtons, Icon, ICON_SIZES } from '@premagic/myne';
import React from 'react';

type Props = {
  showAttendeeEditPanel: (id?: string) => void;
  navigation: {
    next?: string;
    previous?: string;
  };
};

export default function EditEventAttendeeNavButtons(props: Props): JSX.Element | null {
  const { navigation, showAttendeeEditPanel } = props;

  return (
    <GroupedButtons style={GROUPED_BUTTON_STYLES.COMPACT}>
      <GroupedButton disabled={!navigation.previous} onClick={() => showAttendeeEditPanel(navigation.previous)}>
        <Icon name="chevron_left" size={ICON_SIZES.SM} />
      </GroupedButton>
      <GroupedButton disabled={!navigation.next} onClick={() => showAttendeeEditPanel(navigation.next)}>
        <Icon name="chevron_right" size={ICON_SIZES.SM} />
      </GroupedButton>
    </GroupedButtons>
  );
}
