import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Icon,
  ICON_SIZES,
  List,
  LoadingDots,
  Row,
  Space,
  SubTitle,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import EventAttendeeFaceCheckinZonesListItemContainer from './EventAttendeeFaceCheckinZonesListItemContainer';

type Props = {
  zoneIds?: Array<string>;
  isLoading?: boolean;
  isAddingZone?: boolean;
  openAddZonePanel: () => void;
  projectId: string;
};

export default function EventAttendeeFaceCheckinZonesList(props: Props) {
  const { zoneIds, isLoading, openAddZonePanel, isAddingZone, projectId } = props;

  return (
    <div>
      <Row>
        <Space />
        <SubTitle>Zones</SubTitle>
      </Row>

      {(() => {
        if (isLoading) {
          return <LoadingDots size="sm" />;
        }

        if (!zoneIds?.length) {
          return (
            <Row>
              <Space />
              <Text muted size={TEXT_SIZE.SIZE_5}>
                No zones are added
              </Text>
            </Row>
          );
        }

        return (
          <List>
            {zoneIds.map((id) => (
              <EventAttendeeFaceCheckinZonesListItemContainer key={id} projectId={projectId} zoneId={id} />
            ))}
          </List>
        );
      })()}
      <Space size={2} vertical />
      <Row>
        <Space />
        <Button onClick={openAddZonePanel} isLoading={isAddingZone} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
          <Icon name="plus" size={ICON_SIZES.SM} />
          <Space size={1} />
          Zone
        </Button>
      </Row>
    </div>
  );
}
