import { USER_ROLE } from './UsersService';

export enum PERMISSIONS {
  DASHBOARD = 'dashboard',
  LEAD_MANAGEMENT = 'lead.management',
  EVENT_MANAGEMENT = 'event.management',
  EVENT_LIST = 'event.list',
  FINANCIALS = 'org.financial',
  EVENT_DETAILS = 'event.details',
  EVENT_FINANCIAL = 'event.financial',
  EVENT_FINANCIAL_MANAGE = 'event.financial.manage',
  EVENT_FINANCIAL_EXPENSE = 'event.financial.expense',
  EVENT_FINANCIAL_CLIENT_PAYMENTS = 'event.financial.client-payments',
  EVENT_FINANCIAL_QUOTE = 'event.financial.quote',
  EVENT_OCCASION = 'event.occasion',
  EVENT_OCCASION_MANAGE = 'event.occasion.manage',
  EVENT_DELIVERABLE = 'event.deliverable',
  EVENT_DELIVERABLE_MANAGEMENT = 'event.deliverable.management',
  EVENT_CLIENT = 'event.client',
  EVENT_CLIENT_MANAGEMENT = 'event.client.management',
  EVENT_QUOTE_MANAGEMENT = 'event.quote-management',
  EVENT_SPONSOR = 'event.sponsor',
  EVENT_EXHIBITOR = 'event.exhibitor',
  EVENT_SPONSOR_MANAGEMENT = 'event.sponsor-management',
  EVENT_SPONSOR_REPORT = 'event.sponsor.report',
  EVENT_MARKETING_MANAGEMENT = 'event.marketing.manage',
  EVENT_POSTERS_MANAGEMENT = 'event.posters.mange',
  EVENT_FORM_FIELD = 'event.form-fields',
  EVENT_FORM_FIELD_MANAGEMENT = 'event.form-fields.management',
  EVENT_ATTENDEES = 'event.attendees',
  EVENT_ATTENDEES_MANAGEMENT = 'event.attendees.management',
  EVENT_ATTENDEES_REPORT = 'event.attendees.report',
  EVENT_BADGES = 'event.badges',
  EVENT_SETTINGS = 'event.settings',
  EVENT_AVATAR_STUDIO_MANAGEMENT = 'event.avatar-studio.management',
  USER_MANAGEMENT = 'user.management',
  SETTINGS = 'settings',
  ORG_BILLING_MANAGEMENT = 'billing.management',
  REPORTS = 'reports',
  SPONSOR_CREATIVE = 'sponsor.creative',
  SPONSOR_CREATIVE_MANAGEMENT = 'sponsor.creative-management',
  DAM_LIBRARY = 'dam-library',
}

const OWNER_PERMISSION = [
  PERMISSIONS.DASHBOARD,
  PERMISSIONS.LEAD_MANAGEMENT,
  PERMISSIONS.FINANCIALS,
  PERMISSIONS.EVENT_LIST,
  PERMISSIONS.EVENT_MANAGEMENT,
  PERMISSIONS.EVENT_DETAILS,
  PERMISSIONS.EVENT_FINANCIAL,
  PERMISSIONS.EVENT_FINANCIAL_EXPENSE,
  PERMISSIONS.EVENT_FINANCIAL_MANAGE,
  PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS,
  PERMISSIONS.EVENT_FINANCIAL_QUOTE,
  PERMISSIONS.EVENT_OCCASION,
  PERMISSIONS.EVENT_OCCASION_MANAGE,
  PERMISSIONS.EVENT_DELIVERABLE,
  PERMISSIONS.EVENT_DELIVERABLE_MANAGEMENT,
  PERMISSIONS.EVENT_CLIENT,
  PERMISSIONS.EVENT_CLIENT_MANAGEMENT,
  PERMISSIONS.EVENT_MARKETING_MANAGEMENT,
  PERMISSIONS.EVENT_POSTERS_MANAGEMENT,
  PERMISSIONS.EVENT_BADGES,
  PERMISSIONS.USER_MANAGEMENT,
  PERMISSIONS.ORG_BILLING_MANAGEMENT,
  PERMISSIONS.REPORTS,
  PERMISSIONS.SETTINGS,
  PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT,
  PERMISSIONS.EVENT_QUOTE_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR,
  PERMISSIONS.EVENT_EXHIBITOR,
  PERMISSIONS.EVENT_SPONSOR_MANAGEMENT,
  PERMISSIONS.SPONSOR_CREATIVE,
  PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR_REPORT,
  PERMISSIONS.EVENT_FORM_FIELD,
  PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES,
  PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES_REPORT,
  PERMISSIONS.EVENT_SETTINGS,
  PERMISSIONS.DAM_LIBRARY,
];
const MANAGER_PERMISSION = [
  PERMISSIONS.DASHBOARD,
  PERMISSIONS.LEAD_MANAGEMENT,
  PERMISSIONS.EVENT_LIST,
  PERMISSIONS.EVENT_MANAGEMENT,
  PERMISSIONS.EVENT_DETAILS,
  PERMISSIONS.EVENT_FINANCIAL,
  PERMISSIONS.EVENT_FINANCIAL_EXPENSE,
  PERMISSIONS.EVENT_FINANCIAL_MANAGE,
  PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS,
  PERMISSIONS.EVENT_FINANCIAL_QUOTE,
  PERMISSIONS.EVENT_OCCASION,
  PERMISSIONS.EVENT_OCCASION_MANAGE,
  PERMISSIONS.EVENT_DELIVERABLE,
  PERMISSIONS.EVENT_DELIVERABLE_MANAGEMENT,
  PERMISSIONS.EVENT_CLIENT,
  PERMISSIONS.EVENT_CLIENT_MANAGEMENT,
  PERMISSIONS.EVENT_MARKETING_MANAGEMENT,
  PERMISSIONS.EVENT_POSTERS_MANAGEMENT,
  PERMISSIONS.EVENT_BADGES,
  PERMISSIONS.REPORTS,
  PERMISSIONS.SETTINGS,
  PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR,
  PERMISSIONS.EVENT_SPONSOR_MANAGEMENT,
  PERMISSIONS.SPONSOR_CREATIVE,
  PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR_REPORT,
  PERMISSIONS.EVENT_FORM_FIELD,
  PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES,
  PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES_REPORT,
  PERMISSIONS.EVENT_SETTINGS,
  PERMISSIONS.DAM_LIBRARY,
];

const EVENT_OPERATOR_PERMISSION = [
  PERMISSIONS.DASHBOARD,
  PERMISSIONS.EVENT_LIST,
  PERMISSIONS.EVENT_MANAGEMENT,
  PERMISSIONS.EVENT_DETAILS,
  PERMISSIONS.EVENT_FINANCIAL,
  PERMISSIONS.EVENT_FINANCIAL_EXPENSE,
  PERMISSIONS.EVENT_OCCASION,
  PERMISSIONS.EVENT_OCCASION_MANAGE,
  PERMISSIONS.EVENT_DELIVERABLE,
  PERMISSIONS.EVENT_DELIVERABLE_MANAGEMENT,
  PERMISSIONS.EVENT_CLIENT,
  PERMISSIONS.EVENT_CLIENT_MANAGEMENT,
  PERMISSIONS.EVENT_MARKETING_MANAGEMENT,
  PERMISSIONS.EVENT_POSTERS_MANAGEMENT,
  PERMISSIONS.EVENT_BADGES,
  PERMISSIONS.REPORTS,
  PERMISSIONS.EVENT_SPONSOR,
  PERMISSIONS.EVENT_SPONSOR_MANAGEMENT,
  PERMISSIONS.SPONSOR_CREATIVE,
  PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR_REPORT,
  PERMISSIONS.EVENT_FORM_FIELD,
  PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES,
  PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES_REPORT,
  PERMISSIONS.EVENT_SETTINGS,
  PERMISSIONS.DAM_LIBRARY,
];
const SALES_REP_PERMISSION = [
  PERMISSIONS.DASHBOARD,
  PERMISSIONS.LEAD_MANAGEMENT,
  PERMISSIONS.EVENT_LIST,
  PERMISSIONS.EVENT_MANAGEMENT,
  PERMISSIONS.EVENT_DETAILS,
  PERMISSIONS.EVENT_FINANCIAL_EXPENSE,
  PERMISSIONS.EVENT_OCCASION,
  PERMISSIONS.EVENT_OCCASION_MANAGE,
  PERMISSIONS.EVENT_DELIVERABLE,
  PERMISSIONS.EVENT_CLIENT,
  PERMISSIONS.EVENT_CLIENT_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR,
  PERMISSIONS.EVENT_SPONSOR_MANAGEMENT,
  PERMISSIONS.SPONSOR_CREATIVE,
  PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT,
  PERMISSIONS.EVENT_SPONSOR_REPORT,
  PERMISSIONS.EVENT_FORM_FIELD,
  PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT,
  PERMISSIONS.EVENT_ATTENDEES,
  PERMISSIONS.EVENT_ATTENDEES_REPORT,
];

export const PERMISSION_FOR_ROLES: Record<USER_ROLE, Array<Partial<PERMISSIONS>>> = {
  [USER_ROLE.OWNER]: OWNER_PERMISSION,
  [USER_ROLE.SUPER_ADMIN]: OWNER_PERMISSION,
  [USER_ROLE.ACCOUNTS]: [...MANAGER_PERMISSION, PERMISSIONS.FINANCIALS],
  [USER_ROLE.MANAGER]: MANAGER_PERMISSION,
  [USER_ROLE.MARKETING_MANAGER]: MANAGER_PERMISSION,
  [USER_ROLE.EVENT_OPERATOR]: EVENT_OPERATOR_PERMISSION,
  [USER_ROLE.SALES_REP]: SALES_REP_PERMISSION,
  [USER_ROLE.EMPLOYEE]: [
    PERMISSIONS.EVENT_LIST,
    PERMISSIONS.EVENT_DETAILS,
    PERMISSIONS.EVENT_FINANCIAL,
    PERMISSIONS.EVENT_FINANCIAL_EXPENSE,
    PERMISSIONS.EVENT_OCCASION,
  ],
  [USER_ROLE.EXTERNAL_MEMBER]: [PERMISSIONS.EVENT_LIST],
  [USER_ROLE.PHOTOGRAPHER]: [PERMISSIONS.EVENT_LIST],
};
