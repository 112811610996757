import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { clientWesbiteIdForEditSelector } from '../../crm/events/details/EventDetailsPageDuck';
import { projectEventDataSelector, saveProjectMetaData } from '../../projects/project-meta/ProjectsMetaDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureLeadEnabledSelector,
} from '../../settings/preferences/ClientSettingsDataDuck';
import QuickEditClientWebsitePanel from './QuickEditClientWebsitePanel';

const PANEL_KEY = LOADINGS.CLIENT_WEBSITE.SHOW_QUICK_EDIT_PANEL;
function mapStateToProps(
  state,
  ownProps: {
    eventId?: string;
    projectId: string;
  },
) {
  const { eventId, projectId } = ownProps;
  const id = clientWesbiteIdForEditSelector(state);

  return {
    id,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    errors: errorObjectForTypeSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    showPanel: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    eventData: projectEventDataSelector(projectId)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    saveProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickEditClientWebsitePanel);
