import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as ENTranslations from './translations/english.json';
import english from './translations/english.json';
import hindi from './translations/hindi.json';
import telegu from './translations/telegu.json';
import arabic from './translations/arabic.json';
import hebrew from './translations/hebrew.json';
import malayalam from './translations/malayalam.json';
import { isProd } from '../EnvUtils';

const resources = {
  // English
  en: {
    translation: english,
  },
  // Hindi
  hi: {
    translation: hindi,
  },
  // Telegu
  te: {
    translation: telegu,
  },
  // Arabic
  ar: {
    translation: arabic,
  },
  // Hebrew
  heb: {
    translation: hebrew,
  },
  // Malayalam
  ml: {
    translation: malayalam,
  },
};

type TKeys = keyof typeof ENTranslations;

// using this function is causing issue as "Rendered more hooks"
// Might remove this function later.
// Use the t function from i18next directly | import { t } from 'i18next';
function t(textKey: TKeys): string {
  const { t: translate } = useTranslation();

  return translate(textKey);
}

const init = i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // @ts-ignore
    // backend: {
    //   // translation file path
    //   loadPath: './translations/i18n/{{ns}}/{{lng}}.json',
    // },
    resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      espaceValue: false,
    },
    react: {
      wait: true,
    },
  });

export { init, t, TKeys };

export enum LANGUAGE_ID {
  ENGLISH = 'ENGLISH',
  HINDI = 'HINDI',
  TELEGU = 'TELEGU',
  ARABIC = 'ARABIC',
  HEBREW = 'HEBREW',
  MALAYALAM = 'MALAYALAM',
}

export enum LANGUAGE_CODE {
  EN = 'en',
  HI = 'hi',
  TE = 'te',
  AR = 'ar',
  HEB = 'heb',
  ML = 'ml',
}

export const LANGUAGES_DATA: Record<
  LANGUAGE_ID,
  {
    id: LANGUAGE_ID;
    langCode: string;
    langDir: 'ltr' | 'rtl'; // left to right | right to left
    label: string;
  }
> = {
  [LANGUAGE_ID.ENGLISH]: {
    id: LANGUAGE_ID.ENGLISH,
    langCode: LANGUAGE_CODE.EN,
    langDir: 'ltr',
    label: 'English',
  },
  [LANGUAGE_ID.HINDI]: {
    id: LANGUAGE_ID.HINDI,
    langCode: LANGUAGE_CODE.HI,
    langDir: 'ltr',
    label: 'Hindi',
  },
  [LANGUAGE_ID.TELEGU]: {
    id: LANGUAGE_ID.TELEGU,
    langCode: LANGUAGE_CODE.TE,
    langDir: 'ltr',
    label: 'Telegu',
  },
  [LANGUAGE_ID.ARABIC]: {
    id: LANGUAGE_ID.ARABIC,
    langCode: LANGUAGE_CODE.AR,
    langDir: 'rtl',
    label: 'Arabic',
  },
  [LANGUAGE_ID.HEBREW]: {
    id: LANGUAGE_ID.HEBREW,
    langCode: LANGUAGE_CODE.HEB,
    langDir: 'rtl',
    label: 'Hebrew',
  },
  [LANGUAGE_ID.MALAYALAM]: {
    id: LANGUAGE_ID.MALAYALAM,
    langCode: LANGUAGE_CODE.ML,
    langDir: 'ltr',
    label: 'Malayalam',
  },
};
