import React from 'react';
import { FolderService, ProjectMetaService, ProjectService } from '@premagic/core';

import {
  Alert,
  ALERT_STYLES,
  Card,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import PendingPhotographerActionsContainer from '../../photographer-actions/PendingPhotographerActionsContainer';
import SocialShareMessagesContainer from '../../projects/social-media-settings/SocialShareMessagesContainer';
import PhotoSliderWidgetNavigateButtonContainer from '../../widgets/photo-slider-widget/photo-slider-widget-navigate-button/PhotoSliderWidgetNavigateButtonContainer';
import SelectionAlbumCardContainer from './selection-album/SelectionAlbumCardContainer';
import SignatureAlbumCardContainer from './signature-album/SignatureAlbumCardContainer';
import ClientWebsitesSectionContainer from './client-website/ClientWebsitesSectionContainer';
import DeliverablesHeaderContainer from './header/DeliverablesHeaderContainer';
import ConvertToProjectButtonContainer from '../event-project/ConvertToProjectButtonContainer';
import AIPhotoDeliveryCardContainer from '../../client-website/user-registration/AIPhotoDeliveryCardContainer';
import MESSAGES from '../../../../../common/Messages';

type Props = {
  eventId?: string;
  focusId: string;
  project?: ProjectService.ProjectType;
  isLoading: boolean;
  projectId: string;
  showClientWebsite: boolean;
  isCompanyTypeNotPhotographer: boolean;
};

export default function EventDetailsDeliverablesLayout(props: Props): React.ReactElement {
  const { project, isLoading, projectId, focusId, eventId, showClientWebsite, isCompanyTypeNotPhotographer } = props;

  if (isLoading)
    return (
      <InnerPageContentMainCol>
        <Space vertical size={8} />
        <Row vcenter>
          <LoadingDots />
        </Row>
      </InnerPageContentMainCol>
    );

  if (project?.project_id)
    return (
      <>
        <InnerPageContentMainCol>
          {!isCompanyTypeNotPhotographer && (
            <div>
              <DeliverablesHeaderContainer projectId={projectId} />
              <PendingPhotographerActionsContainer projectId={projectId} />
            </div>
          )}
          {showClientWebsite && (
            <ClientWebsitesSectionContainer projectId={projectId} eventId={eventId} focusId={focusId} />
          )}
          <SignatureAlbumCardContainer projectId={projectId} focus={focusId === FolderService.FOLDER_TYPES.HIGHLIGHT} />
          <SelectionAlbumCardContainer projectId={projectId} focus={focusId === FolderService.FOLDER_TYPES.SELECTION} />
          {/* <ProjectAlbumsSectionContainer projectId={projectId} focusId={focusId} /> */}
          <Space vertical size={12} />
          <SocialShareMessagesContainer
            projectId={projectId}
            scope={ProjectMetaService.SOCIAL_SHARE_MESSAGE_SCOPE.GALLERY}
          />
        </InnerPageContentMainCol>
        <InnerPageContentSidebar>
          {eventId ? (
            <div>
              <AIPhotoDeliveryCardContainer projectId={projectId} />
              <Card>
                <Space>
                  <PhotoSliderWidgetNavigateButtonContainer projectId={projectId} />
                </Space>
              </Card>
            </div>
          ) : (
            <Alert style={ALERT_STYLES.INFO}>
              <Row>
                <Icon name="info" size={ICON_SIZES.SM} />
                <Space size={1} />
                <div>
                  <Text>Some of the features are only available for Projects created using</Text>{' '}
                  <Text boldness={TEXT_BOLDNESS.BOLD}>CRM/Events</Text>
                </div>
              </Row>
            </Alert>
          )}
        </InnerPageContentSidebar>
      </>
    );

  return (
    <InnerPageContentMainCol>
      <Space>
        <Text>
          You can only add/view the deliverables(Signature album, Selection album, ${MESSAGES.CLIENT_WEBSITE.NAME}) only
          after converting the event to Project
        </Text>
        <Space size={1} vertical />
        {eventId && <ConvertToProjectButtonContainer eventId={eventId} />}
      </Space>
    </InnerPageContentMainCol>
  );
}
