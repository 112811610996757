import ClassNames from 'classnames';
import { motion, PanInfo } from 'framer-motion';
import { cloneElement, ReactElement, useContext, useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';

import { FileService } from '@premagic/core';
import { MediaUtils, MegaFileUploadService, StringUtils } from '@premagic/utils';
import { Space } from '../Grid/Grid';
import { IMAGE_V2_STYLES, ImageV2 } from '../Media';
import styles from './deck.module.css';
import DeckContext, {
  DeckAdditionalDataType,
  DeckContextFilesType,
  DeckContextFontsType,
  DeckTextOnlyContext,
} from './DeckContext';
import { DECK_SLIDE_DATA_TYPES, DeckSlideDataType } from './DeckSlideLayoutDataService';
import { DECK_SLIDE_LAYOUTS, DECK_SLIDE_LAYOUTS_DETAILS } from './DeckSlideLayoutService';

type DeckSlideIndicatorProps = {
  total: number;
  current: number;
};

export function DeckSlideIndicator(props: DeckSlideIndicatorProps): JSX.Element | null {
  const { total, current } = props;
  if (total < 2) return null;
  return (
    <div
      className={ClassNames(styles['slide-indicators'])}
      style={{
        ['--slide-indicator-total' as string]: total,
      }}
    >
      {Array.from(Array(total))
        .map((_, idx) => idx + 1)
        .map((item) => (
          <div
            key={item}
            className={ClassNames(styles['slide-indicators__item'], {
              [styles['slide-indicators__item--active']]: item === current,
            })}
          />
        ))}
    </div>
  );
}
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity;

function getAllImagesFromFiles(
  files: DeckContextFilesType,
  width: number,
  height: number,
): Array<{
  id: string;
  src: string;
}> {
  if (!files) {
    return [];
  }
  return Object.values(files)
    .filter((file) => file.asset_type === FileService.ASSET_TYPES.PHOTO && file.dynamic_image_url)
    .map((file) => ({
      id: file.id || file.image_name,
      src: MediaUtils.getDynamicImageURL(file.dynamic_image_url, MediaUtils.DYNAMIC_IMAGE_TYPES.HIGHLIGHT, {
        width,
        height,
        ratio: file.meta?.ratio,
      }),
    }));
}

interface DeckProps {
  children: Array<ReactElement>;
  defaultSlideIndex?: number;
  width: number;
  height: number;
  fonts: DeckContextFontsType;
  variables: Record<string, string | number>;
  proposalVariableDetails: Array<any>;
  files: DeckContextFilesType;
  uploadingFiles?: Record<string, MegaFileUploadService.UploaderFileType>;
  onChange?: (slideIndex: number) => void;
  onButtonActions: (action: string) => void;
  deckAdditionalData?: DeckAdditionalDataType;
  showLeftAndRightSlides?: boolean;
}

export function Deck(props: DeckProps) {
  const {
    children,
    defaultSlideIndex = 0,
    width,
    height,
    onChange,
    fonts,
    variables,
    proposalVariableDetails,
    files,
    uploadingFiles,
    onButtonActions,
    deckAdditionalData,
    showLeftAndRightSlides,
  } = props;

  const numberOfSlides = children.length;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(defaultSlideIndex);
  const [direction, setDirections] = useState<-1 | 1>(1);
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);

  const previousSlideComponent = children[currentSlideIndex - 1];
  const currentSlideComponent = children[currentSlideIndex];
  const nextSlideComponent = children[currentSlideIndex + 1];

  // Preload all images
  useEffect(() => {
    if (width > 0) {
      const images = getAllImagesFromFiles(files, width, height);
      MediaUtils.eagerLoadImages(images);
    }
  }, [width]);

  function changeSlideToIndex(slideIndex: number) {
    setCurrentSlideIndex(slideIndex);
    onChange?.(slideIndex);
  }

  function nextSlide() {
    const nextSlideIndex = currentSlideIndex + 1;
    if (nextSlideIndex >= numberOfSlides) {
      return;
    }
    changeSlideToIndex(nextSlideIndex);
  }

  function previousSlide() {
    const previousSlideIndex = currentSlideIndex - 1;
    if (previousSlideIndex <= -1) return;
    changeSlideToIndex(previousSlideIndex);
  }

  function changeSlide(newDirection: 1 | -1) {
    setDirections(newDirection);
    if (newDirection > 0) return nextSlide();
    return previousSlide();
  }

  const keyMap = {
    previous: 'left',
    next: 'right',
  };

  const handlers = {
    previous: () => changeSlide(-1),
    next: () => changeSlide(1),
  };

  function onDragEnd(e, info: PanInfo) {
    const swipe = swipePower(info.offset.x, info.velocity.x);

    if (swipe < -swipeConfidenceThreshold) {
      changeSlide(1);
    } else if (swipe > swipeConfidenceThreshold) {
      changeSlide(-1);
    }

    // if (info.offset.x < -100) {
    //   nextSlide();
    // }
    // if (info.offset.x > 100) {
    //   previousSlide();
    // }
  }

  return (
    <div
      className={styles['deck-super-wrapper']}
      style={{
        ['--slide-width' as string]: `${width}px`,
        ['--slide-height' as string]: `${height}px`,
      }}
    >
      <DeckSlideIndicator current={currentSlideIndex + 1} total={numberOfSlides} />
      <div className={styles['deck-wrapper']}>
        <motion.div
          className={styles.deck}
          drag="x"
          onDragEnd={onDragEnd}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
          <DeckContext.Provider
            value={{
              width,
              height,
              variables,
              proposalVariableDetails,
              fonts,
              files,
              uploadingFiles,
              toggleVideoPlaying,
              changeSlide,
              direction,
              onButtonActions,
              deckAdditionalData,
              showLeftAndRightSlides,
            }}
          >
            {numberOfSlides === 1
              ? currentSlideComponent
              : [previousSlideComponent, currentSlideComponent, nextSlideComponent].map((slideComponent, index) => {
                  if (!slideComponent) return <div style={{ width }} />;
                  return cloneElement(slideComponent, {
                    active: index === 1,
                    custom: direction,
                  });
                })}
          </DeckContext.Provider>
        </motion.div>
      </div>
    </div>
  );
}

type DeckSlideProps = {
  layout: DECK_SLIDE_LAYOUTS;
  data: DeckSlideDataType;
  id: number;
  active?: boolean;
};

export function DeckSlide(props: DeckSlideProps) {
  const { layout, data, id, active } = props;
  const hasBackgroundContent =
    data[DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE] || data[DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO];
  const hasTitle = !StringUtils.isEmptyString(data[DECK_SLIDE_DATA_TYPES.TITLE]?.value);
  const hasSubtitle = !StringUtils.isEmptyString(data[DECK_SLIDE_DATA_TYPES.SUB_TITLE]?.value);
  const hasContent =
    !StringUtils.isEmptyString(data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?.value) ||
    data[DECK_SLIDE_DATA_TYPES.EQUIPMENTS_ITEMS]?.value ||
    data[DECK_SLIDE_DATA_TYPES.TESTIMONIAL_ITEMS]?.value ||
    [DECK_SLIDE_LAYOUTS.ACCEPT, DECK_SLIDE_LAYOUTS.PACKAGE].includes(layout);

  let isDragActive = false;
  const { changeSlide, direction, showLeftAndRightSlides } = useContext(DeckContext);

  /*
   * So on desktop we show 3 cards but on mobile only one card.
   * */
  const slideAnimationVariantsMobile = {
    enter: (newDirection) => ({}),
    active: (newDirection) => ({}),
  };

  const slideAnimationVariantsDesktop = {
    enter: (newDirection) => ({
      scale: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    }),
    active: (newDirection) => {
      if (active) {
        return {
          scale: active ? 1 : 0.5,
          opacity: active ? 1 : 0.5,
          transition: {
            ease: 'easeIn',
            duration: 0.3,
            scale: {
              type: 'spring',
              stiffness: 80,
              damping: 20,
            },
          },
        };
      }
      return {
        scale: 0.5,
        opacity: 0.5,
        transition: {
          duration: 0.3,
        },
      };
    },
    // This wont apply as we dont have AnimatePresence
    exit: (newDirection) => {},
  };

  const slideAnimationVariants = showLeftAndRightSlides ? slideAnimationVariantsDesktop : slideAnimationVariantsMobile;

  function onDragStart() {
    isDragActive = true;
  }

  function onTap(e, info) {
    if (isDragActive) return;
    // eg. app width = 9, left area = 0-3px and right area 6px-9px
    const appWidth = window.innerWidth;
    const leftClickArea = appWidth / 2;
    const rightClickArea = appWidth - leftClickArea;

    if (['BUTTON'].includes(e.target.nodeName)) return;

    if (info.point.x > rightClickArea) {
      changeSlide(1);
    }
    if (info.point.x < leftClickArea) {
      changeSlide(-1);
    }
  }
  const layoutDetails = DECK_SLIDE_LAYOUTS_DETAILS[layout];
  if (!layoutDetails) {
    return (
      <div
        className={ClassNames(styles.slide, styles[`slide--layout-${layout}`], {
          [styles['slide--with-background-content']]: hasBackgroundContent,
        })}
      >
        <Space>This layout is deprecated</Space>
      </div>
    );
  }

  const { component: Component } = DECK_SLIDE_LAYOUTS_DETAILS[layout];
  return (
    <motion.div
      layout
      layoutId={String(id)}
      variants={slideAnimationVariants}
      onTap={onTap}
      initial="enter"
      animate="active"
      exit="exit"
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      className={ClassNames(styles['slide-wrapper'])}
    >
      <div
        className={ClassNames(styles.slide, styles[`slide--layout-${layout}`], {
          [styles['slide--with-background-content']]: hasBackgroundContent,
          [styles['slide--with-title']]: hasTitle,
          [styles['slide--with-subtitle']]: hasSubtitle,
          [styles['slide--with-content']]: hasContent,
          [styles[`slide--with-title--align-${data[DECK_SLIDE_DATA_TYPES.TITLE]?.align}`]]:
            hasTitle && data[DECK_SLIDE_DATA_TYPES.TITLE]?.align,
          [styles[`slide--with-subtitle--align-${data[DECK_SLIDE_DATA_TYPES.SUB_TITLE]?.align}`]]:
            hasSubtitle && data[DECK_SLIDE_DATA_TYPES.SUB_TITLE]?.align,
          [styles[`slide--with-content--align-${data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?.align}`]]:
            hasContent && data[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?.align,
        })}
      >
        <Component data={data} id={id} />
      </div>
    </motion.div>
  );
}

interface DeckTextOnlyProps {
  children: Array<ReactElement>;
  fonts: DeckContextFontsType;
  variables: Record<string, string | number | Array<any>>;
  deckAdditionalData?: DeckAdditionalDataType;
}

export function DeckTextOnly(props: DeckTextOnlyProps) {
  const { children, fonts, variables, deckAdditionalData } = props;

  return (
    <div className={styles['deck-super-wrapper']}>
      <DeckTextOnlyContext.Provider
        value={{
          variables,
          fonts,
          deckAdditionalData,
          proposalVariableDetails: variables?.proposal_variable_details as Array<any>,
        }}
      >
        {children}
      </DeckTextOnlyContext.Provider>
    </div>
  );
}

type DeckSlideTextOnlyProps = {
  layout: DECK_SLIDE_LAYOUTS;
  data: DeckSlideDataType;
  id: number;
};

export function DeckSlideTextOnly(props: DeckSlideTextOnlyProps) {
  const { layout, data, id } = props;

  const { componentTextOnly: Component } = DECK_SLIDE_LAYOUTS_DETAILS[layout];
  if (!Component) return <div />;
  return (
    <div className={ClassNames(styles['slide-text-only'], styles[`slide-slide-text-only--layout-${layout}`])}>
      <Component data={data} id={id} />
    </div>
  );
}
const testDefaultCards = [
  {
    id: 1,
    dynamic_image_url:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPfO37MK81JIyR1ptwqr_vYO3w4VR-iC2wqQ&usqp=CAU',
  },
  {
    id: 2,
    dynamic_image_url: 'https://imagekit.io/blog/content/images/2019/12/image-optimization.jpg',
  },

  {
    id: 3,
    dynamic_image_url: 'https://miro.medium.com/v2/resize:fit:1400/1*YMJDp-kqus7i-ktWtksNjg.jpeg',
  },
  {
    id: 4,
    dynamic_image_url: 'https://static.vecteezy.com/system/header_search_link/thumbnail/34/hsl_large_term-bg-1__1_.jpg',
  },
  {
    id: 5,
    dynamic_image_url:
      'https://imgv3.fotor.com/images/slider-image/A-clear-image-of-a-woman-wearing-red-sharpened-by-Fotors-image-sharpener.jpg',
  },
];

type FlippingCardImagesDeckProps = {
  cards: Array<{ fileId: string; id: number; imageUrl: string }>;
  onClick: () => void;
};

export function FlippingCardImagesDeck(props: FlippingCardImagesDeckProps) {
  const { cards, onClick } = props;

  const [cardList, setCardList] = useState(cards);
  const [animateLeave, setAnimateLeave] = useState(false);
  const [animateBack, setAnimateBack] = useState(false);

  function goToNextCard() {
    setCardList((prevList) => {
      const arr = [...prevList];
      const firstElem = arr.shift();
      if (firstElem) {
        arr.push(firstElem);
      }
      return arr;
    });
    onClick();
  }

  function handleClick() {
    if (!cardList || cardList.length === 0) return;
    if (cardList.length === 1) {
      onClick();
      return;
    }
    setAnimateLeave(true);
    setTimeout(() => {
      setAnimateLeave(false);
      setAnimateBack(true);
      goToNextCard();
    }, 300);

    setTimeout(() => {
      setAnimateBack(false);
    }, 700);
  }

  useEffect(() => {
    setCardList(cards);
  }, [cards]);

  return (
    <div className={ClassNames(styles['flipping_card_images_deck__card-wrapper'])}>
      {cardList.map((item, idx) => (
        <button
          type="button"
          key={item.id}
          onClick={handleClick}
          className={ClassNames(styles.flipping_card_images_deck__button)}
        >
          <div
            className={ClassNames(styles.flipping_card_images_deck__card, {
              [styles['flipping_card_images_deck__left-card']]: item.id % 2 == 0,
              [styles['flipping_card_images_deck__right-card']]: item.id % 2 == 1,
              [styles.flipping_card_images_deck__active]: idx == 0,
              [styles['flipping_card_images_deck__animate-leave']]: idx == 0 && animateLeave,
              [styles['flipping_card_images_deck__animate-back']]: idx == cardList.length - 1 && animateBack,
            })}
            style={{ zIndex: cards.length - idx }}
          >
            <div className={ClassNames(styles['flipping_card_images_deck__card-bg'])}>
              <div className={ClassNames(styles['flipping_card_images_deck__card-img'])}>
                <ImageV2
                  style={IMAGE_V2_STYLES.COVER}
                  src={item.imageUrl}
                  alt="flipping-card-img"
                  imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
                />
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}
