import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';

import { FormResponseType } from '@premagic/myne';
import { ArrayUtils, SimpleDateUtils, ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { EventTrackerService, OccasionsService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { CRMOccasionSchema, CRMOccasionsSchema } from '../../../../schema/Schemas';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';

import APP_URLS, { getRouteUrlFor } from '../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../services/RouterService';
import { userEntitiesSelector } from '../users/UsersDataDuck';
import { USER_SERVICE_TYPE, USER_SERVICE_TYPE_DETAILS } from '../users/UsersService';
import { setEditOfOccasion } from '../events/details/EventDetailsPageDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_OCCASIONS');

const setOccasionsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addOccasionsData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateOccasionsData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
const removeOccasionsData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

const setUserOccasionsData = createAction(getActionType('DATA_USER', 'SET'), (dispatch, userId, data) => ({
  [userId]: data,
}));

export const resetUserOccasionsData = createAction(getActionType('DATA_USER', 'RESET'), (dispatch) => ({}));

export const fetchOccasionsForEventData = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch: Dispatch, eventId: string) => {
    const loadingKey = LOADINGS.OCCASIONS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    OccasionsService.fetchOccasionsForEvent(eventId)
      .then((response) => {
        const normalizedData = normalize(response.results, CRMOccasionsSchema);
        dispatch(addOccasionsData(dispatch, normalizedData.entities.occasions));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Occasions: fetch failed', e);
        return e;
      });
  },
);

export const addOccasion = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch: Dispatch, eventId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.OCCASIONS.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_OCCASION.CREATE, { ...formResponse.data });

    OccasionsService.createOccasion(eventId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, CRMOccasionSchema);
        dispatch(addOccasionsData(dispatch, normalizedData.entities.occasions));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_ADD_PANEL, false));
        // Highlight occasion
        const deliverableTabWithFocus = getRouteUrlFor(APP_URLS.CRM.EVENT__OCCASIONS, {
          eventId,
          focusId: response.id,
        });
        navigateTo(dispatch, deliverableTabWithFocus);

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Occasions: create failed', e);
        return e;
      });
  },
);

export const updateOccasion = createAction(
  getActionType('DATA', 'EDIT'),
  (dispatch: Dispatch, id: string, eventId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.OCCASIONS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_OCCASION.UPDATE, { ...formResponse.data });

    OccasionsService.editOccasion(id, eventId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, CRMOccasionSchema);
        dispatch(addOccasionsData(dispatch, normalizedData.entities.occasions));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Occasions: update failed', e);
        return e;
      });
  },
);

export const removeOccasion = createAction(
  getActionType('DATA', 'DELETE'),
  (dispatch: Dispatch, id: string, eventId: string) => {
    const loadingKey = LOADINGS.OCCASIONS.DELETE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_OCCASION.DELETE);

    dispatch(removeOccasionsData(dispatch, id));
    OccasionsService.deleteOccasion(id, eventId)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Occasions: delete failed', e);
        return e;
      });
  },
);

export const duplicateOccasion = createAction(
  getActionType('DATA', 'DUPLICATE'),
  (dispatch: Dispatch, eventId: string, occasionId: string, data: any) => {
    const loadingKey = LOADINGS.OCCASIONS.DUPLICATE(occasionId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_OCCASION.DUPLICATE, { ...data });

    OccasionsService.createOccasion(eventId, data)
      .then((response) => {
        const { id } = response;
        const normalizedData = normalize(response, CRMOccasionSchema);
        dispatch(addOccasionsData(dispatch, normalizedData.entities.occasions));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setEditOfOccasion(dispatch, id));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_EDIT_PANEL, true));

        // Highlight the duplicate occasion
        const deliverableTabWithFocus = getRouteUrlFor(APP_URLS.CRM.EVENT__OCCASIONS, {
          eventId,
          focusId: id,
        });
        navigateTo(dispatch, deliverableTabWithFocus);

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Occasions: duplicate failed', e);
      });
  },
);

export const getOccasionsForUsers = createAction(
  getActionType('DATA', 'USER_OCCASIONS'),
  async (
    dispatch: Dispatch,
    eventId: string,
    userIds: Array<string>,
    dates: {
      start: string;
      end: string;
    },
  ) => {
    const loadingKey = LOADINGS.OCCASIONS.USER_OCCASIONS;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    dispatch(resetUserOccasionsData(dispatch));

    userIds.map((userId) =>
      OccasionsService.fetchOccasionsForUser(eventId, userId, dates)
        .then((response) => {
          if (response.length) dispatch(setUserOccasionsData(dispatch, userId, response));
          dispatch(toggleLoadingState(dispatch, loadingKey, false));
          return response;
        })
        .catch((e) => {
          dispatch(setErrorState(dispatch, loadingKey, e));
          dispatch(toggleLoadingState(dispatch, loadingKey, false));
          ErrorTracker.logError('Occasions for user: fetch failed', e);
          return e;
        }),
    );
  },
);

type OccasionsStateType = {
  items: IMap<string, OccasionsService.OccasionType>;
  occasionsForUsers: IMap<
    string,
    Array<{
      id: string;
      account_id: string;
      event__number: string;
      event_id: string;
    }>
  >;
};
const initialState = {
  items: IMap([]),
  occasionsForUsers: IMap(),
};

export default handleActions(
  {
    [setOccasionsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addOccasionsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateOccasionsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeOccasionsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.remove(action.payload),
    }),
    [setUserOccasionsData.toString()]: (state, action) => ({
      ...state,
      occasionsForUsers: state.occasionsForUsers.merge(action.payload),
    }),
    [resetUserOccasionsData.toString()]: (state) => ({
      ...state,
      occasionsForUsers: IMap(),
    }),
  },
  initialState,
);

const occasionsDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.crm.occasions as OccasionsStateType,
);

export const occasionEntitiesSelector = createSelector(occasionsDataSelector, (crmOccasions) => crmOccasions.items);
export const occasionsForUsersSelector = createSelector(occasionsDataSelector, (crmOccasions) =>
  crmOccasions.occasionsForUsers.toJSON(),
);

export const occasionsForUsersWithOutActiveOccasionSelector = (occasionId?: string) =>
  createSelector(occasionsDataSelector, (crmOccasions) =>
    crmOccasions.occasionsForUsers
      .map((item) => {
        if (occasionId) {
          return item.filter((occasion) => occasion.id !== occasionId);
        }
        return item;
      })
      .filter((item) => item.length > 0)
      .toJSON(),
  );

export const occasionItemsSelector = createSelector(occasionEntitiesSelector, (crmOccasions) => crmOccasions.toJSON());

export const occasionKeysSelector = createSelector(occasionEntitiesSelector, (crmOccasions) =>
  crmOccasions.keySeq().toArray(),
);

export const occasionIdsForEventSelector = (eventId: string) =>
  createSelector(occasionEntitiesSelector, (occasions) =>
    occasions
      .filter((occasion) => occasion.event === eventId)
      .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.start_date_time, b.start_date_time, false))
      .keySeq()
      .toArray(),
  );

export const groupedServiceProvidersForOccasionSelector = (occasionId: string) =>
  createSelector(occasionEntitiesSelector, userEntitiesSelector, (occasions, users) => {
    const userIdsOnThisOccasion = occasions.get(occasionId)?.service_providers;

    return Object.keys(USER_SERVICE_TYPE_DETAILS).reduce(
      (result, userServiceType) => {
        const userIdsForThisService = userIdsOnThisOccasion?.filter(
          (userId) => users.get(userId)?.service_role === userServiceType,
        );
        return {
          ...result,
          [userServiceType]: userIdsForThisService,
        };
      },
      {
        [USER_SERVICE_TYPE.CAMERA_MAN]: [],
        [USER_SERVICE_TYPE.HELPER]: [],
        [USER_SERVICE_TYPE.LIGHT_MAN]: [],
        [USER_SERVICE_TYPE.TECHNICIAN]: [],
        [USER_SERVICE_TYPE.DEFAULT]: [],
      },
    );
  });

export const serviceCostFromAllOccasions = (eventId: string) =>
  createSelector(occasionEntitiesSelector, userEntitiesSelector, (occasions, users) => {
    const usersWithDays = occasions
      .filter((occasion) => occasion.event === eventId)
      .reduce((result: Array<{ user: string; days: number }>, occasion) => {
        const daysOfOccasion = SimpleDateUtils.getDaysRemaining(occasion.start_date_time, occasion.end_date_time) || 1;
        return result.concat(
          occasion.service_providers.map((serviceProvider) => ({ user: serviceProvider, days: daysOfOccasion })),
        );
      }, [])
      .reduce((result: Record<string, number>, item) => {
        const userId = item.user;
        const days = result[userId] ? result[userId] + item.days : item.days;
        return {
          ...result,
          [userId]: days,
        };
      }, {});
    return Object.entries(usersWithDays).reduce((result: number, [userId, days]) => {
      const costOfUser = users.get(userId)?.charges_per_hour || 0;
      return result + costOfUser * days;
    }, 0);
  });
