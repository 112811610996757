import { connect } from 'react-redux';
import ProposalSettingsPage from './ProposalSettingsPage';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { isFeatureProposalEnabledSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return {
    currentUrlPathname: pathname,
    isFeatureProposalEnabled: isFeatureProposalEnabledSelector(state),
    isLoading: isLoadingSelector(LOADINGS.COMPANY.SAVE_PROFILE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSettingsPage);
