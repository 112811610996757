import { connect } from 'react-redux';
import EventAttendeeWithoutPhotoAlertItem from './EventAttendeeWithoutPhotoAlertItem';
import { eventAttendeeDataByIdSelector } from '../../EventAttendeesDataDuck';

function mapStateToProps(state, ownProps: { attendeeId: string; slNo: number }) {
  const { attendeeId, slNo } = ownProps;
  return {
    attendeeData: eventAttendeeDataByIdSelector(attendeeId)(state),
    slNo,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeWithoutPhotoAlertItem);
