import { connect } from 'react-redux';
import ConvertToProjectButton from './ConvertToProjectButton';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { isModalOpenSelector } from '../../../../../common/ModalDuck';
import { eventProjectSelectorSelector } from '../../projects/AccountProjectsDataDuck';
import { convertEventToProject } from '../events/details/EventDetailsPageDuck';
import { hasExceedProjectLimitSelector } from '../../settings/company/CompanyDataDuck';
import { clientItemForEventSelector, crmEventItemsSelector } from '../events/EventsDataDuck';

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;

  return {
    eventId,
    event: crmEventItemsSelector(state)[eventId] || {},
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    isCreating: isLoadingSelector(LOADINGS.EVENT.CONVERT_TO_PROJECT)(state),
    project: eventProjectSelectorSelector(eventId)(state),
    isTourOpen: isModalOpenSelector(MODALS.TOUR_MODAL)(state),
    hasExceedProjectLimit: hasExceedProjectLimitSelector(state),
    client: clientItemForEventSelector(eventId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    convertEventToProject: (data) => {
      dispatch(convertEventToProject(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvertToProjectButton);
