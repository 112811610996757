import { EventsService, IntegrationService } from '@premagic/core';
import { LoadingDots, MenuItem, MenuItemIcon, Row, Space } from '@premagic/myne';

type Props = {
  projectId: string;
  platform: IntegrationService.INTEGRATION_PLATFORMS;
  showEventsList: (platform: IntegrationService.INTEGRATION_PLATFORMS, projectId: string) => void;
  isProjectConnectedToIntegrationPlatform?: boolean;
  syncIntegrationEvent: (options: {
    platform: IntegrationService.INTEGRATION_PLATFORMS;
    premagicProjectId: string;
    integrationEventId: string;
    integrationEventData?: IntegrationService.IntegrationPlatformEventType;
    premagicEventData?: EventsService.EventDataType;
  }) => void;
  pmEventData?: EventsService.EventDataType;
  integrationEventConfig?: IntegrationService.EventPlatformIntegrationConfig;
  isLoading?: boolean;
};

export default function IntegrationSyncOrImportMenuItem(props: Props) {
  const {
    projectId,
    platform,
    showEventsList,
    isProjectConnectedToIntegrationPlatform,
    syncIntegrationEvent,
    pmEventData,
    integrationEventConfig,
    isLoading,
  } = props;

  const { title, svgIconName } = IntegrationService.INTEGRATION_PLATFORMS_DATA[platform];

  const { platform: connectedPlatform, platform_config: platformConfig } = integrationEventConfig || {};
  const { event_id: integrationEventId } = platformConfig || {};

  function handleOnClick() {
    if (isProjectConnectedToIntegrationPlatform && connectedPlatform === platform && integrationEventId) {
      return syncIntegrationEvent({
        platform: connectedPlatform,
        premagicProjectId: projectId,
        integrationEventId,
        premagicEventData: pmEventData,
      });
    }

    showEventsList(platform, projectId);
  }

  // event can only be connected to a single platform. Hence, if it is already connected then only show that platform
  if (isProjectConnectedToIntegrationPlatform && !(connectedPlatform === platform)) return null;

  return (
    <MenuItem onClick={handleOnClick} disabled={isLoading}>
      <Row vcenter>
        <MenuItemIcon name={svgIconName} />
        Import from {title}
        {isLoading && (
          <>
            <Space size={2} />
            <LoadingDots size="xs" />
          </>
        )}
      </Row>
    </MenuItem>
  );
}
