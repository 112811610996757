import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';
import { MODALS } from '../../../../../common/Constants';
import ProjectCoverFocalPoint from './ProjectCoverFocalPoint';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../project-meta/ProjectsMetaDataDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../common/ModalDuck';

const META_ITEM = ProjectMetaService.PROJECT_META_TYPES.COVER_IMAGE_FOCAL_POINT;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    isFocalPointModalOpen: isModalOpenSelector(MODALS.PROJECT_COVER_FOCAL_POINT_MODAL)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(toggleModalVisibility(dispatch, MODALS.PROJECT_COVER_FOCAL_POINT_MODAL, false));
    },
    setFocalPoint: (projectId, cordinates) => {
      dispatch(
        saveProjectMetaData(
          dispatch,
          projectId,
          { data: { cover_image_focal_point: cordinates } },
          { itemId: META_ITEM },
        ),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCoverFocalPoint);
