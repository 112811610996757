import { connect } from 'react-redux';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { usersItemsSelector } from '../UsersDataDuck';
import UserForm from './UserForm';

function mapStateToProps(state, ownProps: { userId?: string }) {
  const { userId } = ownProps;
  return {
    user: userId ? usersItemsSelector(state)[userId] : null,
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

export default connect(mapStateToProps)(UserForm);
