import { FormGroup, FormLabel, INPUT_SIZES, Select, Space } from '@premagic/myne';
import {
  EventAttendeeBadgeDesignType,
  WIDGET_TYPE,
  WidgetDataType,
} from '../../../service/EventAttendeeBadgeDesignService';

type Props = {
  projectId: string;
  folderId: string;
  selectedWidgetForEdit?: WIDGET_TYPE;
  designData?: EventAttendeeBadgeDesignType;
  editFont: (
    projectId: string,
    folderId: string,
    previousDesign?: EventAttendeeBadgeDesignType,
    widgetId?: string,
    weight?: string,
  ) => void;
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
};

const fontWeightOptions = [
  {
    value: 400,
    label: 'Regular',
  },
  {
    value: 500,
    label: 'Medium',
  },
  {
    value: 700,
    label: 'Bold',
  },
];
export default function EventAttendeeBadgeFontWeightSelect(props: Props) {
  const { selectedWidgetForEdit, designData, editFont, projectId, folderId, posterWidgetDesign } = props;

  function handleFontSelect(option) {
    const { value } = option;
    editFont(projectId, folderId, designData, selectedWidgetForEdit, value);
  }
  const defaultFontWeightOption =
    selectedWidgetForEdit &&
    fontWeightOptions.find((option) => option.value === (posterWidgetDesign?.[selectedWidgetForEdit]?.weight || 400));
  return (
    <FormGroup>
      <FormLabel>Font Style</FormLabel>
      <Select
        options={fontWeightOptions}
        name="weight"
        size={INPUT_SIZES.SM}
        isMulti={false}
        onChange={handleFontSelect}
        value={defaultFontWeightOption}
      />
    </FormGroup>
  );
}
