import imageCompression from 'browser-image-compression';
import justifiedLayout from 'justified-layout';
import { isNumber } from 'lodash';
import { encodeURL, getQueryStringFromObject } from './BrowserUrlUtils';
import {
  downloadAsFile,
  downloadFileSimple,
  getGlobalVariableForBROWSERSUPPORT,
  isInsideAppleWebKitView,
  isInsideIFrame,
  isIOS,
} from './BrowserUtils';
import { createSignForPath, getMD5Hash } from './CryptoUtils';
import {
  WATERMARK_POSITIONS,
  WATERMARK_POSITION_DETAILS,
  WATERMARK_SIZES,
  WATERMARK_SIZE_DETAILS,
} from './PhotoWatermarkUtils';
import { replaceFileExtention } from './StringUtils';

const IMAGES_CDN_OLD = 'https://asts.premagic.com/';
const IMAGES_CDN = 'https://images.premagic.com';
const IMAGES_DOWNLOAD_CDN = 'https://image-downloads.premagic.com';
/* Radio = Width/Height
height = width/radio

eg. 1500/1549 = 0.968366688 is the ratio
eg. 66.9/52.4 = 1.27 is ratio,
  Height = width/radio (66/1.27) = 52
  Width = height * radio (52*1.27) =
*/
export function getImageHeightForRatio(width: number, ratio: number): number {
  return width / ratio;
}

export function getImageWidthForRatio(height: number, ratio: number): number {
  return height * ratio;
}

export function getRatioFromWidthHeight(options?: { width: number; height: number }): number {
  if (!options?.width) return 1;
  return options.width / options.height;
}

export function isPortraitImage(radio: number): boolean {
  return radio < 1;
}

export function isLandscapeImage(ratio?: number): boolean {
  if (ratio) return ratio >= 1.1;
  return false;
}

export function getWidthForImage(): number {
  const { devicePixelRatio, innerWidth: appWidth } = window;
  const maxSize = 4000;
  const bucketSize = 500; // 4000 / 8 grades
  const standardSizeBucket = Math.ceil((appWidth * devicePixelRatio) / bucketSize) * bucketSize;
  return standardSizeBucket >= maxSize ? maxSize : standardSizeBucket;
}

type BucketSizeType = 240 | 500 | 860 | 1280 | 2040 | 4000;

function closestImageSize(imageSize): BucketSizeType {
  const presetImageSize: Array<BucketSizeType> = [240, 500, 860, 1280, 2040, 4000];
  return presetImageSize.reduce((a, b) => (Math.abs(b - imageSize) < Math.abs(a - imageSize) ? b : a));
}

export function getImageWidthToLoad(
  imageWidth: number,
  zoomScale = 0,
  maxWidth: BucketSizeType = 4000,
): BucketSizeType {
  const { devicePixelRatio } = window;
  const deviceDPR = Math.ceil(devicePixelRatio) >= 2 ? 2 : 1;

  if (imageWidth < 2000 && zoomScale > 2) {
    return 2040;
  }

  const width = closestImageSize(imageWidth * deviceDPR);

  return Math.min(width, maxWidth) as BucketSizeType;
}
/*
Should return the keyObject from the src
https://asts.premagic.com/cf804840e1319964bf7c40f0e889bb60d8e9b1fca7b10913f486d590ac0f0ed0-C-U9kGLE7ng-b'SU1HXzg3OTcuanBn'.jpg" => cf804840e1319964bf7c40f0e889bb60d8e9b1fca7b10913f486d590ac0f0ed0-C-U9kGLE7ng-b'SU1HXzg3OTcuanBn'.jpg
https://asts.premagic.com/faces/70a0d62b-6a3a-4449-8595-212d9dcbbe51.jpg?width=240&type=dynthumbnail&webp=true => faces/70a0d62b-6a3a-4449-8595-212d9dcbbe51.jpg
* */
export function getObjectKeyFromSrc(src: string): string {
  if (!src) return '';
  return src?.replace(IMAGES_CDN_OLD, '').split('?')[0];
}

export enum DYNAMIC_IMAGE_TYPES {
  DEFAULT = 'default', // 1200
  SELECTION = 'selection', // 1200
  HIGHLIGHT = 'highlight', // 4k
  SELECTION_THUMBNAIL = 'thumbnail', // for selection
  DYN_THUMBNAIL = 'dynthumbnail', // for highlight
}

export function getDynamicImageURL(
  src: string,
  type: DYNAMIC_IMAGE_TYPES,
  options: { width: number; height?: number; zoomScale?: number; ratio?: number; watermark?: string },
): string {
  if (!src) return '';
  if (src.indexOf('blob:') === 0) return src;
  const { width, height = 0, zoomScale, ratio, watermark } = options;
  let imageWidthToLoad = getImageWidthToLoad(width, zoomScale);

  // If landscape photo then load higher image, we do the same in HighlightWelcomePage
  if (height > 0 && (ratio || 1) >= 1.1) {
    imageWidthToLoad = getImageWidthToLoad(height, zoomScale);
  }

  const browserSupport = getGlobalVariableForBROWSERSUPPORT();
  if (!src) return '';

  // Note: We are not doing this for now | Backend does not support this
  // if (width > 0 && height > 0) {
  //   const imageHeightToLoad = getImageWidthToLoad(height, zoomScale);
  //   return `${src.replace(
  //     'localhost',
  //     window.location.hostname,
  //   )}?height=${imageHeightToLoad}&width=${imageWidthToLoad}&type=${type}&webp=${browserSupport.hasSupportForWebp}`;
  // }o
  // http://localhost:5000/view/savannah-garrett/i/img_zwWlSjiRQiw?width=500&type=dynthumbnail&webp=true

  const imageOptions = getQueryStringFromObject({
    width: imageWidthToLoad,
    type,
    webp: String(browserSupport.hasSupportForWebp),
    message: watermark,
  });
  return `${src.replace('localhost', window.location.hostname)}?${imageOptions}`;
}

export type WatermarkOptions = {
  opacity?: number; // 0 - 1
  size?: WATERMARK_SIZES | number;
  position?: WATERMARK_POSITIONS;
  x?: string | number;
  y?: string | number;
  objKey: string;
  remove_on_download?: boolean;
};

function getWatermarkOptions(options?: Array<WatermarkOptions>): string {
  // :watermark(WIMAGE,x,y,alpha,w_ratio,h_ratio)
  if (!options) return '';
  return options
    .map((watermark) => {
      if (!watermark?.objKey) return '';
      const { objKey, size = 30, opacity = 0.4, position = WATERMARK_POSITIONS.BOTTOM_RIGHT, x, y } = watermark;
      const sizeValue = isNumber(size) ? size : WATERMARK_SIZE_DETAILS[size].size;
      const positionValue =
        x && y
          ? {
              x,
              y,
            }
          : WATERMARK_POSITION_DETAILS[position];
      const alphaValue = (1 - opacity) * 100;
      return `:watermark(${objKey},${positionValue.x},${positionValue.y},${alphaValue},${sizeValue},${sizeValue})`;
    })
    .join('');
}

export enum IMAGE_QUALITY_TYPES {
  DEFAULT,
  THUMBNAIL_SELECTION,
  THUMBNAIL_HIGHLIGHT,
  FULL_VIEW_SELECTION,
  FULL_VIEW_HIGHLIGHT,
  RESOLUTION_FULL,
}
const IMAGE_QUALITY_TYPES_DETAILS: Record<
  IMAGE_QUALITY_TYPES,
  {
    quality: number;
    maxWidth: BucketSizeType;
    sharpen: number;
    algo?: 'fit-in';
    gravity?: 'smart' | 'center';
  }
> = {
  [IMAGE_QUALITY_TYPES.DEFAULT]: {
    maxWidth: 1280,
    quality: 70,
    sharpen: -0.2,
    algo: 'fit-in',
  },
  [IMAGE_QUALITY_TYPES.THUMBNAIL_SELECTION]: {
    maxWidth: 500,
    quality: 40,
    sharpen: -0.2,
    gravity: 'smart',
  },
  [IMAGE_QUALITY_TYPES.THUMBNAIL_HIGHLIGHT]: {
    maxWidth: 1280,
    quality: 84,
    sharpen: -0.2,
    algo: 'fit-in',
  },
  [IMAGE_QUALITY_TYPES.FULL_VIEW_SELECTION]: {
    maxWidth: 1280,
    quality: 70,
    sharpen: -0.2,
    algo: 'fit-in',
  },
  [IMAGE_QUALITY_TYPES.FULL_VIEW_HIGHLIGHT]: {
    maxWidth: 4000,
    quality: 84,
    sharpen: -0.2,
    algo: 'fit-in',
  },
  [IMAGE_QUALITY_TYPES.RESOLUTION_FULL]: {
    maxWidth: 4000, // There wont be any effects on this
    quality: 92,
    sharpen: -0.2,
    algo: 'fit-in',
  },
};

function getImageOptions(options: {
  width: number;
  height: number;
  quality: IMAGE_QUALITY_TYPES;
  objectKey: string;
  ratio?: number;
  watermarks?: Array<WatermarkOptions>;
  zoomScale?: number;
  download?: string;
}) {
  const { width, height, ratio, quality, objectKey, watermarks, zoomScale, download } = options;
  const qualityValue = IMAGE_QUALITY_TYPES_DETAILS[quality];

  let imageWidthToLoad = width === 0 ? 0 : getImageWidthToLoad(width, zoomScale, qualityValue.maxWidth);
  const imageHeightToLoad =
    width === height ? getImageWidthToLoad(height, zoomScale, qualityValue.maxWidth) : Math.floor(height);

  // If landscape photo then load higher image, we do the same in HighlightWelcomePage
  if (height && isLandscapeImage(ratio)) {
    imageWidthToLoad = getImageWidthToLoad(height, zoomScale, qualityValue.maxWidth);
  }
  const gravity = qualityValue.gravity ? `${qualityValue.gravity}/` : '';
  const algo = qualityValue.algo ? qualityValue.algo : '';
  const attachment = download ? `:attachment(${download}):format(jpeg)` : '';

  // fit-in/WxH/filters:quality(80):sharpen(-0.1):strip_icc():strip_exif():watermark(WIMAGE,x,y,alpha,w_ratio,h_ratio)/SIMAGE
  return `${algo}/${imageWidthToLoad}x${imageHeightToLoad}/${gravity}filters:quality(${qualityValue.quality}):sharpen(${
    qualityValue.sharpen
  }):strip_icc():strip_exif()${getWatermarkOptions(watermarks)}${attachment}/${objectKey}`;
}

export function getNewDynamicImageURL(
  src: string,
  quality: IMAGE_QUALITY_TYPES,
  options: {
    width: number;
    height?: number;
    zoomScale?: number;
    ratio?: number;
    watermarks?: Array<WatermarkOptions>;
    download?: string;
  },
): string {
  const { width, height = 0, zoomScale, ratio, watermarks, download } = options;
  const objectKey = getObjectKeyFromSrc(src);

  if (!objectKey) return '';
  const imageOptions = getImageOptions({
    width,
    height,
    quality,
    objectKey,
    watermarks,
    zoomScale,
    ratio,
    download,
  });

  const urlHash = createSignForPath(imageOptions);
  if (src.startsWith('blob:')) return src;
  if (src.includes('localhost')) return `${src.replace('localhost', window.location.hostname)}?${imageOptions}`;
  const path = encodeURL(`${urlHash}/${imageOptions}`);
  return `${IMAGES_CDN}/${path}`;
}

// export function getFullDownloadImageURL(
//   src: string,
//   options: {
//     watermark?: WatermarkOptions;
//     download?: string;
//   },
// ): string {
//   const { watermark, download } = options;
//   const objectKey = src.replace(IMAGES_CDN_OLD, '');
//
//   if (!objectKey) return '';
//   const imageOptions = `filters:${getWatermarkOptions(watermark)}:attachment(${download}):format(jpeg)/${objectKey}`;
//
//   const urlHash = createSignForPath(imageOptions);
//
//   if (src.includes('localhost')) return `${src.replace('localhost', window.location.hostname)}?${imageOptions}`;
//   const path = encodeURL(`${urlHash}/${imageOptions}`);
//   return `${IMAGES_CDN}/${path}`;
// }

export enum DOWNLOAD_QUALITY_TYPES {
  RESOLUTION_1K = '1k',
  RESOLUTION_2K = '2k',
  RESOLUTION_4K = '4k',
  RESOLUTION_FULL = 'FULL',
}

const DOWNLOAD_QUALITY_DETAILS: Record<
  DOWNLOAD_QUALITY_TYPES,
  {
    width: number;
  }
> = {
  [DOWNLOAD_QUALITY_TYPES.RESOLUTION_1K]: {
    width: 1024,
  },
  [DOWNLOAD_QUALITY_TYPES.RESOLUTION_2K]: {
    width: 2048,
  },
  [DOWNLOAD_QUALITY_TYPES.RESOLUTION_4K]: {
    width: 3840,
  },
  [DOWNLOAD_QUALITY_TYPES.RESOLUTION_FULL]: {
    width: 0,
  },
};

export function getDownloadFileForImage(
  src: string,
  options: {
    watermarks?: Array<WatermarkOptions>;
    filename: string;
    downloadQuality?: DOWNLOAD_QUALITY_TYPES;
    originalSrc: string;
  },
): {
  url: string;
  name: string;
} {
  const {
    watermarks: allWatermarks = [],
    downloadQuality = DOWNLOAD_QUALITY_TYPES.RESOLUTION_4K,
    originalSrc,
  } = options;
  const qualityDetails = DOWNLOAD_QUALITY_DETAILS[downloadQuality];

  const watermarksToApply = allWatermarks.filter((watermark) => !watermark.remove_on_download);

  // Replace () with _ otherwise file doesn't get download, it just open on a new tab.
  const filename = replaceFileExtention(options.filename, '.jpeg').replace(/[()]/g, '_');
  if (downloadQuality === DOWNLOAD_QUALITY_TYPES.RESOLUTION_FULL) {
    if (watermarksToApply.length === 0) {
      return {
        name: options.filename,
        url: originalSrc,
      };
    }
    const attachment = `:attachment(${filename}):format(jpeg):quality(100)`;
    const objectKey = getObjectKeyFromSrc(src);
    const imageOptions = `filters${getWatermarkOptions(watermarksToApply)}${attachment}/${objectKey}`;
    const urlHash = createSignForPath(imageOptions);

    const path = encodeURL(`${urlHash}/${imageOptions}`);
    const url = `${IMAGES_DOWNLOAD_CDN}/${path}`;
    return {
      name: filename,
      url,
    };
  }

  return {
    name: filename,
    url: getNewDynamicImageURL(src, IMAGE_QUALITY_TYPES.FULL_VIEW_HIGHLIGHT, {
      width: qualityDetails.width,
      height: 0,
      watermarks: watermarksToApply,
      download: filename,
    }),
  };
}
export enum UPLOAD_QUALITY_TYPES {
  ORIGINAL = 'ORIGINAL',
  SUPERFINE = 'SUPERFINE',
  QUALITY_4K = 'QUALITY_4K',
  QUALITY_2K = 'QUALITY_2K',
  QUALITY_1K = 'QUALITY_1K',
}

export const UPLOAD_QUALITY_DETAILS: Record<
  UPLOAD_QUALITY_TYPES,
  {
    title: string;
    id: string;
    width?: number; // if no width is present then its not required to resize
    quality: number;
  }
> = {
  [UPLOAD_QUALITY_TYPES.ORIGINAL]: {
    title: 'Original Resolution with unwanted pixels',
    id: UPLOAD_QUALITY_TYPES.ORIGINAL,
    quality: 1,
  },
  [UPLOAD_QUALITY_TYPES.SUPERFINE]: {
    title: 'Original Resolution',
    id: UPLOAD_QUALITY_TYPES.SUPERFINE,
    quality: 0.8,
  },
  [UPLOAD_QUALITY_TYPES.QUALITY_4K]: {
    title: '4K Resolution (RECOMMENDED)',
    id: UPLOAD_QUALITY_TYPES.QUALITY_4K,
    width: 3840,
    quality: 0.8,
  },
  [UPLOAD_QUALITY_TYPES.QUALITY_2K]: {
    title: '2K Resolution',
    id: UPLOAD_QUALITY_TYPES.QUALITY_2K,
    width: 2048,
    quality: 0.7,
  },
  [UPLOAD_QUALITY_TYPES.QUALITY_1K]: {
    title: '1K Resolution',
    id: UPLOAD_QUALITY_TYPES.QUALITY_1K,
    width: 1024,
    quality: 0.6,
  },
};

// This is using Canvas API to compress image
async function imageCompressForUploadUsingCanvas(
  imageFile: File,
  uploadQuality: UPLOAD_QUALITY_TYPES = UPLOAD_QUALITY_TYPES.QUALITY_4K,
): Promise<File | void> {
  const maxFileSize = UPLOAD_QUALITY_DETAILS[uploadQuality].quality * (imageFile.size / 1024 / 1024);
  const options = {
    maxSizeMB: maxFileSize,
    maxWidthOrHeight: UPLOAD_QUALITY_DETAILS[uploadQuality].width,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return compressedFile;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function compressImageFilesForUploadUsingCanvas(
  files: Array<File>,
  uploadQuality: UPLOAD_QUALITY_TYPES,
): Promise<Array<File | void> | void> {
  const imagesToCompress = files.map((file) => imageCompressForUploadUsingCanvas(file, uploadQuality));
  return Promise.all(imagesToCompress)
    .then((compressedFiles) => compressedFiles)
    .catch((error) => Promise.reject(error));
}

export function getGalleryImageForThumbnailWidth(appWidth: number): number {
  let thumbnailWidth = 300;
  const { devicePixelRatio } = window;
  const deviceDPR = Math.ceil(devicePixelRatio) >= 2 ? 2 : 1;
  if (appWidth >= 760) thumbnailWidth = 400;
  return thumbnailWidth * deviceDPR;
}

export function getGravatarImageForEmail(email: string, size = 100): string | null {
  if (!email) return null;
  return `https://www.gravatar.com/avatar/${getMD5Hash(email.toLocaleLowerCase().trim())}?s=${size}&default=blank`;
}

export function prefetchImage(src): Promise<unknown> {
  return new Promise((resolve, reject) => {
    const imageObj = new Image();
    imageObj.src = src;
    imageObj.onload = resolve;
    imageObj.onerror = reject;
  });
}

enum EAGER_LOAD_IMAGE_STATES {
  'WAITING',
  'IN_PROGRESS',
  'LOADED',
  'ERROR',
}
const EAGER_LOAD_IMAGES: Record<
  string,
  {
    src: string;
    state: EAGER_LOAD_IMAGE_STATES;
    promise: Promise<unknown>;
  }
> = {};

function updateEagerLoadImageState(id, state: EAGER_LOAD_IMAGE_STATES) {
  EAGER_LOAD_IMAGES[id] = {
    ...EAGER_LOAD_IMAGES[id],
    state,
  };
}

export function eagerLoadImages<T extends { id: string; src: string }>(images: Array<T>): Array<Promise<T>> {
  return images.map(
    (image) =>
      new Promise((resolve, reject) => {
        const { id, src } = image;
        const cacheImage = EAGER_LOAD_IMAGES[id];
        if (
          cacheImage &&
          [EAGER_LOAD_IMAGE_STATES.LOADED, EAGER_LOAD_IMAGE_STATES.IN_PROGRESS].includes(cacheImage.state)
        ) {
          resolve(image);
          return;
        }

        EAGER_LOAD_IMAGES[id] = {
          src,
          state: EAGER_LOAD_IMAGE_STATES.IN_PROGRESS,
          promise: prefetchImage(src),
        };
        EAGER_LOAD_IMAGES[id].promise
          .then(() => {
            resolve(image);
            updateEagerLoadImageState(id, EAGER_LOAD_IMAGE_STATES.LOADED);
          })
          .catch(() => {
            reject(image);
            updateEagerLoadImageState(id, EAGER_LOAD_IMAGE_STATES.ERROR);
          });
      }),
  );
}

export function getJustifiedLayoutPositionForImages(
  imageRatios: Array<number>,
  options: {
    containerWidth: number;
    containerPadding?:
      | number
      | {
          top: number;
          bottom: number;
          left: number;
          right: number;
        };
    fullWidthBreakoutRowCadence?: boolean | number;
    targetRowHeightTolerance?: number;
    targetRowHeight?: number;
    boxSpacing?: number;
    widowLayoutStyle?: 'left' | 'center' | 'justify';
    maxNumRows?: number;
    forceAspectRatio?: number | boolean;
  },
): {
  containerHeight: number;
  widowCount: number;
  boxes: Array<{
    aspectRatio: number;
    top: number;
    width: number;
    height: number;
    left: number;
  }>;
} {
  return justifiedLayout(imageRatios, options);
}

export function isVideoFile(file: File): boolean {
  return file.type.split('/')[0] === 'video';
}

export function isImageFile(file: File): boolean {
  return file.type.split('/')[0] === 'image';
}

export function isJpegImageFile(file: File): boolean {
  return file.type === 'image/jpeg';
}

export function openImageInNewTabForDownload(url: string, fileName: string) {
  const newTab = window.open();
  if (!newTab) return;

  const newTabContentWithImage = `
        <html>
          <head>
            <title>Save Photo to your gallery</title>
            <style>
              body {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 10px;
                font-family: Source Sans Pro, sans-serif;
                font-size: 3rem;
                font-weight: 500;
                color: #4d6178;
              }
              img {
                max-width: 100%;
                height: auto;
              }
              .gallery-image {
                display: none; /* Initially hidden until the image loads */
              }
              .download-info {
                margin-top: 24px;
                display: none; /* Initially hidden until the image loads */
              }
              .close-button {
                background: rgba(0, 0, 0, 0.4);
                color: #fff;
                font-size: 40px;
                width: 85px;
                height: 85px;
                border-radius: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
              .close-button--align-top-right {
                position: absolute;
                top: 25px;
                right: 25px;
              }
              .loading-info {
                font-size: 4rem;
              }
              .error-info {
                  display: none;
              }
            </style>
          </head>
          <body>
            <button class="close-button close-button--align-top-right" onclick="window.close()">X</button>
            <p class='loading-info'>Loading...</p>
            <div class='error-info'>
              <p>Oops...could not load the photo!</p>
              <p>Please download it using the link below.</p>
              <a href=${url} download=${fileName}>Download Link</a>
            </div>
            <img class="gallery-image" src=${url} alt="gallery-image"/>
            <p class="download-info">Press & hold the photo for save options</p>
          </body>
        </html>`;

  // add the html
  newTab.document.documentElement.innerHTML = newTabContentWithImage;

  // add the script to handle image loading
  const script = newTab.document.createElement('script');
  script.textContent = `
        function imageLoaded() {
            document.querySelector('.loading-info').style.display = 'none';
            document.querySelector('.gallery-image').style.display = 'block';
            document.querySelector('.download-info').style.display = 'block';
        }
        function imageError() {
            document.querySelector('.error-info').style.display = 'block';
            document.querySelector('.loading-info').style.display = 'none';
            document.querySelector('.download-info').style.display = 'none';
            document.querySelector('.gallery-image').style.display = 'none';
        }
        document.querySelector('.gallery-image').onload = imageLoaded;
        document.querySelector('.gallery-image').onerror = imageError;
    `;
  newTab.document.body.appendChild(script);
}

// this function will trigger the appropriate download option based on the platform
export function downloadFileWithIphoneSupport(fileUrl: string, fileName: string, isVideo = false): void {
  const isIOSDevice = isIOS();

  if (isIOSDevice && !isVideo && !isInsideIFrame()) {
    openImageInNewTabForDownload(fileUrl, fileName);
    return;
  }

  downloadFileSimple({ file: fileUrl, fileName, isVideo });
}
