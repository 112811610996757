import { connect } from 'react-redux';
import EventOccasionsSummary from './EventOccasionsSummary';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { fetchOccasionsForEventData, occasionIdsForEventSelector } from '../OccasionsDataDuck';

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;

  return {
    eventId,
    isLoading: isLoadingSelector(LOADINGS.OCCASIONS.FETCH, true)(state),
    occasionIds: occasionIdsForEventSelector(eventId)(state) || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (eventId) => {
      dispatch(fetchOccasionsForEventData(dispatch, eventId));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EventOccasionsSummary);
