import { BADGE_PUNCH_HOLE_STYLE } from '@premagic/poster-creator';
import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';
import { fetchCustomEventFromFields } from '../event-custom-form-fields/EventCustomFormFieldsDataDuck';
import { setPunchHoleStyle } from './EventPosterCreatorDuck';
import EventPosterCreatorMainLayout from './EventPosterCreatorMainLayout';
import {
  fetchProject,
  folderIdsWithFolderTypeForProjectSelector,
  projectsSelector,
} from '../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../common/Constants';
import { getFolderImagesAndOrder } from '../projects/folders/AccountFoldersDataDuck';
import { eventPostersSelector, getEventPosterDetails } from '../crm/event-posters/EventPostersDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, projectId, posterId, type } = ownProps.match.params;
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);

  return {
    eventId,
    projectId,
    posterId,
    poster: eventPostersSelector(state)[posterId],
    brandingFolderId: brandingFolderIds[0],
    hasProject: !!projectsSelector(state)[projectId],
    isLoading:
      LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.SINGLE_FETCH)(state) ||
      LoadingDuck.isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPosterImages: (projectId, folderId) => {
      dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, FolderService.FOLDER_TYPES.BRANDING));
    },
    fetchPoster: (projectId, posterId) => {
      dispatch(setPunchHoleStyle(dispatch, BADGE_PUNCH_HOLE_STYLE.NONE));
      dispatch(getEventPosterDetails(dispatch, projectId, posterId));
      dispatch(fetchCustomEventFromFields(dispatch, projectId)); // this is for showing on the set user dummy data
    },
    fetchProject: (projectId) => {
      dispatch(fetchProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterCreatorMainLayout);
