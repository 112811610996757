import { connect } from 'react-redux';

import EventDetailsDeliverablesLayout from './EventDetailsDeliverablesLayout';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { eventProjectSelectorSelector } from '../../projects/AccountProjectsDataDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project ? project.project_id : 'no-project-id';

  return {
    eventId,
    focusId,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    project,
    showClientWebsite: false,
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsDeliverablesLayout);
