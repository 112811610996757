import { HightlightAlbumThemeService } from '@premagic/core';
import { connect } from 'react-redux';
import HighlightDesignAccentColorSelector from './HighlightDesignAccentColorSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (color: string) => void;
    disabled: boolean;
    accentColor?: string;
  },
) {
  const { onChange, disabled, accentColor } = ownProps;
  return { onChange, disabled, accentColor };
}

function mapDispatchToProps() {
  return {
    initAccentColor: (accentColor: string) => {
      HightlightAlbumThemeService.updateThemeVariable({
        color: {
          accent_color: accentColor,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignAccentColorSelector);
