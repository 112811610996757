import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { filesItemDataSelector } from '../../../images/FilesDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import EventPosterFilePicker from './EventPosterFilePicker';
import { deleteEventPosterWidget, eventPostersSelector } from '../../../crm/event-posters/EventPostersDataDuck';

const MODAL_KEY = LOADINGS.CLIENT_WEBSITE.SHOW_I_AM_ATTENDING_POSTER_SELECT_MODAL;
function mapStateToProps(state, ownProps: { projectId: string; posterId: string; widgetId: string }) {
  const { projectId, posterId, widgetId } = ownProps;

  const poster = eventPostersSelector(state)[posterId];
  const imageId = poster?.widgets?.[widgetId]?.value;
  return {
    projectId,
    widgetId,
    poster,
    selectedFile: filesItemDataSelector(imageId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSelectPosterModal: (widgetId) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODAL_KEY, true));
      dispatch(
        ModalDuck.setModalOptions(dispatch, MODAL_KEY, {
          widgetId,
        }),
      );
    },
    onRemove: ({ projectId, poster, widgetId }) => {
      dispatch(
        deleteEventPosterWidget(dispatch, projectId, {
          poster,
          widgetId,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterFilePicker);
