import { EventsService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  RichTextArea,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';

type Props = {
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  projectId?: string;
  saveProjectMeta: (projectId: string, formResponse: FormResponseType) => void;
  eventData?: EventsService.EventDataType;
};

export default function EditEventNotesPanel(props: Props): JSX.Element {
  const { show, isLoading, errors, closePanel, saveProjectMeta, projectId, eventData } = props;

  function handleOnSubmit(formResponse) {
    if (!projectId) return;
    saveProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_DATA]: { ...eventData, ...formResponse.data.event },
      },
    });
    closePanel();
  }

  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Notes</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={errors} onSubmit={handleOnSubmit}>
          <FormGroup>
            <FormLabel>Notes</FormLabel>
            <RichTextArea name="event[notes]" defaultValue={eventData?.notes} />
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
