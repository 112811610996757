import { FeatureToggleService, FolderService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { folderIdsInReviewWorkflowSelector, folderIdsWithStatusDoneSelector } from '../folders/AccountFoldersDataDuck';
import { projectMetaDataForProjectSelector } from '../project-meta/ProjectsMetaDataDuck';
import ProjectPermissionsSettings from './ProjectPermissionsSettings';
import { clientSettingsDataSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderType: FolderService.FOLDER_TYPES }) {
  const { projectId, folderType } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);
  const clientSettings = clientSettingsDataSelector(state);

  const isToggleEnabled = (key: ProjectMetaService.PROJECT_META_TOGGLE_ITEMS) => {
    // conditionally selecting the toggleValue because we will not get 'enable_download' keys in the project meta for older projects
    if (key === ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD) {
      return projectMeta && 'enable_highlight_download' in projectMeta
        ? projectMeta?.enable_highlight_download
        : clientSettings.enable_highlight_download;
    }
    if (key === ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_SELECTION_DOWNLOAD) {
      return projectMeta && 'enable_selection_download' in projectMeta
        ? projectMeta?.enable_selection_download
        : clientSettings.enable_selection_download;
    }
    return projectMeta && projectMeta[key];
  };

  const folderIdsWithStatusDone = folderIdsWithStatusDoneSelector(state);
  const folderIdsInReviewWorkflow = folderIdsInReviewWorkflowSelector(state);

  return {
    projectId,
    folderType,
    isHighlightPhotoDownloadToggleEnabled:
      isToggleEnabled(ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD) || false,
    isSelectionPhotoDownloadToggleEnabled:
      isToggleEnabled(ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_SELECTION_DOWNLOAD) || false,
    isGuestGalleryBulkDownloadToggleEnabled:
      isToggleEnabled(ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ALLOW_GUEST_TO_BULK_DOWNLOAD) || false,
    isProtectWithPinToggleEnabled:
      isToggleEnabled(ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.PROTECT_WITH_PIN) || false,
    isReviewEnabled: folderIdsWithStatusDone.length === 0,
    isReviewToggleHidden: folderIdsWithStatusDone.length === 0 && folderIdsInReviewWorkflow.length === 0,
    isBlockGalleryToggleEnabled: isToggleEnabled(ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.BLOCK_GALLERY) || false,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPermissionsSettings);
