import { QRCodeBackgroundPosterService } from '@premagic/core';
import { TEXT_BOLDNESS, TEXT_SIZE, Text } from '@premagic/myne';
import QRCodeSponsorCreativeItemContainer from './QRCodeSponsorCreativeItemContainer';

type Props = {
  logoSize: 'sm' | 'md';
  displayVertical?: boolean;
  sponsorLogoData: QRCodeBackgroundPosterService.QRCodeSponsorLogoDataType;
  title?: string;
  hasEventSponsorshipFeatureEnabled?: boolean;
  titleColor?: string;
};

export default function QRCodeSponsorLogosWithAlignment(props: Props) {
  const { logoSize, displayVertical, sponsorLogoData, title, hasEventSponsorshipFeatureEnabled, titleColor } = props;

  if (!hasEventSponsorshipFeatureEnabled || Object.values(sponsorLogoData).every((creativeId) => !creativeId))
    return null;

  return (
    <>
      {title && (
        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.NORMAL} block cssStyle={{ color: titleColor }}>
          {title}
        </Text>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          flexDirection: displayVertical ? 'column' : 'row',
        }}
      >
        {Object.entries(sponsorLogoData).map(([logoId, creativeId]) => (
          <QRCodeSponsorCreativeItemContainer key={logoId} id={creativeId} logoSize={logoSize} />
        ))}
      </div>
    </>
  );
}
