import { connect } from 'react-redux';
import { DeviceDuck } from '@premagic/common-ducks';
import { albumCreatorSelectedPageSelector } from '../AlbumCreatorDuck';

import { albumCreatorSelectedTemplateForActivePageSelector } from './AlbumPageDataDuck';
import AlbumCreatorSinglePage from './AlbumCreatorSinglePage';

function mapStateToProps(state) {
  return {
    isMobileUp: DeviceDuck.isMobileUp(state),
    templateId: albumCreatorSelectedTemplateForActivePageSelector(state),
    pageNo: albumCreatorSelectedPageSelector(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumCreatorSinglePage);
