import { ClientWebsiteService, FolderService } from '@premagic/core';
import { Button, BUTTON_SIZE, BUTTON_STYLES, Card, Divider, Icon, ICON_SIZES, Space, SubTitle } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { useEffect } from 'react';
import APP_URLS from '../../../../../services/AppRouteURLService';
import EventAttendeeFaceCheckinZonesListContainer from './face-checkin-zone-list/EventAttendeeFaceCheckinZonesListContainer';

type SelfCheckinLinkProps = {
  clientWebsiteFolder?: FolderService.FolderType;
};

function SelfCheckinLink(props: SelfCheckinLinkProps) {
  const { clientWebsiteFolder } = props;
  if (!clientWebsiteFolder) return null;
  const url = `${clientWebsiteFolder.share_url}${ClientWebsiteService.CLIENT_WEBSITE_LINK.CHECKIN_ZONE_SELFIE}`;

  return (
    <Button link={url} style={BUTTON_STYLES.LINK} isExternalLink>
      <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} />
      Open Checkin Link
    </Button>
  );
}

type CheckinStatsLinkProps = {
  eventId: string;
  projectId: string;
};

function CheckinStatsLink(props: CheckinStatsLinkProps) {
  const { eventId, projectId } = props;
  const checkinStatsUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__CHECKIN_STATS, {
    eventId,
    projectId,
  });

  return (
    <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} link={checkinStatsUrl}>
      <Icon name="pie_chart" size={ICON_SIZES.SM} />
      <Space size={1} />
      Reports
    </Button>
  );
}

type Props = {
  isSelfCheckinFeatureEnabled?: boolean;
  isQRCodeCheckinFeatureEnabled?: boolean;
  clientWebsiteFolder?: FolderService.FolderType;
  getEventAttendeesCheckinZones: (projectId: string) => void;
  projectId: string;
  eventId: string;
};

export default function EventAttendeeFaceCheckinCard(props: Props) {
  const {
    isSelfCheckinFeatureEnabled,
    isQRCodeCheckinFeatureEnabled,
    clientWebsiteFolder,
    getEventAttendeesCheckinZones,
    projectId,
    eventId,
  } = props;

  useEffect(() => {
    if (isSelfCheckinFeatureEnabled || isQRCodeCheckinFeatureEnabled) getEventAttendeesCheckinZones(projectId);
  }, [projectId, getEventAttendeesCheckinZones, isSelfCheckinFeatureEnabled, isQRCodeCheckinFeatureEnabled]);

  if (!(isSelfCheckinFeatureEnabled || isQRCodeCheckinFeatureEnabled)) return null;

  return (
    <Card>
      <Space>
        <SubTitle>Event Check-In</SubTitle>
      </Space>
      <Divider />
      <Space vertical size={2} />
      <EventAttendeeFaceCheckinZonesListContainer projectId={projectId} />
      <Space vertical />
      <Divider />
      <Space>
        <SelfCheckinLink clientWebsiteFolder={clientWebsiteFolder} />
        <Space />
        <CheckinStatsLink eventId={eventId} projectId={projectId} />
      </Space>
    </Card>
  );
}
