import { ProjectMetaService } from '@premagic/core';
import { i18n } from '@premagic/utils';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { saveClientWebsite } from '../../../client-website/ClientWebsiteDataDuck';
import { projectMetaDataForProjectSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';

import {
  clientWebsiteIdForProjectSelector,
  eventProjectSelectorSelector,
} from '../../../projects/AccountProjectsDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission, isStaffUserSelector } from '../../users/UsersDataDuck';
import EventSettingsLayout from './EventSettingsLayout';

function mapStateToProps(state, ownProps) {
  const { eventId, focusId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    eventId,
    projectId,
    project,
    focusId,
    hasPermissions: {
      [PERMISSIONS.SETTINGS]: hasPermission(PERMISSIONS.SETTINGS)(state),
    },
    defaultLanguageCode: (projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.DEFAULT_LANG] ||
      i18n.LANGUAGE_CODE.EN) as i18n.LANGUAGE_CODE,
    isStaffUser: isStaffUserSelector(state),
    clientWebsiteId: clientWebsiteIdForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSelectLanguagePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT.SHOW_LANGUAGE_SELECTOR_PANEL, true));
    },
    updateClientWebsite: (websiteId, projectId, data) => {
      dispatch(saveClientWebsite(dispatch, { id: websiteId, projectId }, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSettingsLayout);
