import {
  BUTTON_ICONS_SIZES,
  BUTTON_ICON_STYLES,
  ButtonIcon,
  ICON_SIZES,
  Icon,
  MODAL_SIZES,
  Modal,
  ModalTitle,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
} from '@premagic/myne';
import AccountFolderPhotoDropZoneContainer from '../../../../../projects/folders/folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderFilesUploadErrorContainer from '../../../../../projects/folders/folder-files-upload/FolderFilesUploadErrorContainer';
import FolderUploadProgressContainer from '../../../../../projects/folders/folder-files-upload/FolderUploadProgressContainer';
import FolderGalleryWithFileSelectContainer from '../../../../../projects/folders/gallery/FolderGalleryWithFileSelectContainer';
import { EventAttendeeBadgeDesignType } from '../service/EventAttendeeBadgeDesignService';

type Props = {
  close: () => void;
  show: boolean;
  projectId: string;
  folderId: string;
  onSelect: (
    projectId: string,
    folderId: string,
    previousDesign?: EventAttendeeBadgeDesignType,
    imageId?: string,
  ) => void;
  designData?: EventAttendeeBadgeDesignType;
};

export default function EventAttendeeBadgePosterUploadModal(props: Props) {
  const { show, close, projectId, folderId, designData, onSelect } = props;

  function handleOnSelect(imageId) {
    onSelect(projectId, folderId, designData, imageId);
    close();
  }
  return (
    <Modal show={show} close={close} size={MODAL_SIZES.MD}>
      <Row vcenter spaceBetween>
        <ModalTitle>Select Poster</ModalTitle>
        <ButtonIcon onClick={close} title="Close" size={BUTTON_ICONS_SIZES.SM} style={BUTTON_ICON_STYLES.SECONDARY}>
          <Icon name="x" size={ICON_SIZES.LG} />
        </ButtonIcon>
      </Row>
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId} />
      <FolderUploadProgressContainer folderId={folderId} />
      <FolderFilesUploadErrorContainer folderId={folderId} />
      <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical">
        <FolderGalleryWithFileSelectContainer projectId={projectId} folderId={folderId} onSelect={handleOnSelect} />
      </ScrollableContainer>
    </Modal>
  );
}
