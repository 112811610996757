import React from 'react';

import { Grid, LoadingDots, Row, Space, Text } from '@premagic/myne';

import SponsorReportCardContainer from './SponsorReportCardContainer';

type Props = {
  isLoading: boolean;
  sponsorIds: Array<string>;
  projectId: string;
};

export default function SponsorReportCards(props: Props): JSX.Element {
  const { projectId, isLoading, sponsorIds } = props;

  if (isLoading)
    return (
      <Row center>
        <LoadingDots />
      </Row>
    );

  return (
    <div>
      {sponsorIds.length === 0 && (
        <Space>
          <Text muted block>
            No sponsors yet
          </Text>
        </Space>
      )}
      <Grid colSize={400}>
        {sponsorIds.map((id) => (
          <SponsorReportCardContainer id={id} projectId={projectId} key={id} />
        ))}
      </Grid>
    </div>
  );
}
