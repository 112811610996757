import CreateColor from './colorClass';

function css(filters) {
  function fmt(idx, multiplier = 1) {
    return Math.round(filters[idx] * multiplier);
  }
  // return `filter: invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%);`;
  return `brightness(0) saturate(100%) invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(
    3,
    3.6,
  )}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%)`;
}

class Solver {
  target?: any;

  targetHSL?: any;

  reusedColor?: any;

  // constructor(target, baseColor) {
  constructor(target) {
    this.target = target;
    this.targetHSL = target.hsl();
    this.reusedColor = new CreateColor(0, 0, 0);
  }

  solve() {
    const result = this.solveNarrow(this.solveWide());
    return {
      values: result.values,
      loss: result.loss,
      filter: css(result.values),
    };
  }

  solveWide() {
    const A = 5;
    const c = 15;
    const a = [60, 180, 18000, 600, 1.2, 1.2];

    let best = { loss: Infinity };
    for (let i = 0; best.loss > 25 && i < 3; i += 1) {
      const initial = [50, 20, 3750, 50, 100, 100];
      const result = this.spsa(A, a, c, initial, 1000);
      if (result.loss < best.loss) {
        best = result;
      }
    }
    return best;
  }

  solveNarrow(wide) {
    const A = wide.loss;
    const c = 2;
    const A1 = A + 1;
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
    return this.spsa(A, a, c, wide.values, 500);
  }

  spsa(A, a, c, values, iters) {
    const newValues = values;

    function fix(value, idx) {
      let newValue = value;
      let max = 100;
      if (idx === 2 /* saturate */) {
        max = 7500;
      } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
        max = 200;
      }

      if (idx === 3 /* hue-rotate */) {
        if (newValue > max) {
          newValue %= max;
        } else if (newValue < 0) {
          newValue = max + (newValue % max);
        }
      } else if (newValue < 0) {
        newValue = 0;
      } else if (newValue > max) {
        newValue = max;
      }
      return newValue;
    }

    const alpha = 1;
    const gamma = 0.16666666666666666;

    let best = null;
    let bestLoss = Infinity;
    const deltas = new Array(6);
    const highArgs = new Array(6);
    const lowArgs = new Array(6);

    for (let k = 0; k < iters; k += 1) {
      // const ck = c / Math.pow(k + 1, gamma);
      const ck = c / (k + 1 ** gamma);
      for (let i = 0; i < 6; i += 1) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1;
        highArgs[i] = newValues[i] + ck * deltas[i];
        lowArgs[i] = newValues[i] - ck * deltas[i];
      }

      const lossDiff = this.loss(highArgs) - this.loss(lowArgs);
      for (let i = 0; i < 6; i += 1) {
        const g = (lossDiff / (2 * ck)) * deltas[i];
        // const ak = a[i] / Math.pow(A + k + 1, alpha);
        const ak = a[i] / (A + k + 1 ** alpha);
        newValues[i] = fix(newValues[i] - ak * g, i);
      }

      const loss = this.loss(newValues);
      if (loss < bestLoss) {
        best = newValues.slice(0);
        bestLoss = loss;
      }
    }
    return { values: best, loss: bestLoss };
  }

  loss(filters) {
    // Argument is array of percentages.
    const color = this.reusedColor;
    color.set(0, 0, 0);

    color.invert(filters[0] / 100);
    color.sepia(filters[1] / 100);
    color.saturate(filters[2] / 100);
    color.hueRotate(filters[3] * 3.6);
    color.brightness(filters[4] / 100);
    color.contrast(filters[5] / 100);

    const colorHSL = color.hsl();
    return (
      Math.abs(color.r - this.target.r) +
      Math.abs(color.g - this.target.g) +
      Math.abs(color.b - this.target.b) +
      Math.abs(colorHSL.h - this.targetHSL.h) +
      Math.abs(colorHSL.s - this.targetHSL.s) +
      Math.abs(colorHSL.l - this.targetHSL.l)
    );
  }
}

function hexToRgb(hex) {
  let newHex = hex;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  newHex = newHex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export default function getColorFilter(hex) {
  const rgb = hexToRgb(hex);
  let result;
  if (rgb) {
    const color = new CreateColor(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    result = solver.solve();
  }
  return result;
}
