import { BUTTON_SIZE, BUTTON_STYLES, Button } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  navigateTo: (url: string) => void;
  projectId: string;
  folderId: string;
  eventId?: string;
};

export default function WatermarkPreviewButton(props: Props) {
  const { navigateTo, projectId, folderId, eventId } = props;

  function handleClick() {
    // if eventId is present, its a sponsors page
    const url = eventId
      ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__SPONSORS__PROJECT_WATERMARK_PREVIEW, {
          eventId,
          projectId,
          folderId,
        })
      : BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT__WATERMARK_PREVIEW, { projectId, folderId });
    navigateTo(url);
  }

  return (
    <Button style={BUTTON_STYLES.TERTIARY} size={BUTTON_SIZE.SM} onClick={handleClick}>
      Preview Watermark
    </Button>
  );
}
