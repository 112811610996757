import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export function uploadFromExternalApp(projectId: string, url: string) {
  const URL = API_URLS.EXTERNAL_UPLOADER.UPLOAD(projectId);

  return HttpUtils.post(
    URL,
    {
      url,
      auto_share_folder: true,
    },
    true,
  )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}
