import { connect } from 'react-redux';
import { FolderFilePositionOrderService } from '@premagic/core';
import { folderFilesPositionOrderSortedByValueSelector } from '../../../images/FilesOrderDataDuck';
import FolderGallerySortedByDropdown from './FolderGallerySortedByDropdown';
import { foldersEntitiesSelector, updateFolderFileSortedByType } from '../AccountFoldersDataDuck';
import { folderMetaDataForFolderSelector, saveFolderMetaData } from '../../folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    folder: foldersEntitiesSelector(state)[folderId] || {},
    sortedBy: folderFilesPositionOrderSortedByValueSelector(folderId)(state),
    sortedByValueFromMetaData: folderMetaDataForFolderSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFolderFileSortedByType: (
      projectId: string,
      folderId: string,
      sortedByType: FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES,
    ) => {
      dispatch(updateFolderFileSortedByType(dispatch, projectId, folderId, sortedByType));
    },
    saveFolderMetaData: (projectId: string, folderId: string, data) => {
      dispatch(saveFolderMetaData(dispatch, projectId, folderId, { ...data }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderGallerySortedByDropdown);
