import { AccountFinancialsMetricType } from '../AccountFinancialsService';

export enum METRIC_REPRESENTATION_TYPES {
  TEXT,
  PIE_CHART,
  TEXT_2_METRIC,
}

export enum ACCOUNT_FINANCIALS_METRICS {
  QUOTE,
  PENDING_DUE_TO_COLLECT,
  REVENUE,
  EXPENSES,
  MONEY_COLLECTED,
}

export type AccountFinancialsMetricsDetailType = {
  title: string;
  representationType: METRIC_REPRESENTATION_TYPES;
  metrics: Array<{
    key: keyof AccountFinancialsMetricType;
    label: string;
  }>;
};

export const ACCOUNT_FINANCIALS_METRICS_DETAILS: Record<
  ACCOUNT_FINANCIALS_METRICS,
  AccountFinancialsMetricsDetailType
> = {
  [ACCOUNT_FINANCIALS_METRICS.QUOTE]: {
    title: 'Quotes Confirmed',
    representationType: METRIC_REPRESENTATION_TYPES.TEXT,
    metrics: [
      {
        key: 'total_quotes_confirmed_projects',
        label: 'Total',
      },
    ],
  },
  [ACCOUNT_FINANCIALS_METRICS.PENDING_DUE_TO_COLLECT]: {
    title: 'Total Pending due to collect',
    representationType: METRIC_REPRESENTATION_TYPES.PIE_CHART,
    metrics: [
      {
        key: 'total_pending_due_to_collect',
        label: 'Total',
      },
      {
        key: 'total_upcoming_due',
        label: 'Upcoming',
      },
      {
        key: 'total_overdue_payments_to_collect',
        label: 'Overdue',
      },
    ],
  },
  [ACCOUNT_FINANCIALS_METRICS.REVENUE]: {
    title: 'Revenue',
    representationType: METRIC_REPRESENTATION_TYPES.TEXT_2_METRIC,
    metrics: [
      {
        key: 'expected_revenue',
        label: 'Expected revenue',
      },
      {
        key: 'current_revenue',
        label: 'Current revenue',
      },
    ],
  },
  [ACCOUNT_FINANCIALS_METRICS.EXPENSES]: {
    title: 'Expenses',
    representationType: METRIC_REPRESENTATION_TYPES.PIE_CHART,
    metrics: [
      {
        key: 'total_expenses',
        label: 'Total',
      },
      {
        key: 'total_expenses_settled',
        label: 'Settled',
      },
      {
        key: 'total_expenses_unsettled',
        label: 'Un-Settled',
      },
    ],
  },
  [ACCOUNT_FINANCIALS_METRICS.MONEY_COLLECTED]: {
    title: 'Total dues collected',
    representationType: METRIC_REPRESENTATION_TYPES.TEXT,
    metrics: [
      {
        key: 'total_dues_collected',
        label: 'Total',
      },
    ],
  },
};
