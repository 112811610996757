import { combineReducers } from 'redux';
import { ProposalDataReducer } from './ProposalDataDuck';
import { ProposalDeckDataReducer } from './proposal-deck/ProposalDeckDataDuck';
import { ProposalSlideDataReducer } from './proposal-slide/ProposalSlideDataDuck';
import { ProposalVariableDataReducer } from './proposal-variables/ProposalVariablesDataDuck';

export const ProposalPageDataReducer = combineReducers({
  proposals: ProposalDataReducer,
  variables: ProposalVariableDataReducer,
  decks: ProposalDeckDataReducer,
  slides: ProposalSlideDataReducer,
});
