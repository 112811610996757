import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ArrayUtils, SimpleDateUtils, ActionTypeUtils } from '@premagic/utils';
import { EventTrackerService } from '@premagic/core';

import { LOADINGS } from '../../../../common/Constants';
import { setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { entitiesDataSelector } from '../../reducers/selectors';

import {
  PhotographerActionType,
  fetchAllPhotographerActionService,
  markActionAsDoneService,
  markActionAsDismissedService,
} from './PhotographerActionService';

const getActionType = ActionTypeUtils.getActionTypeFunction('PHOTOGRAPHER_ACTION', false);

export const setPhotographerActionsData = createAction(
  getActionType('DATA', 'SET'),
  (
    dispatch,
    data: {
      [id: string]: PhotographerActionType;
    },
  ) => data,
);

export const addPhotographerAction = createAction(
  getActionType('DATA', 'ADD'),
  (dispatch, data: PhotographerActionType) => ({
    [data.id]: data,
  }),
);

export const updatePhotographerAction = createAction(
  getActionType('DATA', 'UPDATE'),
  (dispatch, id: string, data: Partial<PhotographerActionType>) => ({
    [id]: data,
  }),
);

export const fetchAllPhotographerActions = createAction(
  getActionType('DATA', 'fetch'),
  (dispatch, listType: 'done' | 'new') => {
    const loadingKey = LOADINGS.PHOTOGRAPHER_ACTIONS.FETCH;

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    fetchAllPhotographerActionService(listType)
      .then((data) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(setPhotographerActionsData(dispatch, data));
        return data;
      })
      .catch((e) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setErrorState(dispatch, loadingKey, e));
      });
  },
);

export const markActionAsDone = createAction(getActionType('DATA', 'DONE'), (dispatch, actionId) => {
  const loadingKey = `${LOADINGS.PHOTOGRAPHER_ACTIONS.MARK_DONE}${actionId}`;

  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER_ACTIONS.ALLOW_SELECTION);
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  markActionAsDoneService(actionId)
    .then((response) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));

      dispatch(updatePhotographerAction(dispatch, actionId, { status: 'DONE' }));
      // dispatch(updatePhotographerAction(dispatch, response.data));
      return response.data;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setErrorState(dispatch, loadingKey, e));
    });
});

export const dismissAction = createAction(getActionType('DATA', 'DISMISS'), (dispatch, actionId) => {
  const loadingKey = `${LOADINGS.PHOTOGRAPHER_ACTIONS.MARK_DONE}${actionId}`;

  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER_ACTIONS.DISMISS_SELECTION);
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  markActionAsDismissedService(actionId)
    .then((response) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));

      dispatch(updatePhotographerAction(dispatch, actionId, { status: 'READ' }));
      return response.data;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setErrorState(dispatch, loadingKey, e));
    });
});

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setPhotographerActionsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addPhotographerAction.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updatePhotographerAction.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState,
);

export const photographerActionsEntitiesSelector = createSelector(
  entitiesDataSelector,
  (state: any) => state.photographerActions.items,
);

export const photographerActionsSizeSelector = createSelector(
  photographerActionsEntitiesSelector,
  (item: any) => item.size,
);

export const pendingPhotographerActionsSelector = (projectId) =>
  createSelector(
    photographerActionsEntitiesSelector,
    (items: any) =>
      items
        .filter((item) => item.project_id === projectId)
        .filter((item) => item.status === 'NEW')
        .map((item) => ({
          ...item,
          created_at: SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(item.created_at),
        }))
        .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.created_at, b.created_at, true))
        .toJS(), // Oldest first
  );
