import React, { useEffect, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  FormFooter,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Row,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  ButtonIcon,
  BUTTON_ICONS_SIZES,
  BUTTON_ICON_STYLES,
} from '@premagic/myne';
import { FolderService, QRCodeBackgroundPosterService } from '@premagic/core';

import QRCodeLogoFilesContainer from './QRCodeLogoFilesContainer';
import QRCodeLogoImageEditorContainer from './QRCodeLogoImageEditorContainer';

type Props = {
  projectId: string;
  folderId: string;
  show: boolean;
  closeModal: () => void;
  logoId?: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
  fetchLogoImages: (projectId, folderId) => void;
  logos?: Array<string | undefined>;
};

export default function QRCodeLogoSelectorModal(props: Props): JSX.Element | null {
  const { show, closeModal, projectId, folderId, logoId, fetchLogoImages, logos } = props;

  let isFileSelected;
  if (logoId && logos) {
    const { positionIndex } = QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_DETAILS[logoId];
    isFileSelected = logos[positionIndex];
  }

  useEffect(() => {
    fetchLogoImages(projectId, folderId);
  }, [projectId, folderId, fetchLogoImages]);

  if (!logoId) return null;

  return (
    <Modal show={show} close={closeModal} size={MODAL_SIZES.MD} showFooterCloseButton>
      {isFileSelected ? (
        <>
          <ModalTitle>Custom Logo</ModalTitle>
          <QRCodeLogoImageEditorContainer projectId={projectId} folderId={folderId} logoId={logoId} />
        </>
      ) : (
        <>
          <ModalTitle>Select a file for the Logo</ModalTitle>
          <QRCodeLogoFilesContainer projectId={projectId} folderId={folderId} logoId={logoId} />
        </>
      )}
    </Modal>
  );
}
