import React, { useMemo } from 'react';
import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  FormGroup,
  FormLabel,
  Input,
  INPUT_SIZES,
  Select,
  Space,
  Text,
} from '@premagic/myne';
import { EventSponsorsService, SponsorCreativesService } from '@premagic/core';
import { ArrayUtils, StringUtils } from '@premagic/utils';
import SponsorFilesSelectorContainer from '../../../../projects/sponsor/sponsor-files/SponsorFilesSelectorContainer';

const ctaNameOptions = Object.entries(SponsorCreativesService.SPONSORS_CREATIVES_CTA_NAME_DETAILS).map(
  ([id, details]) => ({
    value: id,
    label: details.label,
  }),
);

function CTATextSelector(props: { defaultValue?: SponsorCreativesService.SPONSORS_CREATIVES_CTA_NAME }) {
  const { defaultValue } = props;
  const selectedOption = ctaNameOptions.find((option) => option.value === defaultValue) || ctaNameOptions[0];

  return (
    <FormGroup>
      <FormLabel>Call-to-Action Button text</FormLabel>
      <Select size={INPUT_SIZES.MD} options={ctaNameOptions} value={selectedOption} name="cta_name" isMulti={false} />
    </FormGroup>
  );
}

const typeOptions = Object.entries(SponsorCreativesService.SPONSORS_CREATIVES_TYPE_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.label,
}));

function TypeSelector(props: { defaultValue?: SponsorCreativesService.SPONSORS_CREATIVES_TYPE }) {
  const { defaultValue } = props;
  const selectedOption = typeOptions.find((option) => option.value === defaultValue) || typeOptions[0];

  return (
    <FormGroup>
      <FormLabel isRequired>Creative Type</FormLabel>
      <Select size={INPUT_SIZES.SM} options={typeOptions} value={selectedOption} name="type" isMulti={false} />
    </FormGroup>
  );
}

const STATUS_OPTIONS = [
  {
    value: SponsorCreativesService.SPONSORS_CREATIVES_PUBLISH_STATUS.PUBLISHED,
    label: 'Yes',
  },
  {
    value: SponsorCreativesService.SPONSORS_CREATIVES_PUBLISH_STATUS.DRAFT,
    label: 'No',
  },
];

type Props = {
  projectId: string;
  creativeId?: string;
  sponsors?: EventSponsorsService.EventSponsorType[];
  creative?: SponsorCreativesService.SponsorCreativeType;
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  sponsorsCategoryData?: Record<string, EventSponsorsService.EventSponsorCategoryType>;
  creativesStatsForPlacementGroupedBySponsor: Record<string, number>;
};
export default function SponsorCreativeForm(props: Props) {
  const {
    creative,
    projectId,
    creativeId,
    sponsors,
    placement,
    sponsorsCategoryData,
    creativesStatsForPlacementGroupedBySponsor,
  } = props;

  const sponsorWithOutCreative = useMemo(
    () => sponsors?.filter((sponsor) => !creativesStatsForPlacementGroupedBySponsor[sponsor.id]) || [],
    [creativesStatsForPlacementGroupedBySponsor, sponsors],
  );

  const sponsorsOptions = useMemo(
    () =>
      sponsors?.map((sponsor) => {
        const totalItems = creativesStatsForPlacementGroupedBySponsor[sponsor.id];
        return {
          value: sponsor.id,
          label: sponsor.name,
          info: `${totalItems || 'No'} ${StringUtils.pluralize('creative', totalItems)}`,
        };
      }),
    [creativesStatsForPlacementGroupedBySponsor, sponsors],
  );
  const selectedPlacement = creative?.placement || placement;
  const showSelectSponsor = !creativeId;

  const defaultSponsorId = creative?.sponsor_id || sponsorWithOutCreative[0]?.id;

  if (!sponsorsOptions) {
    return <Text>Create at least one sponsor</Text>;
  }
  return (
    <div>
      {sponsorWithOutCreative.length > 0 && (
        <div>
          <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
            {ArrayUtils.listify(
              sponsorWithOutCreative.map((item) => item.name),
              5,
            )}{' '}
            doesnt have any creative
          </Alert>
          <Space vertical />
        </div>
      )}
      {showSelectSponsor ? (
        <FormGroup>
          <FormLabel isRequired>Sponsor</FormLabel>
          <Select
            name="sponsor_id"
            size={INPUT_SIZES.SM}
            options={sponsorsOptions}
            isMulti={false}
            autoFocus
            value={sponsorsOptions.find((option) => option.value === defaultSponsorId)}
          />
        </FormGroup>
      ) : (
        <input type="hidden" name="sponsor_id" value={creative?.sponsor_id} />
      )}
      <FormGroup>
        <FormLabel isRequired info="Useful for analysing the report">
          Name
        </FormLabel>
        <Input
          name="name"
          required
          placeholder="eg. white company logo"
          defaultValue={creative?.name || 'Logo'}
          size={INPUT_SIZES.MD}
        />
      </FormGroup>
      <TypeSelector />
      <SponsorFilesSelectorContainer
        name="asset_ids[0]"
        defaultValue={creative?.asset_ids?.[0]}
        placement={placement}
      />
      <FormGroup>
        <FormLabel>Call-to-Action link</FormLabel>
        <Input name="cta_link" placeholder="https://www.google.com" defaultValue={creative?.cta_link} />
      </FormGroup>
      {selectedPlacement &&
        [SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.IN_GALLERY].includes(selectedPlacement) && (
          <CTATextSelector defaultValue={creative?.cta_name} />
        )}
      {creativeId ? (
        <FormGroup>
          <FormLabel isRequired>Show this creative content?</FormLabel>
          <Select
            name="status"
            size={INPUT_SIZES.SM}
            options={STATUS_OPTIONS}
            isMulti={false}
            value={STATUS_OPTIONS.find((option) => option.value === creative?.status)}
          />
        </FormGroup>
      ) : (
        <input
          type="hidden"
          name="status"
          value={SponsorCreativesService.SPONSORS_CREATIVES_PUBLISH_STATUS.PUBLISHED}
        />
      )}
      <input type="hidden" name="placement" value={selectedPlacement} />
    </div>
  );
}
