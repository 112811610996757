import { connect } from 'react-redux';
import PaymentSummary from './PaymentSummary';
import {
  crmEventRevenueSelector,
  crmEventRevenuePercentSelector,
  crmExpensePaymentTotalSelector,
  crmBalanceAmountSelector,
  crmTotalTaxAmountSelector,
} from './CRMPaymentDataDuck';
import { isMobileUp } from '../../AppDuck';
import { accountTaxNameSelector, accountTaxPercentageSelector } from '../../acccount/AccountDataDuck';

function mapStateToProps(state, ownProps: { eventId: string }) {
  return {
    eventId: ownProps.eventId,
    totalExpense: crmExpensePaymentTotalSelector(state),
    balanceAmount: crmBalanceAmountSelector(state),
    revenue: crmEventRevenueSelector(state),
    revenuePercent: crmEventRevenuePercentSelector(state),
    isMobileUp: isMobileUp(state),
    taxName: accountTaxNameSelector(state),
    taxPercentage: accountTaxPercentageSelector(state),
    totalTaxAmount: crmTotalTaxAmountSelector(state),
  };
}

export default connect(mapStateToProps)(PaymentSummary);
