import { QRCodeBackgroundPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  PillItem,
  Pills,
  PILLS_STYLES,
  Row,
  SimpleImage,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_COLORS,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useState } from 'react';
import CompanyLogoContainer from '../../../../settings/company/CompanyLogoContainer';
import CustomLogoPreviewAndSelectContainer from './CustomLogoPreviewAndSelectContainer';

type LogoTypeSelectorProps = {
  handleChange: (option: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID) => void;
  selectedLogoType: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
};

function LogoTypeSelector(props: LogoTypeSelectorProps) {
  const { handleChange, selectedLogoType } = props;

  return (
    <Pills style={PILLS_STYLES.SECONDARY}>
      {Object.values(QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_DETAILS).map((item) => (
        <PillItem key={item.id} active={selectedLogoType === item.id} onClick={() => handleChange(item.id)}>
          <Text
            size={TEXT_SIZE.SIZE_5}
            boldness={TEXT_BOLDNESS.SEMI_BOLD}
            color={selectedLogoType === item.id ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
          >
            {item.title}
          </Text>
        </PillItem>
      ))}
    </Pills>
  );
}

type Props = {
  folderId: string;
  projectId: string;
  companyLogo?: string;
  navigateToCompanyLogoUpload: () => void;
  updateLogoOptionType: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    optionValue?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID,
  ) => void;
  logoOptionType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodePosterLogoSelector(props: Props) {
  const {
    folderId,
    projectId,
    companyLogo,
    navigateToCompanyLogoUpload,
    updateLogoOptionType,
    qrCodeDesigns,
    logoOptionType = QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.COMPANY_LOGO,
  } = props;

  function handleLogoTypeOptionChange(value: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID) {
    updateLogoOptionType(projectId, folderId, qrCodeDesigns, value);
  }

  return (
    <>
      <LogoTypeSelector handleChange={handleLogoTypeOptionChange} selectedLogoType={logoOptionType} />
      <Space vertical size={2} />
      <Card style={CARD_STYLES.SECONDARY}>
        <Space>
          {(() => {
            if (logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO) {
              return (
                <Row spaceAround>
                  {Object.values(QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_DETAILS).map((item) => (
                    <CustomLogoPreviewAndSelectContainer
                      key={item.id}
                      logoId={item.id}
                      title={item.title}
                      folderId={folderId}
                      projectId={projectId}
                      positionIndex={item.positionIndex}
                    />
                  ))}
                </Row>
              );
            }
            // Company Logo
            return (
              <Row center vcenter>
                <Card style={CARD_STYLES.PRIMARY} size={CARD_SIZES.DEFAULT} center>
                  <Space size={4}>
                    <div style={{ width: '60px', height: '62px' }}>
                      {companyLogo ? (
                        <SimpleImage
                          src={companyLogo}
                          alt="company-logo"
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                      ) : (
                        <Button
                          size={BUTTON_SIZE.SM}
                          onClick={() => navigateToCompanyLogoUpload()}
                          style={BUTTON_STYLES.RESET}
                        >
                          <Space>
                            <Color inline shade={COLOR_SHADES.LIGHT}>
                              <Icon name="image" size={ICON_SIZES.MD} />
                            </Color>
                          </Space>
                        </Button>
                      )}
                    </div>
                  </Space>
                </Card>
              </Row>
            );
          })()}
        </Space>
      </Card>
    </>
  );
}
