import {
  Card,
  Col,
  Color,
  COLOR_SHADES,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  Row,
  Select,
  Space,
  Text,
  Tooltip,
} from '@premagic/myne';
import { PhotoWatermarkUtils } from '@premagic/utils';
import React, { useState } from 'react';

const presetOptions = Object.entries(PhotoWatermarkUtils.WATERMARK_POSITION_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.label,
}));

/**
 * watermark(image, x, y, alpha [, w_ratio [, h_ratio]]) adds a watermark to the image. It can be positioned inside the image with the alpha channel specified and optionally resized based on the image size by specifying the ratio
 * - image watermark image URI, using the same image loader configured for imagor
 * - x horizontal position that the watermark will be in:
 *    - Positive number indicate position from the left, negative number from the right.
 *    - Number followed by a p e.g. 20p means calculating the value from the image width as percentage
 *    - left,right,center positioned left, right or centered respectively
 *    - repeat the watermark will be repeated horizontally
 * - y vertical position that the watermark will be in:
 *    - Positive number indicate position from the top, negative number from the bottom.
 *    - Number followed by a p e.g. 20p means calculating the value from the image height as percentage
 *    - top,bottom,center positioned top, bottom or centered respectively
 *    - repeat the watermark will be repeated vertically
 * - alpha watermark image transparency, a number between 0 (fully opaque) and 100 (fully transparent).
 * - w_ratio percentage of the width of the image the watermark should fit-in
 * - h_ratio percentage of the height of the image the watermark should fit-in
 */
const commonPositionOption = [
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'repeat',
    label: 'Repeat',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];
const positionOptions = {
  x: [
    {
      value: 'left',
      label: 'Left',
    },
    {
      value: 'right',
      label: 'Right',
    },
  ],
  y: [
    {
      value: 'top',
      label: 'Top',
    },
    {
      value: 'bottom',
      label: 'Bottom',
    },
  ],
};

type PositionSelectorProps = {
  name: string;
  onChange: (value: string) => void;
  value: number | undefined | string;
};

export function PositionSelector(props: PositionSelectorProps) {
  const { name, value, onChange } = props;
  const orientation = name === 'x' || name.includes('_x') ? 'x' : 'y';

  const options = [...positionOptions[orientation], ...commonPositionOption];
  const selectedOption = options.find((option) => option.value === value) || {
    value: 'custom',
    label: 'Custom',
  };
  return (
    <div>
      <Select
        size={INPUT_SIZES.SM}
        options={options}
        value={selectedOption}
        onChange={(option) => {
          if (option) {
            onChange(option.value as string);
          }
        }}
        name=""
        isMulti={false}
      />

      {selectedOption.value === 'custom' && (
        <div>
          <Space size={1} vertical />
          <Input
            name=""
            size={INPUT_SIZES.XS}
            value={Number(value)}
            type="number"
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      )}
      <input name={name} type="hidden" value={value} />
    </div>
  );
}

export default function WatermarkPositionSelector(props: {
  name: string;
  position?: PhotoWatermarkUtils.WATERMARK_POSITIONS;
  x?: string | number;
  y?: string | number;
}) {
  const { position = PhotoWatermarkUtils.WATERMARK_POSITIONS.BOTTOM_RIGHT, x: defaultX, y: defaultY, name } = props;
  const defaultOption = presetOptions.find((option) => option.value === position) || presetOptions[0];
  const details = PhotoWatermarkUtils.WATERMARK_POSITION_DETAILS[position];
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [x, setX] = useState<number | undefined | string>(defaultX || details.x);
  const [y, setY] = useState<number | undefined | string>(defaultY || details.y);

  return (
    <div>
      <FormGroup>
        <FormLabel>Position</FormLabel>
        <Card>
          <Space size={2}>
            <Row vcenter>
              <Select
                size={INPUT_SIZES.SM}
                options={presetOptions}
                value={selectedOption}
                onChange={(option) => {
                  if (option) {
                    const newDetails = PhotoWatermarkUtils.WATERMARK_POSITION_DETAILS[option.value as string];
                    setX(newDetails.x);
                    setY(newDetails.y);
                    setSelectedOption(
                      option as {
                        value: string;
                        label: string;
                      },
                    );
                  }
                }}
                name={name}
                isMulti={false}
              />
              <Space size={4} />
              <Tooltip
                message={
                  <div>
                    When Custom is selected, the watermark will be positioned based on the value entered, positive
                    number indicate position from the left/top, negative number from the right/bottom.
                    <Space vertical />
                    When Repeat is selected, the watermark will be repeated horizontally/vertically.
                  </div>
                }
              >
                <Color inline shade={COLOR_SHADES.LIGHTER} fillSolidIcon>
                  <Icon name="info_solid" size={ICON_SIZES.MD} />
                </Color>
              </Tooltip>
            </Row>
            <Space vertical size={4} />
            <Row>
              <Space size={4} />
              <Col size={6}>
                <Text>Vertical</Text>
                <PositionSelector value={y} name={name === 'position' ? 'y' : `${name}_y`} onChange={setY} />
              </Col>
              <Space />
              <Col size={6}>
                <Text>Horizontal</Text>
                <PositionSelector value={x} name={name === 'position' ? 'x' : `${name}_x`} onChange={setX} />
              </Col>
            </Row>
          </Space>
        </Card>
      </FormGroup>
    </div>
  );
}
