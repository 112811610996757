import { INPUT_TYPES } from '../Form';

export enum DECK_SLIDE_DATA_TYPES {
  TITLE = 'title',
  SUB_TITLE = 'sub-title',
  LARGE_TEXT = 'large-text',
  TERMS_AND_CONDITIONS = 'large-text-terms-and-condition',
  BACKGROUND_COLOR = 'background_color',
  COLOR_TINT_OPACITY = 'color_tint_opacity',
  BACKGROUND_IMAGE = 'background-image',
  BACKGROUND_VIDEO = 'background-video',
  INVOICE_ITEMS = 'invoice-items',
  EQUIPMENTS_ITEMS = 'equipments-items',
  TESTIMONIAL_ITEMS = 'testimonial-items',
}

export enum DECK_SLIDE_DATA_TYPE_ALIGNMENT {
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center',
}

export enum DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum DECK_SLIDE_DATA_TYPE_STYLES {
  NONE = 'none',
  GRADIENT_TO_TOP = 'gradient-to-top',
  BOX = 'box',
  GLASS = 'glass',
  FROST = 'frost',
  GLASS_BOX = 'glass-box',
  PAPER_CARD = 'paper-card',
}

export const DECK_SLIDE_DATA_TYPE_DETAILS: Record<
  DECK_SLIDE_DATA_TYPES,
  {
    title: string;
    inputType: INPUT_TYPES;
  }
> = {
  [DECK_SLIDE_DATA_TYPES.TITLE]: {
    title: 'Title',
    inputType: INPUT_TYPES.RICH_TEXTAREA_INLINE_SIMPLE,
  },
  [DECK_SLIDE_DATA_TYPES.SUB_TITLE]: {
    title: 'Sub Title',
    inputType: INPUT_TYPES.RICH_TEXTAREA_INLINE_SIMPLE,
  },
  [DECK_SLIDE_DATA_TYPES.LARGE_TEXT]: {
    title: 'Content',
    inputType: INPUT_TYPES.RICH_TEXTAREA,
  },
  [DECK_SLIDE_DATA_TYPES.TERMS_AND_CONDITIONS]: {
    title: 'Terms & Conditions',
    inputType: INPUT_TYPES.RICH_TEXTAREA,
  },
  [DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR]: {
    title: 'Color',
    inputType: INPUT_TYPES.COLOR,
  },
  [DECK_SLIDE_DATA_TYPES.COLOR_TINT_OPACITY]: {
    title: 'Color Tint Opacity',
    inputType: INPUT_TYPES.NUMBER,
  },
  [DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE]: {
    title: 'Photo',
    inputType: INPUT_TYPES.FILE,
  },
  [DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO]: {
    title: 'Video',
    inputType: INPUT_TYPES.FILE,
  },
  [DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS]: {
    title: 'Invoice Items',
    inputType: INPUT_TYPES.TEXT,
  },
  [DECK_SLIDE_DATA_TYPES.EQUIPMENTS_ITEMS]: {
    title: 'Equipments Items',
    inputType: INPUT_TYPES.TEXT,
  },
  [DECK_SLIDE_DATA_TYPES.TESTIMONIAL_ITEMS]: {
    title: 'Testimonials',
    inputType: INPUT_TYPES.TEXT,
  },
};

export type DeckSlideBackgroundDataType = {
  value?: Record<
    string,
    {
      id: string;
      position?: string;
    }
  >;
};

export type DeckSlideBackgroundColorDataType = {
  value?: string;
};

export type DeckSlideColorTintOpacityDataType = {
  value?: number;
};
export type DeckSlideContentBaseType = {
  value?: string;
  color?: string;
  align?: DECK_SLIDE_DATA_TYPE_ALIGNMENT;
  text_align?: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT;
  style?: DECK_SLIDE_DATA_TYPE_STYLES;
  scale?: number;
};
export type DeckSlideTitleDataType = DeckSlideContentBaseType;
export type DeckSlideSubTitleDataType = DeckSlideContentBaseType;
export type DeckSlideLargeTextDataType = DeckSlideContentBaseType;

// Clone of PROPOSAL_VARIABLE_TYPE from ProposalVariableService (to fix the cyclic dependency)
enum PROPOSAL_VARIABLE_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  DISCOUNT_PERCENT = 'discount-percentage',
  DISCOUNT_CURRENCY = 'discount-currency',
  TAX = 'tax-percentage',
}

export type DeckSlideVariableSingleItemDataType = {
  id: string;
  name: string;
  type: PROPOSAL_VARIABLE_TYPE;
  default_value: string;
};

export type DeckSlideInvoiceItemsDataType = {
  value?: Array<DeckSlideVariableSingleItemDataType>;
  hidden_items?: Array<string>;
};

export type DeckSlideEquipmentItemsDataType = {
  value?: Array<DeckSlideVariableSingleItemDataType>;
};

export type DeckSlideTestimonialItemsDataType = {
  value?: Array<DeckSlideVariableSingleItemDataType>;
};

export type DeckSlideDataType = {
  [DECK_SLIDE_DATA_TYPES.TITLE]?: DeckSlideTitleDataType;
  [DECK_SLIDE_DATA_TYPES.SUB_TITLE]?: DeckSlideSubTitleDataType;
  [DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?: DeckSlideLargeTextDataType;
  [DECK_SLIDE_DATA_TYPES.TERMS_AND_CONDITIONS]?: DeckSlideLargeTextDataType;
  [DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR]?: DeckSlideBackgroundColorDataType;
  [DECK_SLIDE_DATA_TYPES.COLOR_TINT_OPACITY]?: DeckSlideColorTintOpacityDataType;
  [DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE]?: DeckSlideBackgroundDataType;
  [DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO]?: DeckSlideBackgroundDataType;
  [DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS]?: DeckSlideInvoiceItemsDataType;
  [DECK_SLIDE_DATA_TYPES.EQUIPMENTS_ITEMS]?: DeckSlideEquipmentItemsDataType;
  [DECK_SLIDE_DATA_TYPES.TESTIMONIAL_ITEMS]?: DeckSlideTestimonialItemsDataType;
};
