import { connect } from 'react-redux';
import {
  filesArrayIdsGroupedByFolderIdSelector,
  filesArrayIdsSortedByRankSelector,
} from '../../../images/FilesDataDuck';
import { DAM_GALLERY_SORT_BY } from '../filters/DAMLibraryFilterTypes';
import DAMFilesGallery from './DAMFilesGallery';
import { foldersEntitiesSelector } from '../../folders/AccountFoldersDataDuck';

function mapStateToProps(state, ownProps: { filters: string; sortBy: DAM_GALLERY_SORT_BY }) {
  const { filters, sortBy } = ownProps;
  return {
    fileIdsSortedByRank: filesArrayIdsSortedByRankSelector(state),
    fileIdsGroupedByFolder: filesArrayIdsGroupedByFolderIdSelector(state),
    folderData: foldersEntitiesSelector(state),
    filters,
    sortBy,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMFilesGallery);
