import { EventAttendeesService, EventCustomFromFieldsService, PeopleFacesService } from '@premagic/core';
import {
  Avatar,
  AVATAR_SIZES,
  AVATAR_STYLES,
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  INPUT_SIZES,
  Modal,
  MODAL_SIZES,
  Row,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useMemo } from 'react';
import MESSAGES from '../../../../../../../common/Messages';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import AttendeeApprovalStatusContainer from './AttendeeApprovalStatusContainer';

type Props = {
  projectId: string;
  id?: string | null;
  show: boolean;
  isLoading?: boolean;
  error: FormErrorType;
  closeEdit: () => void;
  updateStatus: (
    options: { projectId: string; attendeeId: string },
    status: EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS,
  ) => void;
  attendee?: EventAttendeesService.EventAttendeeType;
  hasPermissionsToManageAttendees: boolean;
};

export default function EditAttendeeApprovalStatusModal(props: Props): JSX.Element | null {
  const { show, projectId, isLoading, error, closeEdit, updateStatus, id, attendee, hasPermissionsToManageAttendees } =
    props;
  const options = useMemo(() => {
    if (!attendee) return [];
    return Object.entries(EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS_DETAILS)
      .filter(([key]) => key !== attendee.approval)
      .map(([key, details]) => ({
        value: key,
        label: details.title,
      }));
  }, [attendee]);

  if (!id || !attendee) return null;
  const { name, email, custom_fields: customFields, best_face_id: bestFaceId, face_id: faceId } = attendee;

  const faceUrlToShow =
    customFields[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]?.dynamic_image_url ||
    PeopleFacesService.getPeopleFaceUrl(bestFaceId || faceId);

  function handleFormSubmit(formResponse) {
    const { status } = formResponse.data;
    if (id) updateStatus({ projectId, attendeeId: id }, status);
  }

  return (
    <Modal show={show} close={closeEdit} size={MODAL_SIZES.SM}>
      <Row vcenter>
        <Avatar
          style={AVATAR_STYLES.SECONDARY}
          size={AVATAR_SIZES.LG}
          user={{
            fullName: name,
            profilePic: faceUrlToShow,
          }}
        />
        <Space />
        <div>
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
            {name}
          </Text>
          {email && (
            <Text color={COLOR_SHADES.DARKER} block>
              {email}
            </Text>
          )}
          <Space vertical size={1} />
          <AttendeeApprovalStatusContainer id={id} editable={false} />
        </div>
      </Row>
      <Space vertical size={12} />
      <Form onSubmit={handleFormSubmit} errors={error}>
        <FormGroup>
          <FormLabel>Change status</FormLabel>
          <Select name="status" options={options} isMulti={false} value={options[0]} size={INPUT_SIZES.SM} />
        </FormGroup>

        <Space vertical size={12} />
        <FormFooter>
          <Button onClick={closeEdit} style={BUTTON_STYLES.LINK}>
            Cancel
          </Button>
          <Button
            style={BUTTON_STYLES.PRIMARY}
            isLoading={isLoading}
            disabled={!hasPermissionsToManageAttendees}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
          >
            Update
          </Button>
        </FormFooter>
      </Form>
    </Modal>
  );
}
