import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { PricingPlanFeaturesService } from '@premagic/core';
import FolderMakeItPublicDialog from './FolderMakeItPublicDialog';
import { isFolderPublicSelector, saveFolderMetaData } from '../../folder-meta/FoldersMetaDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';

const COMMON_KEY = LOADINGS.FOLDER.MARK_AS_PUBLIC;

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  return {
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER_META.UPDATE)(state),
    projectId,
    folderId,
    show: ModalDuck.isModalOpenSelector(COMMON_KEY)(state),
    isFolderPublic: isFolderPublicSelector(folderId)(state),
    hasShowInGuestGalleryFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFolderMeta: (projectId, folderId, formResponse) => {
      dispatch(saveFolderMetaData(dispatch, projectId, folderId, formResponse));
    },
    showMarkAsPublicFolder: (show) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.FOLDER.MARK_AS_PUBLIC, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderMakeItPublicDialog);
