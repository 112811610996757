import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum ENTITY_TYPE {
  EVENT = 'EVENT',
  OCCASION = 'OCCASION',
}

export type LabelsType = {
  id: string;
  name: string;
  color?: string;
  entity?: string;
  created_at?: string;
  updated_at?: string;
  accountId?: string;
};

export function fetchLabels(entityType: ENTITY_TYPE): Promise<{ results: Array<LabelsType>; count: number }> {
  return HttpUtils.get(API_URLS.CRM_LABELS.ROOT(entityType)).then((response) => response.data);
}

export function createLabel(entityType: ENTITY_TYPE, data: Partial<LabelsType>): Promise<LabelsType> {
  return HttpUtils.post(API_URLS.CRM_LABELS.ROOT(entityType), data, true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function addLabelToEvent(clientId: string, eventId: string, labelIds: Array<string>): Promise<void> {
  return HttpUtils.patch(API_URLS.CRM_LABELS.EVENT_LABELS(clientId, eventId), { ids: labelIds }, true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function removeLabelFromEvent(clientId: string, eventId: string, labelIds: Array<string>): Promise<void> {
  return HttpUtils.httpDelete(API_URLS.CRM_LABELS.EVENT_LABELS(clientId, eventId), { data: { ids: labelIds } }, true)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}
