import { createAction } from 'redux-actions';
import { normalize } from 'normalizr';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { ProjectService, Schemas } from '@premagic/core';
import { LoadingDuck, ErrorDuck } from '@premagic/common-ducks';

import { LOADINGS } from '../../common/Constants';
import { fetchAlbum } from './services/AlbumServices';
import { setActiveAlbum } from './AlbumCreatorDuck';
import { fetchImageForAlbumCreator } from './AlbumImagesDataDuck';
import { setAlbumPagesData } from './page/AlbumPageDataDuck';
import { setAlbumData } from './album/AlbumDataDuck';
import { getProject, setProjectData } from './project/AlbumProjectDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM_PAGE', true);

export const initAlbumCreator = createAction(
  getActionType('INIT_DATA', 'GET'),
  async (dispatch, albumId, view: 'preview' | 'edit') => {
    const loadingKey = LOADINGS.ALBUM.LOADINGS;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    try {
      const albumData = await fetchAlbum(albumId);

      await dispatch(fetchImageForAlbumCreator(dispatch, albumData.project_id));
      const normalizedData = normalize(albumData, Schemas.AlbumSchema);

      dispatch(setActiveAlbum(dispatch, albumId));
      if (view === 'edit') {
        dispatch(getProject(dispatch, albumData.project_id));
      }

      dispatch(setAlbumData(dispatch, normalizedData.entities.albums));
      dispatch(setAlbumPagesData(dispatch, normalizedData.entities.pages));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('ALBUM', e);
    }
  },
);
