import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Dialog,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  ICON_SIZES,
  Icon,
  Input,
  Space,
} from '@premagic/myne';
import { useRef, useState } from 'react';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  totalFolder: number;
  projectId: string;
  hasExternalUploadFeature: boolean;
  folderLimit: number;
  isLoading: boolean;
  errors?: FormErrorType;
  importFromService: (projectId: string, FormResponseType) => void;
};

export default function ImportPhotosToSignatureAlbumButton(props: Props): JSX.Element | null {
  const { totalFolder, projectId, hasExternalUploadFeature, folderLimit, isLoading, errors, importFromService } = props;
  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);

  return (
    <>
      <Button
        active={showDialog}
        style={BUTTON_STYLES.LINK}
        size={BUTTON_SIZE.SM}
        ref={target}
        disabled={totalFolder >= folderLimit}
        disabledMessage={MESSAGES.SIGNATURE_FOLDER.GATE_FOLDER}
        onClick={() => setShowDialog(!showDialog)}
      >
        <Icon name="upload_cloud" size={ICON_SIZES.SM} />
        <Space size={1} /> Import
      </Button>
      <Dialog target={target.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Form
          onSubmit={(response) => {
            importFromService(projectId, response);
            setShowDialog(false);
          }}
          errors={errors}
        >
          <FormGroup>
            <FormLabel>Import from google drive</FormLabel>
            <Input
              name="url"
              required
              autoFocus
              placeholder="https://drive.google.com/drive/folders/uniquelink?usp=sharing"
            />
          </FormGroup>
          <FormFooter>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!hasExternalUploadFeature}
              disabledMessage={MESSAGES.EXTERNAL_UPLOADER.COMING_SOON}
            >
              Import
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
