// Mountain names
import { MathUtils } from '@premagic/utils';

export enum COLOUR_PALETTES_IDS {
  FUJI = 1,
  KILIMANJARO = 2,
  K2 = 3,
  DENALI = 4,
  BLANC = 5,
  // KIRKJUFELL,
  // FITZ_ROY,
  // ANNAPURNA,
  // OLYMPUS,
  // HUA,
  // AORAKI,
  // ELBRUS,
  // POPOCATÉPETL,
  // MATTERHORN,
  // EL_CAPITAN,
  // GRAND_TETON,
  // LICANCABUR,
  // TRE_CIME_DI_LAVAREDO,
  // BEN_NEVIS,
  // MOUNT_RAINIER,
  // TABLE_MOUNTAIN,
}

export type ColorPaletteType = {
  primary: string;
  secondary: string;
  base: string;
  tertiary: string;
};

export const COLOUR_PALETTES: Record<COLOUR_PALETTES_IDS, ColorPaletteType> = {
  [COLOUR_PALETTES_IDS.FUJI]: {
    primary: '#8E7FDD',
    secondary: '#ECE6FF',
    base: '#FFFFFF',
    tertiary: '#8E7FDD',
  },

  [COLOUR_PALETTES_IDS.KILIMANJARO]: {
    primary: '#5E94C9',
    secondary: '#DBF3FF',
    base: '#FFFFFF',
    tertiary: '#5E94C9',
  },
  [COLOUR_PALETTES_IDS.K2]: {
    primary: '#46A8B1',
    secondary: '#DBFFF0',
    base: '#FFFFFF',
    tertiary: '#46A8B1',
  },
  [COLOUR_PALETTES_IDS.DENALI]: {
    primary: '#9F6800',
    secondary: '#FFF4DB',
    base: '#FFFFFF',
    tertiary: '#9F6800',
  },
  [COLOUR_PALETTES_IDS.BLANC]: {
    primary: '#C472AE',
    secondary: '#EFD5E6',
    base: '#FFFFFF',
    tertiary: '#C472AE',
  },
};

export function getRandomColorPaletteId(): COLOUR_PALETTES_IDS {
  const totalItems = Object.keys(COLOUR_PALETTES).length;
  const randomId = MathUtils.getRandomNumber(1, totalItems);
  return randomId;
}
