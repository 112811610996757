import { connect } from 'react-redux';
import SponsorDeleteDialog from './SponsorDeleteDialog';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { removeEventSponsor } from '../EventSponsorDataDuck';

function mapStateToProps(state, ownProps: { sponsorId: string; projectId: string }) {
  const { sponsorId, projectId } = ownProps;

  return {
    sponsorId,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.EVENT_SPONSOR.DELETE)(state),
    isDialogShown: isModalOpenSelector(LOADINGS.EVENT_SPONSOR.SHOW_DELETE_DIALOG(sponsorId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (id: string, show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_DELETE_DIALOG(id), show));
    },
    deleteSponsor: (projectId, sponsorId) => {
      dispatch(removeEventSponsor(dispatch, projectId, sponsorId));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_DELETE_DIALOG(sponsorId), false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorDeleteDialog);
