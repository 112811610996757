import React, { useEffect } from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
} from '@premagic/myne';
import { SponsorCreativesService } from '@premagic/core';
import SponsorCreativeFormContainer from './SponsorCreativeFormContainer';

type Props = {
  addSponsorCreative: (projectId: string, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
  modalOptions: {
    placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  };
};

export default function AddSponsorCreativePanel(props: Props) {
  const { show, close, projectId, addSponsorCreative, isLoading, error, modalOptions } = props;

  if (!show) return null;

  const placementDetails = SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT_DETAILS[modalOptions?.placement];

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add {placementDetails.label} Creative</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={error}
          onSubmit={(response) => addSponsorCreative(projectId, response)}
          formSpec={{
            sponsor_id: {
              validator: 'required',
            },
          }}
        >
          <SponsorCreativeFormContainer projectId={projectId} placement={modalOptions?.placement} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
