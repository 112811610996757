import { ProjectMetaService } from '@premagic/core';
import { Col, INPUT_SIZES, Select, Space, SubTitle } from '@premagic/myne';

type HighlightDesignGalleryThumbnailSizeSelectorProps = {
  onChange: (value) => void;
  galleryThumbnailSize: ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
  isThumbnailSizeSelectorEnabled: boolean;
};

export default function HighlightDesignGalleryThumbnailSizeSelector(
  props: HighlightDesignGalleryThumbnailSizeSelectorProps,
) {
  const { onChange, galleryThumbnailSize, isThumbnailSizeSelectorEnabled } = props;
  const options = Object.values(ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE_DETAILS);
  const selectedOption = options.find(
    (option) =>
      option.value === (galleryThumbnailSize || ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT),
  );

  return (
    <>
      <SubTitle>Gallery Thumbnail Size</SubTitle>
      <Space vertical size={2} />
      <Col size={null}>
        <Select
          size={INPUT_SIZES.SM}
          disabled={!isThumbnailSizeSelectorEnabled}
          options={options}
          value={selectedOption}
          name="gallery_thumbnail_size"
          isMulti={false}
          onChange={(option) => {
            if (option) {
              onChange(option.value as string);
            }
          }}
        />
      </Col>
    </>
  );
}
