import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { commonUISelector } from '../src/app/reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction(
  'WINDOW_PANEL',
  true
);

export const toggleWindowPanelVisibility = createAction(
  getActionType('TYPE', 'SHOW'),
  (dispatch: Dispatch, type: string, show: boolean) => ({
    [type]: show,
  })
);

const initialState = {
  items: IMap({}),
};
export default handleActions(
  {
    [toggleWindowPanelVisibility.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState
);

export const windowPanelsSelector = createSelector(
  commonUISelector,
  (state: any) => state.windowPanel.items
);

export const isWindowPanelOpenSelector = (type: string) =>
  createSelector(windowPanelsSelector, (modals: any) => !!modals.get(type));
