import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  INFO_MESSAGE_STYLE,
  InfoMessage,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  FormErrorType,
  Space,
} from '@premagic/myne';
import { ClientsService, EventsService } from '@premagic/core';

import UpdateButton from '../../../settings/UpgradeButton';
import MESSAGES from '../../../../../../common/Messages';
import EventProposalFormContainer from './EventProposalFormContainer';

type Props = {
  projectId?: string;
  addProposal: (projectId: string, data: FormResponseType) => void;
  addProposalWithProject: (
    options: { projectName: string; eventId: string; eventName: string },
    data: FormResponseType,
  ) => void;
  show: boolean;
  isLoading: boolean;
  errors?: FormErrorType;
  closePanel: () => void;
  hasProposalFeature: boolean;
  event: EventsService.EventType;
  client?: ClientsService.ClientType;
};

export default function AddEventProposalPanel(props: Props): JSX.Element | null {
  const {
    show,
    addProposal,
    isLoading,
    errors,
    closePanel,
    projectId,
    hasProposalFeature,
    client,
    addProposalWithProject,
    event,
  } = props;
  if (!show) return null;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Proposal</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        {!hasProposalFeature && (
          <div>
            <InfoMessage iconName="info" style={INFO_MESSAGE_STYLE.INFO}>
              {MESSAGES.PROPOSAL.UPGRADE} <UpdateButton />
            </InfoMessage>
            <Space vertical />
          </div>
        )}

        <Form
          errors={errors}
          onSubmit={(data) =>
            projectId
              ? addProposal(projectId, data)
              : addProposalWithProject(
                  {
                    projectName: client?.name || 'Project',
                    eventId: event.id,
                    eventName: event.name,
                  },
                  data,
                )
          }
        >
          <EventProposalFormContainer />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
