import React from 'react';
import { GridLayout, GridLayoutItem } from '@premagic/myne';
import AlbumCreatorImageContainer from '../page/AlbumCreatorImageContainer';

export function Template1(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={6} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template2(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}
export function Template3(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={12} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template4(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={6} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template5(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={6} height={6}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={12}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template6(props): JSX.Element {
  return (
    <GridLayout>
      {Array.from(Array(4).keys()).map((item) => (
        <GridLayoutItem width={6} height={6} key={item}>
          <AlbumCreatorImageContainer position={item + 1} {...props} />
        </GridLayoutItem>
      ))}
    </GridLayout>
  );
}

export function Template7(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>

      <GridLayoutItem width={6} height={6}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>

      <GridLayoutItem width={6} height={8}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>

      <GridLayoutItem width={6} height={6}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template8(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={8} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template9(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={8} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template10(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={6} height={6}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={6}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={6} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template11(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={6} height={8}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      {Array.from(Array(8).keys()).map((item) => (
        <GridLayoutItem width={3} height={4} key={item}>
          <AlbumCreatorImageContainer position={item + 2} {...props} />
        </GridLayoutItem>
      ))}
    </GridLayout>
  );
}

export function Template12(props): JSX.Element {
  return (
    <GridLayout>
      {Array.from(Array(12).keys()).map((item) => (
        <GridLayoutItem width={3} height={4} key={item}>
          <AlbumCreatorImageContainer position={item + 1} {...props} />
        </GridLayoutItem>
      ))}
    </GridLayout>
  );
}

export function Template13(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={8} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template14(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={8} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template15(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template16(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>

      <GridLayoutItem width={6} height={4}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>

      <GridLayoutItem width={6} height={8}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={4}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={4}>
        <AlbumCreatorImageContainer position={6} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template17(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>

      <GridLayoutItem width={8} height={8}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={6} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template18(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={6} height={12}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={4}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={4}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={6} height={4}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template19(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template20(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={6} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={7} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={6}>
        <AlbumCreatorImageContainer position={8} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={9} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={3} height={4}>
        <AlbumCreatorImageContainer position={10} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template21(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={7} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={6} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}

export function Template22(props): JSX.Element {
  return (
    <GridLayout>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={1} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={2} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={12}>
        <AlbumCreatorImageContainer position={3} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={4} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={6}>
        <AlbumCreatorImageContainer position={5} {...props} />
      </GridLayoutItem>
      <GridLayoutItem width={4} height={4}>
        <AlbumCreatorImageContainer position={6} {...props} />
      </GridLayoutItem>
    </GridLayout>
  );
}
