import * as React from 'react';
import classNames from 'classnames';
import { COLOR_SHADES } from '../Color/Color';
import { Col, Position, Row } from '../Grid/Grid';
import { Icon, ICON_SIZES } from '../Icon/Icons';
import { LoadingUpload } from '../Loading/Loading';
import { Text } from '../Text/Text';

import styles from './alert.module.css';
import { Tooltip } from '../Tooltip/Tooltip';
import { ButtonIcon } from '../Button/Buttons';

export enum INFO_MESSAGE_STYLE {
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

const INFO_MESSAGE_ICON_COLOR = {
  [INFO_MESSAGE_STYLE.INFO]: COLOR_SHADES.BLUE,
  [INFO_MESSAGE_STYLE.DANGER]: COLOR_SHADES.DANGER,
  [INFO_MESSAGE_STYLE.WARNING]: COLOR_SHADES.WARNING,
  [INFO_MESSAGE_STYLE.SUCCESS]: COLOR_SHADES.SUCCESS,
};

interface InfoMessageProps {
  children: React.ReactNode;
  iconName: string;
  style: INFO_MESSAGE_STYLE;
}

export function InfoMessage(props: InfoMessageProps): React.ReactElement {
  const { children, iconName, style } = props;
  const color = INFO_MESSAGE_ICON_COLOR[style];

  return (
    <div className={classNames(styles['info-message'], styles[`info-message--${style}`])}>
      <Row>
        <Col size={null}>
          <div className={classNames(styles['info-message__icon'])}>
            <Text color={color}>
              {iconName === 'upload_cloud' ? <LoadingUpload /> : <Icon name={iconName} size={ICON_SIZES.SM} />}
            </Text>
          </div>
        </Col>
        <Col size={null}>
          <Text>{children}</Text>
        </Col>
      </Row>
    </div>
  );
}

export enum ALERT_SIZES {
  SM = 'sm',
  MD = 'md',
  AUTO = 'auto',
}

export enum ALERT_STYLES {
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

interface AlertProps {
  children: React.ReactNode;
  style: ALERT_STYLES;
  size?: ALERT_SIZES;
  inline?: boolean;
}

export function Alert(props: AlertProps): React.ReactElement {
  const { children, style, size = ALERT_SIZES.MD, inline } = props;

  return (
    <div
      className={classNames(styles.alert, styles[`alert--style-${style}`], styles[`alert--size-${size}`], {
        [styles['alert--inline']]: inline,
      })}
    >
      {children}
    </div>
  );
}

export enum NOTIFICATION_DOT_STYLES {
  LIST = 'list',
  DEFAULT = 'default',
}

type NotificationDotProps = {
  style: NOTIFICATION_DOT_STYLES;
};

export function NotificationDot(props: NotificationDotProps): React.ReactElement {
  const { style = NOTIFICATION_DOT_STYLES.LIST } = props;
  return (
    <Tooltip message="Actions pending">
      <div className={classNames(styles['notification-dot'], styles[`notification-dot--style-${style}`])} />
    </Tooltip>
  );
}
export function PageAlerts(props: { children: React.ReactNode }): React.ReactElement {
  const { children } = props;

  return <div className={classNames(styles['page-alerts'], 'hidden-print')}>{children}</div>;
}
export enum PAGE_ALERT_STYLES {
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

interface PageAlertProps {
  children: React.ReactNode;
  style: PAGE_ALERT_STYLES;
  onClose?: () => void;
}

export function PageAlert(props: PageAlertProps): React.ReactElement {
  const { children, style, onClose } = props;

  return (
    <div className={classNames(styles['page-alert'], styles[`page-alert--style-${style}`])}>
      {children}

      {onClose && (
        <Position align="top-right">
          <ButtonIcon title="close" onClick={onClose}>
            <Icon name="x" />
          </ButtonIcon>
        </Position>
      )}
    </div>
  );
}
