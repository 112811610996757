import { HightlightAlbumThemeService } from '@premagic/core';
import { connect } from 'react-redux';
import HighlightDesignFontColorSelector from './HighlightDesignFontColorSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (color: string) => void;
    disabled: boolean;
    defaultFontColor?: string;
    backgroundColor?: string;
  },
) {
  const { onChange, disabled, defaultFontColor, backgroundColor } = ownProps;
  return { onChange, disabled, defaultFontColor, backgroundColor };
}

function mapDispatchToProps() {
  return {
    initFontColor: (fontColor: string) => {
      HightlightAlbumThemeService.updateThemeVariable({
        color: {
          font_color: fontColor,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignFontColorSelector);
