import { connect } from 'react-redux';
import {
  ProposalVariablesDataDuck,
  ProposalSlideDataDuck,
  ActionConstants,
  ProposalVariableService,
} from '@premagic/proposals';
import { ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';

import { activeProposalSlideSelector } from '../ProposalCreatorDuck';
import ProposalCreatorEquipmentsUpdater from './ProposalCreatorEquipmentsUpdater';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    deckId: string;
  },
) {
  const { projectId, deckId } = ownProps;
  const slideDetails = activeProposalSlideSelector(state);
  return {
    projectId,
    deckId,
    slideDetails,
    equipmentVariables: ProposalVariablesDataDuck.proposalsVariableEquipmentItemsSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProposalSlide: (options, data) => dispatch(ProposalSlideDataDuck.saveProposalSlide(dispatch, options, data)),
    showAddNewEquipmentVariable: () => {
      dispatch(
        ModalDuck.setModalOptions(
          dispatch,
          ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL,
          ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.CAMERA_DETAILS,
        ),
      );
      dispatch(
        WindowPanelDuck.toggleWindowPanelVisibility(
          dispatch,
          ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL,
          true,
        ),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorEquipmentsUpdater);
