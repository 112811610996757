import { ClientSettingsService, EventsCategoryService, EventsService, IntegrationService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Divider,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  Row,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { CountryCodeUtils } from '@premagic/utils';
import { useEffect, useMemo, useState } from 'react';
import MESSAGES from '../../../../../common/Messages';
import EventCategoryPicker from '../events/category/EventCategoryPicker';
import CRMEventDataForm from '../events/form/CRMEventDataForm';
import { getDefaultCRMEventListUrlWithFilters } from '../filters/CRMFilterService';
import UserNamesContainer from '../users/list/UserNamesContainer';
import { UserType } from '../users/UsersService';
import CRMClientFormContainer from './CRMClientFormContainer';
import IntegrationImportEventButtonContainer from '../../integration-third-party-platforms/integration-button/IntegrationImportEventButtonContainer';

type EventNameProps = {
  eventCategory?: EventsService.EVENT_CATEGORY | string;
};
function EventName(props: EventNameProps) {
  const { eventCategory } = props;

  if (EventsCategoryService.isWeddingEvent(eventCategory)) {
    return (
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired>Groom Name</FormLabel>
            <Input name="project[name][0]" placeholder="eg. John" required autoFocus />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel>Bride Name</FormLabel>
            <Input name="project[name][1]" placeholder="eg. Angelina" />
          </FormGroup>
        </Col>
      </Row>
    );
  }
  return (
    <FormGroup>
      <FormLabel isRequired>Event Name</FormLabel>
      <Input
        name="project[name]"
        placeholder={`${
          eventCategory === EventsService.EVENT_CATEGORY.CORPORATE ? 'eg. Tomorrowland' : 'eg. Tomorrowland'
        }`}
        required
        size={INPUT_SIZES.MD}
        autoFocus
      />
    </FormGroup>
  );
}
type HostSectionProps = {
  isCompanyTypeNotPhotographer: boolean;
  requestingUser?: UserType;
  showAddress: boolean;
  errors?: Record<string, string>;
};

function HostSection(props: HostSectionProps) {
  const { isCompanyTypeNotPhotographer, requestingUser, showAddress, errors } = props;

  const [isChangingHost, setIsChangingHost] = useState(false);
  useEffect(() => {
    setIsChangingHost(!!errors?.phone_number);
  }, [errors?.phone_number]);

  if (isCompanyTypeNotPhotographer && requestingUser) {
    if (isChangingHost) {
      return (
        <div>
          <Row>
            <Col size={null} rightAlighed>
              <Button style={BUTTON_STYLES.LINK} onClick={() => setIsChangingHost(false)} size={BUTTON_SIZE.SM}>
                Cancel
              </Button>
            </Col>
          </Row>
          <CRMClientFormContainer
            showLeadSourcePicker
            showAddress={showAddress}
            client={{
              phone_number: requestingUser.phone_number,
              name: requestingUser.name,
              email: requestingUser.email || '',
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <Row vcenter>
          <UserNamesContainer userIds={[requestingUser.id]} /> <Space size={2} />
          <Text boldness={TEXT_BOLDNESS.BOLD}>{requestingUser.name}</Text>
          <Space />
          <Button style={BUTTON_STYLES.LINK} onClick={() => setIsChangingHost(true)} size={BUTTON_SIZE.SM}>
            <Icon name="edit_2" size={ICON_SIZES.SM_XS} />
            <Space size={1} />
            Change
          </Button>
          <input name="name" value={requestingUser.name} type="hidden" />
          <input name="email" value={requestingUser.email} type="hidden" />
          <input name="phone_number" value={requestingUser.phone_number} type="hidden" />
        </Row>
        {errors?.phone_number && <Text color={COLOR_SHADES.DANGER}>{errors.phone_number}</Text>}
      </div>
    );
  }
  return <CRMClientFormContainer showLeadSourcePicker showAddress={showAddress} />;
}

type IntegrationsEventCreateOptionSelectorProps = {
  createEventManually: () => void;
  integrationsClientSettings: Partial<
    Record<
      IntegrationService.INTEGRATION_PLATFORMS,
      {
        enabled: boolean;
      }
    >
  >;
};

function IntegrationsEventCreateOptionSelector(props: IntegrationsEventCreateOptionSelectorProps) {
  const { createEventManually, integrationsClientSettings } = props;

  return (
    <Card>
      <Space>
        <Row center>
          <Col size={6}>
            {Object.values(IntegrationService.INTEGRATION_PLATFORMS)
              .filter((platform) => integrationsClientSettings[platform]?.enabled)
              .map((platform) => (
                <div key={platform}>
                  <IntegrationImportEventButtonContainer platform={platform} />
                  <Space vertical />
                </div>
              ))}
          </Col>
        </Row>
        <Divider />
        <Space vertical />
        <Row center>
          <Col size={6}>
            <Button style={BUTTON_STYLES.TERTIARY} size={BUTTON_SIZE.LG} onClick={createEventManually} block>
              Create manually
            </Button>
          </Col>
        </Row>
      </Space>
    </Card>
  );
}

type Props = {
  addEventWithClientData: (data: FormResponseType, isCompanyTypeNotPhotographer?: boolean) => void;
  isLoading: boolean;
  errors?: Record<string, string>;
  browserBack: (defaultUrl: string) => void;
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
  isCompanyTypeNotPhotographer: boolean;
  isFeatureLeadEnabled: boolean;
  requestingUser?: UserType;
  hasExceedProjectLimit: boolean;
  filterObject?: {
    event_status: EventsService.EVENT_STATUS;
  };
  integrationsClientSettings: Partial<
    Record<
      IntegrationService.INTEGRATION_PLATFORMS,
      {
        enabled: boolean;
      }
    >
  >;
};

export default function CreateEventWithClientPage(props: Props): JSX.Element {
  const {
    addEventWithClientData,
    isLoading,
    errors,
    browserBack,
    companyType,
    isCompanyTypeNotPhotographer,
    isFeatureLeadEnabled,
    requestingUser,
    hasExceedProjectLimit,
    filterObject,
    integrationsClientSettings,
  } = props;
  const [selectedEventStatus, setSelectedEventStatus] = useState<EventsService.EVENT_STATUS>(
    filterObject?.event_status ? filterObject.event_status : EventsService.EVENT_STATUS.CONFIRMED,
  );
  const [eventCategory, setEventCategory] = useState<EventsService.EVENT_CATEGORY | string>();
  const [showAddress, setShowAddress] = useState(false);

  const isIntegrationPlatformEnabled = useMemo(
    () => Object.values(integrationsClientSettings).some((item) => item.enabled),
    [integrationsClientSettings],
  );
  const [showIntegrationEventCreateOptions, setShowIntegrationEventCreateOptions] =
    useState(isIntegrationPlatformEnabled);

  if (showIntegrationEventCreateOptions) {
    return (
      <IntegrationsEventCreateOptionSelector
        integrationsClientSettings={integrationsClientSettings}
        createEventManually={() => setShowIntegrationEventCreateOptions(false)}
      />
    );
  }

  return (
    <div>
      <Form
        onSubmit={(data) => addEventWithClientData(data, isCompanyTypeNotPhotographer)}
        errors={errors}
        formSpec={{
          phone_number: {
            validator: (value) => CountryCodeUtils.isValidPhoneNumber(value),
            message: (value) => {
              if (!CountryCodeUtils.isValidPhoneNumber(value)) return 'Enter a valid number';
              return 'This is Required';
            },
          },
        }}
      >
        <Card>
          <Space>
            <Row vcenter>
              <Icon name="file_text" size={ICON_SIZES.SM} />
              <Space size={1} />
              <SubTitle>Event Information</SubTitle>
            </Row>
            <Space vertical />
            <Row>
              <div>
                <Color shade={COLOR_SHADES.LIGHT} inline>
                  <Icon name="calendar" size={ICON_SIZES.MD_SM} />
                </Color>
                <Space />
              </div>

              <Col size={8}>
                <>
                  {!isCompanyTypeNotPhotographer && (
                    <EventCategoryPicker companyType={companyType} onChange={setEventCategory} />
                  )}
                  <EventName eventCategory={eventCategory} />
                </>
              </Col>
            </Row>

            <CRMEventDataForm
              isFeatureLeadEnabled={isFeatureLeadEnabled}
              selectedEventStatus={selectedEventStatus}
              setSelectedEventStatus={setSelectedEventStatus}
              isCompanyTypeNotPhotographer={isCompanyTypeNotPhotographer}
            />
            <Space vertical />
            <Space vertical size={8} />
            <Row vcenter>
              <Icon name="smile" size={ICON_SIZES.SM} />
              <Space size={1} />
              <SubTitle>Host</SubTitle>
            </Row>
            <Space vertical />
            <HostSection
              isCompanyTypeNotPhotographer={isCompanyTypeNotPhotographer}
              requestingUser={requestingUser}
              showAddress={showAddress}
              errors={errors}
            />
            {!showAddress && !isCompanyTypeNotPhotographer && (
              <Button style={BUTTON_STYLES.LINK} onClick={() => setShowAddress(true)} size={BUTTON_SIZE.SM}>
                <Icon name="plus" size={ICON_SIZES.SM} /> host address
              </Button>
            )}
          </Space>
        </Card>
        <FormFooter>
          <Button
            style={BUTTON_STYLES.CANCEL}
            onClick={() => browserBack(getDefaultCRMEventListUrlWithFilters({ isFeatureLeadEnabled }))}
          >
            Cancel
          </Button>
          <Button
            style={BUTTON_STYLES.PRIMARY}
            isLoading={isLoading}
            disabled={selectedEventStatus === EventsService.EVENT_STATUS.CONFIRMED && hasExceedProjectLimit}
            disabledMessage={MESSAGES.PROJECT.UPGRADE}
          >
            Save
          </Button>
        </FormFooter>
      </Form>
    </div>
  );
}
