import { connect } from 'react-redux';
import { ProjectOrdersService } from '@premagic/core';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWishesIdsForWebsiteFolderSelector, getClientWishesForWebsite } from './ClientWishDataDuck';
import ClientWishesList from './ClientWishesList';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { activeOrderIdsForProjectAndFolderSelector } from '../../project-orders/ProjectOrdersDataDuck';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';

function mapStateToProps(state, ownProps) {
  const { folderId, projectId } = ownProps;
  const clientWishesIds = clientWishesIdsForWebsiteFolderSelector(folderId)(state);
  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WISH.FETCH)(state),
    folderId,
    projectId,
    clientWishesIds,
    order: activeOrderIdsForProjectAndFolderSelector(
      projectId,
      folderId,
      ProjectOrdersService.ORDER_NAMES.DIGITAL_INVITE,
    )(state),
    isStaffUser: isStaffUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (options: { folderId: string; projectId: string }) => {
      dispatch(getClientWishesForWebsite(dispatch, options));
    },
    showAddWish: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WISH.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWishesList);
