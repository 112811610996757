import { Dispatch } from 'redux';
import { Map as IMap } from 'immutable';
import { flatten, groupBy, uniq } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils, BrowserUrlUtils } from '@premagic/utils';
import { LoadingDuck, ErrorDuck, WindowPanelDuck, ModalDuck } from '@premagic/common-ducks';
import { ProjectService, RouterService, Schemas } from '@premagic/core';

import { KEYS } from '../common/ActionConstants';
import { proposalPageSelector } from '../common/selectors';
import {
  createProposalSlide,
  deleteProposalSlide,
  getVariablesInTheSlide,
  ProposalSlideType,
  updateProposalSlide,
} from './ProposalSlideService';
import { PROPOSALS_URLS } from '../services/ProposalRouteURLService';
import { proposalVariablesSelectors } from '../proposal-variables/ProposalVariablesDataDuck';
import { PROPOSAL_VARIABLE_SCOPE } from '../proposal-variables/ProposalVariableService';
import {
  addProposalDeckSlideData,
  proposalDecksSelectors,
  removeProposalDeckSlideData,
} from '../proposal-deck/ProposalDeckDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROPOSAL_SLIDE');

export const setProposalSlidesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addProposalSlideData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateProposalSlideData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, id, data) => ({ id, data }));
const removeProposalSlideData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const addProposalSlide = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch: Dispatch, params: { projectId: string; folderId: string; deckId: string }, data) => {
    const loadingKey = KEYS.PROPOSAL_SLIDE.CREATE;
    const { projectId, folderId, deckId } = params;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    createProposalSlide(projectId, deckId, data)
      .then((response) => {
        dispatch(addProposalDeckSlideData(dispatch, deckId, response.id));
        const normalizedData = normalize(response, Schemas.ProposalSlideSchema);
        dispatch(addProposalSlideData(dispatch, normalizedData.entities.slides));
        // Navigate to new slide
        const slideUrl =
          projectId === ProjectService.SystemProject.id
            ? BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS_TEMPLATES.DETAILS, {
                folderId,
                deckId,
                slideId: response.id,
              })
            : BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS.DETAILS, {
                projectId,
                folderId,
                deckId,
                slideId: response.id,
              });

        RouterService.navigateTo(dispatch, slideUrl);

        dispatch(ModalDuck.toggleModalVisibility(dispatch, KEYS.PROPOSAL_SLIDE.SHOW_ADD_DIALOG, false));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalSlide: add new failed', e);
      });
  },
);

export const saveProposalSlide = createAction(
  getActionType('ACTION', 'UPDATE'),
  (dispatch, options: { projectId: string; deckId: string; id: number }, data: Partial<ProposalSlideType>) => {
    const { id } = options;
    const loadingKey = KEYS.PROPOSAL_SLIDE.UPDATE(id);
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    dispatch(updateProposalSlideData(dispatch, id, data));
    updateProposalSlide(options, data)
      .then((response) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalSlide: save failed', e);
      });
  },
);

export const removeProposalSlide = createAction(
  getActionType('ACTION', 'REMOVE'),
  (
    dispatch: Dispatch,
    options: { projectId: string; folderId: string; deckId: string; id: string; navigateToNextSlideId?: string },
  ) => {
    const loadingKey = KEYS.PROPOSAL_SLIDE.DELETE;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    const { projectId, folderId, deckId, id, navigateToNextSlideId } = options;
    dispatch(removeProposalDeckSlideData(dispatch, deckId, id));
    deleteProposalSlide(options)
      .then((response) => {
        dispatch(removeProposalSlideData(dispatch, id));
        // Navigate to next slide
        if (navigateToNextSlideId) {
          const slideUrl =
            projectId === ProjectService.SystemProject.id
              ? BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS_TEMPLATES.DETAILS, {
                  folderId,
                  deckId,
                  slideId: navigateToNextSlideId,
                })
              : BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS.DETAILS, {
                  projectId,
                  folderId,
                  deckId,
                  slideId: navigateToNextSlideId,
                });

          RouterService.navigateTo(dispatch, slideUrl);
        }

        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalSlide: delete failed', e);
      });
  },
);

type StateType = {
  items: IMap<string, ProposalSlideType>;
};
const initialState = {
  items: IMap({}),
};

export const ProposalSlideDataReducer = handleActions(
  {
    [setProposalSlidesData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addProposalSlideData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateProposalSlideData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(String(action.payload.id), (data) => ({
        ...data,
        ...action.payload.data,
      })),
    }),
    [removeProposalSlideData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.remove(String(action.payload)),
    }),
  },
  initialState,
);

const proposalSlidesDataSelector = createSelector(proposalPageSelector, (state) => state.data.slides as StateType);
export const proposalSlidesSelectors = createSelector(proposalSlidesDataSelector, (state) => state.items);
export const proposalsSlideItemsSelectors = createSelector(proposalSlidesSelectors, (items) => items.toJSON());

export const proposalsSlidesArraySelectors = createSelector(proposalSlidesSelectors, (items) =>
  items.valueSeq().toArray(),
);

export const proposalsVariablesInSlideSelectors = (slideId: number) =>
  createSelector(proposalSlidesSelectors, (slides) => {
    const slide = slides.get(String(slideId));
    if (!slide) return [];
    const variables = getVariablesInTheSlide(slide);
    return uniq(variables);
  });

export const unsetProposalVariablesInSlideSelector = (deckId: string, slideId: number) =>
  createSelector(proposalDecksSelectors, proposalSlidesSelectors, (decks, slides) => {
    const deckVariables = decks.get(deckId)?.variables?.[slideId] || {};
    const slide = slides.get(String(slideId));
    if (!slide) return [];
    const variables = uniq(getVariablesInTheSlide(slide));
    return variables.filter((variable) => !deckVariables[variable]);
  });

export const proposalsVariablesInAllSlidesSelectors = createSelector(proposalSlidesSelectors, (items) => {
  const variables = items
    .map((item) => getVariablesInTheSlide(item))
    .valueSeq()
    .toArray();
  return uniq(flatten(variables));
});

export const groupedProposalsVariablesInAllSlidesSelectors = createSelector(
  proposalsVariablesInAllSlidesSelectors,
  proposalVariablesSelectors,
  (variableIds: Array<string>, items) =>
    groupBy(variableIds, (item) => items.get(item)?.scope) as Record<PROPOSAL_VARIABLE_SCOPE, Array<string>>,
);
