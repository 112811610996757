import { connect } from 'react-redux';
import { crmEventItemsSelector } from '../EventsDataDuck';
import EventSidebarSummary from './EventSidebarSummary';

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;
  const event = crmEventItemsSelector(state)[eventId] || {};

  return {
    event,
  };
}

export default connect(mapStateToProps)(EventSidebarSummary);
