import React, { useRef } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Color,
  COLOR_SHADES,
  DECK_SLIDE_LAYOUTS_DETAILS,
  Dialog,
  DIALOG_PLACEMENT,
  DialogTitle,
  Grid,
  Icon,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { ProposalDeckService, ProposalSlideService } from '@premagic/proposals';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  isDialogShown: boolean;
  isAdding: boolean;
  toggleAddDialog: (show: boolean) => void;
  addSlide: (
    params: { projectId: string; folderId: string; deckId: string },
    data: Partial<ProposalSlideService.ProposalSlideType>,
  ) => void;
  deck?: ProposalDeckService.ProposalDeckType;
};

export default function ProposalSlideAddButton(props: Props): JSX.Element {
  const { toggleAddDialog, isDialogShown, projectId, folderId, deckId, addSlide, isAdding, deck } = props;
  const isDeckPublished = deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;

  const $button = useRef<HTMLButtonElement>(null);
  return (
    <Space>
      <Button
        onClick={() => toggleAddDialog(!isDialogShown)}
        ref={$button}
        style={BUTTON_STYLES.RESET}
        isLoading={isAdding}
        block
        disabled={isDeckPublished}
        disabledMessage="Proposal is shared with host, Edit the proposal to add slides"
      >
        <Card size={CARD_SIZES.SM} style={CARD_STYLES.SECONDARY}>
          <Space>
            <Row columnDirection center>
              <Space vertical size={2} />
              <Color shade={COLOR_SHADES.BLUE}>
                <Icon name="plus" />
              </Color>
              <Space vertical size={1} />
              <Text block center color={COLOR_SHADES.BLUE} boldness={TEXT_BOLDNESS.BOLD}>
                SLIDE
              </Text>
            </Row>
          </Space>
        </Card>
      </Button>
      <Dialog
        target={$button.current}
        placement={DIALOG_PLACEMENT.AUTO}
        show={isDialogShown}
        onClose={() => toggleAddDialog(false)}
      >
        <DialogTitle>Slide Layouts</DialogTitle>
        <Grid colSize={320}>
          {Object.entries(DECK_SLIDE_LAYOUTS_DETAILS)
            .filter(([, item]) => !item.deprecated)
            .map(([key, item]) => (
              <div key={item.id}>
                <Button
                  onClick={() => {
                    toggleAddDialog(false);
                    addSlide(
                      { projectId, folderId, deckId },
                      {
                        template_id: item.id,
                        structure: item.defaultStructure,
                      },
                    );
                  }}
                >
                  {item.name}
                </Button>
              </div>
            ))}
        </Grid>
      </Dialog>
    </Space>
  );
}
