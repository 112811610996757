import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export type FileMetaType = {
  colors?: FolderFileDominantColor;
  width?: number;
  height?: number;
  ratio?: number;
  size?: number;
  product_link?: string;
  tags?: Array<string> | null;
  key_people?: Array<string> | null;
  description?: string;
};

export type SimpleFileType = {
  id: string;
  asset_type: ASSET_TYPES;
  data_key: string;
  dynamic_image_url: string;
  image_key: string;
  image_url: string;
  meta?: FileMetaType;
};

export type ClientWebsiteImageType = {
  download: string;
  key: string;
  ratio: number;
  url: string;
};

export enum IMAGE_STATUS {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
}

export enum ASSET_TYPES {
  VIDEO = 'video',
  PHOTO = 'image',
}

export enum FILE_UPLOAD_STAGES {
  QUEUED = 'QUEUED',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export const FILE_UPLOAD_STAGES_DETAILS: Record<
  FILE_UPLOAD_STAGES,
  {
    title: string;
    message: string;
    waitTime?: (assetProcessorInfo?: string) => string;
    icon: string;
    iconColor: string;
  }
> = {
  [FILE_UPLOAD_STAGES.QUEUED]: {
    title: 'Queued',
    message: 'File is queued for upload',
    waitTime: () => 'Please wait 15 minutes',
    icon: 'clock',
    iconColor: 'light',
  },
  [FILE_UPLOAD_STAGES.UPLOADING]: {
    title: 'Uploading...',
    message: 'Files is getting uploaded',
    waitTime: () => 'Please wait 15 minutes',
    icon: 'upload_cloud',
    iconColor: 'light',
  },
  [FILE_UPLOAD_STAGES.PROCESSING]: {
    title: 'Processing...',
    message: 'File is getting Processed',
    waitTime: (assetProcessorInfo = '0') => `Completed ${assetProcessorInfo}% (Please wait 15 minutes)`,
    icon: 'cpu',
    iconColor: 'blue',
  },
  [FILE_UPLOAD_STAGES.DONE]: {
    title: 'Done',
    message: 'File is uploaded and processed',
    icon: '',
    iconColor: '',
  },
  [FILE_UPLOAD_STAGES.ERROR]: {
    title: 'Error',
    message: 'File is not uploaded',
    icon: 'alert_triangle',
    waitTime: (assetProcessorInfo = '0') => `Error: ${assetProcessorInfo}`,
    iconColor: 'danger',
  },
};

type FolderFileColor = [red: number, green: number, blue: number];
export type FolderFileDominantColor = [
  FolderFileColor,
  FolderFileColor,
  FolderFileColor,
  FolderFileColor,
  FolderFileColor,
];

type FolderFileBaseType = {
  id: string; // I am not sure if we have this. | this wont come from api, but on selection-signature we add it on selector
  rank?: number; // This will come only on guest gallery people details
  image_id: string;
  created_at: number;
  asset_type: ASSET_TYPES;
  asset_upload_status?: FILE_UPLOAD_STAGES;
  asset_processor_info?: string; // This will
  favorite?: boolean;
  image_key: string;
  image_name: string;
  location: string;
  // thumbnailLocation?: string; // This will present only on desktop uploader
  thumbnail_dynamic_image_url: string;
  status: IMAGE_STATUS;
  dynamic_image_url: string;
  meta?: FileMetaType;
  folder_id?: string; // This will come only on guest gallery people details
  share_code?: string; // This will come only on guest gallery people details
  url?: string; // external video link
  label?: 'Sponsored'; // This is added from injectSponsoredCreatives
  stacked_asset_ids?: Array<string>; // This is for AI group similar photos
};

type FolderFileImageType = FolderFileBaseType & {
  asset_type: ASSET_TYPES.PHOTO;
};

type FolderFileVideoType = FolderFileBaseType & {
  asset_type: ASSET_TYPES.VIDEO;
  fallback_url: string;
  thumbnail_meta: {
    height: number;
    width: number;
    totalHeight: number;
    steps: number;
    ratio: number;
  };
};

export type FolderFileType = FolderFileImageType | FolderFileVideoType;

export type PortfolioFileType = FolderFileType & {
  folder_id: string;
  image_id: string;
  likes: number;
  project_id: string;
  score: number;
  share_code: string;
  share_url: string;
  shares: number;
  thumbnailLocation: string;
  uploader: string;
};

export function deleteFileInFolder(projectId: string, folderId: string, fileId: string) {
  const URL = API_URLS.FILES.DELETE(projectId, folderId);
  return HttpUtils.post(URL, {
    image_id_list: [fileId],
  });
}

export function deleteFilesInFolder(projectId: string, folderId: string, filesId: Array<string>) {
  const URL = API_URLS.FILES.DELETE(projectId, folderId);
  return HttpUtils.post(URL, {
    image_id_list: filesId,
  });
}

export function updateFileInFolder(projectId: string, folderId: string, data) {
  const URL = API_URLS.FILES.UPDATE(projectId, folderId);
  return HttpUtils.patch(URL, data, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function updateFileMeta(projectId: string, folderId: string, fileId: string, data: Partial<FileMetaType>) {
  const URL = API_URLS.FILES.META(projectId, folderId, fileId);
  return HttpUtils.post(URL, data, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function fetchFilesForPeopleFaceForShareId(
  shareId: string,
  faceId: string,
  queryObject?: Record<string, string>, // this is just for mevin to test with parameters
): Promise<{
  face_id: string;
  files: Record<string, FolderFileType>;
}> {
  return HttpUtils.get(
    API_URLS.FILES.FETCH_FOR_PEOPLE_FACE_PUBLIC(shareId, faceId),
    { params: queryObject },
    true,
    true,
  ).then(({ data }) => data);
}
export function fetchPublicImagesForShareId(shareId: string): Promise<Record<string, FolderFileType>> {
  return HttpUtils.get(API_URLS.FILES.PUBLIC_GALLERY_PHOTOS(shareId), true, true).then(({ data }) => data.files);
}
// export function fetchFilesForPeopleFaceForProjectId(
//   projectIds: Array<string> | string,
//   faceIds: Array<string> | string,
//   queryObject?: Record<string, string>,
// ): Promise<Record<string, FolderFileType>> {
//   const projectId = Array.isArray(projectIds) ? projectIds.join(',') : projectIds;
//   const faceId = Array.isArray(faceIds) ? faceIds.join(',') : faceIds;
//   return HttpUtils.get(API_URLS.FILES.FETCH_FOR_PEOPLE_FACE(projectId, faceId), { params: queryObject }, true).then(
//     ({ data }) => data.files,
//   );
// }

export function fetchFilesForDAMLibrary(
  projectIds: Array<string> | string,
  faceIds?: Array<string>,
  queryObject?: Record<string, string>,
): Promise<Record<string, FolderFileType>> {
  const projectId = Array.isArray(projectIds) ? projectIds.join(',') : projectIds;

  return HttpUtils.get(
    API_URLS.FILES.FETCH_FOR_PEOPLE_TOGETHER_FACE(projectId),
    {
      params: {
        face_id: faceIds,
        ...queryObject,
      },
    },
    true,
  ).then(({ data }) => data.files);
}

export function fetchFilterLabelsForDAMLibrary(
  projectIds: Array<string> | string,
): Promise<Record<string, FolderFileType>> {
  const projectId = Array.isArray(projectIds) ? projectIds.join(',') : projectIds;

  return HttpUtils.get(API_URLS.FILES.FETCH_DAM_FILTER_LABELS(projectId), {}, true).then(({ data }) => data.data);
}
