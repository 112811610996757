import { closestCenter, DndContext } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FileService, FolderFilePositionOrderService, FolderMetaService, FolderService } from '@premagic/core';
import {
  Col,
  COLOR_SHADES,
  FadeIn,
  Gallery,
  GALLERY_IMAGE_STYLE,
  GalleryImageBottomButton,
  GalleryImageWithUploadStatus,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TimeAgo,
} from '@premagic/myne';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import React, { useEffect, useState } from 'react';
import APP_URLS from '../../../../services/AppRouteURLService';
import AccountFolderPhotoDropZoneContainer from '../folder-files-upload/AccountFolderPhotoDropZoneContainer';
import FolderFilesUploadStatusContainer from '../folder-files-upload/FolderFilesUploadStatusContainer';
import FolderGallerySortedByDropdownContainer from './FolderGallerySortedByDropdownContainer';

type FolderGalleryImageProps = {
  fileId: string;
  file: FileService.FolderFileType;
  sortable?: boolean;
  onClick?: () => void;
  onSelectExpand?: () => void;
  isInBulkAction?: boolean;
  selectedFilesForBulkAction?: Array<string>;
  toggleFileSelectionForBulkAction?: (fileId: string, selected: boolean) => void;
  isAlbumViewWithFileName?: boolean;
  style?: GALLERY_IMAGE_STYLE;
};

export function FolderGalleryImage(props: FolderGalleryImageProps) {
  const {
    fileId,
    sortable = false,
    file,
    onClick,
    onSelectExpand,
    isInBulkAction = false,
    selectedFilesForBulkAction = [],
    toggleFileSelectionForBulkAction,
    isAlbumViewWithFileName = false,
    style = GALLERY_IMAGE_STYLE.COVER,
  } = props;
  const {
    image_name: name,
    thumbnail_dynamic_image_url: src = '',
    asset_upload_status: assetUploadStatus,
    asset_processor_info: assetProcessorInfo,
    asset_type: type,
  } = file || {};
  const detailedError =
    assetUploadStatus && assetUploadStatus !== FileService.FILE_UPLOAD_STAGES.DONE
      ? FileService.FILE_UPLOAD_STAGES_DETAILS[assetUploadStatus]
      : { title: '', icon: undefined, iconColor: undefined, waitTime: () => '' };

  const checked = selectedFilesForBulkAction.includes(fileId);

  return (
    <div style={{ position: 'relative' }}>
      <GalleryImageWithUploadStatus
        onClick={onClick}
        alt={name}
        src={src}
        onChangeValue={(value) => {
          if (toggleFileSelectionForBulkAction) toggleFileSelectionForBulkAction(fileId, value);
        }}
        showCheckbox={isInBulkAction}
        selected={checked}
        isVideo={type === FileService.ASSET_TYPES.VIDEO}
        cssStyle={
          sortable
            ? {
                height: '124px',
                objectFit: 'cover',
                cursor: 'grab',
              }
            : {}
        }
        style={style}
        icon={detailedError?.icon}
        iconColor={detailedError?.iconColor as COLOR_SHADES}
        iconMessage={`${detailedError?.title}, ${detailedError?.waitTime?.(assetProcessorInfo)}`}
      />
      {isAlbumViewWithFileName && <GalleryImageBottomButton name={name} onSelectExpand={onSelectExpand} />}
    </div>
  );
}

type SortabelItemProps = {
  id: string;
  index: number;
  file: FileService.FolderFileType;
  sortable: boolean;
  onClick: (fileId: string) => void;
  isInBulkAction: boolean;
  selectedFilesForBulkAction: Array<string>;
  toggleFileSelectionForBulkAction: (fileId: string, selected: boolean) => void;
  isAlbumViewWithFileName: boolean;
};

function SortabelItem(props: SortabelItemProps) {
  const {
    id,
    sortable,
    file,
    onClick,
    isInBulkAction,
    selectedFilesForBulkAction,
    toggleFileSelectionForBulkAction,
    isAlbumViewWithFileName,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    boxShadow: isDragging ? '0 15px 15px 0 rgb(34 33 81 / 25%)' : 'none',
    zIndex: isDragging ? 1 : 0,
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <FolderGalleryImage
        fileId={id}
        file={file}
        sortable={sortable}
        onClick={() => onClick(id)}
        isInBulkAction={isInBulkAction}
        toggleFileSelectionForBulkAction={toggleFileSelectionForBulkAction}
        selectedFilesForBulkAction={selectedFilesForBulkAction}
        isAlbumViewWithFileName={isAlbumViewWithFileName}
      />
    </div>
  );
}

type FolderGallerySortableContainerProps = {
  files: Record<string, FileService.FolderFileType>;
  fileIds: Array<string>;
  sortable: boolean;
  onClick: (fileId: string) => void;
  isInBulkAction: boolean;
  selectedFilesForBulkAction: Array<string>;
  toggleFileSelectionForBulkAction: (fileId: string, selected: boolean) => void;
  onSortEnd: (indexPosition: FolderFilePositionOrderService.FolderFileOrderIndexType) => void;
  isAlbumViewWithFileName: boolean;
};

function FolderGallerySortableContainer(props: FolderGallerySortableContainerProps) {
  const {
    files,
    fileIds,
    sortable,
    onClick,
    selectedFilesForBulkAction,
    isInBulkAction,
    toggleFileSelectionForBulkAction,
    isAlbumViewWithFileName,
    onSortEnd,
  } = props;

  function handleDrandEnd(event) {
    if (Math.abs(event.delta.x) < 0.5 && Math.abs(event.delta.y) < 0.5) {
      onClick(event.active.id);
      return;
    }
    if (event.active && event.over) {
      onSortEnd({
        oldIndex: event.active.data.current.sortable.index,
        newIndex: event.over.data.current.sortable.index,
      });
    }
  }

  if (sortable && !isInBulkAction) {
    return (
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDrandEnd}>
        <SortableContext items={fileIds} strategy={rectSortingStrategy}>
          <Gallery scaleSizeWithScreen={false}>
            {fileIds.map((fileId, index) => (
              <SortabelItem
                key={fileId}
                id={fileId}
                index={index}
                file={files[fileId]}
                sortable
                onClick={() => onClick(fileId)}
                isInBulkAction={isInBulkAction}
                toggleFileSelectionForBulkAction={toggleFileSelectionForBulkAction}
                selectedFilesForBulkAction={selectedFilesForBulkAction}
                isAlbumViewWithFileName={isAlbumViewWithFileName}
              />
            ))}
          </Gallery>
        </SortableContext>
      </DndContext>
    );
  }
  return (
    <Gallery scaleSizeWithScreen={false}>
      {fileIds.map((id) => (
        <FolderGalleryImage
          key={id}
          fileId={id}
          file={files[id]}
          sortable={false}
          onClick={() => onClick(id)}
          isInBulkAction={isInBulkAction}
          toggleFileSelectionForBulkAction={toggleFileSelectionForBulkAction}
          selectedFilesForBulkAction={selectedFilesForBulkAction}
          isAlbumViewWithFileName={isAlbumViewWithFileName}
        />
      ))}
    </Gallery>
  );
}

type FolderGalleryProps = {
  isLoading: boolean;
  folder?: FolderService.FolderType;
  files: Record<string, FileService.FolderFileType>;
  fetchFilePositionOrdersForFolder: (projectId: string, folderId: string) => void;
  setFileIdsOrder: (updateFilePositionOrderData: FolderFilePositionOrderService.UpdateFilesPositionDataType) => void;
  projectId: string;
  folderId: string;
  fileIds: Array<string>;
  folderType: FolderService.FOLDER_TYPES;
  navigateTo: (path: string) => void;
  saveFolderMetaData: (projectId: string, folderId: string, data) => void;
  folderMetaData: FolderMetaService.FolderMetaType;
  isInBulkAction: boolean;
  selectedFilesForBulkAction: Array<string>;
  toggleFileSelectionForBulkAction: (fileId: string, selected: boolean) => void;
  isAlbumViewWithFileName: boolean;
};

export default function FolderGallery(props: FolderGalleryProps) {
  const {
    files,
    folder,
    fetchFilePositionOrdersForFolder,
    projectId,
    folderId,
    fileIds = [],
    setFileIdsOrder,
    isLoading,
    folderType,
    navigateTo,
    saveFolderMetaData,
    folderMetaData,
    isInBulkAction,
    selectedFilesForBulkAction,
    toggleFileSelectionForBulkAction,
    isAlbumViewWithFileName,
  } = props;
  const totalFiles = fileIds.length;
  const { created_at: createdAt } = folder || {};

  const showSortByInfo = [FolderService.FOLDER_TYPES.HIGHLIGHT, FolderService.FOLDER_TYPES.SELECTION].includes(
    folderType,
  );
  const showFooterInfo =
    [FolderService.FOLDER_TYPES.HIGHLIGHT, FolderService.FOLDER_TYPES.SELECTION].includes(folderType) && createdAt;

  const [searchTerm, updateSearchTerm] = useState('');

  function getFilteredFileIds() {
    if (searchTerm.length < 3) return fileIds;
    return Object.keys(files).filter((fileId) => files[fileId].image_name.includes(searchTerm));
  }

  function handleOnClick(fileId: string) {
    const fileUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.FILE_VIEW, {
      folderType,
      projectId,
      folderId,
      view: 'all',
      id: fileId,
    });
    navigateTo(fileUrl);
  }

  useEffect(() => {
    if (folderType === FolderService.FOLDER_TYPES.HIGHLIGHT) {
      fetchFilePositionOrdersForFolder(projectId, folderId);
    }
  }, []);

  if (isLoading)
    return (
      <Space>
        <LoadingDots size="sm" />
      </Space>
    );

  if (totalFiles === 0) return null;

  return (
    <FadeIn>
      <Row vcenter>
        {isAlbumViewWithFileName && (
          <Col size={4}>
            <Input
              name="search"
              buttonIcon={<Icon name="search" size={ICON_SIZES.MD} />}
              iconPlacement="left"
              placeholder="Search Image"
              defaultValue={searchTerm}
              size={INPUT_SIZES.SM}
              onChange={(e) => updateSearchTerm(e.target.value)}
            />
          </Col>
        )}
        <Col size={4}>
          <FolderFilesUploadStatusContainer folderId={folderId} projectId={projectId} />
        </Col>
        {showSortByInfo && (
          <Col size={null} rightAlighed>
            <Row center>
              <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>Sorted by</Text>
              <Space size={2} />
              <FolderGallerySortedByDropdownContainer projectId={projectId} folderId={folderId} />
            </Row>
          </Col>
        )}
      </Row>
      <Space vertical size={2} />
      <AccountFolderPhotoDropZoneContainer projectId={projectId} folderId={folderId}>
        <FolderGallerySortableContainer
          // @ts-ignore
          fileIds={getFilteredFileIds()}
          files={files}
          isInBulkAction={isInBulkAction}
          selectedFilesForBulkAction={selectedFilesForBulkAction}
          toggleFileSelectionForBulkAction={toggleFileSelectionForBulkAction}
          isAlbumViewWithFileName={isAlbumViewWithFileName}
          onSortEnd={(indexPosition) => {
            if (
              folderMetaData.highlight_files_order !==
              FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.CUSTOM
            ) {
              saveFolderMetaData(projectId, folderId, {
                data: {
                  [FolderMetaService.FOLDER_META_TYPES.HIGHLIGHT_FILES_ORDER]:
                    FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES.CUSTOM,
                },
              });
            }

            setFileIdsOrder({ indexPosition, projectId, folderId, fileIds });
          }}
          onClick={handleOnClick}
          sortable={folderType === FolderService.FOLDER_TYPES.HIGHLIGHT}
        />
      </AccountFolderPhotoDropZoneContainer>
      <Space vertical />
      {showFooterInfo && (
        <Row>
          <Text muted>Drag and drop to upload more photos</Text>
          <Col rightAlighed size={null}>
            <Text muted>
              Folder added <TimeAgo date={SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(createdAt)} />
            </Text>
          </Col>
        </Row>
      )}
    </FadeIn>
  );
}
