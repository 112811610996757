import { connect } from 'react-redux';
import { ProjectMetaService, SponsorCreativesService } from '@premagic/core';
import { ModalDuck } from '@premagic/common-ducks';
import SponsorCreativeList from './SponsorCreativeList';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import {
  sponsorCategoryItemDataMapByCreativeIdSelector,
  sponsorCreativesIdsForPlacementGroupedSelector,
} from '../event-sponsors/EventSponsorDataDuck';
import {
  projectMetaDataForProjectSelector,
  saveProjectMetaData,
  sponsorFooterGroupByTypeSelector,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { sponsorsCategoryDataSelector } from '../event-sponsors/sponsor-category/data-duck/SponsorCategoryDataDuck';

const COMMON_KEY = LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  },
) {
  const { projectId, placement } = ownProps;
  const sponsorFooterGroupByType = sponsorFooterGroupByTypeSelector(projectId)(state);
  const projectMetaData = projectMetaDataForProjectSelector(projectId)(state);
  return {
    placement,
    isLoading: isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state),
    projectId,
    groupedCreativeIds: sponsorCreativesIdsForPlacementGroupedSelector(placement, sponsorFooterGroupByType)(state),
    sponsorCategoriesData: sponsorsCategoryDataSelector(state),
    sponsorFooterGroupByType,
    creativeIdToSponsorCategoryMapData: sponsorCategoryItemDataMapByCreativeIdSelector(placement)(state),
    sponsorTitle: projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.SPONSOR_SECTION_TITLE] || 'Sponsored By',
    isSaving: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddCreative: (placement) => {
      dispatch(
        ModalDuck.setModalOptions(dispatch, COMMON_KEY, {
          placement,
        }),
      );
      dispatch(toggleWindowPanelVisibility(dispatch, COMMON_KEY, true));
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCreativeList);
