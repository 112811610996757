import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { Map as IMap } from 'immutable';
import { ActionTypeUtils, MegaFileUploadService } from '@premagic/utils';
import { commonUISelector } from '../selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('FILE_UPLOAD', true);

export const addFilesToUploader = createAction(
  getActionType('STATE', 'ADD'),
  (
    dispatch: Dispatch,
    file: {
      fileBlob: string;
      name: string;
      uploaded: boolean;
      uniqueIdentifier: string;
      size: number;
      projectId: string;
      folderId: string;
      projectName: string;
      folderName: string;
    },
  ) => ({ [file.uniqueIdentifier]: file }),
);

export const setFileAsUploaded = createAction(
  getActionType('STATE', 'UPLOADED'),
  (
    dispatch: Dispatch,
    file: {
      uniqueIdentifier: string;
      id: string;
    },
  ) => ({
    uniqueIdentifier: file.uniqueIdentifier,
    id: file.id,
  }),
);

export const setFileAsError = createAction(
  getActionType('STATE', 'ERROR'),
  (
    dispatch: Dispatch,
    file: {
      uniqueIdentifier: string;
      id: string;
    },
    message: string,
  ) => ({
    uniqueIdentifier: file.uniqueIdentifier,
    id: file.id,
    message,
  }),
);

export const setFileUploadingProgressState = createAction(
  getActionType('STATE', 'PROGRESS'),
  (dispatch: Dispatch, uniqueIdentifier, progress, avgSpeed) => ({
    uniqueIdentifier,
    progress,
    avgSpeed,
  }),
);

export const removeAllFilesInUploaderState = createAction(getActionType('STATE', 'REMOVE'));

export const setIsImageCompressorReady = createAction(
  getActionType('IMAGE_COMPRESSOR', 'UPDATE'),
  (dispatch: Dispatch, data: boolean) => data,
);

type StateItemType = IMap<string, MegaFileUploadService.UploaderFileType>;

type StateType = {
  items: StateItemType;
  isImageCompressorReady: boolean;
};

const initialState: StateType = {
  items: IMap({}),
  isImageCompressorReady: false,
};

export const UploaderReducer = handleActions(
  {
    [addFilesToUploader.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload) as StateItemType,
    }),
    [removeAllFilesInUploaderState.toString()]: (state) => ({
      ...state,
      items: IMap({}) as StateItemType,
    }),
    [setFileAsUploaded.toString()]: (state, action: { payload }) => {
      const { uniqueIdentifier, id } = action.payload;
      return {
        ...state,
        items: state.items.update(uniqueIdentifier, (item) => ({
          id,
          uniqueIdentifier,
          uploaded: true,
          name: item?.name || '',
          fileBlob: item?.fileBlob || '',
          size: item?.size || 0,
          progress: item?.progress || 0,
          projectId: item?.projectId || '',
          folderId: item?.folderId || '',
          projectName: item?.projectName || '',
          folderName: item?.folderName || '',
          avgSpeed: item?.avgSpeed || 0,
        })),
      };
    },
    [setFileAsError.toString()]: (state, action: { payload }) => {
      const { uniqueIdentifier, id, message } = action.payload;
      return {
        ...state,
        items: state.items.update(uniqueIdentifier, (item) => ({
          ...item,
          id,
          uniqueIdentifier,
          uploaded: true,
          name: item?.name || '',
          fileBlob: item?.fileBlob || '',
          size: item?.size || 0,
          progress: item?.progress || 0,
          projectId: item?.projectId || '',
          folderId: item?.folderId || '',
          projectName: item?.projectName || '',
          folderName: item?.folderName || '',
          avgSpeed: item?.avgSpeed || 0,
          errored: true,
          errorMessage: message,
        })),
      };
    },
    [setFileUploadingProgressState.toString()]: (state, action: { payload }) => {
      const { uniqueIdentifier, progress, avgSpeed } = action.payload;
      return {
        ...state,
        items: state.items.update(uniqueIdentifier, (item) => ({
          uniqueIdentifier,
          id: item?.id,
          uploaded: item?.uploaded || false,
          name: item?.name || '',
          fileBlob: item?.fileBlob || '',
          size: item?.size || 0,
          projectId: item?.projectId || '',
          folderId: item?.folderId || '',
          projectName: item?.projectName || '',
          folderName: item?.folderName || '',
          progress,
          avgSpeed,
        })),
      };
    },
    [setIsImageCompressorReady.toString()]: (state, action: { payload }) => ({
      ...state,
      isImageCompressorReady: action.payload,
    }),
  },
  initialState,
);

const fileUploaderDataSelector = createSelector(commonUISelector, (state) => state.fileUploader as StateType);

export const filesWithProgressEntitiesSelector = createSelector(fileUploaderDataSelector, (state) => state.items);

export const filesUploadingSelector = createSelector(filesWithProgressEntitiesSelector, (items) =>
  items.filter((item) => !item.errored && !item.uploaded),
);

export const fileUploaderFilesSelector = createSelector(filesWithProgressEntitiesSelector, (items) => items.toJSON());

export const filesUploadProgressSelector = createSelector(filesUploadingSelector, (items) => {
  const totalProgressAcrossFiles = items.reduce((result, item) => result + item.progress || 0, 0);
  return totalProgressAcrossFiles / items.size || 0;
});

export const filesUploadErrorSelector = createSelector(filesWithProgressEntitiesSelector, (items) =>
  items
    .filter((item) => item.errored)
    .valueSeq()
    .toArray(),
);

export const totalUploadingFilesSelector = createSelector(filesUploadingSelector, (items) => items.size);

export const totalUploadedFilesSelector = createSelector(
  filesWithProgressEntitiesSelector,
  (items) => items.filter((item) => item.uploaded).size,
);

export const isUploadingFilesSelector = createSelector(totalUploadingFilesSelector, (size) => size > 0);

function getProgressForItems(items: IMap<string, MegaFileUploadService.UploaderFileType>): number {
  const totalProgressAcrossFiles = items.reduce((result, item) => result + item.progress || 0, 0);
  return totalProgressAcrossFiles / items.size || 0;
}

export const projectsWithProgressSelector = createSelector(filesWithProgressEntitiesSelector, (items) =>
  items
    .groupBy((item) => item.projectId)
    .map((groupItems) => {
      const groupItemsMap = groupItems.toMap();
      return {
        projectName: groupItems?.first()?.projectName || 'Some Project',
        totalFiles: groupItemsMap.size,
        progress: getProgressForItems(groupItemsMap),
      };
    })
    .toJSON(),
);

export const totalUploaderFilesForFolderSelector = (folderId: string) =>
  createSelector(filesWithProgressEntitiesSelector, (items) => items.filter((item) => item.folderId === folderId).size);

export const uploadProgressForFolderSelector = (folderId: string) =>
  createSelector(
    filesWithProgressEntitiesSelector,
    (items) => getProgressForItems(items.filter((item) => item.folderId === folderId)) || -1,
  );

export const uploadProgressStatsForFolderSelector = (folderId: string) =>
  createSelector(filesWithProgressEntitiesSelector, (items) => {
    const files = items.filter((item) => item.folderId === folderId);
    const uploadedFiles = items.filter((item) => item.uploaded);
    const avgSpeed = items.reduce((result, item) => result + item.avgSpeed || 0, 0);
    return {
      total: files.size,
      uploaded: uploadedFiles.size,
      avgSpeed,
    };
  });

export const uploadProgressForProjectSelector = (projectId: string) =>
  createSelector(
    filesWithProgressEntitiesSelector,
    (items) => getProgressForItems(items.filter((item) => item.projectId === projectId)) || -1,
  );

export const isImageCompressorReady = createSelector(fileUploaderDataSelector, (state) => state.isImageCompressorReady);
