import { connect } from 'react-redux';
import { hasPermission } from '../../../users/UsersDataDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import EventSponsorOptionsMenu from './EventSponsorOptionsMenu';

function mapStateToProps(state, ownProps: { sponsorId: string; projectId: string }) {
  const { sponsorId, projectId } = ownProps;

  return {
    projectId,
    sponsorId,
    hasPermissionsToManageSponsor: hasPermission(PERMISSIONS.EVENT_SPONSOR_MANAGEMENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: (id: string) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_DELETE_DIALOG(id), true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorOptionsMenu);
