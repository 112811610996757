import { ClientWebsiteService, EventsService, FolderService } from '@premagic/core';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Divider,
  EscapedParagraphHTMLText,
  Icon,
  ICON_SIZES,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import React, { useState } from 'react';
import MESSAGES from '../../../../../../common/Messages';
import ClientWebsiteItineraryLayoutContainer from '../../../client-website/itinerary/ClientWebsiteItineraryLayoutContainer';
import ProjectCoverPhotoContainer from '../project-cover-photo/ProjectCoverPhotoContainer';

type ClientWebsiteNameProps = {
  event?: EventsService.EventType;
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  folder: FolderService.FolderType;
};

function ClientWebsiteName(props: ClientWebsiteNameProps) {
  const { clientWebsite, event, folder } = props;
  const { data } = clientWebsite;
  const { bride_name: brideName, groom_name: groomName } = data;
  const showName = groomName !== event?.name;

  return (
    <div>
      {showName && (
        <div>
          <Space size={2} />
          <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} size={TEXT_SIZE.SIZE_3}>
            {groomName}
            {brideName && (
              <>
                <Space size={1} />
                &
                <Space size={1} />
                {brideName}
              </>
            )}
            <Space />
          </Text>
        </div>
      )}
    </div>
  );
}

type EventScheduleWithTimezoneProps = {
  eventData?: EventsService.EventDataType;
};

function EventScheduleWithTimezone(props: EventScheduleWithTimezoneProps) {
  const { eventData } = props;
  const { date_timezone: dateTimezone, start_date: startDate, end_date: endDate } = eventData || {};

  if (!startDate) return <Text muted>(not set)</Text>;
  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.LIGHT} inline>
        <Icon name="clock" size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} block>
        {endDate
          ? SimpleDateUtils.humanizeDateRage(startDate, endDate, {
              dateTimezone,
            })
          : SimpleDateUtils.humanizeDate(startDate, false, false, false, dateTimezone)}
      </Text>
    </Row>
  );
}

type EventLocationProps = {
  eventData?: EventsService.EventDataType;
};

function EventLocation(props: EventLocationProps) {
  const { eventData } = props;
  const { location, location_map_link: locationMapLink } = eventData || {};

  const locationText = location ? (
    <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>{location}</Text>
  ) : (
    <Text muted>(not set)</Text>
  );
  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.LIGHT} inline>
        <Icon name="map_pin" size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />
      {locationMapLink && location?.length ? (
        <SimpleLink href={locationMapLink} isExternalLink>
          <Row vcenter>
            {locationText}
            <Space size={1} />
            <Icon name="external_link_2" size={ICON_SIZES.SM} />
          </Row>
        </SimpleLink>
      ) : (
        locationText
      )}
    </Row>
  );
}

type EventNumberOfAttendeesProps = {
  eventData?: EventsService.EventDataType;
};

function EventNumberOfAttendees(props: EventNumberOfAttendeesProps) {
  const { eventData } = props;
  const { number_of_attendees: numberOfAttendees } = eventData || {};
  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.LIGHT} inline>
        <Icon name="users" size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />
      {numberOfAttendees ? (
        <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>{numberOfAttendees} </Text>
      ) : (
        <Text muted>(not set)</Text>
      )}
    </Row>
  );
}

type EventWebsiteProps = {
  eventData?: EventsService.EventDataType;
};

function EventWebsite(props: EventWebsiteProps) {
  const { eventData } = props;
  const { event_website: eventWebsite } = eventData || {};

  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.LIGHT} inline>
        <Icon name="link" size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />
      {eventWebsite ? (
        <SimpleLink href={eventWebsite} isExternalLink>
          <Row vcenter>
            {eventWebsite}
            <Space size={1} />
            <Icon name="external_link_2" size={ICON_SIZES.SM} />
          </Row>
        </SimpleLink>
      ) : (
        <Text muted>(not set)</Text>
      )}
    </Row>
  );
}

type AboutEventProps = {
  eventData?: EventsService.EventDataType;
};

function AboutEvent(props: AboutEventProps) {
  const { eventData } = props;
  const { about_the_event: aboutTheEvent } = eventData || {};

  const [viewFullText, setViewFullText] = useState(false);

  return (
    <Row>
      <Color shade={COLOR_SHADES.LIGHT} inline>
        <Icon name="info" size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />

      {(() => {
        if (!aboutTheEvent) return <Text muted>(not set)</Text>;

        if (!viewFullText) {
          return (
            <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={() => setViewFullText(true)}>
              Expand
            </Button>
          );
        }

        return (
          <Text size={TEXT_SIZE.SIZE_5} muted>
            <EscapedParagraphHTMLText value={aboutTheEvent} />
          </Text>
        );
      })()}
    </Row>
  );
}

type Props = {
  event?: EventsService.EventType;
  folder: FolderService.FolderType;
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  folderId: string;
  projectId: string;
  showSharePanel: (folderId: string) => void;
  showEditOfClientWebsite: (folderId: string) => void;
  eventData?: EventsService.EventDataType;
  isSaving?: boolean;
  isCompanyTypeNotPhotographer?: boolean;
};

// later we will rename client website to something else
export default function ClientWebsiteCard(props: Props): JSX.Element {
  const {
    folder,
    clientWebsite,
    folderId,
    projectId,
    event,
    showSharePanel,
    showEditOfClientWebsite,
    eventData,
    isSaving,
    isCompanyTypeNotPhotographer,
  } = props;
  const { folder_name: folderName, share_url: shareUrl, status } = folder;
  if (!clientWebsite)
    return (
      <Text primaryStyle center block>
        {folderName}
      </Text>
    );

  return (
    <div>
      <Card>
        <Space size={2}>
          <ClientWebsiteName clientWebsite={clientWebsite} event={event} folder={folder} />
        </Space>
        <ProjectCoverPhotoContainer projectId={projectId} />
        <Space>
          <EventScheduleWithTimezone eventData={eventData} />
          <Space vertical />
          <EventLocation eventData={eventData} />
          <Space vertical />
          {isCompanyTypeNotPhotographer && (
            <>
              <EventNumberOfAttendees eventData={eventData} />
              <Space vertical />
              <EventWebsite eventData={eventData} />
              <Space vertical />
            </>
          )}
          <AboutEvent eventData={eventData} />
        </Space>
        <Divider />
        <Space>
          <Row>
            <Button style={BUTTON_STYLES.LINK} onClick={() => showEditOfClientWebsite(folderId)} isLoading={isSaving}>
              <Icon name="edit_2" size={ICON_SIZES.SM} />
              <Space size={1} />
              Edit Details
            </Button>
            <Button
              style={BUTTON_STYLES.LINK}
              link={shareUrl}
              disabled={status === FolderService.FOLDER_STATUS.DRAFT}
              disabledMessage={MESSAGES.CLIENT_WEBSITE.SHARE_TO_OPEN}
              isExternalLink
            >
              <Icon name="external_link" size={ICON_SIZES.SM} />
              <Space size={1} />
              Open {MESSAGES.CLIENT_WEBSITE.NAME}
            </Button>
            <Col rightAlighed size={null}>
              <Button style={BUTTON_STYLES.LINK} onClick={() => showSharePanel(folderId)}>
                <Icon name="share" size={ICON_SIZES.SM} />
                <Space size={2} />
                Share
              </Button>
            </Col>
          </Row>
        </Space>
      </Card>
      <ClientWebsiteItineraryLayoutContainer projectId={projectId} id={folderId} />
    </div>
  );
}
