import { getHashCode } from './StringUtils';

export function getRandomNumber(min: number, max: number, round = true): number {
  if (round) return Math.round(Math.random() * (max - min) + min);
  return Math.random() * (max - min + 1) + min;
}

// This is something we used for wish card color
export function getRandomIndexFromListOfItemsWithAConst<T>(list: Array<T>, constString: string): number {
  const hashCode = getHashCode(constString);
  return hashCode % list.length;
}
export function getRandomItemFromListOfItemsWithAConst<T>(list: Array<T>, constString: string): T {
  const colorIndex = getRandomIndexFromListOfItemsWithAConst(list, constString);
  return list[colorIndex];
}

export function getMeasurementUnitForX(
  percent: number,
  size: {
    width: number;
    height: number;
  },
): number {
  return (percent / 100) * size.width;
}
export function getMeasurementUnitForY(
  percent: number,
  size: {
    width: number;
    height: number;
  },
): number {
  return (percent / 100) * size.height;
}
