import { connect } from 'react-redux';
import ClientPaymentsInvoiceContent from './ClientPaymentsInvoiceContent';
import {
  crmAllClientPaymentsSelector,
  crmClientPaymentTotalSelector,
  crmInvoiceNoteSelector,
  crmPendingClientPaymentTotalSelector,
  crmSettledClientPaymentTotalSelector,
  crmTotalNonTaxableAmountSelector,
  crmTotalTaxableAmountSelector,
  crmTotalTaxAmountSelector,
} from '../../CRMPaymentDataDuck';
import { paymentCategoriesSelector } from '../../CRMPaymentCategoryDataDuck';
import {
  accountInvoiceNoteSelector,
  accountTaxNameSelector,
  accountTaxPercentageSelector,
} from '../../../../acccount/AccountDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';

function mapStateToProps(state) {
  return {
    payments: crmAllClientPaymentsSelector(state),
    categories: paymentCategoriesSelector(state),
    totalPayments: crmClientPaymentTotalSelector(state),
    totalPending: crmPendingClientPaymentTotalSelector(state),
    totalSettled: crmSettledClientPaymentTotalSelector(state),
    taxName: accountTaxNameSelector(state),
    taxPercentage: accountTaxPercentageSelector(state),
    totalTaxAmount: crmTotalTaxAmountSelector(state),
    invoiceNote: crmInvoiceNoteSelector(state),
    invoiceNoteLoading: isLoadingSelector(LOADINGS.CRM_PAYMENTS.UPDATE)(state),
    defaultInvoiceNote: accountInvoiceNoteSelector(state),
    totalTaxableAmount: crmTotalTaxableAmountSelector(state),
    totalNonTaxableAmount: crmTotalNonTaxableAmountSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_INVOICE_NOTE_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPaymentsInvoiceContent);
