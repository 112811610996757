import { connect } from 'react-redux';
import { filesSelector } from '../../../images/FilesDataDuck';
import { qrCodeDesignsSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { DisplayedPosterIllustration } from './DisplayedPosterIllustration';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    imageWidth: number;
    imageHeight: number;
  },
) {
  const { folderId, imageWidth, imageHeight } = ownProps;

  let illustrationFile;
  const filesData = filesSelector(state);
  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);
  const illustrationId = qrCodeDesigns?.custom_illustration;
  if (illustrationId) {
    illustrationFile = filesData[illustrationId];
  }

  return {
    illustrationMetaData: qrCodeDesigns?.custom_illustration_meta_data,
    illustrationFile,
    imageWidth,
    imageHeight,
    qrCodeDesigns,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayedPosterIllustration);
