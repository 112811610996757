import { connect } from 'react-redux';
import ClientWishItem from './ClientWishItem';
import { clientWishesSelector } from './ClientWishDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, folderId, projectId } = ownProps;

  return {
    id,
    clientWish: clientWishesSelector(state)[id],
    projectId,
    folderId,
  };
}

export default connect(mapStateToProps)(ClientWishItem);
