import {
  ButtonTile,
  BUTTON_TILE_SIZE,
  Illustration,
  ILLUSTRATION_SIZES,
  Row,
  Space,
  SubTitle,
  Grid,
} from '@premagic/myne';
import React from 'react';

type Props = {
  onChange: (value: number) => void;
  galleryGridSpace: number;
  isGalleryGridSpaceSelectorEnabled: boolean;
};

export default function HighlightDesignGalleryGridSpaceSelector(props: Props) {
  const { onChange, galleryGridSpace, isGalleryGridSpaceSelectorEnabled } = props;

  return (
    <>
      <SubTitle>Grid Spacing</SubTitle>
      <Space vertical size={2} />
      <Grid colSize={94} gap={2}>
        <ButtonTile
          title="Regular"
          onClick={() => {
            if (isGalleryGridSpaceSelectorEnabled && galleryGridSpace !== 1) {
              onChange(1);
            }
          }}
          active={galleryGridSpace === 1}
          size={BUTTON_TILE_SIZE.XS}
        >
          <Illustration name="dummy_gallery_with_regular_space" size={ILLUSTRATION_SIZES.AUTO} />
        </ButtonTile>
        <ButtonTile
          title="Large"
          onClick={() => {
            if (isGalleryGridSpaceSelectorEnabled && galleryGridSpace !== 3) {
              onChange(3);
            }
          }}
          active={galleryGridSpace === 3}
          disabled={!isGalleryGridSpaceSelectorEnabled}
          disabledMessage="Upgrade your Plan to select this Layout"
          tooltipPlacement="top"
          size={BUTTON_TILE_SIZE.XS}
        >
          <Illustration name="dummy_gallery_with_large_space" size={ILLUSTRATION_SIZES.AUTO} />
        </ButtonTile>
      </Grid>
    </>
  );
}
