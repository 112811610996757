import { EventAttendeesService } from '@premagic/core';
import { connect } from 'react-redux';
import { clientWebsiteForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import {
  eventPostersArrayByScopeSelector,
  eventPostersByScopeSelector,
  eventPostersForPreEventArraySelector,
} from '../../event-posters/EventPostersDataDuck';
import { eventExhibitorsSelector } from './EventExhibitorDataDuck';
import EventExhibitorLinkToPosterButton from './EventExhibitorLinkToPosterButton';

function mapStateToProps(
  state,
  ownProps: {
    exhibitorId: string;
    projectId: string;
  },
) {
  const { exhibitorId, projectId } = ownProps;

  const exhibitorPosters = eventPostersArrayByScopeSelector(EventAttendeesService.EVENT_PEOPLE_TYPE.EXHIBITOR)(state);

  return {
    exhibitor: eventExhibitorsSelector(state)[exhibitorId],
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
    poster: exhibitorPosters[0], // selecting the first poster, any other posters can be selected in the client website
  };
}

export default connect(mapStateToProps)(EventExhibitorLinkToPosterButton);
