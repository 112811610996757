import React from 'react';
import { Card, List, Space, Text } from '@premagic/myne';
import EventFeedbackListItemContainer from './EventFeedbackListItemContainer';

type Props = {
  projectId: string;
  eventFeedbackIds: Array<string>;
};

export default function EventFeedbackList(props: Props) {
  const { eventFeedbackIds, projectId } = props;

  const eventFeedbackTotal = eventFeedbackIds.length;

  return (
    <Card>
      {eventFeedbackTotal === 0 && (
        <Space>
          <Text muted>There are no feedbacks submitted by the attendees yet.</Text>
        </Space>
      )}
      <List>
        {eventFeedbackIds.map((feedbackId) => (
          <EventFeedbackListItemContainer key={feedbackId} projectId={projectId} feedbackId={feedbackId} />
        ))}
      </List>
    </Card>
  );
}
