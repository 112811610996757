import React from 'react';
import { Icon, ICON_SIZES, ListItem, ListItemIcon, LIST_ITEM_STYLES } from '@premagic/myne';
import APP_URLS from '../../services/AppRouteURLService';

export default function HelpNavButton(props: { show: boolean; toggle: (show: boolean) => void }) {
  const { show, toggle } = props;
  return (
    <ListItem style={LIST_ITEM_STYLES.NAV} onClick={() => toggle(!show)} active={show}>
      <ListItemIcon>
        <Icon name="help_circle" size={ICON_SIZES.SM} />
      </ListItemIcon>
      Help
    </ListItem>
  );
}
