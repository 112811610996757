import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { EventCustomFromFieldsService, ProjectMetaService } from '@premagic/core';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { PERMISSIONS } from '../../crm/users/UserPermissionService';
import { hasPermission } from '../../crm/users/UsersDataDuck';
import { setEditOfFormField } from './RegistrationFormPageDuck';
import UserRegistrationFormFieldList from './UserRegistrationFormFieldList';
import {
  eventCustomFormFieldIdsSelectors,
  fetchCustomEventFromFields,
} from '../../event-custom-form-fields/EventCustomFormFieldsDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import {
  isEmailHiddenOnRegistrationFormOnProject,
  isEventRegistrationEmailRequiredInProject,
  projectMetaDataForProjectSelector,
} from '../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  const projectMetaData = projectMetaDataForProjectSelector(projectId)(state);
  return {
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_CUSTOM_FORM_FIELDS.FETCH)(state),
    formFieldIds: eventCustomFormFieldIdsSelectors(EventCustomFromFieldsService.FORM_FIELD_SCOPE.REGISTRATION)(state),
    isEmailHiddenOnRegistrationForm: isEmailHiddenOnRegistrationFormOnProject(projectId)(state),
    isEventRegistrationEmailRequired: isEventRegistrationEmailRequiredInProject(projectId)(state),
    eventRegistrationSendNotification:
      projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION],
    eventRegistrationSendNotificationRequired:
      projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED],
    hasPermissions: {
      [PERMISSIONS.EVENT_FORM_FIELD]: hasPermission(PERMISSIONS.EVENT_FORM_FIELD)(state),
      [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT)(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId: string) => {
      dispatch(fetchCustomEventFromFields(dispatch, projectId));
    },
    showEditFormField: (id: string) => {
      dispatch(setEditOfFormField(dispatch, id));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationFormFieldList);
