import { connect } from 'react-redux';
import { filesItemDataSelector } from '../../../images/FilesDataDuck';
import QRCodeCustomLEDBackgroundPoster from './QRCodeCustomLEDBackgroundPoster';

function mapStateToProps(state, ownProps: { fileId: string }) {
  const { fileId } = ownProps;
  return { file: filesItemDataSelector(fileId)(state) };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeCustomLEDBackgroundPoster);
