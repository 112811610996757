import { connect } from 'react-redux';

import { eventBadgesArrayByScopeSelector } from '../../event-posters/EventPostersDataDuck';
import EventAttendeeLinkToBadgeButton from './EventAttendeeLinkToBadgeButton';
import { eventAttendeesSelector } from './EventAttendeesDataDuck';
import { clientWebsiteForProjectSelector } from '../../../projects/AccountProjectsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;

  const attendee = eventAttendeesSelector(state)[id];
  const { people_type: peopleType } = attendee;

  return {
    id,
    attendee,
    badgeId: eventBadgesArrayByScopeSelector(peopleType)(state)[0],
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
  };
}

export default connect(mapStateToProps)(EventAttendeeLinkToBadgeButton);
