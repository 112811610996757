import { Map as IMap } from 'immutable';
import { ClientWebsiteService } from '@premagic/core';
import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  Menu,
  MENU_ITEM_STYLES,
  MenuItem,
  MenuItemIcon,
  Space,
  Text,
} from '@premagic/myne';
import MESSAGES from '../../../../../common/Messages';

type Props = {
  websiteId: string;
  projectId: string;
  itineraryId: number;
  removeItinerary: (options: {
    websiteId: string;
    projectId: string;
    itineraries: IMap<string, ClientWebsiteService.ClientWebsiteItinerary>;
    itineraryId: number;
  }) => void;
  isLoading: boolean;
  itineraryItems: IMap<string, ClientWebsiteService.ClientWebsiteItinerary>;
};

export default function ClientWebsiteItineraryOptionsMenu(props: Props) {
  const { websiteId, projectId, removeItinerary, itineraryItems, isLoading, itineraryId } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const $button = useRef(null);

  return (
    <>
      <Dropdown>
        <span ref={$button}>
          <DropdownIcon title="More ptions" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.MD} isLoading={isLoading}>
            <Icon name="more_vertical" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
          </DropdownIcon>
        </span>
        <Menu>
          <MenuItem style={MENU_ITEM_STYLES.DANGER} onClick={() => setShowDeleteDialog(!showDeleteDialog)}>
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      {showDeleteDialog && (
        <Dialog target={$button.current} show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
          <DialogTitle>Delete this Session?</DialogTitle>
          <Text>Are you sure you want to delete this Session?</Text>
          <Space vertical size={6} />
          <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
          <DialogFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDeleteDialog(false)}>
              Cancel
            </Button>
            <Button
              style={BUTTON_STYLES.DANGER}
              onClick={() => {
                setShowDeleteDialog(false);
                removeItinerary({
                  websiteId,
                  projectId,
                  itineraries: itineraryItems,
                  itineraryId,
                });
              }}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
}
