import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, SimpleDateUtils } from '@premagic/utils';
import { ClientWishService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';

import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { entitiesDataSelector, EntitiesStateType } from '../../../reducers/selectors';
import { setAlertState } from '../../../../../common/AlertDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_WISHES');

const {
  fetchClientWishForWebsite,
  createClientWishForWebsite,
  updateClientWish,
  CLIENT_WISH_STATUS,
  deleteClientWish,
  updateClientWishStatus,
} = ClientWishService;

export const setClientWishData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const addClientWishData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const updateClientWishData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const removeClientWishData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const getClientWishesForWebsite = createAction(
  getActionType('DATA', 'NEW'),
  (dispatch, options: { projectId: string; folderId: string }) => {
    const loadingKey = LOADINGS.CLIENT_WISH.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    fetchClientWishForWebsite(options)
      .then((response) => {
        const normalizedData = response;
        dispatch(setClientWishData(dispatch, normalizedData));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const addClientWish = createAction(
  getActionType('DATA', 'CREATE'),
  (
    dispatch,
    options: { projectId: string; folderId: string },
    formResponse: FormResponseType & {
      data: ClientWishService.NewClientWishType;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WISH.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    createClientWishForWebsite(options, data)
      .then((response) => {
        dispatch(addClientWishData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setAlertState(dispatch, loadingKey, response));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WISH.SHOW_ADD_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const saveClientWishStatus = createAction(
  getActionType('DATA_STATUS', 'UPDATE'),
  (
    dispatch,
    options: { wishId: string; projectId: string; folderId: string },
    status: ClientWishService.CLIENT_WISH_STATUS,
  ) => {
    const loadingKey = LOADINGS.CLIENT_WISH.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    updateClientWishStatus(options, status)
      .then((response) => {
        dispatch(updateClientWishData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const saveClientWish = createAction(
  getActionType('DATA', 'UPDATE'),
  (
    dispatch,
    options: { wishId: string; projectId: string; folderId: string },
    formResponse: FormResponseType & {
      data: ClientWishService.ClientWishType;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_WISH.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    updateClientWish(options, data)
      .then((response) => {
        dispatch(updateClientWishData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WISH.ADD, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const removeClientWish = createAction(
  getActionType('DATA', 'UPDATE'),
  (dispatch, options: { wishId: string; projectId: string; folderId: string }) => {
    dispatch(removeClientWishData(dispatch, options.wishId));
    deleteClientWish(options).then((data) => data);
  },
);

type ClientWishItemType = IMap<string, ClientWishService.ClientWishType>;
type StateType = {
  items: ClientWishItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setClientWishData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addClientWishData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateClientWishData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeClientWishData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

export const clientWishDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => entities.clientWish as StateType,
);

export const clientWishEntitiesSelector = createSelector(clientWishDataSelector, (clientWishes) =>
  clientWishes.items.filter((item) => item.project_id),
);

export const clientWishesSelector = createSelector(clientWishEntitiesSelector, (clientWishes) => clientWishes.toJSON());

export const clientWishesIdsForWebsiteFolderSelector = (folderId: string) =>
  createSelector(clientWishEntitiesSelector, (clientWishes) =>
    clientWishes
      .filter((wish) => wish.folder_id === folderId)
      .sort((aWish, bWish) => {
        const aWishDate = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(aWish.created_at);
        const bWishDate = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(bWish.created_at);
        return bWishDate - aWishDate;
      })
      .keySeq()
      .toArray(),
  );

export const clientWebsiteStatusSelector = (id: string) =>
  createSelector(
    clientWishEntitiesSelector,
    (clientWishes) => clientWishes.get(id, { status: CLIENT_WISH_STATUS.DRAFT }).status,
  );
