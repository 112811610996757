import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonIcon } from '../design/PosterButtons';
import { PosterMenu, PosterMenuItem } from '../design/PosterMenu';

export const PosterZoomButtonsContainer = styled.div`
  position: fixed;
  bottom: 16px;
  left: ${(props) => props.left}px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

  ${ButtonIcon} + ${ButtonIcon} {
    margin-left: 8px; // Add margin on the left for sibling ButtonIcons
  }
`;

// Create component which will have zoom in, zoom out show the current zoom level in the between and allow user to change the zoom level by click on the zoom level and clicking on the preset zoom levels
export function PosterZoomButtons(props: {
  scale: number;
  setScale: (level: number) => void;
  containerSize: {
    width: number;
    height: number;
  };
}) {
  const { scale, setScale, containerSize } = props;
  const zoomLevels = [25, 50, 75, 100, 125, 150, 200];
  const [showMenu, setShowMenu] = useState(false);
  const $button = useRef<Element>();
  const currentZoomLevel = Math.floor(scale * 100);

  function handleZoomLevelChange(level: number) {
    if (level < 25 || level > 200) return;
    setScale(level / 100);
    setShowMenu(false);
  }

  return (
    <PosterZoomButtonsContainer left={containerSize.width / 2}>
      <ButtonIcon onClick={() => handleZoomLevelChange(scale * 100 - 5)} dis>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M19 13H5v-2h14v2z" />
        </svg>
      </ButtonIcon>
      <ButtonIcon ref={$button} onClick={() => setShowMenu(true)}>
        {currentZoomLevel}%
      </ButtonIcon>
      {showMenu && (
        <PosterMenu target={$button} onClose={() => setShowMenu(false)}>
          {zoomLevels.map((level) => (
            <PosterMenuItem
              key={level}
              onClick={() => handleZoomLevelChange(level)}
              active={level === currentZoomLevel}
            >
              {level}%
            </PosterMenuItem>
          ))}
        </PosterMenu>
      )}
      <ButtonIcon onClick={() => handleZoomLevelChange(scale * 100 + 5)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 4v16m8-8H4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </ButtonIcon>
    </PosterZoomButtonsContainer>
  );
}
