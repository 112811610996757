import React from 'react';
import { Select } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

export enum THEMES_STYLE_IDS {
  DEFAULT = 1,
  BLURED = 2,
}

export const THEME_STYLES = [
  { label: 'Default', value: THEMES_STYLE_IDS.DEFAULT },
  { label: 'Blured', value: THEMES_STYLE_IDS.BLURED },
];

export function getClientWebsiteTheme(id: THEMES_STYLE_IDS) {
  return THEME_STYLES.find((theme) => theme.value == id);
}

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  name: string;
  defaultValue?: any;
};

export function ClientWebsiteThemeStyleSelectInput(props: Props): JSX.Element {
  const { clientWebsite, defaultValue, ...elementProps } = props;
  const selectedValue = getClientWebsiteTheme(defaultValue);
  return <Select {...elementProps} value={selectedValue} options={THEME_STYLES} isMulti={false} />;
}

type ClientWebsiteThemeTextGroupValueProps = {
  value: number;
};

export function ClientWebsiteThemeTextGroupValue(props: ClientWebsiteThemeTextGroupValueProps): JSX.Element {
  const { value } = props;
  const themeObj = getClientWebsiteTheme(value);
  return <div>{themeObj?.label}</div>;
}
