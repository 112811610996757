import { connect } from 'react-redux';
import { SimpleDateUtils } from '@premagic/utils';
import { crmTotalRemainingClientPaymentAmountSelector } from '../CRMPaymentDataDuck';
import AddClientPaymentsFromPreset from './AddClientPaymentsFromPreset';
import { clientPaymentCategoriesSelector } from '../CRMPaymentCategoryDataDuck';
import { isMobileUp } from '../../../AppDuck';

function mapStateToProps(state) {
  return {
    eventDate: SimpleDateUtils.now(),
    totalRemainingClientPayment: crmTotalRemainingClientPaymentAmountSelector(state),
    paymentCategories: clientPaymentCategoriesSelector(state),
    isMobileUp: isMobileUp(state),
  };
}

export default connect(mapStateToProps)(AddClientPaymentsFromPreset);
