import { ProjectMetaService } from '@premagic/core';
import {
  ButtonTile,
  BUTTON_TILE_SIZE,
  Illustration,
  ILLUSTRATION_SIZES,
  Row,
  Space,
  SubTitle,
  Grid,
} from '@premagic/myne';
import React from 'react';

type Props = {
  onChange: (value) => void;
  folderLayout: ProjectMetaService.FOLDER_THUMBNAIL_VIEWS;
  isFolderThumbnailViewEnabled: boolean;
  hasSignatureFolderThumbnailsFeature: boolean;
};

export default function HighlightDesignFolderLayoutSelector(props: Props) {
  const { onChange, folderLayout, hasSignatureFolderThumbnailsFeature, isFolderThumbnailViewEnabled } = props;

  return (
    <>
      <SubTitle>Folder Layout</SubTitle>
      <Space vertical size={2} />
      <Grid colSize={94} gap={2}>
        {/* ---- will add carousel layout later--- */}
        {/* <ButtonTile
          title="Carousel"
          onClick={() => {
            if (folderLayout !== ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.CAROUSEL) {
              onChange(ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.CAROUSEL);
            }
          }}
          active={folderLayout === ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.CAROUSEL}
          disabled={!isFolderLayoutSelectorEnabled}
          disabledMessage="Upgrade your Plan to select this Layout"
          tooltipPlacement="top"
          size={BUTTON_TILE_SIZE.SM_XS}
        >
          <Illustration name="dummy_folder_carousel_view_layout" size={ILLUSTRATION_SIZES.SM} />
        </ButtonTile>
        <Space size={3} /> */}
        <ButtonTile
          title="Grid View"
          onClick={() => {
            if (folderLayout !== ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD) {
              onChange(ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD);
            }
          }}
          active={folderLayout === ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD && isFolderThumbnailViewEnabled}
          disabled={!hasSignatureFolderThumbnailsFeature}
          disabledMessage="Upgrade your Plan to select this Layout"
          tooltipPlacement="top"
          size={BUTTON_TILE_SIZE.XS}
        >
          <Illustration name="dummy_folder_grid_view_layout" size={ILLUSTRATION_SIZES.SM} />
        </ButtonTile>
        <ButtonTile
          title="Card View"
          onClick={() => {
            if (folderLayout !== ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.LANDSCAPE_CARD) {
              onChange(ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.LANDSCAPE_CARD);
            }
          }}
          active={
            folderLayout === ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.LANDSCAPE_CARD && isFolderThumbnailViewEnabled
          }
          disabled={!hasSignatureFolderThumbnailsFeature}
          disabledMessage="Upgrade your Plan to select this Layout"
          tooltipPlacement="top"
          size={BUTTON_TILE_SIZE.XS}
        >
          <Illustration name="dummy_folder_card_view_layout" size={ILLUSTRATION_SIZES.SM} />
        </ButtonTile>
        <ButtonTile
          title="No Thumbnail"
          onClick={() => {
            onChange(false);
          }}
          active={!isFolderThumbnailViewEnabled}
          disabled={!hasSignatureFolderThumbnailsFeature}
          disabledMessage="Upgrade your Plan to select this Layout"
          tooltipPlacement="top"
          size={BUTTON_TILE_SIZE.XS}
        >
          <Illustration name="dummy_folder_no_thumbnail_view_layout" size={ILLUSTRATION_SIZES.SM} />
        </ButtonTile>
      </Grid>
    </>
  );
}
