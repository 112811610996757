// https://github.com/premagic/premagic-static-assets/tree/master/dist/s/logos

export const ASSETS_URL = {
  LOGO_ICON_WITH_TEXT: 'logo-lg.png',
  LOGO_ICON_WITH_TEXT_HIGH_QUALITY: 'logo-xxlg.png',
  ICON: '512x12.png',
  DEV_ICON: 'dev.png',
  PRODUCT_IMAGES: {
    DWI_CARD: 'dwi-card.png',
    HERO_PERSON_WITH_CAM: 'hero-person-with-cam.png',
    SELECTION_MINI_GALLERY: 'selection-mini-gallery.png',
    SIGNATURE_ALBUM_MINI_GALLERY: 'signature-album-gallery.png',
    FAVORITE_SELECTION_INTO: 'favorite-selection-into-image.png',
    ALL_SELECTED: 'all-selected-photos.png',
    SELECTION_INTO_PAGE_IMAGE: 'selection-intro-page-image.png',
    SELECTION_HOME_INTRO: 'selection-home-hero.png',
    SELECTION_DONE: 'selection-donev1.webp',
    ORGANIZER_LOOKING: 'organizer-1.png',
    LINKEDIN_THUMBS_UP: 'linkedin-like-icon.png',
    LINKEDIN_CELEBRATE: 'linkedin-celebrate-icon.png',
    LINKEDIN_HEART: 'linkedin-love-icon.png',
    PHOTO_SLIDER_WIDGET_PREVIEW: 'photo-slider-widget-preview-illustration.png',
    AVATAR_STUDIO: 'avatar-intro.png',
  },
  MEMOJI: {
    WHITE_CAP: 'memoji-white-cap-1.png',
    BLACK_GLASS: 'memoji-black-glass-1.png',
  },
  STORE: {
    COMMON_SOON_LG: 'offer-coming-soon-lg.webp',
    COMMON_SOON: 'offer-coming-soon-sm.webp',
  },
  ILLUSTRATIONS: {
    CAMERA_LOCK: 'illu-camera-lock.png',
    CAMERA_TICK: 'illu-camera-tick.png',
    THUMBS_UP: 'thumbs-up-3d.png',
  },
  CSV_FILES: {
    SAMPLE_EVENT_ATTENDEE_LIST_CSV: 'sample_event_attendee_list.csv',
  },
  QR_CODE_BACKGROUND_POSTER: {
    // ---SIMPLE---
    SIMPLE_FLOWERS: 'simple-flowers.jpg',
    // ---CORNER FLOWERS---
    CORNER_FLOWERS_LED_SCREEN: 'corner-flowers-led-screen.jpg',
    CORNER_FLOWERS_POCKET_CARD_FRONT_SINGLE_LOGO: 'corner-flowers-pocket-card-front-single-logo.jpg',
    CORNER_FLOWERS_POCKET_CARD_FRONT_MULTI_LOGO: 'corner-flowers-pocket-card-front-multi-logo.jpg',
    CORNER_FLOWERS_POCKET_CARD_BACK: 'corner-flowers-pocket-card-back.jpg',
    CORNER_FLOWERS_STANDEE_MULTI_LOGO: 'corner-flowers-standee-multi-logo.jpg',
    CORNER_FLOWERS_STANDEE_SINGLE_LOGO: 'corner-flowers-standee-single-logo.jpg',
    // ---NAVY SEMI CIRCLE---
    NAVY_SEMI_CIRCLES_POCKET_BACK: 'navy-semi-circles-pocket-back.jpg',
    NAVY_SEMI_CIRCLES_POCKET_FRONT: 'navy-semi-circles-pocket-front.jpg',
    NAVY_SEMI_CIRCLES_STANDEE_LED: 'navy-semi-circles-standee-led.jpg',
    NAVY_SEMI_CIRCLES_STANDEE: 'navy-semi-circles-standee.jpg',
    // ---PURPLE THREADS---
    PURPLE_THREADS_LED: 'purple-threads-led.jpg',
    PURPLE_THREADS_STANDEE: 'purple-threads-standee.jpg',
    PURPLE_THREADS_POCKET_BACK: 'purple-threads-pocket-back.jpg',
    PURPLE_THREADS_POCKET_FRONT: 'purple-threads-pocket-front.jpg',
    // ---SNOWFLAKE---
    SNOWFLAKE_LED: 'snowflake-led.jpg',
    SNOWFLAKE_POCKET_BACK: 'snowflake-pocket-back.jpg',
    SNOWFLAKE_POCKET_FRONT: 'snowflake-pocket-front.jpg',
    SNOWFLAKE_STANDEE: 'snowflake-standee.jpg',
  },
};

const STATIC_ASSETS_ROOT = 'https://asts.premagic.com';

export function getProductImages(imageName: string): string {
  return `${STATIC_ASSETS_ROOT}/s/product-images/${imageName}`;
}

export function getLogoImages(imageName: string): string {
  return `${STATIC_ASSETS_ROOT}/s/logos/${imageName}`;
}

export function getLinkedinReactionImages(imageName: string): string {
  return `${STATIC_ASSETS_ROOT}/s/linkedin-reactions/${imageName}`;
}

export function getCSVFiles(csvFileName: string): string {
  return `${STATIC_ASSETS_ROOT}/s/csv-files/${csvFileName}`;
}
export function getMemojiImages(imageName: string): string {
  return `${STATIC_ASSETS_ROOT}/s/product-images/${imageName}?v=2`;
}

export function getQRCodeBackgroundPosterImages(imageName: string): string {
  return `${STATIC_ASSETS_ROOT}/s/qr-code-background-posters/${imageName}`;
}
