import React, { useEffect } from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  FormFooter,
  BUTTON_SIZE,
  Button,
  LoadingDots,
} from '@premagic/myne';
import { ProposalService } from '@premagic/proposals';
import ProposalHistoryVersionItemContainer from './ProposalHistoryVersionItemContainer';

//  versions will have all the version from latest to oldest, The oldest version will be 1, and the latest version will be 'latest'
function getVersionNumber(versions: Array<string>, currentVersion: string): 'latest' | string {
  const index = versions.indexOf(currentVersion);
  const totalVersions = versions.length;
  if (index === 0) {
    return 'latest';
  }

  // [a, b, c, d, e];
  // b = 3
  // 3 - 5 = -2
  return String((index - totalVersions) * -1);
}

type Props = {
  projectId: string;
  folderId: string;
  close: () => void;
  show: boolean;
  isLoading: boolean;
  proposal?: ProposalService.ProposalType;
  initData: (projectId: string, deckIds: Array<string>) => void;
};

export default function ProposalHistoryPanel(props: Props) {
  const { show, close, isLoading, proposal, folderId, projectId, initData } = props;

  useEffect(() => {
    if (show && proposal && proposal?.deck_versions?.length > 1) {
      initData(projectId, proposal.deck_versions);
    }
  }, [show, proposal?.deck_versions]);

  if (!proposal) return null;
  const { deck_versions: versions } = proposal;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Proposal history</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        {isLoading && (
          <div>
            <LoadingDots size="sm" />
          </div>
        )}
        {versions.map((version, idx) => (
          <ProposalHistoryVersionItemContainer
            key={version}
            deckId={version}
            folderId={folderId}
            versionNumber={getVersionNumber(versions, version)}
          />
        ))}
        <FormFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
            Close
          </Button>
        </FormFooter>
      </WindowPanelContent>
    </WindowPanel>
  );
}
