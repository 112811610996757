/* eslint-disable class-methods-use-this */

/*
  https://remirror.io/docs/concepts/extension

  Inspiration from https://prosemirror.net/examples/dino/ &
   https://github.com/remirror/remirror/blob/main/packages/remirror__extension-mention-atom/src/mention-atom-extension.ts
*/

import 'core-js/features/reflect';

import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  Handler,
  isElementDomNode,
  NodeExtension,
  NodeExtensionSpec,
  NodeWithPosition,
  omitExtraAttributes,
  ProsemirrorAttributes,
  replaceText,
} from '@remirror/core';
import { RangeWithCursor } from '@remirror/pm/suggest';

const dataAttributeId = 'data-variable-atom-id';
const dataAttributeName = 'data-variable-atom-name';

export interface VariableAtomOptions {
  onChange?: Handler<VariableAtomChangeHandler>;
  onClick?: Handler<(event: MouseEvent, nodeWithPosition: NodeWithPosition) => boolean | undefined | void>;
}
@extension<VariableAtomOptions>({
  handlerKeyOptions: { onClick: { earlyReturnValue: true } },
  handlerKeys: ['onChange', 'onClick'],
  defaultOptions: {},
  staticKeys: [],
  customHandlerKeys: [],
})
export class VariableAtomExtension extends NodeExtension<VariableAtomOptions> {
  get name() {
    return 'VariableAtom' as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.Behavior];
  }

  createNodeSpec(extra: ApplySchemaAttributes): NodeExtensionSpec {
    return {
      attrs: {
        ...extra.defaults(),
        id: {},
        label: {},
        name: {},
      },
      selectable: true,
      inline: true,
      group: 'inline',
      draggable: true,
      atom: true,

      parseDOM: [
        {
          tag: `span[${dataAttributeId}]`,
          getAttrs: (node: string | Node) => {
            if (!isElementDomNode(node)) {
              return false;
            }

            const id = node.getAttribute(dataAttributeId);
            const name = node.getAttribute(dataAttributeName);
            const label = node.textContent;
            return { ...extra.parse(node), id, label, name };
          },
        },
      ],
      toDOM: (node) => {
        const { label, id } = omitExtraAttributes(node.attrs, extra) as NamedVariableAtomNodeAttributes;
        const attrs = {
          ...extra.dom(node),
          class: `rich-text-variable-atom`,
          [dataAttributeId]: id,
          [dataAttributeName]: label,
        };

        return ['span', attrs, label];
      },
    };
  }

  @command()
  createVariableAtom(details: CreateVariableAtom, attrs: VariableAtomNodeAttributes): CommandFunction {
    const { name, range } = details;
    const { ...rest } = attrs;

    return replaceText({
      type: this.type,
      appendText: '',
      attrs: { name, ...rest },
      range,
    });
  }
}

export interface CreateVariableAtom {
  name: string;
  range: RangeWithCursor;
}

export type VariableAtomNodeAttributes = ProsemirrorAttributes<{
  id: string;
  label: string;
}>;

export type NamedVariableAtomNodeAttributes = VariableAtomNodeAttributes & {
  name: string;
};

/**
 * This change handler is called whenever there is an update in the matching
 * suggester. The second parameter `command` is available to automatically
 * create the mention with the required attributes.
 */
export type VariableAtomChangeHandler = (command: (attrs: VariableAtomNodeAttributes) => void) => void;
