import React, { useEffect } from 'react';
import { Button, BUTTON_STYLES, Space, Icon, ICON_SIZES, LoadingBox, Text, TEXT_BOLDNESS } from '@premagic/myne';

type Props = {
  getPendingActions: () => void;
  isLoading: boolean;
  totalActions: number;
};

export default function PendingPhotographerActions(props: Props) {
  const { getPendingActions, isLoading, totalActions } = props;
  useEffect(() => {
    getPendingActions();
  }, []);

  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.BOLD}>Requests from client</Text>
      <LoadingBox isLoading={isLoading} align="left">
        {totalActions === 0 ? (
          <div>
            <Space vertical size={2} />
            <Text muted>All good! There are no pending request from your clients</Text>
          </div>
        ) : (
          <div>
            <Space vertical size={2} />
            <Text>
              {totalActions} requests from clients{' '}
              <Button link="/projects" style={BUTTON_STYLES.LINK}>
                View all <Icon size={ICON_SIZES.SM} name="chevron_right" />
              </Button>
            </Text>
          </div>
        )}
      </LoadingBox>
    </div>
  );
}
