import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { editQRCodeDesigns, qrCodeDesignsSelector } from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeIllustrationFiles from './QRCodeIllustrationFiles';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;

  return {
    projectId,
    folderId,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelect: (projectId, folderId, qrCodeDesigns, customIllustrationId) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION]: customIllustrationId,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeIllustrationFiles);
