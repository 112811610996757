import * as React from 'react';
import ClassNames from 'classnames';
import { Row, Space } from '../Grid/Grid';

import styles from './loading.module.css';

export function Loading(): React.ReactElement {
  return <div>Loading...</div>;
}

interface LoadingSpinProps {
  align?: 'left' | 'center';
}

export function LoadingSpin(props: LoadingSpinProps): React.ReactElement {
  const { align = 'center' } = props;
  return (
    <div className={ClassNames(styles.loader, styles[`loader--align-${align}`])}>
      <svg className={styles.circular} viewBox="25 25 50 50">
        <circle className={styles.path} cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  );
}

interface LoadingDotsProps {
  align?: 'left' | 'center';
  size?: 'xs' | 'sm' | 'md';
  fromTheme?: boolean;
}
const LOADING_DOT_SIZE_DETAILS = {
  xs: {
    radius: 4,
    cy: 2 * 4,
    circle: {
      2: { space: 16 },
      3: { space: 26 },
    },
  },
  sm: {
    radius: 4,
    cy: 4 * 4,
    circle: {
      2: { space: 14 },
      3: { space: 22 },
    },
  },
  md: {
    radius: 6,
    cy: 6 * 2,
    circle: {
      2: { space: 14 },
      3: { space: 22 },
    },
  },
};
// 8 + 8 + 8 = 24
export function LoadingDots(props: LoadingDotsProps): React.ReactElement {
  const { align = 'center', size = 'md', fromTheme } = props;
  const { radius, cy, circle } = LOADING_DOT_SIZE_DETAILS[size];

  const classes = ClassNames(
    styles['loader-dot'],
    styles[`loader-dot--align-${align}`],
    styles[`loader-dot--size-${size}`],
  );

  const loaderDotIconClasses = ClassNames(styles.loader__dot, {
    [styles['from-theme']]: fromTheme,
  });

  return (
    <div className={classes}>
      <svg viewBox="0 0 52 24">
        <circle className={loaderDotIconClasses} stroke="none" cx={radius} cy={cy} r={radius}>
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle className={loaderDotIconClasses} stroke="none" cx={radius * 2 + circle[2].space} cy={cy} r={radius}>
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle className={loaderDotIconClasses} stroke="none" cx={radius * 4 + circle[3].space} cy={cy} r={radius}>
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </circle>
      </svg>
    </div>
  );
}

interface LoadingBoxProps {
  isLoading: boolean;
  children?: any;
  align?: 'left' | 'center';
}

export function LoadingBox(props: LoadingBoxProps): any {
  const { isLoading, children, align = 'center' } = props;

  if (isLoading)
    return (
      <Row columnDirection>
        <Space size={10} vertical />
        <LoadingDots align={align} />
        <Space size={10} vertical />
      </Row>
    );
  if (children) return children;
  return null;
}

export function LoadingUpload(): React.ReactElement {
  return (
    <svg viewBox="0 0 24 24" className={styles['loading-upload']}>
      <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" className={styles['loading-upload__cloud']} />
      <path d="M16 16l-4-4-4 4M12 12v9" className={styles['loading-upload__arrow']} />
    </svg>
  );
}

export enum PROGRESS_BAR_STYLES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  DANGER = 'danger',
}

export enum PROGRESS_BAR_HEIGHT {
  SIZE_1 = '1',
  SIZE_2 = '2',
}

interface ProgressBarProps {
  progress: number;
  style?: PROGRESS_BAR_STYLES;
  height?: PROGRESS_BAR_HEIGHT;
}

export function ProgressBar(props: ProgressBarProps): React.ReactElement {
  const { progress, style = PROGRESS_BAR_STYLES.DEFAULT, height = PROGRESS_BAR_HEIGHT.SIZE_1 } = props;

  return (
    <div
      className={ClassNames(
        styles['progress-bar'],
        styles[`progress-bar--${style}`],
        styles[`progress-bar--height-${height}`],
      )}
    >
      <div
        className={ClassNames(styles['progress-bar__bar'], [styles[`progress-bar__bar--${style}`]])}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
}

export enum LOADING_PULSE_STYLES {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

interface LoadingPulseProps {
  style?: LOADING_PULSE_STYLES;
}

export function LoadingPulse(props: LoadingPulseProps): React.ReactElement {
  const { style = LOADING_PULSE_STYLES.SUCCESS } = props;

  return <div className={ClassNames(styles['loading-pulse'], [styles[`loading-pulse--style-${style}`]])} />;
}

interface ProgressCircleProps {
  progress: number;
}

export function ProgressCircle(props: ProgressCircleProps): React.ReactElement {
  const { progress } = props;
  const circleRadius = 40;
  const circumference = 2 * Math.PI * circleRadius;

  const progressValue = circumference - progress * circumference;
  const isDone = progress >= 1;
  return (
    <div className={ClassNames(styles['progress-circle'])}>
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="40" className={ClassNames(styles['progress-circle__circle'])} />
        <circle
          cx="50"
          cy="50"
          r="40"
          className={ClassNames(styles['progress-circle__circle'], styles['progress-circle__circle--main'], {
            [styles['progress-circle__circle--done']]: isDone,
          })}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressValue}
        />
        <path
          d="M 30 55 L 45 70 L 70 30"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={ClassNames(styles['progress-circle__check'], {
            [styles['progress-circle__check--show']]: isDone,
          })}
        />
      </svg>
    </div>
  );
}

export function LoadingCardPlaceholder(): React.ReactElement {
  return (
    <div className={styles['card-placeholder']}>
      <div className={styles['card-rect']} />
      <div className={styles['text-rect']} />
    </div>
  );
}

interface MusicPlayingLoaderProps {
  play: boolean;
}

export function MusicPlayingLoader(props: MusicPlayingLoaderProps): React.ReactElement {
  const { play } = props;
  return (
    <ul
      className={ClassNames(styles['music-playing-loader'], {
        [styles['music-playing-loader--play']]: play,
      })}
    >
      {[1, 2, 3, 4].map((i) => (
        <li
          key={i}
          className={ClassNames(styles['music-playing-loader__item'])}
          style={{ ['--line-index' as string]: i }}
        />
      ))}
    </ul>
  );
}
