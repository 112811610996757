/* eslint-disable no-bitwise */
// https://bgrins.github.io/TinyColor/docs/tinycolor.html
// https://www.npmjs.com/package/extract-colors?activeTab=readme
// we can use color2k as its used in text-color extention in remirror
import {
  darken,
  getLuminance,
  hasBadContrast,
  hsla,
  lighten,
  mix,
  parseToHsla,
  parseToRgba,
  readableColor,
  toHex,
  transparentize,
} from 'color2k';
import { extractColors, extractColorsFromImage } from 'extract-colors';
import { logError } from './ErrorTracker';

const HEX_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];

export function lightenDarkenColor(colorCode: string, amount: number): string {
  if (!colorCode) return '';
  const colorCodeNumber = colorCode.slice(1);
  const num = parseInt(colorCodeNumber, 16);

  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
}

export function colorShade(col, amt) {
  let newColour = col.replace(/^#/, '');
  if (newColour.length === 3)
    newColour = newColour[0] + newColour[0] + newColour[1] + newColour[1] + newColour[2] + newColour[2];

  let [r, g, b] = newColour.match(/.{2}/g);
  [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
}

export function getContrastColor({
  bgColor = '#FFFFFF',
  defaultColor = '#000000',
  threshold = 128, // Max - 256
  lightnessThreshold = 0.7,
} = {}) {
  // Handle very light color || This was breaking when the primary color is white and its trying to create circle for registration
  // const luminance = getLuminance(bgColor);
  // if (luminance > lightnessThreshold) {
  //   return darken(bgColor, 0.8);
  // }

  let bgColorArray = String(bgColor)
    .toUpperCase()
    .split('')
    .filter((c) => HEX_CHARS.includes(c));

  switch (bgColorArray.length) {
    case 3:
    case 4:
      // 3 e.g. #FFF
      // 4 e.g. #1234 <- (3hex + alpha-channel)
      bgColorArray = bgColorArray.slice(0, 3).map((c) => `${c}${c}`);
      break;
    case 6:
    case 8:
      // 6 e.g. #789ABC <- ideal
      // 8 e.g. #789ABC00 <- (6hex + alpha-channel)
      // @ts-ignore
      bgColorArray = bgColorArray
        .slice(0, 6)
        // @ts-ignore
        .reduce((acc, curr, n, arr) => (n % 2 ? [...acc, `${arr[n - 1]}${curr}`] : acc), []);
      break;
    default:
      // Invalid bgColor value, so you get the default
      return defaultColor;
  }

  const [r, g, b] = bgColorArray.map((h) => parseInt(h, 16));
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= threshold ? colorShade(bgColor, -threshold) : colorShade(bgColor, threshold);
}

export function createNewColorBasedOnBackgroundAndColor(options: {
  color: string;
  bgColor: string;
  weight: number;
}): string {
  const { color, bgColor, weight } = options;
  const newColor = mix(bgColor, color, weight);
  // Ensure the new color contrasts well with the background color
  /*
  newColor = getContrastColor({
    bgColor: newColor,
    defaultColor: bgColor,
    threshold: 128, // You can adjust this value as needed
    lightnessThreshold: 0.7, // You can adjust this value as needed
  });
*/

  return newColor;
}

export function convertToHex(color): string {
  return toHex(color);
}

export function lightenColorWithConstantAmount(color: string, amount: number): string {
  const hslaColors = parseToHsla(color);
  const lightenedColor = hslaColors.map((item, i) => {
    if (i == 2) {
      return amount;
    }
    return item;
  });
  const hslaString = hsla(lightenedColor[0], lightenedColor[1], lightenedColor[2], lightenedColor[3]);
  return convertToHex(hslaString);
}

// amount: The amount to increase the transparency by, given as a decimal between 0 and 1
export function changeOpactiy(color: string, amount: number): string {
  return transparentize(color, amount);
}

// function getRgbFromHex() {
//   return { r: mathRound(this._r), g: mathRound(this._g), b: mathRound(this._b), a: this._a };
// }
//
// function getLuminance(color: string): number {
//   const rgb = this.toRgb();
//   let RsRGB;
//   let GsRGB;
//   let BsRGB;
//   let R;
//   let G;
//   let B;
//   RsRGB = rgb.r / 255;
//   GsRGB = rgb.g / 255;
//   BsRGB = rgb.b / 255;
//
//   if (RsRGB <= 0.03928) {
//     R = RsRGB / 12.92;
//   } else {
//     R = ((RsRGB + 0.055) / 1.055) ** 2.4;
//   }
//   if (GsRGB <= 0.03928) {
//     G = GsRGB / 12.92;
//   } else {
//     G = ((GsRGB + 0.055) / 1.055) ** 2.4;
//   }
//   if (BsRGB <= 0.03928) {
//     B = BsRGB / 12.92;
//   } else {
//     B = ((BsRGB + 0.055) / 1.055) ** 2.4;
//   }
//   return 0.2126 * R + 0.7152 * G + 0.0722 * B;
// }
//
// export function isReadability(color1: string, color2: string): boolean {
//   const c1 = tinycolor(color1);
//   const c2 = tinycolor(color2);
//   return (
//     (Math.max(c1.getLuminance(), c2.getLuminance()) + 0.05) / (Math.min(c1.getLuminance(), c2.getLuminance()) + 0.05)
//   );
// }
//
// function getMostReadable(baseColor, colorList, args) {
//   let bestColor = null;
//   let bestScore = 0;
//   let readability;
//   let includeFallbackColors;
//   let level;
//   let size;
//   args = args || {};
//   includeFallbackColors = args.includeFallbackColors;
//   level = args.level;
//   size = args.size;
//
//   for (let i = 0; i < colorList.length; i++) {
//     readability = tinycolor.readability(baseColor, colorList[i]);
//     if (readability > bestScore) {
//       bestScore = readability;
//       bestColor = tinycolor(colorList[i]);
//     }
//   }
//
//   if (tinycolor.isReadable(baseColor, bestColor, { level, size }) || !includeFallbackColors) {
//     return bestColor;
//   }
//   args.includeFallbackColors = false;
//   return tinycolor.mostReadable(baseColor, ['#fff', '#000'], args);
// }

// https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/radial-gradient#formal_syntax
type GenerateRadialGradientOptions = {
  sizeX: number;
  sizeY: number;
  x: string;
  y: string;
  colorStops: Array<{
    color: string;
    stop: number;
  }>;
};
export function generateRadialGradient(options: GenerateRadialGradientOptions): string {
  const { sizeX, sizeY, x, y, colorStops } = options;

  return `radial-gradient(${sizeX}px ${sizeY}px at ${x} ${y}, ${colorStops
    .map((colorStop) => {
      const { color, stop } = colorStop;
      return `${color} ${stop * 100}%`;
    })
    .join(', ')})`;
}

export function generateMultipleRadialGradient(items: Array<GenerateRadialGradientOptions>): string {
  return items.map((item) => generateRadialGradient(item)).join(', ');
}

export function colorExtractFromImage(imageSrc: string): Promise<void | Array<{
  hex: string;
  red: number;
  green: number;
  blue: number;
  hue: number;
  intensity: number;
  lightness: number;
  saturation: number;
  area: number;
}>> {
  return extractColors(imageSrc, {
    crossOrigin: 'anonymous',
    // saturationDistance: 0.3,
    // lightnessDistance: 0.3,
    // hueDistance: 0.2,
  })
    .then((colors) => colors)
    .catch((e) => logError('Color extraction failed', e));
}

// color array sorted by the area the color covers  (excludes the white (#fff) color as it doesn't pop on white background)
export function getDominantColorsOfImage(
  imageSrc: string,
  excludeWhite = true,
): Promise<void | Array<{
  hex: string;
  red: number;
  green: number;
  blue: number;
  hue: number;
  intensity: number;
  lightness: number;
  saturation: number;
  area: number;
}>> {
  return colorExtractFromImage(imageSrc).then((colors) => {
    if (colors) {
      if (excludeWhite) {
        return colors.filter((item) => item.lightness <= 0.9).sort((a, b) => b.area - a.area);
      }
      return colors.sort((a, b) => b.area - a.area);
    }
    return colors;
  });
}
export type DominantFinalColor = {
  hex: string;
  red: number;
  green: number;
  blue: number;
  hue: number;
  intensity: number;
  lightness: number;
  saturation: number;
  area: number;
};
export function getDominantColorsOfHtmlImageElement(
  imageElement: HTMLImageElement,
): Promise<Array<DominantFinalColor>> {
  return extractColorsFromImage(imageElement as HTMLImageElement, { crossOrigin: 'anonymous' })
    .then((colors) => {
      const arraySortedByDominantColors = colors.sort((a, b) => b.area - a.area);
      return arraySortedByDominantColors;
    })
    .catch((e) => {
      logError('Failed to extract colors', e);
      return [];
    });
}

export function mixColors(color1: string, color2: string, weight: number): string {
  return mix(color1, color2, weight);
}

export function hasBadContrastFrombackground(
  color: string,
  standard: 'decorative' | 'readable' | 'aa' | 'aaa' = 'aa',
  background: string,
): boolean {
  return hasBadContrast(color, standard, background);
}

export function getRgbaFromColor(color: string): [number, number, number, number] {
  return parseToRgba(color);
}

// amount from 0-1
export function darkenColor(color: string, amount: number) {
  return darken(color, amount);
}

// amount from 0-1
export function lightenColor(color: string, amount: number) {
  return lighten(color, amount);
}

// returns #fff or #000
export function readableColorBlackOrWhite(color: string): string {
  return readableColor(color);
}

export function getColorLuminance(color: string): number {
  return getLuminance(color);
}
export function getIconColorToUseBasedOnBackground(options: { color: string }): 'black' | 'white' {
  const { color } = options;
  const contrastColor = getContrastColor({
    bgColor: color,
    threshold: 200,
  });

  const colorLuminance = getColorLuminance(contrastColor);
  if (colorLuminance > 0.5) {
    return 'white';
  }
  return 'black';
}
