import * as React from 'react';
import ClassNames from 'classnames';
import { NumberUtils } from '@premagic/utils';

import { Link } from '../Link/Link';
import { Space } from '../Grid/Grid';

import styles from './workflow-stages.module.css';

interface WorkflowStagesProps {
  children: React.ReactNode;
}

export function WorkflowStages(props: WorkflowStagesProps): JSX.Element {
  const { children } = props;
  const classes = ClassNames(styles['workflow-stages']);

  return <div className={classes}>{children}</div>;
}

interface WorkflowStageItemProps {
  title: string;
  isLoadingCounts?: boolean;
  count: number;
  active?: boolean;
  to: string;
  className?: string;
}

export function WorkflowStageItem(props: WorkflowStageItemProps): JSX.Element {
  const { title, count, active, to, className, isLoadingCounts, ...elementProps } = props;
  const classes = ClassNames(className, styles['workflow-stage-item'], {
    [styles['workflow-stage-item--active']]: active,
  });

  return (
    <Link className={classes} to={to} {...elementProps}>
      {count > -1 &&
        (isLoadingCounts ? (
          <div className={styles['workflow-stage-item__count']}>
            <Space size={2} />
          </div>
        ) : (
          <div className={styles['workflow-stage-item__count']}>{NumberUtils.getNumberInFormat(count)}</div>
        ))}
      <div className={styles['workflow-stage-item__title']}>{title}</div>
      <div className={styles['workflow-stage-item-triangle']} />
    </Link>
  );
}
