import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils, i18n } from '@premagic/utils';
import { ClientSettingsService, EventTrackerService, IntegrationService, RouterService } from '@premagic/core';

import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { toastMessage } from '../../../reducers/ToastStore';

import { entitiesDataSelector } from '../../../reducers/selectors';
import { saveNotificationsSettingsService } from '../notifications/NotificationService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CLIENT_SETTINGS');

export const setClientSettingsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

// export const getClientSettings = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
//   const loadingKey = LOADINGS.CLIENT_SETTINGS.FETCH_ALL;
//   dispatch(toggleLoadingState(dispatch, loadingKey, true));
//
//   ClientSettingsService.fetchClientSettings()
//     .then((response) => {
//       dispatch(toggleLoadingState(dispatch, loadingKey, false));
//       dispatch(setClientSettingsData(dispatch, response));
//       return response;
//     })
//     .catch((e) => {
//       dispatch(toggleLoadingState(dispatch, loadingKey, false));
//       return e;
//     });
// });

export const saveClientSettingsData = createAction(
  getActionType('DATA', 'SAVE'),
  (
    dispatch,
    data,
    options: {
      navigateTo?: string;
    },
  ) => {
    const loadingKey = LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS;
    const { data: formData } = data;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SETTING.UPDATE_FOLDER_SETTINGS, formData);

    ClientSettingsService.saveClientSettingsService(formData)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setClientSettingsData(dispatch, response));
        if (options?.navigateTo) {
          RouterService.navigateTo(dispatch, options.navigateTo);
        } else {
          toastMessage('success', 'Your preference has been saved!');
        }
        return response;
      })
      .catch((e) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setErrorState(dispatch, loadingKey, e));
        toastMessage('error', e.message);
        return e;
      });
  },
);

export const saveClientNotificationData = createAction(getActionType('DATA', 'SAVE'), (dispatch, data) => {
  const loadingKey = LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS;
  const { data: formData } = data;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SETTING.UPDATE_NOTIFICATIONS, formData);

  saveNotificationsSettingsService(formData)
    .then((response) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setClientSettingsData(dispatch, response));
      toastMessage('success', 'Your preference has been saved!');
      return response;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setErrorState(dispatch, loadingKey, e));
      toastMessage('error', e.message);
      return e;
    });
});

type StateType = {
  data: ClientSettingsService.ClientSettingsType;
};

const initialState = {
  data: {},
};

export default handleActions(
  {
    [setClientSettingsData.toString()]: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
  initialState,
);

export const clientSettingsDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => (entities.clientSettings as StateType).data,
);

export const notificationSettingSelector = createSelector(clientSettingsDataSelector, (state) => state.notifications);
export const enableSelectionFavoriteSelector = createSelector(
  clientSettingsDataSelector,
  (state) => state.enable_selection_favorite,
);

export const isFeatureLeadEnabledSelector = createSelector(
  clientSettingsDataSelector,
  (state) => !state.hide_enquire_form,
);
export const isSelectionAlbumEnabledSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  'enable_selection_album' in clientSetting ? (clientSetting.enable_selection_album as boolean) : true,
);
export const isFeatureFinancialsEnabledSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE in clientSetting
    ? (clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_FINANCIALS_ENABLE] as boolean)
    : true,
);
export const isFeatureEventsOccasionsEnabledSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_EVENTS_OCCASIONS_ENABLE in clientSetting
    ? (clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_EVENTS_OCCASIONS_ENABLE] as boolean)
    : true,
);
export const isFeatureProposalEnabledSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROPOSAL_ENABLE in clientSetting
    ? (clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROPOSAL_ENABLE] as boolean)
    : true,
);
export const isFeatureProjectsTabEnabledSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROJECTS_TAB_ENABLE in clientSetting
    ? (clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROJECTS_TAB_ENABLE] as boolean)
    : true,
);

export const isFeatureLeadsModuleEnabledSelector = createSelector(
  clientSettingsDataSelector,
  (clientSetting) => !clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_LEADS_DISABLE] as boolean,
);
export const isEmailHiddenOnRegistrationFormSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  ClientSettingsService.CLIENT_SETTINGS_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD in clientSetting
    ? (clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD] as boolean)
    : false,
);

export const showEventFeedbackFormSelector = createSelector(
  clientSettingsDataSelector,
  (clientSetting) =>
    clientSetting?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SHOW_EVENT_FEEDBACK_FORM] || false,
);

export const enableReportingIssueFromPeopleSelector = createSelector(clientSettingsDataSelector, (clientSetting) => {
  const data = clientSetting?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_REPORTING_ISSUE_FROM_PEOPLE];
  // enable it by default
  return data === undefined || data;
});

export const enableReportingTheseAreNotMyPhotosSelector = createSelector(
  clientSettingsDataSelector,
  (clientSetting) =>
    clientSetting?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_REPORTING_THESE_ARE_NOT_MY_PHOTOS] || false,
);

export const isWatermarkOnClientInvoiceShownSelector = createSelector(
  clientSettingsDataSelector,
  (state) => !!state.show_watermark_on_client_invoice,
);
export const isFeatureDAMLibraryEnabledSelector = createSelector(clientSettingsDataSelector, (clientSetting) =>
  ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_DAM_LIBRARY_ENABLE in clientSetting
    ? (clientSetting[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_DAM_LIBRARY_ENABLE] as boolean)
    : false,
);

export const isAccountSupportAccessEnabled = createSelector(
  clientSettingsDataSelector,
  (state) => state.support_access_to_account,
);

export const isWatermarkBetaEnabledSelector = createSelector(
  clientSettingsDataSelector,
  (state) => state.enable_beta_watermark,
);

export const companyTypeSelector = createSelector(
  clientSettingsDataSelector,
  (state) => state.company_type || ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY,
);

export const isCompanyTypeNotPhotographerSelector = createSelector(clientSettingsDataSelector, (state) =>
  ClientSettingsService.isCompanyTypeNotPhotographer(state),
);

export const storePricingDataSelector = createSelector(clientSettingsDataSelector, (state) => state.store_pricing);

export const accountDefaultLanguageSelector = createSelector(
  clientSettingsDataSelector,
  (clientSetting) =>
    clientSetting?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DEFAULT_LANG] || i18n.LANGUAGE_CODE.EN,
);

export const integrationsEventClientSettingsSelector = createSelector(
  clientSettingsDataSelector,
  (clientSetting) =>
    clientSetting?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.INTEGRATIONS] ||
    ({} as IntegrationService.IntegrationClientSettingsType),
);
