import { createSelector } from 'reselect';

export interface EntitiesStateType {
  account: unknown;
  pricingPlans: unknown;
  subscriptions: unknown;
  company: unknown;
  companyStats: unknown;
  leads: unknown;
  projects: unknown;
  projectsMetaData: unknown;
  projectsStats: unknown;
  projectsBackgroundTasks: unknown;
  projectsNerdStats: unknown;
  foldersMetaData: unknown;
  projectOrders: unknown;
  folders: unknown;
  files: unknown;
  albums: unknown;
  clients: unknown;
  crm: {
    clients: unknown;
    users: unknown;
    events: unknown;
    occasions: unknown;
    payments: unknown;
    categories: unknown;
    labels: unknown;
    workflows: {
      workflow: unknown;
      taskgroup: unknown;
      tasks: unknown;
    };
  };
  clientSettings: unknown;
  photographerActions: unknown;
  clientWebsite: unknown;
  clientWebsiteFiles: unknown;
  clientWish: unknown;
  filesOrder: unknown;
  portfolio: unknown;
  customEventFormFields: unknown;
  peopleFaces: unknown;
  integration: unknown;
}

export interface PagesStateType {
  eventDetails: unknown;
  folders: unknown;
  albumCreator: {
    data: {
      album: unknown;
      pages: unknown;
      images: unknown;
    };
    album: unknown;
  };
  proposal: {
    data: {
      proposals: unknown;
      variables: unknown;
      slides: unknown;
      decks: unknown;
      files: unknown;
    };
    creator: unknown;
    templates: unknown;
  };
  calendarAndEvents: {
    data: {
      occasions: unknown;
      events: unknown;
    };
  };
  accountFinancials: {
    data: {
      payments: unknown;
    };
  };
  invoice: {
    data: {
      invoice: unknown;
    };
  };
  folderShare: unknown;
  fonts: unknown;
  store: {
    data: {
      storeOffers: unknown;
    };
  };
  clientWebsite: {
    data: {
      itinerary: unknown;
    };
    clientWebsite: unknown;
  };
  projectsList: unknown;
  eventSponsor: {
    data: {
      sponsors: unknown;
      creatives: unknown;
    };
    eventSponsor: unknown;
    category: unknown;
  };
  eventExhibitor: {
    data: {
      exhibitors: unknown;
    };
    eventExhibitor: unknown;
  };
  eventReport: {
    data: {
      posterAdvocates: unknown;
    };
    eventReport: unknown;
  };
  registrationForm: {
    registrationForm: unknown;
  };
  eventAttendees: {
    data: {
      attendees: unknown;
      checkinZones: unknown;
    };
    eventAttendees: unknown;
    badgeDesign: unknown;
  };
  eventPoster: {
    creator: unknown;
    data: {
      posters: unknown;
    };
  };
  productReview: {
    data: {
      reportIssue: unknown;
      eventFeedback: unknown;
    };
  };
  avatarStudio: {
    data: {
      memoryTemplates: unknown;
      memories: unknown;
    };
  };
  DAMLibrary: {
    data: {
      DAMData: unknown;
    };
  };
}

export interface CommonStateType {
  alert: unknown;
  loading: unknown;
  fileUploader: unknown;
  modal: unknown;
  windowPanel: unknown;
  errors: unknown;
  auth: unknown;
}

interface StateType {
  entities: EntitiesStateType;
  pages: PagesStateType;
  common: CommonStateType;
  app: unknown;
  appAlerts: unknown;
}

export const entitiesDataSelector = (state: StateType) => state.entities;

export const appAlertsRootSelector = (state: StateType) => state.appAlerts;

export const pagesSelector = (state: StateType) => state.pages;

export const commonUISelector = (state: StateType) => state.common;

export const calendarAndEventsPageSelector = createSelector(pagesSelector, (pages) => pages.calendarAndEvents);
export const accountFinancialsPageSelector = createSelector(pagesSelector, (pages) => pages.accountFinancials);
