import React from 'react';
import { History } from 'history';
import { Router, Route, Switch } from 'react-router-dom';

import CompanySettingPageContainer from '../components/settings/company/CompanySettingPageContainer';
import EventMarketingSettingsPageContainer from '../components/settings/event-marketing/EventMarketingSettingsPageContainer';
import ExperimentsPreferencePageContainer from '../components/settings/experiments-settings/ExperimentsPreferencePageContainer';
import InvoicePageContainer from '../components/settings/invoices/InvoicePageContainer';
import SettingPageContainer from '../components/settings/SettingsPageContainer';
import SignatureAlbumPreferencePageContainer from '../components/settings/signature-selection-preference/SignatureAlbumPreferencePageContainer';
import SelectionFolderPreferencePageContainer from '../components/settings/signature-selection-preference/SelectionFolderPreferencePageContainer';
import SubscriptionsPageContainer from '../components/settings/subscriptions/SubscriptionsPageContainer';
import UsersPageContainer from '../components/crm/users/UsersPageContainer';
import APP_URLS from '../services/AppRouteURLService';
import AccountSettingPageContainer from '../components/settings/account/AccountSettingPageContainer';
import NotificationSettingsPageContainer from '../components/settings/notifications/NotificationSettingsPageContainer';
import CompanyBrandingPageContainer from '../components/settings/branding/CompanyBrandingPageContainer';
import ProposalTemplatePageContainer from '../components/proposal-management/proposal-templates/ProposalTemplatePagesContainer';
import CompanyEnquiryFormPageContainer from '../components/settings/leads-module/enquiry-form/CompanyEnquiryFormPageContainer';
import GlobalSignatureAlbumCoverPageContainer from '../components/settings/signature-album-design/GlobalSignatureAlbumDesignPageContainer';
import WorkflowPageContainer from '../components/crm/workflow/WorkflowPageContainer';
import WorkflowDetailsPageContainer from '../components/crm/workflow/details/WorkflowDetailsPageContainer';
import BrandingDetailsPageContainer from '../components/settings/branding/branding-details/BrandingDetailsPageContainer';
import StorePricingPageContainer from '../components/settings/store-pricing/StorePricingPageContainer';
import CompanyFinancialSettingsPageContainer from '../components/settings/financials/EventFinancialSettingsPageContainer';
import LeadsModuleSettingsPageContainer from '../components/settings/leads-module/LeadsModuleSettingsPageContainer';
import EventsSettingsPageContainer from '../components/settings/event-settings/EventsSettingsPageContainer';
import ProposalSettingsPageContainer from '../components/settings/proposal/ProposalSettingsPageContainer';
import DAMLibrarySettingsPageContainer from '../components/settings/dam-library-settings/DAMLibrarySettingsPageContainer';
import ExperimentsPreference2PageContainer from '../components/settings/experiments-settings/ExperimentsPreference2PageContainer';
import PhotoDistributionSettingsPageContainer from '../components/settings/photo-distribution-settings/PhotoDistributionSettingsPageContainer';

type Props = {
  history: History;
};

export default function SettingsRoutes(props: Props): JSX.Element {
  const { history } = props;
  return (
    <Router history={history}>
      <Switch>
        <Route path={APP_URLS.SETTINGS.INDEX} exact component={SettingPageContainer} />
        <Route path={APP_URLS.SETTINGS.SUBSCRIPTIONS} component={SubscriptionsPageContainer} />
        <Route path={APP_URLS.SETTINGS.PROFILE.ROOT} component={CompanySettingPageContainer} />
        <Route path={APP_URLS.SETTINGS.ENQUIRY_FORM} component={CompanyEnquiryFormPageContainer} />
        <Route path={APP_URLS.SETTINGS.ACCOUNT.ROOT} component={AccountSettingPageContainer} />
        <Route path={APP_URLS.SETTINGS.INVOICE} component={InvoicePageContainer} />
        <Route path={APP_URLS.SETTINGS_USERS.USERS} component={UsersPageContainer} />

        {/* Branding */}
        <Route path={APP_URLS.SETTINGS.BRANDING.DETAILS} component={BrandingDetailsPageContainer} />
        <Route path={APP_URLS.SETTINGS.BRANDING.HOME} component={CompanyBrandingPageContainer} />

        {/* Workflow */}
        <Route path={APP_URLS.SETTINGS_WORKFLOW.EDIT} component={WorkflowDetailsPageContainer} />
        <Route path={APP_URLS.SETTINGS_WORKFLOW.WORKFLOW} exact component={WorkflowPageContainer} />

        {/* Proposal */}
        <Route path={APP_URLS.PROPOSALS_TEMPLATES.LIST} component={ProposalTemplatePageContainer} />
        <Route path={APP_URLS.PROPOSALS.SETTINGS} component={ProposalSettingsPageContainer} />

        {/* Store pricing */}
        <Route path={APP_URLS.SETTINGS.STORE_PRICING.ROOT} component={StorePricingPageContainer} />

        <Route path={APP_URLS.SETTINGS.SIGNATURE} component={SignatureAlbumPreferencePageContainer} />
        <Route path={APP_URLS.SETTINGS.SELECTION} component={SelectionFolderPreferencePageContainer} />
        <Route path={APP_URLS.SETTINGS.PHOTO_DISTRIBUTION} component={PhotoDistributionSettingsPageContainer} />
        <Route path={APP_URLS.SETTINGS.EVENT_MARKETING.ROOT} component={EventMarketingSettingsPageContainer} />
        <Route path={APP_URLS.SETTINGS.SIGNATURE_ALBUM_DESIGN} component={GlobalSignatureAlbumCoverPageContainer} />
        <Route path={APP_URLS.SETTINGS.NOTIFICATIONS} component={NotificationSettingsPageContainer} />
        <Route path={APP_URLS.SETTINGS.EXPERIMENTAL} component={ExperimentsPreferencePageContainer} />
        <Route path={APP_URLS.SETTINGS.EXPERIMENTAL_2} component={ExperimentsPreference2PageContainer} />
        <Route path={APP_URLS.SETTINGS.FINANCIALS.ROOT} component={CompanyFinancialSettingsPageContainer} />
        <Route path={APP_URLS.SETTINGS.LEADS.ROOT} component={LeadsModuleSettingsPageContainer} />
        <Route path={APP_URLS.SETTINGS.EVENTS.ROOT} component={EventsSettingsPageContainer} />
        <Route path={APP_URLS.SETTINGS.DAM_LIBRARY.ROOT} component={DAMLibrarySettingsPageContainer} />
      </Switch>
    </Router>
  );
}
