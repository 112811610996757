import { getAllTimezones } from 'countries-and-timezones';
import { utcToZonedTime, zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';

export function guess(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

let CURRENT_TIMEZONE = guess();
export function setCurrentTimezone(tz: string): void {
  CURRENT_TIMEZONE = tz;
}

export function getCurrentTimezone(): string {
  return CURRENT_TIMEZONE || guess();
}

export type TimezoneDetailsType = {
  name: string;
  utcOffset: number;
  utcOffsetStr: string;
  dstOffset: number;
  dstOffsetStr: string;
  aliasOf: string | undefined;
  countries: Array<string>;
  deprecated?: boolean;
};

export function getAllTimezoneOptions(): Record<string, TimezoneDetailsType> {
  // https://github.com/manuelmhtr/countries-and-timezones#timezone
  return getAllTimezones({
    deprecated: true,
  });
}

export function getTzDateFromUTCDate(dateString: string, tz: string): Date {
  return utcToZonedTime(dateString, tz);
}

export function getUTCDateFromTzDate(dateString: string, tz: string): Date {
  // https://github.com/marnusw/date-fns-tz/issues/126
  return zonedTimeToUtc(new Date(dateString), tz);
}

export function getTzOffset(tz: string): number {
  return getTimezoneOffset(tz) / (1000 * 60 * 60);
}

export function isSameTimezone(tz: string): boolean {
  return getTzOffset(tz) === getTzOffset(getCurrentTimezone());
}

function toTimestamp(strDate) {
  const datum = Date.parse(strDate);
  return datum / 1000;
}
