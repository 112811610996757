import { BrowserUrlUtils, BrowserUtils, HttpUtils, MediaUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { isLocalApiURL } from './AppServices';
import { getSelfieBlob } from './EventAttendeesService';

export type PeopleFace = {
  face_id: string;
  bounding_box: {
    Top: number;
    Left: number;
    Width: number;
    Height: number;
  };
  asset_id: string;
  rekognition_asset_id: string;
  confidence: number;
  quality: number;
  face_asset_url: string;
};

function getQueryObject() {
  const queryString = BrowserUrlUtils.getLocationSearch();
  return (
    BrowserUtils.getQueryObject(queryString) || {
      preview: false,
    }
  );
}

export function fetchPeopleFacesForShareId(shareId: string): Promise<Array<PeopleFace>> {
  const queryObject = getQueryObject();

  return HttpUtils.get(
    API_URLS.PEOPLE_FACES.LIST_PUBLIC(shareId),
    {
      params: {
        preview: queryObject.preview,
      },
    },
    true,
    true,
  ).then(({ data }) => data.faces);
}

export function fetchPeopleFacesForProjectId(projectId: string): Promise<Array<PeopleFace>> {
  return HttpUtils.get(API_URLS.PEOPLE_FACES.LIST(projectId), true, true).then(({ data }) => data.faces);
}
export function uploadPeopleFace(shareId: string, formData): Promise<{ face_id: string }> {
  return HttpUtils.post(API_URLS.PEOPLE_FACES.LIST_PUBLIC(shareId), formData, true, true).then(({ data }) => data);
}

export function getFaceIdFromProject(projectId: string, formData): Promise<{ face_id: string }> {
  return HttpUtils.post(API_URLS.PEOPLE_FACES.LIST(projectId), formData, true, true).then(({ data }) => data);
}

export function getPeopleFaceUrl(faceId: string): string {
  // We are doing this coz mevin is lazy to do it on backend, Ideally it should come in dynamic_urls
  return MediaUtils.getDynamicImageURL(
    API_URLS.PEOPLE_FACES.FACE_URL(faceId),
    MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL,
    { width: 120 },
  );
}

export function getFaceDetailsForFile(shareId: string, fileId: string): Promise<{ faces: Array<PeopleFace> }> {
  return HttpUtils.get(API_URLS.PEOPLE_FACES.FILE_DETAILS(shareId, fileId), {}).then(({ data }) => data);
}

export function fetchFaceIdForExternalId(
  shareId: string,
  externalUserId: string,
  queryObject?: Record<string, string>,
): Promise<string> {
  return HttpUtils.get(
    API_URLS.FILES.FETCH_FOR_PEOPLE_FACE_EXTERNAL(shareId, externalUserId),
    { params: queryObject },
    true,
    true,
  )
    .then(({ data }) => data.face_id)
    .catch((error) => Promise.reject(error.data));
}

const scaleFactor = 0.5;
export function getRectFromFaceBounding(option: {
  boundingBox: {
    Top: number;
    Left: number;
    Width: number;
    Height: number;
  };
  containerWidth: number;
  containerHeight: number;
}): {
  left: number;
  top: number;
  // right: number;
  // bottom: number;
  width: number;
  height: number;
} {
  const { boundingBox, containerHeight, containerWidth } = option;

  const left = containerWidth * boundingBox.Left;
  const top = containerHeight * boundingBox.Top;
  const width = containerWidth * boundingBox.Width;
  const height = containerHeight * boundingBox.Height;

  const cX = left + width / 2.0;
  const cY = top + height / 2.0;
  const m = (Math.abs(width) + Math.abs(height)) / 2.0;

  return {
    left: Math.max(0, cX - scaleFactor * m),
    top: Math.max(0, cY - scaleFactor * m),
    width: Math.min(containerWidth, scaleFactor * m * 2),
    height: Math.min(containerHeight, scaleFactor * m * 2),
    // right: containerWidth - Math.min(containerWidth, cX + scaleFactor * m),
    // bottom: containerHeight - Math.min(containerHeight, cY + scaleFactor * m),
  };
}
