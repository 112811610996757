import { connect } from 'react-redux';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import ProjectPortfolioToggle from './ProjectPortfolioToggle';
import { portfolioProjectLimitSelector } from '../../settings/company/CompanyDataDuck';
import {
  showProjectInPortfolio,
  hideProjectInPortfolio,
  portfolioDataSelector,
} from '../../portfolio/PortfolioDataDuck';

const COMMON_KEY = LOADINGS.PROJECT.SHOW_IN_PORTFOLIO;

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    projectsShownInPortfolio: portfolioDataSelector(state),
    portfolioProjectLimit: portfolioProjectLimitSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showProjectInPortfolio: (projectId) => {
      dispatch(showProjectInPortfolio(dispatch, projectId));
    },
    hideProjectInPortfolio: (projectId) => {
      dispatch(hideProjectInPortfolio(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPortfolioToggle);
