import { QRCodeBackgroundPosterService } from '@premagic/core';
import { Col, Position, Row, Space } from '@premagic/myne';
import React from 'react';
import { VerticalLogoSpacePlaceholder } from './DisplayedPosterLogo';
import DisplayedPosterLogoContainer from './DisplayedPosterLogoContainer';

function getHorizontalSpaceSizeForLogo(posterType) {
  switch (posterType) {
    case 'standee':
      return 12;
    case 'tent':
      return 4;
    case 'pocket-card':
      return 3;
    case 'led-screen':
      return 3;
    default:
      return 12;
  }
}

function getLogoSize(posterType) {
  switch (posterType) {
    case 'standee':
      return 'lg';
    case 'tent':
      return 'md';
    case 'pocket-card':
      return 'sm';
    case 'led-screen':
      return 'sm';
    default:
      return 'md';
  }
}

function getSpaceAroundSizeForCustomStyle(posterType) {
  switch (posterType) {
    case 'standee':
      return 9;
    case 'pocket-card':
      return 6;
    case 'tent':
      return 7;
    case 'led-screen':
      return 6;
    default:
      return 8;
  }
}

type LogoBasedOnPosterTypeProps = {
  logoOptionType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
  folderId: string;
  customLogo1?: string;
  customLogo2?: string;
  posterType: 'standee' | 'pocket-card' | 'tent' | 'led-screen';
  numberOfActiveLogos?: number;
  customStyle?: boolean;
  smallerLogoSpace?: boolean;
};

export function LogoBasedOnPosterType(props: LogoBasedOnPosterTypeProps) {
  const {
    logoOptionType = QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.COMPANY_LOGO,
    folderId,
    customLogo1,
    customLogo2,
    posterType = 'standee',
    numberOfActiveLogos,
    customStyle,
    smallerLogoSpace,
  } = props;

  if (customStyle && logoOptionType !== QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.COMPANY_LOGO) {
    return (
      <Position align="top-left">
        <Space size={getSpaceAroundSizeForCustomStyle(posterType)}>
          <Row columnDirection vcenter>
            {customLogo1 && (
              <DisplayedPosterLogoContainer
                folderId={folderId}
                logoId={QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_1}
                size={getLogoSize(posterType)}
                logoOptionType={QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO}
                align="center"
                customStyle={customStyle}
              />
            )}
            {customLogo1 && customLogo2 && <Space vertical size={posterType === 'standee' ? 6 : 2} />}
            {customLogo2 && (
              <DisplayedPosterLogoContainer
                folderId={folderId}
                logoId={QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_2}
                size={getLogoSize(posterType)}
                logoOptionType={QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO}
                align="center"
                customStyle={customStyle}
              />
            )}
          </Row>
        </Space>
      </Position>
    );
  }

  if (logoOptionType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO) {
    return (
      <Col size={smallerLogoSpace ? 7 : 10}>
        <Row spaceBetween={numberOfActiveLogos === 2} center={numberOfActiveLogos === 1} vcenter>
          {numberOfActiveLogos === 0 && <VerticalLogoSpacePlaceholder size={getLogoSize(posterType)} />}
          {customLogo1 && (
            <DisplayedPosterLogoContainer
              folderId={folderId}
              logoId={QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_1}
              size={getLogoSize(posterType)}
              logoOptionType={QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO}
              align={numberOfActiveLogos === 1 ? 'center' : 'left'}
            />
          )}
          {numberOfActiveLogos === 2 && <Space size={getHorizontalSpaceSizeForLogo(posterType)} />}
          {customLogo2 && (
            <DisplayedPosterLogoContainer
              folderId={folderId}
              logoId={QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID.LOGO_2}
              size={getLogoSize(posterType)}
              logoOptionType={QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO}
              align={numberOfActiveLogos === 1 ? 'center' : 'right'}
            />
          )}
        </Row>
      </Col>
    );
  }

  return (
    <Col size={10}>
      <Row center={!customStyle && posterType !== 'led-screen'} vcenter>
        <DisplayedPosterLogoContainer
          folderId={folderId}
          size={getLogoSize(posterType)}
          logoOptionType={QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.COMPANY_LOGO}
          align={customStyle || posterType === 'led-screen' ? 'left' : undefined}
          customStyle={customStyle}
        />
      </Row>
    </Col>
  );
}
