import { Map as IMap } from 'immutable';
import { get as lodashGet } from 'lodash';
import { ArrayUtils, HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';
import { COLOR_SHADES, STATUS_LABEL_STYLES } from '@premagic/myne';

const API_URLS = APIURLService.API_URLS.ALBUM_CREATOR;

export type ImagePositionLocationsType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ImagePositionType = `position-${ImagePositionLocationsType}`;

export function getImagePositionName(location: ImagePositionLocationsType): ImagePositionType {
  return `position-${location}`;
}

export type AlbumPageImages = Record<
  ImagePositionType,
  {
    imageId: string;
  }
>;

export type NewAlbumPageType = {
  position: number;
  template_name: number; // ALBUM_TEMPLATE_IDS - not including coz cyclic dependency;
  images: Partial<AlbumPageImages>;
};

export type AlbumPageType = NewAlbumPageType & {
  id: string;
};

export enum ALBUM_STATES {
  DRAFT = 'DRAFT',
  REVIEW_PENDING = 'REVIEW_PENDING',
  REVIEW_COMPLETED = 'REVIEW_COMPLETED',
}

export type AlbumType = {
  project_id: string;
  name: string;
  id: string;
  state: ALBUM_STATES;
  pages: Array<number>; // AlbumPageType
};

export const ALBUM_STATES_DETAILS: Record<ALBUM_STATES, { title: string; color: STATUS_LABEL_STYLES }> = {
  [ALBUM_STATES.DRAFT]: {
    title: 'Draft',
    color: STATUS_LABEL_STYLES.SECONDARY,
  },
  [ALBUM_STATES.REVIEW_PENDING]: {
    title: 'Review Pending',
    color: STATUS_LABEL_STYLES.WARNING,
  },
  [ALBUM_STATES.REVIEW_COMPLETED]: {
    title: 'Review completed',
    color: STATUS_LABEL_STYLES.SUCCESS,
  },
};

export function fetchAlbumsForProject(projectId: string): Promise<{
  results: Array<AlbumType>;
}> {
  return HttpUtils.get(
    API_URLS.ALBUMS,
    {
      params: {
        project_id: projectId,
      },
    },
    true,
  )
    .then(({ data }) => data)
    .catch((response) => Promise.reject(response.data));
}

export function createNewAlbumService(data: { name: string; project_id: string }) {
  return HttpUtils.post(API_URLS.ALBUMS, data).then((response) => response.data);
}

export function fetchAlbum(albumId: string): Promise<AlbumType> {
  return HttpUtils.get(API_URLS.ALBUM_DETAILS(albumId))
    .then(({ data }) => data)
    .catch((response) => Promise.reject(response.data));
}

export function updateAlbumDataService(albumId: string, data: Partial<AlbumType>) {
  return HttpUtils.patch(API_URLS.ALBUM_DETAILS(albumId), data)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function deleteAlbumService(albumId: string) {
  return HttpUtils.httpDelete(API_URLS.ALBUM_DETAILS(albumId))
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function createNewAlbumPageService(
  albumId: string,
  data: {
    position: number;
    images: Record<string, any>;
    template_name: number;
  },
) {
  return HttpUtils.post(API_URLS.ALBUM_PAGES(albumId), data).then((response) => response.data);
}

export function updateAlbumPageService(
  albumId: string,
  pageId: string,
  data: {
    position?: number;
    images?: any;
    template_name?: number;
  },
) {
  return HttpUtils.put(API_URLS.ALBUM_PAGE_DETAILS(albumId, pageId), data).then((response) => response.data);
}

const TEMPLATE_IMAGES_SIZE = {
  '1': 3,
  '2': 3,
};

function isPositionAvailable(albumData: any, pageNo: number, position: number) {
  return !lodashGet(albumData, `${pageNo}[images][position-${position}]`, false);
}

function getNextLocation(currentPageNo: number, currentPosition: number, currentTemplateId: number) {
  if (!currentPageNo)
    return {
      pageNo: 1,
      position: 1,
    };

  if (currentPosition === TEMPLATE_IMAGES_SIZE[currentTemplateId]) {
    return {
      pageNo: currentPageNo + 1,
      position: 1,
    };
  }

  return {
    pageNo: currentPageNo,
    position: currentPosition + 1,
  };
}

function getNextAvailableLocation(
  currentPageNo: number,
  currentPosition: number,
  currentTemplateId: number,
  albumData: any,
) {
  const nextLocation = getNextLocation(currentPageNo, currentPosition, currentTemplateId);

  if (!isPositionAvailable(albumData, nextLocation.pageNo, nextLocation.position)) {
    return getNextAvailableLocation(nextLocation.pageNo, nextLocation.position, currentTemplateId, albumData);
  }

  return nextLocation;
}

export function getAutoSelectedPhotos(images: Array<{ id: string | number }>, albumData: any) {
  let imageLocation = {
    pageNo: 1,
    position: 0,
  };

  const currentTemplate = 1;

  const imagePosition = images.reduce((result, image) => {
    imageLocation = getNextAvailableLocation(imageLocation.pageNo, imageLocation.position, currentTemplate, albumData);

    return result.update(String(imageLocation.pageNo), (pageData = { images: {} }) => ({
      // @ts-ignore
      ...pageData,
      images: {
        // @ts-ignore
        ...pageData.images,
        [`position-${imageLocation.position}`]: image.id,
      },
    }));
  }, IMap({}));

  return imagePosition.toJSON();
}

export function getImagesPositionForTemplate(
  currentImagePosition: Partial<AlbumPageImages>,
  newTemplateSize: number,
): Partial<AlbumPageImages> {
  if (!currentImagePosition) return {};

  return Object.entries(currentImagePosition)
    .sort((a, b) => ArrayUtils.stringSortFunctionNatural(a[0], b[0]))
    .slice(0, newTemplateSize)
    .reduce(
      (result, [, imageId], index) => ({
        ...result,
        [getImagePositionName((index + 1) as ImagePositionLocationsType)]: imageId,
      }),
      {},
    );
}
