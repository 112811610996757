import React from 'react';
import {
  Card,
  Space,
  Col,
  InnerPageHeader,
  FormErrorType,
  FormGroup,
  FormLabel,
  Input,
  InputPhoneNumber,
  Form,
  FormFooter,
  Button,
  BUTTON_STYLES,
  BUTTON_SIZE,
  InnerPage,
  InnerPageContent,
  FormError,
} from '@premagic/myne';
import { CountryCodeUtils } from '@premagic/utils';
import { UserType } from '../crm/users/UsersService';
import APP_URLS from '../../services/AppRouteURLService';
import InputPhoneNumberContainer from '../acccount/InputPhoneNumberContainer';

type Props = {
  isMobileUp: boolean;
  user?: UserType;
  isLoading: boolean;
  errors: FormErrorType;
  saveUserProfile: (userId, data) => void;
};

export default function UserProfilePage(props: Props): JSX.Element {
  const { user, isMobileUp, isLoading, errors, saveUserProfile } = props;
  if (!user) return <div>User not set</div>;
  return (
    <InnerPage>
      <InnerPageHeader iconName="award" title="My Profile">
        My Profile
      </InnerPageHeader>
      <InnerPageContent>
        <Col size={8}>
          <Card>
            <Space size={8}>
              <Form
                errors={errors}
                onSubmit={(formResponse) => saveUserProfile(user.id, formResponse)}
                formSpec={{
                  name: {
                    validator: 'required',
                  },
                  phone_number: {
                    validator: (value) => CountryCodeUtils.isValidPhoneNumber(value),
                    message: (value) => {
                      if (!CountryCodeUtils.isValidPhoneNumber(value)) return 'Enter a valid number';
                      return 'This is Required';
                    },
                  },
                }}
              >
                {errors && errors.phone_number && <FormError>{errors.phone_number}</FormError>}
                <FormGroup>
                  <FormLabel isRequired>Name</FormLabel>
                  <Input name="name" placeholder="eg. John Doe" autoFocus required defaultValue={user.name} />
                </FormGroup>
                <FormGroup>
                  <FormLabel isRequired>Phone Number</FormLabel>
                  <InputPhoneNumberContainer name="phone_number" required defaultValue={user.phone_number} />
                </FormGroup>
                <FormGroup>
                  <FormLabel info="Register email cannot be change">Email</FormLabel>
                  <Input name="email" placeholder="eg. john@premagic.com" value={user.email} readOnly />
                </FormGroup>
                <FormFooter>
                  <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.USER_PROFILE.INDEX} size={BUTTON_SIZE.LG}>
                    Cancel
                  </Button>
                  <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
                    Save
                  </Button>
                </FormFooter>
              </Form>
            </Space>
          </Card>
        </Col>
      </InnerPageContent>
    </InnerPage>
  );
}
