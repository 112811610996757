import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
} from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import EventPosterFormContainer from './EventPosterFormContainer';

type Props = {
  addEventPoster: (options: { projectId: string; eventId: string }, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  eventId: string;
  isLoading: boolean;
  error: FormErrorType;
  type: EventPosterService.EVENT_POSTER_TYPE;
};

export default function AddEventPosterPanel(props: Props) {
  const { show, close, projectId, eventId, addEventPoster, isLoading, error, type } = props;
  const { title } = EventPosterService.EVENT_POSTER_TYPE_DETAILS[type];

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add new {title}</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={error} onSubmit={(formResponse) => addEventPoster({ eventId, projectId }, formResponse)}>
          <EventPosterFormContainer projectId={projectId} type={type} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
