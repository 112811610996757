import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { LOADINGS } from '../../../../../common/Constants';
import { addMemoryTemplate } from '../MemoriesTemplatesDataDuck';
import AddMemoryTemplatePanel from './AddMemoryTemplatePanel';

const PANEL_KEY = LOADINGS.MEMORY_TEMPLATES.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
  },
) {
  const { projectId, eventId } = ownProps;

  return {
    projectId,
    eventId,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.MEMORY_TEMPLATES.CREATE)(state),
    error: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADINGS.MEMORY_TEMPLATES.CREATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addTemplate: (options: { projectId: string; eventId: string }, data) =>
      dispatch(addMemoryTemplate(dispatch, options, data)),
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMemoryTemplatePanel);
