import { connect } from 'react-redux';
import { fetchFontsData, fontDataSelector, fontFetchSuccessSelector } from './FontSelectorDuck';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../common/Constants';
import FontSelector from './FontSelector';

function mapStateToProps(
  state,
  ownProps: {
    defaultValue?: string;
    onChange?: (value: { fontFamily: string; fontSource: string }) => void;
    disabled?: boolean;
    name: string;
    nameSource: string;
  },
) {
  const { defaultValue, onChange, disabled, name, nameSource } = ownProps;
  return {
    defaultValue,
    onChange,
    fontItems: fontDataSelector(state),
    isLoading: isLoadingSelector(LOADINGS.FONTS.FETCH)(state),
    isFontFetchExecuted: fontFetchSuccessSelector(state),
    disabled,
    name,
    nameSource,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initFontData: () => {
      dispatch(fetchFontsData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FontSelector);
