import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';

import { LOADINGS } from '../../common/Constants';
import AlbumCreator from './AlbumCreator';
import { initAlbumCreator } from './AlbumDataPageDuck';

function mapStateToProps(state, ownProps) {
  const { albumId, view } = ownProps.match.params;

  return {
    albumId,
    view,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.APP_LOADING)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (albumId, view) => dispatch(initAlbumCreator(dispatch, albumId, view)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumCreator);
