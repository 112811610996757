import { EventSponsorsService, EventTrackerService, Schemas, SponsorCreativesService } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ArrayUtils, ErrorTracker } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { isEmpty, omit } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { pagesSelector } from '../../../../reducers/selectors';
import {
  addSponsorCreativeData,
  removeSponsorCreativeData,
  setSponsorCreativeData,
  sponsorCreativeEntitiesSelector,
} from '../event-sponsor-creatives/SponsorCreativeDataDuck';
import { eventSponsorCategoryItemsSelector } from './sponsor-category/data-duck/SponsorCategoryDataDuck';
import { toastMessage } from '../../../../reducers/ToastStore';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_SPONSORS');

export const setEventSponsorData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const addEventSponsorData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

const updateEventSponsorData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, data) => ({
  [data.id]: data,
}));

export const removeEventSponsorData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

const removeCreativeFromSponsorData = createAction(
  getActionType('DATA', 'REMOVE_CREATIVE'),
  (dispatch, sponsorId: string, creativeId: string) => ({
    id: sponsorId,
    creativeId,
  }),
);
const addNewCreativeToSponsorData = createAction(
  getActionType('DATA', 'ADD_CREATIVE'),
  (dispatch, sponsorId, creativeId) => ({
    id: sponsorId,
    creativeId,
  }),
);

export const getEventSponsorsForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.EVENT_SPONSOR.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventSponsorsService.fetchEventSponsorsForProject(projectId)
      .then((response) => {
        if (!isEmpty(response)) {
          const normalizedData = normalize(response, Schemas.EventSponsors);
          dispatch(setEventSponsorData(dispatch, normalizedData.entities.sponsors));
          dispatch(setSponsorCreativeData(dispatch, normalizedData.entities.creatives));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_SPONSOR_FETCH_ERROR', e);
      });
  },
);

export const addEventSponsor = createAction(
  getActionType('ACTION', 'CREATE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: EventSponsorsService.NewEventSponsorType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_SPONSOR.ADD;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    EventSponsorsService.createEventSponsor(projectId, data)
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SPONSOR.CREATE, {
          name: data.name,
        });
        dispatch(addEventSponsorData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_ADD_PANEL, false));
        toastMessage('success', 'Sponsor added');
        dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
        setTimeout(() => {
          dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_ADD_PANEL, true));
        }, 1);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_SPONSOR_ADD_ERROR', e);
      });
  },
);

export const updateEventSponsor = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; sponsorId: string },
    formResponse: FormResponseType & {
      data: EventSponsorsService.EventSponsorType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_SPONSOR.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    EventSponsorsService.updateEventSponsor(options, data)
      .then((response) => {
        dispatch(updateEventSponsorData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));

        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_SPONSOR', e);
      });
  },
);

export const removeEventSponsor = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, projectId: string, sponsorId: string) => {
    dispatch(removeEventSponsorData(dispatch, sponsorId));
    EventSponsorsService.deleteEventSponsor(projectId, sponsorId).then((data) => data);
  },
);

export const addSponsorCreativeForSponsor = createAction(
  getActionType('ACTION', 'CREATE_CREATIVE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: SponsorCreativesService.NewSponsorCreativeType;
    },
  ) => {
    const loadingKey = LOADINGS.SPONSOR_CREATIVES.ADD;
    if (formResponse.errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    SponsorCreativesService.createSponsorCreative(
      {
        projectId,
      },
      data,
    )
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SPONSOR.CREATIVE_CREATE, {
          name: data.name,
          sponsorId: data.sponsor_id,
        });
        dispatch(addSponsorCreativeData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(addNewCreativeToSponsorData(dispatch, data.sponsor_id, response.id));
        dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_PANEL, false));
        toastMessage('success', 'Creative added');
        setTimeout(() => {
          dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_PANEL, true));
        }, 1);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('SPONSOR_CREATIVES_ADD_ERROR', e);
      });
  },
);

export const addSponsorCreativeWatermarkForSponsor = createAction(
  getActionType('ACTION', 'CREATE_WATERMARK_CREATIVE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: SponsorCreativesService.NewSponsorCreativeType & {
        watermark_folder_ids: Array<string>;
        watermark_opacity: number;
        watermark_size: number;
        watermark_position: string;
        watermark_position_x: number | string;
        watermark_position_y: number | string;
        watermark_remove_on_download: boolean;
      };
    },
  ) => {
    const loadingKey = LOADINGS.SPONSOR_CREATIVES.ADD_WATERMARK;
    if (formResponse.errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    if (!data.watermark_folder_ids || !data.sponsor_id) return;

    const watermarkFolderIds = Array.isArray(data.watermark_folder_ids)
      ? data.watermark_folder_ids
      : [data.watermark_folder_ids];

    const dataForSponsorCreative = omit(data, [
      'watermark_folder_ids',
      'watermark_opacity',
      'watermark_size',
      'watermark_position',
      'watermark_position_x',
      'watermark_position_y',
      'watermark_remove_on_download',
    ]) as unknown as SponsorCreativesService.NewSponsorCreativeType;

    const dataToPost = {
      ...dataForSponsorCreative,
      meta: {
        watermark_folder_ids: watermarkFolderIds,
        watermark_opacity: data.watermark_opacity,
        watermark_size: data.watermark_size,
        watermark_position: data.watermark_position,
        watermark_position_x: data.watermark_position_x,
        watermark_position_y: data.watermark_position_y,
        watermark_remove_on_download: data.watermark_remove_on_download,
      },
    };

    SponsorCreativesService.createSponsorCreative(
      {
        projectId,
      },
      dataToPost,
    )
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SPONSOR.CREATIVE_CREATE, {
          name: data.name,
          sponsorId: data.sponsor_id,
        });
        dispatch(addSponsorCreativeData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(addNewCreativeToSponsorData(dispatch, data.sponsor_id, response.id));
        dispatch(
          toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_SPONSOR_WATERMARK_PANEL, false),
        );
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('SPONSOR_WATERMARK_CREATIVES_ADD_ERROR', e);
      });
  },
);

export const removeSponsorCreativeFromSponsor = createAction(
  getActionType('ACTION', 'REMOVE_CREATIVE'),
  (dispatch, options: { projectId: string; sponsorId: string; creativeId: string }) => {
    const { sponsorId, creativeId } = options;
    dispatch(removeSponsorCreativeData(dispatch, creativeId));
    dispatch(removeCreativeFromSponsorData(dispatch, sponsorId, creativeId));
    SponsorCreativesService.deleteSponsorCreative(options)
      .then((data) => data)
      .catch((e) => {
        ErrorTracker.logError('SPONSOR_CREATIVES_REMOVE_ERROR', e);
      });
  },
);

type EventSponsorItemType = IMap<string, EventSponsorsService.EventSponsorType>;
type StateType = {
  items: EventSponsorItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setEventSponsorData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventSponsorData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateEventSponsorData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeEventSponsorData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
    [addNewCreativeToSponsorData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (sponsor) =>
        Object.assign({}, sponsor, {
          // @ts-ignore
          creatives: sponsor.creatives.concat(action.payload.creativeId),
        }),
      ),
    }),
    [removeCreativeFromSponsorData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (sponsor) =>
        Object.assign({}, sponsor, {
          // @ts-ignore
          creatives: sponsor.creatives.filter((id) => id !== action.payload.creativeId),
        }),
      ),
    }),
  },
  initialState,
);

export const eventSponsorDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.eventSponsor.data.sponsors as StateType,
);

export const eventSponsorEntitiesSelector = createSelector(eventSponsorDataSelector, (sponsors) => sponsors.items);

export const eventSponsorsSelector = createSelector(eventSponsorEntitiesSelector, (sponsors) => sponsors.toJSON());

export const eventSponsorsIdsSelector = createSelector(
  eventSponsorEntitiesSelector,
  eventSponsorCategoryItemsSelector,
  (sponsors, category) =>
    sponsors
      .sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name))
      .sort((a, b) => {
        const aCategoryId = a?.category;
        const bCategoryId = b?.category;

        const aTier = category?.get(aCategoryId)?.tier;
        const bTier = category?.get(bCategoryId)?.tier;
        if (!aTier || !bTier) return 0;
        const aSize = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[aTier].order;
        const bSize = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[bTier].order;
        return aSize - bSize;
      })
      .keySeq()
      .toArray(),
);

export const eventSponsorsIdsForCategorySelector = (categoryId) =>
  createSelector(eventSponsorEntitiesSelector, (sponsors) =>
    sponsors
      .filter((sponsor) => sponsor.category === categoryId)
      .sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name))
      .keySeq()
      .toArray(),
  );

export const eventSponsorsArraySelector = createSelector(
  eventSponsorEntitiesSelector,
  eventSponsorsIdsSelector,
  (sponsors, sponsorIds) => sponsorIds.map((id) => sponsors.get(id)) as Array<EventSponsorsService.EventSponsorType>,
);

export const sponsorCreativesIdsForPlacementGroupedBySponsorTierSelector = (
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT,
) =>
  createSelector(
    sponsorCreativeEntitiesSelector,
    eventSponsorEntitiesSelector,
    eventSponsorCategoryItemsSelector,
    (creatives, sponsors, category) =>
      creatives
        .filter((creative) => creative.placement === placement)
        .sort((aCreative, bCreative) => {
          const aSponsor = sponsors.get(aCreative.sponsor_id);
          const bSponsor = sponsors.get(bCreative.sponsor_id);
          if (!aSponsor || !bSponsor) return 0;

          const aCategoryId = aSponsor?.category;
          const bCategoryId = bSponsor?.category;

          const aTier = category?.get(aCategoryId)?.tier;
          const bTier = category?.get(bCategoryId)?.tier;
          if (!aTier || !bTier) return 0;

          const aSponsorSize = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[aTier].order;
          const bSponsorSize = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[bTier].order;
          return aSponsorSize - bSponsorSize;
        })
        .keySeq()
        .groupBy((creativeId) => {
          const creative = creatives.get(creativeId);
          if (!creative) return EventSponsorsService.EVENT_SPONSORS_TIER.LEVEL_5;
          const sponsor = sponsors.get(creative.sponsor_id);
          const tier = sponsor?.category ? category?.get(sponsor?.category)?.tier : undefined;
          return tier || EventSponsorsService.EVENT_SPONSORS_TIER.LEVEL_5;
        })
        .toJS() as Record<EventSponsorsService.EVENT_SPONSORS_TIER, Array<string>>,
  );

export const sponsorCreativesIdsForPlacementGroupedSelector = (
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT,
  groupByType: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE,
) =>
  createSelector(
    sponsorCreativeEntitiesSelector,
    eventSponsorEntitiesSelector,
    eventSponsorCategoryItemsSelector,
    (creatives, sponsors, category) =>
      creatives
        .filter((creative) => creative.placement === placement)
        .sort((aCreative, bCreative) => {
          const aSponsor = sponsors.get(aCreative.sponsor_id);
          const bSponsor = sponsors.get(bCreative.sponsor_id);
          const aCategoryId = aSponsor?.category;
          const bCategoryId = bSponsor?.category;
          const aTier = aCategoryId && category?.get(aCategoryId)?.tier;
          const bTier = bCategoryId && category?.get(bCategoryId)?.tier;
          if (!aTier || !bTier) return 0;
          const aOrder = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[aTier].order;
          const bOrder = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[bTier].order;
          return aOrder - bOrder;
        })
        .keySeq()
        .groupBy((creativeId) => {
          const creative = creatives.get(creativeId);
          if (!creative) return '';
          const sponsor = sponsors.get(creative.sponsor_id);
          if (groupByType === SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.CATEGORY) {
            return sponsor?.category;
          }
          if (groupByType === SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.TIER) {
            const tier = sponsor?.category && category?.get(sponsor?.category)?.tier;
            return tier;
          }
          return '';
        })
        .toJS() as Record<string, Array<string>>,
  );

export const sponsorCreativesStatsForPlacementGroupedBySponsorSelector = (
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT,
) =>
  createSelector(sponsorCreativeEntitiesSelector, (creatives) =>
    creatives
      .filter((creative) => creative.placement === placement)
      .reduce((result, creative) => {
        const sponsorId = creative.sponsor_id;
        const statsForSponsor = result[sponsorId] || 0;
        return {
          ...result,
          [sponsorId]: statsForSponsor + 1,
        };
      }, {}),
  );

export const sponsorCategoryItemDataMapByCreativeIdSelector = (
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT,
) =>
  createSelector(
    sponsorCreativeEntitiesSelector,
    eventSponsorEntitiesSelector,
    eventSponsorCategoryItemsSelector,
    (creatives, sponsors, sponsorCategory) =>
      creatives
        .filter((creative) => creative.placement === placement)
        .reduce((result, creative) => {
          const sponsorId = creative.sponsor_id;
          const categoryId = sponsorId && sponsors?.get(sponsorId)?.category;
          const category = categoryId ? sponsorCategory.get(categoryId) : undefined;
          return result.set(creative.id, category);
        }, new Map()) as Map<string, EventSponsorsService.EventSponsorCategoryType>,
  );

export const allFileIdsInSponsorCreativesSelector = (placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT) =>
  createSelector(sponsorCreativeEntitiesSelector, (creatives) =>
    creatives
      .filter((creative) => creative.placement === placement)
      .reduce((result: string[], creative) => {
        const fileIds = creative.asset_ids;
        return [...result, ...fileIds];
      }, []),
  );
