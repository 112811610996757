import React from 'react';
import {
  BUTTON_ICONS_SIZES,
  Col,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemAction,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProposalVariableService } from '@premagic/proposals';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';
import MESSAGES from '../../../../../common/Messages';
import AccountCurrencyContainer from '../../acccount/AccountCurrencyContainer';

type Props = {
  id: string;
  variable: ProposalVariableService.ProposalVariableType;
  deleteVariable: (id: string) => void;
  isUpdating: boolean;
  showEditVariable: (scope: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE, id: string) => void;
};

export default function ProposalVariableItem(props: Props): JSX.Element {
  const { variable, isUpdating, deleteVariable, id, showEditVariable } = props;

  const { name, type, is_system: isSystem, scope, default_value: defaultValue } = variable;
  const details = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE_DETAILS[scope];
  return (
    <ListItem>
      <Col size={7}>
        <Text color={COLOR_SHADES.DARKER}>
          {name}
          {isSystem && (
            <>
              <Space size={1} />
              <Text size={TEXT_SIZE.SIZE_5} muted boldness={TEXT_BOLDNESS.BOLD}>
                {HTML_ICON.DOT}
              </Text>
              <Space size={1} />
              <Text size={TEXT_SIZE.SIZE_5} muted boldness={TEXT_BOLDNESS.BOLD}>
                System
              </Text>
            </>
          )}
        </Text>
      </Col>
      {defaultValue && (
        <>
          <Col size={2} rightAlighed>
            <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
              <AccountCurrencyContainer amount={Number(defaultValue)} />
            </Text>
          </Col>
          <Space size={2} />
        </>
      )}
      {details.types.length > 1 && (
        <Col size={null} rightAlighed>
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            {ProposalVariableService.PROPOSAL_VARIABLE_TYPE_DETAILS[type].name}
          </Text>
        </Col>
      )}

      <Space size={8} />
      <ListItemAction className={JS_SELECTORS.PROPOSAL_VARIABLE_MORE_OPTIONS(id)}>
        {isUpdating ? (
          <LoadingDots size="sm" />
        ) : (
          <Dropdown>
            <DropdownIcon title="Variable options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
              <Icon name="more_horizontal" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
            </DropdownIcon>
            <Menu>
              <MenuItem
                onClick={() => deleteVariable(id)}
                disabled={isSystem}
                disabledMessage={MESSAGES.PROPOSAL_VARIABLES.SYSTEM_VARIABLES}
              >
                <MenuItemIcon name="trash" />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => showEditVariable(scope, id)}
                disabled={isSystem}
                disabledMessage={MESSAGES.PROPOSAL_VARIABLES.SYSTEM_VARIABLES}
              >
                <MenuItemIcon name="edit" />
                Edit
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </ListItemAction>
    </ListItem>
  );
}
