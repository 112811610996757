import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { createAction, handleActions } from 'redux-actions';
import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import { normalize } from 'normalizr';
import { EventTrackerService, LabelService } from '@premagic/core';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { LabelsSchema } from '../../../../schema/Schemas';
import { toastMessage } from '../../../reducers/ToastStore';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';

const LOADING_KEYS = LOADINGS.CRM_LABELS;

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_LABELS');

const setLabelsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addLabelsData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);

export const fetchLabelsData = createAction(
  getActionType('DATA', 'FETCH_LABELS'),
  (dispatch, entityType: LabelService.ENTITY_TYPE) => {
    const loadingKey = LOADING_KEYS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    LabelService.fetchLabels(entityType)
      .then((response) => {
        const normalizedData = normalize(response.results, LabelsSchema);
        dispatch(setLabelsData(dispatch, normalizedData.entities.labels));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', `Something went wrong`);
        ErrorTracker.logError('Labels: fetch failed', e);
        return e;
      });
  },
);

// not using it yet, might use later
// export const createLabel = createAction(
//   getActionType('DATA', 'CREATE_LABEL'),
//   (dispatch, entityType: LabelService.ENTITY_TYPE, data: Partial<LabelService.LabelsType>) => {
//     const loadingKey = LOADING_KEYS.CREATE;
//     dispatch(toggleLoadingState(dispatch, loadingKey, true));
//     dispatch(clearErrorState(dispatch, loadingKey));
//     EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_LABELS.CREATE, { data });

//     LabelService.createLabel(entityType, data)
//       .then((response) => {
// fix this adding of labels data
//         dispatch(addLabelsData(dispatch, response));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         return response;
//       })
//       .catch((e) => {
//         dispatch(setErrorState(dispatch, loadingKey, e));
//         dispatch(toggleLoadingState(dispatch, loadingKey, false));
//         toastMessage('error', `Something went wrong`);
//         ErrorTracker.logError('Labels: create failed', e);
//         return e;
//       });
//   },
// );

type LabelsStateType = {
  items: IMap<string, LabelService.LabelsType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setLabelsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addLabelsData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    // [updateLabelsData.toString()]: (state, action) => ({
    //   ...state,
    //   items: state.items.merge(action.payload),
    // }),
    // [removeLabelsData.toString()]: (state, action) => ({
    //   ...state,
    //   items: state.items.delete(action.payload),
    // }),
  },
  initialState,
);

const labelsDataSelector = createSelector(entitiesDataSelector, (entities) => entities.crm.labels as LabelsStateType);

export const labelEntitiesSelector = createSelector(labelsDataSelector, (labels) => labels.items);

export const labelItemsSelector = createSelector(labelEntitiesSelector, (labelItems) => labelItems.toJSON());

export const allLabelsSelector = createSelector(labelEntitiesSelector, (items) => items.valueSeq().toArray());

export const allEventLabelsSelector = createSelector(allLabelsSelector, (items) =>
  items.filter((item) => item.entity === LabelService.ENTITY_TYPE.EVENT),
);
