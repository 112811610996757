import React from 'react';

import { EventSponsorsService, FileService, SponsorCreativesService } from '@premagic/core';
import { Card, CARD_STYLES, Label, LABEL_STYLES, Position, Row, Space, Tooltip } from '@premagic/myne';
import QRCodeSponsorImage from './QRCodeSponsorImage';

type SponsorCreativeStatusProps = {
  isSponsorInDraft?: boolean;
  isInDraft?: boolean;
};

function SponsorCreativeStatus(props: SponsorCreativeStatusProps) {
  const { isSponsorInDraft, isInDraft } = props;

  if (isSponsorInDraft) {
    return (
      <Position align="top-left">
        <Tooltip message="The sponsor is set to hidden, none of the sponsor's creatives will be displayed.">
          <Space size={2}>
            <Label style={LABEL_STYLES.PILL_DANGER}>Sponsor HIDDEN</Label>
          </Space>
        </Tooltip>
      </Position>
    );
  }

  if (!isSponsorInDraft && isInDraft) {
    return (
      <Position align="top-left">
        <Tooltip message="Creative is marked as hidden">
          <Space size={2}>
            <Label style={LABEL_STYLES.PILL_DANGER}>HIDDEN</Label>
          </Space>
        </Tooltip>
      </Position>
    );
  }

  return null;
}

const LOGO_SIZE_MAP = {
  sm: { width: '60px', height: '60px' },
  md: { width: '65px', height: '65px' },
};

type CreativeItemProps = {
  creative: SponsorCreativesService.SponsorCreativeType;
  files: Record<string, FileService.FolderFileType>;
  lightenLogo?: boolean;
  logoSize?: 'sm' | 'md';
};

function CreativeItem(props: CreativeItemProps) {
  const { creative, files, lightenLogo, logoSize } = props;

  if (!creative || creative?.asset_ids.length === 0) return null;

  return (
    <>
      {creative.asset_ids.map((fileId) => {
        const file = files[fileId];
        return (
          <div
            key={fileId}
            style={{
              width: logoSize ? LOGO_SIZE_MAP[logoSize].width : '100%',
              height: logoSize ? LOGO_SIZE_MAP[logoSize].height : '100%',
              opacity: lightenLogo ? 0.3 : 1,
            }}
          >
            <Row fullHeight center vcenter>
              <QRCodeSponsorImage src={file?.dynamic_image_url} />
            </Row>
          </div>
        );
      })}
    </>
  );
}

type Props = {
  creative: SponsorCreativesService.SponsorCreativeType;
  sponsor?: EventSponsorsService.EventSponsorType;
  id: string;
  logoSize?: 'sm' | 'md';
  files: Record<string, FileService.FolderFileType>;
  showCreativeStatus?: boolean;
};

export default function QRCodeSponsorCreativeItem(props: Props): React.ReactElement | null {
  const { creative, id, files, sponsor, showCreativeStatus = false, logoSize } = props;
  const { name, status } = creative || {};

  const isSponsorInDraft = sponsor?.status === EventSponsorsService.EVENT_SPONSORS_PUBLISH_STATUS.DRAFT;
  const isInDraft = status === SponsorCreativesService.SPONSORS_CREATIVES_PUBLISH_STATUS.DRAFT;

  if (!showCreativeStatus && (isSponsorInDraft || isInDraft)) {
    return null;
  }

  return (
    <>
      {showCreativeStatus && <SponsorCreativeStatus isSponsorInDraft={isSponsorInDraft} isInDraft={isInDraft} />}
      <CreativeItem creative={creative} files={files} lightenLogo={isSponsorInDraft || isInDraft} logoSize={logoSize} />
    </>
  );
}
