import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';

import {
  folderIdsWithFolderTypeForProjectSelector,
  registrationLinkForProjectSelector,
} from '../../projects/AccountProjectsDataDuck';
import EventUserRegistrationDetails from './EventUserRegistrationDetails';
import { foldersEntitiesSelector } from '../../projects/folders/AccountFoldersDataDuck';
import { projectMetaDataForProjectSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const clientWebsiteIds =
    folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.CLIENT_WEBSITE, {
      is_shared: true,
    })(state) || [];
  const clientWebsiteId = clientWebsiteIds[0];
  return {
    folder: foldersEntitiesSelector(state)[clientWebsiteId],
    projectId,
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    registrationLink: registrationLinkForProjectSelector(projectId)(state),
  };
}

function mapDispatchTopProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchTopProps)(EventUserRegistrationDetails);
