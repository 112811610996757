import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { notificationSettingSelector, saveClientNotificationData } from '../preferences/ClientSettingsDataDuck';
import NotificationSettingsPage from './NotificationSettingsPage';
import { companyDomainSelector, companyEmailSelector, companyPhoneSelector } from '../company/CompanyDataDuck';

function mapStateToProps(state) {
  return {
    notifications: notificationSettingSelector(state),
    companyEmail: companyEmailSelector(state),
    companyPhone: companyPhoneSelector(state),
    companyDomain: companyDomainSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.APP_LOADING)(state) || isLoadingSelector(LOADINGS.CLIENT_SETTINGS.FETCH_ALL)(state),
    isSaving: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientNotificationData(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsPage);
