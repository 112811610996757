import { connect } from 'react-redux';
import CompanyEnquiryFormPage from './CompanyEnquiryFormPage';
import { companyLeadFormsURL } from '../../company/CompanyDataDuck';

function mapStateToProps(state) {
  return {
    companyUrls: companyLeadFormsURL(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEnquiryFormPage);
