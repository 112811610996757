import { connect } from 'react-redux';
import { ProposalSlideDataDuck } from '@premagic/proposals';
import ProposalSlidePreview from './ProposalSlidePreview';

function mapStateToProps(
  state,
  ownProps: {
    id: number;
    projectId: string;
    deckId: string;
  },
) {
  const { id, projectId, deckId } = ownProps;
  return {
    projectId,
    deckId,
    id,
    slide: ProposalSlideDataDuck.proposalsSlideItemsSelectors(state)[id],
  };
}

export default connect(mapStateToProps)(ProposalSlidePreview);
