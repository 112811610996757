import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ClassNames from 'classnames';
import { unescape } from 'lodash';
import { BrowserUtils, MediaUtils, use100vh, useScroll } from '@premagic/utils';

import { ButtonIcon } from '../Button/Buttons';
import { Color, COLOR_SHADES } from '../Color/Color';
import { ErrorBoundary } from '../Error/Error';
import { Col, Row, Space } from '../Grid/Grid';
import { Icon, ICON_ACCENT_STYLES, ICON_SIZES } from '../Icon/Icons';
import { ASSET_TYPES, IMAGE_V2_STYLES, ImageV2, SimpleImage } from '../Media/Media';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';

import styles from './page.module.css';
import { VIDEO_PLAYER_STYLES, VIDEO_TYPES, VideoJsPlayer } from '../Media';
import { LoadingBox, LoadingDots } from '../Loading/Loading';
import { FadeIn } from '../Animation/Animation';

export function ScrollResetToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
export enum CONTAINER_WIDTH_STYLE {
  FLUID = 'fluid',
  CONTAINED = 'contained',
}
type ContainerProps = {
  widthStyle?: CONTAINER_WIDTH_STYLE;
  children: React.ReactNode;
  className?: string;
};

export function Container(props: ContainerProps) {
  const { children, widthStyle = CONTAINER_WIDTH_STYLE.CONTAINED, className } = props;
  const containerClasses = ClassNames(styles.container, className, styles[`container--width-style-${widthStyle}`]);
  return <div className={containerClasses}>{children}</div>;
}

export enum PAGE_PADDING_STYLE {
  DEFAULT = 'default',
  NO_PADDING_AND_DESK_PADDING = 'no-padding-and-desk-padding',
  NO_PADDING = 'no-padding',
}

export enum PAGE_STYLES {
  DEFAULT = 'default',
  DARK_SIDE = 'dark-side',
  BLACK = 'black',
  LIGHT_GRAY = 'light-gray',
  DARKER = 'darker',
}

interface PageProps {
  children: React.ReactNode;
  center?: boolean;
  paddingStyle?: PAGE_PADDING_STYLE;
  containerWidthStyle?: CONTAINER_WIDTH_STYLE;
  screenHeight?: boolean;
  style?: PAGE_STYLES;
  className?: string;
  onClick?: () => void;
  scrollReset?: boolean;
  fromTheme?: boolean;
  cssStyle?: any;
}

export function Page(props: PageProps): React.ReactElement {
  const {
    center = false,
    children,
    paddingStyle = PAGE_PADDING_STYLE.DEFAULT,
    screenHeight = false,
    style = PAGE_STYLES.DEFAULT,
    containerWidthStyle = CONTAINER_WIDTH_STYLE.FLUID,
    className,
    scrollReset,
    fromTheme,
    cssStyle,
    ...elementProps
  } = props;

  useEffect(() => {
    if (scrollReset) BrowserUtils.scrollToTop(true);
  }, [scrollReset]);

  const classes = ClassNames(
    styles.page,
    'js-page',
    styles[`page--padding-${paddingStyle}`],
    styles[`page--style-${style}`],
    className,
    {
      [styles['page--center']]: center,
      [styles['page--height-screen']]: screenHeight,
      [styles['from-theme']]: fromTheme,
    },
  );
  const height = use100vh();
  if (screenHeight) {
    return (
      <div
        className={classes}
        style={{
          height: height || 0,
          // @ts-ignore
          '--page-height': `${height}px`,
          ...cssStyle,
        }}
        {...elementProps}
      >
        <Container widthStyle={containerWidthStyle}>{children}</Container>
      </div>
    );
  }

  return (
    <div className={classes} style={cssStyle} {...elementProps}>
      <Container widthStyle={containerWidthStyle}>{children}</Container>
    </div>
  );
}

export enum PAGE_CONTENT_SIZE {
  SM = 'sm',
  MD = 'md',
  DEFAULT = 'default',
}

type PageContentProps = {
  children: React.ReactNode;
  size?: PAGE_CONTENT_SIZE;
  isLoading?: boolean;
};
// This is used only without PageWithLayout, For single pages out side of layout eg. signature/selection
export function PageContent(props: PageContentProps): React.ReactElement {
  const { size = PAGE_CONTENT_SIZE.DEFAULT, children, isLoading } = props;
  const classes = ClassNames(styles.page__content, styles[`page__content--size-${size}`]);

  if (isLoading) return <LoadingDots />;

  return <div className={classes}>{children}</div>;
}

type PageFooterProps = {
  children: React.ReactNode;
};

export function PageFooter(props: PageFooterProps): React.ReactElement {
  const { children } = props;
  return (
    <div>
      <div className={styles.page__footer__dummy} style={{ height: 72 }} />
      <div className={styles.page__footer}>
        <div className={styles.page__footer__content}>{children}</div>
      </div>
    </div>
  );
}

interface PageWithLayoutProps {
  children: React.ReactNode;
}

export function PageWithLayout(props: PageWithLayoutProps): React.ReactElement {
  const classes = ClassNames(styles['page-with-layout']);

  const { children } = props;
  return (
    <div className={classes}>
      <ScrollResetToTop />
      {children}
    </div>
  );
}

export enum PAGE_HEADER_STYLE {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
}

interface PageHeaderProps {
  children: React.ReactNode;
  fromTheme?: boolean;
  style?: PAGE_HEADER_STYLE;
}

export function PageHeader(props: PageHeaderProps): React.ReactElement {
  const { children, fromTheme, style = PAGE_HEADER_STYLE.DEFAULT } = props;

  const classes = ClassNames(styles.page__header, styles[`page__header--style-${style}`], {
    [styles['from-theme']]: fromTheme,
  });
  return (
    <div className={classes}>
      <Container className={ClassNames(styles.page__header__content)}>{children}</Container>
    </div>
  );
}

interface PageHeaderActionsProps {
  children: React.ReactNode;
}

export function PageHeaderActions(props: PageHeaderActionsProps): React.ReactElement {
  const classes = ClassNames(styles.page__header__actions, 'hidden-print');

  const { children } = props;
  return (
    <Col rightAlighed size={null} className={classes}>
      {children}
    </Col>
  );
}

interface SidebarProps {
  children?: React.ReactNode;
  isRight?: boolean;
}

export function Sidebar(props: SidebarProps): React.ReactElement {
  const { children, isRight = false } = props;
  const wrapper = ClassNames(styles['sidebar-wrapper'], 'hidden-print', {
    [styles['sidebar-wrapper--left']]: !isRight,
    [styles['sidebar-wrapper--right']]: isRight,
  });

  const classes = ClassNames(styles.sidebar);
  return (
    <div className={wrapper}>
      <div className={classes}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </div>
  );
}

interface SidebarFooterProps {
  children?: React.ReactNode;
}

export function SidebarFooter(props: SidebarFooterProps): React.ReactElement {
  const { children } = props;

  const classes = ClassNames(styles.sidebar__footer);
  return (
    <div className={classes}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}

interface PageWithLayoutMainContentProps {
  children: React.ReactNode;
  hasSidebarOnRight?: boolean;
  hasMaxWidth?: boolean;
  isScrollable?: boolean;
}

export function PageWithLayoutMainContent(props: PageWithLayoutMainContentProps): React.ReactElement {
  const { hasSidebarOnRight = true, hasMaxWidth = false, isScrollable = false, children } = props;
  const classes = ClassNames(styles['page-with-layout__main'], {
    [styles['page-with-layout__main--sidebar-on-right']]: hasSidebarOnRight,
    [styles['page-with-layout__main--max-width']]: hasMaxWidth,
    [styles['page-with-layout__main--scrollable']]: isScrollable,
  });

  return (
    <div className={classes}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}

interface FooterProps {
  children: React.ReactNode;
}

export function Footer(props: FooterProps): React.ReactElement {
  const classes = ClassNames(styles.footer);

  const { children } = props;
  return <div className={classes}>{children}</div>;
}

type ScrollableContainerShadowAndButtonProps = {
  showButton?: boolean;
  handleScroll: (val: 'left' | 'right') => void;
};

function ScrollableContainerShadowAndButton(props: ScrollableContainerShadowAndButtonProps) {
  const { handleScroll, showButton } = props;
  return (
    <>
      <div className={ClassNames(styles['scrollable-container__shadow'], styles['scrollable-container__shadow-top'])} />
      <div
        className={ClassNames(styles['scrollable-container__shadow'], styles['scrollable-container__shadow-left'])}
      />
      <div
        className={ClassNames(styles['scrollable-container__shadow'], styles['scrollable-container__shadow-right'])}
      />
      <div
        className={ClassNames(styles['scrollable-container__shadow'], styles['scrollable-container__shadow-bottom'])}
      />
      {showButton && (
        <>
          <button
            type="button"
            onClick={() => handleScroll('left')}
            className={ClassNames(styles['scrollable-container__button'], styles['scrollable-container__button-left'])}
          >
            <Color shade={COLOR_SHADES.FROM_THEME_CONTRAST} inline>
              <Icon name="chevron_compact_left" size={ICON_SIZES.MD} />
            </Color>
          </button>
          <button
            type="button"
            className={ClassNames(styles['scrollable-container__button'], styles['scrollable-container__button-right'])}
            onClick={() => handleScroll('right')}
          >
            <Color shade={COLOR_SHADES.FROM_THEME_CONTRAST} inline>
              <Icon name="chevron_compact_right" size={ICON_SIZES.MD} />
            </Color>
          </button>
        </>
      )}
    </>
  );
}

export enum SCROLLABLE_CONTAINER_SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  DEFAULT = 'default',
}

interface ScrollableContainerProps {
  children: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
  size?: SCROLLABLE_CONTAINER_SIZE;
  showShadow?: boolean;
  style?: CSSProperties;
  hideScrollBar?: boolean;
  scrollTo?: string;
  hideButton?: boolean;
}

export function ScrollableContainer(props: ScrollableContainerProps): React.ReactElement {
  const {
    children,
    size = SCROLLABLE_CONTAINER_SIZE.DEFAULT,
    direction = 'horizontal',
    showShadow,
    style,
    hideScrollBar = false,
    scrollTo,
    hideButton,
  } = props;
  const $container = useRef<HTMLDivElement>(null);

  function handleScroll(scrollButton: 'left' | 'right') {
    const scrollOffset = 80;
    const scrollOffsetBasedOnDirection = scrollButton === 'right' ? scrollOffset : -scrollOffset;
    if ($container.current) {
      $container.current.scrollLeft += scrollOffsetBasedOnDirection;
    }
  }

  useEffect(() => {
    if (scrollTo && $container.current) {
      BrowserUtils.scrollTo(scrollTo, false, 0, $container.current);
    }
  }, [$container.current, scrollTo]);

  const scrollInfo = useScroll({
    container: $container.current || document.body,
    target: $container.current || document.body,
  });

  const wrapperClasses = ClassNames(
    styles['scrollable-container-wrapper'],
    styles[`scrollable-container-wrapper--${direction}`],
    showShadow
      ? {
          [styles['scrollable-container--show-shadow-top']]: !scrollInfo.isOnTop,
          [styles['scrollable-container--show-shadow-bottom']]: !scrollInfo.isOnBottom,
          [styles['scrollable-container--show-shadow-left']]: !scrollInfo.isOnLeft,
          [styles['scrollable-container--show-shadow-right']]: !scrollInfo.isOnRight,
        }
      : {},
    !hideButton
      ? {
          [styles['scrollable-container--show-button-left']]: !scrollInfo.isOnLeft,
          [styles['scrollable-container--show-button-right']]: !scrollInfo.isOnRight,
        }
      : {},
  );

  const classes = ClassNames(
    styles['scrollable-container'],
    styles[`scrollable-container--${direction}`],
    styles[`scrollable-container--size-${size}`],
    {
      [styles['scrollable-container--hide-scrollbar']]: hideScrollBar,
    },
  );

  return (
    <div className={wrapperClasses}>
      <div className={classes} ref={$container} style={style}>
        {children}
      </div>
      <ScrollableContainerShadowAndButton handleScroll={handleScroll} showButton={!hideButton} />
    </div>
  );
}

interface DividerProps {
  vertical?: boolean;
  color?: COLOR_SHADES;
}

export function Divider(props: DividerProps): React.ReactElement {
  const { vertical = false, color = COLOR_SHADES.LIGHTER } = props;
  const classes = ClassNames(styles.divider, {
    [styles['divider--vertical']]: vertical,
    [styles[`divider--background-color-${color}`]]: color,
  });

  return <div className={classes} />;
}

export enum SECTION_STYLES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  WHITE = 'white',
  GRADIENT = 'gradient',
  GRADIENT_BLUE = 'gradient-blue',
  YELLOW_LIGHT = 'yellow-light',
  BLACK_WITH_WHITE = 'black-with-white',
}

interface SectionProps {
  children: React.ReactNode;
  style?: SECTION_STYLES;
  hasPadding?: boolean;
}

export function Section(props: SectionProps): React.ReactElement {
  const { children, style = SECTION_STYLES.DEFAULT, hasPadding = true } = props;
  const classes = ClassNames(styles.section, styles[`section--style-${style}`], {
    [styles['section--padding']]: hasPadding,
  });

  return <div className={classes}>{children}</div>;
}

type InnerPageProps = {
  children: React.ReactNode;
};

export function InnerPage(props: InnerPageProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['inner-page']);

  return <div className={classes}>{children}</div>;
}

interface InnerPageHeaderProps {
  children: string | React.ReactNode;
  iconName: string;
  title: string;
  backTo?: string;
  backToTitle?: string;
  backToFunction?: () => void;
  rightActions?: React.ReactNode;
  subHeader?: React.ReactNode;
}

export function InnerPageHeader(props: InnerPageHeaderProps): React.ReactElement {
  const {
    children,
    iconName,
    title,
    backTo = '',
    backToFunction,
    rightActions,
    backToTitle = 'Back',
    subHeader,
  } = props;
  const showBackButton = !!(backTo && backTo.length > 0) || !!backToFunction;
  const classes = ClassNames(styles['inner-page__header']);
  useEffect(() => {
    document.title = `${unescape(title)} - Premagic`;
  });

  return (
    <div className={classes}>
      {showBackButton && backTo && (
        <div className={ClassNames(styles['inner-page__header__back-button'])}>
          <Link to={backTo}>
            <ButtonIcon title={backToTitle} onClick={backToFunction}>
              <Icon name="arrow_left" size={ICON_SIZES.SM} />
            </ButtonIcon>
          </Link>
        </div>
      )}
      <Row vcenter>
        <Color shade={COLOR_SHADES.DARKER} inline>
          <Icon name={iconName} accentStyle={ICON_ACCENT_STYLES.FILL} />
        </Color>
        <Space size={2} />
        <Text size={TEXT_SIZE.SIZE_1} block color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          {children}
        </Text>
        {rightActions && (
          <Col rightAlighed size={null}>
            {rightActions}
          </Col>
        )}
      </Row>
      {subHeader && <Row>{subHeader}</Row>}
    </div>
  );
}

interface InnerPageHeaderFloatingTagProps {
  children: React.ReactNode;
}

export function InnerPageHeaderFloatingTag(props: InnerPageHeaderFloatingTagProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['inner-page__header__floating-tag']);

  return <div className={classes}>{children}</div>;
}

type InnerPageSubHeaderProps = {
  children: React.ReactNode;
};

export function InnerPageSubHeader(props: InnerPageSubHeaderProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['inner-page__sub-header']);

  return (
    <div className={ClassNames(styles['inner-page__sub-header-wrapper'], 'hidden-print')}>
      <ScrollableContainer hideScrollBar showShadow direction="horizontal">
        <div className={classes}>{children}</div>
      </ScrollableContainer>
    </div>
  );
}

type InnerPageContentProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  hasInnerCols?: boolean;
};

export function InnerPageContent(props: InnerPageContentProps): React.ReactElement {
  const { children, isLoading = false, hasInnerCols = false } = props;
  const classes = ClassNames(styles['inner-page__content'], {
    [styles['inner-page__content--has-inner-cols']]: hasInnerCols,
  });

  return (
    <LoadingBox isLoading={isLoading}>
      <div className={classes}>{children}</div>
    </LoadingBox>
  );
}
type InnerPageContentMainColProps = {
  children: React.ReactNode;
  hasSidebar?: boolean;
};

export function InnerPageContentMainCol(props: InnerPageContentMainColProps): React.ReactElement {
  const { children, hasSidebar = true } = props;

  return (
    <Col size={hasSidebar ? 8 : 12} screenSMSize={12}>
      {children}
    </Col>
  );
}
type InnerPageContentSidebarProps = {
  children: React.ReactNode;
};
export function InnerPageContentSidebar(props: InnerPageContentSidebarProps): React.ReactElement {
  const { children } = props;

  return (
    <>
      <Space size={8} />
      <Col size={4} screenSMSize={12}>
        {children}
        <Space size={8} vertical />
      </Col>
    </>
  );
}
export enum PAGE_COVER_PHOTO {
  DEFAULT = 'default',
  WITH_FOOTER = 'with-footer',
  FULL_IMAGE = 'full-image',
  BACKGROUND_BLUR = 'background-blur',
  CONTAIN = 'contain',
}

type PageWithCoverProps = {
  title: string;
  children?: React.ReactNode;
  style: PAGE_COVER_PHOTO;
  width: number;
  height: number;
  focalPoint?: { x: number; y: number };
  file: {
    asset_type: ASSET_TYPES;
    dynamic_image_url: string;
    location: string;
    meta?: {
      width?: number;
      height?: number;
      ratio?: number;
    };
    url?: string;
  };
};

export function PageWithCover(props: PageWithCoverProps): React.ReactElement {
  const { file, title, children, style = PAGE_COVER_PHOTO.DEFAULT, focalPoint, width, height } = props;
  const { asset_type: type, meta, dynamic_image_url: imageUrl, location: videoUrl } = file;

  const [showContent, setShowContent] = useState(false);
  const classes = ClassNames(styles['page__cover-photo'], styles[`page__cover-photo--style-${style}`], {
    [styles['page__cover-photo--with-content']]: children,
    [styles['page__cover-photo--video']]: type === ASSET_TYPES.VIDEO,
  });

  const contentClasses = ClassNames(
    styles['page__cover-photo__content'],
    styles[`page__cover-photo__content--style-${style}`],
  );

  function handleRefCallback(n: HTMLDivElement | null) {
    if (!n) return;
    const { height: componentHeight } = n.getBoundingClientRect();
    BrowserUtils.setCSSVariableValue('--cover-photo-footer-size', `${componentHeight}px`);
  }

  let imageStyle;

  if (style === PAGE_COVER_PHOTO.CONTAIN) {
    imageStyle = IMAGE_V2_STYLES.FULL_IMAGE;
  } else {
    imageStyle =
      !focalPoint && style === PAGE_COVER_PHOTO.WITH_FOOTER ? IMAGE_V2_STYLES.FULL_HEIGHT : IMAGE_V2_STYLES.COVER;
  }

  let imageCssStyle = {};

  if (style === PAGE_COVER_PHOTO.BACKGROUND_BLUR) {
    imageCssStyle = { borderRadius: '5px' };
  }

  useEffect(() => {
    if (style === PAGE_COVER_PHOTO.CONTAIN) {
      if (file?.meta?.ratio) {
        const heightBasedOnWidthAndRatio = MediaUtils.getImageHeightForRatio(width, file.meta.ratio);
        BrowserUtils.setCSSVariableValue('--page-cover-photo-height-based-on-ratio', `${heightBasedOnWidthAndRatio}px`);
      }
    }
  }, [style, file?.meta?.ratio, width]);

  useEffect(() => {
    setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  }, []);

  if (type === ASSET_TYPES.VIDEO) {
    return (
      <div className={classes}>
        <div className={ClassNames(styles['page__cover-photo__cover'])}>
          <VideoJsPlayer
            src={videoUrl}
            autoplay
            style={VIDEO_PLAYER_STYLES.DEFAULT_INLINE}
            type={VIDEO_TYPES.STREAM}
            poster={imageUrl}
          />
        </div>
        {children && (
          <div className={contentClasses} ref={handleRefCallback}>
            {children}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={classes}>
      <div className={ClassNames(styles['page__cover-photo__cover'])}>
        <ImageV2
          src={imageUrl}
          alt={title}
          width={width}
          height={height}
          ratio={meta?.ratio}
          imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.HIGHLIGHT}
          style={imageStyle}
          focalPoint={style !== PAGE_COVER_PHOTO.CONTAIN ? focalPoint : undefined}
          fromTheme
          cssStyle={imageCssStyle}
        />
      </div>
      {children && (
        <div className={contentClasses} ref={handleRefCallback}>
          {(() => {
            if ([PAGE_COVER_PHOTO.FULL_IMAGE, PAGE_COVER_PHOTO.BACKGROUND_BLUR].includes(style))
              return showContent ? <FadeIn duration="slow">{children}</FadeIn> : null;
            return children;
          })()}
        </div>
      )}
      {/* Background Image for Blurred Image layout */}
      {style === PAGE_COVER_PHOTO.BACKGROUND_BLUR && (
        <div className={ClassNames(styles['page__cover-photo__background--blurred-image'])}>
          <ImageV2
            src={imageUrl}
            style={IMAGE_V2_STYLES.COVER}
            alt={title}
            width={width}
            height={height}
            ratio={meta?.ratio}
            imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.HIGHLIGHT}
            focalPoint={focalPoint}
            fromTheme
          />
        </div>
      )}
    </div>
  );
}

type BluredViewProps = {
  children: React.ReactNode;
};

export function BluredView(props: BluredViewProps): JSX.Element {
  const { children } = props;
  return <div className={styles['blured-view']}>{children}</div>;
}

type UnselectableTextProps = {
  children: React.ReactNode;
};

export function UnselectableText(props: UnselectableTextProps): JSX.Element {
  const { children } = props;
  return <div className={styles['unselectable-text']}>{children}</div>;
}
