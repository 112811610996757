import { PosterWidgetType } from '@premagic/poster-creator';
import { EventAttendeesService, EventPosterService, EventTrackerService, RouterService, Schemas } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import {
  ActionTypeUtils,
  ArrayUtils,
  BrowserUrlUtils,
  CryptoUtils,
  ErrorTracker,
  SimpleDateUtils,
} from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { debounce, isEmpty, omit } from 'lodash';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { pagesSelector } from '../../../reducers/selectors';
import APP_URLS from '../../../services/AppRouteURLService';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_POSTER');

const setEventPosterData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const addEventPosterData = createAction(getActionType('DATA', 'SET_SINGLE'), (dispatch, id, data) => ({
  [id]: data,
}));

const updateEventPosterData = createAction(getActionType('DATA', 'UPDATE_SINGLE'), (dispatch, id, data) => ({
  id,
  data,
}));

export const removeEventPosterData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const getEventPostersForProject = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.EVENT_POSTERS.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  EventPosterService.fetchEventPostersForProject(projectId)
    .then((response) => {
      if (!isEmpty(response)) {
        const normalizedData = normalize(response, Schemas.EventPosters);
        dispatch(setEventPosterData(dispatch, normalizedData.entities.posters));
      } else {
        dispatch(setEventPosterData(dispatch, {}));
      }
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setEventPosterData(dispatch, {}));
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('EVENT_POSTERS_FETCH', e);
    });
});

export const getEventPosterDetails = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string, posterId: string) => {
    const loadingKey = LOADINGS.EVENT_POSTERS.SINGLE_FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventPosterService.fetchEventPosterDetails(projectId, posterId)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(addEventPosterData(dispatch, response.id, response));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_POSTER_FETCH', e);
      });
  },
);

export const addEventPoster = createAction(
  getActionType('ACTION', 'CREATE'),
  (
    dispatch,
    options: {
      projectId: string;
      eventId: string;
    },
    formResponse: FormResponseType & {
      data: EventPosterService.EventPosterType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_POSTERS.CREATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    const { projectId, eventId } = options;

    EventPosterService.createEventPoster(
      projectId,
      Object.assign(
        {},
        {
          widgets: EventPosterService.getDefaultPosterWidgetsData({
            posterSize: data.size,
            textColor: '#888',
            type: data.type,
          }),
        },
        data,
      ),
    )
      .then((response) => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EVENT_POSTERS.CREATE, {
          name: data.name,
        });
        const posterEdit = BrowserUrlUtils.getRouteUrlFor(APP_URLS.EVENT_POSTER.EDIT, {
          eventId,
          projectId,
          posterId: response.id,
          type: response.type,
        });
        dispatch(addEventPosterData(dispatch, response.id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_ADD_PANEL, false));
        RouterService.navigateTo(dispatch, posterEdit);

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('EVENT_POSTER_ADD', e);
      });
  },
);

export const updateEventPoster = createAction(
  getActionType('ACTION', 'UPDATE'),
  (
    dispatch,
    options: { projectId: string; posterId: string },
    formResponse: FormResponseType & {
      data: EventPosterService.EventPosterType;
    },
  ) => {
    const { data } = formResponse;
    const loadingKey = LOADINGS.EVENT_POSTERS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EVENT_POSTERS.UPDATE, options);
    EventPosterService.updateEventPoster(options, data)
      .then((response) => {
        dispatch(updateEventPosterData(dispatch, response.id, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_EDIT_PANEL, false));

        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_POSTER', e);
      });
  },
);

export const removeEventPoster = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, projectId: string, posterId: string) => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EVENT_POSTERS.DELETE, {
      posterId,
    });
    dispatch(removeEventPosterData(dispatch, posterId));

    EventPosterService.deleteEventPoster(projectId, posterId).then(
      (data) =>
        // @TODO
        // const postersPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MARKETING, {
        //   eventId,
        //   projectId,
        //   posterId: response.id,
        //   type: response.type,
        // });
        // RouterService.navigateTo(dispatch, postersPage);
        data,
    );
  },
);

const debouncedUpdateEventPoster = debounce(EventPosterService.updateEventPoster, 3000);

export const updateEventPosterWidget = createAction(
  getActionType('POSTER_WIDGET', 'EDIT'),
  (
    dispatch,
    projectId: string,
    data: {
      poster: EventPosterService.EventPosterType;
      widgetToUpdate: PosterWidgetType;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_POSTERS.UPDATE_WIDGET;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const { poster, widgetToUpdate } = data;
    const updatePosterData = {
      widgets: Object.assign({}, poster.widgets, {
        [widgetToUpdate.id]: {
          // We are removing the value because we dont want to update them in the backend, then it will show this information on the posters for the attendee
          // this is mainly when user drag and drop the widget
          // ...omit(widgetToUpdate, ['value']),

          ...omit(widgetToUpdate, ['is_system_widget']),
          created_at: widgetToUpdate.created_at || SimpleDateUtils.now(),
          updated_at: SimpleDateUtils.now(),
        },
      }),
    };
    dispatch(updateEventPosterData(dispatch, poster.id, updatePosterData));

    debouncedUpdateEventPoster(
      {
        projectId,
        posterId: poster.id,
      },
      updatePosterData,
    )
      ?.then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('UPDATE_EVENT_POSTER_WIDGET', e);
      });
  },
);

export const deleteEventPosterWidget = createAction(
  getActionType('POSTER_WIDGET', 'DELETE'),
  (
    dispatch,
    projectId: string,
    data: {
      poster: EventPosterService.EventPosterType;
      widgetId: string;
    },
  ) => {
    const loadingKey = LOADINGS.EVENT_POSTERS.DELETE_WIDGET;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { poster, widgetId } = data;
    const updatePosterData = {
      widgets: omit(poster.widgets, widgetId),
    };

    dispatch(updateEventPosterData(dispatch, poster.id, updatePosterData));

    EventPosterService.updateEventPoster(
      {
        projectId,
        posterId: poster.id,
      },
      updatePosterData,
    )
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e?.data?.error || e?.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('DELETE_EVENT_POSTER_WIDGET', e);
      });
  },
);
export const duplicateEventPosterWidget = createAction(
  getActionType('POSTER_WIDGET', 'DUPLICATE'),
  (
    dispatch,
    projectId: string,
    data: {
      poster: EventPosterService.EventPosterType;
      widgetId: string;
    },
  ) => {
    const { poster, widgetId } = data;
    const widget = poster.widgets[widgetId];
    const duplicatedWidget = {
      ...widget,
      id: CryptoUtils.createUUID(),
      name: `${widget.name} Copy`,
      position: {
        ...widget.position,
        x: widget.position.x + 64,
        y: widget.position.y + 10,
      },
    };

    dispatch(updateEventPosterWidget(dispatch, projectId, { poster, widgetToUpdate: duplicatedWidget }));
  },
);

type EventPosterItemType = IMap<string, EventPosterService.EventPosterType>;
type StateType = {
  items: EventPosterItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setEventPosterData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventPosterData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateEventPosterData.toString()]: (
      state,
      action: {
        payload: any;
      },
    ) => ({
      ...state,
      items: state.items.update(action.payload.id, (poster) => Object.assign({}, poster, action.payload.data)),
    }),
    [removeEventPosterData.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

const eventPosterDataSelector = createSelector(pagesSelector, (pages) => pages.eventPoster.data.posters as StateType);

export const eventPosterEntitiesSelector = createSelector(eventPosterDataSelector, (posters) =>
  posters.items.sort((a, b) => ArrayUtils.stringSortFunction(a.name, b.name)),
);

export const eventPostersSelector = createSelector(eventPosterEntitiesSelector, (posters) => posters.toJSON());
export const eventPostersForPreEventSelector = createSelector(eventPosterEntitiesSelector, (posters) =>
  posters.filter((poster) => poster.type === EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT),
);

export const eventPostersForPreEventArraySelector = createSelector(eventPostersForPreEventSelector, (posters) =>
  posters.valueSeq().toArray(),
);

export const eventPostersForMagazineSelector = createSelector(eventPosterEntitiesSelector, (posters) =>
  posters.filter((poster) => poster.type === EventPosterService.EVENT_POSTER_TYPE.MAGAZINE),
);

export const eventPostersForMagazineArraySelector = createSelector(eventPostersForMagazineSelector, (posters) =>
  posters.valueSeq().toArray(),
);

export const eventPosterIdsForTypeSelector = (type: EventPosterService.EVENT_POSTER_TYPE) =>
  createSelector(eventPosterEntitiesSelector, (posters) =>
    posters
      .filter((poster) => poster.type === type)
      .keySeq()
      .toArray(),
  );

export const eventPosterWidgetsArraySelector = (posterId: string) =>
  createSelector(eventPosterEntitiesSelector, (posters) => {
    const widgets = posters.get(posterId)?.widgets;
    if (!widgets) return [];
    return Object.values(widgets).sort((a, b) => b.position.z - a.position.z);
  });

export const eventPostersByScopeSelector = (scope: EventAttendeesService.EVENT_PEOPLE_TYPE) =>
  createSelector(eventPosterEntitiesSelector, (posters) => posters.filter((item) => item.scope.includes(scope)));

export const eventPostersDataByScopeSelector = (scope: EventAttendeesService.EVENT_PEOPLE_TYPE) =>
  createSelector(eventPostersByScopeSelector(scope), (items) => items.toJSON());

export const eventPostersArrayByScopeSelector = (scope: EventAttendeesService.EVENT_PEOPLE_TYPE) =>
  createSelector(eventPostersByScopeSelector(scope), (items) => items.valueSeq().toArray());

export const eventBadgesArrayByScopeSelector = (scope: EventAttendeesService.EVENT_PEOPLE_TYPE) =>
  createSelector(
    eventPosterEntitiesSelector,
    eventPosterIdsForTypeSelector(EventPosterService.EVENT_POSTER_TYPE.BADGE),
    (posters, badges) => badges.filter((badgeId) => posters.get(badgeId)?.scope.includes(scope)),
  );
