/* eslint-disable react/destructuring-assignment */
import {
  AuthUtils,
  BrowserUtils,
  CountryCodeUtils,
  CurrencyUtils,
  FormUtils,
  NumberUtils,
  SimpleDateUtils,
  TimezoneUtils,
  UserLocationUtils,
} from '@premagic/utils';
import ClassNames from 'classnames';
import { FilePondInitialFile } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { filter, isArray, isEmpty, isString } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import LibDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './lib-date-picker-overrides.css';
import { FilePond, registerPlugin } from 'react-filepond';

import { useInView } from 'react-intersection-observer';
import ReactSelect, { components as SelectComponents } from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import { Button, BUTTON_ICON_STYLES, BUTTON_SIZE, BUTTON_STYLES, ButtonIcon } from '../Button/Buttons';
import { COLOR_SHADES } from '../Color/Color';
import { Dialog, DIALOG_PLACEMENT } from '../Dialog';
import { ErrorBoundary } from '../Error/Error';
import { Col, PreventMarginCollapse, Row, Space } from '../Grid/Grid';
import { Icon, ICON_ACCENT_STYLES, ICON_SIZES, IconFromSVGUrl } from '../Icon/Icons';
import { LoadingDots } from '../Loading/Loading';
import { Overlay } from '../Popover/Popover';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './form.module.css';
import { FormContext } from './FormContext';

const POSSIBLE_CONTAINER = {
  GENERAL_EDITOR_SIDEBAR: '.js-general-editor-sidebar-right',
  WINDOW_PANE: '.js-window-panel-content',
};

interface GenericImageType {
  download: string;
  key: string;
  ratio: number;
  url: string;
}
interface FormErrorProps {
  children: React.ReactNode;
}

export function FormError(props: FormErrorProps): React.ReactElement {
  const { children } = props;
  return (
    <div className="js-form-error">
      <Text block color={COLOR_SHADES.DANGER}>
        {children}
      </Text>
    </div>
  );
}

export interface FormErrorType {
  [inputName: string]: string;
  // __all__?: string;
  // non_field_errors?: string;
  // message?: string;
}

export interface FormResponseType {
  data: {
    [inputName: string]: string | boolean | number | Array<string> | Record<string, string | boolean | number>;
  };
  errors?: FormErrorType;
}

export interface FormProps {
  children: React.ReactNode;
  formSpec?: FormUtils.FormSpecType;
  onSubmit: (formResponse: FormResponseType, e: React.FormEvent<HTMLFormElement>) => void;
  errors?: FormErrorType;
}

export function Form(props: FormProps): React.ReactElement {
  const {
    children,
    onSubmit,
    formSpec = {},
    errors = { __all__: null, non_field_errors: null, message: null },
    ...elementProps
  } = props;

  function handleOnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    const formData = FormUtils.getMeFormData(e.target as HTMLFormElement, formSpec);
    if (isEmpty(formData.errors)) {
      onSubmit(
        {
          data: formData.data,
        },
        e,
      );
      return;
    }
    onSubmit(formData, e);
  }

  // eslint-disable-next-line no-underscore-dangle
  const genericFormError = errors && (errors.__all__ || errors.non_field_errors || errors.message); // added errors.message recently for eventRegister api on account app
  return (
    <form {...elementProps} onSubmit={handleOnSubmit}>
      <FormContext.Provider value={errors}>
        {genericFormError && <FormError>{genericFormError}</FormError>}
        <ErrorBoundary>{children}</ErrorBoundary>
      </FormContext.Provider>
    </form>
  );
}

interface FormGroupProps {
  children: React.ReactNode;
  error?: { message: string } | string | null;
}

export function FormGroup(props: FormGroupProps): React.ReactElement {
  const { children, error = null } = props;
  const classes = ClassNames(styles['form-group'], {
    [styles['form-group--error']]: !!error,
  });

  return (
    <div className={classes}>
      {children}
      {error && (
        <div>
          <Space vertical size={2} />
          <FormError>{isString(error) ? error : error.message}</FormError>
        </div>
      )}
    </div>
  );
}

interface FormFooterProps {
  children: React.ReactNode;
}

interface FormLabelProps {
  children: React.ReactNode;
  info?: string;
  isRequired?: boolean;
}

export function FormLabel(props: FormLabelProps): React.ReactElement {
  const { children, info, isRequired } = props;

  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} size={TEXT_SIZE.SIZE_5}>
        {children}
      </Text>
      {isRequired && <Text color={COLOR_SHADES.DANGER}>*</Text>}
      {info && (
        <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
          <Space />({info})
        </Text>
      )}
      <Space vertical size={1} />
    </div>
  );
}

export function FormFooter(props: FormFooterProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['form-footer']);

  return (
    <PreventMarginCollapse>
      <Space vertical />
      <Row>
        <Col size={null} className={classes} rightAlighed>
          <Row vcenter inline>
            {children}
          </Row>
        </Col>
      </Row>
    </PreventMarginCollapse>
  );
}

interface InputWithButtonIconWrapperProps {
  children: Array<JSX.Element>;
}

function InputWithButtonIconWrapper(props: InputWithButtonIconWrapperProps) {
  const { children } = props;

  const classes = ClassNames(styles['input__button-icon-container']);

  return <span className={classes}>{children}</span>;
}

export enum INPUT_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  AUTO = 'auto',
  DEFAULT = 'default',
}

export enum INPUT_STYLES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface InputProps {
  className?: string | void;
  name: string;
  size?: INPUT_SIZES;
  style?: INPUT_STYLES;
  defaultValue?: string | number;
  buttonIcon?: JSX.Element; // Pass InputButtonIcon, to add button icon side the input
  iconPlacement?: 'left' | 'right';
  prefix?: string;
  [elementProps: string]: unknown;
}

export const Input = React.forwardRef((props: InputProps, ref: any) => {
  const {
    className = '',
    name,
    size = INPUT_SIZES.DEFAULT,
    buttonIcon,
    style = INPUT_STYLES.PRIMARY,
    iconPlacement = 'right',
    prefix,
    ...elementProps
  } = props;
  const errors = useContext(FormContext);
  const classes = ClassNames(styles.input, styles[`input--size-${size}`], styles[`input--style-${style}`], className, {
    [styles[`input--with-${iconPlacement}-button-icon`]]: buttonIcon,
  });
  if (buttonIcon) {
    return (
      <InputWithButtonIconWrapper>
        <span className={ClassNames(styles['input__button-icon'], styles[`input__button-icon--${iconPlacement}`])}>
          {buttonIcon}
        </span>
        <input className={classes} name={name} autoComplete="off" ref={ref} {...elementProps} />
      </InputWithButtonIconWrapper>
    );
  }
  if (prefix) {
    return (
      <>
        <div className={ClassNames(classes, styles['input-with-prefix'])}>
          <div className={styles['input-with-prefix__prefix']}>
            <Text size={TEXT_SIZE.SIZE_5}>{prefix}</Text>
          </div>
          <input
            className={ClassNames(styles['input-with-prefix__input'])}
            name={name}
            autoComplete="off"
            ref={ref}
            {...elementProps}
          />
        </div>
        {errors && errors[name] && <FormError>{errors[name]}</FormError>}
      </>
    );
  }
  return (
    <>
      <input className={classes} name={name} autoComplete="off" ref={ref} {...elementProps} />
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
    </>
  );
});

export interface InputCurrencyProps extends InputProps {
  defaultValue?: number; // In micro currency
  currencyCode: string;
  onChange?: (number) => void;
  value?: number;
}
export function InputCurrency(props: InputCurrencyProps): React.ReactElement {
  const {
    className = '',
    name,
    size = INPUT_SIZES.DEFAULT,
    defaultValue,
    currencyCode,
    style,
    value = -1,
    onChange,
    ...elementProps
  } = props;
  const errors = useContext(FormContext);
  const [numeralValue, setNumeralValue] = useState(CurrencyUtils.getAmountFromMicroCurrency(defaultValue || 0));
  const classes = ClassNames(
    styles.input,
    styles['input--style-primary'],
    styles[`input--size-${size}`],
    styles['input--align-right'],
    className,
  );

  useEffect(() => {
    if (value > -1) setNumeralValue(CurrencyUtils.getAmountFromMicroCurrency(value));
  }, [value]);

  return (
    <>
      <input
        className={classes}
        autoComplete="off"
        value={CurrencyUtils.getCurrencyInFormat(numeralValue * CurrencyUtils.MICRO_AMOUNT, currencyCode)}
        onChange={(e) => {
          const numericValue = NumberUtils.getNumberFromFormat(e.currentTarget.value);
          setNumeralValue(numericValue);
          onChange?.(CurrencyUtils.getCurrencyInMicroAmount(numericValue));
        }}
        {...elementProps}
      />
      <input name={name} type="hidden" value={CurrencyUtils.getCurrencyInMicroAmount(numeralValue)} />
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
    </>
  );
}

interface TextareaProps {
  className?: string | void;
  name: string;
  autoFocus?: boolean;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  children?: React.ReactNode;
  onChange?: (e) => void;
  readOnly?: boolean;
}

export function Textarea(props: TextareaProps): React.ReactElement {
  const { className = '', name, children, onChange, ...elementProps } = props;

  const classes = ClassNames(styles.input, styles['input--style-primary'], className);
  const errors = useContext(FormContext);

  if (children) {
    return (
      <>
        <textarea className={classes} name={name} cols={5} rows={6} onChange={onChange} {...elementProps}>
          {children}
        </textarea>
        {errors && errors[name] && <FormError>{errors[name]}</FormError>}
      </>
    );
  }

  return (
    <>
      <textarea className={classes} name={name} cols={5} rows={6} onChange={onChange} {...elementProps} />
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
    </>
  );
}

interface CheckboxProps {
  checked?: boolean;
  readOnly?: boolean;
}

export const Checkbox = React.forwardRef((props: CheckboxProps, ref: any) => {
  const { checked = false, readOnly = false } = props;

  return (
    <span
      className={ClassNames(styles.checkbox, {
        [styles['checkbox--checked']]: checked,
      })}
    >
      <input type="checkbox" {...props} tabIndex={-1} />
      <span className={styles.checkbox__icon}>
        <Icon name="check" size={ICON_SIZES.SM} />
      </span>
    </span>
  );
});

interface RealCheckboxProps {
  children?: React.ReactNode;
  name: string;
  id?: string; // this can be used if you need to different id for checkbox with same names(otherwise when the use clicks on checkbox only the first one will be checked)
  defaultChecked?: boolean;
  defaultValue?: boolean; // this was added to handle the case when the component is rendered from InputTypes.tsx
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e) => void;
  disabledTooltipMessage?: string;
  value?: string;
}

export function RealCheckbox(props: RealCheckboxProps): React.ReactElement {
  const { children, name, id, defaultChecked, defaultValue, disabledTooltipMessage, ...elementProps } = props;
  if (children)
    return (
      <label htmlFor={id || name}>
        <Row vcenter>
          <input
            name={name}
            id={id || name}
            type="checkbox"
            defaultChecked={defaultChecked || defaultValue}
            {...elementProps}
          />
          <Space size={2} />
          <Text>{children}</Text>
        </Row>
      </label>
    );
  return (
    <label htmlFor={id || name}>
      <Tooltip message={elementProps.disabled ? disabledTooltipMessage : ''}>
        <input
          name={name}
          id={id || name}
          type="checkbox"
          defaultChecked={defaultChecked || defaultValue}
          {...elementProps}
        />
      </Tooltip>
    </label>
  );
}

interface RadioButtonProps {
  children: React.ReactNode;
  name: string;
  value: string;
  defaultChecked?: boolean;
  onClick?: (e) => void;
  disabled?: boolean;
}

export function RadioButton(props: RadioButtonProps): JSX.Element {
  const { children, name, value, defaultChecked, disabled, ...elementProps } = props;
  const id = `${name}-${value}`;
  return (
    <div className={styles.radio}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        disabled={disabled}
        {...elementProps}
      />
      <label htmlFor={id} className={styles.radio__text}>
        <Text>{children}</Text>
      </label>
    </div>
  );
}

interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  name: string;
  isLoading?: boolean;
}

export function ToggleSwitch(props: ToggleSwitchProps): React.ReactElement {
  const { onChange, checked, disabled = false, isLoading, ...elementProps } = props;
  return (
    <div
      className={ClassNames(styles['toggle-switch'], {
        [styles['toggle-switch--disabled']]: disabled || isLoading,
        [styles['toggle-switch--loading']]: isLoading,
      })}
    >
      <input
        type="checkbox"
        className={styles['toggle-switch__input']}
        onChange={() => {
          if (!disabled) onChange(!checked);
        }}
        checked={checked}
        {...elementProps}
      />
      <span className={styles['toggle-switch__well']} />
      {isLoading && (
        <div className={styles['toggle-switch__loading']}>
          <LoadingDots size="sm" />
        </div>
      )}
      <span
        className={ClassNames(styles['toggle-switch__thumb'], {
          [styles['toggle-switch__thumb--right']]: checked,
        })}
      />
    </div>
  );
}

function filesNotMatchingSpecifiedFormats(files: Array<File>, accept: string) {
  const fileFormatList = accept.split(',').map((format) => format.trim());
  return filter(files, (file) => !fileFormatList.includes(file.type));
}

interface FileUploaderProps {
  children: React.ReactNode;
  onChange: (e: Event, file: Array<File>) => void;
  onError: (message: string, files: Array<File>, e: Event) => void;
  accept: string;
  maxSize?: number;
  dimensions?: {
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
  };
}

export function FileUploader(props: FileUploaderProps) {
  const { children, onChange, onError, accept, maxSize = 4, dimensions, ...elemProps } = props;
  let input: HTMLInputElement | null;

  const validateDimensions = useCallback(
    (files, invalidFiles, event) => {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new Image();
        image.src = (e?.target?.result as string) || '';
        image.onload = () => {
          if (image.width < (dimensions?.minWidth || 0)) {
            onError(
              `File is width is ${image.width}px! Upload a file with min width of ${dimensions?.minWidth}px`,
              invalidFiles,
              event,
            );
            return;
          }

          if (image.height < (dimensions?.minHeight || 0)) {
            onError(
              `File is height is ${image.height}px! Upload a file with min height of ${dimensions?.minHeight}px`,
              invalidFiles,
              event,
            );
            return;
          }
          if (dimensions?.width && dimensions?.height) {
            if (image.width !== dimensions.width && image.height !== dimensions.height) {
              onError(
                `File is ${image.width}x${image.height}px! Upload a file with ${dimensions?.width}x${dimensions?.height}px`,
                invalidFiles,
                event,
              );
              return;
            }
          }
          onChange(e, files);
        };
      };
    },
    [dimensions, onChange, onError],
  );

  const handleChange = useCallback(
    (e) => {
      const { files } = e.currentTarget;
      if (files.length === 0) return;

      const invalidFiles = filesNotMatchingSpecifiedFormats(files, accept);
      const fileSize = Math.ceil(files[0].size / 1024 / 1024); // in MB

      if (fileSize > maxSize) {
        return onError(
          `File size(${fileSize}MB) is too large! Should be less than ${maxSize}MB for better performance`,
          invalidFiles,
          e,
        );
      }

      if (!isEmpty(invalidFiles)) return onError('Invalid file format!', invalidFiles, e);

      if (dimensions) {
        validateDimensions(Array.from(files), invalidFiles, e);
        return false;
      }
      onChange(e, Array.from(e.currentTarget.files)); // Convert FileList to Array<File>
      return false;
    },
    [accept, dimensions, maxSize, onChange, onError, validateDimensions],
  );

  function handleOnClick(e) {
    if (input) input.click();
  }

  return (
    <div onClick={handleOnClick} onKeyPress={handleOnClick} role="button" tabIndex={0}>
      <input
        type="file"
        className={styles['file-uploader-input']}
        onChange={handleChange}
        accept={accept}
        {...elemProps}
        ref={(node) => {
          input = node;
        }}
      />
      {children}
    </div>
  );
}

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);
function getImageUploadServerConfig(imageUploadApiURL: string) {
  return {
    process: {
      url: imageUploadApiURL,
      method: 'PUT' as const,
      // withCredentials: true,
      headers: {
        // 'Access-Control-Allow-Origin': true,
        ...AuthUtils.getAccountAuthHeaderWithMagicToken(),
      },
    },
    load: (
      uniqueFileId: RequestInfo,
      load: (arg0: Blob) => void,
      error: ((reason: any) => PromiseLike<never>) | null | undefined,
    ) => {
      fetch(uniqueFileId)
        .then((res) => res.blob())
        .then((data) => {
          load(data);
        })
        .catch(error);
    },
  };
}
function getFileObjectForDefaultFile(file: GenericImageType): FilePondInitialFile {
  return {
    source: file.url,
    options: {
      type: 'local',
    },
  };
}
interface ImageUploaderProps {
  name: string;
  imageUploadApiURL?: string;
  defaultValue?: GenericImageType | Array<GenericImageType>;
  onRemoveFile?: (fileKey: string) => void;
  isLoading?: boolean;
}

export function ImageUploader(props: ImageUploaderProps) {
  const { name, imageUploadApiURL, defaultValue = [], onRemoveFile, isLoading } = props;
  const [files, setFiles] = useState(() => {
    if (defaultValue && isArray(defaultValue)) {
      return defaultValue.map((value) => getFileObjectForDefaultFile(value));
    }
    if (defaultValue && !isArray(defaultValue)) {
      return [getFileObjectForDefaultFile(defaultValue)];
    }
    return [];
  });

  if (isLoading) return <LoadingDots />;

  return (
    <div>
      <FilePond
        // @ts-ignore
        server={getImageUploadServerConfig(imageUploadApiURL)}
        files={files}
        maxFileSize="5MB"
        onupdatefiles={(selectedFiles) => {
          // @ts-ignore
          setFiles(selectedFiles);
        }}
        onremovefile={(error, file) => (onRemoveFile ? onRemoveFile(name) : null)}
        maxFiles={1}
        name={name}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}

interface SelectOptionType {
  label: string;
  value: string | boolean | number;
  info?: string;
  icon?: string;
  iconsSvgUrl?: string;
  thumbnail?: string;
  inView?: () => void;
}

interface SelectGroupedOptionType {
  label: string;
  options: Array<SelectOptionType>;
}

interface SelectProps {
  name: string;
  options: Array<SelectOptionType> | Array<SelectGroupedOptionType>;
  size?: INPUT_SIZES;
  style?: INPUT_STYLES;
  isCreatable?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  isMulti: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onMenuScrollToBottom?: () => void;
  isLoading?: boolean;
  filterOption?: (option: any, inputValue: string) => boolean;
  onCreateOption?: (inputValue: string) => void;
  isSearchable?: boolean;
}

interface SingleSelectProps extends SelectProps {
  isMulti: false;
  value?: SelectOptionType;
  onChange?: (option: SelectOptionType | null) => void;
}

interface MultipleSelectProps extends SelectProps {
  isMulti: true;
  value?: Array<SelectOptionType>;
  onChange?: (option: Array<SelectOptionType> | null) => void;
}

const { Option, SingleValue, MultiValue } = SelectComponents;
function SelectOption(props: { data: SelectOptionType }) {
  const { data } = props;
  const { icon, label, iconsSvgUrl, thumbnail, info } = data;
  const [$ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  if (data.inView && inView) {
    // @ts-ignore
    data.inView();
  }

  if (thumbnail) {
    return (
      // @ts-ignore
      <Option {...props}>
        <Row vcenter>
          {
            thumbnail && <img src={thumbnail} className={styles['select-option__thumbnail']} alt={thumbnail} /> // Use ImageV2
          }
          {label}
        </Row>
      </Option>
    );
  }

  return (
    // @ts-ignore
    <Option {...props} innerRef={$ref}>
      <Row vcenter>
        {icon && <Icon name={icon} className={styles['select-option__icon']} accentStyle={ICON_ACCENT_STYLES.FILL} />}
        {iconsSvgUrl && <IconFromSVGUrl url={iconsSvgUrl} className={styles['select-option__icon']} />}
        {label}
      </Row>
      {info && <div className={styles['select-option__info']}>{info}</div>}
    </Option>
  );
}

function SelectSingleValue(props: { children: React.ReactNode; data: SelectOptionType }) {
  const { children, data } = props;
  const { icon, iconsSvgUrl, thumbnail } = data;

  if (thumbnail) {
    return (
      // @ts-ignore
      <SingleValue {...props}>
        <Row vcenter>
          {
            thumbnail && <img src={thumbnail} className={styles['select-option__thumbnail']} alt={thumbnail} /> // Use ImageV2
          }
          {children}
        </Row>
      </SingleValue>
    );
  }

  return (
    // @ts-ignore
    <SingleValue {...props}>
      <Row vcenter>
        {icon && <Icon name={icon} className={styles['select-option__icon']} accentStyle={ICON_ACCENT_STYLES.FILL} />}
        {iconsSvgUrl && <IconFromSVGUrl url={iconsSvgUrl} className={styles['select-option__icon']} />}
        {children}
      </Row>
    </SingleValue>
  );
}

function SelectMultiValue(props: { children: React.ReactNode; data: SelectOptionType }) {
  const { children, data } = props;
  const { icon } = data;
  return (
    // @ts-ignore
    <MultiValue {...props}>
      <Row vcenter>
        {icon && <Icon name={icon} className={styles['select-option__icon']} accentStyle={ICON_ACCENT_STYLES.FILL} />}
        {children}
      </Row>
    </MultiValue>
  );
}
const SelectCustomStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: BrowserUtils.getCSSVariableValue('--input-border'),
    minHeight: 44,
  }),
  option: (provided: any, { data }) => ({
    ...provided,
    fontFamily: data.style?.fontFamily,
  }),
};

export function Select(props: SingleSelectProps | MultipleSelectProps): JSX.Element {
  const {
    isMulti = false,
    value = null,
    onChange,
    options,
    name,
    size = INPUT_SIZES.DEFAULT,
    style = INPUT_STYLES.PRIMARY,
    isCreatable = false,
    autoFocus = false,
    disabled,
    menuPlacement,
    onMenuOpen,
    onMenuClose,
    isLoading = false,
    onMenuScrollToBottom,
    filterOption,
    onCreateOption,
    isSearchable = true,
  } = props;
  const [selectedOption, setOption] = useState(value);

  const errors = useContext(FormContext);
  const $select = useRef<any>(null);

  // When the value change from parent then the internal state should also change
  useEffect(() => {
    setOption(value);
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      const $selectNode = $select.current;
      $selectNode?.focus();
    }
  }, []);

  const classes = ClassNames(styles.select, styles[`input--size-${size}`], styles[`select--style-${style}`]);

  const SelectComponent = isCreatable ? ReactCreatableSelect : ReactSelect;

  return (
    <>
      <SelectComponent
        ref={$select}
        className={classes}
        isSearchable={isSearchable}
        name={name}
        value={selectedOption}
        isMulti={isMulti}
        styles={SelectCustomStyles}
        menuPlacement={menuPlacement}
        components={{
          Option: SelectOption,
          SingleValue: SelectSingleValue,
          MultiValue: SelectMultiValue,
        }}
        isClearable={isCreatable}
        isDisabled={disabled}
        onChange={(option: any) => {
          setOption(option);
          if (onChange) onChange(option);
        }}
        formatCreateLabel={(userInput) => `Add ${userInput}`}
        options={options}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isLoading={isLoading}
        onMenuScrollToBottom={onMenuScrollToBottom}
        filterOption={filterOption}
        onCreateOption={onCreateOption}
      />
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
    </>
  );
}

export function MultiSelect(props: MultipleSelectProps): JSX.Element {
  return <Select {...props} isMulti />;
}

interface DatePickerProps {
  defaultValue?: string;
  name: string;
  showTimePicker?: boolean;
  minDate?: string;
  maxDate?: string;
  onChange?: (newDate: string) => void;
  timezone?: string;
  autoFocus?: boolean;
}

/*
  @TIMEZONE
* When convert default date we need the timezoned Date (getDateObjectForTzDate)
* and we save the date to hidden input we need to change this date to UTC getDateObjectForTzDate
* */
function getDateTimeInFormatForDate(options: {
  inputDate: Date;
  outputFormat: SimpleDateUtils.STANDARD_DATE_FORMATS.ISO | SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO;
  timezone?: string;
}): string {
  const { inputDate, outputFormat, timezone } = options;

  return SimpleDateUtils.getDateStringFromDate(inputDate, outputFormat, {
    tz: timezone,
    toUTC: true,
  });
}

export function DatePicker(props: DatePickerProps): React.ReactElement {
  const { defaultValue, name, showTimePicker = false, minDate, maxDate, onChange, timezone, autoFocus } = props;
  const [inputDate, setDate] = useState(SimpleDateUtils.getStartDateTime());

  useEffect(() => {
    const date = defaultValue
      ? SimpleDateUtils.getDateObject(defaultValue, timezone, false)
      : SimpleDateUtils.getStartDateTime();
    setDate(date);
  }, [defaultValue, timezone]);

  const errors = useContext(FormContext);
  const formInputFormat = showTimePicker
    ? SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_WITH_TIME_INPUT
    : SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_INPUT;
  const outputFormat = showTimePicker
    ? SimpleDateUtils.STANDARD_DATE_FORMATS.ISO
    : SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO;

  const dateOutputValueString = getDateTimeInFormatForDate({
    inputDate,
    outputFormat,
    timezone,
  });

  function CustomInput(inputProps: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) {
    const { onClick, value, onKeyDown, onChange: onChangeInput } = inputProps;
    return <Input onClick={onClick} value={value} onChange={onChangeInput} onKeyDown={onKeyDown} name="" type="text" />;
  }
  return (
    <>
      <LibDatePicker
        popperClassName={ClassNames({
          'react-datepicker--with-time': showTimePicker,
        })}
        ref={(r) => {
          if (r && autoFocus) {
            r.setOpen(true);
          }
        }}
        autoFocus={autoFocus}
        showTimeSelect={showTimePicker}
        selected={inputDate}
        onChange={(date: Date) => {
          const newDateOutputValueString = getDateTimeInFormatForDate({
            inputDate: date,
            outputFormat,
            timezone,
          });
          onChange?.(newDateOutputValueString);
          setDate(date as Date);
        }}
        dateFormat={formInputFormat}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        maxDate={maxDate ? SimpleDateUtils.getDateObject(maxDate) : null}
        minDate={minDate ? SimpleDateUtils.getDateObject(minDate) : null}
      />
      <input type="hidden" name={name} value={dateOutputValueString} />
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
    </>
  );
}

interface ColorPickerProps {
  defaultValue?: string; // hex value
  name: string;
  onChange?: (newColor: string) => void;
  overlayPlacement?: 'left' | 'right' | 'top' | 'bottom' | 'auto';
  disabled?: boolean;
  disableAlpha?: boolean;
}

export function ColorPicker(props: ColorPickerProps): React.ReactElement {
  const { defaultValue, name, onChange, overlayPlacement = 'auto', disabled, disableAlpha = true } = props;
  const [color, setColor] = useState(defaultValue || '#eee');
  const [showPicker, setShowPicker] = useState(false);
  const $input = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue) {
      setColor(defaultValue);
    }
  }, [defaultValue]);

  const errors = useContext(FormContext);
  return (
    <>
      <div ref={$input}>
        <button
          type="button"
          className={ClassNames(styles['input-color'], {
            [styles['input-color--disabled']]: disabled,
          })}
          style={{ backgroundColor: color }}
          onClick={() => {
            setShowPicker(!showPicker);
          }}
          disabled={disabled}
        >
          <div className={styles['input-color__label']}>{color}</div>
          <input type="hidden" name={name} value={color} />
        </button>
      </div>
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
      {showPicker && (
        <Overlay
          show
          target={$input.current}
          placement={overlayPlacement}
          onClose={() => setShowPicker(false)}
          container={$input}
        >
          <SketchPicker
            color={color}
            disableAlpha={disableAlpha}
            onChange={(c) => {
              const newColor = disableAlpha ? c.hex : `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`;
              setColor(newColor);
              onChange?.(newColor);
            }}
          />
        </Overlay>
      )}
    </>
  );
}

interface TimezoneSelectProps {
  defaultValue?: string;
  name: string;
  onChange?: (tz: string) => void;
  size?: INPUT_SIZES;
}

export function TimezoneSelect(props: TimezoneSelectProps): JSX.Element {
  const { defaultValue, name, onChange, size = INPUT_SIZES.DEFAULT } = props;
  const timezoneOptions = useMemo(
    () =>
      Object.entries(TimezoneUtils.getAllTimezoneOptions())
        .map(([, details]: [string, TimezoneUtils.TimezoneDetailsType]) => ({
          label: `${details.name} (${details.utcOffsetStr})`,
          value: details.aliasOf || details.name,
          offset: details.utcOffset,
        }))
        .sort((a, b) => a.offset - b.offset),
    [],
  );
  const defaultOption = useMemo(() => {
    if (defaultValue) {
      const details = TimezoneUtils.getAllTimezoneOptions()[defaultValue];
      return { label: `${details.name} (${details.utcOffsetStr})`, value: details.aliasOf || details.name };
    }
    return timezoneOptions[0];
  }, []);
  return (
    <Select
      isMulti={false}
      name={name}
      options={timezoneOptions}
      value={defaultOption}
      size={size}
      onChange={(option) => onChange?.(option?.value as string)}
    />
  );
}

interface DatePickerWithTimezoneSelectorProps extends DatePickerProps {
  nameForTimezoneInput: string;
}

export function DatePickerWithTimezoneSelector(props: DatePickerWithTimezoneSelectorProps): JSX.Element {
  const {
    defaultValue,
    timezone = TimezoneUtils.getCurrentTimezone(),
    name,
    nameForTimezoneInput,
    showTimePicker = false,
    minDate,
    maxDate,
    onChange,
    autoFocus,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedTz, setSelectedTz] = useState(timezone || TimezoneUtils.getCurrentTimezone());

  const $target = useRef<HTMLButtonElement>(null);

  return (
    <div>
      <div>
        <DatePicker
          autoFocus={autoFocus}
          showTimePicker={showTimePicker}
          name={name}
          minDate={minDate}
          maxDate={maxDate}
          defaultValue={defaultValue === 'no-date' ? undefined : defaultValue}
          onChange={onChange}
          timezone={selectedTz}
        />
      </div>
      <Text size={TEXT_SIZE.SIZE_5} muted>
        Timezone:{' '}
        <Text size={TEXT_SIZE.SIZE_5} muted boldness={TEXT_BOLDNESS.BOLD}>
          {selectedTz}
        </Text>{' '}
        <Space size={2} />
        <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.XS} onClick={() => setShowDialog(true)} ref={$target}>
          Change
        </Button>
      </Text>
      <input type="hidden" name={nameForTimezoneInput} value={selectedTz} />
      {showDialog && (
        <Dialog target={$target.current} show onClose={() => setShowDialog(false)}>
          <FormGroup>
            <FormLabel>Timezone</FormLabel>
            <TimezoneSelect
              name={nameForTimezoneInput}
              defaultValue={selectedTz}
              onChange={(tz) => {
                setShowDialog(false);
                setSelectedTz(tz);
              }}
            />
          </FormGroup>
        </Dialog>
      )}
    </div>
  );
}

interface DateRangePickerWithTimezoneSelectorProps {
  nameForStartDate: string;
  nameForEndDate: string;
  defaultValueForStartDate?: string;
  defaultValueForEndDate?: string;
  nameForTimezoneInput: string;
  timezone?: string;
  showTimePicker?: boolean;
  minDate?: string;
  maxDate?: string;
  title: string;
  hideEndDate?: boolean;
}

export function DateRangePickerWithTimezoneSelector(props: DateRangePickerWithTimezoneSelectorProps): JSX.Element {
  const {
    nameForStartDate,
    nameForEndDate,
    defaultValueForStartDate = SimpleDateUtils.getDateStringFromDate(
      SimpleDateUtils.getStartDateTime(),
      SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
    ),
    defaultValueForEndDate,
    nameForTimezoneInput,
    timezone = TimezoneUtils.getCurrentTimezone(),
    showTimePicker = false,
    minDate,
    maxDate,
    title,
    hideEndDate,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedTz, setSelectedTz] = useState(timezone || TimezoneUtils.getCurrentTimezone());

  const $target = useRef<HTMLButtonElement>(null);

  const timeZoneText = useMemo(() => {
    const details = TimezoneUtils.getAllTimezoneOptions()[selectedTz];
    return `${details.name} (${details.utcOffsetStr})`;
  }, [selectedTz]);
  const [startDate, setStartDate] = useState(defaultValueForStartDate);
  const [endDate, setEndDate] = useState<string>(
    defaultValueForEndDate ||
      SimpleDateUtils.getDateStringFromDate(
        SimpleDateUtils.addToDate(defaultValueForStartDate, 'minutes', 60 * 24),
        SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
      ),
  );
  const duration = SimpleDateUtils.getMinutesRemaining(startDate, endDate);

  function handleStartDateChange(newDate: string) {
    setStartDate(newDate);
    const endDateWithDuration = SimpleDateUtils.addToDate(newDate, 'minutes', duration || 60 * 24);
    const endDateWithDurationString = SimpleDateUtils.getDateStringFromDate(
      endDateWithDuration,
      SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
    );
    setEndDate(endDateWithDurationString);
  }

  return (
    <div>
      <FormGroup>
        <FormLabel>{title}</FormLabel>
        <Row>
          <Col>
            {!hideEndDate && <Text>From</Text>}
            <DatePicker
              showTimePicker={showTimePicker}
              name={nameForStartDate}
              minDate={minDate}
              maxDate={maxDate}
              defaultValue={startDate === 'no-date' ? undefined : startDate}
              onChange={handleStartDateChange}
              timezone={selectedTz}
            />
          </Col>
          <Space />
          {!hideEndDate && (
            <Col>
              <Text>To</Text>
              <DatePicker
                showTimePicker={showTimePicker}
                name={nameForEndDate}
                minDate={startDate}
                maxDate={maxDate}
                defaultValue={endDate === 'no-date' ? undefined : endDate}
                timezone={selectedTz}
              />
            </Col>
          )}
        </Row>
        <Space size={2} vertical />
        <Row>
          <Text size={TEXT_SIZE.SIZE_5} muted>
            Timezone:
          </Text>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_5} muted boldness={TEXT_BOLDNESS.BOLD}>
            {timeZoneText}
          </Text>
          <Space size={2} />
          <Row>
            <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.XS} onClick={() => setShowDialog(true)} ref={$target}>
              Change
            </Button>
          </Row>
        </Row>
        <input type="hidden" name={nameForTimezoneInput} value={selectedTz} />
        {showDialog && (
          <Dialog target={$target.current} show onClose={() => setShowDialog(false)} placement={DIALOG_PLACEMENT.AUTO}>
            <FormGroup>
              <FormLabel>Timezone</FormLabel>
              <TimezoneSelect
                name={nameForTimezoneInput}
                defaultValue={selectedTz}
                onChange={(tz) => {
                  setShowDialog(false);
                  setSelectedTz(tz);
                }}
              />
            </FormGroup>
          </Dialog>
        )}
      </FormGroup>
    </div>
  );
}

interface CountrySelectProps {
  defaultValue: string;
  name: string;
}

export function CountrySelect(props: CountrySelectProps): JSX.Element {
  const { defaultValue = 'IND', name } = props;
  const countryCodeOptions = useMemo(
    () =>
      CountryCodeUtils.COUNTRY_CODES.map((item) => ({
        label: item.name,
        value: item.code,
        iconsSvgUrl: `https://flagcdn.com/${item.alpha2Code.toLocaleLowerCase()}.svg`,
      })),
    [],
  );
  const selected = countryCodeOptions.find((item) => item.value === defaultValue);

  return <Select options={countryCodeOptions} value={selected} name={name} isMulti={false} />;
}

interface CountryCodeSelectProps {
  defaultValue: string | undefined;
  onChange: (SelectOptionType) => void;
  isLoading?: boolean;
}

function CountryCodeSelectButtonIcon(props: CountryCodeSelectProps): JSX.Element {
  const { defaultValue = 'IN', onChange, isLoading } = props;
  const $target = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const countryCodeOptions = useMemo(
    () =>
      CountryCodeUtils.COUNTRY_CODES.map((item) => ({
        label: `${item.code} - ${item.name} (${item.dial_code})`,
        value: item.alpha2Code,
        iconsSvgUrl: `https://flagcdn.com/${item.alpha2Code.toLocaleLowerCase()}.svg`,
      })),
    [],
  );
  const selected = countryCodeOptions.find((item) => item.value === defaultValue);

  return (
    <>
      <ButtonIcon
        title="Change Country"
        style={BUTTON_ICON_STYLES.SECONDARY}
        onClick={() => setShowDialog(!showDialog)}
        ref={$target}
        isLoading={isLoading}
      >
        {selected?.iconsSvgUrl && <IconFromSVGUrl url={selected.iconsSvgUrl} size={ICON_SIZES.SM} />}{' '}
        <Icon name={showDialog ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
      </ButtonIcon>
      {showDialog && (
        <Dialog target={$target.current} show onClose={() => setShowDialog(false)} placement={DIALOG_PLACEMENT.AUTO}>
          <FormGroup>
            <FormLabel>Country Code</FormLabel>
            <Select
              autoFocus
              options={countryCodeOptions}
              value={selected}
              name=""
              isMulti={false}
              onChange={(option) => {
                onChange(option);
                setShowDialog(false);
              }}
            />
          </FormGroup>
        </Dialog>
      )}
    </>
  );
}

interface InputPhoneNumberProps {
  defaultValue?: string;
  name: string;
  required?: boolean;
  defaultCountryCode?: string;
  isLoadingUserLocation?: boolean;
  userLocation?: UserLocationUtils.LocationType;
}

export function InputPhoneNumber(props: InputPhoneNumberProps): JSX.Element {
  const { name, defaultValue = '', required, defaultCountryCode = 'IND', isLoadingUserLocation, userLocation } = props;

  const [selectedCode, setCode] = useState<string>();
  const [selectedValue, setValue] = useState(defaultValue);

  // logic to set the country code
  useEffect(() => {
    if (selectedCode || isLoadingUserLocation) return;

    const parsedNumber = CountryCodeUtils.parsePhoneNumber(selectedValue);

    if (parsedNumber?.countryCallingCode) {
      const callingNumber = parsedNumber?.countryCallingCode;
      const countryCallingCodes = CountryCodeUtils.COUNTRY_CODES.find(
        (country) => country.dial_code === `+${callingNumber}`,
      );
      setCode(countryCallingCodes?.alpha2Code);
      return;
    }

    let countryDetails;
    if (userLocation?.country) {
      // backend api sends aplha2Code for user location
      countryDetails = CountryCodeUtils.COUNTRY_CODES.find((item) => item.alpha2Code === userLocation?.country);
    } else {
      countryDetails = CountryCodeUtils.COUNTRY_CODES.find((item) => item.code === defaultCountryCode);
    }

    const defaultCode = countryDetails?.alpha2Code;
    setCode(defaultCode);
  }, [defaultCountryCode, selectedValue, userLocation?.country, isLoadingUserLocation]);

  const numberInformation = useMemo(() => {
    // Get the national number without the code and then append code with the selected country code
    const parsedNumber = CountryCodeUtils.parsePhoneNumber(selectedValue);
    return CountryCodeUtils.parsePhoneNumber((parsedNumber?.nationalNumber as string) || selectedValue, {
      // @ts-ignore - I dont want to import this type to myne, If I find a way to fix it without importing the type, then I will fix it.
      defaultCountry: selectedCode,
      extract: true,
    });
  }, [selectedCode, selectedValue]);

  const errors = useContext(FormContext);

  return (
    <>
      <Input
        type="tel"
        minLength="9"
        name={name}
        onChange={(e) => setValue(e.target.value)}
        value={numberInformation?.number || selectedValue}
        required={required}
        placeholder="eg. 1234123123"
        buttonIcon={
          <CountryCodeSelectButtonIcon
            defaultValue={selectedCode}
            onChange={(option) => setCode(option.value)}
            isLoading={isLoadingUserLocation}
          />
        }
      />
      {errors && errors[name] && <FormError>{errors[name]}</FormError>}
    </>
  );
}

type CurrencySelectProps = {
  defaultValue: string;
  onChange?: (value) => void;
};

export function CurrencySelect(props: CurrencySelectProps): JSX.Element {
  const currencyOptions = useMemo(
    () =>
      Object.values(CurrencyUtils.CURRENCIES).map((type) => ({
        value: type.code,
        label: `${type.name} - ${type.symbol}`,
      })),
    [],
  );

  const { defaultValue, onChange } = props;
  const selectedOption = currencyOptions.find((option) => option.value === defaultValue) || currencyOptions[0];
  return (
    <Select
      onChange={onChange}
      size={INPUT_SIZES.MD}
      options={currencyOptions}
      value={selectedOption}
      name="currency"
      isMulti={false}
    />
  );
}

export enum RANGE_SIZES {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

type RangeProps = {
  defaultValue: number;
  name: string;
  onChange?: (value: number) => void;
  min: number;
  max: number;
  step?: number;
  hideText?: boolean;
  size?: RANGE_SIZES;
};

export function Range(props: RangeProps): JSX.Element {
  const { defaultValue, name, onChange, min, max, step = 1, hideText, size = RANGE_SIZES.SM } = props;
  const classes = ClassNames(styles.range, styles[`range--size-${size}`]);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <Row vcenter>
      <input
        className={classes}
        type="range"
        name={name}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => {
          setValue(Number(e.target.value || 0));
          if (onChange) onChange(Number(e.target.value) || 0);
        }}
      />

      {!hideText && (
        <>
          <Space size={4} />
          <Text color={COLOR_SHADES.DARKER}>{value}</Text>
        </>
      )}
    </Row>
  );
}

//
// interface FontSelectProps {
//   value: string;
//   name: string;
// }
//
// export function FontSelect(props: FontSelectProps) {
//   const { value, name } = props;
//   const [selectedOption, setSelected] = useState(value);
//   return (
//     <>
//       <FontPicker
//         apiKey={THIRD_PARTY_APPLICATION_KEYS.GOOGLE_FONTS_API}
//         activeFontFamily={selectedOption}
//         onChange={(nextFont) => setSelected(nextFont.family)}
//       />
//       <input type="hidden" name={name} value={selectedOption} />
//     </>
//   );
// }
