export default function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result);
      }
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}
