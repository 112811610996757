export enum KEYS {
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
  ESC = 'Escape',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  TAB = 'Tab',
  COMMA = ',',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  SPACE = ' ',
}

export function isEnterKey(event: KeyboardEvent): boolean {
  if (!event || !event.target) return false;

  const { nodeName } = event.target as Element;
  const { code } = event;

  return code === KEYS.ENTER && nodeName !== 'TEXTAREA';
}
