import { useState } from 'react';
import {
  BUTTON_STYLES,
  FormFooter,
  BUTTON_SIZE,
  Form,
  Space,
  Button,
  Row,
  FormGroup,
  FormLabel,
  Col,
  Input,
  Text,
  COLOR_SHADES,
} from '@premagic/myne';

import InputPhoneNumberContainer from '../../acccount/InputPhoneNumberContainer';

type Props = {
  onCancel: () => void;
  onSubmit: (formData) => void;
};

export default function AddNewClientForm(props: Props) {
  const { onCancel, onSubmit } = props;

  const [hasError, setErros] = useState(false);

  function handleFormSubmit(formData) {
    if (formData.data.name.trim().length && formData.data.phone_number.replaceAll(' ', '').length) {
      const data = {
        data: {
          name: formData.data.name.trim(),
          phone_number: formData.data.phone_number.replaceAll(' ', ''),
          email: formData.data.email,
        },
      };
      onSubmit(data);
      return;
    }
    setErros(true);
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      formSpec={{
        name: {
          validator: 'required',
        },
        email: {
          validator: 'required',
        },
        phone_number: {
          validator: 'required',
        },
      }}
    >
      {hasError && (
        <>
          <Space vertical size={2} />
          <Text color={COLOR_SHADES.DANGER}>Please verify the fields below</Text>
          <Space vertical size={2} />
        </>
      )}
      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input type="text" name="name" required placeholder="eg. Aman" autoFocus onKeyDown={() => setErros(false)} />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired>Phone</FormLabel>
            <InputPhoneNumberContainer name="phone_number" required />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              name="email"
              onKeyDown={() => setErros(false)}
              required
              placeholder="eg. aman@premagic.com"
            />
          </FormGroup>
        </Col>
      </Row>

      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={onCancel}>
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.PRIMARY}>Add Host</Button>
      </FormFooter>
    </Form>
  );
}
