import { EventCustomFromFieldsService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Form,
  FormFooter,
  FormResponseType,
  Row,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { useEffect } from 'react';
import ProjectMetaFeatureLabel from '../../../projects/highlight-settings/project-meta-toggle/ProjectMetaFeatureLabel';
import ProjectMetaFeatureToggleButton from '../../../projects/highlight-settings/project-meta-toggle/ProjectMetaFeatureToggleButton';
import EventFormFieldFormContainer from '../create/EventFormFieldFormContainer';

type Props = {
  formFieldId?: string | null | EventCustomFromFieldsService.SYSTEM_FORM_FIELDS;
  projectId: string;
  updateFormField: (options: { projectId: string; formFieldId: string }, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  cleanup: () => void;
  updateProjectMeta: (projectId: string, formResponse) => void;
  hasFeature: boolean;
  isEventRegistrationEnablePhotoUploadEnabled: boolean;
};

export default function EditEventFormFieldPanel(props: Props): JSX.Element | null {
  const {
    show,
    updateFormField,
    isLoading,
    errors,
    closePanel,
    formFieldId,
    cleanup,
    projectId,
    updateProjectMeta,
    hasFeature,
    isEventRegistrationEnablePhotoUploadEnabled,
  } = props;
  useEffect(() => {
    if (!show) {
      cleanup();
    }
  }, [cleanup, show]);

  if (!formFieldId || !show) return null;

  if (
    [EventCustomFromFieldsService.SYSTEM_FORM_FIELDS.USER_PHOTO].includes(
      formFieldId as EventCustomFromFieldsService.SYSTEM_FORM_FIELDS,
    )
  ) {
    return (
      <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
        <WindowPanelHeader onClose={closePanel}>
          <WindowPanelTitle>Edit Form Field</WindowPanelTitle>
        </WindowPanelHeader>
        <WindowPanelContent>
          <Row>
            <ProjectMetaFeatureLabel
              itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD}
            />

            <Col size={2} rightAlighed>
              <ProjectMetaFeatureToggleButton
                hasFeature={hasFeature}
                isLoading={isLoading}
                name={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD}
                checked={isEventRegistrationEnablePhotoUploadEnabled}
                updateProjectMeta={updateProjectMeta}
                projectId={projectId}
              />
            </Col>
          </Row>
        </WindowPanelContent>
      </WindowPanel>
    );
  }

  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Form Field</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          formSpec={{
            is_required: {
              type: 'boolean',
            },
            position: {
              type: 'number',
            },
          }}
          onSubmit={(data) =>
            updateFormField(
              {
                formFieldId,
                projectId,
              },
              data,
            )
          }
        >
          <EventFormFieldFormContainer projectId={projectId} formFieldId={formFieldId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
