import { GeneralEditorSidebar } from '@premagic/myne';
import PhotoSliderWidgetGetWidgetCodeContainer from './PhotoSliderWidgetGetWidgetCodeContainer';
import PhotoSliderCustomizationLayoutContainer from './photo-slider-customizations/PhotoSliderCustomizationLayoutContainer';

type Props = {
  shareId?: string;
};

export default function PhotoSliderWidgetRightSidebar(props: Props) {
  const { shareId } = props;

  return (
    <GeneralEditorSidebar isRight>
      <PhotoSliderWidgetGetWidgetCodeContainer shareId={shareId} />
      <PhotoSliderCustomizationLayoutContainer />
    </GeneralEditorSidebar>
  );
}
