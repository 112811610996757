import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import ProjectNerdStatsInfo from './ProjectNerdStatsInfo';
import { getProjectNerdStatsData, projectNerdStatsForProjectSelector } from './ProjectNerdStatsDataDuck';
import { foldersEntitiesSelector } from '../folders/AccountFoldersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  return {
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_NERD_STATS.FETCH)(state),
    stats: projectNerdStatsForProjectSelector(projectId)(state),
    projectId,
    folders: foldersEntitiesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => dispatch(getProjectNerdStatsData(dispatch, projectId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNerdStatsInfo);
