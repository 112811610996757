import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';

import ClientWebsiteUserRegistrationFromContainer from './ClientWebsiteUserRegistrationFromContainer';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  id: string;
  projectId: string;
  saveClientWebsite: (id: string, projectId: string, data: FormResponseType, navigateToUrl: string) => void;
  isLoading: boolean;
  errors?: Record<string, string>;
  showPanel: boolean;
  closePanel: () => void;
  langCode?: string;
};

export default function EditClientWebsiteUserRegistrationFromPanel(props: Props): JSX.Element {
  const { id, projectId, saveClientWebsite, isLoading, errors, showPanel, closePanel, langCode } = props;
  const clientWebsiteUserRegisterPageUrl = BrowserUrlUtils.getRouteUrlFor(
    APP_URLS.CLIENT_WEBSITE.DETAILS___REGISTRATION,
    {
      id,
      projectId,
    },
  );
  return (
    <WindowPanel show={showPanel}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Registration form</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          onSubmit={(formResponse) => saveClientWebsite(id, projectId, formResponse, clientWebsiteUserRegisterPageUrl)}
          errors={errors}
        >
          <ClientWebsiteUserRegistrationFromContainer id={id} langCode={langCode} />

          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
