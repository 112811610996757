import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { isModalOpenSelector } from '../../../../common/ModalDuck';
import ImageCompressMessageBox from './ImageCompressMessageBox';

function mapStateToProps(state) {
  return {
    show: isModalOpenSelector(LOADINGS.FILE.COMPRESS_FILE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.FILE.COMPRESS_FILE, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCompressMessageBox);
