import { EventPosterService } from '@premagic/core';
import {
  BUTTON_ICONS_SIZES,
  Divider,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Menu,
  MENU_ITEM_STYLES,
  MenuItem,
  MenuItemIcon,
} from '@premagic/myne';
import React from 'react';
import { EVENT_POSTER_VIEW_OPTIONS } from './EventPosterCreatorDuck';

type Props = {
  eventId: string;
  posterId: string;
  projectId: string;
  removePoster: (projectId: string, posterId: string) => void;
  isDeleting: boolean;
  poster: EventPosterService.EventPosterType;
  duplicatePoster: (options: { projectId: string; eventId: string }, formResponse) => void;
  viewOptions: Array<EVENT_POSTER_VIEW_OPTIONS>;
  setViewOptions: (viewOptions: Array<EVENT_POSTER_VIEW_OPTIONS>) => void;
  location: 'list' | 'creator';
  showEmbedPosterModal: (posterId: string) => void;
};

export default function EventPosterItemOptions(props: Props) {
  const {
    eventId,
    projectId,
    posterId,
    removePoster,
    isDeleting,
    poster,
    duplicatePoster,
    setViewOptions,
    viewOptions,
    location,
    showEmbedPosterModal,
  } = props;
  const isRulerShown = viewOptions.includes(EVENT_POSTER_VIEW_OPTIONS.RULER);

  if (isDeleting) return <LoadingDots size="sm" />;
  const isCreatorView = location === 'creator';
  const showEmbedOption = false && poster.type === EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT;
  return (
    <Dropdown>
      <DropdownIcon title="Poster options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.MD}>
        <Icon name="more_vertical" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
      </DropdownIcon>
      <Menu>
        <MenuItem
          onClick={() =>
            duplicatePoster(
              {
                projectId,
                eventId,
              },
              { data: Object.assign({}, poster, { name: `${poster.name} (copy)` }) },
            )
          }
        >
          <MenuItemIcon name="copy" />
          Duplicate
        </MenuItem>
        {showEmbedOption && (
          <MenuItem onClick={() => showEmbedPosterModal(posterId)}>
            <MenuItemIcon name="embed" />
            Embed on your website
          </MenuItem>
        )}
        {isCreatorView && (
          <MenuItem onClick={() => setViewOptions(isRulerShown ? [] : [EVENT_POSTER_VIEW_OPTIONS.RULER])}>
            <MenuItemIcon name={isRulerShown ? 'eye_off' : 'eye'} />
            {isRulerShown ? 'Hide' : 'Show'} ruler
          </MenuItem>
        )}
        <Divider />
        <MenuItem style={MENU_ITEM_STYLES.DANGER} onClick={() => removePoster(projectId, posterId)}>
          <MenuItemIcon name="trash" />
          Delete
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
