import React from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  PageFooter,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ALBUM_STATES, AlbumType } from '../services/AlbumServices';
import AlbumConfirmationToPhotographerModalContainer from './AlbumConfirmationToPhotographerModalContainer';

type Props = {
  albumId: string;
  album?: AlbumType;
  isMobileUp: boolean;
  showConfirmAlbum: () => void;
};

export default function AlbumPreview(props: Props) {
  const { albumId, album, isMobileUp, showConfirmAlbum } = props;
  const { state } = album || {};

  const showAlbumConfirmSection = state === ALBUM_STATES.REVIEW_PENDING;
  if (showAlbumConfirmSection)
    return (
      <PageFooter>
        <Row vcenter>
          <Col size={6}>
            <Text size={TEXT_SIZE.SIZE_3} block boldness={TEXT_BOLDNESS.BOLD}>
              Confirm this draft photo album?
            </Text>
          </Col>
          <Space />
          <Col rightAlighed={!isMobileUp} size={null}>
            <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.LG} onClick={showConfirmAlbum}>
              <Icon name="check_circle" />
              <Space size={1} />
              Confirm
            </Button>
          </Col>
        </Row>
        <AlbumConfirmationToPhotographerModalContainer albumId={albumId} />
      </PageFooter>
    );

  return (
    <PageFooter>
      <Row center>
        <Color shade={COLOR_SHADES.SUCCESS} inline>
          <Icon name="check_circle" />
        </Color>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
          Confirmed
        </Text>
      </Row>
    </PageFooter>
  );
}
