import { EventAttendeesService, EventCustomFromFieldsService, FileService } from '@premagic/core';
import { BUTTON_STYLES, Button, SimpleImage, TEXT_ALIGN, TEXT_SIZE, Text } from '@premagic/myne';
import classNames from 'classnames';
import { PRINT_BADGE_CLASS, WIDGET_TYPE, WidgetDataType } from '../service/EventAttendeeBadgeDesignService';

function HighlightBox() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        outline: '2px solid #ffbf00',
        outlineOffset: '3px',
      }}
    />
  );
}

type AvatarCardInfoProps = {
  position: { top?: number; left?: number };
  dimension: { width?: number; height?: number };
  url?: string;
  onClick: () => void;
  active?: boolean;
};

function AvatarCardInfo(props: AvatarCardInfoProps) {
  const { position, url, dimension, onClick, active } = props;
  const { top, left } = position;

  if (!url) return null;

  return (
    <div style={{ zIndex: 2, position: 'absolute', top, left, transform: 'translate(-50%, 0)' }}>
      <Button style={BUTTON_STYLES.RESET} onClick={onClick}>
        <SimpleImage
          style={{
            width: dimension.width,
            height: dimension.height,
            objectFit: 'contain',
          }}
          alt="avatar-image"
          src={url}
        />
      </Button>
      {active && <HighlightBox />}
    </div>
  );
}

type PosterTextProps = {
  position: { top?: number; left?: number };
  dimension: { width?: number; height?: number };
  textColor: string;
  textSize: TEXT_SIZE;
  textWeight?: 400 | 500 | 700;
  value?: string;
  align?: TEXT_ALIGN;
  onClick: () => void;
  active?: boolean;
  textFont?: string;
  textCase?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
};

function PosterText(props: PosterTextProps) {
  const { position, textColor, value, textSize, textWeight, align, dimension, onClick, active, textFont, textCase } =
    props;
  const { top, left } = position;
  const { width, height } = dimension;

  if (!value) return null;
  return (
    <div
      style={{
        zIndex: 2,
        position: 'absolute',
        left,
        top,
        width,
        transform: 'translate(-50%, 0)',
        maxWidth: '100%',
        paddingLeft: '5%',
        paddingRight: '5%',
      }}
    >
      <Button block style={BUTTON_STYLES.RESET} onClick={onClick}>
        <Text
          size={textSize}
          block
          cssStyle={{
            color: textColor,
            whiteSpace: 'pre-line',
            fontFamily: textFont,
            fontWeight: textWeight,
            textTransform: textCase,
          }}
          align={align}
        >
          {value}
        </Text>
      </Button>
      {active && <HighlightBox />}
    </div>
  );
}

function getTextSizeBasedOnValue(size?: TEXT_SIZE, value?: string): TEXT_SIZE {
  // proportionate the text size based on the length of the text
  if (!value) return size || TEXT_SIZE.SIZE_4;
  if (!size) return TEXT_SIZE.SIZE_4;
  switch (size) {
    case TEXT_SIZE.SIZE_1:
      if (value.length > 55) return TEXT_SIZE.SIZE_5;
      if (value.length > 45) return TEXT_SIZE.SIZE_4;
      if (value.length > 35) return TEXT_SIZE.SIZE_3;
      if (value.length > 25) return TEXT_SIZE.SIZE_2;
      return size;
    case TEXT_SIZE.SIZE_2:
      if (value.length > 55) return TEXT_SIZE.SIZE_5;
      if (value.length > 45) return TEXT_SIZE.SIZE_4;
      if (value.length > 35) return TEXT_SIZE.SIZE_3;
      return size;
    case TEXT_SIZE.SIZE_3:
      if (value.length > 55) return TEXT_SIZE.SIZE_5;
      if (value.length > 45) return TEXT_SIZE.SIZE_4;
      return size;
    case TEXT_SIZE.SIZE_4:
      if (value.length > 55) return TEXT_SIZE.SIZE_5;
      return size;
    default:
      return size || TEXT_SIZE.SIZE_4;
  }
}

type TextWidgetWrapperProps = {
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
  widgetType: WIDGET_TYPE;
  value?: string;
  setSelectedWidget: (widgetId: WIDGET_TYPE) => void;
  selectedWidgetForEdit: WIDGET_TYPE;
};

function TextWidgetWrapper(props: TextWidgetWrapperProps) {
  const { posterWidgetDesign, widgetType, value, setSelectedWidget, selectedWidgetForEdit } = props;

  if (!value) return null;
  const widgetData = posterWidgetDesign?.[widgetType];
  return (
    <PosterText
      position={{
        top: widgetData?.position?.y,
        left: widgetData?.position?.x,
      }}
      dimension={{
        width: widgetData?.dimension?.width,
        height: widgetData?.dimension?.height,
      }}
      textColor={widgetData?.color || '#203a56'}
      textSize={getTextSizeBasedOnValue(widgetData?.fontSize, value)}
      textFont={widgetData?.font}
      textWeight={widgetData?.weight || 400}
      textCase={widgetData?.fontCase}
      value={value}
      onClick={() => setSelectedWidget(widgetType)}
      active={selectedWidgetForEdit === widgetType}
      align={TEXT_ALIGN.CENTER}
    />
  );
}

type Props = {
  selectedBackgroundPoster?: FileService.FolderFileType;
  attendeeData?: EventAttendeesService.EventAttendeeType;
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
  setSelectedWidget: (widgetId: WIDGET_TYPE) => void;
  selectedWidgetForEdit: WIDGET_TYPE;
};

export default function EventAttendeeBadge(props: Props) {
  const { selectedBackgroundPoster, attendeeData, posterWidgetDesign, setSelectedWidget, selectedWidgetForEdit } =
    props;

  const { name: userName, custom_fields: customFields } = attendeeData || {};

  const {
    [EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID]: displayId,
    [EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY]: userCompany,
    [EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE]: userRole,
    [EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE]: faceAsset,
  } = customFields || {};
  const userAvatarUrl = faceAsset?.dynamic_image_url;

  const posterImageUrl = selectedBackgroundPoster?.dynamic_image_url;

  return (
    <div
      style={{
        width: posterWidgetDesign?.POSTER?.dimension?.width,
        height: posterWidgetDesign?.POSTER?.dimension?.height,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#fff',
        zIndex: 0,
      }}
      className={classNames(PRINT_BADGE_CLASS, displayId || userName)}
    >
      {posterImageUrl && (
        <SimpleImage
          src={posterImageUrl}
          alt="attendee-badge"
          style={{ height: '100%', width: '100%', objectFit: 'contain', zIndex: 1 }}
        />
      )}
      <AvatarCardInfo
        position={{
          top: posterWidgetDesign?.AVATAR?.position?.y,
          left: posterWidgetDesign?.AVATAR?.position?.x,
        }}
        dimension={{
          width: posterWidgetDesign?.AVATAR?.dimension?.width,
          height: posterWidgetDesign?.AVATAR?.dimension?.height,
        }}
        url={userAvatarUrl}
        onClick={() => setSelectedWidget(WIDGET_TYPE.AVATAR)}
        active={selectedWidgetForEdit === WIDGET_TYPE.AVATAR}
      />
      <TextWidgetWrapper
        posterWidgetDesign={posterWidgetDesign}
        widgetType={WIDGET_TYPE.DISPLAY_ID}
        value={displayId}
        selectedWidgetForEdit={selectedWidgetForEdit}
        setSelectedWidget={setSelectedWidget}
      />
      <TextWidgetWrapper
        posterWidgetDesign={posterWidgetDesign}
        widgetType={WIDGET_TYPE.USER_NAME}
        value={userName}
        selectedWidgetForEdit={selectedWidgetForEdit}
        setSelectedWidget={setSelectedWidget}
      />
      <TextWidgetWrapper
        posterWidgetDesign={posterWidgetDesign}
        widgetType={WIDGET_TYPE.USER_ROLE}
        value={userRole}
        selectedWidgetForEdit={selectedWidgetForEdit}
        setSelectedWidget={setSelectedWidget}
      />
      <TextWidgetWrapper
        posterWidgetDesign={posterWidgetDesign}
        widgetType={WIDGET_TYPE.USER_COMPANY}
        value={userCompany}
        selectedWidgetForEdit={selectedWidgetForEdit}
        setSelectedWidget={setSelectedWidget}
      />
    </div>
  );
}
