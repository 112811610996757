import React, { useEffect, useState } from 'react';

import {
  Col,
  DatePicker,
  FormGroup,
  FormLabel,
  Input,
  INPUT_SIZES,
  RichTextArea,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';

import { OccasionsService } from '@premagic/core';
import UserSelectContainer from '../../users/select/UserSelectContainer';
import CRMOccasionUsersBusyAlertContainer from './CRMOccasionUsersBusyAlertContainer';
import OccasionNamePicker from './OccasionNamePicker';

const DURATIONS = [1, 2, 4, 6, 8, 12, 14, 18, 24, 36, 48];
const durationOptions = DURATIONS.map((item) => ({
  label: `${item} hr`,
  value: item,
}));

type OccasionDatesProps = {
  start?: string;
  end?: string;
  onDateChange: (dates: { start: string; end: string }) => void;
};
function OccasionDates(props: OccasionDatesProps) {
  const {
    start = SimpleDateUtils.getDateStringFromDate(
      SimpleDateUtils.getStartDateTime(),
      SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
    ),
    end,
    onDateChange,
  } = props;
  const [fromDateString, setFromDateString] = useState(start);
  const [duration, setDuration] = useState(end ? SimpleDateUtils.getHoursRemaining(start, end) : 12);

  const selectedDurationOptions = durationOptions.find((item) => item.value === duration);

  const endDateWithDuration = SimpleDateUtils.addToDate(fromDateString, 'hours', duration);
  const endDateWithDurationString = SimpleDateUtils.getDateStringFromDate(
    endDateWithDuration,
    SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
  );

  useEffect(() => {
    onDateChange({ start: fromDateString, end: endDateWithDurationString });
  }, [endDateWithDurationString]);

  return (
    <Row>
      <Col size={4}>
        <FormGroup>
          <FormLabel isRequired>Date of the Occasion</FormLabel>
          <DatePicker
            name="start_date_time"
            defaultValue={start}
            showTimePicker
            onChange={(date) => {
              setFromDateString(date);
            }}
          />
          <input type="hidden" name="end_date_time" value={endDateWithDurationString} />
        </FormGroup>
      </Col>
      <Space size={8} />
      <Col size={3}>
        <FormGroup>
          <FormLabel isRequired>Duration</FormLabel>
          <Select
            options={durationOptions}
            name="duration"
            isMulti={false}
            value={selectedDurationOptions}
            onChange={(option) => setDuration(option?.value as number)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}
function getDateFromDateTime(date?: string): string {
  return SimpleDateUtils.getDateStringFromDate(
    date || SimpleDateUtils.now(),
    SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO,
  );
}

type Props = {
  eventId: string;
  occasion?: OccasionsService.OccasionType | null;
  getOccasionsForUsers: (eventId: string, userIds: Array<string>, dates: { start: string; end: string }) => void;
};

export default function CRMOccasionForm(props: Props): JSX.Element {
  const { occasion, getOccasionsForUsers, eventId } = props;
  const [dates, setDates] = useState({
    start: getDateFromDateTime(occasion?.start_date_time),
    end: getDateFromDateTime(occasion?.end_date_time),
  });
  const [users, setUsers] = useState(occasion?.service_providers || []);

  useEffect(() => {
    if (users?.length > 0 && dates.start && dates.end) {
      getOccasionsForUsers(
        eventId,
        users,
        dates as {
          start: string;
          end: string;
        },
      );
    }
  }, [dates, users]);

  return (
    <div>
      <OccasionNamePicker defaultValue={occasion?.name} name="name" />
      <FormGroup>
        <FormLabel>Number of People attending</FormLabel>
        <Input
          type="number"
          name="number_of_guests"
          defaultValue={occasion?.number_of_guests || 250}
          size={INPUT_SIZES.SM}
          step={50}
        />
      </FormGroup>

      <Row>
        <Col>
          <FormGroup>
            <FormLabel>Venue Address</FormLabel>
            <Input type="text" name="location" defaultValue={occasion?.location} />
          </FormGroup>
        </Col>
        <Space size={8} />
        <Col>
          <FormGroup>
            <FormLabel>Venue Google maps link</FormLabel>
            <Input
              type="url"
              name="location_link"
              pattern="https://.*"
              defaultValue={occasion?.location_link}
              placeholder="eg. https://goo.gl/maps/GKFDag2gdtwZH9Qu9"
            />
          </FormGroup>
        </Col>
      </Row>
      <OccasionDates
        start={occasion?.start_date_time}
        end={occasion?.end_date_time}
        onDateChange={(date) =>
          setDates({
            start: getDateFromDateTime(date.start),
            end: getDateFromDateTime(date.end),
          })
        }
      />
      <Space vertical size={8} />
      <FormGroup>
        <FormLabel info="Premagic will notify the team member when the event is marked as confirmed">
          Team member working on this Occasion
        </FormLabel>
        <UserSelectContainer
          name="service_providers"
          isMulti
          defaultValue={occasion?.service_providers}
          onChange={(user) => setUsers(user.map((item) => item.value))}
        />
        <Space vertical size={2} />
        <Row>
          <CRMOccasionUsersBusyAlertContainer occasionId={occasion?.id} />
        </Row>
      </FormGroup>
      <FormGroup>
        <FormLabel>Notes</FormLabel>
        <RichTextArea name="notes" defaultValue={occasion?.notes} />
      </FormGroup>
    </div>
  );
}
