import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import WorkflowDetailsPage from './WorkflowDetailsPage';
import {
  addTaskgroupToWorkflow,
  changeTaskgroupOrder,
  changeWorkflowName,
  deleteWorkflowItem,
  fetchWorkflowItemData,
  workflowDetailsItemSelector,
} from '../data-duck/WorkflowDataDuck';
import { taskgroupIdsByOrderSelector } from '../data-duck/TaskGroupDataDuck';

function mapStateToProps(state, ownProps) {
  const { workflowId: id } = ownProps.match.params;

  return {
    id,
    workflow: workflowDetailsItemSelector(Number(id))(state) || {},
    isLoading: isLoadingSelector(LOADINGS.CRM_WORKFLOW.FETCH_ITEM)(state),
    isCreatingTaskgroup: isLoadingSelector(LOADINGS.CRM_WORKFLOW.CREATE_TASKGROUP)(state),
    taskgroupIds: taskgroupIdsByOrderSelector(state),
    isWorkflowSaving: isLoadingSelector(LOADINGS.CRM_WORKFLOW.UPDATE)(state),
    isDeletingWorkflow: isLoadingSelector(LOADINGS.CRM_WORKFLOW.DELETE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (workflowId) => {
      dispatch(fetchWorkflowItemData(dispatch, workflowId));
    },
    createNewTaskgroup: (workflowId, taskgroupName) => {
      dispatch(addTaskgroupToWorkflow(dispatch, workflowId, { name: taskgroupName }));
    },
    deleteWorkflow: (workflowId) => {
      dispatch(deleteWorkflowItem(dispatch, workflowId));
    },
    changeTaskgroupOrder: (workflowId, taskgroupIds, data) => {
      dispatch(changeTaskgroupOrder(dispatch, workflowId, taskgroupIds, data));
    },
    changeWorkflowName: (workflowId, workflowName) => {
      dispatch(changeWorkflowName(dispatch, workflowId, workflowName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDetailsPage);
