import { EventPosterService } from '@premagic/core';
import { ColorPicker, FormLabel, FormGroup } from '@premagic/myne';
import { PosterWidgetTextType } from '@premagic/poster-creator';

type Props = {
  poster: EventPosterService.EventPosterType;
  editTextColor: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetTextType;
  }) => void;
  projectId: string;
  posterId: string;
  widget: PosterWidgetTextType;
  name: 'fill' | 'font_color';
};
export default function EventPosterTextColorSelector(props: Props) {
  const { projectId, editTextColor, posterId, widget, poster, name } = props;

  function handleColorsChange(fontColor = '#203a56') {
    const updatedWidget = Object.assign({}, widget, { [name]: fontColor });
    editTextColor({ projectId, widgetToUpdate: updatedWidget, poster });
  }

  const textColor = widget?.[name] || '#203a56';

  return (
    <FormGroup>
      <FormLabel>Color</FormLabel>
      <ColorPicker name="color" disableAlpha={false} defaultValue={textColor} onChange={handleColorsChange} />
    </FormGroup>
  );
}
