import { ProjectMetaService } from '@premagic/core';
import { Col, FormGroup, FormLabel, RichTextAreaInline, Space, SubTitle, Tooltip } from '@premagic/myne';

type HighlightDesignWelcomeMessageEditorProps = {
  onChange?: (value: string) => void;
  galleryWelcomeText?: string;
  isWelcomeTextEditorEnabled?: boolean;
  name: string;
};

export default function HighlightDesignWelcomeMessageEditor(props: HighlightDesignWelcomeMessageEditorProps) {
  const { onChange, galleryWelcomeText, isWelcomeTextEditorEnabled, name } = props;

  const disabledMessage = 'Upgrade your Plan to set custom Welcome Text';

  return (
    <FormGroup>
      <FormLabel>Welcome Text</FormLabel>
      <Space vertical size={2} />
      <Col size={6}>
        <Tooltip message={!isWelcomeTextEditorEnabled ? disabledMessage : ''} placement="top">
          <RichTextAreaInline
            name={name}
            defaultValue={galleryWelcomeText}
            placeholder="eg. Welcome"
            onChange={onChange}
            disabled={!isWelcomeTextEditorEnabled}
            disableColorChange
            debounceTimer={0}
          />
        </Tooltip>
      </Col>
    </FormGroup>
  );
}
