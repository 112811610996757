import { connect } from 'react-redux';
import { albumCreatorSelectedPageSelector } from './AlbumCreatorDuck';
import { totalNumberOfPagesInCreatorSelector } from './page/AlbumPageDataDuck';
import AlbumCreatorPageInfo from './AlbumCreatorPageInfo';

function mapStateToProps(state) {
  const pageNo = albumCreatorSelectedPageSelector(state);
  return {
    pageNo,
    totalPages: totalNumberOfPagesInCreatorSelector(state),
  };
}

export default connect(mapStateToProps)(AlbumCreatorPageInfo);
