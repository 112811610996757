import React from 'react';
import { ProductFeedbackService } from '@premagic/core';
import { BUTTON_STYLES, Dropdown, DropdownButton, Menu, MenuItem } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../../services/AppRouteURLService';

const REPORT_TYPE_OPTIONS: Record<
  string,
  {
    id: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
    text: string;
  }
> = {
  [ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.NEED_MORE_PHOTOS]: {
    id: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.NEED_MORE_PHOTOS,
    text: 'Need more photos',
  },
  [ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.BAD_PHOTO_QUALITY]: {
    id: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.BAD_PHOTO_QUALITY,
    text: 'Bad photo quality',
  },
  [ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.NOT_MY_PHOTO]: {
    id: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.NOT_MY_PHOTO,
    text: 'Not my photos',
  },
  [ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.OTHER]: {
    id: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.OTHER,
    text: 'Other',
  },
};

type Props = {
  eventId: string;
  navigateTo: (url: string) => void;
  reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
  isNotMyPhotoEnabled: boolean;
};

export default function ReportIssueFilterByTypeDropdown(props: Props) {
  const { eventId, navigateTo, reportType: selectedReportType, isNotMyPhotoEnabled } = props;

  function handleOptionSelect(id) {
    navigateTo(
      BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__REPORT_ISSUES, {
        eventId,
        reportType: id,
      }),
    );
  }

  return (
    <Dropdown>
      <DropdownButton style={BUTTON_STYLES.LINK_SECONDARY}>
        {REPORT_TYPE_OPTIONS[selectedReportType].text}
      </DropdownButton>
      <Menu>
        {Object.values(REPORT_TYPE_OPTIONS)
          .filter((item) => {
            if (item.id === ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE.NOT_MY_PHOTO) {
              return isNotMyPhotoEnabled;
            }
            return true;
          })
          .map((option) => (
            <MenuItem key={option.id} onClick={() => handleOptionSelect(option.id)}>
              {option.text}
            </MenuItem>
          ))}
      </Menu>
    </Dropdown>
  );
}
