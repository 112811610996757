import React, { CSSProperties, useEffect, useRef } from 'react';
import { Calendar as CalendarLib, dateFnsLocalizer } from 'react-big-calendar';
import { navigate as NavigateConstants } from 'react-big-calendar/lib/utils/constants';

import ClassNames from 'classnames';
import { SimpleDateUtils, StringUtils } from '@premagic/utils';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar-override.css';
import styles from './calendar.module.css';
import { ErrorBoundary } from '../Error/Error';
import { Tooltip } from '../Tooltip/Tooltip';
import { Col, Row, Space } from '../Grid/Grid';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { LoadingDots } from '../Loading/Loading';
import { Button, BUTTON_SIZE, BUTTON_STYLES, ButtonIcon } from '../Button/Buttons';
import { Icon, ICON_SIZES } from '../Icon/Icons';
import { COLOR_SHADES } from '../Color/Color';
import { INPUT_SIZES, Select } from '../Form/Form';

const localizer = dateFnsLocalizer(SimpleDateUtils.dateFnsLocalizerFunctions);

const dateFormats = {
  eventTimeRangeFormat: ({ start, end }) => `${start.format('hh:mm a')} - ${end.format('hh:mm a')}`,
  selectRangeFormat: ({ start, end }) => `${start.format('hh:mm a')} - ${end.format('hh:mm a')}`,
};

const defaultConfig = {
  defaultDate: SimpleDateUtils.getDateObject(SimpleDateUtils.now()),
  formats: {
    timeGutterFormat: 'h a',
    // ...dateFormats,
  },
  selectable: true,
  scrollToTime: SimpleDateUtils.getDateObject(SimpleDateUtils.now()),
  step: 30,
  timeslots: 2,
  views: ['month', 'week', 'day'],
  defaultView: 'month',
  drilldownView: null,
  localizer,
  startAccessor: 'start',
  endAccessor: 'end',
};

type CalendarProps = {
  events: Array<{
    id: string;
    start: Date;
    end: Date;
    title: string;
  }>;
  onRangeChange: (dates: { start: Date; end: Date } | Array<Date>, view: 'string') => void;
  // https://github.com/jquense/react-big-calendar/blob/master/src/Calendar.js#L726
  components?: {
    event: any;
    eventWrapper: any;
    toolbar: any;
  };
  style?: CSSProperties;
};

export function Calendar(props: CalendarProps): JSX.Element {
  const { events, components, onRangeChange, style } = props;
  return (
    <ErrorBoundary>
      <CalendarLib
        {...defaultConfig}
        step={60}
        onRangeChange={onRangeChange}
        components={components}
        events={events}
        style={style}
      />
    </ErrorBoundary>
  );
}

type ToolbarProps = {
  date: Date;
  isLoading: boolean;
  onNavigate: (key: NavigateConstants) => void;
  view: string;
  onView: (view: string) => void;
  views: Array<string>;
};

function ViewSelect(props: { selectedView: string; views: Array<string>; onChange: (view: string) => void }) {
  const { selectedView, views, onChange } = props;
  const viewOptions = views.map((view) => ({
    value: view,
    label: StringUtils.toTitleCase(view),
  }));
  const selectedOption = viewOptions.find((option) => option.value === selectedView) || viewOptions[0];

  return (
    <>
      {selectedView === 'day' && (
        <>
          <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={() => onChange('month')}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
            <Space size={1} />
            Back to Month
          </Button>
          <Space size={4} />
        </>
      )}
      <Select
        size={INPUT_SIZES.SM}
        options={viewOptions}
        value={selectedOption}
        name="status"
        isMulti={false}
        onChange={(option) => onChange(option?.value as string)}
      />
    </>
  );
}

export function Toolbar(props: ToolbarProps): JSX.Element {
  const { date, isLoading, onNavigate, onView, view, views } = props;

  /* Trigger onRangeChange when the component render, so that we dont need to manually calculate
   the date range to load the initial data
   https://github.com/jquense/react-big-calendar/issues/1752#issuecomment-761051235
   */
  useEffect(() => {
    onView(view);
  }, []);

  const dayFormat = SimpleDateUtils.getDateStringFromDate(date, SimpleDateUtils.STANDARD_DATE_FORMATS.DAY_WITH_POSTFIX);
  const monthFormat = SimpleDateUtils.getDateStringFromDate(date, SimpleDateUtils.STANDARD_DATE_FORMATS.MONTH);
  const yearFormat = SimpleDateUtils.getDateStringFromDate(date, SimpleDateUtils.STANDARD_DATE_FORMATS.YEAR);
  return (
    <div className={ClassNames(styles['calendar-toolbar'])}>
      <Row vcenter>
        <Space />
        <Col size={8}>
          <Row vcenter>
            {view === 'day' && (
              <>
                <Text size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.BLUE}>
                  {dayFormat}
                </Text>
                <Space size={1} />
              </>
            )}
            <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {monthFormat}
            </Text>
            <Space size={1} />
            <Text size={TEXT_SIZE.SIZE_1}>{yearFormat}</Text>
            <Space size={6} />
            {isLoading && (
              <>
                <LoadingDots size="sm" align="left" /> <Space size={1} />
                <Text size={TEXT_SIZE.SIZE_5} muted>
                  Loading...
                </Text>
              </>
            )}
          </Row>
        </Col>
        <Col size={null} rightAlighed>
          <Row inline vcenter>
            <ViewSelect selectedView={view} views={views} onChange={onView} />
            <Space />
            <ButtonIcon onClick={() => onNavigate(NavigateConstants.PREVIOUS)} title="Previous month">
              <Icon name="chevron_left" size={ICON_SIZES.SM} />
            </ButtonIcon>
            <ButtonIcon onClick={() => onNavigate(NavigateConstants.TODAY)} title="Today">
              <Icon name="target" size={ICON_SIZES.SM} />
            </ButtonIcon>
            <ButtonIcon onClick={() => onNavigate(NavigateConstants.NEXT)} title="Next month">
              <Icon name="chevron_right" size={ICON_SIZES.SM} />
            </ButtonIcon>
          </Row>
        </Col>
        <Space size={2} />
      </Row>
      <Space size={2} vertical />
    </div>
  );
}

// export const DateHeader = ({ slots, removeAllSlots, ...props }) => {
//   const date_timestamp = DateUtils.dateToTimestamp(props.date);
//
//   const date = DateUtils.format(date_timestamp, 'D MMM');
//   const day = DateUtils.format(date_timestamp, 'ddd');
//   const is_today = DateUtils.isToday(date_timestamp);
//   const is_past_date = DateUtils.isPastDate(date_timestamp);
//
//   return (
//     <Space size={2}>
//       <div>
//         <Row space_between={true} vcenter={true}>
//           <Text color={TextColor.darker} boldness={TextBoldness.bold} size={TextSize.size_3}>
//             {date}
//           </Text>
//           {(!is_past_date || is_today) && (
//             <ButtonIcon
//               className="js-remove-all-suggested-slots"
//               size={ButtonIconSize.xs}
//               disabled={!slots}
//               onClick={() => removeAllSlots(slots)}
//             >
//               <Icon name="close" />
//             </ButtonIcon>
//           )}
//         </Row>
//         <Row space_between={true} vcenter={true} className="relative">
//           <Text muted={true}>{day}</Text>
//           {is_today && <IconDot top={false} />}
//         </Row>
//       </div>
//     </Space>
//   );
// };
//
// DateHeader.propTypes = {
//   ...BigCalendarDateHeader,
//   slots: PropTypes.array,
//   removeAllSlots: PropTypes.func,
// };
//
export type CalendarEvent = {
  id: string;
  title: string;
  isConfirmed: boolean;
  isPrivate: boolean;
  duration: number;
  start: Date;
  end: Date;
};

type EventWrapperProps = {
  event: CalendarEvent;
  children: React.ReactNode;
  onClick: (eventId: string) => void;
};

export function EventWrapper(props: EventWrapperProps): JSX.Element {
  const { onClick, event, children } = props;
  const { isConfirmed } = event;
  const classes = ClassNames(styles['calendar-slot'], {
    'calendar-slot--secondary': !isConfirmed,
    'calendar-slot--primary': isConfirmed,
  });

  const $button = useRef(null);

  return (
    <button ref={$button} className={classes} onClick={(e) => onClick(event.id)} type="button">
      {children}
    </button>
  );
}

type EventContentProps = {
  event: CalendarEvent;
  userNames: JSX.Element;
};

export function EventContent(props: EventContentProps): JSX.Element {
  const { event, userNames } = props;
  const { start, end } = event;
  const eventTitle = event.isPrivate ? 'Private event' : event.title || 'No title';
  const startString = SimpleDateUtils.getDateStringFromDate(start, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_AND_TIME);
  const endString = SimpleDateUtils.getDateStringFromDate(end, SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_AND_TIME);
  const tooltipContent = (
    <ErrorBoundary>
      <span>
        {eventTitle}
        <Space vertical />
        {startString} to {endString}
        <Space vertical />
        {userNames && userNames}
        <br />
      </span>
    </ErrorBoundary>
  );

  return <Tooltip message={tooltipContent}>{eventTitle}</Tooltip>;
}

// export const BackgroundWrapper = ({ value, children }) => {
//   const timestamp = `${DateUtils.dateToTimestamp(value)}`;
//   const classes = DateUtils.isTimeBetweenHours(timestamp, 9, 16)
//     ? 'rb-calendar-day-wrapper rb-calendar-day-wrapper--highlight'
//     : 'rb-calendar-day-wrapper';
//
//   return (
//     <div className={classes} data-timestamp={timestamp}>
//       {children}
//     </div>
//   );
// };
//
// BackgroundWrapper.propTypes = {
//   value: PropTypes.object,
//   children: PropTypes.node,
// };
