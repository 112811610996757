import React from 'react';

import { InnerPageContentMainCol, InnerPageContentSidebar, Space, Text } from '@premagic/myne';

import { PERMISSIONS } from '../../users/UserPermissionService';
import EditAttendeeApprovalStatusModalContainer from './attendee-registration-approval/EditAttendeeApprovalStatusModalContainer';
import EventAttendeesListContainer from './EventAttendeesListContainer';
import MESSAGES from '../../../../../../common/Messages';
import AddEventAttendeePanelContainer from './create/AddEventAttendeePanelContainer';
import EditEventAttendeePanelContainer from './edit/EditEventAttendeePanelContainer';
import EventAttendeeReportSummaryCardContainer from './report/EventAttendeeReportSummaryCardContainer';
import EventAttendeeListHeaderContainer from './EventAttendeeListHeaderContainer';
import EventAttendeeFaceCheckinCardContainer from './face-checkin/EventAttendeeFaceCheckinCardContainer';
import EventAttendeeFaceCheckinAddZonePanelContainer from './face-checkin/face-checkin-zone-side-panel/EventAttendeeFaceCheckinAddZonePanelContainer';

type Props = {
  eventId: string;
  projectId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_ATTENDEES]: boolean;
    [PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]: boolean;
  };
  hasEventAttendeesFeatureEnabled: boolean;
};

export default function EventAttendeesLayout(props: Props): React.ReactElement {
  const { eventId, projectId, hasPermissions, hasEventAttendeesFeatureEnabled } = props;

  if (!projectId)
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text>Convert to Project for adding event {MESSAGES.EVENT_ATTENDEES.NAME}</Text>
        </Space>
      </InnerPageContentMainCol>
    );

  if (!hasEventAttendeesFeatureEnabled)
    return (
      <InnerPageContentMainCol>
        <Space>
          <Text>{MESSAGES.EVENT_ATTENDEES.UPGRADE}</Text>
        </Space>
      </InnerPageContentMainCol>
    );

  return (
    <>
      <InnerPageContentMainCol>
        {hasPermissions[PERMISSIONS.EVENT_ATTENDEES] && (
          <div>
            <EventAttendeeListHeaderContainer eventId={eventId} projectId={projectId} />
            <Space vertical />
            <EventAttendeesListContainer eventId={eventId} projectId={projectId} />
            <EditAttendeeApprovalStatusModalContainer projectId={projectId} />
          </div>
        )}
        <AddEventAttendeePanelContainer projectId={projectId} />
        <EditEventAttendeePanelContainer projectId={projectId} />
        <EventAttendeeFaceCheckinAddZonePanelContainer projectId={projectId} />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <EventAttendeeReportSummaryCardContainer eventId={eventId} projectId={projectId} />
        <EventAttendeeFaceCheckinCardContainer eventId={eventId} projectId={projectId} />
      </InnerPageContentSidebar>
    </>
  );
}
