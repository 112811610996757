import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import PhotoSliderWidgetGetCodeLayout from './PhotoSliderWidgetGetCodeLayout';
import { fetchProject } from '../../../projects/AccountProjectsDataDuck';
import { foldersMetaSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { isAnyHighlightFolderMadePublicSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import { appHeightSelector } from '../../../AppDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, projectId, shareId } = ownProps.match.params;

  const foldersMeta = foldersMetaSelector(state);
  const isItemsPresentInFolderMeta = Object.keys(foldersMeta).length > 0;

  return {
    eventId,
    projectId,
    shareId,
    isLoading:
      isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state) || isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    isItemsPresentInFolderMeta,
    isAnyFolderMadePublic: isAnyHighlightFolderMadePublicSelector(state),
    appHeight: appHeightSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProject: (projectId) => {
      dispatch(fetchProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSliderWidgetGetCodeLayout);
