import React from 'react';
import { Button, BUTTON_STYLES, Space, Col, Row, Loading, Modal, MODAL_SIZES, ModalTitle, Text } from '@premagic/myne';

type Props = {
  show: boolean;
  close: () => void;
  isLoading: boolean;
};

export default function PaymentDoneModal(props: Props) {
  const { show, close, isLoading } = props;
  return (
    <Modal show={show} close={close} size={MODAL_SIZES.MD}>
      {isLoading ? (
        <Space size={8}>
          <Loading />
        </Space>
      ) : (
        <div>
          <ModalTitle>
            Payment successful!{' '}
            <span role="img" aria-label="cake">
              🎂
            </span>
          </ModalTitle>
          <Text>We have received your payment! You are all set to use Premagic!</Text>
          <Row>
            <Col rightAlighed size={null}>
              <Button onClick={close} style={BUTTON_STYLES.DEFAULT}>
                Close
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
