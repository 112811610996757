import React from 'react';
import { Col, COLOR_SHADES, List, ListItem, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { OccasionsService } from '@premagic/core';

type ClientPaymentItemProps = {
  occasion: OccasionsService.OccasionType;
  number: number;
};

export function OccasionsItem(props: ClientPaymentItemProps) {
  const { occasion, number } = props;
  const { name, start_date_time: startDate, end_date_time: endDate, location } = occasion;
  const date = SimpleDateUtils.humanizeDateRageSimple(startDate, endDate);
  return (
    <ListItem>
      <Col size={1}>
        <Text block>{number}</Text>
      </Col>
      <Col size={3}>
        <Text block size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
          {name}
        </Text>
        <Text block>{location}</Text>
      </Col>
      <Col size={null} rightAlighed>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          {date}
        </Text>
      </Col>
    </ListItem>
  );
}

type Props = {
  occasionIds: Array<string>;
  occasions: Record<string, OccasionsService.OccasionType>;
};

export default function ClientPaymentsInvoiceOccasions(props: Props): JSX.Element {
  const { occasionIds, occasions } = props;
  return (
    <div>
      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2} block>
        Occasions
      </Text>
      <List>
        <ListItem>
          <Col size={1}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD}>
              #
            </Text>
          </Col>
          <Col size={3}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD}>
              Details
            </Text>
          </Col>
          <Col rightAlighed size={null}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD}>
              Date
            </Text>
          </Col>
        </ListItem>
        {occasionIds.length === 0 && (
          <Space>
            <Text muted>There are no occasions set</Text>
          </Space>
        )}
        {occasionIds.map((id, idx) => (
          <OccasionsItem key={id} occasion={occasions[id]} number={idx + 1} />
        ))}
      </List>
    </div>
  );
}
