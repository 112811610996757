import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import {
  deleteEventAttendeesCheckinZone,
  eventAttendeeCheckinZonesItemDataSelector,
} from '../EventAttendeesCheckinZonesDataDuck';
import EventAttendeeFaceCheckinZonesListItem from './EventAttendeeFaceCheckinZonesListItem';

function mapStateToProps(state, ownProps: { zoneId: string; projectId: string }) {
  const { zoneId, projectId } = ownProps;
  return {
    zoneId,
    projectId,
    checkinZone: eventAttendeeCheckinZonesItemDataSelector(zoneId)(state),
    isDeleting: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.DELETE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteZone: (projectId: string, zoneId: string) => {
      dispatch(deleteEventAttendeesCheckinZone(dispatch, projectId, zoneId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceCheckinZonesListItem);
