import React, { CSSProperties } from 'react';
import ClassNames from 'classnames';
import { i18n, SimpleDateUtils, StringUtils } from '@premagic/utils';

import { Color, COLOR_SHADES } from '../Color/Color';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './text.module.css';

export enum TEXT_SIZE {
  SIZE_1_LG = '1-lg', // 64 - 64 | 42 - 42 (phone)
  SIZE_1_MD = '1-md', // 38 - 38 | 22 - 22 (phone)
  SIZE_1 = '1', // 28 - 34
  SIZE_2 = '2', // 18 - 34
  SIZE_3 = '3', // 16 - 24
  SIZE_4 = '4', // 14 - 20
  SIZE_5 = '5', // 12 - 16
  SIZE_6 = '6', // 10 - 12
  SIZE_7 = '7', // 6 - 8
}

export enum TEXT_BOLDNESS {
  BOLD = 'bold',
  SEMI_BOLD = 'semibold',
  NORMAL = 'normal',
}

export enum TEXT_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

type TextProps = {
  children?: React.ReactNode;
  primaryStyle?: boolean;
  secondaryStyle?: boolean;
  size?: TEXT_SIZE;
  boldness?: TEXT_BOLDNESS;
  color?: COLOR_SHADES;
  className?: string;
  block?: boolean;
  muted?: boolean;
  ellipsis?: boolean;
  center?: boolean;
  strickOff?: boolean;
  transformUpper?: boolean;
  italic?: boolean;
  fromTheme?: boolean;
  animate?: boolean;
  cssStyle?: CSSProperties;
  translationTextKey?: i18n.TKeys;
  align?: TEXT_ALIGN;
};

export function Text(props: TextProps) {
  let { color = COLOR_SHADES.DARK } = props;
  const {
    children,
    size = TEXT_SIZE.SIZE_4,
    boldness = TEXT_BOLDNESS.NORMAL,
    muted = false,
    block = false,
    className = '',
    primaryStyle = false,
    secondaryStyle,
    center = false,
    ellipsis = false,
    strickOff = false,
    transformUpper,
    italic = false,
    fromTheme,
    animate,
    cssStyle,
    translationTextKey,
    align,
    ...elementProps
  } = props;

  if (muted) color = COLOR_SHADES.LIGHT;

  const classes = ClassNames(
    className,
    styles.text,
    styles[`text--size-${size}`],
    styles[`text--boldness-${boldness}`],
    {
      [styles['text--display-block']]: block,
      [styles['text--primary-style']]: primaryStyle,
      [styles['text--secondary-style']]: secondaryStyle,
      [styles['text--ellipsis']]: ellipsis,
      [styles['text--align-center']]: center,
      [styles['text--strick-off']]: strickOff,
      [styles['text--uppercase']]: transformUpper,
      [styles['text--italic']]: italic,
      [styles['from-theme']]: fromTheme,
      [styles['text--animate']]: animate,
      [styles[`text--align-${align}`]]: align,
    },
  );

  if (translationTextKey) {
    return (
      <Color shade={color} className={classes} {...elementProps} cssStyle={cssStyle}>
        {i18n.t(translationTextKey)}
      </Color>
    );
  }

  return (
    <Color shade={color} className={classes} {...elementProps} cssStyle={cssStyle}>
      {children}
    </Color>
  );
}

type TitleProps = {
  children: React.ReactNode;
  primary?: boolean;
};

export function Title(props: TitleProps): JSX.Element {
  const { children, primary } = props;
  return (
    <Text
      size={TEXT_SIZE.SIZE_1}
      boldness={TEXT_BOLDNESS.BOLD}
      block
      color={primary ? COLOR_SHADES.PRIMARY : COLOR_SHADES.DARKER}
    >
      {children}
    </Text>
  );
}

type SubTitleProps = {
  children: React.ReactNode;
};

export function SubTitle(props: SubTitleProps): JSX.Element {
  const { children } = props;

  const classes = ClassNames(styles['sub-title']);

  return (
    <Text block className={classes} size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
      {children}
    </Text>
  );
}

type TimeAgoProps = {
  date: string;
};

export function TimeAgo(props: TimeAgoProps): JSX.Element | null {
  const { date } = props;
  if (!date || date === 'no-date') return null;
  return <Tooltip message={SimpleDateUtils.fromNowForTooltip(date)}>{SimpleDateUtils.fromNow(date)}</Tooltip>;
}

type EscapedParagraphHTMLTextProps = {
  value: string;
  htmlSanitizeLevel?: 'basic' | 'full';
  overrideTextColor?: string;
  forPrint?: boolean;
};

export function EscapedParagraphHTMLText(props: EscapedParagraphHTMLTextProps): JSX.Element | null {
  const { value, htmlSanitizeLevel = 'basic', overrideTextColor, forPrint } = props;

  let customValue = value;

  if (overrideTextColor) {
    // changes color of each inner element except 'a' tags
    customValue = value.replace(/<[^>]*>/g, (match) => {
      // If the tag is not an anchor tag, replace the style attribute
      if (!match.startsWith('<a')) {
        return match.replace(/style="[^"]*"/g, `style="color:${overrideTextColor}"`);
      }
      // If the tag is an anchor tag, return it unchanged
      return match;
    });
    customValue = value.replace(/<[^>]*>/g, (match) => {
      // If the tag is not an anchor tag, replace the data-text-color-mark attribute
      if (!match.startsWith('<a')) {
        return match.replace(/data-text-color-mark="[^"]*"/g, `data-text-color-mark="${overrideTextColor}"`);
      }
      // If the tag is an anchor tag, return it unchanged
      return match;
    });
    // replaces the color of the entire element except 'a' tags
    customValue = `<span style=" color:${overrideTextColor}" data-text-color-mark="${overrideTextColor}">${customValue}</span>`;
  }

  const sanitizedValue = StringUtils.getSanitizedHTML(customValue, htmlSanitizeLevel);
  const hasContent = StringUtils.getTextFromHTMLString(value).trim().length > 0;
  if (!hasContent) return null;
  return (
    <div
      className={ClassNames('rich-text__html-value', {
        'rich-text__html-value--for-print': forPrint,
      })}
      dangerouslySetInnerHTML={{
        __html: sanitizedValue,
      }}
    />
  );
}
