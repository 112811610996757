import { Col, Input, Space, SubTitle } from '@premagic/myne';
import { usePhotoSliderWidgetData } from '../../../context/PhotoSliderWidgetContext';

export default function PhotoSliderWidgetMaxHeight() {
  const { customizations } = usePhotoSliderWidgetData();

  return (
    <>
      <SubTitle>Maximum height in pixel (px)</SubTitle>
      <Space vertical size={2} />
      <Col size={4}>
        <Input type="number" name="max_height" defaultValue={customizations?.max_height} />
      </Col>
    </>
  );
}
