import { Howler, Howl } from 'howler';
import { getCache } from './LocalCacheUtils';

type SoundPlayOptions = {
  autoplay: boolean;
  loop: boolean;
  volume: number;
  playOnVisible?: boolean;
};

export function hackToPlayTheSoundOnLoad(): void {
  /*  If an AudioContext is created prior to the document receiving a user gesture, it will be created in the "suspended" state, and you will need to call resume() after a user gesture is received.
   https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio

   document.click still doesnt work, use still have to click or touch the document
   */

  setTimeout(() => document.body.click(), 3000);
}
/*
 * https://stackoverflow.com/questions/34665417/safari-and-mobile-safari-require-page-refresh-to-play-howler-audio
 * https://github.com/goldfire/howler.js/issues/395#issuecomment-208574643
 * */
export function handleAutoSuspendIssue(onResume: () => void): void {
  hackToPlayTheSoundOnLoad();
  if (Howler.ctx && Howler.ctx.state && Howler.ctx.state == 'suspended') {
    Howler.ctx.resume().then(onResume);
  }
}

export function initPlayer(soundURL: string, options?: SoundPlayOptions): Howl {
  const { loop = true, autoplay = true, volume = 0.2, playOnVisible = true } = options || {};

  const player = new Howl({
    src: [soundURL],
    autoplay,
    loop,
    volume,
    onplayerror: () => player.once('unlock', () => player.play()),
  });

  player.once('load', () => {
    if (autoplay && document.visibilityState === 'visible') {
      setTimeout(() => player.play(), 1000);
    }
  });

  player.on('play', () => {
    if (!player.playing()) {
      player.play();
    }
  });
  player.on('playerror', console.log);
  player.on('loaderror', console.log);

  function handleVisibilityChange() {
    const hasMusicPausedByUser = getCache('hasMusicPausedByUser') || false;
    if (document.visibilityState === 'hidden') {
      player.pause();
    } else {
      if (!playOnVisible || hasMusicPausedByUser) return;
      player.play();
    }
  }

  document.addEventListener('visibilitychange', handleVisibilityChange, false);

  return player;
}

export function stopAllSongs(): void {
  // @ts-ignore - it has stop function but the type is not added
  Howler.stop();
}
