import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { AttendeeProfilePicture } from './AttendeeProfilePicture';
import { eventAttendeesSelector } from '../EventAttendeesDataDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';

function mapStateToProps(
  state,
  ownProps: {
    attendeeId?: string;
    onChangeOfFile: (file?: File | null) => void;
  },
) {
  const { attendeeId } = ownProps;

  return {
    attendee: attendeeId ? eventAttendeesSelector(state)[attendeeId] : undefined,
    error:
      errorObjectForTypeSelector(LOADINGS.EVENT_ATTENDEES.ADD)(state) ||
      errorObjectForTypeSelector(LOADINGS.EVENT_ATTENDEES.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeProfilePicture);
