import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { eventSponsorsSelector } from '../EventSponsorDataDuck';
import EventSponsorForm from './EventSponsorForm';

const PANEL_KEY = LOADINGS.EVENT_SPONSOR.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    sponsorId?: string;
  },
) {
  const { projectId, sponsorId } = ownProps;

  return {
    projectId,
    sponsorId,
    sponsor: sponsorId ? eventSponsorsSelector(state)[sponsorId] : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorForm);
