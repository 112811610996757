import { connect } from 'react-redux';
import { ProjectOrdersService } from '@premagic/core';
import ClientWebsitePageSidebar from './ClientWebsitePageSidebar';
import { activeOrderIdsForProjectAndFolderSelector } from '../../project-orders/ProjectOrdersDataDuck';
import { getFolderMetaData } from '../../projects/folder-meta/FoldersMetaDataDuck';
import { clientWebsitesSelector, saveClientWebsite } from '../ClientWebsiteDataDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;
  return {
    id,
    projectId,
    order: activeOrderIdsForProjectAndFolderSelector(
      projectId,
      id,
      ProjectOrdersService.ORDER_NAMES.DIGITAL_INVITE,
    )(state),
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    isSaving: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFolderMetaData: (projectId: string, folderId: string, options) => {
      dispatch(getFolderMetaData(dispatch, projectId, folderId, { ...options }));
    },
    saveClientWebsiteData: (id: string, projectId: string, data) => {
      dispatch(saveClientWebsite(dispatch, { id, projectId }, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsitePageSidebar);
