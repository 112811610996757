import React from 'react';

import { Button, BUTTON_SIZE, BUTTON_STYLES, Row, ScrollableContainer, Space } from '@premagic/myne';

import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../services/AppRouteURLService';
import AddSelectionAlbumButtonContainer from '../../../crm/event-deliveries/selection-album/AddSelectionAlbumButtonContainer';
import AccountFolderItemContainer from '../../folders/AccountFolderItemContainer';

type Props = {
  activeFolderId?: string;
  folderIds: Array<string>;
  projectId: string;
};

export default function SelectionFolders(props: Props): JSX.Element {
  const { folderIds, projectId, activeFolderId } = props;
  return (
    <div>
      <ScrollableContainer direction="vertical" showShadow scrollTo={`.js-folder-${activeFolderId}`}>
        <Row>
          {folderIds.map((folderId) => {
            const folderUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.SELECTION, {
              projectId,
              folderId,
            });
            return (
              <Button
                style={BUTTON_STYLES.DEFAULT}
                size={BUTTON_SIZE.SM}
                key={folderId}
                link={folderUrl}
                active={folderId === activeFolderId}
                className={`js-folder-${folderId}`}
              >
                <AccountFolderItemContainer projectId={projectId} folderId={folderId} />
              </Button>
            );
          })}
        </Row>
      </ScrollableContainer>
      <Space vertical size={2} />
      <AddSelectionAlbumButtonContainer projectId={projectId} key={activeFolderId} />
    </div>
  );
}
