import { BrowserUtils, HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { EVENT_ATTENDEE_GENDER } from './EventAttendeesService';
import { SimpleFileType } from './FileService';

export enum MEMORY_SOURCE {
  USER = 'user',
  SYSTEM = 'system',
}

export enum MEMORY_TYPE {
  FACE_SWAP = 'FACE_SWAP',
  AVATAR_GENERATOR = 'AVATAR_GENERATOR',
  GLAM_VIDEO = 'GLAM_VIDEO',
  COLOR_GRADE = 'COLOR_GRADE',
}

export const MEMORY_TYPE_DETAILS: Record<
  MEMORY_TYPE,
  {
    title: string;
    info: string;
  }
> = {
  [MEMORY_TYPE.FACE_SWAP]: {
    title: 'Face Swap',
    info: 'Swap your face in a template picture',
  },
  [MEMORY_TYPE.AVATAR_GENERATOR]: {
    title: 'Avatar Generator',
    info: 'Create an avatar from a picture',
  },
  [MEMORY_TYPE.GLAM_VIDEO]: {
    title: 'Glam Video',
    info: 'Create a glam video',
  },
  [MEMORY_TYPE.COLOR_GRADE]: {
    title: 'Color Grade',
    info: 'Color grade a picture',
  },
};

export enum MEMORY_GENERATOR_PLATFORM {
  FAL_AI = 'FAL_AI',
  MORFRAN = 'MORFRAN',
}

export const MEMORY_GENERATOR_PLATFORM_DETAILS: Record<
  MEMORY_GENERATOR_PLATFORM,
  {
    title: string;
  }
> = {
  [MEMORY_GENERATOR_PLATFORM.FAL_AI]: {
    title: 'Falcon',
  },
  [MEMORY_GENERATOR_PLATFORM.MORFRAN]: {
    title: 'Mongoose',
  },
};

export interface NewMemoryTemplateType {
  name: string;
  gender: EVENT_ATTENDEE_GENDER;
  age: number;
  target: Blob;
  type: MEMORY_TYPE;
  generator_platform: MEMORY_GENERATOR_PLATFORM;
}
export interface MemoryTemplateType extends Omit<NewMemoryTemplateType, 'target'> {
  id: string;
  target_asset: SimpleFileType;
  created_at: string;
  updated_at: string;
}

async function getFormData(memoryTemplate: NewMemoryTemplateType) {
  const formData = new FormData();
  const targetBlob = await BrowserUtils.createBlobFromUrl(memoryTemplate.target);
  formData.append('name', memoryTemplate.name);
  formData.append('gender', memoryTemplate.gender);
  formData.append('age', memoryTemplate.age.toString());
  formData.append('target', targetBlob || memoryTemplate.target);
  formData.append('type', memoryTemplate.type);
  formData.append('generator_platform', memoryTemplate.generator_platform);
  return formData;
}
export async function createMemoryTemplate(
  projectId: string,
  memoryTemplate: NewMemoryTemplateType,
): Promise<Record<string, MemoryTemplateType>> {
  const data = await getFormData(memoryTemplate);
  return HttpUtils.post(API_URLS.ATTENDEE_MEMORY_TEMPLATE.LIST(projectId), data, true).then(
    (response) => response.data.data,
  );
}

export function deleteMemoryTemplate(projectId: string, memoryTemplateId: string) {
  return HttpUtils.httpDelete(API_URLS.ATTENDEE_MEMORY_TEMPLATE.DETAILS(projectId, memoryTemplateId));
}

export async function updateMemoryTemplate(
  projectId: string,
  memoryTemplateId: string,
  memoryTemplate: NewMemoryTemplateType,
): Promise<Record<string, MemoryTemplateType>> {
  const data = await getFormData(memoryTemplate);
  return HttpUtils.put(API_URLS.ATTENDEE_MEMORY_TEMPLATE.DETAILS(projectId, memoryTemplateId), data).then(
    (response) => response.data.data,
  );
}

export function fetchMemoryTemplatesForProject(projectId: string): Promise<Record<string, MemoryTemplateType[]>> {
  return HttpUtils.get(API_URLS.ATTENDEE_MEMORY_TEMPLATE.LIST(projectId)).then((response) => response.data.data);
}

export function fetchMemoryTemplateWithShareId(shareId: string): Promise<Record<string, MemoryTemplateType>> {
  return HttpUtils.get(API_URLS.ATTENDEE_MEMORY_TEMPLATE.LIST_PUBLIC(shareId)).then((response) => response.data.data);
}

// MEMORIES

export interface NewMemoryType {
  selfie: string;
  template_id: string;
}
export interface MemoryType extends NewMemoryType {
  event_registration_id: string;
  attendee?: {
    name: string;
  };
  memory_asset: string;

  id: string;
  source: MEMORY_SOURCE;
  status: string;
  review: string;

  created_at: string;
  updated_at: string;
}
export function getAllMemories(projectId: string): Promise<Record<string, MemoryType>> {
  return HttpUtils.get(API_URLS.ATTENDEE_MEMORY.LIST(projectId)).then((response) => response.data?.data);
}
export function fetchAllMemoriesWithShareId(shareId: string): Promise<Record<string, MemoryType>> {
  return HttpUtils.get(API_URLS.ATTENDEE_MEMORY.LIST_PUBLIC(shareId)).then((response) => response.data?.data);
}

export function fetchMemoriesForUser(projectId: string, registrationId: string): Promise<Record<string, MemoryType>> {
  return HttpUtils.get(API_URLS.ATTENDEE_MEMORY.LIST_FOR_ATTENDEE(projectId, registrationId)).then(
    (response) => response.data?.data,
  );
}

export function fetchMemoriesForUserWithShareId(
  shareId: string,
  registrationId: string,
): Promise<Record<string, MemoryType>> {
  return HttpUtils.get(API_URLS.ATTENDEE_MEMORY.LIST_FOR_ATTENDEE_PUBLIC(shareId, registrationId)).then(
    (response) => response.data?.data,
  );
}

export async function createMemoryForUser(
  projectId: string,
  registrationId: string,
  memory: NewMemoryType,
): Promise<Record<string, MemoryType>> {
  const formData = new FormData();
  const targetBlob = await BrowserUtils.createBlobFromUrl(memory.selfie);
  formData.append('selfie', targetBlob || memory.selfie);
  formData.append('template_id', memory.template_id);

  return HttpUtils.post(API_URLS.ATTENDEE_MEMORY.LIST_FOR_ATTENDEE(projectId, registrationId), formData, true).then(
    (response) => response.data,
  );
}

export async function createMemoryForUserWithShareId(
  shareId: string,
  registrationId: string,
  memory: NewMemoryType,
): Promise<Record<string, MemoryType>> {
  const formData = new FormData();
  const targetBlob = await BrowserUtils.createBlobFromUrl(memory.selfie);
  formData.append('selfie', targetBlob || memory.selfie);
  formData.append('template_id', memory.template_id);
  return HttpUtils.post(
    API_URLS.ATTENDEE_MEMORY.LIST_FOR_ATTENDEE_PUBLIC(shareId, registrationId),
    formData,
    true,
  ).then((response) => response.data.data);
}

export function deleteMemoryForUserWithShareId(projectId: string, registrationId: string, memoryId: string) {
  return HttpUtils.httpDelete(API_URLS.ATTENDEE_MEMORY.DETAILS_FOR_ATTENDEE(projectId, registrationId, memoryId));
}

export function getAttendeeMemoryLimit() {
  return 8;
}
