import { connect } from 'react-redux';
import { DeviceDuck } from '@premagic/common-ducks';
import { albumCreatorSelectedAlbumIdSelector, setActivePage } from './AlbumCreatorDuck';
import AlbumCreatorLayout from './AlbumCreatorLayout';
import { activeCreatorPagesDataSelector, addImageAndSaveThePage } from './page/AlbumPageDataDuck';
import { eventIdForAlbumSelector } from './project/AlbumProjectDataDuck';

function mapStateToProps(state, ownProps) {
  const { pageNo = 1 } = ownProps.match.params;
  const albumId = albumCreatorSelectedAlbumIdSelector(state);

  return {
    pageNo: Number(pageNo),
    isMobileUp: DeviceDuck.isMobileUp(state),
    pageData: activeCreatorPagesDataSelector(state),
    albumId,
    eventId: eventIdForAlbumSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActivePage: (pageNo) => dispatch(setActivePage(dispatch, pageNo)),
    addImageToAlbumPage: (albumId, pageData, newImagePosition, newImageId) =>
      dispatch(
        addImageAndSaveThePage(dispatch, {
          albumId,
          pageData,
          newImagePosition,
          newImageId,
        }),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumCreatorLayout);
