import React, { useMemo, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  RealCheckbox,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { EventCustomFromFieldsService } from '@premagic/core';
import { CryptoUtils } from '@premagic/utils';

const typeOptions: Array<{
  value: EventCustomFromFieldsService.FORM_FIELD_TYPE;
  label: string;
}> = Object.entries(EventCustomFromFieldsService.FORM_FIELDS_TYPE_DETAILS).map(([id, details]) => ({
  value: id as EventCustomFromFieldsService.FORM_FIELD_TYPE,
  label: details.name,
}));

function TypeSelector(props: {
  defaultValue?: EventCustomFromFieldsService.FORM_FIELD_TYPE;
  selectOptions?: Array<string>;
}) {
  const { defaultValue, selectOptions } = props;
  const selectedTypeOption = typeOptions.find((option) => option.value === defaultValue) || typeOptions[0];
  const [selectedType, setSelectedTypeType] = useState<EventCustomFromFieldsService.FORM_FIELD_TYPE>(
    selectedTypeOption.value,
  );
  const [options, setSelectOptions] = useState<string[]>(selectOptions || ['Option 1', 'Option 2']);

  /* When the selected is EventCustomFromFieldsService.FORM_FIELD_TYPE.MULTI_SELECT or EventCustomFromFieldsService.FORM_FIELD_TYPE.SINGLE_SELECT
   * then we need to show 2 Input fields for the options and allow user to add more options on demand
   */

  function onAddOption() {
    const newOption = `Option ${options.length + 1}`;
    setSelectOptions([...options, newOption]);
  }

  function onRemoveOption(name: string) {
    setSelectOptions(options.filter((option) => option !== name));
  }

  return (
    <FormGroup>
      <FormLabel>Type</FormLabel>
      <Select
        options={typeOptions}
        value={selectedTypeOption}
        name="type"
        isMulti={false}
        onChange={(option) => setSelectedTypeType(option?.value as EventCustomFromFieldsService.FORM_FIELD_TYPE)}
      />
      {[
        EventCustomFromFieldsService.FORM_FIELD_TYPE.MULTI_SELECT,
        EventCustomFromFieldsService.FORM_FIELD_TYPE.SINGLE_SELECT,
      ].includes(selectedType) && (
        <FormGroup>
          <Space vertical />
          <FormLabel>Options</FormLabel>
          <Space vertical />
          {options.map((option, index) => (
            <Row key={option}>
              <div>
                <Input name={`select_options[${index}]`} placeholder="eg. Option 1" defaultValue={option} />
                <Space vertical />
              </div>
              <Col size={null} rightAlighed>
                <ButtonIcon title="Delete" onClick={() => onRemoveOption(option)}>
                  <Icon name="trash" size={ICON_SIZES.SM} />
                </ButtonIcon>
              </Col>
            </Row>
          ))}
          <Button size={BUTTON_SIZE.SM} style={BUTTON_STYLES.LINK} onClick={onAddOption}>
            <Icon name="plus" size={ICON_SIZES.SM} />
            <Space size={1} />
            Option
          </Button>
        </FormGroup>
      )}
    </FormGroup>
  );
}

type Props = {
  formFieldId?: string;
  formField?: EventCustomFromFieldsService.FormFieldType;
  scope: EventCustomFromFieldsService.FORM_FIELD_SCOPE;
  formFieldIds: Array<string>;
};

export default function EventFormFieldForm(props: Props) {
  const { formField, formFieldId, scope, formFieldIds } = props;
  const randomId = useMemo(() => CryptoUtils.createUUID(8), []);

  const newPosition = useMemo(() => {
    if (formFieldId) return formFieldId.length + 1;
    return 1;
  }, [formFieldId]);

  return (
    <div>
      <input type="hidden" name="name" value={formFieldId || randomId} />
      <input type="hidden" name="scope" value={scope} />
      <input type="hidden" name="visibility" value="true" />
      <FormGroup>
        <FormLabel isRequired>Label</FormLabel>
        <Input name="label" autoFocus required placeholder="eg. Occupation" defaultValue={formField?.label} />
      </FormGroup>
      <TypeSelector defaultValue={formField?.type} selectOptions={formField?.select_options} />
      <FormGroup>
        <FormLabel isRequired>Position</FormLabel>
        <Input
          size={INPUT_SIZES.XS}
          name="position"
          required
          defaultValue={formField?.position || newPosition}
          min={1}
          step={1}
          type="number"
        />
      </FormGroup>
      <FormGroup>
        <RealCheckbox name="is_required" defaultChecked={formField?.is_required}>
          Attendee must fill this field
        </RealCheckbox>
      </FormGroup>
    </div>
  );
}
