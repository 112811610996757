import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { SponsorCreativesService } from '@premagic/core';
import AddSponsorCreativePanel from './AddSponsorCreativePanel';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import { addSponsorCreativeForSponsor } from '../../event-sponsors/EventSponsorDataDuck';

const PANEL_KEY = LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.SPONSOR_CREATIVES.ADD)(state),
    error: errorObjectForTypeSelector(LOADINGS.SPONSOR_CREATIVES.ADD)(state),
    modalOptions: ModalDuck.modalOptionsSelector<{
      placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
    }>(LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_PANEL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSponsorCreative: (projectId: string, data) => dispatch(addSponsorCreativeForSponsor(dispatch, projectId, data)),
    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSponsorCreativePanel);
