import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  ListItem,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';

import { OccasionsService } from '@premagic/core';
import UserNamesContainer from '../../users/list/UserNamesContainer';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  eventId: string;
  occasion: OccasionsService.OccasionType;
};

export default function EventOccasionSummaryListItem(props: Props): React.ReactElement {
  const { occasion, eventId } = props;
  const {
    name,
    start_date_time: startDate,
    end_date_time: endDate,
    location,
    location_link: locationLink,
    service_providers: serviceProviders,
    number_of_guests: numberOfGuests,
  } = occasion;
  const urlForDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__OCCASIONS, {
    eventId,
    focusId: occasion.id,
  });
  const rowItems = [
    {
      icon: 'users',
      value: numberOfGuests,
    },
    {
      icon: 'map_pin',
      value: locationLink ? (
        <SimpleLink href={locationLink} isExternalLink>
          {location}
        </SimpleLink>
      ) : (
        location
      ),
    },
    {
      icon: 'clock',
      value: SimpleDateUtils.humanizeDateRage(startDate, endDate),
    },
    {
      icon: 'user_check',
      value: <UserNamesContainer userIds={serviceProviders} />,
    },
  ];

  return (
    <ListItem>
      <Col size={11}>
        <Button link={urlForDetails} style={BUTTON_STYLES.RESET}>
          <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
            {name}
          </Text>
        </Button>
        <Space vertical size={1} />
        {rowItems.map((item) => (
          <div key={item.icon}>
            <Row vcenter>
              <Icon name={item.icon} size={ICON_SIZES.SM} />
              <Space size={2} />
              <Text color={item.value ? COLOR_SHADES.DARKER : COLOR_SHADES.LIGHT} block>
                {item.value || '(blank)'}
              </Text>
            </Row>
            <Space vertical size={2} />
          </div>
        ))}
      </Col>
    </ListItem>
  );
}
