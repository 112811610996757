import { InnerPage, InnerPageContent, InnerPageHeader, Space } from '@premagic/myne';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import APP_URLS from '../../../services/AppRouteURLService';
import DAMFilesPageContainer from './dam-files/DAMFilesPageContainer';
import DAMLibraryFiltersContainer from './filters/DAMLibraryFiltersContainer';
import DAMLibraryImagesPopupContainer from './image-full-view/DAMLibraryImagesPopupContainer';
import { DAM_LIBRARY_FILTER_KEYS, DAMLibraryFiltersType } from './filters/DAMLibraryFilterTypes';

type Props = {
  filters: DAMLibraryFiltersType;
  getEventAttendeesForProject: (projectId: string) => void;
};

export default function DAMLibraryPage(props: Props) {
  const { filters, getEventAttendeesForProject } = props;

  const projectIds = filters?.[DAM_LIBRARY_FILTER_KEYS.PROJECT];
  const projectId = Array.isArray(projectIds) ? projectIds[0] : projectIds;

  useEffect(() => {
    if (projectId) {
      getEventAttendeesForProject(projectId);
    }
  }, [projectId, getEventAttendeesForProject]);

  return (
    <InnerPage>
      <InnerPageHeader iconName="book_open" title="Library">
        Library
      </InnerPageHeader>
      <InnerPageContent>
        <DAMLibraryFiltersContainer filters={filters} />
        <Space vertical size={2} />
        <Switch>
          <Route path={APP_URLS.LIBRARY.FILES} component={DAMFilesPageContainer} />
        </Switch>
        <Route exact path={APP_URLS.LIBRARY.FILE_DETAILS} component={DAMLibraryImagesPopupContainer} />
        <Space vertical />
      </InnerPageContent>
    </InnerPage>
  );
}
