import { ModalDuck } from '@premagic/common-ducks';
import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { saveCustomEventFromField } from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';
import {
  isEventRegistrationEnablePhotoUploadEnabledSelector,
  saveProjectMetaData,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { formfieldIdForEditSelector, setEditOfFormField } from '../RegistrationFormPageDuck';
import EditEventFormFieldPanel from './EditEventFormFieldPanel';

const PANEL_KEY = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.EVENT_CUSTOM_FORM_FIELDS.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const formFieldId = formfieldIdForEditSelector(state);

  return {
    projectId,
    formFieldId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    hasFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    )(state),
    isEventRegistrationEnablePhotoUploadEnabled: isEventRegistrationEnablePhotoUploadEnabledSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormField: (options, data) => {
      dispatch(saveCustomEventFromField(dispatch, options, data));
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
      dispatch(setEditOfFormField(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEventFormFieldPanel);
