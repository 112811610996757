import { isArray, isNaN } from 'lodash';
import naturalCompare from 'string-natural-compare';
import { getUnixTimestamp, getDateStringISOFromUglyBackendDateFormat } from './SimpleDateUtils';

export function listify(list: Array<string | number>, limit?: number): string | null {
  if (!list || !isArray(list)) return null;
  if (list.length === 0) return null;
  if (list.length === 1) return String(list);

  if (limit !== undefined && limit < list.length) {
    const remaining = list.length - limit;
    return `${list.slice(0, limit).join(', ')}, and ${remaining} more...`;
  }

  return `${list.slice(0, -1).join(', ')} & ${list.slice(-1)}`;
}

// flicked from https://gist.github.com/thomasjonas/f99f48e278fd2dfe82edb2c6f7d6c365
export function intersperse<T>(arr: Array<T>, sep): Array<T> {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    (xs, x, idx) => {
      const separator = typeof sep === 'function' ? sep(idx) : sep;
      return xs.concat([separator, x]);
    },
    [arr[0]],
  );
}

export function stringSortFunction(str1 = '', str2 = ''): number {
  const nameA = str1.toLowerCase();
  const nameB = str2.toLowerCase();
  if (nameA.localeCompare) return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });

  if (nameA < nameB)
    // sort string ascending
    return -1;
  if (nameA > nameB) return 1;
  return 0; // default return value (no sorting)
}

export function stringSortFunctionNatural(
  str1: string,
  str2: string,
  options: {
    decodeURIName: boolean;
  } = {
    decodeURIName: false,
  },
): number {
  if (options.decodeURIName) return naturalCompare.caseInsensitive(decodeURI(str1), decodeURI(str2));
  return naturalCompare.caseInsensitive(str1, str2);
}

export function dateSortFunction(date1?: string, date2?: string, isDescending?: boolean): number {
  if (!date1 || !date2) return 0;
  const ts1 = getUnixTimestamp(getDateStringISOFromUglyBackendDateFormat(date1));
  const ts2 = getUnixTimestamp(getDateStringISOFromUglyBackendDateFormat(date2));
  if (isDescending) return ts2 - ts1;

  return ts1 - ts2;
}

export function dateSortForISODateFunction(date1: string, date2: string, isDescending?: boolean): number {
  if (!date1 || !date2) return 0;
  const ts1 = getUnixTimestamp(date1);
  const ts2 = getUnixTimestamp(date2);
  if (isDescending) return ts2 - ts1;

  return ts1 - ts2;
}

export function convertToArray<T>(items: T | Array<T> | null): Array<T> {
  if (isArray(items)) return items;
  if (!items) return [];
  return [items];
}

function stringIsNumber(value) {
  return isNaN(Number(value)) === false;
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function getArrayFromEnum<T extends string>(enumme): Array<T> {
  return Object.values(enumme) as Array<T>;
}

export function arrayMove(array, from, to) {
  const arrayCollection = array.slice();
  arrayCollection.splice(to < 0 ? arrayCollection.length + to : to, 0, arrayCollection.splice(from, 1)[0]);

  return arrayCollection;
}

export function getRandomItemsFromArray<T>(arr: Array<T>, limit: number) {
  const result = new Array(limit);
  let len = arr.length;
  const taken = new Array(len);
  if (limit > len) throw new RangeError('getRandomItemsFromArray: more elements taken than available');
  let tempLimit = limit;
  while (tempLimit) {
    const x = Math.floor(Math.random() * len);
    result[tempLimit] = arr[x in taken ? taken[x] : x];
    len -= 1;
    taken[x] = len in taken ? taken[len] : len;
    tempLimit -= 1;
  }
  return result;
}
