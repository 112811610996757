import { HttpUtils, StringUtils } from '@premagic/utils';
import { omit } from 'lodash';
import { API_URLS } from './APIURLService';

export enum FORM_FIELD_SCOPE {
  REGISTRATION = 'REGISTRATION',
  DEFAULT = 'DEFAULT',
  SPONSOR = 'SPONSOR',
}

export enum FORM_FIELD_TYPE {
  TEXT = 'text',
  EMAIL = 'email',
  TELEPHONE = 'tel',
  PARAGRAPH = 'paragraph',
  NUMBER = 'number',
  DATE = 'date',
  URL = 'url',
  SINGLE_SELECT = 'single-select',
  MULTI_SELECT = 'multi-select',
  CHECKBOX = 'checkbox',
}

export const FORM_FIELDS_TYPE_DETAILS: Record<
  FORM_FIELD_TYPE,
  {
    name: string;
  }
> = {
  [FORM_FIELD_TYPE.TEXT]: {
    name: 'Single line text',
  },
  [FORM_FIELD_TYPE.NUMBER]: {
    name: 'Number',
  },
  [FORM_FIELD_TYPE.DATE]: {
    name: 'Date',
  },
  [FORM_FIELD_TYPE.PARAGRAPH]: {
    name: 'Paragraph text',
  },
  [FORM_FIELD_TYPE.URL]: {
    name: 'Web-link/URL',
  },
  [FORM_FIELD_TYPE.SINGLE_SELECT]: {
    name: 'Choose one option from a list',
  },
  [FORM_FIELD_TYPE.MULTI_SELECT]: {
    name: 'Choose multiple option from a list',
  },
  [FORM_FIELD_TYPE.EMAIL]: {
    name: 'Email',
  },
  [FORM_FIELD_TYPE.TELEPHONE]: {
    name: 'Phone number',
  },
  [FORM_FIELD_TYPE.CHECKBOX]: {
    name: 'Checkbox',
  },
};

export type NewFormFieldType = {
  name: string; // this is the id
  label: string;
  type: FORM_FIELD_TYPE;
  scope: FORM_FIELD_SCOPE;
};

export type FormFieldType = NewFormFieldType & {
  position: number;
  default_value: string;
  is_required: boolean;
  char_limit?: number;
  select_options?: Array<string>;
  visibility: boolean;
  createByDefault: boolean;
  validation?: string;
  isConfigurable?: boolean;
};

export enum SYSTEM_FORM_FIELDS {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  USER_PHOTO = 'USER_PHOTO',
}

export const SYSTEM_FORM_FIELDS_DETAILS: Record<SYSTEM_FORM_FIELDS, FormFieldType> = {
  [SYSTEM_FORM_FIELDS.NAME]: {
    name: SYSTEM_FORM_FIELDS.NAME,
    label: 'Full Name',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 1,
    default_value: '',
    is_required: true,
    visibility: true,
    createByDefault: false,
  },
  [SYSTEM_FORM_FIELDS.EMAIL]: {
    name: SYSTEM_FORM_FIELDS.EMAIL,
    label: 'Email',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 2,
    default_value: '',
    is_required: true,
    visibility: true,
    createByDefault: false,
  },
  [SYSTEM_FORM_FIELDS.PHONE]: {
    name: SYSTEM_FORM_FIELDS.PHONE,
    label: 'Whatsapp number',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 3,
    default_value: '',
    is_required: false,
    visibility: true,
    createByDefault: false,
  },
  [SYSTEM_FORM_FIELDS.USER_PHOTO]: {
    name: SYSTEM_FORM_FIELDS.USER_PHOTO,
    label: 'Selfie',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 4,
    default_value: '',
    is_required: true,
    visibility: true,
    createByDefault: false,
    isConfigurable: true,
  },
};

export enum SYSTEM_CUSTOM_FORM_FIELDS { // all the id should be -
  DISPLAY_ID = 'DISPLAY-ID',
  USER_ROLE = 'USER-ROLE',
  BIO = 'BIO',
  INTERESTS = 'INTERESTS',
  USER_COMPANY = 'USER-COMPANY',
  USER_WEBSITE = 'USER-WEBSITE',
  SOCIAL_LINKEDIN = 'SOCIAL-LINKEDIN',
  SOCIAL_INSTAGRAM = 'SOCIAL-INSTAGRAM',
  SOCIAL_TWITTER = 'SOCIAL-TWITTER',
  DATE_OF_BIRTH = 'DATE-OF-BIRTH',
  FACE = 'face_asset',
  QR_CODE = 'checkin_qr_code',
}
export const INTERNAL_SYSTEM_CUSTOM_FORM_FIELDS = [SYSTEM_CUSTOM_FORM_FIELDS.FACE];

export const SYSTEM_CUSTOM_FORM_FIELDS_DETAILS: Record<SYSTEM_CUSTOM_FORM_FIELDS, FormFieldType> = {
  [SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID,
    label: 'Display ID',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 1,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: true,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE,
    label: 'Role',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 2,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: true,
  },

  [SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY,
    label: 'Company name',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 3,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: true,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.FACE]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.FACE,
    label: 'Face Asset',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 3,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.BIO]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.BIO,
    label: 'About me',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 2,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.INTERESTS]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.INTERESTS,
    label: 'Interests',
    type: FORM_FIELD_TYPE.MULTI_SELECT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 5,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.USER_WEBSITE]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.USER_WEBSITE,
    label: 'Website',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 4,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_LINKEDIN]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_LINKEDIN,
    label: 'Linkedin',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 5,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_INSTAGRAM]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_INSTAGRAM,
    label: 'Instagram',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 6,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_TWITTER]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.SOCIAL_TWITTER,
    label: 'Twitter',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 7,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.DATE_OF_BIRTH]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.DATE_OF_BIRTH,
    label: 'Date of birth',
    type: FORM_FIELD_TYPE.DATE,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 8,
    default_value: '',
    is_required: true,
    visibility: false,
    createByDefault: false,
  },
  [SYSTEM_CUSTOM_FORM_FIELDS.QR_CODE]: {
    name: SYSTEM_CUSTOM_FORM_FIELDS.QR_CODE,
    label: 'QR Code',
    type: FORM_FIELD_TYPE.TEXT,
    scope: FORM_FIELD_SCOPE.REGISTRATION,
    position: 9,
    default_value: '',
    is_required: false,
    visibility: false,
    createByDefault: false,
  },
};

export function fetchFormFields(projectId: string): Promise<Record<string, FormFieldType>> {
  return HttpUtils.get(API_URLS.CUSTOM_EVENT_FORM_FIELDS.ROOT(projectId))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}
export function fetchPublicFormFields(domain: string, folderId: string): Promise<Record<string, FormFieldType>> {
  return HttpUtils.get(API_URLS.CUSTOM_EVENT_FORM_FIELDS.PUBLIC_FORM_FIELDS(domain, folderId))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function createFormField(projectId, data: NewFormFieldType): Promise<Record<string, FormFieldType>> {
  return HttpUtils.post(API_URLS.CUSTOM_EVENT_FORM_FIELDS.ROOT(projectId), {
    [data.name]: omit(data, 'createByDefault'),
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function updateFormField(
  options: {
    projectId: string;
    formFieldId: string;
  },
  data: Partial<FormFieldType>,
): Promise<FormFieldType> {
  const { projectId, formFieldId } = options;
  return HttpUtils.put(API_URLS.CUSTOM_EVENT_FORM_FIELDS.ROOT(projectId), { [formFieldId]: data })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function deleteFormField(options: { projectId: string; formFieldId: string }): Promise<void> {
  const { projectId, formFieldId: id } = options;
  return HttpUtils.httpDelete(API_URLS.CUSTOM_EVENT_FORM_FIELDS.ROOT(projectId), {
    data: {
      key: id,
    },
  })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}
