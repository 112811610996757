import { connect } from 'react-redux';
import TemplatePicker from './TemplatePicker';
import {
  activeCreatorPagesDataSelector,
  albumCreatorSelectedTemplateForActivePageSelector,
  setTemplateForPageAndUpdateImage,
} from '../page/AlbumPageDataDuck';
import { albumCreatorSelectedAlbumIdSelector } from '../AlbumCreatorDuck';
import { getNumberOfPhotosForTemplate } from './AlbumPageTemplateService';

function mapStateToProps(state) {
  const albumId = albumCreatorSelectedAlbumIdSelector(state);
  return {
    selectedTemplateName: albumCreatorSelectedTemplateForActivePageSelector(state),
    albumId,
    pageData: activeCreatorPagesDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectTemplate: (albumId, pageData, templateName) => {
      dispatch(
        setTemplateForPageAndUpdateImage(dispatch, albumId, pageData, {
          templateName,
          positionsInTemplate: getNumberOfPhotosForTemplate(templateName),
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePicker);
