import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  LoadingDots,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import EventAttendeeWithoutPhotoAlertItemContainer from './EventAttendeeWithoutPhotoAlertItemContainer';

type Props = {
  show: boolean;
  close: () => void;
  isLoading: boolean;
  eventAttendeesWithoutPhoto: Array<string>;
};

export default function UsersWithoutPhotoAlertModal(props: Props): JSX.Element {
  const { show, close, isLoading, eventAttendeesWithoutPhoto } = props;
  return (
    <Modal show={show} close={close} size={MODAL_SIZES.MD} showCloseButton>
      <ModalTitle>Attendees without photo</ModalTitle>
      <Text>This is a list of attendees without photos. Ensure that the permission to save the photos is enabled.</Text>
      <Space vertical />
      <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
        <Col size={1}>
          <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
            #
          </Text>
        </Col>
        <Col size={5}>
          <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
            Name
          </Text>
        </Col>
        <Col size={4}>
          <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
            Title
          </Text>
        </Col>

        <Col size={null} rightAlighed>
          <Text muted size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
            Company
          </Text>
        </Col>
      </ListItem>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <List>
          {eventAttendeesWithoutPhoto.map((id, idx) => (
            <EventAttendeeWithoutPhotoAlertItemContainer key={id} attendeeId={id} slNo={idx + 1} />
          ))}
        </List>
      )}

      <Space vertical size={8} />
      <Row>
        <Col rightAlighed size={null}>
          <Button size={BUTTON_SIZE.MD} onClick={close} style={BUTTON_STYLES.TERTIARY}>
            Okay
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
