import { List as IList } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { EventTrackerService, PortfolioService } from '@premagic/core';
import { ActionTypeUtils } from '@premagic/utils';
import { LOADINGS } from '../../../../common/Constants';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { entitiesDataSelector, EntitiesStateType } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('PORTFOLIO');

const setPortfolioData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const addProjectToPortfolio = createAction(getActionType('DATA', 'UPDATE'), (dispatch, projectId) => projectId);

const removeProjectFromPortfolio = createAction(getActionType('DATA', 'REMOVE'), (dispatch, projectId) => projectId);

export const fetchProjectsShownInPortfolio = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.PORTFOLIO.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  PortfolioService.fetchProjectsShownInPortfolio()
    .then((response) => {
      dispatch(setPortfolioData(dispatch, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const showProjectInPortfolio = createAction(getActionType('DATA', 'SAVE'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.PROJECT.SHOW_IN_PORTFOLIO;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.SHOW_IN_PORTFOLIO, {
    enabled: true,
  });
  dispatch(addProjectToPortfolio(dispatch, projectId));

  PortfolioService.showProjectInPortfolio(projectId)
    .then((response) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((response) => {
      dispatch(setErrorState(dispatch, loadingKey, response.data));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const hideProjectInPortfolio = createAction(getActionType('DATA', 'DELETE'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.PROJECT.SHOW_IN_PORTFOLIO;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.SHOW_IN_PORTFOLIO, {
    enabled: false,
  });
  dispatch(removeProjectFromPortfolio(dispatch, projectId));

  PortfolioService.hideProjectInPortfolio(projectId)
    .then((response) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((response) => {
      dispatch(setErrorState(dispatch, loadingKey, response.data));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

type StateType = {
  projects: IList<string>;
};
const initialState = {
  projects: IList(),
};

export default handleActions(
  {
    [setPortfolioData.toString()]: (state, action: { payload }) => ({
      ...state,
      projects: IList(action.payload),
    }),
    [addProjectToPortfolio.toString()]: (state, action: { payload }) => ({
      ...state,
      projects: state.projects.merge(action.payload),
    }),
    [removeProjectFromPortfolio.toString()]: (state, action: { payload }) => ({
      ...state,
      projects: state.projects.filter((projectId) => projectId !== action.payload),
    }),
  },
  initialState,
);

export const portfolioDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.portfolio as StateType).projects,
);
