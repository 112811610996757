import React from 'react';
import ClassNames from 'classnames';
import { MediaUtils, StringUtils, useElementSize } from '@premagic/utils';
import { ProjectMetaService } from '@premagic/core';
import { Card, CARD_STYLES } from '../Card/Cards';
import { Color, COLOR_SHADES } from '../Color/Color';
import { DEVICE_SIZES, Iphone } from '../Devices/Devices';
import { Col, Row, Space } from '../Grid';
import { IMAGE_V2_STYLES, ImageV2, SimpleImage } from '../Media';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import styles from './highlight-preview.module.css';
import { Bounce, FadeIn } from '../Animation/Animation';
import { Icon } from '../Icon/Icons';
import { LoadingDots } from '../Loading/Loading';

type CompanyLogoProps = {
  logo?: string;
  name: string;
  size?: 'default' | 'md' | 'sm' | 'xs';
};

const SIZE_MAP = {
  xs: '20px',
  sm: '34px',
  md: '48px',
  default: '64px',
};

export function CompanyLogo(props: CompanyLogoProps) {
  const { logo, name, size = 'default' } = props;
  if (logo) {
    return <SimpleImage src={logo} alt={name} style={{ height: SIZE_MAP[size] }} />;
  }
  return (
    <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} primaryStyle>
      {name}
    </Text>
  );
}

type HighlightPreviewDesktopProps = {
  coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  imageSource?: string;
  alt: string;
  companyLogo?: string;
  companyName?: string;
  isFontLoading?: boolean;
  title: string;
  titleColor?: COLOR_SHADES;
  imageWidth: number;
  size?: 'lg' | 'sm';
  fromTheme?: boolean;
  disableIconAnimation?: boolean;
  imageLoadType?: MediaUtils.DYNAMIC_IMAGE_TYPES;
  focalPoint?: {
    x: number;
    y: number;
  };
  isCoverTitleHidden?: boolean;
  isCoverLogoHidden?: boolean;
};

export function HighlightPreviewDesktop(props: HighlightPreviewDesktopProps) {
  const {
    coverLayout,
    imageSource,
    alt,
    companyLogo,
    companyName = 'Company Name',
    isFontLoading,
    title,
    titleColor = COLOR_SHADES.DARKER,
    imageWidth,
    size = 'lg',
    fromTheme = true,
    disableIconAnimation,
    imageLoadType,
    focalPoint,
    isCoverTitleHidden,
    isCoverLogoHidden,
  } = props;

  const coverImageClasses = ClassNames(styles[`desktop-preview-cover__layout--${coverLayout}`]);

  const contentClasses = ClassNames(
    styles['desktop-preview__content'],
    styles[`desktop-preview__content--${coverLayout}`],
    {
      [styles[`desktop-preview__content--${coverLayout}-from-theme`]]: fromTheme,
    },
  );

  const plainPreviewImageClasses = ClassNames(
    styles['plain-preview-image__desktop'],
    styles[`plain-preview-image__desktop--${coverLayout}`],
  );

  let imageStyle;

  if (coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC) {
    imageStyle = IMAGE_V2_STYLES.FULL_IMAGE;
  } else {
    imageStyle =
      !focalPoint && coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT
        ? IMAGE_V2_STYLES.FULL_HEIGHT
        : IMAGE_V2_STYLES.COVER;
  }

  return (
    <div className={ClassNames(styles[`desktop-preview--size-${size}`])}>
      <Card style={CARD_STYLES.TERTIARY} cssStyle={!fromTheme ? { boxShadow: 'none' } : {}} overflowHidden>
        <div
          className={ClassNames(
            styles['highlight-preview__content'],
            styles['highlight-preview__content--desktop'],
            styles[`highlight-preview__desktop-container--${coverLayout}`],
            {
              [styles['highlight-preview--theme-color']]: fromTheme,
            },
          )}
        >
          <Col size={12}>
            <Row center vcenter>
              <div className={coverImageClasses}>
                {imageSource ? (
                  <ImageV2
                    src={imageSource}
                    style={imageStyle}
                    alt={alt}
                    focalPoint={focalPoint}
                    width={imageWidth}
                    fromTheme={fromTheme}
                    imageLoadType={imageLoadType}
                    cssStyle={
                      coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR
                        ? { borderRadius: '5px' }
                        : {}
                    }
                  />
                ) : (
                  <div className={plainPreviewImageClasses} />
                )}
              </div>
            </Row>
          </Col>

          {isCoverLogoHidden &&
          isCoverTitleHidden &&
          coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC ? null : (
            <div className={contentClasses}>
              {!isCoverLogoHidden && (
                <Row center>
                  <CompanyLogo logo={companyLogo} name={companyName} size="md" />
                </Row>
              )}

              <Space size={2}>
                {(() => {
                  if (isCoverTitleHidden) return null;

                  if (isFontLoading) {
                    return (
                      <>
                        <Space size={1}>
                          <LoadingDots size="sm" />
                        </Space>
                        <div style={{ height: '8px' }} />
                      </>
                    );
                  }

                  return (
                    <Text
                      center
                      block
                      color={fromTheme ? COLOR_SHADES.FROM_THEME : titleColor}
                      boldness={TEXT_BOLDNESS.BOLD}
                      size={
                        coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT
                          ? TEXT_SIZE.SIZE_2
                          : TEXT_SIZE.SIZE_1
                      }
                      primaryStyle
                      fromTheme
                    >
                      {title}
                    </Text>
                  );
                })()}
              </Space>
              {(() => {
                if (coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC) return null;

                return (
                  <Row center>
                    <Color shade={fromTheme ? COLOR_SHADES.FROM_THEME : COLOR_SHADES.DARKER}>
                      {!disableIconAnimation ? (
                        <Bounce>
                          <Icon name="chevron_down" />
                        </Bounce>
                      ) : (
                        <Icon name="chevron_down" />
                      )}
                    </Color>
                  </Row>
                );
              })()}
            </div>
          )}
          {coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR && imageSource ? (
            <FadeIn>
              <div
                className={ClassNames(styles['desktop-preview__background--blurred-image'], {
                  [styles['desktop-preview__background--blurred-image-from-theme']]: fromTheme,
                })}
              >
                <ImageV2
                  src={imageSource}
                  style={IMAGE_V2_STYLES.COVER}
                  alt={alt}
                  width={imageWidth}
                  focalPoint={focalPoint}
                />
              </div>
            </FadeIn>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

type HighlightPreviewIphoneProps = {
  coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  imageSource: string;
  alt: string;
  companyLogo?: string;
  companyName: string;
  isFontLoading?: boolean;
  title: string;
  focalPoint?: {
    x: number;
    y: number;
  };
  imageWidth: number;
  fromTheme?: boolean;
  isCoverTitleHidden?: boolean;
  isCoverLogoHidden?: boolean;
};

export function HighlightPreviewIphone(props: HighlightPreviewIphoneProps) {
  const {
    coverLayout,
    imageSource,
    alt,
    companyLogo,
    companyName,
    isFontLoading,
    title,
    focalPoint,
    imageWidth,
    fromTheme = true,
    isCoverTitleHidden,
    isCoverLogoHidden,
  } = props;

  const coverImageClasses = ClassNames(styles[`iphone-preview__layout--${coverLayout}`]);

  const contentClasses = ClassNames(
    styles[`iphone-preview__content`],
    styles[`iphone-preview__content--${coverLayout}`],
    {
      [styles[`iphone-preview__content--${coverLayout}-from-theme`]]: fromTheme,
    },
  );

  const plainPreviewImageClasses = ClassNames(
    styles['plain-preview-image__iphone'],
    styles[`plain-preview-image__iphone--${coverLayout}`],
  );

  function dynamicImageHeight() {
    if (isCoverLogoHidden && isCoverTitleHidden) {
      return 420;
    }
    if (isCoverLogoHidden || isCoverTitleHidden) {
      return 350;
    }
    return 300;
  }

  let imageStyle;

  if (coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC) {
    imageStyle = IMAGE_V2_STYLES.FULL_IMAGE;
  } else {
    imageStyle =
      !focalPoint && coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT
        ? IMAGE_V2_STYLES.FULL_HEIGHT
        : IMAGE_V2_STYLES.COVER;
  }

  return (
    <div className={ClassNames(styles['iphone-preview'])}>
      <Iphone size={DEVICE_SIZES.SM}>
        <div
          className={ClassNames(
            styles['highlight-preview__content'],
            styles['highlight-preview__content--iphone'],
            styles[`highlight-preview__iphone-container--${coverLayout}`],
            {
              [styles['highlight-preview--theme-color']]: fromTheme,
            },
          )}
        >
          <Row center>
            <div
              className={coverImageClasses}
              style={
                coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT
                  ? { height: `${dynamicImageHeight()}px` }
                  : {}
              }
            >
              {(() => {
                if (imageSource)
                  return (
                    <ImageV2
                      src={imageSource}
                      style={imageStyle}
                      alt={alt}
                      focalPoint={focalPoint}
                      width={imageWidth}
                      fromTheme={fromTheme}
                      cssStyle={
                        coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR
                          ? { borderRadius: '5px' }
                          : {}
                      }
                    />
                  );
                return <div className={plainPreviewImageClasses} />;
              })()}
            </div>
          </Row>
          {isCoverLogoHidden &&
          isCoverTitleHidden &&
          coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC ? null : (
            <div className={contentClasses}>
              {!isCoverLogoHidden && (
                <Row center>
                  <CompanyLogo logo={companyLogo} name={companyName} size="sm" />
                </Row>
              )}

              {(() => {
                if (isCoverTitleHidden) return null;

                if (isFontLoading) {
                  return <LoadingDots size="xs" />;
                }

                return (
                  <Space size={2}>
                    <Text
                      center
                      block
                      color={COLOR_SHADES.FROM_THEME}
                      boldness={TEXT_BOLDNESS.BOLD}
                      size={
                        coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT
                          ? TEXT_SIZE.SIZE_4
                          : TEXT_SIZE.SIZE_3
                      }
                      primaryStyle
                      fromTheme
                    >
                      {title}
                    </Text>
                  </Space>
                );
              })()}
              {(() => {
                if (coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC) return null;

                if (
                  coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT &&
                  isCoverTitleHidden &&
                  isCoverLogoHidden
                )
                  return null;

                return (
                  <Row center>
                    <Bounce>
                      <Color shade={fromTheme ? COLOR_SHADES.FROM_THEME : COLOR_SHADES.DARKER}>
                        <Icon name="chevron_down" />
                      </Color>
                    </Bounce>
                  </Row>
                );
              })()}
            </div>
          )}

          {coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.BACKGROUND_BLUR && imageSource ? (
            <FadeIn>
              <div
                className={ClassNames(styles['iphone-preview__background--blurred-image'], {
                  [styles['iphone-preview__background--blurred-image-from-theme']]: fromTheme,
                })}
              >
                <ImageV2
                  src={imageSource}
                  style={IMAGE_V2_STYLES.COVER}
                  alt={alt}
                  width={imageWidth}
                  focalPoint={focalPoint}
                />
              </div>
            </FadeIn>
          ) : null}
          {coverLayout === ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.CLASSIC && (
            <div style={{ order: -1 }}>
              <Row wrap>
                {[1, 2, 3, 4, 5].map((item) => (
                  <Space size={3}>
                    <div className={styles['highlight-preview__dummy-boxes-sm']} />
                  </Space>
                ))}
              </Row>
            </div>
          )}
        </div>
      </Iphone>
    </div>
  );
}

interface HighlightPreviewProps {
  imageSource: string;
  title: string;
  alt: string;
  focalPoint?: {
    x: number;
    y: number;
  };
  companyLogo?: string;
  companyName: string;
  isFontLoading?: boolean;
  coverLayout: ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  isCoverTitleHidden?: boolean;
  isCoverLogoHidden?: boolean;
}

export function HighlightPreview(props: HighlightPreviewProps): JSX.Element {
  const {
    imageSource,
    alt,
    focalPoint,
    companyLogo,
    companyName,
    title,
    isFontLoading,
    coverLayout = ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT,
    isCoverTitleHidden,
    isCoverLogoHidden,
  } = props;

  const formatedTitle = StringUtils.toTitleCase(title);
  const [$ref, size] = useElementSize();

  return (
    <div className={ClassNames(styles['highlight-preview'])} ref={$ref}>
      <Col size={11}>
        <HighlightPreviewDesktop
          coverLayout={coverLayout}
          imageSource={imageSource}
          alt={alt}
          companyLogo={companyLogo}
          companyName={companyName}
          isFontLoading={isFontLoading}
          title={formatedTitle}
          imageWidth={size.width}
          focalPoint={focalPoint}
          isCoverTitleHidden={isCoverTitleHidden}
          isCoverLogoHidden={isCoverLogoHidden}
        />
      </Col>
      <HighlightPreviewIphone
        coverLayout={coverLayout}
        imageSource={imageSource}
        alt={alt}
        companyLogo={companyLogo}
        companyName={companyName}
        isFontLoading={isFontLoading}
        title={formatedTitle}
        focalPoint={focalPoint}
        imageWidth={size.width}
        isCoverTitleHidden={isCoverTitleHidden}
        isCoverLogoHidden={isCoverLogoHidden}
      />
    </div>
  );
}
