import { HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';

export interface ReportProjectType {
  project_id: string;
  id: string;
  project_name: string;
  stats: {
    views: number;
    shares: number;
    referrals: number;
    downloads: number;
    leads: number;
  };
}

export function fetchProjectsInsight(): Promise<Array<ReportProjectType>> {
  return HttpUtils.get(APIURLService.API_URLS.REPORT.PROJECT_INSIGHT).then((response) => response.data);
}
