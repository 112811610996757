import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { clientWebsiteItinerarySelector } from './ClientWebsiteItineraryDataDuck';
import { setEditOfItinerary } from '../ClientWebsitePageDuck';
import ClientWebsiteItineraryItem from './ClientWebsiteItineraryItem';

function mapStateToProps(
  state,
  ownProps: {
    id: number;
    websiteId: string;
    projectId: string;
  },
) {
  const { id, websiteId, projectId } = ownProps;
  return {
    id,
    websiteId,
    projectId,
    itinerary: clientWebsiteItinerarySelector(state)[id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editItinerary: (id) => {
      dispatch(setEditOfItinerary(dispatch, id));
      dispatch(
        toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_EDIT_ITINERARY_PANEL, true),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteItineraryItem);
