import { connect } from 'react-redux';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import SponsorReportTable from './SponsorReportTable';
import { tableStatsOnSponsorsSelector } from '../../../../projects/project-stats/ProjectsStatsDataDuck';
import { projectsSelector } from '../../../../projects/AccountProjectsDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId } = ownProps;
  return {
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state) &&
      isLoadingSelector(LOADINGS.PROJECT_STATS.SPONSOR_FETCH)(state),
    data: tableStatsOnSponsorsSelector(projectId)(state),
    project: projectsSelector(state)[projectId] || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorReportTable);
