import { FONT_IDS } from './FontService';

/*
 * Custom fonts we can add
 * Voga & London
 * Lovelyn
 * Glamor
 * Wallington
 * Medio
 *
 * */

export enum FONT_PALETTES {
  PLAYFAIR,
  HANDO,
  ARIMA_MADURAI_AND_MULISH,
  OSWALD_AND_SOURCE_SERIF_PRO,
  ZILLA_SLAB_AND_INTER,
  EB_GARAMOND_AND_SPLINE_SANS,
  ABOUT_NUNITO_AND_NUNITO_SANS,
  FUGAZ_ONE_AND_WORK_SANS,
  ABRIL_FATFACE_AND_POPPINS,
  SPACE_MONO_AND_BITTER,
  GRAND_HOTEL_AND_LATO,
  BASICALLY_A_SANS_SERIF,
}
export const FONT_PALETTES_DETAILS: Record<
  FONT_PALETTES,
  {
    name: string;
    primary: FONT_IDS;
    secondary: FONT_IDS;
  }
> = {
  [FONT_PALETTES.PLAYFAIR]: {
    name: 'Playfair & Source Sans Pro',
    primary: FONT_IDS.PLAYFAIR_DISPLAY,
    secondary: FONT_IDS.SOURCE_SANS_PRO,
  },
  [FONT_PALETTES.HANDO]: {
    name: 'Hando & Open Sans',
    primary: FONT_IDS.HANDO,
    secondary: FONT_IDS.OPEN_SANS,
  },
  [FONT_PALETTES.ARIMA_MADURAI_AND_MULISH]: {
    name: 'Arima Madurai & Mulish',
    primary: FONT_IDS.ARIMA_MADURAI,
    secondary: FONT_IDS.MULISH,
  },
  [FONT_PALETTES.OSWALD_AND_SOURCE_SERIF_PRO]: {
    name: 'Oswald & Source Serif Pro',
    primary: FONT_IDS.OSWALD,
    secondary: FONT_IDS.SOURCE_SERIF_PRO,
  },
  [FONT_PALETTES.ZILLA_SLAB_AND_INTER]: {
    name: 'Zilla Slab & Inter',
    primary: FONT_IDS.ZILLA_SLAB,
    secondary: FONT_IDS.INTER,
  },
  [FONT_PALETTES.EB_GARAMOND_AND_SPLINE_SANS]: {
    name: 'EB Garamond & Spline Sans',
    primary: FONT_IDS.EB_GARAMOND,
    secondary: FONT_IDS.SPLINE_SANS,
  },
  [FONT_PALETTES.ABOUT_NUNITO_AND_NUNITO_SANS]: {
    name: 'About Nunito & Nunito Sans',
    primary: FONT_IDS.ABOUT_NUNITO,
    secondary: FONT_IDS.NUNITO_SANS,
  },
  [FONT_PALETTES.FUGAZ_ONE_AND_WORK_SANS]: {
    name: 'Fugaz One & Work Sans',
    primary: FONT_IDS.FUGAZ_ONE,
    secondary: FONT_IDS.WORK_SANS,
  },
  [FONT_PALETTES.ABRIL_FATFACE_AND_POPPINS]: {
    name: 'Abril Fatface & Poppins',
    primary: FONT_IDS.ABRIL_FATFACE,
    secondary: FONT_IDS.POPPINS,
  },
  [FONT_PALETTES.SPACE_MONO_AND_BITTER]: {
    name: 'Space Mono & Bitter',
    primary: FONT_IDS.SPACE_MONO,
    secondary: FONT_IDS.BITTER,
  },
  [FONT_PALETTES.GRAND_HOTEL_AND_LATO]: {
    name: 'Grand Hotel & Lato',
    primary: FONT_IDS.GRAND_HOTEL,
    secondary: FONT_IDS.LATO,
  },
  [FONT_PALETTES.BASICALLY_A_SANS_SERIF]: {
    name: 'Basically a Sans Serif',
    primary: FONT_IDS.BASICALLY_A_SANS_SERIF,
    secondary: FONT_IDS.BASICALLY_A_SANS_SERIF,
  },
};

export function getFontPaletteIdFromValues(value: { primary: FONT_IDS; secondary: FONT_IDS }): FONT_PALETTES {
  const fontDetails = Object.entries(FONT_PALETTES_DETAILS).find(
    ([id, font]) => font.primary == value.primary && font.secondary == value.secondary,
  );
  if (fontDetails) return Number(fontDetails[0]) as FONT_PALETTES;

  return FONT_PALETTES.PLAYFAIR;
}
