import { StringUtils } from '@premagic/utils';
import { PRICING_PLAN_FEATURES } from './PricingPlanFeaturesService';

// https://razorpay.com/docs/payment-gateway/web-integration/standard/
declare const Razorpay;

export type PricingPlanVariant = {
  active: boolean;
  amount: number;
  debug_razorpay_plan_id: string;
  razorpay_plan_id: string;
};

export enum PRICING_PLAN_VARIANT_FREQUENCY {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum PRICING_PLAN_VARIANT_LOCAL {
  IN = 'IN',
  US = 'US',
}

export type PricingPlanVariantId = `${PRICING_PLAN_VARIANT_FREQUENCY}_${PRICING_PLAN_VARIANT_LOCAL}`;
export type PricingPlanType = {
  id: string;
  name: string;
  internal_name: string;
  is_subscription: boolean;
  active: boolean;
  feature_list: Array<PRICING_PLAN_FEATURES>;
  highlights: number;
  photos_per_highlight: number;
  videos_per_highlight?: number;
  ai_photos_per_highlight?: number;
  price_per_project: number;
  projects: number;
  display_photos_per_highlight: string;
  project_validity: number;
  variants: Record<PricingPlanVariantId, PricingPlanVariant>;
  portfolio_project_limit: number;
};

export function getPricingPlanTitle(plan: PricingPlanType): string {
  const planName = plan.name.replace(/_/g, ' ');
  // if (!plan.active) return `${StringUtils.toTitleCase(planName)} (Old plan)`;
  return StringUtils.toTitleCase(planName, true);
}
