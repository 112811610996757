import { connect } from 'react-redux';

import EventFinancialLayout from './EventFinancialLayout';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { fetchPaymentsForEventData } from '../../payments/CRMPaymentDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;

  return {
    eventId,
    isLoading: isLoadingSelector(LOADINGS.CRM_PAYMENTS.FETCH)(state),
    hasPermissions: {
      [PERMISSIONS.EVENT_FINANCIAL_QUOTE]: hasPermission(PERMISSIONS.EVENT_FINANCIAL_QUOTE)(state),
      [PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS]: hasPermission(PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS)(state),
      [PERMISSIONS.EVENT_FINANCIAL_EXPENSE]: hasPermission(PERMISSIONS.EVENT_FINANCIAL_EXPENSE)(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (eventId) => {
      // dispatch(fetchOccasionsForEventData(dispatch, eventId));
      dispatch(fetchPaymentsForEventData(dispatch, eventId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFinancialLayout);
