import { connect } from 'react-redux';
import { UploaderDuck } from '@premagic/common-ducks';
import { ProposalDeckDataDuck, ProposalSlideDataDuck, ProposalVariablesDataDuck } from '@premagic/proposals';

import ProposalCreatorSlide from './ProposalCreatorSlide';
import { activeProposalSlideSelector } from '../ProposalCreatorDuck';
import { folderMetaDataForFolderSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { filesSelector } from '../../../images/FilesDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    deckId: string;
  },
) {
  const { folderId, deckId } = ownProps;
  return {
    folderMeta: folderMetaDataForFolderSelector(folderId)(state),
    slideDetails: activeProposalSlideSelector(state),
    files: filesSelector(state),
    uploadingFiles: UploaderDuck.fileUploaderFilesSelector(state),
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    slides: ProposalSlideDataDuck.proposalsSlidesArraySelectors(state),
    proposalVariableDetails: ProposalVariablesDataDuck.proposalsVariableDetailsSelectors(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorSlide);
