import { connect } from 'react-redux';

import { notificationSettingSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import FolderShareChannelInfo from './FolderShareChannelInfo';

function mapStateToProps(state) {
  return {
    notifications: notificationSettingSelector(state),
  };
}

export default connect(mapStateToProps)(FolderShareChannelInfo);
