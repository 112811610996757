import { PERMISSIONS } from '../src/app/components/crm/users/UserPermissionService';

export const ERRORS = {
  INVALID_MPIN: 'Incorrect! Looks like you made a mistake, try again?',
};

const MESSAGES = {
  WELCOME: 'welcome',
  DELETE_WARNING: 'You can’t undo this action.',
  FEATURE_NOT_AVAILABLE: 'This feature is not available on your plan.',
  PROJECT: {
    ALREADY_ARCHIVED: 'Project is already archived',
    UPGRADE: 'You have used up all the projects, Please upgrade your plan',
  },
  SHARE: {
    ALREADY_SHARED: 'You have already shared with the host',
  },
  FOLDER: {},
  SIGNATURE_FOLDER: {
    NAME: 'Signature Album',
    NAME_FOR_EVENT_ORGANIZER: 'Gallery',
    INFO: 'Share signature photos with your host',
    INFO_FOR_EVENT_ORGANIZER: '',
    UPGRADE: 'Signature albums are not available on your plan, Select a new plan to get Signature albums',
    GATE_FOLDER:
      'You have used up all the signature albums available in this project, upgrade to get more signature albums per project',
    ZERO_STATE: 'You dont have any signature album yet',
    ZERO_STATE_FOR_EVENT_ORGANIZER: 'You havnt created any folders yet',
    FOLDER_NOT_SHARED: 'Signature album is not shared with host, Share the folder to open the link here',
    AI_PHOTOS_TOO_LESS: 'Too less AI photos for AI delivery',
    SIGNATURE_ALBUM_FOLDER_SHOW_IN_GUEST_GALLERY_UPGRADE: 'Not available on your plan',
  },
  SELECTION_FOLDER: {
    NAME: 'Photo Selection',
    INFO: 'Photo Selection folder lets hosts select photos easily from any device',
    UPGRADE: 'Selection albums are not available on your plan, Select a new plan to get Selection albums',
    ZERO_STATE: 'You dont have any selection album yet',
    FOLDER_NOT_SHARED: 'Photo selection folders are not shared with host, Share the folder to open the link here',
    COPY_FROM_SIGNATURE_ALBUM: 'This will copy all the folders and photos from Signature album',
    COPY_FROM_SIGNATURE_ALBUM_ZERO_STATE: 'Oh no!, looks like there no signature folders to copy from',
    COPY_FROM_SIGNATURE_ALBUM_UPGRADE: 'Not available on your plan',
  },
  ALBUM_CREATOR: {
    NAME: 'Draft Album creator',
    INFO: 'Make an draft of the album layout with your host',
    ZERO_STATE: 'You dont have any albums yet',
  },
  CLIENT_WEBSITE: {
    NAME: 'Micro-site',
    INFO: 'A modern way for your host to invite people',
    ZERO_STATE: 'You dont have any Micro-site yet ',
    UPGRADE_TO_CUSTOMIZE: 'Upgrade the micro-site plan',
    UPGRADE_TO_CUSTOMIZE_BELOW: 'To change all below options, Upgrade the micro-site plan or reach out to ',
    UPGRADE_TO_SHOW_WISHES: 'To show all the greetings, Upgrade the micro-site plan or reach out to ',
    LEARN_ABOUT_PLANS: 'Learn more about the micro-site packages',
    NOT_AVAILABLE: 'Not available on your plan',
    SHARE_TO_OPEN: 'Share the micro-site to open the link',
  },
  SCHEDULE: {
    SCHEDULE: 'Schedule',
    SESSION: 'Session',
  },
  UPLOAD: {
    LOSE: 'Your files are getting uploaded!, If you leave close this window then uploading will get cancelled',
  },
  METRICS: {
    ENGAGEMENT:
      'People who have interacted with your brand. This would include visiting your company website, visiting your portfolio, visiting your social media profiles, Reaching out on your phone number',
  },
  USER: {
    ACTIVATE_BLOCK: 'You cannot activate/de-activate yourself',
    DELETE_BLOCK: 'You cannot delete yourself',
    USER_PROXY_MESSAGE: 'NOTE: You have logged in as customer 🙈',
  },
  PERMISSION: {
    [PERMISSIONS.EVENT_FINANCIAL_MANAGE]: 'You dont have permission to mange payments',
    [PERMISSIONS.EVENT_OCCASION_MANAGE]: 'You dont have permission to mange occasions',
    [PERMISSIONS.EVENT_MANAGEMENT]: 'You dont have permission to manage events',
    [PERMISSIONS.EVENT_CLIENT_MANAGEMENT]: 'You dont have permission to manage hosts',
    [PERMISSIONS.EVENT_SPONSOR_MANAGEMENT]: 'You dont have permission to manage sponsors',
    [PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]: 'You dont have permission to manage attendees',
    [PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT]: 'You dont have permission to manage sponsor creatives',
    [PERMISSIONS.EVENT_SPONSOR_REPORT]: 'You dont have permission to view sponsor insights',
    [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: 'You dont have permission to manage form fields',
    [PERMISSIONS.EVENT_DETAILS]: 'You dont have permission to view the event details',
    [PERMISSIONS.EVENT_MARKETING_MANAGEMENT]: 'You dont have permission to manage event marketing',
    [PERMISSIONS.EVENT_BADGES]: 'You dont have permission to manage event badges',
    [PERMISSIONS.EVENT_SETTINGS]: 'You dont have permission to manage event settings',
    [PERMISSIONS.EVENT_AVATAR_STUDIO_MANAGEMENT]: 'You dont have permission to manage Avatar Studio',
    [PERMISSIONS.USER_MANAGEMENT]: 'You dont have permission to manage users',
  },
  NOTIFICATIONS: {
    NOT_TOGGLEABLE: 'This notification cannot be disabled/enabled',
  },
  SUBSCRIPTIONS: {
    INACTIVE: 'Your account is on Hold! Please update your payment details to continue using Premagic',
  },
  PROPOSAL_VARIABLES: {
    SYSTEM_VARIABLES: 'System Variables cannot be modified',
  },
  PROPOSAL: {
    UPGRADE: 'Proposals are not available on your plan.',
  },
  WATERMARK: {
    UPGRADE: 'Watermark feature is not available on your plan.',
  },
  EVENT_SPONSORSHIP: {
    UPGRADE: 'Event Sponsorship are not available on your plan, Upgrade your plan to get Event Sponsorship',
    ENABLE: 'Enable Event Sponsorship',
  },
  EVENT_ATTENDEES: {
    NAME: 'People',
    UPGRADE: 'Event People are not available on your plan, Upgrade your plan',
    ADD_PEOPLE_UPGRADE: 'Adding people feature is not available on your plan, Upgrade your plan',
    DOWNLOAD_PEOPLE_UPGRADE: 'Downloading attendee list feature is not available on your plan, Upgrade your plan',
  },
  EVENT_EXHIBITOR: {
    NAME: 'Exhibitor',
    UPGRADE: 'Event Exhibitor is not available on your plan, Upgrade your plan',
    ADD_EXHIBITOR_UPGRADE: 'Adding exhibitor feature is not available on your plan, Upgrade your plan',
    ENABLE: 'Enable Event Exhibitor',
  },
  EVENT_CUSTOM_FORM_FIELDS: {
    UPGRADE: 'Registration form fields are not available on your plan, Upgrade your plan',
  },
  EVENT_ATTENDEES_BADGES: {
    UPGRADE: 'Event Attendee Badges are not available on your plan, Upgrade your plan',
  },
  EVENT_FEEDBACK: {
    DISABLED: 'Event Feedback is disabled, You can enable it from the event settings',
    UPGRADE: 'Event Feedback is not available on your plan, Upgrade your plan',
  },
  EXTERNAL_UPLOADER: {
    UPGRADE: 'External Uploader is not available on your plan, Upgrade your plan',
    COMING_SOON: 'External Uploader feature is coming soon',
  },
  EVENT_MARKETING: {
    UPGRADE: 'Event Marketing is not available on your plan, Upgrade your plan',
  },
  EVENT_POSTERS: {
    UPGRADE: 'Event Posters are not available on your plan, Upgrade your plan',
  },
  AVATAR_STUDIO: {
    UPGRADE: 'Avatar Studio is not available on your plan, Upgrade your plan',
  },
};

export default MESSAGES;
