import { ModalDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { EventPosterService } from '@premagic/core';
import { LOADINGS } from '../../../../../common/Constants';
import {
  eventPostersSelector,
  eventPosterWidgetsArraySelector,
  updateEventPosterWidget,
} from '../../crm/event-posters/EventPostersDataDuck';
import AddEventPosterWidgetButton from './AddEventPosterWidgetButton';

const FILE_PICKER_MODAL_KEY = LOADINGS.CLIENT_WEBSITE.SHOW_I_AM_ATTENDING_POSTER_SELECT_MODAL;

function mapStateToProps(
  state,
  ownProps: { projectId: string; posterId: string; type: EventPosterService.EVENT_POSTER_TYPE },
) {
  const { projectId, posterId, type } = ownProps;
  const poster = eventPostersSelector(state)[posterId];
  return {
    projectId,
    poster,
    type,
    widgets: eventPosterWidgetsArraySelector(posterId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addWidget: ({ projectId, poster, widgetToUpdate }) => {
      dispatch(
        updateEventPosterWidget(dispatch, projectId, {
          widgetToUpdate,
          poster,
        }),
      );
    },
    showSelectPosterModal: (widgetId) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, FILE_PICKER_MODAL_KEY, true));
      dispatch(
        ModalDuck.setModalOptions(dispatch, FILE_PICKER_MODAL_KEY, {
          widgetId,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEventPosterWidgetButton);
