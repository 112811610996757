import React, { useRef, useState } from 'react';
import {
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Dialog,
  DIALOG_PLACEMENT,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Select,
  Space,
  FormLabel,
  COLOR_SHADES,
  Color,
  ICON_ACCENT_STYLES,
  BUTTON_ICON_STYLES,
} from '@premagic/myne';
import { EventsService } from '@premagic/core';
import MESSAGES from '../../../../../../../common/Messages';
import { PERMISSIONS } from '../../../users/UserPermissionService';

const priorityOptions = Object.values(EventsService.EVENT_PRIORITY_TYPE_DETAILS).map((type) => ({
  value: type.id,
  label: type.title,
  icon: type.icon,
}));

type Props = {
  eventId: string;
  clientId: string;
  isSaving: boolean;
  priority: EventsService.EVENT_PRIORITY;
  savePriority: (eventId: string, clientId: string, priority: EventsService.EVENT_PRIORITY) => void;
  hasEventManagementPermission: boolean;
};

export default function EventPrioritySelector(props: Props): React.ReactElement {
  const { eventId, clientId, isSaving, savePriority, priority, hasEventManagementPermission } = props;
  const selectedOption = priorityOptions.find((option) => option.value === priority) || priorityOptions[2];
  const target = useRef(null);
  const [isDialogeOpen, setIsDialogeOpen] = useState(false);

  return (
    <div ref={target}>
      {isSaving ? (
        <>
          <Space vertical size={2} />
          <LoadingDots size="sm" />
          <Space vertical size={3} />
        </>
      ) : (
        <ButtonIcon
          onClick={() => setIsDialogeOpen(!isDialogeOpen)}
          title={`Priority: ${selectedOption.label}`}
          size={BUTTON_ICONS_SIZES.SM}
          active={isDialogeOpen}
          disabled={!hasEventManagementPermission}
          disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_MANAGEMENT]}
          style={BUTTON_ICON_STYLES.SECONDARY}
        >
          <Icon name={selectedOption.icon} size={ICON_SIZES.LG} accentStyle={ICON_ACCENT_STYLES.NONE} />
        </ButtonIcon>
      )}
      {isDialogeOpen && (
        <Dialog
          target={target.current}
          show={isDialogeOpen}
          placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
          onClose={() => setIsDialogeOpen(false)}
        >
          <Space size={2}>
            <FormLabel>Event Priority</FormLabel>
            <Select
              disabled={isSaving}
              options={priorityOptions}
              value={selectedOption}
              name="event_priority"
              onChange={(e) => {
                savePriority(eventId, clientId, e?.value as EventsService.EVENT_PRIORITY);
                setIsDialogeOpen(false);
              }}
              isMulti={false}
              autoFocus
              isSearchable={false}
            />
          </Space>
        </Dialog>
      )}
    </div>
  );
}
