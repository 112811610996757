import { connect } from 'react-redux';
import { crmEventItemsSelector, removeEvent } from '../../EventsDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import EventDeleteDialog from './EventDeleteDialog';
import { eventProjectSelectorSelector } from '../../../../projects/AccountProjectsDataDuck';

const DIALOG_KEY = LOADINGS.EVENT.SHOW_DELETE_DIALOG;

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;
  const project = eventProjectSelectorSelector(eventId)(state);
  return {
    eventId,
    projectId: project?.project_id,
    isLoading: isLoadingSelector(LOADINGS.EVENT.DELETE)(state),
    isDialogShown: isModalOpenSelector(DIALOG_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
    removeEvent: (options: { projectId?: string; eventId: string }) => {
      dispatch(removeEvent(dispatch, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDeleteDialog);
