import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils, BrowserUrlUtils } from '@premagic/utils';
import { ClientSettingsService, CompanyService, RouterService } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';

import { LOADINGS } from '../../../../common/Constants';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import APP_URLS from '../../services/AppRouteURLService';
import { setClientSettingsData } from '../settings/preferences/ClientSettingsDataDuck';
import { saveCompanyInfoService } from '../settings/company/CompanyService';
import { setCompanyData } from '../settings/company/CompanyDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('WELCOME', true);

export const saveWelcomeCompanyData = createAction(
  getActionType('DATA', 'SAVE'),
  async (
    dispatch,
    formResponse: FormResponseType & {
      data: {
        domain: string;
        company_type: ClientSettingsService.CLIENT_COMPANY_TYPES;
      };
    },
    company: CompanyService.CompanyType,
  ) => {
    const loadingKey = LOADINGS.WELCOME.SAVE;

    if (formResponse.errors) {
      dispatch(setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    try {
      const newSelectedType = formResponse.data.company_type;
      const domain = formResponse.data.domain as string;
      // 1. Save client settings
      const clientSetting = await ClientSettingsService.saveClientSettingsService(
        CompanyService.getClientSettingsToSaveForCompanyType(newSelectedType),
      );
      // 2. Save company info
      if (domain && company.domain !== domain) {
        const companyData = await saveCompanyInfoService({ ...company, domain });
        dispatch(setCompanyData(dispatch, companyData));
      }

      dispatch(setClientSettingsData(dispatch, clientSetting));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));

      const createNewEventUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.CREATE__CLIENT, {});
      RouterService.navigateTo(dispatch, createNewEventUrl);
    } catch (error) {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setErrorState(dispatch, loadingKey, error));
    }
  },
);

const initialState = {};

export default handleActions({}, initialState);
