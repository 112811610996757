import { connect } from 'react-redux';
import { isMobileUp } from '../../AppDuck';
import {
  qrCodeDesignsBackgroundPosterDataSelector,
  qrCodeDesignsLogoSelector,
  qrCodeDesignsSelector,
  qrCodeDesignsShowPoweredByPremagicSelector,
} from '../../projects/folder-meta/FoldersMetaDataDuck';
import LEDScreenQRCode from './LEDScreenQRCode';

function mapStateToProps(
  state,
  ownProps: {
    url: string;
    printClass: string;
    fileNameClass: string;
    folderId: string;
    projectName?: string;
  },
) {
  const { url, printClass, fileNameClass, folderId, projectName } = ownProps;

  return {
    url,
    printClass,
    fileNameClass,
    backgroundCover: qrCodeDesignsBackgroundPosterDataSelector(folderId)(state),
    folderId,
    logos: qrCodeDesignsLogoSelector(folderId)(state),
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
    projectName,
    showPoweredByPremagic: qrCodeDesignsShowPoweredByPremagicSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LEDScreenQRCode);
