import { connect } from 'react-redux';
import { EventAttendeesService } from '@premagic/core';

import UsersWithoutPhotoAlertModal from './UsersWithoutPhotoAlertModal';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../../common/ModalDuck';
import { LOADINGS, MODALS } from '../../../../../../../../common/Constants';
import { eventAttendeeWithoutPhotoSelector } from '../../EventAttendeesDataDuck';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { eventPostersSelector } from '../../../../event-posters/EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    posterId: string;
  },
) {
  const { posterId } = ownProps;
  const poster = posterId ? eventPostersSelector(state)[posterId] : undefined;
  const { scope = [EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE] } = poster || {};

  return {
    show: isModalOpenSelector(LOADINGS.EVENT_ATTENDEES.ATTENDEES_WITHOUT_PHOTO_ALERT_MODAL)(state),
    isLoading: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state),
    eventAttendeesWithoutPhoto: eventAttendeeWithoutPhotoSelector(scope)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.ATTENDEES_WITHOUT_PHOTO_ALERT_MODAL, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersWithoutPhotoAlertModal);
