import { useEffect } from 'react';
import {
  COLOR_SHADES,
  Text,
  TEXT_SIZE,
  FadeIn,
  Color,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  LoadingDots,
} from '@premagic/myne';
import { FileService, FolderService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';

type Props = {
  filesUploadStats: Record<FileService.FILE_UPLOAD_STAGES, number>;
  folderId: string;
  projectId: string;
  folderType: FolderService.FOLDER_TYPES;
  fetchFolder: (projectId, folderId, folderType: FolderService.FOLDER_TYPES) => void;
};

export default function FolderFilesUploadStatus(props: Props): JSX.Element | null {
  const { folderId, filesUploadStats, projectId, fetchFolder, folderType } = props;
  useEffect(() => {
    let timer;

    if (filesUploadStats.QUEUED + filesUploadStats.PROCESSING > 0) {
      fetchFolder(projectId, folderId, folderType);
      timer = setInterval(() => fetchFolder(projectId, folderId, folderType), 60 * 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [filesUploadStats.QUEUED, filesUploadStats.PROCESSING]);

  const showInfo = filesUploadStats.QUEUED + filesUploadStats.PROCESSING + filesUploadStats.ERROR > 0;
  if (!showInfo) return null;

  return (
    <FadeIn>
      <Row vcenter>
        <Color shade={COLOR_SHADES.LIGHT}>
          <Icon name="info" size={ICON_SIZES.SM} />
        </Color>
        <Space size={2} />
        <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
          {filesUploadStats.QUEUED > 0 &&
            `${filesUploadStats.QUEUED} ${StringUtils.pluralize(
              'file',
              filesUploadStats.QUEUED,
            )} are waiting to get processed.`}
        </Text>
        <Space vertical size={1} />
        {filesUploadStats.PROCESSING > 0 && (
          <>
            <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
              {filesUploadStats.PROCESSING} {StringUtils.pluralize('file', filesUploadStats.PROCESSING)} are being
              processed.
              <Space vertical size={1} />
              This will take{' '}
              <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DARKER}>
                2 - 5 min
              </Text>{' '}
              to complete.
            </Text>
            <Space />
            <LoadingDots size="sm" />
          </>
        )}
      </Row>
      {!!filesUploadStats.ERROR && (
        <Row vcenter>
          <Color shade={COLOR_SHADES.LIGHT}>
            <Icon name="alert_triangle" size={ICON_SIZES.SM} />
          </Color>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DANGER}>
            {filesUploadStats.ERROR} {StringUtils.pluralize('file', filesUploadStats.ERROR)} have errors. Please Try
            re-uploading the files
          </Text>
        </Row>
      )}
    </FadeIn>
  );
}
