import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
} from '@premagic/myne';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../common/JSClassSelectors';

type Props = {
  projectId: string;
  folderId?: string;
  deckId: string;
  toggleDialog: (show: boolean) => void;
  isDialogShown: boolean;
  createNewVersion: (
    projectId: string,
    deckId: string,
    options: {
      folderId: string;
    },
  ) => void;
  isEditing: boolean;
};

export default function ProposalEditConfirmationDialog(props: Props): JSX.Element | null {
  const { folderId, projectId, toggleDialog, isDialogShown, createNewVersion, deckId, isEditing } = props;

  if (!folderId || !isDialogShown) return null;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.PROPOSAL_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleDialog(false)}
    >
      <Text block color={COLOR_SHADES.DARKER}>
        The Proposal is confirmed by the client.
      </Text>
      <Space vertical />
      <Text>Are you sure you want to edit it?</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.LINK} onClick={() => toggleDialog(false)} type="button">
          Cancel
        </Button>
        <Button
          isLoading={isEditing}
          style={BUTTON_STYLES.PRIMARY}
          onClick={() =>
            createNewVersion(projectId, deckId, {
              folderId,
            })
          }
        >
          Edit Proposal
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
