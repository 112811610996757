import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import HighlightFoldersList from './HighlightFoldersList';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';
import { saveHighlightFolderPosition } from '../../project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; activeFolderId?: string }) {
  const { projectId, activeFolderId } = ownProps;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.HIGHLIGHT,
  )(state);

  return {
    folderIds: signatureFolderIds,
    projectId,
    activeFolderId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrder: (projectId, folderIds, data) => {
      dispatch(saveHighlightFolderPosition(dispatch, projectId, folderIds, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightFoldersList);
