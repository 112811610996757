import { EventsService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { navigateBack } from '../../../../../services/RouterService';
import { hasExceedProjectLimitSelector } from '../../settings/company/CompanyDataDuck';
import {
  accountDefaultLanguageSelector,
  companyTypeSelector,
  integrationsEventClientSettingsSelector,
  isCompanyTypeNotPhotographerSelector,
  isFeatureLeadEnabledSelector,
} from '../../settings/preferences/ClientSettingsDataDuck';
import { addEventWithClientData } from '../events/details/CreateEventPageDuck';
import { requestingUserSelector } from '../users/UsersDataDuck';
import CreateEventWithClientPage from './CreateEventWithClientPage';

function mapStateToProps(state, ownProps) {
  const { filters } = ownProps.match.params;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as {
    event_status: EventsService.EVENT_STATUS;
  };
  return {
    isLoading: isLoadingSelector(LOADINGS.EVENT.ADD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.EVENT.ADD)(state),
    companyType: companyTypeSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    requestingUser: requestingUserSelector(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    hasExceedProjectLimit: hasExceedProjectLimitSelector(state),
    filterObject,
    integrationsClientSettings: integrationsEventClientSettingsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addEventWithClientData: (data, isCompanyTypeNotPhotographer) => {
      dispatch(addEventWithClientData(dispatch, data, { isCompanyTypeNotPhotographer }));
    },
    browserBack: (defaultUrl: string) => {
      navigateBack(dispatch, defaultUrl);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventWithClientPage);
