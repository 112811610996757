// https://axios-http.com/docs/req_config

import axios, { AxiosInstance } from 'axios';
import { get as lodashGet, isEmpty } from 'lodash';
import qs from 'qs';
import { logError, logMessage, showErrorToUser } from './ErrorTracker';
import { getAccountAuthHeader, getClientAuthHeader, signOut } from './AuthUtils';

enum HTTP_STATUS_CODES {
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  SERVER_ERROR = 500,
  SERVER_TIMEOUT = 504,
}

function getHeaderConfig(): Record<string, string> {
  return {
    ...getClientAuthHeader(),
    ...getAccountAuthHeader(),
  } as Record<string, string>;
}

function getConfig(headers?: Record<string, any>) {
  if (headers)
    return {
      headers,
    };

  return {
    headers: getHeaderConfig(),
  };
}

const axiosInstance = axios.create({
  headers: getHeaderConfig(),
});

export function getHttpInstance(): AxiosInstance {
  return axiosInstance;
}

function handleError(
  error,
  ignoreError: boolean,
  ignoreErrorOnAuths: boolean,
): Promise<{
  data: unknown;
  message?: string;
}> {
  const { response = {} } = error;
  if (!ignoreErrorOnAuths && [HTTP_STATUS_CODES.FORBIDDEN, HTTP_STATUS_CODES.UNAUTHORIZED].includes(response.status)) {
    logMessage('Unauthorized, Please login again');
    showErrorToUser('Session time-out! Please login again and try again');
    signOut();
    return Promise.reject(lodashGet(response, 'message', error));
  }

  if (!ignoreError) {
    if (response.status === HTTP_STATUS_CODES.SERVER_ERROR) {
      showErrorToUser(
        "Apologies, but there's an issue; try again in a bit. \nWe track errors automatically, but if the problem persists feel free to contact us. In the meantime, try again.",
      );
      logError('HTTP_UTILS', response);
      return Promise.reject(lodashGet(response, 'message', error));
    }

    if (response.status === HTTP_STATUS_CODES.SERVER_TIMEOUT) {
      showErrorToUser(
        'The server is feeling a bit slow today. Please be patient. \n\nWe track errors automatically, but if the problem persists feel free to contact us. In the meantime, try again.',
      );
      logError('HTTP_UTILS', response);
      return Promise.reject(lodashGet(response, 'message', error));
    }

    const message =
      lodashGet(response, 'data.error.message') || lodashGet(response, 'message') || 'Refresh and try again';
    logError('HTTP_UTILS', response);
    showErrorToUser(
      `Oops! We encountered a problem \n\n${message} \n\n\nIf it happen again, please write to support@premagic.com`,
    );
  }

  return Promise.reject(error.response || error);
}

// Overriding axios's serializer
// When an array is sent as a parameter, axios serializes it with square brackets
// user = [1,2, 3] is serialized as user[]=1&user[]=2&user[]=3
// This is broken in our API. We're overriding this behaviour till we fix our API's to support both kinds of serialization.
// Only for GET requests
function paramsSerializer(params: Record<string, unknown>) {
  return qs.stringify(params, {
    indices: false,
    arrayFormat: 'repeat',
  });
}

export function get(
  url: string,
  options?:
    | {
        params: Record<string, any>;
      }
    // eslint-disable-next-line @typescript-eslint/ban-types
    | {},
  ignoreError = true,
  ignoreErrorOnAuths = false,
  headers?: Record<string, any>,
) {
  if (!isEmpty(headers)) {
    // Otherwise the authorization header wont get updated
    return axios
      .get(url, { ...options, ...getConfig(headers), paramsSerializer })
      .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
  }
  return axiosInstance
    .get(url, { ...options, ...getConfig(), paramsSerializer })
    .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
}

export function post(
  url: string,
  params: Record<string, any>,
  ignoreError = false,
  ignoreErrorOnAuths = false,
  headers?: Record<string, any>,
) {
  if (headers) {
    // Otherwise the authorization header wont get updated
    return axios
      .post(url, params, getConfig(headers))
      .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
  }
  return axiosInstance
    .post(url, params, getConfig(headers))
    .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
}

export function put(url: string, params?: Record<string, any>, ignoreError = false, ignoreErrorOnAuths = false) {
  return axiosInstance
    .put(url, params, getConfig())
    .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
}

export function patch(url: string, params: Record<string, any>, ignoreError = false, ignoreErrorOnAuths = false) {
  return axiosInstance
    .patch(url, params, getConfig())
    .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
}

export function httpDelete(
  url: string,
  options?: { data?: Record<string, any> },
  ignoreError = false,
  ignoreErrorOnAuths = false,
) {
  return axiosInstance
    .delete(url, { ...options, ...getConfig() })
    .catch((error) => handleError(error, ignoreError, ignoreErrorOnAuths));
}
