import React from 'react';
import { Card, CARD_STYLES, CardContent, CardHeader, Space, SubTitle } from '@premagic/myne';

import CompanyStatsInfoContainer from './CompanyStatsInfoContainer';

export default function CompanyStatsCard({ isStaffUser }: { isStaffUser: boolean }): JSX.Element | null {
  return (
    <Card accordion defaultExpanded style={CARD_STYLES.SECONDARY}>
      <Space>
        <CardHeader>
          <SubTitle>Stats</SubTitle>
        </CardHeader>
        <CardContent>
          <CompanyStatsInfoContainer />
        </CardContent>
      </Space>
    </Card>
  );
}
