import { ProjectMetaService } from '@premagic/core';
import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Form,
  FormErrorType,
  FormFooter,
  FormResponseType,
  Space,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import HighlightDesignEndingTextEditorContainer from './ending-text/HighlightDesignEndingTextEditorContainer';
import HighlightDesignWelcomeMessageEditorContainer from './welcome-message/HighlightDesignWelcomeMessageEditorContainer';

type Props = {
  close: () => void;
  show: boolean;
  updateProjectMeta: (projectId: string, formResponse: FormResponseType) => void;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
  galleryWelcomeText?: string;
  galleryEndingText?: string;
};

export default function HighlightDesignTextEditorWindowPanel(props: Props) {
  const { show, close, projectId, isLoading, error, updateProjectMeta, galleryWelcomeText, galleryEndingText } = props;

  function handleSubmit(response) {
    updateProjectMeta(projectId, response);
    close();
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Highlight Text</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={error} onSubmit={handleSubmit}>
          <HighlightDesignWelcomeMessageEditorContainer
            name={ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT}
            galleryWelcomeText={galleryWelcomeText}
          />
          <Space vertical size={6} />
          <HighlightDesignEndingTextEditorContainer
            name={ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT}
            galleryEndingText={galleryEndingText}
          />
          <Space vertical size={6} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
