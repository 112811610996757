import {
  BUTTON_CIRCLE_SIZES,
  BUTTON_CIRCLE_STYLES,
  ButtonCircle,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  ProgressBar,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  FadeIn,
  PROGRESS_BAR_STYLES,
} from '@premagic/myne';
import { NumberUtils } from '@premagic/utils';

type Props = {
  progress: number;
  stats: {
    total: number;
    uploaded: number;
    avgSpeed: number;
  };
};

export default function FolderUploadProgress(props: Props): JSX.Element | null {
  const { progress, stats } = props;

  if (!(progress >= 0 && progress < 1)) return null;

  return (
    <FadeIn>
      <Card center style={CARD_STYLES.PRIMARY} size={CARD_SIZES.MD}>
        <Col size={8}>
          <Row center>
            <Col size={null}>
              <ButtonCircle style={BUTTON_CIRCLE_STYLES.WHITE} layer={1} size={BUTTON_CIRCLE_SIZES.LG}>
                <Color shade={COLOR_SHADES.SUCCESS} inline>
                  <Icon name="upload_cloud" size={ICON_SIZES.LG} />
                </Color>
              </ButtonCircle>
            </Col>
            <Space size={5} />
            <Col size={8}>
              <Row>
                <Space size={1} />
                <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARK}>
                  Uploading your files
                </Text>
              </Row>
              <Space vertical size={1} />
              <ProgressBar progress={progress * 100} style={PROGRESS_BAR_STYLES.PRIMARY} />
              <Space vertical size={1} />
              <Row>
                <Space size={1} />
                <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT}>
                  {stats.uploaded} of {stats.total} - {NumberUtils.getNumberInBytesFormat(stats.avgSpeed)}/s
                </Text>
              </Row>
            </Col>
          </Row>
        </Col>
      </Card>

      <Space vertical />
    </FadeIn>
  );
}
