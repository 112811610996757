import { combineReducers } from 'redux';
import AlbumDataDuck from './album/AlbumDataDuck';
import AlbumCreatorDuck from './AlbumCreatorDuck';
import AlbumImagesDataDuck from './AlbumImagesDataDuck';
import AlbumPageDataDuck from './page/AlbumPageDataDuck';
import AlbumProjectDataDuck from './project/AlbumProjectDataDuck';

export default combineReducers({
  data: combineReducers({
    images: AlbumImagesDataDuck,
    album: AlbumDataDuck,
    pages: AlbumPageDataDuck,
    project: AlbumProjectDataDuck,
  }),
  creator: AlbumCreatorDuck,
});
