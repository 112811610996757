import { connect } from 'react-redux';
import { memoryTemplatesSelector } from './MemoriesTemplatesDataDuck';
import MemoriesTemplatesListItem from './MemoriesTemplatesListItem';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    eventId: string;
    projectId: string;
    focusId?: string;
  },
) {
  const { id, eventId, projectId, focusId } = ownProps;

  return {
    eventId,
    projectId,
    focusId,
    id,
    template: memoryTemplatesSelector(state)[id],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoriesTemplatesListItem);
