import React from 'react';
import { SimpleDateUtils } from '@premagic/utils';
import {
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';

import { CustomerClientType } from './CustomerClientsService';

type Props = {
  client: CustomerClientType;
};

export default function CustomerClient(props: Props) {
  const { client } = props;
  const { name, phone_number: phone, email, created_at: createdAt } = client;

  return (
    <Card>
      <Space>
        <Row>
          <Col size={null}>
            <Space vertical size={2} />
            <Icon name="user" />
          </Col>
          <Space />
          <Col size={4}>
            <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
              {name}
            </Text>
            <Text block>{SimpleDateUtils.humanizeDate(createdAt, true)}</Text>
          </Col>
          <Col size={5}>
            <SimpleLink href={`tel:${phone}`} isExternalLink={false}>
              <Row vcenter>
                <Color shade={COLOR_SHADES.DARKER}>
                  <Icon name="phone" size={ICON_SIZES.SM} />
                </Color>
                <Space size={1} />
                <Text color={COLOR_SHADES.DARKER}>{phone}</Text>
              </Row>
            </SimpleLink>
            <Space vertical size={1} />
            <SimpleLink href={`mailto:${email}`} isExternalLink={false}>
              <Row vcenter>
                <Color shade={COLOR_SHADES.DARKER}>
                  <Icon name="mail" size={ICON_SIZES.SM} />
                </Color>
                <Space size={1} />
                <Text color={COLOR_SHADES.DARKER}>{email}</Text>
              </Row>
            </SimpleLink>
          </Col>
        </Row>
      </Space>
    </Card>
  );
}
