import React, { useEffect, useMemo, useState } from 'react';
import { size } from 'lodash';

import { EventTrackerService, FileService, FolderService } from '@premagic/core';
import {
  Card,
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  ErrorBoundary,
  Space,
  Col,
  Row,
  Icon,
  HTML_ICON,
  ICON_SIZES,
  Gallery,
  NoPageBreakInside,
  PageBreak,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  LoadingDots,
  Color,
  GalleryImage,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  Modal,
  ModalTitle,
  MODAL_SIZES,
  Input,
  CARD_SIZES,
  CARD_STYLES,
} from '@premagic/myne';
import { BrowserUtils, CsvUtils, StringUtils } from '@premagic/utils';
import { toastMessage } from '../../../reducers/ToastStore';

import AccountFolderItem from '../folders/AccountFolderItem';

type SelectedPhotosCountsInfoProps = {
  totalSelectedPhotos: number;
  enableSelectionFavorite: boolean;
  favoritedImagesCount: number;
};

function SelectedPhotosCountsInfo(props: SelectedPhotosCountsInfoProps) {
  const { totalSelectedPhotos, enableSelectionFavorite, favoritedImagesCount } = props;
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER}>
        <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          {totalSelectedPhotos}
        </Text>{' '}
        selected {StringUtils.pluralize('photo', totalSelectedPhotos)}
      </Text>
      <Space size={2} />
      {enableSelectionFavorite && (
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
          {HTML_ICON.DOT} <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.YELLOW}>
            ⚑
          </Text>{' '}
          <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
            {favoritedImagesCount}
          </Text>{' '}
          marked as important
        </Text>
      )}
    </div>
  );
}

type PhotosCountsInfoInfoProps = {
  totalImages: number;
  view: 'approved' | 'all' | 'rejected' | 'favorite';
};

function PhotosCountsInfo(props: PhotosCountsInfoInfoProps) {
  const { totalImages, view } = props;
  return (
    <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER}>
      <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
        {totalImages}
      </Text>{' '}
      {view === 'favorite' ? 'Favourite' : 'Hidden'} {StringUtils.pluralize('photo', totalImages)}
    </Text>
  );
}

function copyAllPhotosNames(files: Array<string>, images, imageStatus) {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.COPY_SELECTED_PHOTO_NAME, {
    imageStatus,
  });
  const fileNames = files
    .map(
      (file) =>
        decodeURI(images[file].image_name)
          .replace(/\.[^/.]+$/, '') // remove extension
          .replace(/\s/g, ''), // remove space
    )
    .join(',');
  BrowserUtils.copyToClipboard(fileNames);
  toastMessage('success', 'Copied');
}

function copyImportantPhotosNames(files: Array<string>, images, imageStatus) {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.COPY_IMPORTANT_PHOTO_NAME, {
    imageStatus,
  });
  const importantImagesIds = files.filter(
    (item) => images[item].favorite && images[item].status === FileService.IMAGE_STATUS.ACCEPT,
  );
  const fileNames = importantImagesIds
    .map(
      (file) =>
        decodeURI(images[file].image_name)
          .replace(/\.[^/.]+$/, '') // remove extension
          .replace(/\s/g, ''), // remove space
    )
    .join(',');
  BrowserUtils.copyToClipboard(fileNames);
  toastMessage('success', 'Copied');
}

type FolderItemProps = {
  folder: FolderService.FolderType;
  images: any;
  view: 'approved' | 'all' | 'rejected' | 'favorite';
  enableSelectionFavorite: boolean;
};

function FolderItem(props: FolderItemProps) {
  const { folder, images, view, enableSelectionFavorite } = props;
  const files = folder.files ? Object.values(folder.files) : [];
  const totalFiles = files.length;

  function getTextBasedOnPhotoStatus() {
    if (view === 'approved') {
      return 'selected';
    }
    if (view === 'favorite') {
      return 'favourite';
    }

    return 'hidden';
  }

  return (
    <div>
      <Row>
        <Row vcenter>
          <AccountFolderItem folder={folder} />
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3}>{HTML_ICON.DOT}</Text>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3}>
            {totalFiles} {StringUtils.pluralize('photo', totalFiles)}
          </Text>
          <Space size={2} />
        </Row>
        <Col rightAlighed size={null}>
          {files?.length ? (
            <Button style={BUTTON_STYLES.TERTIARY} onClick={() => copyAllPhotosNames(files, images, view)}>
              <Color shade={COLOR_SHADES.PINK} inline>
                <Icon name="copy" size={ICON_SIZES.MD_SM} />
              </Color>{' '}
              <Space size={2} />
              Copy photo names For Lightroom
            </Button>
          ) : null}
          <Space size={4} />
          {enableSelectionFavorite && files?.length ? (
            <Button style={BUTTON_STYLES.TERTIARY} onClick={() => copyImportantPhotosNames(files, images, view)}>
              <Color shade={COLOR_SHADES.PINK} inline>
                <Icon name="copy" size={ICON_SIZES.MD_SM} />
              </Color>
              <Space size={2} />
              Copy important photo names
            </Button>
          ) : null}
        </Col>
      </Row>
      <Space vertical size={2} />
      {files.length ? (
        <Card accordion defaultExpanded={false}>
          <Space>
            <CardHeader>
              <CardHeaderTitle>Photos</CardHeaderTitle>
            </CardHeader>
            <CardContent>
              <ErrorBoundary>
                <Gallery scaleSizeWithScreen={false}>
                  {files.map((file) => {
                    const { image_name: name, thumbnail_dynamic_image_url: dynThumbnailLocation } = images[file];
                    return (
                      <div key={file}>
                        <GalleryImage
                          showIconFlag={enableSelectionFavorite && (images[file].favorite || false)}
                          favorite={images[file].favorite || false}
                          alt={name}
                          src={dynThumbnailLocation}
                        />
                        <Text size={TEXT_SIZE.SIZE_5} block color={COLOR_SHADES.DARKER}>
                          {name}
                        </Text>
                        <Space vertical size={2} />
                      </div>
                    );
                  })}
                </Gallery>
              </ErrorBoundary>
            </CardContent>
          </Space>
          <NoPageBreakInside />
        </Card>
      ) : (
        <Text muted center>
          No {getTextBasedOnPhotoStatus()} photo on this folder
        </Text>
      )}
      <Space vertical size={10} />
      <PageBreak />
    </div>
  );
}

type Props = {
  initData: (
    projectId: string,
    folderType: FolderService.FOLDER_TYPES,
    imageStatus: 'approved' | 'all' | 'rejected' | 'favorite',
  ) => void;
  folders: Array<FolderService.FolderType>;
  images: Record<string, FileService.FolderFileType>;
  isLoading: boolean;
  projectId: string;
  folderType: FolderService.FOLDER_TYPES;
  view: 'approved' | 'all' | 'rejected' | 'favorite';
  enableSelectionFavorite: boolean;
  projectName: string;
};

export default function AlbumImagesBasedOnStatusLayout(props: Props) {
  const { initData, folders, images, projectId, isLoading, folderType, view, enableSelectionFavorite, projectName } =
    props;
  const [showModal, setShowModal] = useState(false);
  const project = {};
  folders.map((folder) => {
    project[folder.folder_name] = folder.files
      ? Object.values(folder.files).map((file) => images[file].image_name)
      : [];
    return folder;
  });
  const allFileNames = Object.values(project).join().split(',');

  const duplicateFileNames = allFileNames.filter((fileName, index, array) => array.indexOf(fileName) !== index);

  const duplicateFileNamesWithArray = Object.entries(project).reduce((result, item) => {
    const [arrayName, fileNames] = item as any;
    const duplicates = fileNames.filter((fileName) => duplicateFileNames.includes(fileName));
    const res = result;
    if (duplicates.length) {
      res[arrayName] = duplicates;
    }
    return res;
  }, {});

  const favoritedImagesCount = Object.values(images).filter((image) => image?.favorite).length;

  useEffect(() => {
    initData(projectId, folderType, view);
  }, [initData, projectId, folderType, view]);

  const totalImages = size(images);
  const totalFolders = folders.length;
  const isSelectionsFolders = folderType === FolderService.FOLDER_TYPES.SELECTION;

  function copyAllPhotosNamesFromAllFolders() {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.COPY_SELECTED_PHOTO_NAME_FROM_ALL_FOLDERS, {
      view,
    });
    const fileNames = allFileNames.map((file) => decodeURI(file).replace(/\.[^/.]+$/, '')).join(',');
    BrowserUtils.copyToClipboard(fileNames);
    toastMessage('success', 'Copied');
    if (Object.keys(duplicateFileNamesWithArray).length) {
      setShowModal(true);
    }
  }

  function copyDuplicatePhotosNamesInFolder(fileNames: Array<string>) {
    const duplicateFiles = fileNames.map((file) => decodeURI(file).replace(/\.[^/.]+$/, '')).join(',');
    BrowserUtils.copyToClipboard(duplicateFiles);
    toastMessage('success', 'Copied');
  }

  const columns = useMemo(
    () => [
      {
        title: 'File name',
        accessor: 'file_name',
      },
    ],
    [],
  );

  function exportAllFiles() {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.EXPORT_SELECTED_PHOTOS, {
      view,
    });
    const filesForExport = Object.values(project)
      .filter((item) => Array.isArray(item) && item.length > 0)
      .join()
      .split(',');

    const fileNames = filesForExport
      .map((file) => decodeURI(file).replace(/\.[^/.]+$/, ''))
      .map((fileName) => ({ file_name: fileName }));

    if (isSelectionsFolders) {
      const selectedImportantPhotos = Object.values(images)
        .filter((image) => image?.favorite)
        .map((image) => image.image_name);

      const selectedImportantFileNames = selectedImportantPhotos
        .map((file) => decodeURI(file).replace(/\.[^/.]+$/, ''))
        .map((fileName) => ({ file_name: fileName }));

      CsvUtils.createCSVFile(fileNames, `${projectName}-selected-photos`, columns);
      CsvUtils.createCSVFile(selectedImportantFileNames, `${projectName}-important-photos`, columns);
    } else {
      CsvUtils.createCSVFile(fileNames, `${projectName}-favourite`, columns);
    }
  }

  if (isLoading) {
    return (
      <Row columnDirection center>
        <Space vertical size={12} />
        <LoadingDots />
      </Row>
    );
  }
  return (
    <Col size={12}>
      <Modal show={showModal} close={() => {}} size={MODAL_SIZES.MD}>
        <ModalTitle>Opps... There are some duplicate filenames on folders</ModalTitle>
        <Text>There are some same filenames on folders, you will need to copy to individually</Text>
        <Space size={8} vertical />
        {Object.keys(duplicateFileNamesWithArray).map((folderName) => (
          <Space>
            <Row center>
              <Col size={3}>
                <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>{folderName}</Text>
              </Col>
              <Col size={8}>
                <Input name="duplicate_filenames" defaultValue={duplicateFileNamesWithArray[folderName].join(', ')} />
              </Col>
              <Space size={4} />
              <Button
                style={BUTTON_STYLES.LINK}
                onClick={() => copyDuplicatePhotosNamesInFolder(duplicateFileNamesWithArray[folderName])}
              >
                <Color shade={COLOR_SHADES.PINK} inline>
                  <Icon name="copy" size={ICON_SIZES.MD_SM} />
                </Color>
              </Button>
            </Row>
          </Space>
        ))}
        <Space vertical size={4} />
        <Row>
          <Col rightAlighed size={null}>
            <Button style={BUTTON_STYLES.TERTIARY} onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Col>
        </Row>
      </Modal>

      <div>
        <Row>
          {isSelectionsFolders ? (
            <SelectedPhotosCountsInfo
              totalSelectedPhotos={totalImages}
              enableSelectionFavorite={enableSelectionFavorite}
              favoritedImagesCount={favoritedImagesCount}
            />
          ) : (
            <PhotosCountsInfo totalImages={totalImages} view={view} />
          )}
          <Col rightAlighed size={null}>
            <Row vcenter>
              <Button
                style={BUTTON_STYLES.LINK}
                onClick={() => {
                  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.PRINT_SELECTED_PHOTOS, {});
                  BrowserUtils.print();
                }}
                className="hidden-print"
              >
                Print
                <Space size={1} />
                <Icon name="printer" size={ICON_SIZES.SM} />
              </Button>
              <Space />
              <Button style={BUTTON_STYLES.LINK} onClick={exportAllFiles} className="hidden-print">
                Export as csv
                <Space size={1} />
                <Icon name="download" size={ICON_SIZES.SM} />
              </Button>
            </Row>
          </Col>
        </Row>
        <Space vertical size={4} />
        <Col size={3}>
          <Card size={CARD_SIZES.DEFAULT}>
            <Space size={4}>
              <Row columnDirection center>
                <Text size={TEXT_SIZE.SIZE_4}>Copy all the filenames to lightroom</Text>
                <Space vertical size={2} />
                <Button style={BUTTON_STYLES.PRIMARY} onClick={() => copyAllPhotosNamesFromAllFolders()}>
                  <Color shade={COLOR_SHADES.PINK} inline>
                    <Icon name="copy" size={ICON_SIZES.MD_SM} />
                  </Color>
                  <Space size={2} />
                  Copy
                </Button>
              </Row>
            </Space>
          </Card>
        </Col>
        <Space vertical size={4} />
      </div>

      {totalFolders === 0 && (
        <div>
          <Text muted>There are no folders added yet</Text>
        </div>
      )}
      {folders.map((folder) => (
        <FolderItem
          key={folder.folder_id}
          folder={folder}
          images={images}
          view={view}
          enableSelectionFavorite={isSelectionsFolders && enableSelectionFavorite}
        />
      ))}
    </Col>
  );
}
