import { FormGroup, FormLabel } from '@premagic/myne';
import FontSelectorContainer from '../../../../../../../font-selector/FontSelectorContainer';
import {
  EventAttendeeBadgeDesignType,
  WIDGET_TYPE,
  WidgetDataType,
} from '../../../service/EventAttendeeBadgeDesignService';

type Props = {
  projectId: string;
  folderId: string;
  selectedWidgetForEdit?: WIDGET_TYPE;
  designData?: EventAttendeeBadgeDesignType;
  editFont: (
    projectId: string,
    folderId: string,
    previousDesign?: EventAttendeeBadgeDesignType,
    widgetId?: string,
    fontFamily?: string,
  ) => void;
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
  loadFonts: (fonts: Array<string>) => void;
};

export default function EventAttendeeBadgeFontSelect(props: Props) {
  const { selectedWidgetForEdit, designData, editFont, projectId, folderId, posterWidgetDesign, loadFonts } = props;

  function handleFontSelect(data) {
    const { fontFamily, fontSource } = data;
    editFont(projectId, folderId, designData, selectedWidgetForEdit, fontFamily);
    loadFonts([fontFamily]);
  }

  return (
    <FormGroup>
      <FormLabel>Font</FormLabel>
      <FontSelectorContainer
        defaultValue={selectedWidgetForEdit && posterWidgetDesign?.[selectedWidgetForEdit]?.font}
        onChange={handleFontSelect}
        name="badge_font"
        nameSource=""
        disabled={!selectedWidgetForEdit}
      />
    </FormGroup>
  );
}
