import { RouterService } from '@premagic/core';
import { connect } from 'react-redux';
import { foldersEntitiesSelector } from '../../../../../../projects/folders/AccountFoldersDataDuck';

import { sponsorCreativeWatermarksByFolderIdSelector } from '../../../SponsorCreativeDataDuck';
import FolderWatermarkListItem from './FolderWatermarkListItem';

function mapStateToProps(state, ownProps: { sponsorFolderId: string; folderId: string; eventId: string }) {
  const { folderId, eventId } = ownProps;

  const folder = foldersEntitiesSelector(state)[folderId];

  return {
    sponsorCreativeWatermarkIds: sponsorCreativeWatermarksByFolderIdSelector(folderId)(state),
    folder,
    eventId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url, {});
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderWatermarkListItem);
