import { connect } from 'react-redux';
import SponsorReportCards from './SponsorReportCards';
import { eventSponsorsIdsSelector } from '../../event-sponsors/EventSponsorDataDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../../common/Constants';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const sponsorIds = eventSponsorsIdsSelector(state);
  return {
    isLoading: isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state),
    sponsorIds,
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorReportCards);
