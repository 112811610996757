import { connect } from 'react-redux';
import CreateClientWebsitePage from './CreateClientWebsitePage';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { addClientWebsite } from '../ClientWebsiteDataDuck';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';

function mapStateToProps(state, ownProps) {
  const { projectId } = ownProps.match.params;

  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.ADD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_WEBSITE.ADD)(state),
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addClientWebsite: (projectId: string, data) => {
      dispatch(addClientWebsite(dispatch, projectId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientWebsitePage);
