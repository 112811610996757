import { PresetDesignService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import PresetDesignButton from './PresetDesignButton';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (value) => void;
    selectedPresetDesign: string;
    option: PresetDesignService.PresetDesignOption;
    isPresetDesignSelectorEnabled: boolean;
    variant?: PresetDesignService.PRESET_DESIGN_VARIANT;
  },
) {
  const { onChange, selectedPresetDesign, isPresetDesignSelectorEnabled, variant } = ownProps;
  return {
    onChange,
    selectedPresetDesign,
    isPresetDesignSelectorEnabled,
    variant,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PresetDesignButton);
