import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { addNewTaskToTaskgroup, taskgroupItemSelector } from '../data-duck/TaskGroupDataDuck';
import WorkflowTaskGroup from './WorkflowTaskGroup';

function mapStateToProps(state, ownProps: { taskgroupId: number; workflowId: number }) {
  const { taskgroupId, workflowId } = ownProps;
  return {
    workflowId,
    taskgroup: taskgroupItemSelector(taskgroupId)(state),
    isLoading: isLoadingSelector(LOADINGS.CRM_WORKFLOW.CREATE_TASKGROUP)(state),
    isCreatingTask: isLoadingSelector(LOADINGS.CRM_WORKFLOW.CREATE_TASK)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addTask: (workflowId, taskgroupId, taskName) => {
      dispatch(addNewTaskToTaskgroup(dispatch, workflowId, taskgroupId, { name: taskName }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskGroup);
