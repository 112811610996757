import { connect } from 'react-redux';
import IntegrationSyncOrImportMenuItems from './IntegrationSyncOrImportMenuItems';
import {
  getProjectMetaData,
  projectMetaDataForProjectSelector,
} from '../../projects/project-meta/ProjectsMetaDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { integrationsEventClientSettingsSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);
  return {
    projectId,
    toFetchProjectMeta: !projectMeta,
    isFetchingProjectMeta: isLoadingSelector(LOADINGS.PROJECT_META.FETCH)(state),
    integrationSettings: integrationsEventClientSettingsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProjectMeta: (projectId: string) => {
      dispatch(getProjectMetaData(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSyncOrImportMenuItems);
