import React from 'react';

import {
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  List,
  LIST_ITEM_ACTION_STYLES,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  Tooltip,
  Row,
} from '@premagic/myne';

import { FolderService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import AccountFolderItem from '../../../projects/folders/AccountFolderItem';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  folderIds: Array<string>;
  folders: Array<FolderService.FolderType>;
  projectId: string;
  updateOrder: (projectId: string, folderIds: Array<string>, data: { from: number; to: number }) => void;
};

export default function SignatureAlbumCardFoldersList(props: Props): JSX.Element {
  const { folders, projectId, folderIds, updateOrder } = props;
  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;
    if (reason === 'DROP' && destination) {
      updateOrder(projectId, folderIds, {
        from: source.index,
        to: destination.index,
      });
    }
  }
  return (
    <DragDropContext onDragEnd={onDrop}>
      <List>
        <Droppable droppableId="FOLDERS-LIST">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {folders.map((folder, idx) => {
                const { folder_id: folderId } = folder;
                const folderEditURL = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT, {
                  projectId,
                  folderId,
                });

                return (
                  <Draggable key={folderId} draggableId={folderId} index={idx}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        key={folderId}
                        className={`js-folder-${folderId}`}
                      >
                        <ListItem key={folderId} style={LIST_ITEM_STYLES.COMPACT_LIST} to={folderEditURL}>
                          <ListItemAction style={LIST_ITEM_ACTION_STYLES.DEFAULT}>
                            <Tooltip message="Drag and move to reorder" placement="left">
                              <Row>
                                <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                                  <Icon name="drag" size={ICON_SIZES.SM} />
                                </Color>
                              </Row>
                            </Tooltip>
                          </ListItemAction>
                          <AccountFolderItem folder={folder} />
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </List>
    </DragDropContext>
  );
}
