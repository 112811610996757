import React, { useMemo } from 'react';
import { FormGroup, FormLabel, Select } from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import { i18n } from '@premagic/utils';

type LanguageSelectorProps = {
  defaultLangCode?: i18n.LANGUAGE_CODE;
};

// later if requirement arrives then we can change the languages of default values of some items like registration title when the
// language is changed here by calling respective apis.
function LanguageSelector(props: LanguageSelectorProps): JSX.Element {
  const { defaultLangCode } = props;
  const languageOptions = useMemo(
    () =>
      Object.values(i18n.LANGUAGES_DATA).map((option) => ({
        value: option.langCode,
        label: option.label,
      })),
    [],
  );

  const defaultSelectedOption = languageOptions.find((option) => option.value === defaultLangCode);

  return (
    <FormGroup>
      <FormLabel isRequired>Default Language</FormLabel>
      <Select
        options={languageOptions}
        value={defaultSelectedOption}
        name={ProjectMetaService.PROJECT_META_TYPES.DEFAULT_LANG}
        isMulti={false}
      />
    </FormGroup>
  );
}

type Props = { defaultLangCode?: i18n.LANGUAGE_CODE };

export default function SwitchLanguageForm(props: Props): JSX.Element {
  const { defaultLangCode } = props;
  return (
    <div>
      <LanguageSelector defaultLangCode={defaultLangCode} />
    </div>
  );
}
