import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils } from '@premagic/utils';
import { omit } from 'lodash';
import { navigateTo } from '../../../../../services/RouterService';
import { ACCOUNT_FINANCIALS_FILTER_KEYS, AccountFinancialsFiltersType } from './AccountFinancialsFilterTypes';
import { getAccountFinancialsUrlWithFilters } from './AccountFinancialsFilterService';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';

const getActionType = ActionTypeUtils.getActionTypeFunction('ACCOUNT_FINANCIALS_FILTERS', true);

export const navigateToAccountFinancialsPageWithFilter = createAction(
  getActionType('NAVIGATE_WITH_FILTER'),
  (dispatch, type: ACCOUNT_FINANCIALS_TYPES, filters: AccountFinancialsFiltersType) => {
    navigateTo(dispatch, getAccountFinancialsUrlWithFilters(type, filters));
  },
);

export const removeFilterAccountFinancialsPage = createAction(
  getActionType('REMOVE_FILTER'),
  (
    dispatch,
    type: ACCOUNT_FINANCIALS_TYPES,
    filterKeyToRemove: ACCOUNT_FINANCIALS_FILTER_KEYS,
    filters: AccountFinancialsFiltersType,
  ) => {
    const updateFilters = omit(filters, filterKeyToRemove);
    navigateTo(dispatch, getAccountFinancialsUrlWithFilters(type, updateFilters));
  },
);

const initialState = {};

export default handleActions({}, initialState);
