import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Divider,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  Row,
  Section,
  SECTION_STYLES,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

import { FolderService, HelpArticlesService, ProjectService } from '@premagic/core';
import { BrowserUrlUtils, NumberUtils, StringUtils } from '@premagic/utils';
import { groupBy } from 'lodash';

import AccountFolderItem from '../../../projects/folders/AccountFolderItem';
import APP_URLS from '../../../../services/AppRouteURLService';
import MESSAGES from '../../../../../../common/Messages';
import { HelpLink } from '../../../settings/HelpLInk';
import AddSelectionAlbumButtonContainer from './AddSelectionAlbumButtonContainer';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import CloneHighlightFolderToSelectionAlbumButtonContainer from './CloneHighlightFolderToSelectionAlbumButtonContainer';

function SummaryMessageInfo(props: { color: COLOR_SHADES; children: JSX.Element }): JSX.Element {
  const { color, children } = props;
  return (
    <div>
      <Row vcenter>
        <Color shade={color} inline>
          <Icon name="info" size={ICON_SIZES.SM} />
        </Color>
        <Space size={1} />
        <Text color={color} boldness={TEXT_BOLDNESS.BOLD}>
          {children}
        </Text>
      </Row>
      <Space size={2} vertical />
    </div>
  );
}

function FolderSummaryMessage(props: { folders: Array<FolderService.FolderType> }) {
  const { folders } = props;
  const groupedFolders = groupBy(folders, 'status');
  const reviewCompletedAndWaitingFolders = groupedFolders[FolderService.FOLDER_STATUS.REVIEW_COMPLETED]?.length || 0;
  const inReviewFolders = groupedFolders[FolderService.FOLDER_STATUS.WAITING_FOR_APPROVAL]?.length || 0;
  const doneFolders = groupedFolders[FolderService.FOLDER_STATUS.DONE]?.length || 0;

  const totalReviewPendingFolders = reviewCompletedAndWaitingFolders + inReviewFolders;

  if (totalReviewPendingFolders) {
    return (
      <SummaryMessageInfo color={COLOR_SHADES.WARNING}>
        <div>
          {totalReviewPendingFolders} {StringUtils.pluralize('Folder', totalReviewPendingFolders)} in review
        </div>
      </SummaryMessageInfo>
    );
  }

  if (doneFolders) {
    return (
      <SummaryMessageInfo color={COLOR_SHADES.SUCCESS}>
        <div>All Folders are reviewed</div>
      </SummaryMessageInfo>
    );
  }
  return null;
}

type SelectionAlbumCardHeaderProps = {
  projectId: string;
  zeroState: boolean;
  folderFilesStats: FolderService.FolderTypeStats;
};

function SelectionAlbumCardHeader(props: SelectionAlbumCardHeaderProps) {
  const { projectId, zeroState, folderFilesStats } = props;
  const { total_image: totalFiles, accepted: totalSelected } = folderFilesStats;
  const selectionSettingsUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.SELECTION, { projectId });

  if (zeroState) {
    return (
      <Section style={SECTION_STYLES.YELLOW_LIGHT} hasPadding={false}>
        <Space>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="selection_album" accentStyle={ICON_ACCENT_STYLES.FILL} />
            </Color>
            <Space size={1} />
            <Text primaryStyle size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
              {MESSAGES.SELECTION_FOLDER.NAME}
            </Text>
          </Row>
          <Text block>
            {MESSAGES.SELECTION_FOLDER.INFO}{' '}
            <HelpLink articleId={HelpArticlesService.ARTICLES.PHOTO_SELECTION}> learn more.</HelpLink>
          </Text>
        </Space>
      </Section>
    );
  }
  return (
    <Section style={SECTION_STYLES.YELLOW_LIGHT} hasPadding={false}>
      <Space>
        <Row>
          <div>
            <Row vcenter>
              <Color shade={COLOR_SHADES.DARKER} inline>
                <Icon name="selection_album" accentStyle={ICON_ACCENT_STYLES.FILL} />
              </Color>
              <Space size={1} />
              <Text primaryStyle size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER}>
                {MESSAGES.SELECTION_FOLDER.NAME}
              </Text>
            </Row>
            <Text block>
              <Text color={COLOR_SHADES.DARKER}>{NumberUtils.getNumberInFormat(totalSelected)}</Text> selected of{' '}
              <Text color={COLOR_SHADES.DARKER}>{NumberUtils.getNumberInFormat(totalFiles)}</Text>{' '}
              {StringUtils.pluralize('Photo', totalFiles)}
            </Text>
          </div>
          <Col size={null} rightAlighed>
            <Button link={selectionSettingsUrl} style={BUTTON_STYLES.TERTIARY}>
              <Icon name="edit" size={ICON_SIZES.SM} /> <Space size={1} /> Edit Album
            </Button>
          </Col>
        </Row>
      </Space>
    </Section>
  );
}

type Props = {
  isSelectionAlbumEnabled: boolean;
  focus: boolean;
  folderIds: Array<string>;
  project?: ProjectService.ProjectType;
  folders: Array<FolderService.FolderType>;
  projectId: string;
  hasSelectionAlbum: boolean;
  folderFilesStats: FolderService.FolderTypeStats;
};

export default function SelectionAlbumCard(props: Props): JSX.Element | null {
  const {
    hasSelectionAlbum,
    folders,
    projectId,
    project,
    folderFilesStats,
    focus,
    isSelectionAlbumEnabled,
    folderIds,
  } = props;
  if (!isSelectionAlbumEnabled) return null;

  const { mpin } = project || { mpin: 0 };
  const groupedFolders = groupBy(folders, (folder) => {
    if ([FolderService.FOLDER_STATUS.DONE, FolderService.FOLDER_STATUS.REVIEW_COMPLETED].includes(folder.status)) {
      return FolderService.FOLDER_STATUS.DONE;
    }
    return folder.status;
  });
  const selectedPhotosUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.SELECTION__SELECTED_PHOTOS, {
    projectId,
    folderId: folderIds[0],
  });
  if (!hasSelectionAlbum)
    return (
      <Card highlight={focus}>
        <SelectionAlbumCardHeader zeroState folderFilesStats={folderFilesStats} projectId={projectId} />
        <Space>
          <Text muted>{MESSAGES.SELECTION_FOLDER.ZERO_STATE}</Text>
          <Space vertical />
          <AddSelectionAlbumButtonContainer projectId={projectId} />
          <CloneHighlightFolderToSelectionAlbumButtonContainer projectId={projectId} />
        </Space>
      </Card>
    );

  const shareUrl = folders.find((folder) => folder.share_url)?.share_url;
  return (
    <Card highlight={focus}>
      <div className={JS_SELECTORS.PHOTO_SELECTION_CARD}>
        <SelectionAlbumCardHeader projectId={projectId} zeroState={false} folderFilesStats={folderFilesStats} />
      </div>
      <Space>
        <Row>
          <Col size={8}>
            {Object.keys(groupedFolders).map((group) => (
              <div key={group}>
                <Text block boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT}>
                  {FolderService.FOLDER_STATUS_INFO[group].title.toUpperCase()}
                </Text>
                <List>
                  {groupedFolders[group].map((folder) => {
                    const { folder_id: folderId } = folder;
                    const folderEditURL = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.SELECTION, {
                      projectId,
                      folderId,
                    });
                    return (
                      <ListItem key={folderId} style={LIST_ITEM_STYLES.COMPACT_LIST} to={folderEditURL}>
                        <AccountFolderItem folder={folder} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            ))}
            <Space vertical size={2} />
            <AddSelectionAlbumButtonContainer projectId={projectId} />
            <CloneHighlightFolderToSelectionAlbumButtonContainer projectId={projectId} />
            <Space vertical size={2} />
          </Col>
          <Col size={null} rightAlighed>
            <FolderSummaryMessage folders={folders} />
          </Col>
        </Row>
      </Space>
      <Divider />
      <Space>
        <Row>
          <Button
            link={shareUrl}
            style={BUTTON_STYLES.LINK}
            disabled={!shareUrl}
            isExternalLink
            disabledMessage={MESSAGES.SELECTION_FOLDER.FOLDER_NOT_SHARED}
          >
            <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} /> Open
          </Button>
          <Button
            link={selectedPhotosUrl}
            style={BUTTON_STYLES.LINK}
            disabled={!shareUrl}
            disabledMessage={MESSAGES.SELECTION_FOLDER.FOLDER_NOT_SHARED}
            className={JS_SELECTORS.PHOTO_SELECTION_PREVIEW_BTN}
          >
            <Icon name="check_circle" size={ICON_SIZES.SM} /> <Space size={1} /> Selected photos
          </Button>
        </Row>
      </Space>
    </Card>
  );
}
