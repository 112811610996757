import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { addEventPoster, eventPostersSelector, removeEventPoster } from '../crm/event-posters/EventPostersDataDuck';
import { setViewOptions, viewOptionsSelector } from './EventPosterCreatorDuck';
import EventPosterItemOptions from './EventPosterItemOptions';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    posterId: string;
    location: 'list' | 'creator';
  },
) {
  const { eventId, projectId, posterId, location } = ownProps;

  return {
    eventId,
    projectId,
    posterId,
    poster: eventPostersSelector(state)[posterId],
    isDeleting: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.DELETE(posterId))(state),
    viewOptions: viewOptionsSelector(state),
    location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removePoster: (projectId, posterId) => {
      dispatch(removeEventPoster(dispatch, projectId, posterId));
    },
    duplicatePoster: (projectId, posterId) => {
      dispatch(addEventPoster(dispatch, projectId, posterId));
    },
    setViewOptions: (viewOptions) => {
      dispatch(setViewOptions(dispatch, viewOptions));
    },
    showEmbedPosterModal: (posterId: string) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.EVENT_POSTERS.SHOW_EMBED_MODAL, true));
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.EVENT_POSTERS.SHOW_EMBED_MODAL, posterId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterItemOptions);
