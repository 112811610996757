import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Icon,
  ICON_SIZES,
  List,
  ListItem,
  LoadingBox,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import ProposalTemplateItemContainer from './EventProposalItemContainer';
import AddEventProposalPanelContainer from './add/AddEventProposalPanelContainer';

type Props = {
  projectId?: string;
  focusId?: string;
  eventId: string;
  showAddPanel: () => void;
  proposalIds: Array<string>;
  isLoading: boolean;
};

export default function EventProposals(props: Props): JSX.Element {
  const { eventId, projectId, focusId } = props;

  const { proposalIds, isLoading, showAddPanel } = props;
  return (
    <Card>
      <Space>
        <CardHeader hasPadding={false}>
          <Row vcenter>
            <Icon name="film" size={ICON_SIZES.SM} /> <Space size={1} />
            <CardHeaderTitle>Proposals</CardHeaderTitle>
          </Row>
          <CardHeaderActions>
            <Button style={BUTTON_STYLES.LINK} onClick={showAddPanel}>
              <Icon size={ICON_SIZES.SM} name="plus" />
              Add
            </Button>
          </CardHeaderActions>
        </CardHeader>
      </Space>
      <LoadingBox isLoading={isLoading}>
        <List>
          {proposalIds.length === 0 ? (
            <ListItem>
              <Text muted>You haven&apos;t sent any Proposals</Text>
            </ListItem>
          ) : (
            proposalIds.map((id) => <ProposalTemplateItemContainer key={id} id={id} highlight={id === focusId} />)
          )}
        </List>
      </LoadingBox>
      <AddEventProposalPanelContainer eventId={eventId} projectId={projectId} />
    </Card>
  );
}
