import { ProposalVariableService } from '@premagic/proposals';
import {
  Card,
  CARD_STYLES,
  Space,
  SubTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroup,
  TextGroupLabel,
  TextGroupValue,
} from '@premagic/myne';
import { FolderMetaService } from '@premagic/core';

type Props = {
  deckGroupedVariables: Record<
    ProposalVariableService.PROPOSAL_VARIABLE_SCOPE,
    Array<ProposalVariableService.ProposalVariableType & { value: string | number }>
  >;
  folderMeta: FolderMetaService.FolderMetaType;
};

export default function EventProposalDeckVariables(props: Props): JSX.Element {
  const { deckGroupedVariables, folderMeta } = props;
  const deckGroupedVariablesArray = Object.entries(deckGroupedVariables) as [
    ProposalVariableService.PROPOSAL_VARIABLE_SCOPE,
    Array<ProposalVariableService.ProposalVariableType & { value: string | number }>,
  ][];

  return (
    <Card style={CARD_STYLES.SECONDARY}>
      <Space size={2}>
        <SubTitle>Variables</SubTitle>
        <Space vertical size={1} />
        {deckGroupedVariablesArray.map(([scope, variables]) => {
          const scopeDetails = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE_DETAILS[scope];
          return (
            <div key={scope}>
              <Text boldness={TEXT_BOLDNESS.BOLD}>{scopeDetails?.title || '[Variable was deleted]'}</Text>
              {variables.map(({ name, value, type }) => (
                <TextGroup key={name} size="sm">
                  <TextGroupLabel>{name}</TextGroupLabel>
                  <TextGroupValue
                    value={value}
                    secondaryValue={folderMeta.currency_code}
                    type={type as unknown as TEXT_GROUP_TYPES}
                  />
                </TextGroup>
              ))}
              {variables.length === 0 && <Text>No Variables</Text>}
              <Space vertical />
            </div>
          );
        })}
        {deckGroupedVariablesArray.length === 0 && (
          <Text muted size={TEXT_SIZE.SIZE_5}>
            No Variables set
          </Text>
        )}
      </Space>
    </Card>
  );
}
