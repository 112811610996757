import React from 'react';
import { isArray } from 'lodash';
import { COLOR_SHADES, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import { PricingPlanFeaturesService, PricingPlanService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';

type FeatureItemNameProps = {
  id: string;
  name: string;
  plan: PricingPlanService.PricingPlanType;
};

function FeatureItemName(props: FeatureItemNameProps): JSX.Element | null {
  const { name, id, plan } = props;
  const {
    display_photos_per_highlight: displayPhotosPerHighlight,
    highlights: totalHighlights,
    photos_per_highlight: photosInHighlight,
  } = plan;
  let valueForPlan;
  const featureDetails: PricingPlanFeaturesService.PricingPlanFeaturesDetailsType =
    PricingPlanFeaturesService.PRICING_PLAN_FEATURES_DETAILS[id];
  switch (id) {
    case PricingPlanFeaturesService.PRICING_PLAN_FEATURES.HIGHLIGHT_PHOTOS_LIMIT:
      return (
        <div>
          <Text color={COLOR_SHADES.DARK} size={TEXT_SIZE.SIZE_5}>
            Unlimited Photos for fair usage
          </Text>
        </div>
      );
    case PricingPlanFeaturesService.PRICING_PLAN_FEATURES.HIGHLIGHT_FOLDER_LIMIT:
      return (
        <div>
          <Text color={COLOR_SHADES.DARK} size={TEXT_SIZE.SIZE_5}>
            {totalHighlights > 20 ? 'Unlimited' : totalHighlights} {StringUtils.pluralize('folder', totalHighlights)}{' '}
            per Project
          </Text>
        </div>
      );
    // case PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE_STANDARD:
    case PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE_STANDARD_COMMISSION:
    case PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SUPPORT_TRAINING:
      valueForPlan = isArray(featureDetails.value)
        ? featureDetails.value.find((value) => plan.id.includes(value.plan))
        : { value: 'MAKE VALUE AS ARRAY!!!!' };
      if (!valueForPlan) return null;

      return (
        <div>
          <Text color={COLOR_SHADES.DARK} size={TEXT_SIZE.SIZE_5}>
            {valueForPlan.value} {name === 'Training' ? '' : name}
          </Text>
        </div>
      );
    default:
      return (
        <div>
          <Text color={COLOR_SHADES.DARK} size={TEXT_SIZE.SIZE_5}>
            {name}
          </Text>
          {featureDetails?.beta && (
            <>
              <Space size={2} />
              <Text color={COLOR_SHADES.PINK} size={TEXT_SIZE.SIZE_6} boldness={TEXT_BOLDNESS.BOLD}>
                BETA
              </Text>
            </>
          )}
        </div>
      );
  }
}

type CustomFeatureListForAddonsGroupProps = {
  plan: PricingPlanService.PricingPlanType;
  userLocal: PricingPlanService.PRICING_PLAN_VARIANT_LOCAL;
};

function CustomFeatureListForAddonsGroup(props: CustomFeatureListForAddonsGroupProps): JSX.Element {
  const { plan, userLocal } = props;
  const money1000 = userLocal === PricingPlanService.PRICING_PLAN_VARIANT_LOCAL.IN ? '₹1000' : '$14';
  const money3 = userLocal === PricingPlanService.PRICING_PLAN_VARIANT_LOCAL.IN ? '₹3' : '$0.05';
  const features = [`Extend Project Validity at ${money3} per GB`];

  if (plan.id.includes('GROWTH')) {
    features.push(
      `Video (One 15 mins video per project) at ${money1000} per month`,
      `Priority support ${money1000} per month`,
    );
  }
  if (plan.id.includes('BUSINESS')) {
    features.push(
      `Video (One 15 mins video per project) at ${money1000} per month`,
      `Priority support ${money1000} per month`,
    );
  }

  return (
    <div>
      {features.map((name) => (
        <div key={name}>
          <FeatureItemName id={plan.name} name={name} plan={plan} />
          <Space vertical size={1} />
        </div>
      ))}
    </div>
  );
}

type FeatureListProps = {
  plan: PricingPlanService.PricingPlanType;
  features: Array<PricingPlanFeaturesService.PricingPlanFeaturesDetailsType>;
};

function FeatureList(props: FeatureListProps): JSX.Element {
  const { plan, features } = props;

  return (
    <div>
      {features.map((feature) => {
        const { key, name } = feature;
        return (
          <div key={`${plan.name}-${key}`}>
            <FeatureItemName id={key} name={name} plan={plan} />
            <Space vertical size={1} />
          </div>
        );
      })}
    </div>
  );
}

type FeatureGroupProps = {
  id: PricingPlanFeaturesService.FEATURE_GROUP;
  plan: PricingPlanService.PricingPlanType;
  previousPlan?: PricingPlanService.PricingPlanType;
  userLocal: PricingPlanService.PRICING_PLAN_VARIANT_LOCAL;
};

function FeatureGroup(props: FeatureGroupProps): JSX.Element | null {
  const { id, plan, previousPlan, userLocal } = props;
  const { feature_list: featureList } = plan;
  const previousFeatures = previousPlan ? previousPlan.feature_list : [];

  const featuresToShow = Object.entries(PricingPlanFeaturesService.PRICING_PLAN_FEATURES_DETAILS)
    .filter(([, value]) => value.group.includes(id))
    .filter(([, feature]) => {
      if (feature.showAlways) return true;
      const isInPreviousPlan = previousFeatures.includes(feature.key);
      const isInCurrentPlan = featureList.includes(feature.key);

      if (feature.showInPricingPlanCustomFunction) {
        const show = feature.showInPricingPlanCustomFunction?.(plan);
        // we will not check for if it exists on previous plan, as it will exists
        return isInCurrentPlan && show;
      }

      return !isInPreviousPlan && isInCurrentPlan;
    })
    .map(([, value]) => value);

  const groupDetails = PricingPlanFeaturesService.FEATURE_GROUP_DETAILS[id];

  if (groupDetails.custom)
    return (
      <div>
        <Text color={COLOR_SHADES.BLUE_DARK} boldness={TEXT_BOLDNESS.BOLD}>
          {groupDetails.name}
        </Text>
        {id === PricingPlanFeaturesService.FEATURE_GROUP.ADDONS && (
          <CustomFeatureListForAddonsGroup plan={plan} userLocal={userLocal} />
        )}
        <Space vertical />
      </div>
    );

  if (featuresToShow.length === 0) return null;

  return (
    <div>
      <Text color={COLOR_SHADES.BLUE_DARK} boldness={TEXT_BOLDNESS.BOLD}>
        {groupDetails.name}
      </Text>
      <FeatureList plan={plan} features={featuresToShow} />
      <Space vertical />
    </div>
  );
}

type Props = {
  plan: PricingPlanService.PricingPlanType;
  previousPlan?: PricingPlanService.PricingPlanType;
  userLocal: PricingPlanService.PRICING_PLAN_VARIANT_LOCAL;
};

export default function PricingPlanList(props: Props): JSX.Element {
  const { plan, previousPlan, userLocal } = props;
  return (
    <div>
      {Object.keys(PricingPlanFeaturesService.FEATURE_GROUP_DETAILS).map((id) => (
        <FeatureGroup
          key={id}
          id={Number(id) as unknown as PricingPlanFeaturesService.FEATURE_GROUP}
          plan={plan}
          userLocal={userLocal}
          previousPlan={previousPlan}
        />
      ))}
    </div>
  );
}
