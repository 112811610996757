import React, { useMemo } from 'react';
import { FormGroup, FormLabel, Select } from '@premagic/myne';
import { CRMPaymentCategoryType } from './CRMPaymentService';

type Props = {
  selectedCategoryId?: number;
  categories: Array<CRMPaymentCategoryType>;
};

export default function PaymentCategoryPicker(props: Props): JSX.Element {
  const { selectedCategoryId, categories } = props;
  const categoryOptions = useMemo(
    () => categories.map((category) => ({ value: category.id, label: category.name })),
    [categories],
  );

  const selectedId = selectedCategoryId || categories[0]?.id;
  const selectedOption = categoryOptions.find((option) => option.value === selectedId);

  return (
    <FormGroup>
      <FormLabel isRequired>Category</FormLabel>
      <Select options={categoryOptions} value={selectedOption} name="payment_category" isMulti={false} />
    </FormGroup>
  );
}
