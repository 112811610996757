import { HttpUtils } from '@premagic/utils';
import { getAPIUrl } from './AppServices';

export enum DIGITAL_INVITE_ORDER_VARIENTS {
  TRIAL = 'TRIAL',
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export enum DIGITAL_INVITE_FEATURES {
  INVITES,
  COUNT_DOWN_TIMER,
  GOOGLE_MAP_LOCATION,
  LIVE_VIDEO,
  WISHES,
}

type DigitalInviteFeatureLimits = {
  INVITES: number;
  WISHES: number;
};

export enum ORDER_NAMES {
  DIGITAL_INVITE = 'DIGITAL_INVITE',
}

type ProjectOrdersBaseType = {
  project_id: string;
  order_id: string;
  active: boolean;
  expiry_month: number;
  folder_id: string;
  price: string;
  sku: number;
  created_at: string;
};

export type ProjectOrdersDigitalInviteType = ProjectOrdersBaseType & {
  name: ORDER_NAMES.DIGITAL_INVITE;
  variant: DIGITAL_INVITE_ORDER_VARIENTS;
  feature_limits: DigitalInviteFeatureLimits;
  feature_list: Array<DIGITAL_INVITE_FEATURES>;
};

export type ProjectOrdersType = ProjectOrdersDigitalInviteType;

export function fetchProjectOrders(projectId: string): Promise<Array<ProjectOrdersType>> {
  return HttpUtils.get(`${getAPIUrl()}/client/projects/${projectId}/orders/`)
    .then((response) => response.data)
    .catch((respose) => Promise.reject(respose.data));
}
