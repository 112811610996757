import { connect } from 'react-redux';
import AddClientWishPanel from './AddClientWishPanel';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { addClientWish } from '../ClientWishDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';

const PANEL_KEY = LOADINGS.CLIENT_WISH.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;

  return {
    projectId,
    folderId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WISH.ADD)(state),
    error: errorObjectForTypeSelector(LOADINGS.CLIENT_WISH.ADD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addWish: (options: { projectId: string; folderId: string }, data) =>
      dispatch(addClientWish(dispatch, options, data)),
    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClientWishPanel);
