import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { clientWebsitesSelector, saveClientWebsite } from '../ClientWebsiteDataDuck';

import ClientWebsiteClientPhotos from './ClientWebsiteClientPhotos';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;

  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    isStaffUser: isStaffUserSelector(state),
    hasDigitalInviteCoverPhotosFilters: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE_COVER_PHOTO_FILTERS,
    )(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveClientWebsite: (id: string, projectId: string, data, navigateToUrl) => {
      dispatch(saveClientWebsite(dispatch, { id, projectId, navigateToUrl }, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteClientPhotos);
