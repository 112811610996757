import { ModalDuck } from '@premagic/common-ducks';
import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  eventProjectSelectorSelector,
  folderIdsWithFolderTypeForProjectSelector,
  projectFolderTypesLinksSelector,
} from '../../../projects/AccountProjectsDataDuck';
import { fetchFolder } from '../../../projects/folders/AccountFoldersDataDuck';
import { getProjectSponsorsStatsData } from '../../../projects/project-stats/ProjectsStatsDataDuck';
import { addSponsorOrBrandingFolder } from '../../../projects/sponsor/SponsorAndBrandingDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import EventSponsorLayout from './EventSponsorLayout';
import {
  sponsorCategoryIdsSelector,
  fetchEventSponsorCategoryData,
} from './sponsor-category/data-duck/SponsorCategoryDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;
  const sponsorFolderIds = projectId
    ? folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.SPONSOR)(state)
    : [];

  return {
    eventId,
    projectId,
    folderId: sponsorFolderIds[0],
    hasPermissions: {
      [PERMISSIONS.EVENT_SPONSOR]: hasPermission(PERMISSIONS.EVENT_SPONSOR)(state),
      [PERMISSIONS.SPONSOR_CREATIVE]: hasPermission(PERMISSIONS.SPONSOR_CREATIVE)(state),
      [PERMISSIONS.EVENT_SPONSOR_REPORT]: hasPermission(PERMISSIONS.EVENT_SPONSOR_REPORT)(state),
    },
    hasEventSponsorshipFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP,
    )(state),
    isCreatingBrandingFolder: isLoadingSelector(LOADINGS.FOLDER.ADD)(state),
    projectFolderTypesLinks: projectId ? projectFolderTypesLinksSelector(projectId)(state) : undefined,
    allSponsorCategoryIds: sponsorCategoryIdsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFolderFiles: (projectId, folderId) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
    createFolder: (projectId) => {
      dispatch(addSponsorOrBrandingFolder(dispatch, projectId, FolderService.FOLDER_TYPES.SPONSOR));
    },
    showAllSponsorFiles: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.FILES_MODAL, true));
    },
    getProjectSponsorsStatsData: (projectId) => {
      dispatch(getProjectSponsorsStatsData(dispatch, projectId));
    },
    fetchEventSponsorCategoryData: () => {
      dispatch(fetchEventSponsorCategoryData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorLayout);
