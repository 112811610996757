import { EventsService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  eventsListEventIdsSelector,
  fetchListEventsData,
} from '../../calendar-and-event-page/events-list/EventsListEventsDataDuck';
import CRMDashboardEventsListPage from './CRMDashboardEventsListPage';

function mapStateToProps(state, ownProps) {
  const { filters } = ownProps.match.params;

  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
  const { sort_by: sortBy = EventsService.EVENT_SORT_BY.CREATED_AT } = filterObject;

  return {
    isLoading: isLoadingSelector(LOADINGS.CRM.LIST_EVENTS_FETCH, true)(state),
    eventIds: eventsListEventIdsSelector(sortBy as EventsService.EVENT_SORT_BY)(state),
    filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEvents: (filters) => {
      dispatch(fetchListEventsData(dispatch, filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMDashboardEventsListPage);
