import { FileService } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, BrowserUrlUtils, ErrorTracker } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { isArray } from 'lodash';
import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { toastMessage } from '../../reducers/ToastStore';
import { entitiesDataSelector } from '../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('FOLDER_FILES', false);

export const setFilesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addFilesData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateFileMetaData = createAction(getActionType('DATA_META', 'UPDATE'), (dispatch, id, data) => ({ id, data }));

export const resetFilesToFolder = createAction(getActionType('DATA', 'RESET'));

export const removeFile = createAction(getActionType('DATA', 'REMOVE'), (dispatch, imageId) => imageId);

export const updateFileMeta = createAction(
  getActionType('META', 'UPDATE'),
  (
    dispatch,
    options: {
      projectId: string;
      folderId: string;
      fileId: string;
    },
    formResponse: FormResponseType & {
      data: Partial<FileService.FileMetaType>;
    },
  ) => {
    const { projectId, folderId, fileId } = options;
    const { data } = formResponse;
    const loadingKey = LOADINGS.FILE.UPDATE_META(fileId);

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    function getArray(value) {
      if (!value) return null;
      if (isArray(value)) return value;
      return [value];
    }
    FileService.updateFileMeta(projectId, folderId, fileId, {
      ...data,
      key_people: getArray(data.key_people),
      tags: getArray(data.tags),
      product_link: BrowserUrlUtils.getURLWithProtocol(data.product_link),
    })
      .then((response) => {
        toastMessage('success', 'File saved', 100);
        dispatch(updateFileMetaData(dispatch, fileId, response.meta));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('FILE_META_UPDATE', e);
      });
  },
);

type FilesStateType = {
  items: IMap<string, FileService.FolderFileType>;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [resetFilesToFolder.toString()]: (state) => ({
      ...state,
      items: IMap({}),
    }),
    [setFilesData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addFilesData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeFile.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.delete(action.payload),
    }),
    [updateFileMetaData.toString()]: (state, action: { payload }) => {
      const { id, data } = action.payload;
      return {
        ...state,
        items: state.items.update(id, (item: any) => ({
          ...item,
          meta: Object.assign(item.meta, data),
        })),
      };
    },
  },
  initialState,
);

const filesDataSelector = createSelector(entitiesDataSelector, (entities) => entities.files as FilesStateType);

export const filesEntitiesDataSelector = createSelector(filesDataSelector, (state) => state.items);
export const filesSelector = createSelector(filesEntitiesDataSelector, (items) => items.toJSON());
export const filesInProcessingSelector = createSelector(filesEntitiesDataSelector, (items) =>
  items
    .filter((item) => item.asset_upload_status && item.asset_upload_status !== FileService.FILE_UPLOAD_STAGES.DONE)
    .valueSeq()
    .toArray(),
);

export const filesItemDataSelector = (fileId) =>
  createSelector(filesEntitiesDataSelector, (items) => items.get(fileId));

export const filesArraySortedByRankSelector = createSelector(filesEntitiesDataSelector, (items) =>
  items
    .sort((a, b) => {
      if (a.rank && b.rank) {
        return a.rank - b.rank;
      }
      return 0;
    })
    .valueSeq()
    .toArray(),
);

export const filesArrayIdsSortedByRankSelector = createSelector(filesEntitiesDataSelector, (items) =>
  items
    .sort((a, b) => {
      if (a.rank && b.rank) {
        return a.rank - b.rank;
      }
      return 0;
    })
    .keySeq()
    .toArray(),
);

export const filesArrayIdsSortedByRankForNavigationSelector = (fileId: string) =>
  createSelector(filesArrayIdsSortedByRankSelector, (items) => {
    const currentLocation = items.indexOf(fileId);
    const totalFiles = items.length;
    const nextFileId = items[currentLocation + 1];
    const previousFileId = items[currentLocation - 1];

    return {
      nextFileId,
      previousFileId,
      totalFiles,
    };
  });

export const sortedFileIdsByFolderCreatedAt = createSelector(filesEntitiesDataSelector, (items) =>
  items.sort((a, b) => {
    if (a.created_at && b.created_at) {
      return b.created_at - a.created_at;
    }
    return 0;
  }),
);

export const filesArrayIdsGroupedByFolderIdSelector = createSelector(
  sortedFileIdsByFolderCreatedAt,
  (items) =>
    items
      .keySeq()
      .groupBy((item) => items.get(item)?.folder_id)
      .toJS() as Record<string, Array<string>>,
);

const flatFileIdsForGroupedByFolder = createSelector(filesArrayIdsGroupedByFolderIdSelector, (items) =>
  Object.values(items).flat(),
);

export const filesArrayIdsSortedByFolderIdForNavigationSelector = (fileId: string) =>
  createSelector(flatFileIdsForGroupedByFolder, (items) => {
    const currentLocation = items.indexOf(fileId);
    const totalFiles = items.length;
    const nextFileId = items[currentLocation + 1];
    const previousFileId = items[currentLocation - 1];

    return {
      nextFileId,
      previousFileId,
      totalFiles,
    };
  });
