import { APIURLService, ClientSettingsService } from '@premagic/core';
import { HttpUtils } from '@premagic/utils';
import MESSAGES from '../../../../../common/Messages';

/*
We have listed all the notification here
https://www.notion.so/pmagic/Premagic-All-Notification-a52dcd0ac5d74a67bc95ef8d22bed7b8
*/

export type NotificationSettingsType = ClientSettingsService.ClientNotificationSettingsType;

export enum NOTIFICATIONS {
  SHARE_FOLDER_WHATSAPP = 'share_on_whatsapp',
  SHARE_FOLDER_EMAIL = 'share_on_email',
}

export type ToggleNotifications = {
  toggleable: true;
  key: NOTIFICATIONS;
};

export type UnToggleNotifications = {
  toggleable: false;
  value: boolean;
};

export const NOTIFICATION_LIST: Array<{
  title: string;
  channelWhatApp: ToggleNotifications | UnToggleNotifications;
  channelEmail: ToggleNotifications | UnToggleNotifications;
}> = [
  {
    title: `Notify your clients when a new Proposal, Signature, Selection & ${MESSAGES.CLIENT_WEBSITE.NAME} is created`,
    channelWhatApp: {
      toggleable: true,
      key: NOTIFICATIONS.SHARE_FOLDER_WHATSAPP,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
  {
    title: 'Remind your clients for selecting photos on Selection folders every week for 4 months',
    channelWhatApp: {
      toggleable: false,
      value: false,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
  {
    title: 'Notify when client request for photo selection again',
    channelWhatApp: {
      toggleable: false,
      value: false,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
  {
    title: 'Notify when client completes photo selection on a folder',
    channelWhatApp: {
      toggleable: false,
      value: true,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
  {
    title: 'Notify when you receive a new lead',
    channelWhatApp: {
      toggleable: false,
      value: false,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
  {
    title: 'Notify assigned team members about upcoming events every week',
    channelWhatApp: {
      toggleable: false,
      value: true,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
  {
    title: 'Notify client when mark payment as paid',
    channelWhatApp: {
      toggleable: false,
      value: true,
    },
    channelEmail: {
      toggleable: false,
      value: true,
    },
  },
];

export function saveNotificationsSettingsService(data: NotificationSettingsType) {
  return HttpUtils.post(APIURLService.API_URLS.ACCOUNT_CLIENT_SETTINGS.ROOT, { notifications: data })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}
