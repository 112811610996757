import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { SponsorCreativesService } from '@premagic/core';

import SponsorFilesSelectFileForCreativeModal from './SponsorFilesSelectFileForCreativeModal';
import { LOADINGS } from '../../../../../../common/Constants';

const COMMON_KEY = LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL;
function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  return {
    projectId,
    show: ModalDuck.isModalOpenSelector(COMMON_KEY)(state),
    modalOptions: ModalDuck.modalOptionsSelector<
      Record<
        string,
        {
          name: string;
          fileId?: string;
          placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
        }
      >
    >(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelect: (name, fileId) => {
      dispatch(
        ModalDuck.setModalOptions(dispatch, COMMON_KEY, {
          [name]: {
            name,
            fileId,
          },
        }),
      );
    },
    hideModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, COMMON_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorFilesSelectFileForCreativeModal);
