import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import ClientWebsiteItineraryLayout from './ClientWebsiteItineraryLayout';
import {
  clientWebsiteItineraryGroupedSelector,
  clientWebsiteTotalItinerarySelector,
} from './ClientWebsiteItineraryDataDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;
  return {
    id,
    projectId,
    groupItineraryIds: clientWebsiteItineraryGroupedSelector(state),
    totalItems: clientWebsiteTotalItinerarySelector(state),
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.FETCH_SINGLE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddItinerary: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_ADD_ITINERARY_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteItineraryLayout);
