import { useRef, useState } from 'react';
import { EventPosterService } from '@premagic/core';
import { BUTTON_ICONS_SIZES, ButtonIcon, Dialog, Icon, ICON_SIZES, Space } from '@premagic/myne';
import EventPosterListContainer from '../../crm/event-posters/EventPosterListContainer';

type Props = {
  eventId: string;
  type: EventPosterService.EVENT_POSTER_TYPE;
  activePosterId?: string;
  projectId: string;
};

export default function EventPosterSwitcher(props: Props) {
  const { eventId, activePosterId, projectId, type } = props;
  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Space size={1} />
      <ButtonIcon
        title="Change"
        tooltipPlacement="bottom"
        size={BUTTON_ICONS_SIZES.SM}
        onClick={() => setShowDialog(true)}
        ref={$button}
      >
        <Icon name="chevron_down" size={ICON_SIZES.SM} />
      </ButtonIcon>
      <Dialog onClose={() => setShowDialog(false)} target={$button.current} show={showDialog} hasPadding={false}>
        <Space size={2}>
          <EventPosterListContainer
            eventId={eventId}
            projectId={projectId}
            type={type}
            listStyle="mini-item"
            focusId={activePosterId}
          />
        </Space>
      </Dialog>
    </>
  );
}
