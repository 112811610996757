import { EventTrackerService, FileService, FolderService } from '@premagic/core';
import {
  ButtonCircle,
  ButtonIcon,
  COLOR_SHADES,
  Col,
  Icon,
  MediaModal,
  MediaModalContent,
  MediaModalContentLeftCol,
  MediaModalContentRightCol,
  MediaModalHeader,
  MediaModalHeaderActions,
  MediaModalNavigator,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { GlobalHotKeys } from 'react-hotkeys';
import APP_URLS from '../../../../services/AppRouteURLService';
import ImagesMetaFormContainer from '../../image-meta-form/ImagesMetaFormContainer';
import FolderImageFullViewContainer from './FolderImageFullViewContainer';

type Props = {
  id: string;
  folder: FolderService.FolderType;
  file: FileService.FolderFileType;
  view: 'approved' | 'all' | 'rejected';
  nextFileId: string;
  previousFileId: string;
  projectId: string;
  folderId: string;
  deleteFile: (
    projectId: string,
    folderId: string,
    fileId: string,
    folderType: FolderService.FOLDER_TYPES,
    navigateToUrl: string,
  ) => void;
  navigate: (url) => void;
  isDeleting: boolean;
};

export default function FolderImagesPopup(props: Props) {
  const { file, nextFileId, previousFileId, deleteFile, projectId, folderId, folder, view, navigate, isDeleting, id } =
    props;

  const fileName = decodeURI(file?.image_name);

  const keyMap = {
    previous: 'left',
    next: 'right',
  };

  function close() {
    const fileUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.DETAILS, {
      projectId,
      folderId,
      folderType: folder.folder_type,
    });

    navigate(fileUrl);
  }

  function handleDelete() {
    const navigateToUrl = nextFileId
      ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.FILE_VIEW, {
          folderType: folder.folder_type,
          projectId,
          folderId,
          view,
          id: nextFileId,
        })
      : BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.DETAILS, {
          projectId,
          folderId,
          folderType: folder.folder_type,
        });
    deleteFile(projectId, folderId, id, folder.folder_type, navigateToUrl);
  }

  function navigateToFile(fileId: string) {
    const fileUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.FOLDER.FILE_VIEW, {
      folderType: folder.folder_type,
      projectId,
      folderId,
      view,
      id: fileId,
    });
    navigate(fileUrl);
  }

  function showNextImage() {
    if (nextFileId) {
      navigateToFile(nextFileId);
    }
  }

  function showPreviousImage() {
    if (previousFileId) {
      navigateToFile(previousFileId);
    }
  }

  const handlers = {
    previous: () => showPreviousImage(),
    next: () => showNextImage(),
  };

  if (!id) return null;
  const downloadLink = file?.asset_type === FileService.ASSET_TYPES.VIDEO ? file?.fallback_url : file?.location;

  return (
    <MediaModal show close={close}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <MediaModalHeader>
        <Col>
          <Text ellipsis>{fileName}</Text>
        </Col>
        <MediaModalHeaderActions>
          <ButtonIcon title="Delete image" onClick={handleDelete} isLoading={isDeleting}>
            <Icon name="trash" />
          </ButtonIcon>
          <Space size={4} />
          <a href={downloadLink} download={fileName} title={fileName} rel="noopener noreferrer" target="_blank">
            <ButtonIcon
              title="Download"
              onClick={() => EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FILE.DOWNLOAD)}
            >
              <Icon name="download" />
            </ButtonIcon>
          </a>
          <Space size={2} />
          <ButtonIcon onClick={close} title="Close">
            <Icon name="x" />
          </ButtonIcon>
        </MediaModalHeaderActions>
      </MediaModalHeader>
      <MediaModalContent>
        <MediaModalContentLeftCol>
          <FolderImageFullViewContainer id={id} />
          <MediaModalNavigator>
            <ButtonCircle onClick={showPreviousImage} disabled={!previousFileId}>
              <Icon name="arrow_left" />
            </ButtonCircle>
          </MediaModalNavigator>
          <MediaModalNavigator isRight>
            <ButtonCircle onClick={showNextImage} disabled={!nextFileId}>
              <Icon name="arrow_right" />
            </ButtonCircle>
          </MediaModalNavigator>
        </MediaModalContentLeftCol>
        <MediaModalContentRightCol>
          <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
            {fileName}
          </Text>
          <Space vertical />
          <ImagesMetaFormContainer id={id} folderId={folderId} projectId={projectId} />
        </MediaModalContentRightCol>
      </MediaModalContent>
    </MediaModal>
  );
}
