import React, { useEffect, useState } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Divider,
  Emoji,
  EMOJIS,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  Position,
  PreventMarginCollapse,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ClientSettingsService, CompanyService, PricingPlanService } from '@premagic/core';

import PaymentDoneModalContainer from './PaymentDoneModalContainer';
import HaveAQuestion from '../../help/HaveAQuestion';
import APP_URLS from '../../../services/AppRouteURLService';
import CurrentSubscriptionsInfoContainer from './CurrentSubscriptionsInfoContainer';
import { PricingPlan } from '../pricing-plan/PricingPlan';
import SubscriptionsPlansContainer from './SubscriptionsPlansContainer';

type Props = {
  initData: (
    company: CompanyService.CompanyType,
    clientSettings: ClientSettingsService.ClientSettingsType,
    userId: string,
  ) => void;
  company: CompanyService.CompanyType;
  isLoading: boolean;
  isMobileUp: boolean;
  clientSettings: ClientSettingsService.ClientSettingsType;
  isCompanyTypeNotPhotographer: boolean;
  userId: string;
};

export default function SubscriptionsPage(props: Props): JSX.Element {
  const { initData, company, isLoading, isMobileUp, clientSettings, isCompanyTypeNotPhotographer, userId } = props;

  useEffect(() => {
    initData(company, clientSettings, userId);
  }, [company, clientSettings, userId]);

  return (
    <InnerPage>
      <InnerPageHeader iconName="box" title="Subscriptions" backTo={APP_URLS.SETTINGS.INDEX}>
        Subscriptions
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <CurrentSubscriptionsInfoContainer />

        <Space vertical size={16} />
        <SubscriptionsPlansContainer />

        {/* <ReferralCodeContainer /> */}

        <Space vertical size={8} />
        {isMobileUp && (
          <PreventMarginCollapse>
            <Row>
              <Col size={6}>
                <HaveAQuestion />
              </Col>
              <Col size={4} rightAlighed>
                <Text muted boldness={TEXT_BOLDNESS.BOLD} block>
                  15 day money back guarantee.
                </Text>
                <Text muted>If not convinced in the first 15 days, we can refund you 100%</Text>
              </Col>
            </Row>
          </PreventMarginCollapse>
        )}
      </InnerPageContent>
      <PaymentDoneModalContainer />
    </InnerPage>
  );
}
