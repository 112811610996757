import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import HighlightDesignEndingTextEditor from './HighlightDesignEndingTextEditor';
import { hasFeatureSelector } from '../../../../../../settings/company/CompanyDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    onChange?: (value: string) => void;
    galleryEndingText?: string;
    name: string;
  },
) {
  const { onChange, galleryEndingText, name } = ownProps;
  return {
    name,
    onChange,
    galleryEndingText,
    isEndingTextEditorEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignEndingTextEditor);
