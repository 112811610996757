import { ErrorTracker, HttpUtils, MegaFileUploadService, PhotoWatermarkUtils, SimpleDateUtils } from '@premagic/utils';
import { API_URLS, UrlServiceFolderType } from './APIURLService';
import { FolderFileType } from './FileService';
import { PROJECT_META_TYPES } from './entity-meta/ProjectMetaService';

// Clone from ProjectService to avoid circular dependency
const SystemProject = {
  id: 'SYSTEM',
};

export enum FOLDER_STATUS {
  DRAFT = 'DRAFT',
  INCOMPLETE = 'INCOMPLETE',
  WAITING_FOR_APPROVAL = 'REVIEW',
  REVIEW_COMPLETED = 'REVIEW_COMPLETED',
  DONE = 'DONE',
  ARCHIVE = 'ARCHIVE',
}

export enum FOLDER_TYPES {
  SELECTION = 'SELECTION',
  HIGHLIGHT = 'HIGHLIGHT',
  CLIENT_WEBSITE = 'SAVE_THE_DATE',
  PROPOSAL = 'PROPOSAL',
  PORTFOLIO = 'PORTFOLIO',
  BRANDING = 'BRANDING',
  SPONSOR = 'SPONSOR',
}

const URL_FOLDER_DETAILS = API_URLS.FOLDER.DETAILS;
const URL_SYSTEM_FOLDER_DETAILS = (id: string, folderType: FOLDER_TYPES) =>
  API_URLS.FOLDER.SYSTEM_FOLDER_DETAILS(folderType as UrlServiceFolderType, id);

export type FolderClientType = {
  name: string;
  phone_number: string;
  email: string;
  isNew?: boolean;
};
export type NewFolderType = {
  folder_name: string;
  folder_type: FOLDER_TYPES;
};

export type FolderTypeStats = {
  accepted: number;
  rejected: number;
  unreviewed: number;
  total_image: number;
  total_video?: number;
};

export type FolderType = {
  folder_id: string;
  folder_type: FOLDER_TYPES;
  status: FOLDER_STATUS;
  folder_name: string;
  created_at: string;
  updated_at: string;
  files?: Record<string, string>;
  share_url?: string;
  guest_share_url?: string;
  share_code?: string;
  is_shared?: boolean;
  stats: FolderTypeStats;
  meta?: {
    cover_image: FolderFileType;
    cover_image_focal_point?: {
      x: number;
      y: number;
    };
    block_folder?: boolean;
    is_folder_public?: boolean;
  };
  is_system_folder: boolean;
  expiry_date?: string;
  clients: Record<string, FolderClientType>;
  share?: {
    guest_share: string;
    human_code: string;
  };
};
export type BrandingFolderDataType = {
  [PROJECT_META_TYPES.SELECTION_ENABLE_WATERMARK]?: boolean;
  [PROJECT_META_TYPES.HIGHLIGHT_ENABLE_WATERMARK]?: boolean;
  [PROJECT_META_TYPES.WATERMARK_POSITION]?: PhotoWatermarkUtils.WATERMARK_POSITIONS;
  [PROJECT_META_TYPES.WATERMARK_SIZE]?: PhotoWatermarkUtils.WATERMARK_SIZES;
  [PROJECT_META_TYPES.WATERMARK_OPACITY]?: number;
  [PROJECT_META_TYPES.WATERMARK_FILE]?: string;
};
export type BrandingFolderType = {
  folder_id: string;
  folder_type: FOLDER_TYPES.BRANDING;
  folder_name: string;
  created_at: string;
  updated_at: string;
  files?: Record<string, string>;
  is_system_folder: boolean;
  data: BrandingFolderDataType;
};

export const FOLDER_TYPE_DETAILS: Record<
  FOLDER_TYPES,
  {
    title: string;
    shortTitle?: string;
    icon: string;
  }
> = {
  [FOLDER_TYPES.SELECTION]: {
    title: 'Selection',
    icon: 'selection_album',
  },
  [FOLDER_TYPES.HIGHLIGHT]: {
    title: 'Signature album',
    shortTitle: 'Signature',
    icon: 'signature_album',
  },
  [FOLDER_TYPES.CLIENT_WEBSITE]: {
    title: 'Micro-site',
    icon: 'feather',
  },
  [FOLDER_TYPES.PORTFOLIO]: {
    title: 'Portfolio',
    icon: 'feather',
  },
  [FOLDER_TYPES.PROPOSAL]: {
    title: 'Proposal',
    icon: 'feather',
  },
  [FOLDER_TYPES.SPONSOR]: {
    title: 'Sponsor',
    icon: 'feather',
  },
  [FOLDER_TYPES.BRANDING]: {
    title: 'Branding',
    icon: 'feather',
  },
};

export const FOLDER_STATUS_INFO = {
  [FOLDER_STATUS.DONE]: {
    title: 'Review Completed',
    type: 'success',
  },
  [FOLDER_STATUS.REVIEW_COMPLETED]: {
    title: 'Review Completed',
    type: 'success',
  },
  [FOLDER_STATUS.WAITING_FOR_APPROVAL]: {
    title: 'In Review',
    type: 'warning',
  },
  [FOLDER_STATUS.DRAFT]: {
    title: 'Draft',
    type: 'default',
  },
  [FOLDER_STATUS.ARCHIVE]: {
    title: 'Archived',
    type: 'default',
  },
};

export function createFolder(projectId: string, data: NewFolderType): Promise<FolderType> {
  const URL = API_URLS.FOLDER.ROOT(projectId);

  return HttpUtils.post(URL, data)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function createSystemFolder(data: NewFolderType): Promise<FolderType> {
  const URL = API_URLS.FOLDER.SYSTEM_FOLDER(data.folder_type as UrlServiceFolderType);

  return HttpUtils.post(URL, data)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function fetchSystemFolders<T extends FolderType>(folderType: FOLDER_TYPES): Promise<T> {
  const URL_SYSTEM_FOLDERS = API_URLS.FOLDER.SYSTEM_FOLDER(folderType as UrlServiceFolderType);

  return HttpUtils.get(URL_SYSTEM_FOLDERS)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function fetchFolder<T extends FolderType>(
  projectId: string,
  folderId: string,
  folderType = FOLDER_TYPES.PROPOSAL,
): Promise<T> {
  if (projectId === SystemProject.id) {
    return HttpUtils.get(URL_SYSTEM_FOLDER_DETAILS(folderId, folderType))
      .then(({ data }) => data)
      .catch((error) => Promise.reject(error.data));
  }

  return HttpUtils.get(URL_FOLDER_DETAILS(projectId, folderId))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function deleteSystemFolder(folderType: FOLDER_TYPES, id: string): Promise<FolderType> {
  const URL = API_URLS.FOLDER.SYSTEM_FOLDER_DETAILS(folderType as UrlServiceFolderType, id);

  return HttpUtils.httpDelete(URL)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function getProjectStatusFromFolders(folders: Array<FolderType>): FOLDER_STATUS {
  return folders.reduce((result: FOLDER_STATUS, folder) => {
    if (result === FOLDER_STATUS.DRAFT || folder.status === FOLDER_STATUS.DRAFT) return FOLDER_STATUS.DRAFT;

    if (result === FOLDER_STATUS.WAITING_FOR_APPROVAL) return result;

    return folder.status || FOLDER_STATUS.DRAFT;
  }, FOLDER_STATUS.DRAFT);
}

export function uploadSingleFileToFolder(projectId: string, folderId: string, file: FormData): Promise<any> {
  const URL = API_URLS.SIMPLE_UPLOADER.ROOT;
  const data = {
    projectId,
    folderId,
    // folderName,
    // folderType,
  };
  return HttpUtils.post(URL, file).then((response) => response.data);
}

export function addInitialInfoFilesToFolder(
  files: Array<File>,
  options: {
    projectId: string;
    folderId: string;
  },
): Promise<void> {
  const url = API_URLS.MEGA_UPLOADER.META;
  const fileNames = files.map((file) => file.name);

  return HttpUtils.post(url, {
    files: fileNames,
    project_id: options.projectId,
    folder_id: options.folderId,
  })
    .then((response) => response.data)
    .catch((response) => {
      ErrorTracker.showErrorToUser(response);
      return Promise.reject(response.data);
    });
}

export function addFilesToFolder(
  files: Array<File>,
  options: {
    projectId: string;
    folderId: string;
  },
  callbacks: MegaFileUploadService.CallbackOptionsType,
): void {
  const { projectId, folderId } = options;
  const url = API_URLS.MEGA_UPLOADER.ROOT;
  MegaFileUploadService.initializeUploader(
    {
      url,
    },
    callbacks,
  );
  MegaFileUploadService.addFilesToUploader(files, { project_id: projectId, folder_id: folderId });
}

export function deleteFolder(projectId: string, folderId: string): Promise<void> {
  const URL = API_URLS.FOLDER.DELETE(projectId, folderId);

  return HttpUtils.put(URL, {}).then(() => {});
}

export function updateFolderName(projectId: string, folderId: string, folderName: string): Promise<FolderType> {
  const URL = API_URLS.FOLDER.RENAME(projectId, folderId);

  return HttpUtils.put(URL, { folderName }).then((response) => response.data);
}

export function updateSystemFolderData<T extends FolderType | BrandingFolderType>(
  folderType: FOLDER_TYPES,
  folderId: string,
  data,
): Promise<T> {
  const URL = API_URLS.FOLDER.SYSTEM_FOLDER_DETAILS(folderType as UrlServiceFolderType, folderId);

  return HttpUtils.put(URL, { data }).then((response) => response.data);
}

export function sortFoldersBytDate(aFolderId: string, bFolderId: string, folders: Record<string, FolderType>): number {
  const aFolderCreatedAt = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(folders[aFolderId].created_at);
  const bFolderCreatedAt = SimpleDateUtils.getTimestampFromUglyBackendDateFormat(folders[bFolderId].created_at);
  return aFolderCreatedAt - bFolderCreatedAt;
}

type ShareInfoType = {
  lock?: boolean;
  mpin?: number;
  share_code: string;
  expiry: number;
  share_url: string;
  status: FOLDER_STATUS;
  folder_status: Record<string, { share_url: string; status: FOLDER_STATUS }>;
};

export function shareFolderWithClientService(
  projectId: string,
  folderIds: string[],
  data: {
    clients: Array<{ name: string; phone_number: string; email: string }>;
    expiry_date?: string;
    email_content?: string;
    skip_notifications?: boolean;
  },
): Promise<ShareInfoType> {
  const URL = API_URLS.FOLDER.SHARE();
  const formData = new FormData();

  formData.append('projectId', projectId);
  formData.append('folder_id_list', folderIds.join(','));

  formData.append('clients', JSON.stringify(data.clients));

  if (data.expiry_date) formData.append('expiry_date', data.expiry_date);
  if (data.email_content) formData.append('email_content', data.email_content);
  if (data.skip_notifications) formData.append('skip_notifications', String(data.skip_notifications));

  // @ts-ignore
  return HttpUtils.post(URL, formData, true).then((res: { data: ShareInfoType }) => ({
    ...res.data,
    mpin: Number(res.data.mpin),
  }));
}

export function toggleReviewStatus(projectId: string, foldersIds: Array<string>, status: FOLDER_STATUS) {
  const urls = foldersIds.map((folderId) => API_URLS.FOLDER.DETAILS(projectId, folderId));
  const formData = new FormData();
  formData.append('status', status);
  const requests = urls.map((url) => HttpUtils.put(url, formData));
  return Promise.all(requests);
}
