import React, { useState } from 'react';
import { FolderService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Col,
  EscapedParagraphHTMLText,
  FormGroup,
  Icon,
  ICON_SIZES,
  RichTextArea,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import FolderShareChannelInfoContainer from './FolderShareChannelInfoContainer';
import { AccountEmailTemplatesType } from '../../../acccount/AccountService';

type Props = {
  folderType: FolderService.FOLDER_TYPES;
  variables: {
    // is not getting used now
    clientName: string;
    senderName: string;
    companyName: string;
  };
  accountEmailTemplates: AccountEmailTemplatesType;
  hasCustomCommunicationFeatures: boolean;
};
const PRESET_VARIABLES = [
  { label: 'Client Name', id: 'clientName' },
  {
    label: 'Sender name',
    id: 'senderName',
  },
];

export default function FolderShareEmailSelector(props: Props) {
  const { folderType, variables, accountEmailTemplates, hasCustomCommunicationFeatures } = props;
  const emailTemplate = accountEmailTemplates[folderType] as { body: string; subject: string };
  const [content, setContent] = useState(emailTemplate.body);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const contentWithVariables = StringUtils.replaceVariablesInString(content || '', variables);
  if (!hasCustomCommunicationFeatures) return <FolderShareChannelInfoContainer showSettingsLink />;

  return (
    <div>
      <FormGroup>
        <Row>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Email content</Text>
          <Col rightAlighed size={null}>
            <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={() => setShowEdit(!showEdit)}>
              <Icon size={ICON_SIZES.SM} name={showEdit ? 'eye' : 'edit_2'} />
              <Space size={1} />
              {showEdit ? 'Preview' : 'Edit'}
            </Button>
          </Col>
        </Row>
        <Space size={1} vertical />
        <Card style={CARD_STYLES.SECONDARY}>
          <Space size={4}>
            {showEdit ? (
              <RichTextArea name="email_content_edit" onChange={(value) => setContent(value)} defaultValue={content} />
            ) : (
              <Text>
                <EscapedParagraphHTMLText value={contentWithVariables} />
              </Text>
            )}
            <Space vertical size={2} />
            <Row center>
              <Button size={BUTTON_SIZE.SM}>View Your Proposal</Button>
            </Row>
            <Space vertical size={2} />
            <Text>Feel free to reach out if you have any questions. Looking forward to capturing your big day.</Text>
            <Space vertical size={2} />
            <Text block>Best regards,</Text>
            <Text>{variables.companyName}</Text>
          </Space>
        </Card>
      </FormGroup>
      <input type="hidden" value={content} name="email_content" />
      <FolderShareChannelInfoContainer showSettingsLink />
    </div>
  );
}
