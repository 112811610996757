import { connect } from 'react-redux';
import CRMClientSummary from './CRMClientSummary';
import { clientItemsSelector } from '../ClientsDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { hasPermission } from '../../users/UsersDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { isFeatureLeadEnabledSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { clientId: string }) {
  const { clientId } = ownProps;

  return {
    clientId,
    client: clientItemsSelector(state)[clientId] || {},
    hasClientManagementPermission: hasPermission(PERMISSIONS.EVENT_CLIENT_MANAGEMENT)(state),
    hasLeadsModule: isFeatureLeadEnabledSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditClientDetails: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_CLIENTS.SHOW_EDIT_PANEL, true));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CRMClientSummary);
