import { connect } from 'react-redux';
import EventAttendeeFaceCheckinStats from './EventAttendeeFaceCheckinStats';

function mapStateToProps(state, ownProps) {
  const { eventId, projectId } = ownProps.match.params;

  return {
    eventId,
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceCheckinStats);
