import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  FormGroup,
  FormLabel,
  IMAGE_V2_STYLES,
  ImageV2,
  Row,
  Space,
  FileContainer,
} from '@premagic/myne';
import { FileService } from '@premagic/core';

type Props = {
  name: string;
  defaultValue?: string;
  openFilePicker: (name: string) => void;
  modalOptions?: Record<
    string,
    {
      name: string;
      fileId?: string;
    }
  >;
  files: Record<string, FileService.FolderFileType>;
  fileType?: 'watermark';
};

export default function BrandingFilesSelector(props: Props): JSX.Element | null {
  const { openFilePicker, modalOptions = {}, name, defaultValue, files, fileType = 'watermark' } = props;

  const selectedFileId = modalOptions?.[name]?.fileId || defaultValue;
  const selectedFile = selectedFileId ? files[selectedFileId] : undefined;

  return (
    <FormGroup>
      <FormLabel info="Max file size of 2MB & Min size of 512px in height and width">Watermark</FormLabel>
      <Row vcenter>
        <FileContainer status={selectedFile?.asset_upload_status}>
          {selectedFile ? (
            <ImageV2
              src={selectedFile.dynamic_image_url}
              alt={selectedFile.image_name}
              width={150}
              height={150}
              style={IMAGE_V2_STYLES.THUMBNAIL}
            />
          ) : (
            <Button onClick={() => openFilePicker(name)} style={BUTTON_STYLES.LINK}>
              {selectedFile ? 'Change' : 'Select a file'}
            </Button>
          )}
        </FileContainer>
        <Space />
        {selectedFile && (
          <div>
            <Button onClick={() => openFilePicker(name)} style={BUTTON_STYLES.LINK}>
              Change
            </Button>
          </div>
        )}
        <input type="hidden" name={name} value={selectedFileId} readOnly />
      </Row>
    </FormGroup>
  );
}
