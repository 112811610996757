import { ClientAppServices, EventAttendeesService, EventPosterService } from '@premagic/core';

import { Button, BUTTON_STYLES, Icon, ICON_SIZES, Space } from '@premagic/myne';
import React, { useMemo } from 'react';

type Props = {
  id: string;
  attendee: EventAttendeesService.EventAttendeeType;
  magazinePages: Array<EventPosterService.EventPosterType>;
  guestGalleryPreviewLink?: string;
};

export default function EventAttendeeLinkToMagazineButton(props: Props): JSX.Element | null {
  const { attendee, id, magazinePages, guestGalleryPreviewLink } = props;
  const { best_face_id: bestFaceId, face_id: faceId } = attendee;

  const magazineUrl = useMemo(() => {
    if (guestGalleryPreviewLink && (bestFaceId || faceId))
      return ClientAppServices.getPeopleMagazineURL(guestGalleryPreviewLink, bestFaceId || faceId);
    return null;
  }, [bestFaceId, faceId, guestGalleryPreviewLink]);

  if (guestGalleryPreviewLink && magazinePages.length > 0 && faceId)
    return (
      <Button style={BUTTON_STYLES.LINK} link={magazineUrl} isExternalLink>
        <Icon name="external_link" size={ICON_SIZES.SM} />
        <Space size={1} />
        Magazine
      </Button>
    );
  return null;
}
