import { connect } from 'react-redux';
import { isModalOpenSelector, modalsSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import EditSocialMessageModal from './EditSocialMessageModal';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  const modals = modalsSelector(state);
  const modalInfo = Object.entries(modals.toJSON()).find(
    ([key, value]) => key.includes('@project-meta/show-edit-social-message-modal') && value == true,
  );
  const id = modalInfo && modalInfo[0]?.split('--')[1];

  return {
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    show: isModalOpenSelector(LOADINGS.PROJECT_META.SHOW_EDIT_SOCIAL_MESSAGE_MODAL(id))(state),
    socialShareMessages: projectMeta?.social_share_messages,
    socialMessageItemForEdit: id ? projectMeta?.social_share_messages?.[id] : undefined,
    error: errorObjectForTypeSelector(LOADINGS.PROJECT_META.UPDATE)(state),
    messageIdToEdit: id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEdit: (id) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_EDIT_SOCIAL_MESSAGE_MODAL(id), false));
    },
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSocialMessageModal);
