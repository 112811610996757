import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { PixelService, ProjectService, SponsorCreativesService } from '@premagic/core';
import { ActionTypeUtils, ArrayUtils, NumberUtils } from '@premagic/utils';
import { entitiesDataSelector, EntitiesStateType } from '../../../reducers/selectors';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { sponsorCreativeEntitiesSelector } from '../../crm/events/event-sponsor-creatives/SponsorCreativeDataDuck';
import { eventSponsorEntitiesSelector } from '../../crm/events/event-sponsors/EventSponsorDataDuck';
import { getROIForSponsor } from '../../crm/events/event-report/EventReportService';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_STATS');

const setProjectStatsData = createAction(getActionType('DATA', 'SET'), (dispatch, projectId, data) => ({
  [projectId]: data,
}));

const setProjectSponsorsStatsData = createAction(
  getActionType('DATA_SPONSERS', 'SET'),
  (dispatch, projectId, data) => ({
    [projectId]: data,
  }),
);

export const getProjectStatsData = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.PROJECT_STATS.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  ProjectService.fetchProjectStats(projectId)
    .then((response) => {
      dispatch(setProjectStatsData(dispatch, projectId, response));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const getProjectSponsorsStatsData = createAction(
  getActionType('DATA_SPONSORS', 'FETCH'),
  (dispatch, projectId: string) => {
    const loadingKey = LOADINGS.PROJECT_STATS.SPONSOR_FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProjectService.fetchProjectSponsorsStats(projectId)
      .then((response) => {
        dispatch(setProjectSponsorsStatsData(dispatch, projectId, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

type ProjectStatsItemType = IMap<string, ProjectService.ProjectDetailedStatsType>;
type ProjectSponsorsStatsItemType = IMap<string, ProjectService.ProjectSponsorsStatsType>;
type StateType = {
  items: ProjectStatsItemType;
  sponsorsItems: ProjectSponsorsStatsItemType;
};
const initialState = {
  items: IMap({}),
  sponsorsItems: IMap({}),
};

export default handleActions(
  {
    [setProjectStatsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [setProjectSponsorsStatsData.toString()]: (state, action) => ({
      ...state,
      sponsorsItems: IMap(action.payload),
    }),
  },
  initialState,
);

export const projectsStatsDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.projectsStats as StateType).items,
);
export const projectsSponsorStatsDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.projectsStats as StateType).sponsorsItems,
);
export const statsOnFolderForTypeSelector = (
  projectId: string,
  folderId: string,
  eventType: PixelService.TRACK_EVENTS,
) =>
  createSelector(projectsStatsDataSelector, (items) => {
    const project = items.get(projectId);
    if (!project) return 0;
    return project[eventType]?.[projectId]?.[folderId] || 0;
  });

function getFolderTotalFromStatsData(project?: Record<string, number>): number {
  if (!project) return 0;
  return Object.values(project).reduce((acc, curr) => acc + Number(curr), 0);
}

function getFolderTotalFromSponsorStatsData(project: Record<string, Record<string, number>>): number {
  // return Object.values(project).reduce((acc, curr) => acc + Number(curr), 0);
  return Object.values(project).reduce((acc, curr) => acc + getFolderTotalFromStatsData(curr), 0);
}

function getEmptyStats(): Record<PixelService.TRACK_EVENTS, 0> {
  return {
    [PixelService.TRACK_EVENTS.VIEW]: 0,
    [PixelService.TRACK_EVENTS.REFER]: 0,
    [PixelService.TRACK_EVENTS.SHARE]: 0,
    [PixelService.TRACK_EVENTS.LEAD]: 0,
    [PixelService.TRACK_EVENTS.DOWNLOAD]: 0,
    [PixelService.TRACK_EVENTS.BULK_DOWNLOAD]: 0,
    [PixelService.TRACK_EVENTS.EVENT_REGISTRATION]: 0,
    [PixelService.TRACK_EVENTS.FACE_SEARCH]: 0,
    [PixelService.TRACK_EVENTS.WISH]: 0,
    [PixelService.TRACK_EVENTS.IMAGE_LIKE]: 0,
    [PixelService.TRACK_EVENTS.IMAGE_DISLIKE]: 0,
    [PixelService.TRACK_EVENTS.SPONSOR_VIEWS]: 0,
    [PixelService.TRACK_EVENTS.SPONSOR_CLICKS]: 0,
    [PixelService.TRACK_EVENTS.SPONSOR_CTA_CLICKS]: 0,
  };
}

function getSponsorEmptyStats() {
  return {
    views: 0,
    clicks: 0,
    ctaClicks: 0,
    roi: 0,
  };
}

export const statsOnProjectSelector = (projectId: string) =>
  createSelector(projectsStatsDataSelector, projectsSponsorStatsDataSelector, (items, sponsorsItems) => {
    const project = items.get(projectId);
    const sponsors = sponsorsItems.get(projectId);
    let projectStats = getEmptyStats();

    if (!project && !sponsors) return projectStats;
    // Return the Record<PixelService.TRACK_EVENTS, number>
    if (project) {
      const entries = Object.entries(project) as ArrayUtils.Entries<typeof project>;
      projectStats = entries.reduce((acc, [key, value]) => {
        const totalFoldersValue = getFolderTotalFromStatsData(value[projectId]);
        return {
          ...acc,
          [key]: totalFoldersValue,
        };
      }, getEmptyStats());
    }

    if (!sponsors) return projectStats;
    const sponsorEntries = Object.entries(sponsors) as ArrayUtils.Entries<typeof sponsors>;
    const sponsorsStats = sponsorEntries.reduce((acc, [key, value]) => {
      const totalFoldersValue = getFolderTotalFromSponsorStatsData(value[projectId]);
      return {
        ...acc,
        [key]: totalFoldersValue,
      };
    }, getSponsorEmptyStats());

    return {
      ...projectStats,
      ...sponsorsStats,
    };
  });

export const statsOnSponsorsSelector = (projectId: string, sponsorId: string) =>
  createSelector(projectsSponsorStatsDataSelector, (items) => {
    const stats = items.get(projectId);
    // Return the {
    //   [PixelService.TRACK_EVENTS.SPONSOR_VIEWS]: sum of all the creatives values,
    //   [PixelService.TRACK_EVENTS.SPONSOR_CLICKS]: sum of all the creatives values,
    // }
    if (!stats) return getSponsorEmptyStats();
    const entries = Object.entries(stats) as ArrayUtils.Entries<typeof stats>;
    const totalStats = entries.reduce((acc, [key, value]) => {
      const totalFoldersValue = getFolderTotalFromStatsData(value?.[projectId]?.[sponsorId]);
      return {
        ...acc,
        [key]: totalFoldersValue,
      };
    }, getSponsorEmptyStats());

    return {
      views: totalStats[PixelService.TRACK_EVENTS.SPONSOR_VIEWS],
      clicks: totalStats[PixelService.TRACK_EVENTS.SPONSOR_CLICKS],
      ctaClicks: totalStats[PixelService.TRACK_EVENTS.SPONSOR_CTA_CLICKS],
      roi: getROIForSponsor({
        sponsorViews: totalStats[PixelService.TRACK_EVENTS.SPONSOR_VIEWS],
        sponsorClicks: totalStats[PixelService.TRACK_EVENTS.SPONSOR_CLICKS],
        sponsorCTAClicks: totalStats[PixelService.TRACK_EVENTS.SPONSOR_CTA_CLICKS],
      }),
    };
  });

export const tableStatsOnSponsorsSelector = (projectId: string) =>
  createSelector(
    sponsorCreativeEntitiesSelector,
    eventSponsorEntitiesSelector,
    projectsSponsorStatsDataSelector,
    (items, sponsors, sponsorStats) =>
      items
        .map((item) => {
          const { sponsor_id: sponsorId, id: creativeId } = item;
          const sponsor = sponsors.get(sponsorId);
          const stats = sponsorStats.get(projectId);
          const views =
            Number(stats?.[PixelService.TRACK_EVENTS.SPONSOR_VIEWS]?.[projectId]?.[sponsorId]?.[creativeId]) || 0;
          const clicks =
            Number(stats?.[PixelService.TRACK_EVENTS.SPONSOR_CLICKS]?.[projectId]?.[sponsorId]?.[creativeId]) || 0;
          const ctaClicks =
            Number(stats?.[PixelService.TRACK_EVENTS.SPONSOR_CTA_CLICKS]?.[projectId]?.[sponsorId]?.[creativeId]) || 0;
          const roi = getROIForSponsor({
            sponsorViews: views,
            sponsorClicks: clicks,
            sponsorCTAClicks: ctaClicks,
          });
          const CTR = views ? NumberUtils.getNumberInDecimal((clicks / views) * 100) : 0;
          return {
            id: `${sponsorId}-${creativeId}`,
            sponsor_id: sponsorId,
            sponsor_name: sponsor?.name || 'not-set',
            creative_id: creativeId,
            creative_name: item.name,
            creative_asset_ids: item.asset_ids,
            creative_placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT_DETAILS[item?.placement]?.label,
            stats_views: views,
            stats_clicks: clicks,
            stats_ctr: CTR,
            stats_cta_clicks: ctaClicks,
            stats_roi: roi,
          };
        })
        .valueSeq()
        .toArray(),
  );
// iesLZtciHjE | OPKBWRA41J4
