import * as React from 'react';
import ClassNames from 'classnames';
import { Color, COLOR_SHADES } from '../Color/Color';
import { Space } from '../Grid/Grid';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import styles from './label.module.css';
import { Icon, ICON_SIZES } from '../Icon/Icons';
import { Button, BUTTON_STYLES } from '../Button/Buttons';
import { Tooltip } from '../Tooltip/Tooltip';

export enum LABEL_STYLES {
  PRIMARY = 'primary',
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  PILL_DEFAULT = 'pill-default',
  PILL_PRIMARY = 'pill-primary',
  PILL_SUCCESS = 'pill-success',
  PILL_INFO = 'pill-info',
  PILL_DANGER = 'pill-danger',
}

interface Props {
  style?: LABEL_STYLES;
  onRemove?: () => void;
  children: React.ReactNode;
  onClick?: () => void;
}

export function Label(props: Props): React.ReactElement {
  const { style = LABEL_STYLES.DEFAULT, children, onRemove, onClick } = props;
  return (
    <span className={ClassNames(styles.label, styles[`label--style-${style}`])}>
      {onClick ? (
        <Button style={BUTTON_STYLES.RESET} className={styles.label__content} onClick={onClick}>
          {children}
        </Button>
      ) : (
        <div className={styles.label__content}>{children}</div>
      )}
      {onRemove && (
        <Button className={styles['label__remove-icon']} style={BUTTON_STYLES.RESET} onClick={onRemove}>
          <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
            <Icon name="x" size={ICON_SIZES.SM} />
          </Color>
        </Button>
      )}
    </span>
  );
}

export enum STATUS_LABEL_STYLES {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  SECONDARY = 'secondary',
}

interface StatusLabelProps {
  style?: STATUS_LABEL_STYLES;
  children: React.ReactNode;
}

const STATUS_LABEL_STYLE_MAP = {
  [STATUS_LABEL_STYLES.SUCCESS]: {
    icon: <span>&#10004;</span>,
    color: COLOR_SHADES.SUCCESS,
  },
  [STATUS_LABEL_STYLES.WARNING]: {
    icon: <span>&#9864;</span>,
    color: COLOR_SHADES.WARNING,
  },
  [STATUS_LABEL_STYLES.PRIMARY]: {
    icon: <span>&#10034;</span>,
    color: COLOR_SHADES.BLUE,
  },
  [STATUS_LABEL_STYLES.DANGER]: {
    icon: <span>&#10004;</span>,
    color: COLOR_SHADES.DANGER,
  },
  [STATUS_LABEL_STYLES.SECONDARY]: {
    icon: <span>●</span>,
    color: COLOR_SHADES.LIGHT,
  },
};

export function StatusLabel(props: StatusLabelProps): React.ReactElement {
  const { style = STATUS_LABEL_STYLES.SUCCESS, children } = props;
  const labelStyle = STATUS_LABEL_STYLE_MAP[style];

  return (
    <span className={ClassNames(styles['status-label'], styles[`status-label--style-${style}`])}>
      <Text color={labelStyle.color} size={TEXT_SIZE.SIZE_6} transformUpper boldness={TEXT_BOLDNESS.BOLD}>
        {labelStyle.icon}
        <Space size={1} />
        {children}
      </Text>
    </span>
  );
}

export function BetaLabel(): JSX.Element {
  return (
    <Tooltip placement="bottom" message="Beta feature! There could be minor bug and active development on this feature">
      <Label style={LABEL_STYLES.PILL_PRIMARY}>BETA</Label>
    </Tooltip>
  );
}
