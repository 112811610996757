import { FolderService, ProjectMetaService } from '@premagic/core';
import {
  ButtonIcon,
  COLOR_SHADES,
  Card,
  CardHeader,
  Col,
  Color,
  ICON_SIZES,
  Icon,
  Row,
  Space,
  SubTitle,
} from '@premagic/myne';
import { useState } from 'react';
import ProjectMetaToggleContainer from '../highlight-settings/project-meta-toggle/ProjectMetaToggleContainer';
import ToggleFolderStatusContainer from '../selection-folders/folders/ToggleFolderStatusContainer';

type ProjectPermissionsToggleItemsProps = {
  folderType: FolderService.FOLDER_TYPES;
  projectId: string;
  isReviewToggleHidden: boolean;
};

function ProjectPermissionsToggleItems(props: ProjectPermissionsToggleItemsProps) {
  const { folderType, projectId, isReviewToggleHidden } = props;

  if (folderType === FolderService.FOLDER_TYPES.HIGHLIGHT) {
    return (
      <>
        <ProjectMetaToggleContainer
          projectId={projectId}
          itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.PROTECT_WITH_PIN}
        />
        <Space vertical size={4} />
        <ProjectMetaToggleContainer
          projectId={projectId}
          itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD}
        />
        <Space vertical size={4} />
        <ProjectMetaToggleContainer
          projectId={projectId}
          itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ALLOW_GUEST_TO_BULK_DOWNLOAD}
        />
      </>
    );
  }

  return (
    <>
      {!isReviewToggleHidden && (
        <>
          <ToggleFolderStatusContainer projectId={projectId} />
          <Space vertical size={4} />
        </>
      )}
      <ProjectMetaToggleContainer
        projectId={projectId}
        itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.ENABLE_SELECTION_DOWNLOAD}
      />
    </>
  );
}

type ProjectPermissionsIconsProps = {
  folderType: FolderService.FOLDER_TYPES;
  isProtectWithPinToggleEnabled: boolean;
  isHighlightPhotoDownloadToggleEnabled: boolean;
  isReviewToggleHidden: boolean;
  isReviewEnabled: boolean;
  isSelectionPhotoDownloadToggleEnabled: boolean;
  isGuestGalleryBulkDownloadToggleEnabled: boolean;
};

function ProjectPermissionsIcons(props: ProjectPermissionsIconsProps) {
  const {
    folderType,
    isProtectWithPinToggleEnabled,
    isHighlightPhotoDownloadToggleEnabled,
    isReviewEnabled,
    isSelectionPhotoDownloadToggleEnabled,
    isReviewToggleHidden,
    isGuestGalleryBulkDownloadToggleEnabled,
  } = props;

  if (folderType === FolderService.FOLDER_TYPES.HIGHLIGHT) {
    return (
      <>
        <ButtonIcon title={`Require PIN to view the Album: ${isProtectWithPinToggleEnabled ? 'Enabled' : 'Disabled'}`}>
          <Color shade={isProtectWithPinToggleEnabled ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
            <Icon name="lock" size={ICON_SIZES.MD_SM} />
          </Color>
        </ButtonIcon>
        <Space size={2} />
        <ButtonIcon
          title={`Allow client to Download photos: ${isHighlightPhotoDownloadToggleEnabled ? 'Enabled' : 'Disabled'} `}
        >
          <Color shade={isHighlightPhotoDownloadToggleEnabled ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
            <Icon name="download" size={ICON_SIZES.MD_SM} />
          </Color>
        </ButtonIcon>
        <Space size={2} />
        <ButtonIcon
          title={`Allow Bulk Download photos in Guest Gallery: ${
            isGuestGalleryBulkDownloadToggleEnabled ? 'Enabled' : 'Disabled'
          } `}
        >
          <Color shade={isGuestGalleryBulkDownloadToggleEnabled ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
            <Icon name="download_cloud" size={ICON_SIZES.MD_SM} />
          </Color>
        </ButtonIcon>
      </>
    );
  }

  return (
    <>
      {!isReviewToggleHidden && (
        <>
          <ButtonIcon title={`Allow photo Selection: ${isReviewEnabled ? 'Enabled' : 'Disabled'}`}>
            <Color shade={isReviewEnabled ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
              <Icon name="check_circle" size={ICON_SIZES.MD_SM} />
            </Color>
          </ButtonIcon>
          <Space size={2} />
        </>
      )}
      <ButtonIcon
        title={`Allow client to Download photos: ${isSelectionPhotoDownloadToggleEnabled ? 'Enabled' : 'Disabled'} `}
      >
        <Color shade={isSelectionPhotoDownloadToggleEnabled ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
          <Icon name="download" size={ICON_SIZES.MD_SM} />
        </Color>
      </ButtonIcon>
    </>
  );
}

type Props = {
  projectId: string;
  folderType: FolderService.FOLDER_TYPES;
  isProtectWithPinToggleEnabled: boolean;
  isHighlightPhotoDownloadToggleEnabled: boolean;
  isBlockGalleryToggleEnabled: boolean;
  isReviewToggleHidden: boolean;
  isReviewEnabled: boolean;
  isSelectionPhotoDownloadToggleEnabled: boolean;
  isGuestGalleryBulkDownloadToggleEnabled: boolean;
};

export default function ProjectPermissionsSettings(props: Props) {
  const {
    projectId,
    isProtectWithPinToggleEnabled,
    isHighlightPhotoDownloadToggleEnabled,
    isSelectionPhotoDownloadToggleEnabled,
    isBlockGalleryToggleEnabled,
    folderType,
    isReviewToggleHidden,
    isReviewEnabled,
    isGuestGalleryBulkDownloadToggleEnabled,
  } = props;

  const [isPermissionSettingsOpen, setIsPermissionSettingsOpen] = useState(false);

  if (isPermissionSettingsOpen) {
    return (
      <Card>
        <Space>
          <CardHeader onClick={() => setIsPermissionSettingsOpen(false)}>
            <Color shade={COLOR_SHADES.LIGHT} inline>
              <Icon name="command" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <SubTitle>PERMISSIONS</SubTitle>
            <Col size={null} rightAlighed>
              <Icon name="chevron_up" size={ICON_SIZES.SM} />
            </Col>
          </CardHeader>
          <ProjectPermissionsToggleItems
            folderType={folderType}
            projectId={projectId}
            isReviewToggleHidden={isReviewToggleHidden}
          />
          <Space vertical size={4} />
          <ProjectMetaToggleContainer
            projectId={projectId}
            itemKey={ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.BLOCK_GALLERY}
          />
        </Space>
      </Card>
    );
  }
  return (
    <Card onClick={() => setIsPermissionSettingsOpen(true)}>
      <Space>
        <Row vcenter>
          <Color shade={COLOR_SHADES.LIGHT} inline>
            <Icon name="command" size={ICON_SIZES.SM} />
          </Color>
          <Space size={1} />
          <SubTitle>PERMISSIONS</SubTitle>
          <Col size={null} rightAlighed>
            <Icon name="chevron_down" size={ICON_SIZES.SM} />
          </Col>
        </Row>
        <Space vertical size={1} />
        <Row>
          <ProjectPermissionsIcons
            folderType={folderType}
            isReviewToggleHidden={isReviewToggleHidden}
            isReviewEnabled={isReviewEnabled}
            isProtectWithPinToggleEnabled={isProtectWithPinToggleEnabled}
            isHighlightPhotoDownloadToggleEnabled={isHighlightPhotoDownloadToggleEnabled}
            isSelectionPhotoDownloadToggleEnabled={isSelectionPhotoDownloadToggleEnabled}
            isGuestGalleryBulkDownloadToggleEnabled={isGuestGalleryBulkDownloadToggleEnabled}
          />
          <Space size={2} />
          <ButtonIcon title={`Block Album: ${isBlockGalleryToggleEnabled ? 'Enabled' : 'Disabled'}`}>
            <Color shade={isBlockGalleryToggleEnabled ? COLOR_SHADES.SUCCESS : COLOR_SHADES.LIGHT} inline>
              <Icon name="slash" size={ICON_SIZES.MD_SM} />
            </Color>
          </ButtonIcon>
        </Row>
      </Space>
    </Card>
  );
}
