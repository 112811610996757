import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { ArrayUtils, ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { LoadingDuck, ErrorDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { KEYS } from '../common/ActionConstants';
import { proposalPageSelector } from '../common/selectors';
import {
  createProposalVariable,
  fetchProposalVariables,
  deleteProposalVariable,
  updateProposalVariable,
  ProposalVariableType,
  NewProposalVariableType,
  PROPOSAL_VARIABLE_SCOPE,
} from './ProposalVariableService';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROPOSAL_VARIABLES');

const setProposalVariablesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addProposalVariableData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateProposalVariableData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, id, data) => ({
  id,
  data,
}));
const removeProposalVariableData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const fetchProposalsVariables = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = KEYS.PROPOSAL_VARIABLES.FETCH;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

  fetchProposalVariables()
    .then((response) => {
      dispatch(setProposalVariablesData(dispatch, response));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('ProposalVariable: fetch failed', e);
    });
});

export const addNewProposalVariable = createAction(
  getActionType('DATA', 'CREATE'),
  (
    dispatch,
    formResponse: FormResponseType & {
      data: NewProposalVariableType;
    },
  ) => {
    const loadingKey = KEYS.PROPOSAL_VARIABLES.ADD;

    if (formResponse.errors) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    createProposalVariable(formResponse.data)
      .then((response) => {
        dispatch(addProposalVariableData(dispatch, response));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalVariable: add new failed', e);
      });
  },
);

export const saveProposalVariable = createAction(
  getActionType('ACTION', 'UPDATE'),
  (dispatch, id, formResponse: FormResponseType) => {
    const loadingKey = KEYS.PROPOSAL_VARIABLES.UPDATE;
    if (formResponse.errors) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    updateProposalVariable(id, formResponse.data)
      .then((response) => {
        dispatch(updateProposalVariableData(dispatch, id, response));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_VARIABLES.SHOW_EDIT_PANEL, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalVariable: save failed', e);
      });
  },
);

export const removeProposalVariable = createAction(getActionType('ACTION', 'DELETE'), (dispatch, id) => {
  const loadingKey = KEYS.PROPOSAL_VARIABLES.DELETE;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
  dispatch(removeProposalVariableData(dispatch, id));

  deleteProposalVariable(id)
    .then((response) => {
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('ProposalVariable: delete failed', e);
    });
});

type StateType = {
  items: IMap<string, ProposalVariableType>;
};
const initialState = {
  items: IMap({}),
};

export const ProposalVariableDataReducer = handleActions(
  {
    [setProposalVariablesData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addProposalVariableData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateProposalVariableData.toString()]: (state, action: { payload }) => {
      const { id, data } = action.payload;

      return {
        ...state,
        items: state.items.update(id, (variable) => ({
          // @ts-ignore
          ...variable,
          ...data,
        })),
      };
    },
    [removeProposalVariableData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.remove(String(action.payload)),
    }),
  },
  initialState,
);

const proposalVariablesDataSelector = createSelector(
  proposalPageSelector,
  (state) => state.data.variables as StateType,
);

export const proposalVariablesItemsSelector = createSelector(proposalVariablesDataSelector, (state) => state.items);

export const proposalVariablesSelectors = createSelector(proposalVariablesDataSelector, (state) =>
  state.items
    .map((item, id) => Object.assign(item, { id }))
    .sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.name, b.name)),
);
export const proposalsVariableItemsSelectors = createSelector(proposalVariablesSelectors, (items) => items.toJSON());

export const proposalsVariableGroupedIdsSelectors = createSelector(proposalVariablesSelectors, (items) =>
  items
    .sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.name, b.name))
    .groupBy((item) => item.scope)
    .reduce(
      (acc, value, key) => ({
        ...acc,
        [key]: value.keySeq().toArray(),
      }),
      {
        [PROPOSAL_VARIABLE_SCOPE.DEFAULT]: [],
        [PROPOSAL_VARIABLE_SCOPE.INVOICE_ITEM]: [],
        [PROPOSAL_VARIABLE_SCOPE.CAMERA_DETAILS]: [],
      },
    ),
);
export const proposalsVariableDetailsSelectors = createSelector(proposalVariablesSelectors, (items) =>
  items.valueSeq().toJSON(),
);
export const proposalsVariableDefaultItemsSelectors = createSelector(proposalVariablesSelectors, (items) =>
  items
    .filter((item) => item.scope === PROPOSAL_VARIABLE_SCOPE.DEFAULT)
    .valueSeq()
    .toJSON(),
);

export const proposalsVariableInvoiceItemsSelectors = createSelector(proposalVariablesSelectors, (items) =>
  items
    .filter((item) => item.scope === PROPOSAL_VARIABLE_SCOPE.INVOICE_ITEM)
    .valueSeq()
    .toJSON(),
);
export const proposalsVariableEquipmentItemsSelectors = createSelector(proposalVariablesSelectors, (items) =>
  items
    .filter((item) => item.scope === PROPOSAL_VARIABLE_SCOPE.CAMERA_DETAILS)
    .valueSeq()
    .toJSON(),
);

export const proposalsVariablesIdsSelectors = createSelector(proposalVariablesSelectors, (items) =>
  items
    .sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.name, b.name))
    .keySeq()
    .toArray(),
);
