import { EventSponsorsService, EventTrackerService, Schemas } from '@premagic/core';
import { ActionTypeUtils, ArrayUtils, ErrorTracker, SimpleDateUtils } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../../../common/LoadingDuck';
import { toastMessage } from '../../../../../../reducers/ToastStore';
import { pagesSelector } from '../../../../../../reducers/selectors';

const LOADING_KEYS = LOADINGS.EVENT_SPONSOR_CATEGORY;

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_SPONSOR_CATEGORY');

const setEventSponsorCategoryData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addEventSponsorCategoryData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);

export const fetchEventSponsorCategoryData = createAction(
  getActionType('DATA', 'FETCH_EVENT_SPONSOR_CATEGORY'),
  (dispatch) => {
    const loadingKey = LOADING_KEYS.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    EventSponsorsService.fetchAllEventSponsorsCategories()
      .then((response) => {
        const normalizedData = normalize(response, Schemas.SponsorsCategoryListSchema);
        dispatch(setEventSponsorCategoryData(dispatch, normalizedData.entities.sponsorsCategory));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('Event sponsor category: fetch failed', e);
        return e;
      });
  },
);

export const createSponsorCategory = createAction(
  getActionType('DATA', 'CREATE_SPONSOR_CATEGORY'),
  (dispatch, sponsorCategoryData: { data: { name: string; tier: EventSponsorsService.EVENT_SPONSORS_TIER } }) => {
    const loadingKey = LOADING_KEYS.CREATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SPONSOR.CATEGORY_CREATE, {
      ...sponsorCategoryData,
    });

    EventSponsorsService.createSponsorCategory(sponsorCategoryData.data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.SponsorsCategorySchema);
        dispatch(addEventSponsorCategoryData(dispatch, normalizedData.entities.sponsorsCategory));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', `Something went wrong`);
        ErrorTracker.logError('Sponsor category: create failed', e);
        return e;
      });
  },
);

export const updateSponsorCategory = createAction(
  getActionType('DATA', 'EDIT_SPONSOR_CATEGORY'),
  (
    dispatch,
    sponsorCategoryId: string,
    sponsorCategoryData: { data: { name: string; tier: EventSponsorsService.EVENT_SPONSORS_TIER } },
  ) => {
    const loadingKey = LOADING_KEYS.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SPONSOR.CATEGORY_EDIT, {
      ...sponsorCategoryData,
    });

    EventSponsorsService.updateSponsorCategory(sponsorCategoryId, sponsorCategoryData.data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.SponsorsCategorySchema);
        dispatch(addEventSponsorCategoryData(dispatch, normalizedData.entities.sponsorsCategory));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', `Something went wrong`);
        ErrorTracker.logError('Sponsor category: update failed', e);
        return e;
      });
  },
);

type SponsorCategoryType = {
  items: IMap<string, EventSponsorsService.EventSponsorCategoryType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setEventSponsorCategoryData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addEventSponsorCategoryData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    // [updateEventSponsorCategoryData.toString()]: (state, action) => ({
    //   ...state,
    //   items: state.items.merge(action.payload),
    // }),
    // [removeEventSponsorCategoryData.toString()]: (state, action) => ({
    //   ...state,
    //   items: state.items.delete(action.payload),
    // }),
  },
  initialState,
);

const eventSponsorCategorySelector = createSelector(
  pagesSelector,
  (page) => page.eventSponsor.category as SponsorCategoryType,
);

export const eventSponsorCategoryItemsSelector = createSelector(eventSponsorCategorySelector, (data) => data.items);

export const sponsorsCategoryDataSelector = createSelector(eventSponsorCategoryItemsSelector, (items) =>
  items.toJSON(),
);

export const sponsorCategoryIdsSelector = createSelector(eventSponsorCategoryItemsSelector, (items) =>
  items
    .map((item) => ({
      ...item,
      created_at: SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(item.created_at),
    }))
    .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.created_at, b.created_at, true))
    .sort((a, b) => {
      const aTier = a?.tier;
      const bTier = b?.tier;
      const aOrder = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[aTier].order;
      const bOrder = EventSponsorsService.EVENT_SPONSORS_TIER_DETAILS[bTier].order;
      return aOrder - bOrder;
    })
    .keySeq()
    .toArray(),
);

export const sponsorCategoryItemDataSelector = (sponsorCategoryId: string) =>
  createSelector(eventSponsorCategoryItemsSelector, (items) => items.get(sponsorCategoryId));
