import { EventsService, FolderService, IntegrationService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { requestingUserSelector } from '../../crm/users/UsersDataDuck';
import { UserType } from '../../crm/users/UsersService';
import { folderIdsWithFolderTypeForProjectSelector } from '../../projects/AccountProjectsDataDuck';
import { projectEventDataSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';
import {
  connectAndSyncEvent,
  createPremagicEventAndConnectIntegrationEvent,
  getCustomFieldsForEvent,
  integrationEventCustomFieldsDataSelector,
  integrationEventItemSelector,
} from '../IntegrationDataDuck';
import IntegrationEventCustomFieldsSelector from './IntegrationEventCustomFieldsSelector';
import { INTEGRATION_CONNECT_OPTION, INTEGRATION_STEP } from './IntegrationStepService';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    platform: IntegrationService.INTEGRATION_PLATFORMS;
    setIntegrationStep: (step: INTEGRATION_STEP) => void;
    closeModal: () => void;
    connectOption: INTEGRATION_CONNECT_OPTION;
    projectId?: string;
    eventId?: string;
  },
) {
  const { platform, setIntegrationStep, closeModal, connectOption, projectId, eventId } = ownProps;

  const shareFolderIds = projectId
    ? folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.HIGHLIGHT, {
        is_shared: true,
      })(state)
    : undefined;

  return {
    platform,
    setIntegrationStep,
    integrationEventData: integrationEventItemSelector(state),
    customFields: integrationEventCustomFieldsDataSelector(state),
    isLoading: isLoadingSelector(LOADINGS.INTEGRATION.FETCH_EVENT_CUSTOM_FIELDS)(state),
    isCreatingAndConnectingEvent:
      isLoadingSelector(LOADINGS.EVENT.ADD)(state) ||
      isLoadingSelector(LOADINGS.INTEGRATION.CONNECT_AND_SYNC_EVENT)(state),
    errors:
      errorObjectForTypeSelector(LOADINGS.EVENT.ADD)(state) ||
      errorObjectForTypeSelector(LOADINGS.INTEGRATION.CONNECT_AND_SYNC_EVENT)(state),
    requestingUser: requestingUserSelector(state),
    closeModal,
    connectOption,
    projectId,
    eventId,
    pmEventData: projectId ? projectEventDataSelector(projectId)(state) : undefined,
    numOfSharedFolders: shareFolderIds?.length,
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomFieldsForEvent: (platform, eventId) => {
      dispatch(getCustomFieldsForEvent(dispatch, platform, eventId));
    },
    createPremagicEventAndConnectIntegrationEvent: (options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      integrationEventData: IntegrationService.IntegrationPlatformEventType;
      fieldsToSync: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
      hostUser?: UserType;
      isCompanyTypeNotPhotographer?: boolean;
    }) => {
      dispatch(createPremagicEventAndConnectIntegrationEvent(dispatch, options));
    },
    connectAndSyncEvent: (options: {
      platform: IntegrationService.INTEGRATION_PLATFORMS;
      integrationEventData: IntegrationService.IntegrationPlatformEventType;
      fieldsToSync: Array<IntegrationService.IntegrationPlatformEventCustomFieldType>;
      premagicProjectId: string;
      hostUser?: UserType;
      numOfSharedFolders?: number;
      syncEvent?: boolean;
      premagicEventData?: EventsService.EventDataType;
    }) => {
      dispatch(connectAndSyncEvent(dispatch, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationEventCustomFieldsSelector);
