import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils, BrowserUtils } from '@premagic/utils';
import { Schemas, PeopleFacesService } from '@premagic/core';
import { ErrorDuck, LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../common/Constants';
import { entitiesDataSelector } from '../../reducers/selectors';
import { resetFilesToFolder } from './FilesDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PEOPLE_FACES', false);

export const setPeopleFacesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const setBlobToFaceIdData = createAction(
  getActionType('BLOB_TO_FACE_ID', 'SET'),
  (dispatch, blobURL: string, faceId: string) => ({
    [blobURL]: faceId,
  }),
);

export const getPeopleFacesInProject = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.PEOPLE_FACES.FETCH;

  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
  PeopleFacesService.fetchPeopleFacesForProjectId(projectId)
    .then((response) => {
      const normalizedData = normalize(response, Schemas.PeopleFacesSchema);

      dispatch(setPeopleFacesData(dispatch, normalizedData.entities.faces));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    })
    .catch((error) => {
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, error));
      ErrorTracker.logError('PeopleFaces fetch failed', error);
    });
});

export const getFaceIdFromPhotos = createAction(
  getActionType('DATA', 'FIND_FACE_ID'),
  async (dispatch, projectId: string, blobURL: string) => {
    const loadingKey = LOADINGS.PEOPLE_FACES.FIND_FACE_ID;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    try {
      const formData = new FormData();
      const blob = await BrowserUtils.createBlobFromUrl(blobURL);
      if (blob) formData.append('selfie', blob);
      // BrowserUtils.revokeBlobUrlFromMemory(blobURL);

      const people = await PeopleFacesService.getFaceIdFromProject(projectId, formData);
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));

      if (!people.face_id) {
        dispatch(
          ErrorDuck.setErrorState(dispatch, loadingKey, {
            message: 'We could not recognize your face. Please try again.',
          }),
        );
        dispatch(resetFilesToFolder(dispatch));
        return;
      }
      dispatch(setBlobToFaceIdData(dispatch, blobURL, people.face_id));
    } catch (error: any) {
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      dispatch(
        ErrorDuck.setErrorState(
          dispatch,
          loadingKey,
          error.response || {
            message: 'We could not recognize face. Please try again.',
          },
        ),
      );
      ErrorTracker.logError('PeopleFaces search failed', error.response);
    }
  },
);

export const setFacesInAItem = createAction(getActionType('DATA_FACES_IN_FILE', 'SET'), (dispatch, data) => data);

export const getFacesForFile = createAction(
  getActionType('DATA_FACES', 'FETCH'),
  (dispatch, shareId: string, fileId: string) => {
    const loadingKey = LOADINGS.PEOPLE_FACES.FACES_IN_ITEM;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    PeopleFacesService.getFaceDetailsForFile(shareId, fileId)
      .then((response) => {
        if (response?.faces)
          dispatch(
            setFacesInAItem(dispatch, {
              [fileId]: response.faces,
            }),
          );
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      })
      .catch((error) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('faces in file fetch failed', error);
      });
  },
);

type StateType = {
  items: IMap<string, PeopleFacesService.PeopleFace>;
  facesInItems: IMap<string, Array<PeopleFacesService.PeopleFace>>;
  photoToFaceId: IMap<string, string>;
};

const initialState = {
  items: IMap({}),
  facesInItems: IMap({}),
  photoToFaceId: IMap({}),
};

export default handleActions(
  {
    [setPeopleFacesData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [setFacesInAItem.toString()]: (state, action) => ({
      ...state,
      facesInItems: state.facesInItems.merge(action.payload),
    }),
    [setBlobToFaceIdData.toString()]: (state, action) => ({
      ...state,
      photoToFaceId: state.photoToFaceId.merge(action.payload),
    }),
  },
  initialState,
);

const peopleFacesDataSelector = createSelector(entitiesDataSelector, (state) => state.peopleFaces as StateType);

const peopleFacesEntityDataSelector = createSelector(peopleFacesDataSelector, (state) => state.items);
export const peopleFacesSelector = createSelector(peopleFacesEntityDataSelector, (items) => items.toJSON());
export const facesInItemsSelector = createSelector(peopleFacesDataSelector, (state) => state.facesInItems.toJSON());
export const photoToFaceIdSelector = createSelector(peopleFacesDataSelector, (state) => state.photoToFaceId.toJSON());

export const peopleFacesArraySelector = createSelector(peopleFacesEntityDataSelector, (items) =>
  items
    .sort((a, b) => b.confidence + b.quality - (a.confidence + a.quality))
    .valueSeq()
    .toArray(),
);
