import { connect } from 'react-redux';
import { ActionConstants, ProposalSlideDataDuck } from '@premagic/proposals';
import { ModalDuck } from '@premagic/common-ducks';
import { DECK_SLIDE_DATA_TYPES, DeckSlideBackgroundDataType } from '@premagic/myne';

import ProposalCreatorSelectFileForSlideModal from './ProposalCreatorSelectFileForSlideModal';
import { filesSelector } from '../../../images/FilesDataDuck';
import { proposalCreatorSelectedSlideSelector } from '../ProposalCreatorDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    show: ModalDuck.isModalOpenSelector(ActionConstants.KEYS.PROPOSAL.SELECT_FILE_MODAL)(state),
    modalOptions: ModalDuck.modalOptionsSelector<{
      datatype: DECK_SLIDE_DATA_TYPES;
      value?: DeckSlideBackgroundDataType;
    }>(ActionConstants.KEYS.PROPOSAL.SELECT_FILE_MODAL)(state),
    files: filesSelector(state),
    slides: ProposalSlideDataDuck.proposalsSlideItemsSelectors(state),
    slideId: proposalCreatorSelectedSlideSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProposalSlide: (options, data) => dispatch(ProposalSlideDataDuck.saveProposalSlide(dispatch, options, data)),
    hideModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SELECT_FILE_MODAL, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorSelectFileForSlideModal);
