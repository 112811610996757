import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { LOADINGS } from '../../../../../common/Constants';
import { updateMemoryTemplate } from '../MemoriesTemplatesDataDuck';
import EditMemoryTemplatePanel from './EditMemoryTemplatePanel';

const PANEL_KEY = LOADINGS.MEMORY_TEMPLATES.SHOW_EDIT_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
  },
) {
  const { projectId, eventId } = ownProps;

  return {
    projectId,
    eventId,
    id: ModalDuck.modalOptionsSelector(PANEL_KEY)(state) as string,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.MEMORY_TEMPLATES.UPDATE)(state),
    error: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADINGS.MEMORY_TEMPLATES.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editTemplate: (projectId: string, templateId: string, data) =>
      dispatch(updateMemoryTemplate(dispatch, { projectId, templateId }, data)),
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMemoryTemplatePanel);
