import { UploaderDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';

import MegaUploader from './MegaUploader';

function mapStateToProps(state) {
  return {
    isUploading: UploaderDuck.isUploadingFilesSelector(state),
    totalUploadingFiles: UploaderDuck.totalUploadingFilesSelector(state),
    totalUploadedFiles: UploaderDuck.totalUploadedFilesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearAll: () => dispatch(UploaderDuck.removeAllFilesInUploaderState(dispatch)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MegaUploader);
