import { AttendeeMemoriesService, EventAttendeesService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  FileUploader,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  Input,
  INPUT_SIZES,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { BrowserUtils, ErrorTracker } from '@premagic/utils';
import React, { useMemo, useState } from 'react';
import { toastMessage } from '../../../reducers/ToastStore';

type TargetFileSelectorProps = {
  template?: AttendeeMemoriesService.MemoryTemplateType;
};

function TargetFileSelector(props: TargetFileSelectorProps): JSX.Element {
  const { template } = props;
  const [photoURL, setPhotoURL] = useState<string | undefined>(template?.target_asset?.dynamic_image_url);

  function onSelectPhoto(file: File | Blob) {
    setPhotoURL(BrowserUtils.createURLFromBlob(file));
  }
  function onRetake() {
    setPhotoURL(undefined);
  }

  if (photoURL) {
    return (
      <Row vcenter>
        <input type="hidden" name="target" value={photoURL} />
        {photoURL && (
          <Card style={CARD_STYLES.TRANSPARENT} size={CARD_SIZES.BOX_SM} center overflowHidden>
            <ImageV2 useNewImageService src={photoURL} style={IMAGE_V2_STYLES.THUMBNAIL} alt="user" />
          </Card>
        )}
        <Space vertical />
        <div>
          <Button style={BUTTON_STYLES.CANCEL} onClick={onRetake}>
            Change
          </Button>
        </div>
      </Row>
    );
  }

  return (
    <Card style={CARD_STYLES.TRANSPARENT} size={CARD_SIZES.BOX_SM} center>
      <FileUploader
        accept="image/jpeg, image/png"
        maxSize={20}
        onError={(message, e) => {
          toastMessage('error', message, 200);
          ErrorTracker.logError('Invalid file format', e);
        }}
        onChange={(e, files) => {
          if (files.length) onSelectPhoto(files[0]);
          return {};
        }}
      >
        <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} block>
          <Icon name="upload" size={ICON_SIZES.MD} />
          <Space size={2} vertical />
          Select template picture
        </Button>
      </FileUploader>
    </Card>
  );
}

function TemplateTypeSelector(props: { defaultValue?: AttendeeMemoriesService.MEMORY_TYPE }) {
  const { defaultValue = AttendeeMemoriesService.MEMORY_TYPE.FACE_SWAP } = props;
  const options = useMemo(
    () =>
      Object.entries(AttendeeMemoriesService.MEMORY_TYPE_DETAILS).map(([key, details]) => ({
        label: details.title,
        value: key,
        info: details.info,
      })),
    [],
  );
  return (
    <FormGroup>
      <FormLabel isRequired>Type</FormLabel>
      <Select
        name="type"
        size={INPUT_SIZES.SM}
        options={options}
        isMulti={false}
        value={options.find((option) => option.value === defaultValue)}
      />
    </FormGroup>
  );
}

function TemplateGenderSelector(props: { defaultValue?: EventAttendeesService.EVENT_ATTENDEE_GENDER }) {
  const { defaultValue = EventAttendeesService.EVENT_ATTENDEE_GENDER.MALE } = props;
  const options = useMemo(
    () =>
      Object.entries(EventAttendeesService.EVENT_ATTENDEE_GENDER_DETAILS).map(([key, details]) => ({
        label: details.title,
        value: key,
      })),
    [],
  );
  return (
    <FormGroup>
      <FormLabel isRequired>Gender of template photo</FormLabel>
      <Select
        name="gender"
        size={INPUT_SIZES.SM}
        options={options}
        isMulti={false}
        value={options.find((option) => option.value === defaultValue)}
      />
    </FormGroup>
  );
}

function TemplatePlatformSelector(props: {
  defaultValue?: AttendeeMemoriesService.MEMORY_GENERATOR_PLATFORM;
  isStaffUser: boolean;
}) {
  const { defaultValue = AttendeeMemoriesService.MEMORY_GENERATOR_PLATFORM.FAL_AI, isStaffUser } = props;
  const options = useMemo(
    () =>
      Object.entries(AttendeeMemoriesService.MEMORY_GENERATOR_PLATFORM_DETAILS).map(([key, details]) => ({
        label: details.title,
        value: key,
      })),
    [],
  );
  if (!isStaffUser) {
    return (
      <input
        type="hidden"
        name="generator_platform"
        value={defaultValue || AttendeeMemoriesService.MEMORY_GENERATOR_PLATFORM.FAL_AI}
      />
    );
  }
  return (
    <FormGroup>
      <FormLabel isRequired info="Only for staff">
        Platform
      </FormLabel>
      <Select
        name="generator_platform"
        size={INPUT_SIZES.SM}
        options={options}
        isMulti={false}
        value={options.find((option) => option.value === defaultValue)}
      />
    </FormGroup>
  );
}

type Props = {
  projectId: string;
  id?: string;
  template?: AttendeeMemoriesService.MemoryTemplateType;
  isStaffUser: boolean;
};

export default function MemoryTemplateForm(props: Props) {
  const { template, projectId, id, isStaffUser } = props;

  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Template</FormLabel>
        <Input name="name" defaultValue={template?.name || 'Avatar'} placeholder="eg. Medival war hero" />
      </FormGroup>
      <Row>
        <TemplateGenderSelector defaultValue={template?.gender} />
        <Space />
        <FormGroup>
          <FormLabel isRequired>Person average age</FormLabel>
          <Input name="age" type="number" defaultValue={template?.age || 30} size={INPUT_SIZES.XS} min={5} max={100} />
        </FormGroup>
      </Row>
      <Row>
        <TemplateTypeSelector defaultValue={template?.type} />
        <Space />
        <TemplatePlatformSelector isStaffUser={isStaffUser} defaultValue={template?.generator_platform} />
      </Row>
      <FormGroup>
        <FormLabel isRequired>Template</FormLabel>
        <TargetFileSelector template={template} />
      </FormGroup>
    </div>
  );
}
