import { EventPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { PosterWidgetAvatarType } from '@premagic/poster-creator';
import { eventPostersSelector, updateEventPosterWidget } from '../../crm/event-posters/EventPostersDataDuck';
import EventPosterAvatarStyleSelect from './EventPosterAvatarStyleSelect';

function mapStateToProps(
  state,
  ownProps: { projectId: string; posterId: string; widgetId: EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS },
) {
  const { projectId, posterId, widgetId } = ownProps;
  const poster = eventPostersSelector(state)[posterId];
  const selectedWidget = poster?.widgets?.[widgetId] as PosterWidgetAvatarType;
  return { projectId, poster, posterId, widget: selectedWidget };
}

function mapDispatchToProps(dispatch) {
  return {
    editPoster: ({ projectId, poster, widgetToUpdate }) => {
      dispatch(
        updateEventPosterWidget(dispatch, projectId, {
          widgetToUpdate,
          poster,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterAvatarStyleSelect);
