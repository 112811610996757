import React from 'react';
import { get, isFunction } from 'lodash';
import { TEXT_GROUP_TYPES, TextGroup, TextGroupLabel, TextGroupValue } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

import {
  CLIENT_WEBSITE_CUSTOM_INPUT_TYPES,
  CLIENT_WEBSITE_FORM_ITEMS,
  CLIENT_WEBSITE_FORM_ITEMS_DETAILS,
} from '../form-items/ClientWebsiteFormService';
import { ClientWebsiteThemeTextGroupValue } from '../form-items/ClientWebsiteThemeItem';
import { ClientWebsiteClipArtTextGroupValue } from '../form-items/ClientWebsiteClipArtItem';
import { ClientWebsiteReligiousClipArtTextGroupValue } from '../form-items/ClientWebsiteReligiousClipArtItem';
import { BackgroundMusicTextGroupValue } from '../../background-music/BackgroungMusicSelector';
import { ClientWebsiteMetricsFormItemValue } from '../form-items/ClientWebsiteMetricsFormItem';

const CLIENT_WEBSITE_CUSTOM_VALUE_ITEM = {
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.THEME_SELECT]: ClientWebsiteThemeTextGroupValue,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.CLIPART_SELECT]: ClientWebsiteClipArtTextGroupValue,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.MUSIC_SELECT]: BackgroundMusicTextGroupValue,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.RELIGIOUS_CLIPART_SELECT]: ClientWebsiteReligiousClipArtTextGroupValue,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.METRICS]: ClientWebsiteMetricsFormItemValue,
};

function getCustomValueComponent(type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES) {
  return CLIENT_WEBSITE_CUSTOM_VALUE_ITEM[type];
}

type ClientWebsiteFormItemProps = {
  itemKey: CLIENT_WEBSITE_FORM_ITEMS;
  title?: string; // When the event type is coporate
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  langCode?: string;
};

export default function ClientWebsiteDetailsValueItem(props: ClientWebsiteFormItemProps): JSX.Element {
  const { itemKey, clientWebsite, title, langCode } = props;
  const formItemDetails = CLIENT_WEBSITE_FORM_ITEMS_DETAILS[itemKey];
  const formType = formItemDetails.type;

  const isCustomInputType = formType in CLIENT_WEBSITE_CUSTOM_INPUT_TYPES;

  const defaultValueProp = formItemDetails?.defaultValue;
  const defaultValue = isFunction(defaultValueProp) && langCode ? defaultValueProp(langCode) : defaultValueProp;

  return (
    <TextGroup>
      {formItemDetails.type !== CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.METRICS && (
        <TextGroupLabel>{title || formItemDetails.title}</TextGroupLabel>
      )}
      {(() => {
        if (isCustomInputType) {
          const ComponentComponent = getCustomValueComponent(formType as CLIENT_WEBSITE_CUSTOM_INPUT_TYPES);
          return (
            <TextGroupValue value={get(clientWebsite, formItemDetails.name)} type={TEXT_GROUP_TYPES.CUSTOM}>
              <ComponentComponent value={get(clientWebsite, formItemDetails.name)} />
            </TextGroupValue>
          );
        }

        return (
          <TextGroupValue
            value={get(clientWebsite, formItemDetails.name) || defaultValue}
            secondaryValue={
              formItemDetails.secondaryInputName ? get(clientWebsite, formItemDetails.secondaryInputName) : null
            }
            type={formType as unknown as TEXT_GROUP_TYPES}
          />
        );
      })()}
    </TextGroup>
  );
}
