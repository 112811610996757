import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import BrandingDetailsPageHeader from './BrandingDetailsPageHeader';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { currentUrlPathname: string; folderId: string }) {
  const { currentUrlPathname, folderId } = ownProps;

  return {
    currentUrlPathname,
    folderId,
    folder: foldersEntitiesSelector(state)[folderId],
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.FETCH)(state),
  };
}

export default connect(mapStateToProps)(BrandingDetailsPageHeader);
