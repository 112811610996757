import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Dialog,
  Form,
  FormFooter,
  FormGroup,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  INPUT_TYPES,
  Row,
  Col,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_BUTTON_STYLES,
  TextButton,
  RealCheckbox,
} from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import React, { useRef } from 'react';

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateProjectMeta: (projectId: string, data: FormResponseType) => void;
  saveClientSettingsData: (data: FormResponseType) => void;
  isLoading: boolean;
  isDialogShown: boolean;
  toggleDialog: (show: boolean) => void;
  defaultSelectionBudget: number;
};

export default function SelectionBudget(props: Props): JSX.Element | null {
  const {
    updateProjectMeta,
    projectId,
    isLoading,
    projectMeta,
    toggleDialog,
    isDialogShown,
    defaultSelectionBudget,
    saveClientSettingsData,
  } = props;
  const $button = useRef(null);

  function onSubmit(formResponse) {
    const metaData = {
      data: {
        selection_budget: formResponse?.data?.selection_budget,
      },
    };
    updateProjectMeta(projectId, metaData);
    if (formResponse?.data?.set_as_default) {
      const settingsData = {
        data: {
          default_selection_budget: formResponse?.data?.selection_budget,
        },
      };
      saveClientSettingsData(settingsData);
    }
  }
  return (
    <>
      <Row vcenter>
        <Color shade={COLOR_SHADES.DARKER} inline>
          <Icon name="pie_chart" size={ICON_SIZES.SM} alignSize={2} align="bottom" />
        </Color>
        <Space size={1} />
        <Text>Selection Limit</Text>
        <Space size={4} />
        {projectMeta?.selection_budget ? (
          <TextButton
            ref={$button}
            style={TEXT_BUTTON_STYLES.SECONDARY}
            onClick={() => toggleDialog(!isDialogShown)}
            size={BUTTON_SIZE.LG}
          >
            {projectMeta.selection_budget}
            <Space size={1} />
            <Color shade={COLOR_SHADES.LIGHT} inline>
              <Icon name="edit_2" size={ICON_SIZES.SM} />
            </Color>
          </TextButton>
        ) : (
          <Button
            ref={$button}
            style={BUTTON_STYLES.LINK}
            size={BUTTON_SIZE.SM}
            onClick={() => toggleDialog(!isDialogShown)}
          >
            Set Limit
          </Button>
        )}
      </Row>
      <Dialog target={$button.current} show={isDialogShown} onClose={() => toggleDialog(false)}>
        <Form
          onSubmit={(formData) => onSubmit(formData)}
          formSpec={{
            [ProjectMetaService.PROJECT_META_TYPES.SELECTION_BUDGET]: {
              type: 'number',
            },
            set_as_default: {
              type: 'boolean',
            },
          }}
        >
          <FormGroup>
            <Row vcenter>
              <Color shade={COLOR_SHADES.DARKER} inline>
                <Icon name="pie_chart" size={ICON_SIZES.SM} alignSize={2} align="bottom" />
              </Color>
              <Space size={1} />
              <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                Selection Limit
              </Text>
            </Row>
            <Space size={2} vertical />
            <Text block>Suggest number of photos to be selected by your clients</Text>
            <Space vertical />
            <Input
              autoFocus
              name={ProjectMetaService.PROJECT_META_TYPES.SELECTION_BUDGET}
              defaultValue={projectMeta?.selection_budget}
              size={INPUT_SIZES.SM}
              type={INPUT_TYPES.NUMBER}
              placeholder="eg. 250"
            />
            <Space vertical />
            <RealCheckbox
              name="set_as_default"
              defaultChecked={defaultSelectionBudget === projectMeta?.selection_budget}
            >
              Set as default for all new projects
            </RealCheckbox>
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
