import React from 'react';

import {
  COLOR_SHADES,
  Space,
  Text,
  Dialog,
  DIALOG_PLACEMENT,
  DialogTitle,
  DialogFooter,
  Button,
  BUTTON_STYLES,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { ProposalService } from '@premagic/proposals';

import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import MESSAGES from '../../../../../../common/Messages';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  projectId: string;
  folderId: string;
  isSaving: boolean;
  removeProposal: (projectId: string, folderId: string, backToUrl: string) => void;
  showDialog: boolean;
  toggleDialog: (show: boolean) => void;
  proposal: ProposalService.ProposalType;
};

export default function ProposalCreatorDeleteDialog(props: Props) {
  const { projectId, folderId, isSaving, removeProposal, showDialog, toggleDialog, proposal } = props;
  const backToUrl = proposal.event_id
    ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
        eventId: proposal.event_id,
      })
    : BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROPOSALS_TEMPLATES.LIST, {});

  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.PROPOSAL_MORE_OPTIONS)}
      show={showDialog}
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleDialog(false)}
    >
      <DialogTitle>Delete this Proposal?</DialogTitle>
      <Text>Are you sure you want to delete this Proposal?</Text>
      <Space vertical size={6} />
      <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
          Cancel
        </Button>
        <Button
          style={BUTTON_STYLES.DANGER}
          onClick={() => removeProposal(projectId, folderId, backToUrl)}
          isLoading={isSaving}
        >
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
