import qs from 'qs';
import { History } from 'history';
import smoothscroll from 'smoothscroll-polyfill';
import { logAction } from './ErrorTracker';
import { get } from './HttpUtils';

smoothscroll.polyfill();

declare const BROWSER_SUPPORT: {
  hasSupportForWebp: null;
};

type GlobalBROWSERSUPPORTVariable = {
  hasSupportForWebp?: boolean;
};

let backListener;
export function getGlobalVariableForBROWSERSUPPORT(): GlobalBROWSERSUPPORTVariable {
  return (window as unknown as { BROWSER_SUPPORT: GlobalBROWSERSUPPORTVariable }).BROWSER_SUPPORT || {};
}

export function setGlobalVariableForBROWSERSUPPORT(data: GlobalBROWSERSUPPORTVariable): void {
  const existingData = getGlobalVariableForBROWSERSUPPORT();
  (window as unknown as { BROWSER_SUPPORT: GlobalBROWSERSUPPORTVariable }).BROWSER_SUPPORT = {
    ...existingData,
    ...data,
  };
}

export function getElement<T extends HTMLElement>(element: string): T | null {
  return document.querySelector(element);
}

export function getElements(element: string): NodeListOf<HTMLElement> {
  return document.querySelectorAll(element);
}

export function getQueryObject(queryString): Record<string, unknown> {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

function isVisibleInTopHalfOfScreen(
  element: string | HTMLElement | Element | null,
  $container?: HTMLElement | Window,
): boolean {
  let $element;
  if (typeof element === 'string') {
    $element = document.querySelector(element);
  } else {
    $element = element;
  }
  if (!$element) return false;

  const rect = $element.getBoundingClientRect();
  const leftOffset = 50;
  // Its okay if 50px of the element is hidden; This will make suer when the user use the nav tab in signature album and selects the last item yet to scroll, it wont jump to center
  const rightOffset = 50;

  const rightLimit =
    $container && $container instanceof HTMLElement ? $container.getBoundingClientRect().right : window.innerWidth;
  const bottomLimit =
    $container && $container instanceof HTMLElement
      ? $container.getBoundingClientRect().bottom
      : window.innerHeight / 2;

  return (
    rect.top >= 0 &&
    rect.left - leftOffset >= 0 &&
    Math.floor(rect.bottom) <= bottomLimit &&
    Math.floor(rect.right) - rightOffset <= rightLimit
  );
}

export function getDocumentHeight(): number {
  const d = document.documentElement;
  return d.offsetHeight;
}

function getBoundingRect(
  element: HTMLElement,
  container: HTMLElement,
): {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
} {
  // root element
  if (container === document.documentElement) {
    return element.getBoundingClientRect();
  }

  // relative to container
  const elRect = element.getBoundingClientRect();
  const vpRect = container.getBoundingClientRect();
  return {
    bottom: elRect.bottom - vpRect.top,
    height: elRect.height,
    left: elRect.left - vpRect.left,
    right: elRect.right - vpRect.left,
    top: elRect.top - vpRect.top,
    width: elRect.width,
  };
}

export function hasScrolledTillBottom(): boolean {
  const buffer = 200;
  const d = document.documentElement;
  const offset = d.scrollTop + window.innerHeight;
  const height = getDocumentHeight() - buffer;

  return offset >= height;
}

export function disableBodyScroll(): void {
  document.body.classList.add('body--prevent-scroll');
}

export function enableBodyScroll(): void {
  document.body.classList.remove('body--prevent-scroll');
}

export function scrollToPosition(positionY): void {
  window.scrollTo(0, positionY);
}

function getTop(container: Window | HTMLElement, element: HTMLElement): number {
  if ('scrollY' in container) {
    return element.getBoundingClientRect().top + container.scrollY;
  }
  return element.getBoundingClientRect().top + container.offsetTop;
}

function getLeft(container: Window | HTMLElement, element: HTMLElement): number {
  if ('scrollX' in container) {
    return element.getBoundingClientRect().left + container.scrollX;
  }

  const rect = getBoundingRect(element, container);
  return rect.left + container.scrollLeft;
}

// handle the case when element is inside `js-window-panel-content` then default container should be `js-window-panel-content`
export function scrollTo(
  element: string | HTMLElement | Element | null,
  smoothScroll?: boolean,
  offset = 120,
  container?: HTMLElement,
): boolean {
  let $element;

  if (typeof element === 'string') {
    $element = document.querySelector(element);
  } else {
    $element = element;
  }

  if (!$element) return false;

  const windowPanelContent = document.querySelector('.js-window-panel-content');
  const isElementInsideWindowPanel = windowPanelContent?.contains($element);
  const $containerElement = isElementInsideWindowPanel ? (windowPanelContent as HTMLElement) : container || window;
  if (isVisibleInTopHalfOfScreen($element, $containerElement)) return false;

  const top = getTop($containerElement, $element) - offset;
  const left = getLeft($containerElement, $element) - offset;

  if (smoothScroll) {
    $containerElement.scroll({
      top,
      left,
      behavior: 'smooth',
    });
    return true;
  }
  $containerElement.scroll(left, top);
  return true;
}

export function scrollToTop(disableSmooth?: boolean): void {
  if (disableSmooth) {
    window.scroll(0, 0);
  }

  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
}

export function share(title: string, text: string, url: string): void {
  if (navigator.share) {
    navigator.share({
      title,
      text,
      url,
    });
  }
}

// function takes 0, 1, 2, 3 to return 1, 10, 100, 1000
function getMultiplier(num: number): number {
  if (num === 0) return 1;
  return 10 ** num;
}

export function scrollToWithWait(
  element: string | HTMLElement | Element | null,
  options: {
    smoothScroll?: boolean;
    offset?: number;
    container?: HTMLElement;
    counter?: number;
  },
) {
  const { smoothScroll, offset, container, counter = 0 } = options;
  const isVisible = isVisibleInTopHalfOfScreen(element, container);
  if (counter >= 10 || isVisible) return;

  const timeOut = getMultiplier(counter);
  setTimeout(() => {
    const hasScrolled = scrollTo(element, smoothScroll, offset, container);
    if (!hasScrolled) scrollToWithWait(element, { smoothScroll, offset, container, counter: counter + 1 });
  }, timeOut);
}

export function copyToClipboard(content) {
  const span = document.createElement('span');
  span.textContent = content;
  span.style.whiteSpace = 'pre';
  span.style.position = 'absolute';
  span.style.left = '-9999px';
  span.style.top = '-9999px';

  const win = window;
  const selection = win.getSelection();
  win.document.body.appendChild(span);

  const range = win.document.createRange();
  selection?.removeAllRanges();
  range.selectNode(span);
  selection?.addRange(range);

  const success = win.document.execCommand('copy');

  selection?.removeAllRanges();
  span.remove();

  return success;
}

export function addEvent(object, type, callback): void {
  callback();

  if (object == null || typeof object === 'undefined') return;
  if (object.addEventListener) {
    object.addEventListener(type, callback, false);
  } else if (object.attachEvent) {
    object.attachEvent(`on${type}`, callback);
  }
}

export function isMobileBrowser(userAgent = navigator.userAgent): boolean {
  return !!(
    userAgent.match(/Android/i) ||
    userAgent.match(/webOS/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPod/i) ||
    userAgent.match(/BlackBerry/i) ||
    userAgent.match(/Windows Phone/i)
  );
}

export enum HAPTIC_FEEDBACK {
  SHORT = 30,
}

export function hapticFeedback(feedback: HAPTIC_FEEDBACK = HAPTIC_FEEDBACK.SHORT): void {
  if (window.navigator.vibrate) window.navigator.vibrate(feedback);
}

export function requestFullScreen($target): void {
  const doc = window.document;
  const docEl = $target || doc.documentElement;

  const funcToCall =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;

  funcToCall.call(docEl);
}

export function cancelFullScreen(): void {
  const doc = window.document;

  // @ts-ignore
  const funcToCall = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  funcToCall.call(doc);
}

export function print(): void {
  window.print();
}

export function listenOfIframeClick(onClick: () => void): () => void {
  function iframeBlurListener() {
    if (document?.activeElement?.nodeName === 'IFRAME') {
      onClick();
    }
  }
  window.addEventListener('blur', iframeBlurListener);
  return () => window.removeEventListener('blur', iframeBlurListener);
}

// Fetched from https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
export function hasSupportForWebp(feature: 'lossy' | 'lossless' | 'alpha' | 'animation' = 'lossy'): Promise<boolean> {
  const kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
  };
  const img = new Image();
  return new Promise((resolve) => {
    img.src = `data:image/webp;base64,${kTestImages[feature]}`;
    img.onload = () => {
      const result = img.width > 0 && img.height > 0;
      setGlobalVariableForBROWSERSUPPORT({ hasSupportForWebp: true });
      resolve(result);
    };
    img.onerror = () => {
      setGlobalVariableForBROWSERSUPPORT({ hasSupportForWebp: false });
      resolve(false);
    };
  });
}

export function getCSSVariableValue(variableName: string): string {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName);
}

export function setCSSVariableValue(variableName: string, value): void {
  document.documentElement.style.setProperty(variableName, value);
}

export function preventContextMenu(event): boolean {
  event.preventDefault();
  if (event.stopPropagation) event.stopPropagation();
  return false;
}

export function beforeTabClosePreventTabClose(e, message: string) {
  e.preventDefault();
  // This wont work on new browsers
  e.returnValue = message;
}

export function openInNewWindow(url: string, target: string, features?: string) {
  window.open(url, target, features);
}

export function getFileNameFromUrl(url: string) {
  const segments = url.split('/');
  const fileName = segments.pop();

  return fileName;
}

// will remove this later
// function createFileFromUrl(fileToShare: { fileType: string; url: string; fileName: string }): Promise<File> {
//   const { fileType, url, fileName } = fileToShare;

//   // Can add support for other types based on requirements
//   const customFileType = (() => {
//     if (fileType === 'image/png') {
//       return 'image/png';
//     }
//     if (fileType === 'image') {
//       return 'image/jpeg';
//     }
//     if (fileType === 'video') {
//       return 'video/mp4';
//     }
//     return 'image/jpeg';
//   })();

//   return get(url, {
//     responseType: 'blob',
//   }).then((response) => {
//     const blob = new Blob([response.data]);
//     return new File([blob], fileName || getFileName(url), { type: customFileType });
//   });
// }

function createFilesFromUrls(
  filesToShare: Array<{ fileType: string; url: string; fileName: string }>,
): Promise<Array<File>> {
  const urlToDataPromiseArray: Array<Promise<any>> = [];
  filesToShare.forEach((file) => {
    const { url } = file;
    urlToDataPromiseArray.push(
      get(url, {
        responseType: 'blob',
      }),
    );
  });
  // Can add support for other types based on requirements
  const customFileType = (fileType) => {
    if (fileType === 'image/png') {
      return 'image/png';
    }
    if (fileType === 'image') {
      return 'image/jpeg';
    }
    if (fileType === 'video') {
      return 'video/mp4';
    }
    return 'image/jpeg';
  };

  return Promise.all(urlToDataPromiseArray).then((dataArray) =>
    dataArray.map((item, idx) => {
      const blob = new Blob([item.data]);
      return new File([blob], filesToShare[idx].fileName || getFileNameFromUrl(filesToShare[idx].url) || '', {
        type: customFileType(filesToShare[idx].fileType),
      });
    }),
  );
}

export async function shareContent(
  options: {
    title?: string;
    message?: string;
    url?: string;
    filesToShare?: Array<{
      fileType: string;
      url: string; // Data URI
      fileName: string;
    }>;
  },
  cb: () => void,
) {
  const { title, message, url, filesToShare } = options;

  // NOTE: navigate.share wont be available on HTTP

  if (!navigator.canShare || !navigator.share) {
    return cb();
  }

  if (filesToShare?.length) {
    const files = await createFilesFromUrls(filesToShare);

    if (navigator.canShare({ title, text: message, url, files })) {
      try {
        return await navigator.share({
          title,
          text: message,
          url,
          files,
        });
      } catch (error) {
        // Try without file
        return shareContent({ title, message, url }, cb);
      }
    }
    return shareContent({ title, message, url }, cb);
  }

  if (
    navigator?.canShare({
      title,
      text: message,
      url,
    })
  ) {
    return navigator
      .share({
        title,
        text: message,
        url,
      })
      .catch((error) => logAction('Sharing Cancelled', error));
  }
}

export function unlistenBrowserBack() {
  backListener();
}

export function handleBrowserBack(onBackButtonClick: (location: any, action: string) => void, history: History) {
  // @ts-ignore
  backListener = history.block((location, action) => {
    if (action === 'POP') {
      setTimeout(() => {
        onBackButtonClick(location, action);
      }, 10);
      return false;
    }
    // Allow navigation by returning void implicitly
  });
}

export function disableBrowserBack(history: History) {
  // @ts-ignore
  backListener = history.block((location, action) => {
    if (action === 'POP') {
      return false;
    }
    // Allow navigation by returning void implicitly
  });
}

export function createSVGFile(svgEl) {
  svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  const svgData = svgEl.outerHTML;
  const preface = '<?xml version="1.0" standalone="no"?>\r\n';
  const svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' });
  return URL.createObjectURL(svgBlob);
}

export function downloadAsFile(options: { file: string; fileName: string }) {
  const { file, fileName } = options;

  // this can be used to download any image from webpage to local disk
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open('GET', file);
  xhr.send();
}

export function downloadFileSimple(options: { file: string; fileName: string; isVideo?: boolean }) {
  const { file, fileName, isVideo } = options;

  const anchor = document.createElement('a');
  anchor.href = file;
  anchor.download = fileName;
  anchor.title = fileName;
  anchor.rel = 'noopener noreferrer';
  anchor.target = isVideo ? '_blank' : '_self';
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
}

// Check if the user is running the website on Google Chrome
export function isChromeBrowser() {
  // @ts-ignore
  const { userAgent } = navigator;

  // Check if the User-Agent string contains "Chrome"
  return userAgent.includes('Chrome');
}

// Check if the user is running the website on Safari browser
export function isSafariBrowser() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isIOS() {
  // return (
  //   ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
  //   // iPad on iOS 13 detection
  //   (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  // );

  // add this if you want to test screentouch macbook
  // (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  return /iPad|iPhone|iPod/.test(navigator.platform);
}

export async function createBlobFromUrl(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching image blob:', error);
    return null;
  }
}

export function createURLFromBlob(imageBlob: Blob) {
  const imageUrl = URL.createObjectURL(imageBlob);
  return imageUrl;
}

export function revokeBlobUrlFromMemory(imageUrl: string) {
  URL.revokeObjectURL(imageUrl);
}

// create a function to trigger hapic feedback

export function triggerHapticFeedback(length: 'short' | 'long' = 'short') {
  const duration = length === 'short' ? 200 : 500;
  if (window.navigator.vibrate) {
    window.navigator.vibrate(duration);
  }
}

export function getIframeEmbedCode(options: { url: string; id: string; name: string }): string {
  const { url, id, name } = options;

  return `<iframe
            id="${id}"
            src="${url}"
            style="border: 0px #ffffff none"
            name="${name}"
            scrolling="no"
            frameborder="0"
            marginheight="0px"
            marginwidth="0px"
            width="100%"
            allow="camera *;microphone *; web-share"></iframe>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js"></script><script type="text/javascript">iFrameResize({log:false,checkOrigin:false},"#${id}");</script>`;
}

export function isInsideIFrame() {
  return window.self !== window.top;
}

export function isInsideAppleWebKitView() {
  return window.navigator.userAgent.includes('AppleWebKit');
}

export function getFileFromBlob(blob: Blob, fileName: string, mimeType: string) {
  return new File([blob], fileName, {
    type: mimeType,
  });
}
