import { IntegrationService } from '@premagic/core';
import { Button, BUTTON_STYLES, Col, FormFooter, Modal, MODAL_SIZES, Row } from '@premagic/myne';
import { useCallback, useEffect, useState } from 'react';
import IntegrationEventCustomFieldsSelectorContainer from './IntegrationEventCustomFieldsSelectorContainer';
import IntegrationEventSelectorListContainer from './IntegrationEventSelectorListContainer';
import { INTEGRATION_CONNECT_OPTION, INTEGRATION_STEP } from './IntegrationStepService';

type ModalComponentWrapperProps = {
  children: React.ReactElement;
  resetToFirstStep: () => void;
};

function ModalComponentWrapper(props: ModalComponentWrapperProps) {
  const { children, resetToFirstStep } = props;

  // set step to first default step on modal close
  useEffect(() => () => resetToFirstStep(), [resetToFirstStep]);

  return children;
}

const COMPONENT_MAP = {
  [INTEGRATION_STEP.SELECT_EVENT]: IntegrationEventSelectorListContainer,
  [INTEGRATION_STEP.SELECT_CUSTOM_FIELD]: IntegrationEventCustomFieldsSelectorContainer,
};

type Props = {
  show: boolean;
  close: () => void;
  platform: IntegrationService.INTEGRATION_PLATFORMS;
  connectOption: INTEGRATION_CONNECT_OPTION;
  projectId?: string;
};

export default function IntegrationEventsModal(props: Props) {
  const { show, close, platform, connectOption, projectId } = props;

  const [integrationStep, setIntegrationStep] = useState(INTEGRATION_STEP.SELECT_EVENT);
  const Component = COMPONENT_MAP[integrationStep];

  const resetToFirstStep = useCallback(() => {
    setIntegrationStep(INTEGRATION_STEP.SELECT_EVENT);
  }, []);

  return (
    <Modal show={show} close={close} size={MODAL_SIZES.XL}>
      <ModalComponentWrapper resetToFirstStep={resetToFirstStep}>
        <Component
          platform={platform}
          setIntegrationStep={setIntegrationStep}
          closeModal={close}
          connectOption={connectOption}
          projectId={projectId}
        />
      </ModalComponentWrapper>
    </Modal>
  );
}
