import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';

import { CountryCodeUtils } from '@premagic/utils';
import UserFormContainer from '../edit/UserFormContainer';

type Props = {
  createNewUser: (data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
};

export default function NewUserPanel(props: Props): JSX.Element {
  const { show, createNewUser, isLoading, errors, closePanel } = props;
  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Add team member to your account</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={createNewUser}
          formSpec={{
            name: {
              validator: 'required',
            },
            phone_number: {
              validator: (value) => CountryCodeUtils.isValidPhoneNumber(value),
              message: (value) => {
                if (!CountryCodeUtils.isValidPhoneNumber(value)) return 'Enter a valid number';
                return 'This is Required';
              },
            },
          }}
        >
          <UserFormContainer />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Invite team member
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
