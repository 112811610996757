import React from 'react';
import { PageAlert, PAGE_ALERT_STYLES, PageAlerts } from '@premagic/myne';
import { UserType } from '../crm/users/UsersService';
import { AccountAppAlertType } from './AccountAppAlertService';

type Props = {
  user?: UserType;
  items: Array<AccountAppAlertType>;
  removeAlert: (id: string) => void;
};

export default function AppAlerts(props: Props): JSX.Element | null {
  const { user, items, removeAlert } = props;
  if (items.length === 0) return null;
  return (
    <PageAlerts>
      {items.map((item) => (
        <PageAlert
          style={item.type as unknown as PAGE_ALERT_STYLES}
          key={item.id}
          onClose={item.readOnly ? undefined : () => removeAlert(item.id)}
        >
          {item.title}
        </PageAlert>
      ))}
    </PageAlerts>
  );
}
