import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';

import ClientWebsiteThemeInfoForm from './ClientWebsiteThemeInfoForm';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;

  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    hasPremiumDigitalInviteFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE_PREMIUM,
    )(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteThemeInfoForm);
