import { UploaderDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';

import FolderUploadProgress from './FolderUploadProgress';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
  },
) {
  const { folderId } = ownProps;

  return {
    folderId,
    progress: UploaderDuck.uploadProgressForFolderSelector(folderId)(state),
    stats: UploaderDuck.uploadProgressStatsForFolderSelector(folderId)(state),
  };
}

export default connect(mapStateToProps)(FolderUploadProgress);
