import React from 'react';
import {
  Space,
  Col,
  Row,
  Color,
  Icon,
  Text,
  COLOR_SHADES,
  TEXT_SIZE,
  TEXT_BOLDNESS,
  BUTTON_STYLES,
  Button,
  BUTTON_SIZE,
  ListItem,
  LIST_ITEM_STYLES,
  ICON_SIZES,
  UserFeedback,
  WindowPanel,
  WindowPanelHeader,
  WindowPanelTitle,
  WindowPanelContent,
} from '@premagic/myne';
import { HelpWidgetUtils } from '@premagic/utils';
import AccountSupportAccessToggleContainer from './AccountSupportAccessToggleContainer';
import HaveAQuestion from './HaveAQuestion';

type HelpItemProps = {
  title: string;
  onClick: () => void;
  iconName: string;
  display: boolean;
  description?: string;
};

function HelpItem(props: HelpItemProps) {
  const { iconName, title, display, onClick, description } = props;
  return (
    <>
      <Row>
        <Col size={null}>
          <Space vertical size={1} />
          <Color shade={COLOR_SHADES.DARKER} inline>
            <Icon name={iconName} />
          </Color>
        </Col>
        <Space />
        <Col size={10}>
          <Button
            style={BUTTON_STYLES.LINK_SECONDARY}
            size={BUTTON_SIZE.XS}
            cssStyle={{ padding: 0 }}
            onClick={onClick}
          >
            <Text size={TEXT_SIZE.SIZE_3} block color={COLOR_SHADES.BLUE_LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
              {title}
            </Text>
          </Button>
          <Text block>{description}</Text>
        </Col>
      </Row>
      <Space vertical size={8} />
    </>
  );
}

type Props = {
  toggleTour: (status: boolean) => void;
  show: boolean;
  close: () => void;
  isCompanyTypeNotPhotographer: boolean;
};

export default function AccountHelpPanel(props: Props) {
  const { toggleTour, show, close, isCompanyTypeNotPhotographer } = props;
  const HELP: Array<HelpItemProps> = [
    {
      title: 'FAQ',
      iconName: 'message_square',
      display: true,
      description: 'Check out the frequently asked questions about Premagic',
      onClick: () => {
        HelpWidgetUtils.showHelpWidget();
      },
    },
    {
      title: 'Chat with support',
      iconName: 'archive',
      display: false,
      description: '',
      onClick: () => null,
    },
    {
      title: 'Report issues',
      iconName: 'alert_triangle',
      display: true,
      description: 'Let our team know about a problem you are facing',
      onClick: () => {
        const data = {
          subject: '',
          description: 'What is the issue you are facing?',
        };
        HelpWidgetUtils.showSupportForm();
        HelpWidgetUtils.prefillSupportForm(data);
      },
    },
  ];

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <Row vcenter>
          <Icon name="help_circle" />
          <Space size={4} />
          <WindowPanelTitle>Help</WindowPanelTitle>
        </Row>
      </WindowPanelHeader>
      <WindowPanelContent>
        {HELP.filter((setting) => setting.display).map((setting) => (
          <HelpItem
            key={setting.title}
            title={setting.title}
            iconName={setting.iconName}
            onClick={setting.onClick}
            display={setting.display}
            description={setting.description}
          />
        ))}
        {!isCompanyTypeNotPhotographer && (
          <Row>
            <Col size={null}>
              <Space vertical size={1} />
              <Color shade={COLOR_SHADES.DARKER} inline>
                <Icon name="play_circle" />
              </Color>
            </Col>
            <Space />
            <Col size={10}>
              <Text size={TEXT_SIZE.SIZE_3} block boldness={TEXT_BOLDNESS.BOLD}>
                Tutorials
              </Text>
              <Text block>Explore the Premagic feature using walk-throughs</Text>
              <ul>
                <li>
                  <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST} onClick={() => toggleTour(true)}>
                    <Row vcenter>
                      <Text>Getting started with Premagic</Text>
                      <Space size={2} />
                      <Color shade={COLOR_SHADES.BLUE} inline>
                        <Icon name="arrow_right" size={ICON_SIZES.SM} />
                      </Color>
                    </Row>
                  </ListItem>
                </li>
              </ul>
              <Space vertical size={6} />
            </Col>
          </Row>
        )}

        <Row>
          <Col size={null}>
            <Space vertical size={1} />
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="lock" />
            </Color>
          </Col>
          <Space />
          <Col size={10}>
            <Text size={TEXT_SIZE.SIZE_3} block boldness={TEXT_BOLDNESS.BOLD}>
              Support options & permissions
            </Text>
            <Text block>Manage your privacy settings</Text>
            <Space vertical />
            <AccountSupportAccessToggleContainer />
          </Col>
        </Row>
        <Space vertical size={8} />
        <Row>
          <Col size={null}>
            <Space vertical size={1} />
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="phone" />
            </Color>
          </Col>
          <Space />
          <Col size={10}>
            <Text size={TEXT_SIZE.SIZE_3} block boldness={TEXT_BOLDNESS.BOLD}>
              Contact us on
            </Text>
            <HaveAQuestion displayHeading={false} />
          </Col>
        </Row>
        <Space vertical size={8} />
        <Row>
          <Col size={null}>
            <Space vertical size={1} />
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="feather" />
            </Color>
          </Col>
          <Space />
          <Col size={10}>
            <UserFeedback module="premagic">
              <Text size={TEXT_SIZE.SIZE_3} block color={COLOR_SHADES.BLUE_LIGHT} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                Share your feedback?
              </Text>
            </UserFeedback>
            <Text block>Help us serve you better</Text>
          </Col>
        </Row>
      </WindowPanelContent>
    </WindowPanel>
  );
}
