import { EventAttendeesCheckinZoneService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import EventAttendeeFaceChekinZoneFormContainer from './face-checkin-zone-form/EventAttendeeFaceChekinZoneFormContainer';

type Props = {
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading?: boolean;
  error?: Record<string, string>;
  createNewZone: (projectId: string, data: EventAttendeesCheckinZoneService.NewEventAttendeeCheckinZoneType) => void;
};

export default function AddMemoryTemplatePanel(props: Props) {
  const { show, close, projectId, isLoading, error, createNewZone } = props;

  function handleFormSubmit(formResponse) {
    createNewZone(projectId, formResponse.data);
    close();
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add New Zone</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={error} onSubmit={handleFormSubmit}>
          <EventAttendeeFaceChekinZoneFormContainer projectId={projectId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
