import { ClientSettingsService } from '@premagic/core';
import {
  BUTTON_STYLES,
  Button,
  COLOR_SHADES,
  FormGroup,
  RadioButton,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { MediaUtils } from '@premagic/utils';
import React, { useState } from 'react';

const HIGHLIGHT_UPLOAD_QUALITIES = {
  [MediaUtils.UPLOAD_QUALITY_TYPES.ORIGINAL]: {
    title: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.ORIGINAL].title,
    id: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.ORIGINAL].id,
    disabledMessage: (
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.BLUE}>
        Contact support to enable this for you
      </Text>
    ),
    info: (
      <div>
        <Text muted block>
          Premagic will upload the photos{' '}
          <Text boldness={TEXT_BOLDNESS.BOLD} muted>
            with no optimization.
          </Text>{' '}
        </Text>
        <Space vertical size={1} />
        <Text boldness={TEXT_BOLDNESS.BOLD} muted>
          NOTE:
        </Text>{' '}
        <Text muted>
          Not Recommended as it uploads unoptimized photos with unwanted pixels. This will significantly increase the
          upload time and requires good internet connection.
        </Text>
      </div>
    ),
  },
  [MediaUtils.UPLOAD_QUALITY_TYPES.SUPERFINE]: {
    title: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.SUPERFINE].title,
    id: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.SUPERFINE].id,
    disabledMessage: (
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.BLUE}>
        Upgrade your account to enable this option
      </Text>
    ),
    info: (
      <div>
        <Text muted block>
          Premagic will upload{' '}
          <Text boldness={TEXT_BOLDNESS.BOLD} muted>
            full resolution, optimised photos
          </Text>{' '}
          by removing all the unwanted pixels.
        </Text>
        <Space vertical size={1} />
        <Text boldness={TEXT_BOLDNESS.BOLD} muted>
          NOTE:
        </Text>{' '}
        <Text muted>
          Recommended if you just want to back up and use it for printing very large photos. This will increase the
          upload time.
        </Text>
      </div>
    ),
  },
  [MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_4K]: {
    title: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_4K].title,
    id: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_4K].id,
    info: (
      <div>
        <Text muted block>
          Premagic will upload 12MP optimised{' '}
          <Text boldness={TEXT_BOLDNESS.BOLD} muted>
            4K Resolution
          </Text>{' '}
          photos.
        </Text>
        <Space vertical size={1} />
        <Text boldness={TEXT_BOLDNESS.BOLD} muted>
          NOTE:
        </Text>{' '}
        <Text muted>
          Recommended option for optimal upload time and quality. Good for posting it on social media and printing 13" x
          10" 300dpi True Quality Photos.
        </Text>
      </div>
    ),
  },
  [MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_2K]: {
    title: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_2K].title,
    id: MediaUtils.UPLOAD_QUALITY_DETAILS[MediaUtils.UPLOAD_QUALITY_TYPES.QUALITY_2K].id,
    info: (
      <div>
        <Text muted block>
          Premagic will optimise the photos and upload{' '}
          <Text boldness={TEXT_BOLDNESS.BOLD} muted>
            Full HD Resolution
          </Text>{' '}
          photos.
        </Text>
        <Space vertical size={1} />
        <Text boldness={TEXT_BOLDNESS.BOLD} muted>
          NOTE:
        </Text>{' '}
        <Text muted>Recommended option if you have slow internet connection.</Text>
      </div>
    ),
  },
};

type HighlightAlbumQualitySelectorProps = {
  selectedQuality?: string;
  enable4KQuality: boolean;
  enableOriginalResolution: boolean;
  enableOriginalBloatedFile: boolean;
};

function HighlightAlbumQualitySelector(props: HighlightAlbumQualitySelectorProps) {
  const { selectedQuality, enable4KQuality, enableOriginalResolution, enableOriginalBloatedFile } = props;

  const defaultSelected = selectedQuality || HIGHLIGHT_UPLOAD_QUALITIES.QUALITY_4K.id;
  const selectedDetails = HIGHLIGHT_UPLOAD_QUALITIES[defaultSelected];

  const [showUpdateSetting, setShowUpdateSetting] = useState(false);

  if (showUpdateSetting) {
    return (
      <FormGroup>
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          Signature album upload quality
        </Text>
        <Space vertical size={1} />
        {Object.values(HIGHLIGHT_UPLOAD_QUALITIES).map((option) => {
          let isDisabled: boolean;
          switch (option.id) {
            case HIGHLIGHT_UPLOAD_QUALITIES.ORIGINAL.id:
              isDisabled = !enableOriginalBloatedFile;
              break;
            case HIGHLIGHT_UPLOAD_QUALITIES.SUPERFINE.id:
              isDisabled = !enableOriginalResolution;
              break;
            case HIGHLIGHT_UPLOAD_QUALITIES.QUALITY_4K.id:
              isDisabled = !enable4KQuality;
              break;
            case HIGHLIGHT_UPLOAD_QUALITIES.QUALITY_2K.id:
              // This option is available for everyone
              isDisabled = false;
              break;
            default:
              isDisabled = true;
          }
          return (
            <>
              <RadioButton
                name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_UPLOAD_QUALITY_WEB}
                key={option.id}
                value={option.id}
                defaultChecked={option.id === defaultSelected}
                disabled={isDisabled}
              >
                <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                  {/* @ts-ignore */}
                  {option.title} <Space /> {isDisabled && option.disabledMessage}
                </Text>
              </RadioButton>
              <Row>
                <Space size={6} />
                {option.info}
              </Row>
              <Space vertical size={2} />
            </>
          );
        })}
      </FormGroup>
    );
  }

  return (
    <>
      <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
        Signature album upload quality
      </Text>
      <Space vertical size={1} />
      <Text>
        {selectedDetails.title}{' '}
        <Button style={BUTTON_STYLES.LINK} onClick={() => setShowUpdateSetting(true)}>
          edit
        </Button>
      </Text>
    </>
  );
}

type HighlightUploadSettingsProps = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  enable4KQuality: boolean;
  enableOriginalResolution: boolean;
  enableOriginalBloatedFile: boolean;
};

export default function HighlightUploadSettings(props: HighlightUploadSettingsProps) {
  const { clientSettings, enable4KQuality, enableOriginalResolution, enableOriginalBloatedFile } = props;

  const selectedQuality =
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_UPLOAD_QUALITY_WEB];

  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
        Upload Settings
      </Text>
      <Space vertical size={2} />
      <HighlightAlbumQualitySelector
        selectedQuality={selectedQuality}
        enable4KQuality={enable4KQuality}
        enableOriginalResolution={enableOriginalResolution}
        enableOriginalBloatedFile={enableOriginalBloatedFile}
      />
    </div>
  );
}
