import React from 'react';

import { Col, COLOR_SHADES, Row, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import { ClientsService, CompanyService } from '@premagic/core';

import { CountryCodeUtils } from '@premagic/utils';
import CompanyLogoContainer from '../../../../settings/company/CompanyLogoContainer';
import { AccountType } from '../../../../acccount/AccountService';

type ClientDetailsProps = {
  client?: ClientsService.ClientType;
};

export function ClientDetails(props: ClientDetailsProps): React.ReactElement {
  const { client } = props;
  const { name, email, phone_number: phone, address } = client || {};
  return (
    <div>
      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2} block>
        {name}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
        {email}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
        {phone}
      </Text>
      <Text color={COLOR_SHADES.DARKER} block>
        {address}
      </Text>
    </div>
  );
}
type CompanyDetailsAddressProps = {
  account: AccountType;
};

export function CompanyDetailsAddress(props: CompanyDetailsAddressProps): React.ReactElement {
  const { account } = props;
  const { billing_address_street_1: billingAddress, tax_id: taxId, country, city, state, zipcode } = account || {};
  const countryName = CountryCodeUtils.COUNTRY_CODES.find((item) => item.code === country)?.name;
  return (
    <div>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_4} block>
        {billingAddress}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_4} block>
        {city}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_4} block>
        {state} - {zipcode}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_4} block>
        {countryName}
      </Text>
      {taxId && (
        <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
          GST: {taxId}
        </Text>
      )}
    </div>
  );
}

type CompanyDetailsProps = {
  company: CompanyService.CompanyType;
  account?: AccountType;
};

export function CompanyDetails(props: CompanyDetailsProps): React.ReactElement {
  const { company, account } = props;
  const { name, contacts } = company || {};
  const { email, phone, website, location } = contacts || {};
  return (
    <div>
      <CompanyLogoContainer />
      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2} block>
        {name}
      </Text>
      {account?.billing_address_street_1 ? (
        <CompanyDetailsAddress account={account} />
      ) : (
        <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
          {location}
        </Text>
      )}

      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
        {website}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
        {email}
      </Text>
      <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3} block>
        {phone}
      </Text>
    </div>
  );
}

type Props = {
  client?: ClientsService.ClientType;
  company: CompanyService.CompanyType;
  account?: AccountType;
};

export default function ClientPaymentsInvoicePageHeader(props: Props): React.ReactElement {
  const { client, company, account } = props;
  return (
    <Row>
      <Col>
        <ClientDetails client={client} />
      </Col>
      <Col size={null} rightAlighed>
        <CompanyDetails company={company} account={account} />
      </Col>
    </Row>
  );
}
