import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Emoji,
  EMOJIE_SIZE,
  EMOJIS,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  Input,
  Page,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ClientSettingsService, CompanyService } from '@premagic/core';
import CompanyLogoUploadContainer from '../settings/company/CompanyLogoUploadContainer';
import CompanyTypeInput from '../settings/branding/CompanyTypeInput';

type Props = {
  view: string;
  type: string;
  name: string;
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
  isSaving: boolean;
  saveCompanyData: (data, company: CompanyService.CompanyType) => void;
  errors: Record<string, string>;
  company: CompanyService.CompanyType;
};

export default function WelcomePage(props: Props) {
  const { type, view, name, companyType, isSaving, saveCompanyData, errors, company } = props;

  const isNewClient = company.total_num_projects === 0;
  const hasSetupCustomDomain = !!company.custom_domain;

  function handleSubmit(formResponse) {
    saveCompanyData(formResponse, company);
  }

  return (
    <Page center screenHeight>
      <Row center>
        <Col size={6} screenSMSize={12}>
          <Text size={TEXT_SIZE.SIZE_1} primaryStyle>
            Welcome,{' '}
            <Text size={TEXT_SIZE.SIZE_1} color={COLOR_SHADES.DARKER} primaryStyle>
              {name}
            </Text>
            <Space />
            <Emoji name={EMOJIS.WAVE} size={EMOJIE_SIZE.LG} />
          </Text>
          <Space vertical size={12} />

          <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} block boldness={TEXT_BOLDNESS.BOLD}>
            Let's set up your account
          </Text>
          <Text>
            Tell us more about your company so we can provide you a personalized experience tailored to your needs.
          </Text>
          <Space vertical />
          <Form
            onSubmit={handleSubmit}
            errors={errors}
            formSpec={{
              company_type: {
                validator: 'required',
              },
            }}
          >
            <CompanyLogoUploadContainer showJustPrimaryLogo />
            <Space vertical />
            {isNewClient &&
              !hasSetupCustomDomain && ( // Dont want existing clients to change their domain
                <FormGroup>
                  <FormLabel>Company Domain</FormLabel>
                  <Row vcenter>
                    <Col>
                      <Input name="domain" defaultValue={company.domain} minLength={4} maxLength={64} />
                    </Col>
                    <Space size={1} />
                    <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
                      .premagic.com
                    </Text>
                  </Row>
                </FormGroup>
              )}
            <FormGroup>
              <FormLabel>Company industry</FormLabel>
              <CompanyTypeInput defaultValue={companyType} />
            </FormGroup>
            <FormFooter>
              <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                Next
              </Button>
            </FormFooter>
          </Form>
        </Col>
      </Row>
    </Page>
  );
}
