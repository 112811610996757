import React from 'react';

import {
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Divider,
  NOTIFICATION_DOT_STYLES,
  NotificationDot,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { ClientWishService } from '@premagic/core';
import ClientWishApprovalContainer from './ClientWishApprovalContainer';
import ClientWishFileContainer from '../client-files/ClientWishFileContainer';

type Props = {
  clientWish: ClientWishService.ClientWishType;
  projectId: string;
  folderId: string;
  id: string;
};

export default function ClientWishItem(props: Props): JSX.Element {
  const { clientWish, id, folderId, projectId } = props;
  const { message, name, status, files = [] } = clientWish;
  const isInReview = status === ClientWishService.CLIENT_WISH_STATUS.REVIEW;
  const isRejectedWish = status === ClientWishService.CLIENT_WISH_STATUS.REJECT;

  return (
    <Card>
      {isInReview && <NotificationDot style={NOTIFICATION_DOT_STYLES.LIST} />}
      <Color disabled={isRejectedWish}>
        <Space>
          {files.map((fileId) => (
            <ClientWishFileContainer id={fileId} key={fileId} />
          ))}
          <Text color={COLOR_SHADES.DARKER}>{message}</Text>
          <Space vertical />
          <Row>
            <Col rightAlighed size={null}>
              <Text>by {name}</Text>
            </Col>
          </Row>
          <Space vertical />
        </Space>
      </Color>
      <Divider />
      <ClientWishApprovalContainer id={id} folderId={folderId} projectId={projectId} />
      <Space vertical size={1} />
    </Card>
  );
}
