import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils } from '@premagic/utils';
import { RouterService } from '@premagic/core';

import { CRMFiltersType } from '../../filters/CRMFilterTypes';
import { getCRMDashboardUrlWithFilters } from '../../filters/CRMFilterService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_DASHBOARD_FILTERS', true);

export const navigateToCRMDashboardPageWithFilter = createAction(
  getActionType('NAVIGATE_WITH_FILTER'),
  (dispatch, view: 'list' | 'calendar', filters: CRMFiltersType) => {
    RouterService.navigateTo(dispatch, getCRMDashboardUrlWithFilters(view, filters));
  },
);

const initialState = {};

export default handleActions({}, initialState);
