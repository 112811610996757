import { EVENT_CATEGORY } from './EventsService';
import { CLIENT_COMPANY_TYPES } from './ClientSettingsService';

export const EVENT_CATEGORY_DETAILS: Record<
  EVENT_CATEGORY,
  {
    id: EVENT_CATEGORY;
    title: string;
    icon: string;
    forCompanyType: Array<CLIENT_COMPANY_TYPES>;
  }
> = {
  [EVENT_CATEGORY.BIRTHDAY]: {
    id: EVENT_CATEGORY.BIRTHDAY,
    title: 'Birthday',
    icon: 'cake',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY, CLIENT_COMPANY_TYPES.EVENT],
  },
  [EVENT_CATEGORY.CORPORATE]: {
    id: EVENT_CATEGORY.CORPORATE,
    title: 'Corporate',
    icon: 'corporate',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY, CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
  [EVENT_CATEGORY.COUPLE_SHOOT]: {
    id: EVENT_CATEGORY.COUPLE_SHOOT,
    title: 'Couple Shoot',
    icon: 'couple_shoot',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.FAMILY_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.FAMILY_PHOTO_SHOOT,
    title: 'Family photoshoot',
    icon: 'family',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.MATERNITY_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.MATERNITY_PHOTO_SHOOT,
    title: 'Maternity photoshoot',
    icon: 'maternity',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.MODEL_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.MODEL_PHOTO_SHOOT,
    title: 'Fashion model photoshoot',
    icon: 'modeling',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.NEWBORN_BABY]: {
    id: EVENT_CATEGORY.NEWBORN_BABY,
    title: 'New born baby photoshoot',
    icon: 'baby',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.PRODUCT_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.PRODUCT_PHOTO_SHOOT,
    title: 'Product photoshoot',
    icon: 'product',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.REAL_ESTATE_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.REAL_ESTATE_PHOTO_SHOOT,
    title: 'Real-estate photoshoot',
    icon: 'real_estate',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.RESTAURANT_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.RESTAURANT_PHOTO_SHOOT,
    title: 'Restaurant photoshoot',
    icon: 'food',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.THEME_PHOTO_SHOOT]: {
    id: EVENT_CATEGORY.THEME_PHOTO_SHOOT,
    title: 'Theme photoshoot',
    icon: 'settings_cam',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY],
  },
  [EVENT_CATEGORY.WEDDING]: {
    id: EVENT_CATEGORY.WEDDING,
    title: 'Wedding',
    icon: 'wedding',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY, CLIENT_COMPANY_TYPES.EVENT],
  },
  [EVENT_CATEGORY.ENGAGEMENT]: {
    id: EVENT_CATEGORY.ENGAGEMENT,
    title: 'Engagement',
    icon: 'wedding',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY, CLIENT_COMPANY_TYPES.EVENT],
  },
  [EVENT_CATEGORY.CUSTOM]: {
    id: EVENT_CATEGORY.CUSTOM,
    title: 'Others',
    icon: 'settings_cam',
    forCompanyType: [CLIENT_COMPANY_TYPES.PHOTOGRAPHY, CLIENT_COMPANY_TYPES.EVENT, CLIENT_COMPANY_TYPES.ORGANIZER],
  },
};

export function isWeddingEvent(eventType?: EVENT_CATEGORY | string): boolean {
  if (!eventType) return false;
  return [EVENT_CATEGORY.WEDDING, EVENT_CATEGORY.ENGAGEMENT, EVENT_CATEGORY.COUPLE_SHOOT].includes(
    eventType as EVENT_CATEGORY,
  );
}

export function getEventCategoryDetails(categoryName: string | EVENT_CATEGORY): {
  id: EVENT_CATEGORY;
  title: string;
  icon: string;
} {
  if (categoryName && categoryName in EVENT_CATEGORY) return EVENT_CATEGORY_DETAILS[categoryName];
  return EVENT_CATEGORY_DETAILS[EVENT_CATEGORY.CUSTOM];
}
