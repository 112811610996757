import { FileService } from '@premagic/core';
import { Col, GALLERY_IMAGE_STYLE } from '@premagic/myne';
import { FolderGalleryImage } from '../../../../projects/folders/gallery/FolderGallery';

type Props = { fileId?: string; files: Record<string, FileService.FolderFileType> };

export default function ExhibitorLogoPicture(props: Props) {
  const { fileId, files } = props;

  const selectedFile = fileId ? files[fileId] : undefined;
  if (!selectedFile) return null;

  return (
    <Col size={1}>
      <FolderGalleryImage fileId={selectedFile.id} file={selectedFile} style={GALLERY_IMAGE_STYLE.CONTAIN} />
    </Col>
  );
}
