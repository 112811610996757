import { BrowserUrlUtils } from '@premagic/utils';
import { connect } from 'react-redux';
import { navigateTo } from '../../../../../../services/RouterService';
import {
  filesArrayIdsSortedByFolderIdForNavigationSelector,
  filesArrayIdsSortedByRankForNavigationSelector,
  filesSelector,
} from '../../../images/FilesDataDuck';
import { DAMLibraryFiltersType } from '../filters/DAMLibraryFilterTypes';
import DAMLibraryImagesPopup from './DAMLibraryImagesPopup';

function mapStateToProps(state, ownProps) {
  const { fileId, filters, folderId } = ownProps.match.params;

  const file = filesSelector(state)[fileId];

  // if folderId is present than navigate by folderId else by rank/relevance
  const { previousFileId, nextFileId, totalFiles } = folderId
    ? filesArrayIdsSortedByFolderIdForNavigationSelector(fileId)(state)
    : filesArrayIdsSortedByRankForNavigationSelector(fileId)(state);

  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as DAMLibraryFiltersType;

  const { project_id: projectIds } = filterObject;

  return {
    id: fileId,
    file,
    filters,
    nextFileId,
    previousFileId,
    totalFiles,
    projectId: Array.isArray(projectIds) ? projectIds[0] : projectIds,
    folderId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (url) => {
      navigateTo(dispatch, url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryImagesPopup);
