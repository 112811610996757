import { useEffect, useMemo } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  FormErrorType,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  List,
  LoadingBox,
  Row,
  Space,
  Text,
} from '@premagic/myne';
import { debounce } from 'lodash';

import { EventAttendeesService, LiveEventService } from '@premagic/core';
import MESSAGES from '../../../../../../common/Messages';
import EventAttendeeItemContainer from './EventAttendeeItemContainer';
import UploadAndEditBulkAttendeeListModalContainer from './bulk-event-attendee/modal/UploadAndEditBulkAttendeeListModalContainer';
import EventAttendeeListMenuContainer from './EventAttendeeListMenuContainer';

type Props = {
  isLoading: boolean;
  attendeeIds: Array<string>;
  projectId: string;
  showAddAttendee: () => void;
  initData: (projectId: string) => void;
  searchTerm?: string;
  updateSearchTerm: (str: string) => void;
  errors?: FormErrorType;
  hasListWithPIIInformationFeature: boolean;
  hasAttendeePoster: boolean;
  isFeatureMagazineEnabled: boolean;
  fetchPosters: (projectId: string) => void;
  addEventAttendeeData: (data: EventAttendeesService.EventAttendeeType) => void;
};

export default function EventAttendeesList(props: Props): JSX.Element {
  const {
    projectId,
    isLoading,
    attendeeIds,
    showAddAttendee,
    initData,
    updateSearchTerm,
    searchTerm,
    errors,
    hasListWithPIIInformationFeature,
    hasAttendeePoster,
    isFeatureMagazineEnabled,
    fetchPosters,
    addEventAttendeeData,
  } = props;

  const debouncedUpdateSearchTerm = useMemo(() => debounce(updateSearchTerm, 500), [updateSearchTerm]);

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  useEffect(() => {
    if (hasAttendeePoster || isFeatureMagazineEnabled) fetchPosters(projectId);
  }, [fetchPosters, hasAttendeePoster, isFeatureMagazineEnabled, projectId]);

  useEffect(() => {
    LiveEventService.listenOnChannelForProject(
      projectId,
      LiveEventService.LIVE_EVENTS.NEW_ATTENDEE_REGISTRATION,
      (data) => {
        addEventAttendeeData(data.registration);
      },
    );
  }, [addEventAttendeeData, projectId]);

  const hasNoPeople = !searchTerm && attendeeIds.length === 0;
  if (isLoading) return <LoadingBox isLoading />;

  if (errors)
    return (
      <div>
        <Text color={COLOR_SHADES.DANGER} block>
          Oops... Something went wrong
        </Text>
        <Text>{errors.message}</Text>
      </div>
    );

  return (
    <div>
      <Row vcenter>
        <Col size={8}>
          <Input
            name="search"
            buttonIcon={<Icon name="search" size={ICON_SIZES.MD} />}
            iconPlacement="left"
            placeholder="Search by Name, Email, Phone, etc."
            defaultValue={searchTerm}
            size={INPUT_SIZES.AUTO}
            onChange={(e) => debouncedUpdateSearchTerm(e.target.value)}
          />
        </Col>
        <Space size={4} />
        <Col rightAlighed size={null}>
          <Row vcenter>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              onClick={showAddAttendee}
              size={BUTTON_SIZE.SM}
              disabled={!hasListWithPIIInformationFeature}
              disabledMessage={MESSAGES.EVENT_ATTENDEES.ADD_PEOPLE_UPGRADE}
            >
              <Icon name="plus" size={ICON_SIZES.SM} /> <Space size={1} />
              {MESSAGES.EVENT_ATTENDEES.NAME}
            </Button>
            <EventAttendeeListMenuContainer projectId={projectId} />
          </Row>
        </Col>
      </Row>
      <Space vertical />
      <Card>
        {searchTerm && attendeeIds.length === 0 && (
          <Row center>
            <Space>
              <Text muted>
                No {MESSAGES.EVENT_ATTENDEES.NAME} found for <Text color={COLOR_SHADES.DARKER}>{searchTerm}</Text> in
                Name, Email, Phone, Registration id, Company or role.
              </Text>
            </Space>
          </Row>
        )}
        {hasNoPeople && (
          <Space>
            <Text muted block>
              No {MESSAGES.EVENT_ATTENDEES.NAME} yet
            </Text>
          </Space>
        )}
        <List>
          {attendeeIds.map((id) => (
            <EventAttendeeItemContainer id={id} projectId={projectId} key={id} />
          ))}
        </List>
      </Card>
      <Space vertical />
      <UploadAndEditBulkAttendeeListModalContainer projectId={projectId} />
    </div>
  );
}
