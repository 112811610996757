import { EventPosterService, FolderService } from '@premagic/core';
import { FormGroup, FormLabel, Select } from '@premagic/myne';
import { PosterGuestImageGalleryType } from '@premagic/poster-creator';
import React from 'react';

type Props = {
  poster: EventPosterService.EventPosterType;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterGuestImageGalleryType;
  }) => void;
  projectId: string;
  widget: PosterGuestImageGalleryType;
  folderIds: Array<string>;
  folders: Record<string, FolderService.FolderType>;
};

export default function EventPosterFolderSelector(props: Props) {
  const { poster, editWidget, projectId, widget, folders, folderIds } = props;
  const key = 'auto_populate_value_from_folder_id';
  const options = folderIds
    .map((id) => ({
      label: folders[id].folder_name,
      value: id,
    }))
    .concat({ label: 'None', value: '' });
  const defaultOption = options.find((option) => option.value === widget[key] || '');

  function onChange(option) {
    const { value } = option;
    const updatedWidget = { ...widget, [key]: value || null };
    editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
  }

  return (
    <FormGroup>
      <FormLabel>Folder</FormLabel>
      <Select name="" options={options} onChange={onChange} value={defaultOption} isMulti={false} />
    </FormGroup>
  );
}
