import { connect } from 'react-redux';
import { ProjectOrdersService, PricingPlanFeaturesService } from '@premagic/core';

import ClientWebsiteThemeEditPage from './ClientWebsiteThemeEditPage';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { getClientWebsite, saveClientWebsite } from '../ClientWebsiteDataDuck';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { activeOrderIdsForProjectAndFolderSelector } from '../../project-orders/ProjectOrdersDataDuck';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps.match.params;

  return {
    id,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.FETCH_SINGLE, true)(state),
    isSaving: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    order: activeOrderIdsForProjectAndFolderSelector(
      projectId,
      id,
      ProjectOrdersService.ORDER_NAMES.DIGITAL_INVITE,
    )(state),
    hasPremiumDigitalInviteFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.DIGITAL_INVITE_PREMIUM,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (id: string, projectId: string) => {
      dispatch(getClientWebsite(dispatch, id, projectId));
    },
    saveClientWebsite: (id: string, projectId: string, data, navigateToUrl) => {
      dispatch(saveClientWebsite(dispatch, { id, projectId, navigateToUrl }, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteThemeEditPage);
