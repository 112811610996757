import { combineReducers } from 'redux';
import OccasionsCalendarDataDuck from './occasion-calendar/OccasionsCalendarDataDuck';
import EventsListEventsDataDuck from './events-list/EventsListEventsDataDuck';

export default combineReducers({
  data: combineReducers({
    events: EventsListEventsDataDuck,
    occasions: OccasionsCalendarDataDuck,
  }),

  // page: ReportsPageDuck,
});
