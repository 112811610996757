import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dialog,
  DIALOG_PLACEMENT,
  DIALOG_SIZES,
  Form,
  FormFooter,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TextGroupValueColor,
} from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';
import ClientWebsiteThemeColorsEditContainer from '../edit/ClientWebsiteThemeColorsEditContainer';
import ClientWebsiteFontSelectorContainer from '../edit/ClientWebsiteFontSelectorContainer';

type ClientWebsiteFontAndColorCustomizationProps = {
  id: string;
  projectId: string;
  isTrialPackage?: boolean;
  saveClientWebsiteData: (id: string, projectId: string, data) => void;
  errors?: Record<string, string>;
  isSaving: boolean;
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  isInsideCard?: boolean;
};

export default function ClientWebsiteFontAndColorCustomization(props: ClientWebsiteFontAndColorCustomizationProps) {
  const { id, projectId, isTrialPackage, saveClientWebsiteData, errors, isSaving, clientWebsite, isInsideCard } = props;

  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef(null);

  function handleFormSubmit(formResponse) {
    saveClientWebsiteData(id, projectId, formResponse);
    setShowDialog(false);
  }

  return (
    <Space size={isInsideCard ? 4 : 0}>
      <Button onClick={() => setShowDialog(true)} style={BUTTON_STYLES.RESET} block ref={$button}>
        <div>
          <Row>
            <Text color={COLOR_SHADES.DARKER} block>
              Customize Color & Font
            </Text>
            <Col size={null} rightAlighed>
              <Icon name="chevron_down" size={ICON_SIZES.SM} />
            </Col>
          </Row>
          <Space vertical size={2} />
          <Row>
            <TextGroupValueColor value={clientWebsite?.theme.color.primary_color || ''} />
            <Space size={2} />
            <TextGroupValueColor value={clientWebsite?.theme.color.secondary_color || ''} />
            <Space size={2} />
            <TextGroupValueColor value={clientWebsite?.theme.color.background || ''} />
            <Space size={2} />
            <TextGroupValueColor value={clientWebsite?.theme.color.tertiary_color || ''} />
          </Row>
        </div>
      </Button>
      <Dialog
        size={DIALOG_SIZES.AUTO}
        placement={DIALOG_PLACEMENT.TOP_START}
        show={showDialog}
        onClose={() => {}}
        target={$button.current}
      >
        <Space size={4}>
          <Form onSubmit={handleFormSubmit} errors={errors}>
            <Button onClick={() => setShowDialog(false)} style={BUTTON_STYLES.RESET} block>
              <Row>
                <Text color={COLOR_SHADES.DARKER} block>
                  Customize Color & Font
                </Text>
                <Col size={null} rightAlighed>
                  <Icon name="chevron_up" size={ICON_SIZES.SM} />
                </Col>
              </Row>
            </Button>
            <Space vertical size={2} />
            <ClientWebsiteThemeColorsEditContainer id={id} />
            <Space vertical />
            <ClientWebsiteFontSelectorContainer id={id} isTrialPackage={isTrialPackage} />
            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL} size={BUTTON_SIZE.SM} onClick={() => setShowDialog(false)}>
                Cancel
              </Button>
              <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving} size={BUTTON_SIZE.SM}>
                Save
              </Button>
            </FormFooter>
          </Form>
        </Space>
      </Dialog>
    </Space>
  );
}
