import { PixelService } from '@premagic/core';
import { CurrencyUtils } from '@premagic/utils';
import { divide } from 'lodash';

export enum EVENT_REPORT_WIDGET_TYPE {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export enum EVENT_REPORT_WIDGETS {
  REGISTRATIONS = 'registrations',
  FILES = 'FILES',
  VIEWS = 'views',
  SHARES = 'shares',
  DOWNLOADS = 'downloads',
  BULK_DOWNLOADS = 'bulk-downloads',
  PHOTO_DISTRIBUTION = 'photo-distribution',
  ROI = 'roi',
  FACE_SEARCH = 'face-search',
  ATTENDEE_FEEDBACK = 'feedback',
  CHECK_IN = 'check-in',
  PAID_DOWNLOADS = 'paid-downloads',
  YOUR_COMMISION = 'your-commission',
  ENGAGEMENT = 'engagement',
  LEADS = 'leads',
}

export const EVENT_REPORT_WIDGET_DETAILS: Record<
  EVENT_REPORT_WIDGETS,
  {
    title: string;
    type: EVENT_REPORT_WIDGET_TYPE;
    pixelMetricKey?: PixelService.TRACK_EVENTS;
    info?: string;
    comingSoon?: boolean;
  }
> = {
  [EVENT_REPORT_WIDGETS.REGISTRATIONS]: {
    title: 'Registrations',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.EVENT_REGISTRATION,
    info: 'People who have registered for the event to get the photos',
  },
  [EVENT_REPORT_WIDGETS.FILES]: {
    title: 'Photos',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
  },
  [EVENT_REPORT_WIDGETS.VIEWS]: {
    title: 'Views',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.VIEW,
  },
  [EVENT_REPORT_WIDGETS.SHARES]: {
    title: 'Shares',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.SHARE,
    info: 'People who have shared their gallery with others',
  },
  [EVENT_REPORT_WIDGETS.DOWNLOADS]: {
    title: 'Downloads',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.DOWNLOAD,
  },
  [EVENT_REPORT_WIDGETS.BULK_DOWNLOADS]: {
    title: 'Bulk Downloads',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.BULK_DOWNLOAD,
  },
  [EVENT_REPORT_WIDGETS.PHOTO_DISTRIBUTION]: {
    title: 'Photo Delivery',
    type: EVENT_REPORT_WIDGET_TYPE.PERCENTAGE,
    info: 'Percentage of people who received the photos',
  },
  [EVENT_REPORT_WIDGETS.ROI]: {
    title: 'ROI',
    type: EVENT_REPORT_WIDGET_TYPE.CURRENCY,
    info:
      'Calculated based on industry average rates. <br/>' +
      'ROI = (views/1000)*CPM + downloads*CPE + shares*CPE + poster advocate*CPE <br/><br/>' +
      'CPM: $6 for 1000 views <br/>' +
      'CPE: $5.9 <br/>' +
      'CPE: shares and downloads',
  },
  [EVENT_REPORT_WIDGETS.FACE_SEARCH]: {
    title: 'Face Search',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.FACE_SEARCH,
    info: 'People who have searched for their photos using face search',
  },
  [EVENT_REPORT_WIDGETS.ATTENDEE_FEEDBACK]: {
    title: 'Attendee Feedback',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    comingSoon: true,
  },
  [EVENT_REPORT_WIDGETS.CHECK_IN]: {
    title: 'Check-in',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    comingSoon: true,
  },
  [EVENT_REPORT_WIDGETS.PAID_DOWNLOADS]: {
    title: 'Paid Downloads',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    comingSoon: true,
  },
  [EVENT_REPORT_WIDGETS.YOUR_COMMISION]: {
    title: 'Your Commission',
    type: EVENT_REPORT_WIDGET_TYPE.CURRENCY,
    comingSoon: true,
  },
  [EVENT_REPORT_WIDGETS.ENGAGEMENT]: {
    title: 'Engagement',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.REFER,
    info: 'People who have interacted with your brand. This would include visiting your company website, visiting your portfolio, visiting your social media profiles, Reaching out on your phone number',
  },
  [EVENT_REPORT_WIDGETS.LEADS]: {
    title: 'Leads',
    type: EVENT_REPORT_WIDGET_TYPE.NUMBER,
    pixelMetricKey: PixelService.TRACK_EVENTS.LEAD,
    info: 'People who have submitted their contact details.',
  },
};

enum EVENT_REPORT_WIDGETS_GROUP {
  TOP = 'top',
  DOWNLOADS = 'downloads',
  ATTENDEE_INTERACTION = 'attendee-interaction',
  PAYMENTS = 'payments',
  LEAD_ENGAGEMENT = 'lead-engagement',
}
export function getLayoutForDashboard(options: { hasLeadsModule: boolean; isPaidDownloadEnabled: boolean }): Array<{
  title?: string;
  id: EVENT_REPORT_WIDGETS_GROUP;
  widgets: Array<EVENT_REPORT_WIDGETS>;
}> {
  const { hasLeadsModule, isPaidDownloadEnabled } = options;
  return [
    {
      id: EVENT_REPORT_WIDGETS_GROUP.TOP,
      widgets: [
        EVENT_REPORT_WIDGETS.REGISTRATIONS,
        EVENT_REPORT_WIDGETS.FILES,
        EVENT_REPORT_WIDGETS.VIEWS,
        EVENT_REPORT_WIDGETS.SHARES,
      ],
    },
    {
      id: EVENT_REPORT_WIDGETS_GROUP.DOWNLOADS,
      widgets: [
        EVENT_REPORT_WIDGETS.DOWNLOADS,
        EVENT_REPORT_WIDGETS.BULK_DOWNLOADS,
        EVENT_REPORT_WIDGETS.PHOTO_DISTRIBUTION,
        EVENT_REPORT_WIDGETS.ROI,
      ],
    },
    {
      id: EVENT_REPORT_WIDGETS_GROUP.ATTENDEE_INTERACTION,
      widgets: [
        EVENT_REPORT_WIDGETS.FACE_SEARCH,
        EVENT_REPORT_WIDGETS.ATTENDEE_FEEDBACK,
        EVENT_REPORT_WIDGETS.CHECK_IN,
      ],
    },
    ...(isPaidDownloadEnabled
      ? [
          {
            id: EVENT_REPORT_WIDGETS_GROUP.PAYMENTS,
            widgets: [EVENT_REPORT_WIDGETS.PAID_DOWNLOADS, EVENT_REPORT_WIDGETS.YOUR_COMMISION],
          },
        ]
      : []),
    // add below code when hasLeadsModule is true
    ...(hasLeadsModule
      ? [
          {
            id: EVENT_REPORT_WIDGETS_GROUP.LEAD_ENGAGEMENT,
            title: 'Lead Engagement',
            widgets: [EVENT_REPORT_WIDGETS.ENGAGEMENT, EVENT_REPORT_WIDGETS.LEADS],
          },
        ]
      : []),
  ];
}

export function getPixelMetricKeyForWidget(widget: EVENT_REPORT_WIDGETS): PixelService.TRACK_EVENTS {
  return EVENT_REPORT_WIDGET_DETAILS[widget].pixelMetricKey as PixelService.TRACK_EVENTS;
}

const ROI_COST_PER = {
  CPE: 5.9, // cost per engagement
  CPM: 6, // cost per mille (1000 views)
  CPC: 0.4, // cost per click
  CPA: 75, // cost per action/acquisition
};

function getROIFromViews(views: number): number {
  return (views / 1000) * ROI_COST_PER.CPM;
}

function getROIForAdvocateValue(advocates = 0, referrals = 0) {
  return Number(advocates) * ROI_COST_PER.CPE + Number(referrals) * ROI_COST_PER.CPE;
}

export function getROIForEvent(data: {
  downloads: number;
  bulkDownloads: number;
  views: number;
  shares: number;
  posterAdvocates: number;
  posterReferrals: number;
}): number {
  const { views = 0, downloads = 0, bulkDownloads = 0, shares = 0, posterReferrals, posterAdvocates } = data;
  const downloadsCost = (downloads + bulkDownloads) * ROI_COST_PER.CPE;
  const sharesCost = shares * ROI_COST_PER.CPE;
  const advocateCost = getROIForAdvocateValue(posterAdvocates, posterReferrals);
  return CurrencyUtils.getCurrencyInMicroAmount(getROIFromViews(views) + downloadsCost + sharesCost + advocateCost);
}

export function getROIForSponsor(data: {
  sponsorViews: number;
  sponsorClicks: number;
  sponsorCTAClicks: number;
}): number {
  const { sponsorViews = 0, sponsorClicks = 0, sponsorCTAClicks = 0 } = data;
  return CurrencyUtils.getCurrencyInMicroAmount(
    getROIFromViews(sponsorViews) + sponsorClicks * ROI_COST_PER.CPC + sponsorCTAClicks * ROI_COST_PER.CPA,
  );
}

export function getROIForAdvocates(data: { advocates: number; referrals: number }): number {
  const { advocates = 0, referrals = 0 } = data;
  return CurrencyUtils.getCurrencyInMicroAmount(getROIForAdvocateValue(advocates, referrals));
}
