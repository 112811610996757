/* eslint-disable class-methods-use-this */

/*
  https://remirror.io/docs/concepts/extension
  https://remirror.io/docs/concepts/extra-attributes/

  Inspiration from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-font-family/src/font-family-extension.ts
*/

import {
  command,
  CommandFunction,
  extension,
  findParentNodeOfType,
  PlainExtension,
  getMarkRange,
  helper,
  Helper,
  Selection,
} from '@remirror/core';

@extension({
  defaultOptions: {},
})
export class ListMarkerColorExtension extends PlainExtension {
  get name() {
    return 'ListMarkerColor' as const;
  }

  @command()
  setListMarkerColor(color: string): CommandFunction {
    return ({ tr, dispatch }) => {
      const list = findParentNodeOfType({
        types: ['orderedList', 'bulletList'],
        selection: tr.selection,
      });
      if (list && dispatch) {
        dispatch(
          tr.setNodeMarkup(list.pos, undefined, {
            style: `--marker-color: ${color}`,
          }),
        );
        return true;
      }
      return false;
    };
  }

  @helper()
  getListMarkerColorForSelection(selection?: Selection): Helper<string> {
    if (!selection) return '#eee';

    const list = findParentNodeOfType({
      types: ['orderedList', 'bulletList'],
      selection,
    });

    if (!list) return '#eee';
    return list.node.attrs.style.split('--marker-color: ')[1];
  }

  @command()
  setLinkColor(color: string): CommandFunction {
    return ({ tr, dispatch, state }) => {
      const linkMarkInfo = getMarkRange(tr.selection.$from, state.schema.marks.link);

      if (linkMarkInfo && dispatch) {
        const { to, from, mark } = linkMarkInfo;
        tr.removeMark(from, to, mark);
        mark.attrs = {
          ...mark.attrs,
          style: `--link-color: ${color}`,
        };
        dispatch(tr.addMark(from, to, mark));
        return true;
      }
      return false;
    };
  }

  @helper()
  getLinkColorForSelection(selection?: Selection): Helper<string> {
    if (!selection) return '#4a90e2';
    const state = this.store.getState();
    const linkMarkInfo = getMarkRange(selection.$from, state.schema.marks.link);
    if (!linkMarkInfo) return '#4a90e2';
    return linkMarkInfo.mark.attrs.style.split('--link-color: ')[1];
  }
}
