import * as React from 'react';
import ClassNames from 'classnames';
import styles from './color.module.css';

export enum COLOR_SHADES {
  DARK = 'dark',
  LIGHT = 'light',
  LIGHTER = 'lighter',
  DARKER = 'darker',

  PRIMARY = 'primary',
  SECONDARY = 'secondary',

  WARNING = 'warning',
  WHITE = 'white',
  DANGER = 'danger',
  DANGER_DARK = 'danger-dark',
  SUCCESS = 'success',

  // BLUE
  BLUE = 'blue',
  BLUE_LIGHT = 'blue-light',
  BLUE_DARK = 'blue-dark',

  // GREEN
  GREEN_DARKER = 'green-darker',

  // PINK
  PINK = 'pink',
  PINK_LIGHT = 'pink-light',
  PINK_DARK = 'pink-dark',

  // YELLOW
  YELLOW = 'yellow',
  YELLOW_LIGHT = 'yellow-light',

  FROM_THEME = 'from-theme',
  FROM_THEME_CONTRAST = 'from-theme-contrast',
}

interface ColorProps {
  children: React.ReactNode;
  shade?: COLOR_SHADES;
  className?: string;
  inline?: boolean;
  disabled?: boolean;
  disabledLighter?: boolean;
  fillSolidIcon?: boolean;
  fillStrokeIcon?: boolean;
  cssStyle?: React.CSSProperties;
}

export function Color(props: ColorProps): React.ReactElement {
  const {
    children,
    shade = COLOR_SHADES.DARK,
    inline = false,
    className = '',
    disabled = false,
    disabledLighter,
    fillSolidIcon = false,
    fillStrokeIcon,
    cssStyle,
  } = props;
  const classes = ClassNames(className, styles.color, styles[`color--shade-${shade}`], {
    [styles['color--inline']]: inline,
    [styles['color--disabled']]: disabled,
    [styles['color--disabled-lighter']]: disabledLighter,
    [styles[`color--fill-solid-icon--shade-${shade}`]]: fillSolidIcon,
    [styles[`color--fill-stroke-icon--shade-${shade}`]]: fillStrokeIcon,
  });

  return (
    <span className={classes} style={cssStyle}>
      {children}
    </span>
  );
}
