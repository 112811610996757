import React, { useEffect } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Row,
  Space,
  Text,
} from '@premagic/myne';

import EventSponsorsGroupContainer from './EventSponsorsGroupContainer';

type Props = {
  isLoading: boolean;
  categoryIds: Array<string>;
  projectId: string;
  showAddSponsor: () => void;
  initData: (projectId: string) => void;
};

export default function EventSponsorsCategoryList(props: Props): JSX.Element {
  const { projectId, isLoading, categoryIds, showAddSponsor, initData } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  if (isLoading)
    return (
      <Row center>
        <LoadingDots />
      </Row>
    );

  return (
    <div>
      <Row>
        <Col rightAlighed size={null}>
          <Button style={BUTTON_STYLES.PRIMARY} onClick={showAddSponsor} size={BUTTON_SIZE.SM}>
            <Icon name="plus" size={ICON_SIZES.SM} /> Sponsor
          </Button>
        </Col>
      </Row>
      {categoryIds.length === 0 && (
        <div>
          <Space vertical />
          <Card>
            <Space>
              <Text muted block>
                No sponsors yet
              </Text>
            </Space>
          </Card>
        </div>
      )}

      {categoryIds.map((id) => (
        <EventSponsorsGroupContainer key={id} categoryId={id} projectId={projectId} />
      ))}
      <Space vertical />
    </div>
  );
}
