import { useState } from 'react';
import { Portal, useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

const DIALOG_ROOT = 'dialog-root';

const StyledMenu = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin: 0;
  list-style-type: none;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eee;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  min-width: 150px;
`;

const StyledMenuItem = styled.button`
  padding: 8px 12px;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: #4d6178;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: ${(props) => (props.disabled ? '#f0f0f0' : '#fff')};
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    color: #fff;
    background-color: #047af0;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

interface PosterMenuProps {
  children: React.ReactNode;
  target?: React.MutableRefObject<Element | undefined>;
  onClose: () => void;
}

export function PosterMenu(props: PosterMenuProps) {
  const { children, target, onClose } = props;
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles: popoverPositionStyles, attributes } = usePopper(target?.current, popperElement, {
    placement: 'top',
    strategy: 'fixed',
  });
  useRootClose(popperElement, onClose, {
    clickTrigger: 'mousedown',
  });

  if (!target) return <StyledMenu role="menu">{children}</StyledMenu>;

  return (
    <Portal container={document.getElementById(DIALOG_ROOT)}>
      <div style={popoverPositionStyles.popper} {...attributes.popper} ref={(element) => setPopperElement(element)}>
        <StyledMenu role="menu">{children}</StyledMenu>
      </div>
    </Portal>
  );
}

interface PosterMenuItemProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  disabledMessage?: string;
  active?: boolean;
}

export function PosterMenuItem(props: PosterMenuItemProps): JSX.Element {
  const { children, onClick, disabled, disabledMessage, active } = props;

  return (
    <StyledMenuItem onClick={onClick} disabled={disabled} className={active ? 'active' : ''}>
      {children}
    </StyledMenuItem>
  );
}
