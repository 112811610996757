import {
  Icon,
  ICON_SIZES,
  ListItem,
  ListItemContent,
  LIST_ITEM_STYLES,
  Row,
  Space,
  Text,
  TextGroupValue,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
} from '@premagic/myne';
import { ClientsService } from '@premagic/core';

type Props = {
  client: ClientsService.ClientType;
  routeUserToFirstEvent: (clientId: string) => void;
};

export default function CRMSearch(props: Props) {
  const { client, routeUserToFirstEvent } = props;
  return (
    <ListItem style={LIST_ITEM_STYLES.LIST} key={client.id} onClick={() => routeUserToFirstEvent(client.id)}>
      <ListItemContent>
        <Row vcenter>
          <Text boldness={TEXT_BOLDNESS.BOLD}>{client.name}</Text>
        </Row>
        <Space vertical size={1} />
        <Row vcenter>
          <Icon name="mail" size={ICON_SIZES.SM} />
          <Space size={2} />
          <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={client.email} />
        </Row>
        <Space vertical size={1} />
        <Row vcenter>
          <Icon name="phone" size={ICON_SIZES.SM} />
          <Space size={2} />
          <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={client.phone_number} />
        </Row>
      </ListItemContent>
    </ListItem>
  );
}
