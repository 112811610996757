import { connect } from 'react-redux';

import { FolderService, PricingPlanFeaturesService, RouterService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import ClientWebsitePrintQRCodePage from './ClientWebsitePrintQRCodePage';
import { clientWebsitesSelector, getClientWebsite } from '../ClientWebsiteDataDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { isMobileUp } from '../../AppDuck';
import { navigateBack } from '../../../../../services/RouterService';
import {
  fetchProjectsData,
  folderIdsWithFolderTypeForProjectSelector,
  projectsSelector,
  registrationLinkForProjectSelector,
} from '../../projects/AccountProjectsDataDuck';
import { getFolderMetaData, qrCodeDesignsSelector } from '../../projects/folder-meta/FoldersMetaDataDuck';
import { fetchFolder } from '../../projects/folders/AccountFoldersDataDuck';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';
import { getEventSponsorsForProject, setEventSponsorData } from '../../crm/events/event-sponsors/EventSponsorDataDuck';
import { setSponsorCreativeData } from '../../crm/events/event-sponsor-creatives/SponsorCreativeDataDuck';

function mapStateToProps(state, ownProps) {
  const { projectId, id, view } = ownProps.match.params;
  const { pathname, search } = ownProps.location;

  const project = projectsSelector(state)[projectId];
  const queryObject = BrowserUrlUtils.getQueryObjectFromString(search);
  const clientWebsite = clientWebsitesSelector(state)[id];

  const sponsorFolderId = projectId
    ? folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.SPONSOR)(state)[0]
    : undefined;

  return {
    id,
    clientWebsite,
    url: (queryObject.url as string) || registrationLinkForProjectSelector(projectId)(state),
    view,
    projectId,
    isLoading:
      isLoadingSelector(LOADINGS.CLIENT_WEBSITE.FETCH_SINGLE)(state) ||
      isLoadingSelector(LOADINGS.FOLDER.FETCH)(state) ||
      isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state),
    currentUrlPathname: pathname,
    isMobileUp: isMobileUp(state),
    eventId: project?.event_id,
    projectName: project?.project_name,
    qrCodeDesigns: qrCodeDesignsSelector(id)(state),
    sponsorFolderId,
    hasEventSponsorshipFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchClientWebsite: (id: string, projectId: string) => {
      dispatch(getClientWebsite(dispatch, id, projectId));
    },
    fetchFolderMeta: (projectId: string, id: string) => {
      dispatch(getFolderMetaData(dispatch, projectId, id, {}));
    },
    fetchProjects: () => {
      dispatch(fetchProjectsData(dispatch));
    },
    browserBack: (defaultUrl: string) => {
      navigateBack(dispatch, defaultUrl);
    },
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url);
    },
    fetchSponsorsData: (projectId, sponsorFolderId) => {
      dispatch(getEventSponsorsForProject(dispatch, projectId));
      dispatch(fetchFolder(dispatch, projectId, sponsorFolderId));
    },
    resetSponsors: () => {
      dispatch(setEventSponsorData(dispatch, {}));
      dispatch(setSponsorCreativeData(dispatch, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsitePrintQRCodePage);
