import React from 'react';
import {
  Alert,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormErrorType,
  FormFooter,
  FormResponseType,
  Space,
  Text,
  TEXT_BOLDNESS,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { ProposalDeckService } from '@premagic/proposals';

import DeckVariablesFormContainer from './DeckVariablesFormContainer';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  deck: ProposalDeckService.ProposalDeckType;
  saveDeck: (projectId: string, deckId: string, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: FormErrorType | unknown;
  closePanel: () => void;
  variablesSetOnDeck: Array<string>;
};

export default function DeckVariablesPanel(props: Props): JSX.Element {
  const { show, saveDeck, isLoading, errors, closePanel, deckId, projectId, deck, variablesSetOnDeck, folderId } =
    props;
  const isDeckPublished = deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;

  function handleSubmit(formData) {
    const dataToPost = {
      data: {
        variables: {
          ...deck?.variables,
          ...formData.data.variables,
        },
      },
    };
    saveDeck(projectId, deckId, dataToPost);
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Variables in the Proposal</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        {variablesSetOnDeck.length === 0 ? (
          <div>
            <Text>
              There are no Variables on the Proposal. <Space vertical />
              You can add it by clicking on the <Text boldness={TEXT_BOLDNESS.BOLD}>Variables</Text> dropdown on the
              content text area
            </Text>
            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
                Close
              </Button>
            </FormFooter>
          </div>
        ) : (
          <Form errors={errors as FormErrorType} onSubmit={handleSubmit}>
            <DeckVariablesFormContainer deckId={deckId} folderId={folderId} />
            {isDeckPublished && (
              <Alert style={ALERT_STYLES.INFO}>
                You can <Text boldness={TEXT_BOLDNESS.BOLD}>not change</Text> the variables in the proposal which are{' '}
                <Text boldness={TEXT_BOLDNESS.BOLD}>shared</Text> with the client.
                <Space vertical />
                <Text boldness={TEXT_BOLDNESS.BOLD}>Edit the proposal</Text> to update the variables and share it again.
              </Alert>
            )}
            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
                Cancel
              </Button>
              <Button
                size={BUTTON_SIZE.LG}
                style={BUTTON_STYLES.PRIMARY}
                isLoading={isLoading}
                disabled={isDeckPublished}
                disabledMessage="The proposal is shared with the host."
              >
                Save
              </Button>
            </FormFooter>
          </Form>
        )}
      </WindowPanelContent>
    </WindowPanel>
  );
}
