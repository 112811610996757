import { connect } from 'react-redux';
import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';
import { LoadingDuck } from '@premagic/common-ducks';
import ProposalTemplatesPage from './ProposalTemplatesPage';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return {
    isLoading: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSALS_TEMPLATES.FETCH)(state),
    proposalIds: ProposalDataDuck.proposalsTemplatesIdsSelectors(state),
    currentUrlPathname: pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(ProposalDataDuck.fetchProposalsTemplates(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalTemplatesPage);
