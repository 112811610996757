import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { FolderService, SponsorCreativesService } from '@premagic/core';

import SponsorFilesModal from './SponsorFilesModal';
import { LOADINGS } from '../../../../../../common/Constants';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';

const PANEL_KEY = LOADINGS.SPONSOR_CREATIVES.FILES_MODAL;
function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  const sponsorFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.SPONSOR,
  )(state);

  const modalOptions = ModalDuck.modalOptionsSelector<{
    creativePlacement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  }>(PANEL_KEY)(state);

  const { creativePlacement } = modalOptions || {};
  return {
    projectId,
    folderId: sponsorFolderIds[0],
    show: ModalDuck.isModalOpenSelector(PANEL_KEY)(state),
    creativePlacement,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, PANEL_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorFilesModal);
