import { connect } from 'react-redux';
import { ProposalDeckDataDuck } from '@premagic/proposals';

import EventProposalDeckVariables from './EventProposalDeckVariables';
import { folderMetaDataForFolderSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    deckId: string;
    folderId: string;
  },
) {
  const { deckId, folderId } = ownProps;

  return {
    folderMeta: folderMetaDataForFolderSelector(folderId)(state),
    deckGroupedVariables: ProposalDeckDataDuck.proposalsDeckVariableDetailsSelectors(deckId)(state),
  };
}

export default connect(mapStateToProps)(EventProposalDeckVariables);
