import React from 'react';

import { Button, BUTTON_STYLES, Card, Divider, Icon, ICON_SIZES, Row, Space, Text } from '@premagic/myne';

import { FolderService } from '@premagic/core';

import AddSignatureAlbumButtonContainer from './AddSignatureAlbumButtonContainer';
import MESSAGES from '../../../../../../common/Messages';
import SignatureAlbumCardHeaderContainer from './SignatureAlbumCardHeaderContainer';
import SignatureAlbumCardFoldersListContainer from './SignatureAlbumCardFoldersListContainer';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import ImportPhotosToSignatureAlbumButtonContainer from './ImportPhotosToSignatureAlbumButtonContainer';

type Props = {
  folders: Array<FolderService.FolderType>;
  projectId: string;
  hasSignatureAlbum: boolean;
  focus: boolean;
  guestGalleryLink?: string;
  isCompanyTypeNotPhotographer: boolean;
};

export default function SignatureAlbumCard(props: Props): JSX.Element {
  const { hasSignatureAlbum, folders, projectId, focus, guestGalleryLink, isCompanyTypeNotPhotographer } = props;

  if (!hasSignatureAlbum)
    return (
      <Card highlight={focus} overflowHidden>
        <SignatureAlbumCardHeaderContainer projectId={projectId} />
        <Space>
          <Text muted>
            {isCompanyTypeNotPhotographer
              ? MESSAGES.SIGNATURE_FOLDER.ZERO_STATE_FOR_EVENT_ORGANIZER
              : MESSAGES.SIGNATURE_FOLDER.ZERO_STATE}
          </Text>
          <Space vertical />
          <AddSignatureAlbumButtonContainer projectId={projectId} />
          <ImportPhotosToSignatureAlbumButtonContainer projectId={projectId} />
        </Space>
      </Card>
    );

  const shareUrl = folders.find((folder) => folder.status !== FolderService.FOLDER_STATUS.DRAFT && folder.share_url)
    ?.share_url;
  return (
    <Card highlight={focus} overflowHidden>
      <div className={JS_SELECTORS.SIGNATURE_ALBUM_CARD}>
        <SignatureAlbumCardHeaderContainer projectId={projectId} />
      </div>
      <Space>
        <SignatureAlbumCardFoldersListContainer projectId={projectId} />
        <Space vertical size={2} />
        <AddSignatureAlbumButtonContainer projectId={projectId} />
        <ImportPhotosToSignatureAlbumButtonContainer projectId={projectId} />
        <Space vertical size={2} />
      </Space>
      <Divider />
      <Space>
        <Row>
          <Button
            link={shareUrl}
            style={BUTTON_STYLES.LINK}
            disabled={!shareUrl}
            isExternalLink
            disabledMessage={MESSAGES.SIGNATURE_FOLDER.FOLDER_NOT_SHARED}
            className={JS_SELECTORS.SIGNATURE_ALBUM_PREVIEW_BTN}
          >
            <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} /> Open
          </Button>
          {guestGalleryLink && (
            <Button link={guestGalleryLink} style={BUTTON_STYLES.LINK} isExternalLink showExternalLinkIcon>
              <Icon name="smile" size={ICON_SIZES.SM} /> <Space size={1} /> Guest Gallery
            </Button>
          )}
        </Row>
      </Space>
    </Card>
  );
}
