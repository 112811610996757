import { connect } from 'react-redux';

import { AuthDuck } from '@premagic/common-ducks';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../common/Constants';
import AuthResetPassword from './AuthResetPassword';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.AUTH.RESET_PASSWORD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestForForgotPassword: (data) => {
      dispatch(AuthDuck.requestForResetPassword(dispatch, data));
    },
    resetPassword: (data) => {
      dispatch(AuthDuck.resetPassword(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthResetPassword);
