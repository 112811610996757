function getActionKey(scope: string) {
  return (action: string) => `@${scope}/${action}`;
}

export const LOADINGS = {
  APP_LOADING: 'app-loading',
  ALBUM: {
    LOADINGS: 'album-loading',
    FETCH: 'album-fetch-all',
    FETCH_IMAGES: 'album-images-fetch-all',
    ADD_NEW_ALBUM: 'album-new-album',
    ADD_NEW_PAGE: 'album-new-page',
    UPDATE_NEW_PAGE: 'album-update-page',
    UPDATE: 'album-update',
    DELETE: 'album-delete',
  },
  PROJECT: {
    FETCH: 'project-fetch',
  },
};

export const MODALS = {
  ALBUM: {
    UPDATE_STATE: 'album-update-state',
  },
};
