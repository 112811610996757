import { connect } from 'react-redux';

import PaymentCategoryPicker from './PaymentCategoryPicker';
import { PAYMENT_TYPES } from './CRMPaymentService';
import { clientPaymentCategoriesSelector, expensePaymentCategoriesSelector } from './CRMPaymentCategoryDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    selectedCategoryId?: number;
    paymentType: PAYMENT_TYPES;
  },
) {
  const { selectedCategoryId, paymentType } = ownProps;

  return {
    selectedCategoryId,
    categories:
      paymentType === PAYMENT_TYPES.INFLOW
        ? clientPaymentCategoriesSelector(state)
        : expensePaymentCategoriesSelector(state),
  };
}

export default connect(mapStateToProps)(PaymentCategoryPicker);
