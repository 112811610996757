import { EventExhibitorService } from '@premagic/core';
import { Divider, Dropdown, DropdownIcon, Icon, ICON_SIZES, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';
import React from 'react';
import ExhibitorDeleteDialogContainer from './ExhibitorDeleteDialogContainer';

type Props = {
  projectId: string;
  exhibitorId: string;
  showDeleteDialog: (id: string) => void;
  showEditExhibitor: (id: string) => void;
  exhibitor: EventExhibitorService.EventExhibitorType;
  hasFeature: boolean;
};

export default function EventExhibitorOptionsMenu(props: Props): React.ReactElement {
  const { exhibitorId, showDeleteDialog, hasFeature, projectId, showEditExhibitor, exhibitor } = props;
  const { id } = exhibitor;

  return (
    <>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="top">
          <Icon name="more_vertical" size={ICON_SIZES.SM} />
        </DropdownIcon>

        <Menu>
          <MenuItem
            // disabled={!hasFeature}
            // disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_EXHIBITOR]}
            onClick={() => showEditExhibitor(exhibitorId)}
          >
            <MenuItemIcon name="edit_2" />
            Edit
          </MenuItem>
          <Divider />
          <MenuItem
            // disabled={!hasFeature}
            // disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
            onClick={() => showDeleteDialog(exhibitorId)}
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>

      <ExhibitorDeleteDialogContainer exhibitorId={exhibitorId} projectId={projectId} />
    </>
  );
}
