import { connect } from 'react-redux';
import { ProjectMetaService } from '@premagic/core';

import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteUserRegistrationLayout from './ClientWebsiteUserRegistrationLayout';
import { projectMetaDataForProjectSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps.match.params;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);
  const values = ProjectMetaService.EVENT_REGISTRATION_STATUS_VALUES;
  const isEnabled = projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_STATUS] === values.OPEN;

  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    isEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteUserRegistrationLayout);
