import { ClientAppServices } from '@premagic/core';
import { connect } from 'react-redux';
import { navigateTo } from '../../../../../../services/RouterService';
import { projectShareUrlForHighlightSelector, projectsSelector } from '../../../projects/AccountProjectsDataDuck';
import PhotoSliderWidgetNavigateButton from './PhotoSliderWidgetNavigateButton';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  const project = projectsSelector(state)[projectId];
  const shareUrl = projectShareUrlForHighlightSelector(state);
  const shareId = shareUrl ? ClientAppServices.getShareIdFromShareUrl(shareUrl) : undefined;

  return {
    eventId: project?.event_id,
    projectId,
    shareId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      navigateTo(dispatch, url, {});
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSliderWidgetNavigateButton);
