import { connect } from 'react-redux';
import ClientWishFile from './ClientWishFile';
import { clientWebsiteFileSelector } from './ClientWebsiteFilesDataDuck';

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  return {
    file: clientWebsiteFileSelector(id)(state),
  };
}

export default connect(mapStateToProps)(ClientWishFile);
