import { FormGroup, FormLabel, INPUT_SIZES, Range, Select, Space, Text } from '@premagic/myne';
import React, { useMemo } from 'react';

import { EventSponsorsService, FolderService, SponsorCreativesService } from '@premagic/core';
import { PhotoWatermarkUtils } from '@premagic/utils';
import { isNumber } from 'lodash';
import SponsorFilesSelectorContainer from '../../../../../../projects/sponsor/sponsor-files/SponsorFilesSelectorContainer';
import WatermarkPositionSelector from '../../../../../../watermark/WatermarkPositionSelector';
import WatermarkRemoveOnDownloadsOption from '../../../../../../watermark/WatermarkRemoveOnDownloadsOption';
import AddSignatureAlbumButtonContainer from '../../../../../event-deliveries/signature-album/AddSignatureAlbumButtonContainer';
import SponsorWatermarkTypeToggleContainer from './SponsorWatermarkTypeToggleContainer';

const sizeOptions = Object.entries(PhotoWatermarkUtils.WATERMARK_SIZE_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.label,
}));

function SizeSelect(props: { selectedSize?: PhotoWatermarkUtils.WATERMARK_SIZES | number; name?: string }) {
  const { selectedSize, name = 'size' } = props;
  const selectedOption = sizeOptions.find((option) => option.value === selectedSize) || sizeOptions[0];
  const selectionValue = isNumber(selectedSize) ? selectedSize : Number(selectedOption.value);
  return (
    <FormGroup>
      <FormLabel>Size</FormLabel>
      <Range name={name} min={1} max={100} step={1} defaultValue={selectionValue || 15} />
    </FormGroup>
  );
}

function OpacitySelector(props: { selectedOpacity?: number; name?: string }) {
  const { selectedOpacity = 1, name = 'opacity' } = props;

  return (
    <FormGroup>
      <FormLabel>Opacity</FormLabel>
      <Range name={name} min={0.1} max={1} step={0.1} defaultValue={selectedOpacity} />
    </FormGroup>
  );
}

type Props = {
  sponsorCreativeWatermark?: SponsorCreativesService.SponsorCreativeType;
  projectId: string;
  sponsors?: EventSponsorsService.EventSponsorType[];
  folders?: Array<FolderService.FolderType>;
};

export default function SponsorWatermarkForm(props: Props) {
  const { projectId, sponsorCreativeWatermark, sponsors, folders } = props;

  const { sponsor_id: sponsorId, meta, asset_ids: assetIds } = sponsorCreativeWatermark || {};
  const {
    watermark_folder_ids: watermarkFolderIds,
    watermark_opacity: watermarkOpacity,
    watermark_position: watermarkPosition,
    watermark_size: watermarkSize,
    watermark_position_x: x,
    watermark_position_y: y,
    watermark_remove_on_download: watermarkRemoveOnDownload,
  } = meta || {};

  const watermarkFileId = assetIds ? assetIds[0] : '';

  const sponsorsOptions = useMemo(
    () =>
      sponsors?.map((sponsor) => ({
        value: sponsor.id,
        label: sponsor.name,
      })),
    [sponsors],
  );

  const folderOptions = useMemo(
    () =>
      folders?.map((folder) => ({
        value: folder.folder_id,
        label: folder.folder_name,
      })),
    [folders],
  );

  if (!sponsorsOptions) {
    return <Text>Create at least one sponsor</Text>;
  }
  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Sponsor</FormLabel>
        <Select
          name="sponsor_id"
          size={INPUT_SIZES.SM}
          options={sponsorsOptions}
          isMulti={false}
          disabled={Boolean(sponsorId)}
          autoFocus
          value={sponsorsOptions.find((option) => option.value === sponsorId)}
        />
      </FormGroup>
      {sponsorId && <input type="hidden" name="sponsor_id" value={sponsorId} />}
      <SponsorWatermarkTypeToggleContainer />
      {/* This will be shown only when folder level watermark is enabled */}
      <FormGroup>
        <FormLabel isRequired>Folder</FormLabel>
        <Select
          name="watermark_folder_ids"
          size={INPUT_SIZES.SM}
          options={folderOptions || []}
          isMulti
          autoFocus
          value={folderOptions?.filter((option) => watermarkFolderIds?.includes(option.value))}
        />
      </FormGroup>
      <AddSignatureAlbumButtonContainer projectId={projectId} navigateToFolder={false} />
      <Space vertical />
      <SponsorFilesSelectorContainer
        name="asset_ids[0]"
        defaultValue={watermarkFileId}
        placement={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.WATERMARK}
      />
      {/* Backend wont support this now */}
      {/* <WatermarkRemoveOnDownloadsOption */}
      {/*  name="watermark_remove_on_download" */}
      {/*  removeOnDownloads={watermarkRemoveOnDownload} */}
      {/* /> */}
      <WatermarkPositionSelector
        name="watermark_position"
        position={watermarkPosition as PhotoWatermarkUtils.WATERMARK_POSITIONS}
        x={x}
        y={y}
      />
      <SizeSelect name="watermark_size" selectedSize={watermarkSize} />
      <OpacitySelector name="watermark_opacity" selectedOpacity={watermarkOpacity} />

      <Space vertical size={8} />
      <input type="hidden" name="name" value="watermark" />
      <input type="hidden" name="placement" value={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.WATERMARK} />
      <input type="hidden" name="size" value={SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT_SIZE.MEDIUM} />
      <input type="hidden" name="status" value={SponsorCreativesService.SPONSORS_CREATIVES_PUBLISH_STATUS.PUBLISHED} />
    </div>
  );
}
