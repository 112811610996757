import { BUTTON_SIZE, BUTTON_STYLES, Button, Icon, ICON_SIZES, Space } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import MESSAGES from '../../../../../../../../common/Messages';
import APP_URLS from '../../../../../../services/AppRouteURLService';

type Props = {
  eventId: string;
  isFeatureEnabled?: boolean;
};

export default function EventAttendeeBadgeButton(props: Props) {
  const { eventId, isFeatureEnabled } = props;

  return (
    <Button
      style={BUTTON_STYLES.LINK}
      size={BUTTON_SIZE.MD}
      link={BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__BADGES, {
        eventId,
      })}
      disabled={!isFeatureEnabled}
      disabledMessage={MESSAGES.EVENT_ATTENDEES_BADGES.UPGRADE}
    >
      <Icon name="award" size={ICON_SIZES.SM} /> <Space size={1} />
      Badges
    </Button>
  );
}
