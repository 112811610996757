import { EventPosterService } from '@premagic/core';
import { Card, CARD_STYLES, Row, Section, SECTION_STYLES, Space, SubTitle, Text } from '@premagic/myne';
import { POSTER_WIDGET_TYPE, PosterWidgetType } from '@premagic/poster-creator';
import EventPosterAvatarStyleSelectContainer from '../widget-selector/EventPosterAvatarStyleSelectContainer';
import EventPosterFilePickerContainer from '../widget-selector/file-picker/EventPosterFilePickerContainer';
import EventPosterFolderIdSelectorContainer from '../widget-selector/EventPosterFolderSelectorContainer';
import EventPosterPositionSelectorContainer from '../widget-selector/EventPosterPositionSelectorContainer';
import EventPosterFontAlignmentSelectorContainer from '../widget-selector/text-widgets/EventPosterFontAlignmentSelectorContainer';
import EventPosterFontFamilySelectContainer from '../widget-selector/text-widgets/EventPosterFontFamilySelectContainer';
import EventPosterFontSizeSelectorContainer from '../widget-selector/text-widgets/EventPosterFontSizeSelectorContainer';
import EventPosterTextColorSelectorContainer from '../widget-selector/text-widgets/EventPosterTextColorSelectorContainer';
import EventPosterTextValueSelectorContainer from '../widget-selector/text-widgets/EventPosterTextValueSelectorContainer';

type Props = {
  projectId: string;
  posterId: string;
  widgetId?: string;
  widget?: PosterWidgetType;
};

export default function EventPosterCreatorWidgetEditor(props: Props) {
  const { projectId, posterId, widgetId, widget } = props;
  const { is_hidden: isHidden, type, name } = widget || { is_hidden: false, name: '', type: '' };

  if (!widget) {
    return (
      <Space>
        <Card style={CARD_STYLES.SECONDARY}>
          <Space size={2}>
            <Text muted block center>
              Select a widget to edit
            </Text>
          </Space>
        </Card>
      </Space>
    );
  }
  if (isHidden) {
    return (
      <Space>
        <Row>
          <Space />
          <SubTitle>{EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS_DETAILS[name]?.title || name}</SubTitle>
        </Row>
        <Space vertical size={1} />
        <Card style={CARD_STYLES.SECONDARY}>
          <Space size={2}>
            <Text muted block center>
              This widget is hidden
            </Text>
          </Space>
        </Card>
      </Space>
    );
  }
  const isSystemWidget = EventPosterService.isSystemWidget(widget);

  return (
    <Section style={SECTION_STYLES.DEFAULT} hasPadding={false} key={widgetId}>
      <Space size={4}>
        <SubTitle>{EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS_DETAILS[name]?.title || name}</SubTitle>
        <Space vertical size={2} />
        {[POSTER_WIDGET_TYPE.IMAGE].includes(widget.type) && (
          <EventPosterFilePickerContainer
            projectId={projectId}
            posterId={posterId}
            widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
          />
        )}
        {type === POSTER_WIDGET_TYPE.TEXT && (
          <div>
            {!isSystemWidget && (
              <EventPosterTextValueSelectorContainer
                projectId={projectId}
                posterId={posterId}
                widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
              />
            )}
            <EventPosterFontFamilySelectContainer
              projectId={projectId}
              posterId={posterId}
              widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
            />
            <EventPosterFontAlignmentSelectorContainer
              projectId={projectId}
              posterId={posterId}
              widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
            />
            <EventPosterFontSizeSelectorContainer
              projectId={projectId}
              posterId={posterId}
              widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
            />
          </div>
        )}
        {[POSTER_WIDGET_TYPE.GUEST_IMAGE_GALLERY, POSTER_WIDGET_TYPE.GUEST_IMAGE].includes(widget.type) && (
          <EventPosterFolderIdSelectorContainer
            projectId={projectId}
            posterId={posterId}
            widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
          />
        )}
        {[POSTER_WIDGET_TYPE.TEXT, POSTER_WIDGET_TYPE.AVATAR].includes(widget.type) && (
          <EventPosterTextColorSelectorContainer
            projectId={projectId}
            posterId={posterId}
            name="font_color"
            widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
          />
        )}
        {[POSTER_WIDGET_TYPE.BOX, POSTER_WIDGET_TYPE.CIRCLE].includes(widget.type) && (
          <EventPosterTextColorSelectorContainer
            projectId={projectId}
            posterId={posterId}
            name="fill"
            widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
          />
        )}
        {widget.type === POSTER_WIDGET_TYPE.AVATAR && (
          <div>
            <EventPosterAvatarStyleSelectContainer
              projectId={projectId}
              posterId={posterId}
              widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
            />
          </div>
        )}
        <EventPosterPositionSelectorContainer
          projectId={projectId}
          posterId={posterId}
          widgetId={widgetId as EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS}
        />
      </Space>
    </Section>
  );
}
