import { connect } from 'react-redux';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { addClientWebsiteItinerary, clientWebsiteItineraryItemsSelector } from './ClientWebsiteItineraryDataDuck';
import AddClientWebsiteItineraryPanel from './AddClientWebsiteItineraryPanel';

const PANEL_KEY = LOADINGS.CLIENT_WEBSITE_ITINERARY.SHOW_ADD_ITINERARY_PANEL;
const LOADING_KEY = LOADINGS.CLIENT_WEBSITE_ITINERARY.ADD;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    websiteId: string;
  },
) {
  const { projectId, websiteId } = ownProps;

  return {
    websiteId,
    projectId,
    isLoading: isLoadingSelector(LOADING_KEY)(state),
    errors: errorObjectForTypeSelector(LOADING_KEY)(state),
    showPanel: isWindowPanelOpenSelector(PANEL_KEY)(state),
    itineraryItems: clientWebsiteItineraryItemsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addItinerary: (options, data) => {
      dispatch(addClientWebsiteItinerary(dispatch, options, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClientWebsiteItineraryPanel);
