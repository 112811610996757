import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideBackgroundDataType,
  FormFooter,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Space,
  Text,
} from '@premagic/myne';
import { ProposalSlideService } from '@premagic/proposals';
import { FileService } from '@premagic/core';

import ProposalCreatorFilesContainer from './ProposalCreatorFilesContainer';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  saveProposalSlide: (options, data) => void;
  slideId: number;
  slides: Record<string, ProposalSlideService.ProposalSlideType>;
  show: boolean;
  hideModal: () => void;
  modalOptions: { datatype: DECK_SLIDE_DATA_TYPES; value?: DeckSlideBackgroundDataType };
  files: Record<string, FileService.FolderFileType>;
};

export default function ProposalCreatorSelectFileForSlideModal(props: Props): JSX.Element | null {
  const { projectId, folderId, show, hideModal, modalOptions, slides, saveProposalSlide, deckId, slideId, files } =
    props;

  function handleOnSelect(fileId) {
    const dataType = ProposalSlideService.getDeckSlideDataTypeForFile(files[fileId]);
    saveProposalSlide(
      { projectId, deckId, id: slideId },
      {
        structure: {
          ...slides[slideId].structure,
          [dataType]: {
            value: {
              [fileId]: {
                id: fileId,
              },
            },
          },
        },
      },
    );
    hideModal();
  }

  return (
    <Modal show={show} close={hideModal} size={MODAL_SIZES.MD}>
      <ModalTitle>
        Select a file for the slide
        <Space />
        <Text color={COLOR_SHADES.LIGHT}>We recommend 1080 x 1920 px photos</Text>
      </ModalTitle>

      <ProposalCreatorFilesContainer projectId={projectId} folderId={folderId} onSelect={handleOnSelect} />
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={hideModal}>
          Cancel
        </Button>
      </FormFooter>
    </Modal>
  );
}
