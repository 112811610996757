import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import { hasFeatureSelector, highlightFoldersLimitSelector } from '../../../settings/company/CompanyDataDuck';
import ImportPhotosToSignatureAlbumButton from './ImportPhotosToSignatureAlbumButton';
import { importFromExternalApp } from '../../../projects/external-uploader/ExternalUploaderDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';

const FOLDER_TYPE = FolderService.FOLDER_TYPES.HIGHLIGHT;
function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(projectId, FOLDER_TYPE)(state);

  const hasExternalUploadFeature = hasFeatureSelector(
    PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_IMPORT_FROM_EXTERNAL_SOURCE,
  )(state);

  return {
    projectId,
    hasExternalUploadFeature,
    totalFolder: signatureFolderIds.length,
    folderLimit: highlightFoldersLimitSelector(state),
    isLoading: isLoadingSelector(LOADINGS.EXTERNAL_UPLOADER.UPLOAD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.EXTERNAL_UPLOADER.UPLOAD)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    importFromService: (projectId, formResponse) => {
      dispatch(importFromExternalApp(dispatch, projectId, formResponse));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportPhotosToSignatureAlbumButton);
