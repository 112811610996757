import ClassNames from 'classnames';
import * as React from 'react';
import styles from './grid.module.css';

interface SpaceProps {
  children?: React.ReactNode | null;
  size?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12 | 15 | 16 | 25 | 35 | 60;
  vertical?: boolean;
}

export function Space(props: SpaceProps): React.ReactElement {
  const { size = 4, vertical = false, children } = props;

  if (children) {
    const classes = ClassNames(styles[`space--around-${size}px`]);

    return <div className={classes}>{children}</div>;
  }

  const classes = ClassNames(styles.space, styles[`space--${size}px`], {
    [styles['space--vertical']]: vertical,
  });

  return <div className={classes} />;
}

interface RowProps {
  children: React.ReactNode;
  vcenter?: boolean;
  center?: boolean;
  fullHeight?: boolean;
  inline?: boolean;
  columnDirection?: boolean;
  wrap?: boolean;
  spaceAround?: boolean;
  spaceBetween?: boolean;
  alignItems?: 'baseline';
  positionRelative?: boolean;
}

export function Row(props: RowProps): React.ReactElement {
  const {
    vcenter = false,
    center = false,
    children,
    fullHeight = false,
    inline = false,
    columnDirection = false,
    wrap = false,
    spaceAround = false,
    alignItems,
    positionRelative = true,
    spaceBetween = false,
    ...elementProps
  } = props;
  const classes = ClassNames(styles.row, {
    [styles['row--vcenter']]: vcenter,
    [styles['row--inline']]: inline,
    [styles['row--center']]: center,
    [styles['row--height-full']]: fullHeight,
    [styles['row--direction-column']]: columnDirection,
    [styles['row--wrap']]: wrap,
    [styles['row--space-around']]: spaceAround,
    [styles['row--space-between']]: spaceBetween,
    [styles[`row--align-items-${alignItems}`]]: alignItems,
    [styles['row--position-relative']]: positionRelative,
  });

  return (
    <div className={classes} {...elementProps}>
      {children}
    </div>
  );
}

interface ColProps {
  children: React.ReactNode;
  size?: null | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  screenSMSize?: null | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  rightAlighed?: boolean;
  align?: 'bottom' | 'right';
  className?: string;
}

export function Col(props: ColProps): React.ReactElement {
  const { size = 6, screenSMSize, rightAlighed = false, children, className, align } = props;
  const classes = ClassNames(styles.col, className, {
    [styles['col--align-right']]: rightAlighed,
    [styles[`col--align-${align}`]]: align,
    [styles[`col--${size}`]]: size,
    [styles[`col--screen-sm-${screenSMSize}`]]: screenSMSize,
  });

  return <div className={classes}>{children}</div>;
}

interface GridProps {
  children: React.ReactNode;
  colSize: 400 | 320 | 200 | 160 | 134 | 124 | 94 | 64;
  gap?: 1 | 2 | 4;
  align?: 'center' | 'space-evenly';
}

export function Grid(props: GridProps): React.ReactElement {
  const { children, colSize, gap = 4, align } = props;
  const classes = ClassNames(styles.grid);

  return (
    <div
      className={classes}
      style={{
        ['--grid-col-size' as string]: `${colSize}px`,
        ['--grid-gap' as string]: `${gap * 4}px`,
        justifyContent: align,
      }}
    >
      {children}
    </div>
  );
}

interface PositionProps {
  children: React.ReactNode;
  align?: 'cover' | 'center' | 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-right' | 'bottom-left';
  position?: 'absolute' | 'fixed';
  offsetY?: number;
}

export function Position(props: PositionProps): React.ReactElement {
  const { children, align = 'center', position = 'absolute', offsetY } = props;
  const classes = ClassNames(styles.position, styles[`position--${align}`], styles[`position--${position}`]);

  return (
    <div
      className={classes}
      style={{
        ['--position-offset-y' as string]: `${offsetY}px`,
      }}
    >
      {children}
    </div>
  );
}

interface PreventMarginCollapseProps {
  children: React.ReactNode;
}

export function PreventMarginCollapse(props: PreventMarginCollapseProps): React.ReactElement {
  const { children } = props;
  return <div className={styles['u-prevent-margin-collapse']}>{children}</div>;
}
