import { UploaderDuck } from '@premagic/common-ducks';
import {
  EventTrackerService,
  FileService,
  FolderFilePositionOrderService,
  FolderMetaService,
  FolderService,
  Schemas,
} from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ProposalService } from '@premagic/proposals';
import {
  ActionTypeUtils,
  ArrayUtils,
  BrowserUrlUtils,
  ErrorTracker,
  MediaUtils,
  SimpleDateUtils,
} from '@premagic/utils';
import { List as IList, Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';

import { setModalOptions, toggleModalVisibility } from '../../../../../common/ModalDuck';
import { navigateTo } from '../../../../../services/RouterService';
import { entitiesDataSelector } from '../../../reducers/selectors';
import APP_URLS from '../../../services/AppRouteURLService';
import { addFilesData, filesEntitiesDataSelector, removeFile } from '../../images/FilesDataDuck';
import {
  filesEntitiesDataOrderSelector,
  getFolderFilesOrder,
  setFilesOrderForFolder,
  setFilesOrderSortedByValueForFolder,
} from '../../images/FilesOrderDataDuck';
import { foldersMetaDataSelector, foldersMetaSelector } from '../folder-meta/FoldersMetaDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('FOLDER');

function getPreviousImageId(oldIndex, newIndex, imageIds) {
  if (newIndex > oldIndex) return imageIds[newIndex];
  return newIndex ? imageIds[newIndex - 1] : null;
}

export const setFoldersData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addFolderData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
export const removeFolderData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, folderId: string) => folderId);
export const updateFolderData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, folderId, data) => ({
  id: folderId,
  data,
}));
export const removeFileFromFolder = createAction(
  getActionType('DATA_FILES', 'UPDATE'),
  (dispatch, folderId, fileId) => ({
    id: folderId,
    fileId,
  }),
);

export const setBulkAction = createAction(
  getActionType('BULK_ACTION', 'SET'),
  (dispatch, action: 'delete' | null) => action,
);

export const toggleAlbumView = createAction(getActionType('ALBUM_VIEW', 'SET'), (dispatch, action: boolean) => action);

export const toggleFileSelectionForBulkAction = createAction(
  getActionType('TOGGLE_SELECTION', 'SET'),
  (dispatch, filesId: string, selected: boolean) => ({
    filesId,
    selected,
  }),
);

export const clearFileSelectionForBulkAction = createAction(getActionType('CLEAR_SELECTION', 'SET'));

export const fetchFolder = createAction(
  getActionType('ACTION', 'FETCH'),
  (dispatch, projectId: string, folderId: string) => {
    const loadingKey = LOADINGS.FOLDER.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderService.fetchFolder(projectId, folderId)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.FolderSchema);
        const { folders, images } = normalizedData.entities || {};
        dispatch(addFolderData(dispatch, folders));
        dispatch(addFilesData(dispatch, images));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

// This action is to silently update folder data without dispatching any actions
export const getFolderImagesAndOrder = createAction(
  getActionType('IMAGE_AND_ORDER', 'FETCH'),
  (dispatch, projectId: string, folderId: string, folderType: FolderService.FOLDER_TYPES) => {
    FolderService.fetchFolder(projectId, folderId).then((response) => {
      const normalizedData = normalize(response, Schemas.FolderSchema);
      const { images, folders } = normalizedData.entities || {};
      dispatch(addFolderData(dispatch, folders));
      dispatch(addFilesData(dispatch, images));
    });
    if (folderType === FolderService.FOLDER_TYPES.HIGHLIGHT) {
      FolderFilePositionOrderService.fetchFilePositionOrders(projectId, folderId).then((response) => {
        dispatch(setFilesOrderForFolder(dispatch, folderId, response.image_id_list));
      });
    }
  },
);

export function uploadFilesToFolderWithProgressTrigger(
  files: Array<File>,
  dispatch: Dispatch,
  options: {
    projectId: string;
    folderId: string;
    projectName: string;
    folderName: string;
    onSuccess?: (objectId: string) => void;
  },
) {
  const { projectId, folderId, projectName, folderName, onSuccess } = options;

  FolderService.addFilesToFolder(
    files,
    { projectId, folderId },
    {
      onAdd: (addedFile) => {
        dispatch(
          UploaderDuck.addFilesToUploader(dispatch, {
            fileBlob: URL.createObjectURL(addedFile.file),
            name: addedFile.name,
            uniqueIdentifier: addedFile.uniqueIdentifier,
            size: addedFile.size,
            uploaded: false,
            projectId,
            folderId,
            projectName,
            folderName,
          }),
        );
      },
      onSuccess: (addedFile, response) => {
        if (onSuccess) onSuccess(response.object_id);
        dispatch(
          UploaderDuck.setFileAsUploaded(dispatch, {
            uniqueIdentifier: addedFile.uniqueIdentifier,
            id: response.object_id,
          }),
        );
      },
      onError: (addedFile, response) => {
        dispatch(UploaderDuck.setFileAsError(dispatch, addedFile, response));
      },
      onProgress: (addedFile, progress, avgSpeed) => {
        dispatch(UploaderDuck.setFileUploadingProgressState(dispatch, addedFile.uniqueIdentifier, progress, avgSpeed));
      },
    },
  );
}

export const addFilesToFolder = createAction(
  getActionType('FILES', 'ADD'),
  (
    dispatch,
    files: Array<File>,
    options: {
      projectId: string;
      folderId: string;
      projectName: string;
      folderName: string;
      folderType: FolderService.FOLDER_TYPES;
    },
  ) => {
    const { projectId, folderId, projectName, folderName, folderType } = options;

    const totalFiles = files.length;
    const totalVideos = files.filter((file) => file.type.includes('video')).length;
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.UPLOAD, {
      projectId,
      folderId,
      folderType,
      folderName,
      totalFiles,
      totalVideos,
      totalSize,
    });

    const loadingKey = LOADINGS.FOLDER.ADD_FILES;
    dispatch(clearErrorState(dispatch, loadingKey));
    // 1. Add Files to the folder, so that we show the dummy images in the gallery
    FolderService.addInitialInfoFilesToFolder(files, {
      projectId,
      folderId,
    }).then(() => {
      // Fetch image and its orders and show in the gallery
      dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, folderType));
    });

    // 2. Upload the files
    uploadFilesToFolderWithProgressTrigger(files, dispatch, { projectId, folderId, projectName, folderName });
  },
);

export const addFilesToFolderWithUrl = createAction(
  getActionType('FILES_WITH_URL', 'ADD'),
  (
    dispatch,
    files: Array<File>,
    options: {
      projectId: string;
      folderId: string;
      projectName: string;
      folderName: string;
      folderType: FolderService.FOLDER_TYPES;
    },
  ) => {
    const { projectId, folderId, projectName, folderName, folderType } = options;
    const loadingKey = LOADINGS.FOLDER.ADD_FILES;

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    // 1. Add Files to the folder
    FolderService.addInitialInfoFilesToFolder(files, {
      projectId,
      folderId,
    });

    // 2. Upload the files
    uploadFilesToFolderWithProgressTrigger(files, dispatch, {
      projectId,
      folderId,
      projectName,
      folderName,
      onSuccess: (fileId) => {
        dispatch(setModalOptions(dispatch, LOADINGS.FOLDER.FILE_ID_FOR_EXTERNAL_VIDEO_LINK, fileId));
        // Fetch image and its orders and show in the gallery
        dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, folderType));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      },
    });
  },
);

export const addUrlToFiles = createAction(
  getActionType('ADD_URL_TO_FILES', 'ADD'),
  (
    dispatch,
    options: {
      projectId: string;
      folderId: string;
      folderType: FolderService.FOLDER_TYPES;
      fileId: string;
      url: string;
    },
  ) => {
    const { projectId, folderId, folderType, fileId, url } = options;

    const loadingKey = LOADINGS.FOLDER.UPDATE;

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FileService.updateFileInFolder(projectId, folderId, {
      asset_id: fileId,
      url,
    })
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Add url to files failed', e);
      });
  },
);

export const removeFolder = createAction(
  getActionType('ACTION', 'REMOVE'),
  (dispatch, projectId: string, folderId: string, folderType) => {
    const loadingKey = LOADINGS.FOLDER.DELETE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.DELETE, { projectId, folderId, folderType });

    FolderService.deleteFolder(projectId, folderId)
      .then((response) => {
        dispatch(removeFolderData(dispatch, folderId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, loadingKey, false));

        const projectDetailsPages = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.ALBUM_DETAILS, {
          projectId,
          folderType,
        });
        navigateTo(dispatch, projectDetailsPages);
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const renameFolder = createAction(
  getActionType('ACTION', 'RENAME'),
  (dispatch, projectId: string, folderId: string, formResponse: FormResponseType) => {
    const loadingKey = LOADINGS.FOLDER.RENAME;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    const { data } = formResponse;
    const folderName = data.folder_name as string;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FOLDER.RENAME, { projectId, folderId, folderName });

    FolderService.updateFolderName(projectId, folderId, data.folder_name as string)
      .then((response) => {
        dispatch(
          updateFolderData(dispatch, folderId, {
            folder_name: folderName,
          }),
        );
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const fetchFilePositionOrdersForFolder = createAction(
  getActionType('FILE_ORDER', 'FETCH'),
  (dispatch, projectId: string, folderId: string) => {
    const loadingKey = LOADINGS.FOLDER_FILES_POSITION_ORDER.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderFilePositionOrderService.fetchFilePositionOrders(projectId, folderId)
      .then((response) => {
        dispatch(setFilesOrderForFolder(dispatch, folderId, response.image_id_list));
        dispatch(setFilesOrderSortedByValueForFolder(dispatch, folderId, response.sort_order));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const updateFilesPosition = createAction(
  getActionType('FILE_ORDER', 'UPDATE'),
  (dispatch, updateFilePostionOrderData: FolderFilePositionOrderService.UpdateFilesPositionDataType) => {
    const { indexPosition, projectId, folderId, fileIds } = updateFilePostionOrderData;
    const { oldIndex, newIndex } = indexPosition;
    if (oldIndex === newIndex) return;

    const draggingImgId = fileIds[oldIndex];
    const draggingImgData = {
      image_id: draggingImgId,
      previous_image_id: getPreviousImageId(oldIndex, newIndex, fileIds),
    };

    dispatch(
      setFilesOrderForFolder(
        dispatch,
        folderId,
        FolderFilePositionOrderService.getNewFilesPositionOrders(fileIds, oldIndex, newIndex),
      ),
    );

    const loadingKey = LOADINGS.FOLDER_FILES_POSITION_ORDER.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderFilePositionOrderService.setFolderFilesPositionOrder(projectId, folderId, draggingImgData)
      .then((response) => {
        dispatch(setFilesOrderForFolder(dispatch, folderId, response.image_id_list));
        dispatch(setFilesOrderSortedByValueForFolder(dispatch, folderId, response.sort_order));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const updateFolderFileSortedByType = createAction(
  getActionType('FILE_ORDER_TYPE', 'UPDATE'),
  (dispatch, projectId: string, folderId: string, sortedByType: string) => {
    if (sortedByType === 'CUSTOM_ORDER') return;
    const data = {
      image_id: null,
      previous_image_id: null,
      sort_order: sortedByType,
    };
    const loadingKey = LOADINGS.FOLDER_FILES_POSITION_ORDER.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderFilePositionOrderService.setFolderFilesPositionOrder(projectId, folderId, data)
      .then((response) => {
        dispatch(setFilesOrderForFolder(dispatch, folderId, response.image_id_list));
        dispatch(setFilesOrderSortedByValueForFolder(dispatch, folderId, response.sort_order));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const toggleReviewStatus = createAction(
  getActionType('REVIEW_STATUS', 'UPDATE'),
  (dispatch, projectId: string, foldersIds: Array<string>, status: boolean) => {
    const loadingKey = LOADINGS.PHOTO_SELECTION.REQUEST_SELECTION_AGAIN;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    FolderService.toggleReviewStatus(
      projectId,
      foldersIds,
      status ? FolderService.FOLDER_STATUS.REVIEW_COMPLETED : FolderService.FOLDER_STATUS.DONE,
    )
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        foldersIds.map((folderId) =>
          dispatch(
            updateFolderData(dispatch, folderId, {
              status: status ? FolderService.FOLDER_STATUS.REVIEW_COMPLETED : FolderService.FOLDER_STATUS.DONE,
            }),
          ),
        );
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const deleteFileFromFolder = createAction(
  getActionType('FILE', 'DELETE'),
  (dispatch, { projectId, folderId, fileId, folderType, navigateToUrl, onDelete = () => {} }) => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FILE.DELETE, { projectId, folderId, fileId });
    const loadingKey = LOADINGS.FILE.DELETE;

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    dispatch(removeFile(dispatch, fileId));
    dispatch(removeFileFromFolder(dispatch, folderId, fileId));
    FileService.deleteFileInFolder(projectId, folderId, fileId)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        if (onDelete) onDelete();
        // Navigate to folder details after delete, This will also increase the friction for deleting multiple files
        if (navigateToUrl) {
          navigateTo(dispatch, navigateToUrl);
        }
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const deleteFilesFromFolder = createAction(
  getActionType('FILE_BULK', 'DELETE'),
  (dispatch, { projectId, folderId, fileIds }) => {
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.FILE.DELETE_BULK, { totalFiles: fileIds.length });
    const loadingKey = LOADINGS.FILE.DELETE;

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    fileIds.forEach((fileId) => {
      dispatch(removeFile(dispatch, fileId));
      dispatch(removeFileFromFolder(dispatch, folderId, fileId));
    });

    FileService.deleteFilesInFolder(projectId, folderId, fileIds)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

function removeFileFromFilesJson(files: Record<string, string>, id) {
  // eslint-disable-next-line no-param-reassign
  delete files[id];
  return files;
}
type FoldersItemType = IMap<string, FolderService.FolderType | ProposalService.ProposalType>;
type StateType = {
  items: FoldersItemType;
  bulkAction: 'delete' | null;
  selectedFilesForBulkAction: IList<string>;
  albumViewWithFileName: boolean;
};

const initialState = {
  items: IMap({}),
  bulkAction: null,
  selectedFilesForBulkAction: IList(),
  albumViewWithFileName: false,
};

export default handleActions(
  {
    [setFoldersData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addFolderData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeFolderData.toString()]: (state, action: any) => ({
      ...state,
      items: state.items.delete(action.payload),
    }),
    [updateFolderData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(
        action.payload.id,
        (folder: any) =>
          ({
            ...folder,
            ...action.payload.data,
          }) as FolderService.FolderType,
      ),
    }),
    [removeFileFromFolder.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (folder) =>
        Object.assign(
          folder as FolderService.FolderType,
          {
            // @ts-ignore
            files: removeFileFromFilesJson(folder.files, action.payload.fileId),
          } as FolderService.FolderType,
        ),
      ),
    }),
    [setBulkAction.toString()]: (state, action: { payload }) => ({ ...state, bulkAction: action.payload }),
    [toggleAlbumView.toString()]: (state, action: { payload }) => ({ ...state, albumViewWithFileName: action.payload }),
    [clearFileSelectionForBulkAction.toString()]: (state) => ({ ...state, selectedFilesForBulkAction: IList() }),
    [toggleFileSelectionForBulkAction.toString()]: (state, action: { payload }) => ({
      ...state,
      selectedFilesForBulkAction: action.payload.selected
        ? state.selectedFilesForBulkAction.push(action.payload.filesId)
        : state.selectedFilesForBulkAction.filter((fileId) => fileId !== action.payload.filesId),
    }),
  },
  initialState,
);

export const foldersDataSelector = createSelector(entitiesDataSelector, (entities) => entities.folders as StateType);

export const foldersSelector = createSelector(foldersDataSelector, (state) => state.items);

export const foldersEntitiesSelector = createSelector(foldersDataSelector, (state) => state.items.toJSON());

export const foldersArraySelector = createSelector(foldersSelector, (items) =>
  items
    .sort((a, b) => ArrayUtils.stringSortFunction(a.folder_name, b.folder_name))
    .valueSeq()
    .toArray(),
);

export const totalVideosInProject = createSelector(foldersArraySelector, (items) => {
  const highlightFolders = items.filter((item) => item.folder_type === FolderService.FOLDER_TYPES.HIGHLIGHT);
  return highlightFolders.reduce((result, folderData) => result + (folderData?.stats?.total_video || 0), 0);
});

export const foldersIdsSelector = createSelector(foldersSelector, (items) => items.keySeq().toArray());

export const folderIdsWithStatusDoneSelector = createSelector(foldersIdsSelector, foldersSelector, (folderIds, items) =>
  folderIds.filter(
    (folderId) =>
      items.get(folderId)?.folder_type === FolderService.FOLDER_TYPES.SELECTION &&
      items.get(folderId)?.status === FolderService.FOLDER_STATUS.DONE,
  ),
);

export const folderIdsInReviewWorkflowSelector = createSelector(
  foldersIdsSelector,
  foldersSelector,
  (folderIds, items) =>
    folderIds.filter(
      (folderId) =>
        items.get(folderId)?.folder_type === FolderService.FOLDER_TYPES.SELECTION &&
        (items.get(folderId)?.status === FolderService.FOLDER_STATUS.WAITING_FOR_APPROVAL ||
          items.get(folderId)?.status === FolderService.FOLDER_STATUS.REVIEW_COMPLETED),
    ),
);

export const folderCoverImageSelector = (folderId: string) =>
  createSelector(foldersSelector, (state) => {
    const src = state.get(folderId)?.meta?.cover_image?.dynamic_image_url;
    if (!src) return undefined;
    return MediaUtils.getDynamicImageURL(src, MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL, { width: 300 });
  });

export const folderTypeSelector = (folderId: string) =>
  createSelector(foldersSelector, (folders) => folders.get(folderId)?.folder_type);

export const filesOrderForFolderSelector = (folderId: string) =>
  createSelector(
    foldersSelector,
    filesEntitiesDataOrderSelector,
    filesEntitiesDataSelector,
    foldersMetaDataSelector,
    (folders, filesOrder, files, foldersMetaData) =>
      getFolderFilesOrder(folderId, folders, filesOrder, files, foldersMetaData),
  );

export const filesNavigationDataForFolderSelector = (folderId: string, fileId: string) =>
  createSelector(
    foldersSelector,
    filesEntitiesDataOrderSelector,
    filesEntitiesDataSelector,
    foldersMetaDataSelector,
    (folders, filesOrder, files, foldersMetaData) => {
      const filesIndex = getFolderFilesOrder(folderId, folders, filesOrder, files, foldersMetaData);

      const currentLocation = filesIndex.indexOf(fileId);
      const totalFiles = filesIndex.length;
      const nextFileId = filesIndex[currentLocation + 1];
      const previousFileId = filesIndex[currentLocation - 1];

      return {
        nextFileId,
        previousFileId,
        totalFiles,
      };
    },
  );

export const totalFilesInFolderSelector = (folderId: string) =>
  createSelector(foldersSelector, (folders) => {
    const folder = folders.get(folderId);
    if (!folder) return 0;
    if (!folder.files) return 0;
    return Object.keys(folder.files).length;
  });

export const filesUploadStatusInFolderSelector = (folderId: string) =>
  createSelector(foldersSelector, filesEntitiesDataSelector, (folders, files) => {
    const folder = folders.get(folderId);
    const folderFiles = folder?.files || {};
    return files
      .filter((file, id) => id in folderFiles)
      .groupBy((file) => file.asset_upload_status || FileService.FILE_UPLOAD_STAGES.DONE)
      .reduce(
        (result, group, key) => ({
          ...result,
          [key]: group.toMap().size,
        }),
        {
          [FileService.FILE_UPLOAD_STAGES.UPLOADING]: 0,
          [FileService.FILE_UPLOAD_STAGES.DONE]: 0,
          [FileService.FILE_UPLOAD_STAGES.QUEUED]: 0,
          [FileService.FILE_UPLOAD_STAGES.PROCESSING]: 0,
          [FileService.FILE_UPLOAD_STAGES.ERROR]: 0,
        },
      );
  });

export const hasFilesPassedTimeoutInQueueStatusSelector = (folderId: string) =>
  createSelector(foldersSelector, filesEntitiesDataSelector, (folders, files) => {
    const folder = folders.get(folderId);
    const folderFiles = folder?.files || {};
    const TIMEOUT = 5 * 60 * 1000; // 5 mins
    const lastestCreatedAt = files
      .filter((file, id) => id in folderFiles)
      .reduce((result, file) => Math.max(result, file.created_at), 0);
    return SimpleDateUtils.nowInEpoc() > lastestCreatedAt * 1000 + TIMEOUT;
  });

export const filesSizeInfoInFolderSelector = (folderId: string) =>
  createSelector(foldersSelector, filesEntitiesDataSelector, (folders, files) => {
    const folder = folders.get(folderId);
    const folderFiles = folder?.files || {};
    return files
      .filter((file, id) => id in folderFiles)
      .reduce(
        (result, file) => {
          const isVideo = file.asset_type === FileService.ASSET_TYPES.VIDEO;
          return {
            images: isVideo ? result.images : result.images + 1,
            videos: isVideo ? result.videos + 1 : result.videos,
          };
        },
        {
          images: 0,
          videos: 0,
        },
      );
  });

export const isInBulkActionSelector = createSelector(foldersDataSelector, (state) => !!state.bulkAction);

export const isBulkDeleteEnabledSelector = createSelector(
  foldersDataSelector,
  (state) => state.bulkAction === 'delete',
);
export const selectedFilesForBulkActionSelector = createSelector(
  foldersDataSelector,
  (state) => state?.selectedFilesForBulkAction.toJSON(),
);

export const albumViewWithFileNameSelector = createSelector(
  foldersDataSelector,
  (state) => state.albumViewWithFileName,
);

export const folderWithBrandingTypeSelector = createSelector(foldersSelector, (folders) =>
  folders.find((folder) => folder.folder_type === FolderService.FOLDER_TYPES.BRANDING),
);

export const brandingFolderFileIdsSelector = createSelector(folderWithBrandingTypeSelector, (folder) =>
  folder?.files ? (Object.values(folder.files) as Array<string>) : undefined,
);

export const folderWithSponsorTypeSelector = createSelector(foldersSelector, (folders) =>
  folders.find((folder) => folder.folder_type === FolderService.FOLDER_TYPES.SPONSOR),
);

export const sponsorFolderFileIdsSelector = createSelector(folderWithSponsorTypeSelector, (folder) =>
  folder?.files ? (Object.values(folder.files) as Array<string>) : undefined,
);

export const isAnyHighlightFolderMadePublicSelector = createSelector(
  foldersSelector,
  foldersMetaSelector,
  (folders, foldersMeta) =>
    folders.some(
      (folder) =>
        folder.folder_type === FolderService.FOLDER_TYPES.HIGHLIGHT &&
        Boolean(foldersMeta?.[folder.folder_id]?.[FolderMetaService.FOLDER_META_TYPES.IS_FOLDER_PUBLIC]),
    ),
);
