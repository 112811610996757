import React from 'react';
import { Card } from '@premagic/myne';
import ClientWebsiteDesignPresetsContainer from './ClientWebsiteDesignPresetsContainer';

type Props = {
  projectId: string;
  id: string;
};

export default function ClientWebsitePageSidebar(props: Props): React.ReactElement {
  const { projectId, id } = props;

  return (
    <div>
      <Card>
        <ClientWebsiteDesignPresetsContainer id={id} projectId={projectId} isInsideCard />
      </Card>
    </div>
  );
}
