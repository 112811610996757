import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { clientWebsiteForProjectSelector, guestGalleryLinkSelector } from '../../../projects/AccountProjectsDataDuck';
import {
  isFeatureMagazineEnabledSelector,
  projectMetaDataForProjectSelector,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { clientSettingsDataSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';

import EventExhibitorItem from './EventExhibitorItem';
import { eventExhibitorsSelector } from './EventExhibitorDataDuck';
import { exhibitorListStyleSelector } from './EventExhibitorPageDuck';

function mapStateToProps(state, ownProps: { exhibitorId: string; projectId: string }) {
  const { exhibitorId, projectId } = ownProps;

  const clientSettingsData = clientSettingsDataSelector(state);
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    exhibitorId,
    exhibitor: eventExhibitorsSelector(state)[exhibitorId],
    projectId,
    hasPermissions: {
      [PERMISSIONS.EVENT_EXHIBITOR]: hasPermission(PERMISSIONS.EVENT_EXHIBITOR)(state),
    },
    listStyle: exhibitorListStyleSelector(state),
    hasPoster: !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED],
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventExhibitorItem);
