import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';

import StorePage from './StorePage';
import { hasFeatureSelector } from '../settings/company/CompanyDataDuck';
import { isMobileUp } from '../AppDuck';
import { LOADINGS } from '../../../../common/Constants';
import { getStoreOfferData } from './StoreOffersDataDuck';

function mapStateToProps(state, ownProps) {
  const { filters, view = 'list' } = ownProps.match.params;

  const hasStoreFeature = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.STORE_OFFERS)(state);
  return {
    filters,
    view,
    hasStoreFeature,
    isMobileUp: isMobileUp(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.STORE.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => dispatch(getStoreOfferData(dispatch)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePage);
