import { Layer, Stage } from 'react-konva';
import Konva from 'konva';
import React, { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { EnvUtils, useResizeObserver } from '@premagic/utils';
import { PosterFileType, PosterType } from '../services/PosterService';
import { POSTER_WIDGET_TYPE, PosterWidgetTextType, PosterWidgetType } from '../services/PosterWidgetService';
import PosterContext from './PosterContext';
import PosterWidget from './PosterWidget';

// poster not getting rendered properly in iphone 13, ios 16 https://github.com/konvajs/konva/issues/608
Konva.pixelRatio = 1;
Konva.showWarnings = !EnvUtils.isProd();

type PosterContainerProps = {
  children: React.ReactNode;
  config: {
    width: number;
    height: number;
  };
  containerSize: {
    width: number;
    height: number;
  };
  options?: Record<string, string | Array<string>>;
  scale?: 'auto' | 'fit-in';
};

function PosterContainer(props: PosterContainerProps) {
  const { containerSize, children, config, options, scale = 'fit-in' } = props;
  const { width, height } = config;
  const [cursorStyle, setCursorStyle] = useState('default');
  const [scaleForScreen, setScale] = useState(1);
  const heightScale = height * scaleForScreen; // 1200 * 0.3433333333333333 = 412
  const widthScale = width * scaleForScreen; // 1200 * 0.3433333333333333 = 412

  useLayoutEffect(() => {
    if (scale === 'fit-in') {
      setScale(Math.min(containerSize.width / width, 1));
    }
  }, [containerSize.height, containerSize.width, height, scale, width]);

  const $stage = useRef(null);
  const context = useMemo(
    () => ({
      setCursor: (cursor: string) => {
        setCursorStyle(cursor);
      },
      options,
    }),
    [options],
  );

  return (
    <PosterContext.Provider value={context}>
      <div
        style={{
          width: widthScale,
          height: heightScale,
          transformOrigin: 'top left',
          transform: `scale(${scaleForScreen})`,
        }}
      >
        <Stage ref={$stage} width={width} height={height} captureAllKeyEvents={false} className="js-poster">
          <Layer>{children}</Layer>
        </Stage>
      </div>
    </PosterContext.Provider>
  );
}

type PosterProps = {
  data: PosterType;
  files: Record<string, PosterFileType>;
  fetchFonts?: (fonts: Array<string>) => void;
  options?: Record<string, string | Array<string>>;
  scale?: 'auto' | 'fit-in';
};

export function Poster(props: PosterProps) {
  const { data, files, fetchFonts, options, scale = 'fit-in' } = props;
  const { size, widgets } = data;
  const items = Object.entries(widgets) as Array<[id: string, item: PosterWidgetType]>;
  // The widgets don't get rerender if you wrap this on a useMemo
  const widgetsToRender = items
    .filter(([id, item]) => {
      if (item.is_hidden) return false;
      if ([POSTER_WIDGET_TYPE.TEXT, POSTER_WIDGET_TYPE.IMAGE].includes(item.type)) return item.value;
      return true;
    })
    .sort(([, item1], [, item2]) => {
      if (item1.position.z > item2.position.z) return 1;
      if (item1.position.z < item2.position.z) return -1;
      return 0;
    });

  useEffect(() => {
    if (fetchFonts) {
      // Load all the folder in widget of type text
      const textWidgets = Object.values(widgets).filter(
        (widget) => widget.type === POSTER_WIDGET_TYPE.TEXT,
      ) as Array<PosterWidgetTextType>;
      const fontsToLoad = textWidgets.reduce((acc, widget) => {
        if (widget.font_family && !acc.includes(widget.font_family)) acc.push(widget.font_family);
        return acc;
      }, [] as string[]);
      if (fontsToLoad.length) {
        fetchFonts(fontsToLoad);
      }
    }
  }, [fetchFonts, widgets]);

  const $wrapper = useRef(null) as RefObject<HTMLDivElement>;
  const [containerSize, setContainerSize] = useState({
    width: 100,
    height: 100,
  });

  const handleOnResize = useCallback(
    (newSize: { width: number; height: number }) => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const isPosterLandscape = Number(size.width) > Number(size.height);
      // setContainerSize({ width: newSize.width, height: newSize.height });

      if (windowWidth > 800) {
        const minWidth = isPosterLandscape ? windowWidth / 2 - 32 : 400;
        const maxHeight = isPosterLandscape ? windowHeight / 2 : 300;
        const newWidth = Math.max(newSize.width, minWidth);
        const newHeight = Math.min(newSize.height, maxHeight);
        return setContainerSize({ width: newWidth, height: newHeight });
      }
      const minWidth = windowWidth - 32;
      const maxHeight = windowHeight / 2;
      const newWidth = Math.max(newSize.width, minWidth);
      const newHeight = Math.min(newSize.height, maxHeight);
      setContainerSize({ width: newWidth, height: newHeight });
    },
    [size.height, size.width],
  );

  useResizeObserver({
    ref: $wrapper,
    onResize: handleOnResize,
  });
  if (!size) return <div>Size not set</div>;

  return (
    <div
      className="js-poster-wrapper"
      ref={$wrapper}
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'none', // Ignore all pointer events when poster is non editable. This will allow the click event to pass through to its parent (otherwise it causes issues when used inside a button).
      }}
    >
      <PosterContainer
        containerSize={containerSize}
        config={{
          width: Number(size.width),
          height: Number(size.height),
        }}
        options={options}
        scale={scale}
      >
        {widgetsToRender.map(([id, item]) => {
          const { type } = item;
          return <PosterWidget type={type} key={id} data={item} files={files} />;
        })}
      </PosterContainer>
    </div>
  );
}
