import { EventTrackerService, PresetDesignService } from '@premagic/core';
import { ButtonTile, BUTTON_TILE_SIZE, BUTTON_TILE_STYLE } from '@premagic/myne';
import * as presetDesignSvg from './svg-preset-design';

type PresetDesignButtonProps = {
  onChange: (value) => void;
  selectedPresetDesign: string;
  isPresetDesignSelectorEnabled: boolean;
  option: PresetDesignService.PresetDesignOption;
  variant?: PresetDesignService.PRESET_DESIGN_VARIANT;
};

export default function PresetDesignButton(props: PresetDesignButtonProps) {
  const {
    selectedPresetDesign,
    option,
    onChange,
    isPresetDesignSelectorEnabled,
    variant = PresetDesignService.PRESET_DESIGN_VARIANT.PRIMARY,
  } = props;

  function getSvgName() {
    if (variant === PresetDesignService.PRESET_DESIGN_VARIANT.SECONDARY) {
      return option.svgNameSecondary;
    }
    if (variant === PresetDesignService.PRESET_DESIGN_VARIANT.PRIMARY) {
      return option.svgNamePrimary;
    }
    return option.svgNamePrimary;
  }

  return (
    <ButtonTile
      onClick={() => {
        if (selectedPresetDesign !== option.value) {
          EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.HIGHLIGHT_DESIGN.GALLERY_PRESET, {
            'selected-preset': option.value,
          });
          onChange(option);
        }
      }}
      active={selectedPresetDesign === option.value}
      disabled={!isPresetDesignSelectorEnabled}
      disabledMessage="Upgrade your Plan to select this Preset"
      tooltipPlacement="top"
      style={BUTTON_TILE_STYLE.PILL}
      size={BUTTON_TILE_SIZE.SM}
    >
      {presetDesignSvg[getSvgName()]}
    </ButtonTile>
  );
}
