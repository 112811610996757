import React, { useEffect, useState } from 'react';
import { EventAttendeesService, EventCustomFromFieldsService } from '@premagic/core';
import {
  Button,
  BUTTON_CIRCLE_SIZES,
  BUTTON_CIRCLE_STYLES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonCircle,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Color,
  COLOR_SHADES,
  FileUploader,
  FormErrorType,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  Row,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { ErrorTracker } from '@premagic/utils';
import AttendeePictureCapture from './AttendeePictureCapture';
import { toastMessage } from '../../../../../reducers/ToastStore';

enum VIEWS {
  CAPTURE_PHOTO = 'capture',
  DEFAULT = 'default',
}

type Props = {
  error: FormErrorType;
  attendee?: EventAttendeesService.EventAttendeeType;
  onChangeOfFile: (file?: File | null) => void;
};

export function AttendeeProfilePicture(props: Props): JSX.Element {
  const { error, attendee, onChangeOfFile } = props;
  const [view, setView] = useState<VIEWS>(VIEWS.DEFAULT);
  const [userPictureFile, setPictureFile] = useState<File | null>();
  const [showSetUploadPic, setShowSetUploadPic] = useState<boolean>(false);
  const faceFile = attendee?.custom_fields?.[EventCustomFromFieldsService.SYSTEM_CUSTOM_FORM_FIELDS.FACE];

  useEffect(() => {
    onChangeOfFile(userPictureFile);
  }, [onChangeOfFile, userPictureFile]);

  function onRetake() {
    setPictureFile(null);
  }

  if (error?.selfi) {
    return (
      <div>
        {userPictureFile && (
          <Card style={CARD_STYLES.TRANSPARENT} size={CARD_SIZES.BOX_SM} center overflowHidden>
            <ImageV2 src={URL.createObjectURL(userPictureFile)} style={IMAGE_V2_STYLES.FULL_IMAGE} alt="user" />
          </Card>
        )}

        <Space vertical />
        <Row vcenter>
          <Color shade={COLOR_SHADES.DANGER} inline>
            <Icon name="alert_triangle" size={ICON_SIZES.SM} />
          </Color>
          <Space size={1} />
          <Text color={COLOR_SHADES.DANGER}>{error?.selfi}</Text>
        </Row>

        <Button style={BUTTON_STYLES.PRIMARY} onClick={onRetake}>
          Retry
        </Button>
      </div>
    );
  }
  if (attendee && attendee?.face_id && !showSetUploadPic) {
    return (
      <Row vcenter>
        <Card style={CARD_STYLES.TRANSPARENT} size={CARD_SIZES.BOX_SM} center overflowHidden>
          {faceFile ? (
            <ImageV2 src={faceFile?.dynamic_image_url} style={IMAGE_V2_STYLES.FULL_IMAGE} alt={attendee?.name} />
          ) : (
            <Space>
              <Row center columnDirection>
                <Space vertical size={12} />
                <ButtonCircle style={BUTTON_CIRCLE_STYLES.SUCCESS} size={BUTTON_CIRCLE_SIZES.SM}>
                  <Icon name="check" size={ICON_SIZES.SM} />
                </ButtonCircle>
                <Space vertical size={1} />
                <Text center block size={TEXT_SIZE.SIZE_5}>
                  Photo is processed
                </Text>
                <Space vertical size={8} />
                <Text muted center block size={TEXT_SIZE.SIZE_6}>
                  Due to privacy concerns, Photo is not displayed
                </Text>
              </Row>
            </Space>
          )}
        </Card>
        <Space vertical />
        <div>
          <Button onClick={() => setShowSetUploadPic(true)}>Change</Button>
        </div>
      </Row>
    );
  }

  if (view === VIEWS.CAPTURE_PHOTO) {
    return (
      <AttendeePictureCapture
        onCapture={(file) => {
          setPictureFile(file);
          setView(VIEWS.DEFAULT);
        }}
      />
    );
  }
  if (userPictureFile) {
    return (
      <Row vcenter>
        {userPictureFile && (
          <Card style={CARD_STYLES.TRANSPARENT} size={CARD_SIZES.BOX_SM} center overflowHidden>
            <ImageV2 src={URL.createObjectURL(userPictureFile)} style={IMAGE_V2_STYLES.FULL_IMAGE} alt="user" />
          </Card>
        )}
        <Space vertical />
        <div>
          <Button style={BUTTON_STYLES.CANCEL} onClick={onRetake}>
            Change
          </Button>
        </div>
      </Row>
    );
  }

  return (
    <Card style={CARD_STYLES.TRANSPARENT} size={CARD_SIZES.BOX_SM} center>
      <Row columnDirection center>
        <Button onClick={() => setView(VIEWS.CAPTURE_PHOTO)} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD}>
          <Icon name="camera" size={ICON_SIZES.SM} />
          <Space size={1} />
          Take photo
        </Button>
        <Space vertical size={8} />
        <FileUploader
          accept="image/jpeg, image/png"
          onError={(message, e) => {
            toastMessage('error', message, 200);
            ErrorTracker.logError('Invalid file format', e);
          }}
          onChange={(e, files) => {
            if (files.length) setPictureFile(files[0]);
            return {};
          }}
        >
          <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD}>
            <Icon name="upload" size={ICON_SIZES.SM} />
            <Space size={1} />
            Select photo
          </Button>
        </FileUploader>
      </Row>
    </Card>
  );
}
