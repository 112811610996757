import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import { ArrayUtils, ErrorTracker, ActionTypeUtils, BrowserUrlUtils } from '@premagic/utils';
import { LoadingDuck, ErrorDuck, ModalDuck } from '@premagic/common-ducks';
import { EventTrackerService, Schemas } from '@premagic/core';

import { LOADINGS, MODALS } from '../../../common/Constants';

import {
  ALBUM_STATES,
  AlbumType,
  deleteAlbumService,
  fetchAlbumsForProject,
  updateAlbumDataService,
} from '../services/AlbumServices';
import { albumCreatorPageSelector } from '../../../common/selectors';
import { ALBUM_CREATOR_URLS } from '../services/AlbumCreatorRouteURLService';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM');

export const setAlbumData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const updateAlbumData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
export const deleteAlbumData = createAction(getActionType('DATA', 'DELETE'), (dispatch, data) => data);

export const getAlbumsForProjectId = createAction(getActionType('DATA', 'GET'), async (dispatch, projectId) => {
  const loadingKey = LOADINGS.ALBUM.LOADINGS;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
  try {
    const albumsData = await fetchAlbumsForProject(projectId);
    const normalizedData = normalize(albumsData.results, Schemas.AlbumsSchema);

    dispatch(setAlbumData(dispatch, normalizedData.entities.albums));
  } catch (e) {
    // @ts-ignore
    dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
    ErrorTracker.logError('ALBUM', e);
  }

  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
});

export const updateAlbum = createAction(
  getActionType('STATE', 'UPDATE'),
  (
    dispatch,
    id,
    data,
    options: {
      eventId?: string;
    },
  ) => {
    const loadingKey = LOADINGS.ALBUM.UPDATE;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    updateAlbumDataService(id, data)
      .then((response) => {
        dispatch(updateAlbumData(dispatch, response));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.ALBUM.UPDATE_STATE, false));

        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.ALBUM_CREATOR.SEND_TO_CLIENT);

        if (options?.eventId && data.state === ALBUM_STATES.REVIEW_PENDING) {
          const eventDetailsURL = BrowserUrlUtils.getRouteUrlFor(ALBUM_CREATOR_URLS.CRM.EVENT__DELIVERABLE, {
            eventId: options?.eventId,
            focusId: id,
          });
          dispatch(push(eventDetailsURL));
        }

        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

export const removeAlbum = createAction(
  getActionType('STATE', 'REMOVE'),
  (
    dispatch,
    id,
    options: {
      eventId?: string;
    },
  ) => {
    const loadingKey = LOADINGS.ALBUM.DELETE;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));

    deleteAlbumService(id)
      .then((response) => {
        dispatch(deleteAlbumData(dispatch, id));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));

        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.ALBUM_CREATOR.DELETE);

        if (options?.eventId) {
          const eventDetailsURL = BrowserUrlUtils.getRouteUrlFor(ALBUM_CREATOR_URLS.CRM.EVENT__DELIVERABLE, {
            eventId: options?.eventId,
          });
          dispatch(push(eventDetailsURL));
        }

        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

type StateType = {
  items: IMap<string, AlbumType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setAlbumData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [deleteAlbumData.toString()]: (state, action) => ({
      ...state,
      items: state.items.remove(String(action.payload)),
    }),
    [updateAlbumData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (item: any) => ({
        ...item,
        ...action.payload,
      })),
    }),
  },
  initialState,
);
const albumCreatorAlbumSelector = createSelector(
  albumCreatorPageSelector,
  (albumCreator) => albumCreator.data.album as StateType,
);

export const albumCreatorDataSelector = createSelector(albumCreatorAlbumSelector, (state) => state.items);

export const albumEntitySelector = (albumId: string) =>
  createSelector(albumCreatorDataSelector, (items) => items.get(albumId));

export const projectIdForAlbumSelector = (albumId: string) =>
  createSelector(albumCreatorDataSelector, (items) => {
    const album = items.get(albumId);
    return album?.project_id;
  });

export const albumIdsSelector = createSelector(albumCreatorDataSelector, (items) =>
  items
    .sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.name, b.name))
    .keySeq()
    .toArray(),
);
