import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { isAccountSupportAccessEnabled, saveClientSettingsData } from '../settings/preferences/ClientSettingsDataDuck';
import AccountSupportAccessToggle from './AccountSupportAccessToggle';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    isAccountSupportAccessEnabled: isAccountSupportAccessEnabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSupportAccessToggle);
