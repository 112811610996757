import { BrowserUrlUtils } from '@premagic/utils';
import { DAMLibraryFiltersType } from './DAMLibraryFilterTypes';
import APP_URLS from '../../../../services/AppRouteURLService';

export function getDAMLibraryUrlWithFilters(filters: DAMLibraryFiltersType): string {
  return BrowserUrlUtils.getRouteUrlFor(APP_URLS.LIBRARY.INDEX, {
    filters: BrowserUrlUtils.getQueryStringFromObject(filters, { arrayFormat: 'repeat' }),
  });
}

export function getDefaultLibraryDAMWithFilters(): string {
  const defaultFilter = {};

  return getDAMLibraryUrlWithFilters(defaultFilter);
}
