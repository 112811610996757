import React, { useRef } from 'react';
import {
  BetaLabel,
  Button,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Dialog,
  DialogTitle,
  Divider,
  Dropdown,
  DropdownIcon,
  FormFooter,
  Icon,
  ICON_SIZES,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  StatusLabel,
  Text,
  TEXT_BOLDNESS,
  UserFeedback,
} from '@premagic/myne';
import { ALBUM_STATES, ALBUM_STATES_DETAILS, AlbumType } from '../../services/AlbumServices';

type Props = {
  album?: AlbumType;
  id: string;
  updateAlbumState: (id: string, state: ALBUM_STATES, eventId: string) => void;
  isSaving: boolean;
  isConfirmDialogShown: boolean;
  toggleConfirmDialog: (show: boolean) => void;
  deleteAlbum: (id: string, eventId: string) => void;
  eventId: string;
  isDeleting: boolean;
};

export default function AlbumStateInfo(props: Props) {
  const {
    album,
    updateAlbumState,
    id,
    isSaving,
    isConfirmDialogShown,
    toggleConfirmDialog,
    eventId,
    deleteAlbum,
    isDeleting,
  } = props;
  const target = useRef(null);
  if (!album) {
    return null;
  }
  const { name, state } = album;
  const stateDetails = ALBUM_STATES_DETAILS[state];

  return (
    <>
      <Space size={1}>
        <BetaLabel />
        <Space size={2} />
        <UserFeedback module="album-creator" />
      </Space>
      <Divider />
      <Space>
        <div ref={target}>
          <Row vcenter>
            <Col size={8}>
              <div>
                <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} ellipsis block>
                  {name}
                </Text>
                <StatusLabel style={stateDetails.color}>{stateDetails.title}</StatusLabel>
              </div>
            </Col>

            <Col rightAlighed size={null}>
              {state === ALBUM_STATES.DRAFT ? (
                <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving} onClick={() => toggleConfirmDialog(true)}>
                  Share
                </Button>
              ) : (
                <Dropdown>
                  <DropdownIcon title="More" tooltipPlacement="top" isLoading={isDeleting}>
                    <Icon name="more_vertical" size={ICON_SIZES.SM} />
                  </DropdownIcon>
                  <Menu>
                    <MenuItem onClick={() => deleteAlbum(id, eventId)}>
                      <MenuItemIcon name="trash" />
                      Delete
                    </MenuItem>
                  </Menu>
                </Dropdown>
              )}
            </Col>
          </Row>
        </div>
        {isConfirmDialogShown && (
          <Dialog target={target.current} show={isConfirmDialogShown} onClose={() => toggleConfirmDialog(false)}>
            <DialogTitle>Share album with your client?</DialogTitle>
            <Text>
              After you share the album will show up in the{' '}
              <Text boldness={TEXT_BOLDNESS.BOLD}>client's selection album</Text> home page.
            </Text>
            <FormFooter>
              <Button
                style={BUTTON_STYLES.PRIMARY}
                isLoading={isSaving}
                onClick={() => updateAlbumState(id, ALBUM_STATES.REVIEW_PENDING, eventId)}
              >
                Ok, Share
              </Button>
            </FormFooter>
          </Dialog>
        )}
      </Space>
    </>
  );
}
