import { Dispatch } from 'redux';
import { Map as IMap, List } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FormResponseType } from '@premagic/myne';
import { ErrorTracker, ActionTypeUtils, BrowserUrlUtils, ArrayUtils } from '@premagic/utils';
import { LoadingDuck, ErrorDuck, WindowPanelDuck, ModalDuck } from '@premagic/common-ducks';
import { EventTrackerService, RouterService, Schemas } from '@premagic/core';

import { KEYS } from '../common/ActionConstants';
import { proposalPageSelector } from '../common/selectors';
import {
  createProposalDeckVersion,
  fetchProposalDeck,
  ProposalDeckType,
  updateProposalDeck,
} from './ProposalDeckService';
import { PROPOSALS_URLS } from '../services/ProposalRouteURLService';
import { proposalVariablesItemsSelector } from '../proposal-variables/ProposalVariablesDataDuck';
import {
  ProposalVariableType,
  PROPOSAL_VARIABLE_SCOPE,
  flattenVariablesObject,
} from '../proposal-variables/ProposalVariableService';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROPOSAL_DECK');

export const setProposalDecksData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addProposalDeckData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateProposalDeckData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);
const updateProposalDeckSlidesData = createAction(getActionType('DATA', 'UPDATE_SLIDES'), (dispatch, id, slides) => ({
  id,
  data: { slides },
}));
export const addProposalDeckSlideData = createAction(getActionType('DATA', 'ADD_SLIDE'), (dispatch, id, slideId) => ({
  id,
  slideId,
}));
export const removeProposalDeckSlideData = createAction(
  getActionType('DATA', 'REMOVE_SLIDE'),
  (dispatch, id, slideId) => ({
    id,
    slideId,
  }),
);
const removeProposalDeckData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, id) => id);

export const getProposalDeck = createAction(
  getActionType('DATA', 'GET'),
  (dispatch, projectId: string, deckId: string) => {
    const loadingKey = KEYS.PROPOSAL_DECK.FETCH(deckId);

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    fetchProposalDeck(projectId, deckId)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProposalDeckSchema);
        dispatch(addProposalDeckData(dispatch, normalizedData.entities.decks));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalDeck: fetch failed', e);
      });
  },
);

export const addNewProposalDeckVersion = createAction(
  getActionType('DATA', 'CREATE'),
  (
    dispatch: Dispatch,
    projectId: string,
    deckId: string,
    options: {
      folderId: string;
    },
  ) => {
    const loadingKey = KEYS.PROPOSAL_DECK.CREATE_VERSION;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.CREATE_VERSION, {
      projectId,
    });

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    createProposalDeckVersion(projectId, deckId, options)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProposalDeckSchema);
        dispatch(addProposalDeckData(dispatch, normalizedData.entities.decks));
        // Navigate to new slide
        const newDeckUrl = BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS.DETAILS, {
          projectId,
          folderId: options.folderId,
          deckId: response.id,
        });

        RouterService.navigateTo(dispatch, newDeckUrl);
        ModalDuck.toggleModalVisibility(dispatch, KEYS.PROPOSAL.SHOW_EDIT_PROPOSAL_CONFIRMATION, false);
        // Open the Variable panel by defaultØ
        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL, true),
        );
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalDeck: add new failed', e);
      });
  },
);

export const fetchProposalDeckVersion = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch: Dispatch, projectId: string, deckIds: Array<string>) => {
    const loadingKey = KEYS.PROPOSAL_DECK.FETCH_ALL;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));

    Promise.all(deckIds.map((deckId) => fetchProposalDeck(projectId, deckId)))
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProposalDecksSchema);
        dispatch(addProposalDeckData(dispatch, normalizedData.entities.decks));

        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalDeckVersions: fetch failed', e);
      });
  },
);

export const saveProposalDeck = createAction(
  getActionType('ACTION', 'UPDATE'),
  (dispatch, projectId, id, formResponse) => {
    const loadingKey = KEYS.PROPOSAL_DECK.UPDATE;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
    if (formResponse.errors) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, formResponse.errors));
      return;
    }
    updateProposalDeck(projectId, id, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.ProposalDeckSchema);
        dispatch(updateProposalDeckData(dispatch, normalizedData.entities.decks));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalDeck: save failed', e);
      });
  },
);

export const updateProposalDeckSidesOrder = createAction(
  getActionType('ACTION', 'UPDATE_SIDES_ORDER'),
  (dispatch, options: { from: number; to: number; projectId: string; deckId: string; slideIds: Array<number> }) => {
    const loadingKey = KEYS.PROPOSAL_DECK.UPDATE_SLIDE_ORDER;
    const { from, to, projectId, deckId, slideIds } = options;

    const newSlideIds = ArrayUtils.arrayMove(slideIds, from, to);

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));

    dispatch(updateProposalDeckSlidesData(dispatch, deckId, newSlideIds));
    updateProposalDeck(projectId, deckId, {
      slides: newSlideIds,
    })
      .then((response) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ProposalDeck: save order failed', e);
      });
  },
);

type StateType = {
  items: IMap<string, ProposalDeckType>;
};
const initialState = {
  items: IMap({}),
};

export const ProposalDeckDataReducer = handleActions(
  {
    [setProposalDecksData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addProposalDeckData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateProposalDeckData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeProposalDeckData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.remove(String(action.payload)),
    }),
    [updateProposalDeckSlidesData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (deck) => ({
        ...deck,
        ...action.payload.data,
      })),
    }),
    [addProposalDeckSlideData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (deck) => ({
        ...deck,
        slides: deck.slides.concat([action.payload.slideId]),
      })),
    }),
    [removeProposalDeckSlideData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (deck) => ({
        ...deck,
        slides: deck.slides.filter((slide) => slide != action.payload.slideId),
      })),
    }),
  },
  initialState,
);

const proposalDecksDataSelector = createSelector(proposalPageSelector, (state) => state.data.decks as StateType);
export const proposalDecksSelectors = createSelector(proposalDecksDataSelector, (state) => state.items);
export const proposalsDeckItemsSelectors = createSelector(proposalDecksSelectors, (items) => items.toJSON());

export const proposalsDeckVariableDetailsSelectors = (deckId: string) =>
  createSelector(proposalDecksSelectors, proposalVariablesItemsSelector, (items, variables) => {
    const deckVariableValues = items.get(deckId)?.variables || {};

    const flattenedVariablesObject = flattenVariablesObject(deckVariableValues);

    const deckVariableWithVariableDetails = Object.entries({ ...deckVariableValues, ...flattenedVariablesObject })
      .filter(([variableId, value]) => variableId !== 'proposal_variable_details' && typeof value !== 'object')
      .map(([variableId, value]) => {
        const variable = variables.get(variableId) as ProposalVariableType;
        return {
          ...variable,
          value,
        };
      });
    return List<
      ProposalVariableType & {
        value: string | number;
      }
      // @ts-ignore
    >(deckVariableWithVariableDetails)
      .groupBy((variable) => variable.scope)
      .toJS() as Record<
      PROPOSAL_VARIABLE_SCOPE,
      Array<
        ProposalVariableType & {
          value: string | number;
        }
      >
    >;
  });

export const proposalsSlideIdsForDeckSelectors = (deckId: string) =>
  createSelector(proposalDecksSelectors, (items) => {
    const deck = items.get(deckId);
    if (!deck) {
      return [];
    }
    return deck.slides || [];
  });

export const proposalsNextSlideIdSelectors = (deckId: string, currentId: number) =>
  createSelector(proposalDecksSelectors, (items) => {
    const deck = items.get(deckId);
    const slides = deck?.slides || [];
    const currentIndex = slides.indexOf(currentId);
    if (currentIndex >= 0 && slides[currentIndex + 1]) return slides[currentIndex + 1];
    return slides[0];
  });
