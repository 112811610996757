import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from '@premagic/utils';
import { PosterFileType, PosterType } from '../services/PosterService';
import {
  POSTER_WIDGET_TYPE,
  PosterWidgetTextType,
  PosterWidgetType,
  BADGE_PUNCH_HOLE_STYLE,
} from '../services/PosterWidgetService';
import PosterCreatorContainer from './PosterCreatorContainer';
import PosterWidget from './PosterWidget';
import { PosterMenu, PosterMenuItem } from '../design/PosterMenu';
import BadgePunchHole from '../widgets/BadgePunchHole';

type PosterCreatorPosterProps = {
  data: PosterType;
  files: Record<string, PosterFileType>;
  widgetOnClick: (widgetId?: string) => void;
  activeWidgetId?: string;
  fetchFonts?: (fonts: Array<string>) => void;
  badgePunchHoleStyle?: BADGE_PUNCH_HOLE_STYLE;
  editWidget: (widget: PosterWidgetType) => void;
  contextMenuPosition?: {
    top: string;
    left: string;
  };
  setShowContextMenu: (position?: { top: string; left: string }) => void;
  onDuplicateWidget: (widgetId: string) => void;
  onDeleteWidget: (widgetId: string) => void;
  showRuler?: boolean;
  options?: Record<string, string | Array<string>>;
};

export function PosterCreatorPoster(props: PosterCreatorPosterProps) {
  const {
    data,
    files,
    activeWidgetId,
    widgetOnClick,
    fetchFonts,
    badgePunchHoleStyle,
    editWidget,
    setShowContextMenu,
    contextMenuPosition,
    onDuplicateWidget,
    onDeleteWidget,
    showRuler,
    options,
  } = props;
  const { size, widgets } = data;
  const items = Object.entries(widgets) as Array<[id: string, item: PosterWidgetType]>;
  // The widgets don't get rerender if you wrap this on a useMemo
  const widgetsToRender = items
    .filter(([id, item]) => {
      if (item.is_hidden) return false;
      if ([POSTER_WIDGET_TYPE.TEXT, POSTER_WIDGET_TYPE.IMAGE].includes(item.type)) return item.value;
      return true;
    })
    .sort(([, item1], [, item2]) => {
      if (item1.position.z > item2.position.z) return 1;
      if (item1.position.z < item2.position.z) return -1;
      return 0;
    });

  useEffect(() => {
    if (fetchFonts) {
      // Load all the folder in widget of type text
      const textWidgets = Object.values(widgets).filter(
        (widget) => widget.type === POSTER_WIDGET_TYPE.TEXT,
      ) as Array<PosterWidgetTextType>;
      const fontsToLoad = textWidgets.reduce((acc, widget) => {
        if (widget.font_family && !acc.includes(widget.font_family)) acc.push(widget.font_family);
        return acc;
      }, [] as string[]);
      if (fontsToLoad.length) {
        fetchFonts(fontsToLoad);
      }
    }
  }, [fetchFonts, widgets]);

  const $wrapper = useRef(null) as RefObject<HTMLDivElement>;
  const [containerSize, setContainerSize] = useState({
    width: 100,
    height: 100,
  });

  useResizeObserver({
    ref: $wrapper,
    onResize: (newSize: { width: number; height: number }) =>
      setContainerSize({ width: newSize.width, height: newSize.height }),
  });
  if (!size) return <div>Size not set</div>;

  return (
    <div
      className="js-poster-wrapper"
      ref={$wrapper}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <PosterCreatorContainer
        containerSize={containerSize}
        config={{
          width: Number(size.width),
          height: Number(size.height),
        }}
        resetActiveWidget={() => {
          setShowContextMenu?.(undefined);
          widgetOnClick?.(undefined);
        }}
        showContextMenu={setShowContextMenu}
        showRuler={showRuler}
        options={options}
      >
        {widgetsToRender.map(([id, item]) => {
          const { type } = item;
          const isActive = activeWidgetId === id;
          return (
            <PosterWidget
              type={type}
              key={id}
              data={item}
              files={files}
              onClick={widgetOnClick}
              isActive={isActive}
              editWidget={editWidget}
              isEditable
            />
          );
        })}
        <BadgePunchHole
          style={badgePunchHoleStyle}
          posterSize={{
            width: Number(size.width),
            height: Number(size.height),
          }}
        />
      </PosterCreatorContainer>
      {contextMenuPosition && activeWidgetId && (
        <div
          id="menu"
          style={{
            ...contextMenuPosition,
            position: 'absolute',
            zIndex: 1000,
          }}
        >
          <PosterMenu onClose={() => setShowContextMenu?.(undefined)}>
            {onDuplicateWidget && (
              <PosterMenuItem
                onClick={() => {
                  setShowContextMenu?.(undefined);
                  onDuplicateWidget(activeWidgetId);
                }}
              >
                Duplicate
              </PosterMenuItem>
            )}
            {onDeleteWidget && (
              <PosterMenuItem
                onClick={() => {
                  setShowContextMenu?.(undefined);
                  onDeleteWidget(activeWidgetId);
                }}
              >
                Delete
              </PosterMenuItem>
            )}
          </PosterMenu>
        </div>
      )}
    </div>
  );
}
