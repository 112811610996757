import React from 'react';
import { InnerPageContentMainCol, InnerPageContentSidebar, Space } from '@premagic/myne';
import { FolderMetaService } from '@premagic/core';
import ClientWishesListContainer from './ClientWishesListContainer';
import AddClientWishPanelContainer from './create/AddClientWishPanelContainer';
import FolderMetaToggleContainer from '../../projects/folder-meta/FolderMetaToggleContainer';

type Props = {
  folderId: string;
  projectId: string;
};

export default function ClientWebsiteWishesLayout(props: Props): React.ReactElement {
  const { projectId, folderId } = props;

  return (
    <>
      <InnerPageContentMainCol>
        <ClientWishesListContainer folderId={folderId} projectId={projectId} />
        <AddClientWishPanelContainer folderId={folderId} projectId={projectId} />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <FolderMetaToggleContainer
          projectId={projectId}
          folderId={folderId}
          itemKey={FolderMetaService.FOLDER_META_TYPES.DISABLE_WISHES}
        />
        <Space vertical />
        <FolderMetaToggleContainer
          projectId={projectId}
          folderId={folderId}
          itemKey={FolderMetaService.FOLDER_META_TYPES.DIGITAL_INVITE_WISHES_REVIEW_REQUIRED}
        />
        <Space vertical />
      </InnerPageContentSidebar>
    </>
  );
}
