import { FolderService, ProjectNerdStatsService } from '@premagic/core';
import {
  COLOR_SHADES,
  ErrorBoundary,
  LoadingDots,
  Row,
  ScrollableContainer,
  Space,
  Text,
  TEXT_GROUP_SIZES,
  TEXT_SIZE,
  TextGroup,
  TextGroupLabel,
  Tooltip,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { ArrayUtils, SimpleDateUtils } from '@premagic/utils';

function StatsContent(props: {
  itemId: ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE;
  stats: Partial<ProjectNerdStatsService.ProjectNerdStatsType>;
  folders: Record<string, FolderService.FolderType>;
}) {
  const { itemId, stats, folders } = props;
  let list;
  let qualityStats;
  let deliveryPercentage = 0;
  switch (itemId) {
    case ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE.AI_DELIVERY:
      return (
        <ErrorBoundary>
          <Text muted>
            <Text color={COLOR_SHADES.DARKER}>{stats[itemId]?.assets_scanned}</Text> Files &{' '}
            <Text color={COLOR_SHADES.DARKER}>{stats[itemId]?.faces_scanned}</Text> Faces
          </Text>
        </ErrorBoundary>
      );
    case ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE.EVENT_REGISTRATION:
      deliveryPercentage = Math.ceil(
        ((stats[itemId]?.users_photo_delivered || 0) / (stats[itemId]?.event_registration_count || 1)) * 100,
      );
      return (
        <ErrorBoundary>
          <Text muted>
            <Text color={COLOR_SHADES.DARKER}>{stats[itemId]?.event_registration_count}</Text> Registrations &{' '}
            <Text color={COLOR_SHADES.DARKER}>{stats[itemId]?.users_photo_delivered}</Text> Photos delivered
            <br />
            <Text color={COLOR_SHADES.DARKER}>{deliveryPercentage}%</Text> Delivery percentage
            <Space vertical size={2} />
            <Text color={COLOR_SHADES.DARKER}>{stats[itemId]?.users_followup_notification_sent}</Text> Follow up send
          </Text>
        </ErrorBoundary>
      );
    case ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE.UPLOAD_TIMES:
      qualityStats = stats[ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE.QUALITY] || {};
      list = Object.entries(stats[itemId] || {})
        .filter(([, value]) => value)
        .map(([id, value], idx) => {
          const time = SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(value);
          const folderName = folders[id]?.folder_name;
          const tooltipMessage = `At ${SimpleDateUtils.humanizeDate(time, true)} with ${qualityStats[id] || 'none'}`;
          return <Tooltip message={tooltipMessage}>{folderName}</Tooltip>;
        });
      return (
        <ErrorBoundary>
          <Text>{ArrayUtils.intersperse(list, ', ')}</Text>
        </ErrorBoundary>
      );
    default:
      return <Text muted>Requires dev</Text>;
  }
}

type Props = {
  isLoading: boolean;
  stats?: Partial<ProjectNerdStatsService.ProjectNerdStatsType>;
  initData: (projectId: string) => void;
  projectId: string;
  folders: Record<string, FolderService.FolderType>;
};

export default function ProjectNerdStatsInfo(props: Props) {
  const { isLoading, stats, initData, projectId, folders } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  if (isLoading) {
    return (
      <Row center>
        <LoadingDots size="sm" />
      </Row>
    );
  }

  if (!stats || isEmpty(stats)) {
    return (
      <Text muted center block size={TEXT_SIZE.SIZE_5}>
        No project stats yet.
      </Text>
    );
  }

  return (
    <ScrollableContainer>
      {Object.entries(stats)
        .filter(
          ([key]) =>
            ![ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE.QUALITY].includes(
              key as ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE,
            ),
        )
        .map(([key, value]) => {
          const details = ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE_DETAIL[key];
          return (
            <TextGroup key={key} size="sm">
              <TextGroupLabel size={TEXT_GROUP_SIZES.SM}>{details?.title || key}</TextGroupLabel>
              <StatsContent
                stats={stats}
                itemId={key as ProjectNerdStatsService.PROJECT_NERD_STATS_TYPE}
                folders={folders}
              />
            </TextGroup>
          );
        })}
    </ScrollableContainer>
  );
}
