import React, { useEffect, useState } from 'react';
import { ProjectService } from '@premagic/core';
import { LocalCacheUtils, StringUtils } from '@premagic/utils';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Card,
  Col,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  Input,
  INPUT_SIZES,
  List,
  ListItem,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_BUTTON_STYLES,
  TEXT_SIZE,
  TextButton,
  Tooltip,
} from '@premagic/myne';
import AccountProjectItemContainer from './AccountProjectItemContainer';

type ProjectGroupProps = {
  name: string;
  projects: string[];
  archivedProjects: string[];
  expandArchived: boolean;
  toggleExpand: (boolean) => void;
  isExpanded: boolean;
  activeSearch: boolean;
};

function ProjectGroup(props: ProjectGroupProps) {
  const { name, projects, archivedProjects, expandArchived, isExpanded, toggleExpand, activeSearch } = props;
  const total = projects.length;
  const totalArchived = archivedProjects.length;
  const [showArchived, setShowArchived] = useState(false);
  function handleToggle() {
    if (!activeSearch) toggleExpand(!isExpanded);
  }

  return (
    <div>
      <Button onClick={handleToggle} style={BUTTON_STYLES.RESET} block>
        <Row vcenter>
          <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
            {name}
          </Text>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3}>{HTML_ICON.DOT}</Text>
          <Space size={1} />
          <Text size={TEXT_SIZE.SIZE_3}>
            {total} {StringUtils.pluralize('project', total)}
          </Text>
          {!activeSearch && (
            <Col size={null} rightAlighed>
              <Tooltip message={isExpanded ? 'Collapse' : 'Expand'}>
                <Space size={2}>
                  <Icon name={isExpanded ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
                </Space>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Button>
      {(activeSearch || isExpanded) && (
        <Card>
          <List>
            {projects.map((project) => (
              <ListItem key={project}>
                <AccountProjectItemContainer id={project} />
              </ListItem>
            ))}
            {totalArchived > 0 && !showArchived && !expandArchived && (
              <ListItem>
                <Button style={BUTTON_STYLES.LINK} onClick={() => setShowArchived(true)}>
                  {totalArchived} archived
                </Button>
              </ListItem>
            )}
            {(showArchived || expandArchived) &&
              archivedProjects.map((project) => (
                <ListItem key={project}>
                  <AccountProjectItemContainer id={project} />
                </ListItem>
              ))}
          </List>
        </Card>
      )}
      <Space vertical />
    </div>
  );
}
type Props = {
  initData: () => void;
  groupedProjects: {
    [date: string]: Record<string, ProjectService.ProjectType>;
  };
  groupedArchivedProjects: {
    [date: string]: Record<string, ProjectService.ProjectType>;
  };
  groupedUnArchivedProjects: {
    [date: string]: Record<string, ProjectService.ProjectType>;
  };
  isLoading: boolean;
  updateSearchTerm: (searchTerm: string) => void;
  searchTerm: string;
};
const CACHE_KEY = 'account-projects-page-collapsed-months';
export default function AccountProjectsPage(props: Props) {
  const {
    groupedProjects,
    isLoading,
    initData,
    updateSearchTerm,
    searchTerm,
    groupedArchivedProjects = {},
    groupedUnArchivedProjects,
  } = props;
  const groupedProjectsKeys = Object.keys(groupedProjects);
  const [collapsedMonths, setCollapsedMonths] = useState<Array<string>>(
    (LocalCacheUtils.getCache(CACHE_KEY) as Array<string>) || [],
  );
  const isExpandedAll = collapsedMonths.length === 0;

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(
    () => () => {
      LocalCacheUtils.setCache(CACHE_KEY, collapsedMonths);
    },
    [collapsedMonths],
  );

  function handleToggleExpandAll() {
    if (isExpandedAll) {
      setCollapsedMonths(groupedProjectsKeys);
    } else {
      setCollapsedMonths([]);
    }
  }

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="server"
        title="Projects"
        rightActions={
          <div>
            <TextButton onClick={handleToggleExpandAll} size={BUTTON_SIZE.SM} style={TEXT_BUTTON_STYLES.DEFAULT}>
              <Icon name={isExpandedAll ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />{' '}
              {isExpandedAll ? 'Collapse all' : 'Expand all'}
            </TextButton>
          </div>
        }
      >
        Projects
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <Space size={2} vertical />
        {(groupedProjectsKeys.length !== 0 || searchTerm) && (
          <Input
            name="search"
            buttonIcon={<Icon name="search" size={ICON_SIZES.MD} />}
            iconPlacement="left"
            autoFocus
            placeholder="Search Project"
            defaultValue={searchTerm}
            size={INPUT_SIZES.AUTO}
            onChange={(e) => updateSearchTerm(e.target.value)}
          />
        )}
        <Space size={4} vertical />
        {searchTerm && groupedProjectsKeys.length === 0 && (
          <Row center>
            <Space>
              <Text muted size={TEXT_SIZE.SIZE_2}>
                There are no project with name
              </Text>{' '}
              <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER}>
                {searchTerm}
              </Text>
            </Space>
          </Row>
        )}
        {!searchTerm && groupedProjectsKeys.length === 0 && (
          <Row center>
            <Space>
              <Text size={TEXT_SIZE.SIZE_2} block>
                You have not added any Project Yet.
              </Text>
              <Space vertical />
              <Text muted size={TEXT_SIZE.SIZE_3}>
                To Create a new project, Add a new event and confirm it as Project
              </Text>
            </Space>
          </Row>
        )}
        {groupedProjectsKeys.map((group) => {
          // We are using unarchived here to handle case when the user search for the project which is archived
          const projectKeysInGroup = groupedUnArchivedProjects[group]
            ? Object.keys(groupedUnArchivedProjects[group])
            : [];
          const archivedProjectKeysInGroup = groupedArchivedProjects[group]
            ? Object.keys(groupedArchivedProjects[group])
            : [];

          return (
            <ProjectGroup
              activeSearch={!!searchTerm}
              isExpanded={!collapsedMonths.includes(group)}
              toggleExpand={(isExpanded) =>
                setCollapsedMonths(
                  isExpanded ? collapsedMonths.filter((m) => m !== group) : [...collapsedMonths, group],
                )
              }
              key={group}
              name={group}
              projects={projectKeysInGroup}
              archivedProjects={archivedProjectKeysInGroup}
              expandArchived={!!searchTerm && archivedProjectKeysInGroup.length > 0}
            />
          );
        })}
      </InnerPageContent>
    </InnerPage>
  );
}
