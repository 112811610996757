import { connect } from 'react-redux';
import ClientWebsitePage from './ClientWebsitePage';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWebsitesSelector, getClientWebsite } from '../ClientWebsiteDataDuck';
import { projectsSelector } from '../../projects/AccountProjectsDataDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { getEventAttendeesForProject } from '../../crm/events/event-attendees/EventAttendeesDataDuck';
import { fetchCustomEventFromFields } from '../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps.match.params;
  const { pathname } = ownProps.location;

  const project = projectsSelector(state)[projectId] || {};
  const eventId = project.event_id;

  return {
    id,
    projectId,
    eventId,
    currentUrlPathname: pathname,
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.FETCH_SINGLE, true)(state),
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (id: string, projectId: string) => {
      dispatch(getEventAttendeesForProject(dispatch, projectId));
      dispatch(getClientWebsite(dispatch, id, projectId));
      dispatch(fetchCustomEventFromFields(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsitePage);
