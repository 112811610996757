import { EventSponsorsService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Col,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  Row,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useState } from 'react';
import AddEditSponsorCategoryFormContainer from '../add-edit-sponsor-category/AddEditSponsorCategoryFormContainer';

type Props = {
  sponsorCategory?: EventSponsorsService.EventSponsorCategoryType;
  handleSelectCategory: (sponsorCategoryId: string) => void;
  updateSponsorCategory: (sponsorCategoryId: string, formData) => void;
  active: boolean;
};

export default function SponsorCategoryListItem(props: Props) {
  const { sponsorCategory, handleSelectCategory, updateSponsorCategory, active } = props;
  const { name, tier } = sponsorCategory || {};
  const [showEditForm, setShowEditForm] = useState(false);

  function handleEditSponsorCategory(formResponse) {
    if (!sponsorCategory?.id) return;
    updateSponsorCategory(sponsorCategory?.id, formResponse);
    setShowEditForm(false);
  }

  function handleAddClick() {
    if (!sponsorCategory?.id) return;
    handleSelectCategory(sponsorCategory?.id);
  }

  if (showEditForm) {
    return (
      <AddEditSponsorCategoryFormContainer onSave={handleEditSponsorCategory} sponsorCategoryId={sponsorCategory?.id} />
    );
  }
  return (
    <Row>
      <ListItem style={LIST_ITEM_STYLES.NAV} onClick={handleAddClick} active={active}>
        {name}
        <Space size={2} />
        <Text size={TEXT_SIZE.SIZE_5} muted>{`[${tier}]`}</Text>
      </ListItem>
      <ListItemAction>
        <Button
          style={BUTTON_STYLES.LINK}
          onClick={(e) => {
            e.stopPropagation();
            setShowEditForm(true);
          }}
        >
          edit
        </Button>
      </ListItemAction>
    </Row>
  );
}
