import { connect } from 'react-redux';
import QRCodePosterSponsorSelector from './QRCodePosterSponsorSelector';
import { qrCodeDesignsSponsorSelector } from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import { setModalOptions, toggleModalVisibility } from '../../../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { folderId: string; projectId?: string }) {
  const { folderId, projectId } = ownProps;

  return { sponsorLogo: qrCodeDesignsSponsorSelector(folderId)(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    openSelectSponsorLogoModal: (logoId) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_SPONSOR_LOGO_MODAL, true));
      dispatch(
        setModalOptions(dispatch, LOADINGS.QR_CODE.SELECT_SPONSOR_LOGO_MODAL, {
          logoId,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterSponsorSelector);
