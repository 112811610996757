import { connect } from 'react-redux';

import ClientPaymentsInvoicePage from './ClientPaymentsInvoicePage';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { fetchPaymentsForEventData } from '../../CRMPaymentDataDuck';
import { hasPermission } from '../../../users/UsersDataDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { fetchEventDataWithDependencyData } from '../../../events/EventsDataDuck';
import { fetchOccasionsForEventData } from '../../../occassions/OccasionsDataDuck';
import { isWatermarkOnClientInvoiceShownSelector } from '../../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps) {
  const { eventId } = ownProps.match.params;

  return {
    eventId,
    isLoading:
      isLoadingSelector(LOADINGS.EVENT.FETCH_SINGLE)(state) || isLoadingSelector(LOADINGS.CRM_PAYMENTS.FETCH)(state),
    hasPermissions: {
      [PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS]: hasPermission(PERMISSIONS.EVENT_FINANCIAL_CLIENT_PAYMENTS)(state),
    },
    isWatermarkOnClientInvoiceShown: isWatermarkOnClientInvoiceShownSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (eventId) => {
      dispatch(fetchEventDataWithDependencyData(dispatch, eventId));
      dispatch(fetchPaymentsForEventData(dispatch, eventId));
      dispatch(fetchOccasionsForEventData(dispatch, eventId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPaymentsInvoicePage);
