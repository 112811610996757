import { EnvUtils, Config, AppUtils } from '@premagic/utils';
import Mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import { handleTrackingPixelEvents } from './PixelService';

export const TRACK_EVENTS = {
  PAGE_VIEW: 'page.view',
  HOME: {
    VISIT: 'home.visit',
  },
  DEMO: {
    SCHEDULE: 'demo.schedule.click',
  },
  PROJECT: {
    CREATE: 'project.create',
    UPDATE: 'project.update',
    VIEW_ALL_SELECTED_PHOTOS: 'project.view-all-selected-photos',
    CONVERT_TO_PROJECT: 'project.convert-from-event',
    TOGGLE_OLD_PROJECT_VIEW: 'project.toggle-old-view',
    SETTING_TOGGLE: (key) => `project.setting.toggle.${key}`,
    SETTING_SET_PROJECT_COVER: 'project.setting.set-project-cover',
    SHOW_IN_PORTFOLIO: 'project.show-in-portfolio',
    PRINT_SELECTED_PHOTOS: 'project.selected-photos.print',
    EXPORT_SELECTED_PHOTOS: 'project.selected-photos.export',
    COPY_HIGHLIGHT_TO_SELECTION: 'project.copy-highlight-to-selection',
    AI_DELIVERY_SHOW_CONFIRMATION: 'project.ai-delivery.show-confirmation',
    AI_DELIVERY_ENABLE: 'project.ai-delivery.enable',
  },
  FOLDER: {
    COPY_SELECTED_PHOTO_NAME: 'folder.copy-selected-photos-name',
    COPY_SELECTED_PHOTO_NAME_FROM_ALL_FOLDERS: 'folder.copy-selected-photos-name-from-all-folders',
    COPY_IMPORTANT_PHOTO_NAME: 'folder.copy-important-photos-name',
    DELETE: 'folder.delete',
    BLOCK: 'folder.block',
    RENAME: 'folder.rename',
    SHARE: 'folder.share',
    CREATE: 'folder.create',
    UPLOAD: 'folder.upload',
    PREVIEW: 'folder.preview',
    MAKE_IT_PUBLIC: 'folder.make-it-public',
    SETTING_TOGGLE: (key) => `folder.setting.toggle.${key}`,
  },
  ALBUM_CREATOR: {
    CREATE: 'album-creator.create',
    SEND_TO_CLIENT: 'album-creator.send-to-client',
    DELETE: 'album-creator.delete',
  },
  FILE: {
    DELETE: 'file.delete',
    DELETE_BULK: 'file.delete-bulk',
    DOWNLOAD: 'file.download',
  },
  CLIENT_WEBSITE: {
    CREATE_BASIC_INVITE: 'client-website.create.basic-invite',
    CREATE_NEW: 'client-website.create',
    SHARE: 'client-website.share',
    ADD_ITINERARY: 'client-website.add-itinerary',
    QR_CODE_DESIGN: 'client-website.qr-code-designs',
    I_AM_ATTENDING_POSTER_DOWNLOAD: 'client-website.i-am-attending-poster-download',
    I_AM_ATTENDING_POSTER_SHARE: 'client-website.i-am-attending-poster-share',
  },
  FOLDER_ACTIONS: {
    ALLOW_SELECTION: 'folder-action.allow-selection.allow',
    DISMISS_SELECTION: 'folder-action.allow-selection.dismiss',
  },
  LEAD: {
    UPDATE_STATUS: 'lead.update-status',
  },
  SETTING: {
    UPDATE_FOLDER_SETTINGS: 'settings.folder-setting.update',
    UPDATE: 'settings.update',
    UPDATE_NOTIFICATIONS: 'settings.notification.update',
  },
  PAYMENT: {
    VIEW: 'payments.view',
    CLICK_BUY: 'payments.click.buy',
    DONE: 'payments.done',
    SIMPLE_INVOICE_CLICK: 'payments.click.simple-invoice',
    SEND_REMINDER: 'payments.reminder.send-to-client',
  },
  CRM: {
    VIEW: 'crm.view',
    EVENT_CREATE: 'crm.event.create',
    EVENT_UPDATE: 'crm.event.update',
    EVENT_DELETE: 'crm.event.delete',
    EVENT_UPDATE_STATUS: 'crm.event.status',
    EVENT_UPDATE_PRIORITY: 'crm.event.priority',
    ADD_EVENT_LABEL: 'crm.event.add-label',
    REMOVE_EVENT_LABEL: 'crm.event.remove-label',
  },
  CRM_LABELS: {
    CREATE: 'crm.label.create',
    DELETE: 'crm.label.delete',
  },

  CRM_OCCASION: {
    CREATE: 'crm.occasion.create',
    UPDATE: 'crm.occasion.update',
    DELETE: 'crm.occasion.delete',
    DUPLICATE: 'crm.occasion.duplicate',
  },
  CRM_PAYMENTS: {
    CREATE_BULK_ACTION: 'crm.payments.create-bulk-action',
    CREATE: 'crm.payments.create',
    UPDATE: 'crm.payments.update',
    DELETE: 'crm.payments.delete',
  },
  ACCOUNT_FINANCIALS: {
    VISIT: 'account_financials.visit',
  },
  SUBSCRIPTIONS: {
    EXPIRED_VIEW: 'subscriptions.view.expired',
    EXPIRED_PAYMENT_CLICK: 'subscriptions.expired.payment-click',
  },
  TOUR: {
    OPEN: 'crm.tour.open',
    SKIP: 'crm.tour.cancel',
    FINISHED: 'crm.tour.finished',
    CHANGE: 'crm.tour.change',
    TOUR_WELCOME_SCREEN: 'crm.tour.welcome-screen',
  },
  PROPOSAL_APP: {
    PAGE_VIEW: 'proposal.page-view',
    REVIEW: 'proposal.review',
  },
  PROPOSAL: {
    CREATE: 'proposal.create',
    CREATE_VERSION: 'proposal.version.create',
    SHOW_HISTORY: 'proposal.version.history.show',
    DELETE: 'proposal.delete',
    SHARE: 'proposal.share',
    DUPLICATE: 'proposal.duplicate',
    UPDATE: 'proposal.update',
  },
  PEOPLE_FACES: {
    FACES_PAGE_VIEW: 'people-faces.page-view',
    FACE_DETAILS_PAGE_VIEW: 'people-faces.details-page-view',
    SCAN_FACE: 'people-faces.scan-face',
    FIND_MY_PHOTOS: 'people-faces.find-my-photos',
  },
  COMPANY: {
    SEND_ENQUIRY: 'company.send.enquiry',
    ENQUIRE_VISIT: 'company.enquire.visit',
    REACH_OUT: 'company.reach-out',
  },
  STORE: {
    VIEW: 'store.view',
    VIEW_ITEM: 'store.item.view',
    CTA_CLICK: 'store.item.cta-click',
  },
  HIGHLIGHT_DESIGN: {
    GALLERY_PRESET: 'highlight-design.gallery-preset',
  },
  SPONSOR: {
    CREATE: 'sponsor.create',
    CREATIVE_CREATE: 'sponsor.creative.create',
    CREATIVE_VIEW: 'sponsor.creative.view',
    CREATIVE_CLICK: 'sponsor.creative.click',
    CREATIVE_CTA_CLICK: 'sponsor.creative.cta-click',
    VIEW_INSIGHTS: 'sponsor.insights.view',
    CATEGORY_CREATE: 'sponsor.category.create',
    CATEGORY_EDIT: 'sponsor.category.edit',
  },
  EVENT_ATTENDEES: {
    CREATE: 'event-attendees.create',
    VIEW_INSIGHTS: 'event-attendees.insights.view',
    DOWNLOAD_BADGES: 'event-attendees.download.badges',
  },
  EVENT_REPORT: {
    VIEW: 'event-report.view',
  },
  EVENT_MARKETING: {
    VIEW: 'event-marketing.view',
    CREATE_POSTERS: 'event-marketing.create.posters',
  },
  USER_REGISTRATION_FORM: {
    CREATE: 'user-registration-form.create',
  },
  USER_REGISTRATION: {
    REGISTER: 'user-registration.register',
    PHOTO_REGISTER: 'photo-registration.register',
  },
  EXTERNAL_UPLOADER: {
    UPLOAD: 'external-uploader.upload',
  },
  EVENT_POSTERS: {
    CREATE: 'event-poster.create',
    UPDATE: 'event-poster.update',
    DELETE: 'event-poster.delete',
  },
  CHECKIN: {
    VIEW: 'checkin.view',
    CHECKIN: 'checkin.checkin',
  },
  PEOPLE_NETWORKING: {
    INVITE_PEOPLE: 'people-networking.invite-people',
    FETCH_FACES_FOR_IMAGE: 'people-networking.fetch-faces-for-image',
    FETCH_USER_DETAILS: 'people-networking.fetch-user-details',
    USER_OPT_IN_STATUS: 'people-networking.user-opt-in-status',
  },
  WIDGET: {
    PHOTO_GALLERY: {
      CLICK_PREVIEW: 'widget.photo-gallery.click-preview',
      COPY_WIDGET_CODE: 'widget.photo-gallery.copy-widget-code',
    },
  },
  ATTENDEE_MEMORY_TEMPLATES: {
    CREATE: 'attendee-memory-templates.create',
  },
  ATTENDEE_MEMORY: {
    CREATE: 'attendee-memory.create',
  },
  AVATAR_STUDIO: {
    START: 'avatar-studio.start',
    SELECT_TEMPLATE: 'avatar-studio.select-template',
    CREATE: 'avatar-studio.create',
  },
};

function hasMixpanel() {
  return EnvUtils.getEnvValues().NX_PUBLIC_ENV === 'production';
}

export function initAnalytics(appName: AppUtils.APPS): void {
  const gtmKey = Config.THIRD_PARTY_APPLICATIONS.TAGMANAGER[appName] || 'dummy';
  TagManager.initialize({ gtmId: gtmKey });

  if (!hasMixpanel()) return;

  const key = Config.THIRD_PARTY_APPLICATIONS.MIXPANEL[appName] || 'dummy';
  Mixpanel.init(key, {
    // https://developer.mixpanel.com/docs/collection-via-a-proxy#2-configure-the-mixpanel-js-client-to-make-requests-to-your-proxy-domain
    api_host: 'https://mp.premagic.com',
  });
}

let USER: Record<string, unknown>;

// "Generally, adopting snake_case for your event and property names tend to be more robust" -mixpanel doc
export function trackEvent(name: string, data?: Record<string, unknown>): void {
  if (hasMixpanel()) {
    Mixpanel.track(name, data);
  }

  if (USER && USER.projectId && USER.folderId)
    handleTrackingPixelEvents(name, { ...(USER as { projectId: string; folderId: string }), ...data });
}

export function setUserInfo(name: string, data: Record<string, unknown>): void {
  USER = {
    name,
    ...data,
  };

  if (hasMixpanel()) {
    Mixpanel.identify(name);
    Mixpanel.people.set(data);
    Mixpanel.register(data);
  }
}

export function updateUserInfo(id: string, data: Record<string, unknown>): void {
  if (hasMixpanel()) {
    Mixpanel.identify(id);
    Mixpanel.people.set(data);
  }
}

export function setCompanyDetails(options: {
  id: string;
  name: string;
  company_type;
  plan;
  plan_projects_used;
  plan_projects;
}): void {
  const { id, ...rest } = options;
  if (hasMixpanel()) {
    Mixpanel.add_group('company', id);
    Mixpanel.get_group('company', id).set(rest);
  }
}
