import { connect } from 'react-redux';
import QRCodeLEDScreenCustomPosterSelectModal from './QRCodeLEDScreenCustomPosterSelectModal';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';

const KEY = LOADINGS.QR_CODE.SELECT_LED_CUSTOM_POSTER_MODAL;

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  return { show: isModalOpenSelector(KEY)(state), projectId, folderId };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(toggleModalVisibility(dispatch, KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeLEDScreenCustomPosterSelectModal);
