import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import {
  qrCodeDesignsBackgroundPosterDataSelector,
  qrCodeDesignsSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { clientWebsitesSelector } from '../../../ClientWebsiteDataDuck';
import QRCodeBackgroundPosterSelector from './QRCodeBackgroundPosterSelector';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;

  const clientWebsite = clientWebsitesSelector(state)[folderId];
  const defaultPosterColorsFromInvite = {
    [QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_1]: clientWebsite?.theme?.color?.primary_color,
    [QRCodeBackgroundPosterService.POSTER_COLOR_ID.PRIMARY_COLOR_2]: clientWebsite?.theme?.color?.secondary_color,
  };

  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);

  return {
    backgroundPoster: qrCodeDesignsBackgroundPosterDataSelector(folderId)(state),
    colors: qrCodeDesigns?.colors || defaultPosterColorsFromInvite,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openPosterSelectModal: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_POSTER_MODAL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeBackgroundPosterSelector);
