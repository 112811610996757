import { ProposalVariablesDataDuck, ProposalVariableService } from '@premagic/proposals';
import { connect } from 'react-redux';
import ProposalVariableForm from './ProposalVariableForm';

function mapStateToProps(
  state,
  ownProps: { id?: string; scope: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE; disableNameInput?: boolean },
) {
  const { id, scope, disableNameInput } = ownProps;

  return {
    variable: id ? ProposalVariablesDataDuck.proposalsVariableItemsSelectors(state)[id] : null,
    scope,
    disableNameInput,
  };
}

export default connect(mapStateToProps)(ProposalVariableForm);
