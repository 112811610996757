import { PosterType } from '@premagic/poster-creator';
import { BrowserUrlUtils } from '@premagic/utils';
import { ClientWebsiteItinerary } from './ClientWebsiteService';
import { EventAttendeeType } from './EventAttendeesService';
import { SYSTEM_CUSTOM_FORM_FIELDS } from './EventCustomFromFieldsService';
import {
  EVENT_POSTER_SYSTEM_WIDGETS,
  EventPosterType,
  getPosterPreviewUrl,
  isSystemWidget,
} from './EventPosterService';
import { FolderType } from './FolderService';
import { getPeopleFaceUrl } from './PeopleFacesService';

export type UserInfoDataType = {
  id?: string;
  userName?: string;
  userTitle?: string;
  userCompany?: string;
  userPhoto?: string;
  advocateId?: string;
  faceId?: string;
};

export type SessionInfoDataType = {
  [EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE]: string;
  sessionId?: number;
};

export type ExhibitorInfoDataType = {
  [EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_LOGO]: string;
  [EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_BOOTH_NUMBER]: string;
  exhibitorId?: string;
};
export type CustomInfoDataType = Record<string, any>;

export function getPosterWithData(
  posterData: PosterType,
  options: {
    user?: UserInfoDataType;
    guestPhotosForWidgets?: Record<string, string | Array<string>>;
    session?: SessionInfoDataType;
    exhibitor?: ExhibitorInfoDataType;
    custom?: Record<string, any>;
  },
): PosterType {
  const widgets = { ...posterData.widgets };
  const { user, guestPhotosForWidgets, session, exhibitor, custom } = options;
  const { userName, userTitle, userCompany, userPhoto, id: userId } = user || {};
  const widgetDataNameValueMap = {
    // Note this is the name of the widget
    // user
    [EVENT_POSTER_SYSTEM_WIDGETS.AVATAR]: userPhoto,
    [EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME]: userName,
    [EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE]: userTitle,
    [EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY]: userCompany,
    [EVENT_POSTER_SYSTEM_WIDGETS.DISPLAY_ID]: userId,
    ...session,
    ...exhibitor,
    ...custom,
  };

  const widgetsWithData = Object.keys(widgets).reduce((acc, widgetId) => {
    // We need to update using the name, instead of the id
    let widget = acc[widgetId];
    if (widget && widgetDataNameValueMap[widget.name]) {
      // If data is available on the map then update the widget with value
      widget = {
        ...widget,
        value: widgetDataNameValueMap[widget.name],
      };
    }
    // Add photos to guest photo widget
    if (widget && guestPhotosForWidgets?.[widgetId]) {
      widget = {
        ...widget,
        value: guestPhotosForWidgets[widgetId] as any, // Hack for now
      };
    }
    // Add is_system_widget flag to prevent user editing the values
    widget = Object.assign({}, widget, {
      is_system_widget: isSystemWidget(widget),
      selectable: widget.name !== EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
    });

    return Object.assign({}, acc, { [widgetId]: widget });
  }, widgets);

  return Object.assign({}, posterData, {
    widgets: widgetsWithData,
  });
}

export function getPosterUserDataFromAttendee(attendee?: EventAttendeeType): UserInfoDataType {
  if (!attendee)
    return {
      userName: 'Your name',
      userCompany: 'Your company',
      userTitle: 'Your role',
      id: 'X-1',
    };
  const { name: userName, custom_fields: customFields, best_face_id: bestFaceId, face_id: faceId } = attendee || {};

  const {
    [SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID]: displayId,
    [SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY]: userCompany,
    [SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE]: userRole,
    [SYSTEM_CUSTOM_FORM_FIELDS.FACE]: faceAsset,
  } = customFields || {};

  const userAvatarUrl = faceAsset?.dynamic_image_url || (bestFaceId ? getPeopleFaceUrl(bestFaceId) : undefined);
  return {
    userName,
    userCompany,
    userTitle: userRole,
    id: displayId,
    userPhoto: userAvatarUrl,
    faceId: bestFaceId || faceId,
  };
}
export function getPosterSessionDataFromSession(session?: ClientWebsiteItinerary): SessionInfoDataType {
  if (!session) {
    return {
      [EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE]: 'Session Title',
      sessionId: 1,
    };
  }
  return {
    [EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE]: session.name,
    sessionId: session.id,
  };
}

export function getPosterUrlForAttendee(data: {
  attendee: EventAttendeeType;
  session?: ClientWebsiteItinerary;
  posters: Array<EventPosterType>;
  clientWebsite: FolderType;
}): string | undefined {
  const { attendee, posters, clientWebsite, session } = data;
  const attendeeType = attendee?.people_type;
  const poster = posters.find((item) => item.scope.includes(attendeeType));
  if (!poster) return undefined;
  const userData = getPosterUserDataFromAttendee(attendee);
  const sessionData = getPosterSessionDataFromSession(session);
  const linkToPoster = getPosterPreviewUrl({
    poster,
    clientWebsite,
  });
  const params = BrowserUrlUtils.getQueryStringFromObject({
    ...userData,
    ...sessionData,
  });
  return `${linkToPoster}?${params}`;
}
