import Konva from 'konva';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Group as CanvasGroup, Line as CanvasLine, Transformer as CanvasTransformer } from 'react-konva';
import { PosterFileType } from '../services/PosterService';
import { POSTER_WIDGET_TYPE, PosterWidgetTextType, PosterWidgetType } from '../services/PosterWidgetService';
import { SELECTOR_KEYS } from '../services/SelectorService';
import {
  DrawLinesType,
  getClosestSnapLines,
  getDrawLines,
  getShapeSnappingEdges,
  getSnapLines,
} from '../services/WidgetSnapingService';
import PosterContext from './PosterContext';
import { PosterBox, PosterCircle, PosterText } from '../widgets/BaseWidgets';
import PosterAvatar from '../widgets/PosterAvatar';
import PosterGuestImage from '../widgets/PosterGuestImage';
import PosterGuestImageGallery from '../widgets/PosterGuestImageGallery';
import PosterGuestImagePortrait from '../widgets/PosterGuestImagePortrait';
import PosterImage from '../widgets/PosterImage';
import PosterQRCode from '../widgets/PosterQRCode';
import KonvaEventObject = Konva.KonvaEventObject;

const WIDGET_TYPE_COMPONENT_MAP: Record<POSTER_WIDGET_TYPE, any> = {
  [POSTER_WIDGET_TYPE.AVATAR]: PosterAvatar,
  [POSTER_WIDGET_TYPE.GUEST_IMAGE]: PosterGuestImage,
  [POSTER_WIDGET_TYPE.GUEST_IMAGE_GALLERY]: PosterGuestImageGallery,
  [POSTER_WIDGET_TYPE.GUEST_IMAGE_PORTRAIT]: PosterGuestImagePortrait,
  [POSTER_WIDGET_TYPE.IMAGE]: PosterImage,
  [POSTER_WIDGET_TYPE.TEXT]: PosterText,
  [POSTER_WIDGET_TYPE.CIRCLE]: PosterCircle,
  [POSTER_WIDGET_TYPE.BOX]: PosterBox,
  [POSTER_WIDGET_TYPE.QR_CODE]: PosterQRCode,
};

export function getComponentForWidgetType(type: POSTER_WIDGET_TYPE) {
  if (type in WIDGET_TYPE_COMPONENT_MAP) return WIDGET_TYPE_COMPONENT_MAP[type];
  return PosterText;
}

const GRID_SIZE = 5; // Snap to 5px grid
type Props = {
  type: POSTER_WIDGET_TYPE;
  data: PosterWidgetType;
  files: Record<string, PosterFileType>;
  isEditable?: boolean;
  onClick?: (widgetId: string) => void;
  isActive?: boolean;
  editWidget?: (widget: PosterWidgetType) => void;
};

export default function PosterWidget(props: Props) {
  const { type, data, files, isEditable, onClick, isActive, editWidget } = props;
  const widget = data;
  const { id, name, is_system_widget: isSystemWidget, selectable } = data as PosterWidgetTextType;
  const $item = useRef<any>(null);
  const trRef = useRef<any>(); // I dont know how to get the ref, will need to fix it later
  const [hLines, setHLines] = useState<Array<DrawLinesType>>([]);
  const [vLines, setVLines] = useState<Array<DrawLinesType>>([]);

  const [isHovering, setIsHovering] = useState(isActive);
  const { setCursor } = useContext(PosterContext);

  useEffect(() => {
    if (trRef.current && $item.current) {
      // we need to attach transformer manually
      trRef.current.nodes([$item.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [trRef.current]);

  const onUpdateOfWidget = useMemo(() => {
    if (!editWidget) return;

    return function onChangeHandler(options: {
      position?: { x: number; y: number; rotation?: number };
      size?: {
        width: number;
        height?: number;
      };
    }) {
      // We dont want user to add values to system widgets
      let updatedWidget = isSystemWidget
        ? Object.assign({}, widget, {
            value: undefined, // otherwise it will update the value, eg, avatar image and text in the creator
          })
        : widget;
      if (options.position) {
        updatedWidget = Object.assign({}, updatedWidget, {
          position: Object.assign({}, widget.position, options.position),
        });
      }
      if (options.size) {
        updatedWidget = Object.assign({}, updatedWidget, {
          size: Object.assign({}, updatedWidget.size, options.size),
        });
      }
      editWidget?.(updatedWidget);
    };
  }, [editWidget, widget]);

  function handleDragStart() {
    if (isEditable) {
      setCursor('move');
    }
  }
  function getCurrentNodeSize(e: KonvaEventObject<Event>): {
    width: number;
    height: number;
  } {
    const node = $item.current;
    const width = node.width();
    const height = node.height();
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    // Groups won't have width and height
    // https://github.com/konvajs/konva/issues/2#issuecomment-74509553 | but doesnt seems to work
    // if (node.nodeType === 'Group') {
    //   const clientRect = node.getClientRect();
    //   width = clientRect.width;
    //   height = clientRect.height;
    // }
    return {
      width: Math.max(20, Math.round((width * scaleX) / GRID_SIZE) * GRID_SIZE),
      height: Math.max(20, Math.round((height * scaleY) / GRID_SIZE) * GRID_SIZE),
    };
  }

  function getPosition(e: KonvaEventObject<Event>): {
    x: number;
    y: number;
    rotation: number;
  } {
    const node = $item.current;
    const x = Math.round(node.x() / GRID_SIZE) * GRID_SIZE;
    const y = Math.round(node.y() / GRID_SIZE) * GRID_SIZE;
    const rotation = node.rotation();
    // move to rounded position
    node.to({
      x,
      y,
    });
    return {
      x,
      y,
      rotation,
    };
  }

  function handleDragEnd(e: KonvaEventObject<Event>) {
    onUpdateOfWidget?.({
      position: getPosition(e),
    });
    setCursor('pointer');
    setHLines([]);
    setVLines([]);
    if (onClick) onClick(id);
  }

  function onTransformEnd(e: KonvaEventObject<Event>) {
    onUpdateOfWidget?.({
      position: getPosition(e),
      size: getCurrentNodeSize(e),
    });
    // we will reset it back
    const node = $item.current;
    node.scaleX(1);
    node.scaleY(1);
  }

  // Drag move is mainly for showing the guides
  function handleOnDragMove(e: KonvaEventObject<Event>) {
    const target = trRef.current;
    const [selectedNode] = target.getNodes();

    if (!selectedNode) return;

    const possibleSnappingLines = getSnapLines(target, selectedNode);
    const selectedShapeSnappingEdges = getShapeSnappingEdges(target, selectedNode);

    const closestSnapLines = getClosestSnapLines(possibleSnappingLines, selectedShapeSnappingEdges);

    // Do nothing if no snapping lines
    if (closestSnapLines.length === 0) {
      setHLines([]);
      setVLines([]);

      return;
    }

    // draw the lines
    const linest = getDrawLines(closestSnapLines);
    setHLines(linest.H);
    setVLines(linest.V);

    const orgAbsPos = target.absolutePosition();
    const absPos = target.absolutePosition();

    // Find new position
    closestSnapLines.forEach((l) => {
      const position = l.snapLine + l.offset;
      if (l.orientation === 'V') {
        absPos.x = position;
      } else if (l.orientation === 'H') {
        absPos.y = position;
      }
    });

    // calculate the difference between original and new position
    const vecDiff = {
      x: orgAbsPos.x - absPos.x,
      y: orgAbsPos.y - absPos.y,
    };

    // apply the difference to the selected shape.
    const nodeAbsPos = selectedNode.getAbsolutePosition();
    const newPos = {
      x: nodeAbsPos.x - vecDiff.x,
      y: nodeAbsPos.y - vecDiff.y,
    };

    selectedNode.setAbsolutePosition(newPos);
  }

  const Component = getComponentForWidgetType(type);

  if (selectable && isEditable) {
    return (
      <CanvasGroup
        onMouseEnter={() => {
          setIsHovering(true);
          setCursor('pointer');
          if (isActive) setCursor('move');
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          setCursor('default');
        }}
        onClick={() => {
          if (onClick) onClick(id);
        }}
        name={widget.name}
      >
        <CanvasTransformer
          visible={isHovering || isActive}
          ref={trRef}
          flipEnabled={false}
          borderStroke={isActive ? '#2E98DE' : '#0C8CE9'}
          anchorSize={12}
          borderStrokeWidth={2}
          anchorCornerRadius={5}
          anchorStroke="#fff"
          anchorFill="#2E98DE"
          rotateEnabled
          rotationSnaps={[
            0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300, 315, 330,
            345,
          ]}
          // rotateAnchorCursor="rotate"
          anchorStrokeWidth={2}
          centeredScaling={false}
          enabledAnchors={
            isActive
              ? [
                  'top-left',
                  'top-right',
                  'bottom-left',
                  'bottom-right',
                  'top-center',
                  'bottom-center',
                  'middle-right',
                  'middle-left',
                ]
              : []
          }
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 12 || Math.abs(newBox.height) < 12) {
              return oldBox;
            }
            return newBox;
          }}
          onTransformEnd={onTransformEnd}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragMove={handleOnDragMove}
        />
        <Component
          ref={$item}
          // @ts-ignore
          data={data}
          files={files}
          draggable={isEditable}
          isEditable={isEditable}
          name={SELECTOR_KEYS.SHAPE} // For getSnapLines
        />
        {hLines.map((item) => (
          <CanvasLine key={`${item.x}-${item.y}`} {...item} />
        ))}
        {vLines.map((item, i) => (
          <CanvasLine key={`${item.x}-${item.y}`} {...item} />
        ))}
      </CanvasGroup>
    );
  }

  return (
    <Component
      // @ts-ignore
      data={data}
      files={files}
    />
  );
}
