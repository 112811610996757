import { connect } from 'react-redux';
import { FolderService, ProjectMetaService } from '@premagic/core';
import { folderIdsWithFolderTypeForProjectSelector, projectsSelector } from '../../AccountProjectsDataDuck';
import HighlightFoldersPageCoverSettingsLayout from './HighlightFoldersPageCoverSettingsLayout';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    match: {
      params: {
        projectId: string;
      };
    };
  },
) {
  const { projectId } = ownProps.match.params;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.HIGHLIGHT,
  )(state);

  const projectMetaData = projectMetaDataForProjectSelector(projectId)(state);

  return {
    projectId,
    project: projectsSelector(state)[projectId],
    folderIds: signatureFolderIds,
    coverPhoto: projectMetaData?.cover_image,
    coverLayout: projectMetaData?.[ProjectMetaService.PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightFoldersPageCoverSettingsLayout);
