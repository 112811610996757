import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { commonUISelector } from '../selectors';
import { ACTION_KEYS } from './Constants';

const getActionType = ActionTypeUtils.getActionTypeFunction('TOAST', true);

export const toggleToastVisibility = createAction(
  getActionType('TYPE', 'SHOW'),
  (dispatch: Dispatch, type: string, show: boolean) => ({
    [type]: show,
  }),
);

export const setToastOptions = createAction(
  getActionType('OPTIONS', 'SET'),
  (dispatch: Dispatch, type: string, options: any) => ({
    [type]: options,
  }),
);

export const showToast = createAction(getActionType('SHOW_TOAST', 'SET'), (dispatch, message, timer) => {
  dispatch(setToastOptions(dispatch, ACTION_KEYS.TOAST, { message }));
  dispatch(toggleToastVisibility(dispatch, ACTION_KEYS.TOAST, true));
  setTimeout(() => {
    dispatch(toggleToastVisibility(dispatch, ACTION_KEYS.TOAST, false));
    dispatch(setToastOptions(dispatch, ACTION_KEYS.TOAST, {}));
  }, timer);
});

const initialState = {
  items: IMap({}),
  itemsOptions: IMap({}),
};

export const ToastReducer = handleActions(
  {
    [toggleToastVisibility.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [setToastOptions.toString()]: (state, action) => ({
      ...state,
      itemsOptions: state.itemsOptions.merge(action.payload),
    }),
  },
  initialState,
);

export const toastDataSelector = createSelector(
  commonUISelector,
  (state) => state.toast as { items: IMap<string, boolean>; itemsOptions: IMap<string, unknown> },
);

const toastSelector = createSelector(toastDataSelector, (state) => state.items);
const toastsOptionsSelector = createSelector(toastDataSelector, (state) => state.itemsOptions);

export const isToastOpenSelector = (type: string) => createSelector(toastSelector, (toast) => !!toast.get(type));

export const toastOptionsSelector = <T>(type: string) =>
  createSelector(toastsOptionsSelector, (toast) => toast.get(type) as T);
