import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';
import SharePanel from './SharePanel';
import { LOADINGS } from '../../../../common/Constants';
import { shareFoldersWithClientBasedOnFolderType, folderIdsToShareSelector } from './FolderShareDuck';
import { clientIdForProjectSelector, clientForProjectFolderSelector } from '../projects/AccountProjectsDataDuck';
import { clientItemsSelector } from '../crm/clients/ClientsDataDuck';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../common/ErrorDuck';
import { foldersEntitiesSelector } from '../projects/folders/AccountFoldersDataDuck';
import { clientIdForEventSelector } from '../crm/events/EventsDataDuck';

const PANEL_KEY = LOADINGS.FOLDER_SHARE.SHARE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId?: string;
  },
) {
  const { projectId, eventId } = ownProps;
  const clientId = eventId ? clientIdForEventSelector(eventId)(state) : clientIdForProjectSelector(projectId)(state);
  const folderIdsToShare = folderIdsToShareSelector(state);
  const folders = foldersEntitiesSelector(state);

  return {
    projectId,
    folderIdsToShare,
    clientId,
    clients: clientForProjectFolderSelector(projectId, folderIdsToShare)(state),
    eventClient: clientId ? clientItemsSelector(state)[clientId] : undefined,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(PANEL_KEY)(state),
    errors: errorObjectForTypeSelector(PANEL_KEY)(state),
    foldersToShare: folderIdsToShare ? folderIdsToShare.map((folderId) => folders[folderId]) : [],
    folders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    shareFoldersWithClient: (options, formResponse) => {
      dispatch(shareFoldersWithClientBasedOnFolderType(dispatch, options, formResponse));
    },
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharePanel);
