import { FormGroup, FormLabel, INPUT_SIZES, Select } from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import { useState } from 'react';

const expiryOptions: Array<{
  label: string;
  value: number;
}> = [
  {
    label: '5 days',
    value: 5,
  },

  {
    label: '2 weeks',
    value: 14,
  },
  {
    label: '1 Month',
    value: 30,
  },
  {
    label: '2 Month',
    value: 60,
  },
  {
    label: '3 Month',
    value: 90,
  },
  {
    label: '6 Month',
    value: 180,
  },
  {
    label: '1 Year',
    value: 365,
  },
  {
    label: '2 Year',
    value: 730,
  },
];

type Props = {
  defaultExpiryInDays?: number;
};

export default function FolderExpirySelector(props: Props) {
  const { defaultExpiryInDays = 30 } = props;
  const defaultOption = expiryOptions.find((option) => option.value === defaultExpiryInDays);
  const [expiryInDays, setExpiryInDays] = useState(defaultExpiryInDays);
  const dateString = SimpleDateUtils.getDateStringFromDate(
    SimpleDateUtils.addToDate(SimpleDateUtils.now(), 'days', expiryInDays),
    SimpleDateUtils.STANDARD_DATE_FORMATS.ISO,
  );

  return (
    <FormGroup>
      <FormLabel>Expiry</FormLabel>
      <Select
        size={INPUT_SIZES.SM}
        options={expiryOptions}
        name=""
        onChange={(option) => setExpiryInDays(Number(option?.value))}
        value={defaultOption}
        isMulti={false}
      />
      <input type="hidden" value={dateString} name="expiry_date" />
    </FormGroup>
  );
}
