import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  Space,
} from '@premagic/myne';
import { ClientWebsiteService, EventsCategoryService, EventsService, ThemeService } from '@premagic/core';

import APP_URLS, { getRouteUrlFor } from '../../../services/AppRouteURLService';
import ClientWebsiteDetailsValueItem from './ClientWebsiteDetailsValueItem';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import EditClientWebsiteBasicInfoPanelContainer from '../edit/EditClientWebsiteBasicInfoPanelContainer';
import EditClientWebsitePeopleDetailsPanelContainer from '../edit/EditClientWebsitePeopleDetailsPanelContainer';
import ClientWebsiteOccasionsLayoutContainer from '../occasions/ClientWebsiteOccasionsLayoutContainer';
import ClientWebsitePageSidebarContainer from './ClientWebsitePageSidebarContainer';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';
import EditClientWebsiteMetricsContainer from '../edit/EditClientWebsiteMetricsContainer';

type ClientWebsiteClientInfoProps = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  isWeddingEvent: boolean;
  showEditPeopleDetails: () => void;
};

function ClientWebsiteClientInfo(props: ClientWebsiteClientInfoProps) {
  const { clientWebsite, isWeddingEvent, showEditPeopleDetails } = props;
  function Header() {
    return (
      <CardHeader>
        <CardHeaderTitle>Details</CardHeaderTitle>
        <CardHeaderActions>
          <Button style={BUTTON_STYLES.LINK} onClick={showEditPeopleDetails}>
            Edit
          </Button>
        </CardHeaderActions>
      </CardHeader>
    );
  }
  if (!isWeddingEvent) {
    return (
      <Card>
        <Space>
          <Header />
          <ClientWebsiteDetailsValueItem
            itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM}
            clientWebsite={clientWebsite}
            title="Name"
          />
        </Space>
      </Card>
    );
  }
  if ([ThemeService.THEMES_IDS.BLED].includes(Number(clientWebsite.theme.id))) {
    return (
      <Card>
        <Space>
          <Header />
          <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM} clientWebsite={clientWebsite} />
          <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.GROOM_INFO} clientWebsite={clientWebsite} />
          <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE} clientWebsite={clientWebsite} />
          <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.BRIDE_INFO} clientWebsite={clientWebsite} />
        </Space>
      </Card>
    );
  }

  return (
    <Card>
      <Space>
        <Header />
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM} clientWebsite={clientWebsite} />
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE} clientWebsite={clientWebsite} />
      </Space>
    </Card>
  );
}

type ClientWebsiteEventInfoProps = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  showEditBasicInfoPanel: () => void;
  id: string;
  projectId: string;
  langCode?: string;
};

function ClientWebsiteEventInfo(props: ClientWebsiteEventInfoProps) {
  const { clientWebsite, showEditBasicInfoPanel, id, projectId, langCode } = props;
  return (
    <Card>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Additional details</CardHeaderTitle>
          <CardHeaderActions>
            <Button style={BUTTON_STYLES.LINK} onClick={showEditBasicInfoPanel}>
              Edit
            </Button>
          </CardHeaderActions>
        </CardHeader>
        <ClientWebsiteDetailsValueItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_REGISTRATION_TITLE}
          clientWebsite={clientWebsite}
          langCode={langCode}
        />
        <ClientWebsiteDetailsValueItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.DETAILED_TAGLINE}
          clientWebsite={clientWebsite}
        />
        <ClientWebsiteDetailsValueItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_TAGLINE}
          clientWebsite={clientWebsite}
        />
        <ClientWebsiteDetailsValueItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_VIDEO_LINK}
          clientWebsite={clientWebsite}
        />
        <ClientWebsiteDetailsValueItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_TRAILER_VIDEO_LINK}
          clientWebsite={clientWebsite}
        />
        <ClientWebsiteDetailsValueItem
          itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_GALLERY_LINK}
          clientWebsite={clientWebsite}
        />
      </Space>
      <EditClientWebsiteBasicInfoPanelContainer id={id} projectId={projectId} />
    </Card>
  );
}
type ClientWebsiteEventMetricsProps = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  showEditEventMetricsPanel: () => void;
  id: string;
  projectId: string;
};

function ClientWebsiteEventMetrics(props: ClientWebsiteEventMetricsProps) {
  const { clientWebsite, showEditEventMetricsPanel, id, projectId } = props;
  return (
    <Card>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Event Metrics</CardHeaderTitle>
          <CardHeaderActions>
            <Button style={BUTTON_STYLES.LINK} onClick={showEditEventMetricsPanel}>
              Edit
            </Button>
          </CardHeaderActions>
        </CardHeader>
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.METRICS} clientWebsite={clientWebsite} />
      </Space>
      <EditClientWebsiteMetricsContainer id={id} projectId={projectId} />
    </Card>
  );
}

type ClientWebsiteThemeInfoProps = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  id: string;
  projectId: string;
  isCompanyTypeNotPhotographer: boolean;
};

function ClientWebsiteThemeInfo(props: ClientWebsiteThemeInfoProps) {
  const { clientWebsite, id, projectId, isCompanyTypeNotPhotographer } = props;
  const editThemeLink = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.EDIT, { id, projectId });
  return (
    <Card>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Customizations</CardHeaderTitle>
          <CardHeaderActions>
            <Button style={BUTTON_STYLES.LINK} link={editThemeLink}>
              Edit
            </Button>
          </CardHeaderActions>
        </CardHeader>
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.WEBSITE_ID} clientWebsite={clientWebsite} />
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_ID} clientWebsite={clientWebsite} />
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.CLIPART_ID} clientWebsite={clientWebsite} />
        {!isCompanyTypeNotPhotographer && (
          <ClientWebsiteDetailsValueItem
            itemKey={CLIENT_WEBSITE_FORM_ITEMS.RELIGIOUS_CLIPART_ID}
            clientWebsite={clientWebsite}
          />
        )}
        <ClientWebsiteDetailsValueItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.MUSIC_ID} clientWebsite={clientWebsite} />
      </Space>
    </Card>
  );
}

type Props = {
  projectId: string;
  id: string;
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  showEditBasicInfoPanel: () => void;
  showEditOfPeopleDetailsPanel: () => void;
  isLoading: boolean;
  isCompanyTypeNotPhotographer: boolean;
  showEditEventMetricsPanel: () => void;
  langCode?: string;
};

export default function ClientWebsiteDetailsLayout(props: Props): React.ReactElement {
  const {
    id,
    projectId,
    clientWebsite,
    showEditBasicInfoPanel,
    showEditOfPeopleDetailsPanel,
    isLoading,
    isCompanyTypeNotPhotographer,
    showEditEventMetricsPanel,
    langCode,
  } = props;
  const isWeddingEvent = clientWebsite?.event?.type
    ? EventsCategoryService.isWeddingEvent(clientWebsite.event.type)
    : false;

  const imageUploadApiURL = ClientWebsiteService.getClientWebsiteApiURL(id, projectId);
  return (
    <>
      <InnerPageContentMainCol>
        <ClientWebsiteClientInfo
          clientWebsite={clientWebsite}
          isWeddingEvent={isWeddingEvent}
          showEditPeopleDetails={showEditOfPeopleDetailsPanel}
        />
        <ClientWebsiteOccasionsLayoutContainer projectId={projectId} id={id} />
        {isCompanyTypeNotPhotographer && (
          <>
            <Card>
              <Space>
                <ClientWebsiteFormItem
                  itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO}
                  clientWebsite={clientWebsite}
                  imageUploadApiURL={imageUploadApiURL}
                  isLoading={isLoading}
                />
              </Space>
            </Card>
            <Space vertical size={6} />
            <Card>
              <Space>
                <ClientWebsiteFormItem
                  itemKey={CLIENT_WEBSITE_FORM_ITEMS.CTA_BG}
                  clientWebsite={clientWebsite}
                  imageUploadApiURL={imageUploadApiURL}
                  isLoading={isLoading}
                />
              </Space>
            </Card>
            <ClientWebsiteEventMetrics
              clientWebsite={clientWebsite}
              showEditEventMetricsPanel={showEditEventMetricsPanel}
              projectId={projectId}
              id={id}
            />
          </>
        )}
        <ClientWebsiteEventInfo
          clientWebsite={clientWebsite}
          showEditBasicInfoPanel={showEditBasicInfoPanel}
          projectId={projectId}
          id={id}
          langCode={langCode}
        />

        <ClientWebsiteThemeInfo
          clientWebsite={clientWebsite}
          projectId={projectId}
          id={id}
          isCompanyTypeNotPhotographer={isCompanyTypeNotPhotographer}
        />
        <EditClientWebsitePeopleDetailsPanelContainer id={id} projectId={projectId} />
        <Space vertical />
      </InnerPageContentMainCol>
      <InnerPageContentSidebar>
        <ClientWebsitePageSidebarContainer projectId={projectId} id={id} />
      </InnerPageContentSidebar>
    </>
  );
}
