import React, { useState } from 'react';
import { ClientSettingsService, ProjectMetaService } from '@premagic/core';
import { Alert, ALERT_SIZES, ALERT_STYLES, Row, Space } from '@premagic/myne';

import HighlightDesignGalleryThumbnailSizeSelectorContainer from '../../projects/highlight-folders/highlight-gallery-design/HighlightDesignGalleryThumbnailSizeSelectorContainer';
import HighlightDesignGalleryGridSpaceSelectorContainer from '../../projects/highlight-folders/highlight-gallery-design/HighlightDesignGalleryGridSpaceSelectorContainer';
import HighlightDesignFolderLayoutSelectorContainer from '../../projects/highlight-folders/highlight-gallery-design/HighlightDesignFolderLayoutSelectorContainer';
import PremiumFeatureIcon from '../PremiumFeatureIcon';
import HighlightDesignWelcomeMessageEditorContainer from '../../projects/highlight-folders/highlight-gallery-design/highlight-design-text/text-editor-window-panel/welcome-message/HighlightDesignWelcomeMessageEditorContainer';
import HighlightDesignEndingTextEditorContainer from '../../projects/highlight-folders/highlight-gallery-design/highlight-design-text/text-editor-window-panel/ending-text/HighlightDesignEndingTextEditorContainer';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  savePreferences: (data: Record<string, any>) => void;
  hasHighlightCustomizationFeature: boolean;
};

export default function GlobalSignatureGalleryLayout(props: Props) {
  const { clientSettings, savePreferences, hasHighlightCustomizationFeature } = props;

  const [folderLayoutForSignatureAlbum, setFolderLayoutForSignatureAlbum]: any = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW] ||
      ProjectMetaService.FOLDER_THUMBNAIL_VIEWS.GRID_CARD,
  );

  const [isFolderThumbnailViewEnabledForSignatureAlbum, setIsFolderThumbnailViewEnabledForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED] || false,
  );

  const [galleryThumbnailSizeForSignatureAlbum, setGalleryThumbnailSizeForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE] ||
      ProjectMetaService.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT,
  );
  const [galleryGridSpaceForSignatureAlbum, setGalleryGridSpaceForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE] || 1,
  );
  const [galleryEndingTextForSignatureAlbum, setGalleryEndingTextForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT] ||
      'Hope you liked it.',
  );
  const [galleryWelcomeTextForSignatureAlbum, setGalleryWelcomeTextForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT],
  );

  function handleFolderLayoutChange(value) {
    if (value === false) {
      setIsFolderThumbnailViewEnabledForSignatureAlbum(false);
      setFolderLayoutForSignatureAlbum('no-thumbnail');
    } else {
      setIsFolderThumbnailViewEnabledForSignatureAlbum(true);
      setFolderLayoutForSignatureAlbum(value);
    }
  }

  return (
    <div>
      {!hasHighlightCustomizationFeature && (
        <>
          <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
            <Row vcenter>
              <PremiumFeatureIcon module="Signature Album Design Customization" />
              Signature Album Design Customization is available on Higher plans.
            </Row>
          </Alert>
          <Space vertical size={2} />
        </>
      )}
      <input
        type="hidden"
        name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE}
        value={galleryThumbnailSizeForSignatureAlbum}
      />
      <input
        type="hidden"
        name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE}
        value={galleryGridSpaceForSignatureAlbum}
      />
      <input
        type="hidden"
        name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED}
        value={String(isFolderThumbnailViewEnabledForSignatureAlbum)}
      />
      <input
        type="hidden"
        name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW}
        value={folderLayoutForSignatureAlbum}
      />

      <HighlightDesignFolderLayoutSelectorContainer
        onChange={handleFolderLayoutChange}
        folderLayout={folderLayoutForSignatureAlbum}
        isFolderThumbnailViewEnabled={isFolderThumbnailViewEnabledForSignatureAlbum}
      />
      <Space vertical size={6} />
      <HighlightDesignGalleryGridSpaceSelectorContainer
        onChange={(value) => setGalleryGridSpaceForSignatureAlbum(value)}
        galleryGridSpace={galleryGridSpaceForSignatureAlbum}
      />
      <Space vertical size={6} />
      <HighlightDesignGalleryThumbnailSizeSelectorContainer
        onChange={(value) => setGalleryThumbnailSizeForSignatureAlbum(value)}
        galleryThumbnailSize={galleryThumbnailSizeForSignatureAlbum}
      />
      <Space vertical size={6} />
      <HighlightDesignWelcomeMessageEditorContainer
        name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT}
        galleryWelcomeText={galleryWelcomeTextForSignatureAlbum}
      />
      <Space vertical size={6} />
      <HighlightDesignEndingTextEditorContainer
        name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT}
        galleryEndingText={galleryEndingTextForSignatureAlbum}
      />
    </div>
  );
}
