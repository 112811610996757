import React, { useState } from 'react';
import { Overlay as OverlayLib } from 'react-overlays';
import { BrowserUtils } from '@premagic/utils';

const TooltipStyle = {
  padding: '0 5px',
  minWidth: '48px',
  maxWidth: '180px',
  height: 'auto',
  fontFamily: 'Source Sans Pro, sans-serif',
  zIndex: 60,
};

const TooltipInnerStyle = {
  padding: '4px 8px',
  color: '#fff',
  borderRadius: 3,
  fontSize: '12px',
  lineHeight: '16px',
  backgroundColor: '#000',
};

interface Props {
  children: any;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  message: string | React.ReactNode;
}

export function Tooltip(props: Props) {
  const { message = '', children, placement = 'top' } = props;
  const node = React.createRef() as React.RefObject<HTMLSpanElement>;
  const [show, setShow] = useState(false);
  // if (BrowserUtils.isMobileBrowser()) return children;
  // @ts-ignore
  if (message.length === 0) return children;

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        ref={node}
        onMouseEnter={() => setShow(true)}
        onClick={() => {
          if (BrowserUtils.isMobileBrowser()) setShow(!show);
        }}
        onKeyDown={() => {
          if (BrowserUtils.isMobileBrowser()) setShow(!show);
        }}
        onMouseLeave={() => setShow(false)}
        // style={{
        //   display: 'inline-flex', // I dont know what will this break if we add this.
        // For now, I had miss-alignment issue in the list action, So I add this dropdown__wrapper > :global(.tooltip-wrapper) | Hopefully it wont break anything

        // }}
        className="tooltip-wrapper"
      >
        {children}
      </span>
      <OverlayLib
        show={show}
        onHide={() => setShow(false)}
        placement={placement}
        container={document.querySelector('body')}
        target={() => node.current}
      >
        {({ props: childProps }) => (
          <div
            {...childProps} // eslint-disable-line
            style={{
              ...TooltipStyle,
              ...childProps.style,
            }}
          >
            <div style={TooltipInnerStyle}>
              {typeof message === 'string' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                />
              ) : (
                message
              )}
            </div>
          </div>
        )}
      </OverlayLib>
    </>
  );
}
