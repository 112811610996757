import { COLOR_SHADES, Icon, Row, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { PremagicResources } from '@premagic/core';

import React from 'react';
import { ShareUtils } from '@premagic/utils';

type HaveAQuestionProps = {
  displayHeading?: boolean;
  displayPhoneNumbers?: boolean;
  subject?: string;
};

export default function HaveAQuestion(props: HaveAQuestionProps): JSX.Element {
  const { displayHeading = true, displayPhoneNumbers = true, subject = 'I need help with' } = props;
  return (
    <div>
      {displayHeading && (
        <Text muted boldness={TEXT_BOLDNESS.BOLD} block>
          Have a question?
        </Text>
      )}
      <Space vertical size={2} />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={ShareUtils.getWhatsappMessageLink(PremagicResources.RESOURCES.supportWhatsapp, subject)}
      >
        <Row>
          <Icon name="whatsapp_solid_color" />
          <Space size={2} />
          <Text boldness={TEXT_BOLDNESS.BOLD} block color={COLOR_SHADES.BLUE_LIGHT}>
            {PremagicResources.RESOURCES.supportWhatsapp}
          </Text>
        </Row>
      </a>
      <Space vertical />
      {displayPhoneNumbers &&
        PremagicResources.RESOURCES.supportPhones.map((phone) => (
          <Text muted boldness={TEXT_BOLDNESS.BOLD} block key={phone.value}>
            <a target="_blank" rel="noopener noreferrer" href={`tel:${phone.value}`}>
              {phone.title}
            </a>
          </Text>
        ))}
      <Row>
        <Icon name="gmail_solid_color" />
        <Space size={2} />
        <Text muted boldness={TEXT_BOLDNESS.BOLD}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${PremagicResources.RESOURCES.supportEmail}?subject=${encodeURIComponent(subject)}`}
          >
            {PremagicResources.RESOURCES.supportEmail}
          </a>
        </Text>
      </Row>
    </div>
  );
}
