import React, { CSSProperties } from 'react';
import ClassNames from 'classnames';
import { BrowserUtils } from '@premagic/utils';
import * as illustrationSvg from './svg-illustration';

import styles from './illustration.module.css';

export enum ILLUSTRATION_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XLG = 'xlg',
  AUTO = 'auto',
}

export interface IllustrationProps {
  name: string;
  size?: ILLUSTRATION_SIZES;
  className?: string;
}

export function Illustration(props: IllustrationProps): JSX.Element {
  const { name, size = ILLUSTRATION_SIZES.MD, className, ...elementProps } = props;
  const classes = ClassNames(className, styles.illustration, styles[`illustration--size-${size}`]);

  return (
    <span {...elementProps} className={classes}>
      {illustrationSvg[name]}
    </span>
  );
}
