import { ClientWebsiteService } from '@premagic/core';

import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Col,
  Divider,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import React from 'react';
import { StringUtils } from '@premagic/utils';
import ClientWebsiteItineraryItemContainer from './ClientWebsiteItineraryItemContainer';

type Props = {
  projectId: string;
  id: string;
  showAddItinerary: () => void;
  groupItineraryIds: Record<string, Array<ClientWebsiteService.ClientWebsiteItinerary>>;
  totalItems: number;
  isLoading: boolean;
};

export default function ClientWebsiteItineraryLayout(props: Props): React.ReactElement {
  const { showAddItinerary, id, projectId, groupItineraryIds, totalItems, isLoading } = props;
  const hasItems = totalItems !== 0;

  if (!hasItems || isLoading)
    return (
      <Card>
        <Space>
          <CardHeader>
            <Row vcenter>
              <Icon name="file_text" size={ICON_SIZES.SM} /> <Space size={1} />
              <CardHeaderTitle>Event Schedule</CardHeaderTitle>
            </Row>
          </CardHeader>
          {isLoading ? (
            <Row center>
              <LoadingDots size="sm" />
            </Row>
          ) : (
            <Row columnDirection center>
              <Text block muted>
                You have not added any sessions yet.
              </Text>
              <Space vertical size={4} />
              <Button style={BUTTON_STYLES.TERTIARY} onClick={showAddItinerary} type="button">
                <Icon name="plus" size={ICON_SIZES.SM} /> Session
              </Button>
            </Row>
          )}
        </Space>
      </Card>
    );

  return (
    <Card>
      <Space>
        <CardHeader hasPadding={false}>
          <Row vcenter>
            <Icon name="file_text" size={ICON_SIZES.SM} /> <Space size={1} />
            <CardHeaderTitle>Event Schedule</CardHeaderTitle>
            <Space size={1} />
            <Text>
              ({totalItems} {StringUtils.pluralize('Session', totalItems)})
            </Text>
          </Row>
          {hasItems && (
            <CardHeaderActions>
              <Button style={BUTTON_STYLES.LINK} onClick={showAddItinerary}>
                <Icon name="plus" size={ICON_SIZES.SM} /> Session
              </Button>
            </CardHeaderActions>
          )}
        </CardHeader>
      </Space>
      {Object.entries(groupItineraryIds).map(([date, items]) => (
        <div key={date}>
          <ErrorBoundary>
            <Row>
              <Space />
              <Col size={2}>
                <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
                  {date}
                </Text>
              </Col>
              <Divider vertical />
              <Space />
              <Col size={10}>
                <List>
                  {items.map((itinerary) => (
                    <ClientWebsiteItineraryItemContainer
                      key={itinerary.id}
                      id={itinerary.id}
                      websiteId={id}
                      projectId={projectId}
                    />
                  ))}
                </List>
              </Col>
            </Row>
          </ErrorBoundary>
        </div>
      ))}
    </Card>
  );
}
