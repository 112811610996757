import { APIURLService, PricingPlanService } from '@premagic/core';
import { StringUtils, HttpUtils } from '@premagic/utils';

export type InvoiceType = {
  amount: number;
  amount_due: number;
  amount_paid: number;
  billing_end: number;
  billing_start: number;
  comment: string;
  created_at: number;
  currency: 'INR' | 'USD';
  currency_symbol: string;
  customer_id: 'cust_IkGvnGt1JXDFce';
  date: number;
  description: string;
  email_status: null;
  id: 'inv_IpQTkqASSOq65f';
  invoice_number: null;
  line_items: [{ name: string }];
  paid_at: number;
  receipt: null;
  short_url: string;
  sms_status: null;
  status: 'paid';
};

export function fetchInvoices(): Promise<{
  count: number;
  items: Array<InvoiceType>;
}> {
  return HttpUtils.get(APIURLService.API_URLS.SUBSCRIPTION.INVOICES).then(({ data }) => data);
}

export function getInvoiceName(invoice, plan): string {
  const { planId, isBonusCredits } = invoice;
  if (isBonusCredits) {
    return 'Free plan';
  }

  return plan ? PricingPlanService.getPricingPlanTitle(plan) : StringUtils.toTitleCase(planId);
}
