import { SimpleImage } from '@premagic/myne';
import React from 'react';

type Props = {
  src?: string;
};

export default function QRCodeSponsorImage(props: Props) {
  const { src } = props;

  if (!src) return null;

  return (
    <SimpleImage src={src} alt="sponsor-logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
  );
}
