import parse, { isValidPhoneNumber as isValid } from 'libphonenumber-js';
import { CountryCode, PhoneNumber } from 'libphonenumber-js/types';

export function parsePhoneNumber(
  text: string,
  defaultCountry?: CountryCode | { defaultCountry?: CountryCode; defaultCallingCode?: string; extract?: boolean },
): PhoneNumber | undefined {
  if (!text) return undefined;
  return parse(text, defaultCountry);
}

export function isValidPhoneNumber(
  text: string,
  defaultCountry?: CountryCode | { defaultCountry?: CountryCode; defaultCallingCode?: string; extract?: boolean },
): boolean {
  return isValid(text, defaultCountry);
}

export type CountryCodeDetails = {
  name: string;
  dial_code: string;
  code: string;
  alpha2Code: string;
  preferred?: boolean;
};

export const COUNTRY_CODES: Array<CountryCodeDetails> = [
  { name: 'United States of America', dial_code: '+1', code: 'USA', alpha2Code: 'US' },
  { name: 'United Arab Emirates', dial_code: '+971', code: 'ARE', alpha2Code: 'AE' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', dial_code: '+44', code: 'GBR', alpha2Code: 'GB' },
  { name: 'India', dial_code: '+91', code: 'IND', alpha2Code: 'IN' },

  { name: 'Afghanistan', dial_code: '+93', code: 'AFG', alpha2Code: 'AF' },
  { name: 'Åland Islands', dial_code: '+358', code: 'ALA', alpha2Code: 'AX' },
  { name: 'Albania', dial_code: '+355', code: 'ALB', alpha2Code: 'AL' },
  { name: 'Algeria', dial_code: '+213', code: 'DZA', alpha2Code: 'DZ' },
  { name: 'American Samoa', dial_code: '+1684', code: 'ASM', alpha2Code: 'AS' },
  { name: 'Andorra', dial_code: '+376', code: 'AND', alpha2Code: 'AD' },
  { name: 'Angola', dial_code: '+244', code: 'AGO', alpha2Code: 'AO' },
  { name: 'Anguilla', dial_code: '+1264', code: 'AIA', alpha2Code: 'AI' },
  { name: 'Antarctica', dial_code: '+672', code: 'ATA', alpha2Code: 'AQ' },
  { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'ATG', alpha2Code: 'AG' },
  { name: 'Argentina', dial_code: '+54', code: 'ARG', alpha2Code: 'AR' },
  { name: 'Armenia', dial_code: '+374', code: 'ARM', alpha2Code: 'AM' },
  { name: 'Aruba', dial_code: '+297', code: 'ABW', alpha2Code: 'AW' },
  { name: 'Australia', dial_code: '+61', code: 'AUS', alpha2Code: 'AU' },
  { name: 'Austria', dial_code: '+43', code: 'AUT', alpha2Code: 'AT' },
  { name: 'Azerbaijan', dial_code: '+994', code: 'AZE', alpha2Code: 'AZ' },
  { name: 'Bahamas', dial_code: '+1242', code: 'BHS', alpha2Code: 'BS' },
  { name: 'Bahrain', dial_code: '+973', code: 'BHR', alpha2Code: 'BH' },
  { name: 'Bangladesh', dial_code: '+880', code: 'BGD', alpha2Code: 'BD' },
  { name: 'Barbados', dial_code: '+1246', code: 'BRB', alpha2Code: 'BB' },
  { name: 'Belarus', dial_code: '+375', code: 'BLR', alpha2Code: 'BY' },
  { name: 'Belgium', dial_code: '+32', code: 'BEL', alpha2Code: 'BE' },
  { name: 'Belize', dial_code: '+501', code: 'BLZ', alpha2Code: 'BZ' },
  { name: 'Benin', dial_code: '+229', code: 'BEN', alpha2Code: 'BJ' },
  { name: 'Bermuda', dial_code: '+1441', code: 'BMU', alpha2Code: 'BM' },
  { name: 'Bhutan', dial_code: '+975', code: 'BTN', alpha2Code: 'BT' },
  { name: 'Bolivia (Plurinational State of)', dial_code: '+591', code: 'BOL', alpha2Code: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', dial_code: '+5997', code: 'BES', alpha2Code: 'BQ' },
  { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BIH', alpha2Code: 'BA' },
  { name: 'Botswana', dial_code: '+267', code: 'BWA', alpha2Code: 'BW' },
  { name: 'Bouvet Island', dial_code: '+', code: 'BVT', alpha2Code: 'BV' },
  { name: 'Brazil', dial_code: '+55', code: 'BRA', alpha2Code: 'BR' },
  { name: 'British Indian Ocean Territory', dial_code: '+246', code: 'IOT', alpha2Code: 'IO' },
  // { name: 'United States Minor Outlying Islands', dial_code: '+', code: 'UMI', alpha2Code: 'UM' },
  { name: 'Virgin Islands (British)', dial_code: '+1284', code: 'VGB', alpha2Code: 'VG' },
  { name: 'Virgin Islands (U.S.)', dial_code: '+1 340', code: 'VIR', alpha2Code: 'VI' },
  { name: 'Brunei Darussalam', dial_code: '+673', code: 'BRN', alpha2Code: 'BN' },
  { name: 'Bulgaria', dial_code: '+359', code: 'BGR', alpha2Code: 'BG' },
  { name: 'Burkina Faso', dial_code: '+226', code: 'BFA', alpha2Code: 'BF' },
  { name: 'Burundi', dial_code: '+257', code: 'BDI', alpha2Code: 'BI' },
  { name: 'Cambodia', dial_code: '+855', code: 'KHM', alpha2Code: 'KH' },
  { name: 'Cameroon', dial_code: '+237', code: 'CMR', alpha2Code: 'CM' },
  { name: 'Canada', dial_code: '+1', code: 'CAN', alpha2Code: 'CA' },
  { name: 'Cabo Verde', dial_code: '+238', code: 'CPV', alpha2Code: 'CV' },
  { name: 'Cayman Islands', dial_code: '+1345', code: 'CYM', alpha2Code: 'KY' },
  { name: 'Central African Republic', dial_code: '+236', code: 'CAF', alpha2Code: 'CF' },
  { name: 'Chad', dial_code: '+235', code: 'TCD', alpha2Code: 'TD' },
  { name: 'Chile', dial_code: '+56', code: 'CHL', alpha2Code: 'CL' },
  { name: 'China', dial_code: '+86', code: 'CHN', alpha2Code: 'CN' },
  { name: 'Christmas Island', dial_code: '+61', code: 'CXR', alpha2Code: 'CX' },
  { name: 'Cocos (Keeling) Islands', dial_code: '+61', code: 'CCK', alpha2Code: 'CC' },
  { name: 'Colombia', dial_code: '+57', code: 'COL', alpha2Code: 'CO' },
  { name: 'Comoros', dial_code: '+269', code: 'COM', alpha2Code: 'KM' },
  { name: 'Congo', dial_code: '+242', code: 'COG', alpha2Code: 'CG' },
  { name: 'Congo (Democratic Republic of the)', dial_code: '+243', code: 'COD', alpha2Code: 'CD' },
  { name: 'Cook Islands', dial_code: '+682', code: 'COK', alpha2Code: 'CK' },
  { name: 'Costa Rica', dial_code: '+506', code: 'CRI', alpha2Code: 'CR' },
  { name: 'Croatia', dial_code: '+385', code: 'HRV', alpha2Code: 'HR' },
  { name: 'Cuba', dial_code: '+53', code: 'CUB', alpha2Code: 'CU' },
  { name: 'Cura\u00e7ao', dial_code: '+599', code: 'CUW', alpha2Code: 'CW' },
  { name: 'Cyprus', dial_code: '+357', code: 'CYP', alpha2Code: 'CY' },
  { name: 'Czech Republic', dial_code: '+420', code: 'CZE', alpha2Code: 'CZ' },
  { name: 'Denmark', dial_code: '+45', code: 'DNK', alpha2Code: 'DK' },
  { name: 'Djibouti', dial_code: '+253', code: 'DJI', alpha2Code: 'DJ' },
  { name: 'Dominica', dial_code: '+1767', code: 'DMA', alpha2Code: 'DM' },
  { name: 'Dominican Republic', dial_code: '+1849', code: 'DOM', alpha2Code: 'DO' },
  { name: 'Ecuador', dial_code: '+593', code: 'ECU', alpha2Code: 'EC' },
  { name: 'Egypt', dial_code: '+20', code: 'EGY', alpha2Code: 'EG' },
  { name: 'El Salvador', dial_code: '+503', code: 'SLV', alpha2Code: 'SV' },
  { name: 'Equatorial Guinea', dial_code: '+240', code: 'GNQ', alpha2Code: 'GQ' },
  { name: 'Eritrea', dial_code: '+291', code: 'ERI', alpha2Code: 'ER' },
  { name: 'Estonia', dial_code: '+372', code: 'EST', alpha2Code: 'EE' },
  { name: 'Ethiopia', dial_code: '+251', code: 'ETH', alpha2Code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', dial_code: '+500', code: 'FLK', alpha2Code: 'FK' },
  { name: 'Faroe Islands', dial_code: '+298', code: 'FRO', alpha2Code: 'FO' },
  { name: 'Fiji', dial_code: '+679', code: 'FJI', alpha2Code: 'FJ' },
  { name: 'Finland', dial_code: '+358', code: 'FIN', alpha2Code: 'FI' },
  { name: 'France', dial_code: '+33', code: 'FRA', alpha2Code: 'FR' },
  { name: 'French Guiana', dial_code: '+594', code: 'GUF', alpha2Code: 'GF' },
  { name: 'French Polynesia', dial_code: '+689', code: 'PYF', alpha2Code: 'PF' },
  { name: 'French Southern Territories', dial_code: '+', code: 'ATF', alpha2Code: 'TF' },
  { name: 'Gabon', dial_code: '+241', code: 'GAB', alpha2Code: 'GA' },
  { name: 'Gambia', dial_code: '+220', code: 'GMB', alpha2Code: 'GM' },
  { name: 'Georgia', dial_code: '+995', code: 'GEO', alpha2Code: 'GE' },
  { name: 'Germany', dial_code: '+49', code: 'DEU', alpha2Code: 'DE' },
  { name: 'Ghana', dial_code: '+233', code: 'GHA', alpha2Code: 'GH' },
  { name: 'Gibraltar', dial_code: '+350', code: 'GIB', alpha2Code: 'GI' },
  { name: 'Greece', dial_code: '+30', code: 'GRC', alpha2Code: 'GR' },
  { name: 'Greenland', dial_code: '+299', code: 'GRL', alpha2Code: 'GL' },
  { name: 'Grenada', dial_code: '+1473', code: 'GRD', alpha2Code: 'GD' },
  { name: 'Guadeloupe', dial_code: '+590', code: 'GLP', alpha2Code: 'GP' },
  { name: 'Guam', dial_code: '+1671', code: 'GUM', alpha2Code: 'GU' },
  { name: 'Guatemala', dial_code: '+502', code: 'GTM', alpha2Code: 'GT' },
  { name: 'Guernsey', dial_code: '+44', code: 'GGY', alpha2Code: 'GG' },
  { name: 'Guinea', dial_code: '+224', code: 'GIN', alpha2Code: 'GN' },
  { name: 'Guinea-Bissau', dial_code: '+245', code: 'GNB', alpha2Code: 'GW' },
  { name: 'Guyana', dial_code: '+592', code: 'GUY', alpha2Code: 'GY' },
  { name: 'Haiti', dial_code: '+509', code: 'HTI', alpha2Code: 'HT' },
  { name: 'Heard Island and McDonald Islands', dial_code: '+', code: 'HMD', alpha2Code: 'HM' },
  { name: 'Holy See', dial_code: '+379', code: 'VAT', alpha2Code: 'VA' },
  { name: 'Honduras', dial_code: '+504', code: 'HND', alpha2Code: 'HN' },
  { name: 'Hong Kong', dial_code: '+852', code: 'HKG', alpha2Code: 'HK' },
  { name: 'Hungary', dial_code: '+36', code: 'HUN', alpha2Code: 'HU' },
  { name: 'Iceland', dial_code: '+354', code: 'ISL', alpha2Code: 'IS' },
  { name: 'Indonesia', dial_code: '+62', code: 'IDN', alpha2Code: 'ID' },
  { name: "C\u00f4te d'Ivoire", dial_code: '+225', code: 'CIV', alpha2Code: 'CI' },
  { name: 'Iran (Islamic Republic of)', dial_code: '+98', code: 'IRN', alpha2Code: 'IR' },
  { name: 'Iraq', dial_code: '+964', code: 'IRQ', alpha2Code: 'IQ' },
  { name: 'Ireland', dial_code: '+353', code: 'IRL', alpha2Code: 'IE' },
  { name: 'Isle of Man', dial_code: '+44', code: 'IMN', alpha2Code: 'IM' },
  { name: 'Israel', dial_code: '+972', code: 'ISR', alpha2Code: 'IL' },
  { name: 'Italy', dial_code: '+39', code: 'ITA', alpha2Code: 'IT' },
  { name: 'Jamaica', dial_code: '+1876', code: 'JAM', alpha2Code: 'JM' },
  { name: 'Japan', dial_code: '+81', code: 'JPN', alpha2Code: 'JP' },
  { name: 'Jersey', dial_code: '+44', code: 'JEY', alpha2Code: 'JE' },
  { name: 'Jordan', dial_code: '+962', code: 'JOR', alpha2Code: 'JO' },
  { name: 'Kazakhstan', dial_code: '+77', code: 'KAZ', alpha2Code: 'KZ' },
  { name: 'Kenya', dial_code: '+254', code: 'KEN', alpha2Code: 'KE' },
  { name: 'Kiribati', dial_code: '+686', code: 'KIR', alpha2Code: 'KI' },
  { name: 'Kuwait', dial_code: '+965', code: 'KWT', alpha2Code: 'KW' },
  { name: 'Kyrgyzstan', dial_code: '+996', code: 'KGZ', alpha2Code: 'KG' },
  { name: "Lao People's Democratic Republic", dial_code: '+856', code: 'LAO', alpha2Code: 'LA' },
  { name: 'Latvia', dial_code: '+371', code: 'LVA', alpha2Code: 'LV' },
  { name: 'Lebanon', dial_code: '+961', code: 'LBN', alpha2Code: 'LB' },
  { name: 'Lesotho', dial_code: '+266', code: 'LSO', alpha2Code: 'LS' },
  { name: 'Liberia', dial_code: '+231', code: 'LBR', alpha2Code: 'LR' },
  { name: 'Libya', dial_code: '+218', code: 'LBY', alpha2Code: 'LY' },
  { name: 'Liechtenstein', dial_code: '+423', code: 'LIE', alpha2Code: 'LI' },
  { name: 'Lithuania', dial_code: '+370', code: 'LTU', alpha2Code: 'LT' },
  { name: 'Luxembourg', dial_code: '+352', code: 'LUX', alpha2Code: 'LU' },
  { name: 'Macao', dial_code: '+853', code: 'MAC', alpha2Code: 'MO' },
  { name: 'Macedonia (the former Yugoslav Republic of)', dial_code: '+389', code: 'MKD', alpha2Code: 'MK' },
  { name: 'Madagascar', dial_code: '+261', code: 'MDG', alpha2Code: 'MG' },
  { name: 'Malawi', dial_code: '+265', code: 'MWI', alpha2Code: 'MW' },
  { name: 'Malaysia', dial_code: '+60', code: 'MYS', alpha2Code: 'MY' },
  { name: 'Maldives', dial_code: '+960', code: 'MDV', alpha2Code: 'MV' },
  { name: 'Mali', dial_code: '+223', code: 'MLI', alpha2Code: 'ML' },
  { name: 'Malta', dial_code: '+356', code: 'MLT', alpha2Code: 'MT' },
  { name: 'Marshall Islands', dial_code: '+692', code: 'MHL', alpha2Code: 'MH' },
  { name: 'Martinique', dial_code: '+596', code: 'MTQ', alpha2Code: 'MQ' },
  { name: 'Mauritania', dial_code: '+222', code: 'MRT', alpha2Code: 'MR' },
  { name: 'Mauritius', dial_code: '+230', code: 'MUS', alpha2Code: 'MU' },
  { name: 'Mayotte', dial_code: '+262', code: 'MYT', alpha2Code: 'YT' },
  { name: 'Mexico', dial_code: '+52', code: 'MEX', alpha2Code: 'MX' },
  { name: 'Micronesia (Federated States of)', dial_code: '+691', code: 'FSM', alpha2Code: 'FM' },
  { name: 'Moldova (Republic of)', dial_code: '+373', code: 'MDA', alpha2Code: 'MD' },
  { name: 'Monaco', dial_code: '+377', code: 'MCO', alpha2Code: 'MC' },
  { name: 'Mongolia', dial_code: '+976', code: 'MNG', alpha2Code: 'MN' },
  { name: 'Montenegro', dial_code: '+382', code: 'MNE', alpha2Code: 'ME' },
  { name: 'Montserrat', dial_code: '+1664', code: 'MSR', alpha2Code: 'MS' },
  { name: 'Morocco', dial_code: '+212', code: 'MAR', alpha2Code: 'MA' },
  { name: 'Mozambique', dial_code: '+258', code: 'MOZ', alpha2Code: 'MZ' },
  { name: 'Myanmar', dial_code: '+95', code: 'MMR', alpha2Code: 'MM' },
  { name: 'Namibia', dial_code: '+264', code: 'NAM', alpha2Code: 'NA' },
  { name: 'Nauru', dial_code: '+674', code: 'NRU', alpha2Code: 'NR' },
  { name: 'Nepal', dial_code: '+977', code: 'NPL', alpha2Code: 'NP' },
  { name: 'Netherlands', dial_code: '+31', code: 'NLD', alpha2Code: 'NL' },
  { name: 'New Caledonia', dial_code: '+687', code: 'NCL', alpha2Code: 'NC' },
  { name: 'New Zealand', dial_code: '+64', code: 'NZL', alpha2Code: 'NZ' },
  { name: 'Nicaragua', dial_code: '+505', code: 'NIC', alpha2Code: 'NI' },
  { name: 'Niger', dial_code: '+227', code: 'NER', alpha2Code: 'NE' },
  { name: 'Nigeria', dial_code: '+234', code: 'NGA', alpha2Code: 'NG' },
  { name: 'Niue', dial_code: '+683', code: 'NIU', alpha2Code: 'NU' },
  { name: 'Norfolk Island', dial_code: '+672', code: 'NFK', alpha2Code: 'NF' },
  { name: "Korea (Democratic People's Republic of)", dial_code: '+850', code: 'PRK', alpha2Code: 'KP' },
  { name: 'Northern Mariana Islands', dial_code: '+1670', code: 'MNP', alpha2Code: 'MP' },
  { name: 'Norway', dial_code: '+47', code: 'NOR', alpha2Code: 'NO' },
  { name: 'Oman', dial_code: '+968', code: 'OMN', alpha2Code: 'OM' },
  { name: 'Pakistan', dial_code: '+92', code: 'PAK', alpha2Code: 'PK' },
  { name: 'Palau', dial_code: '+680', code: 'PLW', alpha2Code: 'PW' },
  { name: 'Palestine, State of', dial_code: '+970', code: 'PSE', alpha2Code: 'PS' },
  { name: 'Panama', dial_code: '+507', code: 'PAN', alpha2Code: 'PA' },
  { name: 'Papua New Guinea', dial_code: '+675', code: 'PNG', alpha2Code: 'PG' },
  { name: 'Paraguay', dial_code: '+595', code: 'PRY', alpha2Code: 'PY' },
  { name: 'Peru', dial_code: '+51', code: 'PER', alpha2Code: 'PE' },
  { name: 'Philippines', dial_code: '+63', code: 'PHL', alpha2Code: 'PH' },
  { name: 'Pitcairn', dial_code: '+64', code: 'PCN', alpha2Code: 'PN' },
  { name: 'Poland', dial_code: '+48', code: 'POL', alpha2Code: 'PL' },
  { name: 'Portugal', dial_code: '+351', code: 'PRT', alpha2Code: 'PT' },
  { name: 'Puerto Rico', dial_code: '+1939', code: 'PRI', alpha2Code: 'PR' },
  { name: 'Qatar', dial_code: '+974', code: 'QAT', alpha2Code: 'QA' },
  { name: 'Republic of Kosovo', dial_code: '+383', code: 'KOS', alpha2Code: 'XK' },
  { name: 'R\u00e9union', dial_code: '+262', code: 'REU', alpha2Code: 'RE' },
  { name: 'Romania', dial_code: '+40', code: 'ROU', alpha2Code: 'RO' },
  { name: 'Russian Federation', dial_code: '+7', code: 'RUS', alpha2Code: 'RU' },
  { name: 'Rwanda', dial_code: '+250', code: 'RWA', alpha2Code: 'RW' },
  { name: 'Saint Barth\u00e9lemy', dial_code: '+590', code: 'BLM', alpha2Code: 'BL' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', dial_code: '+290', code: 'SHN', alpha2Code: 'SH' },
  { name: 'Saint Kitts and Nevis', dial_code: '+1869', code: 'KNA', alpha2Code: 'KN' },
  { name: 'Saint Lucia', dial_code: '+1758', code: 'LCA', alpha2Code: 'LC' },
  { name: 'Saint Martin (French part)', dial_code: '+590', code: 'MAF', alpha2Code: 'MF' },
  { name: 'Saint Pierre and Miquelon', dial_code: '+508', code: 'SPM', alpha2Code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', dial_code: '+1784', code: 'VCT', alpha2Code: 'VC' },
  { name: 'Samoa', dial_code: '+685', code: 'WSM', alpha2Code: 'WS' },
  { name: 'San Marino', dial_code: '+378', code: 'SMR', alpha2Code: 'SM' },
  { name: 'Sao Tome and Principe', dial_code: '+239', code: 'STP', alpha2Code: 'ST' },
  { name: 'Saudi Arabia', dial_code: '+966', code: 'SAU', alpha2Code: 'SA' },
  { name: 'Senegal', dial_code: '+221', code: 'SEN', alpha2Code: 'SN' },
  { name: 'Serbia', dial_code: '+381', code: 'SRB', alpha2Code: 'RS' },
  { name: 'Seychelles', dial_code: '+248', code: 'SYC', alpha2Code: 'SC' },
  { name: 'Sierra Leone', dial_code: '+232', code: 'SLE', alpha2Code: 'SL' },
  { name: 'Singapore', dial_code: '+65', code: 'SGP', alpha2Code: 'SG' },
  { name: 'Sint Maarten (Dutch part)', dial_code: '+1721', code: 'SXM', alpha2Code: 'SX' },
  { name: 'Slovakia', dial_code: '+421', code: 'SVK', alpha2Code: 'SK' },
  { name: 'Slovenia', dial_code: '+386', code: 'SVN', alpha2Code: 'SI' },
  { name: 'Solomon Islands', dial_code: '+677', code: 'SLB', alpha2Code: 'SB' },
  { name: 'Somalia', dial_code: '+252', code: 'SOM', alpha2Code: 'SO' },
  { name: 'South Africa', dial_code: '+27', code: 'ZAF', alpha2Code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', dial_code: '+500', code: 'SGS', alpha2Code: 'GS' },
  { name: 'Korea (Republic of)', dial_code: '+82', code: 'KOR', alpha2Code: 'KR' },
  { name: 'South Sudan', dial_code: '+211', code: 'SSD', alpha2Code: 'SS' },
  { name: 'Spain', dial_code: '+34', code: 'ESP', alpha2Code: 'ES' },
  { name: 'Sri Lanka', dial_code: '+94', code: 'LKA', alpha2Code: 'LK' },
  { name: 'Sudan', dial_code: '+249', code: 'SDN', alpha2Code: 'SD' },
  { name: 'Suriname', dial_code: '+597', code: 'SUR', alpha2Code: 'SR' },
  { name: 'Svalbard and Jan Mayen', dial_code: '+4779', code: 'SJM', alpha2Code: 'SJ' },
  { name: 'Swaziland', dial_code: '+268', code: 'SWZ', alpha2Code: 'SZ' },
  { name: 'Sweden', dial_code: '+46', code: 'SWE', alpha2Code: 'SE' },
  { name: 'Switzerland', dial_code: '+41', code: 'CHE', alpha2Code: 'CH' },
  { name: 'Syrian Arab Republic', dial_code: '+963', code: 'SYR', alpha2Code: 'SY' },
  { name: 'Taiwan', dial_code: '+886', code: 'TWN', alpha2Code: 'TW' },
  { name: 'Tajikistan', dial_code: '+992', code: 'TJK', alpha2Code: 'TJ' },
  { name: 'Tanzania, United Republic of', dial_code: '+255', code: 'TZA', alpha2Code: 'TZ' },
  { name: 'Thailand', dial_code: '+66', code: 'THA', alpha2Code: 'TH' },
  { name: 'Timor-Leste', dial_code: '+670', code: 'TLS', alpha2Code: 'TL' },
  { name: 'Togo', dial_code: '+228', code: 'TGO', alpha2Code: 'TG' },
  { name: 'Tokelau', dial_code: '+690', code: 'TKL', alpha2Code: 'TK' },
  { name: 'Tonga', dial_code: '+676', code: 'TON', alpha2Code: 'TO' },
  { name: 'Trinidad and Tobago', dial_code: '+1868', code: 'TTO', alpha2Code: 'TT' },
  { name: 'Tunisia', dial_code: '+216', code: 'TUN', alpha2Code: 'TN' },
  { name: 'Turkey', dial_code: '+90', code: 'TUR', alpha2Code: 'TR' },
  { name: 'Turkmenistan', dial_code: '+993', code: 'TKM', alpha2Code: 'TM' },
  { name: 'Turks and Caicos Islands', dial_code: '+1649', code: 'TCA', alpha2Code: 'TC' },
  { name: 'Tuvalu', dial_code: '+688', code: 'TUV', alpha2Code: 'TV' },
  { name: 'Uganda', dial_code: '+256', code: 'UGA', alpha2Code: 'UG' },
  { name: 'Ukraine', dial_code: '+380', code: 'UKR', alpha2Code: 'UA' },
  { name: 'Uruguay', dial_code: '+598', code: 'URY', alpha2Code: 'UY' },
  { name: 'Uzbekistan', dial_code: '+998', code: 'UZB', alpha2Code: 'UZ' },
  { name: 'Vanuatu', dial_code: '+678', code: 'VUT', alpha2Code: 'VU' },
  { name: 'Venezuela (Bolivarian Republic of)', dial_code: '+58', code: 'VEN', alpha2Code: 'VE' },
  { name: 'Viet Nam', dial_code: '+84', code: 'VNM', alpha2Code: 'VN' },
  { name: 'Wallis and Futuna', dial_code: '+681', code: 'WLF', alpha2Code: 'WF' },
  { name: 'Western Sahara', dial_code: '+212', code: 'ESH', alpha2Code: 'EH' },
  { name: 'Yemen', dial_code: '+967', code: 'YEM', alpha2Code: 'YE' },
  { name: 'Zambia', dial_code: '+260', code: 'ZMB', alpha2Code: 'ZM' },
  { name: 'Zimbabwe', dial_code: '+263', code: 'ZWE', alpha2Code: 'ZW' },
];

// add all european countries
const IMPLICIT_CONSENT_REQUIRED_COUNTRIES = ['GB', 'DE', 'IT', 'FR', 'CH', 'NL'];

export function doesImplicitConsentRequired(countryCode?: string): boolean {
  if (!countryCode) return false;
  return IMPLICIT_CONSENT_REQUIRED_COUNTRIES.includes(countryCode);
}
