import { EventPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Divider,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  TEXT_GROUP_TYPES,
  TextGroupValue,
} from '@premagic/myne';
import React from 'react';
import EventBadgePunchHoleSelectorContainer from '../widget-selector/EventBadgePunchHoleSelectorContainer';
import EventPosterSizeSelectorContainer from '../widget-selector/EventPosterSizeSelectorContainer';
import EventPosterCreatorLayersContainer from './EventPosterCreatorLayersContainer';
import EventPosterCreatorWidgetEditorContainer from './EventPosterCreatorWidgetEditorContainer';

type Props = {
  projectId: string;
  posterId?: string;
  type: EventPosterService.EVENT_POSTER_TYPE;
  isLoading: boolean;
  poster?: EventPosterService.EventPosterType;
  showEditEventPoster: (posterId: string) => void;
};

export default function EventPosterCreatorSidebar(props: Props) {
  const { projectId, posterId, type, isLoading, poster, showEditEventPoster } = props;

  if (isLoading) {
    return null;
  }
  if (!posterId || !poster) {
    return null;
  }
  if (!posterId) {
    return null;
  }
  const showBadgePunchHole = type === EventPosterService.EVENT_POSTER_TYPE.BADGE;
  const showEventUrl = [EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT].includes(type);

  return (
    <div>
      <Space size={4}>
        <Row>
          <Col size={8}>
            <EventPosterSizeSelectorContainer projectId={projectId} posterId={posterId} />
            {showEventUrl && (
              <FormGroup>
                <FormLabel>Event URL</FormLabel>
                <TextGroupValue value={poster.registration_link} type={TEXT_GROUP_TYPES.URL} />
              </FormGroup>
            )}
          </Col>
          <Col size={null} rightAlighed>
            <Button onClick={() => showEditEventPoster(posterId)} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
              <Icon name="edit_2" size={ICON_SIZES.SM} />
              <Space size={1} />
              Edit
            </Button>
          </Col>
        </Row>
      </Space>
      <EventPosterCreatorLayersContainer projectId={projectId} posterId={posterId} type={type} />
      <Divider />
      <EventPosterCreatorWidgetEditorContainer posterId={posterId} projectId={projectId} />
      <Space vertical size={12} />
      {showBadgePunchHole && (
        <Space size={4}>
          <EventBadgePunchHoleSelectorContainer projectId={projectId} posterId={posterId} />
        </Space>
      )}
    </div>
  );
}
