import { useEffect } from 'react';

import { InnerPage, InnerPageContent } from '@premagic/myne';
import { Route, Switch } from 'react-router-dom';
import APP_URLS from '../../../services/AppRouteURLService';
import WatermarkPreviewFullViewContainer from '../../watermark/watermark-preview/WatermarkPreviewFullViewContainer';
import FolderImagesPopupContainer from '../folders/gallery/FolderImagesPopupContainer';
import AlbumImagesBasedOnStatusLayoutContainer from '../overview/AlbumImagesBasedOnStatusLayoutContainer';
import StorePricingModalPhotoDownloadContainer from '../store-pricing-modal/StorePricingModalPhotoDownloadContainer';
import HighlightFoldersPageHeaderContainer from './HighlightFoldersPageHeaderContainer';
import HighlightFoldersPageFoldersLayoutContainer from './folders/HighlightFoldersPageFoldersLayoutContainer';
import HighlightFoldersPageCoverSettingsLayoutContainer from './highligh-folder-cover/HighlightFoldersPageCoverSettingsLayoutContainer';
import HighlightGalleryDesignLayoutContainer from './highlight-gallery-design/HighlightGalleryDesignLayoutContainer';

type Props = {
  initData: (projectId: string) => void;
  isLoading: boolean;
  projectId: string;
  currentUrlPathname: string;
};

export default function HighlightFoldersPage(props: Props) {
  const { initData, isLoading, projectId, currentUrlPathname } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  return (
    <InnerPage>
      <HighlightFoldersPageHeaderContainer projectId={projectId} currentUrlPathname={currentUrlPathname} />
      <InnerPageContent isLoading={isLoading} hasInnerCols>
        <Switch>
          <Route
            path={APP_URLS.PROJECT.HIGHLIGHT__COVER_SETTINGS}
            component={HighlightFoldersPageCoverSettingsLayoutContainer}
          />
          <Route
            path={APP_URLS.PROJECT.ALBUM_IMAGES_BASED_ON_STATUS}
            component={AlbumImagesBasedOnStatusLayoutContainer}
          />
          <Route
            path={APP_URLS.PROJECT.HIGHLIGHT__GALLERY_SETTINGS}
            component={HighlightGalleryDesignLayoutContainer}
          />
          <Route path={APP_URLS.PROJECT.HIGHLIGHT} component={HighlightFoldersPageFoldersLayoutContainer} />
        </Switch>
      </InnerPageContent>
      <Route exact path={APP_URLS.FOLDER.FILE_VIEW} component={FolderImagesPopupContainer} />
      <Route path={APP_URLS.PROJECT.HIGHLIGHT__WATERMARK_PREVIEW} component={WatermarkPreviewFullViewContainer} />
      <StorePricingModalPhotoDownloadContainer projectId={projectId} />
    </InnerPage>
  );
}
