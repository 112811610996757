import React, { CSSProperties, MouseEvent } from 'react';
import ClassNames from 'classnames';
import styles from './buttons.module.css';
import { Link, SimpleLink } from '../Link/Link';
import { LoadingDots, LoadingSpin } from '../Loading/Loading';
import { Tooltip } from '../Tooltip/Tooltip';
import { Row, Space } from '../Grid';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { COLOR_SHADES } from '../Color/Color';

export enum BUTTON_STYLES {
  DEFAULT = 'default',
  CANCEL = 'cancel',
  PRIMARY = 'primary',
  PRIMARY_DARK = 'primary-dark',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  LINK = 'link',
  LINK_SECONDARY = 'link-secondary',
  RESET = 'reset',
  DANGER = 'danger',
  PILL = 'pill',
}

export enum BUTTON_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

function getButtonElementType(
  type: 'submit' | 'button' | 'reset' | null,
  style: BUTTON_STYLES,
): 'submit' | 'button' | 'reset' {
  if (type) return type;

  if ([BUTTON_STYLES.PRIMARY, BUTTON_STYLES.PRIMARY_DARK].includes(style)) {
    return 'submit';
  }

  return 'button';
}

export interface ButtonProps {
  children: React.ReactNode;
  style?: BUTTON_STYLES;
  cssStyle?: CSSProperties;
  size?: BUTTON_SIZE;
  block?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  active?: boolean;
  disabledMessage?: string;
  className?: string;
  type?: 'submit' | 'button' | 'reset';
  link?: string | null;
  isExternalLink?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onDoubleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  fromTheme?: boolean;
  tabIndex?: number;
  showExternalLinkIcon?: boolean;
}

export const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const {
    children,
    style = BUTTON_STYLES.DEFAULT,
    size = BUTTON_SIZE.MD,
    block = false,
    isLoading = false,
    disabled = false,
    active = false,
    disabledMessage = '',
    className = '',
    type = null,
    link = null,
    isExternalLink = false,
    tooltipPlacement = 'left',
    cssStyle,
    fromTheme = false,
    showExternalLinkIcon = false,
    onDoubleClick,
    onClick,
    ...elementProps
  } = props;

  const classes = ClassNames(
    styles.button,
    className,
    styles[`button--style-${style}`],
    styles[`button--style-${style}`],
    styles[`button--style-${style}--${size}`],
    styles[`button--size-${size}`],
    {
      [styles['button--block']]: block,
      [styles[`button--style-${style}--active`]]: active,
      [styles['button--loading']]: isLoading,
      [styles['button--disabled']]: disabled,
      [styles['button--from-theme']]: fromTheme,
    },
  );

  if (link) {
    return (
      <Tooltip message={disabled ? disabledMessage : ''}>
        <Link
          ref={ref}
          to={link}
          onClick={onClick}
          disabled={isLoading || disabled}
          isExternalLink={isExternalLink}
          showExternalLinkIcon={showExternalLinkIcon}
          {...elementProps}
          className={classes}
          cssStyle={cssStyle}
        >
          {children}
          {isLoading && (
            <div className={styles.button__loading}>
              <LoadingSpin />
            </div>
          )}
        </Link>
      </Tooltip>
    );
  }

  return (
    <Tooltip message={disabled ? disabledMessage : ''} placement={tooltipPlacement}>
      <button
        ref={ref}
        disabled={isLoading || disabled}
        {...elementProps}
        onClick={(e) => {
          if (onDoubleClick && e.detail === 2) {
            onDoubleClick(e);
          } else if (onClick) {
            onClick(e);
          }
        }}
        type={getButtonElementType(type, style)} // eslint-disable-line react/button-has-type
        className={classes}
        style={cssStyle}
      >
        {children}
        {isLoading && (
          <div className={styles.button__loading}>
            <LoadingSpin />
          </div>
        )}
      </button>
    </Tooltip>
  );
});

export enum BUTTON_CIRCLE_STYLES {
  WHITE = 'white',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  SUCCESS = 'success',
  YELLOW = 'yellow',
  DANGER = 'danger',
}

export enum BUTTON_CIRCLE_SIZES {
  N = 'n',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

interface ButtonCircleProps {
  children: React.ReactNode;
  style?: BUTTON_CIRCLE_STYLES;
  layer?: 0 | 1;
  size?: BUTTON_CIRCLE_SIZES;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export function ButtonCircle(props: ButtonCircleProps): React.ReactElement {
  const {
    children,
    style = BUTTON_CIRCLE_STYLES.DEFAULT,
    layer = 0,
    active = false,
    size = BUTTON_CIRCLE_SIZES.N,
    ...elementProps
  } = props;
  const classes = ClassNames(
    styles['button-circle'],
    styles[`button-circle--layer-${layer}`],
    styles[`button-circle--style-${style}`],
    styles[`button-circle--size-${size}`],
    { [styles['button-circle--active']]: active },
  );

  return (
    <button type="button" className={classes} {...elementProps}>
      {children}
    </button>
  );
}

export enum BUTTON_ICON_STYLES {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum BUTTON_ICONS_SIZES {
  SM = 'sm',
  N = 'n',
  MD = 'md',
}

export interface ButtonIconProps {
  children: React.ReactNode;
  style?: BUTTON_ICON_STYLES;
  size?: BUTTON_ICONS_SIZES;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onKeyPress?: () => void;
  title: string | React.ReactNode;
  disabledTitle?: string;
  className?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  isLoading?: boolean;
  link?: string;
  fromTheme?: boolean;
  isExternalLink?: boolean;
}

export const ButtonIcon = React.forwardRef((props: ButtonIconProps, ref: any) => {
  const {
    children,
    style = BUTTON_ICON_STYLES.DEFAULT,
    active = false,
    size = BUTTON_ICONS_SIZES.N,
    title = false,
    disabledTitle = 'disabled',
    className = '',
    tooltipPlacement = 'top',
    isLoading = false,
    disabled = false,
    link,
    fromTheme = false,
    isExternalLink = false,
    ...elementProps
  } = props;

  const classes = ClassNames(
    className,
    styles['button-icon'],
    styles[`button-icon--style-${style}`],
    styles[`button-icon--size-${size}`],
    {
      [styles['button-icon--active']]: active,
      [styles['from-theme']]: fromTheme,
    },
  );

  // @ts-ignore
  if (title && title.length === 0) {
    return (
      <button type="button" className={classes} {...elementProps} ref={ref}>
        {children}
        {isLoading && (
          <div className={styles['button-icon__loading']}>
            <LoadingDots size="xs" />
          </div>
        )}
      </button>
    );
  }
  if (link) {
    return (
      <Tooltip message={disabled ? disabledTitle : title} placement={tooltipPlacement}>
        <Link
          ref={ref}
          to={link}
          disabled={isLoading || disabled}
          isExternalLink={isExternalLink}
          {...elementProps}
          className={classes}
        >
          {children}
          {isLoading && (
            <div className={styles['button-icon__loading']}>
              <LoadingDots size="xs" />
            </div>
          )}
        </Link>
      </Tooltip>
    );
  }
  return (
    <Tooltip message={disabled ? disabledTitle : title} placement={tooltipPlacement}>
      <button type="button" className={classes} {...elementProps} disabled={disabled || isLoading} ref={ref}>
        {children}
        {isLoading && (
          <div className={styles['button-icon__loading']}>
            <LoadingDots size="xs" />
          </div>
        )}
      </button>
    </Tooltip>
  );
});

export enum TEXT_BUTTON_STYLES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
}

interface TextButtonProps {
  children: React.ReactNode;
  style?: TEXT_BUTTON_STYLES;
  size?: BUTTON_SIZE;
  isLoading?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  link?: string;
  onClick?: () => void;
  active?: boolean;
  cssStyle?: CSSProperties;
  isExternalLink?: boolean;
}

export const TextButton = React.forwardRef((props: TextButtonProps, ref: any) => {
  const {
    children,
    style = TEXT_BUTTON_STYLES.DEFAULT,
    size = BUTTON_SIZE.MD,
    isLoading = false,
    disabled = false,
    disabledMessage = '',
    link,
    active = false,
    cssStyle,
    isExternalLink = false,
    ...elementProps
  } = props;

  const classes = ClassNames(
    styles['text-button'],
    styles[`text-button--style-${style}`],
    styles[`text-button--size-${size}`],
    {
      [styles['text-button--loading']]: isLoading,
      [styles['text-button--active']]: active,
    },
  );

  if (link) {
    return (
      <Link
        to={link}
        disabled={isLoading || disabled}
        isExternalLink={isExternalLink}
        {...elementProps}
        className={classes}
        ref={ref}
        cssStyle={cssStyle}
      >
        {children}
        {isLoading && (
          <div className={styles.button__loading}>
            <LoadingSpin />
          </div>
        )}
      </Link>
    );
  }

  return (
    <Tooltip message={disabled ? disabledMessage : ''}>
      <button
        type="button"
        disabled={isLoading || disabled}
        {...elementProps}
        className={classes}
        ref={ref}
        style={cssStyle}
      >
        {children}
        {isLoading && (
          <div className={styles.button__loading}>
            <LoadingSpin />
          </div>
        )}
      </button>
    </Tooltip>
  );
});

export enum GROUPED_BUTTON_STYLES {
  DEFAULT = 'default',
  COMPACT = 'compact',
}

interface GroupedButtonsProps {
  children: React.ReactNode;
  style?: GROUPED_BUTTON_STYLES;
}

export function GroupedButtons(props: GroupedButtonsProps): React.ReactElement {
  const { children, style = GROUPED_BUTTON_STYLES.DEFAULT } = props;

  return <div className={ClassNames(styles['grouped-btns'], styles[`grouped-btns--style-${style}`])}>{children}</div>;
}

interface GroupedButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  link?: string;
  title?: string;
}

export function GroupedButton(props: GroupedButtonProps): React.ReactElement {
  const { children, disabled = false, active, link, title, ...elementProps } = props;
  const classes = ClassNames(styles['grouped-btns__btn'], {
    [styles['grouped-btns__btn--disabled']]: disabled,
    [styles['grouped-btns__btn--active']]: active,
  });
  if (link) {
    return (
      <SimpleLink className={classes} href={link} isExternalLink {...elementProps}>
        {children}
      </SimpleLink>
    );
  }

  return (
    <Tooltip message={title}>
      <button type="button" disabled={disabled} className={classes} {...elementProps}>
        {children}
      </button>
    </Tooltip>
  );
}

export enum BUTTON_TILE_SIZE {
  XS = 'xs',
  SM_XS = 'sm-xs',
  SM = 'sm',
  MD = 'md',
}

export enum BUTTON_TILE_STYLE {
  DEFAULT = 'default',
  PILL = 'pill',
}

interface ButtonTileProps {
  children: React.ReactNode;
  size?: BUTTON_TILE_SIZE;
  style?: BUTTON_TILE_STYLE;
  active?: boolean;
  disabled?: boolean;
  title?: string;
  disabledMessage?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  isLoading?: boolean;
  onClick?: () => void;
  cssStyle?: CSSProperties;
}

export function ButtonTile(props: ButtonTileProps): React.ReactElement {
  const {
    children,
    active,
    size = BUTTON_TILE_SIZE.MD,
    title,
    disabled = false,
    disabledMessage = 'disabled',
    tooltipPlacement,
    isLoading = false,
    onClick,
    cssStyle,
    style = BUTTON_TILE_STYLE.DEFAULT,
  } = props;
  const classes = ClassNames(styles['button-tile'], styles[`button-tile--style-${style}`]);

  const buttonClass = ClassNames(
    styles[`button-tile__button--size-${size}`],
    styles[`button-tile__button--style-${style}`],
    { [styles[`button-tile__button--active`]]: active },
    { [styles[`button-tile__button--style-${style}--active`]]: active },
  );
  if (title) {
    return (
      <div className={classes}>
        {/* Showing the tooltip message here instead of Button due to allignment issue */}
        <Tooltip message={disabled ? disabledMessage : ''} placement={tooltipPlacement}>
          <Row columnDirection vcenter>
            <Button
              onClick={onClick}
              active={active}
              disabled={disabled}
              isLoading={isLoading}
              className={buttonClass}
              cssStyle={cssStyle}
            >
              {children}
            </Button>
            <Space vertical size={1} />
            <Row>
              <Text center block color={active ? COLOR_SHADES.DARKER : COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
                {title}
              </Text>
            </Row>
          </Row>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={classes}>
      <Tooltip message={disabled ? disabledMessage : ''} placement={tooltipPlacement}>
        <Button
          onClick={onClick}
          active={active}
          disabled={disabled}
          isLoading={isLoading}
          className={buttonClass}
          cssStyle={cssStyle}
        >
          {children}
        </Button>
      </Tooltip>
    </div>
  );
}
