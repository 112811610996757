import React, { useEffect, useState } from 'react';
import { Button, BUTTON_STYLES, FormFooter, Modal, MODAL_SIZES, ModalTitle } from '@premagic/myne';
import SponsorFilesContainer from './SponsorFilesContainer';
import FolderImagePopupSimpleContainer from '../../folders/gallery/FolderImagePopupSimpleContainer';

type Props = {
  projectId: string;
  folderId: string;
  show: boolean;
  hideModal: () => void;
};

export default function SponsorFilesModal(props: Props): JSX.Element | null {
  const { projectId, folderId, show, hideModal } = props;
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  useEffect(() => {
    if (!show) {
      setSelectedFile(null);
    }
  }, [show]);

  if (!show) return null;

  function hideFilePopup() {
    setSelectedFile(null);
  }

  return (
    <Modal show={show} close={hideModal} size={MODAL_SIZES.MD}>
      {selectedFile ? (
        <div>
          <FolderImagePopupSimpleContainer
            projectId={projectId}
            folderId={folderId}
            id={selectedFile}
            onDelete={hideFilePopup}
            onNavigate={setSelectedFile}
          />
        </div>
      ) : (
        <div>
          <ModalTitle>Files</ModalTitle>
          <SponsorFilesContainer projectId={projectId} onSelect={setSelectedFile} />
        </div>
      )}
      <FormFooter>
        {selectedFile ? (
          <Button style={BUTTON_STYLES.CANCEL} onClick={hideFilePopup}>
            Back
          </Button>
        ) : (
          <Button style={BUTTON_STYLES.CANCEL} onClick={hideModal}>
            Close
          </Button>
        )}
      </FormFooter>
    </Modal>
  );
}
