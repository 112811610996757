import React, { useEffect } from 'react';
import { Card, Col, COLOR_SHADES, ErrorBoundary, Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { AccountFinancialsMetricType } from '../AccountFinancialsService';
import {
  ACCOUNT_FINANCIALS_METRICS,
  ACCOUNT_FINANCIALS_METRICS_DETAILS,
  METRIC_REPRESENTATION_TYPES,
} from './AccountFinancialsMetricsService';
import AccountCurrencyContainer from '../../acccount/AccountCurrencyContainer';
import { AccountFinancialBarChartWith2Metric, AccountFinancialPieChart } from './AccountFinancialCharts';

function MetricMainValue(props: { amount: number }) {
  const { amount } = props;
  return (
    <Text
      boldness={TEXT_BOLDNESS.BOLD}
      size={TEXT_SIZE.SIZE_1}
      color={amount < 0 ? COLOR_SHADES.DANGER : COLOR_SHADES.DARKER}
      block
      center
    >
      <AccountCurrencyContainer amount={amount} />
    </Text>
  );
}

type MetricContentProps = {
  type: METRIC_REPRESENTATION_TYPES;
  metrics: Array<{
    key: keyof AccountFinancialsMetricType;
    label: string;
  }>;
  data: AccountFinancialsMetricType;
};

function MetricContent(props: MetricContentProps) {
  const { metrics, type, data } = props;
  const firstValue = data[metrics[0].key];
  const secondValue = data[metrics[1]?.key];

  switch (type) {
    case METRIC_REPRESENTATION_TYPES.TEXT:
      return (
        <div>
          <Space vertical size={10} />
          <MetricMainValue amount={firstValue} />
        </div>
      );
    case METRIC_REPRESENTATION_TYPES.TEXT_2_METRIC:
      return (
        <div>
          <MetricMainValue amount={secondValue} />
          <Space vertical size={4} />
          <AccountFinancialBarChartWith2Metric metrics={metrics} data={data} />
        </div>
      );
    case METRIC_REPRESENTATION_TYPES.PIE_CHART:
      return (
        <div>
          <MetricMainValue amount={firstValue} />
          <Space vertical size={2} />
          <AccountFinancialPieChart metrics={metrics} data={data} />
        </div>
      );
    default:
      return <Text boldness={TEXT_BOLDNESS.BOLD}>{firstValue}</Text>;
  }
}

type MetricCardProps = {
  id: ACCOUNT_FINANCIALS_METRICS;
  data: AccountFinancialsMetricType;
};

function MetricCard(props: MetricCardProps) {
  const { id, data } = props;
  const metricDetails = ACCOUNT_FINANCIALS_METRICS_DETAILS[id];
  return (
    <Card>
      <div style={{ height: '164px' }}>
        <Space size={2}>
          <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT} block center>
            {metricDetails.title}
          </Text>
          <Space vertical size={2} />
          <ErrorBoundary>
            <MetricContent type={metricDetails.representationType} metrics={metricDetails.metrics} data={data} />
          </ErrorBoundary>
          <Space size={2} vertical />
        </Space>
      </div>
    </Card>
  );
}

type Props = {
  filters: string;
  data: AccountFinancialsMetricType;
  isLoading: boolean;
  initData: (filters) => void;
  isMobileUp: boolean;
};

export default function AccountFinancialsMetrics(props: Props): JSX.Element {
  const { data, isLoading, isMobileUp, initData, filters } = props;

  useEffect(() => {
    const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
    initData(filterObject);
  }, [filters]);
  if (isMobileUp) {
    return (
      <div>
        <Row>
          <Col size={4}>
            <MetricCard id={ACCOUNT_FINANCIALS_METRICS.QUOTE} data={data} />
          </Col>
          <Space />
          <Col size={4}>
            <MetricCard id={ACCOUNT_FINANCIALS_METRICS.PENDING_DUE_TO_COLLECT} data={data} />
          </Col>
          <Space />
          <Col size={4}>
            <MetricCard id={ACCOUNT_FINANCIALS_METRICS.REVENUE} data={data} />
          </Col>
        </Row>
        <Space vertical />
        <Row>
          <Col size={4}>
            <MetricCard id={ACCOUNT_FINANCIALS_METRICS.EXPENSES} data={data} />
          </Col>
          <Space />
          <Col size={4}>
            <MetricCard id={ACCOUNT_FINANCIALS_METRICS.MONEY_COLLECTED} data={data} />
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Row>
        <Col>
          <MetricCard id={ACCOUNT_FINANCIALS_METRICS.QUOTE} data={data} />
        </Col>
        <Space size={2} />
        <Col>
          <MetricCard id={ACCOUNT_FINANCIALS_METRICS.PENDING_DUE_TO_COLLECT} data={data} />
        </Col>
      </Row>
      <Space vertical size={2} />
      <Row>
        <Col>
          <MetricCard id={ACCOUNT_FINANCIALS_METRICS.REVENUE} data={data} />
        </Col>
        <Space size={2} />
        <Col>
          <MetricCard id={ACCOUNT_FINANCIALS_METRICS.EXPENSES} data={data} />
        </Col>
      </Row>
      <Space vertical size={2} />
      <Row>
        <Col>
          <MetricCard id={ACCOUNT_FINANCIALS_METRICS.MONEY_COLLECTED} data={data} />
        </Col>
      </Row>
    </div>
  );
}
