import { connect } from 'react-redux';
import { switchAccount } from '../AppDuck';
import { accountsArraySelector, accountSelector } from '../acccount/AccountDataDuck';
import UserAccounts from './UserAccounts';

function mapStateToProps(state) {
  return {
    accounts: accountsArraySelector(state),
    activeAccount: accountSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchAccount: (accountId) => dispatch(switchAccount(dispatch, accountId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccounts);
