import { connect } from 'react-redux';
import { CurrencyUtils } from '@premagic/utils';
import { currencyCodeSelector } from './AccountDataDuck';

function AccountCurrency(props: { currencyCode: string; amount: number; compact?: boolean }) {
  const { currencyCode, amount, compact } = props;
  return <>{CurrencyUtils.getCurrencyInFormat(amount, currencyCode, compact)}</>;
}

function mapStateToProps(
  state,
  ownProps: {
    amount: number;
    compact?: boolean;
    currencyCode?: string;
  },
) {
  const { amount = 0, compact, currencyCode } = ownProps;
  return {
    currencyCode: currencyCode || currencyCodeSelector(state),
    amount,
    compact,
  };
}

export default connect(mapStateToProps)(AccountCurrency);
