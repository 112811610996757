import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';
import { PricingPlanFeaturesService } from '@premagic/core';
import { FormErrorType } from '@premagic/myne';

import AddEventProposalPanel from './AddEventProposalPanel';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { clientItemForEventSelector, crmEventItemsSelector } from '../../events/EventsDataDuck';

const PANEL_KEY = ActionConstants.KEYS.PROPOSALS.SHOW_ADD_PANEL;
const COMMON_KEY = ActionConstants.KEYS.PROPOSALS.CREATE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId?: string;
  },
) {
  const { eventId, projectId } = ownProps;

  return {
    eventId,
    projectId,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state) as FormErrorType,
    hasProposalFeature:
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED)(state) ||
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT)(state),
    event: crmEventItemsSelector(state)[eventId] || {},
    client: clientItemForEventSelector(eventId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addProposal: (projectId: string, data) => {
      dispatch(ProposalDataDuck.addNewProposal(dispatch, projectId, data));
    },
    addProposalWithProject: (options, data) => {
      dispatch(ProposalDataDuck.addProposalWithProject(dispatch, options, data));
    },
    closePanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEventProposalPanel);
