import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import ClientWebsiteWishesLayout from './ClientWebsiteWishesLayout';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps.match.params;

  return {
    folderId: id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditBasicInfoPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteWishesLayout);
