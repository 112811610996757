import { useRef, useState } from 'react';
import { GlobalVariableService, HelpDeskService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  Emoji,
  EMOJIS,
  Form,
  FormGroup,
  FormLabel,
  FormResponseType,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_BUTTON_STYLES,
  TEXT_SIZE,
  Textarea,
  TextButton,
} from '@premagic/myne';

const CANCELLATION_REASONS = [
  'Not enough work',
  'Not able to afford',
  'Moving to another tool',
  'Slow uploading',
  'Uploading not working',
  'Premagic doesnt suit my workflow',
  'Others',
];

export function CancelSubscription() {
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const $button = useRef(null);

  function onSubmit(response: FormResponseType) {
    const { message = '', reason } = response.data as {
      message: string;
      reason: string;
    };

    HelpDeskService.raiseTicket({
      subject: 'CANCEL SUBSCRIPTION',
      email: GlobalVariableService.getGlobalRequestingUser().email || 'text@example.com',
      message: `Reason: ${reason}<br/><br/><br/> ${message}`,
      tags: ['subscription-cancellation'],
    });
    setShowSuccess(true);
  }

  return (
    <>
      <TextButton
        style={TEXT_BUTTON_STYLES.SECONDARY}
        size={BUTTON_SIZE.SM}
        onClick={() => setShowDialog(!showDialog)}
        ref={$button}
      >
        Cancel Subscription?
      </TextButton>
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        {showSuccess ? (
          <div>
            <Text
              block
              size={TEXT_SIZE.SIZE_3}
              center
              boldness={TEXT_BOLDNESS.BOLD}
              color={COLOR_SHADES.DARKER}
              primaryStyle
            >
              We are sad <Emoji name={EMOJIS.CRY} /> to hear you want to leave Premagic platform
            </Text>
            <Space vertical />
            <Text>Our support team will reach out to you about the cancellation</Text>
            <DialogFooter>
              <Button
                style={BUTTON_STYLES.CANCEL}
                onClick={() => {
                  setShowDialog(false);
                  setShowSuccess(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <div>
            <DialogTitle>Request for Cancellation</DialogTitle>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <FormLabel>Reason</FormLabel>
                <Select
                  name="reason"
                  autoFocus
                  isMulti={false}
                  options={CANCELLATION_REASONS.map((option) => ({ value: option, label: option }))}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Comments</FormLabel>
                <Textarea name="message" />
              </FormGroup>
              <DialogFooter>
                <Button
                  style={BUTTON_STYLES.CANCEL}
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  Close
                </Button>
                <Button style={BUTTON_STYLES.DANGER} type="submit">
                  Cancel
                </Button>
              </DialogFooter>
            </Form>
          </div>
        )}
      </Dialog>
    </>
  );
}
