import React, { useContext, useState } from 'react';
import { BrowserUtils, CurrencyUtils, MediaUtils, SimpleDateUtils, StringUtils, useImage } from '@premagic/utils';
import { motion } from 'framer-motion';
import ClassNames from 'classnames';
import { FileService } from '@premagic/core';
import styles from './deck.module.css';
import { Col, Position, Row, Space } from '../Grid';
import { EscapedParagraphHTMLText, Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import { LoadingDots } from '../Loading/Loading';
import { VIDEO_PLAYER_STYLES, VIDEO_TYPES, VideoJsPlayer } from '../Media';
import DeckContext, { DeckTextOnlyContext } from './DeckContext';
import {
  DECK_SLIDE_DATA_TYPE_ALIGNMENT,
  DECK_SLIDE_DATA_TYPE_STYLES,
  DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideDataType,
  DeckSlideEquipmentItemsDataType,
  DeckSlideInvoiceItemsDataType,
  DeckSlideLargeTextDataType,
  DeckSlideSubTitleDataType,
  DeckSlideTitleDataType,
} from './DeckSlideLayoutDataService';
import { Color, COLOR_SHADES } from '../Color/Color';
import { Icon } from '../Icon/Icons';
import { Button, BUTTON_SIZE, BUTTON_STYLES } from '../Button/Buttons';
import { ErrorBoundary } from '../Error/Error';
import { Modal, MODAL_SIZES } from '../Modal/Modal';
import { FormFooter } from '../Form';
import { List, ListItem } from '../List/List';

const imageDuration = 0;

function formattedDataObjectToExtractValuesFrom(id, variables) {
  let variablesToExtractFrom = {};
  if (id && variables) {
    variablesToExtractFrom = variables[String(id)] as Record<string, any>;
  } else {
    variablesToExtractFrom = variables;
  }

  return variablesToExtractFrom;
}

function flattenVariablesObject(variables) {
  const result = {};

  if (!variables) return result;

  Object.entries(variables).forEach(([key, value]) => {
    if (value && typeof value === 'object' && key !== 'proposal_variable_details') {
      Object.entries(value).forEach(([slideVar, slideVarValue]) => {
        if (slideVar in result) {
          result[slideVar] = String(Number(result[slideVar]) + Number(slideVarValue));
        } else {
          result[slideVar] = slideVarValue;
        }
      });
    }
  });

  return result;
}

function defaultVariablesAddedOnPackageSlide(variables, proposalVariableDetails, hiddenItems) {
  let defaultItemsAdded: Array<any> = [];
  const flattenedVariablesObject = flattenVariablesObject(variables);
  const allVariablesAddedOnDeck: Array<any> = Object.keys(flattenedVariablesObject);

  if (variables) {
    const defaultItems = proposalVariableDetails?.filter((item) => item.scope === 'DEFAULT');
    const displayedDefaultItems = defaultItems.filter((item) => !hiddenItems.includes(item.id));
    // @ts-ignore
    const defaultItemsWithCost = displayedDefaultItems?.filter((item) => Number(item.default_value) > 0);
    defaultItemsAdded = defaultItemsWithCost?.filter((item) => allVariablesAddedOnDeck?.includes(item.id));
  }

  return defaultItemsAdded;
}

interface DeckSlideBaseProps {
  id: string;
}

type DeckSlideImageProps = DeckSlideBaseProps & {
  colorTint?: string;
  colorTintOpacity?: number;
};

function DeckSlideImage(props: DeckSlideImageProps): JSX.Element {
  const { id, colorTint, colorTintOpacity = 0 } = props;
  const { width, height, files, uploadingFiles = {} } = useContext(DeckContext);
  const file = files[id];
  const uploadingFile = Object.values(uploadingFiles).find((item) => item.id === id);
  const {
    dynamic_image_url: src,
    image_name: alt,
    meta,
    asset_upload_status: assetUploadStatus,
    asset_processor_info: assetProcessorInfo,
  } = file || {
    meta: { ratio: 0 },
  };

  const imageToLoad = MediaUtils.getDynamicImageURL(src, MediaUtils.DYNAMIC_IMAGE_TYPES.HIGHLIGHT, {
    width,
    height,
    ratio: meta?.ratio,
  });

  const { src: loadedImage, hasError, isLoading } = useImage(imageToLoad);

  if (!alt) return <div>Image not in files</div>;
  if (uploadingFile) {
    return (
      <div className={ClassNames(styles.slide__image_container)}>
        <img
          className={ClassNames(styles.slide__image)}
          key={id}
          draggable={false}
          onContextMenu={(e) => BrowserUtils.preventContextMenu(e)}
          src={uploadingFile.fileBlob}
          alt={alt}
        />
        <div
          className={ClassNames(styles.slide__image_color_tint)}
          style={{ background: colorTint, opacity: colorTintOpacity }}
        />
      </div>
    );
  }
  if (assetUploadStatus && assetUploadStatus !== FileService.FILE_UPLOAD_STAGES.DONE) {
    const detailedError = FileService.FILE_UPLOAD_STAGES_DETAILS[assetUploadStatus];
    return (
      <Row center fullHeight>
        <div>
          <Row center>
            <Color shade={COLOR_SHADES.LIGHT}>
              <Icon name={detailedError.icon} />
            </Color>
          </Row>
          <Text block center size={TEXT_SIZE.SIZE_3}>
            {detailedError.title}
          </Text>
          <Text block center>
            {detailedError.message}
          </Text>
          {detailedError.waitTime && (
            <Text muted size={TEXT_SIZE.SIZE_5} block center>
              {detailedError.waitTime(assetProcessorInfo)}
            </Text>
          )}
        </div>
      </Row>
    );
  }

  if (isLoading)
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: 'easeIn', duration: 0.3 }}>
        <Position align="bottom">
          <LoadingDots size="sm" />
        </Position>
      </motion.div>
    );

  if (hasError)
    return (
      <div id={id}>
        <Row center fullHeight>
          <Text>Oops... could not load the photo!</Text>
        </Row>
      </div>
    );

  return (
    <div className={ClassNames(styles.slide__image_container)}>
      <img
        className={ClassNames(styles.slide__image)}
        key={id}
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0, transition: { ease: 'easeIn', duration: imageDuration } }}
        // transition={{ ease: 'easeIn', duration: imageDuration }}
        draggable={false}
        onContextMenu={(e) => BrowserUtils.preventContextMenu(e)}
        src={loadedImage}
        alt={alt}
      />
      <div
        className={ClassNames(styles.slide__image_color_tint)}
        style={{ background: colorTint, opacity: colorTintOpacity }}
      />
    </div>
  );
}

type DeckSlideVideoProps = DeckSlideBaseProps & {
  colorTint?: string;
  colorTintOpacity?: number;
};

function DeckSlideVideo(props: DeckSlideVideoProps): JSX.Element {
  const { id, colorTint, colorTintOpacity = 0 } = props;
  const { toggleVideoPlaying, files, uploadingFiles = {} } = useContext(DeckContext);
  const file = files[id];
  const uploadingFile = Object.values(uploadingFiles).find((item) => item.id === id);
  const {
    location: src,
    asset_upload_status: assetUploadStatus,
    asset_processor_info: assetProcessorInfo,
  } = file || {};
  if (uploadingFile && uploadingFile.fileBlob) {
    return (
      <div className={ClassNames(styles.slide__video_container)}>
        <motion.div
          key={id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { ease: 'easeIn', duration: imageDuration } }}
          transition={{ ease: 'easeIn', duration: imageDuration }}
          className={ClassNames(styles.slide__video)}
        >
          <VideoJsPlayer
            src={uploadingFile.fileBlob}
            autoplay
            autoplayFromStart
            onPlay={() => toggleVideoPlaying(false)}
            onEnded={() => toggleVideoPlaying(true)}
            style={VIDEO_PLAYER_STYLES.HIGH_QUALITY_INLINE}
            type={VIDEO_TYPES.DEFAULT}
          />
        </motion.div>
        <div
          className={ClassNames(styles.slide__video_color_tint)}
          style={{ background: colorTint, opacity: colorTintOpacity }}
        />
      </div>
    );
  }

  if (assetUploadStatus && assetUploadStatus !== FileService.FILE_UPLOAD_STAGES.DONE) {
    const detailedError = FileService.FILE_UPLOAD_STAGES_DETAILS[assetUploadStatus];
    return (
      <Row center fullHeight>
        <div>
          <Row center>
            <Color shade={COLOR_SHADES.LIGHT}>
              <Icon name={detailedError.icon} />
            </Color>
          </Row>
          <Text block center size={TEXT_SIZE.SIZE_3}>
            {detailedError.title}
          </Text>
          <Text block center>
            {detailedError.message}
          </Text>
          {detailedError.waitTime && (
            <Text muted size={TEXT_SIZE.SIZE_5} block center>
              {detailedError.waitTime(assetProcessorInfo)}
            </Text>
          )}
        </div>
      </Row>
    );
  }

  if (!src) return <div>Video not in files</div>;
  return (
    <div className={ClassNames(styles.slide__video_container)}>
      <motion.div
        key={id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { ease: 'easeIn', duration: imageDuration } }}
        transition={{ ease: 'easeIn', duration: imageDuration }}
        className={ClassNames(styles.slide__video)}
      >
        <VideoJsPlayer
          src={src}
          autoplay
          autoplayFromStart
          onPlay={() => toggleVideoPlaying(false)}
          onEnded={() => toggleVideoPlaying(true)}
          style={VIDEO_PLAYER_STYLES.HIGH_QUALITY_INLINE}
          type={VIDEO_TYPES.STREAM}
        />
      </motion.div>
      <div
        className={ClassNames(styles.slide__video_color_tint)}
        style={{ background: colorTint, opacity: colorTintOpacity }}
      />
    </div>
  );
}

export enum SLIDE_GENERIC_STYLES {
  WHITE = 'white',
  BLACK = 'black',
}
interface DeckSlideGenericProps extends DeckSlideBaseProps {
  children: React.ReactNode;
  style: SLIDE_GENERIC_STYLES;
}

function DeckSlideGeneric(props: DeckSlideGenericProps): JSX.Element {
  const { id, children, style } = props;
  return (
    <motion.div
      key={id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { ease: 'easeIn', duration: imageDuration } }}
      transition={{ ease: 'easeIn', duration: imageDuration }}
      className={ClassNames(styles.slide, styles['slide--generic'], styles[`slide--generic--style-${style}`])}
    >
      {children}
    </motion.div>
  );
}

interface DeckSlideBlockSlideDownProps {
  children: React.ReactNode;
  position: 1 | 2 | 3;
}

const POSITION_DELAY = {
  1: 0.5,
  2: 1,
  3: 1.5,
};
export function DeckSlideBlockSlideDown(props: DeckSlideBlockSlideDownProps): JSX.Element {
  const { children, position } = props;
  return (
    <motion.div
      initial={{ opacity: 0, y: '-100%' }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ ease: 'easeIn', delay: POSITION_DELAY[position], duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

interface DeckSlideBlockSlideUpProps {
  children: React.ReactNode;
  position: 1 | 2 | 3;
}

function DeckSlideBlockSlideUp(props: DeckSlideBlockSlideUpProps): JSX.Element {
  const { children, position } = props;
  return (
    <motion.div
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: 'spring', delay: POSITION_DELAY[position], duration: 1 }}
    >
      {children}
    </motion.div>
  );
}

function getDeckSlideBackgroundType(
  data: DeckSlideDataType,
):
  | DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO
  | DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR
  | DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE {
  if (data[DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO]?.value) return DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO;
  if (data[DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE]?.value) return DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE;
  return DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR;
}

export enum DECK_SLIDE_BACKGROUND_STYLE {
  DEFAULT = 'default',
}

interface DeckSlideBackgroundProps {
  slideData: DeckSlideDataType;
  style?: DECK_SLIDE_BACKGROUND_STYLE;
}

// data?: DeckSlideBackgroundDataType | DeckSlideBackgroundColorDataType;
export function DeckSlideBackground(props: DeckSlideBackgroundProps) {
  const { slideData, style } = props;
  const type = getDeckSlideBackgroundType(slideData);
  const dataForType = slideData[type];

  const files = dataForType?.value ? Object.values(dataForType?.value) : [];
  const firstFile = files[0];
  const color = slideData[DECK_SLIDE_DATA_TYPES.BACKGROUND_COLOR]?.value || '#000';

  const colorTintOpacity = slideData[DECK_SLIDE_DATA_TYPES.COLOR_TINT_OPACITY]?.value || 0;

  return (
    <div
      className={ClassNames(
        styles.slide__background,
        styles[`slide__background--type-${type}`],
        styles[`slide__background--style-${style}`],
      )}
      style={{ ['--bg-color' as string]: color }}
    >
      {type === DECK_SLIDE_DATA_TYPES.BACKGROUND_IMAGE && firstFile && (
        <DeckSlideImage id={firstFile.id} colorTint={color} colorTintOpacity={colorTintOpacity} />
      )}
      {type === DECK_SLIDE_DATA_TYPES.BACKGROUND_VIDEO && firstFile && (
        <DeckSlideVideo id={firstFile.id} colorTint={color} colorTintOpacity={colorTintOpacity} />
      )}
    </div>
  );
}

export enum DECK_SLIDE_SECTION_AREAS {
  TITLE = 'title',
  SUB_TITLE = 'sub-title',
  CONTENT = 'content',
}

export enum DECK_SLIDE_SECTION_ANIMATION_TYPE {
  FIRST = 'first',
  SECOND = 'second',
}
const DECK_SLIDE_SECTION_ANIMATION_TYPE_DETAILS: Record<
  DECK_SLIDE_SECTION_ANIMATION_TYPE,
  {
    delay: number;
    duration: number;
  }
> = {
  [DECK_SLIDE_SECTION_ANIMATION_TYPE.FIRST]: {
    delay: 0.3,
    duration: 0.5,
  },
  [DECK_SLIDE_SECTION_ANIMATION_TYPE.SECOND]: {
    delay: 0.8,
    duration: 0.5,
  },
};

interface DeckSlideSectionProps {
  children: React.ReactNode;
  value?: string;
  align?: DECK_SLIDE_DATA_TYPE_ALIGNMENT; // This is to handle the default styles
  textAlign?: DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT;
  style?: DECK_SLIDE_DATA_TYPE_STYLES;
  scale?: number;
  area: DECK_SLIDE_SECTION_AREAS;
  animationType: DECK_SLIDE_SECTION_ANIMATION_TYPE;
}

export function DeckSlideSection(props: DeckSlideSectionProps) {
  const {
    style,
    area,
    align,
    children,
    animationType,
    value,
    scale,
    textAlign = DECK_SLIDE_DATA_TYPE_TEXT_ALIGNMENT.LEFT,
  } = props;
  const hasContent = StringUtils.getTextFromHTMLString(value).trim().length > 0;
  if (!hasContent) return null;

  const animationDetails = DECK_SLIDE_SECTION_ANIMATION_TYPE_DETAILS[animationType];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: animationDetails.duration, delay: animationDetails.delay }}
      style={{
        ['--slide-section-scale' as string]: scale,
      }}
      className={ClassNames(
        styles.slide__section,
        styles[`slide__section--area-${area}`],
        styles[`slide__section--align-${align}`],
        styles[`slide__section--text-align-${textAlign}`],
        styles[`slide__section--style-${style}`],
      )}
    >
      {children}
    </motion.div>
  );
}

interface DeckSlideSimpleTextProps {
  data?: DeckSlideTitleDataType;
  color?: COLOR_SHADES;
  boldness?: TEXT_BOLDNESS;
  size?: TEXT_SIZE;
  showInHtml?: boolean;
  id: string | number;
}

export function DeckSlideSimpleTextForTextOnly(props: DeckSlideSimpleTextProps) {
  const { data = {}, color, boldness, size, showInHtml = true, id } = props;
  const { variables } = useContext(DeckTextOnlyContext);
  const messageWithVariable = StringUtils.replaceVariablesInString(
    data.value || '',
    formattedDataObjectToExtractValuesFrom(id, variables),
  );

  if (showInHtml) {
    return (
      <Text color={color} boldness={boldness} size={size} block>
        <EscapedParagraphHTMLText value={messageWithVariable} htmlSanitizeLevel="full" forPrint />
      </Text>
    );
  }

  const simpleText = StringUtils.getTextFromHTMLString(messageWithVariable);
  return (
    <Text color={color} boldness={boldness} size={size} block>
      {simpleText}
    </Text>
  );
}

interface DeckSlideTitleProps {
  data?: DeckSlideTitleDataType;
}

export function DeckSlideTitle(props: DeckSlideTitleProps) {
  const { data = {} } = props;
  const { fonts, variables } = useContext(DeckContext);
  const messageWithVariable = StringUtils.replaceVariablesInString(data.value || '', variables);
  return (
    <div
      className={ClassNames(styles.slide__title)}
      style={{
        ['--slide-text-color' as string]: data.color,
        ['--slide-font-family' as string]: fonts.primary,
      }}
    >
      <EscapedParagraphHTMLText value={messageWithVariable} />
    </div>
  );
}

interface DeckSlideTitleSectionProps {
  data?: DeckSlideTitleDataType;
}

export function DeckSlideTitleSection(props: DeckSlideTitleSectionProps) {
  const { data = {} } = props;
  const {
    style = DECK_SLIDE_DATA_TYPE_STYLES.NONE,
    align = DECK_SLIDE_DATA_TYPE_ALIGNMENT.TOP,
    value,
    scale = 1,
    text_align: textAlign,
  } = data;

  return (
    <DeckSlideSection
      animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.FIRST}
      area={DECK_SLIDE_SECTION_AREAS.TITLE}
      align={align}
      style={style}
      scale={scale}
      textAlign={textAlign}
      value={value}
    >
      <DeckSlideTitle data={data} />
    </DeckSlideSection>
  );
}

interface DeckSlideSubTitleProps {
  data?: DeckSlideSubTitleDataType;
}

export function DeckSlideSubTitle(props: DeckSlideSubTitleProps): JSX.Element | null {
  const { data = {} } = props;
  const { fonts, variables } = useContext(DeckContext);
  const messageWithVariable = StringUtils.replaceVariablesInString(data.value || '', variables);

  return (
    <div
      className={ClassNames(styles['slide__sub-title'])}
      style={{
        ['--slide-text-color' as string]: data.color,
        ['--slide-font-family' as string]: fonts.secondary,
      }}
    >
      <EscapedParagraphHTMLText value={messageWithVariable} />
    </div>
  );
}

interface DeckSlideSubTitleSectionProps {
  data?: DeckSlideSubTitleDataType;
}

export function DeckSlideSubTitleSection(props: DeckSlideSubTitleSectionProps): JSX.Element | null {
  const { data = {} } = props;
  const {
    style = DECK_SLIDE_DATA_TYPE_STYLES.NONE,
    align = DECK_SLIDE_DATA_TYPE_ALIGNMENT.TOP,
    value,
    scale = 1,
    text_align: textAlign,
  } = data;

  return (
    <DeckSlideSection
      animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.FIRST}
      area={DECK_SLIDE_SECTION_AREAS.SUB_TITLE}
      align={align}
      style={style}
      scale={scale}
      textAlign={textAlign}
      value={value}
    >
      <DeckSlideSubTitle data={data} />
    </DeckSlideSection>
  );
}

interface DeckSlideLargeTextProps {
  data?: DeckSlideLargeTextDataType;
  id?: number | string;
}

export function DeckSlideLargeText(props: DeckSlideLargeTextProps): JSX.Element | null {
  const { data = {}, id } = props;
  const { fonts, variables } = useContext(DeckContext);
  const { value } = data;

  const messageWithVariable = StringUtils.replaceVariablesInString(
    value || '',
    formattedDataObjectToExtractValuesFrom(id, variables),
  );

  return (
    <div
      className={ClassNames(styles['slide__large-text'])}
      style={{
        ['--slide-text-color' as string]: data.color,
        ['--slide-font-family' as string]: fonts.secondary,
      }}
    >
      <EscapedParagraphHTMLText value={messageWithVariable} />
    </div>
  );
}

interface DeckSlideLargeTextSectionProps {
  data?: DeckSlideLargeTextDataType;
  id?: number;
}

export function DeckSlideLargeTextSection(props: DeckSlideLargeTextSectionProps): JSX.Element | null {
  const { data = {}, id } = props;

  const {
    style = DECK_SLIDE_DATA_TYPE_STYLES.GRADIENT_TO_TOP,
    align = DECK_SLIDE_DATA_TYPE_ALIGNMENT.BOTTOM,
    scale = 1,
    text_align: textAlign,
    value,
  } = data;

  return (
    <DeckSlideSection
      animationType={DECK_SLIDE_SECTION_ANIMATION_TYPE.SECOND}
      area={DECK_SLIDE_SECTION_AREAS.CONTENT}
      align={align}
      style={style}
      scale={scale}
      textAlign={textAlign}
      value={value}
    >
      <DeckSlideLargeText data={data} id={id} />
    </DeckSlideSection>
  );
}

export enum DECK_SLIDE_BUTTONS_STYLE {
  DEFAULT = 'default',
}

interface DeckSlideButtonsProps {
  buttons: Array<{
    title: string | React.ReactNode;
    key: string;
    style: BUTTON_STYLES;
    size?: BUTTON_SIZE;
  }>;
  style?: DECK_SLIDE_BUTTONS_STYLE;
}

export function DeckSlideButtons(props: DeckSlideButtonsProps): JSX.Element | null {
  const { buttons, style = DECK_SLIDE_BUTTONS_STYLE.DEFAULT } = props;
  const { fonts, onButtonActions } = useContext(DeckContext);
  return (
    <div className={ClassNames(styles.slide__buttons, styles[`slide__buttons--style-${style}`])}>
      {buttons.map((button) => (
        <Button
          key={button.key}
          className={styles.slide__buttons__button}
          cssStyle={{
            ['--slide-font-family' as string]: fonts.secondary,
          }}
          onClick={() => onButtonActions(button.key)}
          style={button.style}
          size={button.size}
        >
          {button.title}
        </Button>
      ))}
    </div>
  );
}

export function DeckSlideExpiryInfo() {
  const { fonts, deckAdditionalData } = useContext(DeckContext);
  const date = deckAdditionalData?.expiryDate;
  return (
    <ErrorBoundary>
      <div
        className={ClassNames(styles.slide__text, styles['slide__text-sm'])}
        style={{
          ['--slide-font-family' as string]: fonts.secondary,
        }}
      >
        {(() => {
          if (date && SimpleDateUtils.isPastDate(date)) {
            return 'The proposal has expired!';
          }

          return `The proposal will expiry ${date ? SimpleDateUtils.fromNowDate(date, true) : '--Date--'}`;
        })()}
      </div>
    </ErrorBoundary>
  );
}
interface DeckSlideAcceptTermsAndConditionProps {
  data?: DeckSlideLargeTextDataType;
  hasAgreed?: boolean;
}

export function DeckSlideAcceptTermsAndCondition(props: DeckSlideAcceptTermsAndConditionProps) {
  const { data = {}, hasAgreed } = props;
  const { fonts } = useContext(DeckContext);
  const [showTerms, setShowTerms] = useState(false);
  const hasContent = StringUtils.getTextFromHTMLString(data.value).trim().length > 0;
  if (!hasContent) {
    return null;
  }
  return (
    <ErrorBoundary>
      <div
        className={ClassNames(styles['slide__large-text'])}
        style={{
          ['--slide-text-color' as string]: data.color,
          ['--slide-font-family' as string]: fonts.secondary,
        }}
      >
        {hasAgreed ? 'and agreed to the ' : 'By accepting you agree to the '}
        <button
          type="button"
          className={ClassNames(styles['slide__button--link'])}
          onClick={() => setShowTerms(!showTerms)}
        >
          terms & conditions
        </button>{' '}
        of the proposal
      </div>
      <Modal show={showTerms} close={() => setShowTerms(false)} size={MODAL_SIZES.LG}>
        <DeckSlideSubTitle data={{ value: 'Terms & conditions', color: '#000' }} />
        <Space vertical />
        <DeckSlideLargeText data={{ ...data, color: '#000' }} />
        <FormFooter>
          <Button onClick={() => setShowTerms(false)}>Close</Button>
        </FormFooter>
      </Modal>
    </ErrorBoundary>
  );
}

interface DeckSlideInvoiceItemsProps {
  id: number;
  data?: DeckSlideInvoiceItemsDataType;
}

function getValueBasedOnType(variables: Record<string, string | number | Array<any>>, item, currencyCode = 'INR') {
  if (!item) return '';
  const value = (variables && variables[item?.id]) || '0';
  switch (item.type) {
    case 'percentage':
    case 'discount-percentage':
    case 'tax-percentage':
      return `${value}%`;
    case 'discount-currency':
    case 'currency':
      return CurrencyUtils.getCurrencyInFormat(Number(value), currencyCode);
    default:
      return value || '--';
  }
}

export function DeckSlideInvoiceItems(props: DeckSlideInvoiceItemsProps) {
  const { id, data = {} } = props;
  const items = data.value || [];
  const hiddenItems = data?.hidden_items || [];
  const { fonts, variables, deckAdditionalData, proposalVariableDetails } = useContext(DeckContext);
  const { currencyCode, packageSlideTotal } = deckAdditionalData || {};

  const defaultItemsAdded: Array<any> = defaultVariablesAddedOnPackageSlide(
    variables,
    proposalVariableDetails,
    hiddenItems,
  );
  const flattenedVariablesObject = flattenVariablesObject(variables);

  return (
    <div
      className={ClassNames(styles.slide__text)}
      style={{
        ['--slide-font-family' as string]: fonts.secondary,
      }}
    >
      {defaultItemsAdded?.length > 0 &&
        defaultItemsAdded?.map((item) => {
          const totalCostOfItem = Number(item?.default_value) * Number(flattenedVariablesObject?.[item?.id]) || 0;
          return (
            <div key={item.id}>
              <Row vcenter>
                <Col size={8}>{item.name}</Col>
                <Col rightAlighed size={null}>
                  <div className={ClassNames(styles['slide__text--bold'])}>
                    {CurrencyUtils.getCurrencyInFormat(totalCostOfItem, (currencyCode || 'INR') as string)}
                  </div>
                </Col>
              </Row>
              <Space vertical />
            </div>
          );
        })}
      {items.map((item) => (
        <div key={item.id}>
          <Row vcenter>
            <Col size={8}>{item.name}</Col>
            <Col rightAlighed size={null}>
              <div className={ClassNames(styles['slide__text--bold'])}>
                {getValueBasedOnType(variables, item, currencyCode)}
              </div>
            </Col>
          </Row>
          <Space vertical />
        </div>
      ))}
      <Space vertical size={8} />
      <Row>
        <Col rightAlighed size={null}>
          <div className={ClassNames(styles['slide__text--bold'], styles['slide__text-md'])}>
            {CurrencyUtils.getCurrencyInFormat(Number(packageSlideTotal?.[id] || 0), (currencyCode || 'INR') as string)}
          </div>
        </Col>
      </Row>
    </div>
  );
}

interface DeckSlideInvoiceItemsTextOnlyProps {
  id: number;
  data?: DeckSlideInvoiceItemsDataType;
}

export function DeckSlideInvoiceItemsTextOnly(props: DeckSlideInvoiceItemsTextOnlyProps) {
  const { id, data = {} } = props;
  const items = data.value || [];
  const { variables, deckAdditionalData, proposalVariableDetails } = useContext(DeckTextOnlyContext);
  const { currencyCode, packageSlideTotal } = deckAdditionalData || {};
  const hiddenItems = data?.hidden_items || [];
  const defaultItemsAdded: Array<any> = defaultVariablesAddedOnPackageSlide(
    variables,
    proposalVariableDetails,
    hiddenItems,
  );
  const flattenedVariablesObject = flattenVariablesObject(variables);

  return (
    <List>
      {defaultItemsAdded?.length > 0 &&
        defaultItemsAdded?.map((item) => {
          const totalCostOfItem = Number(item?.default_value) * Number(flattenedVariablesObject?.[item?.id]) || 0;
          return (
            <ListItem key={item.id}>
              <Col size={8}>
                <Text>{item.name}</Text>
              </Col>
              <Col rightAlighed size={null}>
                <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                  {CurrencyUtils.getCurrencyInFormat(totalCostOfItem, (currencyCode || 'INR') as string)}
                </Text>
              </Col>
            </ListItem>
          );
        })}
      {items.map((item) => (
        <ListItem key={item.id}>
          <Col size={8}>
            <Text>{item.name}</Text>
          </Col>
          <Col rightAlighed size={null}>
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {getValueBasedOnType(variables, item, currencyCode)}
            </Text>
          </Col>
        </ListItem>
      ))}
      <Space>
        <Row>
          <Col rightAlighed size={null}>
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_2}>
              {CurrencyUtils.getCurrencyInFormat(
                Number(packageSlideTotal?.[id] || 0),
                (currencyCode || 'INR') as string,
              )}
            </Text>
          </Col>
        </Row>
      </Space>
    </List>
  );
}

interface DeckSlideEquipmentsItemsProps {
  id: number;
  data?: DeckSlideEquipmentItemsDataType;
}

export function DeckSlideEquipmentsItems(props: DeckSlideEquipmentsItemsProps) {
  const { id, data } = props;
  const { fonts } = useContext(DeckContext);
  const items = data?.value || [];
  return (
    <div
      className={ClassNames(styles.slide__text)}
      style={{
        ['--slide-font-family' as string]: fonts.secondary,
      }}
    >
      {items.map((item) => (
        <div key={item.id}>
          {item.name}
          <Space vertical />
        </div>
      ))}
    </div>
  );
}
interface DeckSlideEquipmentsItemsTextOnlyProps {
  id: number;
  data?: DeckSlideEquipmentItemsDataType;
}

export function DeckSlideEquipmentsItemsTextOnly(props: DeckSlideEquipmentsItemsTextOnlyProps) {
  const { id, data } = props;
  const items = data?.value || [];
  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.id}>
          <Text>{item.name}</Text>
        </ListItem>
      ))}
    </List>
  );
}
