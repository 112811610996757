import { Col, Input, Space, SubTitle } from '@premagic/myne';
import { usePhotoSliderWidgetData } from '../../../context/PhotoSliderWidgetContext';

export default function PhotoSliderWidgetNumOfImages() {
  const { customizations } = usePhotoSliderWidgetData();

  return (
    <>
      <SubTitle>Number of images to display</SubTitle>
      <Space vertical size={2} />
      <Col size={4}>
        <Input type="number" name="num_of_images" defaultValue={customizations?.num_of_images} />
      </Col>
    </>
  );
}
