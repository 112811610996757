import { wrap } from 'comlink';

let instance = null;

async function loadWorker() {
  // const response = await fetch(new URL('./worker.js', import.meta.url), {
  //   credentials: "same-origin",
  // });
  const response = await fetch('https://asts.premagic.com/c/mozjpeg-worker.js');

  const text = await response.text();
  const blob = new Blob([text], { type: 'application/javascript' });
  const url = URL.createObjectURL(blob);
  const worker = new Worker(url);
  const workerWrapper = wrap(worker);
  return workerWrapper;
}

export async function init() {
  if (instance === null) {
    try {
      const JpegConverter = await loadWorker();
      const converter = await new JpegConverter();
      await converter.init();
      instance = converter;
    }
    catch (e) {
      console.log("error while loading", e);
    }
  }
}

export function convert(data, orientation, quality) {
  if (!instance) return null;

  return instance.convert(data, orientation, quality);
}
