import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import ClientWebsiteMetricsInfoFormContainer from './ClientWebsiteMetricsInfoFormContainer';

type Props = {
  id: string;
  projectId: string;
  saveClientWebsite: (id: string, projectId: string, data: any, navigateToUrl: string) => void;
  isLoading: boolean;
  errors?: Record<string, string>;
  showPanel: boolean;
  closePanel: () => void;
};

export default function EditClientWebsiteMetrics(props: Props): JSX.Element {
  const { id, projectId, saveClientWebsite, isLoading, errors, showPanel, closePanel } = props;
  const clientWebsiteDetailsPageUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, {
    id,
    projectId,
  });

  function onSubmit(formResponse) {
    saveClientWebsite(id, projectId, { data: { data: { metrics: formResponse.data } } }, clientWebsiteDetailsPageUrl);
  }

  return (
    <WindowPanel show={showPanel}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Event Metrics</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form onSubmit={onSubmit} errors={errors}>
          <ClientWebsiteMetricsInfoFormContainer id={id} />

          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
