import { EventAttendeesService } from '@premagic/core';

import { Button, BUTTON_SIZE, BUTTON_STYLES, Icon, ICON_SIZES, LoadingDots, Row, Space } from '@premagic/myne';
import React from 'react';
import MESSAGES from '../../../../../../../common/Messages';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import AttendeeApprovalStatusContainer from './AttendeeApprovalStatusContainer';

type Props = {
  attendee: EventAttendeesService.EventAttendeeType;
  projectId: string;
  id: string;
  hasPermissionsToManageAttendees: boolean;
  hasEnabledApprovalWorkflow: boolean;
  updateStatus: (
    options: { projectId: string; attendeeId: string },
    status: EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS,
  ) => void;
  isSaving: boolean;
};

export default function AttendeeRegistrationApprovalButtons(props: Props): JSX.Element | null {
  const {
    attendee,
    id,
    projectId,
    hasPermissionsToManageAttendees,
    hasEnabledApprovalWorkflow,
    updateStatus,
    isSaving,
  } = props;
  const { approval: status } = attendee;

  if (!hasEnabledApprovalWorkflow) return null;
  if (!status) return null;
  if (isSaving) {
    return (
      <Row>
        <LoadingDots size={ICON_SIZES.XS} />
        <Space size={12} />
      </Row>
    );
  }

  if (status === EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.PENDING) {
    return (
      <div>
        <Button
          size={BUTTON_SIZE.SM}
          style={BUTTON_STYLES.PILL}
          disabled={!hasPermissionsToManageAttendees}
          disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
          isLoading={isSaving}
          onClick={() =>
            updateStatus({ projectId, attendeeId: id }, EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.APPROVED)
          }
        >
          <Icon name="check" size={ICON_SIZES.SM} /> <Space size={1} />
          Approve
        </Button>
        <Button
          size={BUTTON_SIZE.SM}
          style={BUTTON_STYLES.PILL}
          disabled={!hasPermissionsToManageAttendees}
          disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_ATTENDEES_MANAGEMENT]}
          isLoading={isSaving}
          onClick={() =>
            updateStatus({ projectId, attendeeId: id }, EventAttendeesService.EVENT_ATTENDEE_APPROVAL_STATUS.REJECTED)
          }
        >
          <Icon name="x" size={ICON_SIZES.SM} /> <Space size={1} />
          Reject
        </Button>
      </div>
    );
  }
  return <AttendeeApprovalStatusContainer id={id} />;
}
