export enum DEVICES {
  IPHONE_SE = 'iPhone 5/SE',
  IPHONE_X = 'iPhone X',
  IPHONE_13_PRO = 'iPhone 13 Pro',
  PIXEL_2 = 'Pixel 2',
  PIXEL_2_PRO = 'Pixel 2 XL',
  MOTO_G4 = 'Moto G4',
}

export const DEVICE_DETAILS: Record<DEVICES, { width: number; height: number }> = {
  [DEVICES.IPHONE_SE]: { width: 320, height: 568 },
  [DEVICES.IPHONE_X]: { width: 375, height: 812 },
  [DEVICES.IPHONE_13_PRO]: { width: 390, height: 844 },
  [DEVICES.PIXEL_2]: { width: 411, height: 731 },
  [DEVICES.PIXEL_2_PRO]: { width: 411, height: 823 },
  [DEVICES.MOTO_G4]: { width: 360, height: 640 },
};

export const deviceOptions: Array<{
  label: DEVICES;
  value: DEVICES;
}> = Object.keys(DEVICE_DETAILS).map((key) => ({
  label: key as DEVICES,
  value: key as DEVICES,
}));
