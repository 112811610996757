import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { reportsProjectsDataValuesSelector, reportsProjectsSummarySelector } from '../ReportProjectsDataDuck';
import { fetchProjectInsightData } from '../ReportsPageDuck';
import ReportInsightPage from './ReportInsightPage';
import { isFeatureLeadEnabledSelector } from '../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  const projects = reportsProjectsDataValuesSelector(state);

  return {
    isLoading: isLoadingSelector(LOADINGS.REPORTS.FETCH_ALL)(state),
    projects,
    summaryStats: reportsProjectsSummarySelector(state),
    hasLeadsModule: isFeatureLeadEnabledSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchProjectInsightData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportInsightPage);
