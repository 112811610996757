import { ActionTypeUtils, LocalCacheUtils } from '@premagic/utils';
import { BADGE_PUNCH_HOLE_STYLE } from '@premagic/poster-creator';
import { EventAttendeesService } from '@premagic/core';
import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { pagesSelector } from '../../reducers/selectors';

const CACHE_KEY = 'poster_user';

const getActionType = ActionTypeUtils.getActionTypeFunction('POSTER_CREATOR', true);

export const setSelectedPosterWidget = createAction(
  getActionType('SELECTED_WIDGET', 'SET'),
  (dispatch: Dispatch, widgetId: string | undefined) => widgetId,
);

export const setShowContextMenu = createAction(
  getActionType('CONTEXT_MENU', 'SHOW'),
  (dispatch: Dispatch, position) => position,
);

export const setPunchHoleStyle = createAction(
  getActionType('PUNCH_HOLE_STYLE', 'SET'),
  (dispatch: Dispatch, style: string) => style,
);
export enum EVENT_POSTER_VIEW_OPTIONS {
  RULER,
}
export const setViewOptions = createAction(
  getActionType('VIEW_OPTIONS', 'SET'),
  (dispatch: Dispatch, options: Array<EVENT_POSTER_VIEW_OPTIONS>) => options,
);

export enum EVENT_POSTER_TAB {
  DESIGN = 'DESIGN',
  LAYERS = 'LAYERS',
}
export const setActiveSidebarView = createAction(
  getActionType('SIDEBAR_VIEW', 'SET'),
  (dispatch: Dispatch, view: EVENT_POSTER_TAB) => view,
);

export const setDummyUser = createAction(getActionType('DUMMY_USER', 'SET'), (dispatch: Dispatch, user) => {
  LocalCacheUtils.setCache(CACHE_KEY, user);
  return user;
});

type PosterCreatorType = {
  selectedWidget?: string;
  punchHoleStyle: BADGE_PUNCH_HOLE_STYLE;
  dummyUser?: EventAttendeesService.EventAttendeeType;
  contextMenuPosition: {
    top: string;
    left: string;
  };
  sidebarView: EVENT_POSTER_TAB;
  viewOptions: Array<EVENT_POSTER_VIEW_OPTIONS>;
};
const initialState = {
  selectedWidget: undefined,
  punchHoleStyle: undefined,
  dummyUser: LocalCacheUtils.getCache(CACHE_KEY),
  contextMenuPosition: undefined,
  sidebarView: EVENT_POSTER_TAB.DESIGN,
  viewOptions: [],
};

export default handleActions(
  {
    [setSelectedPosterWidget.toString()]: (state, action: { payload }) => ({
      ...state,
      selectedWidget: action.payload,
    }),
    [setPunchHoleStyle.toString()]: (state, action: { payload }) => ({
      ...state,
      punchHoleStyle: action.payload,
    }),
    [setDummyUser.toString()]: (state, action: { payload }) => ({
      ...state,
      dummyUser: action.payload,
    }),
    [setShowContextMenu.toString()]: (state, action: { payload }) => ({
      ...state,
      contextMenuPosition: action.payload,
    }),
    [setActiveSidebarView.toString()]: (state, action: { payload }) => ({
      ...state,
      sidebarView: action.payload,
    }),
    [setViewOptions.toString()]: (state, action: { payload }) => ({
      ...state,
      viewOptions: action.payload,
    }),
  },
  initialState,
);

const eventPosterCreatorDataSelector = createSelector(
  pagesSelector,
  (state) => state.eventPoster.creator as PosterCreatorType,
);

export const selectedEventPosterWidgetSelector = createSelector(
  eventPosterCreatorDataSelector,
  (state) => state.selectedWidget,
);
export const contextMenuPositionSelector = createSelector(
  eventPosterCreatorDataSelector,
  (state) => state.contextMenuPosition,
);

export const selectedPunchHoleStyleSelector = createSelector(
  eventPosterCreatorDataSelector,
  (state) => state.punchHoleStyle,
);

export const selectedDummyUserSelector = createSelector(eventPosterCreatorDataSelector, (state) => state.dummyUser);

export const activeSidebarViewSelector = createSelector(eventPosterCreatorDataSelector, (state) => state.sidebarView);

export const viewOptionsSelector = createSelector(eventPosterCreatorDataSelector, (state) => state.viewOptions);
