import { PaginationFiltersType } from '../../../../services/PaginationService';

export enum DAM_GALLERY_SORT_BY {
  RELEVANCE = 'RELEVANCE',
  FOLDER = 'FOLDER',
}

export const DAM_GALLERY_SORT_BY_OPTIONS = {
  [DAM_GALLERY_SORT_BY.RELEVANCE]: {
    value: DAM_GALLERY_SORT_BY.RELEVANCE,
    label: 'Relevance',
  },
  [DAM_GALLERY_SORT_BY.FOLDER]: {
    value: DAM_GALLERY_SORT_BY.FOLDER,
    label: 'Folder',
  },
};

export enum DAM_LIBRARY_FILTER_KEYS {
  PROJECT = 'project_id',
  FACE_ID = 'face_id',
  KEY_PEOPLE = 'key_people',
  TAGS = 'tags',
  DATE_FROM = 'date_from',
  DATE_END = 'date_end',
  SORT_BY = 'sort_by',
}

export type DAMLibraryFiltersType = {
  [DAM_LIBRARY_FILTER_KEYS.PROJECT]?: Array<string>;
  [DAM_LIBRARY_FILTER_KEYS.FACE_ID]?: Array<string>;
  [DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE]?: Array<string>;
  [DAM_LIBRARY_FILTER_KEYS.TAGS]?: Array<string>;
  [DAM_LIBRARY_FILTER_KEYS.DATE_FROM]?: string;
  [DAM_LIBRARY_FILTER_KEYS.DATE_END]?: string;
  [DAM_LIBRARY_FILTER_KEYS.SORT_BY]?: string;
} & PaginationFiltersType;
