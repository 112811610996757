import React, { useEffect } from 'react';
import { Col, LoadingBox, Row, Text } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import EventsListEventItemContainer from '../../calendar-and-event-page/events-list/EventsListEventItemContainer';
import CRMDashboardEventsListPagePaginationContainer from './CRMDashboardEventsListPagePaginationContainer';

type Props = {
  fetchEvents: (filtersObject: BrowserUrlUtils.QueryObject) => void;
  eventIds: Array<string>;
  isLoading: boolean;
  filters: string;
};

export default function CRMDashboardEventsListPage(props: Props): JSX.Element {
  const { eventIds, isLoading, filters, fetchEvents } = props;
  useEffect(() => {
    const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters);
    fetchEvents(filterObject);
  }, [filters]);

  return (
    <LoadingBox isLoading={isLoading}>
      <div>
        {eventIds.length === 0 && (
          <div>
            <Text muted>There are no events.</Text>
          </div>
        )}
        {eventIds.map((event) => (
          <EventsListEventItemContainer key={event} id={event} />
        ))}
        <Row>
          <Col rightAlighed size={null}>
            <CRMDashboardEventsListPagePaginationContainer filters={filters} />
          </Col>
        </Row>
      </div>
    </LoadingBox>
  );
}
