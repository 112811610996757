import { AssetsService, QRCodeBackgroundPosterService } from '@premagic/core';
import { QRCodePosterIllustration, SimpleImage } from '@premagic/myne';

function getImageSrcBasedOnPosterType(
  type: 'standee' | 'pocket-card-front' | 'pocket-card-back' | 'tent-card' | 'led-screen',
  posterItem: QRCodeBackgroundPosterService.QRCodePoster,
  numberOfActiveLogos?: number,
  selectedLogoType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID,
) {
  const POSTER_MAP = {
    standee: posterItem.backgroundCover.standee,
    'standee-multi-logo': posterItem.backgroundCover.standeeMultiLogo,
    'pocket-card-front': posterItem.backgroundCover.pocketCardFront,
    'pocket-card-front-multi-logo': posterItem.backgroundCover.pocketCardFrontMultiLogo,
    'pocket-card-back': posterItem.backgroundCover.pocketCardBack,
    'tent-card': posterItem.backgroundCover.tentCard,
    'tent-card-multi-logo': posterItem.backgroundCover.tentCardMultiLogo,
    'led-screen': posterItem.backgroundCover.ledScreen,
  };

  if (
    numberOfActiveLogos === 2 &&
    selectedLogoType === QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID.CUSTOM_LOGO &&
    POSTER_MAP[`${type}-multi-logo`]
  ) {
    return POSTER_MAP[`${type}-multi-logo`];
  }

  // default - single logo layout
  return POSTER_MAP[type] || null;
}

type Props = {
  posterItem: QRCodeBackgroundPosterService.QRCodePoster;
  colors?: QRCodeBackgroundPosterService.PosterColorType;
  isPreview?: boolean;
  posterType?: 'standee' | 'pocket-card-front' | 'pocket-card-back' | 'tent-card' | 'led-screen';
  numberOfActiveLogos?: number;
  selectedLogoType?: QRCodeBackgroundPosterService.LOGO_TYPE_OPTION_ID;
};

export default function QRCodeBackgroundPoster(props: Props) {
  const { posterItem, colors, isPreview, posterType = 'standee', numberOfActiveLogos, selectedLogoType } = props;

  const posterName = getImageSrcBasedOnPosterType(posterType, posterItem, numberOfActiveLogos, selectedLogoType);

  if (!posterName) {
    return <div style={{ width: '100%', height: '100%', background: 'white' }} />;
  }

  if (posterItem.imageType === 'jpg') {
    return (
      <SimpleImage
        src={AssetsService.getQRCodeBackgroundPosterImages(posterName)}
        alt="poster"
        style={{ height: '100%', width: '100%', objectFit: 'contain' }}
      />
    );
  }

  return (
    <div style={isPreview ? { width: '90px', height: '100px' } : {}}>
      <QRCodePosterIllustration name={posterName} posterColors={colors} />
    </div>
  );
}
