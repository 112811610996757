import React, { useEffect } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { SponsorCreativesService } from '@premagic/core';
import SponsorCreativeFormContainer from '../create/SponsorCreativeFormContainer';

type Props = {
  creativeId?: string | null;
  projectId: string;
  creative?: SponsorCreativesService.SponsorCreativeType;
  updateCreative: (options: { projectId: string; creativeId: string }, data: FormResponseType) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  cleanup: () => void;
};

export default function EditSponsorCreativePanel(props: Props): JSX.Element | null {
  const { show, updateCreative, isLoading, errors, closePanel, creativeId, cleanup, projectId, creative } = props;
  useEffect(() => {
    if (!show) {
      cleanup();
    }
  }, [show, cleanup]);

  if (!creativeId || !show) return null;
  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Creative</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={(data) =>
            updateCreative(
              {
                creativeId,
                projectId,
              },
              data,
            )
          }
        >
          {creative && (
            <SponsorCreativeFormContainer
              projectId={projectId}
              creativeId={creativeId}
              placement={creative.placement}
            />
          )}
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
