import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideVariableSingleItemDataType,
  Dialog,
  DialogTitle,
  Form,
  FormFooter,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Link,
  List,
  ListItem,
  ListItemAction,
  Select,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { update } from 'lodash';
import { ProposalSlideService, ProposalVariableService } from '@premagic/proposals';
import APP_URLS from '../../../../services/AppRouteURLService';

type AddEquipmentButtonProps = {
  equipmentVariables: Array<ProposalVariableService.ProposalVariableType>;
  equipmentItemsAddedInSlide: Array<DeckSlideVariableSingleItemDataType>;
  onAdd: (equipmentItem: DeckSlideVariableSingleItemDataType) => void;
  showAddNewEquipmentVariable: () => void;
};
function AddEquipmentButton(props: AddEquipmentButtonProps) {
  const { equipmentVariables, equipmentItemsAddedInSlide, onAdd, showAddNewEquipmentVariable } = props;
  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef(null);

  function handleShowNewEquipmentVariable() {
    setShowDialog(false);
    showAddNewEquipmentVariable();
  }

  const variableOptions = useMemo(
    () =>
      equipmentVariables
        .filter(({ id: variableId }) => !equipmentItemsAddedInSlide.find((item) => item.id === variableId))
        .map((variable) => ({
          label: variable.name,
          value: variable.id,
        })),
    [equipmentVariables, equipmentItemsAddedInSlide],
  );

  function handleAdd(formResponse: FormResponseType) {
    const id = formResponse.data.variable_id as string;
    const variable = equipmentVariables.find(({ id: variableId }) => variableId === id);
    setShowDialog(false);
    return onAdd({
      id,
      name: variable?.name || 'no',
      type: variable?.type || ProposalVariableService.PROPOSAL_VARIABLE_TYPE.NUMBER,
      default_value: variable?.default_value || '',
    });
  }

  return (
    <div>
      <ListItem>
        <Button style={BUTTON_STYLES.LINK} onClick={() => setShowDialog(!showDialog)} ref={$button}>
          <Icon name="plus" size={ICON_SIZES.SM} /> <Space size={1} />
          item
        </Button>
      </ListItem>
      <Dialog show={showDialog} onClose={() => setShowDialog(false)} target={$button.current}>
        <DialogTitle>Add Equipment Item</DialogTitle>
        {variableOptions.length === 0 ? (
          <div>
            <Text block>There are no Equipment Items left to select</Text>
            <Space vertical />

            <Text>
              You can manage Equipment Items in the{' '}
              <Link to={APP_URLS.PROPOSALS_TEMPLATES.LIST}>Proposal templates setting page</Link> or{' '}
              <Button onClick={handleShowNewEquipmentVariable} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
                Add new Equipment Item
              </Button>
            </Text>
            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
                Close
              </Button>
            </FormFooter>
          </div>
        ) : (
          <Form onSubmit={handleAdd}>
            <Select name="variable_id" options={variableOptions} isMulti={false} autoFocus />
            <Text size={TEXT_SIZE.SIZE_5}>
              You can manage Equipment Items in the{' '}
              <Link to={APP_URLS.PROPOSALS_TEMPLATES.LIST}>Proposal templates page</Link> or{' '}
              <Button onClick={handleShowNewEquipmentVariable} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
                Add new Equipment Item
              </Button>
            </Text>
            <FormFooter>
              <Button style={BUTTON_STYLES.PRIMARY}>Select</Button>
            </FormFooter>
          </Form>
        )}
      </Dialog>
    </div>
  );
}

type Props = {
  slideDetails?: ProposalSlideService.ProposalSlideType;
  projectId: string;
  deckId: string;
  saveProposalSlide: (options: { projectId: string; deckId: string; id: number }, ProposalSlideType) => void;
  equipmentVariables: Array<ProposalVariableService.ProposalVariableType>;
  showAddNewEquipmentVariable: () => void;
};

const dataType = DECK_SLIDE_DATA_TYPES.EQUIPMENTS_ITEMS;
export default function ProposalCreatorEquipmentsUpdater(props: Props): JSX.Element | null {
  const { saveProposalSlide, projectId, deckId, equipmentVariables, slideDetails, showAddNewEquipmentVariable } = props;

  function handleAdd(item: DeckSlideVariableSingleItemDataType) {
    if (!slideDetails) return;
    const newStructure = update(slideDetails.structure, `[${dataType}][value]`, (items = []) => {
      items.push(item);
      return items;
    });
    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
  }

  function handleRemove(id: string) {
    if (!slideDetails) return;
    const newStructure = update(slideDetails.structure, `[${dataType}][value]`, (items = []) =>
      items.filter((item) => item.id !== id),
    );
    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
  }

  const equipmentItemsAddedInSlide = useMemo(() => slideDetails?.structure?.[dataType]?.value || [], [slideDetails]);

  return (
    <div>
      <FormLabel>Equipments</FormLabel>
      <List>
        {equipmentItemsAddedInSlide.map((item) => {
          const itemDetails = equipmentVariables.find((variable) => variable.id === item.id);
          return (
            <ListItem key={item.id}>
              {itemDetails?.name || item.name}
              <ListItemAction>
                <ButtonIcon onClick={() => handleRemove(item.id)} title="Delete" size={BUTTON_ICONS_SIZES.SM}>
                  <Icon name="trash" size={ICON_SIZES.SM} />
                </ButtonIcon>
              </ListItemAction>
            </ListItem>
          );
        })}
        {equipmentItemsAddedInSlide.length === 0 && (
          <ListItem>
            <Text muted>No items added</Text>
          </ListItem>
        )}
      </List>
      <AddEquipmentButton
        onAdd={handleAdd}
        equipmentItemsAddedInSlide={equipmentItemsAddedInSlide}
        equipmentVariables={equipmentVariables}
        showAddNewEquipmentVariable={showAddNewEquipmentVariable}
      />
    </div>
  );
}
