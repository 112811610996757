import { BrowserUtils, HttpUtils } from '@premagic/utils';
import Fuse from 'fuse.js';
import { isArray, isString } from 'lodash';
import { API_URLS } from './APIURLService';
import { FormFieldType, SYSTEM_CUSTOM_FORM_FIELDS } from './EventCustomFromFieldsService';

export enum EVENT_PEOPLE_TYPE {
  ATTENDEE = 'ATTENDEE',
  CREW = 'CREW',
  HOST = 'HOST',
  SPEAKER = 'SPEAKER',
  SPONSOR = 'SPONSOR',
  VOLUNTEER = 'VOLUNTEER',
  DELEGATE = 'DELEGATE',
  MEDIA = 'MEDIA',
  EXHIBITOR = 'EXHIBITOR',
  // JUDGE = 'JUDGE',
  // WINNER = 'WINNER',
  OTHERS = 'OTHERS',
}

export const EVENT_PEOPLE_TYPE_DETAILS: Record<
  EVENT_PEOPLE_TYPE,
  {
    name: string;
    value: EVENT_PEOPLE_TYPE;
    order: number;
    size: number;
  }
> = {
  [EVENT_PEOPLE_TYPE.ATTENDEE]: {
    name: 'Attendee',
    value: EVENT_PEOPLE_TYPE.ATTENDEE,
    order: 1,
    size: 10,
  },
  [EVENT_PEOPLE_TYPE.DELEGATE]: {
    name: 'Delegate',
    value: EVENT_PEOPLE_TYPE.DELEGATE,
    order: 1,
    size: 10,
  },
  [EVENT_PEOPLE_TYPE.SPEAKER]: {
    name: 'Speaker',
    value: EVENT_PEOPLE_TYPE.SPEAKER,
    order: 3,
    size: 8,
  },

  [EVENT_PEOPLE_TYPE.SPONSOR]: {
    name: 'Sponsor',
    value: EVENT_PEOPLE_TYPE.SPONSOR,
    order: 1,
    size: 10,
  },
  [EVENT_PEOPLE_TYPE.EXHIBITOR]: {
    name: 'Exhibitor',
    value: EVENT_PEOPLE_TYPE.EXHIBITOR,
    order: 1,
    size: 10,
  },
  [EVENT_PEOPLE_TYPE.MEDIA]: {
    name: 'Media',
    value: EVENT_PEOPLE_TYPE.MEDIA,
    order: 2,
    size: 5,
  },
  [EVENT_PEOPLE_TYPE.HOST]: {
    name: 'Host of the event',
    value: EVENT_PEOPLE_TYPE.HOST,
    order: 1,
    size: 10,
  },
  [EVENT_PEOPLE_TYPE.CREW]: {
    name: 'Crew',
    value: EVENT_PEOPLE_TYPE.CREW,
    order: 1,
    size: 10,
  },
  [EVENT_PEOPLE_TYPE.VOLUNTEER]: {
    name: 'Volunteer',
    value: EVENT_PEOPLE_TYPE.VOLUNTEER,
    order: 1,
    size: 10,
  },

  [EVENT_PEOPLE_TYPE.OTHERS]: {
    name: 'Others',
    value: EVENT_PEOPLE_TYPE.OTHERS,
    order: 2,
    size: 5,
  },
};

export enum EVENT_REGISTRATION_PROCESS {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum NOTIFICATION_PLATFORMS {
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  SMS = 'SMS',
  PUSH = 'PUSH',
  WEBHOOKS = 'WEBHOOKS',
}

// GOING, NOT_GOING, INVITED, WAITLIST
export enum EVENT_ATTENDEE_APPROVAL_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  WAITLIST = 'WAITLIST',
  REJECTED = 'REJECTED',
}

export const EVENT_ATTENDEE_APPROVAL_STATUS_DETAILS: Record<
  EVENT_ATTENDEE_APPROVAL_STATUS,
  {
    title: string;
    style: string;
  }
> = {
  [EVENT_ATTENDEE_APPROVAL_STATUS.PENDING]: {
    title: 'Pending',
    style: 'info',
  },
  [EVENT_ATTENDEE_APPROVAL_STATUS.APPROVED]: {
    title: 'Approved',
    style: 'success',
  },
  [EVENT_ATTENDEE_APPROVAL_STATUS.WAITLIST]: {
    title: 'Waitlist',
    style: 'info',
  },
  [EVENT_ATTENDEE_APPROVAL_STATUS.REJECTED]: {
    title: 'Rejected',
    style: 'danger',
  },
};

export enum EVENT_ATTENDEE_GENDER {
  MALE = 'm',
  FEMALE = 'f',
  OTHER = 'n',
}

export const EVENT_ATTENDEE_GENDER_DETAILS: Record<
  EVENT_ATTENDEE_GENDER,
  {
    title: string;
  }
> = {
  [EVENT_ATTENDEE_GENDER.MALE]: {
    title: 'Male',
  },
  [EVENT_ATTENDEE_GENDER.FEMALE]: {
    title: 'Female',
  },
  [EVENT_ATTENDEE_GENDER.OTHER]: {
    title: 'Other',
  },
};

export type NewEventAttendeeType = {
  name: string;
  phone: string;
  email: string;
  rsvp_number_of_people: number;
  people_type: EVENT_PEOPLE_TYPE;
  selfie: Blob | string;
  have_consent_to_use_data: boolean;
  permission_to_save_photo: boolean;
  have_consent_for_networking: boolean;
  have_consent_to_use_data_for_marketing: boolean;
  external_id?: string;
  add_ons?: Array<string>;
};

export type EventAttendeeType = NewEventAttendeeType & {
  registration_id: string;
  best_face_id?: string;
  face_id: string;
  face_url: string;
  face_asset_url: string;
  rsvp_number_of_people: number;
  process_status: EVENT_REGISTRATION_PROCESS;
  custom_fields: Record<string | SYSTEM_CUSTOM_FORM_FIELDS, string | any>;
  created_at: string;
  first_user_check_in_time: string;
  last_user_check_in_time: string;
  notification_success_platform: string; // comma separated NOTIFICATION_PLATFORMS
  first_notification_send_time?: number;
  last_notification_send_time?: number;
  approval?: EVENT_ATTENDEE_APPROVAL_STATUS;
};

export type EventAttendeeSimpleType = {
  // registration_id: string; // we dont have this on the API
  name: string;
  face_id: string;
  best_face_id?: string;
  company_name: string | null;
  role: string | null;
  have_consent_for_networking: boolean | null;
};

export function fetchEventAttendeesForProject(projectId: string): Promise<Record<string, EventAttendeeType>> {
  return HttpUtils.get(API_URLS.EVENT_ATTENDEES.LIST(projectId)).then(({ data }) => data);
}

export function downloadEventAttendeesForProject(projectId: string): Promise<any> {
  return HttpUtils.get(API_URLS.EVENT_ATTENDEES.BULK(projectId)).then(({ data }) => data);
}

export function downloadBulkRegistrationEventAttendeesCSV(projectId: string): Promise<File> {
  return HttpUtils.get(API_URLS.EVENT_ATTENDEES.BULK(projectId))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data.error));
}

export function uploadNewEventAttendeesCSV(projectId: string, csvFile: File): Promise<{ success: boolean }> {
  const formData = new FormData();
  formData.append('attendee_data', csvFile);
  return HttpUtils.post(API_URLS.EVENT_ATTENDEES.BULK(projectId), formData, true)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data.error));
}

export function updateEventAttendeesCSV(projectId: string, csvFile: File): Promise<{ success: boolean }> {
  const formData = new FormData();
  formData.append('attendee_data', csvFile);
  return HttpUtils.put(API_URLS.EVENT_ATTENDEES.BULK(projectId), formData, true)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data.error));
}

export function fetchEventAttendeesForProjectFromClientApp(projectId: string): Promise<{
  events_registered: Array<EventAttendeeType>;
  custom_fields: Record<string, FormFieldType>;
}> {
  return HttpUtils.get(API_URLS.EVENT_ATTENDEES.LIST__FROM_CLIENT_APP(projectId), true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data.error));
}

export type CreateEventAttendeeOptions = {
  should_send_welcome_notification?: boolean;
  event_registration_send_generic_welcome_email?: boolean;
};

export async function getSelfieBlob(selfie: string | Blob): Promise<Blob | null> {
  if (isString(selfie)) {
    const selfieBlob = await BrowserUtils.createBlobFromUrl(selfie);
    // BrowserUtils.revokeBlobUrlFromMemory(selfie as Blob);
    return selfieBlob;
  }
  return selfie;
}

export async function getFormDataForAttendee(
  attendee: Partial<
    (NewEventAttendeeType & CreateEventAttendeeOptions) | (EventAttendeeType & CreateEventAttendeeOptions)
  >,
  ignoreAttendeeType?: boolean,
) {
  const formData = new FormData();
  const {
    name,
    email,
    phone,
    selfie,
    rsvp_number_of_people: count = 1,
    people_type: type = EVENT_PEOPLE_TYPE.ATTENDEE,
    should_send_welcome_notification: shouldSendWelcomeNotification,
    have_consent_to_use_data: hasConsent,
    permission_to_save_photo: hasPermissionToSavePhoto,
    have_consent_to_use_data_for_marketing: hasConsentToSaveDataForMarketing,
    have_consent_for_networking: hasConsentForNetworking,
    add_ons: addOns,
    ...customFields
  } = attendee;
  if (name) {
    formData.append('name', name);
  }
  if (phone) {
    formData.append('phone', phone);
  }
  if (email) formData.append('email', email);

  if (selfie) {
    if (isString(selfie)) {
      const selfieBlob = await BrowserUtils.createBlobFromUrl(selfie);
      // BrowserUtils.revokeBlobUrlFromMemory(selfie as Blob);
      formData.append('selfie', selfieBlob || selfie);
    } else {
      formData.append('selfie', selfie);
    }
  }
  formData.append('rsvp_number_of_people', String(count));

  if (hasConsent !== undefined) {
    formData.append('have_consent_to_use_data', String(hasConsent || false));
  }

  if (!ignoreAttendeeType) {
    if (!Object.values(EVENT_PEOPLE_TYPE).includes(type)) {
      formData.append('people_type', EVENT_PEOPLE_TYPE.ATTENDEE);
    } else {
      formData.append('people_type', type);
    }
  }

  if (addOns) {
    formData.append('add_ons', JSON.stringify(isArray(addOns) ? addOns : [addOns]));
  }

  formData.append('custom_fields', JSON.stringify(customFields));

  if (selfie && hasPermissionToSavePhoto !== undefined)
    formData.append('permission_to_save_photo', String(hasPermissionToSavePhoto || false));

  if (shouldSendWelcomeNotification !== undefined)
    formData.append('should_send_welcome_notification', String(shouldSendWelcomeNotification || false));

  if (hasConsentToSaveDataForMarketing !== undefined)
    formData.append('have_consent_to_use_data_for_marketing', String(hasConsentToSaveDataForMarketing || false));

  if (hasConsentForNetworking !== undefined)
    formData.append('have_consent_for_networking', String(hasConsentForNetworking || false));

  return formData;
}

export async function createEventAttendee(
  projectId: string,
  attendee: NewEventAttendeeType & CreateEventAttendeeOptions,
): Promise<Record<string, EventAttendeeType>> {
  const formData = await getFormDataForAttendee(attendee);

  return HttpUtils.post(API_URLS.EVENT_ATTENDEES.LIST(projectId), formData, true).then(({ data }) => data);
}

export async function createEventAttendeeFromClientWebsite(
  domain: string,
  websiteUrlId: string,
  data: NewEventAttendeeType & CreateEventAttendeeOptions,
): Promise<{
  registration_id: string;
  face_id: string;
  success: boolean;
  message?: 'Already registered for the event';
}> {
  const formData = await getFormDataForAttendee(data);

  return HttpUtils.post(API_URLS.EVENT_ATTENDEES.ROOT__FROM_WEBSITE(domain, websiteUrlId), formData, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.data?.error || { message: 'Oops... Something went wrong, try again' }));
}

export function fetchEventAttendeeFromClientWebsite(
  domain: string,
  websiteUrlId: string,
  registrationId: string,
): Promise<EventAttendeeType> {
  return HttpUtils.get(API_URLS.EVENT_ATTENDEES.DETAILS_FROM_WEBSITE(domain, websiteUrlId, registrationId), true)
    .then(({ data }) => data.data)
    .catch((error) => Promise.reject(error?.data?.error || { message: 'Oops... Something went wrong, try again' }));
}

export async function attendeeSelfCheckinFromClientWebsite(
  domain: string,
  websiteUrlId: string,
  data: { selfie: Blob | string; method?: string },
): Promise<{
  data: EventAttendeeType;
  face_id?: string;
  registration_id?: string;
  success: boolean;
}> {
  const formData = new FormData();
  const selfieBlob = await getSelfieBlob(data.selfie);
  formData.append('selfie', selfieBlob || data.selfie);
  if (data.method) {
    formData.append('method', data.method);
  }
  return HttpUtils.post(API_URLS.EVENT_ATTENDEES.SEARCH_PUBLIC(domain, websiteUrlId), formData, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.data?.error || { message: 'Oops... Something went wrong, try again' }));
}

export async function attendeeDetailFromSelfie(
  shareId: string,
  data: { selfie: Blob | string },
): Promise<{
  data: EventAttendeeType;
  face_id?: string;
  registration_id?: string;
  success: boolean;
}> {
  const formData = new FormData();
  const selfieBlob = await getSelfieBlob(data.selfie);
  formData.append('selfie', selfieBlob || data.selfie);
  return HttpUtils.post(API_URLS.EVENT_ATTENDEES.SEARCH_FROM_SHARE_ID(shareId), formData, true)
    .then((response) => response.data)
    .catch((response) => {
      if (response.data.face_id) {
        return response.data;
      }
      return Promise.reject(response?.data?.error || { message: 'Oops... Something went wrong, try again' });
    });
}

export async function updateEventAttendee(
  options: {
    projectId: string;
    attendeeId: string;
  },
  attendee: EventAttendeeType,
): Promise<Record<string, EventAttendeeType>> {
  const { projectId, attendeeId } = options;
  const formData = await getFormDataForAttendee(attendee);
  return HttpUtils.put(API_URLS.EVENT_ATTENDEES.DETAILS(projectId, attendeeId), formData, true).then(
    ({ data }) => data,
  );
}
export async function updateEventAttendeeApprovalStatus(
  options: {
    projectId: string;
    attendeeId: string;
  },
  status: EVENT_ATTENDEE_APPROVAL_STATUS,
): Promise<Record<string, EventAttendeeType>> {
  const { projectId, attendeeId } = options;

  const formData = new FormData();
  formData.append('approval', status);

  return HttpUtils.put(API_URLS.EVENT_ATTENDEES.DETAILS(projectId, attendeeId), formData, true).then(
    ({ data }) => data,
  );
}

export async function bulkUpdateEventAttendeeDisplayIds(
  projectId: string,
  dataToUpdate: Record<string, Partial<EventAttendeeType>>,
): Promise<Record<string, EventAttendeeType>> {
  return HttpUtils.put(API_URLS.EVENT_ATTENDEES.BULK_UPDATE_DISPLAY_ID(projectId), dataToUpdate, true).then(
    ({ data }) => data,
  );
}

export function deleteEventAttendee(projectId: string, attendeeId: string): Promise<void> {
  return HttpUtils.httpDelete(API_URLS.EVENT_ATTENDEES.DETAILS(projectId, attendeeId)).then(({ data }) => data);
}

let searchFuze;
let searchFuzeIndex;
export function searchAttendees<T extends EventAttendeeType | EventAttendeeSimpleType>(
  attendees: Array<T>,
  pattern: string,
): Array<T> {
  const options = {
    keys: [
      'name',
      'email',
      'phone',
      'registration_id',
      `custom_fields.${SYSTEM_CUSTOM_FORM_FIELDS.DISPLAY_ID}`,
      `custom_fields.${SYSTEM_CUSTOM_FORM_FIELDS.USER_COMPANY}`,
      `custom_fields.${SYSTEM_CUSTOM_FORM_FIELDS.USER_ROLE}`,
      'company_name',
      'role',
    ],
    threshold: 0.3,
  };
  const myIndex = searchFuzeIndex || Fuse.createIndex(options.keys, attendees);
  searchFuze = searchFuze || new Fuse(attendees, options, myIndex);

  return searchFuze.search(pattern).map((i) => i.item);
}

export enum PII_DATA_OPTIONS {
  SHOW = 'SHOW',
  DONT_SHOW = 'DONT_SHOW',
  NO_CONSENT = 'NO_CONSENT',
}

export function getPIIDataDisplayOption(
  showPIIDataFeature: boolean,
  requestForUserDataForMarketing?: boolean,
  consentToUseDataForMarketing?: boolean,
): PII_DATA_OPTIONS {
  if (showPIIDataFeature) return PII_DATA_OPTIONS.SHOW;

  if (requestForUserDataForMarketing) {
    if (consentToUseDataForMarketing) return PII_DATA_OPTIONS.SHOW;
    return PII_DATA_OPTIONS.NO_CONSENT;
  }
  return PII_DATA_OPTIONS.DONT_SHOW;
}

export async function completeEventAttendeeData(
  domain: string,
  websiteUrlId: string,
  registrationId: string,
  userData: Partial<EventAttendeeType>,
): Promise<{ face_id }> {
  const formData = await getFormDataForAttendee(userData, true);

  return HttpUtils.put(
    API_URLS.EVENT_ATTENDEES.DETAILS_FROM_WEBSITE(domain, websiteUrlId, registrationId),
    formData,
    true,
  )
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error?.data?.error || { message: 'Oops... Something went wrong, try again' }));
}
