import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';
import UserActivateDialog from './UserActivateDialog';
import { saveUser, usersItemsSelector } from '../UsersDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import APP_URLS from '../../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../../services/RouterService';

function mapStateToProps(state, ownProps: { match: { params: { userId: string } } }) {
  const { userId } = ownProps.match.params;

  return {
    userId,
    isLoading: isLoadingSelector(LOADINGS.USERS.UPDATE)(state),
    user: usersItemsSelector(state)[userId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideDialog: () => {
      const usersPages = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.USERS, {});
      navigateTo(dispatch, usersPages);
    },
    toggleActivate: (userId, data) => {
      dispatch(saveUser(dispatch, userId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivateDialog);
