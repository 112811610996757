import { connect } from 'react-redux';

import {
  editEventAttendeeBadgeWidgetDesigns,
  eventAttendeeBadgeDesignsSelector,
} from '../../../../../../../projects/folder-meta/FoldersMetaDataDuck';
import { selectedWidgetSelector } from '../../../duck/EventAttendeeBadgeDesignDuck';
import EventAttendeeBadgeTextColorSelector from './EventAttendeeBadgeTextColorSelector';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  const badgeDesign = eventAttendeeBadgeDesignsSelector(folderId)(state);

  return {
    selectedWidgetForEdit: selectedWidgetSelector(state),
    designData: badgeDesign,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editTextColor: (projectId, folderId, previousDesign, widgetId, color) => {
      dispatch(
        editEventAttendeeBadgeWidgetDesigns(dispatch, projectId, folderId, {
          badgeDesignData: previousDesign,
          widgetDesignData: {
            [widgetId]: { color },
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeTextColorSelector);
