import {
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  Dialog,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  FormLabel,
  ICON_SIZES,
  INFO_MESSAGE_STYLE,
  Icon,
  InfoMessage,
  Input,
} from '@premagic/myne';
import { useRef, useState } from 'react';
import MESSAGES from '../../../../../../common/Messages';
import UpdateButton from '../../../settings/UpgradeButton';

type Props = {
  totalFolder: number;
  projectId: string;
  hasSignatureAlbumFeature: boolean;
  folderLimit: number;
  isLoading: boolean;
  errors?: FormErrorType;
  createFolder: (projectId: string, FormResponseType, navigateToFolder) => void;
  hasActiveSubscription: boolean;
  isCompanyTypeNotPhotographer: boolean;
  navigateToFolder?: boolean;
};

export default function AddSignatureAlbumButton(props: Props): JSX.Element | null {
  const {
    totalFolder,
    projectId,
    hasSignatureAlbumFeature,
    folderLimit,
    isLoading,
    errors,
    createFolder,
    hasActiveSubscription,
    isCompanyTypeNotPhotographer,
    navigateToFolder = true,
  } = props;
  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);
  if (!hasSignatureAlbumFeature) {
    return (
      <InfoMessage iconName="info" style={INFO_MESSAGE_STYLE.INFO}>
        {MESSAGES.SIGNATURE_FOLDER.UPGRADE} <UpdateButton />
      </InfoMessage>
    );
  }
  return (
    <>
      <Button
        active={showDialog}
        style={BUTTON_STYLES.LINK}
        size={BUTTON_SIZE.SM}
        ref={target}
        disabled={totalFolder >= folderLimit}
        disabledMessage={MESSAGES.SIGNATURE_FOLDER.GATE_FOLDER}
        onClick={() => setShowDialog(!showDialog)}
      >
        <Icon name="plus" size={ICON_SIZES.SM} /> {isCompanyTypeNotPhotographer ? 'folder' : 'Signature folder'}
      </Button>
      <Dialog target={target.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <Form
          onSubmit={(response) => {
            createFolder(projectId, response, navigateToFolder);
            setShowDialog(false);
          }}
          errors={errors}
        >
          <FormGroup>
            <FormLabel>Folder name</FormLabel>
            <Input
              name="folder_name"
              required
              autoFocus
              placeholder={isCompanyTypeNotPhotographer ? 'eg. Highlight' : 'eg. The wedding day'}
            />
          </FormGroup>
          <FormFooter>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!hasActiveSubscription}
              disabledMessage={MESSAGES.SUBSCRIPTIONS.INACTIVE}
            >
              Add
            </Button>
          </FormFooter>
        </Form>
      </Dialog>
    </>
  );
}
