import { EnvUtils } from '@premagic/utils';
import { getClientName } from './GlobalVariableService';

export enum FEATURES_TOGGLES {
  SELECTION_FAVORITE = 'selection-favorite',
  HIGHLIGHT_FONT = 'highlight-font',
  WATERMARK = 'watermark',
  OLD_IMAGE_RESIZER = 'old-image-resizer',
  SMOOTH_MAGE_ZOOM = 'smooth_image_zoom',
  WORKFLOW = 'workflow',
  STORE_PRICING = 'store_pricing',
  COPY_FROM_SIGNATURE = 'copy_from_signature',
  REDIRECT_TO_GALLERY_AFTER_REGISTRATION_DISABLE = 'redirect_to_gallery_after_registration_disable',
  HIGHER_VIDEO_LIMIT = 'higher_video_limit',
  IMAGE_DIRECT_SHARE = 'image_direct_share',
  AVATAR_SHARE = 'avatar_share',
}

const INTERNAL_CLIENTS = [
  'Anenth Dev',
  'dev',
  'Alpha',
  'Anenth Prod',
  'Anup',
  'Mevin',
  'Demoaccount',
  'Mousam',
  'Mousam photography club',
  'GodsOwnEleven',
  'Hanan',
];
const ALPHA_ACCOUNT = ['PLAYBACK WEDDINGS', 'Wellbeing Studios'];
const BETA_V1_ACCOUNT = ['Shutterbug Film Company'];
const CLIENT_SET = {
  DEV: INTERNAL_CLIENTS,
  ALPHA: [...INTERNAL_CLIENTS, ...ALPHA_ACCOUNT],
  BETA_V1: [...INTERNAL_CLIENTS, ...ALPHA_ACCOUNT, ...BETA_V1_ACCOUNT],
};
const OLD_IMAGE_RESIZER_CLIENTS = ['Captured Momentz', 'KKings Events', 'obscuraphotogenie'];
const LARGE_CLIENTS = ['Yuva Galam ', 'Yuva Galam'];
// He is on ultimate plan, we are giving him this feature as one of case, next year he will be on Ultimate max plan
const COPY_FROM_SIGNATURE = [
  'Shutterbug Film Company', // Jul - 2024
  'WCC Weddings ', // Dec - 2024
  'Jeno Photography', // Jul - 2024 || we might not be able to upsell, but we can get 15k extra for this, Jamal
];
const HIGHER_VIDEO_LIMIT = ['Quark Expeditions'];

const FEATURE_TOGGLES_DETAILS: Record<
  FEATURES_TOGGLES,
  {
    release: boolean;
    clientSet: Array<string>;
    disable?: boolean; // This is used to test the feature toggle
  }
> = {
  [FEATURES_TOGGLES.SELECTION_FAVORITE]: {
    release: true,
    clientSet: CLIENT_SET.DEV,
  },
  [FEATURES_TOGGLES.HIGHLIGHT_FONT]: {
    release: true,
    clientSet: CLIENT_SET.DEV,
  },
  [FEATURES_TOGGLES.WATERMARK]: {
    release: false,
    clientSet: CLIENT_SET.DEV,
  },
  [FEATURES_TOGGLES.SMOOTH_MAGE_ZOOM]: {
    release: true,
    clientSet: CLIENT_SET.DEV,
  },
  [FEATURES_TOGGLES.WORKFLOW]: {
    release: false,
    clientSet: CLIENT_SET.DEV,
  },
  [FEATURES_TOGGLES.STORE_PRICING]: {
    release: false,
    clientSet: CLIENT_SET.DEV,
  },
  [FEATURES_TOGGLES.OLD_IMAGE_RESIZER]: {
    release: false,
    clientSet: OLD_IMAGE_RESIZER_CLIENTS,
  },
  [FEATURES_TOGGLES.COPY_FROM_SIGNATURE]: {
    release: false,
    clientSet: COPY_FROM_SIGNATURE,
  },
  [FEATURES_TOGGLES.REDIRECT_TO_GALLERY_AFTER_REGISTRATION_DISABLE]: {
    release: false,
    clientSet: LARGE_CLIENTS,
  },
  [FEATURES_TOGGLES.HIGHER_VIDEO_LIMIT]: {
    release: false,
    clientSet: HIGHER_VIDEO_LIMIT,
  },
  [FEATURES_TOGGLES.IMAGE_DIRECT_SHARE]: {
    release: true,
    clientSet: CLIENT_SET.DEV,
    disable: false,
  },
  [FEATURES_TOGGLES.AVATAR_SHARE]: {
    release: false,
    clientSet: CLIENT_SET.DEV,
    disable: false,
  },
};

export function isToggleableFeatureEnabledForClient(feature: FEATURES_TOGGLES, enableForDevEnv = false): boolean {
  const featureDetails = FEATURE_TOGGLES_DETAILS[feature];
  if (featureDetails.disable) return false;
  if (featureDetails.release) return true;
  if (enableForDevEnv && !EnvUtils.isProd()) return true;
  const clientName = getClientName();
  return featureDetails.clientSet.includes(clientName);
}
