import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  Dialog,
  DialogFooter,
  DialogTitle,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  InnerPageContent,
  InnerPageContentMainCol,
  Input,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroupValue,
} from '@premagic/myne';

import { CompanyService, HelpDeskService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';

type CustomDomainProps = {
  customDomain?: string;
  email: string;
};

function CustomDomain(props: CustomDomainProps) {
  const { customDomain, email } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const $button = useRef(null);

  function onSubmit(response: FormResponseType) {
    const { domain } = response.data;
    HelpDeskService.raiseTicket({
      subject: 'Request for Custom Domain',
      email,
      message: `Requesting for domain ${domain}`,
      tags: ['client_request.custom_domain'],
    });
    setShowSuccess(true);
  }
  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
        Custom domain
      </Text>
      <Space />
      {customDomain ? (
        <TextGroupValue zeroStateText="Not Set" type={TEXT_GROUP_TYPES.TEXT} value={customDomain} />
      ) : (
        <div>
          <Button
            style={BUTTON_STYLES.LINK}
            size={BUTTON_SIZE.SM}
            onClick={() => setShowDialog(!showDialog)}
            ref={$button}
          >
            Connect domain
          </Button>
        </div>
      )}
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        {showSuccess ? (
          <div>
            <Text block size={TEXT_SIZE.SIZE_3} center boldness={TEXT_BOLDNESS.BOLD}>
              Great!
            </Text>
            <Space vertical />
            <Text>Our team will reach out to you for further instruction.</Text>
            <Space vertical />
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
              Okay
            </Button>
          </div>
        ) : (
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <FormLabel>Preferred Custom domain</FormLabel>
              <Input name="domain" required autoFocus placeholder="eg. stories.yourWebsite.com" />
            </FormGroup>
            <FormFooter>
              <Button style={BUTTON_STYLES.PRIMARY}>Add</Button>
            </FormFooter>
          </Form>
        )}
      </Dialog>
    </div>
  );
}

function CompanyURLS(props: {
  isLeadsModuleEnabled: boolean;
  urls: {
    contactCard: string;
    form: string;
    portfolio: string;
    premagicDomainUrl: string;
  };
}) {
  const { urls, isLeadsModuleEnabled } = props;
  if (isLeadsModuleEnabled) {
    return (
      <div>
        <Text size={TEXT_SIZE.SIZE_5} muted block>
          Portfolio{' '}
          <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(urls.portfolio)} isExternalLink>
            {urls.portfolio}
          </SimpleLink>
        </Text>
        <Text size={TEXT_SIZE.SIZE_5} muted block>
          Portfolio Contact Card{' '}
          <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(urls.contactCard)} isExternalLink>
            {urls.contactCard}
          </SimpleLink>
        </Text>
        <Text size={TEXT_SIZE.SIZE_5} muted block>
          Embed Company Lead form{' '}
          <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(urls.form)} isExternalLink>
            {urls.form}
          </SimpleLink>
        </Text>
      </div>
    );
  }
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_5} muted block>
        Company page{' '}
        <SimpleLink href={BrowserUrlUtils.getURLWithProtocol(urls.portfolio)} isExternalLink>
          {urls.portfolio}
        </SimpleLink>
      </Text>
    </div>
  );
}

type Props = {
  company: CompanyService.CompanyType;
  urls: {
    contactCard: string;
    form: string;
    portfolio: string;
    premagicDomainUrl: string;
  };
  saveCompanyData: (data: Record<string, any>) => void;
  errors: Record<string, string>;
  isSaving: boolean;
  isLeadsModuleEnabled: boolean;
};

export default function CompanyDomainSettingsPage(props: Props) {
  const { company, urls, saveCompanyData, errors, isSaving, isLeadsModuleEnabled } = props;

  const { contacts, social } = company;

  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef(null);

  function handleSubmit(formData) {
    const valueToPost = {
      data: {
        contacts,
        social,
        ...formData.data,
      },
    };
    saveCompanyData(valueToPost);
  }

  function handleDomainChangeConfirm() {
    const button = document.getElementById('domain-settings-submit') as HTMLElement;
    button.click();
    setShowDialog(false);
  }

  function handleSave() {
    const showWarning = company?.total_num_projects ? company?.total_num_projects > 0 : false;
    if (showWarning) {
      setShowDialog(true);
    } else {
      handleDomainChangeConfirm();
    }
  }

  return (
    <InnerPageContent hasInnerCols>
      <InnerPageContentMainCol>
        <Card>
          <Space>
            <Form onSubmit={handleSubmit} errors={errors}>
              {(() => {
                if (company.custom_domain) {
                  return (
                    <Card>
                      <Space>
                        <Form onSubmit={handleSubmit} errors={errors}>
                          <FormGroup>
                            <CustomDomain
                              customDomain={company.custom_domain}
                              email={company.contacts.email || company.domain}
                            />
                            <CompanyURLS urls={urls} isLeadsModuleEnabled={isLeadsModuleEnabled} />
                            <Space vertical size={4} />
                            <Text muted>Fallback Url: {urls.premagicDomainUrl}</Text>
                          </FormGroup>
                        </Form>
                      </Space>
                    </Card>
                  );
                }

                return (
                  <FormGroup>
                    <FormLabel>Premagic Domain</FormLabel>
                    <Row vcenter>
                      <Col>
                        <Input name="domain" defaultValue={company.domain} minLength={4} maxLength={64} />
                      </Col>
                      <Space size={1} />
                      <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
                        .premagic.com
                      </Text>
                    </Row>
                    <Space vertical size={1} />

                    <CompanyURLS urls={urls} isLeadsModuleEnabled={isLeadsModuleEnabled} />
                    <Space vertical size={8} />
                    <CustomDomain email={company.contacts.email || company.domain} />
                  </FormGroup>
                );
              })()}

              <FormFooter>
                <Button
                  ref={$button}
                  type="button"
                  onClick={handleSave}
                  style={BUTTON_STYLES.PRIMARY}
                  isLoading={isSaving}
                >
                  Save
                </Button>
                {showDialog && (
                  <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
                    <DialogTitle>Change domain?</DialogTitle>
                    <Text block>Changing the domain will cause the old links to break</Text>
                    <Space vertical size={2} />
                    <Text block>Are you sure you want to change the domain?</Text>
                    <Space vertical size={6} />
                    <DialogFooter>
                      <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
                        Cancel
                      </Button>
                      <Button onClick={handleDomainChangeConfirm} style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                        Confirm
                      </Button>
                    </DialogFooter>
                  </Dialog>
                )}
              </FormFooter>
              <button type="submit" hidden id="domain-settings-submit" aria-label="save-settings" />
            </Form>
          </Space>
        </Card>
      </InnerPageContentMainCol>
    </InnerPageContent>
  );
}
