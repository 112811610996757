import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import {
  qrCodeDesignsNumberOfActiveLogoSelector,
  qrCodeDesignsSelector,
} from '../../../projects/folder-meta/FoldersMetaDataDuck';

import { LogoBasedOnPosterType } from './LogoBasedOnPosterType';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    customLogo1?: string;
    customLogo2?: string;
    posterType: 'standee' | 'pocket-card' | 'tent' | 'led-screen';
    customStyle?: boolean;
    smallerLogoSpace?: boolean;
  },
) {
  const { folderId, customLogo1, customLogo2, posterType, customStyle, smallerLogoSpace } = ownProps;

  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);

  return {
    folderId,
    customLogo1,
    customLogo2,
    logoOptionType: qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_OPTION_TYPE],
    posterType,
    numberOfActiveLogos: qrCodeDesignsNumberOfActiveLogoSelector(folderId)(state),
    customStyle,
    smallerLogoSpace,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoBasedOnPosterType);
