import { ModalDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../../common/Constants';
import {
  editEventAttendeeBadgeWidgetDesigns,
  eventAttendeeBadgeDesignsSelector,
} from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import { WIDGET_TYPE } from '../service/EventAttendeeBadgeDesignService';
import EventAttendeeBadgePosterUploadModal from './EventAttendeeBadgePosterUploadModal';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    show: ModalDuck.isModalOpenSelector(LOADINGS.EVENT_ATTENDEE_BADGE.SHOW_EVENT_ATTENDEE_BADGE_SELECT_POSTER_MODAL)(
      state,
    ),
    designData: eventAttendeeBadgeDesignsSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(
        ModalDuck.toggleModalVisibility(
          dispatch,
          LOADINGS.EVENT_ATTENDEE_BADGE.SHOW_EVENT_ATTENDEE_BADGE_SELECT_POSTER_MODAL,
          false,
        ),
      );
    },
    onSelect: (projectId, folderId, previousDesign, imageId) => {
      dispatch(
        editEventAttendeeBadgeWidgetDesigns(dispatch, projectId, folderId, {
          badgeDesignData: previousDesign,
          widgetDesignData: {
            [WIDGET_TYPE.POSTER]: { value: imageId },
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgePosterUploadModal);
