import { connect } from 'react-redux';
import ClientPaymentForm from './ClientPaymentForm';
import { crmPaymentsSelector, crmTotalRemainingClientPaymentAmountSelector } from '../CRMPaymentDataDuck';
import { paymentIdForEditSelector } from '../../events/details/EventDetailsPageDuck';

function mapStateToProps(state) {
  const paymentId = paymentIdForEditSelector(state);

  return {
    paymentId,
    totalRemainingClientPayment: crmTotalRemainingClientPaymentAmountSelector(state),
    payment: paymentId ? crmPaymentsSelector(state)[paymentId] : undefined,
  };
}

export default connect(mapStateToProps)(ClientPaymentForm);
