import { ClientWebsiteFormService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  COLOR_SHADES,
  Form,
  FormFooter,
  FormResponseType,
  Icon,
  ICON_SIZES,
  PillItem,
  Pills,
  PILLS_STYLES,
  Row,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ArrayUtils } from '@premagic/utils';
import { isArray } from 'lodash';
import { useState } from 'react';

type ContactInfoForNotificationProps = {
  projectId: string;
  updateProjectMeta: (projectId: string, formResponse) => void;
  registrationType: ProjectMetaService.EVENT_REGISTRATION_TYPES;
  eventRegistrationSendNotification?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  eventRegistrationSendNotificationRequired?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  allowedEmailDomains?: Array<string>;
};

export default function ContactInfoForNotification(props: ContactInfoForNotificationProps) {
  const {
    updateProjectMeta,
    projectId,
    eventRegistrationSendNotification,
    eventRegistrationSendNotificationRequired,
    registrationType,
    allowedEmailDomains = [],
  } = props;
  const [isEditingEmailDomains, setIsEditingEmailDomains] = useState(false);

  function handleSendNotificationTo(val) {
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION]: val,
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED]: val,

        // this is to handle old data
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD]:
          val === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED]:
          val === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL ||
          val === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      },
    });
  }

  function handleSendNotificationToRequired(val) {
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED]: val,

        // this is to handle old data
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED]:
          val === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL ||
          val === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      },
    });
  }

  function handleOnChangeRegistrationType(val) {
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_TYPE]: val,
      },
    });
  }

  function handleOnChangeAllowedEmailDomains(formResponse: FormResponseType) {
    const domains = formResponse?.data?.allowed_emails as Array<string>;
    const domainArray = isArray(domains) ? domains : [domains];

    setIsEditingEmailDomains(false);

    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_ALLOWED_EMAIL_DOMAINS]: domainArray.map((item) =>
          item.toLowerCase(),
        ),
      },
    });
  }

  return (
    <>
      <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} block>
        Registration Type
      </Text>
      <Pills style={PILLS_STYLES.SECONDARY}>
        {Object.values(ProjectMetaService.EVENT_REGISTRATION_TYPES_DETAILS).map((item) => (
          <PillItem
            key={item.id}
            active={registrationType === item.id}
            onClick={() => handleOnChangeRegistrationType(item.id)}
          >
            <Text
              size={TEXT_SIZE.SIZE_5}
              boldness={TEXT_BOLDNESS.SEMI_BOLD}
              color={registrationType === item.id ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
            >
              {item.title}
            </Text>
          </PillItem>
        ))}
      </Pills>
      <Space vertical size={8} />
      <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} block>
        Allowed Email Domains
      </Text>
      {isEditingEmailDomains ? (
        <Form onSubmit={handleOnChangeAllowedEmailDomains}>
          <Select
            value={allowedEmailDomains?.map((item) => ({
              label: item,
              value: item,
            }))}
            options={allowedEmailDomains?.map((item) => ({
              label: item,
              value: item,
            }))}
            isMulti
            autoFocus
            isCreatable
            name="allowed_emails"
          />
          <FormFooter>
            {allowedEmailDomains.length > 0 && (
              <Button
                style={BUTTON_STYLES.TERTIARY}
                size={BUTTON_SIZE.SM}
                onClick={() => handleOnChangeAllowedEmailDomains({ data: { allowed_emails: [] } })}
              >
                Allow all
              </Button>
            )}
            <Button style={BUTTON_STYLES.CANCEL} size={BUTTON_SIZE.SM} onClick={() => setIsEditingEmailDomains(false)}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} size={BUTTON_SIZE.SM}>
              Save
            </Button>
          </FormFooter>
          <Space vertical size={8} />
        </Form>
      ) : (
        <Row>
          <Text size={TEXT_SIZE.SIZE_5}>{ArrayUtils.listify(allowedEmailDomains) || 'All Domain'}</Text>
          <Space size={4} />
          <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={() => setIsEditingEmailDomains(true)}>
            <Icon name="edit" size={ICON_SIZES.SM} />
            <Space size={1} />
            Edit
          </Button>
        </Row>
      )}
      <Space vertical size={8} />

      <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} block>
        Show form fields and send notification to
      </Text>
      <Pills style={PILLS_STYLES.SECONDARY}>
        {Object.values(ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE_DETAILS).map((item) => (
          <PillItem
            key={item.id}
            active={
              eventRegistrationSendNotification === item.id ||
              (eventRegistrationSendNotification === undefined &&
                item.id === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH)
            }
            onClick={() => handleSendNotificationTo(item.id)}
          >
            <Text
              size={TEXT_SIZE.SIZE_5}
              boldness={TEXT_BOLDNESS.SEMI_BOLD}
              color={
                eventRegistrationSendNotification === item.id ||
                (eventRegistrationSendNotification === undefined &&
                  item.id === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH)
                  ? COLOR_SHADES.LIGHTER
                  : COLOR_SHADES.DARKER
              }
            >
              {item.title}
            </Text>
          </PillItem>
        ))}
      </Pills>
      <Space vertical />
      <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} block>
        Required Field
      </Text>
      <Pills style={PILLS_STYLES.SECONDARY}>
        {Object.values(ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE_DETAILS).map((item) => (
          <PillItem
            key={item.id}
            active={
              eventRegistrationSendNotificationRequired === item.id ||
              (eventRegistrationSendNotificationRequired === undefined &&
                item.id === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH)
            }
            onClick={() => handleSendNotificationToRequired(item.id)}
          >
            <Text
              size={TEXT_SIZE.SIZE_5}
              boldness={TEXT_BOLDNESS.SEMI_BOLD}
              color={
                eventRegistrationSendNotificationRequired === item.id ||
                (eventRegistrationSendNotificationRequired === undefined &&
                  item.id === ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH)
                  ? COLOR_SHADES.LIGHTER
                  : COLOR_SHADES.DARKER
              }
            >
              {item.title}
            </Text>
          </PillItem>
        ))}
      </Pills>
    </>
  );
}
