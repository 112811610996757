import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { saveClientSettingsData, companyTypeSelector } from '../preferences/ClientSettingsDataDuck';
import CompanyTypeSelector from './CompanyTypeSelector';
import { LOADINGS } from '../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  return {
    companyType: companyTypeSelector(state),
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    showConfirmationModal: ModalDuck.isModalOpenSelector(LOADINGS.CLIENT_SETTINGS.CHANGE_COMPANY_TYPE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
    toggleConfirmationModal: (show) =>
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.CLIENT_SETTINGS.CHANGE_COMPANY_TYPE, show)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTypeSelector);
