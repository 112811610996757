import { connect } from 'react-redux';
import { projectMetaDataForProjectSelector } from '../../../../projects/project-meta/ProjectsMetaDataDuck';
import { statsOnProjectSelector } from '../../../../projects/project-stats/ProjectsStatsDataDuck';
import EventAttendeeReportSummaryCard from './EventAttendeeReportSummaryCard';
import {
  clientWebsiteIdForProjectSelector,
  registrationLinkForProjectSelector,
} from '../../../../projects/AccountProjectsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
  },
) {
  const { projectId, eventId } = ownProps;
  return {
    eventId,
    clientWebsiteId: clientWebsiteIdForProjectSelector(projectId)(state),
    stats: statsOnProjectSelector(projectId)(state),
    registrationLink: registrationLinkForProjectSelector(projectId)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeReportSummaryCard);
