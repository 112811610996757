import { connect } from 'react-redux';
import { FolderService, PricingPlanFeaturesService, FeatureToggleService } from '@premagic/core';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import {
  cloneHighlightFolderToSelection,
  folderIdsWithFolderTypeForProjectSelector,
} from '../../../projects/AccountProjectsDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import CloneHighlightFolderToSelectionAlbumButton from './CloneHighlightFolderToSelectionAlbumButton';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const hasCopyFromHighlightFeature =
    FeatureToggleService.isToggleableFeatureEnabledForClient(
      FeatureToggleService.FEATURES_TOGGLES.COPY_FROM_SIGNATURE,
    ) || hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_COPY_FROM_HIGHLIGHT)(state);

  return {
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT.COPY_FROM_SIGNATURE_ALBUM)(state),
    hasCopyFromHighlightFeature,
    signatureFolderIds: folderIdsWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.HIGHLIGHT,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cloneFromHighlight: (projectId) => {
      dispatch(cloneHighlightFolderToSelection(dispatch, projectId));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CloneHighlightFolderToSelectionAlbumButton);
