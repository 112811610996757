import { combineReducers } from 'redux';
import ProjectOverviewPageDuck from './ProjectOverviewPageDuck';
import ProjectsOverviewDataDuck from './ProjectsOverviewDataDuck';

export default combineReducers({
  data: combineReducers({
    all: ProjectsOverviewDataDuck,
  }),

  page: ProjectOverviewPageDuck,
});
