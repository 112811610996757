import { connect } from 'react-redux';
import { EventTrackerService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';

import SubscriptionStatusPage from './SubscriptionStatusPage';
import { companyDataSelector, photographerNameSelector } from '../../company/CompanyDataDuck';
import { clientSettingsDataSelector, companyTypeSelector } from '../../preferences/ClientSettingsDataDuck';
import { currentSubscriptionSelector } from '../SubscriptionsDataDuck';
import { isStaffUserSelector } from '../../../crm/users/UsersDataDuck';
import { companyStatsSelector } from '../../../company/company-stats/CompanyStatsDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  return {
    name: photographerNameSelector(state),
    company: companyDataSelector(state),
    companyType: companyTypeSelector(state),
    subscription: currentSubscriptionSelector(state),
    isStaffUser: isStaffUserSelector(state),
    companyStats: companyStatsSelector(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.SUBSCRIPTION.FETCH, true)(state),
    clientSettings: clientSettingsDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (company) => {
      // dispatch(getCurrentSubscription(dispatch, company));
      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.SUBSCRIPTIONS.EXPIRED_VIEW, company);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionStatusPage);
