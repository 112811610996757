import { useLayoutEffect, useState } from 'react';
import { DominantFinalColor, getDominantColorsOfHtmlImageElement } from '../ColorUtils';

export function useImage(
  src?: string,
  options?: {
    extractDominantColors?: boolean;
    getBase64?: boolean;
    getImageObject?: boolean;
    fromCanvas?: boolean;
  },
): {
  src?: string;
  image?: HTMLImageElement;
  base64?: string;
  isLoading: boolean;
  hasError: boolean;
  extractedColors?: Array<any>;
} {
  const {
    extractDominantColors = false,
    getBase64 = false,
    getImageObject = false,
    fromCanvas = false,
  } = options || {};
  let imageObj;
  const [isLoading, setLoading] = useState(!!src); // By default it should be true, otherwise on the first render the img tag will trigger the request and it will end up requesting the image twice
  const [hasError, setError] = useState(false);
  const [extractedColors, setExtractedColors] = useState<Array<DominantFinalColor>>();
  const [image, setImage] = useState<HTMLImageElement>();
  const [base64, setBase64] = useState<string>();

  function onLoad() {
    setLoading(false);
    if (getImageObject) setImage(imageObj);
    if (getBase64) {
      const canvas = document.createElement('canvas');
      canvas.width = imageObj.width;
      canvas.height = imageObj.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(imageObj, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        setBase64(dataURL);
      }
    }

    if (imageObj?.complete && extractDominantColors) {
      getDominantColorsOfHtmlImageElement(imageObj as HTMLImageElement).then((colors) => {
        setExtractedColors(colors);
      });
    }
  }

  function onError() {
    setError(true);
    setLoading(false);
  }

  function loadImg() {
    if (!src) return;
    imageObj = new Image();
    imageObj.src = src;

    if (fromCanvas) {
      // https://stackoverflow.com/questions/22710627/tainted-canvases-may-not-be-exported
      // this was added to make sure canvas download works fine, if its not added, then downloadAsImage will not work, the image will be blank
      imageObj.crossOrigin = 'Anonymous';
    }
    // If not cached then marked as loading https://stackoverflow.com/questions/15352803/how-to-check-if-an-image-was-cached-in-js
    if (!imageObj.complete) {
      setLoading(true);
    }
    imageObj.addEventListener('load', onLoad);
    imageObj.addEventListener('error', onError);
  }

  function unloadImg() {
    imageObj.removeEventListener('load', onLoad);
    imageObj.removeEventListener('error', onError);
    try {
      imageObj.src = '';
      delete imageObj.src;
    } catch (e) {
      // On Safari in Strict mode this will throw an exception,
      //  - https://github.com/mbrevda/react-image/issues/187
      // We don't need to do anything about it.
    }
  }

  useLayoutEffect(() => {
    loadImg();
    return () => {
      if (imageObj) unloadImg();
    };
  }, [src]);

  return {
    isLoading,
    src,
    image,
    base64,
    hasError,
    extractedColors,
  };
}
