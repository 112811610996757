import React from 'react';
import { connect } from 'react-redux';
import CompanySettingPage from './CompanySettingPage';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return { currentUrlPathname: pathname };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettingPage);
