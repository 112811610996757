import {
  BackgroundMusicService,
  ClientWebsiteClipArtService,
  ClientWebsiteService,
  EventsCategoryService,
  EventsService,
  PresetDesignService,
} from '@premagic/core';
import { i18n } from '@premagic/utils';
import {
  CLIENT_WEBSITE_FORM_ITEMS,
  CLIENT_WEBSITE_FORM_ITEMS_DETAILS,
} from '../../client-website/form-items/ClientWebsiteFormService';
import { THEMES_IDS } from '../../client-website/form-items/ClientWebsiteThemeItem';

export function getDefaultDataForInvite(options: {
  eventName: EventsService.EVENT_CATEGORY | string;
  groom_name: string;
  bride_name?: string;
}): ClientWebsiteService.NewClientWebsiteType {
  const presetDesignId = ClientWebsiteClipArtService.getRandomPresetDesignsIdFromClipartMapping();
  const presetDesign = PresetDesignService.PRESET_DESIGN[presetDesignId];

  const { eventName, bride_name: brideName, groom_name: groomName } = options;

  const defaultTheme = {
    id: THEMES_IDS.BAIKAL,
    color: {
      background: presetDesign.backgroundColor,
      primary_color: presetDesign.colors.primaryColor,
      secondary_color: presetDesign.colors.secondaryColor,
      tertiary_color: presetDesign.colors.tertiaryColor,
    },
    font: {
      primary_font: presetDesign.primaryFontFamily,
      secondary_font: presetDesign.secondaryFontFamily,
    },
    clipart_id: ClientWebsiteClipArtService.getClipartForPresetDesign(presetDesignId),
    music_id: BackgroundMusicService.BACKGROUND_MUSICS_IDS.NONE,
  };

  const defaultData = {
    bride_name: brideName,
    groom_name: groomName,
    user_registration_foot_note: CLIENT_WEBSITE_FORM_ITEMS_DETAILS[
      CLIENT_WEBSITE_FORM_ITEMS.USER_REGISTRATION_FOOT_NOTE
    ].defaultValue as string,

    // adding this just because its a required field on the api, will ask backend to remove this key, then I will remove it from here.
    schedule: '',
    schedule_timezone: '',
  };

  if (EventsCategoryService.isWeddingEvent(eventName)) {
    return {
      theme: {
        ...defaultTheme,
        music_id: BackgroundMusicService.getRandomMusicId(),
      },
      data: {
        ...defaultData,
        tagline: "We can't wait to celebrate our special day with you",
        livestream_link: 'https://www.youtube-nocookie.com/embed/Aq8sXpbva4E?controls=0',
      },
    };
  }

  return {
    theme: {
      ...defaultTheme,
      id: THEMES_IDS.CRATER,
      font: {
        primary_font: 'Lato',
        secondary_font: 'Source Sans Pro',
      },
      color: {
        background: '#ffffff',
        primary_color: '#222831',
        secondary_color: '#9C9DBA',
        tertiary_color: '#2D2D2D',
      },
      clipart_id: ClientWebsiteClipArtService.CLIP_ART_IDS.SIASCONSET,
    },
    data: {
      ...defaultData,
      tagline: 'Join us for a memorable event to remember',
      detailed_tagline: '<p></p>',
    },
  };
}
