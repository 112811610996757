import { connect } from 'react-redux';
import { companyDataSelector } from '../company/CompanyDataDuck';
import { CurrentSubscriptionInfo } from './CurrentSubscriptionInfo';
import { currentSubscriptionSelector } from './SubscriptionsDataDuck';

function mapStateToProps(state) {
  return {
    company: companyDataSelector(state),
    subscription: currentSubscriptionSelector(state),
  };
}

export default connect(mapStateToProps)(CurrentSubscriptionInfo);
