import { ModalDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../../../common/Constants';
import { filesItemDataSelector } from '../../../../../../../images/FilesDataDuck';
import {
  editEventAttendeeBadgeWidgetDesigns,
  eventAttendeeBadgeDesignsSelector,
} from '../../../../../../../projects/folder-meta/FoldersMetaDataDuck';
import { WIDGET_TYPE } from '../../../service/EventAttendeeBadgeDesignService';
import EventAttendeeBadgePosterSelect from './EventAttendeeBadgePosterSelect';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  const badgeDesign = eventAttendeeBadgeDesignsSelector(folderId)(state);

  const imageId = badgeDesign?.widget_data?.POSTER?.value;

  return {
    projectId,
    folderId,
    badgeDesign,
    selectedBackgroundPoster: filesItemDataSelector(imageId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSelectPosterModal: () => {
      dispatch(
        ModalDuck.toggleModalVisibility(
          dispatch,
          LOADINGS.EVENT_ATTENDEE_BADGE.SHOW_EVENT_ATTENDEE_BADGE_SELECT_POSTER_MODAL,
          true,
        ),
      );
    },

    onRemove: (projectId, folderId, previousDesign) => {
      dispatch(
        editEventAttendeeBadgeWidgetDesigns(dispatch, projectId, folderId, {
          badgeDesignData: previousDesign,
          widgetDesignData: {
            [WIDGET_TYPE.POSTER]: { ...previousDesign?.widget_data?.[WIDGET_TYPE.POSTER], value: '' },
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgePosterSelect);
