import {
  Alert,
  ALERT_SIZES,
  ALERT_STYLES,
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Divider,
  FadeIn,
  FileUploader,
  FormErrorType,
  Row,
  SimpleImage,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { BrowserUtils, ErrorTracker } from '@premagic/utils';
import { useEffect, useMemo, useState } from 'react';
import { toastMessage } from '../../../../../reducers/ToastStore';

type FaceIdNotMatchErrorMessageProps = {
  show: boolean;
  errorMessage: string;
};

function FaceIdNotMatchErrorMessage(props: FaceIdNotMatchErrorMessageProps) {
  const { errorMessage, show } = props;

  if (!errorMessage || !show) return null;

  return (
    <FadeIn>
      <Alert style={ALERT_STYLES.WARNING} size={ALERT_SIZES.SM}>
        {errorMessage}
      </Alert>
      <Space vertical />
    </FadeIn>
  );
}

enum PERSON_NUMBER {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

type Props = {
  value?: Array<string>;
  onChange: (newFilterValue: Array<string>) => void;
  getFaceId: (projectId: string, blobURL: string) => void;
  isLoading: boolean;
  projectId: string;
  errors: FormErrorType;
  photoToFaceId: Record<string, string>;
  clearError: () => void;
};

export default function DAMLibraryPeopleFilterByFileUploadSelector(props: Props): JSX.Element {
  const { value, onChange, errors, getFaceId, isLoading, projectId, photoToFaceId, clearError } = props;

  // These are all faceIds of both by name and upload photo filter
  const allFaceIds: Array<string> = useMemo(() => (Array.isArray(value) ? value : [value]) as Array<string>, [value]);

  // This contains all the faceId which is currently in the filter and added using the photo upload option
  // item in 0,1,2... index refers to faceId for 1st, 2nd, 3rd... person.
  const photoUploadAllSelectedFaceIds: Array<string> = useMemo(
    () => allFaceIds.filter((id) => id && Object.values(photoToFaceId).includes(id)),
    [allFaceIds, photoToFaceId],
  );

  const initialPersonToFileMappingState: Record<PERSON_NUMBER, string> = useMemo(() => {
    function getFaceUrlFromFaceId(faceId) {
      return Object.entries(photoToFaceId).reduce((acc, [url, itemFaceId]) => {
        if (faceId === itemFaceId) return url;
        return acc;
      }, '');
    }

    return Object.fromEntries(
      Object.values(PERSON_NUMBER).map((personNum) => [
        personNum,
        getFaceUrlFromFaceId(photoUploadAllSelectedFaceIds[Number(personNum) - 1]), // because 0 index in photoUploadAllSelectedFaceIds refers to first person
      ]),
    ) as Record<PERSON_NUMBER, string>;
  }, [photoToFaceId, photoUploadAllSelectedFaceIds]);

  // stores the person number for which we are currently uploading the photo
  const [personNumberToGetTheFaceIdFor, setPersonNumberToGetTheFaceIdFor] = useState<PERSON_NUMBER>(
    PERSON_NUMBER.FIRST,
  );

  const [selectedFileUrl, setSelectedFileURL] = useState<Record<PERSON_NUMBER, string>>(
    initialPersonToFileMappingState,
  );

  function handleFileUpload(blobUrl: string, personNumber: PERSON_NUMBER) {
    if (!projectId) return;

    setPersonNumberToGetTheFaceIdFor(personNumber);
    getFaceId(projectId, blobUrl);
    setSelectedFileURL((prev) => ({ ...prev, [personNumber]: blobUrl }));
  }

  // 1. whenever a new photo is uploaded, we fetch the faceId for that photo and store it in photoToFaceId
  // 2. We then add that faceId to the filter (url query params) if it is not added
  // 3. in this useEffect The persons faceId should only be added to the url when its previous persons faceId is already added
  useEffect(() => {
    // since numeric enums create reverse mapping, hence adding typeof number filter to get only the values
    const allUploadedPhotosFaceIds = Object.values(PERSON_NUMBER)
      .filter((item) => typeof item === 'number')
      .map((personNumber) => photoToFaceId[selectedFileUrl[personNumber]]);

    // find the first uploadedFaceId which is not already present in the current url query params (filter)
    const faceIdToAddToFilter = allUploadedPhotosFaceIds.find((id) => !photoUploadAllSelectedFaceIds.includes(id));
    if (faceIdToAddToFilter) {
      onChange([...allFaceIds, faceIdToAddToFilter]);
    }
  }, [photoUploadAllSelectedFaceIds, selectedFileUrl]);

  useEffect(() => {
    clearError();
  }, [clearError]);

  return (
    <>
      {!photoUploadAllSelectedFaceIds?.length && <Text>From photo</Text>}

      {Object.values(PERSON_NUMBER).map((personNumber) => (
        <div key={personNumber}>
          {/* if the previous persons photo is uploaded and the current one is not uploaded yet */}
          {selectedFileUrl[Number(personNumber) - 1] && !selectedFileUrl[personNumber] && (
            <div>
              <Space vertical size={2} />
              <Divider />
              <Space vertical size={2} />
              <Text boldness={TEXT_BOLDNESS.BOLD}>Select another person?</Text>
              <Space vertical size={2} />
            </div>
          )}

          {/* Show the error next to the person number image */}
          <FaceIdNotMatchErrorMessage
            errorMessage={errors?.message}
            show={personNumberToGetTheFaceIdFor === personNumber}
          />

          {/* If previous person image is uploaded or it's the first person to upload then show the photo uploader */}
          {(selectedFileUrl[Number(personNumber) - 1] || personNumber === PERSON_NUMBER.FIRST) && (
            <Row center>
              {selectedFileUrl[personNumber] && (
                <SimpleImage
                  src={selectedFileUrl[personNumber]}
                  alt="Photo"
                  style={{ width: 120, height: 120, borderRadius: 8, objectFit: 'cover' }}
                />
              )}
              <Space />
              <FileUploader
                maxSize={10}
                accept="image/jpeg, image/png"
                onError={(message, e) => {
                  toastMessage('error', message, 200);
                  ErrorTracker.logError('Invalid file format', e);
                }}
                onChange={(e, files) => {
                  if (files.length) {
                    const file = files[0];
                    const blobUrl = BrowserUtils.createURLFromBlob(file);
                    handleFileUpload(blobUrl, personNumber as PERSON_NUMBER);
                  }
                  return {};
                }}
              >
                <Button
                  style={selectedFileUrl[personNumber] ? BUTTON_STYLES.LINK : BUTTON_STYLES.TERTIARY}
                  size={selectedFileUrl[personNumber] ? BUTTON_SIZE.SM : BUTTON_SIZE.MD}
                  isLoading={isLoading}
                >
                  {selectedFileUrl[personNumber] ? 'Change' : 'Select'}
                </Button>
              </FileUploader>
            </Row>
          )}
          <Space vertical />
        </div>
      ))}
    </>
  );
}
