import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECTS');

export const setProjectSearchTerm = createAction(
  getActionType('PROJECT_SEARCH', 'SET'),
  (dispatch, searchTerm: string) => searchTerm,
);

type StateType = {
  projectSearchTerm: string;
};

const initialState = {
  projectSearchTerm: '',
};

export default handleActions(
  {
    [setProjectSearchTerm.toString()]: (state, action: { payload }) => ({
      ...state,
      projectSearchTerm: action.payload,
    }),
  },
  initialState,
);

export const projectsDataListSelector = (state) => state.pages.projectsList;

export const projectSearchTermSelector = createSelector(
  projectsDataListSelector,
  (state: StateType) => state.projectSearchTerm,
);
