import { QRCodeBackgroundPosterService } from '@premagic/core';
import { BUTTON_SIZE, BUTTON_STYLES, BUTTON_TILE_SIZE, Button, ButtonTile, Row, Space } from '@premagic/myne';
import QRCodeBackgroundPosterContainer from '../../background-poster/QRCodeBackgroundPosterContainer';

type Props = {
  backgroundPoster: QRCodeBackgroundPosterService.QRCodePoster;
  colors?: QRCodeBackgroundPosterService.PosterColorType;
  openPosterSelectModal: () => void;
};

export default function QRCodeBackgroundPosterSelector(props: Props) {
  const { backgroundPoster, colors, openPosterSelectModal } = props;
  return (
    <Row columnDirection center vcenter>
      <ButtonTile onClick={openPosterSelectModal} size={BUTTON_TILE_SIZE.SM_XS}>
        <QRCodeBackgroundPosterContainer posterId={backgroundPoster.id} colors={colors} isPreview />
      </ButtonTile>
      <Space vertical size={2} />
      <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={openPosterSelectModal}>
        Change
      </Button>
    </Row>
  );
}
