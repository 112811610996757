import { LocalCacheUtils } from '@premagic/utils';
import React, { useState } from 'react';
import { Layer, Line, Rect, Text } from 'react-konva';
import Guidelines from './Guidelines';

function getTickSize(index: number, unitSize: number) {
  if (index % 100 === 0) {
    return unitSize * 2;
  }
  if (index % 10 === 0) {
    return unitSize;
  }
  return unitSize * 0.5;
}
function getTickColor(index: number) {
  if (index % 100 === 0) {
    return '#fff';
  }
  if (index % 10 === 0) {
    return '#fff';
  }
  return '#aaa';
}

type Props = {
  width?: number;
  height?: number;
  unitSize?: number;
  tickInterval?: number;
  direction?: 'horizontal' | 'vertical';
  scale: number;
};

export default function Ruler(props: Props) {
  const { width = 800, height = 20, unitSize = 10, tickInterval = 10, direction = 'horizontal', scale } = props;

  const isHorizontal = direction === 'horizontal';
  const CACHE_KEY = `poster-creator-guides-${width}-${height}`;
  const [guides, setGuides] = useState<number[]>(LocalCacheUtils.getCache<number[]>(CACHE_KEY) || []);

  function addGuide(e) {
    const pos = (isHorizontal ? e.evt.layerX : e.evt.layerY) * (scale || 1);
    setGuides([...guides, pos]);
    LocalCacheUtils.setCache(CACHE_KEY, [...guides, pos]);
  }

  const numTicks = isHorizontal ? width / tickInterval / (scale || 1) : height / tickInterval / (scale || 1);

  const ticks = Array.from({ length: numTicks + 1 }).map((_, i) => {
    const pos = i * tickInterval * (scale || 1);
    const tickSize = getTickSize(i, unitSize);
    const tickColor = getTickColor(i);

    const tickStart = isHorizontal ? [pos, height] : [width, pos];
    const tickEnd = isHorizontal ? [pos, height - tickSize] : [width - tickSize, pos];

    const key = `tick-${i}`;

    return <Line key={key} points={[...tickStart, ...tickEnd]} stroke={tickColor} strokeWidth={1} />;
  });

  // Generate labels using map
  const labels = Array.from({ length: Math.floor(numTicks / 10) + 1 }).map((_, i) => {
    const pos = i * 10 * tickInterval * (scale || 1);
    const labelPos = isHorizontal ? [pos + 4, height - unitSize - 5] : [width - unitSize - 13, pos - 12];
    const key = `label-${i}`;
    return (
      <Text
        key={key}
        x={labelPos[0]}
        y={labelPos[1]}
        text={String(Math.floor(i * 10 * unitSize))}
        fontSize={10}
        fill="#fff"
        fontFamily="'Source Sans Pro', sans-serif'"
      />
    );
  });

  return (
    <Layer width={width} height={height} x={0} y={0} onClick={addGuide}>
      <Rect width={width} height={height} fill="black" opacity={0.8} />
      {ticks}
      {labels}
      <Guidelines guides={guides} setGuides={setGuides} isHorizontal={isHorizontal} scale={scale} />
    </Layer>
  );
}
