import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { hasFeatureSelector } from '../company/CompanyDataDuck';
import { integrationsEventClientSettingsSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';
import EventIntegrationSettings from './EventIntegrationSettings';

function mapStateToProps(state, ownProps) {
  return {
    isLoading: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    integrationEventsSettings: integrationsEventClientSettingsSelector(state),
    hasEventIntegrationFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_INTEGRATION)(
      state,
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventIntegrationSettings);
