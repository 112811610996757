import {
  Button,
  BUTTON_STYLES,
  Col,
  Color,
  ColorPicker,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import { useEffect, useState } from 'react';

type Props = {
  onChange: (color: string) => void;
  disabled: boolean;
  aiPickedDominantColors?: Array<Array<number>>;
  defaultBackgroundColor?: string;
  initBackgroundColor: (backgroundColor: string) => void;
};

export default function HighlightDesignBackgroundColorSelector(props: Props) {
  const { onChange, disabled, aiPickedDominantColors, defaultBackgroundColor, initBackgroundColor } = props;

  // we will not show auto color option for now
  const [autoColorOptionEnable, setAutoColorOptionEnable] = useState(false);

  const [selectedColor, setSelectedColor] = useState(defaultBackgroundColor || '#fff');
  const [aiColorPickEnabled, setAiColorPickEnabled] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const selectedAiColor = (aiPickedDominantColors && aiPickedDominantColors[0]?.join(',')) || '0,0,0';
  const dominantColorInHex = ColorUtils.convertToHex(`rgb(${selectedAiColor})`);

  const adjustedColor = ColorUtils.lightenColorWithConstantAmount(dominantColorInHex, 0.89);

  function handleAiColorPick() {
    setSelectedColor(adjustedColor);
    onChange(adjustedColor);
    setAiColorPickEnabled(true);

    setIsAnimated(true);
    setTimeout(() => {
      setIsAnimated(false);
    }, 2000);
  }

  // when the selected color changes, we will change the global theme background color in the stylesheet
  useEffect(() => {
    initBackgroundColor(selectedColor);
  }, [selectedColor, initBackgroundColor]);

  // // To handle Gallery Preset color change
  useEffect(() => {
    if (defaultBackgroundColor) {
      setSelectedColor(defaultBackgroundColor);
    }
  }, [defaultBackgroundColor, initBackgroundColor]);

  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>Background Color</Text>
      <Space vertical size={1} />
      <Row vcenter>
        {aiColorPickEnabled ? (
          <>
            <Row vcenter>
              <Row vcenter>
                <Color shade={isAnimated ? COLOR_SHADES.DARK : COLOR_SHADES.YELLOW} fillStrokeIcon={!isAnimated}>
                  <Icon name={isAnimated ? 'zap__animated' : 'zap'} size={ICON_SIZES.MD_SM} />
                </Color>
                <Space size={1} />
                <Text boldness={TEXT_BOLDNESS.BOLD} animate={isAnimated}>
                  AI COLOR
                </Text>
              </Row>
              <div style={{ height: '44px' }} />
            </Row>

            <Col rightAlighed size={null}>
              <Button
                style={BUTTON_STYLES.LINK}
                onClick={() => {
                  setAiColorPickEnabled(false);
                }}
              >
                Pick Color Manually?
              </Button>
            </Col>
          </>
        ) : (
          <>
            <ColorPicker
              name="highlight_background_color"
              defaultValue={defaultBackgroundColor}
              onChange={(color) => {
                onChange(color);
                setSelectedColor(color);
              }}
              overlayPlacement="right"
              disabled={disabled}
            />

            {autoColorOptionEnable ? (
              <Col rightAlighed size={null}>
                <Button
                  style={BUTTON_STYLES.LINK}
                  onClick={() => {
                    handleAiColorPick();
                  }}
                  disabled={disabled}
                >
                  <Icon name="zap" size={ICON_SIZES.MD_SM} />
                  <Space size={1} />
                  AI COLOR PICK
                </Button>
              </Col>
            ) : null}
          </>
        )}
      </Row>
    </div>
  );
}
