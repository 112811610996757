import { IntegrationService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { setModalOptions, toggleModalVisibility } from '../../../../../common/ModalDuck';
import { integrationsEventClientSettingsSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { INTEGRATION_CONNECT_OPTION } from '../integration-modal/IntegrationStepService';
import IntegrationImportEventButton from './IntegrationImportEventButton';

function mapStateToProps(state, ownProps: { platform: IntegrationService.INTEGRATION_PLATFORMS }) {
  const { platform } = ownProps;
  return {
    platform,
    integrationSettings: integrationsEventClientSettingsSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.EVENT.ADD)(state) || isLoadingSelector(LOADINGS.INTEGRATION.CONNECT_EVENT)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEventsList: (platform: IntegrationService.INTEGRATION_PLATFORMS) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, true));
      dispatch(
        setModalOptions(dispatch, MODALS.INTEGRATION.IMPORT_EVENT, {
          platform,
          connectOption: INTEGRATION_CONNECT_OPTION.CREATE_EVENT_AND_CONNECT,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationImportEventButton);
