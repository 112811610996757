import { ModalDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { eventAttendeesSelector } from '../EventAttendeesDataDuck';
import { setEditStatusOfAttendee } from '../EventAttendeesPageDuck';
import AttendeeApprovalStatus from './AttendeeApprovalStatus';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    editable?: boolean;
  },
) {
  const { id, editable = true } = ownProps;

  return {
    id,
    editable,
    attendee: eventAttendeesSelector(state)[id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditAttendee: (id: string) => {
      dispatch(setEditStatusOfAttendee(dispatch, id));
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_EDIT_STATUS, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeApprovalStatus);
