import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import ClientPayments from './ClientPayments';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import {
  crmPendingClientPaymentIdsSelector,
  crmPendingClientPaymentTotalSelector,
  crmSettledClientPaymentIdsSelector,
  crmSettledClientPaymentTotalSelector,
  updatePayment,
} from '../CRMPaymentDataDuck';
import { LOADINGS, MODALS } from '../../../../../../common/Constants';
import { CRMPaymentType } from '../CRMPaymentService';

function mapStateToProps(state) {
  return {
    pendingPaymentIds: crmPendingClientPaymentIdsSelector(state),
    totalPending: crmPendingClientPaymentTotalSelector(state),
    settledPaymentIds: crmSettledClientPaymentIdsSelector(state),
    totalSettled: crmSettledClientPaymentTotalSelector(state),
    showMarkAsPaidConfirmModal: ModalDuck.isModalOpenSelector(MODALS.MARK_AS_PAID_CONFIRM_MODAL)(state),
    modalOptions: ModalDuck.modalOptionsSelector<{
      paymentId: string;
      eventId: string;
      data: {
        data: CRMPaymentType;
      };
    }>(MODALS.MARK_AS_PAID_CONFIRM_MODAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_CLIENT_PAYMENT_PANEL, true));
    },
    markASPaidConfirm: (modalOptions, notifyClient) => {
      const { paymentId, eventId, data } = modalOptions;
      dispatch(updatePayment(dispatch, { paymentId, eventId, notifyClient }, data));
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.MARK_AS_PAID_CONFIRM_MODAL, false));
    },
    closeModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.MARK_AS_PAID_CONFIRM_MODAL, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPayments);
