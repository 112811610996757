import { FormLabel, LoadingDots, Row, Select } from '@premagic/myne';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { ProjectService } from '@premagic/core';
import { toastMessage } from '../../../../../reducers/ToastStore';

type Props = {
  value?: Array<string>;
  onChange: (newFilterValue: Array<string>) => void;
  projects: Array<ProjectService.ProjectType>;
  initData: () => void;
  isLoading: boolean;
};

export default function DAMLibraryProjectsFilterSelector(props: Props): JSX.Element {
  const { value, onChange, projects, initData, isLoading } = props;

  useEffect(() => {
    // when we open a particular event we reset the projects data to that single project on redux. Hence fetching projects list when it is less than 1.
    if (projects.length <= 1) initData();
  }, [initData, projects.length]);

  const projectOptions = useMemo(
    () =>
      projects?.map((item) => ({
        value: item.project_id,
        label: item.project_name,
      })),
    [projects],
  );
  const selectedOptions = projectOptions.find((item) => value?.includes(item.value));

  const debouncedOnChange = useMemo(() => debounce(onChange, 700), [onChange]);

  function handleOnChange(option) {
    if (option.value) debouncedOnChange([option.value]);
    toastMessage('info', 'Event selected! Please apply a filter to proceed.');
  }

  return (
    <div>
      <FormLabel>Event</FormLabel>
      {isLoading ? (
        <Row center>
          <LoadingDots size="sm" />
        </Row>
      ) : (
        <Select
          options={projectOptions}
          value={selectedOptions}
          name="project_id"
          onChange={handleOnChange}
          isMulti={false}
          autoFocus
        />
      )}
    </div>
  );
}
