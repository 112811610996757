// This is bridge between @premagic/code/GlobalVariableService and utils
// As Utils functions are used inside the core lib, we cannot use Core functions inside the Utils

type GlobalPMVariable = {
  clientName: string;
  APP_VERSION: string;
  ACCOUNT_ID?: string;
  TEMP_SHOW_OLD_JUSTIFIED_LAYOUT: boolean;
};

export function getGlobalVariableForPM(): GlobalPMVariable {
  return (window as unknown as { PM: GlobalPMVariable }).PM || {};
}
