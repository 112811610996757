import { COLOR_SHADES, Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';
import React from 'react';
import MESSAGES from '../../../../../../common/Messages';
import { EventAttendeesInsightType } from './EventAttendeesDataDuck';

type Props = {
  eventAttendeesInsight: EventAttendeesInsightType;
  hasEnabledApprovalWorkflow: boolean;
  hasFaceCheckinEnabled: boolean;
  hasPhotoDistributionEnabled: boolean;
};

export default function EventAttendeeListHeader(props: Props): JSX.Element {
  const { eventAttendeesInsight, hasEnabledApprovalWorkflow, hasPhotoDistributionEnabled, hasFaceCheckinEnabled } =
    props;
  const {
    total,
    totalApproved,
    totalPendingApproval,
    totalWaitingList,
    totalRejected,

    totalPhotoDelivery,
    totalPhotoDeliveryPending,
    totalNotCheckedIn,
    totalCheckedIn,
    totalPeopleType,
  } = eventAttendeesInsight;

  if (hasEnabledApprovalWorkflow) {
    return (
      <div>
        <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
          {totalApproved}
          <Space size={1} />
          <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARKER}>
            Going
          </Text>
        </Text>
        <Space vertical size={1} />
        <Row>
          {!!totalPendingApproval && (
            <div>
              <Text size={TEXT_SIZE.SIZE_4} color={COLOR_SHADES.DARKER}>
                {totalPendingApproval} Pending
              </Text>
              <Space size={8} />
            </div>
          )}
          {!!totalWaitingList && (
            <div>
              <Text size={TEXT_SIZE.SIZE_4} color={COLOR_SHADES.DARKER}>
                {totalWaitingList} Waiting list
              </Text>
              <Space size={8} />
            </div>
          )}
          {!!totalRejected && (
            <Text size={TEXT_SIZE.SIZE_4} color={COLOR_SHADES.DARKER}>
              {totalRejected} Rejected
            </Text>
          )}
        </Row>
      </div>
    );
  }

  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
        {total}
        <Space size={1} />
        <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARKER}>
          {MESSAGES.EVENT_ATTENDEES.NAME}
        </Text>
      </Text>
    </div>
  );
}
