import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { InnerPage, InnerPageContent, InnerPageHeader, InnerPageSubHeader, TabItem, Tabs } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';

import APP_URLS from '../../../services/AppRouteURLService';
import ClientWebsiteDetailsLayoutContainer from './ClientWebsiteDetailsLayoutContainer';
import ClientWebsiteWishesLayoutContainer from '../client-wishes/ClientWebsiteWishesLayoutContainer';
import ClientWebsiteClientPhotosLayoutContainer from '../client-photos/ClientWebsiteClientPhotosLayoutContainer';
import ClientWebsiteShareButtonContainer from './ClientWebsiteShareButtonContainer';
import ClientWebsiteUserRegistrationLayoutContainer from '../user-registration/ClientWebsiteUserRegistrationLayoutContainer';
import MESSAGES from '../../../../../common/Messages';

type Props = {
  initData: (id: string, projectId: string) => void;
  isLoading: boolean;
  projectId: string;
  eventId?: string;
  id: string;
  currentUrlPathname: string;
  isCompanyTypeNotPhotographer: boolean;
};

export default function ClientWebsitePage(props: Props): React.ReactElement {
  const { initData, id, projectId, isLoading, currentUrlPathname, eventId, isCompanyTypeNotPhotographer } = props;
  useEffect(() => {
    initData(id, projectId);
  }, [id, initData, projectId]);

  let backToUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.DETAILS, { projectId });
  if (eventId) {
    backToUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
      eventId,
      focusId: id,
    });
  }

  const title = MESSAGES.CLIENT_WEBSITE.NAME;

  const urlForDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, {
    projectId,
    id,
  });
  const urlForWishes = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___WISH, {
    projectId,
    id,
  });
  const urlForPhotos = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___PHOTOS, {
    projectId,
    id,
  });
  const urlForRegistration = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___REGISTRATION, {
    projectId,
    id,
  });

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="gift"
        title={title}
        backTo={backToUrl}
        rightActions={<ClientWebsiteShareButtonContainer id={id} projectId={projectId} />}
      >
        {title}
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem to={urlForDetails} active={currentUrlPathname == urlForDetails}>
            Details
          </TabItem>
          {!isCompanyTypeNotPhotographer && (
            <TabItem to={urlForPhotos} active={currentUrlPathname == urlForPhotos}>
              Photos
            </TabItem>
          )}
          {!isCompanyTypeNotPhotographer && (
            <TabItem to={urlForWishes} active={currentUrlPathname == urlForWishes}>
              Wishes
            </TabItem>
          )}
          <TabItem to={urlForRegistration} active={currentUrlPathname == urlForRegistration}>
            Event Registration
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
      <InnerPageContent isLoading={isLoading} hasInnerCols>
        <Switch>
          <Route
            path={APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS}
            exact
            component={ClientWebsiteDetailsLayoutContainer}
          />
          <Route path={APP_URLS.CLIENT_WEBSITE.DETAILS___WISH} exact component={ClientWebsiteWishesLayoutContainer} />
          <Route
            path={APP_URLS.CLIENT_WEBSITE.DETAILS___PHOTOS}
            exact
            component={ClientWebsiteClientPhotosLayoutContainer}
          />
          <Route
            path={APP_URLS.CLIENT_WEBSITE.DETAILS___REGISTRATION}
            exact
            component={ClientWebsiteUserRegistrationLayoutContainer}
          />
        </Switch>
      </InnerPageContent>
    </InnerPage>
  );
}
