import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils, NumberUtils, BrowserUrlUtils } from '@premagic/utils';
import {
  CompanyService,
  PricingPlanService,
  RouterService,
  SubscriptionService,
  CompanyStatsService,
  PricingPlanFeaturesService,
  ClientSettingsService,
  EventTrackerService,
} from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { setPurchaseOrder } from '../pricing-plan/PricingPlansDataDuck';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { addAppAlert } from '../../app-alerts/AccountAppAlertsDataDuck';
import { ACCOUNT_APP_ALERT_TYPE } from '../../app-alerts/AccountAppAlertService';
import MESSAGES from '../../../../../common/Messages';
import { setCompanyStatsData } from '../../company/company-stats/CompanyStatsDataDuck';
import APP_URLS from '../../../services/AppRouteURLService';

const getActionType = ActionTypeUtils.getActionTypeFunction('SUBSCRIPTIONS');

export const setSubscriptionData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const getAIDeliveryAlerts = createAction(
  getActionType('AI_DELIVERY_ALERT', 'SET'),
  (
    dispatch,
    options: {
      company: CompanyService.CompanyType;
      companyStats: CompanyStatsService.CompanyStatsType;
      clientSettings: ClientSettingsService.ClientSettingsType;
    },
  ) => {
    const { company, companyStats, clientSettings } = options;
    const hasAIDeliveryFeature = CompanyService.hasFeature(
      company,
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY,
    );
    if (!hasAIDeliveryFeature) return;
    const remainingPhotos = CompanyService.remainingAIDeliveryPhotos({
      pricingPlan: company.currentPricingPlan,
      companyStats,
      clientSettings,
    });
    const isCompanyTypeNotPhotographer = ClientSettingsService.isCompanyTypeNotPhotographer(clientSettings);
    const warningLimit = isCompanyTypeNotPhotographer ? 1000 : 10000;
    if (remainingPhotos < 0) {
      return dispatch(
        addAppAlert(dispatch, {
          id: 'ai-photos-limit',
          title: `You have exceed AI Photos for your plan by ${NumberUtils.getNumberInFormat(
            remainingPhotos * -1,
          )}. Please upgrade your plan to continue using AI Photos.`,
          type: ACCOUNT_APP_ALERT_TYPE.DANGER,
          readOnly: true,
        }),
      );
    }
    if (remainingPhotos < warningLimit) {
      dispatch(
        addAppAlert(dispatch, {
          id: 'ai-photos-limit',
          title: `You are almost out of AI photos by ${NumberUtils.getNumberInFormat(
            remainingPhotos,
          )}. Please upgrade your plan to continue using AI Photos.`,
          type: ACCOUNT_APP_ALERT_TYPE.WARNING,
          readOnly: true,
        }),
      );
    }
  },
);

export const getCurrentSubscription = createAction(
  getActionType('DATA', 'GET'),
  async (
    dispatch,
    options: {
      clientSettings: ClientSettingsService.ClientSettingsType;
      company: CompanyService.CompanyType;
      userId: string;
    },
  ) => {
    const { clientSettings, company, userId } = options;
    const COMMON_KEY = LOADINGS.SUBSCRIPTION.FETCH;
    dispatch(toggleLoadingState(dispatch, COMMON_KEY, true));
    dispatch(clearErrorState(dispatch, COMMON_KEY));
    try {
      const companyStats = await CompanyStatsService.fetchCompanyStats();
      dispatch(setCompanyStatsData(dispatch, companyStats));
      dispatch(
        getAIDeliveryAlerts(dispatch, {
          clientSettings,
          companyStats,
          company,
        }),
      );

      const razorpaySubscription = await SubscriptionService.fetchCurrentSubscription();
      const subscription = razorpaySubscription.subscription_id
        ? razorpaySubscription
        : SubscriptionService.getSubscriptionFromCompanyData(company, companyStats);
      EventTrackerService.updateUserInfo(userId, {
        subscription,
        subscription_provider: subscription.subscription_provider,
        subscription_start_date: subscription.subscription_start_date,
        subscription_end_date: subscription.next_billing_date,
      });
      dispatch(setSubscriptionData(dispatch, subscription));

      if (!SubscriptionService.hasActiveSubscription(subscription)) {
        const expiredPage = BrowserUrlUtils.getRouteUrlFor(APP_URLS.ACCOUNT.SUBSCRIPTIONS, {});
        RouterService.navigateTo(dispatch, expiredPage);

        dispatch(
          addAppAlert(dispatch, {
            id: 'subscription',
            title: MESSAGES.SUBSCRIPTIONS.INACTIVE,
            type: ACCOUNT_APP_ALERT_TYPE.DANGER,
            readOnly: true,
          }),
        );
      }

      dispatch(toggleLoadingState(dispatch, COMMON_KEY, false));
    } catch (e) {
      dispatch(setErrorState(dispatch, COMMON_KEY, e));
      dispatch(toggleLoadingState(dispatch, COMMON_KEY, false));
      ErrorTracker.logError('FETCH SUBSCRIPTION', e);
    }
  },
);

export const createSubscription = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch, planId: string, variantId: PricingPlanService.PricingPlanVariantId) => {
    const COMMON_KEY = `${LOADINGS.PRICING_PLAN_ORDER}__${planId}`;
    dispatch(toggleLoadingState(dispatch, COMMON_KEY, true));
    dispatch(clearErrorState(dispatch, COMMON_KEY));

    SubscriptionService.createSubscriptionForThePlan(planId, variantId)
      .then((response) => {
        dispatch(setPurchaseOrder(dispatch, { subscriptionId: response.subscription_id }));
        dispatch(toggleLoadingState(dispatch, COMMON_KEY, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, COMMON_KEY, e));
        dispatch(toggleLoadingState(dispatch, COMMON_KEY, false));
        ErrorTracker.logError('Create Subscription: create failed', e);
      });
  },
);

type StateType = {
  item: SubscriptionService.SubscriptionType;
};

const initialState = {
  item: undefined,
};

export default handleActions(
  {
    [setSubscriptionData.toString()]: (state, action: { payload }) => ({
      ...state,
      item: action.payload,
    }),
  },
  initialState,
);

const subscriptionDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.subscriptions as StateType,
);

export const currentSubscriptionSelector = createSelector(subscriptionDataSelector, (state) => state.item);
export const hasActiveSubscriptionSelector = createSelector(currentSubscriptionSelector, (item) =>
  SubscriptionService.hasActiveSubscription(item),
);
