import { connect } from 'react-redux';
import { EventPosterService } from '@premagic/core';
import {
  deleteEventPosterWidget,
  eventPostersSelector,
  eventPosterWidgetsArraySelector,
  updateEventPosterWidget,
} from '../../crm/event-posters/EventPostersDataDuck';
import {
  EVENT_POSTER_TAB,
  selectedEventPosterWidgetSelector,
  setActiveSidebarView,
  setSelectedPosterWidget,
} from '../EventPosterCreatorDuck';
import EventPosterCreatorLayers from './EventPosterCreatorLayers';

function mapStateToProps(
  state,
  ownProps: { projectId: string; posterId: string; type: EventPosterService.EVENT_POSTER_TYPE },
) {
  const { projectId, posterId, type } = ownProps;
  const poster = eventPostersSelector(state)[posterId];
  return {
    projectId,
    posterId,
    poster,
    type,
    widgets: eventPosterWidgetsArraySelector(posterId)(state),
    selectedEventPosterWidgetId: selectedEventPosterWidgetSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedWidget: (widgetId) => dispatch(setSelectedPosterWidget(dispatch, widgetId)),
    editWidget: ({ projectId, poster, widgetToUpdate }) => {
      dispatch(
        updateEventPosterWidget(dispatch, projectId, {
          widgetToUpdate,
          poster,
        }),
      );
    },
    deleteWidget: ({ projectId, poster, id }) => {
      dispatch(
        deleteEventPosterWidget(dispatch, projectId, {
          widgetId: id,
          poster,
        }),
      );
    },
    switchToDesignTab: () => {
      dispatch(setActiveSidebarView(dispatch, EVENT_POSTER_TAB.DESIGN));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterCreatorLayers);
