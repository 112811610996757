import { connect } from 'react-redux';
import { companyLogoSelector, photographerNameSelector } from './CompanyDataDuck';
import CompanyLogo from './CompanyLogo';

function mapStateToProps(
  state,
  ownProps: {
    size?: 'xlg' | 'default' | 'md' | 'sm' | 'xs';
  },
) {
  return {
    logo: companyLogoSelector(state),
    name: photographerNameSelector(state),
    size: ownProps.size,
  };
}

export default connect(mapStateToProps)(CompanyLogo);
