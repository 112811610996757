import { EventAttendeesService } from '@premagic/core';
import { Col, LIST_ITEM_STYLES, ListItem, TEXT_SIZE, Text } from '@premagic/myne';
import React from 'react';

type Props = { attendeeData?: EventAttendeesService.EventAttendeeType; slNo: number };

export default function EventAttendeeWithoutPhotoAlertItem(props: Props) {
  const { attendeeData, slNo } = props;
  const { name: userName, custom_fields: customFields } = attendeeData || {};

  const { USER_COMPANY: userCompany, USER_ROLE: userRole, face_asset: faceAsset } = customFields || {};
  return (
    <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
      <Col size={1}>
        <Text muted size={TEXT_SIZE.SIZE_5}>
          {slNo}
        </Text>
      </Col>
      <Col size={5}>
        <Text muted size={TEXT_SIZE.SIZE_5}>
          {userName}
        </Text>
      </Col>
      <Col size={4}>
        <Text muted size={TEXT_SIZE.SIZE_5}>
          {userRole}
        </Text>
      </Col>

      <Col size={null} rightAlighed>
        <Text muted size={TEXT_SIZE.SIZE_5}>
          {userCompany}
        </Text>
      </Col>
    </ListItem>
  );
}
