import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_ACCENT_STYLES,
  ICON_SIZES,
  Row,
  Section,
  SECTION_STYLES,
  Space,
  Text,
  TEXT_BUTTON_STYLES,
  TEXT_SIZE,
  TextButton,
  Tooltip,
} from '@premagic/myne';

import { FolderService, HelpArticlesService, ProjectService } from '@premagic/core';
import { BrowserUrlUtils, NumberUtils, StringUtils } from '@premagic/utils';

import APP_URLS from '../../../../services/AppRouteURLService';
import { HelpLink } from '../../../settings/HelpLInk';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  signatureFolderIds: Array<string>;
  hasSignatureAlbum: boolean;
  projectId: string;
  project: ProjectService.ProjectType;
  folderFilesStats: FolderService.FolderTypeStats;
  isCompanyTypeNotPhotographer: boolean;
};

export default function SignatureAlbumCardHeader(props: Props) {
  const { project, folderFilesStats, projectId, hasSignatureAlbum, signatureFolderIds, isCompanyTypeNotPhotographer } =
    props;
  const { total_image: totalFiles, rejected: hiddenFiles } = folderFilesStats;
  const { mpin } = project || {};
  const rejectedPhotosUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT__HIDDEN_PHOTOS, {
    projectId,
  });

  const settingsUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.HIGHLIGHT, {
    projectId,
    folderId: signatureFolderIds[0],
  });

  if (!hasSignatureAlbum) {
    return (
      <Section style={SECTION_STYLES.YELLOW_LIGHT} hasPadding={false}>
        <Space>
          <Row vcenter>
            <Color shade={COLOR_SHADES.DARKER} inline>
              <Icon name="signature_album" accentStyle={ICON_ACCENT_STYLES.FILL} />
            </Color>
            <Space size={1} />
            <Text primaryStyle size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} block>
              {isCompanyTypeNotPhotographer
                ? MESSAGES.SIGNATURE_FOLDER.NAME_FOR_EVENT_ORGANIZER
                : MESSAGES.SIGNATURE_FOLDER.NAME}
            </Text>
          </Row>
          {!isCompanyTypeNotPhotographer && (
            <Text block>
              {MESSAGES.SIGNATURE_FOLDER.INFO}{' '}
              <HelpLink articleId={HelpArticlesService.ARTICLES.FLASH_ALBUM}> learn more.</HelpLink>
            </Text>
          )}
        </Space>
      </Section>
    );
  }

  return (
    <Section style={SECTION_STYLES.YELLOW_LIGHT} hasPadding={false}>
      <Space>
        <Row>
          <Col size={8}>
            <Row vcenter>
              <Color shade={COLOR_SHADES.DARKER} inline>
                <Icon name="signature_album" accentStyle={ICON_ACCENT_STYLES.FILL} />
              </Color>
              <Space size={1} />
              <Text primaryStyle size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} block>
                {isCompanyTypeNotPhotographer
                  ? MESSAGES.SIGNATURE_FOLDER.NAME_FOR_EVENT_ORGANIZER
                  : MESSAGES.SIGNATURE_FOLDER.NAME}
              </Text>
            </Row>
            <Row vcenter>
              <Text>
                <Text color={COLOR_SHADES.DARKER}>{NumberUtils.getNumberInFormat(totalFiles)}</Text>{' '}
                {StringUtils.pluralize('Photos & Video', totalFiles)}
              </Text>
              <Space size={1} />
              {!!hiddenFiles && (
                <>
                  <Tooltip
                    message={`Client had decided to hide ${hiddenFiles} ${StringUtils.pluralize(
                      'Photo',
                      hiddenFiles,
                    )} from the gallery`}
                  >
                    <Text>
                      (
                      <TextButton link={rejectedPhotosUrl} style={TEXT_BUTTON_STYLES.LINK}>
                        {NumberUtils.getNumberInFormat(hiddenFiles)} Hidden
                      </TextButton>
                      )
                    </Text>
                  </Tooltip>
                  <Space size={4} />
                </>
              )}
            </Row>
          </Col>

          <Col size={null} rightAlighed>
            <Button link={settingsUrl} style={BUTTON_STYLES.TERTIARY}>
              <Icon name="edit" size={ICON_SIZES.SM} /> <Space size={1} /> Edit Album
            </Button>
          </Col>
        </Row>
      </Space>
    </Section>
  );
}
