import { FileService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  Input,
  Textarea,
} from '@premagic/myne';
import ImageKeyPeopleSelectContainer from './ImageKeyPeopleSelectContainer';
import ImageTagsSelectContainer from './ImageTagsSelectContainer';

type Props = {
  id: string;
  file?: FileService.FolderFileType;
  projectId: string;
  folderId?: string;
  updateSaveMeta: (
    options: {
      projectId: string;
      folderId: string;
      fileId: string;
    },
    data: FormResponseType,
  ) => void;
  isLoading: boolean;
};

export default function ImagesMetaForm(props: Props) {
  const { updateSaveMeta, folderId, id, projectId, file, isLoading } = props;

  const { meta } = file || {};

  return (
    <Form
      onSubmit={(formResponse) => {
        if (folderId && projectId) {
          updateSaveMeta(
            {
              fileId: id,
              folderId,
              projectId,
            },
            formResponse,
          );
        }
      }}
    >
      <FormGroup>
        <FormLabel>Description</FormLabel>
        <Textarea name="description" defaultValue={meta?.description} />
      </FormGroup>

      <ImageKeyPeopleSelectContainer values={meta?.key_people || []} projectId={projectId} />
      <ImageTagsSelectContainer values={meta?.tags || []} projectId={projectId} />

      <FormGroup>
        <FormLabel info="User will be directed to the link when they click on the photo in the signature album.">
          Clickable link
        </FormLabel>
        <Input name="product_link" defaultValue={meta?.product_link} type="url" placeholder="premagic.ai" />
      </FormGroup>

      <FormFooter>
        <Button type="submit" style={BUTTON_STYLES.TERTIARY} isLoading={isLoading}>
          Save
        </Button>
      </FormFooter>
    </Form>
  );
}
