import { useMemo, useState } from 'react';

import { EventSponsorsService, ProjectMetaService, SponsorCreativesService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  Col,
  COLOR_SHADES,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  LoadingDots,
  PillItem,
  Pills,
  PILLS_STYLES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';

import SponsorCreativeItemContainer from './SponsorCreativeItemContainer';

type SponsorTitleProps = {
  value?: string;
  isSaving: boolean;
  onSave: (val?: string) => void;
};

function SponsorTitle(props: SponsorTitleProps) {
  const { value, isSaving, onSave } = props;
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [sponsorTitle, setSponsorTitle] = useState(value);

  if (onEdit) {
    return (
      <Row>
        <Col size={5}>
          <Row vcenter>
            <Input
              defaultValue={sponsorTitle}
              onChange={(e) => setSponsorTitle(e.target.value)}
              size={INPUT_SIZES.SM}
            />
            <Space size={2} />
            <Button onClick={() => setOnEdit(false)} style={BUTTON_STYLES.CANCEL} size={BUTTON_SIZE.XS}>
              Cancel
            </Button>
            <Button
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isSaving}
              size={BUTTON_SIZE.XS}
              onClick={() => {
                onSave(sponsorTitle);
                setOnEdit(false);
              }}
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Space size={3}>
      <Row vcenter>
        <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          {value}
        </Text>
        <Space size={2} />
        <Button onClick={() => setOnEdit(true)} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.XS}>
          Edit
        </Button>
      </Row>
    </Space>
  );
}

const SPONSOR_GROUP_TYPES = {
  [SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.CATEGORY]: {
    id: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.CATEGORY,
    title: 'Category',
  },
  [SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.TIER]: {
    id: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.TIER,
    title: 'Tier',
  },
};

type GroupByTypeSelectorProps = {
  handleChange: (option: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE) => void;
  selectedGroupByType?: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE;
  isSaving?: boolean;
};

function GroupByTypeSelector(props: GroupByTypeSelectorProps) {
  const { handleChange, selectedGroupByType, isSaving } = props;

  return (
    <Row vcenter>
      <FormLabel>Group by:</FormLabel>
      <Space size={2} />
      {isSaving ? (
        <LoadingDots size="xs" />
      ) : (
        <Pills style={PILLS_STYLES.SECONDARY}>
          {Object.values(SPONSOR_GROUP_TYPES).map((item) => (
            <PillItem key={item.id} active={selectedGroupByType === item.id} onClick={() => handleChange(item.id)}>
              <Text
                size={TEXT_SIZE.SIZE_5}
                boldness={TEXT_BOLDNESS.SEMI_BOLD}
                color={selectedGroupByType === item.id ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
              >
                {item.title}
              </Text>
            </PillItem>
          ))}
        </Pills>
      )}
    </Row>
  );
}

type GroupedCreativeItemsProps = {
  projectId: string;
  creativesArray: Array<[string, Array<string>]>;
  sponsorCategoriesData?: Record<string, EventSponsorsService.EventSponsorCategoryType>;
  sponsorFooterGroupByType?: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE;
  creativeIdToSponsorCategoryMapData?: Map<string, EventSponsorsService.EventSponsorCategoryType>;
};

function GroupedCreativeItems(props: GroupedCreativeItemsProps) {
  const {
    creativesArray,
    sponsorFooterGroupByType,
    sponsorCategoriesData,
    creativeIdToSponsorCategoryMapData,
    projectId,
  } = props;

  const isCategoryGroupType =
    sponsorFooterGroupByType === SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.CATEGORY;
  const isTierGroupType = sponsorFooterGroupByType === SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.TIER;
  const centerAlign = false;

  return (
    <>
      {creativesArray.map(([groupId, creativeIds]) => (
        <div key={groupId}>
          {isCategoryGroupType && (
            <Row>
              <Space size={2} />
              <Text block boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                {sponsorCategoriesData?.[groupId]?.name}
              </Text>
            </Row>
          )}
          <Space vertical size={2} />
          <Row center={centerAlign} wrap>
            {creativeIds.map((creativeId) => (
              <Space size={2} key={creativeId}>
                <Row vcenter columnDirection>
                  {isTierGroupType && (
                    <>
                      <Text block boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                        {creativeIdToSponsorCategoryMapData?.get(creativeId)?.name}
                      </Text>
                      <Space vertical size={1} />
                    </>
                  )}
                  <SponsorCreativeItemContainer id={creativeId} projectId={projectId} key={creativeId} />
                </Row>
              </Space>
            ))}
          </Row>
          <Space vertical />
        </div>
      ))}
    </>
  );
}

type Props = {
  isLoading: boolean;
  groupedCreativeIds: Record<string, Array<string>>;
  projectId: string;
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
  showAddCreative: (placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT) => void;
  sponsorCategoriesData?: Record<string, EventSponsorsService.EventSponsorCategoryType>;
  sponsorFooterGroupByType?: SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE;
  creativeIdToSponsorCategoryMapData?: Map<string, EventSponsorsService.EventSponsorCategoryType>;
  updateProjectMeta: (projectId: string, formResponse) => void;
  sponsorTitle?: string;
  isSaving: boolean;
};

export default function SponsorCreativeList(props: Props): JSX.Element | null {
  const {
    projectId,
    isLoading,
    groupedCreativeIds,
    showAddCreative,
    placement,
    sponsorCategoriesData,
    sponsorFooterGroupByType,
    creativeIdToSponsorCategoryMapData,
    updateProjectMeta,
    sponsorTitle,
    isSaving,
  } = props;
  const creativesArray = useMemo(() => Object.entries(groupedCreativeIds), [groupedCreativeIds]);
  const totalCreatives = useMemo(
    () => creativesArray.reduce((acc, [, creativeIds]) => acc + creativeIds.length, 0),
    [creativesArray],
  );

  function updateSponsorGroupType(option) {
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SPONSOR_FOOTER_GROUP_BY]: option,
      },
    });
  }
  function handleSponsorTitleChange(option) {
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SPONSOR_SECTION_TITLE]: option,
      },
    });
  }

  if (isLoading) return null;
  const placementDetails = SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT_DETAILS[placement];
  const isFooterPlacement = placement === SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT.FOOTER;

  return (
    <Card accordion>
      <Space>
        <CardHeader>
          <CardHeaderTitle>
            {placementDetails.label}
            <Space size={2} />
            <Text muted>({totalCreatives})</Text>
          </CardHeaderTitle>
        </CardHeader>
        <CardContent>
          <Row spaceBetween>
            <Button style={BUTTON_STYLES.PRIMARY} onClick={() => showAddCreative(placement)} size={BUTTON_SIZE.SM}>
              <Icon name="plus" size={ICON_SIZES.SM} /> {placementDetails.label} Creative
            </Button>

            {isFooterPlacement && (
              <GroupByTypeSelector
                handleChange={updateSponsorGroupType}
                selectedGroupByType={sponsorFooterGroupByType}
                isSaving={isSaving}
              />
            )}
          </Row>
          <Space vertical size={4} />
          {creativesArray.length === 0 && (
            <Text muted block>
              No creatives added yet
            </Text>
          )}
          {isFooterPlacement && (
            <div>
              <SponsorTitle value={sponsorTitle} isSaving={isSaving} onSave={handleSponsorTitleChange} />
              <Space vertical />
            </div>
          )}
          <GroupedCreativeItems
            projectId={projectId}
            creativesArray={creativesArray}
            sponsorFooterGroupByType={sponsorFooterGroupByType}
            sponsorCategoriesData={sponsorCategoriesData}
            creativeIdToSponsorCategoryMapData={creativeIdToSponsorCategoryMapData}
          />
        </CardContent>
      </Space>
    </Card>
  );
}
