import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  COLOR_SHADES,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  List,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import PaymentListItemContainer from '../payment-item/PaymentListItemContainer';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';

type ClientPaymentsTableProps = {
  paymentIds: Array<string>;
};

export function ExpensePaymentsTable(props: ClientPaymentsTableProps) {
  const { paymentIds } = props;
  if (paymentIds.length === 0) {
    return (
      <div>
        <Space vertical size={2} />
        <Row>
          <Space />
          <Text muted block>
            There are no expenses added
          </Text>
        </Row>
      </div>
    );
  }

  return (
    <List>
      {paymentIds.map((id) => (
        <PaymentListItemContainer paymentId={id} key={id} />
      ))}
    </List>
  );
}

type Props = {
  expensePaymentIds: Array<string>;
  unSettledTotal: number;
  total: number;
  showAddPanel: () => void;
};

export default function ExpensePayments(props: Props): JSX.Element {
  const { expensePaymentIds, unSettledTotal, total, showAddPanel } = props;
  return (
    <Card>
      <Space>
        <CardHeader hasPadding={false}>
          <CardHeaderTitle>
            Expenses <Text color={COLOR_SHADES.LIGHT}>{HTML_ICON.DOT}</Text>{' '}
            <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_4}>
              <AccountCurrencyContainer amount={total} />
            </Text>
            <Space size={1} />
            {!!unSettledTotal && (
              <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
                <AccountCurrencyContainer amount={unSettledTotal} />
              </Text>
            )}
          </CardHeaderTitle>
          <CardHeaderActions>
            <Button style={BUTTON_STYLES.LINK} onClick={showAddPanel}>
              <Icon size={ICON_SIZES.SM} name="plus" />
              Add
            </Button>
          </CardHeaderActions>
        </CardHeader>
      </Space>
      <ExpensePaymentsTable paymentIds={expensePaymentIds} />
      <Space vertical />
    </Card>
  );
}
