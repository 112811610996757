import { MathUtils, MediaUtils } from '@premagic/utils';
import React, { useMemo } from 'react';
import { Group as CanvasGroup, Rect as CanvasRect } from 'react-konva';
import { PosterFileType } from '../services/PosterService';
import { POSTER_WIDGET_TYPE, PosterGuestImageGalleryType, PosterWidgetTextType } from '../services/PosterWidgetService';
import { PosterText } from './BaseWidgets';
import PosterGuestImage from './PosterGuestImage';

const CONFIG = {
  TOTAL_IMAGES: 9,
  TOTAL_IMAGES_IN_ROW: 3,
};

function getImagesGeometry(
  options: {
    imagesAspectRatios: Array<number>;
    width: number;
    height: number;
  },
  recalculatingOptions?: {
    maxHeight: number;
    index: number;
  },
) {
  const { imagesAspectRatios, width, height } = options;
  const { maxHeight = height, index: recalculatingIndex = 0 } = recalculatingOptions || {};
  const geometry = MediaUtils.getJustifiedLayoutPositionForImages(imagesAspectRatios, {
    containerWidth: width,
    containerPadding: {
      top: recalculatingIndex ? (maxHeight - height) / 1.5 : height * 0.05,
      bottom: height * 0.07,
      left: width * 0.07,
      right: width * 0.07,
    },
    boxSpacing: (width + height) * 0.02,
    targetRowHeightTolerance: 0,
    targetRowHeight: height / (CONFIG.TOTAL_IMAGES / CONFIG.TOTAL_IMAGES_IN_ROW), // this is to make sure its contained in the height
    maxNumRows: CONFIG.TOTAL_IMAGES / 3,
    widowLayoutStyle: 'center',
  });

  if (geometry.containerHeight > maxHeight) {
    return getImagesGeometry(
      {
        imagesAspectRatios,
        width,
        height: height - height * 0.05,
      },
      {
        maxHeight,
        index: recalculatingIndex + 1,
      },
    );
  }

  return geometry;
}

type Props = {
  data: PosterGuestImageGalleryType;
  files: Record<string, PosterFileType>;
  isEditable?: boolean;
};

const PosterGuestImageGallery = React.forwardRef((props: Props, ref: React.Ref<any>) => {
  const { data, files, isEditable = false, ...rest } = props;
  const { position, size, value, id } = data;
  const { width, height = 0 } = size;
  const eachImageTitle = 5;

  const images = useMemo(() => {
    const imagesAspectRatios =
      value?.length > 0
        ? value.map((imageId) => files[imageId]?.meta?.ratio || 1)
        : Array(CONFIG.TOTAL_IMAGES).fill(MathUtils.getRandomNumber(0.5, 2, true));

    const imagesGeometry = getImagesGeometry({ imagesAspectRatios, width, height });

    return imagesGeometry.boxes.map((box, idx) => {
      const key = `${id}-image-${idx}`;

      return (
        <PosterGuestImage
          key={key}
          data={{
            ...data,
            value: value?.[idx],
            type: POSTER_WIDGET_TYPE.GUEST_IMAGE,
            size: {
              width: box.width,
              height: box.height,
            },
            position: {
              x: position.x + box.left,
              y: position.y + box.top,
              z: position.z,
              rotation: MathUtils.getRandomNumber(eachImageTitle * -1, eachImageTitle, false),
            },
          }}
          files={files}
        />
      );
    });
  }, [data, files, height, id, position, value, width]);

  if (isEditable) {
    const color = '#fff';
    const placeHolderColor = '#4a90e2';
    const fontsize = width * 0.07; // scale based on the width
    const photoFrameProps = {
      fill: placeHolderColor,
      stroke: color,
      shadowColor: '#888',
      shadowBlur: 8,
      shadowOffset: {
        x: 0,
        y: 0,
      },
      shadowOpacity: 0.6,
    };
    const textProps: PosterWidgetTextType = {
      type: POSTER_WIDGET_TYPE.TEXT,
      id: 'text',
      name: 'text',
      font_color: 'rgba(255,255,255,0.8)',
      position: {
        x: position.x + fontsize / 2,
        y: position.y + fontsize / 2,
        z: 3,
        rotation: position.rotation,
      },
      size: {
        width: Math.min(fontsize * 10, width),
        height: fontsize,
      },
      font_size: fontsize,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    return (
      <CanvasGroup>
        <CanvasRect
          x={position.x}
          y={position.y}
          z={position.z}
          width={width}
          height={height}
          {...photoFrameProps}
          rotation={position.rotation}
          ref={ref}
          {...rest}
        />
        <PosterText data={{ ...textProps, value: 'Guest gallery photo' }} />
      </CanvasGroup>
    );
  }

  return <CanvasGroup>{images}</CanvasGroup>;
});

export default PosterGuestImageGallery;
