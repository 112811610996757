import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';
import { FormErrorType } from '@premagic/myne';

import ProposalSharePanel from './ProposalSharePanel';
import { clientItemForEventSelector } from '../../../crm/events/EventsDataDuck';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL.SHARE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  const proposal = ProposalDataDuck.proposalsItemsSelectors(state)[folderId];
  const clientDetails = proposal.event_id ? clientItemForEventSelector(proposal.event_id)(state) : undefined;

  return {
    projectId,
    folderId,
    client: clientDetails,
    proposal,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(PANEL_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(PANEL_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    shareFoldersWithClient: (options, formResponse) => {
      dispatch(ProposalDataDuck.shareProposalFolderWithClient(dispatch, options, formResponse));
    },
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSharePanel);
