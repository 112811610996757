import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';

import { MODALS } from '../../../../common/Constants';
import HelpNavButton from './HelpNavButton';

function mapStateToProps(state) {
  return {
    show: WindowPanelDuck.isWindowPanelOpenSelector(MODALS.HELP_PANEL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: (show) => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, MODALS.HELP_PANEL, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpNavButton);
