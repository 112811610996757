import {
  BackgroundMusicService,
  ClientWebsiteClipArtService,
  ClientWebsiteReligiousClipArtService,
} from '@premagic/core';
import { INPUT_TYPES } from '@premagic/myne';
import i18next, { t } from 'i18next';
import { THEMES_IDS } from './ClientWebsiteThemeItem';
import { THEMES_STYLE_IDS } from './ClientWebsiteThemeStyleItem';

// @TODO: remove this and use ClientWebsiteFormService from @premagic/core

export enum CLIENT_WEBSITE_FORM_ITEMS {
  WEBSITE_ID,
  PHOTO_THUMBNAIL,
  PHOTO_COUPLE_1,
  PHOTO_COUPLE_1_LG,
  PHOTO_COUPLE_2,
  PHOTO_BRIDE,
  PHOTO_GROOM,
  NAME_BRIDE,
  BRIDE_INFO,
  NAME_GROOM,
  GROOM_INFO,
  EVENT_TAGLINE,
  EVENT_CONTACT_PHONE,
  EVENT_INSTRUCTIONS,
  EVENT_VIDEO_LINK,
  EVENT_TRAILER_VIDEO_LINK,
  EVENT_GALLERY_LINK,
  CLIPART_ID,
  RELIGIOUS_CLIPART_ID,
  THEME_ID,
  THEME_COLOR_BACKGROUND,
  THEME_COLOR_PRIMARY,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_TERTIARY,
  THEME_FONT_PRIMARY,
  THEME_FONT_SECONDARY,
  MUSIC_ID,
  DETAILED_TAGLINE,
  PHOTO_GRID,
  SLOGAN,
  USER_REGISTRATION_FOOT_NOTE,
  THEME_STYLE,
  PHOTO,
  CTA_BG,
  METRICS,
  PHOTO_REGISTRATION_TITLE,
}

export enum CLIENT_WEBSITE_CUSTOM_INPUT_TYPES {
  THEME_SELECT = 'THEME_SELECT',
  CLIPART_SELECT = 'CLIPART_SELECT',
  MUSIC_SELECT = 'MUSIC_SELECT',
  RELIGIOUS_CLIPART_SELECT = 'RELIGIOUS_CLIPART_SELECT',
  PHOTO_GRID = 'PHOTO_GRID',
  THEME_STYLE = 'THEME_STYLE',
  FONT = 'FONT',
  PHOTO_ITEM = 'PHOTO_ITEM',
  METRICS = 'METRICS',
}

type ClientWebsiteFormItemType = {
  title: string;
  name: string;
  secondaryInputName?: string;
  type: INPUT_TYPES | CLIENT_WEBSITE_CUSTOM_INPUT_TYPES;
  placeholder?: string;
  defaultValue?: string | number | ((val, val2?) => string | number);
  maxLength?: number;
  accept?: string;
  readOnly?: boolean;
  info?: string;
  required?: boolean;
};

export type ClientWebsiteFormItemImageType = ClientWebsiteFormItemType & {
  nameUsedToSendToServer?: string;
  type: INPUT_TYPES.IMAGE;
};

const CLIENT_WEBSITE_IMAGES = {
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_THUMBNAIL]: {
    title: 'Thumbnail',
    info: 'This photo will be used on social media. Size: 1200 pixels x 627 pixels (1.91/1 ratio)',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][thumbnail]',
    nameUsedToSendToServer: 'thumbnail',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_1]: {
    title: 'Couple Photo',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][couple1]',
    nameUsedToSendToServer: 'couple1',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_1_LG]: {
    title: 'Couple Photo large',
    info: 'The photo will be used for desktop',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][couple1_large]',
    nameUsedToSendToServer: 'couple1_large',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_BRIDE]: {
    title: 'Bride Photo',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][bride]',
    nameUsedToSendToServer: 'bride',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_GROOM]: {
    title: 'Groom Photo',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][groom]',
    nameUsedToSendToServer: 'groom',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_2]: {
    title: 'Couple Photo Gallery',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][couple2]',
    nameUsedToSendToServer: 'couple2',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_GRID]: {
    title: 'Couple Photo Gallery grid',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_GRID,
    name: 'data[images][photo_grid]',
    nameUsedToSendToServer: 'photo_grid',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO]: {
    title: 'Photo',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][photo]',
    nameUsedToSendToServer: 'photo',
    accept: '.jpg,.jpeg',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.CTA_BG]: {
    title: 'CTA Background',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM,
    name: 'data[images][cta_bg]',
    nameUsedToSendToServer: 'cta_bg',
    accept: '.jpg,.jpeg',
  },
};

const CLIENT_WEBSITE_EVENT_ITEMS = {
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_TAGLINE]: {
    title: 'Tagline',
    type: INPUT_TYPES.TEXT,
    defaultValue: "We can't wait to celebrate our special day with you",
    maxLength: 250,
    placeholder: '',
    name: 'data[tagline]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_CONTACT_PHONE]: {
    title: 'Guest Contact phone number',
    type: INPUT_TYPES.TEL,
    placeholder: '',
    name: 'data[contact][phone]',
  },

  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_INSTRUCTIONS]: {
    title: 'Instruction',
    maxLength: 250,
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: '',
    name: 'data[instruction]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_VIDEO_LINK]: {
    title: 'LiveStream video link',
    type: INPUT_TYPES.URL,
    placeholder: '',
    name: 'data[livestream_link]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_TRAILER_VIDEO_LINK]: {
    title: 'Highlight video',
    info: 'Add the embed url',
    type: INPUT_TYPES.URL,
    placeholder: '',
    name: 'data[trailer_video_link]',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.EVENT_GALLERY_LINK]: {
    title: 'Gallery url',
    info: 'This will show up in the bottom',
    type: INPUT_TYPES.URL,
    placeholder: 'Your Signature album link',
    name: 'data[gallery_link]',
  },
};

export const CLIENT_WEBSITE_FORM_ITEMS_DETAILS: Record<
  CLIENT_WEBSITE_FORM_ITEMS,
  ClientWebsiteFormItemType | ClientWebsiteFormItemImageType
> = {
  ...CLIENT_WEBSITE_IMAGES,
  ...CLIENT_WEBSITE_EVENT_ITEMS,
  [CLIENT_WEBSITE_FORM_ITEMS.WEBSITE_ID]: {
    title: 'URL name',
    name: 'website_id',
    type: INPUT_TYPES.TEXT,
    placeholder: 'janewedsjohn',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.NAME_BRIDE]: {
    title: 'Bride Name',
    name: 'data[bride_name]',
    type: INPUT_TYPES.TEXT,
    placeholder: 'jane',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.NAME_GROOM]: {
    title: 'Groom Name',
    name: 'data[groom_name]',
    type: INPUT_TYPES.TEXT,
    placeholder: 'John',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.RELIGIOUS_CLIPART_ID]: {
    title: 'Religious Clip Art',
    name: 'theme[religious_clipart_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.RELIGIOUS_CLIPART_SELECT,
    defaultValue: ClientWebsiteReligiousClipArtService.RELIGIOUS_CLIP_ARTS_IDS.NONE,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.CLIPART_ID]: {
    title: 'Clip Art',
    name: 'theme[clipart_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.CLIPART_SELECT,
    defaultValue: ClientWebsiteClipArtService.CLIP_ART_IDS.MAUNDAYS_BAY,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_ID]: {
    title: 'Theme',
    name: 'theme[id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.THEME_SELECT,
    defaultValue: THEMES_IDS.BAIKAL,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_BACKGROUND]: {
    title: 'Background',
    name: 'theme[color][background]',
    type: INPUT_TYPES.COLOR,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_PRIMARY]: {
    title: 'Primary color',
    name: 'theme[color][primary_color]',
    type: INPUT_TYPES.COLOR,
    defaultValue: '#7B90BF',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_SECONDARY]: {
    title: 'Secondary color',
    name: 'theme[color][secondary_color]',
    type: INPUT_TYPES.COLOR,
    defaultValue: '#E6EEFF',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_COLOR_TERTIARY]: {
    title: 'Accent color',
    name: 'theme[color][tertiary_color]',
    type: INPUT_TYPES.COLOR,
    defaultValue: '#D4D4D4',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_FONT_PRIMARY]: {
    title: 'Primary font',
    name: 'theme[font][primary_font]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.FONT,
    readOnly: true,
    defaultValue: 'Snell Roundhand',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_FONT_SECONDARY]: {
    title: 'Secondary font',
    name: 'theme[font][secondary_font]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.FONT,
    readOnly: true,
    defaultValue: 'Poppins',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.MUSIC_ID]: {
    title: 'Music',
    name: 'theme[music_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.MUSIC_SELECT,
    defaultValue: BackgroundMusicService.BACKGROUND_MUSICS_IDS.NONE,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.GROOM_INFO]: {
    title: 'Groom info',
    name: 'data[groom_info]',
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: 'Son of ...',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.PHOTO_REGISTRATION_TITLE]: {
    title: 'Registration page title',
    name: 'data[photo_registration_title]',
    type: INPUT_TYPES.RICH_TEXTAREA,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.DETAILED_TAGLINE]: {
    title: 'Description',
    name: 'data[detailed_tagline]',
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: 'Whats the event about?',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.BRIDE_INFO]: {
    title: 'Bride info',
    name: 'data[bride_info]',
    type: INPUT_TYPES.RICH_TEXTAREA,
    placeholder: 'Daughter of ...',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.SLOGAN]: {
    title: 'Slogan',
    name: 'data[slogan]',
    type: INPUT_TYPES.TEXT,
    placeholder: 'Shree Ganeshaya Namaha',
  },
  [CLIENT_WEBSITE_FORM_ITEMS.USER_REGISTRATION_FOOT_NOTE]: {
    title: 'Registration foot note',
    placeholder:
      'By clicking submit, you give us permission to use AI facial recognition to securely deliver event photos directly to you.',
    name: 'data[user_registration_foot_note]',
    type: INPUT_TYPES.RICH_TEXTAREA_INLINE_SIMPLE,
    defaultValue: (langCode: string) =>
      `<p>${i18next.t(
        'by_clicking_submit_you_give_us_permission_to_use_ai_facial_recognition_to_securely_deliver_event_photos_directly_to_you',
        { lng: langCode },
      )}</p>`,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.THEME_STYLE]: {
    title: 'Couple photo layout',
    name: 'theme[style_id]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.THEME_STYLE,
    defaultValue: THEMES_STYLE_IDS.DEFAULT,
  },
  [CLIENT_WEBSITE_FORM_ITEMS.METRICS]: {
    title: 'Metrics Data',
    name: 'data[metrics]',
    type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.METRICS,
    defaultValue: '',
  },
};
//   name: 'data[photo_registration_title]', to [data, photo_registration_title]
export function getKeysFromName(key: string): Array<string> {
  return key.split(/[[\]]/).filter((item) => item);
}
