import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  FileUploader,
  FormGroup,
  FormLabel,
  Row,
  SimpleImage,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { ErrorTracker } from '@premagic/utils';
import { CompanyService } from '@premagic/core';
import { useState } from 'react';
import { toastMessage } from '../../../reducers/ToastStore';

type LogoUploadProps = {
  src?: string;
  name: CompanyService.COMPANY_LOGOS;
  isSaving: boolean;
  uploadCompanyLogo: (name: CompanyService.COMPANY_LOGOS, file: File) => void;
  removeCompanyLogo: (name: CompanyService.COMPANY_LOGOS) => void;
  dimensions?: {
    width?: number;
    height?: number;
  };
};

const BOX_STYLES: Record<
  CompanyService.COMPANY_LOGOS,
  {
    borderColor: string;
    backgroundColor: string;
  }
> = {
  [CompanyService.COMPANY_LOGOS.LOGO]: {
    borderColor: '#4d6178',
    backgroundColor: '#fff',
  },
  [CompanyService.COMPANY_LOGOS.LOGO_WHITE]: {
    borderColor: '#fff',
    backgroundColor: '#000',
  },
  [CompanyService.COMPANY_LOGOS.FAVICON]: {
    borderColor: '#4d6178',
    backgroundColor: '#fff',
  },
};

function LogoUpload(props: LogoUploadProps) {
  const { src, name, uploadCompanyLogo, removeCompanyLogo, isSaving, dimensions } = props;
  const boxStyle = BOX_STYLES[name];

  return (
    <div>
      {src && (
        <div
          style={{
            border: `1px dashed ${boxStyle.borderColor}`,
            display: 'inline-flex',
            background: boxStyle.backgroundColor,
            borderRadius: '4px',
            maxWidth: '160px',
          }}
        >
          <SimpleImage src={src} alt={name} style={{ height: `${dimensions?.height || 56}px` }} />
        </div>
      )}
      <Space vertical size={2} />
      <Row>
        <FileUploader
          accept="image/jpeg, image/png"
          onError={(message, e) => {
            toastMessage('error', message, 200);
            ErrorTracker.logError('Invalid file format', e);
          }}
          onChange={(e, files) => {
            if (files.length) uploadCompanyLogo(name, files[0]);
            return {};
          }}
          dimensions={dimensions}
        >
          <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} isLoading={isSaving}>
            {src ? 'Change' : 'Set'}
          </Button>
        </FileUploader>
        {src && (
          <>
            <Space />
            <Button
              style={BUTTON_STYLES.LINK}
              size={BUTTON_SIZE.MD}
              isLoading={isSaving}
              onClick={() => {
                removeCompanyLogo(name);
              }}
            >
              Remove
            </Button>
          </>
        )}
      </Row>
    </div>
  );
}

type Props = {
  company: CompanyService.CompanyType;
  isSaving: Record<CompanyService.COMPANY_LOGOS, boolean>;
  uploadCompanyLogo: (name: CompanyService.COMPANY_LOGOS, file: File) => void;
  removeCompanyLogo: (name: CompanyService.COMPANY_LOGOS) => void;
  showJustPrimaryLogo?: boolean;
};

export default function CompanyLogoUpload(props: Props) {
  const { company, isSaving, uploadCompanyLogo, removeCompanyLogo, showJustPrimaryLogo } = props;
  const { logo, logo_white: logoWhite, favicon } = company;
  if (showJustPrimaryLogo) {
    return (
      <FormGroup>
        <FormLabel>Company Logo</FormLabel>
        <Col>
          <Text muted block>
            The logo should be of <Text color={COLOR_SHADES.DARKER}>56px height and 160px width</Text> and make sure it
            fits perfectly in the box
          </Text>
          <Space vertical size={1} />
          <LogoUpload
            uploadCompanyLogo={uploadCompanyLogo}
            removeCompanyLogo={removeCompanyLogo}
            name={CompanyService.COMPANY_LOGOS.LOGO}
            src={logo}
            isSaving={isSaving[CompanyService.COMPANY_LOGOS.LOGO]}
          />
        </Col>
      </FormGroup>
    );
  }

  return (
    <Card>
      <Space>
        <FormGroup>
          <FormLabel>
            Logos
            <Text muted block>
              The logo should be of <Text color={COLOR_SHADES.DARKER}>56px height and 160px width</Text> width and make
              sure it perfectly fit in the box
            </Text>
          </FormLabel>
          <Space vertical />
          <Row>
            <Col>
              <Text block boldness={TEXT_BOLDNESS.BOLD}>
                Logo For White Background
              </Text>
              <Space vertical size={1} />
              <LogoUpload
                uploadCompanyLogo={uploadCompanyLogo}
                removeCompanyLogo={removeCompanyLogo}
                name={CompanyService.COMPANY_LOGOS.LOGO}
                src={logo}
                isSaving={isSaving[CompanyService.COMPANY_LOGOS.LOGO]}
              />
            </Col>
            <Col>
              <Text block boldness={TEXT_BOLDNESS.BOLD}>
                Logo For Black Background
              </Text>
              <Space vertical size={1} />
              <LogoUpload
                uploadCompanyLogo={uploadCompanyLogo}
                removeCompanyLogo={removeCompanyLogo}
                name={CompanyService.COMPANY_LOGOS.LOGO_WHITE}
                src={logoWhite}
                isSaving={isSaving[CompanyService.COMPANY_LOGOS.LOGO_WHITE]}
              />
            </Col>
          </Row>
          <Space vertical size={4} />
          <Text block boldness={TEXT_BOLDNESS.BOLD}>
            Browser logo/Favicon
          </Text>
          <Text muted block>
            Browser logo/Favicon resolution must be a 32x32.
          </Text>
          <Space vertical size={1} />
          <LogoUpload
            uploadCompanyLogo={uploadCompanyLogo}
            removeCompanyLogo={removeCompanyLogo}
            name={CompanyService.COMPANY_LOGOS.FAVICON}
            src={favicon}
            isSaving={isSaving[CompanyService.COMPANY_LOGOS.FAVICON]}
            dimensions={{ width: 32, height: 32 }}
          />
        </FormGroup>
      </Space>
    </Card>
  );
}
