import { GeneralEditor, GeneralEditorContent, GeneralEditorSidebar, LoadingDots, Space } from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import React, { useEffect } from 'react';
import EditEventPosterPanelContainer from '../crm/event-posters/create/EditEventPosterPanelContainer';
import EventPosterCreatorSidebarContainer from './sidebar/EventPosterCreatorSidebarContainer';
import EventPosterPreviewLayoutContainer from './EventPosterPreviewLayoutContainer';
import EventPosterFilePickerModalContainer from './widget-selector/file-picker/EventPosterFilePickerModalContainer';
import EventPosterCreatorHeaderContainer from './EventPosterCreatorHeaderContainer';

type Props = {
  eventId: string;
  posterId?: string;
  projectId: string;
  brandingFolderId?: string;
  fetchPosterImages: (projectId: string, folderId: string) => void;
  fetchPoster: (projectId: string, posterId: string) => void;
  isLoading: boolean;
  hasProject: boolean;
  fetchProject: (projectId: string) => void;
  type: EventPosterService.EVENT_POSTER_TYPE;
};

export default function EventPosterCreatorMainLayout(props: Props) {
  const {
    eventId,
    posterId,
    projectId,
    fetchPosterImages,
    brandingFolderId,
    fetchPoster,
    isLoading,
    hasProject,
    fetchProject,
    type,
  } = props;

  useEffect(() => {
    if (brandingFolderId) fetchPosterImages(projectId, brandingFolderId);
  }, [projectId, fetchPosterImages, brandingFolderId]);

  useEffect(() => {
    if (posterId) fetchPoster(projectId, posterId);
  }, [projectId, fetchPoster, posterId]);

  useEffect(() => {
    // fetch project if the user refreshes the page, and there are no project in the store. We need the project to get the branding folder details
    if (isLoading) return;
    if (!hasProject) fetchProject(projectId);
  }, [fetchProject, hasProject, isLoading, projectId]);

  return (
    <>
      <GeneralEditor rightPanelSize="md" hasLeftPanel={false}>
        <EventPosterCreatorHeaderContainer projectId={projectId} posterId={posterId} eventId={eventId} type={type} />
        <GeneralEditorContent>
          {isLoading ? (
            <Space>
              <LoadingDots />
            </Space>
          ) : (
            <EventPosterPreviewLayoutContainer projectId={projectId} posterId={posterId} />
          )}
        </GeneralEditorContent>
        <GeneralEditorSidebar isRight>
          <EventPosterCreatorSidebarContainer projectId={projectId} posterId={posterId} type={type} />
        </GeneralEditorSidebar>
      </GeneralEditor>
      <EditEventPosterPanelContainer projectId={projectId} type={type} />
      {posterId && <EventPosterFilePickerModalContainer projectId={projectId} posterId={posterId} />}
    </>
  );
}
