import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export enum COMPANY_STATS_TYPE {
  AI_DELIVERY = 'ai_delivery',
}

export const COMPANY_STATS_TYPE_DETAIL: Record<COMPANY_STATS_TYPE, { title: string }> = {
  [COMPANY_STATS_TYPE.AI_DELIVERY]: {
    title: 'AI delivery',
  },
};

export type CompanyStatsType = {
  [COMPANY_STATS_TYPE.AI_DELIVERY]: {
    assets_scanned: number;
  };
};

export function fetchCompanyStats(): Promise<CompanyStatsType> {
  const URL = API_URLS.COMPANY.STATUS;

  return HttpUtils.get(URL).then((response) => response.data);
}
