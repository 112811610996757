import { createAction, handleActions } from 'redux-actions';
import {
  CompanyService,
  FeatureToggleService,
  GlobalVariableService,
  PricingPlanFeaturesService,
} from '@premagic/core';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { toastMessage } from '../../../reducers/ToastStore';
import { removeCompanyLogoService, saveCompanyInfoService, saveCompanyLogoService } from './CompanyService';
import { entitiesDataSelector } from '../../../reducers/selectors';
import { companyStatsSelector } from '../../company/company-stats/CompanyStatsDataDuck';
import { clientSettingsDataSelector } from '../preferences/ClientSettingsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('COMPANY');

export const setCompanyData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => {
  GlobalVariableService.setGlobalVariableForPM({
    CUSTOMER_ID: data.id,
    clientName: data.name,
  });

  return data;
});

export const saveCompanyData = createAction(getActionType('DATA', 'SAVE'), (dispatch, data) => {
  const loadingKey = LOADINGS.COMPANY.SAVE_PROFILE;
  const { data: formData } = data;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  saveCompanyInfoService(formData)
    .then((response) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setCompanyData(dispatch, response));
      toastMessage('success', 'Company profile information saved!');
      return response;
    })
    .catch((e) => {
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      dispatch(setErrorState(dispatch, loadingKey, e));
      toastMessage('error', e.message || 'There are some error on the information you have entered');
      return e;
    });
});

export const uploadCompanyLogo = createAction(
  getActionType('LOGO', 'SAVE'),
  (dispatch, name: CompanyService.COMPANY_LOGOS, file: File) => {
    const loadingKey = LOADINGS.COMPANY.UPLOAD_LOGO(name);

    const formData = new FormData();
    formData.append(name, file);

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    saveCompanyLogoService(formData)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setCompanyData(dispatch, response));
        return response;
      })
      .catch((e) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setErrorState(dispatch, loadingKey, e));
        return e;
      });
  },
);

export const removeCompanyLogo = createAction(
  getActionType('LOGO', 'REMOVE'),
  (dispatch, name: CompanyService.COMPANY_LOGOS) => {
    const loadingKey = LOADINGS.COMPANY.UPLOAD_LOGO(name);

    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    removeCompanyLogoService(name)
      .then((response) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setCompanyData(dispatch, response));
        return response;
      })
      .catch((e) => {
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(setErrorState(dispatch, loadingKey, e));
        return e;
      });
  },
);

type CompanyStateType = {
  data: CompanyService.CompanyType;
};

const initialState = {
  data: {},
};

export default handleActions(
  {
    [setCompanyData.toString()]: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
  initialState,
);

const companySelector = createSelector(entitiesDataSelector, (entities) => entities.company as CompanyStateType);

export const companyDataSelector = createSelector(companySelector, (company) => company.data);
export const companyEmailSelector = createSelector(companyDataSelector, (company) => company.email);
export const companyPhoneSelector = createSelector(companyDataSelector, (company) => company.phone);
export const showPremagicIntroSelector = createSelector(
  companyDataSelector,
  (company) => company.is_first_time_user || company?.usage?.totalProjects < 5,
);
export const currentPricingPlanSelector = createSelector(companyDataSelector, (company) => company.currentPricingPlan);
export const companyReferralCodeSelector = createSelector(companyDataSelector, (company) => company.referral_code);
export const companyRefereeCodeSelector = createSelector(companyDataSelector, (company) => company.referee_code);
export const companyDomainSelector = createSelector(companyDataSelector, (item) => item.domain);

export const companyLeadFormsURL = createSelector(companyDataSelector, (item) => {
  const url = item.custom_domain ? item.custom_domain : `${item.domain}.premagic.com`;
  const companyUrl = `${url}/company/`;
  return {
    contactCard: `${companyUrl}about/`,
    form: `${companyUrl}enquiry/`,
    portfolio: url,
    premagicDomainUrl: `${item.domain}.premagic.com`,
  };
});

export const photographerNameSelector = createSelector(companyDataSelector, (item) => item.name);
export const companyLogoSelector = createSelector(companyDataSelector, (company) => company.logo);

export const hasFeatureSelector = (featureId: PricingPlanFeaturesService.PRICING_PLAN_FEATURES) =>
  createSelector(companyDataSelector, (item) => {
    if (item.currentPricingPlan && item.currentPricingPlan.feature_list)
      return item.currentPricingPlan.feature_list.includes(featureId);
    return false;
  });

export const accountUsageDataSelector = createSelector(companyDataSelector, (company) => company.usage);

export const hasExceedProjectLimitSelector = createSelector(
  accountUsageDataSelector,
  (usage) => usage?.totalProjects >= usage?.availableProjects,
);

export const highlightPhotosLimitSelector = createSelector(
  currentPricingPlanSelector,
  (item) => item.photos_per_highlight || 0,
);

export const highlightVideosLimitSelector = createSelector(currentPricingPlanSelector, (item) => {
  if (
    FeatureToggleService.isToggleableFeatureEnabledForClient(FeatureToggleService.FEATURES_TOGGLES.HIGHER_VIDEO_LIMIT)
  ) {
    return 25;
  }
  return item.videos_per_highlight || 1;
});
export const proposalPhotosLimitSelector = createSelector(currentPricingPlanSelector, (currentPricingPlan) => {
  const isAdvancedProposal = currentPricingPlan?.feature_list.includes(
    PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CRM_PROPOSAL_MANAGEMENT_ADVANCED,
  );
  if (isAdvancedProposal) return 250;
  return 1;
});

export const highlightFoldersLimitSelector = createSelector(currentPricingPlanSelector, (item) => item.highlights);
export const isCompanyInSubscriptionPlan = createSelector(currentPricingPlanSelector, (item) => item?.is_subscription);

export const portfolioProjectLimitSelector = createSelector(
  currentPricingPlanSelector,
  (item) => item.portfolio_project_limit || 0,
);
export const remainingAiDeliveryPhotosSelector = createSelector(
  currentPricingPlanSelector,
  companyStatsSelector,
  clientSettingsDataSelector,
  (item, stats, clientSettings) =>
    CompanyService.remainingAIDeliveryPhotos({
      pricingPlan: item,
      companyStats: stats,
      clientSettings,
    }),
);

export const isPoweredByPremagicShownSelector = createSelector(
  clientSettingsDataSelector,
  hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.REMOVE_PREMAGIC_BRANDING),
  (clientSetting, hasFeature) =>
    'show_powered_by_premagic' in clientSetting ? clientSetting.show_powered_by_premagic : !hasFeature,
);
