import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import {
  editQRCodeDesigns,
  qrCodeDesignsSelector,
  qrCodeDesignsShowPoweredByPremagicSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodePosterPoweredByPremagicToggle from './QRCodePosterPoweredByPremagicToggle';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { folderId, projectId } = ownProps;
  return {
    showPoweredByPremagic: qrCodeDesignsShowPoweredByPremagicSelector(folderId)(state),
    folderId,
    projectId,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER_META.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    togglePoweredByPremagic: (projectId, folderId, qrCodeDesigns, value) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.SHOW_POWERED_BY_PREMAGIC]: value,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterPoweredByPremagicToggle);
