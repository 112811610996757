import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils, BrowserUtils } from '@premagic/utils';

import { commonUISelector } from '../selectors';

type DeviceState = {
  width: number;
  height: number;
  userAgent: string;
  hasWebpSupport: boolean;
};
const getActionType = ActionTypeUtils.getActionTypeFunction('DEVICE', true);

export const updateDeviceData = createAction(getActionType('DEVICE', 'UPDATE'), (data: DeviceState) => data);

export const setDeviceData = createAction(getActionType('DEVICE', 'SET'), async (dispatch: Dispatch) => {
  const height = window.innerHeight;
  document.documentElement.style.setProperty('--app-height', `${height}px`);

  const hasWebpSupport = await BrowserUtils.hasSupportForWebp();
  dispatch(
    updateDeviceData({
      width: document.body.clientWidth,
      height,
      userAgent: navigator.userAgent,
      hasWebpSupport,
    }),
  );
});

const initialState = {
  device: {
    width: 0,
    height: 0,
    userAgent: '',
    hasWebpSupport: false,
  },
};

export const DeviceReducer = handleActions(
  {
    [updateDeviceData.toString()]: (state, action: { payload: DeviceState }) => ({
      ...state,
      device: action.payload,
    }),
  },
  initialState,
);

export const deviceDataSelector = createSelector(
  commonUISelector,
  (state) => (state.device as { device: DeviceState }).device,
);

export const appWidth = createSelector(deviceDataSelector, (device) => device.width);
export const appHeight = createSelector(deviceDataSelector, (device) => device.height);
export const appUserAgent = createSelector(deviceDataSelector, (device) => device.userAgent);
export const hasWebpSupport = createSelector(deviceDataSelector, (device) => device.hasWebpSupport);

export const isMobileUp = createSelector(appUserAgent, appWidth, (userAgent, width) => {
  if (BrowserUtils.isMobileBrowser(userAgent)) return false;
  return width > 840;
});

export const isLandscapeView = createSelector(
  isMobileUp,
  appWidth,
  (isDesktop) => !isDesktop && window.matchMedia('(orientation: landscape)').matches,
);
