import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import ProjectRenameDialog from './ProjectRenameDialog';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { projectsSelector, updateProject } from '../../../projects/AccountProjectsDataDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';

const DIALOG_KEY = LOADINGS.PROJECT.SHOW_RENAME_DIALOG;
function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    project: projectsSelector(state)[projectId] || {},
    isPopoverShown: isModalOpenSelector(DIALOG_KEY)(state),
    isRenaming: isLoadingSelector(LOADINGS.PROJECT.UPDATE)(state),
    errors: errorObjectForTypeSelector(LOADINGS.PROJECT.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
    renameProject: (projectId: string, data) => {
      dispatch(updateProject(dispatch, projectId, 'RENAME', data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRenameDialog);
