import { useEffect, useState } from 'react';
import { GeneralEditor, GeneralEditorContent, GeneralEditorSidebar, LoadingDots, Row, Space } from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import EventAttendeeBadgeLayoutCenterComponentContainer from './center-component/EventAttendeeBadgeLayoutCenterComponentContainer';
import EventAttendeeBadgePosterUploadModalContainer from './modal/EventAttendeeBadgePosterUploadModalContainer';
import { WIDGET_TYPE, WidgetDataType } from './service/EventAttendeeBadgeDesignService';
import EventAttendeeBadgeRightSidebarContainer from './sidebar/right-sidebar/EventAttendeeBadgeRightSidebarContainer';
import EventAttendeeBadgeCreatorHeaderContainer from './header/EventAttendeeBadgeCreatorHeaderContainer';
import EventPosterListContainer from '../../../event-posters/EventPosterListContainer';

type Props = {
  projectId: string;
  id?: string;
  brandingFolderId: string;
  eventId: string;
  isLoading?: boolean;
  initData: (projectId: string, folderId: string, fetchFont: boolean) => void;
  isFontFetchExecuted?: boolean;
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
  loadFonts: (fonts: Array<string>) => void;
};

export default function EventAttendeeBadgeMainLayout(props: Props) {
  const {
    projectId,
    brandingFolderId,
    eventId,
    isLoading,
    initData,
    isFontFetchExecuted,
    posterWidgetDesign,
    loadFonts,
    id,
  } = props;

  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    initData(projectId, brandingFolderId, !isFontFetchExecuted);
  }, [projectId, brandingFolderId, initData, isFontFetchExecuted]);

  useEffect(() => {
    const fontsToLoad: Array<string> = [];
    if (posterWidgetDesign && !fontsLoaded) {
      Object.values(posterWidgetDesign).forEach((item) => {
        if (item.font) {
          fontsToLoad.push(item.font);
        }
      });
      loadFonts(fontsToLoad);
      setFontsLoaded(true);
    }
  }, [loadFonts, posterWidgetDesign, fontsLoaded]);

  return (
    <>
      <GeneralEditor>
        <EventAttendeeBadgeCreatorHeaderContainer eventId={eventId} folderId={brandingFolderId} projectId={projectId} />
        <GeneralEditorSidebar>
          <EventPosterListContainer
            eventId={eventId}
            projectId={projectId}
            focusId={id}
            type={EventPosterService.EVENT_POSTER_TYPE.BADGE}
          />
        </GeneralEditorSidebar>
        <GeneralEditorContent>
          {isLoading ? (
            <Row center>
              <Space>
                <LoadingDots />
              </Space>
            </Row>
          ) : (
            <EventAttendeeBadgeLayoutCenterComponentContainer projectId={projectId} folderId={brandingFolderId} />
          )}
        </GeneralEditorContent>
        <GeneralEditorSidebar isRight>
          <EventAttendeeBadgeRightSidebarContainer projectId={projectId} folderId={brandingFolderId} />
        </GeneralEditorSidebar>
      </GeneralEditor>
      <EventAttendeeBadgePosterUploadModalContainer projectId={projectId} folderId={brandingFolderId} />
    </>
  );
}
