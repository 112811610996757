import React, { useMemo } from 'react';

import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import { CryptoUtils } from '@premagic/utils';

import WatermarkFormContainer from './WatermarkFormContainer';

type Props = {
  projectId: string;
  addWatermark: (projectId: string, formResponse) => void;
  isLoading: boolean;
  showPanel: boolean;
  closePanel: () => void;
  watermarks?: ProjectMetaService.ProjectMetaWatermarkType;
};

export default function AddWatermarkPanel(props: Props) {
  const { projectId, addWatermark, isLoading, watermarks = {}, showPanel, closePanel } = props;
  const newId = useMemo(() => CryptoUtils.createUUID(), [showPanel]);

  function handleAdd(formResponse) {
    addWatermark(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.WATERMARK]: {
          ...watermarks,
          [newId]: {
            ...watermarks[newId],
            ...formResponse.data,
          },
        },
      },
    });
  }
  if (!showPanel) return null;

  return (
    <WindowPanel show={showPanel} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Add Watermark</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          formSpec={{
            opacity: {
              type: 'number',
            },
            size: {
              type: 'number',
            },
            remove_on_download: {
              type: 'boolean',
            },
          }}
          onSubmit={handleAdd}
        >
          <WatermarkFormContainer projectId={projectId} id={newId} />

          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
