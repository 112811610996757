import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { projectEventDataSelector, saveProjectMetaData } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import EditEventNotesPanel from './EditEventNotesPanel';

const PANEL_KEY = LOADINGS.EVENT.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId?: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    eventData: projectId ? projectEventDataSelector(projectId)(state) : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    saveProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEventNotesPanel);
