import React from 'react';
import { CalendarEvent, EventWrapper } from '@premagic/myne';

type EventsCalendarEventWrapperProps = {
  event: CalendarEvent;
  eventId: string;
  id: string;
  navigateToEventDetails: (eventId: string, id: string) => void;
  children: React.ReactNode;
};

export function OccasionsCalendarItemWrapper(props: EventsCalendarEventWrapperProps): JSX.Element {
  const { navigateToEventDetails, event, children, eventId, id } = props;

  return (
    <EventWrapper event={event} onClick={() => navigateToEventDetails(eventId, id)}>
      {children}
    </EventWrapper>
  );
}
