import { QRCodeBackgroundPosterService } from '@premagic/core';
import { ErrorBoundary } from '@premagic/myne';
import QRCodeBackgroundPosterSelectorContainer from './background-poster/QRCodeBackgroundPosterSelectorContainer';
import QRCodePosterColorSelectorContainer from './color/QRCodePosterColorSelectorContainer';
import QRCodePageSidebarAccordion from './common-ui/QRCodePageSidebarAccordion';
import QRCodePosterIllustrationSelectorContainer from './custom-illustration/QRCodePosterIllustrationSelectorContainer';
import QRCodePosterLEDScreenSettingsContainer from './led-poster-settings/QRCodePosterLEDScreenSettingsContainer';
import QRCodePosterLogoSelectorContainer from './logos/QRCodePosterLogoSelectorContainer';
import QRCodePosterPoweredByPremagicToggleContainer from './powered-by-premagic-toggle/QRCodePosterPoweredByPremagicToggleContainer';

type Props = {
  folderId: string;
  projectId: string;
  backgroundPoster: QRCodeBackgroundPosterService.QRCodePoster;
};

export default function QRCodePageRightSidebar(props: Props) {
  const { folderId, projectId, backgroundPoster } = props;

  return (
    <ErrorBoundary>
      <QRCodePageSidebarAccordion title="Preset">
        <QRCodeBackgroundPosterSelectorContainer folderId={folderId} projectId={projectId} />
      </QRCodePageSidebarAccordion>
      <QRCodePageSidebarAccordion title="Logo">
        <QRCodePosterLogoSelectorContainer folderId={folderId} projectId={projectId} />
      </QRCodePageSidebarAccordion>
      <QRCodePageSidebarAccordion
        title="Background Poster colors"
        info="Premagic has selected the most suitable colors from the logo for you."
        hide={backgroundPoster.designOptions.numberOfColors === 0}
      >
        <QRCodePosterColorSelectorContainer projectId={projectId} folderId={folderId} />
      </QRCodePageSidebarAccordion>

      <QRCodePageSidebarAccordion title="Custom Illustration" hide={!backgroundPoster?.customIllustration}>
        <QRCodePosterIllustrationSelectorContainer folderId={folderId} projectId={projectId} />
      </QRCodePageSidebarAccordion>

      <QRCodePageSidebarAccordion title="LED Screen settings">
        <QRCodePosterLEDScreenSettingsContainer folderId={folderId} projectId={projectId} />
      </QRCodePageSidebarAccordion>
      <QRCodePageSidebarAccordion title="Other settings">
        <QRCodePosterPoweredByPremagicToggleContainer folderId={folderId} projectId={projectId} />
      </QRCodePageSidebarAccordion>
    </ErrorBoundary>
  );
}
