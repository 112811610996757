import { BUTTON_SIZE, BUTTON_STYLES, Button, ICON_SIZES, Icon, Space, MenuItemIcon, MenuItem } from '@premagic/myne';
import React from 'react';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  downloadEventAttendeeData: (projectId: string, projectName: string) => void;
  projectName: string;
  projectId: string;
  disabled?: boolean;
  isLoading: boolean;
};

export default function EventAttendeeDownloadCSV(props: Props) {
  const { downloadEventAttendeeData, projectName, projectId, disabled, isLoading } = props;
  function handleDownload() {
    downloadEventAttendeeData(projectId, projectName);
  }
  return (
    <MenuItem
      onClick={handleDownload}
      disabled={disabled}
      disabledMessage={MESSAGES.EVENT_ATTENDEES.DOWNLOAD_PEOPLE_UPGRADE}
    >
      <MenuItemIcon name="download" />
      Download
    </MenuItem>
  );
}
