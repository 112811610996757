import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { setModalOptions } from '../../../../../../../../common/ModalDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../../common/WindowPanelDuck';
import { addSponsorCreativeWatermarkForSponsor } from '../../../event-sponsors/EventSponsorDataDuck';
import AddSponsorWatermarkPanel from './AddSponsorWatermarkPanel';

const PANEL_KEY = LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_SPONSOR_WATERMARK_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  return {
    projectId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.SPONSOR_CREATIVES.ADD_WATERMARK)(state),
    error: errorObjectForTypeSelector(LOADINGS.SPONSOR_CREATIVES.ADD_WATERMARK)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSponsorCreativeWatermark: (projectId: string, data) => {
      dispatch(addSponsorCreativeWatermarkForSponsor(dispatch, projectId, data));
    },

    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
    clearSponsorModalOptions: () =>
      dispatch(
        setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, {
          file: {
            file: '',
            fileId: '',
          },
        }),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSponsorWatermarkPanel);
