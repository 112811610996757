import { connect } from 'react-redux';
import EventAttendeeBadgeRightSidebar from './EventAttendeeBadgeRightSidebar';
import { selectedWidgetSelector } from '../../duck/EventAttendeeBadgeDesignDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  return { projectId, folderId, selectedWidgetForEdit: selectedWidgetSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeRightSidebar);
