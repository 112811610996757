import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isMobileUp } from '../../../../AppDuck';
import { eventProjectSelectorSelector } from '../../../../projects/AccountProjectsDataDuck';
import {
  isAdvocayPosterEnabledSelector,
  isFeatureAvatarStudioEnabledSelector,
  isFeatureMagazineEnabledSelector,
  isProjectEventExhibitorEnabledSelector,
} from '../../../../projects/project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../../../../settings/company/CompanyDataDuck';
import {
  isCompanyTypeNotPhotographerSelector,
  isFeatureEventsOccasionsEnabledSelector,
  isFeatureFinancialsEnabledSelector,
  isFeatureLeadEnabledSelector,
} from '../../../../settings/preferences/ClientSettingsDataDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { hasPermission } from '../../../users/UsersDataDuck';
import { clientItemForEventSelector, crmEventItemsSelector } from '../../EventsDataDuck';
import EventDetailsHeader from './EventDetailsHeader';

function mapStateToProps(state, ownProps: { eventId: string; currentUrlPathname: string }) {
  const { eventId, currentUrlPathname } = ownProps;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    eventId,
    currentUrlPathname,
    isLoading: isLoadingSelector(LOADINGS.EVENT.FETCH_SINGLE)(state),
    event: crmEventItemsSelector(state)[eventId],
    client: clientItemForEventSelector(eventId)(state),
    isMobileUp: isMobileUp(state),
    hasPermissions: {
      [PERMISSIONS.EVENT_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_MANAGEMENT)(state),
      [PERMISSIONS.EVENT_DELIVERABLE]: hasPermission(PERMISSIONS.EVENT_DELIVERABLE)(state),
      [PERMISSIONS.EVENT_OCCASION]: hasPermission(PERMISSIONS.EVENT_OCCASION)(state),
      [PERMISSIONS.EVENT_FINANCIAL]: hasPermission(PERMISSIONS.EVENT_FINANCIAL)(state),
      [PERMISSIONS.EVENT_SPONSOR]: hasPermission(PERMISSIONS.EVENT_SPONSOR)(state),
      [PERMISSIONS.EVENT_EXHIBITOR]: hasPermission(PERMISSIONS.EVENT_EXHIBITOR)(state),
      [PERMISSIONS.EVENT_ATTENDEES]: hasPermission(PERMISSIONS.EVENT_ATTENDEES)(state),
      [PERMISSIONS.EVENT_DETAILS]: hasPermission(PERMISSIONS.EVENT_DETAILS)(state),
      [PERMISSIONS.EVENT_MARKETING_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_MARKETING_MANAGEMENT)(state),
      [PERMISSIONS.EVENT_SETTINGS]: hasPermission(PERMISSIONS.EVENT_SETTINGS)(state),
    },
    showEventSponsors: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP)(state),
    showEventExhibitors:
      projectId &&
      isProjectEventExhibitorEnabledSelector(projectId)(state) &&
      hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_EXHIBITOR)(state),
    showEventAttendees: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST)(
      state,
    ),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
    isFeatureFinancialsEnabled: isFeatureFinancialsEnabledSelector(state),
    isFeatureEventsOccasionsEnabled: isFeatureEventsOccasionsEnabledSelector(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    isFeatureAddEventPosterEnabled: isAdvocayPosterEnabledSelector(projectId)(state),
    isFeatureAvatarStudioEnabled: isFeatureAvatarStudioEnabledSelector(projectId)(state),
    isFeatureMagazineEnabled: isFeatureMagazineEnabledSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT.SHOW_DELETE_DIALOG, true));
    },
    showEditNamePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT.SHOW_EDIT_NAME_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsHeader);
