import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { customerLeadsKeysForStatusSelector, fetchLeadsData } from './CustomerLeadsDataDuck';
import CustomerLeadsPage from './CustomerLeadsPage';

function mapStateToProps(state, ownProps) {
  const { status = 'NEW' } = ownProps.match.params;

  return {
    isLoading: isLoadingSelector(LOADINGS.LEADS.FETCH_ALL)(state),
    leads: customerLeadsKeysForStatusSelector(status)(state),
    activeStatus: status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchLeadsData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLeadsPage);
