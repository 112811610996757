import React from 'react';

import {
  Text,
  Space,
  BUTTON_STYLES,
  Button,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  DIALOG_PLACEMENT,
  ICON_SIZES,
  TEXT_BOLDNESS,
  Icon,
} from '@premagic/myne';
import { HelpArticlesService } from '@premagic/core';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import { HelpLink } from '../../../settings/HelpLInk';
import MESSAGES from '../../../../../../common/Messages';

type Props = {
  projectId: string;
  togglePopover: (show: boolean) => void;
  isPopoverShown: boolean;
  archive: (projectId: string) => void;
  isLoading: boolean;
};

export default function ProjectArchiveDialog(props: Props): JSX.Element | null {
  const { isLoading, projectId, togglePopover, isPopoverShown, archive } = props;

  if (!isPopoverShown) return null;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.PROJECT_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => togglePopover(false)}
    >
      <DialogTitle>Archive/Close project?</DialogTitle>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARKER}>
        Once you archive this project,
      </Text>
      <ul>
        <li>
          <Text>All the photos on the project will be erased</Text>
        </li>
        <li>
          <Text>You will not be able to view or save photos</Text>
        </li>
      </ul>
      <Space vertical />
      <Text>
        Useful after completing all the work on this project{' '}
        <HelpLink articleId={HelpArticlesService.ARTICLES.ARCHIVE_PROJECT}>learn more</HelpLink>
      </Text>
      <Space vertical size={1} />
      <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.LINK} onClick={() => togglePopover(false)} type="button">
          Cancel
        </Button>
        <Button isLoading={isLoading} style={BUTTON_STYLES.DANGER} onClick={() => archive(projectId)}>
          <Icon name="archive" size={ICON_SIZES.SM} /> <Space size={2} /> Archive
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
