import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import { WindowPanelDuck } from '@premagic/common-ducks';
import FolderShareButton from './FolderShareButton';
import { LOADINGS } from '../../../../../../common/Constants';
import { foldersEntitiesSelector } from '../AccountFoldersDataDuck';
import {
  clientForProjectFolderSelector,
  folderIdsWithFolderTypeForProjectSelector,
} from '../../AccountProjectsDataDuck';
import { setFolderIdsToShare } from '../../../folder-share/FolderShareDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderType: FolderService.FOLDER_TYPES }) {
  const { projectId, folderType } = ownProps;
  const clients = clientForProjectFolderSelector(projectId)(state);

  const folderIdsPendingToShare = folderIdsWithFolderTypeForProjectSelector(projectId, folderType, {
    is_shared: false,
  })(state);

  return {
    projectId,
    folderIdsPendingToShare,
    allFolders: foldersEntitiesSelector(state),
    folderIds: folderIdsWithFolderTypeForProjectSelector(projectId, folderType)(state),
    clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSharePanel: (folderIds: Array<string>) => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.FOLDER_SHARE.SHARE, true));
      dispatch(setFolderIdsToShare(dispatch, folderIds));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderShareButton);
