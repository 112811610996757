import { connect } from 'react-redux';
import AttendeeEventPoster from './AttendeeEventPoster';
import { filesSelector } from '../../images/FilesDataDuck';
import { eventAttendeePosterDataSelector } from '../../crm/events/event-attendees/EventAttendeesDataDuck';

function mapStateToProps(state, ownProps: { posterId: string; attendeeId: string }) {
  const { posterId, attendeeId } = ownProps;

  return {
    poster: eventAttendeePosterDataSelector(posterId, attendeeId)(state),
    files: filesSelector(state),
  };
}

export default connect(mapStateToProps)(AttendeeEventPoster);
