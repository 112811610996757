import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import CompanyStatsInfo from './CompanyStatsInfo';
import { companyStatsSelector, getCompanyStatsData } from './CompanyStatsDataDuck';
import { currentPricingPlanSelector, remainingAiDeliveryPhotosSelector } from '../../settings/company/CompanyDataDuck';

function mapStateToProps(state) {
  return {
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.COMPANY.STATS_FETCH)(state),
    stats: companyStatsSelector(state),
    company: currentPricingPlanSelector(state),
    remainingAiDeliveryPhotos: remainingAiDeliveryPhotosSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => dispatch(getCompanyStatsData(dispatch)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStatsInfo);
