import { FileService } from '@premagic/core';
import { GalleryImage } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  file: FileService.FolderFileType;
  fileId: string;
  navigateTo: (url: string) => void;
  filters?: string;
  folderId?: string;
};

export default function DAMFilesThumbnails(props: Props) {
  const { file, fileId, navigateTo, filters, folderId } = props;

  function handleClick() {
    if (!fileId || !filters) return;
    const url = BrowserUrlUtils.getRouteUrlFor(APP_URLS.LIBRARY.FILE_DETAILS, {
      filters,
      fileId,
      folderId,
    });

    navigateTo(url);
  }

  return (
    <GalleryImage
      key={file.dynamic_image_url}
      onClick={() => handleClick()}
      alt={file.image_name}
      src={file.dynamic_image_url}
    />
  );
}
