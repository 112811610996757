import { connect } from 'react-redux';
import { saveClientSettingsData, storePricingDataSelector } from '../preferences/ClientSettingsDataDuck';
import StorePricingDefaultSettings from './StorePricingDefaultSettings';

function mapStateToProps(state, ownProps) {
  return { storePricingData: storePricingDataSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePricingDefaultSettings);
