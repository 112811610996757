import React from 'react';
import {
  BUTTON_ICONS_SIZES,
  Col,
  Color,
  COLOR_SHADES,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  LIST_ITEM_ACTION_STYLES,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  LoadingDots,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  Tooltip,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { ProjectService } from '@premagic/core';
import { Draggable } from 'react-beautiful-dnd';

import { ProposalDeckService, ProposalSlideService } from '@premagic/proposals';
import ProposalSlidePreviewContainer from './ProposalSlidePreviewContainer';
import APP_URLS from '../../../../services/AppRouteURLService';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  id: number;
  nextSlideId: number;
  deleteSlide: (options: {
    projectId: string;
    folderId: string;
    deckId: string;
    id: number;
    navigateToNextSlideId?: number;
  }) => void;
  isUpdating: boolean;
  position: number;
  activeSlideId: number;
  addSlide: (
    params: { projectId: string; folderId: string; deckId: string },
    data: Partial<ProposalSlideService.ProposalSlideType>,
  ) => void;
  slide: ProposalSlideService.ProposalSlideType;
  deck?: ProposalDeckService.ProposalDeckType;
};

export default function ProposalSlideListItem(props: Props): JSX.Element {
  const {
    isUpdating,
    deleteSlide,
    id,
    projectId,
    folderId,
    deckId,
    position,
    activeSlideId,
    nextSlideId,
    deck,
    addSlide,
    slide,
  } = props;

  const isDeckPublished = deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;

  const slideUrl =
    projectId === ProjectService.SystemProject.id
      ? BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROPOSALS_TEMPLATES.DETAILS, {
          folderId,
          deckId,
          slideId: id,
        })
      : BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROPOSALS.DETAILS, {
          projectId,
          folderId,
          deckId,
          slideId: id,
        });
  const isActive = activeSlideId === id;

  return (
    <Draggable key={id} draggableId={String(id)} index={position - 1}>
      {(draggableProvided, draggableSnapshot) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
        >
          <ListItem
            active={isActive}
            key={id}
            to={slideUrl}
            style={LIST_ITEM_STYLES.NAV}
            className={JS_SELECTORS.PROPOSAL_SLIDE_ITEM(id)}
          >
            <ListItemAction style={LIST_ITEM_ACTION_STYLES.DEFAULT}>
              <Tooltip message="Drag and move to reorder" placement="left">
                <Row>
                  <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                    <Icon name="drag" size={ICON_SIZES.SM} />
                  </Color>
                </Row>
              </Tooltip>
            </ListItemAction>
            <Col size={12}>
              <Row>
                <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
                  {position}
                </Text>
                <Col size={2} rightAlighed>
                  <ListItemAction>
                    {isUpdating ? (
                      <LoadingDots size="sm" />
                    ) : (
                      <Dropdown>
                        <DropdownIcon title="Variable options" tooltipPlacement="left" size={BUTTON_ICONS_SIZES.SM}>
                          <Icon name="more_horizontal" size={ICON_SIZES.SM} align="bottom" alignSize={2} />
                        </DropdownIcon>
                        <Menu>
                          <MenuItem
                            disabled={isDeckPublished}
                            disabledMessage="Edit the Proposal if you want to delete this slide"
                            onClick={() =>
                              // navigateToNextSlideId is undefined the user click on delete on non-active/unfocused slide | so the active slide does gets changes
                              addSlide(
                                {
                                  projectId,
                                  folderId,
                                  deckId,
                                },
                                slide,
                              )
                            }
                          >
                            <MenuItemIcon name="copy" />
                            Duplicate
                          </MenuItem>
                          <MenuItem
                            disabled={isDeckPublished}
                            disabledMessage="Edit the Proposal if you want to delete this slide"
                            onClick={() =>
                              // navigateToNextSlideId is undefined the user click on delete on non-active/unfocused slide | so the active slide does gets changes
                              deleteSlide({
                                projectId,
                                folderId,
                                deckId,
                                id,
                                navigateToNextSlideId: isActive ? nextSlideId : undefined,
                              })
                            }
                          >
                            <MenuItemIcon name="trash" />
                            Delete
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                    )}
                  </ListItemAction>
                </Col>
              </Row>
              <Row>
                <Space size={6} />
                <ProposalSlidePreviewContainer id={Number(id)} projectId={projectId} deckId={deckId} />
              </Row>
            </Col>
          </ListItem>
        </div>
      )}
    </Draggable>
  );
}
