import { EventPosterService } from '@premagic/core';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  List,
  ListItem,
  LoadingDots,
  Space,
  Text,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import AddEventPosterPanelContainer from './create/AddEventPosterPanelContainer';
import EventPosterListItemContainer from './EventPosterListItemContainer';
import EmbedPosterModalContainer from '../../event-poster-creator/embed-poster/EmbedPosterModalContainer';

function Header() {
  return (
    <ListItem>
      <Col size={3}>
        <Text color={COLOR_SHADES.LIGHT}>Name</Text>
      </Col>
      <Col size={2}>
        <Text color={COLOR_SHADES.LIGHT}>For</Text>
      </Col>
      <Col size={1}>
        <Text color={COLOR_SHADES.LIGHT}>Size</Text>
      </Col>
    </ListItem>
  );
}
type Props = {
  projectId: string;
  eventId: string;
  focusId?: string;
  initData: (projectId: string) => void;
  posterIds: Array<string>;
  isLoading: boolean;
  showAddNewEventPoster: () => void;
  type: EventPosterService.EVENT_POSTER_TYPE;
  listStyle?: 'list' | 'mini-item';
};

export default function EventPosterList(props: Props): React.ReactElement {
  const {
    projectId,
    initData,
    posterIds,
    eventId,
    focusId,
    isLoading,
    showAddNewEventPoster,
    type,
    listStyle = 'list',
  } = props;
  const typeDetails = EventPosterService.EVENT_POSTER_TYPE_DETAILS[type];
  const isListView = listStyle === 'list';

  useEffect(() => {
    if (projectId) initData(projectId);
  }, [initData, projectId]);

  if (isLoading && isListView) {
    return (
      <List>
        <Header />
        <ListItem>
          <LoadingDots size="sm" />
        </ListItem>
      </List>
    );
  }

  if (isLoading) {
    return <LoadingDots size="sm" />;
  }
  if (posterIds.length === 0) {
    return (
      <div>
        <List>
          {isListView && <Header />}
          <ListItem>
            <Text muted>You haven&apos;t added any {typeDetails.title} </Text>
            <Space />
            <Button style={BUTTON_STYLES.LINK} onClick={showAddNewEventPoster} size={BUTTON_SIZE.SM}>
              <Icon name="plus" size={ICON_SIZES.SM} />
              <Space size={1} />
              {typeDetails.title}
            </Button>
          </ListItem>
        </List>
        <AddEventPosterPanelContainer projectId={projectId} eventId={eventId} type={type} />
      </div>
    );
  }
  return (
    <div>
      <List>
        {isListView && <Header />}
        {posterIds.map((id) => (
          <EventPosterListItemContainer
            key={id}
            id={id}
            eventId={eventId}
            projectId={projectId}
            focusId={focusId}
            listStyle={listStyle}
          />
        ))}
      </List>
      {isListView && <AddEventPosterPanelContainer projectId={projectId} eventId={eventId} type={type} />}
      <EmbedPosterModalContainer projectId={projectId} />
    </div>
  );
}
