import { connect } from 'react-redux';
import SponsorCreativeDeleteDialog from './SponsorCreativeDeleteDialog';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { removeSponsorCreativeFromSponsor } from '../../event-sponsors/EventSponsorDataDuck';

function mapStateToProps(state, ownProps: { sponsorId: string; projectId: string; creativeId: string }) {
  const { sponsorId, projectId, creativeId } = ownProps;

  return {
    sponsorId,
    projectId,
    creativeId,
    isLoading: isLoadingSelector(LOADINGS.SPONSOR_CREATIVES.DELETE)(state),
    isDialogShown: isModalOpenSelector(LOADINGS.SPONSOR_CREATIVES.SHOW_DELETE_DIALOG(sponsorId, creativeId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (sponsorId: string, id: string, show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_DELETE_DIALOG(sponsorId, id), show));
    },
    deleteCreative: (options: { sponsorId: string; creativeId: string; projectId: string }) => {
      dispatch(removeSponsorCreativeFromSponsor(dispatch, options));
      dispatch(
        toggleModalVisibility(
          dispatch,
          LOADINGS.SPONSOR_CREATIVES.SHOW_DELETE_DIALOG(options.sponsorId, options.creativeId),
          false,
        ),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCreativeDeleteDialog);
