import { ClientSettingsService, FeatureToggleService, ProjectMetaService, StorePricingService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  TEXT_BUTTON_STYLES,
  BUTTON_SIZE,
  TextButton,
  Form,
  FormGroup,
  Text,
  TEXT_BOLDNESS,
  INPUT_SIZES,
  FormFooter,
  Dialog,
  Select,
  Col,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Tooltip,
} from '@premagic/myne';
import { StorePriceUtils } from '@premagic/utils';
import React, { useMemo, useRef, useState } from 'react';
import AccountCurrencyContainer from '../../../acccount/AccountCurrencyContainer';

type PhotoDownloadPriceSummaryProps = {
  price?: number;
  margin?: number;
  currencyCode?: string;
};

function PhotoDownloadPriceSummary(props: PhotoDownloadPriceSummaryProps) {
  const { price = 0, margin = 0, currencyCode } = props;
  return (
    <Card size={CARD_SIZES.DEFAULT} style={CARD_STYLES.SECONDARY}>
      <Space size={3}>
        <Row vcenter spaceBetween>
          <>
            <Col size={2} screenSMSize={6}>
              <Tooltip message="Single photo download price" placement="bottom">
                <Text boldness={TEXT_BOLDNESS.BOLD}>Price</Text>
              </Tooltip>
            </Col>
            <Text color={COLOR_SHADES.DARKER}>
              <AccountCurrencyContainer amount={price} currencyCode={currencyCode} />
            </Text>
          </>
          <>
            <Col size={2} screenSMSize={6}>
              <Tooltip message="profit = price - cost - Premagic commission" placement="bottom">
                <Text boldness={TEXT_BOLDNESS.BOLD}>Profit</Text>
              </Tooltip>
            </Col>
            <Text color={COLOR_SHADES.DARKER}>
              <AccountCurrencyContainer amount={margin} currencyCode={currencyCode} />
            </Text>
          </>
        </Row>
      </Space>
    </Card>
  );
}

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateProjectMeta: (projectId: string, data) => void;
  hasSinglePhotoDownloadQualityPermissions: boolean;
  closeModal: () => void;
  showModal: () => void;
  clientSettings?: ClientSettingsService.ClientSettingsType;
};

export default function PhotoDownloadPaymentSettings(props: Props) {
  const {
    showModal,
    projectMeta = {},
    updateProjectMeta,
    projectId,
    hasSinglePhotoDownloadQualityPermissions,
    closeModal,
    clientSettings,
  } = props;

  const isStoreFeatureEnabled = FeatureToggleService.isToggleableFeatureEnabledForClient(
    FeatureToggleService.FEATURES_TOGGLES.STORE_PRICING,
    true,
  );

  const photoDownloadPrice =
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE] ||
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.STORE_PRICING]?.[
      StorePricingService.SUBPRODUCTS_IDS.HIGH_RES_PHOTO
    ] ||
    0;

  const currency =
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.STORE_PRICE_CURRENCY_CODE] ||
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.STORE_PRICING]?.[
      StorePricingService.STORE_CURRENCY
    ] ||
    'INR';

  const defaultPaymentType =
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE] &&
    projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE] > 0
      ? ProjectMetaService.PHOTO_DOWNLOAD_PAYMENT_TYPE_ID.PAID
      : ProjectMetaService.PHOTO_DOWNLOAD_PAYMENT_TYPE_ID.FREE;

  const [paymentType, setPaymentType] = useState(defaultPaymentType);
  const $button = useRef(null);
  const [isDialogShown, toggleDialog] = useState(false);
  const downloadPaymentOption = useMemo(
    () =>
      Object.entries(ProjectMetaService.PHOTO_DOWNLOAD_PAYMENT_DETAILS).map(([key, value]) => ({
        label: value.title,
        value: key,
      })),
    [],
  );

  const selectedOption =
    downloadPaymentOption.find((option) => option.value === paymentType) || downloadPaymentOption[0];

  function onSubmit(formData) {
    const price = paymentType === ProjectMetaService.PHOTO_DOWNLOAD_PAYMENT_TYPE_ID.PAID ? photoDownloadPrice : 0;
    updateProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE]: price,
        [ProjectMetaService.PROJECT_META_TYPES.STORE_PRICE_CURRENCY_CODE]: currency,
      },
    });
    toggleDialog(false);
  }

  function handlePriceEdit() {
    showModal();
    toggleDialog(false);
  }

  if (!isStoreFeatureEnabled) return null;
  if (!hasSinglePhotoDownloadQualityPermissions) return null;
  return (
    <div>
      <TextButton
        ref={$button}
        style={TEXT_BUTTON_STYLES.SECONDARY}
        onClick={() => toggleDialog(!isDialogShown)}
        size={BUTTON_SIZE.MD}
      >
        {selectedOption.label}
      </TextButton>
      <Dialog target={$button.current} show={isDialogShown} onClose={() => toggleDialog(false)}>
        <Form onSubmit={(formData) => onSubmit(formData)}>
          <FormGroup>
            <Row vcenter>
              <Color shade={COLOR_SHADES.DARKER} inline>
                <Icon name="download" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} />
              <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                Photo download payment settings
              </Text>
            </Row>
            <Space size={2} vertical />
            <Row vcenter spaceBetween>
              <Select
                size={INPUT_SIZES.SM}
                isMulti={false}
                name="payment_type"
                value={selectedOption}
                options={downloadPaymentOption}
                onChange={(val) => setPaymentType(val?.value as ProjectMetaService.PHOTO_DOWNLOAD_PAYMENT_TYPE_ID)}
              />
              <Space size={3} />
            </Row>
            <Space size={2} vertical />
            {paymentType === ProjectMetaService.PHOTO_DOWNLOAD_PAYMENT_TYPE_ID.PAID && (
              <>
                <PhotoDownloadPriceSummary
                  price={photoDownloadPrice}
                  margin={StorePriceUtils.getTotalMarginForProduct(photoDownloadPrice, 0, currency)}
                  currencyCode={currency}
                />
                <Space size={1} vertical />
                <Row>
                  <Col size={null} rightAlighed>
                    <Button onClick={handlePriceEdit} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
                      Edit Price
                    </Button>
                  </Col>
                </Row>
                <Space size={1} vertical />
              </>
            )}
          </FormGroup>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY}>Save</Button>
          </FormFooter>
        </Form>
      </Dialog>
    </div>
  );
}
