import React, { useEffect } from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  FormFooter,
  BUTTON_SIZE,
  Button,
  LoadingDots,
} from '@premagic/myne';
import { ProposalService } from '@premagic/proposals';
import FolderCoverPhotoContainer from '../../../projects/folders/sidebar/FolderCoverPhotoContainer';

type Props = {
  projectId: string;
  folderId: string;
  close: () => void;
  show: boolean;
  isLoading: boolean;
  proposal?: ProposalService.ProposalType;
};

export default function ProposalThumbnailPanel(props: Props) {
  const { show, close, isLoading, proposal, folderId, projectId } = props;
  if (!proposal) return null;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Proposal Thumbnail</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        {isLoading && (
          <div>
            <LoadingDots size="sm" />
          </div>
        )}
        <FolderCoverPhotoContainer folderId={folderId} projectId={projectId} type="thumbnail" />
        <FormFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
            Close
          </Button>
        </FormFooter>
      </WindowPanelContent>
    </WindowPanel>
  );
}
