import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../company/CompanyDataDuck';
import {
  isFeatureFinancialsEnabledSelector,
  isWatermarkOnClientInvoiceShownSelector,
  saveClientSettingsData,
} from '../preferences/ClientSettingsDataDuck';
import EventFinancialSettingsPage from './EventFinancialSettingsPage';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return {
    currentUrlPathname: pathname,
    hasAccountFinancials: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ACCOUNT_FINANCIALS)(
      state,
    ),
    isFeatureFinancialsEnabled: isFeatureFinancialsEnabledSelector(state),
    isWatermarkOnClientInvoiceShown: isWatermarkOnClientInvoiceShownSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFinancialSettingsPage);
