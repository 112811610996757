import { connect } from 'react-redux';
import DAMLibraryPeopleFilterSelectorWrapper from './DAMLibraryPeopleFilterSelectorWrapper';
import { DAM_LIBRARY_FILTER_KEYS, DAMLibraryFiltersType } from '../DAMLibraryFilterTypes';

function mapStateToProps(
  state,
  ownProps: { value: Array<string>; filters: DAMLibraryFiltersType; onChange: (newFilterValue: Array<string>) => void },
) {
  const { value, filters, onChange } = ownProps;

  const projectIds = filters?.[DAM_LIBRARY_FILTER_KEYS.PROJECT];
  const projectId = Array.isArray(projectIds) ? projectIds.join(',') : projectIds;

  return { value, filters, onChange, projectId };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryPeopleFilterSelectorWrapper);
