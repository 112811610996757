import { connect } from 'react-redux';
import { itineraryForSpeakerSelector } from '../../../client-website/itinerary/ClientWebsiteItineraryDataDuck';
import { clientWebsiteForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import { eventPostersForPreEventArraySelector } from '../../event-posters/EventPostersDataDuck';

import EventAttendeeLinkToPosterButton from './EventAttendeeLinkToPosterButton';
import { eventAttendeesSelector } from './EventAttendeesDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;
  return {
    posters: eventPostersForPreEventArraySelector(state),
    attendee: eventAttendeesSelector(state)[id],
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
    sessions: itineraryForSpeakerSelector(id)(state),
  };
}

export default connect(mapStateToProps)(EventAttendeeLinkToPosterButton);
