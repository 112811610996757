import { connect } from 'react-redux';
import AlbumCreatorImagesTray from './AlbumCreatorImagesTray';
import { albumCreatorImagesToSelectImagesSelector } from './page/AlbumPageDataDuck';

function mapStateToProps(state) {
  return {
    images: albumCreatorImagesToSelectImagesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumCreatorImagesTray);
