import { connect } from 'react-redux';
import { ProposalDeckDataDuck, ProposalService } from '@premagic/proposals';

import EventProposalDeckStatus from './EventProposalDeckStatus';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    deckId: string;
    folderId: string;
  },
) {
  const { folderId, deckId } = ownProps;
  const proposal = foldersEntitiesSelector(state)[folderId] as ProposalService.ProposalType;

  return {
    deckId,
    proposal,
    deck: deckId ? ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId] : undefined,
  };
}

export default connect(mapStateToProps)(EventProposalDeckStatus);
