import { ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FileService, ProjectMetaService, SponsorCreativesService } from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { ActionTypeUtils, ArrayUtils, ErrorTracker, PhotoWatermarkUtils } from '@premagic/utils';
import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { LOADINGS, MODALS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { toastMessage } from '../../../reducers/ToastStore';
import { EntitiesStateType, entitiesDataSelector } from '../../../reducers/selectors';
import { filesEntitiesDataSelector } from '../../images/FilesDataDuck';
import { brandingFolderFileIdsSelector } from '../folders/AccountFoldersDataDuck';
import { accountDefaultLanguageSelector } from '../../settings/preferences/ClientSettingsDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_META');

const setProjectMetaData = createAction(getActionType('DATA', 'SET'), (dispatch, projectId, data) => ({
  [projectId]: data,
}));

export const updateProjectMetaData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, projectId, data) => ({
  projectId,
  data,
}));

export const getProjectMetaData = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId: string) => {
  const loadingKey = LOADINGS.PROJECT_META.FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  ProjectMetaService.fetchProjectMeta(projectId)
    .then((response) => {
      dispatch(setProjectMetaData(dispatch, projectId, response));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_UPDATE_PANEL, false));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
    });
});

export const saveProjectMetaData = createAction(
  getActionType('DATA', 'SAVE'),
  (
    dispatch,
    projectId: string,
    formResponse: FormResponseType & {
      data: ProjectMetaService.ProjectMetaType;
    },
    options: {
      itemId?: ProjectMetaService.PROJECT_META_TYPES;
      closeModal?: boolean;
      type?: 'watermark';
    },
  ) => {
    const loadingKey = LOADINGS.PROJECT_META.UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    const { data } = formResponse;
    ProjectMetaService.updateProjectMeta(projectId, data)
      .then((response) => {
        dispatch(updateProjectMetaData(dispatch, projectId, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_UPDATE_PANEL, false));
        if (options.closeModal && options.itemId) {
          dispatch(
            toggleModalVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_UPDATE_PANEL_ITEM(options.itemId), false),
          );
        }
        if (options.type === 'watermark') {
          dispatch(
            WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_EDIT_WATERMARK, false),
          );
          dispatch(
            WindowPanelDuck.toggleWindowPanelVisibility(dispatch, LOADINGS.PROJECT_META.SHOW_ADD_WATERMARK, false),
          );
          dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.BRANDING_FOLDER.SELECT_FILE_MODAL, null));
          dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.PROJECT_META.SHOW_EDIT_WATERMARK, null));
        }
        return response;
      })
      .catch((response) => {
        dispatch(setErrorState(dispatch, loadingKey, response.data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        throw response;
      });
  },
);

export const saveHighlightFolderPosition = createAction(
  getActionType('FOLDER_POSITION', 'UPDATE'),
  (dispatch, projectId: string, folderIds: Array<string>, data: { from: number; to: number }) => {
    const newFolderPosition = ArrayUtils.arrayMove(folderIds, data.from, data.to);
    const dataToSave = {
      highlight_folders_position_order: newFolderPosition,
    };
    dispatch(updateProjectMetaData(dispatch, projectId, dataToSave));

    ProjectMetaService.updateProjectMeta(projectId, dataToSave).catch((response) => {
      toastMessage('error', 'Something went wrong');
      ErrorTracker.logError('FOLDER_POSITION_UPDATE', response);
    });
  },
);

export const uploadProjectCoverPhoto = createAction(
  getActionType('PROJECT_COVER_PHOTO', 'UPDATE'),
  (dispatch, projectId: string, coverPhoto: File, hideFocalPointModal: boolean) => {
    const formData = new FormData();
    formData.append('cover_image', coverPhoto);

    const loadingKey = LOADINGS.PROJECT_META.COVER_UPDATE;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProjectMetaService.uploadProjectCoverPhoto(projectId, formData)
      .then((response) => {
        dispatch(
          updateProjectMetaData(dispatch, projectId, {
            cover_image: response?.cover_image,
          }),
        );
        if (!hideFocalPointModal) {
          dispatch(toggleModalVisibility(dispatch, MODALS.PROJECT_COVER_FOCAL_POINT_MODAL, true));
        }
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

type ProjectMetaItemType = IMap<string, ProjectMetaService.ProjectMetaType>;
type StateType = {
  items: ProjectMetaItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setProjectMetaData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [updateProjectMetaData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.projectId, (data) => ({
        // @ts-ignore
        ...data,
        ...action.payload.data,
      })),
    }),
  },
  initialState,
);

export const projectsMetaDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => (entities.projectsMetaData as StateType).items,
);

export const projectMetaDataForProjectSelector = (projectId) =>
  createSelector(projectsMetaDataSelector, (items) => items.get(projectId));

export const projectWatermarkForProjectSelector = (projectId) =>
  createSelector(
    projectMetaDataForProjectSelector(projectId),
    (item) => item?.[ProjectMetaService.PROJECT_META_TYPES.WATERMARK],
  );

export const isAiSignatureAlbumEnabledSelector = (projectId) =>
  createSelector(projectsMetaDataSelector, (items) => {
    const meta = items.get(projectId);
    if (!meta) return false;
    return !!meta[ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM] || false;
  });

export const hasAiSignatureAlbumSelector = (projectId) =>
  createSelector(projectsMetaDataSelector, isAiSignatureAlbumEnabledSelector, (items, isAISignatureAlbumEnabled) => {
    const meta = items.get(projectId);
    if (!meta) return false;
    const showTab = meta[ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_SHOW_PEOPLE_TAB] || false;
    return isAISignatureAlbumEnabled || showTab;
  });

export const isEmailHiddenOnRegistrationFormOnProject = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD] || false,
  );
export const isEventRegistrationEmailRequiredInProject = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED] ||
      false,
  );

export const photoWatermarkSelectorForPreview = (projectId) =>
  createSelector(
    projectWatermarkForProjectSelector(projectId),
    brandingFolderFileIdsSelector,
    filesEntitiesDataSelector,
    (state, brandingFiles, files) => {
      const watermarks = Object.values(state || {});
      if (watermarks.length > 0) {
        return watermarks
          .filter((watermark) => {
            const watermarkFile = watermark?.file;
            const file = watermarkFile && brandingFiles?.includes(watermarkFile) ? files.get(watermarkFile) : undefined;
            if (!file) return false;
            return file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.DONE;
          })
          .map((watermark) => {
            const file = watermark.file ? files.get(watermark.file)?.dynamic_image_url : undefined;
            return {
              opacity: watermark.opacity,
              size: watermark.size,
              position: watermark.position as PhotoWatermarkUtils.WATERMARK_POSITIONS,
              x: watermark.x,
              y: watermark.y,
              objKey: file ? file.replace('https://asts-company.premagic.com/', '') : '',
            };
          });
      }
      return undefined;
    },
  );

export const isWatermarkProcessingSelector = (projectId) =>
  createSelector(
    projectWatermarkForProjectSelector(projectId),
    brandingFolderFileIdsSelector,
    filesEntitiesDataSelector,
    (state, brandingFiles, files): boolean => {
      const watermarks = Object.values(state || {});
      if (watermarks.length > 0) {
        return watermarks.some((watermark) => {
          const watermarkFile = watermark?.file;
          const file = watermarkFile && brandingFiles?.includes(watermarkFile) ? files.get(watermarkFile) : undefined;
          if (!file) return false;
          return (
            file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.PROCESSING ||
            file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.QUEUED ||
            file.asset_upload_status === FileService.FILE_UPLOAD_STAGES.UPLOADING
          );
        });
      }
      return false;
    },
  );

export const sponsorFooterGroupByTypeSelector = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.SPONSOR_FOOTER_GROUP_BY] ||
      SponsorCreativesService.SPONSOR_FOOTER_GROUP_BY_TYPE.TIER,
  );

export const projectDefaultLanguageSelector = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    accountDefaultLanguageSelector,
    (items, accountLangCOde) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.DEFAULT_LANG] || accountLangCOde,
  );

export const isAdvocayPosterEnabledSelector = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED] || false,
  );

export const isFeatureAvatarStudioEnabledSelector = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) => items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.AVATAR_STUDIO_ENABLE] || false,
  );

export const isFeatureMagazineEnabledSelector = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) => items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.MAGAZINE_CREATOR_ENABLE] || false,
  );

export const isFeatureBadgesEnabledSelector = (projectId) =>
  createSelector(
    projectsMetaDataSelector,
    (items) => items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_BADGES_ENABLED] || false,
  );

export const projectEventDataSelector = (projectId: string) =>
  createSelector(
    projectsMetaDataSelector,
    (items) => items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_DATA],
  );

export const isProjectConnectedToIntegrationPlatformSelector = (projectId: string) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_PLATFORM_INTEGRATION_ENABLED] || false,
  );

export const integrationEventConfigForProjectSelector = (projectId: string) =>
  createSelector(
    projectsMetaDataSelector,
    (items) => items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_PLATFORM_INTEGRATION_CONFIG],
  );

export const isProjectEventExhibitorEnabledSelector = (projectId: string) =>
  createSelector(
    projectsMetaDataSelector,
    (items) => items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_EXHIBITOR] || false,
  );

export const attendeeRegistrationSendNotificationRequiredSelector = (projectId: string) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED],
  );

export const isEventRegistrationEnablePhotoUploadEnabledSelector = (projectId: string) =>
  createSelector(
    projectsMetaDataSelector,
    (items) =>
      items.get(projectId)?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD] ?? true,
  );
