import {
  COLOR_SHADES,
  getComponentForTextGroupType,
  LoadingDots,
  MiniCardCount,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
} from '@premagic/myne';
import React from 'react';

type Props = {
  title: string;
  count: number;
  type: TEXT_GROUP_TYPES;
  isLoading?: boolean;
};

export default function ReportStatsItem(props: Props) {
  const { count, title, type, isLoading } = props;
  const Element = getComponentForTextGroupType(type);
  return (
    <MiniCardCount>
      <Text>{title}</Text>
      <Space vertical />
      <Text
        size={TEXT_SIZE.SIZE_2}
        boldness={TEXT_BOLDNESS.BOLD}
        color={type === TEXT_GROUP_TYPES.CURRENCY ? COLOR_SHADES.GREEN_DARKER : COLOR_SHADES.DARKER}
      >
        {isLoading ? <LoadingDots size="sm" /> : <Element value={count} secondaryValue="USD" />}
      </Text>
    </MiniCardCount>
  );
}
