import { connect } from 'react-redux';
import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { LOADINGS } from '../../../../../../common/Constants';
import ProjectMetaToggle from './ProjectMetaToggle';
import { projectMetaDataForProjectSelector, saveProjectMetaData } from '../../project-meta/ProjectsMetaDataDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { isStaffUserSelector } from '../../../crm/users/UsersDataDuck';
import { projectsSelector } from '../../AccountProjectsDataDuck';
import {
  clientSettingsDataSelector,
  isWatermarkBetaEnabledSelector,
} from '../../../settings/preferences/ClientSettingsDataDuck';

const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    itemKey: ProjectMetaService.PROJECT_META_TOGGLE_ITEMS;
    onChange?: (value: boolean) => void;
  },
) {
  const { projectId, itemKey, onChange } = ownProps;

  return {
    projectId,
    project: projectsSelector(state)[projectId] || {},
    itemKey,
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
    isStaffUser: isStaffUserSelector(state),
    clientSettings: clientSettingsDataSelector(state),
    onChange,
    hasFeatures: {
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.GALLERY_PASSWORD_PROTECTION]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.GALLERY_PASSWORD_PROTECTION,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FACES]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FACES,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.PROJECT_LEVEL_DOWNLOAD]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.PROJECT_LEVEL_DOWNLOAD,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BLOCK_GALLERY]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BLOCK_GALLERY,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK]:
        isWatermarkBetaEnabledSelector(state) ||
        hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK)(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_PEOPLE_NETWORKING]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_PEOPLE_NETWORKING,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_FACE_CHECKIN]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_FACE_CHECKIN,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_MARKETING]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_MARKETING,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AVATAR_STUDIO]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AVATAR_STUDIO,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.MAGAZINE_CREATOR]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.MAGAZINE_CREATOR,
      )(state),
      [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_EXHIBITOR]: hasFeatureSelector(
        PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_EXHIBITOR,
      )(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMetaToggle);
