import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { FolderService } from '@premagic/core';
import { LOADINGS } from '../../../../../../../common/Constants';
import { getFolderImagesAndOrder } from '../../../../projects/folders/AccountFoldersDataDuck';
import { qrCodeDesignsSelector } from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeIllustrationSelectorModal from './QRCodeIllustrationSelectorModal';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
    show: ModalDuck.isModalOpenSelector(LOADINGS.QR_CODE.SELECT_ILLUSTRATION_MODAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_ILLUSTRATION_MODAL, false));
    },

    fetchImages: (projectId, folderId) => {
      dispatch(getFolderImagesAndOrder(dispatch, projectId, folderId, FolderService.FOLDER_TYPES.CLIENT_WEBSITE));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeIllustrationSelectorModal);
