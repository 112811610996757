import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { WorkflowType } from '../service/WorkflowService';
import { WorkflowListItems } from './WorkflowListItems';

function mapStateToProps(state, ownProps: { workflowList: Array<WorkflowType> }) {
  const { workflowList } = ownProps;
  return {
    isLoading: isLoadingSelector(LOADINGS.CRM_WORKFLOW.FETCH)(state),
    workflowList,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowListItems);
