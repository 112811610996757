import React from 'react';
import { SimpleLink } from '@premagic/myne';
import { HelpArticlesService } from '@premagic/core';

type Props = {
  children: React.ReactNode;
  articleId: HelpArticlesService.ARTICLES;
};

export function HelpLink(props: Props) {
  const { children, articleId } = props;

  return (
    <SimpleLink showExternalLinkIcon isExternalLink href={HelpArticlesService.getArticleUrl(articleId)}>
      {children}
    </SimpleLink>
  );
}
