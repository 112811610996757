import { PeopleFacesService, ProjectService } from '@premagic/core';
import { Avatar, AVATAR_SIZES, Label, LABEL_STYLES, Space, Text, TEXT_SIZE } from '@premagic/myne';
import { ArrayUtils, SimpleDateUtils, StringUtils } from '@premagic/utils';

import { DAM_LIBRARY_FILTERS_DETAILS } from './DAMLibraryFilterConstants';
import { DAM_LIBRARY_FILTER_KEYS } from './DAMLibraryFilterTypes';

const EMPTY_VALUE = '(not set)';

type TagsProps = {
  tags?: Array<string>;
};

export function Tags(props: TagsProps): JSX.Element {
  const { tags } = props;
  const tagContent = Array.isArray(tags) ? tags : [tags];
  if (!tags) return <Label style={LABEL_STYLES.PILL_DEFAULT}>All</Label>;

  return (
    <>
      {tagContent.map((item, idx) => {
        const key = `dummy-${idx}`;
        return (
          <Label key={key} style={LABEL_STYLES.PILL_PRIMARY}>
            {item}
          </Label>
        );
      })}
    </>
  );
}
type Props = {
  filterKey: DAM_LIBRARY_FILTER_KEYS;
  value?: string | Array<string>;
  projects: Record<string, ProjectService.ProjectType>;
};

export default function DAMLibraryFiltersItemContent(props: Props): JSX.Element {
  const { filterKey, value = EMPTY_VALUE, projects } = props;
  const { label } = DAM_LIBRARY_FILTERS_DETAILS[filterKey];
  let projectsString = '';
  let keyPeopleString = '';
  let peopleAvatars;

  if (value === EMPTY_VALUE)
    return (
      <>
        {label} <Space size={2} />
        <Text muted size={TEXT_SIZE.SIZE_6}>
          {value}
        </Text>
      </>
    );

  switch (filterKey) {
    case DAM_LIBRARY_FILTER_KEYS.PROJECT: {
      projectsString = Array.isArray(value)
        ? value.map((item) => projects[item]?.project_name).join(', ')
        : projects[value]?.project_name || value;
      return (
        <>
          {label} <Space size={2} /> {projectsString}
        </>
      );
    }
    case DAM_LIBRARY_FILTER_KEYS.FACE_ID: {
      peopleAvatars = Array.isArray(value) ? (
        ArrayUtils.intersperse(
          value.map((itemId) => (
            <Avatar
              size={AVATAR_SIZES.XS}
              user={{
                fullName: itemId,
                profilePic: PeopleFacesService.getPeopleFaceUrl(itemId),
              }}
              key={itemId}
            />
          )),
          ' ',
        )
      ) : (
        <Avatar
          size={AVATAR_SIZES.XS}
          user={{
            fullName: value,
            profilePic: PeopleFacesService.getPeopleFaceUrl(value),
          }}
        />
      );
      return (
        <>
          {label} <Space size={2} /> {peopleAvatars}
        </>
      );
    }
    case DAM_LIBRARY_FILTER_KEYS.TAGS: {
      return (
        <>
          {label} <Space size={2} /> <Tags tags={value === EMPTY_VALUE ? undefined : (value as Array<string>)} />
        </>
      );
    }
    case DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE: {
      keyPeopleString = Array.isArray(value) ? value.join(', ') : value;
      return (
        <>
          {label} <Space size={2} /> {keyPeopleString}
        </>
      );
    }
    case DAM_LIBRARY_FILTER_KEYS.DATE_FROM: {
      const dateStart = Array.isArray(value) ? value.join(', ') : value;
      const dateString = SimpleDateUtils.getDateStringFromDate(
        dateStart,
        SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_YEAR,
      );
      return (
        <>
          {label} <Space size={2} /> {dateString}
        </>
      );
    }
    case DAM_LIBRARY_FILTER_KEYS.DATE_END: {
      const dateEnd = Array.isArray(value) ? value.join(', ') : value;
      const dateString = SimpleDateUtils.getDateStringFromDate(
        dateEnd,
        SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_YEAR,
      );
      return (
        <>
          {label} <Space size={2} /> {dateString}
        </>
      );
    }
    case DAM_LIBRARY_FILTER_KEYS.SORT_BY: {
      const sortByValue = !Array.isArray(value) ? value : undefined;
      const valueString = sortByValue && StringUtils.toTitleCase(sortByValue, true);

      return (
        <>
          {label} <Space size={2} /> {valueString}
        </>
      );
    }

    default:
      return (
        <>
          {label}: {value}
        </>
      );
  }
}
