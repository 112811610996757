import React from 'react';
import { Label, LABEL_STYLES } from '@premagic/myne';
import { EventsService } from '@premagic/core';

type Props = {
  sortBy?: EventsService.EVENT_SORT_BY;
};
export default function EventSortByLabel(props: Props): JSX.Element {
  const { sortBy } = props;

  function getSortByLabel() {
    const SORT_BY_MAP = {
      [EventsService.EVENT_SORT_BY.CREATED_AT]: 'Added date',
      [EventsService.EVENT_SORT_BY.PRIORITY_VALUE]: 'Priority',
    };

    if (sortBy) {
      return SORT_BY_MAP[sortBy];
    }

    return SORT_BY_MAP[EventsService.EVENT_SORT_BY.CREATED_AT];
  }

  if (!sortBy) return <Label style={LABEL_STYLES.DEFAULT}>Added date</Label>;
  return <Label style={LABEL_STYLES.DEFAULT}>{getSortByLabel()}</Label>;
}
