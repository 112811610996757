import { QRCodeBackgroundPosterService } from '@premagic/core';
import { BrowserUtils, ColorUtils } from '@premagic/utils';
import ClassNames from 'classnames';
import styles from './qr-code-poster-illustration.module.css';
import * as QRCodePosters from './qr-code-poster-svg-illustration';

type QRCodePosterIllustrationProps = {
  name: string;
  posterColors?: QRCodeBackgroundPosterService.PosterColorType;
};

export function QRCodePosterIllustration(props: QRCodePosterIllustrationProps) {
  const { name, posterColors } = props;

  if (posterColors) {
    Object.entries(posterColors).forEach(([variable, value]) => {
      if (variable === QRCodeBackgroundPosterService.POSTER_COLOR_ID.SECONDARY_COLORS) {
        // this loop will run only for the four secondary colors
        Object.entries(value).forEach(([secondaryVariable, secondaryValue]) => {
          BrowserUtils.setCSSVariableValue(`--qr-code-poster-secondary_${secondaryVariable}`, secondaryValue);
        });
      } else {
        BrowserUtils.setCSSVariableValue(`--qr-code-poster-${variable}`, value);
      }
    });

    if (posterColors?.primary_color1 && posterColors?.primary_color2) {
      const mixedColors = ColorUtils.mixColors(posterColors.primary_color1, posterColors.primary_color2, 0.5);
      BrowserUtils.setCSSVariableValue(`--qr-code-poster-primary_colors_mixture`, mixedColors);
    }
  }

  const classes = ClassNames(styles['qr-code-poster']);

  return <span className={classes}>{QRCodePosters[name]}</span>;
}

type CustomColorCircleProps = {
  size?: 'sm' | 'md';
  color1: string;
  color2: string;
  color3: string;
  color4: string;
};

export function CustomColorCircle(props: CustomColorCircleProps) {
  const { size = 'sm', color1, color2, color3, color4 } = props;

  return (
    <div
      style={{
        borderRadius: '50%',
        backgroundBlendMode: 'screen',
        backgroundImage: `conic-gradient(${color1},${color2},${color3},${color4}),radial-gradient(#000, transparent)`,
      }}
      className={ClassNames(styles['custom-color-circle'], styles[`custom-color-circle--size-${size}`])}
    />
  );
}

type ColorCircleProps = {
  size?: 'sm' | 'md';
};

export function ColorCircle(props: ColorCircleProps) {
  const { size = 'sm' } = props;
  return <div className={ClassNames(styles['color-circle'], styles[`color-circle--size-${size}`])} />;
}

type PremagicWhiteLogoProps = {
  width: number;
  height: number;
};

export function PremagicWhiteLogo(props: PremagicWhiteLogoProps) {
  const { width, height } = props;
  return (
    <span
      className={ClassNames(styles['premagic-white-logo'])}
      style={{ display: 'inline-flex', width: `${width}px`, height: `${height}px` }}
    >
      {QRCodePosters.premagicWhiteLogo}
    </span>
  );
}

type PremagicBlackLogoProps = {
  width: number;
  height: number;
};

export function PremagicBlackLogo(props: PremagicBlackLogoProps) {
  const { width, height } = props;
  return (
    <span
      className={ClassNames(styles['premagic-black-logo'])}
      style={{ display: 'inline-flex', width: `${width}px`, height: `${height}px` }}
    >
      {QRCodePosters.premagicBlackLogo}
    </span>
  );
}
