import {
  Space,
  Row,
  Icon,
  ICON_SIZES,
  List,
  ListItem,
  ListItemAction,
  LIST_ITEM_STYLES,
  LIST_ITEM_ACTION_STYLES,
  Tooltip,
  Color,
  COLOR_SHADES,
  Col,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  SubTitle,
} from '@premagic/myne';
import { FolderService } from '@premagic/core';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import AccountFolderItem from '../folders/AccountFolderItem';
import FolderCoverPhotoContainer from '../folders/sidebar/FolderCoverPhotoContainer';
import FolderCoverFocalPointContainer from './FolderCoverFocalPointContainer';

type Props = {
  projectId: string;
  folderIds: Array<string>;
  folders: Array<FolderService.FolderType>;
  updateOrder: (projectId: string, folderIds: Array<string>, data: { from: number; to: number }) => void;
};
export default function ProjectHighlightFoldersList(props: Props): JSX.Element {
  const { folders, updateOrder, projectId, folderIds } = props;
  function onDrop(options: DropResult) {
    const { reason, destination, source } = options;
    if (reason === 'DROP' && destination) {
      updateOrder(projectId, folderIds, {
        from: source.index,
        to: destination.index,
      });
    }
  }
  return (
    <div>
      <SubTitle>Folder Banners</SubTitle>
      <Space vertical size={2} />
      <DragDropContext onDragEnd={onDrop}>
        <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical" showShadow>
          <List>
            <Droppable droppableId="FOLDERS-LIST">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {folders.map((folder, idx) => {
                    const { folder_id: folderId, meta } = folder;
                    return (
                      <Draggable key={folderId} draggableId={folderId} index={idx}>
                        {(draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            key={folderId}
                            className={`js-folder-${folderId}`}
                          >
                            <ListItem key={folderId} style={LIST_ITEM_STYLES.LIST}>
                              <Col size={12}>
                                <ListItemAction style={LIST_ITEM_ACTION_STYLES.DEFAULT}>
                                  <Tooltip message="Drag and move to reorder" placement="left">
                                    <Row>
                                      <Color shade={COLOR_SHADES.LIGHT} fillSolidIcon inline>
                                        <Icon name="drag" size={ICON_SIZES.SM} />
                                      </Color>
                                    </Row>
                                  </Tooltip>
                                </ListItemAction>
                                <Col size={8}>
                                  <FolderCoverPhotoContainer folderId={folderId} projectId={projectId} smallStyle />
                                </Col>
                                <AccountFolderItem folder={folder} />
                              </Col>
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
        </ScrollableContainer>
      </DragDropContext>
      <FolderCoverFocalPointContainer projectId={projectId} />
    </div>
  );
}
