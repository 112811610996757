import React, { useEffect, useState } from 'react';
import { Select, Row, Col, Space } from '@premagic/myne';
import { BackgroundMusicService } from '@premagic/core';
import { SoundUtils } from '@premagic/utils';
import ClientWebsiteBackgroundMusicPlayerContainer from './ClientWebsiteBackgroundMusicPlayerContainer';

const BackgroundMusicOptions = Object.values(BackgroundMusicService.BACKGROUND_MUSICS).map((item) => ({
  label: item.label,
  value: item.id,
}));

export function getBackgroundMusic(
  id?: BackgroundMusicService.BACKGROUND_MUSICS_IDS,
): { label: string; value: BackgroundMusicService.BACKGROUND_MUSICS_IDS } | undefined {
  return BackgroundMusicOptions.find((music) => music.value == id);
}

type Props = {
  name: string;
  defaultValue?: number;
  autoFocus?: boolean;
};

export function BackgroundMusicSelectInput(props: Props): JSX.Element {
  const { defaultValue, ...elementProps } = props;
  const selectedValue = getBackgroundMusic(defaultValue);
  const [selectedMusicId, setSelectedMusicId] = useState(selectedValue?.value);

  useEffect(() => () => {
    SoundUtils.stopAllSongs();
  });
  return (
    <Row>
      <Col size={6}>
        <Select
          {...elementProps}
          onChange={(selctedItem) => {
            setSelectedMusicId(Number(selctedItem?.value));
            SoundUtils.stopAllSongs();
          }}
          value={selectedValue}
          options={BackgroundMusicOptions}
          isMulti={false}
        />
      </Col>
      <Space size={16} />
      <Col size={6}>
        <ClientWebsiteBackgroundMusicPlayerContainer musicId={selectedMusicId} />
      </Col>
    </Row>
  );
}

type BackgroundMusicTextGroupValueProps = {
  value: number;
};

export function BackgroundMusicTextGroupValue(props: BackgroundMusicTextGroupValueProps): JSX.Element {
  const { value } = props;
  const clipArtObj = getBackgroundMusic(value);
  return <div>{clipArtObj?.label}</div>;
}
