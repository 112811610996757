export enum ACCOUNT_APP_ALERT_TYPE {
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export type AccountAppAlertType = {
  id: string;
  title: string;
  type: ACCOUNT_APP_ALERT_TYPE;
  readOnly: boolean;
};
