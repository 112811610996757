export const JS_SELECTORS = {
  EVENTS_MORE_OPTIONS: 'js-event-more-options',
  USER_MORE_OPTIONS: 'js-users-more-options--id-',
  FOLDER_MORE_OPTIONS: 'js-folder-more-options',
  PROJECT_MORE_OPTIONS: 'js-project-more-options',
  EVENTS_NAV_ITEM: 'js-events-nav-item',
  LIBRARY_NAV_ITEM: 'js-library-nav-item',
  ADD_EVENTS_BTN: 'js-add-events-btn',
  EVENT_ITEM_CALENDAR: 'js-event-item-calendar',
  EVENT_DETAILS_TAB: 'js-event-details-tab',
  CONVERT_TO_PROJECT: 'js-convert-to-project',
  DELIVERABLES_TAB: 'js-deliverables-tab',
  DIGITAL_INVITE_CARD: 'js-digital-invite-card',
  DIGITAL_INVITE_PREVIEW_BTN: 'js-digital-invite-preview-btn',
  SIGNATURE_ALBUM_CARD: 'js-signature-album-card',
  SIGNATURE_ALBUM_PREVIEW_BTN: 'js-signature-album-preview-btn',
  PHOTO_SELECTION_CARD: 'js-photo-selection-card',
  PHOTO_SELECTION_PREVIEW_BTN: 'js-photo-selection-preview-btn',
  CRM_PAYMENT_MORE_OPTIONS: (id: string) => `js-crm-payment-more-options--id-${id}`,
  PROPOSAL_VARIABLE_MORE_OPTIONS: (id: string) => `js-proposal-variable-more-options--id-${id}`,
  PROPOSAL_TEMPLATE_MORE_OPTIONS: (id: string) => `js-proposal-template-more-options--id-${id}`,
  SYSTEM_FOLDER_MORE_OPTIONS: (id: string) => `js-system-folder-more-options--id-${id}`,
  PROPOSAL_SLIDE_ITEM: (id: number) => `js-proposal-slide--id-${id}`,
  PROPOSAL_MORE_OPTIONS: 'js-proposal-more-options',
  FINANCIALS_TABS: 'js-financials-tabs',
  BADGE_CREATOR_MORE_OPTIONS: 'js-badge-more-options',
  POSTER: (id: string) => `js-poster-id-${id}`,
  OPEN_POSTER_LINK: 'js-open-poster-link',
};

export function getClassNameFromSelector(selector: string): string {
  return `.${selector}`;
}

export function getElementFromSelector(selector: string): HTMLElement | null {
  return document.querySelector(getClassNameFromSelector(selector));
}
