import React from 'react';
import { Grid } from '@premagic/myne';
import StoreOfferItemContainer from './store-item/StoreOfferItemContainer';

type Props = {
  storeOfferIds: Array<string>;
};

export default function StorePageContent(props: Props): JSX.Element {
  const { storeOfferIds } = props;
  return (
    <Grid colSize={320}>
      {storeOfferIds.map((id) => (
        <StoreOfferItemContainer key={id} id={id} />
      ))}
    </Grid>
  );
}
