import { FolderService, ProjectMetaService } from '@premagic/core';
import { FormGroup, FormLabel, Range, Space } from '@premagic/myne';
import { PhotoWatermarkUtils } from '@premagic/utils';
import { isNumber } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import BrandingFilesSelectorContainer from '../projects/sponsor/branding-files/BrandingFilesSelectorContainer';
import WatermarkPositionSelector from './WatermarkPositionSelector';
import WatermarkRemoveOnDownloadsOption from './WatermarkRemoveOnDownloadsOption';

const sizeOptions = Object.entries(PhotoWatermarkUtils.WATERMARK_SIZE_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.label,
}));

function SizeSelect(props: { selectedSize?: PhotoWatermarkUtils.WATERMARK_SIZES | number }) {
  const { selectedSize } = props;
  const selectedOption = sizeOptions.find((option) => option.value === selectedSize) || sizeOptions[0];
  const selectionValue = isNumber(selectedSize) ? selectedSize : Number(selectedOption.value);
  return (
    <FormGroup>
      <FormLabel info="Proportional to the photo">Size</FormLabel>
      <Range name="size" min={1} max={100} step={1} defaultValue={selectionValue || 15} />
    </FormGroup>
  );
}

function OpacitySelector(props: { selectedOpacity?: number }) {
  const { selectedOpacity = 1 } = props;

  return (
    <FormGroup>
      <FormLabel>Opacity</FormLabel>
      <Range name="opacity" min={0.1} max={1} step={0.1} defaultValue={selectedOpacity} />
    </FormGroup>
  );
}

type Props = {
  id: string;
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType | FolderService.BrandingFolderDataType;
  brandingFolderId?: string;
  fetchFolderFiles: (projectId: string, folderId: string) => void;
  watermarks?: ProjectMetaService.ProjectMetaWatermarkType;
  modalOptions?: Record<
    string,
    {
      name: string;
      fileId?: string;
    }
  >;
};

export default function WatermarkForm(props: Props) {
  const { projectMeta = {}, brandingFolderId, projectId, fetchFolderFiles, watermarks = {}, id, modalOptions } = props;

  const hasSelectedFile = modalOptions?.file?.fileId;

  const watermark = useMemo(() => {
    if (id && watermarks && watermarks[id]) {
      return watermarks[id];
    }
    if (id === 'old-watermark') {
      return {
        position: projectMeta[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_POSITION],
        size: projectMeta[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_SIZE],
        opacity: projectMeta[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_OPACITY],
        file: projectMeta[ProjectMetaService.PROJECT_META_TYPES.WATERMARK_FILE],
        x: undefined,
        y: undefined,
        remove_on_download: false,
      };
    }

    return {};
  }, [id, projectMeta, watermarks]);

  useEffect(() => {
    if (watermark.file && brandingFolderId) fetchFolderFiles(projectId, brandingFolderId);
  }, [projectId, brandingFolderId, watermark.file, fetchFolderFiles]);

  return (
    <div>
      <BrandingFilesSelectorContainer name="file" defaultValue={watermark.file} />
      {(hasSelectedFile || watermark.file) && (
        <div>
          <WatermarkRemoveOnDownloadsOption
            name="remove_on_download"
            removeOnDownloads={watermark.remove_on_download}
          />
          <WatermarkPositionSelector name="position" position={watermark.position} x={watermark.x} y={watermark.y} />
          <SizeSelect selectedSize={watermark.size} />
          <OpacitySelector selectedOpacity={watermark.opacity} />
        </div>
      )}

      <Space vertical size={8} />
    </div>
  );
}
