import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { PricingPlanService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../common/LoadingDuck';
import { PricingPlansSchema } from '../../../../schema/Schemas';
import { fetchPricingPlans } from './PricingPlanService';

const getActionType = ActionTypeUtils.getActionTypeFunction('PRICING_PLAN');

export const setPricingPlansData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchPricingPlansData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  dispatch(toggleLoadingState(dispatch, LOADINGS.PRICING_DATA, true));
  dispatch(clearErrorState(dispatch, LOADINGS.PRICING_DATA));

  fetchPricingPlans()
    .then((pricingPlans) => {
      const normalizedData = normalize(pricingPlans, PricingPlansSchema);
      dispatch(
        setPricingPlansData(dispatch, {
          plans: normalizedData.entities.pricingPlans,
          features: {},
        }),
      );
      dispatch(toggleLoadingState(dispatch, LOADINGS.PRICING_DATA, false));
      return pricingPlans;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, LOADINGS.PRICING_DATA, e));
      dispatch(toggleLoadingState(dispatch, LOADINGS.PRICING_DATA, false));
      ErrorTracker.logError('PRICING_PLAN: fetch failed', e);
    });
});

export const setPurchaseOrder = createAction(getActionType('ORDER', 'SET'), (dispatch, data) => data);

type StateType = {
  plans: IMap<string, PricingPlanService.PricingPlanType>;
  planFeature: IMap<string, unknown>;
  purchaseOrder?: {
    subscriptionId: string;
  };
};

const initialState = {
  plans: IMap(),
  planFeature: IMap(),
  purchaseOrder: undefined,
};

export default handleActions(
  {
    [setPricingPlansData.toString()]: (state, action: { payload }) => ({
      ...state,
      plans: IMap(action.payload.plans),
      planFeature: IMap(action.payload.features),
    }),
    [setPurchaseOrder.toString()]: (state, action: { payload }) => ({
      ...state,
      purchaseOrder: action.payload,
    }),
  },
  initialState,
);

const pricingDataSelector = (state) => state.entities.pricingPlans as StateType;
const pricingPlanFeaturesDataSelector = createSelector(pricingDataSelector, (state) => state.planFeature);
const pricingPlanDataSelector = createSelector(pricingDataSelector, (state) => state.plans);

export const purchaseOrderItemSelector = createSelector(pricingDataSelector, (state) => state.purchaseOrder);

export const pricingPlanItemsSelector = createSelector(pricingPlanDataSelector, (plans) => plans.toJSON());
export const pricingPlanValuesSelector = createSelector(pricingPlanDataSelector, (plans) =>
  plans
    .filter((plan) => plan.active && (plan?.variants?.monthly_IN?.amount || plan?.variants?.monthly_US?.amount) > 0)
    .sort((a, b) => {
      const aMonthlyPrice = a.variants.monthly_IN || a.variants.monthly_US;
      const bMonthlyPrice = b.variants.monthly_IN || b.variants.monthly_US;
      return aMonthlyPrice.amount - bMonthlyPrice.amount;
    })
    .valueSeq()
    .toArray(),
);

export const pricingPlanFeaturesValuesSelector = createSelector(pricingPlanFeaturesDataSelector, (planFeature) =>
  planFeature.toJS(),
);
