import { combineReducers } from 'redux';
import ReportProjectsDataDuck from './ReportProjectsDataDuck';
import ReportsPageDuck from './ReportsPageDuck';

export default combineReducers({
  data: combineReducers({
    projects: ReportProjectsDataDuck,
  }),

  page: ReportsPageDuck,
});
