import React, { useMemo, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageHeader,
  INPUT_SIZES,
  Loading,
  Row,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
} from '@premagic/myne';

import { ClientSettingsService, PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { MediaUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import PremiumFeatureIcon from '../PremiumFeatureIcon';
import MESSAGES from '../../../../../common/Messages';
import HighlightUploadSettingsContainer from './upload-settings/HighlightUploadSettingsContainer';

const PHOTO_ORDER_OPTIONS = [
  {
    label: 'Date and time',
    value: true,
  },
  {
    label: 'Photo name',
    value: false,
  },
];

type DownloadSettingsProps = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  hasSinglePhotoDownloadQualityPermission: boolean;
};

function DownloadSettings(props: DownloadSettingsProps) {
  const { clientSettings, hasSinglePhotoDownloadQualityPermission } = props;
  const downloadQualityOption = useMemo(
    () =>
      Object.entries(ProjectMetaService.SINGLE_FILE_DOWNLOAD_QUALITY_DETAILS).map(([key, value]) => ({
        label: value.title,
        value: key,
      })),
    [],
  );
  const selectedSingleFileDownloadValue =
    clientSettings[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SINGLE_FILE_DOWNLOAD_QUALITY] ||
    MediaUtils.DOWNLOAD_QUALITY_TYPES.RESOLUTION_4K;

  const [enableHighlightDownload, toggleDownloadOnHighlight] = useState(clientSettings.enable_highlight_download);
  const [requirePinToDownload, toggleRequirePinToDownload] = useState(clientSettings.require_pin_to_download);
  return (
    <div>
      <Row columnDirection>
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          Download Settings
        </Text>
      </Row>
      <FormGroup>
        <Row vcenter>
          <Text>Allow your client to download photos & videos</Text>
          <Space />
          <ToggleSwitch
            name="enable_highlight_download"
            checked={enableHighlightDownload}
            onChange={toggleDownloadOnHighlight}
          />
          <Space size={2} />
          {enableHighlightDownload ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
              Enabled
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
              Disabled
            </Text>
          )}
        </Row>

        {enableHighlightDownload && (
          <div>
            <Space vertical size={2} />
            <Row vcenter>
              <Space size={8} />
              <Text>Default Single file download quality</Text>
              <Space size={6} />
              <Col>
                <Select
                  size={INPUT_SIZES.SM}
                  isMulti={false}
                  disabled={!hasSinglePhotoDownloadQualityPermission}
                  name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SINGLE_FILE_DOWNLOAD_QUALITY}
                  value={downloadQualityOption.find((option) => option.value === selectedSingleFileDownloadValue)}
                  options={downloadQualityOption}
                />
              </Col>
              {!hasSinglePhotoDownloadQualityPermission && (
                <Text muted size={TEXT_SIZE.SIZE_5}>
                  {MESSAGES.FEATURE_NOT_AVAILABLE}
                </Text>
              )}
            </Row>
          </div>
        )}
      </FormGroup>
      <Text>Allow your client to download photos & videos with/without PIN</Text>
      <Space vertical size={2} />
      <FormGroup>
        <Row vcenter>
          <Space size={8} />
          <Text>Require PIN</Text>
          <Space size={9} />
          <ToggleSwitch
            name="require_pin_to_download"
            checked={requirePinToDownload}
            onChange={toggleRequirePinToDownload}
          />
          <Space size={2} />
          {requirePinToDownload ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
              Require PIN to Download
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
              Download without PIN
            </Text>
          )}
        </Row>
      </FormGroup>
    </div>
  );
}

type FormProps = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FAVORITE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: boolean;
  };
};

export function SignatureFolderPreferenceForm(props: FormProps): JSX.Element {
  const { clientSettings, savePreferences, isSaving, errors, hasFeatures } = props;

  const [enableManualPublishFolder, toggleEnableManualPublishFolder] = useState(
    ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_MANUAL_PUBLISH_FOLDER in clientSettings
      ? !!clientSettings[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_MANUAL_PUBLISH_FOLDER]
      : true,
  );
  const [enableHighlightFavorite, toggleFavoriteOnHighlight] = useState(clientSettings.enable_highlight_favorite);

  const [orderHighlightPhotosByDate, toggleOrderHighlightPhotosByDate] = useState(
    clientSettings.order_highlight_photos_by_date || false,
  );

  const hasFeatureSignatureAlbumFavorite =
    hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FAVORITE];

  const hasSinglePhotoDownloadQualityPermission =
    hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION];

  return (
    <Form
      onSubmit={savePreferences}
      errors={errors}
      formSpec={{
        order_highlight_photos_by_date: {
          type: 'boolean',
        },
        enable_highlight_download: {
          type: 'boolean',
        },
        require_pin_to_download: {
          type: 'boolean',
        },
        enable_highlight_favorite: {
          type: 'boolean',
        },
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_MANUAL_PUBLISH_FOLDER]: {
          type: 'boolean',
        },
      }}
    >
      <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
        Order Photos
      </Text>
      <FormGroup>
        <Row vcenter>
          <Text>Default Order for Signature album photos</Text>
          <Space size={6} />
          <Col>
            <Select
              isMulti={false}
              name="order_highlight_photos_by_date"
              value={PHOTO_ORDER_OPTIONS.find((option) => option.value === orderHighlightPhotosByDate)}
              onChange={(option) => {
                // @ts-ignore
                toggleOrderHighlightPhotosByDate(option.value);
              }}
              options={PHOTO_ORDER_OPTIONS}
            />
          </Col>
        </Row>
      </FormGroup>

      <Space vertical size={8} />
      <DownloadSettings
        clientSettings={clientSettings}
        hasSinglePhotoDownloadQualityPermission={hasSinglePhotoDownloadQualityPermission}
      />
      <Space vertical size={8} />
      <HighlightUploadSettingsContainer />
      <Space vertical size={8} />
      <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
        Favorite Settings
      </Text>
      <FormGroup>
        <Row vcenter>
          <Text>Allow your client to favorite photos & videos</Text>
          <Space size={9} />
          {!hasFeatureSignatureAlbumFavorite && (
            <>
              <PremiumFeatureIcon module="Allow favorite in Signature album" />
              <Space size={4} />
            </>
          )}
          <ToggleSwitch
            name="enable_highlight_favorite"
            checked={enableHighlightFavorite && hasFeatureSignatureAlbumFavorite}
            onChange={toggleFavoriteOnHighlight}
            disabled={!hasFeatureSignatureAlbumFavorite}
          />
          <Space size={2} />
          {enableHighlightFavorite ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
              Enabled
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
              Disabled
            </Text>
          )}
        </Row>
      </FormGroup>
      <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
        Publishing settings
      </Text>
      <FormGroup>
        <Row vcenter>
          <Text>Require manual publishing of folders</Text>
          <Space size={9} />
          <ToggleSwitch
            name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_MANUAL_PUBLISH_FOLDER}
            checked={enableManualPublishFolder}
            onChange={toggleEnableManualPublishFolder}
          />
          <Space size={2} />
          {enableManualPublishFolder ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
              Enabled
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
              Disabled
            </Text>
          )}
        </Row>
      </FormGroup>
      <Space vertical size={8} />
      <Text>
        <Text boldness={TEXT_BOLDNESS.BOLD} muted>
          Note:
        </Text>{' '}
        Setting will be applied to <Text boldness={TEXT_BOLDNESS.BOLD}>new projects only</Text>, For exiting project,
        you can change the settings inside the project
      </Text>
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} link="/settings">
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
          Save
        </Button>
      </FormFooter>
    </Form>
  );
}

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_FAVORITE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SINGLE_PHOTO_DOWNLOAD_QUALITY_PERMISSION]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET]: boolean;
  };
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
};

export default function SignatureAlbumPreferencePage(props: Props) {
  const { clientSettings, savePreferences, isSaving, errors, isLoading, hasFeatures, companyType } = props;
  if (isLoading) return <Loading />;
  const name = companyType === ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY ? 'Signature album' : 'Gallery';
  return (
    <InnerPage>
      <InnerPageHeader iconName="folder_simple" title={name} backTo={APP_URLS.SETTINGS.INDEX}>
        {name}
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <Space>
              <SignatureFolderPreferenceForm
                clientSettings={clientSettings}
                isSaving={isSaving}
                savePreferences={savePreferences}
                errors={errors}
                hasFeatures={hasFeatures}
              />
            </Space>
          </Card>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
