import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import DAMLibraryStartDateFilterSelector from './DAMLibraryStartDateFilterSelector';

function mapStateToProps(state, ownProps: { value: string }) {
  const { value } = ownProps;

  return {
    value,
    isLoading: isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryStartDateFilterSelector);
