import { connect } from 'react-redux';
import { RouterService } from '@premagic/core';
import { clientWebsiteForProjectSelector } from '../../projects/AccountProjectsDataDuck';
import EventPosterListItem from './EventPosterListItem';
import { eventPostersSelector } from './EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    eventId: string;
    projectId: string;
    focusId?: string;
    listStyle: 'list' | 'mini-item';
  },
) {
  const { id, eventId, projectId, focusId, listStyle } = ownProps;
  return {
    eventId,
    projectId,
    focusId,
    id,
    listStyle,
    poster: eventPostersSelector(state)[id],
    clientWebsite: clientWebsiteForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterListItem);
