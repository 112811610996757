import React from 'react';
import { Dropdown, DropdownIcon, Icon, ICON_SIZES, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';
import SponsorDeleteDialogContainer from './SponsorDeleteDialogContainer';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  projectId: string;
  sponsorId: string;
  hasPermissionsToManageSponsor: boolean;
  showDeleteDialog: (id: string) => void;
};

export default function EventSponsorOptionsMenu(props: Props): React.ReactElement {
  const { sponsorId, showDeleteDialog, projectId, hasPermissionsToManageSponsor } = props;
  return (
    <>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="top">
          <Icon name="more_vertical" size={ICON_SIZES.SM} />
        </DropdownIcon>

        <Menu>
          <MenuItem
            disabled={!hasPermissionsToManageSponsor}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_SPONSOR_MANAGEMENT]}
            onClick={() => showDeleteDialog(sponsorId)}
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      {hasPermissionsToManageSponsor && <SponsorDeleteDialogContainer sponsorId={sponsorId} projectId={projectId} />}
    </>
  );
}
