import { HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';

const APIS = APIURLService.API_URLS.CRM_WORKFLOW;

export type TaskGroupType = {
  id: number;
  tasks: Array<number>;
  due_date_duration: number;
  name: string;
  order: number;
  description: string;
  created_at: string;
  updated_at: string;
  workflow: number;
  default_assignee: string;
};

export function fetchTaskGroups(workflowId: number): Promise<{
  results: Array<TaskGroupType>;
  count: number;
}> {
  return HttpUtils.get(APIS.TASK_GROUP_ROOT(workflowId), {
    params: { limit: 100 },
  }).then((response) => response.data);
}

export function fetchTaskGroupDetails(workflowId: number, taskgroupId: number): Promise<TaskGroupType> {
  return HttpUtils.get(APIS.TASK_GROUP_DETAILS(workflowId, taskgroupId))
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function createTaskGroup(
  workflowId: number,
  data: {
    name: string;
    due_date_duration?: number;
    default_assignee?: string;
  },
): Promise<TaskGroupType> {
  return HttpUtils.post(APIS.TASK_GROUP_ROOT(workflowId), data, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}

export function deleteTaskGroup(workflowId: number, taskgroupId: number): Promise<void> {
  return HttpUtils.httpDelete(APIS.TASK_GROUP_DETAILS(workflowId, taskgroupId)).then((response) => response.data);
}

// will implement when required
// export function editTaskGroup(workflowId: number, taskgroupId: number, data: Partial<TaskGroupType>): Promise<any> {
//   return HttpUtils.patch(APIS.TASK_GROUP_DETAILS(workflowId, taskgroupId), data, true)
//     .then((response) => response.data)
//     .catch((e) => Promise.reject(e.data));
// }

export function changeOrderOfTaskGroup(workflowId: number, data: Array<number>): Promise<any> {
  return HttpUtils.put(APIS.TASK_GROUP_ORDER(workflowId), { taskgroup_ids: data }, true)
    .then((response) => response.data)
    .catch((e) => Promise.reject(e.data));
}
