import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  LoadingBox,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { BrowserUrlUtils, StringUtils } from '@premagic/utils';
import { useEffect, useMemo } from 'react';
import { DAM_GALLERY_SORT_BY, DAM_LIBRARY_FILTER_KEYS, DAMLibraryFiltersType } from '../filters/DAMLibraryFilterTypes';
import DAMFilesGalleryContainer from './DAMFilesGalleryContainer';

type ZeroStateMessageProps = {
  toFetchProjectsData: boolean;
  isProjectSelected: boolean;
};

function ZeroStateMessage(props: ZeroStateMessageProps) {
  const { toFetchProjectsData, isProjectSelected } = props;

  return (
    <>
      <Space vertical />
      <Card>
        <Space>
          {toFetchProjectsData ? (
            <Text muted center block>
              Premagic couldn't find any photos. Try using different filters.
            </Text>
          ) : (
            <Row center>
              <div>
                <Text block size={TEXT_SIZE.SIZE_3} muted>
                  To start searching:
                </Text>
                <Row vcenter>
                  <Text block size={TEXT_SIZE.SIZE_3} muted>
                    1. Select an Event
                  </Text>
                  <Space size={2} />
                  {isProjectSelected && (
                    <Color shade={COLOR_SHADES.SUCCESS}>
                      <Icon name="check_circle" size={ICON_SIZES.SM} align="bottom" alignSize={4} />
                    </Color>
                  )}
                </Row>
                <Text block size={TEXT_SIZE.SIZE_3} muted>
                  2. Apply a Filter
                </Text>
              </div>
            </Row>
          )}
        </Space>
      </Card>
    </>
  );
}

type Props = {
  fetchFiles: (filtersObject: BrowserUrlUtils.QueryObject) => void;
  fileIds: Array<string>;
  isLoading: boolean;
  filters: string;
  guestGalleryLink?: string;
  clearFilesData: () => void;
  navigateTo: (url: string) => void;
  sortBy?: DAM_GALLERY_SORT_BY;
  getDAMFilterLabels: (projectId: Array<string>) => void;
};

export default function DAMFilesPage(props: Props): JSX.Element {
  const {
    fileIds,
    isLoading,
    filters = '',
    fetchFiles,
    guestGalleryLink,
    clearFilesData,
    navigateTo,
    sortBy,
    getDAMFilterLabels,
  } = props;

  const filterObject = useMemo(
    () => BrowserUrlUtils.getQueryObjectFromString(filters) as DAMLibraryFiltersType,
    [filters],
  );
  // fetch files only when there is other filters present along with the project key
  const toFetchProjectsData = useMemo(
    () => filterObject[DAM_LIBRARY_FILTER_KEYS.PROJECT] && Object.keys(filterObject).length > 1,
    [filterObject],
  );

  useEffect(() => {
    clearFilesData();
  }, [clearFilesData]);

  useEffect(() => {
    if (toFetchProjectsData) {
      fetchFiles(filterObject);
    }
  }, [fetchFiles, toFetchProjectsData, filterObject]);

  useEffect(() => {
    const projectId = filterObject[DAM_LIBRARY_FILTER_KEYS.PROJECT];
    if (projectId) {
      getDAMFilterLabels(projectId);
    }
  }, [filterObject, getDAMFilterLabels]);

  return (
    <LoadingBox isLoading={isLoading}>
      <>
        {fileIds.length === 0 && (
          <ZeroStateMessage
            toFetchProjectsData={Boolean(toFetchProjectsData)}
            isProjectSelected={Boolean(filterObject[DAM_LIBRARY_FILTER_KEYS.PROJECT])}
          />
        )}
        <Space vertical size={8} />
        {fileIds.length > 0 && (
          <Card>
            <Space>
              <Row vcenter>
                <Row vcenter alignItems="baseline">
                  <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                    {fileIds.length}
                  </Text>
                  <Space size={1} />
                  <Text size={TEXT_SIZE.SIZE_3}>{StringUtils.pluralize('Photo', fileIds.length)}</Text>
                </Row>
                <Space />
                <Button
                  link={guestGalleryLink}
                  style={BUTTON_STYLES.LINK}
                  isExternalLink
                  disabled={!guestGalleryLink}
                  size={BUTTON_SIZE.SM}
                >
                  <Icon name="external_link" size={ICON_SIZES.SM} />
                  <Space size={1} />
                  Open in gallery
                </Button>
              </Row>
            </Space>
            <DAMFilesGalleryContainer filters={filters} sortBy={sortBy || DAM_GALLERY_SORT_BY.RELEVANCE} />
          </Card>
        )}
      </>
    </LoadingBox>
  );
}
