import { connect } from 'react-redux';
import { FolderService, PricingPlanFeaturesService } from '@premagic/core';

import FolderShareEmailSelector from './FolderShareEmailSelector';
import { requestingUserNameSelector } from '../../../crm/users/UsersDataDuck';
import { accountEmailTemplatesSelector } from '../../../acccount/AccountDataDuck';
import { hasFeatureSelector, photographerNameSelector } from '../../../settings/company/CompanyDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    folderType: FolderService.FOLDER_TYPES;
    clientName?: string;
  },
) {
  const { folderType, clientName } = ownProps;

  return {
    folderType,
    accountEmailTemplates: accountEmailTemplatesSelector(state),
    hasCustomCommunicationFeatures: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.CUSTOM_EMAIL_TEMPLATE,
    )(state),
    variables: {
      clientName: clientName || '',
      senderName: requestingUserNameSelector(state) || '',
      companyName: photographerNameSelector(state),
    },
  };
}

export default connect(mapStateToProps)(FolderShareEmailSelector);
