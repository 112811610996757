import { HttpUtils, MegaFileUploadService, StringUtils } from '@premagic/utils';
import { getAPIUrl } from './AppServices';

export enum CLIENT_WISH_STATUS {
  APPROVED = 'ACCEPT',
  REVIEW = 'WAITING_FOR_APPROVAL',
  DRAFT = 'DRAFT',
  REJECT = 'REJECT',
}

export type NewClientWishType = {
  message: string;
  name: string;
  files?: Array<string>;
};

export type ClientWishType = NewClientWishType & {
  id: string;
  website_id: string;
  project_id: string;
  folder_id: string;
  status: CLIENT_WISH_STATUS;
  created_at: string;
  updated_at: string;
  files?: Array<string>;
};

const MOCK = false;
const MOCK_DATA = {
  '62GHB7hAUNc': {
    created_at: '2022-07-07-08-09-24',
    folder_id: 'pvIUNIq33aQ',
    message: 'Best wishes on this wonderful journey, as you build your new lives together',
    name: 'Shishir GauravPhotography',
    project_id: 'G9yERc17i9s',
    status: 'ACCEPT',
    updated_at: '2022-07-07-08-09-24',
    website_id: 'kanika-shubham-l9u9',
  },
  lA6AtcLuYBA: {
    status: 'ACCEPT',
  },
} as unknown as Record<string, ClientWishType>;

function getClientWishApiURL(options: { projectId: string; folderId: string; wishId?: string }): string {
  const { projectId, folderId, wishId } = options;
  const url = `${getAPIUrl()}/project/${projectId}/website/${folderId}/wish/`;
  if (wishId) return `${url}${wishId}/`;
  return url;
}

export function fetchClientWishForWebsite(
  options: {
    projectId: string;
    folderId: string;
  },
  status?: CLIENT_WISH_STATUS,
): Promise<Record<string, ClientWishType>> {
  if (MOCK)
    return new Promise((resolve, reject) => {
      resolve(MOCK_DATA);
    });
  return HttpUtils.get(`${getClientWishApiURL(options)}list/${status || 'all'}/`).then(({ data }) => data);
}

export function createClientWishForWebsite(
  options: {
    projectId: string;
    folderId: string;
  },
  data: NewClientWishType,
): Promise<ClientWishType> {
  return HttpUtils.post(getClientWishApiURL(options), data).then((response) => response.data);
}

export function updateClientWishStatus(
  options: {
    wishId: string;
    projectId: string;
    folderId: string;
  },
  status: CLIENT_WISH_STATUS,
): Promise<ClientWishType> {
  return HttpUtils.put(getClientWishApiURL(options), { status }).then((response) => response.data);
}

export function updateClientWish(
  options: {
    wishId: string;
    projectId: string;
    folderId: string;
  },
  data: ClientWishType,
): Promise<ClientWishType> {
  return HttpUtils.put(getClientWishApiURL(options), data).then((response) => response.data);
}

export function deleteClientWish(options: { wishId: string; projectId: string; folderId: string }): Promise<void> {
  return HttpUtils.httpDelete(getClientWishApiURL(options)).then((response) => response.data);
}

/* In the Client website */
function getClientWishApiURLWithWebsiteUrlId(websiteUrlId: string): string {
  return `${getAPIUrl()}/savethedate/${websiteUrlId}/wish/`;
}

export function fetchUserWishes(websiteUrlId: string): Promise<Record<string, ClientWishType>> {
  if (MOCK)
    return new Promise((resolve, reject) => {
      resolve(MOCK_DATA);
    });
  return HttpUtils.get(getClientWishApiURLWithWebsiteUrlId(websiteUrlId)).then(({ data }) => data);
}

export function createUserWish(websiteUrlId: string, data: NewClientWishType): Promise<ClientWishType> {
  /*
   * Short term solution, when we serialize the form with files, we create 2 input(one with empty value and one with actual content)
   * elements so that our simple form parser getFormDataFromForm will convert to array. So the files will be `files: ["", "vod_4NFdZdf0UW4"]`
   * So we need to remove the empty string from the array before sending to server
   * */
  const files = data?.files?.filter((file) => !StringUtils.isEmptyString(file));
  return HttpUtils.post(getClientWishApiURLWithWebsiteUrlId(websiteUrlId), {
    ...data,
    files,
  }).then((response) => response.data);
}

export function addUserWishVideo(
  websiteUrlId: string,
  file: File,
  callbacks: MegaFileUploadService.CallbackOptionsType,
): void {
  const url = `${getAPIUrl()}/resumable_upload/`;
  MegaFileUploadService.initializeUploader(
    {
      url,
      params: {
        website_id: websiteUrlId,
      },
    },
    callbacks,
  );
  MegaFileUploadService.addFilesToUploader([file]);
}

/* END: In the Client website */
