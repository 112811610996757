import React, { useEffect } from 'react';
import { FormGroup, FormLabel, Link, LoadingDots, Select, Text, TEXT_SIZE } from '@premagic/myne';
import APP_URLS from '../../../../services/AppRouteURLService';

type Props = {
  selectedId?: string;
  templates: Array<{ value: string; label: string }>;
  fetchTemplates: () => void;
  isLoading: boolean;
};

export default function ProposalTemplatePicker(props: Props): JSX.Element {
  const { selectedId, templates, fetchTemplates, isLoading } = props;

  const selectedOption = templates.find((option) => option.value === selectedId);

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <FormGroup>
      <FormLabel>Template</FormLabel>
      {isLoading ? (
        <LoadingDots size="sm" />
      ) : (
        <Select
          disabled={isLoading}
          options={templates}
          value={selectedOption}
          name="proposal_template_id"
          isMulti={false}
        />
      )}
      {templates.length === 0 ? (
        <Text muted size={TEXT_SIZE.SIZE_5}>
          You can create new template from <Link to={APP_URLS.PROPOSALS_TEMPLATES.LIST}>proposal template</Link> page.
        </Text>
      ) : (
        <Text muted size={TEXT_SIZE.SIZE_5}>
          Manage <Link to={APP_URLS.PROPOSALS_TEMPLATES.LIST}>proposal templates</Link>
        </Text>
      )}
    </FormGroup>
  );
}
