import { connect } from 'react-redux';

import { eventPostersSelector, updateEventPoster } from '../../crm/event-posters/EventPostersDataDuck';
import EventPosterSizeSelector from './EventPosterSizeSelector';

function mapStateToProps(state, ownProps: { projectId: string; posterId: string }) {
  const { projectId, posterId } = ownProps;
  const poster = eventPostersSelector(state)[posterId];
  return { projectId, poster, posterId };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePoster: (options: { projectId; posterId }, data) => {
      dispatch(updateEventPoster(dispatch, options, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterSizeSelector);
