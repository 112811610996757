import React, { useRef, useState } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  Col,
  Color,
  COLOR_SHADES,
  Dialog,
  DialogTitle,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  InnerPage,
  InnerPageContent,
  InnerPageHeader,
  Input,
  List,
  ListItem,
  RealCheckbox,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroup,
  TextGroupLabel,
  TextGroupValue,
  Tooltip,
} from '@premagic/myne';
import { HelpDeskService, PremagicResources } from '@premagic/core';

import APP_URLS from '../../../services/AppRouteURLService';
import {
  NOTIFICATION_LIST,
  NotificationSettingsType,
  ToggleNotifications,
  UnToggleNotifications,
} from './NotificationService';
import MESSAGES from '../../../../../common/Messages';

type RequestForCustomNotificationFromProps = {
  type: 'phone' | 'email';
  email?: string;
};

function RequestForCustomNotificationFrom(props: RequestForCustomNotificationFromProps) {
  const { type, email = 'someone@google.com' } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const $button = useRef(null);

  function onSubmit(response: FormResponseType) {
    const { value } = response.data;
    HelpDeskService.raiseTicket({
      subject: `Request for custom notification for ${type}`,
      email,
      message: `Requesting for ${value}`,
      tags: ['client_request.custom_notifi'],
    });
    setShowSuccess(true);
  }
  return (
    <div>
      <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.XS} onClick={() => setShowDialog(!showDialog)} ref={$button}>
        Request for customization
      </Button>
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          {type === 'email' ? 'Request for custom email address' : 'Request for custom whatsapp number'}
        </DialogTitle>
        {showSuccess ? (
          <div>
            <Text block size={TEXT_SIZE.SIZE_3} center boldness={TEXT_BOLDNESS.BOLD}>
              Great!
            </Text>
            <Space vertical />
            <Text>Our team will reach out to you for further instruction.</Text>
            <Space vertical />
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
              Okay
            </Button>
          </div>
        ) : (
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <FormLabel>{type === 'email' ? 'Preferred email address' : 'Preferred Whatsapp Number'}</FormLabel>
              <Input name="value" required autoFocus />
              <Text muted>
                <Text muted boldness={TEXT_BOLDNESS.BOLD}>
                  Note:
                </Text>{' '}
                This is a ad-on feature and will be charged separately and it starts at $850.
              </Text>
            </FormGroup>
            <FormFooter>
              <Button style={BUTTON_STYLES.PRIMARY}>Request</Button>
            </FormFooter>
          </Form>
        )}
      </Dialog>
    </div>
  );
}

function getFormSpec() {
  return NOTIFICATION_LIST.reduce(
    (result, item) => ({
      ...result,
      [(item?.channelWhatApp.toggleable && item?.channelWhatApp.key) || '']: {
        type: 'boolean',
      },
      [(item?.channelEmail.toggleable && item?.channelEmail.key) || '']: {
        type: 'boolean',
      },
    }),
    {},
  );
}

function NotificationCheckbox(props: {
  channel: ToggleNotifications | UnToggleNotifications;
  notifications: NotificationSettingsType;
}) {
  const { channel, notifications = {} } = props;
  if (!channel.toggleable) {
    return (
      <Tooltip message={MESSAGES.NOTIFICATIONS.NOT_TOGGLEABLE}>
        <Color shade={COLOR_SHADES.BLUE_DARK} inline>
          <Icon name={channel.value ? 'check' : 'x'} size={ICON_SIZES.SM} />
        </Color>
      </Tooltip>
    );
  }

  return (
    <RealCheckbox
      disabledTooltipMessage={MESSAGES.NOTIFICATIONS.NOT_TOGGLEABLE}
      name={channel.key}
      defaultChecked={notifications[channel.key]}
    />
  );
}
type Props = {
  notifications: NotificationSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: FormResponseType) => void;
  errors?: Record<string, string>;
  companyPhone?: string;
  companyEmail?: string;
  companyDomain?: string;
};

export default function NotificationSettingsPage(props: Props): JSX.Element {
  const { savePreferences, isSaving, errors, isLoading, notifications, companyEmail, companyPhone, companyDomain } =
    props;
  const notificationFromEmail = `${companyDomain}.premagic.com`;
  const notificationFromPhone = PremagicResources.RESOURCES.notificationNumber.title;
  return (
    <InnerPage>
      <InnerPageHeader iconName="bell" title="Notifications" backTo={APP_URLS.SETTINGS.INDEX}>
        Notifications
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <Card>
          <Space>
            <Row vcenter>
              <Icon name="phone_outgoing" size={ICON_SIZES.SM} />
              <Space size={1} />
              <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                All message/notification will be send from
              </Text>
            </Row>

            <Space vertical />
            <TextGroup>
              <TextGroupLabel>Email</TextGroupLabel>
              <TextGroupValue value={notificationFromEmail} type={TEXT_GROUP_TYPES.TEXT} />
              <Space vertical size={4} />
              <RequestForCustomNotificationFrom type="email" email={companyEmail} />
            </TextGroup>
            <TextGroup>
              <TextGroupLabel>Whatsapp number</TextGroupLabel>
              <TextGroupValue value={notificationFromPhone} type={TEXT_GROUP_TYPES.TEXT} />
              <Space vertical size={4} />
              <RequestForCustomNotificationFrom type="phone" email={companyEmail} />
            </TextGroup>
          </Space>
        </Card>
        <Card>
          <Space>
            <Row vcenter>
              <Icon name="phone_incoming" size={ICON_SIZES.SM} />
              <Space size={1} />
              <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                Company notification will be received on
              </Text>
            </Row>
            <Space vertical />
            <TextGroup>
              <TextGroupLabel>Email</TextGroupLabel>
              <TextGroupValue value={companyEmail} type={TEXT_GROUP_TYPES.TEXT} />
            </TextGroup>
            <TextGroup>
              <TextGroupLabel>Whatsapp number</TextGroupLabel>
              <TextGroupValue value={companyPhone} type={TEXT_GROUP_TYPES.TEXT} />
            </TextGroup>
          </Space>
        </Card>
        <Card>
          <Space>
            <List>
              <ListItem>
                <Col size={8}>
                  <Text muted boldness={TEXT_BOLDNESS.BOLD}>
                    Notifications
                  </Text>
                </Col>
                <Col size={2} rightAlighed>
                  <Text muted center block boldness={TEXT_BOLDNESS.BOLD}>
                    Email
                  </Text>
                </Col>
                <Col size={2} rightAlighed>
                  <Text muted center block boldness={TEXT_BOLDNESS.BOLD}>
                    WhatsApp
                  </Text>
                </Col>
              </ListItem>

              <Form onSubmit={savePreferences} errors={errors} formSpec={getFormSpec()}>
                {NOTIFICATION_LIST.map((item) => {
                  const { channelWhatApp, channelEmail, title } = item;
                  return (
                    <ListItem key={title}>
                      <Col size={8}>
                        <Text>{title}</Text>
                      </Col>
                      <Col size={2} rightAlighed>
                        <Row center>
                          <NotificationCheckbox notifications={notifications} channel={channelEmail} />
                        </Row>
                      </Col>
                      <Col size={2} rightAlighed>
                        <Row center>
                          <NotificationCheckbox notifications={notifications} channel={channelWhatApp} />
                        </Row>
                      </Col>
                    </ListItem>
                  );
                })}

                <Space vertical size={35} />
                <Button style={BUTTON_STYLES.CANCEL} link="/settings">
                  Cancel
                </Button>
                <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
                  Save
                </Button>
              </Form>
            </List>
          </Space>
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
