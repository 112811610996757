import React from 'react';
import { Icon, ICON_ACCENT_STYLES, ICON_SIZES, Text, Tooltip } from '@premagic/myne';
import { EventsCategoryService, EventsService } from '@premagic/core';

type Props = {
  eventName: EventsService.EVENT_CATEGORY | string;
};

export default function EventCategoryIcon(props: Props): JSX.Element {
  const { eventName } = props;
  const details = EventsCategoryService.getEventCategoryDetails(eventName);
  const tooltipMessage = details.id === EventsService.EVENT_CATEGORY.CUSTOM ? eventName : details.title;
  return (
    <Text muted>
      <Tooltip message={tooltipMessage}>
        <Icon name={details.icon} size={ICON_SIZES.MD} accentStyle={ICON_ACCENT_STYLES.FILL} />
      </Tooltip>
    </Text>
  );
}
