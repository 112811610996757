import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { appAlertsRootSelector } from '../../reducers/selectors';
import { AccountAppAlertType } from './AccountAppAlertService';

const getActionType = ActionTypeUtils.getActionTypeFunction('APP_ALERTS');

export const addAppAlert = createAction(
  getActionType('DATA', 'ADD'),
  (dispatch: Dispatch, data: AccountAppAlertType) => ({ [data.id]: data }),
);

export const removeAppAlert = createAction(getActionType('DATA', 'REMOVE'), (dispatch: Dispatch, id: string) => id);

type StateType = {
  items: IMap<string, AccountAppAlertType>;
};

const initialState = {
  items: IMap(),
};

export default handleActions(
  {
    [addAppAlert.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [removeAppAlert.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

const appAlertsDataSelector = createSelector(appAlertsRootSelector, (state) => (state as StateType).items);
export const appAlertsSelector = createSelector(appAlertsDataSelector, (items) => items.valueSeq().toArray());
