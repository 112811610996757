import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { List, Map as IMap } from 'immutable';
import { ArrayUtils, ActionTypeUtils } from '@premagic/utils';
import { FolderFilePositionOrderService, FolderService, FileService, FolderMetaService } from '@premagic/core';
import { entitiesDataSelector } from '../../reducers/selectors';
import { filesEntitiesDataSelector } from './FilesDataDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('FOLDER_FILES_ORDER', false);

export const setFilesOrderForFolder = createAction(
  getActionType('DATA', 'SET'),
  (dispatch, folderId: string, data) => ({
    [folderId]: List(data),
  }),
);

export const setFilesOrderSortedByValueForFolder = createAction(
  getActionType('DATA_SORTED_BY', 'SET'),
  (dispatch, folderId, data) => ({
    [folderId]: data,
  }),
);

type FilesOrderType = {
  items: IMap<string, List<string>>;
  sortedBy: IMap<string, FolderFilePositionOrderService.FOLDER_FILE_ORDER_SORTED_BY_VALUES>;
};

const initialState = {
  items: IMap(),
  sortedBy: IMap(),
};

export default handleActions(
  {
    [setFilesOrderForFolder.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [setFilesOrderSortedByValueForFolder.toString()]: (state, action: { payload }) => ({
      ...state,
      sortedBy: state.sortedBy.merge(action.payload),
    }),
  },
  initialState,
);

const filesDataOrderSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.filesOrder as FilesOrderType,
);

export const filesEntitiesDataOrderSelector = createSelector(filesDataOrderSelector, (state) => state.items);

export const filesOrderedByDateSelector = createSelector(filesEntitiesDataSelector, (files) =>
  files.sort((a, b) => a.created_at - b.created_at),
);

export const folderFilesPositionOrderSortedByValueSelector = (folderId: string) =>
  createSelector(filesDataOrderSelector, (state) => state.sortedBy.get(folderId));

export function getFolderFilesOrder(
  folderId,
  folders: IMap<string, FolderService.FolderType>,
  filesOrder: IMap<string, List<string>>,
  files: IMap<string, FileService.FolderFileType>,
  foldersMetaData: IMap<string, FolderMetaService.FolderMetaType>,
) {
  const folder = folders.get(folderId);
  const filesOrderForFolder = filesOrder.get(folderId) || List();
  // This will not happen as we fetch folder first;
  if (!folder) return [];
  if (!folder.files) return [];
  const folderFiles = folder?.files;
  const folderMetaData = foldersMetaData.get(folderId);
  const filesForFolder = files.filter((file, fileId) => fileId in folderFiles);

  const fileIdsOrderedByDate = filesForFolder
    .sort((a, b) => a.created_at - b.created_at)
    .keySeq()
    .toArray();

  const fileIdsOrderedByName = filesForFolder
    .sort((a, b) => ArrayUtils.stringSortFunctionNatural(a.image_name, b.image_name))
    .keySeq()
    .toArray();

  switch (folder.folder_type) {
    case FolderService.FOLDER_TYPES.SELECTION:
      if (folderMetaData?.selection_files_order === 'NAME') return fileIdsOrderedByName;
      return fileIdsOrderedByDate;

    case FolderService.FOLDER_TYPES.HIGHLIGHT:
      if (folderMetaData?.highlight_files_order === 'NAME') return fileIdsOrderedByName;
      if (folderMetaData?.highlight_files_order === 'DATE') return fileIdsOrderedByDate;
      //  Custom ordering
      return filesOrderForFolder
        .filter((id) => filesForFolder.has(id)) // Filter files that are not present in folder
        .toJSON();

    default:
      return fileIdsOrderedByDate;
  }
}
