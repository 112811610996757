import { connect } from 'react-redux';
import SponsorCategoryList from './SponsorCategoryList';
import { sponsorCategoryIdsSelector } from '../data-duck/SponsorCategoryDataDuck';

function mapStateToProps(
  state,
  ownProps: { handleSelectCategory: (sponsorCategoryId: string) => void; selectedId?: string },
) {
  const { handleSelectCategory, selectedId } = ownProps;
  return {
    sponsorsCategoryListItemsIds: sponsorCategoryIdsSelector(state),
    handleSelectCategory,
    selectedId,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCategoryList);
