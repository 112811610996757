import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { isFeatureDAMLibraryEnabledSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';
import DAMLibrarySettingsPage from './DAMLibrarySettingsPage';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { hasFeatureSelector } from '../company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return {
    currentUrlPathname: pathname,
    hasLibraryFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.LIBRARY)(state),
    isEnabled: isFeatureDAMLibraryEnabledSelector(state),
    isLoading: isLoadingSelector(LOADINGS.COMPANY.SAVE_PROFILE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibrarySettingsPage);
