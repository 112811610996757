import { List as IList } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ErrorTracker, ArrayUtils } from '@premagic/utils';
import {
  AccountFinancialsMetricType,
  AccountFinancialsPaymentType,
  fetchAccountFinancialsMetric,
  fetchAccountFinancialsPayment,
} from './AccountFinancialsService';
import { accountFinancialsPageSelector } from '../../reducers/selectors';
import { LOADINGS } from '../../../../common/Constants';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';

const getActionType = ActionTypeUtils.getActionTypeFunction('ACCOUNT_FINANCIALS');

const setAccountFinancialsMetrics = createAction(getActionType('DATA_METRIC', 'SET'), (dispatch, data) => data);
const setPayments = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const setTotalPaymentsCountData = createAction(getActionType('META_INFO', 'SET'), (dispatch, data) => data);

export const fetchAccountFinancialPaymentsData = createAction(
  getActionType('DATA', 'FETCH'),
  async (dispatch, type, filters) => {
    const loadingKey = LOADINGS.ACCOUNT_FINANCIALS.LIST;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));
    try {
      const payments = await fetchAccountFinancialsPayment(type, filters);
      dispatch(setTotalPaymentsCountData(dispatch, payments.count));
      dispatch(setPayments(dispatch, payments.results));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));

      return payments;
    } catch (e) {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(setPayments(dispatch, []));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('AccountFinancials: fetch failed', e);
      return e;
    }
  },
);

export const fetchAccountFinancialMetric = createAction(getActionType('METRIC', 'FETCH'), async (dispatch, filters) => {
  const loadingKey = LOADINGS.ACCOUNT_FINANCIALS.COUNT;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  try {
    const counts = await fetchAccountFinancialsMetric(filters);

    dispatch(setAccountFinancialsMetrics(dispatch, counts));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));

    return counts;
  } catch (e) {
    dispatch(setErrorState(dispatch, loadingKey, e));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    ErrorTracker.logError('AccountFinancials: count fetch failed', e);
    return e;
  }
});

type StateType = {
  metric: AccountFinancialsMetricType;
  items: IList<AccountFinancialsPaymentType>;
  items_count: number;
};

const initialState = {
  items: IList(),
  items_count: 0,
  metric: {},
};

export default handleActions(
  {
    [setPayments.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IList(action.payload),
    }),
    [setTotalPaymentsCountData.toString()]: (state, action: { payload }) => ({
      ...state,
      items_count: action.payload,
    }),
    [setAccountFinancialsMetrics.toString()]: (state, action: { payload }) => ({
      ...state,
      metric: action.payload,
    }),
  },
  initialState,
);

export const accountFinancialsPaymentsDataSelector = createSelector(
  accountFinancialsPageSelector,
  (accountFinancials) => accountFinancials.data.payments as StateType,
);

export const accountFinancialsPaymentItemsSelector = createSelector(accountFinancialsPaymentsDataSelector, (state) =>
  state.items.sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.due_date, b.due_date, false)).toJSON(),
);

export const accountFinancialsPaymentItemsCountSelector = createSelector(
  accountFinancialsPaymentsDataSelector,
  (state) => state.items_count,
);
export const accountFinancialsPaymentMetricSelector = createSelector(
  accountFinancialsPaymentsDataSelector,
  (state) => state.metric,
);
