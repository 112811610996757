import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { pagesSelector } from '../../../reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROPOSAL_TEMPLATES', true);

export const setEditOfTemplate = createAction(
  getActionType('ITEM', 'EDIT'),
  (dispatch: Dispatch, templateId: string | null) => templateId,
);

type ProposalTemplatesPageType = {
  editTemplateId: string;
};
const initialState = {
  editTemplateId: undefined,
};

export default handleActions(
  {
    [setEditOfTemplate.toString()]: (state, action: { payload }) => ({
      ...state,
      editTemplateId: action.payload,
    }),
  },
  initialState,
);

export const eventAttendeesPageSelector = createSelector(
  pagesSelector,
  (state) => state.proposal.templates as ProposalTemplatesPageType,
);
export const templateIdForEditSelector = createSelector(eventAttendeesPageSelector, (page) => page.editTemplateId);
