import React from 'react';

import {
  Card,
  Col,
  Row,
  Space,
  FormResponseType,
  CardHeader,
  CardHeaderTitle,
  CardContent,
  Divider,
  Text,
  Form,
  FormFooter,
  Button,
  BUTTON_STYLES,
} from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  id: string;
  projectId: string;
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  isStaffUser: boolean;
  hasDigitalInviteCoverPhotosFilters: boolean;
  saveClientWebsite: (id: string, projectId: string, data: FormResponseType, navigateToUrl: string) => void;
  errors?: Record<string, string>;
  isLoading: boolean;
};

export default function ClientWebsiteClientPhotos(props: Props): JSX.Element {
  const {
    clientWebsite,
    id,
    projectId,
    isStaffUser,
    hasDigitalInviteCoverPhotosFilters,
    saveClientWebsite,
    errors,
    isLoading,
  } = props;
  const imageUploadApiURL = ClientWebsiteService.getClientWebsiteApiURL(id, projectId);
  const showBrideAndGroomPhotoSection = false;
  const clientWebsiteDetailsPageUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___PHOTOS, {
    id,
    projectId,
  });

  return (
    <>
      <Card>
        <Space>
          <Col size={8}>
            <ClientWebsiteFormItem
              itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_THUMBNAIL}
              clientWebsite={clientWebsite}
              imageUploadApiURL={imageUploadApiURL}
              isLoading={isLoading}
            />
          </Col>
        </Space>
      </Card>
      <Space vertical size={8} />
      <Card>
        <Space>
          <CardHeader>
            <CardHeaderTitle>Couple photo</CardHeaderTitle>
          </CardHeader>
          <>
            <Row>
              <Col size={4}>
                <ClientWebsiteFormItem
                  itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_1}
                  clientWebsite={clientWebsite}
                  imageUploadApiURL={imageUploadApiURL}
                  isLoading={isLoading}
                />
              </Col>
              <Space />
              <Col size={8}>
                <ClientWebsiteFormItem
                  itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_1_LG}
                  clientWebsite={clientWebsite}
                  imageUploadApiURL={imageUploadApiURL}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
            <Form
              onSubmit={(formResponse) => saveClientWebsite(id, projectId, formResponse, clientWebsiteDetailsPageUrl)}
              errors={errors}
            >
              <Row center>
                <Col size={4}>
                  <ClientWebsiteFormItem
                    itemKey={CLIENT_WEBSITE_FORM_ITEMS.THEME_STYLE}
                    clientWebsite={clientWebsite}
                  />
                </Col>
                <Col size={8}>
                  <FormFooter>
                    <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
                      Save
                    </Button>
                  </FormFooter>
                </Col>
              </Row>
            </Form>
          </>
        </Space>
      </Card>
      {showBrideAndGroomPhotoSection && (
        <Row>
          <Col>
            <ClientWebsiteFormItem
              itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_GROOM}
              clientWebsite={clientWebsite}
              imageUploadApiURL={imageUploadApiURL}
            />
          </Col>
          <Space />
          <Col>
            <ClientWebsiteFormItem
              itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_BRIDE}
              clientWebsite={clientWebsite}
              imageUploadApiURL={imageUploadApiURL}
            />
          </Col>
        </Row>
      )}
      <Space vertical size={8} />
      <Card accordion>
        <Space>
          <CardHeader>
            <CardHeaderTitle>Mini Gallery</CardHeaderTitle>
          </CardHeader>
          <CardContent>
            <ClientWebsiteFormItem
              itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_COUPLE_2}
              clientWebsite={clientWebsite}
              imageUploadApiURL={imageUploadApiURL}
              isLoading={isLoading}
            />
            <Divider />
            <Text block muted center>
              OR
            </Text>
            <Divider />
            <Space vertical />
            <ClientWebsiteFormItem
              itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_GRID}
              clientWebsite={clientWebsite}
              imageUploadApiURL={imageUploadApiURL}
            />
          </CardContent>
        </Space>
      </Card>
    </>
  );
}
