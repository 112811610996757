import { connect } from 'react-redux';
import { FolderService, ProjectService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';
import CompanyBrandingList from './CompanyBrandingList';
import { folderIdsWithFolderTypeForProjectSelector } from '../../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { fetchSystemFolders } from '../../projects/sponsor/SponsorAndBrandingDuck';

function mapStateToProps(state) {
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    ProjectService.SystemProject.id,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);

  return {
    brandingFolderIds,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.FETCH_ALL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchSystemFolders(dispatch, FolderService.FOLDER_TYPES.BRANDING));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyBrandingList);
