import React, { useRef } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
  DialogTitle,
  COLOR_SHADES,
} from '@premagic/myne';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  projectId: string;
  sponsorId: string;
  creativeId: string;
  isLoading: boolean;
  isDialogShown: boolean;
  toggleDialog: (sponsorId: string, creativeId: string, show: boolean) => void;
  deleteCreative: (options: { projectId: string; sponsorId: string; creativeId: string }) => void;
};

export default function SponsorCreativeDeleteDialog(props: Props): React.ReactElement {
  const { projectId, creativeId, isLoading, toggleDialog, isDialogShown, deleteCreative, sponsorId } = props;
  const target = useRef(null);

  return (
    <span ref={target}>
      <Dialog
        target={target.current}
        show={isDialogShown}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => toggleDialog(sponsorId, creativeId, false)}
      >
        <DialogTitle>Delete this Sponsor Content?</DialogTitle>
        <Text>Are you sure you want to delete this?</Text>
        <Space vertical size={6} />
        <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
        <DialogFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(sponsorId, creativeId, false)}>
            Cancel
          </Button>
          <Button
            style={BUTTON_STYLES.DANGER}
            onClick={() =>
              deleteCreative({
                projectId,
                sponsorId,
                creativeId,
              })
            }
            isLoading={isLoading}
          >
            Delete
          </Button>
        </DialogFooter>
      </Dialog>
    </span>
  );
}
