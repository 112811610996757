import { Dispatch } from 'redux';
import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ArrayUtils, BrowserUrlUtils, ErrorTracker } from '@premagic/utils';
import { ErrorDuck, LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import {
  EventTrackerService,
  FolderMetaService,
  FolderService,
  ProjectService,
  RouterService,
  Schemas,
} from '@premagic/core';
import { FormResponseType } from '@premagic/myne';
import { KEYS } from './common/ActionConstants';

import {
  createDuplicateProposal,
  createDuplicateProposalTemplate,
  createProposal,
  createProposalTemplate,
  deleteProposal,
  fetchSystemProposals,
  ProposalType,
  renameProposalName,
} from './services/ProposalService';
import { proposalPageSelector } from './common/selectors';
import { PROPOSALS_URLS } from './services/ProposalRouteURLService';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROPOSAL');

export const setProposalData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const addProposalData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);
const updateProposalData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, id, data) => ({
  id,
  data,
}));
const removeProposalData = createAction(getActionType('DATA', 'DELETE'), (dispatch, id) => id);

export const fetchProposalsTemplates = createAction(getActionType('DATA_TEMPLATES', 'FETCH'), (dispatch) => {
  const loadingKey = KEYS.PROPOSALS_TEMPLATES.FETCH;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

  fetchSystemProposals()
    .then((response) => {
      const normalizedData = normalize(response, Schemas.FoldersSchema);
      dispatch(setProposalData(dispatch, normalizedData.entities.folders));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Proposal templates: fetch failed', e);
    });
});

export const addNewProposalTemplate = createAction(
  getActionType('DATA', 'TEMPLATE_CREATE'),
  async (dispatch, name, folderMeta: FolderMetaService.FolderMetaType) => {
    const loadingKey = KEYS.PROPOSALS_TEMPLATES.CREATE;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    try {
      const template = await createProposalTemplate(name);
      const { project_id: projectId, folder_id: folderId } = template;

      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.CREATE, {
        name,
        projectId,
      });
      await FolderMetaService.updateFolderMeta(projectId, folderId, folderMeta);

      const normalizedData = normalize(template, Schemas.FolderSchema);
      dispatch(addProposalData(dispatch, normalizedData.entities.folders));

      const proposalDetails = BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS_TEMPLATES.DETAILS, {
        folderId,
        deckId: template.current_deck_version,
        slideId: 1,
      });
      RouterService.navigateTo(dispatch, proposalDetails);
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e as Record<string, string>));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Proposal Template: add new failed', e);
    }
  },
);

export const duplicateProposalTemplate = createAction(
  getActionType('DATA', 'TEMPLATE_DUPLICATE'),
  async (dispatch, name, folderId, projectId) => {
    const loadingKey = KEYS.PROPOSALS_TEMPLATES.CREATE;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    try {
      const newProposal =
        projectId === ProjectService.SystemProject.id
          ? await createDuplicateProposalTemplate({ proposal_template_id: folderId, folder_name: name })
          : await createDuplicateProposal(projectId, {
              proposal_template_id: folderId,
              folder_name: name,
            });
      const { folder_id: newFolderId } = newProposal;

      EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.DUPLICATE, {
        name,
        projectId,
      });
      const normalizedData = normalize(newProposal, Schemas.FolderSchema);
      dispatch(addProposalData(dispatch, normalizedData.entities.folders));

      const proposalDetails =
        projectId === ProjectService.SystemProject.id
          ? BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS_TEMPLATES.DETAILS, {
              folderId: newFolderId,
              deckId: newProposal.current_deck_version,
              slideId: 1,
            })
          : BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS.DETAILS, {
              projectId,
              folderId: newFolderId,
              deckId: newProposal.current_deck_version,
              slideId: 1,
            });
      RouterService.navigateTo(dispatch, proposalDetails);
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    } catch (e) {
      dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e as Record<string, string>));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('Proposal Template: duplicate failed', e);
    }
  },
);

export const addNewProposal = createAction(
  getActionType('DATA', 'CREATE'),
  (
    dispatch: Dispatch,
    projectId,
    formResponse: FormResponseType & {
      data: {
        proposal_template_id: string;
        folder_name: string;
      };
    },
  ) => {
    const loadingKey = KEYS.PROPOSALS.CREATE;
    const PANEL_KEY = KEYS.PROPOSALS.SHOW_ADD_PANEL;
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.CREATE, {
      name: formResponse.data.folder_name,
      projectId,
    });
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    createProposal(projectId, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.FoldersSchema);
        dispatch(addProposalData(dispatch, normalizedData.entities.folders));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        const proposalDetails = BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS.DETAILS, {
          projectId,
          folderId: response.folder_id,
          deckId: response.current_deck_version,
        });
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
        // Open the Variable panel by defaultØ
        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL, true),
        );
        RouterService.navigateTo(dispatch, proposalDetails);
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Proposal: add new failed', e);
      });
  },
);

export const addProposalWithProject = createAction(
  getActionType('DATA', 'CREATE'),
  async (
    dispatch: Dispatch,
    options: {
      projectName: string;
      eventId: string;
      eventName: string;
    },
    formResponse: FormResponseType & {
      data: {
        proposal_template_id: string;
        folder_name: string;
      };
    },
  ) => {
    const loadingKey = KEYS.PROPOSALS.CREATE;
    const PANEL_KEY = KEYS.PROPOSALS.SHOW_ADD_PANEL;
    const { projectName, eventId, eventName } = options;

    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.CREATE, {
      name: formResponse.data.folder_name,
      projectName,
      withoutProject: true,
    });
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.CREATE, { from: 'proposal' });

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    const project = await ProjectService.createProject({
      project_name: projectName,
      event_id: eventId,
      event_name: eventName,
    });

    createProposal(project.project_id, formResponse.data)
      .then((response) => {
        const normalizedData = normalize(response, Schemas.FoldersSchema);
        dispatch(addProposalData(dispatch, normalizedData.entities.folders));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        const proposalDetails = BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.PROPOSALS.DETAILS, {
          projectId: project.project_id,
          folderId: response.folder_id,
          deckId: response.current_deck_version,
        });
        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
        // Open the Variable panel by defaultØ
        dispatch(
          WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL, true),
        );
        RouterService.navigateTo(dispatch, proposalDetails);
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Proposal: add new failed', e);
      });
  },
);

export const removeProposal = createAction(
  getActionType('DATA', 'REMOVE'),
  (
    dispatch,
    projectId: string,
    folderId: string,
    options: {
      backToUrl?: string;
    },
  ) => {
    const loadingKey = KEYS.PROPOSAL.DELETE(folderId);
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.DELETE, {
      projectId,
    });

    dispatch(removeProposalData(dispatch, folderId));
    deleteProposal(projectId, folderId)
      .then((response) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(ModalDuck.toggleModalVisibility(dispatch, KEYS.PROPOSAL.SHOW_DELETE_DIALOG, false));
        if (options?.backToUrl) RouterService.navigateTo(dispatch, options?.backToUrl);
        return response;
      })
      .catch((e) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Proposal: delete failed', e);
      });
  },
);

export const renameProposal = createAction(
  getActionType('DATA', 'RENAME'),
  (dispatch, projectId: string, folderId: string, formResponse) => {
    const loadingKey = KEYS.PROPOSAL.UPDATE(folderId);
    const newName = formResponse.data.folder_name;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.UPDATE, {
      projectId,
      folderId,
    });

    dispatch(
      updateProposalData(dispatch, folderId, {
        folder_name: newName,
      }),
    );
    renameProposalName(projectId, folderId, newName)
      .then((response) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        dispatch(ModalDuck.toggleModalVisibility(dispatch, KEYS.PROPOSAL.SHOW_EDIT_NAME, false));
        return response;
      })
      .catch((e) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Proposal: rename failed', e);
      });
  },
);

export const shareProposalFolderWithClient = createAction(
  getActionType('DATA', 'SHARE'),
  (
    dispatch: Dispatch,
    options: {
      eventId: string;
      folderId: string;
      projectId: string;
    },
    formResponse: FormResponseType & {
      data: {
        clients: Array<{ name: string; phone_number: string; email: string }>;
        expiry_date?: string;
        email_content?: string;
      };
    },
  ) => {
    const loadingKey = KEYS.PROPOSAL.SHARE;
    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    const { projectId, folderId, eventId } = options;
    const { data } = formResponse;

    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROPOSAL.SHARE, {
      projectId,
    });

    return FolderService.shareFolderWithClientService(projectId, [folderId], data)
      .then((response) => {
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));

        dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, KEYS.PROPOSAL.SHARE, false));
        const eventDetailsPage = BrowserUrlUtils.getRouteUrlFor(PROPOSALS_URLS.CRM.EVENT__DETAILS, {
          eventId,
          focusId: folderId,
        });

        RouterService.navigateTo(dispatch, eventDetailsPage);
        return response;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e.data));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      });
  },
);

type StateType = {
  items: IMap<string, ProposalType>;
};
const initialState = {
  items: IMap({}),
};

export const ProposalDataReducer = handleActions(
  {
    [setProposalData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [addProposalData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [updateProposalData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.update(action.payload.id, (data) => Object.assign({}, data, action.payload.data)),
    }),
    [removeProposalData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.remove(action.payload),
    }),
  },
  initialState,
);

const proposalDataSelector = createSelector(proposalPageSelector, (state) => state.data.proposals as StateType);
export const proposalsSelectors = createSelector(proposalDataSelector, (state) => state.items);
export const proposalsItemsSelectors = createSelector(proposalsSelectors, (items) => items.toJSON());
export const proposalsTemplateItemsSelectors = createSelector(proposalsSelectors, (items) =>
  items
    .filter((item) => item.project_id === ProjectService.SystemProject.id)
    .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at)),
);

export const proposalsTemplatesIdsSelectors = createSelector(proposalsTemplateItemsSelectors, (items) =>
  items.keySeq().toArray(),
);

export const proposalsTemplateOptionsSelectors = createSelector(proposalsTemplateItemsSelectors, (items) =>
  items
    .map((item) => ({
      value: item.folder_id,
      label: item.folder_name,
    }))
    .valueSeq()
    .toJSON(),
);
