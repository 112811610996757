import { combineReducers } from 'redux';
import { AlbumCreatorPageReducer } from '@premagic/album-creator';
import { ProposalPageDataReducer } from '@premagic/proposals';
import MemoriesTemplatesDataDuck from '../components/avatar-studio/MemoriesTemplatesDataDuck';
import PosterAdvocatesDataDuck from '../components/poster-advocates/PosterAdvocatesDataDuck';

import ProjectOverviewPageReducer from '../components/projects/overview/ProjectOverviewPageReducer';
import ReportsPageReducer from '../components/reports/ReportsPageReducer';
import InvoicesPageReducer from '../components/settings/invoices/InvoicesPageReducer';
import EventDetailsPageDuck from '../components/crm/events/details/EventDetailsPageDuck';
import CalendarAndEventPageReducer from '../components/crm/calendar-and-event-page/CalendarAndEventPageReducer';
import AccountFinancialsPageReducer from '../components/account-financials/AccountFinancialsPageReducer';
import ProposalCreatorDuck from '../components/proposal-management/creator/ProposalCreatorDuck';
import FolderShareDataDuck from '../components/folder-share/FolderShareDuck';
import FontSelectorDuck from '../components/font-selector/FontSelectorDuck';
import StoreOffersDataDuck from '../components/store/StoreOffersDataDuck';
import ClientWebsitePageDuck from '../components/client-website/ClientWebsitePageDuck';
import ClientWebsiteItineraryDataDuck from '../components/client-website/itinerary/ClientWebsiteItineraryDataDuck';
import AccountprojectsListDataDuck from '../components/projects/AccountprojectsListDataDuck';
import EventSponsorPageDuck from '../components/crm/events/event-sponsors/EventSponsorPageDuck';
import EventSponsorDataDuck from '../components/crm/events/event-sponsors/EventSponsorDataDuck';
import SponsorCreativeDataDuck from '../components/crm/events/event-sponsor-creatives/SponsorCreativeDataDuck';
import RegistrationFormPageDuck from '../components/client-website/registration-form/RegistrationFormPageDuck';
import EventAttendeesPageDuck from '../components/crm/events/event-attendees/EventAttendeesPageDuck';
import EventAttendeesDataDuck from '../components/crm/events/event-attendees/EventAttendeesDataDuck';
import ProposalTemplatesPageDuck from '../components/proposal-management/proposal-templates/ProposalTemplatesPageDuck';
import ReportIssueDataDuck from '../components/crm/events/report-issues/data-duck/ReportIssueDataDuck';
import EventFeedbackDataDuck from '../components/crm/events/event-feedback/data-duck/EventFeedbackDataDuck';
import SponsorCategoryDataDuck from '../components/crm/events/event-sponsors/sponsor-category/data-duck/SponsorCategoryDataDuck';
import EventAttendeeBadgeDesignDuck from '../components/crm/events/event-attendees/event-attendee-badges/duck/EventAttendeeBadgeDesignDuck';
import eventReportDuck from '../components/crm/events/event-report/EventReportDuck';
import EventPostersDataDuck from '../components/crm/event-posters/EventPostersDataDuck';
import EventPosterCreatorDuck from '../components/event-poster-creator/EventPosterCreatorDuck';
import EventAttendeesCheckinZonesDataDuck from '../components/crm/events/event-attendees/face-checkin/EventAttendeesCheckinZonesDataDuck';
import EventExhibitorDataDuck from '../components/crm/events/event-exhibitor/EventExhibitorDataDuck';
import EventExhibitorPageDuck from '../components/crm/events/event-exhibitor/EventExhibitorPageDuck';
import DAMLibraryPageDuck from '../components/projects/library-dam/DAMLibraryPageDuck';
import DAMLibraryDataDuck from '../components/projects/library-dam/DAMLibraryDataDuck';

export default combineReducers({
  albumCreator: AlbumCreatorPageReducer,
  proposal: combineReducers({
    data: ProposalPageDataReducer,
    creator: ProposalCreatorDuck,
    templates: ProposalTemplatesPageDuck,
  }),
  reports: ReportsPageReducer,
  invoice: InvoicesPageReducer,
  projectOverview: ProjectOverviewPageReducer,
  eventDetails: EventDetailsPageDuck,
  calendarAndEvents: CalendarAndEventPageReducer,
  accountFinancials: AccountFinancialsPageReducer,
  folderShare: FolderShareDataDuck,
  fonts: FontSelectorDuck,
  store: combineReducers({
    data: combineReducers({
      storeOffers: StoreOffersDataDuck,
    }),
  }),
  clientWebsite: combineReducers({
    data: combineReducers({
      itinerary: ClientWebsiteItineraryDataDuck,
    }),
    clientWebsite: ClientWebsitePageDuck,
  }),
  projectsList: AccountprojectsListDataDuck,
  eventSponsor: combineReducers({
    data: combineReducers({
      sponsors: EventSponsorDataDuck,
      creatives: SponsorCreativeDataDuck,
    }),
    eventSponsor: EventSponsorPageDuck,
    category: SponsorCategoryDataDuck,
  }),
  eventExhibitor: combineReducers({
    data: combineReducers({
      exhibitors: EventExhibitorDataDuck,
    }),
    eventExhibitor: EventExhibitorPageDuck,
  }),
  eventReport: combineReducers({
    data: combineReducers({
      posterAdvocates: PosterAdvocatesDataDuck,
    }),
    eventReport: eventReportDuck,
  }),
  eventAttendees: combineReducers({
    data: combineReducers({
      attendees: EventAttendeesDataDuck,
      checkinZones: EventAttendeesCheckinZonesDataDuck,
    }),
    eventAttendees: EventAttendeesPageDuck,
    badgeDesign: EventAttendeeBadgeDesignDuck,
  }),
  eventPoster: combineReducers({
    creator: EventPosterCreatorDuck,
    data: combineReducers({
      posters: EventPostersDataDuck,
    }),
  }),
  registrationForm: combineReducers({
    registrationForm: RegistrationFormPageDuck,
  }),
  productReview: combineReducers({
    data: combineReducers({
      reportIssue: ReportIssueDataDuck,
      eventFeedback: EventFeedbackDataDuck,
    }),
  }),
  avatarStudio: combineReducers({
    data: combineReducers({
      memoryTemplates: MemoriesTemplatesDataDuck,
    }),
  }),
  DAMLibrary: combineReducers({
    data: combineReducers({
      DAMData: DAMLibraryDataDuck,
    }),
  }),
});
