import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';

import { LOADINGS } from '../../../common/Constants';
import AlbumPreview from './AlbumPreview';
import { initAlbumCreator } from '../AlbumDataPageDuck';
import { albumEntitySelector } from './AlbumDataDuck';
import { totalNumberOfPagesInCreatorSelector } from '../page/AlbumPageDataDuck';

function mapStateToProps(state, ownProps) {
  const { albumId } = ownProps.match.params;

  return {
    albumId,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.APP_LOADING)(state),
    album: albumEntitySelector(albumId)(state),
    noOfPages: totalNumberOfPagesInCreatorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (albumId, view) => dispatch(initAlbumCreator(dispatch, albumId, view)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumPreview);
