import { connect } from 'react-redux';
import SponsorCreativeItem from './SponsorCreativeItem';
import { PERMISSIONS } from '../../users/UserPermissionService';
import { hasPermission } from '../../users/UsersDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { sponsorCreativesSelector } from './SponsorCreativeDataDuck';
import { setEditOfCreative } from '../event-sponsors/EventSponsorPageDuck';
import { filesSelector } from '../../../images/FilesDataDuck';
import { eventSponsorsSelector } from '../event-sponsors/EventSponsorDataDuck';

function mapStateToProps(state, ownProps) {
  const { id, projectId } = ownProps;
  const creative = sponsorCreativesSelector(state)[id];

  return {
    id,
    creative,
    sponsor: eventSponsorsSelector(state)[creative.sponsor_id],
    projectId,
    hasPermissions: {
      [PERMISSIONS.SPONSOR_CREATIVE]: hasPermission(PERMISSIONS.SPONSOR_CREATIVE)(state),
      [PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT]: hasPermission(PERMISSIONS.SPONSOR_CREATIVE_MANAGEMENT)(state),
    },
    files: filesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditCreative: (id: string) => {
      dispatch(setEditOfCreative(dispatch, id));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCreativeItem);
