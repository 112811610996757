import React, { useEffect, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  FormFooter,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Row,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  ButtonIcon,
  BUTTON_ICONS_SIZES,
  BUTTON_ICON_STYLES,
} from '@premagic/myne';
import { FolderService, QRCodeBackgroundPosterService } from '@premagic/core';
import QRCodeIllustrationImageEditorContainer from './QRCodeIllustrationImageEditorContainer';
import QRCodeIllustrationFilesContainer from './QRCodeIllustrationFilesContainer';

type Props = {
  projectId: string;
  folderId: string;
  show: boolean;
  hideModal: () => void;
  fetchImages: (projectId, folderId) => void;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodeIllustrationSelectorModal(props: Props): JSX.Element | null {
  const { show, hideModal, projectId, folderId, fetchImages, qrCodeDesigns } = props;

  const isFileSelected = qrCodeDesigns?.custom_illustration;

  useEffect(() => {
    fetchImages(projectId, folderId);
  }, [projectId, folderId, fetchImages]);

  return (
    <Modal
      show={show}
      close={() => {
        hideModal();
      }}
      size={MODAL_SIZES.MD}
    >
      {isFileSelected ? (
        <>
          <Row spaceBetween>
            <ModalTitle>Custom Illustration</ModalTitle>
            <ButtonIcon
              onClick={() => hideModal()}
              title="Close"
              size={BUTTON_ICONS_SIZES.SM}
              style={BUTTON_ICON_STYLES.SECONDARY}
            >
              <Icon name="x" size={ICON_SIZES.LG} />
            </ButtonIcon>
          </Row>
          <QRCodeIllustrationImageEditorContainer projectId={projectId} folderId={folderId} />
        </>
      ) : (
        <>
          <ModalTitle>Select a file for the Illustration</ModalTitle>
          <QRCodeIllustrationFilesContainer projectId={projectId} folderId={folderId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={() => hideModal()}>
              Cancel
            </Button>
          </FormFooter>
        </>
      )}
    </Modal>
  );
}
