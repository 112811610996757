import { ActionConstants, ProposalDataDuck, ProposalDeckDataDuck } from '@premagic/proposals';
import { connect } from 'react-redux';

import { isModalOpenSelector, toggleModalVisibility } from '../../../../../common/ModalDuck';
import ProposalEditConfirmationDialog from './ProposalEditConfirmationDialog';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

const MODAL_KEY = ActionConstants.KEYS.PROPOSAL.SHOW_EDIT_PROPOSAL_CONFIRMATION;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, folderId, deckId } = ownProps;

  return {
    isEditing: isLoadingSelector(ActionConstants.KEYS.PROPOSAL_DECK.CREATE_VERSION)(state),
    projectId,
    folderId,
    deckId,
    folder: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
    isDialogShown: isModalOpenSelector(MODAL_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, MODAL_KEY, show));
    },
    createNewVersion: (projectId, deckId, options) =>
      dispatch(ProposalDeckDataDuck.addNewProposalDeckVersion(dispatch, projectId, deckId, options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalEditConfirmationDialog);
