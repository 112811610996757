import { Map as IMap, List } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { uniq, omit } from 'lodash';
import { ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { LoadingDuck, ErrorDuck } from '@premagic/common-ducks';

import { LOADINGS } from '../../../common/Constants';
import {
  AlbumPageType,
  createNewAlbumPageService,
  getAutoSelectedPhotos,
  getImagePositionName,
  getImagesPositionForTemplate,
  ImagePositionLocationsType,
  NewAlbumPageType,
  updateAlbumPageService,
} from '../services/AlbumServices';
import { albumCreatorSelectedPageSelector } from '../AlbumCreatorDuck';
import { albumCreatorImageEntitiesDataSelector, imageItemsForAlbumSelector } from '../AlbumImagesDataDuck';
import { albumCreatorPageSelector } from '../../../common/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM_PAGE');

export const setAlbumPagesData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

const updateAlbumPagesData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, data) => data);

const setPageData = createAction(getActionType('PAGE_DATA', 'SET'), (dispatch, pageNo, data) => ({
  pageNo,
  data,
}));

export const autoSelectPhotos = createAction(getActionType('AUTO_SELECT', 'SET'), (dispatch, images, albumData) => {
  dispatch(updateAlbumPagesData(dispatch, getAutoSelectedPhotos(images, albumData)));
});

const createNewAlbumPage = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch, albumId: string, pageData: NewAlbumPageType) => {
    const loadingKey = LOADINGS.ALBUM.ADD_NEW_PAGE;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    createNewAlbumPageService(albumId, pageData)
      .then((pageDataWithId) => {
        dispatch(setPageData(dispatch, pageData.position, pageDataWithId));
        return pageDataWithId;
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ALBUM_PAGE: creation failed', e);
      });
  },
);

const updateAlbumPage = createAction(
  getActionType('DATA', 'UPDATE'),
  (dispatch, albumId: string, pageId: string, pageData: AlbumPageType) => {
    const loadingKey = LOADINGS.ALBUM.ADD_NEW_PAGE;

    dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
    dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));

    updateAlbumPageService(albumId, pageId, pageData)
      .then((updatedPageData) => {
        dispatch(setPageData(dispatch, updatedPageData.position, updatedPageData));
      })
      .catch((e) => {
        dispatch(ErrorDuck.setErrorState(dispatch, loadingKey, e));
        dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('ALBUM_PAGE: update failed', e);
      });
  },
);

const saveNewChangesOnPage = createAction(
  getActionType('PAGE_DATA', 'UPDATE'),
  (dispatch, albumId, pageData: NewAlbumPageType | AlbumPageType) => {
    dispatch(setPageData(dispatch, pageData.position, pageData));

    if ('id' in pageData) {
      dispatch(updateAlbumPage(dispatch, albumId, pageData.id, pageData));
      return true;
    }

    dispatch(createNewAlbumPage(dispatch, albumId, pageData));
    return true;
  },
);

export const addImageAndSaveThePage = createAction(
  getActionType('ADD_IMAGE_PAGE', 'SAVE'),
  (
    dispatch,
    options: { albumId: string; pageData: NewAlbumPageType | AlbumPageType; newImagePosition; newImageId },
  ) => {
    const { albumId, pageData, newImagePosition, newImageId } = options;
    const imagePositionKey = getImagePositionName(newImagePosition);
    const updatedPageWithNewImage = {
      ...pageData,
      images: Object.assign(pageData.images || {}, {
        [imagePositionKey]: {
          imageId: newImageId,
        },
      }),
    };
    dispatch(saveNewChangesOnPage(dispatch, albumId, updatedPageWithNewImage));
  },
);

export const removeImageAndSaveThePage = createAction(
  getActionType('REMOVE_IMAGE_PAGE', 'SAVE'),
  (dispatch, options: { albumId: string; pageData: AlbumPageType; removePositions }) => {
    const { albumId, pageData, removePositions } = options;
    const imagePositionKey = getImagePositionName(removePositions);
    const updatedPageWithNewImage = {
      ...pageData,
      images: omit(pageData.images, imagePositionKey),
    };
    dispatch(saveNewChangesOnPage(dispatch, albumId, updatedPageWithNewImage));
  },
);

export const setTemplateForPageAndUpdateImage = createAction(
  getActionType('TEMPLATE', 'ADD_TO_PAGE_SAVE'),
  (
    dispatch,
    albumId: string,
    pageData: AlbumPageType,
    newTemplate: {
      templateName: string;
      positionsInTemplate: number;
    },
  ) => {
    const { images } = pageData;
    const { templateName, positionsInTemplate } = newTemplate;
    const newImagePositions = getImagesPositionForTemplate(images, positionsInTemplate);
    const updatedPageWithNewImagePosition = Object.assign(pageData, {
      template_name: templateName,
      images: newImagePositions,
    });
    dispatch(saveNewChangesOnPage(dispatch, albumId, updatedPageWithNewImagePosition));
  },
);

type ItemsType = IMap<string, AlbumPageType | NewAlbumPageType>;
type StateType = {
  items: ItemsType;
};
const initialState = {
  items: IMap<string, AlbumPageType | NewAlbumPageType>(),
};

export default handleActions<StateType>(
  {
    [setAlbumPagesData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [setPageData.toString()]: (state, action: { payload }) => {
      const { pageNo, data } = action.payload;

      return {
        ...state,
        items: state.items.update(pageNo.toString(), (pageData) => ({
          ...pageData,
          ...data,
        })),
      };
    },
  },
  initialState,
);

export const albumCreatorPagesSelector = createSelector(
  albumCreatorPageSelector,
  (albumCreator) => (albumCreator as { data: { pages: StateType } }).data.pages,
);

export const albumCreatorPagesDataSelector = createSelector(
  albumCreatorPagesSelector,
  (state) => state.items.toJSON() || {},
);

export const activeCreatorPagesDataSelector = createSelector(
  albumCreatorPagesDataSelector,
  albumCreatorSelectedPageSelector,
  (items, selectedPage) => (items[selectedPage] || { position: selectedPage, template_name: 1 }) as AlbumPageType,
);

export const totalNumberOfPagesInCreatorSelector = createSelector(
  albumCreatorPagesSelector,
  (state) => state.items.size || 1,
);

export const albumCreatorSelectedImageKeysSelector = createSelector(albumCreatorPagesSelector, (state) => {
  const imageKeys = state.items.reduce((result: List<string>, item) => {
    const { images } = item;
    if (images) {
      const imageIds = Object.values(images).map((details) => details.imageId);
      return result.concat(imageIds);
    }
    return result;
  }, List([]));

  return uniq(imageKeys.toJS()) as Array<string>;
});

export const albumCreatorImagesToSelectImagesSelector = createSelector(
  imageItemsForAlbumSelector,
  albumCreatorSelectedImageKeysSelector,
  (images, selectedImagesKey) => images.filter((image) => !selectedImagesKey.includes(image.id)),
);

export const albumCreatorSelectedTemplateForActivePageSelector = createSelector(
  activeCreatorPagesDataSelector,
  (page) => page?.template_name || 1,
);

export const imageDetailsForPageAndPositionSelector = (pageNo: number, position: ImagePositionLocationsType) =>
  createSelector(albumCreatorPagesDataSelector, albumCreatorImageEntitiesDataSelector, (pages, images) => {
    const imageDetails = pages[pageNo]?.images[getImagePositionName(position)];
    if (!imageDetails) return null;

    // TEMP: this is only to handle early data case when position-1: imageId
    if (typeof imageDetails === 'string') {
      return {
        image: images.get(imageDetails),
        imageId: imageDetails,
      };
    }

    const { imageId } = imageDetails;
    return {
      image: images.get(imageId),
      ...imageDetails,
    };
  });
