import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { fetchProject } from '../AccountProjectsDataDuck';
import SelectionFoldersPage from './SelectionFoldersPage';

function mapStateToProps(
  state,
  ownProps: {
    location: { pathname: string };
    match: {
      params: {
        projectId: string;
        folderId?: string;
      };
    };
  },
) {
  const { projectId, folderId } = ownProps.match.params;
  const { pathname } = ownProps.location;

  return {
    projectId,
    currentUrlPathname: pathname,
    activeFolderId: folderId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => {
      dispatch(fetchProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionFoldersPage);
