import React from 'react';
import {
  ColumnInterface,
  TableInstance as ReactTableInstance,
  Row as ReactTableRowType,
  UseSortByState,
  UsePaginationState,
  UseRowSelectState,
  Column,
  TableState,
  ActionType,
  Meta,
  Row,
  UseResizeColumnsState,
} from 'react-table';

export const ROW_SELECTION_COLUMN_ID = '-row-select-';

const ELEMENTS_TO_IGNORE = ['INPUT', 'BUTTON'];

export interface ReactTableInstanceType extends ReactTableInstance {
  page: Array<ReactTableRowType>;
}
/*
Not sure if this the right way handle types for state on TableInstance.
But lets try it out.
To add more types for new plugins check the available types in @types/react-table/index.d.ts and extended the required interface
* */

type TableDataRowType = Record<string, string>;
export type TableStateType = TableState<TableDataRowType> &
  UseSortByState<TableDataRowType> &
  UsePaginationState<TableDataRowType> &
  UseRowSelectState<TableDataRowType> &
  UseResizeColumnsState<TableDataRowType>;

export interface TableFiltersProps {
  offset?: number;
  limit?: number;
  ordering?: string;
}

// export type UseTableOptionsType<D> = {
//   initialState: any;
//   manualSortBy: boolean;
//   columns: Array<Column<D>>;
//   data: D[];
// } & Partial<{
//   stateReducer: (newState: TableState<D>, action: ActionType, previousState: TableState<D>) => TableState<D>;
//   useControlledState: (state: TableState<D>, meta: Meta<D>) => TableState<D>;
//   defaultColumn: Partial<Column<D>>;
//   getSubRows: (originalRow: D, relativeIndex: number) => D[];
//   getRowId: (originalRow: D, relativeIndex: number, parent?: Row<D>) => string;
// }>;

export enum TABLE_CELL_TYPES {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  BOOLEAN = 'boolean',
  CUSTOM = 'custom', // Render customer component using CellComponent attribute
}

export type TableDataType = Array<{ id: number | string }>;
export interface TableCellProps {
  cell: {
    value: string;
  };
  column: {
    type?: TABLE_CELL_TYPES;
    CellComponent?: React.ElementType;
  };
}

export interface TableColumnType {
  title: string;
  accessor: string; // | ((originalRow, rowIndex) => string);

  width?: number;
  type?: TABLE_CELL_TYPES;
  info?: string;

  hideByDefault?: boolean;
  // isFixed?: boolean;
  // sortable?: boolean;
}

export function handleOnClickRow(
  e: any | React.MouseEvent<HTMLTableRowElement>,
  row: {
    original: {
      id: number;
    };
  },
  onClick?: (id: number) => any,
) {
  if (!onClick) return;
  if (e.target.parentElement.classList.contains('js-checkbox')) return;
  if (ELEMENTS_TO_IGNORE.includes(e.target.nodeName)) return;

  onClick(row.original.id);
}

export function getSelectedEntitiesFromRowIndexes(
  data: TableDataType,
  rowIndex: Record<string, boolean>,
): Array<string> {
  return Object.entries(rowIndex)
    .filter(([, selected]) => selected)
    .map(([idx]) => data[idx]?.id);
}

export function getSelectedRowIndexesFromSelectedIds(
  data: TableDataType,
  selectedIds: Array<string>,
): Record<string, boolean> {
  return selectedIds.reduce((result, id) => {
    const rowIndex = data.findIndex((item) => item.id === id);
    return { ...result, [rowIndex]: true };
  }, {});
}

export function getColumnId(column: ColumnInterface | { accessor: string }) {
  if ('id' in column) return column.id;
  if ('accessor' in column) return column.accessor;
  return null;
}

export function getOrderingString(options: UseSortByState<any>): string | null {
  const { sortBy } = options;
  if (sortBy.length === 0) return null;

  // NOTE: right now api support on one sortAttribute
  // return sortBy.map((item) => {
  //     const isDescending = item.desc;
  //     return `${isDescending ? '-' : ''}${item.id}`;
  // });

  const isDescending = sortBy[0].desc;
  return `${isDescending ? '-' : ''}${sortBy[0].id}`;
}

function getOrderingObject(ordering: string): UseSortByState<any> {
  if (ordering) {
    const isDescending = ordering.charAt(0) === '-';
    const columnName = isDescending ? ordering.slice(1) : ordering;

    return { sortBy: [{ id: columnName, desc: isDescending }] };
  }
  return { sortBy: [] };
}

export function getInitialStateFilters(
  data: TableDataType,
  options: {
    tableFilters: TableFiltersProps;
    columns: Array<TableColumnType>;
    selectedIds?: Array<string>;
    pageSize: number;
  },
): Partial<TableStateType> {
  const { tableFilters = {}, columns, selectedIds = [], pageSize } = options;
  // @ts-ignore
  const { sortBy } = getOrderingObject(tableFilters.ordering);

  // @ts-ignore
  return {
    sortBy,
    hiddenColumns: columns.filter((col) => col.hideByDefault).map((col) => getColumnId(col) as string),
    selectedRowIds: getSelectedRowIndexesFromSelectedIds(data, selectedIds),
    pageSize,
  };
}
