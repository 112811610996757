import { connect } from 'react-redux';
import { EventAttendeesService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import { toggleModalVisibility } from '../../../../../common/ModalDuck';
import { projectsSelector } from '../../projects/AccountProjectsDataDuck';
import { eventAttendeeWithoutPhotoSelector } from '../../crm/events/event-attendees/EventAttendeesDataDuck';
import EventAttendeeBadgeDownloadAll from './EventAttendeeBadgeDownloadAll';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    posterId: string;
  },
) {
  const { projectId, posterId } = ownProps;

  const project = projectsSelector(state)[projectId];
  const poster = posterId ? eventPostersSelector(state)[posterId] : undefined;
  const { scope = [EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE] } = poster || {};

  return {
    name: `${project?.project_name} - ${poster?.name}`,
    projectId,
    posterId,
    eventAttendeesWithoutPhoto: eventAttendeeWithoutPhotoSelector(scope)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAlertModal: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.ATTENDEES_WITHOUT_PHOTO_ALERT_MODAL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeDownloadAll);
