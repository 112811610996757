import {
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemContent,
  ListItemIcon,
  ProgressCircle,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { ArrayUtils, MegaFileUploadService, StringUtils } from '@premagic/utils';
import { uniq } from 'lodash';

type Props = {
  projectProgress: Record<string, { progress: number; projectName: string; totalFiles: number }>;
  errors: Array<MegaFileUploadService.UploaderFileType>;
};

export default function MegaUploaderItems(props: Props): JSX.Element {
  const { projectProgress, errors } = props;
  const errorMessages = uniq(
    errors.filter((error) => error.errorMessage).map((error) => error.errorMessage),
  ) as string[];

  return (
    <List>
      {Object.entries(projectProgress).map(([projectId, info]) => {
        const { progress, projectName, totalFiles } = info;
        return (
          <ListItem key={projectId} style={LIST_ITEM_STYLES.COMPACT_LIST}>
            <ListItemIcon>
              <Icon name="file_plus" size={ICON_SIZES.SM} />
            </ListItemIcon>
            <ListItemContent>
              {projectName}
              <Text block size={TEXT_SIZE.SIZE_5}>
                {totalFiles} {StringUtils.pluralize('file', totalFiles)}
              </Text>
            </ListItemContent>
            <Col size={null} rightAlighed>
              <ProgressCircle progress={progress} />
            </Col>
          </ListItem>
        );
      })}
      {errors.length > 0 && (
        <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
          <ListItemIcon>
            <Color shade={COLOR_SHADES.DANGER}>
              <Icon name="alert_triangle" size={ICON_SIZES.SM} />
            </Color>
          </ListItemIcon>
          <ListItemContent>
            {errors.length} files error out
            <Text block size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.DANGER}>
              {ArrayUtils.listify(errorMessages)}
            </Text>
          </ListItemContent>
        </ListItem>
      )}
    </List>
  );
}
