import React from 'react';
import { BUTTON_SIZE, BUTTON_STYLES } from '../../Button/Buttons';
import { Dropdown, DropdownButton, Menu, MenuItem, MenuItemIcon } from '../../Dropdown';
import { Divider, SCROLLABLE_CONTAINER_SIZE, ScrollableContainer } from '../../Page';

export type RichTextVariableType = {
  label: string;
  id: string;
};

type Props = {
  options: Array<RichTextVariableType>;
  onSelect: (RichTextVariableType) => void;
  onNewVariable?: () => void;
};
export default function RichTextVariableSelector(props: Props) {
  const { options, onSelect, onNewVariable } = props;

  return (
    <Dropdown>
      <DropdownButton size={BUTTON_SIZE.XS} style={BUTTON_STYLES.TERTIARY}>
        Add Variable
      </DropdownButton>
      <Menu>
        <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} showShadow direction="vertical">
          {options.map((option) => (
            <MenuItem key={option.id} onClick={() => onSelect(option)}>
              {option.label}
            </MenuItem>
          ))}
        </ScrollableContainer>
        {onNewVariable && (
          <>
            <Divider />
            <MenuItem onClick={onNewVariable}>
              <MenuItemIcon name="plus" />
                New Variable
            </MenuItem>
          </>
        )}
      </Menu>
    </Dropdown>
  );
}
