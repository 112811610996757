import { HttpUtils } from '@premagic/utils';

const HELPDESK_DOMAIN = 'premagic';
const API_KEY = 'vEQnJutNuM86U4Dw3e6i';
const API_URLS = {
  TICKETS: `https://${HELPDESK_DOMAIN}.freshdesk.com/api/v2/tickets`,
};

export function raiseTicket(data: { subject: string; message: string; email: string; tags?: Array<string> }) {
  const { subject, message, email, tags = [] } = data;
  return HttpUtils.post(
    API_URLS.TICKETS,
    {
      subject,
      description: message,
      email,
      priority: 2,
      status: 2,
      tags: ['FROM_ACCOUNT_APP', ...tags],
    },
    false,
    false,
    {
      Authorization: `Basic ${btoa(`${API_KEY}:x`)}`,
    },
  );
}
