import React, { useEffect } from 'react';

import { Button, BUTTON_STYLES, COLOR_SHADES, Text, TEXT_SIZE } from '@premagic/myne';
import { MegaFileUploadService, StringUtils } from '@premagic/utils';
import { FileService } from '@premagic/core';
import { ProposalSlideService } from '@premagic/proposals';
import { ProposalCreatorUploadingFilesType } from '../ProposalCreatorDuck';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  creatorUploadingFiles: Record<string, ProposalCreatorUploadingFilesType>;
  allUploadFiles: Record<string, MegaFileUploadService.UploaderFileType>;
  removeSlideUploadingFile: (id: string) => void;
  saveProposalSlide: (options, data) => void;
  fetchProposalFiles: (options: { projectId: string; folderId: string }) => void;
  filesInProcess: Array<FileService.FolderFileType>;
  totalFiles: number;
  slides: Record<string, ProposalSlideService.ProposalSlideType>;
  showFilesModal: () => void;
};

export default function ProposalCreatorHeaderFilesInfo(props: Props) {
  const {
    projectId,
    folderId,
    deckId,
    creatorUploadingFiles,
    allUploadFiles,
    totalFiles,
    removeSlideUploadingFile,
    saveProposalSlide,
    fetchProposalFiles,
    filesInProcess,
    slides,
    showFilesModal,
  } = props;
  const totalUploadingFiles = Object.keys(creatorUploadingFiles).length;

  /* 2. Tell Proposal Creator about the upload
      - We cannot save the slide with new files details on this action, as  FolderService.addFilesToFolder( onAdd function will be binded only once to the Uploader,
      So If we add saveSlide(slideId, fileId) - the slideId will always be the first upload slideId.

      To solve this issue, We are telling the ProposalCreator about the uploads
      And On ProposalCreatorHeader, we listen on all the uploadingFiles and after the file is uploaded we just update the respect slide with the new file_id
      */
  useEffect(() => {
    Object.values(creatorUploadingFiles).forEach((file) => {
      const { uploaderUniqueIdentifierForFile, forSlideId, dataType } = file;
      const uploadedFile = allUploadFiles[uploaderUniqueIdentifierForFile];
      if (uploadedFile && uploadedFile?.uploaded) {
        removeSlideUploadingFile(uploaderUniqueIdentifierForFile);

        saveProposalSlide(
          { projectId, deckId, id: forSlideId },
          {
            structure: {
              ...slides[forSlideId].structure,
              [dataType]: {
                value: {
                  [uploadedFile.id as string]: {
                    id: uploadedFile.id,
                  },
                },
              },
            },
          },
        );
      }
    });
  }, [allUploadFiles]);

  useEffect(() => {
    let timer;

    if (filesInProcess.length > 0) {
      timer = setInterval(() => fetchProposalFiles({ projectId, folderId }), 60 * 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [filesInProcess.length]);

  return (
    <>
      <Button style={BUTTON_STYLES.LINK} onClick={showFilesModal}>
        {totalFiles} {StringUtils.pluralize('file', totalFiles)}
      </Button>
      {totalUploadingFiles > 0 && (
        <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
          ${totalUploadingFiles} ${StringUtils.pluralize('file', totalUploadingFiles)} uploading...
        </Text>
      )}
    </>
  );
}
