import React, { useState } from 'react';
import { Select, Row, Col, Space, ImageV2 } from '@premagic/myne';
import { ClientWebsiteClipArtService, ClientWebsiteService } from '@premagic/core';

const ClipArtOptions = Object.values(ClientWebsiteClipArtService.CLIP_ARTS).map((item) => ({
  label: item.label,
  value: item.id,
  thumbnail: ClientWebsiteClipArtService.getClipartImageUrl(item.folderPath, 'thumbnail'),
}));

export function getClientWebsiteClipArt(id?: ClientWebsiteClipArtService.CLIP_ART_IDS) {
  return ClipArtOptions.find((clipart) => clipart.value == id);
}

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  name: string;
  defaultValue?: number;
};

export function ClientWebsiteClipArtSelectInput(props: Props) {
  const { clientWebsite, defaultValue, ...elementProps } = props;
  const [selectedClipArtId, setSelectedClipArtId] = useState(Number(defaultValue));
  const selectedValue = getClientWebsiteClipArt(defaultValue);
  const selectedClipArtThumbnailUrl = ClientWebsiteClipArtService.getClipartImageUrl(
    ClientWebsiteClipArtService.CLIP_ARTS[selectedClipArtId].folderPath,
    'thumbnail',
  );
  return (
    <Row>
      <Col size={4}>
        <Select
          {...elementProps}
          value={selectedValue}
          options={ClipArtOptions}
          isMulti={false}
          onChange={(selectedItem) => setSelectedClipArtId(Number(selectedItem?.value))}
        />
      </Col>
      <Space size={16} />
      {selectedClipArtThumbnailUrl && (
        <Col size={8}>
          <ImageV2 cssStyle={{ width: '120px' }} src={selectedClipArtThumbnailUrl} alt="clipart" />
        </Col>
      )}
    </Row>
  );
}

type ClientWebsiteClipArtTextGroupValueProps = {
  value: number;
};

export function ClientWebsiteClipArtTextGroupValue(props: ClientWebsiteClipArtTextGroupValueProps) {
  const { value } = props;
  const clipArtObj = getClientWebsiteClipArt(value);
  return <div>{clipArtObj?.label}</div>;
}
