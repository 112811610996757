import React, { createContext, CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import ClassNames from 'classnames';

import { BrowserUtils, NumberUtils } from '@premagic/utils';

import { Col, Row, Space } from '../Grid/Grid';
import { COLOR_SHADES } from '../Color/Color';
import { HTML_ICON, Icon, ICON_SIZES } from '../Icon/Icons';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';

import styles from './cards.module.css';
import { Button, BUTTON_STYLES } from '../Button';

export enum CARD_STYLES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary', // gray
  TERTIARY = 'tertiary',
  TRANSPARENT = 'transparent',
}

export enum CARD_SIZES {
  DEFAULT = 'default',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  BOX_SM = 'box-sm',
  BOX_SM_MD = 'box-sm-md',
  BOX_MD = 'box-md',
  FULL_WIDTH = 'full-width',
}

interface CardProps {
  children: React.ReactNode;
  center?: boolean;
  style?: CARD_STYLES;
  size?: CARD_SIZES;
  overflowHidden?: boolean;
  highlight?: boolean;
  accordion?: boolean;
  defaultExpanded?: boolean;
  onClick?: () => void;
  cssStyle?: CSSProperties;
  fromTheme?: boolean;
  borderFromThemeContrast?: boolean;
}

export const CardAccordionContext = createContext({ expanded: true, toggleExpanded: () => {}, accordion: false });
export function Card(props: CardProps): React.ReactElement {
  const {
    children,
    center = false,
    style = CARD_STYLES.PRIMARY,
    size = CARD_SIZES.DEFAULT,
    overflowHidden = false,
    highlight,
    accordion = false,
    defaultExpanded = false,
    onClick,
    cssStyle,
    fromTheme,
    borderFromThemeContrast,
  } = props;

  const classes = ClassNames(styles.card, styles[`card--style-${style}`], styles[`card--size-${size}`], {
    [styles['card--center']]: center,
    [styles['card--overflow-hidden']]: overflowHidden,
    [styles['card--highlight']]: highlight,
    [styles['card--clickable']]: onClick,
    [styles['card--from-theme']]: fromTheme,
    [styles['card--border-from-theme-contrast']]: borderFromThemeContrast,
  });
  const $card = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (highlight) BrowserUtils.scrollTo($card.current, true);
  }, [highlight]);
  const onClickProperties = onClick
    ? {
        role: 'button',
        onClick: () => {
          if (onClick) onClick();
        },
        onKeyPress: () => {
          if (onClick) onClick();
        },
      }
    : {};
  return (
    <CardAccordionContext.Provider
      value={{
        expanded: accordion ? expanded : true,
        toggleExpanded: () => setExpanded(!expanded),
        accordion,
      }}
    >
      <div className={classes} ref={$card} {...onClickProperties} style={cssStyle}>
        {children}
      </div>
    </CardAccordionContext.Provider>
  );
}

type CardHeaderProps = {
  children: React.ReactNode;
  hasPadding?: boolean;
  onClick?: () => void;
};

export function CardHeader(props: CardHeaderProps): React.ReactElement {
  const { children, hasPadding = true, onClick } = props;
  const { expanded, toggleExpanded, accordion } = useContext(CardAccordionContext);

  const classes = ClassNames(styles['card-header'], {
    [styles['card-header--padding']]: accordion ? expanded : hasPadding,
  });

  if (accordion) {
    return (
      <Button style={BUTTON_STYLES.RESET} block className={classes} onClick={toggleExpanded}>
        {children}
        <Col rightAlighed size={null}>
          <Icon name={expanded ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
        </Col>
      </Button>
    );
  }

  if (onClick) {
    return (
      <Button style={BUTTON_STYLES.RESET} block className={classes} onClick={onClick || toggleExpanded}>
        {children}
      </Button>
    );
  }

  return <div className={classes}>{children}</div>;
}

type CardContentProps = {
  children: React.ReactNode;
};

export function CardContent(props: CardContentProps): React.ReactElement | null {
  const { children } = props;
  const classes = ClassNames(styles['card-content']);
  const { expanded, accordion } = useContext(CardAccordionContext);

  if (accordion && !expanded) return null;
  return <div className={classes}>{children}</div>;
}

type CardHeaderTitleProps = {
  children: React.ReactNode;
};

export function CardHeaderTitle(props: CardHeaderTitleProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['card-header__title']);

  return (
    <div className={classes}>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_3}>
        {children}
      </Text>
    </div>
  );
}

type CardHeaderActionsProps = {
  children: React.ReactNode;
};

export function CardHeaderActions(props: CardHeaderActionsProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['card-header__actions']);

  return <div className={classes}>{children}</div>;
}

interface MiniCardProps {
  children: React.ReactNode;
}

export function MiniCard(props: MiniCardProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['mini-card']);

  return <div className={classes}>{children}</div>;
}

interface MiniCardHeaderProps {
  children: React.ReactNode;
}

export function MiniCardHeader(props: MiniCardHeaderProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['mini-card__header']);

  return <div className={classes}>{children}</div>;
}

interface MiniCardContentProps {
  children: React.ReactNode;
}

export function MiniCardContent(props: MiniCardContentProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['mini-card__content']);

  return <div className={classes}>{children}</div>;
}

interface MiniCardCountsProps {
  children: React.ReactNode;
}

export function MiniCardCounts(props: MiniCardCountsProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['mini-card__counts']);

  return <div className={classes}>{children}</div>;
}

interface MiniCardCountProps {
  children: React.ReactNode;
}

export function MiniCardCount(props: MiniCardCountProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['mini-card__count']);

  return <div className={classes}>{children}</div>;
}

interface MiniCardLoadingPulseProps {
  children: React.ReactNode;
}

export function MiniCardLoadingPulse(props: MiniCardLoadingPulseProps): React.ReactElement {
  const { children } = props;
  const classes = ClassNames(styles['mini-card__loading-pulse']);

  return <div className={classes}>{children}</div>;
}

interface PricingCardProps {
  children: React.ReactNode;
  highlight: boolean;
  last: boolean;
}

export function PricingCard(props: PricingCardProps): React.ReactElement {
  const { children, highlight, last } = props;
  return (
    <div
      className={ClassNames(styles['pricing-card'], {
        [styles['pricing-card--highlight']]: highlight,
        [styles['pricing-card--last']]: last,
      })}
    >
      {children}
    </div>
  );
}

interface PricingCardHeaderProps {
  children: React.ReactNode;
}

export function PricingCardHeader(props: PricingCardHeaderProps): React.ReactElement {
  const { children } = props;
  return (
    <div className={styles['pricing-card__header']}>
      <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.WHITE}>
        {children}
      </Text>
    </div>
  );
}

interface PricingCardContentProps {
  children: React.ReactNode;
}

export function PricingCardContent(props: PricingCardContentProps): React.ReactElement {
  const { children } = props;
  return (
    <div className={styles['pricing-card__content']}>
      <Space size={4} vertical />
      {children}
    </div>
  );
}

interface PricingCardPriceProps {
  amount: number;
  view: 'monthly' | 'yearly';
  userLocal: 'US' | 'IN';
}

const SHOW_GST = false;

export function PricingCardPrice(props: PricingCardPriceProps): React.ReactElement {
  const { amount, view, userLocal } = props;
  const GST = 0.18;

  return (
    <div>
      <div className={styles['pricing-card__price']}>
        <Text block size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.BLUE_DARK} center>
          {userLocal === 'IN' ? HTML_ICON.rupee : '$'}
          {NumberUtils.getCurrencyInFormat(amount)}
        </Text>
        <Text color={COLOR_SHADES.PINK} boldness={TEXT_BOLDNESS.BOLD} center block>
          per {view === 'monthly' ? 'month' : 'year'}
        </Text>
      </div>
      {SHOW_GST && userLocal === 'IN' && (
        <Row>
          <Col size={null} rightAlighed>
            <Text size={TEXT_SIZE.SIZE_5} muted>
              plus{' '}
              <Text size={TEXT_SIZE.SIZE_5}>
                {userLocal === 'IN' ? HTML_ICON.rupee : '$'}
                {NumberUtils.getCurrencyInFormat(amount * GST)}
              </Text>{' '}
              GST
            </Text>
          </Col>
        </Row>
      )}
    </div>
  );
}
