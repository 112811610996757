import { EventAttendeesService, EventPosterService } from '@premagic/core';
import { POSTER_WIDGET_TYPE, PosterWidgetTextType, PosterWidgetType } from '@premagic/poster-creator';
import { CryptoUtils, MathUtils } from '@premagic/utils';

const { getMeasurementUnitForX, getMeasurementUnitForY } = MathUtils;
type PosterSizeType = {
  width: number;
  height: number;
};

function getDefaultPositionValues(
  posterSize: PosterSizeType,
  options?: {
    x?: number;
    y?: number;
    z?: number;
    width?: number;
    height?: number;
  },
): {
  position: { x: number; y: number; z: number };
  size: { width: number; height: number };
  fill?: string;
} {
  const { x = 10, y = 10, z = 1, width = 10, height = 10 } = options || {};
  return {
    position: {
      x: getMeasurementUnitForX(x, posterSize),
      y: getMeasurementUnitForY(y, posterSize),
      z,
    },
    size: {
      width: getMeasurementUnitForX(width, posterSize),
      height: getMeasurementUnitForY(height, posterSize),
    },
    fill: '#000000',
  };
}

function getDefaultForText(): Partial<PosterWidgetTextType> {
  return {
    font_color: '#000000',
    font_family: 'Source Sans Pro',
    font_weight: 400,
    font_size: 16,
    font_line_height: 1.2,
    font_align: 'left',
    ellipsis: true,
  };
}

const DEFAULT_FUNCTION_TO_CALL: Partial<
  Record<POSTER_WIDGET_TYPE, (posterSize: PosterSizeType) => Partial<PosterWidgetType>>
> = {
  [POSTER_WIDGET_TYPE.TEXT]: getDefaultForText,
};
const DEFAULT_VALUES_FOR_SYSTEM_WIDGETS: Partial<
  Record<
    EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS,
    (options: { posterSize: PosterSizeType; zIndex: number }) => Partial<PosterWidgetType>
  >
> = {
  [EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE]: (options) => ({
    font_size: 34,
    ...getDefaultPositionValues(options.posterSize, {
      z: options.zIndex,
      width: 60,
      height: 25,
    }),
  }),
  [EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE]: (options) => ({
    ...getDefaultPositionValues(options.posterSize, {
      z: options.zIndex,
      width: 30,
      height: 25,
    }),
  }),
  [EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER]: (options) => ({
    position: {
      x: 0,
      y: 0,
      z: 0,
    },
    size: options.posterSize,
  }),
};

export function getDefaultDataForWidget(
  widget: {
    name: string;
    icon: string;
    type: POSTER_WIDGET_TYPE;
    value?: string;
  },
  poster: EventPosterService.EventPosterType,
): PosterWidgetType {
  const id = CryptoUtils.createUUID();
  const widgets = Object.values(poster.widgets);
  const widgetsWithSameType = Object.values(widgets).filter((item) => item.type === widget.type);
  const name = EventPosterService.isSystemWidget(widget)
    ? widget.name
    : `${widget.name} ${widgetsWithSameType.length === 0 ? '' : widgetsWithSameType.length + 1}`;
  const zIndex = widgets.length;
  const posterSize = poster.size;
  const defaultFunction =
    widget.type in DEFAULT_FUNCTION_TO_CALL
      ? (DEFAULT_FUNCTION_TO_CALL[widget.type] as (p: PosterSizeType) => PosterWidgetType)
      : () => ({});
  const defaultValuesForSystemWidgetsFunction =
    widget.name in DEFAULT_VALUES_FOR_SYSTEM_WIDGETS ? DEFAULT_VALUES_FOR_SYSTEM_WIDGETS[widget.name] : () => ({});
  return {
    ...getDefaultPositionValues(posterSize, {
      z: zIndex,
    }),
    ...defaultFunction(posterSize),
    ...defaultValuesForSystemWidgetsFunction({ posterSize, zIndex }),
    id,
    name,
    type: widget.type as POSTER_WIDGET_TYPE.TEXT, // POSTER_WIDGET_TYPE.TEXT was a hack to fix the error
    value: widget.value,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };
}

export function getWidgetToAddOptions(options: {
  widgets: Array<PosterWidgetType>;
  type: EventPosterService.EVENT_POSTER_TYPE;
  scope: Array<EventAttendeesService.EVENT_PEOPLE_TYPE>;
}): Array<{
  name: string;
  title: string;
  icon: string;
  type: POSTER_WIDGET_TYPE;
  value?: string;
}> {
  const { widgets, type, scope } = options;
  const systemWidgetToAdd = Object.entries(EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS_DETAILS)
    .filter(([id, details]) => {
      const isApplicableForPosterType = details.applicableForPosterType
        ? details.applicableForPosterType.includes(type)
        : true;
      const isApplicableForPeopleType = details.applicableForPeopleType
        ? scope.some((peopleType) => details.applicableForPeopleType?.includes(peopleType))
        : true;
      return isApplicableForPosterType && isApplicableForPeopleType;
    })
    .filter(([id, details]) => {
      if (details.allowMultipleWidgets) return true;
      return !widgets.find((widget) => widget.name === details.name);
    })
    .map(([id, details]) => ({ title: details.title, name: details.name, type: details.type, icon: details.icon }));
  return [
    ...systemWidgetToAdd,
    {
      name: 'Text',
      title: 'Text',
      icon: 'text',
      type: POSTER_WIDGET_TYPE.TEXT,
      value: 'New Text',
    },
    { name: 'Rectangle', title: 'Rectangle', icon: 'rectangle', type: POSTER_WIDGET_TYPE.BOX },
    { name: 'Circle', title: 'Circle', icon: 'circle', type: POSTER_WIDGET_TYPE.CIRCLE },
    { name: 'Image', title: 'Image', icon: 'image', type: POSTER_WIDGET_TYPE.IMAGE },
  ];
}
