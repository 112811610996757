import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import {
  PageWithLayout,
  PageWithLayoutMainContent,
  ToastContainer,
  Row,
  LogoText,
  Space,
  LoadingDots,
  FadeIn,
  SimpleImage,
  PreventMarginCollapse,
} from '@premagic/myne';

import { ALBUM_CREATOR_URLS, AlbumCreatorContainer } from '@premagic/album-creator';

import ToastStore from '../../reducers/ToastStore';
import ProjectPagesRoutes from '../../routes/ProjectPagesRoutes';
import SettingsRoutes from '../../routes/SettingsRoutes';
import CustomerClientsPageContainer from '../clients/CustomerClientsPageContainer';
import DashboardPageContainer from '../dashboard/DashboardPageContainer';
import CustomerLeadsPageContainer from '../leads/CustomerLeadsPageContainer';
import ReportInsightPageContainer from '../reports/insight/ReportInsightPageContainer';
import AppLayoutMainNavbarContainer from './AppLayoutMainNavbarContainer';
import ClientWebsitesRoutes from '../../routes/ClientWebsitesRoutes';
import CRMRoutes from '../crm/CRMRoutes';
import AddNewUserPanelContainer from '../crm/users/create/AddNewUserPanelContainer';
import UserProfileRoutes from '../user-profile/UserProfileRoutes';
import { PERMISSIONS } from '../crm/users/UserPermissionService';
import AccountFinancialsRoutes from '../account-financials/AccountFinancialsRoutes';
import AppAlertsContainer from '../app-alerts/AppAlertsContainer';
import MegaUploaderContainer from '../uploader/MegaUploaderContainer';
import CRMTourContainer from '../../tour/CRMTourContainer';
import AddNewProposalVariablePanelContainer from '../proposal-management/proposal-variables/AddNewProposalVariablePanelContainer';
import APP_URLS from '../../services/AppRouteURLService';
import ProposalCreatorLayoutContainer from '../proposal-management/creator/ProposalCreatorLayoutContainer';
import CRMDashboardRoutes from '../crm/crm-dashboard/CRMDashboardRoutes';
import ClientWebsitePrintQRCodePageContainer from '../client-website/print-qr-code/ClientWebsitePrintQRCodePageContainer';
import StoreRoutes from '../store/StoreRoutes';
import AccountHelpPanelContainer from '../help/AccountHelpPanelContainer';
import EditProposalVariablePanelContainer from '../proposal-management/proposal-variables/EditProposalVariablePanelContainer';
import ImageCompressMessageBoxContainer from '../uploader/ImageCompressMessageBoxContainer';
import FeedsPageContainer from '../feed/FeedsPageContainer';
import WelcomePageContainer from '../welcome-setup/WelcomePageContainer';
import DAMLibraryPagesRoutes from '../../routes/DAMLibraryPagesRoutes';
import SubscriptionStatusPageContainer from '../settings/subscriptions/subscriptions-status/SubscriptionStatusPageContainer';
import EventAttendeeBadgeMainLayoutContainer from '../crm/events/event-attendees/event-attendee-badges/EventAttendeeBadgeMainLayoutContainer';
import PosterCreatorMainLayoutContainer from '../event-poster-creator/EventPosterCreatorMainLayoutContainer';
import EventPosterPreviewPageContainer from '../event-poster-creator/preview/EventPosterPreviewPageContainer';
import PhotoSliderWidgetGetCodeLayoutContainer from '../widgets/photo-slider-widget/photo-slider-widget-get-code-layout/PhotoSliderWidgetGetCodeLayoutContainer';

export function AppLoading(props: { activeAccountLogo?: string }) {
  const { activeAccountLogo } = props;
  return (
    <Row fullHeight center columnDirection>
      <LogoText />
      <Space vertical />
      <LoadingDots size="sm" />
      <Space vertical size={8} />
      <PreventMarginCollapse>
        {activeAccountLogo ? (
          <FadeIn>
            <SimpleImage
              src={activeAccountLogo}
              alt="Logo"
              style={{ height: '64px', width: '180px', objectFit: 'contain' }}
            />
          </FadeIn>
        ) : (
          <Space vertical size={16} />
        )}
      </PreventMarginCollapse>
    </Row>
  );
}

type Props = {
  initData: () => void;
  history: any;
  isLoading: boolean;
  hasPermissions: Partial<Record<PERMISSIONS, boolean>>;
  isMobileUp: boolean;
  activeAccountLogo?: string;
  isFeatureLeadEnabled: boolean;
  isCompanyTypeNotPhotographer: boolean;
};

export default function AppLayoutPage(props: Props): JSX.Element {
  const {
    initData,
    history,
    isLoading,
    hasPermissions,
    isMobileUp,
    activeAccountLogo,
    isFeatureLeadEnabled,
    isCompanyTypeNotPhotographer,
  } = props;
  useEffect(() => {
    initData();
  }, [initData]);

  if (isLoading) return <AppLoading activeAccountLogo={activeAccountLogo} />;
  return (
    <div>
      <Router history={history}>
        <Switch>
          <Route path={APP_URLS.WELCOME.ROOT} component={WelcomePageContainer} />
          <Route path={APP_URLS.ACCOUNT.SUBSCRIPTIONS} component={SubscriptionStatusPageContainer} />
          <Route path={ALBUM_CREATOR_URLS.ALBUM.VIEW} component={AlbumCreatorContainer} />
          <Route path={APP_URLS.PROPOSALS_TEMPLATES.DETAILS} component={ProposalCreatorLayoutContainer} />
          <Route path={APP_URLS.PROPOSALS.DETAILS} component={ProposalCreatorLayoutContainer} />
          <Route path={APP_URLS.CLIENT_WEBSITE.PRINT_INVITE} component={ClientWebsitePrintQRCodePageContainer} />
          <Route path={APP_URLS.EVENT_POSTER.EDIT} component={PosterCreatorMainLayoutContainer} />
          <Route path={APP_URLS.EVENT_POSTER.PREVIEW} component={EventPosterPreviewPageContainer} />
          <Route path={APP_URLS.CRM.EVENT__ATTENDEE_BADGES} component={EventAttendeeBadgeMainLayoutContainer} />
          <Route path={APP_URLS.WIDGET.PHOTO_GALLERY} component={PhotoSliderWidgetGetCodeLayoutContainer} />

          <Route>
            <PageWithLayout>
              <AppLayoutMainNavbarContainer />
              <AppAlertsContainer />
              <PageWithLayoutMainContent hasSidebarOnRight={false} hasMaxWidth>
                <Switch>
                  {isCompanyTypeNotPhotographer ? (
                    <Route exact path="/">
                      <Redirect from="/" to={APP_URLS.CRM.INDEX} />
                    </Route>
                  ) : (
                    <Route path="/home" exact component={DashboardPageContainer} />
                  )}

                  {hasPermissions[PERMISSIONS.REPORTS] && (
                    <Route path="/reports/insight" component={ReportInsightPageContainer} />
                  )}

                  {hasPermissions[PERMISSIONS.LEAD_MANAGEMENT] && (
                    <Route path="/leads/:status" component={CustomerLeadsPageContainer} />
                  )}
                  <Route path="/clients/" component={CustomerClientsPageContainer} />
                  <Route path={APP_URLS.FEED.LIST} component={FeedsPageContainer} />

                  <Route exact path="/">
                    <Redirect from="/" to="/home" />
                  </Route>
                </Switch>
                {hasPermissions[PERMISSIONS.EVENT_MANAGEMENT] && <ProjectPagesRoutes history={history} />}
                {hasPermissions[PERMISSIONS.SETTINGS] && <SettingsRoutes history={history} />}
                {hasPermissions[PERMISSIONS.FINANCIALS] && <AccountFinancialsRoutes history={history} />}
                <UserProfileRoutes history={history} />
                <CRMRoutes history={history} isFeatureLeadEnabled={isFeatureLeadEnabled} />
                <CRMDashboardRoutes history={history} />
                <StoreRoutes history={history} />
                {hasPermissions[PERMISSIONS.EVENT_MANAGEMENT] && <ClientWebsitesRoutes history={history} />}
                {hasPermissions[PERMISSIONS.DAM_LIBRARY] && <DAMLibraryPagesRoutes history={history} />}
                <Space vertical size={12} />
              </PageWithLayoutMainContent>
            </PageWithLayout>
          </Route>
        </Switch>
      </Router>
      <ToastContainer store={ToastStore} />
      <MegaUploaderContainer />
      <ImageCompressMessageBoxContainer />
      <AddNewUserPanelContainer />
      <AddNewProposalVariablePanelContainer />
      <EditProposalVariablePanelContainer />
      <AccountHelpPanelContainer />
      <CRMTourContainer history={history} />
    </div>
  );
}
