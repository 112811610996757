import React, { useState } from 'react';
import { get } from 'lodash';
import { Droppable } from 'react-beautiful-dnd';
import { FileService } from '@premagic/core';

import {
  BUTTON_ICON_STYLES,
  ButtonIcon,
  COLOR_SHADES,
  Icon,
  IMAGE_V2_STYLES,
  ImageV2,
  Position,
  Row,
  Text,
  TEXT_BOLDNESS,
  Space,
} from '@premagic/myne';
import { AlbumPageType, getImagePositionName, NewAlbumPageType } from '../services/AlbumServices';

type Props = {
  albumId: string;
  imageDetails?: {
    image?: FileService.FolderFileType;
    imageId: string;
  } | null;
  pageData: NewAlbumPageType | AlbumPageType;
  removeImage: (options: { albumId: string; pageData: NewAlbumPageType | AlbumPageType; removePositions }) => void;
  position: number;
  readOnly?: boolean;
};

function getImageForPosition(images, pageInfo = {}, position) {
  const imagePositionName = getImagePositionName(position);
  const getImageId = get(pageInfo, `${imagePositionName}.imageId`, 0);
  return images[getImageId];
}

export default function AlbumCreatorImage(props: Props): JSX.Element | null {
  const { albumId, imageDetails, pageData, position, removeImage, readOnly } = props;
  const { image } = imageDetails || {};
  const [activeHover, setHoverState] = useState(false);
  if (readOnly && !image) return null;
  if (readOnly && image) {
    return <ImageV2 src={image.dynamic_image_url} alt={image.image_name} style={IMAGE_V2_STYLES.COVER} />;
  }

  return (
    <Droppable droppableId={String(position)}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
          style={{
            position: 'relative',
            borderWidth: '4px',
            borderStyle: 'solid',
            background: snapshot.isDraggingOver ? '#047af0' : '#f4f5f6',
            borderColor: (() => {
              if (snapshot.isDraggingOver) return '#047af0';
              return activeHover ? '#e9ebed' : '#fff';
            })(),
            height: '100%',
          }}
        >
          {provided.placeholder}
          {image && activeHover && (
            <Position align="top-right">
              <ButtonIcon
                style={BUTTON_ICON_STYLES.SECONDARY}
                title="Remove"
                onClick={() =>
                  removeImage({
                    albumId,
                    pageData,
                    removePositions: position,
                  })
                }
              >
                <Icon name="x" />
              </ButtonIcon>
            </Position>
          )}
          {image ? (
            <ImageV2 src={image.dynamic_image_url} alt={image.image_name} style={IMAGE_V2_STYLES.COVER} />
          ) : (
            <Row center fullHeight>
              <Space>
                {snapshot.isDraggingOver ? (
                  <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.WHITE}>
                    Drop here{' '}
                  </Text>
                ) : (
                  <Text muted center>
                    Drop a photo from the photos tray
                  </Text>
                )}
              </Space>
            </Row>
          )}
        </div>
      )}
    </Droppable>
  );
}
