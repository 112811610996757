import React, { useState } from 'react';
import ClassNames from 'classnames';
import { Dropdown as DropdownLib, useDropdownMenu, useDropdownToggle } from 'react-overlays';
import { BrowserUtils } from '@premagic/utils';

import styles from './dropdown.module.css';
import { Button, BUTTON_STYLES, ButtonIcon, ButtonIconProps, ButtonProps } from '../Button/Buttons';
import { Icon, ICON_SIZES, IconNames } from '../Icon/Icons';
import { Backdrop } from '../Modal/Backdrop';
import { Space } from '../Grid/Grid';
import { NavItem, NavItemProps } from '../Navs';

export enum MENU_ITEM_STYLES {
  DEFAULT = 'default',
  DANGER = 'danger',
}

export interface MenuItemProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  disabledMessage?: string;
  style?: MENU_ITEM_STYLES;
  closeOnClick?: boolean;
  link?: string;
  isActive?: boolean;
}

export function MenuItem(props: MenuItemProps): JSX.Element {
  const {
    children,
    onClick,
    disabled,
    disabledMessage,
    style = MENU_ITEM_STYLES.DEFAULT,
    closeOnClick = true,
    link,
    isActive,
  } = props;
  const [, { toggle }] = useDropdownMenu();
  function handleOnClick(e) {
    if (closeOnClick && toggle) toggle(false); // We are not hiding the menu when closeOnClick false, This is used in shared components inside the dropdown
    if (onClick) onClick();
  }

  return (
    <li className={ClassNames(styles.menu__item__wrapper)}>
      <Button
        className={ClassNames(styles.menu__item, styles[`menu__item--style-${style}`], {
          [styles['menu__item--active']]: isActive,
        })}
        disabled={disabled}
        disabledMessage={disabledMessage}
        style={BUTTON_STYLES.RESET}
        onClick={handleOnClick}
        link={link}
      >
        {children}
      </Button>
    </li>
  );
}

export interface MenuItemIconProps {
  name: IconNames | string; // keyof SVGIcons;
}

export function MenuItemIcon(props: MenuItemIconProps): JSX.Element {
  const { name } = props;

  return (
    <>
      <Icon className={ClassNames(styles.menu__item__icon)} name={name} size={ICON_SIZES.SM} />
      <Space size={1} />
    </>
  );
}

export interface MenuProps {
  children: React.ReactNode;
}

export function Menu(props: MenuProps): React.ReactElement | null {
  const { children } = props;
  const [dropdownLibProps, { show }] = useDropdownMenu({
    flip: true,
  });
  if (!show) return null;
  return (
    <>
      <ul role="menu" className={ClassNames(styles.menu)} {...dropdownLibProps}>
        {children}
      </ul>
      {show && BrowserUtils.isMobileBrowser() && <Backdrop />}
    </>
  );
}

interface DropdownButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export function DropdownButton(props: DropdownButtonProps): JSX.Element {
  const { style, children, ...elementProps } = props;
  const [dropdownLibProps, { show, toggle }] = useDropdownToggle();

  return (
    <Button style={style} {...elementProps} {...dropdownLibProps} onClick={() => toggle(!show)} active={show}>
      {children} <Icon name={show ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
    </Button>
  );
}

interface DropdownIconProps extends ButtonIconProps {
  children: React.ReactNode;
  fromTheme?: boolean;
}

export function DropdownIcon(props: DropdownIconProps): JSX.Element {
  const { style, children, fromTheme, ...elementProps } = props;
  const [dropdownLibProps, { show, toggle }] = useDropdownToggle();
  // NOTE: Dropdown will only work if the button has a forward ref handled
  return (
    <ButtonIcon
      className={ClassNames(styles['dropdown__btn-icon'])}
      active={show}
      style={style}
      {...elementProps}
      {...dropdownLibProps}
      onClick={() => toggle(!show)}
      fromTheme={fromTheme}
    >
      {children}
    </ButtonIcon>
  );
}

interface DropdownNavItemProps extends NavItemProps {
  children: React.ReactNode;
  fromTheme?: boolean;
}

export function DropdownNavItem(props: DropdownNavItemProps): JSX.Element {
  const { children, fromTheme, ...elementProps } = props;
  const [dropdownLibProps, { show, toggle }] = useDropdownToggle();

  return (
    <NavItem
      isActive={show}
      {...elementProps}
      {...dropdownLibProps}
      onClick={() => toggle(!show)}
      fromTheme={fromTheme}
    >
      {children}
    </NavItem>
  );
}

export interface DropdownProps {
  children: React.ReactNode;
  defaultShow?: boolean;
  dropDirection?: 'up' | 'down' | 'left' | 'right';
}

export function Dropdown(props: DropdownProps) {
  const { children, defaultShow = false, dropDirection = 'down' } = props;
  const [show, setShow] = useState(defaultShow);

  return (
    <DropdownLib
      show={show}
      defaultShow={defaultShow}
      drop={dropDirection}
      onToggle={(value) => setShow(value)}
      itemSelector="button:not(:disabled)"
      alignEnd
      focusFirstItemOnShow={false}
    >
      <div className={styles.dropdown__wrapper}>{children}</div>
    </DropdownLib>
  );
}
