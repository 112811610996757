import { EventTrackerService } from '@premagic/core';
import {
  BUTTON_STYLES,
  Button,
  COLOR_SHADES,
  Color,
  ICON_SIZES,
  Icon,
  InfoBoxModal,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { BrowserUtils, CanvasUtils } from '@premagic/utils';
import { useState } from 'react';
import { PRINT_BADGE_CLASS } from '../../crm/events/event-attendees/event-attendee-badges/service/EventAttendeeBadgeDesignService';
import UsersWithoutPhotoAlertModalContainer from '../../crm/events/event-attendees/event-attendee-badges/modal/UsersWithoutPhotoAlertModalContainer';

type DownloadButtonProps = {
  name: string;
  setShowDownloadInfoModal: (val: boolean) => void;
  showAlertModal: () => void;
  eventAttendeesWithoutPhoto?: Array<string>;
  isLoading?: boolean;
};

function DownloadButton(props: DownloadButtonProps) {
  const { name, setShowDownloadInfoModal, showAlertModal, eventAttendeesWithoutPhoto, isLoading } = props;

  function onDownload() {
    const elements = BrowserUtils.getElements(`.${PRINT_BADGE_CLASS}`);
    const scale = 2;
    if (elements) {
      CanvasUtils.downloadElementsAsPdf(elements, `${name} badges`, scale, false, () => {
        EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.EVENT_ATTENDEES.DOWNLOAD_BADGES);
        setShowDownloadInfoModal(false);
      });
    }
  }

  function handleDownload() {
    if (eventAttendeesWithoutPhoto && eventAttendeesWithoutPhoto.length > 0) {
      showAlertModal();
    }
    setShowDownloadInfoModal(true);
    setTimeout(() => {
      onDownload();
    }, 100);
  }

  return (
    <Button style={BUTTON_STYLES.PRIMARY} onClick={handleDownload} isLoading={isLoading}>
      Download
    </Button>
  );
}
type Props = {
  projectId: string;
  posterId: string;
  name: string;
  showAlertModal: () => void;
  eventAttendeesWithoutPhoto?: Array<string>;
};

export default function EventAttendeeBadgeDownloadAll(props: Props) {
  const { name, showAlertModal, eventAttendeesWithoutPhoto, projectId, posterId } = props;

  const [showDownloadInfoModal, setShowDownloadInfoModal] = useState(false);

  return (
    <div>
      <DownloadButton
        name={name}
        setShowDownloadInfoModal={setShowDownloadInfoModal}
        showAlertModal={showAlertModal}
        eventAttendeesWithoutPhoto={eventAttendeesWithoutPhoto}
        isLoading={showDownloadInfoModal}
      />

      {showDownloadInfoModal && (
        <InfoBoxModal>
          <Space size={4}>
            <Row vcenter>
              <Color inline shade={COLOR_SHADES.SUCCESS}>
                <Icon name="download" size={ICON_SIZES.MD} />
              </Color>
              <Space size={1} />
              <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.SUCCESS} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                Downloading...
              </Text>
            </Row>
          </Space>
        </InfoBoxModal>
      )}
      <UsersWithoutPhotoAlertModalContainer projectId={projectId} posterId={posterId} />
    </div>
  );
}
