import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';
import { CalendarEvent } from '@premagic/myne';
import { OccasionsCalendarItemWrapper } from './OccasionsCalendarItemWrapper';
import { navigateTo } from '../../../../../../services/RouterService';
import APP_URLS from '../../../../services/AppRouteURLService';
import { calendarOccasionItemsSelector } from './OccasionsCalendarDataDuck';

function mapStateToProps(state, ownProps: { event: CalendarEvent }) {
  const { event: occasionInfo } = ownProps;
  const { id } = occasionInfo;
  const occasion = calendarOccasionItemsSelector(state)[id];
  return {
    id,
    eventId: occasion.event.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateToEventDetails: (eventId, id) => {
      const urlForDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__OCCASIONS, {
        eventId,
        focusId: id,
      });
      navigateTo(dispatch, urlForDetails);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OccasionsCalendarItemWrapper);
