import React, { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  COLOR_SHADES,
  Form,
  FormErrorType,
  FormFooter,
  FormGroup,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageHeader,
  Input,
  INPUT_SIZES,
  INPUT_TYPES,
  Loading,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
} from '@premagic/myne';

import { ClientSettingsService, FeatureToggleService, PricingPlanFeaturesService } from '@premagic/core';
import APP_URLS from '../../../services/AppRouteURLService';
import FeatureInfo from '../../help/feature-details/FeatureInfo';
import PremiumFeatureIcon from '../PremiumFeatureIcon';

function SelectionAlbumSettings(props: {
  clientSettings: ClientSettingsService.ClientSettingsType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET]: boolean;
  };
}) {
  const { clientSettings, hasFeatures } = props;
  const [enableSelectionFavorite, toggleFavoriteOnSelection] = useState(clientSettings.enable_selection_favorite);
  const hasFeatureSelectionFavorite =
    hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE];
  const hasFeatureBulkDownloadGallery =
    hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION];
  const [enableSelectionDownload, toggleDownloadOnSelection] = useState(clientSettings.enable_selection_download);
  const hasSelectionFavoriteFeatureEnabled = FeatureToggleService.isToggleableFeatureEnabledForClient(
    FeatureToggleService.FEATURES_TOGGLES.SELECTION_FAVORITE,
    true,
  );
  const [enableUserToSelectMorethanLimit, toggleEnableUserToSelectMorethanLimit] = useState(
    'enable_selection_more_than_limit' in clientSettings
      ? clientSettings?.enable_selection_more_than_limit || false
      : true,
  );

  const hasFeatureSelectionBudget = hasFeatures[PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET];
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
        Download Settings
      </Text>
      <Row vcenter>
        <Text>Allow your client to download gallery</Text>
        <Space size={8} />
        {!hasFeatureBulkDownloadGallery && (
          <>
            <PremiumFeatureIcon module="Gallery download Selection" />
            <Space size={4} />
          </>
        )}
        <ToggleSwitch
          name="enable_selection_download"
          checked={enableSelectionDownload}
          onChange={toggleDownloadOnSelection}
          disabled={!hasFeatureBulkDownloadGallery}
        />
        <Space size={2} />
        {enableSelectionDownload ? (
          <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
            Enabled
          </Text>
        ) : (
          <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
            Disabled
          </Text>
        )}
      </Row>
      <Space vertical size={8} />
      {hasSelectionFavoriteFeatureEnabled && (
        <>
          <Row vcenter>
            <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
              Mark as important settings
            </Text>
            <Space size={1} />
            <FeatureInfo feature={PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE} />
          </Row>
          <Row>
            <Text>Allow your client to mark photo as important on</Text>
            <Space />
            <Row vcenter>
              {!hasFeatureSelectionFavorite && (
                <>
                  <PremiumFeatureIcon module="Allow Mark as important in Selection" />
                  <Space size={4} />
                </>
              )}
              <ToggleSwitch
                name="enable_selection_favorite"
                checked={enableSelectionFavorite && hasFeatureSelectionFavorite}
                onChange={toggleFavoriteOnSelection}
                disabled={!hasFeatureSelectionFavorite}
              />
              <Space size={2} />
              {enableSelectionFavorite ? (
                <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
                  Enabled
                </Text>
              ) : (
                <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
                  Disabled
                </Text>
              )}
              <Space size={2} />
            </Row>
          </Row>
        </>
      )}
      <Space vertical size={8} />

      <Row>
        <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          Selection Limit
        </Text>
        <Space size={1} />
        <FeatureInfo feature={PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET} />
        {!hasFeatureSelectionBudget && (
          <>
            <PremiumFeatureIcon module="Suggest number of photos to select" />
            <Space size={4} />
          </>
        )}
      </Row>
      <Text>Suggest number of photos to be selected</Text>
      <Space vertical size={2} />
      {hasFeatureSelectionBudget && (
        <>
          <FormGroup>
            <Row vcenter>
              <Space />
              <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
                Default number of photos suggestion
              </Text>
              <Space size={4} />
              <Input
                type={INPUT_TYPES.NUMBER}
                name={ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DEFAULT_SELECTION_BUDGET}
                size={INPUT_SIZES.SM}
                defaultValue={clientSettings[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DEFAULT_SELECTION_BUDGET]}
              />
            </Row>
          </FormGroup>
          <Row>
            <Space />
            <Text>Allow selection more than limit</Text>
            <Space />
            <Row vcenter>
              <ToggleSwitch
                name="enable_selection_more_than_limit"
                checked={enableUserToSelectMorethanLimit}
                onChange={toggleEnableUserToSelectMorethanLimit}
              />
              <Space size={2} />
              {enableUserToSelectMorethanLimit ? (
                <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
                  Enabled
                </Text>
              ) : (
                <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
                  Disabled
                </Text>
              )}
              <Space size={2} />
            </Row>
          </Row>
        </>
      )}
    </div>
  );
}

type FormProps = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET]: boolean;
  };
};

export function SelectionFolderPreferenceForm(props: FormProps): JSX.Element {
  const { clientSettings, savePreferences, isSaving, errors, hasFeatures } = props;

  const defaultEnableSelectionAlbum =
    'enable_selection_album' in clientSettings ? (clientSettings.enable_selection_album as boolean) : true;
  const [enableSelectionAlbum, toggleSelectionAlbum] = useState<boolean>(defaultEnableSelectionAlbum);

  return (
    <Form
      onSubmit={savePreferences}
      errors={errors}
      formSpec={{
        enable_selection_download: {
          type: 'boolean',
        },
        require_pin_to_download: {
          type: 'boolean',
        },
        enable_selection_favorite: {
          type: 'boolean',
        },
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_SELECTION_ALBUM]: {
          type: 'boolean',
        },
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DEFAULT_SELECTION_BUDGET]: {
          type: 'number',
        },
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_SELECTION_MORE_THAN_LIMIT]: {
          type: 'boolean',
        },
      }}
    >
      <Row vcenter>
        <Text>Enable Creating of Selection Album</Text>
        <Space size={8} />
        <ToggleSwitch name="enable_selection_album" checked={enableSelectionAlbum} onChange={toggleSelectionAlbum} />
        <Space size={2} />
        {enableSelectionAlbum ? (
          <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_5}>
            Enabled
          </Text>
        ) : (
          <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
            Disabled
          </Text>
        )}
      </Row>
      {enableSelectionAlbum && (
        <div>
          <Space vertical size={8} />
          <SelectionAlbumSettings clientSettings={clientSettings} hasFeatures={hasFeatures} />
        </div>
      )}
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} link="/settings">
          Cancel
        </Button>
        <Button style={BUTTON_STYLES.PRIMARY} isLoading={isSaving}>
          Save
        </Button>
      </FormFooter>
    </Form>
  );
}

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  isSaving: boolean;
  savePreferences: (data: Record<string, any>) => void;
  errors?: FormErrorType;
  hasFeatures: {
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_PHOTO_IMPORTANCE]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.BULK_PHOTOS_DOWNLOADS_SELECTION]: boolean;
    [PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SELECTION_BUDGET]: boolean;
  };
};

export default function SignatureAndSelectionFolderPreferencePage(props: Props) {
  const { clientSettings, savePreferences, isSaving, errors, isLoading, hasFeatures } = props;
  if (isLoading) return <Loading />;

  return (
    <InnerPage>
      <InnerPageHeader iconName="folder_simple" title="Selection folder" backTo={APP_URLS.SETTINGS.INDEX}>
        Selection album
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <Space>
              <SelectionFolderPreferenceForm
                clientSettings={clientSettings}
                isSaving={isSaving}
                savePreferences={savePreferences}
                errors={errors}
                hasFeatures={hasFeatures}
              />
            </Space>
          </Card>
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
