import { useEffect } from 'react';

import { EventsService, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  Form,
  FormFooter,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import MESSAGES from '../../../../../common/Messages';
import APP_URLS, { getRouteUrlFor } from '../../../services/AppRouteURLService';
import CRMEventDataForm from '../../crm/events/form/CRMEventDataForm';
import ClientWebsiteDesignPresetsContainer from '../details/ClientWebsiteDesignPresetsContainer';

type Props = {
  id: string;
  projectId: string;
  isLoading: boolean;
  errors?: Record<string, string>;
  showPanel: boolean;
  closePanel: () => void;
  isFeatureLeadEnabled: boolean;
  saveProjectMeta: (projectId: string, formResponse: FormResponseType) => void;
  eventData?: EventsService.EventDataType;
  isCompanyTypeNotPhotographer: boolean;
};

export default function QuickEditClientWebsitePanel(props: Props): JSX.Element | null {
  const {
    id,
    projectId,
    isLoading,
    errors,
    showPanel,
    closePanel,
    isFeatureLeadEnabled,
    saveProjectMeta,
    eventData,
    isCompanyTypeNotPhotographer,
  } = props;

  useEffect(() => closePanel, []);

  if (!showPanel || !id) return null;
  const inviteDetailsUrl = getRouteUrlFor(APP_URLS.CLIENT_WEBSITE.DETAILS___DETAILS, { id, projectId });

  function handleSave(formResponse) {
    saveProjectMeta(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.EVENT_DATA]: formResponse.data.event,
      },
    });
    closePanel();
  }

  return (
    <WindowPanel show={showPanel} size={WINDOW_PANEL_SIZES.LG}>
      <WindowPanelHeader onClose={closePanel}>
        <Row vcenter>
          <WindowPanelTitle>Edit {MESSAGES.CLIENT_WEBSITE.NAME}</WindowPanelTitle>
          <Space />
          <Button style={BUTTON_STYLES.LINK} link={inviteDetailsUrl}>
            <Icon name="settings" size={ICON_SIZES.SM} />
            <Space size={1} />
            Advance edit
          </Button>
        </Row>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form onSubmit={handleSave} errors={errors}>
          <CRMEventDataForm
            isFeatureLeadEnabled={isFeatureLeadEnabled}
            eventData={eventData}
            expandAdditionalInfoByDefault
            showGoogleMapLinkFormItem
            showIcon
            isCompanyTypeNotPhotographer={isCompanyTypeNotPhotographer}
            hideEventStatus
          />
          <Space vertical />
          <Row>
            <Color shade={COLOR_SHADES.LIGHT} inline>
              <Icon name="feather" />
            </Color>
            <Space />
            <Col size={8}>
              <ClientWebsiteDesignPresetsContainer id={id} projectId={projectId} isInsideCard={false} />
            </Col>
          </Row>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
