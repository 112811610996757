import * as React from 'react';
import ClassNames from 'classnames';
import { COLOR_SHADES } from '../Color/Color';
import { Link } from '../Link/Link';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';

import styles from './tabs.module.css';
import { Button, BUTTON_STYLES } from '../Button/Buttons';
import { Container, ScrollableContainer } from '../Page';

interface TabsProps {
  children: React.ReactNode;
}

export function Tabs(props: TabsProps): JSX.Element {
  const { children } = props;
  const classes = ClassNames(styles.tabs);

  return <div className={classes}>{children}</div>;
}

interface TabItemProps {
  children: React.ReactNode;
  active?: boolean;
  to?: string;
  className?: string;
  onClick?: () => void;
}

export function TabItem(props: TabItemProps): JSX.Element {
  const { children, active, to, className, onClick, ...elementProps } = props;
  const classes = ClassNames(className, styles['tab-item'], {
    [styles['tab-item--active']]: active,
  });

  if (to) {
    return (
      <Link className={classes} to={to} onClick={onClick} {...elementProps}>
        <Text
          size={TEXT_SIZE.SIZE_3}
          color={active ? COLOR_SHADES.DARK : COLOR_SHADES.LIGHT}
          boldness={active ? TEXT_BOLDNESS.BOLD : TEXT_BOLDNESS.NORMAL}
        >
          {children}
        </Text>
      </Link>
    );
  }
  return (
    <Button className={classes} onClick={onClick} style={BUTTON_STYLES.RESET} {...elementProps}>
      <Text
        size={TEXT_SIZE.SIZE_3}
        color={active ? COLOR_SHADES.DARK : COLOR_SHADES.LIGHT}
        boldness={active ? TEXT_BOLDNESS.BOLD : TEXT_BOLDNESS.NORMAL}
      >
        {children}
      </Text>
    </Button>
  );
}

interface TabsSecondaryProps {
  children: React.ReactNode;
  actions?: React.ReactNode | [React.ReactNode];
  transparentStyle?: boolean;
  scrollTo?: string;
  fromTheme?: boolean;
  hideButton?: boolean;
}

export function TabsSecondary(props: TabsSecondaryProps) {
  const { children, actions, transparentStyle, scrollTo, fromTheme, hideButton } = props;
  const classes = ClassNames(styles['tabs-secondary'], {
    [styles['tabs-secondary--transparent']]: transparentStyle,
    [styles['from-theme']]: fromTheme,
  });

  return (
    <div
      className={ClassNames(styles['tabs-secondary-wrapper'], {
        [styles['tabs-secondary-wrapper--transparent']]: transparentStyle,
        [styles['from-theme']]: fromTheme,
      })}
    >
      <Container className={styles['tabs-secondary-wrapper__container']}>
        <ScrollableContainer
          direction="horizontal"
          showShadow
          hideButton={hideButton}
          hideScrollBar
          scrollTo={scrollTo}
        >
          <div className={classes}>{children}</div>
        </ScrollableContainer>
        {actions && <div className={ClassNames(styles['tab-secondary__actions'])}>{actions}</div>}
      </Container>
    </div>
  );
}

interface TabSecondaryItemProps {
  children: React.ReactNode;
  active?: boolean;
  to?: string;
  onClick?: () => void;
  className?: string;
  fromTheme?: boolean;
}

export function TabSecondaryItem(props: TabSecondaryItemProps) {
  const { children, active, to, onClick, className, fromTheme } = props;
  const classes = ClassNames(styles['tab-secondary__item'], className, {
    [styles['tab-secondary__item--active']]: active,
    [styles['from-theme']]: fromTheme,
  });
  if (onClick) {
    return (
      <Button className={classes} onClick={onClick} style={BUTTON_STYLES.RESET} fromTheme={fromTheme}>
        {children}
      </Button>
    );
  }
  if (to) {
    return (
      <Link className={classes} to={to} isExternalLink={false}>
        {children}
      </Link>
    );
  }
  return null;
}

export enum PILLS_STYLES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface PillsProps {
  children: React.ReactNode;
  style?: PILLS_STYLES;
}

export function Pills(props: PillsProps) {
  const { children, style = PILLS_STYLES.PRIMARY } = props;
  const classes = ClassNames(styles.pills, styles[`pills--style-${style}`]);

  return <div className={classes}>{children}</div>;
}

interface PillItemProps {
  children: React.ReactNode;
  active?: boolean;
  to?: string;
  count?: number;
  onClick?: () => void;
}

export function PillItem(props: PillItemProps) {
  const { children, active = false, to, count = -1, onClick } = props;
  const classes = ClassNames(styles['pill-item'], {
    [styles['pill-item--active']]: active,
  });

  if (!to) {
    return (
      <Button className={classes} onClick={onClick} style={BUTTON_STYLES.RESET}>
        {children}
        {count > -1 && <div className={styles['pill-item__count']}>{count}</div>}
      </Button>
    );
  }

  return (
    <Link className={classes} to={to}>
      {children}
      {count > -1 && <div className={styles['pill-item__count']}>{count}</div>}
    </Link>
  );
}
