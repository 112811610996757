import { connect } from 'react-redux';
import { crmTotalRemainingClientPaymentAmountSelector } from '../CRMPaymentDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import RemainingClientPaymentsInfo from './RemainingClientPaymentsInfo';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';

function mapStateToProps(state) {
  return {
    totalRemainingClientPayment: crmTotalRemainingClientPaymentAmountSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddPanel: () => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_BULK_ADD_CLIENT_PAYMENTS, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemainingClientPaymentsInfo);
