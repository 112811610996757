import { connect } from 'react-redux';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { isMobileUp } from '../AppDuck';
import CustomerLead from './CustomerLead';
import { customerLeadsItemsSelector, saveLeadStatus } from './CustomerLeadsDataDuck';

function mapStateToProps(state, ownProps) {
  const lead = customerLeadsItemsSelector(state)[ownProps.id];

  return {
    isLoading: isLoadingSelector(LOADINGS.LEADS.UPDATE_STATUS)(state),
    lead,
    isMobileUp: isMobileUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (id: string, status) => {
      dispatch(saveLeadStatus(dispatch, id, status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLead);
