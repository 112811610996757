import { CryptoUtils, HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';

export type EventExhibitorType = {
  id: string;
  name: string;
  email: string;
  phone: string;
  logo_asset_id: string;
  booth_number: string;
  created_at: string;
  updated_at: string;
};

export function fetchEventExhibitorsForProject(projectId: string): Promise<Array<EventExhibitorType>> {
  return HttpUtils.get(API_URLS.EVENT_EXHIBITORS.LIST(projectId)).then(({ data }) => data);
}

export function createEventExhibitor(
  projectId: string,
  exhibitor: EventExhibitorType,
): Promise<Record<string, EventExhibitorType>> {
  return HttpUtils.post(API_URLS.EVENT_EXHIBITORS.LIST(projectId), exhibitor).then(({ data }) => data);
}

export function updateEventExhibitor(
  options: {
    projectId: string;
    exhibitorId: string;
  },
  exhibitor: Partial<EventExhibitorType>,
): Promise<Record<string, EventExhibitorType>> {
  const { projectId, exhibitorId } = options;
  return HttpUtils.put(API_URLS.EVENT_EXHIBITORS.DETAILS(projectId, exhibitorId), exhibitor).then(({ data }) => data);
}

export function deleteEventExhibitor(projectId: string, exhibitorId: string): Promise<void> {
  return HttpUtils.httpDelete(API_URLS.EVENT_EXHIBITORS.DETAILS(projectId, exhibitorId)).then(({ data }) => data);
}

export function fetchEventExhibitorsForPublicWebsite(
  domain: string,
  websiteId: string,
): Promise<Array<EventExhibitorType>> {
  return HttpUtils.get(API_URLS.EVENT_EXHIBITORS.LIST_PUBLIC(domain, websiteId)).then(({ data }) => data.data);
}
