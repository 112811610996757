import { StorePricingService } from '@premagic/core';
import { connect } from 'react-redux';
import StorePricingModal from './StorePricingModal';

function mapStateToProps(
  state,
  ownProps: {
    selectedProduct?: StorePricingService.ProductItemType | null;
    onSave: (data) => void;
    onClose: () => void;
    show?: boolean;
    defaultSubProductPrices?: any;
  },
) {
  const { selectedProduct, onSave, onClose, show, defaultSubProductPrices } = ownProps;
  return {
    selectedProduct,
    onSave,
    onClose,
    show,
    defaultSubProductPrices,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePricingModal);
