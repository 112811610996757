import React, { useRef, useState } from 'react';
import { Dialog, DIALOG_PLACEMENT, ErrorBoundary, Icon, Label, Row, Space } from '@premagic/myne';

import { DAM_LIBRARY_FILTER_KEYS, DAMLibraryFiltersType } from './DAMLibraryFilterTypes';
import DAMLibraryItemsDialogContent from './DAMLibraryItemsDialogContent';
import DAMLibraryFiltersItemContentContainer from './DAMLibraryFiltersItemContentContainer';

type CRMFiltersItemProps = {
  filters: DAMLibraryFiltersType;
  filterKey: DAM_LIBRARY_FILTER_KEYS;
  value?: string | Array<string>;
  onRemove: (filterKey: DAM_LIBRARY_FILTER_KEYS) => void;
  onChange: (newFilterValue: string) => void;
};

function DAMLibraryFiltersItem(props: CRMFiltersItemProps): JSX.Element {
  const { filterKey, value, onRemove, onChange, filters } = props;
  const [showDialog, setShowDialog] = useState(false);
  const DialogContentForFilter = DAMLibraryItemsDialogContent[filterKey];

  const target = useRef(null);
  return (
    <span ref={target}>
      <ErrorBoundary>
        <Label onRemove={() => onRemove(filterKey)} onClick={() => setShowDialog(true)}>
          <DAMLibraryFiltersItemContentContainer filterKey={filterKey} value={value} />
        </Label>

        {showDialog && (
          <Dialog
            target={target.current}
            show={showDialog}
            placement={DIALOG_PLACEMENT.AUTO}
            onClose={() => setShowDialog(false)}
          >
            <Space size={2}>
              <DialogContentForFilter
                value={value}
                filters={filters}
                onChange={(newFilterValue) => {
                  onChange(newFilterValue);
                  setShowDialog(false);
                }}
              />
            </Space>
          </Dialog>
        )}
      </ErrorBoundary>
    </span>
  );
}

type Props = {
  filters: DAMLibraryFiltersType;
  navigate: (filters: DAMLibraryFiltersType) => void;
  removeFilter: (filterToRemove: DAM_LIBRARY_FILTER_KEYS, filters: DAMLibraryFiltersType) => void;
  resetDAMLibraryData: () => void;
};

export function DAMLibraryFilters(props: Props): JSX.Element {
  const { filters = {}, navigate, removeFilter, resetDAMLibraryData } = props;

  function navigateWithNewFilter(newFilter: DAMLibraryFiltersType) {
    navigate({
      ...filters,
      ...newFilter,
    });
  }

  function getFiltersToShow() {
    return [
      DAM_LIBRARY_FILTER_KEYS.PROJECT,
      DAM_LIBRARY_FILTER_KEYS.FACE_ID,
      DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE,
      DAM_LIBRARY_FILTER_KEYS.TAGS,
      DAM_LIBRARY_FILTER_KEYS.DATE_FROM,
      DAM_LIBRARY_FILTER_KEYS.DATE_END,
      DAM_LIBRARY_FILTER_KEYS.SORT_BY,
    ];
  }
  const FILTERS_TO_SHOW = getFiltersToShow();

  function handleRemoveFilter(filterKeyToRemove) {
    if (filterKeyToRemove === DAM_LIBRARY_FILTER_KEYS.PROJECT) {
      return resetDAMLibraryData();
    }
    removeFilter(filterKeyToRemove, filters);
  }

  return (
    <Row vcenter wrap>
      <Space size={1} />
      <Icon name="filter" /> <Space />
      {FILTERS_TO_SHOW.map((filterKey) => (
        <Space size={1} key={filterKey}>
          <DAMLibraryFiltersItem
            filterKey={filterKey}
            filters={filters}
            value={filters[filterKey]}
            onRemove={handleRemoveFilter}
            onChange={(newFilter) => navigateWithNewFilter({ [filterKey]: newFilter })}
          />
        </Space>
      ))}
    </Row>
  );
}
