import { Button, BUTTON_SIZE, BUTTON_STYLES, Col, Row, Space } from '@premagic/myne';
import React from 'react';

type ImageEditorBasicButtonsProps = {
  handleSave: () => void;
  handleRemovePhoto?: () => void;
  handleChangePhoto?: () => void;
};

export default function ImageEditorBasicButtons(props: ImageEditorBasicButtonsProps) {
  const { handleRemovePhoto, handleChangePhoto, handleSave } = props;
  return (
    <Space size={2}>
      <Row vcenter>
        {handleRemovePhoto && (
          <Col size={2}>
            <Button onClick={() => handleRemovePhoto()} size={BUTTON_SIZE.MD} style={BUTTON_STYLES.LINK_SECONDARY}>
              Remove photo
            </Button>
          </Col>
        )}
        <Col rightAlighed size={null}>
          <Row spaceBetween>
            {handleChangePhoto && (
              <Button onClick={() => handleChangePhoto()} size={BUTTON_SIZE.MD} style={BUTTON_STYLES.TERTIARY}>
                Change photo
              </Button>
            )}
            <Space size={3} />
            <Button onClick={handleSave} size={BUTTON_SIZE.MD} style={BUTTON_STYLES.PRIMARY}>
              Apply
            </Button>
          </Row>
        </Col>
      </Row>
    </Space>
  );
}
