import FlowFile from '@flowjs/flow.js';
import { getAccountAuthHeaderWithMagicToken, getAuthToken } from './AuthUtils';
import { parseJSON } from './JSONUtils';
import { logAction, logError } from './ErrorTracker';

let flowFileInstance: FlowFile | null = null;

function getFlowFileInstance(): FlowFile | null {
  return flowFileInstance;
}
export function removeFlowFileInstance() {
  flowFileInstance = null;
}

export function generateUniqueIdentifierForFile(file: File): string {
  const relativePath = file.webkitRelativePath || file.name;
  return `${file.size}-${relativePath.replace(/[^0-9a-zA-Z_-]/gim, '')}`;
}

export function getUploaderStats(): {
  timeRemaining: number;
  totalSize: number;
  totalSizeUploaded: number;
  progress: number;
  isUploading: boolean;
} {
  if (!flowFileInstance)
    return {
      timeRemaining: 0,
      totalSize: 0,
      totalSizeUploaded: 0,
      progress: 0,
      isUploading: false,
    };
  return {
    timeRemaining: flowFileInstance.timeRemaining(),
    totalSize: flowFileInstance.getSize(),
    totalSizeUploaded: flowFileInstance.sizeUploaded(),
    progress: flowFileInstance.progress(),
    isUploading: flowFileInstance.isUploading(),
  };
}

type FileQueryOptions = {
  project_id: string;
  folder_id: string;
};
// Creating a map of files and options, so that we dynamically get the options and send to server for each file, This is to maintain a single instance of the uploader
const FILES_AND_QUERY_DETAILS: Array<{
  files: Array<File>;
  options: FileQueryOptions;
}> = [];

function setFilesAndQueryDetails(files: Array<File>, options: FileQueryOptions) {
  FILES_AND_QUERY_DETAILS.push({
    files,
    options,
  });
}

function getQueryDetailForFile(file: File): FileQueryOptions | null {
  const batchWithFile = FILES_AND_QUERY_DETAILS.find((batch) => batch.files.includes(file));
  return batchWithFile ? batchWithFile.options : null;
}

const UPLOAD_CONFIG = {
  maxChunkRetries: 10,
  chunkRetryInterval: 1000 * 5,
  prioritizeFirstAndLastChunk: true,
  simultaneousUploads: 6,
  chunkSize: 10 * 1024 * 1024,
  maxFileSize: 25 * 1024 * 1024,
  maxFiles: 10000,
  withCredentials: true,
  fileType: 'image/x-png, image/jpeg, image/jpg, image/png, image/tiff, video/mp4, video/x-m4v, video/*',
  allowDuplicateUploads: false,
  generateUniqueIdentifier: generateUniqueIdentifierForFile,
};

function getConfig(options: { url: string }) {
  const { url } = options;

  return {
    ...UPLOAD_CONFIG,
    headers: () => getAccountAuthHeaderWithMagicToken(),
    // preprocess: () => getAuthToken(),
    // uploadMethod: () => getAuthToken(),
    target: url,
  };
}

type UploaderOptions = {
  params?: Record<string, string>;
  headers?: Record<string, string>;
  url: string;
};

export type UploaderFileType = {
  id?: string;
  uniqueIdentifier: string;
  name: string;
  fileBlob?: string;
  size: number;
  uploaded: boolean;
  progress: number;
  projectId: string;
  folderId: string;
  projectName: string;
  folderName: string;
  avgSpeed: number;
  errored?: boolean;
  errorMessage?: string;
};

export type CallbackOptionsType = {
  onAdd: (file: FlowFile) => void;
  onSuccess: (
    file: FlowFile,
    response: {
      asset_type: 'video' | 'photo';
      object_id: string;
    },
  ) => void;
  onError: (file: FlowFile, response: string) => void;
  onProgress: (file: FlowFile, relative: number, avgSpeed: number) => void;
};

export function initializeUploader(options: UploaderOptions, callbacks: CallbackOptionsType): FlowFile | null {
  if (flowFileInstance) return flowFileInstance;
  const { params = {}, url } = options;

  const { onAdd, onError, onSuccess, onProgress } = callbacks;
  flowFileInstance = new FlowFile({
    ...getConfig({
      url,
    }),
    query: (flowFile: FlowFile) => {
      const fileQueryOptions = getQueryDetailForFile(flowFile.file);
      return { ...params, ...fileQueryOptions };
    },
  });

  // maxFilesErrorCallback
  // maxFileSizeErrorCallback
  // fileTypeErrorCallback

  flowFileInstance.on('fileProgress', (flowFile) =>
    onProgress(flowFile, flowFile.progress(false), flowFile.averageSpeed),
  );
  flowFileInstance.on('fileAdded', (resumableFile) => onAdd(resumableFile));
  flowFileInstance.on('fileSuccess', (resumableFile, message) =>
    onSuccess(resumableFile, parseJSON(message) as { asset_type: 'video' | 'photo'; object_id: string }),
  );
  flowFileInstance.on('fileRetry', (resumableFile, chunk) =>
    logAction('MEGA_UPLOADER: RETRY', {
      fileName: resumableFile.fileName,
      chunk,
    }),
  );
  flowFileInstance.on('fileError', (resumableFile, message) => {
    logError('MEGA_UPLOADER: uploading error', message);
    onError(resumableFile, message);
  });

  return flowFileInstance;
}

function startUploading(): void {
  const uploaderInstance = getFlowFileInstance();
  uploaderInstance?.upload();
}

export function addFilesToUploader(files: Array<File>, options?: FileQueryOptions): void {
  if (options) {
    setFilesAndQueryDetails(files, options);
  }
  getFlowFileInstance()?.addFiles(files);

  setTimeout(() => {
    // Adding a timeout as upload was not getting tried after first upload on a file
    startUploading();
  }, 300);
}
