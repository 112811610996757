import { Col, Divider, FormLabel, Row, Space, Text } from '@premagic/myne';
import { DAMLibraryFiltersType } from '../DAMLibraryFilterTypes';
import DAMLibraryPeopleFilterByFileUploadSelectorContainer from './DAMLibraryPeopleFilterByFileUploadSelectorContainer';
import DAMLibraryPeopleFilterByNameSelectorContainer from './DAMLibraryPeopleFilterByNameSelectorContainer';

function FilterDividerUI() {
  return (
    <Row vcenter>
      <Col size={5}>
        <Divider />
      </Col>
      <Col size={2}>
        <Row center>
          <Text block>OR</Text>
        </Row>
      </Col>
      <Col size={5}>
        <Divider />
      </Col>
    </Row>
  );
}

type Props = {
  value: Array<string>;
  filters: DAMLibraryFiltersType;
  onChange: (newFilterValue: Array<string>) => void;
  projectId?: string;
};

export default function DAMLibraryPeopleFilterSelectorWrapper(props: Props) {
  const { value, filters, onChange, projectId } = props;

  if (!projectId)
    return (
      <div>
        <FormLabel>People</FormLabel>
        <Text>Select a event first</Text>
      </div>
    );

  return (
    <div>
      <FormLabel>People</FormLabel>
      <DAMLibraryPeopleFilterByNameSelectorContainer value={value} projectId={projectId} onChange={onChange} />
      <Space vertical size={6} />
      <FilterDividerUI />
      <Space vertical size={6} />
      <DAMLibraryPeopleFilterByFileUploadSelectorContainer value={value} projectId={projectId} onChange={onChange} />
    </div>
  );
}
