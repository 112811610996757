import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import WelcomePage from './WelcomePage';
import { companyDataSelector, photographerNameSelector } from '../settings/company/CompanyDataDuck';
import { companyTypeSelector } from '../settings/preferences/ClientSettingsDataDuck';
import { LOADINGS } from '../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../common/ErrorDuck';
import { saveWelcomeCompanyData } from './WelcomePageDuck';

function mapStateToProps(state, ownProps) {
  const { view, type } = ownProps.match.params;
  const COMMON_KEY = LOADINGS.WELCOME.SAVE;
  return {
    view,
    type,
    name: photographerNameSelector(state),
    companyType: companyTypeSelector(state),
    isSaving: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    company: companyDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCompanyData: (data, company) => {
      dispatch(saveWelcomeCompanyData(dispatch, data, company));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
