import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDataDuck } from '@premagic/proposals';

import ProposalCreatorHeader from './ProposalCreatorHeader';
import { proposalCreatorSelectedSlideSelector } from '../ProposalCreatorDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, folderId, deckId } = ownProps;
  const slideId = proposalCreatorSelectedSlideSelector(state);
  return {
    projectId,
    folderId,
    deckId,
    slideId,
    isSaving: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_SLIDE.UPDATE(slideId))(state),
    proposal: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorHeader);
