import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { ProjectService } from '@premagic/core';
import { ErrorDuck, LoadingDuck } from '@premagic/common-ducks';

import { albumCreatorPageSelector } from '../../../common/selectors';
import { LOADINGS } from '../../../common/Constants';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM_PROJECT');

export const setProjectData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const getProject = createAction(getActionType('DATA', 'GET'), async (dispatch, projectId) => {
  const loadingKey = LOADINGS.PROJECT.FETCH;
  dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, true));
  dispatch(ErrorDuck.clearErrorState(dispatch, loadingKey));
  ProjectService.fetchProject(projectId)
    .then((response) => {
      dispatch(setProjectData(dispatch, response));
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
    })
    .catch((e) => {
      dispatch(LoadingDuck.toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('ALBUM', e);
    });
});

type StateType = {
  item: ProjectService.ProjectType;
};

const initialState = {
  items: null,
};

export default handleActions(
  {
    [setProjectData.toString()]: (state, action) => ({
      ...state,
      item: action.payload,
    }),
  },
  initialState,
);

const albumCreatorProjectSelector = createSelector(
  albumCreatorPageSelector,
  (albumCreator) => albumCreator.data.project as StateType,
);

export const albumCreatorProjectDataSelector = createSelector(albumCreatorProjectSelector, (state) => state.item);

export const eventIdForAlbumSelector = createSelector(albumCreatorProjectDataSelector, (project) => project?.event_id);
