import { ClientWebsiteService, PremagicResources, ProjectMetaService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  DialogTitle,
  FormFooter,
  Icon,
  ICON_SIZES,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  RealCheckbox,
  Row,
  Space,
} from '@premagic/myne';
import React, { useRef, useState } from 'react';
import MESSAGES from '../../../../../common/Messages';
import { PERMISSIONS } from '../../crm/users/UserPermissionService';
import ProjectMetaToggleContainer from '../../projects/highlight-settings/project-meta-toggle/ProjectMetaToggleContainer';
import ClientWebsiteDetailsValueItem from '../details/ClientWebsiteDetailsValueItem';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ContactInfoForNotificationContainer from './contact-info/ContactInfoForNotificationContainer';
import AddEventFormFieldPanelContainer from './create/AddEventFormFieldPanelContainer';
import EditEventFormFieldPanelContainer from './edit/EditEventFormFieldPanelContainer';
import UserRegistrationFormFieldListContainer from './UserRegistrationFormFieldListContainer';

type Props = {
  clientWebsite: ClientWebsiteService.ClientWebsiteType;
  showEditForm: () => void;
  projectId: string;
  addFormField: () => void;
  hasEventFormFieldsFeature: boolean;
  hasPermissions: {
    [PERMISSIONS.EVENT_FORM_FIELD]: boolean;
    [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: boolean;
  };
  hasSystemCustomFormFields: boolean;
  createCompanyFormFields: (projectId: string) => void;
  isAdding: boolean;
  langCode?: string;
};

export default function ClientWebsiteUserRegistrationFormLayout(props: Props): React.ReactElement {
  const {
    clientWebsite,
    showEditForm,
    projectId,
    addFormField,
    hasEventFormFieldsFeature,
    hasPermissions,
    hasSystemCustomFormFields,
    createCompanyFormFields,
    isAdding,
    langCode,
  } = props;

  function getDisabledMessage() {
    if (!hasEventFormFieldsFeature) {
      return MESSAGES.EVENT_CUSTOM_FORM_FIELDS.UPGRADE;
    }
    if (!hasPermissions[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]) {
      return MESSAGES.PERMISSION[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT];
    }
    return MESSAGES.EVENT_CUSTOM_FORM_FIELDS.UPGRADE;
  }
  const settingIcon = useRef(null);
  const [showSettingDialog, setShowSettingDialog] = useState(false);
  return (
    <Card>
      <Space>
        <CardHeader>
          <CardHeaderTitle>Registration Form</CardHeaderTitle>
          <CardHeaderActions>
            <ButtonIcon title="setting" ref={settingIcon} onClick={() => setShowSettingDialog(true)}>
              <Icon name="settings" size={ICON_SIZES.SM} />
            </ButtonIcon>
            <Modal show={showSettingDialog} size={MODAL_SIZES.MD} close={() => setShowSettingDialog(false)}>
              <ModalTitle>Registration Settings</ModalTitle>
              <ContactInfoForNotificationContainer projectId={projectId} />
              <Space vertical />
              <ProjectMetaToggleContainer
                projectId={projectId}
                itemKey={
                  ProjectMetaService.PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_REQUEST_TO_FILL_IN_PERSONAL_DETAILS
                }
              />
              {!hasSystemCustomFormFields && (
                <Button
                  style={BUTTON_STYLES.DEFAULT}
                  isLoading={isAdding}
                  onClick={() => createCompanyFormFields(projectId)}
                >
                  <Icon name="plus" size={ICON_SIZES.SM} /> System Field
                </Button>
              )}

              <FormFooter>
                <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowSettingDialog(false)}>
                  Close
                </Button>
              </FormFooter>
            </Modal>
            <Button
              type="button"
              style={BUTTON_STYLES.PRIMARY}
              onClick={addFormField}
              size={BUTTON_SIZE.SM}
              disabledMessage={getDisabledMessage()}
              disabled={!hasEventFormFieldsFeature || !hasPermissions[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]}
            >
              <Icon name="plus" size={ICON_SIZES.SM} /> Field
            </Button>
          </CardHeaderActions>
        </CardHeader>
        <UserRegistrationFormFieldListContainer projectId={projectId} folderId={clientWebsite.id} />
        <Space vertical size={6} />
        <RealCheckbox name="have_consent_to_use_data" disabled>
          I agree to the{' '}
          <a href={PremagicResources.RESOURCES.terms('attendee-register')} target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          . Learn about how we use and protect your data in our{' '}
          <a href={PremagicResources.RESOURCES.privacy('attendee-register')} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </RealCheckbox>
        <Space vertical size={6} />
        <Row>
          <ClientWebsiteDetailsValueItem
            itemKey={CLIENT_WEBSITE_FORM_ITEMS.USER_REGISTRATION_FOOT_NOTE}
            clientWebsite={clientWebsite}
            langCode={langCode}
          />
          <Button style={BUTTON_STYLES.LINK} onClick={showEditForm}>
            Edit
          </Button>
        </Row>
      </Space>
      <AddEventFormFieldPanelContainer projectId={projectId} />
      <EditEventFormFieldPanelContainer projectId={projectId} />
    </Card>
  );
}
