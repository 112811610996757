import { connect } from 'react-redux';
import { LabelService } from '@premagic/core';
import {
  clientIdForEventSelector,
  createAndAddEventLabel,
  crmEventItemsSelector,
  removeEventLabels,
  updateEventLabels,
} from '../../EventsDataDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import { LOADINGS } from '../../../../../../../common/Constants';
import { hasPermission } from '../../../users/UsersDataDuck';
import EventLabelSelector from './EventLabelSelector';
import { activeAccountIdSelector } from '../../../../acccount/AccountDataDuck';
import { fetchLabelsData, labelItemsSelector } from '../../../labels/LabelsDataDuck';
import { navigateToCRMPageWithFilter } from '../../../filters/CRMFilterDuck';

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;

  const event = crmEventItemsSelector(state)[eventId] || {};
  const clientId = clientIdForEventSelector(eventId)(state);
  return {
    eventId,
    clientId,
    labels: event?.labels,
    isSaving:
      isLoadingSelector(LOADINGS.EVENT.ADD_LABEL)(state) || isLoadingSelector(LOADINGS.EVENT.REMOVE_LABEL)(state),
    isLabelsLoading: isLoadingSelector(LOADINGS.CRM_LABELS.FETCH)(state),
    hasEventManagementPermission: hasPermission(PERMISSIONS.EVENT_DELIVERABLE)(state),
    accountId: activeAccountIdSelector(state),
    allLabels: labelItemsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEventLabels: (clientId, eventId, labelsData) => {
      dispatch(updateEventLabels(dispatch, clientId, eventId, labelsData));
    },
    createAndAddEventLabel: (clientId, eventId, labelData) => {
      dispatch(createAndAddEventLabel(dispatch, clientId, eventId, labelData));
    },
    removeEventLabels: (clientId, eventId, labelIds) => {
      dispatch(removeEventLabels(dispatch, clientId, eventId, labelIds));
    },
    navigate: (view, filters) => {
      dispatch(navigateToCRMPageWithFilter(dispatch, view, filters));
    },
    fetchAllEventLabels: () => {
      dispatch(fetchLabelsData(dispatch, LabelService.ENTITY_TYPE.EVENT));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventLabelSelector);
