export function getErrorMessage(message: string): string {
  if (/incorrect.*username.*password/i.test(message)) {
    return 'Incorrect username or password';
  }

  if (/CUSTOM_AUTH is not enabled for the clien/i.test(message)) {
    return 'Looks like you forgot to enter password';
  }

  if (/null failed with error Generate callenges lambda cannot be called/i.test(message)) {
    return 'You might have forgot to enter the password';
  }

  if (/validation error detected: Value at 'password'/i.test(message)) {
    return 'Please enter a better password';
  }

  if (/validation errors? detected: Value at 'userName'/i.test(message)) {
    return 'Invalid Email, Please verify the email and try again';
  }
  if (/This user pool is not configured with a role or Lambda trigger to send SMS/i.test(message)) {
    return 'You Email is not verified, Please write to support@premagic.com to verify your acocunt';
  }

  return message;
}
