import { BUTTON_STYLES, Button, Col, FormLabel, Row, Select, Space } from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import React, { useEffect, useState } from 'react';
import { ACCOUNT_FINANCIALS_FILTER_KEYS } from './AccountFinancialsFilterTypes';

type AccountFinancialsFilterItemDialogContentProps = {
  value?: string;
  onChange: (newFilterValue: string, slice: string) => void;
};

type MonthType = {
  value: string;
  label: string;
};
const currentYear = new Date().getFullYear();
const startYear = 2021;
const yearsOptions = Array.from({ length: currentYear - startYear + 2 }, (_, i) => startYear + i).map((year) => ({
  value: year,
  label: year.toString(),
}));

const breakDownOptions = [
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'yearly',
    label: 'Yearly',
  },
];

function getMonthsOptions(year: number) {
  const nowMonth = SimpleDateUtils.getDateStringFromDate(
    SimpleDateUtils.now(),
    SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO,
  );
  return Array.from(Array(12).keys()).map((idx) => {
    const date = SimpleDateUtils.getDateStringFromDate(
      new Date(year, idx, 1),
      SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_ISO,
    );
    const currentMonth = SimpleDateUtils.isSameMonth(nowMonth, date);
    return {
      value: date,
      label: `${SimpleDateUtils.getDateStringFromDate(date, SimpleDateUtils.STANDARD_DATE_FORMATS.MONTH)} ${
        currentMonth ? '(this month)' : ''
      }`,
    };
  });
}

function AccountFinancialsDateFilterDialog(props: AccountFinancialsFilterItemDialogContentProps): JSX.Element {
  const { value, onChange } = props;
  const nowYear = SimpleDateUtils.getDateStringFromDate(
    SimpleDateUtils.now(),
    SimpleDateUtils.STANDARD_DATE_FORMATS.YEAR,
  );

  const [selectedYear, setSelectedYear] = useState(Number(nowYear));
  const [selectedBreakdown, setSelectedBreakdown] = useState<string>(breakDownOptions[0]?.value);

  const monthOptionsFromYear = getMonthsOptions(selectedYear);
  const selectedMonthOption = monthOptionsFromYear.find((option) => option.value === value) || monthOptionsFromYear[0];
  const selectedYearOption = yearsOptions.find((option) => option.value === selectedYear) || yearsOptions[0];

  const [monthOptions, setMonthOptions] = useState(monthOptionsFromYear);
  const [selectedMonth, setSelectedMonth] = useState<MonthType>(selectedMonthOption);
  useEffect(() => {
    const newMonthOptions = getMonthsOptions(selectedYear);
    setSelectedMonth(monthOptionsFromYear[0]);
    setMonthOptions(newMonthOptions);
  }, [selectedYear]);

  return (
    <div>
      <Select
        options={breakDownOptions}
        value={breakDownOptions[0]}
        name=""
        onChange={(e) => setSelectedBreakdown(e?.value as string)}
        isMulti={false}
      />
      <Space vertical />
      <Row>
        <Col size={5}>
          <FormLabel>Year</FormLabel>
          <Select
            options={yearsOptions}
            value={selectedYearOption}
            name=""
            onChange={(e) => setSelectedYear(e?.value as number)}
            isMulti={false}
          />
        </Col>
        <Space />

        <Col size={7}>
          {selectedBreakdown !== 'yearly' && (
            <>
              <FormLabel>Month</FormLabel>
              <Select
                options={monthOptions}
                value={selectedMonth}
                name="date"
                onChange={(e) => setSelectedMonth(e as MonthType)}
                isMulti={false}
                autoFocus
              />
            </>
          )}
        </Col>
      </Row>
      <Space vertical size={6} />
      <Row>
        <Col size={null} rightAlighed>
          <Button
            style={BUTTON_STYLES.PRIMARY}
            onClick={() => {
              onChange(selectedMonth.value, selectedBreakdown);
            }}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </div>
  );
}

const AccountFinancialsFilterItemsDialogContent: Record<
  string,
  (props: AccountFinancialsFilterItemDialogContentProps) => JSX.Element
> = {
  [ACCOUNT_FINANCIALS_FILTER_KEYS.START_DATE_GTE]: AccountFinancialsDateFilterDialog,
};

export default AccountFinancialsFilterItemsDialogContent;
