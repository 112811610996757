import { connect } from 'react-redux';

import DAMLibraryFiltersItemContent from './DAMLibraryFiltersItemContent';
import { DAM_LIBRARY_FILTER_KEYS } from './DAMLibraryFilterTypes';
import { projectsSelector } from '../../AccountProjectsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    filterKey: DAM_LIBRARY_FILTER_KEYS;
    value?: string | Array<string>;
  },
) {
  const { filterKey, value } = ownProps;
  return {
    filterKey,
    value,
    projects: projectsSelector(state),
  };
}

export default connect(mapStateToProps)(DAMLibraryFiltersItemContent);
