import { connect } from 'react-redux';
import { selectedDummyUserSelector, setDummyUser } from './EventPosterCreatorDuck';
import EventPosterSetDummyUserButton from './EventPosterSetDummyUserButton';

function mapStateToProps(state, ownProps) {
  return {
    user: selectedDummyUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: (user) => {
      dispatch(setDummyUser(dispatch, user));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterSetDummyUserButton);
