import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { foldersEntitiesSelector, removeFolder } from '../AccountFoldersDataDuck';
import FolderDeleteDialog from './FolderDeleteDialog';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { isCompanyInSubscriptionPlan } from '../../../settings/company/CompanyDataDuck';

const COMMON_KEY = LOADINGS.FOLDER.DELETE;
function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;

  return {
    isSubscriptionPlan: isCompanyInSubscriptionPlan(state),
    folder: foldersEntitiesSelector(state)[folderId] || {},
    isDeleting: isLoadingSelector(COMMON_KEY)(state),
    projectId,
    folderId,
    isDeleteFolderPopoverShown: isModalOpenSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleShowDeleteFolder: (show) => {
      dispatch(toggleModalVisibility(dispatch, COMMON_KEY, show));
    },
    deleteFolderData: (projectId: string, folderId: string, folderType) => {
      dispatch(removeFolder(dispatch, projectId, folderId, folderType));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderDeleteDialog);
