import React from 'react';
import {
  Modal,
  MODAL_SIZES,
  Text,
  Button,
  BUTTON_STYLES,
  Space,
  COLOR_SHADES,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  BUTTON_SIZE,
  FormFooter,
} from '@premagic/myne';
import { EventTrackerService } from '@premagic/core';

type Props = {
  show: boolean;
  close: () => void;
  toggleTour: (status: boolean) => void;
};
export default function CRMTourWelcomeModal(props: Props): JSX.Element {
  const { show, close, toggleTour } = props;
  return (
    <Modal show={show} close={close} size={MODAL_SIZES.MD}>
      <Space size={10}>
        <Text block color={COLOR_SHADES.DARK} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
          Welcome to premagic.
        </Text>
        <Text block>
          Get started with our tutorial to learn about Events, Project, Photo selection tool, Signature album, Digital
          invites and many more.{' '}
        </Text>
        <FormFooter>
          <Button
            style={BUTTON_STYLES.LINK}
            onClick={() => {
              close();
              EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.TOUR_WELCOME_SCREEN, {
                action: 'skip',
              });
            }}
          >
            Skip
          </Button>
          <Button
            size={BUTTON_SIZE.LG}
            style={BUTTON_STYLES.PRIMARY}
            onClick={() => {
              close();
              toggleTour(true);
              EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.TOUR.OPEN, {
                source: 'welcome screen',
              });
            }}
          >
            Start tutorial
          </Button>
        </FormFooter>
      </Space>
    </Modal>
  );
}
