import { connect } from 'react-redux';
import { setEditOfAttendee } from '../EventAttendeesPageDuck';
import { attendeeNavigationDataSelector } from '../EventAttendeesDataDuck';
import EditEventAttendeeNavButtons from './EditEventAttendeeNavButtons';

function mapStateToProps(state, ownProps) {
  return {
    navigation: attendeeNavigationDataSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAttendeeEditPanel: (id) => {
      dispatch(setEditOfAttendee(dispatch, id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEventAttendeeNavButtons);
