import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormErrorType,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { useState } from 'react';
import EventExhibitorFormContainer from './EventExhibitorFormContainer';

type Props = {
  addEventExhibitor: (projectId: string, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
};

export default function AddEventExhibitorPanel(props: Props) {
  const { show, close, projectId, addEventExhibitor, isLoading, error } = props;

  const [formError, setFormError] = useState<any>();

  function handleFormSubmit(formResponse) {
    if (formResponse.errors) {
      setFormError(formResponse.errors);
    } else {
      addEventExhibitor(projectId, formResponse);
      setFormError(null);
    }
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add Exhibitor</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={error || formError} onSubmit={handleFormSubmit}>
          <EventExhibitorFormContainer projectId={projectId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
