import { APPS } from './AppUtils';

export const THIRD_PARTY_APPLICATIONS: {
  SENTRY: Record<APPS, string>;
  MIXPANEL: Record<APPS, string | null>;
  GA: Record<APPS, string | null>;
  TAGMANAGER: Record<APPS, string | null>;
} = {
  SENTRY: {
    [APPS.ACCOUNT_APP]: 'https://d260189bb4dc4e98985a7bbc750b4d41@logs.premagic.com/7',
    [APPS.CLIENT_WEBSITE]: 'https://34a91b486d1845c09bd62180e520b98a@logs.premagic.com/8',
    [APPS.SELECTION_SIGNATURE]: 'https://06fc4196899942ef8cd4348511aadc48@logs.premagic.com/9',
    [APPS.CLIENT_APP]: 'https://0272b63520f54c1aa25a417c59a0efa3@logs.premagic.com/10',
    [APPS.PORTFOLIO]: 'https://1dfc216b5b44497c912c7e198bea684c@logs.premagic.com/11',
    [APPS.PROPOSAL]: 'https://65738103473444c0951023fd450a2e55@logs.premagic.com/12',
  },
  MIXPANEL: {
    [APPS.ACCOUNT_APP]: 'd285e10efc78c6d9cced1420f06017f8',
    [APPS.CLIENT_WEBSITE]: null,
    [APPS.SELECTION_SIGNATURE]: '2559bf53db31f69e59d2ab2c93756263',
    [APPS.CLIENT_APP]: '8ef4f87b2e6f8263dbde178a421f8372',
    [APPS.PORTFOLIO]: null,
    [APPS.PROPOSAL]: 'bc601a8196ad386db2a2a9c8efa5a022',
  },
  GA: {
    [APPS.ACCOUNT_APP]: null,
    [APPS.CLIENT_WEBSITE]: null,
    [APPS.SELECTION_SIGNATURE]: null,
    [APPS.CLIENT_APP]: null,
    [APPS.PORTFOLIO]: 'https://1dfc216b5b44497c912c7e198bea684c@logs.premagic.com/11',
    [APPS.PROPOSAL]: null,
  },
  TAGMANAGER: {
    [APPS.ACCOUNT_APP]: null,
    [APPS.CLIENT_WEBSITE]: 'G-T1QVNXMNG2',
    [APPS.SELECTION_SIGNATURE]: 'G-38W6THJ0P6',
    [APPS.CLIENT_APP]: null,
    [APPS.PORTFOLIO]: 'G-79SQ858SQJ',
    [APPS.PROPOSAL]: null,
  },
};
