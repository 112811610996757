import { EventPosterService } from '@premagic/core';
import { COLOR_SHADES, FormGroup, FormLabel, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import React from 'react';
import { getPosterSizeDetailsFromSpec } from '@premagic/poster-creator';

type IAmAttendingPosterSelectProps = {
  projectId: string;
  posterId: string;
  poster: EventPosterService.EventPosterType;
  updatePoster: (options: { posterId: string; projectId: string }, data) => void;
};

export default function EventPosterSizeSelector(props: IAmAttendingPosterSelectProps) {
  const { updatePoster, projectId, posterId, poster } = props;
  if (!poster) return null;

  const { size } = poster;
  const sizeDetails = getPosterSizeDetailsFromSpec(size.width, size.height);

  return (
    <FormGroup>
      <FormLabel>Size</FormLabel>
      {sizeDetails && (
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          {sizeDetails.name}
        </Text>
      )}
      <Space size={1} />
      <Text color={COLOR_SHADES.DARKER}>({Math.ceil(size.width)}</Text>
      <Text muted boldness={TEXT_BOLDNESS.BOLD}>
        x
      </Text>
      <Text color={COLOR_SHADES.DARKER}>{Math.ceil(size.height)})</Text>
    </FormGroup>
  );
}
