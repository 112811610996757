import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { activeAccountIdSelector } from '../../../../acccount/AccountDataDuck';
import { damLibraryFilterLabelsByFilterKeySelector } from '../../DAMLibraryDataDuck';
import { DAM_LIBRARY_FILTER_KEYS } from '../DAMLibraryFilterTypes';
import DAMLibraryKeyPeopleFilterSelector from './DAMLibraryKeyPeopleFilterSelector';

function mapStateToProps(state, ownProps: { value: Array<string> }) {
  const { value } = ownProps;

  return {
    value,
    activeAccountId: activeAccountIdSelector(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECTS.FETCH_ALL)(state),
    allKeyPeople: damLibraryFilterLabelsByFilterKeySelector(DAM_LIBRARY_FILTER_KEYS.KEY_PEOPLE)(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryKeyPeopleFilterSelector);
