import { useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Col,
  FocalPoint,
  ImageV2,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Row,
  Space,
} from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  hideModal: () => void;
  isFocalPointModalOpen: boolean;
  setFocalPoint: (projectId: string, cordinates: { x: number; y: number } | '') => void;
};

export default function ProjectCoverFocalPoint(props: Props) {
  const { projectId, projectMeta, isFocalPointModalOpen, hideModal, setFocalPoint } = props;
  const [focalPointCordinate, setSetFocalPointCordinate] = useState(
    projectMeta?.cover_image_focal_point ? projectMeta?.cover_image_focal_point : { x: 50, y: 50 },
  );

  return (
    <Modal show={isFocalPointModalOpen} close={hideModal} size={MODAL_SIZES.LG}>
      <ModalTitle>Set banner focal point</ModalTitle>
      <Row center>
        <FocalPoint cordinates={focalPointCordinate} onChange={setSetFocalPointCordinate}>
          <ImageV2
            src={projectMeta?.cover_image?.dynamic_image_url || ''}
            cssStyle={{ maxWidth: '100%', borderRadius: '8px', maxHeight: '70vh' }}
            alt="cover"
          />
        </FocalPoint>
      </Row>
      <Space vertical size={8} />
      <Row>
        <Button
          style={BUTTON_STYLES.LINK}
          onClick={() => {
            setFocalPoint(projectId, '');
            hideModal();
          }}
        >
          Remove focal point
        </Button>
        <Col size={null} rightAlighed>
          <Button
            style={BUTTON_STYLES.PRIMARY}
            onClick={() => {
              setFocalPoint(projectId, focalPointCordinate);
              hideModal();
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
