import { connect } from 'react-redux';
import EventAttendeeBadgeLayoutCenterComponent from './EventAttendeeBadgeLayoutCenterComponent';
import { eventAttendeesIdsSelector } from '../../EventAttendeesDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  return { projectId, folderId, eventAttendeeIds: eventAttendeesIdsSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeLayoutCenterComponent);
