import { compile, match } from 'path-to-regexp';
import qs from 'qs';
import { isEmpty, pickBy } from 'lodash';
import { logError } from './ErrorTracker';

export function getLocationSearch() {
  return window.location.search;
}

export function getUrlPathname(): string {
  return window.location.pathname;
}

export function getUrlHostname(): string {
  return window.location.hostname;
}

export function getSubDomain(): string {
  return getUrlHostname().split('.')[0];
}

export function getUrlOrigin(): string {
  return window.location.origin;
}

export function getUrlHash(): string {
  const hash = window.location.hash.replace('#', '');
  if (hash.charAt(hash.length) === '/') return hash;
  return `${hash}/`;
}

export function reload(): void {
  window.location.reload();
}

export function getUrl(options?: {
  removeQueryString?: boolean;
  removeProtocol?: boolean;
  removeHash?: boolean;
}): string {
  const url = window.location.href;

  if (options?.removeHash) return window.location.origin + window.location.pathname;
  if (options?.removeQueryString && options?.removeProtocol) return url.split(window.location.host)[1].split('?')[0];
  if (options?.removeQueryString) return url.split('?')[0];

  return url;
}

export function navigateToPage(page: string): void {
  window.location.href = page;
}

export function removeAllQueryString() {
  const url = getUrl({ removeQueryString: true, removeProtocol: true });
  window.history.pushState({}, document.title, url);
}
/**
 * getRouterUrlFor - Coverts the url pattern to url with attributes
 * @param route - route pattern from APP_URLS
 * @param data - data to be filled to the url
 */
export function getRouteUrlFor(route: string, data: Record<string, unknown>): string {
  try {
    const toPath = compile(route);
    return toPath(data);
  } catch (e) {
    logError('CONVERT TO URL FROM ROUTE PATH FAILED', e);
    return '';
  }
}

export function isExternalUrl(url: string): boolean {
  return url.includes('http') || url.substring(0, 7) === 'mailto:' || url.substring(0, 4) === 'tel:';
}

export interface QueryObject {
  [key: string]: undefined | string | number | string[] | QueryObject | QueryObject[];
}

export function getQueryObjectFromString(
  queryString: string,
  options?: {
    allowDots?: boolean;
  },
): QueryObject {
  const { allowDots } = options || {
    allowDots: true,
  };
  return qs.parse(queryString, {
    ignoreQueryPrefix: true,
    allowDots,
  }) as QueryObject;
}

export function getQueryObjectFromUrl(): QueryObject {
  return getQueryObjectFromString(getLocationSearch());
}

export function getQueryStringFromObject(
  filters: QueryObject,
  options?: { arrayFormat: 'repeat' | 'comma' },
): string | null {
  const updatedFilters = pickBy(filters, (value) => value !== null && value !== '' && value !== undefined);

  if (isEmpty(updatedFilters)) return null;

  return qs.stringify(updatedFilters, {
    skipNulls: true,
    strictNullHandling: false,
    allowDots: true,
    arrayFormat: 'comma',
    ...options,
  });
}
/**
 * getMatchingUrlParts
 * eg match("/user/123"); //=> { path: '/user/123', index: 0, params: { id: '123' } }
 * @param route - the regex
 * @param url - the url where you want to get the variable values from
 */
export function getMatchingUrlParts(
  route: string,
  url: string,
): { path: string; index: number; params: { [variable: string]: string } } | false {
  const toMatch = match<{ QueryObject }>(route, { decode: decodeURIComponent, strict: false });
  return toMatch(url);
}

export function getURLWithProtocol(website?: string): string {
  if (!website) return '';
  const trimmedValue = website.trim();
  if (trimmedValue.length === 0) return '';

  const httpRegex = /^(https?|mailto):\/\/(?!.*:\/\/)\S+/;

  return httpRegex.test(trimmedValue) ? trimmedValue : `https://${trimmedValue}`;
}

export function encodeURL(str): string {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
}

export function getUrlWithoutProtocol(url: string): string {
  return url.replace(/(^\w+:|^)\/\//, '');
}

export function getLinkWithoutThePrefix(prefix: string, value?: string) {
  if (!value) return '';
  // Regex to replace https://, http://, https?://www. and prefix with empty string
  return value.replace(new RegExp(`^(https?://)?(www\\.)?${prefix}`, 'i'), '');
}
