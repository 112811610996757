import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { normalize } from 'normalizr';
import { SimpleDateUtils, ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { CalendarEvent } from '@premagic/myne';
import { EventsService } from '@premagic/core';

import { calendarAndEventsPageSelector } from '../../../../reducers/selectors';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { CalendarOccasionsType, fetchOccasionsForCalendar } from '../OccasionsCalendarService';
import { CalendarOccasionsSchema } from '../../../../../schema/Schemas';
import { CRM_FILTER_KEYS } from '../../filters/CRMFilterTypes';

const getActionType = ActionTypeUtils.getActionTypeFunction('CALENDAR_OCCASION');

export const setCalendarOccasions = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchCalendarOccasionsData = createAction(getActionType('DATA', 'FETCH'), async (dispatch, filters) => {
  const loadingKey = LOADINGS.CRM.CALENDAR_OCCASION_FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  try {
    const occasions = await fetchOccasionsForCalendar(filters);
    const normalizedData = normalize(occasions.results, CalendarOccasionsSchema);
    const { occasions: normalizedOccasions } = normalizedData.entities;
    dispatch(setCalendarOccasions(dispatch, normalizedOccasions));

    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    return occasions;
  } catch (e) {
    dispatch(setErrorState(dispatch, loadingKey, e));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    ErrorTracker.logError('Calendar Occasions: fetch failed', e);
    return e;
  }
});

type CalendarOccasionType = IMap<string, CalendarOccasionsType>;
type StateType = {
  items: CalendarOccasionType;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setCalendarOccasions.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const calendarOccasionsDataSelector = createSelector(
  calendarAndEventsPageSelector,
  (calendarAndEvents) => calendarAndEvents.data.occasions as StateType,
);

export const calendarOccasionItemsSelector = createSelector(calendarOccasionsDataSelector, (state) =>
  state.items.toJSON(),
);

export const calendarOccasionsSelector = (filters: string) =>
  createSelector(calendarOccasionsDataSelector, (state) => {
    const hasStatusFilter = !!filters && filters.includes(CRM_FILTER_KEYS.STATUS);
    let { items } = state;
    // If the user has not selected any status filter, i.e we should status as `ALL`, we need to show all the events expect rejected events.
    if (!hasStatusFilter) {
      items = items.filter((item) => item.event.status !== EventsService.EVENT_STATUS.REJECTED);
    }
    return items
      .map(
        (occasion) =>
          ({
            start: SimpleDateUtils.getDateObject(occasion.start_date_time),
            end: SimpleDateUtils.getDateObject(occasion.end_date_time),
            id: occasion.id,
            title: occasion.name,
            isConfirmed: occasion.event.status === EventsService.EVENT_STATUS.CONFIRMED,
          } as CalendarEvent),
      )
      .valueSeq()
      .toArray();
  });
