import { TEXT_SIZE } from '@premagic/myne';

export type EventAttendeeBadgeDesignType = {
  config: {
    width: number;
    height: number;
  };
  widget_data: Record<WIDGET_TYPE, WidgetDataType>;
};

export const PRINT_BADGE_CLASS = 'js-badge-content';

export type WidgetDataType = {
  id?: string;
  position?: {
    x: number;
    y: number;
  };
  dimension?: {
    width: number;
    height?: number;
  };
  value?: string;
  color?: string;
  font?: string;
  weight?: 400 | 500 | 700;
  fontCase?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  fontSize?: TEXT_SIZE;
};

export const enum WIDGET_TYPE {
  POSTER = 'POSTER',
  AVATAR = 'AVATAR',
  DISPLAY_ID = 'DISPLAY_ID',
  USER_NAME = 'USER_NAME',
  USER_ROLE = 'USER_ROLE',
  USER_COMPANY = 'USER_COMPANY',
}

const widgetData: Record<WIDGET_TYPE, WidgetDataType> = {
  [WIDGET_TYPE.POSTER]: {
    id: WIDGET_TYPE.POSTER,
    position: {
      x: 0,
      y: 0,
    },
    dimension: {
      width: 382,
      height: 605,
    },
  },
  [WIDGET_TYPE.AVATAR]: {
    id: WIDGET_TYPE.AVATAR,
    position: {
      x: 191,
      y: 138,
    },
    dimension: {
      width: 226,
      height: 226,
    },
  },
  [WIDGET_TYPE.DISPLAY_ID]: {
    id: WIDGET_TYPE.DISPLAY_ID,
    position: {
      x: 40,
      y: 288,
    },
    color: '#203a56',
    font: 'Arial',
    weight: 500,
    fontSize: TEXT_SIZE.SIZE_3,
  },
  [WIDGET_TYPE.USER_NAME]: {
    id: WIDGET_TYPE.USER_NAME,
    position: {
      x: 191,
      y: 380,
    },
    dimension: {
      width: 345,
    },
    color: '#203a56',
    font: 'Arial',
    weight: 700,
    fontSize: TEXT_SIZE.SIZE_1,
  },
  [WIDGET_TYPE.USER_ROLE]: {
    id: WIDGET_TYPE.USER_ROLE,
    position: {
      x: 191,
      y: 430,
    },
    dimension: {
      width: 345,
    },
    color: '#203a56',
    font: 'Arial',
    weight: 400,
    fontSize: TEXT_SIZE.SIZE_1,
  },
  [WIDGET_TYPE.USER_COMPANY]: {
    id: WIDGET_TYPE.USER_COMPANY,
    position: {
      x: 191,
      y: 475,
    },
    dimension: {
      width: 345,
    },
    color: '#203a56',
    font: 'Arial',
    weight: 500,
    fontSize: TEXT_SIZE.SIZE_1,
  },
};

export const defaultBadgeWidgetConfig = {
  config: {
    width: 382,
    height: 605,
  },
  widget_data: widgetData,
};
