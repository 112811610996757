import React, { useEffect, useRef, useMemo } from 'react';
import videojs from 'video.js';
import qualityLevelsLib from 'videojs-contrib-quality-levels';
// import chromeCast from '@silvermine/videojs-chromecast';
import 'video.js/dist/video-js.min.css';
import './lib-video-js-overrides.css';
import { throttle } from 'lodash';
import { LocalCacheUtils } from '@premagic/utils';

// chromeCast(videojs);
const THUMBNAIL_PLAYER_LENGTH = 60;
const THUMBNAIL_VIDEO_NAME = 'thumbnail_preview';

export enum VIDEO_PLAYER_STYLES {
  DEFAULT = 'default',
  HIGH_QUALITY_INLINE = 'high-inline',
  DEFAULT_INLINE = 'default_inline',
  THUMBNAIL = 'thumbnail',
}

function setPositionCache(videoId: string, position: number): void {
  LocalCacheUtils.setCache(`player-${videoId}`, {
    position,
  });
}

function getPositionCache(videoId: string): number {
  const cacheData = (LocalCacheUtils.getCache(`player-${videoId}`) || { position: 0 }) as {
    position: number;
  };
  return cacheData.position;
}

const setPlayerCacheDebounced = throttle(setPositionCache, 5000);

const defaultOptions = {
  fill: true,
  responsive: true,
  preload: 'auto' as const,
  controls: true,
  bigPlayButton: true,
  // nativeControlsForTouch: true,
  // plugins: {
  //   chromecast: {},
  // },
  // techOrder: ['chromecast', 'html5'],
};

function getOptions(style: VIDEO_PLAYER_STYLES) {
  if (style === VIDEO_PLAYER_STYLES.THUMBNAIL) {
    return {
      ...defaultOptions,
      fluid: true,
      controls: false,
      loop: true,
      muted: true,
      autoplay: true,
      inactivityTimeout: 0,
    };
  }
  if (style === VIDEO_PLAYER_STYLES.DEFAULT_INLINE) {
    return {
      ...defaultOptions,
      fluid: true,
      controls: false,
      loop: true,
      muted: true,
      autoplay: true,
      inactivityTimeout: 0,
    };
  }

  if (style === VIDEO_PLAYER_STYLES.HIGH_QUALITY_INLINE) {
    return {
      ...defaultOptions,
      fill: true,
      controls: false,
      loop: true,
      muted: true,
      autoplay: true,
      inactivityTimeout: 0,
    };
  }

  return defaultOptions;
}

// function enableQualityLevel(level, qualityLevelsObject) {
//   if (!qualityLevelsObject) return;
//   // eslint-disable-next-line no-underscore-dangle
//   const levels = qualityLevelsObject.levels_;
//   for (let i = 0; i < levels.length; i += 1) {
//     const qLevel = levels[i];
//     qLevel.enabled = i === level;
//   }
//
//   // eslint-disable-next-line no-underscore-dangle,no-param-reassign
//   qualityLevelsObject.selectedIndex_ = level;
//   qualityLevelsObject.trigger({ type: 'change', selectedIndex: level });
// }

function toggleQualityLevels(qualityLevels, type: 'ENABLE_ONLY_THUMBNAIL' | 'DISABLE_THUMBNAIL') {
  if (!qualityLevels) return;

  // Force select 0 quality level
  qualityLevels.on('addqualitylevel', (event) => {
    const quality = event.qualityLevel;
    if (type === 'ENABLE_ONLY_THUMBNAIL') {
      quality.enabled = quality.label.includes(THUMBNAIL_VIDEO_NAME);
    }
    if (type === 'DISABLE_THUMBNAIL') {
      quality.enabled = !quality.label.includes(THUMBNAIL_VIDEO_NAME);
    }
  });

  qualityLevels.on('change', () => {
    if (type === 'ENABLE_ONLY_THUMBNAIL' && qualityLevels.selectedIndex != 0) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
      qualityLevels.selectedIndex_ = 0;
      qualityLevels.trigger({ type: 'change', selectedIndex: 0 });
    }
    if (type === 'DISABLE_THUMBNAIL' && qualityLevels.selectedIndex === 0) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
      qualityLevels.selectedIndex_ = 1;
      qualityLevels.trigger({ type: 'change', selectedIndex: 1 });
    }
  });
}

function registerPlugins(player) {
  if (!player.qualityLevels) {
    videojs.registerPlugin('qualityLevels', qualityLevelsLib);
  }
  // player.chromecast();
}

interface Props {
  src: string;
  type: string;
  autoplay?: boolean;
  autoplayFromStart?: boolean;
  style?: VIDEO_PLAYER_STYLES;
  onClick?: () => void;
  poster?: string;
  onPlay?: () => void;
  onEnded?: () => void;
}

export function VideoJsPlayer(props: Props) {
  const {
    src,
    type,
    autoplay = true,
    style = VIDEO_PLAYER_STYLES.DEFAULT,
    onClick,
    poster,
    onPlay,
    onEnded,
    autoplayFromStart = false,
  } = props;

  const videoRef = useRef(null);
  const playerRef = useRef<videojs.Player>();

  const options = useMemo(
    () => ({
      ...getOptions(style),
      autoplay,
      sources: [
        {
          src,
          type,
        },
      ],
    }),
    [src],
  );

  function onVideoPlayerReady(player, playerOptions) {
    registerPlugins(player);

    if (playerOptions.autoplay) {
      // https://stackoverflow.com/questions/49552005/playing-video-automatically-on-safari-wont-work/55812549#55812549
      player.playsinline(true);
      // DEBUG  player.play().catch(function (error) {alert(error) }); }
    }

    if (!autoplayFromStart) {
      player.currentTime(getPositionCache(src));
      player.on('timeupdate', () => {
        setPlayerCacheDebounced(src, player.currentTime());
      });
    }

    // Listen on events https://github.com/videojs/video.js/blob/master/src/js/player.js#L58
    if (onPlay) player.on('play', onPlay);
    if (onEnded) player.on('ended', onEnded);

    if (onClick) {
      // Handle onclick on thumbnail, by default onclick will not do anything
      player.on('click', onClick);
      let touchStartTime;
      player.on('touchstart', (e: TouchEvent) => {
        touchStartTime = e.timeStamp;
      });
      player.on('touchend', (e: TouchEvent) => {
        const durationOfTouch = e.timeStamp - touchStartTime;
        if (durationOfTouch < 150) onClick();
      });
    }

    if ([VIDEO_PLAYER_STYLES.THUMBNAIL, VIDEO_PLAYER_STYLES.DEFAULT_INLINE].includes(style)) {
      // 1. Play only for 1 min
      player.on('timeupdate', () => {
        if (player.currentTime() > THUMBNAIL_PLAYER_LENGTH) player.currentTime(0);
      });
    }

    if (style === VIDEO_PLAYER_STYLES.THUMBNAIL) {
      toggleQualityLevels(player.qualityLevels(), 'ENABLE_ONLY_THUMBNAIL');
    } else {
      toggleQualityLevels(player.qualityLevels(), 'DISABLE_THUMBNAIL');
    }
  }

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, options, () => {
        const player = playerRef.current;
        if (!player) return;
        onVideoPlayerReady(player, options);
      });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(
    () => () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = undefined;
      }
    },
    [],
  );

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef} className="video-js" poster={poster}>
      <p className="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/">supports HTML5 video</a>
      </p>
    </video>
  );
}
