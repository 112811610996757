import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { setEditOfSponsor, sponsorIdForEditSelector } from '../EventSponsorPageDuck';
import EditEventSponsorPanel from './EditEventSponsorPanel';
import { LOADINGS } from '../../../../../../../common/Constants';
import { updateEventSponsor } from '../EventSponsorDataDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';

const PANEL_KEY = LOADINGS.EVENT_SPONSOR.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.EVENT_SPONSOR.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const sponsorId = sponsorIdForEditSelector(state);

  return {
    projectId,
    sponsorId,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSponsor: (options, data) => {
      dispatch(updateEventSponsor(dispatch, options, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(ModalDuck.setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, null));
      dispatch(setEditOfSponsor(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEventSponsorPanel);
