import { connect } from 'react-redux';
import ClientPaymentsInvoiceOccasions from './ClientPaymentsInvoiceOccasions';
import { occasionIdsForEventSelector, occasionItemsSelector } from '../../../occassions/OccasionsDataDuck';

function mapStateToProps(state, ownProps: { eventId: string }) {
  const { eventId } = ownProps;
  return {
    occasionIds: occasionIdsForEventSelector(eventId)(state),
    occasions: occasionItemsSelector(state),
  };
}

export default connect(mapStateToProps)(ClientPaymentsInvoiceOccasions);
