import { connect } from 'react-redux';
import { EventCustomFromFieldsService } from '@premagic/core';
import EventFormFieldForm from './EventFormFieldForm';
import {
  eventCustomFormFieldIdsSelectors,
  eventCustomFormFieldsItemsSelectors,
} from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    formFieldId?: string;
  },
) {
  const { projectId, formFieldId } = ownProps;

  return {
    projectId,
    formFieldId,
    formField: formFieldId ? eventCustomFormFieldsItemsSelectors(state)[formFieldId] : undefined,
    scope: EventCustomFromFieldsService.FORM_FIELD_SCOPE.REGISTRATION,
    formFieldIds: eventCustomFormFieldIdsSelectors(EventCustomFromFieldsService.FORM_FIELD_SCOPE.REGISTRATION)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFormFieldForm);
