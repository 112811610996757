import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import CompanyBrandingListItem from './CompanyBrandingListItem';
import { LOADINGS } from '../../../../../common/Constants';
import { foldersEntitiesSelector } from '../../projects/folders/AccountFoldersDataDuck';
import { removeSystemBrandingFolder } from '../../projects/sponsor/SponsorAndBrandingDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
  },
) {
  const { id } = ownProps;
  return {
    folder: foldersEntitiesSelector(state)[id],
    isUpdating: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.DELETE_SINGLE(id))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeFolder: (folderId) => {
      dispatch(removeSystemBrandingFolder(dispatch, folderId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBrandingListItem);
