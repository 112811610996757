import styled from 'styled-components';
import * as React from 'react';

const HEIGHT_OF_GRID = 240;
const HEIGHT_OF_GRID_LARGE = 600;
const NUMBER_OF_COLUMNS_ROW = 12;
const GRID_GAP = 12;
const GridLayoutStyle = styled.div`
  display: grid;
  height: ${HEIGHT_OF_GRID}px;
  max-height: ${HEIGHT_OF_GRID}px;
  grid-template-columns: repeat(${NUMBER_OF_COLUMNS_ROW}, 1fr);
  grid-template-rows: repeat(
    ${NUMBER_OF_COLUMNS_ROW},
    ${(HEIGHT_OF_GRID - GRID_GAP * (NUMBER_OF_COLUMNS_ROW - 1)) / 12}px
  );
  grid-gap: ${GRID_GAP}px;

  @media (min-width: 840px) {
    height: ${HEIGHT_OF_GRID_LARGE}px;
    max-height: ${HEIGHT_OF_GRID_LARGE}px;
    grid-template-rows: repeat(
      ${NUMBER_OF_COLUMNS_ROW},
      ${(HEIGHT_OF_GRID_LARGE - GRID_GAP * (NUMBER_OF_COLUMNS_ROW - 1)) / 12}px
    );
  }
`;

interface GridLayoutProps {
  children: React.ReactNode;
}

export function GridLayout(props: GridLayoutProps): JSX.Element {
  const { children } = props;
  return <GridLayoutStyle>{children}</GridLayoutStyle>;
}

interface GridLayoutItemProps {
  width?: number;
  height?: number;
  left?: number;
  top?: number;
  middle?: boolean;
  children: React.ReactNode;
}

const GridLayoutItemStyle = styled.div<GridLayoutItemProps>`
  height: 100%;
  min-width: 0;
  grid-column-end: ${({ width = 1 }) => `span ${width}`};
  grid-row-end: ${({ height = 1 }) => `span ${height}`};
  ${({ left }) => left && `grid-column-start: ${left}`};
  ${({ top }) => top && `grid-row-start: ${top}`};
  ${
    /* prettier-ignore */
    ({ middle }) => middle && `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `
  };
`;

export function GridLayoutItem(props: GridLayoutItemProps): JSX.Element {
  const { children, top, left, middle, height, width } = props;
  return (
    <GridLayoutItemStyle top={top} left={left} middle={middle} height={height} width={width}>
      {children}
    </GridLayoutItemStyle>
  );
}
