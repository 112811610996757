import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  COLOR_SHADES,
  Space,
  Col,
  Row,
  Modal,
  MODAL_SIZES,
  ModalTitle,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ALBUM_STATES } from '../services/AlbumServices';

type Props = {
  show: boolean;
  close: () => void;
  updateAlbumState: (albumId: string, state) => void;
  isSaving: boolean;
  albumId: string;
};

export default function AlbumConfirmationToPhotographerModal(props: Props): React.ReactElement {
  const { close, show, updateAlbumState, albumId, isSaving } = props;

  return (
    <Modal close={close} show={show} size={MODAL_SIZES.SM}>
      <div>
        <ModalTitle>Confirm the draft album?</ModalTitle>

        <Text block>Photographer will be notified about your confirmation</Text>
        <Space vertical size={8} />
        <Row>
          <Col size={null} rightAlighed>
            <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.LG} onClick={close}>
              Review again
            </Button>
            <Space />
            <Button
              isLoading={isSaving}
              style={BUTTON_STYLES.PRIMARY}
              size={BUTTON_SIZE.LG}
              onClick={() => updateAlbumState(albumId, ALBUM_STATES.REVIEW_COMPLETED)}
            >
              Yes, Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
