import React, { useState } from 'react';
import {
  Col,
  DatePicker,
  FormGroup,
  FormLabel,
  RadioButton,
  RichTextArea,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';

import { CRMPaymentType, PAYMENT_TYPES } from '../CRMPaymentService';
import PaymentCategoryPickerContainer from '../PaymentCategoryPickerContainer';
import InputCurrencyContainer from '../../../acccount/InputCurrencyContainer';
import PaymentTaxOptionPickerContainer from '../PaymentTaxOptionPickerContainer';

type Props = {
  payment?: CRMPaymentType;
  totalRemainingClientPayment: number;
};

export default function ClientPaymentForm(props: Props): JSX.Element {
  const { payment, totalRemainingClientPayment } = props;
  const isNew = !payment;
  const [isSettled, setIsSettled] = useState(isNew ? null : String(payment && payment.is_settled));
  const showForm = isSettled;

  return (
    <div>
      {isNew && (
        <FormGroup>
          <RadioButton name="is_settled" value="false" onClick={() => setIsSettled('false')}>
            <Text boldness={TEXT_BOLDNESS.BOLD}>UN-PAID</Text>
            <Text muted block>
              Add payment which is yet to receive from your client
            </Text>
          </RadioButton>
          <RadioButton name="is_settled" value="true" onClick={() => setIsSettled('true')}>
            <Text boldness={TEXT_BOLDNESS.BOLD}>PAID</Text>
            <Text muted block>
              Add payment received from the client.
            </Text>
          </RadioButton>
        </FormGroup>
      )}
      {showForm && (
        <div>
          <Row>
            <Col>
              <PaymentCategoryPickerContainer paymentType={PAYMENT_TYPES.INFLOW} />
            </Col>
            <Space />
            <Col>
              <FormGroup>
                <FormLabel isRequired>Amount</FormLabel>
                <InputCurrencyContainer
                  name="amount"
                  required
                  defaultValue={payment?.amount || totalRemainingClientPayment}
                />
              </FormGroup>
            </Col>
          </Row>
          <PaymentTaxOptionPickerContainer payment={payment} />
          {isSettled === 'true' && (
            <FormGroup>
              <FormLabel isRequired>Payment date</FormLabel>
              <DatePicker
                showTimePicker
                name="payment_date"
                defaultValue={payment?.payment_date}
                maxDate={SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO)}
              />
            </FormGroup>
          )}
          {isSettled === 'false' && (
            <FormGroup>
              <FormLabel isRequired>Due date</FormLabel>
              <DatePicker
                name="due_date"
                defaultValue={payment?.due_date}
                // minDate={SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO)}
                // I am removing this so that client can easily move from tave.
              />
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel>Notes</FormLabel>
            <RichTextArea name="notes" defaultValue={payment?.notes} />
          </FormGroup>
        </div>
      )}
    </div>
  );
}
