import React, { useState } from 'react';
import {
  Card,
  Color,
  COLOR_SHADES,
  Divider,
  FadeIn,
  FormResponseType,
  GroupedButton,
  GroupedButtons,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { EventTrackerService, PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import FeatureInfo from '../../help/feature-details/FeatureInfo';
import PremiumFeatureIcon from '../../settings/PremiumFeatureIcon';
import EventUserRegistrationDetailsContainer from './EventUserRegistrationDetailsContainer';
import AIPhotoDeliveryConfirmationModalContainer from './AIPhotoDeliveryConfirmationModalContainer';
import { AI_DELIVERY_STATES } from './AIPhotoDeliveryService';

const aiFeatureDetails = PricingPlanFeaturesService.getFeatureDetails(
  PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY,
);

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateStatus: (projectId: string, data: FormResponseType) => void;
  hasAiDeliveryFeature: boolean;
  createFolder: (projectId: string) => void;
  shareFolderIds: Array<string>;
};

export default function AIPhotoDeliveryCard(props: Props) {
  const { updateStatus, projectMeta, projectId, hasAiDeliveryFeature, createFolder, shareFolderIds } = props;
  const {
    [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM]: aiSignatureAlbumEnabled,
    [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_EXPRESS_DELIVERY]: aiSignatureAlbumExpressEnabled,
  } = projectMeta || {};
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<false | AI_DELIVERY_STATES>(false);

  function handleShowForDialog(state: false | AI_DELIVERY_STATES) {
    setShowConfirmationDialog(state);
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.AI_DELIVERY_SHOW_CONFIRMATION, {
      state,
    });
  }

  function handleAIDelivery(action: AI_DELIVERY_STATES) {
    setShowConfirmationDialog(false);
    EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PROJECT.AI_DELIVERY_ENABLE, {
      state: action,
      projectId,
    });
    switch (action) {
      case AI_DELIVERY_STATES.NORMAL:
      case AI_DELIVERY_STATES.EXPRESS:
        // show Dialog
        updateStatus(projectId, {
          data: {
            [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_STATUS]:
              ProjectMetaService.EVENT_REGISTRATION_STATUS_VALUES.OPEN,
            [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM]: true,
            [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_EXPRESS_DELIVERY]:
              action === AI_DELIVERY_STATES.EXPRESS,
          },
        });
        if (shareFolderIds.length === 0) return createFolder(projectId);
        return;

      case AI_DELIVERY_STATES.OFF:
      default:
        return updateStatus(projectId, {
          data: {
            [ProjectMetaService.PROJECT_META_TYPES.EVENT_REGISTRATION_STATUS]:
              ProjectMetaService.EVENT_REGISTRATION_STATUS_VALUES.CLOSED,
            [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM]: false,
            [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_EXPRESS_DELIVERY]: false,
          },
        });
    }
  }

  return (
    <>
      <Card>
        <Space>
          <Row vcenter>
            <div>
              <Space size={1} vertical />
              <Color shade={COLOR_SHADES.YELLOW} inline>
                <Icon name="fast_forward" size={ICON_SIZES.SM} />
              </Color>
            </div>
            <Space size={1} />
            <div>
              <Text boldness={TEXT_BOLDNESS.BOLD}>{aiFeatureDetails.name}</Text>
              <Space size={1} />
              <FeatureInfo feature={PricingPlanFeaturesService.PRICING_PLAN_FEATURES.AI_DELIVERY} />
            </div>
            {!hasAiDeliveryFeature && <PremiumFeatureIcon module={aiFeatureDetails.name} />}
          </Row>
          <Space vertical size={2} />
          <GroupedButtons>
            <GroupedButton
              active={!aiSignatureAlbumEnabled}
              onClick={() => setShowConfirmationDialog(AI_DELIVERY_STATES.OFF)}
            >
              <Text>Off</Text>
            </GroupedButton>
            <GroupedButton
              active={aiSignatureAlbumEnabled && !aiSignatureAlbumExpressEnabled}
              onClick={() => setShowConfirmationDialog(AI_DELIVERY_STATES.NORMAL)}
              disabled={!hasAiDeliveryFeature}
            >
              <Text>On</Text>
            </GroupedButton>
            <GroupedButton
              active={aiSignatureAlbumExpressEnabled}
              onClick={() => setShowConfirmationDialog(AI_DELIVERY_STATES.EXPRESS)}
              disabled={!hasAiDeliveryFeature}
            >
              <Text>Express</Text>
            </GroupedButton>
          </GroupedButtons>
        </Space>
        {aiSignatureAlbumEnabled && (
          <FadeIn>
            <Divider />
            <Space>
              <EventUserRegistrationDetailsContainer projectId={projectId} />
            </Space>
          </FadeIn>
        )}
      </Card>
      <AIPhotoDeliveryConfirmationModalContainer
        projectId={projectId}
        onConfirm={handleAIDelivery}
        onHide={() => setShowConfirmationDialog(false)}
        state={showConfirmationDialog}
      />
    </>
  );
}
