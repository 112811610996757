import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { editQRCodeDesigns, qrCodeDesignsSelector } from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodePosterLEDTextColor from './QRCodePosterLEDTextColor';

function mapStateToProps(state, ownProps: { folderId: string; projectId: string }) {
  const { folderId, projectId } = ownProps;
  return {
    folderId,
    projectId,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editLEDPosterTextColor: (
      projectId: string,
      folderId: string,
      qrCodeDesigns: QRCodeBackgroundPosterService.QRCodeDesignType,
      textColor: QRCodeBackgroundPosterService.QRCodeLEDTextColorDataType,
    ) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LED_POSTER_TEXT_COLOR]: textColor,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterLEDTextColor);
