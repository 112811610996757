import { connect } from 'react-redux';
import { ActionConstants } from '@premagic/proposals';
import { LoadingDuck } from '@premagic/common-ducks';
import { FolderService } from '@premagic/core';

import EventProposals from './EventProposals';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import {
  eventProjectSelectorSelector,
  folderIdsWithFolderTypeForProjectSelector,
} from '../../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../../common/Constants';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    focusId?: string;
  },
) {
  const { eventId, focusId } = ownProps;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  const proposalIds = projectId
    ? folderIdsWithFolderTypeForProjectSelector(projectId, FolderService.FOLDER_TYPES.PROPOSAL)(state)
    : [];

  return {
    focusId,
    eventId,
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    proposalIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showAddPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, ActionConstants.KEYS.PROPOSALS.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventProposals);
