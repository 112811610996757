import { connect } from 'react-redux';
import CreateBasicClientWebsitePanel from './CreateBasicClientWebsitePanel';
import { LOADINGS } from '../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { createABasicInviteAndShare } from '../../events/details/EventDetailsPageDuck';
import { clientItemForEventSelector, crmEventItemsSelector } from '../../events/EventsDataDuck';
import { hasExceedProjectLimitSelector } from '../../../settings/company/CompanyDataDuck';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import {
  accountDefaultLanguageSelector,
  isCompanyTypeNotPhotographerSelector,
} from '../../../settings/preferences/ClientSettingsDataDuck';

const COMMON_KEY = LOADINGS.EVENT.CREATE_BASIC_INVITE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
  },
) {
  const { eventId, projectId } = ownProps;
  const folders = foldersEntitiesSelector(state);

  return {
    eventId,
    projectId,
    event: crmEventItemsSelector(state)[eventId] || {},
    client: clientItemForEventSelector(eventId)(state),
    show: isWindowPanelOpenSelector(COMMON_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    hasExceedProjectLimit: hasExceedProjectLimitSelector(state),
    folders,
    langCode: accountDefaultLanguageSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createClientWebsite: (data, langCode) => {
      dispatch(createABasicInviteAndShare(dispatch, data, langCode));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, COMMON_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateBasicClientWebsitePanel);
