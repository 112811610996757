import { Auth, Hub } from 'aws-amplify';

import { getCache, setCache, removeCache } from './LocalCacheUtils';
import { logAction } from './ErrorTracker';
import { getGlobalVariableForPM } from './GlabalVariableServiceBridge';

const CACHE_KEY = '__you_boy_toc';

export function setAuthTokenOnCacheFor3rdPartyLibs(token) {
  setCache(CACHE_KEY, token);
}
export function getAuthTokenOnCacheFor3rdPartyLibs() {
  return getCache(CACHE_KEY);
}
export function clearAuthTokenOnCacheFor3rdPartyLibs() {
  removeCache(CACHE_KEY);
}

export function initListener(listeners: {
  signIn?: (data: unknown) => void;
  signUp?: (data: unknown) => void;
  signOut?: () => void;
  signInFailure?: (data: unknown) => void;
  tokenRefresh?: (data: unknown) => void;
  tokenRefreshFailure?: (data: unknown) => void;
}) {
  Hub.listen('auth', ({ payload: { event, data } }) => {
    // logAction(`AUTH LOG: ${event}`, { message: 'this will be empty' });

    switch (event) {
      case 'signIn':
        return listeners?.signIn && listeners?.signIn(data);
      case 'signUp':
        return listeners?.signUp && listeners?.signUp(data);
      case 'signOut':
        return listeners?.signOut && listeners?.signOut();
      case 'signIn_failure':
        return listeners?.signInFailure && listeners?.signInFailure(data);
      case 'tokenRefresh':
        return listeners?.tokenRefresh && listeners?.tokenRefresh(data);
      case 'tokenRefresh_failure':
        return listeners?.tokenRefreshFailure && listeners?.tokenRefreshFailure(data);
      default:
        break;
    }
  });
}

export function signIn(email = '', password: string) {
  return Auth.signIn(email.trim(), password);
}

export function signOut() {
  return Auth.signOut();
}

export function getAuthUser() {
  return Auth.currentAuthenticatedUser();
}

export function getAuthSession() {
  return Auth.currentSession()
    .then((data) => data)
    .catch((err) => {
      logAction('SESSION', err);
      return undefined;
    });
}

export function changeNewPassword(user, newPassword: string) {
  return Auth.completeNewPassword(user, newPassword);
}

export function changePassword(user, oldPassword, newPassword: string) {
  return Auth.changePassword(user, oldPassword, newPassword);
}

export function requestForForgotPassword(email: string) {
  return Auth.forgotPassword(email);
}

export function setNewPasswordFromForgotPassword(email: string, code: string, newPassword: string) {
  return Auth.forgotPasswordSubmit(email, code, newPassword);
}

function hasTokenExpired(session): boolean {
  const idTokenExpire = session.getIdToken().getExpiration();
  const currentTimeSeconds = Math.round(+new Date() / 1000);
  return idTokenExpire < currentTimeSeconds;
}

export function getAccessToken(session): string {
  // authData.signInUserSession.accessToken.jwtToken
  return session.getAccessToken().getJwtToken();
}

function getRefreshedToken(session): Promise<string | null> {
  return getAuthUser().then((res) => {
    const refreshToken = session.getRefreshToken();
    return res.refreshSession(refreshToken, (err, data) => {
      if (err) {
        return null;
      }
      return data.getIdToken().getJwtToken();
    });
  });
}

export function getAuthToken(): Promise<string | null> {
  return getAuthSession()
    .then((session) => {
      if (!session) return null;

      // When the token expires, we need to refresh it
      if (hasTokenExpired(session)) {
        const newToken = getRefreshedToken(session);
        if (newToken) {
          return newToken;
        }
        // If there is no token then logout
        signOut();
        return null;
      }

      const newToken = getAccessToken(session);
      setAuthTokenOnCacheFor3rdPartyLibs(newToken);
      return newToken;
    })
    .catch((error) => {
      // No logged-in user: don't set auth header
      logAction('TOKEN Request error: ', error);
      return null;
    });
}

export function addAxiosAuthorizationToken(AppAxios) {
  AppAxios.interceptors.request.use((config) =>
    getAuthToken().then((token) => {
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers['Magic-token'] = token;
        // eslint-disable-next-line no-param-reassign
        // config.headers.Authorization = token;
        return config;
      }
      return config;
    }),
  );
}

export function getAccountAuthHeader() {
  if (getGlobalVariableForPM().ACCOUNT_ID) {
    return {
      'X-ACCOUNT-ID': getGlobalVariableForPM().ACCOUNT_ID,
    };
  }

  return {};
}

export function getAccountAuthHeaderWithMagicToken(): Record<string, any> {
  getAuthToken();

  const token = getAuthTokenOnCacheFor3rdPartyLibs();
  if (!token) return {};
  const header = {
    'Magic-token': token,
    // Authorization: token,
  };
  return Object.assign(header, getAccountAuthHeader());
}

export function getClientAuthHeader() {
  if (getCache('MPIN')) return { Authorization: `MPIN ${getCache('MPIN')}` };
  return {};
}
