import { connect } from 'react-redux';
import CompanyProfileHeader from './CompanyProfileHeader';

function mapStateToProps(state, ownProps: { currentUrlPathname: string }) {
  const { currentUrlPathname } = ownProps;

  return {
    currentUrlPathname,
  };
}

export default connect(mapStateToProps)(CompanyProfileHeader);
