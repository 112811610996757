import { connect } from 'react-redux';
import ClientPaymentPresetModal from './ClientPaymentPresetModal';
import { addPaymentInBulkForEvent } from '../CRMPaymentDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { PAYMENT_TYPES } from '../CRMPaymentService';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';

const MODAL_KEY = LOADINGS.CRM_PAYMENTS.SHOW_BULK_ADD_CLIENT_PAYMENTS;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  return {
    eventId: ownProps.eventId,
    isLoading: isLoadingSelector(LOADINGS.CRM_PAYMENTS.BULK_ADD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CRM_PAYMENTS.BULK_ADD)(state),
    isShown: isModalOpenSelector(MODAL_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPaymentInBulk: (eventId: string, data) => {
      dispatch(addPaymentInBulkForEvent(dispatch, eventId, data, PAYMENT_TYPES.INFLOW));
    },
    showAddSingleClientPayment: () => {
      dispatch(toggleModalVisibility(dispatch, MODAL_KEY, false));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CRM_PAYMENTS.SHOW_ADD_CLIENT_PAYMENT_PANEL, true));
    },
    toggleModal: (show) => {
      dispatch(toggleModalVisibility(dispatch, MODAL_KEY, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPaymentPresetModal);
