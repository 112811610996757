import {
  ButtonIcon,
  Col,
  DropdownIcon,
  GeneralEditorHeader,
  ICON_SIZES,
  Icon,
  Row,
  Space,
  Text,
  MenuItemIcon,
  LoadingDots,
  Menu,
  MenuItem,
  Dropdown,
  MENU_ITEM_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogTitle,
  DialogFooter,
  Button,
  BUTTON_STYLES,
} from '@premagic/myne';
import React, { useState } from 'react';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../../../services/AppRouteURLService';
import EventAttendeeBadgeDownloadAllContainer from '../../../../../event-poster-creator/poster-badge/EventAttendeeBadgeDownloadAllContainer';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../../../common/JSClassSelectors';

function ResetDialog(props: { resetData: () => void; closeDialog: () => void }) {
  const { resetData, closeDialog } = props;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.BADGE_CREATOR_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => closeDialog()}
    >
      <DialogTitle>Reset the badge design?</DialogTitle>
      <Text block>Are you sure you want to reset all the design for this badge?</Text>
      <Space vertical size={6} />
      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          style={BUTTON_STYLES.DANGER}
          onClick={() => {
            resetData();
            closeDialog();
          }}
        >
          Reset
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

type Props = {
  isLoading: boolean;
  projectId: string;
  eventId: string;
  navigateTo: (url: string) => void;
  folderId: string;
  resetData: (projectId: string, folderId: string) => void;
};

export default function EventAttendeeBadgeMainLayout(props: Props) {
  const { navigateTo, projectId, folderId, eventId, isLoading, resetData } = props;

  function handleBack() {
    const backUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEES, {
      eventId,
    });
    navigateTo(backUrl);
  }
  const [showResetModal, setShowResetModal] = useState(false);

  return (
    <GeneralEditorHeader>
      <div className="hidden-print">
        <Row vcenter spaceBetween>
          <ButtonIcon title="Back" tooltipPlacement="bottom" onClick={handleBack}>
            <Icon name="arrow_left" size={ICON_SIZES.SM} />
          </ButtonIcon>
          <Text>Badges</Text>

          <Col size={null} rightAlighed>
            <Row vcenter>
              <Space size={1}>
                <EventAttendeeBadgeDownloadAllContainer projectId={projectId} posterId="1" />
              </Space>
              <span className={JS_SELECTORS.BADGE_CREATOR_MORE_OPTIONS}>
                <Dropdown>
                  <DropdownIcon title="More" tooltipPlacement="left">
                    {isLoading ? <LoadingDots size="sm" /> : <Icon name="more_vertical" />}
                  </DropdownIcon>
                  <Menu>
                    <MenuItem onClick={() => setShowResetModal(true)} style={MENU_ITEM_STYLES.DANGER}>
                      <MenuItemIcon name="refresh_ccw" />
                      Reset Badge
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </span>
            </Row>
          </Col>
        </Row>
      </div>
      {showResetModal && (
        <ResetDialog resetData={() => resetData(projectId, folderId)} closeDialog={() => setShowResetModal(false)} />
      )}
    </GeneralEditorHeader>
  );
}
