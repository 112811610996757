import React, { useEffect } from 'react';
import { Text, LoadingSpin, List } from '@premagic/myne';
import EventOccasionListItemContainer from './EventOccasionSummaryListItemContainer';

type Props = {
  eventId: string;
  initData: (eventId: string) => void;
  isLoading: boolean;
  occasionIds: Array<string>;
};

export default function EventOccasionsSummary(props: Props): React.ReactElement {
  const { eventId, occasionIds, isLoading, initData } = props;
  useEffect(() => {
    initData(eventId);
  }, []);

  if (isLoading) {
    return <LoadingSpin />;
  }

  if (occasionIds.length === 0) {
    return <Text muted>You haven&apos;t added any occasion to this event</Text>;
  }
  return (
    <List>
      {occasionIds.map((occasionId) => (
        <EventOccasionListItemContainer eventId={eventId} occasionId={occasionId} key={occasionId} />
      ))}
    </List>
  );
}
