// Docs: https://developers.freshdesk.com/widget-api/
// https://support.freshdesk.com/en/support/solutions/articles/50000000032-pre-populating-hiding-fields-and-other-advanced-functionality-in-the-help-widget
declare function FreshworksWidget(crm: string, cmd2?: string, data?: Record<string, unknown>): void;

export function initHelpWidget(data: { name: string; email?: string; phone: string; company_type?: string }) {
  FreshworksWidget('identify', 'ticketForm', data);
}

export function showHelpWidget() {
  FreshworksWidget('open');
}

export function hideHelpWidget() {
  FreshworksWidget('close');
}

export function showSupportForm() {
  FreshworksWidget('open', 'ticketForm');
}

export function prefillSupportForm(data: { subject: string; description: string }) {
  FreshworksWidget('prefill', 'ticketForm', {
    ...data,
    priority: 1,
    status: 2,
    type: 'Question',
  });
}

export function showArticle(articleId: number) {
  FreshworksWidget('open', 'article', {
    id: articleId,
  });
}
