import React from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  Card,
  Col,
  Icon,
  ICON_SIZES,
  InnerPageContentMainCol,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../services/AppRouteURLService';

import { PERMISSIONS } from '../../users/UserPermissionService';
import MESSAGES from '../../../../../../common/Messages';
import EventPosterListContainer from '../../event-posters/EventPosterListContainer';

type Props = {
  projectId?: string;
  eventId: string;
  focusId?: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_BADGES]: boolean;
  };
  hasFeature: boolean;
  showAddNewBadge: () => void;
};

export default function EventBadgesLayout(props: Props): React.ReactElement {
  const { projectId, hasPermissions, eventId, hasFeature, showAddNewBadge, focusId } = props;

  if (!hasPermissions[PERMISSIONS.EVENT_BADGES]) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.PERMISSION[PERMISSIONS.EVENT_BADGES]}</Text>
      </InnerPageContentMainCol>
    );
  }

  if (!hasFeature) {
    return (
      <InnerPageContentMainCol>
        <Text>{MESSAGES.EVENT_ATTENDEES_BADGES.UPGRADE}</Text>
      </InnerPageContentMainCol>
    );
  }

  if (!projectId) return <InnerPageContentMainCol>There is no project here</InnerPageContentMainCol>;
  const type = EventPosterService.EVENT_POSTER_TYPE.BADGE;
  const typeDetails = EventPosterService.EVENT_POSTER_TYPE_DETAILS[type];
  const backToUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEES, {
    eventId,
  });
  return (
    <InnerPageContentMainCol hasSidebar>
      <Row alignItems="baseline">
        <ButtonIcon title="Back" link={backToUrl}>
          <Icon name="arrow_left" size={ICON_SIZES.SM} />
        </ButtonIcon>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
          {typeDetails.title}s
        </Text>
        <Col rightAlighed size={null}>
          <Button style={BUTTON_STYLES.PRIMARY} onClick={showAddNewBadge} size={BUTTON_SIZE.SM}>
            <Icon name="plus" size={ICON_SIZES.SM} /> Badge
          </Button>
        </Col>
      </Row>
      <Space vertical />
      <Card>
        <EventPosterListContainer eventId={eventId} projectId={projectId} focusId={focusId} type={type} />
      </Card>
    </InnerPageContentMainCol>
  );
}
