import { connect } from 'react-redux';
import { setEditOfAttendee, attendeeIdForEditSelector } from '../EventAttendeesPageDuck';
import EditEventAttendeePanel from './EditEventAttendeePanel';
import { eventAttendeesSelector, updateEventAttendee } from '../EventAttendeesDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../common/WindowPanelDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import { attendeeRegistrationSendNotificationRequiredSelector } from '../../../../projects/project-meta/ProjectsMetaDataDuck';

const PANEL_KEY = LOADINGS.EVENT_ATTENDEES.SHOW_EDIT_PANEL;
const COMMON_KEY = LOADINGS.EVENT_ATTENDEES.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const attendeeId = attendeeIdForEditSelector(state);

  return {
    projectId,
    attendeeId,
    attendee: attendeeId ? eventAttendeesSelector(state)[attendeeId] : undefined,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
    attendeeRegistrationSendNotificationRequired:
      attendeeRegistrationSendNotificationRequiredSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAttendee: (options, data) => {
      dispatch(updateEventAttendee(dispatch, options, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    cleanup: () => {
      dispatch(setEditOfAttendee(dispatch, null));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEventAttendeePanel);
