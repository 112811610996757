import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { normalize } from 'normalizr';
import { ActionTypeUtils, ErrorTracker, ArrayUtils } from '@premagic/utils';
import { EventsService } from '@premagic/core';

import { calendarAndEventsPageSelector } from '../../../../reducers/selectors';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { EventListEventsSchema } from '../../../../../schema/Schemas';
import { EventsListEventType, fetchEventCountByStatus, fetchEventsForList } from '../EventsListService';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENTS_LIST_EVENTS');

export const setListEvents = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const setTotalEventCountData = createAction(getActionType('META_INFO', 'SET'), (dispatch, data) => data);
const setEventsCountByStatusData = createAction(getActionType('COUNT_BY_STATUS', 'SET'), (dispatch, data) => data);

export const fetchListEventsData = createAction(getActionType('DATA', 'FETCH'), async (dispatch, filters) => {
  const loadingKey = LOADINGS.CRM.LIST_EVENTS_FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  try {
    const events = await fetchEventsForList(filters);
    const normalizedData = normalize(events.results, EventListEventsSchema);
    const normalizedEvents = normalizedData.entities.events;
    dispatch(setTotalEventCountData(dispatch, events.count));
    dispatch(setListEvents(dispatch, normalizedEvents));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
  } catch (e) {
    dispatch(setErrorState(dispatch, loadingKey, e));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    ErrorTracker.logError('EventsList: fetch failed', e);
  }
});

export const fetchCountByEventStatus = createAction(getActionType('DATA_COUNT', 'FETCH'), async (dispatch) => {
  const loadingKey = LOADINGS.CRM.LIST_EVENTS_COUNT_FETCH;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));
  try {
    const count = await fetchEventCountByStatus();
    dispatch(setEventsCountByStatusData(dispatch, count));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
  } catch (e) {
    dispatch(setErrorState(dispatch, loadingKey, e));
    dispatch(toggleLoadingState(dispatch, loadingKey, false));
    ErrorTracker.logError('EventsCounts: fetch failed', e);
  }
});

type EventsListEventsType = IMap<string, EventsListEventType>;
type StateType = {
  items: EventsListEventsType;
  count: number;
  countByStatus: Array<{ status: EventsService.EVENT_STATUS; total: number }>;
};

const initialState = {
  items: IMap({}),
  count: 0,
  countByStatus: [],
};

export default handleActions(
  {
    [setListEvents.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [setTotalEventCountData.toString()]: (state, action: { payload }) => ({
      ...state,
      count: action.payload,
    }),
    [setEventsCountByStatusData.toString()]: (state, action: { payload }) => ({
      ...state,
      countByStatus: action.payload,
    }),
  },
  initialState,
);

export const eventsListDataSelector = createSelector(
  calendarAndEventsPageSelector,
  (calendarAndEvents) => calendarAndEvents.data.events as StateType,
);

export const eventsListItemsSelector = createSelector(eventsListDataSelector, (state) => state.items.toJSON());

export const eventsListEventIdsSelector = (sortBy: EventsService.EVENT_SORT_BY) =>
  createSelector(eventsListDataSelector, (state) => {
    if (sortBy === EventsService.EVENT_SORT_BY.PRIORITY_VALUE) {
      return state.items
        .sort((a, b) => a.priority_value - b.priority_value)
        .keySeq()
        .toArray();
    }

    return state.items
      .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.created_at, b.created_at, true))
      .keySeq()
      .toArray();
  });

export const eventsListEventCountSelector = createSelector(eventsListDataSelector, (state) => state.count);
export const eventsCountByStatusSelector = createSelector(eventsListDataSelector, (state) =>
  state.countByStatus.reduce((result, item) => ({ ...result, [item.status]: item.total }), {
    [EventsService.EVENT_STATUS.NEW]: 0,
    [EventsService.EVENT_STATUS.QUALIFIED]: 0,
    [EventsService.EVENT_STATUS.CONFIRMED]: 0,
    [EventsService.EVENT_STATUS.REJECTED]: 0,
  }),
);
