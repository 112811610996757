import { connect } from 'react-redux';
import { isStaffUserSelector } from '../../crm/users/UsersDataDuck';
import CompanyStatsCard from './CompanyStatsCard';

function mapStateToProps(state) {
  return {
    isStaffUser: isStaffUserSelector(state),
  };
}

export default connect(mapStateToProps)(CompanyStatsCard);
