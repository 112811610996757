import * as React from 'react';
import ReactSlider from 'react-slick';
import ClassNames from 'classnames';

import styles from './slider.module.css';

export enum SLIDER_STYLES {
  DEFAULT = 'default',
  STRIP_SLIDER = 'strip-slider',
}

function ArrowButton(props: { direction: 'left' | 'right' }) {
  const { direction } = props;
  return <div data-direction={direction} />;
}
const sliderOptions = {
  dots: false,
  infinite: true,
  speed: 1200,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  autoplay: true,
  arrows: false,
  nextArrow: <ArrowButton direction="left" />,
  prevArrow: <ArrowButton direction="right" />,
  // appendDots: (dots: React.ReactNode) => <ul>{dots}</ul>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function getSliderOptions(style: SLIDER_STYLES) {
  switch (style) {
    case SLIDER_STYLES.STRIP_SLIDER:
      return {
        ...sliderOptions,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 2,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    case SLIDER_STYLES.DEFAULT:
    default:
      return sliderOptions;
  }
}

type SliderProps = {
  children: React.ReactNode;
  style?: SLIDER_STYLES;
  beforeChange?: (current: number, next: number) => void;
};

export const Slider = React.forwardRef((props: SliderProps, ref) => {
  const { children, beforeChange, style = SLIDER_STYLES.DEFAULT } = props;
  const options = getSliderOptions(style);

  return (
    <div className={ClassNames(styles.slider)}>
      <ReactSlider ref={ref} {...options} beforeChange={beforeChange}>
        {children}
      </ReactSlider>
    </div>
  );
});
