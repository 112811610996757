import { HttpUtils } from '@premagic/utils';
import { APIURLService } from '@premagic/core';

const APIS = APIURLService.API_URLS.STORE;
const MOCK = true;

export enum STORE_VENDORS {
  CANVERA = 'Canvera',
  SHUTTERFLY = 'Shutterfly',
  VIDEOLINKS = 'VideoLinks',
}
export enum STORE_TAGS {
  PRINT_ALBUMS = 'Print Albums',
  PHOTO_PRINTING = 'PHOTO_PRINTING',
}

export type StoreOfferItemType = {
  position: number;
  id: string;
  created_at: string;
  expiry_date: string;
  tags: Array<STORE_TAGS>;
  vendor: STORE_VENDORS;
  title: string;
  description: string;
  images: Array<string>;
  cta_url: string;
};

const STORES_ITEMS: Array<StoreOfferItemType> = [
  {
    position: 1,
    id: '1',
    title: '15% off - The Vintage Wood Collection',
    description: `<p>The Vintage Wood Collection signifies attention to detail. A carefully designed wooden box that brings back the best of old-world charm while standing testimony to what innovation and craftsmanship can create.</p>
    <h3>Features</h3>
    <ol>
      <li>Adorned with leather elbow patches.</li>
      <li>Available in two types of wooden covers along with a matching wooden case.</li>
      <li>Box to protect your photobook from airborne pollutants, light, and dirt.</li>
    </ol>`,
    images: [
      'https://asts.premagic.com/s/offers/canvera/Vintage%20wood/slide%201.jpg',
      'https://asts.premagic.com/s/offers/canvera/Vintage%20wood/slide2.jpg',
      'https://asts.premagic.com/s/offers/canvera/Vintage%20wood/slide3.jpg',
      'https://asts.premagic.com/s/offers/canvera/Vintage%20wood/slide4.jpg',
      'https://asts.premagic.com/s/offers/canvera/Vintage%20wood/slide5.jpg',
    ],
    created_at: '2022-10-01T00:00:00.000Z',
    expiry_date: '2023-02-28T00:00:00.000Z',
    tags: [STORE_TAGS.PRINT_ALBUMS],
    vendor: STORE_VENDORS.CANVERA,
    cta_url: 'https://bit.ly/3fvnKg2',
  },
  {
    position: 2,
    id: '2',
    title: '15% off - The Plush Leather Collection',
    description: `<p>With a detailed contrast etching on the cover pad and a simple yet aesthetic encasing, these leatherette photobooks are elegant, unique, and well crafted.</p>
    <h3>Features</h3>
    <ol>
      <li>Plush leather is crafted with premium leather.</li>
        <li>Available in exorbitant colours like Stone Grey, Camel Tan, Royal Beige, Princess Ivory, and Robusta Coffee Brown.</li>
    </ol>`,
    images: [
      'https://asts.premagic.com/s/offers/canvera/Plush%20leather/Slide1.jpg',
      'https://asts.premagic.com/s/offers/canvera/Plush%20leather/Slide2.jpg',
      'https://asts.premagic.com/s/offers/canvera/Plush%20leather/Slide3.jpg',
      'https://asts.premagic.com/s/offers/canvera/Plush%20leather/Slide4.jpg',
      'https://asts.premagic.com/s/offers/canvera/Plush%20leather/Slide5.jpg',
    ],
    created_at: '2022-10-01T00:00:00.000Z',
    expiry_date: '2023-02-28T00:00:00.000Z',
    tags: [STORE_TAGS.PRINT_ALBUMS],
    vendor: STORE_VENDORS.CANVERA,
    cta_url: 'https://bit.ly/3fvnKg2',
  },
  {
    position: 3,
    id: '3',
    title: '15% off - The Mesmera',
    description: `<p>Mesmera Photobook is the right blend of premium leather and acrylic artistry, making it the standing paradigm of elegance interlaced with today's craftsmanship.</p>
    <h3>Features</h3>
    <ol>
        <li>Crafted from plush leather material with name styles intricately etched on the cover pad.</li>
        <li>Sports a large acrylic image panel.</li>
        <li>Finely buffed edges.</li>
        <li>8 scintillating colour options: Yellow Ochre, Forest Green, Winsor Blue, Sand Pink, Camel Tan, Royal Beige, Robusta Coffee Brown, and Princess Ivory.</li>
    </ol>`,
    images: [
      'https://asts.premagic.com/s/offers/canvera/Mesmera/slide1.jpg',
      'https://asts.premagic.com/s/offers/canvera/Mesmera/slide2.jpg',
      'https://asts.premagic.com/s/offers/canvera/Mesmera/slide3.jpg',
      'https://asts.premagic.com/s/offers/canvera/Mesmera/slide4.jpg',
      'https://asts.premagic.com/s/offers/canvera/Mesmera/slide5.jpg',
    ],
    created_at: '2022-10-01T00:00:00.000Z',
    expiry_date: '2023-02-28T00:00:00.000Z',
    tags: [STORE_TAGS.PRINT_ALBUMS],
    vendor: STORE_VENDORS.CANVERA,
    cta_url: 'https://bit.ly/3fvnKg2',
  },
  {
    position: 4,
    id: '4',
    title: '15% off - The Royalty Photobook',
    description: `<p>Finesse, grandeur, and glamour, all rolled into one. Royalty as the name suggests is tailor-made to match the royal Indian Wedding themes.</p>
    <h3>Features</h3>
  <ol>
    <li>A creative blend of two variant luxury material colours.</li>
    <li>Available in 18 Luxury colours.</li>
    <li>A  metallic acrylic image with 6 pattern options.</li>
    <li>A specially designed gold metal nameplate that compliments the pattern is available.</li>
  </ol>
`,
    images: [
      'https://asts.premagic.com/s/offers/canvera/Royalty/slide%201.jpg',
      'https://asts.premagic.com/s/offers/canvera/Royalty/slide2.jpg',
      'https://asts.premagic.com/s/offers/canvera/Royalty/slide3.jpg',
      'https://asts.premagic.com/s/offers/canvera/Royalty/slide4.jpg',
      'https://asts.premagic.com/s/offers/canvera/Royalty/slide5.jpg',
    ],
    created_at: '2022-10-01T00:00:00.000Z',
    expiry_date: '2023-02-28T00:00:00.000Z',
    tags: [STORE_TAGS.PRINT_ALBUMS],
    vendor: STORE_VENDORS.CANVERA,
    cta_url: 'https://bit.ly/3fvnKg2',
  },
  {
    position: 4,
    id: '5',
    title: '15% off - The Iris: Gallery',
    description: `<p>Iris is handcrafted with PU leather across the cover pad, box and bag with a high-resolution image displayed inside the box giving it an immersive unboxing experience.</p>
    <h3>Features</h3>
    <ol>
      <li>The cover pad, box and bag of the photobook are crafted in matching PU leather material.</li>
      <li>4 UV-printed name style options printed on the cover pad of the photobook and the box.</li>
      <li>Available in 3 vibrant colour options: Pansy Red, Pastel Blue and Rusty Orange.</li>
    </ol>
`,
    images: [
      'https://asts.premagic.com/s/offers/canvera/Iris/slide1.jpg',
      'https://asts.premagic.com/s/offers/canvera/Iris/slide2.jpg',
      'https://asts.premagic.com/s/offers/canvera/Iris/slide3.jpg',
      'https://asts.premagic.com/s/offers/canvera/Iris/slide4.jpg',
      'https://asts.premagic.com/s/offers/canvera/Iris/slide5.jpg',
    ],
    created_at: '2022-10-01T00:00:00.000Z',
    expiry_date: '2023-02-28T00:00:00.000Z',
    tags: [STORE_TAGS.PRINT_ALBUMS],
    vendor: STORE_VENDORS.CANVERA,
    cta_url: 'https://bit.ly/3fvnKg2',
  },
];

export function fetchStoreOffers(): Promise<{
  results: Array<StoreOfferItemType>;
  count: number;
}> {
  if (MOCK) {
    return new Promise((resolve) => {
      resolve({
        results: STORES_ITEMS,
        count: STORES_ITEMS.length,
      });
    });
  }
  return HttpUtils.get(APIS.LIST, {
    params: { limit: 100 },
  }).then((response) => response.data);
}
