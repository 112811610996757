import { QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import {
  qrCodeDesignsNumberOfActiveLogoSelector,
  qrCodeDesignsSelector,
} from '../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeBackgroundPoster from './QRCodeBackgroundPoster';

function mapStateToProps(
  state,
  ownProps: {
    folderId?: string;
    posterId: QRCodeBackgroundPosterService.QR_CODE_POSTER_IDS;
    colors?: QRCodeBackgroundPosterService.PosterColorType;
    posterType?: 'standee' | 'pocket-card-front' | 'pocket-card-back' | 'tent-card' | 'led-screen';
  },
) {
  const { posterId, colors, folderId = '', posterType } = ownProps;

  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);

  return {
    posterItem: QRCodeBackgroundPosterService.QR_CODE_POSTERS[posterId],
    colors,
    numberOfActiveLogos: qrCodeDesignsNumberOfActiveLogoSelector(folderId)(state),
    selectedLogoType: qrCodeDesigns?.logo_option_type,
    posterType,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeBackgroundPoster);
