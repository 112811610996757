import { EventAttendeesService, EventPosterService } from '@premagic/core';
import { FormGroup, FormLabel, Input, INPUT_SIZES, Row, Select, Space } from '@premagic/myne';
import {
  POSTER_MEDIA_TYPE,
  POSTER_POST_FORMAT,
  POSTER_POST_FORMAT_DETAILS,
  POSTER_SIZE,
  POSTER_SIZE_DETAIL,
  POSTER_SIZE_TYPES,
} from '@premagic/poster-creator';
import { useMemo, useState } from 'react';

const STATUS_OPTIONS = [
  {
    value: EventPosterService.EVENT_POSTER_STATUS.PUBLISHED,
    label: 'Yes',
  },
  {
    value: EventPosterService.EVENT_POSTER_STATUS.DRAFT,
    label: 'No',
  },
];

const EVENT_POSTER_SCOPE_OPTIONS = Object.entries(EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS).map(
  ([key, value]) => ({
    value: key,
    label: value.name,
  }),
) as Array<{
  value: EventAttendeesService.EVENT_PEOPLE_TYPE;
  label: string;
}>;

const POSTER_TYPE_TO_POSTER_SIZE_TYPE_MAP: Record<EventPosterService.EVENT_POSTER_TYPE, POSTER_SIZE_TYPES> = {
  [EventPosterService.EVENT_POSTER_TYPE.BADGE]: POSTER_SIZE_TYPES.BADGE,
  [EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT]: POSTER_SIZE_TYPES.SOCIAL,
  [EventPosterService.EVENT_POSTER_TYPE.POST_EVENT]: POSTER_SIZE_TYPES.SOCIAL,
  [EventPosterService.EVENT_POSTER_TYPE.MAGAZINE]: POSTER_SIZE_TYPES.MAGAZINE,
  [EventPosterService.EVENT_POSTER_TYPE.DURING_EVENT]: POSTER_SIZE_TYPES.SOCIAL,
};

function PosterSizeSelector(props: {
  size: { width: number; height: number };
  type: EventPosterService.EVENT_POSTER_TYPE;
}) {
  const { size, type } = props;

  const posterSizeOptions = useMemo(() => {
    const posterType = POSTER_TYPE_TO_POSTER_SIZE_TYPE_MAP[type];

    return Object.entries(POSTER_SIZE_DETAIL)
      .filter(([, value]) => value.type.includes(posterType))
      .map(([key, value]) => ({
        value: key,
        label: value.name,
        info: `${Math.floor(value.width)}x${Math.floor(value.height)}`,
      })) as Array<{
      value: POSTER_SIZE;
      label: string;
      info: string;
    }>;
  }, [type]);

  const [selectedOption, setSelectedOption] = useState<POSTER_SIZE | undefined>(() => {
    if (size)
      return (
        posterSizeOptions.find((option) => option.info === `${size.width}x${size.height}`)?.value || POSTER_SIZE.CUSTOM
      );
    return posterSizeOptions[0].value;
  });

  const sizeValue = POSTER_SIZE_DETAIL[selectedOption || POSTER_SIZE.A6];
  return (
    <FormGroup>
      <FormLabel isRequired>Size</FormLabel>
      <Select
        name=""
        size={INPUT_SIZES.SM}
        options={posterSizeOptions}
        isMulti={false}
        onChange={(option) => option?.value && setSelectedOption(option.value as POSTER_SIZE)}
        value={posterSizeOptions.find((option) => option.value === selectedOption)}
      />
      {selectedOption === POSTER_SIZE.CUSTOM ? (
        <div>
          <Space vertical />
          <Row>
            <FormGroup>
              <FormLabel>Width</FormLabel>
              <Input
                type="number"
                name="size[width]"
                required
                defaultValue={size.width || sizeValue.width}
                size={INPUT_SIZES.XS}
              />
            </FormGroup>
            <Space />
            <FormGroup>
              <FormLabel>Height</FormLabel>
              <Input
                type="number"
                name="size[height]"
                required
                defaultValue={size.height || sizeValue.height}
                size={INPUT_SIZES.XS}
              />
            </FormGroup>
          </Row>
        </div>
      ) : (
        <div>
          <input type="hidden" name="size[width]" value={sizeValue.width} />
          <input type="hidden" name="size[height]" value={sizeValue.height} />
        </div>
      )}
    </FormGroup>
  );
}

function PosterPostFormatSelector(props: { defaultValue?: POSTER_POST_FORMAT }) {
  const { defaultValue } = props;
  const postFormatOptions = Object.values(POSTER_POST_FORMAT_DETAILS);
  return (
    <FormGroup>
      <FormLabel isRequired>Linkedin Post format</FormLabel>
      <Select
        name="poster_format"
        size={INPUT_SIZES.MD}
        options={postFormatOptions}
        isMulti={false}
        value={postFormatOptions.find((option) => option.value === defaultValue)}
      />
    </FormGroup>
  );
}

type Props = {
  projectId: string;
  posterId?: string;
  poster?: EventPosterService.EventPosterType;
  type: EventPosterService.EVENT_POSTER_TYPE;
};

export default function EventPosterForm(props: Props) {
  const { poster, posterId, projectId, type } = props;
  const defaultScope = [EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE];
  const selectedScope = EVENT_POSTER_SCOPE_OPTIONS.find((option) =>
    (poster?.scope || defaultScope).includes(option.value),
  );
  const isBadge = type === EventPosterService.EVENT_POSTER_TYPE.BADGE;
  const isAdvocacyPoster = type === EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT;
  const posterSize = isBadge ? POSTER_SIZE_DETAIL[POSTER_SIZE.A6] : POSTER_SIZE_DETAIL[POSTER_SIZE.LANDSCAPE];
  const placeholder = isBadge
    ? {
        name: 'eg. Speaker Badge',
      }
    : {
        name: 'eg. Join us',
      };

  return (
    <div>
      <input type="hidden" name="media" value={poster?.media || POSTER_MEDIA_TYPE.IMAGE} />
      <input type="hidden" name="type" value={type} />

      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input name="name" autoFocus required placeholder={placeholder.name} defaultValue={poster?.name} />
      </FormGroup>
      <FormGroup>
        <FormLabel isRequired>Applicable for</FormLabel>
        <Select
          name="scope"
          size={INPUT_SIZES.SM}
          options={EVENT_POSTER_SCOPE_OPTIONS}
          isMulti={false}
          value={selectedScope}
        />
      </FormGroup>
      <PosterSizeSelector size={poster?.size || posterSize} type={type} />
      {isAdvocacyPoster && (
        <div>
          <FormGroup>
            <FormLabel info="This URL will be linked to all the posters created by your attendee. It can include tracking codes(UTM parameters) to help you measure how many people click on it.">
              Event URL
            </FormLabel>
            <Input
              name="registration_link"
              placeholder="eg. https://io.google/2024/"
              defaultValue={poster?.registration_link}
              type="url"
            />
          </FormGroup>
          <PosterPostFormatSelector
            defaultValue={poster?.poster_format || POSTER_POST_FORMAT.CONTENT_WITH_IMAGE_LINK}
          />
        </div>
      )}

      {posterId ? (
        <FormGroup>
          <FormLabel isRequired>Enabled</FormLabel>
          <Select
            name="status"
            size={INPUT_SIZES.SM}
            options={STATUS_OPTIONS}
            isMulti={false}
            value={STATUS_OPTIONS.find((option) => option.value === poster?.status)}
          />
        </FormGroup>
      ) : (
        <input type="hidden" name="status" value={EventPosterService.EVENT_POSTER_STATUS.PUBLISHED} />
      )}
    </div>
  );
}
