import React from 'react';
import { Button, BUTTON_STYLES, Form, FormErrorType, FormFooter, Modal, MODAL_SIZES, ModalTitle } from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';
import SocialMessageFormContainer from './SocialMessageFormContainer';

type Props = {
  projectId: string;
  show: boolean;
  isLoading?: boolean;
  socialShareMessages?: ProjectMetaService.SocialShareMessagesType;
  socialMessageItemForEdit?: {
    id: string;
    order: number;
    message: string;
    hashtags: string[];
  };
  updateProjectMeta: (projectId: string, data) => void;
  error: FormErrorType;
  messageIdToEdit?: string;
  closeEdit: (id) => void;
};

export default function EditSocialMessageModal(props: Props): JSX.Element | null {
  const {
    show,
    socialMessageItemForEdit,
    projectId,
    isLoading,
    updateProjectMeta,
    socialShareMessages,
    error,
    messageIdToEdit,
    closeEdit,
  } = props;

  function handleFormSubmit(formData) {
    if (!messageIdToEdit) return;

    const dataToPost = {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SOCIAL_SHARE_MESSAGES]: {
          ...socialShareMessages,
          [messageIdToEdit]: {
            ...socialMessageItemForEdit,
            message: formData.data.message,
            hashtags: typeof formData.data.hashtags === 'object' ? formData.data.hashtags : [formData.data.hashtags],
            people_type:
              typeof formData.data.people_type === 'object' ? formData.data.people_type : [formData.data.people_type],
          },
        },
      },
    };
    updateProjectMeta(projectId, dataToPost);
    closeEdit(messageIdToEdit);
  }

  if (!messageIdToEdit) return null;

  return (
    <Modal show={show} close={() => closeEdit(messageIdToEdit)} size={MODAL_SIZES.MD}>
      <ModalTitle>Edit Social Share Message</ModalTitle>

      <Form onSubmit={handleFormSubmit} errors={error}>
        <SocialMessageFormContainer projectId={projectId} id={messageIdToEdit} />
        <FormFooter>
          <Button onClick={() => closeEdit(messageIdToEdit)} style={BUTTON_STYLES.LINK}>
            Cancel
          </Button>
          <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
            Save
          </Button>
        </FormFooter>
      </Form>
    </Modal>
  );
}
