import React from 'react';
import { BrowserUrlUtils } from '@premagic/utils';
import {
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  COLOR_SHADES,
  Divider,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  InnerPageHeader,
  InnerPageHeaderFloatingTag,
  InnerPageSubHeader,
  Menu,
  MenuItem,
  MenuItemIcon,
  Row,
  Space,
  TabItem,
  Tabs,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ClientsService, EventsCategoryService, EventsService } from '@premagic/core';

import APP_URLS from '../../../../../services/AppRouteURLService';
import EventDeleteMenuItemContainer from './EventDeleteDialogContainer';
import { JS_SELECTORS } from '../../../../../../../common/JSClassSelectors';
import EventStatusSelectorContainer from './EventStatusSelectorButtonContainer';
import { getDefaultCRMEventListUrlWithFilters } from '../../../filters/CRMFilterService';
import ConvertToProjectButtonContainer from '../../../event-project/ConvertToProjectButtonContainer';
import EventTagNumber from '../EventTagNumber';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import MESSAGES from '../../../../../../../common/Messages';
import EventLabelSelectorContainer from './EventLabelSelectorContainer';
import EventPrioritySelectorContainer from './EventPrioritySelectorContainer';

type Props = {
  eventId: string;
  isLoading: boolean;
  currentUrlPathname: string;
  event: EventsService.EventType;
  client?: ClientsService.ClientType;
  showDeleteDialog: () => void;
  hasPermissions: Partial<Record<PERMISSIONS, boolean>>;
  isMobileUp: boolean;
  showEventSponsors: boolean;
  showEventAttendees: boolean;
  isFeatureAvatarStudioEnabled: boolean;
  isCompanyTypeNotPhotographer: boolean;
  isFeatureFinancialsEnabled: boolean;
  isFeatureEventsOccasionsEnabled: boolean;
  isFeatureLeadEnabled: boolean;
  isFeatureAddEventPosterEnabled: boolean;
  showEditNamePanel: () => void;
  isFeatureMagazineEnabled: boolean;
  showEventExhibitors: boolean;
};

export default function EventDetailsHeader(props: Props): React.ReactElement {
  const {
    eventId,
    isLoading,
    currentUrlPathname,
    event,
    client,
    showDeleteDialog,
    hasPermissions,
    isMobileUp,
    showEventSponsors,
    showEventAttendees,
    showEventExhibitors,
    isCompanyTypeNotPhotographer,
    isFeatureFinancialsEnabled,
    isFeatureEventsOccasionsEnabled,
    isFeatureLeadEnabled,
    isFeatureAddEventPosterEnabled,
    isFeatureAvatarStudioEnabled,
    isFeatureMagazineEnabled,
    showEditNamePanel,
  } = props;
  const { name: eventName, number: eventNumber } = event || {};
  const { name: clientName } = client || {};

  const urlForDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MICRO_SITE, {
    eventId,
  });
  const urlForMarketing = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__MARKETING, {
    eventId,
  });
  const urlForOccasions = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__OCCASIONS, {
    eventId,
  });
  const urlForDeliverable = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
    eventId,
  });
  const urlForFinancial = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__FINANCIAL, {
    eventId,
  });
  const urlForSponsors = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__SPONSORS, {
    eventId,
  });

  const urlForExhibitors = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__EXHIBITOR, {
    eventId,
  });

  const urlForInsight = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__REPORT, {
    eventId,
  });
  const urlForAttendees = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__ATTENDEES, {
    eventId,
  });
  const urlForSettings = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__SETTINGS, {
    eventId,
  });
  const urlForEventEmbed = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__EMBED, {
    eventId,
  });
  const urlForAvatarStudio = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__AVATAR_STUDIO, {
    eventId,
  });
  const urlForMagazine = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__POSTERS, {
    eventId,
  });
  const eventCategoryDetails = EventsCategoryService.getEventCategoryDetails(eventName);
  const nameToShow = eventName in EventsService.EVENT_CATEGORY ? clientName : eventName;
  const pageTitle = `${nameToShow || 'Loading...'} - ${eventCategoryDetails.title || ''}`;
  const pageIcon = isCompanyTypeNotPhotographer ? 'corporate' : eventCategoryDetails.icon;
  if (isLoading) {
    return (
      <InnerPageHeader iconName={pageIcon} title={pageTitle}>
        <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
          Loading...
        </Text>
      </InnerPageHeader>
    );
  }

  return (
    <div>
      <InnerPageHeaderFloatingTag>
        <EventTagNumber eventNumber={eventNumber} />
      </InnerPageHeaderFloatingTag>
      <InnerPageHeader
        iconName={pageIcon}
        title={pageTitle}
        backTo={getDefaultCRMEventListUrlWithFilters({
          isFeatureLeadEnabled,
        })}
        backToTitle="Back to list"
        rightActions={
          isMobileUp && (
            <Row vcenter>
              <EventPrioritySelectorContainer eventId={eventId} />
              {isFeatureLeadEnabled && (
                <>
                  <Space size={4} />
                  <EventStatusSelectorContainer eventId={eventId} />
                  <Space size={6} />
                </>
              )}
              <span className={JS_SELECTORS.EVENTS_MORE_OPTIONS}>
                <Dropdown>
                  <DropdownIcon title="More" tooltipPlacement="left">
                    <Icon name="more_vertical" />
                  </DropdownIcon>
                  <Menu>
                    <MenuItem
                      link={urlForEventEmbed}
                      disabled={!hasPermissions[PERMISSIONS.EVENT_SETTINGS]}
                      disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_SETTINGS]}
                    >
                      <MenuItemIcon name="embed" />
                      Embed on website
                    </MenuItem>
                    <MenuItem
                      link={urlForSettings}
                      disabled={!hasPermissions[PERMISSIONS.EVENT_SETTINGS]}
                      disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_SETTINGS]}
                    >
                      <MenuItemIcon name="settings" />
                      Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={showDeleteDialog}
                      disabled={!hasPermissions[PERMISSIONS.EVENT_MANAGEMENT]}
                      disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_MANAGEMENT]}
                    >
                      <MenuItemIcon name="trash" />
                      Delete
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </span>
            </Row>
          )
        }
        subHeader={<EventLabelSelectorContainer eventId={eventId} />}
      >
        <Row vcenter>
          <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
            {nameToShow}
            <Space size={2} />
            <ButtonIcon
              title="edit"
              onClick={showEditNamePanel}
              size={BUTTON_ICONS_SIZES.MD}
              disabled={!hasPermissions[PERMISSIONS.EVENT_MANAGEMENT]}
              disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_MANAGEMENT]}
            >
              <Icon name="edit_2" size={ICON_SIZES.SM} />
            </ButtonIcon>
          </Text>
          <Space />
          <ConvertToProjectButtonContainer eventId={eventId} />
        </Row>
      </InnerPageHeader>
      <InnerPageSubHeader>
        {!isMobileUp && isFeatureLeadEnabled && <EventStatusSelectorContainer eventId={eventId} />}
        <Tabs>
          {hasPermissions[PERMISSIONS.EVENT_DETAILS] && (
            <TabItem to={urlForDetails} active={currentUrlPathname.includes(urlForDetails)}>
              Micro-site
            </TabItem>
          )}
          {isFeatureAddEventPosterEnabled && (
            <TabItem to={urlForMarketing} active={currentUrlPathname.includes(urlForMarketing)}>
              Advocacy Posters
            </TabItem>
          )}
          {showEventAttendees && hasPermissions[PERMISSIONS.EVENT_ATTENDEES] && (
            <TabItem to={urlForAttendees} active={currentUrlPathname.includes(urlForAttendees)}>
              Attendees
            </TabItem>
          )}
          {hasPermissions[PERMISSIONS.EVENT_DELIVERABLE] && (
            <TabItem
              to={urlForDeliverable}
              active={currentUrlPathname.includes(urlForDeliverable)}
              className={JS_SELECTORS.DELIVERABLES_TAB}
            >
              {isCompanyTypeNotPhotographer ? 'Photos' : 'Deliverable'}
            </TabItem>
          )}
          {isFeatureAvatarStudioEnabled && (
            <TabItem to={urlForAvatarStudio} active={currentUrlPathname.includes(urlForAvatarStudio)}>
              Avatar Studio
            </TabItem>
          )}
          {isFeatureMagazineEnabled && (
            <TabItem to={urlForMagazine} active={currentUrlPathname.includes(urlForMagazine)}>
              Magazine
            </TabItem>
          )}
          {showEventSponsors && hasPermissions[PERMISSIONS.EVENT_SPONSOR] && (
            <TabItem to={urlForSponsors} active={currentUrlPathname.includes(urlForSponsors)}>
              Sponsors
            </TabItem>
          )}
          {showEventExhibitors && hasPermissions[PERMISSIONS.EVENT_EXHIBITOR] && (
            <TabItem to={urlForExhibitors} active={currentUrlPathname.includes(urlForExhibitors)}>
              Exhibitors
            </TabItem>
          )}
          {hasPermissions[PERMISSIONS.EVENT_SPONSOR] && (
            <TabItem to={urlForInsight} active={currentUrlPathname.includes(urlForInsight)}>
              Insights
            </TabItem>
          )}
          {isFeatureEventsOccasionsEnabled && hasPermissions[PERMISSIONS.EVENT_OCCASION] && (
            <TabItem to={urlForOccasions} active={currentUrlPathname.includes(urlForOccasions)}>
              Occasions
            </TabItem>
          )}
          {hasPermissions[PERMISSIONS.EVENT_FINANCIAL] && isFeatureFinancialsEnabled && (
            <TabItem to={urlForFinancial} active={currentUrlPathname.includes(urlForFinancial)}>
              Financials
            </TabItem>
          )}
        </Tabs>
      </InnerPageSubHeader>

      {hasPermissions[PERMISSIONS.EVENT_MANAGEMENT] && <EventDeleteMenuItemContainer eventId={eventId} />}
    </div>
  );
}
