import { connect } from 'react-redux';

import StorePricingPage from './StorePricingPage';

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location;
  return {
    currentUrlPathname: pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePricingPage);
