import { FolderService } from '@premagic/core';
import {
  ALERT_SIZES,
  ALERT_STYLES,
  Alert,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Button,
  CARD_SIZES,
  CARD_STYLES,
  Card,
  FileUploader,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  ICON_SIZES,
  INPUT_SIZES,
  INPUT_STYLES,
  Icon,
  Input,
  MODAL_SIZES,
  Modal,
  ModalTitle,
  Row,
  SimpleImage,
  Space,
} from '@premagic/myne';
import { useState } from 'react';
import { ErrorTracker } from '@premagic/utils';
import { toastMessage } from '../../../../reducers/ToastStore';

type VideoThumbnailUploadProps = {
  isSaving?: boolean;
  upload: (file: File) => void;
};

function VideoThumbnailUpload(props: VideoThumbnailUploadProps) {
  const { upload, isSaving } = props;

  const [imageSrc, setImageSrc] = useState<any>(null);

  function handleImageChange(e, files) {
    const uploadedFile = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
      setImageSrc(imageDataUrl);
    };
    reader.readAsDataURL(uploadedFile);
    upload(uploadedFile);
  }

  return (
    <FormGroup>
      <FormLabel>Thumbnail</FormLabel>

      <Card style={CARD_STYLES.SECONDARY} size={CARD_SIZES.BOX_SM}>
        <Row fullHeight center vcenter>
          {imageSrc ? (
            <SimpleImage src={imageSrc} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          ) : (
            <Icon name="image" size={ICON_SIZES.LG} />
          )}
        </Row>
      </Card>
      <Space vertical size={2} />
      <FileUploader
        accept="image/jpeg, image/png"
        onError={(message, e) => {
          toastMessage('error', message, 200);
          ErrorTracker.logError('Invalid file format', e);
        }}
        onChange={handleImageChange}
      >
        <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.MD} isLoading={isSaving}>
          {imageSrc ? 'Change' : 'Select file'}
        </Button>
      </FileUploader>
    </FormGroup>
  );
}

type Props = {
  projectId: string;
  folderId: string;
  folder: FolderService.FolderType;
  projectName: string;
  show: boolean;
  hideExternalVideoLinkUploader: () => void;
  uploadFiles: (
    files: [] | Array<File>,
    options: {
      projectId: string;
      folderId: string;
      projectName: string;
      folderName: string;
      folderType: FolderService.FOLDER_TYPES;
    },
  ) => void;
  isLoading?: boolean;
  uploadedFileId?: string;
  addUrlToFiles: (options: {
    projectId: string;
    folderId: string;
    folderType: FolderService.FOLDER_TYPES;
    fileId: string;
    url: string;
  }) => void;
  removeUploadedFileId: () => void;
};

export default function VideoLinkUploader(props: Props): JSX.Element | null {
  const {
    projectId,
    folderId,
    show,
    folder,
    projectName,
    hideExternalVideoLinkUploader,
    uploadFiles,
    isLoading,
    uploadedFileId,
    addUrlToFiles,
    removeUploadedFileId,
  } = props;

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { folder_name: folderName, folder_type: folderType } = folder || {};

  function closeModal() {
    setShowAlert(false);
    removeUploadedFileId();
    hideExternalVideoLinkUploader();
  }

  function handleSubmit(formData) {
    const { external_video_url: externalVideoUrl, file_id_for_url: fileIdForUrl } = formData.data;
    if (!externalVideoUrl || !fileIdForUrl) {
      setShowAlert(true);
      return;
    }
    addUrlToFiles({ projectId, folderId, folderType, fileId: fileIdForUrl, url: externalVideoUrl });
    closeModal();
  }

  function handleFileUpload(file) {
    uploadFiles([file], {
      projectId,
      folderId,
      projectName,
      folderName,
      folderType,
    });
  }

  return (
    <Modal show={show} close={closeModal} size={MODAL_SIZES.MD}>
      <Space size={1}>
        <Form onSubmit={handleSubmit}>
          <ModalTitle>Add Youtube/Vimeo Link</ModalTitle>

          {showAlert && (
            <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
              Please select a thumbnail and enter a valid url.
            </Alert>
          )}
          <Space vertical size={3} />
          <VideoThumbnailUpload isSaving={isLoading} upload={handleFileUpload} />
          <Space size={2} />
          <FormGroup>
            <FormLabel>Video URL</FormLabel>
            <Input
              name="external_video_url"
              size={INPUT_SIZES.MD}
              style={INPUT_STYLES.PRIMARY}
              placeholder="eg. https://www.youtube.com/watch?v=00000XYZ"
              autoFocus
              required
            />
          </FormGroup>
          <FormFooter>
            <Button size={BUTTON_SIZE.MD} style={BUTTON_STYLES.CANCEL} onClick={closeModal}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.MD} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add Video Link
            </Button>
          </FormFooter>
          <input type="hidden" name="file_id_for_url" value={uploadedFileId || ''} />
        </Form>
      </Space>
    </Modal>
  );
}
