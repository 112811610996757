import React, { useEffect, useState } from 'react';
import {
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  BUTTON_STYLES,
  BUTTON_SIZE,
  Form,
  Button,
  FormResponseType,
  FormFooter,
  FormErrorType,
  FormError,
} from '@premagic/myne';
import EventSponsorFormContainer from './EventSponsorFormContainer';

type Props = {
  addEventSponsor: (projectId: string, formResponse: FormResponseType) => void;
  close: () => void;
  show: boolean;
  projectId: string;
  isLoading: boolean;
  error: FormErrorType;
};

export default function AddEventSponsorPanel(props: Props) {
  const { show, close, projectId, addEventSponsor, isLoading, error } = props;

  const [formError, setFormError] = useState<any>();

  function handleFormSubmit(formResponse) {
    if (formResponse.errors) {
      setFormError(formResponse.errors);
    } else {
      addEventSponsor(projectId, formResponse);
      setFormError(null);
    }
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={close}>
        <WindowPanelTitle>Add Sponsor</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          formSpec={{
            category: {
              validator: 'required',
              message: 'Select a sponsor category',
            },
          }}
          errors={error || formError}
          onSubmit={handleFormSubmit}
        >
          {formError && formError.category && <FormError>{formError.category}</FormError>}
          <EventSponsorFormContainer projectId={projectId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
