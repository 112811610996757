import { AppServices } from '@premagic/core';
import { HttpUtils } from '@premagic/utils';
import ACTIONS from '../../../../common/ActionContants';

export type PhotographerActionType = {
  id: string;
  type: string;
  created_at: string;
  folder_id: string;
  status: string;
};

export const ACTION_TYPE_INFO = {
  [ACTIONS.ALLOW_PHOTO_SELECTION]: {
    title: 'Request to allow photo selection',
    primaryButtonText: 'Allow selection',
  },
};

export function fetchAllPhotographerActionService(
  listType: 'all' | 'read' | 'new' | 'done',
): Promise<Record<string, PhotographerActionType>> {
  return HttpUtils.get(`${AppServices.getAPIUrl()}/action/list/${listType}/`)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function markActionAsDoneService(id: string) {
  return HttpUtils.get(`${AppServices.getAPIUrl()}/action/${id}/`);
}

export function markActionAsDismissedService(id: string) {
  return HttpUtils.get(`${AppServices.getAPIUrl()}/action/read/${id}/`);
}
