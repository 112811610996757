import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ArrayUtils, SimpleDateUtils, ErrorTracker, ActionTypeUtils } from '@premagic/utils';

import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { fetchCustomerClients } from './CustomerClientsService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CUSTOMER_CLIENTS');

export const setCustomerClientsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const fetchClientsData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.CLIENTS.FETCH_ALL;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchCustomerClients()
    .then((response) => {
      dispatch(setCustomerClientsData(dispatch, response.data));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('LEADS: fetch failed', e);
    });
});

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setCustomerClientsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const customerClientsDataSelector = (state: any) =>
  state.entities.clients.items
    .map((item) => ({
      ...item,
      created_at: SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(item.created_at),
    }))
    .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.created_at, b.created_at, true));

export const customerClientsItemsSelector = createSelector(customerClientsDataSelector, (clients: any) =>
  clients.toJS(),
);

export const customerClientsKeysSelector = createSelector(customerClientsDataSelector, (clients: any) =>
  clients.keySeq().toArray(),
);
