import { connect } from 'react-redux';
import QuotePaymentItem from './QuotePaymentItem';
import { addPaymentForEvent, crmQuoteSelector, updatePayment } from '../CRMPaymentDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { PAYMENT_TYPES } from '../CRMPaymentService';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';

const DIALOG_KEY = LOADINGS.CRM_PAYMENTS.SHOW_ADD_EDIT_QUOTE_DIALOG;
function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  return {
    eventId: ownProps.eventId,
    quotePayment: crmQuoteSelector(state),
    isLoading: isLoadingSelector(LOADINGS.CRM_PAYMENTS.ADD)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CRM_PAYMENTS.ADD)(state),
    isUpdating: isLoadingSelector(LOADINGS.CRM_PAYMENTS.UPDATE)(state),
    updateErrors: errorObjectForTypeSelector(LOADINGS.CRM_PAYMENTS.UPDATE)(state),
    isDialogShown: isModalOpenSelector(DIALOG_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPayment: (eventId: string, data) => {
      dispatch(addPaymentForEvent(dispatch, eventId, data, PAYMENT_TYPES.QUOTE));
    },
    updatePayment: (paymentId: string, eventId: string, data) => {
      dispatch(updatePayment(dispatch, { paymentId, eventId }, data));
    },
    toggleDialog: (show) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotePaymentItem);
