import React, { useEffect } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  List,
  LoadingBox,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProposalVariableService } from '@premagic/proposals';

import ProposalVariableItemContainer from './ProposalVariableItemContainer';

type Props = {
  initData: () => void;
  groupedVariableIds: Record<ProposalVariableService.PROPOSAL_VARIABLE_SCOPE, Array<string>>;
  isLoading: boolean;
  showAddNewVariable: (scope: string) => void;
};

export default function ProposalVariablesList(props: Props): JSX.Element {
  const { initData } = props;
  useEffect(() => {
    initData();
  }, [initData]);

  const { isLoading, showAddNewVariable, groupedVariableIds } = props;
  return (
    <div>
      {Object.entries(groupedVariableIds).map(([scope, variableIds]) => {
        const details = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE_DETAILS[scope];
        return (
          <div key={scope}>
            <Row>
              <Col>
                <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                  {details.title}
                </Text>
              </Col>
              <Col rightAlighed size={null}>
                <Button style={BUTTON_STYLES.LINK} onClick={() => showAddNewVariable(scope)}>
                  <Icon name="plus" size={ICON_SIZES.SM} /> {details.addNewVariableButtonText}
                </Button>
              </Col>
            </Row>
            <Space vertical size={2} />
            <LoadingBox isLoading={isLoading}>
              {variableIds.length === 0 ? (
                <div>
                  <Text muted>You haven&apos;t added any {details.title}</Text>
                </div>
              ) : (
                <Card>
                  <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.SM} showShadow direction="vertical">
                    <List>
                      {variableIds.map((id) => (
                        <ProposalVariableItemContainer key={id} id={id} />
                      ))}
                    </List>
                  </ScrollableContainer>
                </Card>
              )}
            </LoadingBox>
            <Space size={12} vertical />
          </div>
        );
      })}
    </div>
  );
}
