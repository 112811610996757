import { EnvUtils, LocalCacheUtils } from '@premagic/utils';

declare const PM: {
  clientName: 'not set';
  APP_VERSION: 'develop';
};

type GlobalPMVariable = {
  clientName: string;
  requestingUser?: {
    email?: string;
    id: string;
    name?: string;
  };
  APP_VERSION: string;
  ACCOUNT_ID?: string;
  CUSTOMER_ID?: string;
  DEBUG_FACE?: boolean;
};

function getGlobalVariableForPM(): GlobalPMVariable {
  return (
    (window as unknown as { PM: GlobalPMVariable }).PM || {
      clientName: '',
      APP_VERSION: EnvUtils.getAppVersion(),
      ACCOUNT_ID: undefined,
      CUSTOMER_ID: undefined,
    }
  );
}

export function setGlobalVariableForPM(data: Partial<GlobalPMVariable>): void {
  (window as unknown as { PM: GlobalPMVariable }).PM = {
    ...getGlobalVariableForPM(),
    ...data,
  };
}

export function getClientName(): string {
  const data = getGlobalVariableForPM();
  return data?.clientName;
}

export function getGlobalRequestingUser(): {
  email?: string;
  id: string;
  name?: string;
} {
  const data = getGlobalVariableForPM();
  return (
    data?.requestingUser || {
      email: 'text@example.com',
      id: 'not set',
      name: 'not set',
    }
  );
}

export function getAccountId(): string | undefined {
  const data = getGlobalVariableForPM();
  return data?.ACCOUNT_ID;
}

export function getCustomerId(): string | undefined {
  const data = getGlobalVariableForPM();
  return data?.CUSTOMER_ID;
}

export function debugFaces(): boolean {
  const data = getGlobalVariableForPM();
  return data?.DEBUG_FACE || LocalCacheUtils.getSimpleCache('DEBUG_FACE') === 'true' || false;
}

export function getPMEmbedData() {
  return (window as unknown as { PM_DATA }).PM_DATA || {};
}
