import { connect } from 'react-redux';
import { toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import EventFormFieldOptionsMenu from './EventFormFieldOptionsMenu';
import { hasPermission } from '../../../crm/users/UsersDataDuck';
import { PERMISSIONS } from '../../../crm/users/UserPermissionService';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';

function mapStateToProps(state, ownProps: { formFieldId: string; projectId: string }) {
  const { formFieldId, projectId } = ownProps;

  return {
    projectId,
    formFieldId,
    hasPermissionsToFormField: hasPermission(PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT)(state),
    isLoading: isLoadingSelector(LOADINGS.EVENT_CUSTOM_FORM_FIELDS.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDeleteDialog: (id: string) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_DELETE_DIALOG(id), true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFormFieldOptionsMenu);
