import { connect } from 'react-redux';
import FormFieldDeleteDialog from './FormFieldDeleteDialog';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { removeCustomEventFromField } from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';

function mapStateToProps(state, ownProps: { formFieldId: string; projectId: string }) {
  const { formFieldId, projectId } = ownProps;

  return {
    formFieldId,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.EVENT_CUSTOM_FORM_FIELDS.DELETE)(state),
    isDialogShown: isModalOpenSelector(LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_DELETE_DIALOG(formFieldId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (id: string, show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_DELETE_DIALOG(id), show));
    },
    deleteFormField: (projectId, formFieldId) => {
      dispatch(removeCustomEventFromField(dispatch, { projectId, formFieldId }));
      dispatch(
        toggleModalVisibility(dispatch, LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_DELETE_DIALOG(formFieldId), false),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFieldDeleteDialog);
