import { MODAL_SIZES, Modal, ModalTitle } from '@premagic/myne';
import React from 'react';
import QRCodeLEDScreenCustomPosterFilesContainer from './QRCodeLEDScreenCustomPosterFilesContainer';

type Props = { projectId: string; folderId: string; show: boolean; close: () => void };

export default function QRCodeLEDScreenCustomPosterSelectModal(props: Props) {
  const { projectId, folderId, show, close } = props;
  return (
    <Modal show={show} close={close} size={MODAL_SIZES.XL} showFooterCloseButton>
      <ModalTitle>Select LED Screen poster</ModalTitle>
      <QRCodeLEDScreenCustomPosterFilesContainer projectId={projectId} folderId={folderId} />
    </Modal>
  );
}
