import { INPUT_SIZES, Select } from '@premagic/myne';
import { ClientSettingsService } from '@premagic/core';

type Props = {
  defaultValue?: ClientSettingsService.CLIENT_COMPANY_TYPES;
  onChange?: (newType: ClientSettingsService.CLIENT_COMPANY_TYPES) => void;
};

export default function CompanyTypeInput(props: Props) {
  const { onChange, defaultValue } = props;

  const companyTypesOptions = Object.values(ClientSettingsService.CLIENT_COMPANY_TYPE_DETAILS).map((option) => ({
    label: option.title,
    value: option.id,
  }));

  const selectedCompanyTypeOption = companyTypesOptions.find((option) => option.value === defaultValue);

  return (
    <Select
      size={INPUT_SIZES.MD}
      options={companyTypesOptions}
      value={selectedCompanyTypeOption}
      name="company_type"
      onChange={(option) => {
        if (onChange) onChange(option?.value as ClientSettingsService.CLIENT_COMPANY_TYPES);
      }}
      isMulti={false}
    />
  );
}
