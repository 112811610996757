import React, { useEffect, useState } from 'react';

import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Divider,
  Emoji,
  EMOJIS,
  Position,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { CompanyService, PricingPlanService } from '@premagic/core';

import PaymentDoneModalContainer from './PaymentDoneModalContainer';
import { PricingPlan } from '../pricing-plan/PricingPlan';

type Props = {
  fetchPricingPlan: () => void;
  pricingPlans: Array<PricingPlanService.PricingPlanType>;
  company: CompanyService.CompanyType;
  isLoading: boolean;
  isMobileUp: boolean;
  isCompanyTypeNotPhotographer: boolean;
};

export default function SubscriptionsPlans(props: Props): JSX.Element | null {
  const { fetchPricingPlan, pricingPlans, company, isLoading, isMobileUp, isCompanyTypeNotPhotographer } = props;
  const [view, setView] = useState<PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY>(
    PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY.YEARLY,
  );

  useEffect(() => {
    fetchPricingPlan();
  }, [fetchPricingPlan]);

  const showPricingPlans = !isCompanyTypeNotPhotographer && pricingPlans.length > 0;
  const showTop = false;
  if (!showPricingPlans) return null;
  return (
    <div>
      {showTop && (
        <div>
          <Row center>
            <Button
              size={BUTTON_SIZE.LG}
              style={BUTTON_STYLES.LINK_SECONDARY}
              active={view === PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY.MONTHLY}
              onClick={() => setView(PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY.MONTHLY)}
            >
              Monthly
            </Button>
            <Button
              size={BUTTON_SIZE.LG}
              style={BUTTON_STYLES.LINK_SECONDARY}
              active={view === PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY.YEARLY}
              onClick={() => setView(PricingPlanService.PRICING_PLAN_VARIANT_FREQUENCY.YEARLY)}
            >
              <Position align="top">
                <Space vertical size={2} />
                <Text color={COLOR_SHADES.DANGER} block center size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                  Save 20%
                  <Emoji name={EMOJIS.FIRE} />
                </Text>
              </Position>
              Yearly
            </Button>
          </Row>
          <Divider />
          <Space vertical />
        </div>
      )}
      <Row columnDirection={!isMobileUp}>
        {pricingPlans.map((plan, idx) => (
          <Col key={plan.name} size={isMobileUp ? 6 : 12}>
            <PricingPlan
              plan={plan}
              previousPlan={pricingPlans[idx - 1]}
              highlight={!!(idx % 2)}
              last={pricingPlans.length === idx + 1}
              company={company}
              view={view}
            />
          </Col>
        ))}
      </Row>
      <Space vertical size={8} />
      <PaymentDoneModalContainer />
    </div>
  );
}
