import { FolderMetaService, QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import { navigateTo } from '../../../../../../../services/RouterService';
import APP_URLS from '../../../../../services/AppRouteURLService';
import {
  editQRCodeDesigns,
  qrCodeDesignsLogoSelector,
  qrCodeDesignsSelector,
  saveFolderMetaData,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import { companyLogoSelector } from '../../../../settings/company/CompanyDataDuck';

import QRCodePosterLogoSelector from './QRCodePosterLogoSelector';

function mapStateToProps(state, ownProps) {
  const { folderId, projectId } = ownProps;

  const qrCodeDesigns = qrCodeDesignsSelector(folderId)(state);

  return {
    folderId,
    projectId,
    companyLogo: companyLogoSelector(state),
    logoOptionType: qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_OPTION_TYPE],
    qrCodeDesigns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateToCompanyLogoUpload: () => {
      navigateTo(dispatch, APP_URLS.SETTINGS.PROFILE.DETAILS);
    },
    updateLogoOptionType: (projectId, folderId, qrCodeDesigns, optionValue) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO_OPTION_TYPE]: optionValue,
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLOR_EXTRACTION_FROM_LOGO]: true,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePosterLogoSelector);
