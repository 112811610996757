import { IMAGE_V2_STYLES, ImageV2, LoadingDots, Row, Space, TEXT_SIZE, Text } from '@premagic/myne';
import { MediaUtils } from '@premagic/utils';
import { useMemo } from 'react';

type Props = {
  watermarks?: Array<MediaUtils.WatermarkOptions>;
  appWidth: number;
  isWatermarkProcessing?: boolean;
  isMobileView?: boolean;
  photo?: {
    name: string;
    image: string;
  };
};

export default function WatermarkPreviewFullViewImage(props: Props) {
  const {
    watermarks,
    appWidth,
    isWatermarkProcessing,
    isMobileView,
    photo = {
      image: '',
      name: '',
    },
  } = props;

  const imageSrc = useMemo(
    () =>
      MediaUtils.getNewDynamicImageURL(photo.image, MediaUtils.IMAGE_QUALITY_TYPES.FULL_VIEW_HIGHLIGHT, {
        width: appWidth,
        watermarks,
      }),
    [appWidth, watermarks, photo],
  );

  if (!photo.name) return <Text>Select a photo</Text>;

  const fileName = photo.name;

  if (isWatermarkProcessing) {
    return (
      <Space>
        <Row columnDirection>
          <LoadingDots size="sm" />
          {!isMobileView && (
            <Text size={TEXT_SIZE.SIZE_2}>The watermark is being processed. Please try again after some time.</Text>
          )}
        </Row>
      </Space>
    );
  }

  if (!imageSrc) return <Text>Oops... Cannot find photo</Text>;

  return <ImageV2 alt={fileName} src={imageSrc} style={IMAGE_V2_STYLES.FULL_IMAGE} />;
}
