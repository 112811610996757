import { COLOR_SHADES, Space, Text, TEXT_BOLDNESS, TEXT_SIZE, SimpleImage } from '@premagic/myne';
import { AssetsService } from '@premagic/core';
import { JS_SELECTORS } from '../../../common/JSClassSelectors';
import { getDefaultCRMCalendarUrlWithFilters } from '../components/crm/filters/CRMFilterService';
import MESSAGES from '../../../common/Messages';

const stepsContents = [
  {
    stepNumber: 1,
    heading: 'Find all your photography events from this events section',
    description: 'Maintain client details, venue direction, assign a photographer and many more.',
  },
  {
    stepNumber: 2,
    heading: 'Create events and manage them all in one place, even before it starts',
    description: 'Save contact number, email, event dates and take notes to help you manage all your events.',
  },
  {
    stepNumber: 3,
    heading: 'Find more details about the event',
    description: 'Add contact number, email, event dates and many more.',
  },
  {
    stepNumber: 4,
    heading: 'Maintain all your event and lead/client details in one place.',
    description: 'Follow up with lead, take notes, add or edits contact information.',
  },
  {
    stepNumber: 5,
    heading: 'When your event is confirmed, convert it into a project to unlock its full potential.',
    description: `Get access to signature album, photo selection and ${MESSAGES.CLIENT_WEBSITE.NAME} to make your brand stand out from the rest of the photographers.`,
  },
  {
    stepNumber: 6,
    heading:
      'Once its converted to a project, you can see all deliverables from the photographer in the deliverables tab.',
    description: `Get access to signature album, photo selection and ${MESSAGES.CLIENT_WEBSITE.NAME} to make your brand stand out from the rest of the photographers.`,
  },
  {
    stepNumber: 7,
    heading: `Introducing Premagic ${MESSAGES.CLIENT_WEBSITE.NAME}`,
    description:
      'A modern way for your clients to invite people. Help your client to make their special day memorable and share their happiness with their friends and family.',
    imgSrc: AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.DWI_CARD),
  },
  {
    stepNumber: 8,
    heading: `Preview ${MESSAGES.CLIENT_WEBSITE.NAME}`,
    description: `See how a ${MESSAGES.CLIENT_WEBSITE.NAME} would look like for your client. This will open in a new tab.`,
  },
  {
    stepNumber: 9,
    heading: 'Introducing Signature album',
    description: 'A modern way to deliver and showcase your photos digitally with your branding on it.',
    imgSrc: AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.SIGNATURE_ALBUM_MINI_GALLERY),
  },
  {
    stepNumber: 10,
    heading: 'Preview Signature album',
    description:
      'View how a signature album looks like. You can use your own branding to make it stand out. This will open in a new tab.',
  },
  {
    stepNumber: 11,
    heading: 'Introducing photo selection tool',
    description:
      'Give your clients to view and select pictures for their album. Create a selection folder, add photos that are needed to be selected and share it with your clients for faster selection.',
    imgSrc: AssetsService.getProductImages(AssetsService.ASSETS_URL.PRODUCT_IMAGES.SELECTION_MINI_GALLERY),
  },
  {
    stepNumber: 12,
    heading: 'Find all selected photos from your client.',
    description: 'All selected photos from your client can be found and reviewed here.',
  },
];

type StepContentProps = {
  data: {
    stepNumber: number;
    heading: string;
    description: string;
    imgSrc?: string;
  };
};

function StepContent(props: StepContentProps) {
  const { data } = props;
  const { stepNumber, heading, description, imgSrc } = data;
  return (
    <div>
      {imgSrc && <SimpleImage style={{ width: '100%' }} src={imgSrc} alt="dwi_thumb" />}
      <Space size={6} vertical />
      <Text color={COLOR_SHADES.LIGHTER}>{stepNumber} / 12</Text>
      <Space size={2} vertical />
      <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.WHITE}>
        {heading}
      </Text>
      <Space size={2} vertical />
      <Text color={COLOR_SHADES.LIGHTER}>{description}</Text>
    </div>
  );
}

const steps = [
  {
    selector: `.${JS_SELECTORS.EVENTS_NAV_ITEM}`,
    buttonText: 'Go to Events',
    isClickRequired: false,
    url: getDefaultCRMCalendarUrlWithFilters(),
    content: <StepContent data={stepsContents[0]} />,
  },
  {
    selector: `.${JS_SELECTORS.ADD_EVENTS_BTN}`,
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[1]} />,
  },
  {
    selector: `.${JS_SELECTORS.EVENT_ITEM_CALENDAR}`,
    buttonText: 'Open Events',
    isClickRequired: false,
    content: <StepContent data={stepsContents[2]} />,
  },
  {
    selector: `.${JS_SELECTORS.EVENT_DETAILS_TAB}`,
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[3]} />,
  },
  {
    selector: `.${JS_SELECTORS.CONVERT_TO_PROJECT}`,
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[4]} />,
  },
  {
    selector: `.${JS_SELECTORS.DELIVERABLES_TAB}`,
    buttonText: 'Next',
    isClickRequired: true,
    content: <StepContent data={stepsContents[5]} />,
  },
  {
    selector: `.${JS_SELECTORS.DIGITAL_INVITE_CARD}`,
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[6]} />,
  },
  {
    selector: `.${JS_SELECTORS.DIGITAL_INVITE_PREVIEW_BTN}`,
    buttonText: 'Preview',
    isClickRequired: false,
    content: <StepContent data={stepsContents[7]} />,
  },
  {
    selector: `.${JS_SELECTORS.SIGNATURE_ALBUM_CARD}`,
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[8]} />,
  },
  {
    selector: `.${JS_SELECTORS.SIGNATURE_ALBUM_PREVIEW_BTN}`,
    position: 'top',
    buttonText: 'Preview',
    isClickRequired: false,
    content: <StepContent data={stepsContents[9]} />,
  },
  {
    selector: `.${JS_SELECTORS.PHOTO_SELECTION_CARD}`,
    position: 'top',
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[10]} />,
  },
  {
    selector: `.${JS_SELECTORS.PHOTO_SELECTION_PREVIEW_BTN}`,
    position: 'top',
    buttonText: 'Next',
    isClickRequired: false,
    content: <StepContent data={stepsContents[11]} />,
  },
];

export default steps;
