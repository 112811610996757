import React from 'react';
import { Circle as CanvasCircle, Rect as CanvasRect, Text as CanvasText } from 'react-konva';
import { PosterWidgetRectType, PosterWidgetTextType } from '../services/PosterWidgetService';

type PosterCircleType = {
  data: PosterWidgetRectType;
};

export const PosterCircle = React.forwardRef((props: PosterCircleType, ref: React.Ref<any>) => {
  const { data, ...rest } = props;
  const { position, size, fill, opacity, stroke, id, dash } = data;

  return (
    <CanvasCircle
      ref={ref}
      id={id}
      x={position?.x}
      y={position?.y}
      rotation={position?.rotation}
      offsetX={-size.width / 2}
      offsetY={-(size.height || size.width) / 2}
      width={size.width}
      height={size.height}
      fill={fill}
      opacity={opacity}
      stroke={stroke}
      dash={dash}
      {...rest}
    />
  );
});

type PosterBoxType = {
  data: PosterWidgetRectType;
};

export const PosterBox = React.forwardRef((props: PosterBoxType, ref: React.Ref<any>) => {
  const { data, ...rest } = props;
  const { position, size, fill, opacity, stroke, id, corner_radius: radius } = data;

  return (
    <CanvasRect
      ref={ref}
      id={id}
      x={position?.x}
      y={position?.y}
      rotation={position?.rotation}
      width={size.width}
      height={size.height}
      fill={fill}
      opacity={opacity}
      stroke={stroke}
      cornerRadius={radius}
      {...rest}
    />
  );
});

type PosterTextType = {
  data: PosterWidgetTextType;
};
export const PosterText = React.forwardRef((props: PosterTextType, ref: React.Ref<any>) => {
  const { data, ...rest } = props;
  const {
    position,
    size,
    value,
    font_align: fontAlign,
    font_family: fontFamily,
    font_size: fontSize,
    font_color: fontColor,
    font_weight: fontWeight = 'normal',
    font_vertical_align: fontVerticalAlign,
    font_variant: fontVariant,
    id,
    ellipsis = true,
    font_line_height: fontLineHeight = 1,
    opacity,
  } = data;

  return (
    <CanvasText
      ref={ref}
      id={id}
      x={position?.x}
      y={position?.y}
      rotation={position.rotation}
      width={size.width}
      height={size.height}
      fill={fontColor}
      fontSize={fontSize}
      lineHeight={fontLineHeight}
      fontFamily={fontFamily}
      fontStyle={(fontWeight || 'normal') as string}
      verticalAlign={fontVerticalAlign}
      align={fontAlign}
      ellipsis={ellipsis}
      fontVariant={fontVariant}
      text={value}
      opacity={opacity}
      {...rest}
    />
  );
});
