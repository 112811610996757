import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { ProjectOrdersService, Schemas } from '@premagic/core';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { normalize } from 'normalizr';
import { entitiesDataSelector, EntitiesStateType } from '../../reducers/selectors';
import { toggleLoadingState } from '../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { LOADINGS } from '../../../../common/Constants';

const getActionType = ActionTypeUtils.getActionTypeFunction('PROJECT_ORDERS', false);

export const setProjectOrdersData = createAction(getActionType('DATA', 'SET'), (dispatch: Dispatch, data) => data);

export const fetchOrdersForProject = createAction(getActionType('DATA', 'FETCH'), (dispatch, projectId: string) => {
  const COMMON_KEY = LOADINGS.PROJECT_ORDERS.FETCH;
  dispatch(toggleLoadingState(dispatch, COMMON_KEY, true));
  dispatch(clearErrorState(dispatch, COMMON_KEY));
  ProjectOrdersService.fetchProjectOrders(projectId)
    .then((response) => {
      const normalizedData = normalize(response, Schemas.ProjectOrdersSchema);
      const { projectOrders } = normalizedData.entities || {};
      dispatch(setProjectOrdersData(dispatch, projectOrders));
      dispatch(toggleLoadingState(dispatch, COMMON_KEY, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, COMMON_KEY, e));
      dispatch(toggleLoadingState(dispatch, COMMON_KEY, false));
      ErrorTracker.logError('Project orders fetch', e);
    });
});

type ProjectsOrderType = IMap<string, ProjectOrdersService.ProjectOrdersType>;

const initialState = {
  items: IMap(),
};

export default handleActions(
  {
    [setProjectOrdersData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
  },
  initialState,
);

export const projectOrderDataSelector = createSelector(
  entitiesDataSelector,
  (entities: EntitiesStateType) => entities.projectOrders as { items: ProjectsOrderType },
);

export const projectOrdersEntitiesSelector = createSelector(
  projectOrderDataSelector,
  (projectOrders: { items: ProjectsOrderType }) => projectOrders.items,
);

export const projectOrdersSelector = createSelector(projectOrdersEntitiesSelector, (projectOrders: ProjectsOrderType) =>
  projectOrders.toJSON(),
);

export const activeOrderIdsForProjectAndFolderSelector = (
  projectId,
  folderId,
  orderName: ProjectOrdersService.ORDER_NAMES,
) =>
  createSelector(projectOrdersEntitiesSelector, (projectOrders: ProjectsOrderType) =>
    projectOrders
      .filter((order) => order.project_id === projectId && order.folder_id === folderId && order.name === orderName)
      .first(),
  );

export const isPremiumClientWebsite = (projectId, folderId) =>
  createSelector(projectOrdersEntitiesSelector, (projectOrders: ProjectsOrderType) => {
    const orderItem = projectOrders
      .filter(
        (order) =>
          order.project_id === projectId &&
          order.folder_id === folderId &&
          order.name === ProjectOrdersService.ORDER_NAMES.DIGITAL_INVITE,
      )
      .first();
    if (orderItem) {
      return orderItem.variant !== ProjectOrdersService.DIGITAL_INVITE_ORDER_VARIENTS.TRIAL;
    }
    return false;
  });
