import React, { useRef } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Dialog,
  DIALOG_PLACEMENT,
  DialogFooter,
  Space,
  Text,
  DialogTitle,
  COLOR_SHADES,
} from '@premagic/myne';
import MESSAGES from '../../../../../../../common/Messages';

type Props = {
  projectId: string;
  isLoading: boolean;
  isDialogShown: boolean;
  attendeeId: string;
  toggleDialog: (attendeeId: string, show: boolean) => void;
  deleteAttendee: (projectId: string, attendeeId: string) => void;
};

export default function AttendeeDeleteDialog(props: Props): React.ReactElement {
  const { projectId, isLoading, toggleDialog, isDialogShown, deleteAttendee, attendeeId } = props;
  const target = useRef(null);

  return (
    <span ref={target}>
      <Dialog
        target={target.current}
        show={isDialogShown}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => toggleDialog(attendeeId, false)}
      >
        <DialogTitle>Delete this Attendee?</DialogTitle>
        <Text>Are you sure you want to delete this attendee?</Text>
        <Space vertical size={6} />
        <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
        <DialogFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(attendeeId, false)}>
            Cancel
          </Button>
          <Button
            style={BUTTON_STYLES.DANGER}
            onClick={() => deleteAttendee(projectId, attendeeId)}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </DialogFooter>
      </Dialog>
    </span>
  );
}
