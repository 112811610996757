import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import HighlightDesignFolderLayoutSelector from './HighlightDesignFolderLayoutSelector';

function mapStateToProps(
  state,
  ownProps: {
    onChange: (value) => void;
    folderLayout: ProjectMetaService.FOLDER_THUMBNAIL_VIEWS;
    isFolderThumbnailViewEnabled: boolean;
  },
) {
  const { onChange, folderLayout, isFolderThumbnailViewEnabled } = ownProps;
  return {
    onChange,
    folderLayout,
    isFolderThumbnailViewEnabled,
    hasSignatureFolderThumbnailsFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS,
    )(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightDesignFolderLayoutSelector);
