import { $Keys } from 'utility-types';

import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ArrayUtils, SimpleDateUtils, ErrorTracker, ActionTypeUtils } from '@premagic/utils';
import { EventTrackerService } from '@premagic/core';

import { LOADINGS } from '../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../common/LoadingDuck';

import { CUSTOMER_LEAD_STATUS, fetchCustomerLeads, updateCustomerLeadStatus } from './CustomerLeadsService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CUSTOMER_LEADS');

export const setCustomerLeadsData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);

export const updateCustomerLeadData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, id, data) => ({
  [id]: data,
}));

export const fetchLeadsData = createAction(getActionType('DATA', 'FETCH'), (dispatch) => {
  const loadingKey = LOADINGS.LEADS.FETCH_ALL;
  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  fetchCustomerLeads()
    .then((response) => {
      dispatch(setCustomerLeadsData(dispatch, response.data));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('LEADS: fetch failed', e);
    });
});

export const saveLeadStatus = createAction(getActionType('DATA_STATUS', 'SAVE'), (dispatch, id, newStatus) => {
  const loadingKey = LOADINGS.LEADS.UPDATE_STATUS;
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.LEAD.UPDATE_STATUS, { newStatus });

  dispatch(toggleLoadingState(dispatch, loadingKey, true));
  dispatch(clearErrorState(dispatch, loadingKey));

  updateCustomerLeadStatus(id, newStatus)
    .then((response) => {
      dispatch(updateCustomerLeadData(dispatch, id, { status: newStatus }));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      return response;
    })
    .catch((e) => {
      dispatch(setErrorState(dispatch, loadingKey, e));
      dispatch(toggleLoadingState(dispatch, loadingKey, false));
      ErrorTracker.logError('LEADS: Update status failed', e);
    });
});

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setCustomerLeadsData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [updateCustomerLeadData.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState,
);

export const customerLeadsDataSelector = (state: any) =>
  state.entities.leads.items
    .map((item) => ({
      ...item,
      created_at: SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(item.created_at),
    }))
    .sort((a, b) => ArrayUtils.dateSortForISODateFunction(a.created_at, b.created_at, true));

export const customerLeadsItemsSelector = createSelector(customerLeadsDataSelector, (leads: any) => leads.toJS());

export const customerLeadsKeysSelector = createSelector(customerLeadsDataSelector, (leads: any) =>
  leads.keySeq().toArray(),
);

export const customerLeadsStatsSelector = createSelector(customerLeadsDataSelector, (leads: any) =>
  leads
    .groupBy((lead) => lead.status)
    .reduce(
      (result, leadsInGroup, status) => ({
        ...result,
        [status]: leadsInGroup.size,
      }),
      {},
    ),
);

export const customerLeadsKeysForStatusSelector = (status: $Keys<typeof CUSTOMER_LEAD_STATUS>) =>
  createSelector(customerLeadsDataSelector, (leads: any) =>
    leads
      .filter((lead) => lead.status === status)
      .keySeq()
      .toArray(),
  );
