import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';
import { ProposalDataDuck, ActionConstants, ProposalDeckDataDuck } from '@premagic/proposals';

import ProposalFolderShareButton from './ProposalFolderShareButton';

function mapStateToProps(state, ownProps: { folderId: string; projectId: string; deckId: string }) {
  const { folderId, projectId, deckId } = ownProps;

  return {
    folderId,
    projectId,
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    proposal: ProposalDataDuck.proposalsItemsSelectors(state)[folderId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSharePanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHARE, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalFolderShareButton);
