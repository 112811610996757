import { Map as IMap } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { isToday, isYesterday } from 'date-fns';
import { ProductFeedbackService } from '@premagic/core';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ArrayUtils, CountryCodeUtils, ErrorTracker, SimpleDateUtils } from '@premagic/utils';
import { toastMessage } from '../../../../../reducers/ToastStore';
import { toggleLoadingState } from '../../../../../../../common/LoadingDuck';
import { clearErrorState, setErrorState } from '../../../../../../../common/ErrorDuck';
import { pagesSelector } from '../../../../../reducers/selectors';
import { LOADINGS } from '../../../../../../../common/Constants';
import { REPORT_ISSUE_GROUP_TYPES } from '../service/ReportIssueService';

const getActionType = ActionTypeUtils.getActionTypeFunction('REPORT_ISSUE');

const setReportIssueData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
const removeReportIssueData = createAction(getActionType('DATA', 'REMOVE'), (dispatch, data) => data);

const updateReportIssueData = createAction(getActionType('DATA', 'UPDATE'), (dispatch, issueId, data) => ({
  issueId,
  data,
}));

export const getReportIssuesForProject = createAction(
  getActionType('DATA', 'FETCH'),
  (dispatch, projectId: string, feedbackType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE) => {
    const loadingKey = LOADINGS.PRODUCT_FEEDBACK.REPORT_ISSUE.FETCH;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProductFeedbackService.getProductFeedbackList(projectId, feedbackType)
      .then((response) => {
        dispatch(setReportIssueData(dispatch, response));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('REPORT_ISSUE_FETCH_ERROR', e);
      });
  },
);

export const deleteReportIssue = createAction(
  getActionType('REPORT_ISSUE', 'DELETE'),
  (dispatch, projectId: string, feedbackId: string) => {
    const loadingKey = LOADINGS.PRODUCT_FEEDBACK.REPORT_ISSUE.DELETE(feedbackId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProductFeedbackService.deleteProductFeedbackItem(projectId, feedbackId)
      .then((response) => {
        dispatch(removeReportIssueData(dispatch, feedbackId));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('success', 'Issue removed successfully');
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('REPORT_ISSUE_DELETE_ERROR', e);
      });
  },
);

export const updateReportIssue = createAction(
  getActionType('REPORT_ISSUE', 'UPDATE'),
  (dispatch, projectId: string, feedbackId: string, data: Partial<ProductFeedbackService.ProductFeedbackType>) => {
    const loadingKey = LOADINGS.PRODUCT_FEEDBACK.REPORT_ISSUE.UPDATE(feedbackId);
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    ProductFeedbackService.updateProductFeedbackItem(projectId, feedbackId, data)
      .then((response) => {
        dispatch(updateReportIssueData(dispatch, feedbackId, data));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        toastMessage('error', 'Something went wrong');
        ErrorTracker.logError('REPORT_ISSUE_UPDATE_ERROR', e);
      });
  },
);

type ReportIssueItemType = IMap<string, ProductFeedbackService.ProductFeedbackType>;
type StateType = {
  items: ReportIssueItemType;
};
const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setReportIssueData.toString()]: (state, action) => ({
      ...state,
      items: IMap(action.payload),
    }),
    [removeReportIssueData.toString()]: (state, action: any) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
    [updateReportIssueData.toString()]: (state, action: { payload }) => {
      const { issueId, data } = action.payload;

      return {
        ...state,
        items: state.items.update(String(issueId), (issue: any) => ({
          ...issue,
          ...data,
        })),
      };
    },
  },
  initialState,
);

const reportIssueDataSelector = createSelector(
  pagesSelector,
  (pages) => pages.productReview.data.reportIssue as StateType,
);

const reportIssueEntitiesSelector = createSelector(reportIssueDataSelector, (data) => data.items);

export const reportIssuesValueSelector = createSelector(reportIssueEntitiesSelector, (issues) =>
  issues.valueSeq().toArray(),
);

export const reportIssuesKeySelector = createSelector(reportIssueEntitiesSelector, (issues) =>
  issues.keySeq().toArray(),
);

export const reportIssueByIdSelector = (reportIssueId) =>
  createSelector(reportIssueEntitiesSelector, (issues) => issues.get(reportIssueId));

export const reportIssueIdsGroupedByStatusSelector = (
  projectId: string,
  reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE,
) =>
  createSelector(
    reportIssueEntitiesSelector,
    (issues) =>
      issues
        .filter((issue) => issue?.project_id === projectId && issue?.feedback_type === reportType)
        .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
        .keySeq()
        .groupBy((issueId) => issues.get(issueId)?.status)
        .toJS() as Record<ProductFeedbackService.PRODUCT_FEEDBACK_STATUS_TYPE, Array<string>>,
  );

export const openReportedIssuesIdsByTypeGroupedByDateSelector = (
  projectId: string,
  reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE,
) =>
  createSelector(
    reportIssueEntitiesSelector,
    (issues) =>
      issues
        .filter(
          (issue) =>
            issue?.project_id === projectId &&
            issue?.feedback_type === reportType &&
            issue?.status === ProductFeedbackService.PRODUCT_FEEDBACK_STATUS_TYPE.OPEN,
        )
        .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
        .keySeq()
        .groupBy((issueId) => {
          const dateString = SimpleDateUtils.getISODateStringFromTimestamp(
            Number(issues.get(issueId)?.created_at) * 1000,
          );

          const date = SimpleDateUtils.getDateObject(dateString);

          if (isToday(date)) {
            return REPORT_ISSUE_GROUP_TYPES.TODAY;
          }
          if (isYesterday(date)) {
            return REPORT_ISSUE_GROUP_TYPES.YESTERDAY;
          }

          return REPORT_ISSUE_GROUP_TYPES.OLDER;
        })
        .toJS() as Record<REPORT_ISSUE_GROUP_TYPES, Array<string>>,
  );

export const closedReportIssueIdsByTypeSelector = (
  projectId: string,
  reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE,
) =>
  createSelector(
    reportIssueEntitiesSelector,
    (issues) =>
      issues
        .filter(
          (issue) =>
            issue?.project_id === projectId &&
            issue?.feedback_type === reportType &&
            issue?.status === ProductFeedbackService.PRODUCT_FEEDBACK_STATUS_TYPE.DONE,
        )
        .sort((a, b) => ArrayUtils.dateSortFunction(a.created_at, b.created_at, true))
        .keySeq()
        .toArray() || [],
  );
