export function getActionTypeFunction(SCOPE: string, isUi?: boolean): (type: string, verb?: string) => string {
  const scopeType = `${isUi ? '@UI/' : '@'}${SCOPE}`;

  return function getActionType(type: string, verb?: string) {
    if (!verb) return `${scopeType}/${type}`;

    return `${scopeType}/${type}/${verb}`;
  };
}

