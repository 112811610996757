import { useEffect, useState } from 'react';
import { BackgroundMusicService } from '@premagic/core';
import { BrowserUtils, SoundUtils } from '@premagic/utils';
import { Icon, ICON_SIZES, Button, BUTTON_STYLES, MusicPlayingLoader, Col, Position, Row, Space } from '@premagic/myne';

type Props = {
  musicId?: number;
};

let player;
export function ClientWebsiteBackgroundMusicPlayer(props: Props): JSX.Element | null {
  const { musicId } = props;
  const { BACKGROUND_MUSICS_IDS, BACKGROUND_MUSICS } = BackgroundMusicService;
  const musicDetails = (musicId && BACKGROUND_MUSICS[musicId]) || BACKGROUND_MUSICS[BACKGROUND_MUSICS_IDS.NONE];
  const [isPlaying, setPlaying] = useState(false);

  function play() {
    player?.play();
    setPlaying(true);
  }
  function pause() {
    player?.pause();
    setPlaying(false);
  }
  function togglePlay() {
    if (isPlaying) {
      pause();
      return;
    }
    play();
  }

  useEffect(
    () =>
      BrowserUtils.listenOfIframeClick(() => {
        alert('iframeclick');
        pause();
      }),
    [],
  );
  useEffect(() => {
    if (musicDetails.id !== BACKGROUND_MUSICS_IDS.NONE && !player?.playing()) {
      player = SoundUtils.initPlayer(musicDetails.serverPath, {
        loop: true,
        autoplay: false,
        volume: 0.1,
        playOnVisible: false,
      });
      player.on('play', () => {
        setPlaying(true);
      });
      player.on('pause', () => {
        setPlaying(false);
      });
      player.on('stop', () => setPlaying(false));
      player.on('end', () => setPlaying(false));
    }

    // SoundUtils.handleAutoSuspendIssue(play);
  }, [musicId]);
  if (musicDetails.id === BACKGROUND_MUSICS_IDS.NONE) {
    return null;
  }

  return (
    <Space size={2}>
      <Button onClick={() => togglePlay()} style={BUTTON_STYLES.LINK}>
        <MusicPlayingLoader play={isPlaying} /> <Space size={1} />
        <Icon name={isPlaying ? 'pause' : 'play'} size={ICON_SIZES.SM} />
      </Button>
    </Space>
  );
}
