import { connect } from 'react-redux';
import { CompanyService } from '@premagic/core';

import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { companyDataSelector, uploadCompanyLogo, removeCompanyLogo } from './CompanyDataDuck';
import CompanyLogoUpload from './CompanyLogoUpload';

function mapStateToProps(state) {
  return {
    company: companyDataSelector(state),
    isSaving: {
      [CompanyService.COMPANY_LOGOS.LOGO]: isLoadingSelector(
        LOADINGS.COMPANY.UPLOAD_LOGO(CompanyService.COMPANY_LOGOS.LOGO),
      )(state),
      [CompanyService.COMPANY_LOGOS.LOGO_WHITE]: isLoadingSelector(
        LOADINGS.COMPANY.UPLOAD_LOGO(CompanyService.COMPANY_LOGOS.LOGO_WHITE),
      )(state),
      [CompanyService.COMPANY_LOGOS.FAVICON]: isLoadingSelector(
        LOADINGS.COMPANY.UPLOAD_LOGO(CompanyService.COMPANY_LOGOS.FAVICON),
      )(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadCompanyLogo: (name, logo: File) => {
      dispatch(uploadCompanyLogo(dispatch, name, logo));
    },
    removeCompanyLogo: (name) => {
      dispatch(removeCompanyLogo(dispatch, name));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLogoUpload);
