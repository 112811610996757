import readFileAsync from './utils/read-file-async';
import detectOrientation from './utils/detect-image-orientation';
import * as converter from './optimizer-mozjpeg';
import { UPLOAD_QUALITY_DETAILS, UPLOAD_QUALITY_TYPES } from '../MediaUtils';

let imageBlobReduce: any = null;

export function initImageBlobReduce(): Promise<any> {
  const moduleUrl = 'https://unpkg.com/image-blob-reduce@4.1.0/dist/image-blob-reduce.min.js';
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = moduleUrl;
    script.type = 'module';
    script.async = true;

    script.onload = () => {
      // @ts-ignore
      imageBlobReduce = new ImageBlobReduce(['pica']);
      resolve('done');
    };

    script.onerror = () => {
      // Handle any script loading errors
      reject(new Error(`Failed to load module: ${moduleUrl}`));
    };

    document.head.appendChild(script);
  });
}

const JPEG_MIME_TYPE = 'image/jpeg';

function blobToFile(blob: Blob, fileName: string): File {
  const fileOptions: any = { type: blob.type };
  if (fileName) {
    fileOptions.name = fileName;
  }
  const fileObject = new File([blob], fileName, fileOptions);
  return fileObject;
}

function resizeImageFile(file: File, fileName: string, maxWidthOrHeight: number): Promise<File> | null {
  if (!imageBlobReduce) return null;
  return imageBlobReduce
    .toBlob(file, { max: maxWidthOrHeight })
    .then((blob) => blobToFile(blob, fileName))
    .catch((e) => {
      throw e;
    });
}

class CustomFile {
  name: string;

  initialSize: number;

  valid: boolean;

  rawFile: any;

  loading: boolean;

  error: any;

  finalSize: number | null;

  src: string | null;

  blob: any;

  constructor(rawFile) {
    // initial props
    this.name = rawFile.name;
    this.initialSize = rawFile.size;
    this.valid = rawFile.type === JPEG_MIME_TYPE;
    this.rawFile = rawFile;

    // result props
    this.loading = this.valid;
    this.error = null;
    this.finalSize = null;
    this.src = null;
    this.blob = null;
  }
}

// @quality ranges from 0-100
async function processFile(file: CustomFile, quality: number) {
  const contentBuffer = await readFileAsync(file.rawFile);
  const orientation = detectOrientation(contentBuffer);

  const { resultData, resultSize } = await converter.convert(contentBuffer, orientation, quality);

  return { resultData, resultSize };
}

export async function compressFiles(files: Array<File>, imageQuality: UPLOAD_QUALITY_TYPES): Promise<Array<File>> {
  const { quality, width } = UPLOAD_QUALITY_DETAILS[imageQuality];

  try {
    let filesToProcess;
    // will resize the image only if width is available
    if (width && imageBlobReduce) {
      const filesToResize = files.map((file) => resizeImageFile(file, file.name, width));
      filesToProcess = await Promise.all(filesToResize).then((res) => res);
    } else {
      filesToProcess = files;
    }

    const customFiles = Array.from(filesToProcess).map((f) => new CustomFile(f));
    const filesToCompress = customFiles.filter((f) => f.valid).map((file) => processFile(file, quality * 100));
    const compressedRawFiles = await Promise.all(filesToCompress).then((res) => res);
    const compressedFiles = compressedRawFiles.map((file, idx) => {
      const { resultData, resultSize } = file;
      const resultFile = new File([resultData], files[idx].name, { type: JPEG_MIME_TYPE });
      return resultFile;
    });
    return compressedFiles;
  } catch (e) {
    console.log('failed to optimize', e);
    throw e;
  }
}
