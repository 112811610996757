import React from 'react';
import { Col, FormGroup, FormLabel, Input, INPUT_SIZES, RichTextArea, Row, Space, Textarea } from '@premagic/myne';
import { ClientsService, CompanyLeadService } from '@premagic/core';
import LeadSourcePicker from '../leads/LeadSourcePicker';
import InputPhoneNumberContainer from '../../acccount/InputPhoneNumberContainer';

type Props = {
  client?: Partial<ClientsService.ClientType>;
  showNotes?: boolean;
  showAddress?: boolean;
  showLeadSourcePicker?: boolean;
  hasLeadsModule: boolean;
  isCompanyTypeNotPhotographer: boolean;
};

export default function CRMClientForm(props: Props): JSX.Element {
  const {
    client,
    showNotes,
    showLeadSourcePicker = false,
    showAddress,
    hasLeadsModule,
    isCompanyTypeNotPhotographer,
  } = props;
  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input type="text" name="name" defaultValue={client?.name} placeholder="eg. Aman" size={INPUT_SIZES.MD} />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired>Phone</FormLabel>
            <InputPhoneNumberContainer name="phone_number" defaultValue={client?.phone_number} required />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel>Email address</FormLabel>
            <Input type="email" name="email" defaultValue={client?.email} placeholder="eg. aman@premagic.com" />
          </FormGroup>
        </Col>
      </Row>
      {showLeadSourcePicker && hasLeadsModule && (
        <LeadSourcePicker
          channel={client?.channel || CompanyLeadService.LEAD_CHANNELS.CRM}
          defaultValue={client?.source}
        />
      )}
      {showAddress && (
        <FormGroup>
          <FormLabel>Address</FormLabel>
          <Textarea name="address" defaultValue={client?.address} />
        </FormGroup>
      )}
      {showNotes && (
        <FormGroup>
          <FormLabel>Notes</FormLabel>
          <RichTextArea name="notes" defaultValue={client?.notes} />
        </FormGroup>
      )}
    </div>
  );
}
