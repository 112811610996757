import { GlobalVariableService, HelpDeskService, PremagicResources } from '@premagic/core';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, BUTTON_SIZE, BUTTON_STYLES, TextButton, TEXT_BUTTON_STYLES } from '../Button/Buttons';
import { COLOR_SHADES } from '../Color/Color';
import { Dialog, DialogFooter, DialogTitle } from '../Dialog';
import { Emoji, EMOJIE_SIZE, EMOJIS } from '../Emoji/Emojie';
import { Form, FormGroup, FormLabel, FormResponseType, Textarea } from '../Form';
import { Row, Space } from '../Grid';
import { TEXT_SIZE, Text, TEXT_BOLDNESS } from '../Text/Text';
import { Link } from '../Link/Link';
import styles from './user-feedback.module.css';

type UserFeedbackProps = {
  module: string;
  children?: React.ReactNode;
};

export function UserFeedback(props: UserFeedbackProps) {
  const { module, children } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [rating, setRatting] = useState(-5);
  const $button = useRef(null);

  const showReviewLink = rating === 1 && module === 'premagic';

  function onSubmit(response: FormResponseType) {
    const { message = '' } = response.data as {
      message: string;
    };

    HelpDeskService.raiseTicket({
      subject: `USER FEEDBACK - ${module}`,
      email: GlobalVariableService.getGlobalRequestingUser().email || 'text@example.com',
      message: `${message}<br/><br/><br/> Rating: ${rating}`,
      tags: [`userfeedback.${module}`],
    });
    setShowSuccess(true);
  }

  return (
    <>
      <TextButton
        style={TEXT_BUTTON_STYLES.SECONDARY}
        size={BUTTON_SIZE.SM}
        onClick={() => setShowDialog(!showDialog)}
        ref={$button}
      >
        {children || 'Share feedback'}
      </TextButton>
      <Dialog target={$button.current} show={showDialog} onClose={() => setShowDialog(false)}>
        {showSuccess ? (
          <div>
            <Text
              block
              size={TEXT_SIZE.SIZE_3}
              center
              boldness={TEXT_BOLDNESS.BOLD}
              color={COLOR_SHADES.DARKER}
              primaryStyle
            >
              Thank you
            </Text>
            <Space vertical />
            <Text>Your feedback will help us to serve you better.</Text>
            <DialogFooter>
              <Button
                style={BUTTON_STYLES.CANCEL}
                onClick={() => {
                  setShowDialog(false);
                  setShowSuccess(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <div>
            <DialogTitle>Your feedback is important to us.</DialogTitle>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <FormLabel>Are you happy with {module === 'premagic' ? 'Premagic' : 'this feature'}?</FormLabel>
                <Row>
                  <Button style={BUTTON_STYLES.TERTIARY} onClick={() => setRatting(1)} active={rating === 1}>
                    <Emoji name={EMOJIS.SMILING_FACE_WITH_MOUTH_OPEN} size={EMOJIE_SIZE.MD} />
                  </Button>
                  <Button style={BUTTON_STYLES.TERTIARY} onClick={() => setRatting(0)} active={rating === 0}>
                    <Emoji name={EMOJIS.SMILING_FACE_NEUTRAL_FACE} size={EMOJIE_SIZE.MD} />
                  </Button>
                  <Button style={BUTTON_STYLES.TERTIARY} onClick={() => setRatting(-1)} active={rating === -1}>
                    <Emoji name={EMOJIS.SMILING_FACE_ANGER} size={EMOJIE_SIZE.MD} />
                  </Button>
                </Row>
              </FormGroup>
              <FormGroup>
                <FormLabel>Comments</FormLabel>
                <Textarea name="message" />
              </FormGroup>
              {showReviewLink && (
                <Link isExternalLink to={PremagicResources.RESOURCES.writeAReview}>
                  Write a review and get $5
                </Link>
              )}
              <DialogFooter>
                <Button style={BUTTON_STYLES.PRIMARY}>Send</Button>
              </DialogFooter>
            </Form>
          </div>
        )}
      </Dialog>
    </>
  );
}

type RatingsType = Record<string, { id: number; text?: string }>;

const DEFAULT_RATINGS: RatingsType = {
  1: { id: 1, text: 'very bad' },
  2: { id: 2, text: 'bad' },
  3: { id: 3, text: 'neutral' },
  4: { id: 4, text: 'good' },
  5: { id: 5, text: 'excellent' },
};

interface SimpleFeedbackRatingProps {
  name: string;
  onClick?: (option) => void;
  ratings?: RatingsType;
}

export function SimpleFeedbackRating(props: SimpleFeedbackRatingProps): React.ReactElement {
  const { name, onClick, ratings = DEFAULT_RATINGS } = props;

  const [selectedRating, setSelectedRating] = useState(0);
  const totalOptions = Object.keys(ratings).length;
  const optionsArrayInOrder = Object.values(ratings).sort((option1, option2) => option1.id - option2.id);
  const firstOptionText = optionsArrayInOrder[0].text;
  const lastOptionText = optionsArrayInOrder[totalOptions - 1].text;

  function handleClick(id) {
    setSelectedRating(id);
    if (onClick) {
      onClick(id);
    }
  }

  return (
    <>
      <Row spaceBetween>
        {Object.values(ratings).map((option, idx) => {
          const isActive = option.id === selectedRating;

          return (
            <Button
              key={option.id}
              size={BUTTON_SIZE.SM}
              style={BUTTON_STYLES.DEFAULT}
              onClick={() => handleClick(option.id)}
              className={classNames(styles['simple-feedback__button'], {
                [styles[`simple-feedback__button--active`]]: isActive,
              })}
            >
              <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} color={isActive ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}>
                {option.id}
              </Text>
            </Button>
          );
        })}
        <input name={name} value={selectedRating} type="hidden" />
      </Row>
      <Space vertical size={1} />
      <Row spaceBetween>
        <div className={classNames(styles['simple-feedback__text-wrapper'])}>
          <Text size={TEXT_SIZE.SIZE_5} className={classNames(styles['simple-feedback__text'])}>
            {firstOptionText}
          </Text>
        </div>
        <div className={classNames(styles['simple-feedback__text-wrapper'])}>
          <Text size={TEXT_SIZE.SIZE_5} className={classNames(styles['simple-feedback__text'])}>
            {lastOptionText}
          </Text>
        </div>
      </Row>
    </>
  );
}
