import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { EventPosterService, FolderService } from '@premagic/core';
import { POSTER_SIZE, POSTER_SIZE_DETAIL, POSTER_WIDGET_TYPE } from '@premagic/poster-creator';
import EventPosterFilePickerModal from './EventPosterFilePickerModal';
import { LOADINGS } from '../../../../../../common/Constants';
import { folderIdsWithFolderTypeForProjectSelector } from '../../../projects/AccountProjectsDataDuck';
import { eventPostersSelector, updateEventPosterWidget } from '../../../crm/event-posters/EventPostersDataDuck';

const MODAL_KEY = LOADINGS.CLIENT_WEBSITE.SHOW_I_AM_ATTENDING_POSTER_SELECT_MODAL;
function mapStateToProps(state, ownProps: { projectId: string; posterId: string }) {
  const { projectId, posterId } = ownProps;
  const brandingFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);

  const { widgetId } = (ModalDuck.modalOptionsSelector(MODAL_KEY)(state) as { widgetId: string }) || {
    widgetId: undefined,
  };

  const poster = eventPostersSelector(state)[posterId];
  const dummyBackgroundWidget = {
    id: EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
    name: EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
    type: POSTER_WIDGET_TYPE.IMAGE,
    position: {
      x: 0,
      y: 0,
      z: 0,
    },
    size: POSTER_SIZE_DETAIL[POSTER_SIZE.LANDSCAPE],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };
  const widget = poster?.widgets?.[widgetId] || dummyBackgroundWidget;
  return {
    widgetId,
    projectId,
    posterId,
    poster,
    show: ModalDuck.isModalOpenSelector(MODAL_KEY)(state),
    brandingFolderId: brandingFolderIds[0],
    widget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODAL_KEY, false));
    },
    onSelect: ({ projectId, poster, widgetToUpdate }) => {
      dispatch(
        updateEventPosterWidget(dispatch, projectId, {
          poster,
          widgetToUpdate,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterFilePickerModal);
