import { UploaderDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';

import {
  filesUploadStatusInFolderSelector,
  hasFilesPassedTimeoutInQueueStatusSelector,
} from '../AccountFoldersDataDuck';
import FolderFilesUploadError from './FolderFilesUploadError';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
  },
) {
  const { folderId } = ownProps;

  return {
    folderId,
    totalUploaderFiles: UploaderDuck.totalUploaderFilesForFolderSelector(folderId)(state),
    filesUploadStats: filesUploadStatusInFolderSelector(folderId)(state),
    hasFilePassedTimeout: hasFilesPassedTimeoutInQueueStatusSelector(folderId)(state),
  };
}

export default connect(mapStateToProps)(FolderFilesUploadError);
