import { connect } from 'react-redux';
import { ActionConstants, ProposalVariablesDataDuck, ProposalVariableService } from '@premagic/proposals';
import { LoadingDuck, WindowPanelDuck, ErrorDuck, ModalDuck } from '@premagic/common-ducks';
import AddNewProposalVariablePanel from './AddNewProposalVariablePanel';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL_VARIABLES.SHOW_ADD_PANEL;
const COMMON_KEY = ActionConstants.KEYS.PROPOSAL_VARIABLES.ADD;

function mapStateToProps(state) {
  return {
    variableIds: ProposalVariablesDataDuck.proposalsVariablesIdsSelectors(state),
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    modalOptions: ModalDuck.modalOptionsSelector<ProposalVariableService.PROPOSAL_VARIABLE_SCOPE>(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addVariable: (data) => {
      dispatch(ProposalVariablesDataDuck.addNewProposalVariable(dispatch, data));
    },
    closePanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewProposalVariablePanel);
