import { EnvUtils } from '@premagic/utils';

export const NO_PUBLIC_FOLDER_ALERT_INFO =
  "Mark at least one folder as 'Shown in Gallery' to use the photo slider widget.";

export type PhotoSliderWidgetConfigurations = {
  debug: boolean;
  shareId: string;
  numOfImagesToDisplay: number;
  styles: {
    width?: string;
    height?: string;
    maxHeight?: string;
    backgroundColor?: string;
    classNameContainer?: string;
  };
};

function getConfigForPhotoSliderWidget(
  shareId = '',
  numOfImagesToDisplay = 16,
  height, // currently we are not using the height property in the widget, but might use it later if required.
  maxHeight,
  backgroundColor = '#e9ebed',
): PhotoSliderWidgetConfigurations {
  return {
    debug: true,
    shareId,
    numOfImagesToDisplay,
    styles: {
      width: '100%',
      height: height ? `${height}px` : undefined,
      maxHeight: maxHeight ? `${maxHeight}px` : undefined,
      backgroundColor,
    },
  };
}

export function getPremagicWidgetCode(
  shareId?: string,
  numOfImages?: number,
  height?: number,
  maxHeight?: number,
  backgroundColor?: string,
) {
  const config = getConfigForPhotoSliderWidget(shareId, numOfImages, height, maxHeight, backgroundColor);

  return `
  <div id="premagic-widget-root"></div>
  <script>
  (function (w, d, s, o, f, js, fjs) {
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.defer = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "script", "_hw",'https://asts.premagic.com/widgets/premagic-widget.js');
  
      _hw("init", ${JSON.stringify(config)});
  </script>`;
}
