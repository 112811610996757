import { HttpUtils } from '@premagic/utils';
import { APIURLService, EventsService } from '@premagic/core';

export type CalendarOccasionsType = {
  id: string;
  name: string;
  client: {
    id: string;
    name: string;
  };
  event: {
    id: string;
    status: EventsService.EVENT_STATUS;
    name: string;
    number: string;
  };
  start_date_time: string;
  end_date_time: string;
  service_providers: Array<string>;
  notes: string;
  location: string;
  location_link: string;
  postal_code: string;
};

export type CalendarOccasionsFiltersType = {
  start_date_gte: string;
  start_date_lte: string;
  end_date_gte: string;
  end_date_lte: string;
  event_status: string;
};

export function fetchOccasionsForCalendar(
  filters: CalendarOccasionsFiltersType,
): Promise<{ results: Array<CalendarOccasionsType>; count: number }> {
  const options = {
    params: {
      ...filters,
      limit: 100,
    },
  };
  return HttpUtils.get(APIURLService.API_URLS.CRM.CALENDAR, options).then((response) => response.data);
}
