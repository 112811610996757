import React from 'react';
import { Button, BUTTON_STYLES, FormFooter, Modal, MODAL_SIZES, ModalTitle } from '@premagic/myne';
import { SponsorCreativesService } from '@premagic/core';

import SponsorFilesContainer from './SponsorFilesContainer';

type Props = {
  projectId: string;
  onSelect: (name: string, fileId: string) => void;
  show: boolean;
  hideModal: () => void;
  modalOptions?: Record<
    string,
    {
      name: string;
      fileId?: string;
      placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
    }
  >;
};

export default function SponsorFilesSelectFileForCreativeModal(props: Props): JSX.Element | null {
  const { projectId, onSelect, show, hideModal, modalOptions = {} } = props;
  const placement = modalOptions ? Object.values(modalOptions)?.[0]?.placement : undefined;

  function handleOnSelect(fileId) {
    onSelect(Object.keys(modalOptions)[0], fileId);
    hideModal();
  }

  return (
    <Modal show={show} close={hideModal} size={MODAL_SIZES.MD}>
      <ModalTitle>Select a file</ModalTitle>
      <SponsorFilesContainer projectId={projectId} onSelect={handleOnSelect} placement={placement} />
      <FormFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={hideModal}>
          Cancel
        </Button>
      </FormFooter>
    </Modal>
  );
}
