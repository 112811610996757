import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import ToggleFolderStatus from './ToggleFolderStatus';
import {
  folderIdsWithStatusDoneSelector,
  folderIdsInReviewWorkflowSelector,
  toggleReviewStatus,
} from '../../folders/AccountFoldersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  const folderIdsWithStatusDone = folderIdsWithStatusDoneSelector(state);
  const folderIdsInReviewWorkflow = folderIdsInReviewWorkflowSelector(state);
  const foldersIds = folderIdsWithStatusDone.length === 0 ? folderIdsInReviewWorkflow : folderIdsWithStatusDone;
  return {
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PHOTO_SELECTION.REQUEST_SELECTION_AGAIN)(state),
    foldersIds,
    isReviewEnabled: folderIdsWithStatusDone.length === 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleReviewStatus: (projectId, foldersIds, status) => {
      dispatch(toggleReviewStatus(dispatch, projectId, foldersIds, status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleFolderStatus);
