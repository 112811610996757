import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormErrorType,
  FormFooter,
  FormResponseType,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { ProposalVariableService } from '@premagic/proposals';

import ProposalVariableFormContainer from './ProposalVariableFormContainer';

type Props = {
  editVariable: (id: string, data: FormResponseType) => void;
  modalOptions?: ProposalVariableService.PROPOSAL_VARIABLE_SCOPE;
  show: boolean;
  isLoading: boolean;
  errors?: FormErrorType | unknown;
  closePanel: (id: string) => void;
  idOpenForEdit?: string;
  variableData: Record<string, ProposalVariableService.ProposalVariableType>;
};

export default function EditProposalVariablePanel(props: Props): JSX.Element {
  const {
    show,
    editVariable,
    isLoading,
    errors,
    closePanel,
    modalOptions = ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.DEFAULT,
    idOpenForEdit,
    variableData,
  } = props;

  const name = idOpenForEdit && variableData[idOpenForEdit]?.name;

  function handleFormSubmit(formData) {
    if (idOpenForEdit && name) {
      editVariable(idOpenForEdit, { data: { ...formData.data, name } });
    }
  }

  function handleClose() {
    if (idOpenForEdit) {
      closePanel(idOpenForEdit);
    }
  }

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={handleClose}>
        <WindowPanelTitle>Edit {name}</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form errors={errors as FormErrorType} onSubmit={handleFormSubmit}>
          <ProposalVariableFormContainer scope={modalOptions} id={idOpenForEdit} disableNameInput />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={handleClose} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Add
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
