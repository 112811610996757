import { FolderService } from '@premagic/core';
import { BUTTON_SIZE, BUTTON_STYLES, Button, Divider, Row, Space, TEXT_BOLDNESS, Text } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../../../../../services/AppRouteURLService';
import WatermarkItemContainer from './WatermarkItemContainer';

type Props = {
  sponsorCreativeWatermarkIds?: Array<string>;
  folder: FolderService.FolderType;
  navigateTo: (url: string) => void;
  eventId: string;
};

export default function FolderWatermarkListItem(props: Props) {
  const { sponsorCreativeWatermarkIds, folder, navigateTo, eventId } = props;
  const { folder_name: folderName, folder_id: folderId } = folder;

  if (!folderName || !sponsorCreativeWatermarkIds) return null;

  return (
    <div>
      <Row vcenter>
        <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>{folderName}</Text>
        <Space />
        <Button
          size={BUTTON_SIZE.XS}
          style={BUTTON_STYLES.TERTIARY}
          onClick={() =>
            navigateTo(
              BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__SPONSORS__FOLDER_WATERMARK_PREVIEW, {
                eventId,
                folderId,
              }),
            )
          }
        >
          Preview watermark
        </Button>
      </Row>
      <Row vcenter wrap>
        {sponsorCreativeWatermarkIds?.map((sponsorCreativeWatermarkId) => (
          <WatermarkItemContainer
            key={sponsorCreativeWatermarkId}
            sponsorCreativeWatermarkId={sponsorCreativeWatermarkId}
          />
        ))}
      </Row>
      <Divider />
      <Space vertical />
    </div>
  );
}
