import { QRCodeBackgroundPosterService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_SIZES,
  CARD_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  Row,
  SimpleImage,
  Space,
  SubTitle,
} from '@premagic/myne';
import React, { useState } from 'react';
import QRCodePosterCardImage from '../common-ui/QRCodePosterCardImage';

type Props = {
  folderId: string;
  projectId: string;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  showSelectFileModal: () => void;
  removeIllustration: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
  ) => void;
  illustrationImageSrc?: string;
  backgroundCover: QRCodeBackgroundPosterService.QRCodePoster;
};

export default function QRCodePosterIllustrationSelector(props: Props) {
  const {
    folderId,
    projectId,
    qrCodeDesigns,
    illustrationImageSrc = '',
    showSelectFileModal,
    removeIllustration,
    backgroundCover,
  } = props;

  const customIllustration = qrCodeDesigns?.custom_illustration;

  if (!backgroundCover?.customIllustration) return null;

  return (
    <Row columnDirection vcenter>
      <QRCodePosterCardImage>
        {!customIllustration ? (
          <Color inline shade={COLOR_SHADES.LIGHT}>
            <Icon name="image" size={ICON_SIZES.MD} />
          </Color>
        ) : (
          <SimpleImage
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            src={illustrationImageSrc}
            alt="qr-poster-illustration"
          />
        )}
      </QRCodePosterCardImage>
      <Space vertical size={1} />
      <Row vcenter center={!!customIllustration} spaceBetween={!customIllustration}>
        <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={() => showSelectFileModal()}>
          {!customIllustration ? `Select` : 'Edit'}
        </Button>
        {customIllustration && (
          <Button
            size={BUTTON_SIZE.XS}
            style={BUTTON_STYLES.LINK}
            onClick={() => removeIllustration(projectId, folderId, qrCodeDesigns)}
          >
            Remove
          </Button>
        )}
      </Row>
    </Row>
  );
}
