import { EnvUtils, ErrorTracker, HttpUtils } from '@premagic/utils';
import { APIURLService, EventTrackerService, PremagicResources, PricingPlanService } from '@premagic/core';

// https://razorpay.com/docs/payment-gateway/web-integration/standard/
declare const Razorpay;

export function fetchPricingPlans(): Promise<Array<PricingPlanService.PricingPlanType>> {
  return HttpUtils.get(APIURLService.API_URLS.PRICING_PLANS.ROOT).then(({ data }) =>
    Object.entries(data).map(([key, value]) => Object.assign(value as PricingPlanService.PricingPlanType, { id: key })),
  );
}

export function showPaymentModal(
  subscriptionId: string,
  amount: number,
  description: string,
  userData: {
    name: string;
    email: string;
    phone?: string;
  },
  cbHandler: (response: {
    razorpay_payment_id: string;
    razorpay_subscription_id: string;
    razorpay_signature: string;
  }) => void,
  onClose: (response) => void,
): void {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.PAYMENT.CLICK_BUY, {
    amount,
  });

  // https://razorpay.com/docs/api/subscriptions/#authentication-transaction

  const options = {
    key: EnvUtils.isProd() ? 'rzp_live_aWvkMLvBcmrM2a' : 'rzp_test_BslS4GcrqEH41J',
    name: 'Premagic',
    image: PremagicResources.RESOURCES.publicLogo,
    subscription_id: subscriptionId,
    description,
    handler: cbHandler,
    modal: {
      ondismiss() {
        onClose('Checkout form closed');
      },
    },
    prefill: {
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
    },
    notes: {},
    theme: {
      color: '#047af0',
    },
  };

  const rzp1 = new Razorpay(options);
  rzp1.on('payment.failed', (response) => {
    onClose(response);
    ErrorTracker.logError('PAYMENT_FAILED', response.error.reason);
  });

  rzp1.open();
}
