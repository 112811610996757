import React from 'react';

import {
  Dropdown,
  DropdownIcon,
  Icon,
  LoadingDots,
  Menu,
  MENU_ITEM_STYLES,
  MenuItem,
  MenuItemIcon,
} from '@premagic/myne';
import { ProposalDeckService, ProposalService } from '@premagic/proposals';

import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import ProposalCreatorDeleteDialogContainer from './ProposalCreatorDeleteDialogContainer';
import ProposalRenameDialogContainer from '../../proposal-templates/ProposalRenameDialogContainer';
import ProposalEditConfirmationDialogContainer from '../../proposal-templates/ProposalEditConfirmationDialogContainer';

type Props = {
  projectId: string;
  folderId: string;
  deckId: string;
  proposal: ProposalService.ProposalType;
  showDeleteDialog: () => void;
  deck?: ProposalDeckService.ProposalDeckType;
  createNewVersion: (
    projectId: string,
    deckId: string,
    options: {
      folderId: string;
    },
  ) => void;
  showProposalHistory: () => void;
  showProposalThumbnail: () => void;
  duplicateProposal: (name: string, folderId: string, projectId: string) => void;
  isCreatingProposalLoading: boolean;
  showRenameFolder: (id) => void;
  showConfirmEditProposalDialog: () => void;
};

export default function ProposalCreatorHeaderOptionsMenu(props: Props) {
  const {
    projectId,
    folderId,
    showDeleteDialog,
    deckId,
    proposal,
    createNewVersion,
    deck,
    showProposalHistory,
    showProposalThumbnail,
    duplicateProposal,
    isCreatingProposalLoading,
    showRenameFolder,
    showConfirmEditProposalDialog,
  } = props;
  const isDeckPublished = deck?.status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;
  const isDeckApproved = deck?.review_status === ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.APPROVED;

  return (
    <span className={JS_SELECTORS.PROPOSAL_MORE_OPTIONS}>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="left">
          {isCreatingProposalLoading ? <LoadingDots size="sm" /> : <Icon name="more_vertical" />}
        </DropdownIcon>
        <Menu>
          {isDeckPublished && (
            <MenuItem
              onClick={() =>
                isDeckApproved
                  ? showConfirmEditProposalDialog()
                  : createNewVersion(projectId, deckId, {
                      folderId,
                    })
              }
            >
              <MenuItemIcon name="edit_2" />
              Edit Proposal
            </MenuItem>
          )}
          <MenuItem onClick={() => showRenameFolder(folderId)}>
            <MenuItemIcon name="edit_2" />
            Edit Name
          </MenuItem>
          <MenuItem onClick={showProposalThumbnail}>
            <MenuItemIcon name="image" />
            Proposal Thumbnail
          </MenuItem>
          <MenuItem onClick={() => duplicateProposal(`Copy of ${proposal.folder_name}`, folderId, projectId)}>
            <MenuItemIcon name="copy" />
            Duplicate
          </MenuItem>
          <MenuItem onClick={showProposalHistory}>
            <MenuItemIcon name="clock" />
            History
          </MenuItem>
          <MenuItem
            onClick={showDeleteDialog}
            style={MENU_ITEM_STYLES.DANGER}
            disabled={isDeckApproved}
            disabledMessage="You cannot delete a approved proposal"
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      <ProposalCreatorDeleteDialogContainer projectId={projectId} folderId={folderId} />
      <ProposalRenameDialogContainer projectId={projectId} folderId={folderId} />
      <ProposalEditConfirmationDialogContainer projectId={projectId} folderId={folderId} deckId={deckId} />
    </span>
  );
}
