import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { modalOptionsSelector, setModalOptions } from '../../../../../../../../common/ModalDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../../../common/WindowPanelDuck';
import { removeSponsorCreativeFromSponsor } from '../../../event-sponsors/EventSponsorDataDuck';
import { getSponsorCreativeDataById, updateSponsorCreativeWatermark } from '../../SponsorCreativeDataDuck';
import EditSponsorWatermarkPanel from './EditSponsorWatermarkPanel';

const PANEL_KEY = LOADINGS.SPONSOR_CREATIVES.SHOW_EDIT_SPONSOR_WATERMARK_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  const sponsorCreativeWatermarkId = modalOptionsSelector(PANEL_KEY)(state) as string;
  const sponsorCreativeWatermark = sponsorCreativeWatermarkId
    ? getSponsorCreativeDataById(sponsorCreativeWatermarkId)(state)
    : undefined;

  return {
    projectId,
    sponsorId: sponsorCreativeWatermark?.sponsor_id,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER_META.UPDATE)(state),
    error: errorObjectForTypeSelector(LOADINGS.FOLDER_META.UPDATE)(state),
    sponsorCreativeWatermarkId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSponsorCreativeWatermark: (projectId, creativeId, formResponse) => {
      dispatch(updateSponsorCreativeWatermark(dispatch, { projectId, creativeId }, formResponse));
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
    close: () => dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
    clearSponsorModalOptions: () =>
      dispatch(
        setModalOptions(dispatch, LOADINGS.SPONSOR_CREATIVES.SELECT_FILE_MODAL, {
          file: {
            file: '',
            fileId: '',
          },
        }),
      ),
    deleteSponsorCreativeWatermark: (projectId: string, sponsorId: string, creativeId: string) => {
      dispatch(removeSponsorCreativeFromSponsor(dispatch, { projectId, sponsorId, creativeId }));
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSponsorWatermarkPanel);
