import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Form,
  FormFooter,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import SwitchLanguageFormContainer from '../form/SwitchLanguageFormContainer';

type Props = {
  projectId?: string;
  updateProjectMeta: (projectId: string, formResponse) => void;
  show: boolean;
  isLoading: boolean;
  errors?: Record<string, string>;
  closePanel: () => void;
  eventId: string;
};

export default function SelectDefaultLanguagePanel(props: Props): JSX.Element {
  const { projectId, show, isLoading, errors, closePanel, eventId, updateProjectMeta } = props;
  return (
    <WindowPanel show={show} size={WINDOW_PANEL_SIZES.MD}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Language for Public (Gallery, Registration pages)</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          errors={errors}
          onSubmit={(data) => {
            if (!projectId) return;
            updateProjectMeta(projectId, data);
            closePanel();
          }}
        >
          <SwitchLanguageFormContainer eventId={eventId} projectId={projectId} />
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel} size={BUTTON_SIZE.LG}>
              Cancel
            </Button>
            <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
