import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import {
  folderFilesStatsWithFolderTypeForProjectSelector,
  folderIdsWithFolderTypeForProjectSelector,
  projectsSelector,
} from '../../../projects/AccountProjectsDataDuck';
import SignatureAlbumCardHeader from './SignatureAlbumCardHeader';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.HIGHLIGHT,
  )(state);
  return {
    signatureFolderIds,
    hasSignatureAlbum: signatureFolderIds.length > 0,
    projectId,
    project: projectsSelector(state)[projectId],
    folderFilesStats: folderFilesStatsWithFolderTypeForProjectSelector(
      projectId,
      FolderService.FOLDER_TYPES.HIGHLIGHT,
    )(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

export default connect(mapStateToProps)(SignatureAlbumCardHeader);
