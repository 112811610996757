import { EventAttendeesService, EventPosterService, FolderService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Label,
  LABEL_STYLES,
  LIST_ITEM_STYLES,
  ListItem,
  ListItemAction,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { getPosterSizeDetailsFromSpec } from '@premagic/poster-creator';
import { BrowserUrlUtils } from '@premagic/utils';
import React from 'react';
import { JS_SELECTORS } from '../../../../../common/JSClassSelectors';

import APP_URLS from '../../../services/AppRouteURLService';
import EventPosterItemOptionsContainer from '../../event-poster-creator/EventPosterItemOptionsContainer';

type Props = {
  eventId: string;
  projectId: string;
  poster: EventPosterService.EventPosterType;
  focusId?: string;
  navigateTo: (link: string) => void;
  clientWebsite?: FolderService.FolderType;
  listStyle: 'list' | 'mini-item';
};

export default function EventPosterListItem(props: Props): JSX.Element {
  const { eventId, projectId, poster, focusId, navigateTo, clientWebsite, listStyle } = props;

  const { id: posterId, name, status, scope, type, size } = poster;

  const posterEdit = BrowserUrlUtils.getRouteUrlFor(APP_URLS.EVENT_POSTER.EDIT, {
    eventId,
    projectId,
    posterId,
    type,
  });
  const preEventPosters = poster.type === EventPosterService.EVENT_POSTER_TYPE.PRE_EVENT;
  // https://saasboomi.premagic.com/saasboomi-qafila/poster/create/attendee/
  const linkToPoster = preEventPosters
    ? EventPosterService.getPosterPreviewUrl({
        poster,
        clientWebsite,
      })
    : undefined;
  const posterSize = getPosterSizeDetailsFromSpec(size.width, size.height);
  if (listStyle === 'list')
    return (
      <ListItem highlight={posterId === focusId}>
        <Col size={3}>
          <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block>
            {name}
            {status == EventPosterService.EVENT_POSTER_STATUS.DRAFT && (
              <>
                <Space size={2} />
                <Text size={TEXT_SIZE.SIZE_5} color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD}>
                  DISABLED
                </Text>
              </>
            )}
          </Text>
        </Col>
        <Col size={2}>
          {scope.map((scopeItem) => {
            const peopleDetails = EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS[scopeItem];
            return (
              <Label style={LABEL_STYLES.DEFAULT} key={peopleDetails.name}>
                {peopleDetails.name}
              </Label>
            );
          })}
        </Col>
        <Col size={2}>
          <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_5}>
            {posterSize.name === 'Custom' ? `${Math.floor(size.width)}x${Math.floor(size.height)}` : posterSize.name}
          </Text>
        </Col>
        <ListItemAction className={JS_SELECTORS.POSTER(posterId)}>
          {linkToPoster && (
            <Button style={BUTTON_STYLES.LINK} link={linkToPoster} size={BUTTON_SIZE.SM} isExternalLink>
              <Icon name="external_link" size={ICON_SIZES.SM} />
              <Space size={1} />
              Open
            </Button>
          )}
          <Button style={BUTTON_STYLES.LINK} link={posterEdit} size={BUTTON_SIZE.SM}>
            <Icon name="edit_2" size={ICON_SIZES.SM} />
            <Space size={1} />
            Edit
          </Button>
          <Space />
          <EventPosterItemOptionsContainer
            eventId={eventId}
            posterId={posterId}
            projectId={projectId}
            location="list"
          />
        </ListItemAction>
      </ListItem>
    );
  return (
    <ListItem highlight={posterId === focusId} style={LIST_ITEM_STYLES.COMPACT_LIST} to={posterEdit} showHoverStyle>
      <Text block boldness={TEXT_BOLDNESS.BOLD}>
        {name}
      </Text>
    </ListItem>
  );
}
