import { connect } from 'react-redux';
import EventAttendeeFaceCheckinStatsList from './EventAttendeeFaceCheckinStatsList';
import {
  eventAttendeeCheckinStatsArraySelector,
  eventAttendeeCheckinZonesDataSelector,
  getEventAttendeesCheckinStats,
  getEventAttendeesCheckinZones,
} from '../EventAttendeesCheckinZonesDataDuck';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../../../common/Constants';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;
  return {
    projectId,
    checkinZoneData: eventAttendeeCheckinZonesDataSelector(state),
    checkinStats: eventAttendeeCheckinStatsArraySelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.STATS_FETCH)(state) ||
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.CHECKIN_ZONES.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCheckinStats: (projectId) => {
      dispatch(getEventAttendeesCheckinStats(dispatch, projectId));
    },
    getEventAttendeesCheckinZones: (projectId: string) => {
      dispatch(getEventAttendeesCheckinZones(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeFaceCheckinStatsList);
