import { EventAttendeesService, EventPosterService, PeoplePosterService } from '@premagic/core';
import { Text } from '@premagic/myne';
import React, { useEffect, useMemo } from 'react';
import {
  BADGE_PUNCH_HOLE_STYLE,
  PosterFileType,
  PosterWidgetType,
  PosterCreatorPoster,
} from '@premagic/poster-creator';
import { EVENT_POSTER_VIEW_OPTIONS } from './EventPosterCreatorDuck';

type Props = {
  poster?: EventPosterService.EventPosterType;
  projectId: string;
  files: Record<string, PosterFileType>;
  selectedEventPosterWidgetId?: string;
  setSelectedWidget: (widgetId?: string) => void;
  punchHoleStyle: BADGE_PUNCH_HOLE_STYLE;
  user?: EventAttendeesService.EventAttendeeType;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetType;
  }) => void;
  contextMenuPosition: { top: string; left: string };
  setShowContextMenu: (position?: { top: string; left: string }) => void;
  onDuplicateWidget?: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetId: string;
  }) => void;
  onDeleteWidget?: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetId: string;
  }) => void;
  viewOptions: Array<EVENT_POSTER_VIEW_OPTIONS>;
};

export default function EventPosterCreatorPreview(props: Props) {
  const {
    poster,
    files,
    selectedEventPosterWidgetId,
    setSelectedWidget,
    punchHoleStyle,
    user,
    editWidget,
    projectId,
    setShowContextMenu,
    contextMenuPosition,
    onDuplicateWidget,
    onDeleteWidget,
    viewOptions,
  } = props;
  const widgets = poster?.widgets || {};

  const peoplePosterData = useMemo(() => {
    if (!poster) return undefined;
    const widgetsToShow = poster.widgets;
    const posterUserInfo = PeoplePosterService.getPosterUserDataFromAttendee(user);
    const posterSessionInfo = {
      [EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE]:
        'Session Title: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
    };
    const exhibitorInfo = {
      [EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_LOGO]: 'https://via.placeholder.com/150',
      [EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_BOOTH_NUMBER]: 'E 00123',
    };
    return PeoplePosterService.getPosterWithData(
      Object.assign({}, poster, {
        widgets: widgetsToShow,
      }),
      {
        user: posterUserInfo,
        session: posterSessionInfo,
        exhibitor: exhibitorInfo,
      },
    );
  }, [poster, user]);

  useEffect(() => {
    // If the background is empty then set the active widget as background
    if (!selectedEventPosterWidgetId) {
      const backgroundWidget = Object.values(widgets).find(
        (widget) => widget.name === EventPosterService.EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
      );

      setSelectedWidget(backgroundWidget?.value ? widgets[0]?.id : backgroundWidget?.id);
    }
  }, []);

  if (peoplePosterData && poster) {
    return (
      <PosterCreatorPoster
        data={peoplePosterData}
        files={files}
        activeWidgetId={selectedEventPosterWidgetId}
        widgetOnClick={setSelectedWidget}
        badgePunchHoleStyle={punchHoleStyle}
        editWidget={(widget) => {
          editWidget({
            poster,
            projectId,
            widgetToUpdate: widget,
          });
        }}
        setShowContextMenu={setShowContextMenu}
        contextMenuPosition={contextMenuPosition}
        onDuplicateWidget={(id) => onDuplicateWidget?.({ projectId, poster, widgetId: id })}
        onDeleteWidget={(id) => onDeleteWidget?.({ projectId, poster, widgetId: id })}
        showRuler={viewOptions.includes(EVENT_POSTER_VIEW_OPTIONS.RULER)}
      />
    );
  }

  return <Text>wont happen</Text>;
}
