import { connect } from 'react-redux';
import { requestingUserSelector, saveUserProfile } from '../crm/users/UsersDataDuck';
import EditUserProfilePage from './EditUserProfilePage';
import { LOADINGS } from '../../../../common/Constants';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../common/ErrorDuck';

const COMMON_KEY = LOADINGS.USERS.UPDATE;
function mapStateToProps(state) {
  return {
    user: requestingUserSelector(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveUserProfile: (userId, data) => {
      dispatch(saveUserProfile(dispatch, userId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserProfilePage);
