import { connect } from 'react-redux';
import { isMobileUp } from '../AppDuck';
import CustomerClient from './CustomerClient';
import { customerClientsItemsSelector } from './CustomerClientsDataDuck';

function mapStateToProps(state, ownProps) {
  const client = customerClientsItemsSelector(state)[ownProps.id];

  return {
    client,
    isMobileUp: isMobileUp(state),
  };
}

export default connect(mapStateToProps)(CustomerClient);
