import { StorePricingService } from '@premagic/core';
import { connect } from 'react-redux';
import PhotoDownloadSettings from './PhotoDownloadSettings';

function mapStateToProps(
  state,
  ownProps: {
    defaultSubProductPrices?: StorePricingService.StorePricingType;
    product: StorePricingService.ProductItemType;
    onSave: (data) => void;
    onClose: () => void;
  },
) {
  const { product, onSave, onClose, defaultSubProductPrices = {} } = ownProps;
  return { product, onSave, onClose, defaultSubProductPrices };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoDownloadSettings);
