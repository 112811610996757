import { RouterService } from '@premagic/core';
import { connect } from 'react-redux';
import { filesSelector } from '../../../images/FilesDataDuck';
import DAMFilesThumbnails from './DAMFilesThumbnails';

function mapStateToProps(state, ownProps) {
  const { fileId, filters, folderId } = ownProps;

  const file = filesSelector(state)[fileId];

  return { fileId, file, filters, folderId };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url, {});
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMFilesThumbnails);
