import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';

import { isMobileUp } from '../../AppDuck';
import { companyDataSelector } from '../company/CompanyDataDuck';
import { fetchPricingPlansData, pricingPlanValuesSelector } from '../pricing-plan/PricingPlansDataDuck';
import {
  clientSettingsDataSelector,
  isCompanyTypeNotPhotographerSelector,
} from '../preferences/ClientSettingsDataDuck';
import SubscriptionsPlans from './SubscriptionsPlans';

function mapStateToProps(state) {
  return {
    pricingPlans: pricingPlanValuesSelector(state),
    company: companyDataSelector(state),
    isLoading: isLoadingSelector(LOADINGS.PRICING_DATA)(state),
    isMobileUp: isMobileUp(state),
    clientSettings: clientSettingsDataSelector(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPricingPlan: () => {
      dispatch(fetchPricingPlansData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPlans);
