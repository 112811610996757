import { ProposalDeckService, ProposalService } from '@premagic/proposals';
import { COLOR_SHADES, HTML_ICON, Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE, TimeAgo, Tooltip } from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';

type Props = {
  deck?: ProposalDeckService.ProposalDeckType;
  proposal: ProposalService.ProposalType;
};

export default function EventProposalDeckExpiryInfo(props: Props): JSX.Element | null {
  const { deck, proposal } = props;
  const { expiry_date: expiryDate } = proposal;
  const { status, review_status: reviewStatus } = deck || {};

  const isPublished = status === ProposalDeckService.PROPOSAL_DECK_STATUS.PUBLISHED;
  const isApproved = reviewStatus === ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.APPROVED;
  const expiryDateString = expiryDate
    ? SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(expiryDate)
    : SimpleDateUtils.now();
  const hasExpiryPast = SimpleDateUtils.isPastDate(expiryDateString);

  if (!deck) return null;
  if (!isPublished) return null;
  if (isApproved) return null;

  if (hasExpiryPast)
    return (
      <div>
        <Space size={2} />
        <Tooltip message={SimpleDateUtils.fromNowForTooltip(expiryDateString)}>
          <Text color={COLOR_SHADES.DANGER} size={TEXT_SIZE.SIZE_6} boldness={TEXT_BOLDNESS.BOLD}>
            Expired
          </Text>
        </Tooltip>
      </div>
    );

  return (
    <div>
      <Space size={2} />
      <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6} boldness={TEXT_BOLDNESS.BOLD}>
        Expires in <TimeAgo date={expiryDateString} />
      </Text>
    </div>
  );
}
