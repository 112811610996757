import { connect } from 'react-redux';
import { clientWebsiteIsEventNameOrGroomNameHiddenSelector, clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsitePeopleDetailsForm from './ClientWebsitePeopleDetailsForm';

function mapStateToProps(state, ownProps) {
  const { id, eventName } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    eventName,
    isEventNameOrGroomNameHidden: clientWebsiteIsEventNameOrGroomNameHiddenSelector(id)(state),
  };
}

export default connect(mapStateToProps)(ClientWebsitePeopleDetailsForm);
