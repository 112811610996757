import { connect } from 'react-redux';
import { FolderService } from '@premagic/core';
import {
  folderIdsWithFolderTypeForProjectSelector,
  guestGalleryLinkSelector,
} from '../../../projects/AccountProjectsDataDuck';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';
import SignatureAlbumCard from './SignatureAlbumCard';
import { isCompanyTypeNotPhotographerSelector } from '../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; focus: boolean }) {
  const { projectId, focus } = ownProps;
  const signatureFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.HIGHLIGHT,
  )(state);
  const folders = foldersEntitiesSelector(state);
  return {
    focus,
    folderIds: signatureFolderIds,
    folders: signatureFolderIds.map((folderId) => folders[folderId]),
    hasSignatureAlbum: signatureFolderIds.length > 0,
    projectId,
    guestGalleryLink: guestGalleryLinkSelector(projectId)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

export default connect(mapStateToProps)(SignatureAlbumCard);
