import React from 'react';
import { Divider, List, ListItem, Row, Space, SubTitle } from '@premagic/myne';
import { AccountType } from '../acccount/AccountService';

type Props = {
  activeAccount?: AccountType;
  accounts: Array<AccountType>;
  switchAccount: (id: string) => void;
};

export default function UserAccounts(props: Props): JSX.Element | null {
  const { activeAccount, accounts, switchAccount } = props;
  if (accounts.length === 1) return null;
  return (
    <div>
      <Space vertical size={2} />
      <Row>
        <Space size={4} />
        <SubTitle>Other accounts</SubTitle>
      </Row>
      <Space size={2}>
        <List>
          {accounts
            .filter((account) => account.id !== activeAccount?.id)
            .map((account) => (
              <ListItem onClick={() => switchAccount(account.id)} key={account.id}>
                {account.name}
              </ListItem>
            ))}
        </List>
      </Space>
      <Divider />
    </div>
  );
}
