import { MediaUtils } from '@premagic/utils';
import React from 'react';
import { Group as CanvasGroup, Rect as CanvasRect } from 'react-konva';
import { POSTER_WIDGET_TYPE, PosterWidgetGuestImageType, PosterWidgetTextType } from '../services/PosterWidgetService';
import { PosterText } from './BaseWidgets';
import { PosterFileType } from '../services/PosterService';
import PosterImage from './PosterImage';

function getSizeForTheImage(options: { file?: PosterFileType; size: { width: number; height?: number } }) {
  const { file, size } = options;
  const { width, height = 0 } = size;
  const ratio = file?.meta?.ratio || 1;
  const heightForWidth = MediaUtils.getImageHeightForRatio(width, ratio);
  const widthForHeight = MediaUtils.getImageWidthForRatio(height, ratio);
  return ratio > 1 ? { width, height: heightForWidth } : { width: widthForHeight, height };
}

type PosterGuestImageType = {
  data: PosterWidgetGuestImageType;
  files: Record<string, PosterFileType>;
};

const PosterGuestImage = React.forwardRef((props: PosterGuestImageType, ref: React.Ref<any>) => {
  const { data, files, ...rest } = props;
  const { position, size, value, id } = data;
  const { width, height = 0 } = size;
  const file = value ? files[value] : undefined;
  const resizeCard = true;
  const sizeForImage = resizeCard
    ? getSizeForTheImage({
        file,
        size,
      })
    : size;

  const color = '#fff';
  const placeHolderColor = '#4a90e2';
  const frameWidth = (width + height) * 0.02; // Frame will scale based on the width
  const fontsize = (width + height) * 0.05; // scale based on the width
  const photoFrameProps = {
    fill: placeHolderColor,
    strokeWidth: frameWidth,
    stroke: color,
    shadowColor: '#666',
    shadowBlur: 8,
    shadowOffset: {
      x: 2,
      y: 2,
    },
    shadowOpacity: 0.8,
  };
  const textProps: PosterWidgetTextType = {
    type: POSTER_WIDGET_TYPE.TEXT,
    id: 'text',
    name: 'text',
    font_color: 'rgba(255,255,255,0.8)',
    position: {
      x: position.x + frameWidth + fontsize / 2,
      y: position.y + frameWidth + fontsize / 2,
      z: 3,
      rotation: position.rotation,
    },
    size: {
      width: Math.min(fontsize * 10, width),
      height: fontsize,
    },
    font_size: fontsize,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  if (!value)
    return (
      <CanvasGroup>
        <CanvasRect
          id="photo-frame"
          ref={ref}
          x={position.x}
          y={position.y}
          z={position.z}
          rotation={position.rotation}
          width={width}
          height={height}
          {...photoFrameProps}
          {...rest}
        />
        <PosterText data={{ ...textProps, value: 'Guest photo' }} />
      </CanvasGroup>
    );

  return (
    <PosterImage
      {...photoFrameProps}
      ref={ref}
      data={{
        ...data,
        type: POSTER_WIDGET_TYPE.IMAGE,
        size: sizeForImage,
      }}
      files={files}
      {...rest}
    />
  );
});

export default PosterGuestImage;
