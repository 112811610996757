import { connect } from 'react-redux';
import { History } from 'history';

import { AuthDuck } from '@premagic/common-ducks';
import AccountAppRoutes from './AccountAppRoutes';
import { isLoadingSelector } from '../../common/LoadingDuck';
import { LOADINGS } from '../../common/Constants';

function mapStateToProps(state, ownProps: { history: History }) {
  return {
    isLoggedIn: AuthDuck.isUserLoggedInSelector(state),
    isAuthLoading: isLoadingSelector(LOADINGS.AUTH.LOADING)(state),
    history: ownProps.history,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearAuthState: () => {
      dispatch(AuthDuck.clearAuthState(dispatch));
    },
    initAuth: () => {
      dispatch(AuthDuck.initAuth(dispatch));
    },
    tokenRefresh: (data) => {
      dispatch(AuthDuck.tokenRefresh(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountAppRoutes);
