import { CRM_FILTER_KEYS } from './CRMFilterTypes';

export const CRM_FILTERS_DETAILS = {
  [CRM_FILTER_KEYS.EVENT_STATUS]: {
    key: CRM_FILTER_KEYS.EVENT_STATUS,
    label: 'Status',
  },
  [CRM_FILTER_KEYS.STATUS]: {
    key: CRM_FILTER_KEYS.STATUS,
    label: 'Status',
  },
  [CRM_FILTER_KEYS.EVENT_SORT_BY]: {
    key: CRM_FILTER_KEYS.EVENT_SORT_BY,
    label: 'Sort By',
  },
  [CRM_FILTER_KEYS.EVENT_LABELS]: {
    key: CRM_FILTER_KEYS.EVENT_LABELS,
    label: 'Labels',
  },
  [CRM_FILTER_KEYS.PAYMENT_STATUS]: {
    key: CRM_FILTER_KEYS.STATUS,
    label: 'Payments',
  },
};
