import React from 'react';
import { History } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import APP_URLS from '../services/AppRouteURLService';
import LibraryPageContainer from '../components/projects/library-dam/DAMLibraryPageContainer';

type Props = {
  history: History;
};

export default function DAMLibraryPagesRoutes(props: Props): JSX.Element {
  const { history } = props;
  return (
    <Router history={history}>
      <Switch>
        <Route path={APP_URLS.LIBRARY.INDEX} component={LibraryPageContainer} />
      </Switch>
    </Router>
  );
}
