import { AVATAR_STYLE, POSTER_WIDGET_TYPE, PosterType, PosterWidgetType } from '@premagic/poster-creator';
import { HttpUtils, MathUtils } from '@premagic/utils';
import { List as IList } from 'immutable';
import { isArray } from 'lodash';
import { API_URLS } from './APIURLService';
import { CLIENT_WEBSITE_LINK } from './ClientWebsiteService';
import { EVENT_PEOPLE_TYPE } from './EventAttendeesService';
import { FolderFileType } from './FileService';
import { FolderType } from './FolderService';

const { getMeasurementUnitForX, getMeasurementUnitForY } = MathUtils;

export enum EVENT_POSTER_TYPE {
  PRE_EVENT = 'PRE_EVENT', // I am attending
  POST_EVENT = 'POST_EVENT',
  // I_AM_ATTENDING = 'I_AM_ATTENDING',
  DURING_EVENT = 'DURING_EVENT',
  BADGE = 'BADGE',
  MAGAZINE = 'MAGAZINE',
}

export const EVENT_POSTER_TYPE_DETAILS: Record<EVENT_POSTER_TYPE, { title: string }> = {
  [EVENT_POSTER_TYPE.PRE_EVENT]: {
    title: 'Advocacy Poster',
  },
  [EVENT_POSTER_TYPE.POST_EVENT]: {
    title: 'Post-event',
  },
  // [EVENT_POSTER_TYPE.I_AM_ATTENDING]: {
  //   title: 'I am attending',
  // },
  [EVENT_POSTER_TYPE.DURING_EVENT]: {
    title: 'During event',
  },
  [EVENT_POSTER_TYPE.BADGE]: {
    title: 'Badge',
  },
  [EVENT_POSTER_TYPE.MAGAZINE]: {
    title: 'Magazine',
  },
};
export enum EVENT_POSTER_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum EVENT_POSTER_SYSTEM_WIDGETS {
  BACKGROUND_POSTER = 'BACKGROUND_POSTER',
  AVATAR = 'AVATAR',
  USER_NAME = 'USER_NAME',
  USER_ROLE = 'USER_ROLE',
  USER_COMPANY = 'USER_COMPANY',
  DISPLAY_ID = 'DISPLAY_ID',
  // QR_CODE_VCARD = 'QR_CODE_VCARD',
  // QR_CODE_REGISTRATION_CHECKIN = 'QR_CODE_REGISTRATION_CHECKIN',
  GUEST_IMAGE = 'GUEST_IMAGE',
  GUEST_IMAGE_GALLERY = 'GUEST_IMAGE_GALLERY',
  GUEST_IMAGE_PORTRAIT = 'GUEST_IMAGE_PORTRAIT',
  SESSION_TITLE = 'SESSION_TITLE',
  EXHIBITOR_LOGO = 'EXHIBITOR_LOGO',
  EXHIBITOR_BOOTH_NUMBER = 'EXHIBITOR_BOOTH_NUMBER',
}

export const EVENT_POSTER_SYSTEM_WIDGETS_DETAILS: Record<
  EVENT_POSTER_SYSTEM_WIDGETS,
  {
    name: string;
    title: string;
    type: POSTER_WIDGET_TYPE;
    icon: string;
    allowMultipleWidgets?: boolean;
    applicableForPosterType?: Array<EVENT_POSTER_TYPE>; // if its not set the it will be applicable
    applicableForPeopleType?: Array<EVENT_PEOPLE_TYPE>;
  }
> = {
  [EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER]: {
    icon: 'background',
    title: 'Background',
    name: EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
    type: POSTER_WIDGET_TYPE.IMAGE,
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.AVATAR]: {
    title: 'Avatar',
    name: EVENT_POSTER_SYSTEM_WIDGETS.AVATAR,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.AVATAR,
    applicableForPosterType: [EVENT_POSTER_TYPE.BADGE, EVENT_POSTER_TYPE.PRE_EVENT, EVENT_POSTER_TYPE.POST_EVENT],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME]: {
    title: 'User name',
    name: EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.TEXT,
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE]: {
    title: 'User role',
    name: EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.TEXT,
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY]: {
    title: 'User company',
    name: EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.TEXT,
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.DISPLAY_ID]: {
    title: 'ID',
    name: EVENT_POSTER_SYSTEM_WIDGETS.DISPLAY_ID,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.TEXT,
    applicableForPosterType: [EVENT_POSTER_TYPE.BADGE],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.GUEST_IMAGE]: {
    title: 'Guest Photo',
    name: EVENT_POSTER_SYSTEM_WIDGETS.GUEST_IMAGE,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.GUEST_IMAGE,
    allowMultipleWidgets: true,
    applicableForPosterType: [EVENT_POSTER_TYPE.POST_EVENT, EVENT_POSTER_TYPE.MAGAZINE],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.GUEST_IMAGE_GALLERY]: {
    title: 'Photo gallery',
    name: EVENT_POSTER_SYSTEM_WIDGETS.GUEST_IMAGE_GALLERY,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.GUEST_IMAGE_GALLERY,
    allowMultipleWidgets: true,
    applicableForPosterType: [EVENT_POSTER_TYPE.MAGAZINE],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.GUEST_IMAGE_PORTRAIT]: {
    title: 'Guest Portrait',
    name: EVENT_POSTER_SYSTEM_WIDGETS.GUEST_IMAGE_PORTRAIT,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.GUEST_IMAGE_PORTRAIT,
    allowMultipleWidgets: true,
    applicableForPosterType: [EVENT_POSTER_TYPE.MAGAZINE],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE]: {
    title: 'Session Title',
    name: EVENT_POSTER_SYSTEM_WIDGETS.SESSION_TITLE,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.TEXT,
    applicableForPosterType: [EVENT_POSTER_TYPE.PRE_EVENT],
    applicableForPeopleType: [EVENT_PEOPLE_TYPE.SPEAKER],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_LOGO]: {
    title: 'Exhibitor Logo',
    name: EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_LOGO,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.IMAGE,
    applicableForPosterType: [EVENT_POSTER_TYPE.PRE_EVENT],
    applicableForPeopleType: [EVENT_PEOPLE_TYPE.EXHIBITOR],
  },
  [EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_BOOTH_NUMBER]: {
    title: 'Exhibitor Booth Number',
    name: EVENT_POSTER_SYSTEM_WIDGETS.EXHIBITOR_BOOTH_NUMBER,
    icon: 'user',
    type: POSTER_WIDGET_TYPE.TEXT,
    applicableForPosterType: [EVENT_POSTER_TYPE.PRE_EVENT],
    applicableForPeopleType: [EVENT_PEOPLE_TYPE.EXHIBITOR],
  },

  // [EVENT_POSTER_SYSTEM_WIDGETS.QR_CODE_VCARD]: {
  //   title: 'QR Code vCard',
  //   name: EVENT_POSTER_SYSTEM_WIDGETS.QR_CODE_VCARD,
  //   icon: 'qr-code',
  //   type: POSTER_WIDGET_TYPE.QR_CODE,
  // },
  // [EVENT_POSTER_SYSTEM_WIDGETS.QR_CODE_REGISTRATION_CHECKIN]: {
  //   title: 'QR Code registration check-in',
  //   name: EVENT_POSTER_SYSTEM_WIDGETS.QR_CODE_REGISTRATION_CHECKIN,
  //   icon: 'qr-code',
  //   type: POSTER_WIDGET_TYPE.QR_CODE,
  // },
};

export function isSystemWidget(widget: { id?: string; name: string }): boolean {
  // This is the old way of checking if a widget is a system widget, new widgets will have uuid as id
  if (widget.id && widget.id in EVENT_POSTER_SYSTEM_WIDGETS_DETAILS) return true;
  return widget.name in EVENT_POSTER_SYSTEM_WIDGETS_DETAILS;
}

export type EventPosterType = {
  scope: Array<EVENT_PEOPLE_TYPE>;
  type: EVENT_POSTER_TYPE;
  status: EVENT_POSTER_STATUS;
  registration_link?: string;
} & PosterType;

function getFormToPosterToSave(poster: Partial<EventPosterType>): Partial<EventPosterType> {
  if (poster.scope) {
    return Object.assign({}, poster, {
      scope: isArray(poster.scope) ? poster.scope : [poster.scope],
    });
  }
  return poster;
}

export function createEventPoster(
  projectId: string,
  poster: EventPosterType,
): Promise<Record<string, EventPosterType>> {
  return HttpUtils.post(API_URLS.EVENT_POSTERS.LIST(projectId), getFormToPosterToSave(poster), true).then(
    ({ data }) => data,
  );
}

export function fetchEventPostersForProject(projectId: string): Promise<Record<string, EventPosterType>> {
  return HttpUtils.get(API_URLS.EVENT_POSTERS.LIST(projectId)).then(({ data }) => data);
}

export function fetchEventPosterDetails(projectId: string, posterId: string): Promise<EventPosterType> {
  return HttpUtils.get(API_URLS.EVENT_POSTERS.DETAILS(projectId, posterId)).then(({ data }) => data);
}

export function fetchEventPosterForProjectFromClientWebsite(
  domain: string,
  websiteId: string,
): Promise<{
  posters: Array<EventPosterType>;
}> {
  return HttpUtils.get(API_URLS.EVENT_POSTERS.LIST__FROM_WEBSITE(domain, websiteId), true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data.error));
}

export function fetchEventPosterForProjectFromShareID(shareId: string): Promise<{
  posters: Array<EventPosterType>;
}> {
  return HttpUtils.get(API_URLS.EVENT_POSTERS.LIST__FROM_SHARE_ID(shareId), true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data.error));
}

export function updateEventPoster(
  options: {
    projectId: string;
    posterId: string;
  },
  poster: Partial<EventPosterType>,
): Promise<Record<string, EventPosterType>> {
  const { projectId, posterId } = options;
  return HttpUtils.put(API_URLS.EVENT_POSTERS.DETAILS(projectId, posterId), getFormToPosterToSave(poster), true).then(
    ({ data }) => data,
  );
}

export function deleteEventPoster(projectId: string, attendeeId: string): Promise<void> {
  return HttpUtils.httpDelete(API_URLS.EVENT_POSTERS.DETAILS(projectId, attendeeId)).then(({ data }) => data);
}

export function getPosterPreviewUrl(options: {
  clientWebsite?: FolderType;
  poster?: EventPosterType;
  isEmbed?: boolean;
}) {
  const { clientWebsite, poster, isEmbed } = options;
  if (!clientWebsite || !poster) return undefined;
  const { scope = [], id } = poster;
  let url;
  if (isEmbed) {
    url = CLIENT_WEBSITE_LINK.POSTER.EMBED(scope[0], id);
  } else {
    url = CLIENT_WEBSITE_LINK.POSTER.CREATE(scope[0], id);
  }
  return `${clientWebsite?.share_url}${url}`;
}

export function getAttendeeBadgePrintUrl(options: {
  clientWebsite?: FolderType;
  badgeId?: string;
  registrationId?: string;
}) {
  const { clientWebsite, badgeId, registrationId } = options;
  if (!clientWebsite || !badgeId || !registrationId) return undefined;

  const url = CLIENT_WEBSITE_LINK.BADGE.PRINT(badgeId, registrationId);

  return `${clientWebsite?.share_url}${url}`;
}

export function getDefaultPosterWidgetsData(config: {
  textColor: string;
  posterSize: {
    width: number;
    height: number;
  };
  type: EVENT_POSTER_TYPE;
}): Record<string, PosterWidgetType> {
  const { textColor: fontColor, posterSize, type } = config;
  const defaultProperties = {
    ellipsis: true,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  if (type === EVENT_POSTER_TYPE.BADGE) {
    return {
      [EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER]: {
        name: EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
        id: EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
        type: POSTER_WIDGET_TYPE.IMAGE,
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        size: {
          width: posterSize.width,
          height: posterSize.height,
        },
        selectable: false,
        ...defaultProperties,
      },
      [EVENT_POSTER_SYSTEM_WIDGETS.AVATAR]: {
        name: EVENT_POSTER_SYSTEM_WIDGETS.AVATAR,
        id: EVENT_POSTER_SYSTEM_WIDGETS.AVATAR,
        type: POSTER_WIDGET_TYPE.AVATAR,
        style: AVATAR_STYLE.SQUARE,
        position: {
          x: getMeasurementUnitForX(20, posterSize),
          y: getMeasurementUnitForY(23, posterSize),
          z: 1,
        },
        size: {
          width: getMeasurementUnitForX(60, posterSize),
          height: getMeasurementUnitForX(60, posterSize),
        },
        font_color: fontColor,
        ...defaultProperties,
      },
      // [EVENT_POSTER_SYSTEM_WIDGETS.DISPLAY_ID]: {
      //   name: EVENT_POSTER_SYSTEM_WIDGETS.DISPLAY_ID,
      //   id: EVENT_POSTER_SYSTEM_WIDGETS.DISPLAY_ID,
      //   type: POSTER_WIDGET_TYPE.TEXT,
      //   position: {
      //     x: getMeasurementUnitForX(2, posterSize),
      //     y: getMeasurementUnitForY(49.5, posterSize),
      //     z: 1,
      //   },
      //   size: {
      //     width: getMeasurementUnitForX(15, posterSize),
      //   },
      //   font_color: fontColor,
      //   font_family: 'Source Sans Pro',
      //   font_size: 14,
      //   font_align: 'center',
      //   ...defaultProperties,
      // },
      [EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME]: {
        name: EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME,
        id: EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME,
        type: POSTER_WIDGET_TYPE.TEXT,
        position: {
          x: getMeasurementUnitForX(4, posterSize),
          y: getMeasurementUnitForY(68, posterSize),
          z: 1,
        },
        size: {
          width: getMeasurementUnitForX(92, posterSize),
        },
        font_color: fontColor,
        font_family: 'Source Sans Pro',
        font_weight: 700,
        font_size: 24,
        font_align: 'center',
        ...defaultProperties,
      },
      [EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY]: {
        name: EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY,
        id: EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY,
        type: POSTER_WIDGET_TYPE.TEXT,
        position: {
          x: getMeasurementUnitForX(4, posterSize),
          y: getMeasurementUnitForY(74, posterSize),
          z: 1,
        },
        size: {
          width: getMeasurementUnitForX(92, posterSize),
        },
        font_color: fontColor,
        font_family: 'Source Sans Pro',
        font_weight: 500,
        font_size: 18,
        font_align: 'center',
        ...defaultProperties,
      },
      [EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE]: {
        name: EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE,
        id: EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE,
        type: POSTER_WIDGET_TYPE.TEXT,
        position: {
          x: getMeasurementUnitForX(4, posterSize),
          y: getMeasurementUnitForY(79, posterSize),
          z: 1,
        },
        size: {
          width: getMeasurementUnitForX(92, posterSize),
        },
        font_color: fontColor,
        font_family: 'Source Sans Pro',
        font_weight: 400,
        font_size: 16,
        font_align: 'center',
        ...defaultProperties,
      },
    };
  }
  // I am attending
  const CONFIG = {
    textX: getMeasurementUnitForX(62, posterSize),
    textWidth: getMeasurementUnitForX(35, posterSize),
  };
  return {
    [EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER]: {
      name: EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
      id: EVENT_POSTER_SYSTEM_WIDGETS.BACKGROUND_POSTER,
      type: POSTER_WIDGET_TYPE.IMAGE,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      size: {
        width: posterSize.width,
        height: posterSize.height,
      },
      selectable: false,
      ...defaultProperties,
    },
    [EVENT_POSTER_SYSTEM_WIDGETS.AVATAR]: {
      name: EVENT_POSTER_SYSTEM_WIDGETS.AVATAR,
      id: EVENT_POSTER_SYSTEM_WIDGETS.AVATAR,
      type: POSTER_WIDGET_TYPE.AVATAR,
      position: {
        x: getMeasurementUnitForX(67, posterSize),
        y: getMeasurementUnitForY(10, posterSize),
        z: 1,
      },
      size: {
        width: getMeasurementUnitForX(25, posterSize),
        height: getMeasurementUnitForX(25, posterSize),
      },
      style: AVATAR_STYLE.CIRCLE,
      font_color: fontColor,
      ...defaultProperties,
    },
    [EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME]: {
      name: EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME,
      id: EVENT_POSTER_SYSTEM_WIDGETS.USER_NAME,
      type: POSTER_WIDGET_TYPE.TEXT,
      position: {
        x: CONFIG.textX,
        y: getMeasurementUnitForY(62, posterSize),
        z: 1,
      },
      size: {
        width: CONFIG.textWidth,
        height: 32 * 2,
      },
      font_color: fontColor,
      font_family: 'Source Sans Pro',
      font_weight: 700,
      font_size: 32,
      font_align: 'center',
      ...defaultProperties,
    },
    [EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE]: {
      name: EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE,
      id: EVENT_POSTER_SYSTEM_WIDGETS.USER_ROLE,
      type: POSTER_WIDGET_TYPE.TEXT,
      position: {
        x: CONFIG.textX,
        y: getMeasurementUnitForY(74, posterSize),
        z: 1,
      },
      size: {
        width: CONFIG.textWidth,
        height: 26 * 4,
      },
      font_color: fontColor,
      font_family: 'Source Sans Pro',
      font_weight: 400,
      font_size: 26,
      font_align: 'center',
      ...defaultProperties,
    },
    [EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY]: {
      name: EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY,
      id: EVENT_POSTER_SYSTEM_WIDGETS.USER_COMPANY,
      type: POSTER_WIDGET_TYPE.TEXT,
      position: {
        x: CONFIG.textX,
        y: getMeasurementUnitForY(78, posterSize),
        z: 1,
      },
      size: {
        width: CONFIG.textWidth,
      },
      font_color: fontColor,
      font_family: 'Source Sans Pro',
      font_weight: 400,
      font_size: 26,
      font_align: 'center',
      is_hidden: true,
      ...defaultProperties,
    },
  };
}

// PICKING PHOTOS FOR WIDGETS
/* This should return
  Record<posterId>: {
    [widgetId]: string | Array<string>
  }

  if the widget.type is POSTER_WIDGET_TYPE.GUEST_IMAGE_GALLERY then we need to pick 9 images with folder_id as widget.auto_populate_value_from_folder_id
  if 9 is not available then get the remaining from the public images with folder_id as widget.auto_populate_value_from_folder_id

  Cases
  1. handle the case when random photos doeest have photos from upcoming gallery

 */
export function getPhotosForWidget(options: {
  numberOfPhotosRequired: number;
  pickPhotoFromFolderId?: string;
  guestPhotos: IList<FolderFileType>;
  publicPhotos: IList<FolderFileType>;
  alreadyUsedPhotos: string[];
}): Array<string> {
  const { numberOfPhotosRequired, pickPhotoFromFolderId, guestPhotos, publicPhotos, alreadyUsedPhotos } = options;
  let photos: Array<string> = [];
  photos = guestPhotos
    .filter(
      (photo) =>
        (pickPhotoFromFolderId ? photo.folder_id === pickPhotoFromFolderId : true) &&
        !alreadyUsedPhotos.includes(photo.id) &&
        !photo.meta?.tags?.includes('PORTRAIT'),
    )
    .map((photo) => photo.id)
    .toJS();

  const remainingPhotosRequired = numberOfPhotosRequired - photos.length;
  if (remainingPhotosRequired) {
    const remainingPhotos = publicPhotos
      .filter(
        (photo) =>
          (pickPhotoFromFolderId ? photo.folder_id === pickPhotoFromFolderId : true) &&
          !alreadyUsedPhotos.includes(photo.id) &&
          !photo.meta?.tags?.includes('PORTRAIT'),
      )
      .map((photo) => photo.id)
      .toJS();
    photos = photos.concat(remainingPhotos.slice(0, remainingPhotosRequired));
  }

  return photos.slice(0, numberOfPhotosRequired);
}

const PHOTO_PER_GUEST_GALLERY = 9;
export function getPhotosForPoster(options: {
  poster: EventPosterType;
  guestPhotos: IList<FolderFileType>;
  publicPhotos?: IList<FolderFileType>;
  portraitImageIds?: Array<string>;
  alreadyUsedPhotos: Array<string>;
}) {
  const { poster, guestPhotos, publicPhotos = IList([]), portraitImageIds, alreadyUsedPhotos } = options;
  let newAlreadyUsedPhotos = [...alreadyUsedPhotos];

  const photoForWidgets = Object.values(poster.widgets).reduce((accWidgets, widget) => {
    if (widget.type === POSTER_WIDGET_TYPE.GUEST_IMAGE) {
      const pickPhotosForWidget = getPhotosForWidget({
        numberOfPhotosRequired: 1,
        pickPhotoFromFolderId: widget.auto_populate_value_from_folder_id,
        guestPhotos,
        publicPhotos,
        alreadyUsedPhotos: newAlreadyUsedPhotos,
      });
      newAlreadyUsedPhotos = [...newAlreadyUsedPhotos, ...pickPhotosForWidget];
      return Object.assign({}, accWidgets, { [widget.id]: pickPhotosForWidget[0] });
    }

    if (widget.type === POSTER_WIDGET_TYPE.GUEST_IMAGE_GALLERY) {
      const pickPhotosForWidget = getPhotosForWidget({
        numberOfPhotosRequired: PHOTO_PER_GUEST_GALLERY,
        pickPhotoFromFolderId: widget.auto_populate_value_from_folder_id,
        guestPhotos,
        publicPhotos,
        alreadyUsedPhotos: newAlreadyUsedPhotos,
      });
      newAlreadyUsedPhotos = [...newAlreadyUsedPhotos, ...pickPhotosForWidget];
      return Object.assign({}, accWidgets, { [widget.id]: pickPhotosForWidget });
    }

    if (widget.type === POSTER_WIDGET_TYPE.GUEST_IMAGE_PORTRAIT) {
      return Object.assign({}, accWidgets, { [widget.id]: portraitImageIds?.[0] });
    }

    return accWidgets;
  }, {});
  return {
    photoForWidgets,
    userPhotos: newAlreadyUsedPhotos,
  };
}

export function getPhotosForPosters(options: {
  posters: Array<EventPosterType>;
  guestPhotos: IList<FolderFileType>;
  publicPhotos: IList<FolderFileType>;
  portraitImageIds?: Array<string>;
}) {
  let photosUsed: Array<string> = [];
  const { posters, guestPhotos, publicPhotos, portraitImageIds } = options;

  return posters.reduce((acc, poster) => {
    const data = getPhotosForPoster({
      poster,
      guestPhotos,
      publicPhotos,
      portraitImageIds,
      alreadyUsedPhotos: photosUsed,
    });
    acc[poster.id] = data.photoForWidgets;
    photosUsed = [...photosUsed, ...data.userPhotos];

    return acc;
  }, {});
}

// END PICKING PHOTOS FOR WIDGETS
