import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_SIZE,
  BUTTON_STYLES,
  ButtonIcon,
  DECK_SLIDE_DATA_TYPES,
  DeckSlideVariableSingleItemDataType,
  Dialog,
  DialogTitle,
  Form,
  FormFooter,
  FormLabel,
  FormResponseType,
  Icon,
  ICON_SIZES,
  Link,
  List,
  ListItem,
  ListItemAction,
  Select,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import { update } from 'lodash';
import {
  ProposalDeckService,
  ProposalService,
  ProposalSlideService,
  ProposalVariableService,
} from '@premagic/proposals';
import APP_URLS from '../../../../services/AppRouteURLService';

type AddInvoiceButtonProps = {
  invoiceVariables: Array<ProposalVariableService.ProposalVariableType>;
  invoiceItemsAddedInSlide: Array<DeckSlideVariableSingleItemDataType>;
  onAdd: (invoiceItem: DeckSlideVariableSingleItemDataType) => void;
  showAddNewInvoiceVariable: () => void;
};
function AddInvoiceButton(props: AddInvoiceButtonProps) {
  const { invoiceVariables, invoiceItemsAddedInSlide, onAdd, showAddNewInvoiceVariable } = props;
  const [showDialog, setShowDialog] = useState(false);
  const $button = useRef(null);

  function handleShowNewInvoiceVariable() {
    setShowDialog(false);
    showAddNewInvoiceVariable();
  }

  const variableOptions = useMemo(
    () =>
      invoiceVariables
        .filter(({ id: variableId }) => !invoiceItemsAddedInSlide.find((item) => item.id === variableId))
        .map((variable) => ({
          label: variable.name,
          value: variable.id,
        })),
    [invoiceVariables, invoiceItemsAddedInSlide],
  );

  function handleAdd(formResponse: FormResponseType) {
    const id = formResponse.data.variable_id as string;
    const variable = invoiceVariables.find(({ id: variableId }) => variableId === id);
    setShowDialog(false);
    return onAdd({
      id,
      name: variable?.name || 'no',
      type: variable?.type || ProposalVariableService.PROPOSAL_VARIABLE_TYPE.NUMBER,
      default_value: variable?.default_value || '',
    });
  }

  return (
    <div>
      <ListItem>
        <Button style={BUTTON_STYLES.LINK} onClick={() => setShowDialog(!showDialog)} ref={$button}>
          <Icon name="plus" size={ICON_SIZES.SM} /> <Space size={1} />
          item
        </Button>
      </ListItem>
      <Dialog show={showDialog} onClose={() => setShowDialog(false)} target={$button.current}>
        <DialogTitle>Add Invoice Item</DialogTitle>
        {variableOptions.length === 0 ? (
          <div>
            <Text block>There are no Invoice Items left to select</Text>
            <Space vertical />

            <Text>
              You can manage Invoice Items in the{' '}
              <Link to={APP_URLS.PROPOSALS_TEMPLATES.LIST}>Proposal templates setting page</Link> or{' '}
              <Button onClick={handleShowNewInvoiceVariable} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
                Add new Invoice Item
              </Button>
            </Text>
            <FormFooter>
              <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDialog(false)}>
                Close
              </Button>
            </FormFooter>
          </div>
        ) : (
          <Form onSubmit={handleAdd}>
            <Select name="variable_id" options={variableOptions} isMulti={false} autoFocus />
            <Text size={TEXT_SIZE.SIZE_5}>
              You can manage Invoice Items in the{' '}
              <Link to={APP_URLS.PROPOSALS_TEMPLATES.LIST}>Proposal templates page</Link> or{' '}
              <Button onClick={handleShowNewInvoiceVariable} style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM}>
                Add new Invoice Item
              </Button>
            </Text>
            <FormFooter>
              <Button style={BUTTON_STYLES.PRIMARY}>Select</Button>
            </FormFooter>
          </Form>
        )}
      </Dialog>
    </div>
  );
}

type Props = {
  slideDetails?: ProposalSlideService.ProposalSlideType;
  projectId: string;
  deckId: string;
  saveProposalSlide: (options: { projectId: string; deckId: string; id: number }, ProposalSlideType) => void;
  invoiceVariables: Array<ProposalVariableService.ProposalVariableType>;
  proposalVariableDetails: Array<ProposalVariableService.ProposalVariableType>;
  showAddNewInvoiceVariable: () => void;
  deck: ProposalDeckService.ProposalDeckType;
};

const dataType = DECK_SLIDE_DATA_TYPES.INVOICE_ITEMS;
export default function ProposalCreatorPackageInvoicesUpdater(props: Props): JSX.Element | null {
  const {
    saveProposalSlide,
    projectId,
    deckId,
    invoiceVariables,
    slideDetails,
    proposalVariableDetails,
    showAddNewInvoiceVariable,
    deck,
  } = props;

  const { variables } = deck || {};

  function isItemHidden(item) {
    const hiddenItems = slideDetails?.structure['invoice-items']?.hidden_items;

    const isHidden = hiddenItems?.includes(item.id);
    return isHidden;
  }

  function handleHideUnhideItem(item) {
    if (!slideDetails) return;

    const hiddenItems = slideDetails?.structure['invoice-items']?.hidden_items;

    let newStructure = {};

    if (hiddenItems?.includes(item.id)) {
      // remove the item from the hiddenItems array
      newStructure = update(slideDetails.structure, `[${dataType}][hidden_items]`, (items = []) =>
        items.filter((itemId) => itemId !== item.id),
      );
    } else {
      // add the item to the hiddenItems array
      newStructure = update(slideDetails.structure, `[${dataType}][hidden_items]`, (items = []) => {
        items.push(item.id);
        return items;
      });
    }

    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
  }

  function handleAdd(item: DeckSlideVariableSingleItemDataType) {
    if (!slideDetails) return;
    const newStructure = update(slideDetails.structure, `[${dataType}][value]`, (items = []) => {
      items.push(item);
      return items;
    });
    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
  }

  function handleRemove(id: string) {
    if (!slideDetails) return;
    const newStructure = update(slideDetails.structure, `[${dataType}][value]`, (items = []) =>
      items.filter((item) => item.id !== id),
    );
    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
  }
  const invoiceItemsAddedInSlide = useMemo(() => slideDetails?.structure?.[dataType]?.value || [], [slideDetails]);

  const defaultItemsAddedOnSlide = useMemo(() => {
    const flattenedVariablesObject = ProposalVariableService.flattenVariablesObject(variables);
    let result: Array<any> = [];
    if (flattenedVariablesObject) {
      const variableIdsAddedOnSlide = Object.keys(flattenedVariablesObject);
      const defaultItems = proposalVariableDetails?.filter(
        (item) => item.scope === ProposalVariableService.PROPOSAL_VARIABLE_SCOPE.DEFAULT,
      );
      // @ts-ignore
      const defaultItemsWithCost = defaultItems?.filter((item) => Number(item.default_value) > 0);
      result = defaultItemsWithCost?.filter((item) => variableIdsAddedOnSlide?.includes(item.id));
    }
    return result;
  }, [proposalVariableDetails, variables]);

  return (
    <div>
      <FormLabel>Invoices Items</FormLabel>
      <List>
        {invoiceItemsAddedInSlide.map((item) => {
          const itemDetails = invoiceVariables.find((variable) => variable.id === item.id);
          return (
            <ListItem key={item.id}>
              {itemDetails?.name || item.name}
              <ListItemAction>
                <ButtonIcon onClick={() => handleRemove(item.id)} title="Delete" size={BUTTON_ICONS_SIZES.SM}>
                  <Icon name="trash" size={ICON_SIZES.SM} />
                </ButtonIcon>
              </ListItemAction>
            </ListItem>
          );
        })}
        {defaultItemsAddedOnSlide?.length > 0 &&
          defaultItemsAddedOnSlide.map((item) => (
            <ListItem key={item.id}>
              {item?.name}
              <ListItemAction>
                <ButtonIcon
                  onClick={() => handleHideUnhideItem(item)}
                  title={isItemHidden(item) ? 'show' : 'hide'}
                  size={BUTTON_ICONS_SIZES.SM}
                >
                  <Icon name={isItemHidden(item) ? 'eye_off' : 'eye'} size={ICON_SIZES.SM} />
                </ButtonIcon>
              </ListItemAction>
            </ListItem>
          ))}
        {invoiceItemsAddedInSlide.length === 0 && (
          <ListItem>
            <Text muted>No items added</Text>
          </ListItem>
        )}
      </List>
      <AddInvoiceButton
        onAdd={handleAdd}
        invoiceItemsAddedInSlide={invoiceItemsAddedInSlide}
        invoiceVariables={invoiceVariables}
        showAddNewInvoiceVariable={showAddNewInvoiceVariable}
      />
    </div>
  );
}
