import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button, BUTTON_STYLES, List, InnerPageHeader, Text, Card, InnerPage, InnerPageContent } from '@premagic/myne';
import MESSAGES from '../../../../../common/Messages';
import UserDetailsContainer from './UserDetailsContainer';
import APP_URLS from '../../../services/AppRouteURLService';
import UserDeleteDialogContainer from './delete/UserDeleteDialogContainer';
import EditUserPanelContainer from './edit/EditUserPanelContainer';
import UserActivateDialogContainer from './activate/UserActivateDialogContainer';
import { PERMISSIONS } from './UserPermissionService';

type Props = {
  initData: () => void;
  users: Array<string>;
  isLoading: boolean;
  showAddNewUserPanel: () => void;
  hasPermissionToManageUsers: boolean;
};

export default function UsersPage(props: Props): JSX.Element {
  const { initData, hasPermissionToManageUsers } = props;
  useEffect(() => {
    initData();
  }, [initData]);

  const { users, isLoading, showAddNewUserPanel } = props;
  if (!hasPermissionToManageUsers) {
    return (
      <InnerPage>
        <InnerPageHeader iconName="users" title="User" backTo={APP_URLS.SETTINGS.INDEX}>
          Users
        </InnerPageHeader>
        <InnerPageContent>
          <Text>{MESSAGES[PERMISSIONS.USER_MANAGEMENT]}</Text>
        </InnerPageContent>
      </InnerPage>
    );
  }

  return (
    <InnerPage>
      <InnerPageHeader
        iconName="users"
        title="User"
        backTo={APP_URLS.SETTINGS.INDEX}
        rightActions={
          <Button style={BUTTON_STYLES.PRIMARY} onClick={showAddNewUserPanel}>
            Add Team member
          </Button>
        }
      >
        Users
      </InnerPageHeader>
      <InnerPageContent isLoading={isLoading}>
        <div>
          {users.length === 0 && (
            <div>
              <Text muted>You haven&apos;t added any users to Premagic yet</Text>
            </div>
          )}
          <Card>
            <List>
              {users.map((user) => (
                <UserDetailsContainer key={user} id={user} />
              ))}
            </List>
          </Card>
        </div>
      </InnerPageContent>
      <Switch>
        <Route path={APP_URLS.SETTINGS_USERS.DELETE} component={UserDeleteDialogContainer} />
        <Route path={APP_URLS.SETTINGS_USERS.ACTIVATE} component={UserActivateDialogContainer} />
        <Route path={APP_URLS.SETTINGS_USERS.EDIT} component={EditUserPanelContainer} />
      </Switch>
    </InnerPage>
  );
}
