import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils } from '@premagic/utils';
import { commonUISelector } from '../selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALERT', true);

export const setAlertState = createAction(
  getActionType('ALERT_STATE', 'SET'),
  (dispatch: Dispatch, type: string, data: any) => ({
    [type]: data,
  }),
);

export const clearAlertState = createAction(
  getActionType('ALERT_STATE', 'CLEAR'),
  (dispatch: Dispatch, type: string) => type,
);

const initialState = {
  items: IMap({}),
};

export const AlertReducer = handleActions(
  {
    [setAlertState.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [clearAlertState.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

const alertsDataSelector = createSelector(commonUISelector, (state) => state.alert as { items: IMap<string, unknown> });

export const alertForTypeSelector = (type: string) =>
  createSelector(alertsDataSelector, (state) => !!state.items.get(type));

export const alertObjectForTypeSelector = <T>(type: string) =>
  createSelector(alertsDataSelector, (state) => state.items.get(type) as T);
