import { connect } from 'react-redux';
import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { ActionConstants, ProposalDeckDataDuck, ProposalSlideDataDuck } from '@premagic/proposals';

import ProposalSlideAddButton from './ProposalSlideAddButton';

const DIALOG_KEY = ActionConstants.KEYS.PROPOSAL_SLIDE.SHOW_ADD_DIALOG;
function mapStateToProps(state, ownProps: { projectId: string; folderId: string; deckId: string }) {
  const { projectId, folderId, deckId } = ownProps;
  return {
    projectId,
    folderId,
    deckId,
    isDialogShown: ModalDuck.isModalOpenSelector(DIALOG_KEY)(state),
    isAdding: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_SLIDE.CREATE)(state),
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleAddDialog: (show: boolean) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
    addSlide: (params, data) => {
      dispatch(ProposalSlideDataDuck.addProposalSlide(dispatch, params, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSlideAddButton);
