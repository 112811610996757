import { connect } from 'react-redux';
import { isFeatureLeadsModuleEnabledSelector } from '../preferences/ClientSettingsDataDuck';
import { saveCompanyData, companyDataSelector, companyLeadFormsURL } from './CompanyDataDuck';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import CompanyDomainSettingsPage from './CompanyDomainSettingsPage';

function mapStateToProps(state) {
  return {
    company: companyDataSelector(state),
    urls: companyLeadFormsURL(state),
    isSaving: isLoadingSelector(LOADINGS.COMPANY.SAVE_PROFILE)(state),
    errors: errorObjectForTypeSelector(LOADINGS.COMPANY.SAVE_PROFILE)(state),
    isLeadsModuleEnabled: isFeatureLeadsModuleEnabledSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCompanyData: (data) => {
      dispatch(saveCompanyData(dispatch, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDomainSettingsPage);
