import React, { useContext } from 'react';
import { Line, Circle, Group, Text } from 'react-konva';
import PosterContext from '../poster/PosterContext';
import { SELECTOR_KEYS } from '../services/SelectorService';

type GuideProps = {
  position: number;
  isHorizontal: boolean;
  onMove: (newPos: number) => void;
  onRemove: () => void;
  scale: number;
};
function Guide(props: GuideProps) {
  const { position, isHorizontal, onMove, onRemove, scale } = props;
  const guideStart = isHorizontal ? [position, 0] : [0, position];
  const guideEnd = isHorizontal ? [position, window.innerHeight] : [window.innerWidth, position];
  const { setCursor } = useContext(PosterContext);

  return (
    <Group>
      <Line
        points={[...guideStart, ...guideEnd]}
        stroke="#047af0"
        strokeWidth={1}
        name={SELECTOR_KEYS.SHAPE}
        opacity={0.8}
      />
      <Circle
        x={guideStart[0]}
        y={guideStart[1]}
        stroke="#047af0"
        radius={8}
        strokeWidth={6}
        fill="#fff"
        opacity={0.8}
        draggable
        onDragMove={(e) => {
          setCursor('move');
          const newX = isHorizontal ? e.target.x() : e.target.y();
          if (newX >= window.innerWidth || newX >= window.innerHeight) {
            onRemove();
          }
          const snappedX = Math.round(newX / 5) * 5;
          onMove(snappedX);
        }}
        onContextMenu={(e) => {
          e.evt.preventDefault();
          onRemove();
        }}
        onMouseEnter={() => {
          setCursor('move');
        }}
        onDragEnd={() => {
          setCursor('pointer');
        }}
      />
      <Text
        x={guideStart[0] + 5}
        y={guideStart[1] + 5}
        text={Math.floor(position * scale).toString()}
        fontSize={8}
        fill="#fff"
        fontStyle="bold"
        fontFamily="'Source Sans Pro', sans-serif'"
      />
    </Group>
  );
}

type Props = {
  isHorizontal: boolean;
  guides: number[];
  setGuides: (guides: number[]) => void;
  scale: number;
};

export default function Guidelines(props: Props) {
  const { isHorizontal, guides, setGuides, scale } = props;

  const handleGuideMove = (index: number, newPos: number) => {
    const updatedGuides = [...guides];
    updatedGuides[index] = newPos;
    setGuides(updatedGuides);
  };

  const handleGuideRemove = (index: number) => {
    const updatedGuides = [...guides];
    updatedGuides.splice(index, 1);
    setGuides(updatedGuides);
  };

  return (
    <Group>
      {guides.map((guide, i) => {
        const key = `guide-${i}`;

        return (
          <Guide
            key={key}
            position={guide}
            isHorizontal={isHorizontal}
            onMove={(newPos) => handleGuideMove(i, newPos)}
            onRemove={() => handleGuideRemove(i)}
            scale={scale}
          />
        );
      })}
    </Group>
  );
}
