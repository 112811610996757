import { connect } from 'react-redux';

import { DAMLibraryFilters } from './DAMLibraryFilters';
import { DAMLibraryFiltersType } from './DAMLibraryFilterTypes';
import { removeFilterDAMLibraryPage, navigateToDAMLibraryPageWithFilter } from './DAMLibraryFilterDuck';
import { resetDAMLibraryData } from '../DAMLibraryDataDuck';

function mapStateToProps(state, ownProps: { filters: DAMLibraryFiltersType }) {
  const { filters } = ownProps;
  return {
    filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (filters) => {
      dispatch(navigateToDAMLibraryPageWithFilter(dispatch, filters));
    },
    removeFilter: (filterKey, filters) => {
      dispatch(removeFilterDAMLibraryPage(dispatch, filterKey, filters));
    },

    resetDAMLibraryData: () => {
      dispatch(resetDAMLibraryData(dispatch));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryFilters);
