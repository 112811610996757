import React from 'react';
import { Card, CardContent, CardHeader, Divider, Space, SubTitle } from '@premagic/myne';

import ProjectNerdStatsInfoContainer from '../project-stats-nerd/ProjectNerdStatsInfoContainer';
import ProjectBackgroundTasksInfoContainer from '../project-background-tasks/ProjectBackgroundTasksInfoContainer';

type Props = {
  projectId: string;
  isStaffUser: boolean;
};
export default function ProjectStatsForNerdCard(props: Props): JSX.Element | null {
  const { projectId, isStaffUser } = props;
  if (!isStaffUser) return null;

  return (
    <div className="hidden-print">
      <Space vertical />
      <Card accordion defaultExpanded={false}>
        <Space>
          <CardHeader>
            <SubTitle>Stats for nerds</SubTitle>
          </CardHeader>
          <CardContent>
            <ProjectBackgroundTasksInfoContainer projectId={projectId} />
            <Space vertical size={2} />
            <Divider />
            <Space vertical size={2} />
            <ProjectNerdStatsInfoContainer projectId={projectId} />
          </CardContent>
        </Space>
      </Card>
      <Space vertical />
    </div>
  );
}
