import { connect } from 'react-redux';
import { getEventSponsorsForProject } from './EventSponsorDataDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { sponsorCategoryIdsSelector } from './sponsor-category/data-duck/SponsorCategoryDataDuck';
import EventSponsorsCategoryList from './EventSponsorsCategoryList';

function mapStateToProps(state, ownProps) {
  const { eventId, projectId } = ownProps;
  return {
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state) || isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    projectId,
    eventId,
    categoryIds: sponsorCategoryIdsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId: string) => {
      dispatch(getEventSponsorsForProject(dispatch, projectId));
    },
    showAddSponsor: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_SPONSOR.SHOW_ADD_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorsCategoryList);
