import { getProductImages, ASSETS_URL, getQRCodeBackgroundPosterImages } from './AssetsService';
import { ImageEditorImageMetaDataType } from './GeneralImageEditorService';

export enum POSTER_COLOR_ID {
  PRIMARY_COLOR_1 = 'primary_color1',
  PRIMARY_COLOR_2 = 'primary_color2',
  SECONDARY_COLORS = 'secondary_colors',
}

export type PosterColorType = {
  [POSTER_COLOR_ID.PRIMARY_COLOR_1]?: string;
  [POSTER_COLOR_ID.PRIMARY_COLOR_2]?: string;
  [POSTER_COLOR_ID.SECONDARY_COLORS]?: PosterSecondaryColorType;
};

export enum LOGO_TYPE_OPTION_ID {
  COMPANY_LOGO = 'COMPANY_LOGO',
  CUSTOM_LOGO = 'CUSTOM_LOGO',
}

export enum QR_CODE_DESIGN_DATA_TYPES {
  COLORS = 'colors',
  LOGO = 'logo',
  LOGO_META_DATA = 'logo_meta_data',
  LOGO_OPTION_TYPE = 'logo_option_type',
  BACKGROUND_POSTER = 'background_poster',
  COLOR_EXTRACTION_FROM_LOGO = 'color_extraction_from_logo',
  CUSTOM_ILLUSTRATION = 'custom_illustration',
  CUSTOM_ILLUSTRATION_META_DATA = 'custom_illustration_meta_data',
  SPONSOR_LOGO = 'sponsor_logo',
  SHOW_POWERED_BY_PREMAGIC = 'show_powered_by_premagic',
  CUSTOM_LED_POSTER = 'custom_led_poster',
  LED_POSTER_TEXT_COLOR = 'led_poster_text_color',
}

export type QRCodePoster = {
  label: string;
  value: QR_CODE_POSTER_IDS;
  id: QR_CODE_POSTER_IDS;
  imageType: 'svg' | 'jpg';
  customIllustration?: boolean;
  backgroundCover: {
    standee: string;
    standeeMultiLogo?: string;
    pocketCardFront?: string;
    pocketCardFrontMultiLogo?: string;
    pocketCardBack?: string;
    tentCard: string;
    tentCardMultiLogo?: string;
    ledScreen?: string;
  };
  designOptions: {
    isSecondaryColorAvailable?: boolean;
    smallerLogoSpace?: boolean;
    numberOfColors: number;
    // tent design will be same as standee
    standeeHeaderContrastText?: boolean;
    standeeFooterContrastText?: boolean;
    pocketFrontHeaderContrastText?: boolean;
    pocketFrontFooterContrastText?: boolean;
    pocketBackContrastText?: boolean;
  };
};

export const LOGO_TYPE_OPTION_DETAILS: Record<
  LOGO_TYPE_OPTION_ID,
  {
    id: LOGO_TYPE_OPTION_ID;
    title: string;
  }
> = {
  [LOGO_TYPE_OPTION_ID.COMPANY_LOGO]: {
    id: LOGO_TYPE_OPTION_ID.COMPANY_LOGO,
    title: 'Company Logo',
  },
  [LOGO_TYPE_OPTION_ID.CUSTOM_LOGO]: {
    id: LOGO_TYPE_OPTION_ID.CUSTOM_LOGO,
    title: 'Custom Logo',
  },
};

export enum QR_CODE_DESIGN_LOGO_ID {
  LOGO_1 = 'logo_1',
  LOGO_2 = 'logo_2',
}

export enum QR_CODE_DESIGN_SPONSOR_LOGO_IDS {
  LOGO_1 = 'LOGO_1',
  LOGO_2 = 'LOGO_2',
  LOGO_3 = 'LOGO_3',
}

export type QRCodeSponsorLogoDataType = Partial<Record<QR_CODE_DESIGN_SPONSOR_LOGO_IDS, string>>;

export enum LED_TEXT_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
export type QRCodeLEDTextColorDataType = Partial<Record<LED_TEXT_COLOR, string>>;

export type QRCodeDesignType = {
  [QR_CODE_DESIGN_DATA_TYPES.COLORS]?: PosterColorType;
  // The logo can be object with {logo_1, logo_2} instead of array. will change it later.
  [QR_CODE_DESIGN_DATA_TYPES.LOGO]?: Array<string | undefined>;
  [QR_CODE_DESIGN_DATA_TYPES.LOGO_META_DATA]?: Partial<Record<QR_CODE_DESIGN_LOGO_ID, ImageEditorImageMetaDataType>>;
  [QR_CODE_DESIGN_DATA_TYPES.LOGO_OPTION_TYPE]?: LOGO_TYPE_OPTION_ID;
  [QR_CODE_DESIGN_DATA_TYPES.BACKGROUND_POSTER]?: QR_CODE_POSTER_IDS;
  [QR_CODE_DESIGN_DATA_TYPES.COLOR_EXTRACTION_FROM_LOGO]?: boolean;
  [QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION]?: string;
  [QR_CODE_DESIGN_DATA_TYPES.CUSTOM_ILLUSTRATION_META_DATA]?: ImageEditorImageMetaDataType;
  [QR_CODE_DESIGN_DATA_TYPES.SPONSOR_LOGO]?: QRCodeSponsorLogoDataType;
  [QR_CODE_DESIGN_DATA_TYPES.SHOW_POWERED_BY_PREMAGIC]?: boolean;
  [QR_CODE_DESIGN_DATA_TYPES.CUSTOM_LED_POSTER]?: string;
  [QR_CODE_DESIGN_DATA_TYPES.LED_POSTER_TEXT_COLOR]?: QRCodeLEDTextColorDataType;
};

export const QR_CODE_DESIGN_LOGO_DETAILS: Record<
  QR_CODE_DESIGN_LOGO_ID,
  {
    id: QR_CODE_DESIGN_LOGO_ID;
    title: string;
    positionIndex: number;
  }
> = {
  [QR_CODE_DESIGN_LOGO_ID.LOGO_1]: {
    id: QR_CODE_DESIGN_LOGO_ID.LOGO_1,
    title: 'Logo 1',
    positionIndex: 0,
  },
  [QR_CODE_DESIGN_LOGO_ID.LOGO_2]: {
    id: QR_CODE_DESIGN_LOGO_ID.LOGO_2,
    title: 'Logo 2',
    positionIndex: 1,
  },
};

export type PosterSecondaryColorType = {
  color_1: string;
  color_2: string;
  color_3: string;
  color_4: string;
};

export enum POSTER_SECONDARY_COLOR_IDS {
  'FIRST' = 0,
  'SECOND' = 1,
  'THIRD' = 2,
  'FOURTH' = 3,
}

export const SECONDARY_COLOR_PRESET_DETAILS: Record<POSTER_SECONDARY_COLOR_IDS, PosterSecondaryColorType> = {
  [POSTER_SECONDARY_COLOR_IDS.FIRST]: {
    color_1: '#FF814A',
    color_2: '#FF397B',
    color_3: '#10FFE2',
    color_4: '#FFD60B',
  },
  [POSTER_SECONDARY_COLOR_IDS.SECOND]: {
    color_1: '#FE4747',
    color_2: '#A404FC',
    color_3: '#C8FA1A',
    color_4: '#95FAB4',
  },
  [POSTER_SECONDARY_COLOR_IDS.THIRD]: {
    color_1: '#FFFF19',
    color_2: '#40FFEE',
    color_3: '#FF1B89',
    color_4: '#636DFF',
  },
  [POSTER_SECONDARY_COLOR_IDS.FOURTH]: {
    color_1: '#FF6EB0',
    color_2: '#FFFF17',
    color_3: '#11FF76',
    color_4: '#663AFF',
  },
};

export enum QR_CODE_POSTER_IDS {
  SQUARE_CIRCLE = 'SQUARE_CIRCLE',
  SIMPLE_BORDER = 'SIMPLE_BORDER',
  RIGHT_ALIGNED_QR = 'RIGHT_ALIGNED_QR',
  CORNER_TRIANGLES = 'CORNER_TRIANGLES',
  SOLID_BG_COLORS = 'SOLID_BG_COLORS',
  NAVY_SEMI_CIRCLE = 'NAVY_SEMI_CIRCLE',
  PURPLE_THREADS = 'PURPLE_THREADS',
  SNOWFLAKE = 'SNOWFLAKE',
  CORNER_FLOWERS = 'CORNER_FLOWERS',
  LEAVES_BORDER = 'LEAVES_BORDER',
}

export const QR_CODE_POSTERS: Record<QR_CODE_POSTER_IDS, QRCodePoster> = {
  [QR_CODE_POSTER_IDS.SQUARE_CIRCLE]: {
    id: QR_CODE_POSTER_IDS.SQUARE_CIRCLE,
    label: 'Squares',
    value: QR_CODE_POSTER_IDS.SQUARE_CIRCLE,
    imageType: 'svg',
    backgroundCover: {
      standee: 'squareCircleStandee',
      pocketCardFront: 'squareCirclePocketCardFront',
      pocketCardBack: 'squareCirclePocketCardBack',
      tentCard: 'squareCircleTentCard',
      ledScreen: 'squareCircleLEDScreen',
    },
    designOptions: { numberOfColors: 2 },
  },

  [QR_CODE_POSTER_IDS.SIMPLE_BORDER]: {
    id: QR_CODE_POSTER_IDS.SIMPLE_BORDER,
    label: 'Border',
    value: QR_CODE_POSTER_IDS.SIMPLE_BORDER,
    imageType: 'svg',
    backgroundCover: {
      standee: 'simpleBorderStandee',
      pocketCardFront: 'simpleBorderPocketFront',
      tentCard: 'simpleBorderTent',
      ledScreen: 'simpleBorderLEDScreen',
    },
    designOptions: { numberOfColors: 1 },
  },
  [QR_CODE_POSTER_IDS.RIGHT_ALIGNED_QR]: {
    id: QR_CODE_POSTER_IDS.RIGHT_ALIGNED_QR,
    label: 'Unshift',
    value: QR_CODE_POSTER_IDS.RIGHT_ALIGNED_QR,
    imageType: 'svg',
    customIllustration: true,
    backgroundCover: {
      standee: 'rightAlignedQRStandee',
      pocketCardFront: 'rightAlignedQRPocketFront',
      pocketCardBack: 'rightAlignedQRPocketBack',
      tentCard: 'rightAlignedQRTent',
      ledScreen: 'rightAlignedLEDScreen',
    },
    designOptions: {
      isSecondaryColorAvailable: true,
      numberOfColors: 2,
      standeeHeaderContrastText: true,
      standeeFooterContrastText: true,
      pocketFrontHeaderContrastText: true,
      pocketFrontFooterContrastText: true,
      pocketBackContrastText: true,
    },
  },
  [QR_CODE_POSTER_IDS.CORNER_TRIANGLES]: {
    id: QR_CODE_POSTER_IDS.CORNER_TRIANGLES,
    label: 'Triangles',
    value: QR_CODE_POSTER_IDS.CORNER_TRIANGLES,
    imageType: 'svg',
    backgroundCover: {
      standee: 'cornerTrianglesStandee',
      pocketCardFront: 'cornerTrianglesPocketFront',
      tentCard: 'cornerTrianglesTent',
      ledScreen: 'cornerTrianglesLEDScreen',
    },
    designOptions: {
      smallerLogoSpace: true,
      numberOfColors: 2,
    },
  },
  [QR_CODE_POSTER_IDS.SOLID_BG_COLORS]: {
    id: QR_CODE_POSTER_IDS.SOLID_BG_COLORS,
    label: 'Solid',
    value: QR_CODE_POSTER_IDS.SOLID_BG_COLORS,
    imageType: 'svg',
    backgroundCover: {
      standee: 'solidBgColorsStandee',
      pocketCardFront: 'solidBgColorsPocketFront',
      pocketCardBack: 'solidBgColorsPocketBack',
      tentCard: 'solidBgColorsTent',
      ledScreen: 'solidBgColorsLEDScreen',
    },
    designOptions: {
      smallerLogoSpace: true,
      numberOfColors: 2,
      standeeHeaderContrastText: true,
      standeeFooterContrastText: true,
      pocketFrontHeaderContrastText: true,
      pocketFrontFooterContrastText: true,
    },
  },
  [QR_CODE_POSTER_IDS.NAVY_SEMI_CIRCLE]: {
    id: QR_CODE_POSTER_IDS.NAVY_SEMI_CIRCLE,
    label: 'Navy',
    value: QR_CODE_POSTER_IDS.NAVY_SEMI_CIRCLE,
    imageType: 'jpg',
    backgroundCover: {
      standee: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.NAVY_SEMI_CIRCLES_STANDEE,
      pocketCardFront: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.NAVY_SEMI_CIRCLES_POCKET_FRONT,
      pocketCardBack: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.NAVY_SEMI_CIRCLES_POCKET_BACK,
      tentCard: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.NAVY_SEMI_CIRCLES_STANDEE,
      ledScreen: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.NAVY_SEMI_CIRCLES_STANDEE_LED,
    },
    designOptions: {
      smallerLogoSpace: true,
      numberOfColors: 0,
      standeeFooterContrastText: true,
      pocketFrontFooterContrastText: true,
    },
  },
  [QR_CODE_POSTER_IDS.PURPLE_THREADS]: {
    id: QR_CODE_POSTER_IDS.PURPLE_THREADS,
    label: 'Purple threads',
    value: QR_CODE_POSTER_IDS.PURPLE_THREADS,
    imageType: 'jpg',

    backgroundCover: {
      standee: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.PURPLE_THREADS_STANDEE,
      pocketCardFront: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.PURPLE_THREADS_POCKET_FRONT,
      pocketCardBack: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.PURPLE_THREADS_POCKET_BACK,
      tentCard: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.PURPLE_THREADS_STANDEE,
      ledScreen: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.PURPLE_THREADS_LED,
    },
    designOptions: {
      smallerLogoSpace: true,
      numberOfColors: 0,
      standeeFooterContrastText: true,
      pocketFrontFooterContrastText: true,
    },
  },
  [QR_CODE_POSTER_IDS.SNOWFLAKE]: {
    id: QR_CODE_POSTER_IDS.SNOWFLAKE,
    label: 'Snowflake',
    value: QR_CODE_POSTER_IDS.SNOWFLAKE,
    imageType: 'svg',
    backgroundCover: {
      standee: 'snowflakeStandee',
      pocketCardFront: 'snowflakePocketFront',
      pocketCardBack: 'snowflakePocketBack',
      tentCard: 'snowflakeStandee',
      ledScreen: 'snowflakeLED',
    },
    designOptions: {
      smallerLogoSpace: true,
      numberOfColors: 2,
    },
  },
  [QR_CODE_POSTER_IDS.CORNER_FLOWERS]: {
    id: QR_CODE_POSTER_IDS.CORNER_FLOWERS,
    label: 'Flowers',
    value: QR_CODE_POSTER_IDS.CORNER_FLOWERS,
    imageType: 'jpg',
    backgroundCover: {
      standee: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_STANDEE_SINGLE_LOGO,
      standeeMultiLogo: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_STANDEE_MULTI_LOGO,
      pocketCardFront: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_POCKET_CARD_FRONT_SINGLE_LOGO,
      pocketCardFrontMultiLogo: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_POCKET_CARD_FRONT_MULTI_LOGO,
      pocketCardBack: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_POCKET_CARD_BACK,
      tentCard: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_STANDEE_SINGLE_LOGO,
      tentCardMultiLogo: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_STANDEE_MULTI_LOGO,
      ledScreen: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.CORNER_FLOWERS_LED_SCREEN,
    },
    designOptions: {
      numberOfColors: 0,
    },
  },
  [QR_CODE_POSTER_IDS.LEAVES_BORDER]: {
    id: QR_CODE_POSTER_IDS.LEAVES_BORDER,
    label: 'Leaves',
    value: QR_CODE_POSTER_IDS.LEAVES_BORDER,
    imageType: 'jpg',

    backgroundCover: {
      standee: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.SIMPLE_FLOWERS,
      tentCard: ASSETS_URL.QR_CODE_BACKGROUND_POSTER.SIMPLE_FLOWERS,
    },
    designOptions: {
      numberOfColors: 0,
    },
  },
};
