import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { LOADINGS } from '../../../../../common/Constants';
import ProjectBackgroundTasksInfo from './ProjectBackgroundTasksInfo';
import {
  getProjectBackgroundTasksData,
  projectBackgroundTasksForProjectSelector,
} from './ProjectBackgroundTasksDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  return {
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PROJECT_BACKGROUND_TASKS.FETCH)(state),
    tasks: projectBackgroundTasksForProjectSelector(projectId)(state),
    projectId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId) => dispatch(getProjectBackgroundTasksData(dispatch, projectId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBackgroundTasksInfo);
