import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck, ModalDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { EventPosterService } from '@premagic/core';
import AddEventPosterPanel from './AddEventPosterPanel';
import { LOADINGS } from '../../../../../../common/Constants';
import { addEventPoster } from '../EventPostersDataDuck';

const PANEL_KEY = LOADINGS.EVENT_POSTERS.SHOW_ADD_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    eventId: string;
    type: EventPosterService.EVENT_POSTER_TYPE;
  },
) {
  const { projectId, eventId, type } = ownProps;

  return {
    projectId,
    eventId,
    type,
    show: WindowPanelDuck.isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_POSTERS.CREATE)(state),
    error: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADINGS.EVENT_POSTERS.CREATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addEventPoster: (options: { projectId: string; eventId: string }, data) =>
      dispatch(addEventPoster(dispatch, options, data)),
    close: () => dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEventPosterPanel);
