import React from 'react';
import { connect } from 'react-redux';
import { ProductFeedbackService, RouterService } from '@premagic/core';
import ReportIssueFilterByTypeDropdown from './ReportIssueFilterByTypeDropdown';
import { enableReportingTheseAreNotMyPhotosSelector } from '../../../../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(
  state,
  ownProps: { projectId: string; eventId: string; reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE },
) {
  const { projectId, eventId, reportType } = ownProps;
  return {
    eventId,
    reportType,
    isNotMyPhotoEnabled: enableReportingTheseAreNotMyPhotosSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => {
      RouterService.navigateTo(dispatch, url, {});
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssueFilterByTypeDropdown);
