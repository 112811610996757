import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { FolderService } from '@premagic/core';

import BrandingFilesModal from './BrandingFilesModal';
import { LOADINGS } from '../../../../../../common/Constants';
import { folderIdsWithFolderTypeForProjectSelector } from '../../AccountProjectsDataDuck';

const PANEL_KEY = LOADINGS.BRANDING_FOLDER.FILES_MODAL;
function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;

  const sponsorFolderIds = folderIdsWithFolderTypeForProjectSelector(
    projectId,
    FolderService.FOLDER_TYPES.BRANDING,
  )(state);

  return {
    projectId,
    folderId: sponsorFolderIds[0],
    show: ModalDuck.isModalOpenSelector(PANEL_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, PANEL_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingFilesModal);
