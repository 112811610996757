import { ActionTypeUtils } from '@premagic/utils';
import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { pagesSelector } from '../../../../../../reducers/selectors';
import { WIDGET_TYPE } from '../service/EventAttendeeBadgeDesignService';

const getActionType = ActionTypeUtils.getActionTypeFunction('EVENT_ATTENDEE_BADGE_DESIGN', true);

export const setSelectedWidget = createAction(
  getActionType('SELECTED_WIDGET', 'SET'),
  (dispatch: Dispatch, widgetId: WIDGET_TYPE | null) => widgetId,
);

type BadgeDesignPageType = {
  selected_widget: WIDGET_TYPE | null;
};
const initialState = {
  selected_widget: undefined,
};

export default handleActions(
  {
    [setSelectedWidget.toString()]: (state, action: { payload }) => ({
      ...state,
      selected_widget: action.payload,
    }),
  },
  initialState,
);

export const eventAttendeesBadgeDesignPageSelector = createSelector(
  pagesSelector,
  (state) => state.eventAttendees.badgeDesign as BadgeDesignPageType,
);

export const selectedWidgetSelector = createSelector(
  eventAttendeesBadgeDesignPageSelector,
  (state: any) => state.selected_widget,
);
