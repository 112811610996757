import React, { useMemo, useState } from 'react';
import {
  Button,
  BUTTON_STYLES,
  Col,
  DECK_SLIDE_DATA_TYPE_DETAILS,
  DECK_SLIDE_DATA_TYPES,
  DECK_SLIDE_LAYOUTS,
  DECK_SLIDE_LAYOUTS_DETAILS,
  DeckSlideDataType,
  FormGroup,
  FormLabel,
  getComponentForInputType,
  Icon,
  ICON_SIZES,
  Row,
  Space,
  SubTitle,
  Text,
  ErrorBoundary,
} from '@premagic/myne';
import { update } from 'lodash';
import { ProposalDeckService, ProposalSlideService, ProposalVariableService } from '@premagic/proposals';
import SlideDataSettingButtonContainer from '../slide-data-settings/SlideDataSettingButtonContainer';
import ProposalCreatorPackageInvoicesUpdaterContainer from './ProposalCreatorPackageInvoicesUpdaterContainer';
import ProposalCreatorDeckSetVariablesValuesContainer from '../deck-variables/ProposalCreatorDeckSetVariablesValuesContainer';
import ProposalCreatorEquipmentsUpdaterContainer from './ProposalCreatorEquipmentsUpdaterContainer';

type SlideInputProps = {
  datatype: DECK_SLIDE_DATA_TYPES;
  value: DeckSlideDataType[DECK_SLIDE_DATA_TYPES];
  onChange: (dataType: DECK_SLIDE_DATA_TYPES, value: string) => void;
  variables: Array<{
    label: string;
    id: string;
  }>;
  projectId: string;
  deckId: string;
  onNewVariable: () => void;
};

function SlideInput(props: SlideInputProps) {
  const { datatype, value, onChange, variables, onNewVariable, projectId, deckId } = props;
  const datatypeDetails = DECK_SLIDE_DATA_TYPE_DETAILS[datatype];
  const InputComponent = getComponentForInputType(datatypeDetails.inputType);

  return (
    <FormGroup>
      <Row>
        <Col>
          <FormLabel>{datatypeDetails.title}</FormLabel>
        </Col>
        <Col rightAlighed size={null}>
          <SlideDataSettingButtonContainer projectId={projectId} dataType={datatype} deckId={deckId} />
        </Col>
      </Row>
      <InputComponent
        defaultValue={value?.value}
        name={datatype}
        variables={variables}
        onNewVariable={onNewVariable}
        onChange={(e) => onChange(datatype, e.target ? e.target.value : e)}
      />
    </FormGroup>
  );
}

const SlideDataUpdateComponents = {
  [DECK_SLIDE_LAYOUTS.PACKAGE]: ProposalCreatorPackageInvoicesUpdaterContainer,
  [DECK_SLIDE_LAYOUTS.EQUIPMENT]: ProposalCreatorEquipmentsUpdaterContainer,
};

type Props = {
  slideDetails?: ProposalSlideService.ProposalSlideType;
  projectId: string;
  folderId: string;
  deckId: string;
  saveProposalSlide: (options: { projectId: string; deckId: string; id: number }, ProposalSlideType) => void;
  defaultVariables: Array<ProposalVariableService.ProposalVariableType>;
  showAddNewVariable: () => void;
  variablesSetOnSlide?: Array<string>;
  saveDeck: (projectId: string, deckId: string, data) => void;
  deck?: ProposalDeckService.ProposalDeckType;
};

export default function ProposalCreatorSlideDataUpdater(props: Props): JSX.Element | null {
  const {
    saveProposalSlide,
    projectId,
    folderId,
    deckId,
    defaultVariables,
    slideDetails,
    showAddNewVariable,
    variablesSetOnSlide,
    saveDeck,
    deck,
  } = props;
  const [expand, setExpand] = useState(true);

  function saveVariablesOnSlide(structure) {
    if (!slideDetails) return;

    const slideId = slideDetails?.id;
    const largeText = structure[DECK_SLIDE_DATA_TYPES.LARGE_TEXT]?.value || '';
    const slideVariableData = ProposalDeckService.getAllTheVariablesToSaveFromTheTextContent(
      largeText,
      deck?.variables || {},
      slideId,
    );

    const updatedVariablesData = {
      data: {
        variables: {
          ...deck?.variables,
          [slideId]: slideVariableData,
        },
      },
    };
    saveDeck(projectId, deckId, updatedVariablesData);
  }

  function handleOnChange(dataType: DECK_SLIDE_DATA_TYPES, value) {
    if (!slideDetails) return;
    const newStructure = update(slideDetails.structure, `[${dataType}][value]`, () => value);

    saveProposalSlide(
      { projectId, deckId, id: slideDetails.id },
      {
        structure: newStructure,
      },
    );
    saveVariablesOnSlide(newStructure);
  }
  // const debouncedOnChange = useMemo(() => {
  //   console.log('Debounced function created', slideDetails);
  //   return debounce(handleOnChange, 1000);
  // }, [JSON.stringify(slideDetails)?.length]);

  const variableOptions = useMemo(
    () =>
      defaultVariables.map((variable) => ({
        label: variable.name,
        id: variable.id,
      })),
    [defaultVariables],
  );

  if (!slideDetails) return null;

  const { id, template_id: layoutId } = slideDetails;

  const layoutDetails = DECK_SLIDE_LAYOUTS_DETAILS[layoutId];
  if (!layoutDetails)
    return (
      <Space>
        <Text>This layout is deprecated, please delete and create a new slide</Text>
      </Space>
    );
  const { availableDataTypes } = layoutDetails;
  const LayoutSpecificUpdater = SlideDataUpdateComponents[layoutId];

  return (
    <Space>
      <ErrorBoundary>
        <Button style={BUTTON_STYLES.RESET} onClick={() => setExpand(!expand)} block>
          <Row vcenter>
            <SubTitle>Slide information</SubTitle>
            <Col size={null} rightAlighed>
              <Icon name={expand ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
            </Col>
          </Row>
        </Button>
        {expand && (
          <div>
            <Space vertical size={2} />
            {availableDataTypes.map((datatype) => {
              const datatypeValue = slideDetails.structure[datatype];
              return (
                <SlideInput
                  key={`${id}-${datatype}`}
                  datatype={datatype}
                  value={datatypeValue}
                  variables={variableOptions}
                  onNewVariable={showAddNewVariable}
                  onChange={handleOnChange}
                  projectId={projectId}
                  deckId={deckId}
                />
              );
            })}
            {LayoutSpecificUpdater && (
              <div>
                <LayoutSpecificUpdater projectId={projectId} deckId={deckId} />
                <Space vertical size={2} />
              </div>
            )}
            <ProposalCreatorDeckSetVariablesValuesContainer
              projectId={projectId}
              folderId={folderId}
              deckId={deckId}
              slideId={slideDetails.id}
            />
          </div>
        )}
      </ErrorBoundary>
    </Space>
  );
}
