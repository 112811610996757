import { FormGroup, FormLabel, INPUT_SIZES, Select, TEXT_SIZE } from '@premagic/myne';
import {
  EventAttendeeBadgeDesignType,
  WIDGET_TYPE,
  WidgetDataType,
} from '../../../service/EventAttendeeBadgeDesignService';

type Props = {
  projectId: string;
  folderId: string;
  selectedWidgetForEdit?: WIDGET_TYPE;
  designData?: EventAttendeeBadgeDesignType;
  editFont: (
    projectId: string,
    folderId: string,
    previousDesign?: EventAttendeeBadgeDesignType,
    widgetId?: string,
    fontSize?: string,
  ) => void;
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
};

const fontSizeOptions = [
  {
    value: TEXT_SIZE.SIZE_1,
    label: 'Large',
  },
  {
    value: TEXT_SIZE.SIZE_2,
    label: 'Medium',
  },
  {
    value: TEXT_SIZE.SIZE_3,
    label: 'Small',
  },
  {
    value: TEXT_SIZE.SIZE_4,
    label: 'X-Small',
  },
  {
    value: TEXT_SIZE.SIZE_5,
    label: 'XX-Small',
  },
];

export default function EventAttendeeBadgeFontSizeSelect(props: Props) {
  const { selectedWidgetForEdit, designData, editFont, projectId, folderId, posterWidgetDesign } = props;

  function handleFontSelect(option) {
    const { value } = option;
    editFont(projectId, folderId, designData, selectedWidgetForEdit, value);
  }
  const defaultOption =
    selectedWidgetForEdit &&
    fontSizeOptions.find(
      (option) => option.value === (posterWidgetDesign?.[selectedWidgetForEdit]?.fontSize || TEXT_SIZE.SIZE_1),
    );
  return (
    <FormGroup>
      <FormLabel>Font Size</FormLabel>
      <Select
        options={fontSizeOptions}
        name="fontSize"
        size={INPUT_SIZES.SM}
        isMulti={false}
        onChange={handleFontSelect}
        value={defaultOption}
      />
    </FormGroup>
  );
}
