import { connect } from 'react-redux';
import { WindowPanelDuck } from '@premagic/common-ducks';

import AccountHelpPanel from './AccountHelpPanel';
import { toggleModalVisibility } from '../../../../common/ModalDuck';
import { MODALS } from '../../../../common/Constants';
import { isCompanyTypeNotPhotographerSelector } from '../settings/preferences/ClientSettingsDataDuck';

function mapStateToProps(state) {
  return {
    show: WindowPanelDuck.isWindowPanelOpenSelector(MODALS.HELP_PANEL)(state),
    isCompanyTypeNotPhotographer: isCompanyTypeNotPhotographerSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, MODALS.HELP_PANEL, false));
    },
    toggleTour: (status: boolean) => {
      dispatch(toggleModalVisibility(dispatch, MODALS.TOUR_MODAL, status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountHelpPanel);
