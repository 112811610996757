import { ProjectBackgroundTaskService } from '@premagic/core';
import {
  ErrorBoundary,
  HTML_ICON,
  LoadingDots,
  Row,
  STATUS_LABEL_STYLES,
  StatusLabel,
  Text,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { ArrayUtils, SimpleDateUtils } from '@premagic/utils';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

const STATUS_LABEL_TASK_STATE_MAP: Record<
  ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS,
  { style: STATUS_LABEL_STYLES; state: string }
> = {
  [ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS.DONE]: {
    style: STATUS_LABEL_STYLES.SUCCESS,
    state: 'Completed',
  },
  [ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS.ERROR]: {
    style: STATUS_LABEL_STYLES.DANGER,
    state: 'Errored out',
  },
  [ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS.IN_PROGRESS]: {
    style: STATUS_LABEL_STYLES.PRIMARY,
    state: 'In Progress',
  },
  [ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS.QUEUED]: {
    style: STATUS_LABEL_STYLES.SECONDARY,
    state: 'Waiting',
  },
};

type Props = {
  isLoading: boolean;
  tasks?: Partial<ProjectBackgroundTaskService.ProjectBackgroundTaskType>;
  initData: (projectId: string) => void;
  projectId: string;
};

export default function ProjectBackgroundTasksInfo(props: Props) {
  const { isLoading, tasks, initData, projectId } = props;

  useEffect(() => {
    initData(projectId);
  }, [initData, projectId]);

  if (isLoading) {
    return (
      <Row center>
        <LoadingDots size="sm" />
      </Row>
    );
  }

  if (!tasks || isEmpty(tasks)) {
    return (
      <Text muted center block size={TEXT_SIZE.SIZE_5}>
        No info on tasks yet.
      </Text>
    );
  }

  return (
    <ErrorBoundary>
      {Object.entries(tasks)
        .sort((a, b) => ArrayUtils.dateSortFunction(a[1].ttl, b[1].ttl, true))
        .map(([key, value]) => {
          const metricDetails = ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_TYPE_DETAIL[key];
          if (!value.status) return;
          const showTTL = [
            ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS.IN_PROGRESS,
            ProjectBackgroundTaskService.PROJECT_BACKGROUND_TASK_STATUS.QUEUED,
          ].includes(value.status);
          const ttlDate = SimpleDateUtils.getDateStringISOFromUglyBackendDateFormat(value.ttl);
          const labelInfo = STATUS_LABEL_TASK_STATE_MAP[value.status];
          return (
            <div key={key}>
              <Tooltip message={labelInfo.state}>
                <StatusLabel style={labelInfo.style}>
                  {metricDetails.title} {showTTL && HTML_ICON.DASH_SMALL}{' '}
                  {showTTL && SimpleDateUtils.humanizeDate(ttlDate, true)}
                </StatusLabel>
              </Tooltip>
            </div>
          );
        })}
    </ErrorBoundary>
  );
}
