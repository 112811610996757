import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  Deck,
  DeckSlide,
  Dialog,
  DIALOG_SIZES,
  Icon,
  ICON_SIZES,
  INPUT_SIZES,
  Row,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { FileService, FolderMetaService, FontService } from '@premagic/core';
import { DeviceUtils, MegaFileUploadService } from '@premagic/utils';
import {
  ProposalDeckService,
  ProposalService,
  ProposalSlideService,
  ProposalVariableService,
} from '@premagic/proposals';
import { toastMessage } from '../../../../reducers/ToastStore';

const ProposalCreatorSlidePage = styled.div`
  position: relative;
  margin: auto;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

type DevicePickerProps = {
  activeDevice: DeviceUtils.DEVICES;
  onChange: (device: DeviceUtils.DEVICES) => void;
};

function DevicePicker(props: DevicePickerProps) {
  const { onChange, activeDevice } = props;
  const $target = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Row>
      <Col size={null} rightAlighed>
        <Button style={BUTTON_STYLES.RESET} onClick={() => setIsOpen(!isOpen)} ref={$target}>
          <Row vcenter>
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {activeDevice}
            </Text>
            <Icon name={isOpen ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
          </Row>
        </Button>

        <Dialog target={$target.current} onClose={() => setIsOpen(false)} show={isOpen} size={DIALOG_SIZES.SM}>
          <Select
            options={DeviceUtils.deviceOptions}
            autoFocus
            name=""
            size={INPUT_SIZES.SM}
            onChange={(option) => onChange(option?.value as DeviceUtils.DEVICES)}
            value={DeviceUtils.deviceOptions.find((item) => item.value === activeDevice)}
            isMulti={false}
          />
        </Dialog>
      </Col>
    </Row>
  );
}

type Props = {
  folderMeta?: FolderMetaService.FolderMetaType;
  slideDetails?: ProposalSlideService.ProposalSlideType;
  files: Record<string, FileService.FolderFileType>;
  uploadingFiles: Record<string, MegaFileUploadService.UploaderFileType>;
  deck: ProposalDeckService.ProposalDeckType;
  slides: Array<ProposalSlideService.ProposalSlideType>;
  proposalVariableDetails: Array<ProposalVariableService.ProposalVariableType>;
};

export default function ProposalCreatorSlide(props: Props) {
  const { slideDetails, files, uploadingFiles, folderMeta = {}, deck, slides, proposalVariableDetails } = props;
  const { variables } = deck || {};
  const packageSlideTotal = useMemo(
    () => ProposalService.getAllSlidesInvoiceItemTotal(slides, variables, proposalVariableDetails),
    [slides, variables, proposalVariableDetails],
  );
  const [activeDevice, setActiveDevice] = useState(DeviceUtils.DEVICES.IPHONE_X);
  const { width, height } = DeviceUtils.DEVICE_DETAILS[activeDevice];

  if (!slideDetails)
    return (
      <Row fullHeight>
        <ProposalCreatorSlidePage>
          <Space size={8}>
            <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.BOLD} block primaryStyle>
              Welcome to Proposals
            </Text>
            <Space vertical />
            <Text size={TEXT_SIZE.SIZE_3} block>
              A Proposal consist of
              <ul>
                <li>Slides</li>
                <li>Files</li>
                <li>Variable</li>
              </ul>
            </Text>
            <Space vertical />
            <Text size={TEXT_SIZE.SIZE_3} block>
              Lets start by adding{' '}
              <Text size={TEXT_SIZE.SIZE_3} color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                new slides from the left side
              </Text>
              .
            </Text>
          </Space>
        </ProposalCreatorSlidePage>
      </Row>
    );

  const fonts = {
    primary: FontService.FONTS_DETAILS[folderMeta[FolderMetaService.FOLDER_META_TYPES.PRIMARY_FONT]]?.name,
    secondary: FontService.FONTS_DETAILS[folderMeta[FolderMetaService.FOLDER_META_TYPES.SECONDARY_FONT]]?.name,
  };
  const { structure, template_id: layoutId, id } = slideDetails;

  const deckAdditionalData = {
    // expiryDate: null,
    reviewStatus: deck?.review_status,
    currencyCode: folderMeta[FolderMetaService.FOLDER_META_TYPES.CURRENCY_CODE],
    packageSlideTotal,
  };

  return (
    <div>
      <DevicePicker activeDevice={activeDevice} onChange={setActiveDevice} />
      <Space vertical size={2} />
      <ProposalCreatorSlidePage
        style={{
          width,
          height,
        }}
      >
        <Deck
          width={width}
          height={height}
          variables={variables}
          proposalVariableDetails={proposalVariableDetails}
          fonts={fonts}
          files={files}
          uploadingFiles={uploadingFiles}
          defaultSlideIndex={0}
          deckAdditionalData={deckAdditionalData}
          onButtonActions={(action) => toastMessage('info', 'You have clicked on the button 😃')}
        >
          {[<DeckSlide layout={layoutId} data={structure} key={id} id={id} />]}
        </Deck>
      </ProposalCreatorSlidePage>
    </div>
  );
}
