import {
  BUTTON_STYLES,
  Button,
  CARD_STYLES,
  Card,
  Col,
  Form,
  FormFooter,
  ICON_SIZES,
  Icon,
  Row,
  Space,
  SubTitle,
} from '@premagic/myne';
import { useState } from 'react';
import { toastMessage } from '../../../../../../reducers/ToastStore';
import { usePhotoSliderWidgetData } from '../../../context/PhotoSliderWidgetContext';
import PhotoSliderWidgetBackgroundColor from './PhotoSliderWidgetBackgroundColor';
import PhotoSliderWidgetHeight from './PhotoSliderWidgetHeight';
import PhotoSliderWidgetNumOfImages from './PhotoSliderWidgetNumOfImages';
import PhotoSliderWidgetMaxHeight from './PhotoSliderWidgetMaxHeight';

type Props = {
  hasFeature?: boolean;
};

export default function PhotoSliderCustomizationLayout(props: Props) {
  const { hasFeature } = props;

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const { setCustomizations } = usePhotoSliderWidgetData();

  function handleSubmit(formData) {
    if (setCustomizations && formData) {
      setCustomizations(formData.data);
      toastMessage('success', 'Widget code updated');
    }
  }

  return (
    <Space>
      <Button onClick={() => setIsAccordionOpen(!isAccordionOpen)} style={BUTTON_STYLES.RESET} block>
        <Row>
          <SubTitle>CUSTOMIZATIONS</SubTitle>
          <Col size={null} rightAlighed>
            <Icon name={isAccordionOpen ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
          </Col>
        </Row>
      </Button>
      {isAccordionOpen && (
        <Card style={CARD_STYLES.PRIMARY}>
          <Form
            onSubmit={handleSubmit}
            formSpec={{
              height: {
                type: 'number',
              },
              num_of_images: {
                type: 'number',
              },
            }}
          >
            <Space>
              <Space vertical />
              <PhotoSliderWidgetNumOfImages />
              <Space vertical />
              {/* <PhotoSliderWidgetHeight /> */}
              <PhotoSliderWidgetMaxHeight />
              <Space vertical />
              <PhotoSliderWidgetBackgroundColor />
              <FormFooter>
                <Button type="submit" style={BUTTON_STYLES.PRIMARY} disabled={!hasFeature}>
                  Save
                </Button>
              </FormFooter>
            </Space>
          </Form>
        </Card>
      )}
    </Space>
  );
}
