import { connect } from 'react-redux';
import { eventExhibitorsSelector } from '../EventExhibitorDataDuck';
import EventExhibitorForm from './EventExhibitorForm';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    exhibitorId?: string;
  },
) {
  const { projectId, exhibitorId } = ownProps;

  return {
    projectId,
    exhibitorId,
    exhibitor: exhibitorId ? eventExhibitorsSelector(state)[exhibitorId] : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventExhibitorForm);
