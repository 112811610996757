import { APIURLService } from '@premagic/core';
import { HttpUtils, StringUtils } from '@premagic/utils';

const URL = APIURLService.API_URLS.PROPOSAL_VARIABLES;

export enum PROPOSAL_VARIABLE_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  DISCOUNT_PERCENT = 'discount-percentage',
  DISCOUNT_CURRENCY = 'discount-currency',
  TAX = 'tax-percentage',
}

export const PROPOSAL_VARIABLE_TYPE_DETAILS: Record<
  PROPOSAL_VARIABLE_TYPE,
  {
    name: string;
  }
> = {
  [PROPOSAL_VARIABLE_TYPE.TEXT]: {
    name: 'Text',
  },
  [PROPOSAL_VARIABLE_TYPE.NUMBER]: {
    name: 'Number',
  },
  [PROPOSAL_VARIABLE_TYPE.DATE]: {
    name: 'Date',
  },
  [PROPOSAL_VARIABLE_TYPE.CURRENCY]: {
    name: 'Currency',
  },
  [PROPOSAL_VARIABLE_TYPE.PERCENTAGE]: {
    name: 'Percentage',
  },
  [PROPOSAL_VARIABLE_TYPE.DISCOUNT_CURRENCY]: {
    name: 'Discount amount',
  },
  [PROPOSAL_VARIABLE_TYPE.DISCOUNT_PERCENT]: {
    name: 'Discount in Percentage',
  },
  [PROPOSAL_VARIABLE_TYPE.TAX]: {
    name: 'Tax',
  },
};

export enum PROPOSAL_VARIABLE_SCOPE {
  DEFAULT = 'DEFAULT',
  INVOICE_ITEM = 'INVOICE_ITEM',
  CAMERA_DETAILS = 'CAMERA_DETAILS',
}

export const PROPOSAL_VARIABLE_SCOPE_DETAILS: Record<
  PROPOSAL_VARIABLE_SCOPE,
  {
    title: string;
    addNewVariableButtonText: string;
    addPlaceholder: string;
    types: Array<PROPOSAL_VARIABLE_TYPE>;
  }
> = {
  [PROPOSAL_VARIABLE_SCOPE.DEFAULT]: {
    title: 'Proposal Variables',
    addNewVariableButtonText: 'Variable',
    addPlaceholder: 'eg. Candid photographers',
    types: [
      PROPOSAL_VARIABLE_TYPE.NUMBER,
      PROPOSAL_VARIABLE_TYPE.DATE,
      PROPOSAL_VARIABLE_TYPE.TEXT,
      PROPOSAL_VARIABLE_TYPE.CURRENCY,
    ],
  },
  [PROPOSAL_VARIABLE_SCOPE.INVOICE_ITEM]: {
    title: 'Proposal Invoice Item',
    addNewVariableButtonText: 'Invoice Item',
    addPlaceholder: 'eg. Total Excluding Tax',
    types: [
      PROPOSAL_VARIABLE_TYPE.CURRENCY,
      PROPOSAL_VARIABLE_TYPE.DISCOUNT_PERCENT,
      PROPOSAL_VARIABLE_TYPE.DISCOUNT_CURRENCY,
      PROPOSAL_VARIABLE_TYPE.TAX,
    ],
  },
  [PROPOSAL_VARIABLE_SCOPE.CAMERA_DETAILS]: {
    title: 'Camera and Equipments',
    addNewVariableButtonText: 'Equipments',
    addPlaceholder: 'eg. Cannon EOS-1D X Mark III',
    types: [PROPOSAL_VARIABLE_TYPE.TEXT],
  },
};

export type NewProposalVariableType = {
  name: string;
  type: PROPOSAL_VARIABLE_TYPE;
};

export type ProposalVariableType = NewProposalVariableType & {
  is_system: boolean;
  id: string;
  scope: PROPOSAL_VARIABLE_SCOPE;
  default_value: string;
};

export function fetchProposalVariables(): Promise<Record<string, ProposalVariableType>> {
  return HttpUtils.get(URL.LIST)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function createProposalVariable(data: NewProposalVariableType): Promise<Record<string, ProposalVariableType>> {
  return HttpUtils.post(URL.LIST, {
    [data.name]: data,
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function updateProposalVariable(id: string, data: Partial<ProposalVariableType>): Promise<ProposalVariableType> {
  return HttpUtils.put(URL.LIST, {
    [id]: data,
  })
    .then((response) => response.data[id])
    .catch((error) => Promise.reject(error.data));
}

export function deleteProposalVariable(id: string): Promise<void> {
  return HttpUtils.httpDelete(URL.LIST, {
    data: {
      key: id,
    },
  })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function getVariableFormSpec(variableIds: Array<string>) {
  function duplicateVariableExist(newName: string): boolean {
    return variableIds.includes(StringUtils.getIdString(newName));
  }

  function nameValidator(newName): boolean {
    if (StringUtils.isEmptyString(newName)) {
      return false;
    }
    if (duplicateVariableExist(newName)) {
      return false;
    }
    return true;
  }

  function nameValidatorMessage(newName) {
    if (StringUtils.isEmptyString(newName)) {
      return 'Name is required';
    }
    if (duplicateVariableExist(newName)) {
      return 'a variable with this name already exists! please choose another name';
    }
    return false;
  }
  return {
    name: {
      validator: nameValidator,
      message: nameValidatorMessage,
    },
  };
}

export function flattenVariablesObject(variables) {
  const result = {};

  if (!variables) return result;

  Object.entries(variables).forEach(([key, value]) => {
    if (typeof value === 'object' && key !== 'proposal_variable_details' && value !== null) {
      Object.entries(value).forEach(([slideVar, slideVarValue]) => {
        if (slideVar in result) {
          result[slideVar] = String(Number(result[slideVar]) + Number(slideVarValue));
        } else {
          result[slideVar] = slideVarValue;
        }
      });
    }
  });

  return result;
}
