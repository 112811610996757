import { HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { FOLDER_TYPES } from './FolderService';
import { SponsorCreativeType, SPONSORS_CREATIVES_PLACEMENT_SIZE } from './SponsorCreativesService';

export enum EVENT_SPONSORS_PUBLISH_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum EVENT_SPONSORS_TIER {
  LEVEL_1 = 'LEVEL-1',
  LEVEL_2 = 'LEVEL-2',
  LEVEL_3 = 'LEVEL-3',
  LEVEL_4 = 'LEVEL-4',
  LEVEL_5 = 'LEVEL-5',
}

export type EventSponsorCategoryType = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  tier: EVENT_SPONSORS_TIER;
};

export const EVENT_SPONSORS_TIER_DETAILS: Record<
  EVENT_SPONSORS_TIER,
  {
    name: string;
    value: EVENT_SPONSORS_TIER;
    order: number;
    defaultCreativeSize: SPONSORS_CREATIVES_PLACEMENT_SIZE;
  }
> = {
  [EVENT_SPONSORS_TIER.LEVEL_1]: {
    name: 'Level 1',
    value: EVENT_SPONSORS_TIER.LEVEL_1,
    order: 1,
    defaultCreativeSize: SPONSORS_CREATIVES_PLACEMENT_SIZE.LARGE,
  },
  [EVENT_SPONSORS_TIER.LEVEL_2]: {
    name: 'Level 2',
    value: EVENT_SPONSORS_TIER.LEVEL_2,
    order: 2,
    defaultCreativeSize: SPONSORS_CREATIVES_PLACEMENT_SIZE.MEDIUM,
  },
  [EVENT_SPONSORS_TIER.LEVEL_3]: {
    name: 'Level 3',
    value: EVENT_SPONSORS_TIER.LEVEL_3,
    order: 3,
    defaultCreativeSize: SPONSORS_CREATIVES_PLACEMENT_SIZE.MEDIUM,
  },
  [EVENT_SPONSORS_TIER.LEVEL_4]: {
    name: 'Level 4',
    value: EVENT_SPONSORS_TIER.LEVEL_4,
    order: 4,
    defaultCreativeSize: SPONSORS_CREATIVES_PLACEMENT_SIZE.SMALL,
  },
  [EVENT_SPONSORS_TIER.LEVEL_5]: {
    name: 'Level 5',
    value: EVENT_SPONSORS_TIER.LEVEL_5,
    order: 5,
    defaultCreativeSize: SPONSORS_CREATIVES_PLACEMENT_SIZE.TINY,
  },
};

export type NewEventSponsorType = {
  name: string;
  email: string;
  phone: string;
};

export type EventSponsorType = NewEventSponsorType & {
  id: string;
  logo_asset_id?: string;
  creatives: Array<SponsorCreativeType>;
  status: EVENT_SPONSORS_PUBLISH_STATUS;
  created_at: string;
  updated_at: string;
  // will remove this later
  tier: EVENT_SPONSORS_TIER;
  category: string;
};

export function fetchEventSponsorsForProject(projectId: string): Promise<Record<string, EventSponsorType>> {
  return HttpUtils.get(API_URLS.EVENT_SPONSORS.LIST(projectId)).then(({ data }) => data);
}

export type SponsorFolderType = {
  folder_id: string;
  folder_type: FOLDER_TYPES.SPONSOR;
  folder_name: string;
  created_at: string;
  updated_at: string;
  files?: Record<string, string>;
  is_system_folder: boolean;
  data: Array<EventSponsorType>;
};

export function fetchEventSponsorsForPublic(
  shareId: string,
): Promise<{ data: Array<EventSponsorType>; sponsor_category_data: Array<EventSponsorCategoryType> }> {
  return HttpUtils.get(API_URLS.EVENT_SPONSORS.LIST_PUBLIC(shareId)).then(({ data }) => data);
}

export function fetchEventSponsorsForClientWebsite(
  domain: string,
  websiteId: string,
): Promise<{ data: Array<EventSponsorType>; sponsor_category_data: Array<EventSponsorCategoryType> }> {
  return HttpUtils.get(API_URLS.CLIENT_WEBSITE.SPONSORS_LIST(domain, websiteId)).then(({ data }) => data);
}

export function createEventSponsor(
  projectId: string,
  sponsor: NewEventSponsorType,
): Promise<Record<string, EventSponsorType>> {
  return HttpUtils.post(API_URLS.EVENT_SPONSORS.LIST(projectId), sponsor).then(({ data }) => data);
}

export function updateEventSponsor(
  options: {
    projectId: string;
    sponsorId: string;
  },
  sponsor: Partial<EventSponsorType>,
): Promise<Record<string, EventSponsorType>> {
  const { projectId, sponsorId } = options;
  return HttpUtils.put(API_URLS.EVENT_SPONSORS.DETAILS(projectId, sponsorId), sponsor).then(({ data }) => data);
}

export function deleteEventSponsor(projectId: string, sponsorId: string): Promise<void> {
  return HttpUtils.httpDelete(API_URLS.EVENT_SPONSORS.DETAILS(projectId, sponsorId)).then(({ data }) => data);
}

export function fetchAllEventSponsorsCategories(): Promise<Array<EventSponsorCategoryType>> {
  return HttpUtils.get(API_URLS.EVENT_SPONSORS.CATEGORY_ROOT).then(({ data }) => data);
}

export function createSponsorCategory(
  sponsorCategoryData: Partial<EventSponsorCategoryType>,
): Promise<EventSponsorCategoryType> {
  return HttpUtils.post(API_URLS.EVENT_SPONSORS.CATEGORY_ROOT, sponsorCategoryData).then(({ data }) => data);
}

export function updateSponsorCategory(
  sponsorCategoryId: string,
  sponsorCategoryData: Partial<EventSponsorCategoryType>,
): Promise<EventSponsorCategoryType> {
  return HttpUtils.put(API_URLS.EVENT_SPONSORS.CATEGORY_DETAILS(sponsorCategoryId), sponsorCategoryData).then(
    ({ data }) => data,
  );
}

export function getSponsorCategoryDetails(sponsorCategoryId: string): Promise<EventSponsorCategoryType> {
  return HttpUtils.get(API_URLS.EVENT_SPONSORS.CATEGORY_DETAILS(sponsorCategoryId)).then(({ data }) => data);
}
