import { HttpUtils } from '@premagic/utils';
import { APIURLService, EventTrackerService } from '@premagic/core';

export enum PAYMENT_TYPES {
  INFLOW = 'INFLOW',
  OUTFLOW = 'OUTFLOW',
  QUOTE = 'QUOTE',
}

export enum TAX_TYPES {
  NO_TAX = 'NO_TAX',
  INCLUDING = 'INCLUDING',
}

export const TAX_TYPES_DETAILS: Record<
  TAX_TYPES,
  {
    label: string;
    id: TAX_TYPES;
  }
> = {
  [TAX_TYPES.NO_TAX]: {
    label: 'No Tax',
    id: TAX_TYPES.NO_TAX,
  },
  [TAX_TYPES.INCLUDING]: {
    label: 'Including Tax',
    id: TAX_TYPES.INCLUDING,
  },
};

type CRMPaymentBaseType = {
  id: string;
  event: string;
  payment_category: number;
  human_readable_id: string;
  amount: number; // in MICRO_AMOUNT
  payment_method?: string;
  notes?: string;
  created_at: string;
  updated_at: string;
  added_by: string;
};

export type CRMPaymentSettledType = CRMPaymentBaseType & {
  is_settled: true;
  payment_date: string;
};

export type CRMPaymentUnSettledType = CRMPaymentBaseType & {
  is_settled: false;
  due_date: string;
};

export type CRMPaymentType = CRMPaymentBaseType & {
  is_settled: boolean;
  due_date?: string;
  payment_date?: string;
  taxable?: TAX_TYPES;
};

const PAYMENTS_URL = APIURLService.API_URLS.CRM_PAYMENTS;
const PAYMENTS_CATEGORY_URL = APIURLService.API_URLS.CRM_PAYMENT_CATEGORY;

export function fetchPaymentsForEvent(eventId: string): Promise<{ results: Array<CRMPaymentType> }> {
  return HttpUtils.get(PAYMENTS_URL.EVENT_ROOT(eventId), {
    limit: 100,
  }).then((response) => response.data);
}

export function createPaymentForEvent(
  eventId: string,
  data: Partial<CRMPaymentType>,
  paymentType,
): Promise<CRMPaymentType> {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_PAYMENTS.CREATE, {
    ...data,
    eventId,
    paymentType,
  });

  return HttpUtils.post(
    PAYMENTS_URL.EVENT_ROOT(eventId),
    {
      ...data,
    },
    true,
  )
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function editPaymentForEvent(
  id: string,
  eventId: string,
  data: Partial<CRMPaymentType>,
): Promise<CRMPaymentType> {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_PAYMENTS.UPDATE, { ...data, eventId });

  return HttpUtils.patch(
    PAYMENTS_URL.DETAILS(id, eventId),
    {
      ...data,
    },
    true,
  )
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function deletePaymentForEvent(id: string, eventId: string): Promise<void> {
  EventTrackerService.trackEvent(EventTrackerService.TRACK_EVENTS.CRM_PAYMENTS.DELETE, { eventId });

  return HttpUtils.httpDelete(PAYMENTS_URL.DETAILS(id, eventId)).then((response) => response.data);
}

export function paymentAcknowledgement(id: string, eventId: string): Promise<void> {
  return HttpUtils.get(APIURLService.API_URLS.CRM_PAYMENTS.ACKNOWLEDGEMENT(eventId), {
    params: {
      payment_id: id,
    },
  }).then((response) => response.data);
}

// CLIENT PAYMENT CATEGORY
export type CRMPaymentCategoryType = {
  id: number;
  name: string;
  payment_type: PAYMENT_TYPES;
};

// These are created from the backend, we can use it if we need to autofill
export enum CRM_PAYMENT_CATEGORY_NAME {
  ADVANCE = 'Advance',
  FINAL = 'Final',
  FULL = 'Full',
  PARTIAL = 'Partial',
}

export function getCRMPaymentCategoryIdFromName(
  name: CRM_PAYMENT_CATEGORY_NAME,
  categories: Array<CRMPaymentCategoryType>,
): number | undefined {
  const category = categories.find((item) => item.name.match(name));
  return category?.id;
}

export function fetchPaymentsCategory(): Promise<{ results: Array<CRMPaymentCategoryType> }> {
  return HttpUtils.get(PAYMENTS_CATEGORY_URL.LIST, {
    limit: 100,
  }).then((response) => response.data);
}

export function sentPaymentReminderToClient(paymentId: string, eventId: string): Promise<void> {
  return HttpUtils.get(APIURLService.API_URLS.CRM_PAYMENTS.REMINDER_CLIENT(eventId), {
    params: {
      payment_id: paymentId,
    },
  }).then((response) => response.data);
}
