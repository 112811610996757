import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Dialog,
  DIALOG_PLACEMENT,
  DIALOG_SIZES,
  ErrorBoundary,
  Form,
  FormFooter,
  FormGroup,
  FormLabel,
  Input,
  INPUT_SIZES,
  RealCheckbox,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  Tooltip,
} from '@premagic/myne';
import { useRef, useState } from 'react';

type HighlightDesignTitleAndLogoSettingsProps = {
  isFeatureEnabled: boolean;
  isCoverTitleHidden?: boolean;
  isCoverLogoHidden?: boolean;
  coverTitle?: string;
  handleFormSubmit: (data: any) => void;
  isLoading?: boolean;
  hideTitleChangeInput?: boolean;
};

export default function HighlightDesignTitleAndLogoSettings(props: HighlightDesignTitleAndLogoSettingsProps) {
  const {
    isFeatureEnabled,
    isCoverTitleHidden,
    isCoverLogoHidden,
    coverTitle,
    handleFormSubmit,
    isLoading,
    hideTitleChangeInput,
  } = props;

  const [showDialog, setShowDialog] = useState(false);

  const target = useRef(null);
  return (
    <ErrorBoundary>
      <Button onClick={() => setShowDialog(true)} ref={target} size={BUTTON_SIZE.SM} style={BUTTON_STYLES.TERTIARY}>
        More options
      </Button>
      <Space size={2} />
      {showDialog && (
        <Dialog
          target={target.current}
          show={showDialog}
          placement={DIALOG_PLACEMENT.TOP_START}
          onClose={() => setShowDialog(false)}
          size={DIALOG_SIZES.AUTO}
        >
          <Tooltip
            message={!isFeatureEnabled ? 'Signature Album Design Customization is available on Higher plans.' : ''}
          >
            <Space size={1}>
              <Form
                onSubmit={(data) => {
                  handleFormSubmit(data);
                  setShowDialog(false);
                }}
                formSpec={{
                  hide_cover_logo: {
                    type: 'boolean',
                  },
                  hide_cover_title: {
                    type: 'boolean',
                  },
                }}
              >
                <RealCheckbox name="hide_cover_logo" defaultChecked={isCoverLogoHidden} disabled={!isFeatureEnabled}>
                  <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} muted={!isFeatureEnabled}>
                    Hide Company Logo
                  </Text>
                </RealCheckbox>
                <Space size={2} vertical />
                <RealCheckbox name="hide_cover_title" defaultChecked={isCoverTitleHidden} disabled={!isFeatureEnabled}>
                  <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} muted={!isFeatureEnabled}>
                    Hide Project Title
                  </Text>
                </RealCheckbox>
                <Space vertical size={3} />
                {!hideTitleChangeInput && (
                  <FormGroup>
                    <FormLabel>Album Name</FormLabel>
                    <Input
                      name="album_cover_title"
                      autoFocus
                      size={INPUT_SIZES.AUTO}
                      placeholder="Rahul & Riya"
                      defaultValue={coverTitle}
                      disabled={!isFeatureEnabled}
                    />
                  </FormGroup>
                )}
                <FormFooter>
                  <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading} disabled={!isFeatureEnabled}>
                    Save
                  </Button>
                </FormFooter>
              </Form>
            </Space>
          </Tooltip>
        </Dialog>
      )}
    </ErrorBoundary>
  );
}
