import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { accountSelector, saveAccountData } from '../../acccount/AccountDataDuck';
import AccountBasicSettings from './AccountBasicSettings';

const COMMON_KEY = LOADINGS.ACCOUNTS.UPDATE;

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    isSaving: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveAccount: (id, data) => {
      dispatch(saveAccountData(dispatch, id, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountBasicSettings);
