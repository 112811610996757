import { HttpUtils, LocalCacheUtils } from '@premagic/utils';
import Fuse from 'fuse.js';
import { getAPIUrl } from './AppServices';
import { ClientWebsiteImageType, FolderFileType } from './FileService';
import { API_URLS } from './APIURLService';
import { FOLDER_TYPES } from './FolderService';
import { TRACK_EVENTS } from './PixelService';
import { ProjectMetaType } from './entity-meta/ProjectMetaService';
import { ClientSettingsType } from './ClientSettingsService';

export const SystemProject = {
  id: 'SYSTEM',
};

export type NewProjectType = {
  project_name: string;
  event_id?: string;
  event_name?: string;
};

type ProjectClientType = {
  name: string;
  phone_number: string;
  email: string;
  isNew?: boolean;
  skip_notifications?: boolean;
};

export enum PROJECT_META_TOGGLE_ITEMS {
  PROTECT_WITH_PIN = 'protect_with_pin',
  FOLDER_THUMBNAIL_VIEW_ENABLED = 'folder_thumbnail_view_enabled',
}

export type ProjectType = {
  project_name: string;
  project_id: string;
  is_archive: boolean;
  created_at: string;
  folders: Array<string>;
  stats: {
    views: number;
    shares: number;
    refer: number;
  };
  share_url?: string;
  mpin?: number;
  is_clone?: boolean;
  clients: Array<ProjectClientType>;
  event_id?: string;
};

export type ClientProjectItemCoverImageType = ClientWebsiteImageType | FolderFileType;
export type ClientProjectItemType = {
  folder_id: string;
  cover_image?: FolderFileType;
  share_url: string;
  groom_name?: string;
  bride_name?: string;
  schedule?: string;
  tagline?: string;
  images?: Array<FolderFileType>;
  venue?: {
    location?: string;
  };
  expiry_date?: string;
};

export type ClientProjectType = {
  project_name: string;
  project_id: string;
  created_at: string;
  updated_at: string;
  mpin?: number;
  authorized: boolean;
  items?: Record<FOLDER_TYPES, ClientProjectItemType>;
  meta?: ProjectMetaType;
  client_settings: ClientSettingsType;
};

export function getProjectNameFromName(name: string | Array<string>): string {
  if (Array.isArray(name)) {
    // Check if the second name have content
    return name[1].trim().length === 0 ? name[0] : name.join(' & ');
  }

  return name;
}
export function fetchProjects(): Promise<Record<string, ProjectType>> {
  return HttpUtils.get(API_URLS.PROJECTS.LIST).then((response) => response.data);
}
export function fetchProject(projectId: string): Promise<ProjectType> {
  return HttpUtils.get(API_URLS.PROJECTS.DETAIL(projectId)).then((response) => response.data);
}

export function fetchProjectForEvent(eventId: string): Promise<ProjectType> {
  return HttpUtils.get(`${getAPIUrl()}/project/event/${eventId}/`)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function createProject(data: NewProjectType): Promise<ProjectType> {
  return HttpUtils.post(API_URLS.PROJECTS.ROOT, data)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}
export function cloneSelectionFolderToNewProject(projectId): Promise<ProjectType> {
  return HttpUtils.post(API_URLS.PROJECT.CLONE(projectId), {
    serverSideClone: true,
    folderType: FOLDER_TYPES.SELECTION,
  })
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function cloneHighlightFoldersToSelectionFolder(projectId): Promise<{
  newProjectId: string;
  projectId: string;
  success: string;
}> {
  return HttpUtils.post(API_URLS.PROJECT.CLONE(projectId), {
    serverSideClone: true,
    folderType: FOLDER_TYPES.HIGHLIGHT,
  })
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function renameProject(projectId: string, data: { project_name: string }): Promise<ProjectType> {
  return HttpUtils.put(API_URLS.PROJECTS.RENAME(projectId), {
    projectName: data.project_name,
  })
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function archiveProject(projectId: string): Promise<ProjectType> {
  return HttpUtils.put(API_URLS.PROJECTS.ARCHIVE(projectId), {})
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function fetchProjectImages(
  projectId: string,
  folderType: FOLDER_TYPES,
  imagesStatus: 'approved' | 'rejected' | 'all' | 'favorite',
) {
  if (imagesStatus === 'favorite') {
    const URL = API_URLS.FILES.FAVORITE(projectId);
    return HttpUtils.get(URL, {
      params: { folder_type: folderType },
    }).then(({ data }) => data);
  }

  const URL = API_URLS.FILES.FETCH_ALL(projectId, imagesStatus);
  return HttpUtils.get(URL, {
    params: { folder_type: folderType },
  }).then(({ data }) => data);
}

// Same as getShareId()
export function getSelectionAppShareId() {
  // return window.location.href.match(/share\/(.*)\//)[1];
  return window.location.href.split('/')[4];
}

export function fetchProjectImagesFromSelectionApp(
  folderType: FOLDER_TYPES,
  imagesStatus: 'approved' | 'rejected' | 'all',
) {
  const shareId = getSelectionAppShareId();
  return HttpUtils.get(`${getAPIUrl()}/view/${shareId}/${imagesStatus}/images/`, {
    params: { folder_type: folderType },
  }).then(({ data }) => data);
}

export function deleteProjectFile(projectId: string, folderId: string, fileId: string) {
  const URL = API_URLS.FILES.DELETE(projectId, folderId);
  return HttpUtils.post(URL, {
    image_id_list: [fileId],
  });
}
function isClientSelectionApp() {
  return !!window.location.href.match(/share\/(.*)\//);
}
export function fetchAllSelectedPhotosForProject(projectId: string) {
  if (isClientSelectionApp()) {
    return fetchProjectImagesFromSelectionApp(FOLDER_TYPES.SELECTION, 'approved');
  }

  return fetchProjectImages(projectId, FOLDER_TYPES.SELECTION, 'approved');
}

export function resetProjectMPIN(projectId: string): Promise<number> {
  const URL = API_URLS.PROJECTS.MPIN(projectId);
  return HttpUtils.put(URL, { lock: true, renew_pin: true }).then(({ data }) => data.mpin);
}

/* Client App specific  */
export function fetchClientProject(photographerId: string, projectId: string): Promise<ClientProjectType> {
  return HttpUtils.get(API_URLS.PROJECTS_FOR_CLIENT.DETAILS(photographerId, projectId)).then(
    (response) => response.data,
  );
}

export function triggerDownloadClientProjectImages(
  photographerId: string,
  projectId: string,
  folderType: FOLDER_TYPES,
) {
  return HttpUtils.get(API_URLS.PROJECTS_FOR_CLIENT.TRIGGER_DOWNLOAD(photographerId, projectId, folderType)).then(
    (response) => response.data,
  );
}

export function triggerDownloadClientProjectImagesWithShareID(shareId: string) {
  const URL = API_URLS.FOLDER.DOWNLOAD_WITH_SHARE_ID(shareId);

  return HttpUtils.get(URL).then((response) => response.data);
}

export type ProjectDetailedStatsType = Record<
  TRACK_EVENTS,
  {
    [projectId: string]: {
      [folderId: string]: number;
    };
  }
>;

export function fetchProjectStats(projectId: string): Promise<ProjectDetailedStatsType> {
  const URL = API_URLS.PROJECT.STATS_FETCH(projectId);

  return HttpUtils.get(URL).then((response) => response.data);
}

export type ProjectSponsorsStatsType = Record<
  TRACK_EVENTS,
  {
    [projectId: string]: {
      [sponsorsId: string]: {
        [creativeId: string]: number;
      };
    };
  }
>;

export function fetchProjectSponsorsStats(projectId: string): Promise<ProjectSponsorsStatsType> {
  const URL = API_URLS.PROJECT.STATS_SPONSORS_FETCH(projectId);

  return HttpUtils.get(URL).then((response) => response.data);
}

export function fetchProjectItemsByShareId(shareId: string): Promise<ClientProjectType> {
  const URL = API_URLS.PROJECT.FETCH_ITEMS_WITH_SHARE_ID(shareId);

  return HttpUtils.get(URL).then((response) => response.data);
}

export function validateProjectMPINByShareId(shareId: string, MPIN: string) {
  LocalCacheUtils.setCache('MPIN', MPIN);
  const URL = API_URLS.PROJECT.FETCH_ITEMS_WITH_SHARE_ID(shareId);
  return HttpUtils.get(URL).then(({ data }) => data.authorized);
}

let projectSearchFuze;
let projectSearchFuzeIndex;
export function searchProjects(projects: Array<ProjectType>, pattern: string): Array<ProjectType> {
  const options = {
    keys: ['project_name'],
    threshold: 0.3,
  };
  const myIndex = projectSearchFuzeIndex || Fuse.createIndex(options.keys, projects);
  projectSearchFuze = projectSearchFuze || new Fuse(projects, options, myIndex);

  return projectSearchFuze.search(pattern).map((i) => i.item);
}
