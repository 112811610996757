import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import ClientWebsiteOccasionsLayout from './ClientWebsiteOccasionsLayout';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    id: string;
  },
) {
  const { id, projectId } = ownProps;

  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditOfMainOccasionPanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_MAIN_OCCASION_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteOccasionsLayout);
