import { connect } from 'react-redux';
import { isLoadingSelector } from '../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../common/Constants';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../common/ModalDuck';
import ProjectPinNumberReset from './ProjectPinNumberReset';
import { resetProjectPinNumber } from './AccountProjectsDataDuck';

function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    isLoading: isLoadingSelector(LOADINGS.PROJECT.RESET_PIN)(state),
    isDialogOpen: isModalOpenSelector(LOADINGS.PROJECT.SHOW_RESET_PIN_DIALOG)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT.SHOW_RESET_PIN_DIALOG, show));
    },
    resetProjectPinNumber: (projectId: string) => {
      dispatch(resetProjectPinNumber(dispatch, projectId));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.PROJECT.SHOW_RESET_PIN_DIALOG, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPinNumberReset);
