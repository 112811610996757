import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import ProjectArchiveDialog from './ProjectArchiveDialog';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../common/ModalDuck';
import { projectsSelector, updateProject } from '../../../projects/AccountProjectsDataDuck';

const DIALOG_KEY = LOADINGS.PROJECT.SHOW_ARCHIVE_DIALOG;
function mapStateToProps(state, ownProps: { projectId: string }) {
  const { projectId } = ownProps;

  return {
    projectId,
    project: projectsSelector(state)[projectId] || {},
    isPopoverShown: isModalOpenSelector(DIALOG_KEY)(state),
    isLoading: isLoadingSelector(LOADINGS.PROJECT.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    togglePopover: (show) => {
      dispatch(toggleModalVisibility(dispatch, DIALOG_KEY, show));
    },
    archive: (projectId: string) => {
      dispatch(
        updateProject(dispatch, projectId, 'ARCHIVE', {
          data: {
            is_archive: true,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectArchiveDialog);
