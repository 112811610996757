import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../../../common/WindowPanelDuck';
import { getFolderMetaData } from '../../../../../projects/folder-meta/FoldersMetaDataDuck';
import SponsorCreativesWatermarksList from './SponsorCreativesWatermarksList';

function mapStateToProps(state, ownProps: { projectId: string; sponsorFolderId: string; eventId: string }) {
  const { projectId, sponsorFolderId, eventId } = ownProps;
  return {
    projectId,
    sponsorFolderId,
    eventId,
    isUpdating:
      isLoadingSelector(LOADINGS.SPONSOR_CREATIVES.UPDATE)(state) ||
      isLoadingSelector(LOADINGS.SPONSOR_CREATIVES.DELETE)(state) ||
      isLoadingSelector(LOADINGS.SPONSOR_CREATIVES.ADD_WATERMARK)(state),
    isLoadingSponsor: isLoadingSelector(LOADINGS.EVENT_SPONSOR.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showWatermarkPanel: () =>
      dispatch(
        toggleWindowPanelVisibility(dispatch, LOADINGS.SPONSOR_CREATIVES.SHOW_ADD_SPONSOR_WATERMARK_PANEL, true),
      ),
    fetchFolderMeta: (projectId, folderId) => {
      dispatch(getFolderMetaData(dispatch, projectId, folderId, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorCreativesWatermarksList);
