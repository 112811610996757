import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  Color,
  COLOR_SHADES,
  EscapedParagraphHTMLText,
  HTML_ICON,
  Icon,
  ICON_SIZES,
  List,
  ListItem,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_ALIGN,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { SimpleDateUtils } from '@premagic/utils';
import AccountCurrencyContainer from '../../../../acccount/AccountCurrencyContainer';
import { CRMPaymentCategoryType, CRMPaymentType } from '../../CRMPaymentService';

type ClientPaymentItemProps = {
  payment: CRMPaymentType;
  categories: Record<number, CRMPaymentCategoryType>;
};

export function ClientPaymentItem(props: ClientPaymentItemProps) {
  const { payment, categories } = props;
  const {
    is_settled: isSettled,
    human_readable_id: humanId,
    amount,
    id,
    payment_category: categoryId,
    payment_date: paymentDate,
    due_date: dueDate,
  } = payment;
  const category = categories[categoryId];
  const dueDateString = dueDate && SimpleDateUtils.fromNowDate(dueDate, true);
  const dueDateAbsString = dueDate && SimpleDateUtils.humanizeDateSimple(dueDate);
  const paymentDateString = paymentDate && SimpleDateUtils.humanizeDateSimple(paymentDate);

  return (
    <ListItem>
      <Col size={1}>
        <Text block>{humanId}</Text>
      </Col>
      <Col size={3}>
        <Text block>
          {category.name}{' '}
          {isSettled && (
            <Text size={TEXT_SIZE.SIZE_5} muted>
              (Paid)
            </Text>
          )}
        </Text>
      </Col>
      <Col size={5}>
        {isSettled ? (
          <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
            {paymentDateString}
          </Text>
        ) : (
          <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD}>
            {dueDateAbsString}
            <Text color={COLOR_SHADES.DARK}>
              <Space size={2} />
              {HTML_ICON.DASH_SMALL}
              <Space size={2} />
              {dueDateString}
            </Text>
          </Text>
        )}
      </Col>
      <Col rightAlighed size={3}>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} block align={TEXT_ALIGN.RIGHT}>
          <AccountCurrencyContainer amount={amount} />
        </Text>
      </Col>
    </ListItem>
  );
}

type ClientPaymentsInvoiceFooterProps = {
  title: string;
  value: React.ReactNode | number;
  muted?: boolean;
};

function ClientPaymentsInvoiceFooter(props: ClientPaymentsInvoiceFooterProps) {
  const { title, value, muted } = props;

  return (
    <Row vcenter>
      <Col size={4}>
        <Text color={COLOR_SHADES.LIGHT} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_5}>
          {title}
        </Text>
      </Col>
      <Col size={null} rightAlighed>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3} muted={muted}>
          {value}
        </Text>
      </Col>
      <Space />
    </Row>
  );
}

type ClientPaymentsInvoiceNotesProps = {
  showAddPanel: () => void;
  invoiceNote?: string;
  defaultInvoiceNote?: string;
  isLoading: boolean;
};

function ClientPaymentsInvoiceNotes(props: ClientPaymentsInvoiceNotesProps) {
  const { showAddPanel, invoiceNote, isLoading, defaultInvoiceNote } = props;

  const noteToBeDisplayed = invoiceNote || defaultInvoiceNote;

  return (
    <div className={!noteToBeDisplayed ? 'hidden-print' : ''}>
      <Row vcenter>
        <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2}>
          Notes
        </Text>
        <Space />
        {noteToBeDisplayed && (
          <Button style={BUTTON_STYLES.LINK} onClick={showAddPanel} className="hidden-print">
            <Icon name="edit_2" size={ICON_SIZES.SM} />
            <Space size={1} />
            Edit
          </Button>
        )}
      </Row>
      {(() => {
        if (!noteToBeDisplayed)
          return (
            <div>
              <Text muted>You havn't set any notes.</Text>
              <Space vertical size={2} />
              <Button style={BUTTON_STYLES.TERTIARY} size={BUTTON_SIZE.SM} onClick={showAddPanel}>
                Add a note
              </Button>
            </div>
          );
        if (isLoading) return <LoadingDots size="xs" align="left" />;
        return <EscapedParagraphHTMLText value={noteToBeDisplayed} />;
      })()}
      <Space />
    </div>
  );
}

type Props = {
  payments: Array<CRMPaymentType>;
  categories: Record<number, CRMPaymentCategoryType>;
  totalPayments: number;
  totalPending: number;
  totalSettled: number;
  taxName: string;
  taxPercentage: number;
  totalTaxAmount: number;
  showAddPanel: () => void;
  invoiceNote?: string;
  invoiceNoteLoading: boolean;
  defaultInvoiceNote?: string;
  totalTaxableAmount: number;
  totalNonTaxableAmount: number;
};

export default function ClientPaymentsInvoiceContent(props: Props): JSX.Element {
  const {
    payments,
    categories,
    totalPayments,
    totalPending,
    totalSettled,
    taxName,
    taxPercentage,
    totalTaxAmount,
    showAddPanel,
    invoiceNote,
    invoiceNoteLoading,
    defaultInvoiceNote,
    totalTaxableAmount,
    totalNonTaxableAmount,
  } = props;

  const billDateString = SimpleDateUtils.getDateStringFromDate(
    new Date(),
    SimpleDateUtils.STANDARD_DATE_FORMATS.DATE_YEAR,
  );
  return (
    <div>
      <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2} block>
        Payments
      </Text>
      <List>
        <ListItem>
          <Col size={1}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD}>
              #
            </Text>
          </Col>
          <Col size={3}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD}>
              Name
            </Text>
          </Col>
          <Col size={5}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD}>
              Date
            </Text>
          </Col>
          <Col rightAlighed size={3}>
            <Text block muted boldness={TEXT_BOLDNESS.BOLD} align={TEXT_ALIGN.RIGHT}>
              Amount
            </Text>
          </Col>
        </ListItem>
        {payments.length === 0 && (
          <Space>
            <Text muted>There are no payments added</Text>
          </Space>
        )}
        {payments.map((payment) => (
          <ClientPaymentItem key={payment.id} payment={payment} categories={categories} />
        ))}
        <Space vertical size={4} />
        {payments.length > 0 && (
          <Col rightAlighed size={4} screenSMSize={5}>
            <ClientPaymentsInvoiceFooter
              title="Non-Taxable Amount"
              value={<AccountCurrencyContainer amount={totalNonTaxableAmount} />}
              muted
            />
            <ClientPaymentsInvoiceFooter
              title="Taxable Amount"
              value={<AccountCurrencyContainer amount={totalTaxableAmount} />}
              muted
            />
            <ClientPaymentsInvoiceFooter
              title={`${taxName} (${taxPercentage}%)`}
              value={<AccountCurrencyContainer amount={totalTaxAmount} />}
              muted
            />
            <ClientPaymentsInvoiceFooter title="Total" value={<AccountCurrencyContainer amount={totalPayments} />} />
          </Col>
        )}
      </List>
      <ClientPaymentsInvoiceNotes
        showAddPanel={showAddPanel}
        invoiceNote={invoiceNote}
        isLoading={invoiceNoteLoading}
        defaultInvoiceNote={defaultInvoiceNote}
      />
      {totalSettled > 0 && (
        <>
          <Space vertical size={16} />
          <Row center>
            <Space />
            <Color shade={COLOR_SHADES.LIGHT} inline>
              <Icon name="info" size={ICON_SIZES.SM} />
            </Color>
            <Space size={1} />
            <Text color={COLOR_SHADES.DARKER}>The remaining amount to pay is</Text>
            <Space size={1} />
            <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
              <AccountCurrencyContainer amount={totalPending} />
            </Text>
          </Row>
          <Space size={1} vertical />
          <Text center block color={COLOR_SHADES.DARKER}>
            {billDateString}
          </Text>
          <Space vertical size={8} />
        </>
      )}
    </div>
  );
}
