import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import WorkflowPage from './WorkflowPage';
import { createNewWorkflow, fetchWorkflowList, workflowItemsSelector } from './data-duck/WorkflowDataDuck';

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(LOADINGS.CRM_WORKFLOW.FETCH)(state),
    isCreatingWorkflow: isLoadingSelector(LOADINGS.CRM_WORKFLOW.CREATE)(state),
    workflowList: workflowItemsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => {
      dispatch(fetchWorkflowList(dispatch));
    },
    createNewWorkflow: (workflowName: string) => {
      dispatch(createNewWorkflow(dispatch, { name: workflowName }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPage);
