export const commonUISelector = (state: {
  common: {
    alert: unknown;
    loading: unknown;
    fileUploader: unknown;
    modal: unknown;
    windowPanel: unknown;
    errors: unknown;
    device: unknown;
    auth: unknown;
    toast: unknown;
  };
}) => state.common;
