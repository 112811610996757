import { FormLabel, LoadingDots, Row, Select } from '@premagic/myne';
import { useMemo } from 'react';

import { DAM_GALLERY_SORT_BY, DAM_GALLERY_SORT_BY_OPTIONS } from '../DAMLibraryFilterTypes';

type Props = {
  value?: Array<string>;
  onChange: (newFilterValue: Array<string>) => void;
  isLoading: boolean;
};

export default function DAMLibrarySortByFilterSelector(props: Props): JSX.Element {
  const { value, onChange, isLoading } = props;

  const sortByOptions = useMemo(
    () =>
      Object.values(DAM_GALLERY_SORT_BY_OPTIONS)?.map((item) => ({
        value: item.value,
        label: item.label,
      })),
    [],
  );
  const filterValue = !Array.isArray(value) ? value : undefined;
  const selectedOption = sortByOptions?.find((item) => item.value === (filterValue || DAM_GALLERY_SORT_BY.RELEVANCE));

  function handleOnChange(option) {
    if (option) onChange(option.value);
  }

  return (
    <div>
      <FormLabel>Sort By: </FormLabel>
      {isLoading ? (
        <Row center>
          <LoadingDots size="sm" />
        </Row>
      ) : (
        <Select
          options={sortByOptions}
          value={selectedOption}
          name="sort_by"
          onChange={handleOnChange}
          autoFocus
          isMulti={false}
        />
      )}
    </div>
  );
}
