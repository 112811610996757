import { connect } from 'react-redux';
import OccasionsCalendarPage from './OccasionsCalendarPage';
import { navigateToCRMPageWithFilter } from '../../filters/CRMFilterDuck';
import { calendarOccasionsSelector, fetchCalendarOccasionsData } from './OccasionsCalendarDataDuck';
import { isMobileUp } from '../../../AppDuck';
import { MODALS } from '../../../../../../common/Constants';
import { isModalOpenSelector } from '../../../../../../common/ModalDuck';

function mapStateToProps(state, ownProps) {
  const { filters } = ownProps.match.params;
  return {
    occasions: calendarOccasionsSelector(filters)(state),
    filters,
    isMobileUp: isMobileUp(state),
    isTourOpen: isModalOpenSelector(MODALS.TOUR_MODAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (filters) => {
      dispatch(fetchCalendarOccasionsData(dispatch, filters));
    },
    navigate: (filters) => {
      dispatch(navigateToCRMPageWithFilter(dispatch, 'calendar', filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OccasionsCalendarPage);
