import { ClientWebsiteFormService, EventAttendeesService, PremagicResources } from '@premagic/core';
import {
  Col,
  FormGroup,
  FormLabel,
  Input,
  INPUT_SIZES,
  InputPhoneNumber,
  RealCheckbox,
  Row,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
} from '@premagic/myne';
import { StringUtils } from '@premagic/utils';
import React, { useMemo } from 'react';
import UserRegistrationUserFacingFormFieldsContainer from '../../../../client-website/registration-form/user-facing/UserRegistrationUserFacingFormFieldsContainer';
import AttendeeProfilePictureContainer from '../attendee-profile-picture/AttendeeProfilePictureContainer';
import AttendeeRegistrationApprovalButtonsContainer from '../attendee-registration-approval/AttendeeRegistrationApprovalButtonsContainer';
import UserSocialFormContainer from './UserSocialFormContainer';

const typeOptions = Object.entries(EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.name,
}));

function TypeSelect(props: { defaultValue?: EventAttendeesService.EVENT_PEOPLE_TYPE }) {
  const { defaultValue } = props;
  const selectedOption =
    typeOptions.find((option) => option.value === defaultValue) ||
    typeOptions.find((option) => option.value === EventAttendeesService.EVENT_PEOPLE_TYPE.ATTENDEE);

  return (
    <FormGroup>
      <FormLabel>Type</FormLabel>
      <Select size={INPUT_SIZES.SM} options={typeOptions} value={selectedOption} name="people_type" isMulti={false} />
    </FormGroup>
  );
}

function AddOnSelector(props: { defaultValue?: Array<string>; addOns?: Array<string> }) {
  const { defaultValue, addOns = [] } = props;
  const selectedOption = defaultValue?.map((value) => ({
    value,
    label: StringUtils.toTitleCase(value),
  }));
  const options = useMemo(() => addOns?.map((value) => ({ value, label: StringUtils.toTitleCase(value) })), [addOns]);

  return (
    <FormGroup>
      <FormLabel>Addons</FormLabel>
      <Select options={options} value={selectedOption} name="add_ons" isMulti isCreatable />
    </FormGroup>
  );
}

type Props = {
  attendeeId?: string;
  attendee?: EventAttendeesService.EventAttendeeType;
  onChangeOfFile: (file?: File | null) => void;
  projectId?: string;
  attendeeAddOns?: Array<string>;
  attendeeRegistrationSendNotificationRequired?: ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
};

export default function EventAttendeeForm(props: Props) {
  const {
    attendee,
    attendeeId,
    onChangeOfFile,
    projectId,
    attendeeAddOns,
    attendeeRegistrationSendNotificationRequired,
  } = props;
  const [selectedFile, setSelectedFile] = React.useState<File>();

  const isEmailRequired = attendeeRegistrationSendNotificationRequired
    ? [
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.EMAIL,
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      ].includes(attendeeRegistrationSendNotificationRequired)
    : true;

  const isPhoneRequired = attendeeRegistrationSendNotificationRequired
    ? [
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.PHONE,
        ClientWebsiteFormService.EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE.BOTH,
      ].includes(attendeeRegistrationSendNotificationRequired)
    : true;

  return (
    <div>
      {projectId && attendeeId && (
        <Row>
          <Col size={null} rightAlighed>
            <AttendeeRegistrationApprovalButtonsContainer id={attendeeId} projectId={projectId} />
          </Col>
        </Row>
      )}
      <AttendeeProfilePictureContainer
        attendeeId={attendeeId}
        onChangeOfFile={(file) => {
          setSelectedFile(file as File);
          onChangeOfFile(file);
        }}
      />
      <Space vertical />
      {attendee?.external_id && (
        <>
          <Text>
            <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>External ID:</Text> {attendee?.external_id}
          </Text>
          <Space vertical />
        </>
      )}

      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input name="name" autoFocus required placeholder="eg. John Doe" defaultValue={attendee?.name} />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel isRequired={isEmailRequired}>Email</FormLabel>
            <Input name="email" placeholder="eg. john@microsoft.com" defaultValue={attendee?.email} />
          </FormGroup>
        </Col>
        <Space />
        <Col>
          <FormGroup>
            <FormLabel isRequired={isPhoneRequired}>Phone</FormLabel>
            <InputPhoneNumber name="phone" defaultValue={attendee?.phone} />
          </FormGroup>
        </Col>
      </Row>
      <TypeSelect defaultValue={attendee?.people_type} />
      <UserRegistrationUserFacingFormFieldsContainer defaultValue={attendee?.custom_fields} />
      <AddOnSelector addOns={attendeeAddOns} defaultValue={attendee?.add_ons} />
      <UserSocialFormContainer attendeeId={attendeeId} />
      <Space vertical size={8} />
      <RealCheckbox
        name="have_consent_for_networking"
        defaultChecked={attendeeId ? attendee?.have_consent_for_networking : true}
      >
        Join Networking (Connect with others featured in your photo).
      </RealCheckbox>
      <Space vertical size={2} />
      {selectedFile && (
        <RealCheckbox name="permission_to_save_photo" defaultChecked>
          Save the user photos for showing in badge/event micro site
        </RealCheckbox>
      )}
      <Space vertical />
      <Text muted>
        Person has agreed to the{' '}
        <a href={PremagicResources.RESOURCES.terms('attendee-add')} target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        . Learn about how we use and protect your data in our{' '}
        <a href={PremagicResources.RESOURCES.privacy('attendee-add')} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </Text>
      <input type="hidden" name="have_consent_to_use_data" value="true" />
      <Space vertical />
    </div>
  );
}
