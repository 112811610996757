import { MenuItem, MenuItemIcon, Space } from '@premagic/myne';
import React from 'react';

type Props = { showUploadModal: () => void; isFeatureEnabled?: boolean };

export default function UploadEditBulkEventAttendeeMenuItem(props: Props) {
  const { showUploadModal, isFeatureEnabled } = props;

  return (
    <MenuItem onClick={showUploadModal} disabled={!isFeatureEnabled}>
      <MenuItemIcon name="upload" />
      Import/Update List
    </MenuItem>
  );
}
