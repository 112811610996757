import { connect } from 'react-redux';
import { LoadingDuck } from '@premagic/common-ducks';
import { ProductFeedbackService } from '@premagic/core';
import { eventProjectSelectorSelector } from '../../../../projects/AccountProjectsDataDuck';
import { LOADINGS } from '../../../../../../../common/Constants';
import ReportIssuesLayout from './ReportIssuesLayout';
import { getReportIssuesForProject } from '../data-duck/ReportIssueDataDuck';
import { navigateBack } from '../../../../../../../services/RouterService';

function mapStateToProps(
  state,
  ownProps: { match: { params: { eventId: string; reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE } } },
) {
  const { eventId, reportType } = ownProps.match.params;
  const project = eventProjectSelectorSelector(eventId)(state);
  const projectId = project?.project_id;

  return {
    reportType,
    eventId,
    projectId,
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.PRODUCT_FEEDBACK.REPORT_ISSUE.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReportIssues: (projectId, feedbackType) => {
      dispatch(getReportIssuesForProject(dispatch, projectId, feedbackType));
    },
    browserBack: (defaultUrl: string) => {
      navigateBack(dispatch, defaultUrl);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssuesLayout);
