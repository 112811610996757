import React from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  FormResponseType,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProjectMetaService } from '@premagic/core';

type Props = {
  projectId: string;
  projectMeta?: ProjectMetaService.ProjectMetaType;
  updateStatus: (projectId: string, data: FormResponseType) => void;
  isLoading: boolean;
};

export default function AIPhotoDeliveryNotificationInfo(props: Props) {
  const { updateStatus, isLoading, projectMeta, projectId } = props;
  const {
    [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_ENABLE_DELIVERY_NOTIFICATION]:
      aiDeliveryNotificationEnabled = true,
  } = projectMeta || {};

  function handleToggle() {
    updateStatus(projectId, {
      data: {
        [ProjectMetaService.PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_ENABLE_DELIVERY_NOTIFICATION]:
          !aiDeliveryNotificationEnabled,
      },
    });
  }
  if (aiDeliveryNotificationEnabled) {
    return (
      <div>
        <Text block>
          Premagic will <Text boldness={TEXT_BOLDNESS.BOLD}>notify</Text> each of the registered users{' '}
          <Text boldness={TEXT_BOLDNESS.BOLD}>when</Text> their{' '}
          <Text boldness={TEXT_BOLDNESS.BOLD}>first set of photos are found</Text>
        </Text>
        <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={handleToggle} isLoading={isLoading}>
          {aiDeliveryNotificationEnabled ? 'Dont notify?' : 'Notify?'}
        </Button>
        <Space vertical size={1} />
        <Text muted size={TEXT_SIZE.SIZE_5}>
          To reduce spam, Premagic won't send repeated notifications for every new file uploaded.
        </Text>
      </div>
    );
  }
  return (
    <Text>
      Premagic will <Text boldness={TEXT_BOLDNESS.BOLD}>not notify</Text> the registered users.
      <Space />
      <Button style={BUTTON_STYLES.LINK} size={BUTTON_SIZE.SM} onClick={handleToggle} isLoading={isLoading}>
        {aiDeliveryNotificationEnabled ? 'Dont notify?' : 'Notify?'}
      </Button>
      <Space vertical size={10} />
    </Text>
  );
}
