import { Map as IMap } from 'immutable';
import { normalize } from 'normalizr';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils, ErrorTracker } from '@premagic/utils';
import { LOADINGS } from '../../../../../../common/Constants';
import { clearErrorState, setErrorState } from '../../../../../../common/ErrorDuck';
import { toggleLoadingState } from '../../../../../../common/LoadingDuck';
import { TaskSchema, TasksSchema } from '../../../../../schema/Schemas';
import { entitiesDataSelector } from '../../../../reducers/selectors';
import { createTask, fetchTasks, TaskType } from '../service/TaskService';

const getActionType = ActionTypeUtils.getActionTypeFunction('TASK');

export const setTaskData = createAction(getActionType('DATA', 'SET'), (dispatch, data) => data);
export const addTaskData = createAction(getActionType('DATA', 'ADD'), (dispatch, data) => data);

export const createNewTask = createAction(
  getActionType('DATA', 'CREATE'),
  (dispatch, workflowId: number, taskgroupId: number, data: { name: string }) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.CREATE_TASK;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    createTask(workflowId, taskgroupId, data)
      .then((response) => {
        const normalizedData = normalize(response, TaskSchema);
        dispatch(addTaskData(dispatch, normalizedData.entities.task));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Workflow-Task: create new task failed', e);
      });
  },
);

export const fetchTaskItemsData = createAction(
  getActionType('DATA', 'FETCH_ITEMS'),
  (dispatch, workflowId: number, taskgroupId: number) => {
    const loadingKey = LOADINGS.CRM_WORKFLOW.FETCH_TASK;
    dispatch(toggleLoadingState(dispatch, loadingKey, true));
    dispatch(clearErrorState(dispatch, loadingKey));

    fetchTasks(workflowId, taskgroupId)
      .then((response) => {
        const normalizedData = normalize(response.results, TasksSchema);
        dispatch(addTaskData(dispatch, normalizedData.entities.task));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        return response;
      })
      .catch((e) => {
        dispatch(setErrorState(dispatch, loadingKey, e));
        dispatch(toggleLoadingState(dispatch, loadingKey, false));
        ErrorTracker.logError('Task: fetch failed', e);
      });
  },
);

type TaskStateType = {
  items: IMap<string, TaskType>;
};

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setTaskData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: IMap(action.payload),
    }),

    [addTaskData.toString()]: (state, action: { payload }) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
  },
  initialState,
);

const taskDataSelector = createSelector(
  entitiesDataSelector,
  (entities) => entities.crm.workflows.tasks as TaskStateType,
);

export const taskEntitiesSelector = createSelector(taskDataSelector, (state: any) => state.items);

export const taskItemSelector = (taskId: number) =>
  createSelector(taskEntitiesSelector, (items) => items.get(String(taskId)) || {});
