import { EventCustomFromFieldsService } from '@premagic/core';
import { Input, Select, Textarea, MultiSelect, RealCheckbox } from '@premagic/myne';
import React from 'react';

const FORM_INPUT_TYPES_COMPONENTS: Record<EventCustomFromFieldsService.FORM_FIELD_TYPE, React.ElementType> = {
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.TEXT]: Input,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.NUMBER]: Input,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.PARAGRAPH]: Textarea,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.URL]: Input,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.SINGLE_SELECT]: Select,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.MULTI_SELECT]: MultiSelect,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.DATE]: Input,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.EMAIL]: (props) => <Input {...props} type="email" />,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.TELEPHONE]: (props) => <Input {...props} type="tel" />,
  [EventCustomFromFieldsService.FORM_FIELD_TYPE.CHECKBOX]: RealCheckbox,
};

export function getRegistrationComponentForInputType(type: EventCustomFromFieldsService.FORM_FIELD_TYPE) {
  return FORM_INPUT_TYPES_COMPONENTS[type];
}
