import React from 'react';
import { Select } from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';

export enum THEMES_IDS {
  LAGUNA = 1,
  BAIKAL = 2,
  BLED = 3,
  CRATER = 4,
}

export const THEMES = [
  { label: 'Laguna', value: THEMES_IDS.LAGUNA },
  { label: 'Baikal', value: THEMES_IDS.BAIKAL },
  { label: 'Bled', value: THEMES_IDS.BLED },
  { label: 'Crater', value: THEMES_IDS.CRATER },
];

export function getClientWebsiteTheme(id: THEMES_IDS) {
  return THEMES.find((theme) => theme.value == id);
}

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  name: string;
  defaultValue?: any;
};

export function ClientWebsiteThemeSelectInput(props: Props): JSX.Element {
  const { clientWebsite, defaultValue, ...elementProps } = props;
  const selectedValue = getClientWebsiteTheme(defaultValue);
  return <Select {...elementProps} value={selectedValue} options={THEMES} isMulti={false} />;
}

type ClientWebsiteThemeTextGroupValueProps = {
  value: number;
};

export function ClientWebsiteThemeTextGroupValue(props: ClientWebsiteThemeTextGroupValueProps): JSX.Element {
  const { value } = props;
  const themeObj = getClientWebsiteTheme(value);
  return <div>{themeObj?.label}</div>;
}
