import React from 'react';
import { History } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import APP_URLS from '../../../services/AppRouteURLService';
import CRMDashboardPageContainer from './CRMDashboardPageContainer';
import { getDefaultCRMDashboardEventListUrlWithFilters } from '../filters/CRMFilterService';

type Props = {
  history: History;
};
const ROUTES = APP_URLS.CRM_DASHBOARD;

export default function CRMDashboardRoutes(props: Props): JSX.Element {
  const { history } = props;
  return (
    <Router history={history}>
      <Route path={ROUTES.INDEX}>
        <Switch>
          <Route path={ROUTES.DETAILS} component={CRMDashboardPageContainer} />
          <Route>
            <Redirect to={getDefaultCRMDashboardEventListUrlWithFilters()} />
          </Route>
        </Switch>
      </Route>
    </Router>
  );
}
