import { ModalDuck, UploaderDuck } from '@premagic/common-ducks';
import { ClientSettingsService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import {
  highlightPhotosLimitSelector,
  proposalPhotosLimitSelector,
  highlightVideosLimitSelector,
} from '../../../settings/company/CompanyDataDuck';
import { clientSettingsDataSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { projectsSelector } from '../../AccountProjectsDataDuck';
import {
  addFilesToFolder,
  foldersEntitiesSelector,
  totalFilesInFolderSelector,
  totalVideosInProject,
} from '../AccountFoldersDataDuck';
import AccountFolderPhotoDropZone from './AccountFolderPhotoDropZone';

type OwnPropsType = {
  projectId: string;
  folderId: string;
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  const { projectId, folderId } = ownProps;
  const folder = foldersEntitiesSelector(state)[folderId];
  const project = projectsSelector(state)[projectId] || {};
  const { disable_web_upload_photos_limit: disableUploadLimit } = clientSettingsDataSelector(state);
  const clientSettings = clientSettingsDataSelector(state);

  return {
    projectId,
    folderId,
    projectName: project.project_name,
    folder,
    totalFiles: totalFilesInFolderSelector(folderId)(state),
    highlightPhotosLimit: highlightPhotosLimitSelector(state),
    highlightVideosLimit: highlightVideosLimitSelector(state),
    totalVideosInProject: totalVideosInProject(state),
    proposalPhotosLimit: proposalPhotosLimitSelector(state),
    disableUploadLimit,
    isImageCompressorReady: UploaderDuck.isImageCompressorReady(state),
    highlightUploadQuality:
      clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_UPLOAD_QUALITY_WEB],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadFiles: (files, options) => dispatch(addFilesToFolder(dispatch, files, options)),
    showCompressingInfo: (display: boolean) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.FILE.COMPRESS_FILE, display));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFolderPhotoDropZone);
