import { RefObject, useRef, useState } from 'react';
import { useResizeObserver } from './useResizeObserver';

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [RefObject<T>, Size] {
  const $ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  if ($ref) {
    useResizeObserver<HTMLElement>({
      ref: $ref,
      onResize: (options: { width: number; height: number }) =>
        setSize({
          width: options.width,
          height: options.height,
        }),
    });
  }

  return [$ref, size];
}
