import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck } from '@premagic/common-ducks';
import { ProposalDataDuck, ActionConstants } from '@premagic/proposals';
import { FormErrorType } from '@premagic/myne';
import AddProposalTemplate from './AddProposalTemplate';
import { currencyCodeSelector } from '../../acccount/AccountDataDuck';

const COMMON_KEY = ActionConstants.KEYS.PROPOSALS_TEMPLATES.CREATE;
function mapStateToProps(state) {
  return {
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state) as FormErrorType,
    currencyCode: currencyCodeSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createFolder: (name, meta) => {
      dispatch(ProposalDataDuck.addNewProposalTemplate(dispatch, name, meta));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProposalTemplate);
