import { EventAttendeesCheckinZoneService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  LIST_ITEM_ACTION_STYLES,
  ListItem,
  ListItemAction,
  Menu,
  MenuItem,
  MenuItemIcon,
  Space,
  Text,
} from '@premagic/myne';
import React, { useRef, useState } from 'react';
import { ColorUtils } from '@premagic/utils';
import MESSAGES from '../../../../../../../../common/Messages';

type DeleteZoneConfirmationDialogProps = {
  showDeleteDialog: boolean;
  setShowDeleteDialog: (val: boolean) => void;
  isLoading: boolean;
  deleteZone: () => void;
};

const DeleteZoneConfirmationDialog = React.forwardRef((props: DeleteZoneConfirmationDialogProps, ref: any) => {
  const { showDeleteDialog, setShowDeleteDialog, isLoading, deleteZone } = props;

  if (!showDeleteDialog || !ref?.current) return null;

  return (
    <Dialog target={ref.current} show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
      <DialogTitle>Delete this Zone?</DialogTitle>
      <Text>Are you sure you want to delete this Zone?</Text>
      <Space vertical size={6} />
      <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
      <DialogFooter>
        <Button style={BUTTON_STYLES.CANCEL} onClick={() => setShowDeleteDialog(false)}>
          Cancel
        </Button>
        <Button
          style={BUTTON_STYLES.DANGER}
          onClick={() => {
            setShowDeleteDialog(false);
            deleteZone();
          }}
          isLoading={isLoading}
        >
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  );
});

type ZoneColorCircleUIProps = {
  color?: string;
};

function ZoneColorCircleUI(props: ZoneColorCircleUIProps) {
  const { color = 'gray' } = props;

  const size = '12px';
  const cssStyles = {
    width: size,
    height: size,
    backgroundColor: color,
    borderRadius: '50%',
    // boxShadow: '3px 3px 10px 3px #ddd',
  };

  return <div style={cssStyles} />;
}

type Props = {
  checkinZone?: EventAttendeesCheckinZoneService.EventAttendeeCheckinZoneType;
  isDeleting?: boolean;
  deleteZone: (projectId: string, zoneId: string) => void;
  projectId: string;
  zoneId: string;
};

export default function EventAttendeeFaceCheckinZonesListItem(props: Props) {
  const { checkinZone, isDeleting, deleteZone, projectId, zoneId } = props;
  const { name, color } = checkinZone || {};

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const $ref = useRef(null);
  const textColor = ColorUtils.getContrastColor({
    bgColor: color,
  });

  return (
    <>
      <ListItem>
        <ZoneColorCircleUI color={color} />
        <Space size={2} />
        <Text>{name}</Text>
        <ListItemAction style={LIST_ITEM_ACTION_STYLES.PRIMARY_TOP_CORNER}>
          <Dropdown>
            <div ref={$ref}>
              <DropdownIcon title="More" tooltipPlacement="top">
                <Icon name="more_vertical" size={ICON_SIZES.SM} />
              </DropdownIcon>
            </div>

            <Menu>
              {/* <MenuItem
              onClick={() => console.log('edit')}
            >
              <MenuItemIcon name="edit_2" />
              Edit
            </MenuItem>
            <Divider /> */}

              <MenuItem onClick={() => setShowDeleteDialog(true)}>
                <MenuItemIcon name="trash" />
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        </ListItemAction>
      </ListItem>
      <DeleteZoneConfirmationDialog
        ref={$ref}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        isLoading={isDeleting || false}
        deleteZone={() => deleteZone(projectId, zoneId)}
      />
    </>
  );
}
