import { createAction, handleActions } from 'redux-actions';
import { ActionTypeUtils } from '@premagic/utils';
import { omit } from 'lodash';
import { navigateTo } from '../../../../../services/RouterService';
import { CRM_FILTER_KEYS, CRMFiltersType } from './CRMFilterTypes';
import { getCRMUrlWithFilters } from './CRMFilterService';

const getActionType = ActionTypeUtils.getActionTypeFunction('CRM_FILTERS', true);

export const navigateToCRMPageWithFilter = createAction(
  getActionType('NAVIGATE_WITH_FILTER'),
  (dispatch, view: 'list' | 'calendar', filters: CRMFiltersType) => {
    navigateTo(dispatch, getCRMUrlWithFilters(view, filters));
  },
);

export const removeFilterCRMPage = createAction(
  getActionType('REMOVE_FILTER'),
  (dispatch, view: 'list' | 'calendar', filterKeyToRemove: CRM_FILTER_KEYS, filters: CRMFiltersType) => {
    const updateFilters = omit(filters, filterKeyToRemove);
    navigateTo(dispatch, getCRMUrlWithFilters(view, updateFilters));
  },
);

const initialState = {};

export default handleActions({}, initialState);
