import { InnerPage } from '@premagic/myne';
import { Route } from 'react-router-dom';
import APP_URLS from '../../../services/AppRouteURLService';
import CompanyProfileHeaderContainer from '../profile/CompanyProfileHeaderContainer';
import CompanyDomainSettingsPageContainer from './CompanyDomainSettingsPageContainer';
import CompanySettingDetailsPageContainer from './CompanySettingDetailsPageContainer';
import CompanySettingsCustomPolicyContainer from './CompanySettingsCustomPolicyContainer';

type Props = {
  currentUrlPathname: string;
};

export default function CompanySettingPage(props: Props) {
  const { currentUrlPathname } = props;
  const SETTINGS_PROFILE_URLS = APP_URLS.SETTINGS.PROFILE;
  return (
    <InnerPage>
      <CompanyProfileHeaderContainer currentUrlPathname={currentUrlPathname} />
      <Route path={SETTINGS_PROFILE_URLS.DETAILS} component={CompanySettingDetailsPageContainer} />
      <Route path={SETTINGS_PROFILE_URLS.DOMAIN} component={CompanyDomainSettingsPageContainer} />
      <Route path={SETTINGS_PROFILE_URLS.CUSTOM_POLICY} component={CompanySettingsCustomPolicyContainer} />
    </InnerPage>
  );
}
