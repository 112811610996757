import React from 'react';

import { ClientWebsiteService } from '@premagic/core';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  langCode?: string;
};

export default function ClientWebsiteForm(props: Props): JSX.Element {
  const { clientWebsite, langCode } = props;
  return (
    <div>
      <ClientWebsiteFormItem
        itemKey={CLIENT_WEBSITE_FORM_ITEMS.USER_REGISTRATION_FOOT_NOTE}
        clientWebsite={clientWebsite}
        langCode={langCode}
      />
    </div>
  );
}
