import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import {
  deleteFileFromFolder,
  filesNavigationDataForFolderSelector,
  foldersEntitiesSelector,
} from '../AccountFoldersDataDuck';
import FolderImagesPopupSimple from './FolderImagesPopupSimple';

type OwnPropsType = {
  folderId: string;
  projectId: string;
  id: string;
  onNavigate: (id: string) => void;
  onDelete: () => void;
  onSelect?: (id: string) => void;
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  const { projectId, folderId, id, onSelect, onDelete } = ownProps;
  const { previousFileId, nextFileId, totalFiles } = filesNavigationDataForFolderSelector(folderId, id)(state);
  return {
    id,
    projectId,
    folderId,
    folder: foldersEntitiesSelector(state)[folderId] || {},
    nextFileId,
    previousFileId,
    totalFiles,
    isDeleting: isLoadingSelector(LOADINGS.FILE.DELETE)(state),
    onSelect,
    onDelete,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onNavigate: ownProps.onNavigate,
    deleteFile: (projectId, folderId, fileId: string, folderType) => {
      dispatch(
        deleteFileFromFolder(dispatch, {
          projectId,
          folderId,
          fileId,
          folderType,
          navigateToUrl: '',
          onDelete: ownProps.onDelete,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderImagesPopupSimple);
