import { ModalDuck } from '@premagic/common-ducks';
import { FolderMetaService, QRCodeBackgroundPosterService } from '@premagic/core';
import { connect } from 'react-redux';
import {
  editQRCodeDesigns,
  qrCodeDesignsLogoSelector,
  qrCodeDesignsSelector,
  saveFolderMetaData,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';
import QRCodeLogoFiles from './QRCodeLogoFiles';
import { LOADINGS } from '../../../../../../../common/Constants';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    logoId: QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_ID;
  },
) {
  const { projectId, folderId, logoId } = ownProps;

  return {
    projectId,
    folderId,
    logoId,
    logos: qrCodeDesignsLogoSelector(folderId)(state),
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelect: (projectId, folderId, qrCodeDesigns, logo1, logo2) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData: {
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LOGO]: [logo1, logo2],
            [QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.COLOR_EXTRACTION_FROM_LOGO]: true,
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeLogoFiles);
