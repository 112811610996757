import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../company/CompanyDataDuck';
import { clientSettingsDataSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';
import GlobalSignatureGalleryLayout from './GlobalSignatureGalleryLayout';

function mapStateToProps(state) {
  return {
    clientSettings: clientSettingsDataSelector(state),
    hasHighlightCustomizationFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_CUSTOMIZATION,
    )(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSignatureGalleryLayout);
