import { FileService, FontService } from '@premagic/core';
import { MegaFileUploadService } from '@premagic/utils';
import { createContext } from 'react';

export type DeckContextFontsType = {
  primary: FontService.FONT_IDS;
  secondary: FontService.FONT_IDS;
};
export type DeckContextFilesType = Record<string, FileService.FolderFileType>;
export type DeckAdditionalDataType = {
  expiryDate?: string;
  reviewStatus?: string;
  currencyCode?: string;
  packageSlideTotal?: Record<number, number>;
};

type DeckContextType = {
  width: number;
  height: number;
  fonts: DeckContextFontsType;
  variables: Record<string, string | number | any>;
  proposalVariableDetails: Array<any>;
  files: DeckContextFilesType;
  uploadingFiles?: Record<string, MegaFileUploadService.UploaderFileType>;
  toggleVideoPlaying: (isPlaying: boolean) => void;
  changeSlide: (direction: 1 | -1) => void;
  direction: 1 | -1 | 0;
  onButtonActions: (action: string) => void;
  deckAdditionalData?: DeckAdditionalDataType;
  showLeftAndRightSlides?: boolean;
};

const DeckContext = createContext<DeckContextType>({
  width: 366,
  height: 740,
  fonts: {
    primary: 1,
    secondary: 2,
  },
  variables: {},
  proposalVariableDetails: [{}],
  files: {},
  uploadingFiles: {},
  toggleVideoPlaying: () => {},
  changeSlide: () => {},
  direction: 1,
  onButtonActions: () => {},
  showLeftAndRightSlides: false,
});

type DeckTextOnlyContextType = {
  fonts: DeckContextFontsType;
  variables: Record<string, string | number | Array<any>>;
  deckAdditionalData?: DeckAdditionalDataType;
  proposalVariableDetails: Array<any>;
};

export const DeckTextOnlyContext = createContext<DeckTextOnlyContextType>({
  fonts: {
    primary: 1,
    secondary: 2,
  },
  variables: {},
  proposalVariableDetails: [{}],
});

export default DeckContext;
