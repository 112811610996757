import React from 'react';
import { get, isFunction } from 'lodash';
import {
  DatePickerWithTimezoneSelector,
  FormGroup,
  FormLabel,
  getComponentForInputType,
  hasComponentForInputType,
  INPUT_TYPES,
  Text,
  FormResponseType,
} from '@premagic/myne';
import { ClientWebsiteService } from '@premagic/core';
import { SimpleDateUtils } from '@premagic/utils';

import {
  CLIENT_WEBSITE_CUSTOM_INPUT_TYPES,
  CLIENT_WEBSITE_FORM_ITEMS,
  CLIENT_WEBSITE_FORM_ITEMS_DETAILS,
  ClientWebsiteFormItemImageType,
} from './ClientWebsiteFormService';
import { ClientWebsiteThemeSelectInput } from './ClientWebsiteThemeItem';
import { ClientWebsiteThemeStyleSelectInput } from './ClientWebsiteThemeStyleItem';
import { ClientWebsiteClipArtSelectInput } from './ClientWebsiteClipArtItem';
import { ClientWebsiteReligiousClipArtSelectInput } from './ClientWebsiteReligiousClipArtItem';
import ClientWebsitePhotoGridItemContainer from './ClientWebsitePhotoGridItemContainer';
import { BackgroundMusicSelectInput } from '../../background-music/BackgroungMusicSelector';
import FontSelectorContainer from '../../font-selector/FontSelectorContainer';
import ClientWebsitePhotoItemContainer from './ClientWebsitePhotoItemContainer';
import ClientWebsiteMetricsFormItemContainer from './ClientWebsiteMetricsFormItemContainer';

const CLIENT_WEBSITE_CUSTOM_INPUT = {
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.THEME_SELECT]: ClientWebsiteThemeSelectInput,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.THEME_STYLE]: ClientWebsiteThemeStyleSelectInput,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.CLIPART_SELECT]: ClientWebsiteClipArtSelectInput,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.RELIGIOUS_CLIPART_SELECT]: ClientWebsiteReligiousClipArtSelectInput,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.MUSIC_SELECT]: BackgroundMusicSelectInput,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_GRID]: ClientWebsitePhotoGridItemContainer,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.FONT]: FontSelectorContainer,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.PHOTO_ITEM]: ClientWebsitePhotoItemContainer,
  [CLIENT_WEBSITE_CUSTOM_INPUT_TYPES.METRICS]: ClientWebsiteMetricsFormItemContainer,
};

function getClientWebsiteCustomInput(type: CLIENT_WEBSITE_CUSTOM_INPUT_TYPES) {
  return CLIENT_WEBSITE_CUSTOM_INPUT[type];
}

type ClientWebsiteFormItemProps = {
  itemKey: CLIENT_WEBSITE_FORM_ITEMS;
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  imageUploadApiURL?: string;
  disabled?: boolean;
  isLoading?: boolean;
  hideLabel?: boolean;
  langCode?: string;
};

export default function ClientWebsiteFormItem(props: ClientWebsiteFormItemProps): JSX.Element {
  const { itemKey, clientWebsite, disabled, imageUploadApiURL, hideLabel = false, langCode } = props;
  const formItemDetails = CLIENT_WEBSITE_FORM_ITEMS_DETAILS[itemKey];
  const { type: inputType, name, info, required, title } = formItemDetails;
  const { nameUsedToSendToServer } = formItemDetails as ClientWebsiteFormItemImageType;
  const defaultValueProp = get(
    clientWebsite,
    name,
    'defaultValue' in formItemDetails ? formItemDetails.defaultValue : '',
  ) as string;
  const defaultValue = isFunction(defaultValueProp) && langCode ? defaultValueProp(langCode) : defaultValueProp;

  const InputComponent = hasComponentForInputType(inputType)
    ? getComponentForInputType(inputType as INPUT_TYPES)
    : getClientWebsiteCustomInput(inputType as CLIENT_WEBSITE_CUSTOM_INPUT_TYPES);

  if (!InputComponent) {
    return <Text>{formItemDetails.title} = There is no component added</Text>;
  }

  if (inputType === INPUT_TYPES.DATETIME_WITH_TZ) {
    const secondaryInputName = formItemDetails.secondaryInputName as string;
    const timezoneDefaultValue = get(
      clientWebsite,
      secondaryInputName,
      'defaultValue' in formItemDetails ? formItemDetails.defaultValue : '',
    ) as string;
    return (
      <FormGroup>
        <FormLabel info={info} isRequired={required}>
          {title}
        </FormLabel>
        <DatePickerWithTimezoneSelector
          name={name}
          defaultValue={defaultValue}
          nameForTimezoneInput={secondaryInputName}
          timezone={timezoneDefaultValue}
          showTimePicker
          // minDate={SimpleDateUtils.getDateStringFromDate(new Date(), SimpleDateUtils.STANDARD_DATE_FORMATS.ISO)}
        />
      </FormGroup>
    );
  }
  if (inputType === INPUT_TYPES.CHECKBOX) {
    return (
      <FormGroup>
        <InputComponent
          {...formItemDetails}
          {...(!hasComponentForInputType(inputType) && {
            clientWebsite,
          })}
          name={nameUsedToSendToServer || name}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {title}
        </InputComponent>
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      {!hideLabel && (
        <FormLabel info={info} isRequired={required}>
          {title}
        </FormLabel>
      )}
      <InputComponent
        {...formItemDetails}
        {...(!hasComponentForInputType(inputType) && {
          clientWebsite,
        })}
        name={nameUsedToSendToServer || name}
        disabled={disabled}
        // @ts-ignore
        defaultValue={defaultValue}
        imageUploadApiURL={imageUploadApiURL}
        showTextAlignment={inputType === INPUT_TYPES.RICH_TEXTAREA}
      />
    </FormGroup>
  );
}
