import { StorePricingService } from '@premagic/core';
import {
  Col,
  COLOR_SHADES,
  List,
  ListItemHeader,
  PillItem,
  Pills,
  PILLS_STYLES,
  Row,
  ScrollableContainer,
  SCROLLABLE_CONTAINER_SIZE,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Tooltip,
} from '@premagic/myne';
import { CurrencyUtils } from '@premagic/utils';
import React, { useState } from 'react';
import PriceSetterListItemContainer from './PriceSetterListItemContainer';

type CurrencySelectorProps = {
  selectedCurrencyCode?: string;
  setSelectedCurrencyCode: (val) => void;
};

function CurrencySelector(props: CurrencySelectorProps) {
  const { selectedCurrencyCode = 'INR', setSelectedCurrencyCode } = props;

  return (
    <Pills style={PILLS_STYLES.SECONDARY}>
      {Object.values(CurrencyUtils.CURRENCIES)
        .filter((item) => ['INR', 'USD', 'AED'].includes(item.code))
        .map((item) => (
          <PillItem
            key={item.code}
            active={selectedCurrencyCode === item.code}
            onClick={() => setSelectedCurrencyCode(item.code)}
          >
            <Text
              size={TEXT_SIZE.SIZE_5}
              boldness={TEXT_BOLDNESS.SEMI_BOLD}
              color={selectedCurrencyCode === item.code ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
            >
              {item.name}
            </Text>
          </PillItem>
        ))}
    </Pills>
  );
}

type Props = {
  product: StorePricingService.ProductItemType;
  currencyCode?: string;
  defaultSubProductPrices?: StorePricingService.StorePricingType;
};

export default function PriceSetterTable(props: Props) {
  const { product, currencyCode, defaultSubProductPrices } = props;

  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(currencyCode || 'INR');

  return (
    <>
      <CurrencySelector selectedCurrencyCode={selectedCurrencyCode} setSelectedCurrencyCode={setSelectedCurrencyCode} />
      <Space vertical size={3} />
      <List>
        <ListItemHeader>
          <Row>
            <Col size={6}>
              <Text block size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                Products
              </Text>
            </Col>
            <Col size={2}>
              <Text block size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                Cost
              </Text>
            </Col>
            <Col size={2}>
              <Text block size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                Price
              </Text>
            </Col>
            <Col rightAlighed size={null}>
              <Tooltip message="profit = price - cost - Premagic commission" placement="bottom">
                <Text block size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                  Profit
                </Text>
              </Tooltip>
            </Col>
          </Row>
        </ListItemHeader>
        <ScrollableContainer size={SCROLLABLE_CONTAINER_SIZE.MD} direction="vertical" hideScrollBar showShadow>
          {product?.subproducts.map((subproductId) => (
            <PriceSetterListItemContainer
              key={subproductId}
              subproductId={subproductId}
              selectedCurrencyCode={selectedCurrencyCode}
              defaultPrice={defaultSubProductPrices?.[subproductId]}
            />
          ))}
        </ScrollableContainer>
      </List>
      <input type="hidden" name={StorePricingService.STORE_CURRENCY} value={selectedCurrencyCode} />
    </>
  );
}
