import { connect } from 'react-redux';
import { ErrorDuck, LoadingDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import AddCompanyBrandingButton from './AddCompanyBrandingButton';
import { LOADINGS } from '../../../../../common/Constants';
import { addNewSystemBrandingFolder } from '../../projects/sponsor/SponsorAndBrandingDuck';

const COMMON_KEY = LOADINGS.FOLDER.ADD;
function mapStateToProps(state) {
  return {
    isLoading: LoadingDuck.isLoadingSelector(COMMON_KEY)(state),
    errors: ErrorDuck.errorObjectForTypeSelector(COMMON_KEY)(state) as FormErrorType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createFolder: (name) => {
      dispatch(addNewSystemBrandingFolder(dispatch, name));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyBrandingButton);
