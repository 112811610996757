import { PricingPlanFeaturesService, ProjectMetaService } from '@premagic/core';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { fetchCustomEventFromFields } from '../../../event-custom-form-fields/EventCustomFormFieldsDataDuck';
import {
  isFeatureMagazineEnabledSelector,
  projectMetaDataForProjectSelector,
} from '../../../projects/project-meta/ProjectsMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import { getEventPostersForProject } from '../../event-posters/EventPostersDataDuck';
import {
  addEventAttendeeData,
  getEventAttendeesForProject,
  searchedEventAttendeesIdsSelector,
} from './EventAttendeesDataDuck';
import EventAttendeesList from './EventAttendeesList';
import { attendeeSearchTermSelector, setSearchTermForAttendee } from './EventAttendeesPageDuck';

function mapStateToProps(state, ownProps) {
  const { eventId, projectId } = ownProps;
  const projectMeta = projectMetaDataForProjectSelector(projectId)(state);

  return {
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state) ||
      isLoadingSelector(LOADINGS.PROJECT.FETCH)(state) ||
      isLoadingSelector(LOADINGS.INTEGRATION.SYNC_EVENT)(state),
    projectId,
    eventId,
    attendeeIds: searchedEventAttendeesIdsSelector(state),
    searchTerm: attendeeSearchTermSelector(state),
    errors: errorObjectForTypeSelector(LOADINGS.EVENT_ATTENDEES.FETCH)(state),
    hasListWithPIIInformationFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_ATTENDEES_LIST_WITH_PII,
    )(state),
    hasAttendeePoster: !!projectMeta?.[ProjectMetaService.PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED],
    isFeatureMagazineEnabled: isFeatureMagazineEnabledSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (projectId: string) => {
      dispatch(getEventAttendeesForProject(dispatch, projectId));
      dispatch(fetchCustomEventFromFields(dispatch, projectId));
    },
    showAddAttendee: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_ADD_PANEL, true));
    },
    updateSearchTerm: (str) => {
      dispatch(setSearchTermForAttendee(dispatch, str));
    },
    fetchPosters: (projectId: string) => {
      dispatch(getEventPostersForProject(dispatch, projectId));
    },
    addEventAttendeeData: (response) => {
      dispatch(addEventAttendeeData(dispatch, response.id, response));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeesList);
