import { QRCodeBackgroundPosterService } from '@premagic/core';
import { Row } from '@premagic/myne';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import QRCodeColorPicker from '../../common-ui/QRCodeColorPicker';

type Props = {
  projectId: string;
  folderId: string;
  editLEDPosterTextColor: (
    projectId: string,
    folderId: string,
    qrCodeDesigns: QRCodeBackgroundPosterService.QRCodeDesignType,
    textColor: QRCodeBackgroundPosterService.QRCodeLEDTextColorDataType,
  ) => void;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export default function QRCodePosterLEDTextColor(props: Props) {
  const { projectId, folderId, qrCodeDesigns, editLEDPosterTextColor } = props;

  const textColors = qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LED_POSTER_TEXT_COLOR];
  const primaryTextColor = textColors?.primary;
  const secondaryTextColor = textColors?.secondary;

  const debouncedHandleColorsChange = useMemo(() => {
    function handleColorChange(colorType: QRCodeBackgroundPosterService.LED_TEXT_COLOR, colorVal: string) {
      const data = {
        ...textColors,
        [colorType]: colorVal,
      };
      editLEDPosterTextColor(projectId, folderId, qrCodeDesigns || {}, data);
    }
    return debounce(handleColorChange, 500);
  }, [projectId, folderId, qrCodeDesigns, editLEDPosterTextColor, textColors]);

  return (
    <Row spaceAround>
      <QRCodeColorPicker
        name="led_screen_primary_color"
        title="Primary color"
        onChange={(val) => debouncedHandleColorsChange(QRCodeBackgroundPosterService.LED_TEXT_COLOR.PRIMARY, val)}
        defaultValue={primaryTextColor}
      />
      <QRCodeColorPicker
        name="led_screen_secondary_color"
        title="Secondary color"
        onChange={(val) => debouncedHandleColorsChange(QRCodeBackgroundPosterService.LED_TEXT_COLOR.SECONDARY, val)}
        defaultValue={secondaryTextColor}
      />
    </Row>
  );
}
