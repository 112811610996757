import { LoadingDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { damLibraryFilterLabelsByFilterKeySelector, getDAMFilterLabels } from '../library-dam/DAMLibraryDataDuck';
import { DAM_LIBRARY_FILTER_KEYS } from '../library-dam/filters/DAMLibraryFilterTypes';
import ImageTagsSelect from './ImageTagsSelect';

type OwnProps = {
  values?: string[];
  projectId: string;
};

function mapStateToProps(state, ownProps: OwnProps) {
  const { values = [], projectId } = ownProps;
  return {
    projectId,
    values,
    allTags: damLibraryFilterLabelsByFilterKeySelector(DAM_LIBRARY_FILTER_KEYS.TAGS)(state),
    isLoading: LoadingDuck.isLoadingSelector(LOADINGS.DAM_LIBRARY.FETCH_FILTER_LABELS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDAMFilterLabels: (projectId) => {
      dispatch(getDAMFilterLabels(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageTagsSelect);
