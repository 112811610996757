import React, { createContext, useContext } from 'react';
import { useScroll } from '@premagic/utils';
import ClassNames from 'classnames';
import styles from './navs.module.css';
import { NavLink } from '../Link/Link';

export enum NAV_STYLES {
  BOTTOM = 'bottom',
  TOP = 'top',
}

type NavProps = {
  children: React.ReactNode;
  topOffset?: number;
  style: NAV_STYLES;
  fromTheme?: boolean;
};

const NavContext = createContext({ style: NAV_STYLES.BOTTOM });
export function Nav(props: NavProps): JSX.Element {
  const { children, topOffset = 0, style, fromTheme } = props;
  const scrollInfo = useScroll();
  const show = scrollInfo.scrollY >= topOffset;
  return (
    <div className={ClassNames(styles['nav-wrapper'], 'hidden-print')}>
      <div
        className={ClassNames(styles.nav, styles[`nav--style-${style}`], {
          [styles['nav--show']]: show,
          [styles['nav--animation']]: topOffset > 0,
          [styles['from-theme']]: fromTheme,
        })}
      >
        <NavContext.Provider
          value={{
            style,
          }}
        >
          {children}
        </NavContext.Provider>
      </div>
    </div>
  );
}

export interface NavItemProps {
  children: React.ReactNode;
  to?: string;
  muted?: boolean;
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
  fromTheme?: boolean;
  disabled?: boolean;
}

export const NavItem = React.forwardRef((props: NavItemProps, ref: any) => {
  const {
    children,
    muted = false,
    to = '',
    className = '',
    onClick,
    isActive = false,
    fromTheme = false,
    disabled = false,
    ...elementProps
  } = props;

  const { style: navStyle } = useContext(NavContext);
  const classes = ClassNames(className, styles['nav-item'], styles[`nav-item--style-${navStyle}`], {
    [styles['nav-item--muted']]: muted,
    [styles['nav-item--active']]: isActive,
    [styles['from-theme']]: fromTheme,
  });

  if (to) {
    return (
      <NavLink
        to={to}
        className={classes}
        onClick={onClick}
        activeClassName={styles['nav-item--active']}
        disabled={disabled}
        {...elementProps}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <button className={classes} type="button" onClick={onClick} disabled={disabled} {...elementProps} ref={ref}>
      {children}
    </button>
  );
});

type NavItemIconProps = {
  children: React.ReactNode;
};

export function NavItemIcon(props: NavItemIconProps): JSX.Element {
  const { children } = props;
  return <div className={styles['nav-item__icon']}>{children}</div>;
}
