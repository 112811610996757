import React from 'react';

import ReactDOM from 'react-dom';
import ClassNames from 'classnames';

import { ButtonIcon } from '../Button/Buttons';
import { COLOR_SHADES } from '../Color/Color';
import { ErrorBoundary } from '../Error/Error';
import { Icon } from '../Icon/Icons';

import styles from './windowpanel.module.css';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';

const WINDOW_PANEL_MOUNT = 'window-panel-root';

export enum WINDOW_PANEL_SIZES {
  MD = 'md',
  LG = 'lg',
}

interface WindowPanelProps {
  children: React.ReactNode;
  show: boolean;
  size?: WINDOW_PANEL_SIZES;
}

export function WindowPanel(props: WindowPanelProps): JSX.Element | null {
  const { show, children, size = WINDOW_PANEL_SIZES.MD } = props;

  if (!show) return null;

  const panel = (
    <ErrorBoundary>
      <div className={ClassNames(styles['window-panel'], styles[`window-panel--size-${size}`])}>{children}</div>
    </ErrorBoundary>
  );
  const $target = document.getElementById(WINDOW_PANEL_MOUNT);
  if (!$target) return null;
  return ReactDOM.createPortal(
    <>
      <div className={ClassNames(styles['window-panel__backdrop'])} />
      {panel}
    </>,
    $target,
  );
}

interface WindowPanelHeaderProps {
  children: React.ReactNode;
  onClose?: () => void;
}

export function WindowPanelHeader(props: WindowPanelHeaderProps) {
  const { children, onClose } = props;

  return (
    <div className={ClassNames(styles['window-panel__header'])}>
      {children}
      {onClose && (
        <div className={ClassNames(styles['window-panel__header__actions'])}>
          <ButtonIcon title="Close" onClick={onClose} tooltipPlacement="left">
            <Icon name="x" />
          </ButtonIcon>
        </div>
      )}
    </div>
  );
}

interface WindowPanelTitleProps {
  children: React.ReactNode;
}

export function WindowPanelTitle(props: WindowPanelTitleProps) {
  const { children } = props;
  return (
    <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
      {children}
    </Text>
  );
}

type WindowPanelContentProps = {
  children: React.ReactNode;
};

export function WindowPanelContent(props: WindowPanelContentProps) {
  const { children } = props;
  return <div className={ClassNames(styles['window-panel__content'], 'js-window-panel-content')}>{children}</div>;
}
