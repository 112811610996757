import { createSelector } from 'reselect';

const pagesSelector = (state) =>
  state.pages as {
    albumCreator: {
      data: {
        images: unknown;
        album: unknown;
        pages: unknown;
        project: unknown;
      };
      creator: unknown;
    };
  };

export const albumCreatorPageSelector = createSelector(pagesSelector, (pages) => pages.albumCreator);
