import { connect } from 'react-redux';
import { LoadingDuck, WindowPanelDuck } from '@premagic/common-ducks';
import { ProjectService } from '@premagic/core';
import { ActionConstants, ProposalDeckDataDuck, ProposalVariablesDataDuck } from '@premagic/proposals';

import ProposalCreatorLayout from './ProposalCreatorLayout';
import { initProposalCreator, setActiveSlide } from './ProposalCreatorDuck';

function mapStateToProps(state, ownProps) {
  const { projectId = ProjectService.SystemProject.id, folderId, deckId, slideId } = ownProps.match.params;

  return {
    projectId,
    folderId,
    deckId,
    slideId: Number(slideId),
    slideIds: ProposalDeckDataDuck.proposalsSlideIdsForDeckSelectors(deckId)(state),
    isLoading: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSALS.INIT)(state),
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    proposalVariableDetails: ProposalVariablesDataDuck.proposalsVariableDetailsSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initData: (options) => dispatch(initProposalCreator(dispatch, options)),
    setActiveSlide: (id: number) => {
      dispatch(setActiveSlide(dispatch, id));
    },
    updateProposal: (projectId, deckId, data) => {
      dispatch(ProposalDeckDataDuck.saveProposalDeck(dispatch, projectId, deckId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorLayout);
