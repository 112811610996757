import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ActionTypeUtils } from '@premagic/utils';
import { albumCreatorPageSelector } from '../../common/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('ALBUM', true);

export const setActivePage = createAction(getActionType('PAGE', 'SET'), (dispatch, data: number) => data);

export const setActiveAlbum = createAction(getActionType('ALBUM', 'SET'), (dispatch, albumId: string) => albumId);

type StateType = {
  selectedPage: number;
  selectedAlbum: string;
};

const initialState = {
  selectedPage: 1,
  selectedAlbum: '',
};

export default handleActions<StateType>(
  {
    [setActivePage.toString()]: (state, action: { payload }) => ({
      ...state,
      selectedPage: action.payload,
    }),
    [setActiveAlbum.toString()]: (state, action: { payload }) => ({
      ...state,
      selectedAlbum: action.payload,
    }),
  },
  initialState,
);

export const albumCreatorSelector = createSelector(
  albumCreatorPageSelector,
  (albumCreator) => albumCreator.creator as StateType,
);

export const albumCreatorSelectedPageSelector = createSelector(albumCreatorSelector, (state) => state.selectedPage);

export const albumCreatorSelectedAlbumIdSelector = createSelector(albumCreatorSelector, (state) => state.selectedAlbum);
