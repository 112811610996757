import { EnvUtils, ErrorTracker, HttpUtils } from '@premagic/utils';
import { API_URLS } from './APIURLService';
import { RESOURCES } from './PremagicResources';
import { FOLDER_TYPES } from './FolderService';

// https://razorpay.com/docs/payment-gateway/web-integration/standard/
declare const Razorpay;

export function showPaymentModal(data: {
  orderId: string;
  amount: number;
  description: string;
  client?: {
    name?: string;
    email?: string;
    phone?: string;
  };
  company: {
    name: string;
    logo?: string;
  };
  color?: string;
}): Promise<{
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}> {
  // https://razorpay.com/docs/api/subscriptions/#authentication-transaction
  const { orderId, amount, description, client = {}, company, color = '#047af0' } = data;
  return new Promise((resolve, reject) => {
    const options = {
      key: EnvUtils.isProd() ? 'rzp_live_aWvkMLvBcmrM2a' : 'rzp_test_BslS4GcrqEH41J',
      name: company.name,
      amount,
      currency: 'INR',
      image: company.logo || RESOURCES.publicLogo,
      order_id: orderId,
      description,
      handler: (response) => {
        if (response?.error?.code) {
          reject(response);
        } else {
          resolve(response);
        }
      },
      modal: {
        ondismiss() {
          reject(new Error('Checkout form closed'));
        },
      },
      prefill: {
        name: client.name,
        email: client.email,
        phone: client.phone,
      },
      notes: {},
      theme: {
        color,
      },
    };

    const rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', (response) => {
      reject(new Error('Failed'));
      ErrorTracker.logError('PAYMENT_FAILED', response.error.reason);
    });

    rzp1.open();
  });
}

export enum PUBLIC_PRODUCTS {
  DIGITAL_INVITE = 'DIGITAL_INVITE',
  HIGHLIGHT = 'HIGHLIGHT',
  SELECTION = 'SELECTION',
  SINGLE_IMAGE_DOWNLOAD = 'SINGLE_IMAGE_DOWNLOAD',
  GUEST_GALLERY_DOWNLOADS = 'GUEST_GALLERY_DOWNLOADS_1',
  BULK_IMAGE_DOWNLOADS = 'BULK_IMAGE_DOWNLOADS_1',
}

export const PUBLIC_PRODUCT_FOLDER_MAP: Partial<Record<FOLDER_TYPES, PUBLIC_PRODUCTS>> = {
  [FOLDER_TYPES.HIGHLIGHT]: PUBLIC_PRODUCTS.HIGHLIGHT,
  [FOLDER_TYPES.SELECTION]: PUBLIC_PRODUCTS.SELECTION,
  [FOLDER_TYPES.CLIENT_WEBSITE]: PUBLIC_PRODUCTS.DIGITAL_INVITE,
};

export enum PUBLIC_PRODUCT_HIGHLIGHT_VARIANTS {
  ONE_YEAR = 'ONE_YEAR',
  FIVE_YEAR = 'FIVE_YEAR',
}

export enum PUBLIC_PRODUCT_SELECTION_VARIANTS {
  ONE_YEAR = 'ONE_YEAR',
  FIVE_YEAR = 'FIVE_YEAR',
}

// Duplicate in ProjectOrderService | we need to remove from ProjectOrderService
export enum PUBLIC_PRODUCT_DIGITAL_INVITE_VARIANTS {
  TRIAL = 'TRIAL',
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

type PublicProductBase = {
  features: Array<string>;
  feature_limits: Record<string, number>;
  name: PUBLIC_PRODUCTS;
  price: number;
  expiry_month: number;
  active: boolean;
};

type PublicProductHighlight = PublicProductBase & {
  name: PUBLIC_PRODUCTS.HIGHLIGHT;
  variants: PUBLIC_PRODUCT_HIGHLIGHT_VARIANTS;
};
type PublicProductSelection = PublicProductBase & {
  name: PUBLIC_PRODUCTS.SELECTION;
  variants: PUBLIC_PRODUCT_SELECTION_VARIANTS;
};
type PublicProductDigitalInvite = PublicProductBase & {
  name: PUBLIC_PRODUCTS.DIGITAL_INVITE;
  variants: PUBLIC_PRODUCT_DIGITAL_INVITE_VARIANTS;
};

export type PublicProductsType = PublicProductHighlight | PublicProductSelection | PublicProductDigitalInvite;

export function fetchProducts(shareId: string): Promise<Record<string, PublicProductsType>> {
  return HttpUtils.get(API_URLS.PUBLIC_PAYMENT.PRODUCTS(shareId)).then(({ data }) => data.skus);
}

export function calculateOrderValue(options: {
  sku: string;
  projectId: string;
  folderId?: string;
  shareId: string;
  fileIdsToDownload: Array<string>;
  client: {
    name?: string;
    email: string;
    phone?: string;
  };
}): Promise<{ success: boolean; amount: number }> {
  const { sku, projectId, folderId, shareId, fileIdsToDownload, client } = options;
  const { email } = client;
  return HttpUtils.post(API_URLS.PUBLIC_PAYMENT.CALCULATE_ORDER_VALUE(shareId), {
    sku,
    folder_id: folderId, // optional
    project_id: projectId,
    email,
    asset_ids: fileIdsToDownload,
  }).then(({ data }) => data);
}

export function createOrder(options: {
  sku: string;
  projectId: string;
  folderId?: string;
  shareId: string;
  shareUrl?: string;
  email?: string;
  assetIds?: Array<string>;
  client?: {
    name?: string;
    email?: string;
  };
}): Promise<{
  success: boolean;
  order_id: string;
}> {
  const { sku, projectId, folderId, shareId, client = {}, shareUrl, email, assetIds } = options;
  return HttpUtils.post(API_URLS.PUBLIC_PAYMENT.ORDER(shareId), {
    sku,
    folder_id: folderId,
    project_id: projectId,
    share_url: shareUrl,
    asset_ids: assetIds,
    email,
    ...client,
  }).then(({ data }) => data);
}

export function capturePayment(options: {
  shareId: string;
  shareUrl?: string;
  order_id: string;
  client?: {
    name?: string;
    email?: string;
  };
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  amount: number;
  sku: string;
  project_id: string;
  folder_id?: string;
  asset_ids?: Array<string>;
  face_id?: string;
}): Promise<{
  success: boolean;
  client: string;
}> {
  const { shareId, shareUrl } = options;
  return HttpUtils.post(API_URLS.PUBLIC_PAYMENT.CAPTURE_PAYMENT(shareId), {
    ...options,
    amount: options.amount * 100,
    email: options.client?.email,
    share_url: shareUrl,
  }).then(({ data }) => data);
}
