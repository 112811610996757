import { connect } from 'react-redux';
import { ModalDuck } from '@premagic/common-ducks';
import { FolderService, QRCodeBackgroundPosterService } from '@premagic/core';
import { LOADINGS } from '../../../../../../../common/Constants';
import { addFilesToFolder, getFolderImagesAndOrder } from '../../../../projects/folders/AccountFoldersDataDuck';
import QRCodeLogoImageEditor from './QRCodeLogoImageEditor';
import { filesSelector } from '../../../../images/FilesDataDuck';
import {
  editQRCodeDesigns,
  qrCodeDesignsLogoSelector,
  qrCodeDesignsSelector,
} from '../../../../projects/folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps) {
  const { logoId, folderId, projectId } = ownProps;

  let logoFileData;

  if (logoId) {
    const filesData = filesSelector(state);
    const logos = qrCodeDesignsLogoSelector(folderId)(state);
    const indexForLogo = QRCodeBackgroundPosterService.QR_CODE_DESIGN_LOGO_DETAILS[logoId].positionIndex;

    if (logos) {
      logoFileData = filesData[logos[indexForLogo] as string];
    }
  }
  return {
    logoFileData,
    logoId,
    folderId,
    projectId,
    qrCodeDesigns: qrCodeDesignsSelector(folderId)(state),
    logos: qrCodeDesignsLogoSelector(folderId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLogoData: (projectId, folderId, logoId, qrCodeDesigns, newDesignData) => {
      dispatch(
        editQRCodeDesigns(dispatch, projectId, folderId, {
          previousDesignData: qrCodeDesigns,
          newDesignData,
        }),
      );
    },
    closeModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, LOADINGS.QR_CODE.SELECT_LOGO_MODAL, false));
      dispatch(
        ModalDuck.setModalOptions(dispatch, LOADINGS.QR_CODE.SELECT_LOGO_MODAL, {
          logoId: '',
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeLogoImageEditor);
