import { connect } from 'react-redux';
import AccountFinancialsPaymentsTablePagination from './AccountFinancialsPaymentsTablePagination';
import { ACCOUNT_FINANCIALS_TYPES } from '../AccountFinancialsService';
import { accountFinancialsPaymentItemsCountSelector } from '../AccountFinancialsPaymentsDataDuck';
import { navigateToAccountFinancialsPageWithFilter } from '../filters/AccountFinancialsFilterDuck';

function mapStateToProps(state, ownProps: { filters: string; type: ACCOUNT_FINANCIALS_TYPES }) {
  const { filters, type } = ownProps;

  return {
    totalItems: accountFinancialsPaymentItemsCountSelector(state),
    filters,
    type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (type, filters) => {
      dispatch(navigateToAccountFinancialsPageWithFilter(dispatch, type, filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinancialsPaymentsTablePagination);
