import { toDataURL, create, toString } from 'qrcode';
import { qrRender } from './services/qrRender';

// https://github.com/soldair/node-qrcode#qr-code-options
const QR_CREATE_OPTIONS = {
  errorCorrectionLevel: 'H',
  // margin: 16,
  // scale: 4,
};

const CUSTOM_RENDER_OPTIONS = (width: number) => ({
  // color: {
  //   dark: '#191a19',
  //   light: '#ffffff',
  // },
  width,
  color: 'colored',
  logo: 'fillstuff',
});

export function getCodeInSVG(url: string, width: number): Promise<string> {
  return new Promise((resolve) => {
    qrRender(create(url, QR_CREATE_OPTIONS), CUSTOM_RENDER_OPTIONS(width), (_, svg) => resolve(svg));
  });
}
