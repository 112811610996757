import React, { useRef } from 'react';
import {
  Button,
  BUTTON_ICONS_SIZES,
  BUTTON_STYLES,
  COLOR_SHADES,
  Dialog,
  DialogFooter,
  DialogTitle,
  DIALOG_PLACEMENT,
  Dropdown,
  DropdownIcon,
  Icon,
  ICON_SIZES,
  Menu,
  MenuItem,
  MenuItemIcon,
  Space,
  Text,
  TEXT_SIZE,
} from '@premagic/myne';
import MESSAGES from '../../../../common/Messages';

type ProjectPinNumberResetDialogProps = {
  projectId: string;
  toggleDialog: (show: boolean) => void;
  isDialogOpen: boolean;
  resetProjectPinNumber: (projectId: string) => void;
};

function ProjectPinNumberResetDialog(props: ProjectPinNumberResetDialogProps): React.ReactElement {
  const { projectId, toggleDialog, isDialogOpen, resetProjectPinNumber } = props;
  const target = useRef(null);

  return (
    <span ref={target}>
      <Dialog
        target={target.current}
        show={isDialogOpen}
        placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
        onClose={() => toggleDialog(false)}
      >
        <DialogTitle>Reset the project PIN?</DialogTitle>
        <Text>Resetting the PIN will create a new PIN for this Project. Would you like to reset it?</Text>
        <Space vertical size={6} />
        <Text color={COLOR_SHADES.DANGER}>{MESSAGES.DELETE_WARNING}</Text>
        <DialogFooter>
          <Button style={BUTTON_STYLES.CANCEL} onClick={() => toggleDialog(false)}>
            Cancel
          </Button>
          <Button style={BUTTON_STYLES.PRIMARY} onClick={() => resetProjectPinNumber(projectId)}>
            Reset PIN
          </Button>
        </DialogFooter>
        <Space vertical size={1} />
        <Text size={TEXT_SIZE.SIZE_5} muted>
          Note: You will need to share the new PIN with your client.
        </Text>
      </Dialog>
    </span>
  );
}

type ProjectPinNumberResetProps = {
  projectId: string;
  isDialogOpen: boolean;
  toggleDialog: (show: boolean) => void;
  isLoading: boolean;
  resetProjectPinNumber: (projectId: string) => void;
};

export default function ProjectPinNumberReset(props: ProjectPinNumberResetProps) {
  const { projectId, isDialogOpen, toggleDialog, isLoading, resetProjectPinNumber } = props;
  return (
    <>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="bottom" size={BUTTON_ICONS_SIZES.SM} isLoading={isLoading}>
          <Icon name="edit_2" size={ICON_SIZES.SM} />
        </DropdownIcon>
        <Menu>
          <MenuItem onClick={() => toggleDialog(true)}>
            <MenuItemIcon name="refresh_ccw" />
            Reset PIN
          </MenuItem>
        </Menu>
      </Dropdown>
      <ProjectPinNumberResetDialog
        projectId={projectId}
        toggleDialog={toggleDialog}
        isDialogOpen={isDialogOpen}
        resetProjectPinNumber={resetProjectPinNumber}
      />
    </>
  );
}
