import { FormGroup, FormLabel, INPUT_SIZES, Select, Space } from '@premagic/myne';
import {
  EventAttendeeBadgeDesignType,
  WIDGET_TYPE,
  WidgetDataType,
} from '../../../service/EventAttendeeBadgeDesignService';

type Props = {
  projectId: string;
  folderId: string;
  selectedWidgetForEdit?: WIDGET_TYPE;
  designData?: EventAttendeeBadgeDesignType;
  editFont: (
    projectId: string,
    folderId: string,
    previousDesign?: EventAttendeeBadgeDesignType,
    widgetId?: string,
    weight?: string,
  ) => void;
  posterWidgetDesign?: Record<WIDGET_TYPE, WidgetDataType>;
};

const fontCaseOption = [
  {
    value: 'uppercase',
    label: 'AA',
  },
  {
    value: 'lowercase',
    label: 'aa',
  },
  {
    value: 'capitalize',
    label: 'Aa',
  },
  {
    value: 'none',
    label: 'Normal',
  },
];
export default function EventAttendeeBadgeFontCaseSelect(props: Props) {
  const { selectedWidgetForEdit, designData, editFont, projectId, folderId, posterWidgetDesign } = props;

  function handleFontSelect(option) {
    const { value } = option;
    editFont(projectId, folderId, designData, selectedWidgetForEdit, value);
  }
  const defaultOption =
    selectedWidgetForEdit &&
    fontCaseOption.find((option) => option.value === (posterWidgetDesign?.[selectedWidgetForEdit]?.fontCase || 'none'));
  return (
    <FormGroup>
      <FormLabel>Font Case</FormLabel>
      <Select
        options={fontCaseOption}
        name="fontCase"
        size={INPUT_SIZES.SM}
        isMulti={false}
        onChange={handleFontSelect}
        value={defaultOption}
      />
    </FormGroup>
  );
}
