import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { qrCodeDesignsSponsorSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';
import { hasFeatureSelector } from '../../../settings/company/CompanyDataDuck';
import QRCodeSponsorLogosWithAlignment from './QRCodeSponsorLogosWithAlignment';

function mapStateToProps(
  state,
  ownProps: {
    folderId: string;
    logoSize: 'sm' | 'md';
    displayVertical?: boolean;
    title?: string;
    titleColor?: string;
  },
) {
  const { folderId, logoSize, displayVertical, title, titleColor } = ownProps;

  return {
    logoSize,
    displayVertical,
    sponsorLogoData: qrCodeDesignsSponsorSelector(folderId)(state),
    title,
    hasEventSponsorshipFeatureEnabled: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_SPONSORSHIP,
    )(state),
    titleColor,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeSponsorLogosWithAlignment);
