import { useEffect } from 'react';
import { Space, WindowBox, WindowBoxContent, WindowBoxFooter, WindowBoxHeader } from '@premagic/myne';
import { BrowserUtils, StringUtils } from '@premagic/utils';
import MegaUploaderFilesContainer from './MegaUploaderItemsContainer';
import MegaUploaderStats from './MegaUploaderStats';
import MESSAGES from '../../../../common/Messages';

type Props = {
  isUploading: boolean;
  totalUploadingFiles: number;
  totalUploadedFiles: number;
  clearAll: () => void;
};

export default function MegaUploader(props: Props): JSX.Element | null {
  const { isUploading, totalUploadingFiles, totalUploadedFiles, clearAll } = props;
  const show = totalUploadingFiles || totalUploadedFiles;

  function onUploaderClose(e): void {
    BrowserUtils.beforeTabClosePreventTabClose(e, MESSAGES.UPLOAD.LOSE);
  }

  useEffect(() => {
    // Clear old bindings
    window.removeEventListener('beforeunload', onUploaderClose);

    if (isUploading) window.addEventListener('beforeunload', onUploaderClose);
    return () => {
      window.removeEventListener('beforeunload', onUploaderClose);
    };
  }, [isUploading]);

  if (!show) return null;
  const title = isUploading
    ? `Uploading ${totalUploadingFiles} ${StringUtils.pluralize('file', totalUploadingFiles)}...`
    : `Uploaded ${totalUploadedFiles} ${StringUtils.pluralize('file', totalUploadedFiles)}`;
  const onClose = isUploading ? undefined : clearAll;

  return (
    <WindowBox>
      <WindowBoxHeader onClose={onClose}>{title}</WindowBoxHeader>
      <WindowBoxContent>
        <MegaUploaderFilesContainer />
      </WindowBoxContent>
      <WindowBoxFooter>
        <Space size={1}>
          <MegaUploaderStats />
        </Space>
      </WindowBoxFooter>
    </WindowBox>
  );
}
