import React from 'react';

import { ClientWebsiteService } from '@premagic/core';
import { CLIENT_WEBSITE_FORM_ITEMS } from '../form-items/ClientWebsiteFormService';
import ClientWebsiteFormItem from '../form-items/ClientWebsiteFormItem';

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  langCode?: string;
};

export default function ClientWebsiteForm(props: Props): JSX.Element {
  const { clientWebsite, langCode } = props;
  return (
    <div>
      <ClientWebsiteFormItem
        itemKey={CLIENT_WEBSITE_FORM_ITEMS.PHOTO_REGISTRATION_TITLE}
        clientWebsite={clientWebsite}
        langCode={langCode}
      />
      <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.DETAILED_TAGLINE} clientWebsite={clientWebsite} />
      <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_TAGLINE} clientWebsite={clientWebsite} />
      <ClientWebsiteFormItem
        itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_TRAILER_VIDEO_LINK}
        clientWebsite={clientWebsite}
      />
      <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_VIDEO_LINK} clientWebsite={clientWebsite} />
      <ClientWebsiteFormItem itemKey={CLIENT_WEBSITE_FORM_ITEMS.EVENT_GALLERY_LINK} clientWebsite={clientWebsite} />
    </div>
  );
}
