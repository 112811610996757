import { connect } from 'react-redux';
import CompanyBrandingHeader from './CompanyBrandingHeader';

function mapStateToProps(state, ownProps: { currentUrlPathname: string }) {
  const { currentUrlPathname } = ownProps;

  return {
    currentUrlPathname,
  };
}

export default connect(mapStateToProps)(CompanyBrandingHeader);
