import { useState } from 'react';
import { MegaFileUploadService, NumberUtils, SimpleDateUtils, useAnimationFrame } from '@premagic/utils';
import { Row, Space, Text, TEXT_BOLDNESS, TEXT_SIZE } from '@premagic/myne';

export default function MegaUploaderStats(): JSX.Element {
  const [stats, setStats] = useState(MegaFileUploadService.getUploaderStats());

  useAnimationFrame({
    nextAnimationFrameHandler: () => {
      setStats(MegaFileUploadService.getUploaderStats());
    },
  });
  const { totalSizeUploaded, totalSize, progress, isUploading, timeRemaining } = stats;

  if (progress === 1) {
    return (
      <Row>
        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
          {NumberUtils.getNumberInBytesFormat(totalSize)}
        </Text>
        <Space size={1} />
        <Text size={TEXT_SIZE.SIZE_5}>Uploaded</Text>
      </Row>
    );
  }

  const timeRemainingInWords = SimpleDateUtils.humanizeDuration(SimpleDateUtils.getTimePartsFromSeconds(timeRemaining));

  return (
    <Row>
      {isUploading ? (
        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
          {Number.isFinite(timeRemaining) ? timeRemainingInWords : 'Calculating...'}
        </Text>
      ) : (
        <Text size={TEXT_SIZE.SIZE_5}>Waiting...</Text>
      )}
      <Space size={1} />
      <Text size={TEXT_SIZE.SIZE_5}>
        (
        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
          {NumberUtils.getNumberInBytesFormat(totalSizeUploaded)}
        </Text>{' '}
        of{' '}
        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
          {NumberUtils.getNumberInBytesFormat(totalSize)}
        </Text>
        )
      </Text>
    </Row>
  );
}
