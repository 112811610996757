import React, { useEffect, useRef, useState } from 'react';

// Calculates the font size dynamically based on its parent container width and height
export function useFontSizeBasedOnContainer(initialFontSize: number) {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(initialFontSize);

  useEffect(() => {
    const adjustFontSize = () => {
      const container = containerRef.current;
      const textElement = textRef.current;

      if (!container || !textElement) return;
      const { clientWidth: containerWidth, clientHeight: containerHeight } = container;

      let size = fontSize;

      while ((textElement.scrollWidth > containerWidth || textElement.scrollHeight > containerHeight) && size > 0) {
        size -= 1;
        textElement.style.fontSize = `${size}px`;
      }
      setFontSize(size);
    };
    adjustFontSize();

    // might add these later when there is requirement
    // window.addEventListener('resize', adjustFontSize);
    // return () => window.removeEventListener('resize', adjustFontSize);
  }, [initialFontSize, containerRef.current, textRef.current]);

  return { fontSize, containerRef, textRef };
}
