import { COLOR_SHADES, Space, TEXT_BOLDNESS, TEXT_SIZE, Text } from '@premagic/myne';
import { usePhotoSliderWidgetData } from '../context/PhotoSliderWidgetContext';
import { NO_PUBLIC_FOLDER_ALERT_INFO } from '../service/PhotoSliderWidgetService';

type Props = {
  shareId?: string;
  isAnyFolderMadePublic?: boolean;
};

export default function PhotoSliderWidgetPreview(props: Props) {
  const { shareId, isAnyFolderMadePublic } = props;

  const { widgetCode, customizations } = usePhotoSliderWidgetData();

  // const iframeHeight = customizations?.max_height ? customizations.max_height + 50 : 400;
  const iframeHeight = '700px';

  if (!isAnyFolderMadePublic) {
    return (
      <Space size={8}>
        <Text boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.LIGHT}>
          {NO_PUBLIC_FOLDER_ALERT_INFO}
        </Text>
      </Space>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <iframe title="Sample Widget" srcDoc={widgetCode} width="100%" height={iframeHeight} frameBorder="0" />
    </div>
  );
}
