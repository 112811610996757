import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
  TEXT_SIZE,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
  Text,
  BUTTON_STYLES,
  FormFooter,
  BUTTON_SIZE,
  Form,
  Space,
  PreventMarginCollapse,
  Button,
  FormResponseType,
  FormErrorType,
  Row,
  Dialog,
  DialogTitle,
  DIALOG_SIZES,
  RealCheckbox,
} from '@premagic/myne';
import { ClientsService, FolderService } from '@premagic/core';

import SharePanelClientDetails from './SharePanelClientDetails';
import CRMClientFormContainer from '../crm/clients/CRMClientFormContainer';
import FolderShareChannelInfoContainer from '../projects/folders/share/FolderShareChannelInfoContainer';
import AccountFolderItem from '../projects/folders/AccountFolderItem';
import AddNewClientForm from '../crm/clients/AddNewClientForm';
import MESSAGES from '../../../../common/Messages';

type SharePanelFormProps = {
  clientId?: string;
  client: {
    name: string;
    phone_number: string;
    email: string;
  } | null;
};

type Props = {
  projectId: string;
  clientId?: string;
  eventClient?: ClientsService.ClientType;
  clients?: Array<{
    name: string;
    phone_number: string;
    email: string;
  }>;
  shareFoldersWithClient: (
    options: { projectId: string; folderIds: Array<string>; folderType: FolderService.FOLDER_TYPES },
    formResponse: FormResponseType,
  ) => void;
  close: () => void;
  show: boolean;
  folderIdsToShare: Array<string>;
  isLoading: boolean;
  errors: FormErrorType;
  foldersToShare: Array<FolderService.FolderType>;
};

type ClientData = {
  name: string;
  phone_number: string;
  email: string;
  isNew?: boolean;
};

export default function SharePanel(props: Props) {
  const {
    show,
    close,
    clients,
    eventClient,
    folderIdsToShare,
    projectId,
    shareFoldersWithClient,
    clientId,
    isLoading,
    errors,
    foldersToShare,
  } = props;

  // const sharedFolders = Object.values(folders).find((item) => item.status !== FolderService.FOLDER_STATUS.DRAFT);
  // const clientsDetails = Object.values(sharedFolders?.clients || {});
  const folderType = foldersToShare[0]?.folder_type;
  const [showAddNewClientForm, setAddNewClientFormStatus] = useState(false);
  const [allClients, setAllClients] = useState<Array<ClientData>>([]);
  const enabledShareButton = allClients.find((client) => client.isNew) || allClients.length === 0;

  useEffect(() => {
    if (clients?.length) {
      setAllClients(clients);
    } else if (eventClient) {
      setAllClients([
        {
          name: eventClient.name,
          phone_number: eventClient.phone_number,
          email: eventClient.email,
          isNew: true,
        },
      ]);
    }
  }, [clients, eventClient]);

  const target = useRef(null);

  function onNewClientDataSubmit(clientData) {
    setAddNewClientFormStatus(false);
    setAllClients([...allClients, { ...clientData.data, isNew: true }]);
  }

  function onClose() {
    setAddNewClientFormStatus(false);
    close();
  }

  if (!show) return null;

  function handleShareFolders(formResponse) {
    let mainClientData;

    // will get the formResponse only if the main event client is not present
    if (formResponse) {
      if (formResponse?.data?.name?.trim()?.length && formResponse?.data?.phone_number?.replaceAll(' ', '')?.length) {
        mainClientData = {
          name: formResponse.data.name.trim(),
          phone_number: formResponse.data.phone_number.replaceAll(' ', ''),
          email: formResponse.data.email,
        };
      }
    }

    let clientsArrayToPost: any = [];

    if (mainClientData) {
      clientsArrayToPost.push(mainClientData);
    }

    if (allClients?.length > 0) {
      clientsArrayToPost = [
        ...clientsArrayToPost,
        ...allClients.map((client) => ({
          name: client.name,
          email: client.email,
          phone_number: client.phone_number,
        })),
      ];
    }

    const formResponseWithDate = {
      data: {
        clients: clientsArrayToPost,
        skip_notifications: formResponse?.data?.skip_notifications,
      },
    };

    shareFoldersWithClient(
      { projectId, folderIds: folderIdsToShare, folderType },
      formResponseWithDate as unknown as FormResponseType,
    );
  }

  if (folderIdsToShare.length === 0) {
    return (
      <WindowPanel show={show}>
        <WindowPanelHeader onClose={onClose}>
          <WindowPanelTitle>Share</WindowPanelTitle>
        </WindowPanelHeader>
        <WindowPanelContent>
          <Text size={TEXT_SIZE.SIZE_3}>There is nothing to share</Text>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Okay
            </Button>
          </FormFooter>
        </WindowPanelContent>
      </WindowPanel>
    );
  }
  const showMainClientForm = !eventClient && !clients;

  return (
    <WindowPanel show={show}>
      <WindowPanelHeader onClose={onClose}>
        <WindowPanelTitle>Share</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Dialog target={target.current} show={showAddNewClientForm} onClose={() => {}} size={DIALOG_SIZES.AUTO}>
          <DialogTitle>Add new host</DialogTitle>
          <AddNewClientForm onCancel={() => setAddNewClientFormStatus(false)} onSubmit={onNewClientDataSubmit} />
        </Dialog>
        <Form
          errors={errors}
          onSubmit={handleShareFolders}
          formSpec={
            showMainClientForm
              ? {
                  name: {
                    validator: 'required',
                  },
                  email: {
                    validator: 'required',
                  },
                  phone_number: {
                    validator: 'required',
                  },
                  skip_notifications: {
                    type: 'boolean',
                  },
                }
              : {
                  skip_notifications: {
                    type: 'boolean',
                  },
                }
          }
        >
          {showMainClientForm && <CRMClientFormContainer />}

          {allClients.map((clientData) => (
            <div key={clientData.email}>
              <SharePanelClientDetails client={clientData} />
              <Space vertical />
            </div>
          ))}
          <Button style={BUTTON_STYLES.LINK} onClick={() => setAddNewClientFormStatus(true)} ref={target}>
            Add host
          </Button>
          <Space vertical />
          {(folderType === FolderService.FOLDER_TYPES.HIGHLIGHT ||
            folderType === FolderService.FOLDER_TYPES.SELECTION) && (
            <>
              <Row wrap>
                {foldersToShare.map((folder) => (
                  <Button style={BUTTON_STYLES.DEFAULT} size={BUTTON_SIZE.SM} key={folder.folder_id}>
                    <AccountFolderItem folder={folder} />
                  </Button>
                ))}
              </Row>
              <Space vertical />
            </>
          )}
          <FolderShareChannelInfoContainer showSettingsLink />
          <Space vertical size={2} />
          <RealCheckbox name="skip_notifications">Skip host notifications</RealCheckbox>
          <PreventMarginCollapse>
            <Space vertical size={6} />
          </PreventMarginCollapse>
          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={close} size={BUTTON_SIZE.LG}>
              Skip, share later
            </Button>
            <Button
              size={BUTTON_SIZE.LG}
              style={BUTTON_STYLES.PRIMARY}
              isLoading={isLoading}
              disabled={!enabledShareButton}
              disabledMessage={MESSAGES.SHARE.ALREADY_SHARED}
            >
              Share
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
