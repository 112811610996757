import React, { useRef } from 'react';
import {
  BUTTON_ICONS_SIZES,
  ButtonIcon,
  Dialog,
  DIALOG_PLACEMENT,
  Icon,
  ICON_SIZES,
  LoadingDots,
  Row,
  Space,
  FormLabel,
} from '@premagic/myne';
import { EventsService } from '@premagic/core';

import EventStatusLabel from '../EventStatusLabel';
import MESSAGES from '../../../../../../../common/Messages';
import { PERMISSIONS } from '../../../users/UserPermissionService';
import EventStatusSelector from '../EventStatusSelector';

type Props = {
  eventId: string;
  clientId: string;
  isSaving: boolean;
  status: EventsService.EVENT_STATUS;
  saveStatus: (eventId: string, clientId: string, stage: EventsService.EVENT_STATUS) => void;
  toggleShowEdit: (show: boolean) => void;
  showEdit: boolean;
  hasEventManagementPermission: boolean;
};

export default function EventStatusSelectorButton(props: Props): React.ReactElement {
  const { eventId, clientId, isSaving, saveStatus, status, showEdit, toggleShowEdit, hasEventManagementPermission } =
    props;
  const target = useRef(null);

  return (
    <div ref={target}>
      {isSaving ? (
        <LoadingDots size="sm" />
      ) : (
        <Row vcenter>
          <EventStatusLabel status={status} />
          <Space size={1} />
          <ButtonIcon
            onClick={() => toggleShowEdit(!showEdit)}
            title="Edit"
            size={BUTTON_ICONS_SIZES.SM}
            active={showEdit}
            disabled={!hasEventManagementPermission}
            disabledTitle={MESSAGES.PERMISSION[PERMISSIONS.EVENT_MANAGEMENT]}
          >
            <Icon name="edit_2" size={ICON_SIZES.SM} />
          </ButtonIcon>
        </Row>
      )}
      {showEdit && (
        <Dialog
          target={target.current}
          show={showEdit}
          placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
          onClose={() => toggleShowEdit(false)}
        >
          <Space size={2}>
            <EventStatusSelector
              autoFocus
              status={status}
              isLoading={isSaving}
              onChange={(newStatus) => saveStatus(eventId, clientId, newStatus)}
            />
          </Space>
        </Dialog>
      )}
    </div>
  );
}
