import { connect } from 'react-redux';
import {
  activeCreatorPagesDataSelector,
  albumCreatorPagesDataSelector,
  imageDetailsForPageAndPositionSelector,
  removeImageAndSaveThePage,
} from './AlbumPageDataDuck';
import AlbumCreatorImage from './AlbumCreatorImage';
import { AlbumPageType, ImagePositionLocationsType } from '../services/AlbumServices';
import { albumCreatorSelectedAlbumIdSelector } from '../AlbumCreatorDuck';

function mapStateToProps(state, ownProps: { position: ImagePositionLocationsType; pageNo: number }) {
  const { position, pageNo } = ownProps;
  const pageData = pageNo
    ? (albumCreatorPagesDataSelector(state)[pageNo] as AlbumPageType) // This is on view mode of the album
    : activeCreatorPagesDataSelector(state);

  return {
    pageNo,
    albumId: albumCreatorSelectedAlbumIdSelector(state),
    imageDetails: imageDetailsForPageAndPositionSelector(pageNo, position)(state),
    pageData,
    position,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeImage: (options) => dispatch(removeImageAndSaveThePage(dispatch, options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumCreatorImage);
