import React from 'react';

import {
  Button,
  BUTTON_STYLES,
  Form,
  FormFooter,
  FormResponseType,
  WINDOW_PANEL_SIZES,
  WindowPanel,
  WindowPanelContent,
  WindowPanelHeader,
  WindowPanelTitle,
} from '@premagic/myne';
import { Map as IMap } from 'immutable';
import { ClientWebsiteService } from '@premagic/core';

import ClientWebsiteItineraryFormContainer from './ClientWebsiteItineraryFormContainer';

type Props = {
  websiteId: string;
  projectId: string;
  itineraryId?: string;
  saveItinerary: (
    options: {
      websiteId: string;
      projectId: string;
      itineraries: IMap<string, ClientWebsiteService.ClientWebsiteItinerary>;
      itineraryId: string;
    },
    formResponse: FormResponseType,
  ) => void;
  isLoading: boolean;
  errors?: Record<string, string>;
  showPanel: boolean;
  closePanel: () => void;
  itineraryItems: IMap<string, ClientWebsiteService.ClientWebsiteItinerary>;
};

export default function EditClientWebsiteItineraryPanel(props: Props) {
  const { websiteId, projectId, saveItinerary, isLoading, errors, showPanel, closePanel, itineraryItems, itineraryId } =
    props;
  if (!itineraryId) return null;
  return (
    <WindowPanel show={showPanel} size={WINDOW_PANEL_SIZES.LG}>
      <WindowPanelHeader onClose={closePanel}>
        <WindowPanelTitle>Edit Session</WindowPanelTitle>
      </WindowPanelHeader>
      <WindowPanelContent>
        <Form
          formSpec={{
            time_not_decided: {
              type: 'boolean',
            },
          }}
          onSubmit={(formResponse) =>
            saveItinerary(
              {
                websiteId,
                projectId,
                itineraries: itineraryItems,
                itineraryId,
              },
              formResponse,
            )
          }
          errors={errors}
        >
          <ClientWebsiteItineraryFormContainer itineraryId={itineraryId} />

          <FormFooter>
            <Button style={BUTTON_STYLES.CANCEL} onClick={closePanel}>
              Cancel
            </Button>
            <Button style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </WindowPanelContent>
    </WindowPanel>
  );
}
