import React from 'react';
import {
  Card,
  Space,
  InnerPageHeader,
  FormErrorType,
  FormGroup,
  FormLabel,
  Input,
  Form,
  FormFooter,
  Button,
  BUTTON_STYLES,
  BUTTON_SIZE,
  InnerPage,
  InnerPageContent,
} from '@premagic/myne';
import { UserType } from '../crm/users/UsersService';
import APP_URLS from '../../services/AppRouteURLService';

type Props = {
  user?: UserType;
  isLoading: boolean;
  errors: FormErrorType;
  changePassword: (data) => void;
};

export default function ChangePasswordPage(props: Props): JSX.Element {
  const { user, isLoading, errors, changePassword } = props;
  if (!user) return <div>User not set</div>;
  return (
    <InnerPage>
      <InnerPageHeader iconName="award" title="Change Password">
        Change Password
      </InnerPageHeader>
      <InnerPageContent>
        <Card center>
          <Space size={8}>
            <Form errors={errors} onSubmit={(formResponse) => changePassword(formResponse)}>
              <FormGroup>
                <FormLabel isRequired>Old Password</FormLabel>
                <Input name="oldPassword" autoFocus required type="password" />
              </FormGroup>
              <FormGroup>
                <FormLabel isRequired>New Password</FormLabel>
                <Input name="newPassword" required type="password" />
              </FormGroup>
              <FormFooter>
                <Button style={BUTTON_STYLES.CANCEL} link={APP_URLS.USER_PROFILE.INDEX} size={BUTTON_SIZE.LG}>
                  Cancel
                </Button>
                <Button size={BUTTON_SIZE.LG} style={BUTTON_STYLES.PRIMARY} isLoading={isLoading}>
                  Change Password
                </Button>
              </FormFooter>
            </Form>
          </Space>
        </Card>
      </InnerPageContent>
    </InnerPage>
  );
}
