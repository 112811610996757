import { connect } from 'react-redux';
import EventReportTopMetrics from './EventReportTopMetrics';
import { isFeatureLeadEnabledSelector } from '../../../settings/preferences/ClientSettingsDataDuck';
import { projectMetaDataForProjectSelector } from '../../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
  },
) {
  const { projectId } = ownProps;
  return {
    projectId,
    hasLeadsModule: isFeatureLeadEnabledSelector(state),
    projectMeta: projectMetaDataForProjectSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventReportTopMetrics);
