import { ColorPicker, FormGroup, FormLabel } from '@premagic/myne';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { EventAttendeeBadgeDesignType, WIDGET_TYPE } from '../../../service/EventAttendeeBadgeDesignService';

type Props = {
  selectedWidgetForEdit?: WIDGET_TYPE;
  designData?: EventAttendeeBadgeDesignType;
  editTextColor: (
    projectId: string,
    folderId: string,
    previousDesign?: EventAttendeeBadgeDesignType,
    widgetId?: WIDGET_TYPE,
    color?: string,
  ) => void;
  projectId: string;
  folderId: string;
};
export default function EventAttendeeBadgeTextColorSelector(props: Props) {
  const { projectId, folderId, designData, selectedWidgetForEdit, editTextColor } = props;

  const debouncedHandleColorsChange = useMemo(() => {
    function handleColorsChange(color) {
      editTextColor(projectId, folderId, designData, selectedWidgetForEdit, color);
    }
    return debounce(handleColorsChange, 500);
  }, [projectId, folderId, designData, selectedWidgetForEdit, editTextColor]);

  return (
    <FormGroup>
      <FormLabel>Font Color</FormLabel>
      <ColorPicker
        disableAlpha={false}
        name="event_attendee_badge_text_color"
        defaultValue={(selectedWidgetForEdit && designData?.widget_data?.[selectedWidgetForEdit]?.color) || '#203a56'}
        onChange={debouncedHandleColorsChange}
      />
    </FormGroup>
  );
}
