import {
  ClientWebsiteService,
  EventAttendeesService,
  EventPosterService,
  FolderService,
  PeoplePosterService,
} from '@premagic/core';

import { Button, BUTTON_STYLES, Dialog, Icon, ICON_SIZES, List, Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { StringUtils } from '@premagic/utils';
import React, { useRef, useState } from 'react';
import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type PosterLinkProps = {
  attendee: EventAttendeesService.EventAttendeeType;
  posters: Array<EventPosterService.EventPosterType>;
  clientWebsite: FolderService.FolderType;
  session?: ClientWebsiteService.ClientWebsiteItinerary;
  name?: string;
};

function PosterLink(props: PosterLinkProps): JSX.Element | null {
  const { attendee, posters, clientWebsite, session, name } = props;
  const posterUrlForAttendee = PeoplePosterService.getPosterUrlForAttendee({
    attendee,
    posters,
    clientWebsite,
    session,
  });
  if (!posterUrlForAttendee)
    return (
      <Button
        style={BUTTON_STYLES.LINK}
        disabled
        disabledMessage={`There are no poster for ${EventAttendeesService.EVENT_PEOPLE_TYPE_DETAILS[attendee.people_type].name}`}
      >
        <Icon name="external_link" size={ICON_SIZES.SM} />
        <Space size={1} />
        {name || 'Poster'}
      </Button>
    );

  return (
    <Button
      style={BUTTON_STYLES.LINK}
      link={posterUrlForAttendee}
      isExternalLink
      className={JS_SELECTORS.OPEN_POSTER_LINK}
    >
      <Icon name="external_link" size={ICON_SIZES.SM} />
      <Space size={1} />
      {name || 'Poster'}
    </Button>
  );
}

type Props = {
  id: string;
  attendee: EventAttendeesService.EventAttendeeType;
  posters: Array<EventPosterService.EventPosterType>;
  clientWebsite?: FolderService.FolderType;
  sessions?: Array<ClientWebsiteService.ClientWebsiteItinerary>;
};

export default function EventAttendeeLinkToPosterButton(props: Props): JSX.Element | null {
  const { attendee, id, posters, clientWebsite, sessions } = props;
  const [showList, setShowList] = useState(false);
  const $button = useRef();

  if (!clientWebsite) return null;

  if (attendee.people_type === EventAttendeesService.EVENT_PEOPLE_TYPE.SPEAKER && sessions?.length) {
    if (sessions?.length > 1) {
      return (
        <>
          <Button ref={$button} onClick={() => setShowList(!showList)} style={BUTTON_STYLES.LINK} active={showList}>
            <Icon name="external_link" size={ICON_SIZES.SM} /> <Space size={1} /> posters
          </Button>
          {$button.current && (
            <Dialog show={showList} target={$button.current} onClose={() => setShowList(false)}>
              <Text boldness={TEXT_BOLDNESS.BOLD}>Poster for each of the session</Text>
              <Space vertical size={2} />
              <List>
                {sessions.map((session) => (
                  <div key={session.id}>
                    <PosterLink
                      posters={posters}
                      attendee={attendee}
                      clientWebsite={clientWebsite}
                      session={session}
                      name={StringUtils.ellipseString(session.name, 34)}
                    />
                    <Space vertical size={2} />
                  </div>
                ))}
              </List>
            </Dialog>
          )}
        </>
      );
    }
    return <PosterLink posters={posters} attendee={attendee} clientWebsite={clientWebsite} session={sessions[0]} />;
  }

  return <PosterLink posters={posters} attendee={attendee} clientWebsite={clientWebsite} />;
}
