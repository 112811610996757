import { connect } from 'react-redux';
import EditClientWebsiteBasicInfoPanel from './EditClientWebsiteBasicInfoPanel';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { saveClientWebsite } from '../ClientWebsiteDataDuck';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';

const PANEL_KEY = LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_PANEL;
function mapStateToProps(state, ownProps) {
  const { projectId, id } = ownProps;

  return {
    id,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_WEBSITE.UPDATE)(state),
    showPanel: isWindowPanelOpenSelector(PANEL_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveClientWebsite: (id: string, projectId: string, data, navigateToUrl) => {
      dispatch(saveClientWebsite(dispatch, { id, projectId, navigateToUrl }, data));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClientWebsiteBasicInfoPanel);
