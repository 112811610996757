import { ErrorTracker, MediaUtils, useImage } from '@premagic/utils';
import React, { useEffect, useState } from 'react';
import { Group as CanvasGroup, Image as CanvasImage, Rect as CanvasRect } from 'react-konva';
import { getImageCrop, IMAGE_CLIP_POSITION } from '../services/PosterImageService';
import { removeBackground } from '../services/PosterService';
import {
  AVATAR_STYLE,
  getCornerRadiusFromStyle,
  POSTER_WIDGET_TYPE,
  PosterWidgetAvatarType,
  PosterWidgetTextType,
} from '../services/PosterWidgetService';
import { PosterText } from './BaseWidgets';

function useImageUrlForAvatar(
  url?: string,
  options?: {
    size: { width: number; height?: number };
    removeBg?: boolean;
  },
): {
  imageUrl: string | null;
  isLoading: boolean;
} {
  const { size, removeBg } = options || {
    size: { width: 100, height: 100 },
  };
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (!url) {
        setIsLoading(false);
        return;
      }

      if (removeBg) {
        const newImage = await removeBackground(url);
        setImageUrl(newImage.data.url);
      } else if (url.startsWith('data:image')) {
        setImageUrl(url);
      } else if (url.includes('premagic.')) {
        setImageUrl(
          MediaUtils.getNewDynamicImageURL(url, MediaUtils.IMAGE_QUALITY_TYPES.FULL_VIEW_HIGHLIGHT, {
            width: size.width,
          }),
        );
      } else {
        setImageUrl(url);
      }
      setIsLoading(false);
    };

    fetchImageUrl();
  }, [url, size, removeBg]);

  return { imageUrl, isLoading };
}

type PosterAvatarType = {
  data: PosterWidgetAvatarType;
};

const PosterAvatar = React.forwardRef((props: PosterAvatarType, ref: React.Ref<any>) => {
  const { data, ...rest } = props;
  const { position, size, value, id, style = AVATAR_STYLE.CIRCLE, font_color: color } = data;
  const { imageUrl = '', isLoading: featureImageLoading } = useImageUrlForAvatar(value, {
    size,
    removeBg: style === AVATAR_STYLE.WITHOUT_BACKGROUND,
  });
  const {
    image: imageObj,
    isLoading,
    hasError,
  } = useImage(imageUrl || '', {
    getImageObject: true,
    fromCanvas: true,
  });
  const textProps: PosterWidgetTextType = {
    type: POSTER_WIDGET_TYPE.TEXT,
    id: 'avatar-text',
    name: 'avatar-text',
    position,
    size,
    font_color: color,
    font_size: size.width * 0.1,
    font_align: 'center',
    font_vertical_align: 'middle',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  if (!value)
    return (
      <CanvasGroup>
        <PosterText data={{ ...textProps, value: 'Your photo here' }} />
        <CanvasRect
          x={position?.x}
          y={position?.y}
          rotation={position?.rotation}
          width={size.width}
          height={size.height}
          stroke={color}
          opacity={0.5}
          dash={[3, 5]}
          cornerRadius={getCornerRadiusFromStyle(style, size.width)}
          ref={ref}
          fill="rgba(0,0,0,0.1)"
          {...rest}
        />
      </CanvasGroup>
    );
  if (isLoading) return <PosterText data={{ ...textProps, value: 'Loading...' }} />;

  if (!imageUrl) return <PosterText data={{ ...textProps, value: 'Avatar is not set' }} />;

  if (hasError) {
    ErrorTracker.logError('USER_AVATAR', new Error(`Can't load image ${imageUrl}`));
    return <PosterText data={{ ...textProps, value: "Can't load image" }} />;
  }
  const cropDetails = imageObj
    ? getImageCrop(
        {
          width: imageObj.width || 100,
          height: imageObj.height || 100,
        },
        {
          width: Number(size.width),
          height: Number(size.height) || 100,
        },
        IMAGE_CLIP_POSITION.CENTER_MIDDLE,
      )
    : undefined;
  return (
    <CanvasImage
      ref={ref}
      id={id}
      image={imageObj}
      x={position?.x}
      y={position?.y}
      rotation={position?.rotation}
      width={size.width}
      height={size.height}
      cornerRadius={getCornerRadiusFromStyle(style, size.width)}
      crop={cropDetails}
      {...rest}
    />
  );
});

export default PosterAvatar;
