import { connect } from 'react-redux';
import {
  clearFileSelectionForBulkAction,
  deleteFilesFromFolder,
  isBulkDeleteEnabledSelector,
  selectedFilesForBulkActionSelector,
  setBulkAction,
} from '../AccountFoldersDataDuck';
import FolderFilesBulkDelete from './FolderFilesBulkDelete';

type OwnPropsType = {
  projectId: string;
  folderId: string;
};

function mapStateToProps(state, ownProps: OwnPropsType) {
  return {
    projectId: ownProps.projectId,
    folderId: ownProps.folderId,
    isBulkActionDelete: isBulkDeleteEnabledSelector(state),
    selectedFilesForBulkAction: selectedFilesForBulkActionSelector(state),
  };
}

function mapDispatchToProps(dispatch: (...args: Array<any>) => any) {
  return {
    clearFileSelectionForBulkAction: () => {
      dispatch(clearFileSelectionForBulkAction());
    },
    cancelBulkAction: () => {
      dispatch(setBulkAction(dispatch, null));
    },
    deleteImages: (projectId, folderId, fileIds: Array<string>) => {
      dispatch(
        deleteFilesFromFolder(dispatch, {
          projectId,
          folderId,
          fileIds,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderFilesBulkDelete);
