import { connect } from 'react-redux';
import { ProposalDeckDataDuck, ProposalSlideDataDuck, ProposalVariablesDataDuck } from '@premagic/proposals';
import DeckVariablesForm from './DeckVariablesForm';
import { folderMetaDataForFolderSelector } from '../../../projects/folder-meta/FoldersMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    deckId: string;
    folderId: string;
  },
) {
  const { deckId, folderId } = ownProps;
  return {
    folderMeta: folderMetaDataForFolderSelector(folderId)(state),
    deck: ProposalDeckDataDuck.proposalsDeckItemsSelectors(state)[deckId],
    groupedVariablesSetOnDeck: ProposalSlideDataDuck.groupedProposalsVariablesInAllSlidesSelectors(state),
    variables: ProposalVariablesDataDuck.proposalsVariableItemsSelectors(state),
    slides: ProposalSlideDataDuck.proposalsSlideItemsSelectors(state),
  };
}

export default connect(mapStateToProps)(DeckVariablesForm);
