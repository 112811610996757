import { connect } from 'react-redux';
import { FolderService, PricingPlanFeaturesService } from '@premagic/core';
import { ErrorDuck, LoadingDuck } from '@premagic/common-ducks';
import { FormErrorType } from '@premagic/myne';
import { isWatermarkBetaEnabledSelector } from '../../preferences/ClientSettingsDataDuck';
import { hasFeatureSelector } from '../../company/CompanyDataDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { updateSystemBrandingFolder } from '../../../projects/sponsor/SponsorAndBrandingDuck';
import BrandingWatermarkDetails from './BrandingWatermarkDetails';
import { foldersEntitiesSelector } from '../../../projects/folders/AccountFoldersDataDuck';

function mapStateToProps(state, ownProps) {
  const isWatermarkBetaEnabled = isWatermarkBetaEnabledSelector(state);
  const { folderId } = ownProps.match.params;
  return {
    folderId,
    folder: foldersEntitiesSelector(state)[folderId] as unknown as FolderService.BrandingFolderType,
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.FOLDER.UPDATE)(state),
    errors: ErrorDuck.errorObjectForTypeSelector<FormErrorType>(LOADINGS.FOLDER.UPDATE)(state),
    hasWatermarkFeature:
      isWatermarkBetaEnabled || hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WATERMARK)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    update: (folderId: string, data) => {
      dispatch(updateSystemBrandingFolder(dispatch, folderId, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingWatermarkDetails);
