import { Dropdown, DropdownIcon, Icon, ICON_SIZES, LoadingDots, Menu, MenuItem, MenuItemIcon } from '@premagic/myne';
import React from 'react';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../../crm/users/UserPermissionService';
import SponsorDeleteDialogContainer from './FormFieldDeleteDialogContainer';
import ShowOrHideOnRegistrationMenuItemContainer from './ShowOrHideOnRegistrationMenuItemContainer';

type EventOccasionOptionsMenuProps = {
  projectId: string;
  formFieldId: string;
  hasPermissionsToFormField: boolean;
  showDeleteDialog: (id: string) => void;
  isLoading: boolean;
};

export default function EventFormFieldOptionsMenu(props: EventOccasionOptionsMenuProps): React.ReactElement {
  const { formFieldId, showDeleteDialog, projectId, hasPermissionsToFormField, isLoading } = props;

  if (isLoading) {
    return <LoadingDots size="xs" />;
  }

  return (
    <>
      <Dropdown>
        <DropdownIcon title="More" tooltipPlacement="top">
          <Icon name="more_vertical" size={ICON_SIZES.SM} />
        </DropdownIcon>

        <Menu>
          <MenuItem
            disabled={!hasPermissionsToFormField}
            disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]}
            onClick={() => showDeleteDialog(formFieldId)}
          >
            <MenuItemIcon name="trash" />
            Delete
          </MenuItem>
          <ShowOrHideOnRegistrationMenuItemContainer
            disabled={!hasPermissionsToFormField}
            projectId={projectId}
            formFieldId={formFieldId}
          />
        </Menu>
      </Dropdown>
      {hasPermissionsToFormField && <SponsorDeleteDialogContainer formFieldId={formFieldId} projectId={projectId} />}
    </>
  );
}
