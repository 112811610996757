import { APIURLService, ClientSettingsService, CompanyService } from '@premagic/core';
import { BrowserUrlUtils, HttpUtils } from '@premagic/utils';
import { UserType } from '../../crm/users/UsersService';

export function fetchCompanyInfo(): Promise<CompanyService.CompanyType> {
  return HttpUtils.get(APIURLService.API_URLS.COMPANY.ROOT, {}, false)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export type CapturePaymentDataType = {
  amount?: number;
  subscriptionId: string;
  paymentId: string;
  razorpaySignature: string;
  clientSettings: ClientSettingsService.ClientSettingsType;
  requestingUser: UserType;
};

export function savePaymentInfo(options: CapturePaymentDataType): Promise<CompanyService.CompanyType> {
  const { subscriptionId, paymentId, razorpaySignature } = options;
  return HttpUtils.post(APIURLService.API_URLS.SUBSCRIPTION.CAPTURE_PAYMENT, {
    subscription_id: subscriptionId,
    razorpay_payment_id: paymentId,
    razorpay_signature: razorpaySignature,
  }).then(({ data }) => data);
}

export function saveCompanyLogoService(data: FormData): Promise<CompanyService.CompanyType> {
  return HttpUtils.post(APIURLService.API_URLS.COMPANY.SETTINGS, data, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function removeCompanyLogoService(name: CompanyService.COMPANY_LOGOS): Promise<CompanyService.CompanyType> {
  return HttpUtils.post(APIURLService.API_URLS.COMPANY.SETTINGS, { [name]: null }, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function saveCompanyInfoService(
  formData: CompanyService.CompanyType, // this cannot be partial because we need to send all the data to the server
): Promise<CompanyService.CompanyType> {
  const data = {
    ...formData,
    contacts: { ...formData.contacts, website: BrowserUrlUtils.getURLWithProtocol(formData?.contacts?.website) },
  };
  return HttpUtils.post(APIURLService.API_URLS.COMPANY.SETTINGS, data, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}
