import { connect } from 'react-redux';

import ClientWebsiteMetricsFormItem from './ClientWebsiteMetricsFormItem';

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteMetricsFormItem);
