import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { navigateBack } from '../../../../../services/RouterService';
import { fetchFolder } from '../../projects/folders/AccountFoldersDataDuck';
import WatermarkPreviewFullView from './WatermarkPreviewFullView';

function mapStateToProps(state, ownProps) {
  const { projectId, folderId, eventId } = ownProps.match.params;

  return {
    projectId,
    folderId,
    isLoadingProject: isLoadingSelector(LOADINGS.PROJECT.FETCH)(state),
    isLoading: isLoadingSelector(LOADINGS.FOLDER.FETCH)(state),
    eventId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateBack: (defaultUrl) => {
      navigateBack(dispatch, defaultUrl);
    },

    init: (projectId, folderId) => {
      dispatch(fetchFolder(dispatch, projectId, folderId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermarkPreviewFullView);
