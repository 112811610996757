import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';
import { Dispatch } from 'redux';
import { ActionTypeUtils, BrowserUtils } from '@premagic/utils';
import { commonUISelector } from '../src/app/reducers/selectors';

const getActionType = ActionTypeUtils.getActionTypeFunction('ERROR', true);

function scrollToFirstError() {
  const errorClass = '.js-form-error';
  BrowserUtils.scrollTo(errorClass);
}

export const setErrorState = createAction(
  getActionType('STATE', 'SET'),
  (dispatch: Dispatch, type: string, error: unknown) => {
    setTimeout(scrollToFirstError, 300);
    return { [type]: error };
  },
);

export const clearErrorState = createAction(
  getActionType('STATE', 'CLEAR'),
  (dispatch: Dispatch, type: string) => type,
);

const initialState = {
  items: IMap({}),
};

export default handleActions(
  {
    [setErrorState.toString()]: (state, action) => ({
      ...state,
      items: state.items.merge(action.payload),
    }),
    [clearErrorState.toString()]: (state, action) => ({
      ...state,
      items: state.items.delete(String(action.payload)),
    }),
  },
  initialState,
);

export const errorsDataSelector = createSelector(commonUISelector, (state: any) => state.errors);

export const errorForTypeSelector = (type: string) =>
  createSelector(errorsDataSelector, (state: any) => !!state.items.get(type));

export const errorObjectForTypeSelector = (type: string) =>
  createSelector(errorsDataSelector, (state: any) => state.items.get(type));
