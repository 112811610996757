import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';

import { navigateToCRMPageWithFilter, removeFilterCRMPage } from './CRMFilterDuck';
import { CRMFilters } from './CRMFilters';
import { allEventLabelsSelector } from '../labels/LabelsDataDuck';
import { EventFiltersType } from '../calendar-and-event-page/EventsListService';
import { isFeatureLeadEnabledSelector } from '../../settings/preferences/ClientSettingsDataDuck';
import { isMobileUp } from '../../AppDuck';

function mapStateToProps(state, ownProps: { filters: string; view: 'list' | 'calendar' }) {
  const { filters, view } = ownProps;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as EventFiltersType;

  return {
    filters: { ...filterObject, labels: filterObject?.labels?.split(',') },
    view,
    labelOptions: allEventLabelsSelector(state),
    isFeatureLeadEnabled: isFeatureLeadEnabledSelector(state),
    isMobileUp: isMobileUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (view, filters) => {
      dispatch(navigateToCRMPageWithFilter(dispatch, view, filters));
    },
    removeFilter: (view, filterKey, filters) => {
      dispatch(removeFilterCRMPage(dispatch, view, filterKey, filters));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMFilters);
