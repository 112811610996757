import React from 'react';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  List,
  ListItem,
  Row,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Space,
  InnerPageContentMainCol,
  InnerPageHeader,
} from '@premagic/myne';
import { ClientSettingsService } from '@premagic/core';
import APP_URLS from '../../../services/AppRouteURLService';
import CompanyEnquiryFormPageContainer from './enquiry-form/CompanyEnquiryFormPageContainer';

type Props = {
  savePreferences: (data) => void;
  hasLeadsModule: boolean;
  isLoading: boolean;
};

export default function LeadsModuleSettingsPage(props: Props) {
  const { hasLeadsModule, savePreferences, isLoading } = props;

  return (
    <InnerPage>
      <InnerPageHeader iconName="trello" title="Leads Settings" backTo={APP_URLS.SETTINGS.INDEX}>
        Lead Management
      </InnerPageHeader>
      <InnerPageContent hasInnerCols isLoading={isLoading}>
        <InnerPageContentMainCol>
          <Card>
            <List>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Enable Leads Module
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>Show Enquire form in Signature Album & Portfolio</Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {hasLeadsModule ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      name="show_enquire_form"
                      checked={hasLeadsModule}
                      onChange={(value) => {
                        savePreferences({
                          data: { [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_LEADS_DISABLE]: !value },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
            </List>
          </Card>
          <Space vertical size={8} />
          {hasLeadsModule && <CompanyEnquiryFormPageContainer />}
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
