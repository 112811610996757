import React from 'react';

import { ButtonIcon, Icon, ICON_SIZES, HTML_ICON, Space } from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';

import { ALBUM_CREATOR_URLS } from './services/AlbumCreatorRouteURLService';

type Props = {
  buttonDirection: 'previous' | 'next';
  hasPreviousPage: boolean;
  pageNo: number;
  albumId: string;
};

export default function AlbumCreatorPageNavigatorButton(props: Props): JSX.Element {
  const { buttonDirection, pageNo, hasPreviousPage, albumId } = props;

  function isDisabled(): boolean {
    if (buttonDirection === 'previous') return !hasPreviousPage;
    return false;
  }

  function functionGetURL(): string {
    if (!albumId || !pageNo) return '';
    return BrowserUrlUtils.getRouteUrlFor(ALBUM_CREATOR_URLS.ALBUM.EDIT, {
      albumId,
      pageNo: buttonDirection === 'previous' ? pageNo - 1 : pageNo + 1,
    });
  }
  function getTitle() {
    if (buttonDirection === 'previous') {
      return (
        <div>
          Previous Page <Space size={1} /> <kbd>{HTML_ICON.ARROW_LEFT}</kbd>
        </div>
      );
    }
    return (
      <div>
        Next Page <Space size={1} /> <kbd>{HTML_ICON.ARROW_RIGHT}</kbd>
      </div>
    );
  }

  return (
    <ButtonIcon
      disabledTitle="Thats it"
      title={getTitle()}
      tooltipPlacement="top"
      link={functionGetURL()}
      disabled={isDisabled()}
    >
      <Icon name={buttonDirection === 'previous' ? 'chevron_left' : 'chevron_right'} size={ICON_SIZES.LG} />
    </ButtonIcon>
  );
}
