import { PricingPlanFeaturesService } from '@premagic/core';
import { connect } from 'react-redux';
import { hasFeatureSelector } from '../../../../settings/company/CompanyDataDuck';
import PhotoSliderWidgetGetWidgetCode from './PhotoSliderWidgetGetWidgetCode';

function mapStateToProps(state, ownProps: { shareId?: string }) {
  const { shareId } = ownProps;

  return {
    shareId,
    hasFeature: hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.WIDGET_PHOTO_GALLERY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSliderWidgetGetWidgetCode);
