import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { IMAGE_V2_STYLES, ImageV2, Space, SubTitle, Text } from '@premagic/myne';
import { FileService } from '@premagic/core';
import { MediaUtils } from '@premagic/utils';

function getItemStyle(isDragging, draggableStyle) {
  const baseStyle = {
    userSelect: 'none',
    width: '100%',
    height: '160px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#fff',
    ...draggableStyle,
  };
  if (isDragging)
    return {
      ...baseStyle,
      borderColor: '#047af0',
      borderRadius: '4px',
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    };

  return baseStyle;
}
type Props = {
  images: Array<FileService.FolderFileType>;
};

export default function AlbumCreatorImagesTray(props: Props) {
  const { images } = props;

  if (images.length === 0) {
    return (
      <Space>
        <Text muted block>
          There are no photo here..
        </Text>
        <Text muted>Only the client selected photos from the selection will show up here.</Text>
      </Space>
    );
  }
  return (
    <div>
      <Space>
        <SubTitle>Photos ({images.length})</SubTitle>
      </Space>
      <Droppable droppableId="IMAGE-TRAY">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              display: 'grid',
              marginLeft: '2px',
              gridTemplateColumns: `repeat( auto-fill, 50% )`,
            }}
          >
            {provided.placeholder}
            {images.map(({ thumbnail_dynamic_image_url: dynThumbnailLocation, image_name: imageName, id }, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    key={id}
                    className={`js-image-${id}`}
                  >
                    <ImageV2
                      style={IMAGE_V2_STYLES.COVER}
                      alt={imageName}
                      src={dynThumbnailLocation}
                      width={300}
                      imageLoadType={MediaUtils.DYNAMIC_IMAGE_TYPES.DYN_THUMBNAIL}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </div>
  );
}
