import { connect } from 'react-redux';
import { ActionConstants, ProposalVariablesDataDuck } from '@premagic/proposals';
import { LoadingDuck } from '@premagic/common-ducks';
import ProposalVariableItem from './ProposalVariableItem';
import { showEditProposalParameter } from '../creator/ProposalCreatorDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
  },
) {
  const { id } = ownProps;
  return {
    variable: ProposalVariablesDataDuck.proposalsVariableItemsSelectors(state)[id],
    isUpdating: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL_VARIABLES.UPDATE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteVariable: (id) => {
      dispatch(ProposalVariablesDataDuck.removeProposalVariable(dispatch, id));
    },
    showEditVariable: (scope, id) => {
      dispatch(showEditProposalParameter(dispatch, scope, id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalVariableItem);
