import React from 'react';
import { Circle as CanvasCircle, Rect as CanvasRect } from 'react-konva';
import { BADGE_PUNCH_HOLE_STYLE } from '../services/PosterWidgetService';

type BadgePunchHoleProps = {
  style?: BADGE_PUNCH_HOLE_STYLE;
  posterSize: {
    width: number;
    height: number;
  };
};

export default function BadgePunchHole(props: BadgePunchHoleProps) {
  const { style, posterSize } = props;
  const color = '#ddd';
  const size = 10;
  const padding = 25;
  const rectWidth = size * 3;

  switch (style) {
    case BADGE_PUNCH_HOLE_STYLE.CIRCLE:
      return <CanvasCircle x={posterSize.width / 2} y={padding} radius={size} fill={color} />;
    case BADGE_PUNCH_HOLE_STYLE.CIRCLE_BOTH_SIDES:
      return (
        <>
          <CanvasCircle x={padding} y={padding} radius={size} fill={color} />
          <CanvasCircle x={posterSize.width - padding} y={padding} radius={size} fill={color} />
        </>
      );
    case BADGE_PUNCH_HOLE_STYLE.RECTANGLE:
      return (
        <CanvasRect
          x={posterSize.width / 2 - rectWidth / 2}
          y={padding - size}
          width={rectWidth}
          height={size}
          fill={color}
          cornerRadius={size / 2}
        />
      );
    case BADGE_PUNCH_HOLE_STYLE.RECTANGLE_BOTH_SIDES:
      return (
        <>
          <CanvasRect
            x={padding}
            y={padding - size}
            width={rectWidth}
            height={size}
            fill={color}
            cornerRadius={size / 2}
          />
          <CanvasRect
            x={posterSize.width - rectWidth - padding}
            y={padding - size}
            width={rectWidth}
            height={size}
            fill={color}
            cornerRadius={size / 2}
          />
        </>
      );
    default:
      return null;
  }
}
