/*
 Inspiration from https://www.bigbinary.com/blog/shape-snapping-with-react-konva
                  https://konvajs.org/docs/sandbox/Objects_Snapping.html

 * What's the logic behind snaping?
 * 1. Get all the vertical and horizonal points from all the elements (getSnapLines)
 *  a. get all vertical points(x, x+width and x+width/2) from all the shapes
 *  b. get all horizontal points(y, y+height and y+height/2) from all the shapes
 *
 * 2. Get all the snapping line of current shape (getShapeSnappingEdges)
 *  a. Get vertical point, the offset and snap type (start, center, end)
 *  b. Get horizontal point, the offset and snap type (start, center, end)
 *
 * 3. Get the closest snaping lines (getClosestSnapLines)
 * a. Get the closest vertical and horizontal snaping lines
 *
 * 4. Draw the lines (getDrawLines)
 * a. Draw the lines based on the closest snaping lines
 *
 * */

import { getSelector, SELECTOR_KEYS } from './SelectorService';

const SNAP_THRESHOLD = 5;
enum DIRECTION {
  VERTICAL = 'V',
  HORIZONTAL = 'H',
}
const lineStyle = {
  stroke: '#ff9aac',
  strokeWidth: 1,
  name: 'guid-line',
};

type SnapLineType = {
  snapLine: number;
  offset: number;
  orientation?: DIRECTION;
  snap: string;
  diff: number;
};

type SnapLinesType = Record<DIRECTION, number[]>;

export function getSnapLines(transformerCurrent, selectedNode): SnapLinesType {
  const stage = transformerCurrent.getStage();
  if (!stage) {
    return {
      [DIRECTION.VERTICAL]: [],
      [DIRECTION.HORIZONTAL]: [],
    };
  }

  const vertical: Array<number[]> = [];
  const horizontal: Array<number[]> = [];
  const wrapper = stage.findOne(getSelector(SELECTOR_KEYS.EXPORT_NODE));
  // We snap over edges and center of each object on the canvas
  // We can query and get all the shapes by their name property `shape`.
  function snapElements(e) {
    return e.hasName(SELECTOR_KEYS.SHAPE) || e.hasName(SELECTOR_KEYS.EXPORT_NODE);
  }
  stage.find(snapElements).forEach((shape) => {
    // We don't want to snap to the selected shape, so we will be passing them as `excludedShape`
    if (shape === selectedNode) return;

    const box = shape.getClientRect({ skipShadow: true, skipStroke: true, relativeTo: wrapper });
    vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
    horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
  });
  return {
    [DIRECTION.VERTICAL]: vertical.flat(),
    [DIRECTION.HORIZONTAL]: horizontal.flat(),
  };
}

type ShapeSnappingEdgesType = Record<DIRECTION, Array<{ guide: number; offset: number; snap: string }>>;
export function getShapeSnappingEdges(transformerCurrent, selectedNode): ShapeSnappingEdgesType {
  const stage = transformerCurrent.getStage();
  const wrapper = stage.findOne(getSelector(SELECTOR_KEYS.EXPORT_NODE));

  const box = selectedNode.getClientRect({
    relativeTo: wrapper,
  });
  const absPos = selectedNode.absolutePosition();

  return {
    [DIRECTION.VERTICAL]: [
      {
        guide: box.x,
        offset: absPos.x - box.x,
        snap: 'start',
      },
      {
        guide: box.x + box.width / 2,
        offset: absPos.x - box.x - box.width / 2,
        snap: 'center',
      },
      {
        guide: box.x + box.width,
        offset: absPos.x - box.x - box.width,
        snap: 'end',
      },
    ],
    [DIRECTION.HORIZONTAL]: [
      {
        guide: box.y,
        offset: absPos.y - box.y,
        snap: 'start',
      },
      {
        guide: box.y + box.height / 2,
        offset: absPos.y - box.y - box.height / 2,
        snap: 'center',
      },
      {
        guide: box.y + box.height,
        offset: absPos.y - box.y - box.height,
        snap: 'end',
      },
    ],
  };
}

function getSnapLine({ snapLine, offset, snap, diff }, orientation: DIRECTION): SnapLineType {
  return { snapLine, offset, orientation, snap, diff };
}

export function getClosestSnapLines(possibleSnapLines: SnapLinesType, shapeSnappingEdges: ShapeSnappingEdgesType) {
  function getAllSnapLines(direction: DIRECTION) {
    const result: SnapLineType[] = [];
    possibleSnapLines[direction].forEach((snapLine) => {
      shapeSnappingEdges[direction].forEach((snappingEdge) => {
        const diff = Math.abs(snapLine - snappingEdge.guide);
        // If the distance between the line and the shape is less than the threshold, we will consider it a snapping point.
        if (diff > SNAP_THRESHOLD) return;

        const { snap, offset } = snappingEdge;
        result.push({ snapLine, diff, snap, offset });
      });
    });
    return result;
  }

  const resultV = getAllSnapLines(DIRECTION.VERTICAL);
  const resultH = getAllSnapLines(DIRECTION.HORIZONTAL);

  const closestSnapLines: SnapLineType[] = [];

  // find closest vertical and horizontal snappping lines
  const [minV] = resultV.sort((a, b) => a.diff - b.diff);
  const [minH] = resultH.sort((a, b) => a.diff - b.diff);
  if (minV) closestSnapLines.push(getSnapLine(minV, DIRECTION.VERTICAL));
  if (minH) closestSnapLines.push(getSnapLine(minH, DIRECTION.HORIZONTAL));

  return closestSnapLines;
}

export type DrawLinesType = {
  points: number[];
  x: number;
  y: number;
  stroke: string;
  strokeWidth: number;
  name: string;
};

export function getDrawLines(lines: Array<SnapLineType> = []): Record<DIRECTION, Array<DrawLinesType>> {
  if (lines.length > 0) {
    const hLines: Array<DrawLinesType> = [];
    const vLines: Array<DrawLinesType> = [];
    lines.forEach((l) => {
      if (l.orientation === DIRECTION.HORIZONTAL) {
        const line = {
          points: [-6000, 0, 6000, 0],
          x: 0,
          y: l.snapLine,
          ...lineStyle,
        };
        hLines.push(line);
      } else if (l.orientation === DIRECTION.VERTICAL) {
        const line = {
          points: [0, -6000, 0, 6000],
          x: l.snapLine,
          y: 0,
          ...lineStyle,
        };
        vLines.push(line);
      }
    });
    return {
      [DIRECTION.HORIZONTAL]: hLines,
      [DIRECTION.VERTICAL]: vLines,
    };
  }
  return {
    [DIRECTION.HORIZONTAL]: [],
    [DIRECTION.VERTICAL]: [],
  };
}
