import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import { LoadingDuck } from '@premagic/common-ducks';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { hasFeatureSelector } from '../../settings/company/CompanyDataDuck';
import { PERMISSIONS } from '../../crm/users/UserPermissionService';
import { hasPermission } from '../../crm/users/UsersDataDuck';
import {
  addSystemCustomFormField,
  hasSystemCustomFormFieldsSelector,
} from '../../event-custom-form-fields/EventCustomFormFieldsDataDuck';
import ClientWebsiteUserRegistrationFormLayout from './ClientWebsiteUserRegistrationFormLayout';
import { projectDefaultLanguageSelector } from '../../projects/project-meta/ProjectsMetaDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
    projectId: string;
  },
) {
  const { id, projectId } = ownProps;

  return {
    id,
    projectId,
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    hasEventFormFieldsFeature: hasFeatureSelector(
      PricingPlanFeaturesService.PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    )(state),
    hasPermissions: {
      [PERMISSIONS.EVENT_FORM_FIELD]: hasPermission(PERMISSIONS.EVENT_FORM_FIELD)(state),
      [PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT]: hasPermission(PERMISSIONS.EVENT_FORM_FIELD_MANAGEMENT)(state),
    },
    hasSystemCustomFormFields: hasSystemCustomFormFieldsSelector(state),
    isAdding: LoadingDuck.isLoadingSelector(LOADINGS.EVENT_CUSTOM_FORM_FIELDS.ADD)(state),
    langCode: projectDefaultLanguageSelector(projectId)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditForm: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.CLIENT_WEBSITE.SHOW_EDIT_USER_REGISTRATION_PANEL, true));
    },
    addFormField: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.EVENT_CUSTOM_FORM_FIELDS.SHOW_ADD_PANEL, true));
    },
    createCompanyFormFields: (projectId) => {
      dispatch(addSystemCustomFormField(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteUserRegistrationFormLayout);
