import { connect } from 'react-redux';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteUserRegistrationFrom from './ClientWebsiteUserRegistrationFrom';

function mapStateToProps(state, ownProps: { id: string; langCode?: string }) {
  const { id, langCode } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
    langCode,
  };
}

export default connect(mapStateToProps)(ClientWebsiteUserRegistrationFrom);
