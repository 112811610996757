import * as React from 'react';
import ClassNames from 'classnames';
import styles from './circleshape.module.css';
import { COLOR_SHADES } from '../Color/Color';

interface CircleShapeProps {
  shade?: COLOR_SHADES;
  className?: string;
}

export function CircleShape(props: CircleShapeProps): React.ReactElement {
  const { shade = COLOR_SHADES.PINK_LIGHT, className } = props;
  const classes = ClassNames(className, styles['circle-shape'], styles[`circle-shape--shade-${shade}`]);

  return (
    <div className={styles['circle-shape-container']}>
      <div className={classes} />
    </div>
  );
}

export function SpinningGears(props) {
  return (
    <div className={styles['gears-container']}>
      <div className={styles['gear-rotate']} />
      <div className={styles['gear-rotate-left']} />
    </div>
  );
}

export function AnimatedCheckCircle() {
  return (
    <div className={ClassNames(styles['checkmark-wrapper'])}>
      <span className={ClassNames(styles.checkmark)} />
    </div>
  );
}

type CartCounterNotificationCircleProps = {
  children: React.ReactNode;
  xOffset?: string;
  yOffset?: string;
};

export function CartCounterNotificationCircle(props: CartCounterNotificationCircleProps) {
  const { children, xOffset = '0px', yOffset = '0px' } = props;

  return (
    <div
      className={ClassNames(styles['cart-counter-notification'])}
      style={{ transform: `translate(${xOffset}, ${yOffset})` }}
    >
      {children}
    </div>
  );
}
