import { HttpUtils, MediaUtils, PhotoWatermarkUtils, i18n } from '@premagic/utils';
import { API_URLS } from '../APIURLService';
import { FolderFileType } from '../FileService';
import { PRICING_PLAN_FEATURES, PRICING_PLAN_FEATURES_DETAILS } from '../PricingPlanFeaturesService';
import { SPONSOR_FOOTER_GROUP_BY_TYPE } from '../SponsorCreativesService';
import { EVENT_PEOPLE_TYPE } from '../EventAttendeesService';
import { EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE } from '../ClientWebsiteFormService';
import { EventDataType } from '../EventsService';
import { EventPlatformIntegrationConfig } from '../IntegrationService';

export enum FOLDER_THUMBNAIL_VIEWS {
  LANDSCAPE_CARD = 'landscape-card',
  GRID_CARD = 'grid-card',
}

export const FOLDER_THUMBNAIL_VIEW_DETAILS: Record<
  FOLDER_THUMBNAIL_VIEWS,
  {
    value: FOLDER_THUMBNAIL_VIEWS;
    label: string;
  }
> = {
  [FOLDER_THUMBNAIL_VIEWS.LANDSCAPE_CARD]: {
    value: FOLDER_THUMBNAIL_VIEWS.LANDSCAPE_CARD,
    label: 'Landscape Card',
  },
  [FOLDER_THUMBNAIL_VIEWS.GRID_CARD]: {
    value: FOLDER_THUMBNAIL_VIEWS.GRID_CARD,
    label: 'Grid Card',
  },
};

export enum HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
}

export const HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE_DETAILS: Record<
  HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES,
  {
    value: HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
    label: string;
  }
> = {
  [HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT]: {
    value: HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.DEFAULT,
    label: 'Default',
  },
  [HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.SMALL]: {
    value: HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.SMALL,
    label: 'Small',
  },
  [HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.LARGE]: {
    value: HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES.LARGE,
    label: 'Large',
  },
};

export enum HIGHLIGHT_DESIGN_COVER_LAYOUTS {
  DEFAULT = 'default',
  CLASSIC = 'classic',
  FULL_IMAGE = 'full-image',
  BACKGROUND_BLUR = 'background-blur',
}

export enum EVENT_REGISTRATION_STATUS_VALUES {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export const SINGLE_FILE_DOWNLOAD_QUALITY_DETAILS: Record<
  MediaUtils.DOWNLOAD_QUALITY_TYPES,
  {
    title: string;
  }
> = {
  [MediaUtils.DOWNLOAD_QUALITY_TYPES.RESOLUTION_1K]: {
    title: 'HD Resolution',
  },
  [MediaUtils.DOWNLOAD_QUALITY_TYPES.RESOLUTION_2K]: {
    title: '2K Resolution',
  },
  [MediaUtils.DOWNLOAD_QUALITY_TYPES.RESOLUTION_4K]: {
    title: '4K Resolution',
  },
  [MediaUtils.DOWNLOAD_QUALITY_TYPES.RESOLUTION_FULL]: {
    title: 'Uploaded File',
  },
};

export enum PHOTO_DOWNLOAD_PAYMENT_TYPE_ID {
  FREE = 'FREE',
  PAID = 'PAID',
}

export const PHOTO_DOWNLOAD_PAYMENT_DETAILS: Record<
  PHOTO_DOWNLOAD_PAYMENT_TYPE_ID,
  {
    title: string;
  }
> = {
  [PHOTO_DOWNLOAD_PAYMENT_TYPE_ID.FREE]: {
    title: 'Free',
  },
  [PHOTO_DOWNLOAD_PAYMENT_TYPE_ID.PAID]: {
    title: 'Paid',
  },
};

export enum GUEST_GALLERY_VIEWS {
  DAY = 'day',
  FOLDER = 'folder',
  NO_GROUPING = 'none',
}

export const GUEST_GALLERY_VIEWS_DETAILS: Record<
  GUEST_GALLERY_VIEWS,
  {
    value: GUEST_GALLERY_VIEWS;
    label: string;
  }
> = {
  [GUEST_GALLERY_VIEWS.DAY]: {
    value: GUEST_GALLERY_VIEWS.DAY,
    label: 'Date',
  },
  [GUEST_GALLERY_VIEWS.FOLDER]: {
    value: GUEST_GALLERY_VIEWS.FOLDER,
    label: 'Folder',
  },
  [GUEST_GALLERY_VIEWS.NO_GROUPING]: {
    value: GUEST_GALLERY_VIEWS.NO_GROUPING,
    label: 'No Grouping',
  },
};

export enum EVENT_REGISTRATION_TYPES {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export const EVENT_REGISTRATION_TYPES_DETAILS: Record<
  EVENT_REGISTRATION_TYPES,
  {
    id: EVENT_REGISTRATION_TYPES;
    title: string;
  }
> = {
  [EVENT_REGISTRATION_TYPES.PUBLIC]: {
    id: EVENT_REGISTRATION_TYPES.PUBLIC,
    title: 'Public',
  },
  [EVENT_REGISTRATION_TYPES.PRIVATE]: {
    id: EVENT_REGISTRATION_TYPES.PRIVATE,
    title: 'Private',
  },
};

// Pattern module_action_feature
export enum PROJECT_META_TYPES {
  PRIMARY_FONT = 'primary_font',
  SECONDARY_FONT = 'secondary_font',
  MUSIC = 'music_id',
  HIGHLIGHT_FOLDERS_POSITION_ORDER = 'highlight_folders_position_order',
  PROTECT_WITH_PIN = 'protect_with_pin',
  COVER_IMAGE = 'cover_image',
  FOLDER_THUMBNAIL_VIEW_ENABLED = 'folder_thumbnail_view_enabled',
  FOLDER_THUMBNAIL_VIEW = 'folder_thumbnail_view',
  COVER_IMAGE_FOCAL_POINT = 'cover_image_focal_point',
  EVENT_REGISTRATION_STATUS = 'event_registration_status',
  SELECTION_BUDGET = 'selection_budget',
  SIGNATURE_AI_ALBUM = 'signature_ai_album',
  SIGNATURE_AI_ALBUM_SHOW_FACES = 'signature_ai_show_faces', // This will wont fetch the faces, just show the faces icon and allow user to search for faces
  SIGNATURE_AI_SHOW_PEOPLE_TAB = 'signature_ai_show_people_tab', // This will just show the people tab, this is set from the backend, if the album has process AI, then this will be true. This is to handle the condition where the album has been processed by AI and ai delivery is turned off later
  SIGNATURE_AI_ALBUM_EXPRESS_DELIVERY = 'signature_ai_album_express_delivery',
  SIGNATURE_AI_ALBUM_AUTO_OFF_EXPRESS_DELIVERY = 'signature_ai_album_auto_off_for_express_delivery',
  SIGNATURE_AI_ALBUM_ENABLE_DELIVERY_NOTIFICATION = 'signature_ai_album_enable_delivery_notification',
  SIGNATURE_AI_ALBUM_SHOW_ALL_FACES = 'signature_ai_show_unregistered_faces',
  SIGNATURE_AI_ALBUM_DISABLE_WELCOME_REGISTRAION_NOTIFICATION = 'signature_ai_disable_registration_notification', // This will just disable the welcome notification.
  SIGNATURE_AI_ALBUM_DISABLE_AUTO_OFF_EXPRESS_DELIVERY = 'signature_ai_album_disable_auto_off_for_express_delivery',
  SIGNATURE_AI_GROUP_SIMILAR_IMAGES = 'signature_ai_group_similar_images',
  ENABLE_HIGHLIGHT_DOWNLOAD = 'enable_highlight_download',
  ENABLE_SELECTION_DOWNLOAD = 'enable_selection_download',
  BLOCK_GALLERY = 'block_gallery',
  HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY = 'highlight_design__primary_font_family',
  HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY_SOURCE = 'highlight_design__primary_font_family_source',
  HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY = 'highlight_design__secondary_font_family',
  HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY_SOURCE = 'highlight_design__secondary_font_family_source',
  HIGHLIGHT_DESIGN_BACKGROUND_COLOR = 'highlight_design__background_color',
  HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR = 'highlight_design__primary_font_color',
  HIGHLIGHT_DESIGN_COVER_LAYOUT = 'highlight_design__cover_layout',
  HIGHLIGHT_DESIGN_ACCENT_COLOR = 'highlight_design__accent_color',
  HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE = 'highlight_design__gallery_grid_space',
  HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE = 'highlight_design__gallery_thumbnail_size',
  HIGHLIGHT_DESIGN_ENDING_TEXT = 'highlight_design__ending_text',
  HIGHLIGHT_DESIGN_WELCOME_TEXT = 'highlight_design__welcome_text',
  HIGHLIGHT_DESIGN_COVER_HIDE_TITLE = 'highlight_design__cover_hide_title',
  HIGHLIGHT_DESIGN_COVER_HIDE_LOGO = 'highlight_design__cover_hide_logo',
  ALBUM_COVER_TITLE = 'album_cover_title',
  SINGLE_FILE_DOWNLOAD_QUALITY = 'single_file_download_quality',
  WATERMARK_POSITION = 'watermark__position',
  WATERMARK_SIZE = 'watermark__size',
  WATERMARK_OPACITY = 'watermark__opacity',
  WATERMARK_FILE = 'watermark__file',
  SELECTION_ENABLE_WATERMARK = 'selection__enable_watermark',
  HIGHLIGHT_ENABLE_WATERMARK = 'highlight__enable_watermark',
  SINGLE_PHOTO_DOWNLOAD_PRICE = 'single_photo_download_price',
  STORE_PRICE_CURRENCY_CODE = 'store_price_currency_code',
  WATERMARK = 'watermark',
  SOCIAL_SHARE_MESSAGES = 'social_share_messages',
  SPONSOR_SECTION_TITLE = 'sponsor__section_title',
  EVENT_REGISTRATION_HIDE_EMAIL_FIELD = 'event_registration_hide_email_field',
  EVENT_REGISTRATION_REQUEST_TO_FILL_IN_PERSONAL_DETAILS = 'event_registration__request_to_fill_in_personal_details',
  EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED = 'event_registration__email_field_mark_as_required',
  MARKETING_GENERATE_LEADS = 'marketing__generate_lead',
  ALLOW_GUEST_TO_BULK_DOWNLOAD = 'allow_guest_to_bulk_download',
  DEFAULT_LANG = 'default_lang',
  IS_GUEST_GALLERY_DOWNLOAD_PAID = 'is_guest_gallery_download_paid',
  SPONSOR_FOOTER_GROUP_BY = 'sponsor__footer_group_by',
  PEOPLE_NETWORKING = 'people_networking',
  EVENT_REGISTRATION_SEND_NOTIFICATION = 'event_registration_send_notification',
  EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED = 'event_registration_send_notification_required',
  EVENT_REGISTRATION_TYPE = 'event_registration_type',
  EVENT_REGISTRATION_ALLOWED_EMAIL_DOMAINS = 'event_registration_allowed_email_domains',
  EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED = 'event_settings__registration_module_enabled',
  EVENT_SETTINGS_BADGES_ENABLED = 'event_settings__badges_enabled',
  EVENT_SETTINGS_FACE_CHECKIN_ENABLED = 'event_settings__face_checkin_enabled',
  EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED = 'event_settings__qr_code_checkin_enabled',
  EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED = 'event_marketing_poster_module_enabled',
  EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION = 'event_registration_send_profile_completion_notification',
  EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION = 'event_registration_send_create_poster_notification',
  EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE = 'event_registration_enable_approval_workflow',
  EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD = 'event_registration_enable_photo_upload',
  AVATAR_STUDIO_ENABLE = 'avatar_studio_enabled',
  MAGAZINE_CREATOR_ENABLE = 'magazine_creator_enabled',
  EVENT_DATA = 'event_data',
  EVENT_PLATFORM_INTEGRATION_ENABLED = 'event_platform_integration_enabled',
  EVENT_PLATFORM_INTEGRATION_CONFIG = 'event_platform_integration_config',
  EVENT_EXHIBITOR = 'event_exhibitor',
  AI_CONTENT_GENERATOR_FOR_ATTENDEE = 'event_settings__ai_content_generator_for_attendee_enabled',
  EVENT_LOGO_FOR_WHITE_BG = 'event_logo_for_white_bg',
  EVENT_LOGO_FOR_BLACK_BG = 'event_logo_for_black_bg',
  EVENT_LOGO_HIDE = 'event_logo_hide',
  MAP_FILES_TO_ATTENDEE_GALLERY = 'signature_ai_search_using_key_people',
}

export type ProjectMetaWatermarkType = Record<
  string,
  {
    position?: PhotoWatermarkUtils.WATERMARK_POSITIONS;
    x?: string;
    y?: string;
    size?: number;
    opacity?: number;
    file?: string;
    remove_on_download?: boolean;
  }
>;

export enum SOCIAL_SHARE_MESSAGE_SCOPE {
  GALLERY = 'signature-album',
  POSTER = 'i-am-attending',
}
export type SocialShareMessageType = {
  id: string;
  order: number;
  message: string;
  hashtags: Array<string>;
  scope: SOCIAL_SHARE_MESSAGE_SCOPE;
  people_type?: Array<EVENT_PEOPLE_TYPE>;
};

export type SocialShareMessagesType = Record<string, SocialShareMessageType>;

export type ProjectMetaType = {
  [PROJECT_META_TYPES.PRIMARY_FONT]?: string;
  [PROJECT_META_TYPES.SECONDARY_FONT]?: string;
  [PROJECT_META_TYPES.MUSIC]?: number;
  [PROJECT_META_TYPES.HIGHLIGHT_FOLDERS_POSITION_ORDER]?: Array<string>;
  [PROJECT_META_TYPES.PROTECT_WITH_PIN]?: boolean;
  [PROJECT_META_TYPES.COVER_IMAGE]?: FolderFileType | null;
  [PROJECT_META_TYPES.COVER_IMAGE_FOCAL_POINT]?: {
    x: number;
    y: number;
  };
  [PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED]?: boolean;
  [PROJECT_META_TYPES.FOLDER_THUMBNAIL_VIEW]?: FOLDER_THUMBNAIL_VIEWS;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_STATUS]?: EVENT_REGISTRATION_STATUS_VALUES;
  [PROJECT_META_TYPES.SELECTION_BUDGET]?: number;
  [PROJECT_META_TYPES.SIGNATURE_AI_ALBUM]?: boolean;
  [PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_EXPRESS_DELIVERY]?: boolean;
  [PROJECT_META_TYPES.ENABLE_HIGHLIGHT_DOWNLOAD]?: boolean;
  [PROJECT_META_TYPES.ENABLE_SELECTION_DOWNLOAD]?: boolean;
  [PROJECT_META_TYPES.BLOCK_GALLERY]?: boolean;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT]?: HIGHLIGHT_DESIGN_COVER_LAYOUTS;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE]?: number;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE]?: HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZES;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT]?: string;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_TITLE]?: boolean;
  [PROJECT_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_LOGO]?: boolean;
  [PROJECT_META_TYPES.ALBUM_COVER_TITLE]?: string;
  [PROJECT_META_TYPES.SINGLE_FILE_DOWNLOAD_QUALITY]?: MediaUtils.DOWNLOAD_QUALITY_TYPES;
  [PROJECT_META_TYPES.SELECTION_ENABLE_WATERMARK]?: boolean;
  [PROJECT_META_TYPES.HIGHLIGHT_ENABLE_WATERMARK]?: boolean;
  [PROJECT_META_TYPES.WATERMARK_POSITION]?: PhotoWatermarkUtils.WATERMARK_POSITIONS;
  [PROJECT_META_TYPES.WATERMARK_SIZE]?: PhotoWatermarkUtils.WATERMARK_SIZES | number;
  [PROJECT_META_TYPES.WATERMARK_OPACITY]?: number;
  [PROJECT_META_TYPES.WATERMARK_FILE]?: string;
  [PROJECT_META_TYPES.SINGLE_PHOTO_DOWNLOAD_PRICE]?: number;
  [PROJECT_META_TYPES.STORE_PRICE_CURRENCY_CODE]?: string;
  [PROJECT_META_TYPES.SIGNATURE_AI_ALBUM_ENABLE_DELIVERY_NOTIFICATION]?: boolean;
  [PROJECT_META_TYPES.WATERMARK]?: ProjectMetaWatermarkType;
  [PROJECT_META_TYPES.SOCIAL_SHARE_MESSAGES]?: SocialShareMessagesType;
  [PROJECT_META_TYPES.SPONSOR_SECTION_TITLE]?: string;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD]?: boolean;
  [PROJECT_META_TYPES.ALLOW_GUEST_TO_BULK_DOWNLOAD]?: boolean;
  [PROJECT_META_TYPES.DEFAULT_LANG]?: i18n.LANGUAGE_CODE;
  [PROJECT_META_TYPES.IS_GUEST_GALLERY_DOWNLOAD_PAID]?: boolean;
  [PROJECT_META_TYPES.SPONSOR_FOOTER_GROUP_BY]?: SPONSOR_FOOTER_GROUP_BY_TYPE;
  [PROJECT_META_TYPES.PEOPLE_NETWORKING]?: boolean;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION]?: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_NOTIFICATION_REQUIRED]?: EVENT_REGISTRATION_SEND_NOTIFICATION_TYPE;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_TYPE]?: EVENT_REGISTRATION_TYPES;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_ALLOWED_EMAIL_DOMAINS]?: Array<string>;
  [PROJECT_META_TYPES.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED]?: boolean;
  [PROJECT_META_TYPES.EVENT_SETTINGS_BADGES_ENABLED]?: boolean;
  [PROJECT_META_TYPES.EVENT_SETTINGS_FACE_CHECKIN_ENABLED]?: boolean;
  [PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED]?: boolean;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION]?: boolean;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION]?: boolean;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE]?: boolean;
  [PROJECT_META_TYPES.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD]?: boolean;
  [PROJECT_META_TYPES.AVATAR_STUDIO_ENABLE]?: boolean;
  [PROJECT_META_TYPES.MAGAZINE_CREATOR_ENABLE]?: boolean;
  [PROJECT_META_TYPES.EVENT_DATA]?: EventDataType;
  [PROJECT_META_TYPES.EVENT_PLATFORM_INTEGRATION_ENABLED]?: boolean;
  [PROJECT_META_TYPES.EVENT_PLATFORM_INTEGRATION_CONFIG]?: EventPlatformIntegrationConfig;
  [PROJECT_META_TYPES.MAP_FILES_TO_ATTENDEE_GALLERY]?: boolean;
};

export enum PROJECT_META_TOGGLE_ITEMS {
  PROTECT_WITH_PIN = 'protect_with_pin',
  FOLDER_THUMBNAIL_VIEW_ENABLED = 'folder_thumbnail_view_enabled',
  ENABLE_HIGHLIGHT_DOWNLOAD = 'enable_highlight_download',
  ENABLE_SELECTION_DOWNLOAD = 'enable_selection_download',
  ALLOW_GUEST_TO_BULK_DOWNLOAD = 'allow_guest_to_bulk_download',
  SIGNATURE_AI_ALBUM = 'signature_ai_album',
  SIGNATURE_AI_ALBUM_SHOW_ALL_FACES = 'signature_ai_show_unregistered_faces',
  BLOCK_GALLERY = 'block_gallery',
  GUEST_GALLERY = 'guest_gallery',
  SELECTION_ENABLE_WATERMARK = 'selection__enable_watermark',
  HIGHLIGHT_ENABLE_WATERMARK = 'highlight__enable_watermark',
  EVENT_REGISTRATION_HIDE_EMAIL_FIELD = PROJECT_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD,
  EVENT_REGISTRATION_REQUEST_TO_FILL_IN_PERSONAL_DETAILS = PROJECT_META_TYPES.EVENT_REGISTRATION_REQUEST_TO_FILL_IN_PERSONAL_DETAILS,
  EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED = PROJECT_META_TYPES.EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED,
  MARKETING_GENERATE_LEADS = PROJECT_META_TYPES.MARKETING_GENERATE_LEADS,
  IS_GUEST_GALLERY_DOWNLOAD_PAID = PROJECT_META_TYPES.IS_GUEST_GALLERY_DOWNLOAD_PAID,
  SIGNATURE_AI_GROUP_SIMILAR_IMAGES = PROJECT_META_TYPES.SIGNATURE_AI_GROUP_SIMILAR_IMAGES,
  PEOPLE_NETWORKING = PROJECT_META_TYPES.PEOPLE_NETWORKING,
  EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED = PROJECT_META_TYPES.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED,
  EVENT_SETTINGS_BADGES_ENABLED = PROJECT_META_TYPES.EVENT_SETTINGS_BADGES_ENABLED,
  EVENT_SETTINGS_FACE_CHECKIN_ENABLED = PROJECT_META_TYPES.EVENT_SETTINGS_FACE_CHECKIN_ENABLED,
  EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED = PROJECT_META_TYPES.EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED,
  EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED = PROJECT_META_TYPES.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED,
  EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION = PROJECT_META_TYPES.EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION,
  EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION = PROJECT_META_TYPES.EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION,
  EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE = PROJECT_META_TYPES.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE,
  AVATAR_STUDIO_ENABLE = PROJECT_META_TYPES.AVATAR_STUDIO_ENABLE,
  MAGAZINE_CREATOR_ENABLE = PROJECT_META_TYPES.MAGAZINE_CREATOR_ENABLE,
  EVENT_EXHIBITOR = PROJECT_META_TYPES.EVENT_EXHIBITOR,
  AI_CONTENT_GENERATOR_FOR_ATTENDEE = PROJECT_META_TYPES.AI_CONTENT_GENERATOR_FOR_ATTENDEE,
  EVENT_LOGO_HIDE = PROJECT_META_TYPES.EVENT_LOGO_HIDE,
  EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD = PROJECT_META_TYPES.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD,
  MAP_FILES_TO_ATTENDEE_GALLERY = PROJECT_META_TYPES.MAP_FILES_TO_ATTENDEE_GALLERY,
}

export const PROJECT_META_TOGGLE_ITEMS_DETAILS: Record<
  PROJECT_META_TOGGLE_ITEMS,
  {
    feature: PRICING_PLAN_FEATURES;
    label: string;
    info?: string;
    key: PROJECT_META_TOGGLE_ITEMS;
    icon?: string;
    invertValue?: boolean;
    isStaffToggleable?: boolean; // If its staff toggleable, then we only the staff can toggle. But clients will be able to toggle if the feature is enable on their pricing plan.
    handleUndefinedAsTrue?: boolean;
  }
> = {
  [PROJECT_META_TOGGLE_ITEMS.PROTECT_WITH_PIN]: {
    key: PROJECT_META_TOGGLE_ITEMS.PROTECT_WITH_PIN,
    feature: PRICING_PLAN_FEATURES.GALLERY_PASSWORD_PROTECTION,
    label: 'Require PIN to view the Album',
    icon: 'lock',
  },
  [PROJECT_META_TOGGLE_ITEMS.FOLDER_THUMBNAIL_VIEW_ENABLED]: {
    key: PROJECT_META_TOGGLE_ITEMS.FOLDER_THUMBNAIL_VIEW_ENABLED,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_FOLDER_THUMBNAILS,
    label: 'Show Folder thumbnails',
    icon: 'image',
  },
  [PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD]: {
    key: PROJECT_META_TOGGLE_ITEMS.ENABLE_HIGHLIGHT_DOWNLOAD,
    feature: PRICING_PLAN_FEATURES.PROJECT_LEVEL_DOWNLOAD,
    label: 'Allow client to Download photos',
    icon: 'download',
  },
  [PROJECT_META_TOGGLE_ITEMS.ALLOW_GUEST_TO_BULK_DOWNLOAD]: {
    key: PROJECT_META_TOGGLE_ITEMS.ALLOW_GUEST_TO_BULK_DOWNLOAD,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY_DOWNLOAD,
    label: 'Allow Bulk Download photos in Guest Gallery',
    isStaffToggleable: true,
    icon: 'download_cloud',
  },
  [PROJECT_META_TOGGLE_ITEMS.ENABLE_SELECTION_DOWNLOAD]: {
    key: PROJECT_META_TOGGLE_ITEMS.ENABLE_SELECTION_DOWNLOAD,
    feature: PRICING_PLAN_FEATURES.PROJECT_LEVEL_DOWNLOAD,
    label: 'Allow client to Download photos',
    icon: 'download',
  },
  [PROJECT_META_TOGGLE_ITEMS.BLOCK_GALLERY]: {
    key: PROJECT_META_TOGGLE_ITEMS.BLOCK_GALLERY,
    feature: PRICING_PLAN_FEATURES.BLOCK_GALLERY,
    label: 'Block Album',
    icon: 'slash',
  },
  [PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_ALBUM]: {
    key: PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_ALBUM,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_FACES,
    label: 'AI Delivery',
    info: '<p>Premagic will intelligently </strong>share photos</strong> of registered guest via WhatsApp and email.</p>',
    icon: 'monitor',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES]: {
    key: PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_ALBUM_SHOW_ALL_FACES,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_FACES,
    label: 'Show all people faces',
    info: '<p>Premagic will <strong>identify all</strong> the guests and show their faces on People tab</p>',
    icon: 'smile',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.GUEST_GALLERY]: {
    key: PROJECT_META_TOGGLE_ITEMS.GUEST_GALLERY,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY,
    label: PRICING_PLAN_FEATURES_DETAILS[PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GUEST_GALLERY].name,
    info: `<p>Guests will receive a signature album with <strong>only their photos</strong> on it.</p>
       <ul>
          <li>Guests will not be able to view all the photos in the album</li>
          <li>Will not be able to undo if the link is already shared with guests</li>
       </ul>`,
    icon: 'user_x',
  },
  [PROJECT_META_TOGGLE_ITEMS.SELECTION_ENABLE_WATERMARK]: {
    key: PROJECT_META_TOGGLE_ITEMS.SELECTION_ENABLE_WATERMARK,
    feature: PRICING_PLAN_FEATURES.WATERMARK,
    label: 'Selection folder',
  },
  [PROJECT_META_TOGGLE_ITEMS.HIGHLIGHT_ENABLE_WATERMARK]: {
    key: PROJECT_META_TOGGLE_ITEMS.HIGHLIGHT_ENABLE_WATERMARK,
    feature: PRICING_PLAN_FEATURES.WATERMARK,
    label: 'Enable Watermark',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_HIDE_EMAIL_FIELD]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_HIDE_EMAIL_FIELD,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    invertValue: true,
    label: 'Ask for email address on event registration',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_EMAIL_FIELD_MARK_AS_REQUIRED,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    label: 'Mark email address as required',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_REQUEST_TO_FILL_IN_PERSONAL_DETAILS]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_REQUEST_TO_FILL_IN_PERSONAL_DETAILS,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    label: 'Request to fill in personal details',
  },
  [PROJECT_META_TOGGLE_ITEMS.MARKETING_GENERATE_LEADS]: {
    key: PROJECT_META_TOGGLE_ITEMS.MARKETING_GENERATE_LEADS,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_FACES,
    label: 'Generate Leads for Premagic | Referral Button',
  },
  [PROJECT_META_TOGGLE_ITEMS.IS_GUEST_GALLERY_DOWNLOAD_PAID]: {
    key: PROJECT_META_TOGGLE_ITEMS.IS_GUEST_GALLERY_DOWNLOAD_PAID,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_FACES,
    label: 'Enable Guest Gallery Paid Downloads',
  },
  [PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_GROUP_SIMILAR_IMAGES]: {
    key: PROJECT_META_TOGGLE_ITEMS.SIGNATURE_AI_GROUP_SIMILAR_IMAGES,
    feature: PRICING_PLAN_FEATURES.SIGNATURE_ALBUM_GROUP_SIMILAR_IMAGES,
    label: 'Enable AI group similar photos',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.PEOPLE_NETWORKING]: {
    key: PROJECT_META_TOGGLE_ITEMS.PEOPLE_NETWORKING,
    feature: PRICING_PLAN_FEATURES.EVENT_PEOPLE_NETWORKING,
    label: 'Networking Module',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_REGISTRATION_MODULE_ENABLED,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    label: 'Event Registration',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_BADGES_ENABLED]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_BADGES_ENABLED,
    feature: PRICING_PLAN_FEATURES.EVENT_ATTENDEES_BADGES,
    label: 'Event Badges',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_FACE_CHECKIN_ENABLED]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_FACE_CHECKIN_ENABLED,
    feature: PRICING_PLAN_FEATURES.EVENT_FACE_CHECKIN,
    label: 'Check-In using attendee photo',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_QR_CODE_CHECKIN_ENABLED,
    feature: PRICING_PLAN_FEATURES.EVENT_FACE_CHECKIN,
    label: 'Check-In using QR Code',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_SETTINGS_POSTER_ADVOCACY_ENABLED,
    feature: PRICING_PLAN_FEATURES.EVENT_MARKETING,
    label: 'Poster Advocacy',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_SEND_CREATE_POSTER_NOTIFICATION,
    feature: PRICING_PLAN_FEATURES.EVENT_MARKETING,
    info: 'If enabled, attendee will receive a notification to create their advocacy poster after registration.',
    label: 'Send Advocacy poster notification after registration',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_APPROVAL_WORKFLOW_ENABLE,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    info: 'If enabled, attendee need to be manually approved before receiving their tickets.',
    label: 'Require approval after registration',
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_PROFILE_COMPLETION_NOTIFICATION,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    info: 'If enabled, attendee will receive a notification to complete their profile after registration.',
    label: 'Send profile completion notification for incomplete registration',
  },
  [PROJECT_META_TOGGLE_ITEMS.AVATAR_STUDIO_ENABLE]: {
    key: PROJECT_META_TOGGLE_ITEMS.AVATAR_STUDIO_ENABLE,
    feature: PRICING_PLAN_FEATURES.AVATAR_STUDIO,
    label: 'Avatar Studio',
    info: '<p>Attendees will see a “Create Your Own Avatar” in their gallery and they will be able to create variety of avatar styles.</p>',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.MAGAZINE_CREATOR_ENABLE]: {
    key: PROJECT_META_TOGGLE_ITEMS.MAGAZINE_CREATOR_ENABLE,
    feature: PRICING_PLAN_FEATURES.MAGAZINE_CREATOR,
    label: 'Magazine Creator',
    info: '<p>Create Personalized magazine for your attendees</p>',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_EXHIBITOR]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_EXHIBITOR,
    feature: PRICING_PLAN_FEATURES.EVENT_EXHIBITOR,
    label: 'Event Exhibitor',
    info: '<p>Create and manage event exhibitors.</p>',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.AI_CONTENT_GENERATOR_FOR_ATTENDEE]: {
    key: PROJECT_META_TOGGLE_ITEMS.AI_CONTENT_GENERATOR_FOR_ATTENDEE,
    feature: PRICING_PLAN_FEATURES.AI_CONTENT_CREATION,
    label: 'AI Content Generator for Attendee',
    info: '<p>Attendees can create social share messages</p>',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_LOGO_HIDE]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_LOGO_HIDE,
    feature: PRICING_PLAN_FEATURES.CUSTOM_BRAND_FOR_EVENT,
    label: 'Hide Event Logo',
    info: '<p>Hide event logo from gallery</p>',
    isStaffToggleable: true,
  },
  [PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD]: {
    key: PROJECT_META_TOGGLE_ITEMS.EVENT_REGISTRATION_ENABLE_PHOTO_UPLOAD,
    feature: PRICING_PLAN_FEATURES.EVENT_REGISTRATION_CUSTOM_FORM_FIELDS,
    label: 'Allow user to upload their photo',
  },
  [PROJECT_META_TOGGLE_ITEMS.MAP_FILES_TO_ATTENDEE_GALLERY]: {
    key: PROJECT_META_TOGGLE_ITEMS.MAP_FILES_TO_ATTENDEE_GALLERY,
    feature: PRICING_PLAN_FEATURES.TAG_FILES_TO_ATTENDEE_GALLERY,
    label: 'Assign Photos/Videos to Attendee Galleries',
    info: '<p>Tag key attendees in photos/videos to automatically add them to their personalized galleries</p>',
    isStaffToggleable: true,
  },
};

export function fetchProjectMeta(projectId: string): Promise<ProjectMetaType> {
  return HttpUtils.get(API_URLS.PROJECTS.META(projectId))
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function updateProjectMeta(projectId: string, data: ProjectMetaType): Promise<ProjectMetaType> {
  return HttpUtils.post(API_URLS.PROJECTS.META(projectId), data)
    .then((response) => response.data)
    .catch((response) => Promise.reject(response.data));
}

export function uploadProjectCoverPhoto(projectId: string, coverPhoto: FormData): Promise<any> {
  const URL = API_URLS.PROJECTS.META(projectId);
  return HttpUtils.post(URL, coverPhoto).then((response) => response.data);
}
