import { connect } from 'react-redux';

import { LOADINGS } from '../../../../../../../common/Constants';
import { clearErrorState, errorObjectForTypeSelector } from '../../../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { getFaceIdFromPhotos, photoToFaceIdSelector } from '../../../../images/PeopleFacesDataDuck';
import DAMLibraryPeopleFilterByFileUploadSelector from './DAMLibraryPeopleFilterByFileUploadSelector';

function mapStateToProps(
  state,
  ownProps: { value: Array<string>; projectId: string; onChange: (newFilterValue: Array<string>) => void },
) {
  const { value, projectId, onChange } = ownProps;

  return {
    value,
    isLoading: isLoadingSelector(LOADINGS.PEOPLE_FACES.FIND_FACE_ID)(state),
    errors: errorObjectForTypeSelector(LOADINGS.PEOPLE_FACES.FIND_FACE_ID)(state),
    photoToFaceId: photoToFaceIdSelector(state),
    projectId,
    onChange,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFaceId: (projectId, blobURL) => {
      dispatch(getFaceIdFromPhotos(dispatch, projectId, blobURL));
    },
    clearError: () => {
      dispatch(clearErrorState(dispatch, LOADINGS.PEOPLE_FACES.FIND_FACE_ID));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryPeopleFilterByFileUploadSelector);
