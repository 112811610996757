import { FileService, SponsorCreativesService } from '@premagic/core';
import { BUTTON_STYLES, Button, FileContainer, FormGroup, FormLabel, Row, Space } from '@premagic/myne';
import { FolderGalleryImage } from '../../folders/gallery/FolderGallery';

type Props = {
  name: string;
  defaultValue?: string;
  openFilePicker: (name: string, placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT) => void;
  modalOptions?: Record<
    string,
    {
      name: string;
      fileId?: string;
    }
  >;
  files: Record<string, FileService.FolderFileType>;
  placement: SponsorCreativesService.SPONSORS_CREATIVES_PLACEMENT;
};

export default function SponsorFilesSelector(props: Props): JSX.Element | null {
  const { openFilePicker, modalOptions = {}, name, defaultValue, files, placement } = props;

  const selectedFileId = modalOptions?.[name]?.fileId || defaultValue;
  const selectedFile = selectedFileId ? files[selectedFileId] : undefined;

  return (
    <FormGroup>
      <FormLabel>Creative File</FormLabel>
      <Row vcenter>
        <FileContainer status={selectedFile?.asset_upload_status}>
          {selectedFile ? (
            <FolderGalleryImage fileId={selectedFile.id} file={selectedFile} />
          ) : (
            <Button onClick={() => openFilePicker(name, placement)} style={BUTTON_STYLES.LINK}>
              {selectedFile ? 'Change' : 'Select a file'}
            </Button>
          )}
        </FileContainer>
        <Space />
        {selectedFile && (
          <div>
            <Button onClick={() => openFilePicker(name, placement)} style={BUTTON_STYLES.LINK}>
              Change
            </Button>
          </div>
        )}
        <input type="hidden" name={name} value={selectedFileId} readOnly />
      </Row>
    </FormGroup>
  );
}
