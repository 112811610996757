import { ACCOUNT_FINANCIALS_FILTER_KEYS } from './AccountFinancialsFilterTypes';

export const ACCOUNT_FINANCIALS_FILTERS_DETAILS = {
  [ACCOUNT_FINANCIALS_FILTER_KEYS.START_DATE_GTE]: {
    key: ACCOUNT_FINANCIALS_FILTER_KEYS.START_DATE_GTE,
    label: 'Date',
  },
  [ACCOUNT_FINANCIALS_FILTER_KEYS.SLICE]: {
    key: ACCOUNT_FINANCIALS_FILTER_KEYS.SLICE,
    label: 'Slice',
  },
};
