import { connect } from 'react-redux';
import EventPosterPreviewHeader from './EventPosterPreviewHeader';
import { eventPostersSelector } from '../../crm/event-posters/EventPostersDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
    projectId: string;
    posterId: string;
    type: string;
  },
) {
  const { eventId, projectId, posterId, type } = ownProps;

  return {
    eventId,
    projectId,
    posterId,
    type,
    poster: posterId ? eventPostersSelector(state)[posterId] : undefined,
  };
}

export default connect(mapStateToProps)(EventPosterPreviewHeader);
