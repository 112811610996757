import { connect } from 'react-redux';
import { requestingUserIdSelector } from '../../users/UsersDataDuck';
import { eventAttendeesSelector } from './EventAttendeesDataDuck';
import { EventAttendeesNames } from './EventAttendeesNames';

function mapStateToProps(
  state,
  ownProps: {
    attendeeIds?: Array<string>;
    avatarStyle?: boolean;
  },
) {
  const { attendeeIds, avatarStyle } = ownProps;
  return {
    attendeeIds,
    avatarStyle,
    attendees: eventAttendeesSelector(state),
    requestingUserId: requestingUserIdSelector(state),
  };
}
export default connect(mapStateToProps)(EventAttendeesNames);
