import React from 'react';
import { Space, Text, TEXT_BOLDNESS } from '@premagic/myne';
import { CompanyLeadService } from '@premagic/core';
import { StringUtils } from '@premagic/utils';

export default function LeadChannelAndSourceValue(props: {
  source?: CompanyLeadService.LEAD_SOURCE | string;
  channel?: CompanyLeadService.LEAD_CHANNELS;
}): JSX.Element {
  const { source, channel } = props;
  if (!source || StringUtils.isEmptyString(source)) return <Text muted>(Not set)</Text>;

  const sourceTitle = CompanyLeadService.LEAD_SOURCE_DETAILS[source]?.title || source;
  const channelTitle = channel && CompanyLeadService.LEAD_CHANNEL_DETAILS[channel]?.title;

  return (
    <div>
      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>{sourceTitle}</Text>
      {channel && channel !== CompanyLeadService.LEAD_CHANNELS.CRM && (
        <>
          <Space size={1} />
          <Text>via</Text>
          <Space size={1} />
          <Text>{channelTitle}</Text>
        </>
      )}
    </div>
  );
}
