import { connect } from 'react-redux';
import { occasionsForUsersWithOutActiveOccasionSelector, resetUserOccasionsData } from '../OccasionsDataDuck';
import CRMOccasionUsersBusyAlert from './CRMOccasionUsersBusyAlert';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { activeAccountIdSelector } from '../../../acccount/AccountDataDuck';

function mapStateToProps(state, ownProps: { occasionId?: string }) {
  const { occasionId } = ownProps;
  return {
    accountId: activeAccountIdSelector(state),
    occasionForUsers: occasionsForUsersWithOutActiveOccasionSelector(occasionId)(state),
    isLoading: isLoadingSelector(LOADINGS.OCCASIONS.USER_OCCASIONS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetUserOccasionsData: () => {
      dispatch(resetUserOccasionsData(dispatch));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CRMOccasionUsersBusyAlert);
