import { useState, useEffect } from 'react';

// To test the behavior of this hook, you can use the following example:
// https://codesandbox.io/p/sandbox/3t5wyk?file=/src/App.tsx:137,27
// https://codesandbox.io/p/sandbox/reverent-wu-dmmjcx?file=/src/index.js:16,19

function enableStream(requestedMedia): Promise<MediaStream> | null {
  try {
    return navigator.mediaDevices.getUserMedia(requestedMedia);
  } catch (err) {
    return null;
    // Media Device function is not available
  }
}

function getVideoInputs(): Promise<InputDeviceInfo[]> {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.filter((device) => device.kind === 'videoinput') as InputDeviceInfo[]);
}

export function useUserMedia(
  requestedMedia: MediaStreamConstraints,
): [media?: MediaStream, videoInputs?: InputDeviceInfo[], error?: any] {
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [videoInputs, setVideoInputs] = useState<InputDeviceInfo[]>();
  const [error, setError] = useState<{ name: string }>();

  useEffect(() => {
    // Stop the current media stream tracks if they exist
    // mediaStream?.getTracks().forEach((track) => {
    //   track.stop();
    // });
    enableStream(requestedMedia)
      ?.then((stream) => {
        setMediaStream(stream);
        getVideoInputs().then(setVideoInputs);
      })
      .catch((err) => {
        setError(err);
      });
    // We can get the video inputs only after the stream is enabled

    return undefined;
  }, [requestedMedia]);

  // Close the stream on component unmount
  useEffect(() => {
    return function cleanup() {
      mediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
    return undefined;
  }, [mediaStream, requestedMedia]);

  return [mediaStream, videoInputs, error?.name || error];
}
