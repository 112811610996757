import { HttpUtils, BrowserUrlUtils, LocalCacheUtils } from '@premagic/utils';
import { APIURLService, GlobalVariableService } from '@premagic/core';
import { toastMessage } from '../../reducers/ToastStore';

const LOCAL_STORE_KEY = 'active_account_id';
export function setActiveAccountIdInLocalCache(accountId: string): void {
  LocalCacheUtils.setCache(LOCAL_STORE_KEY, accountId);
}

export function getActiveAccountIdInLocalCache(): string {
  return LocalCacheUtils.getCache(LOCAL_STORE_KEY) as string;
}

export function getAccountIdToSwitchFromURL(): string | undefined {
  const queryObject = BrowserUrlUtils.getQueryObjectFromUrl();
  // Remove the qs from url
  if (queryObject.accountId) {
    BrowserUrlUtils.removeAllQueryString();
  }
  return queryObject.accountId as string | undefined;
}

export function setActiveAccountIdCache(accountId?: string): void {
  GlobalVariableService.setGlobalVariableForPM({
    ACCOUNT_ID: accountId,
  });
  if (accountId) setActiveAccountIdInLocalCache(accountId);
}

// export function getActiveAccountIdCache(): string | undefined {
//   return GlobalVariableService.getAccountId();
// }

function clearAccountIdCache(): void {
  setActiveAccountIdCache(undefined);
  LocalCacheUtils.removeCache(LOCAL_STORE_KEY);
}

// Similar to FolderService.FOLDER_TYPES
export enum ACCOUNT_COMMUNICATION_TEMPLATES {
  SELECTION = 'SELECTION',
  HIGHLIGHT = 'HIGHLIGHT',
  CLIENT_WEBSITE = 'SAVE_THE_DATE',
  PROPOSAL = 'PROPOSAL',
}

export type AccountEmailTemplatesType = Record<
  ACCOUNT_COMMUNICATION_TEMPLATES,
  {
    subject: string;
    body: string;
  }
>;

export type AccountType = {
  id: string;
  name: string;
  tax_id: string;
  tax_name: string;
  tax_percentage: number;
  customer_id: string;
  currency: string;
  timezone: string;
  billing_address_street_1: string;
  zipcode: string;
  city: string;
  state: string;
  country: string;
  created_at: string;
  updated_at: string;
  email_templates: AccountEmailTemplatesType;
  default_invoice_note: string;
};

const APIS = APIURLService.API_URLS.ACCOUNT;
export function fetchAccounts(): Promise<{ results: Array<AccountType> }> {
  return HttpUtils.get(APIS.ROOT, {}, false)
    .then((response) => response.data)
    .catch((response) => {
      clearAccountIdCache();
      toastMessage('error', response.data.details);
      return Promise.reject(response.data);
    });
}

export function saveAccount(id: string, data: FormData): Promise<AccountType> {
  return HttpUtils.patch(APIS.DETAILS(id), data, true)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}
