import formSerialize from 'form-serialize';
import { isEmpty, isNumber, isFunction, reduce, isString } from 'lodash';

export const TEXT = {
  REQUIRED: 'This is required',
};

export function getFormDataFromForm($form: HTMLFormElement): Record<string, unknown> {
  const formData = new FormData($form);
  const result = {};

  formData.forEach((value, key) => {
    const input = $form.querySelector(`[name="${key}"]`) as HTMLInputElement;

    // Handle checkbox inputs
    if (input?.type === 'checkbox') {
      result[key] = input.checked ? 'on' : '';
      return;
    }

    // If this key doesn't exist yet in the result object, set it directly
    // This handles the first occurrence of a form field
    if (!Reflect.has(result, key)) {
      result[key] = value;
      return;
    }

    // Handle multiple values for same key (e.g. multi-select)
    if (!Array.isArray(result[key])) {
      result[key] = [result[key]];
    }
    result[key].push(value);
  });
  return result;
}

export const FORM_INPUT_PATTERNS = {
  EMAIL: '/^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/',
  PHONE: /^([0|+[0-9]{1,5})?([0-9]{9, 11})$/, // use isValidPhoneNumber
};

// GetFormData
export type FormSpecTypeType = 'date' | 'number' | 'boolean' | 'text';
type FormSpecInputType = {
  validator?: ((inputValue: string) => boolean) | 'required';
  message?: ((inputValue: string) => string | false) | string;
  relatedField?: string;
  type?: FormSpecTypeType;
};

export type FormSpecType = Record<string, FormSpecInputType>;

export function isValidString(value?: string): boolean {
  return !!value && value.trim().length > 0;
}

export function isValidNumber(value?: number): boolean {
  return value !== undefined && value !== null;
}

export function getErrorOnFormData(
  formData: Record<string, unknown>,
  formSpec: Record<
    string,
    {
      required?: boolean;
      type?: 'string' | 'number';
    }
  >,
): Record<string, string> | null {
  const errors = Object.entries(formSpec).reduce((result, [key, spec]) => {
    const value = formData[key];
    if (spec.required && !isValidString(value as string)) {
      return {
        ...result,
        [key]: 'This is required',
      };
    }
    if (spec.type === 'number' && !isValidNumber(value as number)) {
      return {
        ...result,
        [key]: 'This must be a number',
      };
    }
    return result;
  }, {});

  if (Object.keys(errors).length > 0) {
    return errors;
  }
  return null;
}

export function getSpecTypeValue(value, specType: FormSpecTypeType) {
  switch (specType) {
    case 'date':
      return Number(value);
    case 'number':
      return Number(value);
    case 'boolean':
      return value === 'on' || value === 'true';

    default:
      return value;
  }
}
type SerializedDataItemValueType = string | number | boolean | Array<string>;
type SerializedDataItemType = Record<string, SerializedDataItemValueType>;
type SerializedDataType = Record<string, SerializedDataItemValueType | SerializedDataItemType>;

type FormDataItemType = Record<string, string | number | boolean>;
type FormDataType = Record<string, string | FormDataItemType>;

export function getFormDataWithSpec(serializedData: SerializedDataType, formSpec: FormSpecType = {}): FormDataType {
  return Object.keys(serializedData).reduce((result, dataKey) => {
    const spec = formSpec[dataKey];
    const specType = spec ? spec.type : undefined;
    const dataValue = serializedData[dataKey];

    if (isEmpty(formSpec)) return serializedData;

    // Nested object | If the specType is json then handle inner
    if (!specType && !isString(dataValue)) {
      // Create formspec by removing the scope, eg. theme[hide_company_branding] => hide_company_branding
      const formSpecForNestedObject = Object.entries(formSpec).reduce((formSpecResult, [key, nestedSpecValue]) => {
        const [scope, nestedKey] = key.split('[');
        if (scope === dataKey) {
          return {
            ...formSpecResult,
            [nestedKey.replace(']', '')]: nestedSpecValue,
          };
        }
        return formSpecResult;
      }, {});

      return {
        ...result,
        [dataKey]: getFormDataWithSpec(dataValue as SerializedDataType, formSpecForNestedObject),
      };
    }

    return {
      ...result,
      [dataKey]: specType ? getSpecTypeValue(dataValue, specType) : dataValue,
    };
  }, {});
}

function hasValue(value) {
  if (!value) return false;
  if (isNumber(value)) return true;

  if (!isEmpty(value)) return true;

  return false;
}

const validatorFunctions = {
  required: (value) => hasValue(value),
};

/**
 * validateFormData - Function to check for validation errors in the formData
 * formData: {key:value}
 * validationConfig: {
 *     field_name: {
 *         validator: String | Function ,
 *         message: String | Function,
 *         relatedField: String - used only when using validator as function
 *     }
 * }
 *
 * validator: 'required' i.e keys inside the validatorFunctions
 * Validator function should only return boolean values
 *
 * * */

export function validateFormData(formData: any, validationConfig?: any) {
  return reduce(
    validationConfig,
    (result, { validator, message, relatedField }, key) => {
      const isValidatorAFunction = isFunction(validator);
      const isMessageAFunction = isFunction(message);

      const fieldData = formData && formData[key];
      let isValid;

      if (isValidatorAFunction) {
        isValid = validator(fieldData, formData[relatedField]);
      } else {
        isValid = validator ? validatorFunctions[validator](fieldData) : true;
      }

      if (!isValid)
        return {
          ...result,
          [key]: (isMessageAFunction ? message(fieldData) : message) || TEXT.REQUIRED,
        };

      return result;
    },
    {},
  );
}

function getDateAsNumberValue(key, value) {
  const isDateInput = key.indexOf('-date') > -1 && isString(value);
  if (isDateInput) {
    return Number(value);
  }
  return value;
}

function getFormDataWithDateAsNumber(serializedData) {
  return reduce(
    serializedData,
    (result, value, key) => ({
      ...result,
      [key]: getDateAsNumberValue(key, value),
    }),
    {},
  );
}
export function getMeFormData($form: HTMLFormElement, formSpec?: FormSpecType) {
  const serializedData = formSerialize($form, { hash: true, empty: true });
  const dataWithSpec = getFormDataWithSpec(serializedData, formSpec);
  const errors = validateFormData(dataWithSpec, formSpec);

  return {
    data: dataWithSpec,
    errors,
  };
}
