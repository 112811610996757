import React, { useEffect } from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Col,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  ListItem,
  LoadingDots,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { ProposalDeckService, ProposalService } from '@premagic/proposals';
import { BrowserUrlUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import EventProposalDeckStatusContainer from './deck/EventProposalDeckStatusContainer';
import EventProposalDeckVariablesContainer from './variables/EventProposalDeckVariablesContainer';
import EventProposalDeckExpiryInfoContainer from './expiry/EventProposalDeckExpiryInfoContainer';

function getPrintUrl(url) {
  const index = url.indexOf('?for_folder_id=');
  if (index !== -1) {
    return `${url.substring(0, index)}print/${url.substring(index)}&print=true`;
  }
  return `${url}print/`;
}

type Props = {
  proposal: ProposalService.ProposalType;
  isLoading: boolean;
  deckId: string;
  initData: (projectId: string, deckId: string) => void;
  highlight?: boolean;
  isLoadingCounts: boolean;
  numberOfVisits: number;
  deck?: ProposalDeckService.ProposalDeckType;
};

export default function EventProposalItem(props: Props): JSX.Element {
  const { proposal, deckId, isLoading, initData, highlight, numberOfVisits, isLoadingCounts, deck } = props;
  const {
    folder_name: name,
    folder_id: folderId,
    project_id: projectId,
    created_at: createdAt,
    share_url: shareUrl,
    is_shared: isShared,
  } = proposal;
  const isDeckApproved = deck?.review_status === ProposalDeckService.PROPOSAL_DECK_REVIEW_STATUS.APPROVED;

  useEffect(() => {
    if (deckId) initData(projectId, deckId);
  }, [initData, projectId, deckId]);

  const proposalDetails = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROPOSALS.DETAILS, {
    projectId,
    folderId,
    deckId,
  });

  return (
    <ListItem highlight={highlight}>
      <Col size={12}>
        <Row vcenter>
          <Col size={6}>
            <Row vcenter>
              <Text color={COLOR_SHADES.DARKER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
                {name}
              </Text>
              <ErrorBoundary>
                <EventProposalDeckExpiryInfoContainer deckId={deckId} folderId={folderId} />
              </ErrorBoundary>
            </Row>
          </Col>
          <Col rightAlighed size={null}>
            <Button
              style={BUTTON_STYLES.LINK}
              link={getPrintUrl(shareUrl)}
              size={BUTTON_SIZE.SM}
              disabled={!isDeckApproved}
              disabledMessage="Only approved proposal can be Printed"
              isExternalLink
            >
              <Icon name="printer" size={ICON_SIZES.SM} />
              <Space size={1} />
              Print
            </Button>
            <Button
              style={BUTTON_STYLES.LINK}
              link={shareUrl}
              size={BUTTON_SIZE.SM}
              disabled={!isShared}
              disabledMessage="Share the proposal to open"
              isExternalLink
            >
              <Icon name="external_link" size={ICON_SIZES.SM} />
              <Space size={1} />
              Open
            </Button>
            <Button style={BUTTON_STYLES.LINK} link={proposalDetails} size={BUTTON_SIZE.SM}>
              <Icon name="edit_2" size={ICON_SIZES.SM} />
              <Space size={1} />
              Edit
            </Button>
          </Col>
        </Row>
        <Space size={1} vertical />
        {isLoading ? (
          <LoadingDots size="sm" align="left" />
        ) : (
          <ErrorBoundary>
            <Row>
              <Col>
                <EventProposalDeckStatusContainer deckId={deckId} folderId={folderId} />
              </Col>
              {isShared && (
                <Col rightAlighed size={null}>
                  {numberOfVisits > 0 ? (
                    <Text size={TEXT_SIZE.SIZE_5}>Your client has viewed ({numberOfVisits} times)</Text>
                  ) : (
                    <Text size={TEXT_SIZE.SIZE_5}>
                      {isLoadingCounts ? <LoadingDots size="sm" /> : 'Your client has not viewed the proposal'}
                    </Text>
                  )}
                </Col>
              )}
            </Row>
            <Space vertical size={2} />
            <EventProposalDeckVariablesContainer deckId={deckId} folderId={folderId} />
          </ErrorBoundary>
        )}
      </Col>
    </ListItem>
  );
}
