import React from 'react';

import { ProjectService } from '@premagic/core';
import {
  BUTTON_STYLES,
  Button,
  Dialog,
  DialogFooter,
  DIALOG_PLACEMENT,
  Form,
  FormResponseType,
  FormErrorType,
  FormGroup,
  FormLabel,
  Input,
} from '@premagic/myne';
import { getElementFromSelector, JS_SELECTORS } from '../../../../../../common/JSClassSelectors';

type Props = {
  project: ProjectService.ProjectType;
  isRenaming: boolean;
  projectId: string;
  toggleDialog: (show: boolean) => void;
  isPopoverShown: boolean;
  renameProject: (projectId: string, data: FormResponseType) => void;
  errors?: FormErrorType;
};

export default function ProjectRenameDialog(props: Props): JSX.Element | null {
  const { isRenaming, projectId, project, toggleDialog, isPopoverShown, renameProject, errors } = props;

  const { project_name: name } = project;

  if (!isPopoverShown) return null;
  return (
    <Dialog
      target={getElementFromSelector(JS_SELECTORS.PROJECT_MORE_OPTIONS)}
      show
      placement={DIALOG_PLACEMENT.BUTTON_BOTTOM}
      onClose={() => toggleDialog(false)}
    >
      <Form onSubmit={(response) => renameProject(projectId, response)} errors={errors}>
        <FormGroup>
          <FormLabel>Name</FormLabel>
          <Input defaultValue={name} name="project_name" autoFocus />
        </FormGroup>
        <DialogFooter>
          <Button style={BUTTON_STYLES.LINK} onClick={() => toggleDialog(false)} type="button">
            Cancel
          </Button>
          <Button isLoading={isRenaming} style={BUTTON_STYLES.PRIMARY}>
            Save
          </Button>
        </DialogFooter>
      </Form>
    </Dialog>
  );
}
