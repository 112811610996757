import React, { useState } from 'react';
import { Button, BUTTON_STYLES, Form, FormFooter, FormGroup, FormLabel, Input, INPUT_SIZES } from '@premagic/myne';

type Props = {
  isLoading: boolean;
  requestForForgotPassword: (formData) => void;
  resetPassword: (formData) => void;
};

export default function AuthResetPassword(props: Props) {
  const { requestForForgotPassword, resetPassword, isLoading } = props;
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);

  function onsubmit(formData) {
    if (showNewPasswordForm) return resetPassword(formData);
    setShowNewPasswordForm(true);
    requestForForgotPassword(formData);
  }

  return (
    <Form onSubmit={onsubmit}>
      <FormGroup>
        <FormLabel>Email</FormLabel>
        <Input name="email" placeholder="john@premagic.com" autoFocus required size={INPUT_SIZES.MD} />
      </FormGroup>
      {showNewPasswordForm && (
        <div>
          <FormGroup>
            <FormLabel>Code</FormLabel>
            <Input
              name="code"
              type="number"
              placeholder="Enter the code, you have received on your email"
              autoFocus
              required
              size={INPUT_SIZES.MD}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>New Password</FormLabel>
            <Input name="password" type="password" required size={INPUT_SIZES.MD} />
          </FormGroup>
        </div>
      )}
      <FormFooter>
        <Button style={BUTTON_STYLES.PRIMARY} block isLoading={isLoading}>
          Reset password
        </Button>
      </FormFooter>
    </Form>
  );
}
