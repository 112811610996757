import { connect } from 'react-redux';
import ClientWebsiteItineraryForm from './ClientWebsiteItineraryForm';
import { eventAttendeesWithTypeSpeakerDataSelector } from '../../crm/events/event-attendees/EventAttendeesDataDuck';
import { clientWebsiteItinerarySelector, lastItineraryEndDateSelector } from './ClientWebsiteItineraryDataDuck';

function mapStateToProps(
  state,
  ownProps: {
    itineraryId?: string;
  },
) {
  const { itineraryId } = ownProps;

  return {
    itineraryId,
    itinerary: itineraryId ? clientWebsiteItinerarySelector(state)[itineraryId] : undefined,
    speakers: eventAttendeesWithTypeSpeakerDataSelector(state),
    lastItineraryEndDate: lastItineraryEndDateSelector(state),
  };
}

export default connect(mapStateToProps)(ClientWebsiteItineraryForm);
