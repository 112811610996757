import { AssetsService, QRCodeBackgroundPosterService } from '@premagic/core';
import {
  COLOR_SHADES,
  LoadingDots,
  Position,
  PremagicBlackLogo,
  PremagicWhiteLogo,
  Row,
  SimpleImage,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { ColorUtils, useFontSizeBasedOnContainer } from '@premagic/utils';
import classNames from 'classnames';
import QRCodeBackgroundPosterContainer from './background-poster/QRCodeBackgroundPosterContainer';
import LogoBasedOnPosterTypeContainer from './displayed-logo/LogoBasedOnPosterTypeContainer';
import QRCodeDisplayedSponsorLogosAlignedContainer from './sponsor/QRCodeSponsorLogosWithAlignmentContainer';
import QRCodeCustomLEDBackgroundPosterContainer from './background-poster/QRCodeCustomLEDBackgroundPosterContainer';
import useQRCode from './custom-hooks/useQrCode';

export default function LEDScreenQRCode(props: {
  url: string;
  printClass: string;
  fileNameClass: string;
  backgroundCover: QRCodeBackgroundPosterService.QRCodePoster;
  folderId: string;
  logos?: Array<string | undefined>;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  projectName?: string;
  showPoweredByPremagic: boolean;
}) {
  const {
    url,
    printClass,
    fileNameClass,
    backgroundCover,
    folderId,
    logos,
    qrCodeDesigns,
    projectName,
    showPoweredByPremagic,
  } = props;

  const {
    fontSize: eventNameFontSize,
    containerRef: eventNameContainerRef,
    textRef: eventNameTextRef,
  } = useFontSizeBasedOnContainer(26);

  const { qrCode, isLoading: isLoadingQr } = useQRCode(url, 130);
  const { qrSvgImage } = qrCode || {};

  const customLogo1 = logos && logos[0];
  const customLogo2 = logos && logos[1];

  // We won't use the useBackgroundPoster hook here as LED will have custom text colors.
  const customLedPoster = qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.CUSTOM_LED_POSTER];

  const textColors = qrCodeDesigns?.[QRCodeBackgroundPosterService.QR_CODE_DESIGN_DATA_TYPES.LED_POSTER_TEXT_COLOR];
  const { primary, secondary } = textColors || {};

  const primaryTextColor = primary || '#203a56';
  const secondaryTextColor = secondary || '#203a56';

  if (isLoadingQr) {
    return <LoadingDots size="sm" />;
  }

  return (
    <>
      <Row vcenter center>
        <Text size={TEXT_SIZE.SIZE_1} boldness={TEXT_BOLDNESS.SEMI_BOLD} color={COLOR_SHADES.DARK}>
          LED Screen (1920px x 1080px)
        </Text>
      </Row>
      <Space vertical size={6} />
      <Row center>
        <div
          className={classNames(printClass, fileNameClass)}
          style={{
            width: '750px',
            height: '422px',
            position: 'relative',
            overflow: 'hidden',
            flexShrink: 0,
          }}
        >
          <Position align="cover">
            {customLedPoster ? (
              <QRCodeCustomLEDBackgroundPosterContainer fileId={customLedPoster} />
            ) : (
              <QRCodeBackgroundPosterContainer
                posterId={backgroundCover.id}
                posterType="led-screen"
                folderId={folderId}
              />
            )}
          </Position>

          <div
            style={{
              zIndex: 1,
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '25px',
                left: '25px',
                width: '210px',
              }}
            >
              <LogoBasedOnPosterTypeContainer
                posterType="led-screen"
                folderId={folderId}
                customLogo1={customLogo1}
                customLogo2={customLogo2}
                // setting small logo space for led for all posters
                smallerLogoSpace
              />
            </div>

            <div style={{ position: 'absolute', bottom: '28px', left: '25px' }}>
              <QRCodeDisplayedSponsorLogosAlignedContainer
                folderId={folderId}
                logoSize="sm"
                title="Sponsored by"
                titleColor={secondaryTextColor}
              />
            </div>
            {/* ---EVENT NAME--- */}
            <div
              ref={eventNameContainerRef}
              style={{
                position: 'absolute',
                top: '100px',
                left: '25px',
                width: '210px',
                height: '200px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p
                ref={eventNameTextRef}
                style={{
                  color: secondaryTextColor,
                  fontSize: eventNameFontSize,
                  fontWeight: 600,
                  whiteSpace: 'pre-line',
                }}
              >
                {projectName}
              </p>
            </div>

            <div
              style={{
                position: 'absolute',
                top: '97px',
                right: '25px',
              }}
            >
              <Row vcenter>
                <div style={{ width: '230px', textAlign: 'right' }}>
                  <Text cssStyle={{ color: primaryTextColor, fontSize: '18px' }} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
                    Scan this Code to
                  </Text>
                  <Text
                    color={COLOR_SHADES.DARKER}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    cssStyle={{ color: primaryTextColor, fontSize: '26px', lineHeight: '28px' }}
                  >
                    GET YOUR PHOTOS
                  </Text>

                  <Text
                    color={COLOR_SHADES.DARKER}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    cssStyle={{ color: primaryTextColor, fontSize: '26px', lineHeight: '28px' }}
                  >
                    FROM THIS EVENT
                  </Text>
                  <Text
                    color={COLOR_SHADES.DARKER}
                    boldness={TEXT_BOLDNESS.BOLD}
                    block
                    cssStyle={{ color: primaryTextColor, fontSize: '26px', lineHeight: '28px' }}
                  >
                    ON YOUR PHONE
                  </Text>
                </div>
                <Space />

                <div
                  style={{
                    border: '1.5px solid #191a19',
                    borderRadius: '7%',
                    padding: '7px',
                    position: 'relative',
                    background: 'white',
                  }}
                >
                  {qrSvgImage && <SimpleImage src={qrSvgImage} alt="qr-code" />}

                  {showPoweredByPremagic && (
                    <div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%,0)' }}>
                      <Row center columnDirection>
                        <Space vertical size={4} />
                        <Text
                          cssStyle={{ color: primaryTextColor }}
                          size={TEXT_SIZE.SIZE_6}
                          boldness={TEXT_BOLDNESS.SEMI_BOLD}
                        >
                          Powered by
                        </Text>
                        <Space size={1}>
                          {(() => (
                            // will add this later
                            // if (useWhitePremagicLogo) {
                            //   return <PremagicWhiteLogo width={110} height={30} />;
                            // }

                            <Space size={1}>
                              <SimpleImage
                                style={{ width: '110px', objectFit: 'contain' }}
                                src={AssetsService.getLogoImages(
                                  AssetsService.ASSETS_URL.LOGO_ICON_WITH_TEXT_HIGH_QUALITY,
                                )}
                                alt="premagic-logo"
                              />
                            </Space>
                          ))()}
                        </Space>
                      </Row>
                    </div>
                  )}
                </div>
              </Row>
            </div>
            <div style={{ position: 'absolute', bottom: '44px', right: '25px', textAlign: 'right' }}>
              <Text
                block
                boldness={TEXT_BOLDNESS.SEMI_BOLD}
                cssStyle={{ color: primaryTextColor, fontSize: '11px', lineHeight: '14px' }}
              >
                We use AI Face Recognition to identify your face from the photos taken by the
              </Text>

              <Text
                block
                boldness={TEXT_BOLDNESS.SEMI_BOLD}
                cssStyle={{ color: primaryTextColor, fontSize: '11px', lineHeight: '14px' }}
              >
                photographers & deliver them to you via WhatsApp & Email
              </Text>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}
