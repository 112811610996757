import { connect } from 'react-redux';
import { clientWebsitesSelector } from '../ClientWebsiteDataDuck';
import ClientWebsiteThemeColorsEdit from './ClientWebsiteThemeColorsEdit';

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  return {
    clientWebsite: clientWebsitesSelector(state)[id] || {},
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebsiteThemeColorsEdit);
