function getActionKey(scope: string) {
  return (action: string) => `@${scope}/${action}`;
}

export const LOADINGS = {
  APP_LOADING: 'app-loading',
  AUTH: {
    LOADING: getActionKey('auth')('loading'),
    LOGIN: getActionKey('auth')('login'),
    LOGOUT: getActionKey('auth')('logout'),
    RESET_PASSWORD: getActionKey('reset')('pasword'),
    SET_PASSWORD: getActionKey('set')('pasword'),
  },
  SEND_TO_PHOTOGRAPHER: 'send-to-photographer',
  PHOTO_SELECTION: {
    REQUEST_SELECTION_AGAIN: 'request-photo-selection-again',
  },

  PRICING_DATA: 'pricing-plan',
  PRICING_PLAN_ORDER: 'pricing-plan-order',

  PROMPT_MPIN: 'prompt-mpin',

  SUBSCRIPTION: {
    PAYMENT: 'subscription-payment',
    FETCH: 'subscription-fetch',
  },
  INVOICE: {
    FETCH_ALL: 'invoice-fetch-all',
  },

  IMAGES: {
    FETCH: getActionKey('images')('fetch'),
  },
  ACCOUNTS: {
    UPDATE: getActionKey('account')('update'),
  },
  COMPANY: {
    SEND_ENQUIRY: 'company-send-enquiry',
    UPLOAD_LOGO: (name) => `company-upload-logo-${name}`,
    SAVE_PROFILE: 'company-save-profile',
    STATS_FETCH: 'company-stats-fetch',
  },

  CLIENT_SETTINGS: {
    FETCH_ALL: 'client-setting-fetch',
    SAVE_SETTINGS: 'client-setting-save',
    UPLOAD_WATERMARK: 'client-setting-watermark',
    CHANGE_COMPANY_TYPE: 'client-setting-company-type',
  },

  LEADS: {
    FETCH_ALL: 'leads-fetch-all',
    UPDATE_STATUS: 'lead-update-status',
  },

  CLIENTS: {
    FETCH_ALL: 'clients-fetch-all',
  },

  PROJECTS: {
    FETCH_ALL: 'projects-fetch-all',
    OVERVIEW_FETCH_ALL: 'project-overview-fetch-all',
  },
  PROJECT: {
    FETCH: getActionKey('project')('fetch'),
    ADD: getActionKey('project')('add'),
    UPDATE: getActionKey('project')('update'),
    RESET_PIN: getActionKey('project')('reset-pin'),
    SHOW_ARCHIVE_DIALOG: getActionKey('project')('archive'),
    SHOW_RENAME_DIALOG: getActionKey('project')('rename'),
    SHOW_RESET_PIN_DIALOG: getActionKey('project')('show-reset-pin-dialog'),
    SHOW_ADD_PANEL: getActionKey('project')('show-add-panel'),
    SHOW_IN_PORTFOLIO: getActionKey('project')('show-in-portfolio'),
    ADD_WATERMARK: getActionKey('project-watermark')('add'),
    COPY_FROM_SIGNATURE_ALBUM: getActionKey('project')('copy-from-signature-album'),
  },
  PROJECT_STATS: {
    FETCH: getActionKey('project-stats')('fetch'),
    SPONSOR_FETCH: getActionKey('sponsors-stats')('fetch'),
  },
  PROJECT_META: {
    FETCH: getActionKey('project-meta')('fetch'),
    UPDATE: getActionKey('project-meta')('update'),
    SHOW_UPDATE_PANEL: getActionKey('project-meta')('show-update-panel'),
    SHOW_UPDATE_PANEL_ITEM: (item) => getActionKey('project-meta')(`show-update-panel--${item}`),
    COVER_UPDATE: getActionKey('project-meta-cover')('update'),
    SHOW_EDIT_WATERMARK: getActionKey('project-meta')('show-edit-watermark'),
    SHOW_ADD_WATERMARK: getActionKey('project-meta')('show-add-watermark'),
    SHOW_SOCIAL_MEDIA_SHARE_SETTINGS_PANEL: getActionKey('project-meta')('show-social-media-share-settings-panel'),
    SHOW_ADD_SOCIAL_MESSAGE_MODAL: (scope) => getActionKey('project-meta')(`show-add-social-message-modal--${scope}`),
    SHOW_EDIT_SOCIAL_MESSAGE_MODAL: (messageId) =>
      getActionKey('project-meta')(`show-edit-social-message-modal--${messageId}`),
  },
  PROJECT_BACKGROUND_TASKS: {
    FETCH: getActionKey('project-background-tasks')('fetch'),
  },
  PROJECT_NERD_STATS: {
    FETCH: getActionKey('project-nerd-stats')('fetch'),
  },
  FONTS: {
    FETCH: getActionKey('fonts')('fetch'),
    UPDATE: getActionKey('fonts')('update'),
  },
  PORTFOLIO: {
    FETCH: getActionKey('portfolio')('fetch'),
  },
  FOLDER: {
    FETCH: getActionKey('folder')('fetch'),
    FETCH_ALL: getActionKey('folders')('fetch'),
    ADD: getActionKey('folder')('add'),
    ADD_FILES: getActionKey('folder')('add-files'),
    ADD_BRANDING_FILES: getActionKey('folder')('add-files-branding'),
    UPDATE: getActionKey('folder')('update'),
    DELETE: getActionKey('folder')('delete'),
    BLOCK: getActionKey('folder')('block'),
    DELETE_SINGLE: (folderId) => getActionKey('folder')(`delete--${folderId}`),
    RENAME: getActionKey('folder')('rename'),
    UPDATE_COVER_PHOTO: (folderId) => getActionKey('folder')(`update-cover-photo--${folderId}`),
    UPDATE_COVER_PHOTO_FOCAL_POINT: (folderId) => getActionKey('folder')(`update-cover-photo-focal-point--${folderId}`),
    ADD_EXTERNAL_VIDEO_LINK: getActionKey('folder')('add-external-video-link'),
    FILE_ID_FOR_EXTERNAL_VIDEO_LINK: getActionKey('folder')('file-id-for-external-video-link'),
    MARK_AS_PUBLIC: getActionKey('folder')('mark-as-public'),
  },
  FOLDER_META: {
    FETCH: getActionKey('folder-meta')('fetch'),
    UPDATE: getActionKey('folder-meta')('update'),
    SHOW_UPDATE_PANEL: getActionKey('folder-meta')('show-update-panel'),
    COVER_UPDATE: getActionKey('folder-meta-cover')('update'),
  },
  FILE: {
    DELETE: getActionKey('file')('delete'),
    COMPRESS_FILE: getActionKey('file')('compress'),
    UPDATE_META: (fileId) => getActionKey('file-meta')(`update--${fileId}`),
  },
  BRANDING_FOLDER: {
    SELECT_FILE_MODAL: getActionKey('branding-folder')('select-file-modal'),
    FILES_MODAL: getActionKey('branding-folder')('files-modal'),
  },
  REPORTS: {
    FETCH_ALL: 'reports-fetch-all',
  },

  ALBUM: {
    LOADINGS: 'album-loading',
    FETCH: 'album-fetch-all',
    FETCH_IMAGES: 'album-images-fetch-all',
    ADD_NEW_ALBUM: 'album-new-album',
    ADD_NEW_PAGE: 'album-new-page',
    UPDATE_NEW_PAGE: 'album-update-page',
  },

  PHOTOGRAPHER_ACTIONS: {
    FETCH: 'photographer-actions-fetch',
    MARK_DONE: 'photographer-action-mark-done--',
  },
  CLIENT_WEBSITE: {
    FETCH: 'client-website-fetch-all',
    FETCH_SINGLE: 'client-website-fetch-all',
    ADD: 'client-website-add',
    UPDATE: 'client-website-update',
    SHARE: 'client-website-share',
    SHOW_QUICK_EDIT_PANEL: 'client-website-quick-edit-panel',
    SHOW_EDIT_PANEL: 'client-website-edit-panel',
    SHOW_EDIT_PEOPLE_DETAILS_PANEL: 'client-website-edit-people-details-panel',
    SHOW_EDIT_MAIN_OCCASION_PANEL: 'client-website-edit-main-occasion-panel',
    SHOW_EDIT_USER_REGISTRATION_PANEL: 'client-website-edit-user-registration-panel',
    SHOW_EDIT_EVENT_METRICS_PANEL: 'client-website-edit-event-metrics-panel',
    PHOTO_UPDATE: (name) => `client-website-photo-update-${name}`,
    SHOW_I_AM_ATTENDING_POSTER_SELECT_MODAL: 'show-i-am-attending-poster-select-modal',
    UPLOAD_PHOTOS: (name) => `client-website-photo-upload-${name}`,
  },
  QR_CODE: {
    SELECT_LOGO_MODAL: getActionKey('qr-code')(`select-logo-modal`),
    SELECT_ILLUSTRATION_MODAL: getActionKey('qr-code')('select-illustration-modal'),
    SELECT_POSTER_MODAL: getActionKey('qr-code')('select-poster-modal'),
    SELECT_SPONSOR_LOGO_MODAL: getActionKey('qr-code')('select-sponsor-logo-modal'),
    SELECT_LED_CUSTOM_POSTER_MODAL: getActionKey('qr-code')('select-led-custom-poster-modal'),
  },
  CLIENT_WEBSITE_ITINERARY: {
    ADD: 'client-website-itinerary-add',
    UPDATE: 'client-website-itinerary-update',
    REMOVE: 'client-website-itinerary-remove',
    SHOW_ADD_ITINERARY_PANEL: 'client-website-add-itinerary-panel',
    SHOW_EDIT_ITINERARY_PANEL: 'client-website-edit-itinerary-panel',
  },
  CLIENT_WISH: {
    FETCH: getActionKey('client-wish')('fetch'),
    ADD: getActionKey('client-wish')('add'),
    UPDATE: getActionKey('client-wish')('update'),
    DELETE: getActionKey('client-wish')('delete'),
    SHOW_ADD_PANEL: getActionKey('client-wish')('show-add-panel'),
  },
  EVENT_CUSTOM_FORM_FIELDS: {
    FETCH: getActionKey('event-form-fields')('fetch'),
    ADD: getActionKey('event-form-fields')('add'),
    UPDATE: getActionKey('event-form-fields')('update'),
    DELETE: getActionKey('event-form-fields')('delete'),
    SHOW_ADD_PANEL: getActionKey('event-form-fields')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('event-form-fields')('show-edit-panel'),
    SHOW_DELETE_DIALOG: (id) => getActionKey('event-form-fields')(`delete-dialog--${id}`),
  },
  USERS: {
    FETCH: getActionKey('users')('fetch'),
    ADD: getActionKey('users')('add'),
    UPDATE: getActionKey('users')('update'),
    DELETE: getActionKey('users')('delete'),
    SHOW_ADD_PANEL: getActionKey('users')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('users')('show-edit-panel'),
  },
  EVENT: {
    FETCH_SINGLE: getActionKey('events')('fetch-single'),
    FETCH: getActionKey('events')('fetch'),
    ADD: getActionKey('events')('add'),
    UPDATE: getActionKey('events')('update'),
    UPDATE_NAME: getActionKey('events')('update-name'),
    UPDATE_STAGE: getActionKey('events')('update-stage'),
    UPDATE_PRIORITY: getActionKey('events')('update-priority'),
    SHOW_EDIT_STATUS: getActionKey('events')('edit-status-dialog'),
    DELETE: getActionKey('events')('delete'),
    SHOW_EDIT_PANEL: getActionKey('events')('edit-panel'),
    SHOW_EDIT_NAME_PANEL: getActionKey('events')('edit-name-panel'),
    SHOW_LANGUAGE_SELECTOR_PANEL: getActionKey('events')('language-selector-panel'),
    SHOW_DELETE_DIALOG: getActionKey('events')('delete-dialog'),
    CONVERT_TO_PROJECT: getActionKey('events')('convert-to-project'),
    CREATE_BASIC_INVITE: getActionKey('events')('create-basic-invite'),
    ADD_LABEL: getActionKey('events')('add-label'),
    REMOVE_LABEL: getActionKey('events')('remove-label'),
  },
  CRM_CLIENTS: {
    FETCH_SINGLE: getActionKey('clients')('fetch-single'),
    FETCH: getActionKey('clients')('fetch'),
    ADD: getActionKey('clients')('add'),
    UPDATE: getActionKey('clients')('update'),
    DELETE: getActionKey('clients')('delete'),
    SHOW_EDIT_PANEL: getActionKey('clients')('edit-panel'),
    SEARCH: getActionKey('clients')('search'),
  },
  OCCASIONS: {
    FETCH: getActionKey('occasions')('fetch'),
    USER_OCCASIONS: getActionKey('occasions')('user-occasions'),
    ADD: getActionKey('occasions')('add'),
    UPDATE: getActionKey('occasions')('update'),
    DUPLICATE: (occasionId) => getActionKey('occasions')(`duplicate--${occasionId}`),
    DELETE: getActionKey('occasions')('delete'),
    SHOW_ADD_PANEL: getActionKey('occasions')('add-panel'),
    SHOW_EDIT_PANEL: getActionKey('occasions')('edit-panel'),
    SHOW_DELETE_DIALOG: (occasionId) => getActionKey('occasions')(`delete-dialog--${occasionId}`),
  },
  CRM_PAYMENTS: {
    FETCH: getActionKey('payments')('fetch'),
    ADD: getActionKey('payments')('add'),
    BULK_ADD: getActionKey('payments')('add-bulk'),
    UPDATE: getActionKey('payments')('update'),
    DELETE: getActionKey('payments')('delete'),
    REMINDER_TO_CLIENT: getActionKey('payments')('reminder-client'),
    SHOW_ADD_CLIENT_PAYMENT_PANEL: getActionKey('payments')('client-add-panel'),
    SHOW_ADD_EXPENSE_PAYMENT_PANEL: getActionKey('payments')('expense-add-panel'),
    SHOW_EDIT_CLIENT_PAYMENT_PANEL: getActionKey('payments')('client-edit-panel'),
    SHOW_EDIT_EXPENSE_PAYMENT_PANEL: getActionKey('payments')('expense-edit-panel'),
    SHOW_ADD_INVOICE_NOTE_PANEL: getActionKey('payments')('invoice-note-add-panel'),
    SHOW_DELETE_DIALOG: getActionKey('payments')('delete-dialog'),
    SHOW_ADD_EDIT_QUOTE_DIALOG: getActionKey('payments')('quote-dialog'),
    SHOW_BULK_ADD_CLIENT_PAYMENTS: getActionKey('payments')('bulk-add-payments'),
  },
  CRM_PAYMENT_CATEGORY: {
    FETCH: getActionKey('payments')('fetch'),
  },
  CRM_LABELS: {
    FETCH: getActionKey('crm-labels')('fetch'),
    CREATE: getActionKey('crm-labels')('create'),
    DELETE: getActionKey('crm-labels')('delete'),
  },
  CRM_WORKFLOW: {
    FETCH: getActionKey('crm-workflow')('fetch'),
    FETCH_ITEM: getActionKey('crm-workflow')('fetch-item'),
    CREATE: getActionKey('crm-workflow')('create'),
    UPDATE: getActionKey('crm-workflow')('update'),
    DELETE: getActionKey('crm-workflow')('delete'),
    FETCH_TASKGROUP: getActionKey('workflow-taskgroup')('fetch'),
    CREATE_TASKGROUP: getActionKey('workflow-taskgroup')('create'),
    UPDATE_TASKGROUP: getActionKey('workflow-taskgroup')('update'),
    DELETE_TASKGROUP: getActionKey('workflow-taskgroup')('delete'),
    CHANGE_TASKGROUP_ORDER: getActionKey('workflow-taskgroup')('change-order'),
    FETCH_TASK: getActionKey('workflow-task')('fetch'),
    CREATE_TASK: getActionKey('workflow-task')('create'),
    UPDATE_TASK: getActionKey('workflow-task')('update'),
    DELETE_TASK: getActionKey('workflow-task')('delete'),
  },
  CRM: {
    CALENDAR_OCCASION_FETCH: getActionKey('calendar-occasions')('fetch'),
    LIST_EVENTS_FETCH: getActionKey('list-events')('fetch'),
    LIST_EVENTS_COUNT_FETCH: getActionKey('list-events-counts')('fetch'),
  },
  ACCOUNT_FINANCIALS: {
    LIST: getActionKey('account-financial')('list'),
    COUNT: getActionKey('account-financial')('count'),
  },
  FOLDER_SHARE: {
    SHARE: 'client-share-share',
  },
  PROJECT_ORDERS: {
    FETCH: getActionKey('orders')('fetch'),
  },
  FOLDER_FILES_POSITION_ORDER: {
    FETCH: getActionKey('files-order')('fetch'),
    UPDATE: getActionKey('files-order')('update'),
  },
  SAMPLE_PROJECT: {
    CREATE: getActionKey('sample-project')('create'),
  },
  STORE: {
    FETCH: getActionKey('store')('fetch'),
  },
  STORE_PRICE: {
    SHOW_PRICE_SETTING_MODAL: getActionKey('store-price')('show-price-setting-modal'),
  },
  EVENT_SPONSOR: {
    FETCH: getActionKey('event-sponsor')('fetch'),
    ADD: getActionKey('event-sponsor')('add'),
    UPDATE: getActionKey('event-sponsor')('update'),
    DELETE: getActionKey('event-sponsor')('delete'),
    SHOW_ADD_PANEL: getActionKey('event-sponsor')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('event-sponsor')('show-edit-panel'),
    SHOW_DELETE_DIALOG: (id) => getActionKey('event-sponsor')(`delete-dialog--${id}`),
  },
  EVENT_EXHIBITOR: {
    FETCH: getActionKey('event-exhibitor')('fetch'),
    ADD: getActionKey('event-exhibitor')('add'),
    UPDATE: getActionKey('event-exhibitor')('update'),
    DELETE: getActionKey('event-exhibitor')('delete'),
    SHOW_ADD_PANEL: getActionKey('event-exhibitor')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('event-exhibitor')('show-edit-panel'),
    SHOW_DELETE_DIALOG: (id) => getActionKey('event-exhibitor')(`delete-dialog--${id}`),
  },
  EVENT_SPONSOR_CATEGORY: {
    FETCH: getActionKey('event-sponsor-category')('fetch'),
    CREATE: getActionKey('event-sponsor-category')('create'),
    UPDATE: getActionKey('event-sponsor-category')('update'),
  },
  EVENT_ATTENDEES: {
    FETCH: getActionKey('event-attendees')('fetch'),
    ADD: getActionKey('event-attendees')('add'),
    UPDATE: getActionKey('event-attendees')('update'),
    UPDATE_STATUS: (id) => getActionKey('event-attendees')(`update-status--${id}`),
    BULK_UPDATE: getActionKey('event-attendees')('update-bulk'),
    DELETE: getActionKey('event-attendees')('delete'),
    DOWNLOAD: getActionKey('event-attendees')('download'),
    CSV_BULK_DOWNLOAD: getActionKey('event-attendees')('csv-bulk-download'),
    CSV_BULK_UPLOAD: getActionKey('event-attendees')('csv-bulk-upload'),
    CSV_BULK_UPDATE: getActionKey('event-attendees')('csv-bulk-update'),
    SHOW_ADD_PANEL: getActionKey('event-attendees')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('event-attendees')('show-edit-panel'),
    SHOW_EDIT_STATUS: getActionKey('event-attendees')('show-edit-status'),
    SHOW_DELETE_DIALOG: (id) => getActionKey('event-attendees')(`delete-dialog--${id}`),
    ATTENDEES_WITHOUT_PHOTO_ALERT_MODAL: getActionKey('event-attendees')('attendees-without-photo-alert'),
    CHECKIN_ZONES: {
      FETCH: getActionKey('event-attendees-checkin-zones')('fetch'),
      ADD: getActionKey('event-attendees-checkin-zones')('add'),
      DELETE: getActionKey('event-attendees-checkin-zones')('delete'),
      STATS_FETCH: getActionKey('event-attendees-checkin-stats')('fetch'),
    },
  },
  EVENT_ATTENDEE_BADGE: {
    SHOW_EVENT_ATTENDEE_BADGE_SELECT_POSTER_MODAL: 'show-event-attendee-badge-select-poster-modal',
  },
  SPONSOR_CREATIVES: {
    FETCH: getActionKey('sponsor-creative')('fetch'),
    ADD: getActionKey('sponsor-creative')('add'),
    UPDATE: getActionKey('sponsor-creative')('update'),
    DELETE: getActionKey('sponsor-creative')('delete'),
    SHOW_ADD_PANEL: getActionKey('sponsor-creative')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('sponsor-creative')('show-edit-panel'),
    SELECT_FILE_MODAL: getActionKey('sponsor-creative')('select-file-modal'),
    FILES_MODAL: getActionKey('sponsor-creative')('files-modal'),
    SHOW_DELETE_DIALOG: (sponsorId, id) => getActionKey('sponsor-creative')(`delete-dialog--${sponsorId}${id}`),
    SHOW_ADD_SPONSOR_WATERMARK_PANEL: getActionKey('sponsor-creative')('show-add-sponsor-watermark-panel'),
    SHOW_EDIT_SPONSOR_WATERMARK_PANEL: getActionKey('sponsor-creative')(`show-edit-sponsor-watermark-panel`),
    ADD_WATERMARK: getActionKey('sponsor-creative')('add-watermark'),
  },
  PRODUCT_FEEDBACK: {
    REPORT_ISSUE: {
      FETCH: getActionKey('product-feedback.report-issue')('fetch'),
      DELETE: (id) => getActionKey('product-feedback.report-issue')(`delete--${id}`),
      UPDATE: (id) => getActionKey('product-feedback.report-issue')(`update--${id}`),
    },
    EVENT_FEEDBACK: {
      FETCH: getActionKey('product-feedback.event-feedback')('fetch'),
    },
  },
  PEOPLE_FACES: {
    FIND_FACE_ID: getActionKey('people-faces')('find-face-id'),
    UPLOAD: getActionKey('people-faces')('upload'),
    FETCH: getActionKey('people-faces')('fetch'),
    FACES_IN_ITEM: getActionKey('people-faces-items')('fetch'),
  },
  DAM_LIBRARY: {
    FETCH_FILES: getActionKey('dam-library')('fetch'),
    FETCH_FILTER_LABELS: getActionKey('dam-library')('fetch-filter-labels'),
  },
  WELCOME: {
    SAVE: getActionKey('welcome')('save'),
  },
  EXTERNAL_UPLOADER: {
    UPLOAD: getActionKey('external-uploader')('upload'),
  },
  EVENT_POSTERS: {
    FETCH: getActionKey('poster')('fetch'),
    SINGLE_FETCH: getActionKey('poster')('single-fetch'),
    CREATE: getActionKey('poster')('create'),
    SHOW_ADD_PANEL: getActionKey('poster')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('poster')('show-edit-panel'),
    DELETE: (id) => getActionKey('poster')(`delete--${id}`),
    UPDATE: getActionKey('poster')('update'),
    UPDATE_WIDGET: getActionKey('poster')('update-widget'),
    DELETE_WIDGET: getActionKey('poster')('delete-widget'),
    SHOW_EMBED_MODAL: getActionKey('poster')('show-embed-modal'),
  },
  MEMORY_TEMPLATES: {
    FETCH: getActionKey('memory-template')('fetch'),
    CREATE: getActionKey('memory-template')('create'),
    UPDATE: getActionKey('memory-template')('update'),
    DELETE: (id) => getActionKey('memory-template')(`delete--${id}`),
    SHOW_ADD_PANEL: getActionKey('memory-template')('show-add-panel'),
    SHOW_EDIT_PANEL: getActionKey('memory-template')('show-edit-panel'),
  },
  ATTENDEE_MEMORY: {
    FETCH: getActionKey('attendee-memory')('fetch'),
    CREATE: getActionKey('attendee-memory')('create'),
    UPDATE: getActionKey('attendee-memory')('update'),
    DELETE: getActionKey('attendee-memory')('delete'),
    SHOW_ADD_PANEL: getActionKey('attendee-memory')('show-add-panel'),
  },
  HIGHLIGHT_DESIGN: {
    SHOW_EDIT_TEXT_PANEL: getActionKey('highlight-design')('show-edit-text-panel'),
  },
  POSTER_ADVOCATES: {
    FETCH: getActionKey('poster-advocates')('fetch'),
  },
  EVENT_ATTENDEE_CHECKIN_ZONE: {
    CREATE: getActionKey('event-attendee-checkin-zone')('create'),
    UPDATE: getActionKey('event-attendee-checkin-zone')('update'),
  },
  INTEGRATION: {
    FETCH_EVENTS: getActionKey('integration')('fetch-events'),
    FETCH_EVENT_CUSTOM_FIELDS: getActionKey('integration')('fetch-event-custom-field'),
    CONNECT_EVENT: getActionKey('integration')('connect-event'),
    SYNC_EVENT: getActionKey('integration')('sync-event'),
    CONNECT_AND_SYNC_EVENT: getActionKey('integration')('connect-and-sync-event'),
    CREATE_AND_CONNECT_EVENT: getActionKey('integration')('create-and-connect-event'),
    UPDATE_FIELDS: getActionKey('integration')('update-fields'),
  },
};
// We can get rid of modals and use the loading variable but rename it ACTION_KEYS
export const MODALS = {
  SEND_TO_PHOTOGRAPHER: 'send-to-photographer',
  PHOTO_SELECTION: {
    REQUEST_SELECTION_AGAIN: 'request-photo-selection-again',
  },

  MARK_AS_PAID_CONFIRM_MODAL: 'mark-as-paid-modal',

  PROMPT_MPIN: 'prompt-mpin',

  PEOPLE: {
    BULK_UPDATE_EVENT_ATTENDEE_LIST: 'bulk-update-event-attendee-list',
  },

  SUBSCRIPTION: {
    PAYMENT: 'subscription-payment',
  },

  COMPANY: {},

  ALBUM: {
    ADD_NEW_ALBUM: 'album-new-album',
  },
  TOUR_MODAL: 'tour-modal',
  TOUR_WELCOME_MODAL: 'tour-welcome-modal',
  PROJECT_COVER_FOCAL_POINT_MODAL: 'project-cover-focal-point-modal',
  FOLDER_COVER_FOCAL_POINT_MODAL: 'folder-cover-focal-point-modal',
  CLIENT: {
    SEARCH: 'search-crm-client',
  },
  HELP_PANEL: 'help-panel',
  INTEGRATION: {
    IMPORT_EVENT: 'integration-import-event',
  },
};

export const EXECUTE_PENDING_FUNCTION = {
  UPDATE_IMAGE_STATUS: 'update-image-status-and-show-hidden-photos-options',
};

export enum FOLDER_STATUS {
  DRAFT = 'DRAFT',
  INCOMPLETE = 'INCOMPLETE',
  WAITING_FOR_APPROVAL = 'REVIEW',
  REVIEW_COMPLETED = 'REVIEW_COMPLETED',
  DONE = 'DONE',
  ARCHIVE = 'ARCHIVE',
}
