import { connect } from 'react-redux';
import { requestingUserIdSelector, usersItemsSelector } from '../UsersDataDuck';
import { UserNames } from './UserNames';

function mapStateToProps(
  state,
  ownProps: {
    userIds: Array<string>;
    avatarStyle?: boolean;
  },
) {
  const { userIds, avatarStyle } = ownProps;
  return {
    userIds,
    avatarStyle,
    users: usersItemsSelector(state),
    requestingUserId: requestingUserIdSelector(state),
  };
}
export default connect(mapStateToProps)(UserNames);
