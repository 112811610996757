import { EventSponsorsService } from '@premagic/core';
import {
  Button,
  BUTTON_STYLES,
  Col,
  FormGroup,
  FormLabel,
  Icon,
  ICON_SIZES,
  Input,
  INPUT_SIZES,
  InputPhoneNumber,
  Row,
  Select,
  Space,
} from '@premagic/myne';
import { useState } from 'react';
import SelectSponsorCategoryContainer from '../sponsor-category/SelectSponsorCategoryContainer';

type Props = {
  projectId: string;
  sponsorId?: string;
  sponsor?: EventSponsorsService.EventSponsorType;
};
const STATUS_OPTIONS = [
  {
    value: EventSponsorsService.EVENT_SPONSORS_PUBLISH_STATUS.PUBLISHED,
    label: 'Yes',
  },
  {
    value: EventSponsorsService.EVENT_SPONSORS_PUBLISH_STATUS.DRAFT,
    label: 'No',
  },
];

export default function EventSponsorForm(props: Props) {
  const { sponsor, sponsorId, projectId } = props;
  const [showAdditionalInfo, setShowOfAdditionalInfo] = useState(false);

  return (
    <div>
      <FormGroup>
        <FormLabel isRequired>Name</FormLabel>
        <Input name="name" autoFocus required placeholder="eg. Microsoft" defaultValue={sponsor?.name} />
      </FormGroup>
      {/* <SponsorFilesSelectorContainer name="logo_asset_id" defaultValue={sponsor?.logo_asset_id} /> */}
      <FormGroup>
        <FormLabel isRequired>Category</FormLabel>
        <SelectSponsorCategoryContainer defaultSponsorCategoryId={sponsor?.category} />
      </FormGroup>
      <Space vertical />
      {!showAdditionalInfo && (
        <Button style={BUTTON_STYLES.LINK} onClick={() => setShowOfAdditionalInfo(true)}>
          <Icon name="plus" size={ICON_SIZES.SM} /> additional information
        </Button>
      )}

      {sponsorId ? (
        <FormGroup>
          <FormLabel isRequired>Show all sponsor content</FormLabel>
          <Select
            name="status"
            size={INPUT_SIZES.SM}
            options={STATUS_OPTIONS}
            isMulti={false}
            value={STATUS_OPTIONS.find((option) => option.value === sponsor?.status)}
          />
        </FormGroup>
      ) : (
        <input type="hidden" name="status" value={EventSponsorsService.EVENT_SPONSORS_PUBLISH_STATUS.PUBLISHED} />
      )}
      <Space vertical />
      {showAdditionalInfo && (
        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <Input name="email" placeholder="eg. john@microsoft.com" defaultValue={sponsor?.email} />
            </FormGroup>
          </Col>
          <Space />
          <Col>
            <FormGroup>
              <FormLabel>Phone</FormLabel>
              <InputPhoneNumber name="phone" defaultValue={sponsor?.phone} />
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
}
