import { EventPosterService } from '@premagic/core';
import { Col, FormGroup, FormLabel, Input, INPUT_SIZES, Row, Space } from '@premagic/myne';
import { PosterWidgetTextType } from '@premagic/poster-creator';
import React from 'react';

type Props = {
  poster: EventPosterService.EventPosterType;
  editWidget: (options: {
    projectId: string;
    poster: EventPosterService.EventPosterType;
    widgetToUpdate: PosterWidgetTextType;
  }) => void;
  projectId: string;
  widget: PosterWidgetTextType;
};

export default function EventPosterFontSizeSelector(props: Props) {
  const { poster, editWidget, projectId, widget } = props;
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    const updatedWidget = Object.assign({}, widget, {
      [name]: Number(value),
    });
    editWidget({ projectId, widgetToUpdate: updatedWidget, poster });
  }

  return (
    <Row>
      <Col size={4}>
        <FormGroup>
          <FormLabel>Size</FormLabel>
          <Input
            name="font_size"
            type="number"
            defaultValue={widget.font_size}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </FormGroup>
      </Col>
      <Space />
      <Col size={4}>
        <FormGroup>
          <FormLabel>Line Height</FormLabel>
          <Input
            name="font_line_height"
            type="number"
            defaultValue={widget.font_line_height}
            onChange={onChange}
            size={INPUT_SIZES.XS}
          />
        </FormGroup>
      </Col>
      <Space />
      <Col size={4}>
        <FormGroup>
          <FormLabel>Boldness</FormLabel>
          <Input name="font_weight" type="number" defaultValue={widget.font_weight} onChange={onChange} step={100} />
        </FormGroup>
      </Col>
    </Row>
  );
}
