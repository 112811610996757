import { connect } from 'react-redux';
import EventAttendeeBadgeFontWeightSelect from './EventAttendeeBadgeFontWeightSelect';
import { selectedWidgetSelector } from '../../../duck/EventAttendeeBadgeDesignDuck';
import {
  editEventAttendeeBadgeWidgetDesigns,
  eventAttendeeBadgeDesignsSelector,
} from '../../../../../../../projects/folder-meta/FoldersMetaDataDuck';

function mapStateToProps(state, ownProps: { projectId: string; folderId: string }) {
  const { projectId, folderId } = ownProps;
  const badgeDesign = eventAttendeeBadgeDesignsSelector(folderId)(state);

  return {
    selectedWidgetForEdit: selectedWidgetSelector(state),
    designData: eventAttendeeBadgeDesignsSelector(folderId)(state),
    posterWidgetDesign: badgeDesign?.widget_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editFont: (projectId, folderId, previousDesign, widgetId, weight) => {
      dispatch(
        editEventAttendeeBadgeWidgetDesigns(dispatch, projectId, folderId, {
          badgeDesignData: previousDesign,
          widgetDesignData: {
            [widgetId]: { weight },
          },
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendeeBadgeFontWeightSelect);
