import React, { useReducer, useState } from 'react';
import {
  Col,
  FormGroup,
  Input,
  INPUT_SIZES,
  List,
  LIST_ITEM_STYLES,
  ListItem,
  Row,
  Select,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  HTML_ICON,
  Divider,
  RealCheckbox,
} from '@premagic/myne';
import {
  CLIENT_PAYMENT_PRESET_OPTIONS,
  clientPresentOptions,
  ClientPresetPaymentDetailsWithData,
  getClientPaymentPresetsForData,
} from './ClientPaymentPresetService';
import { CRMPaymentCategoryType, TAX_TYPES } from '../CRMPaymentService';
import InputCurrencyContainer from '../../../acccount/InputCurrencyContainer';
import DueDateSelector from './DueDateSelector';

type PresetSelectorProps = {
  onSelect: (options: CLIENT_PAYMENT_PRESET_OPTIONS) => void;
};

function PresetSelector(props: PresetSelectorProps): JSX.Element {
  const { onSelect } = props;
  const selectedOption = clientPresentOptions.find((option) => option.value === CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_3);
  return (
    <Select
      size={INPUT_SIZES.MD}
      onChange={(option) => onSelect(option?.value as CLIENT_PAYMENT_PRESET_OPTIONS)}
      options={clientPresentOptions}
      value={selectedOption}
      name="preset"
      isMulti={false}
      autoFocus
    />
  );
}

function getPreviousId(id: number, totalLength: number) {
  // Cycle though the id's
  if (id === totalLength - 1) return id - 1;
  if (id === 0) return totalLength - 1;

  return id + 1;
}
type ClientPaymentPresetItemProps = {
  clientPayments: Array<ClientPresetPaymentDetailsWithData>;
  paymentCategories: Array<CRMPaymentCategoryType>;
  totalRemainingClientPayment: number;
  isMobileUp: boolean;
};

function presetReducer(state, action) {
  const { id, value, totalRemainingClientPayment, remainingPercentage, previousItemId, previousItemValue } =
    action.payload;

  switch (action.type) {
    case 'update-percent':
      return {
        ...state,
        [id]: {
          ...state[id],
          id,
          percent: value,
          amount: Math.ceil(totalRemainingClientPayment * (value / 100)),
        },
      };
    case 'update-amount':
      return {
        ...state,
        [id]: {
          ...state[id],
          id,
          percent: Math.round((value / totalRemainingClientPayment) * 100),
          amount: value,
        },
      };
    case 'update-dueDate':
      return {
        ...state,
        [id]: {
          ...state[id],
          dueDate: value,
        },
      };
    case 'update-taxType':
      return {
        ...state,
        [id]: {
          ...state[id],
          taxable: value,
        },
      };

    default:
      throw new Error();
  }
}

function ClientPaymentPresetItem(props: ClientPaymentPresetItemProps): JSX.Element {
  const { clientPayments, paymentCategories, totalRemainingClientPayment, isMobileUp } = props;
  const totalPaymentsParts = clientPayments.length;
  const [state, dispatch] = useReducer(
    presetReducer,
    clientPayments.reduce(
      (result, payment) => ({
        ...result,
        [payment.id]: {
          amount: payment.amount,
          id: payment.id,
          percent: payment.percentFromQuote,
          dueDate: payment.dueDate,
          taxable: payment.taxable,
        },
      }),
      {},
    ),
  );

  function onChangePercent(id, value) {
    if (totalPaymentsParts === 1) {
      dispatch({
        type: 'update-percent',
        payload: {
          id,
          value,
          totalRemainingClientPayment,
        },
      });
      return;
    }

    const currentTotalPercentage = Object.entries(state).reduce((result, [itemId, item]) => {
      const { percent } = item as { percent: number };
      return result + (id == itemId ? value : percent);
    }, 0);

    const remainingPercentage = 100 - currentTotalPercentage;

    const previousItemId = getPreviousId(id, totalPaymentsParts);

    const previousItemValue = state[previousItemId].percent + remainingPercentage;

    if (previousItemValue !== 0) {
      dispatch({
        type: 'update-percent',
        payload: {
          id,
          value,
          totalRemainingClientPayment,
        },
      });

      dispatch({
        type: 'update-percent',
        payload: {
          id: previousItemId,
          value: previousItemValue,
          totalRemainingClientPayment,
        },
      });
    }
  }

  function onChangeAmount(id, value) {
    if (totalPaymentsParts === 1) {
      dispatch({
        type: 'update-amount',
        payload: {
          id,
          value,
          totalRemainingClientPayment,
        },
      });
      return;
    }

    dispatch({
      type: 'update-amount',
      payload: {
        id,
        value,
        totalRemainingClientPayment,
      },
    });

    // Update previous item based on the remaining amount
    const currentTotalAmount = Object.entries(state).reduce((result, [itemId, item]) => {
      const { amount } = item as { amount: number };
      return result + (id == itemId ? value : amount);
    }, 0);

    const remainingAmount = totalRemainingClientPayment - currentTotalAmount;

    const previousItemId = getPreviousId(id, totalPaymentsParts);
    const previousItemValue = state[previousItemId].amount + remainingAmount;

    dispatch({
      type: 'update-amount',
      payload: {
        id: previousItemId,
        value: previousItemValue > 0 ? previousItemValue : 0, // Handle negative values
        totalRemainingClientPayment,
      },
    });

    if (totalPaymentsParts > 2 && previousItemValue < 0) {
      const secondPreviousItemId = getPreviousId(previousItemId, totalPaymentsParts);
      const secondPreviousItemValue = state[secondPreviousItemId].amount + previousItemValue;
      dispatch({
        type: 'update-amount',
        payload: {
          id: secondPreviousItemId,
          value: secondPreviousItemValue > 0 ? secondPreviousItemValue : 0, // Handle negative values
          totalRemainingClientPayment,
        },
      });
    }
  }

  function onChangeDueDate(id, value) {
    dispatch({
      type: 'update-dueDate',
      payload: {
        id,
        value,
        totalRemainingClientPayment,
      },
    });
  }

  function onChangeTaxType(id, value) {
    const taxTypeValue = value.target.checked ? TAX_TYPES.INCLUDING : TAX_TYPES.NO_TAX;
    dispatch({
      type: 'update-taxType',
      payload: {
        id,
        value: taxTypeValue,
      },
    });
  }

  return (
    <div>
      {clientPayments.map((item) => {
        const { id, categoryId } = item;
        const selectedCategory =
          paymentCategories.find((category) => category.id === categoryId) || paymentCategories[0];

        return (
          <div key={`${totalPaymentsParts}-${id}`}>
            <Space vertical size={2} />
            {!isMobileUp ? (
              <div>
                <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD}>
                  {id + 1}. {selectedCategory.name}
                </Text>
                <Space size={2} />
                <Text size={TEXT_SIZE.SIZE_3}>{HTML_ICON.DASH_SMALL}</Text>
                <Space size={2} />
                <Text>due</Text>
                <Space size={1} />
                <DueDateSelector
                  id={id}
                  value={state[id].dueDate}
                  onChange={(value) => onChangeDueDate(Number(id), value)}
                />
                <Space size={2} vertical />
                <Row>
                  <Col size={4}>
                    <Input
                      size={INPUT_SIZES.XS}
                      type="number"
                      step={1}
                      min={1}
                      max={100}
                      name=""
                      value={state[id].percent}
                      onChange={(e) => onChangePercent(Number(id), Number(e.target.value))}
                    />
                    <Space size={2} />
                    <Text size={TEXT_SIZE.SIZE_3} muted boldness={TEXT_BOLDNESS.BOLD}>
                      %
                    </Text>
                  </Col>
                  <Col size={6} rightAlighed>
                    <InputCurrencyContainer
                      name=""
                      size={INPUT_SIZES.SM}
                      value={state[id].amount}
                      defaultValue={state[id].amount}
                      onChange={(value) => onChangeAmount(Number(id), Number(value))}
                    />
                    <Space vertical size={1} />
                    <Col size={3} rightAlighed>
                      <Row vcenter>
                        <RealCheckbox name="" onChange={(value) => onChangeTaxType(Number(id), value)} />
                        <Space size={2} />
                        <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                          Tax
                        </Text>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <input type="hidden" name={`payments[${id}][amount]`} value={state[id].amount} />
                <input type="hidden" name={`payments[${id}][payment_category]`} value={selectedCategory.id} />
                <input type="hidden" name={`payments[${id}][is_settled]`} value="false" />
                <input type="hidden" name={`payments[${id}][due_date]`} value={state[id].dueDate} />
                <input type="hidden" name={`payments[${id}][taxable]`} value={state[id].taxable} />
                <Space vertical />
                <Divider />
              </div>
            ) : (
              <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
                <Col size={null}>
                  <Text size={TEXT_SIZE.SIZE_4}>{id + 1}</Text>
                </Col>
                <Space />
                <Col size={2} screenSMSize={2}>
                  <Text size={TEXT_SIZE.SIZE_4} ellipsis>
                    {selectedCategory.name}
                  </Text>
                </Col>
                <Space size={2} />
                <Col size={3} screenSMSize={3}>
                  <Text ellipsis>
                    <DueDateSelector
                      id={id}
                      value={state[id].dueDate}
                      onChange={(value) => onChangeDueDate(Number(id), value)}
                    />
                  </Text>
                </Col>
                <Space size={2} />
                <Col size={1} screenSMSize={1}>
                  <Row vcenter>
                    <Input
                      size={INPUT_SIZES.XS}
                      type="number"
                      step={1}
                      min={1}
                      max={100}
                      name=""
                      value={state[id].percent}
                      onChange={(e) => onChangePercent(Number(id), Number(e.target.value))}
                    />
                    <Space size={1} />
                  </Row>
                </Col>
                <Space size={9} />
                <Col size={null} rightAlighed screenSMSize={1}>
                  <RealCheckbox name="" onChange={(value) => onChangeTaxType(Number(id), value)} />
                  <Space size={2} />
                </Col>
                <Col rightAlighed size={null} screenSMSize={2}>
                  <InputCurrencyContainer
                    name=""
                    size={INPUT_SIZES.SM}
                    value={state[id].amount}
                    defaultValue={state[id].amount}
                    onChange={(value) => onChangeAmount(Number(id), Number(value))}
                  />
                </Col>

                <input type="hidden" name={`payments[${id}][amount]`} value={state[id].amount} />
                <input type="hidden" name={`payments[${id}][payment_category]`} value={selectedCategory.id} />
                <input type="hidden" name={`payments[${id}][is_settled]`} value="false" />
                <input type="hidden" name={`payments[${id}][due_date]`} value={state[id].dueDate} />
                <input type="hidden" name={`payments[${id}][taxable]`} value={state[id].taxable} />
              </ListItem>
            )}
          </div>
        );
      })}
    </div>
  );
}

type Props = {
  eventDate: string;
  totalRemainingClientPayment: number;
  paymentCategories: Array<CRMPaymentCategoryType>;
  isMobileUp: boolean;
};

export default function AddClientPaymentsFromPreset(props: Props): JSX.Element | null {
  const { eventDate, totalRemainingClientPayment, paymentCategories, isMobileUp } = props;
  const [selectedPreset, setSelectedPreset] = useState(CLIENT_PAYMENT_PRESET_OPTIONS.SPLIT_3);
  const clientPayments = getClientPaymentPresetsForData(selectedPreset, {
    eventDate,
    quoteAmount: totalRemainingClientPayment,
    paymentCategories,
  });

  return (
    <div>
      <FormGroup>
        <PresetSelector onSelect={setSelectedPreset} />
      </FormGroup>
      <List>
        {isMobileUp && (
          <ListItem style={LIST_ITEM_STYLES.COMPACT_LIST}>
            <Col size={null}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                #
              </Text>
            </Col>
            <Space />
            <Col size={2} screenSMSize={2}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Name
              </Text>
            </Col>
            <Space size={2} />
            <Col size={2}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Due date
              </Text>
            </Col>
            <Space size={16} />
            <Col size={1} screenSMSize={2}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Percent
              </Text>
            </Col>
            <Space size={10} />
            <Col size={1} screenSMSize={2}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Tax
              </Text>
            </Col>
            <Col rightAlighed size={null}>
              <Text size={TEXT_SIZE.SIZE_4} boldness={TEXT_BOLDNESS.BOLD} muted>
                Amount
              </Text>
            </Col>
          </ListItem>
        )}
        <ClientPaymentPresetItem
          key={selectedPreset}
          totalRemainingClientPayment={totalRemainingClientPayment}
          clientPayments={clientPayments}
          paymentCategories={paymentCategories}
          isMobileUp={isMobileUp}
        />
      </List>
    </div>
  );
}
