import { QRCodeBackgroundPosterService } from '@premagic/core';
import {
  BUTTON_TILE_SIZE,
  ButtonTile,
  COLOR_SHADES,
  Grid,
  MODAL_SIZES,
  Modal,
  ModalTitle,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { useMemo } from 'react';
import QRCodeSponsorCreativeItemContainer from '../../sponsor/QRCodeSponsorCreativeItemContainer';

type Props = {
  projectId: string;
  folderId: string;
  show: boolean;
  logoId?: QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS;
  close: () => void;
  selectedCreativeId?: string;
  sponsorCreativesIds: Array<string>;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
  updateSponsorLogo: (
    projectId: string,
    folderId: string,
    qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType,
    sponsorLogoDesigns?: Record<QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS, string>,
  ) => void;
  sponsorLogoForPosterData: QRCodeBackgroundPosterService.QRCodeSponsorLogoDataType;
};

export default function QRCodePosterSponsorLogoSelectorModal(props: Props) {
  const {
    projectId,
    folderId,
    show,
    logoId,
    close,
    sponsorCreativesIds,
    selectedCreativeId,
    qrCodeDesigns,
    updateSponsorLogo,
    sponsorLogoForPosterData,
  } = props;

  function handleSponsorCreativeSelect(creativeId: string) {
    if (!logoId) return;

    const sponsorLogoData = {
      ...sponsorLogoForPosterData,
      [logoId]: creativeId,
    } as Record<QRCodeBackgroundPosterService.QR_CODE_DESIGN_SPONSOR_LOGO_IDS, string>;

    updateSponsorLogo(projectId, folderId, qrCodeDesigns, sponsorLogoData);
    close();
  }

  const sponsorCreativeOptions = useMemo(
    () =>
      sponsorCreativesIds.map((creativeId) => (
        <ButtonTile
          key={creativeId}
          onClick={() => handleSponsorCreativeSelect(creativeId)}
          size={BUTTON_TILE_SIZE.SM_XS}
          active={selectedCreativeId === creativeId}
        >
          <Space size={2}>
            <QRCodeSponsorCreativeItemContainer id={creativeId} showCreativeStatus />
          </Space>
        </ButtonTile>
      )),
    [sponsorCreativesIds, selectedCreativeId],
  );

  return (
    <Modal show={show} close={close} size={MODAL_SIZES.XL} showFooterCloseButton>
      <ModalTitle>Select sponsor</ModalTitle>
      {sponsorCreativeOptions?.length === 0 ? (
        <Text center block color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_2}>
          No sponsor creative added in the gallery footer.
        </Text>
      ) : (
        <Grid colSize={134}>
          {sponsorCreativeOptions}
          <ButtonTile
            onClick={() => handleSponsorCreativeSelect('')}
            size={BUTTON_TILE_SIZE.SM_XS}
            active={!selectedCreativeId}
          >
            <Space size={2}>
              <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.BOLD} muted>
                No logo
              </Text>
            </Space>
          </ButtonTile>
        </Grid>
      )}
    </Modal>
  );
}
