import React from 'react';
import { FormLabel, INPUT_SIZES, Select } from '@premagic/myne';
import { EventsService } from '@premagic/core';

const statusOptions = Object.values(EventsService.EVENT_STATUS_TYPE_DETAILS).map((type) => ({
  value: type.id,
  label: type.title,
}));

type Props = {
  status?: EventsService.EVENT_STATUS;
  onChange: (status: EventsService.EVENT_STATUS) => void;
  isLoading?: boolean;
  autoFocus?: boolean;
};

export default function EventStatusSelector(props: Props): JSX.Element {
  const { status, onChange, isLoading, autoFocus } = props;
  const selectedOption = statusOptions.find((option) => option.value === status) || statusOptions[0];
  return (
    <div>
      <FormLabel isRequired>Event status</FormLabel>
      <Select
        disabled={isLoading}
        options={statusOptions}
        value={selectedOption}
        name="event[status]"
        onChange={(e) => onChange(e?.value as EventsService.EVENT_STATUS)}
        isMulti={false}
        size={INPUT_SIZES.SM}
        autoFocus={autoFocus}
      />
    </div>
  );
}
