import { getAccountMgmntUrl, getAlbumAPIUrl, getAPIUrl, getImageStoreUrl } from './AppServices';

const ACCOUNT_ROOT = `${getAccountMgmntUrl()}/account-management`;
const ALBUM_ROOT = `${getAlbumAPIUrl()}/albums`;
const IMAGE_STORE_ROOT = getImageStoreUrl();
const APP_ROOT = getAPIUrl();

export type UrlServiceFolderType = 'SELECTION' | 'HIGHLIGHT' | 'SAVE_THE_DATE' | 'PROPOSAL' | 'PORTFOLIO' | 'BRANDING';

export const API_URLS = {
  CLIENT_AUTH: {
    SIGNUP: `${APP_ROOT}/my/signup/`,
    SIGNUP_CONFIRM: `${APP_ROOT}/my/confirm/`,
  },
  CLIENT_FOLDERS: {
    DETAIL: (shareId: string) => `${APP_ROOT}/view/${shareId}/`,
    FILE_ORDER: (shareId: string) => `${APP_ROOT}/view/${shareId}/i/order/`,
  },
  CUSTOMER_USERS: {
    CURRENT_USER: `${APP_ROOT}/client/`,
  },
  CRM: {
    CALENDAR: `${ACCOUNT_ROOT}/views/calendar/`,
    EVENTS: `${ACCOUNT_ROOT}/views/events/`,
    EVENTS_COUNTS_BY_STATUS: `${ACCOUNT_ROOT}/reports/events/event_status_summary/`,
  },
  CRM_CLIENT: {
    LIST: `${ACCOUNT_ROOT}/clients/`,
    ROOT: (clientId: string) => `${ACCOUNT_ROOT}/clients/${clientId}/`,
    SEARCH: `${ACCOUNT_ROOT}/clients/search/`,
  },
  CRM_USERS: {
    LIST: `${ACCOUNT_ROOT}/users/`,
    REQUESTING_USER: `${ACCOUNT_ROOT}/users/me/`,
    DETAILS: (userId: string) => `${ACCOUNT_ROOT}/users/${userId}/`,
  },
  CRM_EVENTS: {
    LIST: `${ACCOUNT_ROOT}/clients/all/events/`,
    ROOT: (clientId: string) => `${ACCOUNT_ROOT}/clients/${clientId}/events/`,
    DETAILS: (eventId: string) => `${ACCOUNT_ROOT}/clients/all/events/${eventId}/`,
    EDIT: (clientId: string, eventId: string) => `${ACCOUNT_ROOT}/clients/${clientId}/events/${eventId}/`,
    CREATE_SAMPLE: `${ACCOUNT_ROOT}/clients/all/events/create_sample/`,
  },
  CRM_OCCASIONS: {
    LIST: `${ACCOUNT_ROOT}/events/all/occasions/`,
    ROOT: (eventId: string) => `${ACCOUNT_ROOT}/events/${eventId}/occasions/`,
    DETAILS: (eventId: string, occasionId: string) => `${ACCOUNT_ROOT}/events/${eventId}/occasions/${occasionId}/`,
    USER_OCCASIONS: (eventId: string) => `${ACCOUNT_ROOT}/events/${eventId}/occasions/is_user_available/`,
  },
  CRM_PAYMENTS: {
    REMINDER_CLIENT: (eventId: string) => `${ACCOUNT_ROOT}/events/${eventId}/payments/remind_to_pay_dues/`,
    EVENT_ROOT: (eventId: string) => `${ACCOUNT_ROOT}/events/${eventId}/payments/`,
    DETAILS: (paymentId: string, eventId: string) => `${ACCOUNT_ROOT}/events/${eventId}/payments/${paymentId}/`,
    ACKNOWLEDGEMENT: (eventId: string) => `${ACCOUNT_ROOT}/events/${eventId}/payments/send_payment_acknowledgement/`,
  },
  CRM_PAYMENT_CATEGORY: {
    LIST: `${ACCOUNT_ROOT}/views/paymentcategory/`,
  },
  CRM_LABELS: {
    ROOT: (entityType: string) => `${ACCOUNT_ROOT}/labels/entity/${entityType}/`,
    DETAILS: (entityType: string, labelId: number) => `${ACCOUNT_ROOT}/labels/entity/${entityType}/${labelId}/`,
    EVENT_LABELS: (clientId: string, eventId: string) =>
      `${ACCOUNT_ROOT}/clients/${clientId}/events/${eventId}/labels/`,
    OCCASION_LABELS: (eventId: string, occasionId: string) =>
      `${ACCOUNT_ROOT}/events/${eventId}/occasions/${occasionId}/labels/`,
  },
  CRM_WORKFLOW: {
    ROOT: `${ACCOUNT_ROOT}/workflow/`,
    DETAILS: (workflowId: number) => `${ACCOUNT_ROOT}/workflow/${workflowId}/`,
    TASK_GROUP_ROOT: (workflowId: number) => `${ACCOUNT_ROOT}/workflow/${workflowId}/taskgroup/`,
    TASK_GROUP_DETAILS: (workflowId: number, taskgroupId: number) =>
      `${ACCOUNT_ROOT}/workflow/${workflowId}/taskgroup/${taskgroupId}`,
    TASK_GROUP_ORDER: (workflowId: number) => `${ACCOUNT_ROOT}/workflow/${workflowId}/taskgroup/change_order/`,
    TASKS_ROOT: (workflowId: number, taskgroupId: number) =>
      `${ACCOUNT_ROOT}/workflow/${workflowId}/taskgroup/${taskgroupId}/task/`,
    TASKS_DETAILS: (workflowId: number, taskgroupId: number, taskId: number) =>
      `${ACCOUNT_ROOT}/workflow/${workflowId}/taskgroup/${taskgroupId}/task/${taskId}`,
    CHANGE_TASKS_ORDER: (workflowId: number, taskgroupId: number) =>
      `${ACCOUNT_ROOT}/workflow/${workflowId}/taskgroup/${taskgroupId}/task/change_order/`,
  },
  ACCOUNT_FINANCIALS: {
    COUNTS: `${ACCOUNT_ROOT}/reports/payments/snapshot/`,
    LIST: (
      type:
        | 'over_due_payments_by_event'
        | 'upcoming_payments_by_event'
        | 'total_expenses_by_event'
        | 'unsettled_expenses_by_event',
    ) => `${ACCOUNT_ROOT}/reports/payments/${type}/`,
  },
  ACCOUNT_CLIENT_SETTINGS: {
    ROOT: `${APP_ROOT}/api/client/settings/`,
  },
  PROJECTS: {
    LIST: `${APP_ROOT}/api/customer/projects/`,
    ROOT: `${APP_ROOT}/project/`,
    DETAIL: (id: string) => `${APP_ROOT}/project/${id}/`,
    RENAME: (id: string) => `${APP_ROOT}/project/${id}/rename/`,
    ARCHIVE: (id: string) => `${APP_ROOT}/project/${id}/archive/`,
    META: (id: string) => `${APP_ROOT}/project/${id}/meta/`,
    FOR_EVENT: (eventId: string) => `${APP_ROOT}/project/event/${eventId}/`,
    MPIN: (projectId: string) => `${APP_ROOT}/project/${projectId}/lock/`,
  },
  PORTFOLIO: {
    ROOT: `${APP_ROOT}/portfolio/projects/`,
    SHOW_IN_PORTFOLIO: (projectId: string) => `${APP_ROOT}/portfolio/projects/${projectId}/`,
  },
  PROJECT: {
    STATS_FETCH: (id: string) => `${APP_ROOT}/api/customer/project/${id}/stats/`,
    STATS_SPONSORS_FETCH: (id: string) => `${APP_ROOT}/api/customer/project/${id}/stats/sponsors/`,
    FETCH_ITEMS_WITH_SHARE_ID: (shareId) => `${APP_ROOT}/onedelivery/view/share/${shareId}/`,
    BACKGROUND_STATUS: (id) => `${APP_ROOT}/projects/${id}/background_task_status/`,
    PROJECT_STATUS: (id) => `${APP_ROOT}/projects/${id}/project_stats/`,
    CLONE: (id) => `${APP_ROOT}/project/${id}/clone/`,
  },
  REPORT: {
    PROJECT_INSIGHT: `${APP_ROOT}/api/customer/projects/`,
  },
  PROJECTS_FOR_CLIENT: {
    DETAILS: (photographerId: string, projectId: string) =>
      `${APP_ROOT}/customer/${photographerId}/project/${projectId}/`,
    TRIGGER_DOWNLOAD: (photographerId: string, projectId: string, folderType: string) =>
      `${APP_ROOT}/customer/${photographerId}/project/${projectId}/${folderType.toLowerCase()}/download/`,
  },
  FOLDER_GALLERY: {
    ORDER: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/${folderId}/image/order/`,
  },
  ACCOUNT: {
    ROOT: `${ACCOUNT_ROOT}/accounts/`,
    DETAILS: (accountId) => `${ACCOUNT_ROOT}/accounts/${accountId}/`,
  },
  COMPANY: {
    ROOT: `${APP_ROOT}/api/customer/`,
    STATUS: `${APP_ROOT}/api/customer/stats/`,
    SETTINGS: `${APP_ROOT}/api/customer/settings/`,
  },
  PRICING_PLANS: {
    ROOT: `${APP_ROOT}/api/customer/plans/`,
  },
  SUBSCRIPTION: {
    ROOT: `${APP_ROOT}/api/customer/subscription/`,
    CAPTURE_PAYMENT: `${APP_ROOT}/api/customer/subscription/validate/`,
    INVOICES: `${APP_ROOT}/api/customer/subscription/invoices/`,
  },
  PUBLIC_PAYMENT: {
    PRODUCTS: (shareId: string) => `${APP_ROOT}/view/${shareId}/commerce/skus/`,
    CALCULATE_ORDER_VALUE: (shareId: string) => `${APP_ROOT}/view/${shareId}/commerce/calculate_order_value/`,
    ORDER: (shareId: string) => `${APP_ROOT}/view/${shareId}/commerce/order/`,
    CAPTURE_PAYMENT: (shareId: string) => `${APP_ROOT}/view/${shareId}/commerce/payment_capture/`,
  },
  FOLDER: {
    ROOT: (projectId) => `${APP_ROOT}/project/${projectId}/folder/`,
    DETAILS: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/${folderId}/`,
    RENAME: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/${folderId}/rename/`,
    DELETE: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/${folderId}/delete/`,
    META: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/${folderId}/meta/`,
    UPDATE_COVER_PHOTO: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/${folderId}/meta/`,
    DOWNLOAD_WITH_SHARE_ID: (shareId) => `${APP_ROOT}/download/share/${shareId}/`,
    SHARE: () => `${APP_ROOT}/v2/share/`,
    SYSTEM_FOLDER: (folderType: UrlServiceFolderType) => `${APP_ROOT}/api/customer/folder/${folderType}/`,
    SYSTEM_FOLDER_DETAILS: (folderType: UrlServiceFolderType, folderId: string) =>
      `${APP_ROOT}/api/customer/folder/${folderType}/${folderId}/`,
    SYSTEM_FOLDER_META: (folderType: UrlServiceFolderType, folderId: string) =>
      `${APP_ROOT}/api/customer/folder/${folderType}/${folderId}/meta/`,
  },
  FILES: {
    FETCH_ALL: (projectId: string, imagesStatus: 'approved' | 'rejected' | 'all') =>
      `${APP_ROOT}/view/project/${projectId}/${imagesStatus}/images/`,
    FETCH_ALL_FROM_SELECTIONS_APP: (shareId: string, imagesStatus: 'approved' | 'rejected' | 'all') =>
      `${APP_ROOT}/view/${shareId}/${imagesStatus}/images/`,
    DELETE: (projectId: string, folderId: string) => `${APP_ROOT}/project/${projectId}/${folderId}/image/delete/`,
    UPDATE: (projectId: string, folderId: string) => `${APP_ROOT}/project/${projectId}/${folderId}/asset/update/`,
    FAVORITE: (projectId: string) => `${APP_ROOT}/project/${projectId}/image/favorite/`,
    META: (projectId: string, folderId: string, fileId: string) =>
      `${APP_ROOT}/project/${projectId}/${folderId}/asset/${fileId}/meta/`,
    FETCH_FOR_PEOPLE_FACE_PUBLIC: (shareId: string, faceId: string) => `${APP_ROOT}/view/${shareId}/faces/${faceId}/`,
    FETCH_FOR_PEOPLE_FACE: (projectId: string, faceId: string) => `${APP_ROOT}/project/${projectId}/faces/${faceId}/`,
    FETCH_FOR_PEOPLE_TOGETHER_FACE: (projectId: string) => `${APP_ROOT}/project/${projectId}/together_faces/assets/`,
    FETCH_FOR_PEOPLE_FACE_EXTERNAL: (shareId: string, externalUserId: string) =>
      `${APP_ROOT}/view/${shareId}/faces/external/${externalUserId}/`,
    PUBLIC_GALLERY_PHOTOS: (shareId: string) => `${APP_ROOT}/widgets/view/${shareId}/assets/`,
    FETCH_DAM_FILTER_LABELS: (projectId: string) => `${APP_ROOT}/project/${projectId}/assets/attributes/`,
  },
  SIMPLE_UPLOADER: {
    ROOT: `${APP_ROOT}/sync/`,
  },
  MEGA_UPLOADER: {
    ROOT: `${APP_ROOT}/resumable_upload/`,
    META: `${APP_ROOT}/resumable_upload/metadata/`,
  },
  CUSTOMER_LEAD: {
    CREATE_FROM_SHARE_ID: (shareId) => `${APP_ROOT}/customer/${shareId}/leads/`,
    CREATE_PUBLIC: `${APP_ROOT}/customer/leads/`,
  },
  ALBUM_CREATOR: {
    ALBUMS: `${ALBUM_ROOT}/`,
    ALBUM_DETAILS: (id: string) => `${ALBUM_ROOT}/${id}/`,
    ALBUM_PAGES: (id: string) => `${ALBUM_ROOT}/${id}/pages/`,
    ALBUM_PAGE_DETAILS: (albumId: string, id: string) => `${ALBUM_ROOT}/${albumId}/pages/${id}/`,
  },
  PROPOSAL_VARIABLES: {
    LIST: `${APP_ROOT}/api/customer/deck/template/variables/`,
  },
  PROPOSAL_DECK: {
    LIST: (projectId) => `${APP_ROOT}/project/${projectId}/deck/`,
    DETAILS: (projectId, deckId) => `${APP_ROOT}/project/${projectId}/deck/${deckId}/`,
    VERSION: (projectId, deckId) => `${APP_ROOT}/project/${projectId}/deck/${deckId}/version/`,
    CLIENT_UPDATE_REVIEWS: (shareId: string) => `${APP_ROOT}/view/proposal/share/${shareId}/review/`,
  },
  PROPOSAL_DECK_SLIDE: {
    LIST: (projectId, deckId) => `${APP_ROOT}/project/${projectId}/deck/${deckId}/slide/`,
    DETAILS: (projectId, deckId, slideId) => `${APP_ROOT}/project/${projectId}/deck/${deckId}/slide/${slideId}/`,
  },
  PROPOSAL_APP: {
    DETAIL: (shareId: string) => `${APP_ROOT}/view/proposal/share/${shareId}/`,
  },
  PEOPLE_FACES: {
    LIST_PUBLIC: (shareId: string) => `${APP_ROOT}/view/${shareId}/faces/`,
    LIST: (projectId: string) => `${APP_ROOT}/project/${projectId}/faces/`,
    FILE_DETAILS: (shareId: string, fileId: string) => `${APP_ROOT}/view/${shareId}/faces/assets/${fileId}/`,
    FACE_URL: (faceId: string) => `${IMAGE_STORE_ROOT}faces/${faceId}.jpg`,
  },
  PEOPLE_NETWORKING: {
    PEOPLE_DETAILS: (shareId: string, faceId: string) => `${APP_ROOT}/view/${shareId}/networking/faces/${faceId}/`,
    UPDATE_PEOPLE_DETAILS: (shareId: string, registrationId: string, faceId: string) =>
      `${APP_ROOT}/view/${shareId}/networking/registrations/${registrationId}/faces/${faceId}/`,
    GLOBAL_PEOPLE_LIST: (shareId: string) => `${APP_ROOT}/view/${shareId}/networking/registrations/`,
  },
  SELECTION_SIGNATURE_PUBLIC: {
    FILES: (shareId: string) => `${APP_ROOT}/view/${shareId}/assets/`,
    GUEST_GALLERY_PHOTO_DOWNLOAD: (shareId: string, faceId: string) =>
      `${APP_ROOT}/download/share/${shareId}/face/${faceId}/`,
  },
  PORTFOLIO_APP: {
    DETAIL: (domain) => `${APP_ROOT}/view/portfolio/${domain}/company/`,
    HIGHLIGHT: (domain) => `${APP_ROOT}/view/portfolio/${domain}/highlights/`,
  },
  CLIENT_WEBSITE: {
    ROOT: (projectId) => `${APP_ROOT}/project/${projectId}/website/`,
    DETAILS: (projectId, folderId) => `${APP_ROOT}/project/${projectId}/website/${folderId}/`,
    RSVP_REGISTER: (domain, websiteUrlId) => `${APP_ROOT}/event_registration/${domain}/${websiteUrlId}/register/`,
    RSVP_REGISTER_USERS: (projectId) => `${APP_ROOT}/client/projects/${projectId}/event-registration/`,
    SPONSORS_LIST: (domain, websiteId) => `${APP_ROOT}/view/sponsor/${domain}/${websiteId}/`,
  },
  CUSTOM_EVENT_FORM_FIELDS: {
    ROOT: (projectId) => `${APP_ROOT}/project/${projectId}/form-fields/`,
    DETAILS: (projectId, id) => `${APP_ROOT}/project/${projectId}/form-fields/${id}/`,
    PUBLIC_FORM_FIELDS: (domain, websiteUrlId) =>
      `${APP_ROOT}/event_registration/${domain}/${websiteUrlId}/form-fields/`,
  },
  STORE: {
    LIST: `${ACCOUNT_ROOT}/store/`,
    DETAILS: (id: string) => `${ACCOUNT_ROOT}/store/${id}/`,
  },
  EVENT_SPONSORS: {
    LIST: (projectId) => `${APP_ROOT}/project/${projectId}/sponsor/`,
    DETAILS: (projectId, sponsorId) => `${APP_ROOT}/project/${projectId}/sponsor/${sponsorId}/`,
    LIST_PUBLIC: (shareId) => `${APP_ROOT}/view/sponsor/share/${shareId}/`,
    CATEGORY_ROOT: `${APP_ROOT}/sponsor/category/`,
    CATEGORY_DETAILS: (sponsorCategoryId: string) => `${APP_ROOT}/sponsor/category/${sponsorCategoryId}/`,
  },
  EVENT_EXHIBITORS: {
    LIST: (projectId) => `${APP_ROOT}/project/${projectId}/exhibitor/`,
    DETAILS: (projectId, exhibitorId) => `${APP_ROOT}/project/${projectId}/exhibitor/${exhibitorId}/`,
    // LIST_PUBLIC: (shareId) => `${APP_ROOT}/view/exhibitor/share/${shareId}/`,
    LIST_PUBLIC: (domain, websiteUrlId) => `${APP_ROOT}/view/exhibitor/${domain}/${websiteUrlId}/`,
  },
  SPONSOR_CREATIVES: {
    LIST: (projectId, sponsorId) => `${APP_ROOT}/project/${projectId}/sponsor/${sponsorId}/creative/`,
    DETAILS: (projectId, sponsorId, creativeId) =>
      `${APP_ROOT}/project/${projectId}/sponsor/${sponsorId}/creative/${creativeId}/`,
  },
  EVENT_ATTENDEES: {
    LIST: (projectId) => `${APP_ROOT}/project/${projectId}/event-registration/`,
    SEARCH: (projectId) => `${APP_ROOT}/project/${projectId}/event-registration/checkin/selfie/`,
    SEARCH_FROM_SHARE_ID: (shareId: string) => `${APP_ROOT}/view/${shareId}/checkin/selfie/`,
    SEARCH_PUBLIC: (domain, websiteUrlId) => `${APP_ROOT}/event_registration/${domain}/${websiteUrlId}/checkin/selfie/`,
    BULK: (projectId) => `${APP_ROOT}/project/${projectId}/event-registration/bulk/`, // this is import
    DETAILS: (projectId, registrationId) => `${APP_ROOT}/project/${projectId}/event-registration/${registrationId}/`,
    BULK_UPDATE_DISPLAY_ID: (projectId) =>
      `${APP_ROOT}/project/${projectId}/event-registration/bulk-update-display-id/`,
    ROOT__FROM_WEBSITE: (domain, websiteUrlId) => `${APP_ROOT}/event_registration/${domain}/${websiteUrlId}/register/`,
    DETAILS_FROM_WEBSITE: (domain, websiteUrlId, registrationId) =>
      `${APP_ROOT}/event_registration/${domain}/${websiteUrlId}/register/${registrationId}/`,
    LIST__FROM_CLIENT_APP: (projectId) => `${APP_ROOT}/client/projects/${projectId}/event-registration/`,
    REGISTER__SIGNATURE_ALBUM: (shareId) => `${APP_ROOT}/event_registration/${shareId}/register/`,
  },
  EVENT_POSTERS: {
    LIST: (projectId) => `${APP_ROOT}/project/${projectId}/poster/`,
    DETAILS: (projectId, posterId) => `${APP_ROOT}/project/${projectId}/poster/${posterId}/`,
    LIST__FROM_WEBSITE: (domain, websiteUrlId) => `${APP_ROOT}/view/savethedate/${domain}/${websiteUrlId}/poster/`,
    LIST__FROM_SHARE_ID: (shareId) => `${APP_ROOT}/view/poster/share/${shareId}/`,
  },

  GUEST_GALLERY: {
    TRIGGER_DOWNLOAD: (photographerId: string, projectId: string, faceId: string) =>
      `${APP_ROOT}/customer/${photographerId}/project/${projectId}/download/face/${faceId}/`,
  },
  PRODUCT_FEEDBACK: {
    CREATE: (shareId: string) => `${APP_ROOT}/product_feedback/${shareId}/`,
    LIST: (projectId: string) => `${APP_ROOT}/project/${projectId}/product_feedback/`,
    DETAILS: (projectId: string, feedbackId: string) =>
      `${APP_ROOT}/project/${projectId}/product_feedback/${feedbackId}/`,
  },
  SOCIAL_AUTH: {
    LOGIN_PAGE: (network, redirectUri) =>
      `${APP_ROOT}/view/savethedate/social-auth/${network}/?client_redirect_url=${redirectUri}`,
    PROFILE: (network) => `${APP_ROOT}/view/savethedate/social-auth/${network}/profile/`,
    POST: (network) => `${APP_ROOT}/view/savethedate/social-auth/${network}/post/`,
  },
  EXTERNAL_UPLOADER: {
    UPLOAD: (projectId: string) => `${APP_ROOT}/cloud_services/${projectId}/import/`,
  },
  POSTER_ADVOCATES: {
    FETCH: (projectId: string) => `${APP_ROOT}/project/${projectId}/poster/advocate/`,
    FETCH_FOR_POSTER: (projectId: string, posterId: string) =>
      `${APP_ROOT}/project/${projectId}/poster/${posterId}/advocate/`,
    DETAILS: (projectId: string, posterId: string, advocateId: string) =>
      `${APP_ROOT}/project/${projectId}/poster/${posterId}/advocate/${advocateId}/`,
  },
  ATTENDEE_MEMORY_TEMPLATE: {
    LIST: (projectId: string) => `${APP_ROOT}/project/${projectId}/memory-templates/`,
    LIST_PUBLIC: (shareId: string) => `${APP_ROOT}/view/${shareId}/memory-templates/`,
    DETAILS: (projectId: string, templateId: string) =>
      `${APP_ROOT}/project/${projectId}/memory-templates/${templateId}/`,
  },
  ATTENDEE_MEMORY: {
    LIST: (projectId: string) => `${APP_ROOT}/project/${projectId}/memories/`,
    LIST_PUBLIC: (shareId: string) => `${APP_ROOT}/view/${shareId}/memories/`,
    DETAILS: (projectId: string, memoryId: string) => `${APP_ROOT}/project/${projectId}/memories/${memoryId}/`,
    LIST_FOR_ATTENDEE: (projectId: string, registrationId: string) =>
      `${APP_ROOT}/project/${projectId}/registration/${registrationId}/memories/`,
    DETAILS_FOR_ATTENDEE: (projectId: string, registrationId: string, memoryId: string) =>
      `${APP_ROOT}/project/${projectId}/registration/${registrationId}/memories/${memoryId}/`,
    LIST_FOR_ATTENDEE_PUBLIC: (shareId: string, registrationId: string) =>
      `${APP_ROOT}/view/${shareId}/registration/${registrationId}/memories/`,
  },
  EVENT_CHECKIN_ZONES: {
    ROOT: (projectId: string) => `${APP_ROOT}/project/${projectId}/zones/`,
    DETAILS: (projectId: string, zoneId: string) => `${APP_ROOT}/project/${projectId}/zones/${zoneId}/`,
    STATS: (projectId: string) => `${APP_ROOT}/project/${projectId}/zones/stats/`,
    LIST_PUBLIC: (domain: string, websiteId: string) => `${APP_ROOT}/event_checkin/${domain}/${websiteId}/zones`,
    CHECKIN_CHECKOUT: (domain: string, websiteId: string) => `${APP_ROOT}/event_checkin/${domain}/${websiteId}/`,
    USER_STATUS_INFO: (domain: string, websiteId: string, registrationId: string) =>
      `${APP_ROOT}/event_checkin/${domain}/${websiteId}/registration/${registrationId}/stats/`,
  },
  INTEGRATIONS: {
    EVENTS_LIST: (platform: string) => `${APP_ROOT}/integrations/${platform}/events/`,
    EVENT_CUSTOM_FIELDS: (platform: string, eventId: string) =>
      `${APP_ROOT}/integrations/${platform}/fields/${eventId}/`,
    CONNECT_EVENT: `${APP_ROOT}/integrations/connect/`,
    SYNC_EVENT: `${APP_ROOT}/integrations/sync/`,
  },
  CONTENT_GENERATOR: {
    CREATE_PUBLIC: (shareId: string) => `${APP_ROOT}/view/${shareId}/generate/`,
    CREATE_FROM_WEBSITE: (domain: string, websiteId: string) => `${APP_ROOT}/view/generate/${domain}/${websiteId}/`,
  },
};

export function getPromiseBucket(url: string): string | null {
  let absoluteUrl = url;
  if (url.indexOf('?') > 0) {
    absoluteUrl = url.substring(0, url.indexOf('?'));
  }

  switch (absoluteUrl) {
    case API_URLS.CRM_EVENTS.LIST:
    case API_URLS.CRM_OCCASIONS.LIST:
    case API_URLS.CRM.CALENDAR:
    case API_URLS.CRM.EVENTS:
      return 'CRM';
    case API_URLS.ACCOUNT_FINANCIALS.LIST('upcoming_payments_by_event'):
    case API_URLS.ACCOUNT_FINANCIALS.LIST('unsettled_expenses_by_event'):
    case API_URLS.ACCOUNT_FINANCIALS.LIST('total_expenses_by_event'):
    case API_URLS.ACCOUNT_FINANCIALS.LIST('over_due_payments_by_event'):
      return 'PAYMENT';

    default:
      return null;
  }
}
