import { connect } from 'react-redux';
import { isWindowPanelOpenSelector, toggleWindowPanelVisibility } from '../../../../../../common/WindowPanelDuck';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { eventProjectSelectorSelector } from '../../../projects/AccountProjectsDataDuck';
import { saveProjectMetaData } from '../../../projects/project-meta/ProjectsMetaDataDuck';
import SelectDefaultLanguagePanel from './SelectDefaultLanguagePanel';

const PANEL_KEY = LOADINGS.EVENT.SHOW_LANGUAGE_SELECTOR_PANEL;
const COMMON_KEY = LOADINGS.PROJECT_META.UPDATE;

function mapStateToProps(
  state,
  ownProps: {
    eventId: string;
  },
) {
  const { eventId } = ownProps;

  const project = eventProjectSelectorSelector(eventId)(state);

  return {
    eventId,
    projectId: project?.project_id,
    show: isWindowPanelOpenSelector(PANEL_KEY)(state),
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProjectMeta: (projectId, formResponse) => {
      dispatch(saveProjectMetaData(dispatch, projectId, formResponse, {}));
    },
    closePanel: () => {
      dispatch(toggleWindowPanelVisibility(dispatch, PANEL_KEY, false));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectDefaultLanguagePanel);
