import React, { useEffect, useState } from 'react';
import {
  Form,
  FormErrorType,
  InnerPage,
  InnerPageContent,
  Loading,
  LoadingBox,
  Row,
  Space,
  InnerPageContentMainCol,
  InnerPageContentSidebar,
  HighlightPreview,
  Alert,
  ALERT_STYLES,
  ALERT_SIZES,
  FormResponseType,
  Col,
  Position,
  Card,
  InnerPageHeader,
  Button,
  BUTTON_STYLES,
  Icon,
  ICON_SIZES,
  ICON_ACCENT_STYLES,
  FormFooter,
} from '@premagic/myne';

import { ClientSettingsService, PresetDesignService, ProjectMetaService } from '@premagic/core';
import HighlightDesignCoverLayoutSelectorContainer from '../../projects/highlight-folders/highlight-gallery-design/HighlightDesignCoverLayoutSelectorContainer';
import HighlightDesignTitleAndLogoSettingsContainer from '../../projects/highlight-folders/highlight-gallery-design/HighlightDesignTitleAndLogoSettingsContainer';
import PremiumFeatureIcon from '../PremiumFeatureIcon';
import PresetDesignSelectorContainer from '../../preset-design-selector/PresetDesignSelectorContainer';
import GalleryDesignCustomizationSettingsContainer from '../../projects/highlight-folders/highligh-folder-cover/GalleryDesignCustomizationSettingsContainer';
import GlobalSignatureGalleryLayoutContainer from './GlobalSignatureGalleryLayoutContainer';
import APP_URLS from '../../../services/AppRouteURLService';

type Props = {
  clientSettings: ClientSettingsService.ClientSettingsType;
  isLoading: boolean;
  savePreferences: (data: Record<string, any>) => void;
  companyLogo?: string;
  companyName: string;
  initFontData: (fontFamily: string, secondaryFontFamily?: string) => void;
  errors?: FormErrorType;
  isFontLoading?: boolean;
  hasHighlightCustomizationFeature: boolean;
  updateThemeVariables: (backgroundColor: string, fontColor: string, accentColor: string) => void;
  isSaving?: boolean;
};

export default function GlobalSignatureAlbumDesignPage(props: Props) {
  const {
    clientSettings,
    savePreferences,
    isLoading,
    companyLogo,
    companyName,
    initFontData,
    errors,
    isFontLoading,
    hasHighlightCustomizationFeature,
    updateThemeVariables,
    isSaving,
  } = props;

  const previewTitle = 'Rahul & Riya';

  const [fontFamilyForSignatureAlbum, setFontFamilyForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY] ||
      'Playfair Display',
  );

  const [secondaryFontFamilyForSignatureAlbum, setSecondaryFontFamilyForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY],
  );

  const [backgroundColorForSignatureAlbum, setBackgroundColorForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR] || '#fff',
  );

  const [fontColorForSignatureAlbum, setFontColorForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR] || '#4d6178',
  );

  const [coverLayoutForSignatureAlbum, setCoverLayoutForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT] ||
      ProjectMetaService.HIGHLIGHT_DESIGN_COVER_LAYOUTS.DEFAULT,
  );

  const [isCoverTitleHiddenForSignatureAlbum, setIsCoverTitleHiddenForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_TITLE] || false,
  );

  const [isCoverLogoHiddenForSignatureAlbum, setIsCoverLogoHiddenForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_LOGO] || false,
  );

  const [accentColorForSignatureAlbum, setAccentColorForSignatureAlbum] = useState(
    clientSettings?.[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR] || '#047af0',
  );

  const [galleryPreset, setGalleryPreset] = useState('');

  function handleOnSubmit(response: FormResponseType) {
    const { font_family: fontFamily, font_source: frontSource } = response.data;
    savePreferences({
      data: {
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY]: fontFamily,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_SECONDARY_FONT_FAMILY]:
          secondaryFontFamilyForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_FAMILY_SOURCE]: frontSource,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_BACKGROUND_COLOR]:
          backgroundColorForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_PRIMARY_FONT_COLOR]:
          fontColorForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_LAYOUT]: coverLayoutForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_TITLE]:
          isCoverTitleHiddenForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_COVER_HIDE_LOGO]:
          isCoverLogoHiddenForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ACCENT_COLOR]: accentColorForSignatureAlbum,
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE]:
          response.data[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_THUMBNAIL_SIZE],
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE]: Number(
          response.data[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_GALLERY_GRID_SPACE],
        ),
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT]:
          response.data[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_ENDING_TEXT],
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT]:
          response.data[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.HIGHLIGHT_DESIGN_WELCOME_TEXT],
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED]:
          response.data[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW_ENABLED] === 'true',
        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW]:
          response.data[ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FOLDER_THUMBNAIL_VIEW],
      },
    });
  }

  function handleLayoutChange(value) {
    setCoverLayoutForSignatureAlbum(value);
  }

  function handleCoverOptionFormSubmit(data) {
    setIsCoverTitleHiddenForSignatureAlbum(data.data.hide_cover_title);
    setIsCoverLogoHiddenForSignatureAlbum(data.data.hide_cover_logo);
  }

  function handlePresetDesignChange(value: PresetDesignService.PresetDesignOption) {
    setBackgroundColorForSignatureAlbum(value.backgroundColor);
    setFontFamilyForSignatureAlbum(value.primaryFontFamily);
    setSecondaryFontFamilyForSignatureAlbum(value.secondaryFontFamily);
    setFontColorForSignatureAlbum(value.fontColor);
    setAccentColorForSignatureAlbum(value.accentColor);
  }

  useEffect(() => {
    updateThemeVariables(backgroundColorForSignatureAlbum, fontColorForSignatureAlbum, accentColorForSignatureAlbum);

    const selectedPreset = Object.values(PresetDesignService.PRESET_DESIGN).find((item) => {
      if (
        item.backgroundColor === backgroundColorForSignatureAlbum &&
        item.fontColor === fontColorForSignatureAlbum &&
        item.primaryFontFamily === fontFamilyForSignatureAlbum &&
        item.accentColor === accentColorForSignatureAlbum
      ) {
        return item;
      }

      return null;
    });

    if (selectedPreset) {
      setGalleryPreset(selectedPreset.value);
    }
  }, [
    accentColorForSignatureAlbum,
    backgroundColorForSignatureAlbum,
    fontColorForSignatureAlbum,
    fontFamilyForSignatureAlbum,
    updateThemeVariables,
  ]);

  useEffect(() => {
    if (fontFamilyForSignatureAlbum !== 'Playfair Display') {
      initFontData(fontFamilyForSignatureAlbum, secondaryFontFamilyForSignatureAlbum);
    }
  }, [fontFamilyForSignatureAlbum, secondaryFontFamilyForSignatureAlbum, initFontData]);

  if (isLoading) return <Loading />;
  return (
    <InnerPage>
      <InnerPageHeader iconName="signature_album" title="Signature Album Design" backTo={APP_URLS.SETTINGS.INDEX}>
        Gallery Design
      </InnerPageHeader>
      <Form onSubmit={handleOnSubmit} errors={errors}>
        <InnerPageContent>
          <LoadingBox isLoading={isLoading}>
            <Row>
              <InnerPageContentMainCol>
                <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.AUTO}>
                  This is your default gallery settings. You can change individual designs while creating Albums.
                </Alert>
                <Space size={3} vertical />
                <Col size={12}>
                  <HighlightPreview
                    imageSource=""
                    alt="cover-image"
                    companyLogo={companyLogo}
                    companyName={companyName}
                    title={previewTitle}
                    isFontLoading={isFontLoading}
                    coverLayout={coverLayoutForSignatureAlbum}
                    isCoverTitleHidden={isCoverTitleHiddenForSignatureAlbum}
                    isCoverLogoHidden={isCoverLogoHiddenForSignatureAlbum}
                  />
                  <Position align="bottom">
                    <Space size={6}>
                      <HighlightDesignTitleAndLogoSettingsContainer
                        isCoverTitleHidden={isCoverTitleHiddenForSignatureAlbum}
                        isCoverLogoHidden={isCoverLogoHiddenForSignatureAlbum}
                        handleFormSubmit={handleCoverOptionFormSubmit}
                        hideTitleChangeInput
                      />
                    </Space>
                  </Position>
                </Col>
                <Space vertical />
                <GlobalSignatureGalleryLayoutContainer />

                <FormFooter>
                  <Button
                    style={BUTTON_STYLES.PRIMARY}
                    isLoading={isSaving}
                    disabled={!hasHighlightCustomizationFeature}
                    disabledMessage="Signature Album Design Customization is only available on Higher plans."
                  >
                    <Icon name="save" size={ICON_SIZES.MD_SM} accentStyle={ICON_ACCENT_STYLES.STROKE} />
                    <Space size={2} />
                    Save
                  </Button>
                </FormFooter>
              </InnerPageContentMainCol>
              <InnerPageContentSidebar>
                {!hasHighlightCustomizationFeature && (
                  <>
                    <Space vertical size={2} />
                    <Alert style={ALERT_STYLES.INFO} size={ALERT_SIZES.SM}>
                      <Row vcenter>
                        <PremiumFeatureIcon module="Signature Album Design Customization" />
                        Signature Album Design Customization is available on Higher plans.
                      </Row>
                    </Alert>
                    <Space vertical size={4} />
                  </>
                )}
                <HighlightDesignCoverLayoutSelectorContainer
                  onChange={handleLayoutChange}
                  coverLayout={coverLayoutForSignatureAlbum}
                  imageSource=""
                  alt="cover-layout"
                  title={previewTitle}
                />
                <Space vertical size={6} />

                <Card>
                  <PresetDesignSelectorContainer
                    onChange={handlePresetDesignChange}
                    selectedPresetDesign={galleryPreset}
                    isLoading={isLoading}
                    headingTitle="Gallery Presets"
                    isPresetDesignSelectorEnabled={hasHighlightCustomizationFeature}
                    variant={PresetDesignService.PRESET_DESIGN_VARIANT.PRIMARY}
                  />
                  <GalleryDesignCustomizationSettingsContainer
                    handleFontChange={(value) => setFontFamilyForSignatureAlbum(value.fontFamily)}
                    fontFamilyForSignatureAlbum={fontFamilyForSignatureAlbum}
                    hasHighlightCustomizationFeature={hasHighlightCustomizationFeature}
                    handleBackgroundColorChange={(color) => {
                      setBackgroundColorForSignatureAlbum(color);
                    }}
                    backgroundColorForSignatureAlbum={backgroundColorForSignatureAlbum}
                    handleFontColorChange={(color) => setFontColorForSignatureAlbum(color)}
                    fontColorForSignatureAlbum={fontColorForSignatureAlbum}
                    accentColorForSignatureAlbum={accentColorForSignatureAlbum}
                    handleAccentColorChange={setAccentColorForSignatureAlbum}
                  />
                </Card>
              </InnerPageContentSidebar>
            </Row>
          </LoadingBox>
        </InnerPageContent>
      </Form>
    </InnerPage>
  );
}
