import { connect } from 'react-redux';
import { InputCurrency, InputCurrencyProps } from '@premagic/myne';
import { currencyCodeSelector } from './AccountDataDuck';

function InputCurrencyComponent(props: InputCurrencyProps) {
  const { currencyCode, name, defaultValue, autoFocus, dispatch, ...elementProps } = props;
  return (
    <InputCurrency
      name={name}
      defaultValue={defaultValue}
      currencyCode={currencyCode}
      autoFocus={autoFocus}
      {...elementProps}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { currencyCode } = ownProps;
  return {
    ...ownProps,
    currencyCode: currencyCode || currencyCodeSelector(state),
  };
}

export default connect(mapStateToProps)(InputCurrencyComponent);
