import { connect } from 'react-redux';
import { PricingPlanFeaturesService } from '@premagic/core';
import AccountFinancialsPage from './AccountFinancialsPage';
import { hasFeatureSelector } from '../settings/company/CompanyDataDuck';

function mapStateToProps(state, ownProps) {
  const { filters, type } = ownProps.match.params;

  const hasAccountFinancials = hasFeatureSelector(PricingPlanFeaturesService.PRICING_PLAN_FEATURES.ACCOUNT_FINANCIALS)(
    state,
  );
  return {
    filters,
    type,
    hasAccountFinancials,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinancialsPage);
