import { connect } from 'react-redux';
import AttendeeDeleteDialog from './AttendeeDeleteDialog';
import { LOADINGS } from '../../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../../common/LoadingDuck';
import { isModalOpenSelector, toggleModalVisibility } from '../../../../../../../common/ModalDuck';
import { removeEventAttendee } from '../EventAttendeesDataDuck';

function mapStateToProps(state, ownProps: { attendeeId: string; projectId: string }) {
  const { attendeeId, projectId } = ownProps;

  return {
    attendeeId,
    projectId,
    isLoading: isLoadingSelector(LOADINGS.EVENT_ATTENDEES.DELETE)(state),
    isDialogShown: isModalOpenSelector(LOADINGS.EVENT_ATTENDEES.SHOW_DELETE_DIALOG(attendeeId))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDialog: (id: string, show: boolean) => {
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_DELETE_DIALOG(id), show));
    },
    deleteAttendee: (projectId, attendeeId) => {
      dispatch(removeEventAttendee(dispatch, projectId, attendeeId));
      dispatch(toggleModalVisibility(dispatch, LOADINGS.EVENT_ATTENDEES.SHOW_DELETE_DIALOG(attendeeId), false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeDeleteDialog);
