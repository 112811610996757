import { BrowserUrlUtils } from '@premagic/utils';
import { connect } from 'react-redux';
import { getEventAttendeesForProject } from '../../crm/events/event-attendees/EventAttendeesDataDuck';
import DAMLibraryPage from './DAMLibraryPage';
import { DAMLibraryFiltersType } from './filters/DAMLibraryFilterTypes';

function mapStateToProps(state, ownProps) {
  const { filters } = ownProps.match.params;
  const filterObject = BrowserUrlUtils.getQueryObjectFromString(filters) as DAMLibraryFiltersType;
  return {
    filters: filterObject,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEventAttendeesForProject: (projectId) => {
      dispatch(getEventAttendeesForProject(dispatch, projectId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DAMLibraryPage);
