import { connect } from 'react-redux';
import { BrowserUrlUtils } from '@premagic/utils';
import { LOADINGS } from '../../../../../../common/Constants';
import { isLoadingSelector } from '../../../../../../common/LoadingDuck';
import { errorObjectForTypeSelector } from '../../../../../../common/ErrorDuck';
import { saveUser } from '../UsersDataDuck';
import EditUserPanel from './EditUserPanel';
import APP_URLS from '../../../../services/AppRouteURLService';
import { navigateTo } from '../../../../../../services/RouterService';

const COMMON_KEY = LOADINGS.USERS.UPDATE;

function mapStateToProps(state, ownProps: { match: { params: { userId: string } } }) {
  const { userId } = ownProps.match.params;

  return {
    userId,
    isLoading: isLoadingSelector(COMMON_KEY)(state),
    errors: errorObjectForTypeSelector(COMMON_KEY)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveUser: (userId, data) => {
      dispatch(saveUser(dispatch, userId, data));
    },
    closePanel: () => {
      const usersPages = BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS_USERS.USERS, {});
      navigateTo(dispatch, usersPages);
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditUserPanel);
