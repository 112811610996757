import { connect } from 'react-redux';
import AddSponsorCategoryButton from './AddSponsorCategoryButton';
import { createSponsorCategory } from '../data-duck/SponsorCategoryDataDuck';
import { isLoadingSelector } from '../../../../../../../../common/LoadingDuck';
import { LOADINGS } from '../../../../../../../../common/Constants';

function mapStateToProps(state, ownProps) {
  return {
    isLoading:
      isLoadingSelector(LOADINGS.EVENT_SPONSOR_CATEGORY.CREATE)(state) ||
      isLoadingSelector(LOADINGS.EVENT_SPONSOR_CATEGORY.FETCH)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSponsorCategory: (formResponse) => {
      dispatch(createSponsorCategory(dispatch, formResponse));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSponsorCategoryButton);
