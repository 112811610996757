import { connect } from 'react-redux';
import { ActionConstants, ProposalSlideDataDuck } from '@premagic/proposals';
import { WindowPanelDuck } from '@premagic/common-ducks';

import ProposalCreatorDeckVariablesInfo from './ProposalCreatorDeckVariablesInfo';

const PANEL_KEY = ActionConstants.KEYS.PROPOSAL_DECK.SHOW_UPDATE_VARIABLES_PANEL;

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, folderId, deckId } = ownProps;
  return {
    projectId,
    folderId,
    deckId,
    variablesSetOnDeck: ProposalSlideDataDuck.proposalsVariablesInAllSlidesSelectors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showVariablesPanel: () => {
      dispatch(WindowPanelDuck.toggleWindowPanelVisibility(dispatch, PANEL_KEY, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorDeckVariablesInfo);
