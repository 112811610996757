import {
  ButtonIcon,
  CARD_SIZES,
  COLOR_SHADES,
  Card,
  DEVICE_SIZES,
  GeneralEditor,
  GeneralEditorContent,
  GeneralEditorHeader,
  ICON_SIZES,
  Icon,
  Iphone,
  LoadingDots,
  PILLS_STYLES,
  PillItem,
  Pills,
  Row,
  Space,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  Text,
} from '@premagic/myne';
import { BrowserUrlUtils } from '@premagic/utils';
import { useEffect, useMemo, useState } from 'react';
import APP_URLS from '../../../../services/AppRouteURLService';
import PhotoSliderWidgetContext from '../context/PhotoSliderWidgetContext';
import { getPremagicWidgetCode } from '../service/PhotoSliderWidgetService';
import PhotoSliderWidgetPreviewContainer from './PhotoSliderWidgetPreviewContainer';
import PhotoSliderWidgetRightSidebarContainer from './photo-slider-widget-right-sidebar/PhotoSliderWidgetRightSidebarContainer';

enum DISPLAY_TYPE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

type DisplayTypeSelectorProps = {
  handleChange: (option: DISPLAY_TYPE) => void;
  selectedDisplayType: DISPLAY_TYPE;
};

function DisplayTypeSelector(props: DisplayTypeSelectorProps) {
  const { handleChange, selectedDisplayType } = props;

  return (
    <Pills style={PILLS_STYLES.SECONDARY}>
      {Object.values(DISPLAY_TYPE).map((item) => (
        <PillItem key={item} active={selectedDisplayType === item} onClick={() => handleChange(item)}>
          <Text
            size={TEXT_SIZE.SIZE_5}
            boldness={TEXT_BOLDNESS.SEMI_BOLD}
            color={selectedDisplayType === item ? COLOR_SHADES.LIGHTER : COLOR_SHADES.DARKER}
          >
            {item}
          </Text>
        </PillItem>
      ))}
    </Pills>
  );
}

type Props = {
  eventId: string;
  shareId?: string;
  projectId: string;
  isLoading?: boolean;
  isAnyFolderMadePublic?: boolean;
  isItemsPresentInFolderMeta?: boolean;
  fetchProject: (projectId: string) => void;
  appHeight: number;
};

export default function PhotoSliderWidgetGetCodeLayout(props: Props) {
  const {
    eventId,
    shareId,
    isLoading,
    fetchProject,
    projectId,
    isAnyFolderMadePublic,
    isItemsPresentInFolderMeta,
    appHeight,
  } = props;

  const [displayType, setDisplayType] = useState<DISPLAY_TYPE>(DISPLAY_TYPE.DESKTOP);

  const [customizations, setCustomizations] = useState<{
    background_color: string;
    height?: number;
    max_height?: number;
    num_of_images: number;
  }>({
    background_color: '#e9ebed',
    height: undefined,
    max_height: 560,
    num_of_images: 16,
  });

  const {
    background_color: backgroundColor,
    height,
    max_height: maxHeight,
    num_of_images: numOfImages,
  } = customizations || {};

  const widgetCode = getPremagicWidgetCode(shareId || '', numOfImages, height, maxHeight, backgroundColor);

  const contextValue = useMemo(
    () => ({ widgetCode, customizations, setCustomizations }),
    [widgetCode, customizations, setCustomizations],
  );

  const backToUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
    eventId,
  });

  useEffect(() => {
    if (!isItemsPresentInFolderMeta || !isAnyFolderMadePublic) {
      fetchProject(projectId);
    }
  }, [projectId, fetchProject]);

  return (
    <PhotoSliderWidgetContext.Provider value={contextValue}>
      <GeneralEditor hasLeftPanel={false}>
        <GeneralEditorHeader>
          <Row vcenter>
            <ButtonIcon title="Back" tooltipPlacement="bottom" link={backToUrl}>
              <Icon name="arrow_left" size={ICON_SIZES.MD_SM} />
            </ButtonIcon>
            <Space size={1} />
            <Text size={TEXT_SIZE.SIZE_2} boldness={TEXT_BOLDNESS.SEMI_BOLD}>
              Embed event photos in your website using Photo widget
            </Text>
          </Row>
        </GeneralEditorHeader>
        <GeneralEditorContent>
          <Row center>
            <Space>
              <DisplayTypeSelector handleChange={setDisplayType} selectedDisplayType={displayType} />
            </Space>
          </Row>

          {(() => {
            if (isLoading) return <LoadingDots />;

            if (displayType === DISPLAY_TYPE.MOBILE) {
              return (
                <Row center>
                  <Iphone>
                    <Space vertical size={16} />
                    <PhotoSliderWidgetPreviewContainer shareId={shareId} />
                  </Iphone>
                </Row>
              );
            }

            return (
              <Card center cssStyle={{ minHeight: appHeight - 200, padding: '20px' }}>
                <PhotoSliderWidgetPreviewContainer shareId={shareId} />
              </Card>
            );
          })()}
        </GeneralEditorContent>
        <PhotoSliderWidgetRightSidebarContainer shareId={shareId} />
      </GeneralEditor>
    </PhotoSliderWidgetContext.Provider>
  );
}
