import { connect } from 'react-redux';
import { ProposalDataDuck, ActionConstants } from '@premagic/proposals';
import { LoadingDuck } from '@premagic/common-ducks';
import { ProjectService } from '@premagic/core';
import ProposalTemplateItem from './ProposalTemplateItem';

function mapStateToProps(
  state,
  ownProps: {
    id: string;
  },
) {
  const { id } = ownProps;
  return {
    proposal: ProposalDataDuck.proposalsItemsSelectors(state)[id],
    isDeleting: LoadingDuck.isLoadingSelector(ActionConstants.KEYS.PROPOSAL.DELETE(id))(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeProposal: (projectId, folderId) => {
      dispatch(ProposalDataDuck.removeProposal(dispatch, projectId, folderId, {}));
    },
    duplicateProposal: (name, template_id) => {
      dispatch(
        ProposalDataDuck.duplicateProposalTemplate(dispatch, name, template_id, ProjectService.SystemProject.id),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalTemplateItem);
