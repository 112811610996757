import React, { useState } from 'react';
import { Col, FormGroup, FormLabel, Input, Row, Select, Space } from '@premagic/myne';
import { CompanyLeadService } from '@premagic/core';

function getDefaultSelectedId(defaultValue) {
  if (defaultValue && defaultValue in CompanyLeadService.LEAD_SOURCE) return defaultValue;
  if (defaultValue) return CompanyLeadService.LEAD_SOURCE.OTHER;
  return CompanyLeadService.LEAD_SOURCE.WEBSITE;
}

type OptionType = {
  value: CompanyLeadService.LEAD_SOURCE;
  label: string;
  icon: string;
};

const options = Object.entries(CompanyLeadService.LEAD_SOURCE_DETAILS).map(([id, details]) => ({
  value: id,
  label: details.title,
})) as Array<OptionType>;

export default function LeadSourcePicker(props: {
  defaultValue?: string;
  channel: CompanyLeadService.LEAD_CHANNELS;
}): JSX.Element {
  const { defaultValue, channel } = props;
  const defaultValueId = getDefaultSelectedId(defaultValue);

  const defaultSelectedOption = options.find((option) => option.value === defaultValueId);
  const [selectedOptions, setSelectionOption] = useState(defaultSelectedOption);

  return (
    <Row>
      <input type="hidden" name="channel" value={channel} />
      <Col>
        <FormGroup>
          <FormLabel isRequired>Source</FormLabel>
          <Select
            options={options}
            value={defaultSelectedOption}
            onChange={(option) => setSelectionOption(option as OptionType)}
            name={selectedOptions?.value === CompanyLeadService.LEAD_SOURCE.OTHER ? '' : 'source'}
            isMulti={false}
          />
        </FormGroup>
      </Col>
      <Space />
      <Col>
        {selectedOptions?.value === CompanyLeadService.LEAD_SOURCE.OTHER && (
          <FormGroup>
            <FormLabel isRequired>Other source name</FormLabel>
            <Input
              required
              name="source"
              autoFocus
              defaultValue={defaultValueId === CompanyLeadService.LEAD_SOURCE.OTHER ? defaultValue : undefined}
            />
          </FormGroup>
        )}
      </Col>
    </Row>
  );
}
