import { COLOR_SHADES, SimpleImage, Text, TEXT_BOLDNESS } from '@premagic/myne';

type Props = {
  logo?: string;
  name: string;
  size?: 'xlg' | 'default' | 'md' | 'sm' | 'xs';
};
const SIZE_MAP = {
  xs: '20px',
  sm: '34px',
  md: '48px',
  default: '64px',
  xlg: '350px',
};
export default function CompanyLogo(props: Props) {
  const { logo, name, size = 'default' } = props;
  if (logo) {
    return <SimpleImage src={logo} alt={name} style={{ height: SIZE_MAP[size] }} />;
  }
  return (
    <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} primaryStyle>
      {name}
    </Text>
  );
}
