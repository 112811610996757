import { connect } from 'react-redux';
import { albumCreatorSelectedAlbumIdSelector, albumCreatorSelectedPageSelector } from './AlbumCreatorDuck';
import {
  activeCreatorPagesDataSelector,
  albumCreatorSelectedTemplateForActivePageSelector,
} from './page/AlbumPageDataDuck';
import AlbumCreatorPageNavigatorButton from './AlbumCreatorPageNavigatorButton';

function mapStateToProps(
  state,
  ownProps: {
    buttonDirection: 'previous' | 'next';
  },
) {
  const { buttonDirection } = ownProps;
  const pageNo = albumCreatorSelectedPageSelector(state);
  const albumId = albumCreatorSelectedAlbumIdSelector(state);
  return {
    buttonDirection,
    hasPreviousPage: pageNo > 1,
    pageNo,
    selectedTemplateName: albumCreatorSelectedTemplateForActivePageSelector(state),
    albumId,
    pageData: activeCreatorPagesDataSelector(state),
  };
}

export default connect(mapStateToProps)(AlbumCreatorPageNavigatorButton);
