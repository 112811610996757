import { get } from 'lodash';
import { ClientWebsiteService } from '@premagic/core';
import { FormResponseType, GridLayout, GridLayoutItem, ImageUploader } from '@premagic/myne';

interface GenericImageType {
  download: string;
  key: string;
  ratio: number;
  url: string;
}

type Props = {
  clientWebsite?: ClientWebsiteService.ClientWebsiteType;
  imageUploadApiURL?: string;
  required?: boolean;
  info?: string;
  title?: string;
  defaultValue?: GenericImageType | Array<GenericImageType>;
  deletePhotos?: (id: string, projectId: string, data: FormResponseType) => void;
  isLoading?: boolean;
  name?: string;
};

function onRemoveFile(clientWebsite: ClientWebsiteService.ClientWebsiteType, fileKey: string, deletePhotos) {
  const data = { data: { data: { images: { [fileKey]: null } } }, name: fileKey };
  deletePhotos(clientWebsite.id, clientWebsite.project_id, data);
}

export default function ClientWebsitePhotoItem(props: Props) {
  const {
    clientWebsite,
    deletePhotos,
    imageUploadApiURL,
    required,
    info,
    title,
    isLoading,
    defaultValue,
    name,
    ...elementProps
  } = props;
  return (
    <ImageUploader
      {...elementProps}
      name={name || ''}
      // @ts-ignore
      defaultValue={defaultValue}
      imageUploadApiURL={imageUploadApiURL}
      onRemoveFile={(filename) => {
        if (clientWebsite) onRemoveFile(clientWebsite, filename, deletePhotos);
      }}
      isLoading={isLoading}
    />
  );
}
