import { EventPosterService, FileService } from '@premagic/core';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Color,
  COLOR_SHADES,
  ErrorBoundary,
  Icon,
  ICON_SIZES,
  IMAGE_V2_STYLES,
  ImageV2,
  Row,
  Space,
} from '@premagic/myne';
import React from 'react';

type Props = {
  projectId: string;
  posterId: string;
  widgetId: string;
  showSelectPosterModal: (widgetId: string) => void;
  selectedFile?: FileService.FolderFileType;
  poster: EventPosterService.EventPosterType;
  onRemove: (options: { poster: EventPosterService.EventPosterType; widgetId: string; projectId: string }) => void;
};

export default function EventPosterFilePicker(props: Props) {
  const { showSelectPosterModal, selectedFile, onRemove, projectId, poster, posterId, widgetId } = props;
  const posterImageSrc = selectedFile?.dynamic_image_url;

  function handleRemove() {
    onRemove({ projectId, poster, widgetId });
  }

  return (
    <ErrorBoundary>
      <Row vcenter>
        {posterImageSrc ? (
          <Card overflowHidden style={CARD_STYLES.TRANSPARENT} onClick={() => showSelectPosterModal(widgetId)} center>
            <ImageV2 src={posterImageSrc} alt="image" style={IMAGE_V2_STYLES.THUMBNAIL_SM} width={100} />
          </Card>
        ) : (
          <Button size={BUTTON_SIZE.MD} onClick={() => showSelectPosterModal(widgetId)} style={BUTTON_STYLES.DEFAULT}>
            <div>
              <Color inline shade={COLOR_SHADES.LIGHT}>
                <Icon name="image" size={ICON_SIZES.MD} />
              </Color>
              <Space vertical size={2} />
              Select
            </div>
          </Button>
        )}
        <Space />
        {posterImageSrc && (
          <div>
            <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={() => showSelectPosterModal(widgetId)}>
              <Icon name="edit_2" size={ICON_SIZES.SM} />
              <Space size={1} />
              Change
            </Button>
            <Space size={4} />
            <Button size={BUTTON_SIZE.XS} style={BUTTON_STYLES.LINK} onClick={handleRemove}>
              <Icon name="x_circle" size={ICON_SIZES.SM} />
              <Space size={1} />
              Remove
            </Button>
          </div>
        )}
      </Row>
      <Space vertical />
    </ErrorBoundary>
  );
}
