import sanitizeHtml from 'sanitize-html';
import { isEmpty } from 'lodash';

export function getSanitizedHTML(htmlString: string, level?: 'basic' | 'full'): string {
  const validTags = [
    'p',
    'i',
    'em',
    'strong',
    'b',
    'u',
    'a',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'br',
    'ul',
    'ol',
    'li',
    'hr',
    'div',
    'span',
    'font',
    'img',
    'figure',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
  ];
  const validAttributes =
    level === 'full'
      ? {
          '*': ['href'],
        }
      : {
          '*': ['href', 'src', 'width', 'height', 'target', 'style', 'color', 'face', 'size', 'alt', 'class', 'data-*'],
        };
  return sanitizeHtml(htmlString, {
    allowedTags: validTags,
    allowedAttributes: validAttributes,
    allowedSchemesByTag: {
      img: ['data'],
    },
  });
}

export function getTextFromHTMLString(html?: string): string {
  if (!html) return '';
  const sanitizedHTML = getSanitizedHTML(html);
  return new DOMParser().parseFromString(sanitizedHTML, 'text/html').documentElement.textContent || '';
}

export function isEmptyString(str = ''): boolean {
  return str.trim().length === 0 || getTextFromHTMLString(str).trim().length === 0;
}

export function toTitleCase(string: string, force?: boolean): string {
  if (!string) return string;
  if (string.charAt(0) === string.charAt(0).toUpperCase() && !force) return string;
  if (string.charAt(0) === 'i' && !force) return string;
  if (string.charAt(0) === 'n' && !force) return string;

  /* regext to get the all the valid words eg. shell .ai word => [shell, word]
  should not match words like .ai, .com, .in etc
  const regex = /(?<=\s|^)[a-zA-Z0-9]+(?=\s|$)/g;
  */
  return string
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function humanizeString(string: string): string {
  // Replace - and _ with space
  return toTitleCase(string.replace(/[-_]/g, ' '), true);
}

export function ellipseString(str = '', length = 20, options?: { showThreeDots?: boolean }): string {
  const { showThreeDots = true } = options || {};
  if (str.length > length) return `${str.substr(0, length - 3)}${showThreeDots && '...'}`;
  return str.valueOf();
}

export function pluralize(singular = '', length: number, plural?: string): string {
  return length === 1 ? singular : plural || `${singular}s`;
}

// covert Candid Photographer to candid-photographer
export function getIdString(str: string): string {
  return str.replace(/\s+/g, '-').toLowerCase();
}

export function getHashCode(str: string): number {
  if (!str) return 0;
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i);
    /* eslint-disable no-bitwise */
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
    /* eslint-enable no-bitwise */
  }
  return Math.abs(hash);
}

export function getSortNameFromFullName(fullName: string): string | null {
  if (!fullName) return null;

  const initials = fullName.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

export function replaceAll(string: string, find: string, replace?: string | number): string {
  return string.replace(new RegExp(find, 'g'), String(replace || ''));
}

export function replaceSimpleVariablesInString(
  string: string,
  variables: Record<string, string | number | undefined>,
): string {
  if (isEmpty(variables)) return string;
  return Object.entries(variables)
    .filter(([key, value]) => value)
    .reduce((result, variable) => replaceAll(result, `{{${variable[0]}}}`, variable[1]), string);
}
/*
  * html: <p>
     This is the total <span type="variable" class="rich-text-variable-atom" data-variable-atom-id="total" data-variable-atom-name="Total">Total</span>
   </p>
  to
  <p>
     This is the total <span type="variable" class="rich-text-variable-atom" data-variable-atom-id="total" data-variable-atom-name="Total">(not set)</span>
  </p>
 */
export function replaceVariablesAsNotSetInString(string: string): string {
  const variableAtomRegex =
    /<span type="variable" class="rich-text-variable-atom" data-variable-atom-id="(.*?)" data-variable-atom-name="(.*?)">(.*?)<\/span>/g;
  return string.replace(
    variableAtomRegex,
    (match, id, name, value) =>
      `<span type="variable" class="rich-text-variable-atom" data-variable-atom-id="${id}" data-variable-atom-name="${name}">(not set)</span>`,
  );
}

/*
 * html: <p>Yo <span type=\"user\" class=\"rich-text-variable-atom\" data-variable-atom-id=\"new-zwis\" data-variable-atom-name=\"new\">new</span></p><p></p><p><span type=\"user\" class=\"rich-text-variable-atom\" data-variable-atom-id=\"wedding-photographer\" data-variable-atom-name=\"Wedding photographer\">Wedding photographer</span> Photographer</p>
 * regext to replace <span type=\"user\" class=\"rich-text-variable-atom\" data-variable-atom-id=\"new-zwis\" data-variable-atom-name=\"new\">new</span> with new
 *
 */
export function replaceVariablesInString(string: string, variables?: Record<string, string | number>): string {
  const replacedString = Object.entries(variables || {}).reduce(
    (result, variable) =>
      replaceAll(
        result,
        `<span(([^>]*?)data-variable-atom-id="${variable[0]}")(?:[^>]*?)>(?:[^<]*)<\\/span>`,
        variable[1],
      ),
    string,
  );
  return replaceVariablesAsNotSetInString(replacedString);
}

export function replaceFileExtention(fileName: string, newExtensions?: string) {
  if (!fileName) return '';
  return fileName.replace(/\..+$/, newExtensions || '');
}

export function getHashtagFromString(string: string): string {
  // Check if the string is empty.
  if (!string || string === '') {
    return '';
  }

  return toTitleCase(string.trim().replace(/[\s.,]/g, '')); // Remove all spaces, periods, and commas from the string.
}

export function getFormattedHashtags(
  hashtags: Array<string>,
  formatType: 'array' | 'string' = 'string',
): Array<string> | string {
  const formattedHashtags = hashtags.map((word) => {
    if (!word) return '';

    const formattedWord = getHashtagFromString(word);

    if (!formattedWord.startsWith('#')) {
      return `#${formattedWord}`;
    }
    return formattedWord;
  });

  if (formatType === 'string') {
    return formattedHashtags.join(' ');
  }
  return formattedHashtags;
}
