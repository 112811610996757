import React, { useState } from 'react';
import { Portal, useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';
import ClassNames from 'classnames';

import { Col, PreventMarginCollapse, Row, Space } from '../Grid/Grid';
import { Text, TEXT_BOLDNESS, TEXT_SIZE } from '../Text/Text';
import styles from './dialog.module.css';
import { COLOR_SHADES } from '../Color/Color';

const DIALOG_ROOT = 'dialog-root';

export enum DIALOG_SIZES {
  SM = 'sm',
  MD = 'md',
  AUTO = 'auto',
}

/* POPPER placement options
    'auto', 'auto-start', 'auto-end',
    'top', 'top-start', 'top-end',
    'bottom', 'bottom-start', 'bottom-end',
    'right', 'right-start', 'right-end',
    'left', 'left-start', 'left-end',
*/

export enum DIALOG_PLACEMENT {
  AUTO = 'auto',
  LEFT = 'left',
  TOP_START = 'top-start',
  BUTTON_BOTTOM = 'bottom-end',
  RIGHT = 'right',
}

export interface DialogProps {
  children: React.ReactNode;
  placement?: DIALOG_PLACEMENT;
  target: HTMLElement | null;
  show: boolean;
  size?: DIALOG_SIZES;
  onClose: () => void;
  hasPadding?: boolean;
}

export function Dialog(props: DialogProps): JSX.Element | null {
  const {
    show,
    placement = DIALOG_PLACEMENT.BUTTON_BOTTOM,
    target,
    children,
    size = DIALOG_SIZES.MD,
    onClose,
    hasPadding = true,
  } = props;

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles: popoverPositionStyles, attributes } = usePopper(target, popperElement, {
    placement,
    strategy: 'fixed',
  });

  useRootClose(popperElement, onClose, {
    disabled: !show,
    clickTrigger: 'mousedown',
  });

  if (!show) return null;

  return (
    <Portal container={document.getElementById(DIALOG_ROOT)}>
      <div
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        tabIndex={-1}
        role="button"
        ref={(element) => setPopperElement(element)}
        className={styles['dialog-wrapper']}
        style={popoverPositionStyles.popper}
        {...attributes.popper}
      >
        <div
          className={ClassNames(styles.dialog, styles[`dialog--size-${size}`], {
            [styles['dialog--padding']]: hasPadding,
          })}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
}

export interface DialogTitleProps {
  children: React.ReactNode;
}

export function DialogTitle(props: DialogTitleProps): JSX.Element {
  const { children } = props;
  return (
    <div>
      <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
        {children}
      </Text>
      <Space vertical />
    </div>
  );
}

export interface DialogFooterProps {
  children: React.ReactNode;
  rightAligned?: boolean;
}

export function DialogFooter(props: DialogFooterProps): JSX.Element {
  const { children, rightAligned = true } = props;
  const classes = ClassNames(styles['form-footer']);

  return (
    <div className={classes}>
      <PreventMarginCollapse>
        <Space vertical />
        <Row>
          <Col size={null} rightAlighed={rightAligned}>
            {children}
          </Col>
        </Row>
      </PreventMarginCollapse>
    </div>
  );
}
