import * as React from 'react';

import ClassNames from 'classnames';

import styles from './devices.module.css';

export enum DEVICE_SIZES {
  SM = 'sm',
}

type Props = {
  children: React.ReactNode;
  size?: DEVICE_SIZES;
};

export function Iphone(props: Props) {
  const { children, size } = props;
  return (
    <div
      className={ClassNames(styles.device, styles[`device-iphone-x`], {
        [styles[`device-iphone-x--size-${size}`]]: size,
      })}
    >
      <div
        className={ClassNames(styles['device-iphone-x-notch'], {
          [styles[`device-iphone-x-notch--size-${size}`]]: size,
        })}
      >
        <div
          className={ClassNames(styles['device-iphone-x-camera'], {
            [styles[`device-iphone-x-camera--size-${size}`]]: size,
          })}
        />
        <div
          className={ClassNames(styles['device-iphone-x-speaker'], {
            [styles[`device-iphone-x-speaker--size-${size}`]]: size,
          })}
        />
      </div>
      <div className={ClassNames(styles['top-bar'], styles['device-iphone-x-top-bar'])} />
      <div
        className={ClassNames(styles['device-iphone-x-sleep'], {
          [styles[`device-iphone-x-sleep--size-${size}`]]: size,
        })}
      />
      <div className={ClassNames(styles['bottom-bar'], styles['device-iphone-x-bottom-bar'])} />
      <div
        className={ClassNames(styles['device-iphone-x-volume'], {
          [styles[`device-iphone-x-volume--size-${size}`]]: size,
        })}
      />
      <div
        className={ClassNames(styles['device-iphone-x-overflow'], {
          [styles[`device-iphone-x-overflow--size-${size}`]]: size,
        })}
      >
        <div className={ClassNames(styles.shadow, styles['device-iphone-x-shadow--tr'])} />
        <div className={ClassNames(styles.shadow, styles['device-iphone-x-shadow--tl'])} />
        <div className={ClassNames(styles.shadow, styles['device-iphone-x-shadow--br'])} />
        <div className={ClassNames(styles.shadow, styles['device-iphone-x-shadow--bl'])} />
      </div>

      <div
        className={ClassNames(styles.screen, styles['device-iphone-x-screen'], {
          [styles[`device-iphone-x-screen--size-${size}`]]: size,
        })}
      >
        {children}
      </div>
    </div>
  );
}
