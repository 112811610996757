import { FileService, GeneralImageEditorService, QRCodeBackgroundPosterService } from '@premagic/core';
import { SimpleImage, Space } from '@premagic/myne';
import { ColorUtils } from '@premagic/utils';
import React, { useEffect, useRef, useState } from 'react';
import { PixelCrop } from 'react-image-crop';
import getEditedImage from '../../../general-image-editor/GeneralImageEditorService';
import getColorFilter from '../../../general-image-editor/utils/get-filter-for-a-color/colorToFilter';

type Props = {
  illustrationFile?: FileService.FolderFileType;
  illustrationMetaData?: GeneralImageEditorService.ImageEditorImageMetaDataType;
  imageWidth: number;
  imageHeight: number;
  qrCodeDesigns?: QRCodeBackgroundPosterService.QRCodeDesignType;
};

export function DisplayedPosterIllustration(props: Props) {
  const { illustrationFile, illustrationMetaData, imageWidth, imageHeight, qrCodeDesigns } = props;

  const defaultImageSrc = illustrationFile?.dynamic_image_url;

  const [editedLogo, setEditedLogo]: [any, any] = useState(defaultImageSrc);
  const [colorFilter, setColorFilter] = useState('');

  useEffect(() => {
    if (defaultImageSrc && illustrationMetaData && illustrationMetaData?.pixelCrop) {
      getEditedImage(
        defaultImageSrc,
        illustrationMetaData?.pixelCrop,
        illustrationMetaData?.scale,
        illustrationMetaData?.rotate,
        illustrationMetaData?.imageProperties,
        colorFilter,
      ).then((url) => {
        setEditedLogo(url);
      });
    }
  }, [illustrationMetaData, defaultImageSrc, colorFilter]);

  useEffect(() => {
    const primaryColor = qrCodeDesigns?.colors?.primary_color1 || '#000000';
    // const darkenColorInHex = primaryColor && ColorUtils.lightenDarkenColor(primaryColor, -30);
    const darkenColor = primaryColor && ColorUtils.darkenColor(primaryColor, 0.14);
    const darkenColorInHex = ColorUtils.convertToHex(darkenColor);
    if (darkenColorInHex) {
      const colorFilterForIllustration = getColorFilter(darkenColorInHex)?.filter;
      if (colorFilterForIllustration) {
        setColorFilter(colorFilterForIllustration);
      }
    }
  }, [qrCodeDesigns]);

  if (!defaultImageSrc || !editedLogo) return null;

  return (
    <div
      style={{
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        zIndex: 10,
      }}
    >
      <SimpleImage
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          objectFit: 'contain',
          // filter: colorFilter,
        }}
        src={editedLogo}
        alt="qr-poster-illustration"
      />
    </div>
  );
}
