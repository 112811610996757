import { LoadingDuck, ModalDuck } from '@premagic/common-ducks';
import { connect } from 'react-redux';
import { albumCreatorSelectedAlbumIdSelector } from '../../AlbumCreatorDuck';
import { albumEntitySelector, removeAlbum, updateAlbum } from '../AlbumDataDuck';
import AlbumStateInfo from './AlbumStateInfo';
import { LOADINGS, MODALS } from '../../../../common/Constants';
import { eventIdForAlbumSelector } from '../../project/AlbumProjectDataDuck';

function mapStateToProps(state) {
  const id = albumCreatorSelectedAlbumIdSelector(state);

  return {
    id,
    album: albumEntitySelector(id)(state),
    isSaving: LoadingDuck.isLoadingSelector(LOADINGS.ALBUM.UPDATE)(state),
    isDeleting: LoadingDuck.isLoadingSelector(LOADINGS.ALBUM.DELETE)(state),
    isConfirmDialogShown: ModalDuck.isModalOpenSelector(MODALS.ALBUM.UPDATE_STATE)(state),
    eventId: eventIdForAlbumSelector(state) || 'event-id-dummy',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleConfirmDialog: (show: boolean) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, MODALS.ALBUM.UPDATE_STATE, show));
    },
    deleteAlbum: (albumId, eventId) =>
      dispatch(
        removeAlbum(dispatch, albumId, {
          eventId,
        }),
      ),
    updateAlbumState: (albumId, state, eventId) =>
      dispatch(
        updateAlbum(
          dispatch,
          albumId,
          {
            state,
          },
          {
            eventId,
          },
        ),
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumStateInfo);
