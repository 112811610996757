import { COLOR_SHADES, Col, Row, Space, TEXT_BOLDNESS, TEXT_SIZE, Text, ToggleSwitch } from '@premagic/myne';

export default function SponsorWatermarkTypeToggle(props) {
  const checked = true;
  return (
    <Row>
      <Col size={12}>
        <Row>
          <Text boldness={TEXT_BOLDNESS.BOLD}>Folder watermark</Text>
        </Row>
      </Col>
      <Space size={8} />
      <Col size={null} rightAlighed>
        <Row vcenter columnDirection>
          <ToggleSwitch
            disabled
            //   isLoading={isLoading}
            name=""
            checked={checked}
            onChange={(value) => {}}
          />
          <Space size={1} vertical />
          {checked ? (
            <Text color={COLOR_SHADES.DARKER} size={TEXT_SIZE.SIZE_6}>
              Yes
            </Text>
          ) : (
            <Text color={COLOR_SHADES.LIGHT} size={TEXT_SIZE.SIZE_6}>
              No
            </Text>
          )}
        </Row>
      </Col>
    </Row>
  );
}
