import React from 'react';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  Row,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_GROUP_TYPES,
  TEXT_SIZE,
  TextGroupValue,
} from '@premagic/myne';
import { ClientsService } from '@premagic/core';

import { JS_SELECTORS } from '../../../../../../common/JSClassSelectors';
import LeadChannelAndSourceValue from '../../leads/LeadChannelAndSourceValue';
import MESSAGES from '../../../../../../common/Messages';
import { PERMISSIONS } from '../../users/UserPermissionService';

type Props = {
  client: ClientsService.ClientType;
  showEditClientDetails: () => void;
  hasClientManagementPermission: boolean;
  hasLeadsModule: boolean;
};

export default function CRMClientSummary(props: Props): JSX.Element {
  const { client, showEditClientDetails, hasClientManagementPermission, hasLeadsModule } = props;
  const { name, phone_number: phone, email, notes, address } = client;

  return (
    <div className={JS_SELECTORS.EVENT_DETAILS_TAB}>
      <Card>
        <Space>
          <CardHeader>
            <Row vcenter>
              <Icon name="smile" size={ICON_SIZES.SM} /> <Space size={1} />
              <CardHeaderTitle>Host</CardHeaderTitle>
            </Row>
            <CardHeaderActions>
              <Button
                style={BUTTON_STYLES.LINK}
                onClick={showEditClientDetails}
                disabledMessage={MESSAGES.PERMISSION[PERMISSIONS.EVENT_CLIENT_MANAGEMENT]}
                disabled={!hasClientManagementPermission}
              >
                Edit
              </Button>
            </CardHeaderActions>
          </CardHeader>
          <Text block color={COLOR_SHADES.DARK} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_3}>
            {name}
          </Text>
          <SimpleLink href={`mailto:${email}`} isExternalLink={false}>
            <Row vcenter>
              <Color shade={COLOR_SHADES.LIGHT} inline>
                <Icon name="mail" size={ICON_SIZES.SM} />
              </Color>
              <Space size={2} />
              <TextGroupValue type={TEXT_GROUP_TYPES.TEXT} value={email} />
            </Row>
          </SimpleLink>
          <Space vertical size={1} />
          <Row vcenter>
            <Color shade={COLOR_SHADES.LIGHT} inline>
              <Icon name="phone" size={ICON_SIZES.SM} />
            </Color>
            <Space size={2} />
            <TextGroupValue type={TEXT_GROUP_TYPES.TEL} value={phone} />
          </Row>
          <Space vertical size={1} />
          {hasLeadsModule && (
            <Row vcenter>
              <Text boldness={TEXT_BOLDNESS.BOLD}>Source:</Text>
              <Space size={1} />
              <LeadChannelAndSourceValue source={client.source} channel={client.channel} />
            </Row>
          )}
          {address && (
            <div>
              <Space vertical />
              <Text boldness={TEXT_BOLDNESS.BOLD}>Address</Text>
              <TextGroupValue type={TEXT_GROUP_TYPES.TEXTAREA} value={address} />
            </div>
          )}
          {hasLeadsModule && (
            <div>
              <Space vertical />
              <Text boldness={TEXT_BOLDNESS.BOLD}>Host's Notes</Text>
              <TextGroupValue type={TEXT_GROUP_TYPES.RICH_TEXTAREA} value={notes} />
            </div>
          )}
        </Space>
      </Card>
    </div>
  );
}
