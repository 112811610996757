import { connect } from 'react-redux';
import { occasionItemsSelector } from '../OccasionsDataDuck';
import EventOccasionSummaryListItem from './EventOccasionSummaryListItem';

function mapStateToProps(state, ownProps: { occasionId: string; eventId: string }) {
  const { occasionId, eventId } = ownProps;

  return {
    eventId,
    occasion: occasionItemsSelector(state)[occasionId],
  };
}

export default connect(mapStateToProps)(EventOccasionSummaryListItem);
