import React from 'react';
import { BrowserUrlUtils, SimpleDateUtils } from '@premagic/utils';
import {
  Button,
  BUTTON_STYLES,
  Card,
  CARD_STYLES,
  Col,
  COLOR_SHADES,
  Row,
  SCROLLABLE_CONTAINER_SIZE,
  ScrollableContainer,
  SimpleLink,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import { CompanyService, PricingPlanService, SubscriptionService } from '@premagic/core';

import { CancelSubscription } from './CancelSubscription';
import APP_URLS from '../../../services/AppRouteURLService';
import PricingPlanList from '../pricing-plan/PricingPlanList';
import CompanyStatsCardContainer from '../../company/company-stats/CompanyStatsCardContainer';

type SubscriptionDetailsProps = {
  subscription: SubscriptionService.SubscriptionType;
};

export function SubscriptionDetails(props: SubscriptionDetailsProps) {
  const { subscription } = props;
  const { status, next_billing_date: nextDate, authorization_url: authUrl } = subscription || {};
  if (!status) return null;
  const date = SimpleDateUtils.getDateStringISO(new Date(nextDate * 1000));

  switch (status) {
    case SubscriptionService.SUBSCRIPTION_STATUS.ACTIVE:
      return (
        <div>
          <Text size={TEXT_SIZE.SIZE_3} block>
            Your next billing date is{' '}
            <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
              {SimpleDateUtils.humanizeDate(date, true)}
            </Text>
            .
          </Text>
          <Row>
            <Col size={null} rightAlighed>
              <CancelSubscription />
            </Col>
          </Row>
        </div>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.CANCELLED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has been cancelled. Please select a plan to continue.
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.EXPIRED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has expired.
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.HALTED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has been suspended/halted.
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.CREATED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription is created. Please complete the payment here{' '}
          <SimpleLink isExternalLink showExternalLinkIcon href={authUrl}>
            Open payment link
          </SimpleLink>
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.PENDING:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has been Pending. Please complete the payment here{' '}
          <SimpleLink isExternalLink showExternalLinkIcon href={authUrl}>
            Open payment link
          </SimpleLink>
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.AUTHENTICATED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has been authenticated.
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.COMPLETED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has been completed.
        </Text>
      );
    case SubscriptionService.SUBSCRIPTION_STATUS.PAUSED:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          Subscription has been paused.
        </Text>
      );
    default:
      return (
        <Text size={TEXT_SIZE.SIZE_3} block>
          You need fix you payment
        </Text>
      );
  }
}

type Props = {
  company: CompanyService.CompanyType;
  subscription: SubscriptionService.SubscriptionType;
};

export function CurrentSubscriptionInfo(props: Props) {
  const { company, subscription } = props;
  const { availableProjects, totalProjects } = company.usage || {};
  const currentPlan = company.currentPricingPlan;

  return (
    <div>
      <Card>
        <Space>
          <Text size={TEXT_SIZE.SIZE_2} block>
            You are subscribed to{' '}
            <Text size={TEXT_SIZE.SIZE_2} color={COLOR_SHADES.DARKER}>
              {currentPlan ? <span>{PricingPlanService.getPricingPlanTitle(currentPlan)}</span> : <span>trial</span>}{' '}
            </Text>
            plan.
          </Text>
          {subscription ? (
            <SubscriptionDetails subscription={subscription} />
          ) : (
            !!totalProjects && (
              <Text block>
                You have used{' '}
                <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                  {totalProjects}
                </Text>{' '}
                out of{' '}
                <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                  {availableProjects}
                </Text>
                , you have{' '}
                {availableProjects && (
                  <Text boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
                    {availableProjects - totalProjects}
                  </Text>
                )}{' '}
                remaining!
              </Text>
            )
          )}

          <Text block boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER}>
            Your plan Features
          </Text>
          <Row>
            <Col>
              <Card style={CARD_STYLES.SECONDARY}>
                <ScrollableContainer showShadow direction="vertical" size={SCROLLABLE_CONTAINER_SIZE.SM}>
                  <Space>
                    <PricingPlanList plan={currentPlan} userLocal={company.customer_country_code} />
                  </Space>
                </ScrollableContainer>
              </Card>
            </Col>
            <Space vertical />
            <Col size={4} rightAlighed>
              <CompanyStatsCardContainer />
            </Col>
          </Row>
        </Space>
      </Card>
      <Row>
        <Col size={null} rightAlighed>
          <Button style={BUTTON_STYLES.LINK} link={BrowserUrlUtils.getRouteUrlFor(APP_URLS.SETTINGS.INVOICE, {})}>
            Invoices
          </Button>
        </Col>
      </Row>
    </div>
  );
}
