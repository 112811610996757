import { connect } from 'react-redux';
import { LOADINGS } from '../../../../../common/Constants';
import { errorObjectForTypeSelector } from '../../../../../common/ErrorDuck';
import { isLoadingSelector } from '../../../../../common/LoadingDuck';
import { clientSettingsDataSelector, saveClientSettingsData } from '../preferences/ClientSettingsDataDuck';
import ExperimentsPreferencePage from './ExperimentsPreferencePage';

function mapStateToProps(state) {
  return {
    clientSettings: clientSettingsDataSelector(state),
    isLoading:
      isLoadingSelector(LOADINGS.APP_LOADING)(state) || isLoadingSelector(LOADINGS.CLIENT_SETTINGS.FETCH_ALL)(state),
    isSaving: isLoadingSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
    errors: errorObjectForTypeSelector(LOADINGS.CLIENT_SETTINGS.SAVE_SETTINGS)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePreferences: (data) => {
      dispatch(saveClientSettingsData(dispatch, data, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperimentsPreferencePage);
