import React from 'react';
import { connect } from 'react-redux';
import { ProductFeedbackService } from '@premagic/core';
import { closedReportIssueIdsByTypeSelector } from '../data-duck/ReportIssueDataDuck';
import { REPORT_ISSUE_GROUP_TYPES } from '../service/ReportIssueService';

import ClosedReportIssueList from './ClosedReportIssueList';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    reportType: ProductFeedbackService.PRODUCT_FEEDBACK_REPORT_TYPE;
    name: string;
  },
) {
  const { projectId, reportType, name } = ownProps;

  return {
    closedReportIssuesIds: closedReportIssueIdsByTypeSelector(projectId, reportType)(state),
    projectId,
    name,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClosedReportIssueList);
