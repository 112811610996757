import { APIURLService } from '@premagic/core';
import { HttpUtils } from '@premagic/utils';
import { DECK_SLIDE_DATA_TYPES } from '@premagic/myne';

const URL = APIURLService.API_URLS.PROPOSAL_DECK;

export enum PROPOSAL_DECK_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum PROPOSAL_DECK_REVIEW_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUEST_FOR_CHANGE = 'REQUEST_FOR_CHANGE',
}

export type ProposalDeckType = {
  id: string;
  slides: Array<number>;
  created_at: string;
  updated_at: string;
  variables: Record<string, string | number | any>;
  status: PROPOSAL_DECK_STATUS;
  review_status: PROPOSAL_DECK_REVIEW_STATUS;
  review_comment: string;
  is_locked: boolean;
  signature?: string;
};

export function fetchProposalDeck(projectId: string, deckId: string): Promise<ProposalDeckType> {
  return HttpUtils.get(URL.DETAILS(projectId, deckId))
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.data));
}

export function createProposalDeckVersion(
  projectId,
  deckId,
  options: {
    folderId: string;
  },
): Promise<ProposalDeckType> {
  return HttpUtils.post(URL.VERSION(projectId, deckId), {
    folder_id: options.folderId,
  })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function updateProposalDeck(projectId, deckId, data: Partial<ProposalDeckType>): Promise<ProposalDeckType> {
  return HttpUtils.put(URL.DETAILS(projectId, deckId), data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.data));
}

export function updateProposalDeckReviewStatus(
  options: {
    shareId: string;
    folderId: string;
    deckId: string;
  },
  data: {
    review_status: PROPOSAL_DECK_REVIEW_STATUS;
    review_comment?: string;
    signature?: string;
  },
): Promise<ProposalDeckType> {
  const { shareId, folderId, deckId } = options;

  return HttpUtils.put(
    URL.CLIENT_UPDATE_REVIEWS(shareId),
    {
      folder_id: folderId,
      deck_id: deckId,
      ...data,
    },
    true,
  )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.data?.error));
}

export function getAllTheVariablesToSaveFromTheTextContent(
  text = '',
  deckVariables: Record<string, string>,
  slideId: number,
): Record<string, string> {
  const regex = /data-variable-atom-id="(\S+)"/gim;
  const matches = text.matchAll(regex);
  const variablesAddedOnSlide = [...matches].map((match) => match[1]);

  return variablesAddedOnSlide.reduce((acc, variable) => {
    const existingVariableValue = deckVariables?.[slideId]?.[variable];
    acc[variable] = existingVariableValue || '0';
    return acc;
  }, {});
}
