import React from 'react';

import { FolderService, ProjectService } from '@premagic/core';
import {
  InnerPageHeader,
  Text,
  Space,
  TEXT_BOLDNESS,
  HTML_ICON,
  InnerPageHeaderFloatingTag,
  Tabs,
  TabItem,
  InnerPageSubHeader,
  COLOR_SHADES,
  Row,
} from '@premagic/myne';
import { BrowserUrlUtils, NumberUtils, StringUtils } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import ProjectPinNumber from '../ProjectPinNumber';
import FolderShareButtonContainer from '../folders/sidebar/FolderShareButtonContainer';

type Props = {
  isLoading: boolean;
  projectId: string;
  project: ProjectService.ProjectType;
  currentUrlPathname: string;
  folderFilesStats: FolderService.FolderTypeStats;
};

export default function SelectionFoldersPageHeader(props: Props) {
  const { isLoading, projectId, project, currentUrlPathname, folderFilesStats } = props;
  const { total_image: totalFiles, accepted: totalSelected } = folderFilesStats;

  const { project_name: projectName, event_id: eventId } = project || {};

  const backToUrlProject = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.DETAILS, {
    projectId,
  });
  const backToUrlEvent =
    eventId &&
    BrowserUrlUtils.getRouteUrlFor(APP_URLS.CRM.EVENT__DELIVERABLE, {
      eventId,
      focusId: FolderService.FOLDER_TYPES.SELECTION,
    });
  const foldersUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.SELECTION, { projectId });
  const selectedPhotosUrl = BrowserUrlUtils.getRouteUrlFor(APP_URLS.PROJECT.SELECTION__SELECTED_PHOTOS, {
    projectId,
  });
  const activeFolderView = currentUrlPathname.includes(foldersUrl) && !currentUrlPathname.includes('/v/');

  return (
    <>
      <InnerPageHeaderFloatingTag>
        <Text boldness={TEXT_BOLDNESS.BOLD} block>
          {projectName}
          {project?.mpin && (
            <span>
              <Space size={2} />
              {HTML_ICON.DOT}
              <Space size={2} />
              <ProjectPinNumber pin={project?.mpin} />
            </span>
          )}
        </Text>
      </InnerPageHeaderFloatingTag>
      <InnerPageHeader
        iconName="selection_album"
        title={`Selection for ${projectName}`}
        backTo={eventId ? backToUrlEvent : backToUrlProject}
        rightActions={
          <FolderShareButtonContainer projectId={projectId} folderType={FolderService.FOLDER_TYPES.SELECTION} />
        }
      >
        <Row vcenter alignItems="baseline">
          Selection Album
          <Space size={2} />
          <Text block>
            <Text color={COLOR_SHADES.DARKER}>{NumberUtils.getNumberInFormat(totalSelected)}</Text> selected of{' '}
            <Text color={COLOR_SHADES.DARKER}>{NumberUtils.getNumberInFormat(totalFiles)}</Text>{' '}
            {StringUtils.pluralize('Photo', totalFiles)}
          </Text>
        </Row>
      </InnerPageHeader>
      <InnerPageSubHeader>
        <Tabs>
          <TabItem to={foldersUrl} active={activeFolderView}>
            Folders
          </TabItem>
          <TabItem to={selectedPhotosUrl} active={currentUrlPathname.includes(selectedPhotosUrl)}>
            Selected photos
          </TabItem>
        </Tabs>
      </InnerPageSubHeader>
    </>
  );
}
