import { connect } from 'react-redux';
import { ProposalSlideDataDuck, ActionConstants } from '@premagic/proposals';
import { ModalDuck } from '@premagic/common-ducks';

import { activeProposalSlideSelector, proposalCreatorSelectedSlideSelector } from '../ProposalCreatorDuck';
import ProposalCreatorSlideBackgroundDataUpdater from './ProposalCreatorSlideBackgroundDataUpdater';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
    deckId: string;
  },
) {
  const { projectId, deckId, folderId } = ownProps;
  return {
    projectId,
    deckId,
    folderId,
    key: proposalCreatorSelectedSlideSelector(state),
    slideDetails: activeProposalSlideSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveProposalSlide: (options, data) => dispatch(ProposalSlideDataDuck.saveProposalSlide(dispatch, options, data)),
    showSelectFileModal: (options) => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SELECT_FILE_MODAL, true));
      dispatch(ModalDuck.setModalOptions(dispatch, ActionConstants.KEYS.PROPOSAL.SELECT_FILE_MODAL, options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorSlideBackgroundDataUpdater);
