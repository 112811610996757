import { ClientSettingsService } from '@premagic/core';
import {
  Card,
  Col,
  COLOR_SHADES,
  InnerPage,
  InnerPageContent,
  InnerPageContentMainCol,
  InnerPageHeader,
  List,
  ListItem,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
  ToggleSwitch,
  Tooltip,
} from '@premagic/myne';
import { i18n } from '@premagic/utils';
import APP_URLS from '../../../services/AppRouteURLService';
import PremiumFeatureIcon from '../PremiumFeatureIcon';
import AccountLanguageSelector from './account-language-selector/AccountLanguageSelector';
import EventIntegrationSettingsContainer from './EventIntegrationSettingsContainer';
import MESSAGES from '../../../../../common/Messages';

type Props = {
  savePreferences: (data) => void;
  isEmailHiddenOnRegistrationForm: boolean;
  isFeatureEventsOccasionsEnabled: boolean;
  isFeatureProjectsTabEnabled: boolean;
  isLoading: boolean;
  hasCustomEventFormFieldsFeature: boolean;
  showEventFeedbackForm: boolean;
  enableReportingIssueFromPeople: boolean;
  enableReportingTheseAreNotMyPhotos: boolean;
  isStaffUser?: boolean;
  accountDefaultLanguage?: i18n.LANGUAGE_CODE;
  companyType: ClientSettingsService.CLIENT_COMPANY_TYPES;
};

export default function EventsSettingsPage(props: Props) {
  const {
    isFeatureEventsOccasionsEnabled,
    savePreferences,
    isLoading,
    isFeatureProjectsTabEnabled,
    isEmailHiddenOnRegistrationForm,
    hasCustomEventFormFieldsFeature,
    showEventFeedbackForm,
    enableReportingIssueFromPeople,
    isStaffUser,
    enableReportingTheseAreNotMyPhotos,
    accountDefaultLanguage,
    companyType,
  } = props;

  const isCompanyTypePhotography = companyType === ClientSettingsService.CLIENT_COMPANY_TYPES.PHOTOGRAPHY;

  return (
    <InnerPage>
      <InnerPageHeader iconName="event" title="Events Settings" backTo={APP_URLS.SETTINGS.INDEX}>
        Events
      </InnerPageHeader>
      <InnerPageContent hasInnerCols>
        <InnerPageContentMainCol>
          <Card>
            <List>
              <ListItem>
                <AccountLanguageSelector
                  onChange={(value) => {
                    savePreferences({
                      data: {
                        [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.DEFAULT_LANG]: value,
                      },
                    });
                  }}
                  defaultLanguageCode={accountDefaultLanguage}
                />
              </ListItem>
              {isCompanyTypePhotography && (
                <>
                  <ListItem>
                    <Col size={10}>
                      <Text color={COLOR_SHADES.DARKER} block>
                        Enable Occasions
                      </Text>
                      <Text color={COLOR_SHADES.LIGHT}>Manage occasions for an event</Text>
                    </Col>
                    <Col size={null} rightAlighed>
                      <Row vcenter>
                        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                          {isFeatureEventsOccasionsEnabled ? 'Yes' : 'No'}
                        </Text>
                        <Space size={2} />
                        <ToggleSwitch
                          isLoading={isLoading}
                          name=""
                          checked={isFeatureEventsOccasionsEnabled}
                          onChange={(value) => {
                            savePreferences({
                              data: {
                                [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_EVENTS_OCCASIONS_ENABLE]:
                                  value,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </ListItem>
                  <ListItem>
                    <Col size={10}>
                      <Text color={COLOR_SHADES.DARKER} block>
                        Enable Projects tab
                      </Text>
                      <Text color={COLOR_SHADES.LIGHT}>Projects tab for easy access to photos</Text>
                    </Col>
                    <Col size={null} rightAlighed>
                      <Row vcenter>
                        <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                          {isFeatureProjectsTabEnabled ? 'Yes' : 'No'}
                        </Text>
                        <Space size={2} />
                        <ToggleSwitch
                          isLoading={isLoading}
                          name=""
                          checked={isFeatureProjectsTabEnabled}
                          onChange={(value) => {
                            savePreferences({
                              data: {
                                [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.FEATURE_PROJECTS_TAB_ENABLE]: value,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </ListItem>
                </>
              )}

              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Ask for email address on event registration{' '}
                    {!hasCustomEventFormFieldsFeature && (
                      <PremiumFeatureIcon module="Mark email as optional in event registration" />
                    )}
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>
                    In the Requesting photo registration, include email address question for new events
                  </Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {isEmailHiddenOnRegistrationForm ? 'No' : 'Yes'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isLoading}
                      name=""
                      checked={!isEmailHiddenOnRegistrationForm}
                      disabled={!hasCustomEventFormFieldsFeature}
                      onChange={(value) => {
                        if (!hasCustomEventFormFieldsFeature) return;
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.EVENT_REGISTRATION_HIDE_EMAIL_FIELD]:
                              !value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Show Event Feedback Form
                    {!hasCustomEventFormFieldsFeature && <PremiumFeatureIcon module="Show event feedback form" />}
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>Show 'Share your feedback' button in guest gallery</Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {showEventFeedbackForm ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isLoading}
                      name=""
                      checked={showEventFeedbackForm}
                      disabled={!hasCustomEventFormFieldsFeature}
                      onChange={(value) => {
                        if (!hasCustomEventFormFieldsFeature) return;
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.SHOW_EVENT_FEEDBACK_FORM]: value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
              <ListItem>
                <Col size={10}>
                  <Text color={COLOR_SHADES.DARKER} block>
                    Enable reporting issue from people
                    {!hasCustomEventFormFieldsFeature && <PremiumFeatureIcon module="report issue" />}
                  </Text>
                  <Text color={COLOR_SHADES.LIGHT}>Allow guests to report issues in the guest gallery</Text>
                </Col>
                <Col size={null} rightAlighed>
                  <Row vcenter>
                    <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                      {enableReportingIssueFromPeople ? 'Yes' : 'No'}
                    </Text>
                    <Space size={2} />
                    <ToggleSwitch
                      isLoading={isLoading}
                      name=""
                      checked={enableReportingIssueFromPeople}
                      disabled={!hasCustomEventFormFieldsFeature}
                      onChange={(value) => {
                        if (!hasCustomEventFormFieldsFeature) return;
                        savePreferences({
                          data: {
                            [ClientSettingsService.CLIENT_SETTINGS_META_TYPES.ENABLE_REPORTING_ISSUE_FROM_PEOPLE]:
                              value,
                          },
                        });
                      }}
                    />
                  </Row>
                </Col>
              </ListItem>
              {isStaffUser && (
                <ListItem>
                  <Col size={10}>
                    <Text color={COLOR_SHADES.DARKER} block>
                      Enable reporting issue from people:{' '}
                      <Text boldness={TEXT_BOLDNESS.SEMI_BOLD}>These are not my photos</Text> (For Staff user only)
                      {!hasCustomEventFormFieldsFeature && (
                        <PremiumFeatureIcon module="report issue - these are not my photos" />
                      )}
                    </Text>
                    <Text color={COLOR_SHADES.LIGHT}>
                      Allow guests to report "These are not my photos" in the guest gallery
                    </Text>
                  </Col>
                  <Col size={null} rightAlighed>
                    <Row vcenter>
                      <Text size={TEXT_SIZE.SIZE_5} boldness={TEXT_BOLDNESS.BOLD}>
                        {enableReportingTheseAreNotMyPhotos ? 'Yes' : 'No'}
                      </Text>
                      <Space size={2} />
                      <ToggleSwitch
                        isLoading={isLoading}
                        name=""
                        checked={enableReportingTheseAreNotMyPhotos}
                        disabled={!hasCustomEventFormFieldsFeature}
                        onChange={(value) => {
                          if (!hasCustomEventFormFieldsFeature) return;
                          savePreferences({
                            data: {
                              [ClientSettingsService.CLIENT_SETTINGS_META_TYPES
                                .ENABLE_REPORTING_THESE_ARE_NOT_MY_PHOTOS]: value,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </ListItem>
              )}
            </List>
          </Card>
          <Space vertical size={8} />
          <EventIntegrationSettingsContainer />
        </InnerPageContentMainCol>
      </InnerPageContent>
    </InnerPage>
  );
}
