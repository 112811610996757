import { connect } from 'react-redux';
import { ActionConstants } from '@premagic/proposals';
import { ModalDuck } from '@premagic/common-ducks';
import ProposalCreatorFilesModal from './ProposalCreatorFilesModal';

function mapStateToProps(
  state,
  ownProps: {
    projectId: string;
    folderId: string;
  },
) {
  const { projectId, folderId } = ownProps;
  return {
    projectId,
    folderId,
    show: ModalDuck.isModalOpenSelector(ActionConstants.KEYS.PROPOSAL.SHOW_FILES_MODAL)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(ModalDuck.toggleModalVisibility(dispatch, ActionConstants.KEYS.PROPOSAL.SHOW_FILES_MODAL, false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCreatorFilesModal);
