import { PremagicResources } from '@premagic/core';
import { INPUT_SIZES, Row, Select, Space, Text } from '@premagic/myne';
import React, { useEffect } from 'react';

const photosOptions = PremagicResources.RESOURCES.watermarkPreviewStockImage.map((photo) => ({
  value: photo.image,
  label: photo.name,
}));

export default function WatermarkPhotoSelector(props: {
  onChange: ({ image, name }) => void;
  value?: {
    image: string;
    name: string;
  };
}) {
  const { onChange, value } = props;
  useEffect(() => {
    if (!value) {
      onChange(PremagicResources.RESOURCES.watermarkPreviewStockImage[0]);
    }
  }, [value]);
  return (
    <Row vcenter>
      <Text>Photo</Text> <Space size={2} />
      <Select
        size={INPUT_SIZES.SM}
        options={photosOptions}
        value={photosOptions.find((option) => option.value === value?.image)}
        name=""
        isMulti={false}
        onChange={(option) =>
          option &&
          onChange({
            image: option.value,
            name: option.label,
          })
        }
      />
    </Row>
  );
}
