import { ClientWebsiteService, EventExhibitorService, FolderService } from '@premagic/core';

import {
  Button,
  BUTTON_ICON_STYLES,
  BUTTON_STYLES,
  ButtonIcon,
  Col,
  Color,
  COLOR_SHADES,
  Icon,
  ICON_SIZES,
  LIST_ITEM_ACTION_STYLES,
  ListItem,
  ListItemAction,
  Row,
  Space,
  Text,
  TEXT_BOLDNESS,
  TEXT_SIZE,
} from '@premagic/myne';
import React, { useEffect } from 'react';
import { PERMISSIONS } from '../../users/UserPermissionService';
import EventExhibitorOptionsMenuContainer from './dropdown-menu/EventExhibitorOptionsMenuContainer';
import EventExhibitorLinkToPosterButtonContainer from './EventExhibitorLinkToPosterButtonContainer';
import ExhibitorLogoPictureContainer from './exhibitor-logo-file/ExhibitorLogoPictureContainer';

const EXHIBITOR_CONTACTS_META_DETAILS = {
  phone: {
    icon: 'phone',
    id: 'phone',
    getUrl: (phone: string) => `tel:${phone}`,
  },
  email: {
    icon: 'mail',
    id: 'email',
    getUrl: (email: string) => `mailto:${email}`,
  },
};

type ExhibitorInfoProps = {
  value?: string;
  item: 'phone' | 'email';
};

function ExhibitorInfo(props: ExhibitorInfoProps) {
  const { value, item } = props;
  const itemDetails = EXHIBITOR_CONTACTS_META_DETAILS[item];
  return (
    <Row vcenter>
      <Color shade={COLOR_SHADES.BLUE} inline>
        <Icon name={itemDetails.icon} size={ICON_SIZES.SM} />
      </Color>
      <Space size={2} />
      {value ? (
        <a href={itemDetails.getUrl(value)} target="_blank" rel="noopener noreferrer" data-channel={itemDetails.id}>
          <Text block size={TEXT_SIZE.SIZE_5}>
            {value}
          </Text>
        </a>
      ) : (
        <Text block size={TEXT_SIZE.SIZE_5} muted>
          (blank)
        </Text>
      )}
    </Row>
  );
}

export function getCompleteRegistrationLink(options: {
  clientWebsite?: FolderService.FolderType;
  registrationId: string;
}) {
  const { clientWebsite, registrationId } = options;
  if (!clientWebsite) return null;
  return `${clientWebsite.share_url}${ClientWebsiteService.CLIENT_WEBSITE_LINK.REGISTRATION.COMPLETE_REGISTRATION(registrationId)}`;
}

type Props = {
  exhibitor: EventExhibitorService.EventExhibitorType;
  projectId: string;
  exhibitorId: string;
  hasPermissions: {
    [PERMISSIONS.EVENT_EXHIBITOR]: boolean;
  };
  focus?: boolean;
  listStyle: 'list' | 'detailed';
  hasPoster: boolean;
  clientWebsite?: FolderService.FolderType;
};

export default function EventExhibitorItem(props: Props): JSX.Element {
  const { exhibitor, exhibitorId, projectId, hasPermissions, focus, listStyle, hasPoster, clientWebsite } = props;
  const { name, phone, email, booth_number: boothNumber, created_at: createdAt } = exhibitor;

  const [listExpanded, setListExpanded] = React.useState(listStyle === 'detailed');

  useEffect(() => {
    setListExpanded(listStyle === 'detailed');
  }, [listStyle]);

  return (
    <ListItem highlight={focus}>
      <Col size={12}>
        <Row>
          {exhibitorId ? (
            <ExhibitorLogoPictureContainer exhibitorId={exhibitorId} />
          ) : (
            <Row vcenter columnDirection>
              <Color shade={COLOR_SHADES.DANGER} inline>
                <Icon name="alert_triangle" size={ICON_SIZES.SM} />
              </Color>
              <Space size={1} vertical />
              <Text color={COLOR_SHADES.DANGER} boldness={TEXT_BOLDNESS.BOLD} size={TEXT_SIZE.SIZE_6}>
                Not Set
              </Text>
            </Row>
          )}
          <Space size={4} />
          <Col size={8}>
            <Button onClick={() => setListExpanded(!listExpanded)} style={BUTTON_STYLES.RESET} block>
              <Text size={TEXT_SIZE.SIZE_3} boldness={TEXT_BOLDNESS.BOLD} color={COLOR_SHADES.DARKER} block>
                {name}
              </Text>

              {boothNumber && (
                <>
                  <Space vertical size={1} />
                  <Row vcenter>
                    <Text boldness={TEXT_BOLDNESS.SEMI_BOLD} muted>
                      Booth number:
                    </Text>
                    <Space size={2} />
                    <Text muted>{boothNumber}</Text>
                  </Row>
                </>
              )}
            </Button>
          </Col>
        </Row>

        {listExpanded && (
          <div>
            <Space vertical size={4} />
            <Row>
              <Space size={12} />
              {hasPoster && (
                <EventExhibitorLinkToPosterButtonContainer exhibitorId={exhibitorId} projectId={projectId} />
              )}
            </Row>
            <Space vertical size={2} />
            <Row>
              <Space size={12} />
              <Col size={8}>
                <ExhibitorInfo item="email" value={email} />
                <Space size={2} vertical />
                <ExhibitorInfo item="phone" value={phone} />
              </Col>
            </Row>
          </div>
        )}
      </Col>
      <ListItemAction style={LIST_ITEM_ACTION_STYLES.PRIMARY_TOP_CORNER}>
        <Space size={8} />
        <ButtonIcon
          title={listExpanded ? 'Collapse' : 'Expand'}
          style={BUTTON_ICON_STYLES.SECONDARY}
          onClick={() => setListExpanded(!listExpanded)}
        >
          <Icon name={listExpanded ? 'chevron_up' : 'chevron_down'} size={ICON_SIZES.SM} />
        </ButtonIcon>

        <EventExhibitorOptionsMenuContainer exhibitorId={exhibitorId} projectId={projectId} />
      </ListItemAction>
    </ListItem>
  );
}
