import { connect } from 'react-redux';
import EventOccasionItem from './EventOccasionItem';
import { groupedServiceProvidersForOccasionSelector, occasionItemsSelector } from './OccasionsDataDuck';
import { LOADINGS } from '../../../../../common/Constants';
import { toggleWindowPanelVisibility } from '../../../../../common/WindowPanelDuck';
import { setEditOfOccasion } from '../events/details/EventDetailsPageDuck';
import { hasPermission } from '../users/UsersDataDuck';
import { PERMISSIONS } from '../users/UserPermissionService';

function mapStateToProps(state, ownProps: { occasionId: string; eventId: string; focus: boolean }) {
  const { occasionId, eventId, focus } = ownProps;

  return {
    eventId,
    occasionId,
    occasion: occasionItemsSelector(state)[occasionId],
    focus,
    usersGroupedByRole: groupedServiceProvidersForOccasionSelector(occasionId)(state),
    hasPermissionsToManageEvent: hasPermission(PERMISSIONS.EVENT_OCCASION_MANAGE)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showEditOccasion: (occasionId: string) => {
      dispatch(setEditOfOccasion(dispatch, occasionId));
      dispatch(toggleWindowPanelVisibility(dispatch, LOADINGS.OCCASIONS.SHOW_EDIT_PANEL, true));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventOccasionItem);
